import createAction from '../utils/createAction';

import { ScheduleType } from '../../types/schedules.types';

export const SET_SCHEDULES = 'SET_SCHEDULES';
export const SET_SCHEDULES_LS = 'SET_SCHEDULES_LS';
export const UPDATE_SCHEDULES = 'UPDATE_SCHEDULES';
export const ADD_SCHEDULES = 'ADD_SCHEDULES';
export const REMOVE_SCHEDULES = 'REMOVE_SCHEDULES';

export const setSchedules = createAction<ScheduleType[]>(SET_SCHEDULES);
export const setSchedulesLs = createAction<string>(SET_SCHEDULES_LS);
export const updateSchedules = createAction<ScheduleType>(UPDATE_SCHEDULES);
export const addSchedules = createAction<ScheduleType>(ADD_SCHEDULES);
export const removeSchedules = createAction<number>(REMOVE_SCHEDULES);
