// package: auth
// file: auth-shared.proto

var auth_shared_pb = require("./auth-shared_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var google_protobuf_wrappers_pb = require("google-protobuf/google/protobuf/wrappers_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var AuthSharedApiService = (function () {
  function AuthSharedApiService() {}
  AuthSharedApiService.serviceName = "auth.AuthSharedApiService";
  return AuthSharedApiService;
}());

AuthSharedApiService.checkAccountByEmail = {
  methodName: "checkAccountByEmail",
  service: AuthSharedApiService,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_wrappers_pb.StringValue,
  responseType: auth_shared_pb.VerificationEmailResponse
};

AuthSharedApiService.sendVerificationCode = {
  methodName: "sendVerificationCode",
  service: AuthSharedApiService,
  requestStream: false,
  responseStream: false,
  requestType: auth_shared_pb.VerificationCodeRequest,
  responseType: google_protobuf_empty_pb.Empty
};

AuthSharedApiService.getInvitationInfo = {
  methodName: "getInvitationInfo",
  service: AuthSharedApiService,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_wrappers_pb.StringValue,
  responseType: auth_shared_pb.InvitationResponse
};

AuthSharedApiService.acceptInvitation = {
  methodName: "acceptInvitation",
  service: AuthSharedApiService,
  requestStream: false,
  responseStream: false,
  requestType: auth_shared_pb.InvitationRequest,
  responseType: auth_shared_pb.LoginResponse
};

AuthSharedApiService.login = {
  methodName: "login",
  service: AuthSharedApiService,
  requestStream: false,
  responseStream: false,
  requestType: auth_shared_pb.LoginRequest,
  responseType: auth_shared_pb.LoginResponse
};

AuthSharedApiService.logout = {
  methodName: "logout",
  service: AuthSharedApiService,
  requestStream: false,
  responseStream: false,
  requestType: auth_shared_pb.LogoutRequest,
  responseType: google_protobuf_empty_pb.Empty
};

AuthSharedApiService.resetPassword = {
  methodName: "resetPassword",
  service: AuthSharedApiService,
  requestStream: false,
  responseStream: false,
  requestType: auth_shared_pb.VerificationCodeRequest,
  responseType: google_protobuf_empty_pb.Empty
};

AuthSharedApiService.changePassword = {
  methodName: "changePassword",
  service: AuthSharedApiService,
  requestStream: false,
  responseStream: false,
  requestType: auth_shared_pb.ChangeForgotPasswordRequest,
  responseType: auth_shared_pb.LoginResponse
};

AuthSharedApiService.showReCaptcha = {
  methodName: "showReCaptcha",
  service: AuthSharedApiService,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: auth_shared_pb.ShowReCaptchaResponse
};

exports.AuthSharedApiService = AuthSharedApiService;

function AuthSharedApiServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

AuthSharedApiServiceClient.prototype.checkAccountByEmail = function checkAccountByEmail(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AuthSharedApiService.checkAccountByEmail, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AuthSharedApiServiceClient.prototype.sendVerificationCode = function sendVerificationCode(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AuthSharedApiService.sendVerificationCode, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AuthSharedApiServiceClient.prototype.getInvitationInfo = function getInvitationInfo(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AuthSharedApiService.getInvitationInfo, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AuthSharedApiServiceClient.prototype.acceptInvitation = function acceptInvitation(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AuthSharedApiService.acceptInvitation, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AuthSharedApiServiceClient.prototype.login = function login(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AuthSharedApiService.login, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AuthSharedApiServiceClient.prototype.logout = function logout(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AuthSharedApiService.logout, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AuthSharedApiServiceClient.prototype.resetPassword = function resetPassword(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AuthSharedApiService.resetPassword, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AuthSharedApiServiceClient.prototype.changePassword = function changePassword(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AuthSharedApiService.changePassword, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AuthSharedApiServiceClient.prototype.showReCaptcha = function showReCaptcha(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AuthSharedApiService.showReCaptcha, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.AuthSharedApiServiceClient = AuthSharedApiServiceClient;

