import React from 'react';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';
import { useSelector } from 'react-redux';

import { OrganizationChecklistTask } from 'respona_api/generated/user-organization_pb';

import { completeChecklistItemApi } from '@api/userOrganization.api';

import { userProfileSelector } from '@redux/selectors/userProfile.selector';

import BaseModal from '@uikit/BaseModal/BaseModal';

import VideoFrame from '@components/Campaigns/Dashboard/_components/VideoFrame/VideoFrame';

import './TutorialModal.scss';

const TutorialModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  return (
    <BaseModal
      classNames={{ closeButton: 'tutorial-modal__close-btn' }}
      isOpen={isOpen}
      onClose={onClose}
      fitWidthContent
    >
      <div className="tutorial-modal">
        <VideoFrame />
      </div>
    </BaseModal>
  );
};

export const BookVideTutorialModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { info: userProfile } = useSelector(userProfileSelector);

  useCalendlyEventListener({
    onEventScheduled: async () => {
      await completeChecklistItemApi(OrganizationChecklistTask.SCHEDULE_ONBOARDING_SESSION, true);
    },
  });

  return (
    <BaseModal
      classNames={{ closeButton: 'tutorial-modal__close-btn' }}
      isOpen={isOpen}
      onClose={onClose}
    >
      <InlineWidget
        url="https://calendly.com/respona/onboarding"
        iframeTitle="onboarding-scheduling"
        pageSettings={{
          hideGdprBanner: true,
          textColor: '111a45',
          primaryColor: '251fb6',
        }}
        styles={{
          width: '100%',
          minWidth: '320px',
          maxWidth: '1000px',
          height: '646px',
        }}
        prefill={{
          name: `${userProfile.firstName} ${userProfile.lastName}`,
          email: userProfile.email,
        }}
      />
    </BaseModal>
  );
};

export default TutorialModal;
