import React from 'react';

export default ({ color = '#BDBDBD' }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.3392 6.35305L16.8205 5.45298C16.3817 4.69138 15.4092 4.42864 14.6466 4.86564V4.86564C14.2835 5.07949 13.8504 5.14017 13.4425 5.03428C13.0347 4.92839 12.6858 4.66464 12.4727 4.30118C12.3355 4.07016 12.2619 3.80704 12.2591 3.53841V3.53841C12.2714 3.10773 12.109 2.69038 11.8086 2.38143C11.5083 2.07249 11.0957 1.89826 10.6649 1.89844H9.61987C9.19776 1.89843 8.79305 2.06664 8.49528 2.36583C8.19752 2.66502 8.03126 3.07053 8.03329 3.49264V3.49264C8.02078 4.36415 7.31068 5.06405 6.43909 5.06396C6.17046 5.06117 5.90733 4.98749 5.67631 4.85038V4.85038C4.91367 4.41339 3.94121 4.67612 3.5024 5.43772L2.94557 6.35305C2.50729 7.1137 2.76645 8.08555 3.52528 8.52697V8.52697C4.01854 8.81174 4.32239 9.33803 4.32239 9.90759C4.32239 10.4771 4.01854 11.0034 3.52528 11.2882V11.2882C2.76742 11.7267 2.50797 12.6961 2.94557 13.4545V13.4545L3.47189 14.3622C3.67749 14.7332 4.02245 15.007 4.43045 15.1229C4.83844 15.2389 5.27582 15.1875 5.6458 14.9801V14.9801C6.00952 14.7678 6.44294 14.7097 6.84973 14.8185C7.25653 14.9274 7.60298 15.1942 7.81209 15.5598C7.9492 15.7908 8.02288 16.0539 8.02567 16.3225V16.3225C8.02567 17.203 8.73941 17.9167 9.61987 17.9167H10.6649C11.5424 17.9168 12.2549 17.2076 12.2591 16.3302V16.3302C12.257 15.9067 12.4243 15.5001 12.7238 15.2006C13.0232 14.9012 13.4298 14.7339 13.8533 14.736C14.1213 14.7431 14.3833 14.8165 14.6161 14.9495V14.9495C15.3767 15.3878 16.3485 15.1287 16.79 14.3698V14.3698L17.3392 13.4545C17.5517 13.0896 17.6101 12.655 17.5013 12.247C17.3925 11.839 17.1255 11.4912 16.7595 11.2806V11.2806C16.3934 11.07 16.1264 10.7222 16.0176 10.3142C15.9088 9.90613 15.9672 9.47156 16.1797 9.10668C16.318 8.86532 16.5181 8.66521 16.7595 8.52697V8.52697C17.5137 8.08579 17.7723 7.11962 17.3392 6.36068V6.36068V6.35305Z"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="10.146"
      cy="9.90773"
      r="2.1968"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
