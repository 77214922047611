import { handleActions } from 'redux-actions';

import { TemplateType } from '@ts/template.types';
import loadingStatuses from '@constants/loadingStatuses';

import {
  setSequenceTemplates,
  setSequenceTemplatesLs,
  clearSequenceTemplates,
  addSequenceTemplate,
  addSequenceTemplateStep,
  updateSequenceTemplateStep,
  setEmailTemplates,
  setEmailTemplatesLs,
  clearEmailTemplates,
  addOrUpdateEmailTemplate,
  removeEmailTemplate,
  removeSequenceTemplate,
} from '@redux/actions/templates.actions';
import { normalizeArrayByField } from '@utils/normalize';
import omit from '@utils/omit';
import { updateItemInListByIdHelper } from '@helpers/commonHelpers';

export type TemplatesStateType = {
  sequenceTemplates: {
    templates: { [key: string]: TemplateType };
    loadingStatus: string;
  };
  emailTemplates: {
    templates: { [key: string]: TemplateType };
    loadingStatus: string;
  };
};

const defaultState: TemplatesStateType = {
  sequenceTemplates: {
    templates: {},
    loadingStatus: loadingStatuses.PENDING,
  },
  emailTemplates: {
    templates: {},
    loadingStatus: loadingStatuses.PENDING,
  },
};

export default handleActions(
  {
    [setSequenceTemplates]: (state, { payload }) => ({
      ...state,
      sequenceTemplates: {
        ...state.sequenceTemplates,
        templates: normalizeArrayByField<TemplateType>(payload),
      },
    }),
    [setSequenceTemplatesLs]: (state, { payload }) => ({
      ...state,
      sequenceTemplates: {
        ...state.sequenceTemplates,
        loadingStatus: payload,
      },
    }),
    [addSequenceTemplate]: (state, { payload }) => ({
      ...state,
      sequenceTemplates: {
        ...state.sequenceTemplates,
        templates: {
          ...state.sequenceTemplates.templates,
          [payload.id]: payload,
        },
      },
    }),
    [removeSequenceTemplate]: (state, { payload }) => ({
      ...state,
      sequenceTemplates: {
        ...state.sequenceTemplates,
        templates: omit(state.sequenceTemplates.templates, [payload]),
      },
    }),
    [addSequenceTemplateStep]: (state, { payload }) => {
      const curTemplate = state.sequenceTemplates.templates[payload.templateId];

      return {
        ...state,
        sequenceTemplates: {
          ...state.sequenceTemplates,
          templates: {
            ...state.sequenceTemplates.templates,
            [payload.templateId]: {
              ...curTemplate,
              stepsList: [...curTemplate.stepsList, payload.sequenceTemplateStep],
            },
          },
        },
      };
    },
    [updateSequenceTemplateStep]: (state, { payload }) => {
      const curTemplate = state.sequenceTemplates.templates[payload.templateId];

      return {
        ...state,
        sequenceTemplates: {
          ...state.sequenceTemplates,
          templates: {
            ...state.sequenceTemplates.templates,
            [payload.templateId]: {
              ...curTemplate,
              stepsList: updateItemInListByIdHelper(curTemplate.stepsList, payload.step),
            },
          },
        },
      };
    },
    [clearSequenceTemplates]: (state) => ({
      ...state,
      sequenceTemplates: defaultState.sequenceTemplates,
    }),
    [setEmailTemplates]: (state, { payload }) => ({
      ...state,
      emailTemplates: {
        ...state.emailTemplates,
        templates: normalizeArrayByField<TemplateType>(payload),
      },
    }),
    [addOrUpdateEmailTemplate]: (state, { payload }) => ({
      ...state,
      emailTemplates: {
        ...state.emailTemplates,
        templates: {
          ...state.emailTemplates.templates,
          [payload.id]: payload,
        },
      },
    }),
    [removeEmailTemplate]: (state, { payload }) => ({
      ...state,
      emailTemplates: {
        ...state.emailTemplates,
        templates: {
          ...omit(state.emailTemplates.templates, [String(payload)]),
        },
      },
    }),
    [setEmailTemplatesLs]: (state, { payload }) => ({
      ...state,
      emailTemplates: {
        ...state.emailTemplates,
        loadingStatus: payload,
      },
    }),
    [clearEmailTemplates]: (state) => ({
      ...state,
      emailTemplates: defaultState.emailTemplates,
    }),
  },
  defaultState
);
