import React from 'react';
import { format } from 'date-fns';

import { HaroOpportunityType } from '@ts/haro.types';

import { fromNumberAhrefFormatHelper } from '@helpers/transformDataHelpers';
import { SVGIcon } from '@uikit/Icon/Icon';

import InquiryPitchSummaryInfoCategories from './_components/InquiryPitchSummaryInfoCategories/InquiryPitchSummaryInfoCategories';

import './InquiryPitchSummaryInfo.scss';

const InquiryPitchSummaryInfo = ({ inquiry }: { inquiry: HaroOpportunityType }): JSX.Element => {
  const dateFormat = 'MMM dd, yyyy - h:mma';
  return (
    <div className="inquiry-pitch-summary-info">
      <div className="inquiry-pitch-summary-info__data inquiry-pitch-summary-info__data--category">
        <div className="inquiry-pitch-summary-info__data-icon">
          <SVGIcon icon="priceTag" />
        </div>
        <div className="inquiry-pitch-summary-info__data-label">Category:</div>
        <InquiryPitchSummaryInfoCategories inquiryCategories={inquiry.categoriesList} />
      </div>
      <div className="inquiry-pitch-summary-info__data">
        <div className="inquiry-pitch-summary-info__data-icon">
          <SVGIcon icon="globe" />
        </div>
        <div className="inquiry-pitch-summary-info__data-label">Site:</div>
        <div className="inquiry-pitch-summary-info__data-value">{inquiry.publication || '-'}</div>
      </div>
      <div className="inquiry-pitch-summary-info__data">
        <div className="inquiry-pitch-summary-info__data-icon">
          <SVGIcon icon="downloadFile" />
        </div>
        <div className="inquiry-pitch-summary-info__data-label">Recieved on:</div>
        <div className="inquiry-pitch-summary-info__data-value">
          {format(new Date(inquiry.receivedTimestamp), dateFormat)}
        </div>
      </div>
      <div className="inquiry-pitch-summary-info__data">
        <div className="inquiry-pitch-summary-info__data-icon">
          <SVGIcon icon="star" />
        </div>
        <div className="inquiry-pitch-summary-info__data-label">Domain Authority:</div>
        <div className="inquiry-pitch-summary-info__data-value">{inquiry.domainRating}</div>
      </div>
      <div className="inquiry-pitch-summary-info__data">
        <div className="inquiry-pitch-summary-info__data-icon">
          <SVGIcon icon="flag" />
        </div>
        <div className="inquiry-pitch-summary-info__data-label">Deadline:</div>
        <div className="inquiry-pitch-summary-info__data-value">
          {format(new Date(inquiry.deadlineTimestamp), dateFormat)}
        </div>
      </div>
      <div className="inquiry-pitch-summary-info__data">
        <div className="inquiry-pitch-summary-info__data-icon">
          <SVGIcon icon="curves" />
        </div>
        <div className="inquiry-pitch-summary-info__data-label">Spam Score:</div>
        <div className="inquiry-pitch-summary-info__data-value">
          {fromNumberAhrefFormatHelper(inquiry.domainSpamScore)}
        </div>
      </div>
    </div>
  );
};

export default InquiryPitchSummaryInfo;
