import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DispatchType } from 'src/store';

import {
  insightsDateFilterSelector,
  insightsFiltersSelector,
} from '@redux/selectors/insights.selector';

import HeaderFiltersButtonWithTooltip from '@components/PageHeader/HeaderFiltersButtonWithTooltip/HeaderFiltersButtonWithTooltip';
import InsightsFiltersInTooltip from '@components/Insights/InsightsFiltersInTooltip/InsightsFiltersInTooltip';
import HeaderDateFilter from '@uikit/HeaderDateFilter/HeaderDateFilter';
import { setInsightsDateFilter, throwInsightsFilter } from '@redux/actions/insights.actions';

import './InsightsHeaderActions.scss';

const InsightsHeaderActions = (): JSX.Element => {
  const filterStateRef = useRef<{ reset: () => void }>();

  const dispatch = useDispatch<DispatchType>();

  const filters = useSelector(insightsFiltersSelector);
  const dateFilter = useSelector(insightsDateFilterSelector);

  const areThereChosenItems = filters.peopleIds.length > 0 || filters.campaignsIds.length > 0;
  const areFiltersEnabled = filters.type !== 0 && areThereChosenItems;

  const handleChangeDate = (value) => {
    dispatch(
      setInsightsDateFilter({
        startDate: +value.startDate,
        endDate: +value.endDate,
      })
    );
  };

  const throwAllInsightsFilters = () => dispatch(throwInsightsFilter());

  return (
    <div className="insights-header-actions">
      <div className="insights-header-actions__date-filter">
        <HeaderDateFilter value={dateFilter} onChangeDatarange={handleChangeDate} />
      </div>
      <HeaderFiltersButtonWithTooltip
        tooltipOffset={100}
        areFiltersEnabled={areFiltersEnabled}
        onClearAllFilters={throwAllInsightsFilters}
        onHide={() => filterStateRef.current?.reset?.()}
      >
        <InsightsFiltersInTooltip innerRef={filterStateRef} />
      </HeaderFiltersButtonWithTooltip>
    </div>
  );
};

export default InsightsHeaderActions;
