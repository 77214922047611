import {
  createCampaignApi,
  updateCampaignApi,
  getCampaignsFoldersApi,
  createCampaignsFolderApi,
  removeCampaignsFolderApi,
  updateCampaignsFoldersApi,
  changeCampaignStatusApi,
  addPeopleToCampaignApi,
  AddPeopleToCampaignOptionsType,
} from '@api/campaign.api';
import {
  addCampaign,
  addCampaignFolders,
  setCampaignFolders,
  setCampaignFoldersLs,
  removeCampaignFolder,
  updateCampaignFolder,
  changeCampaignStatus,
  addNewOneCampaignsFolder,
} from '@redux/actions/campaigns.actions';
import loadingStatuses from '@constants/loadingStatuses';
import { Dispatch } from 'redux';
import { addNotification } from '@redux/actions/notifications.actions';
import { CampaignCrudRequest } from 'respona_api/generated/campaign_pb';
import { DispatchType } from 'src/store';

export const saveCampaign = (dispatch: Dispatch, workspaceId: number, folderId: number = null) => {
  return createCampaignApi(workspaceId, folderId).then((res) => {
    dispatch(addCampaign(res));
    return res;
  });
};

export const updateCampaign = (
  dispatch: DispatchType,
  campaignInfo: CampaignCrudRequest.AsObject
) => {
  return updateCampaignApi(campaignInfo);
};

export const changeCampaignStatusThunk =
  (campaignId: number, workspaceId: number, status: 0 | 1 | 2 | 3 | 4 | 5) => (dispatch) => {
    return changeCampaignStatusApi(campaignId, workspaceId, status).then(
      ({ id, status: newStatus }) => {
        dispatch(changeCampaignStatus({ campaignId: id, status: newStatus }));
      }
    );
  };

export const fetchCampaignsFolders =
  (workspaceId: number, page: number, limit: number) => (dispatch: Dispatch) => {
    dispatch(setCampaignFoldersLs(loadingStatuses.INITED));

    return getCampaignsFoldersApi(workspaceId, page, limit)
      .then((folders) => {
        if (page > 0) {
          dispatch(addCampaignFolders(folders));
        } else {
          dispatch(setCampaignFolders(folders));
        }
        return folders;
      })
      .finally(() => {
        dispatch(setCampaignFoldersLs(loadingStatuses.LOADED));
      });
  };

export const createCampaignsFolder =
  (workspaceId: number, title: string) => (dispatch: Dispatch) => {
    return createCampaignsFolderApi(workspaceId, title).then((folder) => {
      dispatch(addNewOneCampaignsFolder(folder));
    });
  };

export const removeCampaignsFolderRequest =
  (folderId: number, workspaceId: number) => (dispatch: Dispatch) => {
    removeCampaignsFolderApi(folderId, workspaceId).then(() => {
      dispatch(removeCampaignFolder(folderId));
    });
  };

export const updateCampaignsFolderRequest =
  (folderId: number, folderTitle: string) => (dispatch: Dispatch) => {
    updateCampaignsFoldersApi(folderId, folderTitle).then((newFolder) => {
      dispatch(updateCampaignFolder(newFolder));
    });
  };

export const addPeopleToCampaignRequest =
  (
    campaignId: number,
    peopleIds: number[],
    workspaceId: number,
    options: AddPeopleToCampaignOptionsType
  ) =>
  (dispatch: Dispatch) => {
    return addPeopleToCampaignApi(campaignId, peopleIds, workspaceId, options)
      .then(() =>
        dispatch(addNotification({ title: 'People were added to campaign', type: 'success' }))
      )
      .catch((error) => {
        dispatch(
          addNotification({
            title: 'Some contacts already exists in this campaign',
            type: 'warning',
          })
        );
        throw new Error(error);
      });
  };
