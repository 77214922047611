import {
  getAllFoldersApi,
  getAllTagsApi,
  createNewFolderApi,
  movePersonToFolderApi,
  removeFolderApi,
  renameFolderApi,
  createTagApi,
  removePersonBatchApi,
  removePersonApi,
} from '@api/people.api';

import loadingStatuses from '@constants/loadingStatuses';

import { normalizeArrayByField } from '@utils/normalize';
import {
  setFoldersData,
  setFoldersLs,
  addFoldersData,
  updateFolderData,
  removeFolderData,
  setPeopleTagsData,
  setPeopleTagsLs,
  addPeopleTag,
} from '@redux/actions/people.actions';
import { FolderType, TagType } from '@ts/common.types';
import { Dispatch } from 'redux';
import { EntityRelationRequest } from 'respona_api/generated/common_pb';

export const fetchAllFolders = (dispatch: Dispatch): void => {
  dispatch(setFoldersLs(loadingStatuses.INITED));

  getAllFoldersApi()
    .then((peopleFolders) => {
      dispatch(setFoldersData(peopleFolders));
    })
    .finally(() => {
      dispatch(setFoldersLs(loadingStatuses.LOADED));
    });
};

export const createNewFolderRequest =
  (title = 'New folder') =>
  (dispatch: Dispatch): Promise<FolderType> => {
    return createNewFolderApi(title).then((folder: FolderType) => {
      dispatch(addFoldersData(folder));

      return folder;
    });
  };

export const movePersonsToFolder = (
  folderId: number,
  personsIds: number[],
  type: EntityRelationRequest.SelectionTypeMap[keyof EntityRelationRequest.SelectionTypeMap]
): Promise<void> => {
  return movePersonToFolderApi(folderId, personsIds, type);
};

export const movePersonsToNewFolder =
  (
    personsIds: number[],
    type: EntityRelationRequest.SelectionTypeMap[keyof EntityRelationRequest.SelectionTypeMap]
  ) =>
  (dispatch) => {
    dispatch(createNewFolderRequest()).then((createdFolder) => {
      return movePersonsToFolder(createdFolder.id, personsIds, type);
    });
  };

export const removeFolder =
  (folderId: number) =>
  (dispatch: Dispatch): Promise<void> =>
    removeFolderApi(folderId).then(() => {
      dispatch(removeFolderData(folderId));
    });

export const removePeopleBatchRequest = (peoplesIds: number[]): Promise<void[] | void> => {
  if (peoplesIds == null || peoplesIds.length === 0) {
    return removePersonBatchApi(null, EntityRelationRequest.SelectionType.ALL);
  }
  if (peoplesIds.length === 1) {
    return removePersonApi(peoplesIds[0]);
  }
  return removePersonBatchApi(peoplesIds, EntityRelationRequest.SelectionType.SELECTED);
};

export const renameFolder = (folderId: number, folderName: string) => (dispatch: Dispatch) => {
  return renameFolderApi(folderId, folderName).then((folder: FolderType) =>
    dispatch(updateFolderData(folder))
  );
};

/*
  Tags
 */
export const fetchAllTags = (dispatch: Dispatch): void => {
  dispatch(setPeopleTagsLs(loadingStatuses.INITED));

  getAllTagsApi()
    .then((tags) => {
      dispatch(setPeopleTagsData(normalizeArrayByField(tags)));
    })
    .finally(() => {
      dispatch(setPeopleTagsLs(loadingStatuses.LOADED));
    });
};

export const createTagRequest =
  (title: string) =>
  (dispatch: Dispatch): Promise<TagType> => {
    return createTagApi(title).then((tag: TagType) => {
      dispatch(addPeopleTag({ [tag.id]: tag }));
      return tag;
    });
  };
