import {
  IntegrationSettingsType,
  IntegrationType,
  UpdateIntegrationType,
} from '@ts/userOrganization.types';
import { IntegrationTypeMap } from 'respona_api/generated/common_pb';

import {
  connectIntegrationApi,
  deleteIntegrationApi,
  getAllIntegrationsApi,
  updateIntegrationApi,
} from '@api/userOrganization.api';

import {
  connectIntegration,
  updateIntegration,
  fetchIntegrations,
  setIntegrations,
  deleteIntegration,
} from '@redux/actions/integrations.actions';

import store from 'src/store';

export const fetchAllIntegrations =
  (workspaceId: number) =>
  (dispatch: typeof store.dispatch): void => {
    dispatch(fetchIntegrations());

    getAllIntegrationsApi(workspaceId).then((integrations) => {
      dispatch(setIntegrations(integrations));
    });
  };

export const connectIntegrationThunk =
  (
    type: IntegrationTypeMap[keyof IntegrationTypeMap],
    workspaceIdsList: number[],
    settings: Partial<IntegrationSettingsType>,
    workspaceId: number
  ) =>
  (dispatch: typeof store.dispatch): Promise<IntegrationType> => {
    return connectIntegrationApi(type, workspaceIdsList, settings, workspaceId).then(
      (integration) => {
        dispatch(connectIntegration(integration));

        return integration;
      }
    );
  };

export const updateIntegrationThunk =
  (
    id: number,
    workspaceId: number,
    type: IntegrationTypeMap[keyof IntegrationTypeMap],
    data: UpdateIntegrationType
  ) =>
  (dispatch: typeof store.dispatch): Promise<void | IntegrationType> => {
    return updateIntegrationApi(id, workspaceId, type, data).then((integration) => {
      dispatch(updateIntegration(integration));

      return integration;
    });
  };

export const deleteIntegrationThunk =
  (id: number, workspaceId: number) =>
  (dispatch: typeof store.dispatch): Promise<IntegrationType> => {
    return deleteIntegrationApi(id, workspaceId).then((integration) => {
      dispatch(deleteIntegration(integration));

      return integration;
    });
  };
