import React from 'react';

export default ({ color = '#fff' }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.63104 9.68394L2.61634 9.69859V9.71935V11.3327C2.61634 11.5228 2.69185 11.705 2.82625 11.8394C2.96065 11.9738 3.14294 12.0493 3.33301 12.0493H10.273H10.3937L10.3084 11.964L5.83503 7.49066L5.83467 7.49031C5.70071 7.359 5.5206 7.28544 5.33301 7.28544C5.14542 7.28544 4.96531 7.359 4.83134 7.49031L4.83104 7.49061L2.63104 9.68394ZM12.1176 12.0346L12.1322 12.0493H12.153H12.6663C12.8564 12.0493 13.0387 11.9738 13.1731 11.8394C13.3075 11.705 13.383 11.5228 13.383 11.3327V11.0527V11.0319L13.3683 11.0173L11.1683 8.82394L11.168 8.82364C11.034 8.69233 10.8539 8.61878 10.6663 8.61878C10.4788 8.61878 10.2986 8.69233 10.1647 8.82364L10.1643 8.82399L9.57765 9.41066L9.54239 9.44592L9.57756 9.48128L12.1176 12.0346ZM13.2977 9.20813L13.383 9.29294V9.17268V4.66602C13.383 4.47594 13.3075 4.29366 13.1731 4.15926C13.0387 4.02485 12.8564 3.94935 12.6663 3.94935H3.33301C3.14294 3.94935 2.96065 4.02485 2.82625 4.15926C2.69185 4.29366 2.61634 4.47594 2.61634 4.66602V7.83935V7.95961L2.7016 7.8748L3.95462 6.62844C4.32524 6.27253 4.81916 6.07377 5.33301 6.07377C5.84683 6.07377 6.34073 6.27251 6.71133 6.62838L8.63099 8.54804L8.66634 8.58339L8.7017 8.54804L9.28801 7.96172C9.65862 7.60584 10.1525 7.4071 10.6663 7.4071C11.1802 7.4071 11.6741 7.60586 12.0447 7.96176C12.0448 7.96186 12.0449 7.96197 12.045 7.96207L13.2977 9.20813ZM3.33301 2.71602H12.6663C13.1835 2.71602 13.6795 2.92146 14.0452 3.28716C14.4109 3.65285 14.6163 4.14884 14.6163 4.66602V11.3327C14.6163 11.8499 14.4109 12.3458 14.0452 12.7115C13.6795 13.0772 13.1835 13.2827 12.6663 13.2827H3.33301C2.81584 13.2827 2.31985 13.0772 1.95415 12.7115C1.58845 12.3458 1.38301 11.8499 1.38301 11.3327V4.66602C1.38301 4.14884 1.58845 3.65285 1.95415 3.28716C2.31985 2.92146 2.81584 2.71602 3.33301 2.71602Z"
      fill={color}
      stroke="black"
      strokeWidth="0.1"
    />
  </svg>
);
