import React, { useCallback, useMemo, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import cn from 'class-names';

import { ThreadMessageType } from '@ts/mailboxInbox.types';

import { getDateShort } from '@utils/date';

import useAllLinksTargetNewWindow from '@hooks/useAllLinksTargetNewWindow';

import { NamedAvatar } from '@uikit/Avatar/Avatar';
import { Button } from '@uikit/Button/Button';
import { SVGIcon } from '@uikit/Icon/Icon';

import ExpandableRow from '@components/ExpandableRow/ExpandableRow';
import Display from '@components/Display/Display';

export function EmailThreadWrapper({
  email,
  emailInfoShort,
  children,
  onReply,
  onForward,
  isFutureTimeFormat = false,
  isInitialPreview = false,
  isActionButtonHidden = false,
}: {
  email: { [key: string]: any };
  emailInfoShort: ThreadMessageType;
  children: React.ReactNode;
  onReply?: (email: ThreadMessageType, replyAll?: boolean) => void;
  onForward?: (email: ThreadMessageType) => void;
  isFutureTimeFormat?: boolean;
  isInitialPreview?: boolean;
  isActionButtonHidden?: boolean;
}): JSX.Element {
  const contentWrapperRef = useRef(null);
  const date = useRef<string>(getDateShort(new Date(emailInfoShort.date)));

  const [isPreview, setIsPreview] = useState<boolean>(isInitialPreview);

  const togglePreview = () => setIsPreview((mode) => !mode);

  useAllLinksTargetNewWindow(contentWrapperRef);

  const cnContainer = cn('thread-email-info', {
    'thread-email-info--preview': isPreview,
  });

  const cc = email?.headers?.cc;
  const bcc = email?.headers?.bcc;

  const encodeBase64Url = useMemo(() => {
    if (emailInfoShort.messageId.includes('@')) {
      let base64 = btoa(emailInfoShort.messageId);
      base64 = base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
      return base64;
    }

    return emailInfoShort.messageId;
  }, [emailInfoShort.messageId]);

  const composedId = useCallback(
    (prefix?: string): string => {
      const messageId = encodeBase64Url;
      if (prefix?.length > 0) return `${prefix}-${messageId}`;
      return messageId;
    },
    [emailInfoShort.messageId]
  );

  return (
    <div className={cnContainer} key={composedId()}>
      <div onClick={togglePreview} className="thread-email-info__header">
        <div className="thread-email-info__header-left">
          <NamedAvatar
            userName={emailInfoShort.fromName}
            className="thread-email-info__from-avatar"
          />
          <div>
            <div className="thread-email-info__from-info">
              <span className="thread-email-info__from-name">{emailInfoShort.fromName}</span>
              <span className="thread-email-info__from-email">
                &#60;{emailInfoShort.fromEmail}&#62;
              </span>
            </div>
            <div>
              <span className="thread-email-info__to-name">To: {emailInfoShort.toName}</span>
              <span className="thread-email-info__to-email">
                &#60;{emailInfoShort.toEmail}&#62;
              </span>
            </div>

            {cc?.length && (
              <div className="thread-email-info__cc-info">
                <span className="thread-email-info__to-name">
                  cc:{' '}
                  <ExpandableRow>
                    {cc.map((emailCC, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <React.Fragment key={index}>
                        {emailCC.value?.[0]?.name || '-'}
                        <span style={{ marginLeft: '8px' }} className="thread-email-info__to-email">
                          &#60;{emailCC.value?.[0]?.address}&#62;
                        </span>
                        {index + 1 < cc?.length && ','}
                      </React.Fragment>
                    ))}
                  </ExpandableRow>
                </span>
              </div>
            )}

            {bcc?.length && (
              <div className="thread-email-info__cc-info">
                <span className="thread-email-info__to-name">
                  bcc:{' '}
                  <ExpandableRow>
                    {bcc.map((emailBCC, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <React.Fragment key={index}>
                        {emailBCC.value?.[0]?.name}
                        <span style={{ marginLeft: '8px' }} className="thread-email-info__to-email">
                          &#60;{emailBCC.value?.[0]?.address}&#62;
                        </span>
                        {index + 1 < bcc?.length && ','}
                      </React.Fragment>
                    ))}
                  </ExpandableRow>
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="thread-email-info__date">{date.current}</div>
      </div>

      <Display isVisible={!!onReply && !!onForward && !isPreview && !isActionButtonHidden}>
        <div className="thread-email-info__actions">
          <Button
            type="ghost"
            className="thread-email-info__reply-btn"
            onClick={() => onReply(emailInfoShort)}
            data-for={composedId(`reply-message`)}
            data-tip="Reply to email"
          >
            <SVGIcon icon="cornerUpLeftIcon" color="#dfe1e6" />
          </Button>

          {cc?.length && (
            <Button
              type="ghost"
              className="thread-email-info__reply-btn"
              onClick={() => onReply(emailInfoShort, true)}
              data-for={composedId(`reply-all-message`)}
              data-tip="Reply All"
            >
              <SVGIcon icon="doubleCornerUpLeftIcon" color="#dfe1e6" />
            </Button>
          )}

          <Button
            type="ghost"
            className="thread-email-info__forward-btn"
            onClick={() => onForward(emailInfoShort)}
            data-for={composedId(`forward-message`)}
            data-tip="Forward email"
          >
            <SVGIcon icon="doubleCornerUpLeftIcon" color="#dfe1e6" />
          </Button>

          <ReactTooltip effect="solid" id={composedId(`reply-message`)} arrowColor="transparent" />
          <ReactTooltip
            effect="solid"
            id={composedId(`reply-all-message`)}
            arrowColor="transparent"
          />
          <ReactTooltip
            effect="solid"
            id={composedId(`forward-message`)}
            arrowColor="transparent"
          />
        </div>
      </Display>

      <Display isVisible={!isPreview}>
        <div className="thread-email-info__content" ref={contentWrapperRef}>
          {children}
        </div>
      </Display>
    </div>
  );
}

export default EmailThreadWrapper;
