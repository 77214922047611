import React, { useState } from 'react';
import { SVGIcon, SvgIconType } from '@uikit/Icon/Icon';
import cn from 'class-names';
import ActionsMenu from '@components/ActionsMenu/ActionsMenu';
import CreateItemComponent from '@uikit/Folders/_components/CreateItemComponent';

function FolderComponent({
  item,
  onClick,
  isActive,
  onRenameClick,
  onRemoveClick,
}: {
  onClick: () => void;
  item: { key: string; title: React.ReactNode; id?: number; icon?: SvgIconType };
  isActive: boolean;
  onRenameClick: (id: number, title: string) => Promise<void>;
  onRemoveClick: (id: number) => Promise<void>;
}) {
  const [isRenaming, setIsRenaming] = useState<boolean>(false);

  if (isRenaming) {
    return (
      <CreateItemComponent
        initialText={item.title as string}
        entityName=""
        isOpen
        onCreate={(newTitle) => onRenameClick(item.id, newTitle).then(() => setIsRenaming(false))}
        onCancel={() => setIsRenaming(false)}
      />
    );
  }

  return (
    <div
      className={cn('relationships-folders__folder', {
        'relationships-folders__folder--active': isActive,
      })}
      onClick={onClick}
    >
      {item.icon ? (
        <SVGIcon icon={item.icon} color={isActive ? '#221cb6' : '#BDBDBD'} size={17} />
      ) : null}
      {item.title}
      {item.key !== 'all' && (
        <span className="relationships-folders__folder--active-btns">
          <ActionsMenu
            id={`folder-${item.key}-actions`}
            itemTitle="Folder"
            onRemove={() => onRemoveClick(item.id)}
            actionsConfig={[
              {
                icon: 'threeDots',
                title: 'Rename',
                iconSize: 16,
                handler: () => setIsRenaming(true),
              },
            ]}
            portalSelector="body"
            tooltipClassName="react-tooltip--position-fixed"
          />
        </span>
      )}
    </div>
  );
}

export default FolderComponent;
