import React, { LegacyRef, useEffect, useRef, useState } from 'react';

import Checkbox from '@uikit/Checkbox/Checkbox';

import { daysHours, daysTitles } from '@components/Settings/ScheduleEdition/config';
import DayItem from '@components/Settings/ScheduleEdition/_components/DayItem';

import { ScheduleDayType } from '@ts/schedules.types';

import { parseFrom12to24Time } from '@utils/timeUtils';

const DayRow = ({
  day,
  timeFrom,
  timeTo,
  includeDay,
  onUpdateDay,
}: ScheduleDayType & { onUpdateDay: (any) => void }): JSX.Element => {
  const [selectedTime, changeSelectedTime] = useState({
    timeFrom: null,
    timeTo: null,
  });
  const [hoveredTimeTo, changeHoveredTimeTo] = useState(null);
  const cacheStartClick = useRef<string | null>(null);

  useEffect(() => {
    changeSelectedTime({ timeFrom, timeTo });
  }, [timeFrom, timeTo]);

  const getDataTimeFromAttrValue = (dataTimeAttrValue: string) => {
    const shortTimeArr = dataTimeAttrValue.split(' ');
    return parseFrom12to24Time(shortTimeArr[0], shortTimeArr[1] as 'am' | 'pm');
  };

  const getTimeFromEvent = (e) => {
    const dataTimeAttrValue = e.target.getAttribute('data-time');
    if (dataTimeAttrValue) {
      return getDataTimeFromAttrValue(dataTimeAttrValue);
    }

    return null;
  };

  const getTimeFromReference = (ref) => {
    const dataTimeAttrValue = ref.current.getAttribute('data-time');
    if (dataTimeAttrValue) {
      return getDataTimeFromAttrValue(dataTimeAttrValue);
    }

    return null;
  };

  const handleClick = (e) => {
    const time = getTimeFromEvent(e);

    if (time) {
      let tempTimeFrom = time;
      let tempTimeTo = time;

      if (!cacheStartClick.current) {
        cacheStartClick.current = time;
      } else {
        tempTimeFrom = cacheStartClick.current;
        cacheStartClick.current = null;
      }

      // Check if TO-time less than FROM-time -> reverse them
      if (Number(tempTimeFrom.split(':')[0]) > Number(tempTimeTo.split(':')[0])) {
        const temp = tempTimeFrom;
        tempTimeFrom = tempTimeTo;
        tempTimeTo = temp;
      }

      onUpdateDay({
        day,
        timeFrom: tempTimeFrom,
        timeTo: tempTimeTo,
        includeDay,
      });
    }
  };

  const handleHoverDay = (ref: LegacyRef<HTMLDivElement>) => {
    /*
      Make hover effect only if we clicked time from
     */
    if (cacheStartClick.current) {
      // @ts-ignore
      changeHoveredTimeTo(getTimeFromReference(ref));
    }
  };

  const checkIsHighlighted = (dayHour: number, dayTime: 'am' | 'pm') => {
    if (!hoveredTimeTo || !cacheStartClick.current) {
      return false;
    }

    const selectedTimeFromNum = Number(cacheStartClick.current.split(':')[0]);
    const hoveredTimeToNum = Number(hoveredTimeTo.split(':')[0]);
    const curTimeNum = Number(parseFrom12to24Time(dayHour, dayTime).split(':')[0]);

    const isForwardHighlighting = hoveredTimeToNum > selectedTimeFromNum;

    if (isForwardHighlighting) {
      return curTimeNum > selectedTimeFromNum && curTimeNum <= hoveredTimeToNum;
    }

    return curTimeNum < selectedTimeFromNum && curTimeNum >= hoveredTimeToNum;
  };

  const checkIsActive = (dayHour: number, dayTime: 'am' | 'pm') => {
    if (selectedTime.timeFrom === null || selectedTime.timeTo === null) {
      return false;
    }
    const selectedTimeFromNum = Number(selectedTime.timeFrom.split(':')[0]);
    const selectedTimeToNum = Number(selectedTime.timeTo.split(':')[0]);
    const curTimeNum = Number(parseFrom12to24Time(dayHour, dayTime).split(':')[0]);

    return curTimeNum >= selectedTimeFromNum && curTimeNum <= selectedTimeToNum;
  };

  const handleToggleDay = ({ target: { checked } }) =>
    onUpdateDay({
      day,
      timeFrom: selectedTime.timeFrom,
      timeTo: selectedTime.timeTo,
      includeDay: checked,
    });

  if (!selectedTime.timeTo || !selectedTime.timeFrom) {
    return null;
  }

  return (
    <div
      className="schedule-edition-days__grid-row schedule-days-grid-row"
      onClick={handleClick}
      tabIndex={0}
      role="button"
    >
      <div className="schedule-days-grid-row__day-name">
        <Checkbox value={includeDay} onChange={handleToggleDay} />
        {daysTitles[day]}
      </div>
      {daysHours.map((dayHour) => (
        <DayItem
          day={day}
          key={`${dayHour}-am`}
          time={`${dayHour} am`}
          isActive={checkIsActive(dayHour, 'am') && includeDay}
          isHighlighted={checkIsHighlighted(dayHour, 'am') && includeDay}
          onHover={handleHoverDay}
        />
      ))}
      {daysHours.map((dayHour) => (
        <DayItem
          day={day}
          key={`${dayHour}-pm`}
          time={`${dayHour} pm`}
          isActive={checkIsActive(dayHour, 'pm') && includeDay}
          isHighlighted={checkIsHighlighted(dayHour, 'pm') && includeDay}
          onHover={handleHoverDay}
        />
      ))}
    </div>
  );
};

export default DayRow;
