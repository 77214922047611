import React from 'react';

export default ({ color = '#5E5E5E' }): JSX.Element => (
  <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.75 9.625C2.75 10.3813 2.13125 11 1.375 11C0.61875 11 0 10.3813 0 9.625C0 8.86875 0.61875 8.25 1.375 8.25C2.13125 8.25 2.75 8.86875 2.75 9.625ZM1.375 4.125C0.61875 4.125 0 4.74375 0 5.5C0 6.25625 0.61875 6.875 1.375 6.875C2.13125 6.875 2.75 6.25625 2.75 5.5C2.75 4.74375 2.13125 4.125 1.375 4.125ZM1.375 0C0.61875 0 0 0.61875 0 1.375C0 2.13125 0.61875 2.75 1.375 2.75C2.13125 2.75 2.75 2.13125 2.75 1.375C2.75 0.61875 2.13125 0 1.375 0ZM5.5 2.75C6.25625 2.75 6.875 2.13125 6.875 1.375C6.875 0.61875 6.25625 0 5.5 0C4.74375 0 4.125 0.61875 4.125 1.375C4.125 2.13125 4.74375 2.75 5.5 2.75ZM5.5 4.125C4.74375 4.125 4.125 4.74375 4.125 5.5C4.125 6.25625 4.74375 6.875 5.5 6.875C6.25625 6.875 6.875 6.25625 6.875 5.5C6.875 4.74375 6.25625 4.125 5.5 4.125ZM5.5 8.25C4.74375 8.25 4.125 8.86875 4.125 9.625C4.125 10.3813 4.74375 11 5.5 11C6.25625 11 6.875 10.3813 6.875 9.625C6.875 8.86875 6.25625 8.25 5.5 8.25Z"
      fill={color}
    />
  </svg>
);
