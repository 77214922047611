import React, { useRef } from 'react';

import ReactTooltip from 'react-tooltip';

import Button from '@uikit/Button/Button';
import { SVGIcon } from '@uikit/Icon/Icon';

import './MoreActionsButton.scss';

type MoreActionsButtonPropsType = {
  onStop: () => void;
  // onUnsubscribe: () => void;
  isSmallScreen?: boolean;
};

function MoreActionsButton({
  onStop,
  // onUnsubscribe,
  isSmallScreen,
}: MoreActionsButtonPropsType): JSX.Element {
  const moreActionsTooltipRef = useRef();

  const handleMoreActionsClick = () => ReactTooltip.show(moreActionsTooltipRef.current);

  return (
    <>
      <Button
        innerRef={moreActionsTooltipRef}
        onClick={handleMoreActionsClick}
        data-for="peoples-more-actions"
        type="additional"
        className="people-analytic-header-actions__more-actions-btn"
        data-tip=""
        data-event="click"
      >
        <SVGIcon icon="actions" />
        {isSmallScreen ? null : 'More actions'}
      </Button>
      <ReactTooltip
        id="peoples-more-actions"
        className="react-tooltip"
        place="bottom"
        effect="solid"
        globalEventOff="click"
        arrowColor="transparent"
        clickable
      >
        <div
          className="people-header-more-actions-tooltip"
          onClick={(e) => e.stopPropagation()}
          role="button"
          tabIndex={0}
        >
          <div
            className="people-header-more-actions-tooltip__action"
            onClick={() => onStop()}
            role="button"
            tabIndex={0}
          >
            <SVGIcon icon="stopMessaging" />
            Stop
          </div>

          {/* <div */}
          {/*  className="people-header-more-actions-tooltip__action" */}
          {/*  onClick={() => onUnsubscribe()} */}
          {/*  role="button" */}
          {/*  tabIndex={0} */}
          {/* > */}
          {/*  <SVGIcon icon="banWhite" /> */}
          {/*  Unsubscribe */}
          {/* </div> */}
        </div>
      </ReactTooltip>
    </>
  );
}

export default MoreActionsButton;
