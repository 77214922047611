import React from 'react';
import { Button } from '@uikit/Button/Button';
import { SVGIcon } from '@uikit/Icon/Icon';

import './BlockHeader.scss';
import SwitcherInsight from '@components/Insights/SwitcherInsight/SwitcherInsight.';
import Select from '@uikit/Select/Select';

const BlockHeader = ({
  title,
  filters,
  selectOptions = [],
  selectedValue,
  onSelect,
  isActive,
  isActiveChangingFilters,
  handlerChangeSwitcher,
  isActiveSwitch,
}: {
  title: string;
  filters: boolean;
  selectOptions?: any[];
  selectedValue?: any;
  onSelect?: (value: any) => void;
  isActive: boolean;
  isActiveChangingFilters?: boolean;
  handlerChangeSwitcher?: (value) => void;
  isActiveSwitch?: boolean;
}): JSX.Element => {
  return (
    <div className="block-header">
      <h3 className="block-header_title">{title}</h3>
      {!!selectOptions.length && (
        <Select
          selectClass="block-header_title_select"
          value={selectedValue}
          options={selectOptions}
          placeholder=""
          onChange={onSelect}
        />
      )}
      {filters && (
        <div className="block-header-container">
          <SwitcherInsight
            handlerChangeSwitcher={handlerChangeSwitcher}
            isActiveSwitch={isActiveSwitch}
          />

          {isActiveChangingFilters && (
            <div className="block-header-container_buttons ml-10">
              <div className="block-header-container_buttons_item">
                <Button size="xs" type="graph">
                  <SVGIcon icon="layoutIcon" color={isActive ? '#221CB6' : '#BDBDBD'} />
                </Button>
              </div>
              <div className="block-header-container_buttons_item">
                <Button size="xs" type="graph" className={isActive ? 'button--blue' : ''}>
                  <SVGIcon icon="downloadDocument" color={isActive ? '#221CB6' : '#BDBDBD'} />
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default BlockHeader;
