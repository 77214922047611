import React from 'react';

export const MessageIcon = ({ color = '#BDBDBD', size = 20 }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.28571 5.56522C3.97339 5.56522 3.71429 5.80179 3.71429 6.08696V13.913C3.71429 14.1982 3.97339 14.4348 4.28571 14.4348H15.7143C16.0266 14.4348 16.2857 14.1982 16.2857 13.913V6.08696C16.2857 5.80179 16.0266 5.56522 15.7143 5.56522H4.28571ZM2 6.08696C2 4.93734 3.02661 4 4.28571 4H15.7143C16.9734 4 18 4.93734 18 6.08696V13.913C18 15.0627 16.9734 16 15.7143 16H4.28571C3.02661 16 2 15.0627 2 13.913V6.08696Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.15502 6.32677C2.42648 5.98022 2.96093 5.89594 3.34874 6.13853L10 10.2991L16.6513 6.13853C17.0391 5.89594 17.5735 5.98022 17.845 6.32677C18.1165 6.67332 18.0221 7.15092 17.6343 7.3935L10.4915 11.8615C10.1964 12.0462 9.80359 12.0462 9.50847 11.8615L2.36567 7.3935C1.97786 7.15092 1.88355 6.67332 2.15502 6.32677Z"
      fill={color}
    />
  </svg>
);

export default MessageIcon;
