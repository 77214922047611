import React, { useState } from 'react';

import { SVGIcon } from '@uikit/Icon/Icon';
import { Button } from '@uikit/Button/Button';

import './ExpandableRow.scss';

type Props = {
  children: JSX.Element[];
  maxCollapsed?: number;
  initiallyOpen?: boolean;
  isButtonView?: boolean;
};

const ExpandableRow = ({
  children,
  maxCollapsed = 3,
  initiallyOpen = false,
  isButtonView = false,
}: Props): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState<boolean>(initiallyOpen);

  const content = children?.slice(0, isExpanded ? undefined : maxCollapsed);

  return (
    <>
      {content?.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={index}>{item}</React.Fragment>
      ))}

      {children?.length > maxCollapsed && (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {!isButtonView ? (
            <Button
              type="ghost"
              onClick={(e) => {
                e.stopPropagation();
                setIsExpanded((value) => !value);
              }}
              className="expandable-row__show-more-btn"
            >
              <SVGIcon icon="actionHorizontal" />
            </Button>
          ) : (
            <Button
              className={`button-show-more ${isExpanded ? 'arrow-up' : ''}`}
              type="ghost"
              leftIcon="arrowDown"
              colorIcon="#221CB6"
              onClick={(e) => {
                e.stopPropagation();
                setIsExpanded((value) => !value);
              }}
            >
              {!isExpanded ? 'More' : 'Less'}
            </Button>
          )}
        </>
      )}
    </>
  );
};

export default ExpandableRow;
