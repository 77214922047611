import React from 'react';
import cn from 'class-names';

import { getDateShort } from '@utils/date';

import { NamedAvatar } from '@uikit/Avatar/Avatar';

import './MetaMailInfo.scss';

type MetaMailInfoProps = {
  fromName: string;
  fromEmail: string;
  toName: string;
  toEmail: string;
  dateMs: number;
  isFutureTimeFormat: boolean;
  ActionRenderer?: React.FC;
  children: React.ReactNode;
  sent?: boolean;
};

const MetaMailInfo = ({
  fromName,
  fromEmail,
  toName,
  toEmail,
  dateMs,
  isFutureTimeFormat,
  ActionRenderer,
  children,
  sent,
}: MetaMailInfoProps): JSX.Element => {
  return (
    <div className={cn('meta-mail-info', { 'meta-mail-info--sent': sent })}>
      <div className="meta-mail-info__header">
        <div className="meta-mail-info__header-left">
          <NamedAvatar userName={fromName} className="meta-mail-info__from-avatar" />
          <div>
            <div className="meta-mail-info__from-info">
              <span className="meta-mail-info__from-name">{fromName}</span>
              <span className="meta-mail-info__from-email">&#60;{fromEmail}&#62;</span>
            </div>
            <div>
              <span className="meta-mail-info__to-name">To: {toName}</span>
              <span className="meta-mail-info__to-email">&#60;{toEmail}&#62;</span>
            </div>
          </div>
        </div>
        <div className="meta-mail-info__date">
          {sent && <p style={{ textAlign: 'right' }}>Sent on</p>}
          {getDateShort(new Date(dateMs))}
        </div>
        <div className="meta-mail-info__actions">{ActionRenderer && <ActionRenderer />}</div>
      </div>
      <div className="meta-mail-info__content">{children}</div>
    </div>
  );
};

export default MetaMailInfo;
