import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, History } from 'react-router-dom';
import cn from 'classnames';

import {
  CampaignTemplateLevel,
  CampaignTemplatePreviewResponse,
  CampaignTemplateType,
} from 'respona_api/generated/campaign_pb';
import { campaignService } from 'src/services/campaignService';
import { DispatchType } from 'src/store';

import {
  changeFavoriteStateForCampaignTemplateApi,
  getAllCampaignTemplatesApi,
} from '@api/campaign.api';

import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';

import { Button } from '@uikit/Button/Button';
import Loader from '@uikit/Loader/Loader';
import { SVGIcon } from '@uikit/Icon/Icon';
import ConfigurableSidebar from '@uikit/ConfigurableSidebar/ConfigurableSidebar';

import {
  sidebarConfigUseCase,
  sidebarConfigLevel,
} from '@components/CampaignTemplates/CampaignTemplatesList/sidebarConfig';
import PageHeader from '@components/PageHeader/PageHeader';
import HeaderSearchInput from '@components/HeaderSearchInput/HeaderSearchInput';

import './CampaignTemplatesList.scss';

const templateTypeText = {
  [CampaignTemplateType.CONTENT_PROMOTION]: 'Content promotion',
  [CampaignTemplateType.PARTNER_RECRUITMENT]: 'Partner recruitment',
  [CampaignTemplateType.DIGITAL_PR]: 'Digital PR',
  [CampaignTemplateType.LINK_BUILDING]: 'Link building',
};

function CampaignTemplateAdvanced({
  onClick,
  isLoading,
}: {
  onClick: () => void;
  isLoading: boolean;
}) {
  return (
    <div
      className={cn('campaign-templates-list__advanced', {
        'campaign-templates-list__advanced--disabled': isLoading,
        'campaign-templates-list__advanced--preview': !onClick,
      })}
      onClick={onClick}
    >
      {isLoading ? (
        <Loader isLoading style={{ width: '29px', height: '29px' }} />
      ) : (
        <SVGIcon icon="plusIcon" size={29} color="#8d8f92" />
      )}

      <div className="campaign-templates-list__advanced-text">
        Start{isLoading ? 'ing' : ''} advanced
        <br />
        campaign
      </div>
    </div>
  );
}

export function CampaignTemplatePreview({
  preview,
  history,
  toggleFavourite,
  ...props
}: CampaignTemplatePreviewResponse.AsObject & {
  preview?: boolean;
  history: History;
  toggleFavourite: (id: number) => Promise<boolean>;
}) {
  const [isFavourite, setIsFavourite] = useState<boolean>(props.favorite);
  const [isSettingFavourite, setIsSettingFavourite] = useState<boolean>(false);
  const location = useLocation();

  const onOpenHandler = () => {
    if (!preview) {
      const query = new URLSearchParams(location.search);
      const folderId = query.get('folderId');
      if (folderId?.length > 0) {
        history.push(`/campaign-templates/${props.id}?folderId=${folderId}`);
      } else {
        history.push(`/campaign-templates/${props.id}`);
      }
    }
  };

  return (
    <div className="campaign-templates-list__preview" onClick={() => onOpenHandler()}>
      <div className="campaign-templates-list__preview-image">
        <img src={props.icon} alt="" />
      </div>

      <div className="campaign-templates-list__preview-info-container">
        <div className="campaign-templates-list__preview-info-title">{props.title}</div>
        <div className="campaign-templates-list__preview-info-subtitle">{props.description}</div>

        <div className="campaign-templates-list__preview-info-row">
          <div className="campaign-templates-list__preview-info-tag">
            {templateTypeText[props.type]}
            <span
              className={cn('campaign-templates-list__preview-info-tag-dot', {
                'campaign-templates-list__preview-info-tag-dot--red':
                  props.level === CampaignTemplateLevel.ADVANCED,
                'campaign-templates-list__preview-info-tag-dot--orange':
                  props.level === CampaignTemplateLevel.INTERMEDIATE,
              })}
            />
          </div>

          <div
            className={cn('campaign-templates-list__preview-info-fav-icon', {
              'campaign-templates-list__preview-info-fav-icon--filled': isFavourite,
              'campaign-templates-list__preview-info-fav-icon--disabled': isSettingFavourite,
            })}
            onClick={(e) => {
              if (preview) {
                return;
              }

              e.stopPropagation();
              setIsSettingFavourite(true);
              setIsFavourite((val) => !val);
              toggleFavourite(props.id).finally(() => setIsSettingFavourite(false));
            }}
          >
            <SVGIcon icon={isFavourite ? 'starFilled' : 'star'} size={15} />
          </div>
        </div>
      </div>
    </div>
  );
}

function CampaignTemplatesList({ mockData }: { mockData?: any[] }) {
  const history = useHistory();

  const dispatch = useDispatch<DispatchType>();
  const location = useLocation();
  const workspaceId = useCurrentWorkspaceId();

  const [isCreatingCampaign, setIsCreatingCampaign] = useState<boolean>();
  const [searchString, setSearchString] = useState('');
  const [useCaseFilter, setUseCaseFilter] = useState<number>(-1);
  const [levelFilter, setLevelFilter] = useState<number>(-1);

  const { data, isLoading } = useQuery<CampaignTemplatePreviewResponse.AsObject[]>(
    'campaign-templates',
    () => getAllCampaignTemplatesApi(),
    {
      enabled: !mockData,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const startNewCampaign = async () => {
    setIsCreatingCampaign(true);
    try {
      const query = new URLSearchParams(location.search);
      const folderId = query.get('folderId');
      const newCampaign = await campaignService.create(
        dispatch,
        workspaceId,
        folderId?.length > 0 ? Number(folderId) : null
      );
      history.push(`/campaign-builder/${newCampaign.id}/opportunities?newCampaign=true`);
    } catch (err) {
      console.error(err);
    } finally {
      setIsCreatingCampaign(false);
    }
  };

  const toggleFavourite = async (templateId: number): Promise<boolean> => {
    try {
      const res = await changeFavoriteStateForCampaignTemplateApi(templateId, workspaceId);
      // queryClient.setQueryData(
      //   'campaign-templates',
      //   createUpdaterCacheItemById(templateId, 'favorite', res?.value)
      // );
      return res?.value as boolean;
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="campaign-templates-list__container">
      <div className="campaign-templates-list__sidebar">
        <ConfigurableSidebar
          SidebarHeader={() => <span>Use case</span>}
          config={sidebarConfigUseCase}
          renderProps={{ useCaseFilter, setUseCaseFilter }}
        />

        <ConfigurableSidebar
          SidebarHeader={() => <span>Level</span>}
          config={sidebarConfigLevel}
          renderProps={{ levelFilter, setLevelFilter }}
        />
      </div>

      <div className="campaign-templates-list">
        <PageHeader
          title="Select a campaign template"
          renderHeaderActions={() => (
            <div className="campaign-templates-list__header">
              <HeaderSearchInput
                onUpdateSearchQuery={(data) => (!mockData ? setSearchString(data) : null)}
                searchQuery={searchString}
                placeholder="Search"
                debounceTime={600}
              />

              <Button
                isLoading={isCreatingCampaign}
                loadingText="Starting campaign..."
                className="campaigns-header__button"
                onClick={() => (mockData ? null : startNewCampaign())}
              >
                + Start advanced campaign
              </Button>
            </div>
          )}
        />

        <Loader withTopMargin isLoading={isLoading}>
          <div className="campaign-templates-list__content">
            <CampaignTemplateAdvanced
              onClick={mockData ? null : startNewCampaign}
              isLoading={isCreatingCampaign}
            />
            {(mockData ?? data)
              // ?.sort((a ,b) => ('' + a.title).localeCompare(b.title))
              ?.sort((a, b) => a.level - b.level)
              ?.filter((item) => (useCaseFilter >= 0 ? item.type === useCaseFilter : true))
              ?.filter((item) => (levelFilter >= 0 ? item.level === levelFilter : true))
              ?.filter((item) =>
                searchString ? item.title.toUpperCase().includes(searchString.toUpperCase()) : true
              )
              ?.sort((a) => (a.favorite ? -1 : 1))
              ?.map((item) => (
                <CampaignTemplatePreview
                  key={item.id}
                  {...item}
                  history={history}
                  toggleFavourite={toggleFavourite}
                  preview={!!mockData}
                />
              ))}
          </div>
        </Loader>
      </div>
    </div>
  );
}

export default CampaignTemplatesList;
