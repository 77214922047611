import React from 'react';

export default ({
  color = '#212121',
  size = 19,
}: {
  color: string;
  size?: number;
}): JSX.Element => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.775 7.54988C16.925 8.14988 17 8.82488 17 9.49988C17 13.6249 13.625 16.9999 9.5 16.9999C5.375 16.9999 2 13.6249 2 9.49988C2 5.37488 5.375 1.99988 9.5 1.99988C10.925 1.99988 12.275 2.37488 13.475 3.12488"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 8.09079L9.93333 10.9999L18 2.99988"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
