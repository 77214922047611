import React from 'react';

function RelationshipsPiplenesIllustration(): JSX.Element {
  return (
    <svg
      width="156"
      height="183"
      viewBox="0 0 156 183"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M154.569 2H1.43119C0.640767 2 0 2.644 0 3.43841V181.562C0 182.356 0.640767 183 1.43119 183H154.569C155.359 183 156 182.356 156 181.562V3.43841C156 2.644 155.359 2 154.569 2Z"
        fill="#F2F6FB"
      />
      <path
        d="M0 4.09181C0 1.83196 2.13485 0 4.77 0H151.23C153.864 0 156 1.83213 156 4.09181V9H0V4.09181Z"
        fill="#D8DEED"
      />
      <path
        d="M5.77544 6.78749C6.8865 6.78749 7.7872 5.88367 7.7872 4.76875C7.7872 3.65382 6.8865 2.75 5.77544 2.75C4.66437 2.75 3.76367 3.65382 3.76367 4.76875C3.76367 5.88367 4.66437 6.78749 5.77544 6.78749Z"
        fill="#FF5645"
      />
      <path
        d="M12.3418 6.78749C13.4529 6.78749 14.3536 5.88367 14.3536 4.76875C14.3536 3.65382 13.4529 2.75 12.3418 2.75C11.2308 2.75 10.3301 3.65382 10.3301 4.76875C10.3301 5.88367 11.2308 6.78749 12.3418 6.78749Z"
        fill="#FFCB3D"
      />
      <path
        d="M19.1172 6.78749C20.2283 6.78749 21.129 5.88367 21.129 4.76875C21.129 3.65382 20.2283 2.75 19.1172 2.75C18.0062 2.75 17.1055 3.65382 17.1055 4.76875C17.1055 5.88367 18.0062 6.78749 19.1172 6.78749Z"
        fill="#3ABF7C"
      />
      <path
        d="M8 98.0687C8 95.7364 9.89068 93.8457 12.223 93.8457H144.286C146.619 93.8457 148.509 95.7364 148.509 98.0687V127.629C148.509 129.962 146.619 131.852 144.286 131.852H12.223C9.89068 131.852 8 129.962 8 127.629V98.0687Z"
        fill="white"
      />
      <path
        d="M8 56.223C8 53.8907 9.89068 52 12.223 52H144.286C146.619 52 148.509 53.8907 148.509 56.223V85.7837C148.509 88.1159 146.619 90.0066 144.286 90.0066H12.223C9.89068 90.0066 8 88.1159 8 85.7837V56.223Z"
        fill="white"
      />
      <path
        d="M8 139.914C8 137.582 9.89068 135.691 12.223 135.691H144.286C146.619 135.691 148.509 137.582 148.509 139.914V169.859C148.509 172.191 146.619 174.082 144.286 174.082H12.223C9.89068 174.082 8 172.191 8 169.859V139.914Z"
        fill="white"
      />
      <path
        d="M28.7305 105.363C28.7305 104.727 29.2461 104.211 29.8822 104.211H62.1302C62.7663 104.211 63.2819 104.727 63.2819 105.363C63.2819 105.999 62.7663 106.514 62.1302 106.514H29.8822C29.2461 106.514 28.7305 105.999 28.7305 105.363Z"
        fill="#BEC8D7"
      />
      <path
        d="M28.7305 63.5169C28.7305 62.8809 29.2461 62.3652 29.8822 62.3652H62.1302C62.7663 62.3652 63.2819 62.8809 63.2819 63.5169C63.2819 64.153 62.7663 64.6687 62.1302 64.6687H29.8822C29.2461 64.6687 28.7305 64.153 28.7305 63.5169Z"
        fill="#BEC8D7"
      />
      <path
        d="M28.7305 147.208C28.7305 146.572 29.2461 146.057 29.8822 146.057H62.1302C62.7663 146.057 63.2819 146.572 63.2819 147.208C63.2819 147.844 62.7663 148.36 62.1302 148.36H29.8822C29.2461 148.36 28.7305 147.844 28.7305 147.208Z"
        fill="#BEC8D7"
      />
      <path
        d="M17.5977 105.363C17.5977 104.303 18.4571 103.443 19.5172 103.443H22.5884C23.6485 103.443 24.5079 104.303 24.5079 105.363V108.434C24.5079 109.494 23.6485 110.354 22.5884 110.354H19.5172C18.4571 110.354 17.5977 109.494 17.5977 108.434V105.363Z"
        fill="#E1E6EE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5884 104.979H19.5172C19.3052 104.979 19.1333 105.151 19.1333 105.363V108.434C19.1333 108.646 19.3052 108.818 19.5172 108.818H22.5884C22.8004 108.818 22.9723 108.646 22.9723 108.434V105.363C22.9723 105.151 22.8004 104.979 22.5884 104.979ZM19.5172 103.443C18.4571 103.443 17.5977 104.303 17.5977 105.363V108.434C17.5977 109.494 18.4571 110.354 19.5172 110.354H22.5884C23.6485 110.354 24.5079 109.494 24.5079 108.434V105.363C24.5079 104.303 23.6485 103.443 22.5884 103.443H19.5172Z"
        fill="#E1E6EE"
      />
      <path
        d="M17.5977 63.5172C17.5977 62.4571 18.4571 61.5977 19.5172 61.5977H22.5884C23.6485 61.5977 24.5079 62.4571 24.5079 63.5172V66.5884C24.5079 67.6485 23.6485 68.5079 22.5884 68.5079H19.5172C18.4571 68.5079 17.5977 67.6485 17.5977 66.5884V63.5172Z"
        fill="#E1E6EE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5884 63.1333H19.5172C19.3052 63.1333 19.1333 63.3052 19.1333 63.5172V66.5884C19.1333 66.8004 19.3052 66.9723 19.5172 66.9723H22.5884C22.8004 66.9723 22.9723 66.8004 22.9723 66.5884V63.5172C22.9723 63.3052 22.8004 63.1333 22.5884 63.1333ZM19.5172 61.5977C18.4571 61.5977 17.5977 62.4571 17.5977 63.5172V66.5884C17.5977 67.6485 18.4571 68.5079 19.5172 68.5079H22.5884C23.6485 68.5079 24.5079 67.6485 24.5079 66.5884V63.5172C24.5079 62.4571 23.6485 61.5977 22.5884 61.5977H19.5172Z"
        fill="#E1E6EE"
      />
      <path
        d="M17.5977 147.209C17.5977 146.148 18.4571 145.289 19.5172 145.289H22.5884C23.6485 145.289 24.5079 146.148 24.5079 147.209V150.28C24.5079 151.34 23.6485 152.199 22.5884 152.199H19.5172C18.4571 152.199 17.5977 151.34 17.5977 150.28V147.209Z"
        fill="#E1E6EE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5884 146.825H19.5172C19.3052 146.825 19.1333 146.997 19.1333 147.209V150.28C19.1333 150.492 19.3052 150.664 19.5172 150.664H22.5884C22.8004 150.664 22.9723 150.492 22.9723 150.28V147.209C22.9723 146.997 22.8004 146.825 22.5884 146.825ZM19.5172 145.289C18.4571 145.289 17.5977 146.148 17.5977 147.209V150.28C17.5977 151.34 18.4571 152.199 19.5172 152.199H22.5884C23.6485 152.199 24.5079 151.34 24.5079 150.28V147.209C24.5079 146.148 23.6485 145.289 22.5884 145.289H19.5172Z"
        fill="#E1E6EE"
      />
      <path
        d="M28.7305 110.162C28.7305 109.42 29.332 108.818 30.0741 108.818H76.5267C77.2688 108.818 77.8703 109.42 77.8703 110.162C77.8703 110.904 77.2688 111.506 76.5267 111.506H30.0741C29.332 111.506 28.7305 110.904 28.7305 110.162Z"
        fill="#E1E6EE"
      />
      <path
        d="M28.7305 68.3163C28.7305 67.5742 29.332 66.9727 30.0741 66.9727H76.5267C77.2688 66.9727 77.8703 67.5742 77.8703 68.3163C77.8703 69.0584 77.2688 69.66 76.5267 69.66H30.0741C29.332 69.66 28.7305 69.0584 28.7305 68.3163Z"
        fill="#E1E6EE"
      />
      <path
        d="M28.7305 152.199C28.7305 151.563 29.2461 151.047 29.8822 151.047H76.7186C77.3547 151.047 77.8703 151.563 77.8703 152.199C77.8703 152.835 77.3547 153.35 76.7186 153.35H29.8822C29.2461 153.35 28.7305 152.835 28.7305 152.199Z"
        fill="#E1E6EE"
      />
      <path
        d="M28.7305 114.193C28.7305 113.557 29.2461 113.041 29.8822 113.041H83.245C83.8811 113.041 84.3967 113.557 84.3967 114.193C84.3967 114.829 83.8811 115.344 83.245 115.344H29.8822C29.2461 115.344 28.7305 114.829 28.7305 114.193Z"
        fill="#E1E6EE"
      />
      <path
        d="M28.7305 72.347C28.7305 71.711 29.2461 71.1953 29.8822 71.1953H83.245C83.8811 71.1953 84.3967 71.711 84.3967 72.347C84.3967 72.9831 83.8811 73.4987 83.245 73.4987H29.8822C29.2461 73.4987 28.7305 72.9831 28.7305 72.347Z"
        fill="#E1E6EE"
      />
      <path
        d="M28.7305 157.189C28.7305 156.553 29.2461 156.037 29.8822 156.037H83.245C83.8811 156.037 84.3967 156.553 84.3967 157.189C84.3967 157.825 83.8811 158.341 83.245 158.341H29.8822C29.2461 158.341 28.7305 157.825 28.7305 157.189Z"
        fill="#E1E6EE"
      />
      <path
        d="M28.7305 121.679C28.7305 121.148 29.1602 120.719 29.6902 120.719H41.9752C42.5053 120.719 42.935 121.148 42.935 121.679C42.935 122.209 42.5053 122.638 41.9752 122.638H29.6902C29.1602 122.638 28.7305 122.209 28.7305 121.679Z"
        fill="#E1E6EE"
      />
      <path
        d="M28.7305 79.8328C28.7305 79.3027 29.1602 78.873 29.6902 78.873H41.9752C42.5053 78.873 42.935 79.3027 42.935 79.8328C42.935 80.3629 42.5053 80.7926 41.9752 80.7926H29.6902C29.1602 80.7926 28.7305 80.3629 28.7305 79.8328Z"
        fill="#E1E6EE"
      />
      <path
        d="M28.7305 164.677C28.7305 164.146 29.1602 163.717 29.6902 163.717H41.9752C42.5053 163.717 42.935 164.146 42.935 164.677C42.935 165.207 42.5053 165.636 41.9752 165.636H29.6902C29.1602 165.636 28.7305 165.207 28.7305 164.677Z"
        fill="#E1E6EE"
      />
      <path
        d="M44.4707 121.679C44.4707 121.148 44.9004 120.719 45.4305 120.719H70.3843C70.9144 120.719 71.3441 121.148 71.3441 121.679C71.3441 122.209 70.9144 122.638 70.3843 122.638H45.4305C44.9004 122.638 44.4707 122.209 44.4707 121.679Z"
        fill="#E1E6EE"
      />
      <path
        d="M44.4707 79.8328C44.4707 79.3027 44.9004 78.873 45.4305 78.873H70.3843C70.9144 78.873 71.3441 79.3027 71.3441 79.8328C71.3441 80.3629 70.9144 80.7926 70.3843 80.7926H45.4305C44.9004 80.7926 44.4707 80.3629 44.4707 79.8328Z"
        fill="#E1E6EE"
      />
      <path
        d="M44.4707 164.677C44.4707 164.146 44.9004 163.717 45.4305 163.717H70.3843C70.9144 163.717 71.3441 164.146 71.3441 164.677C71.3441 165.207 70.9144 165.636 70.3843 165.636H45.4305C44.9004 165.636 44.4707 165.207 44.4707 164.677Z"
        fill="#E1E6EE"
      />
      <path
        d="M37.0371 27.406C37.0371 26.7729 37.5503 26.2598 38.1833 26.2598H70.2768C70.9098 26.2598 71.423 26.7729 71.423 27.406C71.423 28.039 70.9098 28.5522 70.2768 28.5522H38.1833C37.5503 28.5522 37.0371 28.039 37.0371 27.406Z"
        fill="#BEC8D7"
      />
      <path
        d="M29.7773 30.4606C29.7773 29.8276 30.2905 29.3145 30.9235 29.3145H63.017C63.65 29.3145 64.1632 29.8276 64.1632 30.4606C64.1632 31.0937 63.65 31.6068 63.017 31.6068H30.9235C30.2905 31.6068 29.7773 31.0937 29.7773 30.4606Z"
        fill="#BEC8D7"
      />
      <path
        d="M8 23.2027C8 20.8816 9.88162 19 12.2027 19H144.397C146.718 19 148.6 20.8816 148.6 23.2027V37.7212C148.6 40.0423 146.718 41.9239 144.397 41.9239H12.2027C9.88162 41.9239 8 40.0423 8 37.7212V23.2027Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.4781 30.2855C21.4781 31.6065 20.4072 32.6773 19.0863 32.6773C17.7654 32.6773 16.6946 31.6065 16.6946 30.2855C16.6946 28.9646 17.7654 27.8938 19.0863 27.8938C20.4072 27.8938 21.4781 28.9646 21.4781 30.2855ZM21.0759 32.9127C20.523 33.332 19.8337 33.5809 19.0863 33.5809C17.2664 33.5809 15.791 32.1055 15.791 30.2855C15.791 28.4656 17.2664 26.9902 19.0863 26.9902C20.9063 26.9902 22.3816 28.4656 22.3816 30.2855C22.3816 31.0323 22.1333 31.721 21.7146 32.2736L23.0684 33.6273C23.2448 33.8038 23.2448 34.0898 23.0684 34.2663C22.8919 34.4427 22.6059 34.4427 22.4295 34.2663L21.0759 32.9127Z"
        fill="#BEC8D7"
      />
      <path
        d="M33.5977 30.4606C33.5977 29.8276 34.1108 29.3145 34.7439 29.3145H88.9971C89.6301 29.3145 90.1433 29.8276 90.1433 30.4606C90.1433 31.0937 89.6301 31.6068 88.9971 31.6068H34.7439C34.1108 31.6068 33.5977 31.0937 33.5977 30.4606Z"
        fill="#BEC8D7"
      />
    </svg>
  );
}

export default RelationshipsPiplenesIllustration;
