import React from 'react';

export default ({ color = 'white', size = 14 }): JSX.Element => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.66667 0.000488281C5.34813 0.000488281 4.0592 0.391481 2.96287 1.12402C1.86654 1.85657 1.01206 2.89776 0.507473 4.11593C0.00288856 5.33411 -0.129134 6.67455 0.128101 7.96776C0.385336 9.26096 1.02027 10.4489 1.95262 11.3812C2.88497 12.3136 4.07286 12.9485 5.36607 13.2057C6.65927 13.463 7.99972 13.3309 9.21789 12.8264C10.4361 12.3218 11.4773 11.4673 12.2098 10.371C12.9423 9.27463 13.3333 7.9857 13.3333 6.66716C13.3333 5.79168 13.1609 4.92477 12.8259 4.11593C12.4908 3.30709 11.9998 2.57217 11.3807 1.95311C10.7617 1.33405 10.0267 0.842989 9.21789 0.507958C8.40905 0.172927 7.54215 0.000488281 6.66667 0.000488281ZM6.66667 12.0005C5.25218 12.0005 3.89563 11.4386 2.89543 10.4384C1.89524 9.4382 1.33334 8.08164 1.33334 6.66716C1.33186 5.48272 1.72868 4.33219 2.46 3.40049L9.93334 10.8738C9.00164 11.6051 7.85111 12.002 6.66667 12.0005ZM10.8733 9.93382L3.4 2.46049C4.3317 1.72917 5.48223 1.33235 6.66667 1.33382C8.08116 1.33382 9.43771 1.89572 10.4379 2.89592C11.4381 3.89611 12 5.25267 12 6.66716C12.0015 7.85159 11.6047 9.00212 10.8733 9.93382Z"
      fill={color}
    />
  </svg>
);
