// eslint-disable-next-line no-shadow
export enum ParsingDelimiterEnum {
  AUTO_DELIMITER = 0,
  TAB_DELIMITER = 1,
  COMA_DELIMITER = 2,
  SEMICOLON_DELIMITER = 3,
  SPACE_DELIMITER = 4,
  OTHER_DELIMITER = 5,
}

export enum ImportModeEnum {
  PERSON = 0,
  OPPORTUNITY = 1,
}

// eslint-disable-next-line no-shadow
export enum ContactVariableEnum {
  UNDEFINED_VARIABLE = 0,
  URL_VARIABLE = 1,
  URL_TITLE_VARIABLE = 2,
  FIRST_NAME_VARIABLE = 3,
  FULL_NAME_VARIABLE = 4,
  LAST_NAME_VARIABLE = 5,
  EMAIL_VARIABLE = 6,
  EMAIL_2_VARIABLE = 7,
  EMAIL_3_VARIABLE = 8,
  EMAIL_4_VARIABLE = 9,
  EMAIL_5_VARIABLE = 10,
  LOCATION_VARIABLE = 11,
  JOB_TITLE_VARIABLE = 12,
  ORGANIZATION_VARIABLE = 13,
  LINKEDIN_URL_VARIABLE = 14,
  TAG_VARIABLE = 15,
}

export type ParseFileFieldResponseType = {
  title: string;
  mappedVariable: ContactVariableEnum;
  optionalCustomVariableId: number;
};

export type ParseFileResponseType = {
  headersList: ParseFileFieldResponseType[];
  rowNumber: number;
};

export type FileUploadResponseType = {
  uid: string;
  title: string;
  progress: number;
};
