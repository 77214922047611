import React from 'react';

const SidebarDashBoardIcon = ({ color = '#221CB6' }) => (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.41784 4.64074C6.77447 4.55824 7.13942 4.74072 7.28741 5.07552C7.32617 5.15427 7.34948 5.23971 7.35606 5.32724C7.43233 6.41038 7.59252 8.78261 7.68405 10.0641C7.69968 10.2949 7.80666 10.51 7.98131 10.6618C8.15596 10.8135 8.38388 10.8894 8.61464 10.8726L13.321 10.5828C13.5311 10.5701 13.7371 10.6449 13.8902 10.7893C14.0433 10.9338 14.1299 11.1351 14.1295 11.3455C13.9388 14.1875 11.8969 16.5634 9.11593 17.1792C6.33493 17.7951 3.4809 16.5034 2.10816 14.0076C1.69836 13.2893 1.43885 12.4952 1.34538 11.6735C1.30542 11.4214 1.29009 11.1659 1.29961 10.9108C1.30771 7.87576 3.4392 5.26071 6.41021 4.64074M16.9365 7.12739C16.404 3.84956 13.6198 1.41103 10.3004 1.31503C10.1426 1.30885 9.98874 1.36567 9.87285 1.47298C9.75695 1.58029 9.68848 1.72928 9.68253 1.88711V1.94051L10.0563 7.53166C10.0808 7.90804 10.4044 8.19414 10.7809 8.17239L16.3873 7.79863C16.5453 7.78695 16.6921 7.71275 16.7952 7.59248C16.8983 7.47222 16.9492 7.3158 16.9365 7.1579V7.12739Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SidebarDashBoardIcon;
