import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'class-names';

import { PRIMARY_COLOR } from '@constants/colors';

import { SVGIcon, SvgIconType } from '@uikit/Icon/Icon';
import { RenderDefaultCampaignGroup } from './ConfigurableCampaignSidebar';

import ActionsMenu from '@components/ActionsMenu/ActionsMenu';

import './ConfigurableCampaignSidebarLink.scss';

type RenderDefaultLinksPropsType = {
  title: string | React.ReactNode;
  icon?: SvgIconType;
  url?: string;
  isActive?: boolean;
  isCropped?: boolean;
  RenderActions?: () => any;
  iconComponent?: React.ReactNode;
  onClick?: () => void;
};

export const RenderFolderLink = ({
  id,
  folderId,
  title,
  activePage,
  onRemove,
  onRename,
  urlPath,
}) => {
  const isFolderIdTheSameInQuery = Number(folderId) === id;
  const isActivePageUrlPathOfGroup = activePage === urlPath;

  // TODO: To make active also in organizations.
  return (
    <RenderConfigurableCampaignSidebarLink
      key={id}
      title={title}
      url={`/relationships/${urlPath}?folderId=${id}`}
      isActive={isFolderIdTheSameInQuery && isActivePageUrlPathOfGroup}
      icon="folder"
      RenderActions={() => (
        <ActionsMenu
          id={`${urlPath}-folder-${id}-actions`}
          itemTitle={title}
          onRemove={onRemove}
          onRename={onRename}
        />
      )}
    />
  );
};

export const DefaultLink = ({ onClick = undefined, children, url = '', isActive }): JSX.Element => {
  const cnDefaultLink = cn('configurable-campaign-sidebar__link', {
    'configurable-campaign-sidebar__link--active': isActive,
  });

  if (url) {
    return (
      <Link to={url} className={cnDefaultLink}>
        {children}
      </Link>
    );
  }
  return (
    <div onClick={onClick} className={cnDefaultLink}>
      {children}
    </div>
  );
};

export const RenderConfigurableCampaignSidebarLink = ({
  title,
  icon,
  iconComponent,
  url = '',
  isActive = false,
  isCropped = false,
  RenderActions,
  onClick,
}: RenderDefaultLinksPropsType): JSX.Element => {
  const cnDefaultLinkWrapper = cn('configurable-campaign-sidebar__link-wrapper', {
    'configurable-campaign-sidebar__link-wrapper--active': isActive,
  });

  const cnDefaultLinkText = cn('configurable-campaign-sidebar__link-text', {
    'configurable-campaign-sidebar__link-text--cropped': isCropped,
  });

  return (
    <div className={cnDefaultLinkWrapper}>
      <DefaultLink onClick={onClick} url={url} isActive={isActive}>
        <span className="configurable-campaign-sidebar__link-icon">
          {iconComponent ||
            (icon ? (
              <SVGIcon color={isActive ? PRIMARY_COLOR : '#BDBDBD'} icon={icon as SvgIconType} />
            ) : null)}
        </span>
        <span className={cnDefaultLinkText}>{title}</span>
      </DefaultLink>
      {RenderActions ? (
        <span className="configurable-campaign-sidebar__link-actions">
          <RenderActions />
        </span>
      ) : null}
    </div>
  );
};

export const DefaultCampaignGroup = ({ title, children }) => (
  <div className="configurable-campaign-sidebar__group">
    <div className="configurable-campaign-sidebar__group-title">{title}</div>
    <div className="configurable-campaign-sidebar__group-pages">{children}</div>
  </div>
);

export const RenderCampaignGroup = ({
  title,
  RenderGroupLinks,
  pages,
  renderProps,
  groupActionClick,
}): JSX.Element => (
  <div className="configurable-campaign-sidebar__group">
    <div className="configurable-campaign-sidebar__group-pages">
      {RenderGroupLinks ? (
        <RenderGroupLinks renderProps={renderProps}/>
      ) : (
        <RenderDefaultCampaignGroup title={title} renderProps={renderProps} pages={pages}/>
      )}
    </div>
    {renderProps.isNewFolderCreation ? null : (
      <div className="configurable-campaign-sidebar__group-title" onClick={() => groupActionClick(renderProps)}>
        <span
          className="configurable-campaign-sidebar__group-action-icon"
          role="button"
          tabIndex={0}
        >
          <SVGIcon icon="plusIcon" color="#BDBDBD" size={13}/>
        </span>
        {title}
      </div>
    )}
  </div>
);
