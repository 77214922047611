import {
  InboxAccountsPresetType,
  ThreadPreviewResponseType,
  OutboxPreviewResponseType,
} from '@ts/mailboxInbox.types';
import mailboxService from '@api/services/mailboxService';
import streamRequest from '@api/base/streamRequest';
import { GlobalInboxFilterRequest, ThreadPreviewRequest } from 'respona_api/generated/mailbox_pb';
import CommonModels from 'respona_api/generated/common_pb';

export type ThreadPreviewApiProps = ThreadPreviewRequest.AsObject;

export const getThreadPreviewsApi = (
  props: ThreadPreviewApiProps
): Promise<ThreadPreviewResponseType[]> => {
  const client = mailboxService.getInstance();

  return streamRequest<ThreadPreviewResponseType[]>(
    client,
    client.getThreadPreviews,
    createInboxEmailsRequest(props)
  );
};

export const getGlobalOutboxPreviewEmailsApi = (
  props: ThreadPreviewApiProps
): Promise<OutboxPreviewResponseType[]> => {
  const client = mailboxService.getInstance();

  return streamRequest<OutboxPreviewResponseType[]>(
    client,
    client.getGlobalOutboxPreviewEmails,
    createInboxEmailsRequest(props)
  );
};

export const getInboxAccountsPresetByWorkspaceIdApi = (
  workspaceId: number
): Promise<InboxAccountsPresetType[]> => {
  const client = mailboxService.getInstance();
  const idRequest = new CommonModels.IdRequest();

  idRequest.setId(workspaceId);
  idRequest.setOptionalWorkspaceId(workspaceId);

  return streamRequest<InboxAccountsPresetType[]>(
    client,
    client.getInboxAccountsPresetByWorkspaceId,
    idRequest
  );
};

function createInboxEmailsRequest({
  page,
  limit,
  workspaceId,
  mailboxIdsList,
  label,
  status,
  globalSearchQuery,
  sortDirection = 0,
  filter,
}: ThreadPreviewApiProps) {
  const request = new ThreadPreviewRequest();
  request.setPage(page);
  request.setLimit(limit);
  request.setWorkspaceId(workspaceId);
  request.setSortDirection(sortDirection);
  request.setStatus(status);
  request.setGlobalSearchQuery(globalSearchQuery);

  if (mailboxIdsList?.length) {
    request.setMailboxIdsList(mailboxIdsList);
  }

  if (label) {
    request.setLabel(label);
  }

  if (filter) {
    const {
      subjectQuery,
      contentQuery,
      fromEmail,
      toEmail,
      campaignId,
      onlyRelatedToCampaign,
      tagIdsList,
    } = filter;

    const filterRequest = new GlobalInboxFilterRequest();

    if (campaignId) filterRequest.setCampaignId(campaignId);
    if (fromEmail) filterRequest.setFromEmail(fromEmail);
    if (toEmail) filterRequest.setToEmail(toEmail);
    if (subjectQuery) filterRequest.setSubjectQuery(subjectQuery);
    if (contentQuery) filterRequest.setContentQuery(contentQuery);
    if (tagIdsList) filterRequest.setTagIdsList(tagIdsList);
    filterRequest.setOnlyRelatedToCampaign(onlyRelatedToCampaign);

    request.setFilter(filterRequest);
  }
  return request;
}
