import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import cn from 'class-names';

import {
  searchFiltersSelector,
  getCampaignSettingsViewState,
  getSourceType,
} from '@redux/selectors/campaignSettings.selectors';
import { appActionsSelector } from '@redux/selectors/appActions.selectors';
import { csSetFilterValue } from '@redux/actions/campaignSettings.actions';
import Display from '@components/Display/Display';
import Button from '@uikit/Button/Button';

import SearchFilter from './_components/SearchFilter/SearchFilter';

import filters from './filtersConfig';

import './Filters.scss';

const Filters = ({ activeStep }: { activeStep: string }): JSX.Element => {
  const dispatch = useDispatch();
  const [openedFilters, changeOpenedFilters] = useState([]);
  const [isApplyActive, changeIsApplyActive] = useState(false);
  const isFiltersOpen =
    useSelector(getCampaignSettingsViewState).isFiltersOpen && activeStep === 'content';
  const filtersValues = useSelector(searchFiltersSelector);
  const sourceType = useSelector(getSourceType);
  const { makeContentSearchRequest } = useSelector(appActionsSelector);

  const cnFilters = cn('campaign-creation-filters', {
    'campaign-creation-filters--opened': isFiltersOpen,
  });

  useEffect(() => {
    changeOpenedFilters(Object.keys(filtersValues));
  }, [filtersValues]);

  const handleToggleFilter = (filterKey) => {
    let defaultValue;
    if (!openedFilters.includes(filterKey)) {
      defaultValue =
        // @ts-ignore
        filters[sourceType.value].find((filter) => filter.filterKey === filterKey).defaultValue ||
        null;
    }

    dispatch(csSetFilterValue({ filterKey, filterValue: defaultValue }));
  };

  const handleChangeFilterValue = (filterKey, filterValue) => {
    changeIsApplyActive(true);
    dispatch(csSetFilterValue({ filterKey, filterValue }));
  };

  const handleSearchClick = () => {
    if (makeContentSearchRequest) {
      makeContentSearchRequest();
    }
  };

  if (!sourceType) {
    return null;
  }

  return (
    <div className={cnFilters}>
      <Display isVisible={isFiltersOpen}>
        <div className="campaign-creation-filters__title">Refine By</div>
        {filters[sourceType.value].map((filter) => (
          <SearchFilter
            {...filter}
            onOpenFilter={handleToggleFilter}
            isOpen={openedFilters.includes(filter.filterKey)}
            key={filter.filterKey}
            value={filtersValues[filter.filterKey]}
            onChangeFilterValue={handleChangeFilterValue}
            sourceType={sourceType.value}
            // @ts-ignore
            icon={filter.icon || 'slidersSettings'}
          />
        ))}
        <Button
          disabled={!isApplyActive}
          className="campaign-creation-filters__apply-btn"
          onClick={handleSearchClick}
        >
          Apply filters
        </Button>
      </Display>
    </div>
  );
};

export default Filters;
