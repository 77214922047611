import React, { useState, useEffect, useRef } from 'react';

import { SVGIcon } from '@uikit/Icon/Icon';
import Button from '@uikit/Button/Button';
import Select from '@uikit/Select/Select';
import Input from '@uikit/Input/Input';
import Display from '@components/Display/Display';

import { ScheduleType } from '@ts/schedules.types';

import { getTimezonesOptions } from '@constants/timezones';

import ScheduleEditionDaysTable from './_components/ScheduleEditionDaysTable';

import './ScheduleEdition.scss';

type ScheduleEditionPropsType = {
  isOpen: boolean;
  schedule: ScheduleType;
  onClose: () => void;
  onSave: (schedule: ScheduleType) => void;
};

const timezonesOptions = getTimezonesOptions();

const ScheduleEdition = ({
  isOpen,
  schedule,
  onClose,
  onSave,
}: ScheduleEditionPropsType): JSX.Element => {
  if (!isOpen) {
    return null;
  }

  const [isTitleInputOpen, changeIsTitleInputOpen] = useState(false);
  const [selectedTimezone, changeSelectedTimezone] = useState(null);
  const [dayList, changeDayList] = useState([]);
  const [title, changeTitle] = useState('');
  const inputRef = useRef();

  useEffect(() => {
    changeSelectedTimezone(timezonesOptions.find(({ id }) => id === schedule.timezoneId));
    changeTitle(schedule.title);
    changeDayList(schedule.dayList);
  }, [schedule]);

  const handleTimezoneChange = (option) => changeSelectedTimezone(option);
  const handleChangeTitle = ({ target: { value } }) => changeTitle(value);
  // @ts-ignore
  const handleTitleEditClick = () => inputRef?.current?.focus();
  const handleTitleOnKewDown = (e) => {
    if (e.keyCode === 13) {
      changeIsTitleInputOpen(false);
    }
  };
  const handleSaveClick = () => {
    onSave({
      ...schedule,
      title,
      dayList,
      timezoneId: selectedTimezone.id,
      timezoneValue: selectedTimezone.originValue,
    });
  };

  const handleUpdateDay = (changedDayInfo) => {
    changeDayList((prevState) => {
      const newState = prevState.map((dayItem) =>
        dayItem.day === changedDayInfo.day
          ? {
              ...dayItem,
              timeFrom: changedDayInfo.timeFrom,
              timeTo: changedDayInfo.timeTo,
              includeDay: changedDayInfo.includeDay,
            }
          : dayItem
      );

      return newState;
    });
  };

  return (
    <div className="schedule-edition">
      <div className="schedule-edition__header">Schedule Edition</div>
      <div className="schedule-edition__title-row schedule-edition-title-row">
        <Input
          inputRef={inputRef}
          value={title}
          onChange={handleChangeTitle}
          onKeyDown={handleTitleOnKewDown}
          placeholder="Schedule name"
          isTransparent
        />
        <Display isVisible={!isTitleInputOpen}>
          <div
            className="schedule-edition-title-row__edit-btn"
            onClick={handleTitleEditClick}
            tabIndex={0}
            role="button"
          >
            <SVGIcon icon="pen" />
          </div>
        </Display>
        <div className="schedule-edition-title-row__right">
          <div className="schedule-edition-title-row__timezone-select">
            <Select
              value={selectedTimezone}
              options={timezonesOptions}
              onChange={handleTimezoneChange}
              placeholder="Timezone"
            />
          </div>
          <Button type="additional" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleSaveClick}>Save</Button>
        </div>
      </div>
      <ScheduleEditionDaysTable dayList={dayList} onUpdateDay={handleUpdateDay} />
    </div>
  );
};

export default ScheduleEdition;
