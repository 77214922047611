// package: relationships
// file: relationship-pipeline.proto

var relationship_pipeline_pb = require("./relationship-pipeline_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var common_pb = require("./common_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var RelationshipPipelineApiService = (function () {
  function RelationshipPipelineApiService() {}
  RelationshipPipelineApiService.serviceName = "relationships.RelationshipPipelineApiService";
  return RelationshipPipelineApiService;
}());

RelationshipPipelineApiService.createPipeline = {
  methodName: "createPipeline",
  service: RelationshipPipelineApiService,
  requestStream: false,
  responseStream: false,
  requestType: relationship_pipeline_pb.RelationshipPipelineCrudRequest,
  responseType: relationship_pipeline_pb.RelationshipPipelineResponse
};

RelationshipPipelineApiService.updatePipeline = {
  methodName: "updatePipeline",
  service: RelationshipPipelineApiService,
  requestStream: false,
  responseStream: false,
  requestType: relationship_pipeline_pb.RelationshipPipelineCrudRequest,
  responseType: relationship_pipeline_pb.RelationshipPipelineResponse
};

RelationshipPipelineApiService.deletePipeline = {
  methodName: "deletePipeline",
  service: RelationshipPipelineApiService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: google_protobuf_empty_pb.Empty
};

RelationshipPipelineApiService.getAllPipelines = {
  methodName: "getAllPipelines",
  service: RelationshipPipelineApiService,
  requestStream: false,
  responseStream: true,
  requestType: common_pb.IdRequest,
  responseType: relationship_pipeline_pb.RelationshipPipelineResponse
};

RelationshipPipelineApiService.createStage = {
  methodName: "createStage",
  service: RelationshipPipelineApiService,
  requestStream: false,
  responseStream: false,
  requestType: relationship_pipeline_pb.RelationshipPipelineStageCrudRequest,
  responseType: relationship_pipeline_pb.RelationshipPipelineStageResponse
};

RelationshipPipelineApiService.updateStage = {
  methodName: "updateStage",
  service: RelationshipPipelineApiService,
  requestStream: false,
  responseStream: false,
  requestType: relationship_pipeline_pb.RelationshipPipelineStageCrudRequest,
  responseType: relationship_pipeline_pb.RelationshipPipelineStageResponse
};

RelationshipPipelineApiService.moveStage = {
  methodName: "moveStage",
  service: RelationshipPipelineApiService,
  requestStream: false,
  responseStream: false,
  requestType: relationship_pipeline_pb.RelationshipPipelineMoveRequest,
  responseType: google_protobuf_empty_pb.Empty
};

RelationshipPipelineApiService.deleteStage = {
  methodName: "deleteStage",
  service: RelationshipPipelineApiService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: google_protobuf_empty_pb.Empty
};

RelationshipPipelineApiService.getAllStages = {
  methodName: "getAllStages",
  service: RelationshipPipelineApiService,
  requestStream: false,
  responseStream: true,
  requestType: common_pb.IdRequest,
  responseType: relationship_pipeline_pb.RelationshipPipelineStageResponse
};

RelationshipPipelineApiService.getStageOpportunitySuggestions = {
  methodName: "getStageOpportunitySuggestions",
  service: RelationshipPipelineApiService,
  requestStream: false,
  responseStream: true,
  requestType: relationship_pipeline_pb.RelationshipPipelineStageOpportunitySuggestionRequest,
  responseType: relationship_pipeline_pb.RelationshipPipelineStageOpportunitySuggestionResponse
};

RelationshipPipelineApiService.getStageOpportunitiesWithFilters = {
  methodName: "getStageOpportunitiesWithFilters",
  service: RelationshipPipelineApiService,
  requestStream: false,
  responseStream: true,
  requestType: relationship_pipeline_pb.StageOpportunityFilterRequest,
  responseType: relationship_pipeline_pb.RelationshipPipelineStageOpportunityResponse
};

RelationshipPipelineApiService.getStageOpportunitiesPage = {
  methodName: "getStageOpportunitiesPage",
  service: RelationshipPipelineApiService,
  requestStream: false,
  responseStream: true,
  requestType: common_pb.PageRequest,
  responseType: relationship_pipeline_pb.RelationshipPipelineStageOpportunityResponse
};

RelationshipPipelineApiService.createStageOpportunity = {
  methodName: "createStageOpportunity",
  service: RelationshipPipelineApiService,
  requestStream: false,
  responseStream: false,
  requestType: relationship_pipeline_pb.RelationshipPipelineStageOpportunityCrudRequest,
  responseType: relationship_pipeline_pb.RelationshipPipelineStageOpportunityResponse
};

RelationshipPipelineApiService.assignStageOpportunity = {
  methodName: "assignStageOpportunity",
  service: RelationshipPipelineApiService,
  requestStream: false,
  responseStream: false,
  requestType: relationship_pipeline_pb.RelationshipPipelineStageOpportunityAssignRequest,
  responseType: google_protobuf_empty_pb.Empty
};

RelationshipPipelineApiService.removeStageOpportunities = {
  methodName: "removeStageOpportunities",
  service: RelationshipPipelineApiService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdContainer,
  responseType: google_protobuf_empty_pb.Empty
};

RelationshipPipelineApiService.moveStageOpportunity = {
  methodName: "moveStageOpportunity",
  service: RelationshipPipelineApiService,
  requestStream: false,
  responseStream: false,
  requestType: relationship_pipeline_pb.RelationshipPipelineMoveRequest,
  responseType: relationship_pipeline_pb.RelationshipPipelineStageOpportunityResponse
};

exports.RelationshipPipelineApiService = RelationshipPipelineApiService;

function RelationshipPipelineApiServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

RelationshipPipelineApiServiceClient.prototype.createPipeline = function createPipeline(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(RelationshipPipelineApiService.createPipeline, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

RelationshipPipelineApiServiceClient.prototype.updatePipeline = function updatePipeline(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(RelationshipPipelineApiService.updatePipeline, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

RelationshipPipelineApiServiceClient.prototype.deletePipeline = function deletePipeline(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(RelationshipPipelineApiService.deletePipeline, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

RelationshipPipelineApiServiceClient.prototype.getAllPipelines = function getAllPipelines(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(RelationshipPipelineApiService.getAllPipelines, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

RelationshipPipelineApiServiceClient.prototype.createStage = function createStage(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(RelationshipPipelineApiService.createStage, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

RelationshipPipelineApiServiceClient.prototype.updateStage = function updateStage(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(RelationshipPipelineApiService.updateStage, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

RelationshipPipelineApiServiceClient.prototype.moveStage = function moveStage(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(RelationshipPipelineApiService.moveStage, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

RelationshipPipelineApiServiceClient.prototype.deleteStage = function deleteStage(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(RelationshipPipelineApiService.deleteStage, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

RelationshipPipelineApiServiceClient.prototype.getAllStages = function getAllStages(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(RelationshipPipelineApiService.getAllStages, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

RelationshipPipelineApiServiceClient.prototype.getStageOpportunitySuggestions = function getStageOpportunitySuggestions(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(RelationshipPipelineApiService.getStageOpportunitySuggestions, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

RelationshipPipelineApiServiceClient.prototype.getStageOpportunitiesWithFilters = function getStageOpportunitiesWithFilters(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(RelationshipPipelineApiService.getStageOpportunitiesWithFilters, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

RelationshipPipelineApiServiceClient.prototype.getStageOpportunitiesPage = function getStageOpportunitiesPage(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(RelationshipPipelineApiService.getStageOpportunitiesPage, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

RelationshipPipelineApiServiceClient.prototype.createStageOpportunity = function createStageOpportunity(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(RelationshipPipelineApiService.createStageOpportunity, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

RelationshipPipelineApiServiceClient.prototype.assignStageOpportunity = function assignStageOpportunity(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(RelationshipPipelineApiService.assignStageOpportunity, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

RelationshipPipelineApiServiceClient.prototype.removeStageOpportunities = function removeStageOpportunities(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(RelationshipPipelineApiService.removeStageOpportunities, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

RelationshipPipelineApiServiceClient.prototype.moveStageOpportunity = function moveStageOpportunity(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(RelationshipPipelineApiService.moveStageOpportunity, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.RelationshipPipelineApiServiceClient = RelationshipPipelineApiServiceClient;

