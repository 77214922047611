import { createAction } from 'redux-actions';
import { CampaignManualContactsSearchType } from '@redux/reducers/campaignConfirmContactsManual.reducer';

export const setLastManualContactsSearch = createAction<CampaignManualContactsSearchType>(
  'SET_LAST_MANUAL_CONTACTS_SEARCH'
);

export const setLastManualContactsSearchData = createAction<{ opportunityId: number; data: any[] }>(
  'SET_LAST_MANUAL_CONTACTS_SEARCH_DATA'
);
