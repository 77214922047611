import React from 'react';

export default ({ color = '#8F9199' }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.16509 3.30025C2.73859 3.83588 2.5 4.69768 2.5 5.97195V11.8184C2.5 13.0133 2.84924 13.8695 3.40704 14.4266C3.96494 14.9839 4.82309 15.3335 6.02189 15.3335H11.8615C13.0564 15.3335 13.9128 14.9842 14.47 14.427C15.0273 13.8697 15.3765 13.0133 15.3765 11.8184V11.8143L15.3971 8.0942V8.09218C15.3968 6.78718 15.1547 5.98146 14.744 5.50049C14.3565 5.04653 13.6903 4.73662 12.4639 4.73662L10.3402 4.73662C9.56673 4.73508 8.83871 4.37106 8.3734 3.75321L7.68844 2.84223C7.50686 2.60012 7.22135 2.45691 6.91871 2.45691H5.41944C4.22971 2.45691 3.5631 2.80039 3.16509 3.30025ZM1.99164 2.36589C2.75744 1.40414 3.92555 0.956909 5.41944 0.956909H6.91871C7.69338 0.956909 8.42284 1.32159 8.88771 1.94126L9.5716 2.85083C9.75402 3.0929 10.0394 3.23581 10.3425 3.23662H12.4639C13.9281 3.23662 15.1035 3.61131 15.8848 4.52654C16.6429 5.41451 16.8971 6.662 16.8971 8.0942V8.09834L16.8765 11.8184V11.8207C16.8761 13.3085 16.4346 14.5837 15.5307 15.4876C14.6263 16.392 13.3503 16.8335 11.8615 16.8335H6.02189C4.53021 16.8335 3.25242 16.3923 2.347 15.4879C1.44147 14.5835 1 13.3072 1 11.8184V5.97195C1 4.5626 1.25432 3.29188 1.99164 2.36589Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.58729 10.8668C4.58729 10.4526 4.92308 10.1168 5.33729 10.1168H12.5324C12.9466 10.1168 13.2824 10.4526 13.2824 10.8668C13.2824 11.281 12.9466 11.6168 12.5324 11.6168H5.33729C4.92308 11.6168 4.58729 11.281 4.58729 10.8668Z"
      fill={color}
    />
  </svg>
);
