import { sequenceSelector } from '@redux/selectors/sequence.selectors';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import cn from 'class-names';

import useAllVariablesInEditor from '@hooks/useAllVariablesInEditor';

import SlateEditor from '@uikit/RichEditor/SlateEditor';
import InlineSlateEditor from '@uikit/RichEditor/InlineSlateEditor';
import ModerateChance from '@components/ModerateChance/ModerateChance';
import AutosaveIndicator from '@uikit/AutosaveIndicator/AutosaveIndicator';

import debounce from '@utils/debounce';
import stripHtml from '@utils/stripHtml';
import { TemplateStepTransformedType } from '@ts/template.types';

import { getActiveCampaignInfo } from '@redux/selectors/campaignSettings.selectors';
import {
  removeSequenceStepAttachments,
  uploadSequenceStepAttachments,
} from '@redux/thunks/template.thunks';
import { addNotification } from '@redux/actions/notifications.actions';

import './SequenceEditor.scss';
import { ReferralLinkData } from 'respona_api/generated/user-organization_pb';
import CustomFieldsSidebar from '@components/CustomFieldsSidebar/CustomFieldsSidebar';

const AUTO_SAVING_DELAY_MS = 700;

type SequenceEditorPropsType = {
  sequence: TemplateStepTransformedType;
  subjects: string[];
  stepIndex?: number;
  onRemove?: () => void;
  onSaveContent: any;
  onSaveSubject: any;
  referralContent?: ReferralLinkData.AsObject;
};

const SequenceEditor = ({
  sequence,
  subjects,
  stepIndex,
  onRemove,
  onSaveContent,
  onSaveSubject,
  referralContent,
}: SequenceEditorPropsType): JSX.Element => {
  const dispatch = useDispatch();
  const [subject, changeSubject] = useState<string>(sequence?.subject);
  const [template, changeTemplate] = useState<string>('');
  const [isAutosavingProgress, changeIsAutosavingProgress] = useState(false);
  const [isOpenCustomFieldsSidebar, setOpenCustomFieldsSidebar] = useState(false);
  const [changingAiVariable, setChangingAiVariable] = useState(null);

  const { info: campaignInfo } = useSelector(getActiveCampaignInfo);
  const { enabledUnsubscribeContent } = useSelector(sequenceSelector);
  const { variables } = useAllVariablesInEditor();

  const fileMutation = useMutation((file: File): Promise<any> => {
    // @ts-ignore
    return dispatch(
      uploadSequenceStepAttachments(
        campaignInfo.id,
        campaignInfo.workspaceId,
        campaignInfo.lastSequenceId,
        sequence,
        file
      )
    );
  });

  useEffect(() => {
    changeSubject(sequence?.subject);
  }, [sequence, stepIndex]);

  useEffect(() => {
    changeTemplate(sequence?.content);
  }, [sequence, stepIndex]);

  const handleSaveSubjectDebounced = useRef(
    debounce((value, attachmentsList, sequenceFreshInfo) => {
      onSaveSubject({
        ...sequenceFreshInfo,
        subject: value,
        attachmentsList,
      }).finally(() => changeIsAutosavingProgress(false));
    }, AUTO_SAVING_DELAY_MS)
  ).current;

  const handleSaveContentDebounced = useRef(
    debounce((value, attachmentsList, sequenceFreshInfo) => {
      onSaveContent({
        ...sequenceFreshInfo,
        content: value,
        attachmentsList,
      }).finally(() => changeIsAutosavingProgress(false));
    }, AUTO_SAVING_DELAY_MS)
  ).current;

  const handleChangeSubject = useRef(
    debounce((value, newestSequence) => {
      if (value !== subject) {
        changeSubject(value);

        if (stepIndex !== 0 || stripHtml(value).trim() !== '') {
          changeIsAutosavingProgress(true);
          handleSaveSubjectDebounced(value, newestSequence?.attachmentsList, newestSequence);
        }
      }
    }, 400)
  ).current;

  const handleChangeTemplate = useRef(
    debounce((htmlTemplate, newestSequence) => {
      if (template !== htmlTemplate) {
        changeIsAutosavingProgress(true);
        changeTemplate(htmlTemplate);
        handleSaveContentDebounced(htmlTemplate, newestSequence?.attachmentsList, newestSequence);
      }
    }, 400)
  ).current;

  const handleFileUpload = (files: File[]) => {
    const readableFileSizeMB = Math.floor(files?.[0]?.size / 1000000);

    if (readableFileSizeMB > 25) {
      dispatch(addNotification({ title: 'Maximum file size is 25MB', type: 'warning' }));
      return;
    }

    if (stripHtml(sequence.content).trim() === '') {
      dispatch(addNotification({ title: 'Please fill content first', type: 'error' }));
    } else {
      fileMutation.mutate(files[0]);
    }
  };

  const handleRemoveFileUpload = (fileUid) => {
    dispatch(removeSequenceStepAttachments(campaignInfo.lastSequenceId, sequence, fileUid));
  };

  const cnSequenceEditorHeader = cn('sequence-editor__header', {
    'sequence-editor__header--same-thread': sequence?.isSameThread,
  });

  // if the subject is absent for the current step
  // then try to take the first previous none-empty subject
  const getSubject = (sbj): string => {
    const currentSubject = stripHtml(sbj).trim();

    if (currentSubject) {
      return currentSubject;
    }

    for (let i = stepIndex - 1; i >= 0; i--) {
      const item = stripHtml(subjects[i]).trim();

      if (item) {
        return item;
      }
    }

    return '';
  };

  const handleOpenFallbackChange = ({ variable }, fallbackRequired, type) => {
    if (fallbackRequired) {
      if (type === 'ai-variable') {
        // setChangingAiVariable(variables.find((item) => item.value === variable));
        // setOpenCustomFieldsSidebar(true);
        if (variable) {
          window.open(`/settings/custom-fields?open-update-sidebar=${variable}`);
        } else {
          window.open(`/settings/custom-fields?open-create-sidebar`);
        }
      } else {
        // setChangingAiVariable(null);
        // setOpenCustomFieldsSidebar(false);
      }
    }
  };

  return (
    <>
      <div className="sequence-editor">
        <div className={cnSequenceEditorHeader}>
          <div className="sequence-editor__autosave">
            <div className="sequence-editor__autosave-indicator">
              <AutosaveIndicator isSaving={isAutosavingProgress} />
            </div>
            <ModerateChance subject={getSubject(subject)} template={template} />
          </div>

          <InlineSlateEditor
            key={`inline-${stepIndex}`}
            message={subject}
            onChange={(value) => handleChangeSubject(value, sequence)}
            variables={variables}
            withEmoji={false}
            placeholder={
              stepIndex === 0 ? 'Subject' : 'Keep empty to send as a reply to your previous email'
            }
            handleOpenAiFallbackChange={handleOpenFallbackChange}
          />
        </div>
        <div className="sequence-editor__step-content">
          <SlateEditor
            removingText={`Step ${stepIndex + 1}`}
            key={stepIndex}
            message={sequence?.content}
            onChange={(value) => handleChangeTemplate(value, sequence)}
            onRemoveStep={stepIndex !== 0 ? onRemove : undefined}
            onFileUpload={handleFileUpload}
            onRemoveFile={handleRemoveFileUpload}
            isAttachmentsLoading={fileMutation.isLoading}
            attachments={sequence?.attachmentsList}
            // signature={senderMailbox?.signature}
            showUnsubscribeContent={enabledUnsubscribeContent}
            referralContent={referralContent}
            variables={variables}
            showSignaturePlaceholder
            handleOpenAiFallbackChange={handleOpenFallbackChange}
          />
        </div>
      </div>

      {/*<CustomFieldsSidebar*/}
      {/*  isOpen={isOpenCustomFieldsSidebar}*/}
      {/*  isUpdate={!!changingAiVariable}*/}
      {/*  relatedTo={changingAiVariable?.relation || 0}*/}
      {/*  onClose={() => {*/}
      {/*    setChangingAiVariable(null);*/}
      {/*    setOpenCustomFieldsSidebar(false);*/}
      {/*  }}*/}
      {/*  updatedVariable={changingAiVariable}*/}
      {/*/>*/}
    </>
  );
};

export default SequenceEditor;
