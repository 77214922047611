import React, { useRef, useState } from 'react';

import { OrganizationMemberResponseType } from '@ts/userOrganization.types';

import RightSidebar from '@components/RightSidebar/RightSidebar';
import Radio from '@uikit/Radio/Radio';
import Select from '@uikit/Select/Select';

import './RemoveMemberSidebar.scss';

type RemoveMemberSidebarPropsType = {
  member: OrganizationMemberResponseType;
  members: OrganizationMemberResponseType[];
  isOpen: boolean;
  onClose: (any) => void;
  onRemove: (memberId: number, needTransferData: boolean, userIdForTransfer: number) => void;
};

const needTransferOptions = {
  withTransfer: 'true',
  noTransfer: 'false',
};

const transformMembersToOptions = (members) =>
  members.map(({ id, fullName }) => ({
    value: id,
    label: fullName,
  }));

const RemoveMemberSidebar = ({
  isOpen,
  member,
  members,
  onClose,
  onRemove,
}: RemoveMemberSidebarPropsType): JSX.Element => {
  if (!isOpen || !member) {
    return null;
  }

  const filtredMembers = members.filter(({ id }) => id !== member.id);
  const [needTransfer, changeNeedTransfer] = useState(needTransferOptions.withTransfer);
  const [selectedMember, changeSelectedMember] = useState(
    filtredMembers.length
      ? {
          value: filtredMembers[0].id,
          label: filtredMembers[0].fullName,
        }
      : null
  );
  const selectRef = useRef(null);

  const handleNeedTransferChange = ({ target: { value } }) => changeNeedTransfer(value);
  const handleRemove = () => {
    if (needTransfer === 'true' && selectedMember === null) {
      return selectRef?.current?.focus();
    }
    onRemove(member.id, needTransfer === 'true', selectedMember?.value);
  };

  return (
    <RightSidebar
      isOpen={isOpen}
      onClose={onClose}
      title="Remove member"
      onSave={handleRemove}
      buttonSaveText="Remove"
    >
      <div className="remove-member-sidebar">
        <div className="remove-member-sidebar__text-content">Before deleting user</div>
        <div className="remove-member-sidebar__member-info">
          <div className="remove-member-sidebar__member-info-name">{member.fullName}</div>
          <div className="remove-member-sidebar__member-info-email">({member.email})</div>
        </div>
        <div className="remove-member-sidebar__text-content">
          you may want to transfer their data to another user just in case
        </div>
        <div className="remove-member-sidebar__remove-options">
          <div className="remove-member-sidebar__recommended-label">Recommended</div>
          <Radio
            isChecked={needTransfer === needTransferOptions.withTransfer}
            value={String(needTransferOptions.withTransfer)}
            onChange={handleNeedTransferChange}
          >
            Transfer user data to
          </Radio>
          <div className="remove-member-sidebar__member-select">
            <Select
              innerRef={selectRef}
              options={transformMembersToOptions(filtredMembers)}
              value={selectedMember}
              onChange={changeSelectedMember}
            />
          </div>
          <Radio
            isChecked={needTransfer === needTransferOptions.noTransfer}
            value={String(needTransferOptions.noTransfer)}
            onChange={handleNeedTransferChange}
          >
            Dont transfer any data
          </Radio>
        </div>
      </div>
    </RightSidebar>
  );
};

export default RemoveMemberSidebar;
