import React from 'react';

import { nanoid } from 'nanoid';
import cn from 'class-names';

import { SVGIcon, SvgIconType } from '@uikit/Icon/Icon';
import ReactTooltip, { Place } from 'react-tooltip';

import './QuestionTooltip.scss';

type QuestionTooltipType = {
  text: string | React.ReactNode;
  color?: string;
  offset?: { [key in 'left' | 'right' | 'bottom' | 'top']?: number };
  style?: React.CSSProperties;
  icon?: SvgIconType;
  place?: Place;
  children?: React.ReactNode;
};

const QuestionTooltip = ({
  text,
  color,
  offset,
  style,
  icon = 'questionInCircleFilled',
  place = 'bottom',
  children,
}: QuestionTooltipType): JSX.Element => {
  const uniqueId = nanoid();
  const cnTooltip = cn('react-tooltip question-tooltip__react-tooltip', {
    'react-tooltip question-tooltip__react-tooltip without-after': offset,
  });
  return (
    <div className="question-tooltip" style={style}>
      <span className="question-tooltip__svg-wrapper" data-for={uniqueId} data-tip="">
        {icon ? <SVGIcon icon={icon} color={color} /> : children}
      </span>
      <ReactTooltip
        id={uniqueId}
        className={cnTooltip}
        place={place}
        effect="solid"
        offset={offset}
        arrowColor="transparent"
      >
        <div className="question-tooltip__text">{text}</div>
      </ReactTooltip>
    </div>
  );
};

export default QuestionTooltip;
