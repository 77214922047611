// package: users
// file: user-organization.proto

var user_organization_pb = require("./user-organization_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var google_protobuf_wrappers_pb = require("google-protobuf/google/protobuf/wrappers_pb");
var common_pb = require("./common_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var UserOrganizationService = (function () {
  function UserOrganizationService() {}
  UserOrganizationService.serviceName = "users.UserOrganizationService";
  return UserOrganizationService;
}());

UserOrganizationService.createOrganization = {
  methodName: "createOrganization",
  service: UserOrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: user_organization_pb.OrganizationRequest,
  responseType: user_organization_pb.OrganizationResponse
};

UserOrganizationService.updateOrganization = {
  methodName: "updateOrganization",
  service: UserOrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: user_organization_pb.OrganizationRequest,
  responseType: user_organization_pb.OrganizationResponse
};

UserOrganizationService.deleteOrganization = {
  methodName: "deleteOrganization",
  service: UserOrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: google_protobuf_empty_pb.Empty
};

UserOrganizationService.getAllOrganizations = {
  methodName: "getAllOrganizations",
  service: UserOrganizationService,
  requestStream: false,
  responseStream: true,
  requestType: common_pb.PageRequest,
  responseType: user_organization_pb.OrganizationResponse
};

UserOrganizationService.getCurrentOrganization = {
  methodName: "getCurrentOrganization",
  service: UserOrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: user_organization_pb.OrganizationFullResponse
};

UserOrganizationService.getAllMembersOfOrganization = {
  methodName: "getAllMembersOfOrganization",
  service: UserOrganizationService,
  requestStream: false,
  responseStream: true,
  requestType: common_pb.PageRequest,
  responseType: user_organization_pb.OrganizationMemberResponse
};

UserOrganizationService.updateMemberOfOrganization = {
  methodName: "updateMemberOfOrganization",
  service: UserOrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: user_organization_pb.UpdateMemberRequest,
  responseType: google_protobuf_empty_pb.Empty
};

UserOrganizationService.removeMemberFromOrganization = {
  methodName: "removeMemberFromOrganization",
  service: UserOrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: user_organization_pb.RemoveMemberRequest,
  responseType: google_protobuf_empty_pb.Empty
};

UserOrganizationService.getAllInvitesOfOrganization = {
  methodName: "getAllInvitesOfOrganization",
  service: UserOrganizationService,
  requestStream: false,
  responseStream: true,
  requestType: common_pb.PageRequest,
  responseType: user_organization_pb.OrganizationInviteResponse
};

UserOrganizationService.sendInviteToOrganization = {
  methodName: "sendInviteToOrganization",
  service: UserOrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: user_organization_pb.InviteMemberRequest,
  responseType: google_protobuf_empty_pb.Empty
};

UserOrganizationService.removeInviteFromOrganization = {
  methodName: "removeInviteFromOrganization",
  service: UserOrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: google_protobuf_empty_pb.Empty
};

UserOrganizationService.createWorkspace = {
  methodName: "createWorkspace",
  service: UserOrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: user_organization_pb.WorkspaceRequest,
  responseType: user_organization_pb.WorkspaceResponse
};

UserOrganizationService.updateWorkspace = {
  methodName: "updateWorkspace",
  service: UserOrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: user_organization_pb.WorkspaceRequest,
  responseType: user_organization_pb.WorkspaceResponse
};

UserOrganizationService.deleteWorkspace = {
  methodName: "deleteWorkspace",
  service: UserOrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: google_protobuf_empty_pb.Empty
};

UserOrganizationService.getWorkspace = {
  methodName: "getWorkspace",
  service: UserOrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: user_organization_pb.WorkspaceResponse
};

UserOrganizationService.getAllWorkspaces = {
  methodName: "getAllWorkspaces",
  service: UserOrganizationService,
  requestStream: false,
  responseStream: true,
  requestType: common_pb.PageRequest,
  responseType: user_organization_pb.WorkspaceResponse
};

UserOrganizationService.getAllWorkspacesShort = {
  methodName: "getAllWorkspacesShort",
  service: UserOrganizationService,
  requestStream: false,
  responseStream: true,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: user_organization_pb.WorkspaceResponseShort
};

UserOrganizationService.changeWorkspaceFavoriteState = {
  methodName: "changeWorkspaceFavoriteState",
  service: UserOrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: google_protobuf_empty_pb.Empty
};

UserOrganizationService.addMembersToWorkspace = {
  methodName: "addMembersToWorkspace",
  service: UserOrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: user_organization_pb.WorkspaceMemberRequest,
  responseType: user_organization_pb.WorkspaceResponse
};

UserOrganizationService.removeMembersFromWorkspace = {
  methodName: "removeMembersFromWorkspace",
  service: UserOrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: user_organization_pb.WorkspaceMemberRequest,
  responseType: user_organization_pb.WorkspaceResponse
};

UserOrganizationService.getAllMembersOfWorkspace = {
  methodName: "getAllMembersOfWorkspace",
  service: UserOrganizationService,
  requestStream: false,
  responseStream: true,
  requestType: common_pb.PageRequest,
  responseType: user_organization_pb.WorkspaceMemberResponse
};

UserOrganizationService.getAllMembersOfWorkspaceShort = {
  methodName: "getAllMembersOfWorkspaceShort",
  service: UserOrganizationService,
  requestStream: false,
  responseStream: true,
  requestType: common_pb.IdRequest,
  responseType: user_organization_pb.WorkspaceMemberShortResponse
};

UserOrganizationService.getAllWorkspacesByUserId = {
  methodName: "getAllWorkspacesByUserId",
  service: UserOrganizationService,
  requestStream: false,
  responseStream: true,
  requestType: common_pb.IdRequest,
  responseType: common_pb.GenericShortResponse
};

UserOrganizationService.createSchedule = {
  methodName: "createSchedule",
  service: UserOrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: user_organization_pb.Schedule,
  responseType: user_organization_pb.Schedule
};

UserOrganizationService.updateSchedule = {
  methodName: "updateSchedule",
  service: UserOrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: user_organization_pb.Schedule,
  responseType: user_organization_pb.Schedule
};

UserOrganizationService.deleteSchedule = {
  methodName: "deleteSchedule",
  service: UserOrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: google_protobuf_empty_pb.Empty
};

UserOrganizationService.getAllSchedules = {
  methodName: "getAllSchedules",
  service: UserOrganizationService,
  requestStream: false,
  responseStream: true,
  requestType: common_pb.PageRequest,
  responseType: user_organization_pb.Schedule
};

UserOrganizationService.addSchedulesToWorkspace = {
  methodName: "addSchedulesToWorkspace",
  service: UserOrganizationService,
  requestStream: false,
  responseStream: true,
  requestType: user_organization_pb.ScheduleMember,
  responseType: user_organization_pb.Schedule
};

UserOrganizationService.removeSchedulesFromWorkspace = {
  methodName: "removeSchedulesFromWorkspace",
  service: UserOrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: user_organization_pb.ScheduleMember,
  responseType: google_protobuf_empty_pb.Empty
};

UserOrganizationService.getChecklist = {
  methodName: "getChecklist",
  service: UserOrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: user_organization_pb.OrganizationChecklistResponse
};

UserOrganizationService.skipChecklist = {
  methodName: "skipChecklist",
  service: UserOrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: google_protobuf_empty_pb.Empty
};

UserOrganizationService.completeChecklistItem = {
  methodName: "completeChecklistItem",
  service: UserOrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: user_organization_pb.OrganizationChecklistTaskState,
  responseType: google_protobuf_empty_pb.Empty
};

UserOrganizationService.connectIntegration = {
  methodName: "connectIntegration",
  service: UserOrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: user_organization_pb.AppIntegrationRequest,
  responseType: user_organization_pb.AppIntegrationResponse
};

UserOrganizationService.updateIntegration = {
  methodName: "updateIntegration",
  service: UserOrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: user_organization_pb.AppIntegrationRequest,
  responseType: user_organization_pb.AppIntegrationResponse
};

UserOrganizationService.deleteIntegration = {
  methodName: "deleteIntegration",
  service: UserOrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: user_organization_pb.AppIntegrationResponse
};

UserOrganizationService.getAllIntegrations = {
  methodName: "getAllIntegrations",
  service: UserOrganizationService,
  requestStream: false,
  responseStream: true,
  requestType: common_pb.PageRequest,
  responseType: user_organization_pb.AppIntegrationResponse
};

UserOrganizationService.getAllTrackingDomains = {
  methodName: "getAllTrackingDomains",
  service: UserOrganizationService,
  requestStream: false,
  responseStream: true,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: user_organization_pb.TrackingDomain
};

UserOrganizationService.addCustomTrackingDomain = {
  methodName: "addCustomTrackingDomain",
  service: UserOrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_wrappers_pb.StringValue,
  responseType: google_protobuf_empty_pb.Empty
};

UserOrganizationService.deleteCustomTrackingDomain = {
  methodName: "deleteCustomTrackingDomain",
  service: UserOrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_wrappers_pb.StringValue,
  responseType: google_protobuf_empty_pb.Empty
};

UserOrganizationService.getTrackingDomainSettings = {
  methodName: "getTrackingDomainSettings",
  service: UserOrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: user_organization_pb.TrackingDomainDnsSettings
};

UserOrganizationService.getUnsubscribeContent = {
  methodName: "getUnsubscribeContent",
  service: UserOrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: user_organization_pb.UnsubscribeContent
};

UserOrganizationService.updateUnsubscribeContent = {
  methodName: "updateUnsubscribeContent",
  service: UserOrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: user_organization_pb.UnsubscribeContent,
  responseType: user_organization_pb.UnsubscribeContent
};

UserOrganizationService.changeUnsubscribeContentState = {
  methodName: "changeUnsubscribeContentState",
  service: UserOrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_wrappers_pb.BoolValue,
  responseType: user_organization_pb.UnsubscribeContent
};

UserOrganizationService.getReferralsData = {
  methodName: "getReferralsData",
  service: UserOrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: user_organization_pb.ReferralLinkData
};

UserOrganizationService.updateReferralContent = {
  methodName: "updateReferralContent",
  service: UserOrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_wrappers_pb.StringValue,
  responseType: user_organization_pb.ReferralLinkData
};

exports.UserOrganizationService = UserOrganizationService;

function UserOrganizationServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

UserOrganizationServiceClient.prototype.createOrganization = function createOrganization(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserOrganizationService.createOrganization, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserOrganizationServiceClient.prototype.updateOrganization = function updateOrganization(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserOrganizationService.updateOrganization, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserOrganizationServiceClient.prototype.deleteOrganization = function deleteOrganization(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserOrganizationService.deleteOrganization, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserOrganizationServiceClient.prototype.getAllOrganizations = function getAllOrganizations(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(UserOrganizationService.getAllOrganizations, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

UserOrganizationServiceClient.prototype.getCurrentOrganization = function getCurrentOrganization(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserOrganizationService.getCurrentOrganization, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserOrganizationServiceClient.prototype.getAllMembersOfOrganization = function getAllMembersOfOrganization(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(UserOrganizationService.getAllMembersOfOrganization, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

UserOrganizationServiceClient.prototype.updateMemberOfOrganization = function updateMemberOfOrganization(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserOrganizationService.updateMemberOfOrganization, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserOrganizationServiceClient.prototype.removeMemberFromOrganization = function removeMemberFromOrganization(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserOrganizationService.removeMemberFromOrganization, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserOrganizationServiceClient.prototype.getAllInvitesOfOrganization = function getAllInvitesOfOrganization(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(UserOrganizationService.getAllInvitesOfOrganization, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

UserOrganizationServiceClient.prototype.sendInviteToOrganization = function sendInviteToOrganization(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserOrganizationService.sendInviteToOrganization, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserOrganizationServiceClient.prototype.removeInviteFromOrganization = function removeInviteFromOrganization(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserOrganizationService.removeInviteFromOrganization, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserOrganizationServiceClient.prototype.createWorkspace = function createWorkspace(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserOrganizationService.createWorkspace, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserOrganizationServiceClient.prototype.updateWorkspace = function updateWorkspace(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserOrganizationService.updateWorkspace, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserOrganizationServiceClient.prototype.deleteWorkspace = function deleteWorkspace(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserOrganizationService.deleteWorkspace, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserOrganizationServiceClient.prototype.getWorkspace = function getWorkspace(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserOrganizationService.getWorkspace, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserOrganizationServiceClient.prototype.getAllWorkspaces = function getAllWorkspaces(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(UserOrganizationService.getAllWorkspaces, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

UserOrganizationServiceClient.prototype.getAllWorkspacesShort = function getAllWorkspacesShort(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(UserOrganizationService.getAllWorkspacesShort, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

UserOrganizationServiceClient.prototype.changeWorkspaceFavoriteState = function changeWorkspaceFavoriteState(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserOrganizationService.changeWorkspaceFavoriteState, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserOrganizationServiceClient.prototype.addMembersToWorkspace = function addMembersToWorkspace(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserOrganizationService.addMembersToWorkspace, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserOrganizationServiceClient.prototype.removeMembersFromWorkspace = function removeMembersFromWorkspace(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserOrganizationService.removeMembersFromWorkspace, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserOrganizationServiceClient.prototype.getAllMembersOfWorkspace = function getAllMembersOfWorkspace(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(UserOrganizationService.getAllMembersOfWorkspace, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

UserOrganizationServiceClient.prototype.getAllMembersOfWorkspaceShort = function getAllMembersOfWorkspaceShort(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(UserOrganizationService.getAllMembersOfWorkspaceShort, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

UserOrganizationServiceClient.prototype.getAllWorkspacesByUserId = function getAllWorkspacesByUserId(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(UserOrganizationService.getAllWorkspacesByUserId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

UserOrganizationServiceClient.prototype.createSchedule = function createSchedule(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserOrganizationService.createSchedule, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserOrganizationServiceClient.prototype.updateSchedule = function updateSchedule(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserOrganizationService.updateSchedule, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserOrganizationServiceClient.prototype.deleteSchedule = function deleteSchedule(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserOrganizationService.deleteSchedule, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserOrganizationServiceClient.prototype.getAllSchedules = function getAllSchedules(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(UserOrganizationService.getAllSchedules, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

UserOrganizationServiceClient.prototype.addSchedulesToWorkspace = function addSchedulesToWorkspace(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(UserOrganizationService.addSchedulesToWorkspace, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

UserOrganizationServiceClient.prototype.removeSchedulesFromWorkspace = function removeSchedulesFromWorkspace(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserOrganizationService.removeSchedulesFromWorkspace, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserOrganizationServiceClient.prototype.getChecklist = function getChecklist(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserOrganizationService.getChecklist, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserOrganizationServiceClient.prototype.skipChecklist = function skipChecklist(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserOrganizationService.skipChecklist, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserOrganizationServiceClient.prototype.completeChecklistItem = function completeChecklistItem(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserOrganizationService.completeChecklistItem, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserOrganizationServiceClient.prototype.connectIntegration = function connectIntegration(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserOrganizationService.connectIntegration, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserOrganizationServiceClient.prototype.updateIntegration = function updateIntegration(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserOrganizationService.updateIntegration, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserOrganizationServiceClient.prototype.deleteIntegration = function deleteIntegration(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserOrganizationService.deleteIntegration, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserOrganizationServiceClient.prototype.getAllIntegrations = function getAllIntegrations(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(UserOrganizationService.getAllIntegrations, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

UserOrganizationServiceClient.prototype.getAllTrackingDomains = function getAllTrackingDomains(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(UserOrganizationService.getAllTrackingDomains, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

UserOrganizationServiceClient.prototype.addCustomTrackingDomain = function addCustomTrackingDomain(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserOrganizationService.addCustomTrackingDomain, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserOrganizationServiceClient.prototype.deleteCustomTrackingDomain = function deleteCustomTrackingDomain(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserOrganizationService.deleteCustomTrackingDomain, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserOrganizationServiceClient.prototype.getTrackingDomainSettings = function getTrackingDomainSettings(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserOrganizationService.getTrackingDomainSettings, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserOrganizationServiceClient.prototype.getUnsubscribeContent = function getUnsubscribeContent(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserOrganizationService.getUnsubscribeContent, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserOrganizationServiceClient.prototype.updateUnsubscribeContent = function updateUnsubscribeContent(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserOrganizationService.updateUnsubscribeContent, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserOrganizationServiceClient.prototype.changeUnsubscribeContentState = function changeUnsubscribeContentState(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserOrganizationService.changeUnsubscribeContentState, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserOrganizationServiceClient.prototype.getReferralsData = function getReferralsData(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserOrganizationService.getReferralsData, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserOrganizationServiceClient.prototype.updateReferralContent = function updateReferralContent(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserOrganizationService.updateReferralContent, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.UserOrganizationServiceClient = UserOrganizationServiceClient;

