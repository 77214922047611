import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { getUnsubscribeContentApi, updateUnsubscribeContentApi } from '@api/userOrganization.api';

import Loader from '@uikit/Loader/Loader';
import { Button } from '@uikit/Button/Button';
import Input from '@uikit/Input/Input';
import BaseModal, { BaseModalType } from '@uikit/BaseModal/BaseModal';

import './EditLinkContentModal.scss';

const contentRegexp = new RegExp(
  /^<p>(.+)?<a href="%s" target="_blank" rel="noopener noreferrer">(.+)<\/a>(.+)?<\/p>$/
);
const validationRegexp = new RegExp(/^(.+)?{link}(.+){\/link}(.+)?$/);

const contentPattern =
  '<p>text1<a href="%s" target="_blank" rel="noopener noreferrer">link</a>text2</p>';

const EditUnsubscribeContentModal = ({
  isOpen,
  onClose,
  enabled,
}: BaseModalType & { enabled: boolean }): JSX.Element => {
  const queryClient = useQueryClient();

  const [value, setValue] = useState<string>('');

  const { data, isFetching } = useQuery({
    queryFn: () => getUnsubscribeContentApi(),
    enabled: isOpen,
    refetchOnWindowFocus: false,
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: (parsedValue: string) => updateUnsubscribeContentApi(parsedValue, enabled),
    onSuccess: async () => {
      await queryClient.refetchQueries(['campaign-personalization']);
      onClose();
    },
  });

  const parseValue = (string: string) => {
    if (!validationRegexp.test(string)) {
      throw new Error('Wrong format');
    }

    const splitValue = string.split(/{link}|{\/link}/);

    let finalValue = contentPattern;
    finalValue = finalValue.replace('text1', splitValue[0]);
    finalValue = finalValue.replace('link', splitValue[1]);
    finalValue = finalValue.replace('text2', splitValue[2]);

    return finalValue;
  };

  const updateUnsubscribeContent = async () => {
    try {
      const parsedValue = parseValue(value);
      await mutate(parsedValue);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (!!data?.content) {
      setValue(data.content.replace(contentRegexp, '$1{link}$2{/link}$3'));
    }
  }, [data]);

  return (
    <BaseModal isOpen={isOpen} onClose={onClose}>
      <div className="edit-unsubscribe-content-modal__header">
        <div className="edit-unsubscribe-content-modal__header-title">Unsubscribe sentence</div>
        <div className="edit-unsubscribe-content-modal__header-subtitle">
          {'The unsubscribe link will be included between the {link} and {/link} tags.'}
        </div>
      </div>
      <div className="edit-unsubscribe-content-modal__content">
        <Loader isLoading={isFetching}>
          <Input
            placeholder="Unsubscribe sentence"
            className="edit-unsubscribe-content-modal__input"
            value={value}
            onChange={({ target: { value } }) => setValue(value)}
          />
        </Loader>
        <Button
          isLoading={isLoading}
          className="edit-unsubscribe-content-modal__save-button"
          onClick={updateUnsubscribeContent}
        >
          Save
        </Button>
      </div>
    </BaseModal>
  );
};

export default EditUnsubscribeContentModal;
