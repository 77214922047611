import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import './AutomationSourceSelect.scss';

import { SVGIcon } from '@uikit/Icon/Icon';
import { IntegrationType } from '@ts/userOrganization.types';

import {
  BacklinksSearchSource as BACKLINKS_SEARCH_SOURCE,
  BacklinksSearchSourceMap,
  ContactSearchSource as CONTACT_SEARCH_SOURCE,
  ContentSearchType,
  IntegrationStatus as INTEGRATION_STATUS,
  IntegrationType as INTEGRATION_TYPE,
} from 'respona_api/generated/common_pb';
import { AppIntegrationResponse } from 'respona_api/generated/user-organization_pb';

import AutomationSourceOption, {
  AutomationSourceOptionNew,
  AutomationSourceOptionsRow,
} from '@components/CampaignSettings/ContactAutomationSetup/_components/AutomationSourceSelect/_components/AutomationSourceOption';

import { integrationsService } from 'src/services/integrationsService';
import { automationSubTypeSelector } from '@redux/selectors/automationContent.selectors';
import { AutomationSearchSubType } from '@ts/automationContent.types';

function ResponaStatusTag({ backlinksIntegrations }: { backlinksIntegrations?: boolean }) {
  function composeText(): string {
    if (backlinksIntegrations) {
      return '1 credit per result';
    }
    return '1 credit + 5 credits per contact';
  }

  return (
    <div className="respona-status-tag">
      <SVGIcon icon="wallet" className="respona-status-tag__icon" />
      <span>{composeText()}</span>
    </div>
  );
}

function AutomationSourceSelect({
  integrations,
  selectedSource,
  setSelectedSource,
  backlinksIntegrations,
}: {
  integrations: IntegrationType[];
  selectedSource: number;
  setSelectedSource: (source: BacklinksSearchSourceMap[keyof BacklinksSearchSourceMap]) => void;
  backlinksIntegrations?: boolean;
}): JSX.Element {
  const availableIntegrations = integrationsService.getContactSourceAvailableIntegrations(
    integrations,
    backlinksIntegrations
  );

  const sourceEnum = backlinksIntegrations ? BACKLINKS_SEARCH_SOURCE : CONTACT_SEARCH_SOURCE;

  const automationSubType: AutomationSearchSubType = useSelector(automationSubTypeSelector);

  const initialBacklinksSource = () => {
    switch (automationSubType) {
      case ContentSearchType.AHREF_BACKLINKS:
        return BACKLINKS_SEARCH_SOURCE.AHREFS_BACKLINKS_SEARCH;
      case ContentSearchType.SEMRUSH_BACKLINKS:
        return BACKLINKS_SEARCH_SOURCE.SEMRUSH_BACKLINKS_SEARCH;
      case ContentSearchType.MOZ_BACKLINKS:
        return BACKLINKS_SEARCH_SOURCE.MOZ_BACKLINKS_SEARCH;
      case ContentSearchType.RESPONA_BACKLINKS:
        return BACKLINKS_SEARCH_SOURCE.RESPONA_BACKLINKS_SEARCH;
      default:
        return BACKLINKS_SEARCH_SOURCE.RESPONA_BACKLINKS_SEARCH;
    }
  };

  const defaultSearchType = backlinksIntegrations
    ? BACKLINKS_SEARCH_SOURCE.RESPONA_BACKLINKS_SEARCH
    : CONTACT_SEARCH_SOURCE.RESPONA_SEARCH_SOURCE;

  const hasAllIntegrations =
    availableIntegrations.length >=
    Object.keys(sourceEnum).length - (backlinksIntegrations ? 0 : 2); // has all integrations if availableIntegrations length equals all sources length minus 'RESPONA'

  useEffect(() => {
    if (!selectedSource && !backlinksIntegrations) {
      setSelectedSource(CONTACT_SEARCH_SOURCE.RESPONA_SEARCH_SOURCE);
    } else if (backlinksIntegrations) {
      setSelectedSource(initialBacklinksSource());
    }
  }, []);

  function transformToBacklinks(
    response: AppIntegrationResponse.AsObject
  ): BacklinksSearchSourceMap[keyof BacklinksSearchSourceMap] {
    switch (response.type) {
      case INTEGRATION_TYPE.UNKNOWN_INTEGRATION:
        return BACKLINKS_SEARCH_SOURCE.RESPONA_BACKLINKS_SEARCH;
      case INTEGRATION_TYPE.AHREFS_INTEGRATION:
        return BACKLINKS_SEARCH_SOURCE.AHREFS_BACKLINKS_SEARCH;
      case INTEGRATION_TYPE.MOZ_INTEGRATION:
        return BACKLINKS_SEARCH_SOURCE.MOZ_BACKLINKS_SEARCH;
      case INTEGRATION_TYPE.SEMRUSH_INTEGRATION:
        return BACKLINKS_SEARCH_SOURCE.SEMRUSH_BACKLINKS_SEARCH;
      default:
        return BACKLINKS_SEARCH_SOURCE.RESPONA_BACKLINKS_SEARCH;
    }
  }

  return (
    <AutomationSourceOptionsRow>
      <AutomationSourceOption
        backlinksIntegration={backlinksIntegrations}
        type={defaultSearchType}
        title="Respona"
        subtitle="Powered by:"
        subtitleDetails={backlinksIntegrations ? 'Moz' : "Respona's data partners"}
        isSelected={selectedSource === defaultSearchType}
        onSelect={() => setSelectedSource(defaultSearchType)}
        statusText={<ResponaStatusTag backlinksIntegrations={backlinksIntegrations} />}
      />

      {availableIntegrations.map((integration) => (
        <AutomationSourceOption
          key={transformToBacklinks(integration)}
          backlinksIntegration={backlinksIntegrations}
          type={transformToBacklinks(integration)}
          title={integration.title}
          subtitle={integration.description}
          subtitleDetails={integration.subtitleDetails}
          status={integration.status}
          isSelected={selectedSource === transformToBacklinks(integration)}
          onSelect={() => setSelectedSource(transformToBacklinks(integration))}
          disabled={integration.status !== INTEGRATION_STATUS.ACTIVE_INTEGRATION}
        />
      ))}

      {!hasAllIntegrations ? (
        <AutomationSourceOptionNew backlinksIntegrations={backlinksIntegrations} />
      ) : null}
    </AutomationSourceOptionsRow>
  );
}

export default AutomationSourceSelect;
