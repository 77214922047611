import React, { useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import parse from 'emailjs-mime-parser';

import { ThreadMessageType } from '@ts/mailboxInbox.types';

import Loader from '@uikit/Loader/Loader';
import EmailThreadWrapper from '@uikit/ThreadEmails/_components/EmailThreadWrapper/EmailThreadWrapper';
import MimeMessage from '@uikit/MimeMessage/MimeMessage';
import { Button } from '@uikit/Button/Button';
import { SVGIcon } from '@uikit/Icon/Icon';

import InboxWidget, { InboxWidgetInfoType } from '@components/Inbox/InboxWidget/InboxWidget';
import Display from '@components/Display/Display';

import './ThreadEmails.scss';
import InboxComments from '@components/Inbox/InboxComments/InboxComments';
import { useQuery } from 'react-query';
import { getAllThreadConversationMessagesApi } from '@api/mailbox.api';
import { PersonBaseType } from '@ts/people.types';
import useCurrentWorkspaceId from "@hooks/useCurrentWorkspaceId";

function ThreadEmails({
  emails = [],
  onReply,
  onForward,
  inboxWidgetInfo,
  handleCloseEmailWidget,
  isRefetching,
  refetch,
  activeThreadUid,
  onDraftCreation,
  onDraftDelete,
  activePerson,
  isActionButtonHidden = false,
}: {
  emails: ThreadMessageType[];
  onReply?: (email: ThreadMessageType, curThreadUid: string, replyAll?: boolean) => void;
  onForward?: (email: ThreadMessageType, curThreadUid: string) => void;
  handleCloseEmailWidget?: (deleteOnClose: boolean, threadUid?: string) => void;
  inboxWidgetInfo?: InboxWidgetInfoType;
  isRefetching?: boolean;
  refetch?: () => Promise<void>;
  activeThreadUid?: string;
  onDraftCreation: (threadUid: string) => void;
  onDraftDelete: (threadUid: string) => void;
  activePerson?: PersonBaseType;
  isActionButtonHidden?: boolean;
}): JSX.Element {
  const bottomAnchorRef = useRef<HTMLDivElement>();
  const currentWorkspaceId = useCurrentWorkspaceId();

  const { data: comments = [] } = useQuery(
    ['mailbox-comments', activeThreadUid],
    () => getAllThreadConversationMessagesApi(activeThreadUid, currentWorkspaceId),
    {
      enabled: !!activeThreadUid,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5,
    }
  );

  const onSendEmail = useCallback(() => {
    if (refetch) {
      refetch();
    }
  }, [refetch]);

  useLayoutEffect(() => {
    if (!bottomAnchorRef.current) {
      return;
    }

    bottomAnchorRef.current.scrollIntoView(true);
  }, [emails]);

  useEffect(() => {
    if (!bottomAnchorRef.current) {
      return;
    }

    if (activeThreadUid !== null) {
      bottomAnchorRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [activeThreadUid]);

  if (!emails?.length) {
    return null;
  }

  const lastEmail = emails[emails.length - 1];
  const lastEmailParsed = parse(lastEmail.content);
  const lastEmailHasCC = lastEmailParsed?.headers?.cc?.length;

  return (
    <div className="thread-emails">
      {emails.map((emailInfo: ThreadMessageType, index: number) => {
        const content = parse(emailInfo.content);

        return (
          <EmailThreadWrapper
            key={emailInfo.messageId}
            email={content}
            emailInfoShort={emailInfo}
            onReply={(email, replyAll) => onReply(email, activeThreadUid, replyAll)}
            onForward={(email) => onForward(email, activeThreadUid)}
            isInitialPreview={index + 1 !== emails.length}
            isActionButtonHidden={isActionButtonHidden}
          >
            <MimeMessage mimeMessage={content} />
          </EmailThreadWrapper>
        );
      })}

      <Display isVisible={!!onReply && !!onForward && !inboxWidgetInfo}>
        <div className="thread-emails__footer">
          <Button
            type="primary"
            className="thread-emails__reply-btn"
            onClick={() => onReply(lastEmail, activeThreadUid)}
          >
            <SVGIcon icon="cornerUpLeftIcon" />
            Reply
          </Button>

          {lastEmailHasCC ? (
            <Button
              type="primary"
              className="thread-emails__reply-btn"
              onClick={() => onReply(lastEmail, activeThreadUid, true)}
            >
              <SVGIcon icon="doubleCornerUpLeftIcon" color="#ffffff" />
              Reply All
            </Button>
          ) : null}

          <Button
            type="additional"
            className="thread-emails__forward-btn"
            onClick={() => onForward(lastEmail, activeThreadUid)}
          >
            <SVGIcon icon="doubleCornerUpLeftIcon" />
            Forward
          </Button>
        </div>
      </Display>

      <Display isVisible={isRefetching}>
        <Loader isLoading withTopMargin />
      </Display>
      {isRefetching ? null : (
        <>
          <InboxWidget
            key={activeThreadUid}
            onClose={handleCloseEmailWidget}
            onSend={onSendEmail}
            inboxWidgetInfo={inboxWidgetInfo}
            isEmbedded
            activeThreadUid={activeThreadUid}
            onDraftCreation={onDraftCreation}
            onDraftDelete={onDraftDelete}
            activePerson={activePerson}
          />
          <InboxComments threadUid={activeThreadUid} comments={comments} />
        </>
      )}

      <div className="thread-emails__bottom_anchor" ref={bottomAnchorRef} />
    </div>
  );
}

export default ThreadEmails;
