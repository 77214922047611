import createAction from 'src/redux/utils/createAction';

export const SET_ANALYTICS_INBOX_SENT = 'SET_ANALYTICS_INBOX_SENT';
export const SET_ANALYTICS_INBOX_SENT_LS = 'SET_ANALYTICS_INBOX_SENT_LS';
export const SET_ANALYTICS_INBOX_NEED_ATTENTION = 'SET_ANALYTICS_INBOX_NEED_ATTENTION';
export const SET_ANALYTICS_INBOX_NEED_ATTENTION_LS = 'SET_ANALYTICS_INBOX_NEED_ATTENTION_LS';

export const SET_ANALYTICS_TEMPLATE = 'SET_ANALYTICS_TEMPLATE';
export const SET_ANALYTICS_TEMPLATE_LS = 'SET_ANALYTICS_TEMPLATE_LS';
export const UPDATE_ANALYTICS_TEMPLATE_STEP = 'UPDATE_ANALYTICS_TEMPLATE_STEP';

export const setAnalyticsTemplate = createAction(SET_ANALYTICS_TEMPLATE);
export const setAnalyticsTemplateLs = createAction(SET_ANALYTICS_TEMPLATE_LS);
export const updateAnalyticsTemplateStep = createAction(UPDATE_ANALYTICS_TEMPLATE_STEP);
