import React, { useState, useEffect, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import cn from 'class-names';

import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';
import useAllVariablesInEditor from '@hooks/useAllVariablesInEditor';

import SlateEditor from '@uikit/RichEditor/SlateEditor';
import InlineSlateEditor from '@uikit/RichEditor/InlineSlateEditor';
import ModerateChance from '@components/ModerateChance/ModerateChance';

import stripHtml from '@utils/stripHtml';
import { TemplateStepType, TemplateType } from '@ts/template.types';

import { getActiveCampaignInfo } from '@redux/selectors/campaignSettings.selectors';
import {
  removeSequenceStepAttachments,
  uploadSequenceStepAttachments,
} from '@redux/thunks/template.thunks';
import { addNotification } from '@redux/actions/notifications.actions';

import './SequenceEditor.scss';
import { DispatchType } from 'src/store';
import debounce from '@utils/debounce';

type SequenceEditorPropsType = {
  sequence: TemplateStepType;
  stepIndex?: number;
  onRemove?: () => void;
  onSaveStep: (data: TemplateStepType) => void;
  templateId?: number;
  onUpdate?: (silent?: boolean) => Promise<TemplateType>;
  isExternalAutosave?: boolean;
};

const SequenceEditorWithoutAutosave = ({
  sequence,
  stepIndex,
  onRemove,
  onSaveStep,
  templateId,
  onUpdate,
  isExternalAutosave = false,
}: SequenceEditorPropsType): JSX.Element => {
  const dispatch = useDispatch<DispatchType>();
  const [subject, changeSubject] = useState(sequence?.threadA.subject);
  const [template, changeTemplate] = useState('');
  const { info: campaignInfo } = useSelector(getActiveCampaignInfo);
  const workspaceId = useCurrentWorkspaceId();

  const { variables } = useAllVariablesInEditor();

  const fileMutation = useMutation(async (file: File): Promise<any> => {
    return dispatch(
      uploadSequenceStepAttachments(campaignInfo?.id, workspaceId, templateId, sequence, file)
    ).then(onSaveStep);
  });

  useEffect(() => {
    changeSubject(sequence?.threadA.subject);
  }, [sequence, stepIndex]);

  useEffect(() => {
    changeTemplate(sequence?.threadA.content);
  }, [sequence, stepIndex]);

  const handleSaveData = (curSubject, curTemplate, attachmentsList, sequenceFreshInfo) =>
    onSaveStep({
      ...sequenceFreshInfo,
      threadA: {
        ...sequenceFreshInfo.threadA,
        subject: curSubject,
        content: curTemplate,
        attachmentsList,
      },
    });

  const handleChangeSubject = useRef(
    debounce((value, template, sequence) => {
      changeSubject(value);
      handleSaveData(value, template, sequence.threadA.attachmentsList, sequence);
    }, 400)
  ).current;

  const handleChangeTemplate = useRef(
    debounce((htmlTemplate, subject, sequence) => {
      changeTemplate(htmlTemplate);
      handleSaveData(subject, htmlTemplate, sequence.threadA.attachmentsList, sequence);
    }, 400)
  ).current;

  const handleFileUpload = (files: File[]) => {
    const readableFileSizeMB = Math.floor(files?.[0]?.size / 1000000);

    if (readableFileSizeMB > 25) {
      dispatch(addNotification({ title: 'Maximum file size is 25MB', type: 'warning' }));
      return;
    }

    if (stripHtml(sequence.threadA.content).trim() === '') {
      dispatch(addNotification({ title: 'Please fill content first', type: 'error' }));
    } else {
      fileMutation.mutate(files[0]);
    }
  };

  const handleRemoveFileUpload = (fileUid) => {
    dispatch(removeSequenceStepAttachments(templateId, sequence, fileUid)).then(onSaveStep);
  };

  const cnSequenceEditorHeader = cn('sequence-editor__header', {
    'sequence-editor__header--same-thread': sequence?.threadA.sameThread,
  });

  return (
    <>
      {isExternalAutosave && (
        <div className="sequence-editor__autosave external">
          <ModerateChance
            subject={subject}
            template={template}
            tooltipBottom={100}
          />
        </div>
      )}
      <div className="sequence-editor">
        <div className={cnSequenceEditorHeader}>
          {
            isExternalAutosave === false && (
              <div className="sequence-editor__autosave">
                <ModerateChance
                  subject={subject}
                  template={template}
                  tooltipBottom={100}
                />
              </div>
            )
          }
          <InlineSlateEditor
            key={`inline-${stepIndex}`}
            message={subject}
            useMessageUpdate
            onChange={(value) => handleChangeSubject(value, template, sequence)}
            placeholder={
              stepIndex === 0 ? 'Subject' : 'Keep empty to send as a reply to your previous email'
            }
            variables={variables}
            withEmoji={false}
          />
        </div>
        <div className="sequence-editor__step-content">
          <SlateEditor
            removingText={`Step ${stepIndex + 1}`}
            key={stepIndex}
            message={sequence?.threadA.content}
            onChange={(htmlTemplate) => handleChangeTemplate(htmlTemplate, subject, sequence)}
            onRemoveStep={stepIndex !== 0 ? onRemove : undefined}
            onFileUpload={handleFileUpload}
            onRemoveFile={handleRemoveFileUpload}
            isAttachmentsLoading={fileMutation.isLoading}
            attachments={sequence?.threadA.attachmentsList}
            withAttachmentsAtAll={!!templateId}
            variables={variables}
          />
        </div>
      </div>
    </>
  );
};

export default SequenceEditorWithoutAutosave;
