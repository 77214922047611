import React from 'react';

import Table, { THead, TBody, Tr, Th, Td } from '@uikit/Table/Table';
import { ScheduleType } from '@ts/schedules.types';
import { getTimezoneById } from '@constants/timezones';

import ActionsMenu from '../../ActionsMenu/ActionsMenu';

import SchedulesDaysList from '../SchedulesDaysList/SchedulesDaysList';

import './SchedulesTable.scss';

type SchedulesPropsType = {
  schedules: ScheduleType[];
  onEdit: (number) => void;
  onRemove: (number) => void;
};

type SchedulesTableRowPropsType = ScheduleType & {
  onEdit: (number) => void;
  onRemove: (number) => void;
};

const SchedulesTableRow = ({
  id,
  title,
  timezoneId,
  dayList,
  onEdit,
  onRemove,
}: SchedulesTableRowPropsType) => {
  const actionsConfig = [
    {
      icon: 'editAction',
      title: 'Edit',
      handler: () => onEdit(id),
    },
  ];

  return (
    <>
      <Tr className="schedules-table__tr" onClick={() => onEdit(id)}>
        <Td className="schedules-table__td schedules-table__td--empty" />
        <Td className="schedules-table__td schedules-table__td__title">{title}</Td>
        <Td className="schedules-table__td schedules-table__td--timezone">
          {getTimezoneById(timezoneId).name}
        </Td>
        <Td className="schedules-table__td">
          <SchedulesDaysList dayList={dayList} timezone={getTimezoneById(timezoneId).name} />
        </Td>
        <Td className="schedules-table__td">
          <ActionsMenu
            onRemove={() => onRemove(id)}
            id={`schedules-settings-actions-${id}`}
            actionsConfig={actionsConfig}
            iconClassName="schedules-table__actions-icon"
            tooltipPlace="left"
          />
        </Td>
      </Tr>
    </>
  );
};

export default ({ schedules, onEdit, onRemove }: SchedulesPropsType) => {
  return (
    <Table className="schedules-table">
      <THead>
        <Tr>
          <Th className="schedules-table__th" />
          <Th className="schedules-table__th">Name</Th>
          <Th className="schedules-table__th">Time Zone</Th>
          <Th className="schedules-table__th">Schedule</Th>
          <Th />
        </Tr>
      </THead>
      <TBody>
        {schedules.map((schedule) => (
          <SchedulesTableRow {...schedule} key={schedule.id} onEdit={onEdit} onRemove={onRemove} />
        ))}
      </TBody>
    </Table>
  );
};
