import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'class-names';

import SidebarIcon from '../../../uikit/SidebarIcon/SidebarIcon';
import { PageLinkType } from '../pagesLinks';

type SidebarPagesLinksPropsType = {
  pages: PageLinkType[];
  pathname: string;
};

export default ({ pages, pathname }: SidebarPagesLinksPropsType): JSX.Element => {
  const getSidebarLinkClassName = (isActive) =>
    cn('sidebar__link sidebar-link', {
      'sidebar-link--active': isActive,
    });

  const filtredPages = pages.filter(({ isShown }) => isShown === undefined || isShown());

  return (
    <>
      {filtredPages.map(({ path, name, icon, activePrefix, beta }: PageLinkType) => {
        const isActive = pathname.indexOf(activePrefix) === 0;
        return (
          <React.Fragment key={path}>
            <Link
              className={getSidebarLinkClassName(isActive)}
              to={path}
              data-for={`sidebar-link-${path}`}
              data-tip=""
              onClick={(event) => {
                if (isActive) {
                  event.preventDefault();
                }
              }}
            >
              <span className="sidebar-link__icon">
                <SidebarIcon isActive={isActive} type={icon} />
              </span>
              {name}
            </Link>
          </React.Fragment>
        );
      })}
    </>
  );
};
