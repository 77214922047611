import { SVGIcon } from '@uikit/Icon/Icon';
import React from 'react';

function Tag({ title, onRemove }: { title: string; onRemove: () => void }) {
  return (
    <div className="contacts-sidebar-section-tags__tag">
      <span>{title}</span>
      <span className="contacts-sidebar-section-tags__tag-delete-btn" onClick={onRemove}>
        <SVGIcon icon="crossDelete" color="#7B7B7B" size={15} />
      </span>
    </div>
  );
}

export default Tag;
