import React from 'react';

export const LoginPageGradient = (): JSX.Element => (
  <svg
    width="390"
    height="945"
    viewBox="0 0 390 945"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className='login-page__gradient'
  >
    <g opacity="0.6" filter="url(#filter0_f_18106_6328)">
      <path
        d="M486.374 256.216C465.945 303.482 150.778 484.079 186.944 525.486C257.455 606.214 704.912 411.023 766.916 323.912C815.923 255.062 903.985 105.071 843.519 43.2667C816.838 15.9957 559.13 -194.7 514.491 -208.356C414.602 -238.914 517.457 184.301 486.374 256.216Z"
        fill="url(#paint0_radial_18106_6328)"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_18106_6328"
        x="-215.943"
        y="-609.931"
        width="1479.93"
        height="1554.57"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="200" result="effect1_foregroundBlur_18106_6328" />
      </filter>
      <radialGradient
        id="paint0_radial_18106_6328"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(240 548) rotate(-14.894) scale(459.365 726.256)"
      >
        <stop offset="0.0294433" stopColor="#6318C1" />
        <stop offset="0.697917" stopColor="#5847FF" />
        <stop offset="1" stopColor="#0075FF" />
      </radialGradient>
    </defs>
  </svg>
);
