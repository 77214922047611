import React from 'react';

export default ({ color = '#FFFFFF' }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.6667 10.6667V5.33333C14.6669 5.22004 14.6383 5.10855 14.5835 5.0094C14.5286 4.91026 14.4495 4.82671 14.3534 4.76667C14.252 4.70815 14.1371 4.67735 14.02 4.67735C13.903 4.67735 13.7881 4.70815 13.6867 4.76667L14.6667 10.6667ZM14.02 4.72735C14.128 4.72735 14.2341 4.75569 14.3276 4.80953L14.327 11.1908C14.229 11.2516 14.1159 11.2836 14.0005 11.2833C13.9062 11.2818 13.8135 11.2586 13.7295 11.2155L13.7291 11.2153L11.3557 10.0286L11.2863 9.99387L11.2834 10.0715C11.2649 10.5762 11.0513 11.054 10.6876 11.4044C10.3239 11.7547 9.83843 11.9503 9.33341 11.95H9.33337H3.33337C2.8162 11.95 2.32021 11.7446 1.95452 11.3789C1.58882 11.0132 1.38337 10.5172 1.38337 10V6C1.38337 5.48283 1.58882 4.98684 1.95452 4.62114C2.32021 4.25545 2.8162 4.05 3.33337 4.05L9.33337 4.05L9.33341 4.05C9.83843 4.04966 10.3239 4.24526 10.6876 4.59564C11.0513 4.94601 11.2649 5.42382 11.2834 5.9285L11.2862 6.00565L11.3555 5.97151L13.7088 4.81151L13.7089 4.81161L13.7117 4.80997C13.8055 4.75584 13.9118 4.72735 14.02 4.72735ZM13.311 9.63139L13.3834 9.66757V9.58667V6.41333V6.33243L13.311 6.36861L11.311 7.36861L11.2834 7.38243V7.41333V8.58667V8.61757L11.311 8.63139L13.311 9.63139ZM9.84013 10.5068C9.97453 10.3724 10.05 10.1901 10.05 10V6C10.05 5.80993 9.97453 5.62764 9.84013 5.49324C9.70573 5.35884 9.52345 5.28333 9.33337 5.28333H3.33337C3.1433 5.28333 2.96102 5.35884 2.82661 5.49324C2.69221 5.62764 2.61671 5.80993 2.61671 6V10C2.61671 10.1901 2.69221 10.3724 2.82661 10.5068C2.96102 10.6412 3.1433 10.7167 3.33337 10.7167H9.33337C9.52345 10.7167 9.70573 10.6412 9.84013 10.5068Z"
      fill={color}
      stroke="black"
      strokeWidth="0.1"
    />
  </svg>
);
