import React, { memo, PropsWithChildren } from 'react';
import cn from 'class-names';

import './MetricsWrapper.scss';

type MetricsWrapperProps = {
  Logo: (...props: unknown[]) => JSX.Element;
  withAddOtherMetricsBtn?: boolean;
  borderLeft?: boolean;
  borderRight?: boolean;
};

const MetricsWrapper = ({
  Logo,
  withAddOtherMetricsBtn = true,
  children,
  borderLeft,
  borderRight,
}: PropsWithChildren<MetricsWrapperProps>): JSX.Element => {
  return (
    <div className="metrics-step">
      <div
        className={cn('metrics-step__settings', {
          'metrics-step__settings--border-left': borderLeft,
          'metrics-step__settings--border-right': borderRight,
        })}
      >
        <div className="metrics-step__logo">{!!Logo ? <Logo /> : null}</div>
        <div className="metrics-step__body">{children}</div>
      </div>
      <div className="metrics-step__footer">
        {withAddOtherMetricsBtn && (
          <div className="metrics-step__add-other-metrics">+ Add Other Metrics</div>
        )}
      </div>
    </div>
  );
};

export default memo(MetricsWrapper);
