// @ts-nocheck
import { addNotification } from '@redux/actions/notifications.actions';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSlate, ReactEditor } from 'slate-react';
import { Editor, Element as SlateElement, Transforms, Selection, BaseEditor } from 'slate';
import ReactTooltip from 'react-tooltip';
import { nanoid } from 'nanoid';
import cn from 'class-names';

import { VariableType } from '@constants/variables';

import Button from '@uikit/Button/Button';
import { SVGIcon } from '@uikit/Icon/Icon';
import { SVGImage, SVGAttachment, SVGAVideo, SVGALink } from '@uikit/Icon';
import { EditorSelectionType } from '@ts/common.types';
import Display from '@components/Display/Display';
import Variable from '@uikit/RichEditor/_components/Variable/Variable';
import VariablesIconButton from '@uikit/RichEditor/_components/VariablesIconButton/VariablesIconButton';
import { uploadImageApi } from '@api/fileImport.api';

import { GradientBackground } from '@uikit/GradientBackgroundLoader/GradientBackgroundLoader';
import { DispatchType } from 'src/store';

import Input from '@uikit/Input/Input';
import { isLinkActive, unwrapLink, insertLink } from './utils/linkUtils';
import { insertImage } from './utils/imageUtils';
import { insertVariable } from './utils/variableUtils';

import ImageNode from './_components/ImageNode/ImageNode';

export function EditorLink({
  attributes,
  children,
  url,
  onChangeLink,
  editor,
  transformLinkUrl,
  element,
}): JSX.Element {
  const handleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();

    onChangeLink({
      url,
      selection: ReactEditor.findPath(editor, element),
    });
  };

  return (
    <a
      {...attributes}
      className="slate-editor__link"
      href={transformLinkUrl(url)}
      onClick={handleClick}
    >
      {children}
    </a>
  );
}

const elementStyles = { margin: 0, lineHeight: 1.5 };

export function Element(props: { [key: string]: any }): JSX.Element {
  const {
    attributes,
    children,
    element,
    onChangeVariable,
    editor,
    transformVariable,
    transformLinkUrl,
    onChangeLink,
    allVariables,
  } = props;

  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>;
    case 'bulleted-list':
      return (
        <ul className="slate-editor__ul" {...attributes}>
          {children}
        </ul>
      );
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>;
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>;
    case 'list-item':
      return (
        <li style={elementStyles} {...attributes}>
          {children}
        </li>
      );
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>;
    case 'text-alignment-right':
      return (
        <p style={{ ...elementStyles, textAlign: 'right' }} {...attributes}>
          {children}
        </p>
      );
    case 'text-alignment-left':
      return (
        <p style={{ ...elementStyles, textAlign: 'left' }} {...attributes}>
          {children}
        </p>
      );
    case 'text-alignment-center':
      return (
        <p style={{ ...elementStyles, textAlign: 'center' }} {...attributes}>
          {children}
        </p>
      );
    case 'paragraph':
      return (
        <p style={elementStyles} {...attributes}>
          {children}
        </p>
      );
    case 'variable':
      return (
        // @ts-ignore
        <Variable
          {...props}
          onChangeVariable={onChangeVariable}
          editor={editor}
          transformVariable={transformVariable}
          allVariables={allVariables}
        />
      );
    case 'ai-variable':
      return (
        // @ts-ignore
        <Variable
          {...props}
          onChangeVariable={onChangeVariable}
          editor={editor}
          transformVariable={transformVariable}
          allVariables={allVariables}
          tooltipText="Click to edit the prompt"
        />
      );
    case 'temp-variable':
      return (
        // @ts-ignore
        <Variable
          {...props}
          onChangeVariable={onChangeVariable}
          editor={editor}
          transformVariable={transformVariable}
          allVariables={allVariables}
          isTempVariable
        />
      );
    case 'link':
      return (
        <EditorLink
          {...props}
          url={element.url}
          attributes={attributes}
          onChangeLink={onChangeLink}
          editor={editor}
          transformLinkUrl={transformLinkUrl}
        >
          {children}
        </EditorLink>
      );
    case 'image':
      return <ImageNode {...props} />;
    case 'imagePlaceholder':
      return <GradientBackground width={100} height={100} />;
    default:
      return (
        <p style={elementStyles} {...attributes}>
          {children}
        </p>
      );
  }
}

export function Leaf({ attributes, children, leaf }): JSX.Element {
  let resChildren = children;

  if (leaf.bold) {
    resChildren = <strong>{resChildren}</strong>;
  }

  if (leaf.code) {
    resChildren = <code>{resChildren}</code>;
  }

  if (leaf.italic) {
    resChildren = <em>{resChildren}</em>;
  }

  if (leaf.underline) {
    resChildren = <u>{resChildren}</u>;
  }

  return <span {...attributes}>{resChildren}</span>;
}

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

const isMarkActive = (editor, format) => {
  try {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
  } catch (e) {
    return false;
  }
};

export const isBlockActive = (editor, format) => {
  try {
    const [match] = Editor.nodes(editor, {
      // @ts-ignore
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
    });

    return !!match;
  } catch (e) {
    return false;
  }
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

export function MarkButton({
  format,
  icon,
  editorRef,
}: {
  format: string;
  icon: string;
  editorRef?: Editor;
}) {
  const editor = editorRef || useSlate();
  const isActive = isMarkActive(editor, format);

  return (
    <Button
      type="ghost"
      size="xxs"
      active={isActive.toString()}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
      isInline
    >
      <SVGIcon icon={icon} color={isActive ? '#2AA4CB' : '#040404'} />
    </Button>
  );
}

export const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    // @ts-ignore
    match: (n) => LIST_TYPES.includes(!Editor.isEditor(n) && SlateElement.isElement(n) && n.type),
    split: true,
  });
  const newProperties: Partial<SlateElement> = {
    // @ts-ignore
    // eslint-disable-next-line no-nested-ternary
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  };
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

export function BlockButton({
  format,
  icon,
  editorRef,
  revertedColor = false,
}: {
  format: string;
  icon: string;
  editorRef?: Editor;
  revertedColor?: boolean;
}) {
  const editor = editorRef || useSlate();
  const isActive = isBlockActive(editor, format);

  return (
    <Button
      type="ghost"
      size="xs"
      active={isActive.toString()}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
      isInline
    >
      {/* eslint-disable-next-line no-nested-ternary */}
      <SVGIcon icon={icon} color={isActive ? '#2AA4CB' : revertedColor ? '#fff' : '#040404'} />
    </Button>
  );
}

export function LinkButton({
  onChangeLink,
  editorRef,
  isDisabled = false,
}: {
  onChangeLink: any;
  editorRef?: Editor;
  isDisabled?: boolean;
}): JSX.Element {
  const editor = editorRef || useSlate();
  const isActive = isLinkActive(editor);

  return (
    <Button
      type="ghost"
      size="xs"
      active={isActive.toString()}
      className={cn({
        'slate-editor__button-disabled': isDisabled,
      })}
      onMouseDown={(event) => {
        event.preventDefault();

        if (isActive) {
          unwrapLink(editor);
        } else {
          insertLink(editor, onChangeLink);
        }
      }}
      isInline
      disabled={isDisabled}
    >
      <SVGIcon icon="link" color={isActive ? '#2AA4CB' : '#040404'} size={12} />
    </Button>
  );
}

type VariableButtonPropsType = {
  lastSelection: EditorSelectionType;
  editor: Editor;

  onCreateNew?: () => void;
  variables?: VariableType[];
};

export function AddFileButton({
  onFileUpload,
}: {
  onFileUpload: (file: File) => void;
}): JSX.Element {
  const inputId = nanoid();
  const inputRef = useRef<HTMLInputElement>(null);
  const handleUpload = (e) => {
    onFileUpload(e.target.files);
    inputRef.current.value = '';
  };

  return (
    <div className="slate-editor-attachment-button">
      <label
        className="slate-editor-attachment-button__label"
        htmlFor={`input-file-field-${inputId}`}
      >
        <SVGAttachment /> Add attachment
      </label>
      <input
        ref={inputRef}
        onChange={handleUpload}
        id={`input-file-field-${inputId}`}
        className="slate-editor-attachment-button__input-field"
        type="file"
        multiple
      />
    </div>
  );
}

export function InsertImageButton({
  editor,
  lastSelection,
  setImageUploading,
}: {
  editor: BaseEditor;
  lastSelection: Selection;
  setImageUploading: Dispatch<SetStateAction<boolean>>;
}) {
  const inputId = nanoid();
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch<DispatchType>();
  const handleLoadFile = ({ target: { files, value } }) => {
    // eslint-disable-next-line no-param-reassign

    if (files && files.length > 0) {
      setImageUploading(true);
      const readableFileSizeMB = Math.floor(files[0].size / 1000000);

      if (readableFileSizeMB > 25) {
        dispatch(addNotification({ title: 'Maximum file size is 25MB', type: 'warning' }));
        return;
      }

      files[0]
        .arrayBuffer()
        .then((buffer) => {
          const selection = lastSelection;
          // insertImagePlaceholder(editor, selection);

          return uploadImageApi(files[0], buffer).then(({ uid }) => ({
            uid,
            selection,
          }));
        })
        .then(({ uid, selection }) => {
          insertImage(editor, `${BACK_HOST}/mails/api/images/${uid}`, lastSelection, selection);
          setImageUploading(false);
        });
    }
  };

  return (
    <span className="slate-editor-image-button">
      <label className="slate-editor-image-button__label" htmlFor={`input-image-field-${inputId}`}>
        <SVGImage /> Add image
      </label>
      <input
        ref={inputRef}
        onChange={handleLoadFile}
        id={`input-image-field-${inputId}`}
        className="slate-editor-image-button__input-field"
        type="file"
        accept=".jpg, .jpeg, .png, .svg"
      />
    </span>
  );
}

export function MoreActionsButton({
  onFileUpload,
  withAttachments = true,
  lastSelection,
  setImageUploading,
}: {
  onFileUpload?: any;
  withAttachments?: boolean;
  lastSelection?: Selection;
  setImageUploading?: Dispatch<SetStateAction<boolean>>;
}): JSX.Element {
  const tooltipId = useMemo(() => nanoid(), []);
  const editor = useSlate();

  return (
    <span className="slate-editor-more-actions">
      <span
        className="slate-editor-more-actions__icon"
        data-for={`slate-editor-more-actions-${tooltipId}`}
        data-tip=""
      >
        <div className="slate-editor-more-actions__tap-block">
          <SVGIcon icon="actionsTwoDots" color="#000" size={10} />
        </div>
      </span>
      <ReactTooltip
        id={`slate-editor-more-actions-${tooltipId}`}
        className="react-tooltip slate-editor-more-actions__tooltip"
        place="top"
        effect="solid"
        event="click"
        globalEventOff="click"
        arrowColor="transparent"
        clickable
      >
        <div className="slate-editor-more-actions__tooltip">
          <Display isVisible={withAttachments}>
            <div className="slate-editor-more-actions__tooltip-item">
              <AddFileButton onFileUpload={onFileUpload} />
            </div>
          </Display>
          <div className="slate-editor-more-actions__tooltip-item">
            <InsertImageButton
              editor={editor}
              lastSelection={lastSelection}
              setImageUploading={setImageUploading}
            />
          </div>
        </div>
      </ReactTooltip>
    </span>
  );
}

export function InlineVariableButton({
  lastSelection,
  editor,
  variables,
  onCreateNew,
}: VariableButtonPropsType): JSX.Element {
  const onSelectVar = (variable: VariableType) => {
    insertVariable(editor, variable, lastSelection);
  };

  return (
    <VariablesIconButton
      variables={variables}
      className="editor-variables-button-inline"
      onSelectVar={onSelectVar}
      onCreateNew={onCreateNew}
      id="editor-variables-inline-button"
    />
  );
}

export function AbsoluteVariableButton({
  lastSelection,
  editor,
  containerRef,
  variables,
  onCreateNew,
}): JSX.Element {
  const [scrollTop, setScrollTop] = useState(0);
  let style = {};

  try {
    const domSelection = window.getSelection();
    const domRange = domSelection.getRangeAt(0);
    const rect = domRange?.getBoundingClientRect();
    const containerRect = containerRef?.current.getBoundingClientRect();

    style = {
      top: `${Math.max(
        12,
        Math.min(rect?.top + window.pageYOffset - 6 - containerRect.top, containerRect.height - 124)
      )}px`,
    };
  } catch (e) {}

  useEffect(() => {
    containerRef?.current?.firstChild?.addEventListener('scroll', (e) => {
      window.requestAnimationFrame(() => {
        setScrollTop(e.target.scrollTop);
      });
    });
  }, []);

  if (!lastSelection) {
    return null;
  }

  return (
    <div
      className="absolute-slate-variable"
      style={{ ...style, transform: `translateY(${scrollTop})` }}
    >
      <InlineVariableButton
        lastSelection={lastSelection}
        editor={editor}
        variables={variables}
        onCreateNew={onCreateNew}
      />
    </div>
  );
}

export function FlagMarkButton({
  titleHeader,
  templates,
  isSearchable,
  handleManageClick,
  onTemplateSelected,
}): JSX.Element {
  const tooltipId = useMemo(() => nanoid(), []);
  const [searchValue, setSearchValue] = useState<string>('');

  const filteredTemplates = isSearchable
    ? templates.filter(({ title }) => title.includes(searchValue))
    : templates;

  return (
    <div className="slate-editor-flag-mark">
      <span
        className="slate-editor-flag-mark__icon"
        data-for={`slate-editor-flag-mark-${tooltipId}`}
        data-tip=""
      >
        <div className="slate-editor-flag-mark__tap-block">
          <SVGIcon icon="flagMark" />
        </div>
      </span>

      <ReactTooltip
        id={`slate-editor-flag-mark-${tooltipId}`}
        className="react-tooltip slate-editor-flag-mark__tooltip"
        place="top"
        effect="solid"
        event="click"
        globalEventOff="click"
        arrowColor="transparent"
        clickable
      >
        <div className="slate-editor-flag-mark__tooltip">
          <div className="slate-editor-flag-mark__tooltip-header">{titleHeader}</div>
          {isSearchable && (
            <Input
              style={{ color: '#ffffff' }}
              icon="search"
              iconColor="#C4C6CD"
              placeholder="Search"
              isTransparent
              onChange={(event) => setSearchValue(event.target.value.trim())}
            />
          )}
          <div className="slate-editor-flag-mark__tooltip-body">
            {filteredTemplates.map((item) => (
              <div
                key={item.id}
                className="slate-editor-flag-mark__tooltip-header-item"
                onClick={() => onTemplateSelected(item.id)}
              >
                <div className="slate-editor-flag-mark__tooltip-header-title">
                  <SVGIcon icon={item.icon} color="#ffffff" />
                  <div className="slate-editor-flag-mark__tooltip-header-title-content">
                    <p>{item.title}</p>
                    <span>{item.subject}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="slate-editor-flag-mark__tooltip-footer">
            <Button
              type="ghost"
              className="slate-editor-flag-mark__tooltip-footer-button"
              onClick={handleManageClick}
            >
              Manage {titleHeader}
            </Button>
          </div>
        </div>
      </ReactTooltip>
    </div>
  );
}

export function AiButton({
  titleHeader,
  extraTitle,
  isSearchable,
  languages,
  options,
  isShownBlock,
  backClick,
  isDisabledAIBtn,
  onActionSelected,
  onLanguageSelected,
  isLoading,
}): JSX.Element {
  const tooltipId = useMemo(() => nanoid(), []);
  const [languageSearch, setLanguageSearchValue] = useState<string>('');
  const [isTooltipVisible, setTooltipVisible] = useState(true);

  const filteredLanguages = languages.filter(({ label }) =>
    label.toLowerCase().includes(languageSearch.toLowerCase())
  );

  return (
    <div className={`slate-editor-ai ${isDisabledAIBtn ? 'slate-editor-ai__button-disabled' : ''}`}>
      <span className="slate-editor-ai__icon" data-for={`slate-editor-ai-${tooltipId}`} data-tip="">
        {isLoading ? (
          <span className="inbox-widget__spinner">
            <SVGIcon icon="spinner" />
          </span>
        ) : (
          <div className="slate-editor-ai__tap-block">
            <SVGIcon icon="chatGPT" />
          </div>
        )}
      </span>

      {!isTooltipVisible ? null : isDisabledAIBtn ? null : (
        <ReactTooltip
          id={`slate-editor-ai-${tooltipId}`}
          className="react-tooltip slate-editor-ai__tooltip"
          place="top"
          effect="solid"
          event="click"
          globalEventOff="click"
          arrowColor="transparent"
          clickable
        >
          {!isShownBlock ? (
            <div className="slate-editor-ai__tooltip">
              <div className="slate-editor-ai__tooltip-header">{titleHeader}</div>
              <div className="slate-editor-ai__tooltip-body">
                {options.map((option, index) => (
                  <div key={index} className="slate-editor-ai__tooltip-header-item">
                    <div className="slate-editor-ai__tooltip-header-item-options">
                      {option.optionTitle}
                    </div>
                    {option.optionItems.map((item, indexOption) => {
                      const optionItemClasses = cn(
                        'slate-editor-ai__tooltip-header-item-options-element',
                        {
                          'slate-editor-ai__tooltip-header-item-options-element--disabled':
                            item.isDisabled,
                        }
                      );
                      return (
                        <div
                          className={optionItemClasses}
                          key={indexOption}
                          onClick={() => {
                            if (item.isDisabled) {
                              return;
                            }
                            onActionSelected(item.action);

                            if (!item.preventCloseTooltip) {
                              setTooltipVisible(false);
                              setTimeout(() => setTooltipVisible(true));
                            }
                          }}
                        >
                          <SVGIcon icon={item.icon as SvgIconType} color={item.iconColor} />
                          <p>{item.title}</p>
                          <span className="lock-btn">
                            <SVGIcon icon="lock" color="white" size={15} />
                          </span>
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="slate-editor-ai__tooltip extra-tooltip">
              <div className="slate-editor-ai__tooltip-header">
                <Button
                  type="ghost"
                  className="slate-editor-ai__tooltip-header-button"
                  onClick={backClick}
                >
                  <SVGIcon icon="arrowLeft" color="#7974F6" />
                  Back
                </Button>
                / {extraTitle}
              </div>

              {isSearchable && (
                <Input
                  icon="search"
                  iconColor="#C4C6CD"
                  placeholder="Search"
                  isTransparent
                  value={languageSearch}
                  onChange={(event) => setLanguageSearchValue(event.target.value.trim())}
                />
              )}

              <div className="slate-editor-ai__tooltip-body">
                {filteredLanguages.map((item: { label: string; value: string }) => (
                  <div
                    key={item.value}
                    className="slate-editor-ai__tooltip-header-option"
                    onClick={() => {
                      onLanguageSelected(item.label);
                      setTooltipVisible(false);
                      setTimeout(() => setTooltipVisible(true));
                    }}
                  >
                    {item.label}
                  </div>
                ))}
              </div>
            </div>
          )}
        </ReactTooltip>
      )}
    </div>
  );
}
