import React from 'react';

const PenSquared = ({ color = 'white' }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.9999 8.00012C13.8231 8.00012 13.6535 8.07036 13.5285 8.19538C13.4035 8.32041 13.3333 8.48998 13.3333 8.66679V12.6668C13.3333 12.8436 13.263 13.0132 13.138 13.1382C13.013 13.2632 12.8434 13.3335 12.6666 13.3335H3.33325C3.15644 13.3335 2.98687 13.2632 2.86185 13.1382C2.73682 13.0132 2.66659 12.8436 2.66659 12.6668V3.33346C2.66659 3.15664 2.73682 2.98708 2.86185 2.86205C2.98687 2.73703 3.15644 2.66679 3.33325 2.66679H7.33325C7.51006 2.66679 7.67963 2.59655 7.80466 2.47153C7.92968 2.3465 7.99992 2.17693 7.99992 2.00012C7.99992 1.82331 7.92968 1.65374 7.80466 1.52872C7.67963 1.40369 7.51006 1.33346 7.33325 1.33346H3.33325C2.80282 1.33346 2.29411 1.54417 1.91904 1.91924C1.54397 2.29431 1.33325 2.80302 1.33325 3.33346V12.6668C1.33325 13.1972 1.54397 13.7059 1.91904 14.081C2.29411 14.4561 2.80282 14.6668 3.33325 14.6668H12.6666C13.197 14.6668 13.7057 14.4561 14.0808 14.081C14.4559 13.7059 14.6666 13.1972 14.6666 12.6668V8.66679C14.6666 8.48998 14.5963 8.32041 14.4713 8.19538C14.3463 8.07036 14.1767 8.00012 13.9999 8.00012ZM3.99992 8.50679V11.3335C3.99992 11.5103 4.07016 11.6798 4.19518 11.8049C4.32021 11.9299 4.48977 12.0001 4.66659 12.0001H7.49325C7.58099 12.0006 7.66796 11.9838 7.74919 11.9506C7.83041 11.9175 7.90429 11.8686 7.96659 11.8068L12.5799 7.18679L14.4733 5.33346C14.5357 5.27148 14.5853 5.19775 14.6192 5.11651C14.653 5.03527 14.6705 4.94813 14.6705 4.86012C14.6705 4.77211 14.653 4.68498 14.6192 4.60374C14.5853 4.5225 14.5357 4.44876 14.4733 4.38679L11.6466 1.52679C11.5846 1.4643 11.5109 1.41471 11.4296 1.38086C11.3484 1.34702 11.2613 1.32959 11.1733 1.32959C11.0852 1.32959 10.9981 1.34702 10.9169 1.38086C10.8356 1.41471 10.7619 1.4643 10.6999 1.52679L8.81992 3.41346L4.19325 8.03346C4.13146 8.09575 4.08258 8.16963 4.0494 8.25085C4.01623 8.33208 3.99941 8.41905 3.99992 8.50679ZM11.1733 2.94012L13.0599 4.82679L12.1133 5.77346L10.2266 3.88679L11.1733 2.94012ZM5.33325 8.78012L9.28659 4.82679L11.1733 6.71346L7.21992 10.6668H5.33325V8.78012Z"
      fill={color}
    />
    <path
      d="M13.9999 8.00012C13.8231 8.00012 13.6535 8.07036 13.5285 8.19538C13.4035 8.32041 13.3333 8.48998 13.3333 8.66679V12.6668C13.3333 12.8436 13.263 13.0132 13.138 13.1382C13.013 13.2632 12.8434 13.3335 12.6666 13.3335H3.33325C3.15644 13.3335 2.98687 13.2632 2.86185 13.1382C2.73682 13.0132 2.66659 12.8436 2.66659 12.6668V3.33346C2.66659 3.15664 2.73682 2.98708 2.86185 2.86205C2.98687 2.73703 3.15644 2.66679 3.33325 2.66679H7.33325C7.51006 2.66679 7.67963 2.59655 7.80466 2.47153C7.92968 2.3465 7.99992 2.17693 7.99992 2.00012C7.99992 1.82331 7.92968 1.65374 7.80466 1.52872C7.67963 1.40369 7.51006 1.33346 7.33325 1.33346H3.33325C2.80282 1.33346 2.29411 1.54417 1.91904 1.91924C1.54397 2.29431 1.33325 2.80302 1.33325 3.33346V12.6668C1.33325 13.1972 1.54397 13.7059 1.91904 14.081C2.29411 14.4561 2.80282 14.6668 3.33325 14.6668H12.6666C13.197 14.6668 13.7057 14.4561 14.0808 14.081C14.4559 13.7059 14.6666 13.1972 14.6666 12.6668V8.66679C14.6666 8.48998 14.5963 8.32041 14.4713 8.19538C14.3463 8.07036 14.1767 8.00012 13.9999 8.00012ZM3.99992 8.50679V11.3335C3.99992 11.5103 4.07016 11.6798 4.19518 11.8049C4.32021 11.9299 4.48977 12.0001 4.66659 12.0001H7.49325C7.58099 12.0006 7.66796 11.9838 7.74919 11.9506C7.83041 11.9175 7.90429 11.8686 7.96659 11.8068L12.5799 7.18679L14.4733 5.33346C14.5357 5.27148 14.5853 5.19775 14.6192 5.11651C14.653 5.03527 14.6705 4.94813 14.6705 4.86012C14.6705 4.77211 14.653 4.68498 14.6192 4.60374C14.5853 4.5225 14.5357 4.44876 14.4733 4.38679L11.6466 1.52679C11.5846 1.4643 11.5109 1.41471 11.4296 1.38086C11.3484 1.34702 11.2613 1.32959 11.1733 1.32959C11.0852 1.32959 10.9981 1.34702 10.9169 1.38086C10.8356 1.41471 10.7619 1.4643 10.6999 1.52679L8.81992 3.41346L4.19325 8.03346C4.13146 8.09575 4.08258 8.16963 4.0494 8.25085C4.01623 8.33208 3.99941 8.41905 3.99992 8.50679ZM11.1733 2.94012L13.0599 4.82679L12.1133 5.77346L10.2266 3.88679L11.1733 2.94012ZM5.33325 8.78012L9.28659 4.82679L11.1733 6.71346L7.21992 10.6668H5.33325V8.78012Z"
      stroke={color}
    />
  </svg>
);

export default PenSquared;
