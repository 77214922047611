import React, { useRef, useState, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import ReactTooltip from 'react-tooltip';
import { PersonBaseType } from '@ts/people.types';
import { WebsiteType } from '@ts/company.types';
import { getDateShort } from '@utils/date';
import { getPreviewEmailsApi } from '@api/mailbox.api';
import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';
import { SVGIcon } from '@uikit/Icon/Icon';
import Loader from '@uikit/Loader/Loader';
import ContactsSidebarCRMSection from '@components/ContactSidebarCRM/_components/ContactsSidebarCRMSection/ContactsSidebarCRMSection';
import './ContactsSidebarCRMSectionEmails.scss';
import { ThreadPreviewResponseType } from '@ts/mailboxInbox.types';
import ContactsSidebarCRMSectionEmailsPreview from '@components/ContactSidebarCRM/_components/ContactsSidebarCRMSectionEmails/_components/ContactsSidebarCRMSectionEmailsPreview';

interface ContactsSidebarCRMSectionEmailsProps {
  website?: WebsiteType;
  person?: PersonBaseType;
  index: number;
}

const ContactsSidebarCRMSectionEmails: React.FC<ContactsSidebarCRMSectionEmailsProps> = ({
  website,
  person,
  index,
}) => {
  const ref = useRef(null);
  const workspaceId = useCurrentWorkspaceId();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const { data, isLoading } = useQuery<ThreadPreviewResponseType[]>(
    ['contacts-sidebar-emails', person?.id, website?.id],
    () => getPreviewEmailsApi(workspaceId, person?.id, website?.id),
    {
      enabled: !!(person?.id || website?.id) && isExpanded,
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 1000 * 60 * 60,
    }
  );

  useEffect(() => {
    if (data?.length) {
      ref.current.setOpen(true);
    }
  }, [data]);

  const emailsList = useMemo(() => {
    return data?.slice(0, isExpanded ? undefined : 5)?.map((email) => (
      <React.Fragment key={email.uid}>
        <div
          onClickCapture={() => ReactTooltip.hide()}
          className="contacts-sidebar-section-emails__email"
          data-for={`contacts-sidebar-section-emails-${email.uid}`}
          data-tip=""
        >
          <div className="contacts-sidebar-section-emails__email-date">
            {email.lastActivityTime > 0 ? getDateShort(new Date(email.lastActivityTime)) : null}
          </div>
          <div className="contacts-sidebar-section-emails__email-title">
            <SVGIcon icon="email" color="#221CB6" size={20} />
            <span>{email.fromEmail}</span>
          </div>
          <div className="contacts-sidebar-section-emails__email-text">{email.subject}</div>
        </div>
        <ReactTooltip
          arrowColor="#ffffff"
          id={`contacts-sidebar-section-emails-${email.uid}`}
          className="react-tooltip contacts-sidebar-section-emails__email-tooltip"
          place="left"
          effect="solid"
          event="click"
          globalEventOff="click"
          clickable
        >
          <ContactsSidebarCRMSectionEmailsPreview email={email} />
        </ReactTooltip>
      </React.Fragment>
    ));
  }, [data, isExpanded]);

  return (
    <ContactsSidebarCRMSection
      ref={ref}
      index={index}
      id="emails"
      title="Conversation history"
      onOpen={() => setIsExpanded(true)}
    >
      <div className="contacts-sidebar-section-emails">
        <Loader isLoading={isLoading}>
          {emailsList?.length ? (
            emailsList
          ) : (
            <div className="contacts-sidebar-section-emails--empty">Empty</div>
          )}
          {!isExpanded && data?.length > 5 && (
            <div className="contact-sidebar-crm__show-more-btn" onClick={() => setIsExpanded(true)}>
              Show More
              <SVGIcon icon="chevron" size={8} />
            </div>
          )}
        </Loader>
      </div>
    </ContactsSidebarCRMSection>
  );
};

export default ContactsSidebarCRMSectionEmails;
