import React from 'react';

import Button from '@uikit/Button/Button';
import Loader from '@uikit/Loader/Loader';

import './UpsellSidebarFooter.scss';

type UpsellSidebarFooterType = {
  priceDescription?: string;
  onCancel: () => void;
  totalPrice: number;
  isLoadingPrice: boolean;
  isPayButtonLoading: boolean;
  onPay: () => void;
};

const UpsellSidebarFooter = ({
  priceDescription = 'Due today :',
  onCancel,
  totalPrice,
  isLoadingPrice,
  isPayButtonLoading,
  onPay,
}: UpsellSidebarFooterType): JSX.Element => {
  return (
    <div className="upsell-sidebar-footer">
      <div className="upsell-sidebar-footer__price-block">
        <span className="upsell-sidebar-footer__price-label">{priceDescription}</span>
        {isLoadingPrice ? (
          <div className="upsell-sidebar-footer__price-loader">
            <Loader backgroundColor="#fff" isLoading />
          </div>
        ) : (
          <span className="upsell-sidebar-footer__price">${totalPrice}</span>
        )}
      </div>
      <div className="upsell-sidebar-footer__buttons">
        <Button type="grey" className="upsell-sidebar-footer__cancel-button" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          className="upsell-sidebar-footer__pay-button"
          onClick={onPay}
          isLoading={isPayButtonLoading}
          disabled={isPayButtonLoading || isLoadingPrice}
        >
          Pay and upgrade
        </Button>
      </div>
    </div>
  );
};

export default UpsellSidebarFooter;
