// package: search.summary
// file: search-summary.proto

var search_summary_pb = require("./search-summary_pb");
var common_pb = require("./common_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var SummarySearchService = (function () {
  function SummarySearchService() {}
  SummarySearchService.serviceName = "search.summary.SummarySearchService";
  return SummarySearchService;
}());

SummarySearchService.getSummariesByUrl = {
  methodName: "getSummariesByUrl",
  service: SummarySearchService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.StringRequest,
  responseType: search_summary_pb.SummaryContainerResponse
};

SummarySearchService.searchSummariesByUrl = {
  methodName: "searchSummariesByUrl",
  service: SummarySearchService,
  requestStream: false,
  responseStream: true,
  requestType: common_pb.StringRequest,
  responseType: search_summary_pb.SummaryExtractionProcess
};

exports.SummarySearchService = SummarySearchService;

function SummarySearchServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

SummarySearchServiceClient.prototype.getSummariesByUrl = function getSummariesByUrl(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(SummarySearchService.getSummariesByUrl, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SummarySearchServiceClient.prototype.searchSummariesByUrl = function searchSummariesByUrl(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(SummarySearchService.searchSummariesByUrl, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

exports.SummarySearchServiceClient = SummarySearchServiceClient;

