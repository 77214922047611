import React from 'react';

export default ({ color = '#C4C4C4' }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.5 16.6667H16.6666V4.16666C16.6666 3.94565 16.5788 3.73369 16.4225 3.57741C16.2663 3.42113 16.0543 3.33333 15.8333 3.33333C15.6123 3.33333 15.4003 3.42113 15.244 3.57741C15.0878 3.73369 15 3.94565 15 4.16666V16.6667H13.3333V7.5C13.3333 7.27898 13.2455 7.06702 13.0892 6.91074C12.9329 6.75446 12.721 6.66666 12.5 6.66666C12.2789 6.66666 12.067 6.75446 11.9107 6.91074C11.7544 7.06702 11.6666 7.27898 11.6666 7.5V16.6667H9.99996V10.8333C9.99996 10.6123 9.91216 10.4004 9.75588 10.2441C9.5996 10.0878 9.38764 10 9.16663 10C8.94561 10 8.73365 10.0878 8.57737 10.2441C8.42109 10.4004 8.33329 10.6123 8.33329 10.8333V16.6667H6.66663V14.1667C6.66663 13.9457 6.57883 13.7337 6.42255 13.5774C6.26627 13.4211 6.05431 13.3333 5.83329 13.3333C5.61228 13.3333 5.40032 13.4211 5.24404 13.5774C5.08776 13.7337 4.99996 13.9457 4.99996 14.1667V16.6667H3.33329V2.5C3.33329 2.27898 3.2455 2.06702 3.08922 1.91074C2.93293 1.75446 2.72097 1.66666 2.49996 1.66666C2.27895 1.66666 2.06698 1.75446 1.9107 1.91074C1.75442 2.06702 1.66663 2.27898 1.66663 2.5V17.5C1.66663 17.721 1.75442 17.933 1.9107 18.0893C2.06698 18.2455 2.27895 18.3333 2.49996 18.3333H17.5C17.721 18.3333 17.9329 18.2455 18.0892 18.0893C18.2455 17.933 18.3333 17.721 18.3333 17.5C18.3333 17.279 18.2455 17.067 18.0892 16.9107C17.9329 16.7545 17.721 16.6667 17.5 16.6667Z"
      fill={color}
    />
  </svg>
);
