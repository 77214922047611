import React from 'react';

import { UserProfileType } from '@ts/user.types';

import EmailSubscriptions from '@components/Settings/Profile/_components/EmailSubscribtions/EmailSubscriptions';
import PasswordBlock from '@components/Settings/Profile/_components/PasswordBlock/PasswordBlock';
import EmailBlock from '@components/Settings/Profile/_components/EmailBlock/EmailBlock';
import PersonalInfoBlock from '@components/Settings/Profile/_components/PersonalInfoBlock/PersonalInfoBlock';

import './Profile.scss';

type ProfilePropsType = {
  profile: UserProfileType;
};

const Profile = ({ profile }: ProfilePropsType): JSX.Element => {
  return (
    <div className="profile-settings">
      <div className="profile-settings__personal-info-wrapper">
        <PersonalInfoBlock profile={profile} />
        <EmailBlock email={profile.email} />
        <PasswordBlock profile={profile} />
      </div>
      <div className="profile-settings__block profile-settings__email-subscriptions-wrapper">
        <p className="profile-settings__block-title">Email Subscriptions</p>
        <EmailSubscriptions />
      </div>
    </div>
  );
};

export default Profile;
