import React from 'react';

export default ({ color = '#C4C6CD' }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.9795 5.73914C16.6183 6.22503 16.1817 6.64888 15.6865 6.99432C15.6865 7.12125 15.6865 7.24817 15.6865 7.38215C15.6904 9.68715 14.7774 11.8973 13.152 13.5173C11.5267 15.1372 9.32497 16.0313 7.04048 15.9992C5.71977 16.0036 4.41597 15.6995 3.23121 15.1107C3.16733 15.0825 3.12613 15.0188 3.12637 14.9485V14.8709C3.12637 14.7697 3.20773 14.6876 3.3081 14.6876C4.60632 14.6444 5.85824 14.1905 6.88671 13.3901C5.71163 13.3662 4.65436 12.6643 4.1678 11.5849C4.14322 11.5259 4.15088 11.4583 4.18799 11.4065C4.22511 11.3546 4.28633 11.326 4.34953 11.331C4.70666 11.3672 5.06736 11.3337 5.41193 11.2323C4.11474 10.9608 3.14005 9.87481 3.00056 8.54566C2.9956 8.4819 3.02394 8.42014 3.07533 8.38269C3.12672 8.34525 3.19374 8.33753 3.25218 8.36232C3.60029 8.51722 3.97607 8.5988 4.35652 8.60208C3.21987 7.84977 2.72892 6.41886 3.16132 5.1186C3.20595 4.99226 3.31318 4.89892 3.44361 4.87289C3.57403 4.84686 3.70841 4.89199 3.79736 4.99168C5.33119 6.63785 7.43869 7.61879 9.67551 7.72768C9.61822 7.49713 9.59004 7.2602 9.59164 7.02253C9.61256 5.77625 10.3773 4.66605 11.5277 4.21167C12.6782 3.75729 13.9867 4.04865 14.8407 4.94937C15.4229 4.83753 15.9857 4.64035 16.5112 4.36409C16.5497 4.33985 16.5985 4.33985 16.637 4.36409C16.6611 4.40292 16.6611 4.45218 16.637 4.49101C16.3825 5.07884 15.9524 5.57186 15.4069 5.90133C15.8846 5.84546 16.354 5.73182 16.8048 5.56285C16.8427 5.5368 16.8926 5.5368 16.9306 5.56285C16.9624 5.57752 16.9862 5.60558 16.9956 5.63955C17.005 5.67351 16.9991 5.70995 16.9795 5.73914Z"
      fill={color}
    />
  </svg>
);
