import { useQuery } from 'react-query';
import { getInboxAccountsPresetByWorkspaceIdApi } from '@api/inbox.api';
import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';
import { InboxAccountsPresetType } from '@ts/mailboxInbox.types';

export default (): [InboxAccountsPresetType[], boolean] => {
  const currentWorkspaceId = useCurrentWorkspaceId();

  const { data: allMailboxes, isFetching } = useQuery(
    ['inbox-mailboxes-preset', currentWorkspaceId],
    () => getInboxAccountsPresetByWorkspaceIdApi(currentWorkspaceId),
    {
      enabled: !!currentWorkspaceId,
      refetchOnWindowFocus: false,
    }
  );

  return [allMailboxes || [], isFetching];
};
