import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  CustomVariableType,
  CustomVariableValueRelatedToMap,
} from 'respona_api/generated/common_pb';

import { Button } from '@uikit/Button/Button';

import CustomFieldsSidebar from '@components/CustomFieldsSidebar/CustomFieldsSidebar';
import CustomFields from '@components/Settings/CustomFields/CustomFields';
import PageHeader from '@components/PageHeader/PageHeader';
import { VariableType } from '@constants/variables';
import useAllVariablesInEditor from '@hooks/useAllVariablesInEditor';

function CustomFieldsSettingsPage() {
  const customFieldsRef = useRef<{
    setRelation: (
      value: CustomVariableValueRelatedToMap[keyof CustomVariableValueRelatedToMap]
    ) => void;
    relation: CustomVariableValueRelatedToMap[keyof CustomVariableValueRelatedToMap];
  }>();

  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [activeVariable, setActiveVariable] = useState<VariableType>(null);
  const { variables, isCustomVariablesLoading } = useAllVariablesInEditor(null);

  useEffect(() => {
    const isOpenSideBar = new URLSearchParams(location.search).get('open-create-sidebar') !== null;
    const updateVariable = new URLSearchParams(location.search).get('open-update-sidebar');

    if (isOpenSideBar) {
      setSidebarOpen(true);
    }

    if (updateVariable && variables.length > 0) {
      const variable = variables.find(({ name }) => name === updateVariable);

      if (variable && activeVariable?.name !== variable.name) {
        setSidebarOpen(true);
        setActiveVariable(variable);
      }
    }
  }, [variables, location.search]);

  function handleEditSidebarClick(row: VariableType) {
    if (
      (row.type === CustomVariableType.OPEN_AI ||
        row.type === CustomVariableType.SELECT ||
        row.type === CustomVariableType.MULTI_SELECT) &&
      !row.isDefault
    ) {
      setSidebarOpen(true);
      setActiveVariable(row);
    }
  }

  return (
    <>
      <PageHeader
        title="Custom fields"
        renderHeaderActions={() => (
          <Button loadingText="Starting campaign..." onClick={() => setSidebarOpen(true)}>
            Create custom field
          </Button>
        )}
      />

      <CustomFields
        customFieldsRef={customFieldsRef}
        onRowClick={(value) => handleEditSidebarClick(value)}
      />

      <CustomFieldsSidebar
        isOpen={sidebarOpen}
        isUpdate={activeVariable !== null}
        relatedTo={customFieldsRef.current?.relation || 0}
        onClose={() => {
          setActiveVariable(null);
          setSidebarOpen(false);
        }}
        onSave={({ relatedTo }) => customFieldsRef.current?.setRelation?.(relatedTo)}
        updatedVariable={activeVariable}
      />
    </>
  );
}

export default CustomFieldsSettingsPage;
