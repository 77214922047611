import React from 'react';

export default ({ color = '#C4C6CD' }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17 13.1111C17 13.51 16.6866 13.8333 16.3 13.8333C15.39 13.8333 14.9 14.1294 14.9 14.5555C14.9 14.9544 14.5866 15.2778 14.2 15.2778C13.619 15.2778 13.5 14.9383 12.961 14.9383C12.7939 14.9603 12.6362 15.0303 12.506 15.1405C12.3195 15.2808 12.1229 15.4064 11.918 15.5161C11.4872 15.7656 11.0101 15.9182 10.518 15.9639C10.1789 16.012 9.83507 16.012 9.496 15.9639C9.00393 15.9182 8.5268 15.7656 8.096 15.5161C7.8911 15.4064 7.69454 15.2808 7.508 15.1405C7.37777 15.0303 7.22007 14.9603 7.053 14.9383C6.5 14.9383 6.381 15.2778 5.8 15.2778C5.4134 15.2778 5.1 14.9544 5.1 14.5555C5.1 14.1294 4.61 13.8333 3.7 13.8333C3.3134 13.8333 3 13.51 3 13.1111C3 12.7122 3.3134 12.3889 3.7 12.3889C4.74666 12.2973 5.60563 11.4967 5.8 10.4317C5.85023 10.2608 5.76918 10.078 5.611 10.0055C5.09181 9.75931 4.59313 9.46944 4.12 9.13888C3.8374 8.94149 3.67834 8.60354 3.70272 8.25234C3.72711 7.90114 3.93124 7.59004 4.23822 7.43623C4.54521 7.28242 4.9084 7.30927 5.191 7.50666L5.541 7.73777C5.59641 7.77461 5.66759 7.77461 5.723 7.73777C5.77616 7.70108 5.80568 7.63738 5.8 7.57166C5.8 7.49221 5.8 7.41277 5.8 7.33332C5.8 4.9401 7.6804 3 10 3C12.3196 3 14.2 4.9401 14.2 7.33332C14.2 7.41277 14.2 7.49221 14.2 7.57166C14.1958 7.63886 14.2282 7.70289 14.284 7.73777C14.3394 7.77461 14.4106 7.77461 14.466 7.73777L14.816 7.50666C15.0986 7.31056 15.4611 7.28491 15.767 7.43936C16.0729 7.59382 16.2757 7.90492 16.299 8.25547C16.3223 8.60603 16.1626 8.94278 15.88 9.13888C15.4069 9.46944 14.9082 9.75931 14.389 10.0055C14.2308 10.078 14.1498 10.2608 14.2 10.4317C14.3944 11.4967 15.2533 12.2973 16.3 12.3889C16.6866 12.3889 17 12.7122 17 13.1111Z"
      fill={color}
    />
  </svg>
);
