import React, { useCallback, useRef, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import { setPeopleTagsData } from '@redux/actions/people.actions';
import { DispatchType } from 'src/store';
import { PersonBaseType } from '@ts/people.types';
import { TagsType, TagType } from '@ts/common.types';
import { WebsiteType } from '@ts/company.types';

import {
  addCompanyTagApi,
  getAllCompanyTagsApi,
  getTagsByCompanyIdApi,
  removeCompanyTagApi,
} from '@api/website.api';
import { addTagApi, getAllTagsApi, getTagsByPersonIdApi, removeTagApi } from '@api/people.api';

import { addNotification } from '@redux/actions/notifications.actions';

import { SVGIcon } from '@uikit/Icon/Icon';
import Loader from '@uikit/Loader/Loader';
import TooltipSearchableContent from '@uikit/TooltipSearchableContent/TooltipSearchableContent';

import ContactsSidebarCRMSection from '@components/ContactSidebarCRM/_components/ContactsSidebarCRMSection/ContactsSidebarCRMSection';
import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';

import './ContactsSidebarCRMSectionTags.scss';
import Tag from "@components/ContactSidebarCRM/_components/ContactsSidebarCRMSectionTags/_components/Tag";

function ContactsSidebarCRMSectionTags({
  website,
  person,
  index,
}: {
  website?: WebsiteType;
  person?: PersonBaseType;
  index: number;
}) {
  const personId = person?.id;
  const websiteId = website?.id;
  const ref = useRef(null);
  const dispatch = useDispatch<DispatchType>();
  const queryClient = useQueryClient();
  const workspaceId = useCurrentWorkspaceId();
  const [queryEnabled, setQueryEnabled] = useState<boolean>(true);

  const queryKey = ['contacts-sidebar-people-tags', personId, websiteId];

  const fetchTags = personId ? getAllTagsApi : getAllCompanyTagsApi;
  const fetchCurrentTags = personId ? getTagsByPersonIdApi : getTagsByCompanyIdApi;
  const addTag = personId ? addTagApi : addCompanyTagApi;
  const removeTag = personId ? removeTagApi : removeCompanyTagApi;

  const { data: tags, isLoading: isLoadingTags } = useQuery<TagType[]>(queryKey, fetchTags, {
    enabled: queryEnabled && (!!personId || !!websiteId),
    refetchOnWindowFocus: false,
  });

  const { data, isLoading } = useQuery<TagsType>(
    [...queryKey, 'current-tags'],
    () => fetchCurrentTags(personId || websiteId, workspaceId),
    {
      enabled: queryEnabled && !!(personId || websiteId),
      refetchOnWindowFocus: false,
      onSettled: (data) => !!data?.tagsList?.length && ref.current.setOpen(true),
    }
  );

  const onOpenCallback = useCallback(() => {
    setQueryEnabled(true);
  }, []);

  const handleToggleTag = (tagId, tagTitle, selected = true) => {
    const apiCall = selected
      ? addTag(tagId, tagTitle, personId || websiteId)
      : removeTag(tagId, personId || websiteId);

    apiCall.then((res: TagType) => {
      queryClient.setQueryData([...queryKey, 'current-tags'], (cachedTags: TagsType) => ({
        ...cachedTags,
        tagsList: selected
          ? [...(cachedTags?.tagsList || []), res]
          : cachedTags.tagsList.filter((item: TagType) => item.id !== tagId),
      }));
      dispatch(
        addNotification({ title: `Tag ${selected ? 'added' : 'removed'}`, type: 'success' })
      );
    });
  };

  const handleCreateTag = (title: string) => {
    addTag(null, title, personId || websiteId).then((tag) => {
      queryClient.setQueryData<TagType[]>(queryKey, (prevData = []) => [...prevData, tag]);
      if (websiteId) {
        queryClient.setQueryData<TagType[]>(
          ['contacts-sidebar-websites-tags', 'current-tags'],
          (prevTags = []) => [...prevTags, tag]
        );
      }
      queryClient.setQueryData([...queryKey, 'current-tags'], (cachedTags: TagsType) => ({
        ...cachedTags,
        tagsList: [...(cachedTags?.tagsList || []), tag],
      }));
      dispatch(addNotification({ title: 'Tag added', type: 'success' }));
    });
  };

  return (
    <ContactsSidebarCRMSection
      ref={ref}
      index={index}
      id="tags"
      title="Tags"
      onOpen={onOpenCallback}
      rightComponent={
        <div
          className="contact-sidebar-crm__content__add-btn"
          data-for="contact-sidebar-crm-tags"
          data-tip=""
        >
          <SVGIcon icon="plusIcon" size={10} color="#221CB6" />
          Add
        </div>
      }
    >
      <div className="contacts-sidebar-section-tags">
        <Loader isLoading={isLoading}>
          {data?.tagsList?.length ? (
            data?.tagsList.map((item: TagType) => (
              <Tag
                key={item.id}
                title={item.title}
                onRemove={() => handleToggleTag(item.id, item.title, false)}
              />
            ))
          ) : (
            <div className="contacts-sidebar-section-tags--empty">Empty</div>
          )}
        </Loader>
      </div>

      <ReactTooltip
        id="contact-sidebar-crm-tags"
        className="react-tooltip"
        place="bottom"
        effect="solid"
        event="click"
        globalEventOff="click"
        arrowColor="transparent"
        clickable
      >
        <TooltipSearchableContent
          title="Add Tags"
          addButtonText="+ Create new tag"
          isLoading={isLoading}
          onCreate={handleCreateTag}
          onSelect={(tag, selected) => handleToggleTag(tag.id, tag.title, selected)}
          items={tags?.map((item: TagType) => ({
            title: item.title,
            id: item.id,
            selected: data?.tagsList?.some((tag: TagType) => tag.id === item.id),
          }))}
        />
      </ReactTooltip>
    </ContactsSidebarCRMSection>
  );
}

export default ContactsSidebarCRMSectionTags;
