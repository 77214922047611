import React from 'react';
import { format } from 'date-fns';

import { InvoiceRowType, InvoiceType } from '@ts/billing.types';
import ResponaNewLogo from '@uikit/ResponaNewLogo/ResponaNewLogo';

import Display from '@components/Display/Display';
import DataRow from '@components/Settings/InvoiceDetails/_components/DataRow/DataRow';
import TotalRow from '@components/Settings/InvoiceDetails/_components/TotalRow/TotalRow';
import FixedPaymentFooter from '@components/Settings/InvoiceDetails/_components/FixedPaymentFooter/FixedPaymentFooter';

import './InvoiceInfo.scss';

function InvoiceInfo({
  invoice,
  onPaymentSuccess,
}: {
  invoice: InvoiceType;
  onPaymentSuccess: any;
}): JSX.Element {
  return (
    <div className="invoice-info">
      <div className="invoice-info__header">
        <ResponaNewLogo size="big" />
        <div className="invoice-info__header-info">
          Hindsite interactive Inc. DBA Respona <br />
          9211 Corporate Blvd. <br />
          Suite 308 <br />
          Rockville, MD 20850, USA <br />
          support@respona.com <br />
        </div>
      </div>
      <div className="invoice-info__bill-info">
        <div className="invoice-info__bill-info-title">Invoice</div>
        <div className="invoice-info__bill-info-content">
          <div className="invoice-info__bill-to">
            <div className="invoice-info__bill-info-sub-title">Bill to</div>
            <div className="invoice-info__bill-to-content">
              {/* <Display isVisible={Boolean(invoice?.recipient?.fullName)}> */}
              {/*  {invoice?.recipient?.fullName} <br /> */}
              {/* </Display> */}
              <Display isVisible={Boolean(invoice?.recipient?.company)}>
                {invoice?.recipient?.company} <br />
              </Display>
              <Display isVisible={Boolean(invoice?.recipient?.addressFirstLine)}>
                {invoice?.recipient?.addressFirstLine} <br />
              </Display>
              <Display isVisible={Boolean(invoice?.recipient?.addressSecondLine)}>
                {invoice?.recipient?.addressSecondLine} <br />
              </Display>
              {invoice?.recipient?.zipCode} {invoice?.recipient?.state} <br />
              {invoice?.recipient?.country} <br />
              <Display isVisible={Boolean(invoice?.recipient?.tax)}>
                <br />
                {invoice?.recipient?.tax}
                <br />
              </Display>
            </div>
          </div>
          <div className="invoice-info__bill-details">
            <div className="invoice-info__bill-info-sub-title">Details</div>
            <div className="invoice-info__bill-details-row">
              <div className="invoice-info__bill-details-label">Invoice:</div>
              <div className="invoice-info__bill-details-value">{invoice?.number}</div>
            </div>
            <div className="invoice-info__bill-details-row">
              <div className="invoice-info__bill-details-label">Issue Date:</div>
              <div className="invoice-info__bill-details-value">
                {invoice != null && invoice?.issueDate != null && invoice?.issueDate > 0
                  ? format(invoice?.issueDate, 'MMM dd, yyyy')
                  : format(new Date().getTime(), 'MMM dd, yyyy')}
              </div>
            </div>
            <div className="invoice-info__bill-details-row">
              <div className="invoice-info__bill-details-label">Account #:</div>
              <div className="invoice-info__bill-details-value">{invoice.accountId}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="invoice-info__cost-grid">
        {/*
            Titles
          */}
        <div className="invoice-info__cost-grid-title">Description</div>
        <div className="invoice-info__cost-grid-title invoice-info__cost-grid-title--centered">
          Quantity
        </div>
        <div className="invoice-info__cost-grid-title invoice-info__cost-grid-title--righted">
          Amount($)
        </div>

        {/*
            Rows
          */}
        {invoice?.rowsList.map((row: InvoiceRowType): JSX.Element => {
          return <DataRow key={row.description} row={row} />;
        })}

        {/*
            Total block
          */}
        <TotalRow
          title="Subtotal in USD"
          number={invoice.rowsList.reduce((acc, row) => acc + row.amount, 0)}
        />
        <TotalRow title="Sales Tax(0%)" number={0} />

        {invoice.refundAmount ? <TotalRow title="Refund" number={invoice.refundAmount} /> : null}

        <TotalRow title="Total" number={invoice.chargeAmount - invoice.refundAmount} />
      </div>

      <Display isVisible={!!invoice?.comment}>
        <div className="invoice-info__comment">{invoice.comment}</div>
      </Display>

      <Display isVisible={invoice.status === 0}>
        <FixedPaymentFooter
          invoiceId={invoice.id}
          paymentCallback={onPaymentSuccess}
          subscriptionType={invoice.type}
          billingCycle={invoice.cycle}
          price={invoice.chargeAmount}
        />
      </Display>
    </div>
  );
}

export default InvoiceInfo;
