import React from 'react';

const ErrorInputFieldSignIcon = ({ color = '#FF6868' }) => (
  <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.5 0C2.475 0 0 2.475 0 5.5C0 8.525 2.475 11 5.5 11C8.525 11 11 8.525 11 5.5C11 2.475 8.525 0 5.5 0ZM5.5 8.25C5.0875 8.25 4.8125 7.975 4.8125 7.5625C4.8125 7.15 5.0875 6.875 5.5 6.875C5.9125 6.875 6.1875 7.15 6.1875 7.5625C6.1875 7.975 5.9125 8.25 5.5 8.25ZM6.1875 6.1875H4.8125V2.75H6.1875V6.1875Z"
      fill={color}
    />
  </svg>
);

export default ErrorInputFieldSignIcon;
