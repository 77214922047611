import React from 'react';

export default ({ color = '#251FB6' }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99967 2.4987C5.85754 2.4987 2.49967 5.85656 2.49967 9.9987C2.49967 14.1408 5.85754 17.4987 9.99967 17.4987C14.1418 17.4987 17.4997 14.1408 17.4997 9.9987C17.4997 5.85656 14.1418 2.4987 9.99967 2.4987ZM0.833008 9.9987C0.833008 4.93609 4.93706 0.832031 9.99967 0.832031C15.0623 0.832031 19.1663 4.93609 19.1663 9.9987C19.1663 15.0613 15.0623 19.1654 9.99967 19.1654C4.93706 19.1654 0.833008 15.0613 0.833008 9.9987Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6663 6.66536C12.1266 6.66536 12.4997 7.03846 12.4997 7.4987V12.4987C12.4997 12.9589 12.1266 13.332 11.6663 13.332C11.2061 13.332 10.833 12.9589 10.833 12.4987V7.4987C10.833 7.03846 11.2061 6.66536 11.6663 6.66536Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.33301 6.66536C8.79325 6.66536 9.16634 7.03846 9.16634 7.4987V12.4987C9.16634 12.9589 8.79325 13.332 8.33301 13.332C7.87277 13.332 7.49967 12.9589 7.49967 12.4987V7.4987C7.49967 7.03846 7.87277 6.66536 8.33301 6.66536Z"
      fill={color}
    />
  </svg>
);
