import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'class-names';
import braintree from 'braintree-web';

import BillingFormField from '@components/Settings/ChangeBillingForm/_components/BillingFormField/BillingFormField';
import { SVGIcon } from '@uikit/Icon/Icon';

import { getBraintreeTokenApi, updateCreditCardApi } from '@api/billing.api';
import Button from '@uikit/Button/Button';
import { cardDetailsDefault } from '@constants/payments';
import { addNotification } from '@redux/actions/notifications.actions';
import { fetchBillingDetails } from '@redux/thunks/billings.thunks';

import './CardWidget.scss';

let deviceData = '';

const CardWidget = ({
  cardNumber,
  expirationMonth,
  expirationYear,
}: {
  cardNumber?: string;
  expirationMonth?: string;
  expirationYear?: string;
}): JSX.Element => {
  const dispatch = useDispatch();
  const [isEditing, changeIsEditing] = useState(false);
  const [isLoading, changeIsLoading] = useState(false);
  const [token, changeToken] = useState<string | null>(null);
  const submitRef = useRef<HTMLButtonElement>();

  useEffect(() => {
    if (submitRef.current && isEditing) {
      if (token) {
        setupBraintree(token);
      } else {
        getBraintreeTokenApi().then((res) => {
          setupBraintree(res.value);
          changeToken(res.value);
        });
      }
    }
  }, [submitRef, submitRef.current, isEditing]);

  const setupBraintree = (token) => {
    braintree.client.create(
      {
        authorization: token,
      },
      (err, clientInstance) => {
        if (err) {
          console.error(err);
          return;
        }

        braintree.hostedFields.create(
          {
            client: clientInstance,
            styles: {
              input: {
                color: '#282c37',
                'font-size': '16px',
                transition: 'color 0.1s',
                'line-height': '3',
                'padding-left': '10px',
              },
              // Style the text of an invalid input
              'input.invalid': {
                color: '#E53A40',
              },
              // placeholder styles need to be individually adjusted
              '::-webkit-input-placeholder': {
                color: 'rgba(0,0,0,0.6)',
              },
              ':-moz-placeholder': {
                color: 'rgba(0,0,0,0.6)',
              },
              '::-moz-placeholder': {
                color: 'rgba(0,0,0,0.6)',
              },
              ':-ms-input-placeholder': {
                color: 'rgba(0,0,0,0.6)',
              },
              // prevent IE 11 and Edge from
              // displaying the clear button
              // over the card brand icon
              'input::-ms-clear': {
                opacity: '0',
              },
            },
            // Add information for individual fields
            fields: {
              number: {
                selector: '#billing-card-number',
                placeholder: cardDetailsDefault.CARD_NUMBER,
              },
              cvv: {
                selector: '#billing-cvv',
                placeholder: cardDetailsDefault.CVV,
              },
              expirationDate: {
                selector: '#billing-expiration-date',
                placeholder: cardDetailsDefault.EXPIRATION,
              },
            },
          },
          (instanceError, hostedFieldsInstance) => {
            if (instanceError) {
              console.error(instanceError);
              return;
            }

            braintree.dataCollector.create(
              {
                client: clientInstance,
                paypal: false,
              },
              (dataCollectorErr, dataCollectorInstance) => {
                if (dataCollectorErr) {
                  console.log(dataCollectorErr);
                  return;
                }
                deviceData = dataCollectorInstance.deviceData;
              }
            );

            if (submitRef.current) {
              // @ts-ignore
              submitRef.current.addEventListener(
                'click',
                (event) => {
                  event.preventDefault();

                  hostedFieldsInstance.tokenize((tokenizeErr, payload) => {
                    if (tokenizeErr) {
                      console.error(tokenizeErr);
                      return;
                    }

                    changeIsLoading(true);
                    updateCreditCardApi(payload.nonce, deviceData).then(() => {
                      dispatch(fetchBillingDetails())
                        // @ts-ignore
                        .then(() => {
                          changeIsEditing(false);
                          dispatch(fetchBillingDetails(true));
                          dispatch(
                            addNotification({ title: 'Card info updated', type: 'success' })
                          );
                        })
                        .finally(() => {
                          changeIsLoading(false);
                        });
                    });
                  });
                },
                false
              );
            }
          }
        );
      }
    );
  };

  const cardNumberPlaceholder = cardNumber ? `**** **** **** **** ${cardNumber}` : '-';

  const expirationDatePlaceholder =
    expirationMonth && expirationYear ? `${expirationMonth}/${expirationYear}` : `-`;

  const renderFilledForm = () => (
    <div className="card-widget__filled-grid">
      <div className="card-widget__filled-grid-card-number">
        <BillingFormField
          label="Card number"
          value=""
          placeholder={cardNumberPlaceholder}
          disabled
        />
      </div>
      <div className="card-widget__filled-grid-expiration">
        <BillingFormField
          label="Card expiration date"
          value=""
          placeholder={expirationDatePlaceholder}
          disabled
        />
      </div>
      <div className="card-widget__filled-grid-cvv">
        <BillingFormField label="CVV" value="" placeholder="CVV" disabled />
      </div>
    </div>
  );

  const renderWidgetForm = () => (
    <>
      <div className="card-widget__unfilled-grid">
        <label
          className="card-widget__unfilled-grid-card-number-label"
          htmlFor="billing-card-number"
        >
          Card number
        </label>
        <div
          className="card-widget__unfilled-grid-card-number"
          id="billing-card-number"
          data-braintree-name="number"
        />
        <label
          className="card-widget__unfilled-grid-expiration-label"
          htmlFor="billing-expiration-date"
        >
          Card expiration date
        </label>
        <div
          className="card-widget__unfilled-grid-expiration"
          id="billing-expiration-date"
          data-braintree-name="cvv"
        />
        <label className="card-widget__unfilled-grid-cvv-label" htmlFor="billing-cvv">
          CVV
        </label>
        <div
          className="card-widget__unfilled-grid-cvv"
          id="billing-cvv"
          data-braintree-name="expiration_date"
        />
      </div>
      <Button className="card-widget__update-button" innerRef={submitRef} isLoading={isLoading}>
        Update
      </Button>
    </>
  );

  const cnActionBtn = cn('card-widget__action-btn', {
    'card-widget__action-btn--cancel': isEditing,
    'card-widget__action-btn--edit': !isEditing,
  });

  const handleToggleIsEditing = () => changeIsEditing(!isEditing);

  return (
    <div className="card-widget">
      <div className="card-widget__title">
        Payment method
        <span className={cnActionBtn} onClick={handleToggleIsEditing}>
          {isEditing ? (
            <SVGIcon icon="crossBlack" color="#ED483D" />
          ) : (
            <SVGIcon icon="pen" color="#BDBDBD" />
          )}
        </span>
      </div>
      {isEditing ? renderWidgetForm() : renderFilledForm()}
    </div>
  );
};

export default CardWidget;
