import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DispatchType } from 'src/store';
import {
  ImportModeEnum,
  ParseFileFieldResponseType,
  ParsingDelimiterEnum,
} from '@ts/fileImport.types';

import { buildStartImportApiRequest, parseFileApi, uploadFile } from '@api/fileImport.api';

import { getActiveCampaignInfo } from '@redux/selectors/campaignSettings.selectors';
import { addNotification } from '@redux/actions/notifications.actions';

import { getFileUploadFlowSteps } from '@components/CampaignSettings/ContentSearch/ImportFileFlow/getFileUploadFlowSteps';
import Display from '@components/Display/Display';
import { SVGIcon } from '@uikit/Icon/Icon';
import FileSelectStep from './_components/FileSelectStep/FileSelectStep';
import MapAttributesStep from './_components/MapAttributesStep/MapAttributesStep';

import ContentAutoSearchSteps from '../ContentAutomationSearch/_components/ContentAutoSearchSteps/ContentAutoSearchSteps';

import './ImportFileFlow.scss';
import { fetchBillingCredits } from '@redux/thunks/billings.thunks';
import { ContentAutomationSearchContext } from '@pages/OpportunitiesCampaignSettingsPage/OpportunitiesCampaignSettingsPage';
import SettingsStep from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/SettingsStep/SettingsStep';
import { buildLaunchContactAutomationApiRequest } from '@api/contactAutomation.api';
import { ContactSearchSource as CONTACT_SEARCH_SOURCE } from 'respona_api/generated/common_pb';
import { currentContactAutomationStateSelector } from '@redux/selectors/automationContact.selectors';
import { setCurrentContactAutomationType } from '@redux/actions/automationContact.actions';
import { ContactAutomationSearchModes } from '@ts/automationContacts.types';
import { buildLaunchPersonalizationAutomationApiRequest } from '@api/personalizationAutomation.api';
import { personalizationAutomationSelector } from '@redux/selectors/automationPersonalization.selectors';
import { buildPipelineApiRequest, launchPipelineApi } from '@api/pipeline.api';
import { CampaignPipelineType } from 'respona_api/generated/pipeline_pb';
import useCampaignPipelines from '@hooks/useCampaignPipelines';

type ImportFileFlowStylesType = {
  container?: React.CSSProperties;
  header?: React.CSSProperties;
  importSettingsStyle?: React.CSSProperties;
};

function ImportFileFlow({
  onClose,
  styles,
  setOpenModal,
}: {
  onClose?: () => void;
  styles?: ImportFileFlowStylesType;
  setOpenModal?: Dispatch<SetStateAction<boolean>>;
}): JSX.Element {
  const dispatch = useDispatch<DispatchType>();
  const [delimiter, changeDelimiter] = useState(ParsingDelimiterEnum.AUTO_DELIMITER);
  const [importMode, changeImportMode] = useState<ImportModeEnum>(ImportModeEnum.OPPORTUNITY);
  const [csvFileLength, setCsvFileLength] = useState<number>(0);
  const { settings } = useContext(ContentAutomationSearchContext);
  const [customDelimiter, changeCustomDelimiter] = useState('');
  const [uploadedFileUid, changeUploadedFileUid] = useState(null);
  const [isUploading, changeIsUploading] = useState(false);
  const [uploadedFile, changeUploadedFile] = useState(null);
  const [uploadProgress, changeUploadProgress] = useState(null);
  const [activeStep, changeActiveStep] = useState(1);
  const [headersList, changeHeadersList] = useState<ParseFileFieldResponseType[]>(null);
  const [isParsing, setIsParsing] = useState<boolean>(false);
  const [importSettings, changeImportSettings] = useState({
    removeActiveContacts: true,
    removeContactedInPast: true,
    daysInThePast: 365,
    removeDuplicated: true,
    removeUnsubscribed: true,
    groupContactsByDomain: false,
  });

  const automationContact = useSelector(currentContactAutomationStateSelector);
  const { data: automationPersonalization } = useSelector(personalizationAutomationSelector);
  const { info: campaignInfo } = useSelector(getActiveCampaignInfo);
  const { addItem: addPipelineToCache } = useCampaignPipelines(
    campaignInfo.workspaceId,
    campaignInfo.id
  );
  useEffect(() => {
    dispatch(setCurrentContactAutomationType(ContactAutomationSearchModes.BLOG_POST));
  }, []);

  const handleUploadFile = (file: File) => {
    const readableFileSizeMB = Math.floor(file.size / 1000000);

    if (readableFileSizeMB > 25) {
      dispatch(addNotification({ title: 'Maximum file size is 25MB', type: 'warning' }));
      return;
    }

    changeIsUploading(true);
    changeUploadedFile(file);

    const processCallback = ([{ progress }]) => {
      changeUploadProgress(progress);
    };

    file
      ?.arrayBuffer()
      ?.then((buffer) => {
        uploadFile(campaignInfo.id, campaignInfo.workspaceId, file, buffer, processCallback)
          .then((processResponse) => {
            // @ts-ignore
            const { uid } = processResponse[processResponse.length - 1];
            changeUploadedFileUid(uid);
            handleParseFile(uid);
          })
          .finally(() => {
            changeIsUploading(false);
          });
      })
      .catch((e) => {
        console.error(`Error uploading file: ${e?.message}`, e);
      });
  };

  const handleParseFile = (fileUid) => {
    setIsParsing(true);
    parseFileApi(campaignInfo.id, campaignInfo.workspaceId, fileUid, delimiter, customDelimiter)
      .then(({ headersList: headersListRes, rowNumber }) => {
        // @ts-ignore
        changeHeadersList(headersListRes);
        setCsvFileLength(rowNumber);
      })
      .catch(() => {
        dispatch(addNotification({ title: 'Incorrect file format', type: 'error' }));
        changeUploadProgress(null);
      })
      .finally(() => {
        setIsParsing(false);
      });
  };

  const mapFields = (changedHeadersList) => {
    changeHeadersList(changedHeadersList);
    changeActiveStep(3);
  };

  const handleImportFile = () => {
    let contactAutomationRequest = null;

    if (settings.toggleFindContactInfo) {
      const requestData = {
        ...automationContact,
        positionsList: automationContact.positionsList,
        senioritiesList: automationContact.senioritiesList,
      };
      contactAutomationRequest = buildLaunchContactAutomationApiRequest(
        campaignInfo.id,
        campaignInfo.workspaceId,
        campaignInfo.lastBatch,
        requestData,
        false,
        CONTACT_SEARCH_SOURCE.RESPONA_SEARCH_SOURCE
      );
    }

    let personalizationAutomationRequest = null;

    if (settings.togglePersonalization) {
      personalizationAutomationRequest = buildLaunchPersonalizationAutomationApiRequest(
        campaignInfo.id,
        campaignInfo.workspaceId,
        campaignInfo.lastBatch,
        campaignInfo.lastSequenceId,
        automationPersonalization
      );
    }

    launchPipelineApi(
      buildPipelineApiRequest(
        -1,
        campaignInfo.workspaceId,
        campaignInfo.id,
        CampaignPipelineType.IMPORT,
        false,
        settings.repeatSearchEveryWeeks * 7 * 24,
        settings.stopRepeatAfterDate,
        settings.toggleFindContactInfo,
        settings.togglePersonalization,
        settings.notify,
        contactAutomationRequest,
        null,
        buildStartImportApiRequest(
          uploadedFileUid,
          campaignInfo.id,
          campaignInfo.lastBatch,
          campaignInfo.workspaceId,
          headersList,
          delimiter,
          importMode,
          customDelimiter,
          importSettings
        ),
        personalizationAutomationRequest
      )
    )
      .then((response) => {
        addPipelineToCache(response);
        dispatch(fetchBillingCredits());
        setOpenModal(false);
      })
      .catch((e) => {
        console.error(`Launch failed: ${e?.message}`, e);
      });
  };

  const handleReturnBack = () => changeActiveStep((prevState) => prevState - 1);
  const handleNextPage = () => {
    if (importMode === null) {
      dispatch(addNotification({ title: 'Please select the import mode.', type: 'warning' }));
      return;
    }
    changeActiveStep((prevState) => prevState + 1);
  };
  const handleCancelFileUpload = () => {
    changeUploadedFile(null);
    changeIsUploading(false);
    changeUploadProgress(null);
  };
  const handleChangeFile = (file) => {
    changeUploadedFile(file);
    handleUploadFile(file);
  };

  const handleImportSettingsChange = (fieldName, value) =>
    changeImportSettings((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));

  const renderStep = () => {
    switch (activeStep) {
      case 1:
        return (
          <FileSelectStep
            onCancelUpload={handleCancelFileUpload}
            onChangeFile={handleChangeFile}
            uploadedFile={uploadedFile}
            onContinue={handleNextPage}
            delimiter={delimiter}
            onChangeDelimiter={(value) => changeDelimiter(value)}
            importMode={importMode}
            onChangeImportMode={(value) => changeImportMode(value)}
            customDelimiter={customDelimiter}
            onChangeCustomDelimiter={(value) => changeCustomDelimiter(value)}
            importSettings={importSettings}
            onChangeImportSettings={handleImportSettingsChange}
            isUploading={isUploading}
            uploadingProgress={uploadProgress}
            importSettingsStyle={styles?.importSettingsStyle}
          />
        );
      case 2:
        return (
          <MapAttributesStep
            onImport={mapFields}
            onBack={handleReturnBack}
            headersList={headersList}
            isLoading={isParsing}
            importMode={importMode}
          />
        );
      case 3:
        return (
          <SettingsStep
            data={null}
            type={CampaignPipelineType.IMPORT}
            onImportClick={handleImportFile}
            importMode={importMode}
            csvFileLength={csvFileLength}
          />
        );
      default:
        return null;
    }
  };

  const stepsConfig = getFileUploadFlowSteps(uploadedFile, isUploading);

  return (
    <div className="import-file-flow" style={styles?.container}>
      <div className="import-file-flow__header" style={styles?.header}>
        <Display isVisible={!!onClose}>
          <div className="import-file-flow__header-title">
            <SVGIcon icon="upload" color="#251FB6" />
            Import file
          </div>
        </Display>

        <ContentAutoSearchSteps
          activeStepIndex={activeStep}
          onChooseStep={changeActiveStep}
          stepsConfig={stepsConfig}
        />

        <Display isVisible={!!onClose}>
          <div onClick={onClose} className="import-file-flow__header-close-icon">
            <SVGIcon icon="crossBlack" />
          </div>
        </Display>
      </div>

      {renderStep()}
    </div>
  );
}

export default ImportFileFlow;
