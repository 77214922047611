import ContactsCard from '@components/Settings/Billing/_components/ContactsCard/ContactsCard';
import GetUnlimitedCard from '@components/Settings/Billing/_components/GetUltimateCard/GetUltimateCard';
import SwitchToYearlyCard from '@components/Settings/Billing/_components/SwitchToYearlyCard/SwitchToYearlyCard';
import UpsellContactsSidebar from '@components/UpsellSidebars/UpsellContactsSidebar/UpsellContactsSidebar';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';

import urls from '@constants/urls';

import {
  BillingFeedbackMap,
  BillingResponse,
  BillingState,
  BillingSubscriptionCycle,
  BillingSubscriptionType,
} from 'respona_api/generated/billing_pb';

import { BillingType } from '@ts/billing.types';

import {
  cancelAndStoreFeedbackApi,
  getCancellationDetailsApi,
  getCurrentInvoiceApi,
  resumeCancellationApi,
  changeSubscriptionApi,
} from '@api/billing.api';

import { addNotification } from '@redux/actions/notifications.actions';
import { fetchBillingCredits, fetchBillingDetails } from '@redux/thunks/billings.thunks';

import Button from '@uikit/Button/Button';

import Display from '@components/Display/Display';
import PageHeader from '@components/PageHeader/PageHeader';
import BillingCancellationReviewModal, {
  BILLING_CANCELLATION_REVIEW_MODAL_TYPE,
} from '@components/Settings/Billing/_components/BillingCancellationReviewModal/BillingCancellationReviewModal';
import BillingCancellationFlow from '@components/Settings/Billing/_components/BillingCancellationFlow/BillingCancellationFlow';
import BillingCancellationModal from '@components/Settings/Billing/_components/BillingCancellationModal/BillingCancellationModal';
import UpsellCreditsSidebar from '@components/UpsellSidebars/UpsellCreditsSidebar/UpsellCreditsSidebar';
import UpsellEmailSidebar from '@components/UpsellSidebars/UpsellEmailSidebar/UpsellEmailSidebar';
import SubscriptionCard from '@components/Settings/Billing/_components/SubscriptionCard/SubscriptionCard';
import CreditsCard from '@components/Settings/Billing/_components/CreditsCard/CreditsCard';
import EmailAccountsCard from '@components/Settings/Billing/_components/EmailAccountsCard/EmailAccountsCard';
import LastInvoiceCard from '@components/Settings/Billing/_components/LastInvoiceCard/LastInvoiceCard';
import BillingDetailsCard from '@components/Settings/Billing/_components/BillingDetailsCard/BillingDetailsCard';
import TrialPeriodCard from '@components/Settings/Billing/_components/TrialPeriodCard/TrialPeriodCard';

import './Billing.scss';
import SubscriptionActivationSidebar from '@components/SubscriptionActivationSidebars/SubscriptionActivationSidebar';
import gtm from 'src/libs/gtm';
import { userProfileSelector } from '@redux/selectors/userProfile.selector';

export const BILLING_CANCELLATION_STEP = {
  WARNING_MODAL: 1,
  MAINTENANCE: 2,
  CONFIRMATION: 3,
  REVIEW: 4,
  default: 0,
};

function Billing({ billingDetails }: { billingDetails: BillingType }): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const { info: profileFromRedux } = useSelector(userProfileSelector);
  const [upsellCreditsSidebarOpen, setUpsellCreditsSidebarOpen] = useState<boolean>(false);
  const [upsellEmailsSidebarOpen, setUpsellEmailsSidebarOpen] = useState<boolean>(false);
  const [upsellContactsSidebarOpen, setUpsellContactsSidebarOpen] = useState<boolean>(false);
  const [isPaymentSidebarOpen, setPaymentSidebarOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [cancellationStep, setCancellationStep] = useState<number>(
    BILLING_CANCELLATION_STEP.default
  );

  const [reviewDetails, setReviewDetails] = useState<{ type: string; date: number }>(null);

  const { data: details, refetch } = useQuery(
    'cancellationDetails',
    () => getCancellationDetailsApi(),
    {
      enabled: false,
      retry: false,
    }
  );

  const refetchCredits = async (): Promise<BillingResponse.AsObject> => {
    await dispatch(fetchBillingCredits(true));
    // @ts-ignore
    return dispatch(fetchBillingDetails(true));
  };

  const handleOpenCancellationModal = async () => {
    setIsLoading(true);
    try {
      await refetch();
      setCancellationStep(BILLING_CANCELLATION_STEP.WARNING_MODAL);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelSubscription = async (
    feedback: BillingFeedbackMap[keyof BillingFeedbackMap],
    improve: string
  ) => {
    setIsLoading(true);
    try {
      const res = await cancelAndStoreFeedbackApi(feedback, improve);
      setReviewDetails({
        type: BILLING_CANCELLATION_REVIEW_MODAL_TYPE.CANCELLATION,
        date: res.worksUntil,
      });
      setCancellationStep(BILLING_CANCELLATION_STEP.REVIEW);
      refetchCredits();
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePlanChange = async () => {
    return changeSubscriptionApi(
      billingDetails.totalMailboxAccountsNumber,
      billingDetails.maxPaidCreditsNumber,
      BillingSubscriptionType.MAINTENANCE,
      undefined,
      undefined,
      billingDetails.cycle,
      billingDetails.totalContactsNumber
    ).then((response) => {
      dispatch(addNotification({ title: 'Subscription updated', type: 'success' }));
      setReviewDetails({
        type: BILLING_CANCELLATION_REVIEW_MODAL_TYPE.MAINTENANCE,
        date: billingDetails.nextChargeTime,
      });
      gtm.makePayment(
        profileFromRedux.email,
        15,
        response.optionalTransactionId,
        'Maintenance',
        15,
        BillingSubscriptionCycle.MONTHLY
      );
      setCancellationStep(BILLING_CANCELLATION_STEP.REVIEW);
      refetchCredits();
    });
  };

  const handleResumeSubscription = async () => {
    const now = +new Date() / 1000;

    if (billingDetails.deactivatedAt > now) {
      setIsLoading(true);
      try {
        await resumeCancellationApi();
        const newDetails = await refetchCredits();

        setReviewDetails({
          type: BILLING_CANCELLATION_REVIEW_MODAL_TYPE.RESUME,
          date: newDetails.nextChargeTime,
        });
        setCancellationStep(BILLING_CANCELLATION_STEP.REVIEW);
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(true);
      getCurrentInvoiceApi()
        .then((invoiceContainer) => {
          const invoiceId = invoiceContainer.invoice.id;
          setIsLoading(false);
          history.push(urls.INVOICE(invoiceId));
        })
        .catch((e) => {
          console.error(e);
          setIsLoading(false);
        });
    }
  };

  if (
    [BILLING_CANCELLATION_STEP.MAINTENANCE, BILLING_CANCELLATION_STEP.CONFIRMATION].includes(
      cancellationStep
    )
  ) {
    return (
      <BillingCancellationFlow
        billingDetails={billingDetails}
        cancellationStep={cancellationStep}
        setCancellationStep={setCancellationStep}
        handleCancelSubscription={handleCancelSubscription}
        handlePlanChange={handlePlanChange}
        isCancelling={isLoading}
      />
    );
  }

  function onUpsellEmailsSidebarOpenClick() {
    if (billingDetails.state === 6) {
      setPaymentSidebarOpen(true);
    } else {
      setUpsellEmailsSidebarOpen(true);
    }
  }

  function onUpsellContactsSidebarOpenClick() {
    if (billingDetails.state === 6) {
      setPaymentSidebarOpen(true);
    } else {
      setUpsellContactsSidebarOpen(true);
    }
  }

  function onUpsellCreditsSidebarOpenClick() {
    if (billingDetails.state === 6) {
      setPaymentSidebarOpen(true);
    } else {
      setUpsellCreditsSidebarOpen(true);
    }
  }

  return (
    <div className="billing">
      <PageHeader title="Billing" />

      <div className="billing__content-container">
        <div className="billing__content">
          <div className="billing__grid-top">
            <SubscriptionCard
              type={billingDetails.type}
              state={billingDetails.state}
              amount={billingDetails.amount}
              nextChargeTime={billingDetails.nextChargeTime}
              cycle={billingDetails.cycle}
            />

            <CreditsCard
              type={billingDetails.type}
              currentCreditsNumber={billingDetails.currentPaidCreditsNumber}
              totalCreditsNumber={billingDetails.maxPaidCreditsNumber}
              bonusCreditsNumber={billingDetails.currentBonusCreditsNumber}
              nextChargeTime={billingDetails.nextChargeTime}
              onAddMore={() => onUpsellCreditsSidebarOpenClick()}
            />

            <EmailAccountsCard
              type={billingDetails.type}
              currentMailboxAccountsNumber={billingDetails.currentMailboxAccountsNumber}
              totalMailboxAccountsNumber={billingDetails.totalMailboxAccountsNumber}
              onAddMore={() => onUpsellEmailsSidebarOpenClick()}
            />

            <ContactsCard
              type={billingDetails.type}
              totalContactsNumber={billingDetails.totalContactsNumber}
              currentContactsNumber={billingDetails.currentContactsNumber}
              onAddMore={() => onUpsellContactsSidebarOpenClick()}
            />
          </div>

          {billingDetails.type === BillingSubscriptionType.TRIAL ? (
            <TrialPeriodCard
              paymentExpired={billingDetails.paymentExpired}
              endDate={
                billingDetails.nextChargeTime < 0
                  ? billingDetails.deactivatedAt
                  : billingDetails.nextChargeTime
              }
            />
          ) : (
            <div className="billing__grid-bottom">
              {billingDetails.type === BillingSubscriptionType.STARTER &&
                billingDetails.cycle === BillingSubscriptionCycle.YEARLY && <GetUnlimitedCard />}

              {(billingDetails.type === BillingSubscriptionType.STARTER ||
                billingDetails.type === BillingSubscriptionType.UNLIMITED ||
                billingDetails.type === BillingSubscriptionType.PRO) &&
                billingDetails.cycle === BillingSubscriptionCycle.MONTHLY && (
                  <SwitchToYearlyCard cost={billingDetails.amount} />
                )}
            </div>
          )}

          <div className="billing__grid-bottom">
            <LastInvoiceCard
              invoiceAmount={billingDetails.invoiceAmount}
              invoiceChargeTime={billingDetails.invoiceChargeTime}
              invoiceNumber={billingDetails.invoiceNumber}
            />

            <BillingDetailsCard
              cardNumber={billingDetails.card?.creditCardNumber}
              contactEmail={billingDetails?.contactEmail}
            />
          </div>

          {![2, 6].includes(billingDetails.type) && (
            <div className="billing__footer">
              <div>
                <div className="billing__footer-title">Subscription Cancellation</div>
                <div className="billing__footer-description">
                  Cancelling your subscription will cause you to lose all your campaigns and
                  remaining credits on your account.
                </div>
              </div>

              <Display
                isVisible={[
                  BillingState.BILLING_CANCELING_IN_FUTURE, // @ts-ignore
                ].includes(billingDetails.state)}
              >
                <Button
                  isLoading={isLoading}
                  size="xl"
                  type="primary"
                  onClick={handleResumeSubscription}
                >
                  Resume Subscription
                </Button>
              </Display>

              <Display
                isVisible={[
                  BillingState.BILLING_NORMAL,
                  BillingState.BILLING_TRIAL,
                  BillingState.BILLING_UN_PAID_NORMAL,
                  BillingState.BILLING_MAINTENANCE_IN_FUTURE,
                  BillingState.BILLING_MAINTENANCE_IN_PROCESS, // @ts-ignore
                ].includes(billingDetails.state)}
              >
                <Button
                  isLoading={isLoading}
                  size="xl"
                  type="bordered-grey"
                  onClick={handleOpenCancellationModal}
                >
                  Cancel subscription
                </Button>
              </Display>
            </div>
          )}
        </div>
      </div>
      <BillingCancellationModal
        details={details}
        isOpen={cancellationStep === BILLING_CANCELLATION_STEP.WARNING_MODAL}
        onClose={() => setCancellationStep(BILLING_CANCELLATION_STEP.default)}
        onConfirm={() => {
          if (billingDetails.type === BillingSubscriptionType.TRIAL) {
            setCancellationStep(BILLING_CANCELLATION_STEP.CONFIRMATION);
          } else {
            setCancellationStep(BILLING_CANCELLATION_STEP.MAINTENANCE);
          }
        }}
      />

      <BillingCancellationReviewModal
        isOpen={cancellationStep === BILLING_CANCELLATION_STEP.REVIEW}
        onClose={() => setCancellationStep(BILLING_CANCELLATION_STEP.default)}
        details={reviewDetails}
      />

      {isPaymentSidebarOpen && (
        <SubscriptionActivationSidebar
          title="Activate your subscription"
          onClose={() => {
            setPaymentSidebarOpen(false);
          }}
          onActionAfterPay={() => {
            setPaymentSidebarOpen(false);
            dispatch(fetchBillingDetails());
            dispatch(fetchBillingCredits());
          }}
        />
      )}

      {upsellEmailsSidebarOpen ? (
        <UpsellEmailSidebar onClose={() => setUpsellEmailsSidebarOpen(false)} />
      ) : null}

      {upsellContactsSidebarOpen ? (
        <UpsellContactsSidebar onClose={() => setUpsellContactsSidebarOpen(false)} />
      ) : null}

      {upsellCreditsSidebarOpen ? (
        <UpsellCreditsSidebar
          onClose={() => setUpsellCreditsSidebarOpen(false)}
          needForAutomation={0}
        />
      ) : null}
    </div>
  );
}

export default Billing;
