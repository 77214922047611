import { useEffect, RefObject } from 'react';

export default (ref: RefObject<HTMLElement>, callback?: () => void, deps: unknown[] = []): void => {
  useEffect(() => {
    const handleWheel = (event) => {
      if (Math.abs(event.deltaY) > Math.abs(event.deltaX)) {
        // eslint-disable-next-line no-param-reassign
        ref.current.scrollLeft += event.deltaY;
      } else {
        // eslint-disable-next-line no-param-reassign
        ref.current.scrollLeft += event.deltaX;
      }
      event.preventDefault();

      if (callback) {
        callback();
      }
    };

    ref.current?.addEventListener('wheel', handleWheel);

    return () => {
      ref.current?.removeEventListener('wheel', handleWheel);
    };
  }, [ref.current, ...deps]);
};
