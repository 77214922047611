import React from 'react';

const ChecklistItemSuccesIcon = ({ color = '#23E771' }) => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15" r="14.5" fill="#23E771" stroke={color} />
    <path
      d="M10 15.2308L13.5 19L20 12"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);

export default ChecklistItemSuccesIcon;
