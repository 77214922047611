import { handleActions } from 'redux-actions';

import { UnsubscribeFilterType } from '@ts/unsubscribes.types';
import {
  setFiltersForUnsubscribes,
  throwFiltersForUnsubscribes,
} from '@redux/actions/unsubscribes.actions';
import { getUnsubscribesFilterFields } from '@constants/unsubscribesFilterFields';
import { FilterJoins, FilterOperationsMapForString } from '@constants/filterOperations';
import { UnsubscribedFilterField } from 'respona_api/generated/mailbox_pb';

export type UnsubscribesStateType = {
  filters: UnsubscribeFilterType[];
};

export const initialUnsubscribePageFilterRawState = {
  order: 0,
  field: UnsubscribedFilterField.STRING_PATTERN,
  value: '',
  operation: FilterOperationsMapForString[0].value,
  join: FilterJoins[0].value,
};

const initialState: UnsubscribesStateType = {
  filters: [initialUnsubscribePageFilterRawState],
};

export default handleActions(
  {
    [setFiltersForUnsubscribes]: (
      state: UnsubscribesStateType,
      { payload }
    ): UnsubscribesStateType => ({
      ...state,
      filters: payload,
    }),
    [throwFiltersForUnsubscribes]: (state: UnsubscribesStateType): UnsubscribesStateType => ({
      ...state,
      filters: [initialUnsubscribePageFilterRawState],
    }),
  },
  initialState
);
