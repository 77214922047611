import React from 'react';

export default ({ color = '#251FB6' }): JSX.Element => (
  <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.3457 0.231509C11.2729 0.158151 11.1863 0.099926 11.091 0.0601915C10.9956 0.0204571 10.8933 0 10.79 0C10.6866 0 10.5843 0.0204571 10.4889 0.0601915C10.3936 0.099926 10.307 0.158151 10.2342 0.231509L4.4031 6.07013L1.95324 3.61259C1.87769 3.53961 1.78851 3.48223 1.69078 3.44372C1.59306 3.40521 1.48871 3.38633 1.38368 3.38814C1.27866 3.38996 1.17502 3.41244 1.07869 3.45431C0.982353 3.49618 0.895209 3.55661 0.822231 3.63215C0.749253 3.7077 0.69187 3.79687 0.653358 3.89459C0.614846 3.99231 0.595959 4.09666 0.597776 4.20167C0.599593 4.30669 0.622078 4.41032 0.663948 4.50665C0.705817 4.60298 0.766251 4.69012 0.841799 4.76309L3.84738 7.76849C3.92014 7.84185 4.00671 7.90007 4.10209 7.93981C4.19747 7.97954 4.29977 8 4.4031 8C4.50643 8 4.60873 7.97954 4.70411 7.93981C4.79949 7.90007 4.88606 7.84185 4.95882 7.76849L11.3457 1.38201C11.4251 1.30872 11.4885 1.21977 11.5319 1.12077C11.5753 1.02176 11.5977 0.914848 11.5977 0.806761C11.5977 0.698675 11.5753 0.59176 11.5319 0.492754C11.4885 0.393749 11.4251 0.304798 11.3457 0.231509Z"
      fill={color}
    />
    <path
      d="M15.1106 0.0601915C15.2022 0.099926 15.2855 0.158151 15.3554 0.231509C15.4318 0.304798 15.4927 0.393749 15.5344 0.492754C15.5761 0.59176 15.5977 0.698675 15.5977 0.806761C15.5977 0.914848 15.5761 1.02176 15.5344 1.12077C15.4927 1.21977 15.4318 1.30872 15.3554 1.38201L9.21542 7.76849C9.14547 7.84185 9.06225 7.90007 8.97056 7.93981C8.87887 7.97954 8.78052 8 8.68118 8C8.58185 8 8.4835 7.97954 8.39181 7.93981C8.30012 7.90007 8.21689 7.84185 8.14694 7.76849L7.59766 7.19715L8.68118 6.07013L14.2869 0.231509C14.3569 0.158151 14.4401 0.099926 14.5318 0.0601915C14.6235 0.0204571 14.7218 0 14.8212 0C14.9205 0 15.0189 0.0204571 15.1106 0.0601915Z"
      fill={color}
    />
  </svg>
);
