import React from 'react';

export default ({ color = '#C4C6CD' }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.03503 8.21325V10.6177C8.7322 10.5379 8.41531 10.527 8.10772 10.5859C7.80012 10.6448 7.50967 10.772 7.25776 10.958C7.00585 11.1441 6.79891 11.3844 6.65219 11.661C6.50547 11.9377 6.42272 12.2438 6.41003 12.5567C6.39269 12.8262 6.43323 13.0962 6.52893 13.3486C6.62464 13.6011 6.77329 13.8301 6.96488 14.0203C7.15648 14.2106 7.38658 14.3575 7.63972 14.4514C7.89285 14.5453 8.16316 14.5839 8.43245 14.5646C8.70447 14.5854 8.97777 14.547 9.23348 14.4519C9.48919 14.3568 9.72121 14.2074 9.91351 14.0139C10.1058 13.8203 10.2538 13.5874 10.3473 13.3311C10.4407 13.0748 10.4774 12.8012 10.4549 12.5293V3H12.8331C13.2374 5.51708 14.4962 6.101 16.1814 6.3705V8.78258C15.0136 8.68681 13.887 8.30626 12.9002 7.67425V12.4383C12.9002 14.5967 11.6227 17 8.45112 17C7.85609 16.9974 7.26758 16.876 6.72006 16.643C6.17255 16.41 5.67706 16.0701 5.26263 15.6431C4.84821 15.2161 4.52318 14.7107 4.30662 14.1565C4.09005 13.6023 3.9863 13.0104 4.00145 12.4156C4.02029 11.7963 4.17018 11.188 4.44123 10.6309C4.71228 10.0738 5.09833 9.5804 5.57394 9.18333C6.04955 8.78626 6.60392 8.4945 7.2005 8.32729C7.79709 8.16008 8.42233 8.12121 9.03503 8.21325Z"
      fill={color}
    />
  </svg>
);
