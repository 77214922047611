import React from 'react';

const EmptySelectedOpportunitiesIcon = ({ color = '#8F9199' }) => (
  <svg width="32" height="34" viewBox="0 0 32 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.30769 30.8457L30 3.1534"
      stroke={color}
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M3.46109 12.3848L13.8457 12.3848L13.8457 2.00015"
      stroke={color}
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.2305 1.99955L13.2689 1.99955L3.46124 11.8072L3.46124 23.3457"
      stroke={color}
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M10.9616 32L27.6924 32L27.6924 11.8077"
      stroke={color}
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default EmptySelectedOpportunitiesIcon;
