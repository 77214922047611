import { SelectOptionType } from '@ts/common.types';

export const campaignStatusColor = {
  0: '#F5F6FA',
  1: '#221CB6',
  2: '#FFF1E2',
  3: '#FFEEEE',
  4: '#ECFFF3',
  5: '#ff0000',
};

export const campaignStatusTextColor = {
  0: '#A3B2C9',
  1: '#FFFFFF',
  2: '#FC8E0E',
  3: '#ED483D',
  4: '#23E771',
  5: '#ff0000',
};

export const campaignStatusIconColor = {
  0: '#A3B2C9',
  1: '#221CB6',
  2: '#ffffff',
  3: '#FFFFFF',
  4: '#ffffff',
  5: '#ff0000',
};

export const campaignPageTypes = {
  DELETED: 'deleted',
  DASHBOARD: 'dashboard',
  ALL_CAMPAIGNS: 'all-campaigns',
};

export const hunterDepartment = {
  executive: 'Executive',
  it: 'IT',
  finance: 'Finance',
  management: 'Management',
  sales: 'Sales',
  legal: 'Legal',
  support: 'Support',
  hr: 'HR',
  marketing: 'Marketing',
  communication: 'Communication',
};

export const hunterSeniority = {
  junior: 'Junior',
  senior: 'Senior',
  executive: 'Executive',
};

export const hunterDepartmentSelectOptions: SelectOptionType[] = Object.entries(
  hunterDepartment
).map(([value, label]) => ({
  value,
  label,
}));

export const hunterSenioritySelectOptions: SelectOptionType[] = Object.entries(hunterSeniority).map(
  ([value, label]) => ({
    value,
    label,
  })
);
