import React, { useRef } from 'react';
import cn from 'class-names';

import Display from '@components/Display/Display';

import './Bar.scss';

export default ({ duration, curTime, onTimeUpdate, isInline }): JSX.Element => {
  const barProgressRef = useRef();
  const curPercentage = (curTime / duration) * 100;

  function formatDuration(curDuration = 0) {
    if (curDuration > 3600) {
      return new Date(curDuration * 1000).toISOString().substr(11, 8);
    }

    return new Date(curDuration * 1000).toISOString().substr(14, 5);
  }

  function calcClickedTime(e) {
    if (!barProgressRef.current) {
      return 0;
    }
    const clickPositionInPage = e.pageX;
    // @ts-ignore
    const barStart = barProgressRef.current.getBoundingClientRect().left + window.scrollX;
    // @ts-ignore
    const barWidth = barProgressRef.current.offsetWidth;
    const clickPositionInBar = clickPositionInPage - barStart;
    const timePerPixel = duration / barWidth;
    return timePerPixel * clickPositionInBar;
  }

  function handleTimeDrag(e) {
    e.stopPropagation();
    onTimeUpdate(calcClickedTime(e));

    const updateTimeOnMove = (eMove) => {
      onTimeUpdate(calcClickedTime(eMove));
    };

    document.addEventListener('mousemove', updateTimeOnMove);

    document.addEventListener('mouseup', () => {
      document.removeEventListener('mousemove', updateTimeOnMove);
    });
  }

  const cnPlayer = cn('player-bar', {
    'player-bar--inline': isInline,
  });

  return (
    <div className={cnPlayer}>
      <Display isVisible={isInline}>
        <span className="player-bar__time-start">{formatDuration(curTime)}</span>
      </Display>
      <div
        ref={barProgressRef}
        className="player-bar__progress"
        style={{
          background: `linear-gradient(to right, #251FB6 ${curPercentage}%, #E9E9E9 0)`,
        }}
        onMouseDown={handleTimeDrag}
        tabIndex={0}
        role="button"
      >
        <span className="player-bar__progress-knob" style={{ left: `${curPercentage}%` }} />
      </div>
      <Display isVisible={isInline}>
        <span className="player-bar__time-end">{formatDuration(duration)}</span>
      </Display>

      <Display isVisible={!isInline}>
        <div className="player-bar__time">
          <span className="player-bar__time-start">{formatDuration(curTime)}</span>
          <span className="player-bar__time-end">{formatDuration(duration)}</span>
        </div>
      </Display>
    </div>
  );
};
