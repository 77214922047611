import React from 'react';

export default ({ color = '#BDBDBD' }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.0008 3.73513C13.6747 2.40905 11.8761 1.66406 10.0008 1.66406C8.12539 1.66406 6.32684 2.40905 5.00076 3.73513C3.67467 5.06121 2.92969 6.85977 2.92969 8.73513C2.92969 10.6105 3.67467 12.409 5.00076 13.7351L9.39242 18.1351C9.46989 18.2132 9.56206 18.2752 9.66361 18.3175C9.76516 18.3598 9.87408 18.3816 9.98409 18.3816C10.0941 18.3816 10.203 18.3598 10.3046 18.3175C10.4061 18.2752 10.4983 18.2132 10.5758 18.1351L15.0008 13.6935C16.3213 12.3729 17.0632 10.5818 17.0632 8.7143C17.0632 6.84675 16.3213 5.0557 15.0008 3.73513ZM13.8091 12.5018L10.0008 16.3268L6.19242 12.5018C5.44004 11.7487 4.92782 10.7895 4.7205 9.74537C4.51318 8.70124 4.62008 7.61909 5.02768 6.6357C5.43527 5.65231 6.12527 4.81184 7.01045 4.22053C7.89563 3.62922 8.93624 3.31361 10.0008 3.31361C11.0653 3.31361 12.1059 3.62922 12.9911 4.22053C13.8762 4.81184 14.5662 5.65231 14.9738 6.6357C15.3814 7.61909 15.4883 8.70124 15.281 9.74537C15.0737 10.7895 14.5615 11.7487 13.8091 12.5018ZM7.50076 6.1768C6.82802 6.85161 6.45025 7.7656 6.45025 8.71846C6.45025 9.67132 6.82802 10.5853 7.50076 11.2601C8.00056 11.7608 8.63708 12.1027 9.33046 12.243C10.0238 12.3832 10.7432 12.3156 11.3983 12.0486C12.0534 11.7816 12.6151 11.327 13.0128 10.742C13.4106 10.157 13.6267 9.46752 13.6341 8.76013C13.6378 8.2878 13.5469 7.81951 13.3665 7.38295C13.1862 6.94639 12.9201 6.55043 12.5841 6.21846C12.2538 5.88061 11.86 5.61141 11.4253 5.42635C10.9906 5.24129 10.5235 5.14403 10.0511 5.14015C9.57864 5.13628 9.11009 5.22588 8.6724 5.40379C8.23471 5.5817 7.83652 5.84441 7.50076 6.1768ZM11.4091 10.0768C11.0933 10.3974 10.6759 10.5984 10.2284 10.6453C9.78079 10.6923 9.33081 10.5823 8.95535 10.3342C8.57988 10.0861 8.30225 9.71533 8.16993 9.2852C8.0376 8.85507 8.0588 8.39233 8.22989 7.97609C8.40098 7.55986 8.71134 7.21598 9.10791 7.00324C9.50448 6.79051 9.96263 6.72214 10.404 6.80981C10.8454 6.89748 11.2427 7.13576 11.5278 7.48391C11.813 7.83205 11.9684 8.26844 11.9674 8.71846C11.9553 9.23286 11.7395 9.7214 11.3674 10.0768H11.4091Z"
      fill={color}
    />
  </svg>
);
