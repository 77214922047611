import { RefObject, useCallback, useRef, useState } from 'react';

export function useRefCallback<T = unknown>(): [RefObject<T>, (node: T) => void, boolean] {
  const ref = useRef<T>();

  const [ready, setReady] = useState<boolean>(false);

  const setRef = useCallback((node) => {
    ref.current = node;
    setReady(!!node);
  }, []);

  return [ref, setRef, ready];
}
