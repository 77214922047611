import React from 'react';

export default ({
  color = '#C4C6CD',
  size = 14,
}: {
  color?: string;
  size?: number;
}): JSX.Element => (
  <svg width={size} height={size} viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.75 4.5C10.7165 4.5 11.5 3.7165 11.5 2.75C11.5 1.7835 10.7165 1 9.75 1C8.7835 1 8 1.7835 8 2.75C8 3.7165 8.7835 4.5 9.75 4.5Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.75 8.5835C3.7165 8.5835 4.5 7.79999 4.5 6.8335C4.5 5.867 3.7165 5.0835 2.75 5.0835C1.7835 5.0835 1 5.867 1 6.8335C1 7.79999 1.7835 8.5835 2.75 8.5835Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.75 12.6665C10.7165 12.6665 11.5 11.883 11.5 10.9165C11.5 9.95001 10.7165 9.1665 9.75 9.1665C8.7835 9.1665 8 9.95001 8 10.9165C8 11.883 8.7835 12.6665 9.75 12.6665Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.26172 7.71436L8.24589 10.036"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.24005 3.63086L4.26172 5.95253"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
