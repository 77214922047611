import { useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import {
  CustomVariableTypeMap,
  CustomVariableValueRelatedToMap,
} from 'respona_api/generated/common_pb';

import { getCurrentWorkspaceId } from '@redux/selectors/workspaces.selectors';
import {
  createCustomVariableApi,
  getAllCustomVariablesOfWorkspaceApi,
  removeCustomVariableApi,
  updateCustomVariableApi,
} from '@api/customVariables.api';
import {
  CustomAiVariable,
  CustomSelectVariable,
  CustomVariablePlaceholderType,
} from '@ts/customVariales.types';

export default (
  relation: CustomVariableValueRelatedToMap[keyof CustomVariableValueRelatedToMap] = null
) => {
  const curWorkspaceId = useSelector(getCurrentWorkspaceId);
  const queryClient = useQueryClient();

  const {
    data: customVariables,
    isLoading: isCustomVariablesLoading,
    refetch: refetchCustomVariables,
  } = useQuery<CustomVariablePlaceholderType[]>(
    ['custom-variables', curWorkspaceId, relation],
    () => getAllCustomVariablesOfWorkspaceApi(curWorkspaceId, relation),
    {
      enabled: !!curWorkspaceId,
      retry: 1,
      refetchOnWindowFocus: false,
      staleTime: 15 * 60 * 1000,
    }
  );

  const addCustomVariable = async (
    type: CustomVariableTypeMap[keyof CustomVariableTypeMap],
    title: string,
    workspaceId: number,
    description: string,
    relatedTo: CustomVariableValueRelatedToMap[keyof CustomVariableValueRelatedToMap],
    aiSettings: CustomAiVariable,
    selectSettings: CustomSelectVariable
  ) => {
    const newVariable = await createCustomVariableApi(
      type,
      title,
      workspaceId,
      description,
      relatedTo,
      aiSettings,
      selectSettings
    );

    queryClient.setQueryData<CustomVariablePlaceholderType[]>(
      ['custom-variables', curWorkspaceId, relation],
      (oldData) => (oldData ? [...oldData, newVariable] : [newVariable])
    );

    return newVariable;
  };

  const removeCustomVariable = async (id: number) => {
    await removeCustomVariableApi(id, curWorkspaceId);

    queryClient.setQueryData<CustomVariablePlaceholderType[]>(
      ['custom-variables', curWorkspaceId, relation],
      (oldData) => (oldData ? oldData.filter((variable) => variable.id !== id) : [])
    );
  };

  const updateCustomVariable = async (
    id: number,
    title: string,
    description: string,
    relatedTo: CustomVariableValueRelatedToMap[keyof CustomVariableValueRelatedToMap],
    type: CustomVariableTypeMap[keyof CustomVariableTypeMap],
    aiSettings: CustomAiVariable,
    selectSettings: CustomSelectVariable
  ) => {
    const updatedVariable = await updateCustomVariableApi(
      id,
      title,
      description,
      relatedTo,
      type,
      aiSettings,
      selectSettings
    );

    queryClient.setQueryData<CustomVariablePlaceholderType[]>(
      ['custom-variables', curWorkspaceId, relation],
      (oldData) =>
        oldData
          ? oldData.map((variable) => (variable.id === id ? updatedVariable : variable))
          : [updatedVariable]
    );

    return updatedVariable;
  };

  return {
    isCustomVariablesLoading,
    customVariables,
    refetchCustomVariables,
    addCustomVariable,
    removeCustomVariable,
    updateCustomVariable,
  };
};
