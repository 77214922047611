import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { SVGIcon } from '@uikit/Icon/Icon';
import { CampaignPipelineResponseType } from '@ts/pipelilne.types';
import './ContentSearchActivityLogs.scss';
import { CampaignAutomationStatus } from 'respona_api/generated/common_pb';
import { getCurrentWorkspaceId } from '@redux/selectors/workspaces.selectors';
import PipelineListItem from '@components/CampaignSettings/ContentSearchActivityLogs/_components/PipelineListItem';
import { Status } from '@components/CampaignSettings/ContentSearchActivityLogs/types/types';
import getStatusForUI from '@components/CampaignSettings/ContentSearchActivityLogs/utils/getStatusForUI';
import useCampaignPipelines from '@hooks/useCampaignPipelines';

function ContentSearchActivityLogs({
  campaignId,
  setOpenModal,
  setOpenFindContactsModal,
  setOpenPersonalizerModal,
  setActivePipelineId,
}: {
  campaignId: number;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  setOpenFindContactsModal: Dispatch<SetStateAction<boolean>>;
  setOpenPersonalizerModal: Dispatch<SetStateAction<boolean>>;
  setActivePipelineId: Dispatch<SetStateAction<number>>;
}): JSX.Element {
  const [hideRow, setHideRow] = useState<number>(-1);
  const [filter, setFilter] = useState<string>(Status.ALL);
  const currentWorkspaceId = useSelector(getCurrentWorkspaceId);
  const { items: pipelines } = useCampaignPipelines(currentWorkspaceId, campaignId);
  const filteredPipelines = useCallback(
    (data: CampaignPipelineResponseType[]): CampaignPipelineResponseType[] => {
      switch (filter) {
        case Status.IN_PROCESS:
          return data.filter(({ status }) => status === CampaignAutomationStatus.IN_PROCESS);
        case Status.FUTURE:
          return data.filter(({ status }) => status === CampaignAutomationStatus.FUTURE);
        case Status.FINISHED:
          return data.filter(({ status }) => status === CampaignAutomationStatus.FINISHED);
        case Status.CANCELED:
          return data.filter(({ status }) => status === CampaignAutomationStatus.CANCELED);
        case Status.DRAFT:
          return data.filter(({ status }) => status === CampaignAutomationStatus.DRAFT);
        default:
          return data;
      }
    },
    [filter]
  );

  const countPipelines = useCallback(
    (filterStatus: string): number => {
      if (!pipelines || pipelines.length === 0) {
        return 0;
      }
      return pipelines
        .filter((it) => it)
        .filter(({ status }) => status === getStatusForUI(filterStatus)).length;
    },
    [pipelines]
  );

  useEffect(() => {
    if (!pipelines || pipelines.length === 0) {
      return;
    }

    const itemList = document.querySelector('.review-step-list');
    if (!itemList || !itemList.children) {
      return;
    }

    filteredPipelines(pipelines).forEach((_, index) => {
      if (index >= 4) {
        itemList.children.item(index)?.classList.add('d-none');
      }
    });
  }, [pipelines, filteredPipelines]);

  const showMore = useCallback(() => {
    const hiddenElements = document.querySelectorAll('.review-step-list__item.d-none');
    hiddenElements.forEach((item) => {
      item.classList.remove('d-none');
      item.classList.add('d-block');
    });

    const showMoreBtn = document.getElementById('show-more');
    if (showMoreBtn) {
      showMoreBtn.classList.add('d-none');
    }
  }, []);

  const memoizedPipelines = useMemo(
    () => filteredPipelines(pipelines),
    [filteredPipelines, pipelines]
  );

  return (
    <div className="content-search-activity">
      <div className="content-search-activity-title">Activity Log</div>
      <div className="content-search-activity-tabs">
        {Object.values(Status).map((status) => (
          <div
            key={status}
            className={`content-search-activity-tabs__item ${filter === status ? 'active' : ''}`}
            onClick={() => setFilter(status)}
          >
            <p className="content-search-activity-tabs__item-title">{status}</p>
            <span className="content-search-activity-tabs__item-number">
              {status === Status.ALL ? pipelines.length : countPipelines(status)}
            </span>
          </div>
        ))}
      </div>
      <div className="review-step-list">
        {memoizedPipelines.map((pipeline) => (
          <PipelineListItem
            key={pipeline.id}
            campaignId={campaignId}
            workspaceId={currentWorkspaceId}
            {...pipeline}
            setOpenModal={setOpenModal}
            setOpenFindContactsModal={setOpenFindContactsModal}
            setOpenPersonalizerModal={setOpenPersonalizerModal}
            setActivePipelineId={setActivePipelineId}
            hideRow={hideRow}
            setHideRow={setHideRow}
          />
        ))}
        {memoizedPipelines && memoizedPipelines.length >= 5 && (
          <div id="show-more" className="review-step-list__footer" onClick={showMore}>
            <div className="review-step-list__footer-button">
              <span>Show more</span>
              <SVGIcon icon="arrowDown" color="#221CB6" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ContentSearchActivityLogs;
