import React from 'react';

import EmailSelect from '@uikit/EmailSelect/EmailSelect';

import './email.scss';

export default (label = '', key: string) => ({
  type: 'select',
  label,
  key,
  Renderer: (props) => <EmailSelect {...props} isMulti />,
});
