import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import Input from '@uikit/Input/Input';
import { searchHaroApi } from '@api/haro.api';
import { getCurrentWorkspaceId } from '@redux/selectors/workspaces.selectors';

import { HaroFilterPropsType } from '@ts/props.types';
import Display from '@components/Display/Display';
import debounce from '@utils/debounce';
import Loader from '@uikit/Loader/Loader';
import Button from '@uikit/Button/Button';

import FiltersKeywordsPreview from '../FiltersKeywordsPreview/FiltersKeywordsPreview';
import SelectedFilterKeywords from '../SelectedFilterKeywords/SelectedFilterKeywords';
import FilterCreationSelectedKeywords from '../FilterCreationSelectedKeywords/FilterCreationSelectedKeywords';

import './FilterCreationKeywordsStep.scss';

export default ({
  filterFields,
  onFieldChange,
  onContinue,
  onBack,
}: {
  filterFields: HaroFilterPropsType;
  onFieldChange: (fieldName: string, value: any) => void;
  onContinue: () => void;
  onBack: () => void;
}): JSX.Element => {
  const curWorkspaceId = useSelector(getCurrentWorkspaceId);
  const [searchString, changeSearchString] = useState('');
  const [searchResult, changeSearchResult] = useState(null);
  const [isLoading, changeIsLoading] = useState(false);

  const makeSearch = (query) => {
    changeIsLoading(true);
    searchHaroApi(curWorkspaceId, query, 0, undefined, 5)
      .then(changeSearchResult)
      .finally(() => changeIsLoading(false));
  };
  const handleUpdateSearchQueryDebounced = useRef(
    debounce((query) => {
      makeSearch(query);
    }, 1000)
  ).current;

  const handleSearchStringChange = ({ target: { value } }) => {
    if (value?.trim() === '') {
      changeSearchResult(null);
    } else {
      changeIsLoading(true);
      handleUpdateSearchQueryDebounced(value);
    }

    changeSearchString(value);
  };
  const handleAddKeyword = () => {
    if (!filterFields.keywords.includes(searchString)) {
      onFieldChange('keywords', [...filterFields.keywords, searchString]);
      changeSearchString('');
      changeSearchResult(null);
    }
  };
  const handleChangeKeywords = (keywords: string[]) => {
    onFieldChange('keywords', keywords);
  };

  return (
    <div className="filter-creation-keywords-step">
      <div className="filter-creation-keywords-step__body">
        <div className="filter-creation-keywords-step__keywords-search">
          <div className="filter-creation-keywords-step__search-row">
            <div className="filter-creation-keywords-step__search-input">
              <Input
                icon="search"
                value={searchString}
                onChange={handleSearchStringChange}
                placeholder="Insert keyword..."
                isClearable
                onClear={() => changeSearchString('')}
              />
              <Button
                disabled={searchString?.trim() === ''}
                className="filter-creation-keywords-step__search-button"
                onClick={handleAddKeyword}
              >
                Add Keyword
              </Button>
            </div>
          </div>
          <FiltersKeywordsPreview
            inquiries={searchResult}
            isLoading={isLoading && searchString?.trim() !== ''}
            nothingFound={
              searchResult !== null && searchResult.length === 0 && searchString?.trim() !== ''
            }
          />
        </div>
        <FilterCreationSelectedKeywords
          keywords={filterFields.keywords}
          changeKeywords={handleChangeKeywords}
        />
      </div>

      <div className="filter-creation-keywords-step__footer">
        <Button type="secondary" onClick={onBack}>
          Back
        </Button>
        <Button onClick={onContinue}>Continue</Button>
      </div>
    </div>
  );
};
