/* eslint-disable react/no-unused-prop-types */
import React from 'react';

import { Tr } from '@uikit/Table/Table';
import ActionsMenu from '@components/ActionsMenu/ActionsMenu';

import { TemplateType } from '@ts/template.types';
import OwnerSharingCircle from '@uikit/OwnerSharingCircle/OwnerSharingCircle';
import RateCell from '@uikit/RateCell/RateCell';
import { getDateShort } from '@utils/date';
import ReactTooltip from 'react-tooltip';
import stripHtml from '@utils/stripHtml';
import Display from '@components/Display/Display';
import { SVGIcon } from '@uikit/Icon/Icon';

type TableRenderProps = {
  row: TemplateType;
  children?: any;
  renderProps?: {
    [key: string]: any;
  };
};

export default [
  {
    title: '',
    dataKey: 'templateWarnings',
    RenderCell: ({ row: { id, subject } }: TableRenderProps): JSX.Element => {
      const subjectText = stripHtml(subject);

      if (subjectText.trim().length === 0) {
        return (
          <>
            <span data-tip="" data-for={`template-${id}-warning`}>
              <SVGIcon icon="warningTriangle" />
            </span>
            <ReactTooltip id={`template-${id}-warning`} className="react-tooltip" arrowColor="transparent">
              <div className="templates-table__template-warning-triangle">
                Template subject is empty
              </div>
            </ReactTooltip>
          </>
        );
      }

      return null;
    },
  },
  {
    title: 'Template name',
    dataKey: 'title',
    RenderCell: ({ row: { title, subject } }: TableRenderProps): JSX.Element => {
      const subjectText = stripHtml(subject);
      return (
        <div className="title-cell">
          <div className="title-cell__title" title={title}>
            {title}
          </div>
          <Display isVisible={subjectText.trim().length > 0}>
            <div className="title-cell__subtitle" title={subjectText}>
              {subjectText}
            </div>
          </Display>
        </div>
      );
    },
  },
  {
    title: 'Sent',
    dataKey: 'sent',
    RenderCell: ({ row }: TableRenderProps): JSX.Element => (
      <div className="delivered-cell">{row.sentNumber || '-'}</div>
    ),
  },
  {
    title: 'Opened',
    dataKey: 'openRate',
    RenderCell: ({ row }: TableRenderProps): JSX.Element => (
      <RateCell value={row.openRate} color="#9324EB" name="openRate" />
    ),
  },
  {
    title: 'Replied',
    dataKey: 'replyRate',
    RenderCell: ({ row }: TableRenderProps): JSX.Element => (
      <RateCell value={row.replyRate} color="#149FEE" name="replyRate" />
    ),
  },
  {
    title: 'Created',
    dataKey: 'created',
    RenderCell: ({ row }: TableRenderProps): JSX.Element => (
      <div className="delivered-cell">
        {row.createdAt ? getDateShort(new Date(row.createdAt)) : '-'}
      </div>
    ),
  },
  {
    title: 'Owner',
    dataKey: 'owner',
    RenderCell: ({ row, renderProps: { onChangeOwner } }: TableRenderProps): JSX.Element => {
      const handleChangeOwnerClick = (e) => {
        e.stopPropagation();
        onChangeOwner(row);
      };

      return (
        <div className="shares-cell">
          <OwnerSharingCircle
            avatarUrl={String(row.ownerAvatar)}
            userId={row.ownerId}
            onClick={handleChangeOwnerClick}
          />
        </div>
      );
    },
  },
  {
    title: '',
    dataKey: 'actions',
    RenderCell: ({ row: { id }, renderProps: { onRemove } }: TableRenderProps): JSX.Element => {
      const handleRemove = () => onRemove(id);

      return <ActionsMenu onRemove={handleRemove} id={`templates-actions-${id}`} tooltipPlace="left" />;
    },
  },
];

export const RenderRow = ({
  children,
  row: { id },
  renderProps: { onOpen },
}: TableRenderProps): JSX.Element => {
  const handleClick = () => onOpen(id);
  return (
    <Tr onClick={handleClick} className="templates-table__row">
      {children}
    </Tr>
  );
};
