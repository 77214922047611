import React from 'react';

import { HaroOpportunityType } from '@ts/haro.types';

import Display from '@components/Display/Display';
import Loader from '@uikit/Loader/Loader';

import InquiriesPreviewTable from './_components/InquiriesPreviewTable/InquiriesPreviewTable';

import './FiltersKeywordsPreview.scss';

const FiltersKeywordsPreview = ({
  inquiries,
  isLoading,
  nothingFound,
}: {
  inquiries: HaroOpportunityType[];
  isLoading: boolean;
  nothingFound: boolean;
}): JSX.Element => {
  return (
    <div className="filters-keywords-preview">
      <div className="filters-keywords-preview__title">Keyword Preview</div>
      <div className="filters-keywords-preview__table">
        <InquiriesPreviewTable inquiries={inquiries} />
      </div>
      <Display isVisible={nothingFound}>
        <div className="filters-keywords-preview__nothing-found">Nothing was found</div>
      </Display>
      {/*
        Loader is here - to show loading before data will be loaded
        While isLoading table doesn't render, but loader is open...then switch
       */}
      <Loader isLoading={isLoading} withTopMargin />
    </div>
  );
};

export default FiltersKeywordsPreview;
