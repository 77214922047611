import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';

import './DetailedCreditsUsage.scss';

import ConfigurableTable from '@uikit/ConfigurableTable/ConfigurableTable';
import Loader from '@uikit/Loader/Loader';

import { useInfiniteQuery, useQuery } from 'react-query';
import { getCreditUsageDailyInsightsApi, getCreditUsagePageableApi } from '@api/analytics.api';

import {
  insightsDateFilterSelector,
  insightsCreditsUsageByUserFiltersSelector,
} from '@redux/selectors/insights.selector';
import { getCurrentWorkspaceId } from '@redux/selectors/workspaces.selectors';
import { CreditUsageItemType } from '@ts/insights.types';
import {
  CreditUsageCategory,
  CreditUsageCategoryMap,
  CreditUsageSearchGroup,
  CreditUsageSearchGroupMap,
} from 'respona_api/generated/analytics_pb';
import useOrganizationMembers from '@hooks/useOrganizationMembers';
import { useRefCallback } from '@helpers/refHelpers';
import useIntersectionObserver from '@hooks/useIntersectionObserver';
import InsightGraphic from '@components/Insights/InsightGraphic/InsightGraphic';
import LeaderBoardScale from '@uikit/Icon/illustrations/LeaderBoardScale';
import { Button } from '@uikit/Button/Button';
import { billingDetailsSelector } from '@redux/selectors/billings.selectors';
import LeaderBoardIllustaration from '@uikit/Icon/illustrations/LeaderBoardIllustaration';
import { BillingSubscriptionType } from 'respona_api/generated/billing_pb';
import { setInsightsDateFilter } from '@redux/actions/insights.actions';
import tableConfig from './tableConfig';

type DetailedCreditsUsageTablePropsType = {
  tableData: CreditUsageItemType[];
  sortingSelectedKey: string;
};

const mockedTableData = [
  {
    creditsAmount: 10,
    datetime: 1721747956000,
    items: 10,
    searchCategory: 1,
    searchGroup: 0,
    totalItems: 46,
    userId: 7,
  },
  {
    creditsAmount: 20,
    datetime: 1721747956000,
    items: 10,
    searchCategory: 2,
    searchGroup: 2,
    totalItems: 46,
    userId: 7,
  },
  {
    creditsAmount: 15,
    datetime: 1721747956000,
    items: 10,
    searchCategory: 1,
    searchGroup: 0,
    totalItems: 46,
    userId: 7,
  },
  {
    creditsAmount: 10,
    datetime: 1721747956000,
    items: 10,
    searchCategory: 3,
    searchGroup: 1,
    totalItems: 25,
    userId: 7,
  },
  {
    creditsAmount: 5,
    datetime: 1721747956000,
    items: 10,
    searchCategory: 5,
    searchGroup: 0,
    totalItems: 46,
    userId: 7,
  },
  {
    creditsAmount: 15,
    datetime: 1721747956000,
    items: 10,
    searchCategory: 1,
    searchGroup: 0,
    totalItems: 58,
    userId: 7,
  },
  {
    creditsAmount: 10,
    datetime: 1721747956000,
    items: 10,
    searchCategory: 1,
    searchGroup: 0,
    totalItems: 46,
    userId: 7,
  },
  {
    creditsAmount: 10,
    datetime: 1721747956000,
    items: 10,
    searchCategory: 1,
    searchGroup: 0,
    totalItems: 46,
    userId: 7,
  },
  {
    creditsAmount: 20,
    datetime: 1721747956000,
    items: 10,
    searchCategory: 2,
    searchGroup: 2,
    totalItems: 46,
    userId: 7,
  },
  {
    creditsAmount: 15,
    datetime: 1721747956000,
    items: 10,
    searchCategory: 1,
    searchGroup: 0,
    totalItems: 46,
    userId: 7,
  },
  {
    creditsAmount: 10,
    datetime: 1721747956000,
    items: 10,
    searchCategory: 3,
    searchGroup: 1,
    totalItems: 25,
    userId: 7,
  },
];

const parseGroup = (group: CreditUsageSearchGroupMap[keyof CreditUsageSearchGroupMap]) => {
  switch (group) {
    case CreditUsageSearchGroup.CREDIT_USAGE_SEARCH_GROUP_CONTENT:
      return { title: 'Content Search', color: '#58CD95', field: 'contentSearchCreditsAmount' };

    case CreditUsageSearchGroup.CREDIT_USAGE_SEARCH_GROUP_CONTACT:
      return { title: 'Email Search', color: '#221CB6', field: 'contactSearchCreditsAmount' };

    case CreditUsageSearchGroup.CREDIT_USAGE_SEARCH_GROUP_AI:
      return { title: 'AI Search', color: '#FC8619', field: 'aiSearchCreditsAmount' };

    default:
      return { title: '', color: '#fff', field: '' };
  }
};

const parseCategory = (type: CreditUsageCategoryMap[keyof CreditUsageCategoryMap]) => {
  switch (type) {
    case CreditUsageCategory.CREDIT_USAGE_CONTENT_SEARCH:
      return {
        title: 'Automated content search',
        field: 'contentSearchCreditsAmount',
      };

    case CreditUsageCategory.CREDIT_USAGE_CONTACT_AUTOMATION_SEARCH:
      return {
        title: 'Automated email search',
        field: 'contactAutomationSearchCreditsAmount',
      };

    case CreditUsageCategory.CREDIT_USAGE_CONTACT_MANUAL_SEARCH:
      return {
        title: 'Manual email search',
        field: 'contactManualSearchCreditsAmount',
      };

    case CreditUsageCategory.CREDIT_USAGE_CONTACT_LINKED_IN_SEARCH:
      return {
        title: 'Email Search by LinkedIn',
        field: 'contactLinkedInSearchCreditsAmount',
      };

    case CreditUsageCategory.CREDIT_USAGE_CONTACT_EMAIL_SEARCH:
      return {
        title: 'Manual email search',
        field: 'contactEmailSearchCreditsAmount',
      };

    case CreditUsageCategory.CREDIT_USAGE_EMAIL_VERIFICATION:
      return {
        title: 'Email verification',
        field: 'emailVerificationCreditsAmount',
      };

    case CreditUsageCategory.CREDIT_USAGE_AI_PERSONALIZATION_CHEAP:
      return {
        title: 'AI Personalization (OpenAI 4o-mini)',
        field: 'aiPersonalizationOldCreditsAmount',
      };

    case CreditUsageCategory.CREDIT_USAGE_AI_PERSONALIZATION_EXPENSIVE:
      return {
        title: 'AI Personalization (OpenAI 4o)',
        field: 'aiPersonalizationNewCreditsAmount',
      };

    case CreditUsageCategory.CREDIT_USAGE_AI_MAILBOX_ASSISTANT:
      return {
        title: 'Mailbox AI assitant',
        field: 'aiMailboxAssistantCreditsAmount',
      };

    case CreditUsageCategory.CREDIT_USAGE_AI_KEYWORD_SUGGESTIONS:
      return {
        title: 'AI Keywords Suggestions',
        field: 'aiKeywordsSuggestionsCreditsAmount',
      };

    case CreditUsageCategory.CREDIT_USAGE_MOZ_METRICS_POPULATION:
      return {
        title: 'Domain Metrics Population',
        field: 'metricsPopulationCreditsAmount',
      };

    case CreditUsageCategory.CREDIT_USAGE_SUMMARIZE_TEXT:
      return { title: 'Text Summarization', field: 'summarizeTextCreditsAmount' };

    case CreditUsageCategory.CREDIT_USAGE_SUMMARIZE_AUDIO:
      return { title: 'Audio Summarization', field: 'summarizeAudioCreditsAmount' };

    default:
      return { title: '', field: '' };
  }
};

export function DetailedCreditsUsageTable({
  tableData,
  sortingSelectedKey,
}: DetailedCreditsUsageTablePropsType): JSX.Element {
  const { items: members } = useOrganizationMembers(true);

  if (!tableData) {
    return null;
  }

  const [sortingValue, setSortingValue] = useState<string>('');
  const [sortingDirection, setSortingDirection] = useState<string>('none');

  useEffect(() => {
    setSortingValue(sortingSelectedKey);
    setSortingDirection('down');
  }, [sortingSelectedKey]);

  if (sortingDirection !== 'none') {
    tableData = tableData.sort((a, b): number => {
      if (sortingDirection === 'up') {
        return a[sortingValue] - b[sortingValue];
      }

      return b[sortingValue] - a[sortingValue];
    });
  }

  const transformData = (data) => {
    return data.map((item) => {
      const user = members?.find(({ userId }) => userId === item.userId) || {
        fullName: '-',
        logoUrl: '',
      };
      const { color: groupColor, title: groupTitle } = parseGroup(item.searchGroup);
      const { title: categoryTitle } = parseCategory(item.searchCategory);
      return {
        ...item,
        searchGroup: groupTitle,
        searchCategory: categoryTitle,
        color: groupColor,
        datetime: `${format(new Date(item.datetime), 'MM/dd/yyyy')} at ${format(
          new Date(item.datetime),
          'hh:mm aaa'
        )}`,
        username: user.fullName,
        avatarUrl: user.logoUrl,
      };
    });
  };

  const handleSortClick = (direction: string, key: string): void => {
    if (key === sortingValue && sortingDirection !== 'none') {
      setSortingDirection('none');
      setSortingValue('');
    } else {
      setSortingDirection(direction);
      setSortingValue(key);
    }
  };

  return (
    <div className="credits-usage-by-user-report-graphics_table">
      <ConfigurableTable
        tableClassName="credits-usage-by-user-report-table"
        rowClassName="credits-usage-by-user-report-table__body-row"
        data={transformData(tableData)}
        config={tableConfig}
        renderProps={{ handleSortClick, selectedKey: sortingValue, direction: sortingDirection }}
      />
    </div>
  );
}

const selectOptions = [
  { value: 'aiSearchCreditsAmount', label: 'AI Search' },
  { value: 'contactSearchCreditsAmount', label: 'Email Search' },
  { value: 'contentSearchCreditsAmount', label: 'Content Search' },
];

function DetailedCreditsUsage(): JSX.Element {
  const history = useHistory();
  const [switchState, setSwitchState] = useState(false);
  const [loadingRef, setLoadingRef, ready] = useRefCallback<HTMLDivElement>();
  const [selectedValue, setSelectedValue] = useState(selectOptions[0]);
  const currentWorkspaceId = useSelector(getCurrentWorkspaceId);
  const dateFilter = useSelector(insightsDateFilterSelector);
  const filtersFromRedux = useSelector(insightsCreditsUsageByUserFiltersSelector);
  const parsedFilters = Object.values(filtersFromRedux);
  const dispatch = useDispatch();
  const {
    data: { enterpriseFeaturesEnabled, type: billingType },
  } = useSelector(billingDetailsSelector);

  const options = {
    refetchOnWindowFocus: false,
    enabled: !!currentWorkspaceId,
  };
  const [chartsEmail, setChartsEmail] = useState([
    {
      title: 'Content Search',
      field: 'contentSearchCreditsAmount',
      group: CreditUsageSearchGroup.CREDIT_USAGE_SEARCH_GROUP_CONTENT,
      color: '#58CD95',
      isCheckbox: true,
      isChecked: true,
      isClickable: true,
    },
    {
      title: 'Email Search',
      field: 'contactSearchCreditsAmount',
      group: CreditUsageSearchGroup.CREDIT_USAGE_SEARCH_GROUP_CONTACT,
      color: '#221CB6',
      isCheckbox: true,
      isChecked: true,
      isClickable: true,
    },
    {
      title: 'AI Search',
      field: 'aiSearchCreditsAmount',
      group: CreditUsageSearchGroup.CREDIT_USAGE_SEARCH_GROUP_AI,
      color: '#FC8619',
      isCheckbox: true,
      isChecked: true,
      isClickable: true,
    },
  ]);
  const {
    data: chartDataDailyCreditUsage,
    isLoading: isLoadingDailyCreditUsage,
    refetch: refetchDailyCreditUsage,
  } = useQuery(
    ['credits-usage-daily-insights', filtersFromRedux],
    () => {
      if (dateFilter.startDate) {
        return getCreditUsageDailyInsightsApi(parsedFilters, dateFilter);
      }

      return getCreditUsageDailyInsightsApi(parsedFilters);
    },
    options
  );

  const {
    data: chartDataCreditUsagePageable,
    isLoading: isLoadingCreditUsagePageable,
    refetch: refetchCreditUsagePageable,
    fetchNextPage: fetchNextCreditUsagePageablePage,
    hasNextPage: hasNextCreditUsagePageablePage,
    isFetchingNextPage: isFetchingNextCreditUsagePageablePage,
  } = useInfiniteQuery(
    ['credits-usage-daily-insights-pageable', filtersFromRedux],
    ({ pageParam = 0 }) => {
      if (dateFilter.startDate) {
        return getCreditUsagePageableApi(pageParam, 10, parsedFilters, dateFilter);
      }

      return getCreditUsagePageableApi(pageParam, 10, parsedFilters);
    },
    {
      getNextPageParam: (lastPage: CreditUsageItemType[], allPages) => {
        if (lastPage.length < 10) return false;

        return allPages.length;
      },
      refetchOnWindowFocus: false,
      enabled:
        !!currentWorkspaceId &&
        (enterpriseFeaturesEnabled === true || billingType === BillingSubscriptionType.TRIAL),
    }
  );

  const chartDataCreditUsage = chartDataCreditUsagePageable?.pages?.flat() || [];

  useIntersectionObserver(loadingRef, () => fetchNextCreditUsagePageablePage(), [ready]);

  const isLoadingChartData = () => {
    return isLoadingDailyCreditUsage || !chartDataDailyCreditUsage || currentWorkspaceId === null;
  };

  const isLoadingTableData = () => {
    return (
      isLoadingCreditUsagePageable || !chartDataCreditUsagePageable || currentWorkspaceId === null
    );
  };

  const renderTableLoader = () => {
    if (isFetchingNextCreditUsagePageablePage) {
      return <Loader isLoading withTopMargin />;
    }

    if (chartDataCreditUsage.length && hasNextCreditUsagePageablePage) {
      return (
        <div ref={setLoadingRef} style={{ marginBottom: '20px' }}>
          <Loader isLoading withTopMargin />
        </div>
      );
    }

    return null;
  };

  useEffect(() => {
    if (!currentWorkspaceId) {
      return;
    }

    if (!!chartDataDailyCreditUsage && dateFilter.startDate === null) {
      return;
    }

    if (
      !chartDataDailyCreditUsage ||
      chartDataDailyCreditUsage.dateFrom !== dateFilter.startDate ||
      chartDataDailyCreditUsage.dateTo !== dateFilter.endDate
    ) {
      refetchDailyCreditUsage();
      refetchCreditUsagePageable();
    }
  }, [dateFilter, currentWorkspaceId]);

  useEffect(() => {
    if (dateFilter?.startDate === null && !!chartDataDailyCreditUsage?.dateFrom) {
      dispatch(
        setInsightsDateFilter({
          startDate: +chartDataDailyCreditUsage.dateFrom,
          endDate: +chartDataDailyCreditUsage.dateTo,
        })
      );
    }
  }, [chartDataDailyCreditUsage, dateFilter]);

  const handlerChangeSwitcher = (isActive) => {
    setSwitchState(!isActive);
  };

  const transformMainGraphicData = (data) => {
    return data.map((row) => {
      const types = {};

      row.itemsList.forEach((item) => {
        types[parseGroup(item.group).field] = item.creditsAmount;
      });

      return {
        date: row.date,
        ...types,
      };
    });
  };

  const mapDynamicDataWithCharts = (state, chartData) => {
    return state.map((item) => {
      const data = chartData.totalItemsList.find((it) => it.group === item.group);
      return {
        ...item,
        percentage: data.rate,
        value: data.creditsAmount,
      };
    });
  };

  const handleChangeCheckbox = (field) => {
    const [fieldName] = field.split('__');
    const copiedCharts = structuredClone(chartsEmail).map((item) => {
      if (item.field === fieldName) {
        return {
          ...item,
          isChecked: !item.isChecked,
        };
      }

      return item;
    });

    setChartsEmail(copiedCharts);
  };

  return chartDataDailyCreditUsage?.isEmpty ? (
    <div className="credits-usage-by-user-report-empty">
      <LeaderBoardIllustaration />
      <div className="credits-usage-by-user-report-empty__message">
        {parsedFilters.find((filterRow) => filterRow.value)
          ? 'No usage data for current conditions'
          : 'Start using credits to see this report :)'}
      </div>
    </div>
  ) : (
    <>
      <div className="credits-usage-by-user-report-graphics">
        <div className="credits-usage-by-user-report-graphics_item">
          <div className="report-graphic-container">
            {isLoadingChartData() ? (
              <Loader withTopMargin />
            ) : (
              <div className="report-graphic-container">
                <InsightGraphic
                  mainGraphicData={transformMainGraphicData(
                    chartDataDailyCreditUsage.dailyStatisticsList
                  )}
                  charts={mapDynamicDataWithCharts(chartsEmail, chartDataDailyCreditUsage)}
                  isSidebar
                  title="Credits Usage"
                  isActiveButton
                  handleChangeCheckbox={handleChangeCheckbox}
                  isActiveChangingFilters={false}
                  handlerChangeSwitcher={handlerChangeSwitcher}
                  isActiveSwitch={switchState}
                  isActiveLineChart={switchState}
                  graphicWidth="80"
                  sidebarWidth="20"
                  sidebarItemMaxWidth="100"
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {isLoadingTableData() ? null : enterpriseFeaturesEnabled === true ||
        billingType === BillingSubscriptionType.TRIAL ? (
        <>
          <DetailedCreditsUsageTable
            tableData={chartDataCreditUsagePageable.pages.flat()}
            sortingSelectedKey={selectedValue.value}
          />
          {renderTableLoader()}
        </>
      ) : (
        <div className="credits-usage-by-user-report-upgrade credits-usage-by-user-report-upgrade--plain">
          <div className="blurred-table">
            <DetailedCreditsUsageTable
              tableData={mockedTableData as []}
              sortingSelectedKey={selectedValue.value}
            />

            <div className="blurred-table-image">
              <LeaderBoardScale />
              <div className="credits-usage-by-user-report-upgrade__info">
                <h2>Unlock credit usage logs</h2>
                <p>View a detailed log of how each credit is used.
                  Available on the Enterprise plan.</p>
                <Button onClick={() => history.push('/settings/billing/change-subscription')}>
                  Upgrade my plan
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default DetailedCreditsUsage;
