import React from 'react';

export default ({ color = '#040404' }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.33333 4C5.33333 3.63181 5.63181 3.33333 6 3.33333H13.3333C13.7015 3.33333 14 3.63181 14 4C14 4.36819 13.7015 4.66667 13.3333 4.66667H6C5.63181 4.66667 5.33333 4.36819 5.33333 4ZM3 5C2.73478 5 2.48043 4.89464 2.29289 4.70711C2.10536 4.51957 2 4.26522 2 4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3C3.26522 3 3.51957 3.10536 3.70711 3.29289C3.89464 3.48043 4 3.73478 4 4C4 4.26522 3.89464 4.51957 3.70711 4.70711C3.51957 4.89464 3.26522 5 3 5ZM3 9C2.73478 9 2.48043 8.89464 2.29289 8.70711C2.10536 8.51957 2 8.26522 2 8C2 7.73478 2.10536 7.48043 2.29289 7.29289C2.48043 7.10536 2.73478 7 3 7C3.26522 7 3.51957 7.10536 3.70711 7.29289C3.89464 7.48043 4 7.73478 4 8C4 8.26522 3.89464 8.51957 3.70711 8.70711C3.51957 8.89464 3.26522 9 3 9ZM3 12.9333C2.73478 12.9333 2.48043 12.828 2.29289 12.6404C2.10536 12.4529 2 12.1986 2 11.9333C2 11.6681 2.10536 11.4138 2.29289 11.2262C2.48043 11.0387 2.73478 10.9333 3 10.9333C3.26522 10.9333 3.51957 11.0387 3.70711 11.2262C3.89464 11.4138 4 11.6681 4 11.9333C4 12.1986 3.89464 12.4529 3.70711 12.6404C3.51957 12.828 3.26522 12.9333 3 12.9333ZM5.33333 8C5.33333 7.63181 5.63181 7.33333 6 7.33333H13.3333C13.7015 7.33333 14 7.63181 14 8C14 8.36819 13.7015 8.66667 13.3333 8.66667H6C5.63181 8.66667 5.33333 8.36819 5.33333 8ZM5.33333 12C5.33333 11.6318 5.63181 11.3333 6 11.3333H13.3333C13.7015 11.3333 14 11.6318 14 12C14 12.3682 13.7015 12.6667 13.3333 12.6667H6C5.63181 12.6667 5.33333 12.3682 5.33333 12Z"
      fill={color}
    />
  </svg>
);
