// @ts-nocheck
import React, { ReactNode } from 'react';
import cn from 'class-names';

import './GradientBackgroundLoader.scss';

export const GradientBackground = ({
  width = 300,
  height = 30,
  borderRadius = 0,
  children = null,
  className = '',
}: {
  width?: number;
  height?: number;
  borderRadius?: number;
  children?: ReactNode | JSX.Element | string | null;
  className?: string;
}): JSX.Element => {
  const cnGradientBackground = cn('gradient-background-loader', {
    [className]: className.length !== 0,
  });

  return (
    <div
      className={cnGradientBackground}
      style={{
        width: `${width}px`,
        height: `${height}px`,
        borderRadius: `${borderRadius}px`,
      }}
    >
      {children}
    </div>
  );
};

const GradientBackgroundLoader = ({
  width = 300,
  height = 30,
  borderRadius = 0,
  isLoading,
  children,
  className,
}: {
  width?: number;
  height?: number;
  borderRadius?: number;
  isLoading: boolean;
  children?: ReactNode | JSX.Element | string;
  className?: string;
}): JSX.Element =>
  isLoading ? (
    <GradientBackground
      className={className}
      borderRadius={borderRadius}
      width={width}
      height={height}
    />
  ) : (
    children
  );

export default GradientBackgroundLoader;
