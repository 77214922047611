import { BillingCycleTypeValue } from '@ts/billing.types';
import { BillingSubscriptionCycle } from 'respona_api/generated/billing_pb';

const init = (gtmId: string): void => {
  const script = document.createElement('script');
  script.async = true;
  script.src = `https://analytics.respona.com/gtm.js?id=${gtmId}`;
  document.head.appendChild(script);

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js',
  });
};

const signUp = (email: string): void =>
  publishEvent({
    event: 'free_signup',
    email,
  });

const signIn = (userId: number): void =>
  publishEvent({
    uid: userId,
  });

const makePayment = (
  email: string,
  dueTodayPrice: number,
  transactionId: string,
  item: string,
  subscriptionTotalPrice: number,
  billingCycle: BillingCycleTypeValue,
  quantity: number = 1
): void => {
  function convertBillingCycle(value: BillingCycleTypeValue): string {
    switch (value) {
      case BillingSubscriptionCycle.NONE:
        return 'One-time';
      case BillingSubscriptionCycle.MONTHLY:
        return 'Monthly';
      case BillingSubscriptionCycle.YEARLY:
        return 'Annually';
      default:
        return 'Unknown';
    }
  }

  publishEvent({
    event: 'purchase',
    email,
    ecommerce: {
      currency: 'USD',
      value: dueTodayPrice,
      transaction_id: transactionId,
      items: [
        {
          item_name: item,
          price: subscriptionTotalPrice,
          item_category: convertBillingCycle(billingCycle),
          quantity,
        },
      ],
    },
  });
};

const publishEvent = (payload: object) => {
  try {
    window.dataLayer = window.dataLayer || [];
    console.log('Publishing new GTM event', payload);
    window.dataLayer.push(payload);
  } catch (e) {
    console.error('Error while pushing GTM event', e);
  }
};

export default {
  init,
  signUp,
  signIn,
  makePayment,
};
