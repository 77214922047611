import React from 'react';

export const InboxUnreadMessage = ({ color = '#BDBDBD' }) => (
  <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.44629 1.40576L8.99991 6.81812L16.5525 1.40649"
      stroke={color}
      strokeWidth="1.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.5455 1H2.45455C1.65122 1 1 1.65122 1 2.45455V12.6364C1 13.4397 1.65122 14.0909 2.45455 14.0909H15.5455C16.3488 14.0909 17 13.4397 17 12.6364V2.45455C17 1.65122 16.3488 1 15.5455 1Z"
      stroke={color}
      strokeWidth="1.8"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);

export default InboxUnreadMessage;
