/* eslint-disable react/no-unused-prop-types */
import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';

import { CampaignType } from '@ts/campaigns.types';

import {
  campaignStatusColor,
  campaignStatusIconColor,
  campaignStatusTextColor,
} from '@constants/campaigns.constants';

import { Tr } from '@uikit/Table/Table';
import ColoredStatusToggler from '@uikit/ColoredStatusToggler/ColoredStatusToggler';

import ActionsMenu from '@components/ActionsMenu/ActionsMenu';

import './CampaignsTable.scss';
import { SVGIcon } from '@uikit/Icon/Icon';
import Checkbox from '@uikit/Checkbox/Checkbox';
import PieChart from '@uikit/PieChart/PieChart';
import { CampaignStatus } from 'respona_api/generated/campaign_pb';

type TableRenderProps = {
  row: CampaignType;
  children?: any;
  renderProps?: {
    [key: string]: any;
  };
};

const statusToStringMapper = {
  0: 'Draft',
  1: 'In progress',
  2: 'Paused',
  3: 'Removed',
  4: 'Completed',
  5: 'Stopped',
};

const statusFilterMap = {
  1: 'Draft',
  2: 'In progress',
  3: 'Paused',
  4: 'Completed',
};

const statusFilterColorMap = {
  1: 'purple',
  2: 'green',
  3: 'orange',
  4: 'grey',
};

const calculateLaunched = (row: CampaignType): string =>
  `${row.launchedNumber} (${calculatePercent(row.launchedNumber, row.opportunitiesNumber)}%)`;
const calculateScheduled = (row: CampaignType): string =>
  `${row.scheduledNumber} (${calculatePercent(row.scheduledNumber, row.launchedNumber)}%)`;
const calculateContacted = (row: CampaignType): string =>
  `${row.contactedNumber} (${calculatePercent(row.contactedNumber, row.launchedNumber)}%)`;
const calculateOpened = (row: CampaignType): string =>
  `${row.openedNumber} (${calculatePercent(row.openedNumber, row.contactedNumber)}%)`;
const calculateReplied = (row: CampaignType): string =>
  `${row.repliedNumber} (${calculatePercent(row.repliedNumber, row.contactedNumber)}%)`;

const calculatePercent = (val: number, total: number): number => {
  if (val === 0 || total === 0) {
    return 0;
  }
  return Math.round((val / total) * 100);
};

function TimestampToDate({ timestamp }) {
  const date = new Date(timestamp);

  const formattedDate = date.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });

  const formattedTime = date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });

  return (
    <>
      {formattedDate} at {formattedTime}
    </>
  );
}

export default [
  {
    title: 'Title',
    dataKey: 'title',
    RenderTitle: ({
      renderProps: { isSelectedAll, handlerSelectAll },
    }: TableRenderProps): JSX.Element => {
      return (
        <div className="campaigns-table-header">
          <Checkbox onChange={handlerSelectAll} value={isSelectedAll} />
          Campaign name
        </div>
      );
    },
    RenderCell: ({
      row,
      renderProps: { campaignFolders, handlerSelectOne, checkboxes },
    }: TableRenderProps): JSX.Element => {
      const folderTitle = campaignFolders.find(({ id }) => id === row.folderId)?.title;
      return (
        <div className="title-cell">
          <Checkbox
            onChange={() => handlerSelectOne(row)}
            value={checkboxes.find(({ id }) => id === row.id)}
          />
          <div className="title-cell__body">
            <div className="title-cell__title" title={row.title}>
              {row.title}
            </div>
            {folderTitle && <div className="title-cell__folder">{folderTitle}</div>}
          </div>
        </div>
      );
    },
  },
  {
    title: 'Status',
    dataKey: 'statusToggler',
    RenderCell: ({ row, renderProps: { onUpdateStatus } }: TableRenderProps): JSX.Element => {
      const [isStatusChanging, changeIsStatusChanging] = useState(false);

      const handleUpdateStatus = (status: 1 | 2) => {
        changeIsStatusChanging(true);
        onUpdateStatus(row.id, status).finally(() => changeIsStatusChanging(false));
      };

      const handleStatusToggle = (isActive: boolean) => {
        if (isActive && (row.status === 2 || row.status === 5 || row.status === 0)) {
          handleUpdateStatus(1);
        } else if (!isActive && row.status === 1) {
          handleUpdateStatus(2);
        }
      };

      return (
        <div>
          <ColoredStatusToggler
            id={row.id}
            color={campaignStatusColor[row.status]}
            isActive={row.status === 1 || row.status === 4 || row.status === 3}
            onChange={handleStatusToggle}
            isLoading={isStatusChanging}
            title={statusToStringMapper[row.status]}
            status={row.status}
            titleColor={campaignStatusTextColor[row.status]}
            iconColor={campaignStatusIconColor[row.status]}
            iconSize={row.status === 3 ? 10 : null}
          />
        </div>
      );
    },
  },
  // {
  //   title: 'Status',
  //   dataKey: 'status',
  //   RenderCell: ({ row }: TableRenderProps): JSX.Element => (
  //     <div className="status-cell">
  //       <div className="status-cell__status">{statusToStringMapper[row.status]}</div>
  //       <ColoredProgress color={campaignStatusColor[row.status]} progress={row.progress} />
  //     </div>
  //   ),
  // },
  {
    dataKey: 'total',
    colspan: 2,
    RenderTitle: (): JSX.Element => {
      return (
        <div>
          <span data-for="opportunity-column-title" data-tip="">
            Opportunities <SVGIcon icon="questionInCircleFilled" size={12} />
            <ReactTooltip
              id="opportunity-column-title"
              place="right"
              effect="solid"
              arrowColor="transparent"
            >
              <div>
                An opportunity is a potential outreach target,
                <br /> such as a website, which may include several contacts.
              </div>
            </ReactTooltip>
          </span>
        </div>
      );
    },
    RenderCell: ({ row }: TableRenderProps): JSX.Element => (
      <div className="total-cell">
        {row.opportunitiesNumber}
        <span className="subtitle-cell">Total</span>
      </div>
    ),
  },
  {
    title: '',
    isHidden: true,
    dataKey: 'launched',
    RenderCell: ({ row }: TableRenderProps): JSX.Element => (
      <div className="launched-cell">
        {calculateLaunched(row)}
        <span className="subtitle-cell">Launched</span>
      </div>
    ),
  },
  {
    title: '',
    dataKey: 'scheduled',
    RenderCell: ({ row }: TableRenderProps): JSX.Element => (
      <div className="scheduled-cell">
        {calculateScheduled(row)}
        <span className="subtitle-cell">Scheduled</span>
      </div>
    ),
  },
  {
    title: '',
    dataKey: 'contacted',
    RenderCell: ({ row }: TableRenderProps): JSX.Element => (
      <div className="contacted-cell">
        {calculateContacted(row)}
        <span className="subtitle-cell">Contacted</span>
      </div>
    ),
  },
  {
    title: '',
    dataKey: 'opened',
    RenderCell: ({ row }: TableRenderProps): JSX.Element => (
      <div className="opened-cell">
        <PieChart
          size={24}
          name="title"
          value={calculatePercent(row.openedNumber, row.contactedNumber)}
          color="#23E771"
        />
        <div>
          {calculateOpened(row)}
          <span className="subtitle-cell">Opened</span>
        </div>
      </div>
    ),
  },
  {
    title: '',
    dataKey: 'replied',
    RenderCell: ({ row }: TableRenderProps): JSX.Element => (
      <div className="replied-cell">
        <PieChart
          size={24}
          name="title"
          value={calculatePercent(row.repliedNumber, row.contactedNumber)}
          color="#FC8619"
        />
        <div>
          {calculateReplied(row)}
          <span className="subtitle-cell">Replied</span>
        </div>
      </div>
    ),
  },
  {
    title: 'Created on',
    dataKey: 'created',
    RenderCell: ({ row }: TableRenderProps): JSX.Element => (
      <div className="created_on-cell">
        <TimestampToDate timestamp={row.createdAt} />
        <p>by {row.ownerName}</p>
      </div>
    ),
  },
  {
    title: '',
    dataKey: 'actions',
    RenderCell: ({
      row,
      renderProps: {
        onMoveCampaign,
        onRemove,
        onRestore,
        onChangeOwner,
        onDuplicateCampaign,
        onRename,
        isDeletedType,
      },
    }: TableRenderProps): JSX.Element => {
      const actionsConfig = [
        {
          icon: 'renameAction',
          title: 'Rename',
          handler: () => onRename(row),
        },
        {
          icon: 'user',
          title: 'Change owner',
          iconSize: 17,
          handler: () => onChangeOwner(row),
        },
        {
          icon: 'folder',
          title: 'Move',
          handler: () => onMoveCampaign(row),
        },
        {
          icon: 'duplicate',
          title: 'Duplicate',
          handler: () => onDuplicateCampaign(row),
        },
      ];

      if (row.status !== CampaignStatus.PREPARED) {
        actionsConfig.push({
          icon: 'pieChart',
          title: 'Analytics',
          handler: () => {
            window.open(
              `/insights/campaign-reports?filterCampaignId=${row.id}&filterCampaignTitle=${row.title}`,
              '_blank'
            );
          },
        });
      }

      const deletePageActionsConfig = [
        {
          icon: 'arrowLeftCircle',
          title: 'Restore',
          handler: () => onRestore(row.id, row.workspaceId),
        },
      ];

      return (
        <ActionsMenu
          actionsConfig={isDeletedType ? deletePageActionsConfig : actionsConfig}
          onRemove={() => onRemove(row.id, row.workspaceId, isDeletedType)}
          removeActionTitle={isDeletedType ? 'Destroy' : 'Remove'}
          id={`campaign-actions-${row.id}`}
          tooltipPlace="left"
        />
      );
    },
  },
];

export function RenderRow({
  children,
  row,
  renderProps: { onRowClick },
}: TableRenderProps): JSX.Element {
  const handleClick = () => onRowClick(row.id, row.status);

  return (
    <Tr onClick={handleClick} className="campaigns-table__row">
      {children}
    </Tr>
  );
}
