import {
  OpportunityPersonalizationType,
  PersonalizationStepType,
} from '@ts/personalizations.types';
import stripHtml from '@utils/stripHtml';

/*
  We need to have subject in emails, but when there is no subject -> we should take from previous step
 */
export const createHandleTestPersonalization = (
  personalization: PersonalizationStepType,
  personalizations: PersonalizationStepType[],
  changePersonalizationForTest: (state) => void
) => {
  const isSubject = stripHtml(personalization.subject).trim();
  let { subject } = personalization;

  if (!isSubject) {
    let currentPersonalizationIndex = personalizations.findIndex(
      (step) => step.stepId === personalization.stepId
    );

    while (currentPersonalizationIndex >= 0) {
      if (stripHtml(personalizations[currentPersonalizationIndex].threadA.subject).trim()) {
        subject = personalizations[currentPersonalizationIndex].threadA.subject;
        break;
      }
      currentPersonalizationIndex--;
    }
  }
  changePersonalizationForTest({
    ...personalization,
    subject,
  });
};
