import React, { useState } from 'react';

import PageHeader from '@components/PageHeader/PageHeader';
import CreateWorkspaceHeaderActionsButtonCancel from '@pages/CreateWorkspacePage/_components/HeaderActionButtons/CreateWorkspaceHeaderActionsButtonCancel';
import CreateWorkspaceHeaderActionsButtonSkip from '@pages/CreateWorkspacePage/_components/HeaderActionButtons/CreateWorkspaceHeaderActionsButtonSkip';
import CreateWorkspaceStep from '@pages/CreateWorkspacePage/_components/CreateWorkspaceStep/CreateWorkspaceStep';

import configCreateWorkspace from './stepsConfig';

import './CreateWorkspacePage.scss';

const CreateWorkspacePage = (): JSX.Element => {
  const [step, changeStep] = useState(0);

  function nextStep() {
    changeStep((prev) => {
      if (prev === configCreateWorkspace.length - 1) {
        /*
        If it is the last step we stop go to next
       */
        return prev;
      }
      return prev + 1;
    });
  }

  return (
    <>
      <PageHeader
        title="Create workspace"
        renderHeaderActions={
          step === 0
            ? CreateWorkspaceHeaderActionsButtonCancel
            : CreateWorkspaceHeaderActionsButtonSkip
        }
      />
      <CreateWorkspaceStep info={configCreateWorkspace[step]} nextStep={nextStep} />
    </>
  );
};

export default CreateWorkspacePage;
