import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import './PersonalizeOpportunities.scss';
import Checkbox from '@uikit/Checkbox/Checkbox';
import { SVGIcon } from '@uikit/Icon/Icon';
import { OpportunityShortType } from '@ts/opportunity.types';
import Loader from '@uikit/Loader/Loader';
import { useRefCallback } from '@helpers/refHelpers';
import useIntersectionObserver from '@hooks/useIntersectionObserver';
import { useInfiniteQuery } from 'react-query';
import { getCampaignOpportunitiesShortApi } from '@api/campaign.api';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveCampaignInfo } from '@redux/selectors/campaignSettings.selectors';
import { DispatchType } from 'src/store';
import { setActiveOpportunity } from '@redux/actions/opportunities.actions';
import ContentSearchActivityOpportunitiesSearch from '@components/CampaignSettings/ContentSearchActivityOpportunitiesSearch/ContentSearchActivityOpportunitiesSearch';
import { setRefetchShortOpportunities } from '@redux/actions/pipelines.actions';
import { Button } from '@uikit/Button/Button';
import useOutsideClick from '@hooks/useOutsideClick';
import { removeOpportunitiesBatch } from '@redux/thunks/campaignSettings.thunks';
import cn from 'class-names';
import { OpportunityFilterFieldMap } from 'respona_api/generated/campaign_pb';
import * as common_pb from 'respona_api/generated/common_pb';

function PersonalizeOpportunities({
  activeId,
  changeActiveId,
  contactsExisting,
}: {
  activeId?: number;
  changeActiveId: Dispatch<SetStateAction<number>>;
  contactsExisting: boolean;
}): JSX.Element {
  const [loadingRef, setLoadingRef, ready] = useRefCallback<HTMLDivElement>();
  const [selectedIds, setSelectedIds] = useState([]);
  const [filters, setFilters] = useState([]);
  const dispatch = useDispatch<DispatchType>();
  const [isVisibleRemoveApproval, setVisibleRemoveApproval] = useState<boolean>(false);
  const confirmBtnRef = useRef<HTMLButtonElement>();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isFullLeftSideBar, setFullLeftSideBar] = useState<boolean>(false);

  useOutsideClick(confirmBtnRef, () => setVisibleRemoveApproval(false));

  const { info: campaignInfo } = useSelector(getActiveCampaignInfo);

  const {
    data: opportunitiesData,
    isLoading: isLoadingOpportunities,
    isFetchingNextPage: isFetchingNextPageOpportunities,
    fetchNextPage: fetchNextPageOpportunities,
    hasNextPage: hasNextPageOpportunities,
    refetch: refetchOpportunitiesData,
    isRefetching: isRefetchingOpportunities,
  } = useInfiniteQuery<OpportunityShortType[]>(
    ['opportunities-short', campaignInfo.id, campaignInfo.lastBatch],
    ({ pageParam = 0 }) =>
      getCampaignOpportunitiesShortApi(
        campaignInfo.id,
        campaignInfo.workspaceId,
        // the first load takes two pages for all next ones should be the current + 1
        pageParam === 0 ? 0 : pageParam + 1,
        // for the initial load the limit is 20
        // for all further ones the limit is 10
        pageParam === 0 ? 20 : 10,
        campaignInfo.lastBatch,
        filters
      ),
    {
      getNextPageParam: (lastPage: OpportunityShortType[], allPages) => {
        if (lastPage.length < 10) return false;

        return allPages.length;
      },
      enabled: !!contactsExisting && !!campaignInfo,
      retry: 1,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
    }
  );

  useEffect(() => {
    dispatch(setRefetchShortOpportunities(refetchOpportunitiesData));
  }, []);

  // @ts-ignore
  const flatOpportunitiesData: OpportunityShortType[] = opportunitiesData?.pages?.flat() || [];

  useEffect(() => {
    if (flatOpportunitiesData != null && flatOpportunitiesData.length && activeId == null) {
      dispatch(setActiveOpportunity(flatOpportunitiesData[0]));
      changeActiveId(flatOpportunitiesData[0].id);
    }
  }, [flatOpportunitiesData]);

  useIntersectionObserver(loadingRef, () => fetchNextPageOpportunities(), [ready]);

  const toggleSelectAll = () => {
    if (selectedIds.length === flatOpportunitiesData.length) {
      setSelectedIds([]);
    } else {
      setSelectedIds(flatOpportunitiesData.map(({ id }) => id));
    }
  };

  const toggleCheckbox = (id: number) => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
    } else {
      selectedIds.push(id);
      setSelectedIds([...selectedIds, id]);
    }
  };

  const isSelected = (id: number) => {
    return selectedIds.includes(id);
  };

  const renderLoader = () => {
    if (isFetchingNextPageOpportunities) {
      return <Loader isLoading withTopMargin />;
    }

    if (
      flatOpportunitiesData.length &&
      flatOpportunitiesData[0].totalItems > flatOpportunitiesData.length &&
      hasNextPageOpportunities
    ) {
      return (
        <div ref={setLoadingRef} style={{ marginBottom: '20px' }}>
          <Loader isLoading withTopMargin />
        </div>
      );
    }

    if (isLoadingOpportunities || isRefetchingOpportunities) {
      return (
        <div style={{ marginBottom: '20px' }}>
          <Loader isLoading withTopMargin />
        </div>
      );
    }

    return null;
  };

  function onApplyFilters(
    value: {
      field: OpportunityFilterFieldMap[keyof OpportunityFilterFieldMap];
      join: common_pb.FilterJoinTypeMap[keyof common_pb.FilterJoinTypeMap];
      value: string;
      operation: common_pb.FilterOperationMap[keyof common_pb.FilterOperationMap];
      order: number;
    }[]
  ) {
    if (JSON.stringify(value) === JSON.stringify(filters)) {
      return;
    }

    setFilters(value);
    // dirty solution: we need to wait when the parent's component's state is updated
    setTimeout(() => {
      refetchOpportunitiesData();
    });
  }

  function renderIconByStatus(status: 1 | 0 | 2) {
    if (status === 0) {
      return (
        <div className="info-icons">
          <SVGIcon icon="successCircleFilled" size={19} />
        </div>
      );
    }
    if (status === 1) {
      return <></>;
    }

    return (
      <div className="info-icons">
        <SVGIcon icon="warningTriangle" size={19} />
      </div>
    );
  }

  const handleExternalLink = (event, id: number, url: string) => {
    event.preventDefault();

    if (id === activeId) {
      window.open(url, '_blank');
    }
  };

  async function onDeleteOpportunitiesClick() {
    try {
      setIsDeleting(true);
      await dispatch(
        removeOpportunitiesBatch(
          campaignInfo.id,
          campaignInfo.workspaceId,
          campaignInfo.lastBatch,
          selectedIds,
          filters
        )
      );
      refetchOpportunitiesData().then(() => {
        setVisibleRemoveApproval(false);
        if (selectedIds.includes(activeId)) {
          if (flatOpportunitiesData.length > 0) {
            changeActiveId(flatOpportunitiesData[0].id);
          }
        }
        setSelectedIds([]);
      });
    } finally {
      setIsDeleting(false);
    }
  }

  const areFiltersEnabled = Object.values(filters).some(({ value }) => value.toString().length > 0);

  function composeSelectAllText() {
    if (flatOpportunitiesData.length > 0) {
      const firstElement = flatOpportunitiesData[0];
      if (firstElement.totalItems > 0) {
        return `Select All (${firstElement.totalItems})`;
      }
    }
    return 'Select All';
  }

  const personalizeOpportunitiesClasses = cn('personalize-opportunities', {
    full: isFullLeftSideBar,
  });
  const extendBtnClasses = cn('personalize-opportunities__extend', {
    rotate: isFullLeftSideBar,
  });

  return (
    <div className={personalizeOpportunitiesClasses}>
      <div className="personalize-opportunities__header">
        {/* <SVGIcon */}
        {/*  className={extendBtnClasses} */}
        {/*  icon="arrowRightClassic" */}
        {/*  size={10} */}
        {/*  color="#000" */}
        {/*  onClick={() => setFullLeftSideBar(!isFullLeftSideBar)} */}
        {/* /> */}
        <div className="personalize-opportunities__header-check">
          <Checkbox
            onChange={toggleSelectAll}
            value={
              flatOpportunitiesData.length > 0 &&
              flatOpportunitiesData.length === selectedIds.length
            }
          />
          <p>{composeSelectAllText()}</p>
        </div>

        <div className="personalize-opportunities__header-search">
          {selectedIds.length > 0 && (
            <>
              {isVisibleRemoveApproval ? (
                <span ref={confirmBtnRef}>
                  <Button
                    className="mr-10"
                    isLoading={isDeleting}
                    leftIcon="trash"
                    type="alert"
                    onClick={() => onDeleteOpportunitiesClick()}
                  >
                    Confirm
                  </Button>
                </span>
              ) : (
                <Button
                  className="personalize-opportunities__trash-button"
                  leftIcon="trash"
                  type="alert"
                  onClick={() => setVisibleRemoveApproval(true)}
                />
              )}
            </>
          )}
          <ContentSearchActivityOpportunitiesSearch
            onApplyFilters={(value) => onApplyFilters(value)}
            areFiltersEnabled={areFiltersEnabled}
            isFilterBtnText={false}
          />
        </div>
      </div>

      <div className="personalize-opportunities__content">
        <ul className="personalize-opportunities__content-list">
          {flatOpportunitiesData.map((item) => {
            return (
              <li
                key={item.id}
                className={`personalize-opportunities__content-list__item ${
                  item.id === activeId ? 'active' : ''
                }`}
                onClick={() => {
                  dispatch(setActiveOpportunity(item));
                  changeActiveId(item.id);
                }}
              >
                <div className="personalize-opportunities__content-list__item-content">
                  <div className="item-content">
                    <div className="content-checkbox">
                      <Checkbox
                        onChange={() => toggleCheckbox(item.id)}
                        value={isSelected(item.id)}
                      />
                      <p>{item.title}</p>
                    </div>
                    <Link to="" onClick={(event) => handleExternalLink(event, item.id, item.url)}>
                      <SVGIcon icon="link" size={10} color="#221CB6" />
                      <p>{item.url}</p>
                    </Link>
                    {item.edited && <span className="btn-edited">Edited</span>}
                  </div>
                  {renderIconByStatus(item.status)}
                </div>
              </li>
            );
          })}
        </ul>
        {renderLoader()}
      </div>
    </div>
  );
}
export default PersonalizeOpportunities;
