import React from 'react';

export default ({ color = '#000' }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.10762 2.71723C0.847266 2.45688 0.847266 2.03477 1.10762 1.77442C1.36797 1.51407 1.79008 1.51407 2.05042 1.77442L14.1494 13.8734C14.4098 14.1338 14.4098 14.5559 14.1494 14.8162C13.8891 15.0766 13.467 15.0766 13.2066 14.8162L1.10762 2.71723Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9999 2.66683H13.9999C14.1767 2.66683 14.3463 2.73707 14.4713 2.86209C14.5964 2.98712 14.6666 3.15669 14.6666 3.3335V5.3335C14.6666 6.04074 14.3856 6.71902 13.8855 7.21911C13.3854 7.71921 12.7072 8.00016 11.9999 8.00016H10.9733C10.8423 8.1463 10.7014 8.28187 10.5519 8.40613L9.60718 7.46138C9.7049 7.38757 9.79796 7.3067 9.88555 7.21911C10.3856 6.71902 10.6666 6.04074 10.6666 5.3335V2.66683H5.33326V3.18746L4.01326 1.86747C4.03914 1.7401 4.10192 1.62203 4.19519 1.52876C4.32021 1.40373 4.48978 1.3335 4.66659 1.3335H11.3333C11.5101 1.3335 11.6796 1.40373 11.8047 1.52876C11.9297 1.65378 11.9999 1.82335 11.9999 2.00016V2.66683ZM12.9427 6.27631C13.1928 6.02626 13.3333 5.68712 13.3333 5.3335V4.00016H11.9999V5.3335C11.9985 5.78797 11.9196 6.23887 11.7666 6.66683H11.9999C12.3535 6.66683 12.6927 6.52635 12.9427 6.27631Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.92701 2.66683H1.99993C1.82312 2.66683 1.65355 2.73707 1.52852 2.86209C1.4035 2.98712 1.33326 3.15669 1.33326 3.3335V5.3335C1.33326 6.04074 1.61421 6.71902 2.11431 7.21911C2.61441 7.71921 3.29268 8.00016 3.99993 8.00016H5.02659C5.62962 8.67293 6.44264 9.12173 7.33326 9.2735V10.6668H6.66659C6.13616 10.6668 5.62745 10.8775 5.25238 11.2526C4.87731 11.6277 4.66659 12.1364 4.66659 12.6668V14.0002C4.66659 14.177 4.73683 14.3465 4.86186 14.4716C4.98688 14.5966 5.15645 14.6668 5.33326 14.6668H10.6666C10.8434 14.6668 11.013 14.5966 11.138 14.4716C11.263 14.3465 11.3333 14.177 11.3333 14.0002V12.6668C11.3333 12.1364 11.1225 11.6277 10.7475 11.2526C10.3724 10.8775 9.86369 10.6668 9.33326 10.6668H8.66659V9.2735C8.90157 9.23346 9.13114 9.17274 9.35287 9.09269L8.24872 7.98854C8.16636 7.99625 8.08334 8.00016 7.99993 8.00016C7.29268 8.00016 6.61441 7.71921 6.11431 7.21911C5.61421 6.71902 5.33326 6.04074 5.33326 5.3335V5.07308L2.92701 2.66683ZM3.05712 6.27631C3.30717 6.52635 3.64631 6.66683 3.99993 6.66683H4.23326C4.0803 6.23887 4.00139 5.78797 3.99993 5.3335V4.00016H2.66659V5.3335C2.66659 5.68712 2.80707 6.02626 3.05712 6.27631ZM9.80467 12.1954C9.67964 12.0704 9.51007 12.0002 9.33326 12.0002H6.66659C6.48978 12.0002 6.32021 12.0704 6.19519 12.1954C6.07017 12.3204 5.99993 12.49 5.99993 12.6668V13.3335H9.99993V12.6668C9.99993 12.49 9.92969 12.3204 9.80467 12.1954Z"
      fill={color}
    />
  </svg>
);
