import React from 'react';

const KeywordsSearcgSvg = ({ size = 32 }: { size?: number }): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 27 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.74186 6.93211H18.5385V11.3741L12.5978 11.8311C3.00521 12.5475 0 15.0919 0 22.2099V23.6466C0 28.867 3.65582 32 9.27121 32C13.7093 32 16.242 30.9544 19.3905 27.74H19.8978V31.1364H26.2297V0H2.74186V6.93211ZM18.5385 21.9465C16.6022 23.9061 13.6435 25.2112 11.2269 25.2112C8.61673 25.2112 7.50914 24.2314 7.5711 22.0782C7.63694 19.073 8.73291 18.3565 13.7674 17.8995L18.5308 17.4426L18.5385 21.9465Z"
        fill="#FF8800"
      />
    </svg>
  );
};

export default KeywordsSearcgSvg;
