import Radio from '@uikit/Radio/Radio';
import React, { ReactNode } from 'react';

import cn from 'class-names';

import Display from '@components/Display/Display';

import './UpsellOption.scss';

const UpsellOption = ({
  isChosen,
  onChange,
  headerText,
  pricePerCredit,
  children,
}: {
  isChosen: boolean;
  onChange: () => void;
  headerText: string;
  pricePerCredit: number;
  children?: ReactNode;
}) => {
  const cnUpsellOption = cn('upsell-option', {
    'upsell-option--chosen': isChosen,
  });
  return (
    <div className={cnUpsellOption} onClick={onChange} tabIndex={0} role="button">
      <Radio
        className="upsell-option__radio"
        onChange={onChange}
        isChecked={isChosen}
        value={headerText}
        size={22}
      />

      <div className="upsell-option__description">
        <p className="upsell-option__header">
          {headerText}{' '}
          <span className="upsell-option__price-per-credit">(${pricePerCredit} per credit)</span>
        </p>
        <Display isVisible={isChosen}>{children}</Display>
      </div>
    </div>
  );
};

export default UpsellOption;
