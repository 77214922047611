import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useQueryClient } from 'react-query';
import { nanoid } from 'nanoid';

import urls from '@constants/urls';
import stripHtml from '@utils/stripHtml';

import { addNotification } from '@redux/actions/notifications.actions';

import { TemplateStepType, TemplateStepTypeWithTempId } from '@ts/template.types';
import {
  removeTemplateByQueryClient,
  saveTemplateWithStepsByQueryClient,
  useQueryTemplateSteps,
} from '@queries/templates.queries';

import Loader from '@uikit/Loader/Loader';
import Button from '@uikit/Button/Button';
import Input from '@uikit/Input/Input';

import SequenceEditorWithoutAutosave from '@components/CampaignSettings/SequenceEditor/SequenceEditorWithoutAutosave';
import PageHeader from '@components/PageHeader/PageHeader';
import { getDateShort } from '@utils/date';

import { DEFAULT_TEMPLATE_TITLE } from '@constants/templates.constants';

import useNonInitialEffect from '@hooks/useNonInitialEffect';

import useBlockHistoryChanging from '@hooks/useBlockHistoryChanging';
import SequenceStepsWithoutAutosave from '@components/CampaignSettings/SequenceSteps/SequenceStepsWithoutAutosave';
import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';
import ConfirmModal from '@uikit/ConfirmModal/ConfirmModal';
import {
  formatSameThread,
  isTemplateTheSame,
  TEMPLATE_STEP,
} from '@pages/TemplateEditorSettingPage/helpers';

import SaveButton from './_components/SaveButton';

import './TemplateEditorSettingPage.scss';

const TemplateEditorSettingPage = (): JSX.Element => {
  const dispatch = useDispatch();
  const params = useParams();
  const queryClient = useQueryClient();
  const { push } = useHistory();
  const inputRef = useRef<HTMLInputElement>();

  const [isDeleteBtnLoading, changeIsDeleteBtnLoading] = useState<boolean>(false);
  const [openedStepIndex, changeOpenedStepIndex] = useState<number>(0);

  const [isConfirmGoOutModalOpen, changeIsConfirmGoOutModalOpen] = useState<boolean>(false);
  const [isSaveButtonDisabled, changeSaveButtonDisabled] = useState<boolean>(false);

  const [templateTitle, changeTemplateTitle] = useState<string>('');
  const [stepsNewState, changeStepsNewState] = useState<TemplateStepTypeWithTempId[]>([]);

  const currentWorkspaceId = useCurrentWorkspaceId();

  const redirectToTemplates = () => push(urls.TEMPLATES);

  const templateId = Number(params.id);

  if (Number.isNaN(templateId)) {
    redirectToTemplates();
    return null;
  }

  const handleOpenConfirmModal = useCallback(() => changeIsConfirmGoOutModalOpen(true), []);

  const { data: templateContainer, isFetching } = useQueryTemplateSteps(templateId);

  const [onGoOutLikeWeWant, forceRedirect] = useBlockHistoryChanging(
    handleOpenConfirmModal,
    !isTemplateTheSame(templateContainer, { title: templateTitle, stepsList: stepsNewState })
  );

  const handleCloseConfirmModal = useCallback(() => {
    changeIsConfirmGoOutModalOpen(false);
    onGoOutLikeWeWant();
  }, [onGoOutLikeWeWant]);

  useEffect(() => {
    if (templateContainer && templateContainer.title) {
      changeTemplateTitle(templateContainer.title);
    }

    if (templateContainer && templateContainer.stepsList) {
      changeStepsNewState(
        templateContainer.stepsList.map((step) => ({ ...step, tempId: nanoid() }))
      );
    }
  }, [templateContainer]);

  useNonInitialEffect(() => {
    if (templateTitle.includes(DEFAULT_TEMPLATE_TITLE)) {
      inputRef.current.focus();
    }
  }, [templateTitle]);

  const handleDeleteTemplate = () => {
    changeIsDeleteBtnLoading(true);
    removeTemplateByQueryClient(queryClient, templateId)
      .then(() => {
        redirectToTemplates();
      })
      .finally(() => changeIsDeleteBtnLoading(false));
  };

  const handleCreateStep = () => {
    const stepsList = Object.values(stepsNewState);

    const lastStep = stepsList[stepsList.length - 1];

    if (lastStep) {
      if (stepsList.length === 1) {
        if (
          stripHtml(lastStep.threadA.subject).trim() === '' &&
          stripHtml(lastStep.threadA.content).trim() === ''
        ) {
          dispatch(
            addNotification({ title: 'Please, fill step subject or content', type: 'error' })
          );
          return;
        }
      }
    }

    const creatingStep: TemplateStepTypeWithTempId = {
      ...TEMPLATE_STEP,
      templateId,
      order: stepsList.length + 1,
      tempId: nanoid(),
    };

    changeStepsNewState((prevState) => {
      return [...prevState, creatingStep];
    });

    changeOpenedStepIndex(stepsList.length);
  };

  const handleRemove = () => {
    if (openedStepIndex !== 0) {
      changeOpenedStepIndex(openedStepIndex - 1);
    }

    changeStepsNewState((prevState) => {
      // TODO: refactor this shit, we did it
      const realIds: number[] = prevState.reduce((acc, { id }) => {
        if (id) {
          acc.push(id);
        }
        return acc;
      }, [] as number[]);

      return (
        prevState
          .filter((step, index) => index !== openedStepIndex)
          // Order begans from 1
          .map((step, index) => ({
            ...step,
            order: index + 1,
            id: realIds[index] ? realIds[index] : step.id,
          }))
      );
    });
  };

  const handleUpdateTemplateStep = (step: TemplateStepTypeWithTempId) => {
    changeStepsNewState((prevState) =>
      prevState.map((oldStep) =>
        oldStep.tempId === step.tempId
          ? { ...oldStep, ...step, attachmentsList: step?.threadA?.attachmentsList || [] }
          : oldStep
      )
    );
  };

  const handleSaveChangesToServer = (silent = false) => {
    changeSaveButtonDisabled(true);

    // scope === 1, because we save it for organization
    return saveTemplateWithStepsByQueryClient(
      queryClient,
      templateId,
      currentWorkspaceId,
      templateTitle,
      1,
      formatSameThread(stepsNewState) as TemplateStepType[]
    )
      .then((res) => {
        changeSaveButtonDisabled(false);
        if (!silent) {
          dispatch(addNotification({ title: 'Template saved', type: 'success' }));
          forceRedirect(urls.TEMPLATES);
        }

        return res;
      })
      .catch(() => {
        changeSaveButtonDisabled(false);
        return null;
      });
  };

  const handleCancelChanges = useCallback(() => {
    const wasChanged = !isTemplateTheSame(templateContainer, {
      title: templateTitle,
      stepsList: stepsNewState,
    });

    if (wasChanged) {
      redirectToTemplates();
    } else {
      forceRedirect(urls.TEMPLATES);
    }
  }, [templateContainer, templateTitle, stepsNewState]);

  return (
    <>
      <div className="template-editor-page">
        <div className="template-editor-page__header">
          <PageHeader title="Templates" withBackArrow backArrowForceLink={urls.TEMPLATES}>
            <div className="template-editor-page__header-btns">
              <Button
                type="alert"
                onClick={handleDeleteTemplate}
                withConfirm
                isLoading={isDeleteBtnLoading}
                disabled={isDeleteBtnLoading}
              >
                Delete
              </Button>
              <Button type="secondary" onClick={handleCancelChanges}>
                Cancel
              </Button>
              <SaveButton
                stepsNewState={stepsNewState}
                isTemplateTitle={!!templateTitle}
                isSaveButtonDisabled={isSaveButtonDisabled}
                onSaveChangesToServer={() => handleSaveChangesToServer()}
              />
            </div>
          </PageHeader>
        </div>
        <div className="template-editor-page__body">
          <p className="template-editor-page__date-created">
            {templateContainer?.createdAt
              ? <div className="template-editor-page__date-created-text">Template created on {getDateShort(new Date(templateContainer?.createdAt))} by <div
                className="template-editor-page__date-created-autor">{templateContainer.ownerName}</div></div>
              : null}
          </p>
          <div className="template-editor-page__sequence-editor">
            <div className="template-editor-page__steps">
              <div className="template-editor-page__title-input-wrapper">
                <Input
                  inputRef={inputRef}
                  value={templateTitle}
                  onChange={({ target: { value } }) => changeTemplateTitle(value)}
                  icon="documentIcon"
                  placeholder="Enter Template Name"
                />
              </div>
              {isFetching ? (
                <Loader isLoading withTopMargin />
              ) : (
                <SequenceStepsWithoutAutosave
                  openedStepIndex={openedStepIndex}
                  sequenceSteps={stepsNewState.sort((a, b) => a.order - b.order)}
                  onOpenStep={changeOpenedStepIndex}
                  onChangeDelay={handleUpdateTemplateStep}
                  onCreateStep={handleCreateStep}
                />
              )}
            </div>
            <div className="template-editor-page__editor">
              {/* eslint-disable-next-line no-nested-ternary */}
              {isFetching ? (
                <Loader isLoading withTopMargin />
              ) : stepsNewState.length ? (
                <SequenceEditorWithoutAutosave
                  key={stepsNewState[openedStepIndex].id || stepsNewState[openedStepIndex].tempId}
                  onRemove={handleRemove}
                  sequence={stepsNewState[openedStepIndex]}
                  stepIndex={openedStepIndex}
                  onSaveStep={handleUpdateTemplateStep}
                  templateId={templateId}
                  onUpdate={handleSaveChangesToServer}
                  isExternalAutosave
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <ConfirmModal
        onClose={handleCloseConfirmModal}
        isOpen={isConfirmGoOutModalOpen}
        title="Unsaved changes"
        text="Would you like to save changes before moving forward?"
        confirmButtonText="Save changes"
        isConfirmButtonMain
        cancelButtonText="Ignore changes"
        onConfirm={async () => {
          await handleSaveChangesToServer();
          onGoOutLikeWeWant();
        }}
      />
    </>
  );
};

export default TemplateEditorSettingPage;
