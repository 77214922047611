import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';

import { UserProfileType } from '@ts/user.types';

import { OrganizationChecklistTask } from 'respona_api/generated/user-organization_pb';

import { completeChecklistItemApi } from '@api/userOrganization.api';
import { skipOnboardingSessionApi } from '@api/user.api';

import { updateAuthStepRequest } from '@redux/thunks/userProfileRequests';

import Button from '@uikit/Button/Button';

import OnboardingPageHeader from '@components/Onboarding/OnboardingPageHeader/OnboardingPageHeader';

import './OnboardingWorkspace.scss';
import useOrganizationInvitations from '@hooks/useOrganizationInvitations';

function OnboardingOnboarding({ userProfile }: { userProfile: UserProfileType }): JSX.Element {
  const [isEventScheduled, setIsEventScheduled] = useState(false);
  const dispatch = useDispatch();

  useCalendlyEventListener({
    onEventScheduled: async () => {
      await completeChecklistItemApi(OrganizationChecklistTask.SCHEDULE_ONBOARDING_SESSION, true);
      setIsEventScheduled(true);
    },
  });

  const { items: data } = useOrganizationInvitations(true);

  const { refetch: skipOnboarding, isLoading } = useQuery({
    queryFn: () => skipOnboardingSessionApi(),
    enabled: false,
    refetchOnWindowFocus: false,
    retry: false,
  });

  const handleSkip = async (isScheduled: boolean) => {
    if (!isScheduled) {
      await skipOnboarding();
    }

    dispatch(updateAuthStepRequest(4));
  };

  return (
    <div className="onboarding-onboarding">
      <OnboardingPageHeader
        icon="calendar"
        title="Onboarding"
        subtitle="We highly recommend booking a free onboarding session to help you maximize your results."
      />

      <InlineWidget
        url="https://calendly.com/respona/onboarding"
        iframeTitle="onboarding-scheduling"
        pageSettings={{
          hideGdprBanner: true,
          textColor: '111a45',
          primaryColor: '251fb6',
        }}
        styles={{
          width: '100%',
          minWidth: '320px',
          maxWidth: '1000px',
          height: '646px',
        }}
        prefill={{
          name: `${userProfile.firstName} ${userProfile.lastName}`,
          email: userProfile.email,
          guests: data?.map((item) => item.email) || [],
        }}
      />

      <div className="onboarding-onboarding__continue-btn-container">
        <Button
          isLoading={isLoading}
          loadingText="Skipping..."
          className="onboarding-onboarding__continue-btn"
          type={isEventScheduled ? 'primary' : 'bordered-grey'}
          onClick={() => handleSkip(isEventScheduled)}
        >
          {isEventScheduled ? 'Continue' : 'Skip'}
        </Button>
      </div>
    </div>
  );
}

export default OnboardingOnboarding;
