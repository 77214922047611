import React from 'react';
import { MEDIUM_GREY_COLOR_FOR_ICONS } from '@constants/colors';

const LogoutIcon = ({ color = MEDIUM_GREY_COLOR_FOR_ICONS, size = 16 }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.89921 7.60156L13 7.60156"
      stroke={color}
      strokeWidth="1.4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.99971 4.60098L13 7.60127L9.99971 10.6016"
      stroke={color}
      strokeWidth="1.4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.20068 14.2017L2.99998 14.2017C1.89541 14.2017 0.999979 13.3062 0.999979 12.2017L0.999979 3.00037C0.999978 1.8958 1.89541 1.00038 2.99998 1.00038L8.20068 1.00037"
      stroke={color}
      strokeWidth="1.4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LogoutIcon;
