import React, { useState } from 'react';

import BaseModal from '@uikit/BaseModal/BaseModal';
import Button from '@uikit/Button/Button';
import Input from '@uikit/Input/Input';

import { CampaignType } from '@ts/campaigns.types';

import useHotkeys from '@hooks/useHotkeys';

import './RenameModal.scss';

const RenameModal = ({
  isOpen,
  onClose,
  onRename,
  campaign,
}: {
  isOpen: boolean;
  onClose: () => void;
  onRename: (campaign: CampaignType, newTitle: string) => void;
  campaign: CampaignType;
}): JSX.Element => {
  if (!isOpen) {
    return null;
  }

  const [newTitle, changeNewTitle] = useState(campaign.title);

  const handleAction = () => onRename(campaign, newTitle);

  useHotkeys({ Escape: () => onClose(), Enter: () => handleAction() });

  return (
    <BaseModal onClose={onClose} isOpen={isOpen} classNames={{ modal: 'campaign-rename-modal' }}>
      <div className="rename-modal">
        <div className="rename-modal__header">Rename campaign</div>
        <div className="rename-modal__body">
          <Input
            type="text"
            value={newTitle}
            onChange={({ target: { value } }) => changeNewTitle(value)}
          />
        </div>
        <div className="rename-modal__footer">
          <Button onClick={handleAction}>Rename</Button>
        </div>
      </div>
    </BaseModal>
  );
};

export default RenameModal;
