import React from 'react';

export const ExternalLinkIcon = ({
  color = '#BDBDBD',
  size = 13,
}: {
  color?: string;
  size?: number;
}): JSX.Element => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6667 8.77794V13.4446C12.6667 13.8572 12.5028 14.2528 12.2111 14.5446C11.9193 14.8363 11.5237 15.0002 11.1111 15.0002H2.55556C2.143 15.0002 1.74733 14.8363 1.45561 14.5446C1.16389 14.2528 1 13.8572 1 13.4446V4.88905C1 4.47649 1.16389 4.08083 1.45561 3.78911C1.74733 3.49738 2.143 3.3335 2.55556 3.3335H7.22222"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.334 1H15.0007V5.66667"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.44531 9.55556L15.0009 1"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ExternalLinkIcon;
