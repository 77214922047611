// @ts-nocheck
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { components } from 'react-select';
import { Link } from 'react-router-dom';

import Select from 'src/uikit/Select/Select';

import loadingStatuses from '@constants/loadingStatuses';

import { schedulesSelector } from '@redux/selectors/schedules.selectors';
import { fetchAllSchedules } from '@redux/thunks/schedulesRequests';

import SchedulesDaysList from '@components/Settings/SchedulesDaysList/SchedulesDaysList';

const Menu = (props) => {
  const linkStyle = {
    color: '#7974F7',
    fontSize: '14px',
    padding: '10px 0 10px 10px',
    display: 'inline-flex',
    textDecoration: 'none',
  };

  return (
    <>
      <components.Menu {...props}>
        <div>
          <div>{props.children}</div>
          <div style={{ textAlign: 'center' }}>
            <Link style={linkStyle} to="/settings/schedules?createNew=true">
              Add new schedule
            </Link>
          </div>
        </div>
      </components.Menu>
    </>
  );
};

const getSchedulesSelectOptions = (schedules) =>
  schedules.map(({ id, title }) => ({
    label: title,
    value: id,
  }));

export default {
  type: 'select',
  label: 'Campaign Schedule',
  key: 'campaignSchedule',
  Renderer: (props: { [key: string]: any }): JSX.Element => {
    const dispatch = useDispatch();
    const { items, loadingsStatus: schedulesLs } = useSelector(schedulesSelector);

    useEffect(() => {
      if (items.length > 0 && props.value === null) {
        props.onChange({
          label: items[0].title,
          value: items[0].id,
        });
      }
    }, [items.length]);

    useEffect(() => {
      if (!schedulesLs || schedulesLs === loadingStatuses.PENDING) {
        fetchAllSchedules(dispatch);
      }
    }, [schedulesLs]);

    return (
      <Select
        {...props}
        options={getSchedulesSelectOptions(items)}
        additionalComponents={{ Menu }}
        key={schedulesLs}
      />
    );
  },
};

export const campaignSchedulePreview = {
  key: 'campaignSchedulePreview',
  previewKey: 'campaignSchedule',
  label: '',
  Renderer: (props: { [key: string]: any }): JSX.Element => {
    const { items } = useSelector(schedulesSelector);

    const selectedSchedule = items?.find((item) => item.id === props?.value?.value);

    return !!selectedSchedule ? (
      <SchedulesDaysList
        timezone={selectedSchedule.timezoneValue}
        style={{ height: '36px' }}
        dayList={selectedSchedule?.dayList}
      />
    ) : null;
  },
};
