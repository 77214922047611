import streamRequest from '@api/base/streamRequest';
import userOrganizationService from '@api/services/userOrganizationService';
import CommonModel from '../../respona_api/generated/common_pb';
import UserOrganizationModels from '../../respona_api/generated/user-organization_pb';

import { ScheduleType } from '@ts/schedules.types';
import request from '@api/base/request';

export const getAllSchedulesApi = (): Promise<ScheduleType[]> => {
  const service = userOrganizationService.getInstance();
  const schedulesRequest = new CommonModel.PageRequest();

  schedulesRequest.setPage(0);
  schedulesRequest.setLimit(20);

  return streamRequest<ScheduleType[]>(service, service.getAllSchedules, schedulesRequest);
};

const setParamsToScheduleModel = (updateScheduleRequest, schedule) => {
  updateScheduleRequest.setTitle(schedule.title);
  // @ts-ignore
  updateScheduleRequest.setType(schedule.type);
  updateScheduleRequest.setTimezoneId(schedule.timezoneId);
  updateScheduleRequest.setTimezoneValue(schedule.timezoneValue);
  updateScheduleRequest.setTitle(schedule.title);
  updateScheduleRequest.setDeleted(schedule.deleted);

  updateScheduleRequest.setDayList(
    schedule.dayList.map((day) => {
      const dayScheduleRequest = new UserOrganizationModels.DailySchedule();

      dayScheduleRequest.setDay(day.day);
      dayScheduleRequest.setIncludeDay(day.includeDay);
      dayScheduleRequest.setTimeFrom(day.timeFrom);
      dayScheduleRequest.setTimeTo(day.timeTo);

      return dayScheduleRequest;
    })
  );

  return updateScheduleRequest;
};

export const createScheduleApi = (schedule: ScheduleType): Promise<ScheduleType> => {
  const client = userOrganizationService.getInstance();
  let updateScheduleRequest = new UserOrganizationModels.Schedule();

  updateScheduleRequest = setParamsToScheduleModel(updateScheduleRequest, schedule);

  return request(client, client.createSchedule, updateScheduleRequest);
};

export const updateScheduleApi = (schedule: ScheduleType): Promise<ScheduleType> => {
  const client = userOrganizationService.getInstance();
  let updateScheduleRequest = new UserOrganizationModels.Schedule();

  updateScheduleRequest.setId(schedule.id);
  updateScheduleRequest = setParamsToScheduleModel(updateScheduleRequest, schedule);
  return request(client, client.updateSchedule, updateScheduleRequest);
};

export const removeScheduleApi = (scheduleId) => {
  const client = userOrganizationService.getInstance();
  const removeScheduleRequest = new CommonModel.IdRequest();

  removeScheduleRequest.setId(scheduleId);

  return request(client, client.deleteSchedule, removeScheduleRequest);
};
