import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { BillingAddressType, BillingType } from '@ts/billing.types';

import { checkIsAnyPending } from '@utils/loadingChecks';

import { billingAddressSelector } from '@redux/selectors/billings.selectors';
import {
  fetchBillingAddress,
  fetchBillingDetails,
  updateBillingAddress,
} from '@redux/thunks/billings.thunks';

import { SVGIcon } from '@uikit/Icon/Icon';
import Button from '@uikit/Button/Button';
import Loader from '@uikit/Loader/Loader';

import PageHeader from '@components/PageHeader/PageHeader';
import BillingFormField from '@components/Settings/ChangeBillingForm/_components/BillingFormField/BillingFormField';
import CardWidget from '@components/Settings/ChangeBillingForm/_components/CardWidget/CardWidget';

import './ChangeBillingForm.scss';

const ChangeBillingForm = ({ billingDetails }: { billingDetails: BillingType }): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { data: addressInfo, loadingStatus: addressLs } = useSelector(billingAddressSelector);

  const [fields, changeFields] = useState<BillingAddressType>({} as BillingAddressType);

  const handleCloseForm = () => history.goBack();

  const createFieldChangeHandler = (fieldName: string) => (value: string) =>
    changeFields((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));

  const handleSaveAddress = () => {
    dispatch(updateBillingAddress(fields));
    dispatch(fetchBillingDetails(true))
  };

  useEffect(() => {
    if (checkIsAnyPending(addressLs)) {
      dispatch(fetchBillingAddress());
    }
  }, []);

  useEffect(() => {
    changeFields(addressInfo);
  }, [addressInfo]);

  return (
    <>
      <PageHeader
        title="Billing Update"
        renderHeaderActions={() => (
          <span
            className="change-billing-form__close-btn"
            onClick={handleCloseForm}
            tabIndex={0}
            role="button"
          >
            <SVGIcon icon="crossBlack" />
          </span>
        )}
      />
      <Loader lsDeps={[addressLs]}>
        <div className="change-billing-form">
          <div className="change-billing-form__address">
            <div className="change-billing-form__title">Billing Address</div>
            <div className="change-billing-form__address-grid">
              <BillingFormField
                value={fields.companyName}
                label="Name"
                placeholder="Company name"
                onChange={createFieldChangeHandler('companyName')}
                className="change-billing-form__name"
              />
              <BillingFormField
                value={fields.firstLine}
                label="Address Line 1"
                placeholder="Address Line 1"
                onChange={createFieldChangeHandler('firstLine')}
                className="change-billing-form__addressLine1"
              />
              <BillingFormField
                value={fields.secondLine}
                label="Address Line 2"
                placeholder="Address Line 2"
                onChange={createFieldChangeHandler('secondLine')}
                className="change-billing-form__addressLine2"
              />
              <BillingFormField
                value={fields.city}
                label="City"
                placeholder="City"
                onChange={createFieldChangeHandler('city')}
                className="change-billing-form__city"
              />
              <BillingFormField
                value={fields.zipCode}
                label="Zip Code"
                placeholder="Zip Code"
                onChange={createFieldChangeHandler('zipCode')}
                className="change-billing-form__zip-code"
              />
              <BillingFormField
                value={fields.state}
                label="State"
                placeholder="State"
                onChange={createFieldChangeHandler('state')}
                className="change-billing-form__state"
              />
              <BillingFormField
                value={fields.country}
                label="Country"
                placeholder="Country"
                onChange={createFieldChangeHandler('country')}
                className="change-billing-form__country"
              />
              <BillingFormField
                value={fields.tax}
                label="Tax"
                placeholder="Tax"
                onChange={createFieldChangeHandler('tax')}
                className="change-billing-form__tax"
              />
            </div>
          </div>
          <div>
            <div className="change-billing-form__widget">
              <CardWidget
                cardNumber={billingDetails.card?.creditCardNumber}
                expirationMonth={billingDetails.card?.expirationMonth}
                expirationYear={billingDetails.card?.expirationYear}
              />
            </div>
            <div className="change-billing-form__contacts">
              <div className="change-billing-form__title">Billing Contact</div>
              <div className="change-billing-form__contacts-grid">
                <BillingFormField
                  value={fields.contactName}
                  label="Сontact Name"
                  placeholder="Сontact Name"
                  onChange={createFieldChangeHandler('contactName')}
                />
                <BillingFormField
                  value={fields.contactEmail}
                  label="Сontact Email"
                  placeholder="Сontact Email"
                  onChange={createFieldChangeHandler('contactEmail')}
                />
                <Button leftIcon="save" onClick={handleSaveAddress}>
                  Update information
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Loader>
    </>
  );
};

export default ChangeBillingForm;
