import React, { SetStateAction } from 'react';

import { CampaignTemplateLevel, CampaignTemplateType } from 'respona_api/generated/campaign_pb';

import { RenderConfigurableSidebarLink } from '@uikit/ConfigurableSidebar/_components/ConfigurableSidebarLinks';

import AllTemplatesIcon from '@components/CampaignTemplates/CampaignTemplatesList/_assets/icons/AllTemplatesIcon';
import ContentPromotionTemplatesIcon from '@components/CampaignTemplates/CampaignTemplatesList/_assets/icons/ContentPromotionTemplatesIcon';
import DigitalPRIcon from '@components/CampaignTemplates/CampaignTemplatesList/_assets/icons/DigitalPRIcon';
import LinkBuildingIcon from '@components/CampaignTemplates/CampaignTemplatesList/_assets/icons/LinkBuildingIcon';
import PartnerRecruitmentIcon from '@components/CampaignTemplates/CampaignTemplatesList/_assets/icons/PartnerRecruitmentIcon';

type SidebarItemRenderPropsType = {
  useCaseFilter: number;
  setUseCaseFilter: (filter: number) => void;
};

type SidebarLevelRenderPropsType = {
  levelFilter: number;
  setLevelFilter: SetStateAction<any>;
};

export const sidebarConfigUseCase = [
  {
    pageKey: 'all',
    RenderLink: ({ useCaseFilter, setUseCaseFilter }: SidebarItemRenderPropsType): JSX.Element => {
      return (
        <RenderConfigurableSidebarLink
          title="All"
          iconComponent={<AllTemplatesIcon color="currentColor" />}
          onClick={() => setUseCaseFilter(-1)}
          isActive={useCaseFilter === -1}
        />
      );
    },
  },
  {
    pageKey: 'content-promotion',
    RenderLink: ({ useCaseFilter, setUseCaseFilter }: SidebarItemRenderPropsType): JSX.Element => {
      return (
        <RenderConfigurableSidebarLink
          title="Content promotion"
          iconComponent={<ContentPromotionTemplatesIcon color="currentColor" />}
          onClick={() => setUseCaseFilter(CampaignTemplateType.CONTENT_PROMOTION)}
          isActive={useCaseFilter === CampaignTemplateType.CONTENT_PROMOTION}
        />
      );
    },
  },
  {
    pageKey: 'partner-recruitment',
    RenderLink: ({ useCaseFilter, setUseCaseFilter }: SidebarItemRenderPropsType): JSX.Element => {
      return (
        <RenderConfigurableSidebarLink
          title="Partner recruitment"
          iconComponent={<PartnerRecruitmentIcon color="currentColor" />}
          onClick={() => setUseCaseFilter(CampaignTemplateType.PARTNER_RECRUITMENT)}
          isActive={useCaseFilter === CampaignTemplateType.PARTNER_RECRUITMENT}
        />
      );
    },
  },
  {
    pageKey: 'digital-pr',
    RenderLink: ({ useCaseFilter, setUseCaseFilter }: SidebarItemRenderPropsType): JSX.Element => {
      return (
        <RenderConfigurableSidebarLink
          title="Digital PR"
          iconComponent={<DigitalPRIcon color="currentColor" />}
          onClick={() => setUseCaseFilter(CampaignTemplateType.DIGITAL_PR)}
          isActive={useCaseFilter === CampaignTemplateType.DIGITAL_PR}
        />
      );
    },
  },
  {
    pageKey: 'link-building',
    RenderLink: ({ useCaseFilter, setUseCaseFilter }: SidebarItemRenderPropsType): JSX.Element => {
      return (
        <RenderConfigurableSidebarLink
          title="Link building"
          iconComponent={<LinkBuildingIcon color="currentColor" />}
          onClick={() => setUseCaseFilter(CampaignTemplateType.LINK_BUILDING)}
          isActive={useCaseFilter === CampaignTemplateType.LINK_BUILDING}
        />
      );
    },
  },
];

const DifficultyIconComponent = ({ color = '#23E771' }) => (
  <span
    style={{
      width: '7px',
      height: '7px',
      backgroundColor: color,
      borderRadius: '50%',
      marginRight: '1px',
    }}
  />
);

export const sidebarConfigLevel = [
  {
    pageKey: 'easy',
    RenderLink: ({ levelFilter, setLevelFilter }: SidebarLevelRenderPropsType): JSX.Element => {
      return (
        <RenderConfigurableSidebarLink
          title="Easy"
          iconComponent={<DifficultyIconComponent />}
          onClick={() =>
            setLevelFilter((prev) =>
              prev === CampaignTemplateLevel.EASY ? -1 : CampaignTemplateLevel.EASY
            )
          }
          isActive={levelFilter === CampaignTemplateLevel.EASY}
        />
      );
    },
  },
  {
    pageKey: 'intermediate',
    RenderLink: ({ levelFilter, setLevelFilter }: SidebarLevelRenderPropsType): JSX.Element => {
      return (
        <RenderConfigurableSidebarLink
          title="Intermediate"
          iconComponent={<DifficultyIconComponent color="#FFC300" />}
          onClick={() =>
            setLevelFilter((prev) =>
              prev === CampaignTemplateLevel.INTERMEDIATE ? -1 : CampaignTemplateLevel.INTERMEDIATE
            )
          }
          isActive={levelFilter === CampaignTemplateLevel.INTERMEDIATE}
        />
      );
    },
  },
  {
    pageKey: 'advanced',
    RenderLink: ({ levelFilter, setLevelFilter }: SidebarLevelRenderPropsType): JSX.Element => {
      return (
        <RenderConfigurableSidebarLink
          title="Advanced"
          iconComponent={<DifficultyIconComponent color="#ED483D" />}
          onClick={() =>
            setLevelFilter((prev) =>
              prev === CampaignTemplateLevel.ADVANCED ? -1 : CampaignTemplateLevel.ADVANCED
            )
          }
          isActive={levelFilter === CampaignTemplateLevel.ADVANCED}
        />
      );
    },
  },
];
