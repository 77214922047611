import React from 'react';

const ExpandSidebarIcon = ({ color = '#FBFBFB' }: { color?: string }): JSX.Element => (
  <svg width="30" height="107" viewBox="0 0 30 107" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 53.345C0 62.6845 4.75303 71.382 12.6134 76.4259L13.3638 76.9074C20.0604 81.2046 24.9075 87.8512 26.9525 95.5406L30 107L30 0L26 14.7597C24.566 20.0513 21.2372 24.6314 16.6468 27.6291L12.4295 30.3832C4.67478 35.4472 0 44.0833 0 53.345Z"
      fill="#221CB6"
    />
    <path
      className='expand-sidebar-icon-arrow'
      d="M6.20925 52.5037L9.88805 48.6992C9.94847 48.6361 10.0204 48.586 10.0996 48.5518C10.1788 48.5176 10.2637 48.5 10.3495 48.5C10.4353 48.5 10.5203 48.5176 10.5995 48.5518C10.6787 48.586 10.7506 48.6361 10.811 48.6992C10.9321 48.8253 11 48.996 11 49.1739C11 49.3518 10.9321 49.5225 10.811 49.6486L7.59367 53.0154L10.811 56.3486C10.9321 56.4747 11 56.6454 11 56.8233C11 57.0012 10.9321 57.1718 10.811 57.298C10.7508 57.3616 10.679 57.4122 10.5998 57.4469C10.5206 57.4816 10.4355 57.4996 10.3495 57.5C10.2635 57.4996 10.1785 57.4816 10.0992 57.4469C10.02 57.4122 9.94824 57.3616 9.88804 57.298L6.20925 53.4935C6.14327 53.4305 6.09062 53.3539 6.05461 53.2687C6.01859 53.1836 6 53.0916 6 52.9986C6 52.9056 6.01859 52.8136 6.05461 52.7284C6.09062 52.6432 6.14327 52.5667 6.20925 52.5037Z"
      fill="white"
    />
  </svg>
);

export default ExpandSidebarIcon;
