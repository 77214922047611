import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import { TrackingDomainDnsSettings } from 'respona_api/generated/user-organization_pb';

import { addTrackingDomainsApi, getTrackingDomainSettingsApi } from '@api/userOrganization.api';

import { Button } from '@uikit/Button/Button';
import CopyIcon from '@uikit/CopyIcon/CopyIcon';
import { SVGIcon } from '@uikit/Icon/Icon';
import Input from '@uikit/Input/Input';
import Loader from '@uikit/Loader/Loader';
import RightInfoSidebar from '@uikit/RightInfoSidebar/RightInfoSidebar';

import './AddTrackingDomainSidebar.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onAddDomain?: () => Promise<any>;
};

const AddTrackingDomainSidebar = React.forwardRef(
  ({ isOpen, onClose, onAddDomain }: Props, ref) => {
    const [newDomain, setNewDomain] = useState<string>('');

    const { data, isLoading } = useQuery<unknown, unknown, TrackingDomainDnsSettings.AsObject>({
      queryKey: ['tracking-domain-settings'],
      queryFn: () => getTrackingDomainSettingsApi(),
      refetchOnWindowFocus: false,
      enabled: isOpen,
      retry: false,
    });

    const { mutate, isLoading: isAddingTrackingDomain } = useMutation({
      mutationFn: (domain: string) => addTrackingDomainsApi(domain),
      onSuccess: async () => {
        onAddDomain && onAddDomain();
        onClose();
      },
    });

    return (
      <RightInfoSidebar isOpen={isOpen} onClose={onClose} title="Add a tracking domain">
        <div className="add-tracking-domain-sidebar">
          <div className="add-tracking-domain-sidebar__content">
            <div className="add-tracking-domain-sidebar__title">Add the following DNS records:</div>
            <div className="add-tracking-domain-sidebar__records">
              <Loader isLoading={isLoading}>
                <>
                  <div className="add-tracking-domain-sidebar__records-type">
                    Type:{' '}
                    <span className="add-tracking-domain-sidebar__records-value">{data?.type}</span>
                  </div>

                  <div className="add-tracking-domain-sidebar__records-type">
                    Host/Name:{' '}
                    <span className="add-tracking-domain-sidebar__records-value">
                      {data?.host}
                      <CopyIcon text={data?.host} size={17} />
                    </span>
                  </div>

                  <div className="add-tracking-domain-sidebar__records-type">
                    Value/Points to:{' '}
                    <span className="add-tracking-domain-sidebar__records-value">
                      {data?.value}
                      <CopyIcon text={data?.value} size={17} />
                    </span>
                  </div>
                </>
              </Loader>
            </div>
            <div className="add-tracking-domain-sidebar__warning">
              <SVGIcon icon="warningTriangle" color="#FC8E0E" />
              Using Cloudflare? Select DNS only in the Proxy Status column.
            </div>

            <div className="add-tracking-domain-sidebar__label">Enter your domain</div>
            <Input
              className="add-tracking-domain-sidebar__input"
              placeholder="yourdomain.com"
              value={newDomain}
              onChange={({ target: { value } }) => setNewDomain(value)}
            />
          </div>

          <div className="add-tracking-domain-sidebar__footer">
            <Button onClick={() => onClose()} type="additional">
              Cancel
            </Button>
            <Button
              isLoading={isAddingTrackingDomain}
              loadingText="Verifying ..."
              onClick={() => mutate(newDomain)}
            >
              Verify and Save
            </Button>
          </div>
        </div>
      </RightInfoSidebar>
    );
  }
);

export default AddTrackingDomainSidebar;
