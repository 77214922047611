import React, { useState, useEffect } from 'react';

import useCustomVariables from '@hooks/useCustomVariables';

import { SVGIcon } from '@uikit/Icon/Icon';
import Input from '@uikit/Input/Input';
import { normalizeArrayByField } from '@utils/normalize';

import RightSidebar from '@components/RightSidebar/RightSidebar';
import Display from '@components/Display/Display';
import CustomFieldsSidebar from '@components/CustomFieldsSidebar/CustomFieldsSidebar';

import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';
import { ClearbitOptionsType } from '@ts/clearbit.types';
import fieldsConfig from './fieldsConfig';

import './PersonSidebar.scss';
import Loader from '@uikit/Loader/Loader';

type PersonSidebarPropsType = {
  isOpen: boolean;
  onClose?: () => void;
  onCreate: (any: any) => Promise<any>;
  title?: string;
  isEdit?: boolean;
  enableMultiEmail?: boolean;
  editInfo?: {
    [key: string]: any;
  };
  embedded?: boolean;
};

const generateEditionValues = (prevState, editInfo) => {
  const requiredState = {
    name: {
      ...prevState.name,
      value: editInfo.name || '',
    },
    emailsList: {
      ...prevState.emailsList,
      value: editInfo.emailsList.map(({ email }) => email),
    },
    websiteDomain: {
      ...prevState.websiteDomain,
      value: {
        value: editInfo.websiteDomain || '',
        label: editInfo.websiteDomain || '',
      },
    },
    websiteName: {
      ...prevState.websiteName,
      value: (() => {
        const nameArr = editInfo.websiteDomain.split('.');
        nameArr.pop();
        return editInfo.websiteName || nameArr.join('.');
      })(),
    },
  };

  const additionalState = {};
  fieldsConfig.additional.forEach(({ key }) => {
    if (editInfo[key]) {
      additionalState[key] = { value: editInfo[key] };
    }
  });

  const customVariablesState = {};
  if (editInfo.customVariablesList?.length > 0) {
    editInfo.customVariablesList.forEach(
      // eslint-disable-next-line no-return-assign
      (variable) => (customVariablesState[variable.variableId] = variable)
    );
  }

  return {
    ...requiredState,
    ...additionalState,
    customFields: {
      ...customVariablesState,
    },
  };
};

type PersonStateFiledType = {
  key: string;
  icon: string;
  title: string;
  maxCount?: number;
  value: string | string[];
};

// TODO: Need to refactor - RES-1849
function PersonSidebar({
  isOpen,
  onClose,
  onCreate,
  title: sidebarTitle,
  isEdit = false,
  editInfo = {},
  enableMultiEmail = true,
  embedded = false,
}: PersonSidebarPropsType): JSX.Element {
  const [isSaveButtonLoading, changeIsSaveButtonLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const workspaceId = useCurrentWorkspaceId();
  const defaultState = fieldsConfig.required.reduce(
    (acc, { key, icon, title, isArray, maxCount }) => ({
      ...acc,
      [key]: {
        key,
        icon,
        title,
        maxCount,
        value: isArray ? [''] : '',
      },
    }),
    {}
  );

  const [personFields, changePersonFields] = useState(defaultState);
  const [customFields, changeCustomFields] = useState({});
  const [isCustomVarModalOpen, changeIsCustomVarModalOpen] = useState(false);

  const { customVariables } = useCustomVariables(null);

  useEffect(() => {
    if (isEdit && editInfo) {
      changePersonFields((prevState: any) => generateEditionValues(prevState, editInfo));
    }
  }, [editInfo]);

  useEffect(() => {
    if (editInfo && editInfo.customVariablesList && editInfo.customVariablesList.length !== 0) {
      changeCustomFields(normalizeArrayByField(editInfo.customVariablesList, 'variableId'));
    }
  }, [editInfo]);

  if (isEdit && !editInfo) {
    return null;
  }

  const addAdditionalField = (key: string) => {
    changePersonFields((prevState) => ({ ...prevState, [key]: '' }));
  };

  const createPersonFieldChangeHandler =
    (field) =>
    ({ target: { value } }) =>
      changePersonFields((prevState) => {
        return {
          ...prevState,
          [field]: {
            ...prevState[field],
            value,
          },
        };
      });

  const handleWebsiteDomainChange = (clearbitPayload: ClearbitOptionsType) => {
    changePersonFields((prevState) => {
      return {
        ...prevState,
        websiteDomain: {
          // @ts-ignore
          ...prevState.websiteDomain,
          value: {
            value: clearbitPayload?.value || clearbitPayload.domain,
            label: clearbitPayload?.label || clearbitPayload.domain,
            name: clearbitPayload?.name || clearbitPayload.name,
            domain: clearbitPayload?.domain || clearbitPayload.domain,
            logo: clearbitPayload?.logo,
            query: clearbitPayload?.query || '',
          },
        },
        // @ts-ignore
        websiteName: { ...prevState.websiteName, value: clearbitPayload.name },
      };
    });
  };

  const createPersonMultiValuesChangeHandler =
    (field, index) =>
    ({ target: { value } }) =>
      changePersonFields((prevState) => ({
        ...prevState,
        [field]: {
          ...prevState[field],
          value: prevState[field].value.map((curValue, curIndex) =>
            curIndex === index ? value : curValue
          ),
        },
      }));

  const createPersonMultiValuesRemoveHandler = (field, index) => () =>
    changePersonFields((prevState) => ({
      ...prevState,
      [field]: {
        ...prevState[field],
        value: prevState[field].value.filter((_, curIndex) => curIndex !== index),
      },
    }));

  const createAddNewValueHandler = (field) => () =>
    changePersonFields((prevState) => {
      if (field === 'emailsList' && prevState[field].value.length === 5) {
        return prevState;
      }

      return {
        ...prevState,
        [field]: {
          ...prevState[field],
          value: [...prevState[field].value, ''],
        },
      };
    });

  if (!isOpen) {
    return null;
  }

  const handleSaveClick = () => {
    changeIsSaveButtonLoading(true);
    setIsLoading(true);
    const arrayFields: [string, PersonStateFiledType][] = Object.entries(personFields);

    const parsedFields = arrayFields.reduce(
      (acc, [key, { value }]) => ({
        ...acc,
        [key]: value,
      }),
      {}
    );

    let promise;

    if (isEdit) {
      // @ts-ignore
      const emailsList = personFields.emailsList.value.map((email, index) => ({
        email,
        accuracy: index < editInfo.emailsList.length ? editInfo.emailsList[index].accuracy : 1,
      }));

      const newPerson = {
        ...editInfo,
        ...parsedFields,
        emailsList,
        customFields,
        workspaceId,
        company: {
          // @ts-ignore
          domain: parsedFields?.websiteDomain?.value,
          // @ts-ignore
          name: parsedFields?.websiteName,
          // @ts-ignore
          logo: parsedFields?.websiteDomain?.logo,
        },
      };
      promise = onCreate(newPerson);
    } else {
      // @ts-ignore
      const emailsList = personFields.emailsList.value.map((email, index) => ({
        email,
        accuracy: 3,
      }));
      promise = onCreate({
        ...parsedFields,
        emailsList,
        customFields,
        workspaceId,
        company: {
          // @ts-ignore
          domain: parsedFields?.websiteDomain?.domain,
          // @ts-ignore
          name: parsedFields?.websiteName,
          // @ts-ignore
          logo: parsedFields?.websiteDomain?.logo,
        },
      });
    }

    promise.finally(() => {
      if (onClose) {
        onClose();
      }

      changeIsSaveButtonLoading(false);
      setIsLoading(false);
      changePersonFields(defaultState);
      changeCustomFields({});
    });
  };

  const handleAddCustomField = (varId) => {
    changeCustomFields((prevState) => ({
      ...prevState,
      [varId]: {
        ...customVariables.find(({ id }) => id === varId),
        value: '',
      },
    }));
  };

  const handleChangeCustomField = (id, { target: { value } }) => {
    changeCustomFields((prevState) => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        value,
      },
    }));
  };

  const handleCloseCustomVarModal = () => changeIsCustomVarModalOpen(false);

  const renderValues = (fieldKey, field) => {
    return (
      <>
        <div className="person-sidebar__field-label">
          {field.title} *
          <Display
            isVisible={
              field.value.length < field.maxCount &&
              (field.key !== 'emailsList' || enableMultiEmail)
            }
          >
            <span onClick={createAddNewValueHandler(fieldKey)} tabIndex={0} role="button">
              <SVGIcon icon="plusCircle" />
            </span>
          </Display>
        </div>
        {field.value.map((singleValue, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className="person-sidebar__field-wrapper" key={`email-input-${index}`}>
            <Input
              onBlur={() => {
                if (index !== 0) {
                  return;
                }

                // @ts-ignore
                const domain = personFields?.emailsList?.value?.[0]?.split('@')?.[1];
                if (!domain || domain.indexOf('.') < 0) {
                  return;
                }
                const nameArr = domain.split('.');
                nameArr.pop();

                if (
                  domain && // @ts-ignore
                  (!personFields?.websiteDomain?.value?.domain || // @ts-ignore
                    domain !== personFields?.websiteDomain?.value?.query)
                ) {
                  const newPerson = {
                    // @ts-ignore
                    ...personFields?.websiteDomain,
                    domain,
                    name: nameArr.join('.'),
                  };
                  handleWebsiteDomainChange(newPerson);
                }
              }}
              value={singleValue}
              onChange={createPersonMultiValuesChangeHandler(fieldKey, index)}
              placeholder={`${field.title.toLowerCase()}`}
              icon="email"
              iconSize={20}
              iconColor="#251FB6"
              isFullWidth
            />
            <Display isVisible={index !== 0}>
              <span
                className="person-sidebar__remove-field"
                onClick={createPersonMultiValuesRemoveHandler(fieldKey, index)}
                tabIndex={0}
                role="button"
              >
                <SVGIcon icon="crossDelete" />
              </span>
            </Display>
          </div>
        ))}
      </>
    );
  };

  return (
    <RightSidebar
      embedded={embedded}
      title={sidebarTitle || ''}
      isOpen={isOpen}
      onClose={onClose}
      onSave={handleSaveClick}
      buttonCancelText="Discard"
      isSaveButtonLoading={isSaveButtonLoading}
      saveButtonLoadingText="Saving..."
    >
      <div className="person-sidebar">
        <Loader isLoading={isLoading}>
          {fieldsConfig.required.map(
            ({ title, icon, key, isArray, renderField: RenderField, optional }, index) => (
              <div className="person-sidebar__row" key={index}>
                {isArray ? (
                  renderValues(key, personFields[key])
                ) : (
                  <>
                    <div className="person-sidebar__field-label">
                      {title}
                      {!optional ? ' *' : ''}
                    </div>
                    {RenderField !== undefined ? (
                      <RenderField
                        value={personFields[key].value || ''}
                        onChange={(it: ClearbitOptionsType) => handleWebsiteDomainChange(it)}
                      />
                    ) : (
                      <Input
                        value={personFields[key]?.value || ''}
                        onChange={createPersonFieldChangeHandler(key)}
                        placeholder={`Input ${title.toLowerCase()}`}
                        icon={icon}
                        iconColor="#251FB6"
                        isFullWidth
                      />
                    )}
                  </>
                )}
              </div>
            )
          )}

          {Object.entries(customFields).map(([id, customField], index) => {
            // customField.title - is a property of type we send to API
            // customField.variableName - is a property of type we got from API to persons table
            // @ts-ignore
            const title = customField.title || customField.variableName;
            return (
              <div className="person-sidebar__row" key={index}>
                <div className="person-sidebar__field-label">{title}</div>
                <Input
                  value={customFields[id].value || ''}
                  onChange={(event) => handleChangeCustomField(id, event)}
                  placeholder={`Input ${title.toLowerCase()}`}
                  isFullWidth
                />
              </div>
            );
          })}
        </Loader>
      </div>
      <CustomFieldsSidebar
        isOpen={isCustomVarModalOpen}
        onClose={handleCloseCustomVarModal}
        isUpdate={false}
      />
    </RightSidebar>
  );
}

export default PersonSidebar;
