import React, { useEffect, useRef, useState } from 'react';
import cn from 'class-names';

import { SVGIcon, SvgIconType } from '@uikit/Icon/Icon';
import Select from '@uikit/Select/Select';

import './SidebarCRMSectionSelectionField.scss';

function SidebarCRMSectionSelectionField({
  initialValue,
  options,
  onChange,
  isOpen = false,
  icon,
}: {
  initialValue: string | number;
  options: any;
  onChange: (initialValue: string | number, newStatus: string | number) => void;
  isOpen?: boolean;
  icon?: SvgIconType;
}) {
  const editableRowRef = useRef<HTMLDivElement>();

  const [isEditing, setIsEditing] = useState<boolean>(isOpen);
  const [currentValue, setValue] = useState<string | number>(initialValue);

  useEffect(() => {
    if (currentValue !== initialValue) {
      setValue(initialValue);
    }
  }, [initialValue]);

  function onChangeData(selectValue: string | number) {
    setValue(selectValue);
    onChange(initialValue, selectValue);
    setIsEditing(false);
  }

  return (
    <div
      ref={editableRowRef}
      className={cn('contacts-sidebar-crm-section__selection-row', {
        'contacts-sidebar-crm-section__selection-row--is-editing': isEditing,
      })}
    >
      {isEditing ? (
        <>
          <Select
            value={options.find((it) => it.value === currentValue)}
            options={options}
            onChange={(option) => onChangeData(option.value)}
          />
          <div
            className={cn('contacts-sidebar-crm-section__editable-btn')}
            onClick={() => setIsEditing(false)}
          >
            <SVGIcon icon="close" size={11} color="#221CB6" />
          </div>
        </>
      ) : (
        <div className="selected-status">
          {icon ? <SVGIcon icon={icon} size={16} color="#C4C6CD" /> : null}
          {options.find((it) => it.value === currentValue)?.label || '-'}
        </div>
      )}

      {!isEditing && (
        <div className="contacts-sidebar-crm-section__editable-btn-row">
          <div
            className="contacts-sidebar-crm-section__editable-btn"
            onClick={() => setIsEditing(true)}
          >
            <SVGIcon icon="pencil" size={11} color="#221CB6" />
          </div>
        </div>
      )}
    </div>
  );
}

export default SidebarCRMSectionSelectionField;
