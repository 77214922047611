import React from 'react';

import PieChart from '@uikit/PieChart/PieChart';

import './RateCell.scss';

type RateCellType = {
  value: number;
  color: string;
  name: string;
};

const RateCell = ({ value, color = '#9324EB', name = 'rate' }: RateCellType): JSX.Element => {
  return (
    <div className="rate-cell">
      <div className="rate-cell__diagram">
        <PieChart value={value} color={color} name={name} />
      </div>
      <div className="rate-cell__value">{value} %</div>
    </div>
  );
};

export default RateCell;
