import React from 'react';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';

import {
  ExternalUnsubscribePageGradientLeft,
  ExternalUnsubscribePageGradientRight,
} from '@uikit/ExternalUnsubscribePageGradients/ExternalUnsubscribePageGradients';
import { Button } from '@uikit/Button/Button';

// @ts-ignore
import responaLogo from 'src/assets/ResponaLogoLarge.svg';

import './MaintenancePage.scss';

const MaintenancePage = () => {
  const { search } = useLocation();
  const { redirect, message } = qs.parse(search);

  return (
    <div className="maintenance-page">
      <a href="/" className="maintenance-page__logo-container">
        <img alt="Respona" src={responaLogo} className="maintenance-page__logo" />
      </a>

      <ExternalUnsubscribePageGradientLeft />
      <ExternalUnsubscribePageGradientRight />

      <div className="maintenance-page__content">
        <div className="maintenance-page__message">
          {!!message ? (
            <h1 className="maintenance-page__title">Error</h1>
          ) : (
            <h1 className="maintenance-page__title">We’re upgrading your outreach experience.</h1>
          )}

          {!message ? (
            <>
              <p className="maintenance-page__sub-title">
                Respona is undergoing a new update. We’ll be back online soon.
              </p>
              <p className="maintenance-page__sub-title maintenance-page__contact">
                Questions? Don't hesitate to get in touch with us via{' '}
                <a href="mailto:support@respona.com">support@respona.com</a>
              </p>
            </>
          ) : (
            <p className="maintenance-page__sub-title">{decodeURI(message as string)}</p>
          )}

          {!!redirect ? (
            <Button href={redirect as string} className="maintenance-page__refresh-btn">
              Try Again
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default MaintenancePage;
