import React from 'react';

import HoursRow from '@components/Settings/ScheduleEdition/_components/HoursRow';
import DayRow from '@components/Settings/ScheduleEdition/_components/DayRow';
import { ScheduleDayType } from '@ts/schedules.types';

type ScheduleEditionDaysTableType = {
  dayList: ScheduleDayType[];
  onUpdateDay: (any) => void;
};

const ScheduleEditionDaysTable = ({
  dayList,
  onUpdateDay,
}: ScheduleEditionDaysTableType): JSX.Element => {
  return (
    <div className="schedule-edition-days">
      <HoursRow />
      {dayList.map((day) => (
        <DayRow
          {...day}
          key={`day-${day.day}`}
          onUpdateDay={onUpdateDay}
        />
      ))}
    </div>
  );
};

export default ScheduleEditionDaysTable;
