import React from 'react';

export default ({ color = '#C4C6CD' }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.9317 7.13217C16.9239 6.54434 16.8138 5.96235 16.6065 5.41224C16.4267 4.94823 16.1521 4.52682 15.8002 4.17494C15.4483 3.82307 15.0269 3.54846 14.5629 3.36867C14.0199 3.16482 13.4462 3.05459 12.8663 3.04269C12.1196 3.00931 11.8829 3 9.98758 3C8.09225 3 7.84932 3 7.10811 3.04269C6.52845 3.05468 5.95503 3.1649 5.41224 3.36867C4.94815 3.54833 4.52668 3.8229 4.17479 4.17479C3.8229 4.52668 3.54833 4.94815 3.36867 5.41224C3.16441 5.95487 3.05443 6.52842 3.04346 7.10811C3.01009 7.85553 3 8.09225 3 9.98758C3 11.8829 3 12.1251 3.04346 12.8671C3.05511 13.4476 3.16454 14.0204 3.36867 14.5645C3.54864 15.0284 3.8234 15.4497 4.17541 15.8015C4.52742 16.1532 4.94894 16.4277 5.41302 16.6073C5.95432 16.8193 6.52784 16.9374 7.10888 16.9565C7.8563 16.9899 8.09303 17 9.98836 17C11.8837 17 12.1266 17 12.8678 16.9565C13.4477 16.9451 14.0215 16.8351 14.5645 16.6313C15.0284 16.4513 15.4497 16.1767 15.8015 15.8248C16.1534 15.473 16.4281 15.0517 16.6081 14.5878C16.8122 14.0445 16.9216 13.4717 16.9333 12.8903C16.9666 12.1437 16.9767 11.907 16.9767 10.0109C16.9752 8.11553 16.9752 7.87493 16.9317 7.13217ZM9.98293 13.5718C8.00067 13.5718 6.39483 11.966 6.39483 9.9837C6.39483 8.00144 8.00067 6.39561 9.98293 6.39561C10.9345 6.39561 11.8472 6.77364 12.5201 7.44654C13.193 8.11944 13.571 9.03208 13.571 9.9837C13.571 10.9353 13.193 11.848 12.5201 12.5209C11.8472 13.1938 10.9345 13.5718 9.98293 13.5718ZM13.7138 7.09957C13.2505 7.09957 12.8771 6.72547 12.8771 6.26289C12.8771 6.15307 12.8988 6.04432 12.9408 5.94286C12.9828 5.84139 13.0444 5.7492 13.1221 5.67154C13.1998 5.59389 13.2919 5.53229 13.3934 5.49026C13.4949 5.44823 13.6036 5.4266 13.7134 5.4266C13.8233 5.4266 13.932 5.44823 14.0335 5.49026C14.1349 5.53229 14.2271 5.59389 14.3048 5.67154C14.3824 5.7492 14.444 5.84139 14.4861 5.94286C14.5281 6.04432 14.5497 6.15307 14.5497 6.26289C14.5497 6.72547 14.1756 7.09957 13.7138 7.09957Z"
      fill={color}
    />
    <path
      d="M9.98297 12.3143C11.2702 12.3143 12.3137 11.2708 12.3137 9.98358C12.3137 8.69634 11.2702 7.65283 9.98297 7.65283C8.69573 7.65283 7.65222 8.69634 7.65222 9.98358C7.65222 11.2708 8.69573 12.3143 9.98297 12.3143Z"
      fill={color}
    />
  </svg>
);
