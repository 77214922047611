import React, { useCallback, useEffect, useRef, useState } from 'react';

import { DispatchType } from 'src/store';

import { useDispatch, useSelector } from 'react-redux';

import RightInfoSidebar from '@uikit/RightInfoSidebar/RightInfoSidebar';
import NumberInput, { NumberInputValueType } from '@uikit/Input/NumberInput';

import { upgradeMailboxAccountsNumberRequest } from '@redux/thunks/billings.thunks';

import { MailboxAccountPriceResponseType, SubscriptionPresetResponseType } from '@ts/billing.types';

import { calcMailboxAccountPriceApi, getSubscriptionPresetApi } from '@api/billing.api';

import debounce from '@utils/debounce';

import { userProfileSelector } from '@redux/selectors/userProfile.selector';
import UpsellSidebarFooter from '../_components/UpsellSidebarFooter/UpsellSidebarFooter';

import './UpsellEmailSidebar.scss';

function UpsellEmailSidebar({
  title,
  onClose,
  onActionAfterPay,
}: {
  title?: string;
  onClose: () => void;
  onActionAfterPay?: () => void;
}): JSX.Element {
  const dispatch = useDispatch<DispatchType>();

  const isYearly = useRef<boolean | null>(null);
  const numberInputRef = useRef<HTMLInputElement>(null);
  const { info: profileFromRedux } = useSelector(userProfileSelector);
  const [presetState, changePresetState] = useState<SubscriptionPresetResponseType | null>(null);
  const [mailboxesPrice, changeMailboxesPrice] = useState<number>(0);
  const [totalPrice, changeTotalPrice] = useState<number>(0);
  const [mailboxQuantityToAdd, changeMailboxQuantityToAdd] = useState<NumberInputValueType>(1);

  const [isTotalLoading, changeIsTotalLoading] = useState<boolean>(true);
  const [isPayButtonLoading, changeIsPayButtonLoading] = useState<boolean>(false);

  const handleChangeMailboxQuantity = (value: NumberInputValueType) =>
    changeMailboxQuantityToAdd(value);

  const handlePay = () => {
    if (mailboxQuantityToAdd) {
      changeIsPayButtonLoading(true);
      dispatch(
        upgradeMailboxAccountsNumberRequest(
          mailboxQuantityToAdd,
          presetState.cycle,
          profileFromRedux.email
        )
      )
        .then(() => {
          onClose();
          if (onActionAfterPay) {
            setTimeout(() => {
              onActionAfterPay();
            }, 200);
          }
        })
        .finally(() => {
          changeIsPayButtonLoading(false);
        });
    } else {
      numberInputRef.current.focus();
    }
  };

  const debouncedFetchTotalPriceMailboxes = useCallback(
    debounce((mailboxQuantity): Promise<void> => {
      return calcMailboxAccountPriceApi(mailboxQuantity)
        .then((response: MailboxAccountPriceResponseType) => {
          changeTotalPrice(response.priceDueToday);
        })
        .finally(() => changeIsTotalLoading(false));
    }, 1000),
    []
  );

  useEffect(() => {
    getSubscriptionPresetApi().then((preset: SubscriptionPresetResponseType) => {
      isYearly.current = preset.cycle === 2;
      changePresetState(preset);
    });
  }, []);

  useEffect(() => {
    if (presetState) {
      const myMailBoxPrice = isYearly.current
        ? presetState.mailboxAccountYearlyPrice
        : presetState.mailboxAccountMonthlyPrice;

      changeMailboxesPrice(myMailBoxPrice);
    }
  }, [presetState, isYearly.current]);

  useEffect(() => {
    changeIsTotalLoading(true);

    if (presetState && mailboxQuantityToAdd) {
      debouncedFetchTotalPriceMailboxes(mailboxQuantityToAdd);
    }
  }, [presetState, mailboxQuantityToAdd]);

  return (
    <RightInfoSidebar isOpen onClose={onClose} title={title || 'Increase Email Accounts'}>
      <div className="upsell-email-sidebar">
        <div className="upsell-email-sidebar__body">
          <div className="upsell-email-sidebar__buy-email-block">
            <div>
              <span>Additional email account: </span>
              <span className="upsell-email-sidebar__price">
                ${mailboxesPrice} / {isYearly.current ? 'year' : 'month'}
              </span>
            </div>
            <div className="upsell-email-sidebar__price-quantity-row">
              <span>Quantity </span>
              <div className="upsell-email-sidebar__price-quantity-input">
                <NumberInput
                  value={mailboxQuantityToAdd}
                  maxNumber={10}
                  minNumber={1}
                  onChange={handleChangeMailboxQuantity}
                  inputRef={numberInputRef}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="upsell-email-sidebar__footer">
          <UpsellSidebarFooter
            priceDescription="Due today (pro-rated) :"
            onPay={handlePay}
            onCancel={onClose}
            totalPrice={totalPrice}
            isLoadingPrice={isTotalLoading}
            isPayButtonLoading={isPayButtonLoading}
          />
        </div>
      </div>
    </RightInfoSidebar>
  );
}

export default UpsellEmailSidebar;
