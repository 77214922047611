export const createUpdaterCacheItemById =
  (itemId: number, fieldName: string, newValue: any) => (cache: any) => {
    return cache.map((item) =>
      item.id === itemId
        ? {
            ...item,
            [fieldName]: newValue,
          }
        : item
    );
  };
