import Dashboard from '@components/Campaigns/Dashboard/Dashboard';
import React, { useEffect, useRef, useState } from 'react';

import PageWrapper from '@components/PageWrapper/PageWrapper';

import { mockCampaignTemplates } from '@pages/LoginPage/_components/mockData';
import CampaignTemplatesList from '@components/CampaignTemplates/CampaignTemplatesList/CampaignTemplatesList';

function LoginPageBackground({ style, location }: { style?: React.CSSProperties; location?: any }) {
  const [dashboardPreview, setDashboardPreview] = useState(false);

  useEffect(() => {
    if (['/dashboard', '/'].includes(location?.pathname)) {
      const timeoutId = setTimeout(() => setDashboardPreview(true), 500);

      return () => clearTimeout(timeoutId);
    }
  }, []);

  return (
    <div className="login-background-container" style={style}>
      <PageWrapper pathname={window.location.pathname} preview>
        <div
          style={{
            position: 'fixed',
            opacity: dashboardPreview ? 0 : 1,
            transition: 'opacity 1000ms ease',
          }}
        >
          <CampaignTemplatesList mockData={mockCampaignTemplates} />
        </div>
        <div
          style={{
            position: 'fixed',
            opacity: dashboardPreview ? 1 : 0,
            transition: 'opacity 1000ms ease',
          }}
        >
          <Dashboard preview />
        </div>
      </PageWrapper>
    </div>
  );
}

export default LoginPageBackground;
