import React from 'react';

export default ({ size = 18, color = '#8F9199' }) => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_21829_262)">
      <path
        d="M12.4987 16.6663C13.9369 16.6663 15.1029 15.5004 15.1029 14.0622C15.1029 12.6239 13.9369 11.458 12.4987 11.458C11.0605 11.458 9.89453 12.6239 9.89453 14.0622C9.89453 15.5004 11.0605 16.6663 12.4987 16.6663Z"
        stroke="#251FB6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4987 6.24984C13.9369 6.24984 15.1029 5.08391 15.1029 3.64567C15.1029 2.20743 13.9369 1.0415 12.4987 1.0415C11.0605 1.0415 9.89453 2.20743 9.89453 3.64567C9.89453 5.08391 11.0605 6.24984 12.4987 6.24984Z"
        stroke="#251FB6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.64518 11.4583C5.08342 11.4583 6.24935 10.2924 6.24935 8.85417C6.24935 7.41593 5.08342 6.25 3.64518 6.25C2.20694 6.25 1.04102 7.41593 1.04102 8.85417C1.04102 10.2924 2.20694 11.4583 3.64518 11.4583Z"
        stroke="#251FB6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3542 11.4583C22.7924 11.4583 23.9583 10.2924 23.9583 8.85417C23.9583 7.41593 22.7924 6.25 21.3542 6.25C19.9159 6.25 18.75 7.41593 18.75 8.85417C18.75 10.2924 19.9159 11.4583 21.3542 11.4583Z"
        stroke="#251FB6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.60547 23.9582C7.4638 21.5311 9.77839 19.7915 12.5003 19.7915C15.2221 19.7915 17.5367 21.5311 18.3951 23.9582"
        stroke="#251FB6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_21829_262">
        <rect width="25" height="25" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
