import React from 'react';

const ReportBox = ({ color = '#BDBDBD' }: { color: string }): JSX.Element => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.90909 6.16406L1 8.27315V17.0004H17V8.27315L14.0909 6.16406"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.90906 9.87273V1H14.0909V9.87273"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 8.27344L17 17.0007"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M17.3831 8.97575C17.771 8.76418 17.9139 8.27823 17.7023 7.89036C17.4907 7.50248 17.0048 7.35955 16.6169 7.57112L17.3831 8.97575ZM16.6169 7.57112L8.61692 11.9348L9.38308 13.3394L17.3831 8.97575L16.6169 7.57112Z"
      fill={color}
    />
    <path
      d="M11.1819 7.54474V5.72656"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.99988 7.54581V4.63672"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.81812 7.54403V6.45312"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ReportBox;
