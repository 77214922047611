import React from 'react';
import SortButtons from '@uikit/SortButtons/SortButtons';
import PieChart from '@uikit/PieChart/PieChart';

export default [
  {
    dataKey: 'teamMember',
    RenderTitle: () => {
      return (
        <div>
          <div>Team</div>
          <div>member</div>
        </div>
      );
    },
    RenderCell: ({ row: { userName }, rowIndex }) => (
      <div className="leader-report-table__name">
        <span className="title-symbol-number"># {rowIndex + 1} </span>
        {userName}
      </div>
    ),
  },
  {
    RenderTitle: ({
      renderProps: { handleSortClick, dataKey, selectedKey, direction },
    }): JSX.Element => {
      return (
        <div className="leader-report-table__th">
          <div className="text-align-center mr-5">
            <div>New</div>
            <div>Websites</div>
          </div>
          <SortButtons
            handleClick={handleSortClick}
            isActive={selectedKey === dataKey}
            direction={direction}
            dataKey={dataKey}
          />
        </div>
      );
    },
    dataKey: 'websiteCreatedNumber',
    RenderCell: ({ row: { websiteCreatedNumber } }) => (
      <div className="leader-report-table__job-position">{Math.ceil(websiteCreatedNumber)}</div>
    ),
  },
  {
    RenderTitle: ({
      renderProps: { handleSortClick, dataKey, selectedKey, direction },
    }): JSX.Element => {
      return (
        <div className="leader-report-table__th">
          <div className="text-align-center mr-5">
            <div>Contacted</div>
            <div>Websites</div>
          </div>
          <SortButtons
            handleClick={handleSortClick}
            isActive={selectedKey === dataKey}
            direction={direction}
            dataKey={dataKey}
          />
        </div>
      );
    },
    dataKey: 'websiteContactedNumber',
    RenderCell: ({ row: { websiteContactedNumber } }) => (
      <div className="leader-report-table__job-position">{Math.ceil(websiteContactedNumber)}</div>
    ),
  },
  {
    RenderTitle: ({
      renderProps: { handleSortClick, dataKey, selectedKey, direction },
    }): JSX.Element => {
      return (
        <div className="leader-report-table__th">
          <div className="text-align-center mr-5">
            <div>Replied</div>
            <div>Websites</div>
          </div>
          <SortButtons
            handleClick={handleSortClick}
            isActive={selectedKey === dataKey}
            direction={direction}
            dataKey={dataKey}
          />
        </div>
      );
    },
    dataKey: 'websiteRepliedNumber',
    RenderCell: ({ row: { websiteRepliedNumber } }) => (
      <div className="leader-report-table__job-position">{Math.ceil(websiteRepliedNumber)}</div>
    ),
  },
  {
    RenderTitle: ({
      renderProps: { handleSortClick, dataKey, selectedKey, direction },
    }): JSX.Element => {
      return (
        <div className="leader-report-table__th">
          <div className="text-align-center mr-5">
            <div>Website</div>
            <div>Reply Rate</div>
          </div>
          <SortButtons
            handleClick={handleSortClick}
            isActive={selectedKey === dataKey}
            direction={direction}
            dataKey={dataKey}
          />
        </div>
      );
    },
    dataKey: 'repliedWebsiteRate',
    RenderCell: ({ row: { repliedWebsiteRate } }) => (
      <div className="leader-report-table__job-position">
        <div className="percentage-pie">
          <PieChart
            size={20}
            pieWidth={3}
            value={repliedWebsiteRate}
            color="#3424EB"
            name="title"
          />
          <span className="percentage-pie__title">{Math.ceil(repliedWebsiteRate)}%</span>
        </div>
      </div>
    ),
  },
  {
    RenderTitle: ({
      renderProps: { handleSortClick, dataKey, selectedKey, direction },
    }): JSX.Element => {
      return (
        <div className="leader-report-table__th">
          <div className="text-align-center mr-5">
            <div>New</div>
            <div>Contacts</div>
          </div>
          <SortButtons
            handleClick={handleSortClick}
            isActive={selectedKey === dataKey}
            direction={direction}
            dataKey={dataKey}
          />
        </div>
      );
    },
    dataKey: 'contactCreatedNumber',
    RenderCell: ({ row: { contactCreatedNumber } }) => (
      <div className="leader-report-table__job-position bold-text">
        {Math.ceil(contactCreatedNumber)}
      </div>
    ),
  },
  {
    RenderTitle: ({
      renderProps: { handleSortClick, dataKey, selectedKey, direction },
    }): JSX.Element => {
      return (
        <div className="leader-report-table__th">
          <div className="text-align-center mr-5">
            <div>Contacted</div>
            <div>Contacts</div>
          </div>
          <SortButtons
            handleClick={handleSortClick}
            isActive={selectedKey === dataKey}
            direction={direction}
            dataKey={dataKey}
          />
        </div>
      );
    },
    dataKey: 'contactContactedNumber',
    RenderCell: ({ row: { contactContactedNumber } }) => (
      <div className="leader-report-table__job-position">{Math.ceil(contactContactedNumber)}</div>
    ),
  },
  {
    RenderTitle: ({
      renderProps: { handleSortClick, dataKey, selectedKey, direction },
    }): JSX.Element => {
      return (
        <div className="leader-report-table__th">
          <div className="text-align-center mr-5">
            <div>Replied</div>
            <div>Contacts</div>
          </div>
          <SortButtons
            handleClick={handleSortClick}
            isActive={selectedKey === dataKey}
            direction={direction}
            dataKey={dataKey}
          />
        </div>
      );
    },
    dataKey: 'contactRepliedNumber',
    RenderCell: ({ row: { contactRepliedNumber } }) => (
      <div className="leader-report-table__job-position">{Math.ceil(contactRepliedNumber)}</div>
    ),
  },
  {
    RenderTitle: ({
      renderProps: { handleSortClick, dataKey, selectedKey, direction },
    }): JSX.Element => {
      return (
        <div className="leader-report-table__th">
          <div className="text-align-center mr-5">
            <div>Contacts</div>
            <div>Reply Rate</div>
          </div>
          <SortButtons
            handleClick={handleSortClick}
            isActive={selectedKey === dataKey}
            direction={direction}
            dataKey={dataKey}
          />
        </div>
      );
    },
    dataKey: 'repliedContactsRate',
    RenderCell: ({ row: { repliedContactsRate } }) => (
      <div className="leader-report-table__job-position">
        <div className="percentage-pie">
          <PieChart
            size={20}
            pieWidth={3}
            value={repliedContactsRate}
            color="#3424EB"
            name="title"
          />
          <span className="percentage-pie__title">{Math.ceil(repliedContactsRate)}%</span>
        </div>
      </div>
    ),
  },
  {
    RenderTitle: ({
      renderProps: { handleSortClick, dataKey, selectedKey, direction },
    }): JSX.Element => {
      return (
        <div className="leader-report-table__th">
          <div className="text-align-center mr-5">
            <div>Spent</div>
            <div>Credits</div>
          </div>
          <SortButtons
            handleClick={handleSortClick}
            isActive={selectedKey === dataKey}
            direction={direction}
            dataKey={dataKey}
          />
        </div>
      );
    },
    dataKey: 'creditsSpentNumber',
    RenderCell: ({ row: { creditsSpentNumber } }) => (
      <div className="leader-report-table__job-position">{Math.ceil(creditsSpentNumber)}</div>
    ),
  },
];
