import { Transforms } from 'slate';
import getImageDimensions from '@utils/getImageDimensions';

export const insertImagePlaceholder = (editor, selection) => {
  const text = { text: '', type: 'text' };
  const imagePlaceholder: {
    type: 'imagePlaceholder';
    children: {
      text: string;
    }[];
  } = {
    type: 'imagePlaceholder',
    children: [text],
  };

  Transforms.insertNodes(editor, imagePlaceholder, {
    at: { path: selection?.anchor.path, offset: selection?.anchor.offset },
  });
};

export const insertImage = (editor, url, lastSelection?, removingNodeSelection?) => {
  getImageDimensions(url).then(({ width, height }) => {
    const text = { text: '', type: 'text' };
    const image: {
      type: 'image';
      url: string;
      width: number;
      height: number;
      children: {
        text: string;
      }[];
    } = { type: 'image', url, width: width > 450 ? 450 : width, height, children: [text] };

    /* if (removingNodeSelection) {
      Transforms.removeNodes(editor, {
        at: {
          path: removingNodeSelection.anchor.path,
          offset: removingNodeSelection.anchor.offset,
        },
      });
    } */

    if (lastSelection) {
      Transforms.insertNodes(editor, image, {
        at: { path: lastSelection?.anchor.path, offset: lastSelection?.anchor.offset },
      });
    } else {
      Transforms.insertNodes(editor, image);
    }
  });

  /* if (!url) return;

  const { selection } = editor;

  if (selection) {
    const [parentNode, parentPath] = Editor.parent(editor, selection.focus?.path);

    // @ts-ignore
    if (editor.isVoid(parentNode)) {
      // Insert the new image node after the void node or a node with content
      Transforms.insertNodes(editor, image, {
        at: Path.next(parentPath),
        select: true,
      });
    } else {
      // If the node is empty, replace it instead
      Transforms.removeNodes(editor, { at: parentPath });
      Transforms.insertNodes(editor, image, { at: parentPath, select: true });
    }
  } else {
    // Insert the new image node at the bottom of the Editor when selection
    // is falsey
    Transforms.insertNodes(editor, image, { select: true });
  } */
};
