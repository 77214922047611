import {
  changePasswordByTokenApi,
  checkAccountByEmailApi,
  loginByEmailApi,
  loginForUser,
  logoutApi,
  registerApi,
  sendVerificationCodeApi,
} from '@api/auth.api';

import { AuthStep as AUTH_STEP } from 'respona_api/generated/common_pb';
import { LoginResponseType, VerificationEmailResponseType } from '@ts/auth.types';
import { AuthHandlerTypeMap, VerificationCodeTypeMap } from 'respona_api/generated/auth-shared_pb';

import { shutdown as shutdownIntercom } from 'src/libs/intercom';
import urls from '@constants/urls';
import { calendlyStatusTypes } from '@constants/onboardingPopupStatuses';
import gtm from 'src/libs/gtm';

class AuthService {
  loginCallback(response: LoginResponseType): LoginResponseType {
    const { sessionToken } = response;

    localStorage.setItem('token', sessionToken);

    if (response.authStep === AUTH_STEP.CAMPAIGN) {
      window.location.pathname = urls.CREATE_NEW_CAMPAIGN;
    } else if (response.authStep === AUTH_STEP.DASHBOARD) {
      window.location.pathname = urls.DASHBOARD;
    } else {
      window.location.pathname = '/';
    }

    if (
      response.firstLogin &&
      localStorage.getItem('showCalendly') !== calendlyStatusTypes.COMPLETE
    ) {
      localStorage.setItem('showCalendly', calendlyStatusTypes.AWAITING);
    }

    return response;
  }

  async checkAccountByEmail(email: string): Promise<VerificationEmailResponseType> {
    return checkAccountByEmailApi(email);
  }

  async sendVerificationCode(
    email: string,
    type: VerificationCodeTypeMap[keyof VerificationCodeTypeMap]
  ): Promise<void> {
    return sendVerificationCodeApi(email, type);
  }

  async login(email: string, password: string, captcha: string): Promise<LoginResponseType> {
    const promise = loginByEmailApi(email, password, captcha);
    promise.then((loginResponse) => {
      gtm.signIn(loginResponse.userId);
      this.loginCallback(loginResponse);
    });
    return promise;
  }

  async loginWithToken(
    token: string,
    type?: AuthHandlerTypeMap[keyof AuthHandlerTypeMap]
  ): Promise<LoginResponseType> {
    if (localStorage.getItem('token')) {
      await logoutApi();
      await localStorage.removeItem('token');
    }
    return loginForUser(token, type).then(this.loginCallback);
  }

  async changePasswordByToken(
    token: string,
    email: string,
    password: string
  ): Promise<LoginResponseType> {
    return changePasswordByTokenApi(token, email, password).then(this.loginCallback);
  }

  async register(
    email: string,
    password: string,
    fullName: string,
    captcha: string,
    code: string,
    ref?: string,
    utmTagPairs?: [string, string][],
    source?: string
  ): Promise<LoginResponseType> {
    return registerApi(email, password, fullName, captcha, code, ref, utmTagPairs, source);
  }

  async logout(): Promise<void> {
    return logoutApi().finally(() => {
      localStorage.removeItem('token');
      localStorage.removeItem('showCalendly');
      localStorage.removeItem('showEwebinar');
      shutdownIntercom();
      window.location.pathname = '/welcome';
    });
  }
}

export const authService = new AuthService();
