import { VariableType } from '@constants/variables';
import {
  CustomVariableType as CUSTOM_VARIABLE_TYPE,
  CustomVariableValueRelatedTo,
} from 'respona_api/generated/common_pb';

export const getDealsMockVariables = (): VariableType[] => {
  return [
    {
      id: -1,
      title: 'Website',
      value: 'website',
      transform: (fallback: string, _, opportunity: { url: string }): string => '',
      transformLink: (_, opportunity) => '',
      withFallback: true,
      isUrlVariable: false,

      name: 'Website',
      description: 'Website address',
      createdAt: -1,
      workspaceId: -1,
      type: CUSTOM_VARIABLE_TYPE.URL,
      relatedTo: CustomVariableValueRelatedTo.RELATIONSHIP_OPPORTUNITY,
      isDefault: true,
      openAi: undefined,
      select: undefined,
    },
    {
      id: -1,
      title: 'Contacts',
      value: 'Contacts',
      transform: (fallback: string, _, opportunity: { url: string }): string => '',
      transformLink: (_, opportunity) => '',
      withFallback: true,
      isUrlVariable: false,

      name: 'Contacts',
      description: 'Contacts',
      createdAt: -1,
      workspaceId: -1,
      type: CUSTOM_VARIABLE_TYPE.MULTI_SELECT,
      relatedTo: CustomVariableValueRelatedTo.RELATIONSHIP_OPPORTUNITY,
      isDefault: true,
      openAi: undefined,
      select: undefined,
    },
    {
      id: -1,
      title: 'Creation date',
      value: 'creation_date',
      transform: (fallback: string, _, opportunity: { url: string }): string => '',
      transformLink: (_, opportunity) => '',
      withFallback: true,
      isUrlVariable: false,

      name: 'Creation date',
      description: 'Creation date',
      createdAt: -1,
      workspaceId: -1,
      type: CUSTOM_VARIABLE_TYPE.DATE_AND_TIME,
      relatedTo: CustomVariableValueRelatedTo.RELATIONSHIP_OPPORTUNITY,
      isDefault: true,
      openAi: undefined,
      select: undefined,
    },
    {
      id: -1,
      title: 'Creator',
      value: 'creator',
      transform: (fallback: string, _, opportunity: { url: string }): string => '',
      transformLink: (_, opportunity) => '',
      withFallback: true,
      isUrlVariable: false,

      name: 'Creator',
      description: 'Creator',
      createdAt: -1,
      workspaceId: -1,
      type: CUSTOM_VARIABLE_TYPE.TEAM_MEMBER,
      relatedTo: CustomVariableValueRelatedTo.RELATIONSHIP_OPPORTUNITY,
      isDefault: true,
      openAi: undefined,
      select: undefined,
    },
    {
      id: -1,
      title: 'Assignee',
      value: 'Assignee',
      transform: (fallback: string, _, opportunity: { url: string }): string => '',
      transformLink: (_, opportunity) => '',
      withFallback: true,
      isUrlVariable: false,

      name: 'Assignee',
      description: 'Assignee',
      createdAt: -1,
      workspaceId: -1,
      type: CUSTOM_VARIABLE_TYPE.TEAM_MEMBER,
      relatedTo: CustomVariableValueRelatedTo.RELATIONSHIP_OPPORTUNITY,
      isDefault: true,
      openAi: undefined,
      select: undefined,
    },
  ];
};
