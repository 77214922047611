import React, { useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { SVGIcon } from '@uikit/Icon/Icon';
import Input from '@uikit/Input/Input';
import Loader from '@uikit/Loader/Loader';
import Button from '@uikit/Button/Button';
import IconNamePlaceholder from '@uikit/IconNamePlaceholder/IconNamePlaceholder';
import Display from '@components/Display/Display';

import useOutsideClick from '@hooks/useOutsideClick';
import { checkIsAnyLoading } from '@utils/loadingChecks';
import useHotkeys from '@hooks/useHotkeys';

import { getCurrentWorkspaceId, workspacesSelector } from '@redux/selectors/workspaces.selectors';
import { changeCurrentWorkspace } from '@redux/actions/workspaces.actions';
import { appStateSelector } from '@redux/selectors/appState.selectors';
import { toggleWorkspacesSidebar } from '@redux/actions/appState.actions';
import { clearSequenceTemplates, clearEmailTemplates } from '@redux/actions/templates.actions';

import './WorkspacesSidebar.scss';
import { addNotification } from '@redux/actions/notifications.actions';

function WorkspacesSidebar(): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const [searchString, changeSearchString] = useState('');
  const { isWorkspacesSidebarOpen } = useSelector(appStateSelector);
  const curWorkspaceId = useSelector(getCurrentWorkspaceId);
  const { workspaces, loadingStatus: workspacesLs } = useSelector(workspacesSelector);
  const workspacesSidebarRef = useRef();

  const handleCloseSidebar = () => {
    dispatch(toggleWorkspacesSidebar(false));
  };

  useOutsideClick(workspacesSidebarRef, handleCloseSidebar);

  useHotkeys({
    Escape: handleCloseSidebar,
  });

  if (!isWorkspacesSidebarOpen) {
    return null;
  }

  const workspacesFilter = workspaces
    .filter(({ title }) => title.toLowerCase().includes(searchString.toLowerCase()))
    .sort((a, b) => {
      if (a.favorite && !b.favorite) return -1;
      if (!a.favorite && b.favorite) return 1;
      return 0;
    });

  const handleChangeSearchString = ({ target: { value } }) => changeSearchString(value);

  const handleWorkspaceClick = (workspaceId: number, hasAccess: boolean) => {
    if (workspaceId === curWorkspaceId) {
      return;
    }
    if (!hasAccess) {
      dispatch(addNotification({ type: 'warning', title: 'You have no access to this workspace' }));
      return;
    }
    changeSearchString('');
    dispatch(changeCurrentWorkspace(workspaceId));
    dispatch(clearSequenceTemplates());
    dispatch(clearEmailTemplates());

    if (history.location.pathname.includes('campaign-builder')) {
      history.push('/campaigns/my-campaigns');
    }
  };

  return ReactDOM.createPortal(
    <div className="workspaces-sidebar" ref={workspacesSidebarRef}>
      <div className="workspaces-sidebar__header">
        Workspace
        <div className="workspaces-sidebar__close-icon" onClick={handleCloseSidebar}>
          <SVGIcon icon="crossBlack" />
        </div>
      </div>
      <div className="workspaces-sidebar__body">
        <div className="workspaces-sidebar__search">
          <Input
            value={searchString}
            onChange={handleChangeSearchString}
            icon="search"
            placeholder="Search"
            isFullWidth
          />
        </div>
        <Loader isLoading={checkIsAnyLoading(workspacesLs)} withTopMargin>
          <div className="workspaces-sidebar__workspaces-list">
            {workspacesFilter?.map((workspace) => {
              const access = true;
              return (
                <div
                  className="workspaces-sidebar__workspace-row"
                  key={workspace.id}
                  onClick={() => handleWorkspaceClick(workspace.id, access)}
                >
                  <IconNamePlaceholder
                    color="#737373"
                    size={40}
                    borderRadius="10px"
                    name={workspace.title}
                  />
                  <div className="workspaces-sidebar__workspace-info">
                    <div className="workspaces-sidebar__workspace-title" title={workspace.title}>
                      {workspace.title}
                      {/* <Button */}
                      {/*  type="ghost" */}
                      {/*  className="inbox-mail-thread__action" */}
                      {/*  onClick={(e) => onChangeFavoriteState(e, workspace)} */}
                      {/*  title={`${workspace.favorite ? 'Unmark' : 'Mark'} as starred`} */}
                      {/* > */}
                      {/*  <SVGIcon */}
                      {/*    icon="star" */}
                      {/*    color={workspace.favorite ? PRIMARY_COLOR : '#BDBDBD'} */}
                      {/*  /> */}
                      {/* </Button> */}
                    </div>
                    <div className="workspaces-sidebar__workspace-members">
                      {workspace.membersCount} members
                    </div>
                  </div>
                  {!access && (
                    <div className="workspaces-sidebar__warning-triangle">
                      <SVGIcon icon="warningTriangle" />
                    </div>
                  )}
                  <Display isVisible={workspace.id === curWorkspaceId}>
                    <div className="workspaces-sidebar__active-workspace">
                      <SVGIcon icon="check" color="#23E771" />
                    </div>
                  </Display>
                </div>
              );
            })}
          </div>
        </Loader>
        <div className="workspaces-sidebar__create-new-workspace">
          <Button href="/settings/create/workspace">+&nbsp; Create workspace</Button>
        </div>
      </div>
    </div>,
    document.body
  );
}

export default WorkspacesSidebar;
