import { MailboxAccountHealthServiceClient } from 'respona_api/generated/mailbox-account-health_pb_service';

const service = (function () {
  let instance = null;

  return {
    getInstance: (): MailboxAccountHealthServiceClient => {
      if (!instance) {
        // @ts-ignore
        instance = new MailboxAccountHealthServiceClient(API_HOST);
      }

      return instance;
    },
  };
})();

export default service;
