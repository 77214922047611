import React from 'react';
import { LIGHT_GREY_COLOR_FOR_ICONS } from '@constants/colors';

export default ({ color = LIGHT_GREY_COLOR_FOR_ICONS }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.85 10.1078H13.85V2.25781C13.85 2.0645 13.6933 1.9078 13.5 1.9078H2.5C2.30669 1.9078 2.14999 2.0645 2.14999 2.25781V10.2578C2.14999 10.4511 2.30669 10.6078 2.5 10.6078H4.83326H4.83349L13.85 10.1078ZM13.85 10.1078V10.2578M13.85 10.1078V10.2578M13.85 10.2578C13.85 10.4511 13.6933 10.6078 13.5 10.6078H7.83349H7.83346M13.85 10.2578L7.83346 10.6078M7.83346 10.6078C7.67282 10.6079 7.51794 10.6674 7.39861 10.7749M7.83346 10.6078L7.39861 10.7749M7.39861 10.7749L5.33465 12.6326L5.47956 11.33L7.39861 10.7749ZM2.5 11.9078H4.1074L3.85394 14.1862L3.85392 14.1864C3.8145 14.5432 4.07182 14.8644 4.42863 14.9038L4.42865 14.9038C4.61291 14.9241 4.79708 14.8648 4.93486 14.7408L8.08306 11.9078H13.5C14.4113 11.9078 15.15 11.1691 15.15 10.2578V2.25781C15.15 1.34654 14.4113 0.607812 13.5 0.607812H2.5C1.58873 0.607812 0.85 1.34654 0.85 2.25781V10.2578C0.85 11.1691 1.58873 11.9078 2.5 11.9078Z"
      fill={color}
      stroke={color}
      strokeWidth="0.6"
    />
  </svg>
);
