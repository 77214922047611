import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInfiniteQuery, useQueryClient } from 'react-query';

import {
  CampaignOpportunityFilterType,
  OpportunityDetailsType,
  OpportunityPageType,
} from '@ts/opportunity.types';

import { getActiveCampaignInfo } from '@redux/selectors/campaignSettings.selectors';

import {
  searchByDomainAndJobPositionApi,
  searchByDomainAndNameApi,
  searchBySocialLinkApi,
} from '@api/searchContacts.api';
import Display from '@components/Display/Display';
import { fetchBillingCredits } from '@redux/thunks/billings.thunks';

import Loader from '@uikit/Loader/Loader';

import useNonInitialEffect from '@hooks/useNonInitialEffect';

import NothingWasFound from '@components/CampaignSettings/ManualConfirmContactSideBar/_components/Plugs/NothingWasFound';
import ClickToShowMore from '@components/CampaignSettings/ManualConfirmContactSideBar/_components/Plugs/ClickToShowMore';
import { manualSearchTypes } from '@constants/manualSearch.constants';
import { DispatchType } from 'src/store';

import ContactsSearchField from '../ContactsSearchField/ContactsSearchField';
import ContactsSearchResultGroup from '../ContactsSearchResultGroup/ContactsSearchResultGroup';

import './ManualConfirmContactSideBar.scss';
import {
  billingDetailsSelector,
  billingMyRemainingCreditsSelector,
} from '@redux/selectors/billings.selectors';
import SectionSelect from '@uikit/SectionSelect/SectionSelect';
import {
  addContactToOpportunityApi,
  removeAllContactsByPersonIdAndOpportunityIdApi,
  removeOpportunityContactApi,
} from '@api/campaign.api';
import {
  csSetActiveCampaignVerifyStatus,
  csSetCampaignOpportunitiesContacts,
  csUpdateCampaignSelectedOpportunitiesVerificationStatus,
} from '@redux/actions/campaignSettings.actions';
import { addNotification } from '@redux/actions/notifications.actions';
import { OpportunityContactContainerType } from '@ts/contact.types';
import { SVGIcon } from '@uikit/Icon/Icon';
import { PersonBaseType } from '@ts/people.types';
import { FoundAtCategory } from 'respona_api/generated/common_pb';

const dafaultLocalStateValues = {
  searchString: '',
  searchingJobPosition: '',
};

function ManualConfirmContactSideBar({
  activeOpportunity,
  onClose,
  setUpsellCreditsSidebarOpened,
  setPaymentSidebarOpen,
  refetchActiveOpportunity,
  filters = [],
}: {
  activeOpportunity: OpportunityPageType;
  onClose: (value: boolean) => void;
  setUpsellCreditsSidebarOpened?: Dispatch<SetStateAction<boolean>>;
  setPaymentSidebarOpen?: Dispatch<SetStateAction<boolean>>;
  refetchActiveOpportunity?: () => void;
  filters?: CampaignOpportunityFilterType[];
}): JSX.Element {
  const dispatch: DispatchType = useDispatch();
  const queryClient = useQueryClient();

  const {
    info: { workspaceId, lastBatch },
  } = useSelector(getActiveCampaignInfo);

  const [searchType, changeSearchType] = useState(manualSearchTypes.domain);
  const [activeContacts, setActiveContacts] = useState(activeOpportunity.contactsList);

  const myRemainingCredits = useSelector(billingMyRemainingCreditsSelector);
  const { data: billingDetails } = useSelector(billingDetailsSelector);

  const [searchFields, changeSearchFields] = useState({
    domainString: '',
    jobPosition: '',
    fullName: '',
    linkedIn: '',
    seniority: [],
    department: [],
  });

  const [wasSearched, changeWasSearched] = useState(false);

  const { info: campaignInfo } = useSelector(getActiveCampaignInfo);

  const transformSearchResponse = (res) => res.map((item) => ({ ...item, category: 2 }));

  const { data, isLoading, refetch, fetchNextPage, isFetchingNextPage, hasNextPage } =
    useInfiniteQuery<any[]>(
      ['manual-contacts-search', activeOpportunity?.url, searchFields, searchType],
      ({ pageParam = 0 }) => {
        if (searchType === manualSearchTypes.domain && myRemainingCredits < 10) {
          if (billingDetails.state === 6) {
            setPaymentSidebarOpen(true);
          } else {
            setUpsellCreditsSidebarOpened(true);
          }
          return [];
        }
        return getSearchQuery(pageParam)
          .then(transformSearchResponse)
          .finally(() => {
            changeWasSearched(true);
            dispatch(fetchBillingCredits());
          });
      },
      {
        getNextPageParam: (lastPage: any[], allPages) => {
          if (lastPage.length < 10) return false;

          return allPages.length;
        },
        enabled: false,
        retry: 0,
        refetchOnWindowFocus: false,
      }
    );

  // @ts-ignore
  const flatContactsData: OpportunityPageType[] = data?.pages?.flat() || [];

  const getSearchQuery = (pageParam: number): Promise<any> => {
    switch (searchType) {
      case manualSearchTypes.domain:
        if (!searchFields.domainString.length) {
          return Promise.reject();
        }
        return searchByDomainAndJobPositionApi(
          searchFields.domainString,
          searchFields.jobPosition,
          campaignInfo.workspaceId,
          pageParam
        );
      case manualSearchTypes.name: {
        if (searchFields.fullName == null || !searchFields.fullName.length) {
          return Promise.reject();
        }
        return searchByDomainAndNameApi(
          searchFields.fullName,
          searchFields.domainString,
          campaignInfo.workspaceId
        );
      }
      case manualSearchTypes.linkedin: {
        return searchBySocialLinkApi(searchFields.linkedIn, campaignInfo.workspaceId);
      }
      default:
        return Promise.resolve([]);
    }
  };

  useEffect(() => {
    changeWasSearched(false);
  }, [activeOpportunity?.url]);

  const handleContactsSearch = (fields) => {
    refetch();
    changeSearchFields(fields);
  };

  useNonInitialEffect(() => {
    refetch();
  }, [searchFields]);

  useEffect(() => {
    changeWasSearched(false);
  }, [searchType]);

  const handleAddPersonEmails = (personId, personDetails) => {
    queryClient.setQueryData(
      ['manual-contacts-search', activeOpportunity.url, searchFields, searchType],
      ({ pageParams, pages }) => {
        const updatedPages = pages.map((page: any[]) => {
          return page.map((contact: any) => {
            return contact.id === personId
              ? { ...personDetails, totalItems: contact.totalItems }
              : contact;
          });
        });
        return { pageParams, pages: updatedPages };
      }
    );

    // Update wallet info
    dispatch(fetchBillingCredits());
  };

  const handleAddContact = async (payload: PersonBaseType, email: string, accuracy: number) => {
    addContactToOpportunityApi(
      workspaceId,
      activeOpportunity.id,
      payload.id,
      email,
      FoundAtCategory.FROM_MANUAL,
      campaignInfo.id,
      accuracy,
      lastBatch
    )
      .then((response) => {
        const needRefetchActiveOpportunity = activeContacts == null || activeContacts.length <= 0;
        try {
          setActiveContacts([...activeContacts, response]);
          dispatch(
            csUpdateCampaignSelectedOpportunitiesVerificationStatus({
              opportunityId: activeOpportunity.id,
              newStatus: 1,
            })
          );
          dispatch(csSetActiveCampaignVerifyStatus({ statusNumber: 0 }));
          dispatch(addNotification({ title: 'Contact successfully added', type: 'success' }));
        } catch (e) {
          console.error('Error updating data', e);
        }

        try {
          queryClient.setQueryData(
            ['opportunities-with-metrics', campaignInfo.id, campaignInfo.workspaceId, filters],
            ({ pageParams, pages }) => {
              const updatedPages = pages.map((page: OpportunityPageType[]) => {
                return page.map((opportunity: OpportunityPageType) => {
                  if (opportunity.id === activeOpportunity.id) {
                    opportunity.contactsList.push(response);
                    return opportunity;
                  }
                  return opportunity;
                });
              });
              return { pageParams, pages: updatedPages };
            }
          );
        } catch (e) {
          console.log('Error updating data with metrics', e);
        }
        try {
          queryClient.setQueryData(
            ['active-opportunity', activeOpportunity.id],
            (cachedOpp: OpportunityDetailsType) => {
              return {
                ...cachedOpp,
                contactsList: [...cachedOpp.contactsList, response],
              };
            }
          );
        } catch (e) {
          console.log('Error updating active opportunity', e);
        }
        if (needRefetchActiveOpportunity && refetchActiveOpportunity != null) {
          refetchActiveOpportunity();
        }
      })
      .catch((error) => {
        if (error.message.indexOf('Contact already exist with email') !== -1) {
          dispatch(
            addNotification({
              title:
                'You have already selected this contact in one of opportunities in this campaign',
              type: 'error',
            })
          );
        }
        return null;
      });
  };

  const handleRemovePersonContacts = async (personId: number) => {
    removeAllContactsByPersonIdAndOpportunityIdApi(
      activeOpportunity.id,
      workspaceId,
      personId
    ).then((response) => handleChangeContacts(response));
  };

  const handleRemoveOpportunityContact = (contactId: number) => {
    removeOpportunityContactApi(activeOpportunity.id, workspaceId, contactId).then((response) =>
      handleChangeContacts(response)
    );
  };

  const handleChangeContacts = ({ contactsList }: OpportunityContactContainerType) => {
    queryClient.setQueryData(
      ['opportunities-with-metrics', campaignInfo.id, campaignInfo.workspaceId, filters],
      ({ pageParams, pages }) => {
        const updatedPages =
          pages?.map((page: OpportunityPageType[]) => {
            return page?.map((opportunity: OpportunityPageType) => {
              if (opportunity.id === activeOpportunity.id) {
                return { ...opportunity, contactsList };
              }
              return opportunity;
            });
          }) || [];
        return { pageParams, pages: updatedPages };
      }
    );
    dispatch(
      csSetCampaignOpportunitiesContacts({
        opportunityId: activeOpportunity.id,
        contacts: contactsList,
      })
    );
    dispatch(
      csUpdateCampaignSelectedOpportunitiesVerificationStatus({
        opportunityId: activeOpportunity.id,
        newStatus: 1,
      })
    );
    dispatch(csSetActiveCampaignVerifyStatus({ statusNumber: 0 }));
    setActiveContacts(contactsList);
    dispatch(addNotification({ title: 'Contact successfully removed', type: 'success' }));
  };

  function composeInputLabel() {
    if (searchType === manualSearchTypes.domain) {
      return 'Job Titles';
    }
    return 'Full Name';
  }

  return (
    <div className="manual-confirm-contact-sidebar">
      <div
        className="manual-confirm-contact-sidebar__background"
        onClick={() => onClose(false)}
        tabIndex={0}
        role="button"
      />
      <div className="manual-confirm-contact-sidebar__sidebar">
        <span
          className="manual-confirm-contact-sidebar__cross-btn"
          onClick={() => onClose(true)}
          tabIndex={0}
          role="button"
        >
          <SVGIcon icon="crossBlack" />
        </span>

        <h1>Contact Finder</h1>
        <SectionSelect
          selectedOption={searchType}
          onChange={changeSearchType}
          options={[
            {
              title: 'Search by domain',
              key: manualSearchTypes.domain,
            },
            {
              title: 'Search by name',
              key: manualSearchTypes.name,
            },
            {
              title: 'Enter manually',
              key: manualSearchTypes.manually,
            },
          ]}
        />
        <div className="manual-confirm-contact-sidebar__content">
          <div className="manual-confirm-contact-sidebar__search">
            <ContactsSearchField
              onSearchContacts={handleContactsSearch}
              onAddContact={handleAddContact}
              searchType={searchType}
              activeOpportunity={activeOpportunity}
              isInputLabel={composeInputLabel()}
            />
            <Display
              isVisible={
                searchType !== manualSearchTypes.manually &&
                !wasSearched &&
                (flatContactsData?.length === 0 || !flatContactsData)
              }
            >
              <ClickToShowMore />
            </Display>
            <Loader isLoading={isLoading} withTopMargin>
              <Display
                isVisible={
                  searchType !== manualSearchTypes.manually &&
                  wasSearched &&
                  flatContactsData?.length === 0
                }
              >
                <NothingWasFound />
              </Display>
              <Display isVisible={flatContactsData?.length > 0}>
                <ContactsSearchResultGroup
                  title="Found results"
                  contacts={flatContactsData}
                  selectedContacts={activeContacts}
                  onAddEmails={handleAddPersonEmails}
                  onAddContact={handleAddContact}
                  onRemovePersonContact={handleRemovePersonContacts}
                  onRemoveOpportunityContact={handleRemoveOpportunityContact}
                  searchFields={searchFields}
                  searchType={searchType}
                  domain={searchFields.domainString}
                  fetchNextPage={fetchNextPage}
                  isFetchingNextPage={isFetchingNextPage}
                  hasNextPage={hasNextPage}
                />
              </Display>
            </Loader>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManualConfirmContactSideBar;
