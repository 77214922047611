import React from 'react';

export default ({ color = '#212121', size = 18 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5238 1.38091C10.5238 1.38091 8.23805 0.619001 6.71424 2.14281L2.14281 6.71424C0.619001 8.23805 1.38091 10.5238 1.38091 10.5238"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M14.3333 5.19043C14.3333 5.19043 15.0952 7.47614 13.5714 8.99995L8.99995 13.5714C7.47614 15.0952 5.19043 14.3333 5.19043 14.3333"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M5.57143 10.1428L1 14.7143"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M14.7143 1L10.1428 5.57143"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M14.7142 12.4285V16.9999"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M12.4286 14.7144H17"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);
