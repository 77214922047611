export default (url = ''): string => {
  const regex = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?]+(?:\.[^:/\n?]+)*)(?:\/|$)/g;
  const match = regex.exec(url);

  if (match && match.length >= 2) {
    return match[1];
  }

  return '';
};
