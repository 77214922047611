import { SVGIcon } from '@uikit/Icon/Icon';
import React from 'react';

function MapAttributesStepFieldReset({ onClick, hide }) {
  const className = `map-attribute-step${hide ? '' : '__field-reset'}`;
  const iconColor = hide ? 'transparent' : '#ED483D';
  return (
    <div className={className} onClick={!hide ? onClick : undefined}>
      <SVGIcon icon="trash" color={iconColor} />
    </div>
  );
}

export default MapAttributesStepFieldReset;
