import { Dispatch } from 'redux';
import {
  addOpportunityApi,
  removeOpportunityApi,
  getOpenCampaignApi,
  addOpportunitiesBatchApi,
  removeOpportunitiesBatchApi,
} from '@api/campaign.api';
import loadingStatuses from '@constants/loadingStatuses';
import {
  csAddCampaignOpportunities,
  csRemoveCampaignOpportunities,
  csSetOpenCampaignInfo,
  csSetActiveCampaignLs,
  csSetOpenCampaignError,
  csSetActiveCampaignVerifyStatus,
} from '@redux/actions/campaignSettings.actions';
import { SearchItemType } from '@ts/contentSearch.types';
import { CampaignOpportunityFilterType, OpportunityDetailsType } from '@ts/opportunity.types';

export const addOpportunity = (dispatch, campaignId, workspaceId, lastBatch, opportunity) => {
  return addOpportunityApi(campaignId, workspaceId, lastBatch, opportunity).then(({ id }) => {
    dispatch(csAddCampaignOpportunities([{ id, ...opportunity }]));
    dispatch(csSetActiveCampaignVerifyStatus({ statusNumber: 0 }));
  });
};

export const addOpportunitiesBatch =
  (campaignId: number, workspaceId: number, lastBatch: number, opportunities: SearchItemType[]) =>
  (dispatch) => {
    return addOpportunitiesBatchApi(campaignId, workspaceId, lastBatch, opportunities).then(
      ({ itemsList }) => {
        const opportunitiesForSaving = itemsList.reduce((acc, { uid, id, success }) => {
          if (!success) {
            return acc;
          }

          return [
            ...acc,
            {
              id,
              ...opportunities.find(({ uid: tmpUid }) => uid === tmpUid),
            },
          ];
        }, []);

        dispatch(csAddCampaignOpportunities(opportunitiesForSaving));
        dispatch(csSetActiveCampaignVerifyStatus({ statusNumber: 0 }));
      }
    );
  };

export const removeOpportunity =
  (
    campaignId: number,
    workspaceId: number,
    batch: number,
    opportunity: Partial<OpportunityDetailsType>
  ) =>
  (dispatch: Dispatch) => {
    return removeOpportunityApi(campaignId, workspaceId, batch, opportunity.uid).then(() => {
      dispatch(csSetActiveCampaignVerifyStatus({ statusNumber: 0 }));
      return dispatch(csRemoveCampaignOpportunities([opportunity.id]));
    });
  };

export const removeOpportunitiesBatch =
  (
    campaignId: number,
    workspaceId: number,
    lastBatch: number,
    removingIds: number[] = [],
    filters: CampaignOpportunityFilterType[]
  ) =>
  async (dispatch) => {
    await dispatch(csRemoveCampaignOpportunities(removingIds));
    await removeOpportunitiesBatchApi(campaignId, workspaceId, lastBatch, removingIds, filters);
  };

export const fetchOpenCampaign =
  (campaignId: number, workspaceId: number, silent = false) =>
  (dispatch: Dispatch): Promise<void> => {
    if (!silent) {
      dispatch(csSetActiveCampaignLs(loadingStatuses.INITED));
    }

    return getOpenCampaignApi(campaignId, workspaceId)
      .then((openCampaignInfo) => {
        dispatch(csSetOpenCampaignInfo(openCampaignInfo));
        dispatch(csSetActiveCampaignLs(loadingStatuses.LOADED));
      })
      .catch((err) => {
        dispatch(csSetOpenCampaignError(err));
      });
  };
