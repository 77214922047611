import { SummaryContainerResponse } from 'respona_api/generated/search-summary_pb';
import { SearchItem } from 'respona_api/generated/search-content_pb';
import { CS_SET_ACTIVE_CAMPAIGN_INFO } from '../actions/campaignSettings.actions';
import {
  SET_ARTICLE_SUMMARY,
  SET_ARTICLE_SUMMARY_LS,
  CREATE_ARTICLE_SUMMARY,
  SET_AUDIO_INFO,
  SET_AUDIO_INFO_LS,
} from '../actions/articleSummary.actions';

import loadingStatuses from '../../constants/loadingStatuses';

export type ArticleSummaryStateType = {
  [opportunityId: number]: {
    summaryLoadingStatus: string;
    summary: SummaryContainerResponse.AsObject;
    audioInfo: SearchItem.AsObject;
    audioLoadingStatus: string;
    isPodcast?: boolean;
  };
};

const defaultState: ArticleSummaryStateType = {};

export const initialArticleStateInfo = {
  summary: {},
};

export default (state = defaultState, { type, payload }): ArticleSummaryStateType => {
  switch (type) {
    case CS_SET_ACTIVE_CAMPAIGN_INFO:
      return defaultState;
    case CREATE_ARTICLE_SUMMARY:
      return {
        ...state,
        [payload.opportunityId]: {
          summaryLoadingStatus: loadingStatuses.INITED,
          summary: null,
          audioInfo: null,
          audioLoadingStatus: loadingStatuses.INITED,
          isPodcast: payload.isPodcast,
        },
      };
    case SET_ARTICLE_SUMMARY_LS:
      return {
        ...state,
        [payload.opportunityId]: {
          ...state[payload.opportunityId],
          summaryLoadingStatus: payload.loadingStatus,
        },
      };
    case SET_AUDIO_INFO_LS:
      return {
        ...state,
        [payload.opportunityId]: {
          ...state[payload.opportunityId],
          audioLoadingStatus: payload.loadingStatus,
          isPodcast: true,
        },
      };
    case SET_ARTICLE_SUMMARY:
      return {
        ...state,
        [payload.opportunityId]: {
          ...state[payload.opportunityId],
          summary: payload.summary,
        },
      };
    case SET_AUDIO_INFO:
      return {
        ...state,
        [payload.opportunityId]: {
          ...state[payload.opportunityId],
          audioInfo: {
            ...payload.data.info,
            requested: payload.data.requested,
          },
        },
      };
    default:
      return state;
  }
};
