import React from 'react';
import { addNotification } from '@redux/actions/notifications.actions';
import { useDispatch } from 'react-redux';
import { Button } from '@uikit/Button/Button';
import stripHtml from '@utils/stripHtml';
import { TemplateStepTypeWithTempId } from '@ts/template.types';

type SaveButtonProps = {
  stepsNewState: TemplateStepTypeWithTempId[];
  isTemplateTitle: boolean;
  isSaveButtonDisabled: boolean;
  onSaveChangesToServer: () => void;
};

const SaveButton = ({
  stepsNewState,
  isTemplateTitle,
  isSaveButtonDisabled,
  onSaveChangesToServer,
}: SaveButtonProps): JSX.Element => {
  const dispatch = useDispatch();

  const isAllStepsWithContent = stepsNewState.every((step: TemplateStepTypeWithTempId) => {
    return !!(stripHtml(step.threadA.content).trim() || stripHtml(step.threadA.subject).trim());
  });

  return (
    <Button
      type="primary"
      onClick={onSaveChangesToServer}
      isLoading={isSaveButtonDisabled}
      disabled={isSaveButtonDisabled || !isAllStepsWithContent || !isTemplateTitle}
      onClickDisabled={() => {
        !isAllStepsWithContent &&
          dispatch(
            addNotification({
              title: 'Please, input some content into all steps first',
              type: 'warning',
            })
          );
        !isTemplateTitle &&
          dispatch(
            addNotification({
              title: 'Please, input template title',
              type: 'warning',
            })
          );
      }}
    >
      Save
    </Button>
  );
};

export default SaveButton;
