import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';

import RightSidebar from '@components/RightSidebar/RightSidebar';
import Input from '@uikit/Input/Input';
import Display from '@components/Display/Display';
import useHotkeys from '@hooks/useHotkeys';

import './OrganizationInfoSidebar.scss';

export const defaultCompanyInfo = {
  title: '',
  website: '',
  domainAuthority: 0,
};

const fieldsConfig = {
  title: {
    title: 'Organization Name',
    placeholder: 'Insert organization name',
    type: 'text',
    icon: 'building',
  },
  website: {
    title: 'Website',
    placeholder: 'Insert website',
    type: 'text',
    icon: 'building',
  },
  domainAuthority: {
    title: 'Domain Rating',
    placeholder: 'Insert domain rating',
    type: 'number',
    icon: 'link',
    inputProps: {
      min: '0',
      max: '100',
    },
  },
};

const validate = (values) => {
  const errors: {
    domainAuthority?: string;
    website?: string;
    title?: string;
  } = {};

  if (values.website.indexOf('.') === -1) {
    errors.website = 'Invalid website';
  }

  if (values.website.trim() === '') {
    errors.website = 'Field is required';
  }

  if (values.title.trim() === '') {
    errors.title = 'Field is required';
  }

  if (values.domainAuthority === '') {
    errors.domainAuthority = 'Field is required';
  }

  if (Number(values.domainAuthority) < 0 || Number(values.domainAuthority) > 100) {
    errors.domainAuthority = 'Value should be between 0 and 100';
  }

  return errors;
};

function OrganizationInfoSidebar({ isOpen, onClose, fields, onSave }) {
  const [companyFields, changeCompanyFields] = useState(null);
  useEffect(() => {
    if (fields) {
      if (Object.keys(fields).length === 0) {
        changeCompanyFields(defaultCompanyInfo);
      } else {
        changeCompanyFields(fields);
      }
    }
  }, [fields]);

  useHotkeys({
    Escape: () => onClose(),
  });

  if (!fields || !companyFields) {
    return null;
  }

  return (
    <Formik
      initialValues={companyFields}
      onSubmit={(values) => {
        onSave(values);
      }}
      validate={validate}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ values, handleChange, handleSubmit, errors }) => (
        <RightSidebar
          title="Add Organization"
          isOpen={isOpen}
          onClose={onClose}
          onSave={handleSubmit}
        >
          <div className="organization-info-sidebar">
            {Object.entries(fieldsConfig).map(([fieldKey, fieldValue]) => (
              <div key={fieldKey} className="organization-info-sidebar__row">
                <div className="organization-info-sidebar__field-label">
                  {fieldsConfig[fieldKey].title}
                </div>
                <Input
                  name={fieldKey}
                  icon={fieldsConfig[fieldKey].icon}
                  type={fieldsConfig[fieldKey].type}
                  value={values[fieldKey]}
                  iconColor="#251FB6"
                  onChange={handleChange}
                  isFullWidth
                  placeholder={fieldsConfig[fieldKey].placeholder}
                  {...fieldsConfig[fieldKey].inputProps}
                />
                <Display isVisible={Boolean(errors[fieldKey])}>
                  <div className="organization-info-sidebar__error">{errors[fieldKey]}</div>
                </Display>
              </div>
            ))}
          </div>
        </RightSidebar>
      )}
    </Formik>
  );
}

export default OrganizationInfoSidebar;
