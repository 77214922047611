// source: automation-content.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
goog.exportSymbol('proto.automation.BacklinksSearch', null, global);
goog.exportSymbol('proto.automation.ContentAutomationPresetContainer', null, global);
goog.exportSymbol('proto.automation.ContentAutomationRequest', null, global);
goog.exportSymbol('proto.automation.ContentAutomationSearchingMode', null, global);
goog.exportSymbol('proto.automation.KeywordSearch', null, global);
goog.exportSymbol('proto.automation.KeywordSearchSource', null, global);
goog.exportSymbol('proto.automation.LaunchContentAutomationRequest', null, global);
goog.exportSymbol('proto.automation.LaunchContentAutomationRequest.MetricsCase', null, global);
goog.exportSymbol('proto.automation.LaunchContentAutomationRequest.SearchCase', null, global);
goog.exportSymbol('proto.automation.PodcastSearch', null, global);
goog.exportSymbol('proto.automation.PodcastSearchSource', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.automation.ContentAutomationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.automation.ContentAutomationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.automation.ContentAutomationRequest.displayName = 'proto.automation.ContentAutomationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.automation.KeywordSearch = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.automation.KeywordSearch.repeatedFields_, null);
};
goog.inherits(proto.automation.KeywordSearch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.automation.KeywordSearch.displayName = 'proto.automation.KeywordSearch';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.automation.BacklinksSearch = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.automation.BacklinksSearch.repeatedFields_, null);
};
goog.inherits(proto.automation.BacklinksSearch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.automation.BacklinksSearch.displayName = 'proto.automation.BacklinksSearch';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.automation.PodcastSearch = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.automation.PodcastSearch.repeatedFields_, null);
};
goog.inherits(proto.automation.PodcastSearch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.automation.PodcastSearch.displayName = 'proto.automation.PodcastSearch';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.automation.ContentAutomationPresetContainer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.automation.ContentAutomationPresetContainer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.automation.ContentAutomationPresetContainer.displayName = 'proto.automation.ContentAutomationPresetContainer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.automation.LaunchContentAutomationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.automation.LaunchContentAutomationRequest.oneofGroups_);
};
goog.inherits(proto.automation.LaunchContentAutomationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.automation.LaunchContentAutomationRequest.displayName = 'proto.automation.LaunchContentAutomationRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.automation.ContentAutomationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.automation.ContentAutomationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.automation.ContentAutomationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.ContentAutomationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pipelineId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.automation.ContentAutomationRequest}
 */
proto.automation.ContentAutomationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.automation.ContentAutomationRequest;
  return proto.automation.ContentAutomationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.automation.ContentAutomationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.automation.ContentAutomationRequest}
 */
proto.automation.ContentAutomationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPipelineId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.automation.ContentAutomationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.automation.ContentAutomationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.automation.ContentAutomationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.ContentAutomationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPipelineId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 campaign_id = 1;
 * @return {number}
 */
proto.automation.ContentAutomationRequest.prototype.getCampaignId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.ContentAutomationRequest} returns this
 */
proto.automation.ContentAutomationRequest.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 workspace_id = 2;
 * @return {number}
 */
proto.automation.ContentAutomationRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.ContentAutomationRequest} returns this
 */
proto.automation.ContentAutomationRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 pipeline_id = 3;
 * @return {number}
 */
proto.automation.ContentAutomationRequest.prototype.getPipelineId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.ContentAutomationRequest} returns this
 */
proto.automation.ContentAutomationRequest.prototype.setPipelineId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.automation.KeywordSearch.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.automation.KeywordSearch.prototype.toObject = function(opt_includeInstance) {
  return proto.automation.KeywordSearch.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.automation.KeywordSearch} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.KeywordSearch.toObject = function(includeInstance, msg) {
  var f, obj = {
    source: jspb.Message.getFieldWithDefault(msg, 1, 0),
    queriesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    analyzeResultsNumber: jspb.Message.getFieldWithDefault(msg, 3, 0),
    location: jspb.Message.getFieldWithDefault(msg, 4, ""),
    language: jspb.Message.getFieldWithDefault(msg, 5, ""),
    oneResultPerDomain: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    ignoreAlreadyContacted: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    optionalIgnoreAlreadyContactedDays: jspb.Message.getFieldWithDefault(msg, 8, 0),
    ignoreUnsubscribed: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    sortBy: jspb.Message.getFieldWithDefault(msg, 10, 0),
    ignoreActive: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    optionalFreshnessValue: (f = msg.getOptionalFreshnessValue()) && common_pb.TimestampRange.toObject(includeInstance, f),
    ignoreHomepages: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    optionalUnlinkedDomain: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.automation.KeywordSearch}
 */
proto.automation.KeywordSearch.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.automation.KeywordSearch;
  return proto.automation.KeywordSearch.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.automation.KeywordSearch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.automation.KeywordSearch}
 */
proto.automation.KeywordSearch.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.automation.KeywordSearchSource} */ (reader.readEnum());
      msg.setSource(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addQueries(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAnalyzeResultsNumber(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocation(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguage(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOneResultPerDomain(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIgnoreAlreadyContacted(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOptionalIgnoreAlreadyContactedDays(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIgnoreUnsubscribed(value);
      break;
    case 10:
      var value = /** @type {!proto.common.ContentSearchOrderBy} */ (reader.readEnum());
      msg.setSortBy(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIgnoreActive(value);
      break;
    case 12:
      var value = new common_pb.TimestampRange;
      reader.readMessage(value,common_pb.TimestampRange.deserializeBinaryFromReader);
      msg.setOptionalFreshnessValue(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIgnoreHomepages(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionalUnlinkedDomain(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.automation.KeywordSearch.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.automation.KeywordSearch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.automation.KeywordSearch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.KeywordSearch.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSource();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getQueriesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getAnalyzeResultsNumber();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getLocation();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLanguage();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOneResultPerDomain();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getIgnoreAlreadyContacted();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getOptionalIgnoreAlreadyContactedDays();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getIgnoreUnsubscribed();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getSortBy();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getIgnoreActive();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getOptionalFreshnessValue();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      common_pb.TimestampRange.serializeBinaryToWriter
    );
  }
  f = message.getIgnoreHomepages();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getOptionalUnlinkedDomain();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional KeywordSearchSource source = 1;
 * @return {!proto.automation.KeywordSearchSource}
 */
proto.automation.KeywordSearch.prototype.getSource = function() {
  return /** @type {!proto.automation.KeywordSearchSource} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.automation.KeywordSearchSource} value
 * @return {!proto.automation.KeywordSearch} returns this
 */
proto.automation.KeywordSearch.prototype.setSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated string queries = 2;
 * @return {!Array<string>}
 */
proto.automation.KeywordSearch.prototype.getQueriesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.automation.KeywordSearch} returns this
 */
proto.automation.KeywordSearch.prototype.setQueriesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.automation.KeywordSearch} returns this
 */
proto.automation.KeywordSearch.prototype.addQueries = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.automation.KeywordSearch} returns this
 */
proto.automation.KeywordSearch.prototype.clearQueriesList = function() {
  return this.setQueriesList([]);
};


/**
 * optional int32 analyze_results_number = 3;
 * @return {number}
 */
proto.automation.KeywordSearch.prototype.getAnalyzeResultsNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.KeywordSearch} returns this
 */
proto.automation.KeywordSearch.prototype.setAnalyzeResultsNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string location = 4;
 * @return {string}
 */
proto.automation.KeywordSearch.prototype.getLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.automation.KeywordSearch} returns this
 */
proto.automation.KeywordSearch.prototype.setLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string language = 5;
 * @return {string}
 */
proto.automation.KeywordSearch.prototype.getLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.automation.KeywordSearch} returns this
 */
proto.automation.KeywordSearch.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool one_result_per_domain = 6;
 * @return {boolean}
 */
proto.automation.KeywordSearch.prototype.getOneResultPerDomain = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.automation.KeywordSearch} returns this
 */
proto.automation.KeywordSearch.prototype.setOneResultPerDomain = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool ignore_already_contacted = 7;
 * @return {boolean}
 */
proto.automation.KeywordSearch.prototype.getIgnoreAlreadyContacted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.automation.KeywordSearch} returns this
 */
proto.automation.KeywordSearch.prototype.setIgnoreAlreadyContacted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional int32 optional_ignore_already_contacted_days = 8;
 * @return {number}
 */
proto.automation.KeywordSearch.prototype.getOptionalIgnoreAlreadyContactedDays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.KeywordSearch} returns this
 */
proto.automation.KeywordSearch.prototype.setOptionalIgnoreAlreadyContactedDays = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional bool ignore_unsubscribed = 9;
 * @return {boolean}
 */
proto.automation.KeywordSearch.prototype.getIgnoreUnsubscribed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.automation.KeywordSearch} returns this
 */
proto.automation.KeywordSearch.prototype.setIgnoreUnsubscribed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional common.ContentSearchOrderBy sort_by = 10;
 * @return {!proto.common.ContentSearchOrderBy}
 */
proto.automation.KeywordSearch.prototype.getSortBy = function() {
  return /** @type {!proto.common.ContentSearchOrderBy} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.common.ContentSearchOrderBy} value
 * @return {!proto.automation.KeywordSearch} returns this
 */
proto.automation.KeywordSearch.prototype.setSortBy = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional bool ignore_active = 11;
 * @return {boolean}
 */
proto.automation.KeywordSearch.prototype.getIgnoreActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.automation.KeywordSearch} returns this
 */
proto.automation.KeywordSearch.prototype.setIgnoreActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional common.TimestampRange optional_freshness_value = 12;
 * @return {?proto.common.TimestampRange}
 */
proto.automation.KeywordSearch.prototype.getOptionalFreshnessValue = function() {
  return /** @type{?proto.common.TimestampRange} */ (
    jspb.Message.getWrapperField(this, common_pb.TimestampRange, 12));
};


/**
 * @param {?proto.common.TimestampRange|undefined} value
 * @return {!proto.automation.KeywordSearch} returns this
*/
proto.automation.KeywordSearch.prototype.setOptionalFreshnessValue = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.automation.KeywordSearch} returns this
 */
proto.automation.KeywordSearch.prototype.clearOptionalFreshnessValue = function() {
  return this.setOptionalFreshnessValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.automation.KeywordSearch.prototype.hasOptionalFreshnessValue = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional bool ignore_homepages = 13;
 * @return {boolean}
 */
proto.automation.KeywordSearch.prototype.getIgnoreHomepages = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.automation.KeywordSearch} returns this
 */
proto.automation.KeywordSearch.prototype.setIgnoreHomepages = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional string optional_unlinked_domain = 14;
 * @return {string}
 */
proto.automation.KeywordSearch.prototype.getOptionalUnlinkedDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.automation.KeywordSearch} returns this
 */
proto.automation.KeywordSearch.prototype.setOptionalUnlinkedDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.automation.BacklinksSearch.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.automation.BacklinksSearch.prototype.toObject = function(opt_includeInstance) {
  return proto.automation.BacklinksSearch.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.automation.BacklinksSearch} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.BacklinksSearch.toObject = function(includeInstance, msg) {
  var f, obj = {
    source: jspb.Message.getFieldWithDefault(msg, 1, 0),
    urlsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    analyzeResultsNumber: jspb.Message.getFieldWithDefault(msg, 3, 0),
    rel: jspb.Message.getFieldWithDefault(msg, 4, 0),
    optionalAnchor: jspb.Message.getFieldWithDefault(msg, 5, ""),
    optionalTargetUrl: jspb.Message.getFieldWithDefault(msg, 6, ""),
    optionalSourceUrl: jspb.Message.getFieldWithDefault(msg, 7, ""),
    oneResultPerDomain: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    ignoreAlreadyContacted: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    optionalIgnoreAlreadyContactedDays: jspb.Message.getFieldWithDefault(msg, 10, 0),
    ignoreUnsubscribed: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    ignoreActive: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    sortBy: jspb.Message.getFieldWithDefault(msg, 13, 0),
    ignoreHomepages: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    linkStatus: jspb.Message.getFieldWithDefault(msg, 15, 0),
    optionalUnlinkedDomain: jspb.Message.getFieldWithDefault(msg, 16, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.automation.BacklinksSearch}
 */
proto.automation.BacklinksSearch.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.automation.BacklinksSearch;
  return proto.automation.BacklinksSearch.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.automation.BacklinksSearch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.automation.BacklinksSearch}
 */
proto.automation.BacklinksSearch.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.BacklinksSearchSource} */ (reader.readEnum());
      msg.setSource(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addUrls(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAnalyzeResultsNumber(value);
      break;
    case 4:
      var value = /** @type {!proto.common.BacklinksRel} */ (reader.readEnum());
      msg.setRel(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionalAnchor(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionalTargetUrl(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionalSourceUrl(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOneResultPerDomain(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIgnoreAlreadyContacted(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOptionalIgnoreAlreadyContactedDays(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIgnoreUnsubscribed(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIgnoreActive(value);
      break;
    case 13:
      var value = /** @type {!proto.common.ContentSearchOrderBy} */ (reader.readEnum());
      msg.setSortBy(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIgnoreHomepages(value);
      break;
    case 15:
      var value = /** @type {!proto.common.BacklinkStatus} */ (reader.readEnum());
      msg.setLinkStatus(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionalUnlinkedDomain(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.automation.BacklinksSearch.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.automation.BacklinksSearch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.automation.BacklinksSearch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.BacklinksSearch.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSource();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getUrlsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getAnalyzeResultsNumber();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getRel();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getOptionalAnchor();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOptionalTargetUrl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOptionalSourceUrl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getOneResultPerDomain();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getIgnoreAlreadyContacted();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getOptionalIgnoreAlreadyContactedDays();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getIgnoreUnsubscribed();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getIgnoreActive();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getSortBy();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getIgnoreHomepages();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getLinkStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getOptionalUnlinkedDomain();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
};


/**
 * optional common.BacklinksSearchSource source = 1;
 * @return {!proto.common.BacklinksSearchSource}
 */
proto.automation.BacklinksSearch.prototype.getSource = function() {
  return /** @type {!proto.common.BacklinksSearchSource} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.BacklinksSearchSource} value
 * @return {!proto.automation.BacklinksSearch} returns this
 */
proto.automation.BacklinksSearch.prototype.setSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated string urls = 2;
 * @return {!Array<string>}
 */
proto.automation.BacklinksSearch.prototype.getUrlsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.automation.BacklinksSearch} returns this
 */
proto.automation.BacklinksSearch.prototype.setUrlsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.automation.BacklinksSearch} returns this
 */
proto.automation.BacklinksSearch.prototype.addUrls = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.automation.BacklinksSearch} returns this
 */
proto.automation.BacklinksSearch.prototype.clearUrlsList = function() {
  return this.setUrlsList([]);
};


/**
 * optional int32 analyze_results_number = 3;
 * @return {number}
 */
proto.automation.BacklinksSearch.prototype.getAnalyzeResultsNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.BacklinksSearch} returns this
 */
proto.automation.BacklinksSearch.prototype.setAnalyzeResultsNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional common.BacklinksRel rel = 4;
 * @return {!proto.common.BacklinksRel}
 */
proto.automation.BacklinksSearch.prototype.getRel = function() {
  return /** @type {!proto.common.BacklinksRel} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.common.BacklinksRel} value
 * @return {!proto.automation.BacklinksSearch} returns this
 */
proto.automation.BacklinksSearch.prototype.setRel = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string optional_anchor = 5;
 * @return {string}
 */
proto.automation.BacklinksSearch.prototype.getOptionalAnchor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.automation.BacklinksSearch} returns this
 */
proto.automation.BacklinksSearch.prototype.setOptionalAnchor = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string optional_target_url = 6;
 * @return {string}
 */
proto.automation.BacklinksSearch.prototype.getOptionalTargetUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.automation.BacklinksSearch} returns this
 */
proto.automation.BacklinksSearch.prototype.setOptionalTargetUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string optional_source_url = 7;
 * @return {string}
 */
proto.automation.BacklinksSearch.prototype.getOptionalSourceUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.automation.BacklinksSearch} returns this
 */
proto.automation.BacklinksSearch.prototype.setOptionalSourceUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool one_result_per_domain = 8;
 * @return {boolean}
 */
proto.automation.BacklinksSearch.prototype.getOneResultPerDomain = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.automation.BacklinksSearch} returns this
 */
proto.automation.BacklinksSearch.prototype.setOneResultPerDomain = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool ignore_already_contacted = 9;
 * @return {boolean}
 */
proto.automation.BacklinksSearch.prototype.getIgnoreAlreadyContacted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.automation.BacklinksSearch} returns this
 */
proto.automation.BacklinksSearch.prototype.setIgnoreAlreadyContacted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional int32 optional_ignore_already_contacted_days = 10;
 * @return {number}
 */
proto.automation.BacklinksSearch.prototype.getOptionalIgnoreAlreadyContactedDays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.BacklinksSearch} returns this
 */
proto.automation.BacklinksSearch.prototype.setOptionalIgnoreAlreadyContactedDays = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional bool ignore_unsubscribed = 11;
 * @return {boolean}
 */
proto.automation.BacklinksSearch.prototype.getIgnoreUnsubscribed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.automation.BacklinksSearch} returns this
 */
proto.automation.BacklinksSearch.prototype.setIgnoreUnsubscribed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool ignore_active = 12;
 * @return {boolean}
 */
proto.automation.BacklinksSearch.prototype.getIgnoreActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.automation.BacklinksSearch} returns this
 */
proto.automation.BacklinksSearch.prototype.setIgnoreActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional common.ContentSearchOrderBy sort_by = 13;
 * @return {!proto.common.ContentSearchOrderBy}
 */
proto.automation.BacklinksSearch.prototype.getSortBy = function() {
  return /** @type {!proto.common.ContentSearchOrderBy} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.common.ContentSearchOrderBy} value
 * @return {!proto.automation.BacklinksSearch} returns this
 */
proto.automation.BacklinksSearch.prototype.setSortBy = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional bool ignore_homepages = 14;
 * @return {boolean}
 */
proto.automation.BacklinksSearch.prototype.getIgnoreHomepages = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.automation.BacklinksSearch} returns this
 */
proto.automation.BacklinksSearch.prototype.setIgnoreHomepages = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional common.BacklinkStatus link_status = 15;
 * @return {!proto.common.BacklinkStatus}
 */
proto.automation.BacklinksSearch.prototype.getLinkStatus = function() {
  return /** @type {!proto.common.BacklinkStatus} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.common.BacklinkStatus} value
 * @return {!proto.automation.BacklinksSearch} returns this
 */
proto.automation.BacklinksSearch.prototype.setLinkStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * optional string optional_unlinked_domain = 16;
 * @return {string}
 */
proto.automation.BacklinksSearch.prototype.getOptionalUnlinkedDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.automation.BacklinksSearch} returns this
 */
proto.automation.BacklinksSearch.prototype.setOptionalUnlinkedDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.automation.PodcastSearch.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.automation.PodcastSearch.prototype.toObject = function(opt_includeInstance) {
  return proto.automation.PodcastSearch.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.automation.PodcastSearch} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.PodcastSearch.toObject = function(includeInstance, msg) {
  var f, obj = {
    source: jspb.Message.getFieldWithDefault(msg, 1, 0),
    keywordsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    analyzeResultsNumber: jspb.Message.getFieldWithDefault(msg, 3, 0),
    location: jspb.Message.getFieldWithDefault(msg, 4, ""),
    language: jspb.Message.getFieldWithDefault(msg, 5, ""),
    oneResultPerDomain: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    ignoreAlreadyContacted: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    optionalIgnoreAlreadyContactedDays: jspb.Message.getFieldWithDefault(msg, 8, 0),
    ignoreUnsubscribed: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    ignoreActive: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    createContacts: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.automation.PodcastSearch}
 */
proto.automation.PodcastSearch.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.automation.PodcastSearch;
  return proto.automation.PodcastSearch.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.automation.PodcastSearch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.automation.PodcastSearch}
 */
proto.automation.PodcastSearch.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.automation.PodcastSearchSource} */ (reader.readEnum());
      msg.setSource(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addKeywords(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAnalyzeResultsNumber(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocation(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguage(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOneResultPerDomain(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIgnoreAlreadyContacted(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOptionalIgnoreAlreadyContactedDays(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIgnoreUnsubscribed(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIgnoreActive(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCreateContacts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.automation.PodcastSearch.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.automation.PodcastSearch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.automation.PodcastSearch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.PodcastSearch.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSource();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getKeywordsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getAnalyzeResultsNumber();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getLocation();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLanguage();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOneResultPerDomain();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getIgnoreAlreadyContacted();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getOptionalIgnoreAlreadyContactedDays();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getIgnoreUnsubscribed();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getIgnoreActive();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getCreateContacts();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * optional PodcastSearchSource source = 1;
 * @return {!proto.automation.PodcastSearchSource}
 */
proto.automation.PodcastSearch.prototype.getSource = function() {
  return /** @type {!proto.automation.PodcastSearchSource} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.automation.PodcastSearchSource} value
 * @return {!proto.automation.PodcastSearch} returns this
 */
proto.automation.PodcastSearch.prototype.setSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated string keywords = 2;
 * @return {!Array<string>}
 */
proto.automation.PodcastSearch.prototype.getKeywordsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.automation.PodcastSearch} returns this
 */
proto.automation.PodcastSearch.prototype.setKeywordsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.automation.PodcastSearch} returns this
 */
proto.automation.PodcastSearch.prototype.addKeywords = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.automation.PodcastSearch} returns this
 */
proto.automation.PodcastSearch.prototype.clearKeywordsList = function() {
  return this.setKeywordsList([]);
};


/**
 * optional int32 analyze_results_number = 3;
 * @return {number}
 */
proto.automation.PodcastSearch.prototype.getAnalyzeResultsNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.PodcastSearch} returns this
 */
proto.automation.PodcastSearch.prototype.setAnalyzeResultsNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string location = 4;
 * @return {string}
 */
proto.automation.PodcastSearch.prototype.getLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.automation.PodcastSearch} returns this
 */
proto.automation.PodcastSearch.prototype.setLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string language = 5;
 * @return {string}
 */
proto.automation.PodcastSearch.prototype.getLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.automation.PodcastSearch} returns this
 */
proto.automation.PodcastSearch.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool one_result_per_domain = 6;
 * @return {boolean}
 */
proto.automation.PodcastSearch.prototype.getOneResultPerDomain = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.automation.PodcastSearch} returns this
 */
proto.automation.PodcastSearch.prototype.setOneResultPerDomain = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool ignore_already_contacted = 7;
 * @return {boolean}
 */
proto.automation.PodcastSearch.prototype.getIgnoreAlreadyContacted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.automation.PodcastSearch} returns this
 */
proto.automation.PodcastSearch.prototype.setIgnoreAlreadyContacted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional int32 optional_ignore_already_contacted_days = 8;
 * @return {number}
 */
proto.automation.PodcastSearch.prototype.getOptionalIgnoreAlreadyContactedDays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.PodcastSearch} returns this
 */
proto.automation.PodcastSearch.prototype.setOptionalIgnoreAlreadyContactedDays = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional bool ignore_unsubscribed = 9;
 * @return {boolean}
 */
proto.automation.PodcastSearch.prototype.getIgnoreUnsubscribed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.automation.PodcastSearch} returns this
 */
proto.automation.PodcastSearch.prototype.setIgnoreUnsubscribed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool ignore_active = 10;
 * @return {boolean}
 */
proto.automation.PodcastSearch.prototype.getIgnoreActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.automation.PodcastSearch} returns this
 */
proto.automation.PodcastSearch.prototype.setIgnoreActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool create_contacts = 11;
 * @return {boolean}
 */
proto.automation.PodcastSearch.prototype.getCreateContacts = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.automation.PodcastSearch} returns this
 */
proto.automation.PodcastSearch.prototype.setCreateContacts = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.automation.ContentAutomationPresetContainer.prototype.toObject = function(opt_includeInstance) {
  return proto.automation.ContentAutomationPresetContainer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.automation.ContentAutomationPresetContainer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.ContentAutomationPresetContainer.toObject = function(includeInstance, msg) {
  var f, obj = {
    presetMode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    presetType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    ahrefs: (f = msg.getAhrefs()) && common_pb.AhrefMetrics.toObject(includeInstance, f),
    semrush: (f = msg.getSemrush()) && common_pb.SemrushMetrics.toObject(includeInstance, f),
    moz: (f = msg.getMoz()) && common_pb.MozMetrics.toObject(includeInstance, f),
    listennotes: (f = msg.getListennotes()) && common_pb.ListennotesMetrics.toObject(includeInstance, f),
    googleSearch: (f = msg.getGoogleSearch()) && proto.automation.KeywordSearch.toObject(includeInstance, f),
    googleNews: (f = msg.getGoogleNews()) && proto.automation.KeywordSearch.toObject(includeInstance, f),
    bingSearch: (f = msg.getBingSearch()) && proto.automation.KeywordSearch.toObject(includeInstance, f),
    bingNews: (f = msg.getBingNews()) && proto.automation.KeywordSearch.toObject(includeInstance, f),
    ahrefBacklinks: (f = msg.getAhrefBacklinks()) && proto.automation.BacklinksSearch.toObject(includeInstance, f),
    semrushBacklinks: (f = msg.getSemrushBacklinks()) && proto.automation.BacklinksSearch.toObject(includeInstance, f),
    mozBacklinks: (f = msg.getMozBacklinks()) && proto.automation.BacklinksSearch.toObject(includeInstance, f),
    responaBacklinks: (f = msg.getResponaBacklinks()) && proto.automation.BacklinksSearch.toObject(includeInstance, f),
    podcast: (f = msg.getPodcast()) && proto.automation.PodcastSearch.toObject(includeInstance, f),
    episode: (f = msg.getEpisode()) && proto.automation.PodcastSearch.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.automation.ContentAutomationPresetContainer}
 */
proto.automation.ContentAutomationPresetContainer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.automation.ContentAutomationPresetContainer;
  return proto.automation.ContentAutomationPresetContainer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.automation.ContentAutomationPresetContainer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.automation.ContentAutomationPresetContainer}
 */
proto.automation.ContentAutomationPresetContainer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.automation.ContentAutomationSearchingMode} */ (reader.readEnum());
      msg.setPresetMode(value);
      break;
    case 2:
      var value = /** @type {!proto.common.ContentSearchType} */ (reader.readEnum());
      msg.setPresetType(value);
      break;
    case 3:
      var value = new common_pb.AhrefMetrics;
      reader.readMessage(value,common_pb.AhrefMetrics.deserializeBinaryFromReader);
      msg.setAhrefs(value);
      break;
    case 4:
      var value = new common_pb.SemrushMetrics;
      reader.readMessage(value,common_pb.SemrushMetrics.deserializeBinaryFromReader);
      msg.setSemrush(value);
      break;
    case 5:
      var value = new common_pb.MozMetrics;
      reader.readMessage(value,common_pb.MozMetrics.deserializeBinaryFromReader);
      msg.setMoz(value);
      break;
    case 6:
      var value = new common_pb.ListennotesMetrics;
      reader.readMessage(value,common_pb.ListennotesMetrics.deserializeBinaryFromReader);
      msg.setListennotes(value);
      break;
    case 7:
      var value = new proto.automation.KeywordSearch;
      reader.readMessage(value,proto.automation.KeywordSearch.deserializeBinaryFromReader);
      msg.setGoogleSearch(value);
      break;
    case 8:
      var value = new proto.automation.KeywordSearch;
      reader.readMessage(value,proto.automation.KeywordSearch.deserializeBinaryFromReader);
      msg.setGoogleNews(value);
      break;
    case 9:
      var value = new proto.automation.KeywordSearch;
      reader.readMessage(value,proto.automation.KeywordSearch.deserializeBinaryFromReader);
      msg.setBingSearch(value);
      break;
    case 10:
      var value = new proto.automation.KeywordSearch;
      reader.readMessage(value,proto.automation.KeywordSearch.deserializeBinaryFromReader);
      msg.setBingNews(value);
      break;
    case 11:
      var value = new proto.automation.BacklinksSearch;
      reader.readMessage(value,proto.automation.BacklinksSearch.deserializeBinaryFromReader);
      msg.setAhrefBacklinks(value);
      break;
    case 12:
      var value = new proto.automation.BacklinksSearch;
      reader.readMessage(value,proto.automation.BacklinksSearch.deserializeBinaryFromReader);
      msg.setSemrushBacklinks(value);
      break;
    case 13:
      var value = new proto.automation.BacklinksSearch;
      reader.readMessage(value,proto.automation.BacklinksSearch.deserializeBinaryFromReader);
      msg.setMozBacklinks(value);
      break;
    case 14:
      var value = new proto.automation.BacklinksSearch;
      reader.readMessage(value,proto.automation.BacklinksSearch.deserializeBinaryFromReader);
      msg.setResponaBacklinks(value);
      break;
    case 15:
      var value = new proto.automation.PodcastSearch;
      reader.readMessage(value,proto.automation.PodcastSearch.deserializeBinaryFromReader);
      msg.setPodcast(value);
      break;
    case 16:
      var value = new proto.automation.PodcastSearch;
      reader.readMessage(value,proto.automation.PodcastSearch.deserializeBinaryFromReader);
      msg.setEpisode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.automation.ContentAutomationPresetContainer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.automation.ContentAutomationPresetContainer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.automation.ContentAutomationPresetContainer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.ContentAutomationPresetContainer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPresetMode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getPresetType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getAhrefs();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.AhrefMetrics.serializeBinaryToWriter
    );
  }
  f = message.getSemrush();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_pb.SemrushMetrics.serializeBinaryToWriter
    );
  }
  f = message.getMoz();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      common_pb.MozMetrics.serializeBinaryToWriter
    );
  }
  f = message.getListennotes();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      common_pb.ListennotesMetrics.serializeBinaryToWriter
    );
  }
  f = message.getGoogleSearch();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.automation.KeywordSearch.serializeBinaryToWriter
    );
  }
  f = message.getGoogleNews();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.automation.KeywordSearch.serializeBinaryToWriter
    );
  }
  f = message.getBingSearch();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.automation.KeywordSearch.serializeBinaryToWriter
    );
  }
  f = message.getBingNews();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.automation.KeywordSearch.serializeBinaryToWriter
    );
  }
  f = message.getAhrefBacklinks();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.automation.BacklinksSearch.serializeBinaryToWriter
    );
  }
  f = message.getSemrushBacklinks();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.automation.BacklinksSearch.serializeBinaryToWriter
    );
  }
  f = message.getMozBacklinks();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.automation.BacklinksSearch.serializeBinaryToWriter
    );
  }
  f = message.getResponaBacklinks();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.automation.BacklinksSearch.serializeBinaryToWriter
    );
  }
  f = message.getPodcast();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.automation.PodcastSearch.serializeBinaryToWriter
    );
  }
  f = message.getEpisode();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.automation.PodcastSearch.serializeBinaryToWriter
    );
  }
};


/**
 * optional ContentAutomationSearchingMode preset_mode = 1;
 * @return {!proto.automation.ContentAutomationSearchingMode}
 */
proto.automation.ContentAutomationPresetContainer.prototype.getPresetMode = function() {
  return /** @type {!proto.automation.ContentAutomationSearchingMode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.automation.ContentAutomationSearchingMode} value
 * @return {!proto.automation.ContentAutomationPresetContainer} returns this
 */
proto.automation.ContentAutomationPresetContainer.prototype.setPresetMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional common.ContentSearchType preset_type = 2;
 * @return {!proto.common.ContentSearchType}
 */
proto.automation.ContentAutomationPresetContainer.prototype.getPresetType = function() {
  return /** @type {!proto.common.ContentSearchType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.ContentSearchType} value
 * @return {!proto.automation.ContentAutomationPresetContainer} returns this
 */
proto.automation.ContentAutomationPresetContainer.prototype.setPresetType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional common.AhrefMetrics ahrefs = 3;
 * @return {?proto.common.AhrefMetrics}
 */
proto.automation.ContentAutomationPresetContainer.prototype.getAhrefs = function() {
  return /** @type{?proto.common.AhrefMetrics} */ (
    jspb.Message.getWrapperField(this, common_pb.AhrefMetrics, 3));
};


/**
 * @param {?proto.common.AhrefMetrics|undefined} value
 * @return {!proto.automation.ContentAutomationPresetContainer} returns this
*/
proto.automation.ContentAutomationPresetContainer.prototype.setAhrefs = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.automation.ContentAutomationPresetContainer} returns this
 */
proto.automation.ContentAutomationPresetContainer.prototype.clearAhrefs = function() {
  return this.setAhrefs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.automation.ContentAutomationPresetContainer.prototype.hasAhrefs = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional common.SemrushMetrics semrush = 4;
 * @return {?proto.common.SemrushMetrics}
 */
proto.automation.ContentAutomationPresetContainer.prototype.getSemrush = function() {
  return /** @type{?proto.common.SemrushMetrics} */ (
    jspb.Message.getWrapperField(this, common_pb.SemrushMetrics, 4));
};


/**
 * @param {?proto.common.SemrushMetrics|undefined} value
 * @return {!proto.automation.ContentAutomationPresetContainer} returns this
*/
proto.automation.ContentAutomationPresetContainer.prototype.setSemrush = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.automation.ContentAutomationPresetContainer} returns this
 */
proto.automation.ContentAutomationPresetContainer.prototype.clearSemrush = function() {
  return this.setSemrush(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.automation.ContentAutomationPresetContainer.prototype.hasSemrush = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional common.MozMetrics moz = 5;
 * @return {?proto.common.MozMetrics}
 */
proto.automation.ContentAutomationPresetContainer.prototype.getMoz = function() {
  return /** @type{?proto.common.MozMetrics} */ (
    jspb.Message.getWrapperField(this, common_pb.MozMetrics, 5));
};


/**
 * @param {?proto.common.MozMetrics|undefined} value
 * @return {!proto.automation.ContentAutomationPresetContainer} returns this
*/
proto.automation.ContentAutomationPresetContainer.prototype.setMoz = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.automation.ContentAutomationPresetContainer} returns this
 */
proto.automation.ContentAutomationPresetContainer.prototype.clearMoz = function() {
  return this.setMoz(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.automation.ContentAutomationPresetContainer.prototype.hasMoz = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional common.ListennotesMetrics listennotes = 6;
 * @return {?proto.common.ListennotesMetrics}
 */
proto.automation.ContentAutomationPresetContainer.prototype.getListennotes = function() {
  return /** @type{?proto.common.ListennotesMetrics} */ (
    jspb.Message.getWrapperField(this, common_pb.ListennotesMetrics, 6));
};


/**
 * @param {?proto.common.ListennotesMetrics|undefined} value
 * @return {!proto.automation.ContentAutomationPresetContainer} returns this
*/
proto.automation.ContentAutomationPresetContainer.prototype.setListennotes = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.automation.ContentAutomationPresetContainer} returns this
 */
proto.automation.ContentAutomationPresetContainer.prototype.clearListennotes = function() {
  return this.setListennotes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.automation.ContentAutomationPresetContainer.prototype.hasListennotes = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional KeywordSearch google_search = 7;
 * @return {?proto.automation.KeywordSearch}
 */
proto.automation.ContentAutomationPresetContainer.prototype.getGoogleSearch = function() {
  return /** @type{?proto.automation.KeywordSearch} */ (
    jspb.Message.getWrapperField(this, proto.automation.KeywordSearch, 7));
};


/**
 * @param {?proto.automation.KeywordSearch|undefined} value
 * @return {!proto.automation.ContentAutomationPresetContainer} returns this
*/
proto.automation.ContentAutomationPresetContainer.prototype.setGoogleSearch = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.automation.ContentAutomationPresetContainer} returns this
 */
proto.automation.ContentAutomationPresetContainer.prototype.clearGoogleSearch = function() {
  return this.setGoogleSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.automation.ContentAutomationPresetContainer.prototype.hasGoogleSearch = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional KeywordSearch google_news = 8;
 * @return {?proto.automation.KeywordSearch}
 */
proto.automation.ContentAutomationPresetContainer.prototype.getGoogleNews = function() {
  return /** @type{?proto.automation.KeywordSearch} */ (
    jspb.Message.getWrapperField(this, proto.automation.KeywordSearch, 8));
};


/**
 * @param {?proto.automation.KeywordSearch|undefined} value
 * @return {!proto.automation.ContentAutomationPresetContainer} returns this
*/
proto.automation.ContentAutomationPresetContainer.prototype.setGoogleNews = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.automation.ContentAutomationPresetContainer} returns this
 */
proto.automation.ContentAutomationPresetContainer.prototype.clearGoogleNews = function() {
  return this.setGoogleNews(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.automation.ContentAutomationPresetContainer.prototype.hasGoogleNews = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional KeywordSearch bing_search = 9;
 * @return {?proto.automation.KeywordSearch}
 */
proto.automation.ContentAutomationPresetContainer.prototype.getBingSearch = function() {
  return /** @type{?proto.automation.KeywordSearch} */ (
    jspb.Message.getWrapperField(this, proto.automation.KeywordSearch, 9));
};


/**
 * @param {?proto.automation.KeywordSearch|undefined} value
 * @return {!proto.automation.ContentAutomationPresetContainer} returns this
*/
proto.automation.ContentAutomationPresetContainer.prototype.setBingSearch = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.automation.ContentAutomationPresetContainer} returns this
 */
proto.automation.ContentAutomationPresetContainer.prototype.clearBingSearch = function() {
  return this.setBingSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.automation.ContentAutomationPresetContainer.prototype.hasBingSearch = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional KeywordSearch bing_news = 10;
 * @return {?proto.automation.KeywordSearch}
 */
proto.automation.ContentAutomationPresetContainer.prototype.getBingNews = function() {
  return /** @type{?proto.automation.KeywordSearch} */ (
    jspb.Message.getWrapperField(this, proto.automation.KeywordSearch, 10));
};


/**
 * @param {?proto.automation.KeywordSearch|undefined} value
 * @return {!proto.automation.ContentAutomationPresetContainer} returns this
*/
proto.automation.ContentAutomationPresetContainer.prototype.setBingNews = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.automation.ContentAutomationPresetContainer} returns this
 */
proto.automation.ContentAutomationPresetContainer.prototype.clearBingNews = function() {
  return this.setBingNews(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.automation.ContentAutomationPresetContainer.prototype.hasBingNews = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional BacklinksSearch ahref_backlinks = 11;
 * @return {?proto.automation.BacklinksSearch}
 */
proto.automation.ContentAutomationPresetContainer.prototype.getAhrefBacklinks = function() {
  return /** @type{?proto.automation.BacklinksSearch} */ (
    jspb.Message.getWrapperField(this, proto.automation.BacklinksSearch, 11));
};


/**
 * @param {?proto.automation.BacklinksSearch|undefined} value
 * @return {!proto.automation.ContentAutomationPresetContainer} returns this
*/
proto.automation.ContentAutomationPresetContainer.prototype.setAhrefBacklinks = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.automation.ContentAutomationPresetContainer} returns this
 */
proto.automation.ContentAutomationPresetContainer.prototype.clearAhrefBacklinks = function() {
  return this.setAhrefBacklinks(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.automation.ContentAutomationPresetContainer.prototype.hasAhrefBacklinks = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional BacklinksSearch semrush_backlinks = 12;
 * @return {?proto.automation.BacklinksSearch}
 */
proto.automation.ContentAutomationPresetContainer.prototype.getSemrushBacklinks = function() {
  return /** @type{?proto.automation.BacklinksSearch} */ (
    jspb.Message.getWrapperField(this, proto.automation.BacklinksSearch, 12));
};


/**
 * @param {?proto.automation.BacklinksSearch|undefined} value
 * @return {!proto.automation.ContentAutomationPresetContainer} returns this
*/
proto.automation.ContentAutomationPresetContainer.prototype.setSemrushBacklinks = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.automation.ContentAutomationPresetContainer} returns this
 */
proto.automation.ContentAutomationPresetContainer.prototype.clearSemrushBacklinks = function() {
  return this.setSemrushBacklinks(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.automation.ContentAutomationPresetContainer.prototype.hasSemrushBacklinks = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional BacklinksSearch moz_backlinks = 13;
 * @return {?proto.automation.BacklinksSearch}
 */
proto.automation.ContentAutomationPresetContainer.prototype.getMozBacklinks = function() {
  return /** @type{?proto.automation.BacklinksSearch} */ (
    jspb.Message.getWrapperField(this, proto.automation.BacklinksSearch, 13));
};


/**
 * @param {?proto.automation.BacklinksSearch|undefined} value
 * @return {!proto.automation.ContentAutomationPresetContainer} returns this
*/
proto.automation.ContentAutomationPresetContainer.prototype.setMozBacklinks = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.automation.ContentAutomationPresetContainer} returns this
 */
proto.automation.ContentAutomationPresetContainer.prototype.clearMozBacklinks = function() {
  return this.setMozBacklinks(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.automation.ContentAutomationPresetContainer.prototype.hasMozBacklinks = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional BacklinksSearch respona_backlinks = 14;
 * @return {?proto.automation.BacklinksSearch}
 */
proto.automation.ContentAutomationPresetContainer.prototype.getResponaBacklinks = function() {
  return /** @type{?proto.automation.BacklinksSearch} */ (
    jspb.Message.getWrapperField(this, proto.automation.BacklinksSearch, 14));
};


/**
 * @param {?proto.automation.BacklinksSearch|undefined} value
 * @return {!proto.automation.ContentAutomationPresetContainer} returns this
*/
proto.automation.ContentAutomationPresetContainer.prototype.setResponaBacklinks = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.automation.ContentAutomationPresetContainer} returns this
 */
proto.automation.ContentAutomationPresetContainer.prototype.clearResponaBacklinks = function() {
  return this.setResponaBacklinks(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.automation.ContentAutomationPresetContainer.prototype.hasResponaBacklinks = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional PodcastSearch podcast = 15;
 * @return {?proto.automation.PodcastSearch}
 */
proto.automation.ContentAutomationPresetContainer.prototype.getPodcast = function() {
  return /** @type{?proto.automation.PodcastSearch} */ (
    jspb.Message.getWrapperField(this, proto.automation.PodcastSearch, 15));
};


/**
 * @param {?proto.automation.PodcastSearch|undefined} value
 * @return {!proto.automation.ContentAutomationPresetContainer} returns this
*/
proto.automation.ContentAutomationPresetContainer.prototype.setPodcast = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.automation.ContentAutomationPresetContainer} returns this
 */
proto.automation.ContentAutomationPresetContainer.prototype.clearPodcast = function() {
  return this.setPodcast(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.automation.ContentAutomationPresetContainer.prototype.hasPodcast = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional PodcastSearch episode = 16;
 * @return {?proto.automation.PodcastSearch}
 */
proto.automation.ContentAutomationPresetContainer.prototype.getEpisode = function() {
  return /** @type{?proto.automation.PodcastSearch} */ (
    jspb.Message.getWrapperField(this, proto.automation.PodcastSearch, 16));
};


/**
 * @param {?proto.automation.PodcastSearch|undefined} value
 * @return {!proto.automation.ContentAutomationPresetContainer} returns this
*/
proto.automation.ContentAutomationPresetContainer.prototype.setEpisode = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.automation.ContentAutomationPresetContainer} returns this
 */
proto.automation.ContentAutomationPresetContainer.prototype.clearEpisode = function() {
  return this.setEpisode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.automation.ContentAutomationPresetContainer.prototype.hasEpisode = function() {
  return jspb.Message.getField(this, 16) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.automation.LaunchContentAutomationRequest.oneofGroups_ = [[6,7,8],[9,10,11,12]];

/**
 * @enum {number}
 */
proto.automation.LaunchContentAutomationRequest.SearchCase = {
  SEARCH_NOT_SET: 0,
  KEYWORDS: 6,
  BACKLINKS: 7,
  PODCAST: 8
};

/**
 * @return {proto.automation.LaunchContentAutomationRequest.SearchCase}
 */
proto.automation.LaunchContentAutomationRequest.prototype.getSearchCase = function() {
  return /** @type {proto.automation.LaunchContentAutomationRequest.SearchCase} */(jspb.Message.computeOneofCase(this, proto.automation.LaunchContentAutomationRequest.oneofGroups_[0]));
};

/**
 * @enum {number}
 */
proto.automation.LaunchContentAutomationRequest.MetricsCase = {
  METRICS_NOT_SET: 0,
  AHREFS: 9,
  SEMRUSH: 10,
  LISTENNOTES: 11,
  MOZ: 12
};

/**
 * @return {proto.automation.LaunchContentAutomationRequest.MetricsCase}
 */
proto.automation.LaunchContentAutomationRequest.prototype.getMetricsCase = function() {
  return /** @type {proto.automation.LaunchContentAutomationRequest.MetricsCase} */(jspb.Message.computeOneofCase(this, proto.automation.LaunchContentAutomationRequest.oneofGroups_[1]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.automation.LaunchContentAutomationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.automation.LaunchContentAutomationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.automation.LaunchContentAutomationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.LaunchContentAutomationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    batch: jspb.Message.getFieldWithDefault(msg, 3, 0),
    searchMode: jspb.Message.getFieldWithDefault(msg, 4, 0),
    metricsType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    keywords: (f = msg.getKeywords()) && proto.automation.KeywordSearch.toObject(includeInstance, f),
    backlinks: (f = msg.getBacklinks()) && proto.automation.BacklinksSearch.toObject(includeInstance, f),
    podcast: (f = msg.getPodcast()) && proto.automation.PodcastSearch.toObject(includeInstance, f),
    ahrefs: (f = msg.getAhrefs()) && common_pb.AhrefMetrics.toObject(includeInstance, f),
    semrush: (f = msg.getSemrush()) && common_pb.SemrushMetrics.toObject(includeInstance, f),
    listennotes: (f = msg.getListennotes()) && common_pb.ListennotesMetrics.toObject(includeInstance, f),
    moz: (f = msg.getMoz()) && common_pb.MozMetrics.toObject(includeInstance, f),
    campaignPipelineId: jspb.Message.getFieldWithDefault(msg, 13, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 14, 0),
    organizationId: jspb.Message.getFieldWithDefault(msg, 15, 0),
    automationId: jspb.Message.getFieldWithDefault(msg, 16, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.automation.LaunchContentAutomationRequest}
 */
proto.automation.LaunchContentAutomationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.automation.LaunchContentAutomationRequest;
  return proto.automation.LaunchContentAutomationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.automation.LaunchContentAutomationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.automation.LaunchContentAutomationRequest}
 */
proto.automation.LaunchContentAutomationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBatch(value);
      break;
    case 4:
      var value = /** @type {!proto.automation.ContentAutomationSearchingMode} */ (reader.readEnum());
      msg.setSearchMode(value);
      break;
    case 5:
      var value = /** @type {!proto.common.ContentMetricsType} */ (reader.readEnum());
      msg.setMetricsType(value);
      break;
    case 6:
      var value = new proto.automation.KeywordSearch;
      reader.readMessage(value,proto.automation.KeywordSearch.deserializeBinaryFromReader);
      msg.setKeywords(value);
      break;
    case 7:
      var value = new proto.automation.BacklinksSearch;
      reader.readMessage(value,proto.automation.BacklinksSearch.deserializeBinaryFromReader);
      msg.setBacklinks(value);
      break;
    case 8:
      var value = new proto.automation.PodcastSearch;
      reader.readMessage(value,proto.automation.PodcastSearch.deserializeBinaryFromReader);
      msg.setPodcast(value);
      break;
    case 9:
      var value = new common_pb.AhrefMetrics;
      reader.readMessage(value,common_pb.AhrefMetrics.deserializeBinaryFromReader);
      msg.setAhrefs(value);
      break;
    case 10:
      var value = new common_pb.SemrushMetrics;
      reader.readMessage(value,common_pb.SemrushMetrics.deserializeBinaryFromReader);
      msg.setSemrush(value);
      break;
    case 11:
      var value = new common_pb.ListennotesMetrics;
      reader.readMessage(value,common_pb.ListennotesMetrics.deserializeBinaryFromReader);
      msg.setListennotes(value);
      break;
    case 12:
      var value = new common_pb.MozMetrics;
      reader.readMessage(value,common_pb.MozMetrics.deserializeBinaryFromReader);
      msg.setMoz(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignPipelineId(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrganizationId(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAutomationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.automation.LaunchContentAutomationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.automation.LaunchContentAutomationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.automation.LaunchContentAutomationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.LaunchContentAutomationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getBatch();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getSearchMode();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getMetricsType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getKeywords();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.automation.KeywordSearch.serializeBinaryToWriter
    );
  }
  f = message.getBacklinks();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.automation.BacklinksSearch.serializeBinaryToWriter
    );
  }
  f = message.getPodcast();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.automation.PodcastSearch.serializeBinaryToWriter
    );
  }
  f = message.getAhrefs();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      common_pb.AhrefMetrics.serializeBinaryToWriter
    );
  }
  f = message.getSemrush();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      common_pb.SemrushMetrics.serializeBinaryToWriter
    );
  }
  f = message.getListennotes();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      common_pb.ListennotesMetrics.serializeBinaryToWriter
    );
  }
  f = message.getMoz();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      common_pb.MozMetrics.serializeBinaryToWriter
    );
  }
  f = message.getCampaignPipelineId();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getOrganizationId();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getAutomationId();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
};


/**
 * optional int64 campaign_id = 1;
 * @return {number}
 */
proto.automation.LaunchContentAutomationRequest.prototype.getCampaignId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.LaunchContentAutomationRequest} returns this
 */
proto.automation.LaunchContentAutomationRequest.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 workspace_id = 2;
 * @return {number}
 */
proto.automation.LaunchContentAutomationRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.LaunchContentAutomationRequest} returns this
 */
proto.automation.LaunchContentAutomationRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 batch = 3;
 * @return {number}
 */
proto.automation.LaunchContentAutomationRequest.prototype.getBatch = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.LaunchContentAutomationRequest} returns this
 */
proto.automation.LaunchContentAutomationRequest.prototype.setBatch = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional ContentAutomationSearchingMode search_mode = 4;
 * @return {!proto.automation.ContentAutomationSearchingMode}
 */
proto.automation.LaunchContentAutomationRequest.prototype.getSearchMode = function() {
  return /** @type {!proto.automation.ContentAutomationSearchingMode} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.automation.ContentAutomationSearchingMode} value
 * @return {!proto.automation.LaunchContentAutomationRequest} returns this
 */
proto.automation.LaunchContentAutomationRequest.prototype.setSearchMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional common.ContentMetricsType metrics_type = 5;
 * @return {!proto.common.ContentMetricsType}
 */
proto.automation.LaunchContentAutomationRequest.prototype.getMetricsType = function() {
  return /** @type {!proto.common.ContentMetricsType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.common.ContentMetricsType} value
 * @return {!proto.automation.LaunchContentAutomationRequest} returns this
 */
proto.automation.LaunchContentAutomationRequest.prototype.setMetricsType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional KeywordSearch keywords = 6;
 * @return {?proto.automation.KeywordSearch}
 */
proto.automation.LaunchContentAutomationRequest.prototype.getKeywords = function() {
  return /** @type{?proto.automation.KeywordSearch} */ (
    jspb.Message.getWrapperField(this, proto.automation.KeywordSearch, 6));
};


/**
 * @param {?proto.automation.KeywordSearch|undefined} value
 * @return {!proto.automation.LaunchContentAutomationRequest} returns this
*/
proto.automation.LaunchContentAutomationRequest.prototype.setKeywords = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.automation.LaunchContentAutomationRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.automation.LaunchContentAutomationRequest} returns this
 */
proto.automation.LaunchContentAutomationRequest.prototype.clearKeywords = function() {
  return this.setKeywords(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.automation.LaunchContentAutomationRequest.prototype.hasKeywords = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional BacklinksSearch backlinks = 7;
 * @return {?proto.automation.BacklinksSearch}
 */
proto.automation.LaunchContentAutomationRequest.prototype.getBacklinks = function() {
  return /** @type{?proto.automation.BacklinksSearch} */ (
    jspb.Message.getWrapperField(this, proto.automation.BacklinksSearch, 7));
};


/**
 * @param {?proto.automation.BacklinksSearch|undefined} value
 * @return {!proto.automation.LaunchContentAutomationRequest} returns this
*/
proto.automation.LaunchContentAutomationRequest.prototype.setBacklinks = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.automation.LaunchContentAutomationRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.automation.LaunchContentAutomationRequest} returns this
 */
proto.automation.LaunchContentAutomationRequest.prototype.clearBacklinks = function() {
  return this.setBacklinks(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.automation.LaunchContentAutomationRequest.prototype.hasBacklinks = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional PodcastSearch podcast = 8;
 * @return {?proto.automation.PodcastSearch}
 */
proto.automation.LaunchContentAutomationRequest.prototype.getPodcast = function() {
  return /** @type{?proto.automation.PodcastSearch} */ (
    jspb.Message.getWrapperField(this, proto.automation.PodcastSearch, 8));
};


/**
 * @param {?proto.automation.PodcastSearch|undefined} value
 * @return {!proto.automation.LaunchContentAutomationRequest} returns this
*/
proto.automation.LaunchContentAutomationRequest.prototype.setPodcast = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.automation.LaunchContentAutomationRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.automation.LaunchContentAutomationRequest} returns this
 */
proto.automation.LaunchContentAutomationRequest.prototype.clearPodcast = function() {
  return this.setPodcast(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.automation.LaunchContentAutomationRequest.prototype.hasPodcast = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional common.AhrefMetrics ahrefs = 9;
 * @return {?proto.common.AhrefMetrics}
 */
proto.automation.LaunchContentAutomationRequest.prototype.getAhrefs = function() {
  return /** @type{?proto.common.AhrefMetrics} */ (
    jspb.Message.getWrapperField(this, common_pb.AhrefMetrics, 9));
};


/**
 * @param {?proto.common.AhrefMetrics|undefined} value
 * @return {!proto.automation.LaunchContentAutomationRequest} returns this
*/
proto.automation.LaunchContentAutomationRequest.prototype.setAhrefs = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.automation.LaunchContentAutomationRequest.oneofGroups_[1], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.automation.LaunchContentAutomationRequest} returns this
 */
proto.automation.LaunchContentAutomationRequest.prototype.clearAhrefs = function() {
  return this.setAhrefs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.automation.LaunchContentAutomationRequest.prototype.hasAhrefs = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional common.SemrushMetrics semrush = 10;
 * @return {?proto.common.SemrushMetrics}
 */
proto.automation.LaunchContentAutomationRequest.prototype.getSemrush = function() {
  return /** @type{?proto.common.SemrushMetrics} */ (
    jspb.Message.getWrapperField(this, common_pb.SemrushMetrics, 10));
};


/**
 * @param {?proto.common.SemrushMetrics|undefined} value
 * @return {!proto.automation.LaunchContentAutomationRequest} returns this
*/
proto.automation.LaunchContentAutomationRequest.prototype.setSemrush = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.automation.LaunchContentAutomationRequest.oneofGroups_[1], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.automation.LaunchContentAutomationRequest} returns this
 */
proto.automation.LaunchContentAutomationRequest.prototype.clearSemrush = function() {
  return this.setSemrush(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.automation.LaunchContentAutomationRequest.prototype.hasSemrush = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional common.ListennotesMetrics listennotes = 11;
 * @return {?proto.common.ListennotesMetrics}
 */
proto.automation.LaunchContentAutomationRequest.prototype.getListennotes = function() {
  return /** @type{?proto.common.ListennotesMetrics} */ (
    jspb.Message.getWrapperField(this, common_pb.ListennotesMetrics, 11));
};


/**
 * @param {?proto.common.ListennotesMetrics|undefined} value
 * @return {!proto.automation.LaunchContentAutomationRequest} returns this
*/
proto.automation.LaunchContentAutomationRequest.prototype.setListennotes = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.automation.LaunchContentAutomationRequest.oneofGroups_[1], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.automation.LaunchContentAutomationRequest} returns this
 */
proto.automation.LaunchContentAutomationRequest.prototype.clearListennotes = function() {
  return this.setListennotes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.automation.LaunchContentAutomationRequest.prototype.hasListennotes = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional common.MozMetrics moz = 12;
 * @return {?proto.common.MozMetrics}
 */
proto.automation.LaunchContentAutomationRequest.prototype.getMoz = function() {
  return /** @type{?proto.common.MozMetrics} */ (
    jspb.Message.getWrapperField(this, common_pb.MozMetrics, 12));
};


/**
 * @param {?proto.common.MozMetrics|undefined} value
 * @return {!proto.automation.LaunchContentAutomationRequest} returns this
*/
proto.automation.LaunchContentAutomationRequest.prototype.setMoz = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.automation.LaunchContentAutomationRequest.oneofGroups_[1], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.automation.LaunchContentAutomationRequest} returns this
 */
proto.automation.LaunchContentAutomationRequest.prototype.clearMoz = function() {
  return this.setMoz(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.automation.LaunchContentAutomationRequest.prototype.hasMoz = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional int64 campaign_pipeline_id = 13;
 * @return {number}
 */
proto.automation.LaunchContentAutomationRequest.prototype.getCampaignPipelineId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.LaunchContentAutomationRequest} returns this
 */
proto.automation.LaunchContentAutomationRequest.prototype.setCampaignPipelineId = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int64 user_id = 14;
 * @return {number}
 */
proto.automation.LaunchContentAutomationRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.LaunchContentAutomationRequest} returns this
 */
proto.automation.LaunchContentAutomationRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int64 organization_id = 15;
 * @return {number}
 */
proto.automation.LaunchContentAutomationRequest.prototype.getOrganizationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.LaunchContentAutomationRequest} returns this
 */
proto.automation.LaunchContentAutomationRequest.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int64 automation_id = 16;
 * @return {number}
 */
proto.automation.LaunchContentAutomationRequest.prototype.getAutomationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.LaunchContentAutomationRequest} returns this
 */
proto.automation.LaunchContentAutomationRequest.prototype.setAutomationId = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * @enum {number}
 */
proto.automation.ContentAutomationSearchingMode = {
  KEYWORDS_SEARCH: 0,
  BACKLINKS_SEARCH: 1,
  PODCAST_SEARCH: 2,
  IMPORT_MODE: 3
};

/**
 * @enum {number}
 */
proto.automation.KeywordSearchSource = {
  GOOGLE_KEYWORD_SEARCH: 0,
  BING_KEYWORD_SEARCH: 1,
  BING_NEWS_KEYWORD_SEARCH: 2,
  GOOGLE_NEWS_KEYWORD_SEARCH: 3
};

/**
 * @enum {number}
 */
proto.automation.PodcastSearchSource = {
  SHOWS_PODCAST_SEARCH: 0,
  EPISODES_PODCAST_SEARCH: 1
};

goog.object.extend(exports, proto.automation);
