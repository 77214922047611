// package: people
// file: people-website.proto

var people_website_pb = require("./people-website_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var common_pb = require("./common_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var WebsiteApiService = (function () {
  function WebsiteApiService() {}
  WebsiteApiService.serviceName = "people.WebsiteApiService";
  return WebsiteApiService;
}());

WebsiteApiService.getWebsiteById = {
  methodName: "getWebsiteById",
  service: WebsiteApiService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: people_website_pb.PeopleWebsiteResponse
};

WebsiteApiService.createWebsite = {
  methodName: "createWebsite",
  service: WebsiteApiService,
  requestStream: false,
  responseStream: false,
  requestType: people_website_pb.PeopleWebsiteCrudRequest,
  responseType: people_website_pb.PeopleWebsiteResponse
};

WebsiteApiService.updateWebsite = {
  methodName: "updateWebsite",
  service: WebsiteApiService,
  requestStream: false,
  responseStream: false,
  requestType: people_website_pb.PeopleWebsiteCrudRequest,
  responseType: people_website_pb.PeopleWebsiteResponse
};

WebsiteApiService.renameWebsite = {
  methodName: "renameWebsite",
  service: WebsiteApiService,
  requestStream: false,
  responseStream: false,
  requestType: people_website_pb.PeopleWebsiteRenameRequest,
  responseType: google_protobuf_empty_pb.Empty
};

WebsiteApiService.deleteWebsite = {
  methodName: "deleteWebsite",
  service: WebsiteApiService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: google_protobuf_empty_pb.Empty
};

WebsiteApiService.batchDeleteWebsite = {
  methodName: "batchDeleteWebsite",
  service: WebsiteApiService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.EntityRelationRequest,
  responseType: google_protobuf_empty_pb.Empty
};

WebsiteApiService.getAllWebsites = {
  methodName: "getAllWebsites",
  service: WebsiteApiService,
  requestStream: false,
  responseStream: true,
  requestType: people_website_pb.PeopleWebsiteFilterRequest,
  responseType: people_website_pb.PeopleWebsiteResponse
};

WebsiteApiService.getWebsiteByPersonId = {
  methodName: "getWebsiteByPersonId",
  service: WebsiteApiService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: people_website_pb.PeopleWebsiteResponseContainer
};

WebsiteApiService.exportWebsitesToCsv = {
  methodName: "exportWebsitesToCsv",
  service: WebsiteApiService,
  requestStream: false,
  responseStream: true,
  requestType: people_website_pb.ExportPeopleWebsiteRequest,
  responseType: common_pb.FileResponseContainer
};

WebsiteApiService.getComposedDataByPersonId = {
  methodName: "getComposedDataByPersonId",
  service: WebsiteApiService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: people_website_pb.PeopleComposedResponse
};

WebsiteApiService.updateRelationshipStatus = {
  methodName: "updateRelationshipStatus",
  service: WebsiteApiService,
  requestStream: false,
  responseStream: false,
  requestType: people_website_pb.WebsiteRelationshipStatusRequest,
  responseType: google_protobuf_empty_pb.Empty
};

WebsiteApiService.createFolder = {
  methodName: "createFolder",
  service: WebsiteApiService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.Folder,
  responseType: common_pb.Folder
};

WebsiteApiService.updateFolder = {
  methodName: "updateFolder",
  service: WebsiteApiService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.Folder,
  responseType: common_pb.Folder
};

WebsiteApiService.deleteFolder = {
  methodName: "deleteFolder",
  service: WebsiteApiService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: google_protobuf_empty_pb.Empty
};

WebsiteApiService.getAllFolders = {
  methodName: "getAllFolders",
  service: WebsiteApiService,
  requestStream: false,
  responseStream: true,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: common_pb.Folder
};

WebsiteApiService.addWebsitesToFolder = {
  methodName: "addWebsitesToFolder",
  service: WebsiteApiService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.EntityRelationRequest,
  responseType: google_protobuf_empty_pb.Empty
};

WebsiteApiService.removeWebsitesFromFolder = {
  methodName: "removeWebsitesFromFolder",
  service: WebsiteApiService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.EntityRelationRequest,
  responseType: google_protobuf_empty_pb.Empty
};

WebsiteApiService.addNote = {
  methodName: "addNote",
  service: WebsiteApiService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.Note,
  responseType: common_pb.Note
};

WebsiteApiService.removeNote = {
  methodName: "removeNote",
  service: WebsiteApiService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: google_protobuf_empty_pb.Empty
};

WebsiteApiService.updateNote = {
  methodName: "updateNote",
  service: WebsiteApiService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.Note,
  responseType: google_protobuf_empty_pb.Empty
};

WebsiteApiService.getAllNotes = {
  methodName: "getAllNotes",
  service: WebsiteApiService,
  requestStream: false,
  responseStream: true,
  requestType: common_pb.IdRequest,
  responseType: common_pb.Note
};

WebsiteApiService.addTag = {
  methodName: "addTag",
  service: WebsiteApiService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.Tag,
  responseType: common_pb.Tag
};

WebsiteApiService.addTagBatch = {
  methodName: "addTagBatch",
  service: WebsiteApiService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdContainer,
  responseType: google_protobuf_empty_pb.Empty
};

WebsiteApiService.removeTag = {
  methodName: "removeTag",
  service: WebsiteApiService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: google_protobuf_empty_pb.Empty
};

WebsiteApiService.getAllTags = {
  methodName: "getAllTags",
  service: WebsiteApiService,
  requestStream: false,
  responseStream: true,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: common_pb.Tag
};

WebsiteApiService.getTagsByWebsiteId = {
  methodName: "getTagsByWebsiteId",
  service: WebsiteApiService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: common_pb.Tags
};

WebsiteApiService.addSocials = {
  methodName: "addSocials",
  service: WebsiteApiService,
  requestStream: false,
  responseStream: false,
  requestType: people_website_pb.PeopleWebsiteSocialRequest,
  responseType: google_protobuf_empty_pb.Empty
};

WebsiteApiService.updateSocials = {
  methodName: "updateSocials",
  service: WebsiteApiService,
  requestStream: false,
  responseStream: false,
  requestType: people_website_pb.PeopleWebsiteSocialUpdateRequest,
  responseType: google_protobuf_empty_pb.Empty
};

WebsiteApiService.removeSocials = {
  methodName: "removeSocials",
  service: WebsiteApiService,
  requestStream: false,
  responseStream: false,
  requestType: people_website_pb.PeopleWebsiteSocialRequest,
  responseType: google_protobuf_empty_pb.Empty
};

WebsiteApiService.getCampaignOpportunitiesByWebsiteId = {
  methodName: "getCampaignOpportunitiesByWebsiteId",
  service: WebsiteApiService,
  requestStream: false,
  responseStream: true,
  requestType: common_pb.IdRequest,
  responseType: common_pb.PeopleOpportunity
};

WebsiteApiService.getPipelineOpportunitiesByWebsiteId = {
  methodName: "getPipelineOpportunitiesByWebsiteId",
  service: WebsiteApiService,
  requestStream: false,
  responseStream: true,
  requestType: common_pb.IdRequest,
  responseType: people_website_pb.RelationshipPipelineOpportunityResponse
};

WebsiteApiService.getAllMetricsByWebsiteId = {
  methodName: "getAllMetricsByWebsiteId",
  service: WebsiteApiService,
  requestStream: false,
  responseStream: true,
  requestType: common_pb.IdRequest,
  responseType: people_website_pb.PeopleWebsiteMetricsResponse
};

WebsiteApiService.getAllMetricsViewSettingsByWebsiteId = {
  methodName: "getAllMetricsViewSettingsByWebsiteId",
  service: WebsiteApiService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: people_website_pb.PeopleWebsiteMetricsViewSettingsResponse
};

WebsiteApiService.showMetricsByType = {
  methodName: "showMetricsByType",
  service: WebsiteApiService,
  requestStream: false,
  responseStream: false,
  requestType: people_website_pb.PeopleWebsiteShowMetricsRequest,
  responseType: people_website_pb.PeopleWebsiteMetricsResponse
};

WebsiteApiService.loadMetricsValueById = {
  methodName: "loadMetricsValueById",
  service: WebsiteApiService,
  requestStream: false,
  responseStream: false,
  requestType: people_website_pb.PeopleWebsiteMetricsRequest,
  responseType: people_website_pb.PeopleWebsiteMetricsResponse
};

WebsiteApiService.setMetricsValueById = {
  methodName: "setMetricsValueById",
  service: WebsiteApiService,
  requestStream: false,
  responseStream: false,
  requestType: people_website_pb.PeopleWebsiteMetricsRequest,
  responseType: google_protobuf_empty_pb.Empty
};

WebsiteApiService.deleteMetricsValueById = {
  methodName: "deleteMetricsValueById",
  service: WebsiteApiService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: google_protobuf_empty_pb.Empty
};

exports.WebsiteApiService = WebsiteApiService;

function WebsiteApiServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

WebsiteApiServiceClient.prototype.getWebsiteById = function getWebsiteById(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(WebsiteApiService.getWebsiteById, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

WebsiteApiServiceClient.prototype.createWebsite = function createWebsite(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(WebsiteApiService.createWebsite, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

WebsiteApiServiceClient.prototype.updateWebsite = function updateWebsite(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(WebsiteApiService.updateWebsite, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

WebsiteApiServiceClient.prototype.renameWebsite = function renameWebsite(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(WebsiteApiService.renameWebsite, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

WebsiteApiServiceClient.prototype.deleteWebsite = function deleteWebsite(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(WebsiteApiService.deleteWebsite, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

WebsiteApiServiceClient.prototype.batchDeleteWebsite = function batchDeleteWebsite(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(WebsiteApiService.batchDeleteWebsite, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

WebsiteApiServiceClient.prototype.getAllWebsites = function getAllWebsites(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(WebsiteApiService.getAllWebsites, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

WebsiteApiServiceClient.prototype.getWebsiteByPersonId = function getWebsiteByPersonId(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(WebsiteApiService.getWebsiteByPersonId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

WebsiteApiServiceClient.prototype.exportWebsitesToCsv = function exportWebsitesToCsv(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(WebsiteApiService.exportWebsitesToCsv, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

WebsiteApiServiceClient.prototype.getComposedDataByPersonId = function getComposedDataByPersonId(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(WebsiteApiService.getComposedDataByPersonId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

WebsiteApiServiceClient.prototype.updateRelationshipStatus = function updateRelationshipStatus(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(WebsiteApiService.updateRelationshipStatus, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

WebsiteApiServiceClient.prototype.createFolder = function createFolder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(WebsiteApiService.createFolder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

WebsiteApiServiceClient.prototype.updateFolder = function updateFolder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(WebsiteApiService.updateFolder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

WebsiteApiServiceClient.prototype.deleteFolder = function deleteFolder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(WebsiteApiService.deleteFolder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

WebsiteApiServiceClient.prototype.getAllFolders = function getAllFolders(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(WebsiteApiService.getAllFolders, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

WebsiteApiServiceClient.prototype.addWebsitesToFolder = function addWebsitesToFolder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(WebsiteApiService.addWebsitesToFolder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

WebsiteApiServiceClient.prototype.removeWebsitesFromFolder = function removeWebsitesFromFolder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(WebsiteApiService.removeWebsitesFromFolder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

WebsiteApiServiceClient.prototype.addNote = function addNote(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(WebsiteApiService.addNote, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

WebsiteApiServiceClient.prototype.removeNote = function removeNote(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(WebsiteApiService.removeNote, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

WebsiteApiServiceClient.prototype.updateNote = function updateNote(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(WebsiteApiService.updateNote, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

WebsiteApiServiceClient.prototype.getAllNotes = function getAllNotes(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(WebsiteApiService.getAllNotes, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

WebsiteApiServiceClient.prototype.addTag = function addTag(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(WebsiteApiService.addTag, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

WebsiteApiServiceClient.prototype.addTagBatch = function addTagBatch(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(WebsiteApiService.addTagBatch, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

WebsiteApiServiceClient.prototype.removeTag = function removeTag(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(WebsiteApiService.removeTag, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

WebsiteApiServiceClient.prototype.getAllTags = function getAllTags(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(WebsiteApiService.getAllTags, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

WebsiteApiServiceClient.prototype.getTagsByWebsiteId = function getTagsByWebsiteId(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(WebsiteApiService.getTagsByWebsiteId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

WebsiteApiServiceClient.prototype.addSocials = function addSocials(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(WebsiteApiService.addSocials, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

WebsiteApiServiceClient.prototype.updateSocials = function updateSocials(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(WebsiteApiService.updateSocials, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

WebsiteApiServiceClient.prototype.removeSocials = function removeSocials(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(WebsiteApiService.removeSocials, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

WebsiteApiServiceClient.prototype.getCampaignOpportunitiesByWebsiteId = function getCampaignOpportunitiesByWebsiteId(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(WebsiteApiService.getCampaignOpportunitiesByWebsiteId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

WebsiteApiServiceClient.prototype.getPipelineOpportunitiesByWebsiteId = function getPipelineOpportunitiesByWebsiteId(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(WebsiteApiService.getPipelineOpportunitiesByWebsiteId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

WebsiteApiServiceClient.prototype.getAllMetricsByWebsiteId = function getAllMetricsByWebsiteId(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(WebsiteApiService.getAllMetricsByWebsiteId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

WebsiteApiServiceClient.prototype.getAllMetricsViewSettingsByWebsiteId = function getAllMetricsViewSettingsByWebsiteId(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(WebsiteApiService.getAllMetricsViewSettingsByWebsiteId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

WebsiteApiServiceClient.prototype.showMetricsByType = function showMetricsByType(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(WebsiteApiService.showMetricsByType, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

WebsiteApiServiceClient.prototype.loadMetricsValueById = function loadMetricsValueById(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(WebsiteApiService.loadMetricsValueById, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

WebsiteApiServiceClient.prototype.setMetricsValueById = function setMetricsValueById(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(WebsiteApiService.setMetricsValueById, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

WebsiteApiServiceClient.prototype.deleteMetricsValueById = function deleteMetricsValueById(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(WebsiteApiService.deleteMetricsValueById, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.WebsiteApiServiceClient = WebsiteApiServiceClient;

