import copy from 'copy-to-clipboard';
import React, { ReactNode, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PersonType } from '@ts/people.types';
import { DispatchType } from 'src/store';

import useWorkspaceMembers from '@hooks/useWorkspaceMembers';

import { addNotification } from '@redux/actions/notifications.actions';
import { getCurrentWorkspaceId } from '@redux/selectors/workspaces.selectors';

import { Tr } from '@uikit/Table/Table';
import ConfigurableTable from '@uikit/ConfigurableTable/ConfigurableTable';
import ColumnsSelectIcon from '@uikit/ColumnsSelectIcon/ColumnsSelectIcon';

import QueryErrorMessage from '@components/Relationships/QueryErrorMessage/QueryErrorMessage';

import tableConfig from './tableConfig';

import './PeopleTable.scss';
import { ColumnSettingsType } from '@ts/common.types';

type PeopleTablePropsType = {
  people: PersonType[];
  isError: boolean;
  onCreatePerson: () => void;
  onRemovePerson: (number) => void;
  onToggleRowSelection: (number) => void;
  onEditPerson: (number) => void;
  onMovePerson: (person: PersonType) => void;
  selectedRows: {
    [personId: number]: boolean;
  };
  allSelected: boolean;
  onToggleAllSelected: (boolean) => void;
  onAddToBlackList: (peopleIds?: number[]) => void;
};

type RenderRowPropsType = {
  children: ReactNode;
  row: any;
  renderProps: {
    [key: string]: any;
  };
};

function RenderRow({ children, row, renderProps }: RenderRowPropsType): JSX.Element {
  const handleRowClick = (e) => {
    e.stopPropagation();
    renderProps.onEditPerson(row.id);
  };

  const isRowSelected = renderProps.selectedRows[row.id] !== undefined;

  return (
    <Tr onClick={handleRowClick} className="people-table__row" isSelected={isRowSelected}>
      {children}
    </Tr>
  );
}

function PeopleTable({
  people,
  isError,
  onCreatePerson,
  onToggleRowSelection,
  allSelected,
  selectedRows,
  onEditPerson,
  onMovePerson,
  onToggleAllSelected,
  onRemovePerson,
  onAddToBlackList,
}: PeopleTablePropsType): JSX.Element {
  const tableRef = useRef(null);

  const dispatch = useDispatch<DispatchType>();

  const curWorkspaceId = useSelector(getCurrentWorkspaceId);

  const { members: workspaceMembers } = useWorkspaceMembers();

  if (isError) {
    return (
      <div className="people-table-empty">
        <div className="people-table-empty__message">
          <QueryErrorMessage />
        </div>
      </div>
    );
  }

  if (people.length === 0) {
    return (
      <div className="people-table-empty">
        <div className="people-table-empty__message">There are no people yet. Want to</div>
        <span
          onClick={onCreatePerson}
          className="people-table-empty__button-link"
          role="link"
          tabIndex={0}
        >
          Add new person?
        </span>
      </div>
    );
  }

  const copyText = (text: string) => {
    copy(text);
    dispatch(addNotification({ title: 'Copied to clipboard', type: 'info', delay: 1000 }));
  };

  const peopleTableSettings: ColumnSettingsType[] = [
    { enabled: true, columnTitle: 'Contact', columnMappingName: 'contact' },
    { enabled: true, columnTitle: 'Relationship Status', columnMappingName: 'status' },
    { enabled: true, columnTitle: 'Website', columnMappingName: 'website' },
    { enabled: true, columnTitle: 'Date added', columnMappingName: 'created_on' },
  ];

  return (
    <div ref={tableRef} className="people-table-wrapper">
      <div className="people-table-wrapper__wrapper-for-horizontal-scrolling">
        <ConfigurableTable
          config={tableConfig(peopleTableSettings)}
          data={people}
          rowClassName="people-table__row"
          tableClassName="people-table"
          RenderRow={RenderRow}
          renderProps={{
            onEditPerson,
            onMovePerson,
            onToggleRowSelection,
            selectedRows,
            countSelectedRows: Object.keys(selectedRows).length,
            onToggleAllSelected,
            isAllSelected: allSelected,
            onRemovePerson,
            onAddToBlackList,
            tableRef,
            workspaceId: curWorkspaceId,
            copyText,
            workspaceMembers,
          }}
        />
      </div>
    </div>
  );
}

export default PeopleTable;
