import React from 'react';
import cn from 'class-names';

import Input from '@uikit/Input/Input';

import './BillingFormField.scss';

const BillingFormField = ({
  label,
  value,
  onChange,
  placeholder,
  className = '',
  disabled = false,
}: {
  label: string;
  value: string;
  onChange?: (string) => void;
  placeholder: string;
  className?: string;
  disabled?: boolean;
}): JSX.Element => {
  const handleChangeValue = ({ target: { value: inputValue } }) => {
    if (onChange) {
      onChange(inputValue);
    }
  };

  return (
    <div className={cn('billing-form-field', { [className]: className.length > 0 })}>
      <div className="billing-form-field__label">{label}</div>
      <div className="billing-form-field__field">
        <Input
          value={value}
          onChange={handleChangeValue}
          placeholder={placeholder}
          disabled={disabled}
          isFullWidth
        />
      </div>
    </div>
  );
};

export default BillingFormField;
