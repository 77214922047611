import { jsx } from 'slate-hyperscript';
import { isStringArray } from '@utils/dataCheckers';

export const ELEMENT_TAGS = {
  A: (el) => {
    if (el.classList.contains('link-temp')) {
      return {
        type: 'temp-variable',
        character: el.innerText,
        href: el.dataset.href,
      };
    }

    return { type: 'link', url: el.getAttribute('href') };
  },
  BLOCKQUOTE: () => ({ type: 'quote' }),
  H1: () => ({ type: 'heading-one' }),
  H2: () => ({ type: 'heading-two' }),
  H3: () => ({ type: 'heading-three' }),
  H4: () => ({ type: 'heading-four' }),
  H5: () => ({ type: 'heading-five' }),
  H6: () => ({ type: 'heading-six' }),
  IMG: (el) => {
    return { type: 'image', url: el.getAttribute('src') };
  },
  LI: () => ({ type: 'list-item' }),
  OL: () => ({ type: 'numbered-list' }),
  P: (el) => {
    switch (el.style.textAlign) {
      case 'left':
        return { type: 'text-alignment-left' };
      case 'right':
        return { type: 'text-alignment-right' };
      case 'center':
        return { type: 'text-alignment-center' };
      default:
        return { type: 'paragraph' };
    }
  },
  DIV: () => ({ type: 'paragraph' }),
  PRE: () => ({ type: 'code' }),
  UL: () => ({ type: 'bulleted-list' }),
  SPAN: (el) => {
    if (el.classList.contains('ai-variable')) {
      return {
        type: 'ai-variable',
        character: el.innerText,
        href: el.dataset.href,
      };
    }

    if (el.classList.contains('variable') && el.dataset.fallback !== undefined) {
      return {
        type: 'variable',
        fallback: el.dataset.fallback,
        character: el.innerText,
        href: el.dataset.href,
      };
    }

    if (el.classList.contains('temp-variable')) {
      return {
        type: 'temp-variable',
        character: el.innerText,
        href: el.dataset.href,
      };
    }

    return {
      type: 'text',
    };
  },
  /* A: (el) => {
    return {
      type: 'link',
      url: el.href,
    };
  }, */
};

export const TEXT_TAGS = {
  CODE: () => ({ code: true }),
  DEL: () => ({ strikethrough: true }),
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  S: () => ({ strikethrough: true }),
  STRONG: () => ({ bold: true }),
  U: () => ({ underline: true }),
};

export const deserialize = (el) => {
  if (el.nodeType === 3) {
    return el.textContent;
  }
  if (el.nodeType !== 1) {
    return null;
  }
  if (el.nodeName === 'BR') {
    return { text: '', type: 'text' };
  }

  const { nodeName } = el;
  let parent = el;

  if (nodeName === 'PRE' && el.childNodes[0] && el.childNodes[0].nodeName === 'CODE') {
    parent = el.childNodes[0];
  }
  // @ts-ignore
  const children = Array.from(parent.childNodes).map(deserialize).flat();

  if (el.nodeName === 'BODY') {
    return jsx('fragment', {}, children);
  }

  if (ELEMENT_TAGS[nodeName]) {
    const attrs = ELEMENT_TAGS[nodeName](el);

    if (children.length) {
      if (attrs.type === 'text' && isStringArray(children)) {
        return jsx('text', attrs, children);
      }
      return jsx('element', attrs, children.length ? children : [{ type: 'text', text: '' }]);
    }

    if (attrs.type === 'image') {
      return jsx('element', { ...attrs, width: el.width }, [{ type: 'text', text: '' }]);
    }
    return { text: '', type: 'text' };
  }

  if (TEXT_TAGS[nodeName]) {
    const attrs = TEXT_TAGS[nodeName](el);
    return children.map((child) => jsx('text', attrs, child));
  }

  return children;
};
