import cn from 'classnames';
import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';

import { SVGIcon } from '@uikit/Icon/Icon';

import './RightInfoSidebar.scss';

type RightInfoSidebarType = {
  onClose: () => void;
  isOpen: boolean;
  title: ReactNode;
  children: ReactNode;
  classNames?: {
    container?: string;
    background?: string;
    header?: string;
    body?: string;
    closeIcon?: string;
  };
};

const RightInfoSidebar = ({
  onClose,
  isOpen,
  title,
  children,
  classNames,
}: RightInfoSidebarType): JSX.Element => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className={cn('right-info-sidebar', { [classNames?.container]: !!classNames?.container })}>
      <div
        className={cn('right-info-sidebar__background', {
          [classNames?.background]: !!classNames?.background,
        })}
        onClick={onClose}
        tabIndex={0}
        role="button"
      />
      <div className="right-info-sidebar__sidebar">
        <div
          className={cn('right-info-sidebar__cross-svg-close', {
            [classNames?.closeIcon]: !!classNames?.closeIcon,
          })}
          onClick={onClose}
          tabIndex={0}
          role="button"
        >
          <SVGIcon icon="crossBlack" />
        </div>
        <div
          className={cn('right-info-sidebar__header', {
            [classNames?.header]: !!classNames?.header,
          })}
        >
          {title}
        </div>
        <div className={cn('right-info-sidebar__body', { [classNames?.body]: !!classNames?.body })}>
          {children}
        </div>
      </div>
    </div>,
    document.body
  );
};

export default RightInfoSidebar;
