import React from 'react';
import { LIGHT_GREY_COLOR_FOR_ICONS } from '@constants/colors';

const RefreshIconOneArrowAround = ({
  color = LIGHT_GREY_COLOR_FOR_ICONS,
  size = 14,
}: {
  color?: string;
  size?: number;
}): JSX.Element => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.8337 7.0013C12.8337 10.2096 10.2087 12.8346 7.00033 12.8346C3.79199 12.8346 1.16699 10.2096 1.16699 7.0013C1.16699 3.79297 3.79199 1.16797 7.00033 1.16797C9.27533 1.16797 11.2587 2.4513 12.192 4.3763"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.7167 0.992188L12.25 4.43385L8.75 3.96719"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RefreshIconOneArrowAround;
