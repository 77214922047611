import { Dispatch } from 'redux';
import {
  setInquiriesList,
  addInquiriesListData,
  setInquiriesListLs,
  setBookmark,
  removeBookmark,
  setInquiriesBookmarks,
  addInquiriesBookmarksData,
  setInquiriesBookmarksLs,
  setHaroCategoriesLs,
  setHaroCategories,
  setHaroFiltersLs,
  setHaroFiltersData,
  addHaroFiltersData,
  removeHaroFilterData,
  setInquiriesPitchedLs,
  setInquiriesPitched,
  addInquiriesPitchedData,
  updateHaroFilterData,
} from 'src/redux/actions/inquiries.actions';

import {
  addBookmarkApi,
  createAndSendPitchedApi,
  createFilterApi,
  getAllBookmarkedApi,
  getAllCategoriesApi,
  getAllPitchedApi,
  getFiltersApi,
  removeBookmarkApi,
  removeFilterApi,
  searchHaroApi,
  updateFilterApi,
} from '@api/haro.api';
import loadingStatuses from 'src/constants/loadingStatuses';
import { HaroFilterShortType, HaroOpportunityType } from 'src/types/haro.types';
import { HaroFilterPropsType } from '../../types/props.types';

export const fetchInquiriesList =
  (workspaceId: number, query: string, page = 0, filterId?: number, limit = 10) =>
  (dispatch: Dispatch): void => {
    dispatch(setInquiriesListLs(loadingStatuses.INITED));

    searchHaroApi(workspaceId, query, page, filterId, limit)
      .then((haroOpportunities) => {
        if (page === 0) {
          dispatch(setInquiriesList(haroOpportunities));
        } else {
          dispatch(addInquiriesListData(haroOpportunities));
        }
      })
      .finally(() => dispatch(setInquiriesListLs(loadingStatuses.LOADED)));
  };

export const fetchInquiriesBookmarks =
  (workspaceId: number, page = 0) =>
  (dispatch: Dispatch): void => {
    dispatch(setInquiriesBookmarksLs(loadingStatuses.INITED));

    getAllBookmarkedApi(workspaceId, page)
      .then((haroOpportunities) => {
        if (page === 0) {
          dispatch(setInquiriesBookmarks(haroOpportunities));
        } else {
          dispatch(addInquiriesBookmarksData(haroOpportunities));
        }
      })
      .finally(() => dispatch(setInquiriesBookmarksLs(loadingStatuses.LOADED)));
  };

export const fetchInquiriesPitched =
  (workspaceId: number, page = 0) =>
  (dispatch: Dispatch): void => {
    dispatch(setInquiriesPitchedLs(loadingStatuses.INITED));

    getAllPitchedApi(workspaceId, page)
      .then((haroOpportunities) => {
        if (page === 0) {
          dispatch(setInquiriesPitched(haroOpportunities));
        } else {
          dispatch(addInquiriesPitchedData(haroOpportunities));
        }
      })
      .finally(() => dispatch(setInquiriesPitchedLs(loadingStatuses.LOADED)));
  };

export const createAndPitchInquiry =
  (
    inquiryId: string,
    workspaceId: number,
    mailboxAccountId: number,
    emailTo: string,
    subject: string,
    content: string
  ) =>
  (dispatch) => {
    return createAndSendPitchedApi(inquiryId, workspaceId, mailboxAccountId, subject, content).then(
      (res) => {
        // dispatch(addInquiriesPitchedData([res]))
        return res;
      }
    );
  };

export const addInquiryBookmark =
  (workspaceId: number, haroOpportunity: HaroOpportunityType) =>
  (dispatch: Dispatch): void => {
    addBookmarkApi(workspaceId, haroOpportunity.id).then(() =>
      dispatch(setBookmark(haroOpportunity))
    );
  };

export const removeInquiryBookmark =
  (workspaceId: number, haroOpportunity: HaroOpportunityType) =>
  (dispatch: Dispatch): void => {
    removeBookmarkApi(workspaceId, haroOpportunity.id).then(() =>
      dispatch(removeBookmark(haroOpportunity))
    );
  };

export const fetchHaroCategories =
  () =>
  (dispatch: Dispatch): void => {
    dispatch(setHaroCategoriesLs(loadingStatuses.INITED));

    getAllCategoriesApi()
      .then(({ itemsList }) => dispatch(setHaroCategories(itemsList)))
      .finally(() => dispatch(setHaroCategoriesLs(loadingStatuses.LOADED)));
  };

export const fetchHaroFilters = (workspaceId: number) => (dispatch) => {
  dispatch(setHaroFiltersLs(loadingStatuses.INITED));

  getFiltersApi(workspaceId)
    .then((filters) => dispatch(setHaroFiltersData(filters)))
    .finally(() => {
      dispatch(setHaroFiltersLs(loadingStatuses.LOADED));
    });
};

export const createHaroFilter =
  (workspaceId: number, fields: HaroFilterPropsType) =>
  (dispatch): Promise<HaroFilterShortType> => {
    return createFilterApi(workspaceId, fields).then((createdFilter) => {
      dispatch(addHaroFiltersData(createdFilter));
      return createdFilter;
    });
  };

export const updateHaroFilter =
  (workspaceId: number, fields: HaroFilterPropsType) =>
  (dispatch): Promise<HaroFilterShortType> => {
    return updateFilterApi(workspaceId, fields).then((updatedFilter) => {
      dispatch(updateHaroFilterData(updatedFilter));
      return updatedFilter;
    });
  };

export const removeHaroFilter = (workspaceId: number, filterId: number) => (dispatch) => {
  return removeFilterApi(workspaceId, filterId).then(() => {
    dispatch(removeHaroFilterData(filterId));
  });
};
