import React from 'react';

export default ({ color = '#251FB6' }) => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.5983 9.33398C14.4215 9.33398 14.2519 9.40422 14.1269 9.52925C14.0019 9.65427 13.9316 9.82384 13.9316 10.0007V12.6673C13.9316 12.8441 13.8614 13.0137 13.7364 13.1387C13.6114 13.2637 13.4418 13.334 13.265 13.334H3.93164C3.75483 13.334 3.58526 13.2637 3.46024 13.1387C3.33521 13.0137 3.26497 12.8441 3.26497 12.6673V10.0007C3.26497 9.82384 3.19474 9.65427 3.06971 9.52925C2.94469 9.40422 2.77512 9.33398 2.59831 9.33398C2.4215 9.33398 2.25193 9.40422 2.1269 9.52925C2.00188 9.65427 1.93164 9.82384 1.93164 10.0007V12.6673C1.93164 13.1978 2.14235 13.7065 2.51743 14.0815C2.8925 14.4566 3.40121 14.6673 3.93164 14.6673H13.265C13.7954 14.6673 14.3041 14.4566 14.6792 14.0815C15.0543 13.7065 15.265 13.1978 15.265 12.6673V10.0007C15.265 9.82384 15.1947 9.65427 15.0697 9.52925C14.9447 9.40422 14.7751 9.33398 14.5983 9.33398ZM8.12497 10.474C8.18838 10.5347 8.26314 10.5823 8.34497 10.614C8.42477 10.6493 8.51106 10.6675 8.59831 10.6675C8.68555 10.6675 8.77184 10.6493 8.85164 10.614C8.93348 10.5823 9.00824 10.5347 9.07164 10.474L11.7383 7.80732C11.8638 7.68178 11.9344 7.51152 11.9344 7.33398C11.9344 7.15645 11.8638 6.98619 11.7383 6.86065C11.6128 6.73512 11.4425 6.66459 11.265 6.66459C11.0874 6.66459 10.9172 6.73512 10.7916 6.86065L9.26497 8.39398V2.00065C9.26497 1.82384 9.19474 1.65427 9.06971 1.52925C8.94469 1.40422 8.77512 1.33398 8.59831 1.33398C8.4215 1.33398 8.25193 1.40422 8.1269 1.52925C8.00188 1.65427 7.93164 1.82384 7.93164 2.00065V8.39398L6.40497 6.86065C6.34281 6.79849 6.26902 6.74918 6.18781 6.71554C6.10659 6.6819 6.01955 6.66459 5.93164 6.66459C5.84373 6.66459 5.75669 6.6819 5.67547 6.71554C5.59426 6.74918 5.52047 6.79849 5.45831 6.86065C5.39615 6.92281 5.34684 6.9966 5.3132 7.07782C5.27956 7.15903 5.26225 7.24608 5.26225 7.33398C5.26225 7.42189 5.27956 7.50894 5.3132 7.59015C5.34684 7.67137 5.39615 7.74516 5.45831 7.80732L8.12497 10.474Z"
      fill={color}
    />
  </svg>
);
