import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import checkIsEmail from '@utils/checkIsEmail';
import { isCorrectUrlString } from '@utils/isUrl';

import { addBlackListPatternsApi, updateBlackListPatternApi } from '@api/mailbox.api';

import { addNotification } from '@redux/actions/notifications.actions';

import { SVGIcon } from '@uikit/Icon/Icon';
import RightSidebar from '@components/RightSidebar/RightSidebar';

import './UnsubscribeSidebar.scss';
import Select from '@uikit/Select/Select';
import { transformWorkspacesToOptions } from '@mappers/optionTransformers';
import { workspacesSelector } from '@redux/selectors/workspaces.selectors';
import Radio from '@uikit/Radio/Radio';
import { unsubscribeScopeOptions } from '@mappers/common.mappers';
import AutomationPositionTooltipContent from '@components/CampaignSettings/ContactAutomationSetup/_components/AutomationPositionTooltipContent/AutomationPositionTooltipContent';
import { SelectOptionType } from '@ts/common.types';
import Display from '@components/Display/Display';
import { BlackListResponseType } from '@ts/mailboxInbox.types';
import Loader from '@uikit/Loader/Loader';
import { billingDetailsSelector } from '@redux/selectors/billings.selectors';
import useWorkspaces from '@hooks/useWorkspaces';

type FieldsStateType = {
  workspaces: SelectOptionType<number>[];
  scope: string;
};

function UnsubscribeSidebar({
  isOpen,
  onClose,
  activePattern,
}: {
  isOpen: boolean;
  onClose: () => void;
  activePattern?: BlackListResponseType;
}): JSX.Element {
  const dispatch = useDispatch();
  const { items: workspacesData, isLoading } = useWorkspaces(true);
  const { workspaces } = useSelector(workspacesSelector);
  const [patterns, changePatterns] = useState([]);
  const fieldRef = useRef<HTMLDivElement>();
  const {
    data: { enterpriseFeaturesEnabled },
  } = useSelector(billingDetailsSelector);

  const handleScopeChange = ({ target: { value } }) => {
    if (!enterpriseFeaturesEnabled) {
      dispatch(
        addNotification({
          title:
            'This feature is not available in your plan. Please contact support to learn more.',
          type: 'warning',
        })
      );
      return;
    }
    changeFields((prev) => ({
      ...prev,
      scope: value,
    }));
  };

  const [fields, changeFields] = useState<FieldsStateType>({
    workspaces: [],
    scope: unsubscribeScopeOptions.GLOBAL,
  });

  useEffect(() => {
    if (activePattern != null) {
      const newPatterns = activePattern.pattern
        .split(/[\n,]/g)
        .map((pattern) => pattern.trim())
        .filter((string) => !!string);
      const validPatterns = newPatterns.filter(isEmailOrDomain);

      handleAddPatterns(validPatterns);
      changeFields({
        workspaces:
          activePattern.workspaceIdsList.map((it) => {
            const targetWorkspace = workspacesData.find((work) => work.id === it);
            return { label: targetWorkspace.title, value: it } as SelectOptionType<number>;
          }) || [],
        scope:
          activePattern.workspaceIdsList.length > 0
            ? unsubscribeScopeOptions.WORKSPACE
            : unsubscribeScopeOptions.GLOBAL,
      });
    }
  }, [activePattern]);

  const createSelectChangeHandler = (fieldName) => (value) =>
    changeFields((prev) => ({
      ...prev,
      [fieldName]: value,
    }));

  const handleClose = () => {
    changePatterns([]);
    onClose();
  };

  const handleContentClick = () => fieldRef?.current?.focus();

  const handleAddPatterns = (newPatterns: string[]) => {
    const filtered = newPatterns.filter((pattern) => !patterns.includes(pattern));

    changePatterns((prevState) => {
      return [...prevState, ...filtered];
    });
  };

  const isEmailOrDomain = (newPattern: string) => {
    const isEmail = checkIsEmail(newPattern);
    const isUrl = isCorrectUrlString(newPattern);

    return isEmail || isUrl;
  };

  const handleAddPatternFromField = (e) => {
    const newPattern = e.target.textContent.trim();

    if (newPattern !== '') {
      if (!isEmailOrDomain(newPattern)) {
        dispatch(addNotification({ title: 'Should be email or domain', type: 'warning' }));
        return;
      }

      let normalizedPattern;
      if (checkIsEmail(newPattern)) {
        normalizedPattern = newPattern;
      } else {
        try {
          const url = new URL(newPattern);
          normalizedPattern = url.hostname.replace(/^www\./, '');
        } catch (error) {
          normalizedPattern = newPattern.replace(/^www\./, '');
        }
      }

      changePatterns((prevState) => {
        if (!prevState.includes(normalizedPattern)) {
          return [...prevState, normalizedPattern];
        }

        dispatch(addNotification({ title: 'Pattern already added' }));
        return prevState;
      });

      setTimeout(() => {
        e.target.textContent = '';
      }, 0);
    }
  };

  const handlePaste = (evt) => {
    evt.preventDefault();
    const text = evt.clipboardData.getData('text');
    const newPatterns = text
      .split(/[\n,]/g)
      .map((pattern) => pattern.trim())
      .filter((string) => !!string);
    const validPatterns = newPatterns.filter(isEmailOrDomain);

    handleAddPatterns(validPatterns);

    if (newPatterns.length !== validPatterns.length) {
      dispatch(addNotification({ title: 'Some patterns were invalid', type: 'warning' }));
    }

    setTimeout(() => {
      // eslint-disable-next-line no-param-reassign
      evt.target.textContent = '';
    }, 0);
  };

  const handleOnFieldKeyDown = (e) => {
    if (e.keyCode === 13 || e.keyCode === 9) {
      e.preventDefault();
      handleAddPatternFromField(e);
    }
  };

  const handleFieldBlur = (e) => {
    e.preventDefault();
    e.stopPropagation();

    handleAddPatternFromField(e);
  };

  const handleRemovePattern = (pattern: string) => {
    changePatterns((prevState) => prevState.filter((tmpPattern) => tmpPattern !== pattern));
  };

  const handleUnsubscribe = () => {
    const workspaceIds =
      fields.scope === unsubscribeScopeOptions.GLOBAL
        ? []
        : fields.workspaces.map((it) => Number(it.value));
    if (workspaceIds.length > 0 && !enterpriseFeaturesEnabled) {
      dispatch(
        addNotification({
          title: `Contact support to update the plan to use this feature`,
          type: 'warning',
        })
      );
      return;
    }
    if (activePattern != null && activePattern.id > 0) {
      patterns.forEach((pattern) => {
        updateBlackListPatternApi(activePattern.id, pattern, workspaceIds).then(() => {
          dispatch(
            addNotification({
              title: `Pattern updated`,
              type: 'success',
            })
          );
          handleClose();
        });
      });
    } else {
      addBlackListPatternsApi(patterns, workspaceIds).then(() => {
        dispatch(
          addNotification({
            title: `Pattern${patterns.length > 1 ? 's' : ''} added`,
            type: 'success',
          })
        );
        handleClose();
      });
    }
  };

  function isSaveButtonDisabled() {
    if (patterns == null || patterns.length === 0) {
      return true;
    }
    if (activePattern != null && activePattern.id > 0) {
      return patterns.length > 1;
    }
    return false;
  }

  return (
    <RightSidebar
      isOpen={isOpen}
      onClose={handleClose}
      buttonSaveText={activePattern != null ? 'Save' : 'Unsubscribe'}
      isSaveButtonDisabled={isSaveButtonDisabled()}
      onSave={handleUnsubscribe}
      title={activePattern != null ? 'Edit' : 'Unsubscribe'}
      subtitle="We will never send emails to these emails or websites."
    >
      <Loader isLoading={isLoading}>
        <div className="unsubscribe-sidebar">
          <div className="unsubscribe-sidebar__field">
            <div className="unsubscribe-sidebar__field-label">Emails or websites</div>
            <div
              className="unsubscribe-sidebar__content"
              onClick={handleContentClick}
              tabIndex={0}
              role="button"
            >
              {patterns.map((pattern) => (
                <React.Fragment key={pattern}>
                  <div className="unsubscribe-sidebar__pattern">
                    <div className="unsubscribe-sidebar__pattern-text">{pattern}</div>
                    <span
                      className="unsubscribe-sidebar__pattern-remove"
                      onClick={() => handleRemovePattern(pattern)}
                      tabIndex={0}
                      role="button"
                    >
                      <SVGIcon icon="crossDelete" />
                    </span>
                  </div>
                  <br />
                </React.Fragment>
              ))}

              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/no-static-element-interactions */}
              <div
                className="unsubscribe-sidebar__field"
                contentEditable
                ref={fieldRef}
                onKeyDown={handleOnFieldKeyDown}
                onBlur={handleFieldBlur}
                onPaste={handlePaste}
              />
            </div>
          </div>
          <div className="unsubscribe-sidebar__field">
            <div className="unsubscribe-sidebar__field-label">Select a scope</div>
            <div className="unsubscribe-sidebar__role-field">
              <Radio
                onChange={handleScopeChange}
                value={unsubscribeScopeOptions.GLOBAL}
                isChecked={fields.scope === unsubscribeScopeOptions.GLOBAL}
              >
                <div>
                  <div className="unsubscribe-sidebar__role-name">Global</div>
                  <div className="unsubscribe-sidebar__role-desc">
                    Unsubscribe from all campaigns across all workspaces.
                  </div>
                </div>
              </Radio>
              <br />
              <Radio
                onChange={handleScopeChange}
                value={unsubscribeScopeOptions.WORKSPACE}
                isChecked={fields.scope === unsubscribeScopeOptions.WORKSPACE}
              >
                <div>
                  <div className="unsubscribe-sidebar__role-name">
                    Workspace specific
                    {enterpriseFeaturesEnabled ? null : (
                      <SVGIcon icon="lock" size={14} color="#8f9199" />
                    )}
                  </div>
                  <div className="unsubscribe-sidebar__role-desc">
                    Unsubscribe from campaigns in specific workspaces.
                  </div>
                </div>
              </Radio>
            </div>
          </div>
          <Display isVisible={fields.scope === unsubscribeScopeOptions.WORKSPACE}>
            <div className="unsubscribe-sidebar__field">
              <div className="unsubscribe-sidebar__field-label">Workspaces</div>
              <Select
                options={transformWorkspacesToOptions(workspaces)}
                value={fields.workspaces}
                onChange={createSelectChangeHandler('workspaces')}
                placeholder="Select workspaces..."
                isMulti
                renderMultipleValuesTooltipContent={AutomationPositionTooltipContent}
                fieldToRender="label"
              />
            </div>
          </Display>
        </div>
      </Loader>
    </RightSidebar>
  );
}

export default UnsubscribeSidebar;
