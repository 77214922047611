import React from 'react';

import { Formik } from 'formik';

import isUrl, { isCorrectUrlString } from '@utils/isUrl';

import { HaroFilterPropsType } from '@ts/props.types';
import Input from 'src/uikit/Input/Input';
import Button from 'src/uikit/Button/Button';

import './FilterCreationBioStep.scss';

const validate = (values) => {
  const errors: any = {};

  if (!values.bioFullName) {
    errors.bioFullName = 'Required';
  }

  if (!values.bioJobTitle) {
    errors.bioJobTitle = 'Required';
  }

  if (!values.bioCompanyName) {
    errors.bioCompanyName = 'Required';
  }

  if (!values.bioCompanyWebsite) {
    errors.bioCompanyWebsite = 'Required';
  } else if (!isCorrectUrlString(values.bioCompanyWebsite)) {
    errors.bioCompanyWebsite = 'Link entered incorrectly';
  }

  return errors;
};

const FilterCreationBioStep = ({
  filterFields,
  onFieldChange,
  onBack,
  onCreateFilter,
  isNewFilter,
}: {
  filterFields: HaroFilterPropsType;
  onFieldChange: (fieldName: string, value: any) => void;
  onBack: () => void;
  onCreateFilter: () => void;
  isNewFilter?: boolean;
}): JSX.Element => {
  return (
    <Formik
      initialValues={filterFields}
      onSubmit={(values) => {
        onCreateFilter();
      }}
      validate={validate}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ values, handleChange, handleSubmit, errors }) => {
        const createFieldChangeHandler = (fieldName) => (event) => {
          let { value } = event.target;

          if (fieldName === 'bioCompanyWebsite' && !isUrl(value)) {
            value = `https://${value}`;
          }

          onFieldChange(fieldName, value);
          handleChange(event);
        };

        return (
          <div className="filter-creation-bio-step">
            <div className="filter-creation-bio-step__grid">
              <div className="filter-creation-bio-step__field">
                <div className="filter-creation-bio-step__field-title">Full name</div>
                <Input
                  name="bioFullName"
                  value={values.bioFullName}
                  onChange={createFieldChangeHandler('bioFullName')}
                  placeholder="Add your full name"
                />
                {errors.bioFullName ? (
                  <span className="filter-creation-bio-step__field-error">
                    {errors.bioFullName}
                  </span>
                ) : null}
              </div>
              <div className="filter-creation-bio-step__field">
                <div className="filter-creation-bio-step__field-title">Linkedin Profile</div>
                <Input
                  name="bioLinkedInLink"
                  value={values.bioLinkedInLink}
                  onChange={createFieldChangeHandler('bioLinkedInLink')}
                  placeholder="Add your Linkedin profile"
                />
              </div>
              <div className="filter-creation-bio-step__field">
                <div className="filter-creation-bio-step__field-title">Job Title</div>
                <Input
                  name="bioJobTitle"
                  value={values.bioJobTitle}
                  onChange={createFieldChangeHandler('bioJobTitle')}
                  placeholder="Add your job title"
                />
                {errors.bioJobTitle ? (
                  <span className="filter-creation-bio-step__field-error">
                    {errors.bioJobTitle}
                  </span>
                ) : null}
              </div>
              <div className="filter-creation-bio-step__field">
                <div className="filter-creation-bio-step__field-title">Twitter profile</div>
                <Input
                  name="bioTwitterLink"
                  value={values.bioTwitterLink}
                  onChange={createFieldChangeHandler('bioTwitterLink')}
                  placeholder="Add your twitter link"
                />
              </div>
              <div className="filter-creation-bio-step__field">
                <div className="filter-creation-bio-step__field-title">Company Name</div>
                <Input
                  name="bioCompanyName"
                  value={values.bioCompanyName}
                  onChange={createFieldChangeHandler('bioCompanyName')}
                  placeholder="Add your company name"
                />
                {errors.bioCompanyName ? (
                  <span className="filter-creation-bio-step__field-error">
                    {errors.bioCompanyName}
                  </span>
                ) : null}
              </div>
              <div className="filter-creation-bio-step__field">
                <div className="filter-creation-bio-step__field-title">Phone Number</div>
                <Input
                  name="bioPhoneNumber"
                  value={values.bioPhoneNumber}
                  onChange={createFieldChangeHandler('bioPhoneNumber')}
                  placeholder="Add your phone number"
                />
              </div>
              <div className="filter-creation-bio-step__field">
                <div className="filter-creation-bio-step__field-title">Company Website</div>
                <Input
                  name="bioCompanyWebsite"
                  value={values.bioCompanyWebsite}
                  error={errors.bioCompanyWebsite}
                  onChange={createFieldChangeHandler('bioCompanyWebsite')}
                  placeholder="Add your company website"
                />
                {errors.bioCompanyWebsite ? (
                  <span className="filter-creation-bio-step__field-error">
                    {errors.bioCompanyWebsite}
                  </span>
                ) : null}
              </div>
              <div className="filter-creation-bio-step__field">
                <div className="filter-creation-bio-step__field-title">Online Bio</div>
                <Input
                  name="bioOnlineBio"
                  value={values.bioOnlineBio}
                  onChange={createFieldChangeHandler('bioOnlineBio')}
                  placeholder="Add your online bio"
                />
              </div>
              <div className="filter-creation-bio-step__field">
                <div className="filter-creation-bio-step__field-title">
                  Headshot (google Drive & Dropbox)
                </div>
                <Input
                  name="bioHeadshot"
                  value={values.bioHeadshot}
                  onChange={createFieldChangeHandler('bioHeadshot')}
                  placeholder="Add your headshot"
                />
              </div>
              <div className="filter-creation-bio-step__field">
                <div className="filter-creation-bio-step__field-title">Press kit Link</div>
                <Input
                  name="bioPressKitLink"
                  value={values.bioPressKitLink}
                  onChange={createFieldChangeHandler('bioPressKitLink')}
                  placeholder="Add your press kit link"
                />
              </div>
            </div>
            <div className="filter-creation-bio-step__footer">
              <Button type="secondary" onClick={onBack}>
                Back
              </Button>
              <Button onClick={handleSubmit}>{isNewFilter ? 'Create' : 'Update'}</Button>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default FilterCreationBioStep;
