import React, { memo } from 'react';
import cn from 'class-names';

import { ContentAutoSearchStepType } from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/ContentAutoSearchSteps/getContentAutoSearchSteps';

import { SVGIcon } from '@uikit/Icon/Icon';

import './ContentAutoSearchSteps.scss';

type ContentAutoSearchStepsType = {
  stepsConfig?: ContentAutoSearchStepType[];
  activeStepIndex: number;
  onChooseStep?: (number: number) => void;
  style?: React.CSSProperties;
};

const ContentAutoSearchSteps = ({
  stepsConfig,
  activeStepIndex,
  onChooseStep,
  style,
}: ContentAutoSearchStepsType): JSX.Element => {
  const getCnImportStep = (curIndex: number, disabled?: boolean) =>
    cn('content-autosearch__step', {
      'content-autosearch__step--active': curIndex === activeStepIndex,
      'content-autosearch__step--disabled': disabled,
    });

  return (
    <div className="content-autosearch__steps" style={style}>
      {stepsConfig.map(({ title, key, disabled }, index, arr) => {
        const realIndex = index + 1;

        return (
          <React.Fragment key={key}>
            <div
              className={getCnImportStep(realIndex, disabled)}
              onClick={!disabled && onChooseStep ? () => onChooseStep(realIndex) : () => null}
            >
              <div className="content-autosearch__step-index">{realIndex}</div>
              <div className="content-autosearch__step-title">{title}</div>
            </div>

            {index !== arr.length - 1 ? <SVGIcon icon="chevron" size={8} color="#BBBBBB" /> : null}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default memo(ContentAutoSearchSteps);
