import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import Loader from '@uikit/Loader/Loader';

function Iframe({
  initialUrl,
  currentUrl,
  setCurrentUrl,
}: {
  initialUrl: string;
  currentUrl: string;
  setCurrentUrl: (value: string) => void;
}): JSX.Element {
  const [iFrameUrl, setIFrameUrl] = useState<string>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  /**
   * The method handles events from code injected to pages on proxy server, example of the code is in the iframe-proxy-content.js file
   */
  const handleIframeClick = (event) => {
    if (event.origin !== 'https://iframe.respona.com') return;
    console.log(event.data);
    if (event.data.type === 'pageLoad') {
      setIsLoading(false);
    } else if (event.data.type === 'pageError') {
      setHasError(true);
      console.error(`Error: ${event.data.details}`);
    } else if (event.data.type === 'linkClick') {
      handleClick(event.data.details);
    } else if (event.data.type === 'buttonClick') {
      handleClick(event.data.details);
    } else if (event.data.type === 'formSubmission') {
      handleClick(event.data.details);
    } else if (event.data.type === 'jsRedirection') {
      handleClick(event.data.details);
    } else if (event.data.type === 'historyPushState') {
      handleClick(event.data.details);
    } else if (event.data.type === 'historyReplaceState') {
      handleClick(event.data.details);
    } else {
      console.error(`Unrecognized event: ${event.data.type}`, event);
    }
  };

  useEffect(() => {
    if (currentUrl !== iFrameUrl) {
      setIFrameUrl(iFrameUrl);
      setIsLoading(true);
      setHasError(false);
    }
  }, [currentUrl]);

  useEffect(() => {
    window.addEventListener('message', handleIframeClick);
    return () => {
      window.removeEventListener('message', handleIframeClick);
    };
  }, []);

  function extractDomain(url: string): string {
    const domainRegex = /^((?:https?:\/\/)?[^\\/]+)/;
    const match = url.match(domainRegex);
    return match ? match[1] : '';
  }

  function handleClick(originalHref: string) {
    if (originalHref.startsWith('/')) {
      const newUrl = extractDomain(initialUrl) + originalHref;
      setCurrentUrl(newUrl);
    } else if (originalHref.startsWith('#')) {
      const newUrl = currentUrl + originalHref;
      setCurrentUrl(newUrl);
    } else {
      setCurrentUrl(originalHref);
    }
  }

  const composedUrl = (url: string) =>
    `https://iframe.respona.com/rest/api/redirect?destinationUrl=${encodeURIComponent(url)}`;

  return (
    <>
      {isLoading && <Loader isFullScreenLoading />}
      <iframe
        title={composedUrl(currentUrl)}
        ref={iframeRef}
        src={composedUrl(currentUrl)}
        sandbox="allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-same-origin allow-scripts"
        style={{ width: '100%', height: 'calc(100vh - 93px)', border: 'none' }}
      />
    </>
  );
}

export default Iframe;
