import React, { useMemo, useState } from 'react';

import WorkspacesTable from '@components/Settings/WorkspacesTable/WorkspacesTable';
import WorkspaceHeader from '@components/Settings/Workspaces/_components/WorkspaceHeader';
import { useDispatch, useSelector } from 'react-redux';

import './Workspaces.scss';
import EditWorkspaceSidebar from '@components/Settings/EditWorkspaceSidebar/EditWorkspaceSidebar';
import { WorkspaceType } from '@ts/userOrganization.types';
import { addNotification } from '@redux/actions/notifications.actions';
import { updateWorkspaceApi } from '@api/userOrganization.api';
import Loader from '@uikit/Loader/Loader';
import { changeWorkspace } from '@redux/actions/workspaces.actions';
import useMailboxAccounts from '@hooks/useMailboxAccounts';
import { getCurrentWorkspaceId } from '@redux/selectors/workspaces.selectors';
import useMailboxAccountStatistics from '@hooks/useMailboxAccountStatistics';
import useWorkspaces from '@hooks/useWorkspaces';

function Workspaces(): JSX.Element {
  const dispatch = useDispatch();
  const [searchString, changeSearchString] = useState('');
  const [selectedIdsObj, changeSelectedIdsObj] = useState({});
  const [isAllSelected, changeIsAllSelected] = useState(false);
  const [isEditSidebarOpen, setEditSidebarOpen] = useState<boolean>(false);
  const [activeWorkspace, setActiveWorkspace] = useState<WorkspaceType | null>(null);
  const curWorkspaceId = useSelector(getCurrentWorkspaceId);
  const { evictByKey: evictMailboxAccountsByWorkspace } = useMailboxAccounts(curWorkspaceId);
  const { evictByKey: evictMailboxAccounts } = useMailboxAccountStatistics();
  const { items: workspaces, isLoading, updateItem } = useWorkspaces();

  const handleToggleAllSelected = (checked) => {
    changeIsAllSelected(checked);

    if (checked) {
      changeSelectedIdsObj(
        workspaces.reduce(
          (acc, { id }) => ({
            ...acc,
            [id]: true,
          }),
          {}
        )
      );
    } else {
      changeSelectedIdsObj({});
    }
  };

  const handleToggleWorkspaceSelection = (workspaceId) => {
    changeSelectedIdsObj((prevState) => {
      if (prevState[workspaceId] === undefined) {
        return {
          ...prevState,
          [workspaceId]: true,
        };
      }

      changeIsAllSelected(false);
      // @ts-ignore
      const { [workspaceId]: omitted, ...newState } = prevState;
      return newState;
    });
  };

  const handleEditWorkspaceSelection = (workspace: WorkspaceType) => {
    setEditSidebarOpen(true);
    setActiveWorkspace(workspace);
  };

  const handleEditWorkspaceClose = () => {
    setEditSidebarOpen(false);
    setActiveWorkspace(null);
  };

  const handleWorkspaceUpdated = (
    workspaceId: number,
    title: string,
    logoUrl: string,
    members: number[],
    mailboxes: number[]
  ) => {
    updateWorkspaceApi(workspaceId, title, logoUrl, members, mailboxes).then((workspace) => {
      handleEditWorkspaceClose();
      updateItem(workspaceId, workspace);
      evictMailboxAccounts(workspaceId);
      evictMailboxAccountsByWorkspace(workspaceId);
      dispatch(changeWorkspace(workspace));
      dispatch(addNotification({ title: 'Workspace was updated', type: 'success' }));
    });
  };

  const filteredWorkspaces = useMemo(() => {
    if (searchString) {
      return workspaces.filter(({ title }) => {
        return title.toLowerCase().indexOf(searchString.toLowerCase()) !== -1;
      });
    }

    return workspaces;
  }, [searchString, workspaces]);

  return (
    <div className="settings-workspaces">
      <Loader isLoading={isLoading}>
        <WorkspaceHeader searchString={searchString} changeSearchString={changeSearchString} />
        <WorkspacesTable
          workspaces={filteredWorkspaces}
          selectedRows={selectedIdsObj}
          onToggleAllSelected={handleToggleAllSelected}
          isAllSelected={isAllSelected}
          onToggleRowSelection={handleToggleWorkspaceSelection}
          onEditWorkspaceSelection={handleEditWorkspaceSelection}
        />

        <EditWorkspaceSidebar
          workspace={activeWorkspace}
          isOpen={isEditSidebarOpen}
          onClose={handleEditWorkspaceClose}
          onUpdate={handleWorkspaceUpdated}
        />
      </Loader>
    </div>
  );
}

export default Workspaces;
