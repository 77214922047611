export default (checkingValue: string): boolean => {
  let url;

  try {
    url = new URL(checkingValue);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const isCorrectUrlString = (value: string): boolean =>
  /^(https?:\/\/)?(www\.)?[\w.-]+\.[a-zA-Z]{2,}(\/[^\s]*)?$/.test(value);
