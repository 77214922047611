import React from 'react';

export default ({ color = '#fff' }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3 10C3 13.866 6.13401 17 10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
