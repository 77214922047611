import React from 'react';

const UploadShortSVG = ({ size = 18 }: { size?: number }): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="18" height="18" rx="3" fill="#2822B7" />
      <path d="M5 11V13H13V11" stroke="white" strokeMiterlimit="10" strokeLinecap="square" />
      <path d="M9 11V5" stroke="white" strokeMiterlimit="10" />
      <path d="M11 7L9 5L7 7" stroke="white" strokeMiterlimit="10" strokeLinecap="square" />
    </svg>
  );
};

export default UploadShortSVG;
