import React from 'react';

export default ({ color = '#8F9199' }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 6.705C14.9922 6.6361 14.9771 6.56822 14.955 6.5025V6.435C14.9189 6.35788 14.8708 6.287 14.8125 6.225L10.3125 1.725C10.2505 1.66666 10.1796 1.61856 10.1025 1.5825H10.035C9.95881 1.53881 9.87467 1.51076 9.7875 1.5H5.25C4.65326 1.5 4.08097 1.73705 3.65901 2.15901C3.23705 2.58097 3 3.15326 3 3.75V14.25C3 14.8467 3.23705 15.419 3.65901 15.841C4.08097 16.2629 4.65326 16.5 5.25 16.5H12.75C13.3467 16.5 13.919 16.2629 14.341 15.841C14.7629 15.419 15 14.8467 15 14.25V6.75C15 6.75 15 6.75 15 6.705ZM10.5 4.0575L12.4425 6H11.25C11.0511 6 10.8603 5.92098 10.7197 5.78033C10.579 5.63968 10.5 5.44891 10.5 5.25V4.0575ZM13.5 14.25C13.5 14.4489 13.421 14.6397 13.2803 14.7803C13.1397 14.921 12.9489 15 12.75 15H5.25C5.05109 15 4.86032 14.921 4.71967 14.7803C4.57902 14.6397 4.5 14.4489 4.5 14.25V3.75C4.5 3.55109 4.57902 3.36032 4.71967 3.21967C4.86032 3.07902 5.05109 3 5.25 3H9V5.25C9 5.84674 9.23705 6.41903 9.65901 6.84099C10.081 7.26295 10.6533 7.5 11.25 7.5H13.5V14.25Z"
      fill={color}
    />
  </svg>
);
