import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { DispatchType } from 'src/store';

import debounce from '@utils/debounce';

import { GREY_COLOR, PRIMARY_COLOR } from '@constants/colors';

import { setTittleForFilterCampaign } from '@redux/actions/campaigns.actions';

import Input from '@uikit/Input/Input';

import './InboxSearch.scss';
import InboxFilters from '@components/Inbox/InboxSearch/_components/InboxFilters/InboxFilters';

function InboxSearch(): JSX.Element {
  const dispatch = useDispatch<DispatchType>();

  const [searchString, setSearchString] = useState('');

  const debouncedSetReduxSearchString = useCallback(
    debounce((value) => dispatch(setTittleForFilterCampaign(value)), 2000),
    []
  );

  const handleSearchChange = ({ target: { value } }) => {
    setSearchString(value);
    debouncedSetReduxSearchString(value);
  };

  const handleClear = () => {
    setSearchString('');
    dispatch(setTittleForFilterCampaign(''));
  };

  return (
    <>
      {/*<Input*/}
      {/*  className="campaigns-header__search"*/}
      {/*  isClearable*/}
      {/*  icon="search"*/}
      {/*  iconColor={searchString ? PRIMARY_COLOR : GREY_COLOR}*/}
      {/*  value={searchString}*/}
      {/*  onChange={handleSearchChange}*/}
      {/*  onClear={handleClear}*/}
      {/*  onKeyDown={(e) => {*/}
      {/*    if (e.keyCode === 13) {*/}
      {/*      e.stopPropagation();*/}
      {/*    }*/}
      {/*  }}*/}
      {/*  placeholder="Search"*/}
      {/*/>*/}
      <InboxFilters />
    </>
  );
}

export default InboxSearch;
