import React from 'react';

import { SearchTypesEnum } from '@ts/contentSearch.types';

import SemrushSearchSvg from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/SearchTypesTab/assets/SemrushSearchSvg';
import BingShortSVG from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/assets/BingShortSVG';
import GoogleShortSVG from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/assets/GoogleShortSVG';
import UploadShortSVG from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/assets/UploadShortSVG';
import KeywordsSearcgSVG from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/SearchTypesTab/assets/KeywordsSearcgSVG';
import PodcastSVG from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/SearchTypesTab/assets/PodcastSVG';
import RelationshipsSVG from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/assets/RelationshipsSVG';
import BingNewsShortSVG from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/assets/BingNewsShortSVG';
import EpisodesSVG from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/assets/EpisodesSVG';

const mozIcon = require('@assets/moz-logo-square.png').default;

export const contentSearchTypeMap = {
  [SearchTypesEnum.googleSearch]: 0,
  [SearchTypesEnum.googleNews]: 12,
  [SearchTypesEnum.bingSearch]: 1,
  [SearchTypesEnum.podcasts]: 2,
  [SearchTypesEnum.episodes]: 3,
  trendingContent: 4,
  [SearchTypesEnum.bingNews]: 5,
  import: 6,
  relationships: 7,
  ahrefs: 8,
  semrush: 9,
  moz: 10,
};

export const urlSearchTypeMap = {
  all: 0,
  external: 1,
};

export const contentSearchTypeMapByNumber = {
  0: SearchTypesEnum.googleSearch,
  12: SearchTypesEnum.googleNews,
  1: SearchTypesEnum.bingSearch,
  2: SearchTypesEnum.podcasts,
  3: SearchTypesEnum.episodes,
  4: 'trendingContent',
  5: SearchTypesEnum.bingNews,
  6: 'import',
  7: 'relationships',
  8: 'ahrefs',
  9: 'semrush',
  10: 'moz',
};

export const contentSearchIconMapByType = {
  [SearchTypesEnum.googleSearch]: GoogleShortSVG,
  [SearchTypesEnum.googleNews]: GoogleShortSVG,
  [SearchTypesEnum.bingSearch]: BingShortSVG,
  [SearchTypesEnum.podcasts]: PodcastSVG,
  [SearchTypesEnum.episodes]: EpisodesSVG,
  [SearchTypesEnum.bingNews]: BingNewsShortSVG,
  ahrefs: KeywordsSearcgSVG,
  semrush: SemrushSearchSvg,
  import: UploadShortSVG,
  trendingContent: '',
  relationships: RelationshipsSVG,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  moz: () => <img width={17} height={17} src={mozIcon} alt="moz" />,
};
