import DashboardIcon from '@uikit/SidebarIcon/_components/DashboardIcon';
import React from 'react';

import ToolboxIcon from '@uikit/SidebarIcon/_components/ToolboxIcon';
import { PRIMARY_COLOR } from '@constants/colors';
import CampaignsIcon from './_components/CampaignsIcon';
import AutomationsIcon from './_components/AutomationsIcon';
import InboxIcon from './_components/InboxIcon';
import InsightsIcon from './_components/InsightsIcon';
import RelationshipsIcon from './_components/RelationshipsIcon';
import InquiriesIcon from './_components/InquiriesIcon';

type SidebarIconPropsType = {
  isActive?: boolean;
  type: string;
};

export default ({ type }: SidebarIconPropsType): JSX.Element => {
  const color = PRIMARY_COLOR;

  switch (type) {
    case 'dashboard':
      return <DashboardIcon color={color} />;
    case 'campaigns':
      return <CampaignsIcon color={color} />;
    case 'automations':
      return <AutomationsIcon color={color} />;
    case 'inbox':
      return <InboxIcon color={color} />;
    case 'insights':
      return <InsightsIcon color={color} />;
    case 'relationships':
      return <RelationshipsIcon color={color} />;
    case 'inquiries':
      return <InquiriesIcon color={color} />;
    case 'toolbox':
      return <ToolboxIcon color={color} />;
    default:
      return null;
  }

  return null;
};
