import React from 'react';
import cn from 'class-names';

import { SVGIcon } from '@uikit/Icon/Icon';

const MetaInfoValue = ({
  value,
  onRemove,
  disabled = false,
}: {
  value: string;
  onRemove?: (string) => void;
  disabled?: boolean;
}): JSX.Element => {
  const handleRemove = () => {
    if (!disabled) {
      onRemove(value);
    }
  };

  const cnMetaInfoValue = cn('meta-info__value', {
    'meta-info__value--disabled': disabled,
  });

  return (
    <span key={value} className={cnMetaInfoValue}>
      {value}
      {onRemove && !disabled ? (
        <span className="meta-info__remove-icon" onClick={handleRemove} tabIndex={0} role="button">
          <SVGIcon icon="crossDelete" />
        </span>
      ) : null}
    </span>
  );
};

export default MetaInfoValue;
