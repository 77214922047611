import React from 'react';

export default ({ color = '#BDBDBD', size = 20 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_22338_4433)">
        <path
          d="M4.16797 5.41649C4.34691 5.65571 4.5752 5.85365 4.83736 5.99688C5.09953 6.14011 5.38943 6.22529 5.68741 6.24663C5.98538 6.26797 6.28446 6.22498 6.56436 6.12056C6.84426 6.01615 7.09843 5.85277 7.30964 5.64149L8.55964 4.39149C8.93913 3.99857 9.14912 3.47231 9.14437 2.92607C9.13963 2.37983 8.92052 1.8573 8.53426 1.47103C8.14799 1.08477 7.62546 0.865665 7.07922 0.860919C6.53298 0.856172 6.00672 1.06616 5.6138 1.44566L4.89714 2.15816"
          stroke={color}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.8339 4.58302C5.65497 4.3438 5.42667 4.14586 5.16451 4.00263C4.90235 3.8594 4.61244 3.77422 4.31447 3.75288C4.01649 3.73154 3.71741 3.77453 3.43751 3.87894C3.15761 3.98336 2.90344 4.14674 2.69224 4.35802L1.44224 5.60802C1.06274 6.00094 0.852754 6.5272 0.857501 7.07344C0.862247 7.61968 1.08135 8.14221 1.46762 8.52848C1.85388 8.91474 2.37641 9.13384 2.92265 9.13859C3.4689 9.14334 3.99515 8.93335 4.38807 8.55385L5.10057 7.84135"
          stroke={color}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_22338_4433">
          <rect width={size} height={size} fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};
