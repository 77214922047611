import React from 'react';

import './SortButtons.scss';
import { SVGIcon } from '@uikit/Icon/Icon';

const SortButtons = ({
  handleClick,
  isActive,
  direction,
  dataKey,
}: {
  handleClick?: (direction: string, key: string) => void;
  isActive?: boolean;
  direction?: string;
  dataKey?: string;
}): JSX.Element => {
  return (
    <div className="sort-buttons">
      <span
        className={`up ${isActive && direction === 'down' ? 'hidden' : ''}`}
        onClick={() => handleClick('up', dataKey)}
      >
        <SVGIcon icon="sortUpArrow" color={`${isActive ? '#3424EB' : '#8d8f92'}`} size={7} />
      </span>
      <span
        className={`down ${isActive && direction === 'up' ? 'hidden' : ''}`}
        onClick={() => handleClick('down', dataKey)}
      >
        <SVGIcon icon="sortDownErrow" color={`${isActive ? '#3424EB' : '#8d8f92'}`} size={7} />
      </span>
    </div>
  );
};

export default SortButtons;
