import React, { useState } from 'react';

import Display from '@components/Display/Display';
import MetaInfoValue from '@components/Inbox/InboxWidget/_components/MetaInfo/_components/MetaInfoValue';
import Input from '@uikit/Input/Input';
import checkIsEmail from '@utils/checkIsEmail';

const CcBcc = ({ onUpdateMeta, step, children }): JSX.Element => {
  const [isBccFieldShows, changeIsBccFieldShows] = useState(false);
  const [isCcFieldShows, changeIsCcFieldShows] = useState(false);

  const [newCcValue, changeNewCcValue] = useState('');
  const [newBccValue, changeNewBccValue] = useState('');

  const [newCcError, changeNewCcError] = useState(false);
  const [newBccError, changeNewBccError] = useState(false);

  const handleShowBccField = () => changeIsBccFieldShows(true);
  const handleShowCcField = () => changeIsCcFieldShows(true);

  const handleChangeNewCcValue = ({ target: { value } }) => {
    changeNewCcError(false);
    changeNewCcValue(value);
  };
  const handleChangeNewBccValue = ({ target: { value } }) => {
    changeNewBccError(false);
    changeNewBccValue(value);
  };

  const handleApplyNewBcc = () => {
    if (checkIsEmail(newBccValue)) {
      onUpdateMeta({
        ...step,
        emailsToBccList: [...step.emailsToBccList, newBccValue],
      });

      changeNewBccValue('');
    } else {
      changeNewBccError(true);
    }
  };

  const handleApplyNewCc = () => {
    if (checkIsEmail(newCcValue)) {
      onUpdateMeta({
        ...step,
        emailsToCcList: [...step.emailsToCcList, newCcValue],
      });

      changeNewCcValue('');
    } else {
      changeNewCcError(true);
    }
  };

  const handleAddNewCc = (e) => {
    if (e.keyCode === 13) {
      handleApplyNewCc();
    }
  };

  const handleAddNewBcc = (e) => {
    if (e.keyCode === 13) {
      handleApplyNewBcc();
    }
  };

  const createRemoveMetaValueHandler = (metaField) => (value) => {
    onUpdateMeta({
      ...step,
      [metaField]: step[metaField].filter((metaValue) => metaValue !== value),
    });
  };

  return (
    <>
      <Display isVisible={isCcFieldShows || step.emailsToCcList?.length > 0}>
        <div className="meta-info__row">
          <span className="meta-info__label">CC:</span>
          {step.emailsToCcList?.map((email) => (
            <MetaInfoValue
              key={email}
              value={email}
              onRemove={createRemoveMetaValueHandler('emailsToCcList')}
            />
          ))}
          <Input
            placeholder="new cc..."
            onKeyDown={handleAddNewCc}
            value={newCcValue}
            isTransparent
            onChange={handleChangeNewCcValue}
            isError={newCcError}
            onBlur={handleApplyNewCc}
          />
        </div>
      </Display>
      <Display isVisible={isBccFieldShows || step.emailsToBccList?.length > 0}>
        <div className="meta-info__row">
          <span className="meta-info__label">BCC:</span>
          {step.emailsToBccList?.map((email) => (
            <MetaInfoValue
              key={email}
              value={email}
              onRemove={createRemoveMetaValueHandler('emailsToBccList')}
            />
          ))}
          <Input
            placeholder="new bcc..."
            onKeyDown={handleAddNewBcc}
            value={newBccValue}
            isTransparent
            onChange={handleChangeNewBccValue}
            isError={newBccError}
            onBlur={handleAddNewBcc}
          />
        </div>
      </Display>
      <div className="meta-info__row-buttons">
        <Display isVisible={!isCcFieldShows && step.emailsToCcList?.length === 0}>
          <div
            className="meta-info__adding-button"
            onClick={handleShowCcField}
            tabIndex={0}
            role="button"
          >
            + CC
          </div>
        </Display>
        <Display isVisible={!isBccFieldShows && step.emailsToBccList?.length === 0}>
          <div
            className="meta-info__adding-button"
            onClick={handleShowBccField}
            tabIndex={0}
            role="button"
          >
            + BCC
          </div>
        </Display>

        {children}
      </div>
    </>
  );
};

export default CcBcc;
