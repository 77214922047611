import { getCampaignAutomationPresetApi } from '@api/contactAutomation.api';

import {
  setContactAutomationData,
  setCampaignAutomationLs,
} from '@redux/actions/automationContact.actions';

import loadingStatuses from '@constants/loadingStatuses';

import { transformPositionOrSeniorityList } from '@mappers/optionTransformers';

import { ContactAutomationPresetType } from '@ts/automationContacts.types';

const prepareAutomationData = (data) => ({
  ...data,
  positionsList: transformPositionOrSeniorityList(data.positionsList),
  positionSuggestions: transformPositionOrSeniorityList(data.positionsList),
  senioritiesList: transformPositionOrSeniorityList(data.senioritiesList),
  senioritySuggestions: transformPositionOrSeniorityList(data.senioritiesList),
});

export const getContactAutomationPresets =
  (workspaceId: number, pipelineId: number) =>
  (dispatch): Promise<any> => {
    dispatch(setCampaignAutomationLs(loadingStatuses.INITED));

    getCampaignAutomationPresetApi(workspaceId, pipelineId)
      .then((response) => {
        dispatch(
          setContactAutomationData(
            response.map((it: ContactAutomationPresetType) => prepareAutomationData(it))
          )
        );
      })
      .finally(() => {
        dispatch(setCampaignAutomationLs(loadingStatuses.LOADED));
      });
    return Promise.resolve();
  };
