import { SVGIcon } from '@uikit/Icon/Icon';
import ProgressBar, { ProgressBarComponent } from '@uikit/ProgressBar/ProgressBar';
import getShortNumber from '@utils/getShortNumber';
import React from 'react';

import './BillingCard.scss';

type Props = {
  title: string;
  subtitle?: React.ReactNode;
  headerRight?: React.ReactNode;
  chartTitle: React.ReactNode;
  chartLegend?: React.ReactNode;
  chartInfo: {
    value: number;
    max: number;
  };
  showWarning?: boolean;
  warningReversed?: boolean;
};

const BillingCard = ({
  title,
  subtitle,
  headerRight,
  chartTitle,
  chartLegend,
  chartInfo,
  showWarning = true,
  warningReversed = false,
}: Props) => {
  return (
    <div className="billing-card">
      <div className="billing-card__header">
        <div>
          <div className="billing-card__title">{title}</div>
          {subtitle ? <div className="billing-card__subtitle">{subtitle}</div> : null}
        </div>

        {headerRight ? <div className="billing-card__header-right">{headerRight}</div> : null}
      </div>

      <div className="billing-card__footer">
        <div className="billing-card__footer-info">
          <div className="billing-card__footer-info-title">{chartTitle}</div>

          {!!chartLegend ? (
            <div className="billing-card__footer-info-legend">{chartLegend}</div>
          ) : (
            <ChartLegendComponent {...chartInfo} />
          )}
        </div>

        <div className="billing-card__footer-chart">
          {showWarning &&
          (warningReversed ? chartInfo.value >= chartInfo.max : chartInfo.value <= 0) ? (
            <SVGIcon icon="exclamationMarkInCircleIcon" color="#ED483D" size={17} />
          ) : null}

          <ProgressBarComponent
            containerClassName="billing-card__footer-chart-bar-container"
            className="billing-card__footer-chart-bar"
            percentage={(chartInfo.value * 100) / chartInfo.max}
          />
        </div>
      </div>
    </div>
  );
};

export default BillingCard;

const ChartLegendComponent = ({ value, max }: Props['chartInfo']) => (
  <div className="billing-card__chart-legend">
    <div className="billing-card__chart-legend-number">{getShortNumber(value)}</div>
    <span>out of</span>

    {max > 0 ? getShortNumber(max) : <SVGIcon icon="infinity" />}
  </div>
);
