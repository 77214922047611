import React from 'react';

import Loader from '@uikit/Loader/Loader';
import CampaignItemInFilters from '@components/Filters/CampaignItemInFilters/CampaignItemInFilters';
import { CampaignShortcutType } from '@ts/campaigns.types';

import useCampaignShortcutsByWorkspace from '@hooks/useCampaignShortcutsByWorkspace';

import './FilterByCampaign.scss';

type FilterByCampaignType = {
  chosenCampaignsIds: number[];
  gatherChosenCampaigns: (ids: number[]) => void;
  searchString?: string;
};

const FilterByCampaign = ({
  chosenCampaignsIds,
  gatherChosenCampaigns,
  searchString,
}: FilterByCampaignType): JSX.Element => {
  const { items: campaigns, isLoading } = useCampaignShortcutsByWorkspace();

  const isChoosen = (id) => chosenCampaignsIds.includes(id);

  const handleChangeIsChoosenStatus = (personId) => {
    let newIdsList;

    if (isChoosen(personId)) {
      newIdsList = chosenCampaignsIds.filter((id) => id !== personId);
    } else {
      newIdsList = [...chosenCampaignsIds, personId];
    }

    gatherChosenCampaigns(newIdsList);
  };

  if (isLoading) {
    return <Loader backgroundColor="#000000" isLoading />;
  }

  const filteredCampaigns = searchString
    ? campaigns.filter(({ id, title }: CampaignShortcutType) => title.indexOf(searchString) !== -1)
    : campaigns;

  return (
    <div>
      {filteredCampaigns.map((campaign) => {
        return (
          <CampaignItemInFilters
            key={campaign.id}
            campaign={campaign}
            isChoosen={isChoosen(campaign.id)}
            onClick={() => handleChangeIsChoosenStatus(campaign.id)}
            isInFolder={false}
          />
        );
      })}
    </div>
  );
};

export default FilterByCampaign;
