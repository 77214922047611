import React, { useRef, useState } from 'react';
import { useDetectAdBlock } from 'adblock-detect-react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import cn from 'class-names';

import { DispatchType } from 'src/store';

import { addNotification } from '@redux/actions/notifications.actions';
import { userProfileSelector } from '@redux/selectors/userProfile.selector';
import {
  addIntercomListener,
  show as showIntercom,
  update as updateIntercom,
} from 'src/libs/intercom';

import Display from '@components/Display/Display';
import { SVGIcon } from '@uikit/Icon/Icon';
import getStringHashCode from '@utils/getStringHashCode';

import { DANGER_COLOR, PRIMARY_COLOR } from '@constants/colors';

import './HelpButton.scss';

const HelpButton = (): JSX.Element => {
  const dispatch = useDispatch<DispatchType>();

  const helpTooltipRef = useRef<ReactTooltip>();
  const { info: userProfile } = useSelector(userProfileSelector);
  const adBlockDetected = useDetectAdBlock();
  const [isHelpOpen, setIsHelpOpen] = useState<boolean>(false);

  const token = localStorage.getItem('token');

  const handleHelpCenter = (e) => {
    e.stopPropagation();

    window.open(' https://help.respona.com/', '_blank');
  };

  const handleSuggestFeature = (e) => {
    e.stopPropagation();

    window.open(
      `${
        window.location.origin?.includes('localhost')
          ? 'https://dev.respona.com'
          : window.location.origin
      }/rest/api/v1/roadmap?token=${token}`,
      '_blank'
    );
  };

  const handleChatWithUs = (e) => {
    e.stopPropagation();

    try {
      updateIntercom({
        hide_default_launcher: false,
        email: userProfile?.email || '',
        user_id: getStringHashCode(userProfile?.email),
        name:
          userProfile?.firstName && userProfile?.lastName
            ? `${userProfile.firstName} ${userProfile.lastName}`
            : '',
        alignment: 'left',
      });
      showIntercom();

      addIntercomListener('onHide', () => {
        setTimeout(
          () =>
            updateIntercom({
              hide_default_launcher: true,
            }),
          300
        );
      });
    } catch (e) {
      if (adBlockDetected) {
        dispatch(
          addNotification({
            title:
              'Ad blocker detected. Please make sure to add respona.com to your exceptions list.',
            type: 'error',
          })
        );
      }
    }
  };

  return (
    <div
      className={cn('help-button', { 'help-button--open': isHelpOpen })}
      onClick={(e) => {
        e.stopPropagation();

        if (isHelpOpen) {
          // @ts-ignore
          helpTooltipRef.current?.hideTooltip();
        }
      }}
      tabIndex={0}
      role="button"
      data-for="help-container-tooltip"
      data-tip=""
    >
      <SVGIcon
        icon={isHelpOpen ? 'crossInCircle' : 'speechBoxIcon'}
        color={PRIMARY_COLOR}
      />
      <Display isVisible={!isHelpOpen}>
        <span className="help-button__text">Help?</span>
      </Display>
      <Display isVisible={true}>
        <ReactTooltip
          id="help-button-tooltip"
          className="react-tooltip"
          place="right"
          effect="solid"
          arrowColor="transparent"
          offset={{ right: 30, top: 0 }}
        >
          <div className="sidebar-link__tooltip-content">Need help?</div>
        </ReactTooltip>
      </Display>

      <ReactTooltip
        ref={helpTooltipRef}
        id="help-container-tooltip"
        clickable
        className="react-tooltip help-button__tooltip"
        backgroundColor="#000"
        place="right"
        effect="solid"
        event="click"
        globalEventOff="click"
        isCapture
        offset={{ top: 74 }}
        afterHide={() => setIsHelpOpen(false)}
        afterShow={() => setIsHelpOpen(true)}
        delayHide={1}
        delayShow={1}
      >
        <div className="help-button__option" onClick={handleHelpCenter}>
          <div className="help-button__option-title">
            <div className="help-button__option-icon-container">
              <SVGIcon size={16} icon="questionInCircle" color="#ffffff" />
            </div>
            Help center
          </div>
          <div className="help-button__option-subtitle">
            Instantly find answers to most common questions.
          </div>
        </div>

        <div className="help-button__option" onClick={handleSuggestFeature}>
          <div className="help-button__option-title">
            <div className="help-button__option-icon-container">
              <SVGIcon size={20} icon="lightBulb" color="#ffffff" />
            </div>
            Suggest a feature
          </div>
        </div>

        <div className="help-button__option" onClick={handleChatWithUs}>
          <div className="help-button__option-title">
            <div className="help-button__option-icon-container">
              <SVGIcon size={16} icon="speechBoxIcon" color="#ffffff" />
            </div>
            Chat with us
          </div>
        </div>
      </ReactTooltip>
    </div>
  );
};

export default HelpButton;
