import React, { useCallback, useRef, useState, useMemo } from 'react';
import { useQuery } from 'react-query';
import ContactsSidebarCRMSection from '@components/ContactSidebarCRM/_components/ContactsSidebarCRMSection/ContactsSidebarCRMSection';
import RelationshipStatus from '@components/Relationships/PeopleTable/_components/RelationshipStatus';
import { getDateShort } from '@utils/date';
import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';
import { SVGIcon } from '@uikit/Icon/Icon';
import Loader from '@uikit/Loader/Loader';
import './ContactsSidebarCRMSectionCampaigns.scss';
import { getCampaignOpportunitiesByWebsiteIdApi } from '@api/website.api';
import { getCampaignOpportunitiesByPersonIdApi } from '@api/people.api';
import { WebsiteType } from '@ts/company.types';
import { PersonBaseType } from '@ts/people.types';

function ContactsSidebarCRMSectionCampaigns({
  website,
  person,
  index,
}: {
  website?: WebsiteType;
  person?: PersonBaseType;
  index: number;
}) {
  const ref = useRef<{ setOpen: (open: boolean) => void } | null>(null);
  const workspaceId = useCurrentWorkspaceId();

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const queryEnabled = useMemo(() => !!(person?.id || website?.id), [person?.id, website?.id]);

  const fetchCampaignOpportunities = useCallback(() => {
    if (website?.id) {
      return getCampaignOpportunitiesByWebsiteIdApi(website.id, workspaceId);
    }
    return getCampaignOpportunitiesByPersonIdApi(person!.id, workspaceId);
  }, [website?.id, person?.id, workspaceId]);

  const { data, isLoading } = useQuery(
    ['contacts-sidebar-campaigns', person?.id, website?.id],
    fetchCampaignOpportunities,
    {
      enabled: queryEnabled,
      refetchOnWindowFocus: false,
      onSettled: (response) => {
        if (response?.length && ref.current) {
          ref.current.setOpen(true);
        }
      },
      staleTime: 15 * 60 * 1000,
    }
  );

  const onOpenCallback = useCallback(() => {
    setIsExpanded(true);
  }, []);

  return (
    <ContactsSidebarCRMSection
      ref={ref}
      index={index}
      id="campaigns"
      title="Campaign(s)"
      onOpen={onOpenCallback}
    >
      <div className="contacts-sidebar-section-opportunities">
        {/* <div className="contacts-sidebar-section-opportunities__opportunity"> */}
        {/*  <div */}
        {/*    className="contacts-sidebar-section-opportunities__opportunity-title" */}
        {/*    onClick={() => window.open('htto:/asdas', '_blank')} */}
        {/*  > */}
        {/*    <span>{'htto:/asdas'}</span> */}
        {/*    <SVGIcon icon="externalLink" color="#221CB6" size={13} /> */}
        {/*  </div> */}
        {/*  <div className="contacts-sidebar-section-opportunities__opportunity-people"> */}
        {/*    <SVGIcon icon="connection" color="#C4C6CD" /> */}
        {/*    <span>title</span> */}
        {/*  </div> */}
        {/*  <div className="contacts-sidebar-section-opportunities__opportunity-status"> */}
        {/*    <SVGIcon icon="flag" color="#C4C6CD" /> */}
        {/*    <RelationshipStatus */}
        {/*      status={1} */}
        {/*      isBlocked={false} */}
        {/*      withIcon={false} */}
        {/*    /> */}
        {/*  </div> */}
        {/*  <div className="contacts-sidebar-section-opportunities__opportunity-date"> */}
        {/*    <SVGIcon icon="linkChain" color="#C4C6CD" /> */}
        {/*     -*/}
        {/*  </div> */}
        {/* </div> */}

        <Loader isLoading={isLoading} withTopMargin>
          {data?.length > 0 ? (
            data.slice(0, isExpanded ? undefined : 3).map((opportunity) => (
              <div
                key={opportunity.opportunityId}
                className="contacts-sidebar-section-opportunities__opportunity"
              >
                <div
                  className="contacts-sidebar-section-opportunities__opportunity-title"
                  onClick={() =>
                    window.open(`/campaign-analytics/${opportunity.campaignId}/people`, '_blank')
                  }
                >
                  <span>{opportunity.campaignTitle}</span>
                  <SVGIcon icon="externalLink" color="#221CB6" size={13} />
                </div>
                <div className="contacts-sidebar-section-opportunities__opportunity-date">
                  <SVGIcon icon="personRounded" color="#C4C6CD" />
                  {opportunity.email}
                </div>
                <div className="contacts-sidebar-section-opportunities__opportunity-status">
                  <SVGIcon icon="flagStriped" color="#C4C6CD" size={16} />
                  <RelationshipStatus
                    status={opportunity.opportunityStatus}
                    isBlocked={false}
                    withIcon={false}
                  />
                </div>
                <div className="contacts-sidebar-section-opportunities__opportunity-date">
                  <SVGIcon icon="clockThree" color="#C4C6CD" />
                  {opportunity.lastActivity > 0
                    ? getDateShort(new Date(opportunity.lastActivity))
                    : '-'}
                </div>
                <div className="contacts-sidebar-section-opportunities__opportunity-date">
                  <SVGIcon icon="cardio" color="#C4C6CD" />
                  {opportunity.nextActivity > 0
                    ? getDateShort(new Date(opportunity.nextActivity))
                    : '-'}
                </div>
              </div>
            ))
          ) : (
            <div className="contacts-sidebar-section-opportunities--empty">Empty</div>
          )}

          {!isExpanded && data?.length > 3 && (
            <div className="contact-sidebar-crm__show-more-btn" onClick={() => setIsExpanded(true)}>
              Show More
              <SVGIcon icon="chevron" size={8} />
            </div>
          )}
        </Loader>
      </div>
    </ContactsSidebarCRMSection>
  );
}

export default ContactsSidebarCRMSectionCampaigns;
