import React from 'react';

export default ({ color = '#fff' }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.267 7.3263L8.692 10.9096L7.317 9.53464C7.24229 9.4474 7.15036 9.37655 7.04697 9.32653C6.94358 9.27651 6.83097 9.2484 6.71621 9.24397C6.60144 9.23954 6.487 9.25888 6.38006 9.30077C6.27312 9.34267 6.176 9.40621 6.09479 9.48743C6.01357 9.56864 5.95003 9.66576 5.90813 9.7727C5.86624 9.87964 5.8469 9.99408 5.85133 10.1088C5.85576 10.2236 5.88387 10.3362 5.93389 10.4396C5.98391 10.543 6.05476 10.6349 6.142 10.7096L8.10033 12.6763C8.1782 12.7535 8.27054 12.8146 8.37207 12.8561C8.4736 12.8976 8.58232 12.9186 8.692 12.918C8.91061 12.917 9.12011 12.8303 9.27533 12.6763L13.442 8.50964C13.5201 8.43217 13.5821 8.34 13.6244 8.23845C13.6667 8.1369 13.6885 8.02798 13.6885 7.91797C13.6885 7.80796 13.6667 7.69904 13.6244 7.59749C13.5821 7.49594 13.5201 7.40377 13.442 7.3263C13.2859 7.17109 13.0747 7.08398 12.8545 7.08398C12.6343 7.08398 12.4231 7.17109 12.267 7.3263ZM10.0003 1.66797C8.35215 1.66797 6.74099 2.15671 5.37058 3.07239C4.00017 3.98807 2.93206 5.28956 2.30133 6.81227C1.6706 8.33499 1.50558 10.0105 1.82712 11.6271C2.14866 13.2436 2.94234 14.7284 4.10777 15.8939C5.27321 17.0593 6.75807 17.853 8.37458 18.1745C9.99109 18.4961 11.6666 18.331 13.1894 17.7003C14.7121 17.0696 16.0136 16.0015 16.9292 14.6311C17.8449 13.2606 18.3337 11.6495 18.3337 10.0013C18.3337 8.90695 18.1181 7.82332 17.6993 6.81227C17.2805 5.80123 16.6667 4.88257 15.8929 4.10875C15.1191 3.33492 14.2004 2.7211 13.1894 2.30231C12.1783 1.88352 11.0947 1.66797 10.0003 1.66797ZM10.0003 16.668C8.68179 16.668 7.39286 16.277 6.29653 15.5444C5.2002 14.8119 4.34572 13.7707 3.84113 12.5525C3.33655 11.3344 3.20453 9.99391 3.46176 8.7007C3.719 7.40749 4.35393 6.21961 5.28628 5.28726C6.21863 4.35491 7.40652 3.71997 8.69973 3.46273C9.99293 3.2055 11.3334 3.33752 12.5516 3.84211C13.7697 4.34669 14.8109 5.20117 15.5435 6.2975C16.276 7.39383 16.667 8.68276 16.667 10.0013C16.667 11.7694 15.9646 13.4651 14.7144 14.7153C13.4641 15.9656 11.7684 16.668 10.0003 16.668Z"
      fill={color}
    />
  </svg>
);
