import React from 'react';

const TotalRow = ({ title, number }: { title: string; number: number }): JSX.Element => {
  return (
    <>
      <div />
      <div className="invoice-info__cost-grid-cell invoice-info__cost-grid-cell--with-border">
        {title}
      </div>
      <div className="invoice-info__cost-grid-cell invoice-info__cost-grid-cell--righted invoice-info__cost-grid-cell--with-border">
        $ {number.toFixed(2)}
      </div>
    </>
  );
};

export default TotalRow;
