import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ContentAutomationSearchingMode,
  PodcastSearch,
  PodcastSearchSource,
  PodcastSearchSourceMap,
} from 'respona_api/generated/automation-content_pb';
import { PodcastSearchType } from '@ts/automationContent.types';
import { DispatchType } from 'src/store';

import {
  automationContentData,
  automationSubTypeSelector,
} from '@redux/selectors/automationContent.selectors';

import { SVGIcon } from '@uikit/Icon/Icon';

import { StepsContext } from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/CASearchTypeWrapper/CASearchTypeWrapper';
import PodcastSearchAdvancedSettings from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/PodcastSearchAdvancedSettings/PodcastSearchAdvancedSettings';
import ShowsSVG from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/assets/ShowsSVG';
import EpisodesSVG from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/assets/EpisodesSVG';
import ListenNotesMetrics from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/ListenNotesMetrics/ListenNotesMetrics';
import AutomationSourceOption, {
  AutomationSourceOptionsRow,
} from '@components/CampaignSettings/ContactAutomationSetup/_components/AutomationSourceSelect/_components/AutomationSourceOption';
import ContentSearchEditorV2, {
  onChangeQueriesType,
} from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/ContentSearchEditor/ContentSearchEditorV2';
import SettingsStep from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/SettingsStep/SettingsStep';

import './PodcastsSearch.scss';
import { CampaignPipelineType } from 'respona_api/generated/pipeline_pb';

const podcastsSourceOptions = [
  {
    type: PodcastSearchSource.EPISODES_PODCAST_SEARCH,
    title: 'Episodes',
    poweredBy: 'Listen Notes',
    image: <EpisodesSVG />,
    statusText: (
      <>
        <SVGIcon icon="wallet" className="respona-status-tag__icon" />
        <span>1 credit per result</span>
      </>
    ),
  },
  {
    type: PodcastSearchSource.SHOWS_PODCAST_SEARCH,
    title: 'Podcasts',
    poweredBy: 'Listen Notes',
    image: <ShowsSVG />,
    statusText: (
      <>
        <SVGIcon icon="wallet" className="respona-status-tag__icon" />
        <span>1 credit per result</span>
      </>
    ),
  },
];

const PodcastsSearch = ({
  setSourceRef,
  data,
}: {
  setSourceRef: (value: number) => void;
  data?: PodcastSearchType;
}) => {
  const dispatch = useDispatch<DispatchType>();

  const { activeStepIndex, onChangeActiveStepIndex } = useContext(StepsContext);

  const automationSubType = useSelector(automationSubTypeSelector);

  const initialSource = () => {
    switch (automationSubType) {
      case 2:
        return PodcastSearchSource.SHOWS_PODCAST_SEARCH;
      case 3:
        return PodcastSearchSource.EPISODES_PODCAST_SEARCH;
      default:
        PodcastSearchSource.EPISODES_PODCAST_SEARCH;
    }
    return PodcastSearchSource.EPISODES_PODCAST_SEARCH;
  };

  const [source, setSource] = useState<PodcastSearchSourceMap[keyof PodcastSearchSourceMap]>(
    initialSource()
  );

  const automationConfig = useSelector(
    automationContentData(ContentAutomationSearchingMode.PODCAST_SEARCH, source)
  );

  const handleChangeKeywords: onChangeQueriesType = (keywordsList) => {
    dispatch(automationConfig.set({ keywordsList }));
  };

  const handleChangeAdvancedSettings = (advancedSettings: Partial<PodcastSearchType>) => {
    dispatch(automationConfig.set(advancedSettings));
  };

  useEffect(() => {
    setSourceRef(source);
  }, [source]);

  useEffect(() => {
    onChangeActiveStepIndex(1);
  }, []);

  const config = automationConfig.data as PodcastSearch.AsObject;

  if (!config) {
    return null;
  }

  switch (activeStepIndex) {
    case 1:
      return (
        <>
          <div className="podcast-search__title">Select source:</div>
          <AutomationSourceOptionsRow>
            {podcastsSourceOptions.map(({ type, title, poweredBy, image, statusText }) => (
              <AutomationSourceOption
                key={title}
                image={image}
                title={title}
                onSelect={() => setSource(type)}
                isSelected={config.source === type}
                subtitle="Powered by:"
                subtitleDetails={poweredBy}
                statusText={statusText}
              />
            ))}
          </AutomationSourceOptionsRow>

          <ContentSearchEditorV2
            searchSourceKey="podcastsConfig"
            onChangeQueries={handleChangeKeywords}
            queries={config.keywordsList}
            source={source}
          />
          <PodcastSearchAdvancedSettings
            settings={config}
            onChangeSettings={handleChangeAdvancedSettings}
          />
        </>
      );
    case 2:
      return <ListenNotesMetrics source={source} />;
    case 3:
      return <SettingsStep data={data} type={CampaignPipelineType.PODCAST_SEARCH} />;
    default:
      onChangeActiveStepIndex(1);
      return null;
  }
};

export default PodcastsSearch;
