import React from 'react';

export default ({ color = '#8F9199' }) => (
  <svg width="32" height="36" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2334 6.76932C11.1358 4.63894 13.246 3.14439 15.7052 3.14439C18.9857 3.14439 21.645 5.80373 21.645 9.08419C21.645 11.5434 20.1505 13.6536 18.0201 14.556L20.3543 16.8902C23.0105 15.3049 24.7894 12.4023 24.7894 9.08419C24.7894 4.06713 20.7223 0 15.7052 0C12.3871 0 9.48455 1.77895 7.8992 4.43512L10.2334 6.76932ZM10.1981 11.3138L6.7078 7.82353C6.6506 8.23555 6.62103 8.65642 6.62103 9.08419C6.62103 14.1012 10.6882 18.1684 15.7052 18.1684C16.133 18.1684 16.5539 18.1388 16.9659 18.0816L13.4756 14.5913C11.9884 13.9886 10.8008 12.801 10.1981 11.3138ZM19.6545 20.7702C18.5977 20.7209 17.4973 20.7006 16.3672 20.7006C8.26305 20.7006 1.69336 21.7487 1.69336 26.9893C1.69336 32.23 8.26305 33.2781 16.3672 33.2781C22.0984 33.2781 27.0622 32.7539 29.4777 30.5934L27.1724 28.2881C26.9007 28.5456 26.4159 28.8571 25.5627 29.1518C23.5218 29.8567 20.3867 30.1337 16.3672 30.1337C12.3476 30.1337 9.21254 29.8567 7.17169 29.1518C6.19634 28.815 5.70243 28.4561 5.45791 28.1811C5.26178 27.9605 5.07962 27.6382 5.07962 26.9893C5.07962 26.3405 5.26178 26.0182 5.45791 25.7976C5.70243 25.5226 6.19634 25.1637 7.17169 24.8268C9.21254 24.122 12.3476 23.8449 16.3672 23.8449C19.0344 23.8449 21.3122 23.9669 23.1392 24.255L19.6545 20.7702ZM31.0146 27.5505C31.0321 27.369 31.041 27.182 31.041 26.9893C31.041 23.7851 28.585 22.1481 24.8239 21.3598L31.0146 27.5505Z"
      fill={color}
    />
    <rect
      y="5.66406"
      width="3.23841"
      height="42.0164"
      rx="1.6192"
      transform="rotate(-45 0 5.66406)"
      fill={color}
    />
  </svg>
);
