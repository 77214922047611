import React from 'react';
import cn from 'class-names';

import { SVGIcon } from '@uikit/Icon/Icon';

const CollapsibleSidebarButton = ({
  isOpen,
  onClick,
  children,
}: {
  isOpen: boolean;
  onClick: () => void;
  children?: React.ReactNode;
}): JSX.Element => {
  const cnPointer = cn('collapsible-sidebar__button', {
    'collapsible-sidebar__button--opened': isOpen,
  });

  return (
    <div className={cnPointer} onClick={onClick} tabIndex={0} role="button">
      <SVGIcon icon="selectedOpportunities" />
      <div className="collapsible-sidebar__button-content-wrapper">
        <SVGIcon icon="arrowLeft" />
        <span className="collapsible-sidebar__button-content">{children}</span>
      </div>
    </div>
  );
};

export default CollapsibleSidebarButton;
