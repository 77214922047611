/**
 * Return the list of items where one one items is replaced by second param
 * Necessary of replacing depends on items ids
 *
 * @param list
 * @param item
 */
export const updateItemInListByIdHelper = (list, item) =>
  list.map((listItem) => (listItem.id === item.id ? { ...listItem, ...item } : listItem));

export function updateItemInListByFieldNameHelper<T>(list: T[], item: T, fieldName: string): T[] {
  return list.map((listItem) =>
    listItem[fieldName] === item[fieldName] ? { ...listItem, ...item } : listItem
  );
}

export const filterListByFieldHelper = (list, values, fieldname) =>
  list.filter((listItem) => !values.includes(listItem[fieldname]));

export const onlyNumbersInStringCheck = (value: string) => /^[0-9]*$/.test(value);

export const mapMinMaxToFromTo = (value) => ({
  from: value.min === -1 ? '' : value.min,
  to: value.max === -1 ? '' : value.max,
});

export const mapFromToToMinMax = (value) => ({
  min: value.from === '' ? -1 : Number(value.from),
  max: value.to === '' ? -1 : Number(value.to),
});
