import React from 'react';
import cn from 'class-names';

import './FilterCreationSteps.scss';

const steps = [
  {
    title: 'Settings',
    key: 'settings',
  },
  {
    title: 'Keywords',
    key: 'keywords',
  },
  {
    title: 'Bio',
    key: 'bio',
  },
];

export default ({
  activeStepIndex,
  setActiveStepIndex,
  stepsDisabled,
}: {
  activeStepIndex: number;
  setActiveStepIndex: (index: number) => void;
  stepsDisabled?: boolean;
}): JSX.Element => {
  return (
    <div className="filter-creation-steps">
      {steps.map(({ title, key }, index) => (
        <div
          className={cn('filter-creation-steps__step', {
            'filter-creation-steps__step--active': activeStepIndex === index + 1,
            'filter-creation-steps__step--disabled': stepsDisabled,
          })}
          key={key}
          onClick={() => (stepsDisabled ? null : setActiveStepIndex(index + 1))}
        >
          <div className="filter-creation-steps__step-index">0{index + 1}</div>
          <div className="filter-creation-steps__step-title">{title}</div>
        </div>
      ))}
    </div>
  );
};
