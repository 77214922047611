// package: search.contact
// file: search-contact.proto

var search_contact_pb = require("./search-contact_pb");
var common_pb = require("./common_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var ContactSearchService = (function () {
  function ContactSearchService() {}
  ContactSearchService.serviceName = "search.contact.ContactSearchService";
  return ContactSearchService;
}());

ContactSearchService.searchByDomain = {
  methodName: "searchByDomain",
  service: ContactSearchService,
  requestStream: false,
  responseStream: true,
  requestType: search_contact_pb.DomainSearchRequest,
  responseType: search_contact_pb.PersonItemResponse
};

ContactSearchService.searchByDomainAndName = {
  methodName: "searchByDomainAndName",
  service: ContactSearchService,
  requestStream: false,
  responseStream: true,
  requestType: search_contact_pb.DomainAndNameSearchRequest,
  responseType: search_contact_pb.PersonItemResponse
};

ContactSearchService.searchByDomainAndJobPosition = {
  methodName: "searchByDomainAndJobPosition",
  service: ContactSearchService,
  requestStream: false,
  responseStream: true,
  requestType: search_contact_pb.DomainAndJobPositionSearchRequest,
  responseType: search_contact_pb.PersonItemResponse
};

ContactSearchService.searchBySocialLink = {
  methodName: "searchBySocialLink",
  service: ContactSearchService,
  requestStream: false,
  responseStream: true,
  requestType: search_contact_pb.SocialLinkSearchRequest,
  responseType: search_contact_pb.PersonItemResponse
};

ContactSearchService.searchPersonDetails = {
  methodName: "searchPersonDetails",
  service: ContactSearchService,
  requestStream: false,
  responseStream: false,
  requestType: search_contact_pb.PersonDetailsRequest,
  responseType: search_contact_pb.PersonItemResponse
};

ContactSearchService.searchPersonDetailsByNameAndDomain = {
  methodName: "searchPersonDetailsByNameAndDomain",
  service: ContactSearchService,
  requestStream: false,
  responseStream: false,
  requestType: search_contact_pb.PersonDetailsByNameAndDomainRequest,
  responseType: search_contact_pb.PersonItemResponse
};

ContactSearchService.verifyEmail = {
  methodName: "verifyEmail",
  service: ContactSearchService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.StringRequest,
  responseType: search_contact_pb.VerifyEmailResponse
};

exports.ContactSearchService = ContactSearchService;

function ContactSearchServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

ContactSearchServiceClient.prototype.searchByDomain = function searchByDomain(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(ContactSearchService.searchByDomain, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

ContactSearchServiceClient.prototype.searchByDomainAndName = function searchByDomainAndName(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(ContactSearchService.searchByDomainAndName, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

ContactSearchServiceClient.prototype.searchByDomainAndJobPosition = function searchByDomainAndJobPosition(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(ContactSearchService.searchByDomainAndJobPosition, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

ContactSearchServiceClient.prototype.searchBySocialLink = function searchBySocialLink(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(ContactSearchService.searchBySocialLink, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

ContactSearchServiceClient.prototype.searchPersonDetails = function searchPersonDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ContactSearchService.searchPersonDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ContactSearchServiceClient.prototype.searchPersonDetailsByNameAndDomain = function searchPersonDetailsByNameAndDomain(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ContactSearchService.searchPersonDetailsByNameAndDomain, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ContactSearchServiceClient.prototype.verifyEmail = function verifyEmail(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ContactSearchService.verifyEmail, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.ContactSearchServiceClient = ContactSearchServiceClient;

