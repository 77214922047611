import React from 'react';

const SelectedOpportunitiesAbsoluteIcon = ({ color = '#221CB6' }) => (
  <svg width="30" height="107" viewBox="0 0 30 107" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 53.345C0 62.6845 4.75303 71.382 12.6134 76.4259L13.3638 76.9074C20.0604 81.2046 24.9075 87.8512 26.9525 95.5406L30 107L30 0L26 14.7597C24.566 20.0513 21.2372 24.6314 16.6468 27.6291L12.4295 30.3832C4.67478 35.4472 0 44.0833 0 53.345Z"
      fill={color}
    />
  </svg>
);

export default SelectedOpportunitiesAbsoluteIcon;
