import React from 'react';

export default ({ color = '#C4C6CD' }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.4465 14.2868C9.94431 13.2994 9.35684 12.3031 8.20844 12.3031C7.99035 12.3031 7.76904 12.3401 7.56865 12.4294L7.17914 11.6512C7.65395 11.2416 8.4217 10.9181 9.40834 10.9181C10.9438 10.9181 11.7333 11.6601 12.3594 12.6033C12.7288 11.7977 12.905 10.7088 12.905 9.36005C12.905 5.9897 11.8524 4.26107 9.39144 4.26107C6.96588 4.26107 5.91647 5.9897 5.91647 9.36005C5.91647 12.7111 6.96669 14.4228 9.39144 14.4228C9.77612 14.4228 10.1262 14.3818 10.4465 14.2868ZM11.0476 15.4626C10.5073 15.608 9.95022 15.6821 9.39064 15.6831C6.16031 15.6831 3 13.1062 3 9.35925C3.0008 5.57766 6.16031 3 9.39064 3C12.6749 3 15.8062 5.55835 15.8062 9.36005C15.8062 11.475 14.8196 13.1931 13.3863 14.3037C13.8466 14.9966 14.3174 15.4594 14.9862 15.4594C15.7096 15.4594 16.001 14.9065 16.0549 14.4647H16.9941C17.0504 15.0522 16.7518 17.4858 14.1178 17.4858C12.5171 17.4858 11.6754 16.5635 11.042 15.4771L11.0476 15.4626Z"
      fill={color}
    />
  </svg>
);
