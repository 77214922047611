import React from 'react';

export default ({ color = '#C4C6CD' }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.4999 9.62514C17.4999 8.5896 16.6625 7.75014 15.6296 7.75014C15.2219 7.75438 14.8272 7.89419 14.5073 8.14764C13.3503 7.47195 12.0509 7.07885 10.7142 7.00014L11.178 4.33764C11.1894 4.2471 11.2666 4.17942 11.3576 4.18014H11.41L13.7592 4.90014C13.8311 5.61446 14.3979 6.17753 15.111 6.2432C15.8242 6.30887 16.4838 5.85872 16.6844 5.16949C16.8849 4.48025 16.5701 3.74521 15.9335 3.41623C15.297 3.08725 14.5171 3.25658 14.0734 3.82014L11.7092 3.13014C11.5897 3.09749 11.4665 3.07985 11.3426 3.07764C10.7073 3.07913 10.1647 3.53766 10.0558 4.16514L9.57697 7.00014C8.15397 7.04167 6.76338 7.43591 5.52945 8.14764C5.20962 7.89419 4.81489 7.75438 4.40722 7.75014C3.58839 7.73067 2.85293 8.24962 2.59496 9.02891C2.33698 9.8082 2.61701 10.665 3.28498 11.1401C3.27757 11.26 3.27757 11.3802 3.28498 11.5001C3.28498 13.9826 6.27761 16.0001 10.0184 16.0001C13.7592 16.0001 16.7518 13.9826 16.7518 11.5001C16.7592 11.3802 16.7592 11.26 16.7518 11.1401C17.227 10.7829 17.5047 10.2206 17.4999 9.62514ZM6.27761 10.3751C6.27761 9.75382 6.78005 9.25014 7.39984 9.25014C8.01963 9.25014 8.52207 9.75382 8.52207 10.3751C8.52207 10.9965 8.01963 11.5001 7.39984 11.5001C6.78005 11.5001 6.27761 10.9965 6.27761 10.3751ZM12.42 13.7051C12.3934 13.8076 12.3257 13.8945 12.2329 13.9451C10.8488 14.6851 9.18793 14.6851 7.80384 13.9451C7.71105 13.8945 7.64332 13.8076 7.61681 13.7051C7.59516 13.6011 7.61678 13.4928 7.67666 13.4051L7.89362 13.0976C8.00522 12.9477 8.21134 12.9057 8.37244 13.0001C9.41256 13.5023 10.6242 13.5023 11.6643 13.0001C11.8309 12.9103 12.0383 12.9623 12.1431 13.1201L12.3601 13.4276C12.4141 13.5093 12.4355 13.6084 12.42 13.7051ZM12.6369 11.5001C12.0171 11.5001 11.5147 10.9965 11.5147 10.3751C11.5147 9.75382 12.0171 9.25014 12.6369 9.25014C13.2567 9.25014 13.7592 9.75382 13.7592 10.3751C13.7592 10.9965 13.2567 11.5001 12.6369 11.5001Z"
      fill={color}
    />
  </svg>
);
