import React from 'react';
import { useSelector } from 'react-redux';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';

import { getCurrentWorkspaceId } from '@redux/selectors/workspaces.selectors';
import { transformWorkspaceMemberToOptions } from '@mappers/optionTransformers';
import { HaroFilterPropsType } from '@ts/props.types';

import SlideToggler from '@uikit/SlideToggler/SlideToggler';
import Input from '@uikit/Input/Input';
import Select from '@uikit/Select/Select';
import Button from '@uikit/Button/Button';
import EmailSelect from '@uikit/EmailSelect/EmailSelect';

import './FilterCreationSettingsStep.scss';
import { useQuery } from 'react-query';
import { getAllMembersOfWorkspaceApi } from '@api/userOrganization.api';
import Loader from '@uikit/Loader/Loader';
import useMailboxAccounts from '@hooks/useMailboxAccounts';
import { QueryCollectionHookType } from '@ts/common.types';
import { MailboxAccountType } from '@ts/mailboxAccounts.types';

export default function ({
  filterFields,
  onFieldChange,
  onContinue,
}: {
  filterFields: HaroFilterPropsType;
  onFieldChange: (fieldName: string, value: any) => void;
  onContinue: () => void;
}): JSX.Element {
  const curWorkspaceId = useSelector(getCurrentWorkspaceId);

  const { isLoading: isMailboxLoading }: QueryCollectionHookType<MailboxAccountType> =
    useMailboxAccounts(curWorkspaceId);
  const handleTitleChange = ({ target: { value } }) => onFieldChange('title', value);

  const {
    data: workspaceMembers,
    isFetching: isWorkspaceMembersFetching,
    refetch,
  } = useQuery(
    ['members-of-workspace', curWorkspaceId],
    () => getAllMembersOfWorkspaceApi(curWorkspaceId),
    {
      enabled: !!curWorkspaceId,
      refetchOnWindowFocus: false,
      placeholderData: [],
    }
  );

  const isContinueDisabled =
    filterFields.title.trim() === '' || !filterFields.pitchEmailFrom?.value;

  const workspaceMemberOptions = transformWorkspaceMemberToOptions(workspaceMembers);
  const selectedNotifiedUserIds = filterFields.sendNotificationsTo.map((usr) => usr.userId);
  const sendNotificationToMemberSelectedOption = workspaceMemberOptions.filter((mem) =>
    selectedNotifiedUserIds.includes(mem.userId)
  );
  return (
    <Loader isLoading={isWorkspaceMembersFetching} withTopMargin>
      <div className="filter-creation-settings-step">
        <div className="filter-creation-settings-step__row">
          <div className="filter-creation-settings-step__field-title">Filter Name</div>
          <div className="filter-creation-settings-step__field">
            <Input
              value={filterFields.title}
              onChange={handleTitleChange}
              placeholder="Add filter name"
            />
          </div>
        </div>
        <div className="filter-creation-settings-step__row">
          <div className="filter-creation-settings-step__field-title">Pitch email from</div>
          <Loader withTopMargin isLoading={isMailboxLoading}>
            <div className="filter-creation-settings-step__field">
              <EmailSelect
                value={filterFields.pitchEmailFrom}
                onChange={(option) => onFieldChange('pitchEmailFrom', option)}
                onAddNewEmail={() => refetch()}
              />
              {/* <Select */}
              {/*  options={transformEmailToOptions(mbAccounts)} */}
              {/*  value={filterFields.pitchEmailFrom} */}
              {/*  onChange={(option) => onFieldChange('pitchEmailFrom', option)} */}
              {/*  placeholder="Pitch email form" */}
              {/* /> */}
            </div>
          </Loader>
        </div>
        <div className="filter-creation-settings-step__row">
          <div className="filter-creation-settings-step__field-title">
            Send daily notification to
          </div>
          <div className="filter-creation-settings-step__field">
            <Select
              options={workspaceMemberOptions}
              placeholder="Anonymous"
              value={sendNotificationToMemberSelectedOption}
              onChange={(option) => onFieldChange('sendNotificationsTo', option)}
              isMulti
            />
          </div>
        </div>
        <div className="filter-creation-settings-step__row">
          <div className="filter-creation-settings-step__field-title">
            Automatically remove anonymous sources
          </div>
          <div className="filter-creation-settings-step__field">
            <SlideToggler
              value={filterFields.removeAnonymous}
              onChange={(value) => onFieldChange('removeAnonymous', value)}
            />
          </div>
        </div>
        <div className="filter-creation-settings-step__row">
          <div className="filter-creation-settings-step__field-title">Domain authority</div>
          <div className="filter-creation-settings-step__field filter-creation-settings-step__field--range">
            <InputRange
              minValue={0}
              maxValue={100}
              value={{ min: filterFields.domainRating.min, max: filterFields.domainRating.max }}
              onChange={(value) => onFieldChange('domainRating', value)}
            />
          </div>
        </div>
        <div className="filter-creation-settings-step__footer">
          <Button onClick={onContinue} disabled={isContinueDisabled}>
            Continue
          </Button>
        </div>
      </div>
    </Loader>
  );
}
