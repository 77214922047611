// @ts-nocheck
/* eslint-disable */
import React from 'react';

const MozSvg = (): JSX.Element => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="21" height="21" rx="5" fill="#24ABE2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4.03809 14.3159H6.15362C6.3721 14.3159 6.55076 14.1372 6.55076 13.9187V10.2532L10.4996 14.6512L14.4487 10.2532V13.9187C14.4487 14.1372 14.6273 14.3159 14.8458 14.3159H16.9612V6.46167H15.1649C14.6458 6.46167 14.2588 6.71172 14.0498 6.94432L10.4996 10.8981L6.94948 6.94432C6.74061 6.71172 6.35347 6.46167 5.83454 6.46167H4.03809V14.3159Z" fill="white"/>
    </svg>

  );
};

export default MozSvg;
