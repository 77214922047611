import React from 'react';

function ReportIcon({
  color = '#8F9199',
  size = 26,
}: {
  color?: string;
  size?: number;
}): JSX.Element {
  return (
    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.5 0.583984H2.5C1.5335 0.583984 0.75 1.36748 0.75 2.33398V11.6673C0.75 12.6338 1.5335 13.4173 2.5 13.4173H9.5C10.4665 13.4173 11.25 12.6338 11.25 11.6673V2.33398C11.25 1.36749 10.4665 0.583984 9.5 0.583984Z"
        stroke="#221CB6"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.08301 4.08398H8.91634"
        stroke="#221CB6"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.08398 7.00098H8.91732"
        stroke="#221CB6"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.08398 9.91699H5.41732"
        stroke="#221CB6"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ReportIcon;
