import React, { RefObject } from 'react';

import cn from 'class-names';

import Input from '@uikit/Input/Input';

import './Input.scss';

export type NumberInputValueType = number | '';

type NumberInputType = {
  value: NumberInputValueType;
  maxNumber: number;
  minNumber: number;
  onChange: (value: number | '') => void;
  inputRef?: RefObject<HTMLInputElement>;
  disabledArrows?: boolean;
  className?: string;
  [key: string]: any;
};

const NumberInput = ({
  value,
  maxNumber,
  minNumber,
  onChange,
  inputRef,
  disabledArrows = false,
  className,
  ...rest
}: NumberInputType): JSX.Element => {
  const cnNumberInput = cn('number-input', {
    'number-input--without-arrows': disabledArrows,
    [className]: !!className,
  });

  return (
    <Input
      placeholder=""
      className={cnNumberInput}
      inputRef={inputRef}
      type="number"
      value={value}
      onBlur={({ target: { value: val } }) => {
        if (Number.isNaN(Number(val)) || val < minNumber) {
          onChange(minNumber);
          return;
        }
      }}
      onChange={({ target: { value: val } }) => {
        if (val === '') {
          onChange('');
          return;
        }

        if (val > maxNumber) {
          onChange(maxNumber);
          return;
        }

        onChange(val);
      }}
      {...rest}
    />
  );
};

export default NumberInput;
