import React from 'react';

import { VariableType } from '@constants/variables';

import { SVGIcon, SvgIconType } from '@uikit/Icon/Icon';
import { getDateShort } from '@utils/date';

import ActionsMenu from '@components/ActionsMenu/ActionsMenu';

import './CustomFields.scss';
import { CustomVariableType } from 'respona_api/generated/common_pb';

export const fieldTypeToIconMap: {
  [key: number]: SvgIconType;
} = {
  [CustomVariableType.URL]: 'link',
  [CustomVariableType.TEXT]: 'textLetter',
  [CustomVariableType.INT]: 'number',
  [CustomVariableType.OPEN_AI]: 'ai',
  [CustomVariableType.TEAM_MEMBER]: 'teamMember',
  [CustomVariableType.CHECKBOX]: 'checkbox',
  [CustomVariableType.DATE_AND_TIME]: 'dateTime',
  [CustomVariableType.SELECT]: 'select',
  [CustomVariableType.MULTI_SELECT]: 'multiSelect',
  [CustomVariableType.RATING]: 'rating',
};

export const fieldTypeToTypeString: {
  [key: number]: string;
} = {
  [CustomVariableType.URL]: 'URL',
  [CustomVariableType.TEXT]: 'Text',
  [CustomVariableType.INT]: 'Number',
  [CustomVariableType.OPEN_AI]: 'AI',
  [CustomVariableType.TEAM_MEMBER]: 'Team member',
  [CustomVariableType.CHECKBOX]: 'Checkbox',
  [CustomVariableType.DATE_AND_TIME]: 'Date',
  [CustomVariableType.SELECT]: 'Select',
  [CustomVariableType.MULTI_SELECT]: 'Multi Select',
  [CustomVariableType.RATING]: 'Rating',
};

export const customFieldsTableConfig = [
  // {
  //   title: '',
  //   dataKey: 'checkbox',
  //   isSelectable: false,
  //   RenderTitle: ({
  //     renderProps: { isAllSelected, onToggleSelectAll },
  //   }): JSX.Element => {
  //     const handleToggleSelectAll = ({ target: { checked } }) => onToggleSelectAll(checked);
  //
  //     return (
  //       <div className="organizations-table__select-all-cb">
  //         <Checkbox onChange={handleToggleSelectAll} value={isAllSelected} />
  //       </div>
  //     );
  //   },
  //   RenderCell: ({ row, renderProps: { selectedRows, onToggleRowSelection } }): JSX.Element => {
  //     const handleSelectRow = (e) => {
  //       e.stopPropagation();
  //       onToggleRowSelection(row.id);
  //     };
  //
  //     return <Checkbox onChange={handleSelectRow} value={Boolean(selectedRows?.[row.id])} />;
  //   },
  // },
  {
    title: 'Name',
    dataKey: 'name',
    RenderCell: ({
      row: { title, isDefault, type, description },
    }: {
      row: VariableType;
    }): JSX.Element => (
      <div className="custom-fields__table__cell custom-fields__table__cell--name">
        <div className="custom-fields__table__icon">
          <SVGIcon icon={fieldTypeToIconMap[type]} size={13} color="#8F9199" />
        </div>
        <div className="custom-fields__table__cell--name-details">
          <div className="custom-fields__table__field-name">
            {title}
            {isDefault ? <div className="custom-fields__default-tag">Default</div> : null}
          </div>
          <div className="custom-fields__table__field-email">{description}</div>
        </div>
      </div>
    ),
  },
  {
    title: 'Type',
    dataKey: 'type',
    RenderCell: ({ row: { type } }: { row: VariableType }): JSX.Element => (
      <span className="custom-fields__table__cell--data">{fieldTypeToTypeString[type]}</span>
    ),
  },
  {
    title: 'Creation',
    dataKey: 'creation',
    RenderCell: ({ row: { createdAt, isDefault } }: { row: VariableType }): JSX.Element => (
      <span className="custom-fields__table__cell--data">
        {!isDefault ? `Created on ${getDateShort(new Date(createdAt))}` : 'Default Field'}
      </span>
    ),
  },
  {
    title: '',
    dataKey: 'actions',
    isSelectable: false,
    RenderCell: ({
      row,
      renderProps,
    }: {
      row: VariableType;
      renderProps: { onRemove: (id: number) => void; onEdit: (variable: VariableType) => void };
    }): JSX.Element => {
      if (row?.isDefault === true) {
        return null;
      }
      const actionsConfig = [];
      if (
        row.type === CustomVariableType.OPEN_AI ||
        row.type === CustomVariableType.SELECT ||
        row.type === CustomVariableType.MULTI_SELECT
      ) {
        actionsConfig.push({
          icon: 'editAction',
          title: 'Edit',
          handler: () => renderProps.onEdit(row),
        });
      }

      return (
        <ActionsMenu
          onRemove={() => renderProps.onRemove(row.id)}
          id={`custom-fields-${row.id}`}
          actionsConfig={actionsConfig}
          iconClassName="custom-fields__actions-icon"
          tooltipPlace="left"
          catchEvents={false}
        />
      );
    },
  },
];
