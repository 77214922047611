import React from 'react';

export default ({ color }: { color: string }): JSX.Element => (
  <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.5 1H16.5C19 1 21 3 21 5.5C21 8 19 10 16.5 10H5.5"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M5.5 10C7.98528 10 10 7.98528 10 5.5C10 3.01472 7.98528 1 5.5 1C3.01472 1 1 3.01472 1 5.5C1 7.98528 3.01472 10 5.5 10Z"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M16.5 23H5.5C3 23 1 21 1 18.5C1 16 3 14 5.5 14H16.5"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M16.5 23C18.9853 23 21 20.9853 21 18.5C21 16.0147 18.9853 14 16.5 14C14.0147 14 12 16.0147 12 18.5C12 20.9853 14.0147 23 16.5 23Z"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);
