import React from 'react';

export default ({ color = '#040404' }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 10.3333C8.88406 10.3333 9.7319 9.98214 10.357 9.35702C10.9821 8.7319 11.3333 7.88406 11.3333 7V3.66667C11.3333 3.48986 11.2631 3.32029 11.1381 3.19526C11.013 3.07024 10.8435 3 10.6667 3C10.4899 3 10.3203 3.07024 10.1953 3.19526C10.0702 3.32029 10 3.48986 10 3.66667V7C10 7.53043 9.78929 8.03914 9.41421 8.41421C9.03914 8.78929 8.53043 9 8 9C7.46957 9 6.96086 8.78929 6.58579 8.41421C6.21071 8.03914 6 7.53043 6 7V3.66667C6 3.48986 5.92976 3.32029 5.80474 3.19526C5.67971 3.07024 5.51014 3 5.33333 3C5.15652 3 4.98695 3.07024 4.86193 3.19526C4.7369 3.32029 4.66667 3.48986 4.66667 3.66667V7C4.66667 7.88406 5.01786 8.7319 5.64298 9.35702C6.2681 9.98214 7.11595 10.3333 8 10.3333ZM11.3333 11.6667H4.66667C4.48986 11.6667 4.32029 11.7369 4.19526 11.8619C4.07024 11.987 4 12.1565 4 12.3333C4 12.5101 4.07024 12.6797 4.19526 12.8047C4.32029 12.9298 4.48986 13 4.66667 13H11.3333C11.5101 13 11.6797 12.9298 11.8047 12.8047C11.9298 12.6797 12 12.5101 12 12.3333C12 12.1565 11.9298 11.987 11.8047 11.8619C11.6797 11.7369 11.5101 11.6667 11.3333 11.6667Z"
      fill={color}
    />
  </svg>
);
