// @ts-ignore
import MailboxSenderService from '../../../respona_api/generated/mailbox-sender_pb_service';

const service = (function () {
  let instance = null;

  return {
    getInstance: (): MailboxSenderService.MailboxSenderApiServiceClient => {
      if (!instance) {
        // @ts-ignore
        instance = new MailboxSenderService.MailboxSenderApiServiceClient(API_HOST);
      }

      return instance;
    },
  };
})();

export default service;
