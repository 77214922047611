import Avatar, { NamedAvatar } from '@uikit/Avatar/Avatar';
import React from 'react';
import { components } from 'react-select';
import useWorkspaceMembers from '@hooks/useWorkspaceMembers';

function CustomAvatar({ children, ...restProps }): JSX.Element {
  const { members: workspaceMembers } = useWorkspaceMembers();
  return (
    <components.Control {...restProps}>
      {restProps.selectProps?.value?.value === -1 ? (
        <Avatar
          defaultIcon="avatarIcon"
          backgroundCustom="#DFE5FA"
          size="xxs"
          url={restProps.selectProps.value.logoUrl}
        />
      ) : (
        (() => {
          let { label, value } = restProps.selectProps.value;

          if (label === 'Assign to me') {
            label = workspaceMembers.find(({ userId }) => userId === value).fullName;
          }
          return (
            <Avatar size="xxs" fullName={label} url={restProps.selectProps.value.logoUrl} backgroundCustom="#DFE5FA" />
          );
        })()
      )}
      {children}
    </components.Control>
  );
}

export default CustomAvatar;
