import * as google_protobuf_wrappers_pb from 'google-protobuf/google/protobuf/wrappers_pb';

import request from '@api/base/request';
import authSharedService from '@api/services/authSharedService';
import { CodeType, Pair, RegistrationCode } from 'respona_api/generated/common_pb';
import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb';
import { ReCaptchaShowStatusType } from '@ts/apiModels.types';
import { LoginResponseType, VerificationEmailResponseType } from '@ts/auth.types';
import AuthSharedModel, {
  AuthHandlerType,
  AuthHandlerTypeMap,
  VerificationCodeTypeMap,
} from 'respona_api/generated/auth-shared_pb';

export const getTimeZone = () => {
  const timeZone = -(new Date().getTimezoneOffset() / 60);

  const UTCSign = timeZone < 0 ? '-' : '+';
  const timezoneAbs = Math.abs(timeZone);
  const formattedTimeZone = timezoneAbs < 10 ? `0${timezoneAbs}` : `${timezoneAbs}`;

  return `UTC${UTCSign}${formattedTimeZone}`;
};

export const checkAccountByEmailApi = (email: string): Promise<VerificationEmailResponseType> => {
  const client = authSharedService.getInstance();
  const checkRequest = new google_protobuf_wrappers_pb.StringValue();

  checkRequest.setValue(email);

  return request<VerificationEmailResponseType>(
    client,
    client.checkAccountByEmail,
    checkRequest,
    false
  );
};

export const loginByEmailApi = (
  email: string,
  password: string,
  captcha?: string
): Promise<LoginResponseType> => {
  const client = authSharedService.getInstance();
  const loginRequest = new AuthSharedModel.LoginRequest();

  loginRequest.setToken(email);
  loginRequest.setOptionalPassword(password);
  loginRequest.setRealm(0);
  loginRequest.setType(0);
  loginRequest.setUtcTimeZone(getTimeZone());

  if (captcha) {
    loginRequest.setOptionalReCaptchaResponse(captcha);
  }

  return request<LoginResponseType>(client, client.login, loginRequest, false);
};

export const loginForUser = (
  token: string,
  type: AuthHandlerTypeMap[keyof AuthHandlerTypeMap] = AuthHandlerType.ADMIN_AREA
): Promise<LoginResponseType> => {
  const client = authSharedService.getInstance();
  const loginRequest = new AuthSharedModel.LoginRequest();

  loginRequest.setToken(token);
  loginRequest.setRealm(0);
  loginRequest.setType(type);
  loginRequest.setUtcTimeZone(getTimeZone());

  return request<LoginResponseType>(client, client.login, loginRequest, false);
};

export const registerApi = (
  email: string,
  password: string,
  fullName: string,
  captcha: string,
  code: string,
  ref?: string,
  utmTagPairs?: [string, string][],
  source?: string
): Promise<LoginResponseType> => {
  const client = authSharedService.getInstance();
  const registrationRequest = new AuthSharedModel.LoginRequest();

  registrationRequest.setToken(email);
  registrationRequest.setOptionalPassword(password);
  registrationRequest.setRealm(0);
  registrationRequest.setType(0);
  registrationRequest.setUtcTimeZone(getTimeZone());
  registrationRequest.setOptionalVerificationCode(code);
  registrationRequest.setOptionalFullName(fullName);

  const registrationCodes: Array<RegistrationCode> = [];
  if (ref) {
    const regCode = new RegistrationCode();
    regCode.setCode(ref);
    regCode.setType(CodeType.REFERRAL_CODE);
    if (utmTagPairs?.length) {
      regCode.setTagsList(
        utmTagPairs.map(([key, value]) => {
          const pair = new Pair();

          pair.setName(key);
          pair.setValue(value);

          return pair;
        })
      );
    }

    registrationCodes.push(regCode);
  }

  if (source) {
    const regCode = new RegistrationCode();
    regCode.setCode(source);
    regCode.setType(CodeType.A_B_TESTING_CODE);

    registrationCodes.push(regCode);
  }

  if (captcha) {
    registrationRequest.setOptionalReCaptchaResponse(captcha);
  }

  if (registrationCodes.length) {
    registrationRequest.setOptionalRegistrationCodesList(registrationCodes);
  }

  return request<LoginResponseType>(client, client.login, registrationRequest, false);
};

export const logoutApi = (): Promise<void> => {
  const client = authSharedService.getInstance();
  const logoutRequest = new AuthSharedModel.LogoutRequest();

  logoutRequest.setToken(localStorage.getItem('token'));

  return request<void>(client, client.logout, logoutRequest);
};

export const getInvitationInfoApi = (token) => {
  const client = authSharedService.getInstance();
  const tokenRequest = new google_protobuf_wrappers_pb.StringValue();

  tokenRequest.setValue(token);

  return request(client, client.getInvitationInfo, tokenRequest, false);
};

export const acceptInvitationApi = (
  fullName: string,
  password: string,
  token: string,
  captcha: string
) => {
  const client = authSharedService.getInstance();
  const acceptRequest = new AuthSharedModel.InvitationRequest();

  acceptRequest.setFullName(fullName);
  acceptRequest.setPassword(password);
  acceptRequest.setInviteToken(token);
  acceptRequest.setUtcTimeZone(getTimeZone());

  if (captcha) {
    acceptRequest.setOptionalReCaptchaResponse(captcha);
  }

  return request(client, client.acceptInvitation, acceptRequest, false);
};

export const loginByIntegrationApi = (
  code: string,
  ref?: string,
  utmTagPairs?: [string, string][],
  source?: string
): Promise<LoginResponseType> => {
  const client = authSharedService.getInstance();
  const loginRequest = new AuthSharedModel.LoginRequest();

  loginRequest.setToken(code);
  loginRequest.setRealm(0);
  loginRequest.setType(1);
  loginRequest.setUtcTimeZone(getTimeZone());

  const registrationCodes: Array<RegistrationCode> = [];
  if (ref) {
    const regCode = new RegistrationCode();
    regCode.setCode(ref);
    regCode.setType(CodeType.REFERRAL_CODE);
    if (utmTagPairs?.length) {
      regCode.setTagsList(
        utmTagPairs.map(([key, value]) => {
          const pair = new Pair();

          pair.setName(key);
          pair.setValue(value);

          return pair;
        })
      );
    }

    registrationCodes.push(regCode);
  }

  if (source) {
    const regCode = new RegistrationCode();
    regCode.setCode(source);
    regCode.setType(CodeType.A_B_TESTING_CODE);

    registrationCodes.push(regCode);
  }

  if (registrationCodes.length) {
    loginRequest.setOptionalRegistrationCodesList(registrationCodes);
  }

  return request<LoginResponseType>(client, client.login, loginRequest, false);
};

export const showReCaptchaApi = (): Promise<ReCaptchaShowStatusType> => {
  const client = authSharedService.getInstance();
  const emptyRequest = new google_protobuf_empty_pb.Empty();

  return request<ReCaptchaShowStatusType>(client, client.showReCaptcha, emptyRequest, false);
};

export const sendVerificationCodeApi = (
  email: string,
  type: VerificationCodeTypeMap[keyof VerificationCodeTypeMap]
): Promise<void> => {
  const client = authSharedService.getInstance();
  const verificationRequest = new AuthSharedModel.VerificationCodeRequest();

  verificationRequest.setEmail(email);
  verificationRequest.setType(type);

  return request<void>(client, client.sendVerificationCode, verificationRequest);
};

export const changePasswordByTokenApi = (
  code: string,
  email: string,
  password: string
): Promise<LoginResponseType> => {
  const client = authSharedService.getInstance();
  const changePasswordByTokenRequest = new AuthSharedModel.ChangeForgotPasswordRequest();

  changePasswordByTokenRequest.setCode(code);
  changePasswordByTokenRequest.setPassword(password);
  changePasswordByTokenRequest.setEmail(email);

  return request<LoginResponseType>(client, client.changePassword, changePasswordByTokenRequest);
};
