import {
  backlinksAutomationDefaultSettings,
  keywordsAutomationDefaultSettings,
  podcastsAutomationDefaultSettings,
} from '@redux/utils/automationContentDefaultValues';
import { handleActions } from 'redux-actions';

import {
  AhrefMetricsType,
  AutomationSearchSubType,
  BacklinksSearchType,
  KeywordsSearchType,
  ListennotesMetricsType,
  MozMetricsType,
  PodcastSearchType,
  SearchModeType,
  SemrushMetricsType,
} from '@ts/automationContent.types';

import {
  resetContentAutomationConfigState,
  resetContentAutomationState,
  setAhrefsBacklinksConfig,
  setAhrefsMetrics,
  setBingNewsConfig,
  setBingSearchConfig,
  setEpisodesConfig,
  setGoogleNewsConfig,
  setGoogleSearchConfig,
  setListennotesMetrics,
  setMetricsType,
  setMozBacklinksConfig,
  setMozMetrics,
  setPodcastsConfig,
  setResponaBacklinksConfig,
  setSearchMode,
  setAutomationSubType,
  setSemrushBacklinksConfig,
  setSemrushMetrics,
} from '@redux/actions/automationContent.actions';
import {
  KeywordSearchSource,
  PodcastSearchSource,
} from 'respona_api/generated/automation-content_pb';
import {
  BacklinksSearchSource,
  ContentMetricsType,
  ContentMetricsTypeMap,
} from 'respona_api/generated/common_pb';

export type SearchTypeConfigType = {
  googleSearch?: KeywordsSearchType;
  googleNews?: KeywordsSearchType;
  bingSearch?: KeywordsSearchType;
  bingNews?: KeywordsSearchType;
  responaBacklinks?: BacklinksSearchType;
  ahrefBacklinks?: BacklinksSearchType;
  semrushBacklinks?: BacklinksSearchType;
  mozBacklinks?: BacklinksSearchType;
  podcasts?: PodcastSearchType;
  episodes?: PodcastSearchType;
};

export type ContentAutomationMetricsType = {
  ahrefMetrics?: AhrefMetricsType;
  semrushMetrics?: SemrushMetricsType;
  listennotesMetrics?: ListennotesMetricsType & {
    customDateValue?: { from: number; to: number };
  };
  mozMetrics?: MozMetricsType;
};

export type AutomationContentStateType = {
  searchMode: SearchModeType;
  automationSubType: AutomationSearchSubType;
  config: {
    metricsType: ContentMetricsTypeMap[keyof ContentMetricsTypeMap];
  } & SearchTypeConfigType &
    ContentAutomationMetricsType;
};

const initialState: AutomationContentStateType = {
  searchMode: 0,
  automationSubType: 0,
  config: {
    metricsType: ContentMetricsType.AHREFS_METRICS,
    googleSearch: {
      source: KeywordSearchSource.GOOGLE_KEYWORD_SEARCH,
      ...keywordsAutomationDefaultSettings,
    },
    googleNews: {
      source: KeywordSearchSource.GOOGLE_NEWS_KEYWORD_SEARCH,
      ...keywordsAutomationDefaultSettings,
    },
    bingSearch: {
      source: KeywordSearchSource.BING_KEYWORD_SEARCH,
      ...keywordsAutomationDefaultSettings,
    },
    bingNews: {
      source: KeywordSearchSource.BING_NEWS_KEYWORD_SEARCH,
      ...keywordsAutomationDefaultSettings,
    },
    ahrefMetrics: {
      includePaidMetrics: false,
      domainRating: {
        min: 25,
        max: 80,
      },
      domainMonthlyTraffic: {
        min: 250,
        max: -1,
      },
      domainOrganicKeywords: {
        min: 25,
        max: -1,
      },
    },
    semrushMetrics: {
      includePaidMetrics: false,
      domainAuthorityScore: {
        min: 25,
        max: 80,
      },
      domainMonthlyTraffic: {
        min: 250,
        max: -1,
      },
      domainOrganicKeywords: {
        min: 25,
        max: -1,
      },
    },
    mozMetrics: {
      includePaidMetrics: false,
      domainAuthority: {
        min: -1,
        max: -1,
      },
      domainSpamScore: {
        min: -1,
        max: -1,
      },
      pageAuthority: {
        min: -1,
        max: -1,
      },
    },
    responaBacklinks: {
      source: BacklinksSearchSource.RESPONA_BACKLINKS_SEARCH,
      ...backlinksAutomationDefaultSettings,
    },
    ahrefBacklinks: {
      source: BacklinksSearchSource.AHREFS_BACKLINKS_SEARCH,
      ...backlinksAutomationDefaultSettings,
    },
    semrushBacklinks: {
      source: BacklinksSearchSource.SEMRUSH_BACKLINKS_SEARCH,
      ...backlinksAutomationDefaultSettings,
    },
    mozBacklinks: {
      source: BacklinksSearchSource.MOZ_BACKLINKS_SEARCH,
      ...backlinksAutomationDefaultSettings,
    },
    podcasts: {
      source: PodcastSearchSource.SHOWS_PODCAST_SEARCH,
      ...podcastsAutomationDefaultSettings,
    },
    episodes: {
      source: PodcastSearchSource.EPISODES_PODCAST_SEARCH,
      ...podcastsAutomationDefaultSettings,
    },
    listennotesMetrics: {
      totalEpisodes: {
        min: -1,
        max: -1,
      },
      dateoflastepisode: 0,
      customDateValue: undefined,
      searchIn: 0,
      sortBy: 0,
      listenScore: {
        min: 0,
        max: 100,
      },
    },
  },
};

export type SearchTypeConfigUnion = KeywordsSearchType | BacklinksSearchType | PodcastSearchType;

export default handleActions(
  {
    [setSearchMode]: (
      state: AutomationContentStateType,
      { payload }
    ): AutomationContentStateType => ({
      ...state,
      searchMode: payload,
    }),
    [setAutomationSubType]: (
      state: AutomationContentStateType,
      { payload }
    ): AutomationContentStateType => ({
      ...state,
      automationSubType: payload,
    }),
    [setMetricsType]: (
      state: AutomationContentStateType,
      { payload }
    ): AutomationContentStateType => ({
      ...state,
      config: {
        ...state.config,
        metricsType: payload,
      },
    }),
    // KEYWORDS
    [setGoogleSearchConfig]: (
      state: AutomationContentStateType,
      { payload }
    ): AutomationContentStateType => {
      return {
        ...state,
        config: {
          ...state.config,
          googleSearch: {
            ...state.config.googleSearch,
            ...payload,
          },
        },
      };
    },
    [setGoogleNewsConfig]: (
      state: AutomationContentStateType,
      { payload }
    ): AutomationContentStateType => {
      return {
        ...state,
        config: {
          ...state.config,
          googleNews: {
            ...state.config.googleNews,
            ...payload,
          },
        },
      };
    },
    [setBingSearchConfig]: (
      state: AutomationContentStateType,
      { payload }
    ): AutomationContentStateType => {
      return {
        ...state,
        config: {
          ...state.config,
          bingSearch: {
            ...state.config.bingSearch,
            ...payload,
          },
        },
      };
    },
    [setBingNewsConfig]: (
      state: AutomationContentStateType,
      { payload }
    ): AutomationContentStateType => {
      return {
        ...state,
        config: {
          ...state.config,
          bingNews: {
            ...state.config.bingNews,
            ...payload,
          },
        },
      };
    },

    // BACKLINKS
    [setAhrefsBacklinksConfig]: (
      state: AutomationContentStateType,
      { payload }
    ): AutomationContentStateType => {
      return {
        ...state,
        config: {
          ...state.config,
          ahrefBacklinks: {
            ...state.config.ahrefBacklinks,
            ...payload,
          },
        },
      };
    },
    [setSemrushBacklinksConfig]: (
      state: AutomationContentStateType,
      { payload }
    ): AutomationContentStateType => {
      return {
        ...state,
        config: {
          ...state.config,
          semrushBacklinks: {
            ...state.config.semrushBacklinks,
            ...payload,
          },
        },
      };
    },
    [setMozBacklinksConfig]: (
      state: AutomationContentStateType,
      { payload }
    ): AutomationContentStateType => {
      return {
        ...state,
        config: {
          ...state.config,
          mozBacklinks: {
            ...state.config.mozBacklinks,
            ...payload,
          },
        },
      };
    },
    [setResponaBacklinksConfig]: (
      state: AutomationContentStateType,
      { payload }
    ): AutomationContentStateType => {
      return {
        ...state,
        config: {
          ...state.config,
          responaBacklinks: {
            ...state.config.responaBacklinks,
            ...payload,
          },
        },
      };
    },

    // PODCAST
    [setPodcastsConfig]: (
      state: AutomationContentStateType,
      { payload }
    ): AutomationContentStateType => {
      return {
        ...state,
        config: {
          ...state.config,
          podcasts: {
            ...state.config.podcasts,
            ...payload,
          },
        },
      };
    },
    [setEpisodesConfig]: (
      state: AutomationContentStateType,
      { payload }
    ): AutomationContentStateType => {
      return {
        ...state,
        config: {
          ...state.config,
          episodes: {
            ...state.config.episodes,
            ...payload,
          },
        },
      };
    },

    // METRICS
    [setAhrefsMetrics]: (
      state: AutomationContentStateType,
      { payload }
    ): AutomationContentStateType => {
      return {
        ...state,
        config: {
          ...state.config,
          ahrefMetrics: {
            ...state.config.ahrefMetrics,
            ...payload,
          },
        },
      };
    },
    [setSemrushMetrics]: (
      state: AutomationContentStateType,
      { payload }
    ): AutomationContentStateType => {
      const metrics = { ...payload };
      Object.keys(metrics).forEach((key) => metrics[key] === undefined && delete metrics[key]);
      return {
        ...state,
        config: {
          ...state.config,
          semrushMetrics: {
            ...state.config.semrushMetrics,
            ...metrics,
          },
        },
      };
    },
    [setMozMetrics]: (
      state: AutomationContentStateType,
      { payload }
    ): AutomationContentStateType => {
      const metrics = { ...payload };
      Object.keys(metrics).forEach((key) => metrics[key] === undefined && delete metrics[key]);
      return {
        ...state,
        config: {
          ...state.config,
          mozMetrics: {
            ...state.config.mozMetrics,
            ...metrics,
          },
        },
      };
    },
    [setListennotesMetrics]: (
      state: AutomationContentStateType,
      { payload }
    ): AutomationContentStateType => {
      return {
        ...state,
        config: {
          ...state.config,
          listennotesMetrics: {
            ...state.config.listennotesMetrics,
            ...payload,
          },
        },
      };
    },
    //  RESET STATE
    [resetContentAutomationConfigState]: () => {
      return {
        ...initialState,
      };
    },
    [resetContentAutomationState]: (state: AutomationContentStateType) => {
      return {
        ...state,
        automationStream: null,
      };
    },
  },
  initialState
);
