import React, { useRef } from 'react';

import { components } from 'react-select';

import { SVGIcon } from '@uikit/Icon/Icon';
import Select from '@uikit/Select/Select';

import { reactSelectStylesProvider } from '@helpers/reactSelectHelpers';
import { GREY_COLOR, GREY_LIGHT_COLOR } from '@constants/colors';

import personFields from '@constants/personFields';
import MapAttributesStepFieldReset from '@components/CampaignSettings/ContentSearch/ImportFileFlow/_components/MapAttributesStep/_components/MapAttributesStepFieldReset/MapAttributesStepFieldReset';
import { customVarsToOptions } from '@helpers/customVariablesHelpers';

import './MapAttributeRow.scss';
import { ContactVariable } from 'respona_api/generated/common_pb';
import VariablesList from '@uikit/RichEditor/_components/VariablesList/VariablesList';

function MapAttributeRow({
  row,
  onChange,
  index,
  customVars,
  onAddCustomVar,
  selectedVariables,
}): JSX.Element {
  const refRow = useRef<HTMLDivElement>();

  const handleChange = (option) => {
    onChange(index, option);
  };

  function FieldSelectMenu(props) {
    return (
      <components.Menu {...props}>
        <div className="field-select__menu">
          {/* eslint-disable-next-line react/destructuring-assignment */}
          <div>{props.children}</div>
          {/*<VariablesList onSelectVar={props.selectProps.onChange} variables={props.options} />*/}
          <div className="field-select__footer" onClick={onAddCustomVar} tabIndex={0} role="button">
            <span>Add custom field</span>
          </div>
        </div>
      </components.Menu>
    );
  }

  const responaFieldAdditionalStyle = {
    singleValue: reactSelectStylesProvider({
      color: GREY_COLOR,
      fontFamily: 'Mulish',
    }),
    control: reactSelectStylesProvider({
      borderColor: GREY_LIGHT_COLOR,
      borderRadius: '8px',
      height: '48px',
    }),
    menuList: reactSelectStylesProvider({
      maxHeight: '250px',
    }),
  };

  const resetField = () => handleChange({ value: 0 });

  const options = [
    ...personFields.map((variable) => ({
      ...variable,
      title: variable.label,
    })),
    ...customVars.map((variable) => ({
      ...variable,
      isCustomVariable: true,
      label: variable.title,
      value: `custom-${variable.id}`,
    })),
  ];

  const filteredOptions = options.filter(({ value }) => !selectedVariables.includes(value));

  function defineValue() {
    if (
      row.mappedVariable === ContactVariable.CUSTOM_VARIABLE ||
      row.optionalCustomVariableId > 0
    ) {
      // @ts-ignore
      return options.find((it) => it?.id === row.optionalCustomVariableId) || null;
    }
    return row.mappedVariable !== 0
      ? options.find(({ value }) => value === row.mappedVariable)
      : null;
  }

  return (
    <div className="map-attribute-step__row">
      <div className="map-attribute-step__column">
        <div className="map-attribute-step__label">CSV file fields</div>
        <div className="map-attribute-step__field map-attribute-step__field--pseudo">
          <span className="map-attribute-step__field-title">{row.title}</span>
          <div className="map-attribute-step__field-arrow-svg">
            <SVGIcon icon="arrowRightGrey" color={row.mappedVariable ? '#251FB6' : '#C4C6CD'} />
          </div>
        </div>
      </div>
      <div className="map-attribute-step__column">
        <div className="map-attribute-step__label">Respona fields</div>
        <div
          ref={refRow}
          className="map-attribute-step__field"
          role="button"
          tabIndex={0}
        >
          <Select
            onChange={handleChange}
            options={filteredOptions}
            additionalComponents={{ Menu: FieldSelectMenu }}
            additionalStyles={responaFieldAdditionalStyle}
            value={defineValue()}
          />
          {(typeof row.mappedVariable === 'number') && (
            <MapAttributesStepFieldReset onClick={resetField} hide={row.mappedVariable <= 0} />
          )}
        </div>
      </div>
    </div>
  );
}

export default MapAttributeRow;
