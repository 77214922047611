import { applyMiddleware, createStore } from 'redux';
import reducer from '@redux/reducers';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from '@middlewares/thunk';

const store = (function () {
  let instance = null;

  return {
    getInstance: () => {
      if (!instance) {
        // @ts-ignore
        instance = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));
      }

      return instance;
    },
  };
})();

const defaultStore = store.getInstance();

export type DispatchType = typeof defaultStore.dispatch;

export default defaultStore;
