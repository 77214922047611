import HaroService from '../../../respona_api/generated/haro_pb_service';

const service = (function () {
  let instance = null;

  return {
    getInstance: (): HaroService.HaroApiServiceClient => {
      if (!instance) {
        // @ts-ignore
        instance = new HaroService.HaroApiServiceClient(API_HOST);
      }

      return instance;
    },
  };
})();

export default service;
