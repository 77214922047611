import React, { useMemo, useCallback } from 'react';
import HeaderSearchInput from '@components/HeaderSearchInput/HeaderSearchInput';
import Button from '@uikit/Button/Button';
import Display from '@components/Display/Display';
import withSmallScreensCheck from '@hocs/withSmallScreensCheck';

import './UnsubscribedPageActions.scss';
import HeaderFiltersButtonWithTooltip from '@components/PageHeader/HeaderFiltersButtonWithTooltip/HeaderFiltersButtonWithTooltip';
import RelationshipsFiltersTooltipContent from '@components/Relationships/RelationshipsFiltersTooltipContent/RelationshipsFiltersTooltipContent';
import { useDispatch, useSelector } from 'react-redux';
import { unsubscribesFiltersSelector } from '@redux/selectors/unsubscribes.selectors';
import { getUnsubscribesFilterFields } from '@constants/unsubscribesFilterFields';
import { throwFiltersForUnsubscribes } from '@redux/actions/unsubscribes.actions';
import { UnsubscribeFilterObjectType } from '@ts/unsubscribes.types';
import useWorkspaces from '@hooks/useWorkspaces';
import { initialUnsubscribePageFilterRawState } from '@redux/reducers/unsubscribes.reducer';

type UnsubscribedPageActionsProps = {
  searchString?: string;
  changeSearchString: (any) => void;
  handleOpenExportSidebar: () => void;
  selectedIds: {
    [key: string]: number;
  };
  handleOpenRemoveSidebar: () => void;
  handleOpenUnsubscribeSidebar: () => void;
  onApplyFilters?: (value: UnsubscribeFilterObjectType) => void;
};

function UnsubscribedPageActions({
  searchString,
  changeSearchString,
  handleOpenExportSidebar,
  selectedIds,
  handleOpenRemoveSidebar,
  handleOpenUnsubscribeSidebar,
  isSmallScreen,
  onApplyFilters,
}: UnsubscribedPageActionsProps & { isSmallScreen: boolean }): JSX.Element {
  const dispatch = useDispatch();
  const valueFiltersFromRedux = useSelector(unsubscribesFiltersSelector);
  const { items: workspacesData } = useWorkspaces(true);
  const checkFilterValues = useCallback(
    (filtersArray) => filtersArray.some(({ value }) => value.toString()?.length > 0),
    []
  );

  const areFiltersEnabled = useMemo(() => {
    return checkFilterValues(Object.values(valueFiltersFromRedux));
  }, [valueFiltersFromRedux, checkFilterValues]);

  const workspaceOptions = useMemo(() => {
    return workspacesData?.map(({ id, title }) => ({
      value: id,
      label: title,
    }));
  }, [workspacesData]);

  const handleClearFilters = useCallback(() => {
    dispatch(throwFiltersForUnsubscribes());
  }, [dispatch]);

  const valueFilterFields = useMemo(() => {
    return getUnsubscribesFilterFields(workspaceOptions);
  }, [workspaceOptions]);

  return (
    <div className="unsubscribe-page-actions__header-actions">
      <HeaderSearchInput searchQuery={searchString} onUpdateSearchQuery={changeSearchString} />
      <Display isVisible={Object.keys(selectedIds).length > 0}>
        <Button
          type="additional"
          className="unsubscribe-page-actions__export-button"
          onClick={handleOpenExportSidebar}
          leftIcon="download"
          colorIcon="#221CB6"
        >
          <Display isVisible={!isSmallScreen}>Export</Display>
        </Button>
        <Button
          type="alert"
          className="unsubscribe-page-actions__remove-button"
          onClick={handleOpenRemoveSidebar}
          leftIcon="trash"
        >
          <Display isVisible={!isSmallScreen}>Remove</Display>
        </Button>
        <div className="seperator" />
      </Display>

      <div className="unsubscribe-page-actions__header-actions-block">
        <HeaderFiltersButtonWithTooltip
          areFiltersEnabled={areFiltersEnabled}
          onClearAllFilters={handleClearFilters}
        >
          <RelationshipsFiltersTooltipContent
            onApplyFilters={onApplyFilters}
            filterFields={valueFilterFields}
            filtersFromRedux={valueFiltersFromRedux}
            initialFilterState={initialUnsubscribePageFilterRawState}
          />
        </HeaderFiltersButtonWithTooltip>
        <Button onClick={handleOpenUnsubscribeSidebar} leftIcon="plusIcon">
          Unsubscribe
        </Button>
      </div>
    </div>
  );
}

export default withSmallScreensCheck<UnsubscribedPageActionsProps>(UnsubscribedPageActions);
