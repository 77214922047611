import React, { useCallback } from 'react';
import { ContentMetricsType } from 'respona_api/generated/common_pb';
import { fromNumberAhrefFormatHelper } from '@helpers/transformDataHelpers';
import ContactsSidebarCRMSectionEditableField from '@components/ContactSidebarCRM/_components/ContactsSidebarCRMSection/_components/ContactsSidebarCRMSectionEditableField/ContactsSidebarCRMSectionEditableField';
import { PeopleWebsiteMetricsResponse } from 'respona_api/generated/people-website_pb';

const IconsMapByType = {
  [ContentMetricsType.AHREFS_METRICS]: require('../_assets/ahrefs-crm-integration.png').default,
  [ContentMetricsType.SEMRUSH_METRICS]: require('../_assets/semrush-crm-integration.png').default,
  [ContentMetricsType.MOZ_METRICS]: require('../_assets/moz-crm-integration.png').default,
};

function Metrics({
  item,
  onDelete,
  onRefresh,
  isRefreshing,
  onSave,
}: {
  item: PeopleWebsiteMetricsResponse.AsObject;
  onDelete: () => void;
  onRefresh: () => void;
  onSave: (data: PeopleWebsiteMetricsResponse.AsObject) => void;
  isRefreshing?: boolean;
}) {
  const icon = IconsMapByType[item.type] || null;
  const isMoz = item.type === ContentMetricsType.MOZ_METRICS;
  const isSemrush = item.type === ContentMetricsType.SEMRUSH_METRICS;

  const onSaveCallback = useCallback(
    (value: string, key: string) => {
      onSave({
        ...item,
        [key]: Number(value),
      });
    },
    [item, onSave]
  );

  const renderField = (label: string, key: string, initialValue: string) => (
    <div className="contacts-sidebar-section-metrics__metric-row">
      <div className="contacts-sidebar-section-metrics__metric-row-icon">
        <img src={icon} alt="metrics-logo" />
      </div>
      <div className="contacts-sidebar-section-metrics__metric-row-data">
        <div className="contacts-sidebar-section-metrics__metric-row-data-label">{label}</div>
        <ContactsSidebarCRMSectionEditableField
          initialValue={initialValue}
          onSave={(_, value) => onSaveCallback(value, key)}
          onRefresh={onRefresh}
          isRefreshing={isRefreshing}
          onDelete={onDelete}
          enableCopy={false}
          renderContent={(value) =>
            Number(value) < 0 ? '-' : fromNumberAhrefFormatHelper(Number(value))
          }
        />
      </div>
    </div>
  );

  return (
    <div className="contacts-sidebar-section-metrics__metric-col">
      {renderField(
        isSemrush ? 'Authority score' : 'Domain authority',
        'rating',
        item?.rating?.toString() || ''
      )}
      {!isMoz
        ? renderField('Domain Traffic', 'traffic', item?.traffic?.toString() || '')
        : renderField('Spam score', 'spamScore', item?.spamScore?.toString() || '')}
    </div>
  );
}

export default Metrics;
