import React from 'react';

import { RenderConfigurableSidebarLink } from '@uikit/ConfigurableSidebar/_components/ConfigurableSidebarLinks';
import FolderSidebarLink from '@components/FolderSidebarLink/FolderSidebarLink';

declare const IS_DEV: boolean;

const groups = [
  {
    title: 'Websites',
    pageKey: 'websites',
    RenderLink: ({ pathname }) => (
      <RenderConfigurableSidebarLink
        title="Websites"
        icon="office"
        url="/relationships/websites"
        isActive={pathname === '/relationships/websites'}
      />
    ),
  },
  {
    title: 'Contacts',
    pageKey: 'contacts',
    RenderLink: ({ pathname }) => (
      <RenderConfigurableSidebarLink
        title="Contacts"
        icon="person"
        url="/relationships/people"
        isActive={pathname === '/relationships/people'}
      />
    ),
  },
  // {
  //   isGroup: true,
  //   title: 'People',
  //   pageKey: 'peopleGroup',
  //   RenderGroupLinks: ({
  //     renderProps: {
  //       peopleFolders,
  //       activePage,
  //       searchParams: { folderId },
  //     },
  //   }) => {
  //     const allItemsUrl = '/relationships/people';
  //     const dispatch = useDispatch();
  //
  //     const pathNameWithSearch = `${window.location.pathname}${window.location.search}`;
  //
  //     const handleUpdateFolder = (curFolderId: number, title: string) =>
  //       dispatch(renameFolder(curFolderId, title));
  //     const handleRemoveFolder = (curFolderId: number) => dispatch(removeFolder(curFolderId));
  //
  //     return (
  //       <>
  //         <RenderDefaultLink
  //           title="All people"
  //           url={allItemsUrl}
  //           isActive={activePage === 'people' && !folderId}
  //           icon="allMembers"
  //         />
  //         {peopleFolders.map(({ title, id }) => (
  //           <FolderSidebarLink
  //             key={`people-folder-${id}`}
  //             id={id}
  //             basePath="/relationships/people"
  //             title={title}
  //             onRemoveFolder={handleRemoveFolder}
  //             onUpdateFolder={handleUpdateFolder}
  //             pathNameWithSearch={pathNameWithSearch}
  //           />
  //         ))}
  //       </>
  //     );
  //   },
  //   groupActionClick: ({ onCreatePeopleFolder }) => onCreatePeopleFolder(),
  // },
  // {
  //   isGroup: true,
  //   title: 'Organizations',
  //   pageKey: 'organizationsGroup',
  //   RenderGroupLinks: ({
  //     renderProps: {
  //       activePage,
  //       searchParams: { folderId },
  //       companiesFolders,
  //     },
  //   }) => {
  //     const dispatch = useDispatch();
  //     const pathNameWithSearch = `${window.location.pathname}${window.location.search}`;
  //
  //     const handleRemoveFolder = (curFolderId: number) =>
  //       dispatch(removeCompanyFolderRequest(curFolderId));
  //     const handleRenameFolder = (curFolderId: number, title: string) =>
  //       dispatch(renameCompanyFolderRequest(curFolderId, title));
  //
  //     return (
  //       <>
  //         <RenderDefaultLink
  //           title="All organizations"
  //           url="/relationships/websites"
  //           isActive={activePage === 'organizations' && !folderId}
  //           icon="allMembers"
  //         />
  //         {companiesFolders.map(({ title, id }) => (
  //           <FolderSidebarLink
  //             key={`people-folder-${id}`}
  //             id={id}
  //             basePath="/relationships/websites"
  //             title={title}
  //             onRemoveFolder={handleRemoveFolder}
  //             onUpdateFolder={handleRenameFolder}
  //             pathNameWithSearch={pathNameWithSearch}
  //           />
  //         ))}
  //       </>
  //     );
  //   },
  //   groupActionClick: ({ onCreateOrganizationFolder }) => onCreateOrganizationFolder(),
  // },
];

// TODO remove after releasing pipelines
const ENABLE_PIPELINES = localStorage.getItem('ENABLE_PIPELINES') || false;

if (IS_DEV || ENABLE_PIPELINES) {
  groups.push({
    title: 'Pipelines',
    pageKey: 'pipelines',
    RenderLink: ({ pathname }) => (
      <RenderConfigurableSidebarLink
        title="Pipelines"
        icon="office"
        url="/relationships/pipelines"
        isActive={pathname === '/relationships/pipelines'}
      />
    ),
  });
}

// TODO: We can make RenderGroupLinks like a component with props for people or for organizations
export default groups;
