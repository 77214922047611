import React, { useEffect, useRef, useState } from 'react';

import Input from '@uikit/Input/Input';

import ActionsMenu from '@components/ActionsMenu/ActionsMenu';
import {
  DefaultLink,
  RenderConfigurableCampaignSidebarLink
} from '@components/Campaigns/CampaignsSidebar/_components/CampaignsSidebarHeader/components/ConfigurableCampaignSidebarLinks';
import { Button } from '@uikit/Button/Button';
import { SVGIcon } from '@uikit/Icon/Icon';
import useOutsideClick from '@hooks/useOutsideClick';

export const NewFolderSidebarLink = ({
  onCreateFolder,
  onCancel,
}: {
  onCreateFolder: any;
  onCancel: () => void;
}): JSX.Element => {
  const [inputValue, changeInputValue] = useState('New Folder');
  const [wasFocused, changeWasFocused] = useState(false);
  const inputRef = useRef();
  const buttonRef = useRef();

  useEffect(() => {
    if (inputRef.current && !wasFocused) {
      // @ts-ignore
      inputRef.current.focus();
      // @ts-ignore
      inputRef.current.select();
      changeWasFocused(true);
    }
  }, [inputRef, inputRef.current]);

  const cancelInputChanging = () => {
    onCancel();
  };

  const handleInputKeyDown = (e) => {
    if (e.keyCode === 27) {
      cancelInputChanging();
      return;
    }

    if (e.keyCode === 13) {
      onCreateFolder(inputValue);
    }
  };

  useOutsideClick([buttonRef], (event) => {
    cancelInputChanging();
    changeInputValue('New Folder');
  });

  return (
    <div className="configurable-campaign-sidebar-folders__create-btn" ref={buttonRef}>
      <Input
        className="folder-sidebar-link"
        isFullWidth
        onKeyDown={handleInputKeyDown}
        value={inputValue}
        onChange={({target: {value}}) => changeInputValue(value)}
        inputRef={inputRef}
        maxLength={25}
      />
      <Button
        className="configurable-campaign-sidebar__link-button"
        onClick={() => onCreateFolder(inputValue)}
      >
        <SVGIcon icon="check" size={14}/>
      </Button>
    </div>
  );
};

const FolderSidebarLink = ({
  id,
  title,
  pathNameWithSearch,
  onRemoveFolder,
  onUpdateFolder,
  onShareFolder,
  withActions = true,
  basePath = '',
}: {
  id: number;
  title: string;
  pathNameWithSearch: string;
  onRemoveFolder?: (...any) => void;
  onUpdateFolder?: (...any) => void;
  onShareFolder?: (...any) => void;
  withActions?: boolean;
  basePath?: string;
}): JSX.Element => {
  const [isRenaming, changeIsRenaming] = useState(false);
  const [wasFocused, changeWasFocused] = useState(false);
  const [inputValue, changeInputValue] = useState(title);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current && !wasFocused) {
      // @ts-ignore
      inputRef.current.focus();
      // @ts-ignore
      inputRef.current.select();
      changeWasFocused(true);
    }
  }, [isRenaming, inputRef, inputRef.current]);

  const cancelInputChanging = () => {
    changeIsRenaming(false);
    changeInputValue(title);
  };

  const handleInputKeyDown = (e) => {
    if (e.keyCode === 27 || (e.keyCode === 13 && inputValue === title)) {
      cancelInputChanging();
      return;
    }

    if (e.keyCode === 13 && inputValue !== title) {
      onUpdateFolder(id, inputValue);
      changeIsRenaming(false);
    }
  };

  if (isRenaming) {
    return (
      <DefaultLink isActive={false}>
        <Input
          className="folder-sidebar-link"
          isFullWidth
          onKeyDown={handleInputKeyDown}
          value={inputValue}
          onChange={({ target: { value } }) => changeInputValue(value)}
          onBlur={cancelInputChanging}
          inputRef={inputRef}
          maxLength={25}
        />
      </DefaultLink>
    );
  }

  const baseActionsConfig = [
    {
      icon: 'renameAction',
      title: 'Rename',
      handler: () => changeIsRenaming(true),
    },
  ];

  const getActionsConfig = () => {
    if (onShareFolder !== undefined) {
      return [
        ...baseActionsConfig,
        {
          icon: 'shareAction',
          title: 'Share',
          handler: () => onShareFolder({ id, title }),
        },
      ];
    }

    return baseActionsConfig;
  };

  return (
    <RenderConfigurableCampaignSidebarLink
      key={id}
      title={title}
      url={`${basePath}?folderId=${id}`}
      isActive={pathNameWithSearch === `${basePath}?folderId=${id}`}
      isCropped
      icon="folder"
      RenderActions={() =>
        withActions ? (
          <ActionsMenu
            id={`folder-${id}-actions`}
            itemTitle={title}
            onRemove={onRemoveFolder ? () => onRemoveFolder(id) : null}
            actionsConfig={getActionsConfig()}
            tooltipPlace="left"
            portalSelector="body"
            tooltipClassName="react-tooltip--position-fixed"
          />
        ) : null
      }
    />
  );
};

export default FolderSidebarLink;
