import React from 'react';

export default ({ color = '#fff' }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.2583 7.74167L12.2583 2.74167C12.1815 2.66671 12.091 2.60729 11.9917 2.56667C11.8883 2.52468 11.7782 2.50208 11.6667 2.5H5C4.33696 2.5 3.70107 2.76339 3.23223 3.23223C2.76339 3.70107 2.5 4.33696 2.5 5V15C2.5 15.663 2.76339 16.2989 3.23223 16.7678C3.70107 17.2366 4.33696 17.5 5 17.5H15C15.663 17.5 16.2989 17.2366 16.7678 16.7678C17.2366 16.2989 17.5 15.663 17.5 15V8.33333C17.5006 8.22366 17.4796 8.11494 17.4381 8.01341C17.3967 7.91188 17.3356 7.81953 17.2583 7.74167ZM7.5 4.16667H10.8333V5.83333H7.5V4.16667ZM12.5 15.8333H7.5V13.3333C7.5 13.1123 7.5878 12.9004 7.74408 12.7441C7.90036 12.5878 8.11232 12.5 8.33333 12.5H11.6667C11.8877 12.5 12.0996 12.5878 12.2559 12.7441C12.4122 12.9004 12.5 13.1123 12.5 13.3333V15.8333ZM15.8333 15C15.8333 15.221 15.7455 15.433 15.5893 15.5893C15.433 15.7455 15.221 15.8333 15 15.8333H14.1667V13.3333C14.1667 12.6703 13.9033 12.0344 13.4344 11.5656C12.9656 11.0967 12.3297 10.8333 11.6667 10.8333H8.33333C7.67029 10.8333 7.03441 11.0967 6.56557 11.5656C6.09673 12.0344 5.83333 12.6703 5.83333 13.3333V15.8333H5C4.77899 15.8333 4.56702 15.7455 4.41074 15.5893C4.25446 15.433 4.16667 15.221 4.16667 15V5C4.16667 4.77899 4.25446 4.56702 4.41074 4.41074C4.56702 4.25446 4.77899 4.16667 5 4.16667H5.83333V6.66667C5.83333 6.88768 5.92113 7.09964 6.07741 7.25592C6.23369 7.4122 6.44565 7.5 6.66667 7.5H11.6667C11.8877 7.5 12.0996 7.4122 12.2559 7.25592C12.4122 7.09964 12.5 6.88768 12.5 6.66667V5.34167L15.8333 8.675V15Z"
      fill={color}
    />
  </svg>
);
