import { createAction } from 'redux-actions';

import { IntegrationType } from '@ts/userOrganization.types';

export const setIntegrations = createAction<IntegrationType[]>('SET_INTEGRATIONS');

export const fetchIntegrations = createAction('FETCH_INTEGRATIONS');
export const connectIntegration = createAction<IntegrationType>('CONNECT_INTEGRATION');
export const updateIntegration = createAction<IntegrationType>('UPDATE_INTEGRATION');
export const deleteIntegration = createAction<number>('DELETE_INTEGRATION');
