import React from 'react';

export default ({ color = '#040404' }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.33333 3.99935C5.33333 3.63116 5.63181 3.33268 6 3.33268H13.3333C13.7015 3.33268 14 3.63116 14 3.99935C14 4.36754 13.7015 4.66602 13.3333 4.66602H6C5.63181 4.66602 5.33333 4.36754 5.33333 3.99935ZM3.33333 2.66602V4.66602H4V5.33268H2V4.66602H2.66667V3.33268H2V2.66602H3.33333ZM2 9.33268V7.66602H3.33333V7.33268H2V6.66602H4V8.33268H2.66667V8.66602H4V9.33268H2ZM3.33333 12.3327H2V11.666H3.33333V11.3327H2V10.666H4V13.3327H2V12.666H3.33333V12.3327ZM5.33333 7.99935C5.33333 7.63116 5.63181 7.33268 6 7.33268H13.3333C13.7015 7.33268 14 7.63116 14 7.99935C14 8.36754 13.7015 8.66602 13.3333 8.66602H6C5.63181 8.66602 5.33333 8.36754 5.33333 7.99935ZM5.33333 11.9993C5.33333 11.6312 5.63181 11.3327 6 11.3327H13.3333C13.7015 11.3327 14 11.6312 14 11.9993C14 12.3675 13.7015 12.666 13.3333 12.666H6C5.63181 12.666 5.33333 12.3675 5.33333 11.9993Z"
      fill={color}
    />
  </svg>
);
