import React from 'react';

import './TemplateSelectStepsInfo.scss';

type TemplateSelectStepsInfoPropsType = {
  isShown: boolean;
  stepsList: any[];
};

export default ({ isShown, stepsList }: TemplateSelectStepsInfoPropsType): JSX.Element => {
  if (!isShown) {
    return null;
  }

  return (
    <div className="template-select-steps-info">
      asdasd
    </div>
  )
};