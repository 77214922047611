import React, { useEffect, useMemo } from 'react';

import {
  BacklinksSearchType,
  KeywordsSearchType,
  PodcastSearchType,
} from '@ts/automationContent.types';

import ContentAutoSearchSteps from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/ContentAutoSearchSteps/ContentAutoSearchSteps';
import { getContentAutoSearchSteps } from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/ContentAutoSearchSteps/getContentAutoSearchSteps';
import { fetchAllIntegrations } from '@redux/thunks/integrations.thunks';
import { getCampaignContentAutomationPresetApi } from '@api/contentAutomation.api';
import {
  setAhrefsBacklinksConfig,
  setAhrefsMetrics,
  setAutomationSubType,
  setBingNewsConfig,
  setBingSearchConfig,
  setEpisodesConfig,
  setGoogleNewsConfig,
  setGoogleSearchConfig,
  setListennotesMetrics,
  setMozBacklinksConfig,
  setMozMetrics,
  setPodcastsConfig,
  setResponaBacklinksConfig,
  setSearchMode,
  setSemrushBacklinksConfig,
  setSemrushMetrics,
} from '@redux/actions/automationContent.actions';
import { useDispatch, useSelector } from 'react-redux';
import { DispatchType } from 'src/store';
import { getActiveCampaignInfo } from '@redux/selectors/campaignSettings.selectors';
import { getContactAutomationPresets } from '@redux/thunks/automationContact.thunks';

type CASearchTypeWrapperProps = {
  pipelineId: number;
  activeStepIndex: number;
  changeActiveStepIndex: (index: number) => void;
  canStartAutomation?: boolean;
  searchMode?: number;
  setIsLoading: (value: boolean) => void;
};

export type CASearchConfigType = {
  keywordsConfig: KeywordsSearchType;
  backlinksConfig: BacklinksSearchType;
  podcastsConfig: PodcastSearchType;
};

type StepsContextType = {
  activeStepIndex: number;
  onChangeActiveStepIndex: (index: number) => void;
};

export const StepsContext = React.createContext({} as StepsContextType);

const CASearchTypeWrapper: React.FC<CASearchTypeWrapperProps> = ({
  pipelineId,
  children,
  activeStepIndex,
  changeActiveStepIndex,
  canStartAutomation,
  searchMode,
  setIsLoading,
}) => {
  const dispatch = useDispatch<DispatchType>();
  const handleChangeStepByClick = (index: number) => changeActiveStepIndex(index);
  const { info: campaignInfo } = useSelector(getActiveCampaignInfo);
  const contextValue = useMemo(
    () => ({
      activeStepIndex,
      onChangeActiveStepIndex: handleChangeStepByClick,
    }),
    [activeStepIndex, changeActiveStepIndex]
  );

  useEffect(() => {
    dispatch(fetchAllIntegrations(campaignInfo.workspaceId));

    getCampaignContentAutomationPresetApi(
      campaignInfo.id,
      campaignInfo.workspaceId,
      pipelineId
    ).then(
      async ({
        ahrefs,
        semrush,
        moz,
        listennotes,
        googleSearch,
        googleNews,
        bingSearch,
        bingNews,
        ahrefBacklinks,
        semrushBacklinks,
        mozBacklinks,
        responaBacklinks,
        podcast,
        episode,
        presetMode,
        presetType,
      }) => {
        await dispatch(setAhrefsMetrics(ahrefs));
        await dispatch(setSemrushMetrics(semrush));
        await dispatch(setListennotesMetrics({ ...listennotes, customDateValue: undefined }));
        await dispatch(setMozMetrics(moz));

        await dispatch(setGoogleSearchConfig(googleSearch));
        await dispatch(setGoogleNewsConfig(googleNews));
        await dispatch(setBingSearchConfig(bingSearch));
        await dispatch(setBingNewsConfig(bingNews));
        await dispatch(setPodcastsConfig(podcast));
        await dispatch(setEpisodesConfig(episode));
        await dispatch(setAhrefsBacklinksConfig(ahrefBacklinks));
        await dispatch(setSemrushBacklinksConfig(semrushBacklinks));
        await dispatch(setMozBacklinksConfig(mozBacklinks));
        await dispatch(setResponaBacklinksConfig(responaBacklinks));

        if (!campaignInfo.advanced) {
          await dispatch(setSearchMode(presetMode));
          await dispatch(setAutomationSubType(presetType));
        }

        setIsLoading(false);
      }
    );

    dispatch(getContactAutomationPresets(campaignInfo.workspaceId, null));
  }, [campaignInfo]);

  const stepsConfig = getContentAutoSearchSteps(canStartAutomation, searchMode);

  return (
    <>
      {searchMode === 3 ? null : (
        <ContentAutoSearchSteps
          style={{ marginBottom: '28px' }}
          activeStepIndex={activeStepIndex}
          onChooseStep={handleChangeStepByClick}
          stepsConfig={stepsConfig}
        />
      )}
      <StepsContext.Provider value={contextValue}>{children}</StepsContext.Provider>
    </>
  );
};

export default CASearchTypeWrapper;
