import { textStyles } from '@uikit/Select/Select';

export const getCustomStyles = (isGhostProvided: boolean, hasIcon?: boolean) => ({
  input: (provided) => {
    if (isGhostProvided) {
      return {
        ...provided,
        color: '#fff',
      };
    }

    return provided;
  },
  singleValue: (provided) => {
    return {
      ...provided,
      lineHeight: '1.5rem',
    };
  },
  valueContainer: (provided) => {
    return {
      ...provided,
      lineHeight: '1.5rem',
      ...(hasIcon
        ? {
            paddingLeft: '38px',
          }
        : {}),
    };
  },
  control: (provided, { selectProps: { isGhost } }) => {
    if (isGhost) {
      return {
        ...provided,
        backgroundColor: '#181818',
        border: 'none',
        color: '#fff',
        '&:focus,&:hover': {
          outline: 'none',
          border: 'none',
          boxShadow: 'none',
        },
      };
    }

    return {
      ...provided,
      borderRadius: '8.5px',
      '&:focus,&:hover': {
        outline: 'none',
        border: '1px solid #221CB6',
        boxShadow: 'none',
      },
    };
  },
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#000',
    borderRadius: '8px',
  }),
  menuList: (base) => ({
    ...base,
    '::-webkit-scrollbar': {
      width: '6px',
    },
    '::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '30px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#888',
    },
  }),
  option: (provided, { isFocused, isSelected }) => ({
    ...provided,
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    '&:hover, &:focus': {
      backgroundColor: '#181818',
    },
    ...(isFocused || isSelected ? { backgroundColor: '#181818' } : {}),
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#E9E9E9',
    borderRadius: '6px',
  }),
  multiValueLabel: (provided, { selectProps: { isMulti } }) => ({
    ...provided,
    ...textStyles,
    fontSize: '14px',
    overflow: 'hidden',
    ...(isMulti
      ? {
          maxWidth: '150px',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }
      : {}),
  }),
});
