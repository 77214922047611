import React from 'react';

import { GREY_COLOR } from '@constants/colors';

import FormTextField from '../FormTextField/FormTextField';

const EmailBlock = ({ email }) => {
  return (
    <div className="profile-settings__block profile-settings__username-info">
      <p className="profile-settings__block-title">Username</p>
      <FormTextField
        label="Login Email"
        name="loginEmail"
        value={email}
        onChange={() => null}
        additionalStyles={{ color: GREY_COLOR }}
        readOnly
      />
    </div>
  );
};

export default EmailBlock;
