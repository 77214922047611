import React from 'react';

export default ({color = '', size = 20}): JSX.Element => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.3501 9.09412C14.8676 9.09412 16.0976 7.86412 16.0976 6.34662C16.0976 4.82995 14.8676 3.59912 13.3501 3.59912"
      stroke="#202430"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6135 12.0801C15.0669 12.1109 15.5169 12.1759 15.961 12.2742C16.5769 12.3967 17.3185 12.6492 17.5819 13.2017C17.7502 13.5559 17.7502 13.9684 17.5819 14.3226C17.3194 14.8751 16.5769 15.1276 15.961 15.2542"
      stroke="#202430"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99283 12.6719C11.0678 12.6719 13.6945 13.1377 13.6945 14.9985C13.6945 16.8602 11.0845 17.3419 7.99283 17.3419C4.91783 17.3419 2.29199 16.8769 2.29199 15.0152C2.29199 13.1535 4.90116 12.6719 7.99283 12.6719Z"
      stroke="#202430"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99293 10.0155C5.9646 10.0155 4.3396 8.38968 4.3396 6.36134C4.3396 4.33384 5.9646 2.70801 7.99293 2.70801C10.0213 2.70801 11.6471 4.33384 11.6471 6.36134C11.6471 8.38968 10.0213 10.0155 7.99293 10.0155Z"
      stroke="#202430"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
