import React, { useState } from 'react';

import DateRangeSelect from '@uikit/DateRangeSelect/DateRangeSelect';
import { getDateShort } from '@utils/date';

import FilterValue from '../_components/FilterValue/FilterValue';
import { FilterType } from "@components/CampaignSettings/Filters/filtersConfig/index";

export default {
  label: 'Freshness Filter',
  filterKey: 'freshness',
  render: ({ onApply, value }) => {
    const [isVisible, changeIsVisible] = useState(!value);

    const handleChange = (startDate: number, endDate: number) => {
      onApply({
        startDate,
        endDate,
      });
    };

    return (
      <div>
        <DateRangeSelect
          onChange={handleChange}
          value={value}
          isVisible={isVisible}
          onChangeIsVisible={changeIsVisible}
        />
        <FilterValue onEdit={() => changeIsVisible(true)}>
          <>
            <div className="filter-value__date-value">
              {getDateShort(new Date(value?.startDate))}
            </div>
            <div className="filter-value__date-value">{getDateShort(new Date(value?.endDate))}</div>
          </>
        </FilterValue>
      </div>
    );
  },
} as FilterType;
