import React from 'react';

export default ({ color = '#fff' }) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.56869 3.86731H4.48536L6.18869 2.16398C6.24337 2.10975 6.28676 2.04523 6.31638 1.97415C6.34599 1.90306 6.36124 1.82682 6.36124 1.74981C6.36124 1.6728 6.34599 1.59656 6.31638 1.52548C6.28676 1.45439 6.24337 1.38987 6.18869 1.33564C6.0794 1.227 5.93155 1.16602 5.77744 1.16602C5.62333 1.16602 5.47549 1.227 5.36619 1.33564L2.68869 4.03648C2.63349 4.09143 2.58986 4.15689 2.56036 4.22898C2.50202 4.371 2.50202 4.53029 2.56036 4.67231C2.59207 4.74321 2.63545 4.80828 2.68869 4.86481L5.38953 7.58314C5.49723 7.69305 5.64399 7.75595 5.79786 7.75814C5.91372 7.75882 6.02716 7.72497 6.12372 7.66092C6.22027 7.59687 6.29556 7.50552 6.33999 7.39851C6.38442 7.2915 6.39598 7.17368 6.37319 7.06008C6.3504 6.94648 6.29429 6.84224 6.21203 6.76064L4.50869 5.03398H8.59203C9.05615 5.03398 9.50127 5.21835 9.82946 5.54654C10.1577 5.87473 10.342 6.31985 10.342 6.78398V12.2498C10.342 12.4045 10.4035 12.5529 10.5129 12.6623C10.6223 12.7717 10.7706 12.8331 10.9254 12.8331C11.0801 12.8331 11.2284 12.7717 11.3378 12.6623C11.4472 12.5529 11.5087 12.4045 11.5087 12.2498V6.78398C11.5087 6.39898 11.4325 6.0178 11.2845 5.6624C11.1364 5.30701 10.9195 4.98443 10.6462 4.71329C10.3729 4.44214 10.0486 4.22779 9.69202 4.0826C9.33546 3.9374 8.95367 3.86423 8.56869 3.86731Z"
      fill={color}
    />
  </svg>
);
