import React, {useState} from 'react';
import BlockHeader from '@components/Insights/BlockHeader/BlockHeader';
import GraphicContent from '@components/Insights/GraphicCntent/GraphicContent';
import { InsightCard } from '@components/Insights/EmailReport/EmailReport';

import './InsightGraphic.scss';
import HeatMap from '@components/Insights/HeatMap/HeatMap';
import GraphicHeader from '@components/Insights/GraphicHeader/GraphicHeader';

const InsightGraphic = ({
  charts,
  isSidebar,
  title,
  isActiveButton,
  isActiveFilters,
  mainGraphicData,
  handleChangeCheckbox,
  selectOptions,
  onSelect,
  selectedReport,
  dataHeader,
  sidebarType,
  selectedValue,
  isActiveChangingFilters,
  handlerChangeSwitcher,
  isActiveLineChart,
  isActiveSwitch,
  graphicWidth,
  sidebarWidth,
  sidebarItemMaxWidth,
}: {
  charts?: any;
  mainGraphicData?: any;
  isSidebar: boolean;
  title: string;
  isActiveButton?: boolean;
  isActiveFilters?: boolean;
  handleChangeCheckbox?: (checkboxId) => void;
  handlerChangeSwitcher?: (value) => void;
  selectOptions?: any;
  onSelect?: (value: string) => void;
  selectedReport?: string;
  dataHeader?: any;
  sidebarType?: string;
  selectedValue?: any;
  isActiveChangingFilters?: boolean;
  isActiveLineChart?: boolean;
  isActiveSwitch?: boolean;
  graphicWidth?: string;
  sidebarWidth?: string;
  sidebarItemMaxWidth?: string;

}): JSX.Element => {
  return (
    <div className="insight-graphic-container">
      {!isSidebar ? (
        <div className="insight-graphic-container_block">
          <BlockHeader
            selectOptions={selectOptions}
            title={title}
            filters={isActiveFilters}
            isActive={isActiveButton}
            onSelect={onSelect}
            selectedValue={selectedValue}
          />
          <div className="graphic-content_header">
            <GraphicHeader dataHeader={dataHeader} />
          </div>
          <HeatMap data={mainGraphicData} selectedValue={selectedReport} />
        </div>
      ) : (
        <div className="insight-graphic-container_with-sidebar">
          <div className="insight-graphic-container_block" style={{ width: `${graphicWidth}%` }}>
            <BlockHeader
              title={title}
              filters
              isActive={isActiveButton}
              isActiveChangingFilters={isActiveChangingFilters}
              handlerChangeSwitcher={handlerChangeSwitcher}
              isActiveSwitch={isActiveSwitch}
            />
            <GraphicContent
              names={charts}
              dataGraphic={mainGraphicData}
              isActiveLineChart={isActiveLineChart}
            />
          </div>
          <div
            className={`insight-graphic-container_sidebar ${
              sidebarType === 'grid' ? 'grid-sidebar' : ''
            }`}
            style={{ width: `${sidebarWidth}%` }}
          >
            {charts.map((item) => {
              return (
                <label
                  key={item.field}
                  className={`insight-graphic-container_sidebar_item ${
                    sidebarType === 'grid' ? 'grid-sidebar_item' : ''
                  } ${item.isClickable ? 'clickable' : ''}`}
                  style={{ maxWidth: `${sidebarItemMaxWidth}%` }}
                >
                  <InsightCard
                    type="basic"
                    title={item.title}
                    value={item.value}
                    color={item.color}
                    percentageFromTotal={item.percentage}
                    size={33}
                    isChart={item.isChart}
                    chartField={`${item.field}__${title}`}
                    isChecked={item.isChecked}
                    checkboxPosition="absolute"
                    isCheckbox={item.isCheckbox}
                    handleChangeCheckbox={handleChangeCheckbox}
                  />
                </label>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default InsightGraphic;
