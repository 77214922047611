import React, { useCallback } from 'react';

import cn from 'class-names';

import Display from '@components/Display/Display';
import { TemplateStepType, TemplateStepTypeWithTempId } from '@ts/template.types';

import SequenceSingleStepWithoutAutosave from '@components/CampaignSettings/SequenceSingleStep/SequenceSingleStepWithoutAutosave';
import stripHtml from '@utils/stripHtml';

import './SequenceSteps.scss';

const SequenceStepsWithoutAutosave = ({
  sequenceSteps,
  onOpenStep,
  openedStepIndex,
  onChangeDelay,
  onCreateStep,
}: {
  sequenceSteps: TemplateStepTypeWithTempId[];
  onOpenStep?: (index: number) => void;
  openedStepIndex: number;
  onChangeDelay?: (step: TemplateStepType) => void;
  onCreateStep?: () => void;
}): JSX.Element => {
  const handleDelayChange = (step: TemplateStepType) => onChangeDelay(step);

  const cnCreateStepBtn = cn('sequence-steps__create-btn', {
    'sequence-steps__create-btn--disabled': sequenceSteps[0]?.threadA.subject?.trim() === '',
  });

  const createSubjectFromPrev = useCallback(
    (rightArrayIndex) => {
      const htmlSubject = sequenceSteps
        .slice(0, rightArrayIndex + 1)
        .reverse()
        .find(({ threadA: { subject } }) => !!stripHtml(subject).trim())?.threadA.subject;

      return `RE: ${stripHtml(htmlSubject).trim()}`;
    },
    [sequenceSteps]
  );

  return (
    <>
      <div className="sequence-steps">
        {sequenceSteps.map((step: TemplateStepTypeWithTempId, index) => {
          const subject =
            index === 0
              ? stripHtml(step.threadA.subject).trim()
              : stripHtml(step.threadA.subject).trim() || createSubjectFromPrev(index);

          const delayFromFirstDay =
            index === 0
              ? step.sendDelay + 1
              : sequenceSteps
                  .slice(0, index + 1)
                  .reduce((acc, { sendDelay }) => acc + sendDelay, 1);

          return (
            <SequenceSingleStepWithoutAutosave
              step={step}
              delayFromFirstDay={delayFromFirstDay}
              subject={subject}
              isActive={index === openedStepIndex}
              key={`step-${step.tempId}-${index}`}
              index={index}
              onClick={onOpenStep}
              onChangeDelay={onChangeDelay ? handleDelayChange : null}
            />
          );
        })}
      </div>
      <Display isVisible={sequenceSteps.length < 5 && onCreateStep !== undefined}>
        <div onClick={onCreateStep} className={cnCreateStepBtn}>
          +&nbsp;&nbsp;Create new step
        </div>
      </Display>
    </>
  );
};

export default SequenceStepsWithoutAutosave;
