/* eslint-disable react/no-array-index-key */
import React from 'react';
import cn from 'class-names';

import Display from '@components/Display/Display';
import SequenceSingleStep from '@components/CampaignSettings/SequenceSingleStep/SequenceSingleStep';
import {
  TemplateContainerTypeWithTransformedStepsType,
  TemplateStepTransformedType,
} from '@ts/template.types';

import './SequenceSteps.scss';

const SequenceSteps = ({
  sequenceSteps,
  onOpenStep,
  openedStepIndex,
  onChangeDelay,
  onCreateStep,
}: {
  sequenceSteps: TemplateStepTransformedType[];
  onOpenStep?: (index: number) => void;
  openedStepIndex: number;
  onChangeDelay?: (
    step: TemplateStepTransformedType
  ) => Promise<TemplateContainerTypeWithTransformedStepsType> | void;
  onCreateStep?: () => void;
}): JSX.Element => {
  const handleDelayChange = (step: TemplateStepTransformedType) => onChangeDelay(step);

  const cnCreateStepBtn = cn('sequence-steps__create-btn', {
    'sequence-steps__create-btn--disabled': sequenceSteps[0]?.subject?.trim() === '',
  });

  return (
    <div className="sequence-steps__wrapper">
      <div className="sequence-steps">
        {sequenceSteps.map((step, index) => (
          <SequenceSingleStep
            step={step}
            id={step.id || step.order}
            sendDelay={step.sendDelay}
            delayFromFirstDay={step.delayFromFirstDay}
            subject={step.subject}
            prevSubject={step.prevSubject}
            content={step.content}
            isSameThread={step.isSameThread}
            isActive={index === openedStepIndex}
            key={`step-${step.id || step.order}`}
            index={index}
            onClick={onOpenStep}
            onChangeDelay={onChangeDelay ? handleDelayChange : null}
          />
        ))}
      </div>
      <Display isVisible={sequenceSteps.length < 5 && onCreateStep !== undefined}>
        <div onClick={onCreateStep} className={cnCreateStepBtn}>
          +&nbsp;&nbsp;Create new step
        </div>
      </Display>
    </div>
  );
};

export default SequenceSteps;
