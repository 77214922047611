import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import {
  CustomVariableType,
  CustomVariableValueRelatedToMap,
} from 'respona_api/generated/common_pb';

import useCustomVariables from '@hooks/useCustomVariables';
import useWorkspaceMembers from '@hooks/useWorkspaceMembers';

import {
  CustomVariablePlaceholderType,
  CustomVariableSelectItemValueType,
  CustomVariableSelectType,
  CustomVariableValueType,
} from '@ts/customVariales.types';

import Loader from '@uikit/Loader/Loader';
import { SVGIcon } from '@uikit/Icon/Icon';
import SidebarCRMSection from '@components/SidebarCRM/sections/SidebarCRMSection/SidebarCRMSection';
import CustomFieldsSidebar from '@components/CustomFieldsSidebar/CustomFieldsSidebar';
import CustomVariable from '@components/SidebarCRM/sections/SidebarCRMSectionVariables/_components/CustomVariable/CustomVariable';

import './SidebarCRMSectionVariables.scss';

type Props = {
  queryKey: string;
  index: number;
  itemId: number;
  relatedTo: CustomVariableValueRelatedToMap[keyof CustomVariableValueRelatedToMap];
  getVariables: (variables: string[], ids: number[]) => Promise<CustomVariableValueType[]>;
  createOrUpdateVariable: (value: CustomVariableValueType) => Promise<void>;
};

const SidebarCRMSectionVariables: React.FC<Props> = ({
  queryKey,
  index,
  itemId,
  relatedTo,
  getVariables,
  createOrUpdateVariable,
}: Props) => {
  const ref = useRef(null);
  const queryClient = useQueryClient();
  const [queryEnabled, setQueryEnabled] = useState<boolean>(true);
  const [isOpenCustomFieldsSideBar, setOpenCustomFieldsSideBar] = useState(false);
  const preparedQuery = [queryKey, itemId];

  const { customVariables, isCustomVariablesLoading } = useCustomVariables();
  const { members: workspaceMembers } = useWorkspaceMembers();

  const customVariablesByType = useMemo(
    () =>
      customVariables?.filter((it) => {
        if (it.id < 0 || it?.isDefault === true || it.type === CustomVariableType.OPEN_AI) {
          return false;
        }
        return it.relatedTo === relatedTo;
      }) || [],
    [customVariables, itemId]
  );

  const relatedIds = [itemId];

  const { data: valuesData } = useQuery<CustomVariableValueType[]>(
    preparedQuery,
    () =>
      getVariables(
        customVariablesByType.map((it) => it.name),
        relatedIds
      ),
    {
      enabled: queryEnabled && customVariablesByType.length > 0 && relatedIds.length > 0,
      refetchOnWindowFocus: false,
      onSettled: (response) => response?.length && ref.current?.setOpen(true),
      staleTime: 5 * 60 * 1000,
    }
  );

  const onOpenCallback = useCallback(() => {
    setQueryEnabled(true);
  }, []);

  const handleSaveVariable = useCallback(
    (newVariable: CustomVariableValueType) => {
      createOrUpdateVariable(newVariable).then(() => {
        queryClient.setQueryData(preparedQuery, (data: CustomVariableValueType[]) => {
          if (!data) {
            return [newVariable];
          }
          const existingIndex = data.findIndex(
            (item) => item.variableId === newVariable.variableId
          );
          if (existingIndex === -1) {
            return [...data, newVariable];
          }
          return data.map((item, ind) => (ind === existingIndex ? newVariable : item));
        });
      });
    },
    [queryClient, preparedQuery]
  );

  const handleSaveStringVariable = useCallback(
    (newInput: string, variable: CustomVariablePlaceholderType, value: CustomVariableValueType) => {
      const newVariable = value
        ? { ...value, stringValue: newInput }
        : ({
            variableId: variable.id,
            variableName: variable.name,
            type: variable.type,
            relatedTo: variable.relatedTo,
            relatedId: relatedIds[0],
            stringValue: newInput,
          } as CustomVariableValueType);
      handleSaveVariable(newVariable);
    },
    [handleSaveVariable, relatedIds]
  );

  const handleSaveSelectVariable = (
    newInput: { label: string; value: string }[],
    variable: CustomVariablePlaceholderType,
    value: CustomVariableValueType
  ) => {
    const select = {
      valuesList: newInput.map((it) => {
        return { uid: it.value, value: it.label } as CustomVariableSelectItemValueType;
      }),
    } as CustomVariableSelectType;
    const newVariable = value
      ? ({ ...value, selectValue: select } as CustomVariableValueType)
      : ({
          variableId: variable.id,
          variableName: variable.name,
          type: variable.type,
          relatedTo: variable.relatedTo,
          relatedId: relatedIds[0],
          stringValue: null,
          selectValue: select,
        } as CustomVariableValueType);
    handleSaveVariable(newVariable);
  };

  return (
    <SidebarCRMSection
      ref={ref}
      index={index}
      id="variables"
      title="Custom fields"
      onOpen={onOpenCallback}
      rightComponent={
        <div
          className="contact-sidebar-crm__content__add-btn"
          onClick={() => {
            setOpenCustomFieldsSideBar(true);
          }}
        >
          <SVGIcon icon="plusIcon" size={10} color="#221CB6" />
          Add
        </div>
      }
    >
      {isOpenCustomFieldsSideBar ? (
        <CustomFieldsSidebar
          extraClass="contacts-sidebar"
          isOpen={isOpenCustomFieldsSideBar}
          isUpdate={false}
          relatedTo={relatedTo}
          onClose={() => {
            setOpenCustomFieldsSideBar(false);
          }}
        />
      ) : null}
      <div className="contacts-sidebar-section-variables">
        <Loader isLoading={isCustomVariablesLoading}>
          {customVariablesByType.length ? (
            customVariablesByType.map((customVariableByType) => (
              <CustomVariable
                key={customVariableByType.id}
                item={customVariableByType}
                variableValues={valuesData}
                members={workspaceMembers}
                handleSaveStringVariable={handleSaveStringVariable}
                handleSaveSelectVariable={handleSaveSelectVariable}
              />
            ))
          ) : (
            <div className="contacts-sidebar-section-variables--empty">Empty</div>
          )}
        </Loader>
      </div>
    </SidebarCRMSection>
  );
};

export default SidebarCRMSectionVariables;
