import { OpportunityDetailsType } from '@ts/opportunity.types';
import { ContentMetricsType } from 'respona_api/generated/common_pb';

export const getTitleAndUrl = (
  opportunity: OpportunityDetailsType
): {
  title: string;
  url: string;
  useAdditionalFields: boolean;
} => {
  const importOrRelationshipType = opportunity.type === 6 || opportunity.type === 7;
  const hasAdditionalFields = opportunity.additionalFieldsList?.length > 0;
  const useAdditionalFields = !opportunity.url && importOrRelationshipType && hasAdditionalFields;

  const additionalFieldsHashMapByTypes =
    opportunity.additionalFieldsList?.reduce(
      (acc, { type, value }) => ({
        ...acc,
        [type]: value,
      }),
      {}
    ) || {};

  const computedUrl = opportunity.url || additionalFieldsHashMapByTypes[0];
  const computedTitle =
    opportunity.title ||
    additionalFieldsHashMapByTypes[1] ||
    additionalFieldsHashMapByTypes[2] ||
    additionalFieldsHashMapByTypes[0];

  return {
    title: computedTitle || '-',
    url: computedUrl || '-',
    useAdditionalFields,
  };
};

export const getTypeInfo = (item) => {
  if (item.type === 0) {
    return item.googleSearch;
  }
  if (item.type === 1) {
    return item.bingSearch;
  }
  if (item.type === 2) {
    return item.podcast;
  }
  if (item.type === 3) {
    return item.episode;
  }
  if (item.type === 4) {
    return item.trendingContent;
  }
  if (item.type === 5) {
    return item.bingNews;
  }
  if (item.type === 8) {
    return item.ahrefBacklinks;
  }
  if (item.type === 9) {
    return item.semrushBacklinks;
  }
  if (item.type === 10) {
    return item.mozBacklinks;
  }
  if (item.type === 12) {
    return item.googleNews;
  }
  return {};
};

export const formatMetrics = (input: string | number): string => {
  if (typeof input === 'string') {
    return input;
  }
  if (typeof input === 'number') {
    if (input < 1000) {
      return Math.round(input).toString();
    }
    if (input < 1000000) {
      return `${(Math.round((input / 1000) * 100) / 100).toFixed(1)}K`;
    }
    return `${(Math.round((input / 1000000) * 100) / 100).toFixed(1)}M`;
  }
  return input;
};

export const composeDomainRatingTitle = (opportunity): string => {
  if (opportunity == null || opportunity.metricsType < 0) {
    return 'Domain authority';
  }
  switch (opportunity.metricsType) {
    case 0:
      return 'Domain rating';
    case 1:
      return 'Authority score';
    default:
      return 'Domain authority';
  }
};

export const IconsMapByType = {
  [ContentMetricsType.AHREFS_METRICS]: require(`./_assets/ahrefs-crm-integration.png`).default,
  [ContentMetricsType.SEMRUSH_METRICS]: require(`./_assets/semrush-crm-integration.png`).default,
  [ContentMetricsType.MOZ_METRICS]: require(`./_assets/moz-crm-integration.png`).default,
  [ContentMetricsType.LISTENNOTES_METRICS]: require(`./_assets/listenotes-logo-square.png`).default,
};

export const composeMetricsIcon = (opportunity) => {
  if (opportunity == null || opportunity.metricsType < 0) {
    return IconsMapByType[ContentMetricsType.MOZ_METRICS];
  }
  switch (opportunity.metricsType) {
    case 0:
      return IconsMapByType[ContentMetricsType.AHREFS_METRICS];
    case 1:
      return IconsMapByType[ContentMetricsType.SEMRUSH_METRICS];
    case 2:
      return IconsMapByType[ContentMetricsType.LISTENNOTES_METRICS];
    case 3:
      return IconsMapByType[ContentMetricsType.MOZ_METRICS];
    default:
      return IconsMapByType[ContentMetricsType.MOZ_METRICS];
  }
};

export const isAllMetricsZero = ({
  listenScore,
  globalRank,
  domainRating,
  domainAuthority,
  pageAuthority,
  domainTraffic,
  domainTrafficKeywordsNumber,
  domainAuthorityScore,
  domainSpamScore,
}): boolean => {
  return (
    listenScore === 0 &&
    domainRating === 0 &&
    domainAuthority === 0 &&
    pageAuthority === 0 &&
    domainTraffic === 0 &&
    domainTrafficKeywordsNumber === 0 &&
    domainAuthorityScore === 0 &&
    domainSpamScore === null &&
    (globalRank === null || globalRank === 0 || globalRank === '0%')
  );
};
