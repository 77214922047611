import { createAction } from 'redux-actions';

import { TemplateType } from '@ts/template.types';

export const setSequenceTemplates = createAction<TemplateType[]>(
  'SET_WORKSPACE_SEQUENCE_TEMPLATES'
);
export const setSequenceTemplatesLs = createAction<string>('SET_WORKSPACE_SEQUENCE_TEMPLATES_LS');
export const clearSequenceTemplates = createAction('CLEAR_WORKSPACE_SEQUENCE_TEMPLATES');
export const addSequenceTemplate = createAction('ADD_SEQUENCE_TEMPLATE');
export const removeSequenceTemplate = createAction<number>('REMOVE_SEQUENCE_TEMPLATE');
export const addSequenceTemplateStep = createAction('ADD_SEQUENCE_TEMPLATE_STEP');
export const updateSequenceTemplateStep = createAction('UPDATE_SEQUENCE_TEMPLATE_STEP');

export const clearEmailTemplates = createAction('CLEAR_WORKSPACE_EMAIL_TEMPLATES');
export const setEmailTemplates = createAction<TemplateType[]>('SET_WORKSPACE_EMAIL_TEMPLATES');
export const setEmailTemplatesLs = createAction<string>('SET_WORKSPACE_EMAIL_TEMPLATES_LS');
export const addOrUpdateEmailTemplate = createAction<TemplateType>(
  'ADD_OR_UPDATE_WORKSPACE_EMAIL_TEMPLATE'
);
export const removeEmailTemplate = createAction<number>('REMOVE_WORKSPACE_EMAIL_TEMPLATE');
