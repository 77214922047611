import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';

import Input from '@uikit/Input/Input';
import Loader from '@uikit/Loader/Loader';

import Display from '@components/Display/Display';
import MetaInfoValue from '@components/Inbox/InboxWidget/_components/MetaInfo/_components/MetaInfoValue';

import '../MetaInfo.scss';

const From = ({
  isSenderEmailLoading,
  selectedSenderEmail,
  senderEmails,
  onChangeSenderEmail,
}: {
  isSenderEmailLoading: boolean;
  selectedSenderEmail: string;
  senderEmails: string[];
  onChangeSenderEmail: (string) => void;
}): JSX.Element => {
  const [query, setQuery] = useState<string>('');

  return (
    <div className="meta-info__row">
      <span className="meta-info__label">From:</span>
      {isSenderEmailLoading ? (
        <Loader isLoading />
      ) : (
        <>
          <span data-for="editor-sender-meta-chevron" data-tip="" data-event="click focus">
            <MetaInfoValue value={selectedSenderEmail} />
          </span>
          <ReactTooltip
            id="editor-sender-meta-chevron"
            className="react-tooltip"
            place="bottom"
            effect="float"
            event="click"
            globalEventOff="click"
            arrowColor="transparent"
            clickable
          >
            <div className="meta-info__contacts meta-info-contacts">
              <Input
                className="meta-info__contacts-search"
                placeholder="Search"
                icon="search"
                value={query}
                onChange={({ target: { value } }) => setQuery(value)}
                isTransparent
              />
              {senderEmails?.map((email) => (
                <div
                  key={email}
                  onClick={onChangeSenderEmail ? () => onChangeSenderEmail(email) : () => null}
                  className="meta-info-contacts__contact"
                  tabIndex={0}
                  role="button"
                >
                  {email}
                </div>
              ))}
            </div>
          </ReactTooltip>
        </>
      )}
    </div>
  );
};

export default From;
