import React from 'react';

export const ExternalUnsubscribePageGradientLeft = (): JSX.Element => (
  <svg
    width="486"
    height="1526"
    viewBox="0 0 486 1526"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="external-unsubscribe-page__gradient-left"
  >
    <g opacity="0.6" filter="url(#filter0_f_13738_1349)">
      <path
        d="M-281.378 867.145C-298.584 915.677 -600.899 1117.05 -562.031 1155.93C-486.254 1231.74 -52.929 1006.91 3.07956 915.831C47.3477 843.842 125.129 688.271 60.6448 630.671C32.1916 605.255 -239.096 412.359 -284.552 401.734C-386.27 377.959 -255.2 793.303 -281.378 867.145Z"
        fill="url(#paint0_radial_13738_1349)"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_13738_1349"
        x="-965.478"
        y="0.752441"
        width="1450.7"
        height="1570.53"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="200" result="effect1_foregroundBlur_13738_1349" />
      </filter>
      <radialGradient
        id="paint0_radial_13738_1349"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(-347.045 1011.63) rotate(3.24421) scale(274.895 434.608)"
      >
        <stop stopColor="#251FB6" />
        <stop offset="1" stopColor="#0075FF" />
      </radialGradient>
    </defs>
  </svg>
);

export const ExternalUnsubscribePageGradientRight = (): JSX.Element => (
  <svg
    width="795"
    height="816"
    viewBox="0 0 795 816"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="external-unsubscribe-page__gradient-right"
  >
    <g opacity="0.3" filter="url(#filter0_f_13738_1348)">
      <path
        d="M607.217 187.103C588.656 239.459 262.522 456.698 304.451 498.644C386.199 580.423 853.666 337.882 914.088 239.625C961.844 161.964 1045.75 -5.86412 976.188 -68.0028C945.493 -95.4213 652.831 -303.516 603.794 -314.978C494.061 -340.626 635.458 107.443 607.217 187.103Z"
        fill="url(#paint0_radial_13738_1348)"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_13738_1348"
        x="0.73291"
        y="-616.037"
        width="1301.97"
        height="1431.24"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="150" result="effect1_foregroundBlur_13738_1348" />
      </filter>
      <radialGradient
        id="paint0_radial_13738_1348"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(536.377 342.971) rotate(3.24421) scale(296.554 468.851)"
      >
        <stop stopColor="#251FB6" />
        <stop offset="1" stopColor="#5847FF" />
      </radialGradient>
    </defs>
  </svg>
);
