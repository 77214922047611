import React, { useEffect } from 'react';

import { Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import NoAccessMessage from '@uikit/NoAccessMessage/NoAccessMessage';

import BillingHistory from '@components/Settings/BillingHistory/BillingHistory';
import ChangeBillingForm from '@components/Settings/ChangeBillingForm/ChangeBillingForm';
import SubscriptionForm from '@components/Settings/SubscriptionForm/SubscriptionForm';
import InvoiceDetails from '@components/Settings/InvoiceDetails/InvoiceDetails';
import Billing from '@components/Settings/Billing/Billing';

import Loader from '@uikit/Loader/Loader';

import { billingDetailsSelector } from '@redux/selectors/billings.selectors';
import { fetchBillingDetails } from '@redux/thunks/billings.thunks';

import { checkIsAnyNotLoaded } from '@utils/loadingChecks';
import loadingStatuses from '@constants/loadingStatuses';

import './BillingPage.scss';
import { userProfileSelector } from '@redux/selectors/userProfile.selector';

function BillingPage(): JSX.Element {
  const dispatch = useDispatch();
  const { info: userProfileInfo } = useSelector(userProfileSelector);
  const { data: billingDetails, loadingStatus: billingDetailsLs } =
    useSelector(billingDetailsSelector);

  useEffect(() => {
    if (checkIsAnyNotLoaded(billingDetailsLs)) {
      dispatch(fetchBillingDetails());
    }
  }, []);

  if (checkIsAnyNotLoaded(billingDetailsLs)) {
    return <Loader isFullBlockLoading />;
  }

  /*
    it was tried to load, and we still have initial values with id === 0 ->
    -> it means we have no access to billing
   */
  if (billingDetailsLs === loadingStatuses.LOADED && billingDetails.id === 0) {
    return <NoAccessMessage />;
  }

  if (userProfileInfo.rolesList.includes('MEMBER')) {
    return <NoAccessMessage />;
  }

  return (
    <>
      <Route path="/settings/billing/history">
        <BillingHistory />
      </Route>
      <Route path="/settings/billing/change-billing">
        <ChangeBillingForm billingDetails={billingDetails} />
      </Route>
      <Route path="/settings/billing/change-subscription">
        <SubscriptionForm />
      </Route>
      <Route path="/settings/billing/invoice/:invoiceId" component={InvoiceDetails} />
      <Route exact path="/settings/billing">
        <Billing billingDetails={billingDetails} />
      </Route>
    </>
  );
}

export default BillingPage;
