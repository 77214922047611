import React from 'react';

export default ({color = '#ffffff', size = 11, className = ''}) => (
  <svg className={className} width={size} height={size} viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_23220_163)">
      <path
        d="M1.6499 5.5C1.6499 7.6263 3.37361 9.35 5.4999 9.35C7.6262 9.35 9.3499 7.6263 9.3499 5.5C9.3499 3.37371 7.6262 1.65 5.4999 1.65"
        stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
    </g>
    <defs>
      <clipPath id="clip0_23220_163">
        <rect width={size} height={size} fill={color}/>
      </clipPath>
    </defs>
  </svg>
);
