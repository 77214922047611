import React from 'react';

export default ({ color = '#BDBDBD' }) => (
  <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 20H2.9C2.39609 20 1.91282 19.7998 1.5565 19.4435C1.20018 19.0872 1 18.6039 1 18.1V3C1 1.89543 1.89543 1 3 1H8.6L11.45 4.8H18.9C20.5569 4.8 21.9 6.14315 21.9 7.8V18.1C21.9 18.6039 21.6998 19.0872 21.3435 19.4435C20.9872 19.7998 20.5039 20 20 20Z"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M11.4499 14.6802C12.7091 14.6802 13.7299 13.6594 13.7299 12.4002C13.7299 11.141 12.7091 10.1202 11.4499 10.1202C10.1907 10.1202 9.16992 11.141 9.16992 12.4002C9.16992 13.6594 10.1907 14.6802 11.4499 14.6802Z"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.4502 10.1201V8.6001"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.0625 10.7879L14.1379 9.71246"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.7305 12.4001H15.2505"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.0625 14.012L14.1379 15.0874"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.4502 14.68V16.2"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.8381 14.012L8.7627 15.0874"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.17039 12.4001H7.65039"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.8381 10.7879L8.7627 9.71246"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
