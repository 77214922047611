import { handleActions } from 'redux-actions';

import {
  csSetSequence,
  csSetSequenceLs,
  csUpdateSequenceStepAttachments,
  csUpdateSingleSequenceStep,
  csRemoveSequenceStep,
  csAddSequenceStep,
  csSetSequenceAdditionalContent,
} from '@redux/actions/sequence.actions';
import { csClearOpenCampaignInfo } from '@redux/actions/campaignSettings.actions';

import loadingStatuses from '@constants/loadingStatuses';
import { updateItemInListByIdHelper } from '@helpers/commonHelpers';

export type SequenceStateType = {
  loadingStatus: string;
  steps: any;
  enabledUnsubscribeContent: boolean;
  enabledReferralContent: boolean;
};

const initialState: SequenceStateType = {
  loadingStatus: loadingStatuses.PENDING,
  steps: [],
  enabledUnsubscribeContent: false,
  enabledReferralContent: false,
};

export default handleActions(
  {
    [csSetSequence]: (state: SequenceStateType, { payload }) => ({
      ...state,
      steps: payload,
    }),
    [csSetSequenceAdditionalContent]: (state: SequenceStateType, { payload }) => ({
      ...state,
      ...payload,
    }),
    [csSetSequenceLs]: (state: SequenceStateType, { payload }) => ({
      ...state,
      loadingStatus: payload,
    }),
    [csUpdateSingleSequenceStep]: (state: SequenceStateType, { payload }) => ({
      ...state,
      steps: updateItemInListByIdHelper(state.steps, payload),
    }),
    [csUpdateSequenceStepAttachments]: (state: SequenceStateType, { payload }) => {
      return {
        ...state,
        steps: state.steps.map((tmpStep) =>
          tmpStep.id === payload.stepId
            ? {
                ...tmpStep,
                threadA: {
                  ...tmpStep.threadA,
                  attachmentsList: payload.attachmentsList,
                },
              }
            : tmpStep
        ),
      };
    },
    [csRemoveSequenceStep]: (state: SequenceStateType, { payload }) => ({
      ...state,
      steps: state.steps.filter(({ id }) => id !== payload),
    }),
    [csAddSequenceStep]: (state: SequenceStateType, { payload }) => ({
      ...state,
      steps: [...state.steps, payload],
    }),
    [csClearOpenCampaignInfo]: () => initialState,
  },
  initialState
);
