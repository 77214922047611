import React from 'react';
import ReactApexChart from 'react-apexcharts';

const ApexChartsComponent = ({ data: dataGraphic, selectedValue }): JSX.Element => {
  const getDay = (name) => dataGraphic.filter((item) => item.dayOfWeek === name)[0];
  const generateEmptyDayHours = () => ({
    '12 AM': 0,
    '1 AM': 0,
    '2 AM': 0,
    '3 AM': 0,
    '4 AM': 0,
    '5 AM': 0,
    '6 AM': 0,
    '7 AM': 0,
    '8 AM': 0,
    '9 AM': 0,
    '10 AM': 0,
    '11 AM': 0,
    '12 PM': 0,
    '1 PM': 0,
    '2 PM': 0,
    '3 PM': 0,
    '4 PM': 0,
    '5 PM': 0,
    '6 PM': 0,
    '7 PM': 0,
    '8 PM': 0,
    '9 PM': 0,
    '10 PM': 0,
    '11 PM': 0,
  });
  const series = [];
  const weekDays = [
    { name: 'SUNDAY', shortName: 'SU' },
    { name: 'MONDAY', shortName: 'MO' },
    { name: 'TUESDAY', shortName: 'TU' },
    { name: 'WEDNESDAY', shortName: 'WE' },
    { name: 'THURSDAY', shortName: 'TH' },
    { name: 'FRIDAY', shortName: 'FR' },
    { name: 'SATURDAY', shortName: 'SA' },
  ];
  weekDays.forEach((day) => {
    const dayData = getDay(day.name);

    if (dayData) {
      const emptyDayHours = generateEmptyDayHours();
      dayData[selectedValue].forEach(({ name, value }) => {
        emptyDayHours[name] += parseInt(value, 10);
      });

      series.push({
        name: day.shortName,
        data: Object.keys(emptyDayHours).map((key) => ({ x: key, y: emptyDayHours[key] })),
      });
    }
  });

  const data: any = {
    series,
    options: {
      chart: {
        background: '#F9F9F9',
        height: 352,
        type: 'heatmap',
        toolbar: {
          show: false,
        },
      },
      states: {
        hover: {
          filter: {
            type: 'darken',
            value: 0.75,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ['#221cb6'],
      title: {
        show: false,
      },
      stroke: {
        curve: 'straight',
        width: 1,
        colors: ['#E9ECF1'],
      },
      xaxis: {
        show: true,
        labels: {
          show: true,
          style: {
            colors: '#B2BCC7',
            fontSize: '12px',
            fontFamily: 'Mulish',
            fontWeight: 400,
            cssClass: 'apexcharts-yaxis-label',
          },
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: true,
          style: {
            colors: '#B2BCC7',
            fontSize: '12px',
            fontFamily: 'Mulish',
            fontWeight: 400,
            cssClass: 'apexcharts-yaxis-label',
          },
        },
        axisBorder: {
          show: true,
          color: '#E9ECF1',
          offsetX: 0,
          offsetY: 0,
        },
      },
    },
  };

  return (
    <div id="chart">
      <ReactApexChart options={data.options} series={data.series} type="heatmap" height={352} />
    </div>
  );
};

export default ApexChartsComponent;
