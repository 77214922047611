import React, { useState, useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { checkContactsExistsByCampaignIdApi, getOpportunityByIdApi } from '@api/campaign.api';

import { getActiveCampaignInfo } from '@redux/selectors/campaignSettings.selectors';

import Button from '@uikit/Button/Button';
import Loader from '@uikit/Loader/Loader';
import PersonalizationSteps from '@components/CampaignSettings/PersonalizationSteps/PersonalizationSteps';
import PersonalizationSideBar from '@components/CampaignSettings/PersonalizationSideBar/PersonalizationSideBar';

import './PersonalizeCampaignSettingsPage.scss';
import PersonalizeOpportunities from '@pages/PersonalizeOpportunities/PersonalizeOpportunities';
import { sequenceSelector } from '@redux/selectors/sequence.selectors';
import { getBasePersonByIdApi } from '@api/people.api';
import { OpportunityPersonContactType } from '@ts/contact.types';
import ErrorBoundary from '@uikit/ErrorBoundaries/ErrorBoundary';
import { emptyEditorHtml } from '@constants/html.constants';
import useReferralLink from '@hooks/useReferralLink';
// @ts-ignore
import emptyContactsIllustration from './assets/emptyContactsIllustration.svg';

function PersonalizeCampaignSettingsPage(): JSX.Element {
  const refToBlur = useRef();
  const [selectedContact, changeSelectedContact] = useState<OpportunityPersonContactType>(null);
  const [activeOpportunityId, changeActiveOpportunityId] = useState<number>(null);
  const [activePersonId, changeActivePersonId] = useState<number>(null);
  const { steps: sequenceSteps, loadingStatus: sequenceLoadingStatus } =
    useSelector(sequenceSelector);

  const { info: campaignInfo } = useSelector(getActiveCampaignInfo);

  const { data: contactsExistsByCampaign, isLoading: isLoadingContactsExistsByCampaign } = useQuery(
    ['contacts-exists-by-campaign', campaignInfo.id, campaignInfo.lastBatch],
    () =>
      checkContactsExistsByCampaignIdApi(
        campaignInfo.id,
        campaignInfo.workspaceId,
        campaignInfo.lastBatch
      ),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!campaignInfo && !!campaignInfo.id,
    }
  );

  const {
    data: activeOpportunity,
    isLoading: isLoadingActiveOpportunity,
    isRefetching: isRefetchingActiveOpportunity,
    isFetching: isFetchingActiveOpportunity,
  } = useQuery(
    ['active-opportunity', activeOpportunityId],
    () => getOpportunityByIdApi(activeOpportunityId, campaignInfo.workspaceId, campaignInfo.id),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!campaignInfo && !!activeOpportunityId,
    }
  );

  const { data: activePerson, isLoading: isLoadingActivePerson } = useQuery(
    ['active-person', activePersonId],
    () => getBasePersonByIdApi(activePersonId),
    {
      enabled: !!activePersonId && !!campaignInfo,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  const { item: referralContentData } = useReferralLink();

  const isEmptySteps = (): boolean => {
    if (sequenceSteps == null || sequenceSteps.length <= 0) {
      return true;
    }
    if (sequenceSteps.length === 1) {
      const firstStepContent = sequenceSteps[0].threadA;
      return firstStepContent.content === emptyEditorHtml;
    }
    return false;
  };

  useEffect(() => {
    if (
      activeOpportunity != null &&
      activeOpportunity.contactsList != null &&
      activeOpportunity.contactsList.length
    ) {
      changeSelectedContact(activeOpportunity.contactsList[0]);
    }
  }, [activeOpportunity]);

  useEffect(() => {
    if (selectedContact && activeOpportunity.contactsList.length) {
      const selectedPerson = activeOpportunity.contactsList.find(
        (contact) => contact.contactId === selectedContact.contactId
      );

      if (activePerson == null || activePerson.id !== selectedPerson.personId) {
        changeActivePersonId(selectedPerson.personId);
      }
    }
  }, [selectedContact]);

  if (contactsExistsByCampaign == null || !contactsExistsByCampaign.value) {
    return (
      <div className="personalize-campaign-builder-empty">
        <img alt="No contacts" src={emptyContactsIllustration} />
        <div className="personalize-campaign-builder-empty__message">
          Please add opportunities with
          <br /> contact information
        </div>
        <Button
          className="personalize-campaign-builder-empty__button"
          href={`/campaign-builder/${campaignInfo.id}/opportunities`}
        >
          Add Contacts
        </Button>
      </div>
    );
  }

  if (isEmptySteps()) {
    return (
      <div className="personalize-campaign-builder-empty">
        <img alt="No steps" src={emptyContactsIllustration} />
        <div className="personalize-campaign-builder-empty__message">
          Please create a sequence first
        </div>
        <Button
          className="personalize-campaign-builder-empty__button"
          href={`/campaign-builder/${campaignInfo.id}/sequence`}
        >
          Create Sequence
        </Button>
      </div>
    );
  }

  return (
    <div className="personalize-campaign-builder">
      <Loader isLoading={isLoadingContactsExistsByCampaign}>
        <PersonalizeOpportunities
          changeActiveId={changeActiveOpportunityId}
          activeId={activeOpportunityId}
          contactsExisting={contactsExistsByCampaign.value}
        />
        <div className="personalize-campaign-builder__content" ref={refToBlur}>
          <ErrorBoundary style={{ maxHeight: '500px' }}>
            <Loader
              withTopMargin
              isLoading={
                activeOpportunityId == null ||
                isLoadingActiveOpportunity ||
                isFetchingActiveOpportunity ||
                isRefetchingActiveOpportunity
              }
            >
              <PersonalizationSteps
                opportunity={activeOpportunity}
                selectedContact={selectedContact}
                personDetails={activePerson}
                referralContentData={referralContentData}
                onChangeSelectedContact={changeSelectedContact}
              />
            </Loader>
            <Loader
              withTopMargin
              isLoading={activeOpportunityId == null || isLoadingActiveOpportunity}
            >
              <PersonalizationSideBar opportunity={activeOpportunity} />
            </Loader>
          </ErrorBoundary>
        </div>
      </Loader>
    </div>
  );
}

export default PersonalizeCampaignSettingsPage;
