import React from 'react';

import './SidebarCRMSectionWebsite.scss';

function SidebarCRMSectionWebsite(): JSX.Element {
  return (
    <div>None refactored section</div>
  );
}

export default SidebarCRMSectionWebsite;
