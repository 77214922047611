import React from 'react';

export default ({ color = '#040404' }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.66634 12V9.53333C2.66634 9.26812 2.56098 9.01376 2.37345 8.82623C2.18591 8.63869 1.93156 8.53333 1.66634 8.53333H1.33301V7.46667H1.66634C1.79766 7.46667 1.9277 7.4408 2.04902 7.39055C2.17035 7.34029 2.28059 7.26663 2.37345 7.17377C2.46631 7.08091 2.53997 6.97068 2.59022 6.84935C2.64048 6.72802 2.66634 6.59799 2.66634 6.46667V4C2.66634 3.46957 2.87705 2.96086 3.25213 2.58579C3.6272 2.21071 4.13591 2 4.66634 2H5.33301V3.33333H4.66634C4.48953 3.33333 4.31996 3.40357 4.19494 3.5286C4.06991 3.65362 3.99967 3.82319 3.99967 4V6.73333C3.99974 7.01401 3.91124 7.28755 3.74677 7.51498C3.58229 7.74242 3.35025 7.91215 3.08367 8C3.35025 8.08785 3.58229 8.25758 3.74677 8.48502C3.91124 8.71245 3.99974 8.98599 3.99967 9.26667V12C3.99967 12.1768 4.06991 12.3464 4.19494 12.4714C4.31996 12.5964 4.48953 12.6667 4.66634 12.6667H5.33301V14H4.66634C4.13591 14 3.6272 13.7893 3.25213 13.4142C2.87705 13.0391 2.66634 12.5304 2.66634 12ZM13.333 9.53333V12C13.333 12.5304 13.1223 13.0391 12.7472 13.4142C12.3721 13.7893 11.8634 14 11.333 14H10.6663V12.6667H11.333C11.5098 12.6667 11.6794 12.5964 11.8044 12.4714C11.9294 12.3464 11.9997 12.1768 11.9997 12V9.26667C11.9996 8.98599 12.0881 8.71245 12.2526 8.48502C12.4171 8.25758 12.6491 8.08785 12.9157 8C12.6491 7.91215 12.4171 7.74242 12.2526 7.51498C12.0881 7.28755 11.9996 7.01401 11.9997 6.73333V4C11.9997 3.82319 11.9294 3.65362 11.8044 3.5286C11.6794 3.40357 11.5098 3.33333 11.333 3.33333H10.6663V2H11.333C11.8634 2 12.3721 2.21071 12.7472 2.58579C13.1223 2.96086 13.333 3.46957 13.333 4V6.46667C13.333 6.73188 13.4384 6.98624 13.6259 7.17377C13.8134 7.36131 14.0678 7.46667 14.333 7.46667H14.6663V8.53333H14.333C14.0678 8.53333 13.8134 8.63869 13.6259 8.82623C13.4384 9.01376 13.333 9.26812 13.333 9.53333Z"
      fill={color}
    />
  </svg>
);
