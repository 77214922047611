import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { OrganizationMemberRoleMapType } from '@ts/userOrganization.types';

import { OrganizationChecklistTask } from 'respona_api/generated/user-organization_pb';

import {
  completeChecklistItemApi,
  inviteMembersToOrganizationApi,
} from '@api/userOrganization.api';

import { validateEmail } from '@utils/validations';

import { getCurrentWorkspaceId } from '@redux/selectors/workspaces.selectors';
import { updateAuthStepRequest } from '@redux/thunks/userProfileRequests';
import { addNotification } from '@redux/actions/notifications.actions';
import { fetchAllWorkspaces } from '@redux/thunks/workspacesRequests';

import CreatableSelectWrapper from '@uikit/Select/CreatableSelect';
import Button from '@uikit/Button/Button';
import Radio from '@uikit/Radio/Radio';
import { Option } from '@uikit/Select/selectTypes';

import OnboardingPageHeader from '@components/Onboarding/OnboardingPageHeader/OnboardingPageHeader';

import { memberRoleOptions } from '@mappers/common.mappers';

import './OnboardingMembers.scss';

const OnboardingMembers = (): JSX.Element => {
  const dispatch = useDispatch();
  const [invitingMembers, changeInvitingMembers] = useState<Option[]>([]);
  const [role, changeRole] = useState<OrganizationMemberRoleMapType>(memberRoleOptions.MEMBER);
  const curWorkspaceId = useSelector(getCurrentWorkspaceId);

  const handleRoleChange = ({ target: { value } }) =>
    changeRole(Number(value) as OrganizationMemberRoleMapType);

  const emailValidationError = () =>
    dispatch(addNotification({ title: 'Incorrect email', type: 'error' }));

  const handleInviteMembers = () => {
    inviteMembersToOrganizationApi(
      invitingMembers.map(({ value }) => value),
      role,
      [curWorkspaceId]
    ).then(async () => {
      await completeChecklistItemApi(OrganizationChecklistTask.INVITE_TEAM_MEMBER, true);
      handleSkip();
    });
  };

  const handleSkip = () => {
    dispatch(updateAuthStepRequest(3));
  };

  useEffect(() => {
    if (!curWorkspaceId) {
      fetchAllWorkspaces(dispatch);
    }
  }, [curWorkspaceId]);

  return (
    <div className="onboarding-members">
      <OnboardingPageHeader
        icon="addUserWhite"
        title="Invite members"
        subtitle="Invite your team members to collaborate. You can add as many teammates as you'd like!"
      />
      <div className="onboarding-members__content">
        <div className="onboarding-members__field-label">Emails</div>
        <CreatableSelectWrapper
          gatherValues={(values: Option[]) => {
            changeInvitingMembers(values);
          }}
          validateNewValue={validateEmail}
          validationErrorAction={emailValidationError}
          isGhost={false}
          isMulti
          isClearable
        />
        <div className="onboarding-members__role-selection">
          <div className="onboarding-members__role-selection-label">Select a role</div>
          <Radio
            onChange={handleRoleChange}
            value={memberRoleOptions.ADMIN}
            isChecked={role === memberRoleOptions.ADMIN}
          >
            <>
              <div className="onboarding-members__role-name">Admin</div>
              <div className="onboarding-members__role-desc">
                Can access campaigns, inbox, and settings
              </div>
            </>
          </Radio>

          <Radio
            onChange={handleRoleChange}
            value={memberRoleOptions.MEMBER}
            isChecked={role === memberRoleOptions.MEMBER}
          >
            <>
              <div className="onboarding-members__role-name">Member</div>
              <div className="onboarding-members__role-desc">Can access campaigns and inbox</div>
            </>
          </Radio>
        </div>
      </div>
      <Button className="onboarding-members__invite-btn" onClick={handleInviteMembers}>
        Invite members
      </Button>
      <Button type="ghost" className="onboarding-members__skip-btn" onClick={handleSkip}>
        Skip
      </Button>
    </div>
  );
};

export default OnboardingMembers;
