import React from 'react';
import { PRIMARY_COLOR } from '@constants/colors';

export default ({ color = PRIMARY_COLOR, size = 17 }) => (
  <svg width={size} height={size} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1589 3.54451H15.2986C15.4558 3.54451 15.5833 3.71593 15.5833 3.87176V11.0904C15.5833 11.2462 15.4558 11.3333 15.2986 11.3333H10.1589C8.85417 11.3333 8.85417 9.21543 6.91617 9.21543H2.125V1.43085C3.93833 1.41172 5.51083 1.41172 6.84108 1.43085C8.83717 1.45918 8.85417 3.54451 10.1589 3.54451V3.54451Z"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.125 1.41675V15.5643"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
