import React from 'react';

import { SvgIconType } from 'src/uikit/Icon/Icon';
import {
  RenderConfigurableCampaignSidebarLink,
  RenderCampaignGroup,
  DefaultCampaignGroup,
} from './ConfigurableCampaignSidebarLinks';

import './ConfigurableCampaignSidebarLink.scss';

export type ConfigurableCampaignSidebarLinkType = {
  icon?: SvgIconType;
  title?: string;
  RenderLinkComponent?: (any) => JSX.Element;
  key: string;
  url?: string;
  renderProps: {
    [key: string]: any;
  };
};

export type ConfigurableCampaignSidebarPropsType = {
  SidebarHeader?: (props: { [key: string]: any }) => JSX.Element;
  config: any;
  renderProps?: {
    [key: string]: any;
  };
  isDisabled?: boolean;
  style?: React.CSSProperties;
};

function RenderLink({
  title,
  icon,
  RenderLinkComponent,
  renderProps,
}: ConfigurableCampaignSidebarLinkType) {
  return RenderLinkComponent ? (
    <RenderLinkComponent {...renderProps} />
  ) : (
    <RenderConfigurableCampaignSidebarLink title={title} icon={icon} />
  );
}

export function RenderDefaultCampaignGroup({ title, pages, renderProps }): JSX.Element {
  return (
    <DefaultCampaignGroup title={title}>
      {pages.map((page) => (
        <RenderLink {...page} renderProps={renderProps} />
      ))}
    </DefaultCampaignGroup>
  );
}

function ConfigurableCampaignSidebar({
  SidebarHeader,
  config,
  renderProps = {},
  isDisabled = false,
  style,
}: ConfigurableCampaignSidebarPropsType): JSX.Element {
  const Footer = renderProps.footerRender;

  return (
    <div className="configurable-campaign-sidebar">
      {SidebarHeader ? (
        <div className="configurable-campaign-sidebar__header">
          <SidebarHeader />
        </div>
      ) : null}

      <div className="configurable-campaign-sidebar__body" style={style}>
        {config.map((configItem) =>
          configItem.isGroup ? (
            <RenderCampaignGroup
              key={configItem.pageKey}
              {...configItem}
              renderProps={renderProps}
            />
          ) : (
            <RenderLink
              {...configItem}
              key={configItem.pageKey}
              RenderLinkComponent={configItem.RenderLink}
              renderProps={renderProps}
            />
          )
        )}
      </div>
      <div className="configurable-campaign-sidebar__footer">{Footer ? <Footer /> : null}</div>
    </div>
  );
}

export default ConfigurableCampaignSidebar;
