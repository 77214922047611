import React from 'react';

export default ({ color = '#3424EB' }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.99992 7.33337H8.66659V4.66671C8.66659 4.4899 8.59635 4.32033 8.47133 4.1953C8.3463 4.07028 8.17673 4.00004 7.99992 4.00004C7.82311 4.00004 7.65354 4.07028 7.52852 4.1953C7.40349 4.32033 7.33325 4.4899 7.33325 4.66671V8.00004C7.33325 8.17685 7.40349 8.34642 7.52852 8.47145C7.65354 8.59647 7.82311 8.66671 7.99992 8.66671H9.99992C10.1767 8.66671 10.3463 8.59647 10.4713 8.47145C10.5963 8.34642 10.6666 8.17685 10.6666 8.00004C10.6666 7.82323 10.5963 7.65366 10.4713 7.52864C10.3463 7.40361 10.1767 7.33337 9.99992 7.33337ZM7.99992 1.33337C6.68138 1.33337 5.39245 1.72437 4.29612 2.45691C3.19979 3.18945 2.34531 4.23064 1.84072 5.44882C1.33614 6.66699 1.20412 8.00744 1.46135 9.30064C1.71859 10.5938 2.35353 11.7817 3.28588 12.7141C4.21823 13.6464 5.40611 14.2814 6.69932 14.5386C7.99253 14.7958 9.33297 14.6638 10.5511 14.1592C11.7693 13.6547 12.8105 12.8002 13.5431 11.7038C14.2756 10.6075 14.6666 9.31858 14.6666 8.00004C14.6666 7.12456 14.4941 6.25766 14.1591 5.44882C13.8241 4.63998 13.333 3.90505 12.714 3.286C12.0949 2.66694 11.36 2.17588 10.5511 1.84084C9.74231 1.50581 8.8754 1.33337 7.99992 1.33337ZM7.99992 13.3334C6.94509 13.3334 5.91394 13.0206 5.03688 12.4345C4.15982 11.8485 3.47623 11.0156 3.07256 10.041C2.6689 9.06648 2.56328 7.99412 2.76907 6.95956C2.97485 5.92499 3.48281 4.97468 4.22869 4.2288C4.97457 3.48292 5.92487 2.97497 6.95944 2.76919C7.99401 2.5634 9.06636 2.66902 10.0409 3.07268C11.0154 3.47635 11.8484 4.15994 12.4344 5.037C13.0205 5.91406 13.3333 6.94521 13.3333 8.00004C13.3333 9.41453 12.7714 10.7711 11.7712 11.7713C10.771 12.7715 9.41441 13.3334 7.99992 13.3334Z"
      fill={color}
    />
  </svg>
);
