import PageHeader from '@components/PageHeader/PageHeader';
import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { schedulesSelector } from '@redux/selectors/schedules.selectors';
import {
  updateSchedule,
  createSchedule,
  removeSchedule,
  fetchAllSchedules,
} from '@redux/thunks/schedulesRequests';

import Button from '@uikit/Button/Button';
import Loader from '@uikit/Loader/Loader';
import loadingStatuses from '@constants/loadingStatuses';
import { DispatchType } from 'src/store';

import SchedulesTable from '../SchedulesTable/SchedulesTable';
import ScheduleEdition from '../ScheduleEdition/ScheduleEdition';
import { getDefaultSchedule } from '../ScheduleEdition/config';

import './SchedulesSettings.scss';

const SchedulesSettings = (): JSX.Element => {
  const dispatch = useDispatch<DispatchType>();
  const { search } = useLocation();
  const { createNew } = qs.parse(search);

  const [openedScheduleId, changeOpenedScheduleId] = useState(null);
  const [isScheduleEditionOpen, changeIsScheduleEditionOpen] = useState(createNew === 'true');

  const { items: schedules, loadingStatus: schedulesLs } = useSelector(schedulesSelector);

  useEffect(() => {
    if (schedulesLs === loadingStatuses.PENDING) {
      fetchAllSchedules(dispatch);
    }
  }, [schedulesLs, schedules]);

  const handleOpenScheduleEdition = (schemeId) => {
    changeOpenedScheduleId(schemeId);
    changeIsScheduleEditionOpen(true);
  };
  const handleCreateSchedule = () => {
    changeIsScheduleEditionOpen(true);
    changeOpenedScheduleId(null);
  };
  const handleCloseScheduleEdition = () => changeIsScheduleEditionOpen(false);
  const handleUpdateSchedule = (schedule) => {
    if (schedule.id === undefined) {
      createSchedule(dispatch, schedule).finally(() => changeIsScheduleEditionOpen(false));
    } else {
      updateSchedule(dispatch, schedule).finally(() => changeIsScheduleEditionOpen(false));
    }
  };
  const handleRemoveSchedule = (scheduleId) => removeSchedule(dispatch, scheduleId);

  return (
    <div className="schedules-settings">
      <PageHeader
        title="Schedules"
        renderHeaderActions={() => (
          <div className="schedules-settings__header-right">
            <Button onClick={handleCreateSchedule} leftIcon="plusIcon">
              <span className="settings-workspace__button-text">Add schedule</span>
            </Button>
          </div>
        )}
      />

      <Loader isLoading={schedulesLs !== loadingStatuses.LOADED} withTopMargin>
        <SchedulesTable
          schedules={schedules}
          onEdit={handleOpenScheduleEdition}
          onRemove={handleRemoveSchedule}
        />
      </Loader>
      <ScheduleEdition
        isOpen={isScheduleEditionOpen}
        onClose={handleCloseScheduleEdition}
        onSave={handleUpdateSchedule}
        schedule={
          openedScheduleId !== null
            ? schedules.find(({ id }) => id === openedScheduleId)
            : getDefaultSchedule()
        }
      />
    </div>
  );
};

export default SchedulesSettings;
