import React from 'react';
import { useDispatch } from 'react-redux';

import { ThreadPreviewResponseType, QueuePreviewResponseType } from '@ts/mailboxInbox.types';
import { PersonBaseType } from '@ts/people.types';

import { createPersonApi } from '@api/people.api';

import { addNotification } from '@redux/actions/notifications.actions';

import PersonSidebar from '@components/PersonSidebar/PersonSidebar';

import './InboxCreatePersonSidebar.scss';
import { assignMailsByEmailToPersonApi } from '@api/mailbox.api';

function InboxCreatePersonSidebar({
  activeThread,
  onUpdateContactInThreads,
  contactDetails,
}: {
  activeThread: ThreadPreviewResponseType & QueuePreviewResponseType;
  onUpdateContactInThreads: (newPerson: PersonBaseType) => void;
  contactDetails?: {
    name?: string;
    email?: string;
  };
}): JSX.Element {
  const dispatch = useDispatch();

  const handleCreatePerson = async (newPerson: any) => {
    const createdPerson = await createPersonApi(newPerson);
    return assignMailsByEmailToPersonApi(
      createdPerson.id,
      createdPerson?.website?.id || -1,
      createdPerson.emailsList.map((it) => it.email)[0]
    ).then(() => {
      onUpdateContactInThreads(createdPerson);
      dispatch(addNotification({ type: 'success', title: 'Contact created' }));
    });
  };

  const contactName = contactDetails?.name || activeThread.fromName;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <div className="inbox-create-person-sidebar__header">Add contact</div>

      <PersonSidebar
        embedded
        isOpen
        onCreate={handleCreatePerson}
        title="Add contact"
        enableMultiEmail={false}
        isEdit
        editInfo={{
          name: contactName,
          emailsList: [
            {
              email: contactDetails.email || activeThread.fromEmail || '',
            },
          ],
          websiteDomain: (contactDetails.email || activeThread.fromEmail || '').split('@')?.[1],
          websiteName: '',
        }}
      />
    </div>
  );
}

export default InboxCreatePersonSidebar;
