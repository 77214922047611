import React, { useContext } from 'react';

import './ContentAutomationFooter.scss';
import Button from '@uikit/Button/Button';
import Spinner from '@uikit/Spinner/Spinner';
import { SVGIcon } from '@uikit/Icon/Icon';
import { ContentAutomationSearchContext } from '@pages/OpportunitiesCampaignSettingsPage/OpportunitiesCampaignSettingsPage';
import SlideToggler from '@uikit/SlideToggler/SlideToggler';

type FootersProps = {
  isUpdate: boolean;
  handleStartAutomation: () => void;
  activeStepIndex: number;
  changeActiveStepIndex: React.Dispatch<React.SetStateAction<1 | 2 | 3>>;
  canStartAutomation?: boolean;
  isAutomationStarting: boolean;
};

const ContentAutomationFooter: React.FC<FootersProps> = ({
  isUpdate,
  handleStartAutomation,
  activeStepIndex,
  changeActiveStepIndex,
  canStartAutomation,
  isAutomationStarting,
}) => {
  const { settings, setSettings } = useContext(ContentAutomationSearchContext);

  const handleSettingsState = (value: string | number | boolean, field: string): void => {
    setSettings((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleChangeNextStepIndex = () =>
    // @ts-ignore
    changeActiveStepIndex((prevState) => {
      return prevState < 4 ? prevState + 1 : prevState;
    });

  const handleChangeStepIndexBack = () =>
    // @ts-ignore
    changeActiveStepIndex((prevState) => {
      return prevState > 1 ? prevState - 1 : prevState;
    });

  switch (activeStepIndex) {
    case 1:
    case 2:
      return (
        <div className="footers">
          <div className="footers__second-step-btns">
            {activeStepIndex !== 1 && (
              <Button type="additional" size="m" onClick={handleChangeStepIndexBack}>
                Back
              </Button>
            )}
            <Button
              size="m"
              onClick={handleChangeNextStepIndex}
              className="footers__continue-btn"
              disabled={!canStartAutomation}
            >
              Continue
            </Button>
          </div>
        </div>
      );
    case 3:
      return (
        <div className="footers">
          <div className="footers__second-step-btns">
            <Button type="additional" size="m" onClick={handleChangeStepIndexBack}>
              Back
            </Button>
            {isAutomationStarting ? (
              <Button disabled className="footers__second-step-btns__button">
                <Spinner marginRight={10} color="#fff" />
                <span>{isUpdate ? 'Saving' : 'Launching'}</span>
              </Button>
            ) : (
              <>
                <div className="footers__second-step-btns__notify">
                  <SlideToggler
                    value={settings.notify}
                    onChange={(value) => handleSettingsState(value, 'notify')}
                  />
                  <div className="footers__second-step-btns__notify-title">
                    Email me when the search is complete
                  </div>
                </div>
                <Button size="m" onClick={handleStartAutomation} disabled={!canStartAutomation}>
                  <SVGIcon icon="playRounded" />
                  {isUpdate ? 'Save automation' : 'Find opportunities'}
                </Button>
              </>
            )}
          </div>
        </div>
      );
    default:
      return null;
  }
};

export default ContentAutomationFooter;
