import React from 'react';

const WarningTriangleIcon = ({
  color = '#FC8619',
  size = 12,
}: {
  color?: string;
  size?: number;
}): JSX.Element => (
  <svg width={size} height={size} viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.22167 6.42961L5.12298 0.571696C4.84438 0.0468428 4.19327 -0.152803 3.66842 0.125797C3.47851 0.226663 3.32339 0.381789 3.22252 0.571696L0.123831 6.42961C-0.151986 6.95272 0.0483554 7.6007 0.571818 7.87652C0.726596 7.95825 0.89946 8.00069 1.07441 7.99999H7.27109C7.86238 8.00173 8.34341 7.52383 8.34515 6.9322C8.34584 6.7569 8.30341 6.58438 8.22167 6.42961ZM4.17275 6.95655C3.88476 6.95655 3.65103 6.72281 3.65103 6.43482C3.65103 6.14683 3.88476 5.9131 4.17275 5.9131C4.46074 5.9131 4.69447 6.14683 4.69447 6.43482C4.69447 6.72281 4.46074 6.95655 4.17275 6.95655ZM4.35709 5.21747H3.98806C3.89624 5.21747 3.82007 5.14617 3.8145 5.05434L3.66216 2.61963C3.6559 2.51946 3.73555 2.43494 3.83572 2.43494H4.50909C4.60926 2.43494 4.68891 2.51946 4.68265 2.61963L4.53031 5.05434C4.52474 5.14617 4.44892 5.21747 4.35709 5.21747Z"
      fill="#FC8619"
    />
  </svg>
);

export default WarningTriangleIcon;
