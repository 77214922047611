import React from 'react';
import cn from 'class-names';

import { SVGIcon } from '@uikit/Icon/Icon';

import './CounterSigns.scss';

const CounterSigns = ({
  limit,
  minValue = -1,
  value,
}: {
  limit: number;
  minValue?: number;
  value: number;
}): JSX.Element => {
  const isReachedLimit = value === limit || value < minValue;

  return (
    <div
      className={cn('counter-signs', {
        'counter-signs--red': isReachedLimit,
      })}
    >
      <SVGIcon icon="keyboard" />
      <span>
        {value}/{limit}
      </span>
    </div>
  );
};

export default CounterSigns;
