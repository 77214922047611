import React from 'react';
import { SVGIcon, SvgIconType } from 'src/uikit/Icon/Icon';

import './OnboardingStepIcon.scss';

export default ({ icon }: { icon: string }): JSX.Element => (
  <div className="onboarding-step-icon">
    <SVGIcon icon={icon as SvgIconType} color="#251FB6" size={24} />
  </div>
);
