import React, { useState } from 'react';

import cn from 'class-names';

import { HaroOpportunityType } from '@ts/haro.types';

import HaroCategories from '@components/Inquiries/HaroCategories/HaroCategories';
import Display from '@components/Display/Display';
import InquiryDetails from '@components/Inquiries/InquiryDetails/InquiryDetails';
import useHexColorFromCategories from '@hooks/useHexColorsFromCategories';

import ShowMoreButton from '@components/Inquiries/ShowMoreButton/ShowMoreButton';

import { fromNumberAhrefFormatHelper } from '@helpers/transformDataHelpers';

import './InquiriesPreviewTable.scss';

const InquiriesPreviewTable = ({ inquiries }: { inquiries: HaroOpportunityType[] }) => {
  const categoriesColorsHash = useHexColorFromCategories();

  const [expandedInquiry, changeExpandedInquiry] = useState(null);

  const handleExtendInquiry = (event, inquiryId: string) => {
    event.stopPropagation();
    changeExpandedInquiry(inquiryId);
  };

  const handleHideInquiryDetails = () => changeExpandedInquiry(null);

  return (
    <table className="inquiries-preview-table">
      <thead>
        <tr>
          <th className="inquiries-preview-table__th">Summary</th>
          <th className="inquiries-preview-table__th">Publication</th>
          <th className="inquiries-preview-table__th">DA</th>
          <th className="inquiries-preview-table__th">Spam score</th>
          <th className="inquiries-preview-table__th">Category</th>
        </tr>
      </thead>
      <tbody>
        {inquiries?.map((inquiry) => {
          const cnTr = cn('inquiries-preview-table__tr', {
            'inquiries-preview-table__tr--opened': inquiry.id === expandedInquiry,
          });
          return (
            <React.Fragment key={inquiry.id}>
              <tr className={cnTr}>
                <td className="inquiries-preview-table__data-cell">
                  <p
                    className="inquiries-preview-table__data-cell--summary"
                    title={inquiry.summary}
                  >
                    {inquiry.summary}
                  </p>
                </td>
                <td className="inquiries-preview-table__data-cell">{inquiry.publication || '-'}</td>
                <td className="inquiries-preview-table__data-cell">{inquiry.domainRating}</td>
                <td className="inquiries-preview-table__data-cell">
                  {inquiry.domainSpamScore ? fromNumberAhrefFormatHelper(inquiry.domainSpamScore) : '-'}
                </td>
                <td className="inquiries-preview-table__data-cell">
                  <HaroCategories
                    categories={inquiry.categoriesList}
                    categoriesColorsHash={categoriesColorsHash}
                  />
                </td>
                <td className="inquiries-preview-table__extend-cell">
                  <ShowMoreButton
                    isVisible={expandedInquiry !== inquiry.id}
                    onClick={(event) => handleExtendInquiry(event, inquiry.id)}
                  />
                </td>
              </tr>
              <Display isVisible={expandedInquiry === inquiry.id}>
                <tr>
                  <td colSpan={8}>
                    <div className="inquiries-preview-table__extended-info">
                      <InquiryDetails
                        inquiry={inquiry}
                        onHide={handleHideInquiryDetails}
                        withActions={false}
                      />
                    </div>
                  </td>
                </tr>
              </Display>
            </React.Fragment>
          );
        })}
      </tbody>
    </table>
  );
};

export default InquiriesPreviewTable;
