import React from 'react';
import { useInfiniteQuery } from 'react-query';

import Loader from '@uikit/Loader/Loader';
import { getOutboxEmailsOfCampaignPageableApi } from '@api/mailbox.api';

import OutboxContent from '@components/CampaignAnalytics/Outbox/_components/OutboxContent/OutboxContent';
import { QueuePreviewResponseType } from '@ts/mailboxInbox.types';

type InboxPropsTypes = {
  campaignId: number;
  workspaceId: number;
  RenderHeader?: (any) => JSX.Element;
};

const Outbox = ({ campaignId, workspaceId, RenderHeader }: InboxPropsTypes): JSX.Element => {
  const {
    data: rawData,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ['outbox-emails', campaignId, workspaceId],
    ({ pageParam = 0 }) =>
      getOutboxEmailsOfCampaignPageableApi(
        campaignId,
        pageParam === 0 ? 0 : pageParam + 1,
        pageParam === 0 ? 20 : 10,
        workspaceId
      ),
    {
      getNextPageParam: (lastPage: QueuePreviewResponseType[], allPages) => {
        if (lastPage.length < 10) return false;

        return allPages.length;
      },
      enabled: !!campaignId && !!workspaceId,
      refetchOnWindowFocus: false,
    }
  );

  const outboxEmails: QueuePreviewResponseType[] =
    rawData?.pages
      // @ts-ignore
      ?.flat()
      .slice()
      .sort((a, b) => a.nextActivityTime - b.nextActivityTime) || [];

  return (
    <>
      <RenderHeader />
      <Loader isLoading={isLoading} withTopMargin>
        <OutboxContent
          threads={outboxEmails}
          workspaceId={workspaceId}
          campaignId={campaignId}
          isFetchingNextPage={isFetchingNextPage}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
        />
      </Loader>
    </>
  );
};

export default Outbox;
