import React from 'react';

export default ({ color = '#040404' }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="14" height="1" rx="0.5" fill={color} />
    <rect x="3" y="4" width="10" height="1" rx="0.5" fill={color} />
    <rect x="3" y="10" width="10" height="1" rx="0.5" fill={color} />
    <rect x="1" y="7" width="14" height="1" rx="0.5" fill={color} />
    <rect x="1" y="13" width="14" height="1" rx="0.5" fill={color} />
  </svg>
);
