import React from 'react';

export default ({ color = '#C4C6CD' }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 10.0391C3 13.5196 5.52778 16.4134 8.83333 17L8.87241 16.9686C8.8594 16.966 8.84639 16.9635 8.83337 16.9608V11.9944H7.08337V10.0391H8.83337V8.47481C8.83337 6.71503 9.96115 5.73738 11.5556 5.73738C12.0612 5.73738 12.6056 5.81559 13.1112 5.8938V7.69268H12.2167C11.3612 7.69268 11.1667 8.12285 11.1667 8.67034V10.0391H13.0334L12.7223 11.9944H11.1667V16.9608C11.1537 16.9635 11.1406 16.966 11.1276 16.9686L11.1667 17C14.4722 16.4134 17 13.5196 17 10.0391C17 6.1676 13.85 3 10 3C6.15 3 3 6.1676 3 10.0391Z"
      fill={color}
    />
  </svg>
);
