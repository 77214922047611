import React, { useState } from 'react';
import cn from 'class-names';

import { nanoid } from 'nanoid';

import { VariableType } from '@constants/variables';

import useAllVariablesInEditor from '@hooks/useAllVariablesInEditor';

import { variablesCategories } from '@uikit/RichEditor/_components/VariablesList/variablesListConfig';
import Input from '@uikit/Input/Input';

import './VariablesList.scss';
import {
  CustomVariableType,
  CustomVariableValueRelatedToMap,
} from 'respona_api/generated/common_pb';
import { SVGIcon } from '@uikit/Icon/Icon';

function VariablesList({
  onSelectVar,
  onCreateNew,
  variables,
}: {
  variables?: VariableType[];
  onSelectVar: (VariableType) => void;
  onCreateNew?: () => void;
}): JSX.Element {
  const [search, setSearch] = useState<string>('');

  const allVariables: VariableType[] =
    (variables || useAllVariablesInEditor()?.variables)
      ?.slice()
      ?.filter((item) => item.title.toUpperCase().includes(search.toUpperCase())) || [];

  const getVariablesByCategory = (
    relatedTo: CustomVariableValueRelatedToMap[keyof CustomVariableValueRelatedToMap]
  ): VariableType[] => allVariables.filter((item) => item.relatedTo === relatedTo);

  function renderIcon(variable: VariableType) {
    if (variable.type === CustomVariableType.OPEN_AI) {
      return <SVGIcon icon="ai" size={12} color="#FFFFFF" />;
    }
    return null;
  }

  const renderVariableList = (list: VariableType[]): JSX.Element[] =>
    list.map((variable) => (
      <div
        className="editor-variables-list__item"
        key={nanoid()}
        onClick={() => onSelectVar(variable)}
        tabIndex={0}
        role="button"
      >
        {variable.title} {renderIcon(variable)}
      </div>
    ));

  const editorClasses = cn('editor-variables-list', {
    'editor-variables-list--with-sticky-button': onCreateNew,
  });

  return (
    <div className={editorClasses}>
      <div className="editor-variables-list--inner">
        <Input
          className="editor-variables-list__search-input"
          icon="search"
          placeholder="Search"
          value={search}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
        />

        {allVariables?.length ? (
          Object.keys(variablesCategories).map((key: string) => {
            const variablesByCategory = getVariablesByCategory(variablesCategories[key]);

            if (!variablesByCategory.length) {
              return null;
            }

            return (
              <div className="editor-variables-list__category" key={key}>
                <div className="editor-variables-list__category-title">{key}:</div>
                {renderVariableList(variablesByCategory)}
              </div>
            );
          })
        ) : (
          <div className="editor-variables-list__category">
            <div className="editor-variables-list__category-title">No matches found</div>
          </div>
        )}
      </div>

      {onCreateNew ? (
        <div onClick={onCreateNew} className="editor-variables-list_button">
          + Create custom field
        </div>
      ) : null}
    </div>
  );
}

export default VariablesList;
