import { handleActions } from 'redux-actions';

import {
  setInboxFilters,
  setSelectedInboxStatus,
  setSelectedMailboxesIds,
} from '@redux/actions/inbox.actions';
import { GlobalInboxFilterRequestType } from '@ts/inbox.types';
import { ThreadStatus, ThreadStatusMap } from 'respona_api/generated/mailbox_pb';

export type InboxStateType = {
  selectedStatus: ThreadStatusMap[keyof ThreadStatusMap];
  selectedMailboxesIds: number[];
  filters: GlobalInboxFilterRequestType;
};

export const initialInboxFiltersState: GlobalInboxFilterRequestType = {
  fromEmail: '',
  toEmail: '',
  subjectQuery: '',
  contentQuery: '',
  campaignId: 0,
  onlyRelatedToCampaign: false,
  tagIdsList: [],
};

const initialState: InboxStateType = {
  selectedStatus: ThreadStatus.OPEN,
  selectedMailboxesIds: [],
  filters: initialInboxFiltersState,
};

export default handleActions(
  {
    [setSelectedMailboxesIds]: (state: InboxStateType, { payload }) => ({
      ...state,
      selectedMailboxesIds: payload,
    }),
    [setSelectedInboxStatus]: (state: InboxStateType, { payload }) => ({
      ...state,
      selectedStatus: payload,
    }),
    [setInboxFilters]: (state: InboxStateType, { payload }) => ({
      ...state,
      filters: payload,
    }),
  },
  initialState
);
