import { useQuery, useQueryClient } from 'react-query';
import { QueryCollectionHookType } from '@ts/common.types';
import { getCampaignPipelinesApi } from '@api/pipeline.api';
import { CampaignPipelineResponseType } from '@ts/pipelilne.types';

export default (
  workspaceId: number,
  campaignId: number
): QueryCollectionHookType<CampaignPipelineResponseType> => {
  const queryClient = useQueryClient();

  const key = ['campaign-pipelines', campaignId];

  const { data, isFetching, isLoading, isRefetching, refetch, isSuccess } = useQuery<
    CampaignPipelineResponseType[]
  >(key, () => getCampaignPipelinesApi(campaignId, workspaceId), {
    enabled: !!workspaceId && !!campaignId,
    refetchOnWindowFocus: false,
    retry: 2,
    staleTime: 15 * 60 * 1000,
  });

  const updateCache = (
    updater: (cachedData: CampaignPipelineResponseType[]) => CampaignPipelineResponseType[]
  ) => {
    queryClient.setQueryData<CampaignPipelineResponseType[]>(key, updater);
  };

  const removeFromCacheById = (id: number) => {
    updateCache((cachedData = []) => cachedData.filter((item) => item.id !== id));
  };

  const addToCache = (item: CampaignPipelineResponseType) => {
    updateCache((cachedData = []) => [item, ...cachedData]);
  };

  const updateInCache = (id: number, payload: Partial<CampaignPipelineResponseType>) => {
    updateCache((cachedData = []) =>
      cachedData.map((item) => (item.id === id ? { ...item, ...payload } : item))
    );
  };

  const evictCache = () => queryClient.removeQueries(key);

  const isDataLoading = isFetching || isLoading || isRefetching;

  return {
    items: data,
    refetch,
    isLoading: isDataLoading,
    isEmpty: isSuccess && data?.length === 0,
    removeItem: removeFromCacheById,
    addItem: addToCache,
    updateItem: updateInCache,
    evictByKey: evictCache,
  };
};
