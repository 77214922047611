import React from 'react';

import {
  IntegrationStatusMap,
  IntegrationStatus as INTEGRATION_STATUS,
} from 'respona_api/generated/common_pb';

import { SVGIcon, SvgIconType } from '@uikit/Icon/Icon';

import './IntegrationStatus.scss';

export const integrationStatusInfoMap = {
  [INTEGRATION_STATUS.NOT_CONNECTED_INTEGRATION]: {
    icon: 'linkBroken',
    title: 'Not connected',
    color: '#50ABF1',
  },
  [INTEGRATION_STATUS.ACTIVE_INTEGRATION]: {
    icon: 'play',
    title: 'Active',
    color: '#23E771',
  },
  [INTEGRATION_STATUS.AUTH_FAILED_INTEGRATION]: {
    icon: 'warningTriangle',
    title: 'Authentication failed',
    color: '#ED483D',
  },
  [INTEGRATION_STATUS.PAUSED_INTEGRATION]: {
    icon: 'pauseTall',
    title: 'Paused',
    color: '#FC8E0E',
  },
};

const IntegrationStatus = ({
  status,
}: {
  status: IntegrationStatusMap[keyof IntegrationStatusMap];
}): JSX.Element => {
  const { title, icon, color } = integrationStatusInfoMap[status];

  return (
    <div className="integration-status">
      <div className="integration-status__icon-wrapper">
        <SVGIcon size={14} icon={icon as SvgIconType} color={color} />
      </div>

      <span className="integration-status__text" style={{ color }}>
        {title}
      </span>
    </div>
  );
};

export default IntegrationStatus;
