import React, { ErrorInfo, ReactNode } from 'react';
import * as Sentry from '@sentry/react';

import { Button } from '@uikit/Button/Button';
import { SVGIcon } from '@uikit/Icon/Icon';

import './ErrorBoundary.scss';

type PropsType = {
  children: ReactNode;
  style?: React.CSSProperties;
};
type StateType = {
  hasError: boolean;
};

class ErrorBoundary extends React.Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error): { hasError: boolean } {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    Sentry.captureException(error, {
      tags: {
        componentStack: errorInfo?.componentStack,
      },
    });
  }

  tryAgain = () => {
    this.setState({ hasError: false });
  };

  render(): JSX.Element | ReactNode {
    const { hasError } = this.state;
    const { children, style } = this.props;

    if (hasError) {
      return <ErrorBoundaryComponent style={style} onRetry={this.tryAgain} />;
    }

    return children;
  }
}

export default ErrorBoundary;

export const ErrorBoundaryComponent = ({
  onRetry,
  style,
}: {
  onRetry?: () => void;
  style?: React.CSSProperties;
}) => (
  <div className="error-boundary" style={style}>
    <div className="error-boundary__icon-container">
      <SVGIcon icon="warningTriangle" color="#ED483D" size={24} />
    </div>

    <div className="error-boundary__title">We are working on an improvement at the moment</div>
    <div className="error-boundary__subtitle">Please, feel free to use the rest of the app</div>

    {!!onRetry ? (
      <Button className="error-boundary__button" onClick={onRetry}>
        Retry
      </Button>
    ) : null}
  </div>
);
