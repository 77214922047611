// package: analytics
// file: analytics.proto

var analytics_pb = require("./analytics_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var AnalyticsApiService = (function () {
  function AnalyticsApiService() {}
  AnalyticsApiService.serviceName = "analytics.AnalyticsApiService";
  return AnalyticsApiService;
}());

AnalyticsApiService.getInsightsByCriteria = {
  methodName: "getInsightsByCriteria",
  service: AnalyticsApiService,
  requestStream: false,
  responseStream: false,
  requestType: analytics_pb.CampaignInsightsRequest,
  responseType: analytics_pb.InsightsResponse
};

AnalyticsApiService.getCampaignEmailDailyInsights = {
  methodName: "getCampaignEmailDailyInsights",
  service: AnalyticsApiService,
  requestStream: false,
  responseStream: false,
  requestType: analytics_pb.InsightsRequest,
  responseType: analytics_pb.CampaignEmailDailyInsightsResponse
};

AnalyticsApiService.getCampaignEmailHourlyInsights = {
  methodName: "getCampaignEmailHourlyInsights",
  service: AnalyticsApiService,
  requestStream: false,
  responseStream: false,
  requestType: analytics_pb.InsightsRequest,
  responseType: analytics_pb.CampaignEmailHourlyInsightsResponse
};

AnalyticsApiService.getCampaignPersonDailyInsights = {
  methodName: "getCampaignPersonDailyInsights",
  service: AnalyticsApiService,
  requestStream: false,
  responseStream: false,
  requestType: analytics_pb.InsightsRequest,
  responseType: analytics_pb.CampaignEmailDailyInsightsResponse
};

AnalyticsApiService.getCampaignWebsiteDailyInsights = {
  methodName: "getCampaignWebsiteDailyInsights",
  service: AnalyticsApiService,
  requestStream: false,
  responseStream: false,
  requestType: analytics_pb.InsightsRequest,
  responseType: analytics_pb.CampaignEmailDailyInsightsResponse
};

AnalyticsApiService.getTeamMembersInsights = {
  methodName: "getTeamMembersInsights",
  service: AnalyticsApiService,
  requestStream: false,
  responseStream: false,
  requestType: analytics_pb.InsightsRequest,
  responseType: analytics_pb.TeamMembersInsightsResponse
};

AnalyticsApiService.getCreditUsageDailyInsights = {
  methodName: "getCreditUsageDailyInsights",
  service: AnalyticsApiService,
  requestStream: false,
  responseStream: false,
  requestType: analytics_pb.InsightsRequest,
  responseType: analytics_pb.DailyCreditUsageResponse
};

AnalyticsApiService.getCreditUsagePageable = {
  methodName: "getCreditUsagePageable",
  service: AnalyticsApiService,
  requestStream: false,
  responseStream: true,
  requestType: analytics_pb.InsightsPageableRequest,
  responseType: analytics_pb.CreditUsageItemResponse
};

AnalyticsApiService.getCampaignEmailChartSettings = {
  methodName: "getCampaignEmailChartSettings",
  service: AnalyticsApiService,
  requestStream: false,
  responseStream: true,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: analytics_pb.CampaignEmailChartSettings
};

AnalyticsApiService.updateCampaignEmailChartSettings = {
  methodName: "updateCampaignEmailChartSettings",
  service: AnalyticsApiService,
  requestStream: false,
  responseStream: false,
  requestType: analytics_pb.CampaignEmailChartSettings,
  responseType: analytics_pb.CampaignEmailChartSettings
};

AnalyticsApiService.updateUserOnboardingStep = {
  methodName: "updateUserOnboardingStep",
  service: AnalyticsApiService,
  requestStream: false,
  responseStream: false,
  requestType: analytics_pb.OnboardingStepRequest,
  responseType: google_protobuf_empty_pb.Empty
};

exports.AnalyticsApiService = AnalyticsApiService;

function AnalyticsApiServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

AnalyticsApiServiceClient.prototype.getInsightsByCriteria = function getInsightsByCriteria(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AnalyticsApiService.getInsightsByCriteria, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AnalyticsApiServiceClient.prototype.getCampaignEmailDailyInsights = function getCampaignEmailDailyInsights(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AnalyticsApiService.getCampaignEmailDailyInsights, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AnalyticsApiServiceClient.prototype.getCampaignEmailHourlyInsights = function getCampaignEmailHourlyInsights(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AnalyticsApiService.getCampaignEmailHourlyInsights, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AnalyticsApiServiceClient.prototype.getCampaignPersonDailyInsights = function getCampaignPersonDailyInsights(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AnalyticsApiService.getCampaignPersonDailyInsights, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AnalyticsApiServiceClient.prototype.getCampaignWebsiteDailyInsights = function getCampaignWebsiteDailyInsights(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AnalyticsApiService.getCampaignWebsiteDailyInsights, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AnalyticsApiServiceClient.prototype.getTeamMembersInsights = function getTeamMembersInsights(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AnalyticsApiService.getTeamMembersInsights, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AnalyticsApiServiceClient.prototype.getCreditUsageDailyInsights = function getCreditUsageDailyInsights(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AnalyticsApiService.getCreditUsageDailyInsights, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AnalyticsApiServiceClient.prototype.getCreditUsagePageable = function getCreditUsagePageable(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(AnalyticsApiService.getCreditUsagePageable, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

AnalyticsApiServiceClient.prototype.getCampaignEmailChartSettings = function getCampaignEmailChartSettings(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(AnalyticsApiService.getCampaignEmailChartSettings, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

AnalyticsApiServiceClient.prototype.updateCampaignEmailChartSettings = function updateCampaignEmailChartSettings(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AnalyticsApiService.updateCampaignEmailChartSettings, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AnalyticsApiServiceClient.prototype.updateUserOnboardingStep = function updateUserOnboardingStep(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AnalyticsApiService.updateUserOnboardingStep, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.AnalyticsApiServiceClient = AnalyticsApiServiceClient;

