import automationContactsService from '@api/services/automationContactsService';

import { ContactAutomationPresetType } from '@ts/automationContacts.types';

import streamRequest from '@api/base/streamRequest';
import {
  ContactSearchSource as CONTACT_SEARCH_SOURCE,
  ContactSearchSourceMap,
} from '../../respona_api/generated/common_pb';

import AutomationContactsModels, {
  LaunchContactAutomationRequest,
} from '../../respona_api/generated/automation-contact_pb';

const createDomainSearchKeywordsList = (keywordsList: { value: string; label: string }[]) =>
  keywordsList.map(({ value }, index) => {
    const automationKeywords = new AutomationContactsModels.DomainSearchKeywords();

    automationKeywords.setOrder(index + 1);
    automationKeywords.setKeyword(value);

    return automationKeywords;
  });

export const getCampaignAutomationPresetApi = (
  workspaceId: number,
  pipelineId: number
): Promise<ContactAutomationPresetType[]> => {
  const client = automationContactsService.getInstance();
  const campaignAutomationProgressRequest = new AutomationContactsModels.ContactAutomationRequest();

  campaignAutomationProgressRequest.setWorkspaceId(workspaceId);
  campaignAutomationProgressRequest.setPipelineId(pipelineId);

  return streamRequest<ContactAutomationPresetType[]>(
    client,
    client.getCampaignAutomationPreset,
    campaignAutomationProgressRequest
  );
};

export const buildLaunchContactAutomationApiRequest = (
  campaignId: number,
  workspaceId: number,
  batch: number,
  automationSettings,
  autobuy = false,
  searchSource: ContactSearchSourceMap[keyof ContactSearchSourceMap] = CONTACT_SEARCH_SOURCE.RESPONA_SEARCH_SOURCE
): LaunchContactAutomationRequest => {
  const startAutomationRequest = new AutomationContactsModels.LaunchContactAutomationRequest();
  startAutomationRequest.setSource(searchSource);
  startAutomationRequest.setCampaignId(campaignId);
  startAutomationRequest.setWorkspaceId(workspaceId);
  startAutomationRequest.setSearchMode(automationSettings.searchMode);
  startAutomationRequest.setAssignNumber(Math.max(automationSettings.assignNumber, 1));
  if (automationSettings.searchMode < 3) {
    startAutomationRequest.setRequiredPositions(automationSettings.requiredPositions);
    startAutomationRequest.setPositionsList(
      createDomainSearchKeywordsList(automationSettings.positionsList)
    );
    startAutomationRequest.setSenioritiesList(
      createDomainSearchKeywordsList(automationSettings.senioritiesList)
    );
  }
  startAutomationRequest.setSkipCatchAllEmails(automationSettings.skipCatchAllEmails);
  startAutomationRequest.setAssignGenericEmails(automationSettings.assignGenericEmails);
  startAutomationRequest.setAutoBuy(autobuy);

  startAutomationRequest.setBatch(batch);

  return startAutomationRequest;
};
