// source: analytics.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
goog.exportSymbol('proto.analytics.CampaignEmailChartSettings', null, global);
goog.exportSymbol('proto.analytics.CampaignEmailDailyInsightsResponse', null, global);
goog.exportSymbol('proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics', null, global);
goog.exportSymbol('proto.analytics.CampaignEmailHourlyInsightsResponse', null, global);
goog.exportSymbol('proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics', null, global);
goog.exportSymbol('proto.analytics.CampaignInsightsRequest', null, global);
goog.exportSymbol('proto.analytics.ChartSettingsType', null, global);
goog.exportSymbol('proto.analytics.CreditUsageCategory', null, global);
goog.exportSymbol('proto.analytics.CreditUsageCategoryItem', null, global);
goog.exportSymbol('proto.analytics.CreditUsageItemResponse', null, global);
goog.exportSymbol('proto.analytics.CreditUsageSearchGroup', null, global);
goog.exportSymbol('proto.analytics.DailyCreditUsageResponse', null, global);
goog.exportSymbol('proto.analytics.DailyCreditUsageResponse.DailyStatistics', null, global);
goog.exportSymbol('proto.analytics.InsightsFilter', null, global);
goog.exportSymbol('proto.analytics.InsightsFilterField', null, global);
goog.exportSymbol('proto.analytics.InsightsPageableRequest', null, global);
goog.exportSymbol('proto.analytics.InsightsRequest', null, global);
goog.exportSymbol('proto.analytics.InsightsResponse', null, global);
goog.exportSymbol('proto.analytics.InsightsResponse.DailyStatistics', null, global);
goog.exportSymbol('proto.analytics.OnboardingStepRequest', null, global);
goog.exportSymbol('proto.analytics.OnboardingStepRequest.OnboardingStep', null, global);
goog.exportSymbol('proto.analytics.TeamMembersInsightsResponse', null, global);
goog.exportSymbol('proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.analytics.CreditUsageItemResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.analytics.CreditUsageItemResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.analytics.CreditUsageItemResponse.displayName = 'proto.analytics.CreditUsageItemResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.analytics.InsightsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.analytics.InsightsRequest.repeatedFields_, null);
};
goog.inherits(proto.analytics.InsightsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.analytics.InsightsRequest.displayName = 'proto.analytics.InsightsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.analytics.InsightsPageableRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.analytics.InsightsPageableRequest.repeatedFields_, null);
};
goog.inherits(proto.analytics.InsightsPageableRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.analytics.InsightsPageableRequest.displayName = 'proto.analytics.InsightsPageableRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.analytics.InsightsFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.analytics.InsightsFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.analytics.InsightsFilter.displayName = 'proto.analytics.InsightsFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.analytics.CampaignEmailDailyInsightsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.analytics.CampaignEmailDailyInsightsResponse.repeatedFields_, null);
};
goog.inherits(proto.analytics.CampaignEmailDailyInsightsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.analytics.CampaignEmailDailyInsightsResponse.displayName = 'proto.analytics.CampaignEmailDailyInsightsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics.displayName = 'proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.analytics.CampaignEmailHourlyInsightsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.analytics.CampaignEmailHourlyInsightsResponse.repeatedFields_, null);
};
goog.inherits(proto.analytics.CampaignEmailHourlyInsightsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.analytics.CampaignEmailHourlyInsightsResponse.displayName = 'proto.analytics.CampaignEmailHourlyInsightsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics.repeatedFields_, null);
};
goog.inherits(proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics.displayName = 'proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.analytics.TeamMembersInsightsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.analytics.TeamMembersInsightsResponse.repeatedFields_, null);
};
goog.inherits(proto.analytics.TeamMembersInsightsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.analytics.TeamMembersInsightsResponse.displayName = 'proto.analytics.TeamMembersInsightsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics.displayName = 'proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.analytics.CampaignEmailChartSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.analytics.CampaignEmailChartSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.analytics.CampaignEmailChartSettings.displayName = 'proto.analytics.CampaignEmailChartSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.analytics.InsightsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.analytics.InsightsResponse.repeatedFields_, null);
};
goog.inherits(proto.analytics.InsightsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.analytics.InsightsResponse.displayName = 'proto.analytics.InsightsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.analytics.InsightsResponse.DailyStatistics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.analytics.InsightsResponse.DailyStatistics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.analytics.InsightsResponse.DailyStatistics.displayName = 'proto.analytics.InsightsResponse.DailyStatistics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.analytics.OnboardingStepRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.analytics.OnboardingStepRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.analytics.OnboardingStepRequest.displayName = 'proto.analytics.OnboardingStepRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.analytics.CreditUsageCategoryItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.analytics.CreditUsageCategoryItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.analytics.CreditUsageCategoryItem.displayName = 'proto.analytics.CreditUsageCategoryItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.analytics.DailyCreditUsageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.analytics.DailyCreditUsageResponse.repeatedFields_, null);
};
goog.inherits(proto.analytics.DailyCreditUsageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.analytics.DailyCreditUsageResponse.displayName = 'proto.analytics.DailyCreditUsageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.analytics.DailyCreditUsageResponse.DailyStatistics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.analytics.DailyCreditUsageResponse.DailyStatistics.repeatedFields_, null);
};
goog.inherits(proto.analytics.DailyCreditUsageResponse.DailyStatistics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.analytics.DailyCreditUsageResponse.DailyStatistics.displayName = 'proto.analytics.DailyCreditUsageResponse.DailyStatistics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.analytics.CampaignInsightsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.analytics.CampaignInsightsRequest.repeatedFields_, null);
};
goog.inherits(proto.analytics.CampaignInsightsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.analytics.CampaignInsightsRequest.displayName = 'proto.analytics.CampaignInsightsRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.analytics.CreditUsageItemResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.analytics.CreditUsageItemResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.analytics.CreditUsageItemResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.analytics.CreditUsageItemResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    searchGroup: jspb.Message.getFieldWithDefault(msg, 2, 0),
    searchCategory: jspb.Message.getFieldWithDefault(msg, 3, 0),
    items: jspb.Message.getFieldWithDefault(msg, 4, 0),
    creditsAmount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    datetime: jspb.Message.getFieldWithDefault(msg, 6, 0),
    totalItems: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.analytics.CreditUsageItemResponse}
 */
proto.analytics.CreditUsageItemResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.analytics.CreditUsageItemResponse;
  return proto.analytics.CreditUsageItemResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.analytics.CreditUsageItemResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.analytics.CreditUsageItemResponse}
 */
proto.analytics.CreditUsageItemResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {!proto.analytics.CreditUsageSearchGroup} */ (reader.readEnum());
      msg.setSearchGroup(value);
      break;
    case 3:
      var value = /** @type {!proto.analytics.CreditUsageCategory} */ (reader.readEnum());
      msg.setSearchCategory(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setItems(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCreditsAmount(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDatetime(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.analytics.CreditUsageItemResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.analytics.CreditUsageItemResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.analytics.CreditUsageItemResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.analytics.CreditUsageItemResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSearchGroup();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getSearchCategory();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getItems();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getCreditsAmount();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getDatetime();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getTotalItems();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional int64 user_id = 1;
 * @return {number}
 */
proto.analytics.CreditUsageItemResponse.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.CreditUsageItemResponse} returns this
 */
proto.analytics.CreditUsageItemResponse.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional CreditUsageSearchGroup search_group = 2;
 * @return {!proto.analytics.CreditUsageSearchGroup}
 */
proto.analytics.CreditUsageItemResponse.prototype.getSearchGroup = function() {
  return /** @type {!proto.analytics.CreditUsageSearchGroup} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.analytics.CreditUsageSearchGroup} value
 * @return {!proto.analytics.CreditUsageItemResponse} returns this
 */
proto.analytics.CreditUsageItemResponse.prototype.setSearchGroup = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional CreditUsageCategory search_category = 3;
 * @return {!proto.analytics.CreditUsageCategory}
 */
proto.analytics.CreditUsageItemResponse.prototype.getSearchCategory = function() {
  return /** @type {!proto.analytics.CreditUsageCategory} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.analytics.CreditUsageCategory} value
 * @return {!proto.analytics.CreditUsageItemResponse} returns this
 */
proto.analytics.CreditUsageItemResponse.prototype.setSearchCategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int32 items = 4;
 * @return {number}
 */
proto.analytics.CreditUsageItemResponse.prototype.getItems = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.CreditUsageItemResponse} returns this
 */
proto.analytics.CreditUsageItemResponse.prototype.setItems = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 credits_amount = 5;
 * @return {number}
 */
proto.analytics.CreditUsageItemResponse.prototype.getCreditsAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.CreditUsageItemResponse} returns this
 */
proto.analytics.CreditUsageItemResponse.prototype.setCreditsAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 datetime = 6;
 * @return {number}
 */
proto.analytics.CreditUsageItemResponse.prototype.getDatetime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.CreditUsageItemResponse} returns this
 */
proto.analytics.CreditUsageItemResponse.prototype.setDatetime = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 total_items = 7;
 * @return {number}
 */
proto.analytics.CreditUsageItemResponse.prototype.getTotalItems = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.CreditUsageItemResponse} returns this
 */
proto.analytics.CreditUsageItemResponse.prototype.setTotalItems = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.analytics.InsightsRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.analytics.InsightsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.analytics.InsightsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.analytics.InsightsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.analytics.InsightsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dateFrom: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dateTo: jspb.Message.getFieldWithDefault(msg, 2, 0),
    timezone: jspb.Message.getFieldWithDefault(msg, 3, ""),
    filtersList: jspb.Message.toObjectList(msg.getFiltersList(),
    proto.analytics.InsightsFilter.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.analytics.InsightsRequest}
 */
proto.analytics.InsightsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.analytics.InsightsRequest;
  return proto.analytics.InsightsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.analytics.InsightsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.analytics.InsightsRequest}
 */
proto.analytics.InsightsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDateFrom(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDateTo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    case 4:
      var value = new proto.analytics.InsightsFilter;
      reader.readMessage(value,proto.analytics.InsightsFilter.deserializeBinaryFromReader);
      msg.addFilters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.analytics.InsightsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.analytics.InsightsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.analytics.InsightsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.analytics.InsightsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDateFrom();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDateTo();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.analytics.InsightsFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 date_from = 1;
 * @return {number}
 */
proto.analytics.InsightsRequest.prototype.getDateFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.InsightsRequest} returns this
 */
proto.analytics.InsightsRequest.prototype.setDateFrom = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 date_to = 2;
 * @return {number}
 */
proto.analytics.InsightsRequest.prototype.getDateTo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.InsightsRequest} returns this
 */
proto.analytics.InsightsRequest.prototype.setDateTo = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string timezone = 3;
 * @return {string}
 */
proto.analytics.InsightsRequest.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.analytics.InsightsRequest} returns this
 */
proto.analytics.InsightsRequest.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated InsightsFilter filters = 4;
 * @return {!Array<!proto.analytics.InsightsFilter>}
 */
proto.analytics.InsightsRequest.prototype.getFiltersList = function() {
  return /** @type{!Array<!proto.analytics.InsightsFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.analytics.InsightsFilter, 4));
};


/**
 * @param {!Array<!proto.analytics.InsightsFilter>} value
 * @return {!proto.analytics.InsightsRequest} returns this
*/
proto.analytics.InsightsRequest.prototype.setFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.analytics.InsightsFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.analytics.InsightsFilter}
 */
proto.analytics.InsightsRequest.prototype.addFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.analytics.InsightsFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.analytics.InsightsRequest} returns this
 */
proto.analytics.InsightsRequest.prototype.clearFiltersList = function() {
  return this.setFiltersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.analytics.InsightsPageableRequest.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.analytics.InsightsPageableRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.analytics.InsightsPageableRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.analytics.InsightsPageableRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.analytics.InsightsPageableRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    dateFrom: jspb.Message.getFieldWithDefault(msg, 3, 0),
    dateTo: jspb.Message.getFieldWithDefault(msg, 4, 0),
    timezone: jspb.Message.getFieldWithDefault(msg, 5, ""),
    filtersList: jspb.Message.toObjectList(msg.getFiltersList(),
    proto.analytics.InsightsFilter.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.analytics.InsightsPageableRequest}
 */
proto.analytics.InsightsPageableRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.analytics.InsightsPageableRequest;
  return proto.analytics.InsightsPageableRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.analytics.InsightsPageableRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.analytics.InsightsPageableRequest}
 */
proto.analytics.InsightsPageableRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDateFrom(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDateTo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    case 6:
      var value = new proto.analytics.InsightsFilter;
      reader.readMessage(value,proto.analytics.InsightsFilter.deserializeBinaryFromReader);
      msg.addFilters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.analytics.InsightsPageableRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.analytics.InsightsPageableRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.analytics.InsightsPageableRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.analytics.InsightsPageableRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDateFrom();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getDateTo();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.analytics.InsightsFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 page = 1;
 * @return {number}
 */
proto.analytics.InsightsPageableRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.InsightsPageableRequest} returns this
 */
proto.analytics.InsightsPageableRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 limit = 2;
 * @return {number}
 */
proto.analytics.InsightsPageableRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.InsightsPageableRequest} returns this
 */
proto.analytics.InsightsPageableRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 date_from = 3;
 * @return {number}
 */
proto.analytics.InsightsPageableRequest.prototype.getDateFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.InsightsPageableRequest} returns this
 */
proto.analytics.InsightsPageableRequest.prototype.setDateFrom = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 date_to = 4;
 * @return {number}
 */
proto.analytics.InsightsPageableRequest.prototype.getDateTo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.InsightsPageableRequest} returns this
 */
proto.analytics.InsightsPageableRequest.prototype.setDateTo = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string timezone = 5;
 * @return {string}
 */
proto.analytics.InsightsPageableRequest.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.analytics.InsightsPageableRequest} returns this
 */
proto.analytics.InsightsPageableRequest.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated InsightsFilter filters = 6;
 * @return {!Array<!proto.analytics.InsightsFilter>}
 */
proto.analytics.InsightsPageableRequest.prototype.getFiltersList = function() {
  return /** @type{!Array<!proto.analytics.InsightsFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.analytics.InsightsFilter, 6));
};


/**
 * @param {!Array<!proto.analytics.InsightsFilter>} value
 * @return {!proto.analytics.InsightsPageableRequest} returns this
*/
proto.analytics.InsightsPageableRequest.prototype.setFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.analytics.InsightsFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.analytics.InsightsFilter}
 */
proto.analytics.InsightsPageableRequest.prototype.addFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.analytics.InsightsFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.analytics.InsightsPageableRequest} returns this
 */
proto.analytics.InsightsPageableRequest.prototype.clearFiltersList = function() {
  return this.setFiltersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.analytics.InsightsFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.analytics.InsightsFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.analytics.InsightsFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.analytics.InsightsFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    order: jspb.Message.getFieldWithDefault(msg, 1, 0),
    field: jspb.Message.getFieldWithDefault(msg, 2, 0),
    value: jspb.Message.getFieldWithDefault(msg, 3, ""),
    operation: jspb.Message.getFieldWithDefault(msg, 4, 0),
    join: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.analytics.InsightsFilter}
 */
proto.analytics.InsightsFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.analytics.InsightsFilter;
  return proto.analytics.InsightsFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.analytics.InsightsFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.analytics.InsightsFilter}
 */
proto.analytics.InsightsFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 2:
      var value = /** @type {!proto.analytics.InsightsFilterField} */ (reader.readEnum());
      msg.setField(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 4:
      var value = /** @type {!proto.common.FilterOperation} */ (reader.readEnum());
      msg.setOperation(value);
      break;
    case 5:
      var value = /** @type {!proto.common.FilterJoinType} */ (reader.readEnum());
      msg.setJoin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.analytics.InsightsFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.analytics.InsightsFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.analytics.InsightsFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.analytics.InsightsFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getField();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOperation();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getJoin();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional int32 order = 1;
 * @return {number}
 */
proto.analytics.InsightsFilter.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.InsightsFilter} returns this
 */
proto.analytics.InsightsFilter.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional InsightsFilterField field = 2;
 * @return {!proto.analytics.InsightsFilterField}
 */
proto.analytics.InsightsFilter.prototype.getField = function() {
  return /** @type {!proto.analytics.InsightsFilterField} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.analytics.InsightsFilterField} value
 * @return {!proto.analytics.InsightsFilter} returns this
 */
proto.analytics.InsightsFilter.prototype.setField = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.analytics.InsightsFilter.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.analytics.InsightsFilter} returns this
 */
proto.analytics.InsightsFilter.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional common.FilterOperation operation = 4;
 * @return {!proto.common.FilterOperation}
 */
proto.analytics.InsightsFilter.prototype.getOperation = function() {
  return /** @type {!proto.common.FilterOperation} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.common.FilterOperation} value
 * @return {!proto.analytics.InsightsFilter} returns this
 */
proto.analytics.InsightsFilter.prototype.setOperation = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional common.FilterJoinType join = 5;
 * @return {!proto.common.FilterJoinType}
 */
proto.analytics.InsightsFilter.prototype.getJoin = function() {
  return /** @type {!proto.common.FilterJoinType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.common.FilterJoinType} value
 * @return {!proto.analytics.InsightsFilter} returns this
 */
proto.analytics.InsightsFilter.prototype.setJoin = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.analytics.CampaignEmailDailyInsightsResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.analytics.CampaignEmailDailyInsightsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.analytics.CampaignEmailDailyInsightsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.analytics.CampaignEmailDailyInsightsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.analytics.CampaignEmailDailyInsightsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isEmpty: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    dateFrom: jspb.Message.getFieldWithDefault(msg, 2, 0),
    dateTo: jspb.Message.getFieldWithDefault(msg, 3, 0),
    dailyStatisticsList: jspb.Message.toObjectList(msg.getDailyStatisticsList(),
    proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics.toObject, includeInstance),
    totalSentNumber: jspb.Message.getFieldWithDefault(msg, 5, 0),
    totalDeliveryRate: jspb.Message.getFieldWithDefault(msg, 6, 0),
    totalDeliveryNumber: jspb.Message.getFieldWithDefault(msg, 7, 0),
    totalOpenRate: jspb.Message.getFieldWithDefault(msg, 8, 0),
    totalOpenNumber: jspb.Message.getFieldWithDefault(msg, 9, 0),
    totalReplyRate: jspb.Message.getFieldWithDefault(msg, 10, 0),
    totalReplyNumber: jspb.Message.getFieldWithDefault(msg, 11, 0),
    totalBouncedRate: jspb.Message.getFieldWithDefault(msg, 12, 0),
    totalBouncedNumber: jspb.Message.getFieldWithDefault(msg, 13, 0),
    totalInterestedRate: jspb.Message.getFieldWithDefault(msg, 14, 0),
    totalInterestedNumber: jspb.Message.getFieldWithDefault(msg, 15, 0),
    totalNotInterestedRate: jspb.Message.getFieldWithDefault(msg, 16, 0),
    totalNotInterestedNumber: jspb.Message.getFieldWithDefault(msg, 17, 0),
    totalUnsubscribedRate: jspb.Message.getFieldWithDefault(msg, 18, 0),
    totalUnsubscribedNumber: jspb.Message.getFieldWithDefault(msg, 19, 0),
    totalPersonalizedRate: jspb.Message.getFieldWithDefault(msg, 20, 0),
    totalPersonalizedNumber: jspb.Message.getFieldWithDefault(msg, 21, 0),
    totalAttemptToReachNumber: jspb.Message.getFieldWithDefault(msg, 23, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.analytics.CampaignEmailDailyInsightsResponse}
 */
proto.analytics.CampaignEmailDailyInsightsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.analytics.CampaignEmailDailyInsightsResponse;
  return proto.analytics.CampaignEmailDailyInsightsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.analytics.CampaignEmailDailyInsightsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.analytics.CampaignEmailDailyInsightsResponse}
 */
proto.analytics.CampaignEmailDailyInsightsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEmpty(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDateFrom(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDateTo(value);
      break;
    case 4:
      var value = new proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics;
      reader.readMessage(value,proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics.deserializeBinaryFromReader);
      msg.addDailyStatistics(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalSentNumber(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalDeliveryRate(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalDeliveryNumber(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalOpenRate(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalOpenNumber(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalReplyRate(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalReplyNumber(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalBouncedRate(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalBouncedNumber(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalInterestedRate(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalInterestedNumber(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalNotInterestedRate(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalNotInterestedNumber(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalUnsubscribedRate(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalUnsubscribedNumber(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalPersonalizedRate(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalPersonalizedNumber(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalAttemptToReachNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.analytics.CampaignEmailDailyInsightsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.analytics.CampaignEmailDailyInsightsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.analytics.CampaignEmailDailyInsightsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.analytics.CampaignEmailDailyInsightsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsEmpty();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getDateFrom();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getDateTo();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getDailyStatisticsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics.serializeBinaryToWriter
    );
  }
  f = message.getTotalSentNumber();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getTotalDeliveryRate();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getTotalDeliveryNumber();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getTotalOpenRate();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getTotalOpenNumber();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getTotalReplyRate();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getTotalReplyNumber();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getTotalBouncedRate();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getTotalBouncedNumber();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getTotalInterestedRate();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getTotalInterestedNumber();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getTotalNotInterestedRate();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getTotalNotInterestedNumber();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getTotalUnsubscribedRate();
  if (f !== 0) {
    writer.writeInt32(
      18,
      f
    );
  }
  f = message.getTotalUnsubscribedNumber();
  if (f !== 0) {
    writer.writeInt32(
      19,
      f
    );
  }
  f = message.getTotalPersonalizedRate();
  if (f !== 0) {
    writer.writeInt32(
      20,
      f
    );
  }
  f = message.getTotalPersonalizedNumber();
  if (f !== 0) {
    writer.writeInt32(
      21,
      f
    );
  }
  f = message.getTotalAttemptToReachNumber();
  if (f !== 0) {
    writer.writeInt32(
      23,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics.prototype.toObject = function(opt_includeInstance) {
  return proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sentNumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    deliveryNumber: jspb.Message.getFieldWithDefault(msg, 3, 0),
    openNumber: jspb.Message.getFieldWithDefault(msg, 4, 0),
    replyNumber: jspb.Message.getFieldWithDefault(msg, 5, 0),
    bouncedNumber: jspb.Message.getFieldWithDefault(msg, 6, 0),
    interestedNumber: jspb.Message.getFieldWithDefault(msg, 7, 0),
    notInterestedNumber: jspb.Message.getFieldWithDefault(msg, 8, 0),
    unsubscribedNumber: jspb.Message.getFieldWithDefault(msg, 9, 0),
    personalizedNumber: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics}
 */
proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics;
  return proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics}
 */
proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSentNumber(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeliveryNumber(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOpenNumber(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReplyNumber(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBouncedNumber(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInterestedNumber(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNotInterestedNumber(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUnsubscribedNumber(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPersonalizedNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSentNumber();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDeliveryNumber();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getOpenNumber();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getReplyNumber();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getBouncedNumber();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getInterestedNumber();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getNotInterestedNumber();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getUnsubscribedNumber();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getPersonalizedNumber();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
};


/**
 * optional string date = 1;
 * @return {string}
 */
proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics} returns this
 */
proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 sent_number = 2;
 * @return {number}
 */
proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics.prototype.getSentNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics} returns this
 */
proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics.prototype.setSentNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 delivery_number = 3;
 * @return {number}
 */
proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics.prototype.getDeliveryNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics} returns this
 */
proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics.prototype.setDeliveryNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 open_number = 4;
 * @return {number}
 */
proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics.prototype.getOpenNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics} returns this
 */
proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics.prototype.setOpenNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 reply_number = 5;
 * @return {number}
 */
proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics.prototype.getReplyNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics} returns this
 */
proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics.prototype.setReplyNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 bounced_number = 6;
 * @return {number}
 */
proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics.prototype.getBouncedNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics} returns this
 */
proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics.prototype.setBouncedNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 interested_number = 7;
 * @return {number}
 */
proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics.prototype.getInterestedNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics} returns this
 */
proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics.prototype.setInterestedNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 not_interested_number = 8;
 * @return {number}
 */
proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics.prototype.getNotInterestedNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics} returns this
 */
proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics.prototype.setNotInterestedNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 unsubscribed_number = 9;
 * @return {number}
 */
proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics.prototype.getUnsubscribedNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics} returns this
 */
proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics.prototype.setUnsubscribedNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 personalized_number = 10;
 * @return {number}
 */
proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics.prototype.getPersonalizedNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics} returns this
 */
proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics.prototype.setPersonalizedNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional bool is_empty = 1;
 * @return {boolean}
 */
proto.analytics.CampaignEmailDailyInsightsResponse.prototype.getIsEmpty = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.analytics.CampaignEmailDailyInsightsResponse} returns this
 */
proto.analytics.CampaignEmailDailyInsightsResponse.prototype.setIsEmpty = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional int64 date_from = 2;
 * @return {number}
 */
proto.analytics.CampaignEmailDailyInsightsResponse.prototype.getDateFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.CampaignEmailDailyInsightsResponse} returns this
 */
proto.analytics.CampaignEmailDailyInsightsResponse.prototype.setDateFrom = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 date_to = 3;
 * @return {number}
 */
proto.analytics.CampaignEmailDailyInsightsResponse.prototype.getDateTo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.CampaignEmailDailyInsightsResponse} returns this
 */
proto.analytics.CampaignEmailDailyInsightsResponse.prototype.setDateTo = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated CampaignEmailDailyStatistics daily_statistics = 4;
 * @return {!Array<!proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics>}
 */
proto.analytics.CampaignEmailDailyInsightsResponse.prototype.getDailyStatisticsList = function() {
  return /** @type{!Array<!proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics, 4));
};


/**
 * @param {!Array<!proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics>} value
 * @return {!proto.analytics.CampaignEmailDailyInsightsResponse} returns this
*/
proto.analytics.CampaignEmailDailyInsightsResponse.prototype.setDailyStatisticsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics=} opt_value
 * @param {number=} opt_index
 * @return {!proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics}
 */
proto.analytics.CampaignEmailDailyInsightsResponse.prototype.addDailyStatistics = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.analytics.CampaignEmailDailyInsightsResponse.CampaignEmailDailyStatistics, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.analytics.CampaignEmailDailyInsightsResponse} returns this
 */
proto.analytics.CampaignEmailDailyInsightsResponse.prototype.clearDailyStatisticsList = function() {
  return this.setDailyStatisticsList([]);
};


/**
 * optional int32 total_sent_number = 5;
 * @return {number}
 */
proto.analytics.CampaignEmailDailyInsightsResponse.prototype.getTotalSentNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.CampaignEmailDailyInsightsResponse} returns this
 */
proto.analytics.CampaignEmailDailyInsightsResponse.prototype.setTotalSentNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 total_delivery_rate = 6;
 * @return {number}
 */
proto.analytics.CampaignEmailDailyInsightsResponse.prototype.getTotalDeliveryRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.CampaignEmailDailyInsightsResponse} returns this
 */
proto.analytics.CampaignEmailDailyInsightsResponse.prototype.setTotalDeliveryRate = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 total_delivery_number = 7;
 * @return {number}
 */
proto.analytics.CampaignEmailDailyInsightsResponse.prototype.getTotalDeliveryNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.CampaignEmailDailyInsightsResponse} returns this
 */
proto.analytics.CampaignEmailDailyInsightsResponse.prototype.setTotalDeliveryNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 total_open_rate = 8;
 * @return {number}
 */
proto.analytics.CampaignEmailDailyInsightsResponse.prototype.getTotalOpenRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.CampaignEmailDailyInsightsResponse} returns this
 */
proto.analytics.CampaignEmailDailyInsightsResponse.prototype.setTotalOpenRate = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 total_open_number = 9;
 * @return {number}
 */
proto.analytics.CampaignEmailDailyInsightsResponse.prototype.getTotalOpenNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.CampaignEmailDailyInsightsResponse} returns this
 */
proto.analytics.CampaignEmailDailyInsightsResponse.prototype.setTotalOpenNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 total_reply_rate = 10;
 * @return {number}
 */
proto.analytics.CampaignEmailDailyInsightsResponse.prototype.getTotalReplyRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.CampaignEmailDailyInsightsResponse} returns this
 */
proto.analytics.CampaignEmailDailyInsightsResponse.prototype.setTotalReplyRate = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 total_reply_number = 11;
 * @return {number}
 */
proto.analytics.CampaignEmailDailyInsightsResponse.prototype.getTotalReplyNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.CampaignEmailDailyInsightsResponse} returns this
 */
proto.analytics.CampaignEmailDailyInsightsResponse.prototype.setTotalReplyNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 total_bounced_rate = 12;
 * @return {number}
 */
proto.analytics.CampaignEmailDailyInsightsResponse.prototype.getTotalBouncedRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.CampaignEmailDailyInsightsResponse} returns this
 */
proto.analytics.CampaignEmailDailyInsightsResponse.prototype.setTotalBouncedRate = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int32 total_bounced_number = 13;
 * @return {number}
 */
proto.analytics.CampaignEmailDailyInsightsResponse.prototype.getTotalBouncedNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.CampaignEmailDailyInsightsResponse} returns this
 */
proto.analytics.CampaignEmailDailyInsightsResponse.prototype.setTotalBouncedNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int32 total_interested_rate = 14;
 * @return {number}
 */
proto.analytics.CampaignEmailDailyInsightsResponse.prototype.getTotalInterestedRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.CampaignEmailDailyInsightsResponse} returns this
 */
proto.analytics.CampaignEmailDailyInsightsResponse.prototype.setTotalInterestedRate = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int32 total_interested_number = 15;
 * @return {number}
 */
proto.analytics.CampaignEmailDailyInsightsResponse.prototype.getTotalInterestedNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.CampaignEmailDailyInsightsResponse} returns this
 */
proto.analytics.CampaignEmailDailyInsightsResponse.prototype.setTotalInterestedNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int32 total_not_interested_rate = 16;
 * @return {number}
 */
proto.analytics.CampaignEmailDailyInsightsResponse.prototype.getTotalNotInterestedRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.CampaignEmailDailyInsightsResponse} returns this
 */
proto.analytics.CampaignEmailDailyInsightsResponse.prototype.setTotalNotInterestedRate = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional int32 total_not_interested_number = 17;
 * @return {number}
 */
proto.analytics.CampaignEmailDailyInsightsResponse.prototype.getTotalNotInterestedNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.CampaignEmailDailyInsightsResponse} returns this
 */
proto.analytics.CampaignEmailDailyInsightsResponse.prototype.setTotalNotInterestedNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional int32 total_unsubscribed_rate = 18;
 * @return {number}
 */
proto.analytics.CampaignEmailDailyInsightsResponse.prototype.getTotalUnsubscribedRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.CampaignEmailDailyInsightsResponse} returns this
 */
proto.analytics.CampaignEmailDailyInsightsResponse.prototype.setTotalUnsubscribedRate = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional int32 total_unsubscribed_number = 19;
 * @return {number}
 */
proto.analytics.CampaignEmailDailyInsightsResponse.prototype.getTotalUnsubscribedNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.CampaignEmailDailyInsightsResponse} returns this
 */
proto.analytics.CampaignEmailDailyInsightsResponse.prototype.setTotalUnsubscribedNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional int32 total_personalized_rate = 20;
 * @return {number}
 */
proto.analytics.CampaignEmailDailyInsightsResponse.prototype.getTotalPersonalizedRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.CampaignEmailDailyInsightsResponse} returns this
 */
proto.analytics.CampaignEmailDailyInsightsResponse.prototype.setTotalPersonalizedRate = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional int32 total_personalized_number = 21;
 * @return {number}
 */
proto.analytics.CampaignEmailDailyInsightsResponse.prototype.getTotalPersonalizedNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.CampaignEmailDailyInsightsResponse} returns this
 */
proto.analytics.CampaignEmailDailyInsightsResponse.prototype.setTotalPersonalizedNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional int32 total_attempt_to_reach_number = 23;
 * @return {number}
 */
proto.analytics.CampaignEmailDailyInsightsResponse.prototype.getTotalAttemptToReachNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.CampaignEmailDailyInsightsResponse} returns this
 */
proto.analytics.CampaignEmailDailyInsightsResponse.prototype.setTotalAttemptToReachNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.analytics.CampaignEmailHourlyInsightsResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.analytics.CampaignEmailHourlyInsightsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.analytics.CampaignEmailHourlyInsightsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.analytics.CampaignEmailHourlyInsightsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.analytics.CampaignEmailHourlyInsightsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isEmpty: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    dateFrom: jspb.Message.getFieldWithDefault(msg, 2, 0),
    dateTo: jspb.Message.getFieldWithDefault(msg, 3, 0),
    statisticsList: jspb.Message.toObjectList(msg.getStatisticsList(),
    proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.analytics.CampaignEmailHourlyInsightsResponse}
 */
proto.analytics.CampaignEmailHourlyInsightsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.analytics.CampaignEmailHourlyInsightsResponse;
  return proto.analytics.CampaignEmailHourlyInsightsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.analytics.CampaignEmailHourlyInsightsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.analytics.CampaignEmailHourlyInsightsResponse}
 */
proto.analytics.CampaignEmailHourlyInsightsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEmpty(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDateFrom(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDateTo(value);
      break;
    case 4:
      var value = new proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics;
      reader.readMessage(value,proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics.deserializeBinaryFromReader);
      msg.addStatistics(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.analytics.CampaignEmailHourlyInsightsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.analytics.CampaignEmailHourlyInsightsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.analytics.CampaignEmailHourlyInsightsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.analytics.CampaignEmailHourlyInsightsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsEmpty();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getDateFrom();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getDateTo();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getStatisticsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics.repeatedFields_ = [5,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics.prototype.toObject = function(opt_includeInstance) {
  return proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics.toObject = function(includeInstance, msg) {
  var f, obj = {
    dayOfWeek: jspb.Message.getFieldWithDefault(msg, 1, ""),
    bestSentRange: jspb.Message.getFieldWithDefault(msg, 2, ""),
    bestReplyRange: jspb.Message.getFieldWithDefault(msg, 3, ""),
    bestOpenRange: jspb.Message.getFieldWithDefault(msg, 4, ""),
    sentItemsList: jspb.Message.toObjectList(msg.getSentItemsList(),
    common_pb.Pair.toObject, includeInstance),
    replyItemsList: jspb.Message.toObjectList(msg.getReplyItemsList(),
    common_pb.Pair.toObject, includeInstance),
    openItemsList: jspb.Message.toObjectList(msg.getOpenItemsList(),
    common_pb.Pair.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics}
 */
proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics;
  return proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics}
 */
proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDayOfWeek(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBestSentRange(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBestReplyRange(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBestOpenRange(value);
      break;
    case 5:
      var value = new common_pb.Pair;
      reader.readMessage(value,common_pb.Pair.deserializeBinaryFromReader);
      msg.addSentItems(value);
      break;
    case 6:
      var value = new common_pb.Pair;
      reader.readMessage(value,common_pb.Pair.deserializeBinaryFromReader);
      msg.addReplyItems(value);
      break;
    case 7:
      var value = new common_pb.Pair;
      reader.readMessage(value,common_pb.Pair.deserializeBinaryFromReader);
      msg.addOpenItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDayOfWeek();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBestSentRange();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBestReplyRange();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBestOpenRange();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSentItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      common_pb.Pair.serializeBinaryToWriter
    );
  }
  f = message.getReplyItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      common_pb.Pair.serializeBinaryToWriter
    );
  }
  f = message.getOpenItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      common_pb.Pair.serializeBinaryToWriter
    );
  }
};


/**
 * optional string day_of_week = 1;
 * @return {string}
 */
proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics.prototype.getDayOfWeek = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics} returns this
 */
proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics.prototype.setDayOfWeek = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string best_sent_range = 2;
 * @return {string}
 */
proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics.prototype.getBestSentRange = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics} returns this
 */
proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics.prototype.setBestSentRange = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string best_reply_range = 3;
 * @return {string}
 */
proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics.prototype.getBestReplyRange = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics} returns this
 */
proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics.prototype.setBestReplyRange = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string best_open_range = 4;
 * @return {string}
 */
proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics.prototype.getBestOpenRange = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics} returns this
 */
proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics.prototype.setBestOpenRange = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated common.Pair sent_items = 5;
 * @return {!Array<!proto.common.Pair>}
 */
proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics.prototype.getSentItemsList = function() {
  return /** @type{!Array<!proto.common.Pair>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.Pair, 5));
};


/**
 * @param {!Array<!proto.common.Pair>} value
 * @return {!proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics} returns this
*/
proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics.prototype.setSentItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.common.Pair=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Pair}
 */
proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics.prototype.addSentItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.common.Pair, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics} returns this
 */
proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics.prototype.clearSentItemsList = function() {
  return this.setSentItemsList([]);
};


/**
 * repeated common.Pair reply_items = 6;
 * @return {!Array<!proto.common.Pair>}
 */
proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics.prototype.getReplyItemsList = function() {
  return /** @type{!Array<!proto.common.Pair>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.Pair, 6));
};


/**
 * @param {!Array<!proto.common.Pair>} value
 * @return {!proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics} returns this
*/
proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics.prototype.setReplyItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.common.Pair=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Pair}
 */
proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics.prototype.addReplyItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.common.Pair, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics} returns this
 */
proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics.prototype.clearReplyItemsList = function() {
  return this.setReplyItemsList([]);
};


/**
 * repeated common.Pair open_items = 7;
 * @return {!Array<!proto.common.Pair>}
 */
proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics.prototype.getOpenItemsList = function() {
  return /** @type{!Array<!proto.common.Pair>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.Pair, 7));
};


/**
 * @param {!Array<!proto.common.Pair>} value
 * @return {!proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics} returns this
*/
proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics.prototype.setOpenItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.common.Pair=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Pair}
 */
proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics.prototype.addOpenItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.common.Pair, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics} returns this
 */
proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics.prototype.clearOpenItemsList = function() {
  return this.setOpenItemsList([]);
};


/**
 * optional bool is_empty = 1;
 * @return {boolean}
 */
proto.analytics.CampaignEmailHourlyInsightsResponse.prototype.getIsEmpty = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.analytics.CampaignEmailHourlyInsightsResponse} returns this
 */
proto.analytics.CampaignEmailHourlyInsightsResponse.prototype.setIsEmpty = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional int64 date_from = 2;
 * @return {number}
 */
proto.analytics.CampaignEmailHourlyInsightsResponse.prototype.getDateFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.CampaignEmailHourlyInsightsResponse} returns this
 */
proto.analytics.CampaignEmailHourlyInsightsResponse.prototype.setDateFrom = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 date_to = 3;
 * @return {number}
 */
proto.analytics.CampaignEmailHourlyInsightsResponse.prototype.getDateTo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.CampaignEmailHourlyInsightsResponse} returns this
 */
proto.analytics.CampaignEmailHourlyInsightsResponse.prototype.setDateTo = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated CampaignEmailHourlyStatistics statistics = 4;
 * @return {!Array<!proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics>}
 */
proto.analytics.CampaignEmailHourlyInsightsResponse.prototype.getStatisticsList = function() {
  return /** @type{!Array<!proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics, 4));
};


/**
 * @param {!Array<!proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics>} value
 * @return {!proto.analytics.CampaignEmailHourlyInsightsResponse} returns this
*/
proto.analytics.CampaignEmailHourlyInsightsResponse.prototype.setStatisticsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics=} opt_value
 * @param {number=} opt_index
 * @return {!proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics}
 */
proto.analytics.CampaignEmailHourlyInsightsResponse.prototype.addStatistics = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.analytics.CampaignEmailHourlyInsightsResponse.CampaignEmailHourlyStatistics, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.analytics.CampaignEmailHourlyInsightsResponse} returns this
 */
proto.analytics.CampaignEmailHourlyInsightsResponse.prototype.clearStatisticsList = function() {
  return this.setStatisticsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.analytics.TeamMembersInsightsResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.analytics.TeamMembersInsightsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.analytics.TeamMembersInsightsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.analytics.TeamMembersInsightsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.analytics.TeamMembersInsightsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isEmpty: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    dateFrom: jspb.Message.getFieldWithDefault(msg, 2, 0),
    dateTo: jspb.Message.getFieldWithDefault(msg, 3, 0),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics.toObject, includeInstance),
    totalContactCreatedNumber: jspb.Message.getFieldWithDefault(msg, 5, 0),
    totalContactContactedNumber: jspb.Message.getFieldWithDefault(msg, 6, 0),
    totalContactRepliedNumber: jspb.Message.getFieldWithDefault(msg, 7, 0),
    totalWebsiteCreatedNumber: jspb.Message.getFieldWithDefault(msg, 8, 0),
    totalWebsiteContactedNumber: jspb.Message.getFieldWithDefault(msg, 9, 0),
    totalWebsiteRepliedNumber: jspb.Message.getFieldWithDefault(msg, 10, 0),
    totalCreditsSpentNumber: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.analytics.TeamMembersInsightsResponse}
 */
proto.analytics.TeamMembersInsightsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.analytics.TeamMembersInsightsResponse;
  return proto.analytics.TeamMembersInsightsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.analytics.TeamMembersInsightsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.analytics.TeamMembersInsightsResponse}
 */
proto.analytics.TeamMembersInsightsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEmpty(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDateFrom(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDateTo(value);
      break;
    case 4:
      var value = new proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics;
      reader.readMessage(value,proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalContactCreatedNumber(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalContactContactedNumber(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalContactRepliedNumber(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalWebsiteCreatedNumber(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalWebsiteContactedNumber(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalWebsiteRepliedNumber(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalCreditsSpentNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.analytics.TeamMembersInsightsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.analytics.TeamMembersInsightsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.analytics.TeamMembersInsightsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.analytics.TeamMembersInsightsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsEmpty();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getDateFrom();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getDateTo();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics.serializeBinaryToWriter
    );
  }
  f = message.getTotalContactCreatedNumber();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getTotalContactContactedNumber();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getTotalContactRepliedNumber();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getTotalWebsiteCreatedNumber();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getTotalWebsiteContactedNumber();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getTotalWebsiteRepliedNumber();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getTotalCreditsSpentNumber();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics.prototype.toObject = function(opt_includeInstance) {
  return proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    contactCreatedNumber: jspb.Message.getFieldWithDefault(msg, 3, 0),
    contactContactedNumber: jspb.Message.getFieldWithDefault(msg, 4, 0),
    contactRepliedNumber: jspb.Message.getFieldWithDefault(msg, 5, 0),
    websiteCreatedNumber: jspb.Message.getFieldWithDefault(msg, 6, 0),
    websiteContactedNumber: jspb.Message.getFieldWithDefault(msg, 7, 0),
    websiteRepliedNumber: jspb.Message.getFieldWithDefault(msg, 8, 0),
    creditsSpentNumber: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics}
 */
proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics;
  return proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics}
 */
proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setContactCreatedNumber(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setContactContactedNumber(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setContactRepliedNumber(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWebsiteCreatedNumber(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWebsiteContactedNumber(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWebsiteRepliedNumber(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCreditsSpentNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getContactCreatedNumber();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getContactContactedNumber();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getContactRepliedNumber();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getWebsiteCreatedNumber();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getWebsiteContactedNumber();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getWebsiteRepliedNumber();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getCreditsSpentNumber();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
};


/**
 * optional int64 user_id = 1;
 * @return {number}
 */
proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics} returns this
 */
proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string user_name = 2;
 * @return {string}
 */
proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics} returns this
 */
proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 contact_created_number = 3;
 * @return {number}
 */
proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics.prototype.getContactCreatedNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics} returns this
 */
proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics.prototype.setContactCreatedNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 contact_contacted_number = 4;
 * @return {number}
 */
proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics.prototype.getContactContactedNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics} returns this
 */
proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics.prototype.setContactContactedNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 contact_replied_number = 5;
 * @return {number}
 */
proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics.prototype.getContactRepliedNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics} returns this
 */
proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics.prototype.setContactRepliedNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 website_created_number = 6;
 * @return {number}
 */
proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics.prototype.getWebsiteCreatedNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics} returns this
 */
proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics.prototype.setWebsiteCreatedNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 website_contacted_number = 7;
 * @return {number}
 */
proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics.prototype.getWebsiteContactedNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics} returns this
 */
proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics.prototype.setWebsiteContactedNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 website_replied_number = 8;
 * @return {number}
 */
proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics.prototype.getWebsiteRepliedNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics} returns this
 */
proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics.prototype.setWebsiteRepliedNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 credits_spent_number = 9;
 * @return {number}
 */
proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics.prototype.getCreditsSpentNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics} returns this
 */
proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics.prototype.setCreditsSpentNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional bool is_empty = 1;
 * @return {boolean}
 */
proto.analytics.TeamMembersInsightsResponse.prototype.getIsEmpty = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.analytics.TeamMembersInsightsResponse} returns this
 */
proto.analytics.TeamMembersInsightsResponse.prototype.setIsEmpty = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional int64 date_from = 2;
 * @return {number}
 */
proto.analytics.TeamMembersInsightsResponse.prototype.getDateFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.TeamMembersInsightsResponse} returns this
 */
proto.analytics.TeamMembersInsightsResponse.prototype.setDateFrom = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 date_to = 3;
 * @return {number}
 */
proto.analytics.TeamMembersInsightsResponse.prototype.getDateTo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.TeamMembersInsightsResponse} returns this
 */
proto.analytics.TeamMembersInsightsResponse.prototype.setDateTo = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated TeamMemberStatistics items = 4;
 * @return {!Array<!proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics>}
 */
proto.analytics.TeamMembersInsightsResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics, 4));
};


/**
 * @param {!Array<!proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics>} value
 * @return {!proto.analytics.TeamMembersInsightsResponse} returns this
*/
proto.analytics.TeamMembersInsightsResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics=} opt_value
 * @param {number=} opt_index
 * @return {!proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics}
 */
proto.analytics.TeamMembersInsightsResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.analytics.TeamMembersInsightsResponse.TeamMemberStatistics, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.analytics.TeamMembersInsightsResponse} returns this
 */
proto.analytics.TeamMembersInsightsResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional int32 total_contact_created_number = 5;
 * @return {number}
 */
proto.analytics.TeamMembersInsightsResponse.prototype.getTotalContactCreatedNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.TeamMembersInsightsResponse} returns this
 */
proto.analytics.TeamMembersInsightsResponse.prototype.setTotalContactCreatedNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 total_contact_contacted_number = 6;
 * @return {number}
 */
proto.analytics.TeamMembersInsightsResponse.prototype.getTotalContactContactedNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.TeamMembersInsightsResponse} returns this
 */
proto.analytics.TeamMembersInsightsResponse.prototype.setTotalContactContactedNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 total_contact_replied_number = 7;
 * @return {number}
 */
proto.analytics.TeamMembersInsightsResponse.prototype.getTotalContactRepliedNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.TeamMembersInsightsResponse} returns this
 */
proto.analytics.TeamMembersInsightsResponse.prototype.setTotalContactRepliedNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 total_website_created_number = 8;
 * @return {number}
 */
proto.analytics.TeamMembersInsightsResponse.prototype.getTotalWebsiteCreatedNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.TeamMembersInsightsResponse} returns this
 */
proto.analytics.TeamMembersInsightsResponse.prototype.setTotalWebsiteCreatedNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 total_website_contacted_number = 9;
 * @return {number}
 */
proto.analytics.TeamMembersInsightsResponse.prototype.getTotalWebsiteContactedNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.TeamMembersInsightsResponse} returns this
 */
proto.analytics.TeamMembersInsightsResponse.prototype.setTotalWebsiteContactedNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 total_website_replied_number = 10;
 * @return {number}
 */
proto.analytics.TeamMembersInsightsResponse.prototype.getTotalWebsiteRepliedNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.TeamMembersInsightsResponse} returns this
 */
proto.analytics.TeamMembersInsightsResponse.prototype.setTotalWebsiteRepliedNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 total_credits_spent_number = 11;
 * @return {number}
 */
proto.analytics.TeamMembersInsightsResponse.prototype.getTotalCreditsSpentNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.TeamMembersInsightsResponse} returns this
 */
proto.analytics.TeamMembersInsightsResponse.prototype.setTotalCreditsSpentNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.analytics.CampaignEmailChartSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.analytics.CampaignEmailChartSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.analytics.CampaignEmailChartSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.analytics.CampaignEmailChartSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sentDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    deliveryDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    openDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    replyDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    bouncedDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    interestedDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    notInterestedDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    unsubscribedDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    personalizedDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 10, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.analytics.CampaignEmailChartSettings}
 */
proto.analytics.CampaignEmailChartSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.analytics.CampaignEmailChartSettings;
  return proto.analytics.CampaignEmailChartSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.analytics.CampaignEmailChartSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.analytics.CampaignEmailChartSettings}
 */
proto.analytics.CampaignEmailChartSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.analytics.ChartSettingsType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSentDisabled(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeliveryDisabled(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOpenDisabled(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReplyDisabled(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBouncedDisabled(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInterestedDisabled(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNotInterestedDisabled(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUnsubscribedDisabled(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPersonalizedDisabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.analytics.CampaignEmailChartSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.analytics.CampaignEmailChartSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.analytics.CampaignEmailChartSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.analytics.CampaignEmailChartSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSentDisabled();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getDeliveryDisabled();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getOpenDisabled();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getReplyDisabled();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getBouncedDisabled();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getInterestedDisabled();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getNotInterestedDisabled();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getUnsubscribedDisabled();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getPersonalizedDisabled();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
};


/**
 * optional ChartSettingsType type = 1;
 * @return {!proto.analytics.ChartSettingsType}
 */
proto.analytics.CampaignEmailChartSettings.prototype.getType = function() {
  return /** @type {!proto.analytics.ChartSettingsType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.analytics.ChartSettingsType} value
 * @return {!proto.analytics.CampaignEmailChartSettings} returns this
 */
proto.analytics.CampaignEmailChartSettings.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional bool sent_disabled = 2;
 * @return {boolean}
 */
proto.analytics.CampaignEmailChartSettings.prototype.getSentDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.analytics.CampaignEmailChartSettings} returns this
 */
proto.analytics.CampaignEmailChartSettings.prototype.setSentDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool delivery_disabled = 3;
 * @return {boolean}
 */
proto.analytics.CampaignEmailChartSettings.prototype.getDeliveryDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.analytics.CampaignEmailChartSettings} returns this
 */
proto.analytics.CampaignEmailChartSettings.prototype.setDeliveryDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool open_disabled = 4;
 * @return {boolean}
 */
proto.analytics.CampaignEmailChartSettings.prototype.getOpenDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.analytics.CampaignEmailChartSettings} returns this
 */
proto.analytics.CampaignEmailChartSettings.prototype.setOpenDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool reply_disabled = 5;
 * @return {boolean}
 */
proto.analytics.CampaignEmailChartSettings.prototype.getReplyDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.analytics.CampaignEmailChartSettings} returns this
 */
proto.analytics.CampaignEmailChartSettings.prototype.setReplyDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool bounced_disabled = 6;
 * @return {boolean}
 */
proto.analytics.CampaignEmailChartSettings.prototype.getBouncedDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.analytics.CampaignEmailChartSettings} returns this
 */
proto.analytics.CampaignEmailChartSettings.prototype.setBouncedDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool interested_disabled = 7;
 * @return {boolean}
 */
proto.analytics.CampaignEmailChartSettings.prototype.getInterestedDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.analytics.CampaignEmailChartSettings} returns this
 */
proto.analytics.CampaignEmailChartSettings.prototype.setInterestedDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool not_interested_disabled = 8;
 * @return {boolean}
 */
proto.analytics.CampaignEmailChartSettings.prototype.getNotInterestedDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.analytics.CampaignEmailChartSettings} returns this
 */
proto.analytics.CampaignEmailChartSettings.prototype.setNotInterestedDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool unsubscribed_disabled = 9;
 * @return {boolean}
 */
proto.analytics.CampaignEmailChartSettings.prototype.getUnsubscribedDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.analytics.CampaignEmailChartSettings} returns this
 */
proto.analytics.CampaignEmailChartSettings.prototype.setUnsubscribedDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool personalized_disabled = 10;
 * @return {boolean}
 */
proto.analytics.CampaignEmailChartSettings.prototype.getPersonalizedDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.analytics.CampaignEmailChartSettings} returns this
 */
proto.analytics.CampaignEmailChartSettings.prototype.setPersonalizedDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.analytics.InsightsResponse.repeatedFields_ = [11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.analytics.InsightsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.analytics.InsightsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.analytics.InsightsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.analytics.InsightsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalDeliveryRate: jspb.Message.getFieldWithDefault(msg, 1, 0),
    totalDeliveryNumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalOpenRate: jspb.Message.getFieldWithDefault(msg, 3, 0),
    totalOpenNumber: jspb.Message.getFieldWithDefault(msg, 4, 0),
    totalReplyRate: jspb.Message.getFieldWithDefault(msg, 5, 0),
    totalReplyNumber: jspb.Message.getFieldWithDefault(msg, 6, 0),
    totalInterestedRate: jspb.Message.getFieldWithDefault(msg, 7, 0),
    totalInterestedNumber: jspb.Message.getFieldWithDefault(msg, 8, 0),
    totalWonRate: jspb.Message.getFieldWithDefault(msg, 9, 0),
    totalWonNumber: jspb.Message.getFieldWithDefault(msg, 10, 0),
    dailyStatisticsList: jspb.Message.toObjectList(msg.getDailyStatisticsList(),
    proto.analytics.InsightsResponse.DailyStatistics.toObject, includeInstance),
    isEmpty: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    dateFrom: jspb.Message.getFieldWithDefault(msg, 14, 0),
    dateTo: jspb.Message.getFieldWithDefault(msg, 15, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.analytics.InsightsResponse}
 */
proto.analytics.InsightsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.analytics.InsightsResponse;
  return proto.analytics.InsightsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.analytics.InsightsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.analytics.InsightsResponse}
 */
proto.analytics.InsightsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalDeliveryRate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalDeliveryNumber(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalOpenRate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalOpenNumber(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalReplyRate(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalReplyNumber(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalInterestedRate(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalInterestedNumber(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalWonRate(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalWonNumber(value);
      break;
    case 11:
      var value = new proto.analytics.InsightsResponse.DailyStatistics;
      reader.readMessage(value,proto.analytics.InsightsResponse.DailyStatistics.deserializeBinaryFromReader);
      msg.addDailyStatistics(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEmpty(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDateFrom(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDateTo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.analytics.InsightsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.analytics.InsightsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.analytics.InsightsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.analytics.InsightsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalDeliveryRate();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTotalDeliveryNumber();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTotalOpenRate();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTotalOpenNumber();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getTotalReplyRate();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getTotalReplyNumber();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getTotalInterestedRate();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getTotalInterestedNumber();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getTotalWonRate();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getTotalWonNumber();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getDailyStatisticsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.analytics.InsightsResponse.DailyStatistics.serializeBinaryToWriter
    );
  }
  f = message.getIsEmpty();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getDateFrom();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getDateTo();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.analytics.InsightsResponse.DailyStatistics.prototype.toObject = function(opt_includeInstance) {
  return proto.analytics.InsightsResponse.DailyStatistics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.analytics.InsightsResponse.DailyStatistics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.analytics.InsightsResponse.DailyStatistics.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: jspb.Message.getFieldWithDefault(msg, 1, ""),
    deliveryNumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    openNumber: jspb.Message.getFieldWithDefault(msg, 3, 0),
    replyNumber: jspb.Message.getFieldWithDefault(msg, 4, 0),
    interestedNumber: jspb.Message.getFieldWithDefault(msg, 5, 0),
    wonNumber: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.analytics.InsightsResponse.DailyStatistics}
 */
proto.analytics.InsightsResponse.DailyStatistics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.analytics.InsightsResponse.DailyStatistics;
  return proto.analytics.InsightsResponse.DailyStatistics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.analytics.InsightsResponse.DailyStatistics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.analytics.InsightsResponse.DailyStatistics}
 */
proto.analytics.InsightsResponse.DailyStatistics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeliveryNumber(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOpenNumber(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReplyNumber(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInterestedNumber(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWonNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.analytics.InsightsResponse.DailyStatistics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.analytics.InsightsResponse.DailyStatistics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.analytics.InsightsResponse.DailyStatistics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.analytics.InsightsResponse.DailyStatistics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDeliveryNumber();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getOpenNumber();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getReplyNumber();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getInterestedNumber();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getWonNumber();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional string date = 1;
 * @return {string}
 */
proto.analytics.InsightsResponse.DailyStatistics.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.analytics.InsightsResponse.DailyStatistics} returns this
 */
proto.analytics.InsightsResponse.DailyStatistics.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 delivery_number = 2;
 * @return {number}
 */
proto.analytics.InsightsResponse.DailyStatistics.prototype.getDeliveryNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.InsightsResponse.DailyStatistics} returns this
 */
proto.analytics.InsightsResponse.DailyStatistics.prototype.setDeliveryNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 open_number = 3;
 * @return {number}
 */
proto.analytics.InsightsResponse.DailyStatistics.prototype.getOpenNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.InsightsResponse.DailyStatistics} returns this
 */
proto.analytics.InsightsResponse.DailyStatistics.prototype.setOpenNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 reply_number = 4;
 * @return {number}
 */
proto.analytics.InsightsResponse.DailyStatistics.prototype.getReplyNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.InsightsResponse.DailyStatistics} returns this
 */
proto.analytics.InsightsResponse.DailyStatistics.prototype.setReplyNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 interested_number = 5;
 * @return {number}
 */
proto.analytics.InsightsResponse.DailyStatistics.prototype.getInterestedNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.InsightsResponse.DailyStatistics} returns this
 */
proto.analytics.InsightsResponse.DailyStatistics.prototype.setInterestedNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 won_number = 6;
 * @return {number}
 */
proto.analytics.InsightsResponse.DailyStatistics.prototype.getWonNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.InsightsResponse.DailyStatistics} returns this
 */
proto.analytics.InsightsResponse.DailyStatistics.prototype.setWonNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 total_delivery_rate = 1;
 * @return {number}
 */
proto.analytics.InsightsResponse.prototype.getTotalDeliveryRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.InsightsResponse} returns this
 */
proto.analytics.InsightsResponse.prototype.setTotalDeliveryRate = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 total_delivery_number = 2;
 * @return {number}
 */
proto.analytics.InsightsResponse.prototype.getTotalDeliveryNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.InsightsResponse} returns this
 */
proto.analytics.InsightsResponse.prototype.setTotalDeliveryNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 total_open_rate = 3;
 * @return {number}
 */
proto.analytics.InsightsResponse.prototype.getTotalOpenRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.InsightsResponse} returns this
 */
proto.analytics.InsightsResponse.prototype.setTotalOpenRate = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 total_open_number = 4;
 * @return {number}
 */
proto.analytics.InsightsResponse.prototype.getTotalOpenNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.InsightsResponse} returns this
 */
proto.analytics.InsightsResponse.prototype.setTotalOpenNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 total_reply_rate = 5;
 * @return {number}
 */
proto.analytics.InsightsResponse.prototype.getTotalReplyRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.InsightsResponse} returns this
 */
proto.analytics.InsightsResponse.prototype.setTotalReplyRate = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 total_reply_number = 6;
 * @return {number}
 */
proto.analytics.InsightsResponse.prototype.getTotalReplyNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.InsightsResponse} returns this
 */
proto.analytics.InsightsResponse.prototype.setTotalReplyNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 total_interested_rate = 7;
 * @return {number}
 */
proto.analytics.InsightsResponse.prototype.getTotalInterestedRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.InsightsResponse} returns this
 */
proto.analytics.InsightsResponse.prototype.setTotalInterestedRate = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 total_interested_number = 8;
 * @return {number}
 */
proto.analytics.InsightsResponse.prototype.getTotalInterestedNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.InsightsResponse} returns this
 */
proto.analytics.InsightsResponse.prototype.setTotalInterestedNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 total_won_rate = 9;
 * @return {number}
 */
proto.analytics.InsightsResponse.prototype.getTotalWonRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.InsightsResponse} returns this
 */
proto.analytics.InsightsResponse.prototype.setTotalWonRate = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 total_won_number = 10;
 * @return {number}
 */
proto.analytics.InsightsResponse.prototype.getTotalWonNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.InsightsResponse} returns this
 */
proto.analytics.InsightsResponse.prototype.setTotalWonNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * repeated DailyStatistics daily_statistics = 11;
 * @return {!Array<!proto.analytics.InsightsResponse.DailyStatistics>}
 */
proto.analytics.InsightsResponse.prototype.getDailyStatisticsList = function() {
  return /** @type{!Array<!proto.analytics.InsightsResponse.DailyStatistics>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.analytics.InsightsResponse.DailyStatistics, 11));
};


/**
 * @param {!Array<!proto.analytics.InsightsResponse.DailyStatistics>} value
 * @return {!proto.analytics.InsightsResponse} returns this
*/
proto.analytics.InsightsResponse.prototype.setDailyStatisticsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.analytics.InsightsResponse.DailyStatistics=} opt_value
 * @param {number=} opt_index
 * @return {!proto.analytics.InsightsResponse.DailyStatistics}
 */
proto.analytics.InsightsResponse.prototype.addDailyStatistics = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.analytics.InsightsResponse.DailyStatistics, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.analytics.InsightsResponse} returns this
 */
proto.analytics.InsightsResponse.prototype.clearDailyStatisticsList = function() {
  return this.setDailyStatisticsList([]);
};


/**
 * optional bool is_empty = 13;
 * @return {boolean}
 */
proto.analytics.InsightsResponse.prototype.getIsEmpty = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.analytics.InsightsResponse} returns this
 */
proto.analytics.InsightsResponse.prototype.setIsEmpty = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional int64 date_from = 14;
 * @return {number}
 */
proto.analytics.InsightsResponse.prototype.getDateFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.InsightsResponse} returns this
 */
proto.analytics.InsightsResponse.prototype.setDateFrom = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int64 date_to = 15;
 * @return {number}
 */
proto.analytics.InsightsResponse.prototype.getDateTo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.InsightsResponse} returns this
 */
proto.analytics.InsightsResponse.prototype.setDateTo = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.analytics.OnboardingStepRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.analytics.OnboardingStepRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.analytics.OnboardingStepRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.analytics.OnboardingStepRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    step: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.analytics.OnboardingStepRequest}
 */
proto.analytics.OnboardingStepRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.analytics.OnboardingStepRequest;
  return proto.analytics.OnboardingStepRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.analytics.OnboardingStepRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.analytics.OnboardingStepRequest}
 */
proto.analytics.OnboardingStepRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.analytics.OnboardingStepRequest.OnboardingStep} */ (reader.readEnum());
      msg.setStep(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.analytics.OnboardingStepRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.analytics.OnboardingStepRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.analytics.OnboardingStepRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.analytics.OnboardingStepRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStep();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.analytics.OnboardingStepRequest.OnboardingStep = {
  INVALID_STEP: 0,
  FIRST_STEP: 1,
  SECOND_STEP: 2
};

/**
 * optional OnboardingStep step = 1;
 * @return {!proto.analytics.OnboardingStepRequest.OnboardingStep}
 */
proto.analytics.OnboardingStepRequest.prototype.getStep = function() {
  return /** @type {!proto.analytics.OnboardingStepRequest.OnboardingStep} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.analytics.OnboardingStepRequest.OnboardingStep} value
 * @return {!proto.analytics.OnboardingStepRequest} returns this
 */
proto.analytics.OnboardingStepRequest.prototype.setStep = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.analytics.CreditUsageCategoryItem.prototype.toObject = function(opt_includeInstance) {
  return proto.analytics.CreditUsageCategoryItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.analytics.CreditUsageCategoryItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.analytics.CreditUsageCategoryItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    group: jspb.Message.getFieldWithDefault(msg, 1, 0),
    creditsAmount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    rate: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.analytics.CreditUsageCategoryItem}
 */
proto.analytics.CreditUsageCategoryItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.analytics.CreditUsageCategoryItem;
  return proto.analytics.CreditUsageCategoryItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.analytics.CreditUsageCategoryItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.analytics.CreditUsageCategoryItem}
 */
proto.analytics.CreditUsageCategoryItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.analytics.CreditUsageSearchGroup} */ (reader.readEnum());
      msg.setGroup(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCreditsAmount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.analytics.CreditUsageCategoryItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.analytics.CreditUsageCategoryItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.analytics.CreditUsageCategoryItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.analytics.CreditUsageCategoryItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroup();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCreditsAmount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getRate();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional CreditUsageSearchGroup group = 1;
 * @return {!proto.analytics.CreditUsageSearchGroup}
 */
proto.analytics.CreditUsageCategoryItem.prototype.getGroup = function() {
  return /** @type {!proto.analytics.CreditUsageSearchGroup} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.analytics.CreditUsageSearchGroup} value
 * @return {!proto.analytics.CreditUsageCategoryItem} returns this
 */
proto.analytics.CreditUsageCategoryItem.prototype.setGroup = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int32 credits_amount = 2;
 * @return {number}
 */
proto.analytics.CreditUsageCategoryItem.prototype.getCreditsAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.CreditUsageCategoryItem} returns this
 */
proto.analytics.CreditUsageCategoryItem.prototype.setCreditsAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 rate = 3;
 * @return {number}
 */
proto.analytics.CreditUsageCategoryItem.prototype.getRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.CreditUsageCategoryItem} returns this
 */
proto.analytics.CreditUsageCategoryItem.prototype.setRate = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.analytics.DailyCreditUsageResponse.repeatedFields_ = [5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.analytics.DailyCreditUsageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.analytics.DailyCreditUsageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.analytics.DailyCreditUsageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.analytics.DailyCreditUsageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isEmpty: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    dateFrom: jspb.Message.getFieldWithDefault(msg, 2, 0),
    dateTo: jspb.Message.getFieldWithDefault(msg, 3, 0),
    totalSpentCredits: jspb.Message.getFieldWithDefault(msg, 4, 0),
    dailyStatisticsList: jspb.Message.toObjectList(msg.getDailyStatisticsList(),
    proto.analytics.DailyCreditUsageResponse.DailyStatistics.toObject, includeInstance),
    totalItemsList: jspb.Message.toObjectList(msg.getTotalItemsList(),
    proto.analytics.CreditUsageCategoryItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.analytics.DailyCreditUsageResponse}
 */
proto.analytics.DailyCreditUsageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.analytics.DailyCreditUsageResponse;
  return proto.analytics.DailyCreditUsageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.analytics.DailyCreditUsageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.analytics.DailyCreditUsageResponse}
 */
proto.analytics.DailyCreditUsageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEmpty(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDateFrom(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDateTo(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalSpentCredits(value);
      break;
    case 5:
      var value = new proto.analytics.DailyCreditUsageResponse.DailyStatistics;
      reader.readMessage(value,proto.analytics.DailyCreditUsageResponse.DailyStatistics.deserializeBinaryFromReader);
      msg.addDailyStatistics(value);
      break;
    case 6:
      var value = new proto.analytics.CreditUsageCategoryItem;
      reader.readMessage(value,proto.analytics.CreditUsageCategoryItem.deserializeBinaryFromReader);
      msg.addTotalItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.analytics.DailyCreditUsageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.analytics.DailyCreditUsageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.analytics.DailyCreditUsageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.analytics.DailyCreditUsageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsEmpty();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getDateFrom();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getDateTo();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getTotalSpentCredits();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getDailyStatisticsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.analytics.DailyCreditUsageResponse.DailyStatistics.serializeBinaryToWriter
    );
  }
  f = message.getTotalItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.analytics.CreditUsageCategoryItem.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.analytics.DailyCreditUsageResponse.DailyStatistics.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.analytics.DailyCreditUsageResponse.DailyStatistics.prototype.toObject = function(opt_includeInstance) {
  return proto.analytics.DailyCreditUsageResponse.DailyStatistics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.analytics.DailyCreditUsageResponse.DailyStatistics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.analytics.DailyCreditUsageResponse.DailyStatistics.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: jspb.Message.getFieldWithDefault(msg, 1, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.analytics.CreditUsageCategoryItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.analytics.DailyCreditUsageResponse.DailyStatistics}
 */
proto.analytics.DailyCreditUsageResponse.DailyStatistics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.analytics.DailyCreditUsageResponse.DailyStatistics;
  return proto.analytics.DailyCreditUsageResponse.DailyStatistics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.analytics.DailyCreditUsageResponse.DailyStatistics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.analytics.DailyCreditUsageResponse.DailyStatistics}
 */
proto.analytics.DailyCreditUsageResponse.DailyStatistics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 4:
      var value = new proto.analytics.CreditUsageCategoryItem;
      reader.readMessage(value,proto.analytics.CreditUsageCategoryItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.analytics.DailyCreditUsageResponse.DailyStatistics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.analytics.DailyCreditUsageResponse.DailyStatistics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.analytics.DailyCreditUsageResponse.DailyStatistics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.analytics.DailyCreditUsageResponse.DailyStatistics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.analytics.CreditUsageCategoryItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string date = 1;
 * @return {string}
 */
proto.analytics.DailyCreditUsageResponse.DailyStatistics.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.analytics.DailyCreditUsageResponse.DailyStatistics} returns this
 */
proto.analytics.DailyCreditUsageResponse.DailyStatistics.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated CreditUsageCategoryItem items = 4;
 * @return {!Array<!proto.analytics.CreditUsageCategoryItem>}
 */
proto.analytics.DailyCreditUsageResponse.DailyStatistics.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.analytics.CreditUsageCategoryItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.analytics.CreditUsageCategoryItem, 4));
};


/**
 * @param {!Array<!proto.analytics.CreditUsageCategoryItem>} value
 * @return {!proto.analytics.DailyCreditUsageResponse.DailyStatistics} returns this
*/
proto.analytics.DailyCreditUsageResponse.DailyStatistics.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.analytics.CreditUsageCategoryItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.analytics.CreditUsageCategoryItem}
 */
proto.analytics.DailyCreditUsageResponse.DailyStatistics.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.analytics.CreditUsageCategoryItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.analytics.DailyCreditUsageResponse.DailyStatistics} returns this
 */
proto.analytics.DailyCreditUsageResponse.DailyStatistics.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional bool is_empty = 1;
 * @return {boolean}
 */
proto.analytics.DailyCreditUsageResponse.prototype.getIsEmpty = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.analytics.DailyCreditUsageResponse} returns this
 */
proto.analytics.DailyCreditUsageResponse.prototype.setIsEmpty = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional int64 date_from = 2;
 * @return {number}
 */
proto.analytics.DailyCreditUsageResponse.prototype.getDateFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.DailyCreditUsageResponse} returns this
 */
proto.analytics.DailyCreditUsageResponse.prototype.setDateFrom = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 date_to = 3;
 * @return {number}
 */
proto.analytics.DailyCreditUsageResponse.prototype.getDateTo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.DailyCreditUsageResponse} returns this
 */
proto.analytics.DailyCreditUsageResponse.prototype.setDateTo = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 total_spent_credits = 4;
 * @return {number}
 */
proto.analytics.DailyCreditUsageResponse.prototype.getTotalSpentCredits = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.DailyCreditUsageResponse} returns this
 */
proto.analytics.DailyCreditUsageResponse.prototype.setTotalSpentCredits = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated DailyStatistics daily_statistics = 5;
 * @return {!Array<!proto.analytics.DailyCreditUsageResponse.DailyStatistics>}
 */
proto.analytics.DailyCreditUsageResponse.prototype.getDailyStatisticsList = function() {
  return /** @type{!Array<!proto.analytics.DailyCreditUsageResponse.DailyStatistics>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.analytics.DailyCreditUsageResponse.DailyStatistics, 5));
};


/**
 * @param {!Array<!proto.analytics.DailyCreditUsageResponse.DailyStatistics>} value
 * @return {!proto.analytics.DailyCreditUsageResponse} returns this
*/
proto.analytics.DailyCreditUsageResponse.prototype.setDailyStatisticsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.analytics.DailyCreditUsageResponse.DailyStatistics=} opt_value
 * @param {number=} opt_index
 * @return {!proto.analytics.DailyCreditUsageResponse.DailyStatistics}
 */
proto.analytics.DailyCreditUsageResponse.prototype.addDailyStatistics = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.analytics.DailyCreditUsageResponse.DailyStatistics, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.analytics.DailyCreditUsageResponse} returns this
 */
proto.analytics.DailyCreditUsageResponse.prototype.clearDailyStatisticsList = function() {
  return this.setDailyStatisticsList([]);
};


/**
 * repeated CreditUsageCategoryItem total_items = 6;
 * @return {!Array<!proto.analytics.CreditUsageCategoryItem>}
 */
proto.analytics.DailyCreditUsageResponse.prototype.getTotalItemsList = function() {
  return /** @type{!Array<!proto.analytics.CreditUsageCategoryItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.analytics.CreditUsageCategoryItem, 6));
};


/**
 * @param {!Array<!proto.analytics.CreditUsageCategoryItem>} value
 * @return {!proto.analytics.DailyCreditUsageResponse} returns this
*/
proto.analytics.DailyCreditUsageResponse.prototype.setTotalItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.analytics.CreditUsageCategoryItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.analytics.CreditUsageCategoryItem}
 */
proto.analytics.DailyCreditUsageResponse.prototype.addTotalItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.analytics.CreditUsageCategoryItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.analytics.DailyCreditUsageResponse} returns this
 */
proto.analytics.DailyCreditUsageResponse.prototype.clearTotalItemsList = function() {
  return this.setTotalItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.analytics.CampaignInsightsRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.analytics.CampaignInsightsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.analytics.CampaignInsightsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.analytics.CampaignInsightsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.analytics.CampaignInsightsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    idsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    optionalWorkspaceId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    dateFrom: jspb.Message.getFieldWithDefault(msg, 4, 0),
    dateTo: jspb.Message.getFieldWithDefault(msg, 5, 0),
    timezone: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.analytics.CampaignInsightsRequest}
 */
proto.analytics.CampaignInsightsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.analytics.CampaignInsightsRequest;
  return proto.analytics.CampaignInsightsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.analytics.CampaignInsightsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.analytics.CampaignInsightsRequest}
 */
proto.analytics.CampaignInsightsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.analytics.InsightsFilterField} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setIdsList(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOptionalWorkspaceId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDateFrom(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDateTo(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.analytics.CampaignInsightsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.analytics.CampaignInsightsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.analytics.CampaignInsightsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.analytics.CampaignInsightsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
  f = message.getOptionalWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getDateFrom();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getDateTo();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional InsightsFilterField type = 1;
 * @return {!proto.analytics.InsightsFilterField}
 */
proto.analytics.CampaignInsightsRequest.prototype.getType = function() {
  return /** @type {!proto.analytics.InsightsFilterField} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.analytics.InsightsFilterField} value
 * @return {!proto.analytics.CampaignInsightsRequest} returns this
 */
proto.analytics.CampaignInsightsRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated int64 ids = 2;
 * @return {!Array<number>}
 */
proto.analytics.CampaignInsightsRequest.prototype.getIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.analytics.CampaignInsightsRequest} returns this
 */
proto.analytics.CampaignInsightsRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.analytics.CampaignInsightsRequest} returns this
 */
proto.analytics.CampaignInsightsRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.analytics.CampaignInsightsRequest} returns this
 */
proto.analytics.CampaignInsightsRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};


/**
 * optional int64 optional_workspace_id = 3;
 * @return {number}
 */
proto.analytics.CampaignInsightsRequest.prototype.getOptionalWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.CampaignInsightsRequest} returns this
 */
proto.analytics.CampaignInsightsRequest.prototype.setOptionalWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 date_from = 4;
 * @return {number}
 */
proto.analytics.CampaignInsightsRequest.prototype.getDateFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.CampaignInsightsRequest} returns this
 */
proto.analytics.CampaignInsightsRequest.prototype.setDateFrom = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 date_to = 5;
 * @return {number}
 */
proto.analytics.CampaignInsightsRequest.prototype.getDateTo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.CampaignInsightsRequest} returns this
 */
proto.analytics.CampaignInsightsRequest.prototype.setDateTo = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string timezone = 6;
 * @return {string}
 */
proto.analytics.CampaignInsightsRequest.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.analytics.CampaignInsightsRequest} returns this
 */
proto.analytics.CampaignInsightsRequest.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * @enum {number}
 */
proto.analytics.CreditUsageSearchGroup = {
  CREDIT_USAGE_SEARCH_GROUP_CONTENT: 0,
  CREDIT_USAGE_SEARCH_GROUP_AI: 1,
  CREDIT_USAGE_SEARCH_GROUP_CONTACT: 2
};

/**
 * @enum {number}
 */
proto.analytics.CreditUsageCategory = {
  CREDIT_USAGE_UNDEFINED: 0,
  CREDIT_USAGE_CONTENT_SEARCH: 1,
  CREDIT_USAGE_CONTACT_AUTOMATION_SEARCH: 2,
  CREDIT_USAGE_CONTACT_MANUAL_SEARCH: 3,
  CREDIT_USAGE_CONTACT_LINKED_IN_SEARCH: 4,
  CREDIT_USAGE_CONTACT_EMAIL_SEARCH: 5,
  CREDIT_USAGE_EMAIL_VERIFICATION: 6,
  CREDIT_USAGE_AI_PERSONALIZATION_CHEAP: 7,
  CREDIT_USAGE_AI_PERSONALIZATION_EXPENSIVE: 8,
  CREDIT_USAGE_AI_MAILBOX_ASSISTANT: 9,
  CREDIT_USAGE_AI_KEYWORD_SUGGESTIONS: 10,
  CREDIT_USAGE_MOZ_METRICS_POPULATION: 11,
  CREDIT_USAGE_SUMMARIZE_TEXT: 12,
  CREDIT_USAGE_SUMMARIZE_AUDIO: 13
};

/**
 * @enum {number}
 */
proto.analytics.InsightsFilterField = {
  DROPDOWN_INSIGHT_WORKSPACE_ID: 0,
  DROPDOWN_INSIGHT_CAMPAIGN_ID: 1,
  DROPDOWN_INSIGHT_USER_ID: 2,
  DROPDOWN_INSIGHT_MAILBOX_ACCOUNT_ID: 3,
  DROPDOWN_INSIGHT_CAMPAIGN_TEMPLATE_ID: 4
};

/**
 * @enum {number}
 */
proto.analytics.ChartSettingsType = {
  CAMPAIGN_EMAILS_DAILY: 0,
  CAMPAIGN_EMAILS_HOURLY: 1,
  CAMPAIGN_PEOPLE_EMAILS_DAILY: 2,
  CAMPAIGN_WEBSITES_EMAILS_DAILY: 3
};

goog.object.extend(exports, proto.analytics);
