import React from 'react';
import cn from 'class-names';

import './MultiSwitch.scss';

const MultiSwitch = ({
  options,
  onChange,
  classNames,
  value,
}: {
  options: string[];
  onChange: (option: string) => void;
  classNames?: { container?: string; option?: string; optionActive?: string };
  value: string;
}): JSX.Element => {
  const getOptionCn = (option) =>
    cn('multi-switch__option', {
      'multi-switch__option--active': option === value,
      [classNames?.option]: !!classNames?.option,
      [classNames?.optionActive]: option === value && !!classNames?.optionActive,
    });

  const handleChange = (option) => {
    if (option !== value) {
      onChange(option);
    }
  };

  return (
    <div className={cn('multi-switch', { [classNames?.container]: !!classNames?.container })}>
      {options.map((option) => (
        <div onClick={() => handleChange(option)} className={getOptionCn(option)} key={option}>
          {option}
        </div>
      ))}
    </div>
  );
};

export default React.memo(MultiSwitch);
