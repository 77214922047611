import QuestionTooltip from '@pages/CampaignCreationPage/_components/QuestionTooltip/QuestionTooltip';
import { LoginPageGradient } from '@pages/LoginPage/_components/LoginPageGradient';
import Loader from '@uikit/Loader/Loader';
import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
// eslint-disable-next-line import/no-extraneous-dependencies
import cn from 'classnames';
import ReCAPTCHA from 'react-google-recaptcha';
import qs from 'query-string';

import { LoginResponseType, VerificationEmailResponseType } from '@ts/auth.types';

import {
  AuthHandlerType,
  AuthHandlerTypeMap,
  VerificationCodeType,
} from 'respona_api/generated/auth-shared_pb';
import { BasePreloaderScreen } from 'src/BaseRequestComponent';
import { authService } from 'src/services/authService';

import {
  acceptInvitationApi,
  getTimeZone,
  loginByIntegrationApi,
  showReCaptchaApi,
} from '@api/auth.api';

import LoginPageBackground from '@pages/LoginPage/_components/LoginPageBackground';
import { LoginPagePaymentFlow } from '@pages/LoginPage/_components/LoginPagePaymentFlow';

import {
  CodeConfirmationFlow,
  LoginFlow,
  LoginSignupFlow,
  OnboardingSessionFlow,
  RequestUpdatePasswordFlow,
  SignUpFlow,
  UpdatePasswordFlow,
} from '@pages/LoginPage/_components/LoginPageFlows';

import './LoginPage.scss';

import { calendlyStatusTypes, eWebinarStatusTypes } from '@constants/onboardingPopupStatuses';
import { setOnboardingStep } from '@api/analytics.api';
// @ts-ignore
import { useGoogleLogin } from '@react-oauth/google';
// @ts-ignore
import gtm from 'src/libs/gtm';
import { skipOnboardingSessionApi } from '@api/user.api';
// @ts-ignore
import responaLogo from '../../assets/ResponaLogoLarge.svg';

declare const IS_DEV: boolean;

export enum LoginStep {
  welcome = 'welcome',
  login = 'login',
  signUp = 'register',
  auth = 'authorize/login',
  confirm = 'confirm',
  onboarding = 'onboarding',
  trial = 'trial',
  forgotPassword = 'forgot-password',
  restore = 'restore',
}

const getContainerMaxHeight = (step: LoginStep) => {
  switch (step) {
    case LoginStep.welcome:
      // return '500px';
      return '568px';
    case LoginStep.signUp:
      return '664px';
    case LoginStep.login:
      return '535px';
    case LoginStep.confirm:
      return '411px';
    case LoginStep.onboarding:
      return '565px';
    case LoginStep.trial:
      return '655px';
    case LoginStep.forgotPassword:
      return '428px';
    case LoginStep.restore:
      return '540px';
    default:
      return 'unset';
  }
};

function NewLoginPage({ history, location }: any): JSX.Element {
  const parsedSearch = qs.parse(location.search);

  const { token, ref, src } = parsedSearch;

  const utmTagPairs = Object.entries(parsedSearch).filter(([key]) => key.includes('utm_')) as [
    string,
    string,
  ][];

  const [step, setStep] = useState<LoginStep>(LoginStep.welcome);

  const [state, setState] = useState({
    email: '',
    password: '',
    fullName: '',
    code: '',
    captcha: '',
    newPassword: '',
    showCaptcha: true,
    type: AuthHandlerType.NONE,
  });

  const [emailVerification, setEmailVerification] = useState<VerificationEmailResponseType>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [referralCode, setReferralCode] = useState<string>(null);
  const [invitationAccepted, setInvitationAccepted] = useState<boolean>(false);
  const [loginResponse, setLoginResponse] = useState<LoginResponseType>(null);
  const setupDemo = useRef<boolean>(false);

  const { data: showCaptchaStatus, isSuccess } = useQuery({
    queryKey: 'recaptcha',
    queryFn: () => showReCaptchaApi(),
    refetchOnWindowFocus: false,
    onSuccess: (data) => handleInput('showCaptcha', data.show),
  });

  const handleInput = (
    key: string,
    value: string | boolean | AuthHandlerTypeMap[keyof AuthHandlerTypeMap]
  ) => {
    setState((data) => ({ ...data, [key]: value }));
  };

  const updateRef = (key: string, value: boolean) => {
    if (key === 'setupDemo') {
      setupDemo.current = value;
    }
  };

  const loginWithGoogle = useGoogleLogin({
    onSuccess: ({ code }) => {
      loginByIntegrationApi(code, ref as string, utmTagPairs, src as string).then((result) => {
        setLoginResponse(result);
        if (result.firstLogin) {
          gtm.signUp(result.userEmail);
          gtm.signIn(result.userId);
          history.push(`/${LoginStep.onboarding}`);
        } else {
          gtm.signIn(result.userId);
          authService.loginCallback(result);
        }
      });
    },
    onError: (errorResponse) => {
      console.error('Google Auth error', errorResponse);
    },
    flow: 'auth-code',
    scope: 'profile email',
  });

  const completeStep = async (step: LoginStep) => {
    switch (step) {
      case LoginStep.welcome: {
        setIsLoading(true);
        return authService
          .checkAccountByEmail(state.email)
          .then((res) => {
            setEmailVerification(res);
            if (res.exist) {
              handleInput('type', res.type);
              if (res.type === AuthHandlerType.GOOGLE) {
                loginWithGoogle();
              } else if (res.type === AuthHandlerType.MICROSOFT) {
                window.open(
                  `https://${
                    IS_DEV ? 'dev.respona.com' : 'app.respona.com'
                  }/auth/microsoft/secure/aad?time-zone=${encodeURIComponent(getTimeZone())}${
                    ref ? `&ref=${ref}` : ''
                  }${src ? `&src=${src}` : ''}`,
                  '_self'
                );
              } else {
                history.push(`/${LoginStep.login}`);
              }
            } else if (res.valid) {
              authService
                .sendVerificationCode(state.email, VerificationCodeType.REGISTRATION)
                .then(() => {
                  history.push(`/${LoginStep.signUp}${history.location.search}`);
                });
            }
          })
          .finally(() => setIsLoading(false));
      }

      case LoginStep.login: {
        setIsLoading(true);
        return authService
          .login(state.email, state.password, state.captcha)
          .finally(() => setIsLoading(false));
      }

      case LoginStep.signUp: {
        setIsLoading(true);

        if (token) {
          await acceptInvitationApi(state.fullName, state.password, token as string, state.captcha);
          return authService
            .register(
              state.email,
              state.password,
              state.fullName,
              state.captcha,
              state.code,
              referralCode,
              utmTagPairs,
              src as string
            )
            .then((response) => {
              setLoginResponse(response);
              localStorage.setItem('showCalendly', calendlyStatusTypes.COMPLETE);
              gtm.signUp(response.userEmail);
              gtm.signIn(response.userId);
              authService.loginCallback(response);
            });
        }

        history.push(`/${LoginStep.confirm}${history.location.search}`);

        setIsLoading(false);

        return Promise.resolve();
      }

      case LoginStep.onboarding: {
        setIsLoading(true);

        const { sessionToken } = loginResponse;

        localStorage.setItem('token', sessionToken);

        if (setupDemo.current) {
          const nameNor = encodeURIComponent(state.fullName);
          const emailNor = state.email;
          window.open(
            `https://calendly.com/respona/onboarding?name=${nameNor}&email=${emailNor}`,
            '_blank',
            'noopener,noreferrer'
          );
          localStorage.setItem('showCalendly', calendlyStatusTypes.COMPLETE);
          await skipOnboardingSessionApi();
          await setOnboardingStep(1);
        }

        setIsLoading(false);

        localStorage.setItem('firstCampaign', 'true');
        gtm.signIn(loginResponse.userId);
        authService.loginCallback(loginResponse);

        return Promise.resolve();
      }

      case LoginStep.confirm: {
        setIsLoading(true);
        return authService
          .register(
            state.email,
            state.password,
            state.fullName,
            state.captcha,
            state.code,
            referralCode,
            utmTagPairs,
            src as string
          )
          .then((response) => {
            setLoginResponse(response);
            gtm.signUp(response.userEmail);
            gtm.signIn(response.userId);
            if (invitationAccepted) {
              localStorage.setItem('showCalendly', calendlyStatusTypes.COMPLETE);
              authService.loginCallback(response);
            } else {
              history.push(`/${LoginStep.onboarding}`);
            }
          })
          .finally(() => setIsLoading(false));
      }

      case LoginStep.forgotPassword: {
        setIsLoading(true);
        return authService
          .sendVerificationCode(state.email, VerificationCodeType.RESET_PASSWORD)
          .then(() => {
            history.push(`/${LoginStep.restore}`);
          })
          .finally(() => setIsLoading(false));
      }

      case LoginStep.restore: {
        setIsLoading(true);
        return authService
          .changePasswordByToken(state.code, state.email, state.newPassword)
          .finally(() => setIsLoading(false));
      }

      default:
    }
  };

  const resetState = (callback?: () => void) => {
    setEmailVerification(null);
    setState({
      email: '',
      password: '',
      fullName: '',
      code: '',
      captcha: '',
      newPassword: '',
      showCaptcha: showCaptchaStatus?.show,
      type: AuthHandlerType.NONE,
    });

    if (callback) callback();
  };

  useEffect(() => {
    if (ref) {
      setReferralCode(ref as string);
    }
  }, [ref]);

  useEffect(() => {
    setStep(location.pathname.slice(1));
  }, [location.pathname]);

  useEffect(() => {
    const isLoggedIn = !!localStorage.getItem('token');
    if (isLoggedIn && location.pathname === `/${LoginStep.trial}`) {
      return;
    }

    if (!!token && [`/${LoginStep.signUp}`, `/${LoginStep.auth}`].includes(location.pathname)) {
      return;
    }

    if (
      !state.email &&
      !loginResponse?.sessionToken &&
      location.pathname !== `/${LoginStep.welcome}`
    ) {
      history.push(`/${LoginStep.welcome}`);
    }
  }, [state.email, location.pathname, token]);

  useEffect(() => {
    if (location.pathname === `/${LoginStep.welcome}`) {
      resetState();
    }
  }, [location.pathname]);

  useEffect(() => {
    if (!!token && [`/${LoginStep.auth}`].includes(location.pathname)) {
      authService.loginWithToken(token as string, AuthHandlerType.MICROSOFT).catch(() => {
        history.push(`/${LoginStep.welcome}`);
      });
    }
  }, [location.pathname, token]);

  if (step === LoginStep.auth) {
    return <BasePreloaderScreen location={location} />;
  }

  return (
    <div className="login-page__container">
      <LoginPageBackground />
      <LoginPageGradient />
      <div className="login-page">
        <div className="login-modal">
          <div
            className={cn('login-container', {
              'login-container--narrow': step === LoginStep.trial,
            })}
            style={{ height: getContainerMaxHeight(step) }}
          >
            {step !== LoginStep.trial && (
              <img
                alt="Respona"
                onClick={() => window.open('https://respona.com', '_self')}
                src={responaLogo}
                className="login-container__logo"
              />
            )}

            {step === LoginStep.welcome && (
              <LoginSignupFlow
                state={state}
                isLoading={isLoading}
                handleInput={(...data) => {
                  setEmailVerification(null);
                  handleInput(...data);
                }}
                onSubmit={() => completeStep(LoginStep.welcome)}
                setIsLoading={setIsLoading}
                verification={emailVerification}
                setLoginResponse={setLoginResponse}
                history={history}
              />
            )}

            {step === LoginStep.login && (
              <LoginFlow
                state={state}
                isLoading={isLoading}
                handleInput={handleInput}
                onSubmit={() => completeStep(LoginStep.login)}
                history={history}
              />
            )}

            {step === LoginStep.signUp && (
              <SignUpFlow
                state={state}
                isLoading={isLoading}
                handleInput={handleInput}
                onSubmit={() => completeStep(LoginStep.signUp)}
                history={history}
              />
            )}

            {step === LoginStep.confirm && (
              <CodeConfirmationFlow
                state={state}
                isLoading={isLoading}
                handleInput={handleInput}
                onSubmit={() => completeStep(LoginStep.confirm)}
                history={history}
              />
            )}

            {step === LoginStep.onboarding && (
              <OnboardingSessionFlow
                state={state}
                isLoading={isLoading}
                updateRef={updateRef}
                onSubmit={() => completeStep(LoginStep.onboarding)}
                history={history}
              />
            )}

            {step === LoginStep.forgotPassword && (
              <RequestUpdatePasswordFlow
                state={state}
                isLoading={isLoading}
                handleInput={handleInput}
                onSubmit={() => completeStep(LoginStep.forgotPassword)}
                history={history}
              />
            )}
            {step === LoginStep.restore && (
              <UpdatePasswordFlow
                state={state}
                isLoading={isLoading}
                handleInput={handleInput}
                onSubmit={() => completeStep(LoginStep.restore)}
                history={history}
              />
            )}

            {step === LoginStep.trial && (
              <>
                <div
                  className="login-container__logout-button"
                  style={{ backgroundColor: '#fff' }}
                  onClick={() => {
                    setIsLoading(true);
                    authService.logout().finally(() => {
                      setIsLoading(false);
                    });
                  }}
                >
                  <QuestionTooltip
                    text={isLoading ? 'Logging out...' : 'Log out'}
                    style={{ width: '100%', height: '100%' }}
                    icon={isLoading ? null : 'logout'}
                    color="#221CB6"
                  >
                    <Loader isLoading style={{ width: '16px', height: '16px' }} />
                  </QuestionTooltip>
                </div>
                <LoginPagePaymentFlow />
              </>
            )}
          </div>

          {isSuccess && state.showCaptcha ? (
            <div className="register-page-content__recaptcha-wrapper">
              <ReCAPTCHA
                sitekey="6LdTIMQUAAAAAEAEI73rFGj7DT0T2MpafqvaEpY4"
                onChange={(value) => handleInput('captcha', value)}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default NewLoginPage;
