import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';

import { components } from 'react-select';
import Select from '@uikit/Select/Select';
import { getCurrentWorkspaceId } from '@redux/selectors/workspaces.selectors';
import { getEmailSuggestionsApi } from '@api/campaign.api';
import Loader from '@uikit/Loader/Loader';

import AutomationPositionTooltipContent from '@components/CampaignSettings/ContactAutomationSetup/_components/AutomationPositionTooltipContent/AutomationPositionTooltipContent';

const optionsTransformDefault = (emails) =>
  emails.map(({ email }) => ({
    label: email,
    value: email,
  }));

export default (label = '', key: string, suggestFieldName = '') => ({
  type: 'select',
  label,
  key,
  Renderer: ({ state, onChange, value, ...props }: any): JSX.Element => {
    const curWorkspaceId = useSelector(getCurrentWorkspaceId);
    const [inputValue, changeInputValue] = useState<string>('');
    const [save, changeSave] = useState<string>('');

    const {
      data: emailSuggestion = { bccEmailsList: [], ccEmailsList: [] },
      isLoading,
      isIdle,
    } = useQuery(
      ['emailSuggestion', curWorkspaceId],
      () => {
        return getEmailSuggestionsApi(curWorkspaceId);
      },
      {
        enabled: !!curWorkspaceId,
      }
    );

    const handleCreate = (newValue) => onChange([...value, { value: newValue, label: newValue }]);
    const handleChange = (newValue) => changeInputValue(newValue);
    const handleSave = () => changeSave(inputValue);

    const validateEmail = (emailString) => {
      const result = emailString.split('@');
      if (result.length < 2 || result[0].length === 0 || result[1].length === 0) {
        return false;
      }

      const checkDot = result[1].split('.');

      return !(checkDot.length < 2 || checkDot[0].length === 0 || checkDot[1].length === 0);
    };
    /*
      To insert saved value after blur - to input
     */
    useEffect(() => {
      changeInputValue(save);
    }, [save]);

    if (isLoading || isIdle) {
      return <Loader isLoading />;
    }

    const options = emailSuggestion[suggestFieldName].length
      ? optionsTransformDefault(emailSuggestion[suggestFieldName])
      : [];

    return (
      <Select
        {...props}
        placeholder="Select emails"
        isCreatableSelect
        isValidNewOption={validateEmail}
        onCreateOption={handleCreate}
        formatCreateLabel={(string) => `Add ${string}`}
        onChange={onChange}
        onBlur={handleSave}
        isMulti
        value={value}
        options={options}
        visibleMultiCount={1}
        allowCustomInput
        inputValue={inputValue}
        onInputChange={handleChange}
        additionalComponents={{
          DropdownIndicator: () => null,
          NoOptionsMessage: () => null,
          Menu: !options?.length && !validateEmail(inputValue) ? () => null : components.Menu,
        }}
        closeMenuOnSelect={false}
        renderMultipleValuesTooltipContent={AutomationPositionTooltipContent}
      />
    );
  },
});
