import React from 'react';

import {
  IntegrationTypeMap,
  IntegrationStatus as INTEGRATION_STATUS,
} from 'respona_api/generated/common_pb';

import { integrationsService } from 'src/services/integrationsService';

import { IntegrationType } from '@ts/userOrganization.types';

import stripHtml from '@utils/stripHtml';

import { Tr } from '@uikit/Table/Table';
import Loader from '@uikit/Loader/Loader';
import Avatar from '@uikit/Avatar/Avatar';
import SlideToggler from '@uikit/SlideToggler/SlideToggler';

import { integrationsSettingsMap } from '@components/Settings/Integrations/_components/IntegrationEditingSidebar/integrationsSettingsMap';

import IntegrationStatus from '@components/Settings/Integrations/_components/IntegrationStatus/IntegrationStatus';
import ActionsMenu from '@components/ActionsMenu/ActionsMenu';
import Display from '@components/Display/Display';

type TableRenderProps = {
  row: IntegrationType;
  // eslint-disable-next-line react/no-unused-prop-types
  children?: any;
  // eslint-disable-next-line react/no-unused-prop-types
  renderProps?: {
    [key: string]: any;
  };
};

const integrationsTableConfig = [
  {
    title: '',
    dataKey: 'integrationSwitch',
    cellClassName: 'integrations-table__switch-cell',
    RenderCell: ({ row, renderProps: { onUpdate, onRemove } }: TableRenderProps): JSX.Element => {
      const onChange = () => {
        const settings = {
          workspaceIdsList: row.workspaceIdsList,
          settings: {
            ...integrationsService.getIntegrationSettings(row),
          },
        };

        if (row.status === INTEGRATION_STATUS.ACTIVE_INTEGRATION) {
          onUpdate.mutate({
            id: row.id,
            type: row.type,
            data: { status: INTEGRATION_STATUS.PAUSED_INTEGRATION, ...settings },
          });
        } else if (row.status === INTEGRATION_STATUS.PAUSED_INTEGRATION) {
          onUpdate.mutate({
            id: row.id,
            type: row.type,
            data: { status: INTEGRATION_STATUS.ACTIVE_INTEGRATION, ...settings },
          });
        }
      };

      const isLoading =
        (onRemove.isLoading && onRemove.variables?.id === row.id) ||
        (onUpdate.isLoading && onUpdate.variables?.id === row.id);

      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Loader style={{ width: '15px', height: '15px' }} isLoading={isLoading}>
            <SlideToggler
              value={row.status === INTEGRATION_STATUS.ACTIVE_INTEGRATION}
              isDisabled={
                ![
                  INTEGRATION_STATUS.ACTIVE_INTEGRATION,
                  INTEGRATION_STATUS.PAUSED_INTEGRATION,
                  //  @ts-ignore
                ].includes(row.status)
              }
              onChange={onChange}
            />
          </Loader>
        </div>
      );
    },
  },
  {
    title: 'App',
    dataKey: 'title',
    RenderTitle: (): JSX.Element => {
      return <div className="integrations-table__app-title-cell">App</div>;
    },
    RenderCell: ({ row: { title, type, description } }: TableRenderProps): JSX.Element => {
      const subjectText = stripHtml(description);
      return (
        <div className="integrations-table__app-info-cell">
          <Avatar
            wrapperClassName="integrations-table__app-info-avatar"
            className="integrations-table__app-info-avatar"
            url={getIntegrationLogo(type)}
            fullName="App"
          />
          <div className="title-cell">
            <div className="title-cell__title" title={title}>
              {title}
            </div>
            <Display isVisible={subjectText.trim().length > 0}>
              <div className="title-cell__subtitle" title={subjectText}>
                {subjectText}
              </div>
            </Display>
          </div>
        </div>
      );
    },
  },
  {
    title: 'Status',
    dataKey: 'status',
    RenderCell: ({ row: { status } }: TableRenderProps): JSX.Element => (
      <div className="delivered-cell">
        <IntegrationStatus status={status} />
      </div>
    ),
  },
  {
    title: '',
    dataKey: 'actions',
    RenderCell: ({ row, renderProps: { onOpen, onRemove } }: TableRenderProps): JSX.Element => {
      const handleRemove = () => onRemove.mutate({ id: row.id });

      if (row.id < 0) {
        return null;
      }

      return (
        <ActionsMenu
          id={`integrations-actions-${row.title}`}
          actionsConfig={[
            {
              title: 'Edit',
              icon: 'pen',
              handler: () => onOpen(row),
            },
          ]}
          onRemove={handleRemove}
          tooltipPlace="left"
        />
      );
    },
  },
];

export default integrationsTableConfig;

const getIntegrationLogo = (type: IntegrationTypeMap[keyof IntegrationTypeMap]) =>
  integrationsSettingsMap[type].logo;

export const RenderRow = ({
  children,
  row,
  renderProps: { onOpen },
}: TableRenderProps): JSX.Element => {
  const isIntegrationConnected = row.id !== -1;
  const handleClick = () =>
    !isIntegrationConnected && integrationsSettingsMap[row.type].link
      ? window.open(integrationsSettingsMap[row.type].link)
      : onOpen(row);

  return (
    <Tr onClick={handleClick} className="integrations-table__row">
      {children}
    </Tr>
  );
};
