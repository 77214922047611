import { combineReducers } from 'redux';

import sequence, { SequenceStateType } from '@redux/reducers/sequence.reducer';
import automationContent, {
  AutomationContentStateType,
} from '@redux/reducers/automationContent.reducer';
import pipelines, { PipelinesStateType } from '@redux/reducers/pipelines.reducer';
import automationPersonalization, {
  PersonalizationAutomationStateType,
} from '@redux/reducers/automationPersonalization.reducer';
import inbox, { InboxStateType } from '@redux/reducers/inbox.reducer';
import opportunities from '@redux/reducers/opportunities.reducer';
import { OpportunityShortType } from '@ts/opportunity.types';
import fileAttributes, { FileAttributesStateType } from '@redux/reducers/fileAttributes.reducer';
import unsubscribes, { UnsubscribesStateType } from '@redux/reducers/unsubscribes.reducer';
import appState, { AppStateType } from './appState.reducer';
import workspaces, { WorkspacesStateType } from './workspaces.reducer';
import campaigns, { CampaignsStateType } from './campaigns.reducer';
import campaignSettings, { CampaignSettingsStateType } from './campaignSettings.reducer';
import templates, { TemplatesStateType } from './templates.reducer';
import schedules, { ScheduleStateType } from './schedules.reducer';
import insights, { InsightsStateType } from './insights.reducer';
import integrations, { IntegrationsStateType } from './integrations.reducer';

import automationContact, { CampaignAutomationStateType } from './automationContact.reducer';
import campaignConfirmContactsManual, {
  CampaignManualContactsSearchStateType,
} from './campaignConfirmContactsManual.reducer';

import articleSummary, { ArticleSummaryStateType } from './articleSummary.reducer';
import userProfile, { UserProfileStateType } from './userProfile.reducer';
import people, { PeopleStateType } from './people.reducer';
import companies, { CompaniesStateType } from './companies.reducer';
import notifications, { NotificationsStateType } from './notifications.reducer';
import campaignAnalytics, { CampaignAnalyticsStateType } from './campaignAnalytics.reducer';
import billings, { BillingsStateType } from './billings.reducer';
import appActions, { AppActionsStateType } from './appActions.reducer';
import inquiries, { InquiriesStateType } from './inquiries.reducer';
import commonInfo, { CommonInfoStateType } from './commonInfo.reducer';
import personalizations, { PersonalizationsStateType } from './personalizations.reducer';

export type StateType = {
  appState: AppStateType;
  workspaces: WorkspacesStateType;
  campaigns: CampaignsStateType;
  campaignSettings: CampaignSettingsStateType;
  templates: TemplatesStateType;
  schedules: ScheduleStateType;
  automationContact: CampaignAutomationStateType;
  automationPersonalization: PersonalizationAutomationStateType;
  campaignConfirmContactsManual: CampaignManualContactsSearchStateType;
  articleSummary: ArticleSummaryStateType;
  userProfile: UserProfileStateType;
  people: PeopleStateType;
  companies: CompaniesStateType;
  notifications: NotificationsStateType;
  campaignAnalytics: CampaignAnalyticsStateType;
  billings: BillingsStateType;
  appActions: AppActionsStateType;
  inquiries: InquiriesStateType;
  commonInfo: CommonInfoStateType;
  personalizations: PersonalizationsStateType;
  sequence: SequenceStateType;
  insights: InsightsStateType;
  automationContent: AutomationContentStateType;
  inbox: InboxStateType;
  integrations: IntegrationsStateType;
  pipelines: PipelinesStateType;
  opportunities: OpportunityShortType;
  fileAttributes: FileAttributesStateType;
  unsubscribes: UnsubscribesStateType;
};

export default combineReducers({
  appState,
  workspaces,
  campaigns,
  campaignSettings,
  templates,
  schedules,
  automationContact,
  campaignConfirmContactsManual,
  articleSummary,
  userProfile,
  people,
  companies,
  notifications,
  campaignAnalytics,
  billings,
  appActions,
  inquiries,
  commonInfo,
  personalizations,
  sequence,
  insights,
  automationContent,
  inbox,
  integrations,
  pipelines,
  opportunities,
  fileAttributes,
  automationPersonalization,
  unsubscribes,
});
