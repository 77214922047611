import automationPersonalizationService from '@api/services/automationPersonalizationService';
import processStreamRequest from '@api/base/processStreamRequest';

import {
  OpportunityPersonalizationType,
  PersonalizationAutomationSettings,
} from '@ts/automationPersonalizations.types';
import { OpenAIModelTypeMap, OpenAIToneMap } from 'respona_api/generated/common_pb';
import AutomationPersonModels, {
  LaunchPersonalizationAutomationRequest,
  PersonalizationAutomationStrategyMap,
} from 'respona_api/generated/automation-personalization_pb';

export const personalizeOneOpportunity = (
  campaignId: number,
  workspaceId: number,
  sequenceId: number,
  opportunityId: number,
  opportunityUrl: string,
  model: OpenAIModelTypeMap[keyof OpenAIModelTypeMap],
  tone: OpenAIToneMap[keyof OpenAIToneMap],
  includeSummaries: boolean,
  strategy: PersonalizationAutomationStrategyMap[keyof PersonalizationAutomationStrategyMap],
  instructions: string[],
  processCallback: any
): Promise<OpportunityPersonalizationType> => {
  const client = automationPersonalizationService.getInstance();
  const requestModel = new AutomationPersonModels.OpportunityPersonalizationRequest();

  requestModel.setCampaignId(campaignId);
  requestModel.setWorkspaceId(workspaceId);
  requestModel.setSequenceId(sequenceId);
  requestModel.setOpportunityId(opportunityId);
  requestModel.setOpportunityUrl(opportunityUrl);
  requestModel.setStrategy(strategy);

  const settingsModel = new AutomationPersonModels.PersonalizationAISettings();
  settingsModel.setModel(model);
  settingsModel.setIncludeSummaries(includeSummaries);
  settingsModel.setTone(tone);

  instructions.forEach((value, index) => {
    const req = new AutomationPersonModels.InstructionPair();
    req.setOrder(index);
    req.setValue(value);
    settingsModel.addInstruction(req);
  });

  requestModel.setSettings(settingsModel);

  return processStreamRequest<OpportunityPersonalizationType>(
    client,
    client.personalizeOpportunityVariables,
    requestModel,
    processCallback
  );
};

export const buildLaunchPersonalizationAutomationApiRequest = (
  campaignId: number,
  workspaceId: number,
  batch: number,
  sequenceId: number,
  automationSettings: PersonalizationAutomationSettings
): LaunchPersonalizationAutomationRequest => {
  const startAutomationRequest =
    new AutomationPersonModels.LaunchPersonalizationAutomationRequest();
  startAutomationRequest.setCampaignId(campaignId);
  startAutomationRequest.setWorkspaceId(workspaceId);
  startAutomationRequest.setSequenceId(sequenceId);
  startAutomationRequest.setCampaignBatch(batch);

  const settings = new AutomationPersonModels.PersonalizationAISettings();
  settings.setModel(automationSettings.model);
  settings.setTone(automationSettings.tone);
  settings.setIncludeSummaries(automationSettings.includeSummaries);
  const instructions = [];
  for (let i = 0; i < automationSettings.instructions.length; i++) {
    const item = new AutomationPersonModels.InstructionPair();
    item.setOrder(i + 1);
    item.setValue(automationSettings.instructions[i]);
    instructions.push(item);
  }
  settings.setInstructionList(instructions);
  startAutomationRequest.setSettings(settings);

  return startAutomationRequest;
};
