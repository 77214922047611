import React from 'react';

import ReactTooltip from 'react-tooltip';

import './InquiryPitchSummaryInfoCategories.scss';

const InquiryPitchSummaryInfoCategories = ({ inquiryCategories }) => {
  return (
    <>
      {inquiryCategories.length < 4 ? (
        <>
          {inquiryCategories.map((category, index) => (
            <p key={category} title={category} className="inquiry-pitch-summary-info__data-value">
              {index !== 0 ? `, ${category}` : category}
            </p>
          ))}
        </>
      ) : (
        <>
          <p className="categories-number-title" data-for="categories-tooltip" data-tip="">
            {inquiryCategories.length} categories
          </p>
          <ReactTooltip
            id="categories-tooltip"
            place="bottom"
            effect="solid"
            globalEventOff="click"
            arrowColor="transparent"
          >
            <div className="categories-wrapper">
              {inquiryCategories.map((category) => (
                <p key={category} title={category} className="categories-wrapper__value">
                  {category}
                </p>
              ))}
            </div>
          </ReactTooltip>
        </>
      )}
    </>
  );
};

export default InquiryPitchSummaryInfoCategories;
