import { ScheduleType, ScheduleDayType } from '../../../types/schedules.types';
import { getDefaultTimezone } from '../../../constants/timezones';

export const daysTitles = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
export const defaultDayFrom = '09:00:00';
export const defaultDayTo = '17:00:00';
export const daysHours = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

export const getDefaultDatList = (): ScheduleDayType[] =>
  daysTitles.map((day, index) => ({
    day: index,
    dayTitle: day,
    includeDay: index <= 4,
    timeFrom: defaultDayFrom,
    timeTo: defaultDayTo,
  }));

export const getDefaultSchedule = (): ScheduleType => {
  const { id, value } = getDefaultTimezone();

  return {
    dayList: getDefaultDatList(),
    deleted: false,
    title: 'New schedule',
    type: 1,
    timezoneId: id,
    timezoneValue: value,
  };
};
