import React from 'react';

import { PRIMARY_COLOR } from '@constants/colors';

export default ({ color = PRIMARY_COLOR, size = 20 }) => (
  <svg width={size} height={size} viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 3C1 1.89543 1.89543 1 3 1H15C16.1046 1 17 1.89543 17 3V14C17 15.1046 16.1046 16 15 16H10.5452C10.021 16 9.51773 16.2058 9.14375 16.5731L7.14939 18.5318C6.21476 19.4498 4.63636 18.7877 4.63636 17.4776V17.4776C4.63636 16.6616 3.9748 16 3.15873 16H2.81818C1.81403 16 1 15.186 1 14.1818V3Z"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);
