import React, { useState } from 'react';

import SlideToggler from '@uikit/SlideToggler/SlideToggler';

import { subscriptionsConfig } from '@components/Settings/Profile/subscriptionsConfig';

const EmailSubscriptionItem = ({ type, onToggle, isDisabled }) => {
  const [status, changeStatus] = useState(!isDisabled);
  const [isEditable, changeIsEditable] = useState(true);

  const handleToggle = () => {
    if (isEditable) {
      changeStatus((prev) => !prev);
      changeIsEditable(false);
      onToggle(status).then(() => changeIsEditable(true));
    }
  };

  return (
    <div className="profile-settings__email-subscription-item">
      <div className="profile-settings__email-subscription-item-description">
        <div className="profile-settings__email-subscription-item-header">
          {subscriptionsConfig[type].title}
        </div>
        <div className="profile-settings__email-subscription-item-text">
          {subscriptionsConfig[type].description}
        </div>
      </div>
      <div className="profile-settings__email-subscription-item-toogler">
        <SlideToggler value={status} onChange={handleToggle} />
      </div>
    </div>
  );
};

export default EmailSubscriptionItem;
