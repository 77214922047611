import React from 'react';
import { nanoid } from 'nanoid';

export default ({
  color = '#C4C6CD',
  size = 14,
}: {
  color?: string;
  size?: number;
}): JSX.Element => {
  const id = nanoid();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#${id})`}>
        <path
          d="M13.4408 3.33328C12.7341 2.62475 11.7974 2.19231 10.7997 2.11406C9.80208 2.03581 8.80934 2.31692 8.00083 2.90661C7.15261 2.27571 6.09683 1.98962 5.04611 2.10597C3.9954 2.22232 3.02779 2.73247 2.33815 3.53367C1.64851 4.33488 1.28805 5.36763 1.32937 6.42396C1.37069 7.48029 1.81071 8.48173 2.56083 9.22661L6.70084 13.3733C7.04751 13.7145 7.51443 13.9057 8.00083 13.9057C8.48724 13.9057 8.95416 13.7145 9.30083 13.3733L13.4408 9.22661C14.2192 8.44346 14.6561 7.38413 14.6561 6.27995C14.6561 5.17577 14.2192 4.11644 13.4408 3.33328ZM12.5008 8.30661L8.36084 12.4466C8.31372 12.4942 8.25765 12.5319 8.19586 12.5577C8.13407 12.5835 8.06778 12.5968 8.00083 12.5968C7.93389 12.5968 7.8676 12.5835 7.80581 12.5577C7.74402 12.5319 7.68795 12.4942 7.64083 12.4466L3.50083 8.28661C2.97801 7.75218 2.68524 7.03426 2.68524 6.28661C2.68524 5.53897 2.97801 4.82105 3.50083 4.28661C4.03361 3.76061 4.75215 3.46566 5.50083 3.46566C6.24952 3.46566 6.96806 3.76061 7.50083 4.28661C7.56281 4.3491 7.63654 4.3987 7.71778 4.43254C7.79902 4.46639 7.88616 4.48381 7.97417 4.48381C8.06218 4.48381 8.14931 4.46639 8.23055 4.43254C8.31179 4.3987 8.38553 4.3491 8.4475 4.28661C8.98027 3.76061 9.69882 3.46566 10.4475 3.46566C11.1962 3.46566 11.9147 3.76061 12.4475 4.28661C12.9775 4.81405 13.2799 5.52807 13.2899 6.27574C13.2998 7.0234 13.0166 7.74524 12.5008 8.28661V8.30661Z"
          fill={color}
        />
      </g>
    </svg>
  );
};
