import checkIsHtmlString from '@utils/checkIsHtmlString';
import { initialValue } from '@uikit/RichEditor/slateUtils';
import { deserialize } from '@uikit/RichEditor/utils/deserialize';

export const prepareMessageValueForEditor = (message: string) => {
  const parsed = new DOMParser().parseFromString(
    checkIsHtmlString(message) ? message : `<p><span>${message}</span></p>`,
    'text/html'
  );
  const fragment = deserialize(parsed.body);
  return fragment.length ? fragment : initialValue;
};
