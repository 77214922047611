import React from 'react';
import cn from 'class-names';

import { BillingStateType } from '@ts/billing.types';

import {
  BillingState,
  BillingSubscriptionType,
  BillingSubscriptionTypeMap,
} from 'respona_api/generated/billing_pb';

import './SubscriptionTypeBadge.scss';

const subscriptionTypeTitle = {
  [BillingSubscriptionType.STARTER]: 'Starter',
  [BillingSubscriptionType.UNLIMITED]: 'Unlimited',
  [BillingSubscriptionType.TRIAL]: 'Trial',
  [BillingSubscriptionType.MAINTENANCE]: 'Maintenance',
  [BillingSubscriptionType.INTERNAL]: 'Internal',
  [BillingSubscriptionType.PRO]: 'Pro',
  [BillingSubscriptionType.ENTERPRISE]: 'Enterprise',
};

const SubscriptionTypeBadge = ({
  type,
  state,
  style,
}: {
  type: BillingSubscriptionTypeMap[keyof BillingSubscriptionTypeMap];
  state: BillingStateType;
  style?: React.CSSProperties;
}): JSX.Element => {
  return (
    <div
      style={style}
      className={cn('billing-subscription-type-badge', {
        'billing-subscription-type-badge--purple': type === BillingSubscriptionType.TRIAL,
        'billing-subscription-type-badge--green':
          type === BillingSubscriptionType.STARTER ||
          type === BillingSubscriptionType.UNLIMITED ||
          type === BillingSubscriptionType.PRO ||
          type === BillingSubscriptionType.ENTERPRISE,
        'billing-subscription-type-badge--yellow': type === BillingSubscriptionType.MAINTENANCE,
        'billing-subscription-type-badge--red': state === BillingState.BILLING_CANCELED,
      })}
    >
      {BillingState.BILLING_CANCELED === state ? 'Cancelled' : subscriptionTypeTitle[type]}
    </div>
  );
};

export default SubscriptionTypeBadge;
