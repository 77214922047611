export class ApiError extends Error {
  public readonly code: number;

  public readonly originalError: { [key: string]: any };

  constructor(message: string, code: number, originalError: { [key: string]: any }) {
    super(message);
    this.message = message;
    this.code = code;
    this.originalError = originalError;
  }
}
