import { BillingTypeCheckboxComponent } from '@components/Settings/SubscriptionForm/_components/BillingTypeCheckboxes/BillingTypeCheckboxes';
import { roundSubscriptionCredits, subscriptionLimits } from '@helpers/subscriptionHelpers';
import { addNotification } from '@redux/actions/notifications.actions';
import { SVGIcon } from '@uikit/Icon/Icon';
import PoorNumberInput from '@uikit/PoorNumberInput/PoorNumberInput';
import cn from 'class-names';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  BillingSubscriptionCycle,
  BillingSubscriptionType,
} from 'respona_api/generated/billing_pb';
import { DispatchType } from 'src/store';

import './SubscriptionTypeBar.scss';
import ReactTooltip from 'react-tooltip';

const SHOW_STARTER_TAB = true;
const SHOW_ENTERPRISE_TAB = false;

function SubscriptionTypeBar({ values, onChangeValues, presetData, billingDetails }) {
  const dispatch = useDispatch<DispatchType>();

  const isMonthInterval = values.billingInterval === BillingSubscriptionCycle.MONTHLY;
  const isStarterPlan = values.type === BillingSubscriptionType.STARTER;
  const isProPlan = values.type === BillingSubscriptionType.PRO;

  const onEmailAccountChangeFailed = (value: number) => {
    if (value < billingDetails.currentMailboxAccountsNumber) {
      dispatch(
        addNotification({
          title:
            'Please disconnect the emails from the "All Email Accounts" tab first, before downgrading.',
          type: 'warning',
        })
      );
    }
  };

  const [standardCreditsInputValue, setStandardCreditsInputValue] = useState<number>(
    subscriptionLimits[BillingSubscriptionType.STARTER][values.billingInterval].minCredits
  );
  const [unlimitedCreditsInputValue, setUnlimitedCreditsInputValue] = useState<number>(
    subscriptionLimits[BillingSubscriptionType.UNLIMITED][values.billingInterval].minCredits
  );
  const [proCreditsInputValue, setProCreditsInputValue] = useState<number>(
    subscriptionLimits[BillingSubscriptionType.PRO][values.billingInterval].minCredits
  );

  useEffect(() => {
    if (billingDetails != null) {
      setStandardCreditsInputValue(
        Math.max(
          billingDetails.maxPaidCreditsNumber,
          subscriptionLimits[BillingSubscriptionType.STARTER][values.billingInterval].minCredits
        )
      );
      setProCreditsInputValue(
        Math.max(
          billingDetails.maxPaidCreditsNumber,
          subscriptionLimits[BillingSubscriptionType.PRO][values.billingInterval].minCredits
        )
      );
      setUnlimitedCreditsInputValue(
        Math.max(
          billingDetails.maxPaidCreditsNumber,
          subscriptionLimits[BillingSubscriptionType.UNLIMITED][values.billingInterval].minCredits
        )
      );
    }
  }, [billingDetails]);

  useEffect(() => {
    if (!values.creditsCount) return;

    setStandardCreditsInputValue(values.creditsCount);
  }, [values.creditsCount]);

  useEffect(() => {
    if (!values.creditsCountUnlimited) return;

    setUnlimitedCreditsInputValue(values.creditsCountUnlimited);
  }, [values.creditsCountUnlimited]);

  useEffect(() => {
    if (!values.creditsCountPro) return;

    setProCreditsInputValue(values.creditsCountPro);
  }, [values.creditsCountPro]);

  return (
    <div className="subscription-type-bar-container">
      {SHOW_STARTER_TAB && (
        <div
          className={cn('subscription-type-bar', {
            'subscription-type-bar--active': isStarterPlan,
          })}
          onClick={() => onChangeValues('type', BillingSubscriptionType.STARTER)}
        >
          <div className="subscription-type-bar__title">
            <BillingTypeCheckboxComponent
              className="subscription-type-bar__checkbox"
              title="Starter"
              isChecked={isStarterPlan}
              onChange={() => null}
            />
            <div
              className="subscription-type-bar__title__features"
              data-for="featureStarterPlan"
              data-tip=""
            >
              <SVGIcon icon="questionMark" /> Features
            </div>
            <ReactTooltip
              id="featureStarterPlan"
              className="react-tooltip"
              place="bottom"
              effect="solid"
            >
              <div className="subscription-type-bar__title__features_description">
                <div className="subscription-type-bar__title__features_description__item">
                  <SVGIcon icon="blueMark" />
                  Unlimited emails sent
                </div>
                <div className="subscription-type-bar__title__features_description__item">
                  <SVGIcon icon="blueMark" />
                  Unlimited campaigns
                </div>
                <div className="subscription-type-bar__title__features_description__item">
                  <SVGIcon icon="blueMark" />
                  Unlimited users
                </div>
                <div className="subscription-type-bar__title__features_description__item">
                  <SVGIcon icon="blueMark" />
                  30k active contacts
                </div>
              </div>
            </ReactTooltip>
          </div>
          <div className="subscription-type-bar__content">
            <div className="subscription-form__param">
              <div className="subscription-form__param-title">Email accounts</div>

              <div className="subscription-form__param-field">
                <PoorNumberInput
                  onlyPositive
                  value={values.emailAccountsCount}
                  onChange={(data) => onChangeValues('emailAccountsCount', data)}
                  onChangeFailed={onEmailAccountChangeFailed}
                  min={Math.max(
                    subscriptionLimits[BillingSubscriptionType.STARTER][values.billingInterval]
                      .minEmails,
                    billingDetails.currentMailboxAccountsNumber
                  )}
                  max={50}
                />
              </div>
              <div className="subscription-form__param-label">
                $
                {isMonthInterval
                  ? `${presetData?.mailboxAccountMonthlyPrice}/mo `
                  : `${presetData?.mailboxAccountYearlyPrice}/yr `}
                per account
              </div>
            </div>

            <div className="subscription-form__param">
              <div className="subscription-form__param-title">
                Credits/{isMonthInterval ? 'month' : 'year'}
              </div>
              <div className="subscription-form__param-field">
                <PoorNumberInput
                  onlyPositive
                  value={standardCreditsInputValue}
                  onChange={(value) => {
                    setStandardCreditsInputValue(value);
                  }}
                  min={Math.max(
                    subscriptionLimits[BillingSubscriptionType.STARTER][values.billingInterval]
                      .minCredits
                  )}
                  max={
                    subscriptionLimits[BillingSubscriptionType.STARTER][values.billingInterval]
                      .maxCredits
                  }
                  step={values.billingInterval === BillingSubscriptionCycle.YEARLY ? 60000 : 5000}
                  onBlur={(value) => {
                    const creditsNumber = roundSubscriptionCredits(
                      value,
                      values.billingInterval,
                      values.type
                    );
                    setStandardCreditsInputValue(creditsNumber);
                    onChangeValues('creditsCount', creditsNumber);
                  }}
                  capLimits={false}
                />
              </div>
              <div className="subscription-form__param-label">
                $
                {isMonthInterval ? presetData?.creditsMonthlyPrice : presetData?.creditsYearlyPrice}{' '}
                per credit
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        className={cn('subscription-type-bar', {
          'subscription-type-bar--active': isProPlan,
        })}
        onClick={() => onChangeValues('type', BillingSubscriptionType.PRO)}
      >
        <div className="subscription-type-bar__title">
          <BillingTypeCheckboxComponent
            className="subscription-type-bar__checkbox"
            title="Pro"
            isChecked={isProPlan}
            onChange={() => null}
          />
          {/* <div className="subscription-type-bar__title__features-all">All features included</div> */}
          <div
            className="subscription-type-bar__title__features"
            data-for="featureProPlan"
            data-tip=""
          >
            <SVGIcon icon="questionMark" /> Features
          </div>
          <ReactTooltip id="featureProPlan" className="react-tooltip" place="bottom" effect="solid">
            <div className="subscription-type-bar__title__features_description">
              <div className="subscription-type-bar__title__features_description__item">
                <SVGIcon icon="blueMark" />
                Recurring campaigns
              </div>
              <div className="subscription-type-bar__title__features_description__item">
                <SVGIcon icon="blueMark" />
                AI email personalization
              </div>
              <div className="subscription-type-bar__title__features_description__item">
                <SVGIcon icon="blueMark" />
                Advanced reporting
              </div>
              <div className="subscription-type-bar__title__features_description__item">
                <SVGIcon icon="blueMark" />
                50k active contacts
              </div>
            </div>
          </ReactTooltip>
        </div>
        <div className="subscription-type-bar__content">
          <div className="subscription-form__param">
            <div className="subscription-form__param-title">Email accounts</div>

            <div className="subscription-form__param-field">
              <PoorNumberInput
                onlyPositive
                value={values.emailAccountsCountPro}
                onChange={(data) => onChangeValues('emailAccountsCountPro', data)}
                onChangeFailed={onEmailAccountChangeFailed}
                min={Math.max(
                  billingDetails.currentMailboxAccountsNumber,
                  subscriptionLimits[BillingSubscriptionType.PRO][values.billingInterval].minEmails
                )}
                max={
                  subscriptionLimits[BillingSubscriptionType.PRO][values.billingInterval].maxEmails
                }
              />
            </div>
            <div className="subscription-form__param-label">
              $
              {isMonthInterval
                ? `${presetData?.mailboxAccountMonthlyPrice}/mo `
                : `${presetData?.mailboxAccountYearlyPrice}/yr `}
              per account
            </div>
          </div>

          <div className="subscription-form__param">
            <div className="subscription-form__param-title">
              Credits/{isMonthInterval ? 'month' : 'year'}
            </div>
            <div className="subscription-form__param-field">
              <PoorNumberInput
                onlyPositive
                value={proCreditsInputValue}
                onChange={(value) => setProCreditsInputValue(value)}
                min={Math.max(
                  subscriptionLimits[BillingSubscriptionType.PRO][values.billingInterval].minCredits
                )}
                max={
                  subscriptionLimits[BillingSubscriptionType.PRO][values.billingInterval].maxCredits
                }
                step={values.billingInterval === BillingSubscriptionCycle.YEARLY ? 60000 : 5000}
                onBlur={(value) => {
                  const creditsNumber = roundSubscriptionCredits(
                    value,
                    values.billingInterval,
                    values.type
                  );
                  setProCreditsInputValue(creditsNumber);
                  onChangeValues('creditsCountPro', creditsNumber);
                }}
                capLimits={false}
              />
            </div>
            <div className="subscription-form__param-label">
              ${isMonthInterval ? presetData?.creditsMonthlyPrice : presetData?.creditsYearlyPrice}{' '}
              per credit
            </div>
          </div>
        </div>
      </div>

      <div
        className={cn('subscription-type-bar', {
          'subscription-type-bar--active': values.type === BillingSubscriptionType.UNLIMITED,
        })}
        onClick={() => onChangeValues('type', BillingSubscriptionType.UNLIMITED)}
      >
        <div className="subscription-type-bar__title">
          <BillingTypeCheckboxComponent
            className="subscription-type-bar__checkbox"
            title="Unlimited"
            isChecked={values.type === BillingSubscriptionType.UNLIMITED}
            onChange={() => null}
          />
          {/* <div className="subscription-type-bar__title__features-all">All features included</div> */}
          <div
            className="subscription-type-bar__title__features"
            data-for="featureUnlimitedPlan"
            data-tip=""
          >
            <SVGIcon icon="questionMark" /> Features
          </div>
          <ReactTooltip
            id="featureUnlimitedPlan"
            className="react-tooltip"
            place="bottom"
            effect="solid"
          >
            <div className="subscription-type-bar__title__features_description">
              <div className="subscription-type-bar__title__features_description__item">
                <SVGIcon icon="blueMark" />
                Unlimited email accounts
              </div>
              <div className="subscription-type-bar__title__features_description__item">
                <SVGIcon icon="blueMark" />
                White glove onboarding
              </div>
              <div className="subscription-type-bar__title__features_description__item">
                <SVGIcon icon="blueMark" />3 strategy sessions
              </div>
              <div className="subscription-type-bar__title__features_description__item">
                <SVGIcon icon="blueMark" />
                Priority support
              </div>
              <div className="subscription-type-bar__title__features_description__item">
                <SVGIcon icon="blueMark" />
                100k active contacts
              </div>
            </div>
          </ReactTooltip>
        </div>
        <div className="subscription-type-bar__content">
          <div className="subscription-form__param">
            <div className="subscription-form__param-title">Email accounts</div>

            <div className="subscription-form__param-unlimited-field">
              <SVGIcon icon="infinity" size={22} />
              <span>Unlimited</span>
            </div>
            <div className="subscription-form__param-label" />
          </div>

          <div className="subscription-form__param">
            <div className="subscription-form__param-title">Credits/year</div>
            <div className="subscription-form__param-field">
              <PoorNumberInput
                onlyPositive
                value={unlimitedCreditsInputValue}
                onChange={(value) => setUnlimitedCreditsInputValue(value)}
                min={Math.max(
                  subscriptionLimits[BillingSubscriptionType.UNLIMITED][values.billingInterval]
                    .minCredits
                )}
                max={
                  subscriptionLimits[BillingSubscriptionType.UNLIMITED][values.billingInterval]
                    .maxCredits
                }
                step={60000}
                onBlur={(value) => {
                  const creditsNumber = roundSubscriptionCredits(
                    value,
                    values.billingInterval,
                    values.type
                  );
                  setUnlimitedCreditsInputValue(creditsNumber);
                  onChangeValues('creditsCountUnlimited', creditsNumber);
                }}
                capLimits={false}
              />
            </div>
            <div className="subscription-form__param-label">
              ${presetData?.creditsYearlyPrice} per credit
            </div>
          </div>
        </div>
      </div>

      {SHOW_ENTERPRISE_TAB && (
        <div
          className={cn('subscription-type-bar', {
            'subscription-type-bar--active': values.type === BillingSubscriptionType.ENTERPRISE,
          })}
          onClick={() => onChangeValues('type', BillingSubscriptionType.ENTERPRISE)}
        >
          <div className="subscription-type-bar__title">
            <BillingTypeCheckboxComponent
              className="subscription-type-bar__checkbox"
              title="Enterprise"
              isChecked={values.type === BillingSubscriptionType.ENTERPRISE}
              onChange={() => null}
            />
            <div className="subscription-type-bar__title__features-all">Contact us</div>
            {/* <div */}
            {/*  className="subscription-type-bar__title__features" */}
            {/*  data-for="featureUnlimitedPlan" */}
            {/*  data-tip="" */}
            {/* > */}
            {/*  <SVGIcon icon="questionMark" /> Features */}
            {/* </div> */}
            {/* <ReactTooltip */}
            {/*  id="featureUnlimitedPlan" */}
            {/*  className="react-tooltip" */}
            {/*  place="bottom" */}
            {/*  effect="solid" */}
            {/* > */}
            {/*  <div className="subscription-type-bar__title__features_description"> */}
            {/*    <div className="subscription-type-bar__title__features_description__item"> */}
            {/*      <SVGIcon icon="blueMark" /> */}
            {/*      Priority support */}
            {/*    </div> */}
            {/*    <div className="subscription-type-bar__title__features_description__item"> */}
            {/*      <SVGIcon icon="blueMark" /> */}
            {/*      100k active contacts */}
            {/*    </div> */}
            {/*  </div> */}
            {/* </ReactTooltip> */}
          </div>
          <div className="subscription-type-bar__content">
            <div className="subscription-form__param">
              <div className="subscription-form__param-title">Email accounts</div>

              <div className="subscription-form__param-unlimited-field">
                <SVGIcon icon="infinity" size={22} />
                <span>Unlimited</span>
              </div>
              <div className="subscription-form__param-label" />
            </div>

            <div className="subscription-form__param">
              <div className="subscription-form__param-title">Credits/year</div>
              <div className="subscription-form__param-enterprise-field">
                <span>Custom</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SubscriptionTypeBar;
