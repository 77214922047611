import { Descendant } from 'slate';

export const updateSlateBlockByPath = (
  state: Descendant[],
  path: number[],
  newElemFields: unknown
): Descendant[] =>
  state.map((item, index) => {
    if (index === path[0]) {
      return {
        ...item,
        // @ts-ignore
        ...newElemFields,
      };
    }
    return item;
  });

const replaceAtTargetPath = (item, replaceCallback, path, pathIndex = 1) => {
  return {
    ...item,
    children: item.children.reduce((acc, curChildren, childrenIndex) => {
      if (childrenIndex === path[pathIndex]) {
        if (path[pathIndex + 1]) {
          return [...acc, replaceAtTargetPath(curChildren, replaceCallback, path, pathIndex + 1)];
        }
        return [...acc, { ...replaceCallback(curChildren) }];
      }

      return [...acc, curChildren];
    }, []),
  };
};

export const patchSlateElementByPath = (
  state: Descendant[],
  path: number[],
  newElemFields: unknown
): Descendant[] =>
  path
    ? state.map((item, index) => {
        if (index === path[0]) {
          return replaceAtTargetPath(
            item,
            (element) => ({ ...element, ...(newElemFields as Object) }),
            path
          );
        }
        return item;
      })
    : state;

export const putSlateElementByPath = (
  state: Descendant[],
  path: number[],
  newElemFields: unknown
): Descendant[] =>
  path
    ? state.map((item, index) => {
        if (index === path[0]) {
          return replaceAtTargetPath(
            item,
            () => ({ ...(newElemFields as Object) }),
            path
          );
        }
        return item;
      })
    : state;

/*
  This function maps all paragraphs (lines) in editor ->
  then will find line we need by first index in path ->
  then will find element we need by second index in path ->
  will take text from first children (that is because it was link)
  and return new element which is typed as text
 */
export const transformLinkToTextByPath = (state: Descendant[], path: number[]): Descendant[] => {
  if (!path) {
    return state;
  }

  return state.map((item, index) => {
    if (index === path[0]) {
      return replaceAtTargetPath(
        item,
        (element) => ({
          type: 'text',
          text: element.children?.[0]?.text || element.text,
        }),
        path
      );
    }

    return item;
  });
};
