import { PeopleFilterType } from '@ts/people.types';
import React, { useRef, useState } from 'react';

import { getBlobFromBase64 } from '@utils/createFileFromBytesArray';
import downloadCSV from '@utils/downloadCSV';
import RightSidebar from '@components/RightSidebar/RightSidebar';
import useOutsideClick from '@hooks/useOutsideClick';
import useHotkeys from '@hooks/useHotkeys';
import Checkbox from '@uikit/Checkbox/Checkbox';

import './ExportCsvSidebar.scss';
import { exportPeopleToCSVApi, ExportPeopleToCSVApiOptionsType } from '@api/people.api';
import { exportListOpportunitiesApi } from '@api/campaign.api';
import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';
import { downloadResponse } from '@helpers/fileHelpers';
import Display from '@components/Display/Display';
import { ExportPeopleRequest } from 'respona_api/generated/people_pb';

type ExportSidebarProps = {
  isOpen: boolean;
  onClose: () => void;
  selectedIds: number[];
  campaignId?: number | null;
  itemsNumber: number;
  filters?: PeopleFilterType[];
  searchQuery?: string;
  type: 'people' | 'opportunities';
  isSelectedAll?: boolean;
  sidebarExportType: ExportPeopleRequest.ExportTypeMap[keyof ExportPeopleRequest.ExportTypeMap];
};

const filtersCsvMapper = {
  people: {
    setUnverified: 'Unverified emails',
    setWork: 'Work emails',
    setPersonal: 'Personal emails',
    setGeneric: 'Generic emails',
  },
  opportunities: {},
};

function ExportCsvSidebar({
  selectedIds,
  campaignId = null,
  itemsNumber,
  isOpen,
  onClose,
  filters,
  searchQuery,
  type,
  isSelectedAll,
  sidebarExportType,
}: ExportSidebarProps): JSX.Element {
  const barRef = useRef<HTMLDivElement>();
  useOutsideClick(barRef, onClose);
  const currentWorkspaceId = useCurrentWorkspaceId();
  useHotkeys({ Escape: () => onClose() });
  const [isExportButtonLoading, changeIsExportButtonLoading] = useState<boolean>(false);

  const initialOptions = Object.keys(filtersCsvMapper[type]).reduce(
    (acc, item) => ({ ...acc, [item]: true }),
    {} as ExportPeopleToCSVApiOptionsType
  );

  const [options, changeOptions] = useState(initialOptions);

  const handleExport = () => {
    changeIsExportButtonLoading(true);

    if (type === 'people') {
      exportPeopleToCSVApi(sidebarExportType, options, selectedIds, filters, searchQuery, {
        campaignId,
      })
        .then((res) => {
          const { title, fileBytes } = res.find((item) => item.complete)?.file;

          if (!fileBytes) {
            throw new Error('No file was exported');
          }

          const fileBlob = getBlobFromBase64(fileBytes, 'text/csv');
          downloadCSV(title || `${type}-export-${Date.now()}`, fileBlob);
        })
        .finally(() => {
          changeIsExportButtonLoading(false);
        });
    } else {
      exportListOpportunitiesApi(
        campaignId,
        currentWorkspaceId,
        true,
        -1,
        isSelectedAll ? [] : selectedIds
      )
        .then((res) => {
          downloadResponse(res);
        })
        .finally(() => {
          changeIsExportButtonLoading(false);
        });
    }
  };

  return (
    <RightSidebar
      isOpen={isOpen}
      onClose={onClose}
      title={`Export ${type === 'people' ? 'Contacts' : 'Opportunities'}`}
      subtitle={`Export your ${type === 'people' ? 'contacts' : 'opportunities'} in a CSV file`}
      onSave={handleExport}
      isSaveButtonLoading={isExportButtonLoading}
      isSaveButtonDisabled={itemsNumber === 0}
      saveButtonLoadingText="Exporting..."
      buttonSaveText="Export"
      barRef={barRef}
    >
      <div className="export-people-sidebar">
        <div className="export-people-sidebar__header">
          <span>Total items to export</span>
          <br />
          <span className="export-people-sidebar__selected-text">
            {itemsNumber} {type}
          </span>
        </div>
        <Display isVisible={Object.keys(options).length > 0}>
          <div className="export-people-sidebar__filters">
            <span className="export-people-sidebar__filters-title">Include in export:</span>
            {Object.keys(options).map((key) => (
              <div className="export-people-sidebar__filters-row" key={key}>
                <Checkbox
                  onChange={() =>
                    changeOptions((prevState) => ({
                      ...prevState,
                      [key]: !prevState[key],
                    }))
                  }
                  value={options[key]}
                />
                <span className="export-people-sidebar__filters-row-text">
                  {filtersCsvMapper[type][key]}
                </span>
              </div>
            ))}
          </div>
        </Display>
      </div>
    </RightSidebar>
  );
}

export default ExportCsvSidebar;
