import React from 'react';

import EarnFreeCredits from '@components/Settings/EarnFreeCredits/EarnFreeCredits';
import PageHeader from '@components/PageHeader/PageHeader';

const EarnFreeCreditsSettings = () => {
  return (
    <>
      <PageHeader title="Earn free credits" />

      <div style={{ padding: '20px', minWidth: '900px' }}>
        <EarnFreeCredits />
      </div>
    </>
  );
};

export default EarnFreeCreditsSettings;
