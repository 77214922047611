import React, { useCallback, useMemo, useState } from 'react';

import { useDispatch } from 'react-redux';

import Input from '@uikit/Input/Input';
import { SVGIcon } from '@uikit/Icon/Icon';

import Display from '@components/Display/Display';

import { setPersonsIdsForFilterCampaigns } from '@redux/actions/campaigns.actions';
import useWorkspaceMembers from '@hooks/useWorkspaceMembers';

import PersonItem from './_components/PersonItem/PersonItem';

import './TeamFilter.scss';

type TeamFilterType = {
  isSearchable: boolean;
  chosenPeopleIds: number[];
  gatherChosenPeopleIds: (ids: number[]) => void;
  chooseAllPeople: () => void;
};

const TeamFilter = ({
  isSearchable = true,
  chosenPeopleIds,
  gatherChosenPeopleIds,
  chooseAllPeople,
}: TeamFilterType): JSX.Element => {
  const dispatch = useDispatch();
  const [searchString, changeSearchString] = useState('');
  const { members: workspaceMembers, isFetching } = useWorkspaceMembers();

  const handleSearchChange = ({ target: { value } }) => changeSearchString(value);

  const isChoosen = (id) => chosenPeopleIds.includes(id);

  const handleChangeIsChoosenStatus = (personId) => {
    let newIdsList;

    if (isChoosen(personId)) {
      newIdsList = chosenPeopleIds.filter((id) => id !== personId);
    } else {
      newIdsList = [...chosenPeopleIds, personId];
    }

    gatherChosenPeopleIds(newIdsList);
  };

  const filteredMembers = useMemo(() => {
    if (searchString) {
      return workspaceMembers.filter(
        (member) => member.fullName.toLowerCase().indexOf(searchString.toLowerCase()) !== -1
      );
    }

    return workspaceMembers;
  }, [workspaceMembers, searchString]);

  return (
    <div className="team-filter">
      {isSearchable ? (
        <div className="team-filter__input-wrapper">
          <Input
            isTransparent
            icon="search"
            iconColor="#C4C6CD"
            value={searchString}
            onChange={handleSearchChange}
            placeholder="Search"
          />
        </div>
      ) : null}

      <div
        className="team-filter__everybody-title"
        onClick={chooseAllPeople}
        tabIndex={0}
        role="button"
      >
        <div className="team-filter__everybody-icon">
          <SVGIcon icon="allMembers" />
        </div>
        <span className="team-filter__person-name">Everybody</span>
        <Display isVisible={!chosenPeopleIds.length}>
          <div className="team-filter__chosen-person-icon">
            <SVGIcon icon="star" color="#FC8E0E" />
          </div>
        </Display>
      </div>
      <div className="team-filter__people-list">
        {filteredMembers.map(({ userId, logoUrl, fullName }) => (
          <div
            role="button"
            tabIndex={0}
            key={userId}
            className="team-filter__person-item"
            onClick={() => handleChangeIsChoosenStatus(userId)}
          >
            <PersonItem avatarSrc={logoUrl} name={fullName} isChoosen={isChoosen(userId)} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamFilter;
