import React from 'react';

export default ({ color = '#fff' }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.66666 4.00017C4.5348 4.00017 4.40591 4.03927 4.29628 4.11253C4.18664 4.18578 4.1012 4.2899 4.05074 4.41172C4.00028 4.53354 3.98708 4.66758 4.0128 4.7969C4.03852 4.92622 4.10202 5.04501 4.19525 5.13824C4.28849 5.23148 4.40728 5.29497 4.5366 5.3207C4.66592 5.34642 4.79996 5.33322 4.92178 5.28276C5.0436 5.2323 5.14772 5.14685 5.22097 5.03722C5.29422 4.92759 5.33332 4.79869 5.33332 4.66684C5.33332 4.49003 5.26309 4.32046 5.13806 4.19544C5.01304 4.07041 4.84347 4.00017 4.66666 4.00017ZM14.4733 7.85351L8.15332 1.54684C8.09116 1.47982 8.01589 1.42629 7.93216 1.38959C7.84844 1.35289 7.75807 1.3338 7.66666 1.33351H3.66666C3.57892 1.333 3.49194 1.34982 3.41072 1.38299C3.32949 1.41617 3.25562 1.46505 3.19332 1.52684L1.52666 3.18684C1.46487 3.24913 1.41599 3.32301 1.38281 3.40424C1.34963 3.48546 1.33282 3.57244 1.33332 3.66017V7.66017C1.33578 7.83669 1.40483 8.00575 1.52666 8.13351L7.85332 14.4668C7.98108 14.5887 8.15015 14.6577 8.32666 14.6602C8.41439 14.6607 8.50137 14.6439 8.58259 14.6107C8.66382 14.5775 8.7377 14.5286 8.79999 14.4668L14.4733 8.79351C14.5351 8.73121 14.584 8.65734 14.6172 8.57611C14.6503 8.49489 14.6672 8.40791 14.6667 8.32017C14.6625 8.14598 14.5936 7.97958 14.4733 7.85351ZM8.32666 13.0602L2.66666 7.39351V3.93351L3.93332 2.66684H7.38666L13.0533 8.32684L8.32666 13.0602Z"
      fill={color}
    />
  </svg>
);
