import {
  ChecklistStateType,
  IntegrationSettingsType,
  IntegrationType,
  OrganizationInviteResponseType,
  OrganizationMemberResponseType,
  OrganizationMemberRoleMapType,
  UpdateIntegrationType,
  WorkspaceShortType,
  WorkspaceType,
} from '@ts/userOrganization.types';
import { WorkspaceMemberShortType, WorkspaceMemberType } from '@ts/workspace.types';

import streamRequest from '@api/base/streamRequest';
import request from '@api/base/request';
import userOrganizationService from '@api/services/userOrganizationService';

import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb';
import * as google_protobuf_wrappers_pb from 'google-protobuf/google/protobuf/wrappers_pb';
import { integrationsService } from 'src/services/integrationsService';
import { ReferralLinkType } from '@ts/user.types';
import UserOrganizationModel, {
  AppIntegrationRequest,
  AppIntegrationResponse,
  OrganizationChecklistTaskMap,
  OrganizationChecklistTaskState,
  OrganizationMemberRoleMap,
  ReferralLinkData,
  TrackingDomain,
  TrackingDomainDnsSettings,
  UnsubscribeContent,
} from 'respona_api/generated/user-organization_pb';

import CommonModel, {
  IntegrationTypeMap,
  IntegrationStatus as INTEGRATION_STATUS,
} from 'respona_api/generated/common_pb';

/*
  Workspaces
 */
export const getAllWorkspacesApi = (page: number, limit: number): Promise<WorkspaceType[]> => {
  const service = userOrganizationService.getInstance();
  const workspacesRequest = new CommonModel.PageRequest();

  workspacesRequest.setPage(page);
  workspacesRequest.setLimit(limit);

  return streamRequest<WorkspaceType[]>(service, service.getAllWorkspaces, workspacesRequest);
};

export const getAllWorkspacesShortApi = (): Promise<WorkspaceShortType[]> => {
  const service = userOrganizationService.getInstance();
  const emptyRequest = new google_protobuf_empty_pb.Empty();

  return streamRequest<WorkspaceShortType[]>(service, service.getAllWorkspacesShort, emptyRequest);
};

export const createWorkspaceApi = (title: string): Promise<WorkspaceType> => {
  const service = userOrganizationService.getInstance();
  const newWorkspaceRequest = new UserOrganizationModel.WorkspaceRequest();

  newWorkspaceRequest.setTitle(title);

  return request<WorkspaceType>(service, service.createWorkspace, newWorkspaceRequest);
};

export const deleteWorkspaceApi = (workspaceId: number): Promise<void> => {
  const service = userOrganizationService.getInstance();
  const idRequest = new CommonModel.IdRequest();

  idRequest.setId(workspaceId);

  return request<void>(service, service.deleteWorkspace, idRequest);
};

export const changeWorkspaceFavoriteStateApi = (workspaceId: number): Promise<void> => {
  const service = userOrganizationService.getInstance();
  const idRequest = new CommonModel.IdRequest();

  idRequest.setId(workspaceId);

  return request<void>(service, service.changeWorkspaceFavoriteState, idRequest);
};

export const updateWorkspaceApi = (
  id: number,
  title: string,
  logoUrl: string,
  memberIds: number[],
  accountIds: number[]
): Promise<WorkspaceType> => {
  const service = userOrganizationService.getInstance();
  const workspaceRequest = new UserOrganizationModel.WorkspaceRequest();

  workspaceRequest.setId(id);
  workspaceRequest.setTitle(title);
  workspaceRequest.setLogoUrl(logoUrl);
  workspaceRequest.setUserIdsList(memberIds);
  workspaceRequest.setAccountIdsList(accountIds);

  return request<WorkspaceType>(service, service.updateWorkspace, workspaceRequest);
};

export const getAllWorkspacesByUserIdApi = (userId: number) => {
  const service = userOrganizationService.getInstance();
  const idRequest = new CommonModel.IdRequest();

  idRequest.setId(userId);

  return streamRequest(service, service.getAllWorkspacesByUserId, idRequest);
};

/*
  Members of workspace
 */

export const removeMembersFromWorkspaceApi = (userIds: number[], workspaceId: number) => {
  const service = userOrganizationService.getInstance();
  const addRequest = new UserOrganizationModel.WorkspaceMemberRequest();

  addRequest.setWorkspaceId(workspaceId);
  addRequest.setUserIdsList(userIds);

  return request(service, service.removeMembersFromWorkspace, addRequest);
};

export const getAllMembersOfWorkspaceApi = (workspaceId): Promise<WorkspaceMemberType[]> => {
  const client = userOrganizationService.getInstance();
  const membersRequest = new CommonModel.PageRequest();

  membersRequest.setPage(0);
  membersRequest.setLimit(200);
  membersRequest.setOptionalWorkspaceId(workspaceId);

  return streamRequest<WorkspaceMemberType[]>(
    client,
    client.getAllMembersOfWorkspace,
    membersRequest
  );
};

export const getAllMembersOfWorkspaceShortApi = (
  workspaceId: number
): Promise<WorkspaceMemberShortType[]> => {
  const client = userOrganizationService.getInstance();
  const shortMembersRequest = new CommonModel.IdRequest();

  shortMembersRequest.setId(workspaceId);
  shortMembersRequest.setOptionalWorkspaceId(workspaceId);

  return streamRequest<WorkspaceMemberShortType[]>(
    client,
    client.getAllMembersOfWorkspaceShort,
    shortMembersRequest
  );
};

/*
  Organization
 */

/*
  Members of organization
 */
export const getAllInvitesOfOrganizationApi = (
  page: number,
  limit: number
): Promise<OrganizationInviteResponseType[]> => {
  const service = userOrganizationService.getInstance();
  const pageRequest = new CommonModel.PageRequest();

  pageRequest.setPage(page);
  pageRequest.setLimit(limit);

  return streamRequest<OrganizationInviteResponseType[]>(
    service,
    service.getAllInvitesOfOrganization,
    pageRequest
  );
};

export const getAllMembersOfOrganizationApi = (
  page: number,
  limit: number
): Promise<OrganizationMemberResponseType[]> => {
  const service = userOrganizationService.getInstance();
  const pageRequest = new CommonModel.PageRequest();

  pageRequest.setPage(page);
  pageRequest.setLimit(limit);

  return streamRequest<OrganizationMemberResponseType[]>(
    service,
    service.getAllMembersOfOrganization,
    pageRequest
  );
};

export const inviteMembersToOrganizationApi = (
  emails: string[],
  role: OrganizationMemberRoleMapType,
  workspacesIds: number[]
) => {
  const service = userOrganizationService.getInstance();
  const inviteRequest = new UserOrganizationModel.InviteMemberRequest();

  inviteRequest.setRole(role);
  inviteRequest.setEmailsList(emails);
  inviteRequest.setWorkspaceIdsList(workspacesIds);

  return request(service, service.sendInviteToOrganization, inviteRequest);
};

export const removeInviteFromOrganizationApi = (inviteId: number) => {
  const service = userOrganizationService.getInstance();
  const removeRequest = new CommonModel.IdRequest();

  removeRequest.setId(inviteId);

  return request(service, service.removeInviteFromOrganization, removeRequest);
};

export const removeMemberFromOrganizationApi = (
  memberId: number,
  transferData: boolean,
  userIdForTransfer: number
): Promise<void> => {
  const service = userOrganizationService.getInstance();
  const removeRequest = new UserOrganizationModel.RemoveMemberRequest();

  removeRequest.setId(memberId);
  removeRequest.setRemoveData(transferData);
  if (userIdForTransfer && transferData) {
    removeRequest.setOptionalUserIdForTransferData(userIdForTransfer);
  }

  return request<void>(service, service.removeMemberFromOrganization, removeRequest);
};

export const updateMemberOfOrganizationApi = (
  userId: number,
  role: OrganizationMemberRoleMap[keyof OrganizationMemberRoleMap],
  workspacesIds: number[]
): Promise<void> => {
  const service = userOrganizationService.getInstance();
  const updateRequest = new UserOrganizationModel.UpdateMemberRequest();

  updateRequest.setId(userId);
  updateRequest.setRole(role);
  updateRequest.setWorkspaceIdsList(workspacesIds);

  return request<void>(service, service.updateMemberOfOrganization, updateRequest);
};

/*
  Onboarding/dashboard (checklist)
 */

export const getOnboardingChecklistApi = (): Promise<ChecklistStateType> => {
  const service = userOrganizationService.getInstance();
  const emptyRequest = new google_protobuf_empty_pb.Empty();

  return request<ChecklistStateType>(service, service.getChecklist, emptyRequest);
};

export const skipChecklistApi = (): Promise<null> => {
  const service = userOrganizationService.getInstance();
  const emptyRequest = new google_protobuf_empty_pb.Empty();

  return request<null>(service, service.skipChecklist, emptyRequest);
};

export const completeChecklistItemApi = (
  task: OrganizationChecklistTaskMap[keyof OrganizationChecklistTaskMap],
  complete: boolean
): Promise<null> => {
  const service = userOrganizationService.getInstance();
  const taskStateRequest = new OrganizationChecklistTaskState();

  taskStateRequest.setTask(task);
  taskStateRequest.setComplete(complete);

  return request<null>(service, service.completeChecklistItem, taskStateRequest);
};

/*
  Integrations
*/

export const getAllIntegrationsApi = (workspaceId: number): Promise<IntegrationType[]> => {
  const client = userOrganizationService.getInstance();
  const integrationsRequest = new CommonModel.PageRequest();

  integrationsRequest.setPage(0);
  integrationsRequest.setLimit(100);
  integrationsRequest.setOptionalWorkspaceId(workspaceId);

  return streamRequest<IntegrationType[]>(client, client.getAllIntegrations, integrationsRequest);
};

export const connectIntegrationApi = (
  type: IntegrationTypeMap[keyof IntegrationTypeMap],
  workspaceIdsList: number[],
  settings: Partial<IntegrationSettingsType>,
  workspaceId: number
): Promise<IntegrationType> => {
  const client = userOrganizationService.getInstance();
  const connectIntegrationsRequest = new AppIntegrationRequest();

  connectIntegrationsRequest.setType(type);
  connectIntegrationsRequest.setStatus(INTEGRATION_STATUS.ACTIVE_INTEGRATION);
  connectIntegrationsRequest.setCurrentWorkspaceId(workspaceId);
  connectIntegrationsRequest.setAllowedForWorkspaceIdsList(workspaceIdsList);
  integrationsService.setRequestIntegrationSettings(type, connectIntegrationsRequest, settings);

  return request<AppIntegrationResponse.AsObject>(
    client,
    client.connectIntegration,
    connectIntegrationsRequest
  );
};

export const updateIntegrationApi = (
  id: number,
  workspaceId: number,
  type: IntegrationTypeMap[keyof IntegrationTypeMap],
  data: UpdateIntegrationType
): Promise<IntegrationType> => {
  const client = userOrganizationService.getInstance();
  const updateIntegrationsRequest = new AppIntegrationRequest();

  updateIntegrationsRequest.setId(id);
  updateIntegrationsRequest.setCurrentWorkspaceId(workspaceId);
  updateIntegrationsRequest.setType(type);

  if (data.status) {
    updateIntegrationsRequest.setStatus(data.status);
  }
  if (data.workspaceIdsList?.length) {
    updateIntegrationsRequest.setAllowedForWorkspaceIdsList(data.workspaceIdsList);
  }
  if (data?.settings) {
    integrationsService.setRequestIntegrationSettings(
      type,
      updateIntegrationsRequest,
      data?.settings
    );
  }

  return request<AppIntegrationResponse.AsObject>(
    client,
    client.updateIntegration,
    updateIntegrationsRequest
  );
};

export const deleteIntegrationApi = (id: number, workspaceId: number): Promise<IntegrationType> => {
  const client = userOrganizationService.getInstance();
  const deleteIntegrationsRequest = new CommonModel.IdRequest();

  deleteIntegrationsRequest.setId(id);
  deleteIntegrationsRequest.setOptionalWorkspaceId(workspaceId);

  return request<AppIntegrationResponse.AsObject>(
    client,
    client.deleteIntegration,
    deleteIntegrationsRequest
  );
};

// Tracking domains

export const getTrackingDomainSettingsApi = (): Promise<TrackingDomainDnsSettings.AsObject> => {
  const client = userOrganizationService.getInstance();
  const domainsRequest = new google_protobuf_empty_pb.Empty();

  return request<TrackingDomainDnsSettings.AsObject>(
    client,
    client.getTrackingDomainSettings,
    domainsRequest
  );
};

export const getAllTrackingDomainsApi = (): Promise<TrackingDomain.AsObject[]> => {
  const client = userOrganizationService.getInstance();
  const domainsRequest = new google_protobuf_empty_pb.Empty();

  return streamRequest<TrackingDomain.AsObject[]>(
    client,
    client.getAllTrackingDomains,
    domainsRequest
  );
};

export const addTrackingDomainsApi = (domain: string): Promise<void> => {
  const client = userOrganizationService.getInstance();
  const domainsRequest = new google_protobuf_wrappers_pb.StringValue();

  domainsRequest.setValue(domain);

  return request<void>(client, client.addCustomTrackingDomain, domainsRequest);
};

export const deleteCustomTrackingDomainApi = (domain: string): Promise<void> => {
  const client = userOrganizationService.getInstance();
  const domainsRequest = new google_protobuf_wrappers_pb.StringValue();

  domainsRequest.setValue(domain);

  return request<void>(client, client.deleteCustomTrackingDomain, domainsRequest);
};

// Unsubscribe content
export const getUnsubscribeContentApi = (): Promise<UnsubscribeContent.AsObject> => {
  const client = userOrganizationService.getInstance();
  const emptyRequest = new google_protobuf_empty_pb.Empty();

  return request<UnsubscribeContent.AsObject>(client, client.getUnsubscribeContent, emptyRequest);
};

export const updateUnsubscribeContentApi = (
  value: string,
  enabled: boolean
): Promise<UnsubscribeContent.AsObject> => {
  const client = userOrganizationService.getInstance();
  const unsubscribeContentRequest = new UnsubscribeContent();

  unsubscribeContentRequest.setContent(value);
  unsubscribeContentRequest.setEnabled(enabled);

  return request<UnsubscribeContent.AsObject>(
    client,
    client.updateUnsubscribeContent,
    unsubscribeContentRequest
  );
};

export const updateReferralContentApi = (value: string): Promise<ReferralLinkData.AsObject> => {
  const client = userOrganizationService.getInstance();
  const referralContentRequest = new google_protobuf_wrappers_pb.StringValue();

  referralContentRequest.setValue(value);

  return request<ReferralLinkData.AsObject>(
    client,
    client.updateReferralContent,
    referralContentRequest
  );
};

export const changeUnsubscribeContentStateApi = (value: boolean) => {
  const client = userOrganizationService.getInstance();
  const booleanRequest = new google_protobuf_wrappers_pb.BoolValue();

  booleanRequest.setValue(value);

  return request<UnsubscribeContent.AsObject>(
    client,
    client.changeUnsubscribeContentState,
    booleanRequest
  );
};

export const getReferralLinkDataApi = (): Promise<ReferralLinkType> => {
  const client = userOrganizationService.getInstance();
  const emptyRequest = new google_protobuf_empty_pb.Empty();

  return request<ReferralLinkType>(client, client.getReferralsData, emptyRequest);
};
