import React from 'react';

export default ({
  color = '#221CB6',
  size = 48,
}: {
  color: string;
  size?: number;
}): JSX.Element => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 42C5.46957 42 4.96086 41.7893 4.58579 41.4142C4.21071 41.0391 4 40.5304 4 40V8C4 7.46957 4.21071 6.96086 4.58579 6.58579C4.96086 6.21071 5.46957 6 6 6H20.828L24.828 10H40C40.5304 10 41.0391 10.2107 41.4142 10.5858C41.7893 10.9609 42 11.4696 42 12V18H8V37.992L12 22H45L40.38 40.486C40.2717 40.9185 40.0219 41.3024 39.6704 41.5767C39.3189 41.851 38.8859 42 38.44 42H6Z"
      fill={color}
    />
  </svg>
);
