import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import cn from 'class-names';

import {
  CampaignTemplateInputType,
  CampaignTemplateInputTypeMap,
} from 'respona_api/generated/campaign_pb';

import { isCorrectUrlString } from '@utils/isUrl';

import Input from '@uikit/Input/Input';
import Textarea from '@uikit/Textarea/Textarea';

import ContentSearchEditorV2 from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/ContentSearchEditor/ContentSearchEditorV2';

import QuestionTooltip from '@pages/CampaignCreationPage/_components/QuestionTooltip/QuestionTooltip';

import './CampaignTemplateInput.scss';
import FilterWithSelect from '@components/CampaignSettings/Filters/_components/FilterWithSelect/FilterWithSelect';
import { Button } from '@uikit/Button/Button';
import { searchUrls } from '@api/contentSerach.api';
import { UrlItemType } from '@ts/contentSearch.types';
import Select from '@uikit/Select/Select';
import { getAIKeywordSuggestionsApi } from '@api/campaign.api';
import { addNotification } from '@redux/actions/notifications.actions';
import { useDispatch, useSelector } from 'react-redux';
import { DispatchType } from 'src/store';
import { SVGIcon } from '@uikit/Icon/Icon';
import UpsellCreditsSidebar from '@components/UpsellSidebars/UpsellCreditsSidebar/UpsellCreditsSidebar';
import { getCurrentWorkspaceId } from '@redux/selectors/workspaces.selectors';

type OptionType = {
  label: string;
  value: string;
  [key: string]: any;
};

function CampaignTemplateInput({
  order,
  templateId,
  title,
  helpText,
  type,
  onChange,
  placeholder,
  preset,
  variableName,
  maxLines,
  maxCharacters,
  optional,
  autoPopulateSource,
  autoPopulateButtonEnabled,
  aiSuggestionsEnabled,
  aiSuggestionPrompt,
  aiSuggestionPreset,
  globalVarValues,
}: {
  order: number;
  templateId: number;
  title: string;
  helpText?: string;
  type?: CampaignTemplateInputTypeMap[keyof CampaignTemplateInputTypeMap];
  onChange?: Dispatch<SetStateAction<{ [key: string]: string | string[] }>>;
  placeholder?: string;
  preset?: string;
  variableName?: string;
  maxLines?: number;
  maxCharacters?: number;
  autoPopulateButtonEnabled: boolean;
  aiSuggestionsEnabled: boolean;
  optional: boolean;
  autoPopulateSource?: string;
  aiSuggestionPrompt?: string;
  aiSuggestionPreset?: string[];
  globalVarValues: { [key: string]: string | string[] };
}) {
  const dispatch = useDispatch<DispatchType>();
  const currentWorkspaceId = useSelector(getCurrentWorkspaceId);
  const [isAutoPopulateLoading, setIsAutoPopulateLoading] = useState<boolean>(false);
  const [isLoadingInitialValue, setLoadingInitialValue] = useState<boolean>(true);
  const [isAiLoading, setAiLoading] = useState<boolean>(false);
  const [isGenerateBtnDisabled, setGenerateBtnDisabled] = useState<boolean>(true);
  const [value, setValue] = useState<string>('');
  const [values, setValues] = useState<string[]>([]);
  const [aiValue, setAiValue] = useState<{ label: string; value: string }>({
    label: '',
    value: '',
  });
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [optionValue, setOptionValue] = useState<OptionType>(null);
  const [upsellCreditsSidebarOpen, setUpsellCreditsSidebarOpen] = useState<boolean>(false);
  const options = placeholder
    ? placeholder.split(',').map((item) => {
        return {
          label: item.trim(),
          value: item.trim(),
        };
      })
    : [];
  const [inputFocused, setInputFocused] = useState<boolean>(false);

  useEffect(() => {
    if (aiSuggestionPrompt != null && aiSuggestionPrompt.length && aiValue === null) {
      setAiValue({ label: aiSuggestionPrompt, value: aiSuggestionPrompt });
    }
  }, [aiSuggestionPrompt]);

  useEffect(() => {
    if (preset != null && preset.length > 0 && value === '') {
      if (
        type === CampaignTemplateInputType.TEXT ||
        type === CampaignTemplateInputType.URL ||
        CampaignTemplateInputType.TEXT_AREA
      ) {
        setValue(preset);
        setLoadingInitialValue(false);
      }
      if (
        type === CampaignTemplateInputType.TEXT_MULTIPLY ||
        type === CampaignTemplateInputType.URL_MULTIPLY
      ) {
        setValues(preset.split(',').map((sentence) => sentence.trim()));
        setLoadingInitialValue(false);
      }
    }
  }, [preset]);

  useEffect(() => {
    if (!onChange || !variableName) {
      return;
    }

    if (
      type === CampaignTemplateInputType.URL_MULTIPLY ||
      type === CampaignTemplateInputType.TEXT_MULTIPLY
    ) {
      onChange((prevValues) => ({
        ...prevValues,
        [variableName]: values || [],
      }));
    } else if (
      type === CampaignTemplateInputType.DATE_RANGE_DROPDOWN ||
      type === CampaignTemplateInputType.LOCATION_DROPDOWN ||
      type === CampaignTemplateInputType.LANGUAGE_DROPDOWN
    ) {
      onChange((prevValues) => ({
        ...prevValues,
        [variableName]: (optionValue ? optionValue.value.trim() : '') || '',
      }));
    } else {
      onChange((prevValues) => ({
        ...prevValues,
        [variableName]: value || '',
      }));
    }
  }, [value, values, optionValue]);

  function handleResponse(response: UrlItemType[]) {
    setValues(response.map((it) => it.url).concat(values));
  }

  function doAutoPopulate() {
    const inputValues = composeDataByAutoPopulateSource();
    setIsAutoPopulateLoading(true);
    if (Array.isArray(inputValues)) {
      inputValues.forEach((item) => {
        searchUrls(item, 10, 1)
          .then((response) => handleResponse(response))
          .finally(() => setIsAutoPopulateLoading(false));
      });
    } else {
      searchUrls(inputValues, 10, 1)
        .then((response) => handleResponse(response))
        .finally(() => setIsAutoPopulateLoading(false));
    }
  }

  function composeDataByAutoPopulateSource() {
    return globalVarValues[autoPopulateSource] || [];
  }

  const checkDisabled = !Object.values(composeDataByAutoPopulateSource()).length;

  function renderAiSuggestionsBlock() {
    if (!aiSuggestionsEnabled || aiSuggestionPreset == null || !aiSuggestionPreset.length)
      return null;

    function onGenerateClick() {
      if (aiValue.value === '') {
        dispatch(addNotification({ title: 'Empty prompt', type: 'error' }));
        return;
      }
      if (isGenerateBtnDisabled) {
        dispatch(
          addNotification({
            title: 'Please enter a keyword in the prompt, and try again.',
            type: 'error',
          })
        );
        return;
      }
      setAiLoading(true);
      setAiValue({ label: 'Coming up with keywords ...', value: 'Coming up with keywords ...' });
      const oldAiValue = aiValue.value;
      getAIKeywordSuggestionsApi(aiValue.value, currentWorkspaceId)
        .then((response) => {
          setValues([...values, ...response.valuesList]);
        })
        .catch((error) => {
          if (JSON.parse(error.message).code === 4444) {
            setUpsellCreditsSidebarOpen(true);
          }
        })
        .finally(() => {
          setAiValue({ label: oldAiValue, value: oldAiValue });
          setAiLoading(false);
        });
    }

    const prepareLabel = (label: string): string => {
      return `Suggest ${label[0].toLowerCase() + label.substring(1)} related to `;
    };

    const presetValue = () => {
      switch (templateId) {
        case 1:
          return prepareLabel(aiSuggestionPreset[1]);
        case 3:
          return prepareLabel(aiSuggestionPreset[1]);
        case 4:
          return prepareLabel(aiSuggestionPreset[1]);
        case 5:
          return prepareLabel(aiSuggestionPreset[0]);
        case 6:
          return prepareLabel(aiSuggestionPreset[0]);
        case 7:
          return prepareLabel(aiSuggestionPreset[0]);
        case 9:
          return prepareLabel(aiSuggestionPreset[0]);
        case 10:
          return prepareLabel(aiSuggestionPreset[0]);
        case 11:
          return prepareLabel(aiSuggestionPreset[1]);
        case 22:
          return prepareLabel(aiSuggestionPreset[1]);
        case 24:
          return prepareLabel(aiSuggestionPreset[0]);
      }
    };

    return (
      <div className="campaign-template-input__wrapper">
        <Select
          isDisabled={isAiLoading}
          classNamePrefix="campain-select"
          selectClass={!menuIsOpen && aiValue.label === '' ? 'select-grey' : ''}
          options={['Select an option or type your own prompt', ...aiSuggestionPreset].map(
            (it, index) => {
              return { label: it, value: it, isDisabled: index === 0 };
            }
          )}
          allowCustomInput
          icon="ai"
          menuIsOpen={menuIsOpen && aiValue.label.length === 0}
          value={aiValue.label ? aiValue : null}
          inputValue={isAiLoading ? null : aiValue.label}
          placeholder="Click here to ask AI for suggested keywords"
          additionalComponents={{ DropdownIndicator: () => null }}
          onChange={({ label }) => {
            const val = prepareLabel(label);
            setGenerateBtnDisabled(true);
            setAiValue({ label: val, value: val });
          }}
          onInputChange={(text, { action }) => {
            if (action === 'input-change') {
              setGenerateBtnDisabled(false);
              setAiValue({ label: text, value: text });

              if (text.length === 0) {
                setMenuIsOpen(true);
              }
            }
          }}
          onFocus={() => {
            if (aiValue.label.length === 0) {
              const val = presetValue();

              if (val) {
                setAiValue({
                  label: val,
                  value: val,
                });
                setGenerateBtnDisabled(true);
              } else {
                setMenuIsOpen(true);
              }
            }
          }}
          onBlur={() => {
            setMenuIsOpen(false);
          }}
          onKeyDown={(event) => {
            if (event.keyCode === 13 || event.key === 'Enter') {
              onGenerateClick();
            }
          }}
        />
        <Button
          className="campaign-template-input__square-button"
          onClick={() => onGenerateClick()}
          disabled={isAiLoading}
        >
          {isAiLoading ? (
            <SVGIcon className="refresh" icon="refreshCircle" size={11} color="#ffffff" />
          ) : (
            <SVGIcon icon="arrowRightClassic" size={11} color="#ffffff" />
          )}
        </Button>
      </div>
    );
  }

  return (
    <div className="campaign-template-input__container">
      {upsellCreditsSidebarOpen ? (
        <UpsellCreditsSidebar
          onClose={() => setUpsellCreditsSidebarOpen(false)}
          needForAutomation={0}
        />
      ) : null}
      <div className="campaign-template-input__container-row">
        <div className="campaign-template-input__order">{order}</div>
        <div className="campaign-template-input__title">{title}</div>

        {!!helpText && (
          <QuestionTooltip
            color="#C6C6C6"
            style={{ marginLeft: '6px', marginTop: '1px' }}
            text={helpText}
          />
        )}

        {autoPopulateButtonEnabled && (
          <Button
            size="s"
            isAdditional
            style={{ marginLeft: 'auto' }}
            isLoading={isAutoPopulateLoading}
            loadingText="Searching..."
            className="campaigns-header__button"
            onClick={() => doAutoPopulate()}
            disabled={checkDisabled}
          >
            <span>Auto-populate</span>
          </Button>
        )}
        {optional && <span className="campaigns-header__optional">Optional</span>}
      </div>

      {(type === CampaignTemplateInputType.TEXT || type === CampaignTemplateInputType.URL) && (
        <Input
          placeholder={placeholder || title}
          value={value || ''}
          onChange={({ target: { value } }) => setValue(value)}
          className="campaign-template-input"
          onFocus={() => setInputFocused(true)}
          onBlur={() => setInputFocused(false)}
          maxLength={maxCharacters > 0 ? maxCharacters : undefined}
          error={
            type === CampaignTemplateInputType.URL &&
            !inputFocused &&
            !!value &&
            !isCorrectUrlString(value)
              ? 'Is not a valid url'
              : undefined
          }
        />
      )}

      {(type === CampaignTemplateInputType.TEXT_MULTIPLY ||
        type === CampaignTemplateInputType.URL_MULTIPLY) &&
        // if "preset" is set then for the first render "values" is empty,
        // and it will be full in useEffect so it will have a value in the next re-render;
        // without this condition the initialQuery in ContentSearchEditorV2 will be set wrong
        ((preset && (values.length || !isLoadingInitialValue)) || !preset) && (
          <ContentSearchEditorV2
            onChangeQueries={setValues}
            queries={values}
            withLinks={false}
            isUrl={type === CampaignTemplateInputType.URL_MULTIPLY}
            placeholder={placeholder || title}
            maxCharacters={maxCharacters > 0 ? maxCharacters : undefined}
            maxLines={maxLines}
            bottomBlock={renderAiSuggestionsBlock()}
            extraClass="visible"
          />
        )}

      {type === CampaignTemplateInputType.TEXT_AREA &&
        ((preset && (value.length || !isLoadingInitialValue)) || !preset) && (
          <Textarea
            placeholder={value || placeholder || title}
            autosize
            resizeDisabled
            isFullWidth
            value={value}
            maxLength={maxCharacters > 0 ? maxCharacters : undefined}
            onChange={({ target: { value } }) => setValue(value)}
            className="campaign-template-input"
          />
        )}

      {(type === CampaignTemplateInputType.LANGUAGE_DROPDOWN ||
        type === CampaignTemplateInputType.LOCATION_DROPDOWN ||
        type === CampaignTemplateInputType.DATE_RANGE_DROPDOWN) && (
        <FilterWithSelect
          options={options}
          placeholder=""
          value={optionValue}
          onApply={(filterValue: OptionType) => setOptionValue(filterValue)}
          defaultValue={options[0]}
        />
      )}
    </div>
  );
}

export default CampaignTemplateInput;
