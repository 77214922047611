import React from 'react';

const SortDownArrow = ({ color = '#8D8F92', size }: { color: string, size?: number }): JSX.Element => (
  <svg width={size} viewBox="0 0 5 3" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.32568 0.837123L2.16281 2.99999L-5.34282e-05 0.837123L0.514611 0.324585L2.16282 1.97279L3.81102 0.324585L4.32568 0.837123Z"
      fill={color}
    />
  </svg>
);

export default SortDownArrow;
