import React, { useEffect, useState } from 'react';

import { VariableType } from '@constants/variables';

import ErrorBoundary from '@uikit/ErrorBoundaries/ErrorBoundary';
import InlineSlateEditor from '@uikit/RichEditor/InlineSlateEditor';
import SlateEditor from '@uikit/RichEditor/SlateEditor';

import './CampaignTemplateEditor.scss';

const CampaignTemplateEditor = ({
  sequenceStep,
  variables,
  editorVariables,
  setSequence,
}: {
  sequenceStep: {
    order: number;
    subject: string;
    content: string;
    isSameThread: boolean;
    prevSubject?: string;
    delayFromFirstDay?: number;
    attachmentsList: any[];
  };
  variables: VariableType[];
  editorVariables: VariableType[];
  setSequence: (data: { content: string; subject: string }) => void;
}): JSX.Element => {
  const [subject, changeSubject] = useState(sequenceStep?.subject);
  const [content, changeContent] = useState(sequenceStep?.content);

  const handleChangeSubject = (htmlTemplate) => {
    changeSubject(htmlTemplate);
  };
  const handleChangeContent = (htmlTemplate) => {
    changeContent(htmlTemplate);
  };

  const handleCancelClick = () => {
    changeSubject(sequenceStep.subject);
    changeContent(sequenceStep.content);
  };

  useEffect(() => {
    changeContent(sequenceStep?.content);
    changeSubject(sequenceStep?.subject);
  }, [sequenceStep?.content]);

  useEffect(() => {
    setSequence({ subject, content });
  }, [subject, content]);

  return (
    <div className="campaign-template-editor">
      <ErrorBoundary>
        <div className="campaign-template-editor__subject">
          <div className="campaign-template-editor__subject-header">Subject:</div>
          <InlineSlateEditor
            message={subject}
            onChange={handleChangeSubject}
            placeholder="Fill email subject"
            transformVariable={(variable) =>
              transformEditorVariable(variable, undefined, variables)
            }
            variables={editorVariables}
            withEmoji={false}
          />
        </div>
        <SlateEditor
          message={content}
          onChange={handleChangeContent}
          transformVariable={(variable) => transformEditorVariable(variable, undefined, variables)}
          variables={editorVariables}
          withControls={false}
          // useMessageUpdate
        />
      </ErrorBoundary>
    </div>
  );
};

const transformEditorVariable = (
  variable: string,
  _: unknown,
  allVariables: VariableType[]
): string => {
  const variableObject = allVariables.find((item) => item.name === variable);

  if (variableObject?.value === undefined) {
    return `${variableObject?.title || variable}`;
  }

  return variableObject?.value || variableObject?.title || variable;
};

export default CampaignTemplateEditor;
