import React from 'react';

import { components } from 'react-select';

const SelectOption = (props) => {
  return (
    <components.Option {...props}>
      <p className="people-header-more-actions-tooltip__company-title" title={props.label}>{props.label}</p>
    </components.Option>
  );
};

export default SelectOption;