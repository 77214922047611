import React from 'react';

export default ({ color = '#fff' }: { color: string }): JSX.Element => (
  <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.16032 2.53965L4.24662 4.45334C4.20068 4.49929 4.14612 4.53574 4.08609 4.56061C4.02605 4.58548 3.9617 4.59828 3.89672 4.59828C3.83174 4.59828 3.76739 4.58548 3.70735 4.56061C3.64732 4.53574 3.59277 4.49929 3.54682 4.45334L2.86224 3.76875L1.02837 5.60264L6.16032 2.53965ZM6.16032 2.53965L6.16025 2.95399V2.95402C6.16025 3.08526 6.21238 3.21112 6.30518 3.30392C6.39798 3.39671 6.52384 3.44885 6.65508 3.44885C6.78631 3.44885 6.91217 3.39671 7.00497 3.30391C7.09777 3.21112 7.1499 3.08527 7.1499 2.95404C7.1499 2.95404 7.1499 2.95403 7.1499 2.95402L7.15016 1.34485V1.34483C7.15016 1.21359 7.09803 1.08773 7.00523 0.994932M6.16032 2.53965L0.678341 5.74769C0.678275 5.74769 0.678209 5.74769 0.678143 5.74769C0.580342 5.74766 0.484743 5.71864 0.403421 5.6643C0.322043 5.60993 0.258617 5.53265 0.221164 5.44223C0.18371 5.35181 0.173911 5.25231 0.193005 5.15632C0.2121 5.06034 0.25923 4.97216 0.328437 4.90296L2.51234 2.71906C2.55828 2.67311 2.61283 2.63666 2.67287 2.61179C2.73291 2.58692 2.79726 2.57412 2.86224 2.57412C2.92722 2.57412 2.99157 2.58692 3.05161 2.61179C3.11164 2.63666 3.16619 2.67311 3.21214 2.71906L3.89672 3.40365L5.46072 1.83965H5.04614C4.91491 1.83965 4.78905 1.78752 4.69625 1.69472C4.60345 1.60192 4.55132 1.47606 4.55132 1.34483C4.55132 1.21359 4.60345 1.08773 4.69625 0.994932L4.69625 0.994931C4.78905 0.902133 4.91491 0.85 5.04614 0.85H6.65534C6.78657 0.85 6.91243 0.902133 7.00523 0.994932M7.00523 0.994932L6.89947 1.10069L7.00523 0.994931L7.00523 0.994932Z"
      fill={color}
      stroke={color}
      strokeWidth="0.3"
    />
  </svg>
);
