import { useSelector } from 'react-redux';

import { haroCategoriesSelector } from '@redux/selectors/inquiries.selectors';

export default () => {
  const { items: categories } = useSelector(haroCategoriesSelector);

  return categories.reduce(
    (acc, { hexColor, title }) => ({
      ...acc,
      [title]: hexColor,
    }),
    {}
  );
};
