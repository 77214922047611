import { ContactAutomationPresetResponse } from 'respona_api/generated/automation-contact_pb';

export enum ContactAutomationSearchModes {
  BLOG_POST = 0,
  DOMAINS = 2,
  NEWS = 3,
  EXPERIMENTAL = 4,
  PODCAST = 6,
}

export type DomainSearchKeywordsType = {
  order?: number;
  keyword?: string;
  value?: string;
  label?: string;
};

export type ContactAutomationStateType = null | {
  automationId: number;
  campaignId: number;
  workspaceId: number;
  totalItemsNumber: number;
  successItemsNumber: number;
  failedItemsNumber: number;
  creditsSpentNumber: number;
  notify: boolean;
  status: -1 | 0 | 1;
  assignNumber: number;
  requiredPositions: boolean;
  positionsList: DomainSearchKeywordsType[];
  requiredSeniorities: boolean;
  senioritiesList: DomainSearchKeywordsType[];
  skipCatchAllEmails: boolean;
  assignGenericEmails: boolean;
  assignPersonalEmails: boolean;
  searchMode?: number;
  source?: number;
  progress?: number;
};

export type ContactAutomationPresetType = ContactAutomationPresetResponse.AsObject & {
  positionSuggestions: Array<{ label: string; value: string }>;
  senioritySuggestions: Array<{ label: string; value: string }>;
};
