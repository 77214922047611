import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { PodcastSearchSourceMap } from 'respona_api/generated/automation-content_pb';

import { DateOfLastEpisode } from 'respona_api/generated/common_pb';

import { mapFromToToMinMax, mapMinMaxToFromTo } from '@helpers/commonHelpers';

import { listennotesMetricsSelector } from '@redux/selectors/automationContent.selectors';
import { setListennotesMetrics } from '@redux/actions/automationContent.actions';

import { Button } from '@uikit/Button/Button';
import { SVGIcon } from '@uikit/Icon/Icon';
import DateRangePickerWrapper from '@uikit/DateRangePicker/DateRangePicker';

import {
  getListenNotesDateOptions,
  listenNotesSortByOptions,
} from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/ListenNotesMetrics/listenNotesMetricsOptions';
import FromToRow from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/FromToRow/FromToRow';
import Display from '@components/Display/Display';
import ListenNotesLogo from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/assets/ListenNotesLogo';
import MetricsWrapper from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/MetricsWrapper/MetricsWrapper';

import SelectRaw from './_components/SelectRaw';

import './ListenNotesMetrics.scss';

const DurationTitleMap = {
  0: 'Total episodes',
  1: 'Episode duration (min)',
};

const DateTitleMap = {
  0: 'Date of last episode',
  1: 'Episode published',
};

const ListenNotesMetrics = ({
  source,
}: {
  source: PodcastSearchSourceMap[keyof PodcastSearchSourceMap];
}): JSX.Element => {
  const tooltipRef = useRef<ReactTooltip>();
  const dateSelectContainerRef = useRef<HTMLDivElement>();

  const [_, setIsOpen] = useState(false);

  const dispatch = useDispatch();

  const { totalEpisodes, listenScore, dateoflastepisode, sortBy, customDateValue } = useSelector(
    listennotesMetricsSelector
  );

  const dateOptions = getListenNotesDateOptions({
    startDate: customDateValue?.from,
    endDate: customDateValue?.to,
  });

  return (
    <MetricsWrapper Logo={ListenNotesLogo} withAddOtherMetricsBtn={false}>
      {/* <FromToRow */}
      {/*  inputStyle={{ width: '211px' }} */}
      {/*  maxNumber={10000} */}
      {/*  label={DurationTitleMap[source]} */}
      {/*  onChange={(from, to) => { */}
      {/*    dispatch( */}
      {/*      setListennotesMetrics({ */}
      {/*        totalEpisodes: mapFromToToMinMax({ from, to }), */}
      {/*      }) */}
      {/*    ); */}
      {/*  }} */}
      {/*  {...mapMinMaxToFromTo(totalEpisodes)} */}
      {/* /> */}

      <FromToRow
        inputStyle={{ width: '211px' }}
        label="Listen score"
        onChange={(from, to) => {
          let fromFixed = from;
          if (from != null && from !== '') {
            try {
              const parsed = Number(from);
              fromFixed = parsed > 20 ? 20 : from;
            } catch (e) {
              // ignore
            }
          }
          dispatch(
            setListennotesMetrics({
              listenScore: mapFromToToMinMax({ from: fromFixed, to }),
            })
          );
        }}
        {...mapMinMaxToFromTo(listenScore)}
      >
        <>
          <span
            className="listennotes-metrics-score__tooltip"
            data-for="listennotes-metrics-score__tooltip"
            data-tip=""
            style={{
              marginLeft: '4px',
              marginTop: '1px',
            }}
          >
            <SVGIcon color="#C6C6C6" icon="questionInCircleFilled" />
          </span>
          <ReactTooltip
            id="listennotes-metrics-score__tooltip"
            className="react-tooltip"
            place="top"
            effect="solid"
            arrowColor="transparent"
          >
            <p className="custom-fields-sidebar__field-label-tooltip">
              Listen Score shows the estimated popularity of a podcast compared to all other public
              podcasts in the world on a scale from 0 to 100. The higher, the more popular.
            </p>
          </ReactTooltip>
        </>
      </FromToRow>

      <div
        data-for="listen-notes-custom-date"
        data-event="no-event"
        data-event-off="no-event"
        data-tip=""
        ref={dateSelectContainerRef}
      >
        <SelectRaw
          label={DateTitleMap[source]}
          onChange={({ value }) => {
            if (value === DateOfLastEpisode.CUSTOM) {
              ReactTooltip.show(dateSelectContainerRef.current);
            }
            dispatch(setListennotesMetrics({ dateoflastepisode: value }));
          }}
          value={dateOptions.find((item) => item.value === dateoflastepisode)}
          options={dateOptions}
        />
      </div>

      <ReactTooltip
        ref={tooltipRef}
        className="react-tooltip"
        id="listen-notes-custom-date"
        place="top"
        effect="solid"
        afterShow={() => setIsOpen(true)}
        afterHide={() => setIsOpen(false)}
        arrowColor="transparent"
        clickable
        isCapture
      >
        <DateRangePickerWrapper
          value={{
            startDate: customDateValue?.from,
            endDate: customDateValue?.to,
          }}
          handleSelect={({ selection: { startDate, endDate } }) => {
            dispatch(
              setListennotesMetrics({
                customDateValue: {
                  from: startDate,
                  to: endDate,
                },
              })
            );
          }}
        />
        <Button
          size="s"
          onClick={() => {
            // @ts-ignore
            tooltipRef.current.tooltipRef = null;
            setIsOpen(false);
            ReactTooltip.hide();
          }}
          className="listen-notes-metrics__add-date-button"
        >
          Confirm
        </Button>
      </ReactTooltip>

      <SelectRaw
        label="Sort by"
        onChange={({ value }) => {
          dispatch(setListennotesMetrics({ sortBy: value }));
        }}
        value={listenNotesSortByOptions.find((item) => item.value === sortBy)}
        options={listenNotesSortByOptions}
      />

      <Display isVisible={listenScore.min > 0 || (listenScore.max > 0 && listenScore.max < 100)}>
        <div className="listen-notes-metrics-warning">
          <div className="listen-notes-metrics-warning__icon-wrapper">
            <SVGIcon icon="warningRoundedSignIcon" />
          </div>
          Adding restrictions to Listen score significantly reduces the search results.
        </div>
      </Display>
    </MetricsWrapper>
  );
};

export default ListenNotesMetrics;
