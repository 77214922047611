import React, { useEffect, useRef } from 'react';
import { components } from 'react-select';

import useOutsideClick from '@hooks/useOutsideClick';

import { Button } from '@uikit/Button/Button';
import Checkbox from '@uikit/Checkbox/Checkbox';
import Input from '@uikit/Input/Input';

export const createEmailSelectMenu =
  (onAddNewEmail, setIsOpen, selectRef, selectWrapperRef) => (props) => {
    const ref = useRef(null);
    const menuRef = useRef(null);

    const linkStyle = {
      color: '#7974F7',
      fontSize: '14px',
      padding: '10px 0 10px 10px',
      display: 'inline-flex',
      textDecoration: 'none',
    };

    useEffect(() => {
      setIsOpen(true);
      ref.current?.focus?.();
    }, [props.menuIsOpen]);

    useOutsideClick([menuRef, selectWrapperRef], () => {
      return props.selectProps.menuIsOpen ? setIsOpen(undefined) : null;
    });

    return (
      <>
        <components.Menu {...props}>
          <div ref={menuRef}>
            {props.isMulti ? (
              <div className="email-select__search">
                <Input
                  inputRef={ref}
                  icon="search"
                  iconColor="#747474"
                  value={selectRef?.current?.state?.inputValue}
                  onChange={({ target: { value } }) => selectRef?.current?.onInputChange(value)}
                  placeholder="Search"
                  // onFocus={() => setIsOpen(true)}
                  // onBlur={() => setIsOpen(undefined)}
                  onMouseDown={(e) => {
                    e.stopPropagation();
                    setIsOpen(true);
                    e.target.focus();
                  }}
                />
              </div>
            ) : null}
            <div>
              <div>{props.children}</div>
              <div style={{ textAlign: 'center' }}>
                <Button type="ghost" style={linkStyle} onClick={() => onAddNewEmail(true)}>
                  + Add new email
                </Button>
              </div>
            </div>
          </div>
        </components.Menu>
      </>
    );
  };

export const EmailSelectOption = (props) => {
  const { value, selectProps, options, selectOption, label, data, isSelected } = props;

  if (value === 'select-all') {
    const isChecked = () => selectProps.value.length === options.length - 1;

    return (
      <components.Option {...props}>
        <div className="email-select__checkbox-row">
          <span>{label}</span>

          <Checkbox
            onChange={(e) => {
              e.preventDefault();
              e.stopPropagation();

              const inputValue = selectProps?.inputValue;

              if (isChecked()) {
                selectProps.onChange([], {
                  option: { value: 'select-all' }, action: 'deselect-option'
                });
              } else {
                selectOption(data);
              }

              setTimeout(() => {
                selectProps?.onInputChange?.(inputValue);
              }, 1);
            }}
            value={isChecked()}
          />
        </div>
      </components.Option>
    );
  }

  return (
    <components.Option {...props}>
      <div className="email-select__checkbox-row">
        <span>{label}</span>

        <Checkbox
          onChange={(e) => {
            e.preventDefault();
            e.stopPropagation();
            const inputValue = selectProps?.inputValue;
            selectOption(data);
            setTimeout(() => {
              selectProps?.onInputChange?.(inputValue);
            }, 1);
          }}
          value={isSelected || false}
        />
      </div>
    </components.Option>
  );
};
