// @ts-nocheck
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getCurrentWorkspaceId } from 'src/redux/selectors/workspaces.selectors';
import { fetchInquiriesPitched } from 'src/redux/thunks/inquries.thunks';
import { inquiriesPitchedSelector } from 'src/redux/selectors/inquiries.selectors';
import InquiriestList from '../InquiriestList/InquiriestList';

export default (): JSX.Element => {
  const dispatch = useDispatch();
  const [curFakePage, changeFakeCurPage] = useState(0);
  const curWorkspaceId = useSelector(getCurrentWorkspaceId);
  const pitchedState = useSelector(inquiriesPitchedSelector);
  const pitchedIncuries = useMemo(
    () => pitchedState.items.map(({ opportunity }) => opportunity),
    [pitchedState]
  );

  const makeInquiriesRequest = (workspaceId, page) =>
    dispatch(fetchInquiriesPitched(workspaceId, page));

  useEffect(() => {
    makeInquiriesRequest(curWorkspaceId, curFakePage);
  }, [curWorkspaceId]);

  useEffect(() => {
    if (curFakePage > 0) {
      makeInquiriesRequest(curWorkspaceId, curFakePage);
    }
  }, [curWorkspaceId, curFakePage]);

  const loadMore = () => {
    changeFakeCurPage((prevPage) => prevPage + 1);
  };

  return (
    <div className="all-inquiries">
      <InquiriestList
        inquiries={pitchedIncuries}
        loadingStatus={pitchedState.loadingStatus}
        loadMore={loadMore}
        withActions={false}
      />
    </div>
  );
};
