import React from 'react';
import { Link } from 'react-router-dom';

import TextWithIcon from '@uikit/TextWithIcon/TextWithIcon';
import { SVGIcon } from '@uikit/Icon/Icon';

import './GetUltimateCard.scss';

const GetUnlimitedCard = (): JSX.Element => (
  <div className="get-ultimate-card">
    <div className="get-ultimate-card__text-container">
      <div className="get-ultimate-card__icon">
        <SVGIcon icon="infinity" color="#251FB6" size={24} />
      </div>

      <span className="get-ultimate-card__text">Get Unlimited credits and email accounts</span>
    </div>

    <Link to="/settings/billing/change-subscription">
      <TextWithIcon icon="arrowUpCircle" iconColor="#3424EB" iconSize={18}>
        Go unlimited
      </TextWithIcon>
    </Link>
  </div>
);

export default GetUnlimitedCard;
