import React from 'react';
import { useDispatch } from 'react-redux';
import copy from 'copy-to-clipboard';

import { DispatchType } from 'src/store';

import { addNotification } from '@redux/actions/notifications.actions';

import { fromNumberAhrefFormatHelper } from '@helpers/transformDataHelpers';

import { Button } from '@uikit/Button/Button';
import Input from '@uikit/Input/Input';
import HowToUseButton from '@uikit/HowToUseButton/HowToUseButton';
import Loader from '@uikit/Loader/Loader';

import Display from '@components/Display/Display';

import QuestionTooltip from '@pages/CampaignCreationPage/_components/QuestionTooltip/QuestionTooltip';

import CapterraIcon from './_assets/CapterraIcon';
import G2Icon from './_assets/G2Icon';

import './EarcnFreeCredits.scss';
import useReferralLink from '@hooks/useReferralLink';

function EarnFreeCredits() {
  const dispatch = useDispatch<DispatchType>();

  const { item: referralContentData, isLoading } = useReferralLink();

  const handleCopyClick = (text: string) => {
    copy(text);
    dispatch(addNotification({ title: 'Copied to clipboard', type: 'info', delay: 1000 }));
  };

  const refLink = referralContentData?.link;
  const hasRefLink = !!refLink?.length;

  return (
    <>
      <div className="earn-free-credits">
        <div className="earn-free-credits__info">
          <div className="earn-free-credits__info-text">
            <div className="earn-free-credits__info-text-title">
              Refer your friends
              <HowToUseButton
                style={{ marginBottom: '2px' }}
                to="https://help.respona.com/en/articles/7179770-respona-s-referral-program"
              />
            </div>
            <div className="earn-free-credits__info-text-highlight">+ 5,000 credits</div>
            <div className="earn-free-credits__info-text-subtitle">
              Get 5k credits for each new referral who becomes a paying customer.
            </div>
          </div>

          <div className="earn-free-credits__info-link">
            <Loader isLoading={isLoading}>
              <>
                <Display isVisible={hasRefLink}>
                  <Input
                    value={refLink?.replace('https://', '') || ''}
                    disabled
                    onChange={() => null}
                  />
                </Display>
                <Button
                  style={{ marginTop: 0 }}
                  className="earn-free-credits__button earn-free-credits__info-link-button"
                  size="xl"
                  onClick={() => (hasRefLink ? handleCopyClick(refLink) : null)}
                >
                  {!hasRefLink ? 'Get referral link' : 'Copy share link'}
                </Button>
              </>
            </Loader>
          </div>
        </div>

        <div className="earn-free-credits__stats-row">
          <div className="earn-free-credits__stats-item">
            <div className="earn-free-credits__stats-item-text">
              <div className="earn-free-credits__stats-item-title">
                Started trial
                <QuestionTooltip text="Number of referrals who started a free trial of Respona." />
              </div>
              <div className="earn-free-credits__stats-item-value">
                {referralContentData?.startedTrialNumber || '0'}
              </div>
            </div>
          </div>

          <div className="earn-free-credits__stats-item">
            <div className="earn-free-credits__stats-item-text">
              <div className="earn-free-credits__stats-item-title">
                Customers
                <QuestionTooltip text="Number of referrals who completed the trial and became a paying customer of Respona." />
              </div>
              <div className="earn-free-credits__stats-item-value">
                {referralContentData?.paidCustomerNumber || '0'}
              </div>
            </div>
          </div>

          <div className="earn-free-credits__stats-item">
            <div className="earn-free-credits__stats-item-text">
              <div className="earn-free-credits__stats-item-title">
                Credits earned
                <QuestionTooltip text="Total number of bonus credits earned for referrals who became a paying customer." />
              </div>
              <div className="earn-free-credits__stats-item-value">
                {fromNumberAhrefFormatHelper(referralContentData?.totalNumbersNumber)}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="earn-free-credits__review-row">
        <EarnFreeCreditsReviewItem
          icon={<G2Icon />}
          title="G2 review"
          subtitle="Get 5k credits by writing a review on G2. Send us a screenshot via chat to add credits."
          buttonText="Open G2"
          href="https://www.g2.com/products/respona/reviews/start"
        />
        <EarnFreeCreditsReviewItem
          icon={<CapterraIcon />}
          title="Capterra review"
          subtitle="Get 5k credits by writing a review on Capterra. Send us a screenshot via chat to add credits."
          buttonText="Open Capterra"
          href="https://reviews.capterra.com/new/268445"
        />
        {/* <EarnFreeCreditsReviewItem */}
        {/*  icon={<TrustpilotIcon />} */}
        {/*  title="Trustpilot review" */}
        {/*  subtitle="Get 10k credits by writing a review of Respona on Trustpilot. Send a screenshot via chat to add credits." */}
        {/*  buttonText="Open Trustpilot" */}
        {/*  href={null} */}
        {/* /> */}
      </div>
    </>
  );
}

function EarnFreeCreditsReviewItem({
  title,
  subtitle,
  href,
  buttonText,
  icon,
}: {
  title: string;
  subtitle: string;
  buttonText: string;
  href?: string;
  icon: React.ReactNode;
}) {
  return (
    <div className="earn-free-credits__review-item">
      <div className="earn-free-credits__review-item-title">
        {icon}
        {title}
      </div>
      <div className="earn-free-credits__info-text-highlight" style={{ padding: '0 6px' }}>
        + 5,000 credits
      </div>
      <div
        className="earn-free-credits__info-text-subtitle"
        style={{ padding: '0 8px', marginTop: '21px' }}
      >
        {subtitle}
      </div>

      <Button
        disabled={!href}
        onClick={() => window.open(href, '_blank')}
        className="earn-free-credits__button earn-free-credits__review-item-button"
      >
        {buttonText}
      </Button>
    </div>
  );
}

export default EarnFreeCredits;
