import React from 'react';
import { GradientBackground } from '@uikit/GradientBackgroundLoader/GradientBackgroundLoader';
import { FileAttachmentType } from '@ts/common.types';
import getExtensionFromFileName from '@utils/getExtensionFromFileName';

import { SVGIcon } from '@uikit/Icon/Icon';
import Display from '@components/Display/Display';
import FileExtensionIcon from '@uikit/FileExtensionIcon/FileExtensionIcon';
import getFileSizeString from '@utils/getFileSizeString';

import './Attachments.scss';

// TODO: refactor code using FileAttachment component
const Attachments = ({
  isLoading,
  attachments = [],
  onRemoveFile,
}: {
  isLoading: boolean;
  attachments?: FileAttachmentType[];
  onRemoveFile?: (fileUid: string) => void;
}): JSX.Element => {
  if (!attachments.length && !isLoading) {
    return null;
  }

  return (
    <div className="slate-attachments">
      {isLoading ? (
        <GradientBackground className="slate-attachments__loader" width={150} height={50} borderRadius={8} />
      ) : null}

      {attachments.map(({ key, title, size }) => (
        <div className="slate-attachments__file" key={key}>
          <div className="slate-attachments__file-title" title={title}>
            <FileExtensionIcon extension={getExtensionFromFileName(title)} />
            {title}
          </div>
          <div className="slate-attachments__file-size" title={title}>
            {getFileSizeString(size)}
          </div>
          <Display isVisible={onRemoveFile !== undefined}>
            <div
              className="slate-attachments__file-remove"
              onClick={() => onRemoveFile(key)}
              tabIndex={0}
              role="button"
            >
              <SVGIcon icon="crossDelete" />
            </div>
          </Display>
        </div>
      ))}
    </div>
  );
};

export default Attachments;
