import React from 'react';

export default ({ color = 'blue', size = 22 }) => (
  <svg
    width={size}
    height={size + 1}
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 22.9893C17.0751 22.9893 22 18.0644 22 11.9893C22 5.91413 17.0751 0.989258 11 0.989258C4.92487 0.989258 0 5.91413 0 11.9893C0 18.0644 4.92487 22.9893 11 22.9893Z"
      fill={color}
    />
    <path
      d="M7.07031 12.3818L9.68936 15.1318L14.9275 9.63184"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
