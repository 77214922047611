import React, { useEffect } from 'react';
import cn from 'classnames';

import { BacklinksSearchType } from '@ts/automationContent.types';

import * as common_pb from 'respona_api/generated/common_pb';

import {
  BacklinksRel as BACKLINKS_REL,
  BacklinkStatus as BACKLINKS_STATUS,
  BacklinksSearchSource,
  ContentSearchOrderBy,
  IntegrationType,
} from 'respona_api/generated/common_pb';

import { configSelector } from '@redux/selectors/automationContent.selectors';

import NumberInput from '@uikit/Input/NumberInput';
import Select from '@uikit/Select/Select';
import Checkbox from '@uikit/Checkbox/Checkbox';
import Input from '@uikit/Input/Input';

import AdvancedSettingsWrapper from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/AdvancedSettingsWrapper/AdvancedSettingsWrapper';

import './BacklinksSearchAdvancedSettings.scss';

const SelectStyles = {
  control: () => ({
    backgroundColor: 'transparent',
    border: '1px solid #DBDFE7',
    borderRadius: '8px',
    display: 'flex',
    height: '30px',
    width: '215px',
    cursor: 'default',
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#000',
    paddingTop: '13px',
    paddingBottom: '12px',
    width: '215px',
    borderRadius: '8px',
  }),
  input: (provided) => ({
    ...provided,
    paddingTop: 0,
  }),
};

const sortByOptions = [
  { value: ContentSearchOrderBy.RELEVANCE, label: 'Authority' },
  { value: ContentSearchOrderBy.DATE, label: 'Date' },
];

const relOptions = [
  {
    value: BACKLINKS_REL.FOLLOW_AND_NO_FOLLOW,
    label: 'DoFollow & NoFollow',
  },
  {
    value: BACKLINKS_REL.FOLLOW_ONLY,
    label: 'DoFollow',
  },
  {
    value: BACKLINKS_REL.NO_FOLLOW_ONLY,
    label: 'NoFollow',
  },
];

const linkStatusOptions = [
  {
    value: BACKLINKS_STATUS.ALL_BACKLINKS,
    label: 'All',
  },
  {
    value: BACKLINKS_STATUS.LIVE_BACKLINKS,
    label: 'Live',
  },
  {
    value: BACKLINKS_STATUS.LOST_BACKLINKS,
    label: 'Lost',
  },
  {
    value: BACKLINKS_STATUS.LOST_LAST_60_DAYS_BACKLINKS,
    label: 'Lost - last 60 days',
  },
  {
    value: BACKLINKS_STATUS.NEW_LAST_60_DAYS_BACKLINKS,
    label: 'New - last 60 days',
  },
];

// TODO -> make advanced settings more DRY (it is practically the same in all three sections)

function BacklinksSearchAdvancedSettings({
  source,
  settings,
  onChangeSettings,
}: {
  source: common_pb.BacklinksSearchSourceMap[keyof common_pb.BacklinksSearchSourceMap];
  settings: BacklinksSearchType;
  onChangeSettings: (settings: Partial<BacklinksSearchType>) => void;
}): JSX.Element {
  const createFieldChangeHandler = (fieldName: string) => (value: unknown) => {
    onChangeSettings({
      [fieldName]: value,
    });
  };

  useEffect(() => {
    if (!!settings && settings.analyzeResultsNumber !== 100) {
      onChangeSettings({
        analyzeResultsNumber: 1000,
      });
    }
  }, []);

  // @ts-ignore
  const isSemrush = settings?.source === BacklinksSearchSource.SEMRUSH_BACKLINKS_SEARCH;
  const isAhrefs = settings?.source === BacklinksSearchSource.AHREFS_BACKLINKS_SEARCH;
  const isMoz = [
    BacklinksSearchSource.MOZ_BACKLINKS_SEARCH,
    BacklinksSearchSource.RESPONA_BACKLINKS_SEARCH, // @ts-ignore
  ].includes(source);

  const pickMinNumber = () => {
    if (source === BacklinksSearchSource.RESPONA_BACKLINKS_SEARCH) {
      return 10;
    }

    return 100;
  };

  const pickMaxNumber = () => {
    if (source === BacklinksSearchSource.RESPONA_BACKLINKS_SEARCH) {
      return 100;
    }

    return 9999;
  };

  return (
    <AdvancedSettingsWrapper>
      <div
        className={cn('b-s-advanced-settings', {
          // 'b-s-advanced-settings__three-rows': isSemrush,
          // 'b-s-advanced-settings__four-rows': isMoz,
        })}
      >
        <div className="b-s-advanced-settings__item b-s-advanced-settings__top-search-filter">
          Analyze top
          <NumberInput
            value={settings.analyzeResultsNumber}
            maxNumber={pickMaxNumber()}
            minNumber={pickMinNumber()}
            onChange={createFieldChangeHandler('analyzeResultsNumber')}
            disabledArrows
          />
          backlinks per URL
        </div>

        <div className="b-s-advanced-settings__item b-s-advanced-settings__item-grid">
          Rel:
          <div className="b-s-advanced-settings__language-select">
            <Select
              isSearchable={false}
              value={relOptions.find((item) => item.value === settings.rel)}
              options={relOptions}
              additionalStyles={SelectStyles}
              onChange={({ value }) => createFieldChangeHandler('rel')(value)}
            />
          </div>
        </div>

        {(isSemrush || isAhrefs) && (
          <div className="b-s-advanced-settings__item b-s-advanced-settings__item-grid">
            Status:
            <div className="b-s-advanced-settings__language-select">
              <Select
                isSearchable={false}
                value={linkStatusOptions.find((item) => item.value === settings.linkStatus)}
                options={linkStatusOptions}
                additionalStyles={SelectStyles}
                onChange={({ value }) => createFieldChangeHandler('linkStatus')(value)}
              />
            </div>
          </div>
        )}

        {!isMoz && (
          <div className="b-s-advanced-settings__item b-s-advanced-settings__item-grid">
            Sort by
            <div className="b-s-advanced-settings__language-select">
              <Select
                isSearchable={false}
                menuPlacement="top"
                value={sortByOptions.find((item) => item.value === settings.sortBy)}
                options={sortByOptions}
                onChange={(option) => {
                  createFieldChangeHandler('sortBy')(option.value);
                }}
                additionalStyles={SelectStyles}
              />
            </div>
          </div>
        )}

        <div className="b-s-advanced-settings__item b-s-advanced-settings__item-grid">
          Anchor text
          <div className="b-s-advanced-settings__language-select">
            <Input
              placeholder="Contains"
              style={{ width: '215px', marginLeft: 0, textAlign: 'left' }}
              value={settings.optionalAnchor}
              onChange={(e) => {
                createFieldChangeHandler('optionalAnchor')(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="b-s-advanced-settings__item b-s-advanced-settings__item-grid">
          Target URL
          <div className="b-s-advanced-settings__language-select">
            <Input
              placeholder="Contains"
              style={{ width: '215px', marginLeft: 0, textAlign: 'left' }}
              value={settings.optionalTargetUrl}
              onChange={(e) => {
                createFieldChangeHandler('optionalTargetUrl')(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="b-s-advanced-settings__item b-s-advanced-settings__item-grid">
          Source URL
          <div className="b-s-advanced-settings__language-select">
            <Input
              placeholder="Contains"
              style={{ width: '215px', marginLeft: 0, textAlign: 'left' }}
              value={settings.optionalSourceUrl}
              onChange={(e) => {
                createFieldChangeHandler('optionalSourceUrl')(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="b-s-advanced-settings__item b-s-advanced-settings__item-grid">
          Unlinked
          <div className="b-s-advanced-settings__language-select">
            <Input
              placeholder="domain.com"
              style={{ width: '215px', marginLeft: 0, textAlign: 'left' }}
              value={settings.optionalUnlinkedDomain}
              onChange={(e) => {
                createFieldChangeHandler('optionalUnlinkedDomain')(e.target.value);
              }}
            />
          </div>
        </div>

        {isMoz ? (
          <>
            <div className="b-s-advanced-settings__item b-s-advanced-settings__item-grid" />
            <div className="b-s-advanced-settings__item b-s-advanced-settings__item-grid" />
          </>
        ) : null}

        {/* Checkboxes */}

        <div className="b-s-advanced-settings__item">
          <Checkbox
            onChange={() => {
              createFieldChangeHandler('oneResultPerDomain')(!settings.oneResultPerDomain);
            }}
            value={settings.oneResultPerDomain}
          />
          Only one link per domain
        </div>

        <div className="b-s-advanced-settings__item max-label-width">
          <Checkbox
            onChange={() => {
              createFieldChangeHandler('ignoreAlreadyContacted')(!settings.ignoreAlreadyContacted);
            }}
            value={settings.ignoreAlreadyContacted}
          />
          Ignore domains contacted within
          <NumberInput
            value={settings.optionalIgnoreAlreadyContactedDays}
            maxNumber={999}
            minNumber={1}
            onChange={createFieldChangeHandler('optionalIgnoreAlreadyContactedDays')}
            disabledArrows
          />
          days
        </div>
        <div className="b-s-advanced-settings__item">
          <Checkbox
            onChange={() => {
              createFieldChangeHandler('ignoreUnsubscribed')(!settings.ignoreUnsubscribed);
            }}
            value={settings.ignoreUnsubscribed}
          />
          Ignore domains included in the Unsubscribed list
        </div>
        <div className="b-s-advanced-settings__item">
          <Checkbox
            onChange={() => {
              createFieldChangeHandler('ignoreActive')(!settings.ignoreActive);
            }}
            value={settings.ignoreActive}
          />
          Ignore domains included in other active/paused campaigns
        </div>
        <div className="k-s-advanced-settings__item">
          <Checkbox
            onChange={() => {
              createFieldChangeHandler('ignoreHomepages')(!settings.ignoreHomepages);
            }}
            value={settings.ignoreHomepages}
          />
          Ignore homepages
        </div>
      </div>
    </AdvancedSettingsWrapper>
  );
}

export default BacklinksSearchAdvancedSettings;
