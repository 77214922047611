import React from 'react';

export default ({color = '#ffffff', size = 16}):JSX.Element => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_22619_17719)">
      <path
        d="M7.5 14.5002L10.64 7.17018C10.6696 7.0991 10.7196 7.03839 10.7837 6.99568C10.8477 6.95297 10.923 6.93018 11 6.93018C11.077 6.93018 11.1523 6.95297 11.2163 6.99568C11.2804 7.03839 11.3304 7.0991 11.36 7.17018L14.5 14.5002"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.90991 11.21H13.0899"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.5 3.5H10.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 1.5V3.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 3.5L3 10.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 3.5L6.94 8.1"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_22619_17719">
        <rect width="14" height="14" fill="white" transform="translate(1 1)" />
      </clipPath>
    </defs>
  </svg>
);
