import React from 'react';

export default () => (
  <svg width="26" height="23" viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.99666 5.15489H22.2391C22.7767 5.15489 23.2922 5.36844 23.6723 5.74857C24.0525 6.12869 24.266 6.64425 24.266 7.18183V19.3434C24.266 19.881 24.0525 20.3966 23.6723 20.7767C23.2922 21.1568 22.7767 21.3704 22.2391 21.3704H3.99666C3.45909 21.3704 2.94353 21.1568 2.5634 20.7767C2.18328 20.3966 1.96973 19.881 1.96973 19.3434V7.18183C1.96973 6.64425 2.18328 6.12869 2.5634 5.74857C2.94353 5.36844 3.45909 5.15489 3.99666 5.15489V5.15489Z"
      stroke="#221CB6"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.0908 10.2223L16.1582 13.2627L11.0908 16.3031V10.2223Z"
      stroke="#221CB6"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.212 1.10107H6.02344"
      stroke="#221CB6"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
