export const transformDataWithIdAndTittleToOptions = (
  itemsList: any[]
): { label: string; value: number }[] =>
  itemsList.map(({ id, title }) => ({ label: title, value: id }));

export const fromNumberAhrefFormatHelper = (value: number): string => {
  if (value === -1) {
    return '-';
  }

  if (value >= 1000000) {
    return transformByNumberRank(value, 1000000, 'M');
  }

  if (value >= 1000) {
    return transformByNumberRank(value, 1000, 'K');
  }

  return !value && value !== 0 ? 'undefined' : value.toFixed(0);
};

/*
  Helper for fromNumberAhrefFormatHelper
 */
function transformByNumberRank(value, order, letter) {
  const newValue = value / order;

  if (!Number.isInteger(newValue)) {
    return `${newValue.toFixed(1)}${letter}`;
  }

  return `${newValue}${letter}`;
}
