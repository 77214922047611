import useWorkspaceMembers from '@hooks/useWorkspaceMembers';
import Loader from '@uikit/Loader/Loader';
import { getFirstNamePlusBigFirstCharOfLastName } from '@utils/string.utils';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DispatchType } from 'src/store';

import debounce from '@utils/debounce';

import { FilterJoins, FilterOperationsMapForString } from '@constants/filterOperations';
import { GREY_COLOR, PRIMARY_COLOR } from '@constants/colors';

import {
  setExtendedFiltersCampaign,
  setTittleForFilterCampaign,
} from '@redux/actions/campaigns.actions';
import {
  campaignsFiltersSelector,
  campaignsFoldersSelector,
  campaignsTittleFilterSelector,
} from '@redux/selectors/campaigns.selectors';

import Input from '@uikit/Input/Input';

import HeaderFiltersButtonWithTooltip from '@components/PageHeader/HeaderFiltersButtonWithTooltip/HeaderFiltersButtonWithTooltip';
import RelationshipsFiltersTooltipContentFooter from '@components/Relationships/RelationshipsFiltersTooltipContent/RelationshipsFiltersTooltipContentFooter/RelationshipsFiltersTooltipContentFooter';
import RelationshipsFiltersTooltipContentRaw from '@components/Relationships/RelationshipsFiltersTooltipContent/RelationshipsFiltersTooltipContentRaw/RelationshipsFiltersTooltipContentRaw';

import './CampaignSearch.scss';
import { CampaignBatchOperations } from 'respona_api/generated/campaign_pb';
import useOutsideClick from '@hooks/useOutsideClick';
import MoreActionsButton from '@components/CampainsHeader/MoreActionsButton/MoreActionsButton';
import { CampaignType } from '@ts/campaigns.types';
import Button from '@uikit/Button/Button';
import CampaignBatchAction = CampaignBatchOperations.CampaignBatchAction;
import { getCampaignFilterFields } from '@constants/campaignFilterFields';

const initialFilterState = {
  '1': {
    order: 0,
    field: getCampaignFilterFields([], [])[0].value,
    value: '',
    operation: FilterOperationsMapForString[2].value,
    join: FilterJoins[0].value,
  },
};

function CampaignsSearch({
  selectedCampaigns,
  handleCampaignBatchAction,
  setOpenMoveToFolderModal,
  setOpenChangeOwnerModal,
}: {
  selectedCampaigns: CampaignType[];
  handleCampaignBatchAction: (
    action: CampaignBatchOperations.CampaignBatchActionMap[keyof CampaignBatchOperations.CampaignBatchActionMap],
    optDestinationId?: number
  ) => void;
  setOpenMoveToFolderModal: any;
  setOpenChangeOwnerModal: any;
}): JSX.Element {
  const dispatch = useDispatch<DispatchType>();
  const confirmBtnRef = useRef<HTMLButtonElement>();
  const titleSearch = useSelector(campaignsTittleFilterSelector);
  const { extendedFilters: filtersFromRedux } = useSelector(campaignsFiltersSelector);
  const { members, isFetching } = useWorkspaceMembers();
  const { items: campaignFolders, loadingStatus: campaignFoldersLs } =
    useSelector(campaignsFoldersSelector);
  const [filters, changeFilters] = useState(initialFilterState);
  const [searchString, changeSearchString] = useState('');
  const [isVisibleRemoveApproval, setVisibleRemoveApproval] = useState<boolean>(false);

  useOutsideClick(confirmBtnRef, () => setVisibleRemoveApproval(false));

  useEffect(() => {
    changeSearchString(titleSearch || '');
  }, []);

  const debounedSetReduxSearchString = useCallback(
    debounce((value) => dispatch(setTittleForFilterCampaign(value)), 500),
    []
  );

  const handleSearchChange = ({ target: { value } }) => {
    changeSearchString(value);
    debounedSetReduxSearchString(value);
  };

  const handleClear = () => {
    changeSearchString('');
    dispatch(setTittleForFilterCampaign(''));
  };

  const handleAddFilterInput = () =>
    changeFilters((prevState) => {
      const keys = Object.keys(prevState);
      const newKey = Number(keys[keys.length - 1]) + 1;

      return { ...prevState, [newKey]: initialFilterState['1'] };
    });

  const handleApplyFilters = () => {
    const filtersArray = Object.values(filters);

    if (filtersArray.some(({ value }) => value === '')) {
      // dispatch(addNotification({ type: 'warning', title: 'Some filter fields are empty' }));
      return;
    }

    dispatch(setExtendedFiltersCampaign(filtersArray));
  };

  const handleChangeFilterState = (filterRawKey, filterInputKey, value) => {
    changeFilters((prevState) => {
      return {
        ...prevState,
        [filterRawKey]: {
          ...prevState[filterRawKey],
          [filterInputKey]: value,
        },
      };
    });
  };

  const createHandleDeleteFilterRaw = (dummy: number) => () => {
    changeFilters((prevState) => {
      const newState = { ...prevState };
      delete newState[dummy];
      return newState;
    });
  };

  const areFiltersEnabled = useMemo(
    () => Object.values(filtersFromRedux).some(({ value }) => value.toString().length > 0),
    [filtersFromRedux]
  );

  if (isFetching) {
    return <Loader isLoading />;
  }

  return (
    <>
      <Input
        className="campaigns-header__search"
        isClearable
        icon="search"
        iconColor={searchString ? PRIMARY_COLOR : GREY_COLOR}
        value={searchString}
        onChange={handleSearchChange}
        onClear={handleClear}
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            e.stopPropagation();
          }
        }}
        placeholder="Search"
      />
      {selectedCampaigns.length ? (
        <MoreActionsButton
          onCreateOrMoveFolder={() => setOpenMoveToFolderModal(true)}
          onChangeOwner={() => setOpenChangeOwnerModal(true)}
          folders={campaignFolders}
        />
      ) : null}
      {!selectedCampaigns.length ? null : isVisibleRemoveApproval ? (
        <span ref={confirmBtnRef}>
          <Button
            // isLoading={isDeleting}
            leftIcon="trash"
            type="alert"
            onClick={() => handleCampaignBatchAction(CampaignBatchAction.DELETE)}
          >
            Confirm
          </Button>
        </span>
      ) : (
        <Button leftIcon="trash" type="alert" onClick={() => setVisibleRemoveApproval(true)}>
          Remove
        </Button>
      )}
      <HeaderFiltersButtonWithTooltip
        areFiltersEnabled={areFiltersEnabled}
        onClearAllFilters={() => {
          changeFilters(initialFilterState);
          dispatch(setExtendedFiltersCampaign([]));
        }}
      >
        <div className="campaign-filters-tooltip-content">
          <div className="campaign-filters-tooltip-content__body">
            {Object.entries(filters).map((filter, index) => {
              const filterId = Number(filter[0]);
              const filterRawData = filter[1];

              return (
                <RelationshipsFiltersTooltipContentRaw
                  key={filterId}
                  rawId={filterId}
                  isAdditional={!!index}
                  onDeleteFilterInput={createHandleDeleteFilterRaw(filterId)}
                  filterRawData={filterRawData}
                  onChangeFilter={handleChangeFilterState}
                  filterFields={getCampaignFilterFields(
                    members.map((item) => ({
                      value: item.userId,
                      label: getFirstNamePlusBigFirstCharOfLastName(item.fullName),
                    })),
                    campaignFolders.map((item) => ({
                      value: item.id,
                      label: item.title,
                    }))
                  )}
                />
              );
            })}
          </div>
          <RelationshipsFiltersTooltipContentFooter
            onAddFilterInput={handleAddFilterInput}
            onApplyFilters={handleApplyFilters}
          />
        </div>
      </HeaderFiltersButtonWithTooltip>
    </>
  );
}

export default CampaignsSearch;
