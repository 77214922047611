import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getCurrentWorkspaceId } from 'src/redux/selectors/workspaces.selectors';
import { fetchInquiriesBookmarks } from 'src/redux/thunks/inquries.thunks';
import { inquiriesBookmarksSelector } from 'src/redux/selectors/inquiries.selectors';
import InquiriestList from '../InquiriestList/InquiriestList';

export default ({ onPitch }: { onPitch: (number) => void }): JSX.Element => {
  const dispatch = useDispatch();
  const [curFakePage, changeFakeCurPage] = useState(0);
  const curWorkspaceId = useSelector(getCurrentWorkspaceId);
  const bookmarks = useSelector(inquiriesBookmarksSelector);

  const makeInquiriesRequest = (workspaceId, page) =>
    dispatch(fetchInquiriesBookmarks(workspaceId, page));

  useEffect(() => {
    makeInquiriesRequest(curWorkspaceId, curFakePage);
  }, []);

  useEffect(() => {
    if (curFakePage > 0) {
      makeInquiriesRequest(curWorkspaceId, curFakePage);
    }
  }, [curFakePage]);

  const loadMore = () => {
    changeFakeCurPage((prevPage) => prevPage + 1);
  };

  return (
    <div className="all-inquiries">
      <InquiriestList
        inquiries={bookmarks.items}
        loadingStatus={bookmarks.loadingStatus}
        loadMore={loadMore}
        onPitch={onPitch}
      />
    </div>
  );
};
