import { useCallback, useState } from 'react';

const useOpportunitySections = () => {
  const [opportunitySections, setOpportunitySections] = useState<{ id: number; open: boolean }[]>(
    []
  );

  const getOpportunitySectionState = useCallback(
    (id: number): boolean => {
      const section = opportunitySections.find((section) => section.id === id);
      return section ? section.open : false;
    },
    [opportunitySections]
  );

  const changeOpenOpportunitySectionState = useCallback((id: number) => {
    setOpportunitySections((prevSections) => {
      const sectionIndex = prevSections.findIndex((section) => section.id === id);
      if (sectionIndex !== -1) {
        return prevSections.map((section, index) =>
          index === sectionIndex ? { ...section, open: !section.open } : section
        );
      }
      return [...prevSections, { id, open: true }];
    });
  }, []);

  return { opportunitySections, getOpportunitySectionState, changeOpenOpportunitySectionState };
};

export default useOpportunitySections;
