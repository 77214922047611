import { createAction } from 'redux-actions';
import { WorkspaceShortType } from '@ts/userOrganization.types';

export const changeCurrentWorkspace = createAction<number>('CHANGE_CURRENT_WORKSPACE');

export const setWorkspaces = createAction<WorkspaceShortType[]>('SET_WORKSPACES');

export const fetchWorkspaces = createAction('FETCH_WORKSPACES');
export const changeWorkspace = createAction<WorkspaceShortType>('CHANGE_WORKSPACE');
export const createWorkspace = createAction<WorkspaceShortType>('CREATE_WORKSPACE');
export const deleteWorkspace = createAction<number>('DELETE_WORKSPACE');
