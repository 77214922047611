import createAction from '@redux/utils/createAction';
import { createAction as reduxCreateAction } from 'redux-actions';

import { CampaignVerificationStatusType, OpenCampaignType } from '@ts/campaigns.types';
import { CampaignOpportunityStatusMapType } from '@ts/opportunity.types';

export const CS_TOGGLE_FILTERS = 'CS_TOGGLE_FILTERS';
export const CS_TOGGLE_IMPORT_FLOW = 'CS_TOGGLE_IMPORT_FLOW';
export const CS_SET_SOURCE_TYPE = 'CS_SET_SOURCE_TYPE';

export const CS_SET_ACTIVE_CAMPAIGN_INFO = 'CS_SET_ACTIVE_CAMPAIGN_INFO';
export const CS_SET_ACTIVE_CAMPAIGN_LS = 'CS_SET_ACTIVE_CAMPAIGN_LS';
export const CS_SET_OPEN_CAMPAIGN_INFO = 'CS_SET_OPEN_CAMPAIGN_INFO';
export const CS_SET_OPEN_CAMPAIGN_ERROR = 'CS_SET_OPEN_CAMPAIGN_ERROR';

export const CS_ADD_CAMPAIGN_OPPORTUNITIES_PREVIEW_ITEM =
  'CS_ADD_CAMPAIGN_OPPORTUNITIES_PREVIEW_ITEM';
export const CS_REMOVE_CAMPAIGN_OPPORTUNITIES_PREVIEW_ITEM =
  'CS_REMOVE_CAMPAIGN_OPPORTUNITIES_PREVIEW_ITEM';
export const CS_ADD_CAMPAIGN_OPPORTUNITIES_PREVIEW_ITEM_WITH_CONTACT =
  'CS_ADD_CAMPAIGN_OPPORTUNITIES_PREVIEW_ITEM_WITH_CONTACT';
export const CS_REMOVE_CAMPAIGN_OPPORTUNITIES_PREVIEW_ITEM_WITH_CONTACT =
  'CS_REMOVE_CAMPAIGN_OPPORTUNITIES_PREVIEW_ITEM_WITH_CONTACT';

export const CS_SET_CAMPAIGN_OPPORTUNITIES = 'CS_SET_CAMPAIGN_OPPORTUNITIES';
export const CS_ADD_CAMPAIGN_OPPORTUNITIES = 'CS_ADD_CAMPAIGN_OPPORTUNITIES';
export const CS_REMOVE_CAMPAIGN_OPPORTUNITIES = 'CS_REMOVE_CAMPAIGN_OPPORTUNITIES';
export const CS_SET_LS_CAMPAIGN_OPPORTUNITIES = 'CS_SET_LS_CAMPAIGN_OPPORTUNITIES';
export const CS_SET_CAMPAIGN_OPPORTUNITIES_CONTACTS = 'CS_SET_CAMPAIGN_OPPORTUNITIES_CONTACTS';
export const CS_SET_CAMPAIGN_OPPORTUNITIES_CONTACTS_LS =
  'CS_SET_CAMPAIGN_OPPORTUNITIES_CONTACTS_LS';
export const CS_SET_CAMPAIGN_OPPORTUNITY_PERSONALIZED = 'CS_SET_CAMPAIGN_OPPORTUNITY_PERSONALIZED';

export const CS_SET_FILTER_VALUE = 'CS_SET_FILTER_VALUE';

export const CS_SET_VERIFY_STATUS = 'CS_SET_VERIFY_STATUS';
export const CS_SET_VERIFY_PROGRESS = 'CS_SET_VERIFY_PROGRESS';

export const CS_UPDATE_CAMPAIGN_SELECTED_OPPORTUNITY_VERIFICATION_STATUS =
  'CS_UPDATE_CAMPAIGN_SELECTED_OPPORTUNITY_VERIFICATION_STATUS';
export const CS_THROW_VERIFICATION_STATUS_ALL_CAMPAIGN_SELECTED_OPPORTUNITIES =
  'CS_THROW_VERIFICATION_STATUS_ALL_CAMPAIGN_SELECTED_OPPORTUNITIES';

/**
 * View and params actions
 */
export const csToggleFilters = createAction(CS_TOGGLE_FILTERS);
export const csToggleImportFlow = createAction<boolean>(CS_TOGGLE_IMPORT_FLOW);
export const csSetSourceType = createAction<{ label: string; value: string }>(CS_SET_SOURCE_TYPE);

/**
 * Main info actions
 */
export const csSetActiveCampaignLs = createAction<string>(CS_SET_ACTIVE_CAMPAIGN_LS);
export const csSetOpenCampaignInfo = createAction<OpenCampaignType>(CS_SET_OPEN_CAMPAIGN_INFO);
export const csSetOpenCampaignError = createAction<OpenCampaignType>(CS_SET_OPEN_CAMPAIGN_ERROR);

/**
 * Opportunities actions
 */
export const csSetCampaignOpportunities = createAction<any>(CS_SET_CAMPAIGN_OPPORTUNITIES);
export const csSetCampaignOpportunitiesContacts = createAction<any>(
  CS_SET_CAMPAIGN_OPPORTUNITIES_CONTACTS
);
export const csSetCampaignOpportunitiesContactsLs = createAction<{
  opportunityId: number;
  loadingStatus: string;
}>(CS_SET_CAMPAIGN_OPPORTUNITIES_CONTACTS_LS);
export const csSetLsCampaignOpportunities = createAction<string>(CS_SET_LS_CAMPAIGN_OPPORTUNITIES);
export const csAddCampaignOpportunities = createAction<{ [key: string]: any }[]>(
  CS_ADD_CAMPAIGN_OPPORTUNITIES
);
export const csRemoveCampaignOpportunities = createAction<number[] | string[]>(
  CS_REMOVE_CAMPAIGN_OPPORTUNITIES
);
export const csSetCampaignOpportunityPersonalized = createAction<{
  opportunityId: number;
  hasPersonalized: boolean;
}>(CS_SET_CAMPAIGN_OPPORTUNITY_PERSONALIZED);
export const csSetFilterValue = createAction<{
  filterKey: string;
  filterValue: any;
}>(CS_SET_FILTER_VALUE);

export const csSetActiveCampaignVerifyStatus = createAction<{
  statusNumber: CampaignVerificationStatusType;
}>(CS_SET_VERIFY_STATUS);

export const csSetActiveCampaignVerifyProgress = createAction<{
  progress: number;
  totalNumber: number;
  verifiedNumber: number;
  flaggedNumber: number;
}>(CS_SET_VERIFY_PROGRESS);

export const csClearOpenCampaignInfo = reduxCreateAction('CS_CLEAR_OPEN_CAMPAIGN');

export const csUpdateCampaignSelectedOpportunitiesVerificationStatus = createAction<{
  opportunityId: number;
  newStatus: CampaignOpportunityStatusMapType;
}>(CS_UPDATE_CAMPAIGN_SELECTED_OPPORTUNITY_VERIFICATION_STATUS);

export const csThrowVerificationStatusInAllCampaignSelectedOpportunities = createAction(
  CS_THROW_VERIFICATION_STATUS_ALL_CAMPAIGN_SELECTED_OPPORTUNITIES
);
