import store from 'src/store';
import requestErrorParser from '@helpers/requestErrorParser';
import { authService } from 'src/services/authService';
import { ApiError } from 'src/errors/ApiError';
import getMetadata from '@api/base/getMetadata';
import { isInternalPage } from '@utils/redirections';

declare const IS_DEV: boolean;
declare const HIDE_NETWORK_LOGS: boolean;

const GOD_MODE = localStorage.getItem('GOD_MODE') || false;

export default <T>(client, method, request = null): Promise<T> => {
  if (IS_DEV || GOD_MODE) {
    if (HIDE_NETWORK_LOGS !== true) {
      console.group(`>> ${method.name}`);
      if (request != null) {
        console.info(request.array);
      }
      console.groupEnd();
    }
  }
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('token');
    const metadata = getMetadata(token);

    const resData = [];
    const stream = method.call(client, request, metadata);

    stream.on('data', (res) => {
      resData.push(res.toObject());
    });

    stream.on('status', (status) => {
      if (status.code !== 0) {
        if (IS_DEV || GOD_MODE) {
          if (HIDE_NETWORK_LOGS !== true) {
            console.group(`<< ${method.name}`);
            console.error(status);
            console.groupEnd();
          }
        }

        const errorMessage = status.errorMessage || status.message || status.details;

        if (errorMessage) {
          store.dispatch(requestErrorParser(errorMessage, false));
        }

        if (status.code === 16 && isInternalPage(window.location.pathname)) {
          authService.logout();
        }
        reject(new ApiError(errorMessage, status.code, status));
      }
    });

    stream.on('end', () => {
      if (IS_DEV || GOD_MODE) {
        if (HIDE_NETWORK_LOGS !== true) {
          console.group(`<< ${method.name}`);
          console.info(resData);
          console.groupEnd();
        }
      }

      // @ts-ignore
      resolve(resData);
    });
  });
};
