import React from 'react';

export default ({ color = '#fff' }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.4667 4.00016V4.20016H10.6667H13.3333C13.4571 4.20016 13.5758 4.24933 13.6633 4.33685C13.7508 4.42436 13.8 4.54306 13.8 4.66683C13.8 4.7906 13.7508 4.9093 13.6633 4.99681C13.5758 5.08433 13.4571 5.1335 13.3333 5.1335H12.6667H12.4667V5.3335V12.6668C12.4667 13.1442 12.277 13.6021 11.9395 13.9396C11.6019 14.2772 11.1441 14.4668 10.6667 14.4668H5.33333C4.85594 14.4668 4.39811 14.2772 4.06054 13.9396C3.72298 13.6021 3.53333 13.1442 3.53333 12.6668V5.3335V5.1335H3.33333H2.66667C2.5429 5.1335 2.4242 5.08433 2.33668 4.99681C2.24917 4.9093 2.2 4.7906 2.2 4.66683C2.2 4.54306 2.24917 4.42436 2.33668 4.33685C2.4242 4.24933 2.5429 4.20016 2.66667 4.20016H5.33333H5.53333V4.00016V3.3335C5.53333 2.85611 5.72298 2.39827 6.06054 2.0607C6.39811 1.72314 6.85594 1.5335 7.33333 1.5335H8.66667C9.14406 1.5335 9.60189 1.72314 9.93946 2.0607C10.277 2.39827 10.4667 2.85611 10.4667 3.3335V4.00016ZM9.33333 4.20016H9.53333V4.00016V3.3335C9.53333 3.10364 9.44202 2.8832 9.27949 2.72067C9.11696 2.55814 8.89652 2.46683 8.66667 2.46683H7.33333C7.10348 2.46683 6.88304 2.55814 6.72051 2.72067C6.55798 2.8832 6.46667 3.10364 6.46667 3.3335V4.00016V4.20016H6.66667H9.33333ZM4.66667 5.1335H4.46667V5.3335V12.6668C4.46667 12.8967 4.55798 13.1171 4.72051 13.2797C4.88304 13.4422 5.10348 13.5335 5.33333 13.5335H10.6667C10.8965 13.5335 11.117 13.4422 11.2795 13.2797C11.442 13.1171 11.5333 12.8967 11.5333 12.6668V5.3335V5.1335H11.3333H4.66667Z"
      fill={color}
      stroke={color}
      strokeWidth="0.4"
    />
  </svg>
);
