import { createAction } from 'redux-actions';

import { DateRangeNumberType } from '@ts/common.types';

export const setInsightsDateFilter = createAction<DateRangeNumberType>('SET_INSIGHTS_DATE_FILTER');
export const setInsightsFiltersPeopleIds = createAction<number[]>(
  'SET_INSIGHTS_FILTERS_PEOPLE_IDS'
);
export const setInsightsFiltersCampaignIds = createAction<number[]>(
  'SET_INSIGHTS_FILTERS_CAMPAIGN_IDS'
);

export const throwInsightsFilter = createAction<void>('THROW_INSIGHTS_FILTERS');

export const setInsightsCampaignPerformanceFilters = createAction<string>(
  'SET_INSIGHTS_CAMPAIGN_PERFORMANCE_FILTERS'
);

export const setInsightsLeaderBoardsFilters = createAction<string>(
  'SET_INSIGHTS_LEADER_BOARDS_FILTERS'
);

export const setInsightsCreditsUsageByUserFilters = createAction<string>(
  'SET_INSIGHTS_CREDITS_USAGE_BY_USER_FILTERS'
);
