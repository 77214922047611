import { SearchItem, UrlItem } from 'respona_api/generated/search-content_pb';

export const SearchTypesEnum = {
  googleSearch: 'googleSearch',
  googleNews: 'googleNews',
  bingSearch: 'bingSearch',
  bingNews: 'bingNews',
  podcasts: 'podcasts',
  episodes: 'episodes',
};

export enum SearchItemTypeEnum {
  WEB = 0,
  NEWS = 1,
  PODCAST = 2,
  EPISODE = 3,
  TRENDING_CONTENT = 4,
  BLOG = 5,
  IMPORT = 6,
}

export type WebSearchItemType = {
  organicTraffic: number;
  backlinksTotalNumber: number;
  backlinksNofollowNumber: number;
  backlinksDofollowNumber: number;
  domainRating: number;
  urlRating: number;
};

export type NewsSearchItemType = {
  organicTraffic: number;
  backlinksTotalNumber: number;
  backlinksNofollowNumber: number;
  backlinksDofollowNumber: number;
  domainRating: number;
  urlRating: number;
  publishingDate: string;
};

export type PodcastSearchItemType = {
  rrsUrl: string;
  imageUrl: string;
  iTunesUrl: string;
  publishingDate: string;
  publisherName: string;
};

export type EpisodeSearchItemType = {
  podcastUid: string;
  rrsUrl: string;
  audioUrl: string;
  imageUrl: string;
  iTunesUrl: string;
  publishingDate: string;
  publisherName: string;
};

export type TrendingContentSearchItemType = {
  pinterestSharesNumber: number;
  twitterSharesNumber: number;
  trendingScore: number;
  redditEngagementsNumber: number;
  facebookLikesNumber: number;
  facebookCommentsNumber: number;
  facebookSharesNumber: number;
  thumbnail: string;
  updatedAtTimestamp: number;
  publishedAtTimestamp: number;
  totalShares: number;
};

export type BlogSearchItemType = {
  organicTraffic: number;
  backlinksTotalNumber: number;
  backlinksNofollowNumber: number;
  backlinksDofollowNumber: number;
  domainRating: number;
  urlRating: number;
  crawlingDate: string;
};

export type SearchItemType = SearchItem.AsObject;

export type UrlItemType = UrlItem.AsObject;
