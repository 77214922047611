import { Dispatch } from 'redux';

function createThunkMiddleware(extraArgument = {}) {
  return ({ dispatch, getState }: { dispatch: Dispatch; getState: any }) =>
    (next: Dispatch) =>
    (action) => {
      if (typeof action === 'function') {
        return action(dispatch, getState, extraArgument);
      }

      return next(action);
    };
}

const thunk = createThunkMiddleware();
export const withExtraArgument = createThunkMiddleware;

export default thunk;
