import React, { useRef, useState } from 'react';
import { FilterOperation } from 'respona_api/generated/common_pb';

import { getDateShort } from '@utils/date';
import DateRangeSelect from '@uikit/DateRangeSelect/DateRangeSelect';

import Select from '@uikit/Select/Select';
import Input from '@uikit/Input/Input';

import selectStyles from '@components/Relationships/RelationshipsFiltersTooltipContent/RelationshipsFiltersTooltipContentRaw/selectStyles';
import { FilterProps } from '@ts/props.types';
import AsyncSelect from '@uikit/Select/AsyncSelect';

type TypedInputPropsType = {
  type: string;
  value: string | number;
  onChange: (value: string | number) => void;
  placeholder: string;
  filterRawData: any;
  filterFields: FilterProps;
};

function TypedInput({
  type = 'string',
  value,
  onChange,
  filterRawData,
  placeholder,
  filterFields,
}: TypedInputPropsType) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [datePickerVisible, setDatePickerVisible] = useState<boolean>(false);

  if (type === 'number') {
    const defaultNumber = '10';
    const maxNumber = 100;

    // Check string for number
    const numberString = Number.isNaN(Number(value)) ? defaultNumber : value;
    //  Check to be greater than 0
    const positiveValue = Number(numberString) < 0 ? defaultNumber : numberString;

    const handleChangeInputNumberValue = ({ target: { value: inputValue } }) => {
      if (Number.isNaN(Number(inputValue))) {
        onChange(defaultNumber);
        return;
      }

      if (Number(inputValue) > maxNumber) {
        onChange(String(maxNumber));
        return;
      }

      onChange(inputValue);
    };

    return (
      <Input
        inputRef={inputRef}
        className="filters-tooltip-raw__input"
        value={positiveValue}
        placeholder={placeholder}
        onChange={handleChangeInputNumberValue}
        isTransparent
      />
    );
  }

  if (type === 'boolean') {
    // For this type we should understand what it should be looking like
  }

  if (type === 'enumStringAsync') {
    return (
      <AsyncSelect
        loadOptions={filterFields.loadOptions}
        defaultOptions={filterFields.inputOptions}
        className="filters-tooltip-raw__input"
        value={value}
        additionalStyles={selectStyles}
        onChange={onChange}
      />
    );
  }

  if (type === 'enum' || type === 'enumString') {
    return (
      <Select
        defaultValue={filterFields.inputOptions?.[0] || null}
        className="filters-tooltip-raw__input"
        additionalStyles={selectStyles}
        options={filterFields.inputOptions || []}
        value={value}
        onChange={onChange}
      />
    );
  }

  if (type === 'date') {
    return (
      <>
        <Input
          inputRef={inputRef}
          className="filters-tooltip-raw__input"
          value={value ? getDateShort(new Date(value)) : ''}
          placeholder="Select date"
          onChange={() => null}
          onClick={() => setDatePickerVisible(!datePickerVisible)}
          isTransparent
        />

        <DateRangeSelect
          onChange={(val) => onChange(+val)}
          isVisible={datePickerVisible}
          value={{ startDate: Number(value), endDate: null }}
          onChangeIsVisible={setDatePickerVisible}
          isRangePicker={false}
          style={{
            top: '30px',
            left: '-265px',
          }}
          isReverseCheck={filterRawData.operation === FilterOperation.NUMBER_GREATER_THAN_OR_EQUAL}
        />
      </>
    );
  }

  const handleChangeInputStringValue = ({ target: { value: inputValue } }) => onChange(inputValue);

  const isEmptyOption = [
    FilterOperation.STRING_IS_BLANK,
    FilterOperation.STRING_IS_NOT_BLANK,
  ].includes(filterRawData.operation);

  if (isEmptyOption) {
    return null;
  }

  return (
    <Input
      inputRef={inputRef}
      className="filters-tooltip-raw__input"
      value={value}
      placeholder={placeholder}
      onChange={handleChangeInputStringValue}
      isTransparent
    />
  );
}

export default TypedInput;
