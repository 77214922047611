import React from 'react';

import { fitNumberIntoMinMaxRange } from '@helpers/subscriptionHelpers';

import './PoorNumberInput.scss';

function PoorNumberInput({
  value,
  onChange,
  onlyPositive = false,
  onChangeFailed,
  onBlur,
  min = 0,
  max = 100,
  step = 1,
  capLimits = true,
}: {
  value: number;
  onChange: (value: number, evt?: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeFailed?: (value: number) => void;
  onBlur?: (value: number) => void;
  onlyPositive?: boolean;
  min?: number;
  max?: number;
  step?: number;
  capLimits?: boolean;
}): JSX.Element {
  const handleInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const numValue = Number(evt.target.value);

    if (!Number.isNaN(numValue)) {
      if (capLimits && numValue >= max) {
        onChange(max);
        return;
      }

      if (capLimits && numValue <= min) {
        onChange(min);
        return;
      }

      onChange(Math.abs(numValue), evt);
    }
  };

  const handleDecreaseClick = () => {
    const newValue = capLimits ? value - step : fitNumberIntoMinMaxRange(value - step, min, max);

    if (value > min) {
      onChange(newValue);
      onBlur && onBlur(newValue);
    } else if (onChangeFailed) {
      onChangeFailed(newValue);
    }
  };

  const handleIncreaseClick = () => {
    const newValue = capLimits ? value + step : fitNumberIntoMinMaxRange(value + step, min, max);
    if (value < max) {
      onChange(newValue);
      onBlur && onBlur(newValue);
    } else if (onChangeFailed) {
      onChangeFailed(newValue);
    }
  };

  return (
    <div className="poor-number-input">
      <div className="poor-number-input__decrease-btn" onClick={handleDecreaseClick}>
        -
      </div>
      <input
        className="poor-number-input__field"
        type="text"
        value={value}
        onChange={handleInputChange}
        onBlur={() => onBlur && onBlur(value)}
      />
      <div className="poor-number-input__increase-btn" onClick={handleIncreaseClick}>
        +
      </div>
    </div>
  );
}

export default PoorNumberInput;
