import {
  UnsubscribedFilterField,
  UnsubscribedFilterFieldMap,
} from 'respona_api/generated/mailbox_pb';

export const getUnsubscribesFilterFields = (
  workspaces: { value: string | number; label: string }[] = []
): {
  label: string;
  value: UnsubscribedFilterFieldMap[keyof UnsubscribedFilterFieldMap];
  type: 'string' | 'number' | 'boolean' | 'enum' | 'enumString' | 'stringNotEmpty' | 'date';
  inputOptions?: { value: string | number; label: string }[];
}[] => [
  {
    label: 'Pattern',
    value: UnsubscribedFilterField.STRING_PATTERN,
    type: 'string',
  },
  {
    label: 'Scope',
    value: UnsubscribedFilterField.STRING_SCOPE,
    type: 'enumString',
    inputOptions: [
      { value: 'GLOBAL', label: 'Global' },
      { value: 'WORKSPACE', label: 'Workspace' },
    ],
  },
  {
    label: 'Workspaces',
    value: UnsubscribedFilterField.DROPDOWN_WORKSPACE_ID,
    type: 'enumString',
    inputOptions: workspaces,
  },
];
