/* eslint-disable no-param-reassign */

export default (editor) => {
  const { isInline, isVoid } = editor;

  editor.isInline = (element) => {
    if (element.type === 'temp-variable') {
      return true;
    }
    if (element.type === 'ai-variable') {
      return true;
    }

    return element.type === 'variable' ? true : isInline(element);
  };

  editor.isVoid = (element) => {
    if (element.type === 'temp-variable') {
      return true;
    }
    if (element.type === 'ai-variable') {
      return true;
    }

    return element.type === 'variable' ? true : isVoid(element);
  };

  return editor;
};
