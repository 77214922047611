import React from 'react';

export default ({ color = '#C4C6CD' }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.4444 3H4.55556C3.69645 3 3 3.69645 3 4.55556V15.4444C3 16.3036 3.69645 17 4.55556 17H15.4444C16.3036 17 17 16.3036 17 15.4444V4.55556C17 3.69645 16.3036 3 15.4444 3ZM7.27778 14.2778C7.27778 14.4926 7.10367 14.6667 6.88889 14.6667H5.72222C5.50744 14.6667 5.33333 14.4926 5.33333 14.2778V8.83333C5.33333 8.61856 5.50744 8.44444 5.72222 8.44444H6.88889C7.10367 8.44444 7.27778 8.61856 7.27778 8.83333V14.2778ZM6.30556 7.66667C5.66122 7.66667 5.13889 7.14433 5.13889 6.5C5.13889 5.85567 5.66122 5.33333 6.30556 5.33333C6.94989 5.33333 7.47222 5.85567 7.47222 6.5C7.47222 7.14433 6.94989 7.66667 6.30556 7.66667ZM14.6667 14.2778C14.6667 14.4926 14.4926 14.6667 14.2778 14.6667H13.1111C12.8963 14.6667 12.7222 14.4926 12.7222 14.2778V11.3611C12.7222 10.7168 12.1999 10.1944 11.5556 10.1944C10.9112 10.1944 10.3889 10.7168 10.3889 11.3611V14.2778C10.3889 14.4926 10.2148 14.6667 10 14.6667H8.83333C8.61856 14.6667 8.44444 14.4926 8.44444 14.2778V8.83333C8.44444 8.61856 8.61856 8.44444 8.83333 8.44444H10C10.2148 8.44444 10.3889 8.61856 10.3889 8.83333V9.3C10.862 8.58009 11.6932 8.17941 12.5511 8.25778C13.7781 8.40907 14.6919 9.46394 14.6667 10.7V14.2778Z"
      fill={color}
    />
  </svg>
);
