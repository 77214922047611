import React from 'react';

export default ({ color = '#C4C6CD', size = 18 }) => (
  <svg width={size} height={size} viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.9 3.16602L4.16667 2.60768L1.66667 2.39935V1.49935C1.66667 1.27834 1.57887 1.06637 1.42259 0.910093C1.26631 0.753813 1.05435 0.666016 0.833333 0.666016C0.61232 0.666016 0.400358 0.753813 0.244078 0.910093C0.0877975 1.06637 0 1.27834 0 1.49935V16.4993C0 16.7204 0.0877975 16.9323 0.244078 17.0886C0.400358 17.2449 0.61232 17.3327 0.833333 17.3327C1.05435 17.3327 1.26631 17.2449 1.42259 17.0886C1.57887 16.9323 1.66667 16.7204 1.66667 16.4993V10.5993L4.16667 10.391L10.9 9.83268C11.1093 9.81589 11.3045 9.72073 11.4466 9.56623C11.5888 9.41174 11.6673 9.20928 11.6667 8.99935V3.99935C11.6673 3.78942 11.5888 3.58696 11.4466 3.43246C11.3045 3.27797 11.1093 3.18281 10.9 3.16602ZM3.33333 8.79102L1.66667 8.92435V4.07435L3.33333 4.20768V8.79102ZM6.66667 8.50768L5 8.64935V4.34935L6.66667 4.49102V8.50768ZM10 8.23268L8.33333 8.37435V4.62435L10 4.76602V8.23268Z"
      fill={color}
    />
  </svg>
);
