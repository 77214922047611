import React from 'react';

export default ({ size = 18, color = '#8F9199' }) => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.62305 7.62207H8.71194"
      stroke="#251FB6"
      strokeWidth="1.86667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.0664 7.62207H18.5109"
      stroke="#251FB6"
      strokeWidth="1.86667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.62305 13.0669H8.71194"
      stroke="#251FB6"
      strokeWidth="1.86667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.0664 13.0669H18.5109"
      stroke="#251FB6"
      strokeWidth="1.86667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.62305 18.5112H13.0675"
      stroke="#251FB6"
      strokeWidth="1.86667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.2888 25.0444H2.17773V1.08887H23.9555V12.2889"
      stroke="#251FB6"
      strokeWidth="1.86667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g clipPath="url(#clip0_21829_292)">
      <path
        d="M21.7792 23.8508C22.9246 23.8508 23.8532 22.9222 23.8532 21.7767C23.8532 20.6312 22.9246 19.7026 21.7792 19.7026C20.6337 19.7026 19.7051 20.6312 19.7051 21.7767C19.7051 22.9222 20.6337 23.8508 21.7792 23.8508Z"
        stroke="#251FB6"
        strokeWidth="1.86667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.853 19.7031V22.2957C23.853 22.7082 24.0169 23.1039 24.3086 23.3956C24.6004 23.6873 24.996 23.8512 25.4086 23.8512C25.8211 23.8512 26.2168 23.6873 26.5085 23.3956C26.8002 23.1039 26.9641 22.7082 26.9641 22.2957V21.7771C26.9641 20.6069 26.5681 19.471 25.8406 18.5543C25.1132 17.6376 24.097 16.9939 22.9573 16.728C21.8177 16.462 20.6216 16.5894 19.5635 17.0894C18.5054 17.5894 17.6476 18.4327 17.1295 19.482C16.6114 20.5314 16.4636 21.7252 16.71 22.8692C16.9564 24.0133 17.5826 25.0403 18.4867 25.7834C19.3908 26.5264 20.5197 26.9418 21.6898 26.9619C22.8599 26.982 24.0024 26.6057 24.9315 25.8942"
        stroke="#251FB6"
        strokeWidth="1.86667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_21829_292">
        <rect
          width="12.4444"
          height="12.4444"
          fill="white"
          transform="translate(15.5566 15.5552)"
        />
      </clipPath>
    </defs>
  </svg>
);
