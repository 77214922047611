import { FileResponseType } from '@ts/common.types';

export const base64ToUint8Array = (base64: string): Uint8Array => {
  const binaryString = atob(base64);
  const bytesArray = new Uint8Array(binaryString.length);

  return bytesArray.map((_, index) => binaryString.charCodeAt(index));
};

export const createBlobFromResponse = (response: FileResponseType): Blob => {
  let blob;

  if (typeof response.fileBytes === 'string') {
    blob = new Blob([base64ToUint8Array(response.fileBytes)], {
      type: `application/${response.format}`,
    });
  } else {
    blob = new Blob([response.fileBytes], {
      type: `application/${response.format}`,
    });
  }
  return blob;
};

export const downloadResponse = (res: FileResponseType) => {
  const blob = createBlobFromResponse(res);

  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = res.title;
  link.click();
  link.remove();
};
