import React from 'react';

export default ({ color = '#8F9199', size = 12 }: { color?: string; size?: number }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 12 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.0344 1.46669C10.428 1.46669 9.93441 0.973149 9.93441 0.366672C9.93441 0.164269 9.77014 0 9.56774 0C9.36533 0 9.20106 0.164269 9.20106 0.366672C9.20106 0.973149 8.70752 1.46669 8.10105 1.46669C7.89864 1.46669 7.73438 1.63096 7.73438 1.83336C7.73438 2.03577 7.89864 2.20003 8.10105 2.20003C8.70752 2.20003 9.20106 2.69358 9.20106 3.30005C9.20106 3.50246 9.36533 3.66672 9.56774 3.66672C9.77014 3.66672 9.93441 3.50246 9.93441 3.30005C9.93441 2.69358 10.428 2.20003 11.0344 2.20003C11.2368 2.20003 11.4011 2.03577 11.4011 1.83336C11.4011 1.63096 11.2368 1.46669 11.0344 1.46669Z"
      fill={color}
    />
    <path
      d="M4.00243 9.11524C4.00243 7.37003 2.58333 5.95092 0.838109 5.95092C0.734458 5.95092 0.65 5.86646 0.65 5.76281C0.65 5.65916 0.734458 5.5747 0.838109 5.5747C2.58333 5.5747 4.00243 4.15559 4.00243 2.41037C4.00243 2.30672 4.08689 2.22227 4.19054 2.22227C4.29419 2.22227 4.37865 2.30672 4.37865 2.41037C4.37865 4.15559 5.79776 5.5747 7.54298 5.5747C7.64663 5.5747 7.73109 5.65916 7.73109 5.76281C7.73109 5.86646 7.64663 5.95092 7.54298 5.95092C5.79776 5.95092 4.37865 7.37003 4.37865 9.11524C4.37865 9.21889 4.29419 9.30335 4.19054 9.30335C4.08689 9.30335 4.00243 9.21889 4.00243 9.11524Z"
      stroke={color}
      strokeWidth="1.3"
    />
    <path
      d="M10.0916 9.43045C9.65836 9.43045 9.30583 9.07792 9.30583 8.64472C9.30583 8.50015 9.18849 8.38281 9.04392 8.38281C8.89935 8.38281 8.78201 8.50015 8.78201 8.64472C8.78201 9.07792 8.42948 9.43045 7.99628 9.43045C7.85171 9.43045 7.73438 9.54778 7.73438 9.69236C7.73438 9.83693 7.85171 9.95427 7.99628 9.95427C8.42948 9.95427 8.78201 10.3068 8.78201 10.74C8.78201 10.8846 8.89935 11.0019 9.04392 11.0019C9.18849 11.0019 9.30583 10.8846 9.30583 10.74C9.30583 10.3068 9.65836 9.95427 10.0916 9.95427C10.2361 9.95427 10.3535 9.83693 10.3535 9.69236C10.3535 9.54778 10.2361 9.43045 10.0916 9.43045Z"
      fill={color}
    />
  </svg>
);
