import React from 'react';

export default ({ color = '#23E771', size = 16 }) => (
  <svg width={size} height={size} viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.5 0C2.025 0 0 2.025 0 4.5C0 6.975 2.025 9 4.5 9C6.975 9 9 6.975 9 4.5C9 2.025 6.975 0 4.5 0ZM3.9375 6.4125L2.025 4.5L2.8125 3.7125L3.9375 4.8375L6.1875 2.5875L6.975 3.375L3.9375 6.4125Z"
      fill={color}
    />
  </svg>
);
