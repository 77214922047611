import React from 'react';

export default () => (
  <svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="61" height="61" rx="30.5" fill="#ED483D" />
    <path
      d="M41.5 23.5012C38.875 23.5012 34.6825 21.3894 31.9 19.3015C31.3675 18.8995 30.6325 18.8995 30.1 19.3015C27.3175 21.3894 23.125 23.5012 20.5 23.5012C19.672 23.5012 19 24.1717 19 25.0011C19 33.3766 23.0515 39.7257 30.409 42.8785C30.598 42.9595 30.799 43 31 43C31.201 43 31.402 42.9595 31.591 42.8785C38.9485 39.7257 43 33.3766 43 25.0011C43 24.1717 42.328 23.5012 41.5 23.5012Z"
      fill="white"
    />
    <path
      d="M35.9999 27.3425L34.5573 25.8999L30.9499 29.5073L27.3425 25.8999L25.8999 27.3425L29.5073 30.9499L25.8999 34.5573L27.3425 35.9999L30.9499 32.3925L34.5573 35.9999L35.9999 34.5573L32.3925 30.9499L35.9999 27.3425Z"
      fill="#ED483D"
    />
  </svg>
);
