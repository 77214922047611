import { handleActions } from 'redux-actions';
import {
  setPersonalization,
  updatePersonalizationSteps,
  setPersonalizationLs,
  fetchPersonalization,
  clearPersonalization,
} from '@redux/actions/personalizations.actions';
import { csClearOpenCampaignInfo } from '@redux/actions/campaignSettings.actions';
import loadingStatuses from '@constants/loadingStatuses';
import { PersonalizationStepType } from '@ts/personalizations.types';

export type PersonalizationsStateType = {
  [oppurtunityId: number]: {
    loadingStatus: string;
    stepsList: PersonalizationStepType[];
  };
};

const initialState: PersonalizationsStateType = {};

export default handleActions(
  {
    [fetchPersonalization]: (state, { payload }) =>
      payload.opportunityId
        ? {
            ...state,
            [payload.opportunityId]: {
              loadingStatus: loadingStatuses.INITED,
              data: {},
            },
          }
        : state,
    [setPersonalizationLs]: (state, { payload }) => ({
      ...state,
      [payload.opportunityId]: {
        ...state[payload.opportunityId],
        loadingStatus: payload.loadingStatus,
      },
    }),
    [setPersonalization]: (state, { payload }) => ({
      ...state,
      [payload.opportunityId]: {
        ...state[payload.opportunityId],
        stepsList: payload.stepsList,
      },
    }),
    [updatePersonalizationSteps]: (state, { payload }) => ({
      ...state,
      [payload.opportunityId]: {
        ...state[payload.opportunityId],
        data: {
          opportunityId: payload.opportunityId,
          stepsList: payload.stepsList
            ? payload.stepsList
            : state[payload.opportunityId].data.stepsList.map((item) =>
                item.id === payload.step.id ? payload.step : item
              ),
        },
      },
    }),
    [csClearOpenCampaignInfo]: () => initialState,
    [clearPersonalization]: () => initialState,
  },
  initialState
);
