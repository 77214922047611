import capitalize from '@utils/capitalize';

export default (item: { [key: string]: any }) => {
  const newObj = {};
  // eslint-disable-next-line guard-for-in
  for (const key in item) {
    const bigCharIndex = key.split('').findIndex((char) => char.toUpperCase() === char);
    if (bigCharIndex === -1) {
      newObj[capitalize(key)] = item[key];
    } else {
      const newKey = `${capitalize(key.substr(0, bigCharIndex))} ${capitalize(
        key.substr(bigCharIndex)
      )}`;
      newObj[newKey] = item[key];
    }
  }
  return newObj;
}
