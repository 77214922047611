import React, { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import { useGoogleLogin } from '@react-oauth/google';

import { getAllTrackingDomainsApi } from '@api/userOrganization.api';

import { addNotification } from '@redux/actions/notifications.actions';

import EmailsTable from '@components/Settings/EmailsTable/EmailsTable';
import EmailEditionSidebar from '@components/Settings/EmailEditionSidebar/EmailEditionSidebar';
import AddTrackingDomainSidebar from '@components/Settings/AddTrackingDomainSidebar/AddTrackingDomainSidebar';
import ShareEmailModal from '@components/Modals/ShareEmailModal/ShareEmailModal';
import AddEmailSidebar from '@components/Settings/AddEmailSidebar/AddEmailSidebar';
import UpsellEmailSidebar from '@components/UpsellSidebars/UpsellEmailSidebar/UpsellEmailSidebar';
import PageHeader from '@components/PageHeader/PageHeader';

import { Button } from '@uikit/Button/Button';
import Loader from '@uikit/Loader/Loader';

import {
  MailboxAccountSettingsType,
  MailboxAccountStatisticsType,
  MailboxAccountType,
} from '@ts/mailboxAccounts.types';
import { MailboxAccountTypeMap } from 'respona_api/generated/mailbox-account_pb';
import { TrackingDomain } from 'respona_api/generated/user-organization_pb';

import {
  creatGmailAccountApi,
  reAuthenticateGmailAccountApi,
  removeAccountApi,
  updateAccountApi,
} from '@api/mailboxAccounts.api';
import errorCodes from '@constants/errorCodes';

import './EmailsSettings.scss';
import SubscriptionActivationSidebar from '@components/SubscriptionActivationSidebars/SubscriptionActivationSidebar';
import { fetchBillingCredits, fetchBillingDetails } from '@redux/thunks/billings.thunks';
import { billingDetailsSelector } from '@redux/selectors/billings.selectors';
import { QueryPageHookType } from '@ts/common.types';
import useMailboxAccountStatistics from '@hooks/useMailboxAccountStatistics';
import useMailboxAccounts from '@hooks/useMailboxAccounts';
import { getCurrentWorkspaceId } from '@redux/selectors/workspaces.selectors';
import useMailboxAccountSettings from '@hooks/useMailboxAccountSettings';
import Display from '@components/Display/Display';
import useMailboxAccountsSharedWorkspaces from '@hooks/useMailboxAccountsSharedWorkspaces';
import { BillingState } from 'respona_api/generated/billing_pb';
import useIntersectionObserver from '@hooks/useIntersectionObserver';
import { useRefCallback } from '@helpers/refHelpers';

declare const IS_DEV: boolean;

function EmailsSettings(): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loadingRef, setLoadingRef, ready] = useRefCallback<HTMLDivElement>();
  const curWorkspaceId = useSelector(getCurrentWorkspaceId);
  const [isEditionSidebarOpen, setIsEditionSidebarOpen] = useState(false);
  const [isCreationSidebarOpen, setIsCreationSidebarOpen] = useState(false);
  const [isUpsellEmailSidebarOpen, setIsUpsellEmailSidebarOpen] = useState(false);
  const [isPaymentSidebarOpen, setPaymentSidebarOpen] = useState<boolean>(false);
  const [isShareModalOpen, setShareModalOpen] = useState(false);
  const [isAddTrackingDomainOpen, setIsAddTrackingDomainOpen] = useState(false);
  const { data: billingDetails } = useSelector(billingDetailsSelector);

  const [codeFromGoogle, changeCodeFromGoogle] = useState<string | null>(null);
  const [editingMailboxId, changeEditingMailboxId] = useState<number>(null);

  const { item: mailbox, update: updateMailboxSettings } =
    useMailboxAccountSettings(editingMailboxId);

  const { evictByKey: cleanSharedWorkspaceCache } = useMailboxAccountsSharedWorkspaces(
    editingMailboxId,
    true
  );

  const {
    items: baseAccounts,
    isLoading: isLoadingMailboxAccounts,
    isLoadingNextPage: isLoadingNextPageMailboxAccounts,
    addItem: addAccountToCache,
    removeItem: removeAccountFromCache,
    updateItem: updateAccountInCache,
    hasNextPage: baseAccountsHasNextPage,
    fetchNextPage: fetchNextBaseAccountPage,
  }: QueryPageHookType<MailboxAccountStatisticsType> = useMailboxAccountStatistics();

  useIntersectionObserver(loadingRef, () => fetchNextBaseAccountPage());

  const { addItem: addAccountToShortCache } = useMailboxAccounts(curWorkspaceId);

  const { isEmailCreationOpenInUrl, isUpsellEmailOpenInUrl } = qs.parse(history.location.search);

  const {
    data: trackingDomains,
    isLoading: isLoadingTrackingDomains,
    refetch: refetchTrackingDomains,
  } = useQuery<TrackingDomain.AsObject[]>({
    queryKey: ['all-tracking-domains'],
    queryFn: () => getAllTrackingDomainsApi(),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: isEditionSidebarOpen,
  });

  const googleAuth = useGoogleLogin({
    onSuccess: ({ code }) =>
      reAuthenticateGmailAccountApi(editingMailboxId, code).then((response: MailboxAccountType) => {
        dispatch(addNotification({ title: 'Account re-authenticated', type: 'success' }));
        updateAccountInCache(editingMailboxId, { ...response } as MailboxAccountStatisticsType);
      }),
    flow: 'auth-code',
    scope:
      'profile https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/gmail.send',
  });

  const handleCloseEmailCreation = () => setIsCreationSidebarOpen(false);
  const handleEmailEditionDiscard = () => setIsEditionSidebarOpen(false);
  const handleOpenEmail = (id: number) => {
    changeEditingMailboxId(id);
    setIsEditionSidebarOpen(true);
  };

  /* Sharing API`s */
  const handleShareEmailClick = (id: number) => {
    if (!billingDetails.enterpriseFeaturesEnabled && !billingDetails.extendedFeaturesEnabled) {
      dispatch(
        addNotification({
          title:
            'This feature is not available in your plan. Please contact support to learn more.',
          type: 'error',
        })
      );
      return;
    }
    changeEditingMailboxId(id);
    setShareModalOpen(true);
  };
  const handleShareModalClose = () => {
    setShareModalOpen(false);
  };
  const handleUpsellSidebarForGoogleEmailClose = () => changeCodeFromGoogle(null);
  const handleUpsellSidebarForGoogleEmailOpen = (code: string) => changeCodeFromGoogle(code);
  const toggleUpsellEmailSidebarOpen = () => setIsUpsellEmailSidebarOpen((prevState) => !prevState);

  const handleAddGmailAccount = (code: string, autoBuy = false) =>
    creatGmailAccountApi(code, autoBuy)
      .then((response: MailboxAccountType) => {
        addAccountToCache({
          ...response,
          dailySentToday: 0,
        } as MailboxAccountStatisticsType);
        addAccountToShortCache(response);
        dispatch(addNotification({ title: 'Mailbox account added', type: 'success' }));
        handleOpenEmail(response.id);
      })
      .catch((error) => {
        console.warn(error);
        if (
          error &&
          error.message &&
          JSON.parse(error.message).code === errorCodes.NEED_MORE_CREDITS
        ) {
          handleUpsellSidebarForGoogleEmailOpen(code);
        }
      });

  const handleRemoveEmail = useCallback((id: number) => {
    removeAccountApi(id).then(() => {
      removeAccountFromCache(id);
      dispatch(
        addNotification({
          title: 'Email is removed, but billing has not changed.',
          type: 'success',
        })
      );
    });
  }, []);

  const handleUpdateEmail = useCallback(async (payload: MailboxAccountSettingsType) => {
    updateAccountApi(payload).then((response) => {
      updateAccountInCache(payload.id, {
        ...response,
        workspaceIdsList: payload.workspaceIdsList,
      } as MailboxAccountStatisticsType);
      updateMailboxSettings(payload.id, payload);
      cleanSharedWorkspaceCache(payload.id);
      dispatch(addNotification({ title: 'Email was updated', type: 'success' }));
      handleEmailEditionDiscard();
    });
  }, []);

  const reauthenticateEmail = (
    id: number,
    type: MailboxAccountTypeMap[keyof MailboxAccountTypeMap]
  ) => {
    if (type === 1) {
      googleAuth();
    } else if (type === 2) {
      history.push(`/settings/email-smtp?id=${id}`);
    } else if (type === 3) {
      window.open(
        `https://${
          IS_DEV ? 'dev.respona.com' : 'app.respona.com'
        }/mailbox/microsoft/secure/aad?auth=${localStorage.getItem('token')}`,
        '_self'
      );
    }
  };

  useEffect(() => {
    if (mailbox != null) {
      const accountStat = baseAccounts?.find((it) => it.id === mailbox.id);
      if (accountStat != null) {
        updateAccountInCache(accountStat.id, {
          ...accountStat,
          dailySentLimit: mailbox.dailySentLimit,
        });
      }
    }
  }, [mailbox]);

  useEffect(() => {
    if (isEmailCreationOpenInUrl === 'true') {
      setIsCreationSidebarOpen(true);
    }

    if (isUpsellEmailOpenInUrl === 'true') {
      if (billingDetails.state === BillingState.BILLING_TRIAL) {
        setPaymentSidebarOpen(true);
      } else {
        setIsUpsellEmailSidebarOpen(true);
      }
    }
  }, [isEmailCreationOpenInUrl]);

  function onOpenEmailCreationClick() {
    if (
      billingDetails.state === BillingState.BILLING_TRIAL &&
      billingDetails.currentMailboxAccountsNumber >= 1
    ) {
      setPaymentSidebarOpen(true);
    } else {
      setIsCreationSidebarOpen(true);
    }
  }

  const renderLoader = () => {
    if (isLoadingNextPageMailboxAccounts) {
      return <Loader isLoading withTopMargin />;
    }

    if (baseAccountsHasNextPage) {
      return (
        <div ref={setLoadingRef} style={{ marginBottom: '10px' }}>
          <Loader isLoading withTopMargin />
        </div>
      );
    }

    return <div />;
  };

  return (
    <div className="email-settings">
      {isEditionSidebarOpen ? null : (
        <PageHeader
          title="Email accounts"
          renderHeaderActions={() => (
            <div className="email-settings__header-right">
              <Button onClick={() => onOpenEmailCreationClick()} leftIcon="plusIcon">
                <span className="email-settings__button-text">Add Email</span>
              </Button>
            </div>
          )}
        />
      )}

      <Loader isLoading={isLoadingMailboxAccounts} withTopMargin>
        <EmailsTable
          mailboxes={baseAccounts}
          onOpen={handleOpenEmail}
          onRemove={handleRemoveEmail}
          isDisableSharing={
            !billingDetails.extendedFeaturesEnabled && !billingDetails.enterpriseFeaturesEnabled
          }
          onShareClick={handleShareEmailClick}
        />
        {renderLoader()}
      </Loader>

      <Display isVisible={mailbox != null}>
        <EmailEditionSidebar
          fullWidth
          isOpen={isEditionSidebarOpen}
          onSave={handleUpdateEmail}
          onClose={handleEmailEditionDiscard}
          onRemove={handleRemoveEmail}
          onReauthenticate={(item) => reauthenticateEmail(item.id, item.type)}
          onAddTrackingDomain={() => setIsAddTrackingDomainOpen(true)}
          mailbox={mailbox}
          trackingDomains={trackingDomains}
          isLoadingTrackingDomains={isLoadingTrackingDomains}
        />
      </Display>

      <AddTrackingDomainSidebar
        isOpen={isAddTrackingDomainOpen}
        onClose={() => setIsAddTrackingDomainOpen(false)}
        onAddDomain={refetchTrackingDomains}
      />

      <Display isVisible={mailbox != null && isShareModalOpen}>
        <ShareEmailModal
          isOpen={isShareModalOpen}
          onClose={handleShareModalClose}
          mailbox={mailbox}
          updateMailboxSettings={updateMailboxSettings}
        />
      </Display>

      {isPaymentSidebarOpen && (
        <SubscriptionActivationSidebar
          title="Activate your subscription"
          onClose={() => {
            setPaymentSidebarOpen(false);
          }}
          onActionAfterPay={() => {
            setPaymentSidebarOpen(false);
            dispatch(fetchBillingDetails());
            dispatch(fetchBillingCredits());
          }}
        />
      )}

      {codeFromGoogle ? (
        <UpsellEmailSidebar
          title="You have exceeded your email account limit. Would you like to increase your limit?"
          onClose={handleUpsellSidebarForGoogleEmailClose}
          onActionAfterPay={() => {
            handleAddGmailAccount(codeFromGoogle);
          }}
        />
      ) : null}

      {isUpsellEmailSidebarOpen ? (
        <UpsellEmailSidebar
          title="You have exceeded your email account limit. Would you like to increase your limit?"
          onClose={() => setIsUpsellEmailSidebarOpen(false)}
          onActionAfterPay={() => setIsCreationSidebarOpen(true)}
        />
      ) : null}

      {isCreationSidebarOpen && (
        <AddEmailSidebar
          onAddGmailAccount={handleAddGmailAccount}
          isOpen={isCreationSidebarOpen}
          onClose={handleCloseEmailCreation}
          onOpenUpsellSidebar={toggleUpsellEmailSidebarOpen}
        />
      )}
    </div>
  );
}

export default EmailsSettings;
