import React from 'react';

import FileAttachment from '@uikit/FileAttachment/FileAttachment';
import downloadCSV from '@utils/downloadCSV';

const PdfMimeMessage = ({
  title,
  content,
}: {
  title: string;
  content: Uint8Array[];
}): JSX.Element => {
  const fileSize = content.length / 1024;

  const handleClick = () => {
    // TODO: rename downloadCSV to downloadBlob for all project includes
    // @ts-ignore
    downloadCSV(title, new Blob([content]));
  };

  return <FileAttachment title={title} size={fileSize} onClick={handleClick} />;
};

export default PdfMimeMessage;
