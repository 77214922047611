import React from 'react';

const LinkBrokenIcon = ({ color = '#BDBDBD' }: { color: string }): JSX.Element => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.9375 3.18756L8.23437 1.89068C8.81938 1.31973 9.60443 1.00012 10.4219 1.00012C11.2393 1.00012 12.0244 1.31973 12.6094 1.89068V1.89068C13.1803 2.47569 13.4999 3.26074 13.4999 4.07818C13.4999 4.89563 13.1803 5.68068 12.6094 6.26568L11.3125 7.56256"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M7.56244 11.3125L6.26556 12.6094C5.68056 13.1803 4.89551 13.4999 4.07806 13.4999C3.26061 13.4999 2.47556 13.1803 1.89056 12.6094V12.6094C1.31961 12.0244 1 11.2393 1 10.4219C1 9.60443 1.31961 8.81938 1.89056 8.23437L3.18744 6.9375"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M4.75 9.75L5.375 9.125"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M9.125 5.375L9.75 4.75"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M1 1L13.5 13.5"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);

export default LinkBrokenIcon;
