import { Dispatch } from 'redux';

import { changeCampaignStatusApi, updateCampaignApi } from '@api/campaign.api';
import { getTemplateStepsApi, updateTemplateStepAndFutureQueuesApi } from '@api/templates.api';

import loadingStatuses from '@constants/loadingStatuses';
import {
  setAnalyticsTemplate,
  setAnalyticsTemplateLs,
  updateAnalyticsTemplateStep,
} from '@redux/actions/campaignAnalytics.actions';
import { addNotification } from '@redux/actions/notifications.actions';

import { TemplateContainerType } from '@ts/template.types';
import { CampaignCrudRequest } from 'respona_api/generated/campaign_pb';

export const fetchAnalyticsTemplate = (templateId: number) => (dispatch: Dispatch) => {
  dispatch(setAnalyticsTemplateLs(loadingStatuses.INITED));

  getTemplateStepsApi(templateId)
    .then((templateContainer: TemplateContainerType) => {
      dispatch(setAnalyticsTemplate(templateContainer));
    })
    .finally(() => {
      dispatch(setAnalyticsTemplateLs(loadingStatuses.LOADED));
    });
};

export const updateTemplateStepAndFutureQueues = (step, templateId) => (dispatch) => {
  updateTemplateStepAndFutureQueuesApi(step, templateId).then((updatedStep) => {
    dispatch(updateAnalyticsTemplateStep(updatedStep));
  });
};

export const updateOpenCampaignData =
  (fields: CampaignCrudRequest.AsObject) => (dispatch: Dispatch) => {
    updateCampaignApi(fields).then((updatedOpenCampaign) => {
      dispatch(addNotification({ title: 'Campaign was updated' }));
    });
  };

export const changeCampaignAnalyticStatusThunk =
  (campaignId: number, workspaceId: number, status: 0 | 1 | 2 | 3 | 4 | 5) => (dispatch) => {
    return changeCampaignStatusApi(campaignId, workspaceId, status);
  };
