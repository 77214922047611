import React from 'react';

import { checkIsAnyNotLoaded } from 'src/utils/loadingChecks';

import './Loader.scss';

const Loader = ({
  children = null,
  isLoading = false,
  isFullScreenLoading = false,
  isFullBlockLoading = false,
  withTopMargin = false,
  lsDeps = [],
  backgroundColor = '#ffffff',
  style = {},
}) => {
  if (lsDeps.length > 0) {
    if (checkIsAnyNotLoaded(...lsDeps) || isLoading) {
      return (
        <div
          // @ts-ignore
          style={{ '--background-color': backgroundColor, ...style }}
          className={`loader ${withTopMargin ? 'loader--with-top-margin' : ''}`}
        />
      );
    }
  }

  if (isLoading) {
    return (
      <div
        // @ts-ignore
        style={{ '--background-color': backgroundColor, ...style }}
        className={`loader ${withTopMargin ? 'loader--with-top-margin' : ''}`}
      />
    );
  }

  if (isFullScreenLoading) {
    return (
      <div className="loader__full-screen-wrapper">
        <div
          // @ts-ignore
          style={{ '--background-color': backgroundColor, ...style }}
          className="loader"
        />
      </div>
    );
  }

  if (isFullBlockLoading) {
    return (
      <div className="loader__full-block-wrapper">
        <div
          // @ts-ignore
          style={{ '--background-color': backgroundColor, ...style }}
          className="loader"
        />
      </div>
    );
  }

  if (children) {
    return <>{children}</>;
  }

  return null;
};

export const ScrollLoader = React.forwardRef<
  HTMLDivElement,
  {
    isFetchingNextPage: boolean;
    hasNextPage: boolean;
    data: { totalItems: number; [key: string]: any }[];
  }
>(({ isFetchingNextPage, hasNextPage, data }, ref) => {
  if (isFetchingNextPage) {
    return <Loader isLoading withTopMargin />;
  }

  if (data?.length && data[0].totalItems > data.length && hasNextPage) {
    return (
      <div ref={ref} style={{ marginBottom: '20px' }}>
        <Loader isLoading withTopMargin />
      </div>
    );
  }

  return null;
});

export default Loader;
