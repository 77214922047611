import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import ReactSelect, { components } from 'react-select';
import cn from 'classnames';

import {
  MailboxAccountSyncType,
  MailboxAccountWarmupState,
  MailboxHealthcheckState,
} from 'respona_api/generated/mailbox-account_pb';
import { TrackingDomain } from 'respona_api/generated/user-organization_pb';

import { SVGIcon } from '@uikit/Icon/Icon';
import Select from '@uikit/Select/Select';
import Input from '@uikit/Input/Input';
import Button from '@uikit/Button/Button';
import NumberInput from '@uikit/Input/NumberInput';
import SignatureEditor, { signatureTypes, SignatureType } from '@uikit/RichEditor/SignatureEditor';

import RemoveTwoStepsButton from '@uikit/RemoveTwoStepsButton/RemoveTwoStepsButton';
import FromToRow from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/FromToRow/FromToRow';
import QuestionTooltip from '@pages/CampaignCreationPage/_components/QuestionTooltip/QuestionTooltip';

import useHotkeys from '@hooks/useHotkeys';
import { useDispatch, useSelector } from 'react-redux';
import { billingDetailsSelector } from '@redux/selectors/billings.selectors';
import { workspacesSelector } from '@redux/selectors/workspaces.selectors';
import { addNotification } from '@redux/actions/notifications.actions';

import './EmailEditionSidebar.scss';
import { MailboxAccountSettingsType } from '@ts/mailboxAccounts.types';
import { deleteCustomTrackingDomainApi } from '@api/userOrganization.api';
import { mapMinMaxToFromTo } from '@helpers/commonHelpers';
import Loader from '@uikit/Loader/Loader';

declare const IS_DEV: boolean;

const inboxSyncOptions = [
  {
    value: MailboxAccountSyncType.MAILBOX_SYNC_ALL,
    label: 'Sync all new emails',
  },
  {
    value: MailboxAccountSyncType.MAILBOX_SYNC_CAMPAIGN,
    label: 'Sync campaign emails only',
  },
];

const healthCheckOptions = [
  {
    value: MailboxHealthcheckState.HEALTHCHECK_WEEKLY,
    label: 'Weekly',
  },
  {
    value: MailboxHealthcheckState.HEALTHCHECK_OFF,
    label: 'Disabled',
  },
];

type EmailEditionSidebarPropsType = {
  mailbox: MailboxAccountSettingsType;
  isOpen: boolean;
  onClose: () => void;
  onSave: (mailbox: MailboxAccountSettingsType) => void;
  onRemove: (id: number) => void;
  onReauthenticate: (mailbox: MailboxAccountSettingsType) => void;
  onAddTrackingDomain?: () => void;
  fullWidth?: boolean;
  trackingDomains?: TrackingDomain.AsObject[];
  isLoadingTrackingDomains: boolean;
};

const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isDisabled ? '#f0f0f0' : 'transparent',
    border: '1px solid #DBDFE7',
    borderRadius: '10px',
    height: '48px',
    paddingLeft: '10px',
    '&:focus, &:hover': {
      border: '1px solid #221CB6',
    },
  }),
};

const selectWithIconStyles = {
  control: (provided, state) => ({
    ...selectStyles.control(provided, state),
    paddingLeft: '0',
  }),
};

function CustomDropdownOption({ children, ...restProps }) {
  const queryClient = useQueryClient();

  const { mutate, isLoading: isAddingTrackingDomain } = useMutation(deleteCustomTrackingDomainApi, {
    onSuccess: () => queryClient.refetchQueries(['all-tracking-domains']),
  });

  return (
    <components.Option {...restProps}>
      <div className="email-edition-sidebar__tracking-domain-option">{children}</div>
      {!restProps.data.isDefault && (
        <div className="email-edition-sidebar__tracking-domain-remove-btn">
          <RemoveTwoStepsButton onRemoveStep={() => mutate(children)} removingItemText="" />
        </div>
      )}
    </components.Option>
  );
}

const createFieldSelectMenu = (onActionClick, selectRef) =>
  function (props) {
    return (
      <components.Menu {...props}>
        <div className="field-select__menu">
          <div>{props.children}</div>
          <div
            className="email-edition-sidebar__custom-option"
            onClick={() => {
              selectRef.current.onMenuClose();
              onActionClick();
            }}
            role="button"
          >
            <span>Add custom domain</span>
          </div>
        </div>
      </components.Menu>
    );
  };

function EmailEditionSidebar({
  mailbox,
  isOpen,
  onClose,
  onSave,
  onRemove,
  onReauthenticate,
  onAddTrackingDomain,
  fullWidth,
  trackingDomains,
  isLoadingTrackingDomains,
}: EmailEditionSidebarPropsType) {
  const dispatch = useDispatch();

  if (!isOpen) {
    return null;
  }

  const sidebarRef = useRef<HTMLDivElement>();
  const trackingDomainRef = useRef<ReactSelect>();
  const { workspaces } = useSelector(workspacesSelector);

  const {
    data: { enterpriseFeaturesEnabled, extendedFeaturesEnabled },
  } = useSelector(billingDetailsSelector);

  const [signatureType, changeSignatureType] = useState<SignatureType>(
    mailbox.signature ? signatureTypes['HTML Code'] : signatureTypes['plain text']
  );

  const trackingDomainOptions = useMemo(
    () =>
      trackingDomains?.map((data) => ({
        value: data.domain,
        label: data.domain,
        isDefault: data.pb_default,
      })) || [],
    [trackingDomains]
  );

  const [fieldsValue, changeFieldsValue] = useState(() => ({
    dailySentLimit: mailbox.dailySentLimit,
    signature: mailbox.signature,
    senderName: mailbox.senderName,
    syncType: mailbox.syncType,
    healthcheckState: mailbox.healthcheckState,
    warmUpState: MailboxAccountWarmupState.WARMUP_OFF,
    customTrackingDomain: mailbox.customTrackingDomain,
    delayBetweenEmailsFrom: mailbox.delayBetweenEmailsFrom,
    delayBetweenEmailsTo: mailbox.delayBetweenEmailsTo,
    workspaceIdsList: mailbox.workspaceIdsList,
  }));

  const createFieldValueChangeHandler = useCallback(
    (fieldName) => (value) => {
      changeFieldsValue((prevState) => ({
        ...prevState,
        [fieldName]: value,
      }));
    },
    []
  );

  const handleSaveClick = useCallback(() => {
    const params = {
      ...mailbox,
      ...fieldsValue,
    };

    if (mailbox.isGmailSignature) {
      params.isGmailSignature = false;
    }
    onSave(params);
  }, [mailbox, fieldsValue, onSave]);

  const handleRemoveClick = useCallback(() => {
    onRemove(mailbox.id);
    onClose();
  }, [onRemove, onClose, mailbox.id]);

  const handleSignatureTypeChange = useCallback((type: SignatureType) => {
    changeSignatureType(type);
  }, []);

  useHotkeys({
    Escape: onClose,
  });

  useEffect(() => {
    if (
      trackingDomainOptions.length &&
      !mailbox.customTrackingDomain &&
      !fieldsValue.customTrackingDomain
    ) {
      const defaultTrackingDomain = (
        trackingDomains.find((item) => item.pb_default) || trackingDomains[0]
      )?.domain;

      createFieldValueChangeHandler('customTrackingDomain')(defaultTrackingDomain);
    }
  }, [
    mailbox,
    trackingDomainOptions,
    fieldsValue.customTrackingDomain,
    createFieldValueChangeHandler,
  ]);

  const workspaceOptions = useMemo(
    () => [
      { value: -1, label: 'All workspaces' },
      ...workspaces.map((item) => ({ label: item.title, value: item.id })),
    ],
    [workspaces]
  );

  const pickWorkspaceValue = useMemo(() => {
    if (
      !fieldsValue.workspaceIdsList?.length ||
      fieldsValue.workspaceIdsList.length === workspaces.length
    ) {
      return {
        value: -1,
        label: 'All workspaces',
      };
    }
    return workspaceOptions.filter((it) => fieldsValue.workspaceIdsList.includes(it.value));
  }, [fieldsValue.workspaceIdsList, workspaces]);

  const onWorkspaceSelection = useCallback(
    (payload: { label: string; value: number }[]) => {
      if (!payload.length || payload[payload.length - 1].value === -1) {
        createFieldValueChangeHandler('workspaceIdsList')(workspaces.map((it) => it.id));
      } else {
        const filteredWorkspaces = payload.map(({ value }) => value).filter((it) => it > 0);
        createFieldValueChangeHandler('workspaceIdsList')(filteredWorkspaces);
      }
    },
    [createFieldValueChangeHandler, workspaces]
  );

  const handleWrapperWorkSpaceClick = useCallback(() => {
    if (!enterpriseFeaturesEnabled && !extendedFeaturesEnabled) {
      dispatch(
        addNotification({
          title:
            'This feature is not available in your plan. Please contact support to learn more.',
          type: 'error',
        })
      );
    }
  }, [dispatch, enterpriseFeaturesEnabled]);

  const handleWrapperInboxSyncClick = useCallback(() => {
    if (!enterpriseFeaturesEnabled) {
      dispatch(
        addNotification({
          title:
            'This feature is not available in your plan. Please contact support to learn more.',
          type: 'error',
        })
      );
    }
  }, [dispatch, enterpriseFeaturesEnabled]);

  const composeWorkspaceTooltip = (): string => {
    if (enterpriseFeaturesEnabled) {
      return 'Once assigned to a workspace, the inbox will only be available to team members within that workspace.';
    }

    return 'This feature is not available in your plan. Please contact support to learn more.';
  };

  const composeSyncTooltip = (): string => {
    if (enterpriseFeaturesEnabled) {
      return 'Choose whether Respona should show all new emails in the Inbox tab, or only the ones associated with Respona campaigns.';
    }

    return 'This feature is not available in your plan. Please contact support to learn more.';
  };

  return (
    <div
      className={cn('email-edition-sidebar', {
        'email-edition-sidebar--full-width': fullWidth,
      })}
      ref={sidebarRef}
    >
      <div className="email-edition-sidebar__title">
        <div className="email-edition-sidebar__content">
          <span className="email-edition-sidebar__close-btn" onClick={onClose}>
            <SVGIcon icon="arrowLeft" color="#000000" />
          </span>
          Edit Settings for
          <span style={{ color: '#221CB6', marginLeft: 6 }}>{mailbox.email}</span>
        </div>

        <div className="email-edition-sidebar__footer">
          <Button onClick={handleRemoveClick} type="alert" withConfirm>
            Remove
          </Button>
          <div className="email-edition-sidebar__footer-right">
            <Button type="additional" onClick={(e) => onReauthenticate(mailbox)}>
              Re-authenticate
            </Button>
            <Button onClick={handleSaveClick}>Save</Button>
          </div>
        </div>
      </div>

      <div className="email-edition-sidebar__content">
        <div className="email-edition-sidebar__content-column">
          <div className="email-edition-sidebar__row">
            <div className="email-edition-sidebar__label">Sender name</div>
            <Input
              className="email-edition-sidebar__input"
              isFullWidth
              value={fieldsValue.senderName}
              onChange={({ target: { value } }) =>
                createFieldValueChangeHandler('senderName')(value)
              }
            />
          </div>

          <div className="email-edition-sidebar__row">
            <div className="email-edition-sidebar__label">
              Global daily limit
              <QuestionTooltip text="Total number of emails sent per each 24-hour period, across all campaigns and workspaces. We recommend sending max 50 emails/day to improve deliverability." />
            </div>
            <NumberInput
              minNumber={1}
              maxNumber={mailbox.maxDailySentLimit}
              className="email-edition-sidebar__input"
              isFullWidth
              value={fieldsValue.dailySentLimit}
              onChange={createFieldValueChangeHandler('dailySentLimit')}
            />
          </div>

          <div className="email-edition-sidebar__row">
            <div className="email-edition-sidebar__label">
              Random delay between emails (in seconds)
              <QuestionTooltip text="Respona randomizes the delays in between emails to improve deliverability. We recommend a min delay of 60 sec." />
            </div>
            <FromToRow
              containerStyle={{ marginTop: 0 }}
              inputStyle={{ width: '100%', height: '48px' }}
              minNumber={1}
              maxNumber={9999}
              onChange={(from, to) => {
                createFieldValueChangeHandler('delayBetweenEmailsFrom')(from);
                createFieldValueChangeHandler('delayBetweenEmailsTo')(to);
              }}
              {...mapMinMaxToFromTo({
                min: fieldsValue.delayBetweenEmailsFrom,
                max: fieldsValue.delayBetweenEmailsTo,
              })}
            />
          </div>

          <div className="email-edition-sidebar__row">
            <div className="email-edition-sidebar__label">
              Inbox sync
              <QuestionTooltip text={composeSyncTooltip()} />
            </div>
            <div onClick={handleWrapperInboxSyncClick}>
              <Select
                isDisabled={!enterpriseFeaturesEnabled}
                options={inboxSyncOptions}
                value={inboxSyncOptions.find((item) => item.value === fieldsValue.syncType)}
                onChange={({ value }) => createFieldValueChangeHandler('syncType')(value)}
                additionalStyles={selectStyles}
              />
            </div>
          </div>

          <div className="email-edition-sidebar__row">
            <div className="email-edition-sidebar__label">
              Health check
              <QuestionTooltip text="Respona periodically sends a test email from your account to check for potential deliverability issues. Access the reports in the Insights tab." />
            </div>
            <Select
              options={healthCheckOptions}
              value={healthCheckOptions.find((item) => item.value === fieldsValue.healthcheckState)}
              icon={
                fieldsValue.healthcheckState === MailboxHealthcheckState.HEALTHCHECK_OFF
                  ? 'warningTriangle'
                  : undefined
              }
              iconColor="#FC8E0E"
              onChange={({ value }) => createFieldValueChangeHandler('healthcheckState')(value)}
              additionalStyles={
                fieldsValue.warmUpState === MailboxAccountWarmupState.WARMUP_OFF
                  ? selectWithIconStyles
                  : selectStyles
              }
            />
          </div>

          <div className="email-edition-sidebar__row">
            <div className="email-edition-sidebar__label">
              Workspace access
              <QuestionTooltip text={composeWorkspaceTooltip()} />
            </div>
            <div onClick={handleWrapperWorkSpaceClick}>
              <Select
                isDisabled={!enterpriseFeaturesEnabled && !extendedFeaturesEnabled}
                isMulti
                visibleMultiCount={3}
                options={workspaceOptions}
                value={pickWorkspaceValue}
                onChange={onWorkspaceSelection}
                additionalStyles={{
                  control: (provided) => ({
                    ...provided,
                    height: '48px',
                    borderRadius: '10px',
                  }),
                }}
              />
            </div>
          </div>

          {IS_DEV && (
            <div className="email-edition-sidebar__row">
              <div className="email-edition-sidebar__label">
                Custom tracking domain
                <QuestionTooltip text="Set up your own domain for email tracking to increase the deliverability of your emails." />
              </div>
              <Loader withTopMargin isLoading={isLoadingTrackingDomains}>
                <Select
                  innerRef={trackingDomainRef}
                  options={trackingDomainOptions}
                  value={trackingDomainOptions.find(
                    (item) => item.value === fieldsValue.customTrackingDomain
                  )}
                  onChange={({ value }) =>
                    createFieldValueChangeHandler('customTrackingDomain')(value)
                  }
                  additionalStyles={selectStyles}
                  additionalComponents={{
                    Menu: createFieldSelectMenu(onAddTrackingDomain, trackingDomainRef),
                    Option: CustomDropdownOption,
                  }}
                />
              </Loader>
            </div>
          )}
        </div>

        <div className="email-edition-sidebar__content-column">
          <div className="email-edition-sidebar__row" style={{ marginTop: '13px' }}>
            <div className="email-edition-sidebar__label" style={{ marginBottom: '16px' }}>
              Signature
            </div>

            <SignatureEditor
              onChange={(value) => createFieldValueChangeHandler('signature')(value)}
              message={fieldsValue.signature}
              signatureType={signatureType}
              onChangeSignatureType={handleSignatureTypeChange}
            />
          </div>

          <div
            className="email-edition-sidebar__row"
            style={{ marginTop: '49px', marginBottom: '25px' }}
          >
            <div className="email-edition-sidebar__label">Signature preview</div>
            <div
              className="email-edition-sidebar__signature-preview"
              dangerouslySetInnerHTML={{
                __html: fieldsValue.signature,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(EmailEditionSidebar);
