import { createSelector } from 'reselect';

import { StateType } from '@redux/reducers';
import { InsightFilterType } from '@redux/reducers/insights.reducer';
import { PeopleWebsiteFilterType } from '@ts/company.types';

export const getInsights = ({ insights }: StateType) => insights;
export const insightsFiltersSelector = createSelector(
  [getInsights],
  ({ filters }): InsightFilterType => filters
);
export const insightsCampaignPerformanceFiltersSelector = createSelector(
  [getInsights],
  ({ campaignPerformanceFilters }): PeopleWebsiteFilterType[] => campaignPerformanceFilters
);
export const insightsLeaderBoardsFiltersSelector = createSelector(
  [getInsights],
  ({ leaderBoardsFilters }): PeopleWebsiteFilterType[] => leaderBoardsFilters
);
export const insightsCreditsUsageByUserFiltersSelector = createSelector(
  [getInsights],
  ({ creditsUsageByUserFilters }): PeopleWebsiteFilterType[] => creditsUsageByUserFilters
);
export const insightsDateFilterSelector = createSelector(
  [getInsights],
  ({ dateFilter }) => dateFilter
);
