import React from 'react';

export default ({ color = '#202430', size = 18 }): JSX.Element => (
  <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.83343 4.00034C4.66861 4.00034 4.50749 4.04921 4.37045 4.14078C4.23341 4.23235 4.1266 4.3625 4.06353 4.51477C4.00046 4.66704 3.98395 4.8346 4.01611 4.99625C4.04826 5.1579 4.12763 5.30638 4.24417 5.42293C4.36072 5.53947 4.5092 5.61884 4.67085 5.65099C4.8325 5.68315 5.00006 5.66665 5.15233 5.60357C5.3046 5.5405 5.43475 5.43369 5.52632 5.29665C5.61789 5.15961 5.66676 4.99849 5.66676 4.83367C5.66676 4.61266 5.57896 4.4007 5.42268 4.24442C5.2664 4.08814 5.05444 4.00034 4.83343 4.00034ZM17.0918 8.81701L9.19176 0.933673C9.11406 0.849892 9.01996 0.782986 8.91531 0.737111C8.81066 0.691236 8.69769 0.667374 8.58343 0.667006H3.58343C3.47376 0.666372 3.36504 0.687393 3.26351 0.728863C3.16198 0.770334 3.06963 0.831438 2.99176 0.908673L0.908429 2.98367C0.831194 3.06154 0.77009 3.15389 0.728619 3.25542C0.687149 3.35695 0.666128 3.46567 0.666762 3.57534V8.57534C0.669832 8.79598 0.756151 9.00731 0.908429 9.16701L8.81676 17.0837C8.97646 17.236 9.18779 17.3223 9.40843 17.3253C9.5181 17.326 9.62682 17.305 9.72835 17.2635C9.82988 17.222 9.92223 17.1609 10.0001 17.0837L17.0918 9.99201C17.169 9.91414 17.2301 9.82179 17.2716 9.72026C17.313 9.61873 17.3341 9.51001 17.3334 9.40034C17.3283 9.18259 17.2421 8.9746 17.0918 8.81701ZM9.40843 15.3253L2.33343 8.24201V3.91701L3.91676 2.33367H8.23343L15.3168 9.40867L9.40843 15.3253Z"
      fill={color}
    />
  </svg>
);
