import React from 'react';
import TimePicker from 'react-time-picker';

import './TimePicker.css';

export default function TimePickerWrapper({
  onChange,
  value,
}) {
  return (
    <TimePicker
      onChange={onChange}
      value={value}
      disableClock
      closeClock={false}
      format="hh:mm a"
    />
  );
}
