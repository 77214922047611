import React, { useMemo, useCallback, useRef, useEffect, useState } from 'react';

import cn from 'class-names';
import { useQuery } from 'react-query';
import { Transforms, createEditor, Descendant } from 'slate';
import { withHistory } from 'slate-history';
import { Slate, Editable, ReactEditor, withReact } from 'slate-react';

import { ReferralLinkData } from 'respona_api/generated/user-organization_pb';
import { VariableType } from '@constants/variables';

import { getUnsubscribeContentApi } from '@api/userOrganization.api';

import withBulletPoints from '@hocs/withBulletPoints';
import withEditorVariables from '@hocs/withEditorVariables';
import withEditorLinks from '@hocs/withEditorLinks';
import Display from '@components/Display/Display';

import {
  transformLinkUrlDefault,
  transformVariableDefault,
} from '@uikit/RichEditor/utils/variableUtils';
import Button from '@uikit/Button/Button';
import { SVGIcon, SvgIconType } from '@uikit/Icon/Icon';
import RemoveTwoStepsButton from '@uikit/RemoveTwoStepsButton/RemoveTwoStepsButton';
import EditorChangeFallback from '@uikit/RichEditor/_components/EditorChangeFallback/EditorChangeFallback';
import EmojiButton from '@uikit/RichEditor/_components/EmojiButton/EmojiButton';

import { FileAttachmentType } from '@ts/common.types';
import Attachments from '@uikit/RichEditor/_components/Attachments/Attachments';
import withEditorImages from '@hocs/withEditorImages';

import useAllVariablesInEditor from '@hooks/useAllVariablesInEditor';
import EditorChangeLink from './_components/EditorChangeLink/EditorChangeLink';
import {
  Element,
  Leaf,
  MarkButton,
  BlockButton,
  LinkButton,
  MoreActionsButton,
  AbsoluteVariableButton,
} from './SlateComponents';

import { initialValue } from './slateUtils';

import {
  patchSlateElementByPath,
  putSlateElementByPath,
  transformLinkToTextByPath,
} from './utils/stateUtils';
import { serialize } from './utils/serialize';
import { prepareMessageValueForEditor } from './utils/prepareMessageValueForEditor';

import './SlateEditor.scss';
import ShareWithUsIcon from '@uikit/Icon/svgIcons/ShareWithUsIcon';

type RichEditorPropsType = {
  message: string;
  onChange?: (string) => void;
  onRemoveStep?: () => void | undefined;
  onRevert?: () => void | null;
  removingText?: string;
  placeholder?: string;
  transformVariable?: (
    variable: string,
    fallback: string,
    person?: any,
    opportunity?: any
  ) => string;
  transformLinkUrl?: (url: string, person?: any, opportunity?: any) => string;
  disableVariable?: boolean;
  withAttachments?: boolean;
  withAttachmentsAtAll?: boolean;
  isInline?: boolean;
  disabled?: boolean;
  withControls?: boolean;
  disableVarsInLink?: boolean;
  actionText?: string;
  onAction?: () => void;
  actionIcon?: string;
  renderAction?: () => JSX.Element;
  onFileUpload?: (files: File[]) => void;
  onRemoveFile?: (fileUid: string) => void;
  isAttachmentsLoading?: boolean;
  attachments?: FileAttachmentType[];
  signature?: string;
  useMessageUpdate?: boolean;
  defaultValue?: Descendant[];
  useEmoji?: boolean;
  useAlignment?: boolean;
  classNames?: {
    editor?: string;
    controls?: string;
  };
  allowFullFallbackEditing?: boolean;
  variables?: VariableType[];
  showUnsubscribeContent?: boolean;
  referralContent?: ReferralLinkData.AsObject;
  showSignaturePlaceholder?: boolean;
  handleOpenAiFallbackChange?: (variable: any, fallbackRequired: any, type: string) => void;
};

function SlateEditor({
  message,
  onChange,
  onRemoveStep,
  onRevert = null,
  actionText,
  onAction,
  actionIcon,
  removingText,
  transformVariable = transformVariableDefault,
  transformLinkUrl = transformLinkUrlDefault,
  placeholder = 'Email body',
  disableVariable = false,
  disableVarsInLink = false,
  withAttachments = true,
  withAttachmentsAtAll = true,
  disabled = false,
  withControls = true,
  renderAction,
  onFileUpload,
  onRemoveFile,
  isAttachmentsLoading,
  attachments = [],
  signature = '',
  useMessageUpdate = false,
  defaultValue,
  useEmoji = true,
  showUnsubscribeContent = false,
  classNames,
  allowFullFallbackEditing,
  variables,
  referralContent,
  showSignaturePlaceholder,
  handleOpenAiFallbackChange,
}: RichEditorPropsType): JSX.Element {
  const editorContainerRef = useRef<HTMLDivElement | null>();
  const [value, setValue] = useState<Descendant[]>(initialValue);
  const [changingVariable, setChangingVariable] = useState(null);
  const [isImageUploading, setImageUploading] = useState<boolean>(false);
  const [changingLink, setChangingLink] = useState(null);
  const [wasChanged, changeWasChanged] = useState(false);
  const [lastSelection, changeLastSelection] = useState(null);
  const [isDisabledLinkButton, setDisabledLinkButton] = useState(true);
  const { variables: allVariables } = variables ? { variables } : useAllVariablesInEditor();

  const editor = useMemo(() => {
    return withHistory(
      withEditorImages(
        withBulletPoints(
          withEditorLinks(withEditorVariables(withReact(createEditor() as ReactEditor)))
        )
      )
    );
  }, []);

  const { data } = useQuery({
    queryKey: ['campaign-personalization'],
    queryFn: () => getUnsubscribeContentApi(),
    enabled: showUnsubscribeContent,
    refetchOnWindowFocus: false,
  });

  const renderElement = useCallback(
    (props) => (
      <Element
        {...props}
        transformVariable={transformVariable}
        transformLinkUrl={transformLinkUrl}
        onChangeVariable={(variable, fallbackRequired) => {
          if (handleOpenAiFallbackChange && props.element.type === 'ai-variable') {
            setChangingVariable(null);
          } else {
            setChangingVariable(variable);
          }

          if (handleOpenAiFallbackChange) {
            handleOpenAiFallbackChange(variable, fallbackRequired, props.element.type);
          }
        }}
        onChangeLink={setChangingLink}
        disableVariable={disableVariable}
        editor={editor}
        allVariables={allVariables}
      />
    ),
    [editor, allVariables, transformVariable, transformLinkUrl]
  );

  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  const handleChangeFallback = useCallback((newFallback, path, href) => {
    Transforms.deselect(editor);

    setValue((prevValue) =>
      patchSlateElementByPath(prevValue, path, { fallback: newFallback, href })
    );
  }, []);

  const handleTransformVariableIntoText = useCallback((newElem, path) => {
    Transforms.deselect(editor);

    setValue((prevValue) => {
      return putSlateElementByPath(prevValue, path, newElem);
    });
  }, []);

  const handleChangeHref = useCallback((newHref, path) => {
    Transforms.deselect(editor);

    setValue((prevValue) => {
      // If new URL value is empty we map all elements and transform link to text by transformLinkToTextByPath
      if (!newHref) {
        return transformLinkToTextByPath(prevValue, path);
      }

      return patchSlateElementByPath(prevValue, path, { url: newHref });
    });
  }, []);

  const handleSlateContainerClick = () => editor && ReactEditor.focus(editor);

  useEffect(
    () => {
      const preparedMessage = prepareMessageValueForEditor(message);

      setValue(preparedMessage);
    },
    useMessageUpdate ? [message] : [null]
  );

  useEffect(() => {
    if (wasChanged) {
      onChange(serialize(value));
    }
  }, [value]);

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  const cnSlateEditor = cn('slate-editor__editor', classNames?.editor, {
    'slate-editor__editor--with-vars': !disableVariable,
    'slate-editor__editor--disabled': disabled,
    'slate-editor__editor--with-signature': signature && signature.length > 0,
  });

  editor.children = value;

  return (
    <div ref={editorContainerRef} className="slate-editor">
      <Slate
        editor={editor}
        value={value}
        onChange={(currentValue) => {
          setValue(currentValue);
          const { selection } = editor;

          changeWasChanged(true);
          if (selection !== null) {
            changeLastSelection(selection);
          }
        }}
      >
        <div
          className={cnSlateEditor}
          onClick={handleSlateContainerClick}
          tabIndex={0}
          role="button"
        >
          <Display isVisible={withAttachments}>
            <Attachments
              isLoading={isAttachmentsLoading}
              attachments={attachments}
              onRemoveFile={onRemoveFile}
            />
          </Display>
          <Display isVisible={changingVariable !== null}>
            <EditorChangeFallback
              onChangeFallback={handleChangeFallback}
              onTransformIntoText={handleTransformVariableIntoText}
              containerRef={editorContainerRef}
              editor={editor}
              onClose={() => setChangingVariable(null)}
              changingVariable={changingVariable}
              disableVariable={disableVariable}
              allowFullFallbackEditing={allowFullFallbackEditing}
            />
          </Display>
          <Display isVisible={changingLink !== null}>
            <EditorChangeLink
              onChangeHref={handleChangeHref}
              linkInfo={changingLink}
              containerRef={editorContainerRef}
              editor={editor}
              onClose={() => setChangingLink(null)}
              transformLinkUrl={transformLinkUrl}
              disableVarsInLink={disableVarsInLink}
              useApplyButton
            />
          </Display>
          <Editable
            className="editable"
            readOnly={disabled}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            placeholder={placeholder}
            onFocus={() => {
              editorContainerRef.current.classList.add('focused-editor');
            }}
            onBlur={() => {
              editorContainerRef.current.classList.remove('focused-editor');
            }}
            onSelect={() => {
              setDisabledLinkButton(
                editor.selection?.anchor?.offset === editor.selection?.focus?.offset
              );
            }}
          />
          <Display isVisible={!disableVariable}>
            <AbsoluteVariableButton
              lastSelection={lastSelection}
              editor={editor}
              containerRef={editorContainerRef}
              variables={variables}
              onCreateNew={
                handleOpenAiFallbackChange
                  ? () => handleOpenAiFallbackChange({}, true, 'ai-variable')
                  : null
              }
            />
          </Display>

          <div className="editable-footer" onClick={(event) => event.stopPropagation()}>
            <Display isVisible={!!referralContent?.content}>
              <div
                className="slate-editor__referral-content"
                dangerouslySetInnerHTML={{
                  __html: referralContent?.content?.replace('%s', referralContent?.link),
                }}
              />
            </Display>

            <Display isVisible={signature && signature.length > 0}>
              <div
                className="slate-editor__signature"
                dangerouslySetInnerHTML={{ __html: signature }}
              />
            </Display>

            <Display isVisible={!signature?.length && showSignaturePlaceholder}>
              <div className="slate-editor__signature-placeholder">
                <SVGIcon icon="documentSignatureIcon" />
                <span>Sender email signature will appear here</span>

                <Button
                  href="/settings/all-emails"
                  onClick={() => null}
                  size="l"
                  type="primary"
                  className="slate-editor__signature-placeholder-email-open"
                >
                  <SVGIcon icon="shareWithUsIcon" color="#251FB6" />
                </Button>
              </div>
            </Display>

            <Display isVisible={showUnsubscribeContent && !!data?.content}>
              <div
                className="slate-editor__unsubscribe-content"
                dangerouslySetInnerHTML={{
                  __html: data?.content?.replace('"%s"', '/unsubscribed'),
                }}
              />
            </Display>
          </div>
        </div>
        <Display isVisible={!disabled && withControls}>
          <div className={cn('slate-editor__controls', classNames?.controls)}>
            <Display isVisible={onAction !== undefined}>
              <Button size="s" onClick={onAction} className="slate-editor__action-button">
                <Display isVisible={Boolean(actionIcon)}>
                  <SVGIcon icon={actionIcon as SvgIconType} />
                </Display>
                {actionText}
              </Button>
            </Display>
            {renderAction ? renderAction() : null}
            <MarkButton format="bold" icon="boldText" />
            <MarkButton format="italic" icon="italicText" />
            <MarkButton format="underline" icon="underlineText" />
            <div className="slate-editor__controls-separator" />
            <BlockButton format="numbered-list" icon="orderedList" />
            <BlockButton format="bulleted-list" icon="unorderedList" />
            <div className="slate-editor__controls-separator" />
            <LinkButton
              isDisabled={isDisabledLinkButton}
              onChangeLink={setChangingLink}
              editorRef={editor}
            />
            <div className="slate-editor__controls-separator" />
            <Display isVisible={useEmoji}>
              <>
                <EmojiButton editor={editor} lastSelection={lastSelection} />
                <div className="slate-editor__controls-separator" />
              </>
            </Display>
            <Display isVisible={withAttachmentsAtAll}>
              <MoreActionsButton
                onFileUpload={onFileUpload}
                withAttachments={withAttachments}
                lastSelection={lastSelection}
                setImageUploading={setImageUploading}
              />
            </Display>
            <div className="slate-editor__right-bottom-corner-buttons">
              <Display isVisible={onRevert !== null}>
                <Button
                  type="ghost"
                  size="xs"
                  className="slate-editor__revert-button"
                  onClick={onRevert}
                >
                  <SVGIcon icon="refreshOneArrowAround" />
                </Button>
              </Display>
              <Display isVisible={onRemoveStep !== undefined}>
                <span key={removingText} className="slate-editor__trash-button">
                  <RemoveTwoStepsButton
                    onRemoveStep={onRemoveStep}
                    removingItemText={removingText}
                  />
                </span>
              </Display>
              {isImageUploading ? (
                <span className="slate-editor__spinner">
                  <SVGIcon icon="spinner" />
                </span>
              ) : null}
            </div>
          </div>
        </Display>
      </Slate>
    </div>
  );
}

export default SlateEditor;
