/*
  Config order based on NotificationType in UserNotificationSettingResponse
 */
export const subscriptionsConfig = [
  {
    title: 'Product announcements',
    description: 'Stay in the loop with Respona’s new features and updates. Max 1 email a month.',
  },
  {
    title: 'Onboarding',
    description:
      'Learn how to become a Respona insider by 5-6 emails explaining the best performing strategies.',
  },
  {
    title: 'Newsletter',
    description:
      'Keep up with with the latest educational resources published on Respona’s blog. Max 1 email a month.',
  },
  {
    title: 'Customer success',
    description:
      'Allow our team to get in touch with you regarding your account’s performance. Max 1 email a month.',
  },
  {
    title: 'System notifications',
    description:
      'Important information regarding your account, such as email disconnections and automation completions.',
  },
];
