import React from 'react';

import { Button } from '@uikit/Button/Button';
import { SVGIcon } from '@uikit/Icon/Icon';

import './VerifyingButton.scss';

enum VerifyingButtonTextEnum {
  Verify = 'Verify opportunities',
  EmailMe = "Email me when it's done",
  LaunchUnverified = 'Launch unverified opportunities',
  LaunchVerified = 'Launch verified opportunities',
  Launching = 'Launching…',
  Stop = 'Stop verification',
}
export type VerifyingButtonTextType =
  | 'Verify'
  | 'EmailMe'
  | 'LaunchUnverified'
  | 'LaunchVerified'
  | 'Launching'
  | 'Stop';

const VerifyingButton = ({
  type,
  onClick,
  disabled = false,
  onClickDisabled,
  isLoading = false,
}: {
  type: VerifyingButtonTextType;
  onClick: () => void;
  disabled?: boolean;
  onClickDisabled?: () => void;
  isLoading?: boolean;
}): JSX.Element => {
  return (
    <Button
      className="verifying-btn"
      type="additional"
      onClick={onClick}
      disabled={disabled}
      onClickDisabled={onClickDisabled}
      isLoading={isLoading}
    >
      {VerifyingButtonTextEnum[type] === VerifyingButtonTextEnum.Verify && (
        <SVGIcon icon="verifyPlayIcon" color="#ffffff" />
      )}
      {VerifyingButtonTextEnum[type] === VerifyingButtonTextEnum.Stop && (
        <span className="verifying-btn__stop-icon" />
      )}
      <span>{VerifyingButtonTextEnum[type]}</span>
    </Button>
  );
};

export default VerifyingButton;
