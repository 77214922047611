export const getBlobFromBase64 = (b64Data, contentType) => {
  const sliceSize = 512;
  let computedB64Data = b64Data.replace(/^[^,]+,/, '');
  computedB64Data = computedB64Data.replace(/\s/g, '');

  const byteCharacters = window.atob(computedB64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }
  return new Blob(byteArrays, { type: contentType || '' });
};

export default (fileName: string, bytes: string): File => {
  const blob = getBlobFromBase64(bytes, 'text/csv');
  return new File([blob], fileName, { type: 'text/csv' });
};
