import React from 'react';

import DropdownPanel from '@uikit/DropdownPanel/DropdownPanel';

import './AdvancedSettingsWrapper.scss';

const AdvancedSettingsWrapper: React.FC = ({ children }) => (
  <div className="advanced-settings-wrapper">
    <DropdownPanel
      classNames={{
        header: 'advanced-settings-wrapper__header',
        content: 'advanced-settings-wrapper__content',
      }}
      headerIcon="gear"
      headerText="Advanced settings"
    >
      {children}
    </DropdownPanel>
  </div>
);

export default AdvancedSettingsWrapper;
