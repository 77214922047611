import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  IntegrationStatus as INTEGRATION_STATUS,
  IntegrationType as INTEGRATION_TYPE,
} from 'respona_api/generated/common_pb';

import { mapFromToToMinMax, mapMinMaxToFromTo } from '@helpers/commonHelpers';

import QuestionTooltip from '@pages/CampaignCreationPage/_components/QuestionTooltip/QuestionTooltip';

import { setAhrefsMetrics } from '@redux/actions/automationContent.actions';
import { ahrefsMetricsSelector } from '@redux/selectors/automationContent.selectors';
import { integrationsSelector } from '@redux/selectors/integrations.selectors';

import { Button } from '@uikit/Button/Button';

import AhrefsLogo from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/assets/AhrefsLogo';
import FromRow from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/FromRow/FromRow';
import FromToRow from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/FromToRow/FromToRow';
import MetricsWrapper from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/MetricsWrapper/MetricsWrapper';

import './AhrefsMetrics.scss';

const AhrefsMetrics: React.FC = () => {
  const dispatch = useDispatch();
  const { includePaidMetrics, domainRating, domainMonthlyTraffic, domainOrganicKeywords } =
    useSelector(ahrefsMetricsSelector);
  const { integrations } = useSelector(integrationsSelector);

  const toggleIncludePaidMetrics = () => {
    dispatch(
      setAhrefsMetrics({
        includePaidMetrics: !includePaidMetrics,
      })
    );
  };

  const isAhrefsConnected = integrations.some(
    (item) =>
      item.type === INTEGRATION_TYPE.AHREFS_INTEGRATION &&
      item.status === INTEGRATION_STATUS.ACTIVE_INTEGRATION
  );

  function renderMetrics(): React.JSX.Element {
    if (!isAhrefsConnected) {
      return (
        <div className="ahrefs-metrics-include-paid">
          <span>Connect your Ahrefs account to view more metrics.</span>
          <Button href="/settings/integrations">Connect Ahrefs</Button>
        </div>
      );
    }
    if (!!includePaidMetrics) {
      return (
        <Button
          className="ahrefs-metrics-remove-filters"
          onClick={toggleIncludePaidMetrics}
          type="ghost"
          size="m"
        >
          Remove filters
        </Button>
      );
    }
    return (
      <div className="ahrefs-metrics-include-paid">
        <span>Use your Ahrefs account to add more filters</span>
        <Button onClick={toggleIncludePaidMetrics}>Add filters</Button>
      </div>
    );
  }

  return (
    <MetricsWrapper
      borderLeft
      Logo={() => <AhrefsLogo height={30} />}
      withAddOtherMetricsBtn={false}
    >
      <div className="ahrefs-metrics-paid">
        <FromToRow
          inputStyle={{ width: '159px' }}
          label={
            <>
              Domain rating
              <QuestionTooltip
                color="#C6C6C6"
                style={{ marginLeft: '7px' }}
                text="The strength of domain's backlink profile compared to the other websites in Ahrefs database on a 100-point logarithmic scale."
              />
            </>
          }
          onChange={(from, to) => {
            dispatch(
              setAhrefsMetrics({
                domainRating: mapFromToToMinMax({ from, to }),
              })
            );
          }}
          {...mapMinMaxToFromTo(domainRating)}
        />
        <FromRow
          maxNumber={1000000}
          inputStyle={{ width: '318px' }}
          disabled={!includePaidMetrics}
          label={
            <>
              Min domain monthly traffic
              <QuestionTooltip
                color="#C6C6C6"
                style={{ marginLeft: '7px' }}
                text="The estimated number of monthly visits the website gets from organic search."
              />
            </>
          }
          onChange={(from, to) => {
            dispatch(
              setAhrefsMetrics({
                domainMonthlyTraffic: mapFromToToMinMax({ from, to }),
              })
            );
          }}
          {...mapMinMaxToFromTo(domainMonthlyTraffic)}
        />
        <FromRow
          maxNumber={1000000}
          inputStyle={{ width: '318px' }}
          disabled={!includePaidMetrics}
          label={
            <>
              Min domain organic keywords
              <QuestionTooltip
                color="#C6C6C6"
                style={{ marginLeft: '7px' }}
                text="The total number of keywords the website ranks for in the top 100 organic search results."
              />
            </>
          }
          onChange={(from, to) => {
            dispatch(
              setAhrefsMetrics({
                domainOrganicKeywords: mapFromToToMinMax({ from, to }),
              })
            );
          }}
          {...mapMinMaxToFromTo(domainOrganicKeywords)}
        />

        {renderMetrics()}
      </div>
    </MetricsWrapper>
  );
};

export default AhrefsMetrics;
