import React from 'react';

export default ({ color }: { color: string }): JSX.Element => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.0358 1.5C14.1036 1.5 13.3369 2.26399 13.3369 3.22018V6.5141C13.3369 7.46947 14.1038 8.23429 15.0358 8.23429H18.3024C19.2332 8.23429 20.0003 7.46968 20.0003 6.5141V3.22018C20.0003 2.26378 19.2334 1.5 18.3024 1.5H15.0358ZM11.8369 3.22018C11.8369 1.44781 13.263 0 15.0358 0H18.3024C20.0745 0 21.5003 1.44801 21.5003 3.22018V6.5141C21.5003 8.28503 20.0747 9.73429 18.3024 9.73429H15.0358C13.2628 9.73429 11.8369 8.28524 11.8369 6.5141V3.22018Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.19892 1.5C2.26669 1.5 1.5 2.26399 1.5 3.22018V6.5141C1.5 7.46947 2.2669 8.23429 3.19892 8.23429H6.46449C7.39652 8.23429 8.16341 7.46947 8.16341 6.5141V3.22018C8.16341 2.26399 7.39672 1.5 6.46449 1.5H3.19892ZM0 3.22018C0 1.44781 1.42607 0 3.19892 0H6.46449C8.23734 0 9.66341 1.44781 9.66341 3.22018V6.5141C9.66341 8.28524 8.23755 9.73429 6.46449 9.73429H3.19892C1.42586 9.73429 0 8.28524 0 6.5141V3.22018Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.19892 13.2343C2.2669 13.2343 1.5 13.9981 1.5 14.9555V18.2484C1.5 19.2046 2.26669 19.9686 3.19892 19.9686H6.46449C7.39672 19.9686 8.16341 19.2046 8.16341 18.2484V14.9555C8.16341 13.9981 7.39652 13.2343 6.46449 13.2343H3.19892ZM0 14.9555C0 13.1823 1.42586 11.7343 3.19892 11.7343H6.46449C8.23755 11.7343 9.66341 13.1823 9.66341 14.9555V18.2484C9.66341 20.0208 8.23734 21.4686 6.46449 21.4686H3.19892C1.42607 21.4686 0 20.0208 0 18.2484V14.9555Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.0358 13.2343C14.1038 13.2343 13.3369 13.9981 13.3369 14.9555V18.2484C13.3369 19.2046 14.1036 19.9686 15.0358 19.9686H18.3024C19.2334 19.9686 20.0003 19.2048 20.0003 18.2484V14.9555C20.0003 13.9979 19.2332 13.2343 18.3024 13.2343H15.0358ZM11.8369 14.9555C11.8369 13.1823 13.2628 11.7343 15.0358 11.7343H18.3024C20.0747 11.7343 21.5003 13.1825 21.5003 14.9555V18.2484C21.5003 20.0206 20.0745 21.4686 18.3024 21.4686H15.0358C13.263 21.4686 11.8369 20.0208 11.8369 18.2484V14.9555Z"
      fill={color}
    />
  </svg>
);
