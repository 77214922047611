import React, { Dispatch, SetStateAction, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@uikit/Button/Button';
import SelectedOpportunitiesWidget from '@components/CampaignSettings/Opportunities/SelectedOpportunitiesWidget';
import ContentSearchActivityOpportunitiesSearch from '@components/CampaignSettings/ContentSearchActivityOpportunitiesSearch/ContentSearchActivityOpportunitiesSearch';

import './ContentSearchActivityOpportunities.scss';

import { CampaignOpportunityFilterType, OpportunityPageType } from '@ts/opportunity.types';
import { exportListOpportunitiesApi } from '@api/campaign.api';
import { getActiveCampaignInfo } from '@redux/selectors/campaignSettings.selectors';
import { getRefetchOpportunities } from '@redux/selectors/pipelines.selectors';
import { removeOpportunitiesBatch } from '@redux/thunks/campaignSettings.thunks';
import { downloadResponse } from '@helpers/fileHelpers';
import useOutsideClick from '@hooks/useOutsideClick';
import { billingDetailsSelector } from '@redux/selectors/billings.selectors';
import { FilterOperation } from 'respona_api/generated/common_pb';

function ContentSearchActivityOpportunities({
  setOpenAutomationSearchModal,
  setOpenFindContactsModal,
  setOpenPersonalizerModal,
  setOpenOpportunitySidebar,
  setActiveOpportunity,
  setOpportunityIndex,
  setPaymentSidebarOpen,
  setActivePipelineId,
  totalItemsNumber,
  filters,
  setFilters,
}: {
  setOpenAutomationSearchModal: Dispatch<SetStateAction<boolean>>;
  setOpenFindContactsModal: Dispatch<SetStateAction<boolean>>;
  setOpenPersonalizerModal: Dispatch<SetStateAction<boolean>>;
  setOpenOpportunitySidebar: Dispatch<SetStateAction<boolean>>;
  setActiveOpportunity: Dispatch<SetStateAction<OpportunityPageType>>;
  setOpportunityIndex: Dispatch<SetStateAction<number>>;
  setPaymentSidebarOpen: Dispatch<SetStateAction<boolean>>;
  setActivePipelineId: Dispatch<SetStateAction<number>>;
  totalItemsNumber: number | undefined;
  filters: CampaignOpportunityFilterType[];
  setFilters: Dispatch<SetStateAction<CampaignOpportunityFilterType[]>>;
}): JSX.Element {
  const dispatch = useDispatch();
  const confirmBtnRef = useRef<HTMLButtonElement>();
  const [checkboxes, setCheckboxes] = useState<number[]>([]);
  const [isVisibleRemoveApproval, setVisibleRemoveApproval] = useState<boolean>(false);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [isSelectedAll, setSelectedAll] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isOpportunitiesPresent, setIsOpportunitiesPresent] = useState<boolean>(false);
  const { info: campaignInfo } = useSelector(getActiveCampaignInfo);
  const refetchOpportunities = useSelector(getRefetchOpportunities);
  const { data: billingDetails } = useSelector(billingDetailsSelector);

  useOutsideClick(confirmBtnRef, () => setVisibleRemoveApproval(false));

  const handleChangeCheckbox = (id: number) => {
    if (checkboxes.includes(id)) {
      setCheckboxes(checkboxes.filter((item) => item !== id));
      setSelectedAll(false);
    } else {
      setCheckboxes([id, ...checkboxes]);
    }
  };

  const onAddNewOpportunitiesClick = () => {
    setActivePipelineId(-1);
    setOpenAutomationSearchModal(true);
  };

  function onApplyFilters(value: CampaignOpportunityFilterType[]) {
    if (JSON.stringify(value) === JSON.stringify(filters)) {
      return;
    }

    setFilters(value);
  }

  async function onDeleteOpportunitiesClick() {
    try {
      setIsDeleting(true);
      await dispatch(
        removeOpportunitiesBatch(
          campaignInfo.id,
          campaignInfo.workspaceId,
          campaignInfo.lastBatch,
          isSelectedAll ? [] : checkboxes,
          filters
        )
      );
      refetchOpportunities();
      setVisibleRemoveApproval(false);
      setCheckboxes([]);
      setSelectedAll(false);
    } finally {
      setIsDeleting(false);
    }
  }

  async function onExportOpportunitiesClick() {
    if (billingDetails.state === 6) {
      setPaymentSidebarOpen(true);
    } else {
      setIsExporting(true);
      const res = await exportListOpportunitiesApi(
        campaignInfo.id,
        campaignInfo.workspaceId,
        false,
        campaignInfo.lastBatch,
        isSelectedAll ? [] : checkboxes,
        filters
      );
      setIsExporting(false);
      downloadResponse(res);
    }
  }

  const areFiltersEnabled = useMemo(() => {
    return Object.values(filters).some(({ value, operation }) => {
      return (
        operation === FilterOperation.STRING_IS_BLANK ||
        operation === FilterOperation.STRING_IS_NOT_BLANK ||
        value.toString().length > 0
      );
    });
  }, [filters]);

  return (
    <div className="content-search-opportunity">
      <h3 className="content-search-opportunity__title">Opportunities</h3>
      <div className="content-search-opportunity__header">
        <div className="search-form">
          <ContentSearchActivityOpportunitiesSearch
            onApplyFilters={(value) => onApplyFilters(value)}
            areFiltersEnabled={areFiltersEnabled}
          />
        </div>
        <div className="header-list-buttons">
          {checkboxes.length === 0 ? null : isVisibleRemoveApproval ? (
            <span ref={confirmBtnRef}>
              <Button
                isLoading={isDeleting}
                leftIcon="trash"
                type="alert"
                onClick={() => onDeleteOpportunitiesClick()}
              >
                Confirm
              </Button>
            </span>
          ) : (
            <Button leftIcon="trash" type="alert" onClick={() => setVisibleRemoveApproval(true)}>
              Remove
            </Button>
          )}
          {(checkboxes.length > 0 || (filters.length > 0 && isOpportunitiesPresent)) && (
            <Button
              leftIcon="download"
              colorIcon="#2822B7"
              type="additional"
              isLoading={isExporting}
              onClick={() => onExportOpportunitiesClick()}
            >
              Export
            </Button>
          )}
          {/* <Button leftIcon="emailPersonalized" colorIcon="#2822B7" type="additional"> */}
          {/*  Personlize emails */}
          {/* </Button> */}
          <Button
            leftIcon="userSearch"
            colorIcon="#2822B7"
            type="additional"
            onClick={() => setOpenFindContactsModal(true)}
          >
            Bulk contact finder
          </Button>
          <Button
            leftIcon="emailPersonalized"
            colorIcon="#2822B7"
            type="additional"
            onClick={() => setOpenPersonalizerModal(true)}
          >
            Bulk email personalizer
          </Button>
          <Button leftIcon="plusIcon" onClick={() => onAddNewOpportunitiesClick()}>
            Add new opportunities
          </Button>
        </div>
      </div>

      <SelectedOpportunitiesWidget
        handleChangeCheckbox={handleChangeCheckbox}
        checkboxes={checkboxes}
        setCheckboxes={setCheckboxes}
        filters={filters}
        setIsOpportunitiesPresent={setIsOpportunitiesPresent}
        isOpportunitiesPresent={isOpportunitiesPresent}
        setOpenOpportunitySidebar={setOpenOpportunitySidebar}
        setActiveOpportunity={setActiveOpportunity}
        setOpportunityIndex={setOpportunityIndex}
        isSelectedAll={isSelectedAll}
        setSelectedAll={setSelectedAll}
        totalItemsNumber={totalItemsNumber}
      />
    </div>
  );
}

export default ContentSearchActivityOpportunities;
