import React, { useState } from 'react';
import cn from 'class-names';

import { SVGIcon } from '@uikit/Icon/Icon';
import Display from '@components/Display/Display';

import './RemoveTwoStepsButton.scss';

type RemoveTwoStepsButtonPropsType = {
  onRemoveStep: () => void | undefined;
  removingItemText: string | number;
};

const RemoveTwoStepsButton = ({
  onRemoveStep,
  removingItemText = '',
}: RemoveTwoStepsButtonPropsType): JSX.Element => {
  const [isFirstTimeClicked, changeIsFirstTimeClicked] = useState(false);

  const handleClick = (e) => {
    e.stopPropagation();

    if (isFirstTimeClicked) {
      onRemoveStep && onRemoveStep();
      changeIsFirstTimeClicked(false);
    } else {
      changeIsFirstTimeClicked(true);
    }
  };

  const cnRemoveTwoStepsButton = cn('remove-two-steps-button', {
    'remove-two-steps-button--clicked': isFirstTimeClicked,
  });

  return (
    <button type="button" onClick={handleClick} className={cnRemoveTwoStepsButton}>
      <SVGIcon icon="trash" className="remove-two-steps-button__icon" />
      <span className="remove-two-steps-button__text">
        Delete
        <Display isVisible={String(removingItemText).length > 0}>
          <span className="remove-two-steps-button__removing-item-text">
            &nbsp;{removingItemText}&nbsp;
          </span>
        </Display>
        ?
      </span>
    </button>
  );
};

export default RemoveTwoStepsButton;
