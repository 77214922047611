import React from 'react';

export default ({ color = '#fff' }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.00001 1.3335C6.68147 1.3335 5.39254 1.72449 4.29621 2.45703C3.19988 3.18957 2.3454 4.23077 1.84082 5.44894C1.33623 6.66711 1.20421 8.00756 1.46144 9.30077C1.71868 10.594 2.35362 11.7819 3.28597 12.7142C4.21832 13.6466 5.4062 14.2815 6.69941 14.5387C7.99262 14.796 9.33306 14.6639 10.5512 14.1594C11.7694 13.6548 12.8106 12.8003 13.5431 11.704C14.2757 10.6076 14.6667 9.31871 14.6667 8.00016C14.6667 7.12468 14.4942 6.25778 14.1592 5.44894C13.8242 4.6401 13.3331 3.90517 12.7141 3.28612C12.095 2.66706 11.3601 2.176 10.5512 1.84097C9.7424 1.50593 8.87549 1.3335 8.00001 1.3335ZM8.00001 13.3335C6.58553 13.3335 5.22897 12.7716 4.22878 11.7714C3.22858 10.7712 2.66668 9.41465 2.66668 8.00016C2.66521 6.81573 3.06202 5.66519 3.79335 4.7335L11.2667 12.2068C10.335 12.9382 9.18445 13.335 8.00001 13.3335ZM12.2067 11.2668L4.73335 3.7935C5.66504 3.06217 6.81558 2.66536 8.00001 2.66683C9.4145 2.66683 10.7711 3.22873 11.7712 4.22893C12.7714 5.22912 13.3333 6.58567 13.3333 8.00016C13.3348 9.1846 12.938 10.3351 12.2067 11.2668Z"
      fill={color}
    />
  </svg>
);
