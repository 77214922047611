import { ScheduleType } from '@ts/schedules.types';
import { daysOfWeek } from '@constants/days';
import { CustomVariablePlaceholderType, CustomVariableValueType } from '@ts/customVariales.types';
import {
  CustomVariableType,
  CustomVariableType as CUSTOM_VARIABLE_TYPE,
  CustomVariableValueRelatedTo,
} from 'respona_api/generated/common_pb';
import { OrganizationMemberResponseType } from '@ts/userOrganization.types';

type TransformFuncType = (
  fallback: string,
  person: any,
  opportunity: any,
  step: any,
  schedule: ScheduleType,
  customVariablesValues: CustomVariableValueType[]
) => string;

type TransformLinkFuncType = (fallback: string, person: any, opportunity?: any) => string;

export type VariableType = CustomVariablePlaceholderType & {
  value: string;
  transform?: TransformFuncType;
  transformLink?: TransformLinkFuncType;
  withFallback: boolean;
  isUrlVariable: boolean;
};

const variables: VariableType[] = [
  {
    id: -1,
    title: 'URL',
    value: 'url',
    transform: (fallback: string, _, opportunity: { url: string }): string =>
      opportunity?.url.length ? String(opportunity.url) : fallback,
    transformLink: (_, opportunity) => (opportunity ? String(opportunity.url) : ''),
    withFallback: true,
    isUrlVariable: true,

    name: 'URL',
    description: 'Website address',
    createdAt: -1,
    workspaceId: -1,
    type: CUSTOM_VARIABLE_TYPE.URL,
    relatedTo: CustomVariableValueRelatedTo.WEBSITE,
    isDefault: true,
    openAi: undefined,
    select: undefined,
  },
  {
    id: -1,
    title: 'URL Title',
    value: 'url_title',
    transform: (fallback, _, opportunity: { title: string }) =>
      opportunity?.title.length ? String(opportunity.title) : fallback,
    withFallback: true,
    isUrlVariable: false,

    name: '',
    description: 'Website title',
    createdAt: -1,
    workspaceId: -1,
    type: CUSTOM_VARIABLE_TYPE.TEXT,
    relatedTo: CustomVariableValueRelatedTo.WEBSITE,
    isDefault: true,
    openAi: undefined,
    select: undefined,
  },
  {
    id: -1,
    title: 'First Name',
    value: 'first_name',
    transform: (fallback: string, person: { name: string }): string => {
      if (!person?.name) {
        return fallback;
      }

      if (person?.name?.includes(' team')) {
        return person.name;
      }

      return person.name.split(' ')[0];
    },
    withFallback: true,
    isUrlVariable: false,

    name: '',
    description: 'Person first name',
    createdAt: -1,
    type: CUSTOM_VARIABLE_TYPE.TEXT,
    relatedTo: CustomVariableValueRelatedTo.PERSON,
    isDefault: true,
    openAi: undefined,
    select: undefined,
    workspaceId: -1,
  },
  {
    id: -1,
    title: 'Full Name',
    value: 'full_name',
    transform: (fallback, person: { name: string }) => {
      if (person?.name.length) {
        return String(person.name);
      }

      return fallback;
    },
    withFallback: true,
    isUrlVariable: false,

    name: '',
    description: 'Person full name',
    createdAt: -1,
    type: CUSTOM_VARIABLE_TYPE.TEXT,
    relatedTo: CustomVariableValueRelatedTo.PERSON,
    isDefault: true,
    openAi: undefined,
    select: undefined,
    workspaceId: -1,
  },
  {
    id: -1,
    title: 'Last Name',
    value: 'last_name',
    transform: (fallback, person) => {
      if (!person?.name) {
        return fallback;
      }

      if (person?.name?.includes(' team')) {
        return '';
      }

      return person.name.split(' ')[1];
    },
    withFallback: true,
    isUrlVariable: false,

    name: '',
    description: 'Person last name',
    createdAt: -1,
    type: CUSTOM_VARIABLE_TYPE.TEXT,
    relatedTo: CustomVariableValueRelatedTo.PERSON,
    isDefault: true,
    openAi: undefined,
    select: undefined,
    workspaceId: -1,
  },
  {
    id: -1,
    title: 'Email',
    value: 'email',
    transform: (fallback, person) => {
      if (person?.emailsList?.length) {
        return person.emailsList[0].email;
      }

      return fallback;
    },
    withFallback: true,
    isUrlVariable: false,

    name: '',
    description: 'Person email',
    createdAt: -1,
    type: CUSTOM_VARIABLE_TYPE.TEXT,
    relatedTo: CustomVariableValueRelatedTo.PERSON,
    isDefault: true,
    openAi: undefined,
    select: undefined,
    workspaceId: -1,
  },
  {
    id: -1,
    title: 'Location',
    value: 'location',
    transform: (fallback, person) => person?.location || fallback,
    withFallback: true,
    isUrlVariable: false,

    name: '',
    description: 'Location',
    createdAt: -1,
    type: CUSTOM_VARIABLE_TYPE.TEXT,
    relatedTo: CustomVariableValueRelatedTo.PERSON,
    isDefault: true,
    openAi: undefined,
    select: undefined,
    workspaceId: -1,
  },
  {
    id: -1,
    title: 'Job Title',
    value: 'job_position',
    transform: (fallback, person) => person?.jobPosition || fallback,
    withFallback: true,
    isUrlVariable: false,

    name: '',
    description: 'Job title',
    createdAt: -1,
    type: CUSTOM_VARIABLE_TYPE.TEXT,
    relatedTo: CustomVariableValueRelatedTo.PERSON,
    isDefault: true,
    openAi: undefined,
    select: undefined,
    workspaceId: -1,
  },
  {
    id: -1,
    title: 'Organization',
    value: 'organization',
    transform: (fallback, person) => person?.companyName || fallback,
    withFallback: true,
    isUrlVariable: false,

    name: '',
    description: 'Organization name',
    createdAt: -1,
    type: CUSTOM_VARIABLE_TYPE.TEXT,
    relatedTo: CustomVariableValueRelatedTo.PERSON,
    isDefault: true,
    openAi: undefined,
    select: undefined,
    workspaceId: -1,
  },
  {
    id: -1,
    title: 'LinkedIn URL',
    value: 'linkedin',
    transformLink: (fallback, person) => {
      const { socialLinksList } = person || {};

      const linkedIn = socialLinksList ? socialLinksList.find(({ type }) => type === 3) : undefined;
      if (linkedIn) {
        return linkedIn.url;
      }

      return '';
    },
    transform: (fallback, person) => {
      const { socialLinksList } = person || {};

      const linkedIn = socialLinksList ? socialLinksList.find(({ type }) => type === 3) : undefined;
      if (linkedIn) {
        return linkedIn.url;
      }

      return '';
    },
    withFallback: true,
    isUrlVariable: true,

    name: '',
    description: 'Person LinkedIn link',
    createdAt: -1,
    type: CUSTOM_VARIABLE_TYPE.URL,
    relatedTo: CustomVariableValueRelatedTo.PERSON,
    isDefault: true,
    openAi: undefined,
    select: undefined,
    workspaceId: -1,
  },
  {
    id: -1,
    title: 'Day of week',
    value: 'day_of_week',
    transform: (fallback, person, opportunity, step) => {
      const curDate = new Date();

      if (step.nextActivityTime) {
        return daysOfWeek[new Date(step.nextActivityTime).getDay()];
      }

      const delayDate = new Date(Number(curDate) + step.delayFromFirstDay * 24 * 60 * 60 * 1000);

      return daysOfWeek[delayDate.getDay()];
    },
    withFallback: false,
    isUrlVariable: false,

    name: '',
    description: 'Day of week',
    createdAt: -1,
    type: CUSTOM_VARIABLE_TYPE.TEXT,
    relatedTo: CustomVariableValueRelatedTo.PERSON,
    isDefault: true,
    openAi: undefined,
    select: undefined,
    workspaceId: -1,
  },
  {
    id: -1,
    title: 'Website Name',
    value: 'website_name',
    withFallback: true,
    isUrlVariable: false,
    transform: (fallback, person) => person?.website?.title || fallback,

    name: '',
    description: 'Website name',
    createdAt: -1,
    type: CUSTOM_VARIABLE_TYPE.TEXT,
    relatedTo: CustomVariableValueRelatedTo.WEBSITE,
    isDefault: true,
    openAi: undefined,
    select: undefined,
    workspaceId: -1,
  },
  {
    id: -1,
    title: 'Website Domain',
    value: 'website_domain',
    transform: (fallback, person) => person?.website?.domain || fallback,
    withFallback: true,
    isUrlVariable: false,

    name: '',
    description: 'Website domain',
    createdAt: -1,
    type: CUSTOM_VARIABLE_TYPE.URL,
    relatedTo: CustomVariableValueRelatedTo.WEBSITE,
    isDefault: true,
    openAi: undefined,
    select: undefined,
    workspaceId: -1,
  },
  {
    id: -1,
    title: 'Anchor text',
    value: 'anchor_text',
    transform: (fallback, person, opportunity) => opportunity?.optionalAnchorText || fallback,
    withFallback: true,
    isUrlVariable: false,

    name: '',
    description: 'Anchor text',
    createdAt: -1,
    type: CUSTOM_VARIABLE_TYPE.TEXT,
    relatedTo: CustomVariableValueRelatedTo.MISC,
    isDefault: true,
    openAi: undefined,
    select: undefined,
    workspaceId: -1,
  },
  {
    id: -1,
    title: 'Target url',
    value: 'target_url',
    transform: (fallback, person, opportunity) => opportunity?.optionalTargetUrl || fallback,
    withFallback: true,
    isUrlVariable: false,

    name: '',
    description: 'Target url',
    createdAt: -1,
    type: CUSTOM_VARIABLE_TYPE.URL,
    relatedTo: CustomVariableValueRelatedTo.MISC,
    isDefault: true,
    openAi: undefined,
    select: undefined,
    workspaceId: -1,
  },
];

export const getVariableByValue = (lookingValue, varsArray = variables) =>
  varsArray.find(({ value }) => value === lookingValue) || ({} as VariableType);

export const urlsVariables = variables.filter(({ isUrlVariable }) => isUrlVariable);

export const formatCustomToStaticVars = (
  customVariables: CustomVariablePlaceholderType[],
  organizationMembers: OrganizationMemberResponseType[]
): VariableType[] => {
  return customVariables.map((variable: CustomVariablePlaceholderType): VariableType => {
    return {
      id: variable.id,

      title: variable.title,
      withFallback: true,
      transform:
        variables.find((item) => item.value === variable.name)?.transform ||
        transformCustomVariableWithFallbackAndPersonById(variable.name, organizationMembers),
      isUrlVariable: variable.type === CUSTOM_VARIABLE_TYPE.URL,

      name: variable.name,
      description: variable.description,
      createdAt: variable.createdAt,
      type: variable.type,
      relatedTo: variable.relatedTo,
      openAi: variable.openAi,
      select: variable.select,
      value: variable.name,
      workspaceId: variable.workspaceId,
      isDefault: variable.isDefault,
    };
  });
};

const transformCustomVariableWithFallbackAndPersonById =
  (name: string, organizationMembers: OrganizationMemberResponseType[]) =>
  (
    fallback,
    person,
    opportunity,
    step,
    campaignSchedule,
    customVariablesValues: CustomVariableValueType[]
  ) => {
    const customVariableWeNeed = customVariablesValues.find(
      (variableValue) => variableValue.variableName === name
    );
    return getValueFromVariable(customVariableWeNeed, fallback, organizationMembers);
  };

const getValueFromVariable = (
  variable: CustomVariableValueType | null | undefined,
  fallback: string,
  organizationMembers: OrganizationMemberResponseType[]
): string => {
  if (!variable) {
    return fallback;
  }

  try {
    const { type, stringValue, selectValue } = variable;

    if (
      !stringValue &&
      type !== CustomVariableType.SELECT &&
      type !== CustomVariableType.MULTI_SELECT
    ) {
      return fallback;
    }

    switch (type) {
      case CustomVariableType.TEXT:
      case CustomVariableType.URL:
      case CustomVariableType.INT:
      case CustomVariableType.OPEN_AI:
        return stringValue;
      case CustomVariableType.TEAM_MEMBER:
        return (
          organizationMembers.find((member) => member.userId.toString() === stringValue)?.fullName ||
          fallback
        );

      case CustomVariableType.CHECKBOX: {
        return stringValue === 'true' ? 'Yes' : fallback?.length > 0 ? fallback : 'No';
      }

      case CustomVariableType.DATE_AND_TIME: {
        if (Number.isNaN(stringValue)) {
          return fallback;
        }

        return new Date(Number(stringValue)).toLocaleDateString();
      }

      case CustomVariableType.SELECT:
      case CustomVariableType.MULTI_SELECT:
        return selectValue?.valuesList?.length
          ? selectValue.valuesList.map((it) => it.value).join(', ')
          : fallback;

      case CustomVariableType.RATING:
        return stringValue ? '★'.repeat(parseInt(stringValue)) : fallback;

      default:
        return fallback;
    }
  } catch (e) {
    console.error(e);
    return fallback;
  }
};

export default variables;
