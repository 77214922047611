import React from 'react';

const PauseIcon = ({ color = 'white' }: { color: string }): JSX.Element => (
  <svg width="6" height="7" viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.75 0.636719H4.25C4.11193 0.636719 4 0.748648 4 0.886719V6.38672C4 6.52479 4.11193 6.63672 4.25 6.63672H5.75C5.88807 6.63672 6 6.52479 6 6.38672V0.886719C6 0.748648 5.88807 0.636719 5.75 0.636719Z"
      fill={color}
    />
    <path
      d="M1.75 0.636719H0.25C0.111929 0.636719 0 0.748648 0 0.886719V6.38672C0 6.52479 0.111929 6.63672 0.25 6.63672H1.75C1.88807 6.63672 2 6.52479 2 6.38672V0.886719C2 0.748648 1.88807 0.636719 1.75 0.636719Z"
      fill={color}
    />
  </svg>
);

export default PauseIcon;
