import { extractVariableNamesFromHtml } from '@utils/extractVariableNamesFromHtml';
import { OpportunityDetailsType } from '@ts/opportunity.types';

export function extractVariableNamesFromOpportunity(opportunity: OpportunityDetailsType): string[] {
  if (opportunity?.stepsList.length > 0) {
    const extractedNames = opportunity?.stepsList.map((it) => {
      return [
        ...extractVariableNamesFromHtml(it.threadA.content),
        ...extractVariableNamesFromHtml(it.threadA.subject),
      ];
    });

    // @ts-ignore
    const flattenedNames = extractedNames.flat();

    return Array.from(new Set(flattenedNames));
  }
  return [];
}
