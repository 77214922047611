import loadingStatuses from '@constants/loadingStatuses';
import { OpenCampaignType } from '@ts/campaigns.types';
import { contentSearchTypeMap } from '@mappers/contentSearch.mappers';
import { getLastSixMonthsRangeInMs } from '@helpers/dateTimeRangeHelpers';
import {
  CS_SET_FILTER_VALUE,
  CS_SET_ACTIVE_CAMPAIGN_LS,
  CS_TOGGLE_FILTERS,
  CS_SET_SOURCE_TYPE,
  CS_TOGGLE_IMPORT_FLOW,
  CS_SET_CAMPAIGN_OPPORTUNITIES,
  CS_ADD_CAMPAIGN_OPPORTUNITIES,
  CS_REMOVE_CAMPAIGN_OPPORTUNITIES,
  CS_SET_CAMPAIGN_OPPORTUNITY_PERSONALIZED,
  CS_SET_LS_CAMPAIGN_OPPORTUNITIES,
  CS_SET_CAMPAIGN_OPPORTUNITIES_CONTACTS,
  CS_SET_CAMPAIGN_OPPORTUNITIES_CONTACTS_LS,
  CS_SET_OPEN_CAMPAIGN_INFO,
  CS_SET_OPEN_CAMPAIGN_ERROR,
  csClearOpenCampaignInfo,
  CS_SET_VERIFY_STATUS,
  CS_UPDATE_CAMPAIGN_SELECTED_OPPORTUNITY_VERIFICATION_STATUS,
  CS_THROW_VERIFICATION_STATUS_ALL_CAMPAIGN_SELECTED_OPPORTUNITIES,
  CS_ADD_CAMPAIGN_OPPORTUNITIES_PREVIEW_ITEM,
  CS_REMOVE_CAMPAIGN_OPPORTUNITIES_PREVIEW_ITEM,
  CS_ADD_CAMPAIGN_OPPORTUNITIES_PREVIEW_ITEM_WITH_CONTACT,
  CS_REMOVE_CAMPAIGN_OPPORTUNITIES_PREVIEW_ITEM_WITH_CONTACT,
  CS_SET_VERIFY_PROGRESS,
} from '@redux/actions/campaignSettings.actions';
import { trendingContentLanguages } from '@constants/languages.contstants';
import { OpportunityDetailsType } from '@ts/opportunity.types';
import { normalizeArrayByField } from '@utils/normalize';
import omit from '@utils/omit';

// TODO: maybe better to storage full active campaign info instead of id
export type CampaignSettingsStateType = {
  searchContentFilters: { [key: string]: any };
  sourceType?: {
    value: keyof typeof contentSearchTypeMap;
    label: string;
  };
  viewState: {
    isFiltersOpen: boolean;
    isAdvancedSearchOpen: boolean;
    isImportFlowOpen: boolean;
  };
  activeCampaign: {
    info: OpenCampaignType | null;
    loadingStatus: string;
    error: Error;
  };
  selectedOpportunities: {
    loadingStatus: string;
    items: { [key: string]: OpportunityDetailsType };
  };
  personalizations: {
    [oppurtunityId: number]: {
      loadingStatus: string;
      data: any;
    };
  };
};

const defaultState: CampaignSettingsStateType = {
  searchContentFilters: {},
  sourceType: null,
  viewState: {
    isFiltersOpen: false,
    isAdvancedSearchOpen: false,
    isImportFlowOpen: false,
  },
  activeCampaign: {
    loadingStatus: loadingStatuses.PENDING,
    info: null,
    error: null,
  },
  selectedOpportunities: {
    loadingStatus: loadingStatuses.PENDING,
    items: {},
  },
  // @ts-ignore
  personalizations: {},
};

export default (
  state: CampaignSettingsStateType = defaultState,
  { type, payload }
): CampaignSettingsStateType => {
  switch (type) {
    case CS_SET_OPEN_CAMPAIGN_INFO:
      return {
        ...state,
        activeCampaign: {
          ...state.activeCampaign,
          info: {
            ...state.activeCampaign.info,
            ...payload,
          },
        },
      };
    case CS_SET_ACTIVE_CAMPAIGN_LS:
      return {
        ...state,
        activeCampaign: {
          ...state.activeCampaign,
          loadingStatus: payload,
        },
      };
    case CS_SET_OPEN_CAMPAIGN_ERROR:
      return {
        ...state,
        activeCampaign: {
          info: null,
          loadingStatus: loadingStatuses.ERROR,
          error: payload,
        },
      };
    case CS_SET_FILTER_VALUE:
      // eslint-disable-next-line no-case-declarations
      const newFilters = { ...state.searchContentFilters };
      // eslint-disable-next-line no-unused-expressions
      payload.filterValue === undefined
        ? delete newFilters[payload.filterKey]
        : (newFilters[payload.filterKey] = payload.filterValue);
      return {
        ...state,
        searchContentFilters: newFilters,
      };
    case CS_SET_SOURCE_TYPE:
      return {
        ...state,
        sourceType: payload,
        searchContentFilters:
          contentSearchTypeMap[payload.value] === 4
            ? {
                freshness: getLastSixMonthsRangeInMs(),
                language: trendingContentLanguages.find(({ value }) => value === 'en'),
              }
            : {},
      };
    case CS_TOGGLE_FILTERS:
      // eslint-disable-next-line no-case-declarations
      const changedFilters = state.viewState.isFiltersOpen
        ? {
            searchContentFilters: {},
          }
        : {};

      return {
        ...state,
        viewState: {
          ...state.viewState,
          isFiltersOpen: !state.viewState.isFiltersOpen,
        },
        ...changedFilters,
      };
    case CS_TOGGLE_IMPORT_FLOW:
      return {
        ...state,
        viewState: {
          ...state.viewState,
          isImportFlowOpen: payload,
        },
      };

    case CS_SET_CAMPAIGN_OPPORTUNITIES:
      return {
        ...state,
        selectedOpportunities: {
          ...state.selectedOpportunities,
          items: normalizeArrayByField(
            payload.map((item) => ({
              ...item,
              contacts: [],
              contactsLoadingStatus: loadingStatuses.PENDING,
            }))
          ),
        },
      };
    case CS_UPDATE_CAMPAIGN_SELECTED_OPPORTUNITY_VERIFICATION_STATUS:
      return {
        ...state,
        selectedOpportunities: {
          ...state.selectedOpportunities,
          items: {
            ...state.selectedOpportunities.items,
            [payload.opportunityId]: {
              ...state.selectedOpportunities.items[payload.opportunityId],
              status: payload.newStatus,
            },
          },
        },
        activeCampaign: {
          ...state.activeCampaign,
          info: {
            ...state.activeCampaign.info,
            verificationStatus: 0,
          },
        },
      };
    case CS_THROW_VERIFICATION_STATUS_ALL_CAMPAIGN_SELECTED_OPPORTUNITIES:
      return {
        ...state,
        selectedOpportunities: {
          ...state.selectedOpportunities,
          items: Object.entries(state.selectedOpportunities.items).reduce(
            (acc, [id, opportunity]) => {
              return {
                ...acc,
                [id]: {
                  ...opportunity,
                  status: 0,
                },
              };
            },
            {}
          ),
        },
        activeCampaign: {
          ...state.activeCampaign,
          info: {
            ...state.activeCampaign.info,
            verificationStatus: 0,
          },
        },
      };
    case CS_SET_CAMPAIGN_OPPORTUNITIES_CONTACTS:
      return {
        ...state,
        selectedOpportunities: {
          ...state.selectedOpportunities,
          items: {
            ...state.selectedOpportunities.items,
            [payload.opportunityId]: {
              ...state.selectedOpportunities.items[payload.opportunityId],
              contacts: payload.contacts,
              contactsNumber: payload.contacts.length,
            },
          },
        },
      };
    case CS_SET_CAMPAIGN_OPPORTUNITIES_CONTACTS_LS:
      return {
        ...state,
        selectedOpportunities: {
          ...state.selectedOpportunities,
          items: {
            ...state.selectedOpportunities.items,
            [payload.opportunityId]: {
              ...state.selectedOpportunities.items[payload.opportunityId],
              contactsLoadingStatus: payload.loadingStatus,
            },
          },
        },
      };
    case CS_ADD_CAMPAIGN_OPPORTUNITIES:
      return {
        ...state,
        selectedOpportunities: {
          ...state.selectedOpportunities,
          items: {
            ...state.selectedOpportunities.items,
            ...normalizeArrayByField(
              payload.map((opportunity) => ({
                ...opportunity,
                contacts: [],
                contactsNumber: 0,
                contactsLoadingStatus: loadingStatuses.PENDING,
              }))
            ),
          },
        },
      };
    case CS_REMOVE_CAMPAIGN_OPPORTUNITIES:
      return {
        ...state,
        selectedOpportunities: {
          ...state.selectedOpportunities,
          items: omit(state.selectedOpportunities.items, payload.map(String)),
        },
      };
    case CS_SET_CAMPAIGN_OPPORTUNITY_PERSONALIZED:
      return {
        ...state,
        selectedOpportunities: {
          ...state.selectedOpportunities,
          items: {
            ...state.selectedOpportunities.items,
            [payload.opportunityId]: {
              ...state.selectedOpportunities.items[payload.opportunityId],
              hasPersonalized: payload.hasPersonalized,
            },
          },
        },
      };
    case CS_SET_LS_CAMPAIGN_OPPORTUNITIES:
      return {
        ...state,
        selectedOpportunities: {
          ...state.selectedOpportunities,
          loadingStatus: payload,
        },
      };
    case CS_SET_VERIFY_STATUS:
      return {
        ...state,
        activeCampaign: {
          ...state.activeCampaign,
          info: {
            ...state.activeCampaign.info,
            verificationStatus: payload.statusNumber,
          },
        },
      };
    case CS_SET_VERIFY_PROGRESS:
      return {
        ...state,
        activeCampaign: {
          ...state.activeCampaign,
          info: {
            ...state.activeCampaign.info,
            verificationProgress: payload,
          },
        },
      };
    case csClearOpenCampaignInfo.toString():
      return defaultState;
    default:
      return state;
  }
};
