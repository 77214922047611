import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb';

import { VersionMetadata } from 'respona_api/generated/api-gateway_pb';

import request from '@api/base/request';
import apiGatewayService from '@api/services/apiGatewayService';

export const getCurrentVersionApi = (): Promise<VersionMetadata.AsObject> => {
  const service = apiGatewayService.getInstance();
  const emptyRequest = new google_protobuf_empty_pb.Empty();

  return request(service, service.getCurrentVersion, emptyRequest);
};
