// source: mailbox-account-health.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
goog.exportSymbol('proto.mailbox.BlackListRecord', null, global);
goog.exportSymbol('proto.mailbox.DnsRecord', null, global);
goog.exportSymbol('proto.mailbox.MailboxHealthResponse', null, global);
goog.exportSymbol('proto.mailbox.SpamAssassin', null, global);
goog.exportSymbol('proto.mailbox.SpamAssassinRecord', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mailbox.MailboxHealthResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mailbox.MailboxHealthResponse.repeatedFields_, null);
};
goog.inherits(proto.mailbox.MailboxHealthResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mailbox.MailboxHealthResponse.displayName = 'proto.mailbox.MailboxHealthResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mailbox.SpamAssassin = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mailbox.SpamAssassin.repeatedFields_, null);
};
goog.inherits(proto.mailbox.SpamAssassin, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mailbox.SpamAssassin.displayName = 'proto.mailbox.SpamAssassin';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mailbox.SpamAssassinRecord = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mailbox.SpamAssassinRecord, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mailbox.SpamAssassinRecord.displayName = 'proto.mailbox.SpamAssassinRecord';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mailbox.DnsRecord = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mailbox.DnsRecord, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mailbox.DnsRecord.displayName = 'proto.mailbox.DnsRecord';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mailbox.BlackListRecord = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mailbox.BlackListRecord, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mailbox.BlackListRecord.displayName = 'proto.mailbox.BlackListRecord';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mailbox.MailboxHealthResponse.repeatedFields_ = [13,14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mailbox.MailboxHealthResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mailbox.MailboxHealthResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mailbox.MailboxHealthResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mailbox.MailboxHealthResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    healthScore: jspb.Message.getFieldWithDefault(msg, 2, 0),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    spf: jspb.Message.getFieldWithDefault(msg, 4, ""),
    spfValid: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    ptr: jspb.Message.getFieldWithDefault(msg, 6, ""),
    ptrValid: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    dmarc: jspb.Message.getFieldWithDefault(msg, 8, ""),
    dmarcValid: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    dkimSignature: jspb.Message.getFieldWithDefault(msg, 10, ""),
    dkimValid: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    spamAssassin: (f = msg.getSpamAssassin()) && proto.mailbox.SpamAssassin.toObject(includeInstance, f),
    dnsRecordsList: jspb.Message.toObjectList(msg.getDnsRecordsList(),
    proto.mailbox.DnsRecord.toObject, includeInstance),
    blackListList: jspb.Message.toObjectList(msg.getBlackListList(),
    proto.mailbox.BlackListRecord.toObject, includeInstance),
    blackListScore: jspb.Message.getFieldWithDefault(msg, 15, 0),
    dnsScore: jspb.Message.getFieldWithDefault(msg, 16, 0),
    spamAssassinScore: jspb.Message.getFieldWithDefault(msg, 17, 0),
    lastCheckAt: jspb.Message.getFieldWithDefault(msg, 18, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mailbox.MailboxHealthResponse}
 */
proto.mailbox.MailboxHealthResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mailbox.MailboxHealthResponse;
  return proto.mailbox.MailboxHealthResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mailbox.MailboxHealthResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mailbox.MailboxHealthResponse}
 */
proto.mailbox.MailboxHealthResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHealthScore(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpf(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSpfValid(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPtr(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPtrValid(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDmarc(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDmarcValid(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDkimSignature(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDkimValid(value);
      break;
    case 12:
      var value = new proto.mailbox.SpamAssassin;
      reader.readMessage(value,proto.mailbox.SpamAssassin.deserializeBinaryFromReader);
      msg.setSpamAssassin(value);
      break;
    case 13:
      var value = new proto.mailbox.DnsRecord;
      reader.readMessage(value,proto.mailbox.DnsRecord.deserializeBinaryFromReader);
      msg.addDnsRecords(value);
      break;
    case 14:
      var value = new proto.mailbox.BlackListRecord;
      reader.readMessage(value,proto.mailbox.BlackListRecord.deserializeBinaryFromReader);
      msg.addBlackList(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBlackListScore(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDnsScore(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSpamAssassinScore(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastCheckAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mailbox.MailboxHealthResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mailbox.MailboxHealthResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mailbox.MailboxHealthResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mailbox.MailboxHealthResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getHealthScore();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSpf();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSpfValid();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getPtr();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPtrValid();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getDmarc();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDmarcValid();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getDkimSignature();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDkimValid();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getSpamAssassin();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.mailbox.SpamAssassin.serializeBinaryToWriter
    );
  }
  f = message.getDnsRecordsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.mailbox.DnsRecord.serializeBinaryToWriter
    );
  }
  f = message.getBlackListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.mailbox.BlackListRecord.serializeBinaryToWriter
    );
  }
  f = message.getBlackListScore();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getDnsScore();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getSpamAssassinScore();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getLastCheckAt();
  if (f !== 0) {
    writer.writeInt64(
      18,
      f
    );
  }
};


/**
 * optional int64 account_id = 1;
 * @return {number}
 */
proto.mailbox.MailboxHealthResponse.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mailbox.MailboxHealthResponse} returns this
 */
proto.mailbox.MailboxHealthResponse.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 health_score = 2;
 * @return {number}
 */
proto.mailbox.MailboxHealthResponse.prototype.getHealthScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mailbox.MailboxHealthResponse} returns this
 */
proto.mailbox.MailboxHealthResponse.prototype.setHealthScore = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.mailbox.MailboxHealthResponse.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox.MailboxHealthResponse} returns this
 */
proto.mailbox.MailboxHealthResponse.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string spf = 4;
 * @return {string}
 */
proto.mailbox.MailboxHealthResponse.prototype.getSpf = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox.MailboxHealthResponse} returns this
 */
proto.mailbox.MailboxHealthResponse.prototype.setSpf = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool spf_valid = 5;
 * @return {boolean}
 */
proto.mailbox.MailboxHealthResponse.prototype.getSpfValid = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mailbox.MailboxHealthResponse} returns this
 */
proto.mailbox.MailboxHealthResponse.prototype.setSpfValid = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string ptr = 6;
 * @return {string}
 */
proto.mailbox.MailboxHealthResponse.prototype.getPtr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox.MailboxHealthResponse} returns this
 */
proto.mailbox.MailboxHealthResponse.prototype.setPtr = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool ptr_valid = 7;
 * @return {boolean}
 */
proto.mailbox.MailboxHealthResponse.prototype.getPtrValid = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mailbox.MailboxHealthResponse} returns this
 */
proto.mailbox.MailboxHealthResponse.prototype.setPtrValid = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string dmarc = 8;
 * @return {string}
 */
proto.mailbox.MailboxHealthResponse.prototype.getDmarc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox.MailboxHealthResponse} returns this
 */
proto.mailbox.MailboxHealthResponse.prototype.setDmarc = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bool dmarc_valid = 9;
 * @return {boolean}
 */
proto.mailbox.MailboxHealthResponse.prototype.getDmarcValid = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mailbox.MailboxHealthResponse} returns this
 */
proto.mailbox.MailboxHealthResponse.prototype.setDmarcValid = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional string dkim_signature = 10;
 * @return {string}
 */
proto.mailbox.MailboxHealthResponse.prototype.getDkimSignature = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox.MailboxHealthResponse} returns this
 */
proto.mailbox.MailboxHealthResponse.prototype.setDkimSignature = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool dkim_valid = 11;
 * @return {boolean}
 */
proto.mailbox.MailboxHealthResponse.prototype.getDkimValid = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mailbox.MailboxHealthResponse} returns this
 */
proto.mailbox.MailboxHealthResponse.prototype.setDkimValid = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional SpamAssassin spam_assassin = 12;
 * @return {?proto.mailbox.SpamAssassin}
 */
proto.mailbox.MailboxHealthResponse.prototype.getSpamAssassin = function() {
  return /** @type{?proto.mailbox.SpamAssassin} */ (
    jspb.Message.getWrapperField(this, proto.mailbox.SpamAssassin, 12));
};


/**
 * @param {?proto.mailbox.SpamAssassin|undefined} value
 * @return {!proto.mailbox.MailboxHealthResponse} returns this
*/
proto.mailbox.MailboxHealthResponse.prototype.setSpamAssassin = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mailbox.MailboxHealthResponse} returns this
 */
proto.mailbox.MailboxHealthResponse.prototype.clearSpamAssassin = function() {
  return this.setSpamAssassin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mailbox.MailboxHealthResponse.prototype.hasSpamAssassin = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * repeated DnsRecord dns_records = 13;
 * @return {!Array<!proto.mailbox.DnsRecord>}
 */
proto.mailbox.MailboxHealthResponse.prototype.getDnsRecordsList = function() {
  return /** @type{!Array<!proto.mailbox.DnsRecord>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mailbox.DnsRecord, 13));
};


/**
 * @param {!Array<!proto.mailbox.DnsRecord>} value
 * @return {!proto.mailbox.MailboxHealthResponse} returns this
*/
proto.mailbox.MailboxHealthResponse.prototype.setDnsRecordsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.mailbox.DnsRecord=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mailbox.DnsRecord}
 */
proto.mailbox.MailboxHealthResponse.prototype.addDnsRecords = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.mailbox.DnsRecord, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mailbox.MailboxHealthResponse} returns this
 */
proto.mailbox.MailboxHealthResponse.prototype.clearDnsRecordsList = function() {
  return this.setDnsRecordsList([]);
};


/**
 * repeated BlackListRecord black_list = 14;
 * @return {!Array<!proto.mailbox.BlackListRecord>}
 */
proto.mailbox.MailboxHealthResponse.prototype.getBlackListList = function() {
  return /** @type{!Array<!proto.mailbox.BlackListRecord>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mailbox.BlackListRecord, 14));
};


/**
 * @param {!Array<!proto.mailbox.BlackListRecord>} value
 * @return {!proto.mailbox.MailboxHealthResponse} returns this
*/
proto.mailbox.MailboxHealthResponse.prototype.setBlackListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.mailbox.BlackListRecord=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mailbox.BlackListRecord}
 */
proto.mailbox.MailboxHealthResponse.prototype.addBlackList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.mailbox.BlackListRecord, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mailbox.MailboxHealthResponse} returns this
 */
proto.mailbox.MailboxHealthResponse.prototype.clearBlackListList = function() {
  return this.setBlackListList([]);
};


/**
 * optional int32 black_list_score = 15;
 * @return {number}
 */
proto.mailbox.MailboxHealthResponse.prototype.getBlackListScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.mailbox.MailboxHealthResponse} returns this
 */
proto.mailbox.MailboxHealthResponse.prototype.setBlackListScore = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int32 dns_score = 16;
 * @return {number}
 */
proto.mailbox.MailboxHealthResponse.prototype.getDnsScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.mailbox.MailboxHealthResponse} returns this
 */
proto.mailbox.MailboxHealthResponse.prototype.setDnsScore = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional int32 spam_assassin_score = 17;
 * @return {number}
 */
proto.mailbox.MailboxHealthResponse.prototype.getSpamAssassinScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.mailbox.MailboxHealthResponse} returns this
 */
proto.mailbox.MailboxHealthResponse.prototype.setSpamAssassinScore = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional int64 last_check_at = 18;
 * @return {number}
 */
proto.mailbox.MailboxHealthResponse.prototype.getLastCheckAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.mailbox.MailboxHealthResponse} returns this
 */
proto.mailbox.MailboxHealthResponse.prototype.setLastCheckAt = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mailbox.SpamAssassin.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mailbox.SpamAssassin.prototype.toObject = function(opt_includeInstance) {
  return proto.mailbox.SpamAssassin.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mailbox.SpamAssassin} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mailbox.SpamAssassin.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSpam: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    spamScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    spamThreshold: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    recordsList: jspb.Message.toObjectList(msg.getRecordsList(),
    proto.mailbox.SpamAssassinRecord.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mailbox.SpamAssassin}
 */
proto.mailbox.SpamAssassin.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mailbox.SpamAssassin;
  return proto.mailbox.SpamAssassin.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mailbox.SpamAssassin} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mailbox.SpamAssassin}
 */
proto.mailbox.SpamAssassin.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSpam(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSpamScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSpamThreshold(value);
      break;
    case 4:
      var value = new proto.mailbox.SpamAssassinRecord;
      reader.readMessage(value,proto.mailbox.SpamAssassinRecord.deserializeBinaryFromReader);
      msg.addRecords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mailbox.SpamAssassin.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mailbox.SpamAssassin.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mailbox.SpamAssassin} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mailbox.SpamAssassin.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSpam();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getSpamScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getSpamThreshold();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getRecordsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.mailbox.SpamAssassinRecord.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_spam = 1;
 * @return {boolean}
 */
proto.mailbox.SpamAssassin.prototype.getIsSpam = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mailbox.SpamAssassin} returns this
 */
proto.mailbox.SpamAssassin.prototype.setIsSpam = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional double spam_score = 2;
 * @return {number}
 */
proto.mailbox.SpamAssassin.prototype.getSpamScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.mailbox.SpamAssassin} returns this
 */
proto.mailbox.SpamAssassin.prototype.setSpamScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double spam_threshold = 3;
 * @return {number}
 */
proto.mailbox.SpamAssassin.prototype.getSpamThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.mailbox.SpamAssassin} returns this
 */
proto.mailbox.SpamAssassin.prototype.setSpamThreshold = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * repeated SpamAssassinRecord records = 4;
 * @return {!Array<!proto.mailbox.SpamAssassinRecord>}
 */
proto.mailbox.SpamAssassin.prototype.getRecordsList = function() {
  return /** @type{!Array<!proto.mailbox.SpamAssassinRecord>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mailbox.SpamAssassinRecord, 4));
};


/**
 * @param {!Array<!proto.mailbox.SpamAssassinRecord>} value
 * @return {!proto.mailbox.SpamAssassin} returns this
*/
proto.mailbox.SpamAssassin.prototype.setRecordsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.mailbox.SpamAssassinRecord=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mailbox.SpamAssassinRecord}
 */
proto.mailbox.SpamAssassin.prototype.addRecords = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.mailbox.SpamAssassinRecord, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mailbox.SpamAssassin} returns this
 */
proto.mailbox.SpamAssassin.prototype.clearRecordsList = function() {
  return this.setRecordsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mailbox.SpamAssassinRecord.prototype.toObject = function(opt_includeInstance) {
  return proto.mailbox.SpamAssassinRecord.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mailbox.SpamAssassinRecord} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mailbox.SpamAssassinRecord.toObject = function(includeInstance, msg) {
  var f, obj = {
    pts: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    ruleName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mailbox.SpamAssassinRecord}
 */
proto.mailbox.SpamAssassinRecord.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mailbox.SpamAssassinRecord;
  return proto.mailbox.SpamAssassinRecord.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mailbox.SpamAssassinRecord} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mailbox.SpamAssassinRecord}
 */
proto.mailbox.SpamAssassinRecord.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPts(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRuleName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mailbox.SpamAssassinRecord.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mailbox.SpamAssassinRecord.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mailbox.SpamAssassinRecord} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mailbox.SpamAssassinRecord.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPts();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getRuleName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional double pts = 1;
 * @return {number}
 */
proto.mailbox.SpamAssassinRecord.prototype.getPts = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.mailbox.SpamAssassinRecord} returns this
 */
proto.mailbox.SpamAssassinRecord.prototype.setPts = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional string rule_name = 2;
 * @return {string}
 */
proto.mailbox.SpamAssassinRecord.prototype.getRuleName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox.SpamAssassinRecord} returns this
 */
proto.mailbox.SpamAssassinRecord.prototype.setRuleName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.mailbox.SpamAssassinRecord.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox.SpamAssassinRecord} returns this
 */
proto.mailbox.SpamAssassinRecord.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mailbox.DnsRecord.prototype.toObject = function(opt_includeInstance) {
  return proto.mailbox.DnsRecord.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mailbox.DnsRecord} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mailbox.DnsRecord.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mailbox.DnsRecord}
 */
proto.mailbox.DnsRecord.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mailbox.DnsRecord;
  return proto.mailbox.DnsRecord.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mailbox.DnsRecord} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mailbox.DnsRecord}
 */
proto.mailbox.DnsRecord.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mailbox.DnsRecord.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mailbox.DnsRecord.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mailbox.DnsRecord} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mailbox.DnsRecord.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.mailbox.DnsRecord.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox.DnsRecord} returns this
 */
proto.mailbox.DnsRecord.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.mailbox.DnsRecord.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox.DnsRecord} returns this
 */
proto.mailbox.DnsRecord.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mailbox.BlackListRecord.prototype.toObject = function(opt_includeInstance) {
  return proto.mailbox.BlackListRecord.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mailbox.BlackListRecord} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mailbox.BlackListRecord.toObject = function(includeInstance, msg) {
  var f, obj = {
    ip: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dnsType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    source: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isBlocked: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mailbox.BlackListRecord}
 */
proto.mailbox.BlackListRecord.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mailbox.BlackListRecord;
  return proto.mailbox.BlackListRecord.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mailbox.BlackListRecord} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mailbox.BlackListRecord}
 */
proto.mailbox.BlackListRecord.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIp(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDnsType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBlocked(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mailbox.BlackListRecord.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mailbox.BlackListRecord.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mailbox.BlackListRecord} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mailbox.BlackListRecord.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIp();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDnsType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSource();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsBlocked();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string ip = 1;
 * @return {string}
 */
proto.mailbox.BlackListRecord.prototype.getIp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox.BlackListRecord} returns this
 */
proto.mailbox.BlackListRecord.prototype.setIp = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string dns_type = 2;
 * @return {string}
 */
proto.mailbox.BlackListRecord.prototype.getDnsType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox.BlackListRecord} returns this
 */
proto.mailbox.BlackListRecord.prototype.setDnsType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string source = 3;
 * @return {string}
 */
proto.mailbox.BlackListRecord.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox.BlackListRecord} returns this
 */
proto.mailbox.BlackListRecord.prototype.setSource = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool is_blocked = 4;
 * @return {boolean}
 */
proto.mailbox.BlackListRecord.prototype.getIsBlocked = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mailbox.BlackListRecord} returns this
 */
proto.mailbox.BlackListRecord.prototype.setIsBlocked = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


goog.object.extend(exports, proto.mailbox);
