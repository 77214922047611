import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import qs from 'query-string';

import Loader from '@uikit/Loader/Loader';

import RelationshipsSidebar from '@components/Relationships/RelationshipsSidebar/RelationshipsSidebar';
import RelationshipsPeople from '@components/Relationships/RelationshipsPeople/RelationshipsPeople';
import RelationshipsOrganizations from '@components/Relationships/RelationshipsOrganizations/RelationshipsOrganizations';

import loadingStatuses from '@constants/loadingStatuses';

import { peopleFoldersSelector } from '@redux/selectors/people.selectors';
import { companiesFoldersSelector } from '@redux/selectors/companies.selectors';
import { fetchAllFolders } from '@redux/thunks/people.thunks';
import { getAllCompanyFolders } from '@redux/thunks/peopleCompany.thunks';
import RelationshipsPipelines from '@components/Relationships/RelationshipsPipelines/RelationshipsPipelines';
import PipelineDeal from '@components/Relationships/PipelineDeal/PipelineDeal';

import './RelationshipsPage.scss';

const RelationshipsPage = ({
  match: {
    params: { relationshipsPage },
  },
  location: { search },
}): JSX.Element => {
  const dispatch = useDispatch();
  const searchParams: { folderId?: string | undefined, id?: string } = qs.parse(search);
  const { loadingStatus: foldersLs } = useSelector(peopleFoldersSelector);
  const { loadingStatus: companiesFoldersLs } = useSelector(companiesFoldersSelector);

  useEffect(() => {
    if (foldersLs === loadingStatuses.PENDING) {
      fetchAllFolders(dispatch);
    }

    if (companiesFoldersLs === loadingStatuses.PENDING) {
      getAllCompanyFolders(dispatch);
    }
  }, []);

  const isLoading = foldersLs !== loadingStatuses.LOADED;

  return (
    <div className="relationships-page">
      <Loader isLoading={isLoading} withTopMargin>
        <RelationshipsSidebar searchParams={searchParams} activePage={relationshipsPage} />
        <div className="relationships-page__content">
          <Switch>
            <Route path="/relationships/websites">
              <RelationshipsOrganizations searchParams={searchParams} />
            </Route>
            <Route path="/relationships/people">
              <RelationshipsPeople searchParams={searchParams} />
            </Route>
            <Route path="/relationships/deals/:dealId">
              <PipelineDeal />
            </Route>
            <Route path="/relationships/pipelines">
              <RelationshipsPipelines />
            </Route>
            <Route path="/relationships*">
              <Redirect to="/relationships/websites" />
            </Route>
          </Switch>
        </div>
      </Loader>
    </div>
  );
};

export default RelationshipsPage;
