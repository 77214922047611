import React, { useState } from 'react';

import useInboxAccountsPreset from '@hooks/useInboxAccountsPreset';

import { Button } from '@uikit/Button/Button';

import InboxContent from '@components/Inbox/InboxContent/InboxContent';
import InboxSidebar from '@components/Inbox/InboxSidebar/InboxSidebar';
import InboxWidget from '@components/Inbox/InboxWidget/InboxWidget';

import './InboxPage.scss';
import { useQuery } from 'react-query';
import { getThreadUnreadCountersApi } from '@api/mailbox.api';
import { useSelector } from 'react-redux';
import { inboxSelectedMailboxesIdsSelector } from '@redux/selectors/inbox.selectors';
import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';
import { QueuePreviewResponseType, ThreadPreviewResponseType } from '@ts/mailboxInbox.types';
// @ts-ignore
import emptyMailboxesIllustration from './assets/emptyMailboxesIllustration.svg';

function InboxPage(): JSX.Element {
  const currentWorkspaceId = useCurrentWorkspaceId();
  const inboxesSelected: number[] = useSelector(inboxSelectedMailboxesIdsSelector);
  const [inboxWidgetInfo, changeInboxWidgetInfo] = useState(null);

  const [allMailboxes, isFetchingMailboxes] = useInboxAccountsPreset();
  const [activeThread, setActiveThread] = useState<
    (ThreadPreviewResponseType & QueuePreviewResponseType) | null
  >(null);

  const handleCloseNewMessage = () => {
    // ignore and close
    changeInboxWidgetInfo(null);
  };

  const handleSetActiveThread = (newThread) => {
    setActiveThread(newThread);
  };

  const handleOpenNewMessage = () =>
    changeInboxWidgetInfo({
      type: 0,
    });

  const collectSelectedInboxes = inboxesSelected?.length
    ? inboxesSelected
    : allMailboxes.map((account) => account.id);

  const unreadCounterKey = ['unread-counter', currentWorkspaceId, ...collectSelectedInboxes.sort()];

  const { data: unreadCounter, refetch: refetchUnreadCounter } = useQuery(
    unreadCounterKey,
    () => getThreadUnreadCountersApi(currentWorkspaceId, collectSelectedInboxes),
    {
      enabled: !!currentWorkspaceId && !!collectSelectedInboxes && !!collectSelectedInboxes.length,
      retry: 0,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <div className="inbox-page">
      <InboxSidebar
        setActiveThread={handleSetActiveThread}
        allMailboxes={allMailboxes}
        isFetchingMailboxes={isFetchingMailboxes}
        unreadCounter={unreadCounter}
      />

      {isFetchingMailboxes ? null : (
        <div className="inbox-page__content">
          {!isFetchingMailboxes && !allMailboxes?.length ? (
            <div className="inbox-page__empty-illustration-container">
              <img alt="No mailboxes" src={emptyMailboxesIllustration} />
              <div className="inbox-page__empty-illustration-text">
                Connect your emails to manage inbox
              </div>

              <Button
                href="/settings/all-emails?isEmailCreationOpenInUrl=true"
                onClick={() => null}
                size="l"
                type="primary"
              >
                Connect Email
              </Button>
            </div>
          ) : (
            <InboxContent
              activeThread={activeThread}
              setActiveThread={handleSetActiveThread}
              refetchUnreadCounter={refetchUnreadCounter}
              allMailboxes={allMailboxes}
              isFetchingMailboxes={isFetchingMailboxes}
              onNewMessage={handleOpenNewMessage}
            />
          )}
        </div>
      )}

      <InboxWidget
        inboxWidgetInfo={inboxWidgetInfo}
        onClose={handleCloseNewMessage}
        onDraftDelete={() => {
          /** ignore */
        }}
        onDraftCreation={() => {
          /** ignore */
        }}
      />
    </div>
  );
}

export default InboxPage;
