import React from 'react';

export default ({ color = '#8F9199' }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.5 5.75C9.30109 5.75 9.11032 5.82902 8.96967 5.96967C8.82902 6.11032 8.75 6.30109 8.75 6.5V9.5C8.75 9.69891 8.82902 9.88968 8.96967 10.0303C9.11032 10.171 9.30109 10.25 9.5 10.25C9.69891 10.25 9.88968 10.171 10.0303 10.0303C10.171 9.88968 10.25 9.69891 10.25 9.5V6.5C10.25 6.30109 10.171 6.11032 10.0303 5.96967C9.88968 5.82902 9.69891 5.75 9.5 5.75ZM9.5 11.75C9.35166 11.75 9.20666 11.794 9.08332 11.8764C8.95999 11.9588 8.86386 12.0759 8.80709 12.213C8.75032 12.35 8.73547 12.5008 8.76441 12.6463C8.79335 12.7918 8.86478 12.9254 8.96967 13.0303C9.07456 13.1352 9.2082 13.2066 9.35368 13.2356C9.49917 13.2645 9.64997 13.2497 9.78701 13.1929C9.92406 13.1361 10.0412 13.04 10.1236 12.9167C10.206 12.7933 10.25 12.6483 10.25 12.5C10.25 12.3011 10.171 12.1103 10.0303 11.9697C9.88968 11.829 9.69891 11.75 9.5 11.75ZM16.7825 6.17L12.83 2.2175C12.6863 2.08045 12.4961 2.00276 12.2975 2H6.7025C6.50393 2.00276 6.31373 2.08045 6.17 2.2175L2.2175 6.17C2.08045 6.31373 2.00276 6.50393 2 6.7025V12.2975C2.00276 12.4961 2.08045 12.6863 2.2175 12.83L6.17 16.7825C6.31373 16.9195 6.50393 16.9972 6.7025 17H12.2975C12.4961 16.9972 12.6863 16.9195 12.83 16.7825L16.7825 12.83C16.9195 12.6863 16.9972 12.4961 17 12.2975V6.7025C16.9972 6.50393 16.9195 6.31373 16.7825 6.17ZM15.5 11.9825L11.9825 15.5H7.0175L3.5 11.9825V7.0175L7.0175 3.5H11.9825L15.5 7.0175V11.9825Z"
      fill={color}
    />
  </svg>
);
