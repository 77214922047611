import React from 'react';

// @ts-ignore
import emptySearchIllustration from '@uikit/Illustrations/emptySearctIllustration.svg';
// @ts-ignore
import emptySearchArrowIllustration from '@uikit/Illustrations/emptySearchArrowIllustration.svg';

const ClickToShowMore = ({ title }: { title?: string}) => {
  return (
    <div className="manual-confirm-contact-sidebar__empty-search">
      <img
        src={emptySearchIllustration}
        className="manual-confirm-contact-sidebar__empty-search-illustration"
        alt="empty-search-illustration"
      />
      <img
        src={emptySearchArrowIllustration}
        className="manual-confirm-contact-sidebar__empty-search-arrow-illustration"
        alt="empty-search-arrow-illustration"
      />
      {title || 'Click search to find contacts'}
    </div>
  );
};

export default ClickToShowMore;
