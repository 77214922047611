import React from 'react';

import { SVGIcon } from '@uikit/Icon/Icon';

const AutomationCardsInfo = ({
  firstTitle,
  firstValue,
  secondTitle,
  secondValue,
  thirdTitle,
  thirdValue,
}: {
  firstTitle: string;
  firstValue: string;
  secondTitle: string;
  secondValue: string;
  thirdTitle: string;
  thirdValue: string;
}): JSX.Element => {
  return (
    <div className="campaign-automation-progress-info">
      <div className="campaign-automation-progress-info__card">
        <div className="campaign-automation-progress-info__card-title">
          <SVGIcon icon="search" size={13} color="#C4C6CD" />
          {firstTitle}
        </div>
        <div className="campaign-automation-progress-info__card-value--color-green">
          {firstValue}
        </div>
      </div>
      <div className="campaign-automation-progress-info__card">
        <div className="campaign-automation-progress-info__card-title">
          <SVGIcon icon="wallet" size={14} color="#C4C6CD" />
          {secondTitle}
        </div>
        <div className="campaign-automation-progress-info__card-value--color-blue">
          ~ {secondValue}
        </div>
      </div>
      <div className="campaign-automation-progress-info__card">
        <div className="campaign-automation-progress-info__card-title">
          <SVGIcon icon="time" size={15} color="#C4C6CD" />
          {thirdTitle}
        </div>
        <div className="campaign-automation-progress-info__card-value--color-red">
          ~ {thirdValue}
        </div>
      </div>
    </div>
  );
};

export default AutomationCardsInfo;
