import React from 'react';
import { useDispatch } from 'react-redux';

import { createPersonApi } from '@api/people.api';

import { addNotification } from '@redux/actions/notifications.actions';

import PersonSidebar from '@components/PersonSidebar/PersonSidebar';

import './RelationshipsCreatePersonSidebar.scss';
import { WebsiteType } from '@ts/company.types';
import { PersonBaseType } from '@ts/people.types';
import RightInfoSidebar from '@uikit/RightInfoSidebar/RightInfoSidebar';

const SidebarTitle = () => (
  <div>Add contact</div>
);

function RelationshipsCreatePersonSidebar({
  website,
  onUpdateContactInWebsite,
  onClose,
}: {
  website: WebsiteType;
  onUpdateContactInWebsite: (value: PersonBaseType) => void;
  onClose: () => void;
}): JSX.Element {
  const dispatch = useDispatch();

  const handleCreatePerson = async (newPerson: any) => {
    const createdPerson = await createPersonApi(newPerson);
    onUpdateContactInWebsite(createdPerson);
    dispatch(addNotification({ type: 'success', title: 'Contact created' }));
  };

  return (
    <RightInfoSidebar title={<SidebarTitle />} isOpen onClose={onClose}>
      <PersonSidebar
        embedded
        isOpen
        onCreate={handleCreatePerson}
        title="Add contact"
        enableMultiEmail={false}
        isEdit
        editInfo={{
          name: '',
          emailsList: [
            {
              email: '',
            },
          ],
          websiteDomain: website.domain,
          websiteName: website.title,
        }}
      />
    </RightInfoSidebar>
  );
}

export default RelationshipsCreatePersonSidebar;
