import React, { useEffect, useRef, useState } from 'react';

import ReactTooltip from 'react-tooltip';

import Button from '@uikit/Button/Button';
import { SVGIcon } from '@uikit/Icon/Icon';
import Select from '@uikit/Select/Select';

import { FolderType, TagType } from '@ts/common.types';
import { transformDataWithIdAndTittleToOptions } from 'src/helpers/transformDataHelpers';

import Display from '@components/Display/Display';
import { getCampaignShortcutsApi } from '@api/campaign.api';
import { useSelector } from 'react-redux';
import { getCurrentWorkspaceId } from '@redux/selectors/workspaces.selectors';

import SelectOption from './_components/SelectOption/SelectOption';
import { createSelectMenu } from './_components/SelectMenu/SelectMenu';

import './MoreActionsButton.scss';

export type OnAddToCampaignFunctionType = (campaignName: string, campaignId: number) => void;
export type CampaignToAddPeopleType = { campaignName: string; campaignId: number };
type MoreActionsButtonPropsType = {
  onCreateNewFolder?: () => void;
  onMoveToFolder?: ({ value, label }: { value: number, label: string }) => void;
  onAddTag?: (title?: any, id?: any) => void;
  onAddToBlackList: () => void;
  onAddToCampaign?: OnAddToCampaignFunctionType;
  onRemove?: () => void;
  folders?: FolderType[];
  tags?: TagType[];
  isSmallScreen?: boolean;
};

const Actions = {
  Tag: 'tag',
  Folder: 'folder',
  Campaign: 'campaign',
};

const MoreActionsButton = ({
  onMoveToFolder,
  onCreateNewFolder,
  onAddTag,
  onAddToBlackList,
  onRemove,
  onAddToCampaign,
  folders,
  tags,
  isSmallScreen,
}: MoreActionsButtonPropsType): JSX.Element => {
  const moreActionsTooltipRef = useRef();

  const [campaignsList, changeCampaignsList] = useState([]);
  const currentWorkspaceId = useSelector(getCurrentWorkspaceId);

  const [tagInput, changeTagInput] = useState('');
  const [activeAction, changeActiveAction] = useState('');

  const handleMoreActionsClick = () => ReactTooltip.show(moreActionsTooltipRef.current);
  const createSetCurrentActionHandler = (action) => () => changeActiveAction(action);

  useEffect(() => {
    if (activeAction === Actions.Campaign) {
      getCampaignShortcutsApi(currentWorkspaceId).then(changeCampaignsList);
    }
  }, [activeAction]);

  return (
    <>
      <Button
        innerRef={moreActionsTooltipRef}
        onClick={handleMoreActionsClick}
        data-for="peoples-more-actions"
        type="additional"
        className="people-header-actions__more-actions-btn"
        data-tip=""
        data-event="click"
      >
        <SVGIcon icon="actions" />
        {isSmallScreen ? null : 'More actions'}
      </Button>
      <ReactTooltip
        afterHide={() => changeActiveAction('')}
        id="peoples-more-actions"
        className="react-tooltip"
        place="bottom"
        effect="solid"
        globalEventOff="click"
        arrowColor="transparent"
        clickable
      >
        <div
          className="people-header-more-actions-tooltip"
          onClick={(e) => e.stopPropagation()}
          role="button"
          tabIndex={0}
        >
          <Display isVisible={onRemove !== undefined}>
            <div
              className="people-header-more-actions-tooltip__action"
              onClick={onRemove}
              role="button"
              tabIndex={0}
            >
              <SVGIcon icon="trashWhite" />
              Remove
            </div>
          </Display>
          <Display isVisible={onAddTag !== undefined}>
            <div
              className="people-header-more-actions-tooltip__action"
              onClick={createSetCurrentActionHandler(Actions.Tag)}
              role="button"
              tabIndex={0}
            >
              {activeAction === Actions.Tag ? (
                <div className="people-header-more-actions-tooltip__select">
                  <Select
                    isGhost
                    value={null}
                    options={transformDataWithIdAndTittleToOptions(tags)}
                    additionalComponents={{
                      Menu: createSelectMenu('Add tag', () => onAddTag(tagInput)),
                    }}
                    onInputChange={changeTagInput}
                    onChange={({ value, label }) => onAddTag(label, value)}
                  />
                </div>
              ) : (
                <>
                  <SVGIcon icon="tagWhite" />
                  Add a tag
                </>
              )}
            </div>
          </Display>

          <Display isVisible={onAddToCampaign !== undefined}>
            <div
              className="people-header-more-actions-tooltip__action"
              onClick={createSetCurrentActionHandler(Actions.Campaign)}
              tabIndex={0}
              role="button"
            >
              {activeAction === Actions.Campaign ? (
                <div className="people-header-more-actions-tooltip__select">
                  <Select
                    isGhost
                    value={null}
                    options={transformDataWithIdAndTittleToOptions(campaignsList)}
                    additionalComponents={{
                      Menu: createSelectMenu(null, null),
                      Option: SelectOption,
                    }}
                    classNamePrefix="people-header-more-actions-react-select"
                    onChange={({ value, label: campaignName }) =>
                      onAddToCampaign(campaignName, value)
                    }
                  />
                </div>
              ) : (
                <>
                  <SVGIcon icon="inbox" />
                  Add to campaign
                </>
              )}
            </div>
          </Display>

          <Display isVisible={onCreateNewFolder !== undefined}>
            <div
              className="people-header-more-actions-tooltip__action"
              onClick={createSetCurrentActionHandler(Actions.Folder)}
              role="button"
              tabIndex={0}
            >
              {activeAction === Actions.Folder ? (
                <div className="people-header-more-actions-tooltip__select">
                  <Select
                    isGhost
                    value={null}
                    options={transformDataWithIdAndTittleToOptions(folders)}
                    additionalComponents={{
                      Menu: createSelectMenu('Add folder', onCreateNewFolder),
                    }}
                    onChange={onMoveToFolder}
                  />
                </div>
              ) : (
                <>
                  <SVGIcon icon="folderWhite" />
                  Add to folder
                </>
              )}
            </div>
          </Display>
        </div>
      </ReactTooltip>
    </>
  );
};

export default MoreActionsButton;
