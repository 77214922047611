import React, { memo, MouseEvent } from 'react';
import ReactTooltip from 'react-tooltip';
import { Transforms, Selection } from 'slate';
import { ReactEditor } from 'slate-react';
import { nanoid } from 'nanoid';

import emoji from './emoji';

import './EmojiButton.scss';

const EmojiList = memo((): JSX.Element => {
  return (
    <>
      {emoji.map((char) => (
        <span key={char} className="emoji-button-tooltip__item">
          {char}
        </span>
      ))}
    </>
  );
});
/**
 * Emoji took from:
 * https://unpkg.com/emoji.json@13.1.0/emoji.json
 * https://unpkg.com/emoji.json@13.1.0/emoji-compact.json
 * @constructor
 */
const EmojiButton = ({
  editor,
  lastSelection,
  place = 'top',
  onChange,
}: {
  editor?: ReactEditor;
  lastSelection?: Selection;
  place?: 'top' | 'bottom' | 'left' | 'right',
  onChange?: (emoji: string) => void;
}): JSX.Element => {
  const uniqueId = nanoid();
  const handleSelectEmoji = (e: MouseEvent<HTMLDivElement>) => {
    // @ts-ignore
    if (e.target.nodeName === 'SPAN') {
      // @ts-ignore
      const emojiChar = e.target.innerHTML;
      if (editor && lastSelection) {
        Transforms.insertText(editor, emojiChar, { at: lastSelection });
      } else if (onChange) {
        onChange(emojiChar);
      }
    }
  };

  return (
    <>
      <span
        data-for={`editor-emoji-button-${uniqueId}`}
        data-tip=""
        className="editor-emoji-button"
        onClick={(e) => e.stopPropagation()}
      >
        😀
      </span>
      <ReactTooltip
        id={`editor-emoji-button-${uniqueId}`}
        event="click"
        globalEventOff="click"
        clickable
        place={place}
        effect="solid"
        arrowColor="transparent"
      >
        <div className="emoji-button-tooltip" onClick={handleSelectEmoji}>
          <EmojiList />
        </div>
      </ReactTooltip>
    </>
  );
};

export default EmojiButton;
