import React, { useRef } from 'react';

import { BlackListResponseType } from '@ts/mailboxInbox.types';

import useIntersectionObserver from '@hooks/useIntersectionObserver';

import ConfigurableTable from '@uikit/ConfigurableTable/ConfigurableTable';
import Loader from '@uikit/Loader/Loader';

import tableConfig, { RenderRow } from '@components/Settings/UnsubscribeListTable/tableConfig';

import './UnsubscribeListTable.scss';
import { useRefCallback } from '@helpers/refHelpers';

function UnsubscribeListTable({
  data,
  onRemovePattern,
  onEditPattern,
  onToggleSelection,
  selectedIds,
  isAllSelected,
  onToggleAllSelected,
  onLoadMore,
  isFetchingNextPage,
  hasNextPage,
}: {
  data: BlackListResponseType[];
  onRemovePattern: (BlackListResponseType) => void;
  onEditPattern: (BlackListResponseType) => void;
  onToggleSelection: (number) => void;
  selectedIds: { [key: number]: number };
  isAllSelected: boolean;
  onToggleAllSelected: (value: boolean) => void;
  onLoadMore: () => void;
  isFetchingNextPage: boolean;
  hasNextPage: boolean;
}): JSX.Element {
  const [loadingRef, setLoadingRef, ready] = useRefCallback<HTMLDivElement>();

  useIntersectionObserver(loadingRef, () => onLoadMore(), [ready]);

  const renderLoader = () => {
    if (isFetchingNextPage) {
      return <Loader isLoading withTopMargin />;
    }

    if (data?.length && data[0].totalItems > data.length && hasNextPage) {
      return (
        <div ref={setLoadingRef}>
          <Loader isLoading withTopMargin />
        </div>
      );
    }

    return null;
  };

  if (data?.length === 0) {
    return <div className="unsubscribe-table__empty">No unsubscribed patterns found</div>;
  }

  return (
    <>
      <ConfigurableTable
        tableClassName="unsubscribe-table"
        config={tableConfig}
        data={data}
        RenderRow={RenderRow}
        renderProps={{
          onRemovePattern,
          onEditPattern,
          selectedIds,
          onToggleSelection,
          isAllSelected,
          onToggleAllSelected,
        }}
      />
      {renderLoader()}
    </>
  );
}

export default UnsubscribeListTable;
