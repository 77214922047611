import React from 'react';

const WebsitesSvg = () => {
  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="52" height="52" rx="9" fill="white" />
      <g clipPath="url(#clip0_16053_4268)">
        <path
          d="M33.25 20.75V15.125H19.75V24.125"
          stroke="#251FB6"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.25 39.875H28.75"
          stroke="#212121"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M24.25 27.5H14.125V39.875H24.25V27.5Z"
          stroke="#251FB6"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M38.875 24.125H28.75V39.875H38.875V24.125Z"
          stroke="#251FB6"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.25 28.625H34.375"
          stroke="#251FB6"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.25 32H34.375"
          stroke="#251FB6"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.25 35.375H34.375"
          stroke="#251FB6"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.625 35.375H19.75"
          stroke="#251FB6"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.625 32H19.75"
          stroke="#251FB6"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_16053_4268">
          <rect width="27" height="27" fill="white" transform="translate(13 14)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WebsitesSvg;
