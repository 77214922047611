import { useQuery, useQueryClient } from 'react-query';
import { getSharedWorkspacesApi } from '@api/mailboxAccounts.api';
import { MailboxAccountWorkspaceType } from '@ts/mailboxAccounts.types';
import { QueryCollectionHookType } from '@ts/common.types';
import { useMemo } from 'react';

export default function useSharedWorkspaces(
  mailboxId: number,
  lazy: boolean = false
): QueryCollectionHookType<MailboxAccountWorkspaceType> {
  const queryClient = useQueryClient();

  const composeKey = useMemo(() => (id: number) => ['shared-workspaces-by-mailbox', id], []);
  const key = composeKey(mailboxId);

  const queryResult = useQuery<MailboxAccountWorkspaceType[]>(
    key,
    () => getSharedWorkspacesApi(mailboxId),
    {
      enabled: !lazy && mailboxId > 0,
      refetchOnWindowFocus: false,
      placeholderData: [],
      staleTime: 15 * 60 * 1000,
    }
  );

  const { data, isFetching, isLoading, isRefetching, refetch, isSuccess } = queryResult;

  const updateCache = (
    updateFn: (cachedData: MailboxAccountWorkspaceType[]) => MailboxAccountWorkspaceType[]
  ) => {
    queryClient.setQueryData<MailboxAccountWorkspaceType[]>(key, updateFn);
  };

  const removeItem = (id: number) => {
    updateCache((cachedData) => cachedData.filter((item) => item.workspaceId !== id));
  };

  const addItem = (item: MailboxAccountWorkspaceType) => {
    updateCache((cachedData) => (cachedData ? [...cachedData, item] : [item]));
  };

  const updateItem = (id: number, payload: MailboxAccountWorkspaceType) => {
    updateCache((cachedData) =>
      cachedData.map((item) => (item.id === id ? { ...item, ...payload } : item))
    );
  };

  const evictByKey = (id: number) => queryClient.removeQueries(composeKey(id));

  return {
    items: data,
    refetch,
    isLoading: isFetching || isLoading || isRefetching,
    isEmpty: isSuccess && data?.length === 0,
    removeItem,
    addItem,
    updateItem,
    evictByKey,
  };
}
