import React, { useCallback, useEffect, useRef, useState } from 'react';

import ReactTooltip from 'react-tooltip';
import cn from 'class-names';

import Display from 'src/components/Display/Display';
import { SVGIcon } from 'src/uikit/Icon/Icon';
import Input from 'src/uikit/Input/Input';
import stripHtml from 'src/utils/stripHtml';
import useOutsideClick from '@hooks/useOutsideClick';
import {
  TemplateContainerTypeWithTransformedStepsType,
  TemplateStepTransformedType,
} from '@ts/template.types';

const SequenceSingleStep = ({
  step,
  id,
  index,
  sendDelay,
  delayFromFirstDay,
  subject,
  prevSubject,
  content,
  isSameThread,
  onClick,
  isActive,
  onChangeDelay,
}: {
  step: TemplateStepTransformedType,
  id: number;
  index: number;
  sendDelay: number | '';
  delayFromFirstDay: number;
  subject: string;
  prevSubject: string;
  content: string;
  isSameThread: boolean;
  onClick: (index: number) => void;
  isActive: boolean;
  onChangeDelay: (step: TemplateStepTransformedType) => Promise<TemplateContainerTypeWithTransformedStepsType> | void;
}): JSX.Element => {
  const delayValueInputRef = useRef(null);
  const tooltipRef = useRef(null);
  const [delayEditValue, changeDelayEditValue] = useState(sendDelay);
  const editBtnEl = useRef(null);

  useEffect(() => {
    changeDelayEditValue(sendDelay);
  }, [sendDelay]);

  useOutsideClick(editBtnEl, () => ReactTooltip.hide(editBtnEl.current));

  const cnSequenceSingleStep = cn('sequence-step', {
    'sequence-step--active': isActive,
  });

  const handleDelayEditValueChange = useCallback(
    (evt) => {
      evt.stopPropagation();
      const MAX_VALUE = 60;
      const {
        target: { value },
      } = evt;

      if (value === '' || Number.isNaN(Number(value))) {
        return changeDelayEditValue('');
      }

      const valueNumber = Number(value);

      if (valueNumber === 0 || valueNumber < 0) {
        if (index === 0) {
          changeDelayEditValue(0);
        } else {
          changeDelayEditValue(1);
        }
      } else if (valueNumber > MAX_VALUE) {
        changeDelayEditValue(MAX_VALUE);
      } else if (valueNumber < 0) {
        changeDelayEditValue(0);
      } else {
        changeDelayEditValue(valueNumber);
      }
    },
    [sendDelay, index, subject, content]
  );

  const handleCancelDelayChange = (e) => {
    e.stopPropagation();
    tooltipRef.current.tooltipRef = null;
    ReactTooltip.hide();
  };

  const handleSaveDelay = (e) => {
    e.stopPropagation();
    if (delayEditValue === '') {
      return delayValueInputRef.current.focus();
    }
    tooltipRef.current.tooltipRef = null;
    ReactTooltip.hide();
    onChangeDelay({ ...step, sendDelay: delayEditValue });
  };

  const handleEditClick = (e) => {
    e.stopPropagation();
    ReactTooltip.hide(editBtnEl.current);
    ReactTooltip.show(editBtnEl.current);
  };

  const transformedSubject = isSameThread
    ? `Re: ${stripHtml(prevSubject).trim()}`
    : stripHtml(subject).trim();

  const getStepWarning = () => {
    if (index === 0) {
      if (stripHtml(subject).trim() === '') {
        return 'Step subject not filled';
      }

      if (stripHtml(content).trim() === '') {
        return 'Step content not filled';
      }
    }

    if (stripHtml(content).trim() === '') {
      return 'Step content not filled';
    }

    return '';
  };

  const stepWarning = getStepWarning();

  return (
    <div className={cnSequenceSingleStep} onClick={() => onClick(index)} tabIndex={0} role="button">
      <div className="sequence-step__title">
        <Display isVisible={stepWarning?.length > 0}>
          <span
            className="sequence-step__title-warning"
            data-for={`sequence-step-warning-${index}`}
            data-tip={stepWarning}
          >
            <SVGIcon icon="warningTriangle" />
          </span>
          <ReactTooltip id={`sequence-step-warning-${index}`} place="top" effect="solid" arrowColor="transparent" />
        </Display>
        Step {index + 1} - Day {delayFromFirstDay}
        <Display isVisible={Boolean(onChangeDelay)}>
          <span
            ref={editBtnEl}
            data-place="top"
            data-tip=""
            data-for={`delay-tooltip-${id}`}
            className="sequence-step__edit-delay-btn"
            onClick={handleEditClick}
            data-event="click focus"
          >
            <SVGIcon icon="pen" />
          </span>
          <ReactTooltip
            id={`delay-tooltip-${id}`}
            ref={tooltipRef}
            backgroundColor="#000"
            clickable
            className="edit-delay-tooltip-wrapper"
            globalEventOff="click"
            arrowColor="transparent"
            afterShow={() => changeDelayEditValue(sendDelay)}
          >
            <div
              className="sequence-step__edit-delay-tooltip edit-delay-tooltip"
              onClick={(e) => e.stopPropagation()}
              tabIndex={0}
              role="button"
            >
              <div className="edit-delay-tooltip__title">Deliver after a delay</div>
              <Input
                value={delayEditValue}
                onChange={handleDelayEditValueChange}
                afterText="Days"
                inputRef={delayValueInputRef}
              />
              <div className="edit-delay-tooltip__buttons">
                <button
                  onClick={handleSaveDelay}
                  className="edit-delay-tooltip__button edit-delay-tooltip__button--save"
                  type="button"
                >
                  Save
                </button>
                <button
                  onClick={handleCancelDelayChange}
                  className="edit-delay-tooltip__button edit-delay-tooltip__button--cancel"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </div>
          </ReactTooltip>
        </Display>
        <Display isVisible={index !== 0}>
          <span className="sequence-step__title-description">If no reply</span>
        </Display>
      </div>
      <div className="sequence-step__subject">{transformedSubject}</div>
      <div className="sequence-step__template">{stripHtml(content).trim() || 'Body'}</div>
    </div>
  );
};

export default SequenceSingleStep;
