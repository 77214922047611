import React from 'react';

export default ({ color = '#000' }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.3332 6.21328C15.375 6.04199 15.3512 5.86127 15.2666 5.70662C15.0505 5.33171 14.7626 5.00316 14.4192 4.73981C14.0759 4.47647 13.6839 4.28351 13.2658 4.17202C12.8477 4.06053 12.4117 4.03269 11.9828 4.09011C11.5539 4.14753 11.1406 4.28907 10.7666 4.50662L9.33324 5.33328L5.99991 3.76662C5.90514 3.71752 5.79997 3.69189 5.69324 3.69189C5.58651 3.69189 5.48134 3.71752 5.38658 3.76662L3.38658 4.91995C3.28774 4.97696 3.20518 5.05839 3.14682 5.15644C3.08846 5.25449 3.05624 5.36588 3.05324 5.47995C3.05002 5.59486 3.07656 5.70866 3.13029 5.81028C3.18402 5.91191 3.26312 5.99792 3.35991 6.05995L5.55991 7.44662L4.39991 8.11328L1.21324 8.49995C1.08514 8.51579 0.964393 8.56849 0.865659 8.65163C0.766925 8.73478 0.694452 8.8448 0.657038 8.96834C0.619623 9.09187 0.618877 9.22362 0.65489 9.34757C0.690902 9.47152 0.762125 9.58236 0.85991 9.66662L3.21991 11.7066C3.53061 12.0031 3.92851 12.1918 4.35474 12.2446C4.78097 12.2974 5.21289 12.2117 5.58658 11.9999L14.9999 6.61995C15.0801 6.5783 15.1511 6.52078 15.2084 6.45087C15.2657 6.38096 15.3082 6.30012 15.3332 6.21328ZM4.97991 10.8799C4.85185 10.9504 4.70418 10.9768 4.55963 10.9552C4.41508 10.9335 4.28166 10.8649 4.17991 10.7599L2.91324 9.67328L4.73324 9.45328C4.82268 9.44182 4.90886 9.41234 4.98658 9.36662L7.22658 8.07995C7.32637 8.02235 7.40953 7.93988 7.46795 7.84056C7.52637 7.74125 7.55805 7.62849 7.55991 7.51328C7.56128 7.39886 7.53317 7.28601 7.47829 7.1856C7.42342 7.08519 7.34362 7.00059 7.24658 6.93995L5.04658 5.54662L5.77991 5.12662L9.11324 6.67328C9.20801 6.72238 9.31318 6.748 9.41991 6.748C9.52664 6.748 9.63181 6.72238 9.72658 6.67328L11.4332 5.68661C11.783 5.49062 12.1836 5.40425 12.583 5.43873C12.9824 5.4732 13.3623 5.62692 13.6732 5.87995L4.97991 10.8799Z"
      fill={color}
    />
  </svg>
);
