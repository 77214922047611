import React from 'react';

const AllCampaignsSidebarIcon = ({ color = '#221CB6' }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.833496 10C0.833496 9.53976 1.20659 9.16667 1.66683 9.16667H6.66683C6.94546 9.16667 7.20565 9.30592 7.3602 9.53775L8.77948 11.6667H11.2208L12.6401 9.53775C12.7947 9.30592 13.0549 9.16667 13.3335 9.16667H18.3335C18.7937 9.16667 19.1668 9.53976 19.1668 10C19.1668 10.4602 18.7937 10.8333 18.3335 10.8333H13.7795L12.3602 12.9622C12.2056 13.1941 11.9455 13.3333 11.6668 13.3333H8.3335C8.05487 13.3333 7.79467 13.1941 7.64012 12.9622L6.22084 10.8333H1.66683C1.20659 10.8333 0.833496 10.4602 0.833496 10Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.0335 2.5L13.9668 2.5C14.4319 2.50025 14.8882 2.63024 15.2835 2.87535C15.6784 3.12025 15.9972 3.47037 16.2042 3.8864C16.2044 3.88677 16.2046 3.88713 16.2048 3.8875L19.0786 9.62689C19.1366 9.74271 19.1668 9.87047 19.1668 10V15C19.1668 15.663 18.9034 16.2989 18.4346 16.7678C17.9658 17.2366 17.3299 17.5 16.6668 17.5H3.3335C2.67045 17.5 2.03457 17.2366 1.56573 16.7678C1.09689 16.2989 0.833496 15.663 0.833496 15V10C0.833496 9.87047 0.863693 9.74272 0.92169 9.62689L3.79555 3.8875C3.79576 3.88709 3.79596 3.88669 3.79616 3.88628C4.00315 3.4703 4.32197 3.12022 4.71686 2.87535C5.11213 2.63024 5.56839 2.50025 6.0335 2.5ZM4.54183 4.25833L5.28697 4.63144L2.50016 10.197V15C2.50016 15.221 2.58796 15.433 2.74424 15.5893C2.90052 15.7455 3.11248 15.8333 3.3335 15.8333H16.6668C16.8878 15.8333 17.0998 15.7455 17.2561 15.5893C17.4124 15.433 17.5002 15.221 17.5002 15V10.197L14.7134 4.63144L14.7122 4.62917C14.6432 4.49033 14.5369 4.37349 14.4051 4.29178C14.2734 4.21011 14.1215 4.16678 13.9665 4.16667H6.03378C5.8788 4.16678 5.72692 4.21011 5.59521 4.29178C5.46345 4.37349 5.35709 4.49033 5.2881 4.62917L4.54183 4.25833Z"
      fill={color}
    />
  </svg>
);

export default AllCampaignsSidebarIcon;
