import React, { useMemo, useCallback, useRef, useEffect, useState } from 'react';
import cn from 'class-names';
import { Transforms, createEditor, Descendant, Editor } from 'slate';
import { withHistory } from 'slate-history';
import { Slate, Editable, ReactEditor, withReact } from 'slate-react';

import { VariableType } from '@constants/variables';

import withEditorVariables from '@hocs/withEditorVariables';
import useAllVariablesInEditor from '@hooks/useAllVariablesInEditor';

import EmojiButton from '@uikit/RichEditor/_components/EmojiButton/EmojiButton';
import EditorChangeFallback from '@uikit/RichEditor/_components/EditorChangeFallback/EditorChangeFallback';
import Display from '@components/Display/Display';

import { Element, Leaf, InlineVariableButton } from './SlateComponents';
import { initialValue } from './slateUtils';
import { patchSlateElementByPath, putSlateElementByPath } from './utils/stateUtils';

import { serialize } from './utils/serialize';
import { prepareMessageValueForEditor } from './utils/prepareMessageValueForEditor';

import './SlateEditor.scss';
import CounterSigns from '@uikit/CounterSigns/CounterSigns';
import stripHtml from '@utils/stripHtml';

type RichEditorPropsType = {
  message: string;
  onChange?: (string) => void;
  placeholder?: string;
  transformVariable?: (
    variable: string,
    fallback: string,
    person?: any,
    opportunity?: any
  ) => string;
  disableVariable?: boolean;
  disabled?: boolean;
  onClickDisabled?: () => void;
  useMessageUpdate?: boolean;
  withEmoji?: boolean;
  variables?: VariableType[];
  stickVariables?: boolean;
  handleOpenAiFallbackChange?: (variable: any, fallbackRequired: any, type: string) => void;
  limit?: number;
  minValue?: number;
  hasCounter?: boolean;
};

const transformVariableDefault = (variable: string, fallback: string): string => {
  return fallback && fallback.length ? `${variable} | ${fallback}` : `${variable}`;
};

const InlineSlateEditor = ({
  message,
  onChange,
  transformVariable = transformVariableDefault,
  placeholder = 'Email body',
  disableVariable = false,
  disabled = false,
  onClickDisabled = () => null,
  useMessageUpdate = false,
  withEmoji = true,
  variables,
  stickVariables,
  handleOpenAiFallbackChange,
  limit = -1,
  minValue = -1,
  hasCounter = false,
}: RichEditorPropsType): JSX.Element => {
  const editorContainerRef = useRef<HTMLDivElement | null>();
  const [value, setValue] = useState<Descendant[]>(initialValue);
  const [changingVariable, setChangingVariable] = useState(null);
  const [wasChanged, changeWasChanged] = useState(false);
  const [lastSelection, changeLastSelection] = useState(null);
  const editor = useMemo(
    () => withHistory(withEditorVariables(withReact(createEditor() as ReactEditor))),
    []
  );
  const { variables: allVariables } = variables != null ? { variables } : useAllVariablesInEditor();

  const renderElement = useCallback(
    (props) => (
      <Element
        {...props}
        transformVariable={transformVariable}
        onChangeVariable={(variable, fallbackRequired) => {
          if (handleOpenAiFallbackChange && props.element.type === 'ai-variable') {
            setChangingVariable(null);
          } else {
            setChangingVariable(variable);
          }

          if (handleOpenAiFallbackChange) {
            handleOpenAiFallbackChange(variable, fallbackRequired, props.element.type);
          }
        }}
        disableVariable={disableVariable}
        editor={editor}
        allVariables={allVariables}
      />
    ),
    [editor, allVariables, transformVariable]
  );
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const handleChangeFallback = useCallback(
    (newFallback, path) => {
      Transforms.deselect(editor);

      setValue((prevValue) => patchSlateElementByPath(prevValue, path, { fallback: newFallback }));
    },
    [value]
  );
  const handleTransformVariableIntoText = useCallback(
    (newElem, path) => {
      Transforms.deselect(editor);

      setValue((prevValue) => putSlateElementByPath(prevValue, path, newElem));
    },
    [value]
  );

  const handleSlateContainerClick = () => editor && ReactEditor.focus(editor);

  const preventPushEnter = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  useEffect(
    () => {
      setValue(prepareMessageValueForEditor(message));
    },
    useMessageUpdate ? [message] : [null]
  );

  useEffect(() => {
    if (wasChanged) {
      onChange(serialize(value));
    }
  }, [value]);

  const cnSlateEditor = cn('slate-editor__editor slate-editor__editor--inline');
  const cnWrapperVariables = cn('slate-editor__wrapper-variables', {
    'slate-editor__wrapper-variables--stick': stickVariables,
  });

  const handleDOMBeforeInput = (event) => {
    if (limit === -1) {
      return false;
    }

    const { inputType, type } = event;
    let textLength = Editor.string(editor, []).length;

    if (inputType === 'insertText') {
      textLength += event.data.length;
    }

    if (type === 'paste') {
      const clipBoardText = event.clipboardData.getData('Text');
      const diff = limit - textLength;

      if (clipBoardText.length > diff) {
        const newText = clipBoardText.slice(0, diff);

        editor.insertText(newText);
        event.preventDefault();
        return true;
      }
    }

    if (textLength > limit) {
      event.preventDefault();
      return true;
    }

    return false;
  };

  editor.children = value;

  return (
    <>
      <div ref={editorContainerRef} className="slate-editor">
        <Slate
          editor={editor}
          value={value}
          onChange={(currentValue) => {
            const { selection } = editor;

            setValue(currentValue);
            changeWasChanged(true);

            if (selection !== null) {
              changeLastSelection(selection);
            }
          }}
        >
          <div className={cnSlateEditor} onClick={handleSlateContainerClick}>
            <Display isVisible={changingVariable !== null}>
              <EditorChangeFallback
                onChangeFallback={handleChangeFallback}
                onTransformIntoText={handleTransformVariableIntoText}
                containerRef={editorContainerRef}
                editor={editor}
                onClose={() => setChangingVariable(null)}
                changingVariable={changingVariable}
                disableVariable={disableVariable}
                isInline
              />
            </Display>
            <div
              className="slate-editor__editor-wrapper-inline"
              onClick={disabled ? onClickDisabled : () => null}
            >
              <Editable
                onDOMBeforeInput={handleDOMBeforeInput}
                onPaste={handleDOMBeforeInput}
                readOnly={disabled}
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                placeholder={placeholder}
                onKeyPress={preventPushEnter}
                onFocus={() => {
                  editorContainerRef.current.classList.add('focused-editor');
                }}
                onBlur={() => {
                  editorContainerRef.current.classList.remove('focused-editor');
                }}
              />
            </div>
            <Display isVisible={!disableVariable}>
              <div className={cnWrapperVariables}>
                <InlineVariableButton
                  lastSelection={lastSelection}
                  editor={editor}
                  variables={variables}
                  onCreateNew={
                    handleOpenAiFallbackChange
                      ? () => handleOpenAiFallbackChange({}, true, 'ai-variable')
                      : null
                  }
                />
              </div>
            </Display>

            {withEmoji ? (
              <div className="moderate-chance" style={{ marginRight: '5px' }}>
                <EmojiButton editor={editor} lastSelection={lastSelection} place="bottom" />
              </div>
            ) : null}
          </div>
        </Slate>
      </div>
      {hasCounter ? (
        <CounterSigns limit={limit} minValue={minValue} value={stripHtml(message).trim().length} />
      ) : null}
    </>
  );
};

export default InlineSlateEditor;
