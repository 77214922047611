import React from 'react';

export default ({ color = '#868686', size = 10 }: { color?: string; size?: number }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.99935 4.33333L5.66602 3"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.66667 8.46667L1 9L1.53333 6.33333L6.86667 1L9 3.13333L3.66667 8.46667Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
