import React, { useState, useCallback, useMemo } from 'react';
import { useInfiniteQuery, useQueryClient } from 'react-query';

import withSmallScreensCheck from '@hocs/withSmallScreensCheck';

import Loader from '@uikit/Loader/Loader';
import HeaderSearchInput from '@components/HeaderSearchInput/HeaderSearchInput';

import './Opportunities.scss';
import { CampaignOpportunityFilterType, OpportunityPageType } from '@ts/opportunity.types';
import OpportunitiesTable from '@components/CampaignAnalytics/Opportunities/_components/OpportunitiesTable/OpportunitiesTable';
import { getCampaignOpportunitiesApi } from '@api/campaign.api';
import { OpportunityFilterField } from 'respona_api/generated/campaign_pb';
import {
  ContactStatus,
  FilterJoinType,
  FilterOperation,
  PeopleRelationshipStatus,
} from 'respona_api/generated/common_pb';
import { SVGIcon } from '@uikit/Icon/Icon';
import { Button } from '@uikit/Button/Button';
import ExportPeopleSidebar from '@components/ExportCsvSidebar/ExportCsvSidebar';
import Display from '@components/Display/Display';
import MoreActionsButton from '@components/CampaignAnalytics/People/_components/MoreActionsButton/MoreActionsButton';
import { cancelAllQueuesByPersonIdAndReCalculateQueuesApi } from '@api/mailbox.api';
import { MailboxQueueCancelRequest } from 'respona_api/generated/mailbox_pb';
import { addNotification } from '@redux/actions/notifications.actions';
import { useDispatch } from 'react-redux';
import { MailboxAccountStatisticsType } from '@ts/mailboxAccounts.types';
import { ExportPeopleRequest } from 'respona_api/generated/people_pb';

type OpportunitiesPropsType = {
  RenderHeader?: (props: any) => JSX.Element;
  campaignId: number;
  workspaceId: number;
};

const REQUEST_LIMIT = 10;

function Opportunities({
  campaignId,
  workspaceId,
  RenderHeader,
  isSmallScreen,
}: OpportunitiesPropsType & { isSmallScreen: boolean }): JSX.Element {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [searchQuery, changeSearchQuery] = useState<string>('');
  const [selectedIds, changeSelectedIds] = useState<number[]>([]);
  const [isExportOpen, setIsExportOpen] = useState<boolean>(false);

  const filters = useMemo(() => {
    if (searchQuery.length <= 0) {
      return [];
    }
    const emailSearch = {
      order: 0,
      field: OpportunityFilterField.OPPORTUNITY_CONTACT_EMAIL,
      value: searchQuery,
      operation: FilterOperation.STRING_CONTAINS,
      join: FilterJoinType.JOIN_QUERY_OR,
    } as CampaignOpportunityFilterType;
    const titleSearch = {
      order: 1,
      field: OpportunityFilterField.OPPORTUNITY_TITLE,
      value: searchQuery,
      operation: FilterOperation.STRING_CONTAINS,
      join: FilterJoinType.JOIN_QUERY_OR,
    } as CampaignOpportunityFilterType;
    const urlSearch = {
      order: 2,
      field: OpportunityFilterField.OPPORTUNITY_URL,
      value: searchQuery,
      operation: FilterOperation.STRING_CONTAINS,
      join: FilterJoinType.JOIN_QUERY_OR,
    } as CampaignOpportunityFilterType;
    return [emailSearch, titleSearch, urlSearch];
  }, [searchQuery]);

  const queryKey = useMemo(
    () => ['analytics-opportunities', campaignId, workspaceId, searchQuery],
    [campaignId, workspaceId, searchQuery]
  );

  const {
    data,
    isLoading,
    isFetching,
    fetchNextPage,
    isFetchingNextPage,
    isRefetching,
    hasNextPage,
  } = useInfiniteQuery<OpportunityPageType[]>(
    queryKey,
    ({ pageParam = 0 }) =>
      getCampaignOpportunitiesApi(campaignId, workspaceId, pageParam, REQUEST_LIMIT, -1, filters),
    {
      getNextPageParam: (lastPage, allPages) =>
        lastPage.length < REQUEST_LIMIT ? false : allPages.length,
      enabled: !!workspaceId && !!campaignId,
      refetchOnWindowFocus: false,
    }
  );

  const opportunities = useMemo(() => data?.pages?.flat() || [], [data]);

  const handleSearchChange = useCallback((query: string) => {
    changeSearchQuery(query);
  }, []);

  const handleIdChange = useCallback((ids: number[]) => {
    changeSelectedIds(ids);
  }, []);

  const isSelectedAll = useMemo(
    () => opportunities.length === selectedIds.length,
    [opportunities, selectedIds]
  );

  const handleStopSelected = () => {
    cancelAllQueuesByPersonIdAndReCalculateQueuesApi(
      workspaceId,
      campaignId,
      selectedIds,
      MailboxQueueCancelRequest.MailboxQueueCancelRelatedType.OPPORTUNITY
    ).then(() => {
      queryClient.setQueryData(queryKey, ({ pages, pageParams }) => {
        const updatePages = pages.map((page) =>
          page.map((item: OpportunityPageType) => {
            if (selectedIds.includes(item.id))
              return {
                ...item,
                relationshipStatus: ContactStatus.STOPPED,
              };
            return item;
          })
        );

        return {
          pageParams,
          pages: updatePages,
        };
      });
      dispatch(
        addNotification({
          title: 'Queries of selected opportunities were canceled',
          type: 'success',
        })
      );
    });
  };

  const composeItemsNumber = useMemo(() => {
    if (opportunities == null || opportunities.length === 0) {
      return 0;
    }
    return isSelectedAll ? opportunities[0].totalItems : selectedIds?.length;
  }, [isSelectedAll, opportunities, selectedIds]);

  return (
    <>
      {RenderHeader && (
        <RenderHeader>
          <div className="opportunities-analytic-header-actions">
            <HeaderSearchInput
              searchQuery={searchQuery}
              onUpdateSearchQuery={handleSearchChange}
              placeholder="Search by ..."
            />
            <Display
              isVisible={opportunities?.length > 0 && (isSelectedAll || selectedIds?.length > 0)}
            >
              <Button
                onClick={() => setIsExportOpen(true)}
                className="people-analytic-header-actions__export-users-btn"
                type="additional"
                size="xl"
              >
                <SVGIcon icon="download" />
                {isSmallScreen ? null : 'Export'}
              </Button>
              <MoreActionsButton
                onStop={handleStopSelected}
                // onUnsubscribe={handleUnsubscribeSelected}
                isSmallScreen={isSmallScreen}
              />
            </Display>
          </div>
        </RenderHeader>
      )}
      <Loader
        isLoading={(isLoading || isFetching) && !isFetchingNextPage && !isRefetching}
        withTopMargin
      >
        <OpportunitiesTable
          opportunities={opportunities}
          selectedIds={selectedIds}
          changeSelectedIds={handleIdChange}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
        />
      </Loader>
      <ExportPeopleSidebar
        campaignId={campaignId}
        isOpen={isExportOpen}
        onClose={() => setIsExportOpen(false)}
        selectedIds={selectedIds}
        itemsNumber={composeItemsNumber}
        type="opportunities"
        isSelectedAll={isSelectedAll}
        sidebarExportType={
          isSelectedAll
            ? ExportPeopleRequest.ExportType.ALL
            : ExportPeopleRequest.ExportType.SELECTED
        }
      />
    </>
  );
}

export default withSmallScreensCheck<OpportunitiesPropsType>(Opportunities);
