import { SVGIcon } from '@uikit/Icon/Icon';
import React, { useRef } from 'react';

import useIntersectionObserver from '@hooks/useIntersectionObserver';
import Loader from '@uikit/Loader/Loader';
import Display from '@components/Display/Display';
import ThreadsItem from '@components/Inbox/InboxMailsSidebar/_components/ThreadsItem/ThreadsItem';

import { ThreadPreviewResponseType, QueuePreviewResponseType } from '@ts/mailboxInbox.types';

import './ThreadsList.scss';
import { useRefCallback } from '@helpers/refHelpers';

type ThreadsListProps = {
  threadList: ThreadPreviewResponseType[] | QueuePreviewResponseType[];
  activeIndex: number;
  onChangeActiveThread: (threadOrQueStringId: string) => void;
  onLoadMore: () => void;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
};

function ThreadsList({
  threadList,
  activeIndex,
  onChangeActiveThread,
  onLoadMore,
  hasNextPage,
  isFetchingNextPage,
}: ThreadsListProps): JSX.Element {
  const [loadingRef, setLoadingRef, ready] = useRefCallback<HTMLDivElement>();

  useIntersectionObserver(loadingRef, () => onLoadMore(), [ready]);

  const sortedThreadList = (threadList as any[]).sort((a, b) => b.favorite - a.favorite);

  return (
    <div className="threads-list">
      {/* Here can be one type or another,
      & is just to allow to use properties from both */}
      {!sortedThreadList?.length && (
        <div className="threads-list__empty-message">
          <SVGIcon icon="inboxIcon" color="#8F9199" />
          <span>No conversations</span>
        </div>
      )}
      {sortedThreadList?.map((thread: ThreadPreviewResponseType | QueuePreviewResponseType) => {
        // TODO: RES-2960

        function getDraftOrEmpty(payload: unknown) {
          if (Object.prototype.hasOwnProperty.call(thread, 'draft')) {
            // @ts-ignore
            return payload.draft;
          }
          return null;
        }

        function getFavoriteOrFalse(payload: unknown) {
          if (Object.prototype.hasOwnProperty.call(thread, 'favorite')) {
            // @ts-ignore
            return payload.favorite;
          }
          return false;
        }

        // QueuePreviewResponseType
        if ('contactId' in thread) {
          const activeId = thread.contactId;

          return (
            <ThreadsItem
              isActive={activeId === activeIndex}
              onClick={() => onChangeActiveThread(activeId.toString())}
              subject={thread.subject}
              snippet={thread.snippet}
              name={thread.toName}
              time={thread.nextActivityTime}
              viewed
              key={activeId}
              isFutureTime
              draftExists={false}
              isFavorite={getFavoriteOrFalse(thread)}
            />
          );
        }

        // InboxPreviewResponseType
        return (
          <ThreadsItem
            isActive={thread.threadId === activeIndex}
            onClick={() => {
              onChangeActiveThread(thread.uid);
            }}
            subject={thread.subject}
            snoozed={thread.snoozedUntil}
            snippet={thread.snippet}
            name={thread.fromName}
            time={thread.lastActivityTime}
            viewed={thread.viewed}
            key={thread.uid}
            // @ts-ignore
            draftExists={thread?.draftExists}
            isFavorite={thread.favorite}
            tags={thread.tagsList}
          />
        );
      })}

      <Display isVisible={hasNextPage && !isFetchingNextPage}>
        <div ref={setLoadingRef} className="threads-list__loading-trigger" />
      </Display>

      <Display isVisible={isFetchingNextPage}>
        <Loader isLoading withTopMargin />
      </Display>
    </div>
  );
}

export default ThreadsList;
