import React from 'react';

export default () => (
  <svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="61" height="61" rx="30.5" fill="#FC8619" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.9 19.3015C34.6825 21.3894 38.875 23.5013 41.5 23.5013C42.328 23.5013 43 24.1717 43 25.0012C43 33.3767 38.9485 39.7258 31.591 42.8786C31.402 42.9596 31.201 43.0001 31 43.0001C30.799 43.0001 30.598 42.9596 30.409 42.8786C23.0515 39.7258 19 33.3767 19 25.0012C19 24.1717 19.672 23.5013 20.5 23.5013C23.125 23.5013 27.3175 21.3894 30.1 19.3015C30.6325 18.8996 31.3675 18.8996 31.9 19.3015ZM29.4 23.201C29.5775 23.0671 29.7887 23.0001 30 23.0001V39.0001C29.866 39.0001 29.732 38.9731 29.606 38.9191C24.701 36.8172 22 32.5845 22 27.0008C22 26.4478 22.448 26.0009 23 26.0009C24.75 26.0009 27.545 24.593 29.4 23.201Z"
      fill="white"
    />
  </svg>
);
