import React, { useRef } from 'react';

import { normalizeValue } from '@utils/math';

import './ModerateChanceLevelBar.scss';

type ModerateChanceLevelBarPropsType = {
  label: string;
  min: number;
  max: number;
  greenZone: {
    min: number;
    max: number;
  };
  parsedValue: {
    value: number;
    boundary: {
      color: string;
    };
  };
};

const ModerateChanceLevelBar = ({ label, min, max, greenZone, parsedValue }: ModerateChanceLevelBarPropsType): JSX.Element => {
  const barLevelRef = useRef();
  const normalizedFactor = normalizeValue(min, max, 230);

  const greenZoneStyles = {
    left: `${greenZone.min * normalizedFactor}px`,
    width: `${(greenZone.max - greenZone.min) * normalizedFactor}px`,
  };

  const valueStyles = {
    width: `${parsedValue.value * normalizedFactor}px`,
    backgroundColor: parsedValue.boundary ? parsedValue.boundary.color : 'red',
  };

  return (
    <div className="level-bar-wrapper">
      <div className="level-bar-wrapper__label">
        {label}
        <div className="level-bar-wrapper__value">{parsedValue.value}</div>
      </div>
      <div className="level-bar" ref={barLevelRef}>
        <div className="level-bar__green-zone" style={greenZoneStyles} />
        <div className="level-bar__value" style={valueStyles} />
      </div>
    </div>
  );
};

export default ModerateChanceLevelBar;
