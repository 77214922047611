import React from 'react';

export default ({ color = '#C4C6CD' }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.1408 9.20562C11.8844 7.86561 8.16243 7.7424 6.00844 8.39623C5.66255 8.50113 5.29676 8.30587 5.19194 7.95998C5.08712 7.61392 5.28222 7.24839 5.62836 7.14324C8.10099 6.39261 12.2115 6.53764 14.8091 8.0796C15.1202 8.26424 15.2222 8.66605 15.0378 8.97667C14.8533 9.28778 14.4511 9.39035 14.1408 9.20562ZM14.0669 11.1903C13.9086 11.4472 13.5727 11.5278 13.3162 11.3701C11.435 10.2138 8.5665 9.87884 6.34095 10.5543C6.05232 10.6416 5.74747 10.4788 5.65987 10.1908C5.57286 9.90216 5.73569 9.5979 6.02373 9.51013C8.56616 8.73869 11.7267 9.11233 13.8873 10.4401C14.1439 10.598 14.2246 10.9341 14.0669 11.1903ZM13.2103 13.0964C13.0845 13.3027 12.816 13.3673 12.6104 13.2416C10.9666 12.237 8.89759 12.0101 6.46099 12.5666C6.22619 12.6205 5.99222 12.4733 5.93864 12.2385C5.88489 12.0038 6.03142 11.7698 6.26681 11.7162C8.9332 11.1067 11.2205 11.369 13.0656 12.4964C13.2713 12.622 13.3361 12.8908 13.2103 13.0964ZM10 3C6.13407 3 3 6.13399 3 9.99988C3 13.8662 6.13407 16.9999 10 16.9999C13.8661 16.9999 17 13.8662 17 9.99988C17 6.13399 13.8661 3 10 3Z"
      fill={color}
    />
  </svg>
);
