import { useInfiniteQuery, useQueryClient } from 'react-query';
import { QueryPageHookType } from '@ts/common.types';
import { useMemo, useCallback } from 'react';
import { getAllMembersOfOrganizationApi } from '@api/userOrganization.api';
import { OrganizationMemberResponseType } from '@ts/userOrganization.types';

export default (
  all: boolean = false,
  enabled: boolean = true
): QueryPageHookType<OrganizationMemberResponseType> => {
  const queryClient = useQueryClient();
  const requestLimit = useMemo(() => (all ? 250 : 20), [all]);
  const key = useMemo(() => ['organization-members'], []);

  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage, refetch } =
    useInfiniteQuery<OrganizationMemberResponseType[]>(
      key,
      ({ pageParam = 0 }) => getAllMembersOfOrganizationApi(pageParam, requestLimit),
      {
        getNextPageParam: (lastPage: OrganizationMemberResponseType[], allPages) => {
          if (lastPage.length < requestLimit) return false;

          return allPages.length;
        },
        enabled,
        retry: 1,
        refetchOnWindowFocus: false,
        staleTime: 15 * 60 * 1000,
      }
    );

  const removeFromCacheById = (id: number) =>
    queryClient.setQueryData(key, ({ pages, pageParams }) => {
      const updatePages = pages.map((page) =>
        page.filter((item: OrganizationMemberResponseType) => item.id !== id)
      );

      return {
        pageParams,
        pages: updatePages,
      };
    });

  const addToCache = (item: OrganizationMemberResponseType) =>
    queryClient.setQueryData(key, ({ pages, pageParams }) => {
      if (pages == null || pages.length === 0) {
        return {
          pageParams,
          pages: [[item]],
        };
      }

      const updatedPages = [...pages];
      updatedPages[updatedPages.length - 1] = [...updatedPages[updatedPages.length - 1], item];

      return {
        pageParams,
        pages: updatedPages,
      };
    });

  const updateInCache = (id: number, payload: OrganizationMemberResponseType) =>
    queryClient.setQueryData(key, ({ pages, pageParams }) => {
      const updatePages = pages.map((page) =>
        page.map((item: OrganizationMemberResponseType) => {
          if (item.id === id) return payload;
          return item;
        })
      );

      return {
        pageParams,
        pages: updatePages,
      };
    });

  const evictCacheByKey = useCallback(() => {
    queryClient.removeQueries(key);
  }, [queryClient, key]);

  return {
    items: data?.pages?.flat(),
    refetch,
    isLoading,
    isLoadingNextPage: isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    removeItem: removeFromCacheById,
    addItem: addToCache,
    updateItem: updateInCache,
    evictByKey: evictCacheByKey,
  };
};
