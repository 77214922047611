import React from 'react';

export default ({ color = '#fff', size = 15 }: { color?: string; size?: number }): JSX.Element => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 15 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.3296 0.295798C14.2366 0.20207 14.126 0.127675 14.0042 0.0769067C13.8823 0.026138 13.7516 0 13.6196 0C13.4876 0 13.3569 0.026138 13.235 0.0769067C13.1131 0.127675 13.0025 0.20207 12.9096 0.295798L5.45958 7.7558L2.32958 4.6158C2.23306 4.52256 2.11912 4.44925 1.99426 4.40004C1.86941 4.35084 1.73608 4.32671 1.6019 4.32903C1.46772 4.33135 1.33531 4.36008 1.21223 4.41357C1.08915 4.46706 0.977817 4.54428 0.884579 4.6408C0.79134 4.73732 0.718026 4.85126 0.668822 4.97612C0.619618 5.10097 0.595488 5.2343 0.597809 5.36848C0.600131 5.50266 0.628858 5.63507 0.682352 5.75814C0.735845 5.88122 0.813057 5.99256 0.909579 6.0858L4.74958 9.9258C4.84254 10.0195 4.95314 10.0939 5.075 10.1447C5.19686 10.1955 5.32757 10.2216 5.45958 10.2216C5.59159 10.2216 5.7223 10.1955 5.84416 10.1447C5.96601 10.0939 6.07662 10.0195 6.16958 9.9258L14.3296 1.7658C14.4311 1.67216 14.5121 1.5585 14.5675 1.432C14.6229 1.30551 14.6515 1.1689 14.6515 1.0308C14.6515 0.892697 14.6229 0.756091 14.5675 0.629592C14.5121 0.503092 14.4311 0.389441 14.3296 0.295798Z"
      fill={color}
    />
  </svg>
);
