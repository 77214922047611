import React from 'react';

import { RenderConfigurableSidebarLink } from '@uikit/ConfigurableSidebar/_components/ConfigurableSidebarLinks';

type SidebarItemRenderPropsType = {
  pathname: string;
};

export default [
  {
    pageKey: 'profile',
    RenderLink: ({ pathname }: SidebarItemRenderPropsType): JSX.Element => {
      return (
        <RenderConfigurableSidebarLink
          title="Profile"
          icon="profile"
          url="/settings/profile"
          isActive={pathname === '/settings/profile'}
        />
      );
    },
  },
  {
    pageKey: 'billing',
    RenderLink: ({ pathname }: SidebarItemRenderPropsType): JSX.Element => {
      return (
        <RenderConfigurableSidebarLink
          title="Billing"
          icon="billing"
          url="/settings/billing"
          isActive={pathname === '/settings/billing'}
        />
      );
    },
  },
  {
    pageKey: 'workspaces',
    RenderLink: ({ pathname }: SidebarItemRenderPropsType): JSX.Element => {
      return (
        <RenderConfigurableSidebarLink
          title="Workspaces"
          icon="workspace"
          url="/settings/workspaces"
          isActive={pathname === '/settings/workspaces'}
        />
      );
    },
  },
  {
    pageKey: 'unsubscribedList',
    RenderLink: ({ pathname }: SidebarItemRenderPropsType): JSX.Element => {
      return (
        <RenderConfigurableSidebarLink
          title="Unsubscribed"
          icon="banWhite"
          url="/settings/unsubscribed"
          isActive={pathname === '/settings/unsubscribed'}
        />
      );
    },
  },
  {
    pageKey: 'allMembers',
    RenderLink: ({ pathname }: SidebarItemRenderPropsType): JSX.Element => {
      return (
        <RenderConfigurableSidebarLink
          title="Team members"
          icon="member"
          url="/settings/all-members"
          isActive={pathname === '/settings/all-members'}
        />
      );
    },
  },
  {
    pageKey: 'allEmails',
    RenderLink: ({ pathname }: SidebarItemRenderPropsType): JSX.Element => {
      return (
        <RenderConfigurableSidebarLink
          title="Email accounts"
          icon="folder"
          url="/settings/all-emails"
          isActive={pathname === '/settings/all-emails'}
        />
      );
    },
  },
  {
    pageKey: 'schedules',
    RenderLink: ({ pathname }: SidebarItemRenderPropsType): JSX.Element => {
      return (
        <RenderConfigurableSidebarLink
          title="Schedules"
          icon="schedules"
          url="/settings/schedules"
          isActive={pathname === '/settings/schedules'}
        />
      );
    },
  },
  {
    pageKey: 'templates',
    RenderLink: ({ pathname }: SidebarItemRenderPropsType): JSX.Element => {
      return (
        <RenderConfigurableSidebarLink
          title="Templates"
          icon="bookmark"
          url="/settings/templates"
          isActive={pathname.indexOf('/settings/templates') !== -1}
        />
      );
    },
  },
  {
    pageKey: 'integrations',
    RenderLink: ({ pathname }: SidebarItemRenderPropsType): JSX.Element => {
      return (
        <RenderConfigurableSidebarLink
          title="Integrations"
          icon="socketPlug"
          url="/settings/integrations"
          isActive={pathname.indexOf('/settings/integrations') !== -1}
        />
      );
    },
  },
  {
    pageKey: 'referral',
    RenderLink: ({ pathname }: SidebarItemRenderPropsType): JSX.Element => {
      return (
        <RenderConfigurableSidebarLink
          title="Earn credits"
          icon="wallet"
          url="/settings/referral"
          isActive={pathname.indexOf('/settings/referral') !== -1}
        />
      );
    },
  },
  {
    pageKey: 'customFields',
    RenderLink: ({ pathname }: SidebarItemRenderPropsType): JSX.Element => {
      return (
        <RenderConfigurableSidebarLink
          title="Custom fields"
          icon="cubesIcon"
          url="/settings/custom-fields"
          isActive={pathname.indexOf('/settings/custom-fields') !== -1}
        />
      );
    },
  },
];
