import React from 'react';

export default function ({ color = '#8F9199', size = 12, backgroundIcon = '#E8EAED' }: { color?: string; size?: number, backgroundIcon?: string }) {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="17" cy="17" r="17" fill={backgroundIcon} />
      <path
        d="M11 15.3625H23"
        stroke={color}
        strokeWidth="1.4"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M21.9091 12.0908H12.0909C11.4884 12.0908 11 12.5792 11 13.1817V21.909C11 22.5115 11.4884 22.9999 12.0909 22.9999H21.9091C22.5116 22.9999 23 22.5115 23 21.909V13.1817C23 12.5792 22.5116 12.0908 21.9091 12.0908Z"
        stroke={color}
        strokeWidth="1.4"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M13.7271 11L13.7266 13.1818"
        stroke={color}
        strokeWidth="1.4"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M20.272 11L20.2715 13.1818"
        stroke={color}
        strokeWidth="1.4"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
}
