import React from 'react';

import './VideoFrame.scss';

const VideoFrame = ({
  wrapperClassName = 'video-frame-wrapper',
  width = 640,
  height = 360,
  link = 'https://player.vimeo.com/video/894951620',
}) => {
  return (
    <div className={wrapperClassName}>
      {/* <iframe */}
      {/*  className="video-frame" */}
      {/*  width="400" */}
      {/*  height="225" */}
      {/*  src="https://www.youtube.com/embed/8yOhUZtXAzs" */}
      {/*  title="YouTube video player" */}
      {/*  frameBorder="0" */}
      {/*  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" */}
      {/*  allowFullScreen */}
      {/* /> */}
      <iframe
        src={`${link}?h=2eb879dd2e&amp;badge=1&amp;autopause=0&amp;controls=true&amp;player_id=0&amp;app_id=58479`}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
        style={{ position: 'absolute', top: 0, left: 0, width, height }}
        title="Respona 1.1 Demo"
      />
      <script src="https://player.vimeo.com/api/player.js" />
    </div>
  );
};

export default VideoFrame;
