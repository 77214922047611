import React from 'react';

export default ({ size = 24, color = '#8F9199' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 1H10C8.89543 1 8 1.89543 8 3V17C8 18.1046 8.89543 19 10 19H14C15.1046 19 16 18.1046 16 17V3C16 1.89543 15.1046 1 14 1Z"
      stroke="#251FB6"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.05051 14.9498C2.40042 14.2998 1.88474 13.5281 1.53291 12.6788C1.18108 11.8294 1 10.9191 1 9.9998C1 9.08049 1.18108 8.17018 1.53291 7.32086C1.88474 6.47153 2.40042 5.69982 3.05051 5.0498"
      stroke="#251FB6"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.9512 14.9498C21.6013 14.2998 22.1169 13.5281 22.4688 12.6788C22.8206 11.8294 23.0017 10.9191 23.0017 9.9998C23.0017 9.08049 22.8206 8.17018 22.4688 7.32086C22.1169 6.47153 21.6013 5.69982 20.9512 5.0498"
      stroke="#251FB6"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 7H10"
      stroke="#251FB6"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 7H16"
      stroke="#251FB6"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 13H10"
      stroke="#251FB6"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 13H16"
      stroke="#251FB6"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 19V23"
      stroke="#251FB6"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 19V23"
      stroke="#251FB6"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
