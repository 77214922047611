import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useInfiniteQuery } from 'react-query';

import { InvoiceShortType } from '@ts/billing.types';

import useIntersectionObserver from '@hooks/useIntersectionObserver';
import useNonInitialEffect from '@hooks/useNonInitialEffect';

import { getCurrentWorkspaceId } from '@redux/selectors/workspaces.selectors';

import PageHeader from '@components/PageHeader/PageHeader';
import HeaderSearchInput from '@components/HeaderSearchInput/HeaderSearchInput';
import BackArrowButton from '@components/BackArrowButton/BackArrowButton';

import ConfigurableTable from '@uikit/ConfigurableTable/ConfigurableTable';
import Loader from '@uikit/Loader/Loader';

import { getInvoicesApi } from '@api/billing.api';

import tableConfig, { RenderRow } from './tableConfig';

import './BillingHistory.scss';
import { useRefCallback } from '@helpers/refHelpers';

const CURRENT_PAGE_LIMIT = Math.ceil(window.innerHeight / 83);

export default function (): JSX.Element {
  const [loadingRef, setLoadingRef, ready] = useRefCallback<HTMLDivElement>();

  const [searchString, changeSearchString] = useState('');

  const curWorkspaceId = useSelector(getCurrentWorkspaceId);

  const {
    data = [],
    isFetching,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery<InvoiceShortType[]>(
    ['invoices-list'],
    ({ pageParam = 0 }) => getInvoicesApi(pageParam, CURRENT_PAGE_LIMIT, searchString),
    {
      getNextPageParam: (lastPage: InvoiceShortType[], allPages) => {
        if (lastPage.length < CURRENT_PAGE_LIMIT) return false;

        return allPages.length;
      },
      enabled: !!curWorkspaceId,
      retry: 1,
      refetchOnWindowFocus: false,
      refetchInterval: 30 * 60 * 1000,
    }
  );

  useEffect(() => {
    if (curWorkspaceId) {
      refetch();
    }
  }, [curWorkspaceId]);

  useNonInitialEffect(() => {
    refetch();
  }, [searchString]);

  useIntersectionObserver(loadingRef, () => fetchNextPage(), [ready]);

  // @ts-ignore
  const invoices = data?.pages?.flat();

  const renderLoader = () => {
    if (isFetchingNextPage) {
      return <Loader isLoading withTopMargin />;
    }

    if (invoices?.length && invoices[0].totalItems > invoices.length && hasNextPage) {
      return (
        <div ref={setLoadingRef} style={{ marginBottom: '30px' }}>
          <Loader isLoading withTopMargin />
        </div>
      );
    }

    return null;
  };

  if (!data) {
    return null;
  }

  return (
    <div className="billing-history">
      <PageHeader
        renderHeaderTitle={() => (
          <div className="billing-history__header-title">
            <BackArrowButton />
            Billing history
          </div>
        )}
      >
        <div className="billing-history__header-actions">
          <HeaderSearchInput searchQuery={searchString} onUpdateSearchQuery={changeSearchString} />
        </div>
      </PageHeader>
      <Loader isLoading={isLoading && !isFetchingNextPage} withTopMargin>
        <div className="billing-history__table">
          <ConfigurableTable data={invoices} config={tableConfig} RenderRow={RenderRow} />
        </div>

        {renderLoader()}
      </Loader>
    </div>
  );
}
