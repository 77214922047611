type TimeZoneType = {
  id: number;
  name: string;
  value: string;
};
type TransformedTimeZoneType = TimeZoneType & { value: number; label: string; originValue: string };

const timezones: TimeZoneType[] = [
  {
    id: 1,
    name: '(UTC-12:00) International Date Line West',
    value: 'UTC-12',
  },
  {
    id: 2,
    name: '(UTC-11:00) Coordinated Universal Time-11',
    value: 'UTC−11',
  },
  {
    id: 3,
    name: '(UTC-10:00) Aleutian Islands',
    value: 'UTC−10',
  },
  {
    id: 4,
    name: '(UTC-10:00) Hawaii',
    value: 'UTC−10',
  },
  {
    id: 5,
    name: '(UTC-09:00) Alaska',
    value: 'UTC−09',
  },
  {
    id: 6,
    name: '(UTC-09:00) Coordinated Universal Time-09',
    value: 'UTC−09',
  },
  {
    id: 7,
    name: '(UTC-08:00) Pacific Time (US & Canada)',
    value: 'UTC−08',
  },
  {
    id: 8,
    name: '(UTC-08:00) Baja California',
    value: 'UTC−08',
  },
  {
    id: 9,
    name: '(UTC-08:00) Coordinated Universal Time-08',
    value: 'UTC−08',
  },
  {
    id: 10,
    name: '(UTC-07:00) Arizona',
    value: 'UTC−07',
  },
  {
    id: 11,
    name: '(UTC-07:00) Chihuahua, La Paz, Mazatlan',
    value: 'UTC−07',
  },
  {
    id: 12,
    name: '(UTC-07:00) Mountain Time (US & Canada)',
    value: 'UTC−07',
  },
  {
    id: 13,
    name: '(UTC-06:00) Central America',
    value: 'UTC−06',
  },
  {
    id: 14,
    name: '(UTC-06:00) Central Time (US & Canada)',
    value: 'UTC−06',
  },
  {
    id: 15,
    name: '(UTC-06:00) Easter Island',
    value: 'UTC−06',
  },
  {
    id: 16,
    name: '(UTC-06:00) Guadalajara, Mexico City, Monterrey',
    value: 'UTC−06',
  },
  {
    id: 17,
    name: '(UTC-06:00) Saskatchewan',
    value: 'UTC−06',
  },
  {
    id: 18,
    name: '(UTC-05:00) Bogota, Lima, Quito, Rio Branco',
    value: 'UTC−05',
  },
  {
    id: 19,
    name: '(UTC-05:00) Chetumal',
    value: 'UTC−05',
  },
  {
    id: 20,
    name: '(UTC-05:00) Eastern Standard Time (US & Canada)',
    value: 'UTC−05',
  },
  {
    id: 21,
    name: '(UTC-05:00) Haiti',
    value: 'UTC−05',
  },
  {
    id: 22,
    name: '(UTC-05:00) Havana',
    value: 'UTC−05',
  },
  {
    id: 23,
    name: '(UTC-05:00) Indiana (East)',
    value: 'UTC−05',
  },
  {
    id: 24,
    name: '(UTC-04:00) Atlantic Time (Canada)',
    value: 'UTC−04',
  },
  {
    id: 25,
    name: '(UTC-04:00) Caracas',
    value: 'UTC−04',
  },
  {
    id: 26,
    name: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
    value: 'UTC−04',
  },
  {
    id: 27,
    name: '(UTC-04:00) Santiago',
    value: 'UTC−04',
  },
  {
    id: 28,
    name: '(UTC-03:00) Araguaina',
    value: 'UTC−03',
  },
  {
    id: 29,
    name: '(UTC-03:00) Brasilia',
    value: 'UTC−03',
  },
  {
    id: 30,
    name: '(UTC-03:00) Cayenne, Fortaleza',
    value: 'UTC−03',
  },
  {
    id: 31,
    name: '(UTC-03:00) City of Buenos Aires',
    value: 'UTC−03',
  },
  {
    id: 32,
    name: '(UTC-03:00) Greenland',
    value: 'UTC−03',
  },
  {
    id: 33,
    name: '(UTC-03:00) Salvador',
    value: 'UTC−03',
  },
  {
    id: 34,
    name: '(UTC-02:00) Coordinated Universal Time-02',
    value: 'UTC−02',
  },
  {
    id: 35,
    name: '(UTC-02:00) Mid-Atlantic - Old',
    value: 'UTC−02',
  },
  {
    id: 36,
    name: '(UTC-01:00) Azores',
    value: 'UTC−01',
  },
  {
    id: 37,
    name: '(UTC-01:00) Cabo Verde Is.',
    value: 'UTC−01',
  },
  {
    id: 38,
    name: '(UTC) Coordinated Universal Time',
    value: 'UTC',
  },
  {
    id: 39,
    name: '(UTC+00:00) Dublin, Edinburgh, Lisbon, London',
    value: 'UTC',
  },
  {
    id: 40,
    name: '(UTC+00:00) Monrovia, Reykjavik',
    value: 'UTC',
  },
  {
    id: 41,
    name: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
    value: 'UTC+01',
  },
  {
    id: 42,
    name: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
    value: 'UTC+01',
  },
  {
    id: 43,
    name: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris',
    value: 'UTC+01',
  },
  {
    id: 44,
    name: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
    value: 'UTC+01',
  },
  {
    id: 45,
    name: '(UTC+01:00) West Central Africa',
    value: 'UTC+01',
  },
  {
    id: 46,
    name: '(UTC+02:00) Amman',
    value: 'UTC+02',
  },
  {
    id: 47,
    name: '(UTC+02:00) Athens, Bucharest',
    value: 'UTC+02',
  },
  {
    id: 48,
    name: '(UTC+02:00) Beirut',
    value: 'UTC+02',
  },
  {
    id: 49,
    name: '(UTC+02:00) Cairo',
    value: 'UTC+02',
  },
  {
    id: 50,
    name: '(UTC+02:00) Damascus',
    value: 'UTC+02',
  },
  {
    id: 51,
    name: '(UTC+02:00) Gaza, Hebron',
    value: 'UTC+02',
  },
  {
    id: 52,
    name: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
    value: 'UTC+02',
  },
  {
    id: 53,
    name: '(UTC+02:00) Jerusalem',
    value: 'UTC+02',
  },
  {
    id: 54,
    name: '(UTC+02:00) Kaliningrad',
    value: 'UTC+02',
  },
  {
    id: 55,
    name: '(UTC+02:00) Khartoum',
    value: 'UTC+02',
  },
  {
    id: 56,
    name: '(UTC+02:00) Tripoli',
    value: 'UTC+02',
  },
  {
    id: 57,
    name: '(UTC+02:00) Windhoek',
    value: 'UTC+02',
  },
  {
    id: 58,
    name: '(UTC+03:00) Baghdad',
    value: 'UTC+03',
  },
  {
    id: 59,
    name: '(UTC+03:00) Istanbul',
    value: 'UTC+03',
  },
  {
    id: 60,
    name: '(UTC+03:00) Kuwait, Riyadh',
    value: 'UTC+03',
  },
  {
    id: 61,
    name: '(UTC+03:00) Minsk',
    value: 'UTC+03',
  },
  {
    id: 62,
    name: '(UTC+03:00) Moscow, St. Petersburg',
    value: 'UTC+03',
  },
  {
    id: 63,
    name: '(UTC+03:00) Nairobi',
    value: 'UTC+03',
  },
  {
    id: 64,
    name: '(UTC+03:30) Tehran',
    value: 'UTC+03',
  },
  {
    id: 65,
    name: '(UTC+04:00) Abu Dhabi, Muscat',
    value: 'UTC+04',
  },
  {
    id: 66,
    name: '(UTC+04:00) Baku',
    value: 'UTC+04',
  },
  {
    id: 67,
    name: '(UTC+04:00) Port Louis',
    value: 'UTC+04',
  },
  {
    id: 68,
    name: '(UTC+04:00) Tbilisi',
    value: 'UTC+04',
  },
  {
    id: 69,
    name: '(UTC+04:00) Yerevan',
    value: 'UTC+04',
  },
  {
    id: 70,
    name: '(UTC+04:30) Kabul',
    value: 'UTC+04',
  },
  {
    id: 71,
    name: '(UTC+05:00) Ashgabat, Tashkent',
    value: 'UTC+05',
  },
  {
    id: 72,
    name: '(UTC+05:00) Ekaterinburg',
    value: 'UTC+05',
  },
  {
    id: 73,
    name: '(UTC+05:00) Islamabad, Karachi',
    value: 'UTC+05',
  },
  {
    id: 74,
    name: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',
    value: 'UTC+05',
  },
  {
    id: 75,
    name: '(UTC+05:30) Sri Jayawardenepura',
    value: 'UTC+05',
  },
  {
    id: 76,
    name: '(UTC+05:45) Kathmandu',
    value: 'UTC+06',
  },
  {
    id: 77,
    name: '(UTC+06:00) Astana',
    value: 'UTC+06',
  },
  {
    id: 78,
    name: '(UTC+06:00) Dhaka',
    value: 'UTC+06',
  },
  {
    id: 79,
    name: '(UTC+06:00) Omsk',
    value: 'UTC+06',
  },
  {
    id: 80,
    name: '(UTC+06:30) Yangon (Rangoon)',
    value: 'UTC+06',
  },
  {
    id: 81,
    name: '(UTC+07:00) Bangkok, Hanoi, Jakarta',
    value: 'UTC+07',
  },
  {
    id: 82,
    name: '(UTC+07:00) Barnaul, Gorno-Altaysk',
    value: 'UTC+07',
  },
  {
    id: 83,
    name: '(UTC+07:00) Hovd',
    value: 'UTC+07',
  },
  {
    id: 84,
    name: '(UTC+07:00) Krasnoyarsk',
    value: 'UTC+07',
  },
  {
    id: 85,
    name: '(UTC+07:00) Novosibirsk',
    value: 'UTC+07',
  },
  {
    id: 86,
    name: '(UTC+07:00) Tomsk',
    value: 'UTC+07',
  },
  {
    id: 87,
    name: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
    value: 'UTC+08',
  },
  {
    id: 88,
    name: '(UTC+08:00) Irkutsk',
    value: 'UTC+08',
  },
  {
    id: 89,
    name: '(UTC+08:00) Kuala Lumpur, Singapore',
    value: 'UTC+08',
  },
  {
    id: 90,
    name: '(UTC+08:00) Perth',
    value: 'UTC+08',
  },
  {
    id: 91,
    name: '(UTC+08:00) Taipei',
    value: 'UTC+08',
  },
  {
    id: 92,
    name: '(UTC+08:00) Ulaanbaatar',
    value: 'UTC+08',
  },
  {
    id: 93,
    name: '(UTC+08:45) Eucla',
    value: 'UTC+09',
  },
  {
    id: 94,
    name: '(UTC+09:00) Chita',
    value: 'UTC+09',
  },
  {
    id: 95,
    name: '(UTC+09:00) Osaka, Sapporo, Tokyo',
    value: 'UTC+09',
  },
  {
    id: 96,
    name: '(UTC+09:00) Pyongyang',
    value: 'UTC+09',
  },
  {
    id: 97,
    name: '(UTC+09:00) Seoul',
    value: 'UTC+09',
  },
  {
    id: 98,
    name: '(UTC+09:00) Yakutsk',
    value: 'UTC+09',
  },
  {
    id: 99,
    name: '(UTC+09:30) Adelaide',
    value: 'UTC+09',
  },
  {
    id: 100,
    name: '(UTC+09:30) Darwin',
    value: 'UTC+09',
  },
  {
    id: 101,
    name: '(UTC+10:00) Brisbane',
    value: 'UTC+10',
  },
  {
    id: 102,
    name: '(UTC+10:00) Canberra, Melbourne, Sydney',
    value: 'UTC+10',
  },
  {
    id: 103,
    name: '(UTC+10:00) Guam, Port Moresby',
    value: 'UTC+10',
  },
  {
    id: 104,
    name: '(UTC+10:00) Hobart',
    value: 'UTC+10',
  },
  {
    id: 105,
    name: '(UTC+10:00) Vladivostok',
    value: 'UTC+10',
  },
  {
    id: 106,
    name: '(UTC+10:30) Lord Howe Island',
    value: 'UTC+10',
  },
  {
    id: 107,
    name: '(UTC+11:00) Bougainville Island',
    value: 'UTC+11',
  },
  {
    id: 108,
    name: '(UTC+11:00) Chokurdakh',
    value: 'UTC+11',
  },
  {
    id: 109,
    name: '(UTC+11:00) Magadan',
    value: 'UTC+11',
  },
  {
    id: 110,
    name: '(UTC+11:00) Norfolk Island',
    value: 'UTC+11',
  },
  {
    id: 111,
    name: '(UTC+11:00) Sakhalin',
    value: 'UTC+11',
  },
  {
    id: 112,
    name: '(UTC+11:00) Solomon Is., New Caledonia',
    value: 'UTC+11',
  },
  {
    id: 113,
    name: '(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky',
    value: 'UTC+12',
  },
  {
    id: 114,
    name: '(UTC+12:00) Auckland, Wellington',
    value: 'UTC+12',
  },
  {
    id: 115,
    name: '(UTC+12:00) Coordinated Universal Time+12',
    value: 'UTC+12',
  },
  {
    id: 116,
    name: '(UTC+12:00) Fiji',
    value: 'UTC+12',
  },
  {
    id: 117,
    name: '(UTC+12:00) Petropavlovsk-Kamchatsky - Old',
    value: 'UTC+12',
  },
  {
    id: 118,
    name: '(UTC+12:45) Chatham Islands',
    value: 'UTC+13',
  },
  {
    id: 119,
    name: '(UTC+13:00) Coordinated Universal Time+13',
    value: 'UTC+13',
  },
  {
    id: 120,
    name: "(UTC+13:00) Nuku'alofa",
    value: 'UTC+13',
  },
  {
    id: 121,
    name: '(UTC+13:00) Samoa',
    value: 'UTC+13',
  },
  {
    id: 122,
    name: '(UTC+14:00) Kiritimati Island',
    value: 'UTC+14',
  },
];

export const getTimezoneById = (timezoneId: number): TimeZoneType =>
  timezones.find(({ id }) => id === timezoneId);

export const getTimezonesOptions = (): {
  name: string;
  id: number;
  label: string;
  value: number;
  originValue: string;
}[] =>
  timezones.map((timezone) => ({
    ...timezone,
    value: timezone.id,
    label: timezone.name,
    originValue: timezone.value,
  }));
export const getDefaultTimezone = (): TimeZoneType => ({
  id: 20,
  name: '(UTC-05:00) Eastern Standard Time (US & Canada)',
  value: 'UTC−05',
});

export default timezones;
