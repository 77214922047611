import React, { useRef } from "react";

import { nanoid } from 'nanoid';

const EditProfileImagePenIcon = () => {
  const ref = useRef(nanoid());
  return (
    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.0371094"
        y="0.245605"
        width="28.5837"
        height="28.5837"
        rx="14.2919"
        fill="#221CB6"
      />
      <g clipPath={`url(#clip${ref.current})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.4967 9.27986C18.3538 9.27986 18.2122 9.30802 18.0801 9.36274C17.948 9.41745 17.8279 9.49766 17.7268 9.59876L9.79948 17.5261L9.22205 19.6434L11.3393 19.0659L19.2666 11.1386C19.3678 11.0375 19.448 10.9174 19.5027 10.7853C19.5574 10.6532 19.5856 10.5117 19.5856 10.3687C19.5856 10.2257 19.5574 10.0841 19.5027 9.952C19.448 9.8199 19.3678 9.69987 19.2666 9.59876C19.1655 9.49766 19.0455 9.41745 18.9134 9.36274C18.7813 9.30802 18.6397 9.27986 18.4967 9.27986ZM17.6243 8.26241C17.9009 8.14784 18.1973 8.08887 18.4967 8.08887C18.7961 8.08887 19.0926 8.14784 19.3692 8.26241C19.6458 8.37698 19.8971 8.54491 20.1088 8.75661C20.3205 8.96831 20.4884 9.21963 20.603 9.49623C20.7176 9.77283 20.7765 10.0693 20.7765 10.3687C20.7765 10.6681 20.7176 10.9645 20.603 11.2411C20.4884 11.5177 20.3205 11.769 20.1088 11.9807L12.0696 20.0199C11.9964 20.0932 11.9052 20.1461 11.8052 20.1733L8.53002 21.0666C8.32386 21.1228 8.10337 21.0643 7.95226 20.9131C7.80116 20.762 7.7426 20.5416 7.79883 20.3354L8.69207 17.0602C8.71934 16.9602 8.77222 16.8691 8.8455 16.7958L16.8847 8.75661C17.0964 8.54491 17.3477 8.37698 17.6243 8.26241Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id={`url(#clip${ref.current})`}>
          <rect
            width="14.2919"
            height="14.2919"
            fill="white"
            transform="translate(7.18164 7.39111)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EditProfileImagePenIcon;
