import { contentSearchTypeMap } from '@mappers/contentSearch.mappers';
import { KeywordSearchSource } from 'respona_api/generated/automation-content_pb';

export const isPodcastType = (type) =>
  type === contentSearchTypeMap.podcasts || type === contentSearchTypeMap.episodes;

function transformToAscii(input: string): string {
  // Step 1: Use encodeURIComponent to encode the entire input string.
  const encodedString = encodeURIComponent(input);

  // Step 2: Replace special characters that are not encoded by encodeURIComponent.
  // According to the application/x-www-form-urlencoded spec, we need to manually
  // encode spaces as '%20', and replace '%20' with '+'.
  return encodedString.replace(/%20/g, '+');
}

export const getQueryLinkForSource = (query: string, source: number | undefined): string => {
  if (source === undefined) {
    return query;
  }

  switch (source) {
    case KeywordSearchSource.GOOGLE_KEYWORD_SEARCH:
      return `https://www.google.com/search?q=${transformToAscii(query)}`;
    case KeywordSearchSource.BING_KEYWORD_SEARCH:
      return `https://www.bing.com/search?q=${transformToAscii(query)}`;
    case KeywordSearchSource.BING_NEWS_KEYWORD_SEARCH:
      return `https://www.bing.com/news/search?q=${query}`;
    case KeywordSearchSource.GOOGLE_NEWS_KEYWORD_SEARCH:
      return `https://news.google.com/search?q=${query}`;
    default:
      return `https://www.google.com/search?q=${query}`;
  }
};
