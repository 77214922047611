import createAction from '../utils/createAction';

export const SET_ARTICLE_SUMMARY = 'SET_ARTICLE_SUMMARY';
export const SET_AUDIO_INFO = 'SET_AUDIO_INFO';
export const SET_ARTICLE_SUMMARY_LS = 'SET_ARTICLE_SUMMARY_LS';
export const SET_AUDIO_INFO_LS = 'SET_AUDIO_INFO_LS';
export const CREATE_ARTICLE_SUMMARY = 'CREATE_ARTICLE_SUMMARY';

export const setArticleSummary = createAction<{
  opportunityId: number;
  summary?: any;
}>(SET_ARTICLE_SUMMARY);

export const setAudioInfo = createAction<{
  opportunityId: number;
  data?: any;
}>(SET_AUDIO_INFO);

export const setArticleSummaryLs = createAction<{ opportunityId: number; loadingStatus: string }>(
  SET_ARTICLE_SUMMARY_LS
);

export const setAudioInfoLs = createAction<{ opportunityId: number; loadingStatus: string }>(
  SET_AUDIO_INFO_LS
);

export const createArticleSummary = createAction<{ opportunityId: number; isPodcast: boolean }>(
  CREATE_ARTICLE_SUMMARY
);
