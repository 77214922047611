/* eslint-disable react/no-array-index-key */
import React from 'react';

import cn from 'class-names';
import { nanoid } from 'nanoid';

import { getApproximateTimePeriod } from '@utils/getFormattedPastTime';

import './InboxThreadsList.scss';
import { useRefCallback } from '@helpers/refHelpers';
import useIntersectionObserver from '@hooks/useIntersectionObserver';
import Loader from '@uikit/Loader/Loader';

const InboxThreadsList = ({
  threadList,
  activeIndex,
  onChangeActiveThread,
  fetchNextPage,
  isFetchingNextPage,
  hasNextPage,
}) => {
  const getThreatItemCn = (index) =>
    cn('inbox-threads-list-item', {
      'inbox-threads-list-item--active': index === activeIndex,
    });

  const [loadingRef, setLoadingRef, ready] = useRefCallback<HTMLDivElement>();

  useIntersectionObserver(loadingRef, () => fetchNextPage(), [ready]);

  const renderLoader = () => {
    if (isFetchingNextPage) {
      return <Loader isLoading withTopMargin />;
    }

    if (threadList.length && threadList[0].totalItems > threadList.length && hasNextPage) {
      return (
        <div ref={setLoadingRef} style={{ marginBottom: '20px' }}>
          <Loader isLoading withTopMargin />
        </div>
      );
    }
    return null;
  };

  return (
    <div className="inbox-threads-list">
      {threadList.map((thread, index) => (
        <div
          key={nanoid()}
          className={getThreatItemCn(index)}
          onClick={() => onChangeActiveThread(index)}
          role="button"
          tabIndex={0}
        >
          <div className="inbox-threads-list-item__header">
            <div className="inbox-threads-list-item__from-name">
              {thread.name || '<Without name>'}
            </div>
            <div className="inbox-threads-list-item__last-activity-time">
              {getApproximateTimePeriod(thread.time)}
            </div>
          </div>
          {thread.subject ? (
            <div
              className="inbox-threads-list-item__subject"
              dangerouslySetInnerHTML={{ __html: thread.subject }}
            />
          ) : (
            <div className="inbox-threads-list-item__subject">{'<Empty subject>'}</div>
          )}
        </div>
      ))}
      {renderLoader()}
    </div>
  );
};

export default InboxThreadsList;
