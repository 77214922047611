import FileStorageService from '../../../respona_api/generated/file-storage_pb_service';

const service = (function () {
  let instance = null;

  return {
    getInstance: (): FileStorageService.FileStorageServiceClient => {
      if (!instance) {
        // @ts-ignore
        instance = new FileStorageService.FileStorageServiceClient(API_HOST);
      }

      return instance;
    },
  };
})();

export default service;
