// source: haro.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
goog.exportSymbol('proto.haro.HaroBookmarkRequest', null, global);
goog.exportSymbol('proto.haro.HaroCategoriesContainer', null, global);
goog.exportSymbol('proto.haro.HaroCategoriesContainer.HaroCategories', null, global);
goog.exportSymbol('proto.haro.HaroFilter', null, global);
goog.exportSymbol('proto.haro.HaroFilterShortResponse', null, global);
goog.exportSymbol('proto.haro.HaroMember', null, global);
goog.exportSymbol('proto.haro.HaroOpportunityRequest', null, global);
goog.exportSymbol('proto.haro.HaroOpportunityResponse', null, global);
goog.exportSymbol('proto.haro.HaroOpportunitySort', null, global);
goog.exportSymbol('proto.haro.HaroPitchedRequest', null, global);
goog.exportSymbol('proto.haro.HaroPitchedResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.haro.HaroOpportunityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.haro.HaroOpportunityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.haro.HaroOpportunityRequest.displayName = 'proto.haro.HaroOpportunityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.haro.HaroOpportunityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.haro.HaroOpportunityResponse.repeatedFields_, null);
};
goog.inherits(proto.haro.HaroOpportunityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.haro.HaroOpportunityResponse.displayName = 'proto.haro.HaroOpportunityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.haro.HaroFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.haro.HaroFilter.repeatedFields_, null);
};
goog.inherits(proto.haro.HaroFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.haro.HaroFilter.displayName = 'proto.haro.HaroFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.haro.HaroMember = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.haro.HaroMember, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.haro.HaroMember.displayName = 'proto.haro.HaroMember';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.haro.HaroFilterShortResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.haro.HaroFilterShortResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.haro.HaroFilterShortResponse.displayName = 'proto.haro.HaroFilterShortResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.haro.HaroCategoriesContainer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.haro.HaroCategoriesContainer.repeatedFields_, null);
};
goog.inherits(proto.haro.HaroCategoriesContainer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.haro.HaroCategoriesContainer.displayName = 'proto.haro.HaroCategoriesContainer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.haro.HaroCategoriesContainer.HaroCategories = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.haro.HaroCategoriesContainer.HaroCategories, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.haro.HaroCategoriesContainer.HaroCategories.displayName = 'proto.haro.HaroCategoriesContainer.HaroCategories';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.haro.HaroBookmarkRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.haro.HaroBookmarkRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.haro.HaroBookmarkRequest.displayName = 'proto.haro.HaroBookmarkRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.haro.HaroPitchedRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.haro.HaroPitchedRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.haro.HaroPitchedRequest.displayName = 'proto.haro.HaroPitchedRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.haro.HaroPitchedResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.haro.HaroPitchedResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.haro.HaroPitchedResponse.displayName = 'proto.haro.HaroPitchedResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.haro.HaroOpportunityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.haro.HaroOpportunityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.haro.HaroOpportunityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.haro.HaroOpportunityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: jspb.Message.getFieldWithDefault(msg, 1, ""),
    optionalCategory: jspb.Message.getFieldWithDefault(msg, 2, ""),
    optionalFilterId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    sortBy: jspb.Message.getFieldWithDefault(msg, 4, 0),
    sortOrderAsc: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    page: jspb.Message.getFieldWithDefault(msg, 6, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 7, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    ignoreExpiration: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.haro.HaroOpportunityRequest}
 */
proto.haro.HaroOpportunityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.haro.HaroOpportunityRequest;
  return proto.haro.HaroOpportunityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.haro.HaroOpportunityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.haro.HaroOpportunityRequest}
 */
proto.haro.HaroOpportunityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionalCategory(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOptionalFilterId(value);
      break;
    case 4:
      var value = /** @type {!proto.haro.HaroOpportunitySort} */ (reader.readEnum());
      msg.setSortBy(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSortOrderAsc(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIgnoreExpiration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.haro.HaroOpportunityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.haro.HaroOpportunityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.haro.HaroOpportunityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.haro.HaroOpportunityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOptionalCategory();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOptionalFilterId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getSortBy();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getSortOrderAsc();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getIgnoreExpiration();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional string query = 1;
 * @return {string}
 */
proto.haro.HaroOpportunityRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.haro.HaroOpportunityRequest} returns this
 */
proto.haro.HaroOpportunityRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string optional_category = 2;
 * @return {string}
 */
proto.haro.HaroOpportunityRequest.prototype.getOptionalCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.haro.HaroOpportunityRequest} returns this
 */
proto.haro.HaroOpportunityRequest.prototype.setOptionalCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 optional_filter_id = 3;
 * @return {number}
 */
proto.haro.HaroOpportunityRequest.prototype.getOptionalFilterId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.haro.HaroOpportunityRequest} returns this
 */
proto.haro.HaroOpportunityRequest.prototype.setOptionalFilterId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional HaroOpportunitySort sort_by = 4;
 * @return {!proto.haro.HaroOpportunitySort}
 */
proto.haro.HaroOpportunityRequest.prototype.getSortBy = function() {
  return /** @type {!proto.haro.HaroOpportunitySort} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.haro.HaroOpportunitySort} value
 * @return {!proto.haro.HaroOpportunityRequest} returns this
 */
proto.haro.HaroOpportunityRequest.prototype.setSortBy = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional bool sort_order_asc = 5;
 * @return {boolean}
 */
proto.haro.HaroOpportunityRequest.prototype.getSortOrderAsc = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.haro.HaroOpportunityRequest} returns this
 */
proto.haro.HaroOpportunityRequest.prototype.setSortOrderAsc = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional int32 page = 6;
 * @return {number}
 */
proto.haro.HaroOpportunityRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.haro.HaroOpportunityRequest} returns this
 */
proto.haro.HaroOpportunityRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 limit = 7;
 * @return {number}
 */
proto.haro.HaroOpportunityRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.haro.HaroOpportunityRequest} returns this
 */
proto.haro.HaroOpportunityRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 workspace_id = 8;
 * @return {number}
 */
proto.haro.HaroOpportunityRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.haro.HaroOpportunityRequest} returns this
 */
proto.haro.HaroOpportunityRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional bool ignore_expiration = 9;
 * @return {boolean}
 */
proto.haro.HaroOpportunityRequest.prototype.getIgnoreExpiration = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.haro.HaroOpportunityRequest} returns this
 */
proto.haro.HaroOpportunityRequest.prototype.setIgnoreExpiration = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.haro.HaroOpportunityResponse.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.haro.HaroOpportunityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.haro.HaroOpportunityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.haro.HaroOpportunityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.haro.HaroOpportunityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    summary: jspb.Message.getFieldWithDefault(msg, 2, ""),
    query: jspb.Message.getFieldWithDefault(msg, 3, ""),
    requirements: jspb.Message.getFieldWithDefault(msg, 4, ""),
    publication: jspb.Message.getFieldWithDefault(msg, 5, ""),
    domainRating: jspb.Message.getFieldWithDefault(msg, 6, 0),
    domainSpamScore: jspb.Message.getFieldWithDefault(msg, 7, 0),
    receivedTimestamp: jspb.Message.getFieldWithDefault(msg, 8, 0),
    deadlineTimestamp: jspb.Message.getFieldWithDefault(msg, 9, 0),
    categoriesList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    isBookmark: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    totalItems: jspb.Message.getFieldWithDefault(msg, 12, 0),
    authorName: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.haro.HaroOpportunityResponse}
 */
proto.haro.HaroOpportunityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.haro.HaroOpportunityResponse;
  return proto.haro.HaroOpportunityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.haro.HaroOpportunityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.haro.HaroOpportunityResponse}
 */
proto.haro.HaroOpportunityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSummary(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequirements(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublication(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDomainRating(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDomainSpamScore(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReceivedTimestamp(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeadlineTimestamp(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addCategories(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBookmark(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalItems(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthorName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.haro.HaroOpportunityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.haro.HaroOpportunityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.haro.HaroOpportunityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.haro.HaroOpportunityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSummary();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRequirements();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPublication();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDomainRating();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getDomainSpamScore();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getReceivedTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getDeadlineTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
  f = message.getIsBookmark();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getTotalItems();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getAuthorName();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.haro.HaroOpportunityResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.haro.HaroOpportunityResponse} returns this
 */
proto.haro.HaroOpportunityResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string summary = 2;
 * @return {string}
 */
proto.haro.HaroOpportunityResponse.prototype.getSummary = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.haro.HaroOpportunityResponse} returns this
 */
proto.haro.HaroOpportunityResponse.prototype.setSummary = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string query = 3;
 * @return {string}
 */
proto.haro.HaroOpportunityResponse.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.haro.HaroOpportunityResponse} returns this
 */
proto.haro.HaroOpportunityResponse.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string requirements = 4;
 * @return {string}
 */
proto.haro.HaroOpportunityResponse.prototype.getRequirements = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.haro.HaroOpportunityResponse} returns this
 */
proto.haro.HaroOpportunityResponse.prototype.setRequirements = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string publication = 5;
 * @return {string}
 */
proto.haro.HaroOpportunityResponse.prototype.getPublication = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.haro.HaroOpportunityResponse} returns this
 */
proto.haro.HaroOpportunityResponse.prototype.setPublication = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 domain_rating = 6;
 * @return {number}
 */
proto.haro.HaroOpportunityResponse.prototype.getDomainRating = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.haro.HaroOpportunityResponse} returns this
 */
proto.haro.HaroOpportunityResponse.prototype.setDomainRating = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 domain_spam_score = 7;
 * @return {number}
 */
proto.haro.HaroOpportunityResponse.prototype.getDomainSpamScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.haro.HaroOpportunityResponse} returns this
 */
proto.haro.HaroOpportunityResponse.prototype.setDomainSpamScore = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 received_timestamp = 8;
 * @return {number}
 */
proto.haro.HaroOpportunityResponse.prototype.getReceivedTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.haro.HaroOpportunityResponse} returns this
 */
proto.haro.HaroOpportunityResponse.prototype.setReceivedTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 deadline_timestamp = 9;
 * @return {number}
 */
proto.haro.HaroOpportunityResponse.prototype.getDeadlineTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.haro.HaroOpportunityResponse} returns this
 */
proto.haro.HaroOpportunityResponse.prototype.setDeadlineTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * repeated string categories = 10;
 * @return {!Array<string>}
 */
proto.haro.HaroOpportunityResponse.prototype.getCategoriesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.haro.HaroOpportunityResponse} returns this
 */
proto.haro.HaroOpportunityResponse.prototype.setCategoriesList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.haro.HaroOpportunityResponse} returns this
 */
proto.haro.HaroOpportunityResponse.prototype.addCategories = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.haro.HaroOpportunityResponse} returns this
 */
proto.haro.HaroOpportunityResponse.prototype.clearCategoriesList = function() {
  return this.setCategoriesList([]);
};


/**
 * optional bool is_bookmark = 11;
 * @return {boolean}
 */
proto.haro.HaroOpportunityResponse.prototype.getIsBookmark = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.haro.HaroOpportunityResponse} returns this
 */
proto.haro.HaroOpportunityResponse.prototype.setIsBookmark = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional int32 total_items = 12;
 * @return {number}
 */
proto.haro.HaroOpportunityResponse.prototype.getTotalItems = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.haro.HaroOpportunityResponse} returns this
 */
proto.haro.HaroOpportunityResponse.prototype.setTotalItems = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string author_name = 13;
 * @return {string}
 */
proto.haro.HaroOpportunityResponse.prototype.getAuthorName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.haro.HaroOpportunityResponse} returns this
 */
proto.haro.HaroOpportunityResponse.prototype.setAuthorName = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.haro.HaroFilter.repeatedFields_ = [4,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.haro.HaroFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.haro.HaroFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.haro.HaroFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.haro.HaroFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    mailboxAccountId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    usersForNotificationList: jspb.Message.toObjectList(msg.getUsersForNotificationList(),
    proto.haro.HaroMember.toObject, includeInstance),
    notifyAtHours: jspb.Message.getFieldWithDefault(msg, 5, 0),
    removeAnonymous: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    domainRatingMin: jspb.Message.getFieldWithDefault(msg, 7, 0),
    domainRatingMax: jspb.Message.getFieldWithDefault(msg, 8, 0),
    keywordsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    bioFullName: jspb.Message.getFieldWithDefault(msg, 10, ""),
    bioJobTitle: jspb.Message.getFieldWithDefault(msg, 11, ""),
    bioCompanyName: jspb.Message.getFieldWithDefault(msg, 12, ""),
    bioCompanyWebsite: jspb.Message.getFieldWithDefault(msg, 13, ""),
    bioHeadshot: jspb.Message.getFieldWithDefault(msg, 14, ""),
    bioLinkedInLink: jspb.Message.getFieldWithDefault(msg, 15, ""),
    bioTwitterLink: jspb.Message.getFieldWithDefault(msg, 16, ""),
    bioPhoneNumber: jspb.Message.getFieldWithDefault(msg, 17, ""),
    bioOnlineBio: jspb.Message.getFieldWithDefault(msg, 18, ""),
    bioPressKitLink: jspb.Message.getFieldWithDefault(msg, 19, ""),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 20, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.haro.HaroFilter}
 */
proto.haro.HaroFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.haro.HaroFilter;
  return proto.haro.HaroFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.haro.HaroFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.haro.HaroFilter}
 */
proto.haro.HaroFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMailboxAccountId(value);
      break;
    case 4:
      var value = new proto.haro.HaroMember;
      reader.readMessage(value,proto.haro.HaroMember.deserializeBinaryFromReader);
      msg.addUsersForNotification(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNotifyAtHours(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRemoveAnonymous(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDomainRatingMin(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDomainRatingMax(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addKeywords(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setBioFullName(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setBioJobTitle(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setBioCompanyName(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setBioCompanyWebsite(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setBioHeadshot(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setBioLinkedInLink(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setBioTwitterLink(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setBioPhoneNumber(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setBioOnlineBio(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setBioPressKitLink(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.haro.HaroFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.haro.HaroFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.haro.HaroFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.haro.HaroFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMailboxAccountId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getUsersForNotificationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.haro.HaroMember.serializeBinaryToWriter
    );
  }
  f = message.getNotifyAtHours();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getRemoveAnonymous();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getDomainRatingMin();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getDomainRatingMax();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getKeywordsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
  f = message.getBioFullName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getBioJobTitle();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getBioCompanyName();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getBioCompanyWebsite();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getBioHeadshot();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getBioLinkedInLink();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getBioTwitterLink();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getBioPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getBioOnlineBio();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getBioPressKitLink();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      20,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.haro.HaroFilter.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.haro.HaroFilter} returns this
 */
proto.haro.HaroFilter.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.haro.HaroFilter.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.haro.HaroFilter} returns this
 */
proto.haro.HaroFilter.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 mailbox_account_id = 3;
 * @return {number}
 */
proto.haro.HaroFilter.prototype.getMailboxAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.haro.HaroFilter} returns this
 */
proto.haro.HaroFilter.prototype.setMailboxAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated HaroMember users_for_notification = 4;
 * @return {!Array<!proto.haro.HaroMember>}
 */
proto.haro.HaroFilter.prototype.getUsersForNotificationList = function() {
  return /** @type{!Array<!proto.haro.HaroMember>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.haro.HaroMember, 4));
};


/**
 * @param {!Array<!proto.haro.HaroMember>} value
 * @return {!proto.haro.HaroFilter} returns this
*/
proto.haro.HaroFilter.prototype.setUsersForNotificationList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.haro.HaroMember=} opt_value
 * @param {number=} opt_index
 * @return {!proto.haro.HaroMember}
 */
proto.haro.HaroFilter.prototype.addUsersForNotification = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.haro.HaroMember, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.haro.HaroFilter} returns this
 */
proto.haro.HaroFilter.prototype.clearUsersForNotificationList = function() {
  return this.setUsersForNotificationList([]);
};


/**
 * optional int32 notify_at_hours = 5;
 * @return {number}
 */
proto.haro.HaroFilter.prototype.getNotifyAtHours = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.haro.HaroFilter} returns this
 */
proto.haro.HaroFilter.prototype.setNotifyAtHours = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bool remove_anonymous = 6;
 * @return {boolean}
 */
proto.haro.HaroFilter.prototype.getRemoveAnonymous = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.haro.HaroFilter} returns this
 */
proto.haro.HaroFilter.prototype.setRemoveAnonymous = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional int32 domain_rating_min = 7;
 * @return {number}
 */
proto.haro.HaroFilter.prototype.getDomainRatingMin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.haro.HaroFilter} returns this
 */
proto.haro.HaroFilter.prototype.setDomainRatingMin = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 domain_rating_max = 8;
 * @return {number}
 */
proto.haro.HaroFilter.prototype.getDomainRatingMax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.haro.HaroFilter} returns this
 */
proto.haro.HaroFilter.prototype.setDomainRatingMax = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * repeated string keywords = 9;
 * @return {!Array<string>}
 */
proto.haro.HaroFilter.prototype.getKeywordsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.haro.HaroFilter} returns this
 */
proto.haro.HaroFilter.prototype.setKeywordsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.haro.HaroFilter} returns this
 */
proto.haro.HaroFilter.prototype.addKeywords = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.haro.HaroFilter} returns this
 */
proto.haro.HaroFilter.prototype.clearKeywordsList = function() {
  return this.setKeywordsList([]);
};


/**
 * optional string bio_full_name = 10;
 * @return {string}
 */
proto.haro.HaroFilter.prototype.getBioFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.haro.HaroFilter} returns this
 */
proto.haro.HaroFilter.prototype.setBioFullName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string bio_job_title = 11;
 * @return {string}
 */
proto.haro.HaroFilter.prototype.getBioJobTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.haro.HaroFilter} returns this
 */
proto.haro.HaroFilter.prototype.setBioJobTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string bio_company_name = 12;
 * @return {string}
 */
proto.haro.HaroFilter.prototype.getBioCompanyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.haro.HaroFilter} returns this
 */
proto.haro.HaroFilter.prototype.setBioCompanyName = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string bio_company_website = 13;
 * @return {string}
 */
proto.haro.HaroFilter.prototype.getBioCompanyWebsite = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.haro.HaroFilter} returns this
 */
proto.haro.HaroFilter.prototype.setBioCompanyWebsite = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string bio_headshot = 14;
 * @return {string}
 */
proto.haro.HaroFilter.prototype.getBioHeadshot = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.haro.HaroFilter} returns this
 */
proto.haro.HaroFilter.prototype.setBioHeadshot = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string bio_linked_in_link = 15;
 * @return {string}
 */
proto.haro.HaroFilter.prototype.getBioLinkedInLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.haro.HaroFilter} returns this
 */
proto.haro.HaroFilter.prototype.setBioLinkedInLink = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string bio_twitter_link = 16;
 * @return {string}
 */
proto.haro.HaroFilter.prototype.getBioTwitterLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.haro.HaroFilter} returns this
 */
proto.haro.HaroFilter.prototype.setBioTwitterLink = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string bio_phone_number = 17;
 * @return {string}
 */
proto.haro.HaroFilter.prototype.getBioPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.haro.HaroFilter} returns this
 */
proto.haro.HaroFilter.prototype.setBioPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string bio_online_bio = 18;
 * @return {string}
 */
proto.haro.HaroFilter.prototype.getBioOnlineBio = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.haro.HaroFilter} returns this
 */
proto.haro.HaroFilter.prototype.setBioOnlineBio = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string bio_press_kit_link = 19;
 * @return {string}
 */
proto.haro.HaroFilter.prototype.getBioPressKitLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.haro.HaroFilter} returns this
 */
proto.haro.HaroFilter.prototype.setBioPressKitLink = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional int64 workspace_id = 20;
 * @return {number}
 */
proto.haro.HaroFilter.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.haro.HaroFilter} returns this
 */
proto.haro.HaroFilter.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.haro.HaroMember.prototype.toObject = function(opt_includeInstance) {
  return proto.haro.HaroMember.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.haro.HaroMember} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.haro.HaroMember.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    email: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.haro.HaroMember}
 */
proto.haro.HaroMember.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.haro.HaroMember;
  return proto.haro.HaroMember.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.haro.HaroMember} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.haro.HaroMember}
 */
proto.haro.HaroMember.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.haro.HaroMember.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.haro.HaroMember.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.haro.HaroMember} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.haro.HaroMember.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 user_id = 1;
 * @return {number}
 */
proto.haro.HaroMember.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.haro.HaroMember} returns this
 */
proto.haro.HaroMember.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.haro.HaroMember.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.haro.HaroMember} returns this
 */
proto.haro.HaroMember.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.haro.HaroFilterShortResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.haro.HaroFilterShortResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.haro.HaroFilterShortResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.haro.HaroFilterShortResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    totalItems: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.haro.HaroFilterShortResponse}
 */
proto.haro.HaroFilterShortResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.haro.HaroFilterShortResponse;
  return proto.haro.HaroFilterShortResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.haro.HaroFilterShortResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.haro.HaroFilterShortResponse}
 */
proto.haro.HaroFilterShortResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.haro.HaroFilterShortResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.haro.HaroFilterShortResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.haro.HaroFilterShortResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.haro.HaroFilterShortResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTotalItems();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.haro.HaroFilterShortResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.haro.HaroFilterShortResponse} returns this
 */
proto.haro.HaroFilterShortResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.haro.HaroFilterShortResponse.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.haro.HaroFilterShortResponse} returns this
 */
proto.haro.HaroFilterShortResponse.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 total_items = 3;
 * @return {number}
 */
proto.haro.HaroFilterShortResponse.prototype.getTotalItems = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.haro.HaroFilterShortResponse} returns this
 */
proto.haro.HaroFilterShortResponse.prototype.setTotalItems = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.haro.HaroCategoriesContainer.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.haro.HaroCategoriesContainer.prototype.toObject = function(opt_includeInstance) {
  return proto.haro.HaroCategoriesContainer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.haro.HaroCategoriesContainer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.haro.HaroCategoriesContainer.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.haro.HaroCategoriesContainer.HaroCategories.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.haro.HaroCategoriesContainer}
 */
proto.haro.HaroCategoriesContainer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.haro.HaroCategoriesContainer;
  return proto.haro.HaroCategoriesContainer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.haro.HaroCategoriesContainer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.haro.HaroCategoriesContainer}
 */
proto.haro.HaroCategoriesContainer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.haro.HaroCategoriesContainer.HaroCategories;
      reader.readMessage(value,proto.haro.HaroCategoriesContainer.HaroCategories.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.haro.HaroCategoriesContainer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.haro.HaroCategoriesContainer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.haro.HaroCategoriesContainer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.haro.HaroCategoriesContainer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.haro.HaroCategoriesContainer.HaroCategories.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.haro.HaroCategoriesContainer.HaroCategories.prototype.toObject = function(opt_includeInstance) {
  return proto.haro.HaroCategoriesContainer.HaroCategories.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.haro.HaroCategoriesContainer.HaroCategories} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.haro.HaroCategoriesContainer.HaroCategories.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    hexColor: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.haro.HaroCategoriesContainer.HaroCategories}
 */
proto.haro.HaroCategoriesContainer.HaroCategories.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.haro.HaroCategoriesContainer.HaroCategories;
  return proto.haro.HaroCategoriesContainer.HaroCategories.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.haro.HaroCategoriesContainer.HaroCategories} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.haro.HaroCategoriesContainer.HaroCategories}
 */
proto.haro.HaroCategoriesContainer.HaroCategories.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setHexColor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.haro.HaroCategoriesContainer.HaroCategories.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.haro.HaroCategoriesContainer.HaroCategories.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.haro.HaroCategoriesContainer.HaroCategories} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.haro.HaroCategoriesContainer.HaroCategories.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHexColor();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.haro.HaroCategoriesContainer.HaroCategories.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.haro.HaroCategoriesContainer.HaroCategories} returns this
 */
proto.haro.HaroCategoriesContainer.HaroCategories.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.haro.HaroCategoriesContainer.HaroCategories.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.haro.HaroCategoriesContainer.HaroCategories} returns this
 */
proto.haro.HaroCategoriesContainer.HaroCategories.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string hex_color = 3;
 * @return {string}
 */
proto.haro.HaroCategoriesContainer.HaroCategories.prototype.getHexColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.haro.HaroCategoriesContainer.HaroCategories} returns this
 */
proto.haro.HaroCategoriesContainer.HaroCategories.prototype.setHexColor = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated HaroCategories items = 1;
 * @return {!Array<!proto.haro.HaroCategoriesContainer.HaroCategories>}
 */
proto.haro.HaroCategoriesContainer.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.haro.HaroCategoriesContainer.HaroCategories>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.haro.HaroCategoriesContainer.HaroCategories, 1));
};


/**
 * @param {!Array<!proto.haro.HaroCategoriesContainer.HaroCategories>} value
 * @return {!proto.haro.HaroCategoriesContainer} returns this
*/
proto.haro.HaroCategoriesContainer.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.haro.HaroCategoriesContainer.HaroCategories=} opt_value
 * @param {number=} opt_index
 * @return {!proto.haro.HaroCategoriesContainer.HaroCategories}
 */
proto.haro.HaroCategoriesContainer.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.haro.HaroCategoriesContainer.HaroCategories, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.haro.HaroCategoriesContainer} returns this
 */
proto.haro.HaroCategoriesContainer.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.haro.HaroBookmarkRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.haro.HaroBookmarkRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.haro.HaroBookmarkRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.haro.HaroBookmarkRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    opportunityId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.haro.HaroBookmarkRequest}
 */
proto.haro.HaroBookmarkRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.haro.HaroBookmarkRequest;
  return proto.haro.HaroBookmarkRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.haro.HaroBookmarkRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.haro.HaroBookmarkRequest}
 */
proto.haro.HaroBookmarkRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOpportunityId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.haro.HaroBookmarkRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.haro.HaroBookmarkRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.haro.HaroBookmarkRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.haro.HaroBookmarkRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOpportunityId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 workspace_id = 1;
 * @return {number}
 */
proto.haro.HaroBookmarkRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.haro.HaroBookmarkRequest} returns this
 */
proto.haro.HaroBookmarkRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string opportunity_id = 2;
 * @return {string}
 */
proto.haro.HaroBookmarkRequest.prototype.getOpportunityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.haro.HaroBookmarkRequest} returns this
 */
proto.haro.HaroBookmarkRequest.prototype.setOpportunityId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.haro.HaroPitchedRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.haro.HaroPitchedRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.haro.HaroPitchedRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.haro.HaroPitchedRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    opportunityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    mailboxAccountId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    subject: jspb.Message.getFieldWithDefault(msg, 4, ""),
    content: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.haro.HaroPitchedRequest}
 */
proto.haro.HaroPitchedRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.haro.HaroPitchedRequest;
  return proto.haro.HaroPitchedRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.haro.HaroPitchedRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.haro.HaroPitchedRequest}
 */
proto.haro.HaroPitchedRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOpportunityId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMailboxAccountId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubject(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.haro.HaroPitchedRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.haro.HaroPitchedRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.haro.HaroPitchedRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.haro.HaroPitchedRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOpportunityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getMailboxAccountId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getSubject();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string opportunity_id = 1;
 * @return {string}
 */
proto.haro.HaroPitchedRequest.prototype.getOpportunityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.haro.HaroPitchedRequest} returns this
 */
proto.haro.HaroPitchedRequest.prototype.setOpportunityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 workspace_id = 2;
 * @return {number}
 */
proto.haro.HaroPitchedRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.haro.HaroPitchedRequest} returns this
 */
proto.haro.HaroPitchedRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 mailbox_account_id = 3;
 * @return {number}
 */
proto.haro.HaroPitchedRequest.prototype.getMailboxAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.haro.HaroPitchedRequest} returns this
 */
proto.haro.HaroPitchedRequest.prototype.setMailboxAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string subject = 4;
 * @return {string}
 */
proto.haro.HaroPitchedRequest.prototype.getSubject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.haro.HaroPitchedRequest} returns this
 */
proto.haro.HaroPitchedRequest.prototype.setSubject = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string content = 5;
 * @return {string}
 */
proto.haro.HaroPitchedRequest.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.haro.HaroPitchedRequest} returns this
 */
proto.haro.HaroPitchedRequest.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.haro.HaroPitchedResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.haro.HaroPitchedResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.haro.HaroPitchedResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.haro.HaroPitchedResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    mailboxAccountId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    emailTo: jspb.Message.getFieldWithDefault(msg, 3, ""),
    subject: jspb.Message.getFieldWithDefault(msg, 4, ""),
    content: jspb.Message.getFieldWithDefault(msg, 5, ""),
    opportunity: (f = msg.getOpportunity()) && proto.haro.HaroOpportunityResponse.toObject(includeInstance, f),
    totalItems: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.haro.HaroPitchedResponse}
 */
proto.haro.HaroPitchedResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.haro.HaroPitchedResponse;
  return proto.haro.HaroPitchedResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.haro.HaroPitchedResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.haro.HaroPitchedResponse}
 */
proto.haro.HaroPitchedResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMailboxAccountId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailTo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubject(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 6:
      var value = new proto.haro.HaroOpportunityResponse;
      reader.readMessage(value,proto.haro.HaroOpportunityResponse.deserializeBinaryFromReader);
      msg.setOpportunity(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.haro.HaroPitchedResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.haro.HaroPitchedResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.haro.HaroPitchedResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.haro.HaroPitchedResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMailboxAccountId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getEmailTo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSubject();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOpportunity();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.haro.HaroOpportunityResponse.serializeBinaryToWriter
    );
  }
  f = message.getTotalItems();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.haro.HaroPitchedResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.haro.HaroPitchedResponse} returns this
 */
proto.haro.HaroPitchedResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 mailbox_account_id = 2;
 * @return {number}
 */
proto.haro.HaroPitchedResponse.prototype.getMailboxAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.haro.HaroPitchedResponse} returns this
 */
proto.haro.HaroPitchedResponse.prototype.setMailboxAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string email_to = 3;
 * @return {string}
 */
proto.haro.HaroPitchedResponse.prototype.getEmailTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.haro.HaroPitchedResponse} returns this
 */
proto.haro.HaroPitchedResponse.prototype.setEmailTo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string subject = 4;
 * @return {string}
 */
proto.haro.HaroPitchedResponse.prototype.getSubject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.haro.HaroPitchedResponse} returns this
 */
proto.haro.HaroPitchedResponse.prototype.setSubject = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string content = 5;
 * @return {string}
 */
proto.haro.HaroPitchedResponse.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.haro.HaroPitchedResponse} returns this
 */
proto.haro.HaroPitchedResponse.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional HaroOpportunityResponse opportunity = 6;
 * @return {?proto.haro.HaroOpportunityResponse}
 */
proto.haro.HaroPitchedResponse.prototype.getOpportunity = function() {
  return /** @type{?proto.haro.HaroOpportunityResponse} */ (
    jspb.Message.getWrapperField(this, proto.haro.HaroOpportunityResponse, 6));
};


/**
 * @param {?proto.haro.HaroOpportunityResponse|undefined} value
 * @return {!proto.haro.HaroPitchedResponse} returns this
*/
proto.haro.HaroPitchedResponse.prototype.setOpportunity = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.haro.HaroPitchedResponse} returns this
 */
proto.haro.HaroPitchedResponse.prototype.clearOpportunity = function() {
  return this.setOpportunity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.haro.HaroPitchedResponse.prototype.hasOpportunity = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int32 total_items = 7;
 * @return {number}
 */
proto.haro.HaroPitchedResponse.prototype.getTotalItems = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.haro.HaroPitchedResponse} returns this
 */
proto.haro.HaroPitchedResponse.prototype.setTotalItems = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * @enum {number}
 */
proto.haro.HaroOpportunitySort = {
  DEADLINE_AT: 0,
  DOMAIN_RATING: 1,
  DOMAIN_SPAM_SCORE: 2,
  PUBLICATED_AT: 3
};

goog.object.extend(exports, proto.haro);
