import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'query-string';

import AllInquiries from 'src/components/Inquiries/AllInquiries/AllInquiries';
import FilterCreation from 'src/components/Inquiries/FilterCreation/FilterCreation';
import InquiriesSidebar from 'src/components/Inquiries/InquiriesSidebar/InquiriesSidebar';
import PageHeader from 'src/components/PageHeader/PageHeader';
import Bookmarks from 'src/components/Inquiries/Bookmarks/Bookmarks';
import Pitched from 'src/components/Inquiries/Pitched/Pitched';
import Loader from 'src/uikit/Loader/Loader';

import { haroCategoriesSelector } from 'src/redux/selectors/inquiries.selectors';
import loadingStatuses from 'src/constants/loadingStatuses';
import { fetchHaroCategories } from 'src/redux/thunks/inquries.thunks';

import InquiryPitch from '@components/Inquiries/InquiryPitch/InquiryPitch';
import HaroAccountReminder from '@components/Inquiries/HaroReminder/HaroReminder';
import RenderHeaderSearchAndActions from '@components/RenderHeaderSearchAndActions/RenderHeaderSearchAndActions';

import urls from '@constants/urls';

import './InquiriesPage.scss';
import { MailboxAccountSettingsType } from '@ts/mailboxAccounts.types';
import { useQuery } from 'react-query';
import { getAccountsByWorkspaceIdApi } from '@api/mailboxAccounts.api';
import { getCurrentWorkspaceId } from '@redux/selectors/workspaces.selectors';

const InquiriesPage = ({ location: { pathname, search } }): JSX.Element => {
  const { filterId } = qs.parse(search);
  const dispatch = useDispatch();
  const [searchString, changeSearchString] = useState('');
  const [pitchingInquiry, changePitchingInquiry] = useState<string | null>(null);
  const { loadingStatus: haroCategoriesLs } = useSelector(haroCategoriesSelector);

  const currentWorkspaceId = useSelector(getCurrentWorkspaceId);

  const {
    data: mailboxAccountsData,
    isFetching: isLoadingSenderEmail,
  }: { data: MailboxAccountSettingsType[]; isFetching: boolean } = useQuery(
    ['mailbox-accounts', currentWorkspaceId],
    () => getAccountsByWorkspaceIdApi(currentWorkspaceId),
    {
      enabled: !!currentWorkspaceId,
      refetchOnWindowFocus: false,
      placeholderData: [],
    }
  );

  const handlePitchInquiry = (inquiryId: string) => changePitchingInquiry(inquiryId);
  const handleClosePitchInquiry = () => changePitchingInquiry(null);
  const isFiltersPage = pathname === urls.INQUIRIES_FILTERS_CREATE;
  const isFilterUpdatePage = pathname.startsWith(urls.INQUIRIES_FILTERS_UPDATE);
  const getPageHeaderTitle = () => {
    if (isFiltersPage) {
      return 'Set up new filter';
    }

    if (isFilterUpdatePage) {
      return 'Update filter';
    }

    return 'Press inquiries';
  };

  useEffect(() => {
    if (haroCategoriesLs === loadingStatuses.PENDING) {
      dispatch(fetchHaroCategories());
    }
  }, []);

  if (haroCategoriesLs !== loadingStatuses.LOADED) {
    return <Loader isLoading withTopMargin />;
  }

  return (
    <div className="inquiries-page">
      <InquiriesSidebar pathname={pathname} filterId={Number(filterId)} />
      <div className="inquiries-page__page-content">
        <PageHeader
          title={getPageHeaderTitle()}
          howToLink="https://help.respona.com/en/articles/6332457-how-to-use-press-inquiries"
        >
          <RenderHeaderSearchAndActions
            searchString={searchString}
            changeSearchString={changeSearchString}
            isFiltersPage={isFiltersPage}
            isFilterUpdatePage={isFilterUpdatePage}
          />
        </PageHeader>
        <div className="inquiries-page__content">
          <Switch>
            <Route path="/inquiries/all-inquiries">
              <AllInquiries
                searchString={searchString}
                filterId={Number(filterId)}
                onPitch={handlePitchInquiry}
              />
            </Route>
            <Route path="/inquiries/bookmarks">
              <Bookmarks onPitch={handlePitchInquiry} />
            </Route>
            <Route path="/inquiries/pitched">
              <Pitched />
            </Route>
            <Route path={urls.INQUIRIES_FILTERS_CREATE} component={FilterCreation} />
            <Route path={`${urls.INQUIRIES_FILTERS_UPDATE}/:filterId`} component={FilterCreation} />
            <Route path="/inquiries/haro-reminder" component={HaroAccountReminder} />
            <Route path="/inquiries*">
              <Redirect to="/inquiries/all-inquiries" />
            </Route>
          </Switch>
        </div>
      </div>
      <InquiryPitch
        inquiryId={pitchingInquiry}
        filterId={Number(filterId)}
        onClose={handleClosePitchInquiry}
        isLoadingMailboxAccounts={isLoadingSenderEmail}
        mailboxAccountsData={mailboxAccountsData}
      />
    </div>
  );
};

export default InquiriesPage;
