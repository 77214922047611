import { LegacyRef, useEffect, useRef, useState } from 'react';

export default <T extends HTMLElement>(): [LegacyRef<T>, boolean] => {
  const ref = useRef<T | null>(null);
  const [isHover, changeIsHover] = useState<boolean>(false);

  const handleMouseOver = () => changeIsHover(true);
  const handleMouseOut = () => changeIsHover(false);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener('mouseenter', handleMouseOver);
      ref.current.addEventListener('mouseleave', handleMouseOut);

      return () => {
        if (ref.current) {
          ref.current.removeEventListener('mouseenter', handleMouseOver);
          ref.current.removeEventListener('mouseleave', handleMouseOut);
        }
      };
    }
  }, [ref.current]);

  return [ref, isHover];
};
