export default [
  {
    key: 'selectMembers',
    title: 'Select Members',
    additional: 'Most people use the name of their department or client.',
    Renderer: null,
  },
  {
    key: 'emails',
    title: 'Emails',
    Renderer: null,
  },
  {
    key: 'selectRole',
    title: 'Select a role',
    Renderer: null,
  },
];
