import { getOpportunityByIdApi } from '@api/campaign.api';
import { getBasePersonByIdApi } from '@api/people.api';
import useCustomVariablesValues from '@hooks/useCustomVariablesValues';
import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';

import { updateQueueMessageByIdApi, getFutureByQueueIdApi } from '@api/mailbox.api';

import { addNotification } from '@redux/actions/notifications.actions';

import OutboxEmail from '@components/CampaignAnalytics/Outbox/_components/OutboxEmail/OutboxEmail';
import InboxThreadsList from '@components/InboxThreadsList/InboxThreadsList';
import Loader from '@uikit/Loader/Loader';
import { QueuePreviewResponseType } from '@ts/mailboxInbox.types';

import './OutboxContent.scss';
import { extractVariableNamesFromOpportunity } from '@utils/extractVariableNamesFromOpportunity';

const outboxThreadMapper = (list: QueuePreviewResponseType[]) =>
  list.map(({ nextActivityTime, toName, ...rest }) => ({
    time: nextActivityTime,
    name: toName,
    ...rest,
  }));

function OutboxContent({
  threads,
  workspaceId,
  campaignId,
  fetchNextPage,
  isFetchingNextPage,
  hasNextPage,
}: {
  threads: QueuePreviewResponseType[];
  workspaceId: number;
  campaignId?: number;
  fetchNextPage;
  isFetchingNextPage;
  hasNextPage;
}): JSX.Element {
  const dispatch = useDispatch();
  const [activeThreadIndex, changeActiveThreadIndex] = useState<number | null>(null);

  const {
    data: selectedOutboxData,
    isFetching,
    refetch,
  } = useQuery(
    ['outbox-single-email', activeThreadIndex, workspaceId, campaignId],
    () => getFutureByQueueIdApi(threads[activeThreadIndex]?.queueId, workspaceId, campaignId),
    {
      enabled: !!threads[activeThreadIndex] && !!workspaceId && (campaignId ? !!campaignId : true),
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  const personId = threads[activeThreadIndex]?.personId;
  const opportunityId = selectedOutboxData?.opportunityId;

  const { data: personData, isFetching: isPersonFetching } = useQuery(
    ['outbox-single-email-person', workspaceId, personId, campaignId],
    () => getBasePersonByIdApi(personId),
    {
      enabled: !!personId && !!workspaceId && (campaignId ? !!campaignId : true),
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  const { data: opportunityData, isFetching: isOpportunityFetching } = useQuery(
    ['outbox-single-email-opportunity', workspaceId, opportunityId],
    () => getOpportunityByIdApi(opportunityId, workspaceId, -1),
    {
      enabled: !!opportunityId && !!workspaceId && (campaignId ? !!campaignId : true),
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  const relatedIds = [opportunityId, personId].filter((it) => it != null && it > 0);

  const { items: customVariablesValues } = useCustomVariablesValues(
    extractVariableNamesFromOpportunity(opportunityData),
    relatedIds
  );

  const handleUpdateQueueMessage = (id, changedContent, attachmentsList?) => {
    return updateQueueMessageByIdApi(id, changedContent, attachmentsList).then(
      (newQueueMessage) => {
        dispatch(addNotification({ title: 'Email was updated', type: 'success' }));
        refetch();
        return newQueueMessage;
      }
    );
  };

  if (threads?.length === 0) {
    return (
      <div className="analytics-outbox-empty-message">
        <div>There are no emails yet.</div>
        <div>Please wait for messages to be sent.</div>
      </div>
    );
  }

  return (
    <div className="analytics-outbox">
      <InboxThreadsList
        activeIndex={activeThreadIndex}
        threadList={outboxThreadMapper(threads)}
        onChangeActiveThread={changeActiveThreadIndex}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        hasNextPage={hasNextPage}
      />
      <div className="analytics-outbox__content">
        {activeThreadIndex === null ? (
          <div className="analytics-outbox-select-message">Select email</div>
        ) : (
          <Loader isLoading={isFetching || isPersonFetching || isOpportunityFetching} withTopMargin>
            <OutboxEmail
              email={selectedOutboxData}
              onUpdateQueueMessage={handleUpdateQueueMessage}
              campaignId={campaignId}
              workspaceId={workspaceId}
              personData={personData}
              opportunityData={opportunityData}
              customVariablesValues={customVariablesValues}
            />
          </Loader>
        )}
      </div>
    </div>
  );
}

export default OutboxContent;
