import React from 'react';

const ThreeDots = ({ color = '#8C8D8D', size = 20 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
      <path
        d="M8.125 10C8.125 11.0355 8.96447 11.875 10 11.875C11.0355 11.875 11.875 11.0355 11.875 10C11.875 8.96447 11.0355 8.125 10 8.125C8.96447 8.125 8.125 8.96447 8.125 10Z"
        fill={color}
      />
      <path
        d="M8.125 2.5C8.125 3.53553 8.96447 4.375 10 4.375C11.0355 4.375 11.875 3.53553 11.875 2.5C11.875 1.46447 11.0355 0.625 10 0.625C8.96447 0.625 8.125 1.46447 8.125 2.5Z"
        fill={color}
      />
      <path
        d="M8.125 17.5C8.125 18.5355 8.96447 19.375 10 19.375C11.0355 19.375 11.875 18.5355 11.875 17.5C11.875 16.4645 11.0355 15.625 10 15.625C8.96447 15.625 8.125 16.4645 8.125 17.5Z"
        fill={color}
      />
    </svg>
  );
};

export default ThreeDots;
