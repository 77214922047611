import React from 'react';

const ShowsSvg = () => {
  return (
    <svg width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.6875 8.875L29.875 4.9375V18.0625L20.6875 14.125"
        stroke="#221CB6"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M18.0625 1H3.625C2.17525 1 1 2.17525 1 3.625V19.375C1 20.8247 2.17525 22 3.625 22H18.0625C19.5122 22 20.6875 20.8247 20.6875 19.375V3.625C20.6875 2.17525 19.5122 1 18.0625 1Z"
        stroke="#221CB6"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ShowsSvg;
