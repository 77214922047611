import React from 'react';

const SuccessCircleFilledIcon = ({ color = '#23E771', size = 24 }: { color: string, size: number }): JSX.Element => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill={color}
    />
    <path
      d="M7 12L10.3333 15L17 9"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);

export default SuccessCircleFilledIcon;
