import React, { RefObject, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { billingDetailsSelector } from '@redux/selectors/billings.selectors';

import UseNewCard from './_components/UseNewCard/UseNewCard';
import UseExistingCard from './_components/UseExistingCard/UseExistingCard';

import './UseCardToPay.scss';

type UseCardToPayProps = {
  isCurrentCard: boolean;
  onToogle?: (value?: boolean) => void;
  submitBtnRef: RefObject<HTMLButtonElement>;
  gradientLoaderHeight;
  gradientLoaderWidth;
  isButtonDisabled?: boolean;
  disableButtonHandler: (value: boolean) => void;
  buttonClickCallback: (braintreeNonce: string, braintreeDeviceData: string) => void;
};

const UseCardToPay = ({
  isCurrentCard,
  onToogle,
  submitBtnRef,
  gradientLoaderHeight,
  gradientLoaderWidth,
  buttonClickCallback,
  disableButtonHandler,
  isButtonDisabled = false,
}: UseCardToPayProps): JSX.Element => {
  const { data: billingDetails } = useSelector(billingDetailsSelector);
  return (
    <div className="use-card-to-pay">
      {isCurrentCard &&
      billingDetails != null &&
      billingDetails.card != null &&
      billingDetails.card.creditCardNumber != null &&
      billingDetails.card.creditCardNumber.length > 0 ? (
        <UseExistingCard onUseNewCard={onToogle} />
      ) : (
        <UseNewCard
          submitBtnRef={submitBtnRef}
          onUseExistingCard={onToogle}
          gradientLoaderHeight={gradientLoaderHeight}
          gradientLoaderWidth={gradientLoaderWidth}
          buttonClickCallback={buttonClickCallback}
          disableButtonHandler={disableButtonHandler}
          isButtonDisabled={isButtonDisabled}
          isCancelable={!!billingDetails?.card?.creditCardNumber}
        />
      )}
    </div>
  );
};

export default UseCardToPay;
