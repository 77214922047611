// package: importer.file
// file: file-importer.proto

var file_importer_pb = require("./file-importer_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var FileImporterService = (function () {
  function FileImporterService() {}
  FileImporterService.serviceName = "importer.file.FileImporterService";
  return FileImporterService;
}());

FileImporterService.parse = {
  methodName: "parse",
  service: FileImporterService,
  requestStream: false,
  responseStream: false,
  requestType: file_importer_pb.ParseFileRequest,
  responseType: file_importer_pb.ParseFileResponse
};

exports.FileImporterService = FileImporterService;

function FileImporterServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

FileImporterServiceClient.prototype.parse = function parse(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(FileImporterService.parse, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.FileImporterServiceClient = FileImporterServiceClient;

