import { FilterProps } from '@ts/props.types';

const composeFilterOptions = (
  campaignLoader: (
    inputValue: string,
    callback: (options: { value: string | number; label: string }[]) => void
  ) => void,
  campaigns: { value: string | number; label: string }[] = [],
  workspaces: { value: string | number; label: string }[] = [],
  users: { value: string | number; label: string }[] = [],
  mailboxes: { value: string | number; label: string }[] = []
): FilterProps[] => [
  {
    label: 'Workspace',
    value: 0,
    type: 'enumString',
    inputOptions: workspaces,
  },
  {
    label: 'Campaign',
    value: 1,
    type: 'enumStringAsync',
    inputOptions: campaigns,
    loadOptions: campaignLoader,
  },
  {
    label: 'User',
    value: 2,
    type: 'enumString',
    inputOptions: users,
  },
  {
    label: 'Mailbox',
    value: 3,
    type: 'enumString',
    inputOptions: mailboxes,
  },
];

export default composeFilterOptions;
