export default function sideScroll(
  element: HTMLElement,
  direction: 'left' | 'right',
  leftArrow: HTMLElement,
  rightArrow: HTMLElement
): void {
  let scrollAmount = 0;
  const slideTimer = setInterval(() => {
    if (direction === 'left') {
      // eslint-disable-next-line no-param-reassign
      element.scrollLeft -= 10;
    } else {
      // eslint-disable-next-line no-param-reassign
      element.scrollLeft += 10;
    }
    scrollAmount += 10;
    if (scrollAmount >= 100) {
      window.clearInterval(slideTimer);
    }

    if (element.scrollLeft === 0) {
      // eslint-disable-next-line no-param-reassign
      leftArrow.style.opacity = String(0);
    } else {
      // eslint-disable-next-line no-param-reassign
      leftArrow.style.opacity = String(1);
    }
    const maxScrollLeft = element.scrollWidth - element.clientWidth;
    if (element.scrollLeft === maxScrollLeft && !!rightArrow?.style) {
      // eslint-disable-next-line no-param-reassign
      rightArrow.style.opacity = String(0);
    } else if (rightArrow?.style) {
      // eslint-disable-next-line no-param-reassign
      rightArrow.style.opacity = String(1);
    }
  }, 25);
}
