import React from 'react';

export default ({ color = '#212121', size = 18 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 2C1 1.44771 1.44772 1 2 1H6.22222C6.77451 1 7.22222 1.44772 7.22222 2V6.22222C7.22222 6.77451 6.77451 7.22222 6.22222 7.22222H2C1.44771 7.22222 1 6.77451 1 6.22222V2Z"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.7778 2C10.7778 1.44771 11.2255 1 11.7778 1H16.0001C16.5523 1 17.0001 1.44772 17.0001 2V6.22222C17.0001 6.77451 16.5523 7.22222 16.0001 7.22222H11.7778C11.2255 7.22222 10.7778 6.77451 10.7778 6.22222V2Z"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.7778 11.7778C10.7778 11.2255 11.2255 10.7778 11.7778 10.7778H16.0001C16.5523 10.7778 17.0001 11.2255 17.0001 11.7778V16.0001C17.0001 16.5523 16.5523 17.0001 16.0001 17.0001H11.7778C11.2255 17.0001 10.7778 16.5523 10.7778 16.0001V11.7778Z"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 11.7778C1 11.2255 1.44772 10.7778 2 10.7778H6.22222C6.77451 10.7778 7.22222 11.2255 7.22222 11.7778V16.0001C7.22222 16.5523 6.77451 17.0001 6.22222 17.0001H2C1.44771 17.0001 1 16.5523 1 16.0001V11.7778Z"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
