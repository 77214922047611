import React from 'react';

export default ({ color = '#ffffff' }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M12.6666 8.00065H7.99992V3.33398C7.99992 2.93398 7.73325 2.66732 7.33325 2.66732C3.99992 2.66732 1.33325 5.33398 1.33325 8.66732C1.33325 12.0007 3.99992 14.6673 7.33325 14.6673C10.6666 14.6673 13.3333 12.0007 13.3333 8.66732C13.3333 8.26732 13.0666 8.00065 12.6666 8.00065ZM7.99992 13.2673C5.46659 13.6673 3.06659 11.8673 2.73325 9.33398C2.33325 6.80065 4.13325 4.40065 6.66659 4.06732V8.66732C6.66659 9.06732 6.93325 9.33398 7.33325 9.33398H11.9333C11.6666 11.4007 10.0666 13.0007 7.99992 13.2673ZM9.99992 1.33398C9.59992 1.33398 9.33325 1.60065 9.33325 2.00065V6.00065C9.33325 6.40065 9.59992 6.66732 9.99992 6.66732H13.9999C14.3999 6.66732 14.6666 6.40065 14.6666 6.00065C14.6666 3.40065 12.5999 1.33398 9.99992 1.33398ZM10.6666 5.33398V2.73398C11.9999 3.00065 12.9999 4.00065 13.2666 5.33398H10.6666Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath>
        <rect width="16" height="16" fill={color} />
      </clipPath>
    </defs>
  </svg>
);
