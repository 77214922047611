import React from 'react';

export default ({ color = '#212121' }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.8332 4.16667C10.8332 5.0875 8.7815 5.83333 6.24984 5.83333C3.71817 5.83333 1.6665 5.0875 1.6665 4.16667M10.8332 4.16667C10.8332 3.24583 8.7815 2.5 6.24984 2.5C3.71817 2.5 1.6665 3.24583 1.6665 4.16667M10.8332 4.16667V7.88083C9.81484 8.18667 9.1665 8.64917 9.1665 9.16667M1.6665 4.16667V14.1667C1.6665 15.0875 3.71817 15.8333 6.24984 15.8333C7.35817 15.8333 8.37484 15.69 9.1665 15.4525V9.16667M9.1665 9.16667C9.1665 10.0875 11.2182 10.8333 13.7498 10.8333C16.2815 10.8333 18.3332 10.0875 18.3332 9.16667M9.1665 9.16667C9.1665 8.24583 11.2182 7.5 13.7498 7.5C16.2815 7.5 18.3332 8.24583 18.3332 9.16667M9.1665 9.16667V15.8333C9.1665 16.7542 11.2182 17.5 13.7498 17.5C16.2815 17.5 18.3332 16.7542 18.3332 15.8333V9.16667M1.6665 7.5C1.6665 8.42083 3.71817 9.16667 6.24984 9.16667C7.35817 9.16667 8.37484 9.02333 9.1665 8.78583M1.6665 10.8333C1.6665 11.7542 3.71817 12.5 6.24984 12.5C7.35817 12.5 8.37484 12.3567 9.1665 12.1192M18.3332 12.5C18.3332 13.4208 16.2815 14.1667 13.7498 14.1667C11.2182 14.1667 9.1665 13.4208 9.1665 12.5"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
