// package: automation
// file: automation-content.proto

var automation_content_pb = require("./automation-content_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var ContentAutomationService = (function () {
  function ContentAutomationService() {}
  ContentAutomationService.serviceName = "automation.ContentAutomationService";
  return ContentAutomationService;
}());

ContentAutomationService.getCampaignContentAutomationPreset = {
  methodName: "getCampaignContentAutomationPreset",
  service: ContentAutomationService,
  requestStream: false,
  responseStream: false,
  requestType: automation_content_pb.ContentAutomationRequest,
  responseType: automation_content_pb.ContentAutomationPresetContainer
};

exports.ContentAutomationService = ContentAutomationService;

function ContentAutomationServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

ContentAutomationServiceClient.prototype.getCampaignContentAutomationPreset = function getCampaignContentAutomationPreset(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ContentAutomationService.getCampaignContentAutomationPreset, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.ContentAutomationServiceClient = ContentAutomationServiceClient;

