import React from 'react';

import OnboardingStepIcon from 'src/components/Onboarding/OnboardingStepIcon/OnboardingStepIcon';

import './OnboardingPageHeader.scss';

export default ({
  icon,
  title,
  subtitle,
}: {
  icon: string;
  title: string;
  subtitle: string;
}): JSX.Element => (
  <div className="onboarding-page-header">
    <div className="onboarding-page-header__icon">
      <OnboardingStepIcon icon={icon} />
    </div>
    <div className="onboarding-page-header__title">{title}</div>
    <div className="onboarding-page-header__subtitle">{subtitle}</div>
  </div>
);
