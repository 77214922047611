import MetricsContainer from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/MetricsContainer/MetricsContainer';
import GoogleNewsShortSVG from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/assets/GoogleNewsShortSVG';
import { SVGIcon } from '@uikit/Icon/Icon';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ContentAutomationSearchingMode,
  KeywordSearchSource,
  KeywordSearchSourceMap,
} from 'respona_api/generated/automation-content_pb';
import { DispatchType } from 'src/store';

import { SearchTypesEnum } from '@ts/contentSearch.types';
import { KeywordsSearchType } from '@ts/automationContent.types';

import {
  automationContentData,
  automationSubTypeSelector,
} from '@redux/selectors/automationContent.selectors';

import AdvancedSearchListPage from '@pages/AdvancedSearchListPage/AdvancedSearchListPage';

import AutomationSourceOption, {
  AutomationSourceOptionsRow,
} from '@components/CampaignSettings/ContactAutomationSetup/_components/AutomationSourceSelect/_components/AutomationSourceOption';
import { StepsContext } from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/CASearchTypeWrapper/CASearchTypeWrapper';
import GoogleShortSVG from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/assets/GoogleShortSVG';
import KeywordsSearchAdvancedSettings from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/KeywordsSearchAdvancedSettings/KeywordsSearchAdvancedSettings';
import { onChangeQueriesType } from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/ContentSearchEditor/ContentSearchEditor';
import ContentSearchEditorV2 from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/ContentSearchEditor/ContentSearchEditorV2';

import './KeywordsSearchSteps.scss';
import SettingsStep from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/SettingsStep/SettingsStep';
import {CampaignPipelineType} from "respona_api/generated/pipeline_pb";

const keywordsSourceOptions = [
  {
    type: KeywordSearchSource.GOOGLE_KEYWORD_SEARCH,
    title: 'Google',
    poweredBy: 'Google',
    image: <GoogleShortSVG />,
    statusText: (
      <>
        <SVGIcon icon="wallet" className="respona-status-tag__icon" />
        <span>1 credit per result</span>
      </>
    ),
  },
  {
    type: KeywordSearchSource.GOOGLE_NEWS_KEYWORD_SEARCH,
    title: 'Google News',
    poweredBy: 'Google',
    image: <GoogleNewsShortSVG />,
    statusText: (
      <>
        <SVGIcon icon="wallet" className="respona-status-tag__icon" />
        <span>1 credit per result</span>
      </>
    ),
  },
  // {
  //   type: KeywordSearchSource.BING_KEYWORD_SEARCH,
  //   title: 'Bing',
  //   poweredBy: 'Microsoft',
  //   image: <BingShortSVG />,
  //   statusText: 'FREE',
  // },
  // {
  //   type: KeywordSearchSource.BING_NEWS_KEYWORD_SEARCH,
  //   title: 'Bing news',
  //   poweredBy: 'Microsoft',
  //   image: <BingNewsShortSvg />,
  //   statusText: 'FREE',
  // },
];

const KeywordsSearchSteps = ({
  setSourceRef,
  data,
}: {
  setSourceRef: (value: number) => void;
  data?: KeywordsSearchType;
}) => {
  const dispatch = useDispatch<DispatchType>();

  const [advancedSearchIsOpen, setAdvancedSearchIsOpen] = useState(false);
  const { activeStepIndex, onChangeActiveStepIndex } = useContext(StepsContext);

  const automationSubType = useSelector(automationSubTypeSelector);

  const initialSource = () => {
    switch (automationSubType) {
      case 0:
        return KeywordSearchSource.GOOGLE_KEYWORD_SEARCH;
      case 1:
        return KeywordSearchSource.BING_KEYWORD_SEARCH;
      case 5:
        return KeywordSearchSource.BING_NEWS_KEYWORD_SEARCH;
      case 12:
        return KeywordSearchSource.GOOGLE_NEWS_KEYWORD_SEARCH;
      default:
        KeywordSearchSource.GOOGLE_KEYWORD_SEARCH;
    }
    return KeywordSearchSource.GOOGLE_KEYWORD_SEARCH;
  };

  const [source, setSource] = useState<KeywordSearchSourceMap[keyof KeywordSearchSourceMap]>(
    initialSource()
  );

  const automationConfig = useSelector(
    automationContentData(ContentAutomationSearchingMode.KEYWORDS_SEARCH, source)
  );

  const toggleAdvancedSearch = () => setAdvancedSearchIsOpen((prevState) => !prevState);

  const handleChangeQueries: onChangeQueriesType = (queriesList) => {
    dispatch(automationConfig.set({ queriesList }));
  };

  const handleChangeAdvancedSettings = (advancedSettings: Partial<KeywordsSearchType>) => {
    dispatch(automationConfig.set(advancedSettings));
  };

  useEffect(() => {
    setSourceRef(source);
    const newValue = source < 2;
    dispatch(
      automationConfig.set({
        oneResultPerDomain: newValue,
        ignoreActive: newValue,
        ignoreAlreadyContacted: newValue,
      })
    );
  }, [source]);

  useEffect(() => {
    onChangeActiveStepIndex(1);
  }, []);

  const advancedSearchSourceType =
    source === 0 ? SearchTypesEnum.googleSearch : SearchTypesEnum.bingSearch;

  const config = automationConfig.data as KeywordsSearchType;

  if (!config) {
    return null;
  }

  switch (activeStepIndex) {
    case 1:
      return (
        <>
          <div className="keywords-source-select__title">Select source:</div>
          <AutomationSourceOptionsRow>
            {keywordsSourceOptions.map(({ type, title, poweredBy, image, statusText }) => (
              <AutomationSourceOption
                key={title}
                image={image}
                title={title}
                onSelect={() => setSource(type)}
                isSelected={source === type}
                subtitle="Powered by:"
                subtitleDetails={poweredBy}
                statusText={statusText}
              />
            ))}
          </AutomationSourceOptionsRow>
          <ContentSearchEditorV2
            queries={config.queriesList}
            onChangeQueries={handleChangeQueries}
            searchSourceKey="keywordsConfig"
            toggleAdvancedSearch={toggleAdvancedSearch}
            source={source}
          />
          <KeywordsSearchAdvancedSettings
            settings={config}
            onChangeSettings={handleChangeAdvancedSettings}
            source={source}
          />
          {advancedSearchIsOpen ? (
            <AdvancedSearchListPage
              type={advancedSearchSourceType}
              onClose={toggleAdvancedSearch}
            />
          ) : null}
        </>
      );
    case 2:
      return <MetricsContainer searchSource={source} />;
    case 3:
      return <SettingsStep data={data} type={CampaignPipelineType.GOOGLE_SEARCH} />;
    default:
      return null;
  }
};

export default KeywordsSearchSteps;
