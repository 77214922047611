import React from 'react';

export default ({ color = '#2752EB' }) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2278_1076)">
      <path
        d="M9.26889 3.86731H5.18555L6.88889 2.16398C6.94356 2.10975 6.98696 2.04523 7.01657 1.97415C7.04619 1.90306 7.06144 1.82682 7.06144 1.74981C7.06144 1.6728 7.04619 1.59656 7.01657 1.52548C6.98696 1.45439 6.94356 1.38987 6.88889 1.33564C6.77959 1.227 6.63175 1.16602 6.47764 1.16602C6.32353 1.16602 6.17568 1.227 6.06639 1.33564L3.38889 4.03648C3.33369 4.09143 3.29005 4.15689 3.26055 4.22898C3.20221 4.371 3.20221 4.53029 3.26055 4.67231C3.29226 4.74321 3.33564 4.80828 3.38889 4.86481L6.08972 7.58314C6.19742 7.69305 6.34419 7.75595 6.49805 7.75814C6.61392 7.75882 6.72736 7.72497 6.82391 7.66092C6.92047 7.59687 6.99576 7.50552 7.04019 7.39851C7.08462 7.2915 7.09618 7.17368 7.07338 7.06008C7.05059 6.94648 6.99449 6.84224 6.91222 6.76064L5.20889 5.03398H9.29222C9.75635 5.03398 10.2015 5.21835 10.5297 5.54654C10.8578 5.87473 11.0422 6.31985 11.0422 6.78398V12.2498C11.0422 12.4045 11.1037 12.5529 11.2131 12.6623C11.3225 12.7717 11.4708 12.8331 11.6256 12.8331C11.7803 12.8331 11.9286 12.7717 12.038 12.6623C12.1474 12.5529 12.2089 12.4045 12.2089 12.2498V6.78398C12.2089 6.39898 12.1327 6.0178 11.9847 5.6624C11.8366 5.30701 11.6197 4.98443 11.3464 4.71329C11.0731 4.44214 10.7488 4.22779 10.3922 4.0826C10.0357 3.9374 9.65387 3.86423 9.26889 3.86731Z"
        fill={color}
      />
      <path
        d="M3.31281 1.75L0.645508 4.4173L3.46107 7.23286"
        stroke={color}
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2278_1076">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
