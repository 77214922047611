import React from 'react';

import { SVGIcon, SvgIconType} from '@uikit/Icon/Icon';

interface CreateWorkspaceStepInfo {
  StepSettingsRenderer: ({ nextStep }: { nextStep?: () => void }) => JSX.Element;
  title: string;
  description: string;
  icon: string;
}

interface CreateWorkspaceStepProps {
  info: CreateWorkspaceStepInfo;
  nextStep: () => void;
}

export default ({
  info: { StepSettingsRenderer, title, description, icon },
  nextStep,
}: CreateWorkspaceStepProps): JSX.Element => (
  <div className="create-workspace-step">
    <div className="create-workspace-step__wrapper">
      <div className="create-workspace-step__header">
        <SVGIcon className="create-workspace-step__icon" icon={icon as SvgIconType} color="#251FB6" />
        <h1 className="create-workspace-step__title">{title}</h1>
        <p className="create-workspace-step__description">{description}</p>
      </div>
      <div className="create-workspace-step__settings">
        <StepSettingsRenderer nextStep={nextStep} />
      </div>
    </div>
  </div>
);
