import React, { useState } from 'react';

import { useDispatch } from 'react-redux';

import Button from '@uikit/Button/Button';

import FormTextField from '@components/Settings/Profile/_components/FormTextField/FormTextField';

import { GREY_COLOR } from '@constants/colors';
import { updateUserPasswordRequest } from '@redux/thunks/userProfileRequests';
import { addNotification } from '@redux/actions/notifications.actions';
import passwordValidation from '@utils/passwordValidation';

const passwordFieldsInitialState = {
  password: '',
  confirmPassword: '',
};

const PasswordBlock = ({ profile }) => {
  const dispatch = useDispatch();
  const [passwordFields, changePasswordFields] = useState(passwordFieldsInitialState);
  const [passwordError, changePasswordError] = useState('');
  const updatePassword = () => {
    const validations = passwordValidation(passwordFields.password);

    if (validations.score !== 3) {
      return dispatch(addNotification({ title: validations.text, type: 'error' }));
    }

    return dispatch(updateUserPasswordRequest(profile.email, passwordFields.password));
  };

  const handleChangePassword = ({ target: { value, name } }) => {
    changePasswordError('');
    changePasswordFields((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  return (
    <div className="profile-settings__block profile-settings__password-info">
      <p className="profile-settings__block-title">Password</p>
      <div className="profile-settings__password-body">
        <div className="profile-settings__password-inputs">
          <FormTextField
            label="New Password"
            name="password"
            value={passwordFields.password}
            onChange={handleChangePassword}
            type="password"
            additionalStyles={{ color: GREY_COLOR }}
            placeholder="*************"
          />
          <br />
        </div>
        <div className="profile-settings__password-block-right-column">
          <Button
            className="profile-settings__password-button"
            type="additional"
            onClick={updatePassword}
          >
            Update Password
          </Button>
          {passwordError !== '' ? (
            <div className="profile-settings__password-error">{passwordError}</div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default PasswordBlock;
