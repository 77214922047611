import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { DispatchType } from 'src/store';

import { insightsDateFilterSelector } from '@redux/selectors/insights.selector';

import HeaderFiltersButtonWithTooltip from '@components/PageHeader/HeaderFiltersButtonWithTooltip/HeaderFiltersButtonWithTooltip';
import HeaderDateFilter from '@uikit/HeaderDateFilter/HeaderDateFilter';
import {
  setInsightsCampaignPerformanceFilters,
  setInsightsDateFilter,
} from '@redux/actions/insights.actions';
import { addNotification } from '@redux/actions/notifications.actions';

import './InsightsCampaignHeader.scss';
import RelationshipsFiltersTooltipContentRaw from '@components/Relationships/RelationshipsFiltersTooltipContent/RelationshipsFiltersTooltipContentRaw/RelationshipsFiltersTooltipContentRaw';
import RelationshipsFiltersTooltipContentFooter from '@components/Relationships/RelationshipsFiltersTooltipContent/RelationshipsFiltersTooltipContentFooter/RelationshipsFiltersTooltipContentFooter';
import { initialInsightsFilterState } from '@redux/reducers/insights.reducer';
import { InsightsFilterField } from 'respona_api/generated/analytics_pb';

function InsightsCampaignHeader({
  filterFields,
  filterAction,
  filterClass = '',
  filtersValues,
  isFilterOptionsLoading,
}: {
  filterFields: any;
  filterAction: any;
  filterClass?: string;
  filtersValues: any;
  isFilterOptionsLoading?: boolean;
}): JSX.Element {
  const location = useLocation();
  const dispatch = useDispatch<DispatchType>();
  const params = new URLSearchParams(location.search);
  const initialFilterState = {};

  if (params.get('filterCampaignId') && params.get('filterCampaignTitle')) {
    const filterCampaignId = parseInt(params.get('filterCampaignId'), 10);

    initialFilterState['0'] = {
      ...initialInsightsFilterState,
      field: InsightsFilterField.DROPDOWN_INSIGHT_CAMPAIGN_ID,
      value: {
        value: filterCampaignId,
        label: params.get('filterCampaignTitle'),
      },
    };
  } else {
    filtersValues.forEach((item, index) => {
      initialFilterState[index] = structuredClone(item);
    });
  }
  const [filters, changeFilters] = useState(initialFilterState);
  const dateFilter = useSelector(insightsDateFilterSelector);

  useEffect(() => {
    if (params.get('filterCampaignId') && params.get('filterCampaignTitle')) {
      dispatch(setInsightsCampaignPerformanceFilters(initialFilterState));
    }
  }, []);

  const handleChangeDate = (value) => {
    dispatch(
      setInsightsDateFilter({
        startDate: +value.startDate,
        endDate: +value.endDate,
      })
    );
  };

  const areFiltersEnabled = useMemo(
    () => Object.values(filtersValues).some(({ value }) => value.toString().length > 0),
    [filtersValues]
  );

  const handleAddFilterInput = () =>
    changeFilters((prevState) => {
      const keys = Object.keys(prevState);
      const newKey = Number(keys[keys.length - 1]) + 1;

      return { ...prevState, [newKey]: initialInsightsFilterState };
    });

  const handleApplyFilters = () => {
    const filtersArray = Object.values(filters).map((item, index) => ({
      // @ts-ignore
      ...item,
      order: index,
    }));

    if (filtersArray.some(({ value }) => value === '')) {
      dispatch(addNotification({ type: 'warning', title: 'Some filter fields are empty' }));
      return;
    }

    dispatch(filterAction(filtersArray));
  };

  const handleChangeFilterState = (filterRawKey, filterInputKey, value) => {
    changeFilters((prevState) => {
      if (filterInputKey === 'join') {
        const newState = { ...prevState };
        Object.keys(newState).forEach((key) => {
          newState[key].join = value;
        });
        return newState;
      }
      return {
        ...prevState,
        [filterRawKey]: {
          ...prevState[filterRawKey],
          [filterInputKey]: value,
        },
      };
    });
  };

  const createHandleDeleteFilterRaw = (dummy: number) => () => {
    changeFilters((prevState) => {
      const newState = { ...prevState };
      delete newState[dummy];
      return newState;
    });
  };

  const resetFilter = () => {
    // since "initialFilterState" has a selected option by default
    // we shouldn't include it when the filter is reset
    const copyInitialFilterState = structuredClone(initialFilterState);
    copyInitialFilterState[0].value = '';

    return copyInitialFilterState;
  };

  return (
    <div className="insights-campaign-header">
      <div className="insights-campaign-header__date-filter">
        <HeaderDateFilter value={dateFilter} onChangeDatarange={handleChangeDate} />
      </div>
      <HeaderFiltersButtonWithTooltip
        areFiltersEnabled={areFiltersEnabled}
        onClearAllFilters={() => {
          dispatch(filterAction([]));
          changeFilters(resetFilter());
        }}
      >
        <div className={`campaign-filters-tooltip-content ${filterClass}`}>
          <div className="campaign-filters-tooltip-content__body">
            {isFilterOptionsLoading
              ? []
              : Object.entries(filters).map((filter, index) => {
                  const filterId = Number(filter[0]);
                  const filterRawData = filter[1];

                  return (
                    <RelationshipsFiltersTooltipContentRaw
                      key={filterId}
                      rawId={filterId}
                      isAdditional={!!index}
                      filterRawData={filterRawData}
                      onChangeFilter={handleChangeFilterState}
                      onDeleteFilterInput={createHandleDeleteFilterRaw(filterId)}
                      filterFields={filterFields}
                    />
                  );
                })}
          </div>
          <RelationshipsFiltersTooltipContentFooter
            onAddFilterInput={handleAddFilterInput}
            onApplyFilters={handleApplyFilters}
          />
        </div>
      </HeaderFiltersButtonWithTooltip>
    </div>
  );
}

export default InsightsCampaignHeader;
