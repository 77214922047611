import { useInfiniteQuery, useQueryClient } from 'react-query';
import { getAllAccountsApi } from '@api/mailboxAccounts.api';
import { MailboxAccountStatisticsType } from '@ts/mailboxAccounts.types';
import { QueryPageHookType } from '@ts/common.types';
import { useMemo, useCallback } from 'react';

export default (): QueryPageHookType<MailboxAccountStatisticsType> => {
  const queryClient = useQueryClient();
  const key = useMemo(() => ['organization-mailbox-accounts'], []);

  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage, refetch } =
    useInfiniteQuery<MailboxAccountStatisticsType[]>(
      key,
      ({ pageParam = 0 }) => getAllAccountsApi(pageParam, 10),
      {
        getNextPageParam: (lastPage: MailboxAccountStatisticsType[], allPages) => {
          if (lastPage.length < 10) return false;

          return allPages.length;
        },
        retry: 1,
        refetchOnWindowFocus: false,
        staleTime: 15 * 60 * 1000,
      }
    );

  const removeFromCacheById = (id: number) =>
    queryClient.setQueryData(key, ({ pages, pageParams }) => {
      const updatePages = pages.map((page) =>
        page.filter((item: MailboxAccountStatisticsType) => item.id != id)
      );

      return {
        pageParams,
        pages: updatePages,
      };
    });

  const addToCache = (item: MailboxAccountStatisticsType) =>
    queryClient.setQueryData(key, ({ pages, pageParams }) => {
      if (pages == null || pages.length === 0) {
        return {
          pageParams,
          pages: [[item]],
        };
      }

      const updatedPages = [...pages];
      updatedPages[updatedPages.length - 1] = [...updatedPages[updatedPages.length - 1], item];

      return {
        pageParams,
        pages: updatedPages,
      };
    });

  const updateInCache = (id: number, payload: MailboxAccountStatisticsType) =>
    queryClient.setQueryData(key, ({ pages, pageParams }) => {
      const updatePages = pages.map((page) =>
        page.map((item: MailboxAccountStatisticsType) => {
          if (item.id === id) return payload;
          return item;
        })
      );

      return {
        pageParams,
        pages: updatePages,
      };
    });

  const evictCacheByKey = useCallback(() => {
    queryClient.removeQueries(key);
  }, [queryClient, key]);

  return {
    items: data?.pages?.flat(),
    refetch,
    isLoading,
    isLoadingNextPage: isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    removeItem: removeFromCacheById,
    addItem: addToCache,
    updateItem: updateInCache,
    evictByKey: evictCacheByKey,
  };
};
