import AutomationContentService from '../../../respona_api/generated/automation-content_pb_service';

declare const API_HOST: string;

const service = (function () {
  let instance = null;

  return {
    getInstance: (): AutomationContentService.ContentAutomationServiceClient => {
      if (!instance) {
        instance = new AutomationContentService.ContentAutomationServiceClient(API_HOST);
      }

      return instance;
    },
  };
})();

export default service;
