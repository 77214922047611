import React, { useState } from 'react';
import { Button } from '@uikit/Button/Button';
import Textarea from '@uikit/Textarea/Textarea';

function NoteEditor({
  onSave,
  onClose,
  initialValue,
}: {
  onSave: (note: string) => void;
  onClose: () => void;
  initialValue?: string;
}) {
  const [newNote, setNewNote] = useState<string>(initialValue || '');

  return (
    <div className="contacts-sidebar-section-notes__note">
      <Textarea
        placeholder="Enter note"
        name="newNote"
        isFullWidth
        className="contacts-sidebar-section-notes__note-textarea"
        value={newNote}
        onChange={({ target: { value } }) => setNewNote(value)}
      />
      <div
        style={{ justifyContent: 'flex-end', marginTop: '5px' }}
        className="contacts-sidebar-section-notes__footer"
      >
        <Button
          onClick={() => {
            setNewNote('');
            onClose();
          }}
          className="contacts-sidebar-section-notes__footer-cancel-btn"
          type="bordered-grey"
        >
          Cancel
        </Button>
        <Button
          disabled={!newNote}
          className="contacts-sidebar-section-notes__footer-btn"
          type="primary"
          onClick={() => onSave(newNote)}
        >
          Save
        </Button>
      </div>
    </div>
  );
}

export default NoteEditor;
