import React from 'react';

import { BillingCancellationResponse } from 'respona_api/generated/billing_pb';

import BaseModal from '@uikit/BaseModal/BaseModal';
import { Button } from '@uikit/Button/Button';

import './BillingCancellationModal.scss';

const BillingCancellationModal = ({
  isOpen,
  onClose,
  onConfirm,
  details,
}: {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  details: BillingCancellationResponse.AsObject;
}): JSX.Element => {
  if (!details) {
    return null;
  }

  return (
    <BaseModal isOpen={isOpen} onClose={onClose}>
      <div className="billing-cancellation-modal">
        <div className="billing-cancellation-modal__title">Cancellation</div>
        <div className="billing-cancellation-modal__subtitle">
          Canceling your subscription will remove all of your campaign data and remaining credits.
        </div>

        <div className="billing-cancellation-modal__content">
          <div className="billing-cancellation-modal__content-item">
            <div className="billing-cancellation-modal__content-item-title">Credits</div>
            <div className="billing-cancellation-modal__content-item-value">
              {details.remainingCreditsNumber}
            </div>
          </div>

          <div className="billing-cancellation-modal__content-item">
            <div className="billing-cancellation-modal__content-item-title">Campaigns</div>
            <div className="billing-cancellation-modal__content-item-value">
              {details.campaignsNumber}
            </div>
          </div>

          <div className="billing-cancellation-modal__content-item">
            <div className="billing-cancellation-modal__content-item-title">Contacts</div>
            <div className="billing-cancellation-modal__content-item-value">
              {details.contactsNumber}
            </div>
          </div>

          <div className="billing-cancellation-modal__content-item">
            <div className="billing-cancellation-modal__content-item-title">Team Members</div>
            <div className="billing-cancellation-modal__content-item-value">
              {details.membersNumber}
            </div>
          </div>
        </div>

        <div className="billing-cancellation-modal__footer">
          <Button size="xl" type="primary" onClick={onClose}>
            Keep my account
          </Button>

          <Button size="xl" type="bordered-grey" onClick={onConfirm}>
            Delete my data & Continue
          </Button>
        </div>
      </div>
    </BaseModal>
  );
};

export default BillingCancellationModal;
