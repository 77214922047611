import React, { LegacyRef, useEffect } from "react";

import cn from 'class-names';
import useMouseEnterMouseLeave from '@hooks/useMouseEnterMouseLeave';

type DayItem = {
  day: number;
  time: string;
  isActive: boolean;
  isHighlighted: boolean;
  onHover: (ref: LegacyRef<HTMLDivElement>) => void;
};

const DayItem = ({ day, time, isActive, isHighlighted, onHover }: DayItem) => {
  const [ref, isHovered] = useMouseEnterMouseLeave<HTMLDivElement>();

  useEffect(() => {
    if (isHovered && ref) {
      onHover(ref);
    }
  }, [isHovered]);

  const cnDayItem = cn('schedule-days-grid-row__day', {
    'schedule-days-grid-row__day--active': isActive,
    'schedule-days-grid-row__day--highlighted': isHighlighted,
  });

  return <div ref={ref} className={cnDayItem} data-day={day} data-time={time} />;
};

export default DayItem;
