import mailboxTemplateService from '@api/services/mailboxTemplateService';
import streamRequest from '@api/base/streamRequest';
import request from '@api/base/request';
import { TemplateType } from '@ts/template.types';
import MailboxTemplateModels from '../../respona_api/generated/mailbox-template_pb';
import CommonModels from '../../respona_api/generated/common_pb';

// export const getAllEmailTemplatesOfWorkspaceApi = (
//   workspaceId: number
// ): Promise<TemplateType[]> => {
//   const client = mailboxTemplateService.getInstance();
//   const idRequest = new CommonModels.IdRequest();
//
//   idRequest.setId(workspaceId);
//
//   return streamRequest<TemplateType[]>(client, client.getAllEmailTemplatesOfWorkspace, idRequest);
// };

export const updateTemplateTitleApi = (
  workspaceId: number,
  id: number,
  title: string
): Promise<TemplateType> => {
  const client = mailboxTemplateService.getInstance();
  const emailTemplateRequest = new MailboxTemplateModels.TemplateRequest();

  emailTemplateRequest.setId(id);
  if (workspaceId) emailTemplateRequest.setWorkspaceId(workspaceId);
  emailTemplateRequest.setScope(1);
  emailTemplateRequest.setTitle(title);

  return request<TemplateType>(client, client.updateTemplate, emailTemplateRequest);
};

export const removeTemplateApi = (workspaceId: number, id: number): Promise<void> => {
  const client = mailboxTemplateService.getInstance();
  const idRequest = new CommonModels.IdRequest();

  idRequest.setId(id);
  idRequest.setOptionalWorkspaceId(workspaceId);

  return request<void>(client, client.deleteTemplate, idRequest);
};

export const changeOwnerOfTemplateApi = (
  id: number,
  userId: number,
  workspaceId: number
): Promise<void> => {
  const client = mailboxTemplateService.getInstance();
  const idRequest = new CommonModels.IdRequest();

  idRequest.setId(id);
  idRequest.setOptionalRelatedId(userId);
  idRequest.setOptionalWorkspaceId(workspaceId);

  return request<void>(client, client.changeOwnerOfTemplate, idRequest);
};
