import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Editor } from 'slate';
import './EditorFooter.scss';

import { SVGIcon } from '@uikit/Icon/Icon';
import Button from '@uikit/Button/Button';
import {
  AiButton,
  BlockButton,
  FlagMarkButton,
  LinkButton,
  MarkButton,
  MoreActionsButton,
} from '@uikit/RichEditor/SlateComponents';
import { useQueryAllTemplates } from '@queries/templates.queries';
import stripHtml from '@utils/stripHtml';
import urls from '@constants/urls';
import { nanoid } from 'nanoid';
import { MailboxAiActionsType } from '@ts/mailboxInbox.types';
import { useQuery } from 'react-query';
import { getAllAIAssistantActionsApi } from '@api/mailbox.api';
import { MailAIAction, MailAIActionMap } from 'respona_api/generated/mailbox_pb';
import { podcastLanguages } from '@constants/languages.contstants';
import { useSelector } from 'react-redux';
import { billingDetailsSelector } from '@redux/selectors/billings.selectors';

type EditorFooterProps = {
  onFileUpload: (files: unknown) => void;
  onChangeLink: (data: unknown) => void;
  onSend: () => Promise<unknown>;
  onDelete: () => void;
  editor: Editor;
  isDisabledLinkButton: boolean;
  isDisabledAIBtn: boolean;
  onApplyAiAction: (action: MailAIActionMap[keyof MailAIActionMap], language: string) => void;
  onTemplateSelected: (templateId: number) => void;
  isLoadingAI: boolean;
};

const EditorFooter: React.FC<EditorFooterProps> = ({
  onFileUpload,
  onChangeLink,
  onSend,
  onDelete,
  editor,
  isDisabledLinkButton,
  isDisabledAIBtn,
  onApplyAiAction,
  onTemplateSelected,
  isLoadingAI,
}) => {
  const [btnIsLoading, changeBtnIsLoading] = useState<boolean>(false);
  const [isImageUploading, setImageUploading] = useState<boolean>(false);
  const [options, setOptions] = useState([]);
  const { data: allTemplates = [] } = useQueryAllTemplates();
  const history = useHistory();
  const { data: billingDetails, loadingStatus: billingDetailsLs } =
    useSelector(billingDetailsSelector);

  const [isShownBlock, setIsShownBlock] = useState(false);

  const handleClickSend = () => {
    changeBtnIsLoading(true);
    onSend().finally(() => {
      changeBtnIsLoading(false);
    });
  };

  const parsedTemplates = allTemplates.map(({ id, title, subject }) => ({
    id,
    title: stripHtml(title),
    subject: stripHtml(subject).substring(0, 50),
    icon: 'flagMark',
  }));

  const aiTooltipId = useMemo(() => nanoid(), []);

  const {
    data: aiActionsData,
    isSuccess,
  }: {
    data: MailboxAiActionsType;
    isSuccess: boolean;
  } = useQuery(['mailbox-ai-actions'], () => getAllAIAssistantActionsApi(), {
    enabled: !!aiTooltipId,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60,
  });

  useEffect(() => {
    if (isSuccess && billingDetailsLs === 'loaded') {
      const types = new Set(aiActionsData.itemsList.map((it) => it.type));
      const aiOptions = [];
      const { enterpriseFeaturesEnabled, extendedFeaturesEnabled } = billingDetails;
      types.forEach((type) => {
        const itemsByType = aiActionsData.itemsList.filter((item) => item.type === type);
        aiOptions.push({
          optionTitle: type,
          optionItems: itemsByType.map((item) => ({
            ...item,
            preventCloseTooltip: ['Translate'].includes(item.title),
            isDisabled: !enterpriseFeaturesEnabled && !extendedFeaturesEnabled,
          })),
        });
      });
      setOptions(aiOptions);
    }
  }, [isSuccess, billingDetailsLs]);

  const handleActionSelected = (action: MailAIActionMap[keyof MailAIActionMap]) => {
    if (action === MailAIAction.TRANSLATE) {
      setIsShownBlock(true);
    } else {
      onApplyAiAction(action, '');
    }
  };
  const handleLanguageSelected = (language: string) => {
    onApplyAiAction(MailAIAction.TRANSLATE, language);
    setIsShownBlock(false);
  };

  const handlerTranslate = () => {
    setIsShownBlock(!isShownBlock);
  };

  const handleManageClick = () => {
    history.push(urls.TEMPLATES);
  };

  return (
    <div className="inbox-widget__footer">
      <Button
        className="inbox-widget__action-btn"
        isLoading={btnIsLoading}
        disabled={btnIsLoading}
        onClick={handleClickSend}
        leftIcon="play"
      >
        Send
      </Button>
      <MarkButton format="bold" icon="boldText" />
      <MarkButton format="italic" icon="italicText" />
      <MarkButton format="underline" icon="underlineText" />
      <div className="inbox-widget__controls-separator" />
      <BlockButton format="numbered-list" icon="orderedList" />
      <BlockButton format="bulleted-list" icon="unorderedList" />
      <div className="inbox-widget__controls-separator" />
      <LinkButton
        isDisabled={isDisabledLinkButton}
        onChangeLink={onChangeLink}
        editorRef={editor}
      />
      <div className="inbox-widget__controls-separator" />
      <FlagMarkButton
        titleHeader="Templates"
        templates={parsedTemplates}
        isSearchable
        handleManageClick={handleManageClick}
        onTemplateSelected={onTemplateSelected}
      />
      <AiButton
        titleHeader="AI assistant"
        languages={podcastLanguages}
        isSearchable
        options={options}
        isShownBlock={isShownBlock}
        isLoading={isLoadingAI}
        extraTitle="Translate to"
        backClick={handlerTranslate}
        isDisabledAIBtn={isDisabledAIBtn}
        onActionSelected={handleActionSelected}
        onLanguageSelected={handleLanguageSelected}
      />
      <MoreActionsButton onFileUpload={onFileUpload} setImageUploading={setImageUploading} />

      <span className="inbox-widget__trash-icon" onClick={onDelete}>
        <SVGIcon icon="trashSimple" />
      </span>

      {isImageUploading ? (
        <span className="inbox-widget__spinner">
          <SVGIcon icon="spinner" />
        </span>
      ) : null}
    </div>
  );
};

export default EditorFooter;
