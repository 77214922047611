import PipelineService from '../../../respona_api/generated/pipeline_pb_service';

const service = (function () {
  let instance = null;

  return {
    getInstance: (): PipelineService.PipelineServiceClient => {
      if (!instance) {
        // @ts-ignore
        instance = new PipelineService.PipelineServiceClient(API_HOST);
      }

      return instance;
    },
  };
})();

export default service;
