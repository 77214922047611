import React from 'react';

export default ({ color = '#C6C6C6' }) => (
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5 0C2.24292 0 0 2.24292 0 5C0 7.75708 2.24292 10 5 10C7.75708 10 10 7.75708 10 5C10 2.24292 7.75708 0 5 0ZM4.79167 7.91667C4.44667 7.91667 4.16667 7.63667 4.16667 7.29167C4.16667 6.94667 4.44667 6.66667 4.79167 6.66667C5.13667 6.66667 5.41667 6.94667 5.41667 7.29167C5.41667 7.63667 5.13667 7.91667 4.79167 7.91667ZM6.58792 4.21958C6.45917 4.42583 6.21458 4.6575 5.85375 4.915C5.27 5.34667 5.28583 5.4425 5.28583 5.83333H4.26208C4.26208 5.52792 4.25542 5.29333 4.42042 5.00792C4.52583 4.825 4.71917 4.63042 5 4.42458C5.33708 4.18375 5.66458 3.95167 5.66458 3.5475C5.66458 3.16875 5.34042 3.03375 4.96167 3.03375C4.57542 3.03375 4.135 3.16 3.64042 3.4125L3.21917 2.56667C4.11792 2.06292 5.53625 1.835 6.31167 2.46625C6.88083 2.93 6.88417 3.74542 6.58792 4.21958Z"
      fill={color}
    />
  </svg>
);
