import React from 'react';

const GreyRoundCheckmarkIcon = ({ color = '#E9ECF1' }): JSX.Element => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22Z"
      fill={color}
    />
    <path
      d="M8 11L10 13L14 9"
      stroke="#7B7B7B"
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default GreyRoundCheckmarkIcon;
