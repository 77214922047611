import React from 'react';

export default ({ size = 22, color = '#8F9199' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_21829_275)">
      <path
        d="M19.8412 9.16575C20.603 9.83767 21.0833 10.8213 21.0833 11.9167V15.5833C21.0833 17.6083 19.4416 19.25 17.4167 19.25H9.16667C7.14175 19.25 5.5 17.6083 5.5 15.5833V11.9167C5.5 9.89175 7.14175 8.25 9.16667 8.25H12.8333"
        stroke="#251FB6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.1581 12.8343C1.39635 12.1623 0.916016 11.1788 0.916016 10.0833V6.41667C0.916016 4.39175 2.55777 2.75 4.58268 2.75H12.8327C14.8576 2.75 16.4993 4.39175 16.4993 6.41667V10.0833C16.4993 12.1083 14.8576 13.75 12.8327 13.75H9.16602"
        stroke="#251FB6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_21829_275">
        <rect width="22" height="22" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
