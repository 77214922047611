import React, { useContext } from 'react';

import { LIGHT_MAIN_COLOR, PRIMARY_BLACK_COLOR } from '@constants/colors';

import Button from '@uikit/Button/Button';
import { SVGIcon } from '@uikit/Icon/Icon';

import { HeaderFiltersButtonWithTooltipContext } from '@components/PageHeader/HeaderFiltersButtonWithTooltip/HeaderFiltersButtonWithTooltip';

const FiltersTooltipContentFooter = ({
  onAddFilterInput,
  onApplyFilters,
}: {
  onAddFilterInput: () => void;
  onApplyFilters: () => void;
}): JSX.Element => {
  const { cb } = useContext(HeaderFiltersButtonWithTooltipContext);

  const handleClick = (event) => {
    onApplyFilters();
    // TODO: TOOLTIP dirty solution.
    //  ReactTooltip.hide should be used instead but it didn't work.
    // maybe in the new version of ReactTooltip it will be adjusted
    event.target.closest('.__react_component_tooltip').classList.remove('show');
    cb();
  };

  return (
    <div className="relationships-filters-tooltip-content__footer">
      <Button
        onClick={onAddFilterInput}
        className="relationships-filters-tooltip-content__btn-add-filter"
        size="l"
        type="black"
      >
        <SVGIcon icon="plusCircle" color={LIGHT_MAIN_COLOR} />
        Add new filter
      </Button>
      <Button
        onClick={handleClick}
        className="relationships-filters-tooltip-content__btn-apply-filters"
        size="l"
        type="white"
      >
        <SVGIcon icon="arrowLeft" color={PRIMARY_BLACK_COLOR} />
        Apply filters
      </Button>
    </div>
  );
};

export default FiltersTooltipContentFooter;
