import React from 'react';

export default function ({ color = '#8F9199', size = 12, backgroundIcon = '#E8EAED' }: { color?: string; size?: number; backgroundIcon?: string }) {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="17" cy="17" r="17" fill={backgroundIcon} />
      <g clipPath="url(#clip0_24153_1680)">
        <path
          d="M17 11H24.3333"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17 16.333H24.3333"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17 21.6665H24.3333"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.334 22.1108L11.1673 22.9997L13.6673 20.333"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.334 11.4443L11.1673 12.3332L13.6673 9.6665"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.0007 15H10.334V17.6667H13.0007V15Z"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_24153_1680">
          <rect width="16" height="16" fill="white" transform="translate(9 9)" />
        </clipPath>
      </defs>
    </svg>
  );
}
