import { handleActions } from 'redux-actions';

import { updateItemInListByIdHelper } from '@helpers/commonHelpers';
import { FolderType } from '@ts/common.types';
import { PeopleFilterType, PeopleTagsType } from '@ts/people.types';

import loadingStatuses from '@constants/loadingStatuses';
import {
  addFoldersData,
  addPeopleTag,
  removeFolderData,
  setFiltersForPeople,
  setFoldersData,
  setFoldersLs,
  setPeopleTagsData,
  setPeopleTagsLs,
  throwFiltersForPeople,
  updateFolderData,
} from '@redux/actions/people.actions';
import { FilterJoinType, FilterOperation } from 'respona_api/generated/common_pb';
import { PeopleFilterField } from 'respona_api/generated/people_pb';

export type PeopleStateType = {
  folders: {
    loadingStatus: string;
    items: FolderType[];
  };
  tags: {
    loadingStatus: string;
    items: PeopleTagsType;
  };
  filters: PeopleFilterType[];
};

export const initialRelationshipPeopleFilterRawState = {
  order: 0,
  field: PeopleFilterField.DROPDOWN_WORKSPACE_ID,
  value: '',
  operation: FilterOperation.NUMBER_EQUAL,
  join: FilterJoinType.JOIN_QUERY_AND,
};

const initialState: PeopleStateType = {
  folders: {
    loadingStatus: loadingStatuses.PENDING,
    items: [],
  },
  tags: {
    loadingStatus: loadingStatuses.PENDING,
    items: [],
  },
  filters: [initialRelationshipPeopleFilterRawState],
};

export default handleActions(
  {
    [setFoldersData]: (state: PeopleStateType, { payload }) => ({
      ...state,
      folders: {
        ...state.folders,
        items: payload,
      },
    }),
    [setFoldersLs]: (state: PeopleStateType, { payload }) => ({
      ...state,
      folders: {
        ...state.folders,
        loadingStatus: payload,
      },
    }),
    [addFoldersData]: (state: PeopleStateType, { payload }) => ({
      ...state,
      folders: {
        ...state.folders,
        items: [...state.folders.items, payload],
      },
    }),
    [updateFolderData]: (state: PeopleStateType, { payload }) => ({
      ...state,
      folders: {
        ...state.folders,
        items: updateItemInListByIdHelper(state.folders.items, payload),
      },
    }),
    [removeFolderData]: (state: PeopleStateType, { payload }) => ({
      ...state,
      folders: {
        ...state.folders,
        items: state.folders.items.filter(({ id }) => id !== payload),
      },
    }),
    [setPeopleTagsData]: (state: PeopleStateType, { payload }) => ({
      ...state,
      tags: {
        ...state.tags,
        items: payload,
      },
    }),
    [addPeopleTag]: (state: PeopleStateType, { payload }: { payload: PeopleTagsType }) => ({
      ...state,
      tags: {
        ...state.tags,
        items: {
          ...state.tags.items,
          ...payload,
        },
      },
    }),
    [setPeopleTagsLs]: (state: PeopleStateType, { payload }) => ({
      ...state,
      tags: {
        ...state.tags,
        loadingStatus: payload,
      },
    }),
    [setFiltersForPeople]: (state: PeopleStateType, { payload }): PeopleStateType => ({
      ...state,
      filters: payload,
    }),
    [throwFiltersForPeople]: (state: PeopleStateType): PeopleStateType => ({
      ...state,
      filters: [initialRelationshipPeopleFilterRawState],
    }),
  },
  initialState
);
