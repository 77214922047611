import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'class-names';

import { makePaymentApi } from '@api/billing.api';

import { fetchBillingCredits } from '@redux/thunks/billings.thunks';
import { billingDetailsSelector } from '@redux/selectors/billings.selectors';
import { addNotification } from '@redux/actions/notifications.actions';

import Button from '@uikit/Button/Button';

import UseCardToPay from '@components/UseCardToPay/UseCardToPay';

import './FixedPaymentFooter.scss';
import { userProfileSelector } from '@redux/selectors/userProfile.selector';
import gtm from 'src/libs/gtm';
import { BillingCycleTypeValue, BillingSubscriptionTypeMapValue } from '@ts/billing.types';
import { BillingSubscriptionType } from 'respona_api/generated/billing_pb';

function FixedPaymentFooter({
  invoiceId,
  paymentCallback,
  subscriptionType,
  billingCycle,
  price,
}: {
  invoiceId: number;
  paymentCallback?: () => void;
  subscriptionType: BillingSubscriptionTypeMapValue;
  billingCycle: BillingCycleTypeValue;
  price: number;
}): JSX.Element {
  const dispatch = useDispatch();
  const { info: profileFromRedux } = useSelector(userProfileSelector);
  const { data: billingDetails } = useSelector(billingDetailsSelector);

  const [isButtonPaymentByNewCardLoading, changeIsButtonPaymentByNewCardLoading] = useState(false);
  const [isButtonPaymentByExistingCardLoading, changeIsButtonPaymentByExistingCardLoading] =
    useState(false);

  const submitBtnRef = useRef<HTMLButtonElement>();
  const [useExistingCard, changeUseExistingCard] = useState(
    !!billingDetails?.card?.creditCardNumber
  );

  const cnPaymentBlock = cn('fixed-payment-footer__make-payment-block', {
    'fixed-payment-footer__make-payment-block--grey': useExistingCard,
  });

  const cnMakePaymentExistingCard = cn('fixed-payment-footer__make-payment-button', 'oldCard', {
    'fixed-payment-footer__make-payment-button--hidden': !useExistingCard,
  });

  const cnMakePaymentNewCard = cn('fixed-payment-footer__make-payment-button', 'newcard', {
    'fixed-payment-footer__make-payment-button--hidden': useExistingCard,
  });

  const handleMakePayment = () => {
    changeIsButtonPaymentByExistingCardLoading(true);
    makePaymentApi(invoiceId)
      .then(
        async ({
          isSuccess,
          message,
          optionalTransactionId,
          optionalTransactionCost,
          optionalSubscriptionPrice,
        }) => {
          if (isSuccess === false) {
            dispatch(addNotification({ title: message, type: 'error' }));
            return;
          }
          dispatch(addNotification({ title: 'Invoice paid', type: 'success' }));
          gtm.makePayment(
            profileFromRedux.email,
            optionalTransactionCost,
            optionalTransactionId,
            convertBillingType(subscriptionType),
            optionalSubscriptionPrice,
            billingCycle
          );
          await dispatch(fetchBillingCredits(true));
          if (paymentCallback) {
            paymentCallback();
          }
        }
      )
      .finally(() => {
        changeIsButtonPaymentByExistingCardLoading(false);
      });
  };

  function convertBillingType(value: BillingSubscriptionTypeMapValue): string {
    switch (value) {
      case BillingSubscriptionType.STARTER:
        return 'Starter';
      case BillingSubscriptionType.PRO:
        return 'Pro';
      case BillingSubscriptionType.TRIAL:
        return 'Trial';
      case BillingSubscriptionType.MAINTENANCE:
        return 'Maintenance';
      case BillingSubscriptionType.INTERNAL:
        return 'Internal';
      case BillingSubscriptionType.UNLIMITED:
        return 'Unlimited';
      case BillingSubscriptionType.ENTERPRISE:
        return 'Enterprise';
      default:
        return 'Unknown';
    }
  }

  return (
    <div className="fixed-payment-footer">
      <div className={cnPaymentBlock}>
        <UseCardToPay
          onToogle={() => changeUseExistingCard((prevState) => !prevState)}
          disableButtonHandler={(value) => console.log(value)}
          isCurrentCard={useExistingCard}
          submitBtnRef={submitBtnRef}
          gradientLoaderWidth={469}
          gradientLoaderHeight={72}
          buttonClickCallback={(nonce, deviceData) => {
            changeIsButtonPaymentByNewCardLoading(true);
            makePaymentApi(invoiceId, nonce, deviceData)
              .then(
                ({
                  isSuccess,
                  message,
                  optionalTransactionId,
                  optionalTransactionCost,
                  optionalSubscriptionPrice,
                }) => {
                  if (isSuccess === false) {
                    dispatch(addNotification({ title: message, type: 'error' }));
                    return;
                  }
                  gtm.makePayment(
                    profileFromRedux.email,
                    optionalTransactionCost,
                    optionalTransactionId,
                    convertBillingType(subscriptionType),
                    optionalSubscriptionPrice,
                    billingCycle
                  );
                  dispatch(addNotification({ title: 'Invoice paid', type: 'success' }));
                  dispatch(fetchBillingCredits(true));
                  if (paymentCallback) {
                    paymentCallback();
                  }
                }
              )
              .finally(() => {
                changeIsButtonPaymentByNewCardLoading(false);
              });
          }}
        />
        <Button
          disabled={isButtonPaymentByExistingCardLoading}
          isLoading={isButtonPaymentByExistingCardLoading}
          loadingText="Making payment..."
          className={cnMakePaymentExistingCard}
          onClick={handleMakePayment}
        >
          Make Payment
        </Button>
        <Button
          disabled={isButtonPaymentByNewCardLoading}
          isLoading={isButtonPaymentByNewCardLoading}
          loadingText="Making payment..."
          innerRef={submitBtnRef}
          className={cnMakePaymentNewCard}
        >
          Make Payment
        </Button>
      </div>
    </div>
  );
}

export default FixedPaymentFooter;
