import React from 'react';

export default ({ color = '#2752EA', size = 11 }):JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.85524 5.47787C7.34544 5.09219 7.70324 4.56333 7.87886 3.96484C8.05449 3.36635 8.0392 2.728 7.83513 2.1386C7.63107 1.5492 7.24837 1.03806 6.74028 0.676293C6.23218 0.314525 5.62397 0.120117 5.00024 0.120117C4.37652 0.120117 3.7683 0.314525 3.26021 0.676293C2.75212 1.03806 2.36941 1.5492 2.16535 2.1386C1.96128 2.728 1.946 3.36635 2.12162 3.96484C2.29725 4.56333 2.65505 5.09219 3.14524 5.47787C2.30528 5.81439 1.57239 6.37254 1.02469 7.09282C0.476991 7.81311 0.135024 8.66852 0.0352425 9.56786C0.0280198 9.63352 0.0338008 9.69997 0.0522552 9.76339C0.0707096 9.82682 0.101476 9.88599 0.142798 9.93753C0.226252 10.0416 0.347634 10.1083 0.480242 10.1229C0.612851 10.1375 0.745822 10.0988 0.849905 10.0153C0.953988 9.93186 1.02066 9.81047 1.03524 9.67787C1.14503 8.70046 1.61109 7.79777 2.34435 7.14226C3.07762 6.48675 4.02669 6.12438 5.01024 6.12438C5.99379 6.12438 6.94287 6.48675 7.67613 7.14226C8.4094 7.79777 8.87545 8.70046 8.98524 9.67787C8.99884 9.80072 9.05746 9.91419 9.14979 9.99637C9.24213 10.0786 9.36163 10.1236 9.48524 10.1229H9.54024C9.67131 10.1078 9.7911 10.0415 9.87352 9.93849C9.95594 9.83547 9.9943 9.70405 9.98024 9.57286C9.87999 8.67097 9.53618 7.81336 8.98567 7.09197C8.43515 6.37058 7.6987 5.8126 6.85524 5.47787ZM5.00024 5.12287C4.60468 5.12287 4.218 5.00557 3.8891 4.7858C3.5602 4.56604 3.30386 4.25368 3.15248 3.88823C3.00111 3.52278 2.9615 3.12065 3.03867 2.73268C3.11584 2.34472 3.30632 1.98836 3.58603 1.70865C3.86573 1.42895 4.2221 1.23847 4.61006 1.16129C4.99802 1.08412 5.40016 1.12373 5.76561 1.27511C6.13106 1.42648 6.44342 1.68283 6.66318 2.01172C6.88294 2.34062 7.00024 2.7273 7.00024 3.12287C7.00024 3.6533 6.78953 4.16201 6.41446 4.53708C6.03938 4.91215 5.53067 5.12287 5.00024 5.12287Z"
        fill={color}
      />
    </svg>
  );
};
