import React from 'react';

export default ({ color = '#C4C6CD' }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.3053 12.3175L11.0474 9.96825L13.3053 7.68254L16.7263 9.52381C16.9316 9.5873 17 9.71429 17 9.90476V10.0952C17 10.2222 16.8632 10.4127 16.7263 10.4762L13.3053 12.3175ZM10.5 9.39683L4.54737 3.04762C4.68421 2.98413 4.82105 2.98413 4.95789 3.04762L12.5526 7.2381L10.5 9.39683ZM10.5 10.6032L12.5526 12.8254L4.95789 16.9524C4.82105 17.0159 4.68421 17.0159 4.54737 16.9524L10.5 10.6032ZM4 3.68254L9.95263 9.96825L4 16.254V3.68254Z"
      fill={color}
    />
  </svg>
);
