import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getCurrentWorkspaceId } from '@redux/selectors/workspaces.selectors';
import { fetchInquiriesList } from '@redux/thunks/inquries.thunks';
import { inquiriesSelector } from '@redux/selectors/inquiries.selectors';
import { HaroOpportunityType } from '@ts/haro.types';

import InquiriestList from '../InquiriestList/InquiriestList';

const AllInquiries = ({
  searchString,
  filterId,
  onPitch,
}: {
  searchString: string;
  filterId: number;
  onPitch: (number) => void;
}): JSX.Element => {
  const dispatch = useDispatch();
  const [curFakePage, changeFakeCurPage] = useState(0);
  const curWorkspaceId = useSelector(getCurrentWorkspaceId);
  const inquiries = useSelector(inquiriesSelector);
  const makeInquiriesRequest = (workspaceId, query, page) =>
    dispatch(
      /*
        Math.ceil(window.innerHeight / 105) ->
        we count how many items to take in each query depends on screen height
       */
      fetchInquiriesList(workspaceId, query, page, filterId, Math.ceil(window.innerHeight / 105))
    );

  useEffect(() => {
    makeInquiriesRequest(curWorkspaceId, searchString, curFakePage);
  }, [searchString, filterId]);

  useEffect(() => {
    if (curFakePage > 0) {
      makeInquiriesRequest(curWorkspaceId, searchString, curFakePage);
    }
  }, [curFakePage]);

  useEffect(() => {
    changeFakeCurPage(0);
  }, [filterId]);

  const loadMore = () => {
    changeFakeCurPage((prevPage) => prevPage + 1);
  };

  return (
    <div className="all-inquiries">
      <InquiriestList
        inquiries={inquiries.items}
        loadingStatus={inquiries.loadingStatus}
        loadMore={loadMore}
        onPitch={onPitch}
      />
    </div>
  );
};

export default AllInquiries;
