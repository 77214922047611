import React, { useRef, useState } from 'react';
import useOutsideClick from '@hooks/useOutsideClick';
import Input from '@uikit/Input/Input';
import { Button } from '@uikit/Button/Button';
import { SVGIcon } from '@uikit/Icon/Icon';

function CreateItemComponent({
  onCreate,
  entityName,
  onCancel,
  isOpen = false,
  initialText = `New ${entityName}`,
}: {
  onCreate: (name?: string) => void;
  entityName: string;
  onCancel?: () => void;
  isOpen?: boolean;
  initialText?: string;
}) {
  const inputRef = useRef();
  const buttonRef = useRef();

  const [isCreating, setIsCreating] = useState<boolean>(isOpen);
  const [name, setName] = useState<string>(initialText);

  const resetState = () => {
    setIsCreating(false);
    setName(`New ${entityName.toLowerCase()}`);
  };

  const handleInputKeyDown = (e) => {
    if (e.keyCode === 27 || (e.keyCode === 13 && !name)) {
      onCancel && onCancel();
      resetState();
    } else if (e.keyCode === 13 && !!name) {
      onCreate(name);
      resetState();
    }
  };

  useOutsideClick([inputRef, buttonRef], () => {
    onCancel && onCancel();
    resetState();
  });

  if (isCreating) {
    return (
      <div className="relationships-folders__create-btn">
        <Input
          inputRef={inputRef}
          className="relationships-folders__create-input"
          value={name}
          onChange={({ target: { value } }) => setName(value)}
          onKeyDown={handleInputKeyDown}
          // onBlur={resetState}
          maxLength={25}
          placeholder={`${entityName} name`}
        />
        <Button
          innerRef={buttonRef}
          className="relationships-folders__create-input-btn"
          onClick={() => onCreate(name)}
          disabled={!name.length}
        >
          <SVGIcon icon="check" size={14} />
        </Button>
      </div>
    );
  }

  return (
    <div
      className="relationships-folders__create-btn relationships-folders__create-btn--inactive"
      onClick={() => setIsCreating(true)}
    >
      + Create {entityName}
    </div>
  );
}

export default CreateItemComponent;
