import React from 'react';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { useHistory } from 'react-router-dom';

import { BillingSubscriptionType } from 'respona_api/generated/billing_pb';

import { billingDetailsSelector } from '@redux/selectors/billings.selectors';

import { getApproximateTimePeriod, getTimeDifference } from '@utils/getFormattedPastTime';

import { SVGIcon } from '@uikit/Icon/Icon';

import './SidebarTrialCounter.scss';
import ColoredLabel from '@uikit/ColoredLabel/ColoredLabel';

const textTrialEnded = 'Trial ended';

const SidebarTrialCounter = () => {
  const history = useHistory();

  const { data: billingDetails, loadingStatus: billingDetailsLs } =
    useSelector(billingDetailsSelector);

  if (billingDetails.type !== BillingSubscriptionType.TRIAL) {
    return (
      <ReactTooltip
        id="user-link-account"
        className="react-tooltip"
        place="right"
        effect="solid"
        offset={{ right: 20, top: 0 }}
        arrowColor="transparent"
      >
        <div className="user-link__tooltip-content">
          Account
        </div>
      </ReactTooltip>
    );
  }

  const { paymentExpired } = billingDetails;
  const endDate =
    billingDetails.nextChargeTime < 0
      ? billingDetails.deactivatedAt
      : billingDetails.nextChargeTime;

  const trialEndDateText =
    getTimeDifference(endDate, 'm') < 0 || paymentExpired
      ? textTrialEnded
      : getApproximateTimePeriod(endDate, true).toLowerCase();

  return (
    <>
      <ColoredLabel
        color="orange"
        fontSize="small"
        position="moved-to-top"
      >
        <div
          className="sidebar-trial-counter"
          onClick={() => history.push('/settings/billing')}
        >
          <div className="sidebar-trial-counter__icon">
            <SVGIcon icon="warningTriangle" color="#FC8619" size={8} />
          </div>
          {trialEndDateText}
        </div>
      </ColoredLabel>

      <ReactTooltip
        id="user-link-account"
        className="react-tooltip"
        place="right"
        effect="solid"
        offset={{ right: 20, top: 0 }}
        arrowColor="transparent"
      >
        <div className="user-link__tooltip-content">
          Trial is ending in {trialEndDateText}
        </div>
      </ReactTooltip>
    </>
  );
};
export default SidebarTrialCounter;
