import FileImporterService from '../../../respona_api/generated/file-importer_pb_service';

const service = (function () {
  let instance = null;

  return {
    getInstance: (): FileImporterService.FileImporterServiceClient => {
      if (!instance) {
        // @ts-ignore
        instance = new FileImporterService.FileImporterServiceClient(API_HOST);
      }

      return instance;
    },
  };
})();

export default service;
