import {
  ContactAutomationPresetType,
  ContactAutomationSearchModes,
} from '@ts/automationContacts.types';

import createAction from '../utils/createAction';

export const SET_CONTACT_AUTOMATION_DATA = 'SET_CONTACT_AUTOMATION_DATA';
export const UPDATE_CURRENT_CONTACT_AUTOMATION_DATA = 'UPDATE_CURRENT_CONTACT_AUTOMATION_DATA';
export const SET_CURRENT_CONTACT_AUTOMATION_TYPE = 'SET_CURRENT_CONTACT_AUTOMATION_TYPE';
export const SET_CONTACT_AUTOMATION_LS = 'SET_CONTACT_AUTOMATION_LS';

export const setContactAutomationData = createAction<ContactAutomationPresetType[]>(
  SET_CONTACT_AUTOMATION_DATA
);
export const updateCurrentContactAutomationData = createAction<any>(
  UPDATE_CURRENT_CONTACT_AUTOMATION_DATA
);

export const setCurrentContactAutomationType = createAction<ContactAutomationSearchModes>(
  SET_CURRENT_CONTACT_AUTOMATION_TYPE
);

export const setCampaignAutomationLs = createAction<string>(SET_CONTACT_AUTOMATION_LS);
