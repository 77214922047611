import React from 'react';

const DownloadDocumentIcon = ({ color = '#BDBDBD' }: { color: string }): JSX.Element => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5554 6.28125L7.49989 10.9757M7.49989 10.9757L2.44434 6.28125M7.49989 10.9757V1"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 13.8646H14"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DownloadDocumentIcon;
