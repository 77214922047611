import React from 'react';

import { SVGIcon } from 'src/uikit/Icon/Icon';

import './FilterValue.scss';

const FilterValue = ({
  children,
  onEdit,
}: {
  children: JSX.Element;
  onEdit: () => void;
}): JSX.Element => (
  <div className="filter-value">
    <div className="filter-value__value">{children}</div>
    <div onClick={onEdit} className="filter-value__remove-btn">
      <SVGIcon icon="pen" />
    </div>
  </div>
);

export default FilterValue;
