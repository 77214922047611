import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb';
import { UserProfileType } from '@ts/user.types';
import userService from '@api/services/userService';
import request from '@api/base/request';
import streamRequest from '@api/base/streamRequest';
import * as common_pb from '../../respona_api/generated/common_pb';
import UserModels, { UserNotificationSettingResponse } from '../../respona_api/generated/user_pb';

export const getUserProfileApi = (): Promise<UserProfileType> => {
  const client = userService.getInstance();
  const emptyRequest = new google_protobuf_empty_pb.Empty();

  return request<UserProfileType>(client, client.getProfile, emptyRequest);
};

export const updateUserProfileApi = ({
  firstName,
  lastName,
  avatarUid,
}: UserProfileType): Promise<UserProfileType> => {
  const client = userService.getInstance();
  const updateRequest = new UserModels.UserProfileRequest();

  updateRequest.setFirstName(firstName);
  updateRequest.setLastName(lastName);
  if (avatarUid) {
    updateRequest.setAvatarUid(avatarUid);
  }

  return request<UserProfileType>(client, client.updateProfile, updateRequest);
};

export const updateAuthStepApi = (
  stepNumber: common_pb.AuthStepMap[keyof common_pb.AuthStepMap]
): Promise<google_protobuf_empty_pb.Empty | null> => {
  const client = userService.getInstance();
  const updateAuthInfoRequest = new UserModels.UpdateAuthInfoRequest();

  updateAuthInfoRequest.setStep(stepNumber);

  return request<null>(client, client.updateAuthStep, updateAuthInfoRequest);
};

/*
  Email subscriptions
 */
export const getNotificationSettingsApi = (): Promise<
  UserNotificationSettingResponse.AsObject[]
> => {
  const client = userService.getInstance();

  const emptyRequest = new google_protobuf_empty_pb.Empty();

  return streamRequest<UserNotificationSettingResponse.AsObject[]>(
    client,
    client.getNotificationSettings,
    emptyRequest
  );
};

export const updateNotificationSettingStateApi = (id: number, value: boolean): Promise<null> => {
  const client = userService.getInstance();
  const updateSubscriptionRequest = new UserModels.UserNotificationSettingRequest();

  updateSubscriptionRequest.setId(id);
  updateSubscriptionRequest.setIsDisabled(value);

  return request<null>(client, client.updateNotificationSettingState, updateSubscriptionRequest);
};

export const skipOnboardingSessionApi = () => {
  const client = userService.getInstance();
  const emptyRequest = new google_protobuf_empty_pb.Empty();

  return request<void>(client, client.skipOnboardingSession, emptyRequest);
};
