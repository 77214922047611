import React, { useMemo } from 'react';

import Select from '@uikit/Select/Select';
import { SVGIcon } from '@uikit/Icon/Icon';

import {
  FilterJoins,
  FilterOperationsMapForBoolean,
  FilterOperationsMapForDate,
  FilterOperationsMapForEnum,
  FilterOperationsMapForEnumString,
  FilterOperationsMapForNumber,
  FilterOperationsMapForString,
  FilterOperationsMapForStringMandatory,
} from '@constants/filterOperations';

import useNonInitialEffect from '@hooks/useNonInitialEffect';

import TypedInput from './_components/TypedInput/TypedInput';

import selectStyles from './selectStyles';

import './RelationshipsFiltersTooltipContentRaw.scss';
import { FilterProps } from '@ts/props.types';

type HeaderFiltersTooltipContentRawType = {
  isAdditional: boolean;
  rawId: number;
  filterRawData: any;
  onChangeFilter: (filterRawKey: number, filterInputKey: string, value: number | string) => void;
  onDeleteFilterInput: () => void;
  filterFields: FilterProps[];
};

const mappersByFieldType: {
  [key: string]: {
    mapper: { label: string; value: number }[];
    defaultValue: { label: string; value: number };
  };
} = {
  string: {
    mapper: FilterOperationsMapForString,
    defaultValue: FilterOperationsMapForString[2],
  },
  stringNotEmpty: {
    mapper: FilterOperationsMapForStringMandatory,
    defaultValue: FilterOperationsMapForStringMandatory[2],
  },
  number: {
    mapper: FilterOperationsMapForNumber,
    defaultValue: FilterOperationsMapForNumber[0],
  },
  boolean: {
    mapper: FilterOperationsMapForBoolean,
    defaultValue: FilterOperationsMapForBoolean[0],
  },
  enum: {
    mapper: FilterOperationsMapForEnum,
    defaultValue: FilterOperationsMapForEnum[0],
  },
  enumString: {
    mapper: FilterOperationsMapForEnumString,
    defaultValue: FilterOperationsMapForEnumString[0],
  },
  date: {
    mapper: FilterOperationsMapForDate,
    defaultValue: FilterOperationsMapForDate[0],
  },
  enumStringAsync: {
    mapper: FilterOperationsMapForEnumString,
    defaultValue: FilterOperationsMapForEnumString[0],
  },
};

function RelationshipsFiltersTooltipContentRaw({
  isAdditional = false,
  rawId,
  filterRawData,
  onChangeFilter,
  onDeleteFilterInput = () => null,
  filterFields,
}: HeaderFiltersTooltipContentRawType): JSX.Element {
  const handleChangeInputValue = (value: number | string) => onChangeFilter(rawId, 'value', value);
  const handleChangeFieldValue = (value) => onChangeFilter(rawId, 'field', value);
  const handleChangeOperationValue = (value) => onChangeFilter(rawId, 'operation', value);
  const { join, field, operation } = filterRawData;
  const selectedField = filterFields.find(({ value }) => value === field);

  const currentJoins = {
    label: FilterJoins[join].label,
    value: join,
  };

  const currentField = {
    label: selectedField.label,
    value: field,
  };

  const currentFieldType: string = selectedField.type;
  const { mapper: currentOperationTypeMapper, defaultValue } = useMemo(
    () => mappersByFieldType[currentFieldType],
    [currentFieldType]
  );
  const currentOperation = useMemo(
    () => currentOperationTypeMapper.find(({ value }) => value === operation),
    [currentOperationTypeMapper, operation]
  );

  useNonInitialEffect(() => {
    handleChangeOperationValue(defaultValue.value);
    handleChangeInputValue('');
  }, [field]);

  return (
    <div className={`filters-tooltip-raw ${isAdditional ? 'is-additional-row' : ''}`}>
      {isAdditional ? (
        <Select
          defaultValue={FilterJoins[0]}
          options={FilterJoins}
          className="filters-tooltip-raw__select--joins"
          additionalStyles={selectStyles}
          value={currentJoins}
          onChange={({ value }) => {
            onChangeFilter(rawId, 'join', value);
          }}
        />
      ) : null}
      <Select
        defaultValue={filterFields[0]}
        className="filters-tooltip-raw__select--fields"
        additionalStyles={selectStyles}
        options={filterFields}
        value={currentField}
        onChange={({ value }) => handleChangeFieldValue(value)}
      />
      <Select
        defaultValue={defaultValue}
        options={currentOperationTypeMapper}
        // isDisabled={currentOperationTypeMapper?.length === 1}
        className="filters-tooltip-raw__select--operations"
        additionalStyles={selectStyles}
        value={currentOperation || defaultValue}
        onChange={({ value }) => handleChangeOperationValue(value)}
      />
      <TypedInput
        type={currentFieldType}
        value={filterRawData.value}
        filterRawData={filterRawData}
        filterFields={selectedField}
        onChange={handleChangeInputValue}
        placeholder="Value"
      />
      {isAdditional ? (
        <div
          className="filters-tooltip-raw__cross"
          onClick={onDeleteFilterInput}
          tabIndex={0}
          role="button"
        >
          <SVGIcon icon="crossBlack" color="#BDBDBD" />
        </div>
      ) : null}
    </div>
  );
}

export default RelationshipsFiltersTooltipContentRaw;
