import React, { useRef } from 'react';

import { GradientBackground } from '@uikit/GradientBackgroundLoader/GradientBackgroundLoader';
import { SVGIcon } from '@uikit/Icon/Icon';
import FileExtensionIcon from '@uikit/FileExtensionIcon/FileExtensionIcon';

import { FileAttachmentType } from '@ts/common.types';

import getExtensionFromFileName from '@utils/getExtensionFromFileName';
import getFileSizeString from '@utils/getFileSizeString';

import useScrollTransform from '@hooks/useScrollTransform';

import './InboxEditorAttachments.scss';

const InboxEditorAttachments = ({
  isLoading,
  attachments = [],
  onRemoveFile,
}: {
  isLoading: boolean;
  attachments?: FileAttachmentType[];
  onRemoveFile: (fileUid: string) => void;
}): JSX.Element => {
  const attachmentsRef = useRef<HTMLDivElement>();

  useScrollTransform(attachmentsRef);

  if (!attachments?.length && !isLoading) {
    return null;
  }

  return (
    <div ref={attachmentsRef} className="inbox-editor-attachments">
      {attachments.map(({ key, title, size }) => (
        <div className="inbox-editor-attachments__file" key={key}>
          <FileExtensionIcon extension={getExtensionFromFileName(title)} />
          <div className="inbox-editor-attachments__file-text-info">
            <p className="inbox-editor-attachments__file-title" title={title}>
              {title}
            </p>
            <p className="inbox-editor-attachments__file-size" title={title}>
              {getFileSizeString(size)}
            </p>
          </div>
          <div
            className="inbox-editor-attachments__file-remove"
            onClick={() => onRemoveFile(key)}
            tabIndex={0}
            role="button"
          >
            <SVGIcon icon="crossDelete" />
          </div>
        </div>
      ))}
      {isLoading ? (
        <GradientBackground className="inbox-editor-attachments__loader" width={150} height={50} />
      ) : null}
    </div>
  );
};

export default InboxEditorAttachments;
