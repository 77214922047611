import { PersonBaseType, PersonShortType } from '@ts/people.types';

export const mapPeopleBaseToPeopleShort = (payload: PersonBaseType): PersonShortType => {
  return {
    id: payload.id,
    name: payload.name,
    status: payload.status,
    emailsList: payload.emailsList,
    jobPosition: payload.jobPosition,
    ownerId: payload.ownerId,
    workspaceId: payload.workspaceId,
    avatarUrl: payload.avatarUrl,
    socialLinksList: payload.socialLinksList,
    totalItems: -1,
  };
};
