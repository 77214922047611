import React from 'react';

const OpportunityIllustration = () => {
  return (
      <svg
        width="261"
        height="293"
        viewBox="0 0 261 293"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_21536_11340)">
          <path
            d="M207.458 51.632L203.217 65.6964L214.851 89.6572L216.368 81.1333L209.857 64.7744L215.084 49.441L207.458 51.632Z"
            fill="#8C8FB9"
          />
          <path
            d="M226.581 48.1133L235.508 60.1697L232.857 86.3095L228.445 78.6903L228.898 61.2695L218.596 48.3033L226.581 48.1133Z"
            fill="#8C8FB9"
          />
          <path
            d="M29.0797 273.69C28.6328 265.358 26.895 256.82 25.2142 248.562C22.453 234.964 19.5849 220.902 22.2442 206.977C23.8457 198.583 27.5059 191.295 31.3801 183.581C34.5001 177.365 37.7272 170.948 40.5539 162.938C45.9381 147.678 44.8681 126.412 38.0827 113.475C34.885 107.385 30.7347 103.615 25.7474 102.266L26.643 98.9409C32.5104 100.526 37.5149 104.998 41.1286 111.873C48.3195 125.569 49.4671 148.016 43.7965 164.085C40.899 172.3 37.4752 179.106 34.4553 185.128C30.5276 192.947 27.1349 199.7 25.6231 207.623C23.0933 220.881 25.8855 234.603 28.5862 247.875C30.2912 256.254 32.0531 264.92 32.5139 273.506L29.0797 273.69Z"
            fill="#3E3C75"
          />
          <path
            d="M95.2913 59.348C79.6961 59.348 63.8318 53.5762 51.9849 44.7432L54.0436 41.9807C74.5484 57.2692 107.642 62.9944 128.583 43.507C133.088 39.315 136.601 33.9869 139.999 28.8314C142.527 24.9985 145.142 21.0344 148.187 17.4708C161.72 1.6385 180.836 -2.05283 203.467 6.79221L202.214 10.0001C187.473 4.23866 166.587 1.24485 150.805 19.7101C147.897 23.1114 145.343 26.984 142.867 30.7306C139.357 36.0518 135.728 41.5543 130.922 46.0277C120.844 55.4166 108.16 59.348 95.2913 59.348Z"
            fill="#3E3C75"
          />
          <path
            d="M33.929 103.266C15.2207 103.266 0 88.0378 0 69.3187C0 50.5996 15.2207 35.3698 33.929 35.3698C52.6373 35.3698 67.8579 50.5979 67.8579 69.3152C67.8579 88.0326 52.639 103.266 33.929 103.266ZM33.929 38.8194C17.1189 38.8194 3.44277 52.5005 3.44277 69.3187C3.44277 86.1369 17.1189 99.8197 33.929 99.8197C50.739 99.8197 64.4152 86.13 64.4152 69.3187C64.4152 52.5074 50.739 38.8229 33.929 38.8229V38.8194Z"
            fill="#4A4C59"
          />
          <path
            d="M123.058 209.947L62.7429 61.1366C58.9878 51.1935 47.7846 46.1331 37.848 49.89L32.1221 52.0533C22.1838 55.812 17.1258 67.0207 20.8809 76.9638L72.2878 229.141L123.058 209.947Z"
            fill="#A1A4D6"
          />
          <path
            d="M41.0854 69.3566C38.2967 61.9722 40.3727 53.8938 45.6947 48.6831C43.0235 48.5326 40.3503 48.9443 37.848 49.8917L32.1221 52.055C22.1838 55.8137 17.1258 67.0224 20.8809 76.9655L72.2878 229.142L92.4784 221.508L41.0854 69.3566Z"
            fill="#8C8FB9"
          />
          <path
            d="M34.8017 74.5253L32.3992 75.4558L86.3866 214.991L88.7891 214.06L34.8017 74.5253Z"
            fill="#D1D4FF"
          />
          <path
            d="M51.8524 69.7516L49.439 70.6736L102.937 210.839L105.351 209.917L51.8524 69.7516Z"
            fill="#D1D4FF"
          />
          <path
            d="M227.925 49.9677L219.751 12.8471C219.063 10.073 217.319 7.67735 214.891 6.17045C212.464 4.66355 209.544 4.16455 206.754 4.77975C203.964 5.39495 201.525 7.07572 199.955 9.464C198.386 11.8523 197.811 14.7593 198.352 17.5658L206.527 54.6863L227.925 49.9677Z"
            fill="#5C5D6F"
          />
          <path
            d="M227.925 49.9677L219.751 12.8471C219.063 10.073 217.319 7.67735 214.891 6.17045C212.464 4.66355 209.544 4.16455 206.754 4.77975C203.964 5.39495 201.525 7.07572 199.955 9.464C198.386 11.8523 197.811 14.7593 198.352 17.5658L206.527 54.6863L227.925 49.9677Z"
            fill="#CACCF4"
          />
          <path
            d="M206.706 15.7529C206.248 13.6458 206.419 11.4506 207.197 9.43986C207.976 7.42909 209.327 5.69133 211.084 4.44238C209.303 4.10224 207.466 4.20984 205.738 4.75549C204.009 5.30114 202.443 6.26777 201.18 7.5686C199.917 8.86942 198.996 10.4637 198.501 12.2083C198.006 13.9529 197.952 15.7933 198.343 17.564L206.518 54.6846L214.875 52.8424L206.706 15.7529Z"
            fill="#A1A4D6"
          />
          <path
            d="M227.217 46.7886L205.81 51.5053L206.516 54.709L227.922 49.9922L227.217 46.7886Z"
            fill="#A1A4D6"
          />
          <path d="M260.256 273.599H11.2031V293H260.256V273.599Z" fill="#8C8FB9" />
          <path
            d="M206.116 4.74628L130.627 27.1256C127.94 27.9219 125.679 29.7536 124.342 32.2176C123.004 34.6816 122.7 37.5762 123.496 40.2646C124.292 42.9529 126.123 45.2149 128.586 46.5528C131.048 47.8906 133.942 48.1949 136.629 47.3986L212.121 25.0106L206.116 4.74628Z"
            fill="#77788C"
          />
          <path
            d="M210.416 19.5375L135.067 41.8754C132.839 42.535 130.456 42.4411 128.287 41.6083C126.117 40.7756 124.283 39.2504 123.068 37.269C123.022 38.9371 123.372 40.5922 124.089 42.0988C124.807 43.6054 125.87 44.9206 127.193 45.9366C128.516 46.9525 130.061 47.6404 131.701 47.9437C133.341 48.2471 135.03 48.1573 136.629 47.6817L212.121 25.2955L210.416 19.5375Z"
            fill="#6C6E7F"
          />
          <path
            d="M182.474 8.39789L83.9967 37.5919C82.2314 38.0799 80.581 38.9151 79.142 40.0486C77.7029 41.182 76.5041 42.5911 75.6155 44.1933C74.727 45.7956 74.1665 47.559 73.9669 49.3804C73.7673 51.2019 73.9325 53.0449 74.4529 54.8017C74.9732 56.5586 75.8384 58.194 76.9977 59.6126C78.157 61.0311 79.5873 62.2042 81.205 63.0634C82.8226 63.9226 84.5952 64.4507 86.4192 64.6167C88.2431 64.7827 90.0818 64.5834 91.8279 64.0303L190.307 34.8519L182.474 8.39789Z"
            fill="#959CB3"
          />
          <path
            d="M187.77 26.2761L89.7364 55.3389C86.8649 56.188 83.7951 56.0784 80.9913 55.0268C78.1876 53.9751 75.8024 52.0386 74.1965 49.5101C73.9816 51.7559 74.3211 54.0201 75.1853 56.1038C76.0495 58.1876 77.412 60.0272 79.153 61.4609C80.894 62.8947 82.9602 63.8788 85.1703 64.3268C87.3803 64.7748 89.6665 64.673 91.828 64.0303L190.307 34.8518L187.77 26.2761Z"
            fill="#8990A4"
          />
          <path
            d="M136.378 273.599V226.161C136.378 201.507 116.402 181.521 91.7605 181.521C67.1193 181.521 47.1426 201.507 47.1426 226.161V273.599H136.378Z"
            fill="#CACCF4"
          />
          <path
            d="M91.6484 181.523C67.0589 181.583 47.1409 201.551 47.1409 226.161V273.599H91.6484V181.523Z"
            fill="#A1A4D6"
          />
          <path
            d="M91.7606 250.051C105.912 250.051 117.384 238.573 117.384 224.415C117.384 210.257 105.912 198.779 91.7606 198.779C77.6093 198.779 66.1375 210.257 66.1375 224.415C66.1375 238.573 77.6093 250.051 91.7606 250.051Z"
            fill="#CACCF4"
          />
          <path
            d="M153.805 13.4825L31.9444 49.6068C29.7967 50.2435 27.7954 51.2972 26.0547 52.7076C24.314 54.1181 22.868 55.8578 21.7994 57.8274C19.6411 61.8051 19.1506 66.4778 20.4357 70.8173C21.7209 75.1569 24.6765 78.808 28.6523 80.9673C32.6281 83.1266 37.2985 83.6174 41.6359 82.3316L163.491 46.2056L153.805 13.4825Z"
            fill="#CACCF4"
          />
          <path
            d="M41.636 69.2393C37.5983 70.4354 33.2611 70.095 29.4592 68.2835C25.6574 66.472 22.6597 63.3176 21.0432 59.4274C19.8055 62.4047 19.4366 65.6721 19.9793 68.8506C20.522 72.0292 21.9542 74.9887 24.1096 77.3861C26.2651 79.7835 29.0556 81.5206 32.1576 82.3959C35.2596 83.2712 38.5461 83.2489 41.636 82.3316L163.491 46.2055L159.927 34.1699L41.636 69.2393Z"
            fill="#A1A4D6"
          />
          <path d="M91.4568 273.599H11.2031V293H91.4568V273.599Z" fill="#CACCF4" />
          <path
            d="M109.037 224.415C109.037 227.834 108.024 231.176 106.126 234.019C104.227 236.862 101.529 239.078 98.372 240.387C95.2149 241.695 91.7409 242.038 88.3893 241.371C85.0376 240.704 81.9588 239.058 79.5423 236.641C77.1258 234.223 75.4801 231.143 74.8133 227.79C74.1465 224.437 74.4886 220.961 75.7962 217.802C77.1039 214.643 79.3184 211.944 82.1598 210.044C85.0011 208.145 88.3416 207.131 91.7589 207.131C94.0278 207.131 96.2744 207.577 98.3707 208.446C100.467 209.314 102.372 210.588 103.976 212.193C105.58 213.798 106.853 215.703 107.721 217.8C108.59 219.897 109.037 222.145 109.037 224.415Z"
            fill="#8585D2"
          />
          <path
            d="M76.9489 225.934C76.946 222.984 77.699 220.082 79.136 217.505C80.573 214.928 82.646 212.763 85.1573 211.216C87.6685 209.669 90.5342 208.791 93.4808 208.667C96.4274 208.543 99.3565 209.177 101.989 210.508C100.065 209.098 97.8695 208.103 95.5418 207.585C93.2141 207.067 90.8043 207.037 88.4645 207.498C86.1247 207.958 83.9056 208.899 81.9473 210.26C79.989 211.621 78.3339 213.374 77.0866 215.407C75.8392 217.441 75.0266 219.711 74.7 222.074C74.3734 224.437 74.5399 226.843 75.189 229.138C75.8381 231.434 76.9557 233.57 78.4713 235.412C79.987 237.254 81.8677 238.762 83.9949 239.84C81.8114 238.239 80.0357 236.145 78.8115 233.729C77.5874 231.313 76.9493 228.643 76.9489 225.934Z"
            fill="#454395"
          />
          <path
            d="M100.191 224.425C100.195 226.502 99.4228 228.504 98.0267 230.04C97.6185 230.516 97.1484 230.936 96.6288 231.287C95.0074 232.435 93.0322 232.973 91.0525 232.805C89.0729 232.637 87.2164 231.774 85.8115 230.369C84.4067 228.963 83.544 227.106 83.3761 225.125C83.2082 223.145 83.7459 221.168 84.8941 219.546C85.2549 219.035 85.6729 218.566 86.14 218.15C87.3521 217.064 88.8523 216.352 90.4596 216.1C92.067 215.849 93.7128 216.068 95.1983 216.732C96.6839 217.395 97.9458 218.475 98.8316 219.84C99.7175 221.205 100.19 222.798 100.191 224.425Z"
            fill="#A1A4D6"
          />
          <path
            d="M98.0266 230.032C97.6185 230.508 97.1483 230.927 96.6288 231.278L84.894 219.538C85.2549 219.026 85.6729 218.557 86.14 218.141L98.0266 230.032Z"
            fill="#CACCF4"
          />
          <path
            d="M69.6509 75.5687V65.2095H63.2658C62.6731 62.5438 61.6221 60.0014 60.1595 57.6956L64.6791 53.1721L57.3622 45.8499L52.8408 50.3734C50.5358 48.9098 47.9939 47.8582 45.3289 47.2656V40.8774H34.9747V47.2656C32.3097 47.8586 29.7679 48.9101 27.4627 50.3734L22.9431 45.8499L15.6158 53.1756L20.1354 57.6991C18.6728 60.0048 17.6218 62.5472 17.0291 65.213H10.644V75.5722H17.0291C17.6218 78.2384 18.6728 80.7814 20.1354 83.0878L15.6158 87.6061L22.9345 94.9353L27.4541 90.4135C29.7593 91.8768 32.3011 92.9283 34.966 93.5212V99.9094H45.3202V93.5212C47.9853 92.9286 50.5271 91.8771 52.8322 90.4135L57.3484 94.9353L64.6653 87.613L60.1457 83.0912C61.6083 80.7849 62.6593 78.2419 63.252 75.5756L69.6509 75.5687Z"
            fill="#3E3C75"
          />
          <path
            d="M69.6509 75.5687V65.2095H63.2658C62.6732 62.5438 61.6221 60.0013 60.1596 57.6956L64.6792 53.1721L57.3622 45.8499L57.2673 45.9448C57.9018 48.5498 58.2233 51.2212 58.2251 53.9024C58.2251 72.884 42.4712 88.2708 23.0381 88.2708C20.577 88.2714 18.1223 88.0197 15.7125 87.5198L15.6296 87.6027L22.9345 94.9353L27.4541 90.4135C29.7594 91.8767 32.3011 92.9283 34.9661 93.5212V99.9094H45.3203V93.5212C47.9854 92.9286 50.5272 91.877 52.8323 90.4135L57.3484 94.9353L64.6654 87.613L60.1458 83.0912C61.6083 80.7849 62.6594 78.2419 63.252 75.5756L69.6509 75.5687Z"
            fill="#343263"
          />
          <path
            d="M213.971 26.6595C211.449 27.7505 208.661 28.0689 205.958 27.5743C203.255 27.0797 200.759 25.7943 198.787 23.8807C196.814 21.9671 195.453 19.5112 194.876 16.8237C194.298 14.1361 194.53 11.3376 195.543 8.78199C196.555 6.2264 198.302 4.02852 200.563 2.46633C202.824 0.904127 205.497 0.047769 208.244 0.00554643C210.991 -0.0366761 213.689 0.737134 215.997 2.22912C218.305 3.72111 220.118 5.86425 221.209 8.38753C221.933 10.0627 222.32 11.8642 222.348 13.6891C222.376 15.514 222.045 17.3266 221.373 19.0233C220.701 20.72 219.701 22.2677 218.431 23.5779C217.161 24.8882 215.646 25.9353 213.971 26.6595Z"
            fill="#3E3C75"
          />
          <path
            d="M221.209 8.38748C220.273 6.22174 218.801 4.33057 216.931 2.8928C215.061 1.45504 212.856 0.518206 210.523 0.170898C213.822 1.26822 216.588 3.56412 218.276 6.60444C219.964 9.64476 220.449 13.2081 219.637 16.5894C218.825 19.9708 216.773 22.924 213.889 24.8648C211.005 26.8056 207.497 27.5927 204.061 27.0703C206.604 27.9254 209.34 28.0244 211.938 27.3555C214.536 26.6865 216.885 25.2783 218.7 23.3012C220.514 21.3242 221.717 18.8632 222.163 16.2162C222.608 13.5693 222.277 10.8499 221.209 8.38748Z"
            fill="#454395"
          />
          <path
            d="M212.166 22.4829C210.47 23.2171 208.594 23.4314 206.775 23.0988C204.957 22.7662 203.279 21.9016 201.951 20.6143C200.624 19.3271 199.709 17.675 199.32 15.8671C198.932 14.0592 199.088 12.1766 199.769 10.4574C200.45 8.7383 201.625 7.25982 203.146 6.209C204.667 5.15817 206.465 4.58221 208.313 4.55395C210.162 4.52569 211.977 5.0464 213.529 6.05023C215.081 7.05406 216.301 8.49592 217.034 10.1934C218.017 12.4691 218.056 15.0419 217.143 17.3464C216.23 19.6509 214.44 21.4984 212.166 22.4829Z"
            fill="#CACCF4"
          />
          <path
            d="M206.171 8.62234C204.868 9.1808 203.822 10.2069 203.237 11.4987C203.05 11.8817 202.914 12.2882 202.835 12.7073C202.554 14.0347 202.751 15.4188 203.392 16.6146C204.033 17.8103 205.076 18.7406 206.337 19.2406C207.598 19.7406 208.995 19.7781 210.281 19.3464C211.566 18.9147 212.658 18.0418 213.362 16.8821C213.584 16.5175 213.764 16.1288 213.899 15.7236C214.251 14.6696 214.29 13.5363 214.012 12.4605C213.734 11.3847 213.15 10.4125 212.332 9.66118C211.514 8.90988 210.496 8.41167 209.401 8.22671C208.305 8.04175 207.18 8.17796 206.161 8.61889L206.171 8.62234Z"
            fill="#A1A4D6"
          />
          <path
            d="M203.242 11.4987C203.055 11.8816 202.919 12.2882 202.84 12.7073L213.367 16.8821C213.589 16.5175 213.769 16.1288 213.904 15.7236L203.242 11.4987Z"
            fill="#CACCF4"
          />
          <path
            d="M212.168 79.6934L205.539 65.3994L209.379 52.5678L210.204 52.8164L206.457 65.3338L212.949 79.3308L212.168 79.6934Z"
            fill="#D1D4FF"
          />
          <path
            d="M231.715 76.6755L230.855 76.6219L231.817 61.2748L223.813 49.9159L224.515 49.4186L232.693 61.0261L231.715 76.6755Z"
            fill="#D1D4FF"
          />
          <path
            d="M210.775 76.9793C210.311 77.4144 209.821 77.8771 209.376 78.3761L214.855 89.6607L216.372 81.1368L213.657 74.317C212.698 75.2062 211.728 76.0833 210.775 76.9793Z"
            fill="#8990A4"
          />
          <path
            d="M210.775 76.9793C210.311 77.4144 209.821 77.8771 209.376 78.3761L214.855 89.6607L216.372 81.1368L213.657 74.317C212.698 75.2062 211.728 76.0833 210.775 76.9793Z"
            fill="#505492"
          />
          <path
            d="M215.835 79.7919L214.285 88.4919L214.851 89.6573L216.368 81.1334L215.835 79.7919Z"
            fill="#8C8FB9"
          />
          <path
            d="M232.426 72.9962C233.011 73.2483 233.537 73.471 234.126 73.7904L232.864 86.3026L228.451 78.6834L228.641 71.4164C229.835 71.9396 231.225 72.4765 232.426 72.9962Z"
            fill="#505492"
          />
          <path
            d="M228.481 77.2797L232.995 84.9386L232.857 86.3095L228.445 78.7162L228.481 77.2797Z"
            fill="#8C8FB9"
          />
          <path
            d="M60.4667 70.3408C60.467 74.3147 59.2895 78.1994 57.083 81.5038C54.8765 84.8081 51.7401 87.3836 48.0705 88.9045C44.401 90.4254 40.363 90.8234 36.4673 90.0482C32.5717 89.273 28.9932 87.3594 26.1846 84.5495C23.376 81.7395 21.4633 78.1593 20.6885 74.2618C19.9137 70.3642 20.3116 66.3243 21.8317 62.6529C23.3519 58.9816 25.9261 55.8437 29.2288 53.6361C32.5316 51.4286 36.4144 50.2505 40.3864 50.2508C45.712 50.2508 50.8195 52.3674 54.5853 56.135C58.3511 59.9026 60.4667 65.0126 60.4667 70.3408Z"
            fill="#8585D2"
          />
          <path
            d="M56.3475 70.3408C56.3475 73.4991 55.4114 76.5865 53.6576 79.2125C51.9038 81.8386 49.411 83.8853 46.4945 85.094C43.5781 86.3026 40.3688 86.6188 37.2727 86.0027C34.1766 85.3865 31.3326 83.8657 29.1004 81.6324C26.8682 79.3991 25.3481 76.5538 24.7322 73.4561C24.1164 70.3585 24.4325 67.1477 25.6405 64.2298C26.8486 61.3119 28.8943 58.818 31.5191 57.0633C34.1439 55.3086 37.2298 54.3721 40.3865 54.3721C44.6197 54.3721 48.6794 56.0545 51.6727 59.0492C54.6659 62.0439 56.3475 66.1056 56.3475 70.3408Z"
            fill="#CACCF4"
          />
          <path
            d="M30.5604 70.3477C30.5548 72.7676 31.4548 75.102 33.0833 76.8913C33.557 77.4478 34.1045 77.937 34.7107 78.345C36.6007 79.6876 38.9049 80.3174 41.2147 80.1225C43.5245 79.9277 45.6907 78.9209 47.3294 77.2806C48.968 75.6404 49.9732 73.4725 50.1668 71.1615C50.3604 68.8505 49.7298 66.5456 48.3868 64.6553C47.9663 64.0588 47.4788 63.5125 46.9338 63.0272C45.521 61.7597 43.7716 60.9288 41.8969 60.6349C40.0222 60.341 38.1025 60.5966 36.37 61.3709C34.6374 62.1452 33.1661 63.4051 32.1339 64.9981C31.1016 66.5911 30.5527 68.4492 30.5535 70.3477H30.5604Z"
            fill="#A1A4D6"
          />
          <path
            d="M33.0835 76.8912C33.5571 77.4478 34.1046 77.9369 34.7108 78.345L48.387 64.6553C47.9665 64.0588 47.4789 63.5125 46.934 63.0272L33.0835 76.8912Z"
            fill="#CACCF4"
          />
        </g>
        <defs>
          <clipPath id="clip0_21536_11340">
            <rect width="260.258" height="293" fill="white" />
          </clipPath>
        </defs>
      </svg>
  );
};
export default OpportunityIllustration;
