import React from 'react';

export default ({
  color = '#ffffff',
  size = 17,
}: {
  color?: string;
  size?;
  number;
}): JSX.Element => (
  <svg width={size} height={size} viewBox="0 0 17 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 7.81818L6.45455 2.36364L10.5455 6.45455L16 1"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);
