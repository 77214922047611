import React from 'react';

export default ({
  color = '#200E32',
  size = 14,
}: {
  color: string;
  size?: number;
}): JSX.Element => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.8831 5.31231C11.8831 5.31231 11.5211 9.80231 11.3111 11.6936C11.2111 12.597 10.6531 13.1263 9.73908 13.143C7.99974 13.1743 6.25841 13.1763 4.51974 13.1396C3.64041 13.1216 3.09174 12.5856 2.99374 11.6983C2.78241 9.79031 2.42241 5.31231 2.42241 5.31231M12.8053 3.15998H1.5M10.6269 3.15998C10.1036 3.15998 9.65293 2.78998 9.55026 2.27732L9.38826 1.46665C9.28826 1.09265 8.94959 0.833984 8.56359 0.833984H5.74159C5.35559 0.833984 5.01693 1.09265 4.91693 1.46665L4.75493 2.27732C4.65226 2.78998 4.20159 3.15998 3.67826 3.15998"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
