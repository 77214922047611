import React from 'react';

export default ({
  color = '#C4C6CD',
  size = 20,
}: {
  color?: string;
  size?: number;
}): JSX.Element => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0004 10.4887L13.5362 14.0237L12.3571 15.2028L10.8337 13.6795V18.3337H9.16707V13.6778L7.64373 15.2028L6.46457 14.0237L10.0004 10.4887ZM10.0004 1.66699C11.4312 1.66706 12.8121 2.19292 13.8806 3.14459C14.949 4.09626 15.6305 5.40738 15.7954 6.82866C16.8323 7.11142 17.7368 7.74958 18.351 8.6316C18.9651 9.51362 19.2497 10.5834 19.1551 11.654C19.0605 12.7246 18.5926 13.7279 17.8334 14.4885C17.0741 15.2492 16.0716 15.7188 15.0012 15.8153V14.137C15.3847 14.0822 15.7534 13.9516 16.0859 13.7528C16.4183 13.554 16.7079 13.2909 16.9375 12.979C17.1672 12.6671 17.3325 12.3125 17.4236 11.936C17.5148 11.5595 17.53 11.1687 17.4685 10.7862C17.4069 10.4038 17.2698 10.0374 17.0652 9.70853C16.8605 9.37965 16.5924 9.09484 16.2764 8.87072C15.9605 8.64659 15.603 8.48765 15.225 8.40317C14.8469 8.31868 14.4559 8.31035 14.0746 8.37866C14.2051 7.77106 14.198 7.14197 14.0539 6.53745C13.9098 5.93294 13.6323 5.36831 13.2417 4.88493C12.8512 4.40155 12.3574 4.01166 11.7966 3.74382C11.2359 3.47598 10.6223 3.33697 10.0008 3.33697C9.37936 3.33697 8.76578 3.47598 8.20501 3.74382C7.64423 4.01166 7.15048 4.40155 6.7599 4.88493C6.36933 5.36831 6.09182 5.93294 5.94772 6.53745C5.80361 7.14197 5.79656 7.77106 5.92707 8.37866C5.16678 8.23588 4.38091 8.40098 3.74235 8.83763C3.10379 9.27427 2.66484 9.9467 2.52207 10.707C2.37929 11.4673 2.54439 12.2531 2.98104 12.8917C3.41768 13.5303 4.09011 13.9692 4.8504 14.112L5.0004 14.137V15.8153C3.92994 15.7189 2.9274 15.2494 2.16801 14.4888C1.40862 13.7282 0.940673 12.725 0.845962 11.6543C0.751251 10.5837 1.0358 9.5139 1.64987 8.6318C2.26395 7.74971 3.16849 7.11148 4.2054 6.82866C4.37016 5.40731 5.05157 4.09608 6.12005 3.14438C7.18853 2.19268 8.56953 1.66688 10.0004 1.66699Z"
      fill={color}
    />
  </svg>
);
