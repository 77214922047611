import {
  CampaignDailyInsightsType,
  CampaignHourlyInsightsType,
  CreditUsageItemType,
  DailyCreditUsageType,
  InsightsResponseType,
  TeamMembersInsightsType,
} from '@ts/insights.types';
import { CampaignPersonStatisticType } from '@ts/people.types';

import analyticsService from '@api/services/analyticsService';
import campaignsService from '@api/services/campaignsService';
import streamRequest from '@api/base/streamRequest';
import request from '@api/base/request';
import { DateRangeNumberType } from '@ts/common.types';
import { getLastSixMonthsRangeInMs } from '@helpers/dateTimeRangeHelpers';
import { CampaignPersonStatisticsFilterRequest } from 'respona_api/generated/campaign_pb';
import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb';
import { generateFilter } from '@api/base/filter';
import { PeopleWebsiteFilterType } from '@ts/company.types';
import AnalyticsModels, {
  InsightsFilterField,
  InsightsFilterFieldMap,
} from 'respona_api/generated/analytics_pb';

export const getInsightsByCriteriaApi = (
  ids: number[],
  type: InsightsFilterFieldMap[keyof InsightsFilterFieldMap] = InsightsFilterField.DROPDOWN_INSIGHT_WORKSPACE_ID,
  workspaceId = 0,
  dateRange: DateRangeNumberType = getLastSixMonthsRangeInMs()
): Promise<InsightsResponseType> => {
  const client = analyticsService.getInstance();
  const insightsRequest = new AnalyticsModels.CampaignInsightsRequest();

  insightsRequest.setType(type);
  insightsRequest.setIdsList(ids);

  if (workspaceId) {
    insightsRequest.setOptionalWorkspaceId(workspaceId);
  }

  if (dateRange.startDate) {
    insightsRequest.setDateFrom(dateRange.startDate);
  }
  if (dateRange.endDate) {
    insightsRequest.setDateTo(dateRange.endDate);
  }

  const timeZone = -(new Date().getTimezoneOffset() / 60);
  // This is to add sign after UTC
  const UTCSign = timeZone < 0 ? '-' : '+';
  const timezoneAbs = Math.abs(timeZone);
  const UTCTimezone =
    timeZone < 10 ? `UTC${UTCSign}0${timezoneAbs}` : `UTC${UTCSign}${timezoneAbs}`;
  insightsRequest.setTimezone(UTCTimezone);

  return request<InsightsResponseType>(client, client.getInsightsByCriteria, insightsRequest);
};

export const getCampaignEmailDailyInsightsApi = (
  filtersList: any[],
  dateRange: DateRangeNumberType = getLastSixMonthsRangeInMs()
): Promise<CampaignDailyInsightsType> => {
  const client = analyticsService.getInstance();
  const insightsRequest = new AnalyticsModels.InsightsRequest();

  if (dateRange.startDate) {
    insightsRequest.setDateFrom(dateRange.startDate);
  }
  if (dateRange.endDate) {
    insightsRequest.setDateTo(dateRange.endDate);
  }

  insightsRequest.setFiltersList(
    filtersList.map((filterData) =>
      generateFilter(new AnalyticsModels.InsightsFilter(), filterData)
    )
  );

  const timeZone = -(new Date().getTimezoneOffset() / 60);
  // This is to add sign after UTC
  const UTCSign = timeZone < 0 ? '-' : '+';
  const timezoneAbs = Math.abs(timeZone);
  const UTCTimezone =
    timeZone < 10 ? `UTC${UTCSign}0${timezoneAbs}` : `UTC${UTCSign}${timezoneAbs}`;
  insightsRequest.setTimezone(UTCTimezone);

  return request<CampaignDailyInsightsType>(
    client,
    client.getCampaignEmailDailyInsights,
    insightsRequest
  );
};

export const getCampaignEmailHourlyInsightsApi = (
  filtersList: any[],
  dateRange: DateRangeNumberType = getLastSixMonthsRangeInMs()
): Promise<CampaignHourlyInsightsType> => {
  const client = analyticsService.getInstance();
  const insightsRequest = new AnalyticsModels.InsightsRequest();

  if (dateRange.startDate) {
    insightsRequest.setDateFrom(dateRange.startDate);
  }
  if (dateRange.endDate) {
    insightsRequest.setDateTo(dateRange.endDate);
  }

  insightsRequest.setFiltersList(
    filtersList.map((filterData) => {
      return generateFilter(new AnalyticsModels.InsightsFilter(), filterData);
    })
  );

  const timeZone = -(new Date().getTimezoneOffset() / 60);
  // This is to add sign after UTC
  const UTCSign = timeZone < 0 ? '-' : '+';
  const timezoneAbs = Math.abs(timeZone);
  const UTCTimezone =
    timeZone < 10 ? `UTC${UTCSign}0${timezoneAbs}` : `UTC${UTCSign}${timezoneAbs}`;
  insightsRequest.setTimezone(UTCTimezone);

  return request<CampaignHourlyInsightsType>(
    client,
    client.getCampaignEmailHourlyInsights,
    insightsRequest
  );
};

export const getCampaignPersonDailyInsightsApi = (
  filtersList: any[],
  dateRange: DateRangeNumberType = getLastSixMonthsRangeInMs()
): Promise<CampaignDailyInsightsType> => {
  const client = analyticsService.getInstance();
  const insightsRequest = new AnalyticsModels.InsightsRequest();

  if (dateRange.startDate) {
    insightsRequest.setDateFrom(dateRange.startDate);
  }
  if (dateRange.endDate) {
    insightsRequest.setDateTo(dateRange.endDate);
  }

  insightsRequest.setFiltersList(
    filtersList.map((filterData) => {
      return generateFilter(new AnalyticsModels.InsightsFilter(), filterData);
    })
  );

  const timeZone = -(new Date().getTimezoneOffset() / 60);
  // This is to add sign after UTC
  const UTCSign = timeZone < 0 ? '-' : '+';
  const timezoneAbs = Math.abs(timeZone);
  const UTCTimezone =
    timeZone < 10 ? `UTC${UTCSign}0${timezoneAbs}` : `UTC${UTCSign}${timezoneAbs}`;
  insightsRequest.setTimezone(UTCTimezone);

  return request<CampaignDailyInsightsType>(
    client,
    client.getCampaignPersonDailyInsights,
    insightsRequest
  );
};

export const getCampaignWebsiteDailyInsightsApi = (
  filtersList: PeopleWebsiteFilterType[],
  dateRange: DateRangeNumberType = getLastSixMonthsRangeInMs()
): Promise<CampaignDailyInsightsType> => {
  const client = analyticsService.getInstance();
  const insightsRequest = new AnalyticsModels.InsightsRequest();

  if (dateRange.startDate) {
    insightsRequest.setDateFrom(dateRange.startDate);
  }
  if (dateRange.endDate) {
    insightsRequest.setDateTo(dateRange.endDate);
  }

  insightsRequest.setFiltersList(
    filtersList.map((filterData) => {
      return generateFilter(new AnalyticsModels.InsightsFilter(), filterData);
    })
  );

  const timeZone = -(new Date().getTimezoneOffset() / 60);
  // This is to add sign after UTC
  const UTCSign = timeZone < 0 ? '-' : '+';
  const timezoneAbs = Math.abs(timeZone);
  const UTCTimezone =
    timeZone < 10 ? `UTC${UTCSign}0${timezoneAbs}` : `UTC${UTCSign}${timezoneAbs}`;
  insightsRequest.setTimezone(UTCTimezone);

  return request<CampaignDailyInsightsType>(
    client,
    client.getCampaignWebsiteDailyInsights,
    insightsRequest
  );
};

export const getTeamMembersInsightsApi = (
  filtersList: any[],
  dateRange: DateRangeNumberType = getLastSixMonthsRangeInMs()
): Promise<TeamMembersInsightsType> => {
  const client = analyticsService.getInstance();
  const insightsRequest = new AnalyticsModels.InsightsRequest();

  if (dateRange.startDate) {
    insightsRequest.setDateFrom(dateRange.startDate);
  }
  if (dateRange.endDate) {
    insightsRequest.setDateTo(dateRange.endDate);
  }

  insightsRequest.setFiltersList(
    filtersList.map((filterData) => {
      return generateFilter(new AnalyticsModels.InsightsFilter(), filterData);
    })
  );

  const timeZone = -(new Date().getTimezoneOffset() / 60);
  // This is to add sign after UTC
  const UTCSign = timeZone < 0 ? '-' : '+';
  const timezoneAbs = Math.abs(timeZone);
  const UTCTimezone =
    timeZone < 10 ? `UTC${UTCSign}0${timezoneAbs}` : `UTC${UTCSign}${timezoneAbs}`;
  insightsRequest.setTimezone(UTCTimezone);

  return request<TeamMembersInsightsType>(client, client.getTeamMembersInsights, insightsRequest);
};

export const getCreditUsagePageableApi = (
  page: number,
  limit: number,
  filtersList: any[],
  dateRange: DateRangeNumberType = getLastSixMonthsRangeInMs()
): Promise<CreditUsageItemType[]> => {
  const client = analyticsService.getInstance();
  const insightsRequest = new AnalyticsModels.InsightsPageableRequest();

  insightsRequest.setPage(page);
  insightsRequest.setLimit(limit);

  if (dateRange.startDate) {
    insightsRequest.setDateFrom(dateRange.startDate);
  }
  if (dateRange.endDate) {
    insightsRequest.setDateTo(dateRange.endDate);
  }

  insightsRequest.setFiltersList(
    filtersList.map((filterData) => {
      return generateFilter(new AnalyticsModels.InsightsFilter(), filterData);
    })
  );

  const timeZone = -(new Date().getTimezoneOffset() / 60);
  // This is to add sign after UTC
  const UTCSign = timeZone < 0 ? '-' : '+';
  const timezoneAbs = Math.abs(timeZone);
  const UTCTimezone =
    timeZone < 10 ? `UTC${UTCSign}0${timezoneAbs}` : `UTC${UTCSign}${timezoneAbs}`;
  insightsRequest.setTimezone(UTCTimezone);

  return streamRequest<CreditUsageItemType[]>(
    client,
    client.getCreditUsagePageable,
    insightsRequest
  );
};

export const getCreditUsageDailyInsightsApi = (
  filtersList: any[],
  dateRange: DateRangeNumberType = getLastSixMonthsRangeInMs()
): Promise<DailyCreditUsageType> => {
  const client = analyticsService.getInstance();
  const insightsRequest = new AnalyticsModels.InsightsRequest();

  if (dateRange.startDate) {
    insightsRequest.setDateFrom(dateRange.startDate);
  }
  if (dateRange.endDate) {
    insightsRequest.setDateTo(dateRange.endDate);
  }

  insightsRequest.setFiltersList(
    filtersList.map((filterData) => {
      return generateFilter(new AnalyticsModels.InsightsFilter(), filterData);
    })
  );

  const timeZone = -(new Date().getTimezoneOffset() / 60);
  // This is to add sign after UTC
  const UTCSign = timeZone < 0 ? '-' : '+';
  const timezoneAbs = Math.abs(timeZone);
  const UTCTimezone =
    timeZone < 10 ? `UTC${UTCSign}0${timezoneAbs}` : `UTC${UTCSign}${timezoneAbs}`;
  insightsRequest.setTimezone(UTCTimezone);

  return request<DailyCreditUsageType>(client, client.getCreditUsageDailyInsights, insightsRequest);
};

export const getCampaignEmailChartSettingsApi = (): Promise<TeamMembersInsightsType> => {
  const client = analyticsService.getInstance();
  const emptyRequest = new google_protobuf_empty_pb.Empty();

  return streamRequest<TeamMembersInsightsType>(
    client,
    client.getCampaignEmailChartSettings,
    emptyRequest
  );
};

export const getCampaignPeopleStatisticsByFilterApi = (
  campaignId: number,
  workspaceId: number,
  page = 0,
  limit = 50,
  query: string
): Promise<CampaignPersonStatisticType[]> => {
  const client = campaignsService.getInstance();
  const pageRequest = new CampaignPersonStatisticsFilterRequest();

  if (query) {
    pageRequest.setQuery(query);
  }

  pageRequest.setCampaignId(campaignId);
  pageRequest.setWorkspaceId(workspaceId);
  pageRequest.setPage(page);
  pageRequest.setLimit(limit);

  return streamRequest<CampaignPersonStatisticType[]>(
    client,
    client.getCampaignPeopleStatisticsByFilter,
    pageRequest
  );
};

export const setOnboardingStep = (step: 1 | 2): Promise<void> => {
  const client = analyticsService.getInstance();
  const analyticsRequest = new AnalyticsModels.OnboardingStepRequest();

  analyticsRequest.setStep(step);

  return request<void>(client, client.updateUserOnboardingStep, analyticsRequest);
};
