import React, { useRef } from 'react';

import { components } from 'react-select';
import ReactTooltip from 'react-tooltip';
import { nanoid } from 'nanoid';

import { textStyles } from '@uikit/Select/Select';

export const hiddenValuesCountStyles = {
  ...textStyles,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: '#E9E9E9',
  borderRadius: '6px',
  height: '20px',
  width: '30px',
  cursor: 'pointer',
} as React.CSSProperties;

const MultiValueContainer = (props: {
  selectProps: {
    value: any;
    visibleMultiCount: number;
    disableConvolution: boolean;
    renderMultipleValuesTooltipContent?: (dataToRender) => any;
    onChange: (value, action: unknown) => void;
    fieldToRender: string;
  };
  data: any;
}) => {
  const tooltipRef = useRef(null);
  const {
    selectProps: {
      value: selectValue,
      visibleMultiCount = 1,
      disableConvolution,
      renderMultipleValuesTooltipContent,
      onChange,
      fieldToRender,
    },
    data,
  } = props;

  if (selectValue.length > visibleMultiCount && !disableConvolution) {
    const index = selectValue.findIndex(({ value }) => value === data.value);
    const hiddenSelected = selectValue.slice(visibleMultiCount);

    const handleRemoveItem = (dataItem) => {
      const newSetOfValues = selectValue.filter(({ value }) => value !== dataItem.value);
      onChange(newSetOfValues, {});
      ReactTooltip.rebuild();
    };

    if (index === visibleMultiCount) {
      const tooltipId = nanoid();
      return (
        <>
          <span
            ref={tooltipRef}
            style={hiddenValuesCountStyles}
            data-place="top"
            data-tip=""
            data-for={tooltipId}
            className="sequence-step__edit-delay-btn"
            data-event="click focus"
          >
            +{selectValue.length - visibleMultiCount}
          </span>
          <ReactTooltip
            id={tooltipId}
            clickable
            className="react-tooltip select-multivalued-tooltip-wrapper"
            backgroundColor="#000"
            globalEventOff="click"
            arrowColor="transparent"
          >
            {renderMultipleValuesTooltipContent &&
              renderMultipleValuesTooltipContent({
                dataToRender: hiddenSelected,
                onRemoveItem: handleRemoveItem,
                fieldToRender,
              })}
          </ReactTooltip>
        </>
      );
    }

    if (index > visibleMultiCount) {
      return null;
    }
  }

  return <components.MultiValueContainer {...props} />;
};

export default MultiValueContainer;
