import { useQuery, useQueryClient } from 'react-query';
import { getAccountByIdApi } from '@api/mailboxAccounts.api';
import { MailboxAccountSettingsType } from '@ts/mailboxAccounts.types';
import { QueryObjectHookType } from '@ts/common.types';

export default function useMailboxAccountSettings(
  optionalId: number
): QueryObjectHookType<MailboxAccountSettingsType> {
  const queryClient = useQueryClient();

  const composeKey = (id: number) => ['mailbox-account-settings', id];

  const key = composeKey(optionalId);

  const { data, isLoading, isFetching, isRefetching, refetch } =
    useQuery<MailboxAccountSettingsType>({
      queryKey: key,
      queryFn: () => getAccountByIdApi(optionalId),
      refetchOnWindowFocus: false,
      retry: false,
      enabled: optionalId != null && optionalId > 0,
      staleTime: 15 * 60 * 1000,
    });

  const updateInCache = (payloadId: number, payload: MailboxAccountSettingsType) => {
    queryClient.setQueryData<MailboxAccountSettingsType>(composeKey(payloadId), (cachedData) => {
      return { ...cachedData, ...payload, id: payloadId };
    });
  };

  const evictCacheByKey = (id: number) => queryClient.removeQueries(composeKey(id));

  const loadingState = isLoading || isFetching || isRefetching;

  return {
    item: data,
    refetch,
    isLoading: loadingState,
    update: updateInCache,
    evictByKey: evictCacheByKey,
  };
}
