import React from 'react';

export default ({ color = '#fff' }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.16484 3.30037C2.73834 3.836 2.49976 4.6978 2.49976 5.97207V11.8185C2.49976 13.0134 2.84899 13.8696 3.40679 14.4268C3.9647 14.984 4.82285 15.3336 6.02164 15.3336H11.8613C13.0561 15.3336 13.9126 14.9843 14.4698 14.4271C15.027 13.8698 15.3763 13.0134 15.3763 11.8185V11.8144L15.3968 8.09432V8.0923C15.3966 6.7873 15.1544 5.98158 14.7438 5.50061C14.3562 5.04665 13.69 4.73674 12.4637 4.73674L10.34 4.73674C9.56649 4.7352 8.83847 4.37119 8.37315 3.75334L7.6882 2.84235C7.50662 2.60024 7.2211 2.45703 6.91846 2.45703H5.4192C4.22947 2.45703 3.56286 2.80051 3.16484 3.30037ZM1.9914 2.36601C2.75719 1.40426 3.9253 0.957031 5.4192 0.957031H6.91846C7.69313 0.957031 8.4226 1.32171 8.88747 1.94138L9.57136 2.85095C9.75378 3.09302 10.0392 3.23593 10.3423 3.23674H12.4637C13.9278 3.23674 15.1032 3.61143 15.8846 4.52666C16.6427 5.41463 16.8968 6.66213 16.8968 8.09432V8.09846L16.8763 11.8185V11.8208C16.8758 13.3086 16.4344 14.5838 15.5305 15.4877C14.6261 16.3921 13.35 16.8336 11.8613 16.8336H6.02164C4.52997 16.8336 3.25218 16.3924 2.34675 15.4881C1.44123 14.5836 0.999756 13.3073 0.999756 11.8185V5.97207C0.999756 4.56272 1.25408 3.292 1.9914 2.36601Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.58705 10.8669C4.58705 10.4527 4.92283 10.1169 5.33705 10.1169H12.5322C12.9464 10.1169 13.2822 10.4527 13.2822 10.8669C13.2822 11.2812 12.9464 11.6169 12.5322 11.6169H5.33705C4.92283 11.6169 4.58705 11.2812 4.58705 10.8669Z"
      fill={color}
    />
  </svg>
);
