export const isInternalPage = (pathname?: string): boolean => {
  const path = pathname || window.location.pathname;

  return (
    path !== '/welcome' &&
    path !== '/login' &&
    path !== '/register' &&
    path !== '/authorize/login' &&
    path !== '/authorize/microsoft/error' &&
    path !== '/confirm' &&
    path !== '/onboarding' &&
    path !== '/bd/login' &&
    path !== '/forgot-password' &&
    path !== '/update-password' &&
    path !== '/restore' &&
    path !== '/unsubscribe' &&
    path !== '/maintenance' &&
    path !== '/unsubscribed'
  );
};

export const redirectLoginManually = (): void => {
  if (isInternalPage()) {
    window.location.pathname = '/welcome';
  }
};
