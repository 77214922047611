/* eslint-disable react/destructuring-assignment */
import React, { useRef } from 'react';
import ReactTooltip from 'react-tooltip';

import Button from 'src/uikit/Button/Button';
import { SVGIcon } from 'src/uikit/Icon/Icon';

import { FolderType } from '@ts/common.types';

import './MoreActionsButton.scss';

type MoreActionsButtonPropsType = {
  folders: FolderType[];
  onCreateOrMoveFolder: (folderId?: number) => void;
  onChangeOwner: () => void;
  isSmallScreen?: boolean;
};

function MoreActionsButton({
  isSmallScreen,
  onCreateOrMoveFolder,
  onChangeOwner,
}: MoreActionsButtonPropsType): JSX.Element {
  const moreActionsTooltipRef = useRef();
  const handleMoreActionsClick = () => ReactTooltip.show(moreActionsTooltipRef.current);

  return (
    <>
      <Button
        innerRef={moreActionsTooltipRef}
        onClick={handleMoreActionsClick}
        data-for="organizations-more-actions"
        type="additional"
        className="organizations-header-actions__more-actions-btn"
        data-tip=""
        data-event="click"
      >
        <SVGIcon icon="actions" />
        {isSmallScreen ? '' : 'More actions'}
      </Button>
      <ReactTooltip
        id="organizations-more-actions"
        className="react-tooltip"
        place="bottom"
        effect="solid"
        globalEventOff="click"
        arrowColor="transparent"
        clickable
      >
        <div
          className="organizations-header-more-actions-tooltip"
          onClick={(e) => e.stopPropagation()}
        >
          <div
            className="organizations-header-more-actions-tooltip__action"
            onClick={() => onChangeOwner()}
          >
            <SVGIcon icon="user" color="#ffffff" size={18}/>
            Change owner
          </div>
          <div
            className="organizations-header-more-actions-tooltip__action"
            onClick={() => onCreateOrMoveFolder()}
          >
            <SVGIcon icon="folderWhite" />
            Move
          </div>
        </div>
      </ReactTooltip>
    </>
  );
}

export default MoreActionsButton;
