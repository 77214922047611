import { Dispatch } from 'redux';

import { getUserProfileApi, updateAuthStepApi, updateUserProfileApi } from '@api/user.api';
import { changePasswordApi } from '@api/profile.api';
import { uploadImageApi } from '@api/fileImport.api';
import loadingStatuses from '@constants/loadingStatuses';
import { UserProfileType } from '@ts/user.types';
import { addNotification } from '@redux/actions/notifications.actions';
import {
  setShowCheckListStatus,
  setUserAuthStep,
  setUserProfileData,
  setUserProfileLs,
} from '@redux/actions/userProfile.actions';
import { skipChecklistApi } from '@api/userOrganization.api';
import * as common_pb from '../../../respona_api/generated/common_pb';

export const fetchUserProfile = (dispatch): Promise<UserProfileType> => {
  dispatch(setUserProfileLs(loadingStatuses.INITED));

  return getUserProfileApi()
    .then((user) => {
      dispatch(setUserProfileData(user));
      return user;
    })
    .finally(() => {
      dispatch(setUserProfileLs(loadingStatuses.LOADED));
    });
};

export const updateUserProfile = (fields: UserProfileType) => (dispatch: Dispatch) => {
  return updateUserProfileApi(fields).then((user) => {
    dispatch(setUserProfileData(user));
    dispatch(addNotification({ title: 'Profile updated' }));
  });
};

export const updateUserPasswordRequest =
  (email: string, newPassword: string) => (dispatch: Dispatch) => {
    changePasswordApi(email, newPassword).then(() => {
      dispatch(addNotification({ title: 'Password updated' }));
    });
  };

export const updateUserAvatarRequest =
  (fields: UserProfileType, file: File) => (dispatch: Dispatch) => {
    return file
      .arrayBuffer()
      .then((buffer) => uploadImageApi(file, buffer))
      .then(({ uid }) => {
        // @ts-ignore
        return dispatch(updateUserProfile({ ...fields, avatarUid: uid }));
      })
      .catch(() => {
        dispatch(addNotification({ type: 'error', title: 'Server error' }));
      });
  };

export const updateAuthStepRequest =
  (newStep: common_pb.AuthStepMap[keyof common_pb.AuthStepMap]) => (dispatch: Dispatch) => {
    return updateAuthStepApi(newStep).then(() => dispatch(setUserAuthStep(newStep)));
  };

export const setShowCheckListStatusRequest = (status: boolean) => (dispatch: Dispatch) => {
  skipChecklistApi().then(() => dispatch(setShowCheckListStatus(status)));
};
