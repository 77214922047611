import React from 'react';

export default function ({ color = '#8F9199', size = 12, backgroundIcon = '#E8EAED' }: { color?: string; size?: number, backgroundIcon?: string }) {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="17" cy="17" r="17" fill={backgroundIcon} />
      <g clipPath="url(#clip0_24153_1672)">
        <path
          d="M16.5 13.25H24.2917"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.666 11.125H9.41602V15.375H13.666V11.125Z"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.5 21.75H24.2917"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.41602 21.7498L10.8327 23.1665L15.0827 18.9165"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_24153_1672">
          <rect width="17" height="17" fill="white" transform="translate(8 9)" />
        </clipPath>
      </defs>
    </svg>
  );
}
