import React from 'react';

export default ({ color = '#C4C6CD' }) => (
  <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.09512 11.9995H1V6.76172H3.09512"
      stroke={color}
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.31055 8.6543H6.27752"
      stroke={color}
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.31055 6.7207H6.27752"
      stroke={color}
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.28399 2.12814V1L12.5218 2.57134V11.9994H3.09375V4.66646H8.33155V11.9994"
      stroke={color}
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.4277 9.90381V4.66602"
      stroke={color}
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
