import React from 'react';

import Select from '@uikit/Select/Select';

import inviteMembersFieldsConfig from '@pages/CreateWorkspacePage/_components/StepInviteMembers/inviteMembersFieldsConfig';

import './StepInviteMembers.scss';

export default ({ nextStep }: { nextStep?: () => void }): JSX.Element => {
  return (
    <div className="step-invite-member">
      {inviteMembersFieldsConfig.map((field) => {
        return (
          <div className="step-invite-members__row">
            <div className="step-invite-members__labels">
              <div className="step-invite-members__labels-main">{field.title}</div>
              <div className="step-invite-members__labels-additional">{field.additional}</div>
            </div>
            <Select
              options={[
                {
                  value: 123,
                  label: 123,
                },
                {
                  value: 456,
                  label: 456,
                },
              ]}
            />
          </div>
        );
      })}
    </div>
  );
};
