import stripHtml from '@utils/stripHtml';
import { BoundaryType, ParsedValueType, ParseInputsType } from '@ts/moderateChance.types';

const boundaries: BoundaryType[] = [
  {
    min: 0,
    max: 1,
    color: 'red',
  },
  {
    min: 8,
    max: 10,
    color: 'yellow',
  },
  {
    min: 2,
    max: 7,
    color: 'green',
  },
  {
    min: 11,
    color: 'red',
  },
];

export default {
  key: 'subjectLength',
  label: 'Subject length (words)',
  min: boundaries[0].min,
  max: boundaries[boundaries.length - 1].min,
  greenZone: {
    min: 2,
    max: 7,
  },
  parse: ({ subject = '' }: ParseInputsType): ParsedValueType => {
    const numWords = stripHtml(subject)
      .trim()
      .split(' ')
      .filter((word) => word).length;

    return {
      value: numWords,
      boundary: boundaries.find(({ min, max }) => {
        if (!max && numWords >= max) {
          return true;
        }

        if (min <= numWords && numWords <= max) {
          return true;
        }

        return false;
      }),
    };
  },
};
