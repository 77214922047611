// @ts-nocheck
import React, { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import FilterHeaderButtons from '@components/CampainsHeader/CampaignsFilters/_components/FilterHeaderButtons/FilterHeaderButtons';
import TeamFilter from '@components/Filters/TeamFilter/TeamFilter';
import FilterByCampaign from '@components/Filters/FilterByCampaign/FilterByCampaign';
import Input from '@uikit/Input/Input';

import {
  setInsightsFiltersCampaignIds,
  setInsightsFiltersPeopleIds,
} from '@redux/actions/insights.actions';

import { insightsFiltersSelector } from '@redux/selectors/insights.selector';

import './InsightsFiltersInTooltip.scss';

const InsightsFiltersInTooltip = ({ innerRef }): JSX.Element => {
  const dispatch = useDispatch();
  const [search, changeSearch] = useState('');
  const [teamFilterActive, changeTeamFilterActive] = useState(true);

  // itemsIds can be campaigns and peoples
  const filters = useSelector(insightsFiltersSelector);

  const toogleTeamFilterActive = useCallback(() => {
    changeTeamFilterActive((prevState) => !prevState);
  }, []);

  const setInsightsFiltersPeoplesIds = useCallback((ids: number[]) => {
    dispatch(setInsightsFiltersPeopleIds(ids));
  }, []);

  const setInsightsFiltersCampaignsIds = useCallback(
    (ids: number[]) => dispatch(setInsightsFiltersCampaignIds(ids)),
    []
  );

  useEffect(() => {
    innerRef.current = { reset: () => changeTeamFilterActive(true) };
  }, [innerRef, changeTeamFilterActive]);

  return (
    <div className="insights-filters">
      <div className="insights-filters__header">
        <div className="insights-filters__search-input">
          <Input
            isTransparent
            icon="search"
            value={search}
            onChange={(e) => changeSearch(e.target.value)}
            placeholder="Search"
          />
        </div>
        <div className="insights-filters__toogle_buttons">
          <FilterHeaderButtons
            leftButtonName="Team"
            rightButtonName="Campaigns"
            onToogleFilterActive={toogleTeamFilterActive}
            leftFilterActive={teamFilterActive}
          />
        </div>
      </div>
      <div className="insights-filters__body">
        {teamFilterActive ? (
          <TeamFilter
            isSearchable={false}
            chosenPeopleIds={filters.peopleIds}
            gatherChosenPeopleIds={setInsightsFiltersPeoplesIds}
            searchStringFromOutside={search}
          />
        ) : (
          <FilterByCampaign
            chosenCampaignsIds={filters.campaignsIds}
            gatherChosenCampaigns={setInsightsFiltersCampaignsIds}
            searchString={search}
          />
        )}
      </div>
    </div>
  );
};

export default InsightsFiltersInTooltip;
