import React from 'react';

export default ({color = '#200E32'}) => (
  <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M5.77654 11.4211L1.82231 13.5874C1.50659 13.7514 1.11764 13.6353 0.943587 13.325V13.325C0.893231 13.2288 0.866065 13.1223 0.864258 13.0138V3.41498C0.864258 1.58432 2.11521 0.852051 3.91536 0.852051H8.1442C9.88943 0.852051 11.1953 1.5355 11.1953 3.29294V13.0138C11.1953 13.1869 11.1265 13.353 11.0041 13.4755C10.8816 13.5979 10.7155 13.6667 10.5424 13.6667C10.4319 13.6649 10.3233 13.6378 10.2251 13.5874L6.24641 11.4211C6.0998 11.3419 5.92315 11.3419 5.77654 11.4211Z"
          stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M3.57983 5.21527H8.4433" stroke={color} strokeWidth="1.5" strokeLinecap="round"
          strokeLinejoin="round"/>
  </svg>

);
