import React, { useEffect, useState } from 'react';
import qs from 'query-string';
import { Redirect } from 'react-router-dom';

import { authService } from 'src/services/authService';

import Loader from '@uikit/Loader/Loader';

const BackdoorLoginPage = ({ location: { search } }: { location: Location }): JSX.Element => {
  const { token } = qs.parse(search);
  const [error, changeError] = useState(null);

  useEffect(() => {
    if (token) {
      authService.loginWithToken(token as string);
    }
  }, [token]);

  if (!token) {
    return <Redirect to="/welcome" />;
  }

  if (error) {
    return <h2 style={{ textAlign: 'center' }}>{error}</h2>;
  }

  return <Loader isLoading withTopMargin />;
};

export default BackdoorLoginPage;
