import { PersonalizationAutomationSettings } from '@ts/automationPersonalizations.types';
import createAction from '../utils/createAction';

export const SET_PERSONALIZATION_AUTOMATION_DATA = 'SET_PERSONALIZATION_AUTOMATION_DATA';
export const UPDATE_CURRENT_PERSONALIZATION_AUTOMATION_DATA =
  'UPDATE_CURRENT_PERSONALIZATION_AUTOMATION_DATA';

export const setPersonalizationAutomationData = createAction<PersonalizationAutomationSettings>(
  SET_PERSONALIZATION_AUTOMATION_DATA
);

export const updateCurrentPersonalizationAutomationData = createAction<
  Partial<PersonalizationAutomationSettings>
>(UPDATE_CURRENT_PERSONALIZATION_AUTOMATION_DATA);
