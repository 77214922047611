import React from 'react';

const RemoveRelationshipsIcon = ({ color = '#FFFFFF' }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.833252 10C0.833252 9.53976 1.20635 9.16667 1.66659 9.16667H6.66658C6.94521 9.16667 7.20541 9.30592 7.35996 9.53775L8.77924 11.6667H11.2206L12.6399 9.53775C12.7944 9.30592 13.0546 9.16667 13.3333 9.16667H18.3333C18.7935 9.16667 19.1666 9.53976 19.1666 10C19.1666 10.4602 18.7935 10.8333 18.3333 10.8333H13.7792L12.36 12.9622C12.2054 13.1941 11.9452 13.3333 11.6666 13.3333H8.33325C8.05462 13.3333 7.79443 13.1941 7.63988 12.9622L6.2206 10.8333H1.66659C1.20635 10.8333 0.833252 10.4602 0.833252 10Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.03325 2.5L13.9666 2.5C14.4317 2.50025 14.8879 2.63024 15.2832 2.87535C15.6781 3.12025 15.997 3.47037 16.204 3.8864C16.2042 3.88677 16.2043 3.88713 16.2045 3.8875L19.0784 9.62689C19.1364 9.74271 19.1666 9.87047 19.1666 10V15C19.1666 15.663 18.9032 16.2989 18.4344 16.7678C17.9655 17.2366 17.3296 17.5 16.6666 17.5H3.33325C2.67021 17.5 2.03433 17.2366 1.56548 16.7678C1.09664 16.2989 0.833252 15.663 0.833252 15V10C0.833252 9.87047 0.863449 9.74272 0.921446 9.62689L3.79531 3.8875C3.79551 3.88709 3.79571 3.88669 3.79592 3.88628C4.0029 3.4703 4.32173 3.12022 4.71661 2.87535C5.11189 2.63024 5.56815 2.50025 6.03325 2.5ZM4.54158 4.25833L5.28672 4.63144L2.49992 10.197V15C2.49992 15.221 2.58772 15.433 2.744 15.5893C2.90028 15.7455 3.11224 15.8333 3.33325 15.8333H16.6666C16.8876 15.8333 17.0996 15.7455 17.2558 15.5893C17.4121 15.433 17.4999 15.221 17.4999 15V10.197L14.7131 4.63144L14.712 4.62917C14.643 4.49033 14.5366 4.37349 14.4049 4.29178C14.2732 4.21011 14.1213 4.16678 13.9663 4.16667H6.03353C5.87855 4.16678 5.72668 4.21011 5.59496 4.29178C5.4632 4.37349 5.35685 4.49033 5.28786 4.62917L4.54158 4.25833Z"
      fill={color}
    />
  </svg>
);

export default RemoveRelationshipsIcon;
