import React from 'react';
import { Link } from 'react-router-dom';

import TextWithIcon from '@uikit/TextWithIcon/TextWithIcon';
import { SVGIcon } from '@uikit/Icon/Icon';

import './SwitchToYearlyCard.scss';

const SwitchToYearlyCard = ({ cost }: { cost: number }): JSX.Element => {
  const savingAmount = Math.ceil((cost * 12 * 0.2) / 10) * 10;

  if (savingAmount <= 0) {
    return null;
  }

  return (
    <div className="switch-to-yearly-card">
      <div className="switch-to-yearly-card__text-container">
        <div className="switch-to-yearly-card__icon">
          <SVGIcon icon="bill" color="#251FB6" size={16} />
        </div>

        <span className="switch-to-yearly-card__text">
          Save ${savingAmount} by switching to annual
        </span>
      </div>

      <Link to="/settings/billing/change-subscription">
        <TextWithIcon
          className="switch-to-yearly-card__button"
          icon="bill"
          iconColor="#ffffff"
          iconSize={16}
        >
          <>Save ${savingAmount}</>
        </TextWithIcon>
      </Link>
    </div>
  );
};

export default SwitchToYearlyCard;
