import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { getApproximateTimePeriod } from '@utils/getFormattedPastTime';

import { getVariableByValue, VariableType } from '@constants/variables';

import { addNotification } from '@redux/actions/notifications.actions';

import { uploadFile } from '@api/fileImport.api';

import { NamedAvatar } from '@uikit/Avatar/Avatar';
import SlateEditor from '@uikit/RichEditor/SlateEditor';

import './OutboxEmail.scss';
import { DispatchType } from 'src/store';

export const OutboxRichEditor = ({
  email,
  onUpdateQueueMessage,
  campaignId,
  workspaceId,
  personData,
  opportunityData,
  customVariablesValues,
  onRevert = () => null,
  disabled = false,
  classNames = {},
}) => {
  const dispatch = useDispatch<DispatchType>();

  const [template, changeTemplate] = useState(email.content);
  const [isAttachmentLoading, changeIsAttachmentLoading] = useState(false);

  const onSave = () => onUpdateQueueMessage(email.id, template, email?.attachmentsList);

  const handleChangeTemplate = (htmlTemplate) => {
    if (template !== htmlTemplate) {
      changeTemplate(htmlTemplate);
    }
  };

  const handleChangeAttachments = ([file]: File[]) => {
    const readableFileSizeMB = Math.floor(file.size / 1000000);

    if (readableFileSizeMB > 25) {
      dispatch(addNotification({ title: 'Maximum file size is 25MB', type: 'warning' }));
      return;
    }

    changeIsAttachmentLoading(true);
    file?.arrayBuffer()?.then((buffer) => {
      return uploadFile(campaignId, workspaceId, file, buffer).then((processResponse) => {
        const { title, uid } = processResponse[processResponse.length - 1];

        onUpdateQueueMessage(email.id, template, [
          ...email?.attachmentsList,
          {
            key: uid,
            title,
            size: file.size,
          },
        ]).then(() => changeIsAttachmentLoading(false));
      });
    });
  };

  const handleRemoveAttachment = (fileUid) =>
    onUpdateQueueMessage(
      email.id,
      template,
      email.attachmentsList.filter(({ key }) => key !== fileUid)
    );

  const transformEditorVariable = useCallback(
    (variable: string, fallback: string, allVariables: VariableType[]): string => {
      const { transform } = getVariableByValue(variable, allVariables);
      const { transform: transformDefault } = getVariableByValue(variable);

      if ((transform || transformDefault) && customVariablesValues) {
        return (transform || transformDefault)(
          fallback,
          personData,
          opportunityData,
          { nextActivityTime: email.nextActivityTime },
          null,
          customVariablesValues
        );
      }

      return fallback && fallback.length ? `${variable} | ${fallback}` : `${variable}`;
    },
    [customVariablesValues]
  );

  const transformEditorLink = (url: string): string => {
    if (url.startsWith('@')) {
      const variable = url.substr(1, url.length);

      const { transformLink } = getVariableByValue(variable);

      return transformLink ? transformLink('', personData) : url;
    }

    return url;
  };

  useEffect(() => {
    changeTemplate(email.content);
  }, [email.content]);

  return (
    <SlateEditor
      disabled={disabled}
      classNames={classNames}
      message={template}
      onRevert={onRevert}
      isAttachmentsLoading={isAttachmentLoading}
      onChange={handleChangeTemplate}
      attachments={email.attachmentsList}
      onFileUpload={handleChangeAttachments}
      onRemoveFile={handleRemoveAttachment}
      transformVariable={transformEditorVariable}
      transformLinkUrl={transformEditorLink}
      actionText="Save"
      onAction={onSave}
      disableVarsInLink
      disableVariable={disabled}
    />
  );
};

export const EmailThreadWrapper = ({
  email,
  onUpdateQueueMessage,
  campaignId,
  workspaceId,
  personData,
  opportunityData,
  customVariablesValues,
}) => {
  return (
    <div className="analytics-outbox-email-info" key={email.messageId}>
      <div className="analytics-outbox-email-info__subject">
        Subject: &#39;
        <div dangerouslySetInnerHTML={{ __html: email.subject }} />
        &#39;
      </div>
      <div className="analytics-outbox-email-info__header">
        <div className="analytics-outbox-email-info__header-left">
          <NamedAvatar
            userName={email.fromName}
            className="analytics-outbox-email-info__from-avatar"
          />
          <div>
            <div className="analytics-outbox-email-info__from-info">
              <span className="analytics-outbox-email-info__from-name">{email.fromName}</span>
              <span className="analytics-outbox-email-info__from-email">
                &#60;{email.fromEmail}&#62;
              </span>
            </div>
            <div>
              <span className="analytics-outbox-email-info__to-name">To: {email.toName}</span>
              <span className="analytics-outbox-email-info__to-email">
                &#60;{email.toEmail}&#62;
              </span>
            </div>
          </div>
        </div>
        <div>
          <div className="analytics-outbox-email-info__date">
            {getApproximateTimePeriod(email.nextActivityTime)}
          </div>
        </div>
      </div>
      <div className="analytics-outbox-email-info__content">
        <OutboxRichEditor
          email={email}
          onUpdateQueueMessage={onUpdateQueueMessage}
          campaignId={campaignId}
          workspaceId={workspaceId}
          personData={personData}
          opportunityData={opportunityData}
          customVariablesValues={customVariablesValues}
        />
      </div>
    </div>
  );
};

const OutboxEmail = ({
  email,
  onUpdateQueueMessage,
  campaignId,
  workspaceId,
  personData,
  opportunityData,
  customVariablesValues,
}) => {
  if (!email) {
    return null;
  }

  return (
    <div className="analytics-outbox-emails">
      <EmailThreadWrapper
        email={email}
        key={email.id}
        onUpdateQueueMessage={onUpdateQueueMessage}
        campaignId={campaignId}
        workspaceId={workspaceId}
        personData={personData}
        opportunityData={opportunityData}
        customVariablesValues={customVariablesValues}
      />
    </div>
  );
};

export default OutboxEmail;
