import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb';
import HaroModels from '../../respona_api/generated/haro_pb';
import CommonModels from '../../respona_api/generated/common_pb';

import haroService from '@api/services/haroService';
import streamRequest from '@api/base/streamRequest';
import request from '@api/base/request';

import {
  HaroCategoriesType,
  HaroFilterShortType,
  HaroFilterType,
  HaroOpportunityType,
  HaroPitchedType,
} from '@ts/haro.types';
import { HaroFilterPropsType } from '@ts/props.types';

export const searchHaroApi = (
  workspaceId: number,
  query = '',
  page = 0,
  filterId?: number,
  limit = 10
): Promise<HaroOpportunityType[]> => {
  const client = haroService.getInstance();
  const searchRequest = new HaroModels.HaroOpportunityRequest();

  searchRequest.setWorkspaceId(workspaceId);
  searchRequest.setPage(page);
  searchRequest.setQuery(query);
  searchRequest.setLimit(limit);
  searchRequest.setIgnoreExpiration(false);
  searchRequest.setSortOrderAsc(false);
  searchRequest.setSortBy(3);

  if (filterId) {
    searchRequest.setOptionalFilterId(filterId);
  }

  return streamRequest<HaroOpportunityType[]>(client, client.search, searchRequest);
};

export const getAllBookmarkedApi = (
  workspaceId: number,
  page: number
): Promise<HaroOpportunityType[]> => {
  const client = haroService.getInstance();
  const pageRequest = new CommonModels.PageRequest();

  pageRequest.setPage(page);
  pageRequest.setOptionalWorkspaceId(workspaceId);
  pageRequest.setLimit(10);

  return streamRequest<HaroOpportunityType[]>(client, client.getAllBookmarked, pageRequest);
};

export const addBookmarkApi = (workspaceId: number, uid: string): Promise<void> => {
  const client = haroService.getInstance();
  const haroBookmarkRequest = new HaroModels.HaroBookmarkRequest();

  haroBookmarkRequest.setWorkspaceId(workspaceId);
  haroBookmarkRequest.setOpportunityId(uid);

  return request<void>(client, client.addBookmark, haroBookmarkRequest);
};

export const removeBookmarkApi = (workspaceId: number, uid: string): Promise<void> => {
  const client = haroService.getInstance();
  const haroBookmarkRequest = new HaroModels.HaroBookmarkRequest();

  haroBookmarkRequest.setWorkspaceId(workspaceId);
  haroBookmarkRequest.setOpportunityId(uid);

  return request<void>(client, client.removeBookmark, haroBookmarkRequest);
};

export const getAllCategoriesApi = (): Promise<{ itemsList: HaroCategoriesType[] }> => {
  const client = haroService.getInstance();
  const emptyRequest = new google_protobuf_empty_pb.Empty();

  return request<{ itemsList: HaroCategoriesType[] }>(client, client.getAllCategories, emptyRequest);
};

export const getAllPitchedApi = (workspaceId: number, page: number): Promise<HaroPitchedType[]> => {
  const client = haroService.getInstance();
  const pageRequest = new CommonModels.PageRequest();

  pageRequest.setPage(page);
  pageRequest.setOptionalWorkspaceId(workspaceId);
  pageRequest.setLimit(10);

  return streamRequest<HaroPitchedType[]>(client, client.getAllPitched, pageRequest);
};

export const getFiltersApi = (workspaceId: number): Promise<HaroFilterShortType[]> => {
  const client = haroService.getInstance();
  const pageModel = new CommonModels.PageRequest();

  pageModel.setLimit(100);
  pageModel.setPage(0);
  pageModel.setOptionalWorkspaceId(workspaceId);

  return streamRequest(client, client.getFilters, pageModel);
};

export const createFilterApi = (
  workspaceId: number,
  fields: HaroFilterPropsType
): Promise<HaroFilterShortType> => {
  const client = haroService.getInstance();
  const filterModel = new HaroModels.HaroFilter();

  filterModel.setWorkspaceId(workspaceId);
  filterModel.setTitle(fields.title);
  filterModel.setMailboxAccountId(fields.pitchEmailFrom.value);
  filterModel.setUsersForNotificationList(
    fields.sendNotificationsTo.map((user) => {
      const haroMemberModel = new HaroModels.HaroMember();

      haroMemberModel.setEmail(user.email);
      haroMemberModel.setUserId(user.userId);

      return haroMemberModel;
    })
  );
  filterModel.setNotifyAtHours(12);
  filterModel.setRemoveAnonymous(fields.removeAnonymous);
  filterModel.setDomainRatingMin(fields.domainRating.min);
  filterModel.setDomainRatingMax(fields.domainRating.max);
  filterModel.setKeywordsList(fields.keywords);
  filterModel.setBioFullName(fields.bioFullName);
  filterModel.setBioJobTitle(fields.bioJobTitle);
  filterModel.setBioCompanyName(fields.bioCompanyName);
  filterModel.setBioCompanyWebsite(fields.bioCompanyWebsite);
  filterModel.setBioHeadshot(fields.bioHeadshot);
  filterModel.setBioLinkedInLink(fields.bioLinkedInLink);
  filterModel.setBioTwitterLink(fields.bioTwitterLink);
  filterModel.setBioPhoneNumber(fields.bioPhoneNumber);
  filterModel.setBioOnlineBio(fields.bioOnlineBio);
  filterModel.setBioPressKitLink(fields.bioPressKitLink);

  return request(client, client.createFilter, filterModel);
};

export const updateFilterApi = (
  workspaceId: number,
  fields: HaroFilterPropsType
): Promise<HaroFilterShortType> => {
  const client = haroService.getInstance();
  const filterModel = new HaroModels.HaroFilter();

  filterModel.setId(fields.id);
  filterModel.setWorkspaceId(workspaceId);
  filterModel.setTitle(fields.title);
  filterModel.setMailboxAccountId(fields.pitchEmailFrom.value);
  filterModel.setUsersForNotificationList(
    fields.sendNotificationsTo.map((user) => {
      const haroMemberModel = new HaroModels.HaroMember();

      haroMemberModel.setEmail(user.email);
      haroMemberModel.setUserId(user.userId);

      return haroMemberModel;
    })
  );
  filterModel.setNotifyAtHours(12);
  filterModel.setRemoveAnonymous(fields.removeAnonymous);
  filterModel.setDomainRatingMin(fields.domainRating.min);
  filterModel.setDomainRatingMax(fields.domainRating.max);
  filterModel.setKeywordsList(fields.keywords);
  filterModel.setBioFullName(fields.bioFullName);
  filterModel.setBioJobTitle(fields.bioJobTitle);
  filterModel.setBioCompanyName(fields.bioCompanyName);
  filterModel.setBioCompanyWebsite(fields.bioCompanyWebsite);
  filterModel.setBioHeadshot(fields.bioHeadshot);
  filterModel.setBioLinkedInLink(fields.bioLinkedInLink);
  filterModel.setBioTwitterLink(fields.bioTwitterLink);
  filterModel.setBioPhoneNumber(fields.bioPhoneNumber);
  filterModel.setBioOnlineBio(fields.bioOnlineBio);
  filterModel.setBioPressKitLink(fields.bioPressKitLink);

  return request(client, client.updateFilter, filterModel);
};

export const removeFilterApi = (workspaceId: number, filterId: number): Promise<void> => {
  const client = haroService.getInstance();
  const idModel = new CommonModels.IdRequest();

  idModel.setOptionalWorkspaceId(workspaceId);
  idModel.setId(filterId);

  return request(client, client.deleteFilter, idModel);
};

export const getFilterDetailsApi = (
  workspaceId: number,
  filterId: number
): Promise<HaroFilterType> => {
  const client = haroService.getInstance();
  const idModel = new CommonModels.IdRequest();

  idModel.setOptionalWorkspaceId(workspaceId);
  idModel.setId(filterId);

  return request(client, client.getFilterDetails, idModel);
};

export const createAndSendPitchedApi = (
  inquiryId: string,
  workspaceId: number,
  mailboxAccountId: number,
  subject: string,
  content: string
) => {
  const client = haroService.getInstance();
  const haroPitchedModel = new HaroModels.HaroPitchedRequest();

  haroPitchedModel.setWorkspaceId(workspaceId);
  haroPitchedModel.setOpportunityId(inquiryId);
  haroPitchedModel.setMailboxAccountId(mailboxAccountId);
  haroPitchedModel.setSubject(subject);
  haroPitchedModel.setContent(content);

  return request(client, client.createAndSendPitched, haroPitchedModel);
};
