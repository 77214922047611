import React from 'react';
import { components } from 'react-select';
import Select from 'src/uikit/Select/Select';
import PriorityIcon from 'src/uikit/PriorityIcon/PriorityIcon';

import { priorityOptions } from 'src/constants/options';

const Control = ({ children, ...restProps }) => (
  <components.Control {...restProps}>
    <PriorityIcon leftMargin priority={restProps.selectProps.value.value} /> {children}
  </components.Control>
);

const Option = ({ children, ...restProps }) => (
  <components.Option {...restProps}>
    <PriorityIcon rightMargin priority={restProps.data.value} /> {children}
  </components.Option>
);

export default {
  type: 'select',
  label: 'Campaign Priority',
  key: 'priority',
  Renderer: (props: { [key: string]: any }): JSX.Element => (
    <Select {...props} options={priorityOptions} additionalComponents={{ Control, Option }} />
  ),
};
