export function extractVariableNamesFromHtml(html: string): string[] {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  const variableElements = doc.querySelectorAll('span.variable, span.ai-variable');

  return Array.from(variableElements)
    .map((element) => element.textContent || '')
    .filter((name) => name !== '');
}
