export const PRIMARY_COLOR = '#221CB6';
export const GREY_LIGHT_COLOR = '#DBDFE7';
export const GREY_COLOR = '#C1C1C1';

export const LIGHT_MAIN_COLOR = '#EEF3FF';
export const PRIMARY_BLACK_COLOR = '#000000';
export const SECONDARY_BLACK_COLOR = '#181818';

export const MEDIUM_GREY_COLOR_FOR_ICONS = '#7B7B7B';
export const LIGHT_GREY_COLOR_FOR_ICONS = '#D1D1D1';

export const ERROR_MESSAGE = '#FF6868';
export const DANGER_COLOR = '#ed483d';
