import React from 'react';

export default ({ color = '#200E32', size = 13 }: { color?: string, size?: number }): JSX.Element => (
  <svg width={size} height={size} viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.28125 1.42188L6.62461 5.21847L11.968 1.42188"
      stroke={color}
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.89993 9.99935C1.40272 9.99935 1 9.64106 1 9.19953V1.79982C1 1.35829 1.40272 1 1.89993 1H11.3493C11.8465 1 12.2492 1.35829 12.2492 1.79982V9.19953C12.2492 9.64106 11.8465 9.99935 11.3493 9.99935H1.89993Z"
      stroke={color}
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
