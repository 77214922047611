const BITS_IN_B = 8;
const BITS_IN_Kb = 1_024;
const BITS_IN_Mb = 1_048_576;

export default (fileSize = 0): string => {
  if (fileSize > BITS_IN_Mb) {
    return `${(fileSize / BITS_IN_Mb).toFixed(1)} MB`;
  }

  if (fileSize > BITS_IN_Kb) {
    return `${(fileSize / BITS_IN_Kb).toFixed(1)} KB`;
  }

  if (fileSize > BITS_IN_B) {
    return `${(fileSize / BITS_IN_B).toFixed(1)} B`;
  }

  return `${fileSize} bit`;
};
