import React from 'react';
import { format } from 'date-fns';
import cn from 'class-names';

import { BlackListResponseType } from '@ts/mailboxInbox.types';
import { Tr } from '@uikit/Table/Table';
import ActionsMenu from '@components/ActionsMenu/ActionsMenu';
import Checkbox from '@uikit/Checkbox/Checkbox';
import { SVGIcon } from '@uikit/Icon/Icon';

type TableRenderPropsType = {
  row: BlackListResponseType;
  children?: JSX.Element;
  renderProps?: {
    onRemovePattern: (BlackListResponseType) => void;
    onEditPattern: (BlackListResponseType) => void;
    onToggleSelection: (number) => void;
    selectedIds: { [key: number]: number };
  };
};

export default [
  {
    title: '',
    dataKey: 'selectAction',
    RenderCell: ({
      row: { id },
      renderProps: { selectedIds, onToggleSelection },
    }: TableRenderPropsType): JSX.Element => {
      const handleSelection = () => onToggleSelection(id);

      return <Checkbox onChange={handleSelection} value={selectedIds[id] !== undefined} />;
    },
    RenderTitle: ({ renderProps: { onToggleAllSelected, isAllSelected } }): JSX.Element => {
      const handleToggle = ({ target: { checked } }) => onToggleAllSelected(checked);

      return <Checkbox onChange={handleToggle} value={isAllSelected} />;
    },
  },
  {
    title: 'Pattern',
    dataKey: 'pattern',
    cellClassName: 'unsubscribe-table__pattern-cell',
    RenderCell: ({ row: { pattern } }: TableRenderPropsType): JSX.Element => (
      <div title={pattern}>{pattern}</div>
    ),
  },
  {
    title: 'Reason',
    dataKey: 'reason',
    RenderCell: ({ row: { reason } }: TableRenderPropsType): JSX.Element => {
      const type = reason.toLowerCase().includes('manual') ? 0 : 1;

      const renderReason = () => {
        if (type === 0) {
          return 'Manual';
        }

        if (type === 1) {
          return 'Automatic';
        }

        return '';
      };

      const cnCreatedBy = cn('unsubscribe-table__reason-cell', {
        'unsubscribe-table__reason-cell--manual': type === 0,
        'unsubscribe-table__reason-cell--automatic': type === 1,
      });

      return (
        <div className={cnCreatedBy}>
          <span className="unsubscribe-table__created-by">{reason}</span>
        </div>
      );
    },
  },
  {
    title: 'Scope',
    dataKey: 'scope',
    cellClassName: 'unsubscribe-table__pattern-cell',
    RenderCell: ({ row: { workspaceIdsList } }: TableRenderPropsType): JSX.Element => (
      <div className="unsubscribe-table__scope">
        {workspaceIdsList.length && workspaceIdsList.length > 0 ? (
          <div className="unsubscribe-table__scope-item">
            <SVGIcon icon="workspace" color="#C4C6CD" /> {workspaceIdsList.length}{' '}
            {workspaceIdsList.length === 1 ? 'workspace' : 'workspaces'}
          </div>
        ) : (
          <div className="unsubscribe-table__scope-item">
            <SVGIcon icon="globe" color="#C4C6CD" /> Global
          </div>
        )}
      </div>
    ),
  },
  {
    title: 'Date Added',
    dataKey: 'dateAdded',
    RenderCell: ({ row: { createdAt } }: TableRenderPropsType): JSX.Element => {
      return (
        <div className="unsubscribe-table__time-cell">
          {createdAt ? format(new Date(createdAt), 'MMM dd, yyyy') : '-'}
        </div>
      );
    },
  },
  {
    title: '',
    dataKey: 'unsubscribeAction',
    RenderCell: ({
      row,
      renderProps: { onRemovePattern, onEditPattern },
    }: TableRenderPropsType): JSX.Element => {
      const handleRemove = () => {
        onRemovePattern(row);
      };
      const actionsConfig = [
        {
          icon: 'pen',
          title: 'Edit',
          iconSize: 16,
          handler: () => onEditPattern(row),
        },
      ];
      return (
        <ActionsMenu
          id={`unsubscribe-actions-${row.id}`}
          actionsConfig={actionsConfig}
          onRemove={handleRemove}
          tooltipPlace="left"
        />
      );
    },
  },
];

export function RenderRow({ children }: TableRenderPropsType): JSX.Element {
  return <Tr className="unsubscribe-table__row">{children}</Tr>;
}
