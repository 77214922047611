// package: mailbox
// file: mailbox-account.proto

var mailbox_account_pb = require("./mailbox-account_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var common_pb = require("./common_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var MailboxAccountService = (function () {
  function MailboxAccountService() {}
  MailboxAccountService.serviceName = "mailbox.MailboxAccountService";
  return MailboxAccountService;
}());

MailboxAccountService.createAccount = {
  methodName: "createAccount",
  service: MailboxAccountService,
  requestStream: false,
  responseStream: false,
  requestType: mailbox_account_pb.MailboxAuthenticationRequest,
  responseType: mailbox_account_pb.MailboxAccountResponse
};

MailboxAccountService.updateAccount = {
  methodName: "updateAccount",
  service: MailboxAccountService,
  requestStream: false,
  responseStream: false,
  requestType: mailbox_account_pb.MailboxAccountUpdateRequest,
  responseType: mailbox_account_pb.MailboxAccountResponse
};

MailboxAccountService.deleteAccount = {
  methodName: "deleteAccount",
  service: MailboxAccountService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: google_protobuf_empty_pb.Empty
};

MailboxAccountService.getAccountById = {
  methodName: "getAccountById",
  service: MailboxAccountService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: mailbox_account_pb.MailboxAccountSettingsResponse
};

MailboxAccountService.getAccountSignatureById = {
  methodName: "getAccountSignatureById",
  service: MailboxAccountService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: mailbox_account_pb.MailboxAccountSignatureResponse
};

MailboxAccountService.getAllAccounts = {
  methodName: "getAllAccounts",
  service: MailboxAccountService,
  requestStream: false,
  responseStream: true,
  requestType: common_pb.PageRequest,
  responseType: mailbox_account_pb.MailboxAccountStatisticsResponse
};

MailboxAccountService.getAllWorkspacesByAccountId = {
  methodName: "getAllWorkspacesByAccountId",
  service: MailboxAccountService,
  requestStream: false,
  responseStream: true,
  requestType: common_pb.IdRequest,
  responseType: mailbox_account_pb.MailboxAccountWorkspaces
};

MailboxAccountService.reAuthenticateAccount = {
  methodName: "reAuthenticateAccount",
  service: MailboxAccountService,
  requestStream: false,
  responseStream: false,
  requestType: mailbox_account_pb.MailboxAuthenticationRequest,
  responseType: mailbox_account_pb.MailboxAccountResponse
};

MailboxAccountService.addAllToWorkspace = {
  methodName: "addAllToWorkspace",
  service: MailboxAccountService,
  requestStream: false,
  responseStream: false,
  requestType: mailbox_account_pb.AccountToWorkspace,
  responseType: google_protobuf_empty_pb.Empty
};

MailboxAccountService.removeAllFromWorkspace = {
  methodName: "removeAllFromWorkspace",
  service: MailboxAccountService,
  requestStream: false,
  responseStream: false,
  requestType: mailbox_account_pb.AccountToWorkspace,
  responseType: google_protobuf_empty_pb.Empty
};

MailboxAccountService.getAllByWorkspaceId = {
  methodName: "getAllByWorkspaceId",
  service: MailboxAccountService,
  requestStream: false,
  responseStream: true,
  requestType: common_pb.IdRequest,
  responseType: mailbox_account_pb.MailboxAccountResponse
};

MailboxAccountService.testEmailConnectionStreaming = {
  methodName: "testEmailConnectionStreaming",
  service: MailboxAccountService,
  requestStream: false,
  responseStream: true,
  requestType: mailbox_account_pb.TestConnectionRequest,
  responseType: mailbox_account_pb.TestConnectionResponseStreamContainer
};

MailboxAccountService.updateReferralContentState = {
  methodName: "updateReferralContentState",
  service: MailboxAccountService,
  requestStream: false,
  responseStream: false,
  requestType: mailbox_account_pb.ReferralContentState,
  responseType: mailbox_account_pb.ReferralContentState
};

exports.MailboxAccountService = MailboxAccountService;

function MailboxAccountServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

MailboxAccountServiceClient.prototype.createAccount = function createAccount(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MailboxAccountService.createAccount, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MailboxAccountServiceClient.prototype.updateAccount = function updateAccount(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MailboxAccountService.updateAccount, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MailboxAccountServiceClient.prototype.deleteAccount = function deleteAccount(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MailboxAccountService.deleteAccount, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MailboxAccountServiceClient.prototype.getAccountById = function getAccountById(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MailboxAccountService.getAccountById, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MailboxAccountServiceClient.prototype.getAccountSignatureById = function getAccountSignatureById(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MailboxAccountService.getAccountSignatureById, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MailboxAccountServiceClient.prototype.getAllAccounts = function getAllAccounts(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MailboxAccountService.getAllAccounts, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MailboxAccountServiceClient.prototype.getAllWorkspacesByAccountId = function getAllWorkspacesByAccountId(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MailboxAccountService.getAllWorkspacesByAccountId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MailboxAccountServiceClient.prototype.reAuthenticateAccount = function reAuthenticateAccount(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MailboxAccountService.reAuthenticateAccount, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MailboxAccountServiceClient.prototype.addAllToWorkspace = function addAllToWorkspace(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MailboxAccountService.addAllToWorkspace, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MailboxAccountServiceClient.prototype.removeAllFromWorkspace = function removeAllFromWorkspace(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MailboxAccountService.removeAllFromWorkspace, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MailboxAccountServiceClient.prototype.getAllByWorkspaceId = function getAllByWorkspaceId(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MailboxAccountService.getAllByWorkspaceId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MailboxAccountServiceClient.prototype.testEmailConnectionStreaming = function testEmailConnectionStreaming(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MailboxAccountService.testEmailConnectionStreaming, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MailboxAccountServiceClient.prototype.updateReferralContentState = function updateReferralContentState(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MailboxAccountService.updateReferralContentState, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.MailboxAccountServiceClient = MailboxAccountServiceClient;

