import { TemplateStepTransformedType, TemplateStepType } from '@ts/template.types';
import stripHtml from '@utils/stripHtml';
import { emptyEditorHtml } from 'src/constants/html.constants';
import { mapSequenceStepApiInfo } from '@mappers/campaign.mappers';

export const orderSequenceStepsSortPredicate = (
  itemA: TemplateStepType,
  itemB: TemplateStepType
): number => itemA.order - itemB.order;

// Generate prevSubjects for each step
export const prepareSequenceSteps = (steps: TemplateStepType[]): TemplateStepTransformedType[] =>
  steps.sort(orderSequenceStepsSortPredicate).reduce((resultSteps, curStep) => {
    const preparedStep = mapSequenceStepApiInfo(curStep);
    const prevSubject =
      resultSteps.length === 0 || !preparedStep.isSameThread
        ? preparedStep.subject
        : resultSteps[resultSteps.length - 1].prevSubject;

    resultSteps.push({
      ...preparedStep,
      prevSubject,
      delayFromFirstDay:
        resultSteps.length === 0
          ? curStep.sendDelay + 1
          : resultSteps[resultSteps.length - 1].delayFromFirstDay + curStep.sendDelay,
    });

    return resultSteps;
  }, []);

export const stepsUpdateSubjects = (
  steps: TemplateStepTransformedType[]
): TemplateStepTransformedType[] => {
  return steps.reduce((acc, step, index, array) => {
    if (index === 0 || stripHtml(step.subject).trim() !== '') {
      acc.push({
        ...step,
        isSameThread: false,
        prevSubject: step.subject,
      });
    } else {
      acc.push({
        ...step,
        isSameThread: true,
        subject: emptyEditorHtml,
        prevSubject: acc[acc.length - 1].prevSubject,
      });
    }

    return acc;
  }, []);
};

export const deleteSequenceStepHelper = (
  steps: TemplateStepTransformedType[],
  deletingId: number
): TemplateStepTransformedType[] => {
  return steps
    .filter(({ id }) => id !== deletingId)
    .map((step, index) => ({
      ...step,
      order: index + 1,
    }));
};

export const updateSequenceStepSubjectHelper = (
  updatedStep: TemplateStepTransformedType,
  sequenceSteps: TemplateStepTransformedType[]
): TemplateStepTransformedType[] => {
  const updateSequenceSteps = sequenceSteps.map((curSequenceStep) =>
    curSequenceStep.id === updatedStep.id ? updatedStep : curSequenceStep
  );

  return stepsUpdateSubjects(updateSequenceSteps);
};

export const prepareSequenceStepsSubjectsForUpdatingWithOrderHelper = (
  updatedStep: TemplateStepTransformedType,
  sequenceSteps: TemplateStepTransformedType[]
): TemplateStepTransformedType[] => {
  const stepsForUpdatingRequest = [];

  for (let i = 0; i < sequenceSteps.length; i++) {
    if (sequenceSteps[i].isSameThread) {
      stepsForUpdatingRequest.push({
        ...sequenceSteps[i],
        subject: sequenceSteps[i].prevSubject,
      });
    } else {
      stepsForUpdatingRequest.push(sequenceSteps[i]);
    }
  }

  return stepsForUpdatingRequest;
};

export const isEmptySequence = (steps: TemplateStepTransformedType[]) => {
  return steps?.every((item) => item.content === '<p><span></span></p>');
};
