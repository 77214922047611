import React from 'react';

import BaseModal, { BaseModalType } from '@uikit/BaseModal/BaseModal';
import Button from '@uikit/Button/Button';

import './ConfirmModal.scss';

type ConfirmModalType = BaseModalType & {
  title: string;
  text: string;
  confirmButtonText: string;
  isConfirmButtonMain: boolean;
  cancelButtonText: string;
  onConfirm: () => void;
  additionalStyles?: any;
};

function ConfirmModal({
  title,
  text,
  confirmButtonText,
  isConfirmButtonMain,
  cancelButtonText,
  isOpen,
  onClose,
  onConfirm,
  additionalStyles = null,
}: ConfirmModalType): JSX.Element {
  return (
    <BaseModal
      classNames={{ closeButton: 'confirm-modal__close-btn' }}
      onClose={onClose}
      isOpen={isOpen}
      style={{ minHeight: 'unset', ...additionalStyles }}
    >
      <div className="confirm-modal">
        <h1 className="confirm-modal__title">{title}</h1>
        <p className="confirm-modal__message">{text}</p>
        <footer className="confirm-modal__footer">
          <Button
            className="confirm-modal__cancel-button"
            onClick={onClose}
            type={!isConfirmButtonMain ? 'primary' : 'secondary'}
          >
            {cancelButtonText}
          </Button>
          <Button
            className="confirm-modal__remove-button"
            onClick={onConfirm}
            type={isConfirmButtonMain ? 'primary' : 'secondary'}
          >
            {confirmButtonText}
          </Button>
        </footer>
      </div>
    </BaseModal>
  );
}

export default ConfirmModal;
