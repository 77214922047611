import React, { RefObject, useRef, useState } from 'react';

import useOutsideClick from '@hooks/useOutsideClick';

export default <T extends { [prop: string]: unknown } & { innerRef?: RefObject<HTMLElement> }>(
  WrappedComponent: React.ElementType
): ((props: T) => JSX.Element) => {
  return function WithConfirmText({ withConfirm = false, ...props }: T): JSX.Element {
    const ref = useRef<HTMLElement>();
    const [confirmInitiation, changeConfirmInitiation] = useState<boolean>(false);

    useOutsideClick(ref, () => {
      if (confirmInitiation) {
        changeConfirmInitiation(false);
      }
    });

    if (!withConfirm) {
      return <WrappedComponent {...props} />;
    }

    if (confirmInitiation) {
      return (
        <WrappedComponent
          innerRef={ref}
          {...props}
          // @ts-ignore
          onClick={() => {
            // @ts-ignore
            props.onClick();
            changeConfirmInitiation(false);
          }}
        >
          Confirm ?
        </WrappedComponent>
      );
    }

    return (
      <WrappedComponent
        innerRef={ref}
        {...props}
        // @ts-ignore
        onClick={() => {
          changeConfirmInitiation(true);
        }}
      />
    );
  };
};
