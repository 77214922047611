import React from 'react';

export default ({
  color = '#3424EB',
  className,
  size,
}: {
  color: string;
  className?: string;
  size?: number;
}): JSX.Element => (
  <svg className={className} width={size || '16'} height={size || '17'} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.4475 8.3956V5.68899C14.4475 4.58442 13.552 3.68899 12.4475 3.68899H2.34475C1.60177 3.68899 1 3.08721 1 2.34424V13.7746C1 14.8887 1.903 15.7917 3.01712 15.7917H12.4475C13.552 15.7917 14.4475 14.8963 14.4475 13.7917V11.0851"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M11.758 3.68949V3.68949C11.758 2.20413 10.5538 1 9.06848 1H2.34475C1.60177 1 1 1.60177 1 2.34475C1 3.08772 1.60177 3.68949 2.34475 3.68949"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M15.1168 9.74074C15.1168 10.4834 14.5147 11.0855 13.772 11.0855H11.7549C11.0119 11.0855 10.4102 10.4837 10.4102 9.74074C10.4102 8.99777 11.0119 8.396 11.7549 8.396H13.772C14.5147 8.396 15.1168 8.99806 15.1168 9.74074V9.74074Z"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);
