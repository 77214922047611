import React, { useState } from 'react';

import Button from '@uikit/Button/Button';

import PageHeader from '@components/PageHeader/PageHeader';
import Integrations from '@components/Settings/Integrations/Integrations';
import HeaderSearchInput from '@components/HeaderSearchInput/HeaderSearchInput';

import './IntegrationsPage.scss';

const IntegrationsPage = (): JSX.Element => {
  const [searchQuery, changeSearchQuery] = useState<string>('');
  const handleUpdateSearchQuery = (query: string) => changeSearchQuery(query);

  return (
    <div className="integrations-settings-page">
      <PageHeader title="Integrations">
        <div className="integrations-settings-page__header-actions">
          <HeaderSearchInput
            searchQuery={searchQuery}
            onUpdateSearchQuery={handleUpdateSearchQuery}
          />
        </div>
      </PageHeader>

      <Integrations searchQueryFilter={searchQuery} />
    </div>
  );
};

export default IntegrationsPage;
