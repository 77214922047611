// source: automation-contact.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
goog.exportSymbol('proto.automation.ContactAutomationPresetResponse', null, global);
goog.exportSymbol('proto.automation.ContactAutomationRequest', null, global);
goog.exportSymbol('proto.automation.ContactAutomationSearchingMode', null, global);
goog.exportSymbol('proto.automation.ContactAutomationState', null, global);
goog.exportSymbol('proto.automation.DomainSearchKeywords', null, global);
goog.exportSymbol('proto.automation.LaunchContactAutomationRequest', null, global);
goog.exportSymbol('proto.automation.NotificationContactAutomationRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.automation.ContactAutomationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.automation.ContactAutomationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.automation.ContactAutomationRequest.displayName = 'proto.automation.ContactAutomationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.automation.DomainSearchKeywords = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.automation.DomainSearchKeywords, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.automation.DomainSearchKeywords.displayName = 'proto.automation.DomainSearchKeywords';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.automation.LaunchContactAutomationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.automation.LaunchContactAutomationRequest.repeatedFields_, null);
};
goog.inherits(proto.automation.LaunchContactAutomationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.automation.LaunchContactAutomationRequest.displayName = 'proto.automation.LaunchContactAutomationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.automation.NotificationContactAutomationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.automation.NotificationContactAutomationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.automation.NotificationContactAutomationRequest.displayName = 'proto.automation.NotificationContactAutomationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.automation.ContactAutomationState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.automation.ContactAutomationState.repeatedFields_, null);
};
goog.inherits(proto.automation.ContactAutomationState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.automation.ContactAutomationState.displayName = 'proto.automation.ContactAutomationState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.automation.ContactAutomationPresetResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.automation.ContactAutomationPresetResponse.repeatedFields_, null);
};
goog.inherits(proto.automation.ContactAutomationPresetResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.automation.ContactAutomationPresetResponse.displayName = 'proto.automation.ContactAutomationPresetResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.automation.ContactAutomationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.automation.ContactAutomationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.automation.ContactAutomationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.ContactAutomationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pipelineId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.automation.ContactAutomationRequest}
 */
proto.automation.ContactAutomationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.automation.ContactAutomationRequest;
  return proto.automation.ContactAutomationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.automation.ContactAutomationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.automation.ContactAutomationRequest}
 */
proto.automation.ContactAutomationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPipelineId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.automation.ContactAutomationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.automation.ContactAutomationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.automation.ContactAutomationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.ContactAutomationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPipelineId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 pipeline_id = 1;
 * @return {number}
 */
proto.automation.ContactAutomationRequest.prototype.getPipelineId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.ContactAutomationRequest} returns this
 */
proto.automation.ContactAutomationRequest.prototype.setPipelineId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 workspace_id = 2;
 * @return {number}
 */
proto.automation.ContactAutomationRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.ContactAutomationRequest} returns this
 */
proto.automation.ContactAutomationRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.automation.DomainSearchKeywords.prototype.toObject = function(opt_includeInstance) {
  return proto.automation.DomainSearchKeywords.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.automation.DomainSearchKeywords} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.DomainSearchKeywords.toObject = function(includeInstance, msg) {
  var f, obj = {
    order: jspb.Message.getFieldWithDefault(msg, 1, 0),
    keyword: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.automation.DomainSearchKeywords}
 */
proto.automation.DomainSearchKeywords.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.automation.DomainSearchKeywords;
  return proto.automation.DomainSearchKeywords.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.automation.DomainSearchKeywords} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.automation.DomainSearchKeywords}
 */
proto.automation.DomainSearchKeywords.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeyword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.automation.DomainSearchKeywords.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.automation.DomainSearchKeywords.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.automation.DomainSearchKeywords} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.DomainSearchKeywords.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getKeyword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 order = 1;
 * @return {number}
 */
proto.automation.DomainSearchKeywords.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.DomainSearchKeywords} returns this
 */
proto.automation.DomainSearchKeywords.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string keyword = 2;
 * @return {string}
 */
proto.automation.DomainSearchKeywords.prototype.getKeyword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.automation.DomainSearchKeywords} returns this
 */
proto.automation.DomainSearchKeywords.prototype.setKeyword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.automation.LaunchContactAutomationRequest.repeatedFields_ = [7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.automation.LaunchContactAutomationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.automation.LaunchContactAutomationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.automation.LaunchContactAutomationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.LaunchContactAutomationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    batch: jspb.Message.getFieldWithDefault(msg, 3, 0),
    searchMode: jspb.Message.getFieldWithDefault(msg, 4, 0),
    assignNumber: jspb.Message.getFieldWithDefault(msg, 5, 0),
    requiredPositions: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    positionsList: jspb.Message.toObjectList(msg.getPositionsList(),
    proto.automation.DomainSearchKeywords.toObject, includeInstance),
    senioritiesList: jspb.Message.toObjectList(msg.getSenioritiesList(),
    proto.automation.DomainSearchKeywords.toObject, includeInstance),
    skipCatchAllEmails: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    assignGenericEmails: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    autoBuy: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    source: jspb.Message.getFieldWithDefault(msg, 12, 0),
    campaignPipelineId: jspb.Message.getFieldWithDefault(msg, 13, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 14, 0),
    organizationId: jspb.Message.getFieldWithDefault(msg, 15, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.automation.LaunchContactAutomationRequest}
 */
proto.automation.LaunchContactAutomationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.automation.LaunchContactAutomationRequest;
  return proto.automation.LaunchContactAutomationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.automation.LaunchContactAutomationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.automation.LaunchContactAutomationRequest}
 */
proto.automation.LaunchContactAutomationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBatch(value);
      break;
    case 4:
      var value = /** @type {!proto.automation.ContactAutomationSearchingMode} */ (reader.readEnum());
      msg.setSearchMode(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAssignNumber(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequiredPositions(value);
      break;
    case 7:
      var value = new proto.automation.DomainSearchKeywords;
      reader.readMessage(value,proto.automation.DomainSearchKeywords.deserializeBinaryFromReader);
      msg.addPositions(value);
      break;
    case 8:
      var value = new proto.automation.DomainSearchKeywords;
      reader.readMessage(value,proto.automation.DomainSearchKeywords.deserializeBinaryFromReader);
      msg.addSeniorities(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSkipCatchAllEmails(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAssignGenericEmails(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutoBuy(value);
      break;
    case 12:
      var value = /** @type {!proto.common.ContactSearchSource} */ (reader.readEnum());
      msg.setSource(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignPipelineId(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrganizationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.automation.LaunchContactAutomationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.automation.LaunchContactAutomationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.automation.LaunchContactAutomationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.LaunchContactAutomationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getBatch();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getSearchMode();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getAssignNumber();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getRequiredPositions();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getPositionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.automation.DomainSearchKeywords.serializeBinaryToWriter
    );
  }
  f = message.getSenioritiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.automation.DomainSearchKeywords.serializeBinaryToWriter
    );
  }
  f = message.getSkipCatchAllEmails();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getAssignGenericEmails();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getAutoBuy();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getSource();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getCampaignPipelineId();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getOrganizationId();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
};


/**
 * optional int64 campaign_id = 1;
 * @return {number}
 */
proto.automation.LaunchContactAutomationRequest.prototype.getCampaignId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.LaunchContactAutomationRequest} returns this
 */
proto.automation.LaunchContactAutomationRequest.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 workspace_id = 2;
 * @return {number}
 */
proto.automation.LaunchContactAutomationRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.LaunchContactAutomationRequest} returns this
 */
proto.automation.LaunchContactAutomationRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 batch = 3;
 * @return {number}
 */
proto.automation.LaunchContactAutomationRequest.prototype.getBatch = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.LaunchContactAutomationRequest} returns this
 */
proto.automation.LaunchContactAutomationRequest.prototype.setBatch = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional ContactAutomationSearchingMode search_mode = 4;
 * @return {!proto.automation.ContactAutomationSearchingMode}
 */
proto.automation.LaunchContactAutomationRequest.prototype.getSearchMode = function() {
  return /** @type {!proto.automation.ContactAutomationSearchingMode} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.automation.ContactAutomationSearchingMode} value
 * @return {!proto.automation.LaunchContactAutomationRequest} returns this
 */
proto.automation.LaunchContactAutomationRequest.prototype.setSearchMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int32 assign_number = 5;
 * @return {number}
 */
proto.automation.LaunchContactAutomationRequest.prototype.getAssignNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.LaunchContactAutomationRequest} returns this
 */
proto.automation.LaunchContactAutomationRequest.prototype.setAssignNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bool required_positions = 6;
 * @return {boolean}
 */
proto.automation.LaunchContactAutomationRequest.prototype.getRequiredPositions = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.automation.LaunchContactAutomationRequest} returns this
 */
proto.automation.LaunchContactAutomationRequest.prototype.setRequiredPositions = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * repeated DomainSearchKeywords positions = 7;
 * @return {!Array<!proto.automation.DomainSearchKeywords>}
 */
proto.automation.LaunchContactAutomationRequest.prototype.getPositionsList = function() {
  return /** @type{!Array<!proto.automation.DomainSearchKeywords>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.automation.DomainSearchKeywords, 7));
};


/**
 * @param {!Array<!proto.automation.DomainSearchKeywords>} value
 * @return {!proto.automation.LaunchContactAutomationRequest} returns this
*/
proto.automation.LaunchContactAutomationRequest.prototype.setPositionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.automation.DomainSearchKeywords=} opt_value
 * @param {number=} opt_index
 * @return {!proto.automation.DomainSearchKeywords}
 */
proto.automation.LaunchContactAutomationRequest.prototype.addPositions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.automation.DomainSearchKeywords, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.automation.LaunchContactAutomationRequest} returns this
 */
proto.automation.LaunchContactAutomationRequest.prototype.clearPositionsList = function() {
  return this.setPositionsList([]);
};


/**
 * repeated DomainSearchKeywords seniorities = 8;
 * @return {!Array<!proto.automation.DomainSearchKeywords>}
 */
proto.automation.LaunchContactAutomationRequest.prototype.getSenioritiesList = function() {
  return /** @type{!Array<!proto.automation.DomainSearchKeywords>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.automation.DomainSearchKeywords, 8));
};


/**
 * @param {!Array<!proto.automation.DomainSearchKeywords>} value
 * @return {!proto.automation.LaunchContactAutomationRequest} returns this
*/
proto.automation.LaunchContactAutomationRequest.prototype.setSenioritiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.automation.DomainSearchKeywords=} opt_value
 * @param {number=} opt_index
 * @return {!proto.automation.DomainSearchKeywords}
 */
proto.automation.LaunchContactAutomationRequest.prototype.addSeniorities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.automation.DomainSearchKeywords, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.automation.LaunchContactAutomationRequest} returns this
 */
proto.automation.LaunchContactAutomationRequest.prototype.clearSenioritiesList = function() {
  return this.setSenioritiesList([]);
};


/**
 * optional bool skip_catch_all_emails = 9;
 * @return {boolean}
 */
proto.automation.LaunchContactAutomationRequest.prototype.getSkipCatchAllEmails = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.automation.LaunchContactAutomationRequest} returns this
 */
proto.automation.LaunchContactAutomationRequest.prototype.setSkipCatchAllEmails = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool assign_generic_emails = 10;
 * @return {boolean}
 */
proto.automation.LaunchContactAutomationRequest.prototype.getAssignGenericEmails = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.automation.LaunchContactAutomationRequest} returns this
 */
proto.automation.LaunchContactAutomationRequest.prototype.setAssignGenericEmails = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool auto_buy = 11;
 * @return {boolean}
 */
proto.automation.LaunchContactAutomationRequest.prototype.getAutoBuy = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.automation.LaunchContactAutomationRequest} returns this
 */
proto.automation.LaunchContactAutomationRequest.prototype.setAutoBuy = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional common.ContactSearchSource source = 12;
 * @return {!proto.common.ContactSearchSource}
 */
proto.automation.LaunchContactAutomationRequest.prototype.getSource = function() {
  return /** @type {!proto.common.ContactSearchSource} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.common.ContactSearchSource} value
 * @return {!proto.automation.LaunchContactAutomationRequest} returns this
 */
proto.automation.LaunchContactAutomationRequest.prototype.setSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional int64 campaign_pipeline_id = 13;
 * @return {number}
 */
proto.automation.LaunchContactAutomationRequest.prototype.getCampaignPipelineId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.LaunchContactAutomationRequest} returns this
 */
proto.automation.LaunchContactAutomationRequest.prototype.setCampaignPipelineId = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int64 user_id = 14;
 * @return {number}
 */
proto.automation.LaunchContactAutomationRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.LaunchContactAutomationRequest} returns this
 */
proto.automation.LaunchContactAutomationRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int64 organization_id = 15;
 * @return {number}
 */
proto.automation.LaunchContactAutomationRequest.prototype.getOrganizationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.LaunchContactAutomationRequest} returns this
 */
proto.automation.LaunchContactAutomationRequest.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.automation.NotificationContactAutomationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.automation.NotificationContactAutomationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.automation.NotificationContactAutomationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.NotificationContactAutomationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    automationId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    notify: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.automation.NotificationContactAutomationRequest}
 */
proto.automation.NotificationContactAutomationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.automation.NotificationContactAutomationRequest;
  return proto.automation.NotificationContactAutomationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.automation.NotificationContactAutomationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.automation.NotificationContactAutomationRequest}
 */
proto.automation.NotificationContactAutomationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAutomationId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNotify(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.automation.NotificationContactAutomationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.automation.NotificationContactAutomationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.automation.NotificationContactAutomationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.NotificationContactAutomationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAutomationId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getNotify();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional int64 automation_id = 1;
 * @return {number}
 */
proto.automation.NotificationContactAutomationRequest.prototype.getAutomationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.NotificationContactAutomationRequest} returns this
 */
proto.automation.NotificationContactAutomationRequest.prototype.setAutomationId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool notify = 2;
 * @return {boolean}
 */
proto.automation.NotificationContactAutomationRequest.prototype.getNotify = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.automation.NotificationContactAutomationRequest} returns this
 */
proto.automation.NotificationContactAutomationRequest.prototype.setNotify = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.automation.ContactAutomationState.repeatedFields_ = [13,14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.automation.ContactAutomationState.prototype.toObject = function(opt_includeInstance) {
  return proto.automation.ContactAutomationState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.automation.ContactAutomationState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.ContactAutomationState.toObject = function(includeInstance, msg) {
  var f, obj = {
    automationId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    campaignId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    totalItemsNumber: jspb.Message.getFieldWithDefault(msg, 4, 0),
    successItemsNumber: jspb.Message.getFieldWithDefault(msg, 5, 0),
    failedItemsNumber: jspb.Message.getFieldWithDefault(msg, 6, 0),
    creditsSpentNumber: jspb.Message.getFieldWithDefault(msg, 7, 0),
    status: jspb.Message.getFieldWithDefault(msg, 8, 0),
    searchMode: jspb.Message.getFieldWithDefault(msg, 9, 0),
    source: jspb.Message.getFieldWithDefault(msg, 10, 0),
    assignNumber: jspb.Message.getFieldWithDefault(msg, 11, 0),
    requiredPositions: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    positionsList: jspb.Message.toObjectList(msg.getPositionsList(),
    proto.automation.DomainSearchKeywords.toObject, includeInstance),
    senioritiesList: jspb.Message.toObjectList(msg.getSenioritiesList(),
    proto.automation.DomainSearchKeywords.toObject, includeInstance),
    skipCatchAllEmails: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    assignGenericEmails: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    progress: jspb.Message.getFieldWithDefault(msg, 17, 0),
    withWarning: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    optionalWarningMessage: jspb.Message.getFieldWithDefault(msg, 19, ""),
    campaignPipelineId: jspb.Message.getFieldWithDefault(msg, 20, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.automation.ContactAutomationState}
 */
proto.automation.ContactAutomationState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.automation.ContactAutomationState;
  return proto.automation.ContactAutomationState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.automation.ContactAutomationState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.automation.ContactAutomationState}
 */
proto.automation.ContactAutomationState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAutomationId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalItemsNumber(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSuccessItemsNumber(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFailedItemsNumber(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCreditsSpentNumber(value);
      break;
    case 8:
      var value = /** @type {!proto.common.CampaignAutomationStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 9:
      var value = /** @type {!proto.automation.ContactAutomationSearchingMode} */ (reader.readEnum());
      msg.setSearchMode(value);
      break;
    case 10:
      var value = /** @type {!proto.common.ContactSearchSource} */ (reader.readEnum());
      msg.setSource(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAssignNumber(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequiredPositions(value);
      break;
    case 13:
      var value = new proto.automation.DomainSearchKeywords;
      reader.readMessage(value,proto.automation.DomainSearchKeywords.deserializeBinaryFromReader);
      msg.addPositions(value);
      break;
    case 14:
      var value = new proto.automation.DomainSearchKeywords;
      reader.readMessage(value,proto.automation.DomainSearchKeywords.deserializeBinaryFromReader);
      msg.addSeniorities(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSkipCatchAllEmails(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAssignGenericEmails(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProgress(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWithWarning(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionalWarningMessage(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignPipelineId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.automation.ContactAutomationState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.automation.ContactAutomationState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.automation.ContactAutomationState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.ContactAutomationState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAutomationId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCampaignId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getTotalItemsNumber();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getSuccessItemsNumber();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getFailedItemsNumber();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getCreditsSpentNumber();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getSearchMode();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getSource();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getAssignNumber();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getRequiredPositions();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getPositionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.automation.DomainSearchKeywords.serializeBinaryToWriter
    );
  }
  f = message.getSenioritiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.automation.DomainSearchKeywords.serializeBinaryToWriter
    );
  }
  f = message.getSkipCatchAllEmails();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getAssignGenericEmails();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getProgress();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getWithWarning();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getOptionalWarningMessage();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getCampaignPipelineId();
  if (f !== 0) {
    writer.writeInt64(
      20,
      f
    );
  }
};


/**
 * optional int64 automation_id = 1;
 * @return {number}
 */
proto.automation.ContactAutomationState.prototype.getAutomationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.ContactAutomationState} returns this
 */
proto.automation.ContactAutomationState.prototype.setAutomationId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 campaign_id = 2;
 * @return {number}
 */
proto.automation.ContactAutomationState.prototype.getCampaignId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.ContactAutomationState} returns this
 */
proto.automation.ContactAutomationState.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 workspace_id = 3;
 * @return {number}
 */
proto.automation.ContactAutomationState.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.ContactAutomationState} returns this
 */
proto.automation.ContactAutomationState.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 total_items_number = 4;
 * @return {number}
 */
proto.automation.ContactAutomationState.prototype.getTotalItemsNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.ContactAutomationState} returns this
 */
proto.automation.ContactAutomationState.prototype.setTotalItemsNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 success_items_number = 5;
 * @return {number}
 */
proto.automation.ContactAutomationState.prototype.getSuccessItemsNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.ContactAutomationState} returns this
 */
proto.automation.ContactAutomationState.prototype.setSuccessItemsNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 failed_items_number = 6;
 * @return {number}
 */
proto.automation.ContactAutomationState.prototype.getFailedItemsNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.ContactAutomationState} returns this
 */
proto.automation.ContactAutomationState.prototype.setFailedItemsNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 credits_spent_number = 7;
 * @return {number}
 */
proto.automation.ContactAutomationState.prototype.getCreditsSpentNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.ContactAutomationState} returns this
 */
proto.automation.ContactAutomationState.prototype.setCreditsSpentNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional common.CampaignAutomationStatus status = 8;
 * @return {!proto.common.CampaignAutomationStatus}
 */
proto.automation.ContactAutomationState.prototype.getStatus = function() {
  return /** @type {!proto.common.CampaignAutomationStatus} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.common.CampaignAutomationStatus} value
 * @return {!proto.automation.ContactAutomationState} returns this
 */
proto.automation.ContactAutomationState.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional ContactAutomationSearchingMode search_mode = 9;
 * @return {!proto.automation.ContactAutomationSearchingMode}
 */
proto.automation.ContactAutomationState.prototype.getSearchMode = function() {
  return /** @type {!proto.automation.ContactAutomationSearchingMode} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.automation.ContactAutomationSearchingMode} value
 * @return {!proto.automation.ContactAutomationState} returns this
 */
proto.automation.ContactAutomationState.prototype.setSearchMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional common.ContactSearchSource source = 10;
 * @return {!proto.common.ContactSearchSource}
 */
proto.automation.ContactAutomationState.prototype.getSource = function() {
  return /** @type {!proto.common.ContactSearchSource} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.common.ContactSearchSource} value
 * @return {!proto.automation.ContactAutomationState} returns this
 */
proto.automation.ContactAutomationState.prototype.setSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional int32 assign_number = 11;
 * @return {number}
 */
proto.automation.ContactAutomationState.prototype.getAssignNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.ContactAutomationState} returns this
 */
proto.automation.ContactAutomationState.prototype.setAssignNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional bool required_positions = 12;
 * @return {boolean}
 */
proto.automation.ContactAutomationState.prototype.getRequiredPositions = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.automation.ContactAutomationState} returns this
 */
proto.automation.ContactAutomationState.prototype.setRequiredPositions = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * repeated DomainSearchKeywords positions = 13;
 * @return {!Array<!proto.automation.DomainSearchKeywords>}
 */
proto.automation.ContactAutomationState.prototype.getPositionsList = function() {
  return /** @type{!Array<!proto.automation.DomainSearchKeywords>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.automation.DomainSearchKeywords, 13));
};


/**
 * @param {!Array<!proto.automation.DomainSearchKeywords>} value
 * @return {!proto.automation.ContactAutomationState} returns this
*/
proto.automation.ContactAutomationState.prototype.setPositionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.automation.DomainSearchKeywords=} opt_value
 * @param {number=} opt_index
 * @return {!proto.automation.DomainSearchKeywords}
 */
proto.automation.ContactAutomationState.prototype.addPositions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.automation.DomainSearchKeywords, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.automation.ContactAutomationState} returns this
 */
proto.automation.ContactAutomationState.prototype.clearPositionsList = function() {
  return this.setPositionsList([]);
};


/**
 * repeated DomainSearchKeywords seniorities = 14;
 * @return {!Array<!proto.automation.DomainSearchKeywords>}
 */
proto.automation.ContactAutomationState.prototype.getSenioritiesList = function() {
  return /** @type{!Array<!proto.automation.DomainSearchKeywords>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.automation.DomainSearchKeywords, 14));
};


/**
 * @param {!Array<!proto.automation.DomainSearchKeywords>} value
 * @return {!proto.automation.ContactAutomationState} returns this
*/
proto.automation.ContactAutomationState.prototype.setSenioritiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.automation.DomainSearchKeywords=} opt_value
 * @param {number=} opt_index
 * @return {!proto.automation.DomainSearchKeywords}
 */
proto.automation.ContactAutomationState.prototype.addSeniorities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.automation.DomainSearchKeywords, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.automation.ContactAutomationState} returns this
 */
proto.automation.ContactAutomationState.prototype.clearSenioritiesList = function() {
  return this.setSenioritiesList([]);
};


/**
 * optional bool skip_catch_all_emails = 15;
 * @return {boolean}
 */
proto.automation.ContactAutomationState.prototype.getSkipCatchAllEmails = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.automation.ContactAutomationState} returns this
 */
proto.automation.ContactAutomationState.prototype.setSkipCatchAllEmails = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional bool assign_generic_emails = 16;
 * @return {boolean}
 */
proto.automation.ContactAutomationState.prototype.getAssignGenericEmails = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.automation.ContactAutomationState} returns this
 */
proto.automation.ContactAutomationState.prototype.setAssignGenericEmails = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional int32 progress = 17;
 * @return {number}
 */
proto.automation.ContactAutomationState.prototype.getProgress = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.ContactAutomationState} returns this
 */
proto.automation.ContactAutomationState.prototype.setProgress = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional bool with_warning = 18;
 * @return {boolean}
 */
proto.automation.ContactAutomationState.prototype.getWithWarning = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.automation.ContactAutomationState} returns this
 */
proto.automation.ContactAutomationState.prototype.setWithWarning = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional string optional_warning_message = 19;
 * @return {string}
 */
proto.automation.ContactAutomationState.prototype.getOptionalWarningMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.automation.ContactAutomationState} returns this
 */
proto.automation.ContactAutomationState.prototype.setOptionalWarningMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional int64 campaign_pipeline_id = 20;
 * @return {number}
 */
proto.automation.ContactAutomationState.prototype.getCampaignPipelineId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.ContactAutomationState} returns this
 */
proto.automation.ContactAutomationState.prototype.setCampaignPipelineId = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.automation.ContactAutomationPresetResponse.repeatedFields_ = [6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.automation.ContactAutomationPresetResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.automation.ContactAutomationPresetResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.automation.ContactAutomationPresetResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.ContactAutomationPresetResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    pipelineId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    searchMode: jspb.Message.getFieldWithDefault(msg, 2, 0),
    source: jspb.Message.getFieldWithDefault(msg, 3, 0),
    assignNumber: jspb.Message.getFieldWithDefault(msg, 4, 0),
    requiredPositions: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    positionsList: jspb.Message.toObjectList(msg.getPositionsList(),
    proto.automation.DomainSearchKeywords.toObject, includeInstance),
    senioritiesList: jspb.Message.toObjectList(msg.getSenioritiesList(),
    proto.automation.DomainSearchKeywords.toObject, includeInstance),
    skipCatchAllEmails: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    assignGenericEmails: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    isDefault: jspb.Message.getBooleanFieldWithDefault(msg, 10, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.automation.ContactAutomationPresetResponse}
 */
proto.automation.ContactAutomationPresetResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.automation.ContactAutomationPresetResponse;
  return proto.automation.ContactAutomationPresetResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.automation.ContactAutomationPresetResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.automation.ContactAutomationPresetResponse}
 */
proto.automation.ContactAutomationPresetResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPipelineId(value);
      break;
    case 2:
      var value = /** @type {!proto.automation.ContactAutomationSearchingMode} */ (reader.readEnum());
      msg.setSearchMode(value);
      break;
    case 3:
      var value = /** @type {!proto.common.ContactSearchSource} */ (reader.readEnum());
      msg.setSource(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAssignNumber(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequiredPositions(value);
      break;
    case 6:
      var value = new proto.automation.DomainSearchKeywords;
      reader.readMessage(value,proto.automation.DomainSearchKeywords.deserializeBinaryFromReader);
      msg.addPositions(value);
      break;
    case 7:
      var value = new proto.automation.DomainSearchKeywords;
      reader.readMessage(value,proto.automation.DomainSearchKeywords.deserializeBinaryFromReader);
      msg.addSeniorities(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSkipCatchAllEmails(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAssignGenericEmails(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDefault(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.automation.ContactAutomationPresetResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.automation.ContactAutomationPresetResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.automation.ContactAutomationPresetResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.ContactAutomationPresetResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPipelineId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSearchMode();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getSource();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getAssignNumber();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getRequiredPositions();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getPositionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.automation.DomainSearchKeywords.serializeBinaryToWriter
    );
  }
  f = message.getSenioritiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.automation.DomainSearchKeywords.serializeBinaryToWriter
    );
  }
  f = message.getSkipCatchAllEmails();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getAssignGenericEmails();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getIsDefault();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
};


/**
 * optional int64 pipeline_id = 1;
 * @return {number}
 */
proto.automation.ContactAutomationPresetResponse.prototype.getPipelineId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.ContactAutomationPresetResponse} returns this
 */
proto.automation.ContactAutomationPresetResponse.prototype.setPipelineId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional ContactAutomationSearchingMode search_mode = 2;
 * @return {!proto.automation.ContactAutomationSearchingMode}
 */
proto.automation.ContactAutomationPresetResponse.prototype.getSearchMode = function() {
  return /** @type {!proto.automation.ContactAutomationSearchingMode} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.automation.ContactAutomationSearchingMode} value
 * @return {!proto.automation.ContactAutomationPresetResponse} returns this
 */
proto.automation.ContactAutomationPresetResponse.prototype.setSearchMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional common.ContactSearchSource source = 3;
 * @return {!proto.common.ContactSearchSource}
 */
proto.automation.ContactAutomationPresetResponse.prototype.getSource = function() {
  return /** @type {!proto.common.ContactSearchSource} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.common.ContactSearchSource} value
 * @return {!proto.automation.ContactAutomationPresetResponse} returns this
 */
proto.automation.ContactAutomationPresetResponse.prototype.setSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int32 assign_number = 4;
 * @return {number}
 */
proto.automation.ContactAutomationPresetResponse.prototype.getAssignNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.ContactAutomationPresetResponse} returns this
 */
proto.automation.ContactAutomationPresetResponse.prototype.setAssignNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool required_positions = 5;
 * @return {boolean}
 */
proto.automation.ContactAutomationPresetResponse.prototype.getRequiredPositions = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.automation.ContactAutomationPresetResponse} returns this
 */
proto.automation.ContactAutomationPresetResponse.prototype.setRequiredPositions = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * repeated DomainSearchKeywords positions = 6;
 * @return {!Array<!proto.automation.DomainSearchKeywords>}
 */
proto.automation.ContactAutomationPresetResponse.prototype.getPositionsList = function() {
  return /** @type{!Array<!proto.automation.DomainSearchKeywords>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.automation.DomainSearchKeywords, 6));
};


/**
 * @param {!Array<!proto.automation.DomainSearchKeywords>} value
 * @return {!proto.automation.ContactAutomationPresetResponse} returns this
*/
proto.automation.ContactAutomationPresetResponse.prototype.setPositionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.automation.DomainSearchKeywords=} opt_value
 * @param {number=} opt_index
 * @return {!proto.automation.DomainSearchKeywords}
 */
proto.automation.ContactAutomationPresetResponse.prototype.addPositions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.automation.DomainSearchKeywords, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.automation.ContactAutomationPresetResponse} returns this
 */
proto.automation.ContactAutomationPresetResponse.prototype.clearPositionsList = function() {
  return this.setPositionsList([]);
};


/**
 * repeated DomainSearchKeywords seniorities = 7;
 * @return {!Array<!proto.automation.DomainSearchKeywords>}
 */
proto.automation.ContactAutomationPresetResponse.prototype.getSenioritiesList = function() {
  return /** @type{!Array<!proto.automation.DomainSearchKeywords>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.automation.DomainSearchKeywords, 7));
};


/**
 * @param {!Array<!proto.automation.DomainSearchKeywords>} value
 * @return {!proto.automation.ContactAutomationPresetResponse} returns this
*/
proto.automation.ContactAutomationPresetResponse.prototype.setSenioritiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.automation.DomainSearchKeywords=} opt_value
 * @param {number=} opt_index
 * @return {!proto.automation.DomainSearchKeywords}
 */
proto.automation.ContactAutomationPresetResponse.prototype.addSeniorities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.automation.DomainSearchKeywords, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.automation.ContactAutomationPresetResponse} returns this
 */
proto.automation.ContactAutomationPresetResponse.prototype.clearSenioritiesList = function() {
  return this.setSenioritiesList([]);
};


/**
 * optional bool skip_catch_all_emails = 8;
 * @return {boolean}
 */
proto.automation.ContactAutomationPresetResponse.prototype.getSkipCatchAllEmails = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.automation.ContactAutomationPresetResponse} returns this
 */
proto.automation.ContactAutomationPresetResponse.prototype.setSkipCatchAllEmails = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool assign_generic_emails = 9;
 * @return {boolean}
 */
proto.automation.ContactAutomationPresetResponse.prototype.getAssignGenericEmails = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.automation.ContactAutomationPresetResponse} returns this
 */
proto.automation.ContactAutomationPresetResponse.prototype.setAssignGenericEmails = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool is_default = 10;
 * @return {boolean}
 */
proto.automation.ContactAutomationPresetResponse.prototype.getIsDefault = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.automation.ContactAutomationPresetResponse} returns this
 */
proto.automation.ContactAutomationPresetResponse.prototype.setIsDefault = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * @enum {number}
 */
proto.automation.ContactAutomationSearchingMode = {
  ARTICLE_OR_DOMAIN: 0,
  ARTICLE_AND_DOMAIN: 1,
  DOMAIN_ONLY: 2,
  ARTICLE_ONLY: 3,
  ARTICLE_ONLY_V2: 4,
  PODCAST: 6
};

goog.object.extend(exports, proto.automation);
