import React from 'react';

import { SVGIcon } from '@uikit/Icon/Icon';

import './FilterCreationSelectedKeywords.scss';

type FilterCreationSelectedKeywordsType = {
  keywords: string[];
  changeKeywords: (newKeywordsArray: string[]) => void;
};

const FilterCreationSelectedKeywords = ({
  keywords,
  changeKeywords,
}: FilterCreationSelectedKeywordsType): JSX.Element => {
  const handleRemoveKeyword = (removingKeyword: string) => {
    const newKeywords = keywords.filter((keyword) => keyword !== removingKeyword);
    changeKeywords(newKeywords);
  };

  return (
    <div className="filter-creation-selected-keywords">
      <div className="filter-creation-selected-keywords__title">
        Selected keywords ({keywords.length})
      </div>
      <div className="filter-creation-selected-keywords__body">
        {keywords.map((keyword: string) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <div key={keyword} className="filter-creation-selected-keywords__item">
              <div className="filter-creation-selected-keywords__item-title">{keyword}</div>
              <div
                className="filter-creation-selected-keywords__delete-item-button"
                onClick={() => handleRemoveKeyword(keyword)}
                tabIndex={0}
                role="button"
              >
                <SVGIcon icon="crossDelete" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FilterCreationSelectedKeywords;
