import UserService from '../../../respona_api/generated/user_pb_service';

const service = (function () {
  let instance = null;

  return {
    getInstance: (): UserService.UserServiceClient => {
      if (!instance) {
        // @ts-ignore
        instance = new UserService.UserServiceClient(API_HOST);
      }

      return instance;
    },
  };
})();

export default service;
