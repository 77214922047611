import createAction from '@redux/utils/createAction';

import { PeopleWebsiteFilterType } from '@ts/company.types';
import { FolderType } from '@ts/common.types';

export const SET_COMPANIES_FOLDERS_DATA = 'SET_COMPANIES_FOLDERS_DATA';
export const SET_COMPANIES_FOLDERS_DATA_LS = 'SET_COMPANIES_FOLDERS_DATA_LS';
export const ADD_COMPANIES_FOLDER_DATA = 'ADD_COMPANIES_FOLDER_DATA';
export const REMOVE_COMPANIES_FOLDER_DATA = 'REMOVE_COMPANIES_FOLDER_DATA';
export const UPDATE_COMPANIES_FOLDER_DATA = 'UPDATE_COMPANIES_FOLDER_DATA';

export const SET_COMPANIES_FILTERS = 'SET_COMPANIES_FILTERS';
export const THROW_COMPANIES_FILTERS = 'THROW_COMPANIES_FILTERS';

export const setCompaniesFoldersData = createAction<FolderType[]>(SET_COMPANIES_FOLDERS_DATA);
export const setCompaniesFoldersDataLs = createAction<string>(SET_COMPANIES_FOLDERS_DATA_LS);
export const addCompaniesFolderData = createAction<FolderType>(ADD_COMPANIES_FOLDER_DATA);
export const removeCompaniesFolderData = createAction<number>(REMOVE_COMPANIES_FOLDER_DATA);
export const updateCompaniesFolderData = createAction<FolderType>(UPDATE_COMPANIES_FOLDER_DATA);

export const setCompaniesFilters = createAction<{ [key: string]: PeopleWebsiteFilterType }>(
  SET_COMPANIES_FILTERS
);
export const throwCompaniesFilters = createAction<void>(THROW_COMPANIES_FILTERS);
