import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { OpenCampaignType } from '@ts/campaigns.types';
import { fullStateSelector } from '@redux/selectors/base.selectors';
import { schedulesSelector } from '@redux/selectors/schedules.selectors';

import { sendingStrategyOptions, priorityOptions } from '@constants/options';
import Input from '@uikit/Input/Input';
import Display from '@components/Display/Display';
import QuestionTooltip from '@pages/CampaignCreationPage/_components/QuestionTooltip/QuestionTooltip';
import tooltipTexts from '@pages/CampaignCreationPage/_components/QuestionTooltip/tooltipTexts';
import { mainSettingFields, advancedSettingsFields } from '@pages/CampaignCreationPage/fields';
import { minMaxValidation } from '@pages/CampaignCreationPage/fieldsValidations';

import './Settings.scss';
import {
  CampaignCrudRequest,
  SendingPriorityMap,
  SendingStrategyMap,
} from 'respona_api/generated/campaign_pb';
import useMailboxAccounts from '@hooks/useMailboxAccounts';
import { QueryCollectionHookType } from '@ts/common.types';
import { MailboxAccountType } from '@ts/mailboxAccounts.types';
import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';

function Settings({
  openCampaign,
  onUpdate,
  RenderHeader,
}: {
  openCampaign: OpenCampaignType;
  onUpdate: (data: CampaignCrudRequest.AsObject) => void;
  RenderHeader: () => JSX.Element;
}): JSX.Element {
  const { items: mbAccounts }: QueryCollectionHookType<MailboxAccountType> =
    useMailboxAccounts(useCurrentWorkspaceId());
  const state = useSelector(fullStateSelector);
  const { items: schedules } = useSelector(schedulesSelector);

  const settedSchedule = useMemo(
    () => schedules.find(({ id }) => id === openCampaign.settings.scheduleId),
    [schedules, openCampaign.settings.scheduleId]
  );

  const settedMbAccounts = useMemo(() => {
    if (!mbAccounts?.length || !openCampaign.settings.senderMailboxIdList?.length) {
      return [];
    }
    return mbAccounts.reduce((acc, item) => {
      if (openCampaign.settings.senderMailboxIdList.includes(item.id)) {
        acc.push({
          value: item.id,
          label: item.email,
        });
      }
      return acc;
    }, []);
  }, [mbAccounts, openCampaign.settings.senderMailboxIdList]);

  const [formState, changeFormState] = useState({
    campaignName: openCampaign.title,
    priority: priorityOptions.find(({ value }) => value === openCampaign.settings.sendingPriority),
    senderEmail: settedMbAccounts,
    campaignSchedule: settedSchedule
      ? {
          value: settedSchedule.id,
          label: settedSchedule.title,
        }
      : null,
    autoBCC: openCampaign.settings.bccEmailsList.map((email) => ({
      value: email,
      label: email,
    })),
    autoCC: openCampaign.settings.ccEmailsList.map((email) => ({
      value: email,
      label: email,
    })),
    sendingStrategy: sendingStrategyOptions.find(
      ({ value }) => value === openCampaign.settings.sendingStrategy
    ),
    conflictsBuffer: openCampaign.settings.notRepliedStrategyBufferDays || 3,
    isIncludeAnotherCampaign: true,
    isContactedInPath: true,
    contactedPastDays: openCampaign.settings.contactedPastDaysNumber || 30,
  });

  useEffect(() => {
    if (settedMbAccounts?.length > formState?.senderEmail?.length)
      changeFormState((curFormState) => ({
        ...curFormState,
        senderEmail: settedMbAccounts,
      }));
  }, [settedMbAccounts]);

  const createTextFieldChangeHandler =
    (fieldName) =>
    ({ target: { value } }) => {
      changeFormState((curFormState) => ({
        ...curFormState,
        [fieldName]: fieldName === 'conflictsBuffer' ? minMaxValidation(value, 1, 30, 3) : value,
      }));
    };

  const createSelectFieldChangeHandler = (fieldName) => (value) =>
    changeFormState((curFormState) => ({
      ...curFormState,
      [fieldName]: value,
    }));

  const createFieldChangeHandler = (fieldName, type) => {
    if (type === 'text') {
      return createTextFieldChangeHandler(fieldName);
    }

    return createSelectFieldChangeHandler(fieldName);
  };

  const handleUpdateClick = () => {
    const requestData: CampaignCrudRequest.AsObject = {
      id: openCampaign.id,
      workspaceId: openCampaign.workspaceId,
      title: formState.campaignName,

      settings: {
        ...openCampaign.settings,
        sendingStrategy: formState.sendingStrategy
          ?.value as SendingStrategyMap[keyof SendingStrategyMap],
        sendingPriority: formState.priority?.value as SendingPriorityMap[keyof SendingPriorityMap],
        scheduleId: formState.campaignSchedule?.value,
        senderMailboxIdList: formState.senderEmail.map((item) => item.value),
        bccEmailsList: formState.autoBCC.map(({ value }) => value),
        ccEmailsList: formState.autoCC.map(({ value }) => value),
        notRepliedStrategyBufferDays: formState.conflictsBuffer,
      },
    };

    onUpdate(requestData);
  };

  return (
    <>
      <RenderHeader />
      <div className="analytic-settings">
        <div className="analytic-settings__form">
          <div className="analytic-settings__column">
            {Object.values(mainSettingFields).map(({ label, key, type, Renderer }) => (
              <div className="analytic-settings__row" key={label}>
                <div className="analytic-settings__label">
                  {label}
                  {tooltipTexts[key] ? (
                    <div className="analytic-settings__label-tooltip">
                      <QuestionTooltip text={tooltipTexts[key]} />
                    </div>
                  ) : null}
                </div>
                <div className="analytic-settings__field">
                  <Renderer
                    state={state}
                    value={formState[key]}
                    isDisabled
                    onChange={createFieldChangeHandler(key, type)}
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="analytic-settings__column">
            {Object.values(advancedSettingsFields).map(({ label, key, Renderer }) => (
              <div className="analytic-settings__row" key={label}>
                <div className="analytic-settings__label">
                  {label}
                  {tooltipTexts[key] ? (
                    <div className="preparing-form__field-label-tooltip">
                      <QuestionTooltip text={tooltipTexts[key]} />
                    </div>
                  ) : null}
                </div>
                <div className="analytic-settings__field">
                  <Renderer
                    state={state}
                    value={formState[key]}
                    isDisabled
                    onChange={createSelectFieldChangeHandler(key)}
                  />
                </div>
              </div>
            ))}
            <Display isVisible={formState?.sendingStrategy?.value === 3}>
              <div className="analytic-settings__row">
                <div className="analytic-settings__label">Buffer between contacts</div>
                <div className="analytic-settings__field">
                  <Input
                    disabled
                    afterText="Day(s)"
                    placeholder="Buffer between contacts..."
                    value={formState.conflictsBuffer}
                    onChange={createTextFieldChangeHandler('conflictsBuffer')}
                  />
                </div>
              </div>
            </Display>
          </div>
        </div>
        {/* <div className="analytic-settings__footer"> */}
        {/*  <Button onClick={handleUpdateClick}>Update information</Button> */}
        {/* </div> */}
      </div>
    </>
  );
}

export default Settings;
