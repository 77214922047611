import React from 'react';

const ScaleIcon = ({ color = '#BDBDBD' }: { color: string }): JSX.Element => (
  <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.91699 17.7297H1.52173V10.793H6.91699"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M13.0828 13.1016H18.478V17.7261H13.0828"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M13.0828 8.47656H6.91675V17.7256H13.0828V8.47656Z"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M9.99993 0L10.9063 1.83593L12.9319 2.13036L11.4659 3.55933L11.812 5.57715L9.99993 4.62451L8.18789 5.57715L8.53396 3.55933L7.06799 2.13036L9.09353 1.83593L9.99993 0Z"
      fill={color}
    />
  </svg>
);

export default ScaleIcon;
