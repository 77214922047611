import { SVGIcon } from '@uikit/Icon/Icon';
import React from 'react';
import cn from 'classnames';

import './IssuesDropdownStatus.scss';

export enum IssuesDropdownStatusName {
  'default' = 'default',
  'warning' = 'warning',
  'danger' = 'danger',
}

const IssuesDropdownStatus = ({ status }: { status?: IssuesDropdownStatusName }) => {
  const className = cn('issues-dropdown-indicator', {
    'issues-dropdown-indicator--warning': status === IssuesDropdownStatusName.warning,
    'issues-dropdown-indicator--danger': status === IssuesDropdownStatusName.danger,
  });

  return (
    <div className={className}>
      <SVGIcon icon="warningTriangle" size={12} />
    </div>
  );
};

export default IssuesDropdownStatus;
