import CampaignTemplate from '@components/CampaignTemplates/CampaignTemplate/CampaignTemplate';
import CampaignTemplatesList from '@components/CampaignTemplates/CampaignTemplatesList/CampaignTemplatesList';
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import './CampaignTemplatesPage.scss';
import FirstCampaignModal from '@components/Campaigns/FirstCampaignModal/FirstCampaignModal';

const CampaignTemplatesPage = () => {
  const firstCampaign = localStorage.getItem('firstCampaign');

  return (
    <div className="campaign-templates">
      {firstCampaign != null && firstCampaign === 'true' && <FirstCampaignModal />}
      <Switch>
        <Route exact path="/campaign-templates">
          <CampaignTemplatesList />
        </Route>

        <Route path="/campaign-templates/:templateId" component={CampaignTemplate} />

        <Redirect to="/campaign-templates" />
      </Switch>
    </div>
  );
};

export default CampaignTemplatesPage;
