// package: automation
// file: automation-personalization.proto

var automation_personalization_pb = require("./automation-personalization_pb");
var common_pb = require("./common_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var PersonalizationAutomationService = (function () {
  function PersonalizationAutomationService() {}
  PersonalizationAutomationService.serviceName = "automation.PersonalizationAutomationService";
  return PersonalizationAutomationService;
}());

PersonalizationAutomationService.personalizeOpportunityVariables = {
  methodName: "personalizeOpportunityVariables",
  service: PersonalizationAutomationService,
  requestStream: false,
  responseStream: true,
  requestType: automation_personalization_pb.OpportunityPersonalizationRequest,
  responseType: automation_personalization_pb.OpportunityPersonalizationResponse
};

PersonalizationAutomationService.getPreset = {
  methodName: "getPreset",
  service: PersonalizationAutomationService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: automation_personalization_pb.PersonalizationAutomationPreset
};

exports.PersonalizationAutomationService = PersonalizationAutomationService;

function PersonalizationAutomationServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

PersonalizationAutomationServiceClient.prototype.personalizeOpportunityVariables = function personalizeOpportunityVariables(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(PersonalizationAutomationService.personalizeOpportunityVariables, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

PersonalizationAutomationServiceClient.prototype.getPreset = function getPreset(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PersonalizationAutomationService.getPreset, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.PersonalizationAutomationServiceClient = PersonalizationAutomationServiceClient;

