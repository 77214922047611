import React from 'react';
import { PRIMARY_COLOR } from '@constants/colors';

export default ({ color = PRIMARY_COLOR }) => (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.5416 4.74999C5.38502 4.74999 5.23196 4.79642 5.10177 4.88341C4.97158 4.9704 4.87011 5.09404 4.81019 5.2387C4.75027 5.38336 4.7346 5.54254 4.76514 5.69611C4.79569 5.84967 4.87109 5.99074 4.98181 6.10145C5.09252 6.21217 5.23358 6.28757 5.38715 6.31811C5.54072 6.34866 5.6999 6.33298 5.84456 6.27306C5.98921 6.21314 6.11286 6.11167 6.19985 5.98149C6.28683 5.8513 6.33327 5.69824 6.33327 5.54166C6.33327 5.3317 6.24986 5.13033 6.10139 4.98187C5.95293 4.8334 5.75156 4.74999 5.5416 4.74999ZM17.187 9.32583L9.68202 1.83666C9.6082 1.75707 9.51881 1.69351 9.41939 1.64993C9.31997 1.60634 9.21265 1.58368 9.1041 1.58333H4.3541C4.24991 1.58272 4.14663 1.60269 4.05017 1.64209C3.95372 1.68149 3.86599 1.73954 3.79202 1.81291L1.81285 3.78416C1.73948 3.85813 1.68143 3.94586 1.64203 4.04232C1.60263 4.13877 1.58266 4.24205 1.58327 4.34624V9.09624C1.58618 9.30585 1.66818 9.50662 1.81285 9.65833L9.32577 17.1792C9.47748 17.3238 9.67824 17.4058 9.88785 17.4087C9.99204 17.4093 10.0953 17.3894 10.1918 17.35C10.2882 17.3106 10.376 17.2525 10.4499 17.1792L17.187 10.4421C17.2604 10.3681 17.3184 10.2804 17.3578 10.1839C17.3972 10.0875 17.4172 9.98418 17.4166 9.87999C17.4117 9.67313 17.3298 9.47554 17.187 9.32583ZM9.88785 15.5087L3.1666 8.77958V4.67083L4.67077 3.16666H8.7716L15.5008 9.88791L9.88785 15.5087Z"
      fill={color}
    />
  </svg>
);
