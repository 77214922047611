import React, { useCallback, useMemo, useRef } from 'react';
import ReactAsyncSelect from 'react-select/async';

import { SVGIcon, SvgIconType } from '@uikit/Icon/Icon';

import './Select.scss';
import { getCustomStyles } from '@uikit/Select/styles';

type AsyncSelectProps = {
  value: string | number;
  loadOptions: (
    inputValue: string,
    callback: (options: { value: string | number; label: string }[]) => void
  ) => void;
  defaultOptions: { value: string | number; label: string }[];
  onChange: (value: string | number) => void;
  className?: string;
  icon?: SvgIconType;
  iconColor?: string;
  selectClass?: string;
  additionalStyles?: object;
};

const useDebouncedCallback = (callback: (...args: any[]) => void, delay: number) => {
  const timerRef = useRef<number | undefined>(undefined);

  return useCallback(
    (...args: any[]) => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = window.setTimeout(() => {
        callback(...args);
      }, delay);
    },
    [callback, delay]
  );
};

function AsyncSelect({
  value,
  loadOptions,
  defaultOptions,
  className,
  onChange,
  icon = null,
  iconColor = undefined,
  selectClass = '',
  additionalStyles = {},
  ...props
}: AsyncSelectProps) {
  const debouncedLoadOptions = useDebouncedCallback(loadOptions, 400);

  const customStyles = useMemo(() => getCustomStyles(false, !!icon), [icon]);
  const allProps = useMemo(
    () => ({
      ...props,
      value,
      loadOptions: debouncedLoadOptions,
      defaultOptions,
      onChange,
      className,
      styles: { ...customStyles, ...additionalStyles },
    }),
    [
      props,
      value,
      debouncedLoadOptions,
      defaultOptions,
      onChange,
      className,
      customStyles,
      additionalStyles,
    ]
  );

  return (
    <div className={`select ${selectClass}`}>
      <ReactAsyncSelect {...allProps} />
      {icon && (
        <div className="select__icon">
          <SVGIcon icon={icon as SvgIconType} color={iconColor} />
        </div>
      )}
    </div>
  );
}

export default AsyncSelect;
