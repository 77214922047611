import React from 'react';
import { PRIMARY_COLOR } from '@constants/colors';

export default ({ color = PRIMARY_COLOR, size = 20 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.417 16.5807H16.7312"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7126 4.12902C11.3268 3.34652 12.3193 3.38735 13.1026 4.00152L14.2609 4.90985C15.0443 5.52402 15.3218 6.47652 14.7076 7.26069L7.80009 16.0732C7.56926 16.3682 7.21676 16.5424 6.84176 16.5465L4.17759 16.5807L3.57426 13.9849C3.48926 13.6207 3.57426 13.2374 3.80509 12.9415L10.7126 4.12902Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.41895 5.7793L13.4139 8.91096"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
