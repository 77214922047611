import { SET_MAKE_CONTENT_SEARCH_REQUEST } from 'src/redux/actions/appActions.actions';

export type AppActionsStateType = {
  makeContentSearchRequest: () => void;
};

const defaultState: AppActionsStateType = {
  makeContentSearchRequest: () => {},
};

export default (state = defaultState, { type, payload }): AppActionsStateType => {
  switch (type) {
    case SET_MAKE_CONTENT_SEARCH_REQUEST:
      return {
        ...state,
        makeContentSearchRequest: payload,
      };
    default:
      return state;
  }
};
