import { StateType } from '@redux/reducers';
import { ThreadStatusMap } from 'respona_api/generated/mailbox_pb';

export const inboxSelectedMailboxesIdsSelector = ({
  inbox,
}: StateType): typeof inbox.selectedMailboxesIds => {
  return inbox.selectedMailboxesIds;
};
export const inboxSelectedStatusesSelector = ({
  inbox,
}: StateType): ThreadStatusMap[keyof ThreadStatusMap] => {
  return inbox.selectedStatus;
};

export const inboxFiltersSelector = ({ inbox }: StateType): typeof inbox.filters => {
  return inbox.filters;
};
