import React from 'react';
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  Tooltip,
  Line,
  LineChart,
} from 'recharts';

import GraphicHeader from '@components/Insights/GraphicHeader/GraphicHeader';
import './GraphicContent.scss';

const GraphicContent = ({
  isHeader,
  dataGraphic,
  names,
  isActiveLineChart,
}: {
  isHeader?: boolean;
  dataGraphic?: any;
  names: any;
  isActiveLineChart?: boolean;
}): JSX.Element => {
  return (
    <div className="graphic-content">
      <div className={`graphic-content_header ${!isHeader ? 'hidden' : ''}`}>
        <GraphicHeader />
      </div>
      {!isActiveLineChart ? (
        <ResponsiveContainer width="100%" height={303}>
          <BarChart
            width={1500}
            height={600}
            data={dataGraphic}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
            barCategoryGap="25%"
          >
            <CartesianGrid vertical={false} strokeDasharray="4 6" stroke="#CFD4DB" />
            <XAxis tickLine={false} axisLine={false} dataKey="date" stroke="#CFD4DB" />
            <YAxis tickLine={false} axisLine={false} stroke="#CFD4DB" />
            <Tooltip />
            {names.map(
              ({ field, title, color, isChecked }) =>
                isChecked && (
                  <Bar key={field} dataKey={field} name={title} stackId="a" fill={color} />
                )
            )}
            <Bar dataKey="amt" stackId="a" fill="#13B4E6" radius={[5, 5, 0, 0]} />
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <ResponsiveContainer width="100%" height={303}>
          <LineChart
            width={1500}
            height={600}
            data={dataGraphic}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis tickLine={false} axisLine={false} dataKey="date" stroke="#CFD4DB" />
            <YAxis tickLine={false} axisLine={false} stroke="#CFD4DB" />
            <Tooltip />
            {names.map(({ field, color, isChecked }) => {
              return (
                isChecked && (
                  <Line
                    type="monotone"
                    key={field}
                    dataKey={field}
                    stroke={color}
                    activeDot={{ r: 8 }}
                  />
                )
              );
            })}
          </LineChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default GraphicContent;
