import React from 'react';

export default ({ color = '#202430' }) => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M7.41925 8.66659L14.7526 1.33325"
        stroke="#202430"
        strokeWidth="1.6"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.41925 1.33325H14.7526V6.66658"
        stroke={color}
        strokeWidth="1.6"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.08592 2.6665H5.41925C3.21011 2.6665 1.41925 4.45737 1.41925 6.6665V10.6665C1.41925 12.8756 3.21011 14.6665 5.41925 14.6665H9.41925C11.6284 14.6665 13.4193 12.8756 13.4193 10.6665V9.99984"
        stroke={color}
        strokeWidth="1.6"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="16" height="16" fill="white" transform="translate(0.0859375)" />
      </clipPath>
    </defs>
  </svg>
);
