// @ts-nocheck
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  ContactSearchSource as CONTACT_SEARCH_SOURCE,
  ContactSearchSourceMap,
} from 'respona_api/generated/common_pb';

import {
  ContactAutomationSearchModes,
  ContactAutomationStateType,
} from '@ts/automationContacts.types';

import urls from '@constants/urls';
import loadingStatuses from '@constants/loadingStatuses';
import Button from '@uikit/Button/Button';
import Spinner from '@uikit/Spinner/Spinner';
import { buildLaunchContactAutomationApiRequest } from '@api/contactAutomation.api';

import { fetchBillingCredits } from '@redux/thunks/billings.thunks';
import {
  setCurrentContactAutomationType,
  updateCurrentContactAutomationData,
} from '@redux/actions/automationContact.actions';
import {
  contactAutomationSelector,
  currentContactAutomationStateSelector,
  currentContactAutomationTypeSelector,
} from '@redux/selectors/automationContact.selectors';
import { getActiveCampaignInfo } from '@redux/selectors/campaignSettings.selectors';
import { addNotification } from '@redux/actions/notifications.actions';

import SlideToggler from '@uikit/SlideToggler/SlideToggler';
import Loader from '@uikit/Loader/Loader';

import { DispatchType } from 'src/store';
// eslint-disable-next-line import/order
import AutomationPositionAndSeniority from './_components/AutomationPositionAndSeniority/AutomationPositionAndSeniority';
// eslint-disable-next-line import/order
import AutomationCardsInfo from '../AutomationCardsInfo/AutomationCardsInfo';

import './ContactAutomationSetup.scss';
import {
  billingDetailsSelector,
  billingMyRemainingCreditsSelector,
} from '@redux/selectors/billings.selectors';
import { SVGIcon } from '@uikit/Icon/Icon';
import { getOpportunitiesStatsApi } from '@api/campaign.api';
import { getContactAutomationPresets } from '@redux/thunks/automationContact.thunks';
import { useQuery } from 'react-query';
import Select from '@uikit/Select/Select';
import Input from '@uikit/Input/Input';
import QuestionTooltip from '@pages/CampaignCreationPage/_components/QuestionTooltip/QuestionTooltip';
import Display from '@components/Display/Display';
import { ContentAutomationSearchContext } from '@pages/OpportunitiesCampaignSettingsPage/OpportunitiesCampaignSettingsPage';
import { buildPipelineApiRequest, launchPipelineApi } from '@api/pipeline.api';
import { CampaignPipelineType } from 'respona_api/generated/pipeline_pb';
import useCampaignPipelines from '@hooks/useCampaignPipelines';

declare const IS_DEV: boolean;

function ContactAutomationSetup({
  pipelineId,
  setOpenModal,
  setPaymentSidebarOpen,
  setUpsellCreditsSidebarOpened,
  setUpsellCreditsNotEnough,
}: {
  pipelineId: number;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  setPaymentSidebarOpen: (boolean) => void;
  setUpsellCreditsSidebarOpened: (boolean) => void;
  setUpsellCreditsNotEnough: (number) => void;
}): JSX.Element {
  const history = useHistory();
  const dispatch: DispatchType = useDispatch();

  const [isAutomationStarting, changeIsAutomationStarting] = useState(false);

  const { settings, setSettings } = useContext(ContentAutomationSearchContext);
  const myRemainingCredits = useSelector(billingMyRemainingCreditsSelector);
  const { info: campaignInfo } = useSelector(getActiveCampaignInfo);
  const { data: billingDetails } = useSelector(billingDetailsSelector);
  const currentAutomationType = useSelector(currentContactAutomationTypeSelector);
  const currentAutomationState = useSelector(currentContactAutomationStateSelector);
  const { loadingStatus: campaignAutomationLoadingStatus } = useSelector(contactAutomationSelector);
  const { addItem: addPipelineToCache } = useCampaignPipelines(
    campaignInfo.workspaceId,
    campaignInfo.id
  );

  const [randomMultiplier, setRandomMultiplier] = useState<number>(null);

  const contactModeOptions = [
    {
      value: ContactAutomationSearchModes.BLOG_POST,
      label: 'Blogs',
    },
    {
      value: ContactAutomationSearchModes.NEWS,
      label: 'News',
    },
    {
      value: ContactAutomationSearchModes.DOMAINS,
      label: 'Homepages',
    },
    {
      value: ContactAutomationSearchModes.PODCAST,
      label: 'Podcasts',
    },
  ];

  if (IS_DEV) {
    contactModeOptions.push({
      value: ContactAutomationSearchModes.EXPERIMENTAL,
      label: '[Experimental]',
    });
  }

  useEffect(() => {
    if (currentAutomationState != null) {
      if (currentAutomationType === ContactAutomationSearchModes.NEWS) {
        if (currentAutomationState.assignNumber > 1) {
          handleContactAutomationState(1, 'assignNumber');
        }
      }
      if (currentAutomationType === ContactAutomationSearchModes.PODCAST) {
        if (currentAutomationState.assignNumber > 1) {
          handleContactAutomationState(1, 'assignNumber');
        }
      }
    }
  }, [currentAutomationType]);

  useEffect(() => {
    if (campaignInfo.id > 0) {
      if (campaignAutomationLoadingStatus !== loadingStatuses.LOADED) {
        dispatch(getContactAutomationPresets(campaignInfo.workspaceId, pipelineId));
      }
      if (randomMultiplier == null) {
        setRandomMultiplier(1.1 + Math.random() * 0.2);
      }
    }
  }, [campaignInfo, campaignAutomationLoadingStatus]);

  const { data: dataOpportunitiesStats, isLoading: isLoadingOpportunitiesStats } = useQuery(
    [
      'opportunities-stats-counter',
      campaignInfo.id,
      campaignInfo.workspaceId,
      campaignInfo.lastBatch,
    ],
    () =>
      getOpportunitiesStatsApi(campaignInfo.id, campaignInfo.workspaceId, campaignInfo.lastBatch),
    {
      enabled: !!campaignInfo.id,
      refetchOnWindowFocus: false,
    }
  );

  const unassignedOpportunitiesCount = () => {
    if (isLoadingOpportunitiesStats) {
      return 0;
    }
    return dataOpportunitiesStats.withoutContacts;
  };

  const handleRunAutomationClick = (autobuy = false) => {
    if (myRemainingCredits < 10) {
      if (billingDetails.state === 6) {
        setPaymentSidebarOpen(true);
      } else {
        setUpsellCreditsSidebarOpened(true);
        setUpsellCreditsNotEnough(getCardsInfo().secondValue);
      }
      return;
    }

    const requestData = {
      ...currentAutomationState,
      assignNumber:
        currentAutomationType === ContactAutomationSearchModes.BLOG_POST ||
        currentAutomationType === ContactAutomationSearchModes.DOMAINS
          ? currentAutomationState.assignNumber
          : 1,
      positionsList: currentAutomationState.positionsList,
      senioritiesList: currentAutomationState.senioritiesList,
    };

    changeIsAutomationStarting(true);

    launchPipelineApi(
      buildPipelineApiRequest(
        -1,
        campaignInfo.workspaceId,
        campaignInfo.id,
        CampaignPipelineType.CONTACT_AUTOMATION,
        false,
        -1,
        -1,
        true,
        false,
        false,
        buildLaunchContactAutomationApiRequest(
          campaignInfo.id,
          campaignInfo.workspaceId,
          campaignInfo.lastBatch,
          requestData,
          autobuy,
          currentAutomationState.source as ContactSearchSourceMap[keyof ContactSearchSourceMap]
        )
      )
    )
      .then((response) => {
        addPipelineToCache(response);
        dispatch(fetchBillingCredits());
        setOpenModal(false);
      })
      .catch((error) => {
        console.error(error);
        // TODO when codes and JSON from back will be ready -> need to change to condition by code
        if (error.message.includes('All opportunities has contacts')) {
          dispatch(
            addNotification({ title: 'All opportunities already have contacts', type: 'warning' })
          );
          return;
        }

        if (error.message.includes('Exception while auto-buy')) {
          dispatch(
            addNotification({
              title:
                'There was a problem with processing this transaction, please update your billing info',
              type: 'warning',
              delay: 5000,
            })
          );
          history.push(urls.CHANGE_BILLING);
        }

        if (error.message.includes('credits')) {
          if (billingDetails.state === 6) {
            setPaymentSidebarOpen(true);
          } else {
            setUpsellCreditsSidebarOpened(true);
            setUpsellCreditsNotEnough(getCardsInfo().secondValue);
          }
        }
      });
  };

  const handleSettingsState = (value: string | number | boolean, field: string): void => {
    setSettings((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const createFieldChangeHandler = (fieldName) => (value, additionalProps) => {
    if (fieldName === 'notify') {
      handleSettingsState(value, fieldName);
    }
    dispatch(
      updateCurrentContactAutomationData({
        [fieldName]: value,
        ...additionalProps,
      } as ContactAutomationStateType)
    );
  };

  const getCardsInfo = () => {
    const opportunitiesWithoutContactsNumber = unassignedOpportunitiesCount();
    if (opportunitiesWithoutContactsNumber == null) {
      return {
        secondValue: 0,
      };
    }
    const assignNumber =
      currentAutomationType === ContactAutomationSearchModes.BLOG_POST ||
      currentAutomationType === ContactAutomationSearchModes.DOMAINS
        ? currentAutomationState.assignNumber
        : 1;

    const contactsPerOpportunityNumber = opportunitiesWithoutContactsNumber * assignNumber;

    return {
      firstTitle: 'Unassigned opportunities',
      firstValue: opportunitiesWithoutContactsNumber,
      secondTitle:
        currentAutomationState.source !== CONTACT_SEARCH_SOURCE.RESPONA_SEARCH_SOURCE
          ? 'Estimated searches'
          : 'Reserved credits',
      secondValue:
        currentAutomationType === ContactAutomationSearchModes.EXPERIMENTAL
          ? opportunitiesWithoutContactsNumber + contactsPerOpportunityNumber * 50
          : currentAutomationState.source !== CONTACT_SEARCH_SOURCE.RESPONA_SEARCH_SOURCE
            ? opportunitiesWithoutContactsNumber + contactsPerOpportunityNumber
            : opportunitiesWithoutContactsNumber + contactsPerOpportunityNumber * 5,
      thirdTitle: 'Time (mins)',
      thirdValue: Math.ceil(contactsPerOpportunityNumber * randomMultiplier),
    };
  };

  const handleContactAutomationState = (value: string | number | boolean | Date, fieldName) => {
    dispatch(
      updateCurrentContactAutomationData({
        [fieldName]: value,
      } as ContactAutomationStateType)
    );
  };

  const getSearchModeValue = (value: number, options: { value: number; label: string }[]) => {
    return options.find((option) => option.value === value);
  };

  function skipCatchAllText() {
    if (currentAutomationState == null) {
      return '';
    }
    if (currentAutomationState.skipCatchAllEmails)
      return 'Skipping domains with accept-all policies for email verification can improve deliverability.';
    return 'Using catch-all emails significantly increases the bounce rate. We recommend turning this toggle on to maximize deliverability.';
  }

  return (
    <div className="campaign-automation">
      <Loader isLoading={campaignAutomationLoadingStatus !== loadingStatuses.LOADED}>
        {/* SEARCH SOURCE */}
        {/* <Display isVisible={isNotLaunched}> */}
        {/*  <div className="campaign-automation__settings-title">Select contact source:</div> */}
        {/*  <AutomationSourceSelect */}
        {/*    integrations={integrations} */}
        {/*    setSelectedSource={createFieldChangeHandler('source')} */}
        {/*    selectedSource={currentAutomationState.source} */}
        {/*  /> */}
        {/* </Display> */}

        {/* SEARCH MODE */}
        <div className="campaign-automation-content-body">
          <ul className="campaign-automation-content-body__list">
            <li className="campaign-automation-content-body__list-item">
              <div className="campaign-automation-content-body__list-item-label">
                Opportunity type:
              </div>
              <Select
                options={contactModeOptions}
                value={getSearchModeValue(currentAutomationType, contactModeOptions)}
                onChange={({ value }) => dispatch(setCurrentContactAutomationType(value))}
              />
            </li>
            {currentAutomationType < 3 && (
              <li className="campaign-automation-content-body__list-item">
                <div className="campaign-automation-content-body__list-item-label">
                  Find up to
                  <Input
                    value={currentAutomationState.assignNumber}
                    type="number"
                    onChange={(event) => {
                      const value = Number(event.target.value.trim());
                      if (value > 0) {
                        handleContactAutomationState(value, 'assignNumber');
                      }
                    }}
                  />{' '}
                  contacts per website.
                </div>
              </li>
            )}
          </ul>
        </div>

        {/* POSITION AND SENIORITY */}
        {currentAutomationType < 3 && (
          <AutomationPositionAndSeniority
            isRequiredPositions={currentAutomationState.requiredPositions}
            onChangeRequiredPositions={createFieldChangeHandler('requiredPositions')}
            positionsList={currentAutomationState.positionsList}
            positionSuggestions={currentAutomationState.positionSuggestions}
            senioritiesList={currentAutomationState.senioritiesList}
            senioritySuggestions={currentAutomationState.senioritySuggestions}
            onChangePositionsList={createFieldChangeHandler('positionsList')}
            onChangeSenioritiesList={createFieldChangeHandler('senioritiesList')}
            selectedSource={currentAutomationState.source}
          />
        )}

        {/* ADVANCED SETTINGS */}

        <div className="automation-advanced-settings-content">
          <Display isVisible={currentAutomationType !== ContactAutomationSearchModes.PODCAST}>
            <div className="automation-advanced-settings-content__row">
              <SlideToggler
                value={currentAutomationState.skipCatchAllEmails}
                onChange={createFieldChangeHandler('skipCatchAllEmails')}
              />

              <div className="automation-advanced-settings-content__label">
                Skip catch-all domains{' '}
                <QuestionTooltip
                  place="top"
                  color="#C6C6C6"
                  style={{ marginLeft: '6px', marginTop: '1px' }}
                  icon={
                    currentAutomationState.skipCatchAllEmails
                      ? 'questionInCircleFilled'
                      : 'warningTriangle'
                  }
                  text={skipCatchAllText()}
                />
              </div>
            </div>
          </Display>
          <div className="automation-advanced-settings-content__row">
            <SlideToggler
              value={currentAutomationState.assignGenericEmails}
              onChange={createFieldChangeHandler('assignGenericEmails')}
            />
            <div className="automation-advanced-settings-content__label">
              Use generic emails as needed{' '}
              <QuestionTooltip
                place="top"
                color="#C6C6C6"
                style={{ marginLeft: '6px', marginTop: '1px' }}
                text="If Resopna is unable to find a suitable match, we use a generic email as a last
              resort."
              />
            </div>
          </div>
        </div>

        <Loader isLoading={isLoadingOpportunitiesStats}>
          {/* ADDITIONAL INFO IN CARDS */}
          <AutomationCardsInfo {...getCardsInfo()} />
        </Loader>

        {/* NOTIFY TOGGLER AND RUN BUTTON */}
        <div className="campaign-automation-footer">
          <div className="campaign-automation-footer__notify">
            <SlideToggler value={settings.notify} onChange={createFieldChangeHandler('notify')} />
            <div className="campaign-automation-footer__notify-title">
              Email me when the search is complete
            </div>
          </div>

          {isAutomationStarting ? (
            <Button disabled className="campaign-automation-footer__button">
              <Spinner marginRight={10} color="#fff" />
              <span>Launching</span>
            </Button>
          ) : (
            <Button
              onClick={handleRunAutomationClick}
              className="campaign-automation-footer__button"
              disabled={!getCardsInfo().firstValue}
            >
              <span className="campaign-automation-footer__play-icon">
                <SVGIcon icon="playBig" />
              </span>
              <span>Run automation</span>
            </Button>
          )}
        </div>
      </Loader>
    </div>
  );
}

export default ContactAutomationSetup;
