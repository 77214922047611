import React, { useEffect, useState } from 'react';
import cn from 'class-names';

import Input from '@uikit/Input/Input';

import { SVGIcon } from 'src/uikit/Icon/Icon';
import Display from 'src/components/Display/Display';

import './Pagination.scss';

type PaginationPropsType = {
  curPage: number;
  isUnknownNexBehaviour?: boolean;
  isNextDisabled?: boolean;
  withLimitOptions?: boolean;
  onChangePage: (number) => void;
  pageLimit?: number;
  limitOptions?: number[];
  onChangePageLimit?: (number) => void;
  totalItems?: number;
  maxKnownPage?: number;
  pageSelectAllowed?: boolean;
};

const getUnknownPagesList = (curPage) => {
  if (curPage > 3) {
    return [1, '...', curPage];
  }

  const result = [];

  for (let i = 1; i <= curPage; i++) {
    result.push(i);
  }

  return result;
};

const getRestPages = (curPage, lastPage) => {
  if (lastPage - curPage === 0 || lastPage < curPage) {
    return [];
  }

  if (lastPage - curPage === 1) {
    return [lastPage];
  }

  return ['...', lastPage];
};

const PageButton = ({ curPage, pageValue, onClick }) => {
  const getCnForPageBtn = (page) =>
    cn('pagination__button pagination__button--page-btn', {
      'pagination__button--active': page === curPage,
      'pagination__button--not-button': !Number.isInteger(page),
    });

  const handlePageClick = () => {
    if (Number.isInteger(pageValue)) {
      onClick(pageValue);
    }
  };

  return (
    <button type="button" className={getCnForPageBtn(pageValue)} onClick={handlePageClick}>
      {pageValue}
    </button>
  );
};

const UnknownNextPagination = ({ curPage, onChangePage, isNextDisabled, maxKnownPage }) => {
  const cnPrevPageBtn = cn('pagination__button pagination__button--edge-btn', {
    'pagination__button--disabled': curPage === 1,
  });

  const cnNextPageBtn = cn('pagination__button pagination__button--edge-btn', {
    'pagination__button--disabled': isNextDisabled,
  });

  const handleNextClick = () => {
    if (!isNextDisabled) {
      onChangePage(curPage + 1);
    }
  };
  const handlePrevClick = () => {
    if (curPage > 1) {
      onChangePage(curPage - 1);
    }
  };
  const handlePageClick = (page) => onChangePage(page);

  return (
    <div className="pagination-wrapper">
      <div className="pagination">
        <button onClick={handlePrevClick} type="button" className={cnPrevPageBtn}>
          <SVGIcon icon="prevPage" />
        </button>
        {getUnknownPagesList(curPage).map((page) => (
          <PageButton key={page} curPage={curPage} pageValue={page} onClick={handlePageClick} />
        ))}
        {getRestPages(curPage, maxKnownPage).map((page) => (
          <PageButton key={page} curPage={curPage} pageValue={page} onClick={handlePageClick} />
        ))}
        <button onClick={handleNextClick} type="button" className={cnNextPageBtn}>
          <SVGIcon icon="nextPage" />
        </button>
      </div>
    </div>
  );
};

const FullPagination = ({
  curPage,
  onChangePage,
  pageLimit,
  totalItems,
  withLimitOptions,
  isUnknownNexBehaviour = false,
  isNextDisabled = false,
  pageSelectAllowed = true,
}) => {
  const [jumpToPage, setJumpToPage] = useState<number>(curPage || 0);

  useEffect(() => {
    setJumpToPage(curPage);
  }, [curPage]);

  const handlePageClick = (page) => onChangePage(page);

  const lastPage = isUnknownNexBehaviour ? 10 : Math.ceil(totalItems / pageLimit);

  const prevBtnDisabled = curPage <= 1;
  const nextBtnDisabled = isNextDisabled || curPage === lastPage;

  return (
    <div className="pagination-wrapper">
      <Display
        isVisible={
          !withLimitOptions || (withLimitOptions && (curPage !== 1 || totalItems > pageLimit))
        }
      >
        <div className="pagination-full-width">
          <div className="pagination__switch-buttons">
            <button
              onClick={() => (prevBtnDisabled ? null : handlePageClick(1))}
              type="button"
              className={cn('pagination__button pagination__button--edge-btn', {
                'pagination__button--disabled': curPage === 1,
              })}
            >
              <SVGIcon icon="prevPage" />
            </button>

            <div
              className={cn('pagination__switch-buttons-button', {
                'pagination__switch-buttons-button--disabled': prevBtnDisabled,
              })}
              onClick={() =>
                prevBtnDisabled ? null : handlePageClick(Math.max(Number(curPage) - 1, 1))
              }
            >
              Prev
            </div>
            <div
              className={cn('pagination__switch-buttons-button', {
                'pagination__switch-buttons-button--disabled': nextBtnDisabled,
              })}
              onClick={() =>
                nextBtnDisabled ? null : handlePageClick(Math.min(Number(curPage) + 1, lastPage))
              }
            >
              Next
            </div>
          </div>

          <div className="pagination__select">
            <span>Page:</span>

            <Input
              disabled={isUnknownNexBehaviour || !pageSelectAllowed}
              type="number"
              min={1}
              max={pageLimit || 10}
              value={jumpToPage}
              onChange={({ target: { value } }) => {
                if (value >= lastPage) {
                  setJumpToPage(lastPage);
                } else if (!!value && value <= 1) {
                  setJumpToPage(1);
                } else {
                  setJumpToPage(value);
                }
              }}
              onKeyDown={(e) => (e.keyCode === 13 ? handlePageClick(jumpToPage) : null)}
              placeholder=""
            />

            {!isUnknownNexBehaviour && pageSelectAllowed && (
              <span>
                of{' '}
                <span
                  className="pagination__select-last-page"
                  onClick={() => handlePageClick(lastPage)}
                >
                  {lastPage}
                </span>
              </span>
            )}
          </div>
        </div>
      </Display>
    </div>
  );
};

const Pagination = ({
  curPage,
  isUnknownNexBehaviour,
  onChangePage,
  isNextDisabled = false,
  pageLimit,
  limitOptions,
  onChangePageLimit,
  totalItems = 0,
  withLimitOptions = true,
  maxKnownPage = 0,
  pageSelectAllowed = true,
}: PaginationPropsType) => {
  // if (isUnknownNexBehaviour) {
  //   return (
  //     <UnknownNextPagination
  //       isNextDisabled={isNextDisabled}
  //       curPage={curPage}
  //       onChangePage={onChangePage}
  //       maxKnownPage={maxKnownPage}
  //     />
  //   );
  // }

  return (
    <FullPagination
      totalItems={totalItems}
      pageLimit={pageLimit}
      curPage={curPage}
      onChangePage={onChangePage}
      withLimitOptions={withLimitOptions}
      isUnknownNexBehaviour={isUnknownNexBehaviour}
      isNextDisabled={isNextDisabled}
      pageSelectAllowed={pageSelectAllowed}
    />
  );
};

export default Pagination;
