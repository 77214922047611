import { createAction } from 'redux-actions';

import {
  AhrefMetricsType,
  AutomationSearchSubType,
  KeywordsSearchType,
  ListennotesMetricsType,
  SearchModeType,
  SemrushMetricsType,
} from '@ts/automationContent.types';

export const setSearchMode = createAction<SearchModeType>('SET_SEARCH_MODE');
export const setAutomationSubType =
  createAction<AutomationSearchSubType>('SET_AUTOMATION_SUB_TYPE');

export const setMetricsType = createAction<SearchModeType>('SET_METRICS_TYPE');

export const setGoogleSearchConfig = createAction<KeywordsSearchType>('SET_GOOGLE_SEARCH_CONFIG');
export const setGoogleNewsConfig = createAction<KeywordsSearchType>('SET_GOOGLE_NEWS_CONFIG');
export const setBingSearchConfig = createAction<KeywordsSearchType>('SET_BING_SEARCH_CONFIG');
export const setBingNewsConfig = createAction<KeywordsSearchType>('SET_BING_NEWS_CONFIG');
export const setPodcastsConfig = createAction<KeywordsSearchType>('SET_PODCASTS_CONFIG');
export const setEpisodesConfig = createAction<KeywordsSearchType>('SET_EPISODES_CONFIG');
export const setAhrefsBacklinksConfig = createAction<KeywordsSearchType>(
  'SET_AHREFS_BACKLINKS_CONFIG'
);
export const setSemrushBacklinksConfig = createAction<KeywordsSearchType>(
  'SET_SEMRUSH_BACKLINKS_CONFIG'
);
export const setMozBacklinksConfig = createAction<KeywordsSearchType>('SET_MOZ_BACKLINKS_CONFIG');
export const setResponaBacklinksConfig = createAction<KeywordsSearchType>('SET_RESPONA_CONFIG');

export const setAhrefsMetrics = createAction<AhrefMetricsType>('SET_AHREFS_METRICS');
export const setSemrushMetrics = createAction<SemrushMetricsType>('SET_SEMRUSH_METRICS');
export const setMozMetrics = createAction<SemrushMetricsType>('SET_MOZ_METRICS');
export const setListennotesMetrics =
  createAction<ListennotesMetricsType>('SET_LISTENNOTES_METRICS');

export const setContentAutomationStream = createAction<any>('SET_CONTENT_AUTOMATION_STREAM');

export const setNotifyMeContentAutomation = createAction<boolean>(
  'SET_NOTIFY_ME_CONTENT_AUTOMATION'
);

export const resetContentAutomationConfigState = createAction<void>(
  'RESET_CONTENT_AUTOMATION_CONFIG_STATE'
);

export const resetContentAutomationState = createAction<void>('RESET_CONTENT_AUTOMATION_STATE');
