import React from 'react';

export default ({ color = '#C4C6CD' }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.3333 1.66699C8.68516 1.66699 7.074 2.15573 5.70359 3.07141C4.33318 3.98709 3.26507 5.28858 2.63434 6.8113C2.00361 8.33401 1.83858 10.0096 2.16013 11.6261C2.48167 13.2426 3.27534 14.7274 4.44078 15.8929C5.60622 17.0583 7.09108 17.852 8.70759 18.1735C10.3241 18.4951 11.9997 18.3301 13.5224 17.6993C15.0451 17.0686 16.3466 16.0005 17.2623 14.6301C18.1779 13.2597 18.6667 11.6485 18.6667 10.0003C18.6667 8.90598 18.4511 7.82234 18.0323 6.8113C17.6135 5.80025 16.9997 4.88159 16.2259 4.10777C15.4521 3.33395 14.5334 2.72012 13.5224 2.30133C12.5113 1.88254 11.4277 1.66699 10.3333 1.66699ZM10.3333 16.667C9.0148 16.667 7.72586 16.276 6.62954 15.5435C5.53321 14.8109 4.67872 13.7697 4.17414 12.5515C3.66956 11.3334 3.53753 9.99293 3.79477 8.69972C4.052 7.40652 4.68694 6.21863 5.61929 5.28628C6.55164 4.35393 7.73953 3.71899 9.03274 3.46176C10.3259 3.20452 11.6664 3.33654 12.8846 3.84113C14.1027 4.34571 15.1439 5.2002 15.8765 6.29652C16.609 7.39285 17 8.68178 17 10.0003C17 11.7684 16.2976 13.4641 15.0474 14.7144C13.7971 15.9646 12.1014 16.667 10.3333 16.667ZM11.1667 9.94199V5.83366C11.1667 5.61265 11.0789 5.40068 10.9226 5.2444C10.7663 5.08812 10.5544 5.00033 10.3333 5.00033C10.1123 5.00033 9.90036 5.08812 9.74408 5.2444C9.5878 5.40068 9.5 5.61265 9.5 5.83366V10.0003C9.5 10.0003 9.5 10.0587 9.5 10.092C9.4795 10.2614 9.5115 10.433 9.59167 10.5837L10.8417 12.7503C10.9522 12.9426 11.1345 13.0831 11.3486 13.1409C11.5628 13.1988 11.7911 13.1692 11.9833 13.0587C12.1756 12.9482 12.3161 12.7658 12.374 12.5517C12.4318 12.3376 12.4022 12.1093 12.2917 11.917L11.1667 9.94199Z"
      fill={color}
    />
  </svg>
);
