import React, { useMemo, useCallback, useRef, useEffect, useState } from 'react';

import cn from 'class-names';
import { Transforms, createEditor, Descendant } from 'slate';
import { withHistory } from 'slate-history';
import { Slate, Editable, ReactEditor, withReact } from 'slate-react';
import { useDispatch } from 'react-redux';

import withEditorVariables from '@hocs/withEditorVariables';
import withEditorLinks from '@hocs/withEditorLinks';
import Display from '@components/Display/Display';

import MultiSwitch from '@uikit/MultiSwitch/MultiSwitch';
import isHTML from '@utils/isHTML';
import { addNotification } from '@redux/actions/notifications.actions';

import EditorChangeLink from './_components/EditorChangeLink/EditorChangeLink';
import { Element, Leaf, MarkButton, BlockButton, LinkButton } from './SlateComponents';

import { initialValue } from './slateUtils';

import { patchSlateElementByPath } from './utils/stateUtils';
import { serialize } from './utils/serialize';
import { prepareMessageValueForEditor } from './utils/prepareMessageValueForEditor';

import './SlateEditor.scss';

export const signatureTypes = {
  'plain text': 'plain text',
  'HTML Code': 'HTML Code',
};

export type SignatureType = typeof signatureTypes[keyof typeof signatureTypes];

type RichEditorPropsType = {
  message: string;
  onChange?: (string) => void;
  disabled?: boolean;
  defaultValue?: Descendant[];
  signatureType: SignatureType;
  onChangeSignatureType: (type: SignatureType) => void;
};

const SignatureEditor = ({
  message,
  onChange,
  disabled = false,
  defaultValue,
  signatureType,
  onChangeSignatureType,
}: RichEditorPropsType): JSX.Element => {
  const dispatch = useDispatch();
  const editorContainerRef = useRef<HTMLDivElement | null>();
  const [value, setValue] = useState<Descendant[]>(initialValue);
  const [changingLink, setChangingLink] = useState(null);
  const [wasChanged, changeWasChanged] = useState(false);
  const [isDisabledLinkButton, setDisabledLinkButton] = useState(true);

  const editor = useMemo(
    () =>
      withHistory(withEditorLinks(withEditorVariables(withReact(createEditor() as ReactEditor)))),
    []
  );
  const renderElement = useCallback(
    (props) => (
      <Element
        {...props}
        transformLinkUrl={(url: string): string => url}
        onChangeLink={setChangingLink}
        disableVariable
        editor={editor}
      />
    ),
    [editor]
  );

  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  const handleChangeHref = useCallback(
    (newHref, path) => {
      Transforms.deselect(editor);

      setValue((prevValue) => patchSlateElementByPath(prevValue, path, { url: newHref }));
    },
    [value]
  );

  const handleSlateContainerClick = () => ReactEditor.focus(editor);

  useEffect(() => {
    if (signatureType === signatureTypes['plain text']) {
      setValue(prepareMessageValueForEditor(message));
    }
  }, [message, signatureType]);

  useEffect(() => {
    if (wasChanged) {
      onChange(serialize(value));
    }
  }, [value]);

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  const cnSignatureControls = cn('signature-editor__controls', {
    'signature-editor__controls--disabled': signatureType === signatureTypes['HTML Code'],
  });

  editor.children = value;

  return (
    <>
      <div ref={editorContainerRef} className="signature-editor">
        {signatureType === signatureTypes['plain text'] ? (
          <Slate
            editor={editor}
            value={value}
            onChange={(currentValue) => {
              changeWasChanged(true);

              setValue(currentValue);
            }}
          >
            <div
              className="signature-editor__editor"
              onClick={handleSlateContainerClick}
              tabIndex={0}
              role="button"
            >
              <Display isVisible={changingLink !== null}>
                <EditorChangeLink
                  onChangeHref={handleChangeHref}
                  linkInfo={changingLink}
                  containerRef={editorContainerRef}
                  editor={editor}
                  onClose={() => setChangingLink(null)}
                  transformLinkUrl={(url: string): string => url}
                  disableVarsInLink
                  useApplyButton={false}
                />
              </Display>
              <Editable
                readOnly={disabled}
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                placeholder="Insert plain-text signature …"
                onPaste={(evt) => {
                  const text = evt.clipboardData.getData('text');
                  if (isHTML(text)) {
                    dispatch(
                      addNotification({
                        title: "Please use 'HTML CODE' mode for pasting html",
                        type: 'info',
                      })
                    );
                    evt.preventDefault();
                    evt.stopPropagation();
                  }
                }}
                onSelect={() => {
                  setDisabledLinkButton(
                    editor.selection?.anchor?.offset === editor.selection?.focus?.offset
                  );
                }}
              />
            </div>
          </Slate>
        ) : (
          <textarea
            className="signature-editor__signature-textarea-editor"
            onChange={({ target: { value: textareaValue } }) => onChange(textareaValue)}
            placeholder="Insert HTML code …"
            value={message}
          />
        )}

        <div className={cnSignatureControls}>
          <MarkButton format="bold" icon="boldText" editorRef={editor} />
          <MarkButton format="italic" icon="italicText" editorRef={editor} />
          <MarkButton format="underline" icon="underlineText" editorRef={editor} />
          <div className="signature-editor__controls-separator" />
          <BlockButton format="numbered-list" icon="orderedList" editorRef={editor} />
          <BlockButton format="bulleted-list" icon="unorderedList" editorRef={editor} />
          <div className="signature-editor__controls-separator" />
          <LinkButton
            isDisabled={isDisabledLinkButton}
            onChangeLink={setChangingLink}
            editorRef={editor}
          />
          <div className="signature-editor__controls-right">
            <MultiSwitch
              classNames={{
                container: 'signature-editor__controls-switch-container',
                option: 'signature-editor__controls-switch-option',
                optionActive: 'signature-editor__controls-switch-option--active',
              }}
              options={Object.keys(signatureTypes)}
              value={signatureType}
              onChange={onChangeSignatureType}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SignatureEditor;
