import Display from '@components/Display/Display';
import React from 'react';

import From from '@components/Inbox/InboxWidget/_components/MetaInfo/_components/From';
import EmailsRow from '@components/Inbox/InboxWidget/_components/MetaInfo/_components/EmailsRow';
import CcBcc from '@components/Inbox/InboxWidget/_components/MetaInfo/_components/CcBcc';

import './MetaInfo.scss';

export type MetaInfoStep = {
  emailsToBccList: string[];
  emailsToCcList: string[];
};

type MetaInfoPropsType = {
  onUpdateMeta: (MetaInfoStep) => void;
  step: MetaInfoStep;
  contactEmail: string;
  onAddContact: (contactEmail: string) => void;
  onRemoveContact: (contactEmail: string) => void;
  senderEmails: string[];
  isSenderEmailLoading: boolean;
  selectedSenderEmail: string;
  onChangeSenderEmail?: (email: string) => void;
  isReply?: boolean;
  subject?: string;
  onAddSubject?: () => void;
};

const MetaInfo = ({
  onUpdateMeta,
  step,
  contactEmail,
  onAddContact,
  onRemoveContact,
  senderEmails,
  isSenderEmailLoading,
  selectedSenderEmail,
  onChangeSenderEmail,
  isReply = false,
  subject,
  onAddSubject,
}: MetaInfoPropsType): JSX.Element => {
  return (
    <div className="meta-info">
      <From
        selectedSenderEmail={selectedSenderEmail}
        senderEmails={senderEmails}
        onChangeSenderEmail={onChangeSenderEmail}
        isSenderEmailLoading={isSenderEmailLoading}
      />
      <EmailsRow
        label="To"
        contactEmail={contactEmail}
        onAdd={onAddContact}
        onRemove={onRemoveContact}
        // disabled={isReply}
        disabled={false}
      />
      <CcBcc onUpdateMeta={onUpdateMeta} step={step}>
        <Display isVisible={isReply && !subject?.length}>
          <div
            className="meta-info__adding-button"
            onClick={onAddSubject}
            tabIndex={0}
            role="button"
          >
            + Subject
          </div>
        </Display>
      </CcBcc>
    </div>
  );
};

export default MetaInfo;
