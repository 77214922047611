export default (func, wait) => {
  let timeout;

  return function executedFunction(...args): number {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    return timeout;
  };
};
