import { SVGIcon } from '@uikit/Icon/Icon';
import { components } from 'react-select';
import { fieldTypeToIconMap } from '@components/Settings/CustomFields/customFieldsTableConfig';
import React from 'react';

const Control = ({ children, ...restProps }) => {
  return (
    <components.Control {...restProps}>
      <div className="custom-fields-sidebar__type-select">
        <div className="custom-fields-sidebar__type-select-icon">
          <SVGIcon
            icon={fieldTypeToIconMap[restProps.selectProps?.value?.value]}
            size={restProps.selectProps?.value?.iconSize}
            color="#8F9199"
          />
        </div>
        {children}
      </div>
    </components.Control>
  );
};

export default Control;
