import React, { useCallback, useEffect, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { format } from 'date-fns';

import { getDateShort } from '@utils/date';

import SidebarCRMSectionEditableField from '@components/SidebarCRM/sections/SidebarCRMSection/_components/SidebarCRMSectionEditableField/SidebarCRMSectionEditableField';
import SidebarCRMSectionSelectionField from '@components/SidebarCRM/sections/SidebarCRMSection/_components/SidebarCRMSectionSelectionField/SidebarCRMSectionSelectionField';

import SidebarCRMSectionWebsite from '@components/SidebarCRM/sections/SidebarCRMSectionWebsite/SidebarCRMSectionWebsite';
import SidebarCRMSectionContact from '@components/SidebarCRM/sections/SidebarCRMSectionContact/SidebarCRMSectionContact';
import SidebarCRMSectionTags from '@components/SidebarCRM/sections/SidebarCRMSectionTags/SidebarCRMSectionTags';
import SidebarCRMSectionEmails from '@components/SidebarCRM/sections/SidebarCRMSectionEmails/SidebarCRMSectionEmails';
import SidebarCRMSectionCampaigns from '@components/SidebarCRM/sections/SidebarCRMSectionCampaigns/SidebarCRMSectionCampaigns';
import SidebarCRMSectionNotes from '@components/SidebarCRM/sections/SidebarCRMSectionNotes/SidebarCRMSectionNotes';
import SidebarCRMSectionMetrics from '@components/SidebarCRM/sections/SidebarCRMSectionMetrics/SidebarCRMSectionMetrics';
import SidebarCRMSectionVariables from '@components/SidebarCRM/sections/SidebarCRMSectionVariables/SidebarCRMSectionVariables';
import SidebarCRMSectionOpportunities from '@components/SidebarCRM/sections/SidebarCRMSectionOpportunities/SidebarCRMSectionOpportunities';

import './SidebarCRMContent.scss';

declare const IS_DEV: boolean;

export const SECTION_TYPE = {
  WEBSITE: 'website',
  CONTACTS: 'contacts',
  TAGS: 'tags',
  EMAILS: 'emails',
  CAMPAIGNS: 'campaigns',
  NOTES: 'notes',
  METRICS: 'metrics',
  VARIABLES: 'variables',
  OPPORTUNITIES: 'opportunities',
};

const sectionsMap = {
  [SECTION_TYPE.WEBSITE]: SidebarCRMSectionWebsite,
  [SECTION_TYPE.CONTACTS]: SidebarCRMSectionContact,
  [SECTION_TYPE.TAGS]: SidebarCRMSectionTags,
  [SECTION_TYPE.EMAILS]: SidebarCRMSectionEmails,
  [SECTION_TYPE.CAMPAIGNS]: SidebarCRMSectionCampaigns,
  [SECTION_TYPE.NOTES]: SidebarCRMSectionNotes,
  [SECTION_TYPE.METRICS]: SidebarCRMSectionMetrics,
  [SECTION_TYPE.VARIABLES]: SidebarCRMSectionVariables,
};

if (IS_DEV) {
  // @ts-ignore
  sectionsMap[SECTION_TYPE.OPPORTUNITIES] = SidebarCRMSectionOpportunities;
}

function SidebarCRMContent({ type, itemId, rows, sectionsMeta }): JSX.Element {
  const [sections, setSections] = useState<string[]>([]);
  const storageSectionName = `${type}SectionsOrder`;

  const handleDragDrop = useCallback((res: any) => {
    const { source, destination } = res;

    if (!destination || destination.index === source.index) {
      return;
    }

    setSections((state) => {
      const newState = [...state];
      newState.splice(source.index, 1);
      newState.splice(destination.index, 0, state[source.index]);

      localStorage.setItem(storageSectionName, JSON.stringify(newState));
      return newState;
    });
  }, []);

  useEffect(() => {
    const sectionOrder = localStorage.getItem(storageSectionName);
    try {
      const sectionOrderParsed = JSON.parse(sectionOrder);
      if (sectionOrderParsed && sectionOrderParsed.length === Object.keys(sectionsMeta).length) {
        setSections(sectionOrderParsed);
      } else {
        localStorage.removeItem(storageSectionName);
        setSections(Object.keys(sectionsMeta));
      }
    } catch {
      localStorage.removeItem(storageSectionName);
      setSections(Object.keys(sectionsMeta));
    }
  }, []);

  const transformCreatedAt = (createdAt: number): string => {
    return `${getDateShort(new Date(createdAt), true, true)} at ${format(
      new Date(createdAt),
      'hh:mm aaa'
    )}`;
  };

  return (
    <div className="sidebar-crm__content">
      <div className="sidebar-crm__content-header">
        {rows.map((row) => {
          let { value } = row;

          if (row.transformCreatedAt) {
            value = transformCreatedAt(value);
          }

          return (
            <div key={row.key}>
              <div className="sidebar-crm__content-personal-info-label">{row.title}</div>
              <div className="sidebar-crm__content-personal-info-data-row">
                {row.isEditable
                  ? (() => {
                      switch (row.type) {
                        case 'select':
                          return (
                            <SidebarCRMSectionSelectionField
                              icon={row.icon}
                              initialValue={value}
                              options={row?.options || []}
                              onChange={(oldValue: any, newValue: any) => {
                                row.onChange && row.onChange(newValue);
                              }}
                            />
                          );
                        case 'text':
                        default:
                          return (
                            <SidebarCRMSectionEditableField
                              initialValue={value}
                              onSave={(oldValue: string, newValue: string) => {
                                row.onChange && row.onChange(newValue, oldValue);
                              }}
                              renderContent={(val) => val || '-'}
                            />
                          );
                      }
                    })()
                  : value}
              </div>
            </div>
          );
        })}
      </div>
      <DragDropContext onDragEnd={handleDragDrop}>
        <Droppable droppableId={`${type}-sidebar`} direction="vertical">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {sections.map((key, index) => {
                const sectionProps = sectionsMeta[key];
                const Section = sectionsMap[key];
                if (!!sectionProps && !!Section) {
                  return <Section key={key} index={index} itemId={itemId} {...sectionProps} />;
                }

                return <div />;
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

export default SidebarCRMContent;
