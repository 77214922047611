import {
  BillingSubscriptionCycle,
  BillingSubscriptionCycleMap,
  BillingSubscriptionType,
  BillingSubscriptionTypeMap,
} from 'respona_api/generated/billing_pb';

export const subscriptionLimits = {
  [BillingSubscriptionType.STARTER]: {
    [BillingSubscriptionCycle.YEARLY]: {
      minCredits: 120000,
      maxCredits: 90000000,
      minEmails: 2,
      maxEmails: 100,
      minContacts: 30000,
      maxContacts: 1000000,
    },
    [BillingSubscriptionCycle.MONTHLY]: {
      minCredits: 10000,
      maxCredits: 7500000,
      minEmails: 2,
      maxEmails: 100,
      minContacts: 30000,
      maxContacts: 1000000,
    },
  },
  [BillingSubscriptionType.UNLIMITED]: {
    [BillingSubscriptionCycle.YEARLY]: {
      minCredits: 600000,
      maxCredits: 90000000,
      minEmails: 50,
      maxEmails: 100,
      minContacts: 100000,
      maxContacts: 1000000,
    },
    [BillingSubscriptionCycle.MONTHLY]: {
      minCredits: 5000,
      maxCredits: 7500000,
      minEmails: 50,
      maxEmails: 100,
      minContacts: 100000,
      maxContacts: 1000000,
    },
  },
  [BillingSubscriptionType.PRO]: {
    [BillingSubscriptionCycle.YEARLY]: {
      minCredits: 300000,
      maxCredits: 90000000,
      minEmails: 5,
      maxEmails: 100,
      minContacts: 50000,
      maxContacts: 1000000,
    },
    [BillingSubscriptionCycle.MONTHLY]: {
      minCredits: 25000,
      maxCredits: 7500000,
      minEmails: 5,
      maxEmails: 100,
      minContacts: 50000,
      maxContacts: 1000000,
    },
  },
  [BillingSubscriptionType.TRIAL]: {
    [BillingSubscriptionCycle.YEARLY]: {
      minCredits: 60000,
      maxCredits: 90000000,
    },
    [BillingSubscriptionCycle.MONTHLY]: {
      minCredits: 5000,
      maxCredits: 7500000,
    },
  },
  [BillingSubscriptionType.INTERNAL]: {
    [BillingSubscriptionCycle.YEARLY]: {
      minCredits: 60000,
      maxCredits: 90000000,
    },
    [BillingSubscriptionCycle.MONTHLY]: {
      minCredits: 5000,
      maxCredits: 7500000,
    },
  },
  [BillingSubscriptionType.MAINTENANCE]: {
    [BillingSubscriptionCycle.YEARLY]: {
      minCredits: 60000,
      maxCredits: 90000000,
    },
    [BillingSubscriptionCycle.MONTHLY]: {
      minCredits: 5000,
      maxCredits: 7500000,
    },
  },
  [BillingSubscriptionType.ENTERPRISE]: {
    [BillingSubscriptionCycle.YEARLY]: {
      minCredits: 600000,
      maxCredits: 90000000,
      minEmails: 10,
      maxEmails: 100,
      minContacts: 100000,
      maxContacts: 1000000,
    },
    [BillingSubscriptionCycle.MONTHLY]: {
      minCredits: 5000,
      maxCredits: 7500000,
      minEmails: 10,
      maxEmails: 100,
      minContacts: 100000,
      maxContacts: 1000000,
    },
  },
};

export const roundSubscriptionCredits = (
  credits: number,
  cycle: BillingSubscriptionCycleMap[keyof BillingSubscriptionCycleMap],
  type: BillingSubscriptionTypeMap[keyof BillingSubscriptionTypeMap]
) => {
  const roundBy = cycle === BillingSubscriptionCycle.YEARLY ? 12000 : 1000;

  const { minCredits, maxCredits } = subscriptionLimits[type][cycle];

  return fitNumberIntoMinMaxRange(Math.round(credits / roundBy) * roundBy, minCredits, maxCredits);
};

export const roundUpsellCredits = (
  credits: number,
  cycle?: BillingSubscriptionCycleMap[keyof BillingSubscriptionCycleMap]
) => {
  const roundBy = cycle === BillingSubscriptionCycle.YEARLY ? 60000 : 5000;

  return Math.ceil(credits / roundBy) * roundBy;
};

export const fitNumberIntoMinMaxRange = (number, min, max) => {
  return Math.min(Math.max(number, min), max);
};
