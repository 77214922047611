import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import ConfigurableTable from '@uikit/ConfigurableTable/ConfigurableTable';
import ConfirmModal from '@uikit/ConfirmModal/ConfirmModal';

import { WorkspaceType } from '@ts/userOrganization.types';

import { addNotification } from '@redux/actions/notifications.actions';
import { getCurrentWorkspaceId } from '@redux/selectors/workspaces.selectors';

import WorkspaceRenderRow from '@components/Settings/WorkspacesTable/_components/WorkspaceRenderRow';

import './WorkspacesTable.scss';
import { deleteWorkspaceApi } from '@api/userOrganization.api';
import { deleteWorkspace } from '@redux/actions/workspaces.actions';
import useWorkspaces from '@hooks/useWorkspaces';
import useIntersectionObserver from '@hooks/useIntersectionObserver';
import { useRefCallback } from '@helpers/refHelpers';
import Loader from '@uikit/Loader/Loader';
import workspaceTableConfig from './workspaceTableConfig';

type WorkspacesTablePropsType = {
  workspaces: WorkspaceType[];
  selectedRows: {
    [workspaceId: number]: boolean;
  };
  isAllSelected: boolean;
  onToggleAllSelected: (boolean) => void;
  onToggleRowSelection: (number) => void;
  onEditWorkspaceSelection: (workspace: WorkspaceType) => void;
};

function WorkspacesTable({
  workspaces,
  selectedRows,
  isAllSelected,
  onToggleAllSelected,
  onToggleRowSelection,
  onEditWorkspaceSelection,
}: WorkspacesTablePropsType): JSX.Element {
  const dispatch = useDispatch();
  const [loadingRef, setLoadingRef, ready] = useRefCallback<HTMLDivElement>();
  const [workspaceIdToDelete, changeWorkspaceIdToDelete] = useState<number | null>(null);
  const currentWorkspace = useSelector(getCurrentWorkspaceId);
  const { removeItem, fetchNextPage, isLoadingNextPage, hasNextPage } = useWorkspaces();

  useIntersectionObserver(loadingRef, () => fetchNextPage());

  const openModalToRemove = (workspaceId: number) => {
    // we can't delete last workspace
    if (workspaces.length < 2) {
      dispatch(addNotification({ title: 'It is the last workspace', type: 'warning' }));
      return;
    }

    // if we are on this workspace
    if (currentWorkspace === workspaceId) {
      dispatch(addNotification({ title: 'You are on current workspace', type: 'warning' }));
      return;
    }

    changeWorkspaceIdToDelete(workspaceId);
  };

  const handleCloseRemovingModal = () => changeWorkspaceIdToDelete(null);

  const removeWorkspace = () => {
    deleteWorkspaceApi(workspaceIdToDelete).then(() => {
      dispatch(deleteWorkspace(workspaceIdToDelete));
      removeItem(workspaceIdToDelete);
      dispatch(addNotification({ title: 'Workspace removed', type: 'success' }));
    });
    handleCloseRemovingModal();
  };

  // const renameWorkspace = (workspace: WorkspaceType) => {
  //   const { id, title, logoUrl } = workspace;
  //   updateWorkspaceRequest(dispatch, id, title, logoUrl);
  // };

  const handleEditWorkspaceSidebarOpen = (workspace: WorkspaceType) =>
    onEditWorkspaceSelection(workspace);

  const renderLoader = () => {
    if (isLoadingNextPage) {
      return <Loader isLoading withTopMargin />;
    }

    if (hasNextPage) {
      return (
        <div ref={setLoadingRef} style={{ marginBottom: '10px' }}>
          <Loader isLoading withTopMargin />
        </div>
      );
    }

    return <div />;
  };

  return (
    <div className="settings-workspaces-table__wrapper">
      <ConfigurableTable
        config={workspaceTableConfig}
        data={workspaces}
        tableClassName="settings-workspaces-table"
        RenderRow={WorkspaceRenderRow}
        renderProps={{
          isAllSelected,
          selectedRows,
          removeWorkspace,
          onEdit: (payload: WorkspaceType) => handleEditWorkspaceSidebarOpen(payload),
          openModalToRemove,
        }}
      />
      {renderLoader()}
      <ConfirmModal
        title="Warning"
        text="All campaigns and data associated with this workspace will be deleted, this action is not
          reversable."
        confirmButtonText="Remove Workspace"
        isConfirmButtonMain={false}
        cancelButtonText="Cancel"
        isOpen={!!workspaceIdToDelete}
        onClose={handleCloseRemovingModal}
        onConfirm={removeWorkspace}
        additionalStyles={{ maxWidth: '450px' }}
      />
    </div>
  );
}

export default WorkspacesTable;
