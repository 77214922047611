import React from 'react';

export default ({ color = '#BDBDBD', size = 20 }: { color?: string; size?: number }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.16663 3.33317C3.94561 3.33317 3.73365 3.42097 3.57737 3.57725C3.42109 3.73353 3.33329 3.94549 3.33329 4.1665V15.488L5.24404 13.5772C5.40032 13.421 5.61228 13.3332 5.83329 13.3332H15.8333C16.0543 13.3332 16.2663 13.2454 16.4225 13.0891C16.5788 12.9328 16.6666 12.7209 16.6666 12.4998V4.1665C16.6666 3.94549 16.5788 3.73353 16.4225 3.57725C16.2663 3.42097 16.0543 3.33317 15.8333 3.33317H4.16663ZM2.39886 2.39874C2.8677 1.9299 3.50358 1.6665 4.16663 1.6665H15.8333C16.4963 1.6665 17.1322 1.9299 17.6011 2.39874C18.0699 2.86758 18.3333 3.50346 18.3333 4.1665V12.4998C18.3333 13.1629 18.0699 13.7988 17.6011 14.2676C17.1322 14.7364 16.4963 14.9998 15.8333 14.9998H6.17847L3.08922 18.0891C2.85088 18.3274 2.49245 18.3987 2.18106 18.2697C1.86966 18.1408 1.66663 17.8369 1.66663 17.4998V4.1665C1.66663 3.50346 1.93002 2.86758 2.39886 2.39874Z"
      fill={color}
    />
  </svg>
);
