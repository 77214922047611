import Checkbox from '@uikit/Checkbox/Checkbox';
import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import Loader from '@uikit/Loader/Loader';
import useIntersectionObserver from '@hooks/useIntersectionObserver';
import { useRefCallback } from '@helpers/refHelpers';
import { OpportunityPageType } from '@ts/opportunity.types';
import useWorkspaceMembers from '@hooks/useWorkspaceMembers';

import './OpportunityTable.scss';
import OpportunityTableRow from '@components/CampaignAnalytics/Opportunities/_components/OpportunityTableRow/OpportunityTableRow';

const OpportunitiesTable = React.memo(
  ({
    opportunities,
    selectedIds,
    changeSelectedIds,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  }: {
    opportunities: OpportunityPageType[];
    selectedIds: number[];
    changeSelectedIds: Dispatch<SetStateAction<number[]>>;
    hasNextPage: boolean;
    fetchNextPage: () => void;
    isFetchingNextPage: boolean;
  }): JSX.Element => {
    const [loadingRef, setLoadingRef, ready] = useRefCallback<HTMLDivElement>();
    useIntersectionObserver(loadingRef, fetchNextPage, [ready]);
    const { members: workspaceMembers } = useWorkspaceMembers();

    const isSelectedAll = useMemo(
      () => opportunities.length === selectedIds.length,
      [opportunities.length, selectedIds.length]
    );

    const handlerSelectAll = useCallback(() => {
      changeSelectedIds(isSelectedAll ? [] : opportunities.map(({ id }) => id));
    }, [isSelectedAll, opportunities, changeSelectedIds]);

    const renderLoader = () =>
      isFetchingNextPage ||
      (opportunities.length &&
        opportunities[0].totalItems > opportunities.length &&
        hasNextPage) ? (
        <div ref={setLoadingRef} style={{ marginBottom: '20px' }}>
          <Loader isLoading withTopMargin />
        </div>
      ) : (
        <div />
      );

    const composeSelectAllText = useMemo(() => {
      return opportunities.length > 0 && opportunities[0].totalItems > 0
        ? `Select All (${opportunities[0].totalItems})`
        : 'Select All';
    }, [opportunities]);

    const handleChangeCheckbox = useCallback(
      (id: number) => {
        changeSelectedIds(
          selectedIds.includes(id)
            ? selectedIds.filter((item) => item !== id)
            : [id, ...selectedIds]
        );
      },
      [selectedIds, changeSelectedIds]
    );

    return (
      <>
        <table className="campaign-analytics-opportunity__content-table">
          <thead>
            <tr>
              <th>
                <div className="table-header-title">
                  <Checkbox onChange={handlerSelectAll} value={isSelectedAll} />
                  <span>{composeSelectAllText}</span>
                </div>
              </th>
              <th>Status</th>
              <th>Source</th>
              <th>Contacts</th>
              <th>Metrics</th>
              <th>Date Added</th>
            </tr>
          </thead>
          <tbody>
            {opportunities.map((row) => (
              <OpportunityTableRow
                key={row.id}
                data={row}
                workspaceMembers={workspaceMembers}
                handleChangeCheckbox={handleChangeCheckbox}
                isChecked={selectedIds.includes(row.id)}
              />
            ))}
          </tbody>
        </table>
        {renderLoader()}
      </>
    );
  }
);

export default OpportunitiesTable;
