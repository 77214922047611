import React from 'react';
import cn from 'classnames';

import { SVGIcon } from '@uikit/Icon/Icon';

import './HealthCheckStatus.scss';

const HealthCheckStatus = ({ success, text }: { success?: boolean; text?: string }) => {
  const className = cn('health-check-status', {
    'health-check-status--success': success,
  });

  const icon = success ? 'checkCircled' : 'crossDelete';
  return (
    <div className={className}>
      <SVGIcon icon={icon} size={success ? 9 : 12} />
      {!!text ? text : success ? 'Success' : 'Failed'}
    </div>
  );
};

export default HealthCheckStatus;
