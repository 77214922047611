import { Button } from '@uikit/Button/Button';
import React, { useEffect, useState, useCallback, useMemo } from 'react';

import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';

import RightSidebar from '@components/RightSidebar/RightSidebar';

import './CustomFieldsSidebar.scss';
import {
  CustomVariableType,
  CustomVariableValueRelatedTo,
  CustomVariableValueRelatedToMap,
} from 'respona_api/generated/common_pb';

import {
  CustomVariableCreateRequest,
  CustomVariableResponse,
  OpenAICustomVariableSettings,
} from 'respona_api/generated/people_pb';
import { VariableType } from '@constants/variables';
import { CustomAiVariable, CustomSelectVariable } from '@ts/customVariales.types';
import Loader from '@uikit/Loader/Loader';
import stripHtml from '@utils/stripHtml';
import CustomFieldSidebarContent from '@components/CustomFieldsSidebar/_components/CustomFieldSidebarContent';
import useCustomVariables from '@hooks/useCustomVariables';
import { addNotification } from '@redux/actions/notifications.actions';
import { useDispatch } from 'react-redux';

const defaultState = {
  type: CustomVariableType.TEXT,
  title: '',
  description: '',
  relatedTo: CustomVariableValueRelatedTo.PERSON,
};

const MIN_PROMPT_VALUE = 5;
const MIN_TITLE_VALUE = 2;
const defaultOptsAiState: CustomAiVariable = {
  prompt: '',
  tone: '',
  examples: '',
  outputLengthFrom: 50,
  outputLengthTo: 100,
};
const defaultOptsSelectState: CustomSelectVariable = {
  multi: false,
  itemsList: [],
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
  isUpdate: boolean;
  onSave?: (variable: CustomVariableResponse.AsObject) => void;
  relatedTo?: CustomVariableValueRelatedToMap[keyof CustomVariableValueRelatedToMap];
  updatedVariable?: VariableType;
  onlyTextVariableAvailable?: boolean;
  extraClass?: string;
};

const CustomFieldsSidebar: React.FC<Props> = ({
  isOpen,
  onClose,
  isUpdate,
  onSave,
  relatedTo,
  updatedVariable,
  onlyTextVariableAvailable = false,
  extraClass,
}) => {
  const workspaceId = useCurrentWorkspaceId();
  const dispatch = useDispatch();
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [fields, setFields] = useState<Partial<CustomVariableCreateRequest.AsObject>>(defaultState);
  const [optAiSettings, setOptAiSettings] =
    useState<Partial<OpenAICustomVariableSettings.AsObject>>(defaultOptsAiState);
  const [optSelectSettings, setOptSelectSettings] =
    useState<Partial<CustomSelectVariable>>(defaultOptsSelectState);
  const [isLoadingVariable, setLoadingVariable] = useState<boolean>(false);
  const { addCustomVariable, updateCustomVariable } = useCustomVariables();

  const resetFields = useCallback(() => {
    setFields(defaultState);
    setOptAiSettings(defaultOptsAiState);
    setOptSelectSettings(defaultOptsSelectState);
  }, []);

  useEffect(() => {
    if (updatedVariable == null && fields == null) {
      resetFields();
    }
  }, [resetFields, updatedVariable, fields]);

  useEffect(() => {
    if (updatedVariable?.id) {
      setLoadingVariable(true);
      setTimeout(() => {
        setLoadingVariable(false);
      }, 100);
    }
  }, [updatedVariable?.id]);

  const handleCreateCustomVariable = useCallback(() => {
    if (updatedVariable?.isDefault === true) {
      dispatch(
        addNotification({
          title: "You can't edit Default variable",
          type: 'error',
        })
      );
      return;
    }
    setIsCreating(true);

    let filteredOptSelectSettings = { ...optSelectSettings };

    if (
      fields.type === CustomVariableType.MULTI_SELECT ||
      fields.type === CustomVariableType.SELECT
    ) {
      filteredOptSelectSettings = {
        ...optSelectSettings,
        itemsList: optSelectSettings.itemsList.filter(({ name }) => name.length >= 2),
      };
    }

    const promise =
      isUpdate && updatedVariable && updatedVariable.id > 0
        ? updateCustomVariable(
            updatedVariable.id,
            fields.title,
            fields.description,
            fields.relatedTo,
            fields.type,
            optAiSettings as CustomAiVariable,
            filteredOptSelectSettings as CustomSelectVariable
          )
        : addCustomVariable(
            fields.type,
            fields.title,
            workspaceId,
            fields.description,
            fields.relatedTo,
            optAiSettings as CustomAiVariable,
            {
              ...filteredOptSelectSettings,
              itemsList: filteredOptSelectSettings.itemsList.map(({ uid, ...rest }) => ({
                ...rest,
              })),
            } as CustomSelectVariable
          );

    promise
      .then((variable: CustomVariableResponse.AsObject) => {
        onSave?.(variable);
        onCloseHandle();
      })
      .catch(console.error)
      .finally(() => setIsCreating(false));
  }, [
    isUpdate,
    updatedVariable,
    fields,
    optAiSettings,
    optSelectSettings,
    workspaceId,
    onSave,
    updateCustomVariable,
    addCustomVariable,
  ]);

  useEffect(() => {
    setFields((prev) => ({ ...defaultState, relatedTo }));
  }, [isOpen, relatedTo]);

  useEffect(() => {
    if (updatedVariable && fields.title !== updatedVariable.title) {
      if (updatedVariable.type === CustomVariableType.OPEN_AI) {
        setOptAiSettings({ ...optAiSettings, ...updatedVariable.openAi });
      } else if (
        updatedVariable.type === CustomVariableType.MULTI_SELECT ||
        updatedVariable.type === CustomVariableType.SELECT
      ) {
        setOptSelectSettings({ ...optSelectSettings, ...updatedVariable.select });
      }
      setFields((prev) => ({
        ...prev,
        ...updatedVariable,
      }));
    }
  }, [updatedVariable, optAiSettings, optSelectSettings, fields.title]);

  useEffect(() => {
    if (onlyTextVariableAvailable === true) {
      setFields((prev) => ({ ...prev, type: CustomVariableType.TEXT }));
    }
  }, [onlyTextVariableAvailable]);

  const onCloseHandle = useCallback(() => {
    resetFields();
    onClose();
  }, [onClose, resetFields]);

  const isSaveButtonDisabled = useMemo(() => {
    if (updatedVariable?.isDefault === true) {
      return true;
    }
    if (fields?.title?.length < MIN_TITLE_VALUE) {
      return true;
    }

    if (fields.type === CustomVariableType.OPEN_AI) {
      const promptValue = optAiSettings ? stripHtml(optAiSettings.prompt).trim() : '';
      return promptValue.length < MIN_PROMPT_VALUE;
    }
    if (
      fields.type === CustomVariableType.SELECT ||
      fields.type === CustomVariableType.MULTI_SELECT
    ) {
      return optSelectSettings.itemsList.length <= 0;
    }

    return false;
  }, [fields?.title?.length, fields.type, optAiSettings, optSelectSettings.itemsList.length]);

  return (
    <div className={`custom-fields-sidebar ${extraClass}`}>
      <Loader isLoading={fields == null}>
        <RightSidebar
          isOpen={isOpen}
          onClose={onCloseHandle}
          showFooter={false}
          title={
            <div className="custom-fields-sidebar__header">
              <span>{isUpdate ? 'Update' : 'Create'} custom field</span>
            </div>
          }
        >
          <Loader isLoading={isLoadingVariable}>
            <CustomFieldSidebarContent
              onlyTextVariableAvailable={onlyTextVariableAvailable}
              fields={fields}
              isUpdate={isUpdate}
              isDefaultField={updatedVariable?.isDefault ?? false}
              optAiSettings={optAiSettings}
              setOptAiSettings={setOptAiSettings}
              optSelectSettings={optSelectSettings}
              setOptSelectSettings={setOptSelectSettings}
              setFields={setFields}
            />
            <div className="custom-fields-sidebar__footer">
              <Button
                type="primary"
                disabled={isSaveButtonDisabled}
                isLoading={isCreating}
                onClick={handleCreateCustomVariable}
              >
                Save
              </Button>
              <Button type="additional" onClick={onCloseHandle}>
                Cancel
              </Button>
            </div>
          </Loader>
        </RightSidebar>
      </Loader>
    </div>
  );
};

export default CustomFieldsSidebar;
