import { useState } from 'react';

import { countLimitByWindowHeight } from '@utils/windowChecks';

export default (
  rowHeight?: number,
  verticalPaddings = 0,
  startPage = 1
): {
  page: number;
  limit: number;
  changePage: (number) => void;
  changeLimit: (number) => void;
  changeToDefault: () => void;
} => {
  const [page, changePage] = useState(startPage);
  const [limit, changeLimit] = useState(
    rowHeight ? countLimitByWindowHeight(rowHeight, verticalPaddings) : 10
  );

  const handleChangeLimit = (newLimit) => {
    changeLimit(newLimit);
    changePage(1);
  };

  const changeToDefault = () => {
    changeLimit(rowHeight ? countLimitByWindowHeight(rowHeight, verticalPaddings) : 10);
    changePage(1);
  };

  return {
    page,
    changePage,
    limit,
    changeLimit: handleChangeLimit,
    changeToDefault,
  };
};
