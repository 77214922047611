import React from 'react';

import './AutomationPositionTooltipContent.scss';
import { SVGIcon } from '@uikit/Icon/Icon';

type DataItemType = { label: string; value: string };

const AutomationPositionTooltipContent = ({
  dataToRender,
  onRemoveItem,
  fieldToRender = 'value',
}: {
  dataToRender: DataItemType[];
  onRemoveItem: (value: DataItemType) => void;
  fieldToRender: string;
}) => {
  const createOnRemoveHandler = (item) => (e) => {
    e.stopPropagation();
    onRemoveItem(item);
  };

  return (
    <div className="automation-position-tooltip">
      <div className="automation-position-tooltip__scroll-wrapper">
        {/* eslint-disable-next-line react/destructuring-assignment */}
        {dataToRender.map((item) => {
          return (
            <div
              key={item.label}
              className="automation-position-tooltip__item"
              tabIndex={0}
              role="button"
            >
              {item[fieldToRender]}
              <span onClick={createOnRemoveHandler(item)} tabIndex={0} role="button">
                <SVGIcon icon="crossDelete" />
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AutomationPositionTooltipContent;
