import { LinkedStepType } from '@uikit/LinkedSteps/LinkedSteps';

export const getLinksConfig = (authStep: number): LinkedStepType[] => [
  {
    title: 'Trial',
    checkIsActive: (): boolean => window.location.pathname === '/onboarding/payment',
    url: '/onboarding/payment',
    disabled: authStep > 1,
  },
  {
    title: 'Members',
    checkIsActive: (): boolean => window.location.pathname === '/onboarding/members',
    url: '/onboarding/members',
  },
  {
    title: 'Onboarding',
    checkIsActive: (): boolean => window.location.pathname === '/onboarding/onboarding',
    url: '/onboarding/onboarding',
  },
  {
    title: 'Tutorial',
    checkIsActive: (): boolean => window.location.pathname === '/onboarding/tutorial',
    url: '/onboarding/tutorial',
  },
];
