import { Dispatch } from 'redux';
import {
  updateTemplateStepApi,
  deleteTemplateStepApi,
  applyTemplateApi,
  createTemplateApi,
  createTemplateStepApi,
  deleteTemplateApi,
  getAllTemplatesApi,
} from '@api/templates.api';
import { removeTemplateApi, updateTemplateTitleApi } from '@api/emailTemplates.api';

import { prepareSequenceSteps } from '@helpers/sequenceHelpers';
import loadingStatuses from '@constants/loadingStatuses';
import { uploadFile } from '@api/fileImport.api';
import {
  setSequenceTemplates,
  setSequenceTemplatesLs,
  addOrUpdateEmailTemplate,
  removeEmailTemplate,
  addSequenceTemplate,
  addSequenceTemplateStep,
  updateSequenceTemplateStep,
  removeSequenceTemplate,
} from '@redux/actions/templates.actions';
import {
  csSetSequence,
  csSetSequenceAdditionalContent,
  csSetSequenceLs,
  csUpdateSequenceStepAttachments,
} from '@redux/actions/sequence.actions';

import { TemplateStepTransformedType, TemplateType } from '@ts/template.types';
import { addNotification } from '@redux/actions/notifications.actions';
import { emptyEditorHtml } from '@constants/html.constants';
import { csThrowVerificationStatusInAllCampaignSelectedOpportunities } from '@redux/actions/campaignSettings.actions';

export const createSequenceTemplate = (title: string, workspaceId: number) => (dispatch) => {
  createTemplateApi(title, workspaceId).then((template) => {
    dispatch(addSequenceTemplate(template));
  });
};

export const createSequenceTemplateStep = (templateId: number, order: number) => (dispatch) => {
  const creatingStep = {
    subject: order === 1 ? '<p><span>Subject</span></p>' : emptyEditorHtml,
    content: emptyEditorHtml,
    isSameThread: order !== 1,
    sendDelay: 1,
    order,
  };

  return createTemplateStepApi(templateId, creatingStep).then((sequenceTemplateStep) => {
    dispatch(
      addSequenceTemplateStep({
        templateId,
        sequenceTemplateStep,
      })
    );
    return sequenceTemplateStep;
  });
};

export const updateSequenceTemplateStepThunk =
  (templateId: number, step: TemplateStepTransformedType) => (dispatch) => {
    updateTemplateStepApi(templateId, step).then((updatedStep) => {
      dispatch(
        updateSequenceTemplateStep({
          templateId,
          step: updatedStep,
        })
      );
    });
  };

export const removeSequenceTemplateStepThunk =
  (templateId: number, stepId: number) => (dispatch) => {
    deleteTemplateStepApi(stepId, templateId).then((template) => {
      dispatch(addSequenceTemplate(template));
    });
  };

export const removeSequenceTemplateThunk =
  (templateId: number, workspaceId: number) => (dispatch) => {
    deleteTemplateApi(templateId, workspaceId).then(() => {
      dispatch(removeSequenceTemplate(templateId));
      dispatch(addNotification({ title: 'Sequence template removed', type: 'success' }));
    });
  };

export const getAllTemplatesRequest =
  (page: number, limit: number, workspaceId: number) => (dispatch) => {
    dispatch(setSequenceTemplatesLs(loadingStatuses.INITED));

    getAllTemplatesApi(page, limit, workspaceId)
      .then((templates: TemplateType[]) => {
        dispatch(setSequenceTemplates(templates));
      })
      .finally(() => {
        dispatch(setSequenceTemplatesLs(loadingStatuses.LOADED));
      });
  };

// export const getAllEmailTemplatesOfWorkspace = (workspaceId: number) => (dispatch) => {
//   dispatch(setEmailTemplatesLs(loadingStatuses.INITED));
//
//   getAllTemplatesApi(0, 10, workspaceId)
//     .then((templates) => {
//       dispatch(setEmailTemplates(templates));
//     })
//     .finally(() => {
//       dispatch(setEmailTemplatesLs(loadingStatuses.LOADED));
//     });
// };

export const updateTemplate = (workspaceId: number, id: number, title: string) => (dispatch) => {
  updateTemplateTitleApi(workspaceId, id, title).then((updatedEmailTemplate) => {
    dispatch(addOrUpdateEmailTemplate(updatedEmailTemplate));
    dispatch(addNotification({ title: 'Email template updated', type: 'success' }));
  });
};

export const removeEmailTemplateThunk = (workspaceId: number, id: number) => (dispatch) => {
  removeTemplateApi(workspaceId, id).then(() => {
    dispatch(removeEmailTemplate(id));
    dispatch(addNotification({ title: 'Email template removed', type: 'success' }));
  });
};

export const applyTemplateForSequence =
  (
    lastTemplateId: number,
    newTemplateId: number,
    workspaceId: number
    // eslint-disable-next-line consistent-return
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(csSetSequenceLs(loadingStatuses.INITED));

    applyTemplateApi(lastTemplateId, newTemplateId, workspaceId)
      .then(({ stepsList, enabledUnsubscribeContent, enabledReferralContent }) => {
        dispatch(csSetSequence(prepareSequenceSteps(stepsList)));
        dispatch(
          csSetSequenceAdditionalContent({
            enabledUnsubscribeContent,
            enabledReferralContent,
          })
        );

        dispatch(csThrowVerificationStatusInAllCampaignSelectedOpportunities());
      })
      .finally(() => {
        dispatch(csSetSequenceLs(loadingStatuses.LOADED));
      });
  };

export const uploadSequenceStepAttachments =
  (campaignId: number, workspaceId: number, templateId: number, sequence, file) =>
  (dispatch): Promise<any> => {
    return file?.arrayBuffer()?.then((buffer) => {
      return uploadFile(campaignId, workspaceId, file, buffer).then((processResponse) => {
        // @ts-ignore
        const uploadedFileInfo = processResponse[processResponse.length - 1];

        return updateTemplateStepApi(templateId, {
          ...sequence,
          attachmentsList: [
            ...sequence.threadA.attachmentsList,
            {
              key: uploadedFileInfo.uid,
              title: uploadedFileInfo.title,
              size: file.size,
            },
          ],
        }).then((templateStep) => {
          dispatch(
            csUpdateSequenceStepAttachments({
              stepId: templateStep.id,
              attachmentsList: templateStep.threadA.attachmentsList,
            })
          );
          return templateStep;
        });
      });
    });
  };

export const removeSequenceStepAttachments = (templateId, sequence, fileUid) => (dispatch) => {
  return updateTemplateStepApi(templateId, {
    ...sequence,
    attachmentsList: sequence.attachmentsList.filter(({ key }) => key !== fileUid),
  }).then((templateStep) => {
    dispatch(
      csUpdateSequenceStepAttachments({
        stepId: templateStep.id,
        attachmentsList: templateStep.threadA.attachmentsList,
      })
    );
    return templateStep;
  });
};
