import searchContactsService from '@api/services/searchContactsService';
import streamRequest from '@api/base/streamRequest';
import request from '@api/base/request';
import { PersonItemResponse } from '@ts/people.types';

import { ContactSearchSource as CONTACT_SEARCH_SOURCE } from 'respona_api/generated/common_pb';

import * as common_pb from 'respona_api/generated/common_pb';
import SearchContactsModel, {
  DomainAndJobPositionSearchRequest,
  VerifyEmailResponse,
} from 'respona_api/generated/search-contact_pb';

export const searchPersonDetailsApi = (
  id: number,
  workspaceId: number
): Promise<PersonItemResponse> => {
  const client = searchContactsService.getInstance();
  const idRequest = new SearchContactsModel.PersonDetailsRequest();

  idRequest.setId(id);
  idRequest.setSource(CONTACT_SEARCH_SOURCE.RESPONA_SEARCH_SOURCE);
  idRequest.setWorkspaceId(workspaceId);
  idRequest.setAutoBuy(false);

  return request(client, client.searchPersonDetails, idRequest, true, false);
};

export const searchPersonDetailsByNameAndDomainApi = (
  name: string,
  domain: string,
  workspaceId: number
): Promise<PersonItemResponse> => {
  const client = searchContactsService.getInstance();
  const idRequest = new SearchContactsModel.PersonDetailsByNameAndDomainRequest();

  idRequest.setFullName(name);
  idRequest.setDomain(domain);
  idRequest.setSource(CONTACT_SEARCH_SOURCE.RESPONA_SEARCH_SOURCE);
  idRequest.setWorkspaceId(workspaceId);

  return request(client, client.searchPersonDetailsByNameAndDomain, idRequest, true, false);
};

export const verifyEmailApi = (
  email: string,
  workspaceId: number
): Promise<VerifyEmailResponse.AsObject> => {
  const client = searchContactsService.getInstance();
  const verifyRequest = new common_pb.StringRequest();

  verifyRequest.setValue(email);
  verifyRequest.setWorkspaceId(workspaceId);

  return request(client, client.verifyEmail, verifyRequest, true, false);
};

export const searchByDomainAndJobPositionApi = (
  domainString = '',
  jobPosition = '',
  workspaceId: number,
  page = 0,
  limit = 10
): Promise<PersonItemResponse[]> => {
  const client = searchContactsService.getInstance();

  const searchRequest = !jobPosition
    ? new SearchContactsModel.DomainSearchRequest()
    : new SearchContactsModel.DomainAndJobPositionSearchRequest();

  searchRequest.setPage(page);
  searchRequest.setLimit(limit);

  searchRequest.setSource(CONTACT_SEARCH_SOURCE.RESPONA_SEARCH_SOURCE);
  searchRequest.setWorkspaceId(workspaceId);

  searchRequest.setDomain(domainString);

  if (jobPosition) {
    (searchRequest as DomainAndJobPositionSearchRequest).setJobPosition(jobPosition);
  }

  return streamRequest(
    client,
    !jobPosition ? client.searchByDomain : client.searchByDomainAndJobPosition,
    searchRequest
  );
};

export const searchHunterByDomainAndJobPositionApi = (
  domainString = '',
  department = [],
  workspaceId: number,
  page = 0,
  limit = 10
): Promise<PersonItemResponse[]> => {
  const client = searchContactsService.getInstance();
  const searchRequest = new SearchContactsModel.DomainAndJobPositionSearchRequest();

  searchRequest.setPage(page);
  searchRequest.setLimit(limit);

  searchRequest.setSource(CONTACT_SEARCH_SOURCE.RESPONA_SEARCH_SOURCE);
  searchRequest.setWorkspaceId(workspaceId);

  searchRequest.setDomain(domainString);
  searchRequest.setOptionalDepartmentList(department);

  return streamRequest(client, client.searchByDomainAndJobPosition, searchRequest);
};

export const searchByDomainAndNameApi = (
  fullName: string,
  domain: string,
  workspaceId: number
): Promise<PersonItemResponse> => {
  const client = searchContactsService.getInstance();
  const searchRequest = new SearchContactsModel.DomainAndNameSearchRequest();

  searchRequest.setSource(CONTACT_SEARCH_SOURCE.RESPONA_SEARCH_SOURCE);
  searchRequest.setFullName(fullName);
  searchRequest.setDomain(domain);
  searchRequest.setWorkspaceId(workspaceId);

  return streamRequest<PersonItemResponse>(client, client.searchByDomainAndName, searchRequest);
};

export const searchBySocialLinkApi = (
  linkedin: string,
  workspaceId: number
): Promise<PersonItemResponse> => {
  const client = searchContactsService.getInstance();
  const searchRequest = new SearchContactsModel.SocialLinkSearchRequest();

  searchRequest.setSource(CONTACT_SEARCH_SOURCE.RESPONA_SEARCH_SOURCE);
  searchRequest.setSocialLink(linkedin);
  searchRequest.setWorkspaceId(workspaceId);

  return streamRequest<PersonItemResponse>(client, client.searchBySocialLink, searchRequest);
};
