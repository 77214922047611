import SearchService from '../../../respona_api/generated/search-content_pb_service';

const service = (function () {
  let instance = null;

  return {
    getInstance: (): SearchService.ContentSearchServiceClient => {
      if (!instance) {
        // @ts-ignore
        instance = new SearchService.ContentSearchServiceClient(API_HOST);
      }

      return instance;
    },
  };
})();

export default service;
