import React from 'react';

export default ({ color = '#212121', size = 18 }) => (
  <svg width={size} height={size} viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.8 4.19995H3C1.89543 4.19995 1 5.09538 1 6.19995V16.6C1 17.7045 1.89543 18.6 3 18.6H11.8C12.9046 18.6 13.8 17.7045 13.8 16.6V6.19995C13.8 5.09538 12.9046 4.19995 11.8 4.19995Z"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M3.40002 1H15C16.1046 1 17 1.89543 17 3V17"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.19995 8.19995H10.6"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M4.19995 11.3999H10.6"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M4.19995 14.6001H7.39995"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);
