import React, { useState, useMemo, useReducer } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import PersonalizationEditor from '@components/PersonalizationEditor/PersonalizationEditor';
import TestPersonalizationModal from '@components/Modals/TestPersonalizationModal/TestPersonalizationModal';

import { updatePersonalization } from '@redux/thunks/personalization.thunks';
import { scheduleByIdSelector } from '@redux/selectors/schedules.selectors';
import { getActiveCampaignInfo } from '@redux/selectors/campaignSettings.selectors';
import { OpportunityDetailsType, OpportunityShortType } from '@ts/opportunity.types';
import { PersonalizationStepType } from '@ts/personalizations.types';

import { createHandleTestPersonalization } from '@components/CampaignSettings/PersonalizationSteps/handlers';

import useCustomVariablesValues from '@hooks/useCustomVariablesValues';

import './PersonalizationSteps.scss';
import { DispatchType } from 'src/store';
import { transformPersonalizationStepsUtil } from '@utils/stepsListUtils';
import { PersonBaseType } from '@ts/people.types';
import { OpportunityPersonContactType } from '@ts/contact.types';
import { ReferralLinkType } from '@ts/user.types';
import { extractVariableNamesFromOpportunity } from '@utils/extractVariableNamesFromOpportunity';

interface PersonalizationStepsProps {
  opportunity?: OpportunityDetailsType;
  personDetails?: PersonBaseType;
  selectedContact: OpportunityPersonContactType;
  onChangeSelectedContact: (contact: OpportunityPersonContactType) => void;
  referralContentData?: ReferralLinkType;
}

const initialState = {
  isPersonLoading: false,
  personalisationForTest: null as PersonalizationStepType | null,
};

type StateType = typeof initialState;

type ActionType =
  | { type: 'SET_PERSON_LOADING'; payload: boolean }
  | { type: 'CHANGE_PERSONALIZATION_FOR_TEST'; payload: PersonalizationStepType | null };

function reducer(state: StateType, action: ActionType): StateType {
  switch (action.type) {
    case 'SET_PERSON_LOADING':
      return { ...state, isPersonLoading: action.payload };
    case 'CHANGE_PERSONALIZATION_FOR_TEST':
      return { ...state, personalisationForTest: action.payload };
    default:
      return state;
  }
}

function PersonalizationSteps({
  opportunity,
  personDetails,
  selectedContact,
  onChangeSelectedContact,
  referralContentData,
}: PersonalizationStepsProps): JSX.Element {
  const dispatch = useDispatch<DispatchType>();
  const queryClient = useQueryClient();
  const [state, dispatchState] = useReducer(reducer, initialState);

  const { info: campaignInfo, loadingStatus } = useSelector(getActiveCampaignInfo);
  const campaignSchedule = useSelector(scheduleByIdSelector(campaignInfo.settings.scheduleId));

  const steps = useMemo(
    () => transformPersonalizationStepsUtil(opportunity?.stepsList || []),
    [opportunity?.stepsList]
  );
  const relatedIds = useMemo(
    () => [opportunity?.id, personDetails?.id].filter((it) => it != null && it > 0),
    [opportunity?.id, personDetails?.id]
  );

  const { items: customVariablesValues } = useCustomVariablesValues(
    extractVariableNamesFromOpportunity(opportunity),
    relatedIds
  );

  const setRelatedOpportunityPersonalized = (fields: Partial<OpportunityShortType>) => {
    queryClient.setQueryData(
      ['opportunities-short', campaignInfo.id, campaignInfo.lastBatch],
      ({ pageParams, pages }) => {
        const newPages = pages.map((page) =>
          page.map((oppo) => (oppo.id === opportunity.id ? { ...oppo, ...fields } : oppo))
        );

        return { pageParams, pages: newPages };
      }
    );

    queryClient.setQueryData(
      ['active-opportunity', opportunity.id],
      (prevState: OpportunityDetailsType) => ({
        ...prevState,
        ...fields,
      })
    );
  };

  const handleSave = async (step: PersonalizationStepType) => {
    const updatedOpportunity = await dispatch(
      updatePersonalization(step, campaignInfo.lastSequenceId, opportunity.id, campaignInfo.id)
    );

    // @ts-ignore
    setRelatedOpportunityPersonalized({ edited: true, stepsList: updatedOpportunity.stepsList });
  };

  const handleUpdateMeta = async (step: PersonalizationStepType) => {
    await dispatch(
      updatePersonalization(step, campaignInfo.lastSequenceId, opportunity.id, campaignInfo.id)
    );
    setRelatedOpportunityPersonalized({ edited: true });
  };

  const handleTestPersonalization = (personalization: PersonalizationStepType) =>
    createHandleTestPersonalization(personalization, steps, (test) =>
      dispatchState({ type: 'CHANGE_PERSONALIZATION_FOR_TEST', payload: test })
    );

  const handleClosePersonalizationTestModal = () =>
    dispatchState({ type: 'CHANGE_PERSONALIZATION_FOR_TEST', payload: null });

  const composeKey = (stepId: number, index: number) => `${opportunity?.id}-${stepId}-${index}`;

  return (
    <div className="personalization-steps">
      {steps.map((personalization, index) => (
        <PersonalizationEditor
          key={composeKey(personalization.stepId, index)}
          opportunity={opportunity}
          stepIndex={index}
          onSave={handleSave}
          step={personalization}
          subjects={steps.map(({ subject }) => subject)}
          onUpdateMeta={handleUpdateMeta}
          contacts={opportunity.contactsList}
          selectedContact={selectedContact}
          onChangeSelectedContact={onChangeSelectedContact}
          onTestPersonalization={handleTestPersonalization}
          personDetails={personDetails}
          campaignSchedule={campaignSchedule}
          senderMailboxId={null}
          // senderMailboxId={campaignInfo.senderMailboxId} //@todo uncomment when email select is added
          campaignInfo={campaignInfo}
          customVariablesValues={customVariablesValues}
          setRelatedOpportunityPersonalized={setRelatedOpportunityPersonalized}
          isLoading={state.isPersonLoading}
          referralContent={referralContentData}
        />
      ))}
      <TestPersonalizationModal
        personalization={state.personalisationForTest}
        opportunityId={opportunity?.id}
        personId={
          selectedContact &&
          (
            opportunity.contactsList.find(
              (contact) => contact.contactId === selectedContact.contactId
            ) || {}
          )?.personId
        }
        onClose={handleClosePersonalizationTestModal}
      />
    </div>
  );
}

export default PersonalizationSteps;
