import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb';
import processStreamRequest from '@api/base/processStreamRequest';
import mailboxClient from '@api/services/mailboxAccountService';
import streamRequest from '@api/base/streamRequest';
import request from '@api/base/request';

import {
  MailboxAccountSettingsType,
  MailboxAccountSignatureType,
  MailboxAccountStatisticsType,
  MailboxAccountType,
  MailboxAccountWorkspaceType,
} from '@ts/mailboxAccounts.types';
import * as mailbox_account_pb from 'respona_api/generated/mailbox-account_pb';
import CommonModels from 'respona_api/generated/common_pb';
import MailboxAccountModels, {
  EncryptionMethod,
  EncryptionMethodMap,
  TestConnectionResponseStreamContainer,
} from 'respona_api/generated/mailbox-account_pb';

export const getAllAccountsApi = (
  page: number,
  limit: number
): Promise<MailboxAccountStatisticsType[]> => {
  const client = mailboxClient.getInstance();
  const pageRequest = new CommonModels.PageRequest();
  pageRequest.setPage(page);
  pageRequest.setLimit(limit);

  return streamRequest<MailboxAccountStatisticsType[]>(client, client.getAllAccounts, pageRequest);
};

export const getAccountByIdApi = (id: number): Promise<MailboxAccountSettingsType> => {
  const client = mailboxClient.getInstance();
  const idRequest = new CommonModels.IdRequest();
  idRequest.setId(id);

  return request<MailboxAccountSettingsType>(client, client.getAccountById, idRequest);
};

export const getAccountSignatureByIdApi = (id: number): Promise<MailboxAccountSignatureType> => {
  const client = mailboxClient.getInstance();
  const idRequest = new CommonModels.IdRequest();
  idRequest.setId(id);

  return request<MailboxAccountSignatureType>(client, client.getAccountSignatureById, idRequest);
};

export const updateAccountApi = (
  mailbox: MailboxAccountSettingsType
): Promise<MailboxAccountType> => {
  const client = mailboxClient.getInstance();
  const updateMailboxRequest = new MailboxAccountModels.MailboxAccountUpdateRequest();

  updateMailboxRequest.setId(mailbox.id);
  updateMailboxRequest.setEmail(mailbox.email);
  updateMailboxRequest.setSenderName(mailbox.senderName);
  updateMailboxRequest.setSyncType(mailbox.syncType);
  updateMailboxRequest.setDailySentLimit(mailbox.dailySentLimit);
  updateMailboxRequest.setSignature(mailbox.signature);

  updateMailboxRequest.setDelayBetweenEmailsFrom(mailbox.delayBetweenEmailsFrom);
  updateMailboxRequest.setDelayBetweenEmailsTo(mailbox.delayBetweenEmailsTo);
  updateMailboxRequest.setWorkspaceIdsList(mailbox.workspaceIdsList);

  updateMailboxRequest.setHealthcheckState(mailbox.healthcheckState);
  // updateMailboxRequest.setWarmUpState(mailbox.warmUpState);
  updateMailboxRequest.setCustomTrackingDomain(mailbox.customTrackingDomain);
  // updateMailboxRequest.setTrackingDomain()
  // updateMailboxRequest.setTrackingDomainId()

  return request<MailboxAccountType>(client, client.updateAccount, updateMailboxRequest);
};

export const creatGmailAccountApi = (
  code: string,
  autoBuy: boolean
): Promise<MailboxAccountType> => {
  const client = mailboxClient.getInstance();
  const updateMailboxRequest = new MailboxAccountModels.MailboxAuthenticationRequest();
  const gmailRequest = new MailboxAccountModels.GMailSettings();

  gmailRequest.setAuthCode(code);
  updateMailboxRequest.setGmail(gmailRequest);
  updateMailboxRequest.setType(1);

  return request<MailboxAccountType>(client, client.createAccount, updateMailboxRequest);
};

export const creatSmtpAccountApi = (
  senderName: string,
  senderEmail: string,
  smtpSettings: TestSmptpSettingsType,
  imapSettings: TestSmptpSettingsType,
  validSmtpEncryption: EncryptionMethodMap[keyof EncryptionMethodMap],
  validImapEncryption: EncryptionMethodMap[keyof EncryptionMethodMap],
  isAutoBuy = false
): Promise<MailboxAccountType> => {
  const client = mailboxClient.getInstance();
  const updateMailboxRequest = new MailboxAccountModels.MailboxAuthenticationRequest();
  const SMTPSettings = new MailboxAccountModels.SMTPSettings();

  updateMailboxRequest.setEmail(senderEmail);
  updateMailboxRequest.setSenderName(senderName);
  updateMailboxRequest.setType(2);

  SMTPSettings.setImapEmail(senderEmail);
  SMTPSettings.setImapHost(imapSettings.serverAddress);
  SMTPSettings.setImapPort(Number(imapSettings.port));
  SMTPSettings.setImapUsername(imapSettings.username);
  SMTPSettings.setImapPassword(imapSettings.password);
  SMTPSettings.setImapEncryptionMethod(validImapEncryption || EncryptionMethod.DISABLED);

  SMTPSettings.setSmtpEmail(senderEmail);
  SMTPSettings.setSmtpHost(smtpSettings.serverAddress);
  SMTPSettings.setSmtpport(Number(smtpSettings.port));
  SMTPSettings.setSmtpUsername(smtpSettings.username);
  SMTPSettings.setSmtpPassword(smtpSettings.password);
  SMTPSettings.setSmtpEncryptionMethod(validSmtpEncryption || EncryptionMethod.DISABLED);

  updateMailboxRequest.setSmtp(SMTPSettings);

  return request<MailboxAccountType>(client, client.createAccount, updateMailboxRequest);
};

export const removeAccountApi = (id: number): Promise<void> => {
  const client = mailboxClient.getInstance();
  const removeAccountIdRequest = new CommonModels.IdRequest();

  removeAccountIdRequest.setId(id);

  return request<void>(client, client.deleteAccount, removeAccountIdRequest);
};

export const getSharedWorkspacesApi = (
  mailboxId: number
): Promise<MailboxAccountWorkspaceType[]> => {
  const client = mailboxClient.getInstance();
  const sharedAccountsIdRequest = new CommonModels.IdRequest();

  sharedAccountsIdRequest.setId(mailboxId);

  return streamRequest<MailboxAccountWorkspaceType[]>(
    client,
    client.getAllWorkspacesByAccountId,
    sharedAccountsIdRequest
  );
};

export const addAccountsToWorkspaceApi = (workspaceId: number, accountsIds: number[]) => {
  const client = mailboxClient.getInstance();
  const addingRequest = new MailboxAccountModels.AccountToWorkspace();

  addingRequest.setWorkspaceId(workspaceId);
  addingRequest.setAccountIdsList(accountsIds);

  return request(client, client.addAllToWorkspace, addingRequest);
};

export const removeAccountsFromWorkspaceApi = (workspaceId: number, accountsIds: number[]) => {
  const client = mailboxClient.getInstance();
  const addingRequest = new MailboxAccountModels.AccountToWorkspace();

  addingRequest.setWorkspaceId(workspaceId);
  addingRequest.setAccountIdsList(accountsIds);

  return request(client, client.removeAllFromWorkspace, addingRequest);
};

export const reAuthenticateGmailAccountApi = (
  id: number,
  code: string
): Promise<MailboxAccountType> => {
  const client = mailboxClient.getInstance();
  const mailboxAuthenticationRequest = new MailboxAccountModels.MailboxAuthenticationRequest();
  const gMailSettings = new MailboxAccountModels.GMailSettings();

  gMailSettings.setAuthCode(code);

  mailboxAuthenticationRequest.setId(id);
  mailboxAuthenticationRequest.setType(1);

  mailboxAuthenticationRequest.setGmail(gMailSettings);

  return request<MailboxAccountType>(
    client,
    client.reAuthenticateAccount,
    mailboxAuthenticationRequest
  );
};

export const reAuthenticateSmtpAccountApi = (
  id: number,
  senderName: string,
  senderEmail: string,
  smtpSettings: TestSmptpSettingsType,
  imapSettings: TestSmptpSettingsType,
  validSmtpEncryption: EncryptionMethodMap[keyof EncryptionMethodMap],
  validImapEncryption: EncryptionMethodMap[keyof EncryptionMethodMap]
): Promise<MailboxAccountType> => {
  const client = mailboxClient.getInstance();
  const mailboxAuthenticationRequest = new MailboxAccountModels.MailboxAuthenticationRequest();
  const smptSettings = new MailboxAccountModels.SMTPSettings();

  mailboxAuthenticationRequest.setId(id);
  mailboxAuthenticationRequest.setType(2);
  mailboxAuthenticationRequest.setEmail(senderEmail);
  mailboxAuthenticationRequest.setSenderName(senderName);

  smptSettings.setImapEmail(senderEmail);
  smptSettings.setImapHost(imapSettings.serverAddress);
  smptSettings.setImapPort(Number(imapSettings.port));
  smptSettings.setImapUsername(imapSettings.username);
  smptSettings.setImapPassword(imapSettings.password);
  smptSettings.setImapEncryptionMethod(validImapEncryption || 0);

  smptSettings.setSmtpEmail(senderEmail);
  smptSettings.setSmtpHost(smtpSettings.serverAddress);
  smptSettings.setSmtpport(Number(smtpSettings.port));
  smptSettings.setSmtpUsername(smtpSettings.username);
  smptSettings.setSmtpPassword(smtpSettings.password);
  smptSettings.setSmtpEncryptionMethod(validSmtpEncryption || 0);

  mailboxAuthenticationRequest.setSmtp(smptSettings);

  return request<MailboxAccountType>(
    client,
    client.reAuthenticateAccount,
    mailboxAuthenticationRequest
  );
};

export const getAccountsByWorkspaceIdApi = (workspaceId: number): Promise<MailboxAccountType[]> => {
  const client = mailboxClient.getInstance();
  const getWorkspaceAccountsIdRequest = new CommonModels.IdRequest();

  getWorkspaceAccountsIdRequest.setId(workspaceId);

  return streamRequest<MailboxAccountType[]>(
    client,
    client.getAllByWorkspaceId,
    getWorkspaceAccountsIdRequest
  );
};

type TestSmptpSettingsType = {
  username: string;
  serverAddress: string;
  password: string;
  port: string;
};

export const testMailboxAccountApi = (
  senderName: string,
  senderEmail: string,
  smtpSettings: TestSmptpSettingsType,
  imapSettings: TestSmptpSettingsType,
  callback: (data: [TestConnectionResponseStreamContainer.AsObject, any]) => void
): Promise<TestConnectionResponseStreamContainer.AsObject> => {
  const client = mailboxClient.getInstance();
  const tesConnectionRequest = new MailboxAccountModels.TestConnectionRequest();
  const connectionSettingsSmtp = new MailboxAccountModels.ConnectionSettings();
  const connectionSettingsImap = new MailboxAccountModels.ConnectionSettings();

  tesConnectionRequest.setSenderName(senderName);

  connectionSettingsSmtp.setUsername(smtpSettings.username);
  connectionSettingsSmtp.setEmail(senderEmail);
  connectionSettingsSmtp.setHost(smtpSettings.serverAddress);
  connectionSettingsSmtp.setPassword(smtpSettings.password);
  connectionSettingsSmtp.setPort(Number(smtpSettings.port));
  tesConnectionRequest.setSmtp(connectionSettingsSmtp);

  connectionSettingsImap.setUsername(imapSettings.username);
  connectionSettingsImap.setEmail(senderEmail);
  connectionSettingsImap.setHost(imapSettings.serverAddress);
  connectionSettingsImap.setPassword(imapSettings.password);
  connectionSettingsImap.setPort(Number(imapSettings.port));
  tesConnectionRequest.setImap(connectionSettingsImap);

  return processStreamRequest(
    client,
    client.testEmailConnectionStreaming,
    tesConnectionRequest,
    callback
  );
};

export const updateReferralContentStateApi = (
  mailboxId: number,
  state: boolean
): Promise<mailbox_account_pb.ReferralContentState.AsObject> => {
  const client = mailboxClient.getInstance();
  const referralContentStateRequest = new mailbox_account_pb.ReferralContentState();

  referralContentStateRequest.setId(mailboxId);
  referralContentStateRequest.setState(state);

  return request<mailbox_account_pb.ReferralContentState.AsObject>(
    client,
    client.updateReferralContentState,
    referralContentStateRequest
  );
};
