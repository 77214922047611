import React, { useState } from 'react';

import MetaInfoValue from '@components/Inbox/InboxWidget/_components/MetaInfo/_components/MetaInfoValue';
import Input from '@uikit/Input/Input';
import checkIsEmail from '@utils/checkIsEmail';

const EmailsRow = ({
  label,
  contactEmail,
  onAdd,
  onRemove,
  disabled,
}: {
  label: string;
  contactEmail: string;
  onAdd: (contactEmail: string) => void;
  onRemove: (contactEmail: string) => void;
  disabled: boolean;
}): JSX.Element => {
  const [newContact, changeNewContact] = useState<string>('');

  const clearInput = () => changeNewContact('');
  const handleChangeInput = ({ target: { value } }) => changeNewContact(value);

  const [newError, changeNewError] = useState(false);
  const turnOnError = () => changeNewError(true);
  const turnOffError = () => changeNewError(false);

  const addNewContactByEnter = (e) => {
    turnOffError();

    if (e.key === 'Enter') {
      applyAddContact();
    }
  };

  const applyAddContact = () => {
    if (checkIsEmail(newContact)) {
      onAdd(newContact);
      clearInput();
    } else {
      turnOnError();
    }
  };

  return (
    <div className="meta-info__row">
      <div className="meta-info__label">{label}:</div>
      <div className="meta-info__to-emails">
        {contactEmail.length === 0 ? (
          <Input
            placeholder="..."
            onKeyDown={addNewContactByEnter}
            value={newContact}
            isTransparent
            onChange={handleChangeInput}
            isError={newError}
            onBlur={applyAddContact}
          />
        ) : (
          <MetaInfoValue value={contactEmail} onRemove={onRemove} disabled={disabled} />
        )}
      </div>
    </div>
  );
};

export default EmailsRow;
