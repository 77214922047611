import React from 'react';

export default ({ color = '#fff', size = 16 }: { color?: string; size?: number }): JSX.Element => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.16108 11.8001H6.15992H3.33325C3.20948 11.8001 3.09079 11.751 3.00327 11.6634C2.91575 11.5759 2.86659 11.4572 2.86659 11.3335L2.86659 8.50679L2.86658 8.50563C2.86623 8.44422 2.878 8.38333 2.90122 8.32648C2.92439 8.26976 2.95849 8.21817 3.00159 8.17463C3.0017 8.17452 3.00181 8.17441 3.00191 8.1743L7.6279 3.55498L7.62826 3.55463L9.50826 1.66796L9.50859 1.66763C9.55197 1.62389 9.60358 1.58917 9.66045 1.56548C9.71732 1.54179 9.77831 1.52959 9.83992 1.52959C9.90152 1.52959 9.96252 1.54179 10.0194 1.56548C10.0762 1.58916 10.1278 1.62385 10.1712 1.66755C10.1712 1.66758 10.1712 1.6676 10.1713 1.66763L12.9977 4.52738L12.9977 4.52739L12.9991 4.52879C13.0428 4.57217 13.0775 4.62379 13.1012 4.68065C13.1249 4.73752 13.1371 4.79852 13.1371 4.86012C13.1371 4.92173 13.1249 4.98272 13.1012 5.03959C13.0776 5.09626 13.0431 5.14772 12.9995 5.19101C12.9994 5.19116 12.9992 5.19131 12.9991 5.19146L11.1067 7.04387L11.1067 7.04386L11.1051 7.04547L6.49241 11.6648C6.49229 11.6649 6.49217 11.665 6.49205 11.6651C6.44852 11.7082 6.39693 11.7423 6.34023 11.7655C6.28337 11.7887 6.22249 11.8005 6.16108 11.8001ZM9.98134 2.7987L9.83992 2.65728L9.6985 2.7987L8.75183 3.74537L8.61041 3.88679L8.75183 4.02821L10.6385 5.91488L10.7799 6.0563L10.9213 5.91488L11.868 4.96821L12.0094 4.82679L11.868 4.68537L9.98134 2.7987ZM3.8585 8.6387L3.79992 8.69728V8.78012V10.6668V10.8668H3.99992H5.88659H5.96943L6.02801 10.8082L9.98134 6.85488L10.1228 6.71346L9.98134 6.57203L8.09467 4.68537L7.95325 4.54395L7.81183 4.68537L3.8585 8.6387ZM14.3299 14.3301L14.4713 14.4715L14.3299 14.3301C14.2424 14.4176 14.1237 14.4668 13.9999 14.4668H1.99992C1.87615 14.4668 1.75745 14.4176 1.66994 14.3301C1.58242 14.2426 1.53325 14.1239 1.53325 14.0001C1.53325 13.8764 1.58242 13.7577 1.66994 13.6701C1.75745 13.5826 1.87615 13.5335 1.99992 13.5335H13.9999C14.1237 13.5335 14.2424 13.5826 14.3299 13.6701C14.4174 13.7577 14.4666 13.8764 14.4666 14.0001C14.4666 14.1239 14.4174 14.2426 14.3299 14.3301Z"
      fill={color}
      stroke={color}
      strokeWidth="0.4"
    />
  </svg>
);
