import React from 'react';

const DataRow = ({ row }) => {
  return (
    <>
      <div className="invoice-info__cost-grid-cell invoice-info__cost-grid-cell--with-border">
        {row.description}
      </div>
      <div className="invoice-info__cost-grid-cell invoice-info__cost-grid-cell--with-border">
        {row.quantity}
      </div>
      <div className="invoice-info__cost-grid-cell invoice-info__cost-grid-cell--righted invoice-info__cost-grid-cell--with-border">
        $ {row.amount}
      </div>
    </>
  );
};

export default DataRow;