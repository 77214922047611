import { useSelector } from 'react-redux';
import { useInfiniteQuery } from 'react-query';

import { getCurrentWorkspaceId } from '@redux/selectors/workspaces.selectors';
import { getCampaignShortcutsApi } from '@api/campaign.api';
import { CampaignShortcutType } from '@ts/campaigns.types';
import { QueryPageHookType } from '@ts/common.types';
import { useMemo } from 'react';

export default (searchQuery: string = ''): QueryPageHookType<CampaignShortcutType> => {
  const currentWorkspaceId = useSelector(getCurrentWorkspaceId);
  const key = useMemo(
    () => ['campaigns-for-filters', currentWorkspaceId, searchQuery],
    [currentWorkspaceId, searchQuery]
  );

  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage, refetch } =
    useInfiniteQuery(
      key,
      ({ pageParam = 0 }) => getCampaignShortcutsApi(currentWorkspaceId, searchQuery, pageParam),
      {
        getNextPageParam: (lastPage: CampaignShortcutType[], allPages) => {
          if (lastPage.length < 50) return false;

          return allPages.length;
        },
        enabled: currentWorkspaceId > 0,
        retry: 1,
        refetchOnWindowFocus: false,
      }
    );

  const removeFromCacheById = (id: number) => {};
  const addToCache = (item: CampaignShortcutType) => {};
  const updateInCache = (id: number, item: CampaignShortcutType) => {};
  const evictCacheByKey = (id: number) => {};

  return {
    items: data?.pages?.flat(),
    refetch,
    isLoading,
    isLoadingNextPage: isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    removeItem: removeFromCacheById,
    addItem: addToCache,
    updateItem: updateInCache,
    evictByKey: evictCacheByKey,
  };
};
