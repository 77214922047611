import React from 'react';

export default ({ color = '#C4C6CD' }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.33366 11.6667H11.667C11.888 11.6667 12.1 11.5789 12.2562 11.4226C12.4125 11.2663 12.5003 11.0543 12.5003 10.8333C12.5003 10.6123 12.4125 10.4004 12.2562 10.2441C12.1 10.0878 11.888 10 11.667 10H8.33366C8.11265 10 7.90068 10.0878 7.7444 10.2441C7.58812 10.4004 7.50033 10.6123 7.50033 10.8333C7.50033 11.0543 7.58812 11.2663 7.7444 11.4226C7.90068 11.5789 8.11265 11.6667 8.33366 11.6667V11.6667ZM15.8337 2.5H4.16699C3.50395 2.5 2.86807 2.76339 2.39923 3.23223C1.93038 3.70107 1.66699 4.33696 1.66699 5V7.5C1.66699 7.72101 1.75479 7.93298 1.91107 8.08926C2.06735 8.24554 2.27931 8.33333 2.50033 8.33333H3.33366V15C3.33366 15.663 3.59705 16.2989 4.06589 16.7678C4.53473 17.2366 5.17062 17.5 5.83366 17.5H14.167C14.83 17.5 15.4659 17.2366 15.9348 16.7678C16.4036 16.2989 16.667 15.663 16.667 15V8.33333H17.5003C17.7213 8.33333 17.9333 8.24554 18.0896 8.08926C18.2459 7.93298 18.3337 7.72101 18.3337 7.5V5C18.3337 4.33696 18.0703 3.70107 17.6014 3.23223C17.1326 2.76339 16.4967 2.5 15.8337 2.5ZM15.0003 15C15.0003 15.221 14.9125 15.433 14.7562 15.5893C14.6 15.7455 14.388 15.8333 14.167 15.8333H5.83366C5.61265 15.8333 5.40068 15.7455 5.2444 15.5893C5.08812 15.433 5.00033 15.221 5.00033 15V8.33333H15.0003V15ZM16.667 6.66667H3.33366V5C3.33366 4.77899 3.42146 4.56702 3.57774 4.41074C3.73402 4.25446 3.94598 4.16667 4.16699 4.16667H15.8337C16.0547 4.16667 16.2666 4.25446 16.4229 4.41074C16.5792 4.56702 16.667 4.77899 16.667 5V6.66667Z"
      fill={color}
    />
  </svg>
);
