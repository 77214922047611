import React from 'react';

export default ({ color = '#000' }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.4401 3.33328C12.7334 2.62475 11.7966 2.19231 10.799 2.11406C9.80135 2.03581 8.80861 2.31692 8.0001 2.90661C7.15187 2.27571 6.0961 1.98962 5.04538 2.10597C3.99467 2.22232 3.02706 2.73247 2.33742 3.53367C1.64777 4.33488 1.28732 5.36763 1.32864 6.42396C1.36996 7.48029 1.80998 8.48173 2.5601 9.22661L6.7001 13.3733C7.04678 13.7145 7.51369 13.9057 8.0001 13.9057C8.48651 13.9057 8.95342 13.7145 9.3001 13.3733L13.4401 9.22661C14.2185 8.44346 14.6554 7.38413 14.6554 6.27995C14.6554 5.17577 14.2185 4.11644 13.4401 3.33328ZM12.5001 8.30661L8.3601 12.4466C8.31299 12.4942 8.25692 12.5319 8.19513 12.5577C8.13334 12.5835 8.06705 12.5968 8.0001 12.5968C7.93315 12.5968 7.86687 12.5835 7.80508 12.5577C7.74328 12.5319 7.68721 12.4942 7.6401 12.4466L3.5001 8.28661C2.97727 7.75218 2.68451 7.03426 2.68451 6.28661C2.68451 5.53897 2.97727 4.82105 3.5001 4.28661C4.03287 3.76061 4.75142 3.46566 5.5001 3.46566C6.24879 3.46566 6.96733 3.76061 7.5001 4.28661C7.56208 4.3491 7.63581 4.3987 7.71705 4.43254C7.79829 4.46639 7.88543 4.48381 7.97344 4.48381C8.06144 4.48381 8.14858 4.46639 8.22982 4.43254C8.31106 4.3987 8.38479 4.3491 8.44677 4.28661C8.97954 3.76061 9.69808 3.46566 10.4468 3.46566C11.1955 3.46566 11.914 3.76061 12.4468 4.28661C12.9768 4.81405 13.2792 5.52807 13.2891 6.27574C13.2991 7.0234 13.0159 7.74524 12.5001 8.28661V8.30661Z"
      fill={color}
    />
  </svg>
);
