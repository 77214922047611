import React, { useState } from 'react';
import { components } from 'react-select';

import Input from '@uikit/Input/Input';
import Select from '@uikit/Select/Select';
import Loader from '@uikit/Loader/Loader';
import { SVGIcon } from '@uikit/Icon/Icon';

import useCampaignShortcutsByWorkspace from '@hooks/useCampaignShortcutsByWorkspace';
import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';
import { useQueryClient } from 'react-query';
import { InboxAccountsPresetType } from '@ts/mailboxInbox.types';
import { ThreadStatus } from 'respona_api/generated/mailbox_pb';

type RenderProps = {
  placeholder: string;
  icon: string;
  extraClass?: string;
};

type OnGrabValueType = (value: string) => void;

type InboxFiltersRowType = {
  text: string;
  RenderField: ({
    renderProps,
    onGrabValue,
    value,
  }: {
    renderProps: RenderProps;
    onGrabValue: OnGrabValueType;
    value: string;
  }) => JSX.Element;
  filterField: string;
  renderProps?: RenderProps;
};

function RightInput({
  renderProps,
  onGrabValue,
  value,
}: {
  renderProps: RenderProps;
  onGrabValue: OnGrabValueType;
  value: string;
}): JSX.Element {
  return (
    <Input
      className="inbox-filter-input"
      icon={renderProps.icon}
      iconColor="#5E5E5E"
      placeholder={renderProps.placeholder}
      value={value}
      onChange={(e) => onGrabValue(e.target.value)}
    />
  );
}

function Control({ children, ...restProps }) {
  return (
    <components.Control {...restProps}>
      &nbsp;&nbsp; <SVGIcon icon="notificationsIcon" color="#5E5E5E" isSelected /> {children}
    </components.Control>
  );
}

function ControlStatus({ children, ...restProps }) {
  return (
    <components.Control {...restProps}>
      &nbsp;&nbsp; <SVGIcon icon="statusBox" isSelected /> {children}
    </components.Control>
  );
}

function ControlAccount({ children, ...restProps }) {
  return (
    <components.Control {...restProps}>
      &nbsp;&nbsp; <SVGIcon icon="messageIcon" color="#5E5E5E" isSelected /> {children}
    </components.Control>
  );
}

const filterSelectStyles = {
  control: (provided) => ({
    ...provided,
    background: 'transparent',
    borderColor: 'rgba(141, 143, 146, 0.27)',
    borderRadius: '8.5px',
    color: 'white',
    height: '45px',
    paddingLeft: '5px',
    '&:focus,&:hover': {
      outline: 'none',
      boxShadow: 'none',
    },
  }),
  input: (provided) => ({
    ...provided,
    color: 'white',
  }),
  indicatorContainer: (provided) => ({
    ...provided,
    '& svg': {
      fill: 'rgba(141, 143, 146, 0.27)',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'white',
  }),
  placeholder: (provided) => ({
    ...provided,
    paddingLeft: '5px',
    fontSize: '16px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    overflowY: 'auto',
    maxHeight: '43px',
  }),
  multiValue: (provided) => ({
    ...provided,
    width: 'fit-content',
    '& svg': {
      fill: 'rgba(141, 143, 146, 1)',
    },
  }),
};

function CampaignsOptions({ renderProps, onGrabValue, value }) {
  const { items: campaigns, isLoading } = useCampaignShortcutsByWorkspace();

  if (isLoading) {
    return <Loader isLoading />;
  }

  const campaignOptions = campaigns.map(({ id, title }) => ({
    value: id,
    label: title,
  }));
  const selectedOption = campaignOptions.find((option) => option.value === value);

  return (
    <Select
      className={renderProps.extraClass}
      placeholder={renderProps.placeholder}
      options={campaignOptions}
      onChange={({ value }) => onGrabValue(value)}
      additionalStyles={filterSelectStyles}
      additionalComponents={{ Control }}
      value={selectedOption || null}
    />
  );
}

function CampaignsSelect({ renderProps, onGrabValue, value }): JSX.Element {
  const [isTriggered, setIsTriggered] = useState(false);

  if (!isTriggered) {
    return (
      <Select
        className={renderProps.extraClass}
        placeholder={renderProps.placeholder}
        options={[]}
        onChange={({ value }) => onGrabValue(value)}
        onInputChange={(text, { action }) => {
          if (action === 'input-change') {
            setIsTriggered(true);
          }
        }}
        additionalStyles={filterSelectStyles}
        additionalComponents={{ Control }}
      />
    );
  }

  return <CampaignsOptions renderProps={renderProps} onGrabValue={onGrabValue} value={value} />;
}

function StatusSelect({ renderProps, onGrabValue, value }): JSX.Element {
  const statusOptions = [
    { value: ThreadStatus.OPEN, label: 'Open' },
    { value: ThreadStatus.ARCHIVE, label: 'Archived' },
    { value: ThreadStatus.SNOOZED, label: 'Snoozed' },
    { value: ThreadStatus.REMOVED, label: 'Removed' },
  ];
  const selectedOption = statusOptions.find((option) => option.value === value);

  return (
    <Select
      placeholder={renderProps.placeholder}
      options={statusOptions}
      onChange={({ value }) => onGrabValue(value)}
      additionalStyles={filterSelectStyles}
      additionalComponents={{ ControlStatus }}
      value={selectedOption || null}
    />
  );
}

function AccountSelect({ renderProps, onGrabValue, value }): JSX.Element {
  const currentWorkspaceId = useCurrentWorkspaceId();
  const queryClient = useQueryClient();
  const { data, isFetching }: { data: InboxAccountsPresetType[]; isFetching: boolean } =
    queryClient.getQueryState(['inbox-mailboxes-preset', currentWorkspaceId]);

  if (isFetching) {
    return <Loader isLoading />;
  }

  const accountOptions = data.map(({ id, email }) => ({
    value: id,
    label: email,
  }));

  return (
    <Select
      isMulti
      visibleMultiCount={4}
      placeholder={renderProps.placeholder}
      options={accountOptions}
      onChange={(values) => onGrabValue(values)}
      additionalStyles={filterSelectStyles}
      additionalComponents={{ ControlAccount }}
      value={value || null}
    />
  );
}

const inboxFiltersConfig: InboxFiltersRowType[] = [
  {
    text: 'From',
    renderProps: {
      icon: 'messageIcon',
      placeholder: 'Enter email',
    },
    filterField: 'fromEmail',
    RenderField: RightInput,
  },
  {
    text: 'To/CC/BCC',
    renderProps: {
      icon: 'messageIcon',
      placeholder: 'Enter email',
    },
    filterField: 'toEmail',
    RenderField: RightInput,
  },
  {
    text: 'Subject',
    renderProps: {
      icon: 'textMessage',
      placeholder: 'Enter subject',
    },
    filterField: 'subjectQuery',
    RenderField: RightInput,
  },
  {
    text: 'Status',
    renderProps: {
      icon: 'statusBox',
      placeholder: 'Select status',
    },
    filterField: 'statusId',
    RenderField: StatusSelect,
  },
  {
    text: 'Campaign',
    renderProps: {
      icon: 'search',
      placeholder: 'Select campaign',
      extraClass: 'inbox-select',
    },
    filterField: 'campaignId',
    RenderField: CampaignsSelect,
  },
  {
    text: 'Email Accounts',
    renderProps: {
      icon: 'messageIcon',
      placeholder: 'Select inboxes',
    },
    filterField: 'accountId',
    RenderField: AccountSelect,
  },
];

export default inboxFiltersConfig;
