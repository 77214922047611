import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import cn from 'class-names';

import WorkspaceSelect from '@components/WorkspaceSelect/WorkspaceSelect';
import ResponaNewLogo from '@uikit/ResponaNewLogo/ResponaNewLogo';

import { checkIsAnyPending } from '@utils/loadingChecks';
import { fetchBillingCredits } from '@redux/thunks/billings.thunks';
import {
  billingCreditsSelector,
  billingDetailsSelector,
} from '@redux/selectors/billings.selectors';
import { DispatchType } from 'src/store';

import SidebarPagesLinks from './components/SidebarPagesLinks';
import SidebarFooter from './components/SidebarFooter/SidebarFooter';

import { pagesLinks } from './pagesLinks';

import './Sidebar.scss';

const SideBar = ({ pathname, preview }: { pathname: string; preview?: boolean }): JSX.Element => {
  const dispatch = useDispatch<DispatchType>();
  const [isHovered, changeIsHovered] = useState(false);
  const { loadingStatus: billingLs } = useSelector(billingCreditsSelector);
  const { data } = useSelector(billingDetailsSelector);

  const isPaymentStatusActive = data?.active;

  useEffect(() => {
    if (checkIsAnyPending(billingLs) && !preview) {
      dispatch(fetchBillingCredits());
    }
  });

  const handleSidebarMouseOver = () => changeIsHovered(true);
  const handleSidebarMouseOut = () => changeIsHovered(false);

  const cnSidebar = cn('sidebar', {
    'sidebar--closed': true,
    'sidebar--hovered': isHovered,
  });
  const cnSidebarPages = cn('sidebar__pages', {
    'sidebar__pages--disabled': !isPaymentStatusActive,
  });
  const cnSidebarWorkspace = cn('sidebar__workspace', {
    'sidebar__workspace--disabled': !isPaymentStatusActive,
  });

  return (
    <div
      className={cnSidebar}
      tabIndex={0}
      role="button"
    >
      <div className="sidebar__header">
        <span className="sidebar__logo sidebar__logo--respona">
          <ResponaNewLogo size="small" />
        </span>
      </div>
      <div className={cnSidebarWorkspace}>
        <WorkspaceSelect preview={preview} />
      </div>
      <div className={cnSidebarPages} onClick={(e) => e.stopPropagation()}>
        <SidebarPagesLinks pathname={pathname} pages={pagesLinks} />
      </div>
      {!preview && <SidebarFooter />}
    </div>
  );
};

export default SideBar;
