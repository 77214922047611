import {
  TemplateContainerTypeWithTransformedStepsType,
  TemplateStepType,
  TemplateStepTypeWithTempId,
} from '@ts/template.types';
import { emptyEditorHtml } from '@constants/html.constants';
import stripHtml from "@utils/stripHtml";

export const isTemplateTheSame = (
  prevState: TemplateContainerTypeWithTransformedStepsType,
  currentState: { title: string; stepsList: TemplateStepType[] }
): boolean => {
  if (!prevState || !currentState.stepsList.length) {
    return false;
  }

  if (prevState.title !== currentState.title) {
    return false;
  }

  if (prevState.stepsList.length !== currentState.stepsList.length) {
    return false;
  }

  for (let i = 0; i < prevState.stepsList.length; i++) {
    const prevStep = prevState.stepsList[i];
    const currentStep = currentState.stepsList[i];

    if (
      prevStep.threadA.content !== currentStep.threadA.content ||
      prevStep.threadA.subject !== currentStep.threadA.subject ||
      prevStep.sendDelay !== currentStep.sendDelay
    ) {
      return false;
    }
  }

  return true;
};

export const formatSameThread = (
  stepsNewState: TemplateStepTypeWithTempId[]
): TemplateStepTypeWithTempId[] => {
  return stepsNewState.map((step, index) => ({
    ...step,
    threadA: {
      ...step.threadA,
      sameThread: !(index === 0 || !!stripHtml(step.threadA.subject).trim()),
    },
  }))
}

export const TEMPLATE_STEP: TemplateStepTypeWithTempId = {
  id: 0,
  templateId: 0, // Temporary, on save with update it
  enabledThreads: false,
  threadA: {
    subject: emptyEditorHtml,
    content: emptyEditorHtml,
    sameThread: true,
    attachmentsList: [],
  },
  threadB: {
    subject: emptyEditorHtml,
    content: emptyEditorHtml,
    sameThread: true,
    attachmentsList: [],
  },
  sendDelay: 7,
  order: 1,
  tempId: 'need to be unique',
};
