import { BillingFeedback } from 'respona_api/generated/billing_pb';

export const billingCancellationSelectStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '8px',
    height: '48px',
    outline: 'none',
    boxShadow: 'none',
    '&:focus,&:hover': {
      border: '1px solid #221CB6',
    },
  }),
  menu: (provided) => ({
    ...provided,
    left: 'auto',
    right: 0,
    backgroundColor: '#000',
    paddingTop: '10px',
    paddingBottom: '10px',
    width: '535px',
    borderRadius: '8px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: '16px',
    fontSize: '16px',
  }),
  option: (provided, { isFocused, isSelected }) => ({
    ...provided,
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #272727',
    paddingLeft: '35px',
    fontSize: '16px',
    '&:active': {
      backgroundColor: '#2E2E2E',
    },
    ...(isFocused || isSelected ? { backgroundColor: '#2E2E2E' } : {}),
  }),
  placeholder: (provided) => ({
    ...provided,
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '16px',
    color: '#8F9199',
  }),
};

export const billingCancellationOptions = [
  {
    label: "It doesn't have the features I need",
    value: BillingFeedback.NOT_ENOUGH_FEATURES,
  },
  {
    label: "It's too expensive",
    value: BillingFeedback.TOO_EXPENSIVE,
  },
  {
    label: 'I don’t need it anymore',
    value: BillingFeedback.DONT_NEED_ANYMORE,
  },
  {
    label: "Haven't received good results from campaigns",
    value: BillingFeedback.NOT_SATISFIED,
  },
  {
    label: 'It’s too buggy',
    value: BillingFeedback.TOO_BUGGY,
  },
  {
    label: 'Other',
    value: BillingFeedback.OTHER_REASON,
  },
];
