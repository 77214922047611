import request from '@api/base/request';

import authService from '@api/services/authService';
import AuthModels from '../../respona_api/generated/auth_pb';

export const changePasswordApi = (email: string, newPassword: string): Promise<any> => {
  const service = authService.getInstance();
  const model = new AuthModels.ChangePasswordRequest();

  model.setEmail(email);
  model.setNewPassword(newPassword);

  return request(service, service.changePassword, model);
};
