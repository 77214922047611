import { BoundaryType, ParsedValueType, ParseInputsType } from '@ts/moderateChance.types';

const boundaries: BoundaryType[] = [
  {
    min: 0,
    max: 2,
    color: 'green',
  },
  {
    min: 3,
    max: 3,
    color: 'yellow',
  },
  {
    min: 4,
    color: 'red',
  },
];

export default {
  key: 'numberCount',
  label: 'Numbers',
  min: boundaries[0].min,
  max: boundaries[boundaries.length - 1].min,
  greenZone: {
    min: 0,
    max: 2,
  },
  parse: ({ text }: ParseInputsType): ParsedValueType => {
    const numCount = text
      .split(' ')
      .filter((word) => word && !Number.isNaN(parseInt(word, 10))).length;

    return {
      value: numCount,
      boundary: boundaries.find(({ min, max }) => {
        if (!max && numCount >= max) {
          return true;
        }

        if (min <= numCount && numCount <= max) {
          return true;
        }

        return false;
      }),
    };
  },
};
