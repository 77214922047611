import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import { SVGIcon } from '@uikit/Icon/Icon';

import './ContactsSidebarCRMSectionSelectionField.scss';
import Select from '@uikit/Select/Select';
import { RelationshipStatusType } from '@ts/people.types';

function ContactsSidebarCRMSectionSelectionField({
  initialStatus,
  statusOptions,
  onChange,
  isOpen = false,
}: {
  initialStatus: RelationshipStatusType;
  statusOptions: any;
  onChange: (initialStatus: RelationshipStatusType, newStatus: RelationshipStatusType) => void;
  isOpen?: boolean;
}) {
  const editableRowRef = useRef<HTMLDivElement>();

  const [isEditing, setIsEditing] = useState<boolean>(isOpen);
  const [currentValue, setValue] = useState<RelationshipStatusType>(initialStatus);

  useEffect(() => {
    if (currentValue !== initialStatus) {
      setValue(initialStatus);
    }
  }, [initialStatus]);

  function onChangeData(selectValue: RelationshipStatusType) {
    setValue(selectValue);
    onChange(initialStatus, selectValue);
    setIsEditing(false);
  }

  return (
    <div
      ref={editableRowRef}
      className={cn('contacts-sidebar-crm-section__selection-row', {
        'contacts-sidebar-crm-section__selection-row--is-editing': isEditing,
      })}
    >
      {isEditing ? (
        <>
          <Select
            value={statusOptions.find((it) => it.value === currentValue)}
            options={statusOptions}
            onChange={(option) => onChangeData(option.value)}
          />
          <div
            className={cn('contacts-sidebar-crm-section__editable-btn')}
            onClick={() => setIsEditing(false)}
          >
            <SVGIcon icon="close" size={11} color="#221CB6" />
          </div>
        </>
      ) : (
        <div className="selected-status">
          <SVGIcon icon="flagStriped" size={16} color="#C4C6CD" />
          {statusOptions[currentValue]?.label}
        </div>
      )}

      {!isEditing && (
        <div className="contacts-sidebar-crm-section__editable-btn-row">
          <div
            className="contacts-sidebar-crm-section__editable-btn"
            onClick={() => setIsEditing(true)}
          >
            <SVGIcon icon="pencil" size={11} color="#221CB6" />
          </div>
        </div>
      )}
    </div>
  );
}

export default ContactsSidebarCRMSectionSelectionField;
