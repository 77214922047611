import { addMonths, addWeeks, format } from 'date-fns';
import { DateRangeDateType, DateRangeNumberType } from '@ts/common.types';

export const getLastSixMonthsRangeInMs = (): DateRangeNumberType => ({
  startDate: +addMonths(new Date(), -6),
  endDate: +new Date(),
});
export const getLastSixMonthsRange = (): DateRangeDateType => ({
  startDate: addMonths(new Date(), -6),
  endDate: new Date(),
});

export const getLastWeekRangeInMs = (): DateRangeNumberType => ({
  startDate: +addWeeks(new Date(), -1),
  endDate: +new Date(),
});

export const transformDateNumberToDate = (date: DateRangeNumberType): DateRangeDateType => ({
  startDate: new Date(date.startDate),
  endDate: new Date(date.endDate),
});

export const transformDateToDateNumberType = (date: DateRangeDateType): DateRangeNumberType => ({
  startDate: +date.startDate,
  endDate: +date.endDate,
});

export const transformDateToString = (date: Date, withTime = false): string =>
  format(date, 'MMM dd, yyyy' + (withTime ? ' h:mm aaa' : ''));

export const dateRangeNumberFormatToString = (
  dateRange: DateRangeNumberType,
  utc = true
): string => {
  if (!dateRange?.startDate && !dateRange?.endDate) {
    return null;
  }

  if (!dateRange?.startDate || !dateRange?.endDate) {
    return transformDateToString(new Date(dateRange.startDate || dateRange.endDate));
  }

  if (utc === false) {
    const timeZone = -(new Date().getTimezoneOffset() / 60);

    const from = new Date(dateRange.startDate);
    from.setHours(from.getHours() - timeZone);

    const to = new Date(dateRange.endDate);
    to.setHours(to.getHours() - timeZone);

    return `
    ${transformDateToString(from)} - 
    ${transformDateToString(to)}
    `;
  }

  return `
    ${transformDateToString(new Date(dateRange.startDate))} - 
    ${transformDateToString(new Date(dateRange.endDate))}
    `;
};
