import { useInfiniteQuery, useQueryClient } from 'react-query';
import { QueryPageHookType } from '@ts/common.types';
import { useMemo, useCallback } from 'react';
import {
  PipelineOpportunityType,
  RelationshipPipelineFilterType,
} from '@ts/relationshipPipeline.types';
import { getStageOpportunitiesWithFilterApi } from '@api/relationshipPipeline';

export const DEALS_WITH_FILTERS_PAGE_LIMIT = 100;
export const DEALS_WITH_FILTERS_QUERY_KEY = 'relationship-stage-opportunities-with-filters';

export default (
  workspaceId: number,
  pipelineId: number,
  searchQuery: string,
  filters: RelationshipPipelineFilterType[]
): QueryPageHookType<PipelineOpportunityType> => {
  const queryClient = useQueryClient();

  const key = useMemo(() => {
    return [DEALS_WITH_FILTERS_QUERY_KEY, pipelineId, workspaceId, searchQuery, filters];
  }, [pipelineId, workspaceId, searchQuery, filters]);

  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage, refetch } =
    useInfiniteQuery<PipelineOpportunityType[]>(
      key,
      ({ pageParam = 0 }) => {
        return getStageOpportunitiesWithFilterApi(
          pipelineId,
          workspaceId,
          pageParam,
          DEALS_WITH_FILTERS_PAGE_LIMIT,
          filters,
          searchQuery
        );
      },
      {
        getNextPageParam: (lastPage: PipelineOpportunityType[], allPages) => {
          if (lastPage?.length < DEALS_WITH_FILTERS_PAGE_LIMIT) {
            return false;
          }
          return allPages.length;
        },
        enabled: false,
        retry: 1,
        refetchOnWindowFocus: false,
        refetchInterval: 5 * 60 * 1000,
      }
    );

  const removeFromCacheById = (id: number) =>
    queryClient.setQueryData(key, ({ pages, pageParams }) => {
      const updatePages = pages.map((page) =>
        page.filter((item: PipelineOpportunityType) => item.id !== id)
      );

      return {
        pageParams,
        pages: updatePages,
      };
    });

  const addToCache = (item: PipelineOpportunityType) =>
    queryClient.setQueryData(key, (cachedData) => {
      // @ts-ignore
      if (cachedData?.pages == null || cachedData?.pages.length === 0) {
        return {
          pageParams: undefined,
          pages: [[item]],
        };
      }
      // @ts-ignore
      const { pages, pageParams } = cachedData;
      const updatedPages = [...pages];
      updatedPages[updatedPages.length - 1] = [...updatedPages[updatedPages.length - 1], item];

      return {
        pageParams,
        pages: updatedPages,
      };
    });

  const updateInCache = (id: number, payload: PipelineOpportunityType) =>
    queryClient.setQueryData(key, ({ pages, pageParams }) => {
      const updatePages = pages.map((page) =>
        page.map((item: PipelineOpportunityType) => {
          if (item.id === id) return payload;
          return item;
        })
      );

      return {
        pageParams,
        pages: updatePages,
      };
    });

  const evictCacheByKey = useCallback(
    (reqPipelineId: number) => {
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === DEALS_WITH_FILTERS_QUERY_KEY && query.queryKey[1] === reqPipelineId,
      });
    },
    [queryClient, key]
  );

  return {
    items: data?.pages?.flat(),
    refetch,
    isLoading,
    isLoadingNextPage: isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    removeItem: removeFromCacheById,
    addItem: addToCache,
    updateItem: updateInCache,
    evictByKey: evictCacheByKey,
  };
};
