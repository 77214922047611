// source: mailbox-template.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
goog.exportSymbol('proto.templates.ApplyTemplateRequest', null, global);
goog.exportSymbol('proto.templates.ChangeStateRequest', null, global);
goog.exportSymbol('proto.templates.SaveAsNewTemplateRequest', null, global);
goog.exportSymbol('proto.templates.TemplateContainerResponse', null, global);
goog.exportSymbol('proto.templates.TemplateFilterRequest', null, global);
goog.exportSymbol('proto.templates.TemplateRequest', null, global);
goog.exportSymbol('proto.templates.TemplateResponse', null, global);
goog.exportSymbol('proto.templates.TemplateScope', null, global);
goog.exportSymbol('proto.templates.TemplateStep', null, global);
goog.exportSymbol('proto.templates.TemplateStepThread', null, global);
goog.exportSymbol('proto.templates.TemplateWithStepsRequest', null, global);
goog.exportSymbol('proto.templates.UpdateStepDelayRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templates.TemplateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templates.TemplateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templates.TemplateRequest.displayName = 'proto.templates.TemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templates.TemplateWithStepsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.templates.TemplateWithStepsRequest.repeatedFields_, null);
};
goog.inherits(proto.templates.TemplateWithStepsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templates.TemplateWithStepsRequest.displayName = 'proto.templates.TemplateWithStepsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templates.TemplateFilterRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.templates.TemplateFilterRequest.repeatedFields_, null);
};
goog.inherits(proto.templates.TemplateFilterRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templates.TemplateFilterRequest.displayName = 'proto.templates.TemplateFilterRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templates.ApplyTemplateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templates.ApplyTemplateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templates.ApplyTemplateRequest.displayName = 'proto.templates.ApplyTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templates.TemplateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templates.TemplateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templates.TemplateResponse.displayName = 'proto.templates.TemplateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templates.TemplateContainerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.templates.TemplateContainerResponse.repeatedFields_, null);
};
goog.inherits(proto.templates.TemplateContainerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templates.TemplateContainerResponse.displayName = 'proto.templates.TemplateContainerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templates.TemplateStep = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templates.TemplateStep, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templates.TemplateStep.displayName = 'proto.templates.TemplateStep';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templates.UpdateStepDelayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templates.UpdateStepDelayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templates.UpdateStepDelayRequest.displayName = 'proto.templates.UpdateStepDelayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templates.TemplateStepThread = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.templates.TemplateStepThread.repeatedFields_, null);
};
goog.inherits(proto.templates.TemplateStepThread, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templates.TemplateStepThread.displayName = 'proto.templates.TemplateStepThread';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templates.SaveAsNewTemplateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templates.SaveAsNewTemplateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templates.SaveAsNewTemplateRequest.displayName = 'proto.templates.SaveAsNewTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templates.ChangeStateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templates.ChangeStateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templates.ChangeStateRequest.displayName = 'proto.templates.ChangeStateRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templates.TemplateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.templates.TemplateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templates.TemplateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templates.TemplateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    scope: jspb.Message.getFieldWithDefault(msg, 4, 0),
    enabledUnsubscribeContent: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    enabledReferralContent: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templates.TemplateRequest}
 */
proto.templates.TemplateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templates.TemplateRequest;
  return proto.templates.TemplateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templates.TemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templates.TemplateRequest}
 */
proto.templates.TemplateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {!proto.templates.TemplateScope} */ (reader.readEnum());
      msg.setScope(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabledUnsubscribeContent(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabledReferralContent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templates.TemplateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templates.TemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templates.TemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templates.TemplateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getScope();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getEnabledUnsubscribeContent();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getEnabledReferralContent();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.templates.TemplateRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.templates.TemplateRequest} returns this
 */
proto.templates.TemplateRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 workspace_id = 2;
 * @return {number}
 */
proto.templates.TemplateRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.templates.TemplateRequest} returns this
 */
proto.templates.TemplateRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.templates.TemplateRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templates.TemplateRequest} returns this
 */
proto.templates.TemplateRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional TemplateScope scope = 4;
 * @return {!proto.templates.TemplateScope}
 */
proto.templates.TemplateRequest.prototype.getScope = function() {
  return /** @type {!proto.templates.TemplateScope} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.templates.TemplateScope} value
 * @return {!proto.templates.TemplateRequest} returns this
 */
proto.templates.TemplateRequest.prototype.setScope = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional bool enabled_unsubscribe_content = 5;
 * @return {boolean}
 */
proto.templates.TemplateRequest.prototype.getEnabledUnsubscribeContent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.templates.TemplateRequest} returns this
 */
proto.templates.TemplateRequest.prototype.setEnabledUnsubscribeContent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool enabled_referral_content = 6;
 * @return {boolean}
 */
proto.templates.TemplateRequest.prototype.getEnabledReferralContent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.templates.TemplateRequest} returns this
 */
proto.templates.TemplateRequest.prototype.setEnabledReferralContent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.templates.TemplateWithStepsRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templates.TemplateWithStepsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.templates.TemplateWithStepsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templates.TemplateWithStepsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templates.TemplateWithStepsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    scope: jspb.Message.getFieldWithDefault(msg, 4, 0),
    stepsList: jspb.Message.toObjectList(msg.getStepsList(),
    proto.templates.TemplateStep.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templates.TemplateWithStepsRequest}
 */
proto.templates.TemplateWithStepsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templates.TemplateWithStepsRequest;
  return proto.templates.TemplateWithStepsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templates.TemplateWithStepsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templates.TemplateWithStepsRequest}
 */
proto.templates.TemplateWithStepsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {!proto.templates.TemplateScope} */ (reader.readEnum());
      msg.setScope(value);
      break;
    case 5:
      var value = new proto.templates.TemplateStep;
      reader.readMessage(value,proto.templates.TemplateStep.deserializeBinaryFromReader);
      msg.addSteps(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templates.TemplateWithStepsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templates.TemplateWithStepsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templates.TemplateWithStepsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templates.TemplateWithStepsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getScope();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getStepsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.templates.TemplateStep.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.templates.TemplateWithStepsRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.templates.TemplateWithStepsRequest} returns this
 */
proto.templates.TemplateWithStepsRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 workspace_id = 2;
 * @return {number}
 */
proto.templates.TemplateWithStepsRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.templates.TemplateWithStepsRequest} returns this
 */
proto.templates.TemplateWithStepsRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.templates.TemplateWithStepsRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templates.TemplateWithStepsRequest} returns this
 */
proto.templates.TemplateWithStepsRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional TemplateScope scope = 4;
 * @return {!proto.templates.TemplateScope}
 */
proto.templates.TemplateWithStepsRequest.prototype.getScope = function() {
  return /** @type {!proto.templates.TemplateScope} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.templates.TemplateScope} value
 * @return {!proto.templates.TemplateWithStepsRequest} returns this
 */
proto.templates.TemplateWithStepsRequest.prototype.setScope = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * repeated TemplateStep steps = 5;
 * @return {!Array<!proto.templates.TemplateStep>}
 */
proto.templates.TemplateWithStepsRequest.prototype.getStepsList = function() {
  return /** @type{!Array<!proto.templates.TemplateStep>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.templates.TemplateStep, 5));
};


/**
 * @param {!Array<!proto.templates.TemplateStep>} value
 * @return {!proto.templates.TemplateWithStepsRequest} returns this
*/
proto.templates.TemplateWithStepsRequest.prototype.setStepsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.templates.TemplateStep=} opt_value
 * @param {number=} opt_index
 * @return {!proto.templates.TemplateStep}
 */
proto.templates.TemplateWithStepsRequest.prototype.addSteps = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.templates.TemplateStep, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.templates.TemplateWithStepsRequest} returns this
 */
proto.templates.TemplateWithStepsRequest.prototype.clearStepsList = function() {
  return this.setStepsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.templates.TemplateFilterRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templates.TemplateFilterRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.templates.TemplateFilterRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templates.TemplateFilterRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templates.TemplateFilterRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    titleQuery: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ownerIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    workspaceId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    page: jspb.Message.getFieldWithDefault(msg, 4, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templates.TemplateFilterRequest}
 */
proto.templates.TemplateFilterRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templates.TemplateFilterRequest;
  return proto.templates.TemplateFilterRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templates.TemplateFilterRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templates.TemplateFilterRequest}
 */
proto.templates.TemplateFilterRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitleQuery(value);
      break;
    case 2:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setOwnerIdsList(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templates.TemplateFilterRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templates.TemplateFilterRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templates.TemplateFilterRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templates.TemplateFilterRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitleQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOwnerIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional string title_query = 1;
 * @return {string}
 */
proto.templates.TemplateFilterRequest.prototype.getTitleQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templates.TemplateFilterRequest} returns this
 */
proto.templates.TemplateFilterRequest.prototype.setTitleQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated int64 owner_ids = 2;
 * @return {!Array<number>}
 */
proto.templates.TemplateFilterRequest.prototype.getOwnerIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.templates.TemplateFilterRequest} returns this
 */
proto.templates.TemplateFilterRequest.prototype.setOwnerIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.templates.TemplateFilterRequest} returns this
 */
proto.templates.TemplateFilterRequest.prototype.addOwnerIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.templates.TemplateFilterRequest} returns this
 */
proto.templates.TemplateFilterRequest.prototype.clearOwnerIdsList = function() {
  return this.setOwnerIdsList([]);
};


/**
 * optional int64 workspace_id = 3;
 * @return {number}
 */
proto.templates.TemplateFilterRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.templates.TemplateFilterRequest} returns this
 */
proto.templates.TemplateFilterRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 page = 4;
 * @return {number}
 */
proto.templates.TemplateFilterRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.templates.TemplateFilterRequest} returns this
 */
proto.templates.TemplateFilterRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 limit = 5;
 * @return {number}
 */
proto.templates.TemplateFilterRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.templates.TemplateFilterRequest} returns this
 */
proto.templates.TemplateFilterRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templates.ApplyTemplateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.templates.ApplyTemplateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templates.ApplyTemplateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templates.ApplyTemplateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    fromTemplateId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    toTemplateId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templates.ApplyTemplateRequest}
 */
proto.templates.ApplyTemplateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templates.ApplyTemplateRequest;
  return proto.templates.ApplyTemplateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templates.ApplyTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templates.ApplyTemplateRequest}
 */
proto.templates.ApplyTemplateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFromTemplateId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setToTemplateId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templates.ApplyTemplateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templates.ApplyTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templates.ApplyTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templates.ApplyTemplateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getFromTemplateId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getToTemplateId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 workspace_id = 1;
 * @return {number}
 */
proto.templates.ApplyTemplateRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.templates.ApplyTemplateRequest} returns this
 */
proto.templates.ApplyTemplateRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 from_template_id = 2;
 * @return {number}
 */
proto.templates.ApplyTemplateRequest.prototype.getFromTemplateId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.templates.ApplyTemplateRequest} returns this
 */
proto.templates.ApplyTemplateRequest.prototype.setFromTemplateId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 to_template_id = 3;
 * @return {number}
 */
proto.templates.ApplyTemplateRequest.prototype.getToTemplateId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.templates.ApplyTemplateRequest} returns this
 */
proto.templates.ApplyTemplateRequest.prototype.setToTemplateId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templates.TemplateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.templates.TemplateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templates.TemplateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templates.TemplateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    ownerId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    ownerAvatar: jspb.Message.getFieldWithDefault(msg, 4, ""),
    title: jspb.Message.getFieldWithDefault(msg, 5, ""),
    scope: jspb.Message.getFieldWithDefault(msg, 6, 0),
    sentNumber: jspb.Message.getFieldWithDefault(msg, 7, 0),
    openRate: jspb.Message.getFieldWithDefault(msg, 8, 0),
    replyRate: jspb.Message.getFieldWithDefault(msg, 9, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 10, 0),
    subject: jspb.Message.getFieldWithDefault(msg, 11, ""),
    enabledUnsubscribeContent: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    enabledReferralContent: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    totalItems: jspb.Message.getFieldWithDefault(msg, 14, 0),
    ownerName: jspb.Message.getFieldWithDefault(msg, 15, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templates.TemplateResponse}
 */
proto.templates.TemplateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templates.TemplateResponse;
  return proto.templates.TemplateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templates.TemplateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templates.TemplateResponse}
 */
proto.templates.TemplateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOwnerId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerAvatar(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 6:
      var value = /** @type {!proto.templates.TemplateScope} */ (reader.readEnum());
      msg.setScope(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSentNumber(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOpenRate(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReplyRate(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubject(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabledUnsubscribeContent(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabledReferralContent(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalItems(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templates.TemplateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templates.TemplateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templates.TemplateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templates.TemplateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getOwnerId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getOwnerAvatar();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getScope();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getSentNumber();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getOpenRate();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getReplyRate();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getSubject();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getEnabledUnsubscribeContent();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getEnabledReferralContent();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getTotalItems();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getOwnerName();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.templates.TemplateResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.templates.TemplateResponse} returns this
 */
proto.templates.TemplateResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 workspace_id = 2;
 * @return {number}
 */
proto.templates.TemplateResponse.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.templates.TemplateResponse} returns this
 */
proto.templates.TemplateResponse.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 owner_id = 3;
 * @return {number}
 */
proto.templates.TemplateResponse.prototype.getOwnerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.templates.TemplateResponse} returns this
 */
proto.templates.TemplateResponse.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string owner_avatar = 4;
 * @return {string}
 */
proto.templates.TemplateResponse.prototype.getOwnerAvatar = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.templates.TemplateResponse} returns this
 */
proto.templates.TemplateResponse.prototype.setOwnerAvatar = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string title = 5;
 * @return {string}
 */
proto.templates.TemplateResponse.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.templates.TemplateResponse} returns this
 */
proto.templates.TemplateResponse.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional TemplateScope scope = 6;
 * @return {!proto.templates.TemplateScope}
 */
proto.templates.TemplateResponse.prototype.getScope = function() {
  return /** @type {!proto.templates.TemplateScope} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.templates.TemplateScope} value
 * @return {!proto.templates.TemplateResponse} returns this
 */
proto.templates.TemplateResponse.prototype.setScope = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional int32 sent_number = 7;
 * @return {number}
 */
proto.templates.TemplateResponse.prototype.getSentNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.templates.TemplateResponse} returns this
 */
proto.templates.TemplateResponse.prototype.setSentNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 open_rate = 8;
 * @return {number}
 */
proto.templates.TemplateResponse.prototype.getOpenRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.templates.TemplateResponse} returns this
 */
proto.templates.TemplateResponse.prototype.setOpenRate = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 reply_rate = 9;
 * @return {number}
 */
proto.templates.TemplateResponse.prototype.getReplyRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.templates.TemplateResponse} returns this
 */
proto.templates.TemplateResponse.prototype.setReplyRate = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 created_at = 10;
 * @return {number}
 */
proto.templates.TemplateResponse.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.templates.TemplateResponse} returns this
 */
proto.templates.TemplateResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string subject = 11;
 * @return {string}
 */
proto.templates.TemplateResponse.prototype.getSubject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.templates.TemplateResponse} returns this
 */
proto.templates.TemplateResponse.prototype.setSubject = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional bool enabled_unsubscribe_content = 12;
 * @return {boolean}
 */
proto.templates.TemplateResponse.prototype.getEnabledUnsubscribeContent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.templates.TemplateResponse} returns this
 */
proto.templates.TemplateResponse.prototype.setEnabledUnsubscribeContent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool enabled_referral_content = 13;
 * @return {boolean}
 */
proto.templates.TemplateResponse.prototype.getEnabledReferralContent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.templates.TemplateResponse} returns this
 */
proto.templates.TemplateResponse.prototype.setEnabledReferralContent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional int32 total_items = 14;
 * @return {number}
 */
proto.templates.TemplateResponse.prototype.getTotalItems = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.templates.TemplateResponse} returns this
 */
proto.templates.TemplateResponse.prototype.setTotalItems = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional string owner_name = 15;
 * @return {string}
 */
proto.templates.TemplateResponse.prototype.getOwnerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.templates.TemplateResponse} returns this
 */
proto.templates.TemplateResponse.prototype.setOwnerName = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.templates.TemplateContainerResponse.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templates.TemplateContainerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.templates.TemplateContainerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templates.TemplateContainerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templates.TemplateContainerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 4, 0),
    enabledUnsubscribeContent: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    enabledReferralContent: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    ownerId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    ownerAvatar: jspb.Message.getFieldWithDefault(msg, 8, ""),
    ownerName: jspb.Message.getFieldWithDefault(msg, 9, ""),
    stepsList: jspb.Message.toObjectList(msg.getStepsList(),
    proto.templates.TemplateStep.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templates.TemplateContainerResponse}
 */
proto.templates.TemplateContainerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templates.TemplateContainerResponse;
  return proto.templates.TemplateContainerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templates.TemplateContainerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templates.TemplateContainerResponse}
 */
proto.templates.TemplateContainerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabledUnsubscribeContent(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabledReferralContent(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOwnerId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerAvatar(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerName(value);
      break;
    case 10:
      var value = new proto.templates.TemplateStep;
      reader.readMessage(value,proto.templates.TemplateStep.deserializeBinaryFromReader);
      msg.addSteps(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templates.TemplateContainerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templates.TemplateContainerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templates.TemplateContainerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templates.TemplateContainerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getEnabledUnsubscribeContent();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getEnabledReferralContent();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getOwnerId();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getOwnerAvatar();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getOwnerName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getStepsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.templates.TemplateStep.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.templates.TemplateContainerResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.templates.TemplateContainerResponse} returns this
 */
proto.templates.TemplateContainerResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 workspace_id = 2;
 * @return {number}
 */
proto.templates.TemplateContainerResponse.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.templates.TemplateContainerResponse} returns this
 */
proto.templates.TemplateContainerResponse.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.templates.TemplateContainerResponse.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templates.TemplateContainerResponse} returns this
 */
proto.templates.TemplateContainerResponse.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 created_at = 4;
 * @return {number}
 */
proto.templates.TemplateContainerResponse.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.templates.TemplateContainerResponse} returns this
 */
proto.templates.TemplateContainerResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool enabled_unsubscribe_content = 5;
 * @return {boolean}
 */
proto.templates.TemplateContainerResponse.prototype.getEnabledUnsubscribeContent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.templates.TemplateContainerResponse} returns this
 */
proto.templates.TemplateContainerResponse.prototype.setEnabledUnsubscribeContent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool enabled_referral_content = 6;
 * @return {boolean}
 */
proto.templates.TemplateContainerResponse.prototype.getEnabledReferralContent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.templates.TemplateContainerResponse} returns this
 */
proto.templates.TemplateContainerResponse.prototype.setEnabledReferralContent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional int64 owner_id = 7;
 * @return {number}
 */
proto.templates.TemplateContainerResponse.prototype.getOwnerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.templates.TemplateContainerResponse} returns this
 */
proto.templates.TemplateContainerResponse.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string owner_avatar = 8;
 * @return {string}
 */
proto.templates.TemplateContainerResponse.prototype.getOwnerAvatar = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.templates.TemplateContainerResponse} returns this
 */
proto.templates.TemplateContainerResponse.prototype.setOwnerAvatar = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string owner_name = 9;
 * @return {string}
 */
proto.templates.TemplateContainerResponse.prototype.getOwnerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.templates.TemplateContainerResponse} returns this
 */
proto.templates.TemplateContainerResponse.prototype.setOwnerName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * repeated TemplateStep steps = 10;
 * @return {!Array<!proto.templates.TemplateStep>}
 */
proto.templates.TemplateContainerResponse.prototype.getStepsList = function() {
  return /** @type{!Array<!proto.templates.TemplateStep>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.templates.TemplateStep, 10));
};


/**
 * @param {!Array<!proto.templates.TemplateStep>} value
 * @return {!proto.templates.TemplateContainerResponse} returns this
*/
proto.templates.TemplateContainerResponse.prototype.setStepsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.templates.TemplateStep=} opt_value
 * @param {number=} opt_index
 * @return {!proto.templates.TemplateStep}
 */
proto.templates.TemplateContainerResponse.prototype.addSteps = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.templates.TemplateStep, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.templates.TemplateContainerResponse} returns this
 */
proto.templates.TemplateContainerResponse.prototype.clearStepsList = function() {
  return this.setStepsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templates.TemplateStep.prototype.toObject = function(opt_includeInstance) {
  return proto.templates.TemplateStep.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templates.TemplateStep} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templates.TemplateStep.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    templateId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    order: jspb.Message.getFieldWithDefault(msg, 3, 0),
    sendDelay: jspb.Message.getFieldWithDefault(msg, 4, 0),
    enabledThreads: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    threadA: (f = msg.getThreadA()) && proto.templates.TemplateStepThread.toObject(includeInstance, f),
    threadB: (f = msg.getThreadB()) && proto.templates.TemplateStepThread.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templates.TemplateStep}
 */
proto.templates.TemplateStep.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templates.TemplateStep;
  return proto.templates.TemplateStep.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templates.TemplateStep} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templates.TemplateStep}
 */
proto.templates.TemplateStep.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTemplateId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSendDelay(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabledThreads(value);
      break;
    case 6:
      var value = new proto.templates.TemplateStepThread;
      reader.readMessage(value,proto.templates.TemplateStepThread.deserializeBinaryFromReader);
      msg.setThreadA(value);
      break;
    case 7:
      var value = new proto.templates.TemplateStepThread;
      reader.readMessage(value,proto.templates.TemplateStepThread.deserializeBinaryFromReader);
      msg.setThreadB(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templates.TemplateStep.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templates.TemplateStep.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templates.TemplateStep} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templates.TemplateStep.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTemplateId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getSendDelay();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getEnabledThreads();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getThreadA();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.templates.TemplateStepThread.serializeBinaryToWriter
    );
  }
  f = message.getThreadB();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.templates.TemplateStepThread.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.templates.TemplateStep.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.templates.TemplateStep} returns this
 */
proto.templates.TemplateStep.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 template_id = 2;
 * @return {number}
 */
proto.templates.TemplateStep.prototype.getTemplateId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.templates.TemplateStep} returns this
 */
proto.templates.TemplateStep.prototype.setTemplateId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 order = 3;
 * @return {number}
 */
proto.templates.TemplateStep.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.templates.TemplateStep} returns this
 */
proto.templates.TemplateStep.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 send_delay = 4;
 * @return {number}
 */
proto.templates.TemplateStep.prototype.getSendDelay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.templates.TemplateStep} returns this
 */
proto.templates.TemplateStep.prototype.setSendDelay = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool enabled_threads = 5;
 * @return {boolean}
 */
proto.templates.TemplateStep.prototype.getEnabledThreads = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.templates.TemplateStep} returns this
 */
proto.templates.TemplateStep.prototype.setEnabledThreads = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional TemplateStepThread thread_a = 6;
 * @return {?proto.templates.TemplateStepThread}
 */
proto.templates.TemplateStep.prototype.getThreadA = function() {
  return /** @type{?proto.templates.TemplateStepThread} */ (
    jspb.Message.getWrapperField(this, proto.templates.TemplateStepThread, 6));
};


/**
 * @param {?proto.templates.TemplateStepThread|undefined} value
 * @return {!proto.templates.TemplateStep} returns this
*/
proto.templates.TemplateStep.prototype.setThreadA = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.templates.TemplateStep} returns this
 */
proto.templates.TemplateStep.prototype.clearThreadA = function() {
  return this.setThreadA(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.templates.TemplateStep.prototype.hasThreadA = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional TemplateStepThread thread_b = 7;
 * @return {?proto.templates.TemplateStepThread}
 */
proto.templates.TemplateStep.prototype.getThreadB = function() {
  return /** @type{?proto.templates.TemplateStepThread} */ (
    jspb.Message.getWrapperField(this, proto.templates.TemplateStepThread, 7));
};


/**
 * @param {?proto.templates.TemplateStepThread|undefined} value
 * @return {!proto.templates.TemplateStep} returns this
*/
proto.templates.TemplateStep.prototype.setThreadB = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.templates.TemplateStep} returns this
 */
proto.templates.TemplateStep.prototype.clearThreadB = function() {
  return this.setThreadB(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.templates.TemplateStep.prototype.hasThreadB = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templates.UpdateStepDelayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.templates.UpdateStepDelayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templates.UpdateStepDelayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templates.UpdateStepDelayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    templateId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    sendDelay: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templates.UpdateStepDelayRequest}
 */
proto.templates.UpdateStepDelayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templates.UpdateStepDelayRequest;
  return proto.templates.UpdateStepDelayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templates.UpdateStepDelayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templates.UpdateStepDelayRequest}
 */
proto.templates.UpdateStepDelayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTemplateId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSendDelay(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templates.UpdateStepDelayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templates.UpdateStepDelayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templates.UpdateStepDelayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templates.UpdateStepDelayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTemplateId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSendDelay();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.templates.UpdateStepDelayRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.templates.UpdateStepDelayRequest} returns this
 */
proto.templates.UpdateStepDelayRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 template_id = 2;
 * @return {number}
 */
proto.templates.UpdateStepDelayRequest.prototype.getTemplateId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.templates.UpdateStepDelayRequest} returns this
 */
proto.templates.UpdateStepDelayRequest.prototype.setTemplateId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 send_delay = 3;
 * @return {number}
 */
proto.templates.UpdateStepDelayRequest.prototype.getSendDelay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.templates.UpdateStepDelayRequest} returns this
 */
proto.templates.UpdateStepDelayRequest.prototype.setSendDelay = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.templates.TemplateStepThread.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templates.TemplateStepThread.prototype.toObject = function(opt_includeInstance) {
  return proto.templates.TemplateStepThread.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templates.TemplateStepThread} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templates.TemplateStepThread.toObject = function(includeInstance, msg) {
  var f, obj = {
    subject: jspb.Message.getFieldWithDefault(msg, 1, ""),
    content: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sameThread: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    attachmentsList: jspb.Message.toObjectList(msg.getAttachmentsList(),
    common_pb.FileAttachment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templates.TemplateStepThread}
 */
proto.templates.TemplateStepThread.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templates.TemplateStepThread;
  return proto.templates.TemplateStepThread.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templates.TemplateStepThread} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templates.TemplateStepThread}
 */
proto.templates.TemplateStepThread.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubject(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSameThread(value);
      break;
    case 4:
      var value = new common_pb.FileAttachment;
      reader.readMessage(value,common_pb.FileAttachment.deserializeBinaryFromReader);
      msg.addAttachments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templates.TemplateStepThread.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templates.TemplateStepThread.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templates.TemplateStepThread} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templates.TemplateStepThread.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubject();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSameThread();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getAttachmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      common_pb.FileAttachment.serializeBinaryToWriter
    );
  }
};


/**
 * optional string subject = 1;
 * @return {string}
 */
proto.templates.TemplateStepThread.prototype.getSubject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templates.TemplateStepThread} returns this
 */
proto.templates.TemplateStepThread.prototype.setSubject = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string content = 2;
 * @return {string}
 */
proto.templates.TemplateStepThread.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templates.TemplateStepThread} returns this
 */
proto.templates.TemplateStepThread.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool same_thread = 3;
 * @return {boolean}
 */
proto.templates.TemplateStepThread.prototype.getSameThread = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.templates.TemplateStepThread} returns this
 */
proto.templates.TemplateStepThread.prototype.setSameThread = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * repeated common.FileAttachment attachments = 4;
 * @return {!Array<!proto.common.FileAttachment>}
 */
proto.templates.TemplateStepThread.prototype.getAttachmentsList = function() {
  return /** @type{!Array<!proto.common.FileAttachment>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.FileAttachment, 4));
};


/**
 * @param {!Array<!proto.common.FileAttachment>} value
 * @return {!proto.templates.TemplateStepThread} returns this
*/
proto.templates.TemplateStepThread.prototype.setAttachmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.common.FileAttachment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.FileAttachment}
 */
proto.templates.TemplateStepThread.prototype.addAttachments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.common.FileAttachment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.templates.TemplateStepThread} returns this
 */
proto.templates.TemplateStepThread.prototype.clearAttachmentsList = function() {
  return this.setAttachmentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templates.SaveAsNewTemplateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.templates.SaveAsNewTemplateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templates.SaveAsNewTemplateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templates.SaveAsNewTemplateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    copyFromId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    scope: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templates.SaveAsNewTemplateRequest}
 */
proto.templates.SaveAsNewTemplateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templates.SaveAsNewTemplateRequest;
  return proto.templates.SaveAsNewTemplateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templates.SaveAsNewTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templates.SaveAsNewTemplateRequest}
 */
proto.templates.SaveAsNewTemplateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCopyFromId(value);
      break;
    case 4:
      var value = /** @type {!proto.templates.TemplateScope} */ (reader.readEnum());
      msg.setScope(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templates.SaveAsNewTemplateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templates.SaveAsNewTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templates.SaveAsNewTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templates.SaveAsNewTemplateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCopyFromId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getScope();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.templates.SaveAsNewTemplateRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templates.SaveAsNewTemplateRequest} returns this
 */
proto.templates.SaveAsNewTemplateRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 workspace_id = 2;
 * @return {number}
 */
proto.templates.SaveAsNewTemplateRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.templates.SaveAsNewTemplateRequest} returns this
 */
proto.templates.SaveAsNewTemplateRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 copy_from_id = 3;
 * @return {number}
 */
proto.templates.SaveAsNewTemplateRequest.prototype.getCopyFromId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.templates.SaveAsNewTemplateRequest} returns this
 */
proto.templates.SaveAsNewTemplateRequest.prototype.setCopyFromId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional TemplateScope scope = 4;
 * @return {!proto.templates.TemplateScope}
 */
proto.templates.SaveAsNewTemplateRequest.prototype.getScope = function() {
  return /** @type {!proto.templates.TemplateScope} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.templates.TemplateScope} value
 * @return {!proto.templates.SaveAsNewTemplateRequest} returns this
 */
proto.templates.SaveAsNewTemplateRequest.prototype.setScope = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templates.ChangeStateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.templates.ChangeStateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templates.ChangeStateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templates.ChangeStateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    enabled: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templates.ChangeStateRequest}
 */
proto.templates.ChangeStateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templates.ChangeStateRequest;
  return proto.templates.ChangeStateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templates.ChangeStateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templates.ChangeStateRequest}
 */
proto.templates.ChangeStateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templates.ChangeStateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templates.ChangeStateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templates.ChangeStateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templates.ChangeStateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.templates.ChangeStateRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.templates.ChangeStateRequest} returns this
 */
proto.templates.ChangeStateRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool enabled = 2;
 * @return {boolean}
 */
proto.templates.ChangeStateRequest.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.templates.ChangeStateRequest} returns this
 */
proto.templates.ChangeStateRequest.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.templates.TemplateScope = {
  CAMPAIGN: 0,
  ORGANIZATION: 1,
  RESPONA: 2
};

goog.object.extend(exports, proto.templates);
