import { workspaceTitleMaxLength } from '@utils/validations';

export default [
  {
    key: 'workspaceName',
    title: 'Workspace Name',
    maxLength: workspaceTitleMaxLength,
    additional: 'Most people use the name of their department or client.',
    isRequired: true,
  },
  {
    key: 'domain',
    title: 'Domain (recommended)',
  },
];
