import AutomationPersonalizationServiceService from '../../../respona_api/generated/automation-personalization_pb_service';

declare const API_HOST: string;

const service = (function () {
  let instance = null;

  return {
    getInstance:
      (): AutomationPersonalizationServiceService.PersonalizationAutomationServiceClient => {
        if (!instance) {
          // @ts-ignore
          instance =
            new AutomationPersonalizationServiceService.PersonalizationAutomationServiceClient(
              API_HOST
            );
        }

        return instance;
      },
  };
})();

export default service;
