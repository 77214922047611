import loadingStatuses from 'src/constants/loadingStatuses';

export const checkIsAnyNotLoaded = (...lsDeps: string[]): boolean =>
  lsDeps.some((ls) => ls !== loadingStatuses.LOADED);
export const checkIsAnyPending = (...lsDeps: string[]): boolean =>
  lsDeps.some((ls) => ls === loadingStatuses.PENDING);
export const checkIsAnyLoading = (...lsDeps: string[]): boolean =>
  lsDeps.some((ls) => ls === loadingStatuses.INITED);
export const checkIsAllLoaded = (...lsDeps: string[]): boolean =>
  lsDeps.every((ls) => ls === loadingStatuses.LOADED);
