import { useQuery, useQueryClient } from 'react-query';
import { useMemo } from 'react';
import { QueryCollectionHookType } from '@ts/common.types';
import { PipelineOpportunitySuggestionType } from '@ts/relationshipPipeline.types';
import { getStageOpportunitySuggestionsApi } from '@api/relationshipPipeline';

export default (
  workspaceId: number,
  companyTitle: string
): QueryCollectionHookType<PipelineOpportunitySuggestionType> => {
  const queryClient = useQueryClient();

  const key = useMemo(
    () => ['relationship-records-suggestions', workspaceId, companyTitle],
    [workspaceId, companyTitle]
  );

  const queryResult = useQuery<PipelineOpportunitySuggestionType[]>(
    key,
    () => getStageOpportunitySuggestionsApi(companyTitle, workspaceId),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      placeholderData: [],
      staleTime: 15 * 60 * 1000,
    }
  );

  const { data, isFetching, isLoading, isRefetching, refetch, isSuccess } = queryResult;

  const updateCache = (
    updateFn: (
      cachedData: PipelineOpportunitySuggestionType[]
    ) => PipelineOpportunitySuggestionType[]
  ) => {
    queryClient.setQueryData<PipelineOpportunitySuggestionType[]>(key, updateFn);
  };

  const removeItem = (id: number) => {
    updateCache((cachedData) => cachedData.filter((item) => item.id !== id));
  };

  const addItem = (item: PipelineOpportunitySuggestionType) => {
    updateCache((cachedData) => (cachedData ? [...cachedData, item] : [item]));
  };

  const updateItem = (id: number, payload: PipelineOpportunitySuggestionType) => {
    updateCache((cachedData) =>
      cachedData.map((item) => (item.id === id ? { ...item, ...payload } : item))
    );
  };

  const evictByKey = (id: number) => queryClient.removeQueries(key);

  return {
    items: data,
    refetch,
    isLoading: isFetching || isLoading || isRefetching,
    isEmpty: isSuccess && data?.length === 0,
    removeItem,
    addItem,
    updateItem,
    evictByKey,
  };
};
