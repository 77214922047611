import {
  PeopleWebsiteFilterField,
  PeopleWebsiteFilterFieldMap,
} from 'respona_api/generated/people-website_pb';

type FilterFieldType = {
  label: string;
  value: PeopleWebsiteFilterFieldMap[keyof PeopleWebsiteFilterFieldMap];
  type: 'string' | 'boolean' | 'number' | 'enum' | 'date';
  inputOptions?: { value: string | number; label: string }[];
};

export const getWebsiteFilterFields = (
  members: { value: string | number; label: string }[] = [],
  tags: { value: string | number; label: string }[] = [],
  campaigns: { value: string | number; label: string }[] = [],
  workspaces: { value: string | number; label: string }[] = []
): FilterFieldType[] => [
  {
    label: 'Title',
    type: 'string',
    value: PeopleWebsiteFilterField.STRING_TITLE,
  },
  {
    label: 'Website',
    type: 'string',
    value: PeopleWebsiteFilterField.STRING_WEBSITE,
  },
  {
    label: 'Number of people',
    type: 'number',
    value: PeopleWebsiteFilterField.NUMBER_WEBSITE_MEMBERS,
  },
  {
    label: 'Owner',
    value: PeopleWebsiteFilterField.DROPDOWN_WEBSITE_OWNER_ID,
    type: 'enum',
    inputOptions: members,
  },
  {
    label: 'Created on',
    value: PeopleWebsiteFilterField.DATE_WEBSITE_CREATED_AT,
    type: 'date',
  },
  {
    label: 'Tag',
    value: PeopleWebsiteFilterField.DROPDOWN_WEBSITE_TAG,
    type: 'enum',
    inputOptions: tags,
  },
  {
    label: 'Campaign',
    value: PeopleWebsiteFilterField.DROPDOWN_PERSON_CAMPAIGN_ID,
    type: 'enum',
    inputOptions: campaigns,
  },
  {
    label: 'Workspace',
    value: PeopleWebsiteFilterField.DROPDOWN_WEBSITE_WORKSPACE_ID,
    type: 'enum',
    inputOptions: workspaces,
  },
];
