import React from 'react';

import CopyIcon from '@uikit/CopyIcon/CopyIcon';

// TODO: we can make one component but change only tow words here and in Bing brother

const GoogleAdvancedSearchListTable = (): JSX.Element => {
  return (
    <table className="advanced-search-list-table">
      <thead className="advanced-search-list-table__thead">
        <tr className="advanced-search-list-table__row">
          <th className="advanced-search-list-table__th">Keyword</th>
          <th className="advanced-search-list-table__th">Description</th>
          <th className="advanced-search-list-table__th">Example</th>
        </tr>
      </thead>
      <tbody>
        <tr className="advanced-search-list-table__row">
          <td className="advanced-search-list-table__td">
            <span className="advanced-search-list-table__td-content">
              <CopyIcon text="" />
              &quot;&quot;
            </span>
          </td>
          <td className="advanced-search-list-table__td">
            Returns only webpages that mention a specific word or phrase.
          </td>
          <td className="advanced-search-list-table__td">
            To find webpages that mention link building, type &quot;link building&quot;
          </td>
        </tr>
        <tr className="advanced-search-list-table__row">
          <td className="advanced-search-list-table__td">
            <span className="advanced-search-list-table__td-content">
              <CopyIcon text="-" />-
            </span>
          </td>
          <td className="advanced-search-list-table__td">
            Excludes a word or an operator from search results.
          </td>
          <td className="advanced-search-list-table__td">
            To exlclude webpages that mention SEO, type -&quot;seo&quot;. It works with various
            search operators, for example -intitle:&quot;seo&quot; exludes results that mention SEO
            in the title
          </td>
        </tr>
        <tr className="advanced-search-list-table__row">
          <td className="advanced-search-list-table__td">
            <span className="advanced-search-list-table__td-content">
              <CopyIcon text="OR" />
              OR
            </span>
          </td>
          <td className="advanced-search-list-table__td">
            Boolean search function that allows you to run OR searches.
          </td>
          <td className="advanced-search-list-table__td">
            To find webpages that mention link building or seo, type &quot;link building&quot; OR
            &quot;SEO&quot;. Also works with various search operators
          </td>
        </tr>
        <tr className="advanced-search-list-table__row">
          <td className="advanced-search-list-table__td">
            <span className="advanced-search-list-table__td-content">
              <CopyIcon text="( )" />( )
            </span>
          </td>
          <td className="advanced-search-list-table__td">
            Use parentheses to group operators and control the order in which they execute.
          </td>
          <td className="advanced-search-list-table__td">
            To see webpages about heart disease from the BBC or CNN websites, type &quot;heart
            disease&quot; (site:bbc.co.uk OR site:cnn.com)
          </td>
        </tr>
        <tr className="advanced-search-list-table__row">
          <td className="advanced-search-list-table__td">
            <span className="advanced-search-list-table__td-content">
              <CopyIcon text="inurl: " />
              inurl:
            </span>
          </td>
          <td className="advanced-search-list-table__td">
            Returns only webpages with a specific keyword in the url.
          </td>
          <td className="advanced-search-list-table__td">
            To find webpages that are part of a blog, type inurl:blog
          </td>
        </tr>
        <tr className="advanced-search-list-table__row">
          <td className="advanced-search-list-table__td">
            <span className="advanced-search-list-table__td-content">
              <CopyIcon text="intext: " />
              intext:
            </span>
          </td>
          <td className="advanced-search-list-table__td">
            Returns only webpages that mention a specific word or phrase in the text.
          </td>
          <td className="advanced-search-list-table__td">
            To find webpages that mention &quot;link building&quot; in the text, type
            intext:&quot;link building&quot;
          </td>
        </tr>
        <tr className="advanced-search-list-table__row">
          <td className="advanced-search-list-table__td">
            <span className="advanced-search-list-table__td-content">
              <CopyIcon text="intitle:" />
              intitle:
            </span>
          </td>
          <td className="advanced-search-list-table__td">
            Returns only webpages that mention a specific word or phrase in the title.
          </td>
          <td className="advanced-search-list-table__td">
            To find webpages that mention &quot;link building&quot; in the title, type
            intitle:&quot;link building&quot;
          </td>
        </tr>
        <tr className="advanced-search-list-table__row">
          <td className="advanced-search-list-table__td">
            <span className="advanced-search-list-table__td-content">
              <CopyIcon text="site:" />
              site:
            </span>
          </td>
          <td className="advanced-search-list-table__td">
            Returns webpages that belong to the specified site. You can use site: to search for web
            domains, top level domains, and directories.
          </td>
          <td className="advanced-search-list-table__td">
            To find webpages about the PC version of Halo on the Microsoft website, type
            site:www.microsoft.com/games/pc halo
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default GoogleAdvancedSearchListTable;
