import React from 'react';

export default function ({ color = '#8F9199', size = 12, backgroundIcon = '#E8EAED' }: { color?: string; size?: number, backgroundIcon?: string }) {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="17" cy="17" r="17" fill={backgroundIcon} />
      <path
        d="M11 23C11 19.9874 13.442 17.5454 16.4545 17.5454H17.5455C20.558 17.5454 23 19.9874 23 23"
        stroke={color}
        strokeWidth="1.4"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M16.9993 17.5455C18.8068 17.5455 20.272 16.0802 20.272 14.2727C20.272 12.4652 18.8068 11 16.9993 11C15.1918 11 13.7266 12.4652 13.7266 14.2727C13.7266 16.0802 15.1918 17.5455 16.9993 17.5455Z"
        stroke={color}
        strokeWidth="1.4"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
}
