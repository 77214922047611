import { useQuery, useQueryClient } from 'react-query';
import { QueryCollectionHookType } from '@ts/common.types';
import { useMemo } from 'react';
import { RelationshipPipelineStageType } from '@ts/relationshipPipeline.types';
import { getAllStagesApi } from '@api/relationshipPipeline';

export const PIPELINE_COLUMN_QUERY_KEY = 'relationship-stages';

export default (
  pipelineId: number,
  workspaceId: number
): QueryCollectionHookType<RelationshipPipelineStageType> => {
  const queryClient = useQueryClient();

  const key = useMemo(
    () => [PIPELINE_COLUMN_QUERY_KEY, workspaceId, pipelineId],
    [workspaceId, pipelineId]
  );

  const queryResult = useQuery<RelationshipPipelineStageType[]>(
    key,
    () => getAllStagesApi(pipelineId, workspaceId),
    {
      enabled: workspaceId > 0 && pipelineId > 0,
      refetchOnWindowFocus: false,
      placeholderData: [],
      staleTime: 15 * 60 * 1000,
    }
  );

  const { data, isFetching, isLoading, isRefetching, refetch, isSuccess } = queryResult;

  const updateCache = (
    updateFn: (cachedData: RelationshipPipelineStageType[]) => RelationshipPipelineStageType[]
  ) => {
    queryClient.setQueryData<RelationshipPipelineStageType[]>(key, updateFn);
  };

  const removeItem = (id: number) => {
    updateCache((cachedData) => cachedData.filter((item) => item.id !== id));
  };

  const addItem = (item: RelationshipPipelineStageType) => {
    updateCache((cachedData) => (cachedData ? [...cachedData, item] : [item]));
  };

  const updateItem = (id: number, payload: RelationshipPipelineStageType) => {
    updateCache((cachedData) => {
      return cachedData.map((item) => (item.id === id ? { ...item, ...payload } : item));
    });
  };

  const evictByKey = (id: number) => queryClient.removeQueries(key);

  const resetCache = () => {
    queryClient.invalidateQueries(key);
  };

  return {
    items: data,
    refetch,
    isLoading: isFetching || isLoading || isRefetching,
    isEmpty: isSuccess && data?.length === 0,
    removeItem,
    addItem,
    updateItem,
    evictByKey,
    resetCache,
  };
};
