import { getAllWorkspacesShortApi } from '@api/userOrganization.api';
import { setWorkspaces, fetchWorkspaces, changeWorkspace } from '@redux/actions/workspaces.actions';

export const fetchAllWorkspaces = (dispatch) => {
  dispatch(fetchWorkspaces());

  return getAllWorkspacesShortApi().then((workspaces) => {
    dispatch(setWorkspaces(workspaces));

    return workspaces;
  });
};
