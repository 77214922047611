import checkIsEmail from '@utils/checkIsEmail';

export default (value: string): string => {
  if (value === '@url' || value === '@linkedin') {
    return value;
  }

  if (!value) {
    return '';
  }

  if (value.startsWith('http')) {
    return value;
  }

  if (checkIsEmail(value)) {
    return `mailto:${value}`;
  }

  return `http://${value}`;
};
