import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { roundUpsellCredits, subscriptionLimits } from '@helpers/subscriptionHelpers';

import PoorNumberInput from '@uikit/PoorNumberInput/PoorNumberInput';
import RightInfoSidebar from '@uikit/RightInfoSidebar/RightInfoSidebar';
import { SVGIcon } from '@uikit/Icon/Icon';

import Display from '@components/Display/Display';

import {
  billingDetailsSelector,
  billingMyRemainingCreditsSelector,
} from '@redux/selectors/billings.selectors';
import { buyBonusCreditsRequest, upgradePlanCreditsRequest } from '@redux/thunks/billings.thunks';

import {
  calcBonusCreditsPriceApi,
  calcPlanCreditsPriceApi,
  getSubscriptionPresetApi,
} from '@api/billing.api';

import { SubscriptionPresetResponseType } from '@ts/billing.types';

import { BillingSubscriptionCycle } from 'respona_api/generated/billing_pb';

import debounce from '@utils/debounce';

import useNonInitialEffect from '@hooks/useNonInitialEffect';

import UpsellOption from '@components/UpsellSidebars/_components/UpsellOption/UpsellOption';
import UpsellSidebarFooter from '@components/UpsellSidebars/_components/UpsellSidebarFooter/UpsellSidebarFooter';

import './UpsellCreditsSidebar.scss';
import { userProfileSelector } from '@redux/selectors/userProfile.selector';

function UpsellCreditsSidebar({
  title,
  onClose,
  needForAutomation = 0,
  onActionAfterPay,
}: {
  title?: string;
  onClose: () => void;
  needForAutomation?: number;
  onActionAfterPay?: () => void;
}): JSX.Element {
  const dispatch = useDispatch();

  const isYearly = useRef<boolean | null>(null);

  const [isTotalLoading, changeIsTotalLoading] = useState<boolean>(true);
  const [isPayButtonLoading, changeIsPayButtonLoading] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<number>(0);
  const { info: profileFromRedux } = useSelector(userProfileSelector);
  const [recommendedOption, changeRecommendedOption] = useState(true);
  const [totalPrice, changeTotalPrice] = useState<number | null>(null);
  const [recurringCredits, changeRecurringCredits] = useState<number>(0);
  const [bonusCreditsValue, changeBonusCreditsValue] = useState<number>(5000);

  const [presetState, changePresetState] = useState<SubscriptionPresetResponseType | null>(null);

  const { data: billingDetails } = useSelector(billingDetailsSelector);

  const myRemainingCredits = useSelector(billingMyRemainingCreditsSelector);

  const debouncedFetchTotalPriceBonusCredits = useCallback(
    debounce((creditsQuantity: number): Promise<void> => {
      return calcBonusCreditsPriceApi(creditsQuantity)
        .then(({ value }) => changeTotalPrice(value))
        .finally(() => changeIsTotalLoading(false));
    }, 500),
    []
  );

  const handlePay = () => {
    changeIsPayButtonLoading(true);
    if (recommendedOption) {
      dispatch(
        upgradePlanCreditsRequest(
          Number(recurringCredits),
          presetState.currentType,
          presetState.cycle,
          profileFromRedux.email
        )
      )
        // @ts-ignore
        .then(() => {
          if (onActionAfterPay) {
            onActionAfterPay();
          }
          onClose();
        })
        .finally(() => {
          changeIsPayButtonLoading(false);
        });
    } else {
      dispatch(buyBonusCreditsRequest(bonusCreditsValue, profileFromRedux.email))
        // @ts-ignore
        .then(() => {
          onClose();
        })
        // @ts-ignore
        .finally(() => {
          changeIsPayButtonLoading(false);
        });
    }
  };

  useEffect(() => {
    getSubscriptionPresetApi().then((preset) => {
      changePresetState(preset);
      isYearly.current = preset.cycle === 2;
    });
  }, []);

  useEffect(() => {
    if (needForAutomation <= 0) {
      return;
    }
    const needCreditsToStart = needForAutomation - myRemainingCredits;
    const minBatchOptionIndex = Math.ceil(needCreditsToStart / 5000) * 5000;
    changeBonusCreditsValue(minBatchOptionIndex);
  }, [myRemainingCredits, needForAutomation]);

  useNonInitialEffect(() => {
    if (presetState) {
      changeRecurringCredits(presetState.cycle === BillingSubscriptionCycle.YEARLY ? 60000 : 5000);
    }
  }, [presetState]);

  useNonInitialEffect(() => {
    if (!recurringCredits) return;

    setInputValue(recurringCredits);
  }, [recurringCredits]);

  useNonInitialEffect(() => {
    if (presetState) {
      changeIsTotalLoading(true);

      if (recommendedOption && recurringCredits) {
        /* subscription */
        calcPlanCreditsPriceApi(recurringCredits, presetState.currentType, presetState.cycle)
          .then(({ value }) => changeTotalPrice(value))
          .finally(() => changeIsTotalLoading(false));
      } else if (!recommendedOption && bonusCreditsValue) {
        /* bonus credits */
        debouncedFetchTotalPriceBonusCredits(bonusCreditsValue);
      }
    }
  }, [presetState, recommendedOption, recurringCredits, bonusCreditsValue]);

  return (
    <RightInfoSidebar isOpen onClose={onClose} title={title || 'Increase Credits'}>
      {presetState ? (
        <div className="upsell-sidebar">
          <div className="upsell-sidebar__body">
            <UpsellOption
              isChosen={recommendedOption}
              onChange={() => changeRecommendedOption(true)}
              headerText="Recommended: upgrade your plan credits"
              pricePerCredit={
                isYearly.current ? presetState.creditsYearlyPrice : presetState.creditsMonthlyPrice
              }
            >
              <>
                <p className="upsell-option__text">
                  Current credits:{' '}
                  <span className="upsell-option__text--strong">
                    {presetState.planCredits} / {isYearly.current ? 'yearly' : 'monthly'} (
                    {myRemainingCredits} remaining)
                  </span>
                </p>
                <div className="upsell-option__select-subscription-row">
                  <span className="upsell-option__text">Additional credits:</span>
                  <div className="upsell-option__select-wrapper">
                    {/* <Select */}
                    {/*  value={recurringCredits} */}
                    {/*  options={ */}
                    {/*    [] */}
                    {/*    // mapToOptionsFromArray( */}
                    {/*    //   isYearly.current */}
                    {/*    //     ? (presetState.currentType === BillingSubscriptionType.UNLIMITED */}
                    {/*    //         ? presetState.creditsUnlimitedYearlyItemsList */}
                    {/*    //         : presetState.creditsStandardYearlyItemsList */}
                    {/*    //       ).filter((item) => item > presetState.planCredits) */}
                    {/*    //     : (presetState.currentType === BillingSubscriptionType.UNLIMITED */}
                    {/*    //         ? presetState.creditsUnlimitedMonthlyItemsList */}
                    {/*    //         : presetState.creditsStandardMonthlyItemsList */}
                    {/*    //       ).filter((item) => item > presetState.planCredits) */}
                    {/*    // ) */}
                    {/*  } */}
                    {/*  onChange={changeRecurringCredits} */}
                    {/*  isSearchable={false} */}
                    {/* /> */}

                    <PoorNumberInput
                      value={inputValue}
                      onChange={setInputValue}
                      min={presetState.cycle === BillingSubscriptionCycle.YEARLY ? 60000 : 5000}
                      max={
                        subscriptionLimits[presetState.currentType][presetState.cycle].maxCredits
                      }
                      step={presetState.cycle === BillingSubscriptionCycle.YEARLY ? 60000 : 5000}
                      onBlur={(value) => {
                        const creditsNumber = roundUpsellCredits(value, billingDetails.cycle);
                        setInputValue(creditsNumber);
                        changeRecurringCredits(creditsNumber);
                      }}
                      capLimits={false}
                    />
                  </div>
                  <span className="upsell-option__text">
                    / {isYearly.current ? 'year' : 'month'}
                  </span>
                </div>
              </>
            </UpsellOption>
            <UpsellOption
              isChosen={!recommendedOption}
              onChange={() => changeRecommendedOption(false)}
              headerText="Purchase batches of one-time bonus credits"
              pricePerCredit={presetState.bonusCreditsPrice}
            >
              <div className="upsell-option__batch-quantity">
                <span className="upsell-option__text">Quantity</span>
                <div className="upsell-option__select-wrapper">
                  <PoorNumberInput
                    value={bonusCreditsValue}
                    onChange={changeBonusCreditsValue}
                    min={5000}
                    max={90000000}
                    step={5000}
                    onBlur={(value) => {
                      const creditsNumber = roundUpsellCredits(value, null);
                      changeBonusCreditsValue(creditsNumber);
                    }}
                    capLimits={false}
                  />
                </div>
              </div>
            </UpsellOption>
            <Display isVisible={recommendedOption}>
              <div className="upsell-sidebar__bottom-description">
                <SVGIcon icon="wallet" />
                <span>All remaining credits will roll over to the new subscription.</span>
              </div>
            </Display>
          </div>
          <div className="upsell-sidebar__footer">
            <UpsellSidebarFooter
              onPay={handlePay}
              onCancel={onClose}
              totalPrice={totalPrice}
              isLoadingPrice={isTotalLoading}
              isPayButtonLoading={isPayButtonLoading}
            />
          </div>
        </div>
      ) : null}
    </RightInfoSidebar>
  );
}

export default UpsellCreditsSidebar;
