import React, { useEffect, useRef, useState } from 'react';
import { ReactEditor } from 'slate-react';
import cn from 'class-names';

import useOutsideClick from '@hooks/useOutsideClick';
import useAutoFocus from '@hooks/useAutoFocus';
import { EditorSelectionType } from '@ts/common.types';

import Input from '@uikit/Input/Input';
import Button from '@uikit/Button/Button';

import Checkbox from '@uikit/Checkbox/Checkbox';
import { SVGIcon } from '@uikit/Icon/Icon';
import ControlledReactTooltip from '@uikit/ControlledReactTooltip/ControlledReactTooltip';
import { urlsVariables } from '@constants/variables';
import Display from '@components/Display/Display';

import './EditorChangeFallback.scss';

type OnChangeFallbackType = (newFallback: string, path: number[], href?: string) => void;
type OnTransformToTextType = (newElem: any, path: number[]) => void;

let style = {};

const EditorChangeFallback = ({
  onChangeFallback,
  onTransformIntoText,
  onClose,
  changingVariable,
  isInline = false,
  disableVariable,
  allowFullFallbackEditing,
}: {
  onChangeFallback: OnChangeFallbackType;
  onTransformIntoText: OnTransformToTextType;
  onClose: () => void;
  changingVariable?: {
    fallback: string;
    variable: string;
    selection: EditorSelectionType;
    href?: string;
    transformedValue?: string;
  };
  isInline?: boolean;
  disableVariable?: boolean;
  allowFullFallbackEditing?: boolean;
}): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>();
  const [fallbackValue, changeFallbackValue] = useState('');
  const [withHref, changeWithHref] = useState(false);
  const [href, changeHref] = useState('');
  const [varTextValue, changeVarTextValue] = useState('');

  useEffect(() => {
    changeFallbackValue(changingVariable.fallback);
    changeWithHref(changingVariable.href?.length > 0);
    changeHref(changingVariable?.href || '');
    changeVarTextValue(changingVariable?.transformedValue || '');
  }, [changingVariable.fallback, changingVariable.href]);

  useAutoFocus(inputRef);

  const handleApplyChanges = () => {
    if (disableVariable && varTextValue !== changingVariable.transformedValue) {
      const newElem = withHref
        ? {
            type: 'link',
            url: href,
            children: [
              {
                type: 'text',
                text: varTextValue,
              },
            ],
          }
        : {
            type: 'text',
            text: varTextValue,
          };

      onTransformIntoText(newElem, changingVariable.selection?.anchor.path);
    } else {
      onChangeFallback(
        fallbackValue,
        changingVariable.selection?.anchor?.path,
        withHref ? href : ''
      );
    }

    onClose();
  };

  const handleKeyDownOnInput = (e) => {
    if (e.keyCode === 13) {
      handleApplyChanges();
    }
  };

  const handleChangeWithHref = ({ target: { checked } }) => changeWithHref(checked);

  const cnFallbackInput = cn('editor-change-fallback__input-fallback', {
    'editor-change-fallback__input-fallback--with-href': withHref,
  });

  const cnApplyBtn = cn(
    'editor-change-fallback__apply-btn',
    'editor-change-fallback__apply-btn--with-href'
  );

  const isDisabled = disableVariable && !allowFullFallbackEditing;

  return (
    <>
      <Display isVisible={isDisabled || allowFullFallbackEditing}>
        <div className="editor-change-fallback__title">
          You can change value for {changingVariable ? changingVariable.variable : ''}:
        </div>
        <Input
          isFullWidth
          placeholder="Insert text value"
          value={varTextValue}
          onChange={({ target: { value } }) => changeVarTextValue(value)}
          onKeyDown={handleKeyDownOnInput}
        />
      </Display>

      <Display isVisible={!isDisabled && !allowFullFallbackEditing}>
        <div className="editor-change-fallback__title">
          Please provide fallback value for {changingVariable ? changingVariable.variable : ''}:
        </div>
        <div className="editor-change-fallback__body">
          <Input
            isFullWidth={!withHref}
            className={cnFallbackInput}
            value={fallbackValue}
            onChange={({ target: { value } }) => changeFallbackValue(value)}
            onKeyDown={handleKeyDownOnInput}
            disabled={isDisabled}
          />
        </div>
      </Display>
      <Display isVisible={!isInline && !isDisabled}>
        <Checkbox
          className="editor-change-fallback__with-link-cb"
          onChange={handleChangeWithHref}
          value={withHref}
        >
          Wrap into link
        </Checkbox>
      </Display>

      <Display isVisible={withHref && !isDisabled}>
        <div className="editor-change-fallback__link-wrapper">
          <Input
            isFullWidth
            className="editor-change-fallback__input-link"
            value={href}
            onChange={({ target: { value } }) => changeHref(value)}
            onKeyDown={handleKeyDownOnInput}
            placeholder="Input href url"
          />
          <div
            className="editor-change-fallback__variables-button"
            data-for="editor-variable-url-select"
            data-tip=""
          >
            <SVGIcon icon="brackets" color="#9A9CA5" />
          </div>
          <ControlledReactTooltip
            id="editor-variable-url-select"
            className="react-tooltip"
            place="bottom"
            effect="solid"
            event="click"
            globalEventOff="click"
            clickable
          >
            <div className="editor-change-fallback__variables">
              {urlsVariables.map((variable) => (
                <div
                  key={variable.value}
                  className="editor-change-fallback__variables-item"
                  onClick={() => changeHref(`@${variable.value}`)}
                >
                  {variable.title}
                </div>
              ))}
            </div>
          </ControlledReactTooltip>
        </div>
      </Display>

      <Button className={cnApplyBtn} onClick={handleApplyChanges}>
        Apply
      </Button>
    </>
  );
};

export default ({
  onChangeFallback,
  onTransformIntoText,
  containerRef,
  editor,
  onClose,
  changingVariable,
  isInline,
  disableVariable,
  allowFullFallbackEditing,
}: {
  onChangeFallback: OnChangeFallbackType;
  onTransformIntoText: OnTransformToTextType;
  containerRef: any;
  editor: any;
  onClose: () => void;
  changingVariable?: {
    fallback: string;
    variable: string;
    selection: EditorSelectionType;
    transformedValue: string;
  };
  isInline?: boolean;
  disableVariable?: boolean;
  allowFullFallbackEditing?: boolean;
}): JSX.Element => {
  const wrapperRef = useRef(null);

  if (!changingVariable) {
    return null;
  }

  try {
    const containerEl = containerRef.current;
    const domRange = ReactEditor.toDOMRange(editor, changingVariable.selection);
    const rect = domRange.getBoundingClientRect();
    const containerRect = containerEl.getBoundingClientRect();

    let left = rect.left + window.pageXOffset - containerRect.left - 30;

    if (left + 430 + containerRect.left > window.innerWidth) {
      left -= left + 430 + containerRect.left - window.innerWidth;
    }

    if (left + 350 > containerRect.width) {
      style = {
        top: `${rect.top + window.pageYOffset + 24 - containerRect.top}px`,
        left: `auto`,
        right: `30px`,
      };
    } else {
      style = {
        top: `${rect.top + window.pageYOffset + 24 - containerRect.top}px`,
        left: `${left}px`,
      };
    }
  } catch (e) {}

  useOutsideClick(wrapperRef, onClose);

  return (
    <div
      ref={wrapperRef}
      className="editor-change-fallback"
      style={style}
      onClick={(e) => e.stopPropagation()}
    >
      <EditorChangeFallback
        onClose={onClose}
        onChangeFallback={onChangeFallback}
        onTransformIntoText={onTransformIntoText}
        changingVariable={changingVariable}
        isInline={isInline}
        disableVariable={disableVariable}
        allowFullFallbackEditing={allowFullFallbackEditing}
      />
    </div>
  );
};
