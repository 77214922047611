import {
  SET_INQUIRIES_LIST,
  SET_INQUIRIES_LIST_LS,
  ADD_INQUIRIES_LIST_DATA,
  SET_BOOKMARK,
  REMOVE_BOOKMARK,
  SET_INQUIRIES_BOOKMARKS,
  SET_INQUIRIES_BOOKMARKS_LS,
  ADD_INQUIRIES_BOOKMARKS_DATA,
  SET_HARO_CATEGORIES,
  SET_HARO_CATEGORIES_LS,
  SET_HARO_FILTERS_DATA,
  SET_HARO_FILTERS_LS,
  ADD_HARO_FILTERS_DATA,
  REMOVE_HARO_FILTER_DATA,
  SET_INQUIRIES_PITCHED,
  SET_INQUIRIES_PITCHED_LS,
  ADD_INQUIRIES_PITCHED_DATA,
  UPDATE_HARO_FILTER_DATA,
} from 'src/redux/actions/inquiries.actions';
import {
  HaroCategoriesType,
  HaroFilterShortType,
  HaroOpportunityType,
  HaroPitchedType,
} from 'src/types/haro.types';
import loadingStatuses from 'src/constants/loadingStatuses';

export type InquiriesStateType = {
  inquiriesList: {
    items: HaroOpportunityType[];
    loadingStatus: string;
  };
  filters: {
    items: HaroFilterShortType[];
    loadingStatus: string;
  };
  bookmarks: {
    items: HaroOpportunityType[];
    loadingStatus: string;
  };
  categories: {
    items: HaroCategoriesType[];
    loadingStatus: string;
  };
  pitched: {
    items: HaroPitchedType[];
    loadingStatus: string;
  };
};

const defaultState: InquiriesStateType = {
  inquiriesList: {
    items: [],
    loadingStatus: loadingStatuses.PENDING,
  },
  filters: {
    items: [],
    loadingStatus: loadingStatuses.PENDING,
  },
  bookmarks: {
    items: [],
    loadingStatus: loadingStatuses.PENDING,
  },
  categories: {
    items: [],
    loadingStatus: loadingStatuses.PENDING,
  },
  pitched: {
    items: [],
    loadingStatus: loadingStatuses.PENDING,
  },
};

const updateBookmarkValue = (inquiries, inquiryUid, value) =>
  inquiries.map((inquiry) =>
    inquiry.id === inquiryUid
      ? {
          ...inquiry,
          isBookmark: value,
        }
      : inquiry
  );

export default (state = defaultState, { type, payload }): InquiriesStateType => {
  switch (type) {
    case SET_INQUIRIES_LIST:
      return {
        ...state,
        inquiriesList: {
          ...state.inquiriesList,
          items: payload,
        },
      };
    case ADD_INQUIRIES_LIST_DATA:
      return {
        ...state,
        inquiriesList: {
          ...state.inquiriesList,
          items: [...state.inquiriesList.items, ...payload],
        },
      };
    case SET_INQUIRIES_LIST_LS:
      return {
        ...state,
        inquiriesList: {
          ...state.inquiriesList,
          loadingStatus: payload,
        },
      };
    case SET_BOOKMARK:
      return {
        ...state,
        inquiriesList: {
          ...state.inquiriesList,
          items: updateBookmarkValue(state.inquiriesList.items, payload.id, true),
        },
        bookmarks: {
          ...state.bookmarks,
          items: [
            ...state.bookmarks.items,
            {
              ...payload,
              totalItems: 0,
            },
          ],
        },
      };
    case REMOVE_BOOKMARK:
      return {
        ...state,
        inquiriesList: {
          ...state.inquiriesList,
          items: updateBookmarkValue(state.inquiriesList.items, payload.id, false),
        },
        bookmarks: {
          ...state.bookmarks,
          items: state.bookmarks.items.filter(({ id }) => id !== payload.id),
        },
      };
    case SET_INQUIRIES_BOOKMARKS:
      return {
        ...state,
        bookmarks: {
          ...state.bookmarks,
          items: payload,
        },
      };
    case SET_INQUIRIES_BOOKMARKS_LS:
      return {
        ...state,
        bookmarks: {
          ...state.bookmarks,
          loadingStatus: payload,
        },
      };
    case ADD_INQUIRIES_BOOKMARKS_DATA:
      return {
        ...state,
        bookmarks: {
          ...state.bookmarks,
          items: [...state.bookmarks.items, ...payload],
        },
      };
    case SET_INQUIRIES_PITCHED:
      return {
        ...state,
        pitched: {
          ...state.pitched,
          items: payload,
        },
      };
    case SET_INQUIRIES_PITCHED_LS:
      return {
        ...state,
        pitched: {
          ...state.pitched,
          loadingStatus: payload,
        },
      };
    case ADD_INQUIRIES_PITCHED_DATA:
      return {
        ...state,
        pitched: {
          ...state.pitched,
          items: [...state.pitched.items, ...payload],
        },
      };
    case SET_HARO_CATEGORIES:
      return {
        ...state,
        categories: {
          ...state.categories,
          items: payload,
        },
      };
    case SET_HARO_CATEGORIES_LS:
      return {
        ...state,
        categories: {
          ...state.categories,
          loadingStatus: payload,
        },
      };
    case ADD_HARO_FILTERS_DATA:
      return {
        ...state,
        filters: {
          ...state.filters,
          items: [...state.filters.items, payload],
        },
      };
    case UPDATE_HARO_FILTER_DATA:
      return {
        ...state,
        filters: {
          ...state.filters,
          items: state.filters.items.map((filter) => (filter.id === payload.id ? payload : filter)),
        },
      };
    case SET_HARO_FILTERS_DATA:
      return {
        ...state,
        filters: {
          ...state.filters,
          items: payload,
        },
      };
    case REMOVE_HARO_FILTER_DATA:
      return {
        ...state,
        filters: {
          ...state.filters,
          items: state.filters.items.filter(({ id }) => id !== payload),
        },
      };
    case SET_HARO_FILTERS_LS:
      return {
        ...state,
        filters: {
          ...state.filters,
          loadingStatus: payload,
        },
      };
    default:
      return state;
  }
};
