import React from 'react';

export default ({ color = '#fff' }) => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.5402 9.49832L8.88021 3.95832C8.3575 3.65648 7.76421 3.49836 7.16061 3.50001C6.557 3.50167 5.96459 3.66305 5.44354 3.96776C4.92249 4.27246 4.49137 4.70964 4.19396 5.23489C3.89655 5.76014 3.74345 6.35475 3.75021 6.95832V18.0783C3.75021 18.9854 4.11053 19.8552 4.75191 20.4966C5.39328 21.138 6.26317 21.4983 7.17021 21.4983C7.77065 21.4973 8.3603 21.3387 8.88021 21.0383L18.5402 15.4983C19.0593 15.1979 19.4902 14.7663 19.7898 14.2468C20.0894 13.7272 20.2471 13.138 20.2471 12.5383C20.2471 11.9386 20.0894 11.3494 19.7898 10.8299C19.4902 10.3103 19.0593 9.87873 18.5402 9.57832V9.49832ZM17.5402 13.6883L7.88021 19.3083C7.6637 19.431 7.41908 19.4955 7.17021 19.4955C6.92135 19.4955 6.67673 19.431 6.46021 19.3083C6.24431 19.1837 6.06503 19.0044 5.9404 18.7885C5.81576 18.5725 5.75017 18.3276 5.75021 18.0783V6.91832C5.75017 6.66901 5.81576 6.42409 5.9404 6.20818C6.06503 5.99226 6.24431 5.81297 6.46021 5.68832C6.67762 5.56747 6.92151 5.50221 7.17021 5.49832C7.41875 5.50342 7.66235 5.56861 7.88021 5.68832L17.5402 11.2683C17.7562 11.3929 17.9356 11.5722 18.0603 11.7881C18.185 12.004 18.2506 12.249 18.2506 12.4983C18.2506 12.7477 18.185 12.9926 18.0603 13.2085C17.9356 13.4244 17.7562 13.6037 17.5402 13.7283V13.6883Z"
      fill={color}
    />
  </svg>
);

