export const mockCampaignTemplates = [
  {
    id: 4,
    title: 'Listicle',
    description: 'Find “Top 10” listicle blog posts, and pitch your product/service.',
    type: 1,
    level: 0,
    favorite: true,
    icon: 'https://respona-public-files.s3.amazonaws.com/campaign-templates/listicle-template-min.png',
  },
  {
    id: 5,
    title: 'Competitor mentions',
    description: 'Reach out to blogs that have recently mentioned your competitors.',
    type: 1,
    level: 0,
    favorite: false,
    icon: 'https://respona-public-files.s3.amazonaws.com/campaign-templates/competitor-mentions-template-min.png',
  },
  {
    id: 6,
    title: 'Product review',
    description: 'Get reviews for your product.',
    type: 1,
    level: 0,
    favorite: false,
    icon: 'https://respona-public-files.s3.amazonaws.com/campaign-templates/product-review-template-min.png',
  },
  {
    id: 7,
    title: 'Product alternatives',
    description: 'Get mentioned as one of your competitors’ alternatives.',
    type: 1,
    level: 0,
    favorite: false,
    icon: 'https://respona-public-files.s3.amazonaws.com/campaign-templates/product-alternatives-template-min.png',
  },
  {
    id: 8,
    title: 'Podcast outreach',
    description: 'Find interview opportunities at popular podcasts in your niche.',
    type: 2,
    level: 0,
    favorite: false,
    icon: 'https://respona-public-files.s3.amazonaws.com/campaign-templates/podcast-outreach-template-min.png',
  },
  {
    id: 2,
    title: 'Skyscraper technique',
    description: 'Find backlinks to the top competing search results for your target keyword.',
    type: 0,
    level: 1,
    favorite: true,
    icon: 'https://respona-public-files.s3.amazonaws.com/campaign-templates/skyscraper-template-min.png',
  },
  {
    id: 9,
    title: 'Guest post',
    description: 'Publish a guest article on niche sites',
    type: 3,
    level: 1,
    favorite: false,
    icon: 'https://respona-public-files.s3.amazonaws.com/campaign-templates/guest-post-template-min.png',
  },
  {
    id: 10,
    title: 'Resource pages',
    description: 'Find websites that list resources in your niche.',
    type: 3,
    level: 1,
    favorite: false,
    icon: 'https://respona-public-files.s3.amazonaws.com/campaign-templates/relevant-resources-template-min.png',
  },
  {
    id: 1,
    title: 'Anchor text',
    description: 'Find non-competing articles relevant to your content.',
    type: 0,
    level: 2,
    favorite: true,
    icon: 'https://respona-public-files.s3.amazonaws.com/campaign-templates/anchor-text-template-min.png',
  },
  {
    id: 3,
    title: 'Content Syndication',
    description: 'Publish a copy of your article on a third-party website.',
    type: 0,
    level: 2,
    favorite: false,
    icon: 'https://respona-public-files.s3.amazonaws.com/campaign-templates/content-syndication-template-min.png',
  },
  {
    id: 11,
    title: 'Infographic outreach',
    description: 'Get featured on blog posts relevant to your infographic.',
    type: 3,
    level: 2,
    favorite: false,
    icon: 'https://respona-public-files.s3.amazonaws.com/campaign-templates/inforgaphic-outreach-template-min.png',
  },
  {
    id: 22,
    title: 'Blogger outreach',
    description: 'Connect with relevant blogs in your niche.',
    type: 2,
    level: 0,
    favorite: true,
    icon: 'https://respona-public-files.s3.amazonaws.com/campaign-templates/blogger-outreach-template-min.png',
  },
  {
    id: 23,
    title: 'Mention outreach',
    description: 'Reach out to external sources cited in your articles.',
    type: 0,
    level: 0,
    favorite: false,
    icon: 'https://respona-public-files.s3.amazonaws.com/campaign-templates/mention-outreach-min.png',
  },
  {
    id: 24,
    title: 'Mention outreach',
    description: 'Find mentions of your brand that aren’t linked to your website.',
    type: 3,
    level: 0,
    favorite: false,
    icon: 'https://respona-public-files.s3.amazonaws.com/campaign-templates/unlinked-mentions-template-min.png',
  },
  {
    id: 25,
    title: 'Link reclamation',
    description: 'Find your website’s recently lost backlinks',
    type: 3,
    level: 1,
    favorite: false,
    icon: 'https://respona-public-files.s3.amazonaws.com/campaign-templates/link-reclamation-template-min.png',
  },
  {
    id: 26,
    title: 'Journalist outreach',
    description: 'Find journalists who recently covered a relevant topic.',
    type: 2,
    level: 1,
    favorite: true,
    icon: 'https://respona-public-files.s3.amazonaws.com/campaign-templates/journalist-outreach-template-min.png',
  },
];
