export const parseFrom12to24Time = (hour: number | string, dayTime: 'am' | 'pm') => {
  switch (dayTime) {
    case 'am':
      return Number(hour) === 12 ? '00:00:00' : `${`0${hour}`.slice(-2)}:00:00`;
    case 'pm':
      return Number(hour) === 12 ? '12:00:00' : `${Number(hour) + 12}:00:00`;
    default:
      return '';
  }
};
