// @ts-nocheck
import React from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { HaroOpportunityType } from 'src/types/haro.types';

import { SVGIcon } from 'src/uikit/Icon/Icon';
import InquiryPitchSummaryInfo from 'src/components/Inquiries/InquiryPitchSummaryInfo/InquiryPitchSummaryInfo';
import Button from 'src/uikit/Button/Button';
import { addInquiryBookmark, removeInquiryBookmark } from 'src/redux/thunks/inquries.thunks';
import { getCurrentWorkspaceId } from 'src/redux/selectors/workspaces.selectors';
import { inquiryByIdSelector } from 'src/redux/selectors/inquiries.selectors';

import { useBrowserBackButton } from '@hooks/useBrowserBackButton';

import InquiryPitchEmailEditor from '../InquiryPitchEmailEditor/InquiryPitchEmailEditor';

import './InquiryPitch.scss';
import Display from '@components/Display/Display';
import { MailboxAccountSettingsType } from '@ts/mailboxAccounts.types';

const InquiryPitch = ({
  inquiryId,
  filterId,
  onClose,
  mailboxAccountsData,
  isLoadingMailboxAccounts,
}: {
  inquiryId?: string;
  filterId?: number;
  onClose: () => void;
  mailboxAccountsData: MailboxAccountSettingsType[];
  isLoadingMailboxAccounts: boolean;
}): JSX.Element => {
  const dispatch = useDispatch();
  const curWorkspaceId = useSelector(getCurrentWorkspaceId);
  const inquiry = useSelector<HaroOpportunityType | undefined>(inquiryByIdSelector(inquiryId));

  useBrowserBackButton(onClose);

  if (!inquiry) {
    return null;
  }

  const handleToogleBookmark = () => {
    if (inquiry.isBookmark) {
      return dispatch(removeInquiryBookmark(curWorkspaceId, inquiry));
    }

    return dispatch(addInquiryBookmark(curWorkspaceId, inquiry));
  };

  return (
    <div className="inquiry-pitch">
      <div className="inquiry-pitch__header">
        <div className="inquiry-pitch__title">{inquiry.summary}</div>
        <div className="inquiry-pitch__close-icon" onClick={onClose} tabIndex={0} role="button">
          <SVGIcon icon="crossBlack" />
        </div>
      </div>

      <div className="inquiry-pitch__content-wrapper">
        <div className="inquiry-pitch__info-wrapper">
          <div className="inquiry-pitch__info-header">
            <InquiryPitchSummaryInfo inquiry={inquiry} />
          </div>
          <div className="inquiry-pitch__bookmark-row">
            <div className="inquiry-pitch__separator" />
            <Button
              onClick={handleToogleBookmark}
              type="ghost"
              size="xs"
              className="inquiry-pitch__add-bookmark-btn"
            >
              <SVGIcon icon="bookmark" color={inquiry.isBookmark ? '#2AA4CB' : '#8F9199'} />
            </Button>
          </div>
          <div className="inquiry-pitch__info">
            <div className="inquiry-pitch__info-title">Query:</div>
            <div className="inquiry-pitch__info-content">{inquiry.query}</div>
            <Display isVisible={!!inquiry.requirements}>
              <>
                <div className="inquiry-pitch__info-title">Requirements:</div>
                <div className="inquiry-pitch__info-content">{inquiry.requirements}</div>
              </>
            </Display>
          </div>
        </div>
        <div className="inquiry-pitch__email">
          <InquiryPitchEmailEditor
            inquiry={inquiry}
            workspaceId={curWorkspaceId}
            filterId={filterId}
            mailboxAccountsData={mailboxAccountsData}
          />
        </div>
      </div>
    </div>
  );
};

export default InquiryPitch;
