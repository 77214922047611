import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDetectAdBlock } from 'adblock-detect-react';

import {
  show as showIntercom,
  update as updateIntercom,
  addIntercomListener,
} from 'src/libs/intercom';

import getStringHashCode from '@utils/getStringHashCode';
import { getDateShort } from '@utils/date';

import { addNotification } from '@redux/actions/notifications.actions';
import { userProfileSelector } from '@redux/selectors/userProfile.selector';

import { Button } from '@uikit/Button/Button';
import { SVGIcon } from '@uikit/Icon/Icon';
import BaseModal from '@uikit/BaseModal/BaseModal';

import './BillingCancellationReviewModal.scss';
import { DispatchType } from 'src/store';

export const BILLING_CANCELLATION_REVIEW_MODAL_TYPE = {
  CANCELLATION: 'CANCELLATION',
  MAINTENANCE: 'MAINTENANCE',
  RESUME: 'RESUME',
};

const BillingCancellationReviewModal = ({
  isOpen,
  onClose,
  details,
}: {
  isOpen: boolean;
  onClose: () => void;
  details: { type?: string; date?: number };
}): JSX.Element => {
  const dispatch = useDispatch<DispatchType>();
  const { info: userProfile } = useSelector(userProfileSelector);
  const adBlockDetected = useDetectAdBlock();

  const handleNeedHelp = () => {
    try {
      updateIntercom({
        hide_default_launcher: false,
        email: userProfile?.email || '',
        user_id: getStringHashCode(userProfile?.email),
        name:
          userProfile?.firstName && userProfile?.lastName
            ? `${userProfile.firstName} ${userProfile.lastName}`
            : '',
      });
      showIntercom();

      addIntercomListener('onHide', () => {
        setTimeout(
          () =>
            updateIntercom({
              hide_default_launcher: true,
            }),
          300
        );
      });

      onClose();
    } catch (e) {
      if (adBlockDetected) {
        dispatch(
          addNotification({
            title:
              'Ad blocker detected. Please make sure to add respona.com to your exceptions list.',
            type: 'error',
          })
        );
      }
    }
  };

  const getModalDetails = () => {
    if (!details) {
      return null;
    }

    switch (details.type) {
      case BILLING_CANCELLATION_REVIEW_MODAL_TYPE.CANCELLATION:
        return {
          title: 'Subscription cancelled',
          subtitle: `Your subscription will end on ${getDateShort(
            new Date(details.date)
          )}. You will not be billed again.`,
        };
      case BILLING_CANCELLATION_REVIEW_MODAL_TYPE.MAINTENANCE:
        return {
          title: 'Maintenance plan',
          subtitle: `Your account will remain active until ${getDateShort(
            new Date(details.date)
          )} and will convert to the maintenance plan.`,
        };
      case BILLING_CANCELLATION_REVIEW_MODAL_TYPE.RESUME:
        return {
          title: 'Subscription resumed',
          subtitle: `Your subscription will renew on ${getDateShort(new Date(details.date))}`,
        };
      default:
        return null;
    }
  };

  const modalDetails = getModalDetails();

  if (!modalDetails) {
    return null;
  }

  return (
    <BaseModal isOpen={isOpen} onClose={onClose}>
      <div className="billing-cancellation-review-modal">
        <div className="billing-cancellation-review-modal__icon-container">
          <SVGIcon icon="check" color="#251FB6" size={27} />
        </div>

        <div className="billing-cancellation-review-modal__title">{modalDetails.title}</div>
        <div className="billing-cancellation-review-modal__subtitle">{modalDetails.subtitle}</div>

        <Button onClick={onClose} size="xl" type="primary">
          Go to Account
        </Button>
        <Button onClick={handleNeedHelp} size="xl" type="additional">
          <SVGIcon icon="questionInCircle" color="#3424EB" />
          <span>Need help?</span>
        </Button>
      </div>
    </BaseModal>
  );
};

export default BillingCancellationReviewModal;
