// source: billing.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
goog.exportSymbol('proto.billing.BillingAddress', null, global);
goog.exportSymbol('proto.billing.BillingCancellationActionResponse', null, global);
goog.exportSymbol('proto.billing.BillingCancellationFeedbackRequest', null, global);
goog.exportSymbol('proto.billing.BillingCancellationResponse', null, global);
goog.exportSymbol('proto.billing.BillingCreditsResponse', null, global);
goog.exportSymbol('proto.billing.BillingDetailsShortResponse', null, global);
goog.exportSymbol('proto.billing.BillingFeedback', null, global);
goog.exportSymbol('proto.billing.BillingResponse', null, global);
goog.exportSymbol('proto.billing.BillingState', null, global);
goog.exportSymbol('proto.billing.BillingSubscriptionCycle', null, global);
goog.exportSymbol('proto.billing.BillingSubscriptionType', null, global);
goog.exportSymbol('proto.billing.CreditCardInfo', null, global);
goog.exportSymbol('proto.billing.InvoiceContainer', null, global);
goog.exportSymbol('proto.billing.InvoiceDetailsResponse', null, global);
goog.exportSymbol('proto.billing.InvoiceRecipient', null, global);
goog.exportSymbol('proto.billing.InvoiceRequest', null, global);
goog.exportSymbol('proto.billing.InvoiceRow', null, global);
goog.exportSymbol('proto.billing.InvoiceRowType', null, global);
goog.exportSymbol('proto.billing.InvoiceShortResponse', null, global);
goog.exportSymbol('proto.billing.InvoiceStatus', null, global);
goog.exportSymbol('proto.billing.MailboxAccountChargeStrategyRequest', null, global);
goog.exportSymbol('proto.billing.MailboxAccountPriceResponse', null, global);
goog.exportSymbol('proto.billing.MailboxBonusCreditsStrategyRequest', null, global);
goog.exportSymbol('proto.billing.PaymentRequest', null, global);
goog.exportSymbol('proto.billing.PaymentResponse', null, global);
goog.exportSymbol('proto.billing.PlanPriceRequest', null, global);
goog.exportSymbol('proto.billing.SubscriptionChangeRequest', null, global);
goog.exportSymbol('proto.billing.SubscriptionPresetResponse', null, global);
goog.exportSymbol('proto.billing.SubscriptionPriceRequest', null, global);
goog.exportSymbol('proto.billing.SubscriptionRequest', null, global);
goog.exportSymbol('proto.billing.SubscriptionResponse', null, global);
goog.exportSymbol('proto.billing.UpdateCreditCardRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.PlanPriceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.PlanPriceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.PlanPriceRequest.displayName = 'proto.billing.PlanPriceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.SubscriptionPriceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.SubscriptionPriceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.SubscriptionPriceRequest.displayName = 'proto.billing.SubscriptionPriceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.SubscriptionPresetResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.SubscriptionPresetResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.SubscriptionPresetResponse.displayName = 'proto.billing.SubscriptionPresetResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.MailboxAccountPriceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.MailboxAccountPriceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.MailboxAccountPriceResponse.displayName = 'proto.billing.MailboxAccountPriceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.MailboxAccountChargeStrategyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.MailboxAccountChargeStrategyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.MailboxAccountChargeStrategyRequest.displayName = 'proto.billing.MailboxAccountChargeStrategyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.MailboxBonusCreditsStrategyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.MailboxBonusCreditsStrategyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.MailboxBonusCreditsStrategyRequest.displayName = 'proto.billing.MailboxBonusCreditsStrategyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.SubscriptionChangeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.SubscriptionChangeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.SubscriptionChangeRequest.displayName = 'proto.billing.SubscriptionChangeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.BillingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.BillingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.BillingResponse.displayName = 'proto.billing.BillingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.BillingCreditsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.BillingCreditsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.BillingCreditsResponse.displayName = 'proto.billing.BillingCreditsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.BillingDetailsShortResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.BillingDetailsShortResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.BillingDetailsShortResponse.displayName = 'proto.billing.BillingDetailsShortResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.SubscriptionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.SubscriptionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.SubscriptionRequest.displayName = 'proto.billing.SubscriptionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.SubscriptionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.SubscriptionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.SubscriptionResponse.displayName = 'proto.billing.SubscriptionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.UpdateCreditCardRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.UpdateCreditCardRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.UpdateCreditCardRequest.displayName = 'proto.billing.UpdateCreditCardRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.CreditCardInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.CreditCardInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.CreditCardInfo.displayName = 'proto.billing.CreditCardInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.InvoiceShortResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.InvoiceShortResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.InvoiceShortResponse.displayName = 'proto.billing.InvoiceShortResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.InvoiceContainer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.InvoiceContainer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.InvoiceContainer.displayName = 'proto.billing.InvoiceContainer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.InvoiceDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing.InvoiceDetailsResponse.repeatedFields_, null);
};
goog.inherits(proto.billing.InvoiceDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.InvoiceDetailsResponse.displayName = 'proto.billing.InvoiceDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.InvoiceRow = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.InvoiceRow, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.InvoiceRow.displayName = 'proto.billing.InvoiceRow';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.InvoiceRecipient = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.InvoiceRecipient, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.InvoiceRecipient.displayName = 'proto.billing.InvoiceRecipient';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.InvoiceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.InvoiceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.InvoiceRequest.displayName = 'proto.billing.InvoiceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.BillingAddress = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.BillingAddress, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.BillingAddress.displayName = 'proto.billing.BillingAddress';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.PaymentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.PaymentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.PaymentRequest.displayName = 'proto.billing.PaymentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.PaymentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.PaymentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.PaymentResponse.displayName = 'proto.billing.PaymentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.BillingCancellationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.BillingCancellationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.BillingCancellationResponse.displayName = 'proto.billing.BillingCancellationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.BillingCancellationFeedbackRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.BillingCancellationFeedbackRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.BillingCancellationFeedbackRequest.displayName = 'proto.billing.BillingCancellationFeedbackRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.BillingCancellationActionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.BillingCancellationActionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.BillingCancellationActionResponse.displayName = 'proto.billing.BillingCancellationActionResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.PlanPriceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.PlanPriceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.PlanPriceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.PlanPriceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    amount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    cycle: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.PlanPriceRequest}
 */
proto.billing.PlanPriceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.PlanPriceRequest;
  return proto.billing.PlanPriceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.PlanPriceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.PlanPriceRequest}
 */
proto.billing.PlanPriceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmount(value);
      break;
    case 2:
      var value = /** @type {!proto.billing.BillingSubscriptionType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {!proto.billing.BillingSubscriptionCycle} */ (reader.readEnum());
      msg.setCycle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.PlanPriceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.PlanPriceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.PlanPriceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.PlanPriceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getCycle();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional int32 amount = 1;
 * @return {number}
 */
proto.billing.PlanPriceRequest.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.PlanPriceRequest} returns this
 */
proto.billing.PlanPriceRequest.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional BillingSubscriptionType type = 2;
 * @return {!proto.billing.BillingSubscriptionType}
 */
proto.billing.PlanPriceRequest.prototype.getType = function() {
  return /** @type {!proto.billing.BillingSubscriptionType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.billing.BillingSubscriptionType} value
 * @return {!proto.billing.PlanPriceRequest} returns this
 */
proto.billing.PlanPriceRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional BillingSubscriptionCycle cycle = 3;
 * @return {!proto.billing.BillingSubscriptionCycle}
 */
proto.billing.PlanPriceRequest.prototype.getCycle = function() {
  return /** @type {!proto.billing.BillingSubscriptionCycle} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.billing.BillingSubscriptionCycle} value
 * @return {!proto.billing.PlanPriceRequest} returns this
 */
proto.billing.PlanPriceRequest.prototype.setCycle = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.SubscriptionPriceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.SubscriptionPriceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.SubscriptionPriceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.SubscriptionPriceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    emailAccounts: jspb.Message.getFieldWithDefault(msg, 1, 0),
    credits: jspb.Message.getFieldWithDefault(msg, 2, 0),
    contacts: jspb.Message.getFieldWithDefault(msg, 3, 0),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0),
    cycle: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.SubscriptionPriceRequest}
 */
proto.billing.SubscriptionPriceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.SubscriptionPriceRequest;
  return proto.billing.SubscriptionPriceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.SubscriptionPriceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.SubscriptionPriceRequest}
 */
proto.billing.SubscriptionPriceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEmailAccounts(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCredits(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setContacts(value);
      break;
    case 4:
      var value = /** @type {!proto.billing.BillingSubscriptionType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {!proto.billing.BillingSubscriptionCycle} */ (reader.readEnum());
      msg.setCycle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.SubscriptionPriceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.SubscriptionPriceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.SubscriptionPriceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.SubscriptionPriceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmailAccounts();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCredits();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getContacts();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getCycle();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional int32 email_accounts = 1;
 * @return {number}
 */
proto.billing.SubscriptionPriceRequest.prototype.getEmailAccounts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.SubscriptionPriceRequest} returns this
 */
proto.billing.SubscriptionPriceRequest.prototype.setEmailAccounts = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 credits = 2;
 * @return {number}
 */
proto.billing.SubscriptionPriceRequest.prototype.getCredits = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.SubscriptionPriceRequest} returns this
 */
proto.billing.SubscriptionPriceRequest.prototype.setCredits = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 contacts = 3;
 * @return {number}
 */
proto.billing.SubscriptionPriceRequest.prototype.getContacts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.SubscriptionPriceRequest} returns this
 */
proto.billing.SubscriptionPriceRequest.prototype.setContacts = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional BillingSubscriptionType type = 4;
 * @return {!proto.billing.BillingSubscriptionType}
 */
proto.billing.SubscriptionPriceRequest.prototype.getType = function() {
  return /** @type {!proto.billing.BillingSubscriptionType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.billing.BillingSubscriptionType} value
 * @return {!proto.billing.SubscriptionPriceRequest} returns this
 */
proto.billing.SubscriptionPriceRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional BillingSubscriptionCycle cycle = 5;
 * @return {!proto.billing.BillingSubscriptionCycle}
 */
proto.billing.SubscriptionPriceRequest.prototype.getCycle = function() {
  return /** @type {!proto.billing.BillingSubscriptionCycle} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.billing.BillingSubscriptionCycle} value
 * @return {!proto.billing.SubscriptionPriceRequest} returns this
 */
proto.billing.SubscriptionPriceRequest.prototype.setCycle = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.SubscriptionPresetResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.SubscriptionPresetResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.SubscriptionPresetResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.SubscriptionPresetResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    emailAccounts: jspb.Message.getFieldWithDefault(msg, 1, 0),
    currentType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    cycle: jspb.Message.getFieldWithDefault(msg, 3, 0),
    mailboxAccountMonthlyPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    mailboxAccountYearlyPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    creditsMonthlyPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    creditsYearlyPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    bonusCreditsPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    planCredits: jspb.Message.getFieldWithDefault(msg, 9, 0),
    totalCost: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.SubscriptionPresetResponse}
 */
proto.billing.SubscriptionPresetResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.SubscriptionPresetResponse;
  return proto.billing.SubscriptionPresetResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.SubscriptionPresetResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.SubscriptionPresetResponse}
 */
proto.billing.SubscriptionPresetResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEmailAccounts(value);
      break;
    case 2:
      var value = /** @type {!proto.billing.BillingSubscriptionType} */ (reader.readEnum());
      msg.setCurrentType(value);
      break;
    case 3:
      var value = /** @type {!proto.billing.BillingSubscriptionCycle} */ (reader.readEnum());
      msg.setCycle(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMailboxAccountMonthlyPrice(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMailboxAccountYearlyPrice(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCreditsMonthlyPrice(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCreditsYearlyPrice(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBonusCreditsPrice(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlanCredits(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalCost(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.SubscriptionPresetResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.SubscriptionPresetResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.SubscriptionPresetResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.SubscriptionPresetResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmailAccounts();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCurrentType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getCycle();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getMailboxAccountMonthlyPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getMailboxAccountYearlyPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getCreditsMonthlyPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getCreditsYearlyPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getBonusCreditsPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getPlanCredits();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getTotalCost();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
};


/**
 * optional int32 email_accounts = 1;
 * @return {number}
 */
proto.billing.SubscriptionPresetResponse.prototype.getEmailAccounts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.SubscriptionPresetResponse} returns this
 */
proto.billing.SubscriptionPresetResponse.prototype.setEmailAccounts = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional BillingSubscriptionType current_type = 2;
 * @return {!proto.billing.BillingSubscriptionType}
 */
proto.billing.SubscriptionPresetResponse.prototype.getCurrentType = function() {
  return /** @type {!proto.billing.BillingSubscriptionType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.billing.BillingSubscriptionType} value
 * @return {!proto.billing.SubscriptionPresetResponse} returns this
 */
proto.billing.SubscriptionPresetResponse.prototype.setCurrentType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional BillingSubscriptionCycle cycle = 3;
 * @return {!proto.billing.BillingSubscriptionCycle}
 */
proto.billing.SubscriptionPresetResponse.prototype.getCycle = function() {
  return /** @type {!proto.billing.BillingSubscriptionCycle} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.billing.BillingSubscriptionCycle} value
 * @return {!proto.billing.SubscriptionPresetResponse} returns this
 */
proto.billing.SubscriptionPresetResponse.prototype.setCycle = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional double mailbox_account_monthly_price = 4;
 * @return {number}
 */
proto.billing.SubscriptionPresetResponse.prototype.getMailboxAccountMonthlyPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.billing.SubscriptionPresetResponse} returns this
 */
proto.billing.SubscriptionPresetResponse.prototype.setMailboxAccountMonthlyPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double mailbox_account_yearly_price = 5;
 * @return {number}
 */
proto.billing.SubscriptionPresetResponse.prototype.getMailboxAccountYearlyPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.billing.SubscriptionPresetResponse} returns this
 */
proto.billing.SubscriptionPresetResponse.prototype.setMailboxAccountYearlyPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double credits_monthly_price = 6;
 * @return {number}
 */
proto.billing.SubscriptionPresetResponse.prototype.getCreditsMonthlyPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.billing.SubscriptionPresetResponse} returns this
 */
proto.billing.SubscriptionPresetResponse.prototype.setCreditsMonthlyPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double credits_yearly_price = 7;
 * @return {number}
 */
proto.billing.SubscriptionPresetResponse.prototype.getCreditsYearlyPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.billing.SubscriptionPresetResponse} returns this
 */
proto.billing.SubscriptionPresetResponse.prototype.setCreditsYearlyPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double bonus_credits_price = 8;
 * @return {number}
 */
proto.billing.SubscriptionPresetResponse.prototype.getBonusCreditsPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.billing.SubscriptionPresetResponse} returns this
 */
proto.billing.SubscriptionPresetResponse.prototype.setBonusCreditsPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional int32 plan_credits = 9;
 * @return {number}
 */
proto.billing.SubscriptionPresetResponse.prototype.getPlanCredits = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.SubscriptionPresetResponse} returns this
 */
proto.billing.SubscriptionPresetResponse.prototype.setPlanCredits = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 total_cost = 10;
 * @return {number}
 */
proto.billing.SubscriptionPresetResponse.prototype.getTotalCost = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.SubscriptionPresetResponse} returns this
 */
proto.billing.SubscriptionPresetResponse.prototype.setTotalCost = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.MailboxAccountPriceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.MailboxAccountPriceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.MailboxAccountPriceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.MailboxAccountPriceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    priceDueToday: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    nextSubscriptionChargeAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.MailboxAccountPriceResponse}
 */
proto.billing.MailboxAccountPriceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.MailboxAccountPriceResponse;
  return proto.billing.MailboxAccountPriceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.MailboxAccountPriceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.MailboxAccountPriceResponse}
 */
proto.billing.MailboxAccountPriceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPriceDueToday(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNextSubscriptionChargeAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.MailboxAccountPriceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.MailboxAccountPriceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.MailboxAccountPriceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.MailboxAccountPriceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPriceDueToday();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getNextSubscriptionChargeAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional double price_due_today = 1;
 * @return {number}
 */
proto.billing.MailboxAccountPriceResponse.prototype.getPriceDueToday = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.billing.MailboxAccountPriceResponse} returns this
 */
proto.billing.MailboxAccountPriceResponse.prototype.setPriceDueToday = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double next_subscription_charge_amount = 2;
 * @return {number}
 */
proto.billing.MailboxAccountPriceResponse.prototype.getNextSubscriptionChargeAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.billing.MailboxAccountPriceResponse} returns this
 */
proto.billing.MailboxAccountPriceResponse.prototype.setNextSubscriptionChargeAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.MailboxAccountChargeStrategyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.MailboxAccountChargeStrategyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.MailboxAccountChargeStrategyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.MailboxAccountChargeStrategyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    disable: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.MailboxAccountChargeStrategyRequest}
 */
proto.billing.MailboxAccountChargeStrategyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.MailboxAccountChargeStrategyRequest;
  return proto.billing.MailboxAccountChargeStrategyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.MailboxAccountChargeStrategyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.MailboxAccountChargeStrategyRequest}
 */
proto.billing.MailboxAccountChargeStrategyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.MailboxAccountChargeStrategyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.MailboxAccountChargeStrategyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.MailboxAccountChargeStrategyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.MailboxAccountChargeStrategyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDisable();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool disable = 1;
 * @return {boolean}
 */
proto.billing.MailboxAccountChargeStrategyRequest.prototype.getDisable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.MailboxAccountChargeStrategyRequest} returns this
 */
proto.billing.MailboxAccountChargeStrategyRequest.prototype.setDisable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.MailboxBonusCreditsStrategyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.MailboxBonusCreditsStrategyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.MailboxBonusCreditsStrategyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.MailboxBonusCreditsStrategyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    disable: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    packagecreditsnumber: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.MailboxBonusCreditsStrategyRequest}
 */
proto.billing.MailboxBonusCreditsStrategyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.MailboxBonusCreditsStrategyRequest;
  return proto.billing.MailboxBonusCreditsStrategyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.MailboxBonusCreditsStrategyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.MailboxBonusCreditsStrategyRequest}
 */
proto.billing.MailboxBonusCreditsStrategyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisable(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPackagecreditsnumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.MailboxBonusCreditsStrategyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.MailboxBonusCreditsStrategyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.MailboxBonusCreditsStrategyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.MailboxBonusCreditsStrategyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDisable();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getPackagecreditsnumber();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional bool disable = 1;
 * @return {boolean}
 */
proto.billing.MailboxBonusCreditsStrategyRequest.prototype.getDisable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.MailboxBonusCreditsStrategyRequest} returns this
 */
proto.billing.MailboxBonusCreditsStrategyRequest.prototype.setDisable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional int32 packageCreditsNumber = 2;
 * @return {number}
 */
proto.billing.MailboxBonusCreditsStrategyRequest.prototype.getPackagecreditsnumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.MailboxBonusCreditsStrategyRequest} returns this
 */
proto.billing.MailboxBonusCreditsStrategyRequest.prototype.setPackagecreditsnumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.SubscriptionChangeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.SubscriptionChangeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.SubscriptionChangeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.SubscriptionChangeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    emailAccounts: jspb.Message.getFieldWithDefault(msg, 1, 0),
    credits: jspb.Message.getFieldWithDefault(msg, 2, 0),
    contacts: jspb.Message.getFieldWithDefault(msg, 3, 0),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0),
    cycle: jspb.Message.getFieldWithDefault(msg, 5, 0),
    optionalBraintreeNonce: jspb.Message.getFieldWithDefault(msg, 6, ""),
    optionalBraintreeDeviceData: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.SubscriptionChangeRequest}
 */
proto.billing.SubscriptionChangeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.SubscriptionChangeRequest;
  return proto.billing.SubscriptionChangeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.SubscriptionChangeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.SubscriptionChangeRequest}
 */
proto.billing.SubscriptionChangeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEmailAccounts(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCredits(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setContacts(value);
      break;
    case 4:
      var value = /** @type {!proto.billing.BillingSubscriptionType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {!proto.billing.BillingSubscriptionCycle} */ (reader.readEnum());
      msg.setCycle(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionalBraintreeNonce(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionalBraintreeDeviceData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.SubscriptionChangeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.SubscriptionChangeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.SubscriptionChangeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.SubscriptionChangeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmailAccounts();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCredits();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getContacts();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getCycle();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getOptionalBraintreeNonce();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOptionalBraintreeDeviceData();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional int32 email_accounts = 1;
 * @return {number}
 */
proto.billing.SubscriptionChangeRequest.prototype.getEmailAccounts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.SubscriptionChangeRequest} returns this
 */
proto.billing.SubscriptionChangeRequest.prototype.setEmailAccounts = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 credits = 2;
 * @return {number}
 */
proto.billing.SubscriptionChangeRequest.prototype.getCredits = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.SubscriptionChangeRequest} returns this
 */
proto.billing.SubscriptionChangeRequest.prototype.setCredits = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 contacts = 3;
 * @return {number}
 */
proto.billing.SubscriptionChangeRequest.prototype.getContacts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.SubscriptionChangeRequest} returns this
 */
proto.billing.SubscriptionChangeRequest.prototype.setContacts = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional BillingSubscriptionType type = 4;
 * @return {!proto.billing.BillingSubscriptionType}
 */
proto.billing.SubscriptionChangeRequest.prototype.getType = function() {
  return /** @type {!proto.billing.BillingSubscriptionType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.billing.BillingSubscriptionType} value
 * @return {!proto.billing.SubscriptionChangeRequest} returns this
 */
proto.billing.SubscriptionChangeRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional BillingSubscriptionCycle cycle = 5;
 * @return {!proto.billing.BillingSubscriptionCycle}
 */
proto.billing.SubscriptionChangeRequest.prototype.getCycle = function() {
  return /** @type {!proto.billing.BillingSubscriptionCycle} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.billing.BillingSubscriptionCycle} value
 * @return {!proto.billing.SubscriptionChangeRequest} returns this
 */
proto.billing.SubscriptionChangeRequest.prototype.setCycle = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string optional_braintree_nonce = 6;
 * @return {string}
 */
proto.billing.SubscriptionChangeRequest.prototype.getOptionalBraintreeNonce = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.SubscriptionChangeRequest} returns this
 */
proto.billing.SubscriptionChangeRequest.prototype.setOptionalBraintreeNonce = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string optional_braintree_device_data = 7;
 * @return {string}
 */
proto.billing.SubscriptionChangeRequest.prototype.getOptionalBraintreeDeviceData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.SubscriptionChangeRequest} returns this
 */
proto.billing.SubscriptionChangeRequest.prototype.setOptionalBraintreeDeviceData = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.BillingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.BillingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.BillingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.BillingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    cycle: jspb.Message.getFieldWithDefault(msg, 3, 0),
    currentPaidCreditsNumber: jspb.Message.getFieldWithDefault(msg, 4, 0),
    currentBonusCreditsNumber: jspb.Message.getFieldWithDefault(msg, 5, 0),
    maxPaidCreditsNumber: jspb.Message.getFieldWithDefault(msg, 6, 0),
    currentMailboxAccountsNumber: jspb.Message.getFieldWithDefault(msg, 7, 0),
    totalMailboxAccountsNumber: jspb.Message.getFieldWithDefault(msg, 8, 0),
    currentContactsNumber: jspb.Message.getFieldWithDefault(msg, 9, 0),
    totalContactsNumber: jspb.Message.getFieldWithDefault(msg, 10, 0),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    state: jspb.Message.getFieldWithDefault(msg, 12, 0),
    autoRenewal: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    nextChargeTime: jspb.Message.getFieldWithDefault(msg, 14, 0),
    invoiceNumber: jspb.Message.getFieldWithDefault(msg, 15, ""),
    invoiceAmount: jspb.Message.getFieldWithDefault(msg, 16, 0),
    invoiceChargeTime: jspb.Message.getFieldWithDefault(msg, 17, 0),
    card: (f = msg.getCard()) && proto.billing.CreditCardInfo.toObject(includeInstance, f),
    contactName: jspb.Message.getFieldWithDefault(msg, 19, ""),
    contactEmail: jspb.Message.getFieldWithDefault(msg, 20, ""),
    deactivatedAt: jspb.Message.getFieldWithDefault(msg, 21, 0),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
    maintenanceStartedAt: jspb.Message.getFieldWithDefault(msg, 23, 0),
    paymentExpired: jspb.Message.getBooleanFieldWithDefault(msg, 24, false),
    extendedFeaturesEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 25, false),
    enterpriseFeaturesEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 26, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.BillingResponse}
 */
proto.billing.BillingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.BillingResponse;
  return proto.billing.BillingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.BillingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.BillingResponse}
 */
proto.billing.BillingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.billing.BillingSubscriptionType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {!proto.billing.BillingSubscriptionCycle} */ (reader.readEnum());
      msg.setCycle(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentPaidCreditsNumber(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentBonusCreditsNumber(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxPaidCreditsNumber(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentMailboxAccountsNumber(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalMailboxAccountsNumber(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentContactsNumber(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalContactsNumber(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 12:
      var value = /** @type {!proto.billing.BillingState} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutoRenewal(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNextChargeTime(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvoiceNumber(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInvoiceAmount(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInvoiceChargeTime(value);
      break;
    case 18:
      var value = new proto.billing.CreditCardInfo;
      reader.readMessage(value,proto.billing.CreditCardInfo.deserializeBinaryFromReader);
      msg.setCard(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactName(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactEmail(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeactivatedAt(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaintenanceStartedAt(value);
      break;
    case 24:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPaymentExpired(value);
      break;
    case 25:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExtendedFeaturesEnabled(value);
      break;
    case 26:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnterpriseFeaturesEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.BillingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.BillingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.BillingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.BillingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getCycle();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getCurrentPaidCreditsNumber();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getCurrentBonusCreditsNumber();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getMaxPaidCreditsNumber();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getCurrentMailboxAccountsNumber();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getTotalMailboxAccountsNumber();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getCurrentContactsNumber();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getTotalContactsNumber();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getAutoRenewal();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getNextChargeTime();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getInvoiceNumber();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getInvoiceAmount();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getInvoiceChargeTime();
  if (f !== 0) {
    writer.writeInt64(
      17,
      f
    );
  }
  f = message.getCard();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.billing.CreditCardInfo.serializeBinaryToWriter
    );
  }
  f = message.getContactName();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getContactEmail();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getDeactivatedAt();
  if (f !== 0) {
    writer.writeInt64(
      21,
      f
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
  f = message.getMaintenanceStartedAt();
  if (f !== 0) {
    writer.writeInt64(
      23,
      f
    );
  }
  f = message.getPaymentExpired();
  if (f) {
    writer.writeBool(
      24,
      f
    );
  }
  f = message.getExtendedFeaturesEnabled();
  if (f) {
    writer.writeBool(
      25,
      f
    );
  }
  f = message.getEnterpriseFeaturesEnabled();
  if (f) {
    writer.writeBool(
      26,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.billing.BillingResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.BillingResponse} returns this
 */
proto.billing.BillingResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional BillingSubscriptionType type = 2;
 * @return {!proto.billing.BillingSubscriptionType}
 */
proto.billing.BillingResponse.prototype.getType = function() {
  return /** @type {!proto.billing.BillingSubscriptionType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.billing.BillingSubscriptionType} value
 * @return {!proto.billing.BillingResponse} returns this
 */
proto.billing.BillingResponse.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional BillingSubscriptionCycle cycle = 3;
 * @return {!proto.billing.BillingSubscriptionCycle}
 */
proto.billing.BillingResponse.prototype.getCycle = function() {
  return /** @type {!proto.billing.BillingSubscriptionCycle} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.billing.BillingSubscriptionCycle} value
 * @return {!proto.billing.BillingResponse} returns this
 */
proto.billing.BillingResponse.prototype.setCycle = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int32 current_paid_credits_number = 4;
 * @return {number}
 */
proto.billing.BillingResponse.prototype.getCurrentPaidCreditsNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.BillingResponse} returns this
 */
proto.billing.BillingResponse.prototype.setCurrentPaidCreditsNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 current_bonus_credits_number = 5;
 * @return {number}
 */
proto.billing.BillingResponse.prototype.getCurrentBonusCreditsNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.BillingResponse} returns this
 */
proto.billing.BillingResponse.prototype.setCurrentBonusCreditsNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 max_paid_credits_number = 6;
 * @return {number}
 */
proto.billing.BillingResponse.prototype.getMaxPaidCreditsNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.BillingResponse} returns this
 */
proto.billing.BillingResponse.prototype.setMaxPaidCreditsNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 current_mailbox_accounts_number = 7;
 * @return {number}
 */
proto.billing.BillingResponse.prototype.getCurrentMailboxAccountsNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.BillingResponse} returns this
 */
proto.billing.BillingResponse.prototype.setCurrentMailboxAccountsNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 total_mailbox_accounts_number = 8;
 * @return {number}
 */
proto.billing.BillingResponse.prototype.getTotalMailboxAccountsNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.BillingResponse} returns this
 */
proto.billing.BillingResponse.prototype.setTotalMailboxAccountsNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 current_contacts_number = 9;
 * @return {number}
 */
proto.billing.BillingResponse.prototype.getCurrentContactsNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.BillingResponse} returns this
 */
proto.billing.BillingResponse.prototype.setCurrentContactsNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 total_contacts_number = 10;
 * @return {number}
 */
proto.billing.BillingResponse.prototype.getTotalContactsNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.BillingResponse} returns this
 */
proto.billing.BillingResponse.prototype.setTotalContactsNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional double amount = 11;
 * @return {number}
 */
proto.billing.BillingResponse.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.billing.BillingResponse} returns this
 */
proto.billing.BillingResponse.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional BillingState state = 12;
 * @return {!proto.billing.BillingState}
 */
proto.billing.BillingResponse.prototype.getState = function() {
  return /** @type {!proto.billing.BillingState} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.billing.BillingState} value
 * @return {!proto.billing.BillingResponse} returns this
 */
proto.billing.BillingResponse.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional bool auto_renewal = 13;
 * @return {boolean}
 */
proto.billing.BillingResponse.prototype.getAutoRenewal = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.BillingResponse} returns this
 */
proto.billing.BillingResponse.prototype.setAutoRenewal = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional int64 next_charge_time = 14;
 * @return {number}
 */
proto.billing.BillingResponse.prototype.getNextChargeTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.BillingResponse} returns this
 */
proto.billing.BillingResponse.prototype.setNextChargeTime = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional string invoice_number = 15;
 * @return {string}
 */
proto.billing.BillingResponse.prototype.getInvoiceNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.BillingResponse} returns this
 */
proto.billing.BillingResponse.prototype.setInvoiceNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional int32 invoice_amount = 16;
 * @return {number}
 */
proto.billing.BillingResponse.prototype.getInvoiceAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.BillingResponse} returns this
 */
proto.billing.BillingResponse.prototype.setInvoiceAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional int64 invoice_charge_time = 17;
 * @return {number}
 */
proto.billing.BillingResponse.prototype.getInvoiceChargeTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.BillingResponse} returns this
 */
proto.billing.BillingResponse.prototype.setInvoiceChargeTime = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional CreditCardInfo card = 18;
 * @return {?proto.billing.CreditCardInfo}
 */
proto.billing.BillingResponse.prototype.getCard = function() {
  return /** @type{?proto.billing.CreditCardInfo} */ (
    jspb.Message.getWrapperField(this, proto.billing.CreditCardInfo, 18));
};


/**
 * @param {?proto.billing.CreditCardInfo|undefined} value
 * @return {!proto.billing.BillingResponse} returns this
*/
proto.billing.BillingResponse.prototype.setCard = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.BillingResponse} returns this
 */
proto.billing.BillingResponse.prototype.clearCard = function() {
  return this.setCard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.BillingResponse.prototype.hasCard = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional string contact_name = 19;
 * @return {string}
 */
proto.billing.BillingResponse.prototype.getContactName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.BillingResponse} returns this
 */
proto.billing.BillingResponse.prototype.setContactName = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string contact_email = 20;
 * @return {string}
 */
proto.billing.BillingResponse.prototype.getContactEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.BillingResponse} returns this
 */
proto.billing.BillingResponse.prototype.setContactEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional int64 deactivated_at = 21;
 * @return {number}
 */
proto.billing.BillingResponse.prototype.getDeactivatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.BillingResponse} returns this
 */
proto.billing.BillingResponse.prototype.setDeactivatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional bool active = 22;
 * @return {boolean}
 */
proto.billing.BillingResponse.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.BillingResponse} returns this
 */
proto.billing.BillingResponse.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 22, value);
};


/**
 * optional int64 maintenance_started_at = 23;
 * @return {number}
 */
proto.billing.BillingResponse.prototype.getMaintenanceStartedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.BillingResponse} returns this
 */
proto.billing.BillingResponse.prototype.setMaintenanceStartedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional bool payment_expired = 24;
 * @return {boolean}
 */
proto.billing.BillingResponse.prototype.getPaymentExpired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 24, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.BillingResponse} returns this
 */
proto.billing.BillingResponse.prototype.setPaymentExpired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 24, value);
};


/**
 * optional bool extended_features_enabled = 25;
 * @return {boolean}
 */
proto.billing.BillingResponse.prototype.getExtendedFeaturesEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 25, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.BillingResponse} returns this
 */
proto.billing.BillingResponse.prototype.setExtendedFeaturesEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 25, value);
};


/**
 * optional bool enterprise_features_enabled = 26;
 * @return {boolean}
 */
proto.billing.BillingResponse.prototype.getEnterpriseFeaturesEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 26, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.BillingResponse} returns this
 */
proto.billing.BillingResponse.prototype.setEnterpriseFeaturesEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 26, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.BillingCreditsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.BillingCreditsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.BillingCreditsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.BillingCreditsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    currentPaidCreditsNumber: jspb.Message.getFieldWithDefault(msg, 1, 0),
    currentBonusCreditsNumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    maxPaidCreditsNumber: jspb.Message.getFieldWithDefault(msg, 3, 0),
    state: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.BillingCreditsResponse}
 */
proto.billing.BillingCreditsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.BillingCreditsResponse;
  return proto.billing.BillingCreditsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.BillingCreditsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.BillingCreditsResponse}
 */
proto.billing.BillingCreditsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentPaidCreditsNumber(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentBonusCreditsNumber(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxPaidCreditsNumber(value);
      break;
    case 4:
      var value = /** @type {!proto.billing.BillingState} */ (reader.readEnum());
      msg.setState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.BillingCreditsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.BillingCreditsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.BillingCreditsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.BillingCreditsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrentPaidCreditsNumber();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCurrentBonusCreditsNumber();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getMaxPaidCreditsNumber();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional int32 current_paid_credits_number = 1;
 * @return {number}
 */
proto.billing.BillingCreditsResponse.prototype.getCurrentPaidCreditsNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.BillingCreditsResponse} returns this
 */
proto.billing.BillingCreditsResponse.prototype.setCurrentPaidCreditsNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 current_bonus_credits_number = 2;
 * @return {number}
 */
proto.billing.BillingCreditsResponse.prototype.getCurrentBonusCreditsNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.BillingCreditsResponse} returns this
 */
proto.billing.BillingCreditsResponse.prototype.setCurrentBonusCreditsNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 max_paid_credits_number = 3;
 * @return {number}
 */
proto.billing.BillingCreditsResponse.prototype.getMaxPaidCreditsNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.BillingCreditsResponse} returns this
 */
proto.billing.BillingCreditsResponse.prototype.setMaxPaidCreditsNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional BillingState state = 4;
 * @return {!proto.billing.BillingState}
 */
proto.billing.BillingCreditsResponse.prototype.getState = function() {
  return /** @type {!proto.billing.BillingState} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.billing.BillingState} value
 * @return {!proto.billing.BillingCreditsResponse} returns this
 */
proto.billing.BillingCreditsResponse.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.BillingDetailsShortResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.BillingDetailsShortResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.BillingDetailsShortResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.BillingDetailsShortResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    currentPaidCreditsNumber: jspb.Message.getFieldWithDefault(msg, 1, 0),
    currentBonusCreditsNumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    maxPaidCreditsNumber: jspb.Message.getFieldWithDefault(msg, 3, 0),
    state: jspb.Message.getFieldWithDefault(msg, 4, 0),
    currentMailboxAccountsNumber: jspb.Message.getFieldWithDefault(msg, 5, 0),
    maxMailboxAccountsNumber: jspb.Message.getFieldWithDefault(msg, 6, 0),
    currentContactsNumber: jspb.Message.getFieldWithDefault(msg, 7, 0),
    totalContactsNumber: jspb.Message.getFieldWithDefault(msg, 8, 0),
    deactivatedAt: jspb.Message.getFieldWithDefault(msg, 9, 0),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    maintenanceStartedAt: jspb.Message.getFieldWithDefault(msg, 11, 0),
    paymentExpired: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    extendedFeaturesEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    enterpriseFeaturesEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 14, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.BillingDetailsShortResponse}
 */
proto.billing.BillingDetailsShortResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.BillingDetailsShortResponse;
  return proto.billing.BillingDetailsShortResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.BillingDetailsShortResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.BillingDetailsShortResponse}
 */
proto.billing.BillingDetailsShortResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentPaidCreditsNumber(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentBonusCreditsNumber(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxPaidCreditsNumber(value);
      break;
    case 4:
      var value = /** @type {!proto.billing.BillingState} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentMailboxAccountsNumber(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxMailboxAccountsNumber(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentContactsNumber(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalContactsNumber(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeactivatedAt(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaintenanceStartedAt(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPaymentExpired(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExtendedFeaturesEnabled(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnterpriseFeaturesEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.BillingDetailsShortResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.BillingDetailsShortResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.BillingDetailsShortResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.BillingDetailsShortResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrentPaidCreditsNumber();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCurrentBonusCreditsNumber();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getMaxPaidCreditsNumber();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getCurrentMailboxAccountsNumber();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getMaxMailboxAccountsNumber();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getCurrentContactsNumber();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getTotalContactsNumber();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getDeactivatedAt();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getMaintenanceStartedAt();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getPaymentExpired();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getExtendedFeaturesEnabled();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getEnterpriseFeaturesEnabled();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
};


/**
 * optional int32 current_paid_credits_number = 1;
 * @return {number}
 */
proto.billing.BillingDetailsShortResponse.prototype.getCurrentPaidCreditsNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.BillingDetailsShortResponse} returns this
 */
proto.billing.BillingDetailsShortResponse.prototype.setCurrentPaidCreditsNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 current_bonus_credits_number = 2;
 * @return {number}
 */
proto.billing.BillingDetailsShortResponse.prototype.getCurrentBonusCreditsNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.BillingDetailsShortResponse} returns this
 */
proto.billing.BillingDetailsShortResponse.prototype.setCurrentBonusCreditsNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 max_paid_credits_number = 3;
 * @return {number}
 */
proto.billing.BillingDetailsShortResponse.prototype.getMaxPaidCreditsNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.BillingDetailsShortResponse} returns this
 */
proto.billing.BillingDetailsShortResponse.prototype.setMaxPaidCreditsNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional BillingState state = 4;
 * @return {!proto.billing.BillingState}
 */
proto.billing.BillingDetailsShortResponse.prototype.getState = function() {
  return /** @type {!proto.billing.BillingState} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.billing.BillingState} value
 * @return {!proto.billing.BillingDetailsShortResponse} returns this
 */
proto.billing.BillingDetailsShortResponse.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int32 current_mailbox_accounts_number = 5;
 * @return {number}
 */
proto.billing.BillingDetailsShortResponse.prototype.getCurrentMailboxAccountsNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.BillingDetailsShortResponse} returns this
 */
proto.billing.BillingDetailsShortResponse.prototype.setCurrentMailboxAccountsNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 max_mailbox_accounts_number = 6;
 * @return {number}
 */
proto.billing.BillingDetailsShortResponse.prototype.getMaxMailboxAccountsNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.BillingDetailsShortResponse} returns this
 */
proto.billing.BillingDetailsShortResponse.prototype.setMaxMailboxAccountsNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 current_contacts_number = 7;
 * @return {number}
 */
proto.billing.BillingDetailsShortResponse.prototype.getCurrentContactsNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.BillingDetailsShortResponse} returns this
 */
proto.billing.BillingDetailsShortResponse.prototype.setCurrentContactsNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 total_contacts_number = 8;
 * @return {number}
 */
proto.billing.BillingDetailsShortResponse.prototype.getTotalContactsNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.BillingDetailsShortResponse} returns this
 */
proto.billing.BillingDetailsShortResponse.prototype.setTotalContactsNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 deactivated_at = 9;
 * @return {number}
 */
proto.billing.BillingDetailsShortResponse.prototype.getDeactivatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.BillingDetailsShortResponse} returns this
 */
proto.billing.BillingDetailsShortResponse.prototype.setDeactivatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional bool active = 10;
 * @return {boolean}
 */
proto.billing.BillingDetailsShortResponse.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.BillingDetailsShortResponse} returns this
 */
proto.billing.BillingDetailsShortResponse.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional int64 maintenance_started_at = 11;
 * @return {number}
 */
proto.billing.BillingDetailsShortResponse.prototype.getMaintenanceStartedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.BillingDetailsShortResponse} returns this
 */
proto.billing.BillingDetailsShortResponse.prototype.setMaintenanceStartedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional bool payment_expired = 12;
 * @return {boolean}
 */
proto.billing.BillingDetailsShortResponse.prototype.getPaymentExpired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.BillingDetailsShortResponse} returns this
 */
proto.billing.BillingDetailsShortResponse.prototype.setPaymentExpired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool extended_features_enabled = 13;
 * @return {boolean}
 */
proto.billing.BillingDetailsShortResponse.prototype.getExtendedFeaturesEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.BillingDetailsShortResponse} returns this
 */
proto.billing.BillingDetailsShortResponse.prototype.setExtendedFeaturesEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool enterprise_features_enabled = 14;
 * @return {boolean}
 */
proto.billing.BillingDetailsShortResponse.prototype.getEnterpriseFeaturesEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.BillingDetailsShortResponse} returns this
 */
proto.billing.BillingDetailsShortResponse.prototype.setEnterpriseFeaturesEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.SubscriptionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.SubscriptionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.SubscriptionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.SubscriptionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    cycle: jspb.Message.getFieldWithDefault(msg, 3, 0),
    creditsNumber: jspb.Message.getFieldWithDefault(msg, 4, 0),
    mailboxAccountsNumber: jspb.Message.getFieldWithDefault(msg, 5, 0),
    autoRenewal: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.SubscriptionRequest}
 */
proto.billing.SubscriptionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.SubscriptionRequest;
  return proto.billing.SubscriptionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.SubscriptionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.SubscriptionRequest}
 */
proto.billing.SubscriptionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.billing.BillingSubscriptionType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {!proto.billing.BillingSubscriptionCycle} */ (reader.readEnum());
      msg.setCycle(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCreditsNumber(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMailboxAccountsNumber(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutoRenewal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.SubscriptionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.SubscriptionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.SubscriptionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.SubscriptionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getCycle();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getCreditsNumber();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getMailboxAccountsNumber();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getAutoRenewal();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.billing.SubscriptionRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.SubscriptionRequest} returns this
 */
proto.billing.SubscriptionRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional BillingSubscriptionType type = 2;
 * @return {!proto.billing.BillingSubscriptionType}
 */
proto.billing.SubscriptionRequest.prototype.getType = function() {
  return /** @type {!proto.billing.BillingSubscriptionType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.billing.BillingSubscriptionType} value
 * @return {!proto.billing.SubscriptionRequest} returns this
 */
proto.billing.SubscriptionRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional BillingSubscriptionCycle cycle = 3;
 * @return {!proto.billing.BillingSubscriptionCycle}
 */
proto.billing.SubscriptionRequest.prototype.getCycle = function() {
  return /** @type {!proto.billing.BillingSubscriptionCycle} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.billing.BillingSubscriptionCycle} value
 * @return {!proto.billing.SubscriptionRequest} returns this
 */
proto.billing.SubscriptionRequest.prototype.setCycle = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int32 credits_number = 4;
 * @return {number}
 */
proto.billing.SubscriptionRequest.prototype.getCreditsNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.SubscriptionRequest} returns this
 */
proto.billing.SubscriptionRequest.prototype.setCreditsNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 mailbox_accounts_number = 5;
 * @return {number}
 */
proto.billing.SubscriptionRequest.prototype.getMailboxAccountsNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.SubscriptionRequest} returns this
 */
proto.billing.SubscriptionRequest.prototype.setMailboxAccountsNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bool auto_renewal = 6;
 * @return {boolean}
 */
proto.billing.SubscriptionRequest.prototype.getAutoRenewal = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.SubscriptionRequest} returns this
 */
proto.billing.SubscriptionRequest.prototype.setAutoRenewal = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.SubscriptionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.SubscriptionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.SubscriptionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.SubscriptionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    cycle: jspb.Message.getFieldWithDefault(msg, 3, 0),
    creditsNumber: jspb.Message.getFieldWithDefault(msg, 4, 0),
    mailboxAccountsNumber: jspb.Message.getFieldWithDefault(msg, 5, 0),
    autoRenewal: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    startAt: jspb.Message.getFieldWithDefault(msg, 8, 0),
    expireAt: jspb.Message.getFieldWithDefault(msg, 9, 0),
    nextAttempt: jspb.Message.getFieldWithDefault(msg, 10, 0),
    isPaused: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    isSucceed: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    comment: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.SubscriptionResponse}
 */
proto.billing.SubscriptionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.SubscriptionResponse;
  return proto.billing.SubscriptionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.SubscriptionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.SubscriptionResponse}
 */
proto.billing.SubscriptionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.billing.BillingSubscriptionType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {!proto.billing.BillingSubscriptionCycle} */ (reader.readEnum());
      msg.setCycle(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCreditsNumber(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMailboxAccountsNumber(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutoRenewal(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartAt(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpireAt(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNextAttempt(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPaused(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSucceed(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.SubscriptionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.SubscriptionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.SubscriptionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.SubscriptionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getCycle();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getCreditsNumber();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getMailboxAccountsNumber();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getAutoRenewal();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getStartAt();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getExpireAt();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getNextAttempt();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getIsPaused();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getIsSucceed();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.billing.SubscriptionResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.SubscriptionResponse} returns this
 */
proto.billing.SubscriptionResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional BillingSubscriptionType type = 2;
 * @return {!proto.billing.BillingSubscriptionType}
 */
proto.billing.SubscriptionResponse.prototype.getType = function() {
  return /** @type {!proto.billing.BillingSubscriptionType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.billing.BillingSubscriptionType} value
 * @return {!proto.billing.SubscriptionResponse} returns this
 */
proto.billing.SubscriptionResponse.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional BillingSubscriptionCycle cycle = 3;
 * @return {!proto.billing.BillingSubscriptionCycle}
 */
proto.billing.SubscriptionResponse.prototype.getCycle = function() {
  return /** @type {!proto.billing.BillingSubscriptionCycle} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.billing.BillingSubscriptionCycle} value
 * @return {!proto.billing.SubscriptionResponse} returns this
 */
proto.billing.SubscriptionResponse.prototype.setCycle = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int32 credits_number = 4;
 * @return {number}
 */
proto.billing.SubscriptionResponse.prototype.getCreditsNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.SubscriptionResponse} returns this
 */
proto.billing.SubscriptionResponse.prototype.setCreditsNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 mailbox_accounts_number = 5;
 * @return {number}
 */
proto.billing.SubscriptionResponse.prototype.getMailboxAccountsNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.SubscriptionResponse} returns this
 */
proto.billing.SubscriptionResponse.prototype.setMailboxAccountsNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bool auto_renewal = 6;
 * @return {boolean}
 */
proto.billing.SubscriptionResponse.prototype.getAutoRenewal = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.SubscriptionResponse} returns this
 */
proto.billing.SubscriptionResponse.prototype.setAutoRenewal = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional double amount = 7;
 * @return {number}
 */
proto.billing.SubscriptionResponse.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.billing.SubscriptionResponse} returns this
 */
proto.billing.SubscriptionResponse.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional int64 start_at = 8;
 * @return {number}
 */
proto.billing.SubscriptionResponse.prototype.getStartAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.SubscriptionResponse} returns this
 */
proto.billing.SubscriptionResponse.prototype.setStartAt = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 expire_at = 9;
 * @return {number}
 */
proto.billing.SubscriptionResponse.prototype.getExpireAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.SubscriptionResponse} returns this
 */
proto.billing.SubscriptionResponse.prototype.setExpireAt = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 next_attempt = 10;
 * @return {number}
 */
proto.billing.SubscriptionResponse.prototype.getNextAttempt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.SubscriptionResponse} returns this
 */
proto.billing.SubscriptionResponse.prototype.setNextAttempt = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional bool is_paused = 11;
 * @return {boolean}
 */
proto.billing.SubscriptionResponse.prototype.getIsPaused = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.SubscriptionResponse} returns this
 */
proto.billing.SubscriptionResponse.prototype.setIsPaused = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool is_succeed = 12;
 * @return {boolean}
 */
proto.billing.SubscriptionResponse.prototype.getIsSucceed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.SubscriptionResponse} returns this
 */
proto.billing.SubscriptionResponse.prototype.setIsSucceed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional string comment = 13;
 * @return {string}
 */
proto.billing.SubscriptionResponse.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.SubscriptionResponse} returns this
 */
proto.billing.SubscriptionResponse.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.UpdateCreditCardRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.UpdateCreditCardRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.UpdateCreditCardRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.UpdateCreditCardRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    braintreeNonce: jspb.Message.getFieldWithDefault(msg, 1, ""),
    braintreeDeviceData: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.UpdateCreditCardRequest}
 */
proto.billing.UpdateCreditCardRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.UpdateCreditCardRequest;
  return proto.billing.UpdateCreditCardRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.UpdateCreditCardRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.UpdateCreditCardRequest}
 */
proto.billing.UpdateCreditCardRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBraintreeNonce(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBraintreeDeviceData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.UpdateCreditCardRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.UpdateCreditCardRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.UpdateCreditCardRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.UpdateCreditCardRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBraintreeNonce();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBraintreeDeviceData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string braintree_nonce = 1;
 * @return {string}
 */
proto.billing.UpdateCreditCardRequest.prototype.getBraintreeNonce = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.UpdateCreditCardRequest} returns this
 */
proto.billing.UpdateCreditCardRequest.prototype.setBraintreeNonce = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string braintree_device_data = 2;
 * @return {string}
 */
proto.billing.UpdateCreditCardRequest.prototype.getBraintreeDeviceData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.UpdateCreditCardRequest} returns this
 */
proto.billing.UpdateCreditCardRequest.prototype.setBraintreeDeviceData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.CreditCardInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.CreditCardInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.CreditCardInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.CreditCardInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    creditCardNumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    expirationMonth: jspb.Message.getFieldWithDefault(msg, 2, ""),
    expirationYear: jspb.Message.getFieldWithDefault(msg, 3, ""),
    country: jspb.Message.getFieldWithDefault(msg, 4, ""),
    postalCode: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.CreditCardInfo}
 */
proto.billing.CreditCardInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.CreditCardInfo;
  return proto.billing.CreditCardInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.CreditCardInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.CreditCardInfo}
 */
proto.billing.CreditCardInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreditCardNumber(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExpirationMonth(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setExpirationYear(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.CreditCardInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.CreditCardInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.CreditCardInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.CreditCardInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreditCardNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExpirationMonth();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExpirationYear();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPostalCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string credit_card_number = 1;
 * @return {string}
 */
proto.billing.CreditCardInfo.prototype.getCreditCardNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.CreditCardInfo} returns this
 */
proto.billing.CreditCardInfo.prototype.setCreditCardNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string expiration_month = 2;
 * @return {string}
 */
proto.billing.CreditCardInfo.prototype.getExpirationMonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.CreditCardInfo} returns this
 */
proto.billing.CreditCardInfo.prototype.setExpirationMonth = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string expiration_year = 3;
 * @return {string}
 */
proto.billing.CreditCardInfo.prototype.getExpirationYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.CreditCardInfo} returns this
 */
proto.billing.CreditCardInfo.prototype.setExpirationYear = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string country = 4;
 * @return {string}
 */
proto.billing.CreditCardInfo.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.CreditCardInfo} returns this
 */
proto.billing.CreditCardInfo.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string postal_code = 5;
 * @return {string}
 */
proto.billing.CreditCardInfo.prototype.getPostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.CreditCardInfo} returns this
 */
proto.billing.CreditCardInfo.prototype.setPostalCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.InvoiceShortResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.InvoiceShortResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.InvoiceShortResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.InvoiceShortResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    organizationId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    number: jspb.Message.getFieldWithDefault(msg, 3, ""),
    date: jspb.Message.getFieldWithDefault(msg, 4, 0),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0),
    totalItems: jspb.Message.getFieldWithDefault(msg, 7, 0),
    custom: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.InvoiceShortResponse}
 */
proto.billing.InvoiceShortResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.InvoiceShortResponse;
  return proto.billing.InvoiceShortResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.InvoiceShortResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.InvoiceShortResponse}
 */
proto.billing.InvoiceShortResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrganizationId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDate(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 6:
      var value = /** @type {!proto.billing.InvoiceStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalItems(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCustom(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.InvoiceShortResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.InvoiceShortResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.InvoiceShortResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.InvoiceShortResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOrganizationId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDate();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getTotalItems();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getCustom();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.billing.InvoiceShortResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.InvoiceShortResponse} returns this
 */
proto.billing.InvoiceShortResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 organization_id = 2;
 * @return {number}
 */
proto.billing.InvoiceShortResponse.prototype.getOrganizationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.InvoiceShortResponse} returns this
 */
proto.billing.InvoiceShortResponse.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string number = 3;
 * @return {string}
 */
proto.billing.InvoiceShortResponse.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.InvoiceShortResponse} returns this
 */
proto.billing.InvoiceShortResponse.prototype.setNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 date = 4;
 * @return {number}
 */
proto.billing.InvoiceShortResponse.prototype.getDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.InvoiceShortResponse} returns this
 */
proto.billing.InvoiceShortResponse.prototype.setDate = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional double amount = 5;
 * @return {number}
 */
proto.billing.InvoiceShortResponse.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.billing.InvoiceShortResponse} returns this
 */
proto.billing.InvoiceShortResponse.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional InvoiceStatus status = 6;
 * @return {!proto.billing.InvoiceStatus}
 */
proto.billing.InvoiceShortResponse.prototype.getStatus = function() {
  return /** @type {!proto.billing.InvoiceStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.billing.InvoiceStatus} value
 * @return {!proto.billing.InvoiceShortResponse} returns this
 */
proto.billing.InvoiceShortResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional int32 total_items = 7;
 * @return {number}
 */
proto.billing.InvoiceShortResponse.prototype.getTotalItems = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.InvoiceShortResponse} returns this
 */
proto.billing.InvoiceShortResponse.prototype.setTotalItems = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional bool custom = 8;
 * @return {boolean}
 */
proto.billing.InvoiceShortResponse.prototype.getCustom = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.InvoiceShortResponse} returns this
 */
proto.billing.InvoiceShortResponse.prototype.setCustom = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.InvoiceContainer.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.InvoiceContainer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.InvoiceContainer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.InvoiceContainer.toObject = function(includeInstance, msg) {
  var f, obj = {
    isCustom: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    invoice: (f = msg.getInvoice()) && proto.billing.InvoiceDetailsResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.InvoiceContainer}
 */
proto.billing.InvoiceContainer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.InvoiceContainer;
  return proto.billing.InvoiceContainer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.InvoiceContainer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.InvoiceContainer}
 */
proto.billing.InvoiceContainer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCustom(value);
      break;
    case 2:
      var value = new proto.billing.InvoiceDetailsResponse;
      reader.readMessage(value,proto.billing.InvoiceDetailsResponse.deserializeBinaryFromReader);
      msg.setInvoice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.InvoiceContainer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.InvoiceContainer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.InvoiceContainer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.InvoiceContainer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsCustom();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getInvoice();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.billing.InvoiceDetailsResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_custom = 1;
 * @return {boolean}
 */
proto.billing.InvoiceContainer.prototype.getIsCustom = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.InvoiceContainer} returns this
 */
proto.billing.InvoiceContainer.prototype.setIsCustom = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional InvoiceDetailsResponse invoice = 2;
 * @return {?proto.billing.InvoiceDetailsResponse}
 */
proto.billing.InvoiceContainer.prototype.getInvoice = function() {
  return /** @type{?proto.billing.InvoiceDetailsResponse} */ (
    jspb.Message.getWrapperField(this, proto.billing.InvoiceDetailsResponse, 2));
};


/**
 * @param {?proto.billing.InvoiceDetailsResponse|undefined} value
 * @return {!proto.billing.InvoiceContainer} returns this
*/
proto.billing.InvoiceContainer.prototype.setInvoice = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.InvoiceContainer} returns this
 */
proto.billing.InvoiceContainer.prototype.clearInvoice = function() {
  return this.setInvoice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.InvoiceContainer.prototype.hasInvoice = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing.InvoiceDetailsResponse.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.InvoiceDetailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.InvoiceDetailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.InvoiceDetailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.InvoiceDetailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    number: jspb.Message.getFieldWithDefault(msg, 2, ""),
    issueDate: jspb.Message.getFieldWithDefault(msg, 3, 0),
    chargeAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    refundAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0),
    comment: jspb.Message.getFieldWithDefault(msg, 7, ""),
    rowsList: jspb.Message.toObjectList(msg.getRowsList(),
    proto.billing.InvoiceRow.toObject, includeInstance),
    recipient: (f = msg.getRecipient()) && proto.billing.InvoiceRecipient.toObject(includeInstance, f),
    accountId: jspb.Message.getFieldWithDefault(msg, 10, 0),
    custom: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    type: jspb.Message.getFieldWithDefault(msg, 12, 0),
    cycle: jspb.Message.getFieldWithDefault(msg, 13, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.InvoiceDetailsResponse}
 */
proto.billing.InvoiceDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.InvoiceDetailsResponse;
  return proto.billing.InvoiceDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.InvoiceDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.InvoiceDetailsResponse}
 */
proto.billing.InvoiceDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIssueDate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setChargeAmount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRefundAmount(value);
      break;
    case 6:
      var value = /** @type {!proto.billing.InvoiceStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 8:
      var value = new proto.billing.InvoiceRow;
      reader.readMessage(value,proto.billing.InvoiceRow.deserializeBinaryFromReader);
      msg.addRows(value);
      break;
    case 9:
      var value = new proto.billing.InvoiceRecipient;
      reader.readMessage(value,proto.billing.InvoiceRecipient.deserializeBinaryFromReader);
      msg.setRecipient(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAccountId(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCustom(value);
      break;
    case 12:
      var value = /** @type {!proto.billing.BillingSubscriptionType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 13:
      var value = /** @type {!proto.billing.BillingSubscriptionCycle} */ (reader.readEnum());
      msg.setCycle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.InvoiceDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.InvoiceDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.InvoiceDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.InvoiceDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIssueDate();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getChargeAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getRefundAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getRowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.billing.InvoiceRow.serializeBinaryToWriter
    );
  }
  f = message.getRecipient();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.billing.InvoiceRecipient.serializeBinaryToWriter
    );
  }
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getCustom();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getCycle();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.billing.InvoiceDetailsResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.InvoiceDetailsResponse} returns this
 */
proto.billing.InvoiceDetailsResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string number = 2;
 * @return {string}
 */
proto.billing.InvoiceDetailsResponse.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.InvoiceDetailsResponse} returns this
 */
proto.billing.InvoiceDetailsResponse.prototype.setNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 issue_date = 3;
 * @return {number}
 */
proto.billing.InvoiceDetailsResponse.prototype.getIssueDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.InvoiceDetailsResponse} returns this
 */
proto.billing.InvoiceDetailsResponse.prototype.setIssueDate = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional double charge_amount = 4;
 * @return {number}
 */
proto.billing.InvoiceDetailsResponse.prototype.getChargeAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.billing.InvoiceDetailsResponse} returns this
 */
proto.billing.InvoiceDetailsResponse.prototype.setChargeAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double refund_amount = 5;
 * @return {number}
 */
proto.billing.InvoiceDetailsResponse.prototype.getRefundAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.billing.InvoiceDetailsResponse} returns this
 */
proto.billing.InvoiceDetailsResponse.prototype.setRefundAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional InvoiceStatus status = 6;
 * @return {!proto.billing.InvoiceStatus}
 */
proto.billing.InvoiceDetailsResponse.prototype.getStatus = function() {
  return /** @type {!proto.billing.InvoiceStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.billing.InvoiceStatus} value
 * @return {!proto.billing.InvoiceDetailsResponse} returns this
 */
proto.billing.InvoiceDetailsResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string comment = 7;
 * @return {string}
 */
proto.billing.InvoiceDetailsResponse.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.InvoiceDetailsResponse} returns this
 */
proto.billing.InvoiceDetailsResponse.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated InvoiceRow rows = 8;
 * @return {!Array<!proto.billing.InvoiceRow>}
 */
proto.billing.InvoiceDetailsResponse.prototype.getRowsList = function() {
  return /** @type{!Array<!proto.billing.InvoiceRow>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing.InvoiceRow, 8));
};


/**
 * @param {!Array<!proto.billing.InvoiceRow>} value
 * @return {!proto.billing.InvoiceDetailsResponse} returns this
*/
proto.billing.InvoiceDetailsResponse.prototype.setRowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.billing.InvoiceRow=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing.InvoiceRow}
 */
proto.billing.InvoiceDetailsResponse.prototype.addRows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.billing.InvoiceRow, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing.InvoiceDetailsResponse} returns this
 */
proto.billing.InvoiceDetailsResponse.prototype.clearRowsList = function() {
  return this.setRowsList([]);
};


/**
 * optional InvoiceRecipient recipient = 9;
 * @return {?proto.billing.InvoiceRecipient}
 */
proto.billing.InvoiceDetailsResponse.prototype.getRecipient = function() {
  return /** @type{?proto.billing.InvoiceRecipient} */ (
    jspb.Message.getWrapperField(this, proto.billing.InvoiceRecipient, 9));
};


/**
 * @param {?proto.billing.InvoiceRecipient|undefined} value
 * @return {!proto.billing.InvoiceDetailsResponse} returns this
*/
proto.billing.InvoiceDetailsResponse.prototype.setRecipient = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.InvoiceDetailsResponse} returns this
 */
proto.billing.InvoiceDetailsResponse.prototype.clearRecipient = function() {
  return this.setRecipient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.InvoiceDetailsResponse.prototype.hasRecipient = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional int64 account_id = 10;
 * @return {number}
 */
proto.billing.InvoiceDetailsResponse.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.InvoiceDetailsResponse} returns this
 */
proto.billing.InvoiceDetailsResponse.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional bool custom = 11;
 * @return {boolean}
 */
proto.billing.InvoiceDetailsResponse.prototype.getCustom = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.InvoiceDetailsResponse} returns this
 */
proto.billing.InvoiceDetailsResponse.prototype.setCustom = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional BillingSubscriptionType type = 12;
 * @return {!proto.billing.BillingSubscriptionType}
 */
proto.billing.InvoiceDetailsResponse.prototype.getType = function() {
  return /** @type {!proto.billing.BillingSubscriptionType} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.billing.BillingSubscriptionType} value
 * @return {!proto.billing.InvoiceDetailsResponse} returns this
 */
proto.billing.InvoiceDetailsResponse.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional BillingSubscriptionCycle cycle = 13;
 * @return {!proto.billing.BillingSubscriptionCycle}
 */
proto.billing.InvoiceDetailsResponse.prototype.getCycle = function() {
  return /** @type {!proto.billing.BillingSubscriptionCycle} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.billing.BillingSubscriptionCycle} value
 * @return {!proto.billing.InvoiceDetailsResponse} returns this
 */
proto.billing.InvoiceDetailsResponse.prototype.setCycle = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.InvoiceRow.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.InvoiceRow.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.InvoiceRow} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.InvoiceRow.toObject = function(includeInstance, msg) {
  var f, obj = {
    order: jspb.Message.getFieldWithDefault(msg, 1, 0),
    quantity: jspb.Message.getFieldWithDefault(msg, 2, 0),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    type: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.InvoiceRow}
 */
proto.billing.InvoiceRow.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.InvoiceRow;
  return proto.billing.InvoiceRow.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.InvoiceRow} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.InvoiceRow}
 */
proto.billing.InvoiceRow.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuantity(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {!proto.billing.InvoiceRowType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.InvoiceRow.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.InvoiceRow.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.InvoiceRow} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.InvoiceRow.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional int32 order = 1;
 * @return {number}
 */
proto.billing.InvoiceRow.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.InvoiceRow} returns this
 */
proto.billing.InvoiceRow.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 quantity = 2;
 * @return {number}
 */
proto.billing.InvoiceRow.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.InvoiceRow} returns this
 */
proto.billing.InvoiceRow.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional double amount = 3;
 * @return {number}
 */
proto.billing.InvoiceRow.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.billing.InvoiceRow} returns this
 */
proto.billing.InvoiceRow.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.billing.InvoiceRow.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.InvoiceRow} returns this
 */
proto.billing.InvoiceRow.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional InvoiceRowType type = 5;
 * @return {!proto.billing.InvoiceRowType}
 */
proto.billing.InvoiceRow.prototype.getType = function() {
  return /** @type {!proto.billing.InvoiceRowType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.billing.InvoiceRowType} value
 * @return {!proto.billing.InvoiceRow} returns this
 */
proto.billing.InvoiceRow.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.InvoiceRecipient.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.InvoiceRecipient.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.InvoiceRecipient} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.InvoiceRecipient.toObject = function(includeInstance, msg) {
  var f, obj = {
    company: jspb.Message.getFieldWithDefault(msg, 1, ""),
    addressFirstLine: jspb.Message.getFieldWithDefault(msg, 2, ""),
    addressSecondLine: jspb.Message.getFieldWithDefault(msg, 3, ""),
    city: jspb.Message.getFieldWithDefault(msg, 4, ""),
    zipCode: jspb.Message.getFieldWithDefault(msg, 5, ""),
    state: jspb.Message.getFieldWithDefault(msg, 6, ""),
    country: jspb.Message.getFieldWithDefault(msg, 7, ""),
    fullName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    tax: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.InvoiceRecipient}
 */
proto.billing.InvoiceRecipient.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.InvoiceRecipient;
  return proto.billing.InvoiceRecipient.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.InvoiceRecipient} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.InvoiceRecipient}
 */
proto.billing.InvoiceRecipient.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompany(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressFirstLine(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressSecondLine(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setZipCode(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setTax(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.InvoiceRecipient.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.InvoiceRecipient.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.InvoiceRecipient} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.InvoiceRecipient.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompany();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAddressFirstLine();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAddressSecondLine();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getZipCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getFullName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getTax();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string company = 1;
 * @return {string}
 */
proto.billing.InvoiceRecipient.prototype.getCompany = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.InvoiceRecipient} returns this
 */
proto.billing.InvoiceRecipient.prototype.setCompany = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string address_first_line = 2;
 * @return {string}
 */
proto.billing.InvoiceRecipient.prototype.getAddressFirstLine = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.InvoiceRecipient} returns this
 */
proto.billing.InvoiceRecipient.prototype.setAddressFirstLine = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string address_second_line = 3;
 * @return {string}
 */
proto.billing.InvoiceRecipient.prototype.getAddressSecondLine = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.InvoiceRecipient} returns this
 */
proto.billing.InvoiceRecipient.prototype.setAddressSecondLine = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string city = 4;
 * @return {string}
 */
proto.billing.InvoiceRecipient.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.InvoiceRecipient} returns this
 */
proto.billing.InvoiceRecipient.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string zip_code = 5;
 * @return {string}
 */
proto.billing.InvoiceRecipient.prototype.getZipCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.InvoiceRecipient} returns this
 */
proto.billing.InvoiceRecipient.prototype.setZipCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string state = 6;
 * @return {string}
 */
proto.billing.InvoiceRecipient.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.InvoiceRecipient} returns this
 */
proto.billing.InvoiceRecipient.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string country = 7;
 * @return {string}
 */
proto.billing.InvoiceRecipient.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.InvoiceRecipient} returns this
 */
proto.billing.InvoiceRecipient.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string full_name = 8;
 * @return {string}
 */
proto.billing.InvoiceRecipient.prototype.getFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.InvoiceRecipient} returns this
 */
proto.billing.InvoiceRecipient.prototype.setFullName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string tax = 9;
 * @return {string}
 */
proto.billing.InvoiceRecipient.prototype.getTax = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.InvoiceRecipient} returns this
 */
proto.billing.InvoiceRecipient.prototype.setTax = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.InvoiceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.InvoiceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.InvoiceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.InvoiceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dateRange: (f = msg.getDateRange()) && common_pb.TimestampRange.toObject(includeInstance, f),
    page: jspb.Message.getFieldWithDefault(msg, 3, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.InvoiceRequest}
 */
proto.billing.InvoiceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.InvoiceRequest;
  return proto.billing.InvoiceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.InvoiceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.InvoiceRequest}
 */
proto.billing.InvoiceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 2:
      var value = new common_pb.TimestampRange;
      reader.readMessage(value,common_pb.TimestampRange.deserializeBinaryFromReader);
      msg.setDateRange(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.InvoiceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.InvoiceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.InvoiceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.InvoiceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDateRange();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.TimestampRange.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string query = 1;
 * @return {string}
 */
proto.billing.InvoiceRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.InvoiceRequest} returns this
 */
proto.billing.InvoiceRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional common.TimestampRange date_range = 2;
 * @return {?proto.common.TimestampRange}
 */
proto.billing.InvoiceRequest.prototype.getDateRange = function() {
  return /** @type{?proto.common.TimestampRange} */ (
    jspb.Message.getWrapperField(this, common_pb.TimestampRange, 2));
};


/**
 * @param {?proto.common.TimestampRange|undefined} value
 * @return {!proto.billing.InvoiceRequest} returns this
*/
proto.billing.InvoiceRequest.prototype.setDateRange = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.InvoiceRequest} returns this
 */
proto.billing.InvoiceRequest.prototype.clearDateRange = function() {
  return this.setDateRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.InvoiceRequest.prototype.hasDateRange = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 page = 3;
 * @return {number}
 */
proto.billing.InvoiceRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.InvoiceRequest} returns this
 */
proto.billing.InvoiceRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 limit = 4;
 * @return {number}
 */
proto.billing.InvoiceRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.InvoiceRequest} returns this
 */
proto.billing.InvoiceRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.BillingAddress.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.BillingAddress.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.BillingAddress} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.BillingAddress.toObject = function(includeInstance, msg) {
  var f, obj = {
    addressId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    companyName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    firstLine: jspb.Message.getFieldWithDefault(msg, 3, ""),
    secondLine: jspb.Message.getFieldWithDefault(msg, 4, ""),
    city: jspb.Message.getFieldWithDefault(msg, 5, ""),
    zipCode: jspb.Message.getFieldWithDefault(msg, 6, ""),
    state: jspb.Message.getFieldWithDefault(msg, 7, ""),
    country: jspb.Message.getFieldWithDefault(msg, 8, ""),
    contactEmail: jspb.Message.getFieldWithDefault(msg, 9, ""),
    contactName: jspb.Message.getFieldWithDefault(msg, 10, ""),
    tax: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.BillingAddress}
 */
proto.billing.BillingAddress.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.BillingAddress;
  return proto.billing.BillingAddress.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.BillingAddress} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.BillingAddress}
 */
proto.billing.BillingAddress.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAddressId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstLine(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecondLine(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setZipCode(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactEmail(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactName(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setTax(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.BillingAddress.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.BillingAddress.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.BillingAddress} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.BillingAddress.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddressId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCompanyName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFirstLine();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSecondLine();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getZipCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getContactEmail();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getContactName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getTax();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional int64 address_id = 1;
 * @return {number}
 */
proto.billing.BillingAddress.prototype.getAddressId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.BillingAddress} returns this
 */
proto.billing.BillingAddress.prototype.setAddressId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string company_name = 2;
 * @return {string}
 */
proto.billing.BillingAddress.prototype.getCompanyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.BillingAddress} returns this
 */
proto.billing.BillingAddress.prototype.setCompanyName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string first_line = 3;
 * @return {string}
 */
proto.billing.BillingAddress.prototype.getFirstLine = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.BillingAddress} returns this
 */
proto.billing.BillingAddress.prototype.setFirstLine = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string second_line = 4;
 * @return {string}
 */
proto.billing.BillingAddress.prototype.getSecondLine = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.BillingAddress} returns this
 */
proto.billing.BillingAddress.prototype.setSecondLine = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string city = 5;
 * @return {string}
 */
proto.billing.BillingAddress.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.BillingAddress} returns this
 */
proto.billing.BillingAddress.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string zip_code = 6;
 * @return {string}
 */
proto.billing.BillingAddress.prototype.getZipCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.BillingAddress} returns this
 */
proto.billing.BillingAddress.prototype.setZipCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string state = 7;
 * @return {string}
 */
proto.billing.BillingAddress.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.BillingAddress} returns this
 */
proto.billing.BillingAddress.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string country = 8;
 * @return {string}
 */
proto.billing.BillingAddress.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.BillingAddress} returns this
 */
proto.billing.BillingAddress.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string contact_email = 9;
 * @return {string}
 */
proto.billing.BillingAddress.prototype.getContactEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.BillingAddress} returns this
 */
proto.billing.BillingAddress.prototype.setContactEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string contact_name = 10;
 * @return {string}
 */
proto.billing.BillingAddress.prototype.getContactName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.BillingAddress} returns this
 */
proto.billing.BillingAddress.prototype.setContactName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string tax = 11;
 * @return {string}
 */
proto.billing.BillingAddress.prototype.getTax = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.BillingAddress} returns this
 */
proto.billing.BillingAddress.prototype.setTax = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.PaymentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.PaymentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.PaymentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.PaymentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    invoiceId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    ccNumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    braintreeNonce: jspb.Message.getFieldWithDefault(msg, 3, ""),
    braintreeDeviceData: jspb.Message.getFieldWithDefault(msg, 4, ""),
    optionalRecipient: (f = msg.getOptionalRecipient()) && proto.billing.InvoiceRecipient.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.PaymentRequest}
 */
proto.billing.PaymentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.PaymentRequest;
  return proto.billing.PaymentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.PaymentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.PaymentRequest}
 */
proto.billing.PaymentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInvoiceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCcNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBraintreeNonce(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBraintreeDeviceData(value);
      break;
    case 5:
      var value = new proto.billing.InvoiceRecipient;
      reader.readMessage(value,proto.billing.InvoiceRecipient.deserializeBinaryFromReader);
      msg.setOptionalRecipient(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.PaymentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.PaymentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.PaymentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.PaymentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoiceId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCcNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBraintreeNonce();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBraintreeDeviceData();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOptionalRecipient();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.billing.InvoiceRecipient.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 invoice_id = 1;
 * @return {number}
 */
proto.billing.PaymentRequest.prototype.getInvoiceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.PaymentRequest} returns this
 */
proto.billing.PaymentRequest.prototype.setInvoiceId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string cc_number = 2;
 * @return {string}
 */
proto.billing.PaymentRequest.prototype.getCcNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.PaymentRequest} returns this
 */
proto.billing.PaymentRequest.prototype.setCcNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string braintree_nonce = 3;
 * @return {string}
 */
proto.billing.PaymentRequest.prototype.getBraintreeNonce = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.PaymentRequest} returns this
 */
proto.billing.PaymentRequest.prototype.setBraintreeNonce = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string braintree_device_data = 4;
 * @return {string}
 */
proto.billing.PaymentRequest.prototype.getBraintreeDeviceData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.PaymentRequest} returns this
 */
proto.billing.PaymentRequest.prototype.setBraintreeDeviceData = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional InvoiceRecipient optional_recipient = 5;
 * @return {?proto.billing.InvoiceRecipient}
 */
proto.billing.PaymentRequest.prototype.getOptionalRecipient = function() {
  return /** @type{?proto.billing.InvoiceRecipient} */ (
    jspb.Message.getWrapperField(this, proto.billing.InvoiceRecipient, 5));
};


/**
 * @param {?proto.billing.InvoiceRecipient|undefined} value
 * @return {!proto.billing.PaymentRequest} returns this
*/
proto.billing.PaymentRequest.prototype.setOptionalRecipient = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.PaymentRequest} returns this
 */
proto.billing.PaymentRequest.prototype.clearOptionalRecipient = function() {
  return this.setOptionalRecipient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.PaymentRequest.prototype.hasOptionalRecipient = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.PaymentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.PaymentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.PaymentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.PaymentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    optionalTransactionId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    optionalTransactionCost: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    optionalSubscriptionPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.PaymentResponse}
 */
proto.billing.PaymentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.PaymentResponse;
  return proto.billing.PaymentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.PaymentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.PaymentResponse}
 */
proto.billing.PaymentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionalTransactionId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOptionalTransactionCost(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOptionalSubscriptionPrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.PaymentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.PaymentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.PaymentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.PaymentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOptionalTransactionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOptionalTransactionCost();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getOptionalSubscriptionPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.billing.PaymentResponse.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.PaymentResponse} returns this
 */
proto.billing.PaymentResponse.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.billing.PaymentResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.PaymentResponse} returns this
 */
proto.billing.PaymentResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string optional_transaction_id = 3;
 * @return {string}
 */
proto.billing.PaymentResponse.prototype.getOptionalTransactionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.PaymentResponse} returns this
 */
proto.billing.PaymentResponse.prototype.setOptionalTransactionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double optional_transaction_cost = 4;
 * @return {number}
 */
proto.billing.PaymentResponse.prototype.getOptionalTransactionCost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.billing.PaymentResponse} returns this
 */
proto.billing.PaymentResponse.prototype.setOptionalTransactionCost = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double optional_subscription_price = 5;
 * @return {number}
 */
proto.billing.PaymentResponse.prototype.getOptionalSubscriptionPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.billing.PaymentResponse} returns this
 */
proto.billing.PaymentResponse.prototype.setOptionalSubscriptionPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.BillingCancellationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.BillingCancellationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.BillingCancellationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.BillingCancellationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    remainingCreditsNumber: jspb.Message.getFieldWithDefault(msg, 1, 0),
    campaignsNumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    contactsNumber: jspb.Message.getFieldWithDefault(msg, 3, 0),
    membersNumber: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.BillingCancellationResponse}
 */
proto.billing.BillingCancellationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.BillingCancellationResponse;
  return proto.billing.BillingCancellationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.BillingCancellationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.BillingCancellationResponse}
 */
proto.billing.BillingCancellationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRemainingCreditsNumber(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCampaignsNumber(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setContactsNumber(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMembersNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.BillingCancellationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.BillingCancellationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.BillingCancellationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.BillingCancellationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRemainingCreditsNumber();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCampaignsNumber();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getContactsNumber();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getMembersNumber();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional int32 remaining_credits_number = 1;
 * @return {number}
 */
proto.billing.BillingCancellationResponse.prototype.getRemainingCreditsNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.BillingCancellationResponse} returns this
 */
proto.billing.BillingCancellationResponse.prototype.setRemainingCreditsNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 campaigns_number = 2;
 * @return {number}
 */
proto.billing.BillingCancellationResponse.prototype.getCampaignsNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.BillingCancellationResponse} returns this
 */
proto.billing.BillingCancellationResponse.prototype.setCampaignsNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 contacts_number = 3;
 * @return {number}
 */
proto.billing.BillingCancellationResponse.prototype.getContactsNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.BillingCancellationResponse} returns this
 */
proto.billing.BillingCancellationResponse.prototype.setContactsNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 members_number = 4;
 * @return {number}
 */
proto.billing.BillingCancellationResponse.prototype.getMembersNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.BillingCancellationResponse} returns this
 */
proto.billing.BillingCancellationResponse.prototype.setMembersNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.BillingCancellationFeedbackRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.BillingCancellationFeedbackRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.BillingCancellationFeedbackRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.BillingCancellationFeedbackRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    feedback: jspb.Message.getFieldWithDefault(msg, 1, 0),
    improve: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.BillingCancellationFeedbackRequest}
 */
proto.billing.BillingCancellationFeedbackRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.BillingCancellationFeedbackRequest;
  return proto.billing.BillingCancellationFeedbackRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.BillingCancellationFeedbackRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.BillingCancellationFeedbackRequest}
 */
proto.billing.BillingCancellationFeedbackRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.billing.BillingFeedback} */ (reader.readEnum());
      msg.setFeedback(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setImprove(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.BillingCancellationFeedbackRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.BillingCancellationFeedbackRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.BillingCancellationFeedbackRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.BillingCancellationFeedbackRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeedback();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getImprove();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional BillingFeedback feedback = 1;
 * @return {!proto.billing.BillingFeedback}
 */
proto.billing.BillingCancellationFeedbackRequest.prototype.getFeedback = function() {
  return /** @type {!proto.billing.BillingFeedback} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.billing.BillingFeedback} value
 * @return {!proto.billing.BillingCancellationFeedbackRequest} returns this
 */
proto.billing.BillingCancellationFeedbackRequest.prototype.setFeedback = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string improve = 2;
 * @return {string}
 */
proto.billing.BillingCancellationFeedbackRequest.prototype.getImprove = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.BillingCancellationFeedbackRequest} returns this
 */
proto.billing.BillingCancellationFeedbackRequest.prototype.setImprove = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.BillingCancellationActionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.BillingCancellationActionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.BillingCancellationActionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.BillingCancellationActionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    worksUntil: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.BillingCancellationActionResponse}
 */
proto.billing.BillingCancellationActionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.BillingCancellationActionResponse;
  return proto.billing.BillingCancellationActionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.BillingCancellationActionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.BillingCancellationActionResponse}
 */
proto.billing.BillingCancellationActionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorksUntil(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.BillingCancellationActionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.BillingCancellationActionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.BillingCancellationActionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.BillingCancellationActionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorksUntil();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 works_until = 1;
 * @return {number}
 */
proto.billing.BillingCancellationActionResponse.prototype.getWorksUntil = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.BillingCancellationActionResponse} returns this
 */
proto.billing.BillingCancellationActionResponse.prototype.setWorksUntil = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * @enum {number}
 */
proto.billing.BillingState = {
  BILLING_NORMAL: 0,
  BILLING_UN_PAID_NORMAL: 1,
  BILLING_CANCELING_IN_FUTURE: 2,
  BILLING_CANCELED: 3,
  BILLING_MAINTENANCE_IN_FUTURE: 4,
  BILLING_MAINTENANCE_IN_PROCESS: 5,
  BILLING_TRIAL: 6
};

/**
 * @enum {number}
 */
proto.billing.BillingSubscriptionType = {
  STARTER: 0,
  PRO: 1,
  TRIAL: 2,
  MAINTENANCE: 3,
  INTERNAL: 4,
  UNLIMITED: 5,
  ENTERPRISE: 6
};

/**
 * @enum {number}
 */
proto.billing.BillingSubscriptionCycle = {
  NONE: 0,
  MONTHLY: 1,
  YEARLY: 2
};

/**
 * @enum {number}
 */
proto.billing.InvoiceRowType = {
  OTHER: 0,
  MAILBOX_ACCOUNTS: 1,
  CREDITS: 2,
  CONTACTS: 3
};

/**
 * @enum {number}
 */
proto.billing.InvoiceStatus = {
  FUTURE: 0,
  IN_PROGRESS: 1,
  PAID: 2,
  REFUNDED: 3
};

/**
 * @enum {number}
 */
proto.billing.BillingFeedback = {
  NOT_ENOUGH_FEATURES: 0,
  TOO_EXPENSIVE: 1,
  DONT_NEED_ANYMORE: 2,
  NOT_SATISFIED: 3,
  TOO_BUGGY: 4,
  OTHER_REASON: 5
};

goog.object.extend(exports, proto.billing);
