import React from 'react';

const RemoveModalContent = ({ selectedIds, companies }) => {
  /*
    Depends on count of selected companies we should reduce members of this companies
    and show suitable a message
   */
  const ids = Object.keys(selectedIds);
  const reduceMembers = (items) =>
    items.reduce((acc, prevCompany) => acc + prevCompany.peopleNumber, 0);

  if (ids.length === 1) {
    const firstSelectedId = Number(ids[0]);
    const { peopleNumber } = companies.find((company) => company.id === firstSelectedId);

    return (
      <span className="relationships-remove-modal__text">
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        Are you sure you'd like to remove 1 company and {peopleNumber} associated person?
      </span>
    );
  }

  /*
    This logic is for removing all companies (copied from export logic)
    For now it is unneccecary, but maybe for future
   */
  if (ids.length === 0) {
    const totalCompanies = companies && companies[0] && companies[0].totalItems;
    const totalMembers = reduceMembers(companies);

    return (
      <span className="relationships-remove-modal__text">
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        Are you sure you'd like to remove {totalCompanies} companies and {totalMembers} associated
        people?
      </span>
    );
  }

  const selectedCompanies = companies.filter(({ id }) => ids.includes(String(id)));
  const selectedCompaniesMemebers = reduceMembers(selectedCompanies);

  return (
    <span className="relationships-remove-modal__text">
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      Are you sure you'd like to remove {selectedCompanies.length} companies and{' '}
      {selectedCompaniesMemebers} associated people?
    </span>
  );
};

export default RemoveModalContent;
