import React from 'react';

export default ({ color = '#8D8F92' }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.6 5.88889H15.4V3.55556H2.6V5.88889ZM10.6 14.4444V7.44444H7.4V14.4444H10.6ZM12.2 14.4444H15.4V7.44444H12.2V14.4444ZM5.8 14.4444V7.44444H2.6V14.4444H5.8ZM1.8 2H16.2C16.4122 2 16.6157 2.08194 16.7657 2.22781C16.9157 2.37367 17 2.5715 17 2.77778V15.2222C17 15.4285 16.9157 15.6263 16.7657 15.7722C16.6157 15.9181 16.4122 16 16.2 16H1.8C1.58783 16 1.38434 15.9181 1.23431 15.7722C1.08429 15.6263 1 15.4285 1 15.2222V2.77778C1 2.5715 1.08429 2.37367 1.23431 2.22781C1.38434 2.08194 1.58783 2 1.8 2Z"
      fill={color}
    />
  </svg>
);
