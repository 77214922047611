// @ts-nocheck
import CampaignsSidebarHeader from '@components/Campaigns/CampaignsSidebar/_components/CampaignsSidebarHeader/CampaignsSidebarHeader';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getCurrentWorkspace } from '@redux/selectors/workspaces.selectors';
import { campaignsFoldersSelector } from '@redux/selectors/campaigns.selectors';
import {
  createCampaignsFolder,
  fetchCampaignsFolders,
  removeCampaignsFolderRequest,
  updateCampaignsFolderRequest,
} from '@redux/thunks/campaign.thunks';
import { addNotification } from '@redux/actions/notifications.actions';

import ConfigurableCampaignSidebar from '@components/Campaigns/CampaignsSidebar/_components/CampaignsSidebarHeader/components/ConfigurableCampaignSidebar';
import sidebarConfig from './sidebarConfig';

import './CampaignsSidebar.scss';

type CampaignsSidebarPropsType = {
  pathname: string;
  search: string;
  isDeletedType: boolean;
};

function CampaignsSidebar({
  pathname,
  search,
  isDeletedType,
}: CampaignsSidebarPropsType): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const sideBarRef = useRef();
  const limit = useRef(30);
  const [isNewFolderCreation, changeIsNewFolderCreation] = useState(false);

  const [curFakePage, changeFakeCurPage] = useState(0);
  const [hasNextPage, changeHasNextPage] = useState<boolean>(true);

  const workspace = useSelector(getCurrentWorkspace);
  const { items: campaignFolders, loadingStatus: campaignFoldersLs } =
    useSelector(campaignsFoldersSelector);

  // Count folders request limit by sidebar height
  useLayoutEffect(() => {
    limit.current = Math.floor(sideBarRef.current.clientHeight / 48);
  }, [sideBarRef.current]);

  useEffect(() => {
    changeFakeCurPage(0);
  }, [workspace]);

  useEffect(() => {
    if (workspace?.id) {
      dispatch(fetchCampaignsFolders(workspace.id, 0, 20));
    }
  }, [workspace?.id]);

  const loadMore = () => {
    changeFakeCurPage((prevPage) => prevPage + 1);
  };

  const handleCreateCampaignFolder = (title: string) => {
    dispatch(createCampaignsFolder(workspace.id, title)).then(() => {
      handleCancelFolderCreation();
      dispatch(addNotification({ title: 'Folder created', type: 'success' }));
      // We need to increase limit to get new page of folders without one item from current page
      limit.current++;
    });
  };

  const handleRemoveCampaignFolder = (folderId) => {
    dispatch(removeCampaignsFolderRequest(folderId, workspace.id));
  };

  const handleUpdateCampaignFolder = (folderId, title) => {
    dispatch(updateCampaignsFolderRequest(folderId, title));
  };

  const handleOpenFolderCreation = () => changeIsNewFolderCreation(true);
  const handleCancelFolderCreation = () => changeIsNewFolderCreation(false);

  const renderProps = {
    onCreateCampaignFolder: handleCreateCampaignFolder,
    onRemoveCampaignFolder: handleRemoveCampaignFolder,
    onUpdateCampaignFolder: handleUpdateCampaignFolder,
    onOpenFolderCreation: handleOpenFolderCreation,
    onCancelFolderCreation: handleCancelFolderCreation,
    onLoadMoreCampaignFolders: loadMore,
    hasNextPage,
    workspaceTitle: workspace.title,
    campaignFolders,
    campaignFoldersLs,
    pathname,
    search,
    isNewFolderCreation,
    // footerRender: () => <CampaignSidebarFooter {...campaignSidebarFooterProps} />,
  };

  return (
    <div className="campaigns-sidebar" ref={sideBarRef}>
      <ConfigurableCampaignSidebar
        SidebarHeader={() => <CampaignsSidebarHeader isDeletedType={isDeletedType} />}
        config={sidebarConfig}
        renderProps={renderProps}
      />
    </div>
  );
}

export default CampaignsSidebar;
