import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery, useQueryClient } from 'react-query';

import BaseModal from '@uikit/BaseModal/BaseModal';
import Select from '@uikit/Select/Select';
import Button from '@uikit/Button/Button';
import Display from '@components/Display/Display';

import { batchCampaignOperationsApi, getCampaignsFoldersApi } from '@api/campaign.api';
import { updateCampaignFolderHelper } from '@helpers/queryClientData.helpers';

import {
  folderToOptionsMap,
  transformFoldersToOptions,
  transformWorkspacesToOptions,
} from '@mappers/optionTransformers';
import { CampaignType } from '@ts/campaigns.types';

import './MoveToFolderModal.scss';
import { CampaignBatchOperations } from 'respona_api/generated/campaign_pb';
import CampaignBatchAction = CampaignBatchOperations.CampaignBatchAction;
import { getCurrentWorkspaceId, workspacesSelector } from '@redux/selectors/workspaces.selectors';
import Loader from '@uikit/Loader/Loader';
import loadingStatuses from '@constants/loadingStatuses';
import { checkIsAnyNotLoaded } from '@utils/loadingChecks';
import { addNotification } from '@redux/actions/notifications.actions';

function MoveToFolderModal({
  isOpen,
  onClose,
  campaigns,
  folderId,
  queryKey,
}: {
  isOpen: boolean;
  onClose: () => void;
  campaigns: CampaignType[];
  folderId?: number;
  queryKey?: any[];
}): JSX.Element {
  if (!isOpen || !campaigns || campaigns.length === 0) {
    return null;
  }
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { workspaces, loadingStatus: workspacesLs } = useSelector(workspacesSelector);
  const currentWorkspaceId = useSelector(getCurrentWorkspaceId);
  const [selectedFolder, changeSelectedFolder] = useState(null);
  const [selectedWorkspace, changeSelectedWorkspace] = useState(null);
  const [moveButtonIsLoading, changeMoveButtonIsLoading] = useState<boolean>(false);
  const [removeButtonIsLoading, changeRemoveButtonIsLoading] = useState<boolean>(false);

  const { data: foldersByWorkspace, isLoading: isLoadingFoldersByWorkspace } = useQuery(
    ['workspace-folders', selectedWorkspace],
    () => getCampaignsFoldersApi(selectedWorkspace.value, 0, 20),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!selectedWorkspace && !!selectedWorkspace.value && selectedWorkspace.value > 0,
      staleTime: 1000 * 60 * 60,
    }
  );

  useEffect(() => {
    if (workspacesLs === loadingStatuses.LOADED && selectedWorkspace == null) {
      const currentWorkspace = workspaces.find((it) => it.id === currentWorkspaceId);
      if (currentWorkspace != null) {
        changeSelectedWorkspace({ label: currentWorkspace.title, value: currentWorkspace.id });
      }
    }
  }, [workspacesLs]);

  const onChangeSelectedWorkspace = (payload) => {
    changeSelectedFolder(null);
    changeSelectedWorkspace(payload);
  };

  const handleCampaignRemoveFolder = () => {
    changeRemoveButtonIsLoading(true);
    const campaignIds = campaigns.map(({ id }) => id);
    batchCampaignOperationsApi(
      currentWorkspaceId,
      CampaignBatchAction.MOVE_TO_WORKSPACE,
      campaignIds,
      0
    )
      .then(() => {
        campaignIds.forEach((campaignId) => {
          queryClient.setQueryData(queryKey, updateCampaignFolderHelper(campaignId, 0, folderId));
        });

        onClose();
      })
      .finally(() => changeRemoveButtonIsLoading(false));
  };

  const handleCampaignMoving = () => {
    if (
      selectedWorkspace &&
      selectedWorkspace.value != null &&
      ((selectedFolder && !campaigns.find(({ folderId }) => folderId === selectedFolder.id)) ||
        selectedWorkspace.value !== currentWorkspaceId)
    ) {
      const destinationFolderId = selectedFolder?.id || -1;
      changeMoveButtonIsLoading(true);
      batchCampaignOperationsApi(
        selectedWorkspace.value,
        CampaignBatchAction.MOVE_TO_WORKSPACE,
        campaigns.map((it) => it.id),
        destinationFolderId
      ).then(() => {
        if (queryKey) {
          if (selectedWorkspace.value !== currentWorkspaceId) {
            const campaignIds = campaigns.map((it) => it.id);
            queryClient.setQueryData(queryKey, (cache: CampaignType[]) => {
              return cache.filter(({ id }) => !campaignIds.includes(id));
            });
          } else {
            campaigns.forEach((campaign) => {
              queryClient.setQueryData(
                queryKey,
                updateCampaignFolderHelper(campaign.id, destinationFolderId, folderId)
              );
            });
          }
        }

        onClose();
      });
    }
  };

  useEffect(() => {
    const campaignFolderIds = campaigns.map(({ folderId }) => folderId);
    if (
      foldersByWorkspace != null &&
      foldersByWorkspace.length > 0 &&
      !campaignFolderIds.includes(0)
    ) {
      const selectedFolderInfo = foldersByWorkspace.find(({ id }) =>
        campaignFolderIds.includes(id)
      );

      changeSelectedFolder(folderToOptionsMap(selectedFolderInfo));
    }
  }, [foldersByWorkspace]);

  const hasFolder = campaigns.some(({ folderId }) => folderId > 0);

  const checkIfChangingWorkspaceDisabled = (): boolean =>
    !!campaigns.filter((it) => it.status > 0).length;

  const showWarningIsNeeded = (show: boolean) => {
    if (show) {
      dispatch(
        addNotification({
          title: `Only draft Campaigns could be moved to other workspaces`,
          type: 'warning',
        })
      );
    }
  };

  return (
    <BaseModal onClose={onClose} isOpen={isOpen} classNames={{ modal: 'campaign-move-modal' }}>
      <div className="campaign-move-modal__header">
        Move
        <div className="campaign-move-modal__campaign-title">{campaigns[0].title}</div>
        {campaigns.length > 1 ? (
          <div className="campaign-move-modal__campaign-counter">+{campaigns.length - 1}</div>
        ) : null}
      </div>
      <div className="campaign-move-modal__selector">
        <Loader isLoading={checkIsAnyNotLoaded(workspacesLs)}>
          <div
            className="campaign-move-modal__selector--block"
            onClick={() => showWarningIsNeeded(checkIfChangingWorkspaceDisabled())}
          >
            <p>Workspace</p>
            <Select
              isDisabled={checkIfChangingWorkspaceDisabled()}
              value={selectedWorkspace}
              onChange={(it) => onChangeSelectedWorkspace(it)}
              options={transformWorkspacesToOptions(workspaces)}
              placeholder="Select workspace"
            />
          </div>
          <div className="campaign-move-modal__selector--block">
            <Loader isLoading={isLoadingFoldersByWorkspace}>
              <p>Folder</p>
              <Select
                value={selectedFolder}
                onChange={changeSelectedFolder}
                options={transformFoldersToOptions(foldersByWorkspace)}
                placeholder="Select folder"
              />
            </Loader>
          </div>
        </Loader>
      </div>
      <div className="campaign-move-modal__footer">
        <Display isVisible={!!campaigns.find(({ folderId }) => folderId > -1)}>
          <Button
            onClick={handleCampaignMoving}
            isLoading={moveButtonIsLoading}
            disabled={moveButtonIsLoading}
          >
            Save
          </Button>
          {/* <Button */}
          {/*  type="secondary" */}
          {/*  onClick={handleCampaignRemoveFolder} */}
          {/*  isLoading={removeButtonIsLoading} */}
          {/*  disabled={removeButtonIsLoading || !hasFolder} */}
          {/* > */}
          {/*  Remove from folder */}
          {/* </Button> */}
        </Display>
      </div>
    </BaseModal>
  );
}

export default MoveToFolderModal;
