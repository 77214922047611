import { BoundaryType, ParsedValueType, ParseInputsType } from '@ts/moderateChance.types';

import spamWordsList from '@constants/spamWordsList';

const boundaries: BoundaryType[] = [
  {
    min: 0,
    max: 0,
    color: 'green',
  },
  {
    min: 1,
    max: 1,
    color: 'yellow',
  },
  {
    min: 2,
    color: 'red',
  },
];

export default {
  key: 'spamWordsCount',
  label: 'Spam words',
  min: boundaries[0].min,
  max: boundaries[boundaries.length - 1].min,
  greenZone: {
    min: 0,
    max: 0,
  },
  parse: ({ text }: ParseInputsType): ParsedValueType => {
    const spamWordsCount = text.split(' ').filter((word) => spamWordsList[word]).length;

    return {
      value: spamWordsCount,
      boundary: boundaries.find(({ min, max }) => {
        if (!max && max !== 0 && spamWordsCount >= max) {
          return true;
        }

        if (min <= spamWordsCount && spamWordsCount <= max) {
          return true;
        }
      }),
    };
  },
};
