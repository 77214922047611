import { addNotification } from '@redux/actions/notifications.actions';
import { fetchOpenCampaign } from '@redux/thunks/campaignSettings.thunks';
import { CampaignCrudRequest } from 'respona_api/generated/campaign_pb';
import { DispatchType } from 'src/store';

import { saveCampaign, updateCampaign } from '@redux/thunks/campaign.thunks';

class CampaignService {
  async create(dispatch: DispatchType, workspaceId: number, folderId: number = null) {
    return saveCampaign(dispatch, workspaceId, folderId);
  }

  async update(campaignInfo: CampaignCrudRequest.AsObject, dispatch: DispatchType) {
    return updateCampaign(dispatch, campaignInfo).then(async () => {
      await dispatch(fetchOpenCampaign(campaignInfo.id, campaignInfo.workspaceId, true));
      await dispatch(addNotification({ title: 'Campaign has been updated', type: 'success' }));
    });
  }
}

export const campaignService = new CampaignService();
