import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';

import { EntityRelationRequest } from 'respona_api/generated/common_pb';

import BaseModal from '@uikit/BaseModal/BaseModal';
import Select from '@uikit/Select/Select';
import Button from '@uikit/Button/Button';
import Display from '@components/Display/Display';

import { folderToOptionsMap, transformFoldersToOptions } from '@mappers/optionTransformers';

import { peopleFoldersSelector } from '@redux/selectors/people.selectors';
import { movePersonsToFolder } from '@redux/thunks/people.thunks';
import { addNotification } from '@redux/actions/notifications.actions';
import { removePeopleFromFolderApi } from '@api/people.api';

import './RelationshipsMoveToFolderModal.scss';

// TODO: We can connect this modal with OrgnizationsMoveToFolderModal in one component

const PeopleMoveToFolderModal = ({ itemToMove, onClose, onRemovePersonFromList }): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { folderId } = qs.parse(history.location.search);
  const { items: folders } = useSelector(peopleFoldersSelector);
  const [selectedFolder, changeSelectedFolder] = useState(null);

  useEffect(() => {
    if (folders.length > 0 && folderId) {
      const selectedFolderInfo = folders.find(({ id }) => id === Number(folderId));

      changeSelectedFolder(folderToOptionsMap(selectedFolderInfo));
    }
  }, [folders]);

  const closeModal = () => {
    onClose();
    changeSelectedFolder(null);
  };

  const handlePersonMoving = () => {
    if (!selectedFolder) return;
    /*
      If we are in all people -> just show notification, we cant filter list
     */
    if (!folderId) {
      movePersonsToFolder(
        selectedFolder.id,
        [itemToMove.id],
        EntityRelationRequest.SelectionType.SELECTED
      ).then(() => {
        dispatch(
          addNotification({
            title: `Person was moved to the "${selectedFolder.title}" folder`,
            type: 'success',
          })
        );
        closeModal();
      });
    }
    /*
      If we are in folder -> just filter list in UI to see result
     */
    if (folderId && selectedFolder && selectedFolder.id !== Number(folderId)) {
      movePersonsToFolder(
        selectedFolder.id,
        [itemToMove.id],
        EntityRelationRequest.SelectionType.SELECTED
      ).then(() => {
        onRemovePersonFromList(itemToMove.id);
        closeModal();
      });
    }
  };

  const handlePersonRemoveFromFolder = () =>
    removePeopleFromFolderApi(
      selectedFolder.id,
      [itemToMove.id],
      EntityRelationRequest.SelectionType.SELECTED
    ).then(() => {
      dispatch(
        addNotification({
          title: `Person was moved out from the "${selectedFolder.title}" folder`,
          type: 'success',
        })
      );
      onRemovePersonFromList(itemToMove.id);
      closeModal();
    });

  return (
    <BaseModal
      classNames={{ modal: 'relationships-move-modal-wrapper' }}
      onClose={onClose}
      isOpen={itemToMove !== null}
    >
      <div className="relationships-move-modal">
        <div className="relationships-move-modal__header">
          Move
          <div className="relationships-move-modal__campaign-title">{itemToMove.name}</div>
          to folder
        </div>
        <div className="relationships-move-modal__selector">
          <Select
            value={selectedFolder}
            onChange={changeSelectedFolder}
            options={transformFoldersToOptions(folders)}
            placeholder="Select folder"
          />
        </div>

        <div className="relationships-move-modal__footer">
          <Display isVisible={!!folderId}>
            <Button type="secondary" onClick={handlePersonRemoveFromFolder}>
              Remove from folder
            </Button>
          </Display>
          <Button onClick={handlePersonMoving}>Move</Button>
        </div>
      </div>
    </BaseModal>
  );
};

export default PeopleMoveToFolderModal;
