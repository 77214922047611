import { getQueryLinkForSource } from '@helpers/contentSearchHelpers';
import React from 'react';

import { SVGIcon } from '@uikit/Icon/Icon';

import { CASearchConfigType } from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/CASearchTypeWrapper/CASearchTypeWrapper';
import { KeywordSearchSource } from 'respona_api/generated/automation-content_pb';

export const getContentSearchTitle = (
  key: keyof CASearchConfigType,
  searchSource: number,
  toggleAdvancedSearch?: () => void
): string | JSX.Element => {
  switch (key) {
    case 'backlinksConfig':
      return (
        <span>
          Enter URLs (e.g.{' '}
          <a href="https://respona.com" target="_blank">
            respona.com
          </a>
          )
        </span>
      );
    case 'keywordsConfig':
      return (
        <>
          <span>
            Enter search queries (e.g.{' '}
            <a
              href={getQueryLinkForSource(
                searchSource === KeywordSearchSource.GOOGLE_NEWS_KEYWORD_SEARCH
                  ? 'Cryptocurrency'
                  : 'best SEO tools',
                searchSource
              )}
              target="_blank"
            >
              {searchSource === KeywordSearchSource.GOOGLE_NEWS_KEYWORD_SEARCH
                ? 'Cryptocurrency'
                : 'Best SEO tools'}
            </a>
            )
          </span>
          {![
            KeywordSearchSource.GOOGLE_NEWS_KEYWORD_SEARCH,
            KeywordSearchSource.BING_NEWS_KEYWORD_SEARCH,
          ].includes(searchSource as 3 | 2) && (
            <div className="">
              <span
                onClick={toggleAdvancedSearch}
                className="options-row__item"
                tabIndex={0}
                role="button"
              >
                <span className="options-row__icon">
                  <SVGIcon icon="slidersSettings" />
                </span>
                Advanced Search
              </span>
            </div>
          )}
        </>
      );

    case 'podcastsConfig':
      return (
        <span>
          Enter keywords (e.g. <a href="#">Startups</a>)
        </span>
      );
    default:
      return 'Search queries';
  }
};
