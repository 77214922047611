import React from 'react';
import cn from 'classnames';

import './HealthScoreTile.scss';

const HealthScoreTile = ({ score, text }: { score: number, text?: string | number }) => {

  const className = cn('health-score-tile', {
    'health-score-tile--warning': score < 80,
    'health-score-tile--danger': score < 50,
  });

  return (
    <div className={className}>
      Score
      <div className="health-score-tile__number">{text || score}</div>
    </div>
  );
};

export default HealthScoreTile;
