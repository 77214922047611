import BillingCard from '@components/Settings/Billing/_components/BillingCard/BillingCard';
import React from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

import {
  BillingCycleTypeValue,
  BillingStateType as BillingStateTypeName,
  BillingSubscriptionTypeMapValue as BillingSubscriptionTypeName,
} from '@ts/billing.types';

import {
  BillingSubscriptionCycle,
  BillingSubscriptionType,
} from 'respona_api/generated/billing_pb';

import { getTimeDifference } from '@utils/getFormattedPastTime';

import { SVGIcon } from '@uikit/Icon/Icon';
import TextWithIcon from '@uikit/TextWithIcon/TextWithIcon';
import PieChart from '@uikit/PieChart/PieChart';

import SubscriptionTypeBadge from '@components/Settings/Billing/_components/SubscriptionCard/_components/SubscriptionTypeBadge/SubscriptionTypeBadge';

import './SubscriptionCard.scss';

const SubscriptionCard = ({
  type,
  state,
  amount,
  nextChargeTime,
  cycle,
}: {
  type: BillingSubscriptionTypeName;
  state: BillingStateTypeName;
  amount: number;
  nextChargeTime: number;
  cycle: BillingCycleTypeValue;
}): JSX.Element => {
  const subscriptionDaysLeft = nextChargeTime > 0 ? getTimeDifference(nextChargeTime) : 0;

  const getBillingPeriodDaysNumber = (): number => {
    const now = new Date();
    const year = now.getFullYear();

    if (cycle === BillingSubscriptionCycle.YEARLY) {
      return (year % 4 === 0 && year % 100 > 0) || year % 400 === 0 ? 366 : 365;
    }
    if (type === BillingSubscriptionType.TRIAL) {
      return 7;
    }
    return new Date(year, now.getMonth() + 1, 0).getDate();
  };

  return (
    <BillingCard
      title="Subscription"
      warningReversed
      subtitle={
        nextChargeTime > 0 ? (
          <div className="subscription-card__footer">
            Next charge: {format(new Date(nextChargeTime), 'MMM dd, yyyy')}
          </div>
        ) : null
      }
      headerRight={
        type !== BillingSubscriptionType.ENTERPRISE ? (
          <Link
            to="/settings/billing/change-subscription"
            className="subscription-card__change-link"
          >
            <TextWithIcon icon="billing" iconColor="#3424EB">
              Change
            </TextWithIcon>
          </Link>
        ) : null
      }
      chartInfo={{
        value: Math.min(
          getBillingPeriodDaysNumber() - subscriptionDaysLeft,
          getBillingPeriodDaysNumber()
        ),
        max: getBillingPeriodDaysNumber(),
      }}
      chartLegend={<div className="subscription-card__info-amount">${amount}</div>}
      chartTitle={
        <div className="subscription-card__info">
          <div>
            <div className="subscription-card__info-title">Cost</div>

            {BillingSubscriptionCycle.NONE !== cycle ? (
              <div className="subscription-card__info-type">
                {cycle === BillingSubscriptionCycle.YEARLY ? 'Yearly' : null}
                {cycle === BillingSubscriptionCycle.MONTHLY ? 'Monthly' : null}
              </div>
            ) : null}
          </div>
          <SubscriptionTypeBadge type={type} state={state} />
        </div>
      }
    />
  );
};

export default SubscriptionCard;
