import React from 'react';

export default ({ color = '#8F9199' }) => (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.64711 5.98109L3.0928 10.5523C2.69718 11.0611 2.49247 11.6925 2.51427 12.3366L2.61311 15.2563C2.62233 15.5287 2.75207 15.7829 2.96723 15.9502C3.18239 16.1175 3.46075 16.1806 3.72702 16.1224L6.58098 15.4987C7.21063 15.3611 7.77211 15.0071 8.16773 14.4983L11.2282 10.5622L9.91932 9.25331L6.71775 13.3708C6.58588 13.5404 6.39872 13.6584 6.18884 13.7043L4.41149 14.0927L4.34994 12.2745C4.34267 12.0598 4.41091 11.8493 4.54278 11.6797L7.95599 7.28998L6.64711 5.98109ZM14.9388 14.2728L15.416 14.75C15.4619 14.858 15.4873 14.9768 15.4873 15.1015C15.4873 15.5986 15.0843 16.0015 14.5873 16.0015H9.80451C9.30745 16.0015 8.90451 15.5986 8.90451 15.1015C8.90451 14.6045 9.30745 14.2015 9.80451 14.2015H14.5873C14.712 14.2015 14.8308 14.2269 14.9388 14.2728ZM12.4654 8.97099L7.88434 4.38989L8.6036 3.46485C9.38193 2.46384 10.8244 2.28333 11.8254 3.06165L13.2753 4.18908C14.2763 4.9674 14.4569 6.40983 13.6785 7.41084L12.4654 8.97099ZM10.6979 4.51163C10.4977 4.35597 10.2092 4.39207 10.0536 4.59227L9.73175 5.00618L11.9086 6.69489L12.2286 6.28341C12.3842 6.08321 12.3481 5.79472 12.1479 5.63906L10.6979 4.51163Z"
      fill={color}
    />
    <rect
      x="2.42969"
      y="4.58984"
      width="2"
      height="18.065"
      rx="1"
      transform="rotate(-45 2.42969 4.58984)"
      fill={color}
    />
  </svg>
);
