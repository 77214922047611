// package: auth
// file: auth.proto

var auth_pb = require("./auth_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var auth_shared_pb = require("./auth-shared_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var AuthApiService = (function () {
  function AuthApiService() {}
  AuthApiService.serviceName = "auth.AuthApiService";
  return AuthApiService;
}());

AuthApiService.changePassword = {
  methodName: "changePassword",
  service: AuthApiService,
  requestStream: false,
  responseStream: false,
  requestType: auth_pb.ChangePasswordRequest,
  responseType: auth_shared_pb.LoginResponse
};

AuthApiService.generateTwoFactorAuthenticationCode = {
  methodName: "generateTwoFactorAuthenticationCode",
  service: AuthApiService,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: auth_pb.TwoFactorAuthenticationPreviewResponse
};

AuthApiService.enableTwoFactorAuthentication = {
  methodName: "enableTwoFactorAuthentication",
  service: AuthApiService,
  requestStream: false,
  responseStream: false,
  requestType: auth_pb.EnableTwoFactorAuthenticationRequest,
  responseType: google_protobuf_empty_pb.Empty
};

exports.AuthApiService = AuthApiService;

function AuthApiServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

AuthApiServiceClient.prototype.changePassword = function changePassword(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AuthApiService.changePassword, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AuthApiServiceClient.prototype.generateTwoFactorAuthenticationCode = function generateTwoFactorAuthenticationCode(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AuthApiService.generateTwoFactorAuthenticationCode, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AuthApiServiceClient.prototype.enableTwoFactorAuthentication = function enableTwoFactorAuthentication(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AuthApiService.enableTwoFactorAuthentication, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.AuthApiServiceClient = AuthApiServiceClient;

