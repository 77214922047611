import React from 'react';

const QueryLinkToInternet = (): JSX.Element => {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.9502 7.05L12.0002 1"
        stroke="#3424EB"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.6001 1H12.0001V5.4"
        stroke="#3424EB"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.85 2.09961H2C1.44772 2.09961 1 2.54732 1 3.09961V10.9996C1 11.5519 1.44772 11.9996 2 11.9996H9.9C10.4523 11.9996 10.9 11.5519 10.9 10.9996V8.14961"
        stroke="#3424EB"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default QueryLinkToInternet;
