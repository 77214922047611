import React from 'react';

export default ({ color = '#C4C6CD' }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.0907 5.3102L14.1617 5.21475C11.3819 4.92842 8.57997 4.92842 5.80015 5.21475L4.90927 5.3102C3.80937 5.43452 2.98342 6.37335 3.00025 7.48013V12.6981C2.98342 13.8049 3.80937 14.7437 4.90927 14.868L5.83833 14.9635C8.61815 15.2498 11.42 15.2498 14.1998 14.9635L15.0907 14.868C16.1906 14.7437 17.0166 13.8049 16.9997 12.6981V7.48013C17.0166 6.37335 16.1906 5.43452 15.0907 5.3102ZM11.909 10.4837L9.43366 12.1381C9.28952 12.2173 9.1148 12.2166 8.97125 12.1364C8.82769 12.0563 8.73553 11.9078 8.72732 11.7436V8.43464C8.72764 8.25824 8.82524 8.0964 8.98111 8.0138C9.13697 7.93121 9.32569 7.94132 9.47184 8.04011L11.9472 9.69459C12.0806 9.7815 12.161 9.92992 12.161 10.0891C12.161 10.2483 12.0806 10.3967 11.9472 10.4837H11.909Z"
      fill={color}
    />
  </svg>
);
