import React from 'react';

export default ({ color = '#C4C6CD', size = 17 }: { color?: string; size?: number }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="6.68509"
      cy="4.58352"
      rx="3.58352"
      ry="3.58352"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.00001 13.1507C0.999049 12.8988 1.05539 12.6499 1.16477 12.423C1.50802 11.7365 2.47598 11.3726 3.27919 11.2079C3.85846 11.0843 4.44573 11.0017 5.03663 10.9607C6.13063 10.8646 7.23095 10.8646 8.32495 10.9607C8.9158 11.0022 9.50304 11.0847 10.0824 11.2079C10.8856 11.3726 11.8536 11.7022 12.1968 12.423C12.4168 12.8856 12.4168 13.4226 12.1968 13.8852C11.8536 14.6061 10.8856 14.9356 10.0824 15.0935C9.5038 15.2222 8.91632 15.3071 8.32495 15.3475C7.43453 15.423 6.53994 15.4367 5.64761 15.3887C5.44166 15.3887 5.24258 15.3887 5.03663 15.3475C4.44747 15.3076 3.86224 15.2227 3.28605 15.0935C2.47598 14.9356 1.51489 14.6061 1.16477 13.8852C1.05595 13.6557 0.999664 13.4047 1.00001 13.1507Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
