import urls from '@constants/urls';

export type PageLinkType = {
  path: string;
  icon: string;
  name: string;
  activePrefix?: string;
  isShown?: () => boolean;
  beta?: boolean;
};

export const pagesLinks: PageLinkType[] = [
  {
    path: urls.DASHBOARD,
    name: 'Dashboard',
    icon: 'dashboard',
    activePrefix: '/dashboard',
  },
  {
    path: urls.ALL_CAMPAIGNS,
    name: 'Campaigns',
    icon: 'campaigns',
    activePrefix: '/campaigns',
  },
  {
    path: '/relationships/websites',
    name: 'Relationships',
    icon: 'relationships',
    activePrefix: '/relationships',
  },
  {
    path: '/insights/campaign-reports',
    name: 'Insights',
    icon: 'insights',
    activePrefix: '/insights',
  },
  {
    path: '/inbox/inbox',
    name: 'Inbox',
    icon: 'inbox',
    activePrefix: '/inbox',
  },
  ...(localStorage.getItem('ENABLE_PRESS_INQUIRIES') === 'true'
    ? [
        {
          path: '/inquiries/all-inquiries',
          name: 'Press Inquiries',
          icon: 'inquiries',
          activePrefix: '/inquiries',
        },
      ]
    : []),
  {
    path: '/toolbox/email-finder',
    name: 'Toolbox',
    icon: 'toolbox',
    activePrefix: '/toolbox',
  },
];
