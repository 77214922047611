import React, { ReactNode } from 'react';
import cn from 'classnames';

import ToolboxVerificationStatusCatch from '@components/Toolbox/ToolboxEmailVerifier/_components/ToolboxEmailVerifierStatus/_assets/ToolboxVerificationStatusCatch';
import ToolboxVerificationStatusInvalid from '@components/Toolbox/ToolboxEmailVerifier/_components/ToolboxEmailVerifierStatus/_assets/ToolboxVerificationStatusInvalid';
import ToolboxVerificationStatusValid from '@components/Toolbox/ToolboxEmailVerifier/_components/ToolboxEmailVerifierStatus/_assets/ToolboxVerificationStatusValid';

import './ToolboxEmailVerifierStatus.scss';

export enum ToolboxEmailVerifierStatusName {
  VALID = 'Valid',
  INVALID = 'Invalid',
  CATCH_ALL = 'Catch-all',
}

export const ToolboxEmailVerifierStatusText = ({
  children,
  status,
}: {
  children: ReactNode;
  status?: ToolboxEmailVerifierStatusName;
}) => {
  const className = cn('toolbox-email-verification-status-text', {
    'toolbox-email-verification-status-text--valid':
      status === ToolboxEmailVerifierStatusName.VALID,
    'toolbox-email-verification-status-text--catch-all':
      status === ToolboxEmailVerifierStatusName.CATCH_ALL,
    'toolbox-email-verification-status-text--invalid':
      status === ToolboxEmailVerifierStatusName.INVALID,
  });

  return <div className={className}>{children}</div>;
};

const ToolboxEmailVerifierStatus = ({ status }: { status: ToolboxEmailVerifierStatusName }) => {
  return (
    <div className="toolbox-email-verification-status">
      {status === ToolboxEmailVerifierStatusName.VALID ? <ToolboxVerificationStatusValid /> : null}
      {status === ToolboxEmailVerifierStatusName.INVALID ? (
        <ToolboxVerificationStatusInvalid />
      ) : null}
      {status === ToolboxEmailVerifierStatusName.CATCH_ALL ? (
        <ToolboxVerificationStatusCatch />
      ) : null}

      <div className="toolbox-email-verification-status__text">
        <div className="toolbox-email-verification-status__title">{status}</div>
        <div className="toolbox-email-verification-status__subtitle">
          {status === ToolboxEmailVerifierStatusName.VALID
            ? 'You can use this email address safely.'
            : null}
          {status === ToolboxEmailVerifierStatusName.INVALID
            ? 'We do not recommend using this email address.'
            : null}
          {status === ToolboxEmailVerifierStatusName.CATCH_ALL
            ? 'This domain accepts all emails. Email verification is not possible.'
            : null}
        </div>
      </div>
    </div>
  );
};

export default ToolboxEmailVerifierStatus;
