import React from 'react';

import hexToRgba from '@utils/hexToRgba';

import './IconNamePlaceholder.scss';

export default ({
  size = 16,
  color,
  borderRadius = '100%',
  name = '',
}: {
  size?: number;
  color: string;
  borderRadius?: string;
  name: string;
}): JSX.Element => {
  const bgColor = hexToRgba(color, '0.1');

  const mainStyles = {
    width: `${size}px`,
    height: `${size}px`,
    backgroundColor: bgColor,
    borderRadius,
  };

  const textStyles = {
    fontSize: `${size / 2}px`,
    color,
  };

  return (
    <div className="icon-name-placeholder" style={mainStyles}>
      <span style={textStyles}>{name[0]}</span>
    </div>
  );
};
