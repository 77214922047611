import { useState, useEffect } from 'react';

export default (audioRef) => {
  const [duration, setDuration] = useState();
  const [curTime, setCurTime] = useState();
  const [playing, setPlaying] = useState(false);
  const [volume, setVolume] = useState(1);
  const [lastVolume, setLastVolume] = useState(1);
  const [clickedTime, setClickedTime] = useState();

  useEffect(() => {
    const audio = audioRef.current;

    // state setters wrappers
    const setAudioData = () => {
      setDuration(audio.duration);
      setCurTime(audio.currentTime);
    };

    const setAudioTime = () => setCurTime(audio.currentTime);

    if (audio) {
      // DOM listeners: update React state on DOM events
      audio.addEventListener('loadeddata', setAudioData);

      audio.addEventListener('timeupdate', setAudioTime);

      // React state listeners: update DOM on React state changes
      if (playing) {
        audio.play();
      } else {
        audio.pause();
      }

      if (clickedTime && clickedTime !== curTime) {
        audio.currentTime = clickedTime;
        setCurTime(clickedTime);
        setClickedTime(null);
      }
    }

    // effect cleanup
    return () => {
      audio.removeEventListener('loadeddata', setAudioData);
      audio.removeEventListener('timeupdate', setAudioTime);
    };
  }, [audioRef, playing, clickedTime]);

  useEffect(() => {
    const audio = audioRef.current;

    if (audio) {
      audio.volume = volume;
    }
  }, [volume]);

  const handleMute = () => {
    setLastVolume(volume);
    setVolume(0);
  };

  const handleUnmute = () => {
    setVolume(lastVolume);
  };

  return {
    curTime,
    duration,
    playing,
    setPlaying,
    setClickedTime,
    volume,
    setVolume,
    onMute: handleMute,
    onUnmute: handleUnmute,
  };
};
