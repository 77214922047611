import React from 'react';
import { useParams } from 'react-router-dom';
import cn from 'class-names';

import PageHeader from '@components/PageHeader/PageHeader';
import Tabs, { TabConfigType } from '@uikit/Tabs/Tabs';

import './PipelineDeal.scss';
import { SVGIcon } from '@uikit/Icon/Icon';

const PipeLineDealHistory = (): JSX.Element => <div>PipeLineDealHistory</div>;

const PipeLineDealNotes = (): JSX.Element => <div>PipeLineDealNotes</div>;

const PipelineDeal = (): JSX.Element => {
  const { dealId } = useParams();

  const getDealTabHeaderCn = (isActive?: boolean): string =>
    cn('pipeline-deal__tab-header', {
      'pipeline-deal__tab-header--active': isActive,
    });

  const tabsConfig: TabConfigType[] = [
    {
      key: 'Conversation history',
      renderContent: PipeLineDealHistory,
      renderTitle: (isActive) => (
        <div className={getDealTabHeaderCn(isActive)}>
          <SVGIcon icon="chatUnfilled" />
          Conversation history
        </div>
      ),
    },
    {
      key: 'Notes',
      renderContent: PipeLineDealNotes,
      renderTitle: (isActive) => (
        <div className={getDealTabHeaderCn(isActive)}>
          <SVGIcon icon="fileBlank" />
          Notes
        </div>
      ),
    },
  ];

  return (
    <>
      <PageHeader
        renderHeaderTitle={() => (
          <div className="pipeline-deal-header">
            <h3 className="pipeline-deal-header__name">Robert Fox</h3>
            <div className="pipeline-deal-header__website">website.com</div>
          </div>
        )}
      />
      <div className="pipeline-deal">
        <div className="pipeline-deal__content">
          <Tabs tabs={tabsConfig} />
        </div>
      </div>
    </>
  );
};

export default PipelineDeal;
