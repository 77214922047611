import { onlyNumbersInStringCheck } from '@helpers/commonHelpers';

export const minMaxValidation = (value: string, min: number, max: number, defaultValue: number) => {
  let newValue: string | number = value;

  if (newValue === '') {
    return '';
  }

  if (onlyNumbersInStringCheck(newValue)) {
    newValue = Number(newValue);
    if (newValue > max) {
      newValue = max;
    }
    if (newValue < min) {
      newValue = min;
    }
  } else {
    newValue = defaultValue;
  }

  return newValue;
};
