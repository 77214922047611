import React, { ReactNode, useRef } from 'react';

import NumberInput from '@uikit/Input/NumberInput';

import './FromToRow.scss';

type FromToRowProps = {
  label?: React.ReactNode;
  from: number;
  to: number;
  onChange: (fromValue: number | '', toValue: number | '') => void;
  disabled?: boolean;
  inputStyle?: React.CSSProperties;
  maxNumber?: number;
  minNumber?: number;
  containerStyle?: React.CSSProperties;
  children?: ReactNode;
};

const FromToRow: React.FC<FromToRowProps> = ({
  label,
  from,
  to,
  onChange,
  disabled,
  inputStyle,
  maxNumber = 100,
  minNumber = 0,
  containerStyle,
  children,
}) => {
  const minInputRef = useRef<HTMLInputElement>();
  const maxInputRef = useRef<HTMLInputElement>();

  const onBlur = (e: React.FocusEvent) => {
    if (
      ![minInputRef.current, maxInputRef.current].includes(e.relatedTarget as HTMLInputElement) &&
      ![from, to].some((item) => ['', -1].includes(item))
    ) {
      onChange(Math.min(from, to), Math.max(from, to));
    }
  };

  return (
    <div className="from-to-row" style={containerStyle}>
      {!!label && (
        <span className="from-to-row__label">
          {label}
          {!!children && children}
        </span>
      )}

      <div className="from-to-row__double-input">
        <NumberInput
          inputRef={minInputRef}
          tabIndex={0}
          onBlur={onBlur}
          disabled={disabled}
          beforeText="From:"
          value={!from && from !== 0 ? '' : from}
          onChange={(val) => onChange(val, to)}
          maxNumber={maxNumber}
          minNumber={minNumber}
          style={{ ...inputStyle, paddingLeft: 61 }}
        />
        <NumberInput
          inputRef={maxInputRef}
          tabIndex={0}
          onBlur={onBlur}
          disabled={disabled}
          beforeText="To:"
          value={!to && to !== 0 ? '' : to}
          onChange={(val) => onChange(from, val)}
          maxNumber={maxNumber}
          minNumber={minNumber}
          style={{ ...inputStyle, paddingLeft: 41 }}
        />
      </div>
    </div>
  );
};

export default FromToRow;
