import React, { useRef, useState } from 'react';

import { format } from 'date-fns';

import Loader from '@uikit/Loader/Loader';

import { fromNumberAhrefFormatHelper } from '@helpers/transformDataHelpers';
import { HaroOpportunityType } from '@ts/haro.types';
import loadingStatuses from '@constants/loadingStatuses';

import useHexColorsFromCategories from '@hooks/useHexColorsFromCategories';
import useIntersectionObserver from '@hooks/useIntersectionObserver';

import InquiryDetails from '@components/Inquiries/InquiryDetails/InquiryDetails';
import Display from '@components/Display/Display';
import HaroCategories from '@components/Inquiries/HaroCategories/HaroCategories';
import ShowMoreButton from '@components/Inquiries/ShowMoreButton/ShowMoreButton';

import './InquiriestList.scss';

const InquiriestList = ({
  inquiries,
  loadingStatus,
  loadMore,
  onPitch,
  withActions = true,
}: {
  inquiries: HaroOpportunityType[];
  loadingStatus: string;
  loadMore: () => void;
  onPitch?: (number) => void;
  withActions?: boolean;
}): JSX.Element => {
  const loadingRef = useRef<HTMLDivElement>();
  const [expandedInquiry, changeExpandedInquiry] = useState(null);

  const categoriesColorsHash = useHexColorsFromCategories();

  const handleExtendInquiry = (event, inquiryId: string) => {
    event.stopPropagation();
    changeExpandedInquiry(inquiryId);
  };
  const handleHideInquiryDetails = () => changeExpandedInquiry(null);

  useIntersectionObserver(loadingRef, () => loadMore());

  const handlePitchInquiry = (id: string) => {
    if (onPitch) {
      onPitch(id);
    }
  }

  const getClassName = (inquiriId) =>
    inquiriId === expandedInquiry ? 'inquiries-table__tr--opened' : 'inquiries-table__tr';

  if (loadingStatus !== loadingStatuses.LOADED && inquiries.length === 0) {
    return <Loader isLoading withTopMargin />;
  }

  if (inquiries.length === 0) {
    return <div className="inquiries-list__empty-message">There are no new press inquiries.</div>;
  }

  return (
    <div className="inquiries-list">
      <table className="inquiries-table">
        <thead>
          <tr>
            <th className="inquiries-table__th">Summary</th>
            <th className="inquiries-table__th">Publication</th>
            <th className="inquiries-table__th">DA</th>
            <th className="inquiries-table__th">Spam score</th>
            <th className="inquiries-table__th">Received</th>
            <th className="inquiries-table__th">Deadline</th>
            <th className="inquiries-table__th">Categories</th>
          </tr>
        </thead>
        <tbody>
          {inquiries.map((inquiry) => (
            <React.Fragment key={inquiry.id}>
              <tr className={getClassName(inquiry.id)} onClick={() => handlePitchInquiry(inquiry.id)}>
                <td className="inquiries-table__data-cell ">
                  <p className="inquiries-table__data-cell--summary" title={inquiry.summary}>
                    {inquiry.summary}
                  </p>
                </td>
                <td className="inquiries-table__data-cell">
                  <div
                    className="inquiries-table__data-cell--publication"
                    title={inquiry.publication}
                  >
                    {inquiry.publication || '-'}
                  </div>
                </td>
                <td className="inquiries-table__data-cell">{inquiry.domainRating}</td>
                <td className="inquiries-table__data-cell">
                  {inquiry.domainSpamScore ? fromNumberAhrefFormatHelper(inquiry.domainSpamScore) : '-'}
                </td>
                <td className="inquiries-table__data-cell inquiries-table__data-cell--no-wrap">
                  {inquiry.receivedTimestamp
                    ? format(new Date(inquiry.receivedTimestamp), 'MMM dd, yyyy h aaa')
                    : '-'}
                </td>
                <td className="inquiries-table__data-cell inquiries-table__data-cell--no-wrap inquiries-table__data-cell--deadline">
                  {inquiry.deadlineTimestamp
                    ? format(new Date(inquiry.deadlineTimestamp), 'MMM dd, yyyy - h aaa')
                    : '-'}
                </td>
                <td className="inquiries-table__data-cell">
                  <HaroCategories
                    categories={inquiry.categoriesList || []}
                    categoriesColorsHash={categoriesColorsHash}
                  />
                </td>
                <td className="inquiries-table__extend-cell">
                  <ShowMoreButton
                    isVisible={expandedInquiry !== inquiry.id}
                    onClick={(event) => handleExtendInquiry(event, inquiry.id)}
                  />
                </td>
              </tr>
              <Display isVisible={expandedInquiry === inquiry.id}>
                <tr>
                  <td colSpan={8}>
                    <div className="inquiries-table__extended-info">
                      <InquiryDetails
                        inquiry={inquiry}
                        onHide={handleHideInquiryDetails}
                        onPitch={onPitch}
                        withActions={withActions}
                      />
                    </div>
                  </td>
                </tr>
              </Display>
            </React.Fragment>
          ))}
        </tbody>
      </table>
      <Display isVisible={inquiries.length && inquiries.length < inquiries[0].totalItems}>
        <div ref={loadingRef}>
          <Loader isLoading withTopMargin />
        </div>
      </Display>
    </div>
  );
};

export default InquiriestList;
