import React from 'react';
import cn from 'class-names';

import {
  BillingSubscriptionCycle,
  BillingSubscriptionCycleMap,
  BillingSubscriptionType,
  BillingSubscriptionTypeMap,
} from 'respona_api/generated/billing_pb';

import './BillingTypeCheckboxes.scss';

const BillingTypeCheckboxes = ({
  type,
  value,
  onChange,
}: {
  type: BillingSubscriptionTypeMap[keyof BillingSubscriptionTypeMap];
  value: BillingSubscriptionCycleMap[keyof BillingSubscriptionCycleMap];
  onChange: (value: BillingSubscriptionCycleMap[keyof BillingSubscriptionCycleMap]) => void;
}): JSX.Element => (
  <div className="billing-type-checkboxes">
    <div
      className={cn('billing-type-checkboxes__item-container', {
        'billing-type-checkboxes__item-container--active':
          value === BillingSubscriptionCycle.MONTHLY,
        'billing-type-checkboxes__item-container--disabled':
          type === BillingSubscriptionType.UNLIMITED,
      })}
      onClick={() =>
        type === BillingSubscriptionType.UNLIMITED
          ? null
          : onChange(BillingSubscriptionCycle.MONTHLY)
      }
    >
      <BillingTypeCheckboxComponent
        title="Pay monthly"
        onChange={() => null}
        isChecked={value === BillingSubscriptionCycle.MONTHLY}
      />
    </div>
    <div
      className={cn('billing-type-checkboxes__item-container', {
        'billing-type-checkboxes__item-container--active':
          value === BillingSubscriptionCycle.YEARLY,
      })}
      onClick={() => onChange(BillingSubscriptionCycle.YEARLY)}
    >
      <BillingTypeCheckboxComponent
        title={
          <div className="billing-type-checkboxes__sale-container">
            Pay annually<span className="billing-type-checkboxes__sale">-20%</span>
          </div>
        }
        onChange={() => null}
        isChecked={value === BillingSubscriptionCycle.YEARLY}
      />
    </div>
  </div>
);

export default BillingTypeCheckboxes;

export const BillingTypeCheckboxComponent = ({
  title,
  isChecked,
  onChange,
  className,
}: {
  title: React.ReactNode;
  isChecked: boolean;
  onChange: () => void;
  className?: string;
}) => (
  <div
    className={cn('billing-type-checkboxes__item', {
      [className]: !!className,
      'billing-type-checkboxes__item--active': isChecked,
    })}
    onClick={() => onChange()}
    tabIndex={0}
    role="button"
  >
    {title}
  </div>
);
