import React from 'react';

export default ({ color = '#000' }) => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.45 0H2.55C1.8737 0 1.2251 0.26866 0.746878 0.746878C0.26866 1.2251 1.53746e-07 1.8737 1.53746e-07 2.55V3.5445C-0.000121726 3.8955 0.0722214 4.24275 0.2125 4.5645V4.6155C0.332588 4.88832 0.502684 5.13626 0.714 5.3465L5.95 10.5485V16.15C5.94971 16.2944 5.98624 16.4366 6.05614 16.563C6.12604 16.6894 6.227 16.7959 6.3495 16.8725C6.48477 16.9563 6.64086 17.0005 6.8 17C6.93306 16.9992 7.06408 16.9672 7.1825 16.9065L10.5825 15.2065C10.7227 15.1359 10.8406 15.0278 10.9231 14.8943C11.0056 14.7607 11.0495 14.607 11.05 14.45V10.5485L16.252 5.3465C16.4633 5.13626 16.6334 4.88832 16.7535 4.6155V4.5645C16.9055 4.24527 16.9894 3.89791 17 3.5445V2.55C17 1.8737 16.7313 1.2251 16.2531 0.746878C15.7749 0.26866 15.1263 0 14.45 0ZM9.5965 9.5965C9.51772 9.67592 9.4554 9.77012 9.4131 9.87368C9.3708 9.97724 9.34935 10.0881 9.35 10.2V13.923L7.65 14.773V10.2C7.65065 10.0881 7.62921 9.97724 7.58691 9.87368C7.54461 9.77012 7.48228 9.67592 7.4035 9.5965L2.8985 5.1H14.1015L9.5965 9.5965ZM15.3 3.4H1.7V2.55C1.7 2.32457 1.78955 2.10836 1.94896 1.94896C2.10837 1.78955 2.32457 1.7 2.55 1.7H14.45C14.6754 1.7 14.8916 1.78955 15.051 1.94896C15.2104 2.10836 15.3 2.32457 15.3 2.55V3.4Z"
      fill={color}
    />
  </svg>
);
