import React from 'react';

export default ({ color = '#000', className }) => (
  <svg className={className || ''} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.6272 12.9597L15.3355 8.24304C15.4136 8.16557 15.4756 8.07341 15.5179 7.97186C15.5602 7.87031 15.582 7.76139 15.582 7.65138C15.582 7.54137 15.5602 7.43245 15.5179 7.3309C15.4756 7.22935 15.4136 7.13718 15.3355 7.05971C15.1794 6.9045 14.9682 6.81738 14.748 6.81738C14.5279 6.81738 14.3167 6.9045 14.1605 7.05971L9.99387 11.1847L5.86887 7.05971C5.71273 6.9045 5.50152 6.81738 5.28137 6.81738C5.06121 6.81738 4.85 6.9045 4.69387 7.05971C4.61513 7.13689 4.55248 7.22893 4.50957 7.33049C4.46666 7.43205 4.44432 7.54112 4.44387 7.65138C4.44432 7.76163 4.46666 7.8707 4.50957 7.97226C4.55248 8.07383 4.61513 8.16586 4.69387 8.24304L9.4022 12.9597C9.48023 13.0443 9.57494 13.1118 9.68036 13.158C9.78578 13.2041 9.89961 13.228 10.0147 13.228C10.1298 13.228 10.2436 13.2041 10.349 13.158C10.4545 13.1118 10.5492 13.0443 10.6272 12.9597Z"
      fill={color}
    />
  </svg>
);
