import React from 'react';
import { rgba } from 'polished';
import Avatar from '@uikit/Avatar/Avatar';

export default [
  {
    dataKey: 'username',
    RenderTitle: (): JSX.Element => {
      return (
        <div className="credits-usage-by-user-report-table__th">
          <div className="text-align-center mr-5">
            <div>Team Member</div>
          </div>
        </div>
      );
    },
    RenderCell: ({ row: { username, avatarUrl } }) => (
      <div className="credits-usage-by-user-report-table__name">
        <div className="avatar-container">
          <Avatar fullName={username} url={avatarUrl} size="xs" />
        </div>
        <span className="title-symbol-number">{username} </span>
      </div>
    ),
  },
  {
    RenderTitle: ({
      renderProps: { handleSortClick, dataKey, selectedKey, direction },
    }): JSX.Element => {
      return (
        <div className="credits-usage-by-user-report-table__th">
          <div className="text-align-center mr-5">
            <div>Usage category</div>
          </div>
        </div>
      );
    },
    dataKey: 'searchGroup',
    RenderCell: ({ row: { searchGroup, color } }) => {
      return (
        <div className="category-label" style={{ backgroundColor: rgba(color, 0.1), color }}>
          {searchGroup}
        </div>
      );
    },
  },
  {
    RenderTitle: ({
      renderProps: { handleSortClick, dataKey, selectedKey, direction },
    }): JSX.Element => {
      return (
        <div className="credits-usage-by-user-report-table__th">
          <div className="text-align-center mr-5">
            <div>Sub-category</div>
          </div>
        </div>
      );
    },
    dataKey: 'searchCategory',
    RenderCell: ({ row: { searchCategory } }) => <div className="">{searchCategory}</div>,
  },
  {
    RenderTitle: ({
      renderProps: { handleSortClick, dataKey, selectedKey, direction },
    }): JSX.Element => {
      return (
        <div className="credits-usage-by-user-report-table__th">
          <div className="text-align-center mr-5">
            <div># of items</div>
          </div>
        </div>
      );
    },
    dataKey: 'items',
    RenderCell: ({ row: { items } }) => <div className="">{items}</div>,
  },
  {
    RenderTitle: ({
      renderProps: { handleSortClick, dataKey, selectedKey, direction },
    }): JSX.Element => {
      return (
        <div className="credits-usage-by-user-report-table__th">
          <div className="text-align-center mr-5">
            <div>Credits used</div>
          </div>
        </div>
      );
    },
    dataKey: 'creditsAmount',
    RenderCell: ({ row: { creditsAmount } }) => <div className="text-blue">{creditsAmount}</div>,
  },
  {
    RenderTitle: ({
      renderProps: { handleSortClick, dataKey, selectedKey, direction },
    }): JSX.Element => {
      return (
        <div className="credits-usage-by-user-report-table__th">
          <div className="text-align-center mr-5">
            <div>Date/time</div>
          </div>
        </div>
      );
    },
    dataKey: 'datetime',
    RenderCell: ({ row: { datetime } }) => <div className="">{datetime}</div>,
  },
];
