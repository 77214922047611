export default (fileName, csvContent): void => {
  const data = window.URL.createObjectURL(csvContent);

  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.href = data;
  link.download = fileName;
  document.body.appendChild(link);

  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );

  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
    link.remove();
  }, 100);
};
