import React from 'react';

export default ({ size = 19 }) => (
  <svg width={size} height={size} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width={size} height={size} rx="3" fill="#064ADB" />
    <path
      d="M5.74306 6.35928H11.4644V7.89777L9.31276 8.05605C5.83844 8.30419 4.75 9.18544 4.75 11.6508V12.1484C4.75 13.9565 6.07408 15.0416 8.1079 15.0416C9.71531 15.0416 10.6326 14.6795 11.773 13.5662H11.9567V14.7425H14.25V3.95831H5.74306V6.35928ZM11.4644 11.5596C10.7631 12.2383 9.69147 12.6903 8.81622 12.6903C7.87085 12.6903 7.4697 12.351 7.49214 11.6052C7.51599 10.5643 7.91293 10.3162 9.73635 10.1579L11.4616 9.99962L11.4644 11.5596Z"
      fill="#FF8800"
    />
  </svg>
);
