import { getAllSchedulesApi, updateScheduleApi, createScheduleApi, removeScheduleApi } from '@api/schedules.api';

import {
  setSchedules,
  setSchedulesLs,
  updateSchedules,
  addSchedules,
  removeSchedules,
} from '../actions/schedules.actions';

import loadingStatuses from '@constants/loadingStatuses';

export const fetchAllSchedules = (dispatch) => {
  dispatch(setSchedulesLs(loadingStatuses.INITED));

  return getAllSchedulesApi()
    .then((schedules) => {
      dispatch(setSchedules(schedules));
    })
    .finally(() => {
      dispatch(setSchedulesLs(loadingStatuses.LOADED));
    });
};

export const updateSchedule = (dispatch, schedule) => {
  return updateScheduleApi(schedule).then((scheduleRes) => {
    dispatch(updateSchedules(scheduleRes));
  });
};

export const createSchedule = (dispatch, schedule) => {
  return createScheduleApi(schedule).then((scheduleRes) => {
    dispatch(addSchedules(scheduleRes));
  });
};

export const removeSchedule = (dispatch, scheduleId) => {
  return removeScheduleApi(scheduleId).then(() => {
    dispatch(removeSchedules(scheduleId));
  });
};
