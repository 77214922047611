import React, { useEffect } from 'react';
import cn from 'class-names';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { SVGIcon } from 'src/uikit/Icon/Icon';

import { haroFiltersSelector } from 'src/redux/selectors/inquiries.selectors';
import { fetchHaroFilters, removeHaroFilter } from 'src/redux/thunks/inquries.thunks';
import { getCurrentWorkspaceId } from 'src/redux/selectors/workspaces.selectors';
import Loader from 'src/uikit/Loader/Loader';
import { checkIsAnyNotLoaded } from 'src/utils/loadingChecks';
import ActionsMenu from 'src/components/ActionsMenu/ActionsMenu';
import { MEDIUM_GREY_COLOR_FOR_ICONS, PRIMARY_COLOR } from '@constants/colors';

import './InquiriesSidebar.scss';
import urls from '@constants/urls';

const FilterLink = ({
  title,
  id,
  workspaceId,
  isActive,
}: {
  title: string;
  id: number;
  workspaceId: number;
  isActive: boolean;
}): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleRemoveFilter = () => dispatch(removeHaroFilter(workspaceId, id));

  const actionsMenu = [
    {
      title: 'Update',
      icon: 'pen',
      handler: () => {
        history.push(`${urls.INQUIRIES_FILTERS_UPDATE}/${id}`);
      },
    },
  ];

  return (
    <div
      className={cn('inquiries-sidebar__page-link', {
        'inquiries-sidebar__page-link--active': isActive,
      })}
    >
      <Link
        className="inquiries-sidebar__page-link-href"
        to={`/inquiries/all-inquiries?filterId=${id}`}
      >
        <div className="inquiries-sidebar__page-link-icon">
          <SVGIcon icon="filter" color={isActive ? PRIMARY_COLOR : '#000000'} />
        </div>
        {title}
      </Link>
      <div className="inquiries-sidebar__page-link-actions">
        <ActionsMenu
          id={`haro-filter-${id}`}
          onRemove={handleRemoveFilter}
          actionsConfig={actionsMenu}
          catchEvents
        />
      </div>
    </div>
  );
};

export default ({ filterId, pathname }: { filterId?: number; pathname: string }): JSX.Element => {
  const dispatch = useDispatch();
  const curWorkspaceId = useSelector(getCurrentWorkspaceId);
  const { items: filters, loadingStatus: filtersLs } = useSelector(haroFiltersSelector);

  const isFilterLinkActive = (id) => '/inquiries/all-inquiries' && filterId === id;

  useEffect(() => {
    if (curWorkspaceId) {
      dispatch(fetchHaroFilters(curWorkspaceId));
    }
  }, [curWorkspaceId]);

  return (
    <div className="inquiries-sidebar">
      <div className="inquiries-sidebar__header">Inquiries</div>
      <div className="inquiries-sidebar__pages">
        <div
          className={cn('inquiries-sidebar__page-link', {
            'inquiries-sidebar__page-link--active':
              pathname === '/inquiries/all-inquiries' && !filterId,
          })}
        >
          <Link to="/inquiries/all-inquiries" className="inquiries-sidebar__page-link-href">
            <div className="inquiries-sidebar__page-link-icon">
              <SVGIcon
                icon="inquiries"
                color={
                  pathname === '/inquiries/all-inquiries' && !filterId ? PRIMARY_COLOR : '#000000'
                }
              />
            </div>
            All Inquiries
          </Link>
        </div>
        <div className="inquiries-sidebar__separator" />
        <div className="inquiries-sidebar__sub-title">
          Saved Filters
          <Link
            to={urls.INQUIRIES_FILTERS_CREATE}
            className="inquiries-sidebar__sub-title-action-icon"
          >
            <SVGIcon icon="plusCircle" color={MEDIUM_GREY_COLOR_FOR_ICONS} />
          </Link>
        </div>
        <Loader isLoading={checkIsAnyNotLoaded(filtersLs)} withTopMargin>
          <>
            {filters.map(({ id, title }) => (
              <FilterLink
                title={title}
                id={id}
                workspaceId={curWorkspaceId}
                isActive={isFilterLinkActive(id)}
                key={id}
              />
            ))}
          </>
        </Loader>
      </div>

      <div className="inquiries-sidebar__footer">
        <Link
          to="/inquiries/bookmarks"
          className={cn(
            'inquiries-sidebar__footer-button inquiries-sidebar__footer-button--bookmarks',
            {
              'inquiries-sidebar__footer-button--active': pathname === '/inquiries/bookmarks',
            }
          )}
        >
          <span className="inquiries-sidebar__footer-button-icon">
            <SVGIcon
              icon="bookmark"
              color={pathname === '/inquiries/bookmarks' ? '#251FB6' : '#8F9199'}
            />
          </span>
          Bookmarks
        </Link>
        <Link
          to="/inquiries/pitched"
          className={cn(
            'inquiries-sidebar__footer-button inquiries-sidebar__footer-button--pitched',
            {
              'inquiries-sidebar__footer-button--active': pathname === '/inquiries/pitched',
            }
          )}
        >
          <span className="inquiries-sidebar__footer-button-icon">
            <SVGIcon icon="pen" color={pathname === '/inquiries/pitched' ? '#251FB6' : '#8F9199'} />
          </span>
          Pitched
        </Link>
      </div>
    </div>
  );
};
