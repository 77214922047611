import React from 'react';

import Button from '@uikit/Button/Button';
import BaseModal from '@uikit/BaseModal/BaseModal';

import RemoveModalContent from './_components/RemoveModalContent';

import './RemoveCompaniesModal.scss';

const RemoveCompaniesModal = ({
  isRemovingModalOpen,
  changeRemovingModalOpen,
  onRemoveCompanies,
  selectedIds,
  companies,
}) => {
  return (
    <BaseModal isOpen={isRemovingModalOpen} onClose={() => changeRemovingModalOpen(false)}>
      <div className="relationships-remove-modal">
        <div className="relationships-remove-modal__header">Remove companies</div>
        <div className="relationships-remove-modal__body">
          <RemoveModalContent selectedIds={selectedIds} companies={companies} />
        </div>
        <div className="relationships-remove-modal__footer">
          <Button type="alert" onClick={() => onRemoveCompanies()}>
            Remove
          </Button>
          <Button onClick={() => changeRemovingModalOpen(false)}>Cancel</Button>
        </div>
      </div>
    </BaseModal>
  );
};

export default RemoveCompaniesModal;
