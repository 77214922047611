// @ts-nocheck
/* eslint-disable */
import { Transforms, Element, Node, Editor, Path, Range } from 'slate';
import isUrl from '@utils/isUrl';
import { wrapLink } from '@uikit/RichEditor/utils/linkUtils';

// TODO: refactor this shit to typescript

export default (editor) => {
  const { insertData, insertText, insertBreak, isInline, normalizeNode } = editor;

  editor.isInline = (element) => {
    // @ts-ignore
    return element.type === 'link' ? true : isInline(element);
  };

  editor.insertText = (text) => {
    const [selectedElement, path] = Editor.parent(editor, editor.selection);

    if (text === ' ' && selectedElement.type === 'link') {
      const endPoint = Range.end(editor.selection);
      const [selectedLeaf] = Editor.node(editor, endPoint);
      if (selectedLeaf.text.length === endPoint.offset) {
        // if (Range.isExpanded(editor.selection)) {
        //   Transforms.delete(editor);
        // }

        Transforms.select(editor, {
          focus: { path: Path.next(path), offset: 0 },
          anchor: { path: Path.next(path), offset: 0 },
        });
      }
    }

    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertBreak = () => {
    const parent = Node.parent(editor, editor.selection.focus.path);

    if (parent?.type === 'link') {
      insertBreak();
      const newParent = Node.parent(editor, editor.selection.focus.path);

      if (newParent?.type === 'link') {
        Transforms.removeNodes(editor, { at: editor.selection.focus.path });
      }
      return;
    }

    insertBreak();
  };

  editor.insertData = (data) => {
    const text = data.getData('text/plain');

    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  editor.normalizeNode = (entry) => {
    const [node, path] = entry;
    if (Element.isElement(node) && node.type === 'paragraph') {
      const children = Array.from(Node.children(editor, path));
      for (const [child, childPath] of children) {
        // remove link nodes whose text value is empty string.
        // empty text links happen when you move from link to next line or delete link line.
        if (Element.isElement(child) && child.type === 'link' && child.children[0].text === ' ') {
          if (children.length === 1) {
            Transforms.removeNodes(editor, { at: path });
            Transforms.insertNodes(
              editor,
              {
                type: 'paragraph',
                children: [{ text: ' ' }],
              },
              { at: path }
            );
          } else {
            Transforms.removeNodes(editor, { at: childPath });
          }
          return;
        }
      }
    }
    normalizeNode(entry);
  };

  return editor;
};
