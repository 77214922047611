import React from 'react';

export default ({ color = '#C4C6CD' }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.54688 15.1301C4.75782 14.783 5.10939 14.5748 5.46095 14.5748H6.86721L6.02346 16.0324L5.95314 16.1018C5.7422 16.5183 5.1797 16.5877 4.75782 16.3795C4.33595 16.1713 4.19532 15.616 4.47657 15.1995L4.54688 15.1301ZM9.82036 12.0066C10.3125 12.0066 10.7344 12.2842 10.9454 12.7007L11.5782 13.7419H3.84376C3.42188 13.7419 3 13.3254 3 12.8395C3 12.423 3.35156 12.0066 3.84376 12.0066H6.44533L9.04691 7.56427L7.78128 5.34311C7.71097 5.34311 7.71097 5.2737 7.71097 5.2737C7.42972 4.78782 7.64065 4.30194 8.06253 4.09371C8.48441 3.88547 9.04691 4.02429 9.25785 4.51017L10.0313 5.82899L10.8047 4.51017L10.8751 4.44076C11.086 4.02429 11.6485 3.95488 12.0704 4.16312C12.4922 4.37135 12.6329 4.92664 12.3516 5.34311L8.4141 12.0066H9.82036ZM16.2188 12.0066C16.6407 12.0066 17.0626 12.423 16.9923 12.9089C16.9923 13.3254 16.6407 13.7419 16.1485 13.7419H14.6719L15.586 15.2689C15.6563 15.2689 15.6563 15.3383 15.6563 15.3383C15.8673 15.7548 15.6563 16.3101 15.2345 16.5183C14.8126 16.7265 14.2501 16.5183 14.0391 16.1018L11.1563 11.1736C10.8047 10.5489 10.8047 9.71601 11.1563 9.09131L11.5782 8.3972L13.6876 12.0066H16.2188Z"
      fill={color}
    />
  </svg>
);
