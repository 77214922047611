import { removeNotification } from '@redux/actions/notifications.actions';
import React, { useEffect, useRef } from 'react';
import cn from 'class-names';

import { NotificationType } from '@ts/notifications.types';

import { Button } from '@uikit/Button/Button';
import { SVGIcon } from '@uikit/Icon/Icon';

import './NotificationBanner.scss';
import { useDispatch } from 'react-redux';

const NotificationBanner = ({
  id,
  title,
  content,
  type,
  delay,
  onConfirm,
  onDecline,
  onConfirmText,
  onDeclineText,
  dismissible = true,
}: NotificationType): JSX.Element => {
  const dispatch = useDispatch();
  const closeTimer = useRef(null);

  const cnNotification = cn('notification', {
    [`notification--${type}`]: Boolean(type),
    'notification--with-actions': onConfirm || onDecline,
    'notification--with-content': !!content,
  });

  const cnNotificationSign = cn('notification__sign', {
    [`notification--${type}__sign`]: type === 'warning',
  });

  const onRemoveNotification = (notificationId: string | number): void =>
    dispatch(removeNotification(notificationId));

  useEffect(() => {
    // Prevent setting timeout if the delay is zero
    if (!delay) {
      return null;
    }

    closeTimer.current = setTimeout(() => {
      onRemoveNotification(id);
    }, delay);

    return () => {
      clearTimeout(closeTimer.current);
    };
  });

  return (
    <div
      className={cn('notification-container', {
        [`notification--${type}`]: Boolean(type),
      })}
    >
      <div className={cnNotification}>
        {type === 'warning' ? (
          <div className={cnNotificationSign}>
            <SVGIcon icon="warningRoundedSignIcon" color="#fff" />
          </div>
        ) : null}

        {title || null}

        {dismissible && (
          <span className="notification__close-btn" onClick={() => onRemoveNotification(id)}>
            <SVGIcon icon="crossBlack" color="rgba(255, 255, 255, 0.6)" />
          </span>
        )}
      </div>

      {!!content && <div className="notification__content">{content}</div>}

      {(onConfirm || onDecline) && (
        <div className="notification__action-btn-row">
          {onDecline && (
            <Button
              className="notification__action-btn"
              type="white"
              onClick={() => {
                onRemoveNotification(id);
                onDecline();
              }}
            >
              {onDeclineText || 'Decline'}
            </Button>
          )}
          {onConfirm && (
            <Button
              className="notification__action-btn"
              type="white"
              onClick={() => {
                onRemoveNotification(id);
                onConfirm();
              }}
            >
              {onConfirmText || 'Confirm'}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default NotificationBanner;
