// source: relationship-pipeline.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
var people_pb = require('./people_pb.js');
goog.object.extend(proto, people_pb);
goog.exportSymbol('proto.relationships.RelationshipPipelineCrudRequest', null, global);
goog.exportSymbol('proto.relationships.RelationshipPipelineCustomVariableResponse', null, global);
goog.exportSymbol('proto.relationships.RelationshipPipelineMoveRequest', null, global);
goog.exportSymbol('proto.relationships.RelationshipPipelineResponse', null, global);
goog.exportSymbol('proto.relationships.RelationshipPipelineStageCrudRequest', null, global);
goog.exportSymbol('proto.relationships.RelationshipPipelineStageOpportunityAssignRequest', null, global);
goog.exportSymbol('proto.relationships.RelationshipPipelineStageOpportunityCrudRequest', null, global);
goog.exportSymbol('proto.relationships.RelationshipPipelineStageOpportunityResponse', null, global);
goog.exportSymbol('proto.relationships.RelationshipPipelineStageOpportunitySuggestionRequest', null, global);
goog.exportSymbol('proto.relationships.RelationshipPipelineStageOpportunitySuggestionResponse', null, global);
goog.exportSymbol('proto.relationships.RelationshipPipelineStageResponse', null, global);
goog.exportSymbol('proto.relationships.StageOpportunityFilter', null, global);
goog.exportSymbol('proto.relationships.StageOpportunityFilterField', null, global);
goog.exportSymbol('proto.relationships.StageOpportunityFilterRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.relationships.StageOpportunityFilterRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.relationships.StageOpportunityFilterRequest.repeatedFields_, null);
};
goog.inherits(proto.relationships.StageOpportunityFilterRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.relationships.StageOpportunityFilterRequest.displayName = 'proto.relationships.StageOpportunityFilterRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.relationships.StageOpportunityFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.relationships.StageOpportunityFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.relationships.StageOpportunityFilter.displayName = 'proto.relationships.StageOpportunityFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.relationships.RelationshipPipelineCrudRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.relationships.RelationshipPipelineCrudRequest.repeatedFields_, null);
};
goog.inherits(proto.relationships.RelationshipPipelineCrudRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.relationships.RelationshipPipelineCrudRequest.displayName = 'proto.relationships.RelationshipPipelineCrudRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.relationships.RelationshipPipelineResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.relationships.RelationshipPipelineResponse.repeatedFields_, null);
};
goog.inherits(proto.relationships.RelationshipPipelineResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.relationships.RelationshipPipelineResponse.displayName = 'proto.relationships.RelationshipPipelineResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.relationships.RelationshipPipelineCustomVariableResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.relationships.RelationshipPipelineCustomVariableResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.relationships.RelationshipPipelineCustomVariableResponse.displayName = 'proto.relationships.RelationshipPipelineCustomVariableResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.relationships.RelationshipPipelineStageCrudRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.relationships.RelationshipPipelineStageCrudRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.relationships.RelationshipPipelineStageCrudRequest.displayName = 'proto.relationships.RelationshipPipelineStageCrudRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.relationships.RelationshipPipelineStageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.relationships.RelationshipPipelineStageResponse.repeatedFields_, null);
};
goog.inherits(proto.relationships.RelationshipPipelineStageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.relationships.RelationshipPipelineStageResponse.displayName = 'proto.relationships.RelationshipPipelineStageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.relationships.RelationshipPipelineStageOpportunitySuggestionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.relationships.RelationshipPipelineStageOpportunitySuggestionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.relationships.RelationshipPipelineStageOpportunitySuggestionRequest.displayName = 'proto.relationships.RelationshipPipelineStageOpportunitySuggestionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.relationships.RelationshipPipelineStageOpportunitySuggestionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.relationships.RelationshipPipelineStageOpportunitySuggestionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.relationships.RelationshipPipelineStageOpportunitySuggestionResponse.displayName = 'proto.relationships.RelationshipPipelineStageOpportunitySuggestionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.relationships.RelationshipPipelineStageOpportunityCrudRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.relationships.RelationshipPipelineStageOpportunityCrudRequest.repeatedFields_, null);
};
goog.inherits(proto.relationships.RelationshipPipelineStageOpportunityCrudRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.relationships.RelationshipPipelineStageOpportunityCrudRequest.displayName = 'proto.relationships.RelationshipPipelineStageOpportunityCrudRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.relationships.RelationshipPipelineStageOpportunityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.relationships.RelationshipPipelineStageOpportunityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.relationships.RelationshipPipelineStageOpportunityResponse.displayName = 'proto.relationships.RelationshipPipelineStageOpportunityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.relationships.RelationshipPipelineMoveRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.relationships.RelationshipPipelineMoveRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.relationships.RelationshipPipelineMoveRequest.displayName = 'proto.relationships.RelationshipPipelineMoveRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.relationships.RelationshipPipelineStageOpportunityAssignRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.relationships.RelationshipPipelineStageOpportunityAssignRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.relationships.RelationshipPipelineStageOpportunityAssignRequest.displayName = 'proto.relationships.RelationshipPipelineStageOpportunityAssignRequest';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.relationships.StageOpportunityFilterRequest.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.relationships.StageOpportunityFilterRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.relationships.StageOpportunityFilterRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.relationships.StageOpportunityFilterRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.relationships.StageOpportunityFilterRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pipelineId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    page: jspb.Message.getFieldWithDefault(msg, 3, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 4, 0),
    query: jspb.Message.getFieldWithDefault(msg, 5, ""),
    filtersList: jspb.Message.toObjectList(msg.getFiltersList(),
    proto.relationships.StageOpportunityFilter.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.relationships.StageOpportunityFilterRequest}
 */
proto.relationships.StageOpportunityFilterRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.relationships.StageOpportunityFilterRequest;
  return proto.relationships.StageOpportunityFilterRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.relationships.StageOpportunityFilterRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.relationships.StageOpportunityFilterRequest}
 */
proto.relationships.StageOpportunityFilterRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPipelineId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 6:
      var value = new proto.relationships.StageOpportunityFilter;
      reader.readMessage(value,proto.relationships.StageOpportunityFilter.deserializeBinaryFromReader);
      msg.addFilters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.relationships.StageOpportunityFilterRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.relationships.StageOpportunityFilterRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.relationships.StageOpportunityFilterRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.relationships.StageOpportunityFilterRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPipelineId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.relationships.StageOpportunityFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 workspace_id = 1;
 * @return {number}
 */
proto.relationships.StageOpportunityFilterRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.relationships.StageOpportunityFilterRequest} returns this
 */
proto.relationships.StageOpportunityFilterRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 pipeline_id = 2;
 * @return {number}
 */
proto.relationships.StageOpportunityFilterRequest.prototype.getPipelineId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.relationships.StageOpportunityFilterRequest} returns this
 */
proto.relationships.StageOpportunityFilterRequest.prototype.setPipelineId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 page = 3;
 * @return {number}
 */
proto.relationships.StageOpportunityFilterRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.relationships.StageOpportunityFilterRequest} returns this
 */
proto.relationships.StageOpportunityFilterRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 limit = 4;
 * @return {number}
 */
proto.relationships.StageOpportunityFilterRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.relationships.StageOpportunityFilterRequest} returns this
 */
proto.relationships.StageOpportunityFilterRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string query = 5;
 * @return {string}
 */
proto.relationships.StageOpportunityFilterRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.relationships.StageOpportunityFilterRequest} returns this
 */
proto.relationships.StageOpportunityFilterRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated StageOpportunityFilter filters = 6;
 * @return {!Array<!proto.relationships.StageOpportunityFilter>}
 */
proto.relationships.StageOpportunityFilterRequest.prototype.getFiltersList = function() {
  return /** @type{!Array<!proto.relationships.StageOpportunityFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.relationships.StageOpportunityFilter, 6));
};


/**
 * @param {!Array<!proto.relationships.StageOpportunityFilter>} value
 * @return {!proto.relationships.StageOpportunityFilterRequest} returns this
*/
proto.relationships.StageOpportunityFilterRequest.prototype.setFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.relationships.StageOpportunityFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.relationships.StageOpportunityFilter}
 */
proto.relationships.StageOpportunityFilterRequest.prototype.addFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.relationships.StageOpportunityFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.relationships.StageOpportunityFilterRequest} returns this
 */
proto.relationships.StageOpportunityFilterRequest.prototype.clearFiltersList = function() {
  return this.setFiltersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.relationships.StageOpportunityFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.relationships.StageOpportunityFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.relationships.StageOpportunityFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.relationships.StageOpportunityFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    order: jspb.Message.getFieldWithDefault(msg, 1, 0),
    field: jspb.Message.getFieldWithDefault(msg, 2, 0),
    value: jspb.Message.getFieldWithDefault(msg, 3, ""),
    operation: jspb.Message.getFieldWithDefault(msg, 4, 0),
    join: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.relationships.StageOpportunityFilter}
 */
proto.relationships.StageOpportunityFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.relationships.StageOpportunityFilter;
  return proto.relationships.StageOpportunityFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.relationships.StageOpportunityFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.relationships.StageOpportunityFilter}
 */
proto.relationships.StageOpportunityFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 2:
      var value = /** @type {!proto.relationships.StageOpportunityFilterField} */ (reader.readEnum());
      msg.setField(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 4:
      var value = /** @type {!proto.common.FilterOperation} */ (reader.readEnum());
      msg.setOperation(value);
      break;
    case 5:
      var value = /** @type {!proto.common.FilterJoinType} */ (reader.readEnum());
      msg.setJoin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.relationships.StageOpportunityFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.relationships.StageOpportunityFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.relationships.StageOpportunityFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.relationships.StageOpportunityFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getField();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOperation();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getJoin();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional int32 order = 1;
 * @return {number}
 */
proto.relationships.StageOpportunityFilter.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.relationships.StageOpportunityFilter} returns this
 */
proto.relationships.StageOpportunityFilter.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional StageOpportunityFilterField field = 2;
 * @return {!proto.relationships.StageOpportunityFilterField}
 */
proto.relationships.StageOpportunityFilter.prototype.getField = function() {
  return /** @type {!proto.relationships.StageOpportunityFilterField} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.relationships.StageOpportunityFilterField} value
 * @return {!proto.relationships.StageOpportunityFilter} returns this
 */
proto.relationships.StageOpportunityFilter.prototype.setField = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.relationships.StageOpportunityFilter.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.relationships.StageOpportunityFilter} returns this
 */
proto.relationships.StageOpportunityFilter.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional common.FilterOperation operation = 4;
 * @return {!proto.common.FilterOperation}
 */
proto.relationships.StageOpportunityFilter.prototype.getOperation = function() {
  return /** @type {!proto.common.FilterOperation} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.common.FilterOperation} value
 * @return {!proto.relationships.StageOpportunityFilter} returns this
 */
proto.relationships.StageOpportunityFilter.prototype.setOperation = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional common.FilterJoinType join = 5;
 * @return {!proto.common.FilterJoinType}
 */
proto.relationships.StageOpportunityFilter.prototype.getJoin = function() {
  return /** @type {!proto.common.FilterJoinType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.common.FilterJoinType} value
 * @return {!proto.relationships.StageOpportunityFilter} returns this
 */
proto.relationships.StageOpportunityFilter.prototype.setJoin = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.relationships.RelationshipPipelineCrudRequest.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.relationships.RelationshipPipelineCrudRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.relationships.RelationshipPipelineCrudRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.relationships.RelationshipPipelineCrudRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.relationships.RelationshipPipelineCrudRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    workspaceIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    customVariablesList: jspb.Message.toObjectList(msg.getCustomVariablesList(),
    proto.relationships.RelationshipPipelineCustomVariableResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.relationships.RelationshipPipelineCrudRequest}
 */
proto.relationships.RelationshipPipelineCrudRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.relationships.RelationshipPipelineCrudRequest;
  return proto.relationships.RelationshipPipelineCrudRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.relationships.RelationshipPipelineCrudRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.relationships.RelationshipPipelineCrudRequest}
 */
proto.relationships.RelationshipPipelineCrudRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setWorkspaceIdsList(value);
      break;
    case 4:
      var value = new proto.relationships.RelationshipPipelineCustomVariableResponse;
      reader.readMessage(value,proto.relationships.RelationshipPipelineCustomVariableResponse.deserializeBinaryFromReader);
      msg.addCustomVariables(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.relationships.RelationshipPipelineCrudRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.relationships.RelationshipPipelineCrudRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.relationships.RelationshipPipelineCrudRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.relationships.RelationshipPipelineCrudRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getWorkspaceIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      3,
      f
    );
  }
  f = message.getCustomVariablesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.relationships.RelationshipPipelineCustomVariableResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.relationships.RelationshipPipelineCrudRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.relationships.RelationshipPipelineCrudRequest} returns this
 */
proto.relationships.RelationshipPipelineCrudRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.relationships.RelationshipPipelineCrudRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.relationships.RelationshipPipelineCrudRequest} returns this
 */
proto.relationships.RelationshipPipelineCrudRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated int64 workspace_ids = 3;
 * @return {!Array<number>}
 */
proto.relationships.RelationshipPipelineCrudRequest.prototype.getWorkspaceIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.relationships.RelationshipPipelineCrudRequest} returns this
 */
proto.relationships.RelationshipPipelineCrudRequest.prototype.setWorkspaceIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.relationships.RelationshipPipelineCrudRequest} returns this
 */
proto.relationships.RelationshipPipelineCrudRequest.prototype.addWorkspaceIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.relationships.RelationshipPipelineCrudRequest} returns this
 */
proto.relationships.RelationshipPipelineCrudRequest.prototype.clearWorkspaceIdsList = function() {
  return this.setWorkspaceIdsList([]);
};


/**
 * repeated RelationshipPipelineCustomVariableResponse custom_variables = 4;
 * @return {!Array<!proto.relationships.RelationshipPipelineCustomVariableResponse>}
 */
proto.relationships.RelationshipPipelineCrudRequest.prototype.getCustomVariablesList = function() {
  return /** @type{!Array<!proto.relationships.RelationshipPipelineCustomVariableResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.relationships.RelationshipPipelineCustomVariableResponse, 4));
};


/**
 * @param {!Array<!proto.relationships.RelationshipPipelineCustomVariableResponse>} value
 * @return {!proto.relationships.RelationshipPipelineCrudRequest} returns this
*/
proto.relationships.RelationshipPipelineCrudRequest.prototype.setCustomVariablesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.relationships.RelationshipPipelineCustomVariableResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.relationships.RelationshipPipelineCustomVariableResponse}
 */
proto.relationships.RelationshipPipelineCrudRequest.prototype.addCustomVariables = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.relationships.RelationshipPipelineCustomVariableResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.relationships.RelationshipPipelineCrudRequest} returns this
 */
proto.relationships.RelationshipPipelineCrudRequest.prototype.clearCustomVariablesList = function() {
  return this.setCustomVariablesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.relationships.RelationshipPipelineResponse.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.relationships.RelationshipPipelineResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.relationships.RelationshipPipelineResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.relationships.RelationshipPipelineResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.relationships.RelationshipPipelineResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    workspaceIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    customVariablesList: jspb.Message.toObjectList(msg.getCustomVariablesList(),
    proto.relationships.RelationshipPipelineCustomVariableResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.relationships.RelationshipPipelineResponse}
 */
proto.relationships.RelationshipPipelineResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.relationships.RelationshipPipelineResponse;
  return proto.relationships.RelationshipPipelineResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.relationships.RelationshipPipelineResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.relationships.RelationshipPipelineResponse}
 */
proto.relationships.RelationshipPipelineResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setWorkspaceIdsList(value);
      break;
    case 4:
      var value = new proto.relationships.RelationshipPipelineCustomVariableResponse;
      reader.readMessage(value,proto.relationships.RelationshipPipelineCustomVariableResponse.deserializeBinaryFromReader);
      msg.addCustomVariables(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.relationships.RelationshipPipelineResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.relationships.RelationshipPipelineResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.relationships.RelationshipPipelineResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.relationships.RelationshipPipelineResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getWorkspaceIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      3,
      f
    );
  }
  f = message.getCustomVariablesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.relationships.RelationshipPipelineCustomVariableResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.relationships.RelationshipPipelineResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.relationships.RelationshipPipelineResponse} returns this
 */
proto.relationships.RelationshipPipelineResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.relationships.RelationshipPipelineResponse.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.relationships.RelationshipPipelineResponse} returns this
 */
proto.relationships.RelationshipPipelineResponse.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated int64 workspace_ids = 3;
 * @return {!Array<number>}
 */
proto.relationships.RelationshipPipelineResponse.prototype.getWorkspaceIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.relationships.RelationshipPipelineResponse} returns this
 */
proto.relationships.RelationshipPipelineResponse.prototype.setWorkspaceIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.relationships.RelationshipPipelineResponse} returns this
 */
proto.relationships.RelationshipPipelineResponse.prototype.addWorkspaceIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.relationships.RelationshipPipelineResponse} returns this
 */
proto.relationships.RelationshipPipelineResponse.prototype.clearWorkspaceIdsList = function() {
  return this.setWorkspaceIdsList([]);
};


/**
 * repeated RelationshipPipelineCustomVariableResponse custom_variables = 4;
 * @return {!Array<!proto.relationships.RelationshipPipelineCustomVariableResponse>}
 */
proto.relationships.RelationshipPipelineResponse.prototype.getCustomVariablesList = function() {
  return /** @type{!Array<!proto.relationships.RelationshipPipelineCustomVariableResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.relationships.RelationshipPipelineCustomVariableResponse, 4));
};


/**
 * @param {!Array<!proto.relationships.RelationshipPipelineCustomVariableResponse>} value
 * @return {!proto.relationships.RelationshipPipelineResponse} returns this
*/
proto.relationships.RelationshipPipelineResponse.prototype.setCustomVariablesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.relationships.RelationshipPipelineCustomVariableResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.relationships.RelationshipPipelineCustomVariableResponse}
 */
proto.relationships.RelationshipPipelineResponse.prototype.addCustomVariables = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.relationships.RelationshipPipelineCustomVariableResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.relationships.RelationshipPipelineResponse} returns this
 */
proto.relationships.RelationshipPipelineResponse.prototype.clearCustomVariablesList = function() {
  return this.setCustomVariablesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.relationships.RelationshipPipelineCustomVariableResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.relationships.RelationshipPipelineCustomVariableResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.relationships.RelationshipPipelineCustomVariableResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.relationships.RelationshipPipelineCustomVariableResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    type: jspb.Message.getFieldWithDefault(msg, 5, 0),
    relatedTo: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.relationships.RelationshipPipelineCustomVariableResponse}
 */
proto.relationships.RelationshipPipelineCustomVariableResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.relationships.RelationshipPipelineCustomVariableResponse;
  return proto.relationships.RelationshipPipelineCustomVariableResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.relationships.RelationshipPipelineCustomVariableResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.relationships.RelationshipPipelineCustomVariableResponse}
 */
proto.relationships.RelationshipPipelineCustomVariableResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {!proto.common.CustomVariableType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 6:
      var value = /** @type {!proto.common.CustomVariableValueRelatedTo} */ (reader.readEnum());
      msg.setRelatedTo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.relationships.RelationshipPipelineCustomVariableResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.relationships.RelationshipPipelineCustomVariableResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.relationships.RelationshipPipelineCustomVariableResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.relationships.RelationshipPipelineCustomVariableResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getRelatedTo();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.relationships.RelationshipPipelineCustomVariableResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.relationships.RelationshipPipelineCustomVariableResponse} returns this
 */
proto.relationships.RelationshipPipelineCustomVariableResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.relationships.RelationshipPipelineCustomVariableResponse.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.relationships.RelationshipPipelineCustomVariableResponse} returns this
 */
proto.relationships.RelationshipPipelineCustomVariableResponse.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.relationships.RelationshipPipelineCustomVariableResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.relationships.RelationshipPipelineCustomVariableResponse} returns this
 */
proto.relationships.RelationshipPipelineCustomVariableResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.relationships.RelationshipPipelineCustomVariableResponse.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.relationships.RelationshipPipelineCustomVariableResponse} returns this
 */
proto.relationships.RelationshipPipelineCustomVariableResponse.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional common.CustomVariableType type = 5;
 * @return {!proto.common.CustomVariableType}
 */
proto.relationships.RelationshipPipelineCustomVariableResponse.prototype.getType = function() {
  return /** @type {!proto.common.CustomVariableType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.common.CustomVariableType} value
 * @return {!proto.relationships.RelationshipPipelineCustomVariableResponse} returns this
 */
proto.relationships.RelationshipPipelineCustomVariableResponse.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional common.CustomVariableValueRelatedTo related_to = 6;
 * @return {!proto.common.CustomVariableValueRelatedTo}
 */
proto.relationships.RelationshipPipelineCustomVariableResponse.prototype.getRelatedTo = function() {
  return /** @type {!proto.common.CustomVariableValueRelatedTo} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.common.CustomVariableValueRelatedTo} value
 * @return {!proto.relationships.RelationshipPipelineCustomVariableResponse} returns this
 */
proto.relationships.RelationshipPipelineCustomVariableResponse.prototype.setRelatedTo = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.relationships.RelationshipPipelineStageCrudRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.relationships.RelationshipPipelineStageCrudRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.relationships.RelationshipPipelineStageCrudRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.relationships.RelationshipPipelineStageCrudRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pipelineId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    order: jspb.Message.getFieldWithDefault(msg, 4, 0),
    trackTime: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    targetTimeInDays: jspb.Message.getFieldWithDefault(msg, 6, 0),
    changeWebsiteStatusEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    websiteStatusDestination: jspb.Message.getFieldWithDefault(msg, 8, 0),
    changeAssigneeEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    assigneeUserIdDestination: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.relationships.RelationshipPipelineStageCrudRequest}
 */
proto.relationships.RelationshipPipelineStageCrudRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.relationships.RelationshipPipelineStageCrudRequest;
  return proto.relationships.RelationshipPipelineStageCrudRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.relationships.RelationshipPipelineStageCrudRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.relationships.RelationshipPipelineStageCrudRequest}
 */
proto.relationships.RelationshipPipelineStageCrudRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPipelineId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTrackTime(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTargetTimeInDays(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setChangeWebsiteStatusEnabled(value);
      break;
    case 8:
      var value = /** @type {!proto.common.PeopleRelationshipStatus} */ (reader.readEnum());
      msg.setWebsiteStatusDestination(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setChangeAssigneeEnabled(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAssigneeUserIdDestination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.relationships.RelationshipPipelineStageCrudRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.relationships.RelationshipPipelineStageCrudRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.relationships.RelationshipPipelineStageCrudRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.relationships.RelationshipPipelineStageCrudRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPipelineId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getTrackTime();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getTargetTimeInDays();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getChangeWebsiteStatusEnabled();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getWebsiteStatusDestination();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getChangeAssigneeEnabled();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getAssigneeUserIdDestination();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.relationships.RelationshipPipelineStageCrudRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.relationships.RelationshipPipelineStageCrudRequest} returns this
 */
proto.relationships.RelationshipPipelineStageCrudRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 pipeline_id = 2;
 * @return {number}
 */
proto.relationships.RelationshipPipelineStageCrudRequest.prototype.getPipelineId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.relationships.RelationshipPipelineStageCrudRequest} returns this
 */
proto.relationships.RelationshipPipelineStageCrudRequest.prototype.setPipelineId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.relationships.RelationshipPipelineStageCrudRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.relationships.RelationshipPipelineStageCrudRequest} returns this
 */
proto.relationships.RelationshipPipelineStageCrudRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 order = 4;
 * @return {number}
 */
proto.relationships.RelationshipPipelineStageCrudRequest.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.relationships.RelationshipPipelineStageCrudRequest} returns this
 */
proto.relationships.RelationshipPipelineStageCrudRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool track_time = 5;
 * @return {boolean}
 */
proto.relationships.RelationshipPipelineStageCrudRequest.prototype.getTrackTime = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.relationships.RelationshipPipelineStageCrudRequest} returns this
 */
proto.relationships.RelationshipPipelineStageCrudRequest.prototype.setTrackTime = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional int32 target_time_in_days = 6;
 * @return {number}
 */
proto.relationships.RelationshipPipelineStageCrudRequest.prototype.getTargetTimeInDays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.relationships.RelationshipPipelineStageCrudRequest} returns this
 */
proto.relationships.RelationshipPipelineStageCrudRequest.prototype.setTargetTimeInDays = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool change_website_status_enabled = 7;
 * @return {boolean}
 */
proto.relationships.RelationshipPipelineStageCrudRequest.prototype.getChangeWebsiteStatusEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.relationships.RelationshipPipelineStageCrudRequest} returns this
 */
proto.relationships.RelationshipPipelineStageCrudRequest.prototype.setChangeWebsiteStatusEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional common.PeopleRelationshipStatus website_status_destination = 8;
 * @return {!proto.common.PeopleRelationshipStatus}
 */
proto.relationships.RelationshipPipelineStageCrudRequest.prototype.getWebsiteStatusDestination = function() {
  return /** @type {!proto.common.PeopleRelationshipStatus} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.common.PeopleRelationshipStatus} value
 * @return {!proto.relationships.RelationshipPipelineStageCrudRequest} returns this
 */
proto.relationships.RelationshipPipelineStageCrudRequest.prototype.setWebsiteStatusDestination = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional bool change_assignee_enabled = 9;
 * @return {boolean}
 */
proto.relationships.RelationshipPipelineStageCrudRequest.prototype.getChangeAssigneeEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.relationships.RelationshipPipelineStageCrudRequest} returns this
 */
proto.relationships.RelationshipPipelineStageCrudRequest.prototype.setChangeAssigneeEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional int64 assignee_user_id_destination = 10;
 * @return {number}
 */
proto.relationships.RelationshipPipelineStageCrudRequest.prototype.getAssigneeUserIdDestination = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.relationships.RelationshipPipelineStageCrudRequest} returns this
 */
proto.relationships.RelationshipPipelineStageCrudRequest.prototype.setAssigneeUserIdDestination = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.relationships.RelationshipPipelineStageResponse.repeatedFields_ = [11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.relationships.RelationshipPipelineStageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.relationships.RelationshipPipelineStageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.relationships.RelationshipPipelineStageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.relationships.RelationshipPipelineStageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    order: jspb.Message.getFieldWithDefault(msg, 3, 0),
    opportunitiesNumber: jspb.Message.getFieldWithDefault(msg, 4, 0),
    trackTime: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    targetTimeInDays: jspb.Message.getFieldWithDefault(msg, 6, 0),
    changeWebsiteStatusEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    websiteStatusDestination: jspb.Message.getFieldWithDefault(msg, 8, 0),
    changeAssigneeEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    assigneeUserIdDestination: jspb.Message.getFieldWithDefault(msg, 10, 0),
    opportunitiesList: jspb.Message.toObjectList(msg.getOpportunitiesList(),
    proto.relationships.RelationshipPipelineStageOpportunityResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.relationships.RelationshipPipelineStageResponse}
 */
proto.relationships.RelationshipPipelineStageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.relationships.RelationshipPipelineStageResponse;
  return proto.relationships.RelationshipPipelineStageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.relationships.RelationshipPipelineStageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.relationships.RelationshipPipelineStageResponse}
 */
proto.relationships.RelationshipPipelineStageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOpportunitiesNumber(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTrackTime(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTargetTimeInDays(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setChangeWebsiteStatusEnabled(value);
      break;
    case 8:
      var value = /** @type {!proto.common.PeopleRelationshipStatus} */ (reader.readEnum());
      msg.setWebsiteStatusDestination(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setChangeAssigneeEnabled(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAssigneeUserIdDestination(value);
      break;
    case 11:
      var value = new proto.relationships.RelationshipPipelineStageOpportunityResponse;
      reader.readMessage(value,proto.relationships.RelationshipPipelineStageOpportunityResponse.deserializeBinaryFromReader);
      msg.addOpportunities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.relationships.RelationshipPipelineStageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.relationships.RelationshipPipelineStageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.relationships.RelationshipPipelineStageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.relationships.RelationshipPipelineStageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getOpportunitiesNumber();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getTrackTime();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getTargetTimeInDays();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getChangeWebsiteStatusEnabled();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getWebsiteStatusDestination();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getChangeAssigneeEnabled();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getAssigneeUserIdDestination();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getOpportunitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.relationships.RelationshipPipelineStageOpportunityResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.relationships.RelationshipPipelineStageResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.relationships.RelationshipPipelineStageResponse} returns this
 */
proto.relationships.RelationshipPipelineStageResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.relationships.RelationshipPipelineStageResponse.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.relationships.RelationshipPipelineStageResponse} returns this
 */
proto.relationships.RelationshipPipelineStageResponse.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 order = 3;
 * @return {number}
 */
proto.relationships.RelationshipPipelineStageResponse.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.relationships.RelationshipPipelineStageResponse} returns this
 */
proto.relationships.RelationshipPipelineStageResponse.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 opportunities_number = 4;
 * @return {number}
 */
proto.relationships.RelationshipPipelineStageResponse.prototype.getOpportunitiesNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.relationships.RelationshipPipelineStageResponse} returns this
 */
proto.relationships.RelationshipPipelineStageResponse.prototype.setOpportunitiesNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool track_time = 5;
 * @return {boolean}
 */
proto.relationships.RelationshipPipelineStageResponse.prototype.getTrackTime = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.relationships.RelationshipPipelineStageResponse} returns this
 */
proto.relationships.RelationshipPipelineStageResponse.prototype.setTrackTime = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional int32 target_time_in_days = 6;
 * @return {number}
 */
proto.relationships.RelationshipPipelineStageResponse.prototype.getTargetTimeInDays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.relationships.RelationshipPipelineStageResponse} returns this
 */
proto.relationships.RelationshipPipelineStageResponse.prototype.setTargetTimeInDays = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool change_website_status_enabled = 7;
 * @return {boolean}
 */
proto.relationships.RelationshipPipelineStageResponse.prototype.getChangeWebsiteStatusEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.relationships.RelationshipPipelineStageResponse} returns this
 */
proto.relationships.RelationshipPipelineStageResponse.prototype.setChangeWebsiteStatusEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional common.PeopleRelationshipStatus website_status_destination = 8;
 * @return {!proto.common.PeopleRelationshipStatus}
 */
proto.relationships.RelationshipPipelineStageResponse.prototype.getWebsiteStatusDestination = function() {
  return /** @type {!proto.common.PeopleRelationshipStatus} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.common.PeopleRelationshipStatus} value
 * @return {!proto.relationships.RelationshipPipelineStageResponse} returns this
 */
proto.relationships.RelationshipPipelineStageResponse.prototype.setWebsiteStatusDestination = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional bool change_assignee_enabled = 9;
 * @return {boolean}
 */
proto.relationships.RelationshipPipelineStageResponse.prototype.getChangeAssigneeEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.relationships.RelationshipPipelineStageResponse} returns this
 */
proto.relationships.RelationshipPipelineStageResponse.prototype.setChangeAssigneeEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional int64 assignee_user_id_destination = 10;
 * @return {number}
 */
proto.relationships.RelationshipPipelineStageResponse.prototype.getAssigneeUserIdDestination = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.relationships.RelationshipPipelineStageResponse} returns this
 */
proto.relationships.RelationshipPipelineStageResponse.prototype.setAssigneeUserIdDestination = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * repeated RelationshipPipelineStageOpportunityResponse opportunities = 11;
 * @return {!Array<!proto.relationships.RelationshipPipelineStageOpportunityResponse>}
 */
proto.relationships.RelationshipPipelineStageResponse.prototype.getOpportunitiesList = function() {
  return /** @type{!Array<!proto.relationships.RelationshipPipelineStageOpportunityResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.relationships.RelationshipPipelineStageOpportunityResponse, 11));
};


/**
 * @param {!Array<!proto.relationships.RelationshipPipelineStageOpportunityResponse>} value
 * @return {!proto.relationships.RelationshipPipelineStageResponse} returns this
*/
proto.relationships.RelationshipPipelineStageResponse.prototype.setOpportunitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.relationships.RelationshipPipelineStageOpportunityResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.relationships.RelationshipPipelineStageOpportunityResponse}
 */
proto.relationships.RelationshipPipelineStageResponse.prototype.addOpportunities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.relationships.RelationshipPipelineStageOpportunityResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.relationships.RelationshipPipelineStageResponse} returns this
 */
proto.relationships.RelationshipPipelineStageResponse.prototype.clearOpportunitiesList = function() {
  return this.setOpportunitiesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.relationships.RelationshipPipelineStageOpportunitySuggestionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.relationships.RelationshipPipelineStageOpportunitySuggestionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.relationships.RelationshipPipelineStageOpportunitySuggestionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.relationships.RelationshipPipelineStageOpportunitySuggestionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    query: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.relationships.RelationshipPipelineStageOpportunitySuggestionRequest}
 */
proto.relationships.RelationshipPipelineStageOpportunitySuggestionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.relationships.RelationshipPipelineStageOpportunitySuggestionRequest;
  return proto.relationships.RelationshipPipelineStageOpportunitySuggestionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.relationships.RelationshipPipelineStageOpportunitySuggestionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.relationships.RelationshipPipelineStageOpportunitySuggestionRequest}
 */
proto.relationships.RelationshipPipelineStageOpportunitySuggestionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.relationships.RelationshipPipelineStageOpportunitySuggestionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.relationships.RelationshipPipelineStageOpportunitySuggestionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.relationships.RelationshipPipelineStageOpportunitySuggestionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.relationships.RelationshipPipelineStageOpportunitySuggestionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 workspace_id = 1;
 * @return {number}
 */
proto.relationships.RelationshipPipelineStageOpportunitySuggestionRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.relationships.RelationshipPipelineStageOpportunitySuggestionRequest} returns this
 */
proto.relationships.RelationshipPipelineStageOpportunitySuggestionRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string query = 2;
 * @return {string}
 */
proto.relationships.RelationshipPipelineStageOpportunitySuggestionRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.relationships.RelationshipPipelineStageOpportunitySuggestionRequest} returns this
 */
proto.relationships.RelationshipPipelineStageOpportunitySuggestionRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.relationships.RelationshipPipelineStageOpportunitySuggestionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.relationships.RelationshipPipelineStageOpportunitySuggestionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.relationships.RelationshipPipelineStageOpportunitySuggestionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.relationships.RelationshipPipelineStageOpportunitySuggestionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    domain: jspb.Message.getFieldWithDefault(msg, 3, ""),
    logo: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.relationships.RelationshipPipelineStageOpportunitySuggestionResponse}
 */
proto.relationships.RelationshipPipelineStageOpportunitySuggestionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.relationships.RelationshipPipelineStageOpportunitySuggestionResponse;
  return proto.relationships.RelationshipPipelineStageOpportunitySuggestionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.relationships.RelationshipPipelineStageOpportunitySuggestionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.relationships.RelationshipPipelineStageOpportunitySuggestionResponse}
 */
proto.relationships.RelationshipPipelineStageOpportunitySuggestionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomain(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.relationships.RelationshipPipelineStageOpportunitySuggestionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.relationships.RelationshipPipelineStageOpportunitySuggestionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.relationships.RelationshipPipelineStageOpportunitySuggestionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.relationships.RelationshipPipelineStageOpportunitySuggestionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDomain();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLogo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.relationships.RelationshipPipelineStageOpportunitySuggestionResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.relationships.RelationshipPipelineStageOpportunitySuggestionResponse} returns this
 */
proto.relationships.RelationshipPipelineStageOpportunitySuggestionResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.relationships.RelationshipPipelineStageOpportunitySuggestionResponse.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.relationships.RelationshipPipelineStageOpportunitySuggestionResponse} returns this
 */
proto.relationships.RelationshipPipelineStageOpportunitySuggestionResponse.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string domain = 3;
 * @return {string}
 */
proto.relationships.RelationshipPipelineStageOpportunitySuggestionResponse.prototype.getDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.relationships.RelationshipPipelineStageOpportunitySuggestionResponse} returns this
 */
proto.relationships.RelationshipPipelineStageOpportunitySuggestionResponse.prototype.setDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string logo = 4;
 * @return {string}
 */
proto.relationships.RelationshipPipelineStageOpportunitySuggestionResponse.prototype.getLogo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.relationships.RelationshipPipelineStageOpportunitySuggestionResponse} returns this
 */
proto.relationships.RelationshipPipelineStageOpportunitySuggestionResponse.prototype.setLogo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.relationships.RelationshipPipelineStageOpportunityCrudRequest.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.relationships.RelationshipPipelineStageOpportunityCrudRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.relationships.RelationshipPipelineStageOpportunityCrudRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.relationships.RelationshipPipelineStageOpportunityCrudRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.relationships.RelationshipPipelineStageOpportunityCrudRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    stageId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    relatedTo: jspb.Message.getFieldWithDefault(msg, 2, 0),
    relatedId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    relatedName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    relatedDomain: jspb.Message.getFieldWithDefault(msg, 5, ""),
    relatedLogo: jspb.Message.getFieldWithDefault(msg, 6, ""),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    assignToUserId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    variableValuesList: jspb.Message.toObjectList(msg.getVariableValuesList(),
    common_pb.CustomVariableValue.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.relationships.RelationshipPipelineStageOpportunityCrudRequest}
 */
proto.relationships.RelationshipPipelineStageOpportunityCrudRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.relationships.RelationshipPipelineStageOpportunityCrudRequest;
  return proto.relationships.RelationshipPipelineStageOpportunityCrudRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.relationships.RelationshipPipelineStageOpportunityCrudRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.relationships.RelationshipPipelineStageOpportunityCrudRequest}
 */
proto.relationships.RelationshipPipelineStageOpportunityCrudRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStageId(value);
      break;
    case 2:
      var value = /** @type {!proto.common.RelationshipPipelineStageOpportunityRelatedTo} */ (reader.readEnum());
      msg.setRelatedTo(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRelatedId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRelatedName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRelatedDomain(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRelatedLogo(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAssignToUserId(value);
      break;
    case 9:
      var value = new common_pb.CustomVariableValue;
      reader.readMessage(value,common_pb.CustomVariableValue.deserializeBinaryFromReader);
      msg.addVariableValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.relationships.RelationshipPipelineStageOpportunityCrudRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.relationships.RelationshipPipelineStageOpportunityCrudRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.relationships.RelationshipPipelineStageOpportunityCrudRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.relationships.RelationshipPipelineStageOpportunityCrudRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStageId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getRelatedTo();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getRelatedId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getRelatedName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRelatedDomain();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRelatedLogo();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getAssignToUserId();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getVariableValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      common_pb.CustomVariableValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 stage_id = 1;
 * @return {number}
 */
proto.relationships.RelationshipPipelineStageOpportunityCrudRequest.prototype.getStageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.relationships.RelationshipPipelineStageOpportunityCrudRequest} returns this
 */
proto.relationships.RelationshipPipelineStageOpportunityCrudRequest.prototype.setStageId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional common.RelationshipPipelineStageOpportunityRelatedTo related_to = 2;
 * @return {!proto.common.RelationshipPipelineStageOpportunityRelatedTo}
 */
proto.relationships.RelationshipPipelineStageOpportunityCrudRequest.prototype.getRelatedTo = function() {
  return /** @type {!proto.common.RelationshipPipelineStageOpportunityRelatedTo} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.RelationshipPipelineStageOpportunityRelatedTo} value
 * @return {!proto.relationships.RelationshipPipelineStageOpportunityCrudRequest} returns this
 */
proto.relationships.RelationshipPipelineStageOpportunityCrudRequest.prototype.setRelatedTo = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int64 related_id = 3;
 * @return {number}
 */
proto.relationships.RelationshipPipelineStageOpportunityCrudRequest.prototype.getRelatedId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.relationships.RelationshipPipelineStageOpportunityCrudRequest} returns this
 */
proto.relationships.RelationshipPipelineStageOpportunityCrudRequest.prototype.setRelatedId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string related_name = 4;
 * @return {string}
 */
proto.relationships.RelationshipPipelineStageOpportunityCrudRequest.prototype.getRelatedName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.relationships.RelationshipPipelineStageOpportunityCrudRequest} returns this
 */
proto.relationships.RelationshipPipelineStageOpportunityCrudRequest.prototype.setRelatedName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string related_domain = 5;
 * @return {string}
 */
proto.relationships.RelationshipPipelineStageOpportunityCrudRequest.prototype.getRelatedDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.relationships.RelationshipPipelineStageOpportunityCrudRequest} returns this
 */
proto.relationships.RelationshipPipelineStageOpportunityCrudRequest.prototype.setRelatedDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string related_logo = 6;
 * @return {string}
 */
proto.relationships.RelationshipPipelineStageOpportunityCrudRequest.prototype.getRelatedLogo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.relationships.RelationshipPipelineStageOpportunityCrudRequest} returns this
 */
proto.relationships.RelationshipPipelineStageOpportunityCrudRequest.prototype.setRelatedLogo = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 workspace_id = 7;
 * @return {number}
 */
proto.relationships.RelationshipPipelineStageOpportunityCrudRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.relationships.RelationshipPipelineStageOpportunityCrudRequest} returns this
 */
proto.relationships.RelationshipPipelineStageOpportunityCrudRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 assign_to_user_id = 8;
 * @return {number}
 */
proto.relationships.RelationshipPipelineStageOpportunityCrudRequest.prototype.getAssignToUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.relationships.RelationshipPipelineStageOpportunityCrudRequest} returns this
 */
proto.relationships.RelationshipPipelineStageOpportunityCrudRequest.prototype.setAssignToUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * repeated common.CustomVariableValue variable_values = 9;
 * @return {!Array<!proto.common.CustomVariableValue>}
 */
proto.relationships.RelationshipPipelineStageOpportunityCrudRequest.prototype.getVariableValuesList = function() {
  return /** @type{!Array<!proto.common.CustomVariableValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.CustomVariableValue, 9));
};


/**
 * @param {!Array<!proto.common.CustomVariableValue>} value
 * @return {!proto.relationships.RelationshipPipelineStageOpportunityCrudRequest} returns this
*/
proto.relationships.RelationshipPipelineStageOpportunityCrudRequest.prototype.setVariableValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.common.CustomVariableValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.CustomVariableValue}
 */
proto.relationships.RelationshipPipelineStageOpportunityCrudRequest.prototype.addVariableValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.common.CustomVariableValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.relationships.RelationshipPipelineStageOpportunityCrudRequest} returns this
 */
proto.relationships.RelationshipPipelineStageOpportunityCrudRequest.prototype.clearVariableValuesList = function() {
  return this.setVariableValuesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.relationships.RelationshipPipelineStageOpportunityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.relationships.RelationshipPipelineStageOpportunityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.relationships.RelationshipPipelineStageOpportunityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.relationships.RelationshipPipelineStageOpportunityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    stageId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    order: jspb.Message.getFieldWithDefault(msg, 3, 0),
    relatedTo: jspb.Message.getFieldWithDefault(msg, 4, 0),
    relatedId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    relatedName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    relatedDomain: jspb.Message.getFieldWithDefault(msg, 7, ""),
    relatedLogo: jspb.Message.getFieldWithDefault(msg, 8, ""),
    createdByUserId: jspb.Message.getFieldWithDefault(msg, 9, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 10, 0),
    targetTimeAt: jspb.Message.getFieldWithDefault(msg, 11, 0),
    assignedToUserId: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.relationships.RelationshipPipelineStageOpportunityResponse}
 */
proto.relationships.RelationshipPipelineStageOpportunityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.relationships.RelationshipPipelineStageOpportunityResponse;
  return proto.relationships.RelationshipPipelineStageOpportunityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.relationships.RelationshipPipelineStageOpportunityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.relationships.RelationshipPipelineStageOpportunityResponse}
 */
proto.relationships.RelationshipPipelineStageOpportunityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStageId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 4:
      var value = /** @type {!proto.common.RelationshipPipelineStageOpportunityRelatedTo} */ (reader.readEnum());
      msg.setRelatedTo(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRelatedId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRelatedName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRelatedDomain(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setRelatedLogo(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedByUserId(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTargetTimeAt(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAssignedToUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.relationships.RelationshipPipelineStageOpportunityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.relationships.RelationshipPipelineStageOpportunityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.relationships.RelationshipPipelineStageOpportunityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.relationships.RelationshipPipelineStageOpportunityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getStageId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getRelatedTo();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getRelatedId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getRelatedName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRelatedDomain();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getRelatedLogo();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCreatedByUserId();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getTargetTimeAt();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getAssignedToUserId();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.relationships.RelationshipPipelineStageOpportunityResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.relationships.RelationshipPipelineStageOpportunityResponse} returns this
 */
proto.relationships.RelationshipPipelineStageOpportunityResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 stage_id = 2;
 * @return {number}
 */
proto.relationships.RelationshipPipelineStageOpportunityResponse.prototype.getStageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.relationships.RelationshipPipelineStageOpportunityResponse} returns this
 */
proto.relationships.RelationshipPipelineStageOpportunityResponse.prototype.setStageId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 order = 3;
 * @return {number}
 */
proto.relationships.RelationshipPipelineStageOpportunityResponse.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.relationships.RelationshipPipelineStageOpportunityResponse} returns this
 */
proto.relationships.RelationshipPipelineStageOpportunityResponse.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional common.RelationshipPipelineStageOpportunityRelatedTo related_to = 4;
 * @return {!proto.common.RelationshipPipelineStageOpportunityRelatedTo}
 */
proto.relationships.RelationshipPipelineStageOpportunityResponse.prototype.getRelatedTo = function() {
  return /** @type {!proto.common.RelationshipPipelineStageOpportunityRelatedTo} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.common.RelationshipPipelineStageOpportunityRelatedTo} value
 * @return {!proto.relationships.RelationshipPipelineStageOpportunityResponse} returns this
 */
proto.relationships.RelationshipPipelineStageOpportunityResponse.prototype.setRelatedTo = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int64 related_id = 5;
 * @return {number}
 */
proto.relationships.RelationshipPipelineStageOpportunityResponse.prototype.getRelatedId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.relationships.RelationshipPipelineStageOpportunityResponse} returns this
 */
proto.relationships.RelationshipPipelineStageOpportunityResponse.prototype.setRelatedId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string related_name = 6;
 * @return {string}
 */
proto.relationships.RelationshipPipelineStageOpportunityResponse.prototype.getRelatedName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.relationships.RelationshipPipelineStageOpportunityResponse} returns this
 */
proto.relationships.RelationshipPipelineStageOpportunityResponse.prototype.setRelatedName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string related_domain = 7;
 * @return {string}
 */
proto.relationships.RelationshipPipelineStageOpportunityResponse.prototype.getRelatedDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.relationships.RelationshipPipelineStageOpportunityResponse} returns this
 */
proto.relationships.RelationshipPipelineStageOpportunityResponse.prototype.setRelatedDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string related_logo = 8;
 * @return {string}
 */
proto.relationships.RelationshipPipelineStageOpportunityResponse.prototype.getRelatedLogo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.relationships.RelationshipPipelineStageOpportunityResponse} returns this
 */
proto.relationships.RelationshipPipelineStageOpportunityResponse.prototype.setRelatedLogo = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int64 created_by_user_id = 9;
 * @return {number}
 */
proto.relationships.RelationshipPipelineStageOpportunityResponse.prototype.getCreatedByUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.relationships.RelationshipPipelineStageOpportunityResponse} returns this
 */
proto.relationships.RelationshipPipelineStageOpportunityResponse.prototype.setCreatedByUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 created_at = 10;
 * @return {number}
 */
proto.relationships.RelationshipPipelineStageOpportunityResponse.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.relationships.RelationshipPipelineStageOpportunityResponse} returns this
 */
proto.relationships.RelationshipPipelineStageOpportunityResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 target_time_at = 11;
 * @return {number}
 */
proto.relationships.RelationshipPipelineStageOpportunityResponse.prototype.getTargetTimeAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.relationships.RelationshipPipelineStageOpportunityResponse} returns this
 */
proto.relationships.RelationshipPipelineStageOpportunityResponse.prototype.setTargetTimeAt = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 assigned_to_user_id = 12;
 * @return {number}
 */
proto.relationships.RelationshipPipelineStageOpportunityResponse.prototype.getAssignedToUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.relationships.RelationshipPipelineStageOpportunityResponse} returns this
 */
proto.relationships.RelationshipPipelineStageOpportunityResponse.prototype.setAssignedToUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.relationships.RelationshipPipelineMoveRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.relationships.RelationshipPipelineMoveRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.relationships.RelationshipPipelineMoveRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.relationships.RelationshipPipelineMoveRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pipelineId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    stageId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    opportunityId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    headRelatedId: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.relationships.RelationshipPipelineMoveRequest}
 */
proto.relationships.RelationshipPipelineMoveRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.relationships.RelationshipPipelineMoveRequest;
  return proto.relationships.RelationshipPipelineMoveRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.relationships.RelationshipPipelineMoveRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.relationships.RelationshipPipelineMoveRequest}
 */
proto.relationships.RelationshipPipelineMoveRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPipelineId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStageId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOpportunityId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHeadRelatedId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.relationships.RelationshipPipelineMoveRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.relationships.RelationshipPipelineMoveRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.relationships.RelationshipPipelineMoveRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.relationships.RelationshipPipelineMoveRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPipelineId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getStageId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getOpportunityId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getHeadRelatedId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional int64 workspace_id = 1;
 * @return {number}
 */
proto.relationships.RelationshipPipelineMoveRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.relationships.RelationshipPipelineMoveRequest} returns this
 */
proto.relationships.RelationshipPipelineMoveRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 pipeline_id = 2;
 * @return {number}
 */
proto.relationships.RelationshipPipelineMoveRequest.prototype.getPipelineId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.relationships.RelationshipPipelineMoveRequest} returns this
 */
proto.relationships.RelationshipPipelineMoveRequest.prototype.setPipelineId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 stage_id = 3;
 * @return {number}
 */
proto.relationships.RelationshipPipelineMoveRequest.prototype.getStageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.relationships.RelationshipPipelineMoveRequest} returns this
 */
proto.relationships.RelationshipPipelineMoveRequest.prototype.setStageId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 opportunity_id = 4;
 * @return {number}
 */
proto.relationships.RelationshipPipelineMoveRequest.prototype.getOpportunityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.relationships.RelationshipPipelineMoveRequest} returns this
 */
proto.relationships.RelationshipPipelineMoveRequest.prototype.setOpportunityId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 head_related_id = 5;
 * @return {number}
 */
proto.relationships.RelationshipPipelineMoveRequest.prototype.getHeadRelatedId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.relationships.RelationshipPipelineMoveRequest} returns this
 */
proto.relationships.RelationshipPipelineMoveRequest.prototype.setHeadRelatedId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.relationships.RelationshipPipelineStageOpportunityAssignRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.relationships.RelationshipPipelineStageOpportunityAssignRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.relationships.RelationshipPipelineStageOpportunityAssignRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.relationships.RelationshipPipelineStageOpportunityAssignRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    opportunityId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    toUserId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.relationships.RelationshipPipelineStageOpportunityAssignRequest}
 */
proto.relationships.RelationshipPipelineStageOpportunityAssignRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.relationships.RelationshipPipelineStageOpportunityAssignRequest;
  return proto.relationships.RelationshipPipelineStageOpportunityAssignRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.relationships.RelationshipPipelineStageOpportunityAssignRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.relationships.RelationshipPipelineStageOpportunityAssignRequest}
 */
proto.relationships.RelationshipPipelineStageOpportunityAssignRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOpportunityId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setToUserId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.relationships.RelationshipPipelineStageOpportunityAssignRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.relationships.RelationshipPipelineStageOpportunityAssignRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.relationships.RelationshipPipelineStageOpportunityAssignRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.relationships.RelationshipPipelineStageOpportunityAssignRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOpportunityId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getToUserId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 opportunity_id = 1;
 * @return {number}
 */
proto.relationships.RelationshipPipelineStageOpportunityAssignRequest.prototype.getOpportunityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.relationships.RelationshipPipelineStageOpportunityAssignRequest} returns this
 */
proto.relationships.RelationshipPipelineStageOpportunityAssignRequest.prototype.setOpportunityId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 to_user_id = 2;
 * @return {number}
 */
proto.relationships.RelationshipPipelineStageOpportunityAssignRequest.prototype.getToUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.relationships.RelationshipPipelineStageOpportunityAssignRequest} returns this
 */
proto.relationships.RelationshipPipelineStageOpportunityAssignRequest.prototype.setToUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 workspace_id = 3;
 * @return {number}
 */
proto.relationships.RelationshipPipelineStageOpportunityAssignRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.relationships.RelationshipPipelineStageOpportunityAssignRequest} returns this
 */
proto.relationships.RelationshipPipelineStageOpportunityAssignRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.relationships.StageOpportunityFilterField = {
  DROPDOWN_OPPORTUNITY_OWNER_ID: 0,
  DROPDOWN_OPPORTUNITY_ASSIGNED_TO_USER_ID: 1,
  DATE_OPPORTUNITY_CREATED_AT: 2,
  RELATIONSHIP_WEBSITE_NAME: 3,
  RELATIONSHIP_WEBSITE_DOMAIN: 4
};

goog.object.extend(exports, proto.relationships);
