import { updateItemInListByIdHelper } from '@helpers/commonHelpers';
import loadingStatuses from '@constants/loadingStatuses';
import { ScheduleType } from '@ts/schedules.types';
import {
  SET_SCHEDULES,
  SET_SCHEDULES_LS,
  UPDATE_SCHEDULES,
  ADD_SCHEDULES,
  REMOVE_SCHEDULES,
} from '../actions/schedules.actions';

export type ScheduleStateType = {
  items: ScheduleType[];
  loadingStatus: string;
};

const initialState: ScheduleStateType = {
  items: [],
  loadingStatus: loadingStatuses.PENDING,
};

export default (state = initialState, { type, payload }): ScheduleStateType => {
  switch (type) {
    case SET_SCHEDULES:
      return {
        ...state,
        items: payload,
      };
    case SET_SCHEDULES_LS:
      return {
        ...state,
        loadingStatus: payload,
      };
    case UPDATE_SCHEDULES:
      return {
        ...state,
        items: updateItemInListByIdHelper(state.items, payload),
      };
    case ADD_SCHEDULES:
      return {
        ...state,
        items: [...state.items, payload],
      };
    case REMOVE_SCHEDULES:
      return {
        ...state,
        items: state.items.filter(({ id }) => id !== payload),
      };
    default:
      return state;
  }
};
