import { Range } from 'respona_api/generated/common_pb';

export const createRange = (min: number, max: number): Range => {
  const range = new Range();

  range.setMin(min);
  range.setMax(max);

  return range;
};
