import { BoundaryType, ParsedValueType, ParseInputsType } from '@ts/moderateChance.types';

const boundaries: BoundaryType[] = [
  {
    min: 0,
    max: 2,
    color: 'green',
  },
  {
    min: 3,
    max: 3,
    color: 'yellow',
  },
  {
    min: 4,
    color: 'red',
  },
];

export default {
  key: 'linksCount',
  label: 'Links',
  min: boundaries[0].min,
  max: boundaries[boundaries.length - 1].min,
  greenZone: {
    min: 0,
    max: 2,
  },
  parse: ({ template }: ParseInputsType): ParsedValueType => {
    const divEl = document.createElement('div');
    divEl.innerHTML = template;
    const numLinks = Array.from(divEl.querySelectorAll('a')).filter(
      (domEl) => domEl.innerText
    ).length;

    return {
      value: numLinks,
      boundary: boundaries.find(({ min, max }) => {
        if (!max && numLinks >= max) {
          return true;
        }

        if (min <= numLinks && numLinks <= max) {
          return true;
        }

        return false;
      }),
    };
  },
};
