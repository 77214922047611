import {
  SET_CONTACT_AUTOMATION_DATA,
  SET_CONTACT_AUTOMATION_LS,
  SET_CURRENT_CONTACT_AUTOMATION_TYPE,
  UPDATE_CURRENT_CONTACT_AUTOMATION_DATA,
} from '@redux/actions/automationContact.actions';

import {
  ContactAutomationPresetType,
  ContactAutomationSearchModes,
} from 'src/types/automationContacts.types';

import loadingStatuses from 'src/constants/loadingStatuses';
import { LSType } from '@ts/common.types';
import { ContactAutomationSearchingMode } from 'respona_api/generated/automation-contact_pb';
import { ContactSearchSource } from 'respona_api/generated/common_pb';

export type CampaignAutomationStateType = {
  loadingStatus: string;
  isRunning: boolean;
  isRunningLs: LSType;
  data: ContactAutomationPresetType[];
  currentMode: ContactAutomationSearchModes;
};

export const initialState: CampaignAutomationStateType = {
  loadingStatus: loadingStatuses.PENDING,
  isRunning: false,
  isRunningLs: loadingStatuses.PENDING,
  data: [
    {
      pipelineId: -1,
      searchMode: ContactAutomationSearchingMode.ARTICLE_OR_DOMAIN,
      positionSuggestions: [],
      positionsList: [],
      senioritySuggestions: [],
      senioritiesList: [],
      assignNumber: 1,
      assignGenericEmails: true,
      skipCatchAllEmails: true,
      isDefault: true,
      requiredPositions: false,
      source: ContactSearchSource.RESPONA_SEARCH_SOURCE,
    } as ContactAutomationPresetType,
  ],
  currentMode: ContactAutomationSearchModes.BLOG_POST,
};

export default (state: CampaignAutomationStateType = initialState, { type, payload }) => {
  switch (type) {
    case SET_CONTACT_AUTOMATION_DATA:
      return {
        ...state,
        data: payload,
      };
    case UPDATE_CURRENT_CONTACT_AUTOMATION_DATA:
      return {
        ...state,
        data: state.data.map((it) => {
          if (it.searchMode === state.currentMode) {
            return { ...it, ...payload };
          }

          return it;
        }),
      };
    case SET_CURRENT_CONTACT_AUTOMATION_TYPE:
      return {
        ...state,
        currentMode: payload,
      };
    case SET_CONTACT_AUTOMATION_LS:
      return {
        ...state,
        loadingStatus: payload,
      };
    default:
      return state;
  }
};
