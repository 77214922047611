import React, { Dispatch, SetStateAction } from 'react';

import BaseModal from '@uikit/BaseModal/BaseModal';

import ContactAutomationSetup from '@components/CampaignSettings/ContactAutomationSetup/ContactAutomationSetup';

import './ContactPipelineSetupModal.scss';
import { SVGIcon } from '@uikit/Icon/Icon';

const ContactPipelineSetupModal = ({
  pipelineId,
  isOpenModal,
  setOpenModal,
  setPaymentSidebarOpen,
  setUpsellCreditsSidebarOpened,
  setUpsellCreditsNotEnough,
}: {
  pipelineId: number;
  isOpenModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  setPaymentSidebarOpen: (boolean) => void;
  setUpsellCreditsSidebarOpened: (boolean) => void;
  setUpsellCreditsNotEnough: (number) => void;
}): JSX.Element => {
  return (
    <BaseModal
      classNames={{ modal: 'confirm-contact-campaign-modal' }}
      isOpen={isOpenModal}
      onClose={() => setOpenModal(false)}
      closeOnBackgroundClick={false}
    >
      <div className="confirm-contact-campaign-builder">
        <div className="confirm-contact-campaign-builder__header">
          <div className="confirm-contact-campaign-builder__header-title">Find Contact info</div>
          <div className="confirm-contact-campaign-builder__header-info">
            <SVGIcon size={10} icon="wallet" />
            <span>5 credits per contact</span>
          </div>
        </div>
        <div className="confirm-contact-campaign-builder__content">
          <ContactAutomationSetup
            pipelineId={pipelineId}
            setOpenModal={setOpenModal}
            setUpsellCreditsSidebarOpened={setUpsellCreditsSidebarOpened}
            setUpsellCreditsNotEnough={setUpsellCreditsNotEnough}
            setPaymentSidebarOpen={setPaymentSidebarOpen}
          />
        </div>
      </div>
    </BaseModal>
  );
};

export default ContactPipelineSetupModal;
