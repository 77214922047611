import request from '@api/base/request';
import streamRequest from '@api/base/streamRequest';
import pipelineService from '@api/services/pipelineService';
import CommonModels from 'respona_api/generated/common_pb';
import { LaunchContactAutomationRequest } from 'respona_api/generated/automation-contact_pb';
import { LaunchContentAutomationRequest } from 'respona_api/generated/automation-content_pb';
import * as file_importer_pb from 'respona_api/generated/file-importer_pb';
import { LaunchPersonalizationAutomationRequest } from 'respona_api/generated/automation-personalization_pb';
import processStreamRequest from '@api/base/processStreamRequest';
import { CampaignPipelineResponseType } from '@ts/pipelilne.types';
import PipelineModels, { CampaignPipelineTypeMap } from 'respona_api/generated/pipeline_pb';

export const getCampaignPipelinesApi = (
  campaignId: number,
  workspaceId: number
): Promise<CampaignPipelineResponseType[]> => {
  const service = pipelineService.getInstance();
  const pipelineRequest = new CommonModels.IdRequest();

  pipelineRequest.setId(campaignId);
  pipelineRequest.setOptionalWorkspaceId(workspaceId);

  return streamRequest<CampaignPipelineResponseType[]>(
    service,
    service.getCampaignPipelines,
    pipelineRequest
  );
};

function toTimestamp(value: Date | number): number {
  if (value instanceof Date) {
    return value.getTime();
  }
  return value;
}

export const buildPipelineApiRequest = (
  pipelineId: number,
  workspaceId: number,
  campaignId: number,
  type: CampaignPipelineTypeMap[keyof CampaignPipelineTypeMap],
  recurringEnabled: boolean,
  recurringPeriodInHours: number,
  endAfterDateTime: Date | number,
  contactAutomationEnabled: boolean,
  personalizationAutomationEnabled: boolean,
  notify: boolean,
  contactAutomation?: LaunchContactAutomationRequest,
  contentAutomation?: LaunchContentAutomationRequest,
  importAutomation?: file_importer_pb.ImportRequest,
  personalizationAutomation?: LaunchPersonalizationAutomationRequest
): PipelineModels.LaunchCampaignPipelineRequest => {
  const pipelineRequest = new PipelineModels.LaunchCampaignPipelineRequest();

  pipelineRequest.setId(pipelineId);
  pipelineRequest.setWorkspaceId(workspaceId);
  pipelineRequest.setCampaignId(campaignId);
  pipelineRequest.setType(type);
  pipelineRequest.setRecurringEnabled(recurringEnabled);
  pipelineRequest.setRecurringPeriodInHours(recurringPeriodInHours);
  if (endAfterDateTime > 0) {
    pipelineRequest.setRecurringEndAt(toTimestamp(endAfterDateTime));
  } else {
    pipelineRequest.setRecurringEndAt(-1);
  }
  pipelineRequest.setContactAutomationEnabled(contactAutomationEnabled);
  pipelineRequest.setPersonalizationAutomationEnabled(personalizationAutomationEnabled);
  pipelineRequest.setNotify(notify);

  if (importAutomation != null) {
    pipelineRequest.setImport(importAutomation);
  }
  if (contactAutomation != null) {
    pipelineRequest.setContactAutomation(contactAutomation);
  }
  if (contentAutomation != null) {
    pipelineRequest.setContent(contentAutomation);
  }
  if (personalizationAutomation != null) {
    pipelineRequest.setPersonalizationAutomation(personalizationAutomation);
  }

  return pipelineRequest;
};

export const launchPipelineApi = (
  payload: PipelineModels.LaunchCampaignPipelineRequest
): Promise<CampaignPipelineResponseType> => {
  const service = pipelineService.getInstance();
  return request<CampaignPipelineResponseType>(service, service.launchCampaignPipeline, payload);
};

export const updatePipelineApi = (
  payload: PipelineModels.LaunchCampaignPipelineRequest
): Promise<CampaignPipelineResponseType> => {
  const service = pipelineService.getInstance();
  return request<CampaignPipelineResponseType>(service, service.updateCampaignPipeline, payload);
};

export const stopActiveCampaignPipelineApi = (
  pipelineId: number,
  campaignId: number,
  workspaceId: number
): Promise<CampaignPipelineResponseType> => {
  const service = pipelineService.getInstance();
  const pipelineRequest = new CommonModels.IdRequest();

  pipelineRequest.setId(pipelineId);
  pipelineRequest.setOptionalRelatedId(campaignId);
  pipelineRequest.setOptionalWorkspaceId(workspaceId);

  return request<CampaignPipelineResponseType>(
    service,
    service.cancelCampaignPipeline,
    pipelineRequest
  );
};

export const deleteCampaignPipelineApi = (
  pipelineId: number,
  campaignId: number,
  workspaceId: number
): Promise<void> => {
  const service = pipelineService.getInstance();
  const pipelineRequest = new CommonModels.IdRequest();

  pipelineRequest.setId(pipelineId);
  pipelineRequest.setOptionalRelatedId(campaignId);
  pipelineRequest.setOptionalWorkspaceId(workspaceId);

  return request<void>(service, service.deleteCampaignPipeline, pipelineRequest);
};

export const getCampaignPipelineProgressApi = (
  pipelineId: number,
  workspaceId: number,
  processCallback: (data: [CampaignPipelineResponseType, any]) => void,
  onEndCallback: (data: [CampaignPipelineResponseType, any]) => void
): Promise<CampaignPipelineResponseType> => {
  const service = pipelineService.getInstance();
  const progressRequest = new CommonModels.IdRequest();

  progressRequest.setId(pipelineId);
  progressRequest.setOptionalWorkspaceId(workspaceId);

  return processStreamRequest<CampaignPipelineResponseType>(
    service,
    service.getCampaignPipelineProgress,
    progressRequest,
    processCallback,
    onEndCallback
  );
};
