import BillingService from '../../../respona_api/generated/billing_pb_service';

const service = (function () {
  let instance = null;

  return {
    getInstance: (): BillingService.BillingApiServiceClient => {
      if (!instance) {
        // @ts-ignore
        instance = new BillingService.BillingApiServiceClient(API_HOST);
      }

      return instance;
    },
  };
})();

export default service;
