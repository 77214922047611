import React from 'react';

export default ({ color = '#C4C6CD' }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 3C6.15 3 3 6.15 3 10C3 12.975 4.86667 15.4833 7.43333 16.5333C7.375 16.0083 7.31667 15.1333 7.43333 14.55C7.55 14.025 8.25 11.05 8.25 11.05C8.25 11.05 8.075 10.5833 8.075 10C8.075 9.00833 8.65833 8.30833 9.35833 8.30833C9.94167 8.30833 10.2333 8.775 10.2333 9.3C10.2333 9.88333 9.825 10.8167 9.65 11.6333C9.475 12.3333 10 12.9167 10.7 12.9167C11.925 12.9167 12.9167 11.6333 12.9167 9.70833C12.9167 8.01667 11.6917 6.85 10 6.85C8.01667 6.85 6.85 8.36667 6.85 9.88333C6.85 10.4667 7.08333 11.1083 7.375 11.4583C7.43333 11.5167 7.43333 11.575 7.43333 11.6333C7.375 11.8667 7.25833 12.3333 7.25833 12.45C7.2 12.5667 7.14167 12.625 7.025 12.5667C6.15 12.1583 5.625 10.875 5.625 9.88333C5.625 7.66667 7.25833 5.625 10.2333 5.625C12.6833 5.625 14.55 7.375 14.55 9.65C14.55 12.0417 13.0333 14.025 10.9333 14.025C10.2333 14.025 9.53333 13.675 9.3 13.2083C9.3 13.2083 8.95 14.55 8.89167 14.9C8.71667 15.4833 8.30833 16.2417 8.01667 16.7083C8.6 16.8833 9.3 17 10 17C13.85 17 17 13.85 17 10C17 6.15 13.85 3 10 3Z"
      fill={color}
    />
  </svg>
);
