import React from 'react';

export default ({ color = '#251FB6', size = 19 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.107 14.6908V15.1629C16.107 15.6637 15.9081 16.144 15.5539 16.4981C15.1998 16.8523 14.7195 17.0512 14.2187 17.0512H2.88838C2.38755 17.0512 1.90723 16.8523 1.55309 16.4981C1.19895 16.144 1 15.6637 1 15.1629V2.88838C1 2.38755 1.19895 1.90723 1.55309 1.55309C1.90723 1.19895 2.38755 1 2.88838 1H10.914H14.2187"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.77539 13.2773H5.71958"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6146 5.35422C14.7987 5.17009 15.0173 5.02403 15.2579 4.92438C15.4985 4.82473 15.7563 4.77344 16.0167 4.77344C16.2771 4.77344 16.5349 4.82473 16.7755 4.92438C17.0161 5.02403 17.2347 5.17009 17.4188 5.35422C17.603 5.53834 17.749 5.75694 17.8487 5.99751C17.9483 6.23809 17.9996 6.49594 17.9996 6.75634C17.9996 7.01674 17.9483 7.27458 17.8487 7.51516C17.749 7.75574 17.603 7.97433 17.4188 8.15846L13.2266 12.3318L9.49707 13.276L10.4318 9.54642L14.6146 5.35422Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
