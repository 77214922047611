import mailboxService from '@api/services/mailboxService';
import streamRequest from '@api/base/streamRequest';
import request from '@api/base/request';

import {
  ThreadPreviewResponseType,
  QueuePreviewResponseType,
  ThreadMessageType,
  QueueMessageResponseType,
  BlackListResponseType,
  OutboxThreadMessageType,
  MailboxAiActionsType,
  MailAIAssistantResponseType,
  ThreadConversationMessageType,
  InboxUnreadCounterType,
  MailboxThreadDraftType,
  ThreadTagType,
} from '@ts/mailboxInbox.types';

import { FileAttachmentType, FileResponseType } from '@ts/common.types';
import MailboxModels, {
  ThreadPreviewResponse,
  ThreadStatusMap,
  MailAIActionMap,
  MailboxQueueCancelRequest,
  ExportBlackListPatternRequest,
  ThreadUidRequest,
  ThreadTag,
} from 'respona_api/generated/mailbox_pb';
import CommonModels from 'respona_api/generated/common_pb';
import { UnsubscribeFilterType } from '@ts/unsubscribes.types';
import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb';
import { MessageRequestTypeMap } from 'respona_api/generated/mailbox-sender_pb';

const createPageRequest = (campaignId, workspaceId, page, limit) => {
  const pageRequest = new CommonModels.PageRequest();

  pageRequest.setPage(page);
  pageRequest.setLimit(limit);
  pageRequest.setOptionalCampaignId(campaignId);
  if (workspaceId) {
    pageRequest.setOptionalWorkspaceId(workspaceId);
  }

  return pageRequest;
};

export const getSentEmailsOfCampaignPageableApi = (
  campaignId: number,
  page: number,
  limit: number,
  workspaceId?: number
): Promise<ThreadPreviewResponseType[]> => {
  const client = mailboxService.getInstance();

  return streamRequest<ThreadPreviewResponseType[]>(
    client,
    client.getSentEmailsOfCampaignPageable,
    createPageRequest(campaignId, workspaceId, page, limit)
  );
};

export const getOutboxEmailsOfCampaignPageableApi = (
  campaignId: number,
  page: number,
  limit: number,
  workspaceId?: number
): Promise<QueuePreviewResponseType[]> => {
  const client = mailboxService.getInstance();

  return streamRequest<QueuePreviewResponseType[]>(
    client,
    client.getOutboxEmailsOfCampaignPageable,
    createPageRequest(campaignId, workspaceId, page, limit)
  );
};

export const getFutureByQueueIdApi = (
  id: number,
  workspaceId: number,
  campaignId?: number
): Promise<QueueMessageResponseType> => {
  const client = mailboxService.getInstance();
  const idRequest = new CommonModels.IdRequest();

  idRequest.setId(id);
  idRequest.setOptionalWorkspaceId(workspaceId);
  campaignId && idRequest.setOptionalRelatedId(campaignId);

  return request<QueueMessageResponseType>(client, client.getFutureByQueueId, idRequest);
};

export const getAllEmailsByContactIdApi = (
  contactId: number,
  workspaceId?: number
): Promise<OutboxThreadMessageType[]> => {
  const client = mailboxService.getInstance();
  const idRequest = new CommonModels.IdRequest();

  idRequest.setId(contactId);
  workspaceId && idRequest.setOptionalWorkspaceId(workspaceId);

  return streamRequest<OutboxThreadMessageType[]>(
    client,
    client.getAllEmailsByContactId,
    idRequest
  );
};

export const getNeedAttentionEmailsOfCampaignPageableApi = (
  campaignId: number,
  page: number,
  limit: number,
  workspaceId?: number
): Promise<ThreadPreviewResponseType[]> => {
  const client = mailboxService.getInstance();

  return streamRequest<ThreadPreviewResponseType[]>(
    client,
    client.getNeedAttentionEmailsOfCampaignPageable,
    createPageRequest(campaignId, workspaceId, page, limit)
  );
};

export const getInboxEmailsOfCampaignPageableApi = (
  campaignId: number,
  page: number,
  limit: number,
  workspaceId?: number
): Promise<ThreadPreviewResponseType[]> => {
  const client = mailboxService.getInstance();

  return streamRequest<ThreadPreviewResponseType[]>(
    client,
    client.getInboxEmailsOfCampaignPageable,
    createPageRequest(campaignId, workspaceId, page, limit)
  );
};

export const getAllEmailsByThreadApi = (
  threadUid: string,
  workspaceId: number
): Promise<ThreadMessageType[]> => {
  const client = mailboxService.getInstance();
  const uidRequest = new ThreadUidRequest();

  uidRequest.setUid(threadUid);
  uidRequest.setWorkspaceId(workspaceId);

  return streamRequest<ThreadMessageType[]>(client, client.getThreadEmails, uidRequest);
};

export const updateQueueMessageByIdApi = (
  id: number,
  content: string,
  attachmentsList?: FileAttachmentType[]
): Promise<QueueMessageResponseType> => {
  const client = mailboxService.getInstance();
  const updateRequest = new MailboxModels.QueueMessageUpdateRequest();

  updateRequest.setId(id);
  updateRequest.setContent(content);
  if (attachmentsList) {
    updateRequest.setAttachmentsList(
      attachmentsList
        ? attachmentsList.map((attachment) => {
            const fileAttachmentModel = new CommonModels.FileAttachment();
            fileAttachmentModel.setKey(attachment.key);
            fileAttachmentModel.setTitle(attachment.title);
            fileAttachmentModel.setSize(attachment.size);

            return fileAttachmentModel;
          })
        : []
    );
  }

  return request(client, client.updateQueueById, updateRequest);
};

export const cancelAllQueuesByPersonIdAndReCalculateQueuesApi = (
  workspaceId: number,
  campaignId: number,
  idList: number[],
  relatedTo: MailboxQueueCancelRequest.MailboxQueueCancelRelatedTypeMap[keyof MailboxQueueCancelRequest.MailboxQueueCancelRelatedTypeMap]
): Promise<void> => {
  const client = mailboxService.getInstance();
  const mailboxQueueCancelRequest = new MailboxModels.MailboxQueueCancelRequest();

  mailboxQueueCancelRequest.setWorkspaceId(workspaceId);
  mailboxQueueCancelRequest.setCampaignId(campaignId);
  mailboxQueueCancelRequest.setIdsList(idList);
  mailboxQueueCancelRequest.setRelatedTo(relatedTo);

  return request<void>(
    client,
    client.cancelAllQueuesByRelatedIdInAndReCalculateQueues,
    mailboxQueueCancelRequest
  );
};

export const getAllUnsubscribedPatternsOfOrganizationApi = (
  page = 0,
  limit = 10,
  query = '',
  optionalFiltersList: UnsubscribeFilterType[] = []
): Promise<BlackListResponseType[]> => {
  const client = mailboxService.getInstance();
  const pageRequest = new MailboxModels.GetUnsubscribedPatternPageable();

  pageRequest.setPage(page);
  pageRequest.setLimit(limit);

  if (query?.length) {
    pageRequest.setOptionalQuery(query);
  }

  pageRequest.setOptionalFiltersList(
    optionalFiltersList.map((filterData) => {
      const filter = new MailboxModels.UnsubscribedFilter();

      filter.setOrder(filterData.order);
      filter.setField(filterData.field);

      // @ts-ignore
      if (filterData.value?.value !== undefined) {
        filter.setValue(
          // @ts-ignore
          filterData.value?.value.toString // @ts-ignore
            ? filterData.value?.value.toString() // @ts-ignore
            : filterData.value?.value
        );
      } else if (filterData.value.toString) {
        filter.setValue(filterData.value.toString());
      } else {
        filter.setValue(filterData.value);
      }

      filter.setOperation(filterData.operation);
      filter.setJoin(filterData.join);

      return filter;
    })
  );

  return streamRequest(client, client.getAllBlackListPatternsOfOrganization, pageRequest);
};

export const addBlackListPatternsApi = (
  patterns: string[],
  workspaceIds: number[]
): Promise<void> => {
  const client = mailboxService.getInstance();
  const blackListRequest = new MailboxModels.BlackListBatchRequest();

  blackListRequest.setPatternsList(patterns);
  if (workspaceIds.length && workspaceIds.length > 0) {
    blackListRequest.setWorkspaceIdsList(workspaceIds);
  }

  return request<void>(client, client.addBlackListPatterns, blackListRequest);
};

export const updateBlackListPatternApi = (
  id: number,
  pattern: string,
  workspaceIds: number[]
): Promise<void> => {
  const client = mailboxService.getInstance();
  const blackListRequest = new MailboxModels.BlackListRequest();

  blackListRequest.setId(id);
  blackListRequest.setPattern(pattern);
  if (workspaceIds.length && workspaceIds.length > 0) {
    blackListRequest.setWorkspaceIdsList(workspaceIds);
  }

  return request<void>(client, client.updateBlackListPattern, blackListRequest);
};

export const removeBlackListPatternBulkApi = (
  patternsIds: number[],
  workspaceId: number
): Promise<void> => {
  const client = mailboxService.getInstance();
  const idContainer = new CommonModels.IdContainer();

  idContainer.setIdList(patternsIds);
  idContainer.setOptionalWorkspaceId(workspaceId);

  return request<void>(client, client.removeBlackListPatternBulk, idContainer);
};

export const exportBlackListPatternsToCsvApi = (
  patternsIds: number[]
): Promise<FileResponseType> => {
  const client = mailboxService.getInstance();
  const exportBlackListPatternRequest = new MailboxModels.ExportBlackListPatternRequest();

  if (patternsIds.length === 0) {
    exportBlackListPatternRequest.setType(ExportBlackListPatternRequest.ExportType.ALL);
  } else {
    exportBlackListPatternRequest.setType(ExportBlackListPatternRequest.ExportType.SELECTED);
    exportBlackListPatternRequest.setOptionalSelectedList(patternsIds);
  }

  return request<FileResponseType>(
    client,
    client.exportBlackListPatternsToCsv,
    exportBlackListPatternRequest
  );
};

export const changeThreadsStatusApi = (
  workspaceId: number,
  threadUid: string,
  status: ThreadStatusMap[keyof ThreadStatusMap],
  snoozedUntil?: number
): Promise<void> => {
  const client = mailboxService.getInstance();
  const threadStatusRequest = new MailboxModels.ThreadStatusRequest();

  threadStatusRequest.setWorkspaceId(workspaceId);
  threadStatusRequest.setThreadUidsList([threadUid]);
  threadStatusRequest.setStatus(status);
  if (snoozedUntil !== null && snoozedUntil > 0) {
    threadStatusRequest.setOptionalSnoozedUntilAt(snoozedUntil);
  }

  return request<void>(client, client.changeThreadsStatus, threadStatusRequest);
};

export const changeViewStateByThreadIdApi = (threadUid: string, viewed: boolean): Promise<void> => {
  const client = mailboxService.getInstance();
  const threadViewedRequest = new MailboxModels.ChangeViewStateRequest();

  threadViewedRequest.setThreadUid(threadUid);
  threadViewedRequest.setViewed(viewed);

  return request<void>(client, client.changeViewStateByThreadId, threadViewedRequest);
};

export const getPreviewEmailsApi = (
  workspaceId: number,
  personId?: number,
  websiteId?: number
): Promise<ThreadPreviewResponse.AsObject[]> => {
  const client = mailboxService.getInstance();
  const previewRequest = new MailboxModels.PersonInboxPreviewEmailsRequest();

  previewRequest.setWorkspaceId(workspaceId);

  if (personId) {
    previewRequest.setPersonId(personId);
  } else if (websiteId) {
    previewRequest.setPersonCompanyId(websiteId);
  }

  return streamRequest<ThreadPreviewResponse.AsObject[]>(
    client,
    client.getPersonPreviewEmails,
    previewRequest
  );
};

export const getPreviewEmailsByPersonIdInApi = (
  workspaceId: number,
  personIds: number[]
): Promise<ThreadPreviewResponse.AsObject[]> => {
  const client = mailboxService.getInstance();
  const previewRequest = new CommonModels.IdContainer();

  previewRequest.setIdList(personIds);
  previewRequest.setOptionalRelatedId(-1);
  previewRequest.setOptionalWorkspaceId(workspaceId);

  return streamRequest<ThreadPreviewResponse.AsObject[]>(
    client,
    client.getPreviewEmailsByPersonIdIn,
    previewRequest
  );
};

export const getLatestEmailByThreadId = (
  threadUid: string,
  workspaceId: number
): Promise<ThreadMessageType> => {
  const client = mailboxService.getInstance();
  const uidRequest = new ThreadUidRequest();

  uidRequest.setUid(threadUid);
  uidRequest.setWorkspaceId(workspaceId);

  return request<ThreadMessageType>(client, client.getLatestEmailByThreadId, uidRequest);
};

export const getThreadPreviewByThreadIdApi = (
  threadUid: string,
  workspaceId: number
): Promise<ThreadPreviewResponse.AsObject> => {
  const client = mailboxService.getInstance();
  const uidRequest = new ThreadUidRequest();

  uidRequest.setUid(threadUid);
  uidRequest.setWorkspaceId(workspaceId);

  return request<ThreadPreviewResponse.AsObject>(
    client,
    client.getThreadPreviewByThreadId,
    uidRequest
  );
};

export const assignToUserApi = (threadUid: string, userId: number): Promise<void> => {
  const client = mailboxService.getInstance();
  const assignRequest = new MailboxModels.AssignThreadToUserRequest();

  assignRequest.setUserId(userId);
  assignRequest.setThreadUid(threadUid);

  return request<void>(client, client.assignToUser, assignRequest);
};

export const changeFavoriteStateApi = (threadUid: string, workspaceId: number): Promise<void> => {
  const client = mailboxService.getInstance();
  const uidRequest = new ThreadUidRequest();

  uidRequest.setUid(threadUid);
  uidRequest.setWorkspaceId(workspaceId);

  return request<void>(client, client.changeFavoriteState, uidRequest);
};

export const getAllLabelsApi = (): Promise<ThreadTagType[]> => {
  const client = mailboxService.getInstance();
  const emptyRequest = new google_protobuf_empty_pb.Empty();
  return streamRequest<ThreadTagType[]>(client, client.getAllThreadTags, emptyRequest);
};

export const addLabelApi = (
  threadUid: string,
  tagTitle: string,
  tagId?: number
): Promise<ThreadTagType> => {
  const client = mailboxService.getInstance();
  const tagRequest = new ThreadTag();

  if (tagId) {
    tagRequest.setId(tagId);
  }
  tagRequest.setThreadUid(threadUid);
  tagRequest.setTitle(tagTitle);

  return request<ThreadTagType>(client, client.addThreadTag, tagRequest);
};

export const updateLabelApi = (tagId: number, tagTitle: string): Promise<ThreadTagType> => {
  const client = mailboxService.getInstance();
  const tagRequest = new ThreadTag();

  tagRequest.setId(tagId);
  tagRequest.setTitle(tagTitle);

  return request<ThreadTagType>(client, client.updateThreadTag, tagRequest);
};

export const removeLabelApi = (tagId: number, threadUid: string): Promise<void> => {
  const client = mailboxService.getInstance();
  const removeRequest = new ThreadTag();

  removeRequest.setId(tagId);
  removeRequest.setThreadUid(threadUid);

  return request<void>(client, client.removeThreadTag, removeRequest);
};

export const createOrUpdateThreadDraftApi = (
  threadUid: string,
  messageId: string,
  type: MessageRequestTypeMap[keyof MessageRequestTypeMap],
  emailTo: string,
  subject: string,
  content: string,
  attachmentsList?: FileAttachmentType[]
): Promise<void> => {
  const client = mailboxService.getInstance();
  const payload = new MailboxModels.MailThreadDraftRequest();

  payload.setThreadUid(threadUid);
  payload.setMessageId(messageId);
  payload.setEmailTo(emailTo);
  payload.setType(type);
  payload.setSubject(subject);
  payload.setContent(content);
  if (attachmentsList) {
    payload.setAttachmentsList(
      attachmentsList
        ? attachmentsList.map((attachment) => {
            const fileAttachmentModel = new CommonModels.FileAttachment();
            fileAttachmentModel.setKey(attachment.key);
            fileAttachmentModel.setTitle(attachment.title);
            fileAttachmentModel.setSize(attachment.size);

            return fileAttachmentModel;
          })
        : []
    );
  }

  return request<void>(client, client.createOrUpdateThreadDraft, payload);
};

export const deleteThreadDraftApi = (
  threadUid: string,
  workspaceId: number
): Promise<MailboxAiActionsType> => {
  const client = mailboxService.getInstance();
  const uidRequest = new ThreadUidRequest();
  uidRequest.setUid(threadUid);
  uidRequest.setWorkspaceId(workspaceId);

  return request<MailboxAiActionsType>(client, client.deleteThreadDraft, uidRequest);
};

export const getAllAIAssistantActionsApi = (): Promise<MailboxAiActionsType> => {
  const client = mailboxService.getInstance();
  const emptyRequest = new google_protobuf_empty_pb.Empty();

  return request<MailboxAiActionsType>(client, client.getAllAIAssistantActions, emptyRequest);
};

export const executeAIAssistantActionApi = (
  action: MailAIActionMap[keyof MailAIActionMap],
  language: string,
  content: string,
  workspaceId: number,
  replyMessageId?: string
): Promise<MailAIAssistantResponseType> => {
  const client = mailboxService.getInstance();
  const aiRequest = new MailboxModels.MailAIAssistantRequest();
  aiRequest.setAction(action);
  aiRequest.setContext(content);
  aiRequest.setWorkspaceId(workspaceId);
  if (language.length) {
    aiRequest.setOptLanguage(language);
  }
  if (replyMessageId !== null && replyMessageId.length) {
    aiRequest.setOptReplyMessageId(replyMessageId);
  }

  return request<MailAIAssistantResponseType>(client, client.executeAIAssistantAction, aiRequest);
};

export const createOrUpdateThreadConversationMessageApi = (
  threadUid: string,
  content: string,
  mentionedUsersIds: number[],
  conversationId?: number
): Promise<ThreadConversationMessageType> => {
  const client = mailboxService.getInstance();
  const conversationRequest = new MailboxModels.ThreadConversationMessageRequest();
  conversationRequest.setThreadUid(threadUid);
  conversationRequest.setContent(content);
  if (conversationId) {
    conversationRequest.setId(conversationId);
  }
  if (mentionedUsersIds && mentionedUsersIds.length) {
    conversationRequest.setMentionedUserIdsList(mentionedUsersIds);
  }

  return request<ThreadConversationMessageType>(
    client,
    client.createOrUpdateThreadConversationMessage,
    conversationRequest
  );
};

export const deleteThreadConversationMessageApi = (commentId: number): Promise<void> => {
  const client = mailboxService.getInstance();
  const conversationRequest = new CommonModels.IdRequest();

  conversationRequest.setId(commentId);

  return request<void>(client, client.deleteThreadConversationMessage, conversationRequest);
};

export const deleteThreadApi = (threadUid: string, workspaceId: number): Promise<void> => {
  const client = mailboxService.getInstance();
  const threadRequest = new ThreadUidRequest();

  threadRequest.setUid(threadUid);
  threadRequest.setWorkspaceId(workspaceId);

  return request<void>(client, client.deleteThread, threadRequest);
};

export const getThreadUnreadCountersApi = (
  workspaceId: number,
  accountIds: number[]
): Promise<InboxUnreadCounterType> => {
  const client = mailboxService.getInstance();
  const counterRequest = new MailboxModels.InboxUnreadCounterRequest();

  counterRequest.setWorkspaceId(workspaceId);
  counterRequest.setAccountIdsList(accountIds);

  return request<InboxUnreadCounterType>(client, client.getThreadUnreadCounters, counterRequest);
};

export const assignMailsByEmailToPersonApi = (
  personId: number,
  websiteId: number,
  email: string
): Promise<void> => {
  const client = mailboxService.getInstance();
  const assignRequest = new MailboxModels.AssignMailsToPersonRequest();

  assignRequest.setPersonId(personId);
  assignRequest.setWebsiteId(websiteId);
  assignRequest.setEmail(email);

  return request<void>(client, client.assignMailsByEmailToPerson, assignRequest);
};

export const getAllThreadConversationMessagesApi = (
  threadUid: string,
  workspaceId: number
): Promise<ThreadConversationMessageType[]> => {
  const client = mailboxService.getInstance();
  const uidRequest = new ThreadUidRequest();
  uidRequest.setUid(threadUid);
  uidRequest.setWorkspaceId(workspaceId);

  return streamRequest<ThreadConversationMessageType[]>(
    client,
    client.getAllThreadConversationMessages,
    uidRequest
  );
};

export const getThreadDraftApi = (draftIf: number): Promise<MailboxThreadDraftType> => {
  const client = mailboxService.getInstance();
  const assignRequest = new CommonModels.IdRequest();

  assignRequest.setId(draftIf);

  return request<MailboxThreadDraftType>(client, client.getThreadDraft, assignRequest);
};
