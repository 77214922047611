import { CampaignType, CampaignFiltersType } from '@ts/campaigns.types';

import { CampaignFilterField } from 'respona_api/generated/campaign_pb';

import { getCampaignsByFiltersApi, getDeletedCampaignApi } from '@api/campaign.api';
import { FilterOperation } from 'respona_api/generated/common_pb';

export const getAllCampaignsQuery = (
  workspaceId: number,
  isDeleted: boolean,
  page = 0,
  limit = 10,
  folderId?: number,
  filters?: CampaignFiltersType
): Promise<CampaignType[]> => {
  if (isDeleted) {
    return getDeletedCampaignApi(workspaceId, page, limit, folderId);
  }

  // if (filters && (filters.personIds.length !== 0 || filters.dateRange || filters.titleSearch)) {
  const { titleSearch, personIds, dateRange, status, extendedFilters } = filters;

  return getCampaignsByFiltersApi(
    titleSearch,
    personIds,
    dateRange,
    workspaceId,
    page,
    limit,
    folderId,
    status,
    extendedFilters.map((item) => {
      if (item.field === CampaignFilterField.DROPDOWN_CAMPAIGN_STATUS) {
        // @ts-ignore
        return { ...item, value: item.value.value };
      }

      if (item.field === CampaignFilterField.DROPDOWN_CAMPAIGN_OWNER_ID) {
        return {
          ...item, // @ts-ignore
          value: item.value.value?.toString(),
          operation:
            item.operation === FilterOperation.STRING_EQUAL
              ? FilterOperation.STRING_EQUAL
              : FilterOperation.STRING_EQUAL_NOT,
        };
      }

      if (item.field === CampaignFilterField.DROPDOWN_CAMPAIGN_FOLDER_ID) {
        return {
          ...item, // @ts-ignore
          value: item.value.value?.toString(),
          operation:
            item.operation === FilterOperation.STRING_EQUAL
              ? FilterOperation.STRING_EQUAL
              : FilterOperation.STRING_EQUAL_NOT,
        };
      }

      return item;
    })
  );
  // }
};
