import AnalyticsService from '../../../respona_api/generated/analytics_pb_service';

const service = (function () {
  let instance = null;

  return {
    getInstance: (): AnalyticsService.AnalyticsApiServiceClient => {
      if (!instance) {
        // @ts-ignore
        instance = new AnalyticsService.AnalyticsApiServiceClient(API_HOST);
      }

      return instance;
    },
  };
})();

export default service;
