import React from 'react';

import NoAccessIllustration from '@uikit/Icon/illustrations/NoAccessIllustration';

import './NoAccessMessage.scss';

const NoAccessMessage = ({
  message = "Oops! You don't have access to this tab.",
}: {
  message?: string;
}): JSX.Element => {
  return (
    <div className="no-access-error">
      <NoAccessIllustration />
      <h1 className="no-access-error__title">{message}</h1>
      <p className="no-access-error__sub-title">Please, contact your administrator</p>
    </div>
  );
};

export default NoAccessMessage;
