import React from 'react';

export default function ({ color = '#E8E8E8', size = 23 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.99935 17.3341C4.39685 17.3341 0.666016 13.6033 0.666016 9.00081C0.666016 4.39831 4.39685 0.66748 8.99935 0.66748C13.6018 0.66748 17.3327 4.39831 17.3327 9.00081C17.3327 13.6033 13.6018 17.3341 8.99935 17.3341ZM8.99935 7.82248L6.64268 5.46498L5.46352 6.64415L7.82102 9.00081L5.46352 11.3575L6.64268 12.5366L8.99935 10.1791L11.356 12.5366L12.5352 11.3575L10.1777 9.00081L12.5352 6.64415L11.356 5.46498L8.99935 7.82248Z"
        fill={color}
      />
    </svg>
  );
}
