import {
  CampaignAutomationStatus,
  CampaignAutomationStatusMap,
} from 'respona_api/generated/common_pb';
import { Status } from '@components/CampaignSettings/ContentSearchActivityLogs/types/types';

const getStatusForUI = (
  value: string
): CampaignAutomationStatusMap[keyof CampaignAutomationStatusMap] => {
  switch (value) {
    case Status.IN_PROCESS:
      return CampaignAutomationStatus.IN_PROCESS;
    case Status.FUTURE:
      return CampaignAutomationStatus.FUTURE;
    case Status.CANCELED:
      return CampaignAutomationStatus.CANCELED;
    case Status.FINISHED:
      return CampaignAutomationStatus.FINISHED;
    case Status.DRAFT:
      return CampaignAutomationStatus.DRAFT;
    default:
      return CampaignAutomationStatus.FINISHED;
  }
};

export default getStatusForUI;
