import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import ReactSelect, { components } from 'react-select';
import ReactTooltip from 'react-tooltip';
import Sortable from 'sortablejs';
import cn from 'class-names';

import Input from '@uikit/Input/Input';
import Select from '@uikit/Select/Select';
import Radio from '@uikit/Radio/Radio';
import AutomationPositionTooltipContent from '@components/CampaignSettings/ContactAutomationSetup/_components/AutomationPositionTooltipContent/AutomationPositionTooltipContent';
import Option from '@components/CustomFieldsSidebar/_components/Option';
import Control from '@components/CustomFieldsSidebar/_components/Control';
import InlineSlateEditor from '@uikit/RichEditor/InlineSlateEditor';
import NumberInput from '@uikit/Input/NumberInput';
import ContentSearchEditorV2 from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/ContentSearchEditor/ContentSearchEditorV2';
import { SVGIcon } from '@uikit/Icon/Icon';
import debounce from '@utils/debounce';
import useAllVariablesInEditor from '@hooks/useAllVariablesInEditor';
import {
  CustomVariableType,
  CustomVariableValueRelatedTo,
  CustomVariableValueRelatedToMap,
} from 'respona_api/generated/common_pb';
import {
  CustomVariableCreateRequest,
  OpenAICustomVariableSettings,
} from 'respona_api/generated/people_pb';
import { CustomSelectVariable } from '@ts/customVariales.types';
import MultiValueContainer from '@components/CustomFieldsSidebar/_components/MultiValueContainer';
import useOutsideClick from '@hooks/useOutsideClick';
import QuestionTooltip from '@pages/CampaignCreationPage/_components/QuestionTooltip/QuestionTooltip';
import ControlOptions from '@components/CustomFieldsSidebar/_components/ControlOptions';

const typeOptions = [
  { value: CustomVariableType.TEXT, label: 'Text', iconSize: 11 },
  { value: CustomVariableType.URL, label: 'URL', iconSize: 11 },
  { value: CustomVariableType.INT, label: 'Number', iconSize: 16 },
  { value: CustomVariableType.OPEN_AI, label: 'AI', iconSize: 16 },
  { value: CustomVariableType.TEAM_MEMBER, label: 'Team Member', iconSize: 16 },
  { value: CustomVariableType.CHECKBOX, label: 'Checkbox', iconSize: 16 },
  { value: CustomVariableType.DATE_AND_TIME, label: 'Date', iconSize: 16 },
  { value: CustomVariableType.SELECT, label: 'Select', iconSize: 16 },
  { value: CustomVariableType.MULTI_SELECT, label: 'Multi Select', iconSize: 16 },
  { value: CustomVariableType.RATING, label: 'Rating', iconSize: 16 },
];

const toneOptions = [
  { value: 'friendly', label: 'Friendly 😊', iconSize: 11 },
  { value: 'witty', label: 'Witty 💡', iconSize: 11 },
  { value: 'funny', label: 'Funny 😂', iconSize: 11 },
  { value: 'professional', label: 'Professional 💼', iconSize: 11 },
  { value: 'casual', label: 'Casual 😌', iconSize: 11 },
  { value: 'persuasive', label: 'Persuasive 🧠', iconSize: 11 },
  { value: 'enthusiastic', label: 'Enthusiastic 🤩', iconSize: 11 },
  { value: 'empathetic', label: 'Empathetic 🤗', iconSize: 11 },
  { value: 'curious', label: 'Curious 🤔', iconSize: 11 },
];

const buttonColours = [
  '#00DCF0',
  '#7044FE',
  '#4FB83D',
  '#DF3617',
  '#EDC200',
  '#FC8619',
  '#CD58C3',
  '#ABABAB',
];

const LIMIT_OPTIONS = 10;
const MIN_OPTION_LEN = 2;
const MAX_OPTION_LEN = 50;
const OUTPUT_LENGTH_TO = 'outputLengthTo';
const OUTPUT_LENGTH_FROM = 'outputLengthFrom';

function MenuList(props) {
  const menuListRef = useRef(null);

  useEffect(() => {
    new Sortable(menuListRef.current, {
      handle: '.js-custom-field-option',
      onUpdate(event) {
        event.preventDefault();

        props.selectProps.setOptSelectSettings((nextState) => {
          const newArr = [
            ...menuListRef.current.querySelectorAll('.js-custom-field-select__item'),
          ].map((el, index) => {
            return {
              ...nextState.itemsList.find((option) => option.uid === el.dataset.value),
              order: index,
            };
          });

          return {
            ...nextState,
            itemsList: newArr,
          };
        });
      },
    });
  }, []);

  const handleChangeOptionTitle = (uid, title) => {
    props.selectProps.setOptSelectSettings({
      ...props.selectProps.optSelectSettings,
      itemsList: props.selectProps.optSelectSettings.itemsList.map((option) => {
        if (option.uid === uid) {
          return {
            ...option,
            name: title,
          };
        }

        return option;
      }),
    });
  };

  const handleRemoveOption = (uid) => {
    props.selectProps.setOptSelectSettings({
      ...props.selectProps.optSelectSettings,
      itemsList: props.selectProps.optSelectSettings.itemsList.filter(
        (option) => option.uid !== uid
      ),
    });
  };

  const handleClickColor = (value, uid) => {
    props.selectProps.setOptSelectSettings({
      ...props.selectProps.optSelectSettings,
      itemsList: props.selectProps.optSelectSettings.itemsList.map((option) => {
        if (option.uid === uid) {
          return {
            ...option,
            color: value,
          };
        }

        return option;
      }),
    });
  };

  return (
    <components.MenuList {...props}>
      <div
        className="custom-field-select"
        // without it any events don't work, i.e: focus in input, sortable, remove option
        onMouseDown={(event) => event.stopPropagation()}
        // needed to unblock "delete" in Input
        onKeyDown={(event) => event.stopPropagation()}
      >
        <div ref={menuListRef}>
          {props.selectProps.options.map((item, index) => {
            return (
              <div
                key={item.value}
                data-value={item.value}
                className="custom-field-select__item js-custom-field-select__item"
              >
                <span className="custom-field-select__item-drag js-custom-field-option">
                  <SVGIcon icon="dragAndDrop" />
                </span>
                <span
                  className="custom-field-select__item-color"
                  style={{ background: item.color }}
                  data-for={`custom-field-sidebar-length-${index}`}
                  data-tip=""
                />
                <ReactTooltip
                  id={`custom-field-sidebar-length-${index}`}
                  className="react-tooltip"
                  place="bottom"
                  effect="solid"
                  event="click"
                  globalEventOff="click"
                  arrowColor="transparent"
                  clickable
                >
                  <div className="custom-fields-sidebar__field-label-tooltip">
                    <h4 className="custom-fields-sidebar__field-label-tooltip-title">
                      Choose color
                    </h4>
                    <div className="custom-fields-sidebar__field-label-tooltip-color">
                      {buttonColours.map((color, id) => {
                        return (
                          <span
                            key={id}
                            onClick={() => handleClickColor(color, item.value)}
                            style={{ background: color }}
                            className={`color-item ${item.color === color ? 'active' : ''}`}
                          />
                        );
                      })}
                    </div>
                  </div>
                </ReactTooltip>
                <input
                  className="custom-field-select__item-input"
                  type="text"
                  defaultValue={item.label}
                  maxLength={MAX_OPTION_LEN}
                  onChange={(event) => {
                    handleChangeOptionTitle(item.value, event.target.value);
                  }}
                />
                <span
                  className="custom-field-select__item-close"
                  onClick={() => handleRemoveOption(item.value)}
                >
                  <SVGIcon icon="crosedIcon" size={15} color="#5E5E5E" />
                </span>
              </div>
            );
          })}
        </div>
        {props.selectProps.options.length < LIMIT_OPTIONS ? (
          <div
            className={cn('custom-field-select__create-button', {
              'custom-field-select__create-button--disabled':
                props.selectProps.options.length >= LIMIT_OPTIONS,
            })}
            onClick={props.selectProps.createNewOption}
          >
            + Create new option
          </div>
        ) : null}
      </div>
    </components.MenuList>
  );
}

function CustomFieldSidebarContent({
  fields,
  isUpdate,
  isDefaultField,
  optAiSettings,
  setFields,
  setOptAiSettings,
  optSelectSettings,
  setOptSelectSettings,
  onlyTextVariableAvailable,
}: {
  fields: Partial<CustomVariableCreateRequest.AsObject>;
  isUpdate?: boolean;
  isDefaultField?: boolean;
  optAiSettings?: Partial<OpenAICustomVariableSettings.AsObject>;
  setFields: Dispatch<SetStateAction<Partial<CustomVariableCreateRequest.AsObject>>>;
  setOptAiSettings?: Dispatch<SetStateAction<Partial<OpenAICustomVariableSettings.AsObject>>>;
  optSelectSettings?: Partial<CustomSelectVariable>;
  setOptSelectSettings?: Dispatch<SetStateAction<Partial<CustomSelectVariable>>>;
  onlyTextVariableAvailable?: boolean;
}) {
  const { variables } = useAllVariablesInEditor(null);
  const minInputRef = useRef<HTMLInputElement>();
  const maxInputRef = useRef<HTMLInputElement>();

  const ref = useRef<ReactSelect>();
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

  useOutsideClick({ current: ref?.current?.select.controlRef }, () => {
    if (menuIsOpen) {
      setMenuIsOpen(false);
    }
  });

  const onChangeType = ({ value }) => {
    createSelectChangeHandler('type')(value);
    if (value === CustomVariableType.OPEN_AI) {
      createSelectChangeHandler('relatedTo')(CustomVariableValueRelatedTo.OPPORTUNITY);
    } else if (value === CustomVariableType.SELECT) {
      setOptSelectSettings((prev) => ({ ...prev, multi: false }));
    } else if (value === CustomVariableType.MULTI_SELECT) {
      setOptSelectSettings((prev) => ({ ...prev, multi: true }));
    }
  };

  const handleChange = ({ target: { value, name } }) =>
    setFields((prev) => ({
      ...prev,
      [name]: value,
    }));

  const handleRelationChange = ({ target: { value } }) =>
    setFields((prev) => ({
      ...prev,
      relatedTo: Number(
        value
      ) as CustomVariableValueRelatedToMap[keyof CustomVariableValueRelatedToMap],
    }));

  const createSelectChangeHandler = (fieldName) => (value) =>
    setFields((prev) => ({
      ...prev,
      [fieldName]: value,
    }));

  const handleChangeOptions = (fieldName) => (value) => {
    setOptAiSettings((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const getToneValue = () => {
    if (!optAiSettings?.tone) {
      return null;
    }

    const defaultOption = toneOptions.find((option) => option.value === optAiSettings.tone);

    if (defaultOption) {
      return defaultOption;
    }

    return {
      value: optAiSettings.tone,
      label: optAiSettings.tone,
    };
  };

  const handleTone = (value) => {
    handleChangeOptions('tone')(value.value);
  };

  const handlePrompt = useRef(
    debounce((value) => {
      handleChangeOptions('prompt')(value);
    }, 400)
  ).current;

  const handleMinMaxInputs = () => {
    setTimeout(() => {
      const { outputLengthFrom, outputLengthTo } = optAiSettings || {};

      if (outputLengthFrom === undefined || outputLengthTo === undefined) {
        return;
      }

      if (Number(outputLengthTo) < Number(outputLengthFrom)) {
        handleChangeOptions(OUTPUT_LENGTH_FROM)(Number(outputLengthTo));
        handleChangeOptions(OUTPUT_LENGTH_TO)(Number(outputLengthFrom));
      }
    });
  };

  const availableTypeOptions = onlyTextVariableAvailable
    ? typeOptions.filter((it) => it.value === CustomVariableType.TEXT)
    : typeOptions;

  return (
    <div className="custom-fields-sidebar__content">
      <div className="custom-fields-sidebar__field">
        <div className="custom-fields-sidebar__field-label">Field name</div>
        <Input
          placeholder=""
          value={fields.title}
          disabled={isUpdate || isDefaultField}
          isFullWidth
          isFullHeight
          maxLength={40}
          name="title"
          className="custom-fields-sidebar__input"
          onChange={handleChange}
        />
      </div>

      <div className="custom-fields-sidebar__field">
        <div className="custom-fields-sidebar__field-label">Description</div>
        <Input
          placeholder=""
          value={fields.description}
          disabled={isDefaultField}
          isFullWidth
          isFullHeight
          maxLength={250}
          name="description"
          className="custom-fields-sidebar__input"
          onChange={handleChange}
        />
      </div>

      <div className="custom-fields-sidebar__field">
        <div className="custom-fields-sidebar__field-label">
          <span style={{ marginRight: '4px' }}>Type</span>
          {onlyTextVariableAvailable && (
            <QuestionTooltip
              text="Only Text variables available during Import"
              offset={{ left: 5, top: 2 }}
            />
          )}
        </div>
        <Select
          options={availableTypeOptions}
          value={availableTypeOptions.find((item) => item.value === fields.type)}
          onChange={onChangeType}
          renderMultipleValuesTooltipContent={AutomationPositionTooltipContent}
          fieldToRender="label"
          allowCustomInput={false}
          isSearchable={false}
          isDisabled={isUpdate || onlyTextVariableAvailable}
          additionalComponents={{ Option, Control }}
        />
      </div>

      {(fields.type === CustomVariableType.MULTI_SELECT ||
        fields.type === CustomVariableType.SELECT) && (
        <div className="custom-fields-sidebar__field">
          <div className="custom-fields-sidebar__field-label">Options</div>
          <Select
            onClickControl={() => !menuIsOpen && setMenuIsOpen(true)}
            innerRef={ref}
            menuIsOpen={menuIsOpen}
            additionalStyles={{ multiValueRemove: (base) => ({ ...base, display: 'none' }) }}
            options={optSelectSettings.itemsList.map(({ uid, name, ...rest }) => ({
              ...rest,
              label: name,
              value: uid,
            }))}
            isMulti={fields.type === CustomVariableType.MULTI_SELECT}
            value={optSelectSettings.itemsList
              .filter(({ name }) => name.length >= MIN_OPTION_LEN)
              .map((option) => ({
                label: option.name,
                value: option.uid,
                color: option.color,
              }))}
            createNewOption={() => {
              const num = optSelectSettings.itemsList.length + 1;
              setOptSelectSettings({
                ...optSelectSettings,
                itemsList: [
                  ...optSelectSettings.itemsList,
                  {
                    uid: String(Math.random()), // TODO we can keep it now, but remove before sending to server for new variables
                    name: `Option ${num}`,
                    color: '#EDC200',
                    order: num,
                  },
                ],
              });
            }}
            setOptSelectSettings={setOptSelectSettings}
            optSelectSettings={optSelectSettings}
            renderMultipleValuesTooltipContent={AutomationPositionTooltipContent}
            fieldToRender="label"
            allowCustomInput
            isSearchable={false}
            additionalComponents={{ MenuList, MultiValueContainer, Control: ControlOptions }}
          />
        </div>
      )}

      {fields.type === CustomVariableType.OPEN_AI && (
        <>
          <div className="custom-fields-sidebar__field custom-fields-sidebar__field--relative">
            <div className="custom-fields-sidebar__field-label">
              Prompt
              <span
                className="custom-fields-sidebar__field-label-help"
                data-for="custom-field-sidebar-prompt"
                data-tip=""
              >
                <SVGIcon color="#C6C6C6" icon="questionInCircleFilled" />
              </span>
              <ReactTooltip
                id="custom-field-sidebar-prompt"
                className="react-tooltip"
                place="top"
                effect="solid"
                arrowColor="transparent"
              >
                <p className="custom-fields-sidebar__field-label-tooltip">
                  Clearly explain how you’d like the field populated. Having a detailed prompt will
                  result in more accurate outputs.
                  <br />
                  <br /> Minimum value is 5
                </p>
              </ReactTooltip>
            </div>
            <InlineSlateEditor
              message={optAiSettings?.prompt}
              onChange={handlePrompt}
              placeholder="Craft an icebreaker sentence for the email using the title and provided summary points to connect with the recipient authentically. The opener should be engaging, reflect a personal touch, and motivate the recipient to read on, firmly establishing that the email is not part of a mass campaign but is directly addressing something they've created or are interested in."
              useMessageUpdate
              withEmoji={false}
              disabled={isDefaultField}
              variables={variables.filter((it) => it?.type !== CustomVariableType.OPEN_AI)}
              stickVariables
              limit={1000}
              minValue={5}
              hasCounter
            />
          </div>

          <div className="custom-fields-sidebar__field">
            <div className="custom-fields-sidebar__field-label">
              Tone
              <div className="custom-fields-sidebar__field-label-option">Optional</div>
            </div>
            <Select
              options={toneOptions}
              placeholder="Try “Funny,” “Professional,” or “Casual”"
              onChange={handleTone}
              renderMultipleValuesTooltipContent={AutomationPositionTooltipContent}
              fieldToRender="label"
              allowCustomInput
              isSearchable
              additionalComponents={{
                Input: (props) => <components.Input {...props} maxLength={16} />,
              }}
              value={getToneValue()}
            />
          </div>

          <div className="custom-fields-sidebar__field">
            <div className="custom-fields-sidebar__field-label">
              Length of output (words)
              <span
                className="custom-fields-sidebar__field-label-help"
                data-for="custom-field-sidebar-length"
                data-tip=""
              >
                <SVGIcon color="#C6C6C6" icon="questionInCircleFilled" />
              </span>
              <ReactTooltip
                id="custom-field-sidebar-length"
                className="react-tooltip"
                place="top"
                effect="solid"
                arrowColor="transparent"
              >
                <p className="custom-fields-sidebar__field-label-tooltip">
                  Restrict the length of the outputted text to ensure your email won’t become too
                  wordy.
                </p>
              </ReactTooltip>
              <div className="custom-fields-sidebar__field-label-option">Optional</div>
            </div>
            <div className="custom-fields-sidebar__from-to">
              <NumberInput
                inputRef={minInputRef}
                tabIndex={0}
                beforeText="From:"
                value={
                  !optAiSettings?.outputLengthFrom || optAiSettings.outputLengthFrom < 0
                    ? ''
                    : optAiSettings.outputLengthFrom
                }
                onChange={handleChangeOptions(OUTPUT_LENGTH_FROM)}
                onBlur={handleMinMaxInputs}
                maxNumber={100}
                minNumber={0}
                style={{ paddingLeft: 61, borderRadius: '8px 0 0 8px' }}
              />
              <NumberInput
                inputRef={maxInputRef}
                tabIndex={0}
                beforeText="To:"
                value={
                  !optAiSettings?.outputLengthTo || optAiSettings.outputLengthTo < 0
                    ? ''
                    : optAiSettings.outputLengthTo
                }
                onChange={handleChangeOptions(OUTPUT_LENGTH_TO)}
                onBlur={handleMinMaxInputs}
                maxNumber={100}
                minNumber={0}
                style={{ paddingLeft: 41, borderRadius: '0 8px 8px 0' }}
              />
            </div>
          </div>

          <div className="custom-fields-sidebar__field">
            <div className="custom-fields-sidebar__field-label">
              Examples of desired output
              <span
                className="custom-fields-sidebar__field-label-help"
                data-for="custom-field-sidebar-examples"
                data-tip=""
              >
                <SVGIcon color="#C6C6C6" icon="questionInCircleFilled" />
              </span>
              <ReactTooltip
                id="custom-field-sidebar-examples"
                className="react-tooltip"
                place="top"
                effect="solid"
                arrowColor="transparent"
              >
                <p className="custom-fields-sidebar__field-label-tooltip">
                  We recommend providing at least three examples to help the model generate more
                  relevant results.
                </p>
              </ReactTooltip>
              <div className="custom-fields-sidebar__field-label-option">Optional</div>
            </div>
            <ContentSearchEditorV2
              onChangeQueries={(value) => handleChangeOptions('examples')(value.join('\n'))}
              queries={optAiSettings?.examples?.split('\n') || []}
              withLinks={false}
              isUrl={false}
              placeholder='Just listened to your interview with Rand Fishkin - added his book "Lost and founder" to my Amazon list.'
              limit={500}
              hasCounter
            />
          </div>
        </>
      )}

      <div className="custom-fields-sidebar__field">
        <div className="custom-fields-sidebar__field-label">Assign to</div>
        <div className="custom-fields-sidebar__relation-field">
          <Radio
            onChange={handleRelationChange}
            value={CustomVariableValueRelatedTo.PERSON}
            isChecked={fields.relatedTo === CustomVariableValueRelatedTo.PERSON}
            isDisabled={isUpdate || fields.type === CustomVariableType.OPEN_AI || isDefaultField}
          >
            <div>
              <div className="custom-fields-sidebar__relation-name">Contacts</div>
              <div className="custom-fields-sidebar__relation-desc">
                Attribute this custom field to people in the relationships tab.
              </div>
            </div>
          </Radio>
          <br />
          <Radio
            onChange={handleRelationChange}
            value={CustomVariableValueRelatedTo.WEBSITE}
            isChecked={fields.relatedTo === CustomVariableValueRelatedTo.WEBSITE}
            isDisabled={isUpdate || fields.type === CustomVariableType.OPEN_AI || isDefaultField}
          >
            <div>
              <div className="custom-fields-sidebar__relation-name">Websites</div>
              <div className="custom-fields-sidebar__relation-desc">
                Attribute this custom field to websites in the relationships tab.
              </div>
            </div>
          </Radio>
          <br />
          <Radio
            onChange={handleRelationChange}
            value={CustomVariableValueRelatedTo.OPPORTUNITY}
            isChecked={fields.relatedTo === CustomVariableValueRelatedTo.OPPORTUNITY}
            isDisabled={isUpdate || isDefaultField}
          >
            <div>
              <div className="custom-fields-sidebar__relation-name">Campaign Opportunities</div>
              <div className="custom-fields-sidebar__relation-desc">
                Attribute this custom field to opportunity during campaign creation.
              </div>
            </div>
          </Radio>
          <br />
          <Radio
            onChange={handleRelationChange}
            value={CustomVariableValueRelatedTo.RELATIONSHIP_OPPORTUNITY}
            isChecked={fields.relatedTo === CustomVariableValueRelatedTo.RELATIONSHIP_OPPORTUNITY}
            isDisabled={isUpdate || isDefaultField}
          >
            <div>
              <div className="custom-fields-sidebar__relation-name">Deals</div>
              <div className="custom-fields-sidebar__relation-desc">
                Attribute this custom field to pipelines in the relationships tab.
              </div>
            </div>
          </Radio>
        </div>
      </div>
    </div>
  );
}

export default CustomFieldSidebarContent;
