import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { components } from 'react-select';

import BaseModal from '@uikit/BaseModal/BaseModal';
import { SVGIcon } from '@uikit/Icon/Icon';
import Select from '@uikit/Select/Select';
import Loader from '@uikit/Loader/Loader';
import Button from '@uikit/Button/Button';

import { workspacesSelector } from '@redux/selectors/workspaces.selectors';

import { transformWorkspacesToOptions } from '@mappers/optionTransformers';
import loadingStatuses from '@constants/loadingStatuses';

import { MailboxAccountSettingsType, MailboxAccountWorkspaceType } from '@ts/mailboxAccounts.types';

import './ShareEmailModal.scss';
import useMailboxAccountsSharedWorkspaces from '@hooks/useMailboxAccountsSharedWorkspaces';
import {
  addAccountsToWorkspaceApi,
  removeAccountsFromWorkspaceApi,
} from '@api/mailboxAccounts.api';
import { addNotification } from '@redux/actions/notifications.actions';

type ShareEmailModalPropsType = {
  onClose: () => void;
  isOpen: boolean;
  mailbox: MailboxAccountSettingsType;
  updateMailboxSettings: (payloadId: number, payload: MailboxAccountSettingsType) => void;
};

function Control({ children, ...restProps }) {
  return (
    <components.Control {...restProps}>
      &nbsp;&nbsp; <SVGIcon icon="search" /> {children}
    </components.Control>
  );
}

function ShareEmailModal({
  onClose,
  isOpen,
  mailbox,
  updateMailboxSettings,
}: ShareEmailModalPropsType): JSX.Element {
  const dispatch = useDispatch();

  const { workspaces, loadingStatus: workspacesLs } = useSelector(workspacesSelector);

  const {
    items: sharedWorkspaces,
    addItem,
    removeItem: removeFromCacheByWorkspaceId,
  } = useMailboxAccountsSharedWorkspaces(mailbox?.id);

  const workspaceSelectOption = transformWorkspacesToOptions(
    workspaces.filter((work) => !sharedWorkspaces?.some((shared) => shared.workspaceId === work.id))
  );

  const handleSelectWorkspace = ({ value }) => {
    if (!sharedWorkspaces.some((shared) => value === shared.id)) {
      const foundWorkspace = workspaces.find((workspace) => workspace.id === value);
      if (foundWorkspace !== null) {
        addAccountsToWorkspaceApi(foundWorkspace.id, [mailbox.id]).then(() => {
          dispatch(addNotification({ title: 'Email was successfully shared', type: 'success' }));
          addItem({
            id: mailbox.id,
            workspaceId: foundWorkspace.id,
            workspaceTitle: foundWorkspace.title,
            workspaceLogoUrl: foundWorkspace.logoUrl,
          } as MailboxAccountWorkspaceType);
          updateMailboxSettings(mailbox.id, {
            ...mailbox,
            workspaceIdsList: [...mailbox.workspaceIdsList, foundWorkspace.id],
          });
        });
      }
    }
  };

  const handleRemoveShared = (workspaceId: number) => {
    removeAccountsFromWorkspaceApi(workspaceId, [mailbox.id]).then(() => {
      dispatch(addNotification({ title: 'Removed from workspace', type: 'success' }));
      removeFromCacheByWorkspaceId(workspaceId);
      updateMailboxSettings(mailbox.id, {
        ...mailbox,
        workspaceIdsList: mailbox.workspaceIdsList.filter((work: number) => work !== workspaceId),
      });
    });
  };

  return (
    <BaseModal isOpen={isOpen} onClose={onClose}>
      <div className="share-email-modal">
        <div className="share-email-modal__header">
          <div className="share-email-modal__title">Sharing</div>
          <div className="share-email-modal__title-email">
            <div className="share-email-modal__title-email-icon">
              <SVGIcon icon="envelope" />
            </div>
            {mailbox.email}
          </div>
        </div>
        <div className="share-email-modal__body">
          <Loader isLoading={workspacesLs !== loadingStatuses.LOADED} withTopMargin>
            <Select
              className="share-email-modal__workspace-select"
              onChange={handleSelectWorkspace}
              placeholder="Workspace name"
              value={null}
              options={workspaceSelectOption}
              additionalComponents={{ Control }}
            />
          </Loader>
          {sharedWorkspaces?.map(({ workspaceId, workspaceTitle }) => (
            <div className="share-email-modal__workspace-row workspace-row" key={workspaceId}>
              <div className="workspace-row__title">{workspaceTitle}</div>
              <div
                className="workspace-row__remove-icon"
                onClick={() => handleRemoveShared(workspaceId)}
              >
                <SVGIcon icon="crossBlack" />
              </div>
            </div>
          ))}
        </div>
        <div className="share-email-modal__footer">
          <Button onClick={onClose}>Accept</Button>
        </div>
      </div>
    </BaseModal>
  );
}

export default ShareEmailModal;
