import React, { ChangeEventHandler } from 'react';

type FormTextFieldType = {
  label: string;
  name: string;
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  type?: string;
  additionalStyles?: { [key: string]: string };
  [key: string]: any;
};

const FormTextField = ({
  label,
  name,
  value,
  onChange,
  type = 'text',
  additionalStyles,
  ...rest
}: FormTextFieldType) => {
  return (
    <div className="form-text-field">
      <div className="profile-settings__label">{label}</div>
      <input
        className="profile-settings__input"
        style={additionalStyles}
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        {...rest}
      />
    </div>
  );
};

export default FormTextField;
