import { RefObject, useEffect } from 'react';

export default <T extends HTMLElement>(contentWrapperRef: RefObject<T>): void => {
  useEffect(() => {
    if (contentWrapperRef && contentWrapperRef.current) {
      // @ts-ignore
      const allLinks = contentWrapperRef.current.querySelectorAll('a');
      allLinks.forEach((link) => link.setAttribute('target', '_blank'));
    }
  }, [contentWrapperRef]);
};
