import React, { useEffect } from 'react';
import { useQuery } from 'react-query';

import Loader from '@uikit/Loader/Loader';
import { EmailReportContent } from '@components/Insights/EmailReport/EmailReport';
import { getInsightsByCriteriaApi } from '@api/analytics.api';
import { InsightsFilterField } from 'respona_api/generated/analytics_pb';

function CampaignInsights({
  campaignId,
  workspaceId,
  dateRange,
  RenderHeader,
}: {
  campaignId: number;
  workspaceId: number;
  dateRange: any;
  RenderHeader: () => JSX.Element;
}): JSX.Element {
  const { data, isFetching, refetch } = useQuery(
    ['campaign-insights', campaignId, workspaceId],
    () =>
      getInsightsByCriteriaApi(
        [Number(campaignId)],
        InsightsFilterField.DROPDOWN_INSIGHT_CAMPAIGN_ID,
        workspaceId,
        dateRange
      ),
    {
      enabled: false,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (!workspaceId) {
      return;
    }

    if (!!data && dateRange.startDate === null) {
      return;
    }

    if (!data || data.dateFrom !== dateRange.startDate || data.dateTo !== dateRange.endDate) {
      refetch();
    }
  }, [dateRange, workspaceId]);

  return (
    <>
      <RenderHeader />
      <Loader isLoading={isFetching || !data} withTopMargin>
        <EmailReportContent insightsData={data} />
      </Loader>
    </>
  );
}

export default CampaignInsights;
