import React from 'react';

import cn from 'class-names';
import ReactTooltip from 'react-tooltip';
import { nanoid } from 'nanoid';

import { getVariableByValue } from '@constants/variables';

import './Variable.scss';
import { ReactEditor } from 'slate-react';
import { CustomVariableType } from 'respona_api/generated/common_pb';

const Variable = ({
  attributes,
  children,
  element,
  onChangeVariable,
  editor,
  transformVariable,
  disableVariable,
  allVariables,
  isTempVariable = false,
  tooltipText = 'Click to add fallback value',
}): JSX.Element => {
  const idForTooltip = nanoid();
  const { withFallback, type, id } = getVariableByValue(element.character, allVariables);
  const isFallbackEmpty =
    withFallback && !disableVariable && (!element.fallback || element.fallback.length === 0);

  const transformedValue = transformVariable(element.character, element.fallback, allVariables);

  const cnVariable = cn('slate-editor-variable', {
    'slate-editor-variable--empty': transformedValue?.length === 0,
    'slate-editor-variable--temp-variable': isTempVariable,
    'slate-editor-variable--clickable': withFallback,
    'slate-editor-variable--ai-variable': type === CustomVariableType.OPEN_AI,
  });

  const getSelectionForVariableClicking = (e) => {
    const { selection } = editor;

    const node = ReactEditor.toSlateNode(editor, e.target);

    const path = ReactEditor.findPath(e, node);

    return {
      focus: {
        offset: 0,
        path,
      },
      anchor: {
        offset: 0,
        path,
      },
    };

    if (transformedValue || !selection) {
      return selection;
    }

    /*
      For some reason when we click on empty variable,
      we got path[1] of previous element in path
      That is why we do plus 1
     */
    return {
      ...selection,
      anchor: {
        ...selection.anchor,
        path: [selection.anchor.path[0], selection.anchor.path[1] + 1, selection.anchor.path[2]],
      },
    };
  };

  const isDefaultAIVariable = (): boolean => {
    return id === -1 && type === CustomVariableType.OPEN_AI;
  };

  const handleClickOnVariable = (e) => {
    if (isDefaultAIVariable()) {
      return;
    }

    onChangeVariable(
      {
        fallback: element.fallback,
        selection: getSelectionForVariableClicking(e),
        variable: element.character,
        href:
          element.href && element.href === '@url'
            ? transformVariable('url', element.fallback, allVariables)
            : element.href,
        transformedValue,
      },
      withFallback
    );
  };

  const renderVar = () => (
    <>
      <span
        {...attributes}
        contentEditable={false}
        className={cnVariable}
        style={{
          cursor: 'pointer',
        }}
        onClick={handleClickOnVariable}
        tabIndex={0}
        role="button"
        data-for={idForTooltip}
        data-tip=""
      >
        {transformedValue}
        {children}
      </span>
      {isFallbackEmpty && !isDefaultAIVariable() ? (
        <ReactTooltip id={idForTooltip} effect="solid" wrapper="span" arrowColor="transparent">
          {tooltipText}
        </ReactTooltip>
      ) : null}
    </>
  );

  if (element.href) {
    return (
      <a className="link" href={element.href}>
        {renderVar()}
      </a>
    );
  }

  return renderVar();
};

export default Variable;
