import { Dispatch } from 'redux';

import { setPersonalization } from '@redux/actions/personalizations.actions';
import { removePersonalizationApi, updatePersonalizationApi } from '@api/personalizations.api';
import { uploadFile } from '@api/fileImport.api';
import { PersonalizationStepType } from '@ts/personalizations.types';
import {
  csSetActiveCampaignVerifyStatus,
  csUpdateCampaignSelectedOpportunitiesVerificationStatus,
} from '@redux/actions/campaignSettings.actions';

export const updatePersonalization =
  (step: PersonalizationStepType, templateId: number, opportunityId: number, campaignId: number) =>
  (dispatch: Dispatch) => {
    return updatePersonalizationApi(step.id, step, templateId, opportunityId, campaignId).then(
      (updatedPersonalizations) => {
        dispatch(setPersonalization(updatedPersonalizations));
        dispatch(
          csUpdateCampaignSelectedOpportunitiesVerificationStatus({ opportunityId, newStatus: 1 })
        );
        dispatch(csSetActiveCampaignVerifyStatus({ statusNumber: 0 }));

        return updatedPersonalizations; // Return this so that it can be accessed in handleSave
      }
    );
  };

export const uploadPersonalizationFile =
  (
    workspaceId: number,
    opportunityId: number,
    step: PersonalizationStepType,
    templateId: number,
    campaignId: number,
    file: File
  ) =>
  (dispatch) => {
    return file?.arrayBuffer()?.then((buffer) => {
      return uploadFile(campaignId, workspaceId, file, buffer).then((processResponse) => {
        // @ts-ignore
        const uploadedFileInfo = processResponse[processResponse.length - 1];

        return dispatch(
          updatePersonalization(
            {
              ...step,
              attachmentsList: [
                ...step.threadA.attachmentsList,
                {
                  key: uploadedFileInfo.uid,
                  title: uploadedFileInfo.title,
                  size: file.size,
                },
              ],
            },
            templateId,
            opportunityId,
            campaignId
          )
        );
      });
    });
  };

export const removePersonalizationRequest =
  (personalizationId: number, opportunityId: number, campaignId: number, workspaceId: number) =>
  (dispatch: Dispatch) => {
    return removePersonalizationApi(personalizationId, opportunityId, campaignId, workspaceId).then(
      (updatedPersonalizations) => {
        dispatch(csSetActiveCampaignVerifyStatus({ statusNumber: 0 }));
        dispatch(setPersonalization(updatedPersonalizations));
        dispatch(
          csUpdateCampaignSelectedOpportunitiesVerificationStatus({
            opportunityId,
            newStatus: 1,
          })
        );

        return updatedPersonalizations;
      }
    );
  };
