import { handleActions } from 'redux-actions';

import { setActiveOpportunity } from 'src/redux/actions/opportunities.actions';
import { OpportunityShortType } from '@ts/opportunity.types';

export default handleActions({
  [setActiveOpportunity]: (state: OpportunityShortType, { payload }): OpportunityShortType => ({
    ...state,
    ...payload,
  }),
}, {});
