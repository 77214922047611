import React, { useState } from 'react';

import BaseModal, { BaseModalType } from '@uikit/BaseModal/BaseModal';
import Select from '@uikit/Select/Select';
import Button from '@uikit/Button/Button';
import useWorkspaceMembers from '@hooks/useWorkspaceMembers';
import Loader from '@uikit/Loader/Loader';

import mapToOptionsByFields from '@utils/mapToOptionsByFields';

import './ChangeOwnerModal.scss';

function ChangeOwnerModal({
  ownedItems,
  onClose,
  isOpen,
  onConfirmChangeOwner,
  isLoading,
  workspaceMembers: workspaceMembersList,
}: BaseModalType & {
  ownedItems: { id: number; ownerId: number; title: string }[];
  onConfirmChangeOwner: (id: number) => void;
  isLoading?: boolean;
  workspaceMembers?: any[];
}): JSX.Element {
  const [newOwner, changeNewOwner] = useState<{ value: number; label: string }>();
  const { members, isFetching } = useWorkspaceMembers(workspaceMembersList?.length > 0);

  const workspaceMembers = workspaceMembersList?.length ? workspaceMembersList : members;

  const handleClose = () => {
    changeNewOwner(null);
    onClose();
  };

  const handleChangeOwner = () => {
    onConfirmChangeOwner(newOwner.value);
    handleClose();
  };

  if (ownedItems.length === 0) {
    return null;
  }

  const currentOwners = ownedItems.map(({ ownerId }) =>
    workspaceMembers.find(({ userId }) => userId === ownerId)
  );
  const campaignTitle = ownedItems.map(({ title }) => title).join(', ');
  const isDisabled = !newOwner || currentOwners.every(({ userId }) => newOwner.value === userId);
  const uniqOwnerNames = [...new Set(currentOwners.map((own) => own?.fullName || 'Unknown'))];

  return (
    <BaseModal isOpen={isOpen} onClose={handleClose} classNames={{ modal: 'change-owner-modal' }}>
      <div className="change-owner-modal__header">
        Change owner{' '}
        <span className="change-owner-modal__campaign-title" title={campaignTitle}>
          {campaignTitle}
        </span>
        <br />
      </div>
      <p className="change-owner-modal__subheader">
        Current owner is <span>{uniqOwnerNames.join(', ')}</span>
      </p>
      <div className="change-owner-modal__body">
        <Loader isLoading={isFetching} withTopMargin>
          <Select
            placeholder="Choose new owner"
            onChange={changeNewOwner}
            options={mapToOptionsByFields(workspaceMembers, 'userId', 'fullName')}
          />
        </Loader>
      </div>
      <div className="change-owner-modal__footer">
        <Button isLoading={isLoading} onClick={() => handleChangeOwner()} disabled={isDisabled}>
          Save
        </Button>
        <Button onClick={handleClose} isAdditional>
          Cancel
        </Button>
      </div>
    </BaseModal>
  );
}

export default ChangeOwnerModal;
