import React from 'react';

import { daysHours } from '@components/Settings/ScheduleEdition/config';

const HoursRow = () => {
  return (
    <div className="schedule-edition-days__grid-row schedule-edition-days-hours">
      <div />
      {daysHours.map((dayHour) => (
        <div key={`${dayHour}-am`}>
          <span className="schedule-edition-days-hours__hour">{dayHour}</span>
          <span className="schedule-edition-days-hours__day-time">AM</span>
        </div>
      ))}
      {daysHours.map((dayHour) => (
        <div key={`${dayHour}-pm`}>
          <span className="schedule-edition-days-hours__hour">{dayHour}</span>
          <span className="schedule-edition-days-hours__day-time">PM</span>
        </div>
      ))}
    </div>
  );
};

export default HoursRow;
