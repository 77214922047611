import React from 'react';

export default ({ color = '#fff' }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.666687 8C0.666687 7.63181 0.965164 7.33333 1.33335 7.33333H5.33335C5.55625 7.33333 5.76441 7.44473 5.88805 7.6302L7.02348 9.33333H8.97656L10.112 7.6302C10.2356 7.44473 10.4438 7.33333 10.6667 7.33333H14.6667C15.0349 7.33333 15.3334 7.63181 15.3334 8C15.3334 8.36819 15.0349 8.66667 14.6667 8.66667H11.0235L9.88805 10.3698C9.76441 10.5553 9.55625 10.6667 9.33335 10.6667H6.66669C6.44378 10.6667 6.23563 10.5553 6.11199 10.3698L4.97656 8.66667H1.33335C0.965164 8.66667 0.666687 8.36819 0.666687 8Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.82669 2L11.1734 2C11.5454 2.0002 11.9104 2.10419 12.2267 2.30028C12.5426 2.4962 12.7977 2.77629 12.9633 3.10912C12.9634 3.10941 12.9636 3.10971 12.9637 3.11L15.2628 7.70151C15.3092 7.79417 15.3334 7.89637 15.3334 8V12C15.3334 12.5304 15.1226 13.0391 14.7476 13.4142C14.3725 13.7893 13.8638 14 13.3334 14H2.66669C2.13625 14 1.62755 13.7893 1.25247 13.4142C0.877401 13.0391 0.666687 12.5304 0.666687 12V8C0.666687 7.89637 0.690845 7.79417 0.737242 7.70151L3.03633 3.11C3.03649 3.10968 3.03666 3.10935 3.03682 3.10903C3.20241 2.77624 3.45747 2.49618 3.77338 2.30028C4.0896 2.10419 4.4546 2.0002 4.82669 2ZM3.63335 3.40667L4.22946 3.70516L2.00002 8.15758V12C2.00002 12.1768 2.07026 12.3464 2.19528 12.4714C2.32031 12.5964 2.48988 12.6667 2.66669 12.6667H13.3334C13.5102 12.6667 13.6797 12.5964 13.8048 12.4714C13.9298 12.3464 14 12.1768 14 12V8.15758L11.7706 3.70516L11.7697 3.70333C11.7145 3.59226 11.6294 3.49879 11.524 3.43343C11.4186 3.36809 11.2971 3.33342 11.1731 3.33333H4.82691C4.70293 3.33342 4.58143 3.36808 4.47605 3.43343C4.37065 3.49879 4.28557 3.59226 4.23037 3.70333L3.63335 3.40667Z"
      fill={color}
    />
  </svg>
);
