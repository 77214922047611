import {
  FileUploadResponseType,
  ImportModeEnum,
  ParseFileFieldResponseType,
  ParseFileResponseType,
} from '@ts/fileImport.types';

import request from '@api/base/request';
import processStreamRequest from '@api/base/processStreamRequest';
import fileImporterService from '@api/services/fileImporterService';
import fileStorageService from '@api/services/fileStorageService';

import { FileResponseType } from '@ts/common.types';
import { ContactVariable } from 'respona_api/generated/common_pb';
import FileImporterModels from 'respona_api/generated/file-importer_pb';
import FileStorageModels from 'respona_api/generated/file-storage_pb';

export const uploadFile = (
  campaignId: number,
  workspaceId: number,
  file,
  bufferArray,
  processCallback?: any
): Promise<FileUploadResponseType[]> => {
  const client = fileStorageService.getInstance();
  const fileUploadRequest = new FileStorageModels.UploadRequest();

  if (campaignId) {
    fileUploadRequest.setOptionalCampaignId(campaignId);
  }
  fileUploadRequest.setWorkspaceId(workspaceId);
  fileUploadRequest.setTitle(file.name);
  fileUploadRequest.setFormat(file.type || 'text/csv');
  fileUploadRequest.setFileBytes(new Uint8Array(bufferArray));
  fileUploadRequest.setFileSize(file.size);

  return processStreamRequest<FileUploadResponseType[]>(
    client,
    client.upload,
    fileUploadRequest,
    processCallback
  );
};

export const buildStartImportApiRequest = (
  fileUid: string,
  campaignId: number,
  lastBatch: number,
  workspaceId: number,
  headers: ParseFileFieldResponseType[],
  delimiter,
  importMode: ImportModeEnum,
  customDelimiter,
  importSettings
): FileImporterModels.ImportRequest => {
  const importRequest = new FileImporterModels.ImportRequest();

  importRequest.setFileUid(fileUid);
  importRequest.setCampaignId(campaignId);
  importRequest.setBatch(lastBatch);
  importRequest.setWorkspaceId(workspaceId);
  importRequest.setMappedColumnsList(
    headers.map(({ title, mappedVariable, optionalCustomVariableId }) => {
      const headerModel = new FileImporterModels.ColumnHeader();
      const isCustomVariable: boolean = optionalCustomVariableId > 0;
      const mappedVariableValue = isCustomVariable
        ? ContactVariable.CUSTOM_VARIABLE
        : mappedVariable;

      headerModel.setTitle(title);
      headerModel.setMappedVariable(mappedVariableValue);
      if (isCustomVariable) {
        headerModel.setOptionalCustomVariableId(optionalCustomVariableId);
      }

      return headerModel;
    })
  );
  importRequest.setDelimiter(delimiter);
  importRequest.setImportmode(importMode);
  if (importMode === ImportModeEnum.OPPORTUNITY) {
    importRequest.setGroupContactsByDomain(false);
  } else {
    importRequest.setGroupContactsByDomain(importSettings.groupContactsByDomain);
  }
  importRequest.setOptionalOtherDelimiterValue(customDelimiter);
  importRequest.setRemoveDuplicated(importSettings.removeDuplicated);
  importRequest.setRemoveActiveContacts(importSettings.removeActiveContacts);
  importRequest.setRemoveContactedInPast(importSettings.removeContactedInPast);
  if (importSettings.removeContactedInPast) {
    importRequest.setOptionalContactedPastDays(importSettings.daysInThePast);
  }
  importRequest.setRemoveUnsubscribed(importSettings.removeUnsubscribed);

  return importRequest;
};

export const parseFileApi = (
  campaignId,
  workspaceId,
  uid,
  delimiter,
  otherDelimiterValue
): Promise<ParseFileResponseType> => {
  const client = fileImporterService.getInstance();
  const parseRequest = new FileImporterModels.ParseFileRequest();

  parseRequest.setCampaignId(campaignId);
  parseRequest.setWorkspaceId(workspaceId);
  parseRequest.setDelimiter(delimiter);
  parseRequest.setOptionalOtherDelimiterValue(otherDelimiterValue);
  parseRequest.setFileUid(uid);

  return request<ParseFileResponseType>(client, client.parse, parseRequest);
};

export const downloadImportReportApi = (
  campaignId: number,
  pipelineId: number
): Promise<FileResponseType> => {
  const client = fileStorageService.getInstance();
  const downloadImportReportRequest = new FileStorageModels.DownloadImportReportRequest();

  downloadImportReportRequest.setCampaignId(campaignId);
  downloadImportReportRequest.setPipelineId(pipelineId);

  return request<FileResponseType>(
    client,
    client.downloadImportReport,
    downloadImportReportRequest
  );
};

export const uploadImageApi = (file, bufferArray): Promise<FileUploadResponseType> => {
  const client = fileStorageService.getInstance();
  const imageUploadRequest = new FileStorageModels.UploadImageRequest();

  imageUploadRequest.setFileBytes(new Uint8Array(bufferArray));
  imageUploadRequest.setFileSize(file.size);

  return request<FileUploadResponseType>(client, client.uploadImage, imageUploadRequest);
};
