import React, { useState } from 'react';

import './SwitcherInsight.scss';
import { SVGIcon, SvgIconType } from '@uikit/Icon/Icon';

const SwitcherInsight = ({
  className,
  handlerChangeSwitcher,
  isActiveSwitch,
}: {
  className?: string;
  handlerChangeSwitcher?: (isActiveSwitch) => void;
  isActiveSwitch?: boolean;
}): JSX.Element => {
  const [toggleOn, setToggleOn] = useState(false);
  const toggleBtn = () => {
    setToggleOn(!toggleOn);

    return toggleOn;
  };

  return (
    <div
      className={`switcher ${className || ''}`}
      onClick={() => handlerChangeSwitcher(isActiveSwitch)}
    >
      <div className="switcher_item">
        <SVGIcon icon="rateGraphic" />
      </div>
      <div className="switcher_item">
        <SVGIcon icon="curveGraph" />
      </div>
      <span className={`switcher_toggler ${isActiveSwitch ? 'active' : ''}`}>
        {isActiveSwitch ? (
          <SVGIcon icon="curveGraph" color="#221CB6" />
        ) : (
          <SVGIcon icon="rateGraphic" color="#221CB6" />
        )}
      </span>
    </div>
  );
};

export default SwitcherInsight;
