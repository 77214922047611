import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import PageHeader from '@components/PageHeader/PageHeader';
import InsightsCampaignHeader from '@components/Insights/InsightsCampaignHeader/InsightsCampaignHeader';

import { getAllWorkspacesShortApi } from '@api/userOrganization.api';

import { insightsCreditsUsageByUserFiltersSelector } from '@redux/selectors/insights.selector';
import { setInsightsCreditsUsageByUserFilters } from '@redux/actions/insights.actions';
import useWorkspaceMembers from '@hooks/useWorkspaceMembers';
import DetailedCreditsUsage from '@components/Insights/DetailedCreditsUsage/DetailedCreditsUsage';

const CreditsUsageByUserFilterOptions = (
  workspaces: { value: string | number; label: string }[] = [],
  users: { value: string | number; label: string }[] = []
): {
  label: string;
  value: number;
  type: 'string' | 'number' | 'boolean' | 'enum' | 'enumString' | 'stringNotEmpty';
  inputOptions?: { value: string | number; label: string }[];
}[] => [
  {
    label: 'Workspace',
    value: 0,
    type: 'enumString',
    inputOptions: workspaces,
  },
  {
    label: 'User',
    value: 2,
    type: 'enumString',
    inputOptions: users,
  },
];

function CreditsUsage(): JSX.Element {
  const [workspaces, setWorkspaces] = useState([]);
  const [isFilterOptionsLoading, setFilterOptionsLoading] = useState(true);
  const filtersFromRedux = useSelector(insightsCreditsUsageByUserFiltersSelector);
  const { members, isFetching: isFetchingMembers } = useWorkspaceMembers();

  useEffect(() => {
    Promise.all([getAllWorkspacesShortApi()]).then(([workspacesResult]) => {
      const workspaceOptions = workspacesResult.map(({ id, title }) => ({
        value: id,
        label: title,
      }));

      setWorkspaces(workspaceOptions);
      setFilterOptionsLoading(false);
    });
  }, []);

  const users = members.map(({ userId, fullName }) => ({ value: userId, label: fullName }));
  const filterFields = CreditsUsageByUserFilterOptions(workspaces, users);

  return (
    <>
      <PageHeader title="Credits Usage">
        <div className="campaign-report-header">
          <InsightsCampaignHeader
            filterFields={filterFields}
            filterAction={setInsightsCreditsUsageByUserFilters}
            filtersValues={filtersFromRedux}
            isFilterOptionsLoading={isFilterOptionsLoading || isFetchingMembers}
          />
        </div>
      </PageHeader>
      <DetailedCreditsUsage />
    </>
  );
}

export default CreditsUsage;
