import { useQuery, useQueryClient } from 'react-query';
import { QueryCollectionHookType } from '@ts/common.types';
import { MailboxAccountType } from '@ts/mailboxAccounts.types';
import { getAccountsByWorkspaceIdApi } from '@api/mailboxAccounts.api';

export default (workspaceId: number): QueryCollectionHookType<MailboxAccountType> => {
  const queryClient = useQueryClient();

  const key = ['mailbox-accounts-by-workspace', workspaceId];

  const { data, isFetching, isLoading, isRefetching, refetch, isSuccess } = useQuery<
    MailboxAccountType[]
  >(key, () => getAccountsByWorkspaceIdApi(workspaceId), {
    enabled: workspaceId != null && workspaceId > 0,
    refetchOnWindowFocus: false,
    placeholderData: [],
    staleTime: 15 * 60 * 1000,
  });

  const updateCache = (updater: (cachedData: MailboxAccountType[]) => MailboxAccountType[]) => {
    queryClient.setQueryData<MailboxAccountType[]>(key, updater);
  };

  const removeFromCacheById = (id: number) => {
    updateCache((cachedData = []) => cachedData.filter((item) => item.id !== id));
  };

  const addToCache = (item: MailboxAccountType) => {
    updateCache((cachedData = []) => [...cachedData, item]);
  };

  const updateInCache = (id: number, payload: Partial<MailboxAccountType>) => {
    updateCache((cachedData = []) =>
      cachedData.map((item) => (item.id === id ? { ...item, ...payload } : item))
    );
  };

  const evictCache = () => queryClient.removeQueries(key);

  const isDataLoading = isFetching || isLoading || isRefetching;

  return {
    items: data,
    refetch,
    isLoading: isDataLoading,
    isEmpty: isSuccess && data?.length === 0,
    removeItem: removeFromCacheById,
    addItem: addToCache,
    updateItem: updateInCache,
    evictByKey: evictCache,
  };
};
