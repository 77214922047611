import React from 'react';

export default ({ color = '#251FB6', size= 12 }) => (
  <svg width={size} height={size} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.5 2H2.5C2.10218 2 1.72064 2.15804 1.43934 2.43934C1.15804 2.72064 1 3.10218 1 3.5V8.5C1 8.89782 1.15804 9.27936 1.43934 9.56066C1.72064 9.84196 2.10218 10 2.5 10H9.5C9.89782 10 10.2794 9.84196 10.5607 9.56066C10.842 9.27936 11 8.89782 11 8.5V3.5C11 3.10218 10.842 2.72064 10.5607 2.43934C10.2794 2.15804 9.89782 2 9.5 2ZM9.295 3L6.355 5.94C6.30852 5.98686 6.25322 6.02406 6.19229 6.04945C6.13136 6.07483 6.06601 6.0879 6 6.0879C5.93399 6.0879 5.86864 6.07483 5.80771 6.04945C5.74678 6.02406 5.69148 5.98686 5.645 5.94L2.705 3H9.295ZM10 8.5C10 8.63261 9.94732 8.75979 9.85355 8.85355C9.75979 8.94732 9.63261 9 9.5 9H2.5C2.36739 9 2.24021 8.94732 2.14645 8.85355C2.05268 8.75979 2 8.63261 2 8.5V3.705L4.94 6.645C5.22125 6.9259 5.6025 7.08368 6 7.08368C6.3975 7.08368 6.77875 6.9259 7.06 6.645L10 3.705V8.5Z"
      fill={color}
    />
  </svg>
);
