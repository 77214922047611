import { TemplateStepTransformedType, TemplateStepType } from '@ts/template.types';
import { emptyEditorHtml } from 'src/constants/html.constants';

export const campaignStatusMap = {
  0: 'success', // 'PREPARED',
  1: 'success', // 'LAUNCHED',
  2: 'paused',
  3: 'success', // 'REMOVED',
  4: 'success', // 'FINISHED',
  5: 'success', // 'STOPPED',
  100: 'success', // 'INFO',
  101: 'success', // 'ERROR',
};

export const mapSequenceStepApiInfo = (step: TemplateStepType): TemplateStepTransformedType => ({
  ...step,
  subject: step.threadA.sameThread ? emptyEditorHtml : step.threadA.subject,
  content: step.threadA.content,
  isSameThread: step.threadA.sameThread,
  attachmentsList: step.threadA.attachmentsList,
});
