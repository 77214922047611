import { checkCampaignHasPerosnalizationsApi } from '@api/campaign.api';
import EditReferralContentModal from '@pages/CampaignCreationPage/_components/EditLinkContentModal/EditReferralContentModal';
import EditUnsubscribeContentModal from '@pages/CampaignCreationPage/_components/EditLinkContentModal/EditUnsubscribeContentModal';

import Checkbox from '@uikit/Checkbox/Checkbox';
import ConfirmModal from '@uikit/ConfirmModal/ConfirmModal';
import { SVGIcon } from '@uikit/Icon/Icon';
import React, { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { csSetSequenceAdditionalContent } from '@redux/actions/sequence.actions';
import {
  createSequenceStep,
  deleteSequenceStep,
  updateSequenceStepContent,
  updateSequenceStepDelay,
  updateSequenceStepSubject,
} from '@redux/thunks/sequence.thunks';

import { sequenceSelector } from '@redux/selectors/sequence.selectors';
import { getActiveCampaignInfo } from '@redux/selectors/campaignSettings.selectors';
import Loader from '@uikit/Loader/Loader';
import loadingStatuses from '@constants/loadingStatuses';

import { getCurrentWorkspaceId } from '@redux/selectors/workspaces.selectors';
import { useQueryAllTemplates } from '@queries/templates.queries';
import { addNotification } from '@redux/actions/notifications.actions';
import { TemplateStepTransformedType } from '@ts/template.types';
import stripHtml from '@utils/stripHtml';
import {
  changeUnsubscribeContentStateOfTemplateApi,
  saveAsNewTemplateApi,
} from '@api/templates.api';
import { applyTemplateForSequence } from '@redux/thunks/template.thunks';
import TemplateSelect from '@components/CampaignSettings/TemplateSelect/TemplateSelect';
import SequenceSteps from '@components/CampaignSettings/SequenceSteps/SequenceSteps';
import SequenceEditor from '@components/CampaignSettings/SequenceEditor/SequenceEditor';
import SaveSequenceTemplateModal from '@pages/SequenceCampaignSettingsPage/_components/SaveSequenceTemplateModal/SaveSequenceTemplateModal';

import './SequenceCampaignSettingsPage.scss';
import { DispatchType } from 'src/store';
import useReferralLink from '@hooks/useReferralLink';

function SequenceCampaignSettingsPage(): JSX.Element {
  const dispatch = useDispatch<DispatchType>();

  const [isUpdateUnsubscribeContentModalOpen, setUpdateUnsubscribeContentModalOpen] =
    useState<boolean>(false);
  const [isUpdateReferralContentModalOpen, setUpdateReferralContentModalOpen] =
    useState<boolean>(false);

  const [confirmationPopupAction, setConfirmationPopupAction] = useState<() => any>(null);

  const currentWorkspaceId = useSelector(getCurrentWorkspaceId);
  const { info: campaignInfo } = useSelector(getActiveCampaignInfo);
  const {
    steps: sequenceSteps,
    loadingStatus: sequenceLoadingStatus,
    enabledUnsubscribeContent,
    enabledReferralContent,
  } = useSelector(sequenceSelector);

  const { data: templates, refetch } = useQueryAllTemplates(0, 100);

  const [isSaveSequenceTemplateModalOpen, changeIsSaveSequenceTemplateModalOpen] = useState(false);
  const [sequenceTemplateTitle, changeSequenceTemplateTitle] = useState('');
  const [openedStepIndex, changeOpenedStepIndex] = useState(0);
  const [isUpdatingUnsubscribeSettings, setIsUpdatingUnsubscribeSettings] =
    useState<boolean>(false);

  const { item: referralContentData } = useReferralLink();

  const handleChangeDelay = (step: TemplateStepTransformedType) => {
    if (sequenceSteps.find(({ id }) => id === step.id)?.id === 0) {
      dispatch(addNotification({ title: 'Please fill step first', type: 'error' }));
    } else {
      dispatch(updateSequenceStepDelay(campaignInfo.lastSequenceId, step, step.sendDelay));
    }
  };

  const handleUpdateTemplateStepSubject = (step: TemplateStepTransformedType) => {
    return dispatch(updateSequenceStepSubject(campaignInfo.lastSequenceId, step));
  };

  const handleUpdateTemplateStepContent = (step: TemplateStepTransformedType) => {
    return dispatch(updateSequenceStepContent(campaignInfo.lastSequenceId, step));
  };

  const handleRemove = () => {
    if (openedStepIndex !== 0) {
      changeOpenedStepIndex(openedStepIndex - 1);
    }
    dispatch(deleteSequenceStep(campaignInfo.lastSequenceId, sequenceSteps, openedStepIndex));
  };

  const handleCreateStep = () => {
    const lastStep = sequenceSteps[sequenceSteps.length - 1];

    if (sequenceSteps.length === 1) {
      if (stripHtml(lastStep.subject).trim() === '') {
        return dispatch(addNotification({ title: 'Fill first step subject', type: 'error' }));
      }

      if (stripHtml(lastStep.content).trim() === '') {
        return dispatch(addNotification({ title: 'Fill first step content', type: 'error' }));
      }
    }

    if (stripHtml(lastStep.content).trim() === '') {
      return dispatch(
        addNotification({ title: 'Last step has empty subject or content', type: 'error' })
      );
    }

    dispatch(createSequenceStep(campaignInfo.lastSequenceId)).then(() => {
      changeOpenedStepIndex(sequenceSteps.length);
    });
  };

  const handleSaveAsTemplate = () => {
    saveAsNewTemplateApi(
      sequenceTemplateTitle,
      currentWorkspaceId,
      campaignInfo.lastSequenceId
    ).then(() => {
      refetch();
    });
    toggleSaveSequenceTemplateModal();
    dispatch(addNotification({ title: 'Template saved', type: 'success' }));
  };

  const handleApplyTemplate = async (newTemplateId: number, force = false) => {
    // Need to change index to prevent situation when such index do not exist in template

    if (!force) {
      const hasPersonalizations = await checkCampaignHasPerosnalizationsApi(
        campaignInfo.id,
        currentWorkspaceId,
        campaignInfo.lastBatch
      );

      if (hasPersonalizations.value) {
        setConfirmationPopupAction(() => () => {
          dispatch(
            applyTemplateForSequence(campaignInfo.lastSequenceId, newTemplateId, currentWorkspaceId)
          ).finally(() => setConfirmationPopupAction(null));
        });
        return;
      }
    }

    changeOpenedStepIndex(0);
    dispatch(
      applyTemplateForSequence(campaignInfo.lastSequenceId, newTemplateId, currentWorkspaceId)
    );
  };

  const handleUpdateUnsubscribeContent = async ({
    target: { checked: enabledUnsubscribeContent },
  }) => {
    setIsUpdatingUnsubscribeSettings(true);

    changeUnsubscribeContentStateOfTemplateApi(
      campaignInfo.lastSequenceId,
      enabledUnsubscribeContent
    )
      .then(() => {
        dispatch(csSetSequenceAdditionalContent({ enabledUnsubscribeContent }));
      })
      .finally(() => setIsUpdatingUnsubscribeSettings(false));
  };

  const toggleSaveSequenceTemplateModal = () =>
    changeIsSaveSequenceTemplateModalOpen((prevState) => !prevState);

  if (sequenceLoadingStatus !== loadingStatuses.LOADED) {
    return <Loader isLoading withTopMargin />;
  }

  const isSequenceEmpty =
    !sequenceSteps[openedStepIndex]?.content.replace(/<[^>]+>/g, '') &&
    !sequenceSteps[openedStepIndex]?.subject.replace(/<[^>]+>/g, '');

  return (
    <>
      <div className="sequence">
        <div className="sequence__steps" style={{ overflow: 'visible' }}>
          <TemplateSelect
            templates={templates}
            onSaveTemplate={toggleSaveSequenceTemplateModal}
            onApplyTemplate={handleApplyTemplate}
          />
          <SequenceSteps
            openedStepIndex={openedStepIndex}
            sequenceSteps={sequenceSteps}
            onOpenStep={changeOpenedStepIndex}
            onChangeDelay={handleChangeDelay}
            onCreateStep={handleCreateStep}
          />
        </div>
        <div className="sequence__editor">
          <SequenceEditor
            key={sequenceSteps[openedStepIndex] && sequenceSteps[openedStepIndex].id}
            onRemove={handleRemove}
            sequence={sequenceSteps[openedStepIndex]}
            subjects={sequenceSteps.map(({ subject }) => subject)}
            stepIndex={openedStepIndex}
            onSaveContent={handleUpdateTemplateStepContent}
            onSaveSubject={handleUpdateTemplateStepSubject}
            referralContent={enabledReferralContent ? referralContentData : null}
          />
          <div className="sequence__unsubscribe-content">
            <div className="sequence__unsubscribe-content-row">
              <Checkbox
                disabled={isUpdatingUnsubscribeSettings || isSequenceEmpty}
                onChange={handleUpdateUnsubscribeContent}
                value={enabledUnsubscribeContent}
              >
                <span style={{ marginLeft: '8px' }}>Insert an unsubscribe link in the emails</span>
              </Checkbox>
              <span
                className="sequence__edit-icon"
                onClick={() => setUpdateUnsubscribeContentModalOpen(true)}
              >
                <SVGIcon icon="pen" color="#221CB6" size={16} />
              </span>
            </div>
          </div>
        </div>
        <SaveSequenceTemplateModal
          onConfirm={handleSaveAsTemplate}
          isOpen={isSaveSequenceTemplateModalOpen}
          onClose={toggleSaveSequenceTemplateModal}
          changeSequenceTemplateTitle={changeSequenceTemplateTitle}
          sequenceTemplateTitle={sequenceTemplateTitle}
        />
      </div>
      <EditUnsubscribeContentModal
        isOpen={isUpdateUnsubscribeContentModalOpen}
        onClose={() => setUpdateUnsubscribeContentModalOpen(false)}
        enabled
      />

      <EditReferralContentModal
        isOpen={isUpdateReferralContentModalOpen}
        onClose={() => setUpdateReferralContentModalOpen(false)}
        content={referralContentData?.content}
      />

      <ConfirmModal
        isOpen={confirmationPopupAction != null}
        onConfirm={() => confirmationPopupAction()}
        onClose={() => {
          setConfirmationPopupAction(null);
        }}
        confirmButtonText="Change template"
        cancelButtonText="Keep template"
        isConfirmButtonMain={false}
        title="You have personalized opportunities"
        text="Selecting another sequence template will reset all personalizations. Are you sure you’d like to change the template?"
      />
    </>
  );
}

export default SequenceCampaignSettingsPage;
