import campaignService from '@api/services/campaignsService';
import request from '@api/base/request';
import stripHtml from '@utils/stripHtml';
import CampaignModels from '../../respona_api/generated/campaign_pb';
import CommonModels from '../../respona_api/generated/common_pb';

const createOpportunityPersonalizationUpdateRequest = (
  id: number,
  step,
  templateId: number,
  opportunityId: number,
  campaignId: number
) => {
  // @ts-ignore
  const updateRequest = new CampaignModels.OpportunityPersonalizationUpdateRequest();
  // @ts-ignore
  const threadRequest = new CampaignModels.PersonalizationThreadRequest();
  threadRequest.setSubject(step.subject);
  threadRequest.setContent(step.content);
  threadRequest.setSameThread(stripHtml(step.subject)?.trim() === '' && step.order !== 1);
  threadRequest.setEmailsToCcList(step.emailsToCcList);
  threadRequest.setEmailsToBccList(step.emailsToBccList);
  if (step?.attachmentsList) {
    threadRequest.setAttachmentsList(
      step.attachmentsList.map((attachment) => {
        const fileAttachmentModel = new CommonModels.FileAttachment();
        fileAttachmentModel.setKey(attachment.key);
        fileAttachmentModel.setTitle(attachment.title);
        fileAttachmentModel.setSize(attachment.size);

        return fileAttachmentModel;
      })
    );
  }

  updateRequest.setCampaignId(campaignId);
  updateRequest.setId(id);
  updateRequest.setType(0);
  updateRequest.setThread(threadRequest);
  updateRequest.setTemplateId(templateId);
  updateRequest.setTemplateStepId(step.stepId);
  updateRequest.setOpportunityId(opportunityId);

  return updateRequest;
};

export const updatePersonalizationApi = (
  id: number,
  step,
  templateId: number,
  opportunityId: number,
  campaignId: number
) => {
  const service = campaignService.getInstance();
  const updateRequest = createOpportunityPersonalizationUpdateRequest(
    id,
    step,
    templateId,
    opportunityId,
    campaignId
  );

  return request(service, service.createOrUpdatePersonalization, updateRequest);
};

type RemovePersonalizationApiType = (
  personalizationId: number,
  opportunityId: number,
  campaignId: number,
  workspaceId: number
) => Promise<void>;

export const removePersonalizationApi: RemovePersonalizationApiType = (
  personalizationId: number,
  opportunityId: number,
  campaignId: number,
  workspaceId: number
) => {
  const service = campaignService.getInstance();
  const removePersonalizationRequest = new CampaignModels.OpportunityPersonalizationDeleteRequest();

  removePersonalizationRequest.setId(personalizationId);
  removePersonalizationRequest.setOpportunityId(opportunityId);
  removePersonalizationRequest.setCampaignId(campaignId);
  removePersonalizationRequest.setWorkspaceId(workspaceId);

  return request(service, service.removePersonalization, removePersonalizationRequest);
};
