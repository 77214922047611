import Display from '@components/Display/Display';
import React, { useEffect, useState } from 'react';
import cn from 'class-names';

import useHotkeys from '@hooks/useHotkeys';

import { SVGIcon } from '@uikit/Icon/Icon';

import CollapsibleSidebarButton from '@components/CollapsibleSidebar/_components/CollapsibleSidebarButton';

const CollapsibleSidebarWrapper = ({
  children,
  fixed,
  title,
  initiallyOpen = false,
}: {
  children: React.ReactNode;
  initiallyOpen: boolean;
  fixed?: boolean;
  title?: string;
  // position: 'left' | 'right'
}): JSX.Element => {
  const [isOpen, setOpen] = useState(initiallyOpen);

  const toggleIsOpen = () => setOpen((prevState) => !prevState);

  useHotkeys({
    Escape: () => setOpen(false),
  });

  useEffect(() => {
    if (initiallyOpen !== isOpen) {
      setOpen(initiallyOpen);
    }
  }, [initiallyOpen]);

  const cnContainer = cn('collapsible-sidebar', {
    'collapsible-sidebar--fixed': fixed,
    'collapsible-sidebar--opened': isOpen,
    'collapsible-sidebar--fixed--opened': fixed && isOpen,
  });

  return (
    <div className={cnContainer}>
      <Display isVisible={title !== null}>
        <div className="collapsible-sidebar__header">
          <div className="collapsible-sidebar__title">
            <span className="collapsible-sidebar__close-btn" onClick={toggleIsOpen}>
              <SVGIcon icon="arrowLeftCircle" />
            </span>

            <span>{title}</span>
          </div>
        </div>
      </Display>

      <CollapsibleSidebarButton isOpen={isOpen} onClick={toggleIsOpen} />

      <div style={{ height: '100vh' }}>{children}</div>
    </div>
  );
};

export default CollapsibleSidebarWrapper;
