import React from 'react';

export default ({ color = '#C4C6CD', size = 16 }: { color?: string; size?: number }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.66699 8.66648C6.95329 9.04923 7.31856 9.36594 7.73803 9.59511C8.15749 9.82428 8.62133 9.96056 9.09809 9.9947C9.57485 10.0288 10.0534 9.96006 10.5012 9.793C10.9491 9.62594 11.3557 9.36453 11.6937 9.02648L13.6937 7.02648C14.3009 6.39781 14.6368 5.5558 14.6292 4.68181C14.6216 3.80782 14.2711 2.97178 13.6531 2.35375C13.035 1.73573 12.199 1.38516 11.325 1.37757C10.451 1.36997 9.609 1.70595 8.98033 2.31315L7.83366 3.45315"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.33347 7.33332C9.04716 6.95057 8.68189 6.63387 8.26243 6.40469C7.84297 6.17552 7.37913 6.03924 6.90237 6.0051C6.4256 5.97095 5.94708 6.03974 5.49924 6.2068C5.0514 6.37386 4.64472 6.63527 4.3068 6.97332L2.3068 8.97332C1.69961 9.60199 1.36363 10.444 1.37122 11.318C1.37881 12.192 1.72938 13.028 2.3474 13.646C2.96543 14.2641 3.80147 14.6146 4.67546 14.6222C5.54945 14.6298 6.39146 14.2938 7.02013 13.6867L8.16013 12.5467"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
