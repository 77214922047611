import React from 'react';

export default ({ color = '#2822B7' }: { color?: string }): JSX.Element => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.14132 7.16063C8.80571 7.16063 10.155 5.81138 10.155 4.147C10.155 2.48261 8.80571 1.13336 7.14132 1.13336C5.47694 1.13336 4.12769 2.48261 4.12769 4.147C4.12769 5.81138 5.47694 7.16063 7.14132 7.16063Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.81098 9.87625C7.59132 9.85214 7.36764 9.83942 7.14129 9.83942C3.81222 9.83942 1.11401 12.5376 1.11401 15.8667H10.4898"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.1777 15.197L13.2496 13.269"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.8292 13.8576C12.9387 13.8576 13.8383 12.9581 13.8383 11.8485C13.8383 10.7389 12.9387 9.83942 11.8292 9.83942C10.7196 9.83942 9.82007 10.7389 9.82007 11.8485C9.82007 12.9581 10.7196 13.8576 11.8292 13.8576Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
