import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { EntityRelationRequest } from 'respona_api/generated/common_pb';

import BaseModal from '@uikit/BaseModal/BaseModal';
import Select from '@uikit/Select/Select';
import Button from '@uikit/Button/Button';
import Display from '@components/Display/Display';

import { folderToOptionsMap, transformFoldersToOptions } from '@mappers/optionTransformers';
import qs from 'query-string';

import { addNotification } from '@redux/actions/notifications.actions';

import { companiesFoldersSelector } from '@redux/selectors/companies.selectors';

import {
  moveCompaniesToFolderRequest,
  removeCompaniesFromFolderRequest,
} from '@redux/thunks/peopleCompany.thunks';

import './RelationshipsMoveToFolderModal.scss';

// TODO: We can connect this modal with PeopleMoveToFolderModal in one component

const OrganizationsMoveToFolderModal = ({ itemToMove, onClose }): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { folderId } = qs.parse(history.location.search);
  const { items: folders } = useSelector(companiesFoldersSelector);
  const [selectedFolder, changeSelectedFolder] = useState(null);

  const closeModal = () => {
    onClose();
    changeSelectedFolder(null);
  };

  const handleCompanyMovingConfirm = () => {
    if (!selectedFolder) return;
    /*
      If we are in all organizations -> just show notification, we cant filter list
     */
    if (!folderId) {
      moveCompaniesToFolderRequest(
        selectedFolder.id,
        [itemToMove.id],
        EntityRelationRequest.SelectionType.SELECTED
      ).then(() => {
        dispatch(
          addNotification({
            title: `Organization was moved to the "${selectedFolder.title}" folder`,
            type: 'success',
          })
        );
        closeModal();
      });
    }

    /*
      If we are in folder -> just filter list in UI to see result
     */
    if (folderId && selectedFolder && selectedFolder.id !== Number(folderId)) {
      moveCompaniesToFolderRequest(
        selectedFolder.id,
        [itemToMove.id],
        EntityRelationRequest.SelectionType.SELECTED
      ).then(() => closeModal());
    }
  };

  const handleCompanyRemoveFromFolder = () =>
    removeCompaniesFromFolderRequest(
      dispatch,
      selectedFolder,
      [itemToMove.id],
      EntityRelationRequest.SelectionType.SELECTED
    ).then(() => {
      closeModal();
    });

  useEffect(() => {
    if (folders.length > 0 && folderId) {
      const selectedFolderInfo = folders.find(({ id }) => id === Number(folderId));

      changeSelectedFolder(folderToOptionsMap(selectedFolderInfo));
    }
  }, [folders]);

  return (
    <BaseModal onClose={onClose} isOpen={itemToMove !== null}>
      <div className="relationships-move-modal">
        <div className="relationships-move-modal__header">
          Move
          <div className="relationships-move-modal__campaign-title">{itemToMove.title}</div>
          to folder
        </div>
        <div className="relationships-move-modal__selector">
          <Select
            value={selectedFolder}
            onChange={changeSelectedFolder}
            options={transformFoldersToOptions(folders)}
            placeholder="Select folder"
          />
        </div>

        <div className="relationships-move-modal__footer">
          <Display isVisible={!!folderId}>
            <Button type="secondary" onClick={handleCompanyRemoveFromFolder}>
              Remove from folder
            </Button>
          </Display>
          <Button onClick={handleCompanyMovingConfirm}>Move</Button>
        </div>
      </div>
    </BaseModal>
  );
};

export default OrganizationsMoveToFolderModal;
