import React from 'react';
import { PRIMARY_COLOR } from '@constants/colors';

export default ({ color = PRIMARY_COLOR }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25ZM0.75 9C0.75 4.44365 4.44365 0.75 9 0.75C13.5563 0.75 17.25 4.44365 17.25 9C17.25 13.5563 13.5563 17.25 9 17.25C4.44365 17.25 0.75 13.5563 0.75 9Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.75 9C0.75 8.58579 1.08579 8.25 1.5 8.25H16.5C16.9142 8.25 17.25 8.58579 17.25 9C17.25 9.41421 16.9142 9.75 16.5 9.75H1.5C1.08579 9.75 0.75 9.41421 0.75 9Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.75017 9C6.80158 11.3061 7.59469 13.5264 9 15.3369C10.4053 13.5264 11.1984 11.3061 11.2498 9C11.1984 6.69387 10.4053 4.47363 9 2.6631C7.59469 4.47363 6.80158 6.69387 6.75017 9ZM9 1.5L8.44624 0.994183C6.44767 3.18218 5.31188 6.02164 5.25016 8.98438C5.24995 8.99479 5.24995 9.00521 5.25016 9.01562C5.31188 11.9784 6.44767 14.8178 8.44624 17.0058C8.58834 17.1614 8.78931 17.25 9 17.25C9.21069 17.25 9.41166 17.1614 9.55376 17.0058C11.5523 14.8178 12.6881 11.9784 12.7498 9.01562C12.7501 9.00521 12.7501 8.99479 12.7498 8.98438C12.6881 6.02164 11.5523 3.18218 9.55376 0.994183L9 1.5Z"
      fill={color}
    />
  </svg>
);
