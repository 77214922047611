import React from 'react';
import {
  ResponsiveContainer,
  ComposedChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  Tooltip,
} from 'recharts';

import './VerticalComposedChart.scss';
import { TeamMembersInsightsType } from '@ts/insights.types';

export default function ({
  itemsList,
  dataKey,
  selectedValue,
  selectedLabel,
}: {
  itemsList: [];
  dataKey: string;
  selectedValue: string;
  selectedLabel: string;
}): JSX.Element {
  const tablePadding = 50;
  const rowHeight = 50;
  const height = itemsList.length * rowHeight + tablePadding;
  const sortedData = itemsList.sort((a, b) => b[selectedValue] - a[selectedValue]);
  const tickFormatter = (value: string) => {
    const limit = 20; // put your maximum character
    if (value.length < limit) {
      return value;
    }
    return `${value.substring(0, limit)}...`;
  };

  return (
    <ResponsiveContainer width="98%" height={height}>
      <ComposedChart
        layout="vertical"
        width={500}
        height={height}
        data={sortedData}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 65,
        }}
      >
        <CartesianGrid vertical={false} horizontal={false} stroke="#f5f5f5" />
        <XAxis tickLine={false} axisLine={false} type="number" tick={{ fill: '#CFD4DB' }} />
        <YAxis
          tickMargin={20}
          tickLine={false}
          axisLine={false}
          tick={{ fill: '#1B1E22' }}
          dataKey={dataKey}
          type="category"
          width={120}
          tickFormatter={tickFormatter}
        />
        <Tooltip />
        <Bar
          name={selectedLabel}
          dataKey={selectedValue}
          barSize={14}
          fill="#221CB6"
          radius={[5, 5, 5, 5]}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}
