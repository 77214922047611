import React from 'react';

export default ({ color = '#C4C6CD' }) => (
  <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.73998 4.95483L2.02332 0.246499C1.94585 0.168392 1.85368 0.106397 1.75213 0.0640893C1.65058 0.0217821 1.54166 0 1.43165 0C1.32164 0 1.21272 0.0217821 1.11117 0.0640893C1.00962 0.106397 0.917452 0.168392 0.839983 0.246499C0.684774 0.402634 0.597656 0.613844 0.597656 0.833999C0.597656 1.05415 0.684774 1.26536 0.839983 1.4215L4.96498 5.58817L0.839983 9.71317C0.684774 9.8693 0.597656 10.0805 0.597656 10.3007C0.597656 10.5208 0.684774 10.732 0.839983 10.8882C0.917162 10.9669 1.0092 11.0295 1.11076 11.0725C1.21233 11.1154 1.32139 11.1377 1.43165 11.1382C1.54191 11.1377 1.65097 11.1154 1.75254 11.0725C1.8541 11.0295 1.94614 10.9669 2.02332 10.8882L6.73998 6.17983C6.82457 6.1018 6.89208 6.00709 6.93825 5.90167C6.98442 5.79625 7.00826 5.68242 7.00826 5.56733C7.00826 5.45225 6.98442 5.33841 6.93825 5.23299C6.89208 5.12758 6.82457 5.03287 6.73998 4.95483Z"
      fill={color}
    />
  </svg>
);
