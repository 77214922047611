import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { WebsiteType } from '@ts/company.types';
import { PersonBaseType, RelationshipPipelineOpportunityType } from '@ts/people.types';

import { getPipelineOpportunitiesByWebsiteIdApi } from '@api/website.api';

import { getDateShort } from '@utils/date';
import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';

import { SVGIcon } from '@uikit/Icon/Icon';
import Loader from '@uikit/Loader/Loader';

import ContactsSidebarCRMSection from '@components/ContactSidebarCRM/_components/ContactsSidebarCRMSection/ContactsSidebarCRMSection';

import './ContactsSidebarCRMSectionOpportunities.scss';
import { CustomVariableType, CustomVariableValueRelatedTo } from 'respona_api/generated/common_pb';
import useCustomVariables from '@hooks/useCustomVariables';
import useCustomVariablesValues from '@hooks/useCustomVariablesValues';
import {
  CustomVariablePlaceholderType,
  CustomVariableSelectItemValueType,
  CustomVariableSelectType,
  CustomVariableValueType,
} from '@ts/customVariales.types';
import { fromNumberAhrefFormatHelper } from '@helpers/transformDataHelpers';
import { addNotification } from '@redux/actions/notifications.actions';
import Checkbox from '@uikit/Checkbox/Checkbox';
import HeaderDateFilter from '@uikit/HeaderDateFilter/HeaderDateFilter';
import Select from '@uikit/Select/Select';
import CustomAvatar from '@components/CustomAvatar/CustonAvatar';
import CustomOptionAvatar from '@components/CustomOptionAvatar/CustomOptionAvatar';
import Rating from 'react-rating';
import { transformCustomVariableOptionsToOptions } from '@mappers/optionTransformers';
import MultiValueContainer from '@components/CustomFieldsSidebar/_components/MultiValueContainer';
import SidebarCRMSectionEditableField from '@components/SidebarCRM/sections/SidebarCRMSection/_components/SidebarCRMSectionEditableField/SidebarCRMSectionEditableField';
import useWorkspaceMembers from '@hooks/useWorkspaceMembers';
import { SelectCustomVariableSettings } from 'respona_api/generated/people_pb';
import { useDispatch } from 'react-redux';
import { SelectOptionType } from '@ts/common.types';
import { createOrUpdateCustomVariableValueApi } from '@api/customVariables.api';
import DealsSidebar from '@components/Relationships/WebsitesSidebar/_components/DealsSidebar/DealsSidebar';
import { PipelineOpportunityType } from '@ts/relationshipPipeline.types';

function ContactsSidebarCRMSectionOpportunities({
  website,
  person,
  index,
}: {
  website?: WebsiteType;
  person?: PersonBaseType;
  index: number;
}) {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const workspaceId = useCurrentWorkspaceId();
  const websiteId = person?.website?.id || website?.id;
  const { members } = useWorkspaceMembers();

  const [isOpenCreateNewSideBar, setOpenCreateNewSideBar] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [showVariablesForPipelineId, setShowVariablesForPipelineId] = useState<number>(-1);
  const { customVariables } = useCustomVariables(
    CustomVariableValueRelatedTo.RELATIONSHIP_OPPORTUNITY
  );
  const customVariablesByType = useMemo(
    () =>
      customVariables?.filter(
        (it) => it.relatedTo === CustomVariableValueRelatedTo.RELATIONSHIP_OPPORTUNITY
      ) || [],
    [customVariables]
  );

  const variableNames = useMemo(
    () => customVariablesByType.map((it) => it.name),
    [customVariablesByType]
  );

  const {
    items: valuesData,
    refetch: refetchCustomVariableValues,
    isLoading: isLoadingCustomVariablesValues,
    addItem: addVariableValueToCache,
  } = useCustomVariablesValues(variableNames, [websiteId], false);

  const queryPipelinesKey = useMemo(
    () => ['contacts-sidebar-opportunities', workspaceId, websiteId],
    [workspaceId, websiteId]
  );

  const { data, isLoading } = useQuery<RelationshipPipelineOpportunityType[]>(
    queryPipelinesKey,
    () => getPipelineOpportunitiesByWebsiteIdApi(websiteId, workspaceId),
    {
      enabled: workspaceId > 0 && websiteId > 0,
      refetchOnWindowFocus: false,
      staleTime: 5 * (60 * 1000),
      onSettled: (data) => !!data?.length && ref.current.setOpen(true),
    }
  );

  const onClickShowDetails = (pipelineId: number) => {
    if (pipelineId === showVariablesForPipelineId) {
      setShowVariablesForPipelineId(-1);
    } else {
      setShowVariablesForPipelineId(pipelineId);
      refetchCustomVariableValues();
    }
  };

  const onOpenCallback = useCallback(() => {
    // let's try to how it works without it
    // queryClient.invalidateQueries(queryPipelinesKey);
  }, [queryClient, workspaceId, website?.id]);

  const handleSaveOpportunity = useCallback(
    (opportunity: PipelineOpportunityType) => {
      // TODO incorrect key - looks like correct :-)
      queryClient.setQueryData(queryPipelinesKey, (cachedData: PipelineOpportunityType[]) => {
        if (!cachedData) {
          return [opportunity];
        }
        return [opportunity, ...cachedData];
      });
    },
    [queryClient]
  );

  function composeAssignedUserById(assignedToUserId: number): string {
    if (assignedToUserId > 0) {
      return members.find((it) => it.userId === assignedToUserId)?.fullName || 'Deleted user';
    }

    return 'Unassigned';
  }

  const assignList = useMemo(() => {
    const sortedMembers = members
      .sort((a, b) => Number(b.isMe) - Number(a.isMe))
      .map((member) => ({
        value: member.userId,
        label: member.isMe ? 'Assign to me' : member.fullName,
        logoUrl: member.logoUrl,
      }));

    return [
      {
        value: -1,
        label: 'Unassigned',
        logoUrl: '',
      },
      ...sortedMembers,
    ];
  }, [members]);

  const renderEditableField = (
    key: string,
    initialValue: string,
    onSave: (newValue: string) => void,
    renderContent: (value: string) => string
  ) => (
    <React.Fragment key={key}>
      <div
        className="contacts-sidebar-section-variables-variable-info-label w-100"
        key={`label-${key}`}
      >
        {key}
      </div>
      <SidebarCRMSectionEditableField
        key={`value-${key}`}
        initialValue={initialValue}
        onSave={(_, newValue) => onSave(newValue)}
        renderContent={renderContent}
        extraRowClass="padding-top-none"
      />
    </React.Fragment>
  );

  const composeOptionsFromSettings = (settings: SelectCustomVariableSettings.AsObject) => {
    return settings.itemsList
      .sort((a, b) => (a.order <= b.order ? -1 : 1))
      .map((it) => {
        return { label: it.name, value: it.uid, color: it.color };
      });
  };

  const handleSaveVariable = useCallback(
    (newVariable: CustomVariableValueType) => {
      createOrUpdateCustomVariableValueApi(newVariable).then(() => {
        addVariableValueToCache(newVariable);
      });
    },
    [queryClient]
  );

  const handleSaveStringVariable = useCallback(
    (newInput: string, variable: CustomVariablePlaceholderType, value: CustomVariableValueType) => {
      const newVariable = value
        ? { ...value, stringValue: newInput }
        : ({
            variableId: variable.id,
            variableName: variable.name,
            type: variable.type,
            relatedTo: variable.relatedTo,
            relatedId: websiteId,
            stringValue: newInput,
          } as CustomVariableValueType);
      handleSaveVariable(newVariable);
    },
    [handleSaveVariable, workspaceId]
  );

  function enrichOptionsWithColor(
    options: SelectOptionType[],
    select: SelectCustomVariableSettings.AsObject
  ) {
    if (options?.length > 0) {
      return options.map((opt) => {
        const object = select.itemsList.find((it) => it.uid === opt.value);
        return { ...opt, color: object?.color };
      });
    }

    return [];
  }

  const handleSaveSelectVariable = (
    newInput: { label: string; value: string }[],
    variable: CustomVariablePlaceholderType,
    value: CustomVariableValueType
  ) => {
    const select = {
      valuesList: newInput.map((it) => {
        return { uid: it.value, value: it.label } as CustomVariableSelectItemValueType;
      }),
    } as CustomVariableSelectType;
    const newVariable = value
      ? ({ ...value, selectValue: select } as CustomVariableValueType)
      : ({
          variableId: variable.id,
          variableName: variable.name,
          type: variable.type,
          relatedTo: variable.relatedTo,
          relatedId: websiteId,
          stringValue: null,
          selectValue: select,
        } as CustomVariableValueType);
    handleSaveVariable(newVariable);
  };

  const renderVariable = useCallback(
    (item: CustomVariablePlaceholderType) => {
      const variableValue = valuesData?.find((it) => it.variableId === item.id);

      switch (item.type) {
        case CustomVariableType.TEXT:
          return renderEditableField(
            item.title,
            variableValue?.stringValue || '',
            (newValue) => handleSaveStringVariable(newValue, item, variableValue),
            (value) => (value?.length ? value : '-')
          );

        case CustomVariableType.INT:
          return renderEditableField(
            item.title,
            variableValue?.stringValue || '',
            (newValue) => handleSaveStringVariable(newValue, item, variableValue),
            (value) => (Number.isNaN(value) ? '-' : fromNumberAhrefFormatHelper(Number(value)))
          );

        case CustomVariableType.URL:
          return renderEditableField(
            item.title,
            variableValue?.stringValue || '',
            (newValue) => {
              const urlPattern = /^(https?:\/\/)?([\w.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
              if (urlPattern.test(newValue)) {
                handleSaveStringVariable(newValue, item, variableValue);
              } else {
                dispatch(addNotification({ title: 'Invalid URL format', type: 'warning' }));
              }
            },
            (value) => (value?.length ? value : '-')
          );

        case CustomVariableType.CHECKBOX:
          return (
            <div
              key={item.id}
              className="contacts-sidebar-section-variables-variable-info-label w-100"
            >
              <div>{item.title}</div>
              <Checkbox
                value={variableValue?.stringValue === 'true'}
                onChange={({ target: { checked } }) =>
                  handleSaveStringVariable(checked.toString(), item, variableValue)
                }
              />
            </div>
          );

        case CustomVariableType.DATE_AND_TIME:
          return (
            <div
              key={item.id}
              className="contacts-sidebar-section-variables-variable-info-label w-100"
            >
              <div className="contacts-sidebar-section-variables-variable-info-label w-100">
                {item.title}
              </div>
              <HeaderDateFilter
                value={{
                  startDate: Number.isNaN(variableValue?.stringValue)
                    ? null
                    : Number(variableValue?.stringValue),
                }}
                onChangeDatarange={(value) =>
                  handleSaveStringVariable(
                    value.startDate.getTime().toString(),
                    item,
                    variableValue
                  )
                }
                isRangePicker={false}
                id={item.id.toString()}
                skipDayCheck
              />
            </div>
          );

        case CustomVariableType.TEAM_MEMBER: {
          const composeAssignee = (userIdValue: string) => {
            const assignedUserId = Number(userIdValue);
            const user = assignList.find((it) => it.value === assignedUserId);
            if (user) {
              return {
                value: assignedUserId,
                label:
                  user.label === 'Assign to me'
                    ? members.find(({ userId }) => userId === user.value).fullName
                    : user.label,
                logoUrl: user.logoUrl,
              };
            }
            if (assignedUserId) {
              return {
                value: assignedUserId,
                label: 'Removed User',
                logoUrl: '',
              };
            }
            return {
              value: -1,
              label: 'Unassigned',
              logoUrl: '',
            };
          };
          return (
            <div key={item.id} style={{ width: '100%' }}>
              <div className="contacts-sidebar-section-variables-variable-info-label w-100">
                {item.title}
              </div>
              <div className="contacts-sidebar-section-variables-variable-info-select">
                <Select
                  openMenuOnFocus
                  classNamePrefix="assign"
                  placeholder="Assign user"
                  onChange={(value) =>
                    handleSaveStringVariable(value.value.toString(), item, variableValue)
                  }
                  value={composeAssignee(variableValue?.stringValue)}
                  options={assignList}
                  additionalComponents={{ Control: CustomAvatar, Option: CustomOptionAvatar }}
                  additionalStyles={{
                    control: (provided) => ({
                      ...provided,
                      minHeight: '40px',
                      borderRadius: '8px',
                      paddingLeft: '8px',
                    }),
                    option: () => ({
                      display: 'flex',
                      gridColumnGap: '8px',
                      alignItems: 'center',
                      width: '100%',
                      color: 'white',
                      padding: '8px 12px',
                      '&:hover, &:focus': {
                        backgroundColor: '#181818',
                      },
                    }),
                  }}
                />
              </div>
            </div>
          );
        }

        case CustomVariableType.RATING: {
          return (
            <div
              key={item.id}
              className="contacts-sidebar-section-variables-variable-info-label w-100"
            >
              <div className="contacts-sidebar-section-variables-variable-info-label w-100">
                {item.title}
              </div>
              <Rating
                emptySymbol={<SVGIcon icon="star" color="#BDBDBD" />}
                fullSymbol={<SVGIcon icon="fullStar" />}
                initialRating={
                  Number.isNaN(variableValue?.stringValue) ? 0 : Number(variableValue?.stringValue)
                }
                onChange={(value) =>
                  handleSaveStringVariable(value.toString(), item, variableValue)
                }
              />
            </div>
          );
        }

        case CustomVariableType.SELECT: {
          const values = transformCustomVariableOptionsToOptions(
            variableValue?.selectValue?.valuesList
          );
          const coloredValue = enrichOptionsWithColor(values, item.select);
          const initialValue = coloredValue?.length ? values[0] : null;

          return (
            <div
              key={item.id}
              className="contacts-sidebar-section-variables-variable-info-label w-100"
            >
              <div className="contacts-sidebar-section-variables-variable-info-label w-100">
                {item.title}
              </div>
              <div className="contacts-sidebar-section-variables-variable-info-select">
                <Select
                  options={composeOptionsFromSettings(item.select)}
                  value={initialValue}
                  onChange={(opt) => handleSaveSelectVariable([opt], item, variableValue)}
                />
              </div>
            </div>
          );
        }

        case CustomVariableType.MULTI_SELECT: {
          const values = transformCustomVariableOptionsToOptions(
            variableValue?.selectValue?.valuesList
          );
          const coloredValues = enrichOptionsWithColor(values, item.select);

          return (
            <div
              key={item.id}
              className="contacts-sidebar-section-variables-variable-info-label w-100"
            >
              <div className="contacts-sidebar-section-variables-variable-info-label w-100">
                {item.title}
              </div>
              <div className="contacts-sidebar-section-variables-variable-info-select">
                <Select
                  isMulti
                  isClearable={false}
                  visibleMultiCount={3}
                  options={composeOptionsFromSettings(item.select)}
                  value={coloredValues}
                  onChange={(opt) => handleSaveSelectVariable(opt, item, variableValue)}
                  additionalComponents={{ MultiValueContainer }}
                />
              </div>
            </div>
          );
        }

        default:
          return <div />;
      }
    },
    [valuesData, handleSaveStringVariable, assignList, members]
  );

  return (
    <ContactsSidebarCRMSection
      ref={ref}
      index={index}
      id="opportunities"
      title="Deals"
      onOpen={onOpenCallback}
      rightComponent={
        <div
          className="contact-sidebar-crm__content__add-btn"
          onClick={() => {
            setOpenCreateNewSideBar(true);
          }}
        >
          <SVGIcon icon="plusIcon" size={10} color="#221CB6" />
          Add
        </div>
      }
    >
      {isOpenCreateNewSideBar ? (
        <DealsSidebar
          extraClass="contacts-sidebar"
          isOpen={isOpenCreateNewSideBar}
          itemId={websiteId}
          onSave={handleSaveOpportunity}
          onClose={() => {
            setOpenCreateNewSideBar(false);
          }}
        />
      ) : null}
      <div className="contacts-sidebar-section-opportunities">
        <Loader isLoading={isLoading} withTopMargin>
          {data?.length > 0 ? (
            data
              .slice(0, isExpanded ? undefined : 3)
              .map((opportunity: RelationshipPipelineOpportunityType) => (
                <div
                  key={opportunity.pipelineId}
                  className="contacts-sidebar-section-opportunities__opportunity"
                >
                  <div
                    className="contacts-sidebar-section-opportunities__opportunity-title"
                    onClick={() =>
                      window.open(`/relationships/pipelines?id=${opportunity.pipelineId}`, '_blank')
                    }
                  >
                    <span>{opportunity.pipelineTitle}</span>
                    <SVGIcon icon="externalLink" color="#221CB6" size={12} />
                  </div>
                  <div className="contacts-sidebar-section-opportunities__opportunity-status">
                    <SVGIcon icon="flag" color="#C4C6CD" size={12} />
                    <span>{opportunity.stageTitle}</span>
                  </div>
                  <div className="contacts-sidebar-section-opportunities__opportunity-status">
                    <SVGIcon icon="user" color="#C4C6CD" size={12} />
                    <span>{composeAssignedUserById(opportunity.assignedToUserId)}</span>
                  </div>
                  <div className="contacts-sidebar-section-opportunities__opportunity-date">
                    <SVGIcon icon="link" color="#C4C6CD" size={12} />
                    {opportunity.createdAt > 0
                      ? getDateShort(new Date(opportunity.createdAt))
                      : '-'}
                  </div>
                  {customVariablesByType.length &&
                  showVariablesForPipelineId === opportunity.pipelineId ? (
                    <Loader isLoading={isLoadingCustomVariablesValues}>
                      {customVariablesByType.map(renderVariable)}
                    </Loader>
                  ) : null}
                  {customVariablesByType.length > 0 && (
                    <div
                      className="contact-sidebar-crm__show-more-btn"
                      onClick={() => onClickShowDetails(opportunity.pipelineId)}
                    >
                      {showVariablesForPipelineId === opportunity.pipelineId
                        ? 'Show Less'
                        : 'Show More'}
                      <SVGIcon icon="chevron" size={8} />
                    </div>
                  )}
                </div>
              ))
          ) : (
            <div className="contacts-sidebar-section-opportunities--empty">Empty</div>
          )}

          {!isExpanded && data?.length > 3 && (
            <div className="contact-sidebar-crm__show-more-btn" onClick={() => setIsExpanded(true)}>
              Show More
              <SVGIcon icon="chevron" size={8} />
            </div>
          )}
        </Loader>
      </div>
    </ContactsSidebarCRMSection>
  );
}

export default ContactsSidebarCRMSectionOpportunities;
