import { DomainSearchKeywordsType } from 'src/types/automationContacts.types';
import { WorkspaceMemberType } from '@ts/workspace.types';
import { CustomVariableSelectItemValueType } from '@ts/customVariales.types';
import { SelectOptionType } from '@ts/common.types';

export const transformWorkspacesToOptions = (workspaces) =>
  workspaces.map((workspace) => ({
    ...workspace,
    value: workspace.id,
    label: workspace.title,
  }));

export const folderToOptionsMap = (folder) => ({
  ...folder,
  value: folder?.id,
  label: folder?.title,
});

export const transformFoldersToOptions = (folders) => {
  if (folders == null) return [];
  return folders.map(folderToOptionsMap);
};

export const transformPositionOrSeniorityList = (list: DomainSearchKeywordsType[]) =>
  list.map(({ keyword }) => ({
    label: keyword,
    value: keyword,
  }));

export const transformEmailToOptions = (emails) =>
  emails.map((email) => ({
    ...email,
    label: email.email,
    value: email.id,
  }));

export const transformCustomVariableOptionsToOptions = (
  options: CustomVariableSelectItemValueType[]
): SelectOptionType<string>[] =>
  options?.map((it) => {
    return { label: it.value, value: it.uid };
  }) || [];

export const transformWorkspaceMemberToOptions = (members: WorkspaceMemberType[]) =>
  members.map((member) => ({
    ...member,
    label: member.email,
    value: member.userId,
  }));

export const commonOptionsTransformer = (items, valueKey = 'id', labelKey = 'title') =>
  items.map((item) => ({
    ...item,
    value: item[valueKey],
    label: item[labelKey],
  }));
