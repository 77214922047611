import React from 'react';

export default ({ color = '#ED483D' }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.44444 12.8C6.65072 12.8 6.84855 12.7157 6.99442 12.5657C7.14028 12.4157 7.22222 12.2122 7.22222 12V7.2C7.22222 6.98783 7.14028 6.78434 6.99442 6.63432C6.84855 6.48429 6.65072 6.4 6.44444 6.4C6.23816 6.4 6.04033 6.48429 5.89447 6.63432C5.74861 6.78434 5.66667 6.98783 5.66667 7.2V12C5.66667 12.2122 5.74861 12.4157 5.89447 12.5657C6.04033 12.7157 6.23816 12.8 6.44444 12.8ZM14.2222 3.2H11.1111V2.4C11.1111 1.76348 10.8653 1.15303 10.4277 0.702944C9.99011 0.252856 9.39662 0 8.77778 0H7.22222C6.60338 0 6.00989 0.252856 5.57231 0.702944C5.13472 1.15303 4.88889 1.76348 4.88889 2.4V3.2H1.77778C1.5715 3.2 1.37367 3.28429 1.22781 3.43431C1.08194 3.58434 1 3.78783 1 4C1 4.21217 1.08194 4.41566 1.22781 4.56569C1.37367 4.71571 1.5715 4.8 1.77778 4.8H2.55556V13.6C2.55556 14.2365 2.80139 14.847 3.23897 15.2971C3.67656 15.7471 4.27005 16 4.88889 16H11.1111C11.7299 16 12.3234 15.7471 12.761 15.2971C13.1986 14.847 13.4444 14.2365 13.4444 13.6V4.8H14.2222C14.4285 4.8 14.6263 4.71571 14.7722 4.56569C14.9181 4.41566 15 4.21217 15 4C15 3.78783 14.9181 3.58434 14.7722 3.43431C14.6263 3.28429 14.4285 3.2 14.2222 3.2ZM6.44444 2.4C6.44444 2.18783 6.52639 1.98434 6.67225 1.83431C6.81811 1.68429 7.01594 1.6 7.22222 1.6H8.77778C8.98406 1.6 9.18189 1.68429 9.32775 1.83431C9.47361 1.98434 9.55556 2.18783 9.55556 2.4V3.2H6.44444V2.4ZM11.8889 13.6C11.8889 13.8122 11.8069 14.0157 11.6611 14.1657C11.5152 14.3157 11.3174 14.4 11.1111 14.4H4.88889C4.68261 14.4 4.48478 14.3157 4.33892 14.1657C4.19306 14.0157 4.11111 13.8122 4.11111 13.6V4.8H11.8889V13.6ZM9.55556 12.8C9.76184 12.8 9.95967 12.7157 10.1055 12.5657C10.2514 12.4157 10.3333 12.2122 10.3333 12V7.2C10.3333 6.98783 10.2514 6.78434 10.1055 6.63432C9.95967 6.48429 9.76184 6.4 9.55556 6.4C9.34928 6.4 9.15145 6.48429 9.00558 6.63432C8.85972 6.78434 8.77778 6.98783 8.77778 7.2V12C8.77778 12.2122 8.85972 12.4157 9.00558 12.5657C9.15145 12.7157 9.34928 12.8 9.55556 12.8Z"
      fill={color}
    />
  </svg>
);
