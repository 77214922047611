import { handleActions } from 'redux-actions';
import { nanoid } from 'nanoid';

import { addNotification, removeNotification } from '@redux/actions/notifications.actions';

import { NotificationType } from '@ts/notifications.types';

type NotificationsStateItem = { id: string } & NotificationType;

export type NotificationsStateType = NotificationsStateItem[];

const initialState: NotificationsStateType = [];

const defaultNotification: NotificationType = {
  title: '',
  type: 'info',
  delay: 4000,
};

export default handleActions(
  {
    [addNotification]: (
      state: NotificationsStateType,
      { payload }: { payload: Partial<NotificationsStateItem> }
    ) => [
      ...state,
      {
        ...defaultNotification,
        ...payload,
        id: nanoid(),
      },
    ],
    [removeNotification]: (state: NotificationsStateType, { payload }) =>
      state.filter(({ id }) => id !== payload),
  },
  initialState
);
