import { setInsightsDateFilter } from '@redux/actions/insights.actions';
import React, { useState, useCallback, useRef, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BarChart,
  LineChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { useQuery } from 'react-query';
import { DispatchType } from 'src/store';

import useNonInitialEffect from '@hooks/useNonInitialEffect';

import PieChart from 'src/uikit/PieChart/PieChart';
import BooleanToggler from 'src/uikit/BooleanToggler/BooleanToggler';
import { SVGIcon } from 'src/uikit/Icon/Icon';

import { InsightsResponseType } from '@ts/insights.types';
import { getCurrentWorkspaceId } from '@redux/selectors/workspaces.selectors';
import { getInsightsByCriteriaApi } from '@api/analytics.api';

import Loader from '@uikit/Loader/Loader';
import { PRIMARY_COLOR } from '@constants/colors';
import {
  insightsDateFilterSelector,
  insightsFiltersSelector,
} from '@redux/selectors/insights.selector';
import InsightsHeaderActions from '@components/Insights/InsightsHeaderActions/InsightsHeaderActions';
import PageHeader from '@components/PageHeader/PageHeader';

import { InsightsTypeMapper } from '@mappers/insights.mapper';
import { dateRangeNumberFormatToString } from '@helpers/dateTimeRangeHelpers';
import capitalizeAndSplitKeysTitleInObject from '@utils/capitalizeAndSplitKeysTitleInObject';

import { sortByDateString } from '@components/Insights/EmailReport/emailReportHelpers';

import './EmailReport.scss';
import ColoredCheckbox from '@components/Insights/ColoredCheckbox/ColoredCheckbox';

const dataKeysMap = {
  'Delivery Number': 'Delivery rate',
  'Open Number': 'Open rate',
  'Reply Number': 'Reply rate',
};

const renderLegend = ({ payload }) => (
  <ul className="email-report-chart-legend">
    {payload.map(({ value, dataKey, color }) => (
      <li
        className="email-report-chart-legend__item"
        style={{ '--legend-item-color': color } as React.CSSProperties}
        key={`item-${dataKey}`}
      >
        {dataKeysMap[value]}
      </li>
    ))}
  </ul>
);

export const InsightCard = ({
  title,
  value,
  percentageFromTotal,
  color,
  type,
  size,
  isChart = true,
  isCheckbox,
  chartField,
  checkboxPosition,
  isChecked,
  handleChangeCheckbox,
}: {
  title: string;
  value: number;
  percentageFromTotal: number;
  color?: string;
  type?: string;
  size?: number;
  isChart?: boolean;
  isCheckbox?: boolean;
  chartField?: string;
  checkboxPosition?: string;
  isChecked?: boolean;
  handleChangeCheckbox?: (chartField) => void;
}): JSX.Element => {
  let formattedValue = '';
  const strValue = value?.toString() || '';

  for (let i = strValue.length; i >= 0; i -= 3) {
    const start = i - 3 >= 0 ? i - 3 : 0;
    formattedValue = `${strValue.substring(start, i)} ${formattedValue}`;
  }

  formattedValue = formattedValue.trim();

  return (
    <div className={`analytics-email-report-card ${type === 'basic' ? 'custom-style' : ''}`}>
      {isCheckbox && (
        <ColoredCheckbox
          color={color}
          isChecked={isChecked}
          chartField={chartField}
          position={checkboxPosition}
          handleChange={handleChangeCheckbox}
        />
      )}
      <span className={`analytics-email-report-card__title ${type === 'basic' ? 'title-base' : ''}`}>
        {title}
      </span>
      {isChart ? (
        <div
          className={`${
            type === 'basic' ? 'analytics-report-container-basic' : 'analytics-report-container'
          }`}
        >
          <div className="analytics-email-report-card__diagram">
            <PieChart size={size} value={percentageFromTotal} color={color} name="title" />
          </div>
          <div
            className={`${
              type === 'basic'
                ? 'analytics-report-container-basic-values'
                : 'analytics-report-container-values'
            }`}
          >
            <span
              className="analytics-email-report-card__value"
              style={{ '--card-color': type === 'basic' ? '#000000' : color } as React.CSSProperties}
            >
              {percentageFromTotal}%
            </span>
            <span className="analytics-email-report-card__total">{formattedValue}</span>
          </div>
        </div>
      ) : (
        <span className="analytics-email-report-card__basic">{formattedValue}</span>
      )}
    </div>
  );
};

export const EmailReportContent = ({
  insightsData,
  RenderHeader,
}: {
  insightsData: InsightsResponseType;
  RenderHeader?: () => JSX.Element;
}) => {
  const dispatch = useDispatch<DispatchType>();

  const dateFilter = useSelector(insightsDateFilterSelector);

  const [isBarChart, changeIsBarChart] = useState(true);

  const dateRange = dateRangeNumberFormatToString(
    {
      startDate: insightsData.dateFrom,
      endDate: insightsData.dateTo,
    },
    false
  );

  const dailyStatisticsList = useMemo(() => {
    return insightsData.dailyStatisticsList
      .sort(sortByDateString)
      .map(capitalizeAndSplitKeysTitleInObject);
  }, [insightsData.dailyStatisticsList]);

  useEffect(() => {
    if (dateFilter?.startDate === null && !!insightsData?.dateFrom) {
      dispatch(
        setInsightsDateFilter({
          startDate: +insightsData.dateFrom,
          endDate: +insightsData.dateTo,
        })
      );
    }
  }, [insightsData, dateFilter]);

  return (
    <>
      {RenderHeader ? <RenderHeader /> : null}
      <div className="analytics-email-report">
        <div className="analytics-email-report__cards-row">
          <InsightCard
            title="Delivery rate"
            value={insightsData.totalDeliveryNumber}
            color="#2BA4CC"
            percentageFromTotal={insightsData.totalDeliveryRate}
            size={43}
          />
          <InsightCard
            title="Open rate"
            value={insightsData.totalOpenNumber}
            color={PRIMARY_COLOR}
            percentageFromTotal={insightsData.totalOpenRate}
            size={43}
          />
          <InsightCard
            title="Reply rate"
            value={insightsData.totalReplyNumber}
            color="#FC8619"
            percentageFromTotal={insightsData.totalReplyRate}
            size={43}
          />
        </div>

        {insightsData.dailyStatisticsList.length > 0 ? (
          <div className="analytics-email-report-daily-chart">
            <div className="analytics-email-report-daily-chart__header">
              <div className="analytics-email-report-daily-chart__title">
                Email performance over time
              </div>
              <div className="analytics-email-report-daily-chart__chart-toggler">
                <BooleanToggler
                  value={isBarChart}
                  onChange={changeIsBarChart}
                  renderTruthly={(isActive) => (
                    <SVGIcon icon="barChart" color={isActive ? PRIMARY_COLOR : '#C4C4C4'} />
                  )}
                  renderFalsy={(isActive) => (
                    <SVGIcon icon="lineChart" color={isActive ? PRIMARY_COLOR : '#C4C4C4'} />
                  )}
                />
              </div>
            </div>
            <div className="analytics-email-report-daily-chart__chart">
              <ResponsiveContainer width="100%" height={300}>
                {isBarChart ? (
                  <BarChart data={dailyStatisticsList}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <YAxis />
                    <Tooltip />
                    <Legend content={renderLegend} iconType="circle" />
                    <XAxis dataKey="Date" />
                    <Bar dataKey="Delivery Number" stackId="a" fill="#2BA4CC" />
                    <Bar dataKey="Open Number" stackId="a" fill={PRIMARY_COLOR} />
                    <Bar dataKey="Reply Number" stackId="a" fill="#FC8619" />
                  </BarChart>
                ) : (
                  <LineChart data={dailyStatisticsList}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <YAxis />
                    <Tooltip />
                    <Legend content={renderLegend} iconType="circle" />
                    <XAxis dataKey="Date" />
                    <Line type="monotone" dataKey="Delivery Number" stroke="#2BA4CC" />
                    <Line type="monotone" dataKey="Open Number" stroke="#221CB6" />
                    <Line type="monotone" dataKey="Reply Number" stroke="#FC8619" />
                  </LineChart>
                )}
              </ResponsiveContainer>
            </div>
          </div>
        ) : (
          <div className="analytics-email-report__empty">No info for {dateRange}</div>
        )}
      </div>
    </>
  );
};

export default ({ embedded = false }): JSX.Element => {
  const currentWorkspaceId = useSelector(getCurrentWorkspaceId);
  const filters = useSelector(insightsFiltersSelector);
  const dateFilter = useSelector(insightsDateFilterSelector);

  const {
    data: emailReportData,
    isIdle,
    isFetching,
    isLoading,
    refetch,
  } = useQuery(
    ['global-insights', filters, currentWorkspaceId],
    () => {
      const { type, peopleIds, campaignsIds } = filters;
      let filterItemsIds = [currentWorkspaceId];

      if (type === InsightsTypeMapper.DROPDOWN_INSIGHT_USER_ID && peopleIds.length > 0) {
        filterItemsIds = peopleIds;
      }

      if (type === InsightsTypeMapper.DROPDOWN_INSIGHT_CAMPAIGN_ID && campaignsIds.length > 0) {
        filterItemsIds = campaignsIds;
      }

      return getInsightsByCriteriaApi(filterItemsIds, type, currentWorkspaceId, dateFilter);
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
    }
  );

  useNonInitialEffect(() => {
    refetch();
  }, [filters]);

  useEffect(() => {
    if (!currentWorkspaceId) {
      return;
    }

    if (!!emailReportData && dateFilter.startDate === null) {
      return;
    }

    if (
      !emailReportData ||
      emailReportData.dateFrom !== dateFilter.startDate ||
      emailReportData.dateTo !== dateFilter.endDate
    ) {
      refetch();
    }
  }, [dateFilter, currentWorkspaceId]);

  const RenderInsightsHeaderActions = useCallback(() => <InsightsHeaderActions />, []);

  return (
    <>
      {embedded ? (
        <div className="analytics-email-report-header">
          Email Reports
          {RenderInsightsHeaderActions()}
        </div>
      ) : (
        <PageHeader
          title="Email reports"
          howToLink="https://help.respona.com/en/articles/6332439-how-to-use-insights"
          renderHeaderActions={RenderInsightsHeaderActions}
        />
      )}

      <Loader isLoading={isLoading || isIdle || isFetching || !emailReportData} withTopMargin>
        <EmailReportContent insightsData={emailReportData} />
      </Loader>
    </>
  );
};
