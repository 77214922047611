import React from 'react';

export default ({ color = '#202430' }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.8624 2.1447C15.5758 1.85262 15.2125 1.64762 14.8143 1.55338C14.4161 1.45915 13.9994 1.47954 13.6124 1.6122L2.99985 5.1597C2.57184 5.2998 2.19787 5.56931 1.92957 5.93103C1.66127 6.29275 1.51187 6.72883 1.50199 7.17908C1.49211 7.62933 1.62224 8.07155 1.87442 8.44468C2.12661 8.81781 2.4884 9.10347 2.90985 9.2622L6.83985 10.7622C6.92934 10.7964 7.01082 10.8487 7.07921 10.9158C7.1476 10.9829 7.20143 11.0634 7.23735 11.1522L8.73735 15.0897C8.89004 15.5052 9.16712 15.8635 9.53083 16.1158C9.89454 16.3681 10.3272 16.5022 10.7699 16.4997H10.8224C11.2731 16.4915 11.71 16.3425 12.0718 16.0735C12.4336 15.8045 12.7022 15.429 12.8399 14.9997L16.3874 4.3722C16.5164 3.98891 16.5356 3.57716 16.4428 3.18352C16.35 2.78987 16.149 2.43004 15.8624 2.1447ZM14.9999 3.8997L11.4149 14.5347C11.3732 14.6693 11.2896 14.7871 11.1763 14.8707C11.0629 14.9544 10.9257 14.9996 10.7849 14.9997C10.6448 15.002 10.5074 14.9616 10.3908 14.8839C10.2742 14.8062 10.1841 14.6949 10.1324 14.5647L8.63235 10.6272C8.52361 10.3411 8.3559 10.0811 8.14011 9.86399C7.92433 9.64692 7.66531 9.47766 7.37985 9.3672L3.44235 7.8672C3.30952 7.81853 3.19534 7.72935 3.11594 7.61227C3.03654 7.49519 2.99593 7.35612 2.99985 7.2147C2.99997 7.07381 3.04517 6.93665 3.12883 6.8233C3.2125 6.70994 3.33025 6.62633 3.46485 6.5847L14.0999 3.0372C14.222 2.98744 14.3559 2.97411 14.4854 2.99882C14.6149 3.02354 14.7345 3.08524 14.8297 3.17647C14.9249 3.2677 14.9916 3.38455 15.0218 3.5129C15.052 3.64125 15.0444 3.77559 14.9999 3.8997Z"
      fill={color}
    />
  </svg>
);
