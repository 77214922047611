import { useEffect } from 'react';

export default (ref, cb) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (Array.isArray(ref)) {
        if (!ref.some((item) => item && item.current && item.current?.contains(event.target))) {
          cb();
        }

        return;
      }

      if (ref && ref.current && !ref.current?.contains(event.target)) {
        cb();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, cb]);
};
