import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { nanoid } from 'nanoid';

import { InboxAccountsPresetType } from '@ts/mailboxInbox.types';

import useNonInitialEffect from '@hooks/useNonInitialEffect';

import { inboxSelectedMailboxesIdsSelector } from '@redux/selectors/inbox.selectors';
import { setSelectedMailboxesIds } from '@redux/actions/inbox.actions';

// import Input from '@uikit/Input/Input';
import Loader from '@uikit/Loader/Loader';
// import ControlledReactTooltip from '@uikit/ControlledReactTooltip/ControlledReactTooltip';

// import MailboxesList from '@components/Inbox/InboxSidebar/_components/MailboxesList/MailboxesList';
import InboxSidebarMailboxesButton from '@components/Inbox/InboxSidebar/_components/InboxSidebarMailboxesButton/InboxSidebarMailboxesButton';

import './InboxSelector.scss';

const InboxSelector = ({
  allMailboxes,
  isFetchingMailboxes,
  numberUnreadMessages,
  isActive,
  onClick,
}: {
  allMailboxes: InboxAccountsPresetType[];
  isFetchingMailboxes: boolean;
  numberUnreadMessages: number;
  isActive: boolean;
  onClick: () => void;
}): JSX.Element => {
  const idForTooltip = nanoid();
  const dispatch = useDispatch();
  const history = useHistory();
  const inboxesSelected: number[] = useSelector(inboxSelectedMailboxesIdsSelector);
  const [query, changeQuery] = useState<string>('');
  const [isSelectorOpen, setSelectorOpen] = useState<boolean>(false);

  const filteredData =
    allMailboxes?.filter((item) => item.email.toLowerCase().indexOf(query.toLowerCase()) !== -1) ||
    [];

  const onChoseAllInboxes = () => {
    dispatch(setSelectedMailboxesIds([]));
  };

  const onClickInbox = (id: number) => {
    // if there is no selected it means all selected
    if (inboxesSelected.length + 1 === allMailboxes.length) {
      dispatch(setSelectedMailboxesIds([]));
      return;
    }

    // if there is no selected (all selected) -> we chose only one
    if (inboxesSelected.length === 0) {
      dispatch(setSelectedMailboxesIds([id]));
      return;
    }

    const newInboxesSelected = inboxesSelected.filter((inbId) => inbId !== id);

    if (inboxesSelected.length === newInboxesSelected.length) {
      newInboxesSelected.push(id);
      dispatch(setSelectedMailboxesIds(newInboxesSelected));
    } else {
      dispatch(setSelectedMailboxesIds(newInboxesSelected));
    }
  };

  useNonInitialEffect(() => {
    /*
      If on start we have no chosen inboxes for now we chose all
     */
    if (!inboxesSelected?.length && allMailboxes.length) {
      onChoseAllInboxes();
    }
  }, [allMailboxes]);

  return (
    <div className="inbox-selector">
      <div onClick={onClick}>
        {isFetchingMailboxes ? (
          <Loader style={{ width: '34px', height: '34px' }} isLoading />
        ) : (
          <InboxSidebarMailboxesButton
            isOpen={isSelectorOpen}
            isActive={isActive}
            number={inboxesSelected?.length || allMailboxes.length}
            numberUnreadMessages={numberUnreadMessages}
          />
        )}
      </div>
      {/*<ControlledReactTooltip*/}
      {/*  afterShow={() => setSelectorOpen(true)}*/}
      {/*  afterHide={() => setSelectorOpen(false)}*/}
      {/*  id={`inbox-selector-tooltip ${idForTooltip}`}*/}
      {/*  className="react-tooltip"*/}
      {/*  place="bottom"*/}
      {/*  effect="solid"*/}
      {/*  event="click"*/}
      {/*  globalEventOff="click"*/}
      {/*  disable={!allMailboxes?.length}*/}
      {/*  clickable*/}
      {/*  arrowColor="transparent"*/}
      {/*>*/}
      {/*  <div className="inbox-selector__content">*/}
      {/*    <Input*/}
      {/*      className="inbox-selector__content-search"*/}
      {/*      placeholder="Search"*/}
      {/*      icon="search"*/}
      {/*      value={query}*/}
      {/*      onChange={({ target: { value } }) => changeQuery(value)}*/}
      {/*      isTransparent*/}
      {/*    />*/}
      {/*    <MailboxesList*/}
      {/*      list={filteredData}*/}
      {/*      allInboxesCounter={allMailboxes?.length}*/}
      {/*      inboxesSelected={inboxesSelected}*/}
      {/*      onClickInbox={onClickInbox}*/}
      {/*      onChoseAllInboxes={onChoseAllInboxes}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*</ControlledReactTooltip>*/}
    </div>
  );
};

export default InboxSelector;
