import React from 'react';
import { PRIMARY_COLOR } from '@constants/colors';

export default ({ color = PRIMARY_COLOR }): JSX.Element => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M3.5415 12.0417C12.0415 12.0417 7.7915 2.125 16.2915 2.125"
        stroke={color}
        strokeWidth="1.7"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.2916 14.875H0.708252V2.125"
        stroke={color}
        strokeWidth="1.7"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="17" height="17" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
