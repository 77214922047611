import React from 'react';

export default ({ color = '#221CB6' }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill={color}
    />
    <path
      d="M12.0075 4.67822C7.88015 4.67822 4.52249 8.03588 4.52249 12.1632C4.52092 13.9532 5.16426 15.6839 6.33468 17.0382C6.33468 17.0382 5.65078 15.6756 7.04671 14.2806C6.73217 13.5449 6.58533 12.7485 6.61684 11.949C6.64835 11.1496 6.85742 10.3671 7.22888 9.65853C7.60034 8.94992 8.12488 8.33287 8.76444 7.85217C9.40401 7.37146 10.1426 7.03914 10.9265 6.87933C11.7105 6.71951 12.5201 6.7362 13.2968 6.92819C14.0735 7.12018 14.7978 7.48265 15.417 7.98931C16.0362 8.49597 16.5348 9.13411 16.8768 9.85743C17.2187 10.5808 17.3954 11.3711 17.3939 12.1712C17.3939 14.6171 15.5967 16.8334 13.3627 17.4873C12.2883 17.8018 10.958 17.8703 10.2441 17.2923C8.63531 15.9896 10.4105 14.8182 10.4105 14.8079L11.2294 15.969L15.765 9.85041L8.0114 11.4681L9.10921 12.9751L13.5553 11.0401L9.52406 13.577C9.40272 13.656 9.28568 13.7414 9.17343 13.8329C8.58468 14.2951 7.38187 15.4918 7.90171 17.3537C8.57718 19.7748 11.8266 19.6824 12.0103 19.6824C16.1377 19.6824 19.4897 16.2906 19.4897 12.1632C19.4897 8.03588 16.1348 4.67822 12.0075 4.67822Z"
      fill="white"
    />
  </svg>
);
