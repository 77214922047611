import React from 'react';

import PageWrapper from '../components/PageWrapper/PageWrapper';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (WrappedComponent: React.FC<{ [key: string]: any }>, isSideBarVisible = true) =>
  function WithPageWrapper(props: { [key: string]: unknown }): JSX.Element {
    return (
      <PageWrapper pathname={window.location.pathname} isSideBarVisible={isSideBarVisible}>
        <WrappedComponent {...props} />
      </PageWrapper>
    );
  };
