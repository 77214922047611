import React, { useState } from 'react';
import cn from 'classnames';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { transformDateToString } from '@helpers/dateTimeRangeHelpers';

import { SVGIcon } from '@uikit/Icon/Icon';

import Routes from 'src/Routes';
import BaseRequestComponent from 'src/BaseRequestComponent';
import Notifications from '@uikit/Notifications/Notifications';
import { redirectLoginManually } from '@utils/redirections';
import { ApiError } from 'src/errors/ApiError';

import store from 'src/store';

import gtm from 'src/libs/gtm';
import './vendors/normalize.css';
import './vendors/integrations.scss';

const queryClient = new QueryClient();

declare const SENTRY_DNS: string;
declare const GIT_SHA: string;
declare const BUILD_TIME: string;
declare const IS_DEV: boolean;
declare const GAPI_CLIENT_ID: string;
declare const GTM_ID: string;

if (!localStorage.getItem('token')) {
  redirectLoginManually();
}

if (!IS_DEV && SENTRY_DNS) {
  Sentry.init({
    dsn: SENTRY_DNS,
    beforeSend(event, pipe) {
      if (pipe?.originalException instanceof ApiError) {
        return null;
      }
      return event;
    },
    integrations: [new Integrations.BrowserTracing()],
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
    tracesSampleRate: 1.0,
  });
}

const initializeCustomGTM = () => {
  if (!!GTM_ID && !!GTM_ID.length) {
    gtm.init(GTM_ID);
    console.log('GTM initialized');
  } else {
    console.error('GTM not initialized');
  }
};

function VersionTag() {
  const [collapsed, setCollapsed] = useState<boolean>(false);

  return !!GIT_SHA && !!BUILD_TIME ? (
    <div
      onClick={() => setCollapsed((val) => !val)}
      className={cn('git-sha-tag', { 'git-sha-tag--collapsed': collapsed })}
    >
      {collapsed ? (
        <SVGIcon icon="arrowLeftCircle" />
      ) : (
        <>
          <code>{GIT_SHA}</code>
          <code>{transformDateToString(new Date(parseInt(BUILD_TIME) * 1000), true)}</code>
        </>
      )}
    </div>
  ) : null;
}

ReactDOM.render(
  <GoogleOAuthProvider clientId={GAPI_CLIENT_ID}>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <Router>
          <BaseRequestComponent>
            <>
              <Routes />
              <Notifications />

              {/* <VersionTag /> */}
            </>
          </BaseRequestComponent>
        </Router>
      </Provider>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  </GoogleOAuthProvider>,
  document.getElementById('root')
);

initializeCustomGTM();

if (IS_DEV && module.hot) {
  module.hot.accept();
}
