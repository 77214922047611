import React, { useEffect, useRef, useState } from 'react';
import { UseMutationResult } from 'react-query';
import { useSelector } from 'react-redux';

import { transformCamelCase } from '@helpers/textHelpers';

import {
  ConnectIntegrationMutationArgs,
  DeleteIntegrationMutationArgs,
  IntegrationSettingsType,
  IntegrationType,
  UpdateIntegrationMutationArgs,
} from '@ts/userOrganization.types';

import { AppIntegrationResponse } from 'respona_api/generated/user-organization_pb';

import { getDateShort } from '@utils/date';
import { redirectToUrl } from '@utils/url.utils';

import useHotkeys from '@hooks/useHotkeys';
import useOutsideClick from '@hooks/useOutsideClick';

import { transformWorkspacesToOptions } from '@mappers/optionTransformers';

import { workspacesSelector } from '@redux/selectors/workspaces.selectors';

import Button from '@uikit/Button/Button';
import { SVGIcon } from '@uikit/Icon/Icon';
import Input from '@uikit/Input/Input';
import Loader from '@uikit/Loader/Loader';
import Textarea from '@uikit/Textarea/Textarea';

import { integrationsSettingsMap } from '@components/Settings/Integrations/_components/IntegrationEditingSidebar/integrationsSettingsMap';

import {
  customIntegrationDetailsTitleMap,
  integrationsService,
} from 'src/services/integrationsService';

import './IntegrationEditingSidebar.scss';

type IntegrationEditingSidebarPropsType = {
  integration: IntegrationType;
  onClose: () => void;
  onSave: UseMutationResult<
    AppIntegrationResponse.AsObject,
    unknown,
    ConnectIntegrationMutationArgs
  >;
  onUpdate: UseMutationResult<
    AppIntegrationResponse.AsObject,
    unknown,
    UpdateIntegrationMutationArgs
  >;
  onRemove: UseMutationResult<
    AppIntegrationResponse.AsObject,
    unknown,
    DeleteIntegrationMutationArgs
  >;
};

const IntegrationEditingSidebar = ({
  integration,
  onClose,
  onSave,
  onUpdate,
  onRemove,
}: IntegrationEditingSidebarPropsType): JSX.Element => {
  const sidebarRef = useRef<HTMLDivElement>();
  const isIntegrationConnected = integration?.id > -1;

  const { workspaces, loadingStatus: workspacesLs } = useSelector(workspacesSelector);

  const [workspacesValue, changeWorkspacesValue] = useState([]);
  const [settings, setSettings] = useState<Partial<IntegrationSettingsType>>({});

  const handleSettingsInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSettings((value) => ({
      ...value,
      [e.target.name]: e.target.value,
    }));
  };

  const onConnectIntegration = () => {
    onSave.mutate({
      type: integration.type,
      workspaceIdsList: workspacesValue.map((item) => item.id),
      settings,
    });
  };

  const onUpdateIntegration = () => {
    onUpdate.mutate({
      id: integration.id,
      type: integration.type,
      data: {
        status: integration.status,
        workspaceIdsList: workspacesValue.map((item) => item.id),
        settings,
      },
    });
  };

  const validateInput = () => {
    return !Object.keys(keysFields).some((key) => !settings[key]?.length);
  };

  useHotkeys({
    Escape: () => onClose(),
  });

  useOutsideClick(sidebarRef, onClose);

  useEffect(() => {
    setSettings(integrationsService.getIntegrationSettings(integration) || {});
    changeWorkspacesValue(
      transformWorkspacesToOptions(
        isIntegrationConnected
          ? workspaces.filter((item) => integration?.workspaceIdsList.includes(item.id))
          : workspaces
      )
    );
  }, [integration, isIntegrationConnected]);

  if (!integration) {
    return null;
  }

  const keysFields = integrationsService.getIntegrationSettings(integration);
  const isValid = validateInput();

  return (
    <div className="integration-editing-sidebar" ref={sidebarRef}>
      <div className="integration-editing-sidebar__title">
        <span className="integration-editing-sidebar__close-btn" onClick={onClose}>
          <SVGIcon icon="arrowLeftCircle" />
        </span>
        {isIntegrationConnected ? 'Edit' : 'Connect'} {integration.title}
      </div>
      <Loader isLoading={false}>
        <>
          <div className="integration-editing-sidebar__row">
            <Button
              type="additional"
              className="integration-editing-sidebar__learn-more-button"
              onClick={() => redirectToUrl(integration.learnLink)}
            >
              <SVGIcon size={13} color="#251fb6" icon="externalLink" />
              <span>Learn more</span>
            </Button>
          </div>

          {Object.keys(keysFields)?.map((key, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className="integration-editing-sidebar__row" key={index}>
              <div className="integration-editing-sidebar__label">
                {customIntegrationDetailsTitleMap[integration.type]?.[key] ||
                  transformCamelCase(key, true)}
              </div>
              <Input
                isFullWidth
                name={key}
                value={settings[key] || ''}
                placeholder={
                  customIntegrationDetailsTitleMap[integration.type]?.[key] ||
                  transformCamelCase(key, true)
                }
                onChange={handleSettingsInput}
                // icon="pen" @todo iconLeft, iconRight on Input
              />
            </div>
          ))}

          <div className="integration-editing-sidebar__row">
            <div className="integration-editing-sidebar__label">Info</div>
            <Textarea
              isFullWidth
              resizeDisabled
              value={integration.detailsList?.reduce(
                (acc, item) => `${acc}${item.name}: ${item.value}\n`,
                ''
              )}
              disabled
            />
          </div>

          <div className="integration-editing-sidebar__row">
            <div className="integration-editing-sidebar__label">Categories</div>
            <div className="integration-editing-sidebar__tag-row">
              {integrationsSettingsMap[integration.type].categories.map((tag) => (
                <span className="integration-editing-sidebar__tag" key={tag}>
                  {tag}
                </span>
              ))}
            </div>
          </div>

          {isIntegrationConnected ? (
            <div className="integration-editing-sidebar__row">
              <div className="integration-editing-sidebar__connected-by">
                <div className="integration-editing-sidebar__connected-by-icon">
                  <SVGIcon icon="successCircleFilled" size={24} />
                </div>
                <div className="integration-editing-sidebar__connected-by-details">
                  <span className="integration-editing-sidebar__connected-by-name">
                    Connected by {integration.connectedByUserName}
                  </span>
                  <span className="integration-editing-sidebar__connected-by-date">
                    {getDateShort(new Date(integration.connectedAt))}
                  </span>
                </div>
              </div>
            </div>
          ) : null}

          {isIntegrationConnected ? (
            <div className="integration-editing-sidebar__footer">
              <Button
                isLoading={onRemove.isLoading}
                onClick={() => onRemove.mutate({ id: integration.id })}
                type="alert"
                withConfirm
              >
                Remove Connection
              </Button>
              <div className="integration-editing-sidebar__footer-right">
                <Button
                  disabled={!isValid}
                  isLoading={onUpdate.isLoading}
                  onClick={onUpdateIntegration}
                >
                  Save
                </Button>
              </div>
            </div>
          ) : (
            <div className="integration-editing-sidebar__footer">
              <div className="integration-editing-sidebar__footer-right">
                <Button
                  disabled={!isValid}
                  isLoading={onSave.isLoading}
                  onClick={onConnectIntegration}
                >
                  Connect
                </Button>
              </div>
            </div>
          )}
        </>
      </Loader>
    </div>
  );
};

export default IntegrationEditingSidebar;
