import MimeMessage from '@uikit/MimeMessage/MimeMessage';
import React, { createRef, useState } from 'react';
import { useQuery } from 'react-query';
import ReactTooltip from 'react-tooltip';
import parse from 'emailjs-mime-parser';

import { OutboxThreadMessageType, QueuePreviewResponseType } from '@ts/mailboxInbox.types';

import stripHtml from '@utils/stripHtml';

import { getAllEmailsByContactIdApi, updateQueueMessageByIdApi } from '@api/mailbox.api';
import { getOpportunityByIdApi } from '@api/campaign.api';
import { getBasePersonByIdApi } from '@api/people.api';

import useCustomVariablesValues from '@hooks/useCustomVariablesValues';
import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';
import useAllLinksTargetNewWindow from '@hooks/useAllLinksTargetNewWindow';

import Loader from '@uikit/Loader/Loader';
import { Button } from '@uikit/Button/Button';
import { SVGIcon } from '@uikit/Icon/Icon';

import MetaMailInfo from '@components/Inbox/MetaMailInfo/MetaMailInfo';
import { OutboxRichEditor } from '@components/CampaignAnalytics/Outbox/_components/OutboxEmail/OutboxEmail';

import './OutboxMailThread.scss';
import { extractVariableNamesFromOpportunity } from '@utils/extractVariableNamesFromOpportunity';

type OutboxMailThreadProps = {
  activeQueue: (QueuePreviewResponseType & { opportunityId?: number }) | null;
};

function OutboxMailThread({ activeQueue }: OutboxMailThreadProps): JSX.Element {
  const contentWrapperRef = createRef<HTMLDivElement>();

  const [editingMessageId, setEditingMessageId] = useState<number>(null);

  const workspaceId = useCurrentWorkspaceId();

  useAllLinksTargetNewWindow(contentWrapperRef);

  const { data, isFetching, isError } = useQuery<OutboxThreadMessageType[]>(
    ['outbox-global-email', activeQueue?.contactId, workspaceId],
    async () => {
      const response = await getAllEmailsByContactIdApi(activeQueue.contactId, workspaceId);
      return response.slice().sort((a, b) => {
        const aDate = a.outbox?.nextActivityTime || a.inbox?.date;
        const bDate = b.outbox?.nextActivityTime || b.inbox?.date;

        return aDate - bDate;
      });
    },
    {
      enabled: !!activeQueue && !!activeQueue.contactId && !!workspaceId,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  const personId = data?.find((item) => !!item.outbox)?.outbox?.personId;
  const opportunityId = activeQueue?.opportunityId;

  const { data: personData, isFetching: isPersonFetching } = useQuery(
    ['outbox-single-email-person', personId],
    () => getBasePersonByIdApi(personId),
    {
      enabled: !!personId,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  const { data: opportunityData, isFetching: isOpportunityFetching } = useQuery(
    ['outbox-single-email-opportunity', workspaceId, opportunityId],
    () => getOpportunityByIdApi(opportunityId, workspaceId, -1),
    {
      enabled: !!opportunityId && !!workspaceId,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  const relatedIds = [opportunityId, personId].filter((it) => it != null && it > 0);

  const { items: customVariablesValues, isLoading: customValuesLoading } = useCustomVariablesValues(
    extractVariableNamesFromOpportunity(opportunityData),
    relatedIds
  );

  if (!activeQueue?.contactId) {
    return (
      <div className="outbox-mail-thread">
        <p className="outbox-mail-thread__empty-message">Select thread</p>
      </div>
    );
  }

  if (
    isFetching ||
    isPersonFetching ||
    isOpportunityFetching ||
    customValuesLoading ||
    !activeQueue
  ) {
    return (
      <div className="outbox-mail-thread">
        <Loader isFullBlockLoading withTopMargin />
      </div>
    );
  }

  if (isError) {
    return null;
  }

  const subject = stripHtml(data[0].inbox ? data[0].inbox.subject : data[0].outbox.subject);

  return (
    <div className="outbox-mail-thread">
      <div className="outbox-mail-thread__header">{subject}</div>
      <div ref={contentWrapperRef} className="outbox-mail-thread__mails">
        {data.map((mail: OutboxThreadMessageType) => {
          if (mail.outbox) {
            return (
              <MetaMailInfo
                key={mail.outbox.nextActivityTime}
                ActionRenderer={() => (
                  <>
                    <Button
                      type="ghost"
                      className="meta-mail-info__btn"
                      onClick={() => setEditingMessageId(mail.outbox.queueId)}
                      data-for={`edit-message-${mail.outbox.queueId}`}
                      data-tip="Edit email"
                    >
                      <SVGIcon icon="editAction" color="#dfe1e6" />
                    </Button>
                    <ReactTooltip
                      effect="solid"
                      id={`edit-message-${mail.outbox.queueId}`}
                      arrowColor="transparent"
                    />
                  </>
                )}
                dateMs={mail.outbox.nextActivityTime}
                fromEmail={mail.outbox.fromEmail}
                fromName={mail.outbox.fromName}
                toEmail={mail.outbox.toEmail}
                toName={mail.outbox.toName}
                isFutureTimeFormat
              >
                <OutboxRichEditor
                  disabled={editingMessageId !== mail.outbox.queueId}
                  email={{
                    id: mail.outbox.queueId,
                    content: mail.outbox.snippet,
                  }}
                  onUpdateQueueMessage={updateQueueMessageByIdApi}
                  campaignId={mail.outbox.campaignId}
                  workspaceId={workspaceId}
                  personData={personData}
                  opportunityData={opportunityData}
                  customVariablesValues={customVariablesValues}
                  onRevert={() => setEditingMessageId(null)}
                  classNames={{
                    editor: 'outbox-mail-thread__editor',
                    controls: 'outbox-mail-thread__editor-controls',
                  }}
                />
              </MetaMailInfo>
            );
          }
          if (mail.inbox) {
            const content = parse(mail.inbox.content);

            return (
              <MetaMailInfo
                key={mail.inbox.date}
                dateMs={mail.inbox.date}
                fromEmail={mail.inbox.fromEmail}
                fromName={mail.inbox.fromName}
                toEmail={mail.inbox.toEmail}
                toName={mail.inbox.toName}
                isFutureTimeFormat
                sent
              >
                <MimeMessage mimeMessage={content} />
              </MetaMailInfo>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
}

export default OutboxMailThread;
