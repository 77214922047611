// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
import { useSelector } from 'react-redux';

import { getActiveCampaignInfo } from '@redux/selectors/campaignSettings.selectors';

export const getFileUploadFlowSteps = (uploadedFile: boolean, isUploading: boolean) => {
  const isFileAdded = !(uploadedFile === null || isUploading);
  const { info } = useSelector(getActiveCampaignInfo);

  return [
    {
      title: 'Upload',
      key: 'selectFile',
      disabled: false,
    },
    {
      title: 'Attributes',
      key: 'mapAttributes',
      disabled: !isFileAdded,
    },
    {
      title: 'Settings',
      key: 'settings',
      disabled: !isFileAdded,
    },
  ];
};
