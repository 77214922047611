import React from 'react';

export default ({ color = '#212121', size = 18 }) => (
  <svg width={size} height={size} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.29925 11.5743L7.98662 15.2791C8.26189 15.8958 7.9851 16.6182 7.3684 16.8934C6.7517 17.1687 6.0293 16.8919 5.75403 16.2752L2.57776 9.25122"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.4063 13.1667L6.32293 9.3646H3.28126C2.02124 9.3646 1 8.34336 1 7.08335C1 5.82334 2.02124 4.80209 3.28126 4.80209H6.32293L12.4063 1V13.1667Z"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.2085 7.08325H17.7293"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.2913 3.66154L16.6083 2.90112"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.2913 10.5051L16.6083 11.2655"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
