import React from 'react';
import { components } from 'react-select';

const ControlOptions = ({ children, ...restProps }) => (
  <div onClick={restProps.selectProps.onClickControl}>
    <components.Control {...restProps}>
      {children}
    </components.Control>
  </div>
);

export default ControlOptions;
