import React, { ReactNode } from 'react';

import { Tr } from '@uikit/Table/Table';

type RenderRowPropsType = {
  children: ReactNode;
  row: any;
  renderProps: {
    [key: string]: any;
  };
};

export default function ({ children, row, renderProps }: RenderRowPropsType): JSX.Element {
  const handleRowClick = (e) => {
    e.stopPropagation();
    renderProps.onEdit(row);
  };

  const isRowSelected = renderProps.selectedRows[row.id] !== undefined;

  return (
    <Tr
      onClick={handleRowClick}
      className="settings-workspaces-table__row settings-workspaces-table__row--with-hover"
      isSelected={isRowSelected}
    >
      {children}
    </Tr>
  );
}
