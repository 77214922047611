import { Dispatch } from 'redux';

import {
  removeCompanyApi,
  getAllCompaniesFoldersApi,
  createCompanyFolderApi,
  moveCompanyToFolderApi,
  removeCompanyFolderApi,
  updateCompanyFolderApi,
  removeCompanyFromFolderApi,
  removeCompanyBatchApi,
} from '@api/website.api';

import loadingStatuses from '@constants/loadingStatuses';
import { FolderType } from '@ts/common.types';
import { addNotification } from '@redux/actions/notifications.actions';

import {
  setCompaniesFoldersData,
  setCompaniesFoldersDataLs,
  addCompaniesFolderData,
  removeCompaniesFolderData,
  updateCompaniesFolderData,
} from '@redux/actions/companies.actions';
import { EntityRelationRequest } from 'respona_api/generated/common_pb';
import { DispatchType } from 'src/store';

export const removeCompaniesBatchRequest = (websiteIds: number[], workspaceId: number) => {
  if (websiteIds == null || websiteIds.length === 0) {
    return Promise.reject(Error('Removing all Websites temporarily disabled'));
    // return removeCompanyBatchApi(websiteIds, EntityRelationRequest.SelectionType.ALL);
  }
  if (websiteIds.length === 1) {
    return removeCompanyApi(websiteIds[0], workspaceId);
  }
  return removeCompanyBatchApi(websiteIds, EntityRelationRequest.SelectionType.SELECTED);
};

/*
  Folders
 */

export const getAllCompanyFolders = (dispatch) => {
  dispatch(setCompaniesFoldersDataLs(loadingStatuses.INITED));

  getAllCompaniesFoldersApi()
    .then((folders) => {
      dispatch(setCompaniesFoldersData(folders));
    })
    .finally(() => {
      dispatch(setCompaniesFoldersDataLs(loadingStatuses.LOADED));
    });
};

export const createFolderCompanyRequest = (dispatch: DispatchType, title = 'New folder') =>
  createCompanyFolderApi(title).then((folder) => dispatch(addCompaniesFolderData(folder)));

export const renameCompanyFolderRequest = (folderId, folderTitle) => (dispatch) => {
  return updateCompanyFolderApi(folderId, folderTitle).then((folder) =>
    dispatch(updateCompaniesFolderData(folder))
  );
};

export const removeCompanyFolderRequest = (folderId) => (dispatch) =>
  removeCompanyFolderApi(folderId).then(() => dispatch(removeCompaniesFolderData(folderId)));

/*
  Moving Companies between Folders
*/

export const moveCompaniesToFolderRequest = (folderId, companiesIds, type) => {
  return moveCompanyToFolderApi(folderId, companiesIds, type);
};

export const createFolderAndMoveCompaniesRequest = (dispatch, companiesIds, type) => {
  return createCompanyFolderApi('New folder').then((folder) => {
    dispatch(addCompaniesFolderData(folder));
    return moveCompaniesToFolderRequest(folder.id, companiesIds, type);
  });
};

export const removeCompaniesFromFolderRequest = (
  dispatch: Dispatch,
  folder: FolderType,
  companiesIds: number[],
  type: EntityRelationRequest.SelectionTypeMap[keyof EntityRelationRequest.SelectionTypeMap]
): Promise<void> => {
  return removeCompanyFromFolderApi(folder.id, companiesIds, type).then(() => {
    dispatch(
      addNotification({
        title: `Organization was moved out from the "${folder.title}" folder`,
        type: 'success',
      })
    );
  });
};
