import React from 'react';

export default ({ color = '#8D8F92', size = 17 }: { color?: string; size?: number }) => (
  <svg width={size} height={size} viewBox="0 0 17 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.4583 0C11.5522 0 9.78562 1.3265 8.5 2.5235C7.21437 1.3265 5.44779 0 3.54167 0C1.58879 0 0 1.5701 0 3.5C0 5.4299 1.58879 7 3.54167 7C5.44779 7 7.21437 5.6735 8.5 4.4765C9.78562 5.6735 11.5522 7 13.4583 7C15.4112 7 17 5.4299 17 3.5C17 1.5701 15.4112 0 13.4583 0ZM3.54167 5.6C2.37008 5.6 1.41667 4.6578 1.41667 3.5C1.41667 2.3422 2.37008 1.4 3.54167 1.4C4.65162 1.4 5.94433 2.0881 7.48071 3.5C5.94433 4.9119 4.65162 5.6 3.54167 5.6ZM13.4583 5.6C12.3484 5.6 11.0557 4.9119 9.51929 3.5C11.0557 2.0881 12.3484 1.4 13.4583 1.4C14.6299 1.4 15.5833 2.3422 15.5833 3.5C15.5833 4.6578 14.6299 5.6 13.4583 5.6Z"
      fill={color}
    />
  </svg>
);
