import createAction from 'src/redux/utils/createAction';

import { HaroCategoriesType, HaroFilterShortType, HaroOpportunityType, HaroPitchedType } from 'src/types/haro.types';

export const SET_INQUIRIES_LIST = 'SET_INQUIRIES_LIST';
export const ADD_INQUIRIES_LIST_DATA = 'ADD_INQUIRIES_LIST_DATA';
export const SET_INQUIRIES_LIST_LS = 'SET_INQUIRIES_LIST_LS';
export const SET_BOOKMARK = 'SET_BOOKMARK';
export const REMOVE_BOOKMARK = 'REMOVE_BOOKMARK';

export const SET_INQUIRIES_BOOKMARKS = 'SET_INQUIRIES_BOOKMARKS';
export const ADD_INQUIRIES_BOOKMARKS_DATA = 'ADD_INQUIRIES_BOOKMARKS_DATA';
export const SET_INQUIRIES_BOOKMARKS_LS = 'SET_INQUIRIES_BOOKMARKS_LS';

export const SET_INQUIRIES_PITCHED = 'SET_INQUIRIES_PITCHED';
export const ADD_INQUIRIES_PITCHED_DATA = 'ADD_INQUIRIES_PITCHED_DATA';
export const SET_INQUIRIES_PITCHED_LS = 'SET_INQUIRIES_PITCHED_LS';

export const SET_HARO_CATEGORIES = 'SET_HARO_CATEGORIES';
export const SET_HARO_CATEGORIES_LS = 'SET_HARO_CATEGORIES_LS';

export const SET_HARO_FILTERS_DATA = 'SET_HARO_FILTERS_DATA';
export const ADD_HARO_FILTERS_DATA = 'ADD_HARO_FILTERS_DATA';
export const UPDATE_HARO_FILTER_DATA = 'UPDATE_HARO_FILTER_DATA';
export const REMOVE_HARO_FILTER_DATA = 'REMOVE_HARO_FILTER_DATA';
export const SET_HARO_FILTERS_LS = 'SET_HARO_FILTERS_LS';

export const setInquiriesList = createAction<HaroOpportunityType[]>(SET_INQUIRIES_LIST);
export const addInquiriesListData = createAction<HaroOpportunityType[]>(ADD_INQUIRIES_LIST_DATA);
export const setInquiriesListLs = createAction<string>(SET_INQUIRIES_LIST_LS);
export const setBookmark = createAction<HaroOpportunityType>(SET_BOOKMARK);
export const removeBookmark = createAction<HaroOpportunityType>(REMOVE_BOOKMARK);

export const setInquiriesBookmarks = createAction<HaroOpportunityType[]>(SET_INQUIRIES_BOOKMARKS);
export const addInquiriesBookmarksData = createAction<HaroOpportunityType[]>(
  ADD_INQUIRIES_BOOKMARKS_DATA
);
export const setInquiriesBookmarksLs = createAction<string>(SET_INQUIRIES_BOOKMARKS_LS);

export const setInquiriesPitched = createAction<HaroPitchedType[]>(SET_INQUIRIES_PITCHED);
export const addInquiriesPitchedData = createAction<HaroPitchedType[]>(
  ADD_INQUIRIES_PITCHED_DATA
);
export const setInquiriesPitchedLs = createAction<string>(SET_INQUIRIES_PITCHED_LS);

export const setHaroCategories = createAction<HaroCategoriesType[]>(SET_HARO_CATEGORIES);
export const setHaroCategoriesLs = createAction<string>(SET_HARO_CATEGORIES_LS);

export const setHaroFiltersData = createAction<HaroFilterShortType[]>(SET_HARO_FILTERS_DATA);
export const addHaroFiltersData = createAction<HaroFilterShortType>(ADD_HARO_FILTERS_DATA);
export const updateHaroFilterData = createAction<HaroFilterShortType>(UPDATE_HARO_FILTER_DATA);
export const removeHaroFilterData = createAction<number>(REMOVE_HARO_FILTER_DATA);
export const setHaroFiltersLs = createAction<string>(SET_HARO_FILTERS_LS);
