import React from 'react';

export default ({ color = '#D6D6D6', size = 12 }) => (
  <svg width={size} height={size} viewBox="0 0 4 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0ZM2 8C0.9 8 0 8.9 0 10C0 11.1 0.9 12 2 12C3.1 12 4 11.1 4 10C4 8.9 3.1 8 2 8Z"
      fill={color}
    />
  </svg>
);
