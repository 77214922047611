import React from 'react';
import ReactTooltip from 'react-tooltip';
import { nanoid } from 'nanoid';

import { ContactStatus } from 'respona_api/generated/common_pb';

import {contactStatusIconMap, contactStatusMapper} from '@mappers/contact.mappers';

import EmailAccuracy from '@uikit/EmailAccuracy/EmailAccuracy';
import PieChart from '@uikit/PieChart/PieChart';
import Steps from '@uikit/Steps/Steps';
import Checkbox from '@uikit/Checkbox/Checkbox';
import { Tr } from '@uikit/Table/Table';
import { getDateShort } from '@utils/date';
import { SVGIcon } from '@uikit/Icon/Icon';
import QuestionHint from '@uikit/QuestionHint/QuestionHint';

import ActionsMenu from '@components/ActionsMenu/ActionsMenu';

export default [
  {
    dataKey: 'selection',
    RenderCell: ({
      row: { personId },
      renderProps: { onToggleSelection, selectedIds },
    }): JSX.Element => {
      const handleToggle = () => onToggleSelection(personId);

      return <Checkbox onChange={handleToggle} value={selectedIds[personId] !== undefined} />;
    },
    RenderTitle: ({ renderProps: { onToggleAllSelected, isAllSelected } }): JSX.Element => {
      const handleToggle = ({ target: { checked } }) => onToggleAllSelected(checked);

      return <Checkbox onChange={handleToggle} value={isAllSelected} />;
    },
  },
  {
    title: 'Name',
    dataKey: 'name',
    RenderCell: ({ row: { name } }): JSX.Element => (
      <span className="analytic-people__name">{name || '-'}</span>
    ),
  },
  {
    title: 'Email',
    RenderCell: ({ row: { emailsList } }): JSX.Element => {
      const secondaryEmails = emailsList?.slice(1) || [];

      if (secondaryEmails.length) {
        const id = nanoid();
        return (
          <>
            <span
              className="analytic-people-table-emails-chevron"
              data-for={`people-emails-${id}`}
              data-tip=""
            >
              {emailsList[0]?.value}
              <SVGIcon icon="chevron" />
            </span>
            <ReactTooltip
              id={`people-emails-${id}`}
              className="react-tooltip"
              place="bottom"
              effect="solid"
              delayHide={100}
              arrowColor="transparent"
              clickable
            >
              <div
                className="analytic-people-table-emails-tooltip"
                onClick={(e) => e.stopPropagation()}
              >
                {secondaryEmails.map(({ value, accuracy }) => (
                  <div key={value} className="people-table-emails-tooltip__email">
                    <EmailAccuracy accuracy={accuracy} />
                    {value}
                  </div>
                ))}
              </div>
            </ReactTooltip>
          </>
        );
      }

      return <span>{emailsList[0]?.value}</span>;
    },
    dataKey: 'email',
  },
  {
    title: 'Status',
    dataKey: 'status',
    RenderCell: ({ row: { status } }): JSX.Element => (
      <div className="analytic-people__status-container">
        <img
          alt={contactStatusMapper[status]}
          className="analytic-people__status-icon"
          src={contactStatusIconMap[status].default}
        />
        <span className="analytic-people__status">{contactStatusMapper[status]}</span>
      </div>
    ),
  },
  {
    title: 'Sent',
    dataKey: 'sentNumber',
  },
  {
    title: 'Opened',
    dataKey: 'opened',
    RenderCell: ({ row: { openRate, openNumber } }): JSX.Element => (
      <div className="analytic-people__rate-cell">
        <PieChart value={openRate} color="#3424EB" name="openRate" />
        <div className="analytic-people__rate-number">{openNumber}</div>
      </div>
    ),
  },
  {
    title: 'Replied',
    dataKey: 'replied',
    RenderCell: ({ row: { replyRate, replyNumber } }): JSX.Element => (
      <div className="analytic-people__rate-cell">
        <PieChart value={replyRate} color="#9324EB" name="replyRate" />
        <div className="analytic-people__rate-number">{replyNumber}</div>
      </div>
    ),
  },
  {
    title: 'Next step',
    dataKey: 'nextStep',
    RenderCell: ({ row: { nextStepNumber, totalStepNumber } }): JSX.Element =>
      nextStepNumber === -1 ? (
        <span>-</span>
      ) : (
        <Steps curStep={nextStepNumber} stepsCount={totalStepNumber} />
      ),
  },
  {
    title: 'Last Activity',
    dataKey: 'lastActivity',
    RenderCell: ({ row: { lastActivityTimestamp } }): JSX.Element => {
      if (!lastActivityTimestamp || lastActivityTimestamp === -1) {
        return <span>-</span>;
      }

      const date = new Date(lastActivityTimestamp);

      return <span>{getDateShort(date)}</span>;
    },
  },
  {
    title: 'Next Activity',
    dataKey: 'nextActivity',
    RenderTitle: (): JSX.Element => (
      <div className="analytic-people-table__next-activity-th">
        Next Activity
        <QuestionHint
          hintText="The next activity date is re-calculated based on the email schedule attached to this campaign and also the daily email limit for the sending email. The date may get pushed back based on these factors."
          marginLeft={5}
          place="left"
        />
      </div>
    ),
    RenderCell: ({ row: { nextActivityTimestamp } }): JSX.Element => {
      if (!nextActivityTimestamp || nextActivityTimestamp === -1) {
        return <span>-</span>;
      }

      const date = new Date(nextActivityTimestamp);

      return <span>~{getDateShort(date)}</span>;
    },
  },
  {
    title: '',
    dataKey: 'actions',
    RenderCell: ({
      row: { personId, status },
      renderProps: { onRemove, onUnsubscribe },
    }): JSX.Element => {
      const actionsConfig = [
        {
          icon: 'banWhite',
          title: 'Unsubscribe',
          handler: () => onUnsubscribe(personId),
        },
        ...(status !== ContactStatus.STOPPED
          ? [
              {
                icon: 'stopMessaging',
                title: 'Stop',
                handler: () => onRemove(personId),
              },
            ]
          : []),
      ];

      return (
        <ActionsMenu
          actionsConfig={actionsConfig}
          id={`campaign-analytic-person-actions-${personId}`}
        />
      );
    },
  },
];

export const RenderRow = ({ children, row, renderProps }): JSX.Element => {
  const handleRowClick = (e) => {
    // e.stopPropagation();
    renderProps.onToggleSelection(row.personId);
  };

  const isRowSelected = renderProps.selectedIds[row.personId] !== undefined;

  return (
    <Tr onClick={handleRowClick} isSelected={isRowSelected}>
      {children}
    </Tr>
  );
};
