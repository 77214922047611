import { components } from 'react-select';
import React from 'react';

import './MultiValueContainer.scss';

const MultiValueContainer = (props) => {
  const { color } = props.data;
  const styles = {
    backgroundColor: '#E9E9E9',
    color: '#000',
  };

  const hexToRGB = (hex, alpha) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }

    return `rgb(${r}, ${g}, ${b})`;
  };

  if (color) {
    styles.backgroundColor = hexToRGB(color, 0.09);
    styles.color = color;
  }

  return (
    <div className="multi-value-container" style={styles}>
      <components.MultiValueContainer {...props} />
    </div>
  );
};

export default MultiValueContainer;
