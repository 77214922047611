import React from 'react';

import {
  webSearchLanguages,
  podcastLanguages,
  trendingContentLanguages,
} from '@constants/languages.contstants';

import { SearchTypesEnum } from '@ts/contentSearch.types';
import { FilterType } from '@components/CampaignSettings/Filters/filtersConfig/index';

import FilterWithSelect from '../_components/FilterWithSelect/FilterWithSelect';

export default {
  label: 'Language',
  filterKey: 'language',
  defaultValue: false,
  render: ({
    onApply,
    sourceType,
    value,
  }: {
    onApply: (filterValue: any) => void;
    sourceType: number | string;
    value: any;
  }): JSX.Element => {
    switch (sourceType) {
      case SearchTypesEnum.googleSearch:
      case SearchTypesEnum.bingSearch:
        return (
          <FilterWithSelect
            options={webSearchLanguages}
            placeholder="Select language"
            value={value}
            onApply={onApply}
            defaultValue={{ value: 'lang_en', label: 'English' }}
          />
        );
      case SearchTypesEnum.podcasts:
        return (
          <FilterWithSelect
            options={podcastLanguages}
            placeholder="Select language"
            value={value}
            onApply={onApply}
            defaultValue={{ value: 'English', label: 'English' }}
          />
        );
      case 'trendingContent':
        return (
          <FilterWithSelect
            options={trendingContentLanguages}
            placeholder="Select language"
            value={value}
            onApply={onApply}
            defaultValue={{ value: 'en', label: 'English' }}
          />
        );
      default:
        return null;
    }
  },
} as FilterType;
