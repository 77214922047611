import React, { ReactNode } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import cn from 'class-names';

import Sidebar from '@components/Sidebar/Sidebar';

import './PageWrapper.scss';

export default ({
  children,
  pathname,
  preview,
  isSideBarVisible,
}: {
  children: ReactNode;
  pathname: string;
  preview?: boolean;
  isSideBarVisible?: boolean;
}): JSX.Element => {
  const { campaignId, step } = useParams();
  const history = useHistory();
  const path = `/campaign-builder/${Number(campaignId)}/personalize`;
  const pathStep = `/campaign-builder/${Number(campaignId)}/${step}`;
  const isActivePersonalize = history.location.pathname === path;
  const isStepActive = history.location.pathname === pathStep;

  return (
    <div
      className={cn('page-wrapper', {
        'page-wrapper--disabled': preview,
      })}
    >
      {isSideBarVisible && <Sidebar pathname={pathname} preview={preview} />}
      <div
        className={cn('page-wrapper__content', {
          'active-personalize': isActivePersonalize,
          'padding-fixed': isStepActive,
        })}
      >
        {children}
      </div>
    </div>
  );
};
