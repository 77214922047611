import {
  CampaignDailyInsightsType,
  CampaignDailyWebsitesType,
  CampaignHourlyInsightsType,
} from '@ts/insights.types';
import React, { useState } from 'react';
import InsightGraphic from '@components/Insights/InsightGraphic/InsightGraphic';

const ascSorting = (data) => {
  return data.sort((a, b) => {
    if (a.date > b.date) {
      return 1;
    }

    if (a.date === b.date) {
      return 0;
    }

    return -1;
  });
};

const selectOptions = [
  { value: 'openItemsList', label: 'Open' },
  { value: 'replyItemsList', label: 'Replies' },
  { value: 'sentItemsList', label: 'Sent' },
];

export function CampaignReportsContent({
  chartDataEmailDaily,
  chartDataEmailHourly,
  chartDataWebsiteDaily,
  chartDataPersonDaily,
}: {
  chartDataEmailDaily: CampaignDailyInsightsType;
  chartDataWebsiteDaily: CampaignDailyWebsitesType;
  chartDataPersonDaily: CampaignDailyWebsitesType;
  chartDataEmailHourly: CampaignHourlyInsightsType;
}): JSX.Element {
  const isActiveFilterBtn = true;
  const [chartsEmail, setChartsEmail] = useState([
    {
      title: 'Sent',
      field: 'sentNumber',
      isChart: false,
      color: '#221CB6',
      isCheckbox: true,
      isChecked: true,
      isClickable: true,
    },
    {
      title: 'Delivered',
      field: 'deliveryNumber',
      color: '#F0C826',
      isCheckbox: true,
      isChecked: true,
      isClickable: true,
    },
    {
      title: 'Opened',
      field: 'openNumber',
      color: '#13B4E6',
      isCheckbox: true,
      isChecked: true,
      isClickable: true,
    },
    {
      title: 'Replied',
      field: 'replyNumber',
      color: '#58CD95',
      isCheckbox: true,
      isChecked: true,
      isClickable: true,
    },
    {
      title: 'Bounced',
      field: 'bouncedNumber',
      color: '#FDA452',
      isCheckbox: true,
      isChecked: true,
      isClickable: true,
    },
    {
      title: 'Unsubscribed',
      field: 'unsubscribedNumber',
      color: '#A6A6A6',
      isCheckbox: true,
      isChecked: true,
      isClickable: true,
    },
  ]);

  const [chartsWebsites, setChartsWebsites] = useState([
    {
      title: 'Opened',
      field: 'openNumber',
      color: '#13B4E6',
      isCheckbox: true,
      isChecked: true,
      isClickable: true,
    },
    {
      title: 'Replied',
      field: 'replyNumber',
      color: '#58CD95',
      isCheckbox: true,
      isChecked: true,
      isClickable: true,
    },
    {
      title: 'Unsubscribed',
      field: 'unsubscribedNumber',
      color: '#A6A6A6',
      isCheckbox: true,
      isChecked: true,
      isClickable: true,
      extraClass: 'last-item',
    },
    {
      title: 'New websites',
      field: 'attemptToReachNumber',
      isChart: false,
      isCheckbox: false,
    },
    {
      title: 'Contacted',
      field: 'sentNumber',
      color: '#221CB6',
      isCheckbox: true,
      isChecked: true,
      isClickable: true,
      isChart: false,
    },
  ]);

  const [chartsPersonal, setChartsPersonal] = useState([
    {
      title: 'Opened',
      field: 'openNumber',
      color: '#13B4E6',
      isCheckbox: true,
      isChecked: true,
      isClickable: true,
    },
    {
      title: 'Replied',
      field: 'replyNumber',
      color: '#58CD95',
      isCheckbox: true,
      isChecked: true,
      isClickable: true,
    },
    {
      title: 'Unsubscribed',
      field: 'unsubscribedNumber',
      color: '#A6A6A6',
      isCheckbox: true,
      isChecked: true,
      isClickable: true,
      extraClass: 'last-item',
    },
    {
      title: 'New contacts',
      field: 'attemptToReachNumber',
      isChart: false,
      isCheckbox: false,
    },
    {
      title: 'Contacted',
      field: 'sentNumber',
      color: '#221CB6',
      isCheckbox: true,
      isChecked: true,
      isClickable: true,
      isChart: false,
    },
  ]);

  ascSorting(chartDataEmailDaily.dailyStatisticsList);
  ascSorting(chartDataWebsiteDaily.dailyStatisticsList);
  ascSorting(chartDataPersonDaily.dailyStatisticsList);

  const sortedStatisticsList = [];

  if (chartDataEmailHourly != null) {
    chartDataEmailHourly.statisticsList.forEach((day) => {
      if (day.dayOfWeek === 'MONDAY') {
        sortedStatisticsList[0] = day;
      } else if (day.dayOfWeek === 'TUESDAY') {
        sortedStatisticsList[1] = day;
      } else if (day.dayOfWeek === 'WEDNESDAY') {
        sortedStatisticsList[2] = day;
      } else if (day.dayOfWeek === 'THURSDAY') {
        sortedStatisticsList[3] = day;
      } else if (day.dayOfWeek === 'FRIDAY') {
        sortedStatisticsList[4] = day;
      } else if (day.dayOfWeek === 'SATURDAY') {
        sortedStatisticsList[5] = day;
      } else if (day.dayOfWeek === 'SUNDAY') {
        sortedStatisticsList[6] = day;
      }
    });
  }

  const mapDynamicDataWithCharts = (state, chartData) => {
    return state.map((item) => {
      if (item.field === 'openNumber') {
        return {
          ...item,
          percentage: chartData.totalOpenRate,
          value: chartData.totalOpenNumber,
        };
      }

      if (item.field === 'sentNumber') {
        return {
          ...item,
          percentage:
            chartData.totalSentNumber === 0 || chartData.totalAttemptToReachNumber === 0
              ? 0
              : Number(
                  ((chartData.totalSentNumber / chartData.totalAttemptToReachNumber) * 100).toFixed(
                    2
                  )
                ),
          value: chartData.totalSentNumber,
        };
      }

      if (item.field === 'replyNumber') {
        return {
          ...item,
          percentage: chartData.totalReplyRate,
          value: chartData.totalReplyNumber,
        };
      }

      if (item.field === 'unsubscribedNumber') {
        return {
          ...item,
          percentage: chartData.totalUnsubscribedRate,
          value: chartData.totalUnsubscribedNumber,
        };
      }

      if (item.field === 'attemptToReachNumber') {
        return {
          ...item,
          value: chartData.totalAttemptToReachNumber,
        };
      }

      if (item.field === 'personalizedNumber') {
        return {
          ...item,
          percentage: chartData.totalPersonalizedRate,
          value: chartData.totalPersonalizedNumber,
        };
      }

      if (item.field === 'deliveryNumber') {
        return {
          ...item,
          percentage: chartData.totalDeliveryRate,
          value: chartData.totalDeliveryNumber,
        };
      }

      if (item.field === 'bouncedNumber') {
        return {
          ...item,
          percentage: chartData.totalBouncedRate,
          value: chartData.totalBouncedNumber,
        };
      }
    });
  };

  const [switchState, setSwitchState] = useState(false);
  const [switchStateWebsite, setSwitchStateWebsite] = useState(false);
  const [switchStatePersonal, setSwitchStatePersonal] = useState(false);
  const [dailyReport, setReport] = useState('openItemsList');
  const [dailyReportSelected, setReportSelected] = useState(selectOptions[0]);
  // eslint-disable-next-line array-callback-return,consistent-return
  const defaultStateValue = sortedStatisticsList.map((item) => {
    if (item.openItemsList) {
      return { day: item.dayOfWeek, timeRange: item.bestOpenRange };
    }
  });
  const [statisticReport, setStatisticReport] = useState(defaultStateValue);
  const onSelect = (selectValue) => {
    setReport(selectValue.value);
    setReportSelected(selectValue);
    const newStatistic = sortedStatisticsList.map((statisticValue) => {
      if (dailyReport === 'openItemsList') {
        return { day: statisticValue.dayOfWeek, timeRange: statisticValue.bestOpenRange };
      }
      if (dailyReport === 'replyItemsList') {
        return { day: statisticValue.dayOfWeek, timeRange: statisticValue.bestReplyRange };
      }
      if (dailyReport === 'sentItemsList') {
        return { day: statisticValue.dayOfWeek, timeRange: statisticValue.bestSentRange };
      }

      return { day: statisticValue.dayOfWeek, timeRange: statisticValue.bestOpenRange };
    });
    setStatisticReport(newStatistic);
  };

  const handleChangeCheckbox = (field) => {
    const [fieldName] = field.split('__');
    const copiedCharts = structuredClone(chartsEmail).map((item) => {
      if (item.field === fieldName) {
        return {
          ...item,
          isChecked: !item.isChecked,
        };
      }

      return item;
    });

    setChartsEmail(copiedCharts);
  };

  const handleChangeCheckboxWebsites = (field) => {
    const [fieldName] = field.split('__');
    const copiedChartsWebsites = structuredClone(chartsWebsites).map((item) => {
      if (item.field === fieldName) {
        return {
          ...item,
          isChecked: !item.isChecked,
        };
      }

      return item;
    });

    setChartsWebsites(copiedChartsWebsites);
  };

  const handleChangeCheckboxPersonal = (field) => {
    const [fieldName] = field.split('__');
    const copiedChartsPersonal = structuredClone(chartsPersonal).map((item) => {
      if (item.field === fieldName) {
        return {
          ...item,
          isChecked: !item.isChecked,
        };
      }

      return item;
    });

    setChartsPersonal(copiedChartsPersonal);
  };
  const handlerChangeSwitcher = (isActive) => {
    setSwitchState(!isActive);
  };

  const handlerChangeSwitcherWebsite = (isActive) => {
    setSwitchStateWebsite(!isActive);
  };

  const handlerChangeSwitcherPersonal = (isActive) => {
    setSwitchStatePersonal(!isActive);
  };

  return (
    <div className="campaign-report-graphics">
      <div className="campaign-report-graphics_item">
        <div className="report-graphic-container">
          <InsightGraphic
            mainGraphicData={chartDataEmailDaily.dailyStatisticsList}
            charts={mapDynamicDataWithCharts(chartsEmail, chartDataEmailDaily)}
            isSidebar
            title="Email reports"
            isActiveButton={isActiveFilterBtn}
            handleChangeCheckbox={handleChangeCheckbox}
            isActiveChangingFilters={false}
            handlerChangeSwitcher={handlerChangeSwitcher}
            isActiveSwitch={switchState}
            isActiveLineChart={switchState}
          />
        </div>
        <div className="report-graphic-container">
          <InsightGraphic
            mainGraphicData={sortedStatisticsList}
            isSidebar={false}
            title="Best time to email by"
            isActiveFilters={false}
            selectOptions={selectOptions}
            onSelect={onSelect}
            selectedReport={dailyReport}
            dataHeader={statisticReport}
            selectedValue={dailyReportSelected}
          />
        </div>
        <div className="report-graphic-container">
          <InsightGraphic
            mainGraphicData={chartDataWebsiteDaily.dailyStatisticsList}
            charts={mapDynamicDataWithCharts(chartsWebsites, chartDataWebsiteDaily)}
            isSidebar
            title="Websites"
            isActiveButton={isActiveFilterBtn}
            handleChangeCheckbox={handleChangeCheckboxWebsites}
            sidebarType="grid"
            handlerChangeSwitcher={handlerChangeSwitcherWebsite}
            isActiveSwitch={switchStateWebsite}
            isActiveLineChart={switchStateWebsite}
          />
        </div>
        <div className="report-graphic-container">
          <InsightGraphic
            mainGraphicData={chartDataPersonDaily.dailyStatisticsList}
            charts={mapDynamicDataWithCharts(chartsPersonal, chartDataPersonDaily)}
            isSidebar
            title="Contacts"
            isActiveButton={isActiveFilterBtn}
            handleChangeCheckbox={handleChangeCheckboxPersonal}
            sidebarType="grid"
            handlerChangeSwitcher={handlerChangeSwitcherPersonal}
            isActiveSwitch={switchStatePersonal}
            isActiveLineChart={switchStatePersonal}
          />
        </div>
      </div>
    </div>
  );
}
