import React from 'react';

const PeopleSvg = () => {
  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="52" height="52" rx="9" fill="white" />
      <g clipPath="url(#clip0_16053_4260)">
        <path
          d="M31.125 36.625V34.375C31.125 33.1815 30.6509 32.0369 29.807 31.193C28.9631 30.3491 27.8185 29.875 26.625 29.875H17.625C16.4315 29.875 15.2869 30.3491 14.443 31.193C13.5991 32.0369 13.125 33.1815 13.125 34.375V36.625"
          stroke="#251FB6"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.125 25.375C24.6103 25.375 26.625 23.3603 26.625 20.875C26.625 18.3897 24.6103 16.375 22.125 16.375C19.6397 16.375 17.625 18.3897 17.625 20.875C17.625 23.3603 19.6397 25.375 22.125 25.375Z"
          stroke="#251FB6"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M37.875 36.6252V34.3752C37.8743 33.3782 37.5424 32.4096 36.9315 31.6216C36.3207 30.8336 35.4654 30.2707 34.5 30.0215"
          stroke="#251FB6"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30 16.5215C30.968 16.7693 31.8259 17.3323 32.4386 18.1216C33.0513 18.9109 33.3838 19.8817 33.3838 20.8809C33.3838 21.8801 33.0513 22.8508 32.4386 23.6401C31.8259 24.4294 30.968 24.9924 30 25.2402"
          stroke="#251FB6"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_16053_4260">
          <rect width="27" height="27" fill="white" transform="translate(12 13)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PeopleSvg;
