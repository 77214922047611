import React from 'react';

const DocumentIcon = (): JSX.Element => {
  return (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.97457 11.1052H4.55957"
        stroke="#7B7B7B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.97457 7.96533H4.55957"
        stroke="#7B7B7B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.62582 4.83276H4.55957"
        stroke="#7B7B7B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1185 1C10.1185 1 4.36075 1.003 4.35175 1.003C2.28175 1.01575 1 2.37775 1 4.45525V11.3522C1 13.4402 2.2915 14.8075 4.3795 14.8075C4.3795 14.8075 10.1365 14.8052 10.1462 14.8052C12.2162 14.7925 13.4988 13.4297 13.4988 11.3522V4.45525C13.4988 2.36725 12.2065 1 10.1185 1Z"
        stroke="#7B7B7B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DocumentIcon;
