import React from 'react';

import TextWithIcon from '@uikit/TextWithIcon/TextWithIcon';

import BillingCard from '@components/Settings/Billing/_components/BillingCard/BillingCard';

import './ContactsCard.scss';
import { BillingSubscriptionTypeMapValue as BillingSubscriptionTypeName } from '@ts/billing.types';

function ContactsCard({
  totalContactsNumber,
  currentContactsNumber,
  onAddMore,
  type,
}: {
  totalContactsNumber: number;
  currentContactsNumber: number;
  onAddMore: () => void;
  type: BillingSubscriptionTypeName;
}): JSX.Element {
  return (
    <BillingCard
      title="Active Contacts"
      headerRight={
        <div onClick={onAddMore}>
          <TextWithIcon icon="arrowUpCircle" iconColor="#3424EB" iconSize={18}>
            Add contacts
          </TextWithIcon>
        </div>
      }
      chartInfo={{ value: currentContactsNumber, max: totalContactsNumber }}
      chartTitle="Added contacts"
      showWarning={false}
      warningReversed
    />
  );
}

export default ContactsCard;
