import { createSelector } from 'reselect';

import { StateType } from 'src/redux/reducers';

export const getInquiriesState = ({ inquiries }: StateType) => inquiries;

export const inquiriesSelector = createSelector(
  [getInquiriesState],
  (inquiries) => inquiries.inquiriesList
);
export const inquiriesBookmarksSelector = createSelector(
  [getInquiriesState],
  (inquiries) => inquiries.bookmarks
);
export const inquiriesPitchedSelector = createSelector(
  [getInquiriesState],
  (inquiries) => inquiries.pitched
);
export const haroCategoriesSelector = createSelector(
  [getInquiriesState],
  (inquiries) => inquiries.categories
);
export const haroFiltersSelector = createSelector(
  [getInquiriesState],
  (inquiries) => inquiries.filters
);
export const inquiryByIdSelector = (inquiryId: string) =>
  createSelector([getInquiriesState], (state) => {
    let findedItem = state.inquiriesList.items.find(({ id }) => id === inquiryId);

    if (!findedItem) {
      findedItem = state.bookmarks.items.find(({ id }) => id === inquiryId);
    }

    return findedItem;
  });
