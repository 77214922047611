import { addNotification } from '@redux/actions/notifications.actions';
import React, { useCallback, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { UserProfileType } from '@ts/user.types';

import debounce from '@utils/debounce';
import { updateUserAvatarRequest, updateUserProfile } from '@redux/thunks/userProfileRequests';
import useNonInitialEffect from '@hooks/useNonInitialEffect';

import FormTextField from '@components/Settings/Profile/_components/FormTextField/FormTextField';
import ProfileAvatar from '@components/Settings/Profile/_components/ProfileAvatar/ProfileAvatar';
import { userProfileSelector } from '@redux/selectors/userProfile.selector';

const PersonalInfoBlock = ({ profile }: { profile: UserProfileType }): JSX.Element => {
  const dispatch = useDispatch();

  const { info: profileFromRedux } = useSelector(userProfileSelector);

  const [profileFields, changeProfileFields] = useState<UserProfileType>(profile);

  const debounceUpdateUserProfile = useCallback(
    debounce(
      ({ firstName, lastName }) =>
        dispatch(updateUserProfile({ ...profileFromRedux, firstName, lastName })),
      1000
    ),
    []
  );

  useNonInitialEffect(() => {
    debounceUpdateUserProfile(profileFields);
  }, [profileFields]);

  const handleChangeName = ({ target: { value, name } }) => {
    changeProfileFields((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeAvatar = (e) => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    const readableFileSizeMB = Math.floor(file.size / 1000000);

    if (readableFileSizeMB > 25) {
      dispatch(addNotification({ title: 'Maximum file size is 25MB', type: 'warning' }));
      return;
    }

    dispatch(updateUserAvatarRequest(profileFields, file));
  };

  return (
    <div className="profile-settings__block profile-settings__personal-info">
      <p className="profile-settings__block-title">Personal Information </p>
      <div className="profile-settings__personal-info-content">
        <ProfileAvatar
          avatarUrl={profileFromRedux.avatarUrl}
          firstName={profileFields.firstName}
          lastName={profileFields.lastName}
          onChangeAvatar={handleChangeAvatar}
        />
        <div className="profile-settings__personal-info-inputs-block">
          <FormTextField
            label="First name"
            name="firstName"
            value={profileFields.firstName}
            onChange={handleChangeName}
          />
          <FormTextField
            label="Last name"
            name="lastName"
            value={profileFields.lastName}
            onChange={handleChangeName}
          />
        </div>
      </div>
    </div>
  );
};

export default PersonalInfoBlock;
