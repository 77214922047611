import React from 'react';

export default ({ color = '#fff' }) => (
  <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.59701 17.3327C3.99451 17.3327 0.263672 13.6018 0.263672 8.99935C0.263672 4.39685 3.99451 0.666016 8.59701 0.666016C13.1995 0.666016 16.9303 4.39685 16.9303 8.99935C16.9303 13.6018 13.1995 17.3327 8.59701 17.3327ZM8.59701 15.666C10.3651 15.666 12.0608 14.9636 13.3111 13.7134C14.5613 12.4632 15.2637 10.7675 15.2637 8.99935C15.2637 7.23124 14.5613 5.53555 13.3111 4.2853C12.0608 3.03506 10.3651 2.33268 8.59701 2.33268C6.8289 2.33268 5.1332 3.03506 3.88296 4.2853C2.63272 5.53555 1.93034 7.23124 1.93034 8.99935C1.93034 10.7675 2.63272 12.4632 3.88296 13.7134C5.1332 14.9636 6.8289 15.666 8.59701 15.666ZM7.76367 4.83268H9.43034V6.49935H7.76367V4.83268ZM7.76367 8.16602H9.43034V13.166H7.76367V8.16602Z"
      fill={color}
    />
  </svg>
);
