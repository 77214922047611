import React from 'react';

const SortUpArrow = ({ color = '#8D8F92', size }: { color: string, size?: number }): JSX.Element => (
  <svg width={size} viewBox="0 0 5 3" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.11196e-09 2.16288L2.16287 8.60886e-06L4.32574 2.16288L3.81107 2.67542L2.16287 1.02721L0.514665 2.67542L6.11196e-09 2.16288Z"
      fill={color}
    />
  </svg>
);

export default SortUpArrow;
