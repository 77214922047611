import { SECONDARY_BLACK_COLOR } from '@constants/colors';

export default {
  singleValue: (provided) => ({ ...provided, color: 'white' }),
  container: (provided) => ({ ...provided, backgroundColor: 'transparent' }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? SECONDARY_BLACK_COLOR : 'transparent',
    borderColor: state.isFocused ? 'none' : '#282828',
    boxShadow: 'none',
    borderRadius: '8px',
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '8px',
    backgroundColor: SECONDARY_BLACK_COLOR,
  }),
  option: (provided, { isFocused, isSelected }) => ({
    ...provided,
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    ...(isFocused || isSelected ? { backgroundColor: '#2E2E2E' } : {}),
    textOverflow: 'ellipsis',
    overflow:'hidden',
    whiteSpace: 'nowrap'
  }),
  input: (provided) => ({
    ...provided,
    color : '#ffffff',
  })
};
