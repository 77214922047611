import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'class-names';

import { RenderConfigurableSidebarLink } from '@uikit/ConfigurableSidebar/_components/ConfigurableSidebarLinks';

function GroupLink({ title, pathKey, pathname, campaignId }) {
  const cnGroupLink = cn('campaign-analytics-sidebar-group__item', {
    'campaign-analytics-sidebar-group__item--active':
      pathname === `/campaign-analytics/${campaignId}/${pathKey}`,
  });

  return (
    <Link to={`/campaign-analytics/${campaignId}/${pathKey}`} className={cnGroupLink}>
      {title}
    </Link>
  );
}

export default [
  {
    pageKey: 'peoplePage',
    RenderLink: ({ pathname, campaignId }) => {
      return (
        <RenderConfigurableSidebarLink
          title="People"
          icon="allMembers"
          url={`/campaign-analytics/${campaignId}/people`}
          isActive={pathname === `/campaign-analytics/${campaignId}/people`}
        />
      );
    },
  },
  {
    pageKey: 'opportunitiesPage',
    RenderLink: ({ pathname, campaignId }) => {
      return (
        <RenderConfigurableSidebarLink
          title="Opportunities"
          icon="opportunities"
          url={`/campaign-analytics/${campaignId}/opportunities`}
          isActive={pathname === `/campaign-analytics/${campaignId}/opportunities`}
        />
      );
    },
  },
  {
    pageKey: 'sequencePage',
    RenderLink: ({ pathname, campaignId }) => {
      return (
        <RenderConfigurableSidebarLink
          title="Sequence"
          icon="sequence"
          url={`/campaign-analytics/${campaignId}/sequence`}
          isActive={pathname === `/campaign-analytics/${campaignId}/sequence`}
        />
      );
    },
  },
  {
    pageKey: 'insightsPage',
    RenderLink: ({ pathname, campaignId }) => {
      return (
        <RenderConfigurableSidebarLink
          title="Insights"
          icon="insights"
          url={`/campaign-analytics/${campaignId}/insights`}
          isActive={pathname === `/campaign-analytics/${campaignId}/insights`}
        />
      );
    },
  },
  {
    pageKey: 'inboxPage',
    RenderLink: ({ pathname, campaignId }) => (
      <RenderConfigurableSidebarLink
        title="Inbox"
        icon="inbox"
        url={`/campaign-analytics/${campaignId}/inbox`}
        isActive={pathname === `/campaign-analytics/${campaignId}/inbox`}
      />
    ),
  },
  {
    pageKey: 'sent',
    RenderLink: ({ pathname, campaignId }) => {
      return (
        <RenderConfigurableSidebarLink
          title="Sent"
          icon="sent"
          url={`/campaign-analytics/${campaignId}/sent`}
          isActive={pathname === `/campaign-analytics/${campaignId}/sent`}
        />
      );
    },
  },
  {
    pageKey: 'outbox',
    RenderLink: ({ pathname, campaignId }) => {
      return (
        <RenderConfigurableSidebarLink
          title="Outbox"
          icon="outbox"
          url={`/campaign-analytics/${campaignId}/outbox`}
          isActive={pathname === `/campaign-analytics/${campaignId}/outbox`}
        />
      );
    },
  },
  {
    pageKey: 'bounced',
    RenderLink: ({ pathname, campaignId }) => {
      return (
        <RenderConfigurableSidebarLink
          title="Bounced"
          icon="needAttention"
          url={`/campaign-analytics/${campaignId}/bounced`}
          isActive={pathname === `/campaign-analytics/${campaignId}/bounced`}
        />
      );
    },
  },
  {
    pageKey: 'settingsPage',
    RenderLink: ({ pathname, campaignId }) => {
      return (
        <RenderConfigurableSidebarLink
          title="Settings"
          icon="gear"
          url={`/campaign-analytics/${campaignId}/settings`}
          isActive={pathname === `/campaign-analytics/${campaignId}/settings`}
        />
      );
    },
  },
];
