import React, { useCallback, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { ContentMetricsType, ContentMetricsTypeMap } from 'respona_api/generated/common_pb';
import { WebsiteType } from '@ts/company.types';
import {
  deleteMetricsValueByIdApi,
  getAllMetricsByCompanyIdApi,
  getAllMetricsViewSettingsByCompanyIdApi,
  loadMetricsValueByIdApi,
  setMetricsValueByIdApi,
  showMetricsByTypeApi,
} from '@api/website.api';
import { fetchBillingCredits } from '@redux/thunks/billings.thunks';
import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';
import { SVGIcon } from '@uikit/Icon/Icon';
import Checkbox from '@uikit/Checkbox/Checkbox';
import Loader from '@uikit/Loader/Loader';
import TooltipSearchableContent from '@uikit/TooltipSearchableContent/TooltipSearchableContent';
import ContactsSidebarCRMSection from '@components/ContactSidebarCRM/_components/ContactsSidebarCRMSection/ContactsSidebarCRMSection';
import './ContactsSidebarCRMSectionMetrics.scss';
import { DispatchType } from 'src/store';
import { PersonBaseType } from '@ts/people.types';
import {
  PeopleWebsiteMetricsRequest,
  PeopleWebsiteMetricsResponse,
  PeopleWebsiteMetricsViewSettingsResponse,
} from 'respona_api/generated/people-website_pb';
import Metrics from '@components/ContactSidebarCRM/_components/ContactsSidebarCRMSectionMetrics/_components/Metrics';
import { IconsMapByType } from '@helpers/opportunitiesHelpers';

function ContactsSidebarCRMSectionMetrics({
  website,
  person,
  index,
  queryKey: qKey,
}: {
  website?: WebsiteType;
  person?: PersonBaseType;
  index: number;
  queryKey: any;
}) {
  const ref = useRef(null);
  const dispatch = useDispatch<DispatchType>();
  const websiteId = website?.id || person?.website?.id;
  const queryClient = useQueryClient();
  const workspaceId = useCurrentWorkspaceId();
  const tooltipRef = useRef(null);
  const [queryEnabled, setQueryEnabled] = useState<boolean>(true);

  const queryKey = ['contacts-sidebar-people-metrics', websiteId];

  const { data, isFetching } = useQuery<PeopleWebsiteMetricsResponse.AsObject[]>(
    queryKey,
    () => getAllMetricsByCompanyIdApi(websiteId, workspaceId),
    {
      enabled: queryEnabled && !!websiteId,
      refetchOnWindowFocus: false,
      onSettled: (response) => !!response?.length && ref.current.setOpen(true),
    }
  );

  const {
    data: metricsSettings,
    isFetching: isLoadingMetricsSetting,
    refetch: refetchMetricsSettings,
  } = useQuery<PeopleWebsiteMetricsViewSettingsResponse.AsObject>(
    [...queryKey, 'settings'],
    () => getAllMetricsViewSettingsByCompanyIdApi(websiteId, workspaceId),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      staleTime: 15 * 60 * 1000,
    }
  );

  const showMetricsMutation = useMutation<
    PeopleWebsiteMetricsResponse.AsObject,
    unknown,
    { type: ContentMetricsTypeMap[keyof ContentMetricsTypeMap]; enabled: boolean }
  >(({ type, enabled }) => showMetricsByTypeApi(type, websiteId, workspaceId, enabled), {
    onSuccess: (res, variables) => {
      queryClient.setQueryData<PeopleWebsiteMetricsResponse.AsObject[]>(queryKey, (prevData) => {
        dispatch(fetchBillingCredits(true));

        if (prevData.find((d) => d.type === variables.type)) {
          return prevData.filter((d) => d.type !== variables.type);
        }

        return [...prevData, res];
      });
      queryClient.setQueryData(qKey, (prevData: WebsiteType[]) => {
        return prevData.map((row) => {
          if (row.id !== websiteId) {
            return row;
          }
          if (row.metricsList.find((metric) => metric.type === res.type)) {
            return {
              ...row,
              metricsList: [...row.metricsList.filter((metric) => metric.type !== res.type)],
            };
          }

          return { ...row, metricsList: [...row.metricsList, res] };
        });
      });
    },
  });

  const loadMetricsMutation = useMutation<
    PeopleWebsiteMetricsResponse.AsObject,
    unknown,
    { id: number; type: ContentMetricsTypeMap[keyof ContentMetricsTypeMap] }
  >(({ id, type }) => loadMetricsValueByIdApi(id, type, websiteId, workspaceId), {
    onSuccess: (res, variables) => {
      queryClient.setQueryData<PeopleWebsiteMetricsResponse.AsObject[]>(queryKey, (prevData) =>
        prevData ? prevData.map((d) => (d.type === variables.type ? res : d)) : [res]
      );
    },
  });

  const setMetricsMutation = useMutation<
    void,
    unknown,
    { data: PeopleWebsiteMetricsRequest.AsObject }
  >(({ data }) => setMetricsValueByIdApi(data), {
    onSuccess: (_, variables) => {
      queryClient.setQueryData<PeopleWebsiteMetricsResponse.AsObject[]>(queryKey, (prevData) =>
        prevData
          ? prevData.map((d) => (d.id === variables.data.id ? variables.data : d))
          : [variables.data]
      );
    },
  });

  const deleteMetricsMutation = useMutation<
    void,
    unknown,
    { id: number; type: ContentMetricsTypeMap[keyof ContentMetricsTypeMap] }
  >(({ id }) => deleteMetricsValueByIdApi(id, websiteId), {
    onSuccess: (_, variables) => {
      queryClient.setQueryData<PeopleWebsiteMetricsResponse.AsObject[]>(
        queryKey,
        (prevData) => prevData?.filter((item) => item.id !== variables.id) || []
      );
      queryClient.setQueryData(qKey, (prevData: WebsiteType[]) =>
        prevData.map((row) =>
          row.id === websiteId
            ? {
                ...row,
                metricsList: row.metricsList.filter((item) => item.type !== variables.type),
              }
            : row
        )
      );
    },
  });

  const onOpenCallback = useCallback(() => {
    setQueryEnabled(true);
  }, []);

  if (!websiteId) {
    return null;
  }

  return (
    <ContactsSidebarCRMSection
      ref={ref}
      index={index}
      id="metrics"
      title="Metrics"
      onOpen={onOpenCallback}
      rightComponent={
        <div
          ref={tooltipRef}
          data-for="contact-sidebar-crm-metrics"
          data-tip=""
          className="contact-sidebar-crm__content__add-btn"
          onClick={(e) => {
            e.stopPropagation();
            refetchMetricsSettings().then(() => ReactTooltip.show(tooltipRef.current));
          }}
        >
          {isLoadingMetricsSetting ? (
            <div className="contacts-sidebar-section-metrics__refresh-icon--animated">
              <SVGIcon icon="refresh" size={12} color="#221CB6" />
            </div>
          ) : (
            <>
              <SVGIcon icon="plusIcon" size={10} color="#221CB6" />
              Add
            </>
          )}
        </div>
      }
    >
      <div className="contacts-sidebar-section-metrics">
        <Loader isLoading={!data || isFetching}>
          {data?.length ? (
            data
              .slice()
              .sort((a, b) => b.type - a.type)
              .map((item) => (
                <Metrics
                  key={item.id}
                  item={item}
                  onDelete={() => deleteMetricsMutation.mutate({ id: item.id, type: item.type })}
                  onRefresh={() => loadMetricsMutation.mutate({ id: item.id, type: item.type })}
                  isRefreshing={
                    loadMetricsMutation.isLoading &&
                    loadMetricsMutation.variables?.type === item.type
                  }
                  onSave={(data) =>
                    setMetricsMutation.mutate({ data: { ...data, websiteId, workspaceId } })
                  }
                />
              ))
          ) : (
            <div className="contacts-sidebar-section-metrics--empty">Empty</div>
          )}
        </Loader>
      </div>

      <ReactTooltip
        id="contact-sidebar-crm-metrics"
        className="react-tooltip contacts-sidebar-section-metrics__tooltip"
        place="left"
        event="none"
        effect="float"
        globalEventOff="click"
        arrowColor="transparent"
        bodyMode={false}
        clickable
      >
        <TooltipSearchableContent
          title="Add Metrics"
          addButtonText="+ Connect other sources"
          customCreateAction={() => window.open('/settings/integrations', '_self')}
          searchDisabled
        >
          <div className="tooltip-searchable-content-items">
            <div className="contacts-sidebar-section-metrics__metric-options-row">
              <div className="contacts-sidebar-section-metrics__metric-options-row-icon">
                <img src={IconsMapByType[ContentMetricsType.MOZ_METRICS]} alt="metrics-logo" />
              </div>
              <span>Moz metrics</span>
              <Checkbox
                onChange={() =>
                  showMetricsMutation.mutate({
                    type: ContentMetricsType.MOZ_METRICS,
                    enabled: false,
                  })
                }
                value={data?.some((item) => item.type === ContentMetricsType.MOZ_METRICS)}
                isLoading={
                  showMetricsMutation.isLoading &&
                  showMetricsMutation.variables?.type === ContentMetricsType.MOZ_METRICS
                }
              />
            </div>

            {metricsSettings?.ahrefEnabled && (
              <div className="contacts-sidebar-section-metrics__metric-options-row">
                <div className="contacts-sidebar-section-metrics__metric-options-row-icon">
                  <img src={IconsMapByType[ContentMetricsType.AHREFS_METRICS]} alt="metrics-logo" />
                </div>
                <span>Ahrefs metrics</span>
                <Checkbox
                  onChange={() =>
                    showMetricsMutation.mutate({
                      type: ContentMetricsType.AHREFS_METRICS,
                      enabled: false,
                    })
                  }
                  value={data?.some((item) => item.type === ContentMetricsType.AHREFS_METRICS)}
                  isLoading={
                    showMetricsMutation.isLoading &&
                    showMetricsMutation.variables?.type === ContentMetricsType.AHREFS_METRICS
                  }
                />
              </div>
            )}

            {metricsSettings?.semrushEnabled && (
              <div className="contacts-sidebar-section-metrics__metric-options-row">
                <div className="contacts-sidebar-section-metrics__metric-options-row-icon">
                  <img
                    src={IconsMapByType[ContentMetricsType.SEMRUSH_METRICS]}
                    alt="metrics-logo"
                  />
                </div>
                <span>Semrush metrics</span>
                <Checkbox
                  onChange={() =>
                    showMetricsMutation.mutate({
                      type: ContentMetricsType.SEMRUSH_METRICS,
                      enabled: false,
                    })
                  }
                  value={data?.some((item) => item.type === ContentMetricsType.SEMRUSH_METRICS)}
                  isLoading={
                    showMetricsMutation.isLoading &&
                    showMetricsMutation.variables?.type === ContentMetricsType.SEMRUSH_METRICS
                  }
                />
              </div>
            )}
          </div>
        </TooltipSearchableContent>
      </ReactTooltip>
    </ContactsSidebarCRMSection>
  );
}

export default ContactsSidebarCRMSectionMetrics;
