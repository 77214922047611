// package: search.content
// file: search-content.proto

var search_content_pb = require("./search-content_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var ContentSearchService = (function () {
  function ContentSearchService() {}
  ContentSearchService.serviceName = "search.content.ContentSearchService";
  return ContentSearchService;
}());

ContentSearchService.search = {
  methodName: "search",
  service: ContentSearchService,
  requestStream: false,
  responseStream: true,
  requestType: search_content_pb.SearchRequest,
  responseType: search_content_pb.SearchItem
};

ContentSearchService.searchUrls = {
  methodName: "searchUrls",
  service: ContentSearchService,
  requestStream: false,
  responseStream: true,
  requestType: search_content_pb.UrlRequest,
  responseType: search_content_pb.UrlItem
};

ContentSearchService.searchTrendingContentTopSharesByUid = {
  methodName: "searchTrendingContentTopSharesByUid",
  service: ContentSearchService,
  requestStream: false,
  responseStream: true,
  requestType: search_content_pb.UidRequest,
  responseType: search_content_pb.SearchItem
};

ContentSearchService.searchBackLinksByUrl = {
  methodName: "searchBackLinksByUrl",
  service: ContentSearchService,
  requestStream: false,
  responseStream: true,
  requestType: search_content_pb.UidRequest,
  responseType: search_content_pb.SearchItem
};

ContentSearchService.getAudioGenres = {
  methodName: "getAudioGenres",
  service: ContentSearchService,
  requestStream: false,
  responseStream: true,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: search_content_pb.AudioGenre
};

ContentSearchService.searchLastEpisode = {
  methodName: "searchLastEpisode",
  service: ContentSearchService,
  requestStream: false,
  responseStream: false,
  requestType: search_content_pb.AudioDetailsRequest,
  responseType: search_content_pb.SearchItem
};

exports.ContentSearchService = ContentSearchService;

function ContentSearchServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

ContentSearchServiceClient.prototype.search = function search(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(ContentSearchService.search, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

ContentSearchServiceClient.prototype.searchUrls = function searchUrls(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(ContentSearchService.searchUrls, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

ContentSearchServiceClient.prototype.searchTrendingContentTopSharesByUid = function searchTrendingContentTopSharesByUid(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(ContentSearchService.searchTrendingContentTopSharesByUid, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

ContentSearchServiceClient.prototype.searchBackLinksByUrl = function searchBackLinksByUrl(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(ContentSearchService.searchBackLinksByUrl, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

ContentSearchServiceClient.prototype.getAudioGenres = function getAudioGenres(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(ContentSearchService.getAudioGenres, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

ContentSearchServiceClient.prototype.searchLastEpisode = function searchLastEpisode(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ContentSearchService.searchLastEpisode, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.ContentSearchServiceClient = ContentSearchServiceClient;

