import React from 'react';

export default ({ color = '#212121', size = 18 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.14906 13.4263L4.79051 14.4401C4.25597 14.6699 3.90906 15.1957 3.90906 15.7775V17.0001H14.0909V15.7775C14.0909 15.1957 13.744 14.6699 13.2094 14.4408L10.8451 13.4314"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M8.99996 3.90909C9.80328 3.90909 10.4545 3.25787 10.4545 2.45455C10.4545 1.65122 9.80328 1 8.99996 1C8.19663 1 7.54541 1.65122 7.54541 2.45455C7.54541 3.25787 8.19663 3.90909 8.99996 3.90909Z"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M15.5455 7.54532C16.3488 7.54532 17 6.8941 17 6.09078C17 5.28745 16.3488 4.63623 15.5455 4.63623C14.7422 4.63623 14.0909 5.28745 14.0909 6.09078C14.0909 6.8941 14.7422 7.54532 15.5455 7.54532Z"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M2.45455 7.54532C3.25787 7.54532 3.90909 6.8941 3.90909 6.09078C3.90909 5.28745 3.25787 4.63623 2.45455 4.63623C1.65122 4.63623 1 5.28745 1 6.09078C1 6.8941 1.65122 7.54532 2.45455 7.54532Z"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M8.99991 14.0909C7.39337 14.0909 6.09082 12.7883 6.09082 11.1818V10.4545C6.09082 8.84796 7.39337 7.54541 8.99991 7.54541C10.6065 7.54541 11.909 8.84796 11.909 10.4545V11.1818C11.909 12.7883 10.6065 14.0909 8.99991 14.0909Z"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);
