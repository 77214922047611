import React, { ReactNode } from 'react';

type DisplayPropsType = {
  isVisible: boolean;
  children: ReactNode;
};

const Display = ({ isVisible, children }: DisplayPropsType): JSX.Element =>
  isVisible ? <>{children}</> : null;

export default Display;
