import React from 'react';

export default ({ color = '#040404' }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 6.33333C11 6.02692 10.9396 5.7235 10.8224 5.44041C10.7051 5.15731 10.5333 4.90009 10.3166 4.68342C10.0999 4.46675 9.84269 4.29488 9.55959 4.17761C9.2765 4.06035 8.97308 4 8.66667 4H5.66667C5.48986 4 5.32029 4.07024 5.19526 4.19526C5.07024 4.32029 5 4.48986 5 4.66667V11.3333C5 11.5101 5.07024 11.6797 5.19526 11.8047C5.32029 11.9298 5.48986 12 5.66667 12H8.66667C9.13112 12.002 9.58554 11.865 9.97145 11.6065C10.3574 11.3481 10.6571 10.9801 10.832 10.5498C11.007 10.1196 11.0492 9.64684 10.9532 9.19241C10.8572 8.73799 10.6274 8.3227 10.2933 8C10.5162 7.78328 10.6935 7.5242 10.8149 7.23799C10.9362 6.95178 10.9992 6.64421 11 6.33333ZM8.66667 10.6667H6.33333V8.66667H8.66667C8.93188 8.66667 9.18624 8.77202 9.37377 8.95956C9.56131 9.1471 9.66667 9.40145 9.66667 9.66667C9.66667 9.93188 9.56131 10.1862 9.37377 10.3738C9.18624 10.5613 8.93188 10.6667 8.66667 10.6667ZM8.66667 7.33333H6.33333V5.33333H8.66667C8.93188 5.33333 9.18624 5.43869 9.37377 5.62623C9.56131 5.81376 9.66667 6.06812 9.66667 6.33333C9.66667 6.59855 9.56131 6.8529 9.37377 7.04044C9.18624 7.22798 8.93188 7.33333 8.66667 7.33333Z"
      fill={color}
    />
  </svg>
);
