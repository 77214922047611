import PeopleWebsiteService from 'respona_api/generated/people-website_pb_service';

const service = (function () {
  let instance = null;

  return {
    getInstance: (): PeopleWebsiteService.WebsiteApiServiceClient => {
      if (!instance) {
        // @ts-ignore
        instance = new PeopleWebsiteService.WebsiteApiServiceClient(API_HOST);
      }

      return instance;
    },
  };
})();

export default service;
