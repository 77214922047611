// @ts-ignore
import MailboxService from '../../../respona_api/generated/mailbox_pb_service';

const service = (function () {
  let instance = null;

  return {
    getInstance: (): MailboxService.MailboxApiServiceClient => {
      if (!instance) {
        // @ts-ignore
        instance = new MailboxService.MailboxApiServiceClient(API_HOST);
      }

      return instance;
    },
  };
})();

export default service;
