import React from 'react';
import Loader from '@uikit/Loader/Loader';
import { updateNotificationSettingStateApi } from '@api/user.api';
import useUserNotificationSettings from '@hooks/useUserNotificationSettings';
import EmailSubscriptionItem from '@components/Settings/Profile/_components/EmailSubscribtionItem/EmailSubscriptionItem';

function EmailSubscriptions() {
  const { items: emailSubscriptions, isLoading, refetch } = useUserNotificationSettings();

  const handleToggleSubscription = async (id: number, status: boolean) => {
    try {
      await updateNotificationSettingStateApi(id, status);
      refetch();
    } catch (error) {
      console.error('Failed to update subscription', error);
    }
  };

  return (
    <div className="profile-settings__email-subscriptions">
      {isLoading ? (
        <Loader isLoading />
      ) : (
        <>
          {emailSubscriptions
            .sort((a, b) => a.id - b.id)
            .map(({ type, id, isDisabled }) => (
              <EmailSubscriptionItem
                key={type}
                type={type}
                isDisabled={isDisabled}
                onToggle={(status) => handleToggleSubscription(id, status)}
              />
            ))}
        </>
      )}
    </div>
  );
}

export default EmailSubscriptions;
