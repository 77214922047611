import React from 'react';

export default ({ color = '#BDBDBD' }: { color: string }): JSX.Element => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 15.9999L2.848 14.1519"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.97349 7.72314L2.84849 8.84814C2.14514 9.5515 1.75 10.5055 1.75 11.5001C1.75 12.4948 2.14514 13.4488 2.84849 14.1521C3.55185 14.8555 4.5058 15.2506 5.50049 15.2506C6.49519 15.2506 7.44914 14.8555 8.15249 14.1521L9.27749 13.0271L3.97349 7.72314Z"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.0003 1L14.1523 2.848"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.0267 9.27701L14.1517 8.15201C14.855 7.44865 15.2502 6.4947 15.2502 5.50001C15.2502 4.50531 14.855 3.55136 14.1517 2.84801C13.4483 2.14465 12.4943 1.74951 11.4997 1.74951C10.505 1.74951 9.55101 2.14465 8.84766 2.84801L7.72266 3.97301L13.0267 9.27701Z"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 7.75L5.5 9.25"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.25 10L7.75 11.5"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
