import { createSelector } from 'reselect';

import { StateType } from '@redux/reducers';
import { prepareSequenceSteps } from '@helpers/sequenceHelpers';

export const getSequenceBase = ({ sequence }: StateType) => sequence;

export const sequenceSelector = createSelector(
  [getSequenceBase],
  ({ loadingStatus, steps, enabledUnsubscribeContent, enabledReferralContent }) => {
    return {
      loadingStatus,
      steps: prepareSequenceSteps(steps),
      enabledUnsubscribeContent,
      enabledReferralContent,
    };
  }
);

export const sequenceAiStepsSelector = ({ sequence }: StateType) =>
  sequence?.steps?.filter(
    (step) =>
      step.threadA.content.includes('class="ai-variable"') ||
      step.threadA.subject.includes('class="ai-variable"')
  ) || [];
