import React from 'react';

const QuestionInCircleFilled = ({
  color = '#DBDFE7',
  size = 12,
}: {
  color?: string;
  size?: number;
}): JSX.Element => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 0C2.6915 0 0 2.6915 0 6C0 9.3085 2.6915 12 6 12C9.3085 12 12 9.3085 12 6C12 2.6915 9.3085 0 6 0ZM5.75 9.5C5.336 9.5 5 9.164 5 8.75C5 8.336 5.336 8 5.75 8C6.164 8 6.5 8.336 6.5 8.75C6.5 9.164 6.164 9.5 5.75 9.5ZM7.9055 5.0635C7.751 5.311 7.4575 5.589 7.0245 5.898C6.324 6.416 6.343 6.531 6.343 7H5.1145C5.1145 6.6335 5.1065 6.352 5.3045 6.0095C5.431 5.79 5.663 5.5565 6 5.3095C6.4045 5.0205 6.7975 4.742 6.7975 4.257C6.7975 3.8025 6.4085 3.6405 5.954 3.6405C5.4905 3.6405 4.962 3.792 4.3685 4.095L3.863 3.08C4.9415 2.4755 6.6435 2.202 7.574 2.9595C8.257 3.516 8.261 4.4945 7.9055 5.0635Z"
      fill={color}
    />
  </svg>
);

export default QuestionInCircleFilled;
