import { manualSearchTypes } from '@constants/manualSearch.constants';
import { PersonBaseType, PersonItemResponse } from '@ts/people.types';
import React from 'react';

import { useSelector } from 'react-redux';

import { getCurrentWorkspaceId } from '@redux/selectors/workspaces.selectors';

import {
  searchPersonDetailsApi,
  searchPersonDetailsByNameAndDomainApi,
} from '@api/searchContacts.api';

import Pagination from '@uikit/Pagination/Pagination';
import ConfigurableTable from '@uikit/ConfigurableTable/ConfigurableTable';

import './ContactsSearchResultGroup.scss';
import { OpportunityPersonContactType } from '@ts/contact.types';
import { useRefCallback } from '@helpers/refHelpers';
import useIntersectionObserver from '@hooks/useIntersectionObserver';
import Loader from '@uikit/Loader/Loader';
import { createPersonApi } from '@api/people.api';
import tableConfig from './tableConfig';

type ContactsSearchResultGroupPropsType = {
  title: string;
  contacts: any;
  selectedContacts?: OpportunityPersonContactType[];
  onAddEmails: (personId: number, emailAddressList: any) => void;
  onAddContact?: (person: PersonBaseType, email: string, accuracy: number) => void;
  onRemovePersonContact: (number) => void;
  onRemoveOpportunityContact: (contactId: number) => void;
  onError?: (err: any) => void;
  searchType: string;
  domain: string;
  searchFields?: any;
  fetchNextPage: () => void;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
};

/**
 * We need to have fast access to selected contacts order
 * That`s why we need to build a map instead of array
 */
const createSelectedContactsMap = (selectedContacts: OpportunityPersonContactType[] = []) => {
  return selectedContacts.reduce((acc, { contactEmail, contactOrder, contactId, personId }) => {
    acc[contactEmail.email] = {
      order: contactOrder,
      personId,
      id: contactId,
    };

    return acc;
  }, {});
};

function ContactsSearchResultGroup({
  title,
  contacts,
  selectedContacts,
  onAddEmails,
  onAddContact,
  onError,
  onRemovePersonContact,
  onRemoveOpportunityContact,
  searchFields,
  searchType,
  domain,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
}: ContactsSearchResultGroupPropsType): JSX.Element {
  const currentWorkspaceId = useSelector(getCurrentWorkspaceId);
  const [loadingRef, setLoadingRef, ready] = useRefCallback<HTMLDivElement>();

  const handleGetContactsClick = (id) => {
    const promise: { method: (...args: any[]) => Promise<PersonItemResponse>; args: any[] } = {
      method: async () => null,
      args: [],
    };

    if (id === -999 && searchType === manualSearchTypes.name) {
      promise.method = searchPersonDetailsByNameAndDomainApi;
      promise.args = [searchFields?.fullName, domain, currentWorkspaceId];
    } else {
      promise.method = searchPersonDetailsApi;
      promise.args = [id, currentWorkspaceId];
    }

    return promise
      .method(...promise.args)
      .then((personDetails) => {
        onAddEmails(id, personDetails);
      })
      .catch((err) => {
        console.error(err?.message);
        if (onError) {
          onError(err);
        }
      });
  };

  const handleAssignContact = (personData, email, accuracy, optionalPersonId) => {
    // if (optionalPersonId > 0) {
    //   return onAddContact(
    //     {
    //       name: personData.name,
    //       jobPosition: personData.jobPosition,
    //       id: optionalPersonId,
    //       emailsList: [{ email, accuracy, verified: false, type: 0 }],
    //
    //     },
    //     email,
    //     accuracy
    //   );
    // }
    return createPersonApi({
      name: personData.name,
      emailsList: [{ email, accuracy }],
      location: personData.location,
      jobPosition: personData.jobPosition,
      accuracy,
      company: personData.company,
      workspaceId: currentWorkspaceId,
    }).then((personDetails) => onAddContact(personDetails, email, accuracy));
  };

  useIntersectionObserver(loadingRef, () => fetchNextPage(), [ready]);

  const renderLoader = (): JSX.Element => {
    if (isFetchingNextPage) {
      return <Loader isLoading withTopMargin />;
    }

    if (contacts.length && contacts[0].totalItems > contacts.length && hasNextPage) {
      return (
        <div ref={setLoadingRef} style={{ marginBottom: '20px' }}>
          <Loader isLoading withTopMargin />
        </div>
      );
    }

    return null;
  };

  return (
    <div className="contacts-result">
      <div className="contacts-result__title">{title}</div>
      <div className="contacts-result__table contacts-result-table">
        <ConfigurableTable
          rowClassName="contacts-result-table__body-row"
          data={contacts}
          config={tableConfig}
          renderProps={{
            selectedContactsMap: createSelectedContactsMap(selectedContacts),
            onRemovePersonContact,
            onGetContacts: handleGetContactsClick,
            onAssignContact: onAddContact ? handleAssignContact : null,
            onRemoveOpportunityContact,
          }}
        />
        {renderLoader()}
      </div>
    </div>
  );
}

export default ContactsSearchResultGroup;
