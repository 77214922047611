import React, { useEffect, useRef, useState } from 'react';
import cn from 'class-names';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { CampaignLaunchType } from '@ts/campaigns.types';
import {
  CampaignCrudRequest,
  CampaignVerificationStatus,
  SendingPriorityMap,
  SendingStrategyMap,
} from 'respona_api/generated/campaign_pb';

import { MailboxAccountStatus } from 'respona_api/generated/mailbox-account_pb';
import { priorityOptions, sendingStrategyOptions } from '@constants/options';
import loadingStatuses from '@constants/loadingStatuses';
import urls from '@constants/urls';

import {
  launchCampaignApi,
  resetOpportunityVerificationApi,
  stopOpportunityVerificationApi,
} from '@api/campaign.api';

import useAutoFocus from '@hooks/useAutoFocus';

import {
  csSetActiveCampaignLs,
  csSetActiveCampaignVerifyProgress,
  csSetActiveCampaignVerifyStatus,
  csSetOpenCampaignInfo,
} from '@redux/actions/campaignSettings.actions';
import { addNotification } from '@redux/actions/notifications.actions';
import { fullStateSelector } from '@redux/selectors/base.selectors';
import {
  getActiveCampaignInfo,
  getActiveCampaignVerifyStatus,
} from '@redux/selectors/campaignSettings.selectors';
import { getCurrentWorkspaceId } from '@redux/selectors/workspaces.selectors';
import { updateCampaign } from '@redux/thunks/campaign.thunks';

import QuestionTooltip from '@pages/CampaignCreationPage/_components/QuestionTooltip/QuestionTooltip';
import tooltipTexts from '@pages/CampaignCreationPage/_components/QuestionTooltip/tooltipTexts';
import additionalEmail from '@pages/CampaignCreationPage/fields/additionalEmail';
import email from '@pages/CampaignCreationPage/fields/email/email';
import priority from '@pages/CampaignCreationPage/fields/priority';
import campaignSchedule, {
  campaignSchedulePreview,
} from '@pages/CampaignCreationPage/fields/schedules';
import sendingStrategy from '@pages/CampaignCreationPage/fields/sendingStrategy';
import textField from '@pages/CampaignCreationPage/fields/textField';

import Icon from '@uikit/Icon/Icon';

import CampaignSettingsVerification from '@components/CampaignSettingsSidebar/_components/CampaignSettingsVerification/CampaignSettingsVerification';
import Display from '@components/Display/Display';
import RightSidebar from '@components/RightSidebar/RightSidebar';

import './CampaignSettingsSidebar.scss';
import useMailboxAccounts from '@hooks/useMailboxAccounts';
import { QueryCollectionHookType } from '@ts/common.types';
import { MailboxAccountType } from '@ts/mailboxAccounts.types';
import SlideToggler from '@uikit/SlideToggler/SlideToggler';

const mainSettingFields = {
  campaignName: textField('Campaign Name', 'campaignName', 'Enter campaign name'),
  senderEmail: email('Sender Email(s)', 'senderEmail'),
  campaignSchedule,
  campaignSchedulePreview,
};

const advancedSettingsFields = {
  priority,
  sendingStrategy,
  bccEmailsList: additionalEmail('Auto-BCC', 'bccEmailsList', 'bccEmailsList'),
  ccEmailsList: additionalEmail('Auto-CC', 'ccEmailsList', 'ccEmailsList'),
};

type Props = any;

function CampaignSettingsSidebar({
  campaignId,
  isOpen,
  onClose,
  onVerification,
  onAddNewEmail,
}: Props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const campaignNameInputRef = useRef(null);
  const senderEmailInputRef = useRef(null);
  const notRepliedStrategyBufferDaysInputRef = useRef(null);
  const contactedPastDaysNumberInputRef = useRef(null);

  const refKeyMapper = {
    campaignName: campaignNameInputRef,
    senderEmail: senderEmailInputRef,
  };

  const verifyingStatus = useSelector(getActiveCampaignVerifyStatus);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const { info: campaignInfo } = useSelector(getActiveCampaignInfo);
  const state = useSelector(fullStateSelector);
  const { items: mbAccounts }: QueryCollectionHookType<MailboxAccountType> =
    useMailboxAccounts(workspaceId);

  const [isAdvancedSettingOpen, changeIsAdvancedSettingOpen] = useState(true);

  const [settingChanged, setSettingsChanged] = useState(false);
  const [defaultState, setDefaultState] = useState(null);
  const [formState, changeFormState] = useState({
    campaignName: 'New campaign',
    priority: priorityOptions[1],
    senderEmail: [],
    campaignSchedule: null,
    bccEmailsList: [],
    ccEmailsList: [],
    sendingStrategy: sendingStrategyOptions[3],
    notRepliedStrategyBufferDays: 3 || '',
    enabledTrackingInPastDays: true,
    enabledTrackingInOtherActiveCampaign: true,
    contactedPastDaysNumber: 30 || '',
    unsubscribedContent: false,
    trackOpens: true,
  });

  const isNotReplyStrategyActive =
    formState.sendingStrategy && formState.sendingStrategy.value === 3;

  const setDefaultFormState = () => {
    const mySchedule = state.schedules.items.find(
      ({ id }) => id === campaignInfo.settings.scheduleId
    );

    const data = {
      campaignName: campaignInfo.title,
      priority: priorityOptions[campaignInfo.settings.sendingPriority],
      bccEmailsList: campaignInfo.settings.bccEmailsList.map((email) => ({
        value: email,
        label: email,
      })),
      ccEmailsList: campaignInfo.settings.ccEmailsList.map((email) => ({
        value: email,
        label: email,
      })),
      sendingStrategy: sendingStrategyOptions[campaignInfo.settings.sendingStrategy],
      senderEmail: mbAccounts?.reduce(
        (acc, item) =>
          campaignInfo.settings.senderMailboxIdList.includes(item.id)
            ? [
                ...acc,
                {
                  value: item?.id,
                  label: item?.email,
                },
              ]
            : acc,
        []
      ),
      campaignSchedule: {
        value: mySchedule?.id,
        label: mySchedule?.title,
      },
      enabledTrackingInPastDays: campaignInfo.settings.enabledTrackingInPastDays,
      enabledTrackingInOtherActiveCampaign:
        campaignInfo.settings.enabledTrackingInOtherActiveCampaign,
      contactedPastDaysNumber: campaignInfo.settings.contactedPastDaysNumber,
      trackOpens: campaignInfo.settings.trackOpens,
    };

    changeFormState((prevState) => {
      return {
        ...prevState,
        ...data,
      };
    });

    setDefaultState({ ...formState, ...data });
  };

  useEffect(() => {
    if (!formState || !defaultState) {
      return;
    }

    if (!_.isEqual(formState, defaultState)) {
      setSettingsChanged(true);

      if (!_.isEqual(formState.senderEmail, defaultState.senderEmail)) {
        if (verifyingStatus === CampaignVerificationStatus.IN_PROGRESS) {
          stopOpportunityVerificationApi(campaignInfo.id, campaignInfo.workspaceId).then(() => {
            dispatch(csSetActiveCampaignVerifyStatus({ statusNumber: 0 }));
          });
        }
        if (verifyingStatus === CampaignVerificationStatus.VERIFIED) {
          resetOpportunityVerificationApi(campaignInfo.id, campaignInfo.workspaceId).then(() => {
            dispatch(csSetActiveCampaignVerifyStatus({ statusNumber: 0 }));
            if (onVerification) {
              onVerification();
            }
            dispatch(
              csSetActiveCampaignVerifyProgress({
                progress: 0,
                totalNumber: 0,
                verifiedNumber: 0,
                flaggedNumber: 0,
              })
            );
          });
        }
      }
    } else {
      setSettingsChanged(false);
    }
  }, [formState, defaultState]);

  useAutoFocus(campaignNameInputRef);

  useEffect(() => {
    if (campaignId && campaignInfo && campaignInfo.id === Number(campaignId)) {
      setDefaultFormState();
    }
  }, [campaignInfo.id, campaignId]);

  const handleSaveClick = async () => {
    if (formState.campaignName === '') {
      dispatch(addNotification({ title: 'Please enter a name for the campaign', type: 'warning' }));
      campaignNameInputRef.current && campaignNameInputRef.current.focus();
      return;
    }

    if (!formState.senderEmail?.length) {
      dispatch(addNotification({ title: 'Please select sender email', type: 'warning' }));
      senderEmailInputRef.current && senderEmailInputRef.current.focus();
      return;
    }

    if (formState.sendingStrategy == null) {
      dispatch(addNotification({ title: 'Please specify "Sending strategy"', type: 'warning' }));
      return;
    }

    if (formState.priority == null) {
      dispatch(addNotification({ title: 'Please specify "Campaign Priority"', type: 'warning' }));
      return;
    }

    if (formState.campaignSchedule == null) {
      dispatch(addNotification({ title: 'Please specify "Campaign Schedule"', type: 'warning' }));
      return;
    }

    if (isNotReplyStrategyActive && formState.notRepliedStrategyBufferDays === '') {
      notRepliedStrategyBufferDaysInputRef.current &&
        notRepliedStrategyBufferDaysInputRef.current.focus();
      return;
    }

    if (formState.enabledTrackingInPastDays && formState.contactedPastDaysNumber === '') {
      contactedPastDaysNumberInputRef.current && contactedPastDaysNumberInputRef.current.focus();
      return;
    }
    const requestData: CampaignCrudRequest.AsObject = {
      id: campaignId,

      title: formState.campaignName,
      workspaceId,
      settings: {
        sendingStrategy: formState.sendingStrategy
          .value as SendingStrategyMap[keyof SendingStrategyMap],
        sendingPriority: formState.priority.value as SendingPriorityMap[keyof SendingPriorityMap],
        scheduleId: formState.campaignSchedule.value,
        senderMailboxIdList: formState.senderEmail.map((item) => item.value),
        bccEmailsList: Array.isArray(formState.bccEmailsList)
          ? formState.bccEmailsList.map(({ value }) => value)
          : [],
        ccEmailsList: Array.isArray(formState.ccEmailsList)
          ? formState.ccEmailsList.map(({ value }) => value)
          : [],
        enabledTrackingInPastDays: formState.enabledTrackingInPastDays,
        enabledTrackingInOtherActiveCampaign: formState.enabledTrackingInOtherActiveCampaign,
        contactedPastDaysNumber: Number(formState.contactedPastDaysNumber),
        notRepliedStrategyBufferDays: Number(formState.notRepliedStrategyBufferDays),
        trackOpens: formState.trackOpens,
        trackClicks: campaignInfo.settings.trackClicks,
      },
    };

    if (campaignId) {
      await updateCampaign(dispatch, { ...requestData }).then((res) => {
        dispatch(csSetOpenCampaignInfo(res));
        dispatch(csSetActiveCampaignLs(loadingStatuses.LOADED));
        dispatch(addNotification({ type: 'success', title: 'Campaign updated' }));
      });
    }
  };

  const createTextFieldChangeHandler =
    (fieldName) =>
    ({ target: { value } }) => {
      changeFormState((curFormState) => ({
        ...curFormState,
        [fieldName]: value,
      }));
    };

  const createSelectFieldChangeHandler = (fieldName) => (value) => {
    changeFormState((curFormState) => ({
      ...curFormState,
      [fieldName]: value,
    }));
  };

  const createFieldChangeHandler = (fieldName, type) => {
    if (type === 'text') {
      return createTextFieldChangeHandler(fieldName);
    }

    return createSelectFieldChangeHandler(fieldName);
  };

  const handleConfirmLaunch = (type: CampaignLaunchType): Promise<void> => {
    if (!mbAccounts?.length) {
      addNotification({
        title: 'Please, make sure that you have connected an email(s).',
        type: 'warning',
      });
      return new Promise((resolve, reject) => reject());
    }
    if (
      campaignInfo?.settings?.senderMailboxIdList?.some((acc) => {
        const account = mbAccounts?.find((item) => item.id === acc);

        return account.status !== MailboxAccountStatus.MAILBOX_ACTIVE;
      })
    ) {
      dispatch(
        addNotification({
          title:
            'We can’t connect to the selected sender emails. Please re-authenticate the selected sender emails from the Account menu.',
          type: 'warning',
        })
      );
      return new Promise((resolve, reject) => reject());
    }

    return launchCampaignApi(campaignId, workspaceId, type).then(() => {
      dispatch(addNotification({ title: 'Campaign was launched', type: 'success' }));
      history.push(urls.ALL_CAMPAIGNS);
    });
  };

  const toggleAdvSettings = () => changeIsAdvancedSettingOpen((curIsOpen) => !curIsOpen);

  const renderField = ({ label, key, previewKey, type, Renderer }) => {
    return (
      <div key={label || key}>
        <div className="campaign-verification__settings-label">
          {label}
          {tooltipTexts[previewKey || key] ? (
            <div className="campaign-verification__settings-label-tooltip">
              <QuestionTooltip text={tooltipTexts[previewKey || key]} />
            </div>
          ) : null}
        </div>
        <div className="campaign-verification__settings-field">
          <Renderer
            {...(type === 'select' ? { onAddNewEmail } : {})}
            inputRef={refKeyMapper[previewKey || key] || null}
            state={state}
            value={formState[previewKey || key]}
            onChange={createFieldChangeHandler(key, type)}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="campaign-verification">
      <RightSidebar
        isOpen={isOpen}
        onClose={onClose}
        title={
          <div className="campaign-verification__header">
            <span>Campaign Settings</span>
          </div>
        }
        showFooter={false}
      >
        <div className="campaign-verification__settings">
          {Object.values(mainSettingFields).map(renderField)}
        </div>
        <div
          className="campaign-verification__advanced-settings-toggle"
          onClick={toggleAdvSettings}
          tabIndex={0}
          role="button"
        >
          <span>Advanced Settings</span>
          <Icon
            icon="chevron"
            className={cn('preparing-form__setting-toggler-chevron', {
              'preparing-form__setting-toggler-chevron--opened': isAdvancedSettingOpen,
            })}
          />
        </div>

        <Display isVisible={isAdvancedSettingOpen}>
          <div className="campaign-verification__settings" style={{ paddingTop: '20px' }}>
            {Object.values(advancedSettingsFields).map(renderField)}
            <div className="campaign-verification__settings__slider">
              <SlideToggler
                value={formState.trackOpens}
                onChange={(value) =>
                  changeFormState((curFormState) => ({
                    ...curFormState,
                    trackOpens: value,
                  }))
                }
              />
              <span>Open tracking</span>
              <QuestionTooltip
                place="right"
                color="#C6C6C6"
                text="You can disable email open tracking to improve deliverability."
              />
            </div>
          </div>
        </Display>

        <div className="campaign-verification__settings-verification">
          <CampaignSettingsVerification
            onVerification={() => (onVerification ? onVerification() : null)}
            onLaunch={(type: CampaignLaunchType) => handleConfirmLaunch(type)}
            emailsPresent={
              formState.senderEmail?.map((item) => item.value)?.length > 0 &&
              formState.campaignSchedule?.value > 0
            }
            handleSaveClick={settingChanged ? handleSaveClick : null}
          />
        </div>
      </RightSidebar>
    </div>
  );
}

export default CampaignSettingsSidebar;
