import { Editor, Transforms, Selection } from 'slate';
import { VariableType } from '@constants/variables';
import { CustomVariableType } from 'respona_api/generated/common_pb';

export type CustomTextType = {
  bold?: boolean;
  italic?: boolean;
  code?: boolean;
  text: string;
  type?: string;
};

export type VariableElementType = {
  type: 'variable' | 'ai-variable';
  character: string;
  fallback: string;
  children: CustomTextType[];
  isCustomVariable?: boolean;
  customVariableId?: number;
};

export const insertVariable = (
  editor: Editor,
  variable: VariableType,
  lastSelection?: Selection
): void => {
  const variableNode: VariableElementType = {
    type: variable.type === CustomVariableType.OPEN_AI ? 'ai-variable' : 'variable',
    character: variable.value,
    fallback: '',
    children: [{ text: '', type: 'text' }],
    isCustomVariable: !variable.isDefault,
    customVariableId: variable.id,
  };

  // @ts-ignore
  if (lastSelection) {
    Transforms.insertNodes(editor, variableNode, {
      at: { path: lastSelection.anchor.path, offset: lastSelection.anchor.offset },
    });
  } else {
    Transforms.insertNodes(editor, variableNode);
  }

  try {
    Transforms.setSelection(editor, {
      focus: {
        path: lastSelection.focus.path.map((item, index, arr) => {
          if (index === arr.length - 1) {
            return item + 2;
          }
          return item;
        }),
        offset: 0,
      },
      anchor: {
        path: lastSelection.anchor.path.map((item, index, arr) => {
          if (index === arr.length - 1) {
            return item + 2;
          }
          return item;
        }),
        offset: 0,
      },
    });
  } catch (e) {
    console.error(e);
  }
};

export const transformVariableDefault = (variable: string, fallback: string): string => {
  return fallback && fallback.length ? `${variable} | ${fallback}` : `${variable}`;
};

export const transformLinkUrlDefault = (url: string): string =>
  url === 'linkedin' || url === 'url' ? `@${url}` : url;
