import { ContentAutomationSearchingMode } from 'respona_api/generated/automation-content_pb';

export type ContentAutoSearchStepType = {
  title: string;
  key: string;
  disabled?: boolean;
};

export const getContentAutoSearchSteps = (
  canStartAutomation?: boolean,
  searchMode?: number
): ContentAutoSearchStepType[] => [
  {
    title: searchMode === ContentAutomationSearchingMode.BACKLINKS_SEARCH ? 'URLs' : 'Keywords',
    key: 'keywords',
    disabled: !canStartAutomation,
  },
  {
    title: 'Metrics',
    key: 'metrics',
    disabled: !canStartAutomation,
  },
  {
    title: 'Settings',
    key: 'settings',
    disabled: !canStartAutomation,
  },
];
