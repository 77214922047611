import React, { useRef } from 'react';
import cn from 'class-names';
import { nanoid } from 'nanoid';

import { convertHtmlToPlainText, sanitizeMimeHtml } from '@helpers/emailsHelpers';

import Avatar from '@uikit/Avatar/Avatar';

import useAllLinksTargetNewWindow from '@hooks/useAllLinksTargetNewWindow';

import './ThreadsItem.scss';
import { getFormattedDate } from '@utils/getFormattedPastTime';
import { SVGIcon } from '@uikit/Icon/Icon';
import { ThreadConversationMessageType } from '@ts/mailboxInbox.types';
import { Tag } from 'respona_api/generated/common_pb';

type ThreadsItemProps = {
  isActive: boolean;
  onClick: () => void;
  name: string;
  subject: string;
  time: number;
  snippet: string;
  draftExists: boolean;
  snoozed?: number;
  isFutureTime?: boolean;
  viewed: boolean;
  isFavorite?: boolean;
  comment?: ThreadConversationMessageType;
  tags?: Array<Tag.AsObject>;
};

function ThreadsItem({
  isActive,
  onClick,
  name,
  time,
  subject,
  snippet,
  draftExists,
  snoozed,
  isFutureTime = false,
  isFavorite,
  viewed,
  comment,
  tags = [],
}: ThreadsItemProps): JSX.Element {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const threatItemCn = cn('threads-list-item', {
    'threads-list-item--active': isActive,
  });

  const threatItemSubjectCn = cn('threads-list-item__subject', {
    'threads-list-item__subject--noviewed': !viewed,
  });

  const threatItemFromNameCn = cn('threads-list-item__from-name', {
    'threads-list-item__from-name--noviewed': !viewed,
  });

  useAllLinksTargetNewWindow(wrapperRef);

  function composeSnoozed(value: number) {
    if (value < Date.now()) {
      return 'Snooze ended';
    }

    try {
      return `Snoozed until ${new Date(value).toLocaleDateString()}`;
    } catch (e) {
      return 'Snooze ended';
    }
  }

  return (
    <div
      key={nanoid()}
      className={threatItemCn}
      onClick={onClick}
      role="button"
      tabIndex={0}
      ref={wrapperRef}
    >
      <div className="threads-list-item__header">
        <Avatar url={undefined} fullName={name} size="xs" />
        <div className={threatItemFromNameCn} title={name}>
          {name || '<Without name>'}
        </div>
        {!viewed && <div className="threads-list-item__no-viewed-circle" />}
        <div className="threads-list-item__last-activity-time">{getFormattedDate(time)}</div>
      </div>
      {tags.length === 0 ? null : (
        <div className="threads-list-item__header-tags">
          {tags.map(({ id, title }) => (
            <span className="inbox-mail-thread__header-tags-tag" key={id}>
              {title}
            </span>
          ))}
        </div>
      )}
      {subject ? (
        <div className="threads-list-item__header-subject-container">
          <div
            className={threatItemSubjectCn}
            dangerouslySetInnerHTML={{ __html: sanitizeMimeHtml(subject) }}
          />
          {isFavorite && <SVGIcon icon="fullStar" />}
        </div>
      ) : (
        <div className={threatItemSubjectCn}>{'<Empty subject>'}</div>
      )}
      {snippet ? (
        <div
          className="threads-list-item__snippet"
          dangerouslySetInnerHTML={{ __html: convertHtmlToPlainText(sanitizeMimeHtml(snippet)) }}
        />
      ) : (
        <div className="threads-list-item__snippet">{'<Empty snippet>'}</div>
      )}
      <div className="threads-list-item__container">
        {snoozed > 0 ? (
          <div className="threads-list-item__snooze">
            <SVGIcon icon="snooze" color="#FC8619" />
            {composeSnoozed(snoozed)}
          </div>
        ) : null}
        {draftExists ? (
          <div className="threads-list-item__draft">
            <SVGIcon icon="draft" color="#2752EB" />
            Draft
          </div>
        ) : null}
      </div>
      {comment ? (
        <div
          key={comment.id}
          className="threads-list-item__comment"
          dangerouslySetInnerHTML={{
            __html: convertHtmlToPlainText(sanitizeMimeHtml(comment.content)),
          }}
        />
      ) : null}
    </div>
  );
}

export default ThreadsItem;
