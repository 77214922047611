import React from 'react';
import { useHistory } from 'react-router-dom';

import urls from '@constants/urls';

import './CampaignsSidebarHeader.scss';
import {
  RenderConfigurableCampaignSidebarLink
} from '@components/Campaigns/CampaignsSidebar/_components/CampaignsSidebarHeader/components/ConfigurableCampaignSidebarLinks';

const CampaignsSidebarHeader = ({ isDeletedType }: { isDeletedType: boolean }): JSX.Element => {
  const history = useHistory();

  return (
    <div className="campaigns-sidebar__header-container">
      {/*<div className="campaigns-sidebar__header">*/}
      {/*  {isDeletedType ? 'Deleted campaigns' : 'Campaigns'}*/}
      {/*</div>*/}
      <RenderConfigurableCampaignSidebarLink
        url={urls.ALL_CAMPAIGNS}
        isActive={history.location.pathname === urls.ALL_CAMPAIGNS && !history.location.search}
        icon="allCampaignsSidebarIcon"
        title="All campaigns"
      />
      {/*<div className="campaigns-sidebar-header__bottom-line" />*/}
    </div>
  );
};

export default CampaignsSidebarHeader;
