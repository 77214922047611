export const calendlyStatusTypes = {
  NONE: 'none',
  AWAITING: 'awaiting',
  COMPLETE: 'complete',
};

export const eWebinarStatusTypes = {
  NONE: 'none',
  LOADING: 'loading',
  COMPLETE: 'complete',
};
