import React from 'react';

export default ({ color = '#C4C6CD' }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17 10.0052C16.9977 13.0167 15.0695 15.6891 12.2124 16.6407C12.1054 16.6737 11.9893 16.6556 11.8974 16.5917C11.8055 16.5249 11.7509 16.4184 11.7504 16.3048V14.4359C11.7655 13.9325 11.6446 13.4344 11.4004 12.994C11.3635 12.9364 11.3635 12.8626 11.4004 12.805C11.4288 12.7519 11.4808 12.7155 11.5404 12.707C13.2483 12.532 14.2002 11.8531 14.2002 10.0052C14.2414 9.14244 13.9423 8.29796 13.3673 7.65341C13.4511 7.38113 13.4959 7.09833 13.5003 6.81347C13.4973 6.55807 13.462 6.30407 13.3953 6.05752C13.3513 5.89312 13.1933 5.78586 13.0243 5.80554C12.383 5.89657 11.786 6.18528 11.3164 6.63148C10.4472 6.46353 9.55384 6.46353 8.68461 6.63148C8.21505 6.18528 7.61806 5.89657 6.97674 5.80554C6.80769 5.78586 6.64978 5.89312 6.60576 6.05752C6.53905 6.30407 6.50378 6.55807 6.50077 6.81347C6.50513 7.09833 6.5499 7.38113 6.63376 7.65341C6.05874 8.29796 5.75965 9.14244 5.80082 10.0052C5.80082 11.9511 6.85775 12.602 8.7966 12.763C8.55592 13.0784 8.39071 13.4448 8.31364 13.834C8.31364 13.834 8.31364 13.8829 8.31364 13.9179C8.31027 13.9505 8.31027 13.9834 8.31364 14.0159C8.28451 14.236 8.08666 14.3936 7.86567 14.3729C7.7603 14.3679 7.65741 14.3392 7.56469 14.2889C7.18337 14.0567 6.84674 13.758 6.57077 13.407C6.39493 13.2076 6.20798 13.0183 6.01081 12.84C5.88552 12.7316 5.74671 12.6398 5.59784 12.567C5.49352 12.5084 5.36619 12.5084 5.26186 12.567C5.16172 12.6313 5.10107 12.742 5.10088 12.861V12.903C5.10107 13.022 5.16172 13.1327 5.26186 13.197C5.51312 13.4073 5.73034 13.6552 5.90582 13.9319C6.20216 14.3963 6.57905 14.804 7.01873 15.1359C7.30843 15.3301 7.6499 15.4325 7.99866 15.4298H8.25064V16.3048C8.2501 16.4184 8.19552 16.5249 8.10365 16.5917C8.01178 16.6556 7.89562 16.6737 7.78868 16.6407C4.39536 15.5105 2.39994 12.0039 3.16158 8.50938C3.92321 5.01483 7.19687 2.6566 10.7527 3.04097C14.3086 3.42534 17.0027 6.42865 17 10.0052Z"
      fill={color}
    />
  </svg>
);
