import { createAction } from 'redux-actions';

import { BillingType, BillingDetailsShortType, BillingAddressType } from '@ts/billing.types';

export const setBillingCreditsLs = createAction<string>('SET_BILLING_CREDITS_LS');
export const setBillingCreditsData = createAction<BillingDetailsShortType>(
  'SET_BILLING_CREDITS_DATA'
);
export const setBillingLs = createAction<string>('SET_BILLING_LS');
export const setBillingData = createAction<BillingType>('SET_BILLING_DATA');
export const setBillingAddressLs = createAction<string>('SET_BILLING_ADDRESS_LS');
export const setBillingAddressData = createAction<BillingAddressType>('SET_BILLING_ADDRESS_DATA');
