import { handleActions } from 'redux-actions';

import { CampaignFiltersType } from '@ts/campaigns.types';

import {
  setCampaignFolders,
  setCampaignFoldersLs,
  addCampaignFolders,
  removeCampaignFolder,
  updateCampaignFolder,
  setDaterangeForFilterCampaigns,
  setPersonsIdsForFilterCampaigns,
  setTittleForFilterCampaign,
  addNewOneCampaignsFolder,
  setStatusForFilterCampaign,
  setExtendedFiltersCampaign,
} from '@redux/actions/campaigns.actions';
import loadingStatuses from '@constants/loadingStatuses';
import { FolderType, StateItemsListType } from '@ts/common.types';
import { updateItemInListByIdHelper } from '@helpers/commonHelpers';

export type CampaignsStateType = {
  folders: StateItemsListType<FolderType>;
  sharedWithMeFolders: StateItemsListType<FolderType>;
  sharedWithWorkspaceFolders: StateItemsListType<FolderType>;
  filters: CampaignFiltersType;
};

const initialState: CampaignsStateType = {
  folders: {
    items: [],
    loadingStatus: loadingStatuses.PENDING,
  },
  sharedWithMeFolders: {
    items: [],
    loadingStatus: loadingStatuses.PENDING,
  },
  sharedWithWorkspaceFolders: {
    items: [],
    loadingStatus: loadingStatuses.PENDING,
  },
  filters: {
    personIds: [],
    dateRange: undefined,
    titleSearch: null,
    status: undefined,
    extendedFilters: [],
  },
};

export default handleActions(
  {
    [setCampaignFolders]: (state: CampaignsStateType, { payload }): CampaignsStateType => ({
      ...state,
      folders: {
        ...state.folders,
        items: payload,
      },
    }),
    [setCampaignFoldersLs]: (state: CampaignsStateType, { payload }): CampaignsStateType => ({
      ...state,
      folders: {
        ...state.folders,
        loadingStatus: payload,
      },
    }),
    [addCampaignFolders]: (state: CampaignsStateType, { payload }): CampaignsStateType => ({
      ...state,
      folders: {
        ...state.folders,
        items: [...state.folders.items, ...payload],
      },
    }),
    [addNewOneCampaignsFolder]: (state: CampaignsStateType, { payload }): CampaignsStateType => ({
      ...state,
      folders: {
        ...state.folders,
        items: [payload, ...state.folders.items],
      },
    }),
    [removeCampaignFolder]: (state: CampaignsStateType, { payload }): CampaignsStateType => ({
      ...state,
      folders: {
        ...state.folders,
        items: state.folders.items.filter(({ id }) => id !== payload),
      },
    }),
    [updateCampaignFolder]: (state: CampaignsStateType, { payload }): CampaignsStateType => ({
      ...state,
      folders: {
        ...state.folders,
        items: updateItemInListByIdHelper(state.folders.items, payload),
      },
    }),
    [setPersonsIdsForFilterCampaigns]: (
      state: CampaignsStateType,
      { payload }
    ): CampaignsStateType => ({
      ...state,
      filters: {
        ...state.filters,
        personIds: payload,
      },
    }),
    [setDaterangeForFilterCampaigns]: (
      state: CampaignsStateType,
      { payload }
    ): CampaignsStateType => ({
      ...state,
      filters: {
        ...state.filters,
        dateRange: payload,
      },
    }),
    [setTittleForFilterCampaign]: (state: CampaignsStateType, { payload }): CampaignsStateType => ({
      ...state,
      filters: {
        ...state.filters,
        titleSearch: payload,
      },
    }),
    [setStatusForFilterCampaign]: (state: CampaignsStateType, { payload }): CampaignsStateType => ({
      ...state,
      filters: {
        ...state.filters,
        status: payload,
      },
    }),
    [setExtendedFiltersCampaign]: (state: CampaignsStateType, { payload }): CampaignsStateType => ({
      ...state,
      filters: {
        ...state.filters,
        extendedFilters: payload,
      },
    }),
  },
  initialState
);
