import React from 'react';

import './ColoredCheckbox.scss';

const ColoredCheckbox = ({
  classValue = '',
  color,
  chartField,
  position = 'relative',
  isChecked,
  handleChange,
}: {
  classValue?: string;
  color?: string;
  chartField: string;
  position?: string;
  isChecked: boolean;
  handleChange: (chartField) => void;
}): JSX.Element => {
  let className = '';
  const classes = () => {
    if (classValue) {
      className += classValue;
    }

    return className;
  };

  return (
    <div
      className="colored-checkbox"
      style={{ '--checkbox-position': position } as React.CSSProperties}
    >
      <div className="colored-checkbox-round">
        <input
          id={`coloredCheckbox--${chartField}`}
          type="checkbox"
          onChange={() => handleChange(chartField)}
          checked={isChecked}
          className={`colored-checkbox-round-input ${classes()}`}
        />
        <label
          htmlFor={`coloredCheckbox--${chartField}`}
          style={{ '--checkbox-color': color } as React.CSSProperties}
        />
      </div>
    </div>
  );
};

export default ColoredCheckbox;