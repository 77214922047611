import React from 'react';

export default ({ color = '#2822B7' }: { color?: string }): JSX.Element => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.5833 9.20831V4.24998C15.5833 3.87426 15.434 3.51392 15.1684 3.24824C14.9027 2.98257 14.5424 2.83331 14.1666 2.83331H2.83329C2.45757 2.83331 2.09723 2.98257 1.83156 3.24824C1.56588 3.51392 1.41663 3.87426 1.41663 4.24998V12.75C1.41663 13.5291 2.05413 14.1666 2.83329 14.1666H8.49996"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.5833 4.95831L9.22954 8.99581C9.01086 9.13282 8.75802 9.20549 8.49996 9.20549C8.2419 9.20549 7.98906 9.13282 7.77038 8.99581L1.41663 4.95831"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.3333 13.4583L12.7499 14.875L15.5833 12.0416"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);