import React from 'react';

export default ({ color = '#202430' }) => (
  <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.01918 14.4671L7.70093 1.83561C7.8344 1.58336 8.03411 1.37227 8.27858 1.22503C8.52306 1.0778 8.80304 1 9.08843 1C9.37382 1 9.6538 1.0778 9.89828 1.22503C10.1427 1.37227 10.3425 1.58336 10.4759 1.83561L17.1577 14.4671C17.2831 14.7055 17.3446 14.9723 17.3363 15.2415C17.3279 15.5107 17.25 15.7732 17.11 16.0033C16.9701 16.2335 16.7729 16.4234 16.5377 16.5547C16.3025 16.686 16.0373 16.7541 15.7679 16.7524H2.40593C2.13684 16.7535 1.87202 16.6851 1.63723 16.5536C1.40244 16.4221 1.20568 16.2321 1.06608 16.002C0.926478 15.772 0.84879 15.5097 0.840571 15.2407C0.832353 14.9718 0.893884 14.7053 1.01918 14.4671V14.4671Z"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.08691 6.25244V10.7524"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.08691 14.5024C9.70823 14.5024 10.2119 13.9988 10.2119 13.3774C10.2119 12.7561 9.70823 12.2524 9.08691 12.2524C8.46559 12.2524 7.96191 12.7561 7.96191 13.3774C7.96191 13.9988 8.46559 14.5024 9.08691 14.5024Z"
      fill={color}
    />
  </svg>
);
