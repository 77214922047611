import React from 'react';

export default ({color = '#200E32'}) => (
  <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.5 1H3C1.89543 1 1 1.89543 1 3V11C1 12.1046 1.89543 13 3 13H13.5C14.6046 13 15.5 12.1046 15.5 11V3C15.5 1.89543 14.6046 1 13.5 1Z"
      stroke={color} strokeWidth="1.5"/>
    <path
      d="M3.912 10L6.504 4.36H7.536L10.16 10H8.896L8.216 8.432L8.704 8.776H5.352L5.848 8.432L5.168 10H3.912ZM7.016 5.672L5.984 8.12L5.768 7.816H8.288L8.104 8.12L7.048 5.672H7.016ZM10.6463 10V4.36H11.8943V10H10.6463Z"
      fill={color}/>
  </svg>
);
