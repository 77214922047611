// @ts-nocheck
import React, { useMemo } from 'react';
import ReactTooltip from 'react-tooltip';

import cn from 'class-names';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { SVGIcon } from '@uikit/Icon/Icon';
import { billingCreditsSelector } from '@redux/selectors/billings.selectors';
import { toggleNotificationsSidebar } from '@redux/actions/appState.actions';
import { appStateSelector } from '@redux/selectors/appState.selectors';

import urls from '@constants/urls';

import userLinksConfig from '@components/UserLinks/userLinksConfig';

import './UserLinks.scss';

function UserLinks(): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const { data } = useSelector(billingCreditsSelector);
  const { isPaymentStatusActive } = useSelector(appStateSelector);

  const handleOpenNotificationSidebar = (evt) => {
    evt.stopPropagation();
    dispatch(toggleNotificationsSidebar(true));
  };

  const handlerMapper = useMemo(
    () => ({
      notifications: handleOpenNotificationSidebar,
      account: (e) => {
        e.stopPropagation();
        history.push(urls.PROFILE);
      },
      billing: (e) => {
        e.stopPropagation();
        history.push(urls.BILLING);
        console.log('Redirecting to billing from user links component');
      },
    }),
    [history]
  );

  const propsMapper = useMemo(() => ({ billing: { credits: data } }), [data]);

  const cnUserLink = cn('user-link', {
    'user-link--short': true,
    'user-link--blocked': !isPaymentStatusActive,
  });

  const cnUserLinkWrapper = cn('user-links-wrapper', {
    'user-links-wrapper--blocked': !isPaymentStatusActive,
  });

  return (
    <div className={cnUserLinkWrapper}>
      {userLinksConfig.map(({ key, icon, positionElements, Children, Tooltip }) => {
        return (
          <div
            key={key}
            className={`${cnUserLink} user-link--${positionElements}`}
            onClick={isPaymentStatusActive ? handlerMapper[key] : () => null}
            tabIndex={0}
            role="button"
            data-for={`user-link-${key}`}
            data-tip=""
          >
            <div className="user-link__icon">
              <SVGIcon icon={icon} color="#BDBDBD" />
            </div>

            <Children renderProps={propsMapper[key]} />

            {Tooltip && (
              <ReactTooltip
                id={`user-link-${key}`}
                className="react-tooltip"
                place="right"
                effect="solid"
                offset={{ right: 20, top: 0 }}
                arrowColor="transparent"
              >
                <div className="user-link__tooltip-content">
                  <Tooltip renderProps={propsMapper[key]} />
                </div>
              </ReactTooltip>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default UserLinks;
