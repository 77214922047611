import AuthSharedService from '../../../respona_api/generated/auth-shared_pb_service';

const service = (function () {
  let instance = null;

  return {
    getInstance: (): AuthSharedService.AuthSharedApiServiceClient => {
      if (!instance) {
        // @ts-ignore
        instance = new AuthSharedService.AuthSharedApiServiceClient(API_HOST);
      }

      return instance;
    },
  };
})();

export default service;
