// source: user-organization.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var mailbox_pb = require('./mailbox_pb.js');
goog.object.extend(proto, mailbox_pb);
var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
goog.exportSymbol('proto.users.AppIntegrationRequest', null, global);
goog.exportSymbol('proto.users.AppIntegrationRequest.SettingsCase', null, global);
goog.exportSymbol('proto.users.AppIntegrationResponse', null, global);
goog.exportSymbol('proto.users.AppIntegrationResponse.SettingsCase', null, global);
goog.exportSymbol('proto.users.DailySchedule', null, global);
goog.exportSymbol('proto.users.InviteMemberRequest', null, global);
goog.exportSymbol('proto.users.OrganizationChecklistResponse', null, global);
goog.exportSymbol('proto.users.OrganizationChecklistTask', null, global);
goog.exportSymbol('proto.users.OrganizationChecklistTaskState', null, global);
goog.exportSymbol('proto.users.OrganizationFullResponse', null, global);
goog.exportSymbol('proto.users.OrganizationInviteResponse', null, global);
goog.exportSymbol('proto.users.OrganizationInviteStatus', null, global);
goog.exportSymbol('proto.users.OrganizationMemberInviteResponse', null, global);
goog.exportSymbol('proto.users.OrganizationMemberResponse', null, global);
goog.exportSymbol('proto.users.OrganizationMemberRole', null, global);
goog.exportSymbol('proto.users.OrganizationRequest', null, global);
goog.exportSymbol('proto.users.OrganizationResponse', null, global);
goog.exportSymbol('proto.users.ReferralLinkData', null, global);
goog.exportSymbol('proto.users.RemoveMemberRequest', null, global);
goog.exportSymbol('proto.users.Schedule', null, global);
goog.exportSymbol('proto.users.ScheduleMember', null, global);
goog.exportSymbol('proto.users.ScheduleType', null, global);
goog.exportSymbol('proto.users.TrackingDomain', null, global);
goog.exportSymbol('proto.users.TrackingDomainDnsSettings', null, global);
goog.exportSymbol('proto.users.UnsubscribeContent', null, global);
goog.exportSymbol('proto.users.UpdateMemberRequest', null, global);
goog.exportSymbol('proto.users.WorkspaceMemberRequest', null, global);
goog.exportSymbol('proto.users.WorkspaceMemberResponse', null, global);
goog.exportSymbol('proto.users.WorkspaceMemberShortResponse', null, global);
goog.exportSymbol('proto.users.WorkspaceRequest', null, global);
goog.exportSymbol('proto.users.WorkspaceResponse', null, global);
goog.exportSymbol('proto.users.WorkspaceResponseShort', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users.OrganizationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.users.OrganizationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users.OrganizationRequest.displayName = 'proto.users.OrganizationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users.OrganizationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.users.OrganizationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users.OrganizationResponse.displayName = 'proto.users.OrganizationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users.OrganizationFullResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.users.OrganizationFullResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users.OrganizationFullResponse.displayName = 'proto.users.OrganizationFullResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users.WorkspaceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.users.WorkspaceRequest.repeatedFields_, null);
};
goog.inherits(proto.users.WorkspaceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users.WorkspaceRequest.displayName = 'proto.users.WorkspaceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users.WorkspaceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.users.WorkspaceResponse.repeatedFields_, null);
};
goog.inherits(proto.users.WorkspaceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users.WorkspaceResponse.displayName = 'proto.users.WorkspaceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users.WorkspaceResponseShort = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.users.WorkspaceResponseShort, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users.WorkspaceResponseShort.displayName = 'proto.users.WorkspaceResponseShort';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users.WorkspaceMemberRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.users.WorkspaceMemberRequest.repeatedFields_, null);
};
goog.inherits(proto.users.WorkspaceMemberRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users.WorkspaceMemberRequest.displayName = 'proto.users.WorkspaceMemberRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users.Schedule = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.users.Schedule.repeatedFields_, null);
};
goog.inherits(proto.users.Schedule, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users.Schedule.displayName = 'proto.users.Schedule';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users.DailySchedule = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.users.DailySchedule, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users.DailySchedule.displayName = 'proto.users.DailySchedule';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users.ScheduleMember = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.users.ScheduleMember.repeatedFields_, null);
};
goog.inherits(proto.users.ScheduleMember, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users.ScheduleMember.displayName = 'proto.users.ScheduleMember';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users.InviteMemberRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.users.InviteMemberRequest.repeatedFields_, null);
};
goog.inherits(proto.users.InviteMemberRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users.InviteMemberRequest.displayName = 'proto.users.InviteMemberRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users.RemoveMemberRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.users.RemoveMemberRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users.RemoveMemberRequest.displayName = 'proto.users.RemoveMemberRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users.UpdateMemberRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.users.UpdateMemberRequest.repeatedFields_, null);
};
goog.inherits(proto.users.UpdateMemberRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users.UpdateMemberRequest.displayName = 'proto.users.UpdateMemberRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users.OrganizationMemberResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.users.OrganizationMemberResponse.repeatedFields_, null);
};
goog.inherits(proto.users.OrganizationMemberResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users.OrganizationMemberResponse.displayName = 'proto.users.OrganizationMemberResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users.OrganizationInviteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.users.OrganizationInviteResponse.repeatedFields_, null);
};
goog.inherits(proto.users.OrganizationInviteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users.OrganizationInviteResponse.displayName = 'proto.users.OrganizationInviteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users.WorkspaceMemberResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.users.WorkspaceMemberResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users.WorkspaceMemberResponse.displayName = 'proto.users.WorkspaceMemberResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users.WorkspaceMemberShortResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.users.WorkspaceMemberShortResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users.WorkspaceMemberShortResponse.displayName = 'proto.users.WorkspaceMemberShortResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users.OrganizationMemberInviteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.users.OrganizationMemberInviteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users.OrganizationMemberInviteResponse.displayName = 'proto.users.OrganizationMemberInviteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users.OrganizationChecklistTaskState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.users.OrganizationChecklistTaskState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users.OrganizationChecklistTaskState.displayName = 'proto.users.OrganizationChecklistTaskState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users.OrganizationChecklistResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.users.OrganizationChecklistResponse.repeatedFields_, null);
};
goog.inherits(proto.users.OrganizationChecklistResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users.OrganizationChecklistResponse.displayName = 'proto.users.OrganizationChecklistResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users.AppIntegrationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.users.AppIntegrationRequest.repeatedFields_, proto.users.AppIntegrationRequest.oneofGroups_);
};
goog.inherits(proto.users.AppIntegrationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users.AppIntegrationRequest.displayName = 'proto.users.AppIntegrationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users.AppIntegrationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.users.AppIntegrationResponse.repeatedFields_, proto.users.AppIntegrationResponse.oneofGroups_);
};
goog.inherits(proto.users.AppIntegrationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users.AppIntegrationResponse.displayName = 'proto.users.AppIntegrationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users.UnsubscribeContent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.users.UnsubscribeContent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users.UnsubscribeContent.displayName = 'proto.users.UnsubscribeContent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users.TrackingDomainDnsSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.users.TrackingDomainDnsSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users.TrackingDomainDnsSettings.displayName = 'proto.users.TrackingDomainDnsSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users.TrackingDomain = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.users.TrackingDomain, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users.TrackingDomain.displayName = 'proto.users.TrackingDomain';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users.ReferralLinkData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.users.ReferralLinkData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users.ReferralLinkData.displayName = 'proto.users.ReferralLinkData';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users.OrganizationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.users.OrganizationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users.OrganizationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.OrganizationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    logoUrl: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users.OrganizationRequest}
 */
proto.users.OrganizationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users.OrganizationRequest;
  return proto.users.OrganizationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users.OrganizationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users.OrganizationRequest}
 */
proto.users.OrganizationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogoUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users.OrganizationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users.OrganizationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users.OrganizationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.OrganizationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLogoUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.users.OrganizationRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.OrganizationRequest} returns this
 */
proto.users.OrganizationRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string logo_url = 2;
 * @return {string}
 */
proto.users.OrganizationRequest.prototype.getLogoUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.OrganizationRequest} returns this
 */
proto.users.OrganizationRequest.prototype.setLogoUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users.OrganizationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.users.OrganizationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users.OrganizationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.OrganizationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    logoUrl: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users.OrganizationResponse}
 */
proto.users.OrganizationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users.OrganizationResponse;
  return proto.users.OrganizationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users.OrganizationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users.OrganizationResponse}
 */
proto.users.OrganizationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogoUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users.OrganizationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users.OrganizationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users.OrganizationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.OrganizationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLogoUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.users.OrganizationResponse.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.OrganizationResponse} returns this
 */
proto.users.OrganizationResponse.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string logo_url = 2;
 * @return {string}
 */
proto.users.OrganizationResponse.prototype.getLogoUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.OrganizationResponse} returns this
 */
proto.users.OrganizationResponse.prototype.setLogoUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users.OrganizationFullResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.users.OrganizationFullResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users.OrganizationFullResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.OrganizationFullResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    logoUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    membersNumber: jspb.Message.getFieldWithDefault(msg, 3, 0),
    workspacesNumber: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users.OrganizationFullResponse}
 */
proto.users.OrganizationFullResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users.OrganizationFullResponse;
  return proto.users.OrganizationFullResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users.OrganizationFullResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users.OrganizationFullResponse}
 */
proto.users.OrganizationFullResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogoUrl(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMembersNumber(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWorkspacesNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users.OrganizationFullResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users.OrganizationFullResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users.OrganizationFullResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.OrganizationFullResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLogoUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMembersNumber();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getWorkspacesNumber();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.users.OrganizationFullResponse.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.OrganizationFullResponse} returns this
 */
proto.users.OrganizationFullResponse.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string logo_url = 2;
 * @return {string}
 */
proto.users.OrganizationFullResponse.prototype.getLogoUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.OrganizationFullResponse} returns this
 */
proto.users.OrganizationFullResponse.prototype.setLogoUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 members_number = 3;
 * @return {number}
 */
proto.users.OrganizationFullResponse.prototype.getMembersNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.OrganizationFullResponse} returns this
 */
proto.users.OrganizationFullResponse.prototype.setMembersNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 workspaces_number = 4;
 * @return {number}
 */
proto.users.OrganizationFullResponse.prototype.getWorkspacesNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.OrganizationFullResponse} returns this
 */
proto.users.OrganizationFullResponse.prototype.setWorkspacesNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.users.WorkspaceRequest.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users.WorkspaceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.users.WorkspaceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users.WorkspaceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.WorkspaceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    logoUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    accountIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    userIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users.WorkspaceRequest}
 */
proto.users.WorkspaceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users.WorkspaceRequest;
  return proto.users.WorkspaceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users.WorkspaceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users.WorkspaceRequest}
 */
proto.users.WorkspaceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogoUrl(value);
      break;
    case 4:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setAccountIdsList(value);
      break;
    case 5:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setUserIdsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users.WorkspaceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users.WorkspaceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users.WorkspaceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.WorkspaceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLogoUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccountIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      4,
      f
    );
  }
  f = message.getUserIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      5,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.users.WorkspaceRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.WorkspaceRequest} returns this
 */
proto.users.WorkspaceRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.users.WorkspaceRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.WorkspaceRequest} returns this
 */
proto.users.WorkspaceRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string logo_url = 3;
 * @return {string}
 */
proto.users.WorkspaceRequest.prototype.getLogoUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.WorkspaceRequest} returns this
 */
proto.users.WorkspaceRequest.prototype.setLogoUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated int64 account_ids = 4;
 * @return {!Array<number>}
 */
proto.users.WorkspaceRequest.prototype.getAccountIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.users.WorkspaceRequest} returns this
 */
proto.users.WorkspaceRequest.prototype.setAccountIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.users.WorkspaceRequest} returns this
 */
proto.users.WorkspaceRequest.prototype.addAccountIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.users.WorkspaceRequest} returns this
 */
proto.users.WorkspaceRequest.prototype.clearAccountIdsList = function() {
  return this.setAccountIdsList([]);
};


/**
 * repeated int64 user_ids = 5;
 * @return {!Array<number>}
 */
proto.users.WorkspaceRequest.prototype.getUserIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.users.WorkspaceRequest} returns this
 */
proto.users.WorkspaceRequest.prototype.setUserIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.users.WorkspaceRequest} returns this
 */
proto.users.WorkspaceRequest.prototype.addUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.users.WorkspaceRequest} returns this
 */
proto.users.WorkspaceRequest.prototype.clearUserIdsList = function() {
  return this.setUserIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.users.WorkspaceResponse.repeatedFields_ = [7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users.WorkspaceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.users.WorkspaceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users.WorkspaceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.WorkspaceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    logoUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    membersCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    mailboxesCount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    campaignsCount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    accountsList: jspb.Message.toObjectList(msg.getAccountsList(),
    mailbox_pb.InboxAccountsPreset.toObject, includeInstance),
    membersList: jspb.Message.toObjectList(msg.getMembersList(),
    proto.users.WorkspaceMemberShortResponse.toObject, includeInstance),
    favorite: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users.WorkspaceResponse}
 */
proto.users.WorkspaceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users.WorkspaceResponse;
  return proto.users.WorkspaceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users.WorkspaceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users.WorkspaceResponse}
 */
proto.users.WorkspaceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogoUrl(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMembersCount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMailboxesCount(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCampaignsCount(value);
      break;
    case 7:
      var value = new mailbox_pb.InboxAccountsPreset;
      reader.readMessage(value,mailbox_pb.InboxAccountsPreset.deserializeBinaryFromReader);
      msg.addAccounts(value);
      break;
    case 8:
      var value = new proto.users.WorkspaceMemberShortResponse;
      reader.readMessage(value,proto.users.WorkspaceMemberShortResponse.deserializeBinaryFromReader);
      msg.addMembers(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFavorite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users.WorkspaceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users.WorkspaceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users.WorkspaceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.WorkspaceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLogoUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMembersCount();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getMailboxesCount();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getCampaignsCount();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      mailbox_pb.InboxAccountsPreset.serializeBinaryToWriter
    );
  }
  f = message.getMembersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.users.WorkspaceMemberShortResponse.serializeBinaryToWriter
    );
  }
  f = message.getFavorite();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.users.WorkspaceResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.WorkspaceResponse} returns this
 */
proto.users.WorkspaceResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.users.WorkspaceResponse.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.WorkspaceResponse} returns this
 */
proto.users.WorkspaceResponse.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string logo_url = 3;
 * @return {string}
 */
proto.users.WorkspaceResponse.prototype.getLogoUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.WorkspaceResponse} returns this
 */
proto.users.WorkspaceResponse.prototype.setLogoUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 members_count = 4;
 * @return {number}
 */
proto.users.WorkspaceResponse.prototype.getMembersCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.WorkspaceResponse} returns this
 */
proto.users.WorkspaceResponse.prototype.setMembersCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 mailboxes_count = 5;
 * @return {number}
 */
proto.users.WorkspaceResponse.prototype.getMailboxesCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.WorkspaceResponse} returns this
 */
proto.users.WorkspaceResponse.prototype.setMailboxesCount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 campaigns_count = 6;
 * @return {number}
 */
proto.users.WorkspaceResponse.prototype.getCampaignsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.WorkspaceResponse} returns this
 */
proto.users.WorkspaceResponse.prototype.setCampaignsCount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated inbox.InboxAccountsPreset accounts = 7;
 * @return {!Array<!proto.inbox.InboxAccountsPreset>}
 */
proto.users.WorkspaceResponse.prototype.getAccountsList = function() {
  return /** @type{!Array<!proto.inbox.InboxAccountsPreset>} */ (
    jspb.Message.getRepeatedWrapperField(this, mailbox_pb.InboxAccountsPreset, 7));
};


/**
 * @param {!Array<!proto.inbox.InboxAccountsPreset>} value
 * @return {!proto.users.WorkspaceResponse} returns this
*/
proto.users.WorkspaceResponse.prototype.setAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.inbox.InboxAccountsPreset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.inbox.InboxAccountsPreset}
 */
proto.users.WorkspaceResponse.prototype.addAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.inbox.InboxAccountsPreset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.users.WorkspaceResponse} returns this
 */
proto.users.WorkspaceResponse.prototype.clearAccountsList = function() {
  return this.setAccountsList([]);
};


/**
 * repeated WorkspaceMemberShortResponse members = 8;
 * @return {!Array<!proto.users.WorkspaceMemberShortResponse>}
 */
proto.users.WorkspaceResponse.prototype.getMembersList = function() {
  return /** @type{!Array<!proto.users.WorkspaceMemberShortResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.users.WorkspaceMemberShortResponse, 8));
};


/**
 * @param {!Array<!proto.users.WorkspaceMemberShortResponse>} value
 * @return {!proto.users.WorkspaceResponse} returns this
*/
proto.users.WorkspaceResponse.prototype.setMembersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.users.WorkspaceMemberShortResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.users.WorkspaceMemberShortResponse}
 */
proto.users.WorkspaceResponse.prototype.addMembers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.users.WorkspaceMemberShortResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.users.WorkspaceResponse} returns this
 */
proto.users.WorkspaceResponse.prototype.clearMembersList = function() {
  return this.setMembersList([]);
};


/**
 * optional bool favorite = 9;
 * @return {boolean}
 */
proto.users.WorkspaceResponse.prototype.getFavorite = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.users.WorkspaceResponse} returns this
 */
proto.users.WorkspaceResponse.prototype.setFavorite = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users.WorkspaceResponseShort.prototype.toObject = function(opt_includeInstance) {
  return proto.users.WorkspaceResponseShort.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users.WorkspaceResponseShort} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.WorkspaceResponseShort.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    logoUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    membersCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    favorite: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users.WorkspaceResponseShort}
 */
proto.users.WorkspaceResponseShort.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users.WorkspaceResponseShort;
  return proto.users.WorkspaceResponseShort.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users.WorkspaceResponseShort} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users.WorkspaceResponseShort}
 */
proto.users.WorkspaceResponseShort.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogoUrl(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMembersCount(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFavorite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users.WorkspaceResponseShort.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users.WorkspaceResponseShort.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users.WorkspaceResponseShort} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.WorkspaceResponseShort.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLogoUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMembersCount();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getFavorite();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.users.WorkspaceResponseShort.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.WorkspaceResponseShort} returns this
 */
proto.users.WorkspaceResponseShort.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.users.WorkspaceResponseShort.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.WorkspaceResponseShort} returns this
 */
proto.users.WorkspaceResponseShort.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string logo_url = 3;
 * @return {string}
 */
proto.users.WorkspaceResponseShort.prototype.getLogoUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.WorkspaceResponseShort} returns this
 */
proto.users.WorkspaceResponseShort.prototype.setLogoUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 members_count = 4;
 * @return {number}
 */
proto.users.WorkspaceResponseShort.prototype.getMembersCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.WorkspaceResponseShort} returns this
 */
proto.users.WorkspaceResponseShort.prototype.setMembersCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool favorite = 5;
 * @return {boolean}
 */
proto.users.WorkspaceResponseShort.prototype.getFavorite = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.users.WorkspaceResponseShort} returns this
 */
proto.users.WorkspaceResponseShort.prototype.setFavorite = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.users.WorkspaceMemberRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users.WorkspaceMemberRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.users.WorkspaceMemberRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users.WorkspaceMemberRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.WorkspaceMemberRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    userIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users.WorkspaceMemberRequest}
 */
proto.users.WorkspaceMemberRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users.WorkspaceMemberRequest;
  return proto.users.WorkspaceMemberRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users.WorkspaceMemberRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users.WorkspaceMemberRequest}
 */
proto.users.WorkspaceMemberRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setUserIdsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users.WorkspaceMemberRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users.WorkspaceMemberRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users.WorkspaceMemberRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.WorkspaceMemberRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUserIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 workspace_id = 2;
 * @return {number}
 */
proto.users.WorkspaceMemberRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.WorkspaceMemberRequest} returns this
 */
proto.users.WorkspaceMemberRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated int64 user_ids = 3;
 * @return {!Array<number>}
 */
proto.users.WorkspaceMemberRequest.prototype.getUserIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.users.WorkspaceMemberRequest} returns this
 */
proto.users.WorkspaceMemberRequest.prototype.setUserIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.users.WorkspaceMemberRequest} returns this
 */
proto.users.WorkspaceMemberRequest.prototype.addUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.users.WorkspaceMemberRequest} returns this
 */
proto.users.WorkspaceMemberRequest.prototype.clearUserIdsList = function() {
  return this.setUserIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.users.Schedule.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users.Schedule.prototype.toObject = function(opt_includeInstance) {
  return proto.users.Schedule.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users.Schedule} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.Schedule.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    timezoneId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    timezoneValue: jspb.Message.getFieldWithDefault(msg, 5, ""),
    dayList: jspb.Message.toObjectList(msg.getDayList(),
    proto.users.DailySchedule.toObject, includeInstance),
    deleted: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    optionalTotalItems: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users.Schedule}
 */
proto.users.Schedule.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users.Schedule;
  return proto.users.Schedule.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users.Schedule} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users.Schedule}
 */
proto.users.Schedule.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {!proto.users.ScheduleType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTimezoneId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezoneValue(value);
      break;
    case 6:
      var value = new proto.users.DailySchedule;
      reader.readMessage(value,proto.users.DailySchedule.deserializeBinaryFromReader);
      msg.addDay(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeleted(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOptionalTotalItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users.Schedule.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users.Schedule.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users.Schedule} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.Schedule.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getTimezoneId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getTimezoneValue();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDayList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.users.DailySchedule.serializeBinaryToWriter
    );
  }
  f = message.getDeleted();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getOptionalTotalItems();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.users.Schedule.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.Schedule} returns this
 */
proto.users.Schedule.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.users.Schedule.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.Schedule} returns this
 */
proto.users.Schedule.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ScheduleType type = 3;
 * @return {!proto.users.ScheduleType}
 */
proto.users.Schedule.prototype.getType = function() {
  return /** @type {!proto.users.ScheduleType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.users.ScheduleType} value
 * @return {!proto.users.Schedule} returns this
 */
proto.users.Schedule.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int32 timezone_id = 4;
 * @return {number}
 */
proto.users.Schedule.prototype.getTimezoneId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.Schedule} returns this
 */
proto.users.Schedule.prototype.setTimezoneId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string timezone_value = 5;
 * @return {string}
 */
proto.users.Schedule.prototype.getTimezoneValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.Schedule} returns this
 */
proto.users.Schedule.prototype.setTimezoneValue = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated DailySchedule day = 6;
 * @return {!Array<!proto.users.DailySchedule>}
 */
proto.users.Schedule.prototype.getDayList = function() {
  return /** @type{!Array<!proto.users.DailySchedule>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.users.DailySchedule, 6));
};


/**
 * @param {!Array<!proto.users.DailySchedule>} value
 * @return {!proto.users.Schedule} returns this
*/
proto.users.Schedule.prototype.setDayList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.users.DailySchedule=} opt_value
 * @param {number=} opt_index
 * @return {!proto.users.DailySchedule}
 */
proto.users.Schedule.prototype.addDay = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.users.DailySchedule, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.users.Schedule} returns this
 */
proto.users.Schedule.prototype.clearDayList = function() {
  return this.setDayList([]);
};


/**
 * optional bool deleted = 7;
 * @return {boolean}
 */
proto.users.Schedule.prototype.getDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.users.Schedule} returns this
 */
proto.users.Schedule.prototype.setDeleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional int32 optional_total_items = 8;
 * @return {number}
 */
proto.users.Schedule.prototype.getOptionalTotalItems = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.Schedule} returns this
 */
proto.users.Schedule.prototype.setOptionalTotalItems = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users.DailySchedule.prototype.toObject = function(opt_includeInstance) {
  return proto.users.DailySchedule.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users.DailySchedule} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.DailySchedule.toObject = function(includeInstance, msg) {
  var f, obj = {
    day: jspb.Message.getFieldWithDefault(msg, 1, 0),
    includeDay: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    timeFrom: jspb.Message.getFieldWithDefault(msg, 3, ""),
    timeTo: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users.DailySchedule}
 */
proto.users.DailySchedule.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users.DailySchedule;
  return proto.users.DailySchedule.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users.DailySchedule} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users.DailySchedule}
 */
proto.users.DailySchedule.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDay(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeDay(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimeFrom(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimeTo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users.DailySchedule.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users.DailySchedule.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users.DailySchedule} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.DailySchedule.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDay();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getIncludeDay();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getTimeFrom();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTimeTo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int32 day = 1;
 * @return {number}
 */
proto.users.DailySchedule.prototype.getDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.DailySchedule} returns this
 */
proto.users.DailySchedule.prototype.setDay = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool include_day = 2;
 * @return {boolean}
 */
proto.users.DailySchedule.prototype.getIncludeDay = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.users.DailySchedule} returns this
 */
proto.users.DailySchedule.prototype.setIncludeDay = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string time_from = 3;
 * @return {string}
 */
proto.users.DailySchedule.prototype.getTimeFrom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.DailySchedule} returns this
 */
proto.users.DailySchedule.prototype.setTimeFrom = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string time_to = 4;
 * @return {string}
 */
proto.users.DailySchedule.prototype.getTimeTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.DailySchedule} returns this
 */
proto.users.DailySchedule.prototype.setTimeTo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.users.ScheduleMember.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users.ScheduleMember.prototype.toObject = function(opt_includeInstance) {
  return proto.users.ScheduleMember.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users.ScheduleMember} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.ScheduleMember.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    scheduleIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users.ScheduleMember}
 */
proto.users.ScheduleMember.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users.ScheduleMember;
  return proto.users.ScheduleMember.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users.ScheduleMember} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users.ScheduleMember}
 */
proto.users.ScheduleMember.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 2:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setScheduleIdsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users.ScheduleMember.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users.ScheduleMember.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users.ScheduleMember} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.ScheduleMember.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getScheduleIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 workspace_id = 1;
 * @return {number}
 */
proto.users.ScheduleMember.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.ScheduleMember} returns this
 */
proto.users.ScheduleMember.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated int64 schedule_ids = 2;
 * @return {!Array<number>}
 */
proto.users.ScheduleMember.prototype.getScheduleIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.users.ScheduleMember} returns this
 */
proto.users.ScheduleMember.prototype.setScheduleIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.users.ScheduleMember} returns this
 */
proto.users.ScheduleMember.prototype.addScheduleIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.users.ScheduleMember} returns this
 */
proto.users.ScheduleMember.prototype.clearScheduleIdsList = function() {
  return this.setScheduleIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.users.InviteMemberRequest.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users.InviteMemberRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.users.InviteMemberRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users.InviteMemberRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.InviteMemberRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    role: jspb.Message.getFieldWithDefault(msg, 1, 0),
    emailsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    workspaceIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users.InviteMemberRequest}
 */
proto.users.InviteMemberRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users.InviteMemberRequest;
  return proto.users.InviteMemberRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users.InviteMemberRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users.InviteMemberRequest}
 */
proto.users.InviteMemberRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.users.OrganizationMemberRole} */ (reader.readEnum());
      msg.setRole(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addEmails(value);
      break;
    case 3:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setWorkspaceIdsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users.InviteMemberRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users.InviteMemberRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users.InviteMemberRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.InviteMemberRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRole();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getEmailsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getWorkspaceIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      3,
      f
    );
  }
};


/**
 * optional OrganizationMemberRole role = 1;
 * @return {!proto.users.OrganizationMemberRole}
 */
proto.users.InviteMemberRequest.prototype.getRole = function() {
  return /** @type {!proto.users.OrganizationMemberRole} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.users.OrganizationMemberRole} value
 * @return {!proto.users.InviteMemberRequest} returns this
 */
proto.users.InviteMemberRequest.prototype.setRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated string emails = 2;
 * @return {!Array<string>}
 */
proto.users.InviteMemberRequest.prototype.getEmailsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.users.InviteMemberRequest} returns this
 */
proto.users.InviteMemberRequest.prototype.setEmailsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.users.InviteMemberRequest} returns this
 */
proto.users.InviteMemberRequest.prototype.addEmails = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.users.InviteMemberRequest} returns this
 */
proto.users.InviteMemberRequest.prototype.clearEmailsList = function() {
  return this.setEmailsList([]);
};


/**
 * repeated int64 workspace_ids = 3;
 * @return {!Array<number>}
 */
proto.users.InviteMemberRequest.prototype.getWorkspaceIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.users.InviteMemberRequest} returns this
 */
proto.users.InviteMemberRequest.prototype.setWorkspaceIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.users.InviteMemberRequest} returns this
 */
proto.users.InviteMemberRequest.prototype.addWorkspaceIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.users.InviteMemberRequest} returns this
 */
proto.users.InviteMemberRequest.prototype.clearWorkspaceIdsList = function() {
  return this.setWorkspaceIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users.RemoveMemberRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.users.RemoveMemberRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users.RemoveMemberRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.RemoveMemberRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    optionalUserIdForTransferData: jspb.Message.getFieldWithDefault(msg, 2, 0),
    removeData: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users.RemoveMemberRequest}
 */
proto.users.RemoveMemberRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users.RemoveMemberRequest;
  return proto.users.RemoveMemberRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users.RemoveMemberRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users.RemoveMemberRequest}
 */
proto.users.RemoveMemberRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOptionalUserIdForTransferData(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRemoveData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users.RemoveMemberRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users.RemoveMemberRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users.RemoveMemberRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.RemoveMemberRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOptionalUserIdForTransferData();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getRemoveData();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.users.RemoveMemberRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.RemoveMemberRequest} returns this
 */
proto.users.RemoveMemberRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 optional_user_id_for_transfer_data = 2;
 * @return {number}
 */
proto.users.RemoveMemberRequest.prototype.getOptionalUserIdForTransferData = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.RemoveMemberRequest} returns this
 */
proto.users.RemoveMemberRequest.prototype.setOptionalUserIdForTransferData = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool remove_data = 3;
 * @return {boolean}
 */
proto.users.RemoveMemberRequest.prototype.getRemoveData = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.users.RemoveMemberRequest} returns this
 */
proto.users.RemoveMemberRequest.prototype.setRemoveData = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.users.UpdateMemberRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users.UpdateMemberRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.users.UpdateMemberRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users.UpdateMemberRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.UpdateMemberRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    role: jspb.Message.getFieldWithDefault(msg, 2, 0),
    workspaceIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users.UpdateMemberRequest}
 */
proto.users.UpdateMemberRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users.UpdateMemberRequest;
  return proto.users.UpdateMemberRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users.UpdateMemberRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users.UpdateMemberRequest}
 */
proto.users.UpdateMemberRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.users.OrganizationMemberRole} */ (reader.readEnum());
      msg.setRole(value);
      break;
    case 3:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setWorkspaceIdsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users.UpdateMemberRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users.UpdateMemberRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users.UpdateMemberRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.UpdateMemberRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getRole();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getWorkspaceIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.users.UpdateMemberRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.UpdateMemberRequest} returns this
 */
proto.users.UpdateMemberRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional OrganizationMemberRole role = 2;
 * @return {!proto.users.OrganizationMemberRole}
 */
proto.users.UpdateMemberRequest.prototype.getRole = function() {
  return /** @type {!proto.users.OrganizationMemberRole} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.users.OrganizationMemberRole} value
 * @return {!proto.users.UpdateMemberRequest} returns this
 */
proto.users.UpdateMemberRequest.prototype.setRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated int64 workspace_ids = 3;
 * @return {!Array<number>}
 */
proto.users.UpdateMemberRequest.prototype.getWorkspaceIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.users.UpdateMemberRequest} returns this
 */
proto.users.UpdateMemberRequest.prototype.setWorkspaceIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.users.UpdateMemberRequest} returns this
 */
proto.users.UpdateMemberRequest.prototype.addWorkspaceIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.users.UpdateMemberRequest} returns this
 */
proto.users.UpdateMemberRequest.prototype.clearWorkspaceIdsList = function() {
  return this.setWorkspaceIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.users.OrganizationMemberResponse.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users.OrganizationMemberResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.users.OrganizationMemberResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users.OrganizationMemberResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.OrganizationMemberResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    fullName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    logoUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    rolesList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    email: jspb.Message.getFieldWithDefault(msg, 6, ""),
    workspacesNumber: jspb.Message.getFieldWithDefault(msg, 7, 0),
    invitedAt: jspb.Message.getFieldWithDefault(msg, 8, 0),
    invitedBy: jspb.Message.getFieldWithDefault(msg, 9, ""),
    totalItems: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users.OrganizationMemberResponse}
 */
proto.users.OrganizationMemberResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users.OrganizationMemberResponse;
  return proto.users.OrganizationMemberResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users.OrganizationMemberResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users.OrganizationMemberResponse}
 */
proto.users.OrganizationMemberResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogoUrl(value);
      break;
    case 5:
      var value = /** @type {!Array<!proto.users.OrganizationMemberRole>} */ (reader.readPackedEnum());
      msg.setRolesList(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWorkspacesNumber(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInvitedAt(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvitedBy(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users.OrganizationMemberResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users.OrganizationMemberResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users.OrganizationMemberResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.OrganizationMemberResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getFullName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLogoUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRolesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      5,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getWorkspacesNumber();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getInvitedAt();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getInvitedBy();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getTotalItems();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.users.OrganizationMemberResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.OrganizationMemberResponse} returns this
 */
proto.users.OrganizationMemberResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 user_id = 2;
 * @return {number}
 */
proto.users.OrganizationMemberResponse.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.OrganizationMemberResponse} returns this
 */
proto.users.OrganizationMemberResponse.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string full_name = 3;
 * @return {string}
 */
proto.users.OrganizationMemberResponse.prototype.getFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.OrganizationMemberResponse} returns this
 */
proto.users.OrganizationMemberResponse.prototype.setFullName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string logo_url = 4;
 * @return {string}
 */
proto.users.OrganizationMemberResponse.prototype.getLogoUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.OrganizationMemberResponse} returns this
 */
proto.users.OrganizationMemberResponse.prototype.setLogoUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated OrganizationMemberRole roles = 5;
 * @return {!Array<!proto.users.OrganizationMemberRole>}
 */
proto.users.OrganizationMemberResponse.prototype.getRolesList = function() {
  return /** @type {!Array<!proto.users.OrganizationMemberRole>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<!proto.users.OrganizationMemberRole>} value
 * @return {!proto.users.OrganizationMemberResponse} returns this
 */
proto.users.OrganizationMemberResponse.prototype.setRolesList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {!proto.users.OrganizationMemberRole} value
 * @param {number=} opt_index
 * @return {!proto.users.OrganizationMemberResponse} returns this
 */
proto.users.OrganizationMemberResponse.prototype.addRoles = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.users.OrganizationMemberResponse} returns this
 */
proto.users.OrganizationMemberResponse.prototype.clearRolesList = function() {
  return this.setRolesList([]);
};


/**
 * optional string email = 6;
 * @return {string}
 */
proto.users.OrganizationMemberResponse.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.OrganizationMemberResponse} returns this
 */
proto.users.OrganizationMemberResponse.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 workspaces_number = 7;
 * @return {number}
 */
proto.users.OrganizationMemberResponse.prototype.getWorkspacesNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.OrganizationMemberResponse} returns this
 */
proto.users.OrganizationMemberResponse.prototype.setWorkspacesNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 invited_at = 8;
 * @return {number}
 */
proto.users.OrganizationMemberResponse.prototype.getInvitedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.OrganizationMemberResponse} returns this
 */
proto.users.OrganizationMemberResponse.prototype.setInvitedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string invited_by = 9;
 * @return {string}
 */
proto.users.OrganizationMemberResponse.prototype.getInvitedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.OrganizationMemberResponse} returns this
 */
proto.users.OrganizationMemberResponse.prototype.setInvitedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int32 total_items = 10;
 * @return {number}
 */
proto.users.OrganizationMemberResponse.prototype.getTotalItems = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.OrganizationMemberResponse} returns this
 */
proto.users.OrganizationMemberResponse.prototype.setTotalItems = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.users.OrganizationInviteResponse.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users.OrganizationInviteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.users.OrganizationInviteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users.OrganizationInviteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.OrganizationInviteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    role: jspb.Message.getFieldWithDefault(msg, 4, 0),
    sentAt: jspb.Message.getFieldWithDefault(msg, 5, 0),
    sentBy: jspb.Message.getFieldWithDefault(msg, 6, ""),
    workspaceIdsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    totalItems: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users.OrganizationInviteResponse}
 */
proto.users.OrganizationInviteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users.OrganizationInviteResponse;
  return proto.users.OrganizationInviteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users.OrganizationInviteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users.OrganizationInviteResponse}
 */
proto.users.OrganizationInviteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {!proto.users.OrganizationInviteStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {!proto.users.OrganizationMemberRole} */ (reader.readEnum());
      msg.setRole(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSentAt(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSentBy(value);
      break;
    case 7:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setWorkspaceIdsList(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users.OrganizationInviteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users.OrganizationInviteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users.OrganizationInviteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.OrganizationInviteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getRole();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getSentAt();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getSentBy();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getWorkspaceIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      7,
      f
    );
  }
  f = message.getTotalItems();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.users.OrganizationInviteResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.OrganizationInviteResponse} returns this
 */
proto.users.OrganizationInviteResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.users.OrganizationInviteResponse.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.OrganizationInviteResponse} returns this
 */
proto.users.OrganizationInviteResponse.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional OrganizationInviteStatus status = 3;
 * @return {!proto.users.OrganizationInviteStatus}
 */
proto.users.OrganizationInviteResponse.prototype.getStatus = function() {
  return /** @type {!proto.users.OrganizationInviteStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.users.OrganizationInviteStatus} value
 * @return {!proto.users.OrganizationInviteResponse} returns this
 */
proto.users.OrganizationInviteResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional OrganizationMemberRole role = 4;
 * @return {!proto.users.OrganizationMemberRole}
 */
proto.users.OrganizationInviteResponse.prototype.getRole = function() {
  return /** @type {!proto.users.OrganizationMemberRole} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.users.OrganizationMemberRole} value
 * @return {!proto.users.OrganizationInviteResponse} returns this
 */
proto.users.OrganizationInviteResponse.prototype.setRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int64 sent_at = 5;
 * @return {number}
 */
proto.users.OrganizationInviteResponse.prototype.getSentAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.OrganizationInviteResponse} returns this
 */
proto.users.OrganizationInviteResponse.prototype.setSentAt = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string sent_by = 6;
 * @return {string}
 */
proto.users.OrganizationInviteResponse.prototype.getSentBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.OrganizationInviteResponse} returns this
 */
proto.users.OrganizationInviteResponse.prototype.setSentBy = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated int64 workspace_ids = 7;
 * @return {!Array<number>}
 */
proto.users.OrganizationInviteResponse.prototype.getWorkspaceIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.users.OrganizationInviteResponse} returns this
 */
proto.users.OrganizationInviteResponse.prototype.setWorkspaceIdsList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.users.OrganizationInviteResponse} returns this
 */
proto.users.OrganizationInviteResponse.prototype.addWorkspaceIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.users.OrganizationInviteResponse} returns this
 */
proto.users.OrganizationInviteResponse.prototype.clearWorkspaceIdsList = function() {
  return this.setWorkspaceIdsList([]);
};


/**
 * optional int32 total_items = 8;
 * @return {number}
 */
proto.users.OrganizationInviteResponse.prototype.getTotalItems = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.OrganizationInviteResponse} returns this
 */
proto.users.OrganizationInviteResponse.prototype.setTotalItems = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users.WorkspaceMemberResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.users.WorkspaceMemberResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users.WorkspaceMemberResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.WorkspaceMemberResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    fullName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    logoUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    email: jspb.Message.getFieldWithDefault(msg, 5, ""),
    totalItems: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users.WorkspaceMemberResponse}
 */
proto.users.WorkspaceMemberResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users.WorkspaceMemberResponse;
  return proto.users.WorkspaceMemberResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users.WorkspaceMemberResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users.WorkspaceMemberResponse}
 */
proto.users.WorkspaceMemberResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogoUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users.WorkspaceMemberResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users.WorkspaceMemberResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users.WorkspaceMemberResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.WorkspaceMemberResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getFullName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLogoUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTotalItems();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.users.WorkspaceMemberResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.WorkspaceMemberResponse} returns this
 */
proto.users.WorkspaceMemberResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 user_id = 2;
 * @return {number}
 */
proto.users.WorkspaceMemberResponse.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.WorkspaceMemberResponse} returns this
 */
proto.users.WorkspaceMemberResponse.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string full_name = 3;
 * @return {string}
 */
proto.users.WorkspaceMemberResponse.prototype.getFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.WorkspaceMemberResponse} returns this
 */
proto.users.WorkspaceMemberResponse.prototype.setFullName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string logo_url = 4;
 * @return {string}
 */
proto.users.WorkspaceMemberResponse.prototype.getLogoUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.WorkspaceMemberResponse} returns this
 */
proto.users.WorkspaceMemberResponse.prototype.setLogoUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string email = 5;
 * @return {string}
 */
proto.users.WorkspaceMemberResponse.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.WorkspaceMemberResponse} returns this
 */
proto.users.WorkspaceMemberResponse.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 total_items = 6;
 * @return {number}
 */
proto.users.WorkspaceMemberResponse.prototype.getTotalItems = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.WorkspaceMemberResponse} returns this
 */
proto.users.WorkspaceMemberResponse.prototype.setTotalItems = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users.WorkspaceMemberShortResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.users.WorkspaceMemberShortResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users.WorkspaceMemberShortResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.WorkspaceMemberShortResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    fullName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    logoUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isMe: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users.WorkspaceMemberShortResponse}
 */
proto.users.WorkspaceMemberShortResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users.WorkspaceMemberShortResponse;
  return proto.users.WorkspaceMemberShortResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users.WorkspaceMemberShortResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users.WorkspaceMemberShortResponse}
 */
proto.users.WorkspaceMemberShortResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogoUrl(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsMe(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users.WorkspaceMemberShortResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users.WorkspaceMemberShortResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users.WorkspaceMemberShortResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.WorkspaceMemberShortResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getFullName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLogoUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsMe();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional int64 user_id = 1;
 * @return {number}
 */
proto.users.WorkspaceMemberShortResponse.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.WorkspaceMemberShortResponse} returns this
 */
proto.users.WorkspaceMemberShortResponse.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string full_name = 2;
 * @return {string}
 */
proto.users.WorkspaceMemberShortResponse.prototype.getFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.WorkspaceMemberShortResponse} returns this
 */
proto.users.WorkspaceMemberShortResponse.prototype.setFullName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string logo_url = 3;
 * @return {string}
 */
proto.users.WorkspaceMemberShortResponse.prototype.getLogoUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.WorkspaceMemberShortResponse} returns this
 */
proto.users.WorkspaceMemberShortResponse.prototype.setLogoUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool is_me = 4;
 * @return {boolean}
 */
proto.users.WorkspaceMemberShortResponse.prototype.getIsMe = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.users.WorkspaceMemberShortResponse} returns this
 */
proto.users.WorkspaceMemberShortResponse.prototype.setIsMe = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users.OrganizationMemberInviteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.users.OrganizationMemberInviteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users.OrganizationMemberInviteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.OrganizationMemberInviteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    organizationTitle: jspb.Message.getFieldWithDefault(msg, 2, ""),
    organizationLogo: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users.OrganizationMemberInviteResponse}
 */
proto.users.OrganizationMemberInviteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users.OrganizationMemberInviteResponse;
  return proto.users.OrganizationMemberInviteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users.OrganizationMemberInviteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users.OrganizationMemberInviteResponse}
 */
proto.users.OrganizationMemberInviteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationLogo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users.OrganizationMemberInviteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users.OrganizationMemberInviteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users.OrganizationMemberInviteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.OrganizationMemberInviteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrganizationTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrganizationLogo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.users.OrganizationMemberInviteResponse.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.OrganizationMemberInviteResponse} returns this
 */
proto.users.OrganizationMemberInviteResponse.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string organization_title = 2;
 * @return {string}
 */
proto.users.OrganizationMemberInviteResponse.prototype.getOrganizationTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.OrganizationMemberInviteResponse} returns this
 */
proto.users.OrganizationMemberInviteResponse.prototype.setOrganizationTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string organization_logo = 3;
 * @return {string}
 */
proto.users.OrganizationMemberInviteResponse.prototype.getOrganizationLogo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.OrganizationMemberInviteResponse} returns this
 */
proto.users.OrganizationMemberInviteResponse.prototype.setOrganizationLogo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users.OrganizationChecklistTaskState.prototype.toObject = function(opt_includeInstance) {
  return proto.users.OrganizationChecklistTaskState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users.OrganizationChecklistTaskState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.OrganizationChecklistTaskState.toObject = function(includeInstance, msg) {
  var f, obj = {
    task: jspb.Message.getFieldWithDefault(msg, 1, 0),
    complete: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users.OrganizationChecklistTaskState}
 */
proto.users.OrganizationChecklistTaskState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users.OrganizationChecklistTaskState;
  return proto.users.OrganizationChecklistTaskState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users.OrganizationChecklistTaskState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users.OrganizationChecklistTaskState}
 */
proto.users.OrganizationChecklistTaskState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.users.OrganizationChecklistTask} */ (reader.readEnum());
      msg.setTask(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setComplete(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users.OrganizationChecklistTaskState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users.OrganizationChecklistTaskState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users.OrganizationChecklistTaskState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.OrganizationChecklistTaskState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTask();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getComplete();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional OrganizationChecklistTask task = 1;
 * @return {!proto.users.OrganizationChecklistTask}
 */
proto.users.OrganizationChecklistTaskState.prototype.getTask = function() {
  return /** @type {!proto.users.OrganizationChecklistTask} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.users.OrganizationChecklistTask} value
 * @return {!proto.users.OrganizationChecklistTaskState} returns this
 */
proto.users.OrganizationChecklistTaskState.prototype.setTask = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional bool complete = 2;
 * @return {boolean}
 */
proto.users.OrganizationChecklistTaskState.prototype.getComplete = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.users.OrganizationChecklistTaskState} returns this
 */
proto.users.OrganizationChecklistTaskState.prototype.setComplete = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.users.OrganizationChecklistResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users.OrganizationChecklistResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.users.OrganizationChecklistResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users.OrganizationChecklistResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.OrganizationChecklistResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    complete: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    tasksList: jspb.Message.toObjectList(msg.getTasksList(),
    proto.users.OrganizationChecklistTaskState.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users.OrganizationChecklistResponse}
 */
proto.users.OrganizationChecklistResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users.OrganizationChecklistResponse;
  return proto.users.OrganizationChecklistResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users.OrganizationChecklistResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users.OrganizationChecklistResponse}
 */
proto.users.OrganizationChecklistResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setComplete(value);
      break;
    case 2:
      var value = new proto.users.OrganizationChecklistTaskState;
      reader.readMessage(value,proto.users.OrganizationChecklistTaskState.deserializeBinaryFromReader);
      msg.addTasks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users.OrganizationChecklistResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users.OrganizationChecklistResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users.OrganizationChecklistResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.OrganizationChecklistResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComplete();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getTasksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.users.OrganizationChecklistTaskState.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool complete = 1;
 * @return {boolean}
 */
proto.users.OrganizationChecklistResponse.prototype.getComplete = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.users.OrganizationChecklistResponse} returns this
 */
proto.users.OrganizationChecklistResponse.prototype.setComplete = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * repeated OrganizationChecklistTaskState tasks = 2;
 * @return {!Array<!proto.users.OrganizationChecklistTaskState>}
 */
proto.users.OrganizationChecklistResponse.prototype.getTasksList = function() {
  return /** @type{!Array<!proto.users.OrganizationChecklistTaskState>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.users.OrganizationChecklistTaskState, 2));
};


/**
 * @param {!Array<!proto.users.OrganizationChecklistTaskState>} value
 * @return {!proto.users.OrganizationChecklistResponse} returns this
*/
proto.users.OrganizationChecklistResponse.prototype.setTasksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.users.OrganizationChecklistTaskState=} opt_value
 * @param {number=} opt_index
 * @return {!proto.users.OrganizationChecklistTaskState}
 */
proto.users.OrganizationChecklistResponse.prototype.addTasks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.users.OrganizationChecklistTaskState, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.users.OrganizationChecklistResponse} returns this
 */
proto.users.OrganizationChecklistResponse.prototype.clearTasksList = function() {
  return this.setTasksList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.users.AppIntegrationRequest.repeatedFields_ = [4];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.users.AppIntegrationRequest.oneofGroups_ = [[6,7,8,9,10,11]];

/**
 * @enum {number}
 */
proto.users.AppIntegrationRequest.SettingsCase = {
  SETTINGS_NOT_SET: 0,
  AHREF: 6,
  SNOVIO: 7,
  HUNTER: 8,
  ROCKET_REACH: 9,
  SEMRUSH: 10,
  MOZ: 11
};

/**
 * @return {proto.users.AppIntegrationRequest.SettingsCase}
 */
proto.users.AppIntegrationRequest.prototype.getSettingsCase = function() {
  return /** @type {proto.users.AppIntegrationRequest.SettingsCase} */(jspb.Message.computeOneofCase(this, proto.users.AppIntegrationRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users.AppIntegrationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.users.AppIntegrationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users.AppIntegrationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.AppIntegrationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    currentWorkspaceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    allowedForWorkspaceIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    status: jspb.Message.getFieldWithDefault(msg, 5, 0),
    ahref: (f = msg.getAhref()) && common_pb.AhrefIntegrationSettings.toObject(includeInstance, f),
    snovio: (f = msg.getSnovio()) && common_pb.SnovioIntegrationSettings.toObject(includeInstance, f),
    hunter: (f = msg.getHunter()) && common_pb.HunterIntegrationSettings.toObject(includeInstance, f),
    rocketReach: (f = msg.getRocketReach()) && common_pb.RocketReachIntegrationSettings.toObject(includeInstance, f),
    semrush: (f = msg.getSemrush()) && common_pb.SemrushIntegrationSettings.toObject(includeInstance, f),
    moz: (f = msg.getMoz()) && common_pb.MozIntegrationSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users.AppIntegrationRequest}
 */
proto.users.AppIntegrationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users.AppIntegrationRequest;
  return proto.users.AppIntegrationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users.AppIntegrationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users.AppIntegrationRequest}
 */
proto.users.AppIntegrationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCurrentWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {!proto.common.IntegrationType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setAllowedForWorkspaceIdsList(value);
      break;
    case 5:
      var value = /** @type {!proto.common.IntegrationStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 6:
      var value = new common_pb.AhrefIntegrationSettings;
      reader.readMessage(value,common_pb.AhrefIntegrationSettings.deserializeBinaryFromReader);
      msg.setAhref(value);
      break;
    case 7:
      var value = new common_pb.SnovioIntegrationSettings;
      reader.readMessage(value,common_pb.SnovioIntegrationSettings.deserializeBinaryFromReader);
      msg.setSnovio(value);
      break;
    case 8:
      var value = new common_pb.HunterIntegrationSettings;
      reader.readMessage(value,common_pb.HunterIntegrationSettings.deserializeBinaryFromReader);
      msg.setHunter(value);
      break;
    case 9:
      var value = new common_pb.RocketReachIntegrationSettings;
      reader.readMessage(value,common_pb.RocketReachIntegrationSettings.deserializeBinaryFromReader);
      msg.setRocketReach(value);
      break;
    case 10:
      var value = new common_pb.SemrushIntegrationSettings;
      reader.readMessage(value,common_pb.SemrushIntegrationSettings.deserializeBinaryFromReader);
      msg.setSemrush(value);
      break;
    case 11:
      var value = new common_pb.MozIntegrationSettings;
      reader.readMessage(value,common_pb.MozIntegrationSettings.deserializeBinaryFromReader);
      msg.setMoz(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users.AppIntegrationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users.AppIntegrationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users.AppIntegrationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.AppIntegrationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCurrentWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getAllowedForWorkspaceIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      4,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getAhref();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      common_pb.AhrefIntegrationSettings.serializeBinaryToWriter
    );
  }
  f = message.getSnovio();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      common_pb.SnovioIntegrationSettings.serializeBinaryToWriter
    );
  }
  f = message.getHunter();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      common_pb.HunterIntegrationSettings.serializeBinaryToWriter
    );
  }
  f = message.getRocketReach();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      common_pb.RocketReachIntegrationSettings.serializeBinaryToWriter
    );
  }
  f = message.getSemrush();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      common_pb.SemrushIntegrationSettings.serializeBinaryToWriter
    );
  }
  f = message.getMoz();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      common_pb.MozIntegrationSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.users.AppIntegrationRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.AppIntegrationRequest} returns this
 */
proto.users.AppIntegrationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 current_workspace_id = 2;
 * @return {number}
 */
proto.users.AppIntegrationRequest.prototype.getCurrentWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.AppIntegrationRequest} returns this
 */
proto.users.AppIntegrationRequest.prototype.setCurrentWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional common.IntegrationType type = 3;
 * @return {!proto.common.IntegrationType}
 */
proto.users.AppIntegrationRequest.prototype.getType = function() {
  return /** @type {!proto.common.IntegrationType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.common.IntegrationType} value
 * @return {!proto.users.AppIntegrationRequest} returns this
 */
proto.users.AppIntegrationRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * repeated int64 allowed_for_workspace_ids = 4;
 * @return {!Array<number>}
 */
proto.users.AppIntegrationRequest.prototype.getAllowedForWorkspaceIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.users.AppIntegrationRequest} returns this
 */
proto.users.AppIntegrationRequest.prototype.setAllowedForWorkspaceIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.users.AppIntegrationRequest} returns this
 */
proto.users.AppIntegrationRequest.prototype.addAllowedForWorkspaceIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.users.AppIntegrationRequest} returns this
 */
proto.users.AppIntegrationRequest.prototype.clearAllowedForWorkspaceIdsList = function() {
  return this.setAllowedForWorkspaceIdsList([]);
};


/**
 * optional common.IntegrationStatus status = 5;
 * @return {!proto.common.IntegrationStatus}
 */
proto.users.AppIntegrationRequest.prototype.getStatus = function() {
  return /** @type {!proto.common.IntegrationStatus} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.common.IntegrationStatus} value
 * @return {!proto.users.AppIntegrationRequest} returns this
 */
proto.users.AppIntegrationRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional common.AhrefIntegrationSettings ahref = 6;
 * @return {?proto.common.AhrefIntegrationSettings}
 */
proto.users.AppIntegrationRequest.prototype.getAhref = function() {
  return /** @type{?proto.common.AhrefIntegrationSettings} */ (
    jspb.Message.getWrapperField(this, common_pb.AhrefIntegrationSettings, 6));
};


/**
 * @param {?proto.common.AhrefIntegrationSettings|undefined} value
 * @return {!proto.users.AppIntegrationRequest} returns this
*/
proto.users.AppIntegrationRequest.prototype.setAhref = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.users.AppIntegrationRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.users.AppIntegrationRequest} returns this
 */
proto.users.AppIntegrationRequest.prototype.clearAhref = function() {
  return this.setAhref(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.users.AppIntegrationRequest.prototype.hasAhref = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional common.SnovioIntegrationSettings snovio = 7;
 * @return {?proto.common.SnovioIntegrationSettings}
 */
proto.users.AppIntegrationRequest.prototype.getSnovio = function() {
  return /** @type{?proto.common.SnovioIntegrationSettings} */ (
    jspb.Message.getWrapperField(this, common_pb.SnovioIntegrationSettings, 7));
};


/**
 * @param {?proto.common.SnovioIntegrationSettings|undefined} value
 * @return {!proto.users.AppIntegrationRequest} returns this
*/
proto.users.AppIntegrationRequest.prototype.setSnovio = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.users.AppIntegrationRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.users.AppIntegrationRequest} returns this
 */
proto.users.AppIntegrationRequest.prototype.clearSnovio = function() {
  return this.setSnovio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.users.AppIntegrationRequest.prototype.hasSnovio = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional common.HunterIntegrationSettings hunter = 8;
 * @return {?proto.common.HunterIntegrationSettings}
 */
proto.users.AppIntegrationRequest.prototype.getHunter = function() {
  return /** @type{?proto.common.HunterIntegrationSettings} */ (
    jspb.Message.getWrapperField(this, common_pb.HunterIntegrationSettings, 8));
};


/**
 * @param {?proto.common.HunterIntegrationSettings|undefined} value
 * @return {!proto.users.AppIntegrationRequest} returns this
*/
proto.users.AppIntegrationRequest.prototype.setHunter = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.users.AppIntegrationRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.users.AppIntegrationRequest} returns this
 */
proto.users.AppIntegrationRequest.prototype.clearHunter = function() {
  return this.setHunter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.users.AppIntegrationRequest.prototype.hasHunter = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional common.RocketReachIntegrationSettings rocket_reach = 9;
 * @return {?proto.common.RocketReachIntegrationSettings}
 */
proto.users.AppIntegrationRequest.prototype.getRocketReach = function() {
  return /** @type{?proto.common.RocketReachIntegrationSettings} */ (
    jspb.Message.getWrapperField(this, common_pb.RocketReachIntegrationSettings, 9));
};


/**
 * @param {?proto.common.RocketReachIntegrationSettings|undefined} value
 * @return {!proto.users.AppIntegrationRequest} returns this
*/
proto.users.AppIntegrationRequest.prototype.setRocketReach = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.users.AppIntegrationRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.users.AppIntegrationRequest} returns this
 */
proto.users.AppIntegrationRequest.prototype.clearRocketReach = function() {
  return this.setRocketReach(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.users.AppIntegrationRequest.prototype.hasRocketReach = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional common.SemrushIntegrationSettings semrush = 10;
 * @return {?proto.common.SemrushIntegrationSettings}
 */
proto.users.AppIntegrationRequest.prototype.getSemrush = function() {
  return /** @type{?proto.common.SemrushIntegrationSettings} */ (
    jspb.Message.getWrapperField(this, common_pb.SemrushIntegrationSettings, 10));
};


/**
 * @param {?proto.common.SemrushIntegrationSettings|undefined} value
 * @return {!proto.users.AppIntegrationRequest} returns this
*/
proto.users.AppIntegrationRequest.prototype.setSemrush = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.users.AppIntegrationRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.users.AppIntegrationRequest} returns this
 */
proto.users.AppIntegrationRequest.prototype.clearSemrush = function() {
  return this.setSemrush(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.users.AppIntegrationRequest.prototype.hasSemrush = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional common.MozIntegrationSettings moz = 11;
 * @return {?proto.common.MozIntegrationSettings}
 */
proto.users.AppIntegrationRequest.prototype.getMoz = function() {
  return /** @type{?proto.common.MozIntegrationSettings} */ (
    jspb.Message.getWrapperField(this, common_pb.MozIntegrationSettings, 11));
};


/**
 * @param {?proto.common.MozIntegrationSettings|undefined} value
 * @return {!proto.users.AppIntegrationRequest} returns this
*/
proto.users.AppIntegrationRequest.prototype.setMoz = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.users.AppIntegrationRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.users.AppIntegrationRequest} returns this
 */
proto.users.AppIntegrationRequest.prototype.clearMoz = function() {
  return this.setMoz(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.users.AppIntegrationRequest.prototype.hasMoz = function() {
  return jspb.Message.getField(this, 11) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.users.AppIntegrationResponse.repeatedFields_ = [9,10];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.users.AppIntegrationResponse.oneofGroups_ = [[11,12,13,14,15,16]];

/**
 * @enum {number}
 */
proto.users.AppIntegrationResponse.SettingsCase = {
  SETTINGS_NOT_SET: 0,
  AHREF: 11,
  SNOVIO: 12,
  HUNTER: 13,
  ROCKET_REACH: 14,
  SEMRUSH: 15,
  MOZ: 16
};

/**
 * @return {proto.users.AppIntegrationResponse.SettingsCase}
 */
proto.users.AppIntegrationResponse.prototype.getSettingsCase = function() {
  return /** @type {proto.users.AppIntegrationResponse.SettingsCase} */(jspb.Message.computeOneofCase(this, proto.users.AppIntegrationResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users.AppIntegrationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.users.AppIntegrationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users.AppIntegrationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.AppIntegrationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    learnLink: jspb.Message.getFieldWithDefault(msg, 4, ""),
    type: jspb.Message.getFieldWithDefault(msg, 5, 0),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0),
    connectedAt: jspb.Message.getFieldWithDefault(msg, 7, 0),
    connectedByUserName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    workspaceIdsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    detailsList: jspb.Message.toObjectList(msg.getDetailsList(),
    common_pb.Pair.toObject, includeInstance),
    ahref: (f = msg.getAhref()) && common_pb.AhrefIntegrationSettings.toObject(includeInstance, f),
    snovio: (f = msg.getSnovio()) && common_pb.SnovioIntegrationSettings.toObject(includeInstance, f),
    hunter: (f = msg.getHunter()) && common_pb.HunterIntegrationSettings.toObject(includeInstance, f),
    rocketReach: (f = msg.getRocketReach()) && common_pb.RocketReachIntegrationSettings.toObject(includeInstance, f),
    semrush: (f = msg.getSemrush()) && common_pb.SemrushIntegrationSettings.toObject(includeInstance, f),
    moz: (f = msg.getMoz()) && common_pb.MozIntegrationSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users.AppIntegrationResponse}
 */
proto.users.AppIntegrationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users.AppIntegrationResponse;
  return proto.users.AppIntegrationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users.AppIntegrationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users.AppIntegrationResponse}
 */
proto.users.AppIntegrationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLearnLink(value);
      break;
    case 5:
      var value = /** @type {!proto.common.IntegrationType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 6:
      var value = /** @type {!proto.common.IntegrationStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setConnectedAt(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setConnectedByUserName(value);
      break;
    case 9:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setWorkspaceIdsList(value);
      break;
    case 10:
      var value = new common_pb.Pair;
      reader.readMessage(value,common_pb.Pair.deserializeBinaryFromReader);
      msg.addDetails(value);
      break;
    case 11:
      var value = new common_pb.AhrefIntegrationSettings;
      reader.readMessage(value,common_pb.AhrefIntegrationSettings.deserializeBinaryFromReader);
      msg.setAhref(value);
      break;
    case 12:
      var value = new common_pb.SnovioIntegrationSettings;
      reader.readMessage(value,common_pb.SnovioIntegrationSettings.deserializeBinaryFromReader);
      msg.setSnovio(value);
      break;
    case 13:
      var value = new common_pb.HunterIntegrationSettings;
      reader.readMessage(value,common_pb.HunterIntegrationSettings.deserializeBinaryFromReader);
      msg.setHunter(value);
      break;
    case 14:
      var value = new common_pb.RocketReachIntegrationSettings;
      reader.readMessage(value,common_pb.RocketReachIntegrationSettings.deserializeBinaryFromReader);
      msg.setRocketReach(value);
      break;
    case 15:
      var value = new common_pb.SemrushIntegrationSettings;
      reader.readMessage(value,common_pb.SemrushIntegrationSettings.deserializeBinaryFromReader);
      msg.setSemrush(value);
      break;
    case 16:
      var value = new common_pb.MozIntegrationSettings;
      reader.readMessage(value,common_pb.MozIntegrationSettings.deserializeBinaryFromReader);
      msg.setMoz(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users.AppIntegrationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users.AppIntegrationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users.AppIntegrationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.AppIntegrationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLearnLink();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getConnectedAt();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getConnectedByUserName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getWorkspaceIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      9,
      f
    );
  }
  f = message.getDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      common_pb.Pair.serializeBinaryToWriter
    );
  }
  f = message.getAhref();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      common_pb.AhrefIntegrationSettings.serializeBinaryToWriter
    );
  }
  f = message.getSnovio();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      common_pb.SnovioIntegrationSettings.serializeBinaryToWriter
    );
  }
  f = message.getHunter();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      common_pb.HunterIntegrationSettings.serializeBinaryToWriter
    );
  }
  f = message.getRocketReach();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      common_pb.RocketReachIntegrationSettings.serializeBinaryToWriter
    );
  }
  f = message.getSemrush();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      common_pb.SemrushIntegrationSettings.serializeBinaryToWriter
    );
  }
  f = message.getMoz();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      common_pb.MozIntegrationSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.users.AppIntegrationResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.AppIntegrationResponse} returns this
 */
proto.users.AppIntegrationResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.users.AppIntegrationResponse.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.AppIntegrationResponse} returns this
 */
proto.users.AppIntegrationResponse.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.users.AppIntegrationResponse.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.AppIntegrationResponse} returns this
 */
proto.users.AppIntegrationResponse.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string learn_link = 4;
 * @return {string}
 */
proto.users.AppIntegrationResponse.prototype.getLearnLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.AppIntegrationResponse} returns this
 */
proto.users.AppIntegrationResponse.prototype.setLearnLink = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional common.IntegrationType type = 5;
 * @return {!proto.common.IntegrationType}
 */
proto.users.AppIntegrationResponse.prototype.getType = function() {
  return /** @type {!proto.common.IntegrationType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.common.IntegrationType} value
 * @return {!proto.users.AppIntegrationResponse} returns this
 */
proto.users.AppIntegrationResponse.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional common.IntegrationStatus status = 6;
 * @return {!proto.common.IntegrationStatus}
 */
proto.users.AppIntegrationResponse.prototype.getStatus = function() {
  return /** @type {!proto.common.IntegrationStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.common.IntegrationStatus} value
 * @return {!proto.users.AppIntegrationResponse} returns this
 */
proto.users.AppIntegrationResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional int64 connected_at = 7;
 * @return {number}
 */
proto.users.AppIntegrationResponse.prototype.getConnectedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.AppIntegrationResponse} returns this
 */
proto.users.AppIntegrationResponse.prototype.setConnectedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string connected_by_user_name = 8;
 * @return {string}
 */
proto.users.AppIntegrationResponse.prototype.getConnectedByUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.AppIntegrationResponse} returns this
 */
proto.users.AppIntegrationResponse.prototype.setConnectedByUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated int64 workspace_ids = 9;
 * @return {!Array<number>}
 */
proto.users.AppIntegrationResponse.prototype.getWorkspaceIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.users.AppIntegrationResponse} returns this
 */
proto.users.AppIntegrationResponse.prototype.setWorkspaceIdsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.users.AppIntegrationResponse} returns this
 */
proto.users.AppIntegrationResponse.prototype.addWorkspaceIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.users.AppIntegrationResponse} returns this
 */
proto.users.AppIntegrationResponse.prototype.clearWorkspaceIdsList = function() {
  return this.setWorkspaceIdsList([]);
};


/**
 * repeated common.Pair details = 10;
 * @return {!Array<!proto.common.Pair>}
 */
proto.users.AppIntegrationResponse.prototype.getDetailsList = function() {
  return /** @type{!Array<!proto.common.Pair>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.Pair, 10));
};


/**
 * @param {!Array<!proto.common.Pair>} value
 * @return {!proto.users.AppIntegrationResponse} returns this
*/
proto.users.AppIntegrationResponse.prototype.setDetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.common.Pair=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Pair}
 */
proto.users.AppIntegrationResponse.prototype.addDetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.common.Pair, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.users.AppIntegrationResponse} returns this
 */
proto.users.AppIntegrationResponse.prototype.clearDetailsList = function() {
  return this.setDetailsList([]);
};


/**
 * optional common.AhrefIntegrationSettings ahref = 11;
 * @return {?proto.common.AhrefIntegrationSettings}
 */
proto.users.AppIntegrationResponse.prototype.getAhref = function() {
  return /** @type{?proto.common.AhrefIntegrationSettings} */ (
    jspb.Message.getWrapperField(this, common_pb.AhrefIntegrationSettings, 11));
};


/**
 * @param {?proto.common.AhrefIntegrationSettings|undefined} value
 * @return {!proto.users.AppIntegrationResponse} returns this
*/
proto.users.AppIntegrationResponse.prototype.setAhref = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.users.AppIntegrationResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.users.AppIntegrationResponse} returns this
 */
proto.users.AppIntegrationResponse.prototype.clearAhref = function() {
  return this.setAhref(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.users.AppIntegrationResponse.prototype.hasAhref = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional common.SnovioIntegrationSettings snovio = 12;
 * @return {?proto.common.SnovioIntegrationSettings}
 */
proto.users.AppIntegrationResponse.prototype.getSnovio = function() {
  return /** @type{?proto.common.SnovioIntegrationSettings} */ (
    jspb.Message.getWrapperField(this, common_pb.SnovioIntegrationSettings, 12));
};


/**
 * @param {?proto.common.SnovioIntegrationSettings|undefined} value
 * @return {!proto.users.AppIntegrationResponse} returns this
*/
proto.users.AppIntegrationResponse.prototype.setSnovio = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.users.AppIntegrationResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.users.AppIntegrationResponse} returns this
 */
proto.users.AppIntegrationResponse.prototype.clearSnovio = function() {
  return this.setSnovio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.users.AppIntegrationResponse.prototype.hasSnovio = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional common.HunterIntegrationSettings hunter = 13;
 * @return {?proto.common.HunterIntegrationSettings}
 */
proto.users.AppIntegrationResponse.prototype.getHunter = function() {
  return /** @type{?proto.common.HunterIntegrationSettings} */ (
    jspb.Message.getWrapperField(this, common_pb.HunterIntegrationSettings, 13));
};


/**
 * @param {?proto.common.HunterIntegrationSettings|undefined} value
 * @return {!proto.users.AppIntegrationResponse} returns this
*/
proto.users.AppIntegrationResponse.prototype.setHunter = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.users.AppIntegrationResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.users.AppIntegrationResponse} returns this
 */
proto.users.AppIntegrationResponse.prototype.clearHunter = function() {
  return this.setHunter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.users.AppIntegrationResponse.prototype.hasHunter = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional common.RocketReachIntegrationSettings rocket_reach = 14;
 * @return {?proto.common.RocketReachIntegrationSettings}
 */
proto.users.AppIntegrationResponse.prototype.getRocketReach = function() {
  return /** @type{?proto.common.RocketReachIntegrationSettings} */ (
    jspb.Message.getWrapperField(this, common_pb.RocketReachIntegrationSettings, 14));
};


/**
 * @param {?proto.common.RocketReachIntegrationSettings|undefined} value
 * @return {!proto.users.AppIntegrationResponse} returns this
*/
proto.users.AppIntegrationResponse.prototype.setRocketReach = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.users.AppIntegrationResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.users.AppIntegrationResponse} returns this
 */
proto.users.AppIntegrationResponse.prototype.clearRocketReach = function() {
  return this.setRocketReach(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.users.AppIntegrationResponse.prototype.hasRocketReach = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional common.SemrushIntegrationSettings semrush = 15;
 * @return {?proto.common.SemrushIntegrationSettings}
 */
proto.users.AppIntegrationResponse.prototype.getSemrush = function() {
  return /** @type{?proto.common.SemrushIntegrationSettings} */ (
    jspb.Message.getWrapperField(this, common_pb.SemrushIntegrationSettings, 15));
};


/**
 * @param {?proto.common.SemrushIntegrationSettings|undefined} value
 * @return {!proto.users.AppIntegrationResponse} returns this
*/
proto.users.AppIntegrationResponse.prototype.setSemrush = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.users.AppIntegrationResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.users.AppIntegrationResponse} returns this
 */
proto.users.AppIntegrationResponse.prototype.clearSemrush = function() {
  return this.setSemrush(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.users.AppIntegrationResponse.prototype.hasSemrush = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional common.MozIntegrationSettings moz = 16;
 * @return {?proto.common.MozIntegrationSettings}
 */
proto.users.AppIntegrationResponse.prototype.getMoz = function() {
  return /** @type{?proto.common.MozIntegrationSettings} */ (
    jspb.Message.getWrapperField(this, common_pb.MozIntegrationSettings, 16));
};


/**
 * @param {?proto.common.MozIntegrationSettings|undefined} value
 * @return {!proto.users.AppIntegrationResponse} returns this
*/
proto.users.AppIntegrationResponse.prototype.setMoz = function(value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.users.AppIntegrationResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.users.AppIntegrationResponse} returns this
 */
proto.users.AppIntegrationResponse.prototype.clearMoz = function() {
  return this.setMoz(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.users.AppIntegrationResponse.prototype.hasMoz = function() {
  return jspb.Message.getField(this, 16) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users.UnsubscribeContent.prototype.toObject = function(opt_includeInstance) {
  return proto.users.UnsubscribeContent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users.UnsubscribeContent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.UnsubscribeContent.toObject = function(includeInstance, msg) {
  var f, obj = {
    content: jspb.Message.getFieldWithDefault(msg, 1, ""),
    enabled: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users.UnsubscribeContent}
 */
proto.users.UnsubscribeContent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users.UnsubscribeContent;
  return proto.users.UnsubscribeContent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users.UnsubscribeContent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users.UnsubscribeContent}
 */
proto.users.UnsubscribeContent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users.UnsubscribeContent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users.UnsubscribeContent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users.UnsubscribeContent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.UnsubscribeContent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string content = 1;
 * @return {string}
 */
proto.users.UnsubscribeContent.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.UnsubscribeContent} returns this
 */
proto.users.UnsubscribeContent.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool enabled = 2;
 * @return {boolean}
 */
proto.users.UnsubscribeContent.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.users.UnsubscribeContent} returns this
 */
proto.users.UnsubscribeContent.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users.TrackingDomainDnsSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.users.TrackingDomainDnsSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users.TrackingDomainDnsSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.TrackingDomainDnsSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    host: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getFieldWithDefault(msg, 3, ""),
    pb_default: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users.TrackingDomainDnsSettings}
 */
proto.users.TrackingDomainDnsSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users.TrackingDomainDnsSettings;
  return proto.users.TrackingDomainDnsSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users.TrackingDomainDnsSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users.TrackingDomainDnsSettings}
 */
proto.users.TrackingDomainDnsSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHost(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDefault(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users.TrackingDomainDnsSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users.TrackingDomainDnsSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users.TrackingDomainDnsSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.TrackingDomainDnsSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHost();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDefault();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.users.TrackingDomainDnsSettings.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.TrackingDomainDnsSettings} returns this
 */
proto.users.TrackingDomainDnsSettings.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string host = 2;
 * @return {string}
 */
proto.users.TrackingDomainDnsSettings.prototype.getHost = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.TrackingDomainDnsSettings} returns this
 */
proto.users.TrackingDomainDnsSettings.prototype.setHost = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.users.TrackingDomainDnsSettings.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.TrackingDomainDnsSettings} returns this
 */
proto.users.TrackingDomainDnsSettings.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool default = 4;
 * @return {boolean}
 */
proto.users.TrackingDomainDnsSettings.prototype.getDefault = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.users.TrackingDomainDnsSettings} returns this
 */
proto.users.TrackingDomainDnsSettings.prototype.setDefault = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users.TrackingDomain.prototype.toObject = function(opt_includeInstance) {
  return proto.users.TrackingDomain.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users.TrackingDomain} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.TrackingDomain.toObject = function(includeInstance, msg) {
  var f, obj = {
    domain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pb_default: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users.TrackingDomain}
 */
proto.users.TrackingDomain.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users.TrackingDomain;
  return proto.users.TrackingDomain.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users.TrackingDomain} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users.TrackingDomain}
 */
proto.users.TrackingDomain.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomain(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDefault(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users.TrackingDomain.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users.TrackingDomain.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users.TrackingDomain} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.TrackingDomain.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDefault();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string domain = 1;
 * @return {string}
 */
proto.users.TrackingDomain.prototype.getDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.TrackingDomain} returns this
 */
proto.users.TrackingDomain.prototype.setDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool default = 2;
 * @return {boolean}
 */
proto.users.TrackingDomain.prototype.getDefault = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.users.TrackingDomain} returns this
 */
proto.users.TrackingDomain.prototype.setDefault = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users.ReferralLinkData.prototype.toObject = function(opt_includeInstance) {
  return proto.users.ReferralLinkData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users.ReferralLinkData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.ReferralLinkData.toObject = function(includeInstance, msg) {
  var f, obj = {
    link: jspb.Message.getFieldWithDefault(msg, 1, ""),
    clickedNumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    startedTrialNumber: jspb.Message.getFieldWithDefault(msg, 3, 0),
    paidCustomerNumber: jspb.Message.getFieldWithDefault(msg, 4, 0),
    totalNumbersNumber: jspb.Message.getFieldWithDefault(msg, 5, 0),
    content: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users.ReferralLinkData}
 */
proto.users.ReferralLinkData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users.ReferralLinkData;
  return proto.users.ReferralLinkData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users.ReferralLinkData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users.ReferralLinkData}
 */
proto.users.ReferralLinkData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setClickedNumber(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStartedTrialNumber(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaidCustomerNumber(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalNumbersNumber(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users.ReferralLinkData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users.ReferralLinkData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users.ReferralLinkData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.ReferralLinkData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLink();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getClickedNumber();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getStartedTrialNumber();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getPaidCustomerNumber();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getTotalNumbersNumber();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string link = 1;
 * @return {string}
 */
proto.users.ReferralLinkData.prototype.getLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.ReferralLinkData} returns this
 */
proto.users.ReferralLinkData.prototype.setLink = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 clicked_number = 2;
 * @return {number}
 */
proto.users.ReferralLinkData.prototype.getClickedNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.ReferralLinkData} returns this
 */
proto.users.ReferralLinkData.prototype.setClickedNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 started_trial_number = 3;
 * @return {number}
 */
proto.users.ReferralLinkData.prototype.getStartedTrialNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.ReferralLinkData} returns this
 */
proto.users.ReferralLinkData.prototype.setStartedTrialNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 paid_customer_number = 4;
 * @return {number}
 */
proto.users.ReferralLinkData.prototype.getPaidCustomerNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.ReferralLinkData} returns this
 */
proto.users.ReferralLinkData.prototype.setPaidCustomerNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 total_numbers_number = 5;
 * @return {number}
 */
proto.users.ReferralLinkData.prototype.getTotalNumbersNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.ReferralLinkData} returns this
 */
proto.users.ReferralLinkData.prototype.setTotalNumbersNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string content = 6;
 * @return {string}
 */
proto.users.ReferralLinkData.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.ReferralLinkData} returns this
 */
proto.users.ReferralLinkData.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * @enum {number}
 */
proto.users.ScheduleType = {
  DEFAULT: 0,
  CUSTOM: 1
};

/**
 * @enum {number}
 */
proto.users.OrganizationMemberRole = {
  OWNER: 0,
  ADMIN: 1,
  MEMBER: 2
};

/**
 * @enum {number}
 */
proto.users.OrganizationInviteStatus = {
  PENDING: 0,
  EXPIRED: 1
};

/**
 * @enum {number}
 */
proto.users.OrganizationChecklistTask = {
  SCHEDULE_ONBOARDING_SESSION: 0,
  CONNECT_EMAIL_ACCOUNT: 1,
  LAUNCH_CAMPAIGN: 2,
  INVITE_TEAM_MEMBER: 3
};

goog.object.extend(exports, proto.users);
