import React, { useState } from 'react';
import cn from 'classnames';

import {
  BacklinksSearchSource as BACKLINKS_SEARCH_SOURCE,
  BacklinksSearchSourceMap,
} from 'respona_api/generated/common_pb';

import MozLogo from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/assets/MozLogo';
import AhrefsLogo from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/assets/AhrefsLogo';
import SemrushLogo from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/assets/SemrushLogo';

import './MetricsSourceToggle.scss';

const metricsSources: {
  Icon: () => JSX.Element;
  source: BacklinksSearchSourceMap[keyof BacklinksSearchSourceMap];
}[] = [
  {
    Icon: () => <MozLogo height={18} />,
    source: BACKLINKS_SEARCH_SOURCE.MOZ_BACKLINKS_SEARCH,
  },
  {
    Icon: () => <SemrushLogo height={18} />,
    source: BACKLINKS_SEARCH_SOURCE.SEMRUSH_BACKLINKS_SEARCH,
  },
  {
    Icon: () => <AhrefsLogo height={19} />,
    source: BACKLINKS_SEARCH_SOURCE.AHREFS_BACKLINKS_SEARCH,
  },
];

const MetricsSourceToggle = ({
  source,
  setSource,
}: {
  source: BacklinksSearchSourceMap[keyof BacklinksSearchSourceMap];
  setSource: (source: BacklinksSearchSourceMap[keyof BacklinksSearchSourceMap]) => void;
}) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(
    metricsSources.findIndex((item) => item.source === source)
  );
  return (
    <div className="metrics-source-toggle">
      {metricsSources.map((item, index) => {
        return (
          <div
            key={item.source}
            onClick={() => {
              setSelectedIndex(index);
              setSource(item.source);
            }}
            className={cn('metrics-source-toggle__item', {
              'metrics-source-toggle__item--unselected': item.source !== source,
              'metrics-source-toggle__item--unselected-border-left': selectedIndex - index === -1,
              'metrics-source-toggle__item--unselected-border-right': selectedIndex - index === 1,
            })}
          >
            <item.Icon />
          </div>
        );
      })}

      {!!source && selectedIndex !== null && (
        <div
          style={{ transform: `translateX(${selectedIndex * 251}px)` }}
          className="metrics-source-toggle__slider"
        />
      )}
    </div>
  );
};

export default MetricsSourceToggle;
