import {
  BacklinksRel,
  BacklinkStatus,
  ContentSearchOrderBy,
} from 'respona_api/generated/common_pb';

export const keywordsAutomationDefaultSettings = {
  source: null,
  queriesList: [],
  analyzeResultsNumber: 100,
  location: 'us',
  language: 'lang_en',
  oneResultPerDomain: true,
  ignoreAlreadyContacted: true,
  optionalIgnoreAlreadyContactedDays: 365,
  ignoreUnsubscribed: true,
  sortBy: ContentSearchOrderBy.RELEVANCE,
  ignoreActive: true,
  ignoreHomepages: false,
  optionalUnlinkedDomain: '',
};

export const backlinksAutomationDefaultSettings = {
  source: null,
  urlsList: [],
  analyzeResultsNumber: 10,
  rel: BacklinksRel.FOLLOW_AND_NO_FOLLOW,
  optionalAnchor: '',
  optionalSourceUrl: '',
  optionalTargetUrl: '',
  oneResultPerDomain: true,
  ignoreAlreadyContacted: true,
  optionalIgnoreAlreadyContactedDays: 365,
  ignoreUnsubscribed: true,
  ignoreActive: true,
  sortBy: ContentSearchOrderBy.RELEVANCE,
  ignoreHomepages: true,
  linkStatus: BacklinkStatus.ALL_BACKLINKS,
  optionalUnlinkedDomain: '',
};

export const podcastsAutomationDefaultSettings = {
  source: null,
  keywordsList: [],
  analyzeResultsNumber: 100,
  location: 'us',
  language: 'lang_en',
  oneResultPerDomain: true,
  ignoreAlreadyContacted: true,
  optionalIgnoreAlreadyContactedDays: 100,
  ignoreUnsubscribed: true,
  ignoreActive: true,
  createContacts: true,
};
