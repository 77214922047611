import { Dispatch } from 'redux';

import { setAudioGenres, setAudioGenresLs } from '@redux/actions/commonInfo.actions';
import { getAudioGenresApi } from '@api/contentSerach.api';
import loadingStatuses from '@constants/loadingStatuses';

export const fetchAudioGenres = () => (dispatch: Dispatch) => {
  dispatch(setAudioGenresLs(loadingStatuses.INITED));

  getAudioGenresApi()
    .then((genres) => dispatch(setAudioGenres(genres)))
    .finally(() => dispatch(setAudioGenresLs(loadingStatuses.LOADED)))
};
