import {
  SET_PERSONALIZATION_AUTOMATION_DATA,
  UPDATE_CURRENT_PERSONALIZATION_AUTOMATION_DATA,
} from '@redux/actions/automationPeronalization.actions';
import { PersonalizationAutomationSettings } from '@ts/automationPersonalizations.types';
import { OpenAIModelType, OpenAITone } from 'respona_api/generated/common_pb';

export type PersonalizationAutomationStateType = {
  data: PersonalizationAutomationSettings;
};

export const initialState: PersonalizationAutomationStateType = {
  data: {
    tone: OpenAITone.CREATIVE,
    model: OpenAIModelType.GPT_EXPENSIVE,
    instructions: [],
    includeSummaries: true,
  },
};

export default (state: PersonalizationAutomationStateType = initialState, { type, payload }) => {
  switch (type) {
    case SET_PERSONALIZATION_AUTOMATION_DATA:
      return {
        ...state,
        data: payload,
      };
    case UPDATE_CURRENT_PERSONALIZATION_AUTOMATION_DATA:
      return {
        ...state,
        data: { ...state.data, ...payload },
      };
    default:
      return state;
  }
};
