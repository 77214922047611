import React from 'react';

import { WebsiteType } from '@ts/company.types';

import useWorkspaceMembers from '@hooks/useWorkspaceMembers';

import ConfigurableTable from '@uikit/ConfigurableTable/ConfigurableTable';
import { Tr } from '@uikit/Table/Table';

import QueryErrorMessage from '@components/Relationships/QueryErrorMessage/QueryErrorMessage';

import tableConfig from './tableConfig';

import './OrganizationsTable.scss';
import { ColumnSettingsType } from '@ts/common.types';

type OrganizationTablePropsType = {
  websites: WebsiteType[];
  isError: boolean;
  onCreateCompanyClick: () => void;
  onToggleRowSelection: (number) => void;
  onToggleSelectAll: (boolean) => void;
  onEditCompany: (companyInfo: WebsiteType) => void;
  onRemoveCompany: (companiesIds: number[]) => void;
  onMoveCompany: (companyInfo: WebsiteType) => void;
  selectedRows: {
    [key: number]: boolean;
  };
};

function RenderRow({ children, row, renderProps, rowClassName }): JSX.Element {
  const handleRowClick = (e) => {
    e.stopPropagation();
    renderProps.onEditCompany(row);
  };

  const isRowSelected = renderProps.selectedRows[row.id] !== undefined;

  return (
    <Tr onClick={handleRowClick} className={rowClassName} isSelected={isRowSelected}>
      {children}
    </Tr>
  );
}

function OrganizationsTable({
  websites,
  isError,
  onCreateCompanyClick,
  selectedRows,
  onToggleRowSelection,
  onToggleSelectAll,
  onEditCompany,
  onMoveCompany,
  onRemoveCompany,
}: OrganizationTablePropsType): JSX.Element {
  const { members: workspaceMembers } = useWorkspaceMembers();

  if (isError) {
    return (
      <div className="organizations-table-empty">
        <div className="organizations-table-empty__message">
          <QueryErrorMessage />
        </div>
      </div>
    );
  }

  if (websites.length === 0) {
    return (
      <div className="organizations-table-empty">
        <div className="organizations-table-empty__message">There are no websites yet. Want to</div>
        <span
          onClick={onCreateCompanyClick}
          className="organizations-table-empty__button-link"
          role="link"
          tabIndex={0}
        >
          Add new website?
        </span>
      </div>
    );
  }

  const websitesTableSettings: ColumnSettingsType[] = [
    { enabled: true, columnTitle: 'Website', columnMappingName: 'website' },
    { enabled: true, columnTitle: 'Relationship Status', columnMappingName: 'status' },
    { enabled: true, columnTitle: 'Contacts', columnMappingName: 'contact' },
    { enabled: true, columnTitle: 'Metrics', columnMappingName: 'metrics' },
    { enabled: true, columnTitle: 'Date added', columnMappingName: 'created_on' },
  ];

  return (
    <div className="organizations-table-wrapper">
      <div className="organizations-table-wrapper__wrapper-for-horizontal-scrolling">
        <ConfigurableTable
          config={tableConfig(websitesTableSettings)}
          data={websites}
          rowClassName="organizations-table__row"
          tableClassName="organizations-table"
          RenderRow={RenderRow}
          renderProps={{
            selectedRows,
            onToggleRowSelection,
            onEditCompany,
            onMoveCompany,
            onRemoveCompany,
            onToggleSelectAll,
            isAllSelected: websites?.every(({ id }) => selectedRows[id] !== undefined),
            workspaceMembers,
          }}
        />
      </div>
    </div>
  );
}

export default OrganizationsTable;
