export const redirectToUrl = function (url: string): void {
  if (url.indexOf('http') !== 0) {
    window.open(`https://${url}`, '_blank');
    return;
  }

  try {
    window.open(url, '_blank');
  } catch (e) {
    console.error(e);
  }
};

export function parseUrlSearch<T = { [key: string]: string | string[] }>(search: string): T {
  if (!search || !search.slice(1)) {
    return {} as T;
  }
  return search
    .slice(1)
    .split('&')
    .reduce((reducer, item) => {
      const keyValue = item.split('=');
      return {
        ...reducer,
        [keyValue[0]]: keyValue[1].includes(',') ? keyValue[1].split(',') : keyValue[1],
      };
    }, {}) as T;
}
