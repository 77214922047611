import React from 'react';

export default ({ color = '#C4C6CD' }) => (
  <svg width="15" height="4" viewBox="0 0 15 4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.2065 2.18182C13.2966 2.18182 13.3696 2.10041 13.3696 2C13.3696 1.89959 13.2966 1.81818 13.2065 1.81818C13.1165 1.81818 13.0435 1.89959 13.0435 2C13.0435 2.10041 13.1165 2.18182 13.2065 2.18182ZM11.413 2C11.413 0.895431 12.216 0 13.2065 0C14.197 0 15 0.895431 15 2C15 3.10457 14.197 4 13.2065 4C12.216 4 11.413 3.10457 11.413 2Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 2.18182C7.59005 2.18182 7.66304 2.10042 7.66304 2C7.66304 1.89958 7.59005 1.81818 7.5 1.81818C7.40995 1.81818 7.33696 1.89958 7.33696 2C7.33696 2.10042 7.40995 2.18182 7.5 2.18182ZM5.70652 2C5.70652 0.895431 6.50949 0 7.5 0C8.49051 0 9.29348 0.895431 9.29348 2C9.29348 3.10457 8.49051 4 7.5 4C6.50949 4 5.70652 3.10457 5.70652 2Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.79348 2.18182C1.88353 2.18182 1.95652 2.10041 1.95652 2C1.95652 1.89959 1.88353 1.81818 1.79348 1.81818C1.70343 1.81818 1.63043 1.89959 1.63043 2C1.63043 2.10041 1.70343 2.18182 1.79348 2.18182ZM0 2C0 0.895431 0.802967 0 1.79348 0C2.78399 0 3.58696 0.895431 3.58696 2C3.58696 3.10457 2.78399 4 1.79348 4C0.802967 4 0 3.10457 0 2Z"
      fill={color}
    />
  </svg>
);
