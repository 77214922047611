// package: mailbox
// file: mailbox-account-health.proto

var mailbox_account_health_pb = require("./mailbox-account-health_pb");
var common_pb = require("./common_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var MailboxAccountHealthService = (function () {
  function MailboxAccountHealthService() {}
  MailboxAccountHealthService.serviceName = "mailbox.MailboxAccountHealthService";
  return MailboxAccountHealthService;
}());

MailboxAccountHealthService.getByAccountId = {
  methodName: "getByAccountId",
  service: MailboxAccountHealthService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: mailbox_account_health_pb.MailboxHealthResponse
};

exports.MailboxAccountHealthService = MailboxAccountHealthService;

function MailboxAccountHealthServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

MailboxAccountHealthServiceClient.prototype.getByAccountId = function getByAccountId(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MailboxAccountHealthService.getByAccountId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.MailboxAccountHealthServiceClient = MailboxAccountHealthServiceClient;

