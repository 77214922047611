import { createAction } from 'redux-actions';

export const setPersonalization = createAction<{ [key: string]: any }>('CS_SET_PERSONALIZATIONS');
export const updatePersonalizationSteps = createAction<{ [key: string]: any }>(
  'CS_UPDATE_PERSONALIZATION_STEPS'
);
export const setPersonalizationLs = createAction<{
  loadingStatus: string;
  opportunityId: number;
}>('CS_SET_PERSONALIZATIONS_LS');
export const fetchPersonalization = createAction<number>('CS_FETCH_PERSONALIZATIONS');
export const clearPersonalization = createAction('CS_CLEAR_PERSONALIZATION');