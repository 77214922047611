import React from 'react';

const OpportunityIllustration = () => {
  return (
      <svg width="156" height="183" viewBox="0 0 156 183" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M154.569 2H1.43119C0.640767 2 0 2.644 0 3.43841V181.562C0 182.356 0.640767 183 1.43119 183H154.569C155.359 183 156 182.356 156 181.562V3.43841C156 2.644 155.359 2 154.569 2Z"
            fill="#F2F6FB"/>
        <path d="M0 4.09181C0 1.83196 2.13485 0 4.77 0H151.23C153.864 0 156 1.83213 156 4.09181V9H0V4.09181Z"
              fill="#D8DEED"/>
        <path
            d="M5.77641 6.78701C6.88748 6.78701 7.78818 5.88318 7.78818 4.76826C7.78818 3.65334 6.88748 2.74951 5.77641 2.74951C4.66535 2.74951 3.76465 3.65334 3.76465 4.76826C3.76465 5.88318 4.66535 6.78701 5.77641 6.78701Z"
            fill="#FF5645"/>
        <path
            d="M12.3413 6.78701C13.4524 6.78701 14.3531 5.88318 14.3531 4.76826C14.3531 3.65334 13.4524 2.74951 12.3413 2.74951C11.2303 2.74951 10.3296 3.65334 10.3296 4.76826C10.3296 5.88318 11.2303 6.78701 12.3413 6.78701Z"
            fill="#FFCB3D"/>
        <path
            d="M19.1172 6.78701C20.2283 6.78701 21.129 5.88318 21.129 4.76826C21.129 3.65334 20.2283 2.74951 19.1172 2.74951C18.0062 2.74951 17.1055 3.65334 17.1055 4.76826C17.1055 5.88318 18.0062 6.78701 19.1172 6.78701Z"
            fill="#3ABF7C"/>
        <path
            d="M8 98.0687C8 95.7364 9.89068 93.8457 12.223 93.8457H144.286C146.619 93.8457 148.509 95.7364 148.509 98.0687V127.629C148.509 129.962 146.619 131.852 144.286 131.852H12.223C9.89068 131.852 8 129.962 8 127.629V98.0687Z"
            fill="white"/>
        <path
            d="M8 56.223C8 53.8907 9.89068 52 12.223 52H144.286C146.619 52 148.509 53.8907 148.509 56.223V85.7837C148.509 88.1159 146.619 90.0066 144.286 90.0066H12.223C9.89068 90.0066 8 88.1159 8 85.7837V56.223Z"
            fill="white"/>
        <path
            d="M8 139.915C8 137.583 9.89068 135.692 12.223 135.692H144.286C146.619 135.692 148.509 137.583 148.509 139.915V169.86C148.509 172.192 146.619 174.083 144.286 174.083H12.223C9.89068 174.083 8 172.192 8 169.86V139.915Z"
            fill="white"/>
        <path
            d="M28.731 105.363C28.731 104.727 29.2466 104.211 29.8827 104.211H62.1307C62.7668 104.211 63.2824 104.727 63.2824 105.363C63.2824 105.999 62.7668 106.514 62.1307 106.514H29.8827C29.2466 106.514 28.731 105.999 28.731 105.363Z"
            fill="#BEC8D7"/>
        <path
            d="M28.731 63.5169C28.731 62.8809 29.2466 62.3652 29.8827 62.3652H62.1307C62.7668 62.3652 63.2824 62.8809 63.2824 63.5169C63.2824 64.153 62.7668 64.6687 62.1307 64.6687H29.8827C29.2466 64.6687 28.731 64.153 28.731 63.5169Z"
            fill="#BEC8D7"/>
        <path
            d="M28.731 147.208C28.731 146.572 29.2466 146.057 29.8827 146.057H62.1307C62.7668 146.057 63.2824 146.572 63.2824 147.208C63.2824 147.844 62.7668 148.36 62.1307 148.36H29.8827C29.2466 148.36 28.731 147.844 28.731 147.208Z"
            fill="#BEC8D7"/>
        <path
            d="M17.5977 105.362C17.5977 104.302 18.4571 103.443 19.5172 103.443H22.5884C23.6485 103.443 24.5079 104.302 24.5079 105.362V108.434C24.5079 109.494 23.6485 110.353 22.5884 110.353H19.5172C18.4571 110.353 17.5977 109.494 17.5977 108.434V105.362Z"
            fill="#E1E6EE"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M22.5884 104.978H19.5172C19.3052 104.978 19.1333 105.15 19.1333 105.362V108.434C19.1333 108.646 19.3052 108.818 19.5172 108.818H22.5884C22.8004 108.818 22.9723 108.646 22.9723 108.434V105.362C22.9723 105.15 22.8004 104.978 22.5884 104.978ZM19.5172 103.443C18.4571 103.443 17.5977 104.302 17.5977 105.362V108.434C17.5977 109.494 18.4571 110.353 19.5172 110.353H22.5884C23.6485 110.353 24.5079 109.494 24.5079 108.434V105.362C24.5079 104.302 23.6485 103.443 22.5884 103.443H19.5172Z"
              fill="#E1E6EE"/>
        <path
            d="M17.5977 63.5177C17.5977 62.4575 18.4571 61.5981 19.5172 61.5981H22.5884C23.6485 61.5981 24.5079 62.4575 24.5079 63.5177V66.5889C24.5079 67.649 23.6485 68.5084 22.5884 68.5084H19.5172C18.4571 68.5084 17.5977 67.649 17.5977 66.5889V63.5177Z"
            fill="#E1E6EE"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M22.5884 63.1338H19.5172C19.3052 63.1338 19.1333 63.3056 19.1333 63.5177V66.5889C19.1333 66.8009 19.3052 66.9728 19.5172 66.9728H22.5884C22.8004 66.9728 22.9723 66.8009 22.9723 66.5889V63.5177C22.9723 63.3056 22.8004 63.1338 22.5884 63.1338ZM19.5172 61.5981C18.4571 61.5981 17.5977 62.4575 17.5977 63.5177V66.5889C17.5977 67.649 18.4571 68.5084 19.5172 68.5084H22.5884C23.6485 68.5084 24.5079 67.649 24.5079 66.5889V63.5177C24.5079 62.4575 23.6485 61.5981 22.5884 61.5981H19.5172Z"
              fill="#E1E6EE"/>
        <path
            d="M17.5977 147.208C17.5977 146.147 18.4571 145.288 19.5172 145.288H22.5884C23.6485 145.288 24.5079 146.147 24.5079 147.208V150.279C24.5079 151.339 23.6485 152.198 22.5884 152.198H19.5172C18.4571 152.198 17.5977 151.339 17.5977 150.279V147.208Z"
            fill="#E1E6EE"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M22.5884 146.824H19.5172C19.3052 146.824 19.1333 146.996 19.1333 147.208V150.279C19.1333 150.491 19.3052 150.663 19.5172 150.663H22.5884C22.8004 150.663 22.9723 150.491 22.9723 150.279V147.208C22.9723 146.996 22.8004 146.824 22.5884 146.824ZM19.5172 145.288C18.4571 145.288 17.5977 146.147 17.5977 147.208V150.279C17.5977 151.339 18.4571 152.198 19.5172 152.198H22.5884C23.6485 152.198 24.5079 151.339 24.5079 150.279V147.208C24.5079 146.147 23.6485 145.288 22.5884 145.288H19.5172Z"
              fill="#E1E6EE"/>
        <path
            d="M28.731 110.162C28.731 109.419 29.3325 108.818 30.0746 108.818H76.5272C77.2692 108.818 77.8708 109.419 77.8708 110.162C77.8708 110.904 77.2692 111.505 76.5272 111.505H30.0746C29.3325 111.505 28.731 110.904 28.731 110.162Z"
            fill="#E1E6EE"/>
        <path
            d="M28.731 68.3163C28.731 67.5742 29.3325 66.9727 30.0746 66.9727H76.5272C77.2692 66.9727 77.8708 67.5742 77.8708 68.3163C77.8708 69.0584 77.2692 69.66 76.5272 69.66H30.0746C29.3325 69.66 28.731 69.0584 28.731 68.3163Z"
            fill="#E1E6EE"/>
        <path
            d="M28.731 152.2C28.731 151.563 29.2466 151.048 29.8827 151.048H76.7191C77.3552 151.048 77.8708 151.563 77.8708 152.2C77.8708 152.836 77.3552 153.351 76.7191 153.351H29.8827C29.2466 153.351 28.731 152.836 28.731 152.2Z"
            fill="#E1E6EE"/>
        <path
            d="M28.731 114.193C28.731 113.557 29.2466 113.041 29.8827 113.041H83.2455C83.8816 113.041 84.3972 113.557 84.3972 114.193C84.3972 114.829 83.8816 115.344 83.2455 115.344H29.8827C29.2466 115.344 28.731 114.829 28.731 114.193Z"
            fill="#E1E6EE"/>
        <path
            d="M28.731 72.347C28.731 71.711 29.2466 71.1953 29.8827 71.1953H83.2455C83.8816 71.1953 84.3972 71.711 84.3972 72.347C84.3972 72.9831 83.8816 73.4987 83.2455 73.4987H29.8827C29.2466 73.4987 28.731 72.9831 28.731 72.347Z"
            fill="#E1E6EE"/>
        <path
            d="M28.731 157.189C28.731 156.553 29.2466 156.038 29.8827 156.038H83.2455C83.8816 156.038 84.3972 156.553 84.3972 157.189C84.3972 157.825 83.8816 158.341 83.2455 158.341H29.8827C29.2466 158.341 28.731 157.825 28.731 157.189Z"
            fill="#E1E6EE"/>
        <path
            d="M28.731 121.679C28.731 121.148 29.1607 120.719 29.6907 120.719H41.9757C42.5057 120.719 42.9354 121.148 42.9354 121.679C42.9354 122.209 42.5057 122.638 41.9757 122.638H29.6907C29.1607 122.638 28.731 122.209 28.731 121.679Z"
            fill="#E1E6EE"/>
        <path
            d="M28.731 79.8328C28.731 79.3027 29.1607 78.873 29.6907 78.873H41.9757C42.5057 78.873 42.9354 79.3027 42.9354 79.8328C42.9354 80.3629 42.5057 80.7926 41.9757 80.7926H29.6907C29.1607 80.7926 28.731 80.3629 28.731 79.8328Z"
            fill="#E1E6EE"/>
        <path
            d="M28.731 164.677C28.731 164.146 29.1607 163.717 29.6907 163.717H41.9757C42.5057 163.717 42.9354 164.146 42.9354 164.677C42.9354 165.207 42.5057 165.636 41.9757 165.636H29.6907C29.1607 165.636 28.731 165.207 28.731 164.677Z"
            fill="#E1E6EE"/>
        <path
            d="M44.4707 121.679C44.4707 121.148 44.9004 120.719 45.4305 120.719H70.3843C70.9144 120.719 71.3441 121.148 71.3441 121.679C71.3441 122.209 70.9144 122.638 70.3843 122.638H45.4305C44.9004 122.638 44.4707 122.209 44.4707 121.679Z"
            fill="#E1E6EE"/>
        <path
            d="M44.4707 79.8328C44.4707 79.3027 44.9004 78.873 45.4305 78.873H70.3843C70.9144 78.873 71.3441 79.3027 71.3441 79.8328C71.3441 80.3629 70.9144 80.7926 70.3843 80.7926H45.4305C44.9004 80.7926 44.4707 80.3629 44.4707 79.8328Z"
            fill="#E1E6EE"/>
        <path
            d="M44.4707 164.677C44.4707 164.146 44.9004 163.717 45.4305 163.717H70.3843C70.9144 163.717 71.3441 164.146 71.3441 164.677C71.3441 165.207 70.9144 165.636 70.3843 165.636H45.4305C44.9004 165.636 44.4707 165.207 44.4707 164.677Z"
            fill="#E1E6EE"/>
        <path
            d="M114.13 112.351C114.593 112.438 114.904 112.887 114.749 113.333C114.356 114.459 113.616 115.44 112.625 116.13C111.398 116.982 109.886 117.319 108.414 117.066C106.942 116.814 105.628 115.993 104.756 114.781C103.884 113.568 103.523 112.061 103.752 110.585C103.981 109.109 104.78 107.782 105.979 106.891C107.177 106 108.678 105.615 110.157 105.82C111.637 106.025 112.976 106.804 113.887 107.988C114.623 108.945 115.031 110.104 115.064 111.297C115.078 111.768 114.645 112.102 114.177 112.045C113.709 111.988 113.386 111.559 113.342 111.089C113.273 110.346 112.996 109.63 112.534 109.029C111.896 108.2 110.959 107.655 109.923 107.511C108.887 107.368 107.837 107.637 106.998 108.261C106.159 108.885 105.599 109.814 105.439 110.847C105.279 111.88 105.531 112.935 106.142 113.784C106.752 114.632 107.672 115.207 108.702 115.384C109.733 115.56 110.792 115.325 111.65 114.728C112.273 114.295 112.754 113.697 113.045 113.009C113.228 112.575 113.666 112.264 114.13 112.351Z"
            fill="#E1E6EE"/>
        <path
            d="M131.955 112.351C132.418 112.438 132.729 112.887 132.574 113.333C132.181 114.459 131.441 115.44 130.449 116.13C129.223 116.982 127.711 117.319 126.239 117.066C124.767 116.814 123.453 115.993 122.58 114.781C121.708 113.568 121.348 112.061 121.576 110.585C121.805 109.109 122.605 107.782 123.804 106.891C125.002 106 126.503 105.615 127.982 105.82C129.462 106.025 130.801 106.804 131.712 107.988C132.448 108.945 132.856 110.104 132.889 111.297C132.902 111.768 132.47 112.102 132.002 112.045C131.534 111.988 131.211 111.559 131.167 111.089C131.098 110.346 130.821 109.63 130.358 109.029C129.721 108.2 128.783 107.655 127.748 107.511C126.712 107.368 125.661 107.637 124.823 108.261C123.984 108.885 123.424 109.814 123.264 110.847C123.103 111.88 123.356 112.935 123.966 113.784C124.577 114.632 125.497 115.207 126.527 115.384C127.558 115.56 128.616 115.325 129.475 114.728C130.097 114.295 130.579 113.697 130.87 113.009C131.053 112.575 131.491 112.264 131.955 112.351Z"
            fill="#E1E6EE"/>
        <path
            d="M113.444 114.073C113.841 114.328 114.376 114.215 114.569 113.785C114.868 113.118 115.037 112.396 115.063 111.66C115.1 110.638 114.86 109.625 114.37 108.728C113.88 107.831 113.157 107.082 112.277 106.561C111.643 106.185 110.945 105.938 110.221 105.829C109.755 105.759 109.371 106.148 109.372 106.619C109.372 107.091 109.759 107.464 110.22 107.564C110.636 107.654 111.037 107.811 111.407 108.03C112.022 108.395 112.528 108.919 112.872 109.547C113.215 110.175 113.382 110.884 113.357 111.599C113.342 112.028 113.257 112.451 113.108 112.85C112.943 113.291 113.048 113.818 113.444 114.073Z"
            fill="#8194B0"/>
        <path
            d="M131.269 114.073C131.666 114.328 132.201 114.215 132.393 113.785C132.693 113.118 132.862 112.396 132.888 111.66C132.924 110.638 132.685 109.625 132.195 108.728C131.704 107.831 130.981 107.082 130.102 106.561C129.468 106.185 128.769 105.938 128.046 105.829C127.58 105.759 127.196 106.148 127.196 106.619C127.197 107.091 127.583 107.464 128.044 107.564C128.461 107.654 128.862 107.811 129.231 108.03C129.847 108.395 130.353 108.919 130.696 109.547C131.039 110.175 131.207 110.884 131.182 111.599C131.166 112.028 131.082 112.451 130.933 112.85C130.768 113.291 130.873 113.818 131.269 114.073Z"
            fill="#8194B0"/>
        <path
            d="M114.13 70.5055C114.593 70.5926 114.904 71.0422 114.749 71.4873C114.356 72.6135 113.616 73.5953 112.625 74.2844C111.398 75.1371 109.886 75.4736 108.414 75.2212C106.942 74.9688 105.628 74.1478 104.756 72.9353C103.884 71.7228 103.523 70.2161 103.752 68.7401C103.981 67.2641 104.78 65.9373 105.979 65.0458C107.177 64.1543 108.678 63.7697 110.157 63.9749C111.637 64.1801 112.976 64.9586 113.887 66.1426C114.623 67.0995 115.031 68.2593 115.064 69.4516C115.078 69.9229 114.645 70.2571 114.177 70.1998C113.709 70.1425 113.386 69.7136 113.342 69.2441C113.273 68.5003 112.996 67.7847 112.534 67.1834C111.896 66.3546 110.959 65.8096 109.923 65.666C108.887 65.5224 107.837 65.7916 106.998 66.4157C106.159 67.0397 105.599 67.9685 105.439 69.0017C105.279 70.0348 105.531 71.0896 106.142 71.9383C106.752 72.7871 107.672 73.3618 108.702 73.5384C109.733 73.7151 110.792 73.4796 111.65 72.8827C112.273 72.4497 112.754 71.8517 113.045 71.1636C113.228 70.7294 113.666 70.4185 114.13 70.5055Z"
            fill="#E1E6EE"/>
        <path
            d="M131.955 70.5055C132.418 70.5926 132.729 71.0422 132.574 71.4873C132.181 72.6135 131.441 73.5953 130.449 74.2844C129.223 75.1371 127.711 75.4736 126.239 75.2212C124.767 74.9688 123.453 74.1478 122.58 72.9353C121.708 71.7228 121.348 70.2161 121.576 68.7401C121.805 67.2641 122.605 65.9373 123.804 65.0458C125.002 64.1543 126.503 63.7697 127.982 63.9749C129.462 64.1801 130.801 64.9586 131.712 66.1426C132.448 67.0995 132.856 68.2593 132.889 69.4516C132.902 69.9229 132.47 70.2571 132.002 70.1998C131.534 70.1425 131.211 69.7136 131.167 69.2441C131.098 68.5003 130.821 67.7847 130.358 67.1834C129.721 66.3546 128.783 65.8096 127.748 65.666C126.712 65.5224 125.661 65.7916 124.823 66.4157C123.984 67.0397 123.424 67.9685 123.264 69.0017C123.103 70.0348 123.356 71.0896 123.966 71.9383C124.577 72.7871 125.497 73.3618 126.527 73.5384C127.558 73.7151 128.616 73.4796 129.475 72.8827C130.097 72.4497 130.579 71.8517 130.87 71.1636C131.053 70.7294 131.491 70.4185 131.955 70.5055Z"
            fill="#E1E6EE"/>
        <path
            d="M113.444 72.229C113.841 72.484 114.376 72.371 114.569 71.9408C114.868 71.2734 115.037 70.552 115.063 69.8157C115.1 68.7938 114.86 67.781 114.37 66.8837C113.88 65.9865 113.157 65.2378 112.277 64.7166C111.643 64.341 110.945 64.0935 110.221 63.9848C109.755 63.9147 109.371 64.3038 109.372 64.7752C109.372 65.2467 109.759 65.6194 110.22 65.7193C110.636 65.8095 111.037 65.9664 111.407 66.1854C112.022 66.5503 112.528 67.0743 112.872 67.7024C113.215 68.3305 113.382 69.0395 113.357 69.7548C113.342 70.1842 113.257 70.6063 113.108 71.0055C112.943 71.4472 113.048 71.974 113.444 72.229Z"
            fill="#8194B0"/>
        <path
            d="M131.269 72.229C131.666 72.484 132.201 72.371 132.393 71.9408C132.693 71.2734 132.862 70.552 132.888 69.8157C132.924 68.7938 132.685 67.781 132.195 66.8837C131.704 65.9865 130.981 65.2378 130.102 64.7166C129.468 64.341 128.769 64.0935 128.046 63.9848C127.58 63.9147 127.196 64.3038 127.196 64.7752C127.197 65.2467 127.583 65.6194 128.044 65.7193C128.461 65.8095 128.862 65.9664 129.231 66.1854C129.847 66.5503 130.353 67.0743 130.696 67.7024C131.039 68.3305 131.207 69.0395 131.182 69.7548C131.166 70.1842 131.082 70.6063 130.933 71.0055C130.768 71.4472 130.873 71.974 131.269 72.229Z"
            fill="#8194B0"/>
        <path
            d="M114.13 154.315C114.593 154.402 114.904 154.851 114.749 155.296C114.356 156.423 113.616 157.404 112.625 158.094C111.398 158.946 109.886 159.283 108.414 159.03C106.942 158.778 105.628 157.957 104.756 156.744C103.884 155.532 103.523 154.025 103.752 152.549C103.981 151.073 104.78 149.746 105.979 148.855C107.177 147.963 108.678 147.579 110.157 147.784C111.637 147.989 112.976 148.768 113.887 149.952C114.623 150.909 115.031 152.068 115.064 153.261C115.078 153.732 114.645 154.066 114.177 154.009C113.709 153.952 113.386 153.523 113.342 153.053C113.273 152.309 112.996 151.594 112.534 150.992C111.896 150.164 110.959 149.619 109.923 149.475C108.887 149.331 107.837 149.601 106.998 150.225C106.159 150.849 105.599 151.778 105.439 152.811C105.279 153.844 105.531 154.899 106.142 155.747C106.752 156.596 107.672 157.171 108.702 157.348C109.733 157.524 110.792 157.289 111.65 156.692C112.273 156.259 112.754 155.661 113.045 154.973C113.228 154.538 113.666 154.228 114.13 154.315Z"
            fill="#E1E6EE"/>
        <path
            d="M131.955 154.315C132.418 154.402 132.729 154.851 132.574 155.296C132.181 156.423 131.441 157.404 130.449 158.094C129.223 158.946 127.711 159.283 126.239 159.03C124.767 158.778 123.453 157.957 122.58 156.744C121.708 155.532 121.348 154.025 121.576 152.549C121.805 151.073 122.605 149.746 123.804 148.855C125.002 147.963 126.503 147.579 127.982 147.784C129.462 147.989 130.801 148.768 131.712 149.952C132.448 150.909 132.856 152.068 132.889 153.261C132.902 153.732 132.47 154.066 132.002 154.009C131.534 153.952 131.211 153.523 131.167 153.053C131.098 152.309 130.821 151.594 130.358 150.992C129.721 150.164 128.783 149.619 127.748 149.475C126.712 149.331 125.661 149.601 124.823 150.225C123.984 150.849 123.424 151.778 123.264 152.811C123.103 153.844 123.356 154.899 123.966 155.747C124.577 156.596 125.497 157.171 126.527 157.348C127.558 157.524 128.616 157.289 129.475 156.692C130.097 156.259 130.579 155.661 130.87 154.973C131.053 154.538 131.491 154.228 131.955 154.315Z"
            fill="#E1E6EE"/>
        <path
            d="M113.444 156.037C113.841 156.292 114.376 156.179 114.569 155.749C114.868 155.081 115.037 154.36 115.063 153.624C115.1 152.602 114.86 151.589 114.37 150.692C113.88 149.795 113.157 149.046 112.277 148.525C111.643 148.149 110.945 147.902 110.221 147.793C109.755 147.723 109.371 148.112 109.372 148.583C109.372 149.055 109.759 149.428 110.22 149.527C110.636 149.618 111.037 149.774 111.407 149.994C112.022 150.358 112.528 150.882 112.872 151.511C113.215 152.139 113.382 152.848 113.357 153.563C113.342 153.992 113.257 154.414 113.108 154.814C112.943 155.255 113.048 155.782 113.444 156.037Z"
            fill="#8194B0"/>
        <path
            d="M131.269 156.037C131.666 156.292 132.201 156.179 132.393 155.749C132.693 155.081 132.862 154.36 132.888 153.624C132.924 152.602 132.685 151.589 132.195 150.692C131.704 149.795 130.981 149.046 130.102 148.525C129.468 148.149 128.769 147.902 128.046 147.793C127.58 147.723 127.196 148.112 127.196 148.583C127.197 149.055 127.583 149.428 128.044 149.527C128.461 149.618 128.862 149.774 129.231 149.994C129.847 150.358 130.353 150.882 130.696 151.511C131.039 152.139 131.207 152.848 131.182 153.563C131.166 153.992 131.082 154.414 130.933 154.814C130.768 155.255 130.873 155.782 131.269 156.037Z"
            fill="#8194B0"/>
        <path
            d="M37.0371 27.406C37.0371 26.7729 37.5503 26.2598 38.1833 26.2598H70.2768C70.9098 26.2598 71.423 26.7729 71.423 27.406C71.423 28.039 70.9098 28.5522 70.2768 28.5522H38.1833C37.5503 28.5522 37.0371 28.039 37.0371 27.406Z"
            fill="#BEC8D7"/>
        <path
            d="M29.7778 30.4611C29.7778 29.8281 30.291 29.3149 30.924 29.3149H63.0175C63.6505 29.3149 64.1637 29.8281 64.1637 30.4611C64.1637 31.0942 63.6505 31.6073 63.0175 31.6073H30.924C30.291 31.6073 29.7778 31.0942 29.7778 30.4611Z"
            fill="#BEC8D7"/>
        <path
            d="M8 23.2027C8 20.8816 9.88162 19 12.2027 19H144.397C146.718 19 148.6 20.8816 148.6 23.2027V37.7212C148.6 40.0423 146.718 41.9239 144.397 41.9239H12.2027C9.88162 41.9239 8 40.0423 8 37.7212V23.2027Z"
            fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M21.4781 30.286C21.4781 31.607 20.4072 32.6778 19.0863 32.6778C17.7654 32.6778 16.6946 31.607 16.6946 30.286C16.6946 28.9651 17.7654 27.8943 19.0863 27.8943C20.4072 27.8943 21.4781 28.9651 21.4781 30.286ZM21.0759 32.9132C20.523 33.3325 19.8337 33.5813 19.0863 33.5813C17.2664 33.5813 15.791 32.106 15.791 30.286C15.791 28.4661 17.2664 26.9907 19.0863 26.9907C20.9063 26.9907 22.3816 28.4661 22.3816 30.286C22.3816 31.0328 22.1333 31.7215 21.7146 32.2741L23.0684 33.6278C23.2448 33.8043 23.2448 34.0903 23.0684 34.2667C22.8919 34.4432 22.6059 34.4432 22.4295 34.2667L21.0759 32.9132Z"
              fill="#BEC8D7"/>
        <path
            d="M33.5981 30.4611C33.5981 29.8281 34.1113 29.3149 34.7443 29.3149H88.9976C89.6306 29.3149 90.1438 29.8281 90.1438 30.4611C90.1438 31.0942 89.6306 31.6073 88.9976 31.6073H34.7443C34.1113 31.6073 33.5981 31.0942 33.5981 30.4611Z"
            fill="#BEC8D7"/>
      </svg>

  );
};
export default OpportunityIllustration;
