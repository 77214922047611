import React from 'react';
import { LIGHT_GREY_COLOR_FOR_ICONS } from '@constants/colors';

export default ({ color = LIGHT_GREY_COLOR_FOR_ICONS }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.83614 12.881L3.55534 11.1019C3.55535 11.1019 3.55535 11.1019 3.55535 11.1019C3.6527 10.8612 3.60806 10.5863 3.43963 10.3887C2.65603 9.46935 2.24375 8.37103 2.24375 7.21094C2.24375 4.35199 4.81207 2.00156 8 2.00156C11.1879 2.00156 13.7563 4.35199 13.7563 7.21094C13.7563 8.197 13.4504 9.15728 12.87 9.9883L12.87 9.98831C12.6497 10.3039 12.7268 10.7383 13.0424 10.9587C13.358 11.179 13.7924 11.1019 14.0128 10.7863L13.8898 10.7004L14.0128 10.7863C14.7563 9.72156 15.15 8.48505 15.15 7.21094C15.15 5.43393 14.3965 3.76795 13.0335 2.52051C11.6848 1.28606 9.89653 0.607812 8 0.607812C6.10347 0.607812 4.31522 1.28606 2.96646 2.52051C1.60351 3.76795 0.85 5.43393 0.85 7.21094C0.85 8.56801 1.28504 9.85513 2.11059 10.9572L0.900831 13.9497C0.90083 13.9497 0.900829 13.9497 0.900828 13.9497C0.794522 14.2127 0.858182 14.5138 1.06184 14.7113C1.06184 14.7113 1.06184 14.7113 1.06185 14.7113L2.83614 12.881ZM11.3742 12.8665L11.4421 13.0002C11.7853 12.8259 11.9221 12.4065 11.7479 12.0633L11.7479 12.0633C11.5736 11.7202 11.1542 11.5834 10.811 11.7576L10.811 11.7576C9.958 12.1908 8.98585 12.4203 8 12.4203C7.14328 12.4203 6.31941 12.2538 5.55041 11.9257C5.55041 11.9257 5.5504 11.9257 5.5504 11.9257L5.49154 12.0637L11.3742 12.8665ZM8.69688 9.64453V6.69141C8.69688 6.30652 8.38488 5.99453 8 5.99453C7.61512 5.99453 7.30312 6.30652 7.30312 6.69141V9.64453C7.30312 10.0294 7.61512 10.3414 8 10.3414C8.38488 10.3414 8.69688 10.0294 8.69688 9.64453ZM8 4.10781C7.61512 4.10781 7.30312 4.41981 7.30312 4.80469C7.30312 5.18957 7.61512 5.50156 8 5.50156C8.38488 5.50156 8.69688 5.18957 8.69688 4.80469C8.69688 4.41981 8.38488 4.10781 8 4.10781Z"
      fill={color}
      stroke={color}
      strokeWidth="0.3"
    />
  </svg>
);
