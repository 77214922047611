import React from 'react';
import { useInfiniteQuery } from 'react-query';

import { getInboxEmailsOfCampaignPageableApi } from '@api/mailbox.api';
import InboxThreadsContent from '@components/CampaignAnalytics/InboxThreadsContent/InboxThreadsContent';
import Loader from '@uikit/Loader/Loader';
import { sentAndNeedThreadMapper } from '@helpers/emailsHelpers';
import { ThreadPreviewResponseType } from '@ts/mailboxInbox.types';

const InboxEmails = ({
  campaignId,
  workspaceId,
  RenderHeader,
}: {
  campaignId: number;
  workspaceId: number;
  RenderHeader: () => JSX.Element;
}): JSX.Element => {
  const {
    data: rawData = [],
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ['inbox-emails', campaignId, workspaceId],
    ({ pageParam = 0 }) =>
      getInboxEmailsOfCampaignPageableApi(
        campaignId,
        pageParam === 0 ? 0 : pageParam + 1,
        pageParam === 0 ? 20 : 10,
        workspaceId
      ),
    {
      getNextPageParam: (lastPage: ThreadPreviewResponseType[], allPages) => {
        if (lastPage.length < 10) return false;

        return allPages.length;
      },
      enabled: !!campaignId && !!workspaceId,
      refetchOnWindowFocus: false,
    }
  );

  // @ts-ignore
  const inboxThreads: ThreadPreviewResponseType[] = rawData?.pages?.flat() || [];

  return (
    <>
      <RenderHeader />
      <Loader isLoading={isLoading} withTopMargin>
        <InboxThreadsContent
          threads={sentAndNeedThreadMapper(inboxThreads)}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          hasNextPage={hasNextPage}
        />
      </Loader>
    </>
  );
};

export default InboxEmails;
