import Avatar, { NamedAvatar } from '@uikit/Avatar/Avatar';
import { SVGIcon } from '@uikit/Icon/Icon';
import React from 'react';
import { components } from 'react-select';
import useWorkspaceMembers from '@hooks/useWorkspaceMembers';

function CustomOptionAvatar({ children, ...restProps }): JSX.Element {
  const { members: workspaceMembers } = useWorkspaceMembers();
  return (
    <components.Option {...restProps}>
      {restProps.data.value === -1 ? (
        <Avatar
          defaultIcon="avatarIcon"
          backgroundCustom="#DFE5FA"
          size="xxs"
          url={restProps.data.logoUrl}
        />
      ) : (
        (() => {
          let { label, value } = restProps.data;

          if (label === 'Assign to me') {
            label = workspaceMembers.find(({ userId }) => userId === value).fullName;
          }
          return (
            <Avatar
              size="xxs"
              url={restProps.data.logoUrl}
              fullName={label}
              backgroundCustom="#DFE5FA"
            />
          );
        })()
      )}
      {children}
      {restProps.selectProps.value.value === restProps.data.value ? (
        <SVGIcon icon="check" color="#7DE5B3" />
      ) : null}
    </components.Option>
  );
}

export default CustomOptionAvatar;
