import React from 'react';

export default ({ color = '#D6D6D6' }) => (
  <svg width="5" height="20" viewBox="0 0 5 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.00001 8C0.900014 8 1.65965e-05 8.9 1.65965e-05 10C1.65965e-05 11.1 0.900014 12 2.00001 12C3.10001 12 4.00001 11.1 4.00001 10C4.00001 8.9 3.10001 8 2.00001 8ZM2.00001 16C0.900014 16 1.65965e-05 16.9 1.65965e-05 18C1.65965e-05 19.1 0.900014 20 2.00001 20C3.10001 20 4.00001 19.1 4.00001 18C4.00001 16.9 3.10001 16 2.00001 16Z"
      fill={color}
    />
    <path
      d="M2.06368 0C0.963681 0 0.0636806 0.9 0.0636806 2C0.0636806 3.1 0.963681 4 2.06368 4C3.16368 4 4.06368 3.1 4.06368 2C4.06368 0.9 3.16368 0 2.06368 0Z"
      fill={color}
    />
  </svg>
);
