import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { getAllMembersOfWorkspaceShortApi } from '@api/userOrganization.api';
import { getCurrentWorkspaceId } from '@redux/selectors/workspaces.selectors';
import { WorkspaceMemberShortType } from '@ts/workspace.types';

export type MemberOption = {
  label: string;
  value: number;
  logoUrl: string;
};

const useWorkspaceMembers = (
  skip = false
): {
  members: WorkspaceMemberShortType[];
  composeMemberOptions: (replaceOwnName: boolean) => MemberOption[];
  activeUserId: number;
  isFetching: boolean;
} => {
  const curWorkspaceId = useSelector(getCurrentWorkspaceId);
  const queryOptions = useMemo(
    () => ({
      enabled: !!curWorkspaceId && !skip,
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 15 * (60 * 1000),
    }),
    [curWorkspaceId, skip]
  );

  const { data = [], isFetching } = useQuery(
    ['workspace-members', curWorkspaceId],
    () => getAllMembersOfWorkspaceShortApi(curWorkspaceId),
    queryOptions
  );

  const composeMemberOptions = useMemo(
    () =>
      (replaceOwnName: boolean): MemberOption[] => {
        return [
          { value: -1, label: 'Unassigned', logoUrl: '' },
          ...data
            .slice()
            .sort((a, b) => Number(b.isMe) - Number(a.isMe))
            .map((member) => ({
              value: member.userId,
              label: replaceOwnName
                ? member.isMe
                  ? 'Assign to me'
                  : member.fullName
                : member.fullName,
              logoUrl: member.logoUrl,
            })),
        ];
      },
    [data]
  );

  const activeUserId = useMemo(() => data.find((it) => it.isMe)?.userId ?? -1, [data]);

  return {
    members: data,
    composeMemberOptions,
    activeUserId,
    isFetching,
  };
};

export default useWorkspaceMembers;
