import React, { useEffect } from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';

import MultiValueContainer from '@uikit/Select/_components/MultiValueContainer';
import { ClearbitOptionsType } from '@ts/clearbit.types';
import { addNotification } from '@redux/actions/notifications.actions';
import { useDispatch } from 'react-redux';

export const textStyles = {
  fontFamily: 'Mulish',
  fontStyle: 'normal',
  fontWeight: 'normal',
  lineHeight: '100%',
  color: '#000000',
  fontSize: '14px',
} as React.CSSProperties;

const getCustomStyles = (isGhostProvided) => ({
  input: (provided) => {
    if (isGhostProvided) {
      return {
        ...provided,
        color: '#fff',
      };
    }

    return provided;
  },
  control: (provided, { selectProps: { isGhost } }) => {
    if (isGhost) {
      return {
        ...provided,
        backgroundColor: '#181818',
        border: 'none',
        color: '#fff',
        '&:focus,&:hover': {
          outline: 'none',
          border: 'none',
          boxShadow: 'none',
        },
      };
    }

    return {
      ...provided,
      borderRadius: '8.5px',
      '&:focus,&:hover': {
        outline: 'none',
        border: '1px solid #221CB6',
        boxShadow: 'none',
      },
    };
  },
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#000',
    borderRadius: '8px',
  }),
  menuList: (base) => ({
    ...base,
    '::-webkit-scrollbar': {
      width: '6px',
    },
    '::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '30px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#888',
    },
  }),
  option: (provided, { isFocused, isSelected }) => ({
    ...provided,
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    '&:hover, &:focus': {
      backgroundColor: '#181818',
    },
    ...(isFocused || isSelected ? { backgroundColor: '#181818' } : {}),
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#E9E9E9',
    borderRadius: '6px',
  }),
  multiValueLabel: (provided, { selectProps: { isMulti } }) => ({
    ...provided,
    ...textStyles,
    fontSize: '14px',
    overflow: 'hidden',
    ...(isMulti
      ? {
          maxWidth: '150px',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }
      : {}),
  }),
});

function IndicatorSeparator() {
  return null;
}

function ClearbitAsyncSelect({
  additionalComponents = {},
  additionalStyles = {},
  allowCustomInput = false,
  visibleMultiCount = 1,
  disableСonvolution = false,
  innerRef = null,
  ...props
}) {
  const dispatch = useDispatch();
  const loadOptions = (inputValue: string): Promise<ClearbitOptionsType[]> => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(
          `https://autocomplete.clearbit.com/v1/companies/suggest?query=${inputValue}`
        );
        if (!response.ok) {
          reject('Network error');
        }
        const info = await response.json();
        const options = info.map(({ name, domain, logo }) => ({
          value: domain,
          label: `${name} (${domain})`,
          name,
          domain,
          logo,
        })) as ClearbitOptionsType[];

        if (allowCustomInput && inputValue) {
          options.unshift({
            value: inputValue,
            label: inputValue,
            name: inputValue,
            domain: inputValue,
            logo: '',
          } as ClearbitOptionsType);
        }

        resolve(options);
      } catch (error) {
        reject(error);
      }
    });
  };

  const onCreateNewProp = (inputValue) => {
    const domainRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+.*)$/;

    if (domainRegex.test(inputValue)) {
      props.onChange({
        value: inputValue,
        label: inputValue,
        name: inputValue,
        domain: inputValue.replace(/^(https?:\/\/)?/, ''),
        logo: '',
        query: '',
      });
    } else {
      dispatch(addNotification({ title: 'Invalid domain format', type: 'error' }));
    }
  };

  useEffect(() => {
    if (!props.value?.value?.length) {
      return;
    }
    if (props.value.value === props.value.query) {
      return;
    }
    if (props.value.query === '') {
      return;
    }
    (async () => {
      try {
        const response = await fetch(
          `https://autocomplete.clearbit.com/v1/companies/suggest?query=${
            props.value?.value || props.value
          }`
        );
        const info = await response.json();
        if (!!info.length && info[0]) {
          const { domain, name, logo } = info[0];
          props.onChange({
            value: domain,
            label: `${name} (${domain})`,
            name,
            domain,
            logo,
            query: props.value?.value || props.value,
          });
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [props]);

  return (
    <AsyncCreatableSelect
      {...props}
      cacheOptions
      loadOptions={loadOptions}
      ref={innerRef}
      placeholder={props.value?.name || 'Start typing...'}
      disableСonvolution={disableСonvolution}
      visibleMultiCount={visibleMultiCount}
      styles={{ ...getCustomStyles(props.isGhost), ...additionalStyles }}
      components={{ ...additionalComponents, MultiValueContainer, IndicatorSeparator }}
      onCreateOption={(inputValue) => onCreateNewProp(inputValue)}
      isCreateable={allowCustomInput}
    />
  );
}

export default ClearbitAsyncSelect;
