import React from 'react';

export default ({ color = '#fff' }) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.18225 0.821273L12.9517 6.74444C12.9975 6.76962 13.0356 6.80661 13.0622 6.85155C13.0887 6.89649 13.1027 6.94774 13.1027 6.99994C13.1027 7.05214 13.0887 7.10339 13.0622 7.14833C13.0356 7.19327 12.9975 7.23026 12.9517 7.25544L2.18225 13.1786C2.13784 13.203 2.08784 13.2155 2.03716 13.2147C1.98648 13.2139 1.93688 13.1999 1.89325 13.1741C1.84962 13.1483 1.81346 13.1116 1.78834 13.0676C1.76322 13.0236 1.75001 12.9738 1.75 12.9231V1.07677C1.75001 1.02609 1.76322 0.976284 1.78834 0.932264C1.81346 0.888244 1.84962 0.85153 1.89325 0.82574C1.93688 0.79995 1.98648 0.785974 2.03716 0.785191C2.08784 0.784408 2.13784 0.796844 2.18225 0.821273ZM2.91667 7.58327V11.4432L10.9958 6.99994L2.91667 2.55669V6.41661H5.83333V7.58327H2.91667Z"
      fill={color}
    />
  </svg>
);
