import React from 'react';

export default ({ color = '#949494' }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.99992 1.33337L1.33325 6.66671V15.3334H5.99992V10.6667H9.99992V15.3334H14.6666V6.66671L7.99992 1.33337Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
