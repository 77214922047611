/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect, useRef } from 'react';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';

import MultiValueContainer from '@uikit/Select/_components/MultiValueContainer';
import { SVGIcon, SvgIconType } from '@uikit/Icon/Icon';

import { getCustomStyles } from './styles';

import './Select.scss';

export const textStyles = {
  fontFamily: 'Mulish',
  fontStyle: 'normal',
  fontWeight: 'normal',
  lineHeight: '100%',
  color: '#000000',
  fontSize: '14px',
} as React.CSSProperties;

function IndicatorSeparator() {
  return null;
}

function Select({
  additionalComponents = {},
  additionalStyles = {},
  allowCustomInput = false,
  allowSelectAll = false,
  visibleMultiCount = 1,
  disableConvolution = false,
  options,
  onInputChange = undefined,
  innerRef = null,
  isCreatableSelect = false,
  icon = null,
  iconColor = undefined,
  selectClass = '',
  ...props
}: any) {
  const [computedOptions, changeComputedOptions] = useState(options);
  const searchText = useRef('');

  useEffect(() => {
    changeComputedOptions(options);
  }, [options]);

  const handleInputChange = (value: string, { action }) => {
    if (action === 'set-value' || action === 'input-change') {
      changeComputedOptions([
        ...options,
        {
          value,
          label: value,
        },
      ]);
    }
  };

  const getInputChangeHandler = () => {
    return (value: string, data: { action: string }) => {
      if (!data) {
        searchText.current = value;
      }

      if (onInputChange) {
        onInputChange(value, data);
      }

      if (allowCustomInput) {
        handleInputChange(value, data);
      }
    };
  };

  const allProps = {
    ...props,
    ref: innerRef,
    disableConvolution,
    visibleMultiCount,
    options: computedOptions,
    onInputChange: getInputChangeHandler(),
    styles: { ...getCustomStyles(props.isGhost, !!icon), ...additionalStyles },
    components: { MultiValueContainer, IndicatorSeparator, ...additionalComponents },
  };

  if (allowSelectAll) {
    const SELECT_ALL_VALUE = 'select-all';
    const selectAllOption = {
      value: SELECT_ALL_VALUE,
      label: 'Select all',
    };
    const customFilter = (option, searchValue: string) => {
      if (option.value === SELECT_ALL_VALUE) {
        return true;
      }

      return option.label.includes(searchValue);
    };

    allProps.options = [selectAllOption, ...computedOptions];
    allProps.onChange = (...params) => {
      if (!props.onChange) {
        return;
      }

      const [_, clickedOption] = params;

      if (clickedOption.option?.value === SELECT_ALL_VALUE) {
        if (clickedOption.action === 'select-option') {
          const filteredOptions =
            computedOptions?.filter((option) => customFilter(option, searchText.current)) ?? [];
          props.onChange(filteredOptions);
        } else if (clickedOption.action === 'deselect-option') {
          props.onChange([]);
        }
      } else {
        props.onChange(...params);
      }
    };

    allProps.filterOption = props.filterOption || customFilter;
    allProps.value = allProps.value?.filter((option) => option.value !== SELECT_ALL_VALUE) ?? [];
  }

  if (isCreatableSelect) {
    return <CreatableSelect {...allProps} />;
  }

  return (
    <div className={`select ${selectClass}`}>
      <ReactSelect {...allProps} />
      {icon ? (
        <div className="select__icon">
          <SVGIcon icon={icon as SvgIconType} color={iconColor} />
        </div>
      ) : null}
    </div>
  );
}

export default Select;
