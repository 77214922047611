import { ContactStatus } from 'respona_api/generated/common_pb';

export const contactCategoryMapper = {
  0: 'FROM_UNKNOWN',
  1: 'FROM_PAGE',
  2: 'FROM_SEARCH',
  3: 'FROM_AUTOMATION',
  4: 'FROM_IMPORT',
  5: 'FROM_MANUAL',
};

export const contactCategoryToGroupMapper = {
  0: 'UNKNOWN',
  1: 'PAGE',
  2: 'SEARCH',
  3: 'AUTOMATION',
  4: 'IMPORT',
  5: 'IMPORT',
};

export const contactStatusMapper = {
  0: 'Not contacted',
  1: 'Contacted',
  2: 'Replied',
  3: 'Accepted',
  4: 'Rejected',
  5: 'Not applicable',
  6: 'In progress',
  7: 'Bounced',
  8: 'Scheduled',
  9: 'Unsubscribed',
  10: 'Stopped',
  11: 'Unknown',
};

export const contactStatusIconMap = {
  [ContactStatus.NOT_CONTACTED]: require('../assets/people/not-contacted.svg'),
  [ContactStatus.CONTACTED]: require('../assets/people/contacted.svg'),
  [ContactStatus.REPLIED]: require('../assets/people/replied-interested.svg'),
  [ContactStatus.ACCEPTED]: require('../assets/people/collaborating.svg'),
  [ContactStatus.REJECTED]: require('../assets/people/replied-not-interested.svg'),
  [ContactStatus.NOT_APPLICABLE]: require('../assets/people/replied-not-interested.svg'),
  [ContactStatus.IN_PROGRESS]: require('../assets/people/attempting.svg'),
  [ContactStatus.BOUNCED]: require('../assets/people/bounced.svg'),
  [ContactStatus.ATTEMPT_TO_REACH]: require('../assets/people/attempting.svg'),
  [ContactStatus.UNSUBSCRIBED]: require('../assets/people/unsubscribed.svg'),
  [ContactStatus.STOPPED]: require('../assets/people/stopped.svg'),
  [ContactStatus.UNKNOWN]: null,
};
