import React from 'react';

import { useSelector } from 'react-redux';

import { userProfileSelector } from '@redux/selectors/userProfile.selector';

import Avatar from '@uikit/Avatar/Avatar';

import Display from '@components/Display/Display';

import LogoutButton from '../LogoutButton/LogoutButton';

const SidebarFooterProfile = (): JSX.Element => {
  const {
    info: { avatarUrl, firstName, lastName },
  } = useSelector(userProfileSelector);

  return (
    <div className="sidebar-footer__profile">
      <div className="sidebar-footer__profile-avatar">
        <Avatar url={avatarUrl} fullName={`${firstName} ${lastName}`} size="sl" />
        <Display isVisible>
          <div className="background-on-hover-avatar" />
          <div className="logout-on-hover-avatar">
            <LogoutButton color="#ED483D" />
          </div>
        </Display>
      </div>
    </div>
  );
};

export default SidebarFooterProfile;
