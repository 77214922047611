import request from '@api/base/request';
import userSharedService from '@api/services/userSharedService';

import {
  GetNotificationSettingsRequest,
  NotificationSettingsContainer,
  SetNotificationSettingsRequest,
} from 'respona_api/generated/user-shared_pb';

export const getNotificationSettingsSharedApi = (
  hash: string
): Promise<NotificationSettingsContainer.AsObject> => {
  const client = userSharedService.getInstance();

  const notificationSettingsRequest = new GetNotificationSettingsRequest();
  notificationSettingsRequest.setUserHash(hash);

  return request<NotificationSettingsContainer.AsObject>(
    client,
    client.getNotificationSettings,
    notificationSettingsRequest
  );
};

export const updateNotificationSettingStateSharedApi = (
  id: number,
  value: boolean,
  hash: string
): Promise<null> => {
  const client = userSharedService.getInstance();
  const updateSubscriptionRequest = new SetNotificationSettingsRequest();

  updateSubscriptionRequest.setNotificationId(id);
  updateSubscriptionRequest.setIsDisabled(value);
  updateSubscriptionRequest.setUserHash(hash);

  return request<null>(client, client.setNotificationSetting, updateSubscriptionRequest);
};
