import React from 'react';

import './GraphicHeader.scss';

const GraphicHeader = ({ dataHeader }: { dataHeader?: any }): JSX.Element => {
  return (
    <ul className="header-list">
      {dataHeader?.map((item, index) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <li className="header-list_item" key={index}>
            <h4 className="header-list_item__title">{item.day}</h4>
            <p className="header-list_item__text">{item.timeRange}</p>
          </li>
        );
      })}
    </ul>
  );
};

export default GraphicHeader;