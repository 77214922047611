import React from 'react';

export default ({ color = '#000' }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.80474 2.74745C1.54439 2.4871 1.54439 2.06499 1.80474 1.80464C2.06509 1.54429 2.4872 1.54429 2.74755 1.80464L13.2469 12.304C13.5072 12.5643 13.5072 12.9864 13.2469 13.2468C12.9865 13.5071 12.5644 13.5071 12.3041 13.2468L1.80474 2.74745Z"
      fill={color}
    />
    <path
      d="M3.52004 2.58211C3.07483 2.82238 2.67304 3.14354 2.33717 3.53373C1.64753 4.33494 1.28707 5.36769 1.32839 6.42402C1.36971 7.48035 1.80974 8.48179 2.55986 9.22668L6.69986 13.3733C7.04654 13.7145 7.51345 13.9058 7.99986 13.9058C8.48627 13.9058 8.95318 13.7145 9.29986 13.3733L11.8035 10.8656L10.8722 9.9343L8.35986 12.4467C8.31275 12.4942 8.25668 12.532 8.19489 12.5578C8.13309 12.5835 8.06681 12.5968 7.99986 12.5968C7.93291 12.5968 7.86662 12.5835 7.80483 12.5578C7.74304 12.532 7.68697 12.4942 7.63986 12.4467L3.49986 8.28668C2.97703 7.75224 2.68427 7.03432 2.68427 6.28668C2.68426 5.53903 2.97703 4.82111 3.49986 4.28668C3.80335 3.98703 4.16713 3.76237 4.56243 3.6245L3.52004 2.58211Z"
      fill={color}
    />
    <path
      d="M11.815 8.99149L12.4999 8.30668V8.28668C13.0156 7.7453 13.2989 7.02346 13.2889 6.2758C13.2789 5.52814 12.9765 4.81411 12.4465 4.28668C11.9138 3.76067 11.1952 3.46572 10.4465 3.46572C9.69784 3.46572 8.9793 3.76067 8.44653 4.28668C8.38455 4.34916 8.31082 4.39876 8.22958 4.4326C8.14834 4.46645 8.0612 4.48388 7.97319 4.48388C7.88518 4.48388 7.79805 4.46645 7.71681 4.4326C7.63557 4.39876 7.56183 4.34916 7.49986 4.28668C7.20062 3.99123 6.84277 3.76869 6.45389 3.63035L4.94226 2.11872C4.97646 2.11407 5.01076 2.10984 5.04514 2.10603C6.09585 1.98968 7.15163 2.27577 7.99986 2.90668C8.80837 2.31699 9.80111 2.03588 10.7988 2.11412C11.7964 2.19237 12.7332 2.62481 13.4399 3.33334C14.2182 4.1165 14.6551 5.17583 14.6551 6.28001C14.6551 7.38419 14.2182 8.44352 13.4399 9.22668L12.7456 9.92205L11.815 8.99149Z"
      fill={color}
    />
  </svg>
);
