import React from 'react';

import { RenderConfigurableSidebarLink } from '@uikit/ConfigurableSidebar/_components/ConfigurableSidebarLinks';

export default [
  {
    title: 'Campaign',
    pageKey: 'campaignReports',
    RenderLink: ({ pathname }) => (
      <RenderConfigurableSidebarLink
        title="Campaign Reports"
        icon="reportBox"
        url="/insights/campaign-reports"
        isActive={pathname === '/insights/campaign-reports'}
      />
    ),
  },
  {
    title: 'Leader',
    pageKey: 'leaderBoards',
    RenderLink: ({ pathname }) => (
      <RenderConfigurableSidebarLink
        title="Leaderboards"
        icon="scaleIcon"
        url="/insights/leader-boards"
        isActive={pathname === '/insights/leader-boards'}
      />
    ),
  },
  {
    title: 'Credits Usage',
    pageKey: 'creditsUsageByUser',
    RenderLink: ({ pathname }) => (
      <RenderConfigurableSidebarLink
        title="Credits Usage"
        icon="coins"
        url="/insights/credits-usage"
        isActive={pathname === '/insights/credits-usage'}
      />
    ),
  },
  // {
  //   title: 'Detailed Credits Usage',
  //   pageKey: 'detailedCreditsUsage',
  //   RenderLink: ({ pathname }) => (
  //     <RenderConfigurableSidebarLink
  //       title="Detailed Credits Usage"
  //       icon="coins"
  //       url="/insights/detailed-credits-usage"
  //       isActive={pathname === '/insights/detailed-credits-usage'}
  //     />
  //   ),
  // },
  // {
  //   title: 'Reports',
  //   pageKey: 'emailReports',
  //   RenderLink: ({ pathname }) => (
  //     <RenderConfigurableSidebarLink
  //       title="Email Reports"
  //       icon="emails"
  //       url="/insights/email-reports"
  //       isActive={pathname === '/insights/email-reports'}
  //     />
  //   ),
  // },
  {
    title: 'Health',
    pageKey: 'emailHealth',
    RenderLink: ({ pathname }) => (
      <RenderConfigurableSidebarLink
        title="Email Health"
        icon="emailHealth"
        url="/insights/email-health"
        isActive={pathname === '/insights/email-health'}
      />
    ),
  },
  // {
  //   title: 'Warmup',
  //   pageKey: 'emailWarmup',
  //   RenderLink: ({ pathname }) => (
  //     <RenderDefaultLink
  //       title="Email Warmup"
  //       icon="messageIcon"
  //       url="/insights/email-warmup"
  //       isActive={pathname === '/insights/email-warmup'}
  //     />
  //   ),
  // },
];
