export const sendingStrategyOptions = [
  {
    value: 1,
    label: 'Send-all strategy',
    tooltipText:
      'If multiple emails are assigned to an opportunity, send the sequence emails to all emails assigned at the same time in different threads.',
  },
  // {
  //   value: 0,
  //   label: 'Bounce strategy',
  //   tooltipText:
  //     'If multiple emails are assigned to an opportunity, send the sequence emails to the first email assigned, and if that email bounced, try the next email assigned as a backup.',
  // },
  {
    value: 2,
    label: 'CC strategy',
    tooltipText:
      'If multiple emails are assigned to an opportunity, send the sequence emails to the first email assigned, and CC all other selected emails.',
  },
  {
    value: 3,
    label: 'No-reply strategy',
    tooltipText:
      'If multiple people are assigned to an opportunity, send all steps of the sequence to the first person, but if that person doesn’t reply, try the next person assigned.',
  },
];

export const priorityOptions = [
  {
    value: 0,
    label: 'High',
  },
  {
    value: 1,
    label: 'Medium',
  },
  {
    value: 2,
    label: 'Low',
  },
];
