import React, { memo } from 'react';
import cn from 'class-names';

import { SVGIcon, SvgIconType } from '../Icon/Icon';
import './Input.scss';

type InputPropsType = {
  value: string | number;
  placeholder?: string;
  onChange?: (any) => void;
  isInline?: boolean;
  isFullWidth?: boolean;
  isFullHeight?: boolean;
  type?: string;
  afterText?: React.ReactNode;
  beforeText?: React.ReactNode;
  onKeyDown?: (any) => void;
  isTransparent?: boolean;
  isClearable?: boolean;
  onClear?: () => void;
  disabled?: boolean;
  icon?: string;
  iconColor?: string;
  inputRef?: any;
  error?: any;
  isError?: boolean;
  className?: string;
  iconSize?: number;
  [key: string]: any;
};

const Input = (props: InputPropsType) => {
  const {
    value,
    placeholder = 'Input',
    onChange,
    isInline = false,
    isFullWidth = false,
    isFullHeight = false,
    type = 'text',
    afterText = '',
    beforeText = '',
    onKeyDown,
    isTransparent = false,
    disabled = false,
    isClearable = false,
    onClear = () => null,
    icon = '',
    iconColor,
    inputRef,
    className = '',
    autoFocus = false,
    error = '',
    isError = false,
    containerStyle = undefined,
    iconSize,
    ...rest
  } = props;

  const cnInput = cn('input', {
    'input--inline': isInline,
    'input--disabled': disabled,
    'input--transparent': isTransparent,
    'input--with-after-text': !!afterText,
    'input--with-icon': icon.length,
    'input--full-width': isFullWidth,
    'input--full-height': isFullHeight,
    'input--clearable': isClearable,
    'input--error': error.length > 0 || isError,
    [className]: className.length > 0,
  });

  return (
    <div className={cnInput} style={containerStyle}>
      {icon.length ? (
        <div className="input__icon">
          <SVGIcon icon={icon as SvgIconType} color={iconColor} size={iconSize} />
        </div>
      ) : null}
      <input
        {...rest}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
        placeholder={isInline && !placeholder ? '' : placeholder}
        onChange={onChange && !disabled ? onChange : null}
        type={type}
        value={value}
        className="input__field"
        onKeyDown={onKeyDown}
        disabled={disabled}
        ref={inputRef}
      />
      {isClearable && value !== '' ? (
        <div className="input__clear-icon" onClick={onClear}>
          <SVGIcon icon="crossDelete" />
        </div>
      ) : null}
      {beforeText ? <span className="input__before-text">{beforeText}</span> : null}
      {afterText ? <span className="input__after-text">{afterText}</span> : null}
      {error ? <span className="input__error">{error}</span> : null}
    </div>
  );
};

export default memo(Input);
