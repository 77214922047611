import React from 'react';
import cn from 'class-names';

import { SVGIcon } from '@uikit/Icon/Icon';

import './InboxSidebarMailboxesButton.scss';

function InboxSidebarMailboxesButton({
  number,
  isOpen,
  numberUnreadMessages,
  isActive,
}: {
  number: number;
  isOpen?: boolean;
  numberUnreadMessages: number;
  isActive: boolean;
}): JSX.Element {
  const cnContainer = cn('inbox-sidebar-mailboxes-btn', {
    'inbox-sidebar-mailboxes-btn--open': isOpen,
    'inbox-sidebar-mailboxes-btn--active': isActive,
    'inbox-sidebar-mailboxes-btn--disabled': !number,
  });

  return (
    <div className={cnContainer}>
      <div className="inbox-sidebar-mailboxes-btn__text">
        <span className="inbox-sidebar-mailboxes-btn__icon">
          <SVGIcon icon="inbox" color="#202430" />
        </span>
        {!number ? 'No inbox' : `${number} Inbox${number === 1 ? '' : 'es'}`}
      </div>

      <span className="inbox-sidebar-mailboxes-btn__number">{numberUnreadMessages ? numberUnreadMessages : null}</span>
    </div>
  );
}

export default InboxSidebarMailboxesButton;
