import GoogleSVG from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/SearchTypesTab/assets/GoogleSVG';
import KeywordsSearcgSVG from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/SearchTypesTab/assets/KeywordsSearcgSVG';
import PodcastSVG from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/SearchTypesTab/assets/PodcastSVG';
import CSVImportSVG from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/SearchTypesTab/assets/CSVImportSVG';
import { SearchModeType } from '@ts/automationContent.types';

export type SearchTypesTabItemConfigType = {
  icon: (e?: any) => JSX.Element;
  title: string;
  value: SearchModeType;
};

const config: SearchTypesTabItemConfigType[] = [
  {
    icon: GoogleSVG,
    title: 'Google search',
    value: 0,
  },
  {
    icon: KeywordsSearcgSVG,
    title: 'Backlink search',
    value: 1,
  },
  {
    icon: PodcastSVG,
    title: 'Podcast search',
    value: 2,
  },
  {
    icon: CSVImportSVG,
    title: 'CSV Import',
    value: 3,
  },
];

export default config;
