import StepCreateWorkspace from '@pages/CreateWorkspacePage/_components/StepCreateWorkspace/StepCreateWorkspace';
import StepInviteMembers from '@pages/CreateWorkspacePage/_components/StepInviteMembers/StepInviteMembers';
import StepCopyWrokspaceSettings from '@pages/CreateWorkspacePage/_components/StepCopyWrokspaceSettings/StepCopyWrokspaceSettings';

export default [
  {
    icon: 'pen',
    title: 'Create a workspace',
    description: 'This is where your team can work together to create campaigns and collaborate.',
    StepSettingsRenderer: StepCreateWorkspace,
  },
  {
    icon: 'addUserWhite',
    title: 'Invite members',
    description:
      "Invite your team members to collaborate. You can add as many teammates as you'd like!",
    StepSettingsRenderer: StepInviteMembers,
  },
  {
    icon: 'gear',
    title: 'Copy Wrokspace Settings',
    description:
      'Copy some or all of your settings from another workspace in your organization to save time!',
    StepSettingsRenderer: StepCopyWrokspaceSettings,
  },
];
