import React from 'react';

const QuestionInCircle = ({ color = '#C4C6CD', size = 14 }: { color?: string; size?: number }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.00065 0.333496C5.68211 0.333496 4.39318 0.724489 3.29685 1.45703C2.20052 2.18957 1.34604 3.23077 0.841457 4.44894C0.336873 5.66711 0.204851 7.00756 0.462086 8.30077C0.71932 9.59397 1.35426 10.7819 2.28661 11.7142C3.21896 12.6466 4.40685 13.2815 5.70005 13.5387C6.99326 13.796 8.3337 13.6639 9.55188 13.1594C10.7701 12.6548 11.8112 11.8003 12.5438 10.704C13.2763 9.60764 13.6673 8.31871 13.6673 7.00016C13.6673 6.12468 13.4949 5.25778 13.1599 4.44894C12.8248 3.6401 12.3338 2.90517 11.7147 2.28612C11.0956 1.66706 10.3607 1.176 9.55188 0.840966C8.74304 0.505935 7.87613 0.333496 7.00065 0.333496ZM7.00065 12.3335C5.94582 12.3335 4.91467 12.0207 4.03761 11.4347C3.16055 10.8486 2.47696 10.0157 2.0733 9.04114C1.66963 8.0666 1.56401 6.99425 1.7698 5.95968C1.97559 4.92512 2.48354 3.97481 3.22942 3.22893C3.9753 2.48305 4.92561 1.9751 5.96017 1.76931C6.99474 1.56352 8.06709 1.66914 9.04163 2.07281C10.0162 2.47647 10.8491 3.16006 11.4352 4.03712C12.0212 4.91418 12.334 5.94533 12.334 7.00016C12.334 8.41465 11.7721 9.77121 10.7719 10.7714C9.7717 11.7716 8.41514 12.3335 7.00065 12.3335Z"
      fill={color}
    />
    <path
      d="M6.33402 9.24015C6.33476 9.06526 6.40419 8.89766 6.52735 8.77349C6.58933 8.711 6.66306 8.66141 6.7443 8.62756C6.82554 8.59371 6.91268 8.57629 7.00069 8.57629C7.0887 8.57629 7.17583 8.59371 7.25707 8.62756C7.33831 8.66141 7.41205 8.711 7.47402 8.77349C7.59718 8.89766 7.66662 9.06526 7.66735 9.24015C7.66706 9.39371 7.61377 9.54245 7.51648 9.66126C7.41919 9.78006 7.28388 9.86164 7.1334 9.8922C6.98291 9.92277 6.82649 9.90045 6.69057 9.82902C6.55464 9.75759 6.44753 9.64142 6.38735 9.50015C6.35233 9.41794 6.33419 9.32952 6.33402 9.24015Z"
      fill={color}
    />
    <path
      d="M5.99996 4.17445C6.30424 3.9989 6.6494 3.90659 7.00069 3.90682C7.53112 3.90682 8.03983 4.11753 8.4149 4.49261C8.78997 4.86768 9.00069 5.37639 9.00069 5.90682C9.00069 6.43725 8.78997 6.94596 8.4149 7.32103C8.03983 7.69611 7.53112 7.90682 7.00069 7.90682C6.82388 7.90682 6.65431 7.83658 6.52928 7.71156C6.40426 7.58653 6.33402 7.41696 6.33402 7.24015C6.33402 7.06334 6.40426 6.89377 6.52928 6.76875C6.65431 6.64373 6.82388 6.57349 7.00069 6.57349C7.1176 6.57302 7.23233 6.54182 7.33338 6.48301C7.43442 6.4242 7.51822 6.33986 7.57638 6.23844C7.63453 6.13701 7.66499 6.02208 7.6647 5.90517C7.66441 5.78826 7.63338 5.67348 7.57472 5.57235C7.51607 5.47121 7.43184 5.38729 7.33051 5.32898C7.22917 5.27068 7.11429 5.24004 6.99738 5.24016C6.88046 5.24027 6.76564 5.27113 6.66442 5.32964C6.5632 5.38814 6.47914 5.47224 6.42069 5.57349C6.32844 5.7186 6.1837 5.82245 6.01668 5.86336C5.84967 5.90427 5.67331 5.87907 5.52445 5.79302C5.37558 5.70697 5.26572 5.56672 5.21781 5.40158C5.1699 5.23644 5.18765 5.05918 5.26735 4.90682C5.443 4.60259 5.69568 4.35 5.99996 4.17445Z"
      fill={color}
    />
  </svg>
);

export default QuestionInCircle;
