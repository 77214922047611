import React from 'react';

export default ({ color = '#ffffff', size = 20 }): JSX.Element => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6663 7.44984C16.6577 7.37328 16.6409 7.29786 16.6163 7.22484V7.14984C16.5763 7.06415 16.5228 6.98539 16.458 6.9165L11.458 1.9165C11.3891 1.85168 11.3104 1.79824 11.2247 1.75817H11.1497C11.065 1.70962 10.9715 1.67846 10.8747 1.6665H5.83301C5.16997 1.6665 4.53408 1.9299 4.06524 2.39874C3.5964 2.86758 3.33301 3.50346 3.33301 4.1665V15.8332C3.33301 16.4962 3.5964 17.1321 4.06524 17.6009C4.53408 18.0698 5.16997 18.3332 5.83301 18.3332H14.1663C14.8294 18.3332 15.4653 18.0698 15.9341 17.6009C16.4029 17.1321 16.6663 16.4962 16.6663 15.8332V7.49984C16.6663 7.49984 16.6663 7.49984 16.6663 7.44984ZM11.6663 4.50817L13.8247 6.6665H12.4997C12.2787 6.6665 12.0667 6.57871 11.9104 6.42243C11.7541 6.26615 11.6663 6.05418 11.6663 5.83317V4.50817ZM14.9997 15.8332C14.9997 16.0542 14.9119 16.2661 14.7556 16.4224C14.5993 16.5787 14.3874 16.6665 14.1663 16.6665H5.83301C5.61199 16.6665 5.40003 16.5787 5.24375 16.4224C5.08747 16.2661 4.99967 16.0542 4.99967 15.8332V4.1665C4.99967 3.94549 5.08747 3.73353 5.24375 3.57725C5.40003 3.42097 5.61199 3.33317 5.83301 3.33317H9.99967V5.83317C9.99967 6.49621 10.2631 7.1321 10.7319 7.60094C11.2007 8.06978 11.8366 8.33317 12.4997 8.33317H14.9997V15.8332Z"
      fill={color}
    />
  </svg>
);
