import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BillingSubscriptionCycle } from 'respona_api/generated/billing_pb';

import { DispatchType } from 'src/store';
import { SubscriptionPresetResponseType } from '@ts/billing.types';

import { calcContactsPriceApi, getSubscriptionPresetApi } from '@api/billing.api';

import { checkIsAnyNotLoaded } from '@utils/loadingChecks';

import { billingDetailsSelector } from '@redux/selectors/billings.selectors';
import { fetchBillingDetails, upgradeContactsNumberRequest } from '@redux/thunks/billings.thunks';

import Loader from '@uikit/Loader/Loader';
import PoorNumberInput from '@uikit/PoorNumberInput/PoorNumberInput';
import RightInfoSidebar from '@uikit/RightInfoSidebar/RightInfoSidebar';

import debounce from '@utils/debounce';

import UpsellSidebarFooter from '../_components/UpsellSidebarFooter/UpsellSidebarFooter';

import './UpsellContactsSidebar.scss';
import { userProfileSelector } from '@redux/selectors/userProfile.selector';

function UpsellContactsSidebar({
  title,
  onClose,
  onActionAfterPay,
}: {
  title?: string;
  onClose: () => void;
  onActionAfterPay?: () => void;
}): JSX.Element {
  const dispatch = useDispatch<DispatchType>();

  const isYearly = useRef<boolean | null>(null);
  const numberInputRef = useRef<HTMLInputElement>(null);
  const { info: profileFromRedux } = useSelector(userProfileSelector);

  const [presetState, changePresetState] = useState<SubscriptionPresetResponseType | null>(null);
  const [totalPrice, changeTotalPrice] = useState<number>(0);
  const [contactsNumber, setContactsNumber] = useState<number>(10000);

  const [isTotalLoading, changeIsTotalLoading] = useState<boolean>(true);
  const [isPayButtonLoading, changeIsPayButtonLoading] = useState<boolean>(false);

  const { data: billingDetails, loadingStatus: billingDetailsLs } =
    useSelector(billingDetailsSelector);

  useEffect(() => {
    dispatch(fetchBillingDetails(true));
  }, []);

  const handlePay = () => {
    if (contactsNumber) {
      changeIsPayButtonLoading(true);

      dispatch(
        upgradeContactsNumberRequest(
          contactsNumber,
          presetState.currentType,
          presetState.cycle,
          profileFromRedux.email
        )
      )
        .then(() => {
          onClose();
          if (onActionAfterPay) {
            setTimeout(() => {
              onActionAfterPay();
            }, 200);
          }
        })
        .finally(() => {
          changeIsPayButtonLoading(false);
        });
    } else {
      numberInputRef.current.focus();
    }
  };

  const debouncedFetchTotalPrice = useCallback(
    debounce((amount, type, cycle): Promise<void> => {
      return calcContactsPriceApi(amount, type, cycle)
        .then((response) => {
          changeTotalPrice(response.value);
        })
        .finally(() => changeIsTotalLoading(false));
    }, 1000),
    []
  );

  useEffect(() => {
    getSubscriptionPresetApi().then((preset: SubscriptionPresetResponseType) => {
      isYearly.current = preset.cycle === BillingSubscriptionCycle.YEARLY;
      changePresetState(preset);
    });
  }, []);

  useEffect(() => {
    changeIsTotalLoading(true);

    if (presetState && contactsNumber) {
      debouncedFetchTotalPrice(contactsNumber, presetState.currentType, presetState.cycle);
    }
  }, [presetState, billingDetailsLs, contactsNumber]);

  return (
    <RightInfoSidebar isOpen onClose={onClose} title={title || 'Increase active contacts'}>
      <div className="upsell-contacts-sidebar">
        <div className="upsell-contacts-sidebar__body">
          <div className="upsell-contacts-sidebar__buy-email-block">
            <Loader withTopMargin isLoading={checkIsAnyNotLoaded(billingDetailsLs)}>
              <div style={{ marginBottom: '10px' }}>Increase active contacts limit</div>
              <PoorNumberInput
                value={contactsNumber}
                onChange={setContactsNumber}
                min={10000}
                max={10000000}
                step={10000}
              />
              <div className="upsell-email-sidebar__price">
                ${isYearly.current ? '96/year' : '10/mo'} per 10K extra contacts
              </div>
            </Loader>
          </div>
        </div>
        <div className="upsell-contacts-sidebar__footer">
          <UpsellSidebarFooter
            priceDescription="Due today:"
            onPay={handlePay}
            onCancel={onClose}
            totalPrice={totalPrice}
            isLoadingPrice={isTotalLoading}
            isPayButtonLoading={isPayButtonLoading}
          />
        </div>
      </div>
    </RightInfoSidebar>
  );
}

export default UpsellContactsSidebar;
