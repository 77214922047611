import { DateOfLastEpisode } from 'respona_api/generated/common_pb';

import { DateRangeNumberType, SelectOptionType } from '@ts/common.types';

import { dateRangeNumberFormatToString } from '@helpers/dateTimeRangeHelpers';
import { transformCamelCase } from '@helpers/textHelpers';

export const getListenNotesDateOptions = (
  customDate?: DateRangeNumberType
): SelectOptionType<number>[] => {
  const dateOfLastEpisodeOptions = Object.entries(DateOfLastEpisode)
    .filter(([_, item]) => item !== DateOfLastEpisode.CUSTOM)
    .map(([key, value]) => ({
      label: transformCamelCase(key.replace(/_/g, ' ')),
      value,
    }));

  const customDateString = dateRangeNumberFormatToString(customDate);

  return [
    ...dateOfLastEpisodeOptions,
    {
      label: customDateString || 'Custom',
      value: DateOfLastEpisode.CUSTOM,
    },
  ];
};

export const listenNotesSortByOptions = [
  {
    label: 'Relevance',
    value: 0,
  },
  {
    label: 'Date',
    value: 1,
  },
];
