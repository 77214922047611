import createAction from '@redux/utils/createAction';

import { UserProfileType } from '@ts/user.types';

export const SET_USER_PROFILE_DATA = 'SET_USER_PROFILE_DATA';
export const SET_USER_PROFILE_DATA_LS = 'SET_USER_PROFILE_DATA_LS';
export const SET_USER_AUTH_STEP = 'SET_USER_AUTH_STEP';
export const SET_SHOW_CHECKLIST_STATUS = 'SET_SHOW_CHECKLIST_STATUS';

export const setUserProfileData = createAction<UserProfileType>(SET_USER_PROFILE_DATA);
export const setUserProfileLs = createAction<string>(SET_USER_PROFILE_DATA_LS);

export const setUserAuthStep = createAction<number>(SET_USER_AUTH_STEP);
export const setShowCheckListStatus = createAction<boolean>(SET_SHOW_CHECKLIST_STATUS);
