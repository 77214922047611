// source: mailbox-account.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
goog.exportSymbol('proto.mailbox.AccountToWorkspace', null, global);
goog.exportSymbol('proto.mailbox.ConnectionSettings', null, global);
goog.exportSymbol('proto.mailbox.EncryptionMethod', null, global);
goog.exportSymbol('proto.mailbox.GMailSettings', null, global);
goog.exportSymbol('proto.mailbox.MailboxAccountResponse', null, global);
goog.exportSymbol('proto.mailbox.MailboxAccountSettingsResponse', null, global);
goog.exportSymbol('proto.mailbox.MailboxAccountSettingsResponse.SettingsCase', null, global);
goog.exportSymbol('proto.mailbox.MailboxAccountSignatureResponse', null, global);
goog.exportSymbol('proto.mailbox.MailboxAccountStatisticsResponse', null, global);
goog.exportSymbol('proto.mailbox.MailboxAccountStatus', null, global);
goog.exportSymbol('proto.mailbox.MailboxAccountSyncType', null, global);
goog.exportSymbol('proto.mailbox.MailboxAccountType', null, global);
goog.exportSymbol('proto.mailbox.MailboxAccountUpdateRequest', null, global);
goog.exportSymbol('proto.mailbox.MailboxAccountWarmupState', null, global);
goog.exportSymbol('proto.mailbox.MailboxAccountWorkspaces', null, global);
goog.exportSymbol('proto.mailbox.MailboxAuthenticationRequest', null, global);
goog.exportSymbol('proto.mailbox.MailboxAuthenticationRequest.SettingsCase', null, global);
goog.exportSymbol('proto.mailbox.MailboxHealthcheckState', null, global);
goog.exportSymbol('proto.mailbox.MicrosoftSettings', null, global);
goog.exportSymbol('proto.mailbox.ReferralContentState', null, global);
goog.exportSymbol('proto.mailbox.SMTPSettings', null, global);
goog.exportSymbol('proto.mailbox.TestConnectionRequest', null, global);
goog.exportSymbol('proto.mailbox.TestConnectionResponse', null, global);
goog.exportSymbol('proto.mailbox.TestConnectionResponseStreamContainer', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mailbox.MailboxAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mailbox.MailboxAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mailbox.MailboxAccountResponse.displayName = 'proto.mailbox.MailboxAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mailbox.MailboxAccountSignatureResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mailbox.MailboxAccountSignatureResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mailbox.MailboxAccountSignatureResponse.displayName = 'proto.mailbox.MailboxAccountSignatureResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mailbox.MailboxAccountStatisticsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mailbox.MailboxAccountStatisticsResponse.repeatedFields_, null);
};
goog.inherits(proto.mailbox.MailboxAccountStatisticsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mailbox.MailboxAccountStatisticsResponse.displayName = 'proto.mailbox.MailboxAccountStatisticsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mailbox.MailboxAccountSettingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mailbox.MailboxAccountSettingsResponse.repeatedFields_, proto.mailbox.MailboxAccountSettingsResponse.oneofGroups_);
};
goog.inherits(proto.mailbox.MailboxAccountSettingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mailbox.MailboxAccountSettingsResponse.displayName = 'proto.mailbox.MailboxAccountSettingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mailbox.MailboxAccountUpdateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mailbox.MailboxAccountUpdateRequest.repeatedFields_, null);
};
goog.inherits(proto.mailbox.MailboxAccountUpdateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mailbox.MailboxAccountUpdateRequest.displayName = 'proto.mailbox.MailboxAccountUpdateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mailbox.MailboxAuthenticationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.mailbox.MailboxAuthenticationRequest.oneofGroups_);
};
goog.inherits(proto.mailbox.MailboxAuthenticationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mailbox.MailboxAuthenticationRequest.displayName = 'proto.mailbox.MailboxAuthenticationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mailbox.GMailSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mailbox.GMailSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mailbox.GMailSettings.displayName = 'proto.mailbox.GMailSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mailbox.MicrosoftSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mailbox.MicrosoftSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mailbox.MicrosoftSettings.displayName = 'proto.mailbox.MicrosoftSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mailbox.SMTPSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mailbox.SMTPSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mailbox.SMTPSettings.displayName = 'proto.mailbox.SMTPSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mailbox.AccountToWorkspace = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mailbox.AccountToWorkspace.repeatedFields_, null);
};
goog.inherits(proto.mailbox.AccountToWorkspace, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mailbox.AccountToWorkspace.displayName = 'proto.mailbox.AccountToWorkspace';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mailbox.MailboxAccountWorkspaces = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mailbox.MailboxAccountWorkspaces, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mailbox.MailboxAccountWorkspaces.displayName = 'proto.mailbox.MailboxAccountWorkspaces';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mailbox.TestConnectionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mailbox.TestConnectionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mailbox.TestConnectionRequest.displayName = 'proto.mailbox.TestConnectionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mailbox.TestConnectionResponseStreamContainer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mailbox.TestConnectionResponseStreamContainer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mailbox.TestConnectionResponseStreamContainer.displayName = 'proto.mailbox.TestConnectionResponseStreamContainer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mailbox.TestConnectionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mailbox.TestConnectionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mailbox.TestConnectionResponse.displayName = 'proto.mailbox.TestConnectionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mailbox.ConnectionSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mailbox.ConnectionSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mailbox.ConnectionSettings.displayName = 'proto.mailbox.ConnectionSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mailbox.ReferralContentState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mailbox.ReferralContentState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mailbox.ReferralContentState.displayName = 'proto.mailbox.ReferralContentState';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mailbox.MailboxAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mailbox.MailboxAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mailbox.MailboxAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mailbox.MailboxAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    senderName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0),
    type: jspb.Message.getFieldWithDefault(msg, 5, 0),
    dailySentLimit: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mailbox.MailboxAccountResponse}
 */
proto.mailbox.MailboxAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mailbox.MailboxAccountResponse;
  return proto.mailbox.MailboxAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mailbox.MailboxAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mailbox.MailboxAccountResponse}
 */
proto.mailbox.MailboxAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSenderName(value);
      break;
    case 4:
      var value = /** @type {!proto.mailbox.MailboxAccountStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {!proto.mailbox.MailboxAccountType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDailySentLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mailbox.MailboxAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mailbox.MailboxAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mailbox.MailboxAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mailbox.MailboxAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSenderName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getDailySentLimit();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.mailbox.MailboxAccountResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mailbox.MailboxAccountResponse} returns this
 */
proto.mailbox.MailboxAccountResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.mailbox.MailboxAccountResponse.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox.MailboxAccountResponse} returns this
 */
proto.mailbox.MailboxAccountResponse.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string sender_name = 3;
 * @return {string}
 */
proto.mailbox.MailboxAccountResponse.prototype.getSenderName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox.MailboxAccountResponse} returns this
 */
proto.mailbox.MailboxAccountResponse.prototype.setSenderName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional MailboxAccountStatus status = 4;
 * @return {!proto.mailbox.MailboxAccountStatus}
 */
proto.mailbox.MailboxAccountResponse.prototype.getStatus = function() {
  return /** @type {!proto.mailbox.MailboxAccountStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.mailbox.MailboxAccountStatus} value
 * @return {!proto.mailbox.MailboxAccountResponse} returns this
 */
proto.mailbox.MailboxAccountResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional MailboxAccountType type = 5;
 * @return {!proto.mailbox.MailboxAccountType}
 */
proto.mailbox.MailboxAccountResponse.prototype.getType = function() {
  return /** @type {!proto.mailbox.MailboxAccountType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.mailbox.MailboxAccountType} value
 * @return {!proto.mailbox.MailboxAccountResponse} returns this
 */
proto.mailbox.MailboxAccountResponse.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional int32 daily_sent_limit = 6;
 * @return {number}
 */
proto.mailbox.MailboxAccountResponse.prototype.getDailySentLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.mailbox.MailboxAccountResponse} returns this
 */
proto.mailbox.MailboxAccountResponse.prototype.setDailySentLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mailbox.MailboxAccountSignatureResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mailbox.MailboxAccountSignatureResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mailbox.MailboxAccountSignatureResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mailbox.MailboxAccountSignatureResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    senderName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0),
    referralContentEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    signature: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mailbox.MailboxAccountSignatureResponse}
 */
proto.mailbox.MailboxAccountSignatureResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mailbox.MailboxAccountSignatureResponse;
  return proto.mailbox.MailboxAccountSignatureResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mailbox.MailboxAccountSignatureResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mailbox.MailboxAccountSignatureResponse}
 */
proto.mailbox.MailboxAccountSignatureResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSenderName(value);
      break;
    case 4:
      var value = /** @type {!proto.mailbox.MailboxAccountStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReferralContentEnabled(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignature(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mailbox.MailboxAccountSignatureResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mailbox.MailboxAccountSignatureResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mailbox.MailboxAccountSignatureResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mailbox.MailboxAccountSignatureResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSenderName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getReferralContentEnabled();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getSignature();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.mailbox.MailboxAccountSignatureResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mailbox.MailboxAccountSignatureResponse} returns this
 */
proto.mailbox.MailboxAccountSignatureResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.mailbox.MailboxAccountSignatureResponse.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox.MailboxAccountSignatureResponse} returns this
 */
proto.mailbox.MailboxAccountSignatureResponse.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string sender_name = 3;
 * @return {string}
 */
proto.mailbox.MailboxAccountSignatureResponse.prototype.getSenderName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox.MailboxAccountSignatureResponse} returns this
 */
proto.mailbox.MailboxAccountSignatureResponse.prototype.setSenderName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional MailboxAccountStatus status = 4;
 * @return {!proto.mailbox.MailboxAccountStatus}
 */
proto.mailbox.MailboxAccountSignatureResponse.prototype.getStatus = function() {
  return /** @type {!proto.mailbox.MailboxAccountStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.mailbox.MailboxAccountStatus} value
 * @return {!proto.mailbox.MailboxAccountSignatureResponse} returns this
 */
proto.mailbox.MailboxAccountSignatureResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional bool referral_content_enabled = 5;
 * @return {boolean}
 */
proto.mailbox.MailboxAccountSignatureResponse.prototype.getReferralContentEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mailbox.MailboxAccountSignatureResponse} returns this
 */
proto.mailbox.MailboxAccountSignatureResponse.prototype.setReferralContentEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string signature = 6;
 * @return {string}
 */
proto.mailbox.MailboxAccountSignatureResponse.prototype.getSignature = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox.MailboxAccountSignatureResponse} returns this
 */
proto.mailbox.MailboxAccountSignatureResponse.prototype.setSignature = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mailbox.MailboxAccountStatisticsResponse.repeatedFields_ = [11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mailbox.MailboxAccountStatisticsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mailbox.MailboxAccountStatisticsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mailbox.MailboxAccountStatisticsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mailbox.MailboxAccountStatisticsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    senderName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0),
    status: jspb.Message.getFieldWithDefault(msg, 5, 0),
    dailySentToday: jspb.Message.getFieldWithDefault(msg, 6, 0),
    dailySentLimit: jspb.Message.getFieldWithDefault(msg, 7, 0),
    openRate: jspb.Message.getFieldWithDefault(msg, 8, 0),
    replyRate: jspb.Message.getFieldWithDefault(msg, 9, 0),
    healthScoreRate: jspb.Message.getFieldWithDefault(msg, 10, 0),
    workspaceIdsList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mailbox.MailboxAccountStatisticsResponse}
 */
proto.mailbox.MailboxAccountStatisticsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mailbox.MailboxAccountStatisticsResponse;
  return proto.mailbox.MailboxAccountStatisticsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mailbox.MailboxAccountStatisticsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mailbox.MailboxAccountStatisticsResponse}
 */
proto.mailbox.MailboxAccountStatisticsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSenderName(value);
      break;
    case 4:
      var value = /** @type {!proto.mailbox.MailboxAccountType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {!proto.mailbox.MailboxAccountStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDailySentToday(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDailySentLimit(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOpenRate(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReplyRate(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHealthScoreRate(value);
      break;
    case 11:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setWorkspaceIdsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mailbox.MailboxAccountStatisticsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mailbox.MailboxAccountStatisticsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mailbox.MailboxAccountStatisticsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mailbox.MailboxAccountStatisticsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSenderName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getDailySentToday();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getDailySentLimit();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getOpenRate();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getReplyRate();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getHealthScoreRate();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getWorkspaceIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      11,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.mailbox.MailboxAccountStatisticsResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mailbox.MailboxAccountStatisticsResponse} returns this
 */
proto.mailbox.MailboxAccountStatisticsResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.mailbox.MailboxAccountStatisticsResponse.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox.MailboxAccountStatisticsResponse} returns this
 */
proto.mailbox.MailboxAccountStatisticsResponse.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string sender_name = 3;
 * @return {string}
 */
proto.mailbox.MailboxAccountStatisticsResponse.prototype.getSenderName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox.MailboxAccountStatisticsResponse} returns this
 */
proto.mailbox.MailboxAccountStatisticsResponse.prototype.setSenderName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional MailboxAccountType type = 4;
 * @return {!proto.mailbox.MailboxAccountType}
 */
proto.mailbox.MailboxAccountStatisticsResponse.prototype.getType = function() {
  return /** @type {!proto.mailbox.MailboxAccountType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.mailbox.MailboxAccountType} value
 * @return {!proto.mailbox.MailboxAccountStatisticsResponse} returns this
 */
proto.mailbox.MailboxAccountStatisticsResponse.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional MailboxAccountStatus status = 5;
 * @return {!proto.mailbox.MailboxAccountStatus}
 */
proto.mailbox.MailboxAccountStatisticsResponse.prototype.getStatus = function() {
  return /** @type {!proto.mailbox.MailboxAccountStatus} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.mailbox.MailboxAccountStatus} value
 * @return {!proto.mailbox.MailboxAccountStatisticsResponse} returns this
 */
proto.mailbox.MailboxAccountStatisticsResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional int32 daily_sent_today = 6;
 * @return {number}
 */
proto.mailbox.MailboxAccountStatisticsResponse.prototype.getDailySentToday = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.mailbox.MailboxAccountStatisticsResponse} returns this
 */
proto.mailbox.MailboxAccountStatisticsResponse.prototype.setDailySentToday = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 daily_sent_limit = 7;
 * @return {number}
 */
proto.mailbox.MailboxAccountStatisticsResponse.prototype.getDailySentLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.mailbox.MailboxAccountStatisticsResponse} returns this
 */
proto.mailbox.MailboxAccountStatisticsResponse.prototype.setDailySentLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 open_rate = 8;
 * @return {number}
 */
proto.mailbox.MailboxAccountStatisticsResponse.prototype.getOpenRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.mailbox.MailboxAccountStatisticsResponse} returns this
 */
proto.mailbox.MailboxAccountStatisticsResponse.prototype.setOpenRate = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 reply_rate = 9;
 * @return {number}
 */
proto.mailbox.MailboxAccountStatisticsResponse.prototype.getReplyRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.mailbox.MailboxAccountStatisticsResponse} returns this
 */
proto.mailbox.MailboxAccountStatisticsResponse.prototype.setReplyRate = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 health_score_rate = 10;
 * @return {number}
 */
proto.mailbox.MailboxAccountStatisticsResponse.prototype.getHealthScoreRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.mailbox.MailboxAccountStatisticsResponse} returns this
 */
proto.mailbox.MailboxAccountStatisticsResponse.prototype.setHealthScoreRate = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * repeated int64 workspace_ids = 11;
 * @return {!Array<number>}
 */
proto.mailbox.MailboxAccountStatisticsResponse.prototype.getWorkspaceIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.mailbox.MailboxAccountStatisticsResponse} returns this
 */
proto.mailbox.MailboxAccountStatisticsResponse.prototype.setWorkspaceIdsList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.mailbox.MailboxAccountStatisticsResponse} returns this
 */
proto.mailbox.MailboxAccountStatisticsResponse.prototype.addWorkspaceIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mailbox.MailboxAccountStatisticsResponse} returns this
 */
proto.mailbox.MailboxAccountStatisticsResponse.prototype.clearWorkspaceIdsList = function() {
  return this.setWorkspaceIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mailbox.MailboxAccountSettingsResponse.repeatedFields_ = [23];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.mailbox.MailboxAccountSettingsResponse.oneofGroups_ = [[6,7,8]];

/**
 * @enum {number}
 */
proto.mailbox.MailboxAccountSettingsResponse.SettingsCase = {
  SETTINGS_NOT_SET: 0,
  GMAIL: 6,
  SMTP: 7,
  MICROSOFT: 8
};

/**
 * @return {proto.mailbox.MailboxAccountSettingsResponse.SettingsCase}
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.getSettingsCase = function() {
  return /** @type {proto.mailbox.MailboxAccountSettingsResponse.SettingsCase} */(jspb.Message.computeOneofCase(this, proto.mailbox.MailboxAccountSettingsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mailbox.MailboxAccountSettingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mailbox.MailboxAccountSettingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mailbox.MailboxAccountSettingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    senderName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0),
    status: jspb.Message.getFieldWithDefault(msg, 5, 0),
    gmail: (f = msg.getGmail()) && proto.mailbox.GMailSettings.toObject(includeInstance, f),
    smtp: (f = msg.getSmtp()) && proto.mailbox.SMTPSettings.toObject(includeInstance, f),
    microsoft: (f = msg.getMicrosoft()) && proto.mailbox.MicrosoftSettings.toObject(includeInstance, f),
    warmUpState: jspb.Message.getFieldWithDefault(msg, 9, 0),
    syncType: jspb.Message.getFieldWithDefault(msg, 10, 0),
    healthcheckState: jspb.Message.getFieldWithDefault(msg, 11, 0),
    healthCheckLastAt: jspb.Message.getFieldWithDefault(msg, 12, 0),
    delayBetweenEmailsFrom: jspb.Message.getFieldWithDefault(msg, 13, 0),
    delayBetweenEmailsTo: jspb.Message.getFieldWithDefault(msg, 14, 0),
    signature: jspb.Message.getFieldWithDefault(msg, 15, ""),
    isGmailSignature: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    dailySentLimit: jspb.Message.getFieldWithDefault(msg, 17, 0),
    maxDailySentLimit: jspb.Message.getFieldWithDefault(msg, 18, 0),
    customTrackingDomain: jspb.Message.getFieldWithDefault(msg, 19, ""),
    connectedBy: jspb.Message.getFieldWithDefault(msg, 20, ""),
    connectedAt: jspb.Message.getFieldWithDefault(msg, 21, 0),
    referralContentEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
    workspaceIdsList: (f = jspb.Message.getRepeatedField(msg, 23)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mailbox.MailboxAccountSettingsResponse}
 */
proto.mailbox.MailboxAccountSettingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mailbox.MailboxAccountSettingsResponse;
  return proto.mailbox.MailboxAccountSettingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mailbox.MailboxAccountSettingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mailbox.MailboxAccountSettingsResponse}
 */
proto.mailbox.MailboxAccountSettingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSenderName(value);
      break;
    case 4:
      var value = /** @type {!proto.mailbox.MailboxAccountType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {!proto.mailbox.MailboxAccountStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 6:
      var value = new proto.mailbox.GMailSettings;
      reader.readMessage(value,proto.mailbox.GMailSettings.deserializeBinaryFromReader);
      msg.setGmail(value);
      break;
    case 7:
      var value = new proto.mailbox.SMTPSettings;
      reader.readMessage(value,proto.mailbox.SMTPSettings.deserializeBinaryFromReader);
      msg.setSmtp(value);
      break;
    case 8:
      var value = new proto.mailbox.MicrosoftSettings;
      reader.readMessage(value,proto.mailbox.MicrosoftSettings.deserializeBinaryFromReader);
      msg.setMicrosoft(value);
      break;
    case 9:
      var value = /** @type {!proto.mailbox.MailboxAccountWarmupState} */ (reader.readEnum());
      msg.setWarmUpState(value);
      break;
    case 10:
      var value = /** @type {!proto.mailbox.MailboxAccountSyncType} */ (reader.readEnum());
      msg.setSyncType(value);
      break;
    case 11:
      var value = /** @type {!proto.mailbox.MailboxHealthcheckState} */ (reader.readEnum());
      msg.setHealthcheckState(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHealthCheckLastAt(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDelayBetweenEmailsFrom(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDelayBetweenEmailsTo(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignature(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsGmailSignature(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDailySentLimit(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxDailySentLimit(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomTrackingDomain(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setConnectedBy(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setConnectedAt(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReferralContentEnabled(value);
      break;
    case 23:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setWorkspaceIdsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mailbox.MailboxAccountSettingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mailbox.MailboxAccountSettingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mailbox.MailboxAccountSettingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSenderName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getGmail();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.mailbox.GMailSettings.serializeBinaryToWriter
    );
  }
  f = message.getSmtp();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.mailbox.SMTPSettings.serializeBinaryToWriter
    );
  }
  f = message.getMicrosoft();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.mailbox.MicrosoftSettings.serializeBinaryToWriter
    );
  }
  f = message.getWarmUpState();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getSyncType();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getHealthcheckState();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getHealthCheckLastAt();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getDelayBetweenEmailsFrom();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getDelayBetweenEmailsTo();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getSignature();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getIsGmailSignature();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getDailySentLimit();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getMaxDailySentLimit();
  if (f !== 0) {
    writer.writeInt32(
      18,
      f
    );
  }
  f = message.getCustomTrackingDomain();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getConnectedBy();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getConnectedAt();
  if (f !== 0) {
    writer.writeInt64(
      21,
      f
    );
  }
  f = message.getReferralContentEnabled();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
  f = message.getWorkspaceIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      23,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mailbox.MailboxAccountSettingsResponse} returns this
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox.MailboxAccountSettingsResponse} returns this
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string sender_name = 3;
 * @return {string}
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.getSenderName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox.MailboxAccountSettingsResponse} returns this
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.setSenderName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional MailboxAccountType type = 4;
 * @return {!proto.mailbox.MailboxAccountType}
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.getType = function() {
  return /** @type {!proto.mailbox.MailboxAccountType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.mailbox.MailboxAccountType} value
 * @return {!proto.mailbox.MailboxAccountSettingsResponse} returns this
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional MailboxAccountStatus status = 5;
 * @return {!proto.mailbox.MailboxAccountStatus}
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.getStatus = function() {
  return /** @type {!proto.mailbox.MailboxAccountStatus} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.mailbox.MailboxAccountStatus} value
 * @return {!proto.mailbox.MailboxAccountSettingsResponse} returns this
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional GMailSettings gmail = 6;
 * @return {?proto.mailbox.GMailSettings}
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.getGmail = function() {
  return /** @type{?proto.mailbox.GMailSettings} */ (
    jspb.Message.getWrapperField(this, proto.mailbox.GMailSettings, 6));
};


/**
 * @param {?proto.mailbox.GMailSettings|undefined} value
 * @return {!proto.mailbox.MailboxAccountSettingsResponse} returns this
*/
proto.mailbox.MailboxAccountSettingsResponse.prototype.setGmail = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.mailbox.MailboxAccountSettingsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mailbox.MailboxAccountSettingsResponse} returns this
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.clearGmail = function() {
  return this.setGmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.hasGmail = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional SMTPSettings smtp = 7;
 * @return {?proto.mailbox.SMTPSettings}
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.getSmtp = function() {
  return /** @type{?proto.mailbox.SMTPSettings} */ (
    jspb.Message.getWrapperField(this, proto.mailbox.SMTPSettings, 7));
};


/**
 * @param {?proto.mailbox.SMTPSettings|undefined} value
 * @return {!proto.mailbox.MailboxAccountSettingsResponse} returns this
*/
proto.mailbox.MailboxAccountSettingsResponse.prototype.setSmtp = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.mailbox.MailboxAccountSettingsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mailbox.MailboxAccountSettingsResponse} returns this
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.clearSmtp = function() {
  return this.setSmtp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.hasSmtp = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional MicrosoftSettings microsoft = 8;
 * @return {?proto.mailbox.MicrosoftSettings}
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.getMicrosoft = function() {
  return /** @type{?proto.mailbox.MicrosoftSettings} */ (
    jspb.Message.getWrapperField(this, proto.mailbox.MicrosoftSettings, 8));
};


/**
 * @param {?proto.mailbox.MicrosoftSettings|undefined} value
 * @return {!proto.mailbox.MailboxAccountSettingsResponse} returns this
*/
proto.mailbox.MailboxAccountSettingsResponse.prototype.setMicrosoft = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.mailbox.MailboxAccountSettingsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mailbox.MailboxAccountSettingsResponse} returns this
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.clearMicrosoft = function() {
  return this.setMicrosoft(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.hasMicrosoft = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional MailboxAccountWarmupState warm_up_state = 9;
 * @return {!proto.mailbox.MailboxAccountWarmupState}
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.getWarmUpState = function() {
  return /** @type {!proto.mailbox.MailboxAccountWarmupState} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.mailbox.MailboxAccountWarmupState} value
 * @return {!proto.mailbox.MailboxAccountSettingsResponse} returns this
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.setWarmUpState = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional MailboxAccountSyncType sync_type = 10;
 * @return {!proto.mailbox.MailboxAccountSyncType}
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.getSyncType = function() {
  return /** @type {!proto.mailbox.MailboxAccountSyncType} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.mailbox.MailboxAccountSyncType} value
 * @return {!proto.mailbox.MailboxAccountSettingsResponse} returns this
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.setSyncType = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional MailboxHealthcheckState healthcheck_state = 11;
 * @return {!proto.mailbox.MailboxHealthcheckState}
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.getHealthcheckState = function() {
  return /** @type {!proto.mailbox.MailboxHealthcheckState} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.mailbox.MailboxHealthcheckState} value
 * @return {!proto.mailbox.MailboxAccountSettingsResponse} returns this
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.setHealthcheckState = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional int64 health_check_last_at = 12;
 * @return {number}
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.getHealthCheckLastAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.mailbox.MailboxAccountSettingsResponse} returns this
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.setHealthCheckLastAt = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int32 delay_between_emails_from = 13;
 * @return {number}
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.getDelayBetweenEmailsFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.mailbox.MailboxAccountSettingsResponse} returns this
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.setDelayBetweenEmailsFrom = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int32 delay_between_emails_to = 14;
 * @return {number}
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.getDelayBetweenEmailsTo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.mailbox.MailboxAccountSettingsResponse} returns this
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.setDelayBetweenEmailsTo = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional string signature = 15;
 * @return {string}
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.getSignature = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox.MailboxAccountSettingsResponse} returns this
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.setSignature = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional bool is_gmail_signature = 16;
 * @return {boolean}
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.getIsGmailSignature = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mailbox.MailboxAccountSettingsResponse} returns this
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.setIsGmailSignature = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional int32 daily_sent_limit = 17;
 * @return {number}
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.getDailySentLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.mailbox.MailboxAccountSettingsResponse} returns this
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.setDailySentLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional int32 max_daily_sent_limit = 18;
 * @return {number}
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.getMaxDailySentLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.mailbox.MailboxAccountSettingsResponse} returns this
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.setMaxDailySentLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional string custom_tracking_domain = 19;
 * @return {string}
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.getCustomTrackingDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox.MailboxAccountSettingsResponse} returns this
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.setCustomTrackingDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string connected_by = 20;
 * @return {string}
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.getConnectedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox.MailboxAccountSettingsResponse} returns this
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.setConnectedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional int64 connected_at = 21;
 * @return {number}
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.getConnectedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.mailbox.MailboxAccountSettingsResponse} returns this
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.setConnectedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional bool referral_content_enabled = 22;
 * @return {boolean}
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.getReferralContentEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mailbox.MailboxAccountSettingsResponse} returns this
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.setReferralContentEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 22, value);
};


/**
 * repeated int64 workspace_ids = 23;
 * @return {!Array<number>}
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.getWorkspaceIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 23));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.mailbox.MailboxAccountSettingsResponse} returns this
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.setWorkspaceIdsList = function(value) {
  return jspb.Message.setField(this, 23, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.mailbox.MailboxAccountSettingsResponse} returns this
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.addWorkspaceIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 23, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mailbox.MailboxAccountSettingsResponse} returns this
 */
proto.mailbox.MailboxAccountSettingsResponse.prototype.clearWorkspaceIdsList = function() {
  return this.setWorkspaceIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mailbox.MailboxAccountUpdateRequest.repeatedFields_ = [12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mailbox.MailboxAccountUpdateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mailbox.MailboxAccountUpdateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mailbox.MailboxAccountUpdateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mailbox.MailboxAccountUpdateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    senderName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    signature: jspb.Message.getFieldWithDefault(msg, 4, ""),
    dailySentLimit: jspb.Message.getFieldWithDefault(msg, 5, 0),
    warmUpState: jspb.Message.getFieldWithDefault(msg, 6, 0),
    syncType: jspb.Message.getFieldWithDefault(msg, 7, 0),
    healthcheckState: jspb.Message.getFieldWithDefault(msg, 8, 0),
    delayBetweenEmailsFrom: jspb.Message.getFieldWithDefault(msg, 9, 0),
    delayBetweenEmailsTo: jspb.Message.getFieldWithDefault(msg, 10, 0),
    customTrackingDomain: jspb.Message.getFieldWithDefault(msg, 11, ""),
    workspaceIdsList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mailbox.MailboxAccountUpdateRequest}
 */
proto.mailbox.MailboxAccountUpdateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mailbox.MailboxAccountUpdateRequest;
  return proto.mailbox.MailboxAccountUpdateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mailbox.MailboxAccountUpdateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mailbox.MailboxAccountUpdateRequest}
 */
proto.mailbox.MailboxAccountUpdateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSenderName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignature(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDailySentLimit(value);
      break;
    case 6:
      var value = /** @type {!proto.mailbox.MailboxAccountWarmupState} */ (reader.readEnum());
      msg.setWarmUpState(value);
      break;
    case 7:
      var value = /** @type {!proto.mailbox.MailboxAccountSyncType} */ (reader.readEnum());
      msg.setSyncType(value);
      break;
    case 8:
      var value = /** @type {!proto.mailbox.MailboxHealthcheckState} */ (reader.readEnum());
      msg.setHealthcheckState(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDelayBetweenEmailsFrom(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDelayBetweenEmailsTo(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomTrackingDomain(value);
      break;
    case 12:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setWorkspaceIdsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mailbox.MailboxAccountUpdateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mailbox.MailboxAccountUpdateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mailbox.MailboxAccountUpdateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mailbox.MailboxAccountUpdateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSenderName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSignature();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDailySentLimit();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getWarmUpState();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getSyncType();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getHealthcheckState();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getDelayBetweenEmailsFrom();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getDelayBetweenEmailsTo();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getCustomTrackingDomain();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getWorkspaceIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      12,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.mailbox.MailboxAccountUpdateRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mailbox.MailboxAccountUpdateRequest} returns this
 */
proto.mailbox.MailboxAccountUpdateRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.mailbox.MailboxAccountUpdateRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox.MailboxAccountUpdateRequest} returns this
 */
proto.mailbox.MailboxAccountUpdateRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string sender_name = 3;
 * @return {string}
 */
proto.mailbox.MailboxAccountUpdateRequest.prototype.getSenderName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox.MailboxAccountUpdateRequest} returns this
 */
proto.mailbox.MailboxAccountUpdateRequest.prototype.setSenderName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string signature = 4;
 * @return {string}
 */
proto.mailbox.MailboxAccountUpdateRequest.prototype.getSignature = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox.MailboxAccountUpdateRequest} returns this
 */
proto.mailbox.MailboxAccountUpdateRequest.prototype.setSignature = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 daily_sent_limit = 5;
 * @return {number}
 */
proto.mailbox.MailboxAccountUpdateRequest.prototype.getDailySentLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.mailbox.MailboxAccountUpdateRequest} returns this
 */
proto.mailbox.MailboxAccountUpdateRequest.prototype.setDailySentLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional MailboxAccountWarmupState warm_up_state = 6;
 * @return {!proto.mailbox.MailboxAccountWarmupState}
 */
proto.mailbox.MailboxAccountUpdateRequest.prototype.getWarmUpState = function() {
  return /** @type {!proto.mailbox.MailboxAccountWarmupState} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.mailbox.MailboxAccountWarmupState} value
 * @return {!proto.mailbox.MailboxAccountUpdateRequest} returns this
 */
proto.mailbox.MailboxAccountUpdateRequest.prototype.setWarmUpState = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional MailboxAccountSyncType sync_type = 7;
 * @return {!proto.mailbox.MailboxAccountSyncType}
 */
proto.mailbox.MailboxAccountUpdateRequest.prototype.getSyncType = function() {
  return /** @type {!proto.mailbox.MailboxAccountSyncType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.mailbox.MailboxAccountSyncType} value
 * @return {!proto.mailbox.MailboxAccountUpdateRequest} returns this
 */
proto.mailbox.MailboxAccountUpdateRequest.prototype.setSyncType = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional MailboxHealthcheckState healthcheck_state = 8;
 * @return {!proto.mailbox.MailboxHealthcheckState}
 */
proto.mailbox.MailboxAccountUpdateRequest.prototype.getHealthcheckState = function() {
  return /** @type {!proto.mailbox.MailboxHealthcheckState} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.mailbox.MailboxHealthcheckState} value
 * @return {!proto.mailbox.MailboxAccountUpdateRequest} returns this
 */
proto.mailbox.MailboxAccountUpdateRequest.prototype.setHealthcheckState = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional int32 delay_between_emails_from = 9;
 * @return {number}
 */
proto.mailbox.MailboxAccountUpdateRequest.prototype.getDelayBetweenEmailsFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.mailbox.MailboxAccountUpdateRequest} returns this
 */
proto.mailbox.MailboxAccountUpdateRequest.prototype.setDelayBetweenEmailsFrom = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 delay_between_emails_to = 10;
 * @return {number}
 */
proto.mailbox.MailboxAccountUpdateRequest.prototype.getDelayBetweenEmailsTo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.mailbox.MailboxAccountUpdateRequest} returns this
 */
proto.mailbox.MailboxAccountUpdateRequest.prototype.setDelayBetweenEmailsTo = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string custom_tracking_domain = 11;
 * @return {string}
 */
proto.mailbox.MailboxAccountUpdateRequest.prototype.getCustomTrackingDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox.MailboxAccountUpdateRequest} returns this
 */
proto.mailbox.MailboxAccountUpdateRequest.prototype.setCustomTrackingDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * repeated int64 workspace_ids = 12;
 * @return {!Array<number>}
 */
proto.mailbox.MailboxAccountUpdateRequest.prototype.getWorkspaceIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.mailbox.MailboxAccountUpdateRequest} returns this
 */
proto.mailbox.MailboxAccountUpdateRequest.prototype.setWorkspaceIdsList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.mailbox.MailboxAccountUpdateRequest} returns this
 */
proto.mailbox.MailboxAccountUpdateRequest.prototype.addWorkspaceIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mailbox.MailboxAccountUpdateRequest} returns this
 */
proto.mailbox.MailboxAccountUpdateRequest.prototype.clearWorkspaceIdsList = function() {
  return this.setWorkspaceIdsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.mailbox.MailboxAuthenticationRequest.oneofGroups_ = [[5,6,7]];

/**
 * @enum {number}
 */
proto.mailbox.MailboxAuthenticationRequest.SettingsCase = {
  SETTINGS_NOT_SET: 0,
  GMAIL: 5,
  SMTP: 6,
  MICROSOFT: 7
};

/**
 * @return {proto.mailbox.MailboxAuthenticationRequest.SettingsCase}
 */
proto.mailbox.MailboxAuthenticationRequest.prototype.getSettingsCase = function() {
  return /** @type {proto.mailbox.MailboxAuthenticationRequest.SettingsCase} */(jspb.Message.computeOneofCase(this, proto.mailbox.MailboxAuthenticationRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mailbox.MailboxAuthenticationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mailbox.MailboxAuthenticationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mailbox.MailboxAuthenticationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mailbox.MailboxAuthenticationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    senderName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    gmail: (f = msg.getGmail()) && proto.mailbox.GMailSettings.toObject(includeInstance, f),
    smtp: (f = msg.getSmtp()) && proto.mailbox.SMTPSettings.toObject(includeInstance, f),
    microsoft: (f = msg.getMicrosoft()) && proto.mailbox.MicrosoftSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mailbox.MailboxAuthenticationRequest}
 */
proto.mailbox.MailboxAuthenticationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mailbox.MailboxAuthenticationRequest;
  return proto.mailbox.MailboxAuthenticationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mailbox.MailboxAuthenticationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mailbox.MailboxAuthenticationRequest}
 */
proto.mailbox.MailboxAuthenticationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.mailbox.MailboxAccountType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSenderName(value);
      break;
    case 5:
      var value = new proto.mailbox.GMailSettings;
      reader.readMessage(value,proto.mailbox.GMailSettings.deserializeBinaryFromReader);
      msg.setGmail(value);
      break;
    case 6:
      var value = new proto.mailbox.SMTPSettings;
      reader.readMessage(value,proto.mailbox.SMTPSettings.deserializeBinaryFromReader);
      msg.setSmtp(value);
      break;
    case 7:
      var value = new proto.mailbox.MicrosoftSettings;
      reader.readMessage(value,proto.mailbox.MicrosoftSettings.deserializeBinaryFromReader);
      msg.setMicrosoft(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mailbox.MailboxAuthenticationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mailbox.MailboxAuthenticationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mailbox.MailboxAuthenticationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mailbox.MailboxAuthenticationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSenderName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getGmail();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.mailbox.GMailSettings.serializeBinaryToWriter
    );
  }
  f = message.getSmtp();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.mailbox.SMTPSettings.serializeBinaryToWriter
    );
  }
  f = message.getMicrosoft();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.mailbox.MicrosoftSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.mailbox.MailboxAuthenticationRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mailbox.MailboxAuthenticationRequest} returns this
 */
proto.mailbox.MailboxAuthenticationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional MailboxAccountType type = 2;
 * @return {!proto.mailbox.MailboxAccountType}
 */
proto.mailbox.MailboxAuthenticationRequest.prototype.getType = function() {
  return /** @type {!proto.mailbox.MailboxAccountType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.mailbox.MailboxAccountType} value
 * @return {!proto.mailbox.MailboxAuthenticationRequest} returns this
 */
proto.mailbox.MailboxAuthenticationRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.mailbox.MailboxAuthenticationRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox.MailboxAuthenticationRequest} returns this
 */
proto.mailbox.MailboxAuthenticationRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string sender_name = 4;
 * @return {string}
 */
proto.mailbox.MailboxAuthenticationRequest.prototype.getSenderName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox.MailboxAuthenticationRequest} returns this
 */
proto.mailbox.MailboxAuthenticationRequest.prototype.setSenderName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional GMailSettings gmail = 5;
 * @return {?proto.mailbox.GMailSettings}
 */
proto.mailbox.MailboxAuthenticationRequest.prototype.getGmail = function() {
  return /** @type{?proto.mailbox.GMailSettings} */ (
    jspb.Message.getWrapperField(this, proto.mailbox.GMailSettings, 5));
};


/**
 * @param {?proto.mailbox.GMailSettings|undefined} value
 * @return {!proto.mailbox.MailboxAuthenticationRequest} returns this
*/
proto.mailbox.MailboxAuthenticationRequest.prototype.setGmail = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.mailbox.MailboxAuthenticationRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mailbox.MailboxAuthenticationRequest} returns this
 */
proto.mailbox.MailboxAuthenticationRequest.prototype.clearGmail = function() {
  return this.setGmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mailbox.MailboxAuthenticationRequest.prototype.hasGmail = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional SMTPSettings smtp = 6;
 * @return {?proto.mailbox.SMTPSettings}
 */
proto.mailbox.MailboxAuthenticationRequest.prototype.getSmtp = function() {
  return /** @type{?proto.mailbox.SMTPSettings} */ (
    jspb.Message.getWrapperField(this, proto.mailbox.SMTPSettings, 6));
};


/**
 * @param {?proto.mailbox.SMTPSettings|undefined} value
 * @return {!proto.mailbox.MailboxAuthenticationRequest} returns this
*/
proto.mailbox.MailboxAuthenticationRequest.prototype.setSmtp = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.mailbox.MailboxAuthenticationRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mailbox.MailboxAuthenticationRequest} returns this
 */
proto.mailbox.MailboxAuthenticationRequest.prototype.clearSmtp = function() {
  return this.setSmtp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mailbox.MailboxAuthenticationRequest.prototype.hasSmtp = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional MicrosoftSettings microsoft = 7;
 * @return {?proto.mailbox.MicrosoftSettings}
 */
proto.mailbox.MailboxAuthenticationRequest.prototype.getMicrosoft = function() {
  return /** @type{?proto.mailbox.MicrosoftSettings} */ (
    jspb.Message.getWrapperField(this, proto.mailbox.MicrosoftSettings, 7));
};


/**
 * @param {?proto.mailbox.MicrosoftSettings|undefined} value
 * @return {!proto.mailbox.MailboxAuthenticationRequest} returns this
*/
proto.mailbox.MailboxAuthenticationRequest.prototype.setMicrosoft = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.mailbox.MailboxAuthenticationRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mailbox.MailboxAuthenticationRequest} returns this
 */
proto.mailbox.MailboxAuthenticationRequest.prototype.clearMicrosoft = function() {
  return this.setMicrosoft(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mailbox.MailboxAuthenticationRequest.prototype.hasMicrosoft = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mailbox.GMailSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.mailbox.GMailSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mailbox.GMailSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mailbox.GMailSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    authCode: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mailbox.GMailSettings}
 */
proto.mailbox.GMailSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mailbox.GMailSettings;
  return proto.mailbox.GMailSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mailbox.GMailSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mailbox.GMailSettings}
 */
proto.mailbox.GMailSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mailbox.GMailSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mailbox.GMailSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mailbox.GMailSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mailbox.GMailSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string auth_code = 1;
 * @return {string}
 */
proto.mailbox.GMailSettings.prototype.getAuthCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox.GMailSettings} returns this
 */
proto.mailbox.GMailSettings.prototype.setAuthCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mailbox.MicrosoftSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.mailbox.MicrosoftSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mailbox.MicrosoftSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mailbox.MicrosoftSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    authCode: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mailbox.MicrosoftSettings}
 */
proto.mailbox.MicrosoftSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mailbox.MicrosoftSettings;
  return proto.mailbox.MicrosoftSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mailbox.MicrosoftSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mailbox.MicrosoftSettings}
 */
proto.mailbox.MicrosoftSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mailbox.MicrosoftSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mailbox.MicrosoftSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mailbox.MicrosoftSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mailbox.MicrosoftSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string auth_code = 1;
 * @return {string}
 */
proto.mailbox.MicrosoftSettings.prototype.getAuthCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox.MicrosoftSettings} returns this
 */
proto.mailbox.MicrosoftSettings.prototype.setAuthCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mailbox.SMTPSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.mailbox.SMTPSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mailbox.SMTPSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mailbox.SMTPSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    imapEmail: jspb.Message.getFieldWithDefault(msg, 1, ""),
    imapHost: jspb.Message.getFieldWithDefault(msg, 2, ""),
    imapPort: jspb.Message.getFieldWithDefault(msg, 3, 0),
    imapUsername: jspb.Message.getFieldWithDefault(msg, 4, ""),
    imapPassword: jspb.Message.getFieldWithDefault(msg, 5, ""),
    imapEncryptionMethod: jspb.Message.getFieldWithDefault(msg, 6, 0),
    smtpEmail: jspb.Message.getFieldWithDefault(msg, 7, ""),
    smtpHost: jspb.Message.getFieldWithDefault(msg, 8, ""),
    smtpport: jspb.Message.getFieldWithDefault(msg, 9, 0),
    smtpUsername: jspb.Message.getFieldWithDefault(msg, 10, ""),
    smtpPassword: jspb.Message.getFieldWithDefault(msg, 11, ""),
    smtpEncryptionMethod: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mailbox.SMTPSettings}
 */
proto.mailbox.SMTPSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mailbox.SMTPSettings;
  return proto.mailbox.SMTPSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mailbox.SMTPSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mailbox.SMTPSettings}
 */
proto.mailbox.SMTPSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setImapEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setImapHost(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setImapPort(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setImapUsername(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setImapPassword(value);
      break;
    case 6:
      var value = /** @type {!proto.mailbox.EncryptionMethod} */ (reader.readEnum());
      msg.setImapEncryptionMethod(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSmtpEmail(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSmtpHost(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSmtpport(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setSmtpUsername(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setSmtpPassword(value);
      break;
    case 12:
      var value = /** @type {!proto.mailbox.EncryptionMethod} */ (reader.readEnum());
      msg.setSmtpEncryptionMethod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mailbox.SMTPSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mailbox.SMTPSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mailbox.SMTPSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mailbox.SMTPSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImapEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getImapHost();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getImapPort();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getImapUsername();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getImapPassword();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getImapEncryptionMethod();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getSmtpEmail();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSmtpHost();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSmtpport();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getSmtpUsername();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSmtpPassword();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getSmtpEncryptionMethod();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
};


/**
 * optional string imap_email = 1;
 * @return {string}
 */
proto.mailbox.SMTPSettings.prototype.getImapEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox.SMTPSettings} returns this
 */
proto.mailbox.SMTPSettings.prototype.setImapEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string imap_host = 2;
 * @return {string}
 */
proto.mailbox.SMTPSettings.prototype.getImapHost = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox.SMTPSettings} returns this
 */
proto.mailbox.SMTPSettings.prototype.setImapHost = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 imap_port = 3;
 * @return {number}
 */
proto.mailbox.SMTPSettings.prototype.getImapPort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.mailbox.SMTPSettings} returns this
 */
proto.mailbox.SMTPSettings.prototype.setImapPort = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string imap_username = 4;
 * @return {string}
 */
proto.mailbox.SMTPSettings.prototype.getImapUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox.SMTPSettings} returns this
 */
proto.mailbox.SMTPSettings.prototype.setImapUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string imap_password = 5;
 * @return {string}
 */
proto.mailbox.SMTPSettings.prototype.getImapPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox.SMTPSettings} returns this
 */
proto.mailbox.SMTPSettings.prototype.setImapPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional EncryptionMethod imap_encryption_method = 6;
 * @return {!proto.mailbox.EncryptionMethod}
 */
proto.mailbox.SMTPSettings.prototype.getImapEncryptionMethod = function() {
  return /** @type {!proto.mailbox.EncryptionMethod} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.mailbox.EncryptionMethod} value
 * @return {!proto.mailbox.SMTPSettings} returns this
 */
proto.mailbox.SMTPSettings.prototype.setImapEncryptionMethod = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string smtp_email = 7;
 * @return {string}
 */
proto.mailbox.SMTPSettings.prototype.getSmtpEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox.SMTPSettings} returns this
 */
proto.mailbox.SMTPSettings.prototype.setSmtpEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string smtp_host = 8;
 * @return {string}
 */
proto.mailbox.SMTPSettings.prototype.getSmtpHost = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox.SMTPSettings} returns this
 */
proto.mailbox.SMTPSettings.prototype.setSmtpHost = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int32 smtpPort = 9;
 * @return {number}
 */
proto.mailbox.SMTPSettings.prototype.getSmtpport = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.mailbox.SMTPSettings} returns this
 */
proto.mailbox.SMTPSettings.prototype.setSmtpport = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string smtp_username = 10;
 * @return {string}
 */
proto.mailbox.SMTPSettings.prototype.getSmtpUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox.SMTPSettings} returns this
 */
proto.mailbox.SMTPSettings.prototype.setSmtpUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string smtp_password = 11;
 * @return {string}
 */
proto.mailbox.SMTPSettings.prototype.getSmtpPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox.SMTPSettings} returns this
 */
proto.mailbox.SMTPSettings.prototype.setSmtpPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional EncryptionMethod smtp_encryption_method = 12;
 * @return {!proto.mailbox.EncryptionMethod}
 */
proto.mailbox.SMTPSettings.prototype.getSmtpEncryptionMethod = function() {
  return /** @type {!proto.mailbox.EncryptionMethod} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.mailbox.EncryptionMethod} value
 * @return {!proto.mailbox.SMTPSettings} returns this
 */
proto.mailbox.SMTPSettings.prototype.setSmtpEncryptionMethod = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mailbox.AccountToWorkspace.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mailbox.AccountToWorkspace.prototype.toObject = function(opt_includeInstance) {
  return proto.mailbox.AccountToWorkspace.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mailbox.AccountToWorkspace} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mailbox.AccountToWorkspace.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    accountIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mailbox.AccountToWorkspace}
 */
proto.mailbox.AccountToWorkspace.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mailbox.AccountToWorkspace;
  return proto.mailbox.AccountToWorkspace.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mailbox.AccountToWorkspace} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mailbox.AccountToWorkspace}
 */
proto.mailbox.AccountToWorkspace.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 2:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setAccountIdsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mailbox.AccountToWorkspace.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mailbox.AccountToWorkspace.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mailbox.AccountToWorkspace} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mailbox.AccountToWorkspace.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAccountIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 workspace_id = 1;
 * @return {number}
 */
proto.mailbox.AccountToWorkspace.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mailbox.AccountToWorkspace} returns this
 */
proto.mailbox.AccountToWorkspace.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated int64 account_ids = 2;
 * @return {!Array<number>}
 */
proto.mailbox.AccountToWorkspace.prototype.getAccountIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.mailbox.AccountToWorkspace} returns this
 */
proto.mailbox.AccountToWorkspace.prototype.setAccountIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.mailbox.AccountToWorkspace} returns this
 */
proto.mailbox.AccountToWorkspace.prototype.addAccountIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mailbox.AccountToWorkspace} returns this
 */
proto.mailbox.AccountToWorkspace.prototype.clearAccountIdsList = function() {
  return this.setAccountIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mailbox.MailboxAccountWorkspaces.prototype.toObject = function(opt_includeInstance) {
  return proto.mailbox.MailboxAccountWorkspaces.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mailbox.MailboxAccountWorkspaces} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mailbox.MailboxAccountWorkspaces.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    workspaceTitle: jspb.Message.getFieldWithDefault(msg, 3, ""),
    workspaceLogoUrl: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mailbox.MailboxAccountWorkspaces}
 */
proto.mailbox.MailboxAccountWorkspaces.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mailbox.MailboxAccountWorkspaces;
  return proto.mailbox.MailboxAccountWorkspaces.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mailbox.MailboxAccountWorkspaces} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mailbox.MailboxAccountWorkspaces}
 */
proto.mailbox.MailboxAccountWorkspaces.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceLogoUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mailbox.MailboxAccountWorkspaces.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mailbox.MailboxAccountWorkspaces.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mailbox.MailboxAccountWorkspaces} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mailbox.MailboxAccountWorkspaces.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getWorkspaceTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getWorkspaceLogoUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.mailbox.MailboxAccountWorkspaces.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mailbox.MailboxAccountWorkspaces} returns this
 */
proto.mailbox.MailboxAccountWorkspaces.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 workspace_id = 2;
 * @return {number}
 */
proto.mailbox.MailboxAccountWorkspaces.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mailbox.MailboxAccountWorkspaces} returns this
 */
proto.mailbox.MailboxAccountWorkspaces.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string workspace_title = 3;
 * @return {string}
 */
proto.mailbox.MailboxAccountWorkspaces.prototype.getWorkspaceTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox.MailboxAccountWorkspaces} returns this
 */
proto.mailbox.MailboxAccountWorkspaces.prototype.setWorkspaceTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string workspace_logo_url = 4;
 * @return {string}
 */
proto.mailbox.MailboxAccountWorkspaces.prototype.getWorkspaceLogoUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox.MailboxAccountWorkspaces} returns this
 */
proto.mailbox.MailboxAccountWorkspaces.prototype.setWorkspaceLogoUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mailbox.TestConnectionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mailbox.TestConnectionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mailbox.TestConnectionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mailbox.TestConnectionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    senderName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    smtp: (f = msg.getSmtp()) && proto.mailbox.ConnectionSettings.toObject(includeInstance, f),
    imap: (f = msg.getImap()) && proto.mailbox.ConnectionSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mailbox.TestConnectionRequest}
 */
proto.mailbox.TestConnectionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mailbox.TestConnectionRequest;
  return proto.mailbox.TestConnectionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mailbox.TestConnectionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mailbox.TestConnectionRequest}
 */
proto.mailbox.TestConnectionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSenderName(value);
      break;
    case 4:
      var value = new proto.mailbox.ConnectionSettings;
      reader.readMessage(value,proto.mailbox.ConnectionSettings.deserializeBinaryFromReader);
      msg.setSmtp(value);
      break;
    case 5:
      var value = new proto.mailbox.ConnectionSettings;
      reader.readMessage(value,proto.mailbox.ConnectionSettings.deserializeBinaryFromReader);
      msg.setImap(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mailbox.TestConnectionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mailbox.TestConnectionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mailbox.TestConnectionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mailbox.TestConnectionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSenderName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSmtp();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.mailbox.ConnectionSettings.serializeBinaryToWriter
    );
  }
  f = message.getImap();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.mailbox.ConnectionSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional string sender_name = 1;
 * @return {string}
 */
proto.mailbox.TestConnectionRequest.prototype.getSenderName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox.TestConnectionRequest} returns this
 */
proto.mailbox.TestConnectionRequest.prototype.setSenderName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ConnectionSettings smtp = 4;
 * @return {?proto.mailbox.ConnectionSettings}
 */
proto.mailbox.TestConnectionRequest.prototype.getSmtp = function() {
  return /** @type{?proto.mailbox.ConnectionSettings} */ (
    jspb.Message.getWrapperField(this, proto.mailbox.ConnectionSettings, 4));
};


/**
 * @param {?proto.mailbox.ConnectionSettings|undefined} value
 * @return {!proto.mailbox.TestConnectionRequest} returns this
*/
proto.mailbox.TestConnectionRequest.prototype.setSmtp = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mailbox.TestConnectionRequest} returns this
 */
proto.mailbox.TestConnectionRequest.prototype.clearSmtp = function() {
  return this.setSmtp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mailbox.TestConnectionRequest.prototype.hasSmtp = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ConnectionSettings imap = 5;
 * @return {?proto.mailbox.ConnectionSettings}
 */
proto.mailbox.TestConnectionRequest.prototype.getImap = function() {
  return /** @type{?proto.mailbox.ConnectionSettings} */ (
    jspb.Message.getWrapperField(this, proto.mailbox.ConnectionSettings, 5));
};


/**
 * @param {?proto.mailbox.ConnectionSettings|undefined} value
 * @return {!proto.mailbox.TestConnectionRequest} returns this
*/
proto.mailbox.TestConnectionRequest.prototype.setImap = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mailbox.TestConnectionRequest} returns this
 */
proto.mailbox.TestConnectionRequest.prototype.clearImap = function() {
  return this.setImap(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mailbox.TestConnectionRequest.prototype.hasImap = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mailbox.TestConnectionResponseStreamContainer.prototype.toObject = function(opt_includeInstance) {
  return proto.mailbox.TestConnectionResponseStreamContainer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mailbox.TestConnectionResponseStreamContainer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mailbox.TestConnectionResponseStreamContainer.toObject = function(includeInstance, msg) {
  var f, obj = {
    inProcess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    result: (f = msg.getResult()) && proto.mailbox.TestConnectionResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mailbox.TestConnectionResponseStreamContainer}
 */
proto.mailbox.TestConnectionResponseStreamContainer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mailbox.TestConnectionResponseStreamContainer;
  return proto.mailbox.TestConnectionResponseStreamContainer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mailbox.TestConnectionResponseStreamContainer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mailbox.TestConnectionResponseStreamContainer}
 */
proto.mailbox.TestConnectionResponseStreamContainer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInProcess(value);
      break;
    case 2:
      var value = new proto.mailbox.TestConnectionResponse;
      reader.readMessage(value,proto.mailbox.TestConnectionResponse.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mailbox.TestConnectionResponseStreamContainer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mailbox.TestConnectionResponseStreamContainer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mailbox.TestConnectionResponseStreamContainer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mailbox.TestConnectionResponseStreamContainer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInProcess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mailbox.TestConnectionResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool in_process = 1;
 * @return {boolean}
 */
proto.mailbox.TestConnectionResponseStreamContainer.prototype.getInProcess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mailbox.TestConnectionResponseStreamContainer} returns this
 */
proto.mailbox.TestConnectionResponseStreamContainer.prototype.setInProcess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional TestConnectionResponse result = 2;
 * @return {?proto.mailbox.TestConnectionResponse}
 */
proto.mailbox.TestConnectionResponseStreamContainer.prototype.getResult = function() {
  return /** @type{?proto.mailbox.TestConnectionResponse} */ (
    jspb.Message.getWrapperField(this, proto.mailbox.TestConnectionResponse, 2));
};


/**
 * @param {?proto.mailbox.TestConnectionResponse|undefined} value
 * @return {!proto.mailbox.TestConnectionResponseStreamContainer} returns this
*/
proto.mailbox.TestConnectionResponseStreamContainer.prototype.setResult = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mailbox.TestConnectionResponseStreamContainer} returns this
 */
proto.mailbox.TestConnectionResponseStreamContainer.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mailbox.TestConnectionResponseStreamContainer.prototype.hasResult = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mailbox.TestConnectionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mailbox.TestConnectionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mailbox.TestConnectionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mailbox.TestConnectionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    validSmtpEncryption: jspb.Message.getFieldWithDefault(msg, 3, 0),
    validImapEncryption: jspb.Message.getFieldWithDefault(msg, 4, 0),
    validSmtpPort: jspb.Message.getFieldWithDefault(msg, 5, 0),
    validImapPort: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mailbox.TestConnectionResponse}
 */
proto.mailbox.TestConnectionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mailbox.TestConnectionResponse;
  return proto.mailbox.TestConnectionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mailbox.TestConnectionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mailbox.TestConnectionResponse}
 */
proto.mailbox.TestConnectionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {!proto.mailbox.EncryptionMethod} */ (reader.readEnum());
      msg.setValidSmtpEncryption(value);
      break;
    case 4:
      var value = /** @type {!proto.mailbox.EncryptionMethod} */ (reader.readEnum());
      msg.setValidImapEncryption(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setValidSmtpPort(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setValidImapPort(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mailbox.TestConnectionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mailbox.TestConnectionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mailbox.TestConnectionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mailbox.TestConnectionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValidSmtpEncryption();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getValidImapEncryption();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getValidSmtpPort();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getValidImapPort();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.mailbox.TestConnectionResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mailbox.TestConnectionResponse} returns this
 */
proto.mailbox.TestConnectionResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.mailbox.TestConnectionResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox.TestConnectionResponse} returns this
 */
proto.mailbox.TestConnectionResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional EncryptionMethod valid_smtp_encryption = 3;
 * @return {!proto.mailbox.EncryptionMethod}
 */
proto.mailbox.TestConnectionResponse.prototype.getValidSmtpEncryption = function() {
  return /** @type {!proto.mailbox.EncryptionMethod} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.mailbox.EncryptionMethod} value
 * @return {!proto.mailbox.TestConnectionResponse} returns this
 */
proto.mailbox.TestConnectionResponse.prototype.setValidSmtpEncryption = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional EncryptionMethod valid_imap_encryption = 4;
 * @return {!proto.mailbox.EncryptionMethod}
 */
proto.mailbox.TestConnectionResponse.prototype.getValidImapEncryption = function() {
  return /** @type {!proto.mailbox.EncryptionMethod} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.mailbox.EncryptionMethod} value
 * @return {!proto.mailbox.TestConnectionResponse} returns this
 */
proto.mailbox.TestConnectionResponse.prototype.setValidImapEncryption = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int32 valid_smtp_port = 5;
 * @return {number}
 */
proto.mailbox.TestConnectionResponse.prototype.getValidSmtpPort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.mailbox.TestConnectionResponse} returns this
 */
proto.mailbox.TestConnectionResponse.prototype.setValidSmtpPort = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 valid_imap_port = 6;
 * @return {number}
 */
proto.mailbox.TestConnectionResponse.prototype.getValidImapPort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.mailbox.TestConnectionResponse} returns this
 */
proto.mailbox.TestConnectionResponse.prototype.setValidImapPort = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mailbox.ConnectionSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.mailbox.ConnectionSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mailbox.ConnectionSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mailbox.ConnectionSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    host: jspb.Message.getFieldWithDefault(msg, 3, ""),
    port: jspb.Message.getFieldWithDefault(msg, 4, 0),
    username: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mailbox.ConnectionSettings}
 */
proto.mailbox.ConnectionSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mailbox.ConnectionSettings;
  return proto.mailbox.ConnectionSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mailbox.ConnectionSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mailbox.ConnectionSettings}
 */
proto.mailbox.ConnectionSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setHost(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPort(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mailbox.ConnectionSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mailbox.ConnectionSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mailbox.ConnectionSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mailbox.ConnectionSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHost();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPort();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.mailbox.ConnectionSettings.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox.ConnectionSettings} returns this
 */
proto.mailbox.ConnectionSettings.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.mailbox.ConnectionSettings.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox.ConnectionSettings} returns this
 */
proto.mailbox.ConnectionSettings.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string host = 3;
 * @return {string}
 */
proto.mailbox.ConnectionSettings.prototype.getHost = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox.ConnectionSettings} returns this
 */
proto.mailbox.ConnectionSettings.prototype.setHost = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 port = 4;
 * @return {number}
 */
proto.mailbox.ConnectionSettings.prototype.getPort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.mailbox.ConnectionSettings} returns this
 */
proto.mailbox.ConnectionSettings.prototype.setPort = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string username = 5;
 * @return {string}
 */
proto.mailbox.ConnectionSettings.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox.ConnectionSettings} returns this
 */
proto.mailbox.ConnectionSettings.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mailbox.ReferralContentState.prototype.toObject = function(opt_includeInstance) {
  return proto.mailbox.ReferralContentState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mailbox.ReferralContentState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mailbox.ReferralContentState.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    state: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mailbox.ReferralContentState}
 */
proto.mailbox.ReferralContentState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mailbox.ReferralContentState;
  return proto.mailbox.ReferralContentState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mailbox.ReferralContentState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mailbox.ReferralContentState}
 */
proto.mailbox.ReferralContentState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mailbox.ReferralContentState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mailbox.ReferralContentState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mailbox.ReferralContentState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mailbox.ReferralContentState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getState();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.mailbox.ReferralContentState.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mailbox.ReferralContentState} returns this
 */
proto.mailbox.ReferralContentState.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool state = 2;
 * @return {boolean}
 */
proto.mailbox.ReferralContentState.prototype.getState = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mailbox.ReferralContentState} returns this
 */
proto.mailbox.ReferralContentState.prototype.setState = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.mailbox.MailboxAccountStatus = {
  MAILBOX_ACTIVE: 0,
  MAILBOX_PAUSED: 1,
  MAILBOX_AUTH_FAILED: 2
};

/**
 * @enum {number}
 */
proto.mailbox.MailboxHealthcheckState = {
  HEALTHCHECK_OFF: 0,
  HEALTHCHECK_WEEKLY: 1
};

/**
 * @enum {number}
 */
proto.mailbox.MailboxAccountWarmupState = {
  WARMUP_OFF: 0,
  WARMUP_LIGHT: 1,
  WARMUP_MEDIUM: 2,
  WARMUP_HIGH: 3
};

/**
 * @enum {number}
 */
proto.mailbox.MailboxAccountSyncType = {
  MAILBOX_SYNC_CAMPAIGN: 0,
  MAILBOX_SYNC_ALL: 1
};

/**
 * @enum {number}
 */
proto.mailbox.MailboxAccountType = {
  UNKNOWN: 0,
  GMAIL: 1,
  SMTP_IMAP: 2,
  MICROSOFT: 3
};

/**
 * @enum {number}
 */
proto.mailbox.EncryptionMethod = {
  DISABLED: 0,
  SSL: 1,
  TLS: 2,
  STARTTLS: 3,
  PLAIN: 4
};

goog.object.extend(exports, proto.mailbox);
