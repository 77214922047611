import React from 'react';

export default ({ color = '#fff' }) => (
  <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.865487 5.81616L5.58215 1.10783C5.65962 1.02972 5.75179 0.967725 5.85334 0.925418C5.95489 0.88311 6.06381 0.861328 6.17382 0.861328C6.28383 0.861328 6.39275 0.88311 6.4943 0.925417C6.59585 0.967725 6.68802 1.02972 6.76549 1.10783C6.92069 1.26396 7.00781 1.47517 7.00781 1.69533C7.00781 1.91548 6.92069 2.12669 6.76549 2.28283L2.64049 6.44949L6.76549 10.5745C6.9207 10.7306 7.00781 10.9418 7.00781 11.162C7.00781 11.3821 6.9207 11.5934 6.76549 11.7495C6.68831 11.8282 6.59627 11.8909 6.49471 11.9338C6.39314 11.9767 6.28408 11.999 6.17382 11.9995C6.06356 11.999 5.9545 11.9767 5.85293 11.9338C5.75137 11.8909 5.65933 11.8282 5.58215 11.7495L0.865487 7.04116C0.7809 6.96313 0.713392 6.86842 0.66722 6.763C0.621047 6.65758 0.59721 6.54375 0.59721 6.42866C0.59721 6.31358 0.621047 6.19974 0.66722 6.09432C0.713392 5.98891 0.780899 5.8942 0.865487 5.81616Z"
      fill={color}
    />
  </svg>
);
