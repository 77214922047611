import React from 'react';

export default ({ color = '#D6D6D6' }) => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.03563 3.73333C1.38148 4.71057 1 5.88576 1 7.15C1 10.5466 3.75345 13.3 7.15 13.3C10.5466 13.3 13.3 10.5466 13.3 7.15C13.3 3.75345 10.5466 1 7.15 1V3.05M7.15 7.15L4.41667 4.41667"
      stroke="#C4C6CD"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
