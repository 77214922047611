import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb';
import * as google_protobuf_wrappers_pb from 'google-protobuf/google/protobuf/wrappers_pb';

import billingService from '@api/services/billingService';
import request from '@api/base/request';
import streamRequest from '@api/base/streamRequest';

import {
  InvoiceContainerType,
  BillingType,
  InvoiceShortType,
  InvoiceType,
  BillingAddressType,
  CreditCardInfoType,
  PaymentResponseType,
  SubscriptionPresetResponseType,
  MailboxAccountPriceResponseType,
  BillingSubscriptionTypeMapValue,
  BillingDetailsShortType,
  BillingCycleTypeValue,
} from '@ts/billing.types';
import { DateRangeNumberType, FileResponseType } from '@ts/common.types';
import * as common_pb from 'respona_api/generated/common_pb';
import CommonModels from 'respona_api/generated/common_pb';
import BillingModels, {
  BillingCancellationActionResponse,
  BillingCancellationResponse,
  BillingFeedbackMap,
  BillingSubscriptionCycleMap,
  PlanPriceRequest,
} from 'respona_api/generated/billing_pb';

export const getBraintreeTokenApi = (): Promise<{ value: string }> => {
  const client = billingService.getInstance();
  const emptyRequest = new google_protobuf_empty_pb.Empty();

  return request<{ value: string }>(client, client.getBraintreeToken, emptyRequest);
};

export const updateCreditCardApi = (
  braintreeNonce: string,
  braintreeDeviceData: any
): Promise<CreditCardInfoType> => {
  const client = billingService.getInstance();
  const updateCreditCardrequest = new BillingModels.UpdateCreditCardRequest();

  updateCreditCardrequest.setBraintreeDeviceData(braintreeDeviceData);
  updateCreditCardrequest.setBraintreeNonce(braintreeNonce);

  return request<CreditCardInfoType>(client, client.updateCreditCardInfo, updateCreditCardrequest);
};

export const makePaymentApi = (
  invoiceId: number,
  braintreeNonce?: string,
  braintreeDeviceData?: string,
  billingDetails?: any
): Promise<PaymentResponseType> => {
  const client = billingService.getInstance();
  const paymentRequest = new BillingModels.PaymentRequest();

  paymentRequest.setInvoiceId(invoiceId);
  if (braintreeNonce) {
    paymentRequest.setBraintreeNonce(braintreeNonce);
  }
  if (braintreeDeviceData) {
    paymentRequest.setBraintreeDeviceData(braintreeDeviceData);
  }

  if (billingDetails) {
    const invoiceRecipient = new BillingModels.InvoiceRecipient();

    invoiceRecipient.setCompany(billingDetails.name);
    invoiceRecipient.setAddressFirstLine(billingDetails.adress1);
    invoiceRecipient.setAddressSecondLine(billingDetails.adress2);
    invoiceRecipient.setCity(billingDetails.city);
    invoiceRecipient.setZipCode(billingDetails.zipCode);
    invoiceRecipient.setState(billingDetails.state);
    invoiceRecipient.setCountry(billingDetails.country);

    paymentRequest.setOptionalRecipient(invoiceRecipient);
  }

  return request<PaymentResponseType>(client, client.makePayment, paymentRequest);
};

export const getCurrentInvoiceApi = (): Promise<InvoiceContainerType> => {
  const client = billingService.getInstance();
  const emptyRequest = new google_protobuf_empty_pb.Empty();

  return request<InvoiceContainerType>(client, client.getCurrentInvoice, emptyRequest);
};

export const getBillingDetailsApi = (): Promise<BillingType> => {
  const client = billingService.getInstance();
  const emptyRequest = new google_protobuf_empty_pb.Empty();

  return request<BillingType>(client, client.getBillingDetails, emptyRequest);
};

export const getBillingShortDetailsApi = (): Promise<BillingDetailsShortType> => {
  const client = billingService.getInstance();
  const emptyRequest = new google_protobuf_empty_pb.Empty();

  return request<BillingDetailsShortType>(client, client.getBillingShortDetails, emptyRequest);
};

export const getBillingAddressApi = (): Promise<BillingAddressType> => {
  const client = billingService.getInstance();
  const emptyRequest = new google_protobuf_empty_pb.Empty();

  return request<BillingAddressType>(client, client.getBillingAddress, emptyRequest);
};

export const updateBillingAddressApi = (
  addressFields: BillingAddressType
): Promise<BillingAddressType> => {
  const client = billingService.getInstance();
  const billingAddress = new BillingModels.BillingAddress();

  if (addressFields.addressId) {
    billingAddress.setAddressId(addressFields.addressId);
  }
  billingAddress.setCompanyName(addressFields.companyName);
  billingAddress.setFirstLine(addressFields.firstLine);
  billingAddress.setSecondLine(addressFields.secondLine);
  billingAddress.setCity(addressFields.city);
  billingAddress.setZipCode(addressFields.zipCode);
  billingAddress.setState(addressFields.state);
  billingAddress.setCountry(addressFields.country);
  billingAddress.setContactEmail(addressFields.contactEmail);
  billingAddress.setContactName(addressFields.contactName);
  billingAddress.setTax(addressFields.tax);

  return request<BillingAddressType>(client, client.updateBillingAddress, billingAddress);
};

export const getInvoicesApi = (
  page: number,
  limit: number,
  searchString?: string,
  dateRange?: DateRangeNumberType
): Promise<InvoiceShortType[]> => {
  const client = billingService.getInstance();
  const invoiceRequest = new BillingModels.InvoiceRequest();

  invoiceRequest.setPage(page);
  invoiceRequest.setLimit(limit);

  if (searchString) {
    invoiceRequest.setQuery(searchString);
  }

  if (dateRange) {
    const timestampRange = new common_pb.TimestampRange();

    timestampRange.setFrom(dateRange.startDate);
    timestampRange.setTo(dateRange.endDate);

    invoiceRequest.setDateRange(timestampRange);
  }

  return streamRequest<InvoiceShortType[]>(client, client.getInvoices, invoiceRequest);
};

export const getInvoiceDetailsApi = (
  workspaceId: number,
  invoiceId: number
): Promise<InvoiceType> => {
  const client = billingService.getInstance();
  const idRequest = new CommonModels.IdRequest();

  idRequest.setOptionalWorkspaceId(workspaceId);
  idRequest.setId(invoiceId);

  return request<InvoiceType>(client, client.getInvoiceDetails, idRequest);
};

export const calcSubscriptionPriceApi = (
  emailAccounts: number,
  credits: number,
  type: BillingSubscriptionTypeMapValue,
  cycle: BillingCycleTypeValue,
  contacts: number
): Promise<{ value: number }> => {
  const client = billingService.getInstance();
  const subscriptionPriceRequest = new BillingModels.SubscriptionPriceRequest();

  subscriptionPriceRequest.setType(type);
  subscriptionPriceRequest.setCredits(credits);
  subscriptionPriceRequest.setCycle(cycle);
  subscriptionPriceRequest.setEmailAccounts(emailAccounts);
  subscriptionPriceRequest.setContacts(contacts);

  return request<{ value: number }>(client, client.calcSubscriptionPrice, subscriptionPriceRequest);
};

export const changeSubscriptionApi = (
  emailAccounts: number,
  credits: number,
  type: BillingSubscriptionTypeMapValue,
  braintreeNonce?: string,
  braintreeDeviceData?: any,
  cycle?: BillingCycleTypeValue,
  contacts?: number
): Promise<PaymentResponseType> => {
  const client = billingService.getInstance();
  const subscriptionChangeRequest = new BillingModels.SubscriptionChangeRequest();

  subscriptionChangeRequest.setCycle(cycle);
  subscriptionChangeRequest.setContacts(contacts);
  subscriptionChangeRequest.setEmailAccounts(emailAccounts);
  subscriptionChangeRequest.setCredits(credits);
  subscriptionChangeRequest.setType(type);
  if (braintreeNonce) {
    subscriptionChangeRequest.setOptionalBraintreeNonce(braintreeNonce);
  }
  if (braintreeDeviceData) {
    subscriptionChangeRequest.setOptionalBraintreeDeviceData(braintreeDeviceData);
  }

  return request<PaymentResponseType>(client, client.changeSubscription, subscriptionChangeRequest);
};

export const getSubscriptionPresetApi = (): Promise<SubscriptionPresetResponseType> => {
  const client = billingService.getInstance();
  const emptyRequest = new google_protobuf_empty_pb.Empty();

  return request<SubscriptionPresetResponseType>(
    client,
    client.getSubscriptionPreset,
    emptyRequest
  );
};

export const calcBonusCreditsPriceApi = (
  bonusCreditsNumber: number
): Promise<{ value: number }> => {
  const client = billingService.getInstance();
  const bonusCreditsrequest = new google_protobuf_wrappers_pb.Int32Value();

  bonusCreditsrequest.setValue(bonusCreditsNumber);

  return request<{ value: number }>(client, client.calcBonusCreditsPrice, bonusCreditsrequest);
};

export const buyBonusCreditsApi = (bonusCreditsNumber: number): Promise<PaymentResponseType> => {
  const client = billingService.getInstance();
  const bonusCreditsrequest = new google_protobuf_wrappers_pb.Int32Value();

  bonusCreditsrequest.setValue(bonusCreditsNumber);

  return request<PaymentResponseType>(client, client.buyBonusCredits, bonusCreditsrequest);
};

export const calcMailboxAccountPriceApi = (
  mailboxesNumber: number
): Promise<MailboxAccountPriceResponseType> => {
  const client = billingService.getInstance();
  const calcMailboxPriceRequest = new google_protobuf_wrappers_pb.Int32Value();

  calcMailboxPriceRequest.setValue(mailboxesNumber);

  return request<MailboxAccountPriceResponseType>(
    client,
    client.calcMailboxAccountPrice,
    calcMailboxPriceRequest
  );
};

export const calcContactsPriceApi = (
  amount: number,
  type: BillingSubscriptionTypeMapValue,
  cycle: BillingCycleTypeValue
): Promise<{ value: number }> => {
  const client = billingService.getInstance();
  const contactsPriceRequest = new PlanPriceRequest();

  contactsPriceRequest.setAmount(amount);
  contactsPriceRequest.setType(type);
  contactsPriceRequest.setCycle(cycle);

  return request<{ value: number }>(client, client.calcPlanContactsPrice, contactsPriceRequest);
};

export const upgradeMailboxAccountsNumberApi = (
  mailboxesNumber: number
): Promise<PaymentResponseType> => {
  const client = billingService.getInstance();
  const calcMailboxPriceRequest = new google_protobuf_wrappers_pb.Int32Value();

  calcMailboxPriceRequest.setValue(mailboxesNumber);

  return request<PaymentResponseType>(
    client,
    client.upgradeMailboxAccountsNumber,
    calcMailboxPriceRequest
  );
};

export const upgradeContactsNumberApi = (
  amount: number,
  type: BillingSubscriptionTypeMapValue,
  cycle: BillingCycleTypeValue
): Promise<PaymentResponseType> => {
  const client = billingService.getInstance();
  const upgradeContactsNumberRequest = new PlanPriceRequest();

  upgradeContactsNumberRequest.setAmount(amount);
  upgradeContactsNumberRequest.setType(type);
  upgradeContactsNumberRequest.setCycle(cycle);

  return request<PaymentResponseType>(
    client,
    client.upgradePlanContacts,
    upgradeContactsNumberRequest
  );
};

const createPlanPriceRequest = (
  bonusCreditsNumber: number,
  billingSubscriptionType: BillingSubscriptionTypeMapValue,
  cycle: BillingSubscriptionCycleMap[keyof BillingSubscriptionCycleMap]
) => {
  const planPriceRequest = new BillingModels.PlanPriceRequest();

  planPriceRequest.setAmount(bonusCreditsNumber);
  planPriceRequest.setType(billingSubscriptionType);
  planPriceRequest.setCycle(cycle);

  return planPriceRequest;
};

export const calcPlanCreditsPriceApi = (
  bonusCreditsNumber: number,
  billingSubscriptionType: BillingSubscriptionTypeMapValue,
  cycle: BillingSubscriptionCycleMap[keyof BillingSubscriptionCycleMap]
): Promise<{ value: number }> => {
  const client = billingService.getInstance();

  return request<{ value: number }>(
    client,
    client.calcPlanCreditsPrice,
    createPlanPriceRequest(bonusCreditsNumber, billingSubscriptionType, cycle)
  );
};

export const upgradePlanCreditsApi = (
  bonusCreditsNumber: number,
  billingSubscriptionType: BillingSubscriptionTypeMapValue,
  cycle: BillingSubscriptionCycleMap[keyof BillingSubscriptionCycleMap]
): Promise<PaymentResponseType> => {
  const client = billingService.getInstance();

  return request<PaymentResponseType>(
    client,
    client.upgradePlanCredits,
    createPlanPriceRequest(bonusCreditsNumber, billingSubscriptionType, cycle)
  );
};

export const generateInvoicePdfApi = (
  invoiceId: number,
  workspaceId: number
): Promise<FileResponseType> => {
  const client = billingService.getInstance();
  const idRequest = new CommonModels.IdRequest();

  idRequest.setId(invoiceId);
  idRequest.setOptionalWorkspaceId(workspaceId);

  return request<FileResponseType>(client, client.generateInvoicePdf, idRequest);
};

export const getCancellationDetailsApi = (): Promise<BillingCancellationResponse.AsObject> => {
  const client = billingService.getInstance();
  const emptyRequest = new google_protobuf_empty_pb.Empty();

  return request<BillingCancellationResponse.AsObject>(
    client,
    client.getCancellationDetails,
    emptyRequest
  );
};

export const cancelAndStoreFeedbackApi = (
  feedback: BillingFeedbackMap[keyof BillingFeedbackMap],
  improve: string
): Promise<BillingCancellationActionResponse.AsObject> => {
  const client = billingService.getInstance();
  const cancellationRequest = new BillingModels.BillingCancellationFeedbackRequest();

  cancellationRequest.setFeedback(feedback);
  cancellationRequest.setImprove(improve || '-');

  return request<BillingCancellationActionResponse.AsObject>(
    client,
    client.cancelAndStoreFeedback,
    cancellationRequest
  );
};

export const resumeCancellationApi = (): Promise<void> => {
  const client = billingService.getInstance();
  const emptyRequest = new google_protobuf_empty_pb.Empty();

  return request<void>(client, client.resumeCancellation, emptyRequest);
};
