import React, { useCallback, useRef, useState } from 'react';
import { QueryKey, useQuery } from 'react-query';

import { PeopleRelationshipStatus } from 'respona_api/generated/common_pb';

import { WebsiteType } from '@ts/company.types';
import { PersonBaseType, RelationshipStatusType } from '@ts/people.types';

import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';

import { SVGIcon, SvgIconType } from '@uikit/Icon/Icon';
import { socialLinkTypeToIconMapper } from '@uikit/SocialLinks/SocialLinks';
import RelationshipStatus from '@components/Relationships/PeopleTable/_components/RelationshipStatus';
import RelationshipsCreatePersonSidebar from '@components/Relationships/RelationshipsCreatePersonSidebar/RelationshipsCreatePersonSidebar';
import SidebarCRMSection from '@components/SidebarCRM/sections/SidebarCRMSection/SidebarCRMSection';
import SidebarCRMSectionEditableField from '@components/SidebarCRM/sections/SidebarCRMSection/_components/SidebarCRMSectionEditableField/SidebarCRMSectionEditableField';
import SidebarCRMSectionSelectionField from '@components/SidebarCRM/sections/SidebarCRMSection/_components/SidebarCRMSectionSelectionField/SidebarCRMSectionSelectionField';

import './SidebarCRMSectionContact.scss';

declare const IS_DEV: boolean;

export const personStatusOptions = [
  {
    value: PeopleRelationshipStatus.RELATIONSHIP_STATUS_NOT_CONTACTED,
    label: 'Not contacted',
  },
  {
    value: PeopleRelationshipStatus.RELATIONSHIP_STATUS_ATTEMPTING_TO_REACH,
    label: 'Scheduled',
  },
  {
    value: PeopleRelationshipStatus.RELATIONSHIP_STATUS_CONTACTED,
    label: 'Contacted',
  },
  {
    value: PeopleRelationshipStatus.RELATIONSHIP_STATUS_REPLIED,
    label: 'Replied',
  },
  {
    value: PeopleRelationshipStatus.RELATIONSHIP_STATUS_BOUNCED,
    label: 'Bounced',
  },
  {
    value: PeopleRelationshipStatus.RELATIONSHIP_STATUS_UNSUBSCRIBED,
    label: 'Unsubscribed',
  },
  {
    value: PeopleRelationshipStatus.RELATIONSHIP_STATUS_CANCELED_MANUALLY,
    label: 'Stopped',
  },
  {
    value: PeopleRelationshipStatus.RELATIONSHIP_STATUS_INTERESTED,
    label: 'Interested',
  },
  {
    value: PeopleRelationshipStatus.RELATIONSHIP_STATUS_NOT_INTERESTED,
    label: 'Not interested',
  },
  {
    value: PeopleRelationshipStatus.RELATIONSHIP_STATUS_WON,
    label: 'Won',
  },
  {
    value: PeopleRelationshipStatus.RELATIONSHIP_STATUS_LOST,
    label: 'Lost',
  },
];

function SidebarCRMSectionPerson({
  contact,
  updateContact,
  saveEmail,
  removeEmail,
  removeContact,
  isRemovable,
}: {
  contact: PersonBaseType;
  updateContact?: (
    key: keyof PersonBaseType,
    value: string,
    contact: PersonBaseType
  ) => Promise<void>;
  saveEmail?: (contactId: number, initialEmail: string, email: string) => Promise<void>;
  removeEmail?: (contactId: number, email: string) => Promise<void>;
  removeContact: (contactId: number) => Promise<void>;
  isRemovable: boolean;
}) {
  const [isShownMoreEmails, setShownMoreEmails] = useState(false);

  const handleRemoveContact = (event, contactId) => {
    event.stopPropagation();

    removeContact(contactId);
  };

  const socialLinks = []
    .concat(contact.socialLinksList || [])
    .filter(Boolean)
    .filter((item, index, arr) => arr.findIndex((link) => link.url === item.url) === index);

  const emailsList = contact.emailsList || [];

  return (
    <div className="contact-sidebar-crm__content-personal-info">
      <div className="inbox-content-wrapper flex-content">
        <div className="inbox-content-wrapper__content">
          {updateContact ? (
            <SidebarCRMSectionEditableField
              enableCopy={false}
              initialValue={contact.name}
              onSave={(_, value) => updateContact('name', value, contact)}
              placeholder="Enter name:"
              extraClass="main-title"
            />
          ) : (
            <div className="inbox-content-wrapper__content-title">
              <span>{contact.name}</span>
            </div>
          )}
          {socialLinks?.length > 0 && (
            <>
              {socialLinks.map((item) => (
                <div
                  key={item.url}
                  className="contact-sidebar-crm__content-personal-info-data-row contact-sidebar-crm__content-personal-info-data-row-social"
                  onClick={() => window.open(item.url, '_blank')}
                >
                  <SVGIcon icon={socialLinkTypeToIconMapper[item.type] as SvgIconType} />
                </div>
              ))}
            </>
          )}
          {isRemovable && (
            <span
              className="opportunity-contacts-sidebar-section-contacts__contacts-contact-title-close"
              onClick={(event) => handleRemoveContact(event, contact.id)}
            >
              <SVGIcon icon="crossInGreyCircle" />
            </span>
          )}
        </div>
        {(isShownMoreEmails ? emailsList : emailsList.length >= 1 ? [emailsList[0]] : []).map(
          (item) => {
            if (saveEmail) {
              return (
                <SidebarCRMSectionEditableField
                  key={item.email}
                  initialValue={item.email}
                  onSave={(initialValue: string, email: string) =>
                    saveEmail(contact.id, initialValue, email)
                  }
                  onDelete={(email) => removeEmail(contact.id, email)}
                  placeholder="Enter email:"
                  isRenderIcon
                  renderIcon="messageIcon"
                />
              );
            }

            return (
              <div className="inbox-content-wrapper__data">
                <SVGIcon icon="email" color="#C4C6CD" />
                <div className="inbox-content-wrapper__data-email">{item.email}</div>
              </div>
            );
          }
        )}
        {isShownMoreEmails || emailsList.length <= 1 ? null : (
          <div
            onClick={() => setShownMoreEmails(true)}
            className="organizations-table__emails_more text-align-right"
          >
            +{emailsList.length - 1} other{emailsList.length - 1 > 1 ? 's' : ''}
          </div>
        )}
        {updateContact ? (
          <SidebarCRMSectionEditableField
            enableCopy={false}
            initialValue={contact.jobPosition || ''}
            onSave={(_, value) => updateContact('jobPosition', value, contact)}
            placeholder="Enter job title:"
            renderContent={(value) => (value == null || value.length === 0 ? '-' : value)}
            isRenderIcon
            renderIcon="personRounded"
          />
        ) : (
          <div className="inbox-content-wrapper__data">
            <SVGIcon icon="personRounded" size={15} color="#C4C6CD" />
            <div className="inbox-content-wrapper__data-email">{contact.jobPosition || ''}</div>
          </div>
        )}
        <div className="contact-sidebar-crm__content-personal-info-data-row">
          {contact.status >= 0 ? (
            <>
              <SVGIcon icon="flagStriped" size={16} />
              <RelationshipStatus status={contact.status} isBlocked={false} withIcon={false} />
            </>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
}

function SidebarCRMSectionContact({
  queryKeyWebsite,
  index,
  itemId,
  title = 'Contacts',
  getWebsite,
  updateContact,
  onCreatedContact,
  removeContact,
  saveEmail,
  removeEmail,
}: {
  queryKeyWebsite: QueryKey;
  index: number;
  itemId: number;
  title: string;
  getWebsite: (itemId: number, workspaceId: number) => Promise<WebsiteType>;
  updateContact?: (
    key: keyof PersonBaseType,
    value: string,
    contact: PersonBaseType
  ) => Promise<void>;
  onCreatedContact: (PersonBaseType) => Promise<void>;
  removeContact: (id: number) => Promise<void>;
  saveEmail?: (contactId: number, initialEmail: string, email: string) => Promise<void>;
  removeEmail?: (personId: number, email: string) => Promise<void>;
}) {
  const ref = useRef(null);
  const workspaceId = useCurrentWorkspaceId();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [queryEnabled, setQueryEnabled] = useState<boolean>(true);
  const [isOpenedCreatePersonSideBar, setOpenedCreatePersonSideBar] = useState(false);

  const onOpenCallback = useCallback(() => {
    setQueryEnabled(true);
  }, []);

  if (!itemId) {
    return null;
  }

  const preparedQuery = [queryKeyWebsite, itemId, workspaceId];
  const { data: website } = useQuery<WebsiteType>(
    preparedQuery,
    () => getWebsite(itemId, workspaceId),
    {
      enabled: queryEnabled && !!itemId,
      refetchOnWindowFocus: false,
    }
  );

  const forceOpen = website?.contactsList?.length > 0;

  return (
    <SidebarCRMSection
      extraClass="inbox-contact-section"
      ref={ref}
      index={index}
      id="contacts"
      title={title}
      onOpen={onOpenCallback}
      forceOpen={forceOpen}
      rightComponent={
        <div
          className="contact-sidebar-crm__content__add-btn"
          onClick={() => setOpenedCreatePersonSideBar(true)}
        >
          <SVGIcon icon="plusIcon" size={10} color="#221CB6" />
          Add
        </div>
      }
    >
      <div>
        {website?.contactsList.length === 0 ? (
          <div className="contacts-sidebar-section-metrics--empty">Empty</div>
        ) : (
          website?.contactsList
            ?.slice(0, isExpanded ? undefined : 3)
            ?.map((contact) => (
              <SidebarCRMSectionPerson
                key={contact.id}
                contact={contact as any}
                updateContact={updateContact}
                removeContact={removeContact}
                saveEmail={saveEmail}
                removeEmail={removeEmail}
                isRemovable={website?.contactsList?.length > 1}
              />
            ))
        )}
        {!isExpanded && website?.contactsList?.length > 3 && (
          <div className="contact-sidebar-crm__show-more-btn" onClick={() => setIsExpanded(true)}>
            Show More
            <SVGIcon icon="chevron" size={8} />
          </div>
        )}
      </div>
      {!isOpenedCreatePersonSideBar ? null : (
        <div className="relationships-organizations__person-info-sidebar">
          <RelationshipsCreatePersonSidebar
            website={website}
            onUpdateContactInWebsite={(contact) => {
              onCreatedContact(contact);
              setOpenedCreatePersonSideBar(false);
            }}
            onClose={() => setOpenedCreatePersonSideBar(false)}
          />
        </div>
      )}
    </SidebarCRMSection>
  );
}

export default SidebarCRMSectionContact;
