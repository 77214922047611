import React from 'react';

import cn from 'class-names';

import SearchTypesTabItem from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/SearchTypesTabItem/SearchTypesTabItem';

import { SearchModeType } from '@ts/automationContent.types';

import config from './config';

import './SearchTypesTab.scss';

const SearchTypesTab = ({
  onChangeActiveTabIndex,
  activeTabIndex,
  isBlocked = false,
  isHighlighted = -1,
}: {
  activeTabIndex: number;
  onChangeActiveTabIndex: (number: SearchModeType) => void;
  isBlocked?: boolean;
  isHighlighted?: number;
}): JSX.Element => {
  const cnSearchTypes = cn('search-types-tab', {
    'search-types-tab--blocked': isBlocked,
  });

  const isBlurredItem = (value: number): boolean => {
    if (isHighlighted === -1) {
      return false;
    }

    return isHighlighted !== value;
  };

  return (
    <div className={cnSearchTypes}>
      {config.map(({ title, icon, value }, index) => {
        // We use index as key, because we will not change array
        return (
          <SearchTypesTabItem
            onClick={() => onChangeActiveTabIndex(value)}
            isActive={activeTabIndex === value}
            title={title}
            icon={icon}
            key={value}
            isBlurred={isBlurredItem(value)}
          />
        );
      })}
    </div>
  );
};

export default SearchTypesTab;
