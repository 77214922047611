// @ts-nocheck
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getCurrentWorkspaceId } from 'src/redux/selectors/workspaces.selectors';

import { HaroFilterPropsType } from '@ts/props.types';

import { createHaroFilter, updateHaroFilter } from '@redux/thunks/inquries.thunks';
import { HaroFilterType } from '@ts/haro.types';
import { getFilterDetailsApi } from '@api/haro.api';
import loadingStatuses from '@constants/loadingStatuses';
import Loader from '@uikit/Loader/Loader';
import { checkIsAnyNotLoaded } from '@utils/loadingChecks';
import { transformWorkspaceMemberToOptions } from '@mappers/optionTransformers';
import useWorkspaceMembers from '@hooks/useWorkspaceMembers';

import urls from '@constants/urls';
import { addNotification } from '@redux/actions/notifications.actions';
import { QueryCollectionHookType } from '@ts/common.types';
import { MailboxAccountType } from '@ts/mailboxAccounts.types';
import useMailboxAccounts from '@hooks/useMailboxAccounts';
import FilterCreationBioStep from './_components/FilterCreationBioStep/FilterCreationBioStep';
import FilterCreationKeywordsStep from './_components/FilterCreationKeywordsStep/FilterCreationKeywordsStep';
import FilterCreationSettingsStep from './_components/FilterCreationSettingsStep/FilterCreationSettingsStep';
import FilterCreationSteps from './_components/FilterCreationSteps/FilterCreationSteps';

const filterDefaultFields: HaroFilterPropsType = {
  title: '',
  removeAnonymous: true,
  domainRating: {
    min: 0,
    max: 100,
  },
  sendNotificationsTo: [],
  keywords: [],
  bioCompanyName: '',
  bioFullName: '',
  bioCompanyWebsite: '',
  bioHeadshot: '',
  bioJobTitle: '',
  bioLinkedInLink: '',
  bioOnlineBio: '',
  bioPhoneNumber: '',
  bioPressKitLink: '',
  bioTwitterLink: '',
};

function FilterCreation({
  match: {
    params: { filterId },
  },
}: {
  match: {
    params: { filterId?: string };
  };
}): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const curWorkspaceId = useSelector(getCurrentWorkspaceId);
  const [activeStepIndex, setActiveStepIndex] = useState(1);
  const [filterFields, changeFilterFields] = useState<HaroFilterPropsType>(filterDefaultFields);
  const [filterDetailsLs, changeFilterDetailsLs] = useState(loadingStatuses.PENDING);
  const [filterDetails, changeFilterDetails] = useState<HaroFilterType | null>(null);
  const { items: mbAccounts }: QueryCollectionHookType<MailboxAccountType> =
    useMailboxAccounts(curWorkspaceId);
  const { members: workspaceMembers, isFetching: isWorkspaceMembersFetching } =
    useWorkspaceMembers();

  const handleError = useCallback(
    (err) => {
      const jsonErrorMessage = JSON.parse(err.message);
      if (jsonErrorMessage.code === 2000) {
        history.push(urls.INQUIRIES_FILTERS);
        dispatch(addNotification({ title: jsonErrorMessage.msg, type: 'warning' }));
      }
    },
    [history]
  );

  useEffect(() => {
    if (filterDetails && mbAccounts?.length > 0) {
      const mbSettedAccount = mbAccounts.find(({ id }) => id === filterDetails.mailboxAccountId);
      const settedNotificationList = workspaceMembers.filter(({ userId: mailboxUserId }) =>
        filterDetails.usersForNotificationList.some(({ userId }) => userId === mailboxUserId)
      );

      changeFilterFields({
        title: filterDetails.title,
        removeAnonymous: filterDetails.removeAnonymous,
        domainRating: {
          min: filterDetails.domainRatingMin,
          max: filterDetails.domainRatingMax,
        },
        pitchEmailFrom: {
          ...mbSettedAccount,
          label: mbSettedAccount?.email || '-',
          value: mbSettedAccount?.id || -1,
        },
        sendNotificationsTo: transformWorkspaceMemberToOptions(settedNotificationList),
        keywords: filterDetails.keywordsList,
        bioFullName: filterDetails.bioFullName,
        bioPressKitLink: filterDetails.bioPressKitLink,
        bioOnlineBio: filterDetails.bioOnlineBio,
        bioPhoneNumber: filterDetails.bioPhoneNumber,
        bioTwitterLink: filterDetails.bioTwitterLink,
        bioLinkedInLink: filterDetails.bioLinkedInLink,
        bioHeadshot: filterDetails.bioHeadshot,
        bioCompanyWebsite: filterDetails.bioCompanyWebsite,
        bioCompanyName: filterDetails.bioCompanyName,
        bioJobTitle: filterDetails.bioJobTitle,
      });
    }
  }, [filterId, filterDetails, workspaceMembers, mbAccounts]);

  useEffect(() => {
    if (curWorkspaceId && filterId) {
      changeFilterDetailsLs(loadingStatuses.INITED);
      getFilterDetailsApi(curWorkspaceId, Number(filterId))
        .then(changeFilterDetails)
        .catch(handleError)
        .finally(() => {
          changeFilterDetailsLs(loadingStatuses.LOADED);
        });
    }
  }, [curWorkspaceId, filterId]);

  const handleFieldChange = (fieldName, value) =>
    changeFilterFields((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));

  const handleContinueClick = () => setActiveStepIndex((prevIndex) => prevIndex + 1);
  const handleBackClick = () => setActiveStepIndex((prevIndex) => prevIndex - 1);

  const handleCreateFilter = () => {
    if (filterId) {
      dispatch(
        updateHaroFilter(curWorkspaceId, {
          ...filterFields,
          id: Number(filterId),
        })
      ).then(({ id }) => {
        history.push(
          `/inquiries/haro-reminder?filterId=${id}&email=${filterFields.pitchEmailFrom.label}`
        );
      });
    } else {
      dispatch(createHaroFilter(curWorkspaceId, filterFields)).then(({ id }) => {
        history.push(
          `/inquiries/haro-reminder?filterId=${id}&email=${filterFields.pitchEmailFrom.label}`
        );
      });
    }
  };

  return (
    <div>
      <Loader isLoading={Boolean(filterId) && checkIsAnyNotLoaded(filterDetailsLs)} withTopMargin>
        <FilterCreationSteps
          activeStepIndex={activeStepIndex}
          setActiveStepIndex={setActiveStepIndex}
          stepsDisabled={!filterFields.title || !filterFields.pitchEmailFrom}
        />

        {activeStepIndex === 1 ? (
          <FilterCreationSettingsStep
            filterFields={filterFields}
            onFieldChange={handleFieldChange}
            onContinue={handleContinueClick}
          />
        ) : null}
        {activeStepIndex === 2 ? (
          <FilterCreationKeywordsStep
            filterFields={filterFields}
            onFieldChange={handleFieldChange}
            onContinue={handleContinueClick}
            onBack={handleBackClick}
          />
        ) : null}
        {activeStepIndex === 3 ? (
          <FilterCreationBioStep
            filterFields={filterFields}
            onFieldChange={handleFieldChange}
            onBack={handleBackClick}
            onCreateFilter={handleCreateFilter}
            isNewFilter={!filterId}
          />
        ) : null}
      </Loader>
    </div>
  );
}

export default FilterCreation;
