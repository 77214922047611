import React from 'react';
import { nanoid } from 'nanoid';

export default function ({
  color = '#C4C6CD',
  size = 14,
}: {
  color?: string;
  size?: number;
}): JSX.Element {
  const id = nanoid();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.10737 1.71723C0.847022 1.45688 0.847022 1.03477 1.10737 0.774417C1.36772 0.514067 1.78983 0.514067 2.05018 0.774417L14.1492 12.8734C14.4095 13.1338 14.4095 13.5559 14.1492 13.8162C13.8888 14.0766 13.4667 14.0766 13.2064 13.8162L1.10737 1.71723Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9997 1.66683H13.9997C14.1765 1.66683 14.3461 1.73707 14.4711 1.86209C14.5961 1.98712 14.6663 2.15669 14.6663 2.3335V4.3335C14.6663 5.04074 14.3854 5.71902 13.8853 6.21911C13.3852 6.71921 12.7069 7.00016 11.9997 7.00016H10.973C10.842 7.1463 10.7011 7.28187 10.5517 7.40613L9.60694 6.46138C9.70466 6.38757 9.79771 6.3067 9.8853 6.21911C10.3854 5.71902 10.6664 5.04074 10.6664 4.3335V1.66683H5.33302V2.18746L4.01302 0.867465C4.03889 0.740097 4.10167 0.622031 4.19495 0.528758C4.31997 0.403734 4.48954 0.333496 4.66635 0.333496H11.333C11.5098 0.333496 11.6794 0.403734 11.8044 0.528758C11.9294 0.653782 11.9997 0.823352 11.9997 1.00016V1.66683ZM12.9425 5.27631C13.1925 5.02626 13.333 4.68712 13.333 4.3335V3.00016H11.9997V4.3335C11.9982 4.78797 11.9193 5.23887 11.7663 5.66683H11.9997C12.3533 5.66683 12.6924 5.52635 12.9425 5.27631Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.92677 1.66683H1.99968C1.82287 1.66683 1.6533 1.73707 1.52828 1.86209C1.40326 1.98712 1.33302 2.15669 1.33302 2.3335V4.3335C1.33302 5.04074 1.61397 5.71902 2.11407 6.21911C2.61416 6.71921 3.29244 7.00016 3.99968 7.00016H5.02635C5.62937 7.67293 6.44239 8.12173 7.33302 8.2735V9.66683H6.66635C6.13592 9.66683 5.62721 9.87754 5.25214 10.2526C4.87706 10.6277 4.66635 11.1364 4.66635 11.6668V13.0002C4.66635 13.177 4.73659 13.3465 4.86161 13.4716C4.98664 13.5966 5.15621 13.6668 5.33302 13.6668H10.6664C10.8432 13.6668 11.0127 13.5966 11.1378 13.4716C11.2628 13.3465 11.333 13.177 11.333 13.0002V11.6668C11.333 11.1364 11.1223 10.6277 10.7472 10.2526C10.3722 9.87754 9.86345 9.66683 9.33302 9.66683H8.66635V8.2735C8.90132 8.23346 9.13089 8.17274 9.35263 8.09269L8.24848 6.98854C8.16611 6.99625 8.0831 7.00016 7.99968 7.00016C7.29244 7.00016 6.61416 6.71921 6.11407 6.21911C5.61397 5.71902 5.33302 5.04074 5.33302 4.3335V4.07308L2.92677 1.66683ZM3.05687 5.27631C3.30692 5.52635 3.64606 5.66683 3.99968 5.66683H4.23302C4.08006 5.23887 4.00115 4.78797 3.99968 4.3335V3.00016H2.66635V4.3335C2.66635 4.68712 2.80683 5.02626 3.05687 5.27631ZM9.80442 11.1954C9.6794 11.0704 9.50983 11.0002 9.33302 11.0002H6.66635C6.48954 11.0002 6.31997 11.0704 6.19495 11.1954C6.06992 11.3204 5.99968 11.49 5.99968 11.6668V12.3335H9.99968V11.6668C9.99968 11.49 9.92945 11.3204 9.80442 11.1954Z"
        fill={color}
      />
    </svg>
  );
}
