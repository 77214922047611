import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';

import { CampaignTemplateDetailsResponse } from 'respona_api/generated/campaign_pb';

import { createCampaignFromTemplateApi, getCampaignTemplateDetailsApi } from '@api/campaign.api';

import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';
import useAllVariablesInEditor from '@hooks/useAllVariablesInEditor';

import { Button } from '@uikit/Button/Button';
import { SVGIcon } from '@uikit/Icon/Icon';
import Loader from '@uikit/Loader/Loader';

import CampaignTemplateEditor from '@components/CampaignTemplates/CampaignTemplate/_components/CampaignTemplateEditor/CampaignTemplateEditor';
import CampaignTemplateInput from '@components/CampaignTemplates/CampaignTemplate/_components/CampaignTemplateInput/CampaignTemplateInput';
import VideoFrame from '@components/Campaigns/Dashboard/_components/VideoFrame/VideoFrame';
import PageHeader from '@components/PageHeader/PageHeader';

import './CampaignTemplate.scss';
import { VariableType } from '@constants/variables';

declare const IS_DEV: boolean;

const varRegExp = new RegExp(/(<span[^>]+class=['"]temp-variable['"][^>]+>)(.*?)(<\/span>)/g);
const ignoredWordsRegExp = new RegExp(
  /(?:(the |a |an | and | in | at | on| of| to))*(\S{1})(\S{2,})/g
);

// const mockText = `<p><span>Hi </span><span class="variable" data-fallback="there" data-href="">first_name</span><span>,</span></p><p><span></span></p><p><span>Loved the review you did on </span><a class="link link-temp" href="@url"><span class="temp-variable" data-href="">competitor_name</span></a><span>. It’s very thorough and well-researched.</span></p><p><span> </span></p><p><span>I’m wondering if you’re open to writing a review for </span><span class="temp-variable" data-href="">company_name</span><span>?</span></p><p><span> </span></p><p><span class="temp-variable" data-href="">aspects</span></p><p><span> </span></p><p><span class="temp-variable" data-href="">incentive</span></p><p><span> </span></p><p><span>How does that sound?</span></p>`;

const replaceVarValues = (string, varValues) => {
  return string.replace(varRegExp, (str) => {
    const groups = str.match(/(<span[^>]+class=['"]temp-variable['"][^>]+>)(.*?)(<\/span>)/);

    const varValue = varValues[groups[2]];

    if (varValue == null) {
      return `<span></span>`;
    }

    const mappedVarValue = Array.isArray(varValue)
      ? varValue[0]
      : varValue.replace(/\n/g, '</span></p><p><span>');

    return `<span>${mappedVarValue}${groups[3]}`;
  });
};

function CampaignTemplate({
  match: {
    params: { templateId },
  },
}) {
  const history = useHistory();
  const location = useLocation();
  const workspaceId = useCurrentWorkspaceId();
  const { variables: editorVariables } = useAllVariablesInEditor();

  const [isCreatingCampaign, setIsCreatingCampaign] = useState<boolean>();
  const [varValues, setVarValues] = useState<{ [key: string]: string | string[] }>({});
  const [sequenceLoading, setSequenceLoading] = useState<boolean>(true);
  const [sequence, setSequence] = useState({
    subject: '',
    content: '',
  });

  const { data, isFetching } = useQuery<CampaignTemplateDetailsResponse.AsObject>(
    ['campaign-template', templateId],
    () => getCampaignTemplateDetailsApi(Number(templateId), workspaceId),
    {
      enabled: true,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const startTemplateCampaign = async () => {
    const query = new URLSearchParams(location.search);
    const folderId = query.get('folderId');
    setIsCreatingCampaign(true);
    try {
      const res = await createCampaignFromTemplateApi(
        templateId,
        workspaceId,
        data?.sequenceStepsList
          ?.sort((a, b) => a.order - b.order)
          ?.map((item, index) => ({
            ...item,
            threadA: {
              ...item.threadA,
              subject: replaceVarValues(
                index === 0 ? sequence.subject : item.threadA.subject,
                varValues
              ),
              content: replaceVarValues(
                index === 0 ? sequence.content : item.threadA.content,
                varValues
              ),
            },
          })) || [],
        Object.entries(varValues).map(([key, value]) => ({
          value: Array.isArray(value) ? value.join(', ') : value,
          type: data?.inputsList?.find((item) => item.variable === key)?.type,
          variable: key,
          optional: data?.inputsList?.find((item) => item.variable === key)?.optional,
        })),
        folderId?.length > 0 ? Number(folderId) : null
      );
      history.push(`/campaign-builder/${res.id}/opportunities?newCampaign=true`);
    } catch (err) {
      console.error(err);
    } finally {
      setIsCreatingCampaign(false);
    }
  };

  useEffect(() => {
    if (data?.inputsList?.length) {
      setVarValues(
        data?.inputsList?.reduce(
          (acc, item) => ({
            ...acc,
            [item.variable]: '',
          }),
          {}
        )
      );
    }

    if (data?.sequenceStepsList?.length) {
      setSequence({
        subject: data.sequenceStepsList.find((item) => item.order === 1)?.threadA?.subject,
        // content: mockText,
        content: data.sequenceStepsList.find((item) => item.order === 1)?.threadA?.content,
      });

      setSequenceLoading(false);
    }
  }, [data]);

  return (
    <div className="campaign-template__container">
      <PageHeader
        withBackArrow
        title={data?.title || 'Loading...'}
        howToLink={data?.howToUseLink}
        renderHeaderActions={() => (
          <div className="campaign-template__header">
            <Button
              isLoading={isCreatingCampaign}
              loadingText="Starting campaign..."
              className="campaigns-header__button"
              onClick={startTemplateCampaign}
              disabled={
                !Object.values(varValues).length ||
                !Object.entries(varValues).every(
                  ([key, value]) =>
                    Boolean(value?.length) ||
                    data.inputsList.find((item) => item.variable === key).optional
                )
              }
            >
              <span>Use this template</span>
              <SVGIcon icon="arrowRightClassic" size={10} color="#ffffff" />
            </Button>
          </div>
        )}
      />

      <Loader isLoading={isFetching} withTopMargin>
        <div className="campaign-template">
          <div
            style={{
              display: 'flex',
              height: '100%',
              overflow: 'auto',
              backgroundColor: '#f2f2f2',
            }}
          >
            <div className="campaign-template__sidebar">
              {!IS_DEV && data?.videoLink ? (
                <VideoFrame
                  wrapperClassName="campaign-template__sidebar-video"
                  width={468}
                  height={263}
                  link={data?.videoLink}
                />
              ) : (
                <img src={require('./_assets/placeholder.png').default} alt="placeholder" />
              )}

              <div className="campaign-template__sidebar-description">{data?.description}</div>

              <div className="campaign-template__sidebar-sequence">
                <div className="campaign-template__sidebar-sequence-label">Email template:</div>
                <Loader isLoading={sequenceLoading} withTopMargin>
                  <CampaignTemplateEditor
                    sequenceStep={{
                      order: 1,
                      isSameThread: false,
                      attachmentsList: [],
                      ...sequence,
                    }}
                    variables={
                      data?.inputsList?.map(
                        (item) =>
                          ({
                            title: item.title,
                            name: item.variable,
                            value: Array.isArray(varValues[item.variable])
                              ? varValues[item.variable][0] || ''
                              : (varValues[item.variable] as string),
                            withFallback: false,
                          }) as VariableType
                      ) || []
                    }
                    editorVariables={editorVariables}
                    setSequence={setSequence}
                  />
                </Loader>
              </div>
            </div>
          </div>

          <div className="campaign-template__content--container">
            <div className="campaign-template__content">
              {data?.inputsList
                ?.sort((a, b) => a.order - b.order)
                ?.map((input) => (
                  <CampaignTemplateInput
                    key={input.order}
                    templateId={data.id}
                    title={input.title}
                    order={input.order}
                    type={input.type}
                    placeholder={input.example}
                    preset={input.preset}
                    variableName={input.variable}
                    helpText={input.description}
                    maxLines={input.linesLimit}
                    maxCharacters={input.linesCharLimit}
                    autoPopulateSource={input.autoPopulateSource}
                    autoPopulateButtonEnabled={input.autoPopulateButtonEnabled}
                    optional={input.optional}
                    globalVarValues={varValues}
                    onChange={setVarValues}
                    aiSuggestionsEnabled={input.aiSuggestionEnabled}
                    aiSuggestionPrompt={input.aiSuggestionPrompt}
                    aiSuggestionPreset={data.aiSuggestionPromptPresetsList}
                  />
                ))}
            </div>
          </div>
        </div>
      </Loader>
    </div>
  );
}

export default CampaignTemplate;
