/* global API_HOST */
import { insertImage, insertImagePlaceholder } from '@uikit/RichEditor/utils/imageUtils';
import { uploadImageApi } from '@api/fileImport.api';

const withEditorImages = (editor) => {
  const { insertData, isVoid } = editor;

  // eslint-disable-next-line no-param-reassign
  editor.isVoid = (element) => {
    return element.type === 'image' ? true : isVoid(element);
  };

  // eslint-disable-next-line no-param-reassign
  editor.insertData = (data) => {
    insertData(data);
    return;
    const { files } = data;

    if (files && files.length > 0) {
      for (const file of files) {
        files[0]
          .arrayBuffer()
          .then((buffer) => {
            const { selection } = editor;
            insertImagePlaceholder(editor, selection);

            return uploadImageApi(files[0], buffer).then(({ uid }) => ({
              uid,
              selection,
            }));
          })
          .then(({ uid, selection }) => {
            // @ts-ignore
            insertImage(editor, `${BACK_HOST}/mails/api/images/${uid}`, null, selection);
          });
      }
    } else {
      insertData(data);
    }
  };

  return editor;
};

export default withEditorImages;
