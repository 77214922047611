import React from 'react';

export default ({ color = '#202430', size = 20 }): JSX.Element => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="9.64917"
      cy="6.06519"
      rx="3.98169"
      ry="3.98169"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.33327 15.5846C3.3322 15.3047 3.3948 15.0282 3.51633 14.7761C3.89772 14.0133 4.97323 13.609 5.86568 13.426C6.50932 13.2886 7.16184 13.1969 7.81839 13.1514C9.03395 13.0446 10.2565 13.0446 11.4721 13.1514C12.1286 13.1974 12.7811 13.2891 13.4248 13.426C14.3172 13.609 15.3928 13.9752 15.7741 14.7761C16.0186 15.2901 16.0186 15.8868 15.7741 16.4008C15.3928 17.2017 14.3172 17.5678 13.4248 17.7433C12.7819 17.8863 12.1292 17.9807 11.4721 18.0255C10.4827 18.1094 9.48874 18.1247 8.49726 18.0713C8.26843 18.0713 8.04722 18.0713 7.81839 18.0255C7.16378 17.9812 6.51352 17.8869 5.87331 17.7433C4.97323 17.5678 3.90535 17.2017 3.51633 16.4008C3.39542 16.1457 3.33288 15.8669 3.33327 15.5846Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
