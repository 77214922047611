import React from 'react';

import { components, OptionProps } from 'react-select';

import Select from 'src/uikit/Select/Select';
import { sendingStrategyOptions } from 'src/constants/options';
import QuestionTooltip from '../_components/QuestionTooltip/QuestionTooltip';

export const Option = (props: OptionProps): JSX.Element => {
  // eslint-disable-next-line react/destructuring-assignment
  const { tooltipText, label } = props.data;
  return (
    <components.Option {...props}>
      {tooltipText ? (
        <QuestionTooltip
          style={{ marginRight: '8px' }}
          text={tooltipText}
          color="#FFFFFF"
          offset={{ left: 115 }}
        />
      ) : null}
      <div className="preparing-form__sending-strategy-option">{label}</div>
    </components.Option>
  );
};

export default {
  type: 'select',
  label: 'Sending strategy',
  key: 'sendingStrategy',
  Renderer: (props: { [key: string]: any }): JSX.Element => (
    <Select {...props} options={sendingStrategyOptions} additionalComponents={{ Option }} />
  ),
};
