import {
  CustomVariableValueRelatedToMap,
  CustomVariableValueSelect,
} from 'respona_api/generated/common_pb';
import * as common_pb from 'respona_api/generated/common_pb';
import {
  CustomAiVariable,
  CustomSelectVariable,
  CustomVariablePlaceholderType,
  CustomVariableValueType,
} from 'src/types/customVariales.types';

import request from '@api/base/request';
import streamRequest from '@api/base/streamRequest';
import peopleService from '@api/services/peopleService';

import PeopleModels, {
  CustomVariableCreateRequest,
  CustomVariableRequest,
  CustomVariableUpdateRequest,
  OpenAICustomVariableSettings,
  SelectCustomVariableSettings,
} from 'respona_api/generated/people_pb';

export const getAllCustomVariablesOfWorkspaceApi = (
  workspaceId: number,
  relation: CustomVariableValueRelatedToMap[keyof CustomVariableValueRelatedToMap] | null = null
): Promise<CustomVariablePlaceholderType[]> => {
  const client = peopleService.getInstance();
  const variableRequest = new CustomVariableRequest();

  if (relation != null) {
    variableRequest.setRelatedTo(relation);
  }
  variableRequest.setWorkspaceId(workspaceId);

  return streamRequest<CustomVariablePlaceholderType[]>(
    client,
    relation === null ? client.getAllCustomVariables : client.getAllCustomVariablesByType,
    variableRequest
  );
};

export const createCustomVariableApi = (
  type: common_pb.CustomVariableTypeMap[keyof common_pb.CustomVariableTypeMap],
  title: string,
  workspaceId: number,
  description: string,
  relatedTo: CustomVariableValueRelatedToMap[keyof CustomVariableValueRelatedToMap],
  aiSettings: CustomAiVariable,
  selectSettings: CustomSelectVariable
): Promise<CustomVariablePlaceholderType> => {
  const client = peopleService.getInstance();
  const customVariable = new CustomVariableCreateRequest();

  customVariable.setType(type);
  customVariable.setTitle(title);
  customVariable.setDescription(description);
  customVariable.setWorkspaceId(workspaceId);
  customVariable.setRelatedTo(relatedTo);

  if (type === common_pb.CustomVariableType.OPEN_AI) {
    const optSettings = new OpenAICustomVariableSettings();
    optSettings.setTone(aiSettings.tone);
    optSettings.setPrompt(aiSettings.prompt);
    optSettings.setExamples(aiSettings.examples);
    optSettings.setOutputLengthFrom(aiSettings.outputLengthFrom);
    optSettings.setOutputLengthTo(aiSettings.outputLengthTo);
    customVariable.setOpenAi(optSettings);
  } else if (
    type === common_pb.CustomVariableType.MULTI_SELECT ||
    type === common_pb.CustomVariableType.SELECT
  ) {
    const optSettings = new SelectCustomVariableSettings();
    optSettings.setMultiSelect(type === common_pb.CustomVariableType.MULTI_SELECT);
    optSettings.setItemsList(
      selectSettings.itemsList.map((it) => {
        const item = new SelectCustomVariableSettings.SelectItem();
        item.setUid(it.uid);
        item.setName(it.name);
        item.setOrder(it.order);
        item.setColor(it.color);
        return item;
      })
    );
    customVariable.setSelect(optSettings);
  }

  return request<CustomVariablePlaceholderType>(
    client,
    client.createCustomVariable,
    customVariable
  );
};

export const updateCustomVariableApi = (
  id: number,
  title: string,
  description: string,
  relatedTo: CustomVariableValueRelatedToMap[keyof CustomVariableValueRelatedToMap],
  type: common_pb.CustomVariableTypeMap[keyof common_pb.CustomVariableTypeMap],
  settings: CustomAiVariable,
  selectSettings: CustomSelectVariable
): Promise<CustomVariablePlaceholderType> => {
  const client = peopleService.getInstance();
  const customVariable = new CustomVariableUpdateRequest();

  customVariable.setId(id);
  customVariable.setTitle(title);
  customVariable.setDescription(description);
  customVariable.setRelatedTo(relatedTo);
  customVariable.setType(type);

  if (type === common_pb.CustomVariableType.OPEN_AI) {
    const optSettings = new OpenAICustomVariableSettings();
    optSettings.setTone(settings.tone);
    optSettings.setPrompt(settings.prompt);
    optSettings.setExamples(settings.examples);
    optSettings.setOutputLengthFrom(settings.outputLengthFrom);
    optSettings.setOutputLengthTo(settings.outputLengthTo);
    customVariable.setOpenAi(optSettings);
  } else if (
    type === common_pb.CustomVariableType.MULTI_SELECT ||
    type === common_pb.CustomVariableType.SELECT
  ) {
    const optSettings = new SelectCustomVariableSettings();
    optSettings.setMultiSelect(selectSettings.multi);
    optSettings.setItemsList(
      selectSettings.itemsList.map((it) => {
        const item = new SelectCustomVariableSettings.SelectItem();
        item.setUid(it.uid);
        item.setName(it.name);
        item.setOrder(it.order);
        item.setColor(it.color);
        return item;
      })
    );
    customVariable.setSelect(optSettings);
  }

  return request<CustomVariablePlaceholderType>(
    client,
    client.updateCustomVariable,
    customVariable
  );
};

export const removeCustomVariableApi = (id: number, workspaceId: number): Promise<void> => {
  const client = peopleService.getInstance();
  const customVariable = new common_pb.IdRequest();

  customVariable.setId(id);
  customVariable.setOptionalWorkspaceId(workspaceId);

  return request<void>(client, client.removeCustomVariable, customVariable);
};

export const createOrUpdateCustomVariableValueApi = (
  value: CustomVariableValueType
): Promise<void> => {
  const client = peopleService.getInstance();
  const variableRequest = new PeopleModels.CustomVariableValueCreateRequest();

  const customVariable = new common_pb.CustomVariableValue();
  customVariable.setVariableId(value.variableId);
  customVariable.setVariableName(value.variableName);
  customVariable.setType(value.type);
  customVariable.setOptCompanionId(value.optCompanionId);
  if (value.stringValue != null && value.stringValue.length > 0) {
    customVariable.setStringValue(value.stringValue);
  }
  if (value.selectValue != null) {
    const selectReq = new CustomVariableValueSelect();
    selectReq.setValuesList(
      value.selectValue.valuesList.map((it) => {
        const item = new CustomVariableValueSelect.SelectItemValue();
        item.setValue(it.value);
        item.setUid(it.uid);
        return item;
      })
    );
    customVariable.setSelectValue(selectReq);
  }
  customVariable.setRelatedId(value.relatedId);
  customVariable.setRelatedTo(value.relatedTo);

  variableRequest.setItem(customVariable);
  variableRequest.setRelatedid(value.relatedId);
  variableRequest.setRelatedto(value.relatedTo);

  return request<void>(client, client.createCustomVariableValue, variableRequest);
};

export const getCustomVariableValuesApi = (
  variables: string[],
  ids: number[]
): Promise<CustomVariableValueType[]> => {
  const client = peopleService.getInstance();
  const customVariablesValues = new PeopleModels.PersonCustomVariableValueRequest();

  customVariablesValues.setVariableNamesList(variables);
  customVariablesValues.setRelatedIdsList(ids);

  return streamRequest<CustomVariableValueType[]>(
    client,
    client.getCustomVariableValues,
    customVariablesValues
  );
};
