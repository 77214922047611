// package: campaigns
// file: pipeline.proto

var pipeline_pb = require("./pipeline_pb");
var common_pb = require("./common_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var PipelineService = (function () {
  function PipelineService() {}
  PipelineService.serviceName = "campaigns.PipelineService";
  return PipelineService;
}());

PipelineService.getCampaignPipelines = {
  methodName: "getCampaignPipelines",
  service: PipelineService,
  requestStream: false,
  responseStream: true,
  requestType: common_pb.IdRequest,
  responseType: pipeline_pb.CampaignPipelineResponse
};

PipelineService.getCampaignPipelineProgress = {
  methodName: "getCampaignPipelineProgress",
  service: PipelineService,
  requestStream: false,
  responseStream: true,
  requestType: common_pb.IdRequest,
  responseType: pipeline_pb.CampaignPipelineResponse
};

PipelineService.createCampaignPipeline = {
  methodName: "createCampaignPipeline",
  service: PipelineService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: pipeline_pb.CampaignPipelineResponse
};

PipelineService.updateCampaignPipeline = {
  methodName: "updateCampaignPipeline",
  service: PipelineService,
  requestStream: false,
  responseStream: false,
  requestType: pipeline_pb.LaunchCampaignPipelineRequest,
  responseType: pipeline_pb.CampaignPipelineResponse
};

PipelineService.cancelCampaignPipeline = {
  methodName: "cancelCampaignPipeline",
  service: PipelineService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: pipeline_pb.CampaignPipelineResponse
};

PipelineService.deleteCampaignPipeline = {
  methodName: "deleteCampaignPipeline",
  service: PipelineService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: google_protobuf_empty_pb.Empty
};

PipelineService.launchCampaignPipeline = {
  methodName: "launchCampaignPipeline",
  service: PipelineService,
  requestStream: false,
  responseStream: false,
  requestType: pipeline_pb.LaunchCampaignPipelineRequest,
  responseType: pipeline_pb.CampaignPipelineResponse
};

exports.PipelineService = PipelineService;

function PipelineServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

PipelineServiceClient.prototype.getCampaignPipelines = function getCampaignPipelines(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(PipelineService.getCampaignPipelines, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

PipelineServiceClient.prototype.getCampaignPipelineProgress = function getCampaignPipelineProgress(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(PipelineService.getCampaignPipelineProgress, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

PipelineServiceClient.prototype.createCampaignPipeline = function createCampaignPipeline(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PipelineService.createCampaignPipeline, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PipelineServiceClient.prototype.updateCampaignPipeline = function updateCampaignPipeline(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PipelineService.updateCampaignPipeline, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PipelineServiceClient.prototype.cancelCampaignPipeline = function cancelCampaignPipeline(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PipelineService.cancelCampaignPipeline, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PipelineServiceClient.prototype.deleteCampaignPipeline = function deleteCampaignPipeline(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PipelineService.deleteCampaignPipeline, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PipelineServiceClient.prototype.launchCampaignPipeline = function launchCampaignPipeline(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PipelineService.launchCampaignPipeline, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.PipelineServiceClient = PipelineServiceClient;

