import { monthsShort, twoNumFormat } from '@utils/date';

export const MILLISECOND_IN_MINUTE = 60000;
export const MILLISECOND_IN_HOUR = MILLISECOND_IN_MINUTE * 60;
export const MILLISECOND_IN_DAY = MILLISECOND_IN_HOUR * 24;

const timeFormat = {
  short: {
    minutes: 'M',
    hours: 'H',
    days: 'D',
  },
  long: {
    minutes: 'Minutes',
    hours: 'Hours',
    days: 'Days',
  },
};

const getTimeWithSign = (sign: number, timeDiff: string): string => {
  if (sign < 1) {
    return `${timeDiff} ago`;
  }

  return timeDiff;
};

export default (time: number, format: 'long' | 'short' = 'short'): string => {
  const curTimestamp = Date.now();
  const timeSign = Math.sign(time - curTimestamp);
  const timeDiff = Math.abs(time - curTimestamp);

  if (timeDiff > MILLISECOND_IN_DAY) {
    return getTimeWithSign(
      timeSign,
      `${Math.floor(timeDiff / MILLISECOND_IN_DAY)}  ${timeFormat[format].days}`
    );
  }

  if (timeDiff > MILLISECOND_IN_HOUR) {
    return getTimeWithSign(
      timeSign,
      `${Math.floor(timeDiff / MILLISECOND_IN_HOUR)}  ${timeFormat[format].hours}`
    );
  }

  return getTimeWithSign(
    timeSign,
    `${Math.floor(timeDiff / MILLISECOND_IN_MINUTE)} ${timeFormat[format].minutes}`
  );
};

export const getTimeDifference = (time: number, unit: 'd' | 'h' | 'm' = 'd'): number => {
  const curTimestamp = Date.now();
  const timeDiff = time - curTimestamp;

  const getDelimiter = () => {
    switch (unit) {
      case 'd':
        return MILLISECOND_IN_DAY;
      case 'h':
        return MILLISECOND_IN_HOUR;
      case 'm':
        return MILLISECOND_IN_MINUTE;
      default:
        return MILLISECOND_IN_DAY;
    }
  };

  return Math.round(timeDiff / getDelimiter());
};

/*
  It gives us:
  Minutes if < 60 minutes
  Hours if < 24 hours
  Days if > 24 hours
 */
export const getApproximateTimePeriod = (time: number, showNumber = false): string => {
  const curTimestamp = Date.now();
  const timeDiff = Math.abs(time - curTimestamp);

  if (timeDiff < MILLISECOND_IN_HOUR) {
    return showNumber ? `${Math.round(timeDiff / MILLISECOND_IN_MINUTE)} Minutes` : 'Minutes';
  }

  if (timeDiff < MILLISECOND_IN_DAY) {
    return showNumber ? `${Math.round(timeDiff / MILLISECOND_IN_HOUR)} Hours` : 'Hours';
  }

  return showNumber ? `${Math.round(timeDiff / MILLISECOND_IN_DAY)} Days` : 'Days';
};

export const getFormattedDate = (time: number) => {
  const timeDateObj = new Date(time);
  const months = twoNumFormat(timeDateObj.getMonth() + 1);
  const days = twoNumFormat(timeDateObj.getDate());
  const year = timeDateObj.getFullYear();
  const now = new Date();

  if (new Date(time).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)) {
    const ampm = timeDateObj.getHours() >= 12 ? 'pm' : 'am';
    let hours = timeDateObj.getHours() % 12;
    const minutes = timeDateObj.getMinutes();
    const formattedMinutes = minutes >= 10 ? minutes : `0${minutes}`;

    if (hours === 0) {
      hours = 12;
    }

    return `${hours}:${formattedMinutes}${ampm}`;
  } else if (timeDateObj.getMonth() === now.getMonth() && year === now.getFullYear()) {
    return `${monthsShort[timeDateObj.getMonth()]} ${days}`;
  }

  return `${months}/${days}/${year}`; // For example: 01/23/2021
}
