import React, { useCallback, useRef, useState, useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { DispatchType } from 'src/store';
import useWorkspaceMembers from '@hooks/useWorkspaceMembers';
import { addNotification } from '@redux/actions/notifications.actions';

import Loader from '@uikit/Loader/Loader';
import { SVGIcon } from '@uikit/Icon/Icon';
import SidebarCRMSection from '@components/SidebarCRM/sections/SidebarCRMSection/SidebarCRMSection';
import NoteEditor from '@components/SidebarCRM/sections/SidebarCRMSectionNotes/_components/NoteEditor';
import NotePreview from '@components/SidebarCRM/sections/SidebarCRMSectionNotes/_components/NotePreview';

import { NoteType } from '@ts/common.types';

import './SidebarCRMSectionNotes.scss';

function SidebarCRMSectionNotes({
  queryKey,
  index,
  itemId,
  getNote,
  addNote,
  updateNote,
  removeNote,
}: {
  queryKey: string;
  index: number;
  itemId: number;
  getNote: (itemId: number) => Promise<NoteType[]>;
  addNote: (content: string, itemId: number) => Promise<NoteType[]>;
  updateNote: (noteId: number, content: string, itemId: number) => Promise<NoteType[]>;
  removeNote: (noteId: number, itemId: number) => Promise<NoteType[]>;
}) {
  const ref = useRef(null);
  const dispatch = useDispatch<DispatchType>();
  const queryClient = useQueryClient();
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [queryEnabled, setQueryEnabled] = useState<boolean>(true);

  const { data, isLoading } = useQuery<NoteType[]>(
    [queryKey, itemId],
    () =>
      getNote(itemId).then((notesArray: NoteType[]) => notesArray.sort((a, b) => a.createdAt - b.createdAt)),
    {
      enabled: !!itemId && queryEnabled,
      refetchOnWindowFocus: false,
      onSettled: (data) => !!data?.length && ref.current.setOpen(true),
    }
  );

  const { members } = useWorkspaceMembers(!itemId || !queryEnabled);

  useEffect(() => {
    setQueryEnabled(!!itemId);
  }, [itemId]);

  const handleAddNote = useCallback(
    (value: string) => {
      addNote(value, itemId).then((newNote) => {
        setIsCreating(false);
        queryClient.setQueryData([queryKey, itemId], (prevNotes: NoteType[]) => [newNote, ...(prevNotes || [])]);
        dispatch(addNotification({ title: 'Note added', type: 'success' }));
      });
    },
    [itemId, queryClient]
  );

  const handleUpdateNote = useCallback(
    (newValue: string, noteId: number): Promise<void> => {
      return updateNote(noteId, newValue, itemId).then(() => {
        queryClient.setQueryData([queryKey, itemId], (prevNotes: NoteType[]) =>
          prevNotes.map((note) => (note.id === noteId ? { ...note, content: newValue } : note))
        );
        dispatch(addNotification({ title: 'Note updated', type: 'success' }));
      });
    },
    [itemId, dispatch, queryClient]
  );

  const handleRemoveNote = useCallback(
    (noteId: number): Promise<void> => {
      return removeNote(noteId, itemId).then(() => {
        queryClient.setQueryData([queryKey, itemId], (prevNotes: NoteType[]) =>
          prevNotes.filter(({ id }) => id !== noteId)
        );
        dispatch(addNotification({ title: 'Note removed', type: 'success' }));
      });
    },
    [itemId, queryClient]
  );

  const onOpenCallback = useCallback(() => {
    setQueryEnabled(true);
  }, []);

  return (
    <SidebarCRMSection
      ref={ref}
      index={index}
      id="notes"
      title="Notes"
      onOpen={onOpenCallback}
      rightComponent={
        <div
          className="contact-sidebar-crm__content__add-btn"
          onClick={() => {
            ref.current.setOpen(true);
            setIsCreating(true);
          }}
        >
          <SVGIcon icon="plusIcon" size={10} color="#221CB6" />
          Add
        </div>
      }
    >
      <div className="contacts-sidebar-section-notes__container">
        {isCreating && <NoteEditor onSave={handleAddNote} onClose={() => setIsCreating(false)} />}
        <Loader isLoading={isLoading} withTopMargin>
          {data?.length
            ? data.map((note) => (
                <NotePreview
                  key={note.id}
                  note={note}
                  members={members}
                  onSave={handleUpdateNote}
                  onRemove={handleRemoveNote}
                />
              ))
            : null}
          {!data?.length && !isCreating ? (
            <div className="contacts-sidebar-section-notes__container--empty">Empty</div>
          ) : null}
        </Loader>
      </div>
    </SidebarCRMSection>
  );
}

export default SidebarCRMSectionNotes;
