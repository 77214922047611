import { StateType } from '@redux/reducers';

import {
  AhrefMetricsType,
  AutomationSearchSubType,
  BacklinksSearchType,
  KeywordsSearchType,
  ListennotesMetricsType,
  MozMetricsType,
  PodcastSearchType,
  SearchModeType,
  SemrushMetricsType,
} from '@ts/automationContent.types';
import {
  ContentAutomationSearchingMode,
  ContentAutomationSearchingModeMap,
  KeywordSearchSource,
  KeywordSearchSourceMap,
  PodcastSearchSource,
  PodcastSearchSourceMap,
} from 'respona_api/generated/automation-content_pb';
import { BacklinksSearchSource, ContentMetricsTypeMap } from 'respona_api/generated/common_pb';
import * as common_pb from 'respona_api/generated/common_pb';
import {
  ContentAutomationMetricsType,
  SearchTypeConfigType,
} from '@redux/reducers/automationContent.reducer';
import {
  setAhrefsBacklinksConfig,
  setBingNewsConfig,
  setBingSearchConfig,
  setEpisodesConfig,
  setGoogleNewsConfig,
  setGoogleSearchConfig,
  setMozBacklinksConfig,
  setPodcastsConfig,
  setResponaBacklinksConfig,
  setSemrushBacklinksConfig,
} from '@redux/actions/automationContent.actions';

export const searchModeSelector = ({ automationContent }: StateType): SearchModeType =>
  automationContent.searchMode;

export const automationSubTypeSelector = ({
  automationContent,
}: StateType): AutomationSearchSubType => automationContent.automationSubType;

export const configSelector = ({
  automationContent,
}: StateType): SearchTypeConfigType &
  ContentAutomationMetricsType & {
    metricsType: ContentMetricsTypeMap[keyof ContentMetricsTypeMap];
  } => automationContent.config;

export const automationContentData =
  (
    searchMode: ContentAutomationSearchingModeMap[keyof ContentAutomationSearchingModeMap],
    source:
      | KeywordSearchSourceMap[keyof KeywordSearchSourceMap]
      | PodcastSearchSourceMap[keyof PodcastSearchSourceMap]
      | common_pb.BacklinksSearchSourceMap[keyof common_pb.BacklinksSearchSourceMap]
  ) =>
  ({
    automationContent,
  }: StateType): {
    data: KeywordsSearchType | BacklinksSearchType | PodcastSearchType;
    set: (
      data: Partial<KeywordsSearchType | BacklinksSearchType | PodcastSearchType>
    ) => Promise<void>;
  } => {
    switch (searchMode) {
      case ContentAutomationSearchingMode.KEYWORDS_SEARCH:
        if (source === KeywordSearchSource.GOOGLE_KEYWORD_SEARCH)
          return { data: automationContent.config.googleSearch, set: setGoogleSearchConfig };
        if (source === KeywordSearchSource.GOOGLE_NEWS_KEYWORD_SEARCH)
          return { data: automationContent.config.googleNews, set: setGoogleNewsConfig };
        if (source === KeywordSearchSource.BING_KEYWORD_SEARCH)
          return { data: automationContent.config.bingSearch, set: setBingSearchConfig };
        if (source === KeywordSearchSource.BING_NEWS_KEYWORD_SEARCH)
          return { data: automationContent.config.bingNews, set: setBingNewsConfig };

        break;

      case ContentAutomationSearchingMode.BACKLINKS_SEARCH:
        const integrationToSourceTypeMap = {
          [BacklinksSearchSource.AHREFS_BACKLINKS_SEARCH]: 1,
          [BacklinksSearchSource.SEMRUSH_BACKLINKS_SEARCH]: 2,
          [BacklinksSearchSource.MOZ_BACKLINKS_SEARCH]: 3,
          [BacklinksSearchSource.RESPONA_BACKLINKS_SEARCH]: 0,
        };

        if (source === integrationToSourceTypeMap[BacklinksSearchSource.RESPONA_BACKLINKS_SEARCH])
          return {
            data: automationContent.config.responaBacklinks,
            set: setResponaBacklinksConfig,
          };
        if (source === integrationToSourceTypeMap[BacklinksSearchSource.AHREFS_BACKLINKS_SEARCH])
          return { data: automationContent.config.ahrefBacklinks, set: setAhrefsBacklinksConfig };
        if (source === integrationToSourceTypeMap[BacklinksSearchSource.SEMRUSH_BACKLINKS_SEARCH])
          return {
            data: automationContent.config.semrushBacklinks,
            set: setSemrushBacklinksConfig,
          };
        if (source === integrationToSourceTypeMap[BacklinksSearchSource.MOZ_BACKLINKS_SEARCH])
          return { data: automationContent.config.mozBacklinks, set: setMozBacklinksConfig };
        break;

      case ContentAutomationSearchingMode.PODCAST_SEARCH:
        if (source === PodcastSearchSource.SHOWS_PODCAST_SEARCH)
          return { data: automationContent.config.podcasts, set: setPodcastsConfig };
        if (source === PodcastSearchSource.EPISODES_PODCAST_SEARCH)
          return { data: automationContent.config.episodes, set: setEpisodesConfig };
        break;

      default:
        return null;
    }
  };

export const ahrefsMetricsSelector = ({ automationContent }: StateType): AhrefMetricsType =>
  automationContent.config.ahrefMetrics;

export const semrushMetricsSelector = ({ automationContent }: StateType): SemrushMetricsType =>
  automationContent.config.semrushMetrics;

export const mozMetricsSelector = ({ automationContent }: StateType): MozMetricsType =>
  automationContent.config.mozMetrics;

export const listennotesMetricsSelector = ({
  automationContent,
}: StateType): ListennotesMetricsType & {
  customDateValue?: { from: number; to: number };
} => automationContent.config.listennotesMetrics;
