export const monthsShort = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

export const getDateShort = (date: Date, verbal = true, withTime = false): string => {
  // getMonth() gives us from 0 to 11
  const months = twoNumFormat(date.getMonth() + 1);
  const days = twoNumFormat(date.getDate());
  const year = date.getFullYear();

  if (verbal) {
    return `${monthsShort[date.getMonth()]} ${days}, ${year}`;
  }
  return `${months}/${days}/${year}`; // For example: 01/23/2021
};

export const twoNumFormat = (number) => number.toString().padStart(2, '0');

