import { useMemo } from 'react';
import { formatCustomToStaticVars } from '@constants/variables';
import useCustomVariables from '@hooks/useCustomVariables';
import { CustomVariableValueRelatedToMap } from 'respona_api/generated/common_pb';
import useOrganizationMembers from '@hooks/useOrganizationMembers';

// Don't use with useCustomVariables
// Because this hook uses it inside
export default (
  relation: CustomVariableValueRelatedToMap[keyof CustomVariableValueRelatedToMap] = null
) => {
  const payload = useCustomVariables(relation);
  const { items: members } = useOrganizationMembers(true);

  const variables = useMemo(() => {
    const customVariables = payload?.customVariables ?? [];
    return [...formatCustomToStaticVars(customVariables, members)];
  }, [payload, members]);

  return { variables, ...payload };
};
