import React from 'react';

import cn from 'class-names';

import UserLinks from '@components/UserLinks/UserLinks';
import HelpButton from '@components/HelpButton/HelpButton';

import SidebarFooterProfile from '../SidebarFooterProfile/SidebarFooterProfile';

import './SidebarFooter.scss';

const SidebarFooter = (): JSX.Element => {
  return (
    <div className="sidebar-footer">
      <UserLinks />
      <HelpButton />
      <SidebarFooterProfile />
    </div>
  );
};

export default SidebarFooter;
