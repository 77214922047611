import React from 'react';

import Button from '@uikit/Button/Button';
import PageHeader from '@components/PageHeader/PageHeader';

import RenderHeaderSearchAndActions from '@components/RenderHeaderSearchAndActions/RenderHeaderSearchAndActions';
import { useSelector } from 'react-redux';
import { userProfileSelector } from '@redux/selectors/userProfile.selector';

export default ({ searchString, changeSearchString }): JSX.Element => {
  const { info: userProfileInfo } = useSelector(userProfileSelector);

  return (
    <PageHeader title="Workspaces">
      <div className="settings-workspace__search-and-button">
        <RenderHeaderSearchAndActions
          searchString={searchString}
          changeSearchString={changeSearchString}
        >
          {!userProfileInfo.rolesList.includes('MEMBER') && (
            <Button href="/settings/create/workspace" leftIcon="plusIcon">
              <span className="settings-workspace__button-text">Create workspace</span>
            </Button>
          )}
        </RenderHeaderSearchAndActions>
      </div>
    </PageHeader>
  );
};
