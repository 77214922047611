import React, { MutableRefObject } from 'react';
import ReactDOM from 'react-dom';

import withSmallScreensCheck from '@hocs/withSmallScreensCheck';

import CollapsibleSidebarWrapper from '@components/CollapsibleSidebar/_components/CollapsibleSidebarWrapper';

import './CollapsibleSidebar.scss';

function CollapsibleSidebar({
  children,
  title,
  isSmallScreen,
  targetElementRef,
}: {
  children: React.ReactNode;
  title?: string;
  isSmallScreen: boolean;
  targetElementRef?: MutableRefObject<HTMLDivElement>;
}) {
  if (targetElementRef && !targetElementRef.current) {
    return null;
  }

  return ReactDOM.createPortal(
    <CollapsibleSidebarWrapper initiallyOpen={!isSmallScreen} title={title} fixed={isSmallScreen}>
      {children}
    </CollapsibleSidebarWrapper>,
    targetElementRef?.current || document.body
  );
}

export default withSmallScreensCheck<{
  children: React.ReactNode;
  title?: string;
  targetElementRef?: MutableRefObject<HTMLDivElement>;
  withCloseBtn?: boolean;
}>(CollapsibleSidebar, 1550);
