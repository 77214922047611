import RelationshipPipelineService from 'respona_api/generated/relationship-pipeline_pb_service';

declare const API_HOST: string;

const service = (function () {
  let instance = null;

  return {
    getInstance: (): RelationshipPipelineService.RelationshipPipelineApiServiceClient => {
      if (!instance) {
        instance = new RelationshipPipelineService.RelationshipPipelineApiServiceClient(API_HOST);
      }

      return instance;
    },
  };
})();

export default service;
