/* eslint-disable react/no-array-index-key */
import { convert24to12time } from '@helpers/timeHelpers';
import React from 'react';
import { nanoid } from 'nanoid';
import ReactTooltip from 'react-tooltip';

import { ScheduleDayType } from '@ts/schedules.types';

import './SchedulesDaysList.scss';

export const daysTitles = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const ScheduleDaysListDay = ({
  isActive,
  title,
  timezone,
  timeFrom,
  timeTo,
}: {
  isActive: boolean;
  title: string;
  timezone?: string;
  timeFrom: string;
  timeTo: string;
}) => {
  const id = nanoid();
  return (
    <>
      <div
        data-for={'schedule-days-time-' + id}
        data-tip=""
        className={`schedule-days-list__day ${isActive ? 'schedule-days-list__day--active' : ''}`}
      >
        {title}
      </div>

      {isActive && (
        <ReactTooltip
          id={'schedule-days-time-' + id}
          className="react-tooltip"
          place="bottom"
          effect="solid"
          arrowColor="transparent"
        >
          <div style={{ padding: '10px 20px' }}>
            {convert24to12time(timeFrom) + ' - ' + convert24to12time(timeTo)} ({timezone})
          </div>
        </ReactTooltip>
      )}
    </>
  );
};

export default ({
  dayList,
  style,
  timezone,
}: {
  dayList: ScheduleDayType[];
  style?: React.CSSProperties;
  timezone?: string;
}): JSX.Element => (
  <div style={style} className="schedule-days-list">
    {dayList.map(({ includeDay, timeFrom, timeTo }, index) => (
      <ScheduleDaysListDay
        timezone={timezone}
        key={`schedule-day-${index}`}
        isActive={includeDay}
        timeFrom={timeFrom}
        timeTo={timeTo}
        title={daysTitles[index]}
      />
    ))}
  </div>
);
