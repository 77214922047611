declare const BUILD_VERSION: string;

type MetadataType = { Authorization?: string; 'X-UI-VERSION'?: string };

export default (token: string): MetadataType => {
  const metadata: MetadataType = {};

  if (token) {
    metadata.Authorization = token;
  }

  // metadata['X-UI-VERSION'] = '2';
  // 8080 port is in webstorm dev server
  // if (window.location.host !== 'localhost:8080') {
  //   metadata['X-UI-VERSION'] = '2';
  // }

  return metadata;
};
