import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';

import { updateReferralContentApi } from '@api/userOrganization.api';

import { Button } from '@uikit/Button/Button';
import Input from '@uikit/Input/Input';
import BaseModal, { BaseModalType } from '@uikit/BaseModal/BaseModal';

import './EditLinkContentModal.scss';
import useReferralLink from '@hooks/useReferralLink';

const contentRegexp = new RegExp(
  /^<p>(.+)?<a href="%s" target="_blank" rel="noopener noreferrer">(.+)<\/a>(.+)?<\/p>$/
);
const validationRegexp = new RegExp(/^(.+)?{link}(.+){\/link}(.+)?$/);

const contentPattern =
  '<p>text1<a href="%s" target="_blank" rel="noopener noreferrer">link</a>text2</p>';

function EditReferralContentModal({
  isOpen,
  onClose,
  content,
}: BaseModalType & { content?: string }): JSX.Element {
  const { evictByKey: evictReferralCache } = useReferralLink();

  const [value, setValue] = useState<string>('');

  const { mutate, isLoading } = useMutation({
    mutationFn: (parsedValue: string) => updateReferralContentApi(parsedValue),
    onSuccess: async () => {
      evictReferralCache(-1);
      onClose();
    },
  });

  const parseValue = (string: string) => {
    if (!validationRegexp.test(string)) {
      throw new Error('Wrong format');
    }

    const splitValue = string.split(/{link}|{\/link}/);

    let finalValue = contentPattern;
    finalValue = finalValue.replace('text1', splitValue[0]);
    finalValue = finalValue.replace('link', splitValue[1]);
    finalValue = finalValue.replace('text2', splitValue[2]);

    return finalValue;
  };

  const updateReferralContent = async () => {
    try {
      const parsedValue = parseValue(value);
      await mutate(parsedValue);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (content) {
      setValue(content.replace(contentRegexp, '$1{link}$2{/link}$3'));
    }
  }, [content]);

  return (
    <BaseModal isOpen={isOpen} onClose={onClose}>
      <div className="edit-unsubscribe-content-modal__header">
        <div className="edit-unsubscribe-content-modal__header-title">Referral sentence</div>
        <div className="edit-unsubscribe-content-modal__header-subtitle">
          {'The referral link will be included between {link} and {/link} tags.'}
        </div>
      </div>
      <div className="edit-unsubscribe-content-modal__content">
        <Input
          placeholder="Referral sentence"
          className="edit-unsubscribe-content-modal__input"
          value={value}
          onChange={({ target: { value } }) => setValue(value)}
        />

        <Button
          isLoading={isLoading}
          className="edit-unsubscribe-content-modal__save-button"
          onClick={updateReferralContent}
        >
          Save
        </Button>
      </div>
    </BaseModal>
  );
}

export default EditReferralContentModal;
