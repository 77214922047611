import React from 'react';

export const OpenMessageIcon = ({ color = '#BDBDBD' }) => (
  <svg width="18" height="20" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.44629 7.58789L8.99991 13.0002L16.5525 7.58862"
      stroke={color}
      strokeWidth="1.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.44629 7.58765L9.36355 1L16.5525 7.58837"
      stroke={color}
      strokeWidth="1.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.4177 7.47212C16.6199 7.6239 16.7813 7.82714 16.8824 8.06242C16.9581 8.23847 17 8.43245 17 8.63622V18.818C17 19.6214 16.3488 20.2726 15.5455 20.2726H2.45455C1.65122 20.2726 1 19.6214 1 18.818V8.63622C1 8.14831 1.24024 7.7165 1.60888 7.45264"
      stroke={color}
      strokeWidth="1.8"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);

export default OpenMessageIcon;
