import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import Sortable from 'sortablejs';
import { useQueryClient } from 'react-query';
import ReactTooltip from 'react-tooltip';

import PipelineDealCard from '@components/Relationships/RelationshipsPipelines/_conponents/PipelineDealCard/PipelineDealCard';
import { Button } from '@uikit/Button/Button';
import { SVGIcon } from '@uikit/Icon/Icon';
import Input from '@uikit/Input/Input';
import SlideToggler from '@uikit/SlideToggler/SlideToggler';
import Loader from '@uikit/Loader/Loader';

import {
  PipelineOpportunityType,
  RelationshipPipelineFilterType,
  RelationshipPipelineStageType,
} from '@ts/relationshipPipeline.types';
import {
  moveStageOpportunityApi,
  removeStageOpportunityApi,
  updateStageApi,
} from '@api/relationshipPipeline';

import useOutsideClick from '@hooks/useOutsideClick';
import useWorkspaceMembers from '@hooks/useWorkspaceMembers';
import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';
import useIntersectionObserver from '@hooks/useIntersectionObserver';

import './PipelineColumn.scss';
import useRelationshipOpportunities, {
  DEALS_PAGE_LIMIT,
  DEALS_WITHOUT_FILTERS_QUERY_KEY,
} from '@hooks/useRelationshipOpportunities';
import useRelationshipPipelineStages, {
  PIPELINE_COLUMN_QUERY_KEY,
} from '@hooks/useRelationshipPipelineStages';
import { useRefCallback } from '@helpers/refHelpers';
import { addNotification } from '@redux/actions/notifications.actions';
import Select from '@uikit/Select/Select';
import { personStatusOptions } from '@components/SidebarCRM/sections/SidebarCRMSectionContact/SidebarCRMSectionContact';
import * as common_pb from 'respona_api/generated/common_pb';

// Sortable.mount(new MultiDrag());
const SORTABLE_COLUMN_CLASS = 'pipeline-column__sortable-list';

interface PipelineColumnProps {
  pipelineId: number;
  stage: RelationshipPipelineStageType;
  searchQuery: string;
  appliedFilters: RelationshipPipelineFilterType[];
  selectCard: (oppoId: number, isAdded: boolean) => void;
  handleStageDelete: (stageId: number) => void;
  openCreateDealSideBar: (stageId: number) => void;
  handleCardClick: (opportunity: PipelineOpportunityType) => void;
  opportunitiesByFilter: PipelineOpportunityType[];
  onMoveOpportunityWithFilter: (
    evt: any,
    opportunityId: number,
    movedOpportunity: PipelineOpportunityType,
    filters: RelationshipPipelineFilterType[]
  ) => void;
  hasNextPageWithFilters: boolean;
  fetchNextPageWithFilters: () => void;
}

const PipelineColumn: React.FC<PipelineColumnProps> = ({
  pipelineId,
  stage,
  searchQuery,
  appliedFilters,
  selectCard,
  handleStageDelete,
  openCreateDealSideBar,
  handleCardClick,
  opportunitiesByFilter,
  onMoveOpportunityWithFilter,
  hasNextPageWithFilters,
  fetchNextPageWithFilters,
}) => {
  const isFirstRender = useRef(true);
  const dispatch = useDispatch();
  const [loadingRef, setLoadingRef, ready] = useRefCallback<HTMLDivElement>();
  const [loadingWithFilterRef, setLoadingWithFiltersRef, readyWithFilters] =
    useRefCallback<HTMLDivElement>();
  const { composeMemberOptions } = useWorkspaceMembers();
  const currentWorkspaceId = useCurrentWorkspaceId();
  const queryClient = useQueryClient();
  const sortableItemsRef = useRef<HTMLDivElement>(null);
  const [tooltipColumnTitle, setTooltipColumnTitle] = useState<string>(stage.title);
  const [websiteStatus, setWebsiteStatus] = useState<
    common_pb.PeopleRelationshipStatusMap[keyof common_pb.PeopleRelationshipStatusMap]
  >(stage.changeWebsiteStatusEnabled ? stage.websiteStatusDestination : null);
  const [assignee, setAssignee] = useState<number>(
    stage.changeAssigneeEnabled ? stage.assigneeUserIdDestination : null
  );
  const [counterValue, setCounterValue] = useState<number>(stage.targetTimeInDays);
  const [isActive, setIsActive] = useState<boolean>(stage.trackTime);
  const [isVisibleRemoveApproval, setVisibleRemoveApproval] = useState<boolean>(false);

  const {
    items: opportunitiesData,
    refetch: refetchOpportunitiesData,
    fetchNextPage: fetchNextOpportunitiesPage,
    hasNextPage: hasNextOpportunitiesPage,
    addItems: addOpportunitiesToCache,
    removeItem: removeOpportunityFromCache,
  } = useRelationshipOpportunities(currentWorkspaceId, pipelineId, stage?.id, searchQuery);

  const { updateItem: updateStageItem } = useRelationshipPipelineStages(
    pipelineId,
    currentWorkspaceId
  );

  const confirmBtnRef = useRef<HTMLButtonElement>();

  useOutsideClick(confirmBtnRef, () => setVisibleRemoveApproval(false));

  useEffect(() => {
    setTooltipColumnTitle(stage.title);
    setIsActive(stage.trackTime);
    setCounterValue(stage.targetTimeInDays);
    warmUpOpportunitiesCache(stage.opportunitiesList);
  }, [stage]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (appliedFilters?.length === 0) {
      refetchOpportunitiesData();
    }
  }, [JSON.stringify(appliedFilters), searchQuery]);

  const opportunities: PipelineOpportunityType[] = useMemo(() => {
    if (appliedFilters?.length > 0) return opportunitiesByFilter;
    return opportunitiesData;
  }, [JSON.stringify(appliedFilters), opportunitiesData, opportunitiesByFilter]);

  useIntersectionObserver(loadingRef, () => fetchNextOpportunitiesPage(), [ready]);
  useIntersectionObserver(loadingWithFilterRef, () => fetchNextPageWithFilters(), [
    readyWithFilters,
  ]);

  const renderLoader = () => {
    if (opportunities?.length && hasNextOpportunitiesPage && appliedFilters.length === 0) {
      return (
        <div ref={setLoadingRef}>
          <Loader isLoading withTopMargin />
        </div>
      );
    }
    if (opportunities?.length && hasNextPageWithFilters && appliedFilters.length > 0) {
      return (
        <div ref={setLoadingWithFiltersRef}>
          <Loader isLoading withTopMargin />
        </div>
      );
    }
    return null;
  };

  const onRemoveOpportunityClick = (opportunityId: number) => {
    removeStageOpportunityApi([opportunityId], stage?.id, currentWorkspaceId).then(() => {
      removeOpportunityFromCache(opportunityId);
      updateStageItem(stage?.id, {
        ...stage,
        opportunitiesNumber: stage.opportunitiesNumber - 1,
      });
      dispatch(addNotification({ title: 'Opportunity removed', type: 'success' }));
    });
  };

  const handleMoveCard = useCallback(
    (opportunityId: number, targetStageId: number, headOpportunityId?: number) => {
      return moveStageOpportunityApi(
        currentWorkspaceId,
        pipelineId,
        targetStageId,
        opportunityId,
        headOpportunityId
      );
    },
    [currentWorkspaceId, pipelineId, stage?.id]
  );

  const handleApplyChanges = () => {
    updateStageApi(
      stage.id,
      pipelineId,
      tooltipColumnTitle,
      isActive,
      counterValue,
      true,
      assignee,
      stage.changeWebsiteStatusEnabled,
      websiteStatus
    ).then((response) => {
      updateStageItem(stage.id, response);
    });
  };

  const warmUpOpportunitiesCache = (items: PipelineOpportunityType[]) =>
    addOpportunitiesToCache(items);

  const sortable = useRef(null);

  if (sortable.current) {
    sortable.current.appliedFilters = appliedFilters;
  }

  function onMoveOpportunityWithoutFilter(
    evt: any,
    opportunityId: number,
    sourceStageId: number,
    targetStageId: number,
    movedOpportunity: PipelineOpportunityType
  ) {
    const sourceSearchKey = [
      DEALS_WITHOUT_FILTERS_QUERY_KEY,
      pipelineId,
      currentWorkspaceId,
      sourceStageId,
      searchQuery,
    ];
    queryClient.setQueryData(sourceSearchKey, ({ pages, pageParams }) => {
      const updatePages = pages.map((page) =>
        page.filter((item: PipelineOpportunityType) => item.id !== opportunityId)
      );

      return {
        pageParams,
        pages: updatePages,
      };
    });

    const targetSearchKey = [
      DEALS_WITHOUT_FILTERS_QUERY_KEY,
      pipelineId,
      currentWorkspaceId,
      targetStageId,
      searchQuery,
    ];
    queryClient.setQueryData(targetSearchKey, ({ pages, pageParams }) => {
      const allItems = pages.flat() as PipelineOpportunityType[];
      allItems.splice(evt.newIndex, 0, movedOpportunity);
      const recalculatedItems = allItems.map((item, index) => {
        return { ...item, order: index + 1 };
      });
      recalculatedItems.sort((a, b) => (a.order > b.order ? 1 : -1));
      const pageSize = DEALS_PAGE_LIMIT;
      const updatedPages: PipelineOpportunityType[][] = [];
      for (let i = 0; i < recalculatedItems.length; i += pageSize) {
        updatedPages.push(recalculatedItems.slice(i, i + pageSize));
      }

      return { pageParams, pages: updatedPages };
    });

    queryClient.setQueryData(
      [PIPELINE_COLUMN_QUERY_KEY, currentWorkspaceId, pipelineId],
      (columns: RelationshipPipelineStageType[] = []) => {
        return columns.map((column) => {
          if (column.id === targetStageId) {
            return {
              ...column,
              opportunitiesNumber: column.opportunitiesNumber + 1,
            };
          }

          if (column.id === sourceStageId) {
            return {
              ...column,
              opportunitiesNumber: Math.max(column.opportunitiesNumber - 1, 0),
            };
          }

          return column;
        });
      }
    );
  }

  useEffect(() => {
    if (sortableItemsRef.current) {
      sortable.current = new Sortable(sortableItemsRef.current, {
        group: 'shared',
        // multiDrag: true,
        animation: 150,
        selectedClass: 'sortable-selected',

        onMove(evt) {
          document.querySelectorAll(`.${SORTABLE_COLUMN_CLASS}`).forEach((list) => {
            list.classList.remove(`${SORTABLE_COLUMN_CLASS}--hovered`);
          });
          if (evt.to) {
            evt.to.classList.add(`${SORTABLE_COLUMN_CLASS}--hovered`);
          }
        },

        onEnd(evt) {
          document.querySelectorAll(`.${SORTABLE_COLUMN_CLASS}`).forEach((list) => {
            list.classList.remove(`${SORTABLE_COLUMN_CLASS}--hovered`);
          });

          const itemEl = evt.item;
          const targetStageId = parseInt(evt.to.dataset.stageId, 10);
          const sourceStageId = parseInt(evt.from.dataset.stageId, 10);
          const opportunityId = parseInt(itemEl.dataset.opportunityId, 10);
          const prevId =
            Array.from(evt.to.querySelectorAll('.pipeline-deal-card'))
              .map((item: HTMLElement) => parseInt(item.dataset.opportunityId, 10))
              .find((item, index, arr) => arr[index + 1] === opportunityId) || -1;

          handleMoveCard(opportunityId, targetStageId, prevId).then((movedOpportunity) => {
            if (targetStageId !== stage.id) {
              evt.from.appendChild(evt.item);

              const updatedMovedOppo = {
                ...movedOpportunity,
                stageId: targetStageId,
                targetTimeAt: Date.now(),
              };

              if (sortable.current.appliedFilters.length === 0) {
                onMoveOpportunityWithoutFilter(
                  evt,
                  opportunityId,
                  sourceStageId,
                  targetStageId,
                  updatedMovedOppo
                );
              } else {
                onMoveOpportunityWithFilter(
                  evt,
                  opportunityId,
                  updatedMovedOppo,
                  sortable.current.appliedFilters
                );
              }
            }
          });
        },
      });

      return () => {
        sortable.current.destroy();
      };
    }
  }, [handleMoveCard]);

  const filterSelectStyles = {
    control: (provided) => ({
      ...provided,
      background: 'transparent',
      borderColor: 'rgba(232, 232, 232, 0.25)',
      borderRadius: '6px',
      height: '40px',
      color: 'white',
      paddingLeft: '3px',
      '&:focus,&:hover': {
        outline: 'none',
        boxShadow: 'none',
        borderColor: 'rgba(232, 232, 232, 0.25)',
      },
    }),
    placeholder: () => ({
      color: 'white',
    }),
    singleValue: () => ({
      color: 'white',
    }),
  };

  const composedWebsiteStatusValue = useMemo(() => {
    return personStatusOptions.find((option) => option.value === websiteStatus);
  }, [personStatusOptions, websiteStatus]);

  const composedAssigneeValue = useMemo(() => {
    return composeMemberOptions(false).find((option) => option.value === assignee);
  }, [composeMemberOptions, assignee]);

  return (
    <div className="pipeline-column" data-stage-id={stage.id}>
      <div className="pipeline-column__header">
        <div className="pipeline-column__header-title">
          <span
            title={stage?.title}
            className="pipeline-column__header-tooltip"
            data-for={`edit-column-${stage?.id}`}
            data-tip=""
          >
            {stage?.title}
          </span>
          <ReactTooltip
            id={`edit-column-${stage?.id}`}
            event="click"
            globalEventOff="click"
            clickable
            className="pipeline-column__header-tooltip-content edit-tooltip"
            effect="solid"
            arrowColor="transparent"
            overridePosition={() => ({ top: 8, left: -7 })}
          >
            <div onClick={(event) => event.stopPropagation()}>
              <div className="pipeline-column__header-tooltip-content-header">
                <Input
                  onChange={(event) => setTooltipColumnTitle(event.target.value)}
                  value={tooltipColumnTitle}
                  placeholder="Type something"
                  isTransparent
                />
              </div>
              <div className="pipeline-column__header-tooltip-content-body">
                <div className="pipeline-column__header-tooltip-content-body-section">
                  <label>
                    Change website status to:
                    <Select
                      placeholder="Keep the status as is"
                      options={personStatusOptions}
                      value={composedWebsiteStatusValue}
                      onChange={(payload) => setWebsiteStatus(payload.value)}
                      additionalStyles={filterSelectStyles}
                    />
                  </label>
                </div>

                <div className="pipeline-column__header-tooltip-content-body-section">
                  <label>
                    Change assignee to:
                    <Select
                      placeholder="Keep the assignee as is"
                      options={composeMemberOptions}
                      onChange={(payload) => setAssignee(payload.value)}
                      value={composedAssigneeValue}
                      additionalStyles={filterSelectStyles}
                    />
                  </label>
                </div>
                <div className="pipeline-column__header-tooltip-content-body-toggler">
                  <span>Track time</span>
                  <SlideToggler value={isActive} onChange={() => setIsActive(!isActive)} />
                </div>

                <div className="pipeline-column__header-tooltip-content-body-counter">
                  <label>
                    Target Time
                    <Input
                      afterText="Day(s)"
                      onChange={(event) => setCounterValue(event.target.value)}
                      value={counterValue}
                      isTransparent
                    />
                  </label>
                </div>
              </div>

              <div className="pipeline-column__header-tooltip-content-footer">
                {isVisibleRemoveApproval ? (
                  <span ref={confirmBtnRef}>
                    <Button
                      type="ghost"
                      leftIcon="trash"
                      className="pipeline-column__header-tooltip-content-footer-delete"
                      onClick={() => handleStageDelete(stage?.id)}
                    >
                      Confirm
                    </Button>
                  </span>
                ) : (
                  <div className="pipeline-column__header-tooltip-content-footer-buttons">
                    <Button
                      type="ghost"
                      leftIcon="trash"
                      className="pipeline-column__header-tooltip-content-footer-delete"
                      onClick={() => setVisibleRemoveApproval(true)}
                    >
                      Delete Stage
                    </Button>
                    <Button onClick={handleApplyChanges} size="s">
                      Apply
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </ReactTooltip>

          {appliedFilters?.length === 0 && (
            <span className="pipeline-column__header-title-counter">
              {stage?.opportunitiesNumber}
            </span>
          )}
        </div>
        <Button
          type="ghost"
          className="pipeline-column__header-title__add-btn"
          onClick={() => openCreateDealSideBar(stage?.id)}
        >
          <SVGIcon icon="plusCircle" color="#BDBDBD" size={20} />
        </Button>
      </div>
      <div className="pipeline-column__sortable-list-container">
        <div
          ref={sortableItemsRef}
          className="pipeline-column__sortable-list"
          data-stage-id={stage.id}
        >
          {opportunities?.map((opportunity, index) => (
            <PipelineDealCard
              key={`card-${opportunity.id}-${index}`}
              id={opportunity.id}
              name={opportunity.relatedName}
              website={opportunity.relatedDomain}
              targetTime={opportunity.targetTimeAt}
              daysLimit={stage.targetTimeInDays}
              showTargetTime={stage.trackTime}
              logoUrl={opportunity.relatedLogo}
              selectCard={selectCard}
              assignedToUser={composeMemberOptions(false).find(
                ({ value }) => value === opportunity.assignedToUserId
              )}
              onClick={() => handleCardClick(opportunity)}
              onRemoveClick={(opportunityId: number) => onRemoveOpportunityClick(opportunityId)}
              order={opportunity.order}
            />
          ))}
          <div className="pipeline-column__loader">{renderLoader()}</div>
        </div>
      </div>
    </div>
  );
};

export default PipelineColumn;
