import {
  FilterJoinTypeMap,
  FilterOperation,
  FilterOperationMap,
} from 'respona_api/generated/common_pb';

export const FilterJoins: {
  label: string;
  value: FilterJoinTypeMap[keyof FilterJoinTypeMap];
}[] = [
  {
    label: 'AND',
    value: 0,
  },
  {
    label: 'OR',
    value: 1,
  },
];

/*
  All filters are based on FilterOperationMap, but string, number and boolean filters divided
 */
export const FilterOperationsMapForString: {
  label: string;
  value: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
}[] = [
  {
    label: 'Is',
    value: 0,
  },
  {
    label: 'Is not',
    value: 1,
  },
  {
    label: 'Contains',
    value: 2,
  },
  {
    label: 'Does not contain',
    value: 3,
  },
  {
    label: 'Starts with',
    value: 4,
  },
  {
    label: 'Ends with',
    value: 5,
  },
  {
    label: 'Is empty',
    value: 6,
  },
  {
    label: 'Is not empty',
    value: 7,
  },
];

export const FilterOperationsMapForStringMandatory: {
  label: string;
  value: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
}[] = [
  {
    label: 'Is',
    value: 0,
  },
  {
    label: 'Is not',
    value: 1,
  },
  {
    label: 'Contains',
    value: 2,
  },
  {
    label: 'Does not contain',
    value: 3,
  },
  {
    label: 'Starts with',
    value: 4,
  },
  {
    label: 'Ends with',
    value: 5,
  },
];

export const FilterOperationsMapForNumber: {
  label: string;
  value: 8 | 9 | 10 | 11 | 12 | 13;
}[] = [
  {
    label: '=',
    value: 8,
  },
  {
    label: '≠',
    value: 9,
  },
  {
    label: '>',
    value: 10,
  },
  {
    label: '≥',
    value: 11,
  },
  {
    label: '<',
    value: 12,
  },
  {
    label: '≤',
    value: 13,
  },
];

export const FilterOperationsMapForBoolean: {
  label: string;
  value: 14 | 15;
}[] = [
  {
    label: 'Equal',
    value: 14,
  },
  {
    label: 'Not Equal',
    value: 15,
  },
];

export const FilterOperationsMapForEnum: {
  label: string;
  value: FilterOperationMap[keyof FilterOperationMap];
}[] = [
  {
    label: 'Is',
    value: FilterOperation.NUMBER_EQUAL,
  },
  {
    label: 'Is Not',
    value: FilterOperation.NUMBER_EQUAL_NOT,
  },
];

export const FilterOperationsMapForEnumString: {
  label: string;
  value: FilterOperationMap[keyof FilterOperationMap];
}[] = [
  {
    label: 'Is',
    value: FilterOperation.STRING_EQUAL,
  },
  {
    label: 'Is Not',
    value: FilterOperation.STRING_EQUAL_NOT,
  },
];

export const FilterOperationsMapForDate: {
  label: string;
  value: FilterOperationMap[keyof FilterOperationMap];
}[] = [
  {
    label: 'Is after',
    value: FilterOperation.NUMBER_GREATER_THAN_OR_EQUAL,
  },
  {
    label: 'Is before',
    value: FilterOperation.NUMBER_LESS_THAN_OR_EQUAL,
  },
];
