import React from 'react';

import Display from '@components/Display/Display';
import { SVGIcon } from '@uikit/Icon/Icon';

const FileSeletcStepUploadProgress = ({ curFile, uploadedFile, uploadingProgress, onCancel }) => {
  const splittedFileName = curFile ? curFile.name.split('.') : uploadedFile.name.split('.');
  const fileName = splittedFileName.slice(0, splittedFileName.length - 1);
  const extension = splittedFileName[splittedFileName.length - 1];

  return (
    <div className="file-select-step-upload-progress">
      <div className="file-select-step-upload-progress__extends-icon">
        <Display isVisible={Number(uploadingProgress) === 100}>
          <div className="file-select-step-upload-progress__remove-icon" onClick={onCancel}>
            <SVGIcon icon="trash" />
          </div>
        </Display>
        CSV
      </div>
      <div className="file-select-step-upload-progress__info">
        <div className="file-select-step-upload-progress__info-header">
          <div className="file-select-step-upload-progress__file-name">
            {fileName}.
            <span className="file-select-step-upload-progress__file-extension">{extension}</span>
          </div>
          <div className="file-select-step-upload-progress__percentage">
            {Number(uploadingProgress) === 100 ? (
              <SVGIcon icon="doubleCheckmark" />
            ) : (
              <>{uploadingProgress} %</>
            )}
          </div>
        </div>
        <div className="file-select-step-upload-progress__bar">
          <div
            className="file-select-step-upload-progress__progress-bar"
            style={{ width: `${uploadingProgress}%` }}
          />
        </div>
      </div>
    </div>
  );
};

export default FileSeletcStepUploadProgress;
