import email from './email/email';
import additionalEmail from './additionalEmail';
import textField from './textField';
import sendingStrategy from './sendingStrategy';
import campaignSchedule from './schedules';
import priority from './priority';

export const mainSettingFields = {
  campaignName: textField('Campaign Name', 'campaignName', 'Give your campaign a name...'),
  /*
    Just comment, we will need it in future
   */
  priority,
  senderEmail: email('Sender Email', 'senderEmail'),
  campaignSchedule,
};

export const advancedSettingsFields = {
  autoBCC: additionalEmail('Auto-BCC', 'autoBCC', 'bccEmailsList'),
  autoCC: additionalEmail('Auto-CC', 'autoCC', 'ccEmailsList'),
  sendingStrategy,
};
