import React from 'react';

export default ({ color = '#C4C6CD', size = 18 }: { color?: string; size?: number }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.074 4.3541C6.50451 3.3379 7.51111 2.625 8.68422 2.625C10.2491 2.625 11.5177 3.89361 11.5177 5.45852C11.5177 6.63163 10.8048 7.63824 9.78865 8.06874L10.9021 9.18224C12.1692 8.42594 13.0177 7.04133 13.0177 5.45852C13.0177 3.06519 11.0776 1.125 8.68422 1.125C7.10142 1.125 5.7168 1.97357 4.96051 3.2406L6.074 4.3541ZM6.05695 6.52179L4.39212 4.85696C4.36481 5.05357 4.3507 5.2544 4.3507 5.45852C4.3507 7.85186 6.29088 9.79205 8.68422 9.79205C8.88835 9.79205 9.08918 9.77794 9.28579 9.75063L7.62096 8.0858C6.91122 7.79829 6.34446 7.23153 6.05695 6.52179ZM10.5684 11.0333C10.0642 11.0097 9.53922 11 9 11C5.13401 11 2 11.5 2 14C2 16.5 5.13401 17 9 17C11.7341 17 14.1021 16.7499 15.2543 15.7192L14.1546 14.6195C14.025 14.7423 13.7937 14.891 13.3866 15.0316C12.4131 15.3678 10.9175 15.5 9 15.5C7.08251 15.5 5.58695 15.3678 4.61339 15.0316C4.1481 14.8709 3.91249 14.6997 3.79584 14.5685C3.70228 14.4633 3.61538 14.3095 3.61538 14C3.61538 13.6905 3.70228 13.5367 3.79584 13.4315C3.91249 13.3003 4.1481 13.1291 4.61339 12.9684C5.58695 12.6322 7.08251 12.5 9 12.5C10.2725 12.5 11.3592 12.5582 12.2308 12.6956L10.5684 11.0333ZM15.9874 14.2675C15.9958 14.181 16 14.0918 16 14C16 12.4715 14.8285 11.6906 13.0345 11.3145L15.9874 14.2675Z"
      fill={color}
    />
    <rect
      x="1.19141"
      y="3.82812"
      width="1.54485"
      height="20.0435"
      rx="0.772426"
      transform="rotate(-45 1.19141 3.82812)"
      fill={color}
    />
  </svg>
);
