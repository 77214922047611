import React from 'react';

import Loader from '@uikit/Loader/Loader';
import { sentAndNeedThreadMapper } from '@helpers/emailsHelpers';

import InboxThreadsContent from '@components/CampaignAnalytics/InboxThreadsContent/InboxThreadsContent';
import { useInfiniteQuery } from 'react-query';
import {
  getNeedAttentionEmailsOfCampaignPageableApi,
  getSentEmailsOfCampaignPageableApi,
} from '@api/mailbox.api';
import { ThreadPreviewResponseType } from '@ts/mailboxInbox.types';

// eslint-disable-next-line no-shadow
export enum InboxList {
  Sent = 'Sent',
  NeedAttention = 'NeedAttention',
}

type InboxPropsTypes = {
  listType: InboxList;
  campaignId: number;
  workspaceId: number;
  RenderHeader?: (any) => JSX.Element;
};

const SentEmails = ({
  listType,
  campaignId,
  workspaceId,
  RenderHeader,
}: InboxPropsTypes): JSX.Element => {
  const {
    data: rawData,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ['sent-emails', listType, campaignId, workspaceId],
    ({ pageParam = 0 }) => {
      if (listType === InboxList.Sent) {
        return getSentEmailsOfCampaignPageableApi(
          campaignId,
          pageParam === 0 ? 0 : pageParam + 1,
          pageParam === 0 ? 20 : 10,
          workspaceId
        );
      }

      if (listType === InboxList.NeedAttention) {
        return getNeedAttentionEmailsOfCampaignPageableApi(
          campaignId,
          pageParam === 0 ? 0 : pageParam + 1,
          pageParam === 0 ? 20 : 10,
          workspaceId
        );
      }

      return Promise.resolve([]);
    },
    {
      getNextPageParam: (lastPage: ThreadPreviewResponseType[], allPages) => {
        if (lastPage.length < 10) return false;

        return allPages.length;
      },
      enabled: !!campaignId && !!workspaceId,
      refetchOnWindowFocus: false,
    }
  );

  // @ts-ignore
  const threadsData: ThreadPreviewResponseType[] = rawData?.pages?.flat() || [];

  const threads = sentAndNeedThreadMapper(threadsData);

  return (
    <>
      <RenderHeader />
      <Loader isLoading={isLoading} withTopMargin>
        <InboxThreadsContent
          threads={threads}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
        />
      </Loader>
    </>
  );
};

export default SentEmails;
