import React from 'react';

import BaseModal from '@uikit/BaseModal/BaseModal';
import Button from '@uikit/Button/Button';
import Input from '@uikit/Input/Input';

import './SaveSequenceTemplateModal.scss';

const SaveSequenceTemplateModal = ({
  isOpen,
  onConfirm,
  onClose,
  changeSequenceTemplateTitle,
  sequenceTemplateTitle,
}) => {
  const handleChangeInput = ({ target: { value } }) => changeSequenceTemplateTitle(value);

  return (
    <BaseModal isOpen={isOpen} onClose={onClose}>
      <div className="sequence-save-template-modal">
        <div className="sequence-save-template-modal__header">New email template</div>
        <div className="sequence-save-template-modal__input">
          <Input
            value={sequenceTemplateTitle}
            onChange={handleChangeInput}
            placeholder="Name your template"
          />
        </div>
        <div className="sequence-save-template-modal__footer">
          <Button className="sequence-save-template-modal__cancel-button" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onConfirm}>Save Sequence</Button>
        </div>
      </div>
    </BaseModal>
  );
};

export default SaveSequenceTemplateModal;
