import React from 'react';

export default function ({ color = '#8F9199', size = 26 }: { color?: string; size?: number }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1663 3.83333C14.1663 5.1225 11.294 6.16667 7.74967 6.16667C4.20534 6.16667 1.33301 5.1225 1.33301 3.83333M14.1663 3.83333C14.1663 2.54417 11.294 1.5 7.74967 1.5C4.20534 1.5 1.33301 2.54417 1.33301 3.83333M14.1663 3.83333V5.58333M1.33301 3.83333V17.8333C1.33301 19.1225 4.20534 20.1667 7.74967 20.1667M7.74967 10.8333C7.55251 10.8333 7.35884 10.8298 7.16634 10.824C3.89617 10.7167 1.33301 9.71683 1.33301 8.5M7.74967 15.5C4.20534 15.5 1.33301 14.4558 1.33301 13.1667M24.6663 11.4167C24.6663 12.7058 21.794 13.75 18.2497 13.75C14.7053 13.75 11.833 12.7058 11.833 11.4167M24.6663 11.4167C24.6663 10.1275 21.794 9.08333 18.2497 9.08333C14.7053 9.08333 11.833 10.1275 11.833 11.4167M24.6663 11.4167V20.1667C24.6663 21.4558 21.794 22.5 18.2497 22.5C14.7053 22.5 11.833 21.4558 11.833 20.1667V11.4167M24.6663 15.7917C24.6663 17.0808 21.794 18.125 18.2497 18.125C14.7053 18.125 11.833 17.0808 11.833 15.7917"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
