import GoogleLongSVG from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/assets/GoogleLongSVG';
import MicrosoftLongSVG from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/assets/MicrosoftLongSVG';

// @ts-ignore
import otherIcon from '@components/Settings/AddEmailSidebar/assets/otherIcon.svg';
import IntegrationStatus from '@components/Settings/Integrations/_components/IntegrationStatus/IntegrationStatus';
import PieChart from '@uikit/PieChart/PieChart';
import React from 'react';
import ReactTooltip from 'react-tooltip';

import Table, { THead, TBody, Tr, Th, Td } from '@uikit/Table/Table';
import { SVGIcon } from '@uikit/Icon/Icon';
import ActionsTooltipContent from '@uikit/ActionsTooltipContent/ActionsTooltipContent';

import { MailboxAccountStatisticsType } from '@ts/mailboxAccounts.types';

import './EmailsTable.scss';
import { IntegrationStatus as INTEGRATION_STATUS } from 'respona_api/generated/common_pb';
import { MailboxAccountStatus, MailboxAccountType } from 'respona_api/generated/mailbox-account_pb';

type EmailsTablePropsType = {
  mailboxes: MailboxAccountStatisticsType[];
  onOpen: (id: number) => void;
  onRemove: (id: number) => void;
  onShareClick: (id: number) => void;
  isDisableSharing?: boolean;
};

function EmailsTableRow({
  senderName,
  dailySentLimit,
  dailySentToday = 0,
  email,
  id,
  healthScoreRate,
  openRate = 0,
  replyRate = 0,
  onOpen,
  onRemove,
  isDisableSharing = false,
  onShareClick,
  status,
  type,
}: MailboxAccountStatisticsType & {
  onOpen: (number) => void;
  onRemove: (number) => void;
  isDisableSharing: boolean;
  onShareClick: (number) => void;
}) {
  const actionsConfig = [
    {
      icon: 'editAction',
      title: 'Edit',
      handler: () => onOpen(id),
    },
    {
      icon: 'shareAction',
      title: 'Share',
      handler: () => onShareClick(id),
      isDisable: isDisableSharing,
    },
  ];

  const getStatus = () => {
    switch (status) {
      case MailboxAccountStatus.MAILBOX_ACTIVE:
        return INTEGRATION_STATUS.ACTIVE_INTEGRATION;
      case MailboxAccountStatus.MAILBOX_PAUSED:
        return INTEGRATION_STATUS.PAUSED_INTEGRATION;
      case MailboxAccountStatus.MAILBOX_AUTH_FAILED:
        return INTEGRATION_STATUS.AUTH_FAILED_INTEGRATION;
      default:
        return INTEGRATION_STATUS.NOT_CONNECTED_INTEGRATION;
    }
  };

  return (
    <Tr className="emails-table__tr" onClick={() => onOpen(id)}>
      <Td className="emails-table__td" style={{ paddingRight: '20px' }}>
        <div className="emails-table__email">{email}</div>
        <div className="emails-table__name">{senderName}</div>
      </Td>
      <Td className="emails-table__td">
        <div className="emails-table__email" style={{ paddingRight: '20px' }}>
          {type === MailboxAccountType.GMAIL ? <GoogleLongSVG width={77} height={26} /> : null}
          {type === MailboxAccountType.MICROSOFT ? <MicrosoftLongSVG /> : null}
          {type === MailboxAccountType.SMTP_IMAP ? (
            <div className="emails-table__imap-smtp">
              <img src={otherIcon} width="21px" height="21px" alt="SMTP/IMAP" />
              IMAP/SMTP
            </div>
          ) : null}
          {type === MailboxAccountType.UNKNOWN ? <div>-</div> : null}
        </div>
      </Td>
      <Td className="emails-table__td" style={{ minWidth: '170px' }}>
        <div className="emails-table__email">
          <IntegrationStatus status={getStatus()} />
        </div>
      </Td>
      <Td className="emails-table__td">
        <div className="diagram-cell">
          <div className="diagram-cell__ring">
            <PieChart
              size={38}
              value={(dailySentToday * 100) / dailySentLimit}
              color="#3424EB"
              name="dailySentToday"
            />
          </div>
          <div className="diagram-cell__label">
            {Number(dailySentToday)} / {dailySentLimit}
          </div>
        </div>
      </Td>
      <Td className="emails-table__td">
        <div className="diagram-cell">
          <div className="diagram-cell__ring">
            <PieChart size={38} value={openRate} color="#3424EB" name="openRate" />
          </div>
          <div className="diagram-cell__label">{Number(openRate)} %</div>
        </div>
      </Td>
      <Td className="emails-table__td">
        <div className="diagram-cell">
          <div className="diagram-cell__ring">
            <PieChart size={38} value={replyRate} color="#3424EB" name="replyRate" />
          </div>
          <div className="diagram-cell__label">{Number(replyRate)} %</div>
        </div>
      </Td>
      <Td className="emails-table__td">
        <div className="diagram-cell">
          <div className="diagram-cell__ring">
            <PieChart size={38} value={healthScoreRate} color="#3424EB" name="healthScore" />
          </div>
          <div className="diagram-cell__label">{Number(healthScoreRate)} %</div>
        </div>
      </Td>
      <Td>
        <span
          className="emails-table__actions-icon"
          data-for={`mailbox-settings-actions-${id}`}
          data-tip=""
          data-event="click focus"
        >
          <SVGIcon icon="actions" />
        </span>
        <ReactTooltip
          className="react-tooltip react-tooltip--actions"
          id={`mailbox-settings-actions-${id}`}
          place="left"
          effect="solid"
          globalEventOff="click"
          arrowColor="transparent"
          clickable
        >
          <ActionsTooltipContent onRemove={() => onRemove(id)} config={actionsConfig} />
        </ReactTooltip>
      </Td>
    </Tr>
  );
}

function EmailsTable({
  mailboxes,
  onOpen,
  onRemove,
  isDisableSharing,
  onShareClick,
}: EmailsTablePropsType): JSX.Element {
  return (
    <Table className="emails-table">
      <THead>
        <Tr>
          <Th className="emails-table__th" style={{ paddingLeft: '20px' }}>
            Email
          </Th>
          <Th className="emails-table__th">Connection</Th>
          <Th className="emails-table__th">Status</Th>
          <Th className="emails-table__th">Daily Limit</Th>
          <Th className="emails-table__th">Open Rate</Th>
          <Th className="emails-table__th">Reply Rate</Th>
          <Th className="emails-table__th">Health Score</Th>
          <Th />
        </Tr>
      </THead>
      <TBody>
        {mailboxes?.map((mailbox) => (
          <EmailsTableRow
            key={mailbox.id}
            {...mailbox}
            onOpen={onOpen}
            onRemove={onRemove}
            onShareClick={onShareClick}
            isDisableSharing={isDisableSharing}
          />
        ))}
      </TBody>
    </Table>
  );
}

export default EmailsTable;
