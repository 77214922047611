import { useQuery, useQueryClient } from 'react-query';
import { QueryObjectHookType } from '@ts/common.types';
import { getReferralLinkDataApi } from '@api/userOrganization.api';
import { ReferralLinkType } from '@ts/user.types';

export default function useReferralLink(): QueryObjectHookType<ReferralLinkType> {
  const queryClient = useQueryClient();

  const key = ['referral-link'];

  const { data, isLoading, isFetching, isRefetching, refetch } = useQuery<ReferralLinkType>({
    queryKey: key,
    queryFn: () => getReferralLinkDataApi(),
    refetchOnWindowFocus: false,
    retry: 1,
    enabled: !!localStorage.getItem('token'),
    staleTime: 90 * 60 * 1000,
  });

  const updateInCache = (payloadId: number, payload: ReferralLinkType) => {
    queryClient.setQueryData<ReferralLinkType>(key, (cachedData) => {
      return { ...cachedData, ...payload, id: payloadId };
    });
  };

  const evictCacheByKey = (id: number) => queryClient.removeQueries(key);

  const loadingState = isLoading || isFetching || isRefetching;

  return {
    item: data,
    refetch,
    isLoading: loadingState,
    update: updateInCache,
    evictByKey: evictCacheByKey,
  };
}
