import React from 'react';

import hexToRgba from 'src/utils/hexToRgba';

import './HaroCategories.scss';

// export default ({ hexColor, title }: { hexColor: string; title: string }): JSX.Element => (
//   <div className="haro-category" style={{ backgroundColor: hexToRgba(hexColor, '0.1') }}>
//     <div className="haro-category__text" style={{ color: hexColor }}>
//       {title}
//     </div>
//   </div>
// );

const HaroCategories = ({
  categories,
  categoriesColorsHash,
}: {
  categories: any[];
  categoriesColorsHash: any;
}): JSX.Element => {
  return (
    <>
      {categories.map((category, id) => {
        const hexColor = categoriesColorsHash[category];
        return (
          <div
            key={category}
            className="haro-category"
            style={{ backgroundColor: hexToRgba(hexColor, '0.1') }}
          >
            <div className="haro-category__text" style={{ color: hexColor }}>
              <span>
                {category}
              </span>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default HaroCategories;
