import React from 'react';

export default function ({ color = '#BDBDBD', size = 20 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0924 10.5897C13.9094 9.94695 14.5057 9.0655 14.7984 8.06802C15.0911 7.07054 15.0657 6.00662 14.7256 5.02429C14.3854 4.04196 13.7476 3.19006 12.9008 2.58712C12.054 1.98417 11.0403 1.66016 10.0007 1.66016C8.96119 1.66016 7.94749 1.98417 7.10067 2.58712C6.25385 3.19006 5.61602 4.04196 5.27591 5.02429C4.9358 6.00662 4.91032 7.07054 5.20303 8.06802C5.49574 9.0655 6.09207 9.94695 6.90906 10.5897C5.50913 11.1506 4.28764 12.0809 3.37481 13.2813C2.46198 14.4818 1.89203 15.9075 1.72573 17.4064C1.71369 17.5158 1.72333 17.6266 1.75408 17.7323C1.78484 17.838 1.83612 17.9366 1.90499 18.0225C2.04408 18.196 2.24638 18.3071 2.4674 18.3314C2.68841 18.3557 2.91003 18.2912 3.0835 18.1521C3.25697 18.0131 3.36809 17.8108 3.3924 17.5897C3.57538 15.9607 4.35214 14.4562 5.57425 13.3637C6.79636 12.2712 8.37815 11.6673 10.0174 11.6673C11.6566 11.6673 13.2384 12.2712 14.4605 13.3637C15.6827 14.4562 16.4594 15.9607 16.6424 17.5897C16.6651 17.7945 16.7628 17.9836 16.9167 18.1206C17.0705 18.2575 17.2697 18.3327 17.4757 18.3314H17.5674C17.7858 18.3063 17.9855 18.1958 18.1229 18.0241C18.2602 17.8524 18.3242 17.6334 18.3007 17.4147C18.1336 15.9116 17.5606 14.4822 16.6431 13.2799C15.7256 12.0776 14.4982 11.1476 13.0924 10.5897ZM10.0007 9.99807C9.34146 9.99807 8.69699 9.80257 8.14883 9.4363C7.60067 9.07003 7.17342 8.54944 6.92113 7.94035C6.66884 7.33126 6.60283 6.66104 6.73145 6.01444C6.86006 5.36783 7.17753 4.77389 7.64371 4.30771C8.10988 3.84154 8.70383 3.52407 9.35043 3.39545C9.99703 3.26683 10.6673 3.33285 11.2763 3.58514C11.8854 3.83743 12.406 4.26467 12.7723 4.81284C13.1386 5.361 13.3341 6.00547 13.3341 6.66474C13.3341 7.54879 12.9829 8.39664 12.3578 9.02176C11.7326 9.64688 10.8848 9.99807 10.0007 9.99807Z"
        fill={color}
      />
    </svg>
  );
}
