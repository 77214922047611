import React, { RefObject, useEffect, useRef, useState } from 'react';

import { useHistory } from 'react-router-dom';

import GradientBackgroundLoader from '@uikit/GradientBackgroundLoader/GradientBackgroundLoader';
import { SVGIcon } from '@uikit/Icon/Icon';
import { getBraintreeTokenApi } from '@api/billing.api';
import Display from '@components/Display/Display';
import urls from '@constants/urls';

import { setupBraintree } from './setupBraintree';

import './UseNewCard.scss';

type UseNewCardType = {
  submitBtnRef: RefObject<HTMLButtonElement>;
  onUseExistingCard?: () => void;
  gradientLoaderWidth: number;
  gradientLoaderHeight: number;
  isCancelable?: boolean;
  isButtonDisabled: boolean;
  disableButtonHandler: (value: boolean) => void;
  buttonClickCallback: (braintreeNonce: string, braintreeDeviceData: string) => void;
};

const UseNewCard = ({
  submitBtnRef,
  onUseExistingCard,
  gradientLoaderWidth = 700,
  gradientLoaderHeight = 78,
  buttonClickCallback,
  isCancelable = true,
  isButtonDisabled,
  disableButtonHandler,
}: UseNewCardType): JSX.Element => {
  const history = useHistory();

  const brainTreeIsInstalled = useRef<boolean>(false);

  const [isBraintreeLoading, changeIsBraintreeLoading] = useState(false);

  useEffect(() => {
    if (submitBtnRef.current && !brainTreeIsInstalled.current) {
      // isButtonDisabled = true;
      changeIsBraintreeLoading(true);
      brainTreeIsInstalled.current = true;

      getBraintreeTokenApi()
        .then(({ value }) => {
          setupBraintree({
            token: value,
            submitBtnRef,
            buttonClickCallback,
            disableButtonHandler,
            isButtonInitiallyDisabled: isButtonDisabled,
          })
            .then(() => changeIsBraintreeLoading(false))
            .catch(() => {
              brainTreeIsInstalled.current = false;
            });
        })
        .catch(() => {
          brainTreeIsInstalled.current = false;
        });
    }
  }, [submitBtnRef, submitBtnRef.current]);

  return (
    <GradientBackgroundLoader
      width={gradientLoaderWidth}
      height={gradientLoaderHeight}
      isLoading={isBraintreeLoading}
      borderRadius={8}
    >
      <form className="subscription-payment-form" method="post">
        <div className="subscription-payment-form__fields" id="checkout">
          <div className="subscription-payment-form__input-field-wrapper">
            <div id="card-image" />
            <div
              className="subscription-payment-form__input-field subscription-payment-form__input-field--card-number"
              id="card-number"
              data-braintree-name="number"
            />
          </div>
          <div
            className="subscription-payment-form__input-field"
            id="expiration-date"
            data-braintree-name="expiration_date"
          />
          <div
            className="subscription-payment-form__input-field"
            id="cvv"
            data-braintree-name="cvv"
          />
          <Display
            isVisible={history.location.pathname !== urls.ONBOARDING_PAYMENT && isCancelable}
          >
            {onUseExistingCard ? (
              <span
                className="subscription-payment-form__cancel-btn"
                onClick={() => onUseExistingCard}
              >
                <SVGIcon icon="close" size={12} color="#ffffff" />
              </span>
            ) : null}
          </Display>
        </div>
      </form>
    </GradientBackgroundLoader>
  );
};

export default UseNewCard;
