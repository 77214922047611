import React, { useEffect, useState } from 'react';

import loadingStatuses from '@constants/loadingStatuses';
import { getAllEmailsByThreadApi } from '@api/mailbox.api';
import InboxThreadsList from '@components/InboxThreadsList/InboxThreadsList';
import Loader from '@uikit/Loader/Loader';
import ThreadEmails from '@uikit/ThreadEmails/ThreadEmails';
import { checkIsAnyNotLoaded } from '@utils/loadingChecks';

import './InboxThreadsContent.scss';
import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';

function InboxThreadsContent({ threads, fetchNextPage, isFetchingNextPage, hasNextPage }) {
  const [activeThreadIndex, changeActiveThreadIndex] = useState(null);
  const currentWorkspaceId = useCurrentWorkspaceId();
  const [content, changeContent] = useState([]);
  const [contentLs, changeContentLs] = useState(loadingStatuses.PENDING);

  useEffect(() => {
    if (activeThreadIndex != null) {
      changeContentLs(loadingStatuses.INITED);

      getAllEmailsByThreadApi(threads[activeThreadIndex].uid, currentWorkspaceId)
        .then((contentData) => changeContent(contentData))
        .finally(() => changeContentLs(loadingStatuses.LOADED));
    }
  }, [activeThreadIndex]);

  if (threads?.length === 0) {
    return (
      <div className="analytics-inbox-threads-content-empty-message">
        <div>There are no threads yet.</div>
        <div>Please wait for messages to be sent</div>
      </div>
    );
  }

  return (
    <div className="analytics-inbox-threads-content">
      <InboxThreadsList
        activeIndex={activeThreadIndex}
        threadList={threads}
        onChangeActiveThread={changeActiveThreadIndex}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        hasNextPage={hasNextPage}
      />
      <div className="analytics-inbox-threads-content__content">
        {activeThreadIndex === null ? (
          <div className="analytics-inbox-threads-content-select-message">Select thread</div>
        ) : (
          <Loader isLoading={checkIsAnyNotLoaded(contentLs)} withTopMargin>
            <ThreadEmails
              emails={content}
              isActionButtonHidden
              onDraftCreation={() => {
                /** ignore */
              }}
              onDraftDelete={() => {
                /** ignore */
              }}
            />
          </Loader>
        )}
      </div>
    </div>
  );
}

export default InboxThreadsContent;
