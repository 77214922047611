import React from 'react';

import cn from 'class-names';
import ReactTooltip from 'react-tooltip';

import stripHtml from '@utils/stripHtml';

import ModerateChanceLevelBar from './_components/ModerateChanceLevelBar/ModerateChanceLevelBar';
import SpamWords from './_components/SpamWords/SpamWords';

import config from './config';

import './ModerateChance.scss';

const ModerateChance = ({
  subject,
  template,
  id = 'moderate-chance-tooltip',
  tooltipBottom = 50,
}: {
  subject: string;
  template: string;
  id?: string;
  tooltipBottom?: number;
}): JSX.Element => {
  const parsedParamsValues = config.reduce(
    (acc, curParam) => ({
      ...acc,
      [curParam.key]: curParam.parse({ subject, text: stripHtml(template).trim(), template }),
    }),
    {}
  );

  const levelsList = Object.values(parsedParamsValues).map(
    ({ boundary }) => (boundary && boundary.color) || 'red'
  );

  const isGreen = () => {
    return levelsList.filter((level) => level === 'green').length === levelsList.length;
  };

  const isYellow = () => {
    return levelsList.some((level) => level === 'yellow');
  };

  const isRed = () => {
    return levelsList.some((level) => level === 'red');
  };

  const isGrey = () => {
    return Object.values(parsedParamsValues).every(({ value }) => value === 0);
  };

  const cnModerateChanceLevel = cn('moderate-chance__level', {
    'moderate-chance__level--green': isGreen(),
    'moderate-chance__level--yellow': isYellow(),
    'moderate-chance__level--red': isRed(),
    'moderate-chance__level--grey': isGrey(),
  });

  const getStatusEmailAnalyzer = (): string => {
    if (isRed()) {
      return 'Low chance';
    }

    if (isYellow()) {
      return 'Moderate chance';
    }

    if (isGreen()) {
      return 'High chance';
    }

    return '';
  };

  return (
    <div>
      <div className="moderate-chance" data-for={id} data-tip="">
        <div className={cnModerateChanceLevel} />
        {/* eslint-disable-next-line no-nested-ternary */}
        <p className={isGrey() ? 'ml-0' : ''}>{isGrey() ? '' : isRed() || isYellow() ? 'Needs some work' : isGreen() ? 'Good to go' : ''}</p>
      </div>

      {isGrey() ? null : (
        <ReactTooltip
          id={id}
          place="left"
          effect="solid"
          globalEventOff="click"
          offset={{ bottom: tooltipBottom, left: 0 }}
          delayHide={300}
          arrowColor="transparent"
        >
          <div className="moderate-chance-tooltip-content">
            <div className="moderate-chance-tooltip-content__title">{getStatusEmailAnalyzer()}</div>
            <div className="moderate-chance-tooltip-content__subtitle">to receive a response</div>
            {config.map((paramConfig) => (
              <ModerateChanceLevelBar
                key={paramConfig.key}
                {...paramConfig}
                parsedValue={parsedParamsValues[paramConfig.key]}
              />
            ))}
            <SpamWords template={template} />
          </div>
        </ReactTooltip>
      )}
    </div>
  );
};

export default ModerateChance;
