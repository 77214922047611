import React from 'react';

export default function ({ color = '#FFFFFF', size = '16' }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="10" fill={color} />
      <g clipPath="url(#clip0_22035_1194)">
        <path
          d="M14.595 12.5602L11.0783 5.912C10.7621 5.31635 10.0232 5.08977 9.4275 5.40595C9.21197 5.52042 9.03592 5.69648 8.92145 5.912L5.40473 12.5602C5.09171 13.1538 5.31908 13.8892 5.91316 14.2023C6.08881 14.295 6.285 14.3432 6.48355 14.3424H13.5162C14.1872 14.3444 14.7332 13.802 14.7351 13.1306C14.7359 12.9316 14.6878 12.7358 14.595 12.5602ZM9.99987 13.1582C9.67302 13.1582 9.40776 12.8929 9.40776 12.5661C9.40776 12.2392 9.67302 11.974 9.99987 11.974C10.3267 11.974 10.592 12.2392 10.592 12.5661C10.592 12.8929 10.3267 13.1582 9.99987 13.1582ZM10.2091 11.1845H9.79026C9.68605 11.1845 9.5996 11.1036 9.59329 10.9994L9.42039 8.23621C9.41329 8.12253 9.50368 8.02661 9.61737 8.02661H10.3816C10.4953 8.02661 10.5857 8.12253 10.5786 8.23621L10.4057 10.9994C10.3993 11.1036 10.3133 11.1845 10.2091 11.1845Z"
          fill="#FC8E0E"
        />
      </g>
      <defs>
        <clipPath id="clip0_22035_1194">
          <rect
            width="9.47368"
            height="9.47368"
            fill="white"
            transform="translate(5.26316 5.26318)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
