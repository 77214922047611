import React from 'react';

export default function ({ color = '#BDBDBD', size = 20 }) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 13.5L12.5 13.5C13.0523 13.5 13.5 13.0523 13.5 12.5L13.5 4C13.5 3.44771 13.0523 3 12.5 3L4 3C3.44771 3 3 3.44771 3 4L3 12.5C3 13.0523 3.44771 13.5 4 13.5Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.5 10.5V1.5C0.5 1.23478 0.605357 0.98043 0.792893 0.792893C0.98043 0.605357 1.23478 0.5 1.5 0.5H10.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
