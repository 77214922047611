import { Dispatch } from 'redux';
import { searchLastEpisodeApi } from '@api/contentSerach.api';
import { getSummariesApi, searchSummariesApi } from '@api/searchSummary.api';
import { ContentSearchTypeMap } from 'respona_api/generated/common_pb';

import loadingStatuses from 'src/constants/loadingStatuses';
import { isPodcastType } from 'src/helpers/contentSearchHelpers';
import isUrl from '@utils/isUrl';
import { addNotification } from '@redux/actions/notifications.actions';
import {
  createArticleSummary,
  setArticleSummary,
  setArticleSummaryLs,
  setAudioInfo,
  setAudioInfoLs,
} from '../actions/articleSummary.actions';

export const getAudioInfo =
  (
    opportunityId: number,
    opportunityUid: string,
    opportunityType: ContentSearchTypeMap[keyof ContentSearchTypeMap]
  ) =>
  (dispatch: Dispatch) => {
    dispatch(createArticleSummary({ opportunityId, isPodcast: true }));
    return searchLastEpisodeApi(opportunityUid, opportunityType)
      .then((info) => {
        dispatch(
          setAudioInfo({
            opportunityId,
            data: { info, requested: true },
          })
        );
      })
      .finally(() => {
        dispatch(setAudioInfoLs({ opportunityId, loadingStatus: loadingStatuses.LOADED }));
      });
  };

export const getArticleSummary =
  (workspaceId: number, opportunityId: number, opportunityUrl: string, isPodcast: boolean) =>
  (dispatch: Dispatch) => {
    if (!isPodcast) {
      dispatch(createArticleSummary({ opportunityId, isPodcast: false }));
    }
    if (isUrl(opportunityUrl)) {
      return getSummariesApi(opportunityUrl, workspaceId)
        .then((summary) => {
          dispatch(setArticleSummary({ opportunityId, summary }));
        })
        .finally(() => {
          dispatch(setArticleSummaryLs({ opportunityId, loadingStatus: loadingStatuses.LOADED }));
        });
    }

    dispatch(setArticleSummaryLs({ opportunityId, loadingStatus: loadingStatuses.LOADED }));
    dispatch(
      addNotification({
        title:
          "Oops! It looks like the URL for the opportunity you provided isn't in the correct format, so we're unable to summarize it.",
        type: 'warning',
      })
    );
  };

export const searchArticleSummary =
  (
    workspaceId: number,
    opportunityId: number,
    opportunityUrl: string,
    opportunityUid: string,
    isPodcast: boolean,
    processCallback
  ) =>
  (dispatch: Dispatch) => {
    return searchSummariesApi(opportunityUrl, workspaceId, processCallback)
      .then((res) => {
        const result = res.slice().pop();
        dispatch(setArticleSummary({ opportunityId, summary: result.response }));
      })
      .finally(() => {
        dispatch(setArticleSummaryLs({ opportunityId, loadingStatus: loadingStatuses.LOADED }));
      });
  };
