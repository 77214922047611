import React from 'react';

export default ({ color = '#221CB6', size = 15 }: { color?: string; size?: number }) => (
  <svg width={size} height={size} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.46667 0.746667H2.8C1.66598 0.746667 0.746667 1.66598 0.746667 2.8V7.46667C0.746667 8.60069 1.66598 9.52 2.8 9.52H7.46667C8.60069 9.52 9.52 8.60069 9.52 7.46667V2.8C9.52 1.66598 8.60069 0.746667 7.46667 0.746667ZM2.8 0C1.2536 0 0 1.2536 0 2.8V7.46667C0 9.01306 1.2536 10.2667 2.8 10.2667H7.46667C9.01306 10.2667 10.2667 9.01306 10.2667 7.46667V2.8C10.2667 1.2536 9.01306 0 7.46667 0H2.8Z"
      fill={color}
    />
    <path
      d="M6.5334 4.04745H11.2001C12.0579 4.04745 12.7534 4.7429 12.7534 5.60078V10.2674C12.7534 11.1253 12.0579 11.8208 11.2001 11.8208H6.5334C5.67552 11.8208 4.98007 11.1253 4.98007 10.2674V5.60078C4.98007 4.7429 5.67552 4.04745 6.5334 4.04745ZM6.5334 2.30078C4.71086 2.30078 3.2334 3.77824 3.2334 5.60078V10.2674C3.2334 12.09 4.71086 13.5674 6.5334 13.5674H11.2001C13.0226 13.5674 14.5001 12.09 14.5001 10.2674V5.60078C14.5001 3.77824 13.0226 2.30078 11.2001 2.30078H6.5334Z"
      fill={color}
      stroke="#FBFCFD"
    />
  </svg>
);


export const CopyIcon2 = ({ color = '#221CB6', size = 16 }: { color?: string; size?: number }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.1382 5.45211C14.667 5.91855 15.0005 6.60135 15.0005 7.36178V9.90715C15.0005 11.3128 13.8608 12.4525 12.4551 12.4525H6.72799C5.32231 12.4525 4.18262 11.3128 4.18262 9.90715V7.36178C4.18262 5.9561 5.32231 4.81641 6.72799 4.81641H9.27336"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M1.86224 8.00041C1.33344 7.53397 1 6.85117 1 6.09074V3.54537C1 2.13969 2.13969 1 3.54537 1H9.27246C10.6781 1 11.8178 2.13969 11.8178 3.54537V6.09074C11.8178 7.49643 10.6781 8.63612 9.27246 8.63612H6.72709"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);
