import React from 'react';

export default () => (
  <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="23" height="23" rx="11.5" fill="#FFD8D8" />
    <path
      d="M14 9L9 14"
      stroke="#EB5757"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M14 14L9 9"
      stroke="#EB5757"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);
