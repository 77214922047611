import React from 'react';

const EpisodesSvg = ({ size = 33 }: { size?: number }): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 33 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.7779 1H9.33344C7.79932 1 6.55566 2.24365 6.55566 3.77778V17.6667C6.55566 19.2008 7.79932 20.4444 9.33344 20.4444H28.7779C30.312 20.4444 31.5557 19.2008 31.5557 17.6667V3.77778C31.5557 2.24365 30.312 1 28.7779 1Z"
        stroke="#221CB6"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M1 6.55566V23.2223C1 23.959 1.29266 24.6656 1.81359 25.1865C2.33453 25.7075 3.04107 26.0001 3.77778 26.0001H26"
        stroke="#221CB6"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.2778 6.55566L23.2223 10.7223L16.2778 14.889V6.55566Z"
        stroke="#221CB6"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EpisodesSvg;
