import { StateType } from 'src/redux/reducers';
import { prepareSequenceSteps } from '@helpers/sequenceHelpers';

export const campaignAnalyticsTemplate = ({ campaignAnalytics: { template } }: StateType) => ({
  ...template,
  data: {
    ...template.data,
    stepsList: prepareSequenceSteps(template.data?.stepsList || []),
  },
});
