import React from 'react';

const AhrefsLogo = ({ width = 133, height = 34 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 133 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.25603 13.3737H15.2537V17.0286L10.3656 17.4046C2.47271 17.9941 0 20.0876 0 25.9444V27.1265C0 31.4219 3.00803 33.9998 7.62842 33.9998C11.2801 33.9998 13.3641 33.1394 15.9547 30.4946H16.3721V33.2892H21.582V7.66992H2.25603V13.3737ZM15.2537 25.7277C13.6604 27.34 11.226 28.4139 9.23759 28.4139C7.08991 28.4139 6.17858 27.6077 6.22956 25.836C6.28373 23.3633 7.1855 22.7738 11.3279 22.3978L15.2473 22.0218L15.2537 25.7277Z"
        fill="#FF8800"
      />
      <path
        d="M124.346 17.9936L120.477 17.6112C118.33 17.3977 117.791 16.8592 117.791 15.1417C117.791 13.2075 118.543 12.7232 121.551 12.7232C124.237 12.7232 124.989 13.1534 125.375 14.9792H131.391C131.228 8.85481 129.348 7.2998 121.882 7.2998C113.719 7.2998 111.686 8.85481 111.686 15.1417C111.686 20.8774 113.19 22.4451 119.26 23.0346L122.431 23.3533C125.276 23.6241 125.974 24.1595 125.974 25.8801C125.974 27.9737 125.114 28.5122 121.624 28.5122C118.562 28.5122 117.756 28.0278 117.597 26.0395H111.507C111.667 32.3774 113.601 33.9897 121.338 33.9897C129.941 33.9897 132.079 32.2722 132.079 25.2875C132.079 20.0871 130.521 18.5831 124.346 17.9936Z"
        fill="#054ADA"
      />
      <path
        d="M92.1178 18.4784C92.1178 10.1936 89.2691 7.30664 81.1053 7.30664C73.0499 7.30664 69.8794 11.0635 69.8794 20.4094C69.8794 30.6061 72.6739 33.9997 81.698 33.9997C88.1953 33.9997 90.9388 32.0146 91.8469 26.4255H85.6716C84.9737 28.1462 84.2217 28.5222 81.5897 28.5222C77.6162 28.5222 76.5933 27.3942 76.3798 22.6145H91.6749C91.9384 21.2507 92.0866 19.8671 92.1178 18.4784ZM76.434 17.5639C76.4881 13.9664 77.938 12.6217 81.373 12.6217C84.6519 12.6217 85.9934 13.9664 86.1017 17.5639H76.434Z"
        fill="#054ADA"
      />
      <path
        d="M42.7562 7.68259H32.7124V0H26.3203V33.3019H32.7124V13.4278H41.8416V33.3019H48.2337V12.8925H42.7562V7.68259Z"
        fill="#054ADA"
      />
      <path
        d="M95.7153 8.80742V33.3019H102.107V13.1506H109.787V7.68259H102.107V6.98157C102.162 5.47755 102.538 5.05057 103.774 5.05057H110.756V0H103.235C98.1339 0 95.7153 1.55819 95.7153 8.80742Z"
        fill="#054ADA"
      />
      <path d="M52.9595 33.3019H59.3515V13.6955H68.86V7.68262H52.9595V33.3019Z" fill="#054ADA" />
    </svg>
  );
};

export default AhrefsLogo;
