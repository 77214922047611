import React from 'react';

export default ({
  color = '#3424EB',
  size = 18,
}: {
  color?: string;
  size?: number;
}): JSX.Element => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.47925 6.49575C9.41506 6.4343 9.33936 6.38613 9.2565 6.354C9.09217 6.28649 8.90784 6.28649 8.7435 6.354C8.66065 6.38613 8.58495 6.4343 8.52075 6.49575L6.49575 8.52075C6.36865 8.64785 6.29724 8.82024 6.29724 9C6.29724 9.17975 6.36865 9.35214 6.49575 9.47925C6.62286 9.60635 6.79525 9.67776 6.975 9.67776C7.15476 9.67776 7.32715 9.60635 7.45425 9.47925L8.325 8.60175V11.025C8.325 11.204 8.39612 11.3757 8.52271 11.5023C8.64929 11.6289 8.82098 11.7 9 11.7C9.17902 11.7 9.35071 11.6289 9.4773 11.5023C9.60389 11.3757 9.675 11.204 9.675 11.025V8.60175L10.5458 9.47925C10.6085 9.54251 10.6832 9.59273 10.7654 9.627C10.8477 9.66127 10.9359 9.67891 11.025 9.67891C11.1141 9.67891 11.2023 9.66127 11.2846 9.627C11.3668 9.59273 11.4415 9.54251 11.5043 9.47925C11.5675 9.4165 11.6177 9.34184 11.652 9.25959C11.6863 9.17733 11.7039 9.08911 11.7039 9C11.7039 8.91089 11.6863 8.82266 11.652 8.74041C11.6177 8.65815 11.5675 8.5835 11.5043 8.52075L9.47925 6.49575ZM9 2.25C7.66498 2.25 6.35994 2.64588 5.2499 3.38758C4.13987 4.12928 3.27471 5.18348 2.76382 6.41689C2.25292 7.65029 2.11925 9.00749 2.3797 10.3169C2.64015 11.6262 3.28303 12.829 4.22703 13.773C5.17104 14.717 6.37377 15.3598 7.68314 15.6203C8.99251 15.8807 10.3497 15.7471 11.5831 15.2362C12.8165 14.7253 13.8707 13.8601 14.6124 12.7501C15.3541 11.6401 15.75 10.335 15.75 9C15.75 8.11358 15.5754 7.23583 15.2362 6.41689C14.897 5.59794 14.3998 4.85382 13.773 4.22703C13.1462 3.60023 12.4021 3.10303 11.5831 2.76381C10.7642 2.42459 9.88642 2.25 9 2.25ZM9 14.4C7.93198 14.4 6.88795 14.0833 5.99992 13.4899C5.1119 12.8966 4.41977 12.0532 4.01105 11.0665C3.60234 10.0798 3.4954 8.99401 3.70376 7.94651C3.91212 6.89901 4.42642 5.93683 5.18163 5.18162C5.93683 4.42642 6.89902 3.91212 7.94651 3.70376C8.99401 3.4954 10.0798 3.60234 11.0665 4.01105C12.0532 4.41976 12.8966 5.11189 13.4899 5.99992C14.0833 6.88795 14.4 7.93198 14.4 9C14.4 10.4322 13.8311 11.8057 12.8184 12.8184C11.8057 13.8311 10.4322 14.4 9 14.4Z"
      fill={color}
    />
  </svg>
);
