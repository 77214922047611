import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb';
import { contentSearchTypeMap, urlSearchTypeMap } from '@mappers/contentSearch.mappers';
import { SearchItemType, UrlItemType } from '@ts/contentSearch.types';
import { AudioGenreType } from '@ts/common.types';

import contentSearchService from '@api/services/contentSearchService';
import streamRequest from '@api/base/streamRequest';
import request from '@api/base/request';
import { ContentSearchTypeMap, TimestampRange } from 'respona_api/generated/common_pb';
import * as common_pb from 'respona_api/generated/common_pb';
import {
  ArticleSearchFilters,
  AudioDetailsRequest,
  DateFilter,
  EpisodeSearchFilters,
  PodcastSearchFilters,
  SearchRequest,
  TrendingContentSearchFilters,
  UrlRequest,
} from 'respona_api/generated/search-content_pb';

const createArticleFilters = (filters) => {
  const articleSearchFilters = new ArticleSearchFilters();

  if (filters.freshness) {
    const dateFilter = new DateFilter();
    const timestampRange = new TimestampRange();

    timestampRange.setFrom(filters.freshness.startDate);
    timestampRange.setTo(filters.freshness.endDate);
    dateFilter.setFreshness(6);
    dateFilter.setCustomFreshnessValue(timestampRange);
    articleSearchFilters.setFreshness(dateFilter);
  }

  articleSearchFilters.setLocation(filters.location?.value);
  articleSearchFilters.setLanguage(filters.language?.value);

  return articleSearchFilters;
};

const createSearchRequestWithFilters = (contentType, filters) => {
  const contentSearchRequest = new SearchRequest();

  switch (contentType) {
    // Web search
    case 0: {
      contentSearchRequest.setGoogleSearch(createArticleFilters(filters));
      break;
    }
    // News search
    case 1: {
      contentSearchRequest.setBingNews(createArticleFilters(filters));
      break;
    }
    // Podcast search
    case 2: {
      const podcastSearchFilters = new PodcastSearchFilters();

      if (filters.freshness) {
        const timestampRange = new TimestampRange();
        podcastSearchFilters.setFreshness(6);

        timestampRange.setFrom(filters.freshness.startDate);
        timestampRange.setTo(filters.freshness.endDate);
        podcastSearchFilters.setCustomFreshnessValue(timestampRange);
      }

      podcastSearchFilters.setLocation(filters.location?.value);
      podcastSearchFilters.setLanguage(filters.language?.value);

      contentSearchRequest.setPodcast(podcastSearchFilters);
      break;
    }
    // Episodes search
    case 3: {
      const episodesSearchFilters = new EpisodeSearchFilters();

      if (filters.freshness) {
        const timestampRange = new TimestampRange();
        episodesSearchFilters.setFreshness(6);

        timestampRange.setFrom(filters.freshness.startDate);
        timestampRange.setTo(filters.freshness.endDate);
        episodesSearchFilters.setCustomFreshnessValue(timestampRange);
      }

      episodesSearchFilters.setOnePerPodcast(filters.uniquePodcasts?.value === 'unique');
      episodesSearchFilters.setLocation(filters.location?.value);
      episodesSearchFilters.setLanguage(filters.language?.value);
      episodesSearchFilters.setDurationMinutes(filters.duration);

      contentSearchRequest.setEpisode(episodesSearchFilters);
      break;
    }
    case 4: {
      const trendingSearchFilters = new TrendingContentSearchFilters();

      // @ts-ignore
      trendingSearchFilters.setMinWordsNumber(filters?.minWordCount);
      trendingSearchFilters.setLanguage(filters.language?.value || 'en');
      trendingSearchFilters.setFreshness(filters.freshness ? 6 : 4);

      if (filters.freshness) {
        const timestampRange = new TimestampRange();

        timestampRange.setFrom(filters.freshness.startDate);
        timestampRange.setTo(filters.freshness.endDate);
        trendingSearchFilters.setCustomFreshnessValue(timestampRange);
      }

      contentSearchRequest.setTrendingContent(trendingSearchFilters);
      break;
    }
    // Blogs search
    case 5: {
      contentSearchRequest.setBingSearch(createArticleFilters(filters));
      break;
    }
    default: {
      contentSearchRequest.setGoogleSearch(createArticleFilters(filters));
      break;
    }
  }

  return contentSearchRequest;
};

export const searchUrls = (query = '', limit = 10, type: 0 | 1): Promise<UrlItemType[]> => {
  const service = contentSearchService.getInstance();

  const urlRequest = new UrlRequest();
  urlRequest.setQuery(query);
  urlRequest.setLimit(limit);
  urlRequest.setType(type);

  return streamRequest<SearchItemType[]>(service, service.searchUrls, urlRequest);
};

export const search = (
  query = '',
  contentType: keyof typeof contentSearchTypeMap,
  filters: {
    [key: string]: any;
  },
  campaignId: number,
  workspaceId: number,
  batch: number,
  curPage = 1
): Promise<SearchItemType[]> => {
  const computedSourceType = contentSearchTypeMap[
    contentType
  ] as common_pb.ContentSearchTypeMap[keyof common_pb.ContentSearchTypeMap];

  const service = contentSearchService.getInstance();
  const contentSearchRequest = createSearchRequestWithFilters(computedSourceType, filters);

  contentSearchRequest.setQuery(query);
  contentSearchRequest.setType(computedSourceType);
  contentSearchRequest.setWorkspaceId(workspaceId);
  contentSearchRequest.setCampaignId(campaignId);
  contentSearchRequest.setBatch(batch);
  contentSearchRequest.setPage(curPage);

  return streamRequest<SearchItemType[]>(service, service.search, contentSearchRequest).then(
    (response) => {
      return response.reduce((acc, curValue) => {
        if (acc.findIndex(({ uid }) => uid === curValue.uid) !== -1) {
          return acc.map((item) => {
            if (item.uid === curValue.uid) {
              return {
                ...item,
                ...curValue,
              };
            }

            return item;
          });
        }

        return [...acc, curValue];
      }, []);
    }
  );
};

export const searchLastEpisodeApi = (
  uid: string,
  type: ContentSearchTypeMap[keyof ContentSearchTypeMap]
): Promise<SearchItemType> => {
  const service = contentSearchService.getInstance();
  const contentSearchRequest = new AudioDetailsRequest();

  contentSearchRequest.setUid(uid);
  contentSearchRequest.setType(type);

  return request<SearchItemType>(service, service.searchLastEpisode, contentSearchRequest);
};

export const getAudioGenresApi = (): Promise<AudioGenreType[]> => {
  const service = contentSearchService.getInstance();
  const emptyRequest = new google_protobuf_empty_pb.Empty();

  return streamRequest<AudioGenreType[]>(service, service.getAudioGenres, emptyRequest);
};
