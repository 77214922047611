import React from 'react';

export default ({ color = '#C4C4C4' }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.6666 16.6667H17.5C17.721 16.6667 17.9329 16.7545 18.0892 16.9107C18.2455 17.067 18.3333 17.279 18.3333 17.5C18.3333 17.721 18.2455 17.933 18.0892 18.0893C17.9329 18.2455 17.721 18.3333 17.5 18.3333H2.49996C2.27895 18.3333 2.06698 18.2455 1.9107 18.0893C1.75442 17.933 1.66663 17.721 1.66663 17.5V2.5C1.66663 2.27898 1.75442 2.06702 1.9107 1.91074C2.06698 1.75446 2.27895 1.66666 2.49996 1.66666C2.72097 1.66666 2.93293 1.75446 3.08922 1.91074C3.2455 2.06702 3.33329 2.27898 3.33329 2.5V16.6667H4.99996H6.66663H8.33329H9.99996H11.6666H13.3333H15H16.6666Z"
      fill={color}
    />
    <path
      d="M5 13C5.44 11.266 6.584 7.88459 7.64 8.2314C8.696 8.57821 9.84 10.1099 10.28 10.8325C10.8667 11.555 12.216 11.7862 12.92 6.93087C13.624 2.07557 15.2667 4.32981 16 6.06385"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);
