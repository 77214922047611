// package: billing
// file: billing.proto

var billing_pb = require("./billing_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var google_protobuf_wrappers_pb = require("google-protobuf/google/protobuf/wrappers_pb");
var common_pb = require("./common_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var BillingApiService = (function () {
  function BillingApiService() {}
  BillingApiService.serviceName = "billing.BillingApiService";
  return BillingApiService;
}());

BillingApiService.getBillingDetails = {
  methodName: "getBillingDetails",
  service: BillingApiService,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: billing_pb.BillingResponse
};

BillingApiService.getBillingShortDetails = {
  methodName: "getBillingShortDetails",
  service: BillingApiService,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: billing_pb.BillingDetailsShortResponse
};

BillingApiService.getCancellationDetails = {
  methodName: "getCancellationDetails",
  service: BillingApiService,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: billing_pb.BillingCancellationResponse
};

BillingApiService.cancelAndStoreFeedback = {
  methodName: "cancelAndStoreFeedback",
  service: BillingApiService,
  requestStream: false,
  responseStream: false,
  requestType: billing_pb.BillingCancellationFeedbackRequest,
  responseType: billing_pb.BillingCancellationActionResponse
};

BillingApiService.resumeCancellation = {
  methodName: "resumeCancellation",
  service: BillingApiService,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: google_protobuf_empty_pb.Empty
};

BillingApiService.updateCreditCardInfo = {
  methodName: "updateCreditCardInfo",
  service: BillingApiService,
  requestStream: false,
  responseStream: false,
  requestType: billing_pb.UpdateCreditCardRequest,
  responseType: billing_pb.CreditCardInfo
};

BillingApiService.getInvoices = {
  methodName: "getInvoices",
  service: BillingApiService,
  requestStream: false,
  responseStream: true,
  requestType: billing_pb.InvoiceRequest,
  responseType: billing_pb.InvoiceShortResponse
};

BillingApiService.getInvoiceDetails = {
  methodName: "getInvoiceDetails",
  service: BillingApiService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: billing_pb.InvoiceDetailsResponse
};

BillingApiService.getCurrentInvoice = {
  methodName: "getCurrentInvoice",
  service: BillingApiService,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: billing_pb.InvoiceContainer
};

BillingApiService.getBraintreeToken = {
  methodName: "getBraintreeToken",
  service: BillingApiService,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: google_protobuf_wrappers_pb.StringValue
};

BillingApiService.makePayment = {
  methodName: "makePayment",
  service: BillingApiService,
  requestStream: false,
  responseStream: false,
  requestType: billing_pb.PaymentRequest,
  responseType: billing_pb.PaymentResponse
};

BillingApiService.getSubscriptionPreset = {
  methodName: "getSubscriptionPreset",
  service: BillingApiService,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: billing_pb.SubscriptionPresetResponse
};

BillingApiService.calcSubscriptionPrice = {
  methodName: "calcSubscriptionPrice",
  service: BillingApiService,
  requestStream: false,
  responseStream: false,
  requestType: billing_pb.SubscriptionPriceRequest,
  responseType: google_protobuf_wrappers_pb.DoubleValue
};

BillingApiService.changeSubscription = {
  methodName: "changeSubscription",
  service: BillingApiService,
  requestStream: false,
  responseStream: false,
  requestType: billing_pb.SubscriptionChangeRequest,
  responseType: billing_pb.PaymentResponse
};

BillingApiService.getBillingAddress = {
  methodName: "getBillingAddress",
  service: BillingApiService,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: billing_pb.BillingAddress
};

BillingApiService.updateBillingAddress = {
  methodName: "updateBillingAddress",
  service: BillingApiService,
  requestStream: false,
  responseStream: false,
  requestType: billing_pb.BillingAddress,
  responseType: billing_pb.BillingAddress
};

BillingApiService.calcMailboxAccountPrice = {
  methodName: "calcMailboxAccountPrice",
  service: BillingApiService,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_wrappers_pb.Int32Value,
  responseType: billing_pb.MailboxAccountPriceResponse
};

BillingApiService.upgradeMailboxAccountsNumber = {
  methodName: "upgradeMailboxAccountsNumber",
  service: BillingApiService,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_wrappers_pb.Int32Value,
  responseType: billing_pb.PaymentResponse
};

BillingApiService.changeMailboxAccountChargeStrategy = {
  methodName: "changeMailboxAccountChargeStrategy",
  service: BillingApiService,
  requestStream: false,
  responseStream: false,
  requestType: billing_pb.MailboxAccountChargeStrategyRequest,
  responseType: google_protobuf_empty_pb.Empty
};

BillingApiService.calcPlanCreditsPrice = {
  methodName: "calcPlanCreditsPrice",
  service: BillingApiService,
  requestStream: false,
  responseStream: false,
  requestType: billing_pb.PlanPriceRequest,
  responseType: google_protobuf_wrappers_pb.DoubleValue
};

BillingApiService.upgradePlanCredits = {
  methodName: "upgradePlanCredits",
  service: BillingApiService,
  requestStream: false,
  responseStream: false,
  requestType: billing_pb.PlanPriceRequest,
  responseType: billing_pb.PaymentResponse
};

BillingApiService.calcPlanContactsPrice = {
  methodName: "calcPlanContactsPrice",
  service: BillingApiService,
  requestStream: false,
  responseStream: false,
  requestType: billing_pb.PlanPriceRequest,
  responseType: google_protobuf_wrappers_pb.DoubleValue
};

BillingApiService.upgradePlanContacts = {
  methodName: "upgradePlanContacts",
  service: BillingApiService,
  requestStream: false,
  responseStream: false,
  requestType: billing_pb.PlanPriceRequest,
  responseType: billing_pb.PaymentResponse
};

BillingApiService.calcBonusCreditsPrice = {
  methodName: "calcBonusCreditsPrice",
  service: BillingApiService,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_wrappers_pb.Int32Value,
  responseType: google_protobuf_wrappers_pb.DoubleValue
};

BillingApiService.buyBonusCredits = {
  methodName: "buyBonusCredits",
  service: BillingApiService,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_wrappers_pb.Int32Value,
  responseType: billing_pb.PaymentResponse
};

BillingApiService.changeBonusCreditsChargeStrategy = {
  methodName: "changeBonusCreditsChargeStrategy",
  service: BillingApiService,
  requestStream: false,
  responseStream: false,
  requestType: billing_pb.MailboxBonusCreditsStrategyRequest,
  responseType: google_protobuf_empty_pb.Empty
};

BillingApiService.generateInvoicePdf = {
  methodName: "generateInvoicePdf",
  service: BillingApiService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: common_pb.FileResponse
};

exports.BillingApiService = BillingApiService;

function BillingApiServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

BillingApiServiceClient.prototype.getBillingDetails = function getBillingDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingApiService.getBillingDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingApiServiceClient.prototype.getBillingShortDetails = function getBillingShortDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingApiService.getBillingShortDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingApiServiceClient.prototype.getCancellationDetails = function getCancellationDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingApiService.getCancellationDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingApiServiceClient.prototype.cancelAndStoreFeedback = function cancelAndStoreFeedback(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingApiService.cancelAndStoreFeedback, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingApiServiceClient.prototype.resumeCancellation = function resumeCancellation(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingApiService.resumeCancellation, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingApiServiceClient.prototype.updateCreditCardInfo = function updateCreditCardInfo(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingApiService.updateCreditCardInfo, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingApiServiceClient.prototype.getInvoices = function getInvoices(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(BillingApiService.getInvoices, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

BillingApiServiceClient.prototype.getInvoiceDetails = function getInvoiceDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingApiService.getInvoiceDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingApiServiceClient.prototype.getCurrentInvoice = function getCurrentInvoice(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingApiService.getCurrentInvoice, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingApiServiceClient.prototype.getBraintreeToken = function getBraintreeToken(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingApiService.getBraintreeToken, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingApiServiceClient.prototype.makePayment = function makePayment(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingApiService.makePayment, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingApiServiceClient.prototype.getSubscriptionPreset = function getSubscriptionPreset(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingApiService.getSubscriptionPreset, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingApiServiceClient.prototype.calcSubscriptionPrice = function calcSubscriptionPrice(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingApiService.calcSubscriptionPrice, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingApiServiceClient.prototype.changeSubscription = function changeSubscription(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingApiService.changeSubscription, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingApiServiceClient.prototype.getBillingAddress = function getBillingAddress(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingApiService.getBillingAddress, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingApiServiceClient.prototype.updateBillingAddress = function updateBillingAddress(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingApiService.updateBillingAddress, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingApiServiceClient.prototype.calcMailboxAccountPrice = function calcMailboxAccountPrice(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingApiService.calcMailboxAccountPrice, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingApiServiceClient.prototype.upgradeMailboxAccountsNumber = function upgradeMailboxAccountsNumber(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingApiService.upgradeMailboxAccountsNumber, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingApiServiceClient.prototype.changeMailboxAccountChargeStrategy = function changeMailboxAccountChargeStrategy(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingApiService.changeMailboxAccountChargeStrategy, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingApiServiceClient.prototype.calcPlanCreditsPrice = function calcPlanCreditsPrice(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingApiService.calcPlanCreditsPrice, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingApiServiceClient.prototype.upgradePlanCredits = function upgradePlanCredits(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingApiService.upgradePlanCredits, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingApiServiceClient.prototype.calcPlanContactsPrice = function calcPlanContactsPrice(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingApiService.calcPlanContactsPrice, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingApiServiceClient.prototype.upgradePlanContacts = function upgradePlanContacts(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingApiService.upgradePlanContacts, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingApiServiceClient.prototype.calcBonusCreditsPrice = function calcBonusCreditsPrice(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingApiService.calcBonusCreditsPrice, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingApiServiceClient.prototype.buyBonusCredits = function buyBonusCredits(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingApiService.buyBonusCredits, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingApiServiceClient.prototype.changeBonusCreditsChargeStrategy = function changeBonusCreditsChargeStrategy(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingApiService.changeBonusCreditsChargeStrategy, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingApiServiceClient.prototype.generateInvoicePdf = function generateInvoicePdf(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingApiService.generateInvoicePdf, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.BillingApiServiceClient = BillingApiServiceClient;

