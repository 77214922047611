import Dashboard from '@components/Campaigns/Dashboard/Dashboard';
import CampaignTemplatesPage from '@pages/CampaignTemplatesPage/CampaignTemplatesPage';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import urls from '@constants/urls';

import { billingCreditsSelector } from '@redux/selectors/billings.selectors';

import useAppUpdates from '@hooks/useAppUpdates';
import useIntercom from '@hooks/useIntercom';
import withPageWrapper from '@hocs/withPageWrapper';

import NotificationsSidebar from '@components/NotificationsSidebar/NotificationsSidebar';
import WorkspacesSidebar from '@components/WorkspacesSidebar/WorkspacesSidebar';

import MaintenancePage from '@pages/MaintenancePage/MaintenancePage';
import BackdoorLoginPage from '@pages/BackdoorLoginPage/BackdoorLoginPage';
import CampaignAnalyticsPage from '@pages/CampaignAnalyticsPage/CampaignAnalyticsPage';
import CampaignSettingsPage from '@pages/CampaignSettingsPage/CampaignSettingsPage';
import ToolboxPage from '@pages/ToolboxPage/ToolboxPage';
import CampaignsPage from '@pages/CampaignsPage/CampaignsPage';
import ExternalUnsubscribePage from '@pages/ExternalUnsubscribePage/ExternalUnsubscribePage';
import InboxPage from '@pages/InboxPage/InboxPage';
import InquiriesPage from '@pages/InquiriesPage/InquiriesPage';
import InsightsPage from '@pages/InsightsPage/InsightsPage';
import LoginPage from '@pages/LoginPage/LoginPage';
import OnboardingPage from '@pages/OnboardingPage/OnboardingPage';
import RelationshipsPage from '@pages/RelationshipsPage/RelationshipsPage';
import SettingsPage from '@pages/SettingPage/SettingsPage';
import UnsubscribeConfirmationPage from '@pages/UnsubscribeConfirmationPage/UnsubscribeConfirmationPage';
import CalendlyPopup from '@components/CalendlyPopup/CalendlyPopup';
import { checkIsAllLoaded } from '@utils/loadingChecks';

export default (): JSX.Element => {
  const { data: billingDetails, loadingStatus: billingDetailsLs } =
    useSelector(billingCreditsSelector);
  const history = useHistory();
  const isLoggedIn = !!localStorage.getItem('token');
  const isBillingActive = billingDetails?.active;

  useIntercom();
  useAppUpdates();

  useEffect(() => {
    if (checkIsAllLoaded(billingDetailsLs) && !isBillingActive) {
      history.push(urls.BILLING);
    }
  }, [billingDetailsLs, isBillingActive, history]);

  return useMemo(() => {
    if (!isLoggedIn) {
      return (
        <Switch>
          <Route path="/unsubscribe" component={ExternalUnsubscribePage} />
          <Route path="/unsubscribed" component={UnsubscribeConfirmationPage} />
          <Route path="/maintenance" component={MaintenancePage} />

          <Route path="/bd/login" component={BackdoorLoginPage} />

          <Route path="/authorize/microsoft/error" component={MaintenancePage} />

          <Route path="/authorize/login?:token" component={LoginPage} />

          <Route path="*" component={LoginPage} />
          {/* <Route path="/forgot-password"> */}
          {/*  <ForgotPasswordPage /> */}
          {/* </Route> */}
          {/* <Route path="/restore"> */}
          {/*  <RestorePasswordPage /> */}
          {/* </Route> */}
          {/* <Route path="/register" component={RegisterPage} /> */}

          <Route path="/">
            <Redirect to={urls.LOGIN} />
          </Route>
        </Switch>
      );
    }

    if (checkIsAllLoaded(billingDetailsLs) && !billingDetails.active) {
      return (
        <>
          <Switch>
            <Route path="/unsubscribe" component={ExternalUnsubscribePage} />
            <Route path="/unsubscribed" component={UnsubscribeConfirmationPage} />
            <Route path="/maintenance" component={MaintenancePage} />

            {/* <Route path="/onboarding/:onboardingPage?" component={OnboardingPage} /> */}

            <Route path="/trial" component={LoginPage} />

            <Route path="/settings/:settingsPage?" component={withPageWrapper(SettingsPage)} />
            <Route path="/">
              <Redirect to={urls.BILLING} />
            </Route>
          </Switch>
          <WorkspacesSidebar />
          <NotificationsSidebar />
        </>
      );
    }

    return (
      <>
        <Switch>
          <Route path="/unsubscribe" component={ExternalUnsubscribePage} />
          <Route path="/unsubscribed" component={UnsubscribeConfirmationPage} />
          <Route path="/maintenance" component={MaintenancePage} />

          <Route path="/onboarding/:onboardingPage?" component={OnboardingPage} />
          <Route path="/inbox/:inboxType" component={withPageWrapper(InboxPage)} />
          <Route path="/toolbox/:toolboxPage" component={withPageWrapper(ToolboxPage)} />

          <Route path="/dashboard/:section?" component={withPageWrapper(Dashboard)} />

          <Route
            path="/campaign-templates/:templateId?"
            component={withPageWrapper(CampaignTemplatesPage)}
          />

          <Route path="/campaigns/:campaignsType?" component={withPageWrapper(CampaignsPage)} />
          <Route
            path="/campaign-builder/:campaignId/:step?"
            component={withPageWrapper(CampaignSettingsPage, false)}
          />
          <Route
            path="/campaign-analytics/:campaignId"
            component={withPageWrapper(CampaignAnalyticsPage)}
          />
          <Route
            path="/relationships/:relationshipsPage?"
            component={withPageWrapper(RelationshipsPage)}
          />
          <Route path="/settings/:settingsPage?" component={withPageWrapper(SettingsPage)} />
          <Route path="/inquiries/:inquiriesPage?" component={withPageWrapper(InquiriesPage)} />
          <Route path="/insights/:insightsPage?" component={withPageWrapper(InsightsPage)} />

          <Route path="/">
            <Redirect to={urls.DASHBOARD} />
          </Route>
        </Switch>
        <CalendlyPopup />
        <WorkspacesSidebar />
        <NotificationsSidebar />
      </>
    );
  }, [isLoggedIn, isBillingActive]);
};
