import React, { useRef } from 'react';

import RightSidebar from '@components/RightSidebar/RightSidebar';

import useOutsideClick from '@hooks/useOutsideClick';
import useHotkeys from '@hooks/useHotkeys';

import './SimpleActionSidebar.scss';

export type PeopleSimpleActionSidebarPropsType = {
  isOpen: boolean;
  onClose: () => void;
  onAction: () => void;
  isActionButtonLoading?: boolean;
  actionButtonLoadingText?: string;
  itemsNumber: number;
  itemsTitle: string;
  title: string;
  subtitle?: string;
};

const SimpleActionSidebar = ({
  isOpen,
  onClose,
  onAction,
  isActionButtonLoading = false,
  actionButtonLoadingText,
  itemsNumber,
  itemsTitle,
  title,
  subtitle,
}: PeopleSimpleActionSidebarPropsType): JSX.Element => {
  const barRef = useRef<HTMLDivElement>();
  useOutsideClick(barRef, onClose);

  useHotkeys({ Escape: () => onClose() });

  return (
    <RightSidebar
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      subtitle={subtitle}
      onSave={onAction}
      isSaveButtonLoading={isActionButtonLoading}
      saveButtonLoadingText={actionButtonLoadingText}
      buttonSaveText={title}
      barRef={barRef}
    >
      <div className="people-export-sidebar__content">
        <span>Would you like to {title.toLowerCase()} the</span>
        <br />
        <span className="people-export-sidebar__selected_people-text">
          {itemsNumber} selected {itemsTitle}?
        </span>
      </div>
    </RightSidebar>
  );
};

export default SimpleActionSidebar;
