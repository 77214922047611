import { SVGIcon } from '@uikit/Icon/Icon';
import { isCorrectUrlString } from '@utils/isUrl';
import { nanoid } from 'nanoid';
import React, { useEffect, useMemo, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { KeywordSearchSourceMap } from 'respona_api/generated/automation-content_pb';
import { withHistory } from 'slate-history';
import { Editable, ReactEditor, Slate, withReact } from 'slate-react';
import { createEditor, Descendant, Editor, Node, Range, Transforms } from 'slate';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'class-names';

import { getQueryLinkForSource } from '@helpers/contentSearchHelpers';

import useNonInitialEffect from '@hooks/useNonInitialEffect';

import { searchModeSelector } from '@redux/selectors/automationContent.selectors';

import { CASearchConfigType } from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/CASearchTypeWrapper/CASearchTypeWrapper';
import { getContentSearchTitle } from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/ContentSearchEditor/getContentSearchTitle';
import QueryLinkToInternet from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/assets/QueryLinkToInternet';
import Display from '@components/Display/Display';

import './ContentSearchEditorV2.scss';
import CounterSigns from '@uikit/CounterSigns/CounterSigns';
import { addNotification } from '@redux/actions/notifications.actions';

export type onChangeQueriesType = (queries: string[]) => void;

type EditorProps = {
  queries: string[];
  onChangeQueries: onChangeQueriesType;
  onPressEnter?: () => void;
  searchSourceKey?: keyof CASearchConfigType;
  toggleAdvancedSearch?: () => void;
  withLinks?: boolean;
  isUrl?: boolean;
  placeholder?: string;
  maxLines?: number;
  maxCharacters?: number;
  limit?: number;
  source?: KeywordSearchSourceMap[keyof KeywordSearchSourceMap];
  hasCounter?: boolean;
  bottomBlock?: JSX.Element;
  extraClass?: string;
};

const KEY_CODE = {
  ARROW_LEFT: 37,
  ARROW_UP: 38,
  ARROW_RIGHT: 39,
  ARROW_DOWN: 40,
  BACKSPACE: 8,
  ENTER: 13,
};

const renderElement = (
  props,
  pendingQuery,
  searchMode,
  source,
  isSingleElement,
  withLinks,
  isUrl
): JSX.Element => {
  const id = nanoid();

  const handleClick = (e) => {
    e.preventDefault();
    window.open(getQueryLinkForSource(props.element.children[0].text, source), '_blank');
  };

  return (
    <div className="content-search-editor-v2__query" {...props.attributes}>
      <span
        className={cn('content-search-editor-v2__query-text', {
          'content-search-editor-v2__query-text--empty':
            !props.element.children[0].text && !pendingQuery && isSingleElement,
        })}
      >
        {props.children}

        <Display isVisible={withLinks && !!props.element.children[0].text && searchMode === 0}>
          <span onClick={handleClick} className="content-search-editor-v2__query-link">
            <QueryLinkToInternet />
          </span>
        </Display>

        <Display
          isVisible={
            isUrl &&
            !!props.element.children[0].text &&
            !isCorrectUrlString(props.element.children[0].text)
          }
        >
          <>
            <span
              className="content-search-editor-v2__warning-icon"
              data-tip=""
              data-for={`warning-${id}`}
            >
              <SVGIcon icon="warningTriangle" size={13} />
            </span>
            <ReactTooltip
              id={`warning-${id}`}
              place="bottom"
              effect="solid"
              arrowColor="transparent"
            >
              Not a valid url
            </ReactTooltip>
          </>
        </Display>
      </span>
    </div>
  );
};

const withLineLimits =
  (maxLines = 99) =>
  (editor: ReactEditor) => {
    const { insertBreak } = editor;

    editor.insertBreak = () => {
      const texts = Array.from(Node.texts(editor));

      if (texts.length >= maxLines) {
        return;
      }

      insertBreak();
    };

    return editor;
  };

const ContentSearchEditorV2: React.FC<EditorProps> = ({
  queries: initialQueries,
  onChangeQueries,
  onPressEnter,
  searchSourceKey,
  toggleAdvancedSearch,
  withLinks = true,
  isUrl = false,
  placeholder = '',
  maxLines = 99,
  maxCharacters = -1, // per line
  source,
  limit = -1, // all chars
  hasCounter = false,
  bottomBlock = null,
  extraClass = '',
}): JSX.Element => {
  const dispatch = useDispatch();
  const searchMode = useSelector(searchModeSelector);
  const editor = useMemo(
    () => withReact(withHistory(withLineLimits(maxLines)(createEditor() as ReactEditor))),
    []
  );
  const [componentKey, setComponentKey] = useState<number>(0);
  const [pendingQuery, setPendingQuery] = useState<boolean>(false);
  const [value, setValue] = useState<Descendant[]>(
    (initialQueries.length ? initialQueries : ['']).map((text) => ({
      children: [{ text: text || '' }],
    }))
  );

  useEffect(() => {
    if (initialQueries.length > value.length) {
      const initChildren = initialQueries.map((text) => ({
        children: [{ text: text || '' }],
      }));
      setValue(initChildren);
      setComponentKey(componentKey + 1);
    }
  }, [initialQueries]);

  const mapEditorValuesToStringArray = (data: Descendant[]) => {
    return Array.from(
      new Set(
        data.reduce((acc, item: { children }) => {
          if (!item?.children[0]?.text) {
            return acc;
          }
          return acc.concat([item?.children[0]?.text]);
        }, [])
      )
    );
  };

  const onKeyDown = (e) => {
    const endPoint = Range.end(editor.selection);
    const [selectedLeaf] = Editor.node(editor, endPoint);

    // @ts-ignore
    if (maxCharacters > 0 && selectedLeaf.text.length >= maxCharacters && e.keyCode >= 48) {
      e.preventDefault();
      return false;
    }

    if (e.keyCode === KEY_CODE.BACKSPACE && e.target.innerText.trim() === '') {
      // @ts-ignore
      document.activeElement.blur();
    }

    if (e.keyCode === KEY_CODE.ENTER && onPressEnter) {
      onPressEnter();
    }
  };

  const onPaste = async (e) => {
    e.preventDefault();

    // Early return if limit is reached and handleDOMBeforeInput returns true
    if (limit !== -1 && handleDOMBeforeInput(e)) {
      return;
    }

    const text = e.clipboardData.getData('text');

    // Split the text, but also immediately check if the resulting array is too large
    const newPatterns = text.split(/[\n,]/g).filter(Boolean);

    // Early return if no patterns or if the newPatterns array is excessively large
    if (!newPatterns.length || newPatterns.length > 250) {
      // Arbitrary large number, adjust based on performance tests
      dispatch(addNotification({ title: 'Please, insert less than 250 rows', type: 'error' }));
      return;
    }

    // Optimizing texts retrieval: Only fetch the necessary count, avoid fetching all texts if not needed
    const textsCount = editor.children.length; // Assuming this gives a reliable count, adjust based on actual data structure

    // Insert the first new pattern
    const firstPattern = newPatterns[0];
    Transforms.insertText(editor, firstPattern);

    // Early return if the maxLines limit is reached
    if (textsCount >= maxLines) {
      return;
    }

    // Batch operations if inserting more than one node
    if (newPatterns.length > 1) {
      const nodesToInsert = newPatterns.slice(1, maxLines - textsCount).map((query) => ({
        children: [{ text: query }],
      }));

      // Using Slate's Transforms to batch insert nodes
      Transforms.insertNodes(editor, nodesToInsert);
    }
  };

  const handleDOMBeforeInput = (event): boolean => {
    if (limit === -1) {
      return false;
    }

    const { inputType, type } = event;
    let textLength = Editor.string(editor, []).length;

    if (inputType === 'insertText') {
      textLength += event.data.length;
    }

    if (type === 'paste') {
      textLength += event.clipboardData.getData('Text').length;
    }

    if (textLength > limit) {
      event.preventDefault();
      return true;
    }

    return false;
  };

  useNonInitialEffect(() => {
    onChangeQueries(mapEditorValuesToStringArray(value));
  }, [value]);

  useEffect(() => {
    if (source === undefined) {
      return;
    }

    onChangeQueries(mapEditorValuesToStringArray(value));
  }, [source]);

  const isEmpty = !initialQueries?.[0]?.length;

  return (
    <div className="content-search-editor-v2">
      {searchSourceKey !== undefined ? (
        <div className="content-search-editor-v2__block-title">
          {getContentSearchTitle(searchSourceKey, source, toggleAdvancedSearch)}
        </div>
      ) : null}

      <div className={`content-search-editor-v2__query-editor-wrapper ${extraClass}`}>
        <div
          className={cn('content-search-editor-v2__query-editor', {
            'content-search-editor-v2__query-editor--with-placeholder': isEmpty,
          })}
          // placeholder={!initialQueries?.length ? placeholder?.split(',')?.join(` \A `) : ''}
        >
          <Slate editor={editor} value={value} onChange={setValue} key={componentKey}>
            <Editable
              style={{
                overflowY: 'auto',
                height: '100%',
                padding: '9px 17px',
              }}
              onDOMBeforeInput={handleDOMBeforeInput}
              onPaste={onPaste}
              onKeyDown={onKeyDown}
              onFocus={() => setPendingQuery(true)}
              onBlur={() => setPendingQuery(false)}
              renderElement={(props) => {
                return renderElement(
                  props,
                  pendingQuery,
                  searchMode,
                  source,
                  value.length === 1,
                  withLinks,
                  isUrl
                );
              }}
            />
          </Slate>

          {!!placeholder && (
            <div className="content-search-editor-v2__query-editor-placeholder">
              {isEmpty &&
                placeholder?.split(',')?.map((item, key) => (
                  <div className="content-search-editor-v2__query-editor-placeholder-row" key={key}>
                    <div className="content-search-editor-v2__query-editor-placeholder-order">
                      {key + 1}.
                    </div>
                    {item.trim()}
                  </div>
                ))}
            </div>
          )}
        </div>
        {!!bottomBlock && bottomBlock}
      </div>

      {hasCounter ? (
        <CounterSigns limit={limit} value={mapEditorValuesToStringArray(value).join('').length} />
      ) : null}
    </div>
  );
};

export default ContentSearchEditorV2;
