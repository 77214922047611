import React, { useRef } from 'react';
import cn from 'class-names';

import hexToRgba from '@utils/hexToRgba';
import Display from '@components/Display/Display';

import './ColoredStatusToggler.scss';
import { SVGIcon } from '@uikit/Icon/Icon';
import ReactTooltip from 'react-tooltip';

const  TogglerIcon = React.memo(
  ({ color = '#fff', name, iconSize }: { color: string, name: any, iconSize: number }): JSX.Element => (
   <SVGIcon icon={name} color={color} size={iconSize} />
  )
);

const ColoredStatusToggler = ({
  isActive,
  isLoading = false,
  color = '#fff',
  onChange,
  title,
  status,
  titleColor,
  iconColor,
  iconSize,
  id,
}: {
  isActive: boolean;
  isLoading?: boolean;
  color?: string;
  onChange: (boolean) => void;
  title?: string;
  status?:number;
  titleColor?: string;
  iconColor?: string;
  iconSize?: number;
  id?: number;
}): JSX.Element => {
  const elementRef = useRef(null);

  const cnToggleSlider = cn('colored-toggler__slider', {
    'colored-toggler__slider--active': isActive,
    'colored-toggler__slider--loading': isLoading,
  });

  const getIcon = (status) => {
    switch (status) {
      case 0:
        return 'lightningArrow';
      case 1:
        return 'pauseIcon';
      case 2:
        return 'playArrow';
      case 3:
        return 'crossBlack';
      case 4:
        return 'checkmarkIcon';
    }
  };

  const getTitle = (status) => {
    switch (status) {
      case 0:
        return 'Click to lunch the campaign';
      case 1:
        return 'Click to pause the campaign';
      case 2:
        return 'Click to unpause the campaign';
      default:
        return '';
    }
  }

  const handleChange = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (!isLoading) {
      onChange(!isActive);
    }
  };

  const tooltipTitle = getTitle(status);

  return (
    <>
      <div
        className={`colored-toggler ${title ? 'colored-toggler__big-size' : ''}`}
        style={{
          background: hexToRgba(color, '1'),
        }}
        onClick={handleChange}
        data-for={`colored-status-button-${id}`}
        data-tip=""
      >
        {title && (<span className={`colored-toggler__title ${!isActive ? 'right' : ''}`}
                         style={{color: titleColor}}>{title}</span>)}
        <div
          className={cnToggleSlider}
          style={{
            background: isActive ? (title ? titleColor : status === 4 ? '#ffffff' : color) : status === 2 ? titleColor : '#fff',
          }}
        >
          <Display isVisible={!isLoading}>
            <TogglerIcon
              color={title ? iconColor : '#A3B2C9'}
              name={getIcon(status)}
              iconSize={iconSize}
            />
          </Display>
        </div>
      </div>
      {tooltipTitle.length ? (
        <ReactTooltip
          id={`colored-status-button-${id}`}
          place="top"
          effect="solid"
          arrowColor="transparent"
        >
          <div>
            {getTitle(status)}
          </div>
        </ReactTooltip>
      ) : null}
    </>
  );
};

export default ColoredStatusToggler;
