import mailboxAccountHealthService from '@api/services/mailboxAccountHealthService';
import request from '@api/base/request';

import CommonModels from 'respona_api/generated/common_pb';
import { MailboxHealthResponse } from 'respona_api/generated/mailbox-account-health_pb';

export const getAccountHealthByIdApi = (
  id: number,
  workspaceId: number
): Promise<MailboxHealthResponse.AsObject> => {
  const client = mailboxAccountHealthService.getInstance();
  const idRequest = new CommonModels.IdRequest();

  idRequest.setId(id);
  idRequest.setOptionalWorkspaceId(workspaceId);

  return request<MailboxHealthResponse.AsObject>(client, client.getByAccountId, idRequest);
};
