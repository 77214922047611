import React from 'react';

const NotificationsIcon = ({ color = '#BDBDBD' }) => (
  <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.22519 11.4299L7.88944 15.0839C8.16094 15.6922 7.88794 16.4047 7.27969 16.6762C6.67144 16.9477 5.95894 16.6747 5.68744 16.0664L2.55469 9.13867"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.25 13L6.25 9.25H3.25C2.00725 9.25 1 8.24275 1 7C1 5.75725 2.00725 4.75 3.25 4.75H6.25L12.25 1V13Z"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 7H17.5"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.0977 3.625L16.3967 2.875"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.0977 10.375L16.3967 11.125"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default NotificationsIcon;
