import React from 'react';

export default function ({ color = '#8F9199', size = 12, backgroundIcon = '#E8EAED'  }: { color?: string; size?: number; backgroundIcon?: string; }) {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="17" cy="17" r="17" fill={backgroundIcon} />
      <g clipPath="url(#clip0_24153_1667)">
        <path
          d="M17.0007 10.6602L19.0607 14.8335L23.6673 15.5028L20.334 18.7522L21.1207 23.3402L17.0007 21.1748L12.8807 23.3402L13.6673 18.7522L10.334 15.5028L14.9407 14.8335L17.0007 10.6602Z"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_24153_1667">
          <rect width="16" height="16" fill="white" transform="translate(9 9)" />
        </clipPath>
      </defs>
    </svg>
  );
}
