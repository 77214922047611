import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'query-string';
import cn from 'class-names';

import {
  getActiveCampaignInfo,
  getActiveCampaignVerifyStatus,
} from '@redux/selectors/campaignSettings.selectors';

import CampaignSettingsSidebar from '@components/CampaignSettingsSidebar/CampaignSettingsSidebar';
import SubscriptionActivationSidebar from '@components/SubscriptionActivationSidebars/SubscriptionActivationSidebar';

import Button from '@uikit/Button/Button';
import { SVGIcon } from '@uikit/Icon/Icon';
import Loader from '@uikit/Loader/Loader';

import { CampaignVerificationStatus } from 'respona_api/generated/campaign_pb';

import './VerifyAndLaunchCampaignButton.scss';
import { billingDetailsSelector } from '@redux/selectors/billings.selectors';
import { fetchBillingCredits, fetchBillingDetails } from '@redux/thunks/billings.thunks';
import { BillingSubscriptionType } from 'respona_api/generated/billing_pb';

enum ButtonStateEnum {
  ConnectEmail = 'Connect Email',
  SelectEmail = 'Select Email',
  Launch = 'Launch',
  Verifying = 'Verifying...',
  Verified = 'Verified',
}

type LaunchCampaignButtonPropsType = {
  campaignId: number;
  onVerificationComplete?: () => Promise<void>;
};

const ButtonText = ({ buttonState }: { buttonState: ButtonStateEnum }) => {
  switch (buttonState) {
    case ButtonStateEnum.Verifying:
      return (
        <>
          <span className="verify-campaign-button__icon">
            <SVGIcon icon="launching" />
          </span>
          {ButtonStateEnum.Verifying}
        </>
      );
    case ButtonStateEnum.Verified:
      return (
        <>
          <span className="verify-campaign-button__icon">
            <SVGIcon icon="successCircle" />
          </span>
          {ButtonStateEnum.Verified}
        </>
      );
    default:
      return (
        <>
          <span className="verify-campaign-button__icon">
            <SVGIcon icon="rocket" />
          </span>
          {ButtonStateEnum.Launch}
        </>
      );
  }
};

const VerifyAndLaunchCampaignButton = ({
  campaignId,
  onVerificationComplete,
}: LaunchCampaignButtonPropsType): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { isVerifying } = qs.parse(search);

  const { info: campaignInfo } = useSelector(getActiveCampaignInfo);
  const { data: billingDetails } = useSelector(billingDetailsSelector);

  const [isVerifySidebarOpen, setVerifySidebarOpen] = useState<boolean>(isVerifying === 'true');
  const [isPaymentSidebarOpen, setPaymentSidebarOpen] = useState<boolean>(false);

  const [buttonState, changeButtonState] = useState<ButtonStateEnum>(null);
  const verifyingStatus = useSelector(getActiveCampaignVerifyStatus);

  useEffect(() => {
    if (campaignInfo?.verificationStatus === CampaignVerificationStatus.VERIFIED) {
      changeButtonState(ButtonStateEnum.Verified);
    } else if (campaignInfo?.verificationStatus === CampaignVerificationStatus.IN_PROGRESS) {
      changeButtonState(ButtonStateEnum.Verifying);
    } else if (campaignInfo?.verificationStatus === CampaignVerificationStatus.NOT_VERIFIED) {
      changeButtonState(ButtonStateEnum.Launch);
    }
  }, [campaignInfo?.verificationStatus]);

  useEffect(() => {
    if (isVerifying === 'true') {
      history.push(`/campaign-builder/${campaignId}/personalize`);
    }
  }, [isVerifying]);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    if (verifyingStatus !== undefined) {
      changeButtonState(Object.values(ButtonStateEnum)[verifyingStatus]);
    }
  }, [verifyingStatus]);

  const cnLaunchButton = cn('verify-campaign-button__button', {
    'verify-campaign-button__button--launch': buttonState === ButtonStateEnum.Launch,
    'verify-campaign-button__button--verifying': buttonState === ButtonStateEnum.Verifying,
    'verify-campaign-button__button--verified': buttonState === ButtonStateEnum.Verified,
  });

  if (verifyingStatus === undefined) {
    return <Loader isLoading />;
  }

  function openSideBar() {
    if (billingDetails.type === BillingSubscriptionType.TRIAL) {
      setPaymentSidebarOpen(true);
    } else {
      setVerifySidebarOpen(true);
    }
  }

  return (
    <>
      <div className="verify-campaign-button">
        <Button className={cnLaunchButton} onClick={() => openSideBar()}>
          <ButtonText buttonState={buttonState} />
        </Button>
      </div>

      {isVerifySidebarOpen && (
        <CampaignSettingsSidebar
          isOpen
          onClose={() => setVerifySidebarOpen(false)}
          campaignId={campaignId}
          onVerification={onVerificationComplete}
        />
      )}

      {isPaymentSidebarOpen && (
        <SubscriptionActivationSidebar
          title="Activate your subscription"
          onClose={() => {
            setPaymentSidebarOpen(false);
          }}
          onActionAfterPay={() => {
            setPaymentSidebarOpen(false);
            dispatch(fetchBillingDetails());
            dispatch(fetchBillingCredits());
            setVerifySidebarOpen(true);
          }}
        />
      )}
    </>
  );
};

export default VerifyAndLaunchCampaignButton;
