import React from 'react';

export default ({ color = '#8F9199', size = 19 }: { color?: string; size?: number }) => (
  <svg width={size} height={size} viewBox="0 0 19 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 2.4L2.66667 1H3V8"
      stroke={color}
      strokeWidth="1.4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.16667 1.5122C6.67135 1.20064 7.24458 1.02448 7.83333 1C9 1 10 1.5122 10 2.87805C10 4.92683 6 7.65854 6 7.65854V8H10"
      stroke={color}
      strokeWidth="1.4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5536 4.45239C15.4167 4.45239 17.3155 4.62501 17.3155 6.3512C17.3155 7.55954 16.2798 8.24207 14.8988 8.25001C14.2329 8.23659 13.5806 8.05869 13 7.73215"
      stroke={color}
      strokeWidth="1.4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.1719 1.51788C13.6832 1.17791 14.284 0.997653 14.8981 1.00002C15.9372 1.00002 16.9695 1.51788 16.9695 2.55359C16.9695 3.93455 15.5885 4.4524 14.8981 4.4524H14.5528"
      stroke={color}
      strokeWidth="1.4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
