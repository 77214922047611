import React, { useRef, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import useOutsideClick from '@hooks/useOutsideClick';
import useHotkeys from '@hooks/useHotkeys';
import RightSidebar from '@components/RightSidebar/RightSidebar';
import { addPeopleToCampaignRequest } from '@redux/thunks/campaign.thunks';
import { fetchOpenCampaign } from '@redux/thunks/campaignSettings.thunks';
import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';

import { AddPeopleToCampaignOptionsType } from '@api/campaign.api';

import Checkbox from '@uikit/Checkbox/Checkbox';
import { CampaignToAddPeopleType } from '@components/Relationships/PeopleHeaderActions/MoreActionsButton/MoreActionsButton';

import './AddPeopleToCampaignSidebar.scss';

const filtersMapper = {
  setUnverified: 'Unverified emails',
  setWork: 'Work emails',
  setPersonal: 'Personal emails',
  setGeneric: 'Generic emails',
};

const AddPeopleToCampaignSidebar = ({
  onClose,
  isOpen,
  selectedIds,
  itemsNumber,
  campaignInfo,
}: {
  onClose: () => void;
  isOpen: boolean;
  selectedIds: number[];
  itemsNumber: number;
  campaignInfo: CampaignToAddPeopleType;
}): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();

  const barRef = useRef<HTMLDivElement>();

  const [isAdding, changeIsAdding] = useState<boolean>(false);

  const [options, changeOptions] = useState<AddPeopleToCampaignOptionsType>(
    Object.keys(filtersMapper).reduce(
      (acc, item) => ({ ...acc, [item]: true }),
      {} as AddPeopleToCampaignOptionsType
    )
  );

  const currentWorkspaceId = useCurrentWorkspaceId();

  useOutsideClick(barRef, onClose);
  useHotkeys({ Escape: () => onClose() });

  const handleAddPeopleToCampaign = () => {
    const { campaignId } = campaignInfo;

    changeIsAdding(true);

    dispatch(addPeopleToCampaignRequest(campaignId, selectedIds, currentWorkspaceId, options))
      // @ts-ignore
      .then(() => {
        dispatch(fetchOpenCampaign(campaignId, currentWorkspaceId))
          // @ts-ignore
          .then(() => {
            changeIsAdding(false);
            history.push(`/campaign-builder/${campaignId}/opportunities`);
          })
          .catch(() => {
            changeIsAdding(false);
          });
      })
      .catch((error) => {
        console.error(error);
        changeIsAdding(false);
      });
  };

  const Subtitle = () => {
    return (
      <div className="add-people-to-campaign-sidebar__header">
        <div className="add-people-to-campaign-sidebar__title">Add your contacts to</div>
        <div className="add-people-to-campaign-sidebar__campaign-name">
          {campaignInfo.campaignName}
        </div>
      </div>
    );
  };

  return (
    <RightSidebar
      isOpen={isOpen}
      onClose={onClose}
      title="Add to Campaign"
      subtitle={Subtitle}
      onSave={handleAddPeopleToCampaign}
      isSaveButtonLoading={isAdding}
      saveButtonLoadingText="Adding..."
      buttonSaveText="Add"
      barRef={barRef}
    >
      <div className="export-people-sidebar">
        <div className="export-people-sidebar__header">
          <span>Total contacts to export</span>
          <br />
          <span className="export-people-sidebar__selected_people-text">{itemsNumber} people</span>
        </div>
        <div className="export-people-sidebar__filters">
          <span className="export-people-sidebar__filters-title">Include in export:</span>
          {Object.keys(options).map((key) => {
            return (
              <div className="export-people-sidebar__filters-raw" key={key}>
                <Checkbox
                  onChange={() =>
                    changeOptions((prevState) => ({
                      ...prevState,
                      [key]: !prevState[key],
                    }))}
                  value={options[key]}
                />
                <span className="export-people-sidebar__filters-raw-text">
                  {filtersMapper[key]}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </RightSidebar>
  );
};

export default AddPeopleToCampaignSidebar;
