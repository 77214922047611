import React from 'react';

import { nanoid } from 'nanoid';

const GoogleNewsShortSVG = ({ size = 35 }: { size?: number }): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 35 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_18520_7823)">
        <path
          d="M27.8406 19.425C27.8406 19.9123 27.4388 20.3114 26.9478 20.3114H8.05173C7.56072 20.3114 7.15894 19.9123 7.15894 19.425V0.88638C7.15894 0.399112 7.56072 0 8.05173 0H26.9483C27.4394 0 27.8411 0.399112 27.8411 0.88638V19.425H27.8406Z"
          fill="#0C9D58"
        />
        <path
          opacity="0.2"
          d="M27.8405 4.76598L20.7585 3.85449L27.8405 5.75441V4.76598Z"
          fill="#004D40"
        />
        <path
          opacity="0.2"
          d="M17.8414 0.966202L7.1167 5.75501L18.9698 1.44065V1.42889C18.7615 0.98223 18.2539 0.773859 17.8414 0.966202Z"
          fill="#004D40"
        />
        <path
          opacity="0.2"
          d="M26.9483 0H8.05173C7.56072 0 7.15894 0.399112 7.15894 0.88638V1.08513C7.15894 0.597866 7.56072 0.198754 8.05173 0.198754H26.9483C27.4394 0.198754 27.8411 0.597866 27.8411 1.08513V0.88638C27.8406 0.399112 27.4394 0 26.9483 0Z"
          fill="white"
        />
        <path
          d="M11.7446 18.8552C11.6169 19.3313 11.8718 19.8169 12.311 19.9345L29.8131 24.6416C30.2523 24.7591 30.716 24.4658 30.8432 23.9897L34.9663 8.60229C35.094 8.12624 34.8391 7.64057 34.4 7.52303L16.8978 2.81597C16.4586 2.69843 15.9949 2.99175 15.8677 3.4678L11.7446 18.8552Z"
          fill="#EA4335"
        />
        <path
          opacity="0.2"
          d="M31.0227 14.0947L31.9433 19.6871L31.0227 23.1242V14.0947Z"
          fill="#3E2723"
        />
        <path
          opacity="0.2"
          d="M20.8413 4.08203L21.6662 4.30376L22.1781 7.75525L20.8413 4.08203Z"
          fill="#3E2723"
        />
        <path
          opacity="0.2"
          d="M34.4006 7.52303L16.8985 2.81597C16.4593 2.69843 15.9955 2.99175 15.8684 3.4678L11.7453 18.8552C11.7383 18.8809 11.7367 18.9071 11.7319 18.9327L15.824 3.66175C15.9517 3.1857 16.4155 2.89237 16.8541 3.00992L34.3563 7.71698C34.7714 7.82811 35.0177 8.26889 34.936 8.7193L34.9675 8.60229C35.0947 8.12624 34.8398 7.64057 34.4006 7.52303Z"
          fill="white"
        />
        <path
          d="M25.5288 16.9608C25.6971 17.424 25.4855 17.93 25.0586 18.0854L6.58999 24.8073C6.16256 24.9628 5.67529 24.7111 5.50699 24.2479L0.0583422 9.27826C-0.109958 8.81504 0.101619 8.30907 0.528514 8.15359L18.9972 1.43174C19.4246 1.27626 19.9119 1.52791 20.0802 1.99114L25.5288 16.9608Z"
          fill="#FFC107"
        />
        <path
          opacity="0.2"
          d="M0.128132 9.46642C-0.0401678 9.0032 0.171409 8.49723 0.598304 8.34175L19.067 1.61936C19.4896 1.46549 19.9699 1.71073 20.1435 2.16434L20.08 1.99016C19.9111 1.52693 19.4239 1.27528 18.997 1.43076L0.528312 8.15315C0.100884 8.30862 -0.110694 8.81459 0.0581408 9.27782L5.50679 24.2474C5.50839 24.2523 5.5116 24.2565 5.51373 24.2619L0.128132 9.46642Z"
          fill="white"
        />
        <path
          d="M31.0228 27.7414C31.0228 28.2334 30.62 28.6363 30.1279 28.6363H4.87222C4.38015 28.6363 3.97729 28.2334 3.97729 27.7414V8.65079C3.97729 8.15871 4.38015 7.75586 4.87222 7.75586H30.1279C30.62 7.75586 31.0228 8.15871 31.0228 8.65079V27.7414Z"
          fill="#4285F4"
        />
        <path
          d="M26.2003 15.3128H18.6433V13.3242H26.2003C26.4198 13.3242 26.5978 13.5021 26.5978 13.7217V14.9148C26.5978 15.1344 26.4198 15.3128 26.2003 15.3128ZM26.2003 22.8692H18.6433V20.8806H26.2003C26.4198 20.8806 26.5978 21.0585 26.5978 21.2781V22.4712C26.5978 22.6913 26.4198 22.8692 26.2003 22.8692ZM27.3933 19.0908H18.6433V17.1022H27.3933C27.6129 17.1022 27.7908 17.2801 27.7908 17.4997V18.6927C27.7908 18.9128 27.6129 19.0908 27.3933 19.0908Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M30.1279 28.4369H4.87222C4.38015 28.4369 3.97729 28.0341 3.97729 27.542V27.7407C3.97729 28.2328 4.38015 28.6357 4.87222 28.6357H30.1279C30.62 28.6357 31.0228 28.2328 31.0228 27.7407V27.542C31.0228 28.0341 30.62 28.4369 30.1279 28.4369Z"
          fill="#1A237E"
        />
        <path
          opacity="0.2"
          d="M4.87222 7.95461H30.1279C30.62 7.95461 31.0228 8.35747 31.0228 8.84954V8.65079C31.0228 8.15871 30.62 7.75586 30.1279 7.75586H4.87222C4.38015 7.75586 3.97729 8.15871 3.97729 8.65079V8.84954C3.97729 8.35747 4.38015 7.95461 4.87222 7.95461Z"
          fill="white"
        />
        <path
          d="M11.882 17.3014V19.095H14.4556C14.243 20.1877 13.2823 20.98 11.882 20.98C10.3197 20.98 9.05291 19.6587 9.05291 18.0965C9.05291 16.5342 10.3197 15.2129 11.882 15.2129C12.5856 15.2129 13.2155 15.455 13.713 15.9289V15.9299L15.0748 14.568C14.2478 13.7971 13.169 13.3242 11.8814 13.3242C9.24579 13.3242 7.10864 15.4608 7.10864 18.097C7.10864 20.7332 9.24525 22.8698 11.8814 22.8698C14.6378 22.8698 16.4512 20.9319 16.4512 18.2055C16.4512 17.8929 16.4223 17.5916 16.3758 17.3014H11.882Z"
          fill="white"
        />
        <g opacity="0.2">
          <path
            d="M11.9316 19.0947V19.2935H14.4572C14.4759 19.2283 14.4919 19.162 14.5053 19.0947H11.9316Z"
            fill="#1A237E"
          />
          <path
            d="M11.9317 22.8694C9.3292 22.8694 7.2145 20.7862 7.16161 18.196C7.16107 18.2291 7.15894 18.2622 7.15894 18.2953C7.15894 20.9315 9.29554 23.0681 11.9317 23.0681C14.6881 23.0681 16.5015 21.1303 16.5015 18.4038C16.5015 18.3819 16.4988 18.3616 16.4988 18.3397C16.4395 20.9951 14.6427 22.8694 11.9317 22.8694ZM13.7627 15.929C13.2653 15.4556 12.6348 15.213 11.9317 15.213C10.3695 15.213 9.10267 16.5343 9.10267 18.0966C9.10267 18.1302 9.10641 18.1628 9.10748 18.196C9.16037 16.6775 10.4026 15.4118 11.9317 15.4118C12.6354 15.4118 13.2653 15.6538 13.7627 16.1277V16.1288L15.224 14.6675C15.1898 14.6355 15.1519 14.6072 15.1166 14.5762L13.7632 15.9301L13.7627 15.929Z"
            fill="#1A237E"
          />
        </g>
        <path
          opacity="0.2"
          d="M26.5978 15.1138V14.915C26.5978 15.1346 26.4198 15.3125 26.2003 15.3125H18.6433V15.5113H26.2003C26.4198 15.5118 26.5978 15.3339 26.5978 15.1138ZM26.2003 22.8695H18.6433V23.0682H26.2003C26.4198 23.0682 26.5978 22.8903 26.5978 22.6707V22.472C26.5978 22.6916 26.4198 22.8695 26.2003 22.8695ZM27.3933 19.091H18.6433V19.2898H27.3933C27.6129 19.2898 27.7908 19.1119 27.7908 18.8923V18.6935C27.7908 18.9131 27.6129 19.091 27.3933 19.091Z"
          fill="#1A237E"
        />
        <path
          d="M0.168042 0.0367504L0.136 0.0281324V0.00432987C0.136 0.00194962 0.134038 0 0.131639 0H0.0393312C0.0369327 0 0.03497 0.00194962 0.03497 0.00432987V0.0280385L0.00258075 0.0398275C0.000492806 0.040587 -0.000540726 0.0430586 0.000284012 0.0453214L0.0194279 0.097922V0.135513C0.0194279 0.137917 0.0213957 0.139884 0.0237995 0.139884H0.147171C0.149575 0.139884 0.151543 0.137917 0.151543 0.135513V0.113931L0.170812 0.0420225C0.171433 0.039697 0.170188 0.0373246 0.168042 0.0367504Z"
          fill="url(#paint0_radial_18520_7823)"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_18520_7823"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0.0385326 0.0113333) scale(0.166267)"
        >
          <stop stopColor="white" stopOpacity="0.1" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <clipPath id="clip0_18520_7823">
          <rect width="35" height="28.6361" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GoogleNewsShortSVG;
