import React from 'react';

export default ({ color = '#BDBDBD' }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.5001 1.6665H2.50007C2.27905 1.6665 2.06709 1.7543 1.91081 1.91058C1.75453 2.06686 1.66673 2.27882 1.66673 2.49984V17.4998C1.66673 17.7209 1.75453 17.9328 1.91081 18.0891C2.06709 18.2454 2.27905 18.3332 2.50007 18.3332H17.5001C17.7211 18.3332 17.933 18.2454 18.0893 18.0891C18.2456 17.9328 18.3334 17.7209 18.3334 17.4998V2.49984C18.3334 2.27882 18.2456 2.06686 18.0893 1.91058C17.933 1.7543 17.7211 1.6665 17.5001 1.6665ZM6.66673 16.6665H3.3334V8.33317H6.66673V16.6665ZM11.6667 16.6665H8.3334V8.33317H11.6667V16.6665ZM16.6667 16.6665H13.3334V8.33317H16.6667V16.6665ZM16.6667 6.6665H3.3334V3.33317H16.6667V6.6665Z"
      fill={color}
    />
  </svg>
);
