import { createAction } from 'redux-actions';
import { FolderType } from '@ts/common.types';

export const addCampaign = createAction<{ [key: string]: any }>('ADD_CAMPAIGN');
export const changeCampaignStatus =
  createAction<{ campaignId: number; status: number }>('CHANGE_CAMPAIGN_STATUS');
export const changeFolderOfCampaign = createAction<{ campaignId: number; folderId: number }>(
  'CHANGE_FOLDER_OF_CAMPAIGN'
);
export const setCampaignFolders = createAction<FolderType[]>('SET_CAMPAIGNS_FOLDERS');
export const addCampaignFolders = createAction<FolderType[]>('ADD_CAMPAIGNS_FOLDERS');
export const addNewOneCampaignsFolder = createAction<FolderType>('ADD_NEW_ONE_CAMPAIGNS_FOLDER');
export const removeCampaignFolder = createAction<number>('REMOVE_CAMPAIGNS_FOLDER');
export const updateCampaignFolder = createAction<FolderType>('UPDATE_CAMPAIGNS_FOLDER');
export const setCampaignFoldersLs = createAction<string>('SET_CAMPAIGNS_FOLDERS_LS');

/*
  Filters
 */

export const setPersonsIdsForFilterCampaigns = createAction<number[]>(
  'SET_PERSONS_IDS_FOR_FILTER_CAMPAIGN'
);
export const setDaterangeForFilterCampaigns = createAction<
  { startDate: number; endDate: number } | undefined
>('SET_DATERANGE_FOR_FILTER_CAMPAIGN');

export const setTittleForFilterCampaign = createAction<string>('SET_TITTLE_FOR_FILTER_CAMPAIGN');
export const setStatusForFilterCampaign = createAction<string>('SET_STATUS_FOR_FILTER_CAMPAIGN');
export const setExtendedFiltersCampaign = createAction<string>('SET_EXTENDED_FILTERS_CAMPAIGN');
