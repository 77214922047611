import { useCallback } from 'react';

const useFormatDate = () => {
    const formatDate = useCallback((timestamp: number, skipHours = false): string => {
        if (timestamp === -1) {
            return '-';
        }

        const dateObj = new Date(timestamp);
        const payloadMonth = dateObj.getMonth() + 1;
        const months = payloadMonth >= 10 ? payloadMonth : `0${payloadMonth}`;
        const date = dateObj.getDate() >= 10 ? dateObj.getDate() : `0${dateObj.getDate()}`;

        if (skipHours) {
            return `${months}/${date}/${dateObj.getFullYear()}`;
        }

        const ampm = dateObj.getHours() >= 12 ? 'pm' : 'am';
        const hours = dateObj.getHours() % 12;
        const minutes =
            dateObj.getMinutes() >= 10 ? dateObj.getMinutes() : `0${dateObj.getMinutes()}`;

        return `${months}/${date}/${dateObj.getFullYear()} (${hours >= 10 ? hours : `0${hours}`
        }:${minutes} ${ampm})`;
    }, []);

    return { formatDate };
};

export default useFormatDate;
