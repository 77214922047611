import { getDateShort } from '@utils/date';
import { getTimeDifference } from '@utils/getFormattedPastTime';
import React from 'react';

import {
  OrganizationInviteResponseType,
  OrganizationMemberResponseType,
} from '@ts/userOrganization.types';

import { memberRoleMap, memberStatusMap } from '@mappers/common.mappers';

import Avatar from '@uikit/Avatar/Avatar';

import ActionsMenu, { ActionsIconsTypeEnum } from '@components/ActionsMenu/ActionsMenu';

import './MembersSettings.scss';
import { OrganizationInviteStatus } from 'respona_api/generated/user-organization_pb';

// MEMBERS TABLE CONFIG
const membersTableConfig = [
  {
    title: 'Name',
    dataKey: 'fullName',
    RenderCell: ({
      row: { fullName, logoUrl, email },
    }: {
      row: OrganizationMemberResponseType;
    }): JSX.Element => (
      <div className="settings-members__table__cell settings-members__table__cell--name">
        <Avatar fullName={fullName} url={logoUrl} />
        <div className="settings-members__table__cell--name-details">
          <div className="settings-members__table__member-name">{fullName}</div>
          <div className="settings-members__table__member-email">{email}</div>
        </div>
      </div>
    ),
  },
  {
    title: 'Role',
    dataKey: 'rolesList',
    RenderCell: ({ row: { rolesList } }: { row: OrganizationMemberResponseType }): JSX.Element => (
      <span className="settings-members__table__cell--data">
        {rolesList ? memberRoleMap[rolesList[0]] : '-'}
      </span>
    ),
  },
  {
    title: 'Workspaces',
    dataKey: 'workspaces',
    RenderCell: ({
      row: { workspacesNumber },
    }: {
      row: OrganizationMemberResponseType;
    }): JSX.Element => (
      <span className="settings-members__table__cell--data">{workspacesNumber}</span>
    ),
  },
  {
    title: 'Invitation date',
    dataKey: 'invitationDate',
    RenderCell: ({
      row: { invitedBy, invitedAt },
    }: {
      row: OrganizationMemberResponseType;
    }): JSX.Element =>
      invitedAt > 0 ? (
        <span className="settings-members__table__cell--data">
          Invited on {getDateShort(new Date(invitedAt), true)}
          <br />
          by {invitedBy}
        </span>
      ) : (
        <span className="settings-members__table__cell--data">-</span>
      ),
  },
  {
    title: '',
    dataKey: 'actions',
    RenderCell: ({
      row,
      renderProps,
    }: {
      row: OrganizationMemberResponseType;
      renderProps: any;
    }): JSX.Element => {
      const actionsConfig = [
        {
          icon: 'editAction',
          title: 'Edit',
          handler: () => renderProps.onEdit(row),
          isDisable: !renderProps.isEditableInActionMenu,
        },
      ];

      return (
        <ActionsMenu
          id={`member-action-${row.id}`}
          icon={ActionsIconsTypeEnum.ThreeDots}
          onRemove={() => renderProps.onRemove(row)}
          actionsConfig={actionsConfig}
          tooltipPlace="left"
          isSingleStepRemove
        />
      );
    },
  },
];

// INVITES TABLE CONFIG
const invitesTableConfig = [
  {
    title: 'Email address',
    dataKey: 'email',
    RenderCell: ({ row: { email } }: { row: OrganizationInviteResponseType }): JSX.Element => (
      <div className="settings-members__table__member-name" style={{ margin: '0' }}>
        {email}
      </div>
    ),
  },
  {
    title: 'Role',
    dataKey: 'rolesList',
    RenderCell: ({ row: { role } }: { row: OrganizationInviteResponseType }): JSX.Element => (
      <span className="settings-members__table__cell--data">{memberRoleMap[role]}</span>
    ),
  },
  {
    title: 'Status',
    dataKey: 'status',
    RenderCell: ({ row: { status } }: { row: OrganizationInviteResponseType }): JSX.Element => (
      <span className="settings-members__table__cell--data">
        {status === OrganizationInviteStatus.PENDING ? (
          <span className="settings-members__table__cell--status settings-members__table__cell--status--active">
            Invite sent
          </span>
        ) : (
          <span className="settings-members__table__cell--status settings-members__table__cell--status--expired">
            Expired
          </span>
        )}
      </span>
    ),
  },
  {
    title: 'Workspaces',
    dataKey: 'workspaces',
    RenderCell: ({
      row: { workspaceIdsList },
    }: {
      row: OrganizationInviteResponseType;
    }): JSX.Element => (
      <span className="settings-members__table__cell--data">{workspaceIdsList?.length || '-'}</span>
    ),
  },
  {
    title: 'Invitation date',
    dataKey: 'invitationDate',
    RenderCell: ({
      row: { sentBy, sentAt },
    }: {
      row: OrganizationInviteResponseType;
    }): JSX.Element =>
      sentAt > 0 ? (
        <span className="settings-members__table__cell--data">
          Invited on {getDateShort(new Date(sentAt), true)}
          <br />
          by {sentBy}
        </span>
      ) : (
        <span className="settings-members__table__cell--data">-</span>
      ),
  },
  {
    title: '',
    dataKey: 'actions',
    RenderCell: ({
      row,
      renderProps,
    }: {
      row: OrganizationInviteResponseType;
      renderProps: any;
    }): JSX.Element => {
      const actionsConfig = [
        {
          icon: 'refreshOneArrowAround',
          title: 'Re-send',
          handler: () => renderProps.onInvite([row.email], row.role, row.workspaceIdsList),
          // isDisable: Math.abs(getTimeDifference(row.sentAt, 'm')) < 30,
        },
      ];

      return (
        <ActionsMenu
          id={`invite-action-${row.id}`}
          icon={ActionsIconsTypeEnum.ThreeDots}
          onRemove={() => renderProps.onRemove(row)}
          actionsConfig={actionsConfig}
          isSingleStepRemove
        />
      );
    },
  },
];

export const getMembersTableConfig = (isAllMembers: boolean) =>
  isAllMembers ? membersTableConfig : invitesTableConfig;
