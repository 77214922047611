import React, { useRef } from 'react';
import { SVGIcon } from '@uikit/Icon/Icon';

import './Volume.scss';

export default ({
  volume,
  onUpdateVolume,
  onMute,
  onUnmute,
}: {
  volume: number;
  onUpdateVolume: (number) => void;
  onMute: () => void;
  onUnmute: () => void;
}): JSX.Element => {
  const volumeRef = useRef();
  const volumeBarWidth = volume * 100;

  const calcClickedVolume = (e) => {
    e.stopPropagation();
    if (!volumeRef.current) {
      return 0;
    }
    const clickPositionInPage = e.pageX;
    // @ts-ignore
    const barStart = volumeRef.current.getBoundingClientRect().left + window.scrollX;
    // @ts-ignore
    const barWidth = volumeRef.current.offsetWidth;
    const clickPositionInBar = clickPositionInPage - barStart;
    const timePerPixel = 1 / barWidth;

    return timePerPixel * clickPositionInBar;
  };

  const handleVolumeDrag = (e) => {
    e.stopPropagation();
    onUpdateVolume(calcClickedVolume(e));

    const updateTimeOnMove = (eMove) => {
      const newVolume = calcClickedVolume(eMove);

      if (newVolume < 0) {
        return onUpdateVolume(0);
      }

      if (newVolume > 1) {
        return onUpdateVolume(1);
      }

      return onUpdateVolume(calcClickedVolume(eMove));
    };

    document.addEventListener('mousemove', updateTimeOnMove);

    document.addEventListener('mouseup', () => {
      document.removeEventListener('mousemove', updateTimeOnMove);
    });
  };

  return (
    <div className="player-volume">
      {volume === 0 ? (
        <span className="player-volume__icon" onClick={onUnmute}>
          <SVGIcon icon="volumeMute" />
        </span>
      ) : (
        <span className="player-volume__icon" onClick={onMute}>
          <SVGIcon icon="volume" />
        </span>
      )}
      <div
        onMouseDown={handleVolumeDrag}
        ref={volumeRef}
        className="player-volume__bar-wrapper"
        style={{
          background: `linear-gradient(to right, #251FB6 ${volumeBarWidth}%, #ABA8E6 0)`,
        }}
      />
    </div>
  );
};
