import { useCallback, useEffect } from 'react';

export default (ref, onAction, deps = []) => {
  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      onAction();
    }
  }, []);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: '20px',
      threshold: 0,
    };

    const observer = new IntersectionObserver(handleObserver, option);

    if (ref.current && handleObserver) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [handleObserver, ref.current, ...deps]);
};
