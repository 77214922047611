import React from 'react';
import { PRIMARY_COLOR } from '@constants/colors';

export default ({ color = PRIMARY_COLOR, size = 20 }) => (
  <svg width={20} height={20} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.5 1L10.8175 5.60778L16 6.35121L12.25 9.93586L13.135 15L8.5 12.6078L3.865 15L4.75 9.93586L1 6.35121L6.1825 5.60778L8.5 1Z"
      fill={color}
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
