import React from 'react';

export default function ({ color = '#8F9199', size = 12, backgroundIcon = '#E8EAED' }: { color?: string; size?: number, backgroundIcon?: string }) {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="17" cy="17" r="17" fill={backgroundIcon} />
      <path
        d="M13.4746 11H19.5246C20.8996 11 21.9996 12.1 21.9996 13.475C21.9996 14.85 20.8996 15.95 19.5246 15.95H13.4746"
        stroke={color}
        strokeWidth="1.4"
        strokeMiterlimit="10"
      />
      <path
        d="M13.475 15.95C14.8419 15.95 15.95 14.8419 15.95 13.475C15.95 12.1081 14.8419 11 13.475 11C12.1081 11 11 12.1081 11 13.475C11 14.8419 12.1081 15.95 13.475 15.95Z"
        stroke={color}
        strokeWidth="1.4"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M19.525 23.9002H13.475C12.1 23.9002 11 22.8002 11 21.4252C11 20.0502 12.1 18.9502 13.475 18.9502H19.525"
        stroke={color}
        strokeWidth="1.4"
        strokeMiterlimit="10"
      />
      <path
        d="M19.5258 23.9002C20.8927 23.9002 22.0008 22.7921 22.0008 21.4252C22.0008 20.0583 20.8927 18.9502 19.5258 18.9502C18.1589 18.9502 17.0508 20.0583 17.0508 21.4252C17.0508 22.7921 18.1589 23.9002 19.5258 23.9002Z"
        stroke={color}
        strokeWidth="1.4"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
}
