import React from 'react';

export default ({ color = '#200E32' }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.99837 0.763672C13.6692 0.763672 17.4567 4.55024 17.4567 9.22201C17.4567 13.8929 13.6693 17.6803 8.99837 17.6803C4.32749 17.6803 0.540039 13.8929 0.540039 9.22201C0.540039 4.55024 4.32755 0.763672 8.99837 0.763672ZM15.9567 9.22201C15.9567 5.37877 12.8409 2.26367 8.99837 2.26367C5.15587 2.26367 2.04004 5.37877 2.04004 9.22201C2.04004 13.0645 5.15592 16.1803 8.99837 16.1803C12.8408 16.1803 15.9567 13.0645 15.9567 9.22201Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7318 5.80015C11.024 6.09368 11.023 6.56855 10.7295 6.86081L8.35824 9.22184L10.7295 11.5829C11.023 11.8751 11.024 12.35 10.7318 12.6435C10.4395 12.937 9.96463 12.9381 9.6711 12.6458L6.7661 9.75331C6.62475 9.61257 6.54529 9.42131 6.54529 9.22184C6.54529 9.02237 6.62475 8.83111 6.7661 8.69037L9.6711 5.79787C9.96463 5.50561 10.4395 5.50663 10.7318 5.80015Z"
      fill={color}
    />
  </svg>
);
