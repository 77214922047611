import { ApiGatewayServiceClient } from 'respona_api/generated/api-gateway_pb_service';

declare const API_HOST: string;

const service = (() => {
  let instance = null;

  return {
    getInstance: (): ApiGatewayServiceClient => {
      if (!instance) {
        instance = new ApiGatewayServiceClient(API_HOST);
      }

      return instance;
    },
  };
})();

export default service;
