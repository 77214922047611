// package: campaigns
// file: campaign.proto

var campaign_pb = require("./campaign_pb");
var common_pb = require("./common_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var google_protobuf_wrappers_pb = require("google-protobuf/google/protobuf/wrappers_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var CampaignService = (function () {
  function CampaignService() {}
  CampaignService.serviceName = "campaigns.CampaignService";
  return CampaignService;
}());

CampaignService.createCampaign = {
  methodName: "createCampaign",
  service: CampaignService,
  requestStream: false,
  responseStream: false,
  requestType: campaign_pb.CampaignCreateRequest,
  responseType: campaign_pb.CampaignCrudResponse
};

CampaignService.updateCampaign = {
  methodName: "updateCampaign",
  service: CampaignService,
  requestStream: false,
  responseStream: false,
  requestType: campaign_pb.CampaignCrudRequest,
  responseType: campaign_pb.CampaignCrudResponse
};

CampaignService.getEmailSuggestions = {
  methodName: "getEmailSuggestions",
  service: CampaignService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: campaign_pb.CampaignSuggestionEmailResponse
};

CampaignService.deleteCampaign = {
  methodName: "deleteCampaign",
  service: CampaignService,
  requestStream: false,
  responseStream: false,
  requestType: campaign_pb.CampaignDeleteRequest,
  responseType: google_protobuf_empty_pb.Empty
};

CampaignService.openCampaign = {
  methodName: "openCampaign",
  service: CampaignService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: campaign_pb.CampaignCrudResponse
};

CampaignService.launchCampaign = {
  methodName: "launchCampaign",
  service: CampaignService,
  requestStream: false,
  responseStream: false,
  requestType: campaign_pb.CampaignLaunchRequest,
  responseType: google_protobuf_empty_pb.Empty
};

CampaignService.changeCampaignStatus = {
  methodName: "changeCampaignStatus",
  service: CampaignService,
  requestStream: false,
  responseStream: false,
  requestType: campaign_pb.ChangeCampaignStatus,
  responseType: campaign_pb.ChangeCampaignStatus
};

CampaignService.changeFolderOfCampaign = {
  methodName: "changeFolderOfCampaign",
  service: CampaignService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: google_protobuf_empty_pb.Empty
};

CampaignService.changeOwnerOfCampaign = {
  methodName: "changeOwnerOfCampaign",
  service: CampaignService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: google_protobuf_empty_pb.Empty
};

CampaignService.getCampaigns = {
  methodName: "getCampaigns",
  service: CampaignService,
  requestStream: false,
  responseStream: true,
  requestType: campaign_pb.CampaignRequest,
  responseType: campaign_pb.CampaignBaseResponse
};

CampaignService.getCampaignShortcutsByWorkspace = {
  methodName: "getCampaignShortcutsByWorkspace",
  service: CampaignService,
  requestStream: false,
  responseStream: true,
  requestType: common_pb.IdRequest,
  responseType: campaign_pb.CampaignShortcutResponse
};

CampaignService.getCampaignShortcutsPageable = {
  methodName: "getCampaignShortcutsPageable",
  service: CampaignService,
  requestStream: false,
  responseStream: true,
  requestType: campaign_pb.CampaignShortcutsRequest,
  responseType: campaign_pb.CampaignShortcutResponsePageable
};

CampaignService.getCampaignsFromTrash = {
  methodName: "getCampaignsFromTrash",
  service: CampaignService,
  requestStream: false,
  responseStream: true,
  requestType: common_pb.PageRequest,
  responseType: campaign_pb.CampaignBaseResponse
};

CampaignService.getLastContentInput = {
  methodName: "getLastContentInput",
  service: CampaignService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: campaign_pb.CampaignContentSearchInput
};

CampaignService.restoreCampaign = {
  methodName: "restoreCampaign",
  service: CampaignService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: google_protobuf_empty_pb.Empty
};

CampaignService.batchCampaignOperations = {
  methodName: "batchCampaignOperations",
  service: CampaignService,
  requestStream: false,
  responseStream: false,
  requestType: campaign_pb.CampaignBatchOperations,
  responseType: google_protobuf_empty_pb.Empty
};

CampaignService.duplicateCampaign = {
  methodName: "duplicateCampaign",
  service: CampaignService,
  requestStream: false,
  responseStream: true,
  requestType: campaign_pb.CampaignDuplicateRequest,
  responseType: campaign_pb.CampaignDuplicateResponse
};

CampaignService.getAllCampaignTemplates = {
  methodName: "getAllCampaignTemplates",
  service: CampaignService,
  requestStream: false,
  responseStream: true,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: campaign_pb.CampaignTemplatePreviewResponse
};

CampaignService.getCampaignTemplateDetails = {
  methodName: "getCampaignTemplateDetails",
  service: CampaignService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: campaign_pb.CampaignTemplateDetailsResponse
};

CampaignService.createCampaignFromTemplate = {
  methodName: "createCampaignFromTemplate",
  service: CampaignService,
  requestStream: false,
  responseStream: false,
  requestType: campaign_pb.CampaignTemplateCreateRequest,
  responseType: common_pb.IdResponse
};

CampaignService.changeFavoriteStateForCampaignTemplate = {
  methodName: "changeFavoriteStateForCampaignTemplate",
  service: CampaignService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: google_protobuf_wrappers_pb.BoolValue
};

CampaignService.getAIKeywordSuggestions = {
  methodName: "getAIKeywordSuggestions",
  service: CampaignService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.StringRequest,
  responseType: common_pb.StringListContainer
};

CampaignService.createCampaignFolder = {
  methodName: "createCampaignFolder",
  service: CampaignService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.Folder,
  responseType: common_pb.Folder
};

CampaignService.updateCampaignFolder = {
  methodName: "updateCampaignFolder",
  service: CampaignService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.Folder,
  responseType: common_pb.Folder
};

CampaignService.deleteCampaignFolder = {
  methodName: "deleteCampaignFolder",
  service: CampaignService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: google_protobuf_empty_pb.Empty
};

CampaignService.getAllCampaignFolders = {
  methodName: "getAllCampaignFolders",
  service: CampaignService,
  requestStream: false,
  responseStream: true,
  requestType: common_pb.PageRequest,
  responseType: common_pb.Folder
};

CampaignService.addOpportunity = {
  methodName: "addOpportunity",
  service: CampaignService,
  requestStream: false,
  responseStream: false,
  requestType: campaign_pb.AddCampaignOpportunityRequest,
  responseType: common_pb.IdResponse
};

CampaignService.addListOpportunities = {
  methodName: "addListOpportunities",
  service: CampaignService,
  requestStream: false,
  responseStream: false,
  requestType: campaign_pb.AddCampaignOpportunityListRequest,
  responseType: campaign_pb.AddCampaignOpportunityListResponse
};

CampaignService.removeOpportunity = {
  methodName: "removeOpportunity",
  service: CampaignService,
  requestStream: false,
  responseStream: false,
  requestType: campaign_pb.RemoveOpportunityRequest,
  responseType: google_protobuf_empty_pb.Empty
};

CampaignService.removeListOpportunities = {
  methodName: "removeListOpportunities",
  service: CampaignService,
  requestStream: false,
  responseStream: false,
  requestType: campaign_pb.BatchOpportunitiesRequest,
  responseType: google_protobuf_empty_pb.Empty
};

CampaignService.getOpportunityDetails = {
  methodName: "getOpportunityDetails",
  service: CampaignService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: campaign_pb.CampaignOpportunityDetails
};

CampaignService.getOpportunities = {
  methodName: "getOpportunities",
  service: CampaignService,
  requestStream: false,
  responseStream: true,
  requestType: campaign_pb.GetCampaignOpportunities,
  responseType: campaign_pb.CampaignOpportunityPageResponse
};

CampaignService.getOpportunitiesShort = {
  methodName: "getOpportunitiesShort",
  service: CampaignService,
  requestStream: false,
  responseStream: true,
  requestType: campaign_pb.GetCampaignOpportunities,
  responseType: campaign_pb.CampaignOpportunityShort
};

CampaignService.checkContactsExistsByCampaignId = {
  methodName: "checkContactsExistsByCampaignId",
  service: CampaignService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: google_protobuf_wrappers_pb.BoolValue
};

CampaignService.markAsReviewed = {
  methodName: "markAsReviewed",
  service: CampaignService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: google_protobuf_empty_pb.Empty
};

CampaignService.getOpportunitiesStat = {
  methodName: "getOpportunitiesStat",
  service: CampaignService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: campaign_pb.CampaignOpportunityStat
};

CampaignService.moveToCampaign = {
  methodName: "moveToCampaign",
  service: CampaignService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: google_protobuf_empty_pb.Empty
};

CampaignService.replaceOpportunityUrlByOpportunityId = {
  methodName: "replaceOpportunityUrlByOpportunityId",
  service: CampaignService,
  requestStream: false,
  responseStream: false,
  requestType: campaign_pb.UpdateOpportunityUrlRequest,
  responseType: campaign_pb.CampaignOpportunityShort
};

CampaignService.runOpportunityVerification = {
  methodName: "runOpportunityVerification",
  service: CampaignService,
  requestStream: false,
  responseStream: false,
  requestType: campaign_pb.CampaignValidationRequest,
  responseType: campaign_pb.CampaignValidationProgressResponse
};

CampaignService.stopOpportunityVerification = {
  methodName: "stopOpportunityVerification",
  service: CampaignService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: google_protobuf_empty_pb.Empty
};

CampaignService.resetOpportunityVerification = {
  methodName: "resetOpportunityVerification",
  service: CampaignService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: google_protobuf_empty_pb.Empty
};

CampaignService.getOpportunityVerificationProgress = {
  methodName: "getOpportunityVerificationProgress",
  service: CampaignService,
  requestStream: false,
  responseStream: true,
  requestType: common_pb.IdRequest,
  responseType: campaign_pb.CampaignValidationProgressResponse
};

CampaignService.exportListOpportunityVerificationResult = {
  methodName: "exportListOpportunityVerificationResult",
  service: CampaignService,
  requestStream: false,
  responseStream: false,
  requestType: campaign_pb.CampaignOpportunityVerificationRequest,
  responseType: common_pb.FileResponse
};

CampaignService.changeNotifyStateForOpportunityVerification = {
  methodName: "changeNotifyStateForOpportunityVerification",
  service: CampaignService,
  requestStream: false,
  responseStream: false,
  requestType: campaign_pb.CampaignOpportunityVerificationRequest,
  responseType: google_protobuf_wrappers_pb.BoolValue
};

CampaignService.exportListOpportunities = {
  methodName: "exportListOpportunities",
  service: CampaignService,
  requestStream: false,
  responseStream: false,
  requestType: campaign_pb.BatchOpportunitiesRequest,
  responseType: common_pb.FileResponse
};

CampaignService.addPeopleToCampaign = {
  methodName: "addPeopleToCampaign",
  service: CampaignService,
  requestStream: false,
  responseStream: false,
  requestType: campaign_pb.AddPeopleToCampaign,
  responseType: google_protobuf_empty_pb.Empty
};

CampaignService.addContactToOpportunity = {
  methodName: "addContactToOpportunity",
  service: CampaignService,
  requestStream: false,
  responseStream: false,
  requestType: campaign_pb.OpportunityContactRequest,
  responseType: campaign_pb.OpportunityPersonContactResponse
};

CampaignService.removeContactFromOpportunity = {
  methodName: "removeContactFromOpportunity",
  service: CampaignService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: campaign_pb.OpportunityContactContainer
};

CampaignService.removeAllContactsByPersonIdAndOpportunityId = {
  methodName: "removeAllContactsByPersonIdAndOpportunityId",
  service: CampaignService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: campaign_pb.OpportunityContactContainer
};

CampaignService.changeContactOrders = {
  methodName: "changeContactOrders",
  service: CampaignService,
  requestStream: false,
  responseStream: false,
  requestType: campaign_pb.OpportunityContactOrderRequest,
  responseType: campaign_pb.OpportunityContactContainer
};

CampaignService.getPersonalizationByOpportunityId = {
  methodName: "getPersonalizationByOpportunityId",
  service: CampaignService,
  requestStream: false,
  responseStream: false,
  requestType: campaign_pb.OpportunityPersonalizationGetRequest,
  responseType: campaign_pb.OpportunityPersonalizationResponse
};

CampaignService.createOrUpdatePersonalization = {
  methodName: "createOrUpdatePersonalization",
  service: CampaignService,
  requestStream: false,
  responseStream: false,
  requestType: campaign_pb.OpportunityPersonalizationUpdateRequest,
  responseType: campaign_pb.OpportunityPersonalizationResponse
};

CampaignService.removePersonalization = {
  methodName: "removePersonalization",
  service: CampaignService,
  requestStream: false,
  responseStream: false,
  requestType: campaign_pb.OpportunityPersonalizationDeleteRequest,
  responseType: campaign_pb.OpportunityPersonalizationResponse
};

CampaignService.checkPersonalizationsExistsByCampaignId = {
  methodName: "checkPersonalizationsExistsByCampaignId",
  service: CampaignService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: google_protobuf_wrappers_pb.BoolValue
};

CampaignService.getCampaignPeopleStatisticsByFilter = {
  methodName: "getCampaignPeopleStatisticsByFilter",
  service: CampaignService,
  requestStream: false,
  responseStream: true,
  requestType: campaign_pb.CampaignPersonStatisticsFilterRequest,
  responseType: campaign_pb.CampaignPersonStatistics
};

exports.CampaignService = CampaignService;

function CampaignServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

CampaignServiceClient.prototype.createCampaign = function createCampaign(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CampaignService.createCampaign, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.updateCampaign = function updateCampaign(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CampaignService.updateCampaign, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.getEmailSuggestions = function getEmailSuggestions(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CampaignService.getEmailSuggestions, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.deleteCampaign = function deleteCampaign(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CampaignService.deleteCampaign, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.openCampaign = function openCampaign(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CampaignService.openCampaign, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.launchCampaign = function launchCampaign(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CampaignService.launchCampaign, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.changeCampaignStatus = function changeCampaignStatus(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CampaignService.changeCampaignStatus, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.changeFolderOfCampaign = function changeFolderOfCampaign(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CampaignService.changeFolderOfCampaign, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.changeOwnerOfCampaign = function changeOwnerOfCampaign(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CampaignService.changeOwnerOfCampaign, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.getCampaigns = function getCampaigns(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(CampaignService.getCampaigns, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.getCampaignShortcutsByWorkspace = function getCampaignShortcutsByWorkspace(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(CampaignService.getCampaignShortcutsByWorkspace, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.getCampaignShortcutsPageable = function getCampaignShortcutsPageable(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(CampaignService.getCampaignShortcutsPageable, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.getCampaignsFromTrash = function getCampaignsFromTrash(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(CampaignService.getCampaignsFromTrash, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.getLastContentInput = function getLastContentInput(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CampaignService.getLastContentInput, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.restoreCampaign = function restoreCampaign(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CampaignService.restoreCampaign, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.batchCampaignOperations = function batchCampaignOperations(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CampaignService.batchCampaignOperations, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.duplicateCampaign = function duplicateCampaign(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(CampaignService.duplicateCampaign, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.getAllCampaignTemplates = function getAllCampaignTemplates(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(CampaignService.getAllCampaignTemplates, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.getCampaignTemplateDetails = function getCampaignTemplateDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CampaignService.getCampaignTemplateDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.createCampaignFromTemplate = function createCampaignFromTemplate(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CampaignService.createCampaignFromTemplate, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.changeFavoriteStateForCampaignTemplate = function changeFavoriteStateForCampaignTemplate(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CampaignService.changeFavoriteStateForCampaignTemplate, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.getAIKeywordSuggestions = function getAIKeywordSuggestions(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CampaignService.getAIKeywordSuggestions, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.createCampaignFolder = function createCampaignFolder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CampaignService.createCampaignFolder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.updateCampaignFolder = function updateCampaignFolder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CampaignService.updateCampaignFolder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.deleteCampaignFolder = function deleteCampaignFolder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CampaignService.deleteCampaignFolder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.getAllCampaignFolders = function getAllCampaignFolders(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(CampaignService.getAllCampaignFolders, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.addOpportunity = function addOpportunity(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CampaignService.addOpportunity, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.addListOpportunities = function addListOpportunities(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CampaignService.addListOpportunities, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.removeOpportunity = function removeOpportunity(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CampaignService.removeOpportunity, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.removeListOpportunities = function removeListOpportunities(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CampaignService.removeListOpportunities, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.getOpportunityDetails = function getOpportunityDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CampaignService.getOpportunityDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.getOpportunities = function getOpportunities(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(CampaignService.getOpportunities, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.getOpportunitiesShort = function getOpportunitiesShort(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(CampaignService.getOpportunitiesShort, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.checkContactsExistsByCampaignId = function checkContactsExistsByCampaignId(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CampaignService.checkContactsExistsByCampaignId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.markAsReviewed = function markAsReviewed(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CampaignService.markAsReviewed, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.getOpportunitiesStat = function getOpportunitiesStat(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CampaignService.getOpportunitiesStat, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.moveToCampaign = function moveToCampaign(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CampaignService.moveToCampaign, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.replaceOpportunityUrlByOpportunityId = function replaceOpportunityUrlByOpportunityId(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CampaignService.replaceOpportunityUrlByOpportunityId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.runOpportunityVerification = function runOpportunityVerification(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CampaignService.runOpportunityVerification, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.stopOpportunityVerification = function stopOpportunityVerification(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CampaignService.stopOpportunityVerification, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.resetOpportunityVerification = function resetOpportunityVerification(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CampaignService.resetOpportunityVerification, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.getOpportunityVerificationProgress = function getOpportunityVerificationProgress(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(CampaignService.getOpportunityVerificationProgress, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.exportListOpportunityVerificationResult = function exportListOpportunityVerificationResult(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CampaignService.exportListOpportunityVerificationResult, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.changeNotifyStateForOpportunityVerification = function changeNotifyStateForOpportunityVerification(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CampaignService.changeNotifyStateForOpportunityVerification, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.exportListOpportunities = function exportListOpportunities(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CampaignService.exportListOpportunities, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.addPeopleToCampaign = function addPeopleToCampaign(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CampaignService.addPeopleToCampaign, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.addContactToOpportunity = function addContactToOpportunity(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CampaignService.addContactToOpportunity, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.removeContactFromOpportunity = function removeContactFromOpportunity(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CampaignService.removeContactFromOpportunity, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.removeAllContactsByPersonIdAndOpportunityId = function removeAllContactsByPersonIdAndOpportunityId(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CampaignService.removeAllContactsByPersonIdAndOpportunityId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.changeContactOrders = function changeContactOrders(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CampaignService.changeContactOrders, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.getPersonalizationByOpportunityId = function getPersonalizationByOpportunityId(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CampaignService.getPersonalizationByOpportunityId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.createOrUpdatePersonalization = function createOrUpdatePersonalization(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CampaignService.createOrUpdatePersonalization, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.removePersonalization = function removePersonalization(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CampaignService.removePersonalization, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.checkPersonalizationsExistsByCampaignId = function checkPersonalizationsExistsByCampaignId(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CampaignService.checkPersonalizationsExistsByCampaignId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CampaignServiceClient.prototype.getCampaignPeopleStatisticsByFilter = function getCampaignPeopleStatisticsByFilter(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(CampaignService.getCampaignPeopleStatisticsByFilter, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

exports.CampaignServiceClient = CampaignServiceClient;

