import React, { useCallback, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useSelector, useDispatch } from 'react-redux';

import useWorkspaceMembers from '@hooks/useWorkspaceMembers';
import { FolderType, TagType } from '@ts/common.types';

import { addNotification } from '@redux/actions/notifications.actions';
import {
  removeCompaniesBatchRequest,
  createFolderAndMoveCompaniesRequest,
  moveCompaniesToFolderRequest,
  createFolderCompanyRequest,
  renameCompanyFolderRequest,
  removeCompanyFolderRequest,
} from '@redux/thunks/peopleCompany.thunks';
import {
  companiesFiltersSelector,
  companiesFoldersSelector,
} from '@redux/selectors/companies.selectors';

import Loader from '@uikit/Loader/Loader';
import PageHeader from '@components/PageHeader/PageHeader';
import SimpleActionSidebar from '@components/Relationships/SimpleActionSidebar/SimpleActionSidebar';
import { getCurrentWorkspaceId, workspacesSelector } from '@redux/selectors/workspaces.selectors';
import { getBlobFromBase64 } from '@utils/createFileFromBytesArray';
import downloadCSV from '@utils/downloadCSV';
import {
  exportCompanyToCSVApi,
  getAllWebsitesApi,
  getAllCompanyTagsApi,
  createOrUpdateCompanyApi,
  getWebsiteByIdApi,
} from '@api/website.api';
import RemoveCompaniesModal from '@components/Relationships/RemoveCompaniesModal/RemoveCompaniesModal';
import Pagination from '@uikit/Pagination/Pagination';
import usePagination from '@hooks/usePagination';
import useNonInitialEffect from '@hooks/useNonInitialEffect';
import { EntityRelationRequest } from 'respona_api/generated/common_pb';

import OrganizationTable from '../OrganizationsTable/OrganizationsTable';
import OrganizationsMoveToFolderModal from '../MoveToFolderModals/OrgnizationsMoveToFolderModal';
import OrganizationsHeaderActions from '../OrganizationsHeaderActions/OrganizationsHeaderActions';

import './RelationshipsOrganizations.scss';
import { WebsiteType } from '@ts/company.types';
import { CampaignToAddPeopleType } from '@components/Relationships/PeopleHeaderActions/MoreActionsButton/MoreActionsButton';
import AddPeopleToCampaignSidebar from '@components/Relationships/AddPeopleToCampaignSidebar/AddPeopleToCampaignSidebar';
import OrganizationInfoSidebar from '@components/Relationships/OrganizationInfoSidebar/OrganizationInfoSidebar';
import Folders from '@uikit/Folders/Folders';
import { SvgIconType } from '@uikit/Icon/Icon';
import useCampaignShortcutsByWorkspace from '@hooks/useCampaignShortcutsByWorkspace';
import WebsitesSidebar from '@components/Relationships/WebsitesSidebar/WebsitesSidebar';

const ROW_HEIGHT = 90;

function RelationshipsOrganizations({ searchParams }): JSX.Element {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const folderId = searchParams.folderId ? Number(searchParams.folderId) : undefined;
  const currentWorkspaceId = useSelector(getCurrentWorkspaceId);
  const filters = useSelector(companiesFiltersSelector);
  const { items: companiesFolders } = useSelector(companiesFoldersSelector);
  const { workspaces: workspacesData } = useSelector(workspacesSelector);
  const { items: campaigns } = useCampaignShortcutsByWorkspace();

  const { members } = useWorkspaceMembers();

  const [openedSidebarCompany, changeOpenedSidebarCompany] = useState(null);
  const [isRemovingSidebarOpen, changeRemovingSidebarOpen] = useState(null);
  const [isExportButtonLoading, changeIsExportButtonLoading] = useState<boolean>(false);
  const [isRemovingModalOpen, changeRemovingModalOpen] = useState(false);
  const [isExportSidebarOpen, changeExportSidebarOpen] = useState(null);
  const [selectedCompanyForModal, changeSelectedCompanyForModal] = useState(null);
  const [selectedIds, changeSelectedIds] = useState({});
  const [searchString, changeSearchString] = useState('');
  const { page, changePage, limit, changeLimit } = usePagination(ROW_HEIGHT, 200 + ROW_HEIGHT);
  const [campaignsList, setCampaignsList] = useState(null);
  const [isOpenAddPeopleToCampaignSidebar, changeIsOpenAddPeopleToCampaignSidebar] =
    useState<null | CampaignToAddPeopleType>(null);

  const handleOpenExportCompany = useCallback(() => changeExportSidebarOpen(true), []);
  const handleCloseExportCompany = useCallback(() => changeExportSidebarOpen(false), []);
  const handleOpenRemovingCompany = useCallback(() => changeRemovingSidebarOpen(true), []);
  const handleCloseRemovingCompany = useCallback(() => changeRemovingSidebarOpen(false), []);

  const getSelectedIds = useCallback(() => Object.keys(selectedIds).map(Number), [selectedIds]);

  const queryKey = ['relationships-websites', page, limit, folderId, searchString, filters];

  const {
    data: websitesData,
    isLoading,
    isIdle,
    isFetching,
    isRefetching,
    isError,
    refetch,
  } = useQuery(
    queryKey,
    () => {
      return getAllWebsitesApi(
        page - 1,
        limit,
        folderId ? Number(folderId) : undefined,
        searchString,
        Object.values(filters)
      );
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: true,
    }
  );

  const { data: tagsData } = useQuery<TagType[]>(
    ['contacts-sidebar-websites-tags', 'current-tags'],
    () => getAllCompanyTagsApi(),
    {
      enabled: true,
      refetchOnWindowFocus: false,
    }
  );

  const handleOpenAddPeopleToCampaignSidebar = useCallback(
    (campaignName: string, campaignId: number) =>
      changeIsOpenAddPeopleToCampaignSidebar({ campaignName, campaignId }),
    []
  );

  const handleCloseAddPeopleToCampaignSidebar = useCallback(
    () => changeIsOpenAddPeopleToCampaignSidebar(null),
    []
  );

  const handleCreateOrganizationFolder = useCallback(
    (title?: string) => createFolderCompanyRequest(dispatch, title),
    []
  );

  const query = new URLSearchParams(location.search);

  useEffect(() => {
    if (!query.get('id')) {
      return;
    }
    getWebsiteByIdApi(Number(query.get('id')), currentWorkspaceId)
      .then(changeOpenedSidebarCompany);
  }, [query.get('id')]);

  const handleCloseCompanySidebar = () => changeOpenedSidebarCompany(null);
  const handleCreateCompanySidebarOpen = () => changeOpenedSidebarCompany({});
  const handleEditCompanySidebarOpen = (company) => changeOpenedSidebarCompany(company);
  const handleCloseModal = () => changeSelectedCompanyForModal(null);
  const handleMoveCompanyByModal = (company) => changeSelectedCompanyForModal(company);

  const handleCreateOrMoveToFolder = (tmpFolderId?: number) => {
    if (tmpFolderId) {
      moveCompaniesToFolderRequest(
        tmpFolderId,
        Object.keys(selectedIds).map(Number),
        EntityRelationRequest.SelectionType.SELECTED
      ).then(() => {
        refetch();
        dispatch(
          addNotification({
            title: 'Organizations moved',
            type: 'success',
          })
        );
      });
    } else {
      createFolderAndMoveCompaniesRequest(
        dispatch,
        Object.keys(selectedIds).map(Number),
        EntityRelationRequest.SelectionType.SELECTED
      ).then(() => {
        refetch();
        dispatch(
          addNotification({
            title: 'Organizations moved to new folder',
            type: 'success',
          })
        );
      });
    }
  };

  const handleRemoveCompanies = (companiesIds: number[]) => {
    let requestWebsites: number[];
    if (companiesIds?.length > 0) {
      requestWebsites = companiesIds;
    } else if (getSelectedIds()?.length > 0) {
      requestWebsites = getSelectedIds();
    } else {
      requestWebsites = [];
    }
    removeCompaniesBatchRequest(requestWebsites, currentWorkspaceId).then(() => {
      if (websitesData.every(({ id }) => selectedIds[id] !== undefined)) {
        if (page !== 1) {
          changePage(page - 1);
        } else {
          refetch();
        }
      } else {
        refetch();
      }
      handleCloseRemovingCompany();
      changeSelectedIds({});
      changeRemovingModalOpen(false);
    });
  };

  const handleExportCompany = () => {
    changeIsExportButtonLoading(true);
    exportCompanyToCSVApi(getSelectedIds(), folderId, searchString)
      .then((res) => {
        const { title, fileBytes } = res.find((item) => item.complete)?.file;

        if (!fileBytes) {
          dispatch(
            addNotification({ type: 'error', title: 'Empty file. Please, try again later.' })
          );
          return;
        }

        const fileBlob = getBlobFromBase64(fileBytes, 'text/csv');
        downloadCSV(title || `Company-export-${Date.now()}`, fileBlob);
      })
      .finally(() => {
        changeIsExportButtonLoading(false);
        changeExportSidebarOpen(false);
      });
  };

  const handleToggleRowSelection = (rowId) => {
    changeSelectedIds((prevState) => {
      if (prevState[rowId] === undefined) {
        return {
          ...prevState,
          [rowId]: true,
        };
      }

      // @ts-ignore
      const { [rowId]: omitted, ...newState } = prevState;
      return newState;
    });
  };

  useNonInitialEffect(() => {
    changeSelectedIds({});
  }, [folderId]);

  const handleSelectAllToggle = (isSelected) => {
    if (isSelected) {
      changeSelectedIds((prevState) => {
        const newSelectedIds = websitesData?.reduce(
          (acc, { id }) => ({
            ...acc,
            [id]: id,
          }),
          {}
        );

        return {
          ...prevState,
          ...newSelectedIds,
        };
      });
    } else {
      changeSelectedIds((prevState) => {
        return Object.keys(prevState).reduce((acc, storedId) => {
          if (!websitesData?.some(({ id }) => id === Number(storedId))) {
            return {
              ...acc,
              [storedId]: storedId,
            };
          }

          return acc;
        }, {});
      });
    }
  };

  useEffect(() => {
    if (campaigns?.length > 0) {
      setCampaignsList(campaignsList);
    }
  }, [campaigns]);

  const retrieveContactIds = () => {
    const contactIds = [];
    const selectedWebsiteIds = getSelectedIds();

    websitesData?.forEach(({ id, contactsList }) => {
      if (!selectedWebsiteIds.includes(id)) {
        return;
      }

      contactsList.forEach((contact) => {
        contactIds.push(contact.id);
      });
    });

    return contactIds;
  };

  const contactIds = retrieveContactIds();

  const handleCreateCompany = (website: {
    domainAuthority: number;
    title: string;
    website: string;
  }) => {
    createOrUpdateCompanyApi({
      id: -1,
      title: website.title,
      domain: website.website,
      domainAuthority: website.domainAuthority,
      workspaceId: currentWorkspaceId,
    }).then((newWebsite) => {
      queryClient.setQueryData<WebsiteType[]>(queryKey, (oldData) =>
        oldData ? [newWebsite, ...oldData] : [newWebsite]
      );
    });
  };

  const mapToFolders = (items: FolderType[]): (FolderType & { icon: SvgIconType })[] => {
    return items.map((item) => ({ ...item, icon: 'folder' }));
  };

  const handleRename = (id: number, title: string) => {
    dispatch(renameCompanyFolderRequest(id, title));
    return Promise.resolve();
  };

  const handleRemove = (id: number) => {
    dispatch(removeCompanyFolderRequest(id));
    return Promise.resolve();
  };

  return (
    <div className="relationships-organizations">
      <PageHeader
        title="Websites"
        howToLink="https://help.respona.com/en/articles/6332429-how-to-use-relationships"
        renderHeaderActions={() => (
          <OrganizationsHeaderActions
            searchString={searchString}
            onChangeSearchString={changeSearchString}
            selectedIds={Object.keys(selectedIds).map(Number)}
            onOpenRemoveCompany={handleOpenRemovingCompany}
            onOpenExportCompany={handleOpenExportCompany}
            onCreateCompany={handleCreateCompanySidebarOpen}
            onCreateOrMoveFolder={handleCreateOrMoveToFolder}
            onAddToCampaign={handleOpenAddPeopleToCampaignSidebar}
            members={members || []}
            tags={tagsData || []}
            campaignsList={campaignsList}
            workspaces={workspacesData || []}
          />
        )}
      />

      <Folders
        path="/relationships/websites"
        items={mapToFolders(companiesFolders)}
        handleCreate={handleCreateOrganizationFolder}
        entityName="Folder"
        paramName="folderId"
        allIcon="office"
        allText="All websites"
        onRemoveClick={(id) => handleRemove(id)}
        onRenameClick={(id, title) => handleRename(id, title)}
      />

      <div className="relationships-organizations__table-wrapper">
        <Loader isLoading={(isLoading || isFetching || isIdle) && !isRefetching} withTopMargin>
          <OrganizationTable
            websites={websitesData}
            isError={isError}
            selectedRows={selectedIds}
            onToggleRowSelection={handleToggleRowSelection}
            onToggleSelectAll={handleSelectAllToggle}
            onCreateCompanyClick={handleCreateCompanySidebarOpen}
            onEditCompany={handleEditCompanySidebarOpen}
            onMoveCompany={handleMoveCompanyByModal}
            onRemoveCompany={handleRemoveCompanies}
          />
        </Loader>
      </div>
      <Pagination
        curPage={page}
        onChangePage={changePage}
        pageLimit={limit}
        onChangePageLimit={changeLimit}
        totalItems={websitesData?.length && websitesData[0].totalItems}
      />
      <OrganizationInfoSidebar
        isOpen={openedSidebarCompany !== null && openedSidebarCompany.id == null}
        onClose={handleCloseCompanySidebar}
        onSave={handleCreateCompany}
        fields={openedSidebarCompany}
      />
      {selectedCompanyForModal ? (
        <OrganizationsMoveToFolderModal
          itemToMove={selectedCompanyForModal}
          onClose={handleCloseModal}
        />
      ) : null}
      <SimpleActionSidebar
        title="Remove"
        subtitle="Removing organizations is not reversable"
        isOpen={isRemovingSidebarOpen}
        onClose={handleCloseRemovingCompany}
        itemsNumber={
          Object.values(selectedIds).length ||
          (websitesData && websitesData[0] && websitesData[0].totalItems)
        }
        itemsTitle="organizations"
        onAction={() => changeRemovingModalOpen(true)}
      />
      <RemoveCompaniesModal
        isRemovingModalOpen={isRemovingModalOpen}
        changeRemovingModalOpen={changeRemovingModalOpen}
        onRemoveCompanies={handleRemoveCompanies}
        selectedIds={selectedIds}
        companies={websitesData}
      />
      <AddPeopleToCampaignSidebar
        isOpen={!!isOpenAddPeopleToCampaignSidebar}
        onClose={handleCloseAddPeopleToCampaignSidebar}
        selectedIds={contactIds}
        itemsNumber={contactIds.length}
        campaignInfo={isOpenAddPeopleToCampaignSidebar}
      />
      <SimpleActionSidebar
        title="Export"
        subtitle="Export your organizations in a CSV file"
        isOpen={isExportSidebarOpen}
        onClose={handleCloseExportCompany}
        itemsNumber={
          Object.values(selectedIds).length ||
          (websitesData && websitesData[0] && websitesData[0].totalItems)
        }
        itemsTitle="organizations"
        onAction={handleExportCompany}
        isActionButtonLoading={isExportButtonLoading}
        actionButtonLoadingText="Exporting"
      />

      <WebsitesSidebar
        isOpen={!!openedSidebarCompany && openedSidebarCompany?.id > 0}
        itemId={openedSidebarCompany?.id}
        onClose={handleCloseCompanySidebar}
        data={openedSidebarCompany}
        tableQueryKey={queryKey}
      />
    </div>
  );
}

export default RelationshipsOrganizations;
