import React from 'react';

export default () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.6364 19.9091H4.54545C2.53636 19.9091 1 21.4454 1 23.4545V27H15.1818V23.4545C15.1818 21.4454 13.6455 19.9091 11.6364 19.9091Z"
      stroke="#50ABF1"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M8.09135 16.3637C6.08226 16.3637 4.5459 14.8273 4.5459 12.8182V11.6364C4.5459 9.62731 6.08226 8.09094 8.09135 8.09094C10.1004 8.09094 11.6368 9.62731 11.6368 11.6364V12.8182C11.6368 14.8273 10.1004 16.3637 8.09135 16.3637Z"
      stroke="#50ABF1"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M15.1816 1V12.8182L19.9089 9.27273H26.9998V1H15.1816Z"
      stroke="#50ABF1"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);
