import React from 'react';

import Input from 'src/uikit/Input/Input';
import { FilterType } from '@components/CampaignSettings/Filters/filtersConfig/index';

export default {
  label: 'Min Word Count',
  filterKey: 'minWordCount',
  defaultValue: 10,
  render: ({ value, onApply }): JSX.Element => {
    const handleChange = ({ target: { value: inputValue } }) => {
      const numValue = Number(inputValue);

      if (numValue < 0) {
        return onApply(0);
      }

      onApply(String(numValue));
    };

    return (
      <Input
        className="campaign-creation-filters__input-field"
        value={value}
        onChange={handleChange}
        type="number"
        placeholder="Insert min words count"
      />
    );
  },
} as FilterType;
