import React from 'react';

import { SVGIcon } from '@uikit/Icon/Icon';
import { authService } from 'src/services/authService';

const LogoutButton = ({ color }: { color?: string }): JSX.Element => {
  const handleLogout = (e) => {
    e.stopPropagation();

    authService.logout();
  };

  return (
    <div
      className="sidebar-footer__profile-logout-button"
      onClick={handleLogout}
      role="button"
      tabIndex={0}
    >
      <SVGIcon icon="logout" color={color} />
    </div>
  );
};

export default LogoutButton;
