import React from 'react';

import Table, { Tr, Th, Td, TBody, THead } from 'src/uikit/Table/Table';

type RenderCellPropsType = {
  row: any;
  dataKey?: string;
  renderProps?: any;
  rowIndex?: number;
};

export type TableConfigType = {
  title?: string;
  dataKey: string;
  RenderTitle?: (any) => JSX.Element;
  RenderCell?: (props: RenderCellPropsType) => JSX.Element;
  cellClassName?: string;
  colspan?: number;
}[];

type ConfigurableTablePropsType = {
  tableRef?: any;
  config: TableConfigType;
  data: any;
  renderProps?: {
    [propKey: string]: any;
  };
  availableColumns?: string[];
  rowClassName?: string;
  tableClassName?: string;
  RenderRow?: (any) => JSX.Element;
  onRowClick?: (any) => void;
};

function TableHeader({ columns, renderProps, onePageItems }): JSX.Element {
  return (
    <THead>
      <Tr>
        {columns.map(({ RenderTitle, title, dataKey, colspan, isHidden }) =>
          isHidden ? null : (
            <Th key={`th-${dataKey}`} colspan={colspan}>
              {RenderTitle ? (
                <RenderTitle
                  renderProps={{ dataKey, ...renderProps }}
                  onePageItems={onePageItems}
                />
              ) : (
                title
              )}
            </Th>
          )
        )}
      </Tr>
    </THead>
  );
}

const DefaultRenderCell = ({ row, dataKey }: RenderCellPropsType): JSX.Element =>
  row[dataKey] !== undefined ? row[dataKey] : '';

function DefaultTableRow({ children, rowClassName, onRowClick }): JSX.Element {
  return (
    <Tr className={rowClassName} onClick={onRowClick}>
      {children}
    </Tr>
  );
}

function ConfigurableTable({
  tableRef,
  config,
  data,
  renderProps = {},
  availableColumns,
  rowClassName = '',
  tableClassName = '',
  onRowClick,
  RenderRow = DefaultTableRow,
}: ConfigurableTablePropsType): JSX.Element {
  const filtredColumns = availableColumns
    ? config.filter(({ dataKey }) => availableColumns.includes(dataKey))
    : config;

  return (
    <Table tableRef={tableRef} className={tableClassName}>
      <TableHeader columns={filtredColumns} renderProps={renderProps} onePageItems={data} />
      <TBody>
        {data.map((dataRow, index: number) => (
          <RenderRow
            rowClassName={rowClassName}
            row={dataRow}
            renderProps={renderProps}
            columns={filtredColumns}
            key={`tr-${dataRow.id}-${index}`}
            onRowClick={onRowClick ? () => onRowClick(dataRow) : undefined}
          >
            {filtredColumns.map(
              ({ RenderCell = DefaultRenderCell, dataKey, cellClassName = '' }) => (
                <Td className={cellClassName} key={`td-${dataKey}-${dataRow.id}`}>
                  <RenderCell
                    row={dataRow}
                    dataKey={dataKey}
                    renderProps={renderProps}
                    rowIndex={index}
                  />
                </Td>
              )
            )}
          </RenderRow>
        ))}
      </TBody>
    </Table>
  );
}

export default ConfigurableTable;
