import React from 'react';

// @ts-ignore
import notFoundSearch from '@uikit/Illustrations/notFoundSearch.svg';

const NothingWasFound = () => {
  return (
    <div className="manual-confirm-contact-sidebar__not_found">
      <img
        src={notFoundSearch}
        className="manual-confirm-contact-sidebar__not_found-illustration"
        alt="not_found-illustration"
      />
      Nothing was found :(
    </div>
  );
};

export default NothingWasFound;
