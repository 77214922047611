import { Dispatch } from 'redux';
import {
  buyBonusCreditsApi,
  getBillingAddressApi,
  getBillingDetailsApi,
  getBillingShortDetailsApi,
  updateBillingAddressApi,
  upgradeContactsNumberApi,
  upgradeMailboxAccountsNumberApi,
  upgradePlanCreditsApi,
} from '@api/billing.api';

import loadingStatuses from '@constants/loadingStatuses';
import {
  setBillingData,
  setBillingLs,
  setBillingCreditsLs,
  setBillingCreditsData,
  setBillingAddressLs,
  setBillingAddressData,
} from '@redux/actions/billing.actions';
import {
  BillingAddressType,
  BillingCycleTypeValue,
  BillingDetailsShortType,
  BillingSubscriptionTypeMapValue,
  BillingType,
} from '@ts/billing.types';
import { addNotification } from '@redux/actions/notifications.actions';
import { setActivePaymentStatusInAppState } from '@redux/actions/appState.actions';
import {
  BillingSubscriptionCycle,
  BillingSubscriptionCycleMap,
} from 'respona_api/generated/billing_pb';
import gtm from 'src/libs/gtm';
import { useSelector } from 'react-redux';
import { userProfileSelector } from '@redux/selectors/userProfile.selector';

export const fetchBillingDetails =
  (silent = false) =>
  (dispatch: Dispatch): Promise<BillingType> => {
    !silent && dispatch(setBillingLs(loadingStatuses.INITED));

    return getBillingDetailsApi()
      .then((billing) => {
        dispatch(setBillingData(billing));
        return billing;
      })
      .finally(() => {
        dispatch(setBillingLs(loadingStatuses.LOADED));
      });
  };

export const fetchBillingCredits =
  (silent = false) =>
  (dispatch: Dispatch): Promise<BillingDetailsShortType> => {
    !silent && dispatch(setBillingCreditsLs(loadingStatuses.INITED));

    return getBillingShortDetailsApi()
      .then((billingCredits) => {
        dispatch(setActivePaymentStatusInAppState(billingCredits.active));
        dispatch(setBillingCreditsData(billingCredits));
        return billingCredits;
      })
      .finally(() => {
        dispatch(setBillingCreditsLs(loadingStatuses.LOADED));
      });
  };

export const fetchBillingAddress =
  () =>
  (dispatch: Dispatch): void => {
    dispatch(setBillingAddressLs(loadingStatuses.INITED));

    getBillingAddressApi()
      .then((address) => {
        dispatch(setBillingAddressData(address));
      })
      .finally(() => {
        dispatch(setBillingAddressLs(loadingStatuses.LOADED));
      });
  };

export const updateBillingAddress =
  (addressFields: BillingAddressType) =>
  (dispatch: Dispatch): void => {
    updateBillingAddressApi(addressFields).then((updateAddress) => {
      dispatch(setBillingAddressData(updateAddress));
      dispatch(addNotification({ title: 'Billing address updated', type: 'success' }));
    });
  };

export const upgradePlanCreditsRequest =
  (
    credits: number,
    type: BillingSubscriptionTypeMapValue,
    cycle: BillingSubscriptionCycleMap[keyof BillingSubscriptionCycleMap],
    userEmail
  ) =>
  (dispatch) => {
    return upgradePlanCreditsApi(credits, type, cycle).then((response) => {
      dispatch(addNotification({ title: 'Subscription updated', type: 'success' }));
      dispatch(fetchBillingCredits(true));
      dispatch(fetchBillingDetails(true));
      gtm.makePayment(
        userEmail,
        response.optionalTransactionCost,
        response.optionalTransactionId,
        'Credits',
        response.optionalSubscriptionPrice,
        cycle,
        credits
      );
    });
  };

export const buyBonusCreditsRequest =
  (bonusCreditsNumber: number, userEmail: string) => (dispatch) => {
    return new Promise<void>((res, rej) => {
      buyBonusCreditsApi(bonusCreditsNumber)
        .then((response) => {
          dispatch(
            addNotification({
              title: `You bought ${bonusCreditsNumber} bonus credits`,
              type: 'success',
            })
          );
          dispatch(fetchBillingCredits(true));
          dispatch(fetchBillingDetails(true));
          gtm.makePayment(
            userEmail,
            response.optionalTransactionCost,
            response.optionalTransactionId,
            'Credits',
            response.optionalSubscriptionPrice,
            BillingSubscriptionCycle.NONE,
            bonusCreditsNumber
          );
          res();
        })
        .catch(rej);
    });
  };

export const upgradeMailboxAccountsNumberRequest =
  (mailboxesNumber: number, cycle: BillingCycleTypeValue, userEmail: string) => (dispatch) => {
    return upgradeMailboxAccountsNumberApi(mailboxesNumber).then((response) => {
      dispatch(addNotification({ title: 'Success mailbox upgrade', type: 'success' }));
      dispatch(fetchBillingDetails(true));
      gtm.makePayment(
        userEmail,
        response.optionalTransactionCost,
        response.optionalTransactionId,
        'Emails',
        response.optionalSubscriptionPrice,
        cycle,
        mailboxesNumber
      );
    });
  };

export const upgradeContactsNumberRequest =
  (
    amount: number,
    type: BillingSubscriptionTypeMapValue,
    cycle: BillingCycleTypeValue,
    userEmail: string
  ) =>
  (dispatch) => {
    return upgradeContactsNumberApi(amount, type, cycle).then((response) => {
      dispatch(addNotification({ title: 'Contacts limit upgraded', type: 'success' }));
      dispatch(fetchBillingDetails(true));
      gtm.makePayment(
        userEmail,
        response.optionalTransactionCost,
        response.optionalTransactionId,
        'Contacts',
        response.optionalSubscriptionPrice,
        cycle,
        amount
      );
    });
  };
