import React from 'react';
import { PRIMARY_COLOR } from '@constants/colors';

export default ({ color = PRIMARY_COLOR }) => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M1.41675 9.91663H5.66675V12.0416H11.3334V9.91663H15.5834"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0418 1.41663H14.1668C14.5425 1.41663 14.9028 1.56588 15.1685 1.83156C15.4342 2.09723 15.5834 2.45757 15.5834 2.83329V14.1666C15.5834 14.5424 15.4342 14.9027 15.1685 15.1684C14.9028 15.434 14.5425 15.5833 14.1668 15.5833H2.83342C2.45769 15.5833 2.09736 15.434 1.83168 15.1684C1.566 14.9027 1.41675 14.5424 1.41675 14.1666V2.83329C1.41675 2.45757 1.566 2.09723 1.83168 1.83156C2.09736 1.56588 2.45769 1.41663 2.83342 1.41663H4.95842"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 0.708313V7.79165"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3334 4.95831L8.50008 7.79165L5.66675 4.95831"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="17" height="17" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
