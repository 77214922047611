import { Dispatch } from 'redux';

import {
  csRemoveSequenceStep,
  csSetSequence,
  csSetSequenceLs,
  csUpdateSingleSequenceStep,
  csAddSequenceStep,
  csSetSequenceAdditionalContent,
} from '@redux/actions/sequence.actions';
import loadingStatuses from '@constants/loadingStatuses';
import {
  createTemplateStepApi,
  deleteTemplateStepApi,
  getTemplateStepsApi,
  updateTemplateStepApi,
  updateTemplateStepDelayApi,
} from '@api/templates.api';
import { TemplateStepTransformedType } from '@ts/template.types';
import { clearPersonalization } from '@redux/actions/personalizations.actions';
import { emptyEditorHtml } from '@constants/html.constants';
import { csThrowVerificationStatusInAllCampaignSelectedOpportunities } from '@redux/actions/campaignSettings.actions';

export const fetchSequenceInfo = (templateId: number) => (dispatch: Dispatch) => {
  dispatch(csSetSequenceLs(loadingStatuses.INITED));

  return getTemplateStepsApi(templateId)
    .then(({ stepsList, enabledUnsubscribeContent, enabledReferralContent }) => {
      dispatch(
        csSetSequenceAdditionalContent({
          enabledUnsubscribeContent,
          enabledReferralContent,
        })
      );
      return dispatch(csSetSequence(stepsList));
    })
    .finally(() => dispatch(csSetSequenceLs(loadingStatuses.LOADED)));
};

export const createSequenceStep = (templateId: number) => (dispatch: Dispatch, getState) => {
  const {
    sequence: { steps },
  } = getState();
  const creatingStep = {
    subject: emptyEditorHtml,
    content: emptyEditorHtml,
    isSameThread: true,
    sendDelay: 7,
    order: steps.length + 1,
  };

  return createTemplateStepApi(templateId, creatingStep).then((createdStep) => {
    dispatch(clearPersonalization());
    dispatch(csAddSequenceStep(createdStep));
    dispatch(csThrowVerificationStatusInAllCampaignSelectedOpportunities());
  });
};

export const updateSequenceStepSubject =
  (templateId: number, step: TemplateStepTransformedType) =>
  (dispatch: Dispatch): Promise<any> => {
    return updateTemplateStepApi(templateId, step).then((updatedStep) => {
      dispatch(clearPersonalization());
      dispatch(csThrowVerificationStatusInAllCampaignSelectedOpportunities());
      return dispatch(csUpdateSingleSequenceStep(updatedStep));
    });
  };

export const updateSequenceStepContent =
  (templateId: number, step: TemplateStepTransformedType) =>
  (dispatch: Dispatch): Promise<any> => {
    return updateTemplateStepApi(templateId, step).then((updatedStep) => {
      dispatch(clearPersonalization());
      dispatch(csThrowVerificationStatusInAllCampaignSelectedOpportunities());
      return dispatch(csUpdateSingleSequenceStep(updatedStep));
    });
  };

export const updateSequenceStepDelay =
  (templateId: number, step: any, sendDelay: number) =>
  (dispatch: Dispatch): Promise<any> => {
    return updateTemplateStepDelayApi(templateId, step.id, sendDelay).then((updatedStepRes) => {
      dispatch(clearPersonalization());
      dispatch(csThrowVerificationStatusInAllCampaignSelectedOpportunities());
      return dispatch(csUpdateSingleSequenceStep(updatedStepRes));
    });
  };

export const changeReferralContentStateOfTemplate =
  (templateId: number, step: any, sendDelay: number) =>
  (dispatch: Dispatch): Promise<any> => {
    return updateTemplateStepDelayApi(templateId, step.id, sendDelay).then((updatedStepRes) => {
      dispatch(clearPersonalization());
      dispatch(csThrowVerificationStatusInAllCampaignSelectedOpportunities());
      return dispatch(csUpdateSingleSequenceStep(updatedStepRes));
    });
  };

export const changeUnsubscribeContentStateOfTemplate =
  (templateId: number, step: any, sendDelay: number) =>
  (dispatch: Dispatch): Promise<any> => {
    return updateTemplateStepDelayApi(templateId, step.id, sendDelay).then((updatedStepRes) => {
      dispatch(clearPersonalization());
      dispatch(csThrowVerificationStatusInAllCampaignSelectedOpportunities());
      return dispatch(csUpdateSingleSequenceStep(updatedStepRes));
    });
  };

export const deleteSequenceStep =
  (templateId: number, steps: TemplateStepTransformedType[], index: number) =>
  (dispatch: Dispatch) => {
    const deletingStep = steps[index];

    dispatch(csRemoveSequenceStep(deletingStep.id));
    dispatch(csThrowVerificationStatusInAllCampaignSelectedOpportunities());
    deleteTemplateStepApi(deletingStep.id, templateId).then(({ stepsList }) => {
      dispatch(csSetSequence(stepsList));
    });
  };
