import { SVGIcon } from '@uikit/Icon/Icon';
import { components } from 'react-select';
import { fieldTypeToIconMap } from '@components/Settings/CustomFields/customFieldsTableConfig';
import React from 'react';

const Option = ({ children, ...restProps }): JSX.Element => {
  return (
    <components.Option {...restProps}>
      <div className="custom-fields-sidebar__type-option">
        <div className="custom-fields-sidebar__type-option-icon">
          <SVGIcon
            icon={fieldTypeToIconMap[restProps.data.value]}
            size={restProps.data.iconSize}
            color="#8F9199"
            backgroundIcon="#3d3d3d"
          />
        </div>
        {children}
      </div>
    </components.Option>
  );
};

export default Option;
