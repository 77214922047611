import React from 'react';

import Avatar, { NamedAvatar } from '@uikit/Avatar/Avatar';
import { SVGIcon } from '@uikit/Icon/Icon';

import './ProfileAvatar.scss';

type ProfileAvatarProps = {
  avatarUrl: string;
  firstName: string;
  lastName: string;
  onChangeAvatar: React.ChangeEventHandler<HTMLInputElement>;
};

const ProfileAvatar = ({
  avatarUrl,
  firstName,
  lastName,
  onChangeAvatar,
}: ProfileAvatarProps): JSX.Element => {
  const fullName = `${firstName} ${lastName}`;

  return (
    <div className="profile-avatar">
      {avatarUrl ? (
        <Avatar
          wrapperClassName="profile-avatar__wrapper"
          className="profile-avatar__image"
          url={avatarUrl}
          fullName={fullName}
        />
      ) : (
        <NamedAvatar className="profile-avatar__image" userName={fullName} />
      )}
      <div className="profile-avatar__edit-svg">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className="profile-avatar__edit-svg-label" htmlFor="input-file-field">
          <SVGIcon icon="editProfileImagePenIcon" />
        </label>
        <input
          onChange={onChangeAvatar}
          id="input-file-field"
          className="profile-avatar__input-field"
          type="file"
          accept="image/png, image/jpg, image/jpeg"
        />
      </div>
    </div>
  );
};

export default ProfileAvatar;
