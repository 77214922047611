import { cardDetailsDefault } from '@constants/payments';

export default (additionalProps: { [key: string]: any }) => ({
  ...additionalProps,
  styles: {
    input: {
      color: '#282c37',
      'font-size': '16px',
      transition: 'color 0.1s',
      'line-height': '3',
      'padding-left': '10px',
      height: '72px',
    },
    // Style the text of an invalid input
    'input.invalid': {
      color: '#E53A40',
    },
    // placeholder styles need to be individually adjusted
    '::-webkit-input-placeholder': {
      color: 'rgba(0,0,0,0.6)',
    },
    ':-moz-placeholder': {
      color: 'rgba(0,0,0,0.6)',
    },
    '::-moz-placeholder': {
      color: 'rgba(0,0,0,0.6)',
    },
    ':-ms-input-placeholder': {
      color: 'rgba(0,0,0,0.6)',
    },
    // prevent IE 11 and Edge from
    // displaying the clear button
    // over the card brand icon
    'input::-ms-clear': {
      opacity: '0',
    },
  },
  // Add information for individual fields
  fields: {
    number: {
      selector: '#card-number',
      placeholder: cardDetailsDefault.CARD_NUMBER,
    },
    cvv: {
      selector: '#cvv',
      placeholder: cardDetailsDefault.CVV,
    },
    expirationDate: {
      selector: '#expiration-date',
      placeholder: cardDetailsDefault.EXPIRATION,
    },
  },
});
