import { FilterProps } from '@ts/props.types';
import { CampaignFilterField } from 'respona_api/generated/campaign_pb';

export const getCampaignFilterFields = (
  members: { value: string | number; label: string }[],
  folders: { value: string | number; label: string }[]
): FilterProps[] => [
  {
    label: 'Campaign name',
    value: CampaignFilterField.STRING_CAMPAIGN_TITLE,
    type: 'stringNotEmpty',
  },
  {
    label: 'Folder',
    value: CampaignFilterField.DROPDOWN_CAMPAIGN_FOLDER_ID,
    type: 'enumString',
    inputOptions: folders,
  },
  {
    label: 'Status',
    value: CampaignFilterField.DROPDOWN_CAMPAIGN_STATUS,
    type: 'enumString',
    inputOptions: [
      // { value: 'ALL', label: 'All' },
      { value: 'ONLY_LAUNCHED', label: 'In Progress' },
      { value: 'ONLY_DRAFT', label: 'Draft' },
      { value: 'ONLY_PAUSED', label: 'Paused' },
      { value: 'ONLY_FINISHED', label: 'Completed' },
    ],
  },
  {
    label: 'Owner',
    value: CampaignFilterField.DROPDOWN_CAMPAIGN_OWNER_ID,
    type: 'enumString',
    inputOptions: members,
  },
  {
    label: 'Created on',
    value: CampaignFilterField.DATE_CAMPAIGN_CREATED_AT,
    type: 'date',
  },
  // {
  //   label: 'Total opportunities',
  //   value: 10,
  //   type: 'number',
  // },
  {
    label: 'Launched opportunities',
    value: CampaignFilterField.NUMBER_CAMPAIGN_LAUNCHED,
    type: 'number',
  },
  {
    label: 'Scheduled opportunities',
    value: CampaignFilterField.NUMBER_CAMPAIGN_SCHEDULED,
    type: 'number',
  },
  {
    label: 'Contacted opportunities',
    value: CampaignFilterField.NUMBER_CAMPAIGN_CONTACTED,
    type: 'number',
  },
  {
    label: 'Opened opportunities',
    value: CampaignFilterField.NUMBER_CAMPAIGN_OPENED,
    type: 'number',
  },
  {
    label: 'Replied opportunities',
    value: CampaignFilterField.NUMBER_CAMPAIGN_REPLIED,
    type: 'number',
  },
  {
    label: 'Total opportunities',
    value: CampaignFilterField.NUMBER_CAMPAIGN_TOTAL_OPPORTUNITIES,
    type: 'number',
  },
];
