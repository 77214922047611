import { handleActions } from 'redux-actions';

import { setAudioGenres, setAudioGenresLs } from '@redux/actions/commonInfo.actions';

import { AudioGenreType } from '@ts/common.types';
import loadingStatuses from '@constants/loadingStatuses';

export type CommonInfoStateType = {
  audioGenres: {
    data: AudioGenreType[];
    loadingStatus: string;
  };
};

export const defaultState: CommonInfoStateType = {
  audioGenres: {
    data: [],
    loadingStatus: loadingStatuses.PENDING,
  },
};

export default handleActions(
  {
    [setAudioGenres]: (state: CommonInfoStateType, { payload }): CommonInfoStateType => ({
      ...state,
      audioGenres: {
        ...state.audioGenres,
        data: payload,
      },
    }),
    [setAudioGenresLs]: (state: CommonInfoStateType, { payload }): CommonInfoStateType => ({
      ...state,
      audioGenres: {
        ...state.audioGenres,
        loadingStatus: payload,
      },
    }),
  },
  defaultState
);
