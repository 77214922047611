import React from 'react';

export default ({ color = '#BDBDBD' }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.0833 16H18.0833"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.0833 8H10.0833V13H18.0833V8Z"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.83333 21.0832V21.0832C2.82459 21.0832 2.01044 20.2586 2.02321 19.25L2.08333 14.5"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.83325 21.0832C4.31904 21.0817 5.66659 20.5446 5.66659 17V5.99802C5.66659 4.34116 7.00973 3 8.66659 3H19.1666C20.8234 3 22.1666 4.34312 22.1666 5.99998V17.0857C22.1666 19.2948 20.3757 21.0832 18.1666 21.0832H3.83325Z"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
