import braintree from 'braintree-web';
import braintreeHostedFields from '@components/UseCardToPay/_components/UseNewCard/braintreeHostedFields';
import { RefObject } from 'react';

let deviceData = '';

type BraintreeWidgetType = {
  token: string;
  submitBtnRef: RefObject<HTMLButtonElement>;
  buttonClickCallback: (braintreeNonce: string, braintreeDeviceData: string) => void;
  disableButtonHandler: (value: boolean) => void;
  isButtonInitiallyDisabled: boolean;
};

export const setupBraintree = ({
  token,
  submitBtnRef,
  buttonClickCallback,
  disableButtonHandler,
  isButtonInitiallyDisabled,
}: BraintreeWidgetType): Promise<unknown> => {
  const result = braintree.client.create(
    {
      authorization: token,
    },
    (err, clientInstance) => {
      if (err) {
        console.error(err);
        return;
      }

      braintree.hostedFields.create(
        braintreeHostedFields({ client: clientInstance }),
        (instanceError, hostedFieldsInstance) => {
          if (instanceError) {
            console.error(instanceError);
            return;
          }

          braintree.dataCollector.create(
            {
              client: clientInstance,
              paypal: false,
            },
            (dataCollectorErr, dataCollectorInstance) => {
              if (dataCollectorErr) {
                console.log(dataCollectorErr);
                return;
              }
              deviceData = dataCollectorInstance.deviceData;
            }
          );

          hostedFieldsInstance.on('empty', function (event) {
            document.getElementById('card-image').className = '';
          });

          hostedFieldsInstance.on('blur', function (event) {
            const isValid =
              event.fields.cvv.isValid &&
              event.fields.expirationDate.isValid &&
              event.fields.number.isValid;
            if (isValid) {
              disableButtonHandler(false);
            } else if (!isValid && isButtonInitiallyDisabled) {
              disableButtonHandler(true);
            }
          });

          hostedFieldsInstance.on('cardTypeChange', function (event) {
            if (event.cards.length === 1) {
              document.getElementById('card-image').className = `${event.cards[0]?.type}-md`;
            }
            const isValid =
              event.fields.cvv.isValid &&
              event.fields.expirationDate.isValid &&
              event.fields.number.isValid;
            if (isValid) {
              disableButtonHandler(false);
            } else if (!isValid && isButtonInitiallyDisabled) {
              disableButtonHandler(true);
            }
          });

          if (submitBtnRef.current) {
            // @ts-ignore
            submitBtnRef.current.addEventListener(
              'click',
              (event) => {
                event.preventDefault();

                hostedFieldsInstance.tokenize((tokenizeErr, payload) => {
                  if (tokenizeErr) {
                    console.error(tokenizeErr);
                    return;
                  }

                  buttonClickCallback(payload.nonce, deviceData);
                });
              },
              false
            );
          }
        }
      );
    }
  );
  return Promise.resolve(result);
};
