import React from 'react';

export default () => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 7.48936L8.44762 7.49105L13.5843 7.49192V2.40302L0 7.48936Z" fill="#FF9D28" />
    <path d="M13.584 2.40297V20.3694L19.9996 0L13.584 2.40297Z" fill="#68C5ED" />
    <path d="M13.584 7.49184L8.44727 7.49097L13.584 20.3693V7.49184Z" fill="#044D80" />
    <path d="M0 7.48932L9.76488 10.7954L8.44762 7.49101L0 7.48932Z" fill="#E54747" />
  </svg>
);
