// @ts-nocheck
import React, { useEffect, useRef } from 'react';
import { ReactEditor, useFocused, useSelected } from 'slate-react';
import { Transforms } from 'slate';
import cn from 'class-names';
import debounce from '@utils/debounce';

import './ImageNode.scss';

const Image = ({ attributes, children, element, editor }): JSX.Element => {
  const imageWrapperRef = useRef<HTMLDivElement>(null);
  const resizerRef = useRef<HTMLDivElement>();
  const selected = useSelected();
  const focused = useFocused();

  const cnImage = cn('slate-image', {
    'slate-image--focused': focused || selected,
  });

  const cnImageWrapper = cn('slate-image-wrapper', {
    'slate-image-wrapper--focused': focused || selected,
  });

  const handleImageResize = debounce(() => {
    const path = ReactEditor.findPath(editor, element);
    const newProperties = {
      width: imageWrapperRef?.current?.width,
    };
    Transforms.setNodes(editor, newProperties, { at: path });
  }, 100);

  useEffect(() => {
    const handleDocumentMouseUp = () => {
      document.removeEventListener('mousemove', resize);
      document.removeEventListener('mouseup', handleDocumentMouseUp);
      handleImageResize();
    };

    const handleResizeMouseDown = () => {
      document.addEventListener('mousemove', resize);
      document.addEventListener('mouseup', handleDocumentMouseUp);
    };

    if (imageWrapperRef?.current && resizerRef?.current) {
      resizerRef.current.addEventListener('mousedown', handleResizeMouseDown);
    }

    return () => {
      resizerRef.current?.removeEventListener('mousedown', handleResizeMouseDown);
      document.removeEventListener('mouseup', handleDocumentMouseUp);
    };
  }, [imageWrapperRef, resizerRef]);

  const resize = (e) => {
    imageWrapperRef.current.style.width = `${
      e.pageX - imageWrapperRef.current.getBoundingClientRect().left
    }px`;
  };

  return (
    <div {...attributes}>
      <div contentEditable={false} className={cnImageWrapper}>
        <img
          ref={imageWrapperRef}
          className={cnImage}
          draggable={false}
          src={element.url}
          width={element.width > 500 ? 500 : element.width}
        />
        <div ref={resizerRef} className="slate-image-wrapper__resizer" />
      </div>
      {children}
    </div>
  );
};

export default Image;