import React from 'react';

import Button from '@uikit/Button/Button';

export default (): JSX.Element => {
  return (
    <Button size="m" className="button--additional">
      Skip
    </Button>
  );
};
