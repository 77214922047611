import React from 'react';
import { nanoid } from 'nanoid';

export default ({
  color = '#221CB6',
  size = 16,
}: {
  color?: string;
  size?: number;
}): JSX.Element => {
  const id = nanoid();
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.92606 2.78271H2.56087C2.25111 2.78271 2 3.03382 2 3.34358V6.70877C2 7.01853 2.25111 7.26964 2.56087 7.26964H5.92606C6.23582 7.26964 6.48692 7.01853 6.48692 6.70877V3.34358C6.48692 3.03382 6.23582 2.78271 5.92606 2.78271Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M10.5949 2.37848L8.32561 4.64777C8.11666 4.85673 8.11666 5.19552 8.32561 5.40447L10.5949 7.67377C10.8039 7.88273 11.1427 7.88273 11.3516 7.67377L13.6209 5.40447C13.8299 5.19552 13.8299 4.85673 13.6209 4.64777L11.3516 2.37848C11.1427 2.16952 10.8039 2.16952 10.5949 2.37848Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M12.6565 9.51318H9.29133C8.98158 9.51318 8.73047 9.76429 8.73047 10.074V13.4392C8.73047 13.749 8.98158 14.0001 9.29133 14.0001H12.6565C12.9663 14.0001 13.2174 13.749 13.2174 13.4392V10.074C13.2174 9.76429 12.9663 9.51318 12.6565 9.51318Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M5.92606 9.51318H2.56087C2.25111 9.51318 2 9.76429 2 10.074V13.4392C2 13.749 2.25111 14.0001 2.56087 14.0001H5.92606C6.23582 14.0001 6.48692 13.749 6.48692 13.4392V10.074C6.48692 9.76429 6.23582 9.51318 5.92606 9.51318Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};
