import React, { useEffect, useState } from 'react';

import Input from '@uikit/Input/Input';

export default (label = '', key = '', placeholder = '') => ({
  type: 'text',
  label,
  key,
  Renderer: (props) => {
    const [value, setValue] = useState(props.value);

    useEffect(() => {
      setValue(props.value);
    }, [props.value]);

    return (
      <Input
        type="text"
        placeholder={placeholder}
        inputRef={props.inputRef}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() => props.onChange({ target: { value } })}
        onKeyDown={(e) => (e.key === 'Enter' ? props.inputRef?.current?.blur() : null)}
        maxLength={40}
        disabled={props.isDisabled}
      />
    );
  },
});
