import React from 'react';

const VerifyiedOpportunityIcon = ({ color = '#fff' }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7483 1H5.25081C2.63805 1 1 2.84995 1 5.46789V12.5321C1 15.1501 2.63027 17 5.25081 17H12.7475C15.3689 17 17 15.1501 17 12.5321V5.46789C17 2.84995 15.3689 1 12.7483 1Z"
      stroke="#23E771"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.91992 9.00154L7.97311 11.0539L12.0778 6.94922"
      stroke="#23E771"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default VerifyiedOpportunityIcon;
