import AutomationContactsService from '../../../respona_api/generated/automation-contact_pb_service';

const service = (function () {
  let instance = null;

  return {
    getInstance: (): AutomationContactsService.ContactAutomationServiceClient => {
      if (!instance) {
        // @ts-ignore
        instance = new AutomationContactsService.ContactAutomationServiceClient(API_HOST);
      }

      return instance;
    },
  };
})();

export default service;
