import React from 'react';

export default () => (
  <svg width="31" height="29" viewBox="0 0 31 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 15.119H3.5671"
      stroke="#FC8E0E"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.13477 5.13574L6.9497 6.95068"
      stroke="#FC8E0E"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.1191 1V3.5671"
      stroke="#FC8E0E"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.103 5.13574L23.2881 6.95068"
      stroke="#FC8E0E"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.238 15.119H26.6709"
      stroke="#FC8E0E"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.8206 15.1191C22.8206 10.8654 19.3729 7.41778 15.1193 7.41778C10.8656 7.41778 7.41797 10.8654 7.41797 15.1191C7.41797 18.4704 9.56278 21.3148 12.5522 22.3724V27.9546H17.6864V22.3724C20.6758 21.3148 22.8206 18.4704 22.8206 15.1191Z"
      stroke="#FC8E0E"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
