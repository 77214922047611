import React, { useEffect } from 'react';

import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@uikit/Button/Button';

import LinkedSteps from '@uikit/LinkedSteps/LinkedSteps';
import OnboardingTutorial from '@components/Onboarding/OnboardingTutorial/OnboardingTutorial';
import OnboardingOnboarding from '@components/Onboarding/OnboardingOnboarding/OnboardingOnboarding';
import OnboardingMembers from '@components/Onboarding/OnboardingMembers/OnboardingMembers';
import OnboardingPayment from '@components/Onboarding/OnboardingPayment/OnboardingPayment';
import ResponaNewLogo from '@uikit/ResponaNewLogo/ResponaNewLogo';
import Loader from '@uikit/Loader/Loader';

import { userProfileSelector } from '@redux/selectors/userProfile.selector';
import { fetchUserProfile } from '@redux/thunks/userProfileRequests';

import { authService } from 'src/services/authService';

import loadingStatuses from '@constants/loadingStatuses';
import { onboardingStepsUrls } from '@mappers/common.mappers';

import { getLinksConfig } from './getLinksConfig';

import './OnboardingPage.scss';

const OnboardingPage = (): JSX.Element => {
  const { info: userProfile, loadingStatus: userProfileLs } = useSelector(userProfileSelector);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (userProfileLs === loadingStatuses.PENDING) {
      fetchUserProfile(dispatch);
    }
  }, []);

  useEffect(() => {
    if (userProfile.authStep === 0) {
      return;
    }
    history.push(onboardingStepsUrls[userProfile.authStep]);
  }, [userProfile.authStep]);

  const handleLogout = () => authService.logout();

  return (
    <div>
      <Loader lsDeps={[userProfileLs]} withTopMargin>
        <div className="onboarding-page__header">
          <div className="onboarding-page__logo">
            <ResponaNewLogo size="big" />
          </div>
          <LinkedSteps
            stepsConfig={getLinksConfig(userProfile.authStep)}
            authStep={userProfile.authStep}
            isClickable={userProfile.authStep > 1}
          />
          <Button
            className="onboarding-page__header-signout-button"
            type="ghost"
            onClick={handleLogout}
          >
            Sign out
          </Button>
        </div>

        <Switch>
          <Route path="/onboarding/payment">
            <OnboardingPayment />
          </Route>
          <Route path="/onboarding/members">
            <OnboardingMembers />
          </Route>
          <Route path="/onboarding/onboarding">
            <OnboardingOnboarding userProfile={userProfile} />
          </Route>
          <Route path="/onboarding/tutorial">
            <OnboardingTutorial />
          </Route>
          <Route path="/onboarding*">
            <Redirect to="/onboarding/payment" />
          </Route>
        </Switch>
      </Loader>
    </div>
  );
};

export default OnboardingPage;
