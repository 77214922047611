import React from 'react';

import {
  ExternalUnsubscribePageGradientLeft,
  ExternalUnsubscribePageGradientRight,
} from '@uikit/ExternalUnsubscribePageGradients/ExternalUnsubscribePageGradients';

import 'src/assets/fonts/OakesGrotesk.css';
import './UnsubscribeConfirmationPage.scss';
// @ts-ignore
import responaLogo from 'src/assets/ResponaLogoLarge.svg';

const UnsubscribeConfirmationPage = (): JSX.Element => {
  const email = false;

  return (
    <div className="unsubscribed-page">
      <a href="https://respona.com/" className="unsubscribed-page__logo-container">
        <img alt="Respona" src={responaLogo} className="unsubscribed-page__logo" />
      </a>

      <ExternalUnsubscribePageGradientLeft />
      <ExternalUnsubscribePageGradientRight />

      <div className="unsubscribed-page__content">
        <div className="unsubscribed-page__message">
          <h1 className="unsubscribed-page__title">You're unsubscribed</h1>
          {email ? (
            <p className="unsubscribed-page__sub-title">
              Your email{' '}
              <span className="unsubscribed-page__sub-title-highlight">farzad@respona.</span> has
              been removed from this list.
            </p>
          ) : (
            <p className="unsubscribed-page__sub-title">
              You have been successfully unsubscribed from this list.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default UnsubscribeConfirmationPage;
