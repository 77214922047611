import React from 'react';

export default ({ color = '#8F9199', size = 13 }: { color?: string; size?: number }) => (
  <svg width={size} height={size} viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 2.5V1H11V2.5"
      stroke={color}
      strokeWidth="1.4"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M6 1V12"
      stroke={color}
      strokeWidth="1.4"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M3.5 12.0005H8.5"
      stroke={color}
      strokeWidth="1.4"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);
