import React, { useState } from 'react';

import ReactTooltip from 'react-tooltip';

import { SVGIcon } from '@uikit/Icon/Icon';
import Input from '@uikit/Input/Input';
import checkIsEmail from '@utils/checkIsEmail';
import Loader from '@uikit/Loader/Loader';
import EmailAccuracy from '@uikit/EmailAccuracy/EmailAccuracy';

import Display from '@components/Display/Display';

import './EditorEmailMeta.scss';
import { OpportunityPersonContactType } from '@ts/contact.types';

type EditorEmailMetaPropsType = {
  onUpdateMeta: (any) => void;
  step: any;
  contacts: OpportunityPersonContactType[];
  selectedEmail: string;
  onChangeContact: (contact: OpportunityPersonContactType) => void;
  senderEmails?: string[];
  isSenderEmailLoading?: boolean;
  selectedSenderEmail?: string;
  onChangeSenderEmail?: (email: string) => void;
  additionalClassName?: string;
};

const MetaInfoValue = ({ value, onRemove }: { value: string; onRemove?: (string) => void }) => {
  const handleRemove = () => onRemove(value);

  return (
    <span key={value} className="editor-email-meta__value">
      {value}
      {onRemove ? (
        <span
          className="editor-email-meta__remove-icon"
          onClick={handleRemove}
          tabIndex={0}
          role="button"
        >
          <SVGIcon icon="crossDelete" />
        </span>
      ) : null}
    </span>
  );
};

const EditorEmailMeta = ({
  onUpdateMeta,
  step,
  contacts,
  selectedEmail,
  onChangeContact,
  senderEmails,
  isSenderEmailLoading,
  selectedSenderEmail,
  onChangeSenderEmail,
  additionalClassName,
}: EditorEmailMetaPropsType): JSX.Element => {
  const [isBccFieldShows, changeIsBccFieldShows] = useState(false);
  const [isCcFieldShows, changeIsCcFieldShows] = useState(false);
  const [newCcValue, changeNewCcValue] = useState('');
  const [newBccValue, changeNewBccValue] = useState('');
  const [newCcError, changeNewCcError] = useState(false);
  const [newBccError, changeNewBccError] = useState(false);

  if (!selectedEmail) {
    return null;
  }

  const createRemoveMetaValueHandler = (metaField) => (value) => {
    onUpdateMeta({
      ...step,
      [metaField]: step[metaField].filter((metaValue) => metaValue !== value),
    });
  };

  const handleShowBccField = () => changeIsBccFieldShows(true);
  const handleShowCcField = () => changeIsCcFieldShows(true);

  const handleChangeNewCcValue = ({ target: { value } }) => {
    changeNewCcError(false);
    changeNewCcValue(value);
  };
  const handleChangeNewBccValue = ({ target: { value } }) => {
    changeNewBccError(false);
    changeNewBccValue(value);
  };

  const handleApplyNewCc = () => {
    if (checkIsEmail(newCcValue)) {
      onUpdateMeta({
        ...step,
        emailsToCcList: [...step.emailsToCcList, newCcValue],
      });

      changeNewCcValue('');
    } else {
      changeNewCcError(true);
    }
  };

  const handleApplyNewBcc = () => {
    if (checkIsEmail(newBccValue)) {
      onUpdateMeta({
        ...step,
        emailsToBccList: [...step.emailsToBccList, newBccValue],
      });

      changeNewBccValue('');
    } else {
      changeNewBccError(true);
    }
  };

  const handleAddNewCc = (e) => {
    if (e.keyCode === 13) {
      handleApplyNewCc();
    }
  };

  const handleAddNewBcc = (e) => {
    if (e.keyCode === 13) {
      handleApplyNewBcc();
    }
  };

  return (
    <div className={`editor-email-meta ${additionalClassName}`}>
      {!!senderEmails?.length && (
        <div className="editor-email-meta__row">
          <span className="editor-email-meta__label">From:</span>
          {isSenderEmailLoading ? (
            <Loader isLoading />
          ) : (
            <>
              <MetaInfoValue value={selectedSenderEmail || senderEmails[0]} />
              <Display isVisible={senderEmails.length > 1}>
                <span
                  className="editor-email-meta__chevron"
                  data-for="editor-sender-meta-chevron"
                  data-tip=""
                  data-event="click focus"
                >
                  <SVGIcon icon="chevron" />
                </span>
                <ReactTooltip
                  id="editor-sender-meta-chevron"
                  className="react-tooltip"
                  place="bottom"
                  effect="float"
                  event="click"
                  globalEventOff="click"
                  arrowColor="transparent"
                  clickable
                >
                  <div className="editor-email-meta__contacts editor-email-meta-contacts">
                    {senderEmails.map((email, index) => (
                      <div
                        key={email + index}
                        onClick={
                          onChangeSenderEmail ? () => onChangeSenderEmail(email) : () => null
                        }
                        className="editor-email-meta-contacts__contact"
                        tabIndex={0}
                        role="button"
                      >
                        {email}
                      </div>
                    ))}
                  </div>
                </ReactTooltip>
              </Display>
            </>
          )}
        </div>
      )}
      <div className="editor-email-meta__row">
        <span className="editor-email-meta__label">To:</span>
        <MetaInfoValue key={selectedEmail} value={selectedEmail} />
        <Display isVisible={contacts.length > 1}>
          <span
            className="editor-email-meta__chevron"
            data-for="editor-email-meta-chevron"
            data-tip=""
            data-event="click focus"
          >
            <SVGIcon icon="chevron" />
          </span>
          <ReactTooltip
            id="editor-email-meta-chevron"
            className="react-tooltip"
            place="bottom"
            effect="float"
            event="click"
            globalEventOff="click"
            arrowColor="transparent"
            clickable
          >
            <div className="editor-email-meta__contacts editor-email-meta-contacts">
              {contacts.map((contact) => (
                <div
                  key={contact.contactEmail.email}
                  onClick={() => onChangeContact(contact)}
                  className="editor-email-meta-contacts__contact"
                  tabIndex={0}
                  role="button"
                >
                  <EmailAccuracy
                    accuracy={contact.contactEmail.accuracy}
                    order={contact.contactOrder}
                  />
                  {contact.contactEmail.email}
                </div>
              ))}
            </div>
          </ReactTooltip>
        </Display>
      </div>
      <Display isVisible={isCcFieldShows || step.emailsToCcList?.length > 0}>
        <div className="editor-email-meta__row">
          <span className="editor-email-meta__label">CC:</span>
          {step.emailsToCcList?.map((email) => (
            <MetaInfoValue
              key={email}
              value={email}
              onRemove={createRemoveMetaValueHandler('emailsToCcList')}
            />
          ))}
          <Input
            placeholder="new cc..."
            onKeyDown={handleAddNewCc}
            value={newCcValue}
            isTransparent
            onChange={handleChangeNewCcValue}
            isError={newCcError}
            onBlur={handleApplyNewCc}
          />
        </div>
      </Display>
      <Display isVisible={isBccFieldShows || step.emailsToBccList?.length > 0}>
        <div className="editor-email-meta__row">
          <span className="editor-email-meta__label">BCC:</span>
          {step.emailsToBccList?.map((email) => (
            <MetaInfoValue
              key={email}
              value={email}
              onRemove={createRemoveMetaValueHandler('emailsToBccList')}
            />
          ))}
          <Input
            placeholder="new bcc..."
            onKeyDown={handleAddNewBcc}
            value={newBccValue}
            isTransparent
            onChange={handleChangeNewBccValue}
            isError={newBccError}
            onBlur={handleAddNewBcc}
          />
        </div>
      </Display>
      <div className="editor-email-meta__row-buttons">
        <Display isVisible={!isBccFieldShows && step.emailsToBccList?.length === 0}>
          <div
            className="editor-email-meta__adding-button"
            onClick={handleShowBccField}
            tabIndex={0}
            role="button"
          >
            + BCC
          </div>
        </Display>
        <Display isVisible={!isCcFieldShows && step.emailsToCcList?.length === 0}>
          <div
            className="editor-email-meta__adding-button"
            onClick={handleShowCcField}
            tabIndex={0}
            role="button"
          >
            + CC
          </div>
        </Display>
      </div>
    </div>
  );
};

export default EditorEmailMeta;
