import React from 'react';

const ShieldIcon = ({ color = '#8F9199' }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 15C8 15 1 12.2 1 3.1L8 1L15 3.1C15 12.2 8 15 8 15Z"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M6.00073 7.40012L7.40073 8.80012L10.2007 6.00012"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);

export default ShieldIcon;
