import { Descendant, Text } from 'slate';

const serializableItem = (node) => {
  if (Text.isText(node)) {
    let string = node.text;
    switch (true) {
      // @ts-ignore
      case !!node.bold:
        string = `<strong>${string}</strong>`;
        break;
      // @ts-ignore
      case !!node.italic:
        string = `<em>${string}</em>`;
        break;
      // @ts-ignore
      case !!node.underline:
        string = `<u>${string}</u>`;
        break;
      default:
        string = `<span>${string}</span>`;
    }
    // @ts-ignore
    return string;
  }

  const children = node.children.map((n) => serializableItem(n)).join('');
  switch (node.type) {
    case 'quote':
      return `<blockquote><p>${children}</p></blockquote>`;
    case 'paragraph':
      return `<p>${children}</p>`;
    case 'variable':
      // eslint-disable-next-line no-case-declarations
      const href = node.href || '';

      return `<span class="variable" data-fallback="${node.fallback}" data-href="${href}">${node.character}</span>`;
    case 'ai-variable':
      return `<span class="ai-variable">${node.character}</span>`;
    case 'temp-variable':
      // eslint-disable-next-line no-case-declarations
      const link = node.href || '';

      if (!!link) {
        return `<a class="temp-variable" href="${link}">${node.character}</a>`;
      }

      return `<span class="temp-variable" data-href="${link}">${node.character}</span>`;
    case 'link':
      return `<a class="link" href="${node.url}">${children}</a>`;
    case 'list-item':
      return `<li>${children}</li>`;
    case 'numbered-list':
      return `<ol>${children}</ol>`;
    case 'bulleted-list':
      return `<ul>${children}</ul>`;
    case 'text-alignment-right':
      return `<p style="text-align: right">${children}</p>`;
    case 'text-alignment-left':
      return `<p style="text-align: left">${children}</p>`;
    case 'text-alignment-center':
      return `<p style="text-align: center">${children}</p>`;
    case 'image':
      return `<img width="${node.width}" src="${node.url}" />`;
    default:
      return children;
  }
};

export const serialize = (nodes: Descendant[]): string => {
  return nodes.map(serializableItem).join('');
};
