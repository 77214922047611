import React from 'react';

export default ({
  color = '#ffffff',
  size = 16,
}: {
  color?: string;
  size?;
  number;
}): JSX.Element => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.94217 7.05697C9.72322 7.83802 10.9895 7.83802 11.7706 7.05697C12.5516 6.27592 12.5516 5.00959 11.7706 4.22854C10.9895 3.44749 9.72322 3.44749 8.94217 4.22854C8.16112 5.00959 8.16112 6.27592 8.94217 7.05697ZM7.99936 7.99978C9.30111 9.30153 11.4117 9.30153 12.7134 7.99978C14.0152 6.69803 14.0152 4.58748 12.7134 3.28573C11.4117 1.98399 9.30111 1.98399 7.99936 3.28573C6.69761 4.58748 6.69761 6.69803 7.99936 7.99978Z"
      fill={color}
    />
    <path
      d="M7.99936 7.05697L8.94217 7.99978L3.75672 13.1852L3.28532 12.7138C3.02497 12.4535 3.02497 12.0314 3.28532 11.771L7.99936 7.05697Z"
      fill={color}
    />
    <path
      d="M5.17093 9.8854C5.43128 9.62505 5.85339 9.62505 6.11374 9.8854L7.52796 11.2996C7.78831 11.56 7.78831 11.9821 7.52796 12.2424C7.26761 12.5028 6.8455 12.5028 6.58515 12.2424L5.17093 10.8282C4.91058 10.5679 4.91059 10.1457 5.17093 9.8854Z"
      fill={color}
    />
    <path
      d="M3.28532 11.771C3.54567 11.5107 3.96778 11.5107 4.22813 11.771L5.64234 13.1852C5.90269 13.4456 5.90269 13.8677 5.64234 14.128C5.38199 14.3884 4.95988 14.3884 4.69953 14.128L3.28532 12.7138C3.02497 12.4535 3.02497 12.0314 3.28532 11.771Z"
      fill={color}
    />
  </svg>
);
