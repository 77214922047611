import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import SequenceSteps from '@components/CampaignSettings/SequenceSteps/SequenceSteps';
import { TemplateStepTransformedType } from '@ts/template.types';

import AnalyticSequenceEditor from '@components/CampaignAnalytics/AnalyticSequenceEditor/AnalyticSequenceEditor';
import { updateTemplateStepAndFutureQueues } from '@redux/thunks/campaignAnalytics.thunks';

import './Sequence.scss';
import useReferralLink from '@hooks/useReferralLink';

function Sequence({
  stepsList,
  enabledUnsubscribeContent,
  enabledReferralContent,
  templateId,
  RenderHeader,
  openCampaign,
}: {
  stepsList: TemplateStepTransformedType[];
  enabledUnsubscribeContent: boolean;
  enabledReferralContent: boolean;
  templateId: number;
  RenderHeader: (any) => JSX.Element;
  openCampaign: any;
}): JSX.Element {
  const dispatch = useDispatch();
  const [openedIndex, changeOpenedIndex] = useState(0);
  const { item: referralContentData } = useReferralLink();

  const handleUpdateStep = (step) => {
    // eslint-disable-next-line no-restricted-globals
    const confirmResult = confirm('Are you sure in updating?');
    if (confirmResult) {
      return dispatch(updateTemplateStepAndFutureQueues(step, templateId));
    }
  };

  useEffect(() => {
    if (!stepsList[openedIndex]) {
      changeOpenedIndex(0);
    }
  }, [stepsList, openedIndex]);

  return (
    <div style={{ overflowX: 'hidden', height: '100%' }}>
      <RenderHeader />
      <div className="campaign-analytics-sequence">
        <div className="campaign-analytics-sequence__steps" style={{ width: '300px' }}>
          <SequenceSteps
            sequenceSteps={stepsList}
            openedStepIndex={openedIndex}
            onOpenStep={changeOpenedIndex}
          />
        </div>
        <div className="campaign-analytics-sequence__editor" style={{ flex: 1 }}>
          <AnalyticSequenceEditor
            key={openedIndex}
            step={stepsList[openedIndex]}
            stepIndex={openedIndex}
            enabledUnsubscribeContent={enabledUnsubscribeContent}
            referralContent={enabledReferralContent ? referralContentData : null}
            openCampaign={openCampaign}
            onSave={handleUpdateStep}
          />
        </div>
      </div>
    </div>
  );
}

export default Sequence;
