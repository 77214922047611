import React from 'react';

export default ({ color = '#8F9199' }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.59676 3.25994C4.23253 3.52895 3.9617 3.96989 3.9617 4.77012V15.147L8.30432 12.853C8.72545 12.6336 9.23288 12.6336 9.65401 12.853L9.65648 12.8543L14.0384 15.1549V4.63584C14.0384 3.90199 13.7724 3.49495 13.4015 3.23851C12.984 2.94982 12.3184 2.77541 11.4127 2.77541H6.58739C5.65542 2.77541 5.00098 2.96139 4.59676 3.25994ZM3.19643 15.6738L2.43835 16.0567C2.31894 15.8369 2.25452 15.5932 2.25024 15.3451L2.25 15.3314L2.25012 4.77012C2.25012 3.55612 2.69299 2.58711 3.55599 1.94972C4.37899 1.34186 5.46529 1.125 6.58739 1.125H11.4127C12.4985 1.125 13.5736 1.32658 14.399 1.89729C15.271 2.50025 15.75 3.43603 15.75 4.63584V15.3314C15.75 15.7408 15.5813 16.1334 15.2811 16.4229C14.9809 16.7124 14.5737 16.875 14.1492 16.875H14.1356C13.8783 16.8711 13.6254 16.81 13.3964 16.6967L13.3779 16.6873L8.97961 14.3781L4.61027 16.6862L4.59374 16.6947C3.82353 17.0807 2.87463 16.8075 2.45002 16.0775L3.19643 15.6738Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.34863 6.75077C5.34863 6.29502 5.73178 5.92557 6.20442 5.92557H11.7539C12.2265 5.92557 12.6097 6.29502 12.6097 6.75077C12.6097 7.20652 12.2265 7.57598 11.7539 7.57598H6.20442C5.73178 7.57598 5.34863 7.20652 5.34863 6.75077Z"
      fill={color}
    />
  </svg>
);
