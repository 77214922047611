import React from 'react';

import HeaderSearchInput from '@components/HeaderSearchInput/HeaderSearchInput';

export default ({
  searchString = '',
  changeSearchString,
  isFilterUpdatePage = false,
  isFiltersPage = false,
  children = null,
}) => (
  <>
    <HeaderSearchInput
      onUpdateSearchQuery={changeSearchString}
      searchQuery={searchString}
      isFiltersPage={isFiltersPage || isFilterUpdatePage}
    />
    {children}
  </>
);
