import { BoundaryType, ParsedValueType, ParseInputsType } from '@ts/moderateChance.types';

const boundaries: BoundaryType[] = [
  {
    min: 0,
    max: 9,
    color: 'red',
  },
  {
    min: 10,
    max: 19,
    color: 'yellow',
  },
  {
    min: 20,
    max: 85,
    color: 'green',
  },
  {
    min: 86,
    max: 125,
    color: 'yellow',
  },
  {
    min: 126,
    color: 'red',
  },
];

export default {
  key: 'wordsCount',
  label: 'Body length (words)',
  min: boundaries[0].min,
  max: boundaries[boundaries.length - 1].min,
  greenZone: {
    min: 20,
    max: 85,
  },
  parse: ({ text }: ParseInputsType): ParsedValueType => {
    // We split by space and to get only words -> filter white spaces,
    // But one thing! we count punctuation like a word if it stands alone
    // Example: "very , cool" -> it is 3 words
    const wordsCount = text.split(' ').filter((letter) => letter !== '').length;

    return {
      value: wordsCount,
      boundary: boundaries.find(({ min, max }) => {
        if (!max && wordsCount >= max) {
          return true;
        }

        if (min <= wordsCount && wordsCount <= max) {
          return true;
        }

        return false;
      }),
    };
  },
};
