import React from 'react';

import { components } from 'react-select';

import Button from '@uikit/Button/Button';

export const createSelectMenu = (title, buttonCallback) => (props) => {
  return (
    <>
      <components.Menu {...props}>
        <div className="people-actions-menu">
          {title ? (
            <div className="people-actions-menu__header">
              <Button
                type="ghost"
                className="people-actions-menu__button"
                onClick={() => buttonCallback()}
              >
                {title}
              </Button>
            </div>
          ) : null}
          <div>{props.children}</div>
        </div>
      </components.Menu>
    </>
  );
};