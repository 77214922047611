// package: people
// file: people.proto

var people_pb = require("./people_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var common_pb = require("./common_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var PeopleApiService = (function () {
  function PeopleApiService() {}
  PeopleApiService.serviceName = "people.PeopleApiService";
  return PeopleApiService;
}());

PeopleApiService.create = {
  methodName: "create",
  service: PeopleApiService,
  requestStream: false,
  responseStream: false,
  requestType: people_pb.PeopleCrudRequest,
  responseType: people_pb.PeopleCrudResponse
};

PeopleApiService.update = {
  methodName: "update",
  service: PeopleApiService,
  requestStream: false,
  responseStream: false,
  requestType: people_pb.PeopleCrudRequest,
  responseType: people_pb.PeopleCrudResponse
};

PeopleApiService.remove = {
  methodName: "remove",
  service: PeopleApiService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: google_protobuf_empty_pb.Empty
};

PeopleApiService.batchRemove = {
  methodName: "batchRemove",
  service: PeopleApiService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.EntityRelationRequest,
  responseType: google_protobuf_empty_pb.Empty
};

PeopleApiService.getPersonById = {
  methodName: "getPersonById",
  service: PeopleApiService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: people_pb.PeopleDetailResponse
};

PeopleApiService.getAllPeople = {
  methodName: "getAllPeople",
  service: PeopleApiService,
  requestStream: false,
  responseStream: true,
  requestType: people_pb.PeoplePageRequest,
  responseType: people_pb.PeopleResponse
};

PeopleApiService.getPeopleByCompanyId = {
  methodName: "getPeopleByCompanyId",
  service: PeopleApiService,
  requestStream: false,
  responseStream: true,
  requestType: common_pb.PageRequest,
  responseType: people_pb.PeopleShortResponse
};

PeopleApiService.getRelationshipStatusesByCompany = {
  methodName: "getRelationshipStatusesByCompany",
  service: PeopleApiService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: people_pb.PeopleWebsiteRelationshipStatuses
};

PeopleApiService.exportToCsv = {
  methodName: "exportToCsv",
  service: PeopleApiService,
  requestStream: false,
  responseStream: true,
  requestType: people_pb.ExportPeopleRequest,
  responseType: common_pb.FileResponseContainer
};

PeopleApiService.validateIfUnsubscribed = {
  methodName: "validateIfUnsubscribed",
  service: PeopleApiService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdContainer,
  responseType: people_pb.UnsubscribedPersonsList
};

PeopleApiService.updateRelationshipStatus = {
  methodName: "updateRelationshipStatus",
  service: PeopleApiService,
  requestStream: false,
  responseStream: false,
  requestType: people_pb.PersonRelationshipStatusRequest,
  responseType: google_protobuf_empty_pb.Empty
};

PeopleApiService.addEmailToPerson = {
  methodName: "addEmailToPerson",
  service: PeopleApiService,
  requestStream: false,
  responseStream: false,
  requestType: people_pb.PersonInfoRequest,
  responseType: google_protobuf_empty_pb.Empty
};

PeopleApiService.updatePersonEmail = {
  methodName: "updatePersonEmail",
  service: PeopleApiService,
  requestStream: false,
  responseStream: false,
  requestType: people_pb.PersonInfoUpdateRequest,
  responseType: google_protobuf_empty_pb.Empty
};

PeopleApiService.removePersonEmail = {
  methodName: "removePersonEmail",
  service: PeopleApiService,
  requestStream: false,
  responseStream: false,
  requestType: people_pb.PersonInfoRequest,
  responseType: google_protobuf_empty_pb.Empty
};

PeopleApiService.addSocials = {
  methodName: "addSocials",
  service: PeopleApiService,
  requestStream: false,
  responseStream: false,
  requestType: people_pb.PersonInfoRequest,
  responseType: google_protobuf_empty_pb.Empty
};

PeopleApiService.updateSocials = {
  methodName: "updateSocials",
  service: PeopleApiService,
  requestStream: false,
  responseStream: false,
  requestType: people_pb.PersonInfoUpdateRequest,
  responseType: google_protobuf_empty_pb.Empty
};

PeopleApiService.removeSocials = {
  methodName: "removeSocials",
  service: PeopleApiService,
  requestStream: false,
  responseStream: false,
  requestType: people_pb.PersonInfoRequest,
  responseType: google_protobuf_empty_pb.Empty
};

PeopleApiService.createFolder = {
  methodName: "createFolder",
  service: PeopleApiService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.Folder,
  responseType: common_pb.Folder
};

PeopleApiService.updateFolder = {
  methodName: "updateFolder",
  service: PeopleApiService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.Folder,
  responseType: common_pb.Folder
};

PeopleApiService.deleteFolder = {
  methodName: "deleteFolder",
  service: PeopleApiService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: google_protobuf_empty_pb.Empty
};

PeopleApiService.getAllFolders = {
  methodName: "getAllFolders",
  service: PeopleApiService,
  requestStream: false,
  responseStream: true,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: common_pb.Folder
};

PeopleApiService.addPeopleToFolder = {
  methodName: "addPeopleToFolder",
  service: PeopleApiService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.EntityRelationRequest,
  responseType: google_protobuf_empty_pb.Empty
};

PeopleApiService.removePeopleFromFolder = {
  methodName: "removePeopleFromFolder",
  service: PeopleApiService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.EntityRelationRequest,
  responseType: google_protobuf_empty_pb.Empty
};

PeopleApiService.addNote = {
  methodName: "addNote",
  service: PeopleApiService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.Note,
  responseType: common_pb.Note
};

PeopleApiService.removeNote = {
  methodName: "removeNote",
  service: PeopleApiService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: google_protobuf_empty_pb.Empty
};

PeopleApiService.updateNote = {
  methodName: "updateNote",
  service: PeopleApiService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.Note,
  responseType: google_protobuf_empty_pb.Empty
};

PeopleApiService.getNotesByPersonId = {
  methodName: "getNotesByPersonId",
  service: PeopleApiService,
  requestStream: false,
  responseStream: true,
  requestType: common_pb.IdRequest,
  responseType: common_pb.Note
};

PeopleApiService.addTag = {
  methodName: "addTag",
  service: PeopleApiService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.Tag,
  responseType: common_pb.Tag
};

PeopleApiService.addTagBatch = {
  methodName: "addTagBatch",
  service: PeopleApiService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdContainer,
  responseType: google_protobuf_empty_pb.Empty
};

PeopleApiService.removeTag = {
  methodName: "removeTag",
  service: PeopleApiService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: google_protobuf_empty_pb.Empty
};

PeopleApiService.getAllTags = {
  methodName: "getAllTags",
  service: PeopleApiService,
  requestStream: false,
  responseStream: true,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: common_pb.Tag
};

PeopleApiService.getTagsByPersonId = {
  methodName: "getTagsByPersonId",
  service: PeopleApiService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: common_pb.Tags
};

PeopleApiService.getCampaignOpportunitiesByPersonId = {
  methodName: "getCampaignOpportunitiesByPersonId",
  service: PeopleApiService,
  requestStream: false,
  responseStream: true,
  requestType: common_pb.IdRequest,
  responseType: common_pb.PeopleOpportunity
};

PeopleApiService.getCampaignOpportunitiesByPersonIdIn = {
  methodName: "getCampaignOpportunitiesByPersonIdIn",
  service: PeopleApiService,
  requestStream: false,
  responseStream: true,
  requestType: common_pb.IdContainer,
  responseType: common_pb.PeopleOpportunity
};

PeopleApiService.addToBlacklist = {
  methodName: "addToBlacklist",
  service: PeopleApiService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdContainer,
  responseType: google_protobuf_empty_pb.Empty
};

PeopleApiService.removeFromBlacklist = {
  methodName: "removeFromBlacklist",
  service: PeopleApiService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdContainer,
  responseType: google_protobuf_empty_pb.Empty
};

PeopleApiService.createCustomVariable = {
  methodName: "createCustomVariable",
  service: PeopleApiService,
  requestStream: false,
  responseStream: false,
  requestType: people_pb.CustomVariableCreateRequest,
  responseType: people_pb.CustomVariableResponse
};

PeopleApiService.updateCustomVariable = {
  methodName: "updateCustomVariable",
  service: PeopleApiService,
  requestStream: false,
  responseStream: false,
  requestType: people_pb.CustomVariableUpdateRequest,
  responseType: people_pb.CustomVariableResponse
};

PeopleApiService.removeCustomVariable = {
  methodName: "removeCustomVariable",
  service: PeopleApiService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: google_protobuf_empty_pb.Empty
};

PeopleApiService.getAllCustomVariables = {
  methodName: "getAllCustomVariables",
  service: PeopleApiService,
  requestStream: false,
  responseStream: true,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: people_pb.CustomVariableResponse
};

PeopleApiService.getAllCustomVariablesByType = {
  methodName: "getAllCustomVariablesByType",
  service: PeopleApiService,
  requestStream: false,
  responseStream: true,
  requestType: people_pb.CustomVariableRequest,
  responseType: people_pb.CustomVariableResponse
};

PeopleApiService.getCustomVariableValues = {
  methodName: "getCustomVariableValues",
  service: PeopleApiService,
  requestStream: false,
  responseStream: true,
  requestType: people_pb.PersonCustomVariableValueRequest,
  responseType: common_pb.CustomVariableValue
};

PeopleApiService.createCustomVariableValue = {
  methodName: "createCustomVariableValue",
  service: PeopleApiService,
  requestStream: false,
  responseStream: false,
  requestType: people_pb.CustomVariableValueCreateRequest,
  responseType: google_protobuf_empty_pb.Empty
};

exports.PeopleApiService = PeopleApiService;

function PeopleApiServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

PeopleApiServiceClient.prototype.create = function create(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PeopleApiService.create, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PeopleApiServiceClient.prototype.update = function update(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PeopleApiService.update, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PeopleApiServiceClient.prototype.remove = function remove(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PeopleApiService.remove, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PeopleApiServiceClient.prototype.batchRemove = function batchRemove(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PeopleApiService.batchRemove, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PeopleApiServiceClient.prototype.getPersonById = function getPersonById(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PeopleApiService.getPersonById, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PeopleApiServiceClient.prototype.getAllPeople = function getAllPeople(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(PeopleApiService.getAllPeople, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

PeopleApiServiceClient.prototype.getPeopleByCompanyId = function getPeopleByCompanyId(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(PeopleApiService.getPeopleByCompanyId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

PeopleApiServiceClient.prototype.getRelationshipStatusesByCompany = function getRelationshipStatusesByCompany(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PeopleApiService.getRelationshipStatusesByCompany, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PeopleApiServiceClient.prototype.exportToCsv = function exportToCsv(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(PeopleApiService.exportToCsv, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

PeopleApiServiceClient.prototype.validateIfUnsubscribed = function validateIfUnsubscribed(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PeopleApiService.validateIfUnsubscribed, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PeopleApiServiceClient.prototype.updateRelationshipStatus = function updateRelationshipStatus(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PeopleApiService.updateRelationshipStatus, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PeopleApiServiceClient.prototype.addEmailToPerson = function addEmailToPerson(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PeopleApiService.addEmailToPerson, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PeopleApiServiceClient.prototype.updatePersonEmail = function updatePersonEmail(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PeopleApiService.updatePersonEmail, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PeopleApiServiceClient.prototype.removePersonEmail = function removePersonEmail(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PeopleApiService.removePersonEmail, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PeopleApiServiceClient.prototype.addSocials = function addSocials(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PeopleApiService.addSocials, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PeopleApiServiceClient.prototype.updateSocials = function updateSocials(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PeopleApiService.updateSocials, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PeopleApiServiceClient.prototype.removeSocials = function removeSocials(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PeopleApiService.removeSocials, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PeopleApiServiceClient.prototype.createFolder = function createFolder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PeopleApiService.createFolder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PeopleApiServiceClient.prototype.updateFolder = function updateFolder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PeopleApiService.updateFolder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PeopleApiServiceClient.prototype.deleteFolder = function deleteFolder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PeopleApiService.deleteFolder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PeopleApiServiceClient.prototype.getAllFolders = function getAllFolders(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(PeopleApiService.getAllFolders, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

PeopleApiServiceClient.prototype.addPeopleToFolder = function addPeopleToFolder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PeopleApiService.addPeopleToFolder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PeopleApiServiceClient.prototype.removePeopleFromFolder = function removePeopleFromFolder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PeopleApiService.removePeopleFromFolder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PeopleApiServiceClient.prototype.addNote = function addNote(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PeopleApiService.addNote, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PeopleApiServiceClient.prototype.removeNote = function removeNote(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PeopleApiService.removeNote, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PeopleApiServiceClient.prototype.updateNote = function updateNote(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PeopleApiService.updateNote, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PeopleApiServiceClient.prototype.getNotesByPersonId = function getNotesByPersonId(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(PeopleApiService.getNotesByPersonId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

PeopleApiServiceClient.prototype.addTag = function addTag(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PeopleApiService.addTag, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PeopleApiServiceClient.prototype.addTagBatch = function addTagBatch(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PeopleApiService.addTagBatch, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PeopleApiServiceClient.prototype.removeTag = function removeTag(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PeopleApiService.removeTag, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PeopleApiServiceClient.prototype.getAllTags = function getAllTags(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(PeopleApiService.getAllTags, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

PeopleApiServiceClient.prototype.getTagsByPersonId = function getTagsByPersonId(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PeopleApiService.getTagsByPersonId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PeopleApiServiceClient.prototype.getCampaignOpportunitiesByPersonId = function getCampaignOpportunitiesByPersonId(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(PeopleApiService.getCampaignOpportunitiesByPersonId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

PeopleApiServiceClient.prototype.getCampaignOpportunitiesByPersonIdIn = function getCampaignOpportunitiesByPersonIdIn(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(PeopleApiService.getCampaignOpportunitiesByPersonIdIn, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

PeopleApiServiceClient.prototype.addToBlacklist = function addToBlacklist(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PeopleApiService.addToBlacklist, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PeopleApiServiceClient.prototype.removeFromBlacklist = function removeFromBlacklist(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PeopleApiService.removeFromBlacklist, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PeopleApiServiceClient.prototype.createCustomVariable = function createCustomVariable(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PeopleApiService.createCustomVariable, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PeopleApiServiceClient.prototype.updateCustomVariable = function updateCustomVariable(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PeopleApiService.updateCustomVariable, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PeopleApiServiceClient.prototype.removeCustomVariable = function removeCustomVariable(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PeopleApiService.removeCustomVariable, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PeopleApiServiceClient.prototype.getAllCustomVariables = function getAllCustomVariables(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(PeopleApiService.getAllCustomVariables, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

PeopleApiServiceClient.prototype.getAllCustomVariablesByType = function getAllCustomVariablesByType(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(PeopleApiService.getAllCustomVariablesByType, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

PeopleApiServiceClient.prototype.getCustomVariableValues = function getCustomVariableValues(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(PeopleApiService.getCustomVariableValues, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

PeopleApiServiceClient.prototype.createCustomVariableValue = function createCustomVariableValue(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PeopleApiService.createCustomVariableValue, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.PeopleApiServiceClient = PeopleApiServiceClient;

