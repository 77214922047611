import DateRangeSelect from '@uikit/DateRangeSelect/DateRangeSelect';
import { getDateShort } from '@utils/date';
import React, { useState } from 'react';

import { KeywordsSearchType } from '@ts/automationContent.types';

import { countries } from '@constants/countries.contstants';
import { webSearchLanguages } from '@constants/languages.contstants';

import NumberInput from '@uikit/Input/NumberInput';
import Select from '@uikit/Select/Select';
import Checkbox from '@uikit/Checkbox/Checkbox';
import {
  KeywordSearchSource,
  KeywordSearchSourceMap,
} from 'respona_api/generated/automation-content_pb';

import AdvancedSettingsWrapper from '../AdvancedSettingsWrapper/AdvancedSettingsWrapper';

import './KeywordsSearchAdvancedSettings.scss';
import Input from '@uikit/Input/Input';

const sortByOptions = [
  { value: 0, label: 'Relevance' },
  { value: 1, label: 'Date' },
];

const SelectStyles = {
  control: () => ({
    backgroundColor: 'transparent',
    border: '1px solid #DBDFE7',
    borderRadius: '8px',
    display: 'flex',
    height: '30px',
    width: '174px',
  }),
};

const KeywordsSearchAdvancedSettings = ({
  settings,
  onChangeSettings,
  source,
}: {
  settings: KeywordsSearchType;
  onChangeSettings: (settings: Partial<KeywordsSearchType>) => void;
  source?: KeywordSearchSourceMap[keyof KeywordSearchSourceMap];
}): JSX.Element => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const createFieldChangeHandler = (fieldName: string) => (value: unknown) => {
    onChangeSettings({
      [fieldName]: value,
    });
  };

  return (
    <AdvancedSettingsWrapper>
      <div className="k-s-advanced-settings">
        <div className="k-s-advanced-settings__item k-s-advanced-settings__top-search-filter">
          Analyze top
          <NumberInput
            value={settings.analyzeResultsNumber}
            maxNumber={source === KeywordSearchSource.GOOGLE_NEWS_KEYWORD_SEARCH ? 999 : 100}
            minNumber={0}
            onChange={createFieldChangeHandler('analyzeResultsNumber')}
            disabledArrows
          />
          search results
        </div>
        <div className="k-s-advanced-settings__item k-s-advanced-settings__item-grid">
          Location
          <div className="k-s-advanced-settings__location-select">
            <Select
              menuPlacement="top"
              value={countries.find((item) => item.value === settings.location)}
              options={countries}
              onChange={(option) => {
                createFieldChangeHandler('location')(option.value);
              }}
              additionalStyles={SelectStyles}
            />
          </div>
        </div>
        <div className="k-s-advanced-settings__item k-s-advanced-settings__item-grid">
          Language
          <div className="k-s-advanced-settings__language-select">
            <Select
              menuPlacement="top"
              value={webSearchLanguages.find((item) => item.value === settings.language)}
              options={webSearchLanguages}
              onChange={(option) => {
                createFieldChangeHandler('language')(option.value);
              }}
              additionalStyles={SelectStyles}
            />
          </div>
        </div>

        {source === KeywordSearchSource.GOOGLE_NEWS_KEYWORD_SEARCH ? (
          <div className="k-s-advanced-settings__item k-s-advanced-settings__item-grid">
            Date range
            <div className="k-s-advanced-settings__language-select">
              {/* <Select */}
              {/*  menuPlacement="top" */}
              {/*  value={sortByOptions.find((item) => item.value === settings.sortBy)} */}
              {/*  options={sortByOptions} */}
              {/*  onChange={(option) => { */}
              {/*    createFieldChangeHandler('sortBy')(option.value); */}
              {/*  }} */}
              {/*  additionalStyles={SelectStyles} */}
              {/* /> */}

              <div
                onClick={() => setIsVisible(true)}
                style={{
                  backgroundColor: 'transparent',
                  border: '1px solid #DBDFE7',
                  borderRadius: '8px',
                  display: 'flex',
                  height: '30px',
                  width: '174px',
                  padding: '2px 8px',
                  alignItems: 'center',
                }}
              >
                {settings.optionalFreshnessValue?.from ? (
                  <p style={{ fontSize: '13px' }}>
                    {getDateShort(new Date(settings.optionalFreshnessValue?.from), false)}
                    {' - '}
                    {getDateShort(new Date(settings.optionalFreshnessValue?.to), false)}
                  </p>
                ) : (
                  <p>Default</p>
                )}
              </div>

              <DateRangeSelect
                onChange={(from, to) => {
                  onChangeSettings({
                    optionalFreshnessValue: {
                      from,
                      to: to ?? from,
                    },
                  });
                }}
                value={{
                  startDate: settings.optionalFreshnessValue?.from,
                  endDate: settings.optionalFreshnessValue?.to,
                }}
                isVisible={isVisible}
                onChangeIsVisible={setIsVisible}
                emptyAllowed
              />
            </div>
          </div>
        ) : (
          <div className="k-s-advanced-settings__item k-s-advanced-settings__item-grid">
            Sort by
            <div className="k-s-advanced-settings__language-select">
              <Select
                menuPlacement="top"
                value={sortByOptions.find((item) => item.value === settings.sortBy)}
                options={sortByOptions}
                onChange={(option) => {
                  createFieldChangeHandler('sortBy')(option.value);
                }}
                additionalStyles={SelectStyles}
              />
            </div>
          </div>
        )}

        <div className="b-s-advanced-settings__item b-s-advanced-settings__item-grid">
          Unlinked
          <div className="b-s-advanced-settings__language-select">
            <Input
              placeholder="domain.com"
              style={{ width: '215px', marginLeft: 0, textAlign: 'left' }}
              value={settings.optionalUnlinkedDomain}
              onChange={(e) => {
                createFieldChangeHandler('optionalUnlinkedDomain')(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="k-s-advanced-settings__item">
          <Checkbox
            onChange={() => {
              createFieldChangeHandler('oneResultPerDomain')(!settings.oneResultPerDomain);
            }}
            value={settings.oneResultPerDomain}
          />
          Only one URL per domain
        </div>
        <div className="k-s-advanced-settings__item max-label-width">
          <Checkbox
            onChange={() => {
              createFieldChangeHandler('ignoreAlreadyContacted')(!settings.ignoreAlreadyContacted);
            }}
            value={settings.ignoreAlreadyContacted}
          />
          Ignore domains contacted within
          <NumberInput
            value={settings.optionalIgnoreAlreadyContactedDays}
            maxNumber={999}
            minNumber={1}
            onChange={createFieldChangeHandler('optionalIgnoreAlreadyContactedDays')}
            disabledArrows
          />
          days
        </div>
        <div className="k-s-advanced-settings__item">
          <Checkbox
            onChange={() => {
              createFieldChangeHandler('ignoreUnsubscribed')(!settings.ignoreUnsubscribed);
            }}
            value={settings.ignoreUnsubscribed}
          />
          Ignore domains included in the Unsubscribed list
        </div>
        <div className="k-s-advanced-settings__item">
          <Checkbox
            onChange={() => {
              createFieldChangeHandler('ignoreActive')(!settings.ignoreActive);
            }}
            value={settings.ignoreActive}
          />
          Ignore domains included in other active/paused campaigns
        </div>
        <div className="k-s-advanced-settings__item">
          <Checkbox
            onChange={() => {
              createFieldChangeHandler('ignoreHomepages')(!settings.ignoreHomepages);
            }}
            value={settings.ignoreHomepages}
          />
          Ignore homepages
        </div>
      </div>
    </AdvancedSettingsWrapper>
  );
};

export default KeywordsSearchAdvancedSettings;
