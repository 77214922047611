import { handleActions } from 'redux-actions';

import { setIsEmailMapped } from '@redux/actions/fileAttributes.actions';

export type FileAttributesStateType = {
  isEmailMapped: boolean;
};

export default handleActions(
  {
    [setIsEmailMapped]: (state: FileAttributesStateType, { payload }): FileAttributesStateType => {
      return {
          ...state,
          isEmailMapped: payload,
      }
    },
  },
  {
    isEmailMapped: false,
  }
);
