import React from 'react';
import { components } from 'react-select';

import ClearbitAsyncSelect from '@uikit/Select/ClearbitAsyncSelect';

export type ConfigItemType = {
  key: string;
  title: string;
  icon: string;
  isArray?: boolean;
  maxCount?: number;
  value?: string;
  renderField?: (any) => any;
  optional?: boolean;
};

const config: { required: ConfigItemType[]; additional: ConfigItemType[] } = {
  required: [
    {
      key: 'name',
      title: 'Name',
      icon: 'user',
      optional: true,
    },
    {
      key: 'emailsList',
      title: 'Email',
      icon: 'link',
      isArray: true,
      maxCount: 5,
    },
    {
      title: 'Website domain',
      key: 'websiteDomain',
      icon: 'building',
      optional: true,
      renderField: ({ value, onChange }) => {
        const optionsContainerStyles = {
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'nowrap',
        } as React.CSSProperties;

        function Option({ children, ...restProps }): JSX.Element {
          return (
            <components.Option {...restProps}>
              <div style={optionsContainerStyles}>
                {restProps.data?.logo ? (
                  <img
                    style={{ borderRadius: '100%' }}
                    src={restProps.data?.logo}
                    width="16px"
                    height="16px"
                    alt=""
                  />
                ) : null}
                &nbsp; {children}
              </div>
            </components.Option>
          );
        }

        return (
          <ClearbitAsyncSelect
            className="basic-multi-select"
            value={value}
            onChange={onChange}
            additionalComponents={{ Option }}
          />
        );
      },
    },
    {
      key: 'websiteName',
      title: 'Website name',
      icon: 'building',
      optional: true,
    },
  ],
  additional: [
    {
      title: 'Location',
      key: 'location',
      icon: 'location',
    },
    {
      title: 'Company Name',
      key: 'company',
      icon: 'building',
      renderField: ({ value, onChange }) => {
        const optionsContainerStyles = {
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'nowrap',
        } as React.CSSProperties;

        function Option({ children, ...restProps }): JSX.Element {
          return (
            <components.Option {...restProps}>
              <div style={optionsContainerStyles}>
                <img
                  style={{ borderRadius: '100%' }}
                  src={restProps.data?.logo}
                  width="16px"
                  height="16px"
                />{' '}
                &nbsp; {children}
              </div>
            </components.Option>
          );
        }

        return (
          <ClearbitAsyncSelect
            value={value}
            onChange={onChange}
            additionalComponents={{ Option }}
          />
        );
      },
    },
    {
      title: 'Job Position',
      key: 'jobPosition',
      icon: 'suitcase',
    },
  ],
};

export default config;
