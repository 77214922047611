import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';

import { createBlobFromResponse } from '@helpers/fileHelpers';

import { generateInvoicePdfApi, getInvoiceDetailsApi } from '@api/billing.api';

import { getCurrentWorkspaceId } from '@redux/selectors/workspaces.selectors';
import { fetchBillingDetails } from '@redux/thunks/billings.thunks';

import Loader from '@uikit/Loader/Loader';
import PageHeader from '@components/PageHeader/PageHeader';

import BackArrowButton from '@components/BackArrowButton/BackArrowButton';
import InvoiceInfo from '@components/InvoiceInfo/InvoiceInfo';
import urls from '@constants/urls';
import { Button } from '@uikit/Button/Button';

const InvoiceDetails = ({
  match: {
    params: { invoiceId },
  },
}): JSX.Element => {
  const dispatch = useDispatch();
  const curWorkspaceId = useSelector(getCurrentWorkspaceId);

  const [isInvoiceLoading, setInvoiceLoading] = useState(false);

  const { data: invoiceDetails, isLoading } = useQuery({
    queryKey: ['invoice-details', curWorkspaceId, invoiceId],
    queryFn: () => getInvoiceDetailsApi(curWorkspaceId, Number(invoiceId)),
    refetchOnWindowFocus: false,
  });

  const handlePaymentSuccess = () => {
    dispatch(fetchBillingDetails());
  };

  const handleDownloadInvoice = () => {
    setInvoiceLoading(true);
    generateInvoicePdfApi(invoiceDetails.id, curWorkspaceId)
      .then((res) => {
        const blob = createBlobFromResponse(res);

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = res.title;
        link.click();
        link.remove();
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setInvoiceLoading(false));
  };

  if (isLoading) {
    return <Loader isLoading withTopMargin />;
  }

  return (
    <div className="invoice-details">
      <PageHeader
        renderHeaderTitle={() => (
          <div className="invoice-details__header-title">
            <BackArrowButton forceLink={urls.BILLING} />
            Invoice
          </div>
        )}
        renderHeaderActions={() => (
          <Button isLoading={isInvoiceLoading} onClick={handleDownloadInvoice}>
            Download
          </Button>
        )}
      />
      <InvoiceInfo invoice={invoiceDetails} onPaymentSuccess={handlePaymentSuccess} />
    </div>
  );
};

export default InvoiceDetails;
