import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { HaroOpportunityType } from 'src/types/haro.types';
import Button from 'src/uikit/Button/Button';
import { SVGIcon } from 'src/uikit/Icon/Icon';
import { addInquiryBookmark, removeInquiryBookmark } from 'src/redux/thunks/inquries.thunks';
import { getCurrentWorkspaceId } from 'src/redux/selectors/workspaces.selectors';

import './InquiryDetails.scss';
import Display from '../../Display/Display';

export default ({
  inquiry,
  onHide,
  withActions = true,
  onPitch = () => {},
}: {
  inquiry: HaroOpportunityType;
  onHide: () => void;
  withActions?: boolean;
  onPitch?: (HaroOpportunityType) => void;
}): JSX.Element => {
  const dispatch = useDispatch();
  const curWorkspaceId = useSelector(getCurrentWorkspaceId);

  const handleToogleBookmark = () => {
    if (inquiry.isBookmark) {
      return dispatch(removeInquiryBookmark(curWorkspaceId, inquiry));
    }

    return dispatch(addInquiryBookmark(curWorkspaceId, inquiry));
  };

  return (
    <div className="inquiry-details">
      <div className="inquiry-details__details">
        <div className="inquiry-details__title">Query:</div>
        <div className="inquiry-details__info">{inquiry.query}</div>
        <Display isVisible={!!inquiry.requirements}>
          <>
            <div className="inquiry-details__title">Requirements:</div>
            <div className="inquiry-details__info">{inquiry.requirements}</div>
          </>
        </Display>
      </div>
      <Display isVisible={withActions}>
        <div className="inquiry-details__actions">
          <Button
            onClick={handleToogleBookmark}
            type="ghost"
            size="xs"
            className="inquiry-details__add-bookmark-btn"
          >
            <SVGIcon icon="bookmark" color={inquiry.isBookmark ? '#2AA4CB' : '#8F9199'} />
          </Button>
          <Button className="inquiry-details__pitch-btn" onClick={() => onPitch(inquiry.id)}>
            <SVGIcon icon="pen" color="#fff" />
            Write Pitch
          </Button>
        </div>
      </Display>
      <div className="inquiry-details__hide-btn" onClick={onHide}>
        <div className="inquiry-details__hide-btn-content">
          HIDE DETAILS
          <SVGIcon icon="arrowRightGrey" color="#BBBDBE" />
        </div>
      </div>
    </div>
  );
};
