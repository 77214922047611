/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import { format } from 'date-fns';

import { InvoiceShortType } from '@ts/billing.types';
import { Tr } from '@uikit/Table/Table';
import Button from '@uikit/Button/Button';

type TableRenderProps = {
  row: InvoiceShortType;
  children?: any;
};

const statusTextMapper = {
  0: 'Future',
  1: 'In progress',
  2: 'Paid',
  3: 'Refunded',
};

const statusColorMapper = {
  0: '#fce40e',
  1: '#fc8e0e',
  2: '#23E771',
  3: '#000000',
};

export default [
  {
    title: 'Transaction ID',
    dataKey: 'number',
  },
  {
    title: 'Date / time',
    dataKey: 'date',
    RenderCell: ({ row }: TableRenderProps) => {
      if (!row.date || row.date === -1) {
        return <div className="billing-history-table__date-time">-</div>;
      }
      const invoiceDate = new Date(row.date);

      return (
        <div className="billing-history-table__date-time">
          <div>{format(invoiceDate, 'MMM dd, yyyy')}</div>
          <div>{format(invoiceDate, 'hh.mm aaa')}</div>
        </div>
      );
    },
  },
  {
    title: 'Amount',
    dataKey: 'amount',
    RenderCell: ({ row }: TableRenderProps) => <>{`$${row.amount}`}</>,
  },
  {
    title: 'Status',
    dataKey: 'status',
    RenderCell: ({ row }: TableRenderProps) => (
      <span style={{ color: statusColorMapper[row.status] }}>{statusTextMapper[row.status]}</span>
    ),
  },
  {
    title: 'Receipt',
    dataKey: 'receipt',
    RenderCell: ({ row }: TableRenderProps) => (
      <Button
        href={`/settings/billing/invoice/${row.id}`}
        type="secondary"
        className="billing-history-table__details-btn"
      >
        Details
      </Button>
    ),
  },
];

export const RenderRow = ({ children }: TableRenderProps): JSX.Element => (
  <Tr className="billing-history-table__row">{children}</Tr>
);
