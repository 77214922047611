import MailboxSenderService from '@api/services/mailboxSenderService';
import request from '@api/base/request';
import { FileAttachmentType } from '@ts/common.types';
import { FileAttachment } from 'respona_api/generated/common_pb';
import MailboxSenderModels, {
  MessageRequestTypeMap,
} from 'respona_api/generated/mailbox-sender_pb';

export const sendTestMessageApi = (
  mailboxAccountId: number,
  personId: number,
  scheduleId: number,
  opportunityId: number,
  subject: string,
  content: string,
  toEmail: string,
  attachments: FileAttachment.AsObject[],
  campaignId: number,
  unsubscribeContentEnabled: boolean
) => {
  const client = MailboxSenderService.getInstance();
  const testMessage = new MailboxSenderModels.TestMessage();

  testMessage.setMailboxAccountId(mailboxAccountId);
  testMessage.setPersonId(personId);
  testMessage.setScheduleId(scheduleId);
  testMessage.setOpportunityId(opportunityId);
  testMessage.setSubject(subject);
  testMessage.setContent(content);
  testMessage.setOptionalToEmail(toEmail);
  testMessage.setCampaignId(campaignId);
  testMessage.setUnsubscribeContentEnabled(unsubscribeContentEnabled);
  testMessage.setReferralLinkEnabled(false);

  testMessage.setAttachmentsList(
    attachments.map(({ key, title, size }) => {
      const fileAttachment = new FileAttachment();

      fileAttachment.setKey(key);
      fileAttachment.setTitle(title);
      fileAttachment.setSize(size);

      return fileAttachment;
    })
  );

  return request(client, client.sendTestMessage, testMessage);
};

export const sendMessageApi = (
  mailboxAccountId: number,
  type: MessageRequestTypeMap[keyof MessageRequestTypeMap],
  sendTo: string,
  subject: string,
  content: string,
  ccList: string[],
  bccList: string[],
  attachments: FileAttachmentType[],
  referralLingEnabled: boolean,
  messageId?: string
): Promise<void> => {
  const client = MailboxSenderService.getInstance();
  const emailMessageRequest = new MailboxSenderModels.EmailMessageRequest();

  emailMessageRequest.setMailboxAccountId(mailboxAccountId);
  emailMessageRequest.setType(type);
  emailMessageRequest.setSendToEmail(sendTo);
  emailMessageRequest.setSubject(subject);
  emailMessageRequest.setContent(content);
  emailMessageRequest.setEmailsToCcList(ccList);
  emailMessageRequest.setEmailsToBccList(bccList);
  emailMessageRequest.setReferralLinkEnabled(referralLingEnabled);
  if (messageId) {
    emailMessageRequest.setOptionalMessageId(messageId);
  }
  emailMessageRequest.setAttachmentsList(
    attachments?.map(({ key, title, size }) => {
      const attachment = new FileAttachment();
      attachment.setKey(key);
      attachment.setTitle(title);
      attachment.setSize(size);

      return attachment;
    })
  );

  return request<void>(client, client.sendMessage, emailMessageRequest);
};
