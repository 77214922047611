import React from 'react';
import { PRIMARY_COLOR } from '@constants/colors';

export default ({ color = PRIMARY_COLOR }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99994 0.833313C10.3171 0.833313 10.6068 1.01337 10.7472 1.29779L13.1285 6.12203L18.4538 6.90041C18.7676 6.94627 19.0282 7.16628 19.126 7.46795C19.2238 7.76963 19.1419 8.10067 18.9147 8.32195L15.062 12.0745L15.9713 17.3758C16.0249 17.6884 15.8964 18.0044 15.6397 18.1909C15.3831 18.3773 15.0428 18.4019 14.7621 18.2542L9.99994 15.7499L5.23782 18.2542C4.95706 18.4019 4.61681 18.3773 4.36016 18.1909C4.10352 18.0044 3.97498 17.6884 4.0286 17.3758L4.93784 12.0745L1.08517 8.32195C0.857984 8.10067 0.77609 7.76963 0.873905 7.46795C0.971719 7.16628 1.23229 6.94627 1.54609 6.90041L6.87139 6.12203L9.25269 1.29779C9.39308 1.01337 9.68276 0.833313 9.99994 0.833313ZM9.99994 3.54936L8.1722 7.25217C8.05092 7.49787 7.8166 7.66826 7.54547 7.70789L3.45695 8.30548L6.41472 11.1863C6.61128 11.3778 6.701 11.6537 6.65462 11.9242L5.95676 15.993L9.61207 14.0708C9.85489 13.9431 10.145 13.9431 10.3878 14.0708L14.0431 15.993L13.3453 11.9242C13.2989 11.6537 13.3886 11.3778 13.5852 11.1863L16.5429 8.30548L12.4544 7.70789C12.1833 7.66826 11.949 7.49787 11.8277 7.25217L9.99994 3.54936Z"
      fill={color}
    />
  </svg>
);
