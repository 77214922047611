import { PeopleWebsiteFilterType } from '@ts/company.types';
import { FolderType } from '@ts/common.types';

import loadingStatuses from '@constants/loadingStatuses';
import { updateItemInListByIdHelper } from '@helpers/commonHelpers';
import { FilterJoins } from '@constants/filterOperations';
import {
  SET_COMPANIES_FOLDERS_DATA,
  SET_COMPANIES_FOLDERS_DATA_LS,
  ADD_COMPANIES_FOLDER_DATA,
  UPDATE_COMPANIES_FOLDER_DATA,
  REMOVE_COMPANIES_FOLDER_DATA,
  SET_COMPANIES_FILTERS,
  THROW_COMPANIES_FILTERS,
} from '@redux/actions/companies.actions';
import { FilterOperation } from 'respona_api/generated/common_pb';
import { PeopleWebsiteFilterField } from 'respona_api/generated/people-website_pb';

export type CompaniesStateType = {
  folders: {
    loadingStatus: string;
    items: FolderType[];
  };
  filters: PeopleWebsiteFilterType[] | null;
};

export const initialRelationshipWebsiteFilterRawState = {
  order: 0,
  field: PeopleWebsiteFilterField.DROPDOWN_WEBSITE_WORKSPACE_ID,
  value: '',
  operation: FilterOperation.NUMBER_EQUAL,
  join: FilterJoins[0].value,
};

const initialState: CompaniesStateType = {
  folders: {
    loadingStatus: loadingStatuses.PENDING,
    items: [],
  },
  filters: [initialRelationshipWebsiteFilterRawState],
};

export default (
  state: CompaniesStateType = initialState,
  { type, payload }
): CompaniesStateType => {
  switch (type) {
    // Filters
    case SET_COMPANIES_FILTERS:
      return {
        ...state,
        filters: payload,
      };
    case THROW_COMPANIES_FILTERS:
      return {
        ...state,
        filters: [initialRelationshipWebsiteFilterRawState],
      };

    // Folders
    case SET_COMPANIES_FOLDERS_DATA:
      return {
        ...state,
        folders: {
          ...state.folders,
          items: payload,
        },
      };
    case SET_COMPANIES_FOLDERS_DATA_LS:
      return {
        ...state,
        folders: {
          ...state.folders,
          loadingStatus: payload,
        },
      };
    case ADD_COMPANIES_FOLDER_DATA:
      return {
        ...state,
        folders: {
          ...state.folders,
          items: [...state.folders.items, payload],
        },
      };
    case UPDATE_COMPANIES_FOLDER_DATA:
      return {
        ...state,
        folders: {
          ...state.folders,
          items: updateItemInListByIdHelper(state.folders.items, payload),
        },
      };
    case REMOVE_COMPANIES_FOLDER_DATA:
      return {
        ...state,
        folders: {
          ...state.folders,
          items: state.folders.items.filter(({ id }) => id !== payload),
        },
      };
    default:
      return state;
  }
};
