import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import CustomFieldsSettingsPage
  from '@components/Settings/CustomFieldsSettingsPage/CustomFieldsSettingsPage';
import SettingsSidebar from '@components/Settings/SettingsSidebar/SettingsSidebar';
import ProfileSettings from '@components/Settings/ProfileSettings/ProfileSettings';
import EmailsSettings from '@components/Settings/EmailsSettings/EmailsSettings';
import SchedulesSettings from '@components/Settings/SchedulesSettings/SchedulesSettings';
import AllMembersSettings from '@components/Settings/MembersSettings/AllMembersSettings';
import EarnFreeCreditsSettings
  from '@components/Settings/EarnFreeCreditsSettings/EarnFreeCreditsSettings';

import Workspaces from '@components/Settings/Workspaces/Workspaces';
import IntegrationsPage from '@pages/IntegrationsPage/IntegrationsPage';
import CreateWorkspacePage from '@pages/CreateWorkspacePage/CreateWorkspacePage';
import TemplatesSettingPage from '@pages/TemplatesSettingPage/TemplatesSettingPage';
import SMTPEmailSettings from '@components/Settings/SMTPEmailSettings/SMTPEmailSettings';
import UnsubscribedList from '@pages/UnsubscribedListPage/UnsubscribedListPage';
import BillingPage from '@pages/BillingPage/BillingPage';
import TemplateEditorSettingPage from '@pages/TemplateEditorSettingPage/TemplateEditorSettingPage';
import NewTemplateSettingsPage from '@pages/TemplateEditorSettingPage/NewTemplateSettingsPage';

import ErrorBoundaryNoAccess from '@uikit/ErrorBoundaries/ErrorBoundaryNoAccess';

import './SettingsPage.scss';
import urls from '@constants/urls';

const SettingsPage = ({ location: { pathname } }): JSX.Element => (
  <div className="settings-page">
    <SettingsSidebar pathname={pathname} />
    <div className="settings-page__content">
      <Switch>
        <Route path="/settings/profile">
          <ProfileSettings />
        </Route>
        <Route path="/settings/all-emails">
          <EmailsSettings />
        </Route>
        <Route path="/settings/email-smtp">
          <SMTPEmailSettings />
        </Route>
        <Route path="/settings/all-members">
          <ErrorBoundaryNoAccess>
            <AllMembersSettings />
          </ErrorBoundaryNoAccess>
        </Route>
        <Route path="/settings/schedules">
          <SchedulesSettings />
        </Route>
        <Route path="/settings/billing">
          <ErrorBoundaryNoAccess>
            <BillingPage />
          </ErrorBoundaryNoAccess>
        </Route>
        <Route path="/settings/workspaces">
          <Workspaces />
        </Route>
        <Route path="/settings/unsubscribed">
          <UnsubscribedList />
        </Route>
        <Route path={urls.NEW_TEMPLATE}>
          <NewTemplateSettingsPage />
        </Route>
        <Route path={`${urls.TEMPLATES}/:id`}>
          <TemplateEditorSettingPage />
        </Route>
        <Route path={urls.TEMPLATES}>
          <TemplatesSettingPage />
        </Route>
        <Route path="/settings/create/workspace">
          <CreateWorkspacePage />
        </Route>
        <Route path="/settings/integrations">
          <IntegrationsPage />
        </Route>
        <Route path="/settings/referral">
          <EarnFreeCreditsSettings />
        </Route>
        <Route path="/settings/custom-fields">
          <CustomFieldsSettingsPage />
        </Route>
        <Route path="/settings/*">
          <Redirect to="/settings/all-emails" />
        </Route>
      </Switch>
    </div>
  </div>
);

export default SettingsPage;
