export const getOnlyHiDescendant = (name: string) => {
  let resultForName = name.split(' ')[0];

  if (name === 'Anonymous') {
    resultForName = '';
  }

  return [
    { type: 'paragraph', children: [{ text: `Hi, ${resultForName}`, type: 'text' }] },

    { type: 'paragraph', children: [{ text: '', type: 'text' }] },
    { type: 'paragraph', children: [{ text: '', type: 'text' }] },
    { type: 'paragraph', children: [{ text: '', type: 'text' }] },
  ];
};

export const getHiWithFooterDescendant = (name: string, filterInfo) => {
  const result = getOnlyHiDescendant(name);

  const addIfExists = (fieldName, preText) => {
    if (filterInfo[fieldName]) {
      const textLine = getParagraphWithItalicPreText(preText, filterInfo[fieldName]);
      result.push(textLine);
    }
  };

  const getParagraphWithItalicPreText = (preText, text) => {
    return {
      type: 'paragraph',
      children: [
        { text: `${preText}: `, type: 'text', italic: true },
        { text, type: 'text' },
      ],
    };
  };

  if (filterInfo) {
    result.push({
      type: 'paragraph',
      // @ts-ignore
      children: [{ text: "Source's Bio:", type: 'text', bold: true }],
    });

    addIfExists('bioFullName', 'Full Name');
    addIfExists('bioJobTitle', 'Job Title');
    addIfExists('bioCompanyName', 'Company');
    addIfExists('bioCompanyWebsite', 'Company Website');
    addIfExists('bioHeadshot', 'Headshot');
    addIfExists('bioLinkedInLink', 'Linkedin');
    addIfExists('bioTwitterLink', 'Twitter');
  }

  return result;
};
