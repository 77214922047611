import React from 'react';
import Button from '@uikit/Button/Button';

import './InboxHeader.scss';
import InboxSearch from '@components/Inbox/InboxSearch/InboxSearch';

function InboxHeader({
  onNewMessage,
  allMailboxes,
}: {
  onNewMessage: () => void;
  allMailboxes: any;
}): JSX.Element {
  return (
    <div className="inbox-header__actions-wrapper">
      <h1>Inbox</h1>
      <div className="inbox-header__actions-wrapper-content">
        <InboxSearch />
        <Button
          loadingText="Starting campaign..."
          className="inbox-header__button"
          onClick={onNewMessage}
          disabled={!allMailboxes?.length}
          leftIcon="editAction"
          iconSize={20}
        >
          Compose
        </Button>
      </div>
    </div>
  );
}

export default InboxHeader;
