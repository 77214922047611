import { BoundaryType, ParsedValueType, ParseInputsType } from '@ts/moderateChance.types';

const boundaries: BoundaryType[] = [
  {
    min: 0,
    max: 0,
    color: 'yellow',
  },
  {
    min: 1,
    max: 1,
    color: 'green',
  },
  {
    min: 2,
    max: 2,
    color: 'yellow',
  },
  {
    min: 3,
    color: 'red',
  },
];

export default {
  key: 'questionCount',
  label: 'Questions',
  min: boundaries[0].min,
  max: boundaries[boundaries.length - 1].min,
  greenZone: {
    min: 1,
    max: 1,
  },
  parse: ({ text }: ParseInputsType): ParsedValueType => {
    const questionCount = text.split(' ').filter((word) => word.includes('?')).length;

    return {
      value: questionCount,
      boundary: boundaries.find(({ min, max }) => {
        if (!max && max !== 0 && questionCount >= max) {
          return true;
        }

        if (min <= questionCount && questionCount <= max) {
          return true;
        }
      }),
    };
  },
};
