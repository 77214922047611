import React from 'react';

const VisaIcon = () => {
  return (
    <svg width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M30.6667 4.66699H0V17.3337H30.6667V4.66699Z" fill="#E6E6E6" />
      <path
        d="M2 22.6663H28.6667C29.7713 22.6663 30.6667 21.771 30.6667 20.6663V17.333H0V20.6663C0 21.771 0.895333 22.6663 2 22.6663Z"
        fill="#E79800"
      />
      <path
        d="M28.6667 0H2C0.895333 0 0 0.895333 0 2V5.33333H30.6667V2C30.6667 0.895333 29.7713 0 28.6667 0Z"
        fill="#1A1876"
      />
      <path d="M12.1587 14.5334H13.898L14.9867 8.14941H13.2467L12.1587 14.5334Z" fill="#1A1876" />
      <path
        d="M18.4953 10.7128C17.8873 10.4174 17.5146 10.2201 17.5186 9.92142C17.5186 9.65609 17.8339 9.37209 18.5153 9.37209C19.0726 9.36342 19.5219 9.47675 19.9739 9.69009L20.2099 8.30609C19.8653 8.17675 19.3253 8.03809 18.6506 8.03809C16.9313 8.03809 15.7199 8.90409 15.7099 10.1448C15.6999 11.0621 16.5746 11.5741 17.2346 11.8794C17.9119 12.1921 18.1399 12.3921 18.1366 12.6714C18.1326 13.0994 17.5959 13.2948 17.0953 13.2948C16.3233 13.2948 15.9339 13.1754 15.2333 12.8581L14.9893 14.2874C15.3959 14.4654 16.1473 14.6201 16.9279 14.6281C18.7573 14.6281 19.9446 13.7721 19.9579 12.4468C19.9626 11.7208 19.4993 11.1681 18.4953 10.7128Z"
        fill="#1A1876"
      />
      <path
        d="M24.6713 8.15527H23.3266C22.91 8.15527 22.5986 8.26927 22.4153 8.68461L19.8313 14.5353H21.6586C21.6586 14.5353 21.9573 13.7486 22.0253 13.5759C22.2253 13.5759 24 13.5786 24.254 13.5786C24.306 13.8019 24.466 14.5353 24.466 14.5353H26.0806L24.6713 8.15527ZM22.526 12.2706C22.598 12.0866 23.308 10.2633 23.45 9.87661C23.6853 10.9773 23.456 9.90927 23.9706 12.2706H22.526Z"
        fill="#1A1876"
      />
      <path
        d="M10.6993 8.15439L8.99534 12.5077L8.814 11.6231L8.204 8.68906C8.09867 8.28506 7.79334 8.16439 7.41534 8.15039H4.60867L4.58667 8.28306C5.26934 8.44839 5.87934 8.68706 6.41467 8.98306L7.962 14.5277L9.80334 14.5257L12.5427 8.15372H10.6993V8.15439Z"
        fill="#1A1876"
      />
    </svg>
  );
};

export default VisaIcon;
