import { handleActions } from 'redux-actions';

import {
  setRefetchOpportunities,
  setRefetchShortOpportunities,
} from '@redux/actions/pipelines.actions';

export type PipelinesStateType = {
  refetchOpportunities: () => void;
  refetchShortOpportunities: () => void;
};

const initialState: PipelinesStateType = {
  refetchOpportunities: () => {},
  refetchShortOpportunities: () => {},
};

export default handleActions(
  {
    [setRefetchOpportunities]: (state: PipelinesStateType, { payload }): PipelinesStateType => ({
      ...state,
      refetchOpportunities: payload,
    }),
    [setRefetchShortOpportunities]: (
      state: PipelinesStateType,
      { payload }
    ): PipelinesStateType => ({
      ...state,
      refetchShortOpportunities: payload,
    }),
  },
  initialState
);
