// source: file-importer.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
goog.exportSymbol('proto.importer.file.ColumnHeader', null, global);
goog.exportSymbol('proto.importer.file.ImportRequest', null, global);
goog.exportSymbol('proto.importer.file.ParseFileRequest', null, global);
goog.exportSymbol('proto.importer.file.ParseFileResponse', null, global);
goog.exportSymbol('proto.importer.file.ParsingDelimiter', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.importer.file.ParseFileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.importer.file.ParseFileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.importer.file.ParseFileRequest.displayName = 'proto.importer.file.ParseFileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.importer.file.ColumnHeader = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.importer.file.ColumnHeader, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.importer.file.ColumnHeader.displayName = 'proto.importer.file.ColumnHeader';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.importer.file.ParseFileResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.importer.file.ParseFileResponse.repeatedFields_, null);
};
goog.inherits(proto.importer.file.ParseFileResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.importer.file.ParseFileResponse.displayName = 'proto.importer.file.ParseFileResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.importer.file.ImportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.importer.file.ImportRequest.repeatedFields_, null);
};
goog.inherits(proto.importer.file.ImportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.importer.file.ImportRequest.displayName = 'proto.importer.file.ImportRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.importer.file.ParseFileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.importer.file.ParseFileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.importer.file.ParseFileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.importer.file.ParseFileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    fileUid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    delimiter: jspb.Message.getFieldWithDefault(msg, 4, 0),
    optionalOtherDelimiterValue: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.importer.file.ParseFileRequest}
 */
proto.importer.file.ParseFileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.importer.file.ParseFileRequest;
  return proto.importer.file.ParseFileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.importer.file.ParseFileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.importer.file.ParseFileRequest}
 */
proto.importer.file.ParseFileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileUid(value);
      break;
    case 4:
      var value = /** @type {!proto.importer.file.ParsingDelimiter} */ (reader.readEnum());
      msg.setDelimiter(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionalOtherDelimiterValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.importer.file.ParseFileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.importer.file.ParseFileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.importer.file.ParseFileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.importer.file.ParseFileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getFileUid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDelimiter();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getOptionalOtherDelimiterValue();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int64 campaign_id = 1;
 * @return {number}
 */
proto.importer.file.ParseFileRequest.prototype.getCampaignId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.importer.file.ParseFileRequest} returns this
 */
proto.importer.file.ParseFileRequest.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 workspace_id = 2;
 * @return {number}
 */
proto.importer.file.ParseFileRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.importer.file.ParseFileRequest} returns this
 */
proto.importer.file.ParseFileRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string file_uid = 3;
 * @return {string}
 */
proto.importer.file.ParseFileRequest.prototype.getFileUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.importer.file.ParseFileRequest} returns this
 */
proto.importer.file.ParseFileRequest.prototype.setFileUid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ParsingDelimiter delimiter = 4;
 * @return {!proto.importer.file.ParsingDelimiter}
 */
proto.importer.file.ParseFileRequest.prototype.getDelimiter = function() {
  return /** @type {!proto.importer.file.ParsingDelimiter} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.importer.file.ParsingDelimiter} value
 * @return {!proto.importer.file.ParseFileRequest} returns this
 */
proto.importer.file.ParseFileRequest.prototype.setDelimiter = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string optional_other_delimiter_value = 5;
 * @return {string}
 */
proto.importer.file.ParseFileRequest.prototype.getOptionalOtherDelimiterValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.importer.file.ParseFileRequest} returns this
 */
proto.importer.file.ParseFileRequest.prototype.setOptionalOtherDelimiterValue = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.importer.file.ColumnHeader.prototype.toObject = function(opt_includeInstance) {
  return proto.importer.file.ColumnHeader.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.importer.file.ColumnHeader} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.importer.file.ColumnHeader.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    mappedVariable: jspb.Message.getFieldWithDefault(msg, 2, 0),
    optionalCustomVariableId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.importer.file.ColumnHeader}
 */
proto.importer.file.ColumnHeader.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.importer.file.ColumnHeader;
  return proto.importer.file.ColumnHeader.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.importer.file.ColumnHeader} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.importer.file.ColumnHeader}
 */
proto.importer.file.ColumnHeader.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {!proto.common.ContactVariable} */ (reader.readEnum());
      msg.setMappedVariable(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOptionalCustomVariableId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.importer.file.ColumnHeader.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.importer.file.ColumnHeader.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.importer.file.ColumnHeader} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.importer.file.ColumnHeader.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMappedVariable();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getOptionalCustomVariableId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.importer.file.ColumnHeader.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.importer.file.ColumnHeader} returns this
 */
proto.importer.file.ColumnHeader.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional common.ContactVariable mapped_variable = 2;
 * @return {!proto.common.ContactVariable}
 */
proto.importer.file.ColumnHeader.prototype.getMappedVariable = function() {
  return /** @type {!proto.common.ContactVariable} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.ContactVariable} value
 * @return {!proto.importer.file.ColumnHeader} returns this
 */
proto.importer.file.ColumnHeader.prototype.setMappedVariable = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int64 optional_custom_variable_id = 3;
 * @return {number}
 */
proto.importer.file.ColumnHeader.prototype.getOptionalCustomVariableId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.importer.file.ColumnHeader} returns this
 */
proto.importer.file.ColumnHeader.prototype.setOptionalCustomVariableId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.importer.file.ParseFileResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.importer.file.ParseFileResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.importer.file.ParseFileResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.importer.file.ParseFileResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.importer.file.ParseFileResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    headersList: jspb.Message.toObjectList(msg.getHeadersList(),
    proto.importer.file.ColumnHeader.toObject, includeInstance),
    rowNumber: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.importer.file.ParseFileResponse}
 */
proto.importer.file.ParseFileResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.importer.file.ParseFileResponse;
  return proto.importer.file.ParseFileResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.importer.file.ParseFileResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.importer.file.ParseFileResponse}
 */
proto.importer.file.ParseFileResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.importer.file.ColumnHeader;
      reader.readMessage(value,proto.importer.file.ColumnHeader.deserializeBinaryFromReader);
      msg.addHeaders(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRowNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.importer.file.ParseFileResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.importer.file.ParseFileResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.importer.file.ParseFileResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.importer.file.ParseFileResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeadersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.importer.file.ColumnHeader.serializeBinaryToWriter
    );
  }
  f = message.getRowNumber();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated ColumnHeader headers = 1;
 * @return {!Array<!proto.importer.file.ColumnHeader>}
 */
proto.importer.file.ParseFileResponse.prototype.getHeadersList = function() {
  return /** @type{!Array<!proto.importer.file.ColumnHeader>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.importer.file.ColumnHeader, 1));
};


/**
 * @param {!Array<!proto.importer.file.ColumnHeader>} value
 * @return {!proto.importer.file.ParseFileResponse} returns this
*/
proto.importer.file.ParseFileResponse.prototype.setHeadersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.importer.file.ColumnHeader=} opt_value
 * @param {number=} opt_index
 * @return {!proto.importer.file.ColumnHeader}
 */
proto.importer.file.ParseFileResponse.prototype.addHeaders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.importer.file.ColumnHeader, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.importer.file.ParseFileResponse} returns this
 */
proto.importer.file.ParseFileResponse.prototype.clearHeadersList = function() {
  return this.setHeadersList([]);
};


/**
 * optional int32 row_number = 2;
 * @return {number}
 */
proto.importer.file.ParseFileResponse.prototype.getRowNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.importer.file.ParseFileResponse} returns this
 */
proto.importer.file.ParseFileResponse.prototype.setRowNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.importer.file.ImportRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.importer.file.ImportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.importer.file.ImportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.importer.file.ImportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.importer.file.ImportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    fileUid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    campaignId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    batch: jspb.Message.getFieldWithDefault(msg, 4, 0),
    mappedColumnsList: jspb.Message.toObjectList(msg.getMappedColumnsList(),
    proto.importer.file.ColumnHeader.toObject, includeInstance),
    delimiter: jspb.Message.getFieldWithDefault(msg, 6, 0),
    optionalOtherDelimiterValue: jspb.Message.getFieldWithDefault(msg, 7, ""),
    removeDuplicated: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    removeActiveContacts: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    removeContactedInPast: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    optionalContactedPastDays: jspb.Message.getFieldWithDefault(msg, 11, 0),
    removeUnsubscribed: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    importmode: jspb.Message.getFieldWithDefault(msg, 13, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 14, 0),
    organizationId: jspb.Message.getFieldWithDefault(msg, 15, 0),
    pipelineId: jspb.Message.getFieldWithDefault(msg, 16, 0),
    groupContactsByDomain: jspb.Message.getBooleanFieldWithDefault(msg, 17, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.importer.file.ImportRequest}
 */
proto.importer.file.ImportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.importer.file.ImportRequest;
  return proto.importer.file.ImportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.importer.file.ImportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.importer.file.ImportRequest}
 */
proto.importer.file.ImportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileUid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBatch(value);
      break;
    case 5:
      var value = new proto.importer.file.ColumnHeader;
      reader.readMessage(value,proto.importer.file.ColumnHeader.deserializeBinaryFromReader);
      msg.addMappedColumns(value);
      break;
    case 6:
      var value = /** @type {!proto.importer.file.ParsingDelimiter} */ (reader.readEnum());
      msg.setDelimiter(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionalOtherDelimiterValue(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRemoveDuplicated(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRemoveActiveContacts(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRemoveContactedInPast(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOptionalContactedPastDays(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRemoveUnsubscribed(value);
      break;
    case 13:
      var value = /** @type {!proto.common.CustomVariableValueRelatedTo} */ (reader.readEnum());
      msg.setImportmode(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrganizationId(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPipelineId(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGroupContactsByDomain(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.importer.file.ImportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.importer.file.ImportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.importer.file.ImportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.importer.file.ImportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFileUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCampaignId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getBatch();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getMappedColumnsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.importer.file.ColumnHeader.serializeBinaryToWriter
    );
  }
  f = message.getDelimiter();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getOptionalOtherDelimiterValue();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getRemoveDuplicated();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getRemoveActiveContacts();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getRemoveContactedInPast();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getOptionalContactedPastDays();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getRemoveUnsubscribed();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getImportmode();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getOrganizationId();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getPipelineId();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
  f = message.getGroupContactsByDomain();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
};


/**
 * optional string file_uid = 1;
 * @return {string}
 */
proto.importer.file.ImportRequest.prototype.getFileUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.importer.file.ImportRequest} returns this
 */
proto.importer.file.ImportRequest.prototype.setFileUid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 campaign_id = 2;
 * @return {number}
 */
proto.importer.file.ImportRequest.prototype.getCampaignId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.importer.file.ImportRequest} returns this
 */
proto.importer.file.ImportRequest.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 workspace_id = 3;
 * @return {number}
 */
proto.importer.file.ImportRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.importer.file.ImportRequest} returns this
 */
proto.importer.file.ImportRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 batch = 4;
 * @return {number}
 */
proto.importer.file.ImportRequest.prototype.getBatch = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.importer.file.ImportRequest} returns this
 */
proto.importer.file.ImportRequest.prototype.setBatch = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated ColumnHeader mapped_columns = 5;
 * @return {!Array<!proto.importer.file.ColumnHeader>}
 */
proto.importer.file.ImportRequest.prototype.getMappedColumnsList = function() {
  return /** @type{!Array<!proto.importer.file.ColumnHeader>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.importer.file.ColumnHeader, 5));
};


/**
 * @param {!Array<!proto.importer.file.ColumnHeader>} value
 * @return {!proto.importer.file.ImportRequest} returns this
*/
proto.importer.file.ImportRequest.prototype.setMappedColumnsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.importer.file.ColumnHeader=} opt_value
 * @param {number=} opt_index
 * @return {!proto.importer.file.ColumnHeader}
 */
proto.importer.file.ImportRequest.prototype.addMappedColumns = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.importer.file.ColumnHeader, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.importer.file.ImportRequest} returns this
 */
proto.importer.file.ImportRequest.prototype.clearMappedColumnsList = function() {
  return this.setMappedColumnsList([]);
};


/**
 * optional ParsingDelimiter delimiter = 6;
 * @return {!proto.importer.file.ParsingDelimiter}
 */
proto.importer.file.ImportRequest.prototype.getDelimiter = function() {
  return /** @type {!proto.importer.file.ParsingDelimiter} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.importer.file.ParsingDelimiter} value
 * @return {!proto.importer.file.ImportRequest} returns this
 */
proto.importer.file.ImportRequest.prototype.setDelimiter = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string optional_other_delimiter_value = 7;
 * @return {string}
 */
proto.importer.file.ImportRequest.prototype.getOptionalOtherDelimiterValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.importer.file.ImportRequest} returns this
 */
proto.importer.file.ImportRequest.prototype.setOptionalOtherDelimiterValue = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool remove_duplicated = 8;
 * @return {boolean}
 */
proto.importer.file.ImportRequest.prototype.getRemoveDuplicated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.importer.file.ImportRequest} returns this
 */
proto.importer.file.ImportRequest.prototype.setRemoveDuplicated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool remove_active_contacts = 9;
 * @return {boolean}
 */
proto.importer.file.ImportRequest.prototype.getRemoveActiveContacts = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.importer.file.ImportRequest} returns this
 */
proto.importer.file.ImportRequest.prototype.setRemoveActiveContacts = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool remove_contacted_in_past = 10;
 * @return {boolean}
 */
proto.importer.file.ImportRequest.prototype.getRemoveContactedInPast = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.importer.file.ImportRequest} returns this
 */
proto.importer.file.ImportRequest.prototype.setRemoveContactedInPast = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional int32 optional_contacted_past_days = 11;
 * @return {number}
 */
proto.importer.file.ImportRequest.prototype.getOptionalContactedPastDays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.importer.file.ImportRequest} returns this
 */
proto.importer.file.ImportRequest.prototype.setOptionalContactedPastDays = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional bool remove_unsubscribed = 12;
 * @return {boolean}
 */
proto.importer.file.ImportRequest.prototype.getRemoveUnsubscribed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.importer.file.ImportRequest} returns this
 */
proto.importer.file.ImportRequest.prototype.setRemoveUnsubscribed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional common.CustomVariableValueRelatedTo importMode = 13;
 * @return {!proto.common.CustomVariableValueRelatedTo}
 */
proto.importer.file.ImportRequest.prototype.getImportmode = function() {
  return /** @type {!proto.common.CustomVariableValueRelatedTo} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.common.CustomVariableValueRelatedTo} value
 * @return {!proto.importer.file.ImportRequest} returns this
 */
proto.importer.file.ImportRequest.prototype.setImportmode = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional int64 user_id = 14;
 * @return {number}
 */
proto.importer.file.ImportRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.importer.file.ImportRequest} returns this
 */
proto.importer.file.ImportRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int64 organization_id = 15;
 * @return {number}
 */
proto.importer.file.ImportRequest.prototype.getOrganizationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.importer.file.ImportRequest} returns this
 */
proto.importer.file.ImportRequest.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int64 pipeline_id = 16;
 * @return {number}
 */
proto.importer.file.ImportRequest.prototype.getPipelineId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.importer.file.ImportRequest} returns this
 */
proto.importer.file.ImportRequest.prototype.setPipelineId = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional bool group_contacts_by_domain = 17;
 * @return {boolean}
 */
proto.importer.file.ImportRequest.prototype.getGroupContactsByDomain = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.importer.file.ImportRequest} returns this
 */
proto.importer.file.ImportRequest.prototype.setGroupContactsByDomain = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * @enum {number}
 */
proto.importer.file.ParsingDelimiter = {
  AUTO_DELIMITER: 0,
  TAB_DELIMITER: 1,
  COMA_DELIMITER: 2,
  SEMICOLON_DELIMITER: 3,
  SPACE_DELIMITER: 4,
  OTHER_DELIMITER: 5
};

goog.object.extend(exports, proto.importer.file);
