import React, { useState, useRef, useEffect, RefObject } from 'react';

import { ReactEditor } from 'slate-react';

import { urlsVariables } from '@constants/variables';
import Button from '@uikit/Button/Button';
import { EditorSelectionType } from '@ts/common.types';
import useOutsideClick from '@hooks/useOutsideClick';
import prepareHrefUrl from '@utils/prepareHrefUrl';

import Input from '@uikit/Input/Input';
import useAutoFocus from '@hooks/useAutoFocus';
import { SVGIcon } from '@uikit/Icon/Icon';
import ControlledReactTooltip from '@uikit/ControlledReactTooltip/ControlledReactTooltip';
import Display from '@components/Display/Display';

import './EditorChangeLink.scss';

type OnChangeHrefType = (newHref: string, path: number[]) => void;

let style = {};

const EditorChangeLinkComponent = ({
  wrapperRef,
  url,
  onClose,
  disableVarsInLink,
  useApplyButton = true,
}: {
  wrapperRef: RefObject<HTMLDivElement>;
  url: string;
  onClose: (hrefValue: string) => void;
  disableVarsInLink: boolean;
  useApplyButton: boolean;
}): JSX.Element => {
  const inputRef = useRef();
  const [hrefValue, changeHrefValue] = useState('');

  const handleClose = () => onClose(hrefValue);

  useEffect(() => {
    changeHrefValue(url);
  }, [url]);

  useAutoFocus(inputRef);
  useOutsideClick(wrapperRef, handleClose);

  const handleKeyDownOnInput = (e) => {
    if (e.keyCode === 13) {
      onClose(hrefValue);
    }
  };

  return (
    <>
      <div className="editor-change-link__title">Url</div>
      <div className="editor-change-link__body">
        <div className="editor-change-link__link-wrapper">
          <Input
            inputRef={inputRef}
            className="editor-change-link__input"
            value={hrefValue}
            onChange={({ target: { value } }) => changeHrefValue(value)}
            onKeyDown={handleKeyDownOnInput}
          />
          <Display isVisible={!disableVarsInLink}>
            <div
              className="editor-change-link__variables-button"
              data-for="editor-variable-url-select"
              data-tip=""
            >
              <SVGIcon icon="brackets" color="#9A9CA5" />
            </div>
            <ControlledReactTooltip
              id="editor-variable-url-select"
              className="react-tooltip"
              place="bottom"
              effect="solid"
              event="click"
              globalEventOff="click"
              clickable
            >
              <div className="editor-change-link__variables">
                {urlsVariables.map((variable) => (
                  <div
                    key={variable.value}
                    className="editor-change-link__variables-item"
                    onClick={() => changeHrefValue(`@${variable.value}`)}
                  >
                    {variable.title}
                  </div>
                ))}
              </div>
            </ControlledReactTooltip>
          </Display>
        </div>
        <Display isVisible={useApplyButton}>
          <Button className="editor-change-link__apply-btn" onClick={handleClose}>
            Apply
          </Button>
        </Display>
      </div>
    </>
  );
};

const EditorChangeLink = ({
  onChangeHref,
  containerRef,
  editor,
  onClose,
  linkInfo,
  transformLinkUrl,
  disableVarsInLink = false,
  useApplyButton = true,
}: {
  onChangeHref: OnChangeHrefType;
  containerRef: RefObject<HTMLDivElement>;
  editor: ReactEditor;
  onClose: () => void;
  linkInfo: {
    url: string;
    selection: EditorSelectionType;
  };
  transformLinkUrl: (url: string) => string;
  disableVarsInLink?: boolean;
  useApplyButton: boolean;
}): JSX.Element => {
  const wrapperRef = useRef(null);

  try {
    const containerEl = containerRef.current;
    const domRange = ReactEditor.toDOMRange(editor, linkInfo.selection);
    const rect = domRange.getBoundingClientRect();
    const containerRect = containerEl.getBoundingClientRect();

    let left = rect.left + window.pageXOffset - containerRect.left - 30;

    if (left + 430 + containerRect.left > window.innerWidth) {
      left -= left + 430 + containerRect.left - window.innerWidth;
    }

    style = {
      top: `${rect.top + window.pageYOffset + 24 - containerRect.top}px`,
      left: `${left}px`,
    };
  } catch (e) {
    // console.error(e);
  }

  const handleCloseWidget = (hrefValue: string) => {
    // When we just click on link we got path like [number, number]
    // When we select by mouse -> we got path like EditorSelectionType
    if ('focus' in linkInfo.selection) {
      onChangeHref(prepareHrefUrl(hrefValue), linkInfo.selection.focus.path);
    } else {
      onChangeHref(prepareHrefUrl(hrefValue), linkInfo.selection as unknown as [number, number]);
    }

    onClose();
  };

  return (
    <div
      ref={wrapperRef}
      className="editor-change-link"
      style={style}
      onClick={(e) => e.stopPropagation()}
    >
      <EditorChangeLinkComponent
        wrapperRef={wrapperRef}
        onClose={handleCloseWidget}
        url={transformLinkUrl(linkInfo.url)}
        disableVarsInLink={disableVarsInLink}
        useApplyButton={useApplyButton}
      />
    </div>
  );
};

export default EditorChangeLink;
