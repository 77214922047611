// source: mailbox-sender.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
goog.exportSymbol('proto.mailbox_sender.EmailMessageRequest', null, global);
goog.exportSymbol('proto.mailbox_sender.MessageRequestType', null, global);
goog.exportSymbol('proto.mailbox_sender.TestMessage', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mailbox_sender.EmailMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mailbox_sender.EmailMessageRequest.repeatedFields_, null);
};
goog.inherits(proto.mailbox_sender.EmailMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mailbox_sender.EmailMessageRequest.displayName = 'proto.mailbox_sender.EmailMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mailbox_sender.TestMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mailbox_sender.TestMessage.repeatedFields_, null);
};
goog.inherits(proto.mailbox_sender.TestMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mailbox_sender.TestMessage.displayName = 'proto.mailbox_sender.TestMessage';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mailbox_sender.EmailMessageRequest.repeatedFields_ = [4,5,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mailbox_sender.EmailMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mailbox_sender.EmailMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mailbox_sender.EmailMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mailbox_sender.EmailMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    mailboxAccountId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    sendToEmail: jspb.Message.getFieldWithDefault(msg, 3, ""),
    emailsToCcList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    emailsToBccList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    subject: jspb.Message.getFieldWithDefault(msg, 6, ""),
    content: jspb.Message.getFieldWithDefault(msg, 7, ""),
    optionalMessageId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    attachmentsList: jspb.Message.toObjectList(msg.getAttachmentsList(),
    common_pb.FileAttachment.toObject, includeInstance),
    referralLinkEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 10, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mailbox_sender.EmailMessageRequest}
 */
proto.mailbox_sender.EmailMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mailbox_sender.EmailMessageRequest;
  return proto.mailbox_sender.EmailMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mailbox_sender.EmailMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mailbox_sender.EmailMessageRequest}
 */
proto.mailbox_sender.EmailMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMailboxAccountId(value);
      break;
    case 2:
      var value = /** @type {!proto.mailbox_sender.MessageRequestType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSendToEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addEmailsToCc(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addEmailsToBcc(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubject(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionalMessageId(value);
      break;
    case 9:
      var value = new common_pb.FileAttachment;
      reader.readMessage(value,common_pb.FileAttachment.deserializeBinaryFromReader);
      msg.addAttachments(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReferralLinkEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mailbox_sender.EmailMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mailbox_sender.EmailMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mailbox_sender.EmailMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mailbox_sender.EmailMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMailboxAccountId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getSendToEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEmailsToCcList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getEmailsToBccList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getSubject();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getOptionalMessageId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAttachmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      common_pb.FileAttachment.serializeBinaryToWriter
    );
  }
  f = message.getReferralLinkEnabled();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
};


/**
 * optional int64 mailbox_account_id = 1;
 * @return {number}
 */
proto.mailbox_sender.EmailMessageRequest.prototype.getMailboxAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mailbox_sender.EmailMessageRequest} returns this
 */
proto.mailbox_sender.EmailMessageRequest.prototype.setMailboxAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional MessageRequestType type = 2;
 * @return {!proto.mailbox_sender.MessageRequestType}
 */
proto.mailbox_sender.EmailMessageRequest.prototype.getType = function() {
  return /** @type {!proto.mailbox_sender.MessageRequestType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.mailbox_sender.MessageRequestType} value
 * @return {!proto.mailbox_sender.EmailMessageRequest} returns this
 */
proto.mailbox_sender.EmailMessageRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string send_to_email = 3;
 * @return {string}
 */
proto.mailbox_sender.EmailMessageRequest.prototype.getSendToEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox_sender.EmailMessageRequest} returns this
 */
proto.mailbox_sender.EmailMessageRequest.prototype.setSendToEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string emails_to_cc = 4;
 * @return {!Array<string>}
 */
proto.mailbox_sender.EmailMessageRequest.prototype.getEmailsToCcList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.mailbox_sender.EmailMessageRequest} returns this
 */
proto.mailbox_sender.EmailMessageRequest.prototype.setEmailsToCcList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.mailbox_sender.EmailMessageRequest} returns this
 */
proto.mailbox_sender.EmailMessageRequest.prototype.addEmailsToCc = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mailbox_sender.EmailMessageRequest} returns this
 */
proto.mailbox_sender.EmailMessageRequest.prototype.clearEmailsToCcList = function() {
  return this.setEmailsToCcList([]);
};


/**
 * repeated string emails_to_bcc = 5;
 * @return {!Array<string>}
 */
proto.mailbox_sender.EmailMessageRequest.prototype.getEmailsToBccList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.mailbox_sender.EmailMessageRequest} returns this
 */
proto.mailbox_sender.EmailMessageRequest.prototype.setEmailsToBccList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.mailbox_sender.EmailMessageRequest} returns this
 */
proto.mailbox_sender.EmailMessageRequest.prototype.addEmailsToBcc = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mailbox_sender.EmailMessageRequest} returns this
 */
proto.mailbox_sender.EmailMessageRequest.prototype.clearEmailsToBccList = function() {
  return this.setEmailsToBccList([]);
};


/**
 * optional string subject = 6;
 * @return {string}
 */
proto.mailbox_sender.EmailMessageRequest.prototype.getSubject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox_sender.EmailMessageRequest} returns this
 */
proto.mailbox_sender.EmailMessageRequest.prototype.setSubject = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string content = 7;
 * @return {string}
 */
proto.mailbox_sender.EmailMessageRequest.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox_sender.EmailMessageRequest} returns this
 */
proto.mailbox_sender.EmailMessageRequest.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string optional_message_id = 8;
 * @return {string}
 */
proto.mailbox_sender.EmailMessageRequest.prototype.getOptionalMessageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox_sender.EmailMessageRequest} returns this
 */
proto.mailbox_sender.EmailMessageRequest.prototype.setOptionalMessageId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated common.FileAttachment attachments = 9;
 * @return {!Array<!proto.common.FileAttachment>}
 */
proto.mailbox_sender.EmailMessageRequest.prototype.getAttachmentsList = function() {
  return /** @type{!Array<!proto.common.FileAttachment>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.FileAttachment, 9));
};


/**
 * @param {!Array<!proto.common.FileAttachment>} value
 * @return {!proto.mailbox_sender.EmailMessageRequest} returns this
*/
proto.mailbox_sender.EmailMessageRequest.prototype.setAttachmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.common.FileAttachment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.FileAttachment}
 */
proto.mailbox_sender.EmailMessageRequest.prototype.addAttachments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.common.FileAttachment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mailbox_sender.EmailMessageRequest} returns this
 */
proto.mailbox_sender.EmailMessageRequest.prototype.clearAttachmentsList = function() {
  return this.setAttachmentsList([]);
};


/**
 * optional bool referral_link_enabled = 10;
 * @return {boolean}
 */
proto.mailbox_sender.EmailMessageRequest.prototype.getReferralLinkEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mailbox_sender.EmailMessageRequest} returns this
 */
proto.mailbox_sender.EmailMessageRequest.prototype.setReferralLinkEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mailbox_sender.TestMessage.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mailbox_sender.TestMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.mailbox_sender.TestMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mailbox_sender.TestMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mailbox_sender.TestMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    mailboxAccountId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    personId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    scheduleId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    opportunityId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    subject: jspb.Message.getFieldWithDefault(msg, 5, ""),
    content: jspb.Message.getFieldWithDefault(msg, 6, ""),
    optionalToEmail: jspb.Message.getFieldWithDefault(msg, 7, ""),
    attachmentsList: jspb.Message.toObjectList(msg.getAttachmentsList(),
    common_pb.FileAttachment.toObject, includeInstance),
    campaignId: jspb.Message.getFieldWithDefault(msg, 9, 0),
    unsubscribeContentEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    referralLinkEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mailbox_sender.TestMessage}
 */
proto.mailbox_sender.TestMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mailbox_sender.TestMessage;
  return proto.mailbox_sender.TestMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mailbox_sender.TestMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mailbox_sender.TestMessage}
 */
proto.mailbox_sender.TestMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMailboxAccountId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPersonId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setScheduleId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOpportunityId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubject(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionalToEmail(value);
      break;
    case 8:
      var value = new common_pb.FileAttachment;
      reader.readMessage(value,common_pb.FileAttachment.deserializeBinaryFromReader);
      msg.addAttachments(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignId(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUnsubscribeContentEnabled(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReferralLinkEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mailbox_sender.TestMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mailbox_sender.TestMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mailbox_sender.TestMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mailbox_sender.TestMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMailboxAccountId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPersonId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getScheduleId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getOpportunityId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getSubject();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOptionalToEmail();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAttachmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      common_pb.FileAttachment.serializeBinaryToWriter
    );
  }
  f = message.getCampaignId();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getUnsubscribeContentEnabled();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getReferralLinkEnabled();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * optional int64 mailbox_account_id = 1;
 * @return {number}
 */
proto.mailbox_sender.TestMessage.prototype.getMailboxAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mailbox_sender.TestMessage} returns this
 */
proto.mailbox_sender.TestMessage.prototype.setMailboxAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 person_id = 2;
 * @return {number}
 */
proto.mailbox_sender.TestMessage.prototype.getPersonId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mailbox_sender.TestMessage} returns this
 */
proto.mailbox_sender.TestMessage.prototype.setPersonId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 schedule_id = 3;
 * @return {number}
 */
proto.mailbox_sender.TestMessage.prototype.getScheduleId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.mailbox_sender.TestMessage} returns this
 */
proto.mailbox_sender.TestMessage.prototype.setScheduleId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 opportunity_id = 4;
 * @return {number}
 */
proto.mailbox_sender.TestMessage.prototype.getOpportunityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.mailbox_sender.TestMessage} returns this
 */
proto.mailbox_sender.TestMessage.prototype.setOpportunityId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string subject = 5;
 * @return {string}
 */
proto.mailbox_sender.TestMessage.prototype.getSubject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox_sender.TestMessage} returns this
 */
proto.mailbox_sender.TestMessage.prototype.setSubject = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string content = 6;
 * @return {string}
 */
proto.mailbox_sender.TestMessage.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox_sender.TestMessage} returns this
 */
proto.mailbox_sender.TestMessage.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string optional_to_email = 7;
 * @return {string}
 */
proto.mailbox_sender.TestMessage.prototype.getOptionalToEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.mailbox_sender.TestMessage} returns this
 */
proto.mailbox_sender.TestMessage.prototype.setOptionalToEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated common.FileAttachment attachments = 8;
 * @return {!Array<!proto.common.FileAttachment>}
 */
proto.mailbox_sender.TestMessage.prototype.getAttachmentsList = function() {
  return /** @type{!Array<!proto.common.FileAttachment>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.FileAttachment, 8));
};


/**
 * @param {!Array<!proto.common.FileAttachment>} value
 * @return {!proto.mailbox_sender.TestMessage} returns this
*/
proto.mailbox_sender.TestMessage.prototype.setAttachmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.common.FileAttachment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.FileAttachment}
 */
proto.mailbox_sender.TestMessage.prototype.addAttachments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.common.FileAttachment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mailbox_sender.TestMessage} returns this
 */
proto.mailbox_sender.TestMessage.prototype.clearAttachmentsList = function() {
  return this.setAttachmentsList([]);
};


/**
 * optional int64 campaign_id = 9;
 * @return {number}
 */
proto.mailbox_sender.TestMessage.prototype.getCampaignId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.mailbox_sender.TestMessage} returns this
 */
proto.mailbox_sender.TestMessage.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional bool unsubscribe_content_enabled = 10;
 * @return {boolean}
 */
proto.mailbox_sender.TestMessage.prototype.getUnsubscribeContentEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mailbox_sender.TestMessage} returns this
 */
proto.mailbox_sender.TestMessage.prototype.setUnsubscribeContentEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool referral_link_enabled = 11;
 * @return {boolean}
 */
proto.mailbox_sender.TestMessage.prototype.getReferralLinkEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mailbox_sender.TestMessage} returns this
 */
proto.mailbox_sender.TestMessage.prototype.setReferralLinkEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * @enum {number}
 */
proto.mailbox_sender.MessageRequestType = {
  SEND_NEW_EMAIL: 0,
  SEND_REPLY_TO: 1,
  FORWARD_EMAIL: 2
};

goog.object.extend(exports, proto.mailbox_sender);
