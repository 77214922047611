import { useInfiniteQuery, useQueryClient } from 'react-query';
import { QueryPageHookType } from '@ts/common.types';
import { useMemo, useCallback } from 'react';
import { BlackListResponseType } from '@ts/mailboxInbox.types';
import { getAllUnsubscribedPatternsOfOrganizationApi } from '@api/mailbox.api';
import { UnsubscribeFilterType } from '@ts/unsubscribes.types';

/**
 * Calculated number of item for this page
 */
const CURRENT_PAGE_LIMIT = Math.ceil(window.innerHeight / 50);

export default (
  searchQuery: string,
  filters: UnsubscribeFilterType[]
): QueryPageHookType<BlackListResponseType> => {
  const queryClient = useQueryClient();
  const key = useMemo(() => ['unsubscribe-patterns', searchQuery, filters], [searchQuery, filters]);

  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage, refetch } =
    useInfiniteQuery<BlackListResponseType[]>(
      key,
      ({ pageParam = 0 }) =>
        getAllUnsubscribedPatternsOfOrganizationApi(
          pageParam,
          CURRENT_PAGE_LIMIT,
          searchQuery,
          Object.values(filters)
        ),
      {
        getNextPageParam: (lastPage: BlackListResponseType[], allPages) => {
          if (lastPage.length < CURRENT_PAGE_LIMIT) return false;

          return allPages.length;
        },
        retry: 1,
        refetchOnWindowFocus: false,
        staleTime: 30 * 60 * 1000,
      }
    );

  const removeFromCacheById = (id: number) =>
    queryClient.setQueryData(key, ({ pages, pageParams }) => {
      const updatePages = pages.map((page) =>
        page.filter((item: BlackListResponseType) => item.id != id)
      );

      return {
        pageParams,
        pages: updatePages,
      };
    });

  const addToCache = (item: BlackListResponseType) =>
    queryClient.setQueryData(key, ({ pages, pageParams }) => {
      if (pages == null || pages.length === 0) {
        return {
          pageParams,
          pages: [[item]],
        };
      }

      const updatedPages = [...pages];
      updatedPages[updatedPages.length - 1] = [...updatedPages[updatedPages.length - 1], item];

      return {
        pageParams,
        pages: updatedPages,
      };
    });

  const updateInCache = (id: number, payload: BlackListResponseType) =>
    queryClient.setQueryData(key, ({ pages, pageParams }) => {
      const updatePages = pages.map((page) =>
        page.map((item: BlackListResponseType) => {
          if (item.id === id) return payload;
          return item;
        })
      );

      return {
        pageParams,
        pages: updatePages,
      };
    });

  const evictCacheByKey = useCallback(() => {
    queryClient.removeQueries(key);
  }, [queryClient, key]);

  return {
    items: data?.pages?.flat(),
    refetch,
    isLoading,
    isLoadingNextPage: isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    removeItem: removeFromCacheById,
    addItem: addToCache,
    updateItem: updateInCache,
    evictByKey: evictCacheByKey,
  };
};
