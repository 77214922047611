import { WorkspaceShortType } from '@ts/userOrganization.types';
import { WorkspacesStateType } from '@redux/reducers/workspaces.reducer';
import { StateType } from '../reducers';

export const workspacesSelector = ({ workspaces }): WorkspacesStateType => workspaces;

export const getCurrentWorkspaceId = ({ workspaces: { currentWorkspaceUid } }) =>
  currentWorkspaceUid;

export const getCurrentWorkspace = ({
  workspaces: { currentWorkspaceUid, workspaces },
}: StateType): WorkspaceShortType | any =>
  workspaces.find(({ id }) => id === currentWorkspaceUid) || {};
