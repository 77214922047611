import React, { useCallback, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import ReactTooltip from 'react-tooltip';

import { ContentMetricsType, ContentMetricsTypeMap } from 'respona_api/generated/common_pb';
import {
  PeopleWebsiteMetricsRequest,
  PeopleWebsiteMetricsResponse,
  PeopleWebsiteMetricsViewSettingsResponse,
} from 'respona_api/generated/people-website_pb';

import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';

import { SVGIcon } from '@uikit/Icon/Icon';
import Checkbox from '@uikit/Checkbox/Checkbox';
import Loader from '@uikit/Loader/Loader';
import TooltipSearchableContent from '@uikit/TooltipSearchableContent/TooltipSearchableContent';
import SidebarCRMSection from '@components/SidebarCRM/sections/SidebarCRMSection/SidebarCRMSection';
import Metrics from '@components/SidebarCRM/sections/SidebarCRMSectionMetrics/_components/Metrics';

import { IconsMapByType } from '@helpers/opportunitiesHelpers';
import { WebsiteType } from '@ts/company.types';

import './SidebarCRMSectionMetrics.scss';

function SidebarCRMSectionMetrics({
  queryKey,
  queryKeyWebsite,
  index,
  itemId,
  title = 'Metrics',
  isShownWebsiteInfo,
  isOpenSection,
  getWebsite,
  getAllMetricsViewSettings,
  setMetricValue,
  onSuccessSetMetric,
  deleteMetricValue,
  onSuccessDeleteMetricValue,
  showMetricByType,
  onSuccessShowMetricByType,
  loadMetricValueById,
  openUrl,
}: {
  queryKey: string;
  queryKeyWebsite: string;
  index: number;
  itemId: number;
  title: string;
  isShownWebsiteInfo?: boolean;
  isOpenSection?: boolean;
  getWebsite: (itemId: number, workspaceId: number) => Promise<WebsiteType>;
  getAllMetricsViewSettings: (
    itemId: number,
    workspaceId: number
  ) => Promise<PeopleWebsiteMetricsViewSettingsResponse.AsObject>;
  setMetricValue: (data: Partial<PeopleWebsiteMetricsRequest.AsObject>) => Promise<void>;
  onSuccessSetMetric: () => Promise<void>;
  deleteMetricValue: (id: number, workspaceId: number) => Promise<void>;
  onSuccessDeleteMetricValue: () => void;
  showMetricByType: (
    type: ContentMetricsTypeMap[keyof ContentMetricsTypeMap],
    companyId: number,
    workspaceId: number,
    enabled: boolean
  ) => Promise<PeopleWebsiteMetricsResponse.AsObject>;
  onSuccessShowMetricByType: () => void;
  loadMetricValueById: (
    id: number,
    type: ContentMetricsTypeMap[keyof ContentMetricsTypeMap],
    companyId: number,
    workspaceId: number
  ) => Promise<PeopleWebsiteMetricsResponse.AsObject>;
  openUrl?: string;
}) {
  const ref = useRef(null);
  const workspaceId = useCurrentWorkspaceId();
  const tooltipRef = useRef(null);
  const [queryEnabled, setQueryEnabled] = useState<boolean>(true);

  const preparedQuery = [queryKey, itemId];

  const { data: website, isFetching } = useQuery<WebsiteType>(
    [queryKeyWebsite, itemId, workspaceId],
    () => getWebsite(itemId, workspaceId),
    {
      enabled: queryEnabled && !!itemId,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: metricsSettings,
    isFetching: isLoadingMetricsSetting,
    refetch: refetchMetricsSettings,
  } = useQuery<PeopleWebsiteMetricsViewSettingsResponse.AsObject>(
    [...preparedQuery, 'settings'],
    () => getAllMetricsViewSettings(itemId, workspaceId),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      staleTime: 15 * 60 * 1000,
    }
  );

  const showMetricsMutation = useMutation<
    PeopleWebsiteMetricsResponse.AsObject,
    unknown,
    { type: ContentMetricsTypeMap[keyof ContentMetricsTypeMap]; enabled: boolean }
  >(({ type, enabled }) => showMetricByType(type, itemId, workspaceId, enabled), {
    onSuccess: onSuccessShowMetricByType,
  });

  const loadMetricsMutation = useMutation<
    PeopleWebsiteMetricsResponse.AsObject,
    unknown,
    { id: number; type: ContentMetricsTypeMap[keyof ContentMetricsTypeMap] }
  >(({ id, type }) => loadMetricValueById(id, type, itemId, workspaceId));

  const setMetricsMutation = useMutation<
    void,
    unknown,
    { data: PeopleWebsiteMetricsRequest.AsObject }
  >(({ data }) => setMetricValue(data), {
    onSuccess: onSuccessSetMetric,
  });

  const deleteMetricsMutation = useMutation<
    void,
    unknown,
    { id: number; type: ContentMetricsTypeMap[keyof ContentMetricsTypeMap] }
  >(({ id }) => deleteMetricValue(id, itemId), {
    onSuccess: onSuccessDeleteMetricValue,
  });

  const onOpenCallback = useCallback(() => {
    setQueryEnabled(true);
  }, []);

  if (!itemId) {
    return null;
  }

  const metricTypes: { type: ContentMetricsTypeMap[keyof ContentMetricsTypeMap]; title: string }[] =
    [];

  if (metricsSettings?.mozEnabled) {
    metricTypes.push({
      type: ContentMetricsType.MOZ_METRICS,
      title: 'Moz metrics',
    });
  }

  if (metricsSettings?.ahrefEnabled) {
    metricTypes.push({
      type: ContentMetricsType.AHREFS_METRICS,
      title: 'Ahrefs metrics',
    });
  }

  if (metricsSettings?.semrushEnabled) {
    metricTypes.push({
      type: ContentMetricsType.SEMRUSH_METRICS,
      title: 'Semrush metrics',
    });
  }

  return (
    <SidebarCRMSection
      ref={ref}
      index={index}
      id="metrics"
      title={title}
      forceOpen={isOpenSection && !!website?.metricsList.length}
      onOpen={onOpenCallback}
      rightComponent={
        <div
          ref={tooltipRef}
          data-for="sidebar-crm-metrics"
          data-tip=""
          className="contact-sidebar-crm__content__add-btn"
          onClick={(e) => {
            e.stopPropagation();
            refetchMetricsSettings().then(() => ReactTooltip.show(tooltipRef.current));
          }}
        >
          {isLoadingMetricsSetting ? (
            <div className="contacts-sidebar-section-metrics__refresh-icon--animated">
              <SVGIcon icon="refresh" size={12} color="#221CB6" />
            </div>
          ) : (
            <>
              <SVGIcon icon="plusIcon" size={10} color="#221CB6" />
              Add
            </>
          )}
        </div>
      }
    >
      <Loader isLoading={!website || isFetching}>
        {website?.metricsList.length && (
          <div className="contacts-sidebar-section-metrics">
            <div className="contacts-sidebar-section-metrics-header">
              {isShownWebsiteInfo ? (
                <>
                  <div
                    className="contacts-sidebar-section-metrics-header-title"
                    onClick={() => openUrl && window.open(openUrl, '_blank')}
                  >
                    {website?.title}
                  </div>
                  <div className="contacts-sidebar-section-metrics-header-link">
                    <SVGIcon icon="link" size={13} />
                    {website?.domain}
                  </div>
                </>
              ) : null}
              {website?.metricsList
                .slice()
                .sort((a, b) => b.type - a.type)
                .map((item) => (
                  <Metrics
                    key={item.id}
                    item={item}
                    onDelete={() => deleteMetricsMutation.mutate({id: item.id, type: item.type})}
                    onRefresh={() => loadMetricsMutation.mutate({id: item.id, type: item.type})}
                    isRefreshing={
                      loadMetricsMutation.isLoading &&
                      loadMetricsMutation.variables?.type === item.type
                    }
                    onSave={(data) => {
                      setMetricsMutation.mutate({
                        data: {...data, websiteId: itemId, workspaceId},
                      });
                    }}
                  />
                ))}
            </div>
          </div>
        )}
      </Loader>

      <ReactTooltip
        id="sidebar-crm-metrics"
        className="react-tooltip contacts-sidebar-section-metrics__tooltip"
        place="left"
        event="none"
        effect="float"
        globalEventOff="click"
        arrowColor="transparent"
        bodyMode={false}
        clickable
      >
        <TooltipSearchableContent
          title="Add Metrics"
          addButtonText="+ Connect other sources"
          customCreateAction={() => window.open('/settings/integrations', '_self')}
          searchDisabled
        >
          <div className="tooltip-searchable-content-items">
            {metricTypes.map((metricType) => (
              <div
                key={metricType.type.toString()}
                className="contacts-sidebar-section-metrics__metric-options-row"
              >
                <div className="contacts-sidebar-section-metrics__metric-options-row-icon">
                  <img src={IconsMapByType[metricType.type]} alt="metrics-logo"/>
                </div>
                <span>{metricType.title}</span>
                <Checkbox
                  onChange={() =>
                    showMetricsMutation.mutate({
                      type: metricType.type,
                      enabled: !website?.metricsList.some((item) => item.type === metricType.type),
                    })
                  }
                  value={website?.metricsList.some((item) => item.type === metricType.type)}
                  isLoading={
                    showMetricsMutation.isLoading &&
                    showMetricsMutation.variables?.type === metricType.type
                  }
                />
              </div>
            ))}
          </div>
        </TooltipSearchableContent>
      </ReactTooltip>
    </SidebarCRMSection>
  );
}

export default SidebarCRMSectionMetrics;
