import React, { useMemo, useState } from 'react';
import { differenceInCalendarDays } from 'date-fns';
import cn from 'class-names';

import './PipelineDealCard.scss';
import Avatar from '@uikit/Avatar/Avatar';
import { SVGIcon } from '@uikit/Icon/Icon';
import { Button } from '@uikit/Button/Button';

declare const IS_DEV: boolean;

function PipelineDealCard({
  id,
  name,
  website,
  logoUrl,
  showTargetTime,
  targetTime,
  daysLimit,
  selectCard,
  assignedToUser,
  onClick,
  onRemoveClick,
  order,
}): JSX.Element {
  const daysDiff = useMemo(
    () => differenceInCalendarDays(new Date(), new Date(targetTime)),
    [targetTime]
  );
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const handleTrashClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    onRemoveClick(id);
  };

  return (
    <div className="pipeline-deal-card" onClick={onClick} data-opportunity-id={id}>
      <div className="pipeline-deal-card__header">
        {isConfirmOpen ? (
          <div className="pipeline-deal-card__header-confirmation">
            <Button
              className="pipeline-deal-card__header-confirm"
              type="ghost"
              leftIcon="checkCircled"
              colorIcon="#23E771"
              iconSize={25}
              onClick={(e) => {
                handleTrashClick(e);
              }}
            />

            <Button
              className="pipeline-deal-card__header-cancel"
              type="ghost"
              leftIcon="crossDelete"
              colorIcon="#ED483D"
              iconSize={29}
              onClick={(e) => {
                setIsConfirmOpen(false);
                e.stopPropagation();
              }}
            />
          </div>
        ) : (
          <div className="pipeline-deal-card__website-info">
            <Avatar
              url={logoUrl}
              size="sm"
              fullName={website}
              wrapperClassName="pipeline-deal-card__website-info-logo"
            />
            <div className="pipeline-deal-card__website-info-container">
              <div className="pipeline-deal-card__website-name">{name}</div>
              <div className="pipeline-deal-card__website">{website}</div>
            </div>
            {/*{IS_DEV && (*/}
            {/*  <div className="pipeline-deal-card__website-info-counter">*/}
            {/*    ID: {id}*/}
            {/*    <br />*/}
            {/*    Order: {order}*/}
            {/*  </div>*/}
            {/*)}*/}
          </div>
        )}
        <Button
          className="pipeline-deal-card__header-delete"
          type="ghost"
          leftIcon="trash"
          colorIcon="#ed483d"
          onClick={(e) => {
            setIsConfirmOpen(true);
            e.stopPropagation();
          }}
        />
      </div>
      <div className="pipeline-deal-card__footer">
        <div className="pipeline-deal-card__user-info">
          <Avatar size="xs" url={assignedToUser?.logoUrl} fullName={assignedToUser?.label} />
          <div className="pipeline-deal-card__user-name">{assignedToUser?.label}</div>
        </div>

        {showTargetTime && (
          <div
            className={cn('pipeline-deal-card__footer-timing', {
              'pipeline-deal-card__footer-timing--red': daysDiff >= daysLimit,
            })}
          >
            <SVGIcon icon="clockThree" color="#C4C6CD" />
            <span>{daysDiff}d</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default PipelineDealCard;
