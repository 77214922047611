import React from 'react';

export default ({ color = '#ED483D', size = 16 }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.00065 14.6663C4.31865 14.6663 1.33398 11.6817 1.33398 7.99968C1.33398 4.31767 4.31865 1.33301 8.00065 1.33301C11.6827 1.33301 14.6673 4.31767 14.6673 7.99968C14.6673 11.6817 11.6827 14.6663 8.00065 14.6663ZM8.00065 7.05701L6.11532 5.17101L5.17198 6.11434L7.05799 7.99968L5.17198 9.88501L6.11532 10.8283L8.00065 8.94234L9.88599 10.8283L10.8293 9.88501L8.94332 7.99968L10.8293 6.11434L9.88599 5.17101L8.00065 7.05701Z"
      fill={color}
    />
  </svg>
);
