import { Descendant, Editor, Element as SlateElement, Range, Selection, Transforms } from 'slate';

type LinkElementType = { type: 'link'; url: string; children: Descendant[] };
type ChangeLink = (options: { url: string; selection: Selection }) => void;

export const insertLink = (editor: Editor, onChangeLink: ChangeLink): void => {
  if (editor.selection) {
    if (editor.selection?.anchor?.offset === editor.selection?.focus?.offset) {
      return;
    }

    wrapLink(editor, '');
    setTimeout(() => {
      onChangeLink({
        url: '',
        selection: editor.selection,
      });
    }, 0);
  }
};

export const isLinkActive = (editor: Editor): boolean => {
  let link = false;
  try {
    const [linkTemp] = Editor.nodes(editor, {
      // @ts-ignore
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
    });
    link = !!linkTemp;
  } catch (err) {}

  return link;
};

export const wrapLink = (editor: Editor, url: string): void => {
  const { selection } = editor;

  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const isCollapsed = selection && Range.isCollapsed(selection);
  const link: LinkElementType = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: url }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
};

export const unwrapLink = (editor: Editor): void => {
  Transforms.unwrapNodes(editor, {
    // @ts-ignore
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  });
};
