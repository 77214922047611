import React, { useRef } from 'react';
import ReactTooltip from 'react-tooltip';

import Button from 'src/uikit/Button/Button';
import { SVGIcon } from 'src/uikit/Icon/Icon';
import { IMoreActions } from '@components/HeaderTemplate/HeaderTemplate.model';

import './MoreActionsButton.scss';

type MoreActionsButtonPropsType = {
  items: IMoreActions[];
};

function MoreActionsButton({ items }: MoreActionsButtonPropsType): JSX.Element {
  const moreActionsTooltipRef = useRef();
  const handleMoreActionsClick = () => ReactTooltip.show(moreActionsTooltipRef.current);

  return (
    <>
      <Button
        innerRef={moreActionsTooltipRef}
        onClick={handleMoreActionsClick}
        data-for="organizations-more-actions"
        type="additional"
        className="organizations-header-actions__more-actions-btn"
        data-tip=""
        data-event="click"
      >
        <SVGIcon icon="actions" />
        More actions
      </Button>
      <ReactTooltip
        id="organizations-more-actions"
        className="react-tooltip"
        place="bottom"
        effect="solid"
        globalEventOff="click"
        arrowColor="transparent"
        clickable
      >
        <div
          className="organizations-header-more-actions-tooltip"
          onClick={(e) => e.stopPropagation()}
        >
          {items.map(({ title, handler, iconName }, index) => {
            return (
              <div
                key={index}
                className="organizations-header-more-actions-tooltip__action"
                onClick={() => handler()}
              >
                <SVGIcon icon={iconName} color="#ffffff" size={18} />
                {title}
              </div>
            );
          })}
        </div>
      </ReactTooltip>
    </>
  );
}

export default MoreActionsButton;
