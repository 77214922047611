import { DailyStatisticsType } from '@ts/insights.types';

// Only 2021-11-21 format to input
export const sortByDateString = (a: DailyStatisticsType, b: DailyStatisticsType): number => {
  // @ts-ignore
  const x = Date.parse(new Date(a.date));
  // @ts-ignore
  const z = Date.parse(new Date(b.date));

  if (x < z) {
    return -1;
  }
  if (x > z) {
    return 1;
  }
  // a must be equal to b
  return 0;
};
