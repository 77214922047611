import React, { useLayoutEffect, useRef, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useQueryClient } from 'react-query';
import { nanoid } from 'nanoid';

import urls from '@constants/urls';
import stripHtml from '@utils/stripHtml';

import { addNotification } from '@redux/actions/notifications.actions';

import { TemplateStepTypeWithTempId } from '@ts/template.types';
import { saveTemplateWithStepsByQueryClient } from '@queries/templates.queries';

import Input from '@uikit/Input/Input';

import SequenceEditorWithoutAutosave from '@components/CampaignSettings/SequenceEditor/SequenceEditorWithoutAutosave';
import PageHeader from '@components/PageHeader/PageHeader';

import SequenceStepsWithoutAutosave from '@components/CampaignSettings/SequenceSteps/SequenceStepsWithoutAutosave';
import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';

import { formatSameThread, TEMPLATE_STEP } from '@pages/TemplateEditorSettingPage/helpers';

import SaveButton from './_components/SaveButton';

import './TemplateEditorSettingPage.scss';

const TemplateEditorSettingPage = (): JSX.Element => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { push } = useHistory();
  const inputRef = useRef<HTMLInputElement>();

  const [openedStepIndex, changeOpenedStepIndex] = useState<number>(0);

  const [isSaveButtonDisabled, changeSaveButtonDisabled] = useState<boolean>(false);

  const [templateTitle, changeTemplateTitle] = useState<string>('');
  const [stepsNewState, changeStepsNewState] = useState<TemplateStepTypeWithTempId[]>([
    { ...TEMPLATE_STEP, sendDelay: 0 },
  ]);

  const currentWorkspaceId = useCurrentWorkspaceId();

  useLayoutEffect(() => {
    inputRef.current.focus();
  }, []);

  const handleCreateStep = () => {
    const stepsList = Object.values(stepsNewState);

    const lastStep = stepsList[stepsList.length - 1];

    if (lastStep) {
      if (stepsList.length === 1) {
        if (
          stripHtml(lastStep.threadA.content).trim() === '' &&
          stripHtml(lastStep.threadA.subject).trim() === ''
        ) {
          dispatch(
            addNotification({ title: 'Please, fill step content or subject', type: 'error' })
          );
          return;
        }
      }
    }

    const creatingStep: TemplateStepTypeWithTempId = {
      ...TEMPLATE_STEP,
      order: stepsList.length + 1,
      tempId: nanoid(),
    };

    changeStepsNewState((prevState) => {
      return [...prevState, creatingStep];
    });

    changeOpenedStepIndex(stepsList.length);
  };

  const handleRemove = () => {
    if (openedStepIndex !== 0) {
      changeOpenedStepIndex(openedStepIndex - 1);
    }

    changeStepsNewState((prevState) => {
      // TODO: refactor this shit, we did it
      const realIds: number[] = prevState.reduce((acc, { id }) => {
        if (id) {
          acc.push(id);
        }
        return acc;
      }, [] as number[]);

      return (
        prevState
          .filter((step, index) => index !== openedStepIndex)
          // Order begans from 1
          .map((step, index) => ({
            ...step,
            order: index + 1,
            id: realIds[index] ? realIds[index] : step.id,
          }))
      );
    });
  };

  const handleUpdateTemplateStep = (step: TemplateStepTypeWithTempId) => {
    changeStepsNewState((prevState) =>
      prevState.map((oldStep) => (oldStep.tempId === step.tempId ? step : oldStep))
    );
  };

  const handleSaveChangesToServer = () => {
    changeSaveButtonDisabled(true);

    // scope === 1, because we save it for organization
    // templateId === -1, because we will create on back new template
    saveTemplateWithStepsByQueryClient(
      queryClient,
      -1,
      currentWorkspaceId,
      templateTitle,
      1,
      formatSameThread(stepsNewState)
    )
      .then((res) => {
        changeSaveButtonDisabled(false);
        dispatch(addNotification({ title: 'Template saved', type: 'success' }));
        push(`${urls.TEMPLATES}/${res.id}`)
      })
      .catch(() => {
        changeSaveButtonDisabled(false);
      });
  };

  return (
    <>
      <div className="template-editor-page">
        <div className="template-editor-page__header">
          <PageHeader title="Templates" withBackArrow>
            <div className="template-editor-page__header-btns">
              <SaveButton
                stepsNewState={stepsNewState}
                isTemplateTitle={!!templateTitle}
                isSaveButtonDisabled={isSaveButtonDisabled}
                onSaveChangesToServer={handleSaveChangesToServer}
              />
            </div>
          </PageHeader>
        </div>
        <div className="template-editor-page__body">
          <div className="template-editor-page__sequence-editor">
            <div className="template-editor-page__steps">
              <div className="template-editor-page__title-input-wrapper">
                <Input
                  inputRef={inputRef}
                  value={templateTitle}
                  onChange={({ target: { value } }) => changeTemplateTitle(value)}
                  icon="documentIcon"
                  placeholder="Enter Template Name"
                />
              </div>
              <SequenceStepsWithoutAutosave
                openedStepIndex={openedStepIndex}
                sequenceSteps={stepsNewState.sort((a, b) => a.order - b.order)}
                onOpenStep={changeOpenedStepIndex}
                onChangeDelay={handleUpdateTemplateStep}
                onCreateStep={handleCreateStep}
              />
            </div>
            <div className="template-editor-page__editor">
              <SequenceEditorWithoutAutosave
                key={stepsNewState[openedStepIndex] && stepsNewState[openedStepIndex].id}
                onRemove={handleRemove}
                sequence={stepsNewState[openedStepIndex]}
                stepIndex={openedStepIndex}
                onSaveStep={handleUpdateTemplateStep}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TemplateEditorSettingPage;
