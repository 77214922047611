import React from 'react';

export default ({ color = '#C4C6CD' }) => {
  return (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.14469 11.8653L6.53556 1.67418C6.64324 1.47066 6.80437 1.30035 7.00161 1.18156C7.19885 1.06277 7.42475 1 7.655 1C7.88525 1 8.11114 1.06277 8.30838 1.18156C8.50563 1.30035 8.66676 1.47066 8.77444 1.67418L14.1653 11.8653C14.2665 12.0576 14.3161 12.2729 14.3094 12.4901C14.3027 12.7073 14.2398 12.9191 14.1268 13.1048C14.0139 13.2904 13.8548 13.4437 13.6651 13.5496C13.4753 13.6555 13.2614 13.7104 13.044 13.7091H2.26353C2.04642 13.71 1.83276 13.6548 1.64334 13.5487C1.45391 13.4426 1.29516 13.2893 1.18253 13.1037C1.0699 12.9181 1.00722 12.7065 1.00059 12.4895C0.993956 12.2725 1.0436 12.0575 1.14469 11.8653V11.8653Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.65381 5.23779V8.86841"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.65375 11.8939C8.15503 11.8939 8.5614 11.4876 8.5614 10.9863C8.5614 10.485 8.15503 10.0786 7.65375 10.0786C7.15246 10.0786 6.74609 10.485 6.74609 10.9863C6.74609 11.4876 7.15246 11.8939 7.65375 11.8939Z"
        fill={color}
      />
    </svg>
  );
};
