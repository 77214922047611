import { createSelector } from 'reselect';

import { StateType } from '@redux/reducers';

export const commonInfoSelector = ({ commonInfo }: StateType) => commonInfo;

export const audioGenresSelector = createSelector(
  [commonInfoSelector],
  ({ audioGenres }) => audioGenres
);
