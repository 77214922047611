import React from 'react';

import './ColoredLabel.scss';

export default ({ classNames, color, fontSize, position, children }: { classNames?: string, color: string, fontSize: string, position: string, children: string | JSX.Element }): JSX.Element => {
  let classes = 'label';

  if (classNames) {
    classes += classNames;
  }

  if (color) {
    classes += ` label-color-${color}`;
  }

  if (fontSize) {
    classes += ` label-font-size-${fontSize}`;
  }

  if (position) {
    classes += ` label-position-${position}`;
  }

  return <label className={classes}>{children}</label>;
};
