import { handleActions } from 'redux-actions';

import loadingStatuses from '@constants/loadingStatuses';
import { IntegrationType } from '@ts/userOrganization.types';

import { updateItemInListByFieldNameHelper } from '@helpers/commonHelpers';

import {
  connectIntegration,
  updateIntegration,
  deleteIntegration,
  fetchIntegrations,
  setIntegrations,
} from '@redux/actions/integrations.actions';

export type IntegrationsStateType = {
  integrations: IntegrationType[];
  loadingStatus: string;
};

const initialState: IntegrationsStateType = {
  integrations: [],
  loadingStatus: loadingStatuses.PENDING,
};

export default handleActions(
  {
    [fetchIntegrations]: (state: IntegrationsStateType) => ({
      ...state,
      loadingStatus: loadingStatuses.INITED,
    }),
    [setIntegrations]: (state: IntegrationsStateType, { payload }) => ({
      ...state,
      integrations: payload,
      loadingStatus: loadingStatuses.LOADED,
    }),
    [connectIntegration]: (state: IntegrationsStateType, { payload }) => ({
      ...state,
      integrations: updateItemInListByFieldNameHelper(state.integrations, payload, 'title'),
    }),
    [updateIntegration]: (state: IntegrationsStateType, { payload }) => ({
      ...state,
      integrations: updateItemInListByFieldNameHelper(state.integrations, payload, 'title'),
    }),
    [deleteIntegration]: (state: IntegrationsStateType, { payload }) => ({
      ...state,
      integrations: updateItemInListByFieldNameHelper(state.integrations, payload, 'title'),
    }),
  },
  initialState
);
