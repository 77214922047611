import React from 'react';
import { useSelector } from 'react-redux';

import PageHeader from '@components/PageHeader/PageHeader';
import Profile from '@components/Settings/Profile/Profile';
import Loader from '@uikit/Loader/Loader';
import { checkIsAnyNotLoaded } from '@utils/loadingChecks';

import { userProfileSelector } from '@redux/selectors/userProfile.selector';

const ProfileSettings = () => {
  const { info: profile, loadingStatus: profileLs } = useSelector(userProfileSelector);

  return (
    <>
      <PageHeader title="Profile" />
      <Loader isLoading={checkIsAnyNotLoaded(profileLs)} withTopMargin>
        <Profile profile={profile} />
      </Loader>
    </>
  );
};

export default ProfileSettings;
