/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import { components } from 'react-select';
import { useHistory } from 'react-router-dom';

import Select from '@uikit/Select/Select';
import { SVGIcon } from '@uikit/Icon/Icon';
import Button from '@uikit/Button/Button';
import stripHtml from '@utils/stripHtml';
import urls from '@constants/urls';
import { TemplateType } from '@ts/template.types';
import TemplateSelectStepsInfo from './_components/TemplateSelectStepsInfo/TemplateSelectStepsInfo';

import './TemplateSelect.scss';
// box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);

const selectStyles = {
  control: (provided) => ({
    ...provided,
    height: '40px',
    borderRadius: '8px',
    border: '1px solid #e9ecf1',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.04)',
    cursor: 'pointer',

    '&:focus,&:hover': {
      outline: 'none',
      border: '1px solid #221CB6',
      boxShadow: 'none',
      background: '#EEF3FF',

      'svg *': {
        stroke: '#221CB6',
      },
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    right: 0,
    left: 0,
    textAlign: 'center',
    padding: '9px 0',

    '&:focus,&:hover': {
      color: '#221CB6',
    },
  }),
};

const Option = ({ children, ...restProps }) => {
  const title = stripHtml(restProps.data.title);
  const content = stripHtml(restProps.data.subject).substr(0, 50);

  return (
    <components.Option {...restProps}>
      <div className="template-select__option">
        <SVGIcon icon="sequence" color="#FFFFFF" />
        <div className="template-select__option-text-wrapper">
          <div className="template-select__title" title={title}>
            {title}
          </div>
          <div className="template-select__sub-title" title={content}>
            {content.length > 0 ? content : '<Empty content>'}
          </div>
        </div>
      </div>
    </components.Option>
  );
};

const Menu = (props) => {
  const history = useHistory();

  const redirectToManageSequences = () => history.push(urls.TEMPLATES);

  return (
    <>
      <components.Menu {...props}>
        <div className="template-select__menu">
          <TemplateSelectStepsInfo {...props.selectProps.stepsInfoPopup} />
          <div className="template-select__header">Sequences</div>
          <div>{props.children}</div>
          <div className="template-select__footer">
            <Button
              type="ghost"
              className="template-select__footer-button"
              onClick={redirectToManageSequences}
            >
              Manage sequences
            </Button>
            <Button
              type="ghost"
              className="template-select__footer-button"
              onClick={props.selectProps.onSaveTemplate}
            >
              Save sequence
            </Button>
          </div>
        </div>
      </components.Menu>
    </>
  );
};

const Placeholder = () => (
  <span className="template-select__placeholder">
    <span className="template-select__placeholder-icon">
      <SVGIcon icon="folders" />
    </span>{' '}
    Templates
  </span>
);

const transformTemplatesToOptions = (templates) =>
  templates.map((template) => ({
    ...template,
    label: template.title,
    value: template.id,
  }));

const TemplateSelect = ({
  templates,
  onSaveTemplate,
  onApplyTemplate,
}: {
  templates: TemplateType[];
  onSaveTemplate: (any) => void;
  onApplyTemplate: (templateId: number) => void;
}): JSX.Element => {
  const [stepsInfoPopup, changeStepsInfoPopup] = useState({
    isShown: false,
    stepsList: [],
  });
  const [placeholder, setPlaceholder] = useState(<Placeholder />);

  const handleSelect = ({ id }) => onApplyTemplate(id);

  const handleStepsInfoShow = () => {
    changeStepsInfoPopup((prevState) => ({
      ...prevState,
      isShown: true,
    }));
  };

  const handleStepsInfoHide = () => {
    changeStepsInfoPopup((prevState) => ({
      ...prevState,
      isShown: false,
    }));
  };

  return (
    <div className="template-select">
      <Select
        value={null}
        onChange={handleSelect}
        onFocus={() =>
          setPlaceholder(<div style={{ textAlign: 'left', marginLeft: '10px' }}>Search</div>)
        }
        onBlur={() => setPlaceholder(<Placeholder />)}
        options={
          templates ? transformTemplatesToOptions(templates.filter(({ subject }) => !!subject)) : []
        }
        additionalComponents={{ Menu, Option, DropdownIndicator: null }}
        placeholder={placeholder}
        additionalStyles={selectStyles}
        onSaveTemplate={onSaveTemplate}
        stepsInfoPopup={stepsInfoPopup}
        onStepsInfoShow={handleStepsInfoShow}
        onStepsInfoHide={handleStepsInfoHide}
      />
    </div>
  );
};

export default TemplateSelect;
