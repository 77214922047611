import React from 'react';

import Select from '@uikit/Select/Select';

type SelectRawValueType = { value: number; label: string };
type SelectRawType = {
  label: string;
  options: SelectRawValueType[];
  value: SelectRawValueType;
  onChange: (value: SelectRawValueType) => void;
};

const SelectRaw: React.FC<SelectRawType> = ({ label, options, value, onChange }) => {
  return (
    <div className="select-raw">
      <span className="select-raw__label">{label}</span>
      <div className="select-raw__select-wrapper">
        <Select
          onChange={onChange}
          options={options}
          value={value}
          additionalStyles={{
            control: (provided) => {
              return {
                ...provided,
                borderRadius: '8.5px',
                height: '48px',
                fontSize: '16px',
                '&:focus,&:hover': {
                  outline: 'none',
                  border: '1px solid #221CB6',
                  boxShadow: 'none',
                },
              };
            },
          }}
        />
      </div>
    </div>
  );
};

export default SelectRaw;
