import React from 'react';

export default () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_16269_5460)">
      <path
        d="M17.3921 5.90682H14.4043C14.4854 5.43745 14.7743 5.17565 15.3611 4.8778L15.9118 4.59792C16.8957 4.09252 17.4191 3.52377 17.4191 2.59397C17.4191 2.00725 17.1935 1.54686 16.7422 1.21288C16.2999 0.878892 15.7583 0.716431 15.1355 0.716431C14.6389 0.716431 14.1876 0.84272 13.7723 1.10452C13.3662 1.35733 13.0592 1.68225 12.8697 2.08842L13.7363 2.95514C14.0703 2.27807 14.5577 1.94409 15.1987 1.94409C15.7402 1.94409 16.0742 2.22397 16.0742 2.61206C16.0742 2.93706 15.9118 3.20784 15.2889 3.52377L14.9369 3.6953C14.1695 4.08338 13.637 4.52573 13.33 5.03128C13.0232 5.52772 12.8697 6.16862 12.8697 6.93596V7.14354H17.3921V5.90682ZM16.9927 8.57178H12.0451L9.57129 12.8565H14.5189L16.9927 17.1412L19.4665 12.8565L16.9927 8.57178Z"
        fill="#FF492C"
      />
      <path
        d="M9.99769 15.713C6.84758 15.713 4.28477 13.1502 4.28477 10.0001C4.28477 6.84996 6.84758 4.28711 9.99769 4.28711L11.9536 0.194178C11.3095 0.0664658 10.6544 0.00222614 9.99769 0.00238065C4.47613 0.00238065 0 4.47851 0 10.0001C0 15.5216 4.47613 19.9978 9.99769 19.9978C12.1991 19.9978 14.234 19.2857 15.8856 18.08L13.7198 14.3287C12.7191 15.1903 11.4187 15.713 9.99769 15.713Z"
        fill="#FF492C"
      />
    </g>
    <defs>
      <clipPath id="clip0_16269_5460">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
