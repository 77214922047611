import {
  SummaryContainerResponse,
  SummaryExtractionProcess,
} from 'respona_api/generated/search-summary_pb';

import processStreamRequest from '@api/base/processStreamRequest';
import request from '@api/base/request';

import searchSummaryService from '@api/services/searchSummaryService';
import * as common_pb from 'respona_api/generated/common_pb';

export const getSummariesApi = (
  url: string,
  workspaceId: number
): Promise<SummaryContainerResponse.AsObject[]> => {
  const client = searchSummaryService.getInstance();
  const summariesRequest = new common_pb.StringRequest();

  summariesRequest.setValue(url);

  return request<SummaryContainerResponse.AsObject[]>(
    client,
    client.getSummariesByUrl,
    summariesRequest
  );
};

export const searchSummariesApi = (
  url: string,
  workspaceId: number,
  processCallback
): Promise<SummaryExtractionProcess.AsObject[]> => {
  const client = searchSummaryService.getInstance();
  const summariesRequest = new common_pb.StringRequest();

  summariesRequest.setValue(url);

  return processStreamRequest<SummaryExtractionProcess.AsObject[]>(
    client,
    client.searchSummariesByUrl,
    summariesRequest,
    processCallback
  );
};
