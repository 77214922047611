import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryClient } from 'react-query';

import { getBlobFromBase64 } from '@utils/createFileFromBytesArray';
import downloadCSV from '@utils/downloadCSV';

import { BlackListResponseType } from '@ts/mailboxInbox.types';

import { getCurrentWorkspaceId } from '@redux/selectors/workspaces.selectors';

import { addNotification } from '@redux/actions/notifications.actions';

import { removeBlackListPatternBulkApi, exportBlackListPatternsToCsvApi } from '@api/mailbox.api';

import Loader from '@uikit/Loader/Loader';

import omit from '@utils/omit';
import UnsubscribeSidebar from '@components/Settings/UnsubscribeSidebar/UnsubscribeSidebar';
import SimpleActionSidebar from '@components/Relationships/SimpleActionSidebar/SimpleActionSidebar';
import UnsubscribeListTable from '@components/Settings/UnsubscribeListTable/UnsubscribeListTable';
import UnsubscribedPageActions from '@components/Settings/UnsubscribedPageActions/UnsubscribedPageActions';
import PageHeader from '@components/PageHeader/PageHeader';
import { UnsubscribeFilterObjectType } from '@ts/unsubscribes.types';
import { setFiltersForUnsubscribes } from '@redux/actions/unsubscribes.actions';
import { unsubscribesFiltersSelector } from '@redux/selectors/unsubscribes.selectors';

import './UnsubscribedListPage.scss';
import useUnsubscribedPatterns from '@hooks/useUnsubscribedPatterns';

function UnsubscribedListPage(): JSX.Element {
  const dispatch = useDispatch();
  const [isUnsubscribeOpened, changeUnsubscribeOpened] = useState(false);
  const [activePattern, setActivePattern] = useState<BlackListResponseType>(null);
  const [searchString, changeSearchString] = useState('');
  const [selectedIds, changeSelectedIds] = useState({});
  const [isRemoveSidebarOpen, changeIsRemoveSidebarOpen] = useState(false);
  const [isExportSidebarOpen, changeIsExportSidebarOpen] = useState(false);
  const curWorkspaceId = useSelector(getCurrentWorkspaceId);

  const filters = useSelector(unsubscribesFiltersSelector);

  const {
    items: unsubscribePattersData,
    removeItem,
    refetch,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isLoadingNextPage,
  } = useUnsubscribedPatterns(searchString, filters);

  const handleOpenRemoveSidebar = () => changeIsRemoveSidebarOpen(true);
  const handleCloseRemoveSidebar = () => changeIsRemoveSidebarOpen(false);
  const handleOpenExportSidebar = () => changeIsExportSidebarOpen(true);
  const handleCloseExportSidebar = () => changeIsExportSidebarOpen(false);
  const handleOpenUnsubscribeSidebar = () => changeUnsubscribeOpened(true);
  const handleCloseUnsubscribeSidebar = () => {
    changeUnsubscribeOpened(false);
    setActivePattern(null);
    refetch();
  };

  const handleRemovePattern = (pattern: BlackListResponseType) => {
    removeBlackListPatternBulkApi([pattern.id], curWorkspaceId).then(() => {
      removeItem(pattern.id);
    });
  };

  const handleEditPattern = (pattern: BlackListResponseType) => {
    setActivePattern(pattern);
    changeUnsubscribeOpened(true);
  };

  const handleToggleSelection = (id: number) => {
    changeSelectedIds((prevState) => {
      if (prevState[id] === undefined) {
        return {
          ...prevState,
          [id]: id,
        };
      }

      return omit(prevState, [String(id)]);
    });
  };

  const handleToggleAllSelected = (checked: boolean) => {
    if (checked) {
      changeSelectedIds(
        unsubscribePattersData.reduce(
          (acc, { id }) => ({
            ...acc,
            [id]: id,
          }),
          {}
        )
      );
    } else {
      changeSelectedIds({});
    }
  };

  const handleRemoveUnsubscribe = () => {
    removeBlackListPatternBulkApi(Object.keys(selectedIds).map(Number), curWorkspaceId).then(() => {
      dispatch(addNotification({ title: 'Patterns removed', type: 'success' }));
      changeSelectedIds({});
      handleCloseRemoveSidebar();
      refetch();
    });
  };

  const handleExportUnsubscribe = () => {
    let selIds = Object.keys(selectedIds).map(Number);
    if (calculateIsAllSelected) {
      selIds = [];
    }
    exportBlackListPatternsToCsvApi(selIds).then(({ title, fileBytes }) => {
      handleCloseExportSidebar();
      const fileBlob = getBlobFromBase64(fileBytes, 'text/csv');
      downloadCSV(title || `Unsubscribe-patterns-export-${Date.now()}`, fileBlob);
    });
  };

  const filteredData = useMemo(
    () =>
      unsubscribePattersData?.filter(({ pattern }) =>
        pattern.toLowerCase().includes(searchString.toLowerCase())
      ) || [],
    [unsubscribePattersData, searchString]
  );

  const handleApplyUnsubscribesFilters = useCallback(
    (newFiltersObject: UnsubscribeFilterObjectType) =>
      dispatch(setFiltersForUnsubscribes(newFiltersObject)),
    []
  );

  const calculateIsAllSelected = useMemo(() => {
    return Object.keys(selectedIds).length >= filteredData?.length;
  }, [selectedIds, filteredData]);

  const composeExportItemsNumber = useMemo(() => {
    if (calculateIsAllSelected && unsubscribePattersData?.length > 0) {
      return unsubscribePattersData[0].totalItems;
    }
    return Object.keys(selectedIds)?.length || unsubscribePattersData?.length;
  }, [unsubscribePattersData, selectedIds]);

  return (
    <div className="unsubscribe-list-page">
      <PageHeader
        title="Unsubscribe list"
        renderHeaderActions={() => (
          <UnsubscribedPageActions
            selectedIds={selectedIds}
            handleOpenExportSidebar={handleOpenExportSidebar}
            handleOpenRemoveSidebar={handleOpenRemoveSidebar}
            handleOpenUnsubscribeSidebar={handleOpenUnsubscribeSidebar}
            searchString={searchString}
            changeSearchString={changeSearchString}
            onApplyFilters={handleApplyUnsubscribesFilters}
          />
        )}
      />
      <Loader isLoading={isLoading} withTopMargin>
        <UnsubscribeListTable
          data={filteredData}
          onRemovePattern={handleRemovePattern}
          onEditPattern={handleEditPattern}
          onToggleSelection={handleToggleSelection}
          selectedIds={selectedIds}
          onToggleAllSelected={handleToggleAllSelected}
          isAllSelected={calculateIsAllSelected}
          onLoadMore={() => fetchNextPage()}
          isFetchingNextPage={isLoadingNextPage}
          hasNextPage={hasNextPage}
        />
      </Loader>
      <UnsubscribeSidebar
        isOpen={isUnsubscribeOpened}
        onClose={handleCloseUnsubscribeSidebar}
        activePattern={activePattern}
      />
      <SimpleActionSidebar
        title="Remove"
        subtitle="Remove unsubscribe patterns"
        isOpen={isRemoveSidebarOpen}
        onClose={handleCloseRemoveSidebar}
        itemsNumber={Object.keys(selectedIds).length}
        itemsTitle="patterns"
        onAction={handleRemoveUnsubscribe}
      />
      <SimpleActionSidebar
        title="Export"
        subtitle="Export unsubscribe patterns"
        isOpen={isExportSidebarOpen}
        onClose={handleCloseExportSidebar}
        itemsNumber={composeExportItemsNumber}
        itemsTitle="patterns"
        onAction={handleExportUnsubscribe}
      />
    </div>
  );
}

export default UnsubscribedListPage;
