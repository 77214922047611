import React from 'react';

import Display from '@components/Display/Display';
import { SVGIcon } from '@uikit/Icon/Icon';

import './ShowMoreButton.scss';

const ShowMoreButton = ({ isVisible, onClick }) => {
  return (
    <Display isVisible={isVisible}>
      <div className="show-more-button" onClick={onClick} tabIndex={0} role="button">
        MORE DETAILS
        <SVGIcon icon="arrowLeft" color="#BBBDBE" />
      </div>
    </Display>
  );
};

export default ShowMoreButton;
