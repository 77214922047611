import React, { useMemo, useState } from 'react';

import { SVGIcon, SvgIconType } from '@uikit/Icon/Icon';
import { SocialLinkType } from '@ts/socialLinks.types';
import { PRIMARY_COLOR } from '@constants/colors';

import uniqueArray from '@utils/uniqueArray';

import './SocialLinks.scss';

export const socialLinkTypeToIconMapper = {
  0: 'link',
  1: 'twitter',
  2: 'facebook',
  3: 'linkedin',
  4: 'crunchbase',
  5: 'angellist',
  6: 'link',
  7: 'link',
  8: 'link',
  9: 'googleplay',
  10: 'spotify',
  11: 'youtube',
};

export const SocialLink = ({ type, url }: SocialLinkType): JSX.Element => {
  const [isHovered, changeIsHovered] = useState(false);

  return (
    <div
      className="social-link"
      onMouseOver={() => changeIsHovered(true)}
      onMouseOut={() => changeIsHovered(false)}
    >
      <a href={url} target="__black" className="social-link__link">
        <SVGIcon
          icon={socialLinkTypeToIconMapper[type] as SvgIconType}
          color={isHovered ? PRIMARY_COLOR : undefined}
          size={type === 0 ? 12 : undefined}
        />
      </a>
    </div>
  );
};

const SocialLinks = ({
  socialLinks,
  hideUknownType = true,
}: {
  socialLinks: SocialLinkType[];
  hideUknownType?: boolean;
}): JSX.Element => {
  const uniqueSocialLinks = useMemo(() => uniqueArray(socialLinks), [socialLinks]);
  return (
    <div className="social-links" onClick={(e) => e.stopPropagation()}>
      {uniqueSocialLinks.map(({ type, url }) => {
        if (hideUknownType && type === 0) {
          return null;
        }
        // We have no such icons in design
        if (type === 6 || type === 7 || type === 8) {
          return null;
        }

        return <SocialLink url={url} type={type} key={url} />;
      })}
    </div>
  );
};

export default SocialLinks;
