import React from 'react';

export default ({ color = '#251FB6' }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 23H18"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 1H4C2.34315 1 1 2.34315 1 4V16C1 17.6569 2.34315 19 4 19H20C21.6569 19 23 17.6569 23 16V4C23 2.34315 21.6569 1 20 1Z"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 6L16 10L10 14V6Z"
      stroke="#251FB6"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
