import React from 'react';
import ReactTooltip from 'react-tooltip';
import { nanoid } from 'nanoid';

import { VariableType } from '@constants/variables';

import { SVGIcon } from '@uikit/Icon/Icon';
import VariablesList from '@uikit/RichEditor/_components/VariablesList/VariablesList';

import './VariablesIconButton.scss';

export const VariablesIconButton = ({
  id,
  onSelectVar,
  onCreateNew,
  className = 'editor-variables-button',
  variables,
}: {
  id: string;
  onSelectVar: (VariableType) => void;
  onCreateNew?: () => void;
  className?: string;
  variables?: VariableType[];
}): JSX.Element => {
  const buttonId = nanoid();

  return (
    <>
      <span
        className={className}
        data-for={id + buttonId}
        data-tip=""
        data-event="click"
        onClick={(e) => e.stopPropagation()}
      >
        <SVGIcon icon="brackets" />
      </span>
      <ReactTooltip
        id={id + buttonId}
        className="react-tooltip"
        effect="solid"
        event="click"
        place="left"
        globalEventOff="click"
        arrowColor="transparent"
        isCapture
        clickable
      >
        <VariablesList variables={variables} onSelectVar={onSelectVar} onCreateNew={onCreateNew} />
      </ReactTooltip>
    </>
  );
};

export default VariablesIconButton;
