export const webSearchLanguages = [
  { value: '', label: 'Any Language' },
  { value: 'lang_af', label: 'Afrikaans' },
  { value: 'lang_ar', label: 'Arabic' },
  { value: 'lang_az', label: 'Azerbaijani' },
  { value: 'lang_bg', label: 'Bulgarian' },
  { value: 'lang_bn', label: 'Bengali' },
  { value: 'lang_bs', label: 'Bosnian' },
  { value: 'lang_ca', label: 'Catalan' },
  { value: 'lang_cs', label: 'Czech' },
  { value: 'lang_cy', label: 'Welsh' },
  { value: 'lang_da', label: 'Danish' },
  { value: 'lang_de', label: 'German' },
  { value: 'lang_el', label: 'Greek' },
  { value: 'lang_en', label: 'English' },
  { value: 'lang_es', label: 'Spanish' },
  { value: 'lang_et', label: 'Estonian' },
  { value: 'lang_eu', label: 'Basque' },
  { value: 'lang_fa', label: 'Persian' },
  { value: 'lang_fi', label: 'Finnish' },
  { value: 'lang_fr', label: 'French' },
  { value: 'lang_ga', label: 'Irish' },
  { value: 'lang_gl', label: 'Galician' },
  { value: 'lang_gu', label: 'Gujarati' },
  { value: 'lang_hi', label: 'Hindi' },
  { value: 'lang_hr', label: 'Croatian' },
  { value: 'lang_hu', label: 'Hungarian' },
  { value: 'lang_hy', label: 'Armenian' },
  { value: 'lang_id', label: 'Indonesian' },
  { value: 'lang_is', label: 'Icelandic' },
  { value: 'lang_it', label: 'Italian' },
  { value: 'lang_iw', label: 'Hebrew' },
  { value: 'lang_ja', label: 'Japanese' },
  { value: 'lang_kn', label: 'Kannada' },
  { value: 'lang_kk', label: 'Kazakh' },
  { value: 'lang_km', label: 'Khmer' },
  { value: 'lang_ko', label: 'Korean' },
  { value: 'lang_ky', label: 'Kyrgyz' },
  { value: 'lang_lo', label: 'Lao' },
  { value: 'lang_lt', label: 'Lithuanian' },
  { value: 'lang_lv', label: 'Latvian' },
  { value: 'lang_ml', label: 'Malayalam' },
  { value: 'lang_mn', label: 'Mongolian' },
  { value: 'lang_mr', label: 'Marathi' },
  { value: 'lang_ne', label: 'Nepali' },
  { value: 'lang_nl', label: 'Dutch' },
  { value: 'lang_no', label: 'Norwegian' },
  { value: 'lang_pa', label: 'Punjabi' },
  { value: 'lang_pl', label: 'Polish' },
  { value: 'lang_pt', label: 'Portuguese' },
  { value: 'lang_ro', label: 'Romanian' },
  { value: 'lang_ru', label: 'Russian' },
  { value: 'lang_si', label: 'Sinhala' },
  { value: 'lang_sk', label: 'Slovak' },
  { value: 'lang_sl', label: 'Slovenian' },
  { value: 'lang_sr', label: 'Serbian' },
  { value: 'lang_sv', label: 'Swedish' },
  { value: 'lang_ta', label: 'Tamil' },
  { value: 'lang_te', label: 'Telugu' },
  { value: 'lang_th', label: 'Thai' },
  { value: 'lang_tr', label: 'Turkish' },
  { value: 'lang_uk', label: 'Ukrainian' },
  { value: 'lang_ur', label: 'Urdu' },
  { value: 'lang_uz', label: 'Uzbek' },
  { value: 'lang_vi', label: 'Vietnamese' },
  { value: 'lang_xh', label: 'Xhosa' },
  { value: 'lang_zh-CN', label: 'Chinese (Simplified)' },
  { value: 'lang_zh-TW', label: 'Chinese (Traditional)' },
  { value: 'lang_zu', label: 'Zulu' },
];

export const podcastLanguages = [
  { value: '', label: 'Any language' },
  { value: 'Afar', label: 'Afar' },
  { value: 'Abkhazian', label: 'Abkhazian' },
  { value: 'Afrikaans', label: 'Afrikaans' },
  { value: 'Akan', label: 'Akan' },
  { value: 'Albanian', label: 'Albanian' },
  { value: 'Arabic', label: 'Arabic' },
  { value: 'Azerbaijani', label: 'Azerbaijani' },
  { value: 'Bambara', label: 'Bambara' },
  { value: 'Bashkir', label: 'Bashkir' },
  { value: 'Basque', label: 'Basque' },
  { value: 'Belarusian', label: 'Belarusian' },
  { value: 'Bulgarian', label: 'Bulgarian' },
  { value: 'Catalan', label: 'Catalan' },
  { value: 'Chamorro', label: 'Chamorro' },
  { value: 'Chinese', label: 'Chinese' },
  { value: 'Croatian', label: 'Croatian' },
  { value: 'Czech', label: 'Czech' },
  { value: 'Danish', label: 'Danish' },
  { value: 'Dutch', label: 'Dutch' },
  { value: 'English', label: 'English' },
  { value: 'Estonian', label: 'Estonian' },
  { value: 'Faeroese', label: 'Faeroese' },
  { value: 'Farsi', label: 'Farsi' },
  { value: 'Finnish', label: 'Finnish' },
  { value: 'French', label: 'French' },
  { value: 'Gaelic', label: 'Gaelic' },
  { value: 'Galician', label: 'Galician' },
  { value: 'German', label: 'German' },
  { value: 'Greek', label: 'Greek' },
  { value: 'Hebrew', label: 'Hebrew' },
  { value: 'Hindi', label: 'Hindi' },
  { value: 'Hungarian', label: 'Hungarian' },
  { value: 'Icelandic', label: 'Icelandic' },
  { value: 'Indonesian', label: 'Indonesian' },
  { value: 'Irish', label: 'Irish' },
  { value: 'Italian', label: 'Italian' },
  { value: 'Japanese', label: 'Japanese' },
  { value: 'Khmer', label: 'Khmer' },
  { value: 'Kirghiz', label: 'Kirghiz' },
  { value: 'Korean', label: 'Korean' },
  { value: 'Latvian', label: 'Latvian' },
  { value: 'Lithuanian', label: 'Lithuanian' },
  { value: 'Macedonian', label: 'Macedonian' },
  { value: 'Malay', label: 'Malay' },
  { value: 'Nepali', label: 'Nepali' },
  { value: 'Northern Sami', label: 'Northern Sami' },
  { value: 'Norwegian', label: 'Norwegian' },
  { value: 'Polish', label: 'Polish' },
  { value: 'Portuguese', label: 'Portuguese' },
  { value: 'Romanian', label: 'Romanian' },
  { value: 'Russian', label: 'Russian' },
  { value: 'Serbian', label: 'Serbian' },
  { value: 'Singhalese', label: 'Singhalese' },
  { value: 'Slovak', label: 'Slovak' },
  { value: 'Slovenian', label: 'Slovenian' },
  { value: 'Spanish', label: 'Spanish' },
  { value: 'Swahili', label: 'Swahili' },
  { value: 'Swedish', label: 'Swedish' },
  { value: 'Thai', label: 'Thai' },
  { value: 'Turkish', label: 'Turkish' },
  { value: 'Twi', label: 'Twi' },
  { value: 'Ukranian', label: 'Ukranian' },
  { value: 'Urdu', label: 'Urdu' },
  { value: 'Vietnamese', label: 'Vietnamese' },
];

export const trendingContentLanguages = [
  { value: 'ab', label: 'Abkhazian' },
  { value: 'aa', label: 'Afar' },
  { value: 'af', label: 'Afrikaans' },
  { value: 'ak', label: 'Akan' },
  { value: 'sq', label: 'Albanian' },
  { value: 'am', label: 'Amharic' },
  { value: 'ar', label: 'Arabic' },
  { value: 'an', label: 'Aragonese' },
  { value: 'hy', label: 'Armenian' },
  { value: 'as', label: 'Assamese' },
  { value: 'av', label: 'Avaric' },
  { value: 'ae', label: 'Avestan' },
  { value: 'ay', label: 'Aymara' },
  { value: 'az', label: 'Azerbaijani' },
  { value: 'bm', label: 'Bambara' },
  { value: 'ba', label: 'Bashkir' },
  { value: 'eu', label: 'Basque' },
  { value: 'be', label: 'Belarusian' },
  { value: 'bn', label: 'Bengali' },
  { value: 'bi', label: 'Bislama' },
  { value: 'bs', label: 'Bosnian' },
  { value: 'br', label: 'Breton' },
  { value: 'bg', label: 'Bulgarian' },
  { value: 'my', label: 'Burmese' },
  { value: 'ca', label: 'Catalan' },
  { value: 'ch', label: 'Chamorro' },
  { value: 'ce', label: 'Chechen' },
  { value: 'ny', label: 'Chichewa' },
  { value: 'zh', label: 'Chinese' },
  { value: 'cv', label: 'Chuvash' },
  { value: 'kw', label: 'Cornish' },
  { value: 'co', label: 'Corsican' },
  { value: 'cr', label: 'Cree' },
  { value: 'hr', label: 'Croatian' },
  { value: 'cs', label: 'Czech' },
  { value: 'da', label: 'Danish' },
  { value: 'dv', label: 'Divehi' },
  { value: 'nl', label: 'Dutch' },
  { value: 'dz', label: 'Dzongkha' },
  { value: 'en', label: 'English' },
  { value: 'eo', label: 'Esperanto' },
  { value: 'et', label: 'Estonian' },
  { value: 'ee', label: 'Ewe' },
  { value: 'fo', label: 'Faroese' },
  { value: 'fj', label: 'Fijian' },
  { value: 'fi', label: 'Finnish' },
  { value: 'fr', label: 'French' },
  { value: 'ff', label: 'Fulah' },
  { value: 'gl', label: 'Galician' },
  { value: 'ka', label: 'Georgian' },
  { value: 'de', label: 'German' },
  { value: 'el', label: 'Greek' },
  { value: 'gn', label: 'Guarani' },
  { value: 'gu', label: 'Gujarati' },
  { value: 'ht', label: 'Haitian' },
  { value: 'ha', label: 'Hausa' },
  { value: 'he', label: 'Hebrew' },
  { value: 'hz', label: 'Herero' },
  { value: 'hi', label: 'Hindi' },
  { value: 'ho', label: 'Hiri Motu' },
  { value: 'hu', label: 'Hungarian' },
  { value: 'ia', label: 'Interlingua' },
  { value: 'id', label: 'Indonesian' },
  { value: 'ie', label: 'Interlingue' },
  { value: 'ga', label: 'Irish' },
  { value: 'ig', label: 'Igbo' },
  { value: 'ik', label: 'Inupiaq' },
  { value: 'io', label: 'Ido' },
  { value: 'is', label: 'Icelandic' },
  { value: 'it', label: 'Italian' },
  { value: 'iu', label: 'Inuktitut' },
  { value: 'ja', label: 'Japanese' },
  { value: 'jv', label: 'Javanese' },
  { value: 'kl', label: 'Kalaallisut' },
  { value: 'kn', label: 'Kannada' },
  { value: 'kr', label: 'Kanuri' },
  { value: 'ks', label: 'Kashmiri' },
  { value: 'kk', label: 'Kazakh' },
  { value: 'km', label: 'Central Khmer' },
  { value: 'ki', label: 'Kikuyu' },
  { value: 'rw', label: 'Kinyarwanda' },
  { value: 'ky', label: 'Kirghiz' },
  { value: 'kv', label: 'Komi' },
  { value: 'kg', label: 'Kongo' },
  { value: 'ko', label: 'Korean' },
  { value: 'ku', label: 'Kurdish' },
  { value: 'la', label: 'Latin' },
  { value: 'lg', label: 'Ganda' },
  { value: 'ln', label: 'Lingala' },
  { value: 'lo', label: 'Lao' },
  { value: 'lt', label: 'Lithuanian' },
  { value: 'lu', label: 'Luba-Katanga' },
  { value: 'lv', label: 'Latvian' },
  { value: 'gv', label: 'Manx' },
  { value: 'mk', label: 'Macedonian' },
  { value: 'mg', label: 'Malagasy' },
  { value: 'ms', label: 'Malay' },
  { value: 'ml', label: 'Malayalam' },
  { value: 'mt', label: 'Maltese' },
  { value: 'mi', label: 'Maori' },
  { value: 'mr', label: 'Marathi' },
  { value: 'mh', label: 'Marshallese' },
  { value: 'mn', label: 'Mongolian' },
  { value: 'na', label: 'Nauru' },
  { value: 'nv', label: 'Navajo, Navaho' },
  { value: 'nd', label: 'North Ndebele' },
  { value: 'ne', label: 'Nepali' },
  { value: 'ng', label: 'Ndonga' },
  { value: 'nb', label: 'Norwegian Bokmål' },
  { value: 'nn', label: 'Norwegian Nynorsk' },
  { value: 'no', label: 'Norwegian' },
  { value: 'ii', label: 'Nuosu' },
  { value: 'nr', label: 'South Ndebele' },
  { value: 'oc', label: 'Occitan' },
  { value: 'oj', label: 'Ojibwa' },
  { value: 'om', label: 'Oromo' },
  { value: 'or', label: 'Oriya' },
  { value: 'os', label: 'Ossetian' },
  { value: 'pa', label: 'Punjabi' },
  { value: 'pi', label: 'Pali' },
  { value: 'fa', label: 'Persian' },
  { value: 'pl', label: 'Polish' },
  { value: 'ps', label: 'Pashto' },
  { value: 'pt', label: 'Portuguese' },
  { value: 'qu', label: 'Quechua' },
  { value: 'rm', label: 'Romansh' },
  { value: 'rn', label: 'Rundi' },
  { value: 'ro', label: 'Romanian, Moldavian' },
  { value: 'ru', label: 'Russian' },
  { value: 'sa', label: 'Sanskrit' },
  { value: 'sc', label: 'Sardinian' },
  { value: 'sd', label: 'Sindhi' },
  { value: 'se', label: 'Northern Sami' },
  { value: 'sm', label: 'Samoan' },
  { value: 'sg', label: 'Sango' },
  { value: 'sr', label: 'Serbian' },
  { value: 'gd', label: 'Scottish Gaelic' },
  { value: 'sn', label: 'Shona' },
  { value: 'si', label: 'Sinhala' },
  { value: 'sk', label: 'Slovak' },
  { value: 'sl', label: 'Slovenian' },
  { value: 'so', label: 'Somali' },
  { value: 'st', label: 'Southern Sotho' },
  { value: 'es', label: 'Spanish' },
  { value: 'su', label: 'Sundanese' },
  { value: 'sw', label: 'Swahili' },
  { value: 'ss', label: 'Swati' },
  { value: 'sv', label: 'Swedish' },
  { value: 'ta', label: 'Tamil' },
  { value: 'te', label: 'Telugu' },
  { value: 'tg', label: 'Tajik' },
  { value: 'th', label: 'Thai' },
  { value: 'ti', label: 'Tigrinya' },
  { value: 'bo', label: 'Tibetan' },
  { value: 'tk', label: 'Turkmen' },
  { value: 'tl', label: 'Tagalog' },
  { value: 'tn', label: 'Tswana' },
  { value: 'to', label: 'Tonga' },
  { value: 'tr', label: 'Turkish' },
  { value: 'ts', label: 'Tsonga' },
  { value: 'tt', label: 'Tatar' },
  { value: 'tw', label: 'Twi' },
  { value: 'ty', label: 'Tahitian' },
  { value: 'ug', label: 'Uighur' },
  { value: 'uk', label: 'Ukrainian' },
  { value: 'ur', label: 'Urdu' },
  { value: 'uz', label: 'Uzbek' },
  { value: 've', label: 'Venda' },
  { value: 'vi', label: 'Vietnamese' },
  { value: 'vo', label: 'Volapük' },
  { value: 'wa', label: 'Walloon' },
  { value: 'cy', label: 'Welsh' },
  { value: 'wo', label: 'Wolof' },
  { value: 'fy', label: 'Western Frisian' },
  { value: 'xh', label: 'Xhosa' },
  { value: 'yi', label: 'Yiddish' },
  { value: 'yo', label: 'Yoruba' },
  { value: 'za', label: 'Zhuang' },
  { value: 'zu', label: 'Zulu' },
];
