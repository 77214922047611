import React from 'react';

import Button from '@uikit/Button/Button';
import { SVGIcon } from '@uikit/Icon/Icon';

import './FilterHeaderButtons.scss';

type FiltersHeaderButtonsType = {
  leftButtonName: string;
  rightButtonName: string;
  onToogleFilterActive: () => void;
  leftFilterActive: boolean;
  onThrowAllFilters?: () => void;
};

const FilterHeaderButtons = ({
  leftButtonName,
  rightButtonName,
  onToogleFilterActive,
  leftFilterActive,
  onThrowAllFilters,
}: FiltersHeaderButtonsType): JSX.Element => (
  <div className="filters-buttons">
    <Button
      onClick={onToogleFilterActive}
      className="filters-buttons__button"
      type={leftFilterActive ? 'white' : 'grey'}
    >
      {leftButtonName}
    </Button>
    <Button
      onClick={onToogleFilterActive}
      className="filters-buttons__button"
      type={leftFilterActive ? 'grey' : 'white'}
    >
      {rightButtonName}
    </Button>
    {onThrowAllFilters ? (
      <div
        className="filters-buttons__throw-filters-icon"
        onClick={onThrowAllFilters}
        role="button"
        tabIndex={0}
      >
        <SVGIcon icon="refreshOneArrowAround" />
      </div>
    ) : null}
  </div>
);

export default FilterHeaderButtons;
