import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { GradientBackground } from '@uikit/GradientBackgroundLoader/GradientBackgroundLoader';
import { SVGIcon } from '@uikit/Icon/Icon';

import { billingDetailsSelector } from '@redux/selectors/billings.selectors';
import { fetchBillingDetails } from '@redux/thunks/billings.thunks';
import { checkIsAnyNotLoaded, checkIsAnyPending } from '@utils/loadingChecks';

import './UseExistingCard.scss';

const CreditCardNumberRendrer = ({ creditCardNumber }: { creditCardNumber: string }) => {
  if (!creditCardNumber) {
    return <>{'You have no credit card in service'}</>;
  }

  return <>{`Use existing Credit Card **** **** **** ${creditCardNumber}`}</>;
};

const UseExistingCard = ({ onUseNewCard }: { onUseNewCard?: () => void }): JSX.Element => {
  const dispatch = useDispatch();

  const { data: billingDetails, loadingStatus: billingDetailsLs } =
    useSelector(billingDetailsSelector);

  useEffect(() => {
    if (checkIsAnyPending(billingDetailsLs)) {
      dispatch(fetchBillingDetails());
    }
  }, []);

  return (
    <div className="use-existing-card__details">
      <div className="use-existing-card__details-text">
        {checkIsAnyNotLoaded(billingDetailsLs) ? (
          <GradientBackground width={50} height={24} />
        ) : (
          <CreditCardNumberRendrer creditCardNumber={billingDetails.card?.creditCardNumber} />
        )}
      </div>
      {onUseNewCard ? (
        <div className="use-existing-card__btn" onClick={onUseNewCard} role="button" tabIndex={0}>
          <SVGIcon icon="pen" color="#8F9199" />
        </div>
      ) : null}
    </div>
  );
};

export default UseExistingCard;
