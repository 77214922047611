import React, { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import RightSidebar from '@components/RightSidebar/RightSidebar';
import Select from '@uikit/Select/Select';
import Radio from '@uikit/Radio/Radio';

import { getCurrentWorkspace, workspacesSelector } from '@redux/selectors/workspaces.selectors';
import { transformWorkspacesToOptions } from '@mappers/optionTransformers';

import { SETTINGS_ALL_MEMBERS_ROUTE } from '@constants/routes';
import { OrganizationMemberRoleMapType } from '@ts/userOrganization.types';
import AutomationPositionTooltipContent from '@components/CampaignSettings/ContactAutomationSetup/_components/AutomationPositionTooltipContent/AutomationPositionTooltipContent';

import { memberRoleOptions } from '@mappers/common.mappers';

import './InviteMemberSidebar.scss';
import checkIsEmail from '@utils/checkIsEmail';
import { addNotification } from '@redux/actions/notifications.actions';
import { SelectOptionType } from '@ts/common.types';
import { SVGIcon } from '@uikit/Icon/Icon';

type InviteMemberSidebarPropsType = {
  isOpen: boolean;
  onClose: () => void;
  onInvite: (emails: string[], role: OrganizationMemberRoleMapType, workspaces: number[]) => void;
};

type fieldsStateType = {
  emails: string[];
  workspaces: SelectOptionType[];
  role: OrganizationMemberRoleMapType;
};

const selectStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '8.5px',
    height: '115px',
    '&:focus,&:hover': {
      outline: 'none',
      border: '1px solid #221CB6',
      boxShadow: 'none',
    },
  }),
};

function InviteMemberSidebar({
  isOpen,
  onClose,
  onInvite,
}: InviteMemberSidebarPropsType): JSX.Element {
  if (!isOpen) {
    return null;
  }
  const fieldRef = useRef<HTMLDivElement>();
  const dispatch = useDispatch();
  const { workspaces } = useSelector(workspacesSelector);
  const currentWorkspace = useSelector(getCurrentWorkspace);

  const [fields, changeFields] = useState<fieldsStateType>({
    emails: [],
    workspaces: [],
    role: memberRoleOptions.MEMBER,
  });

  const {
    location: { pathname },
  } = useHistory();
  const isAllMembersPage = pathname === SETTINGS_ALL_MEMBERS_ROUTE;

  const handleRoleChange = ({ target: { value } }) =>
    changeFields((prev) => ({
      ...prev,
      role: Number(value) as OrganizationMemberRoleMapType,
    }));

  const createSelectChangeHandler = (fieldName) => (value) =>
    changeFields((prev) => ({
      ...prev,
      [fieldName]: value,
    }));

  const handleInviteUsers = () => {
    if (fields.emails.length === 0) {
      return;
    }

    const workspacesInviteTo = isAllMembersPage
      ? fields.workspaces.map(({ value }) => value)
      : [currentWorkspace.id];

    onInvite(fields.emails, fields.role as OrganizationMemberRoleMapType, workspacesInviteTo);
  };

  const addEmails = (emails: string[]) => {
    changeFields((prev) => ({
      ...prev,
      emails: Array.from(new Set([...prev.emails, ...emails])),
    }));
  };

  const handleAddEmailFromField = (e) => {
    const newEmailString = e.target.textContent.trim();

    if (newEmailString !== '') {
      if (!checkIsEmail(newEmailString)) {
        dispatch(addNotification({ title: 'Should be email', type: 'warning' }));
        return;
      }

      addEmails([newEmailString]);

      setTimeout(() => {
        e.target.textContent = '';
      }, 0);
    }
  };
  const handleAddEmailsFromPaste = (newEmails: string[]) => {
    addEmails(newEmails);
  };
  const handleOnFieldKeyDown = (e) => {
    if (e.keyCode === 13 || e.keyCode === 9) {
      e.preventDefault();
      handleAddEmailFromField(e);
    }
  };

  const handleRemoveEmail = (emailToDelete: string) => {
    changeFields((prev) => ({
      ...prev,
      emails: prev.emails.filter((email) => email !== emailToDelete),
    }));
  };

  const handlePaste = (evt) => {
    evt.preventDefault();

    const text = evt.clipboardData.getData('text');
    const newPatterns = text.split('\n');
    const validPatterns = newPatterns.filter(checkIsEmail).map((email) => email.trim());

    handleAddEmailsFromPaste(validPatterns);

    if (newPatterns.length !== validPatterns.length) {
      dispatch(addNotification({ title: 'Some patterns were invalid', type: 'warning' }));
    }

    setTimeout(() => {
      // eslint-disable-next-line no-param-reassign
      evt.target.textContent = '';
    }, 0);
  };

  const handleFieldBlur = (e) => {
    e.preventDefault();
    e.stopPropagation();

    handleAddEmailFromField(e);
  };

  useEffect(() => {
    if (workspaces.length && !fields.workspaces.length) {
      changeFields((prev) => ({
        ...prev,
        workspaces: [...transformWorkspacesToOptions(workspaces)],
      }));
    }
  }, [workspaces]);

  return (
    <RightSidebar
      title="Invite member"
      isOpen={isOpen}
      onClose={onClose}
      onSave={handleInviteUsers}
      buttonSaveText="Invite"
    >
      <div className="invite-member-sidebar">
        <div className="invite-member-sidebar__header">
          Respona is better together <br />
          Invite your teammates to collaborate
        </div>

        <div className="invite-member-sidebar__field">
          <div className="invite-member-sidebar__field-label">Emails</div>
          <div
            className="invite-member-sidebar__emails-field"
            tabIndex={0}
            role="button"
            onClick={() => fieldRef.current?.focus?.()}
          >
            {fields.emails.map((email) => (
              <React.Fragment key={email}>
                <div className="invite-member-sidebar__email-item">
                  <div className="invite-member-sidebar__email-text">{email}</div>
                  <span
                    className="invite-member-sidebar__email-remove"
                    onClick={() => handleRemoveEmail(email)}
                    tabIndex={0}
                    role="button"
                  >
                    <SVGIcon icon="crossDelete" size={18} />
                  </span>
                </div>
                <br />
              </React.Fragment>
            ))}
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <div
              className="invite-member-sidebar__new-email-field"
              contentEditable
              ref={fieldRef}
              onKeyDown={handleOnFieldKeyDown}
              onBlur={handleFieldBlur}
              onPaste={handlePaste}
              placeholder="Insert emails..."
            />
          </div>
        </div>
        <div className="invite-member-sidebar__field">
          <div className="invite-member-sidebar__field-label">Select a role</div>
          <div className="invite-member-sidebar__role-field">
            <Radio
              onChange={handleRoleChange}
              value={memberRoleOptions.ADMIN}
              isChecked={fields.role === memberRoleOptions.ADMIN}
            >
              <div>
                <div className="invite-member-sidebar__role-name">Admin</div>
                <div className="invite-member-sidebar__role-desc">
                  Can access billing, settings, and all campaigns.
                </div>
              </div>
            </Radio>
            <br />
            <Radio
              onChange={handleRoleChange}
              value={memberRoleOptions.MEMBER}
              isChecked={fields.role === memberRoleOptions.MEMBER}
            >
              <div>
                <div className="invite-member-sidebar__role-name">Member</div>
                <div className="invite-member-sidebar__role-desc">
                  Can only access campaigns within assigned workspaces.
                </div>
              </div>
            </Radio>
          </div>
        </div>
        <div className="invite-member-sidebar__field">
          {isAllMembersPage ? (
            <>
              <div className="invite-member-sidebar__field-label">Workspaces</div>
              <Select
                options={transformWorkspacesToOptions(workspaces)}
                value={fields.workspaces}
                onChange={createSelectChangeHandler('workspaces')}
                placeholder="Select workspaces..."
                isMulti
                renderMultipleValuesTooltipContent={AutomationPositionTooltipContent}
                fieldToRender="label"
              />
            </>
          ) : (
            <div className="invite-member-sidebar__field-label">
              Workspace:
              <p className="invite-member-sidebar__field-value">{currentWorkspace.title}</p>
            </div>
          )}
        </div>
      </div>
    </RightSidebar>
  );
}

export default InviteMemberSidebar;
