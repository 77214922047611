import React from 'react';

import { contactSidebarSize } from '@components/ContactSidebarCRM/ContactSidebarCRM';
import Display from '@components/Display/Display';
import Avatar, { NamedAvatar } from '@uikit/Avatar/Avatar';
import { SVGIcon } from '@uikit/Icon/Icon';

import './SidebarCRMHeader.scss';

type Props = {
  logoUrl: string;
  title: string;
  url: string;
  size?: contactSidebarSize;
};

function SidebarCRMHeader({
  logoUrl,
  title,
  url,
  size = contactSidebarSize.NORMAL,
}: Props): JSX.Element {
  return (
    <div className="sidebar-crm-header">
      <Display isVisible={size === contactSidebarSize.NORMAL}>
        {logoUrl.length ? (
          <Avatar url={logoUrl} fullName={title} size="m" />
        ) : (
          <NamedAvatar userName={title} className="sidebar-crm-header-avatar" />
        )}
      </Display>

      <div className="sidebar-crm-header-column">
        <div className="sidebar-crm-header-name">{title}</div>

        {url.length ? (
          <div
            className="sidebar-crm-header-company"
            onClick={() => window.open(`https://${url}`, '_blank')}
          >
            {url}
            <SVGIcon icon="externalLink" size={12} color="#221cb6" />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default SidebarCRMHeader;
