import React, { useRef, useState } from 'react';
import { QueryKey, useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';

import {
  addEmailToPersonApi,
  removePersonApi,
  removePersonEmailApi,
  updatePersonApi,
  updatePersonEmailApi,
  updatePersonStatus,
} from '@api/people.api';
import { createOrUpdateCompanyApi } from '@api/website.api';

import { WebsiteType } from '@ts/company.types';
import { PersonBaseType, PersonType, RelationshipStatusType } from '@ts/people.types';

import { getDateShort } from '@utils/date';

import { SVGIcon, SvgIconType } from '@uikit/Icon/Icon';
import { socialLinkTypeToIconMapper } from '@uikit/SocialLinks/SocialLinks';

import RelationshipStatus from '@components/Relationships/PeopleTable/_components/RelationshipStatus';
import ContactsSidebarCRMSectionEditableField from '@components/ContactSidebarCRM/_components/ContactsSidebarCRMSection/_components/ContactsSidebarCRMSectionEditableField/ContactsSidebarCRMSectionEditableField';

import './ContactsSidebarCRMSectionPersonal.scss';
import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';
import ContactsSidebarCRMSectionSelectionField from '@components/ContactSidebarCRM/_components/ContactsSidebarCRMSection/_components/ContactsSidebarCRMSectionSelectionField/ContactsSidebarCRMSectionSelectionField';
import ContactsSidebarCRMSection from '@components/ContactSidebarCRM/_components/ContactsSidebarCRMSection/ContactsSidebarCRMSection';
import { updateItemInListByIdHelper } from '@helpers/commonHelpers';
import { PeopleRelationshipStatus } from 'respona_api/generated/common_pb';
import { mapPeopleBaseToPeopleShort } from '@utils/peopleMapper';
import { SECTION_TYPE } from '@components/ContactSidebarCRM/ContactSidebarCRM';

declare const IS_DEV: boolean;

export const personStatusOptions = [
  {
    value: PeopleRelationshipStatus.RELATIONSHIP_STATUS_NOT_CONTACTED,
    label: 'Not contacted',
  },
  {
    value: PeopleRelationshipStatus.RELATIONSHIP_STATUS_ATTEMPTING_TO_REACH,
    label: 'Scheduled',
  },
  {
    value: PeopleRelationshipStatus.RELATIONSHIP_STATUS_CONTACTED,
    label: 'Contacted',
  },
  {
    value: PeopleRelationshipStatus.RELATIONSHIP_STATUS_REPLIED,
    label: 'Replied',
  },
  {
    value: PeopleRelationshipStatus.RELATIONSHIP_STATUS_BOUNCED,
    label: 'Bounced',
  },
  {
    value: PeopleRelationshipStatus.RELATIONSHIP_STATUS_UNSUBSCRIBED,
    label: 'Unsubscribed',
  },
  {
    value: PeopleRelationshipStatus.RELATIONSHIP_STATUS_CANCELED_MANUALLY,
    label: 'Stopped',
  },
  {
    value: PeopleRelationshipStatus.RELATIONSHIP_STATUS_INTERESTED,
    label: 'Interested',
  },
  {
    value: PeopleRelationshipStatus.RELATIONSHIP_STATUS_NOT_INTERESTED,
    label: 'Not interested',
  },
  {
    value: PeopleRelationshipStatus.RELATIONSHIP_STATUS_WON,
    label: 'Won',
  },
  {
    value: PeopleRelationshipStatus.RELATIONSHIP_STATUS_LOST,
    label: 'Lost',
  },
];

function ContactsSidebarCRMSectionPerson({
  person,
  website,
  queryKey,
  onUpdate,
}: {
  person: PersonBaseType;
  website?: WebsiteType;
  queryKey?: QueryKey;
  onUpdate?: (person?: PersonBaseType, website?: WebsiteType) => void;
}) {
  const queryClient = useQueryClient();
  const workspaceId = useCurrentWorkspaceId();

  const [isAddingEmail, setIsAddingEmail] = useState<boolean>(false);
  const [isShownMoreEmails, setShownMoreEmails] = useState(false);

  const composeWebsiteContact = (payload: PersonBaseType): WebsiteType => {
    if (!website) return { contactsList: [mapPeopleBaseToPeopleShort(payload)] } as WebsiteType;
    return { ...website, contactsList: updateItemInListByIdHelper(website.contactsList, payload) };
  };

  const handleAddEmail = async (_: unknown, email: string) => {
    setIsAddingEmail(true);
    try {
      await addEmailToPersonApi(person.id, email);
      const newPerson = {
        ...person,
        emailsList: [
          {
            email,
            accuracy: 0,
            type: 0,
            verified: false,
          },
          ...person.emailsList,
        ],
      } as PersonBaseType;
      onUpdate && onUpdate(newPerson, composeWebsiteContact(newPerson));
      if (queryKey) {
        queryClient.setQueryData<PersonType | PersonType[]>(queryKey, (prevData) =>
          Array.isArray(prevData)
            ? prevData.map((item) =>
                item.id === person.id
                  ? {
                      ...item,
                      emailsList: [
                        { email, accuracy: 0, type: 0, verified: false },
                        ...item.emailsList,
                      ],
                    }
                  : item
              )
            : {
                ...prevData,
                emailsList: [
                  { email, accuracy: 0, type: 0, verified: false },
                  ...prevData.emailsList,
                ],
              }
        );
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsAddingEmail(false);
    }
  };

  const handleChangeStatus = async (
    initStatus: RelationshipStatusType,
    newStatus: RelationshipStatusType
  ) => {
    if (newStatus == null || newStatus === initStatus) return;
    try {
      await updatePersonStatus(person.id, newStatus);
      const newPerson = { ...person, status: newStatus };
      onUpdate && onUpdate(newPerson, composeWebsiteContact(newPerson));
      if (queryKey) {
        queryClient.setQueryData<WebsiteType[]>(queryKey, (prevData: WebsiteType[]) =>
          prevData.map((item) =>
            item.id !== website?.id
              ? item
              : {
                  ...item,
                  contactsList: item.contactsList.map((con) =>
                    con.id !== person?.id ? con : { ...con, status: newStatus }
                  ),
                }
          )
        );
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsAddingEmail(false);
    }
  };

  const handleSaveEmail = async (initialValue: string, email: string) => {
    try {
      await updatePersonEmailApi(person.id, initialValue, email);
      const newPerson = {
        ...person,
        emailsList: person.emailsList.map((oldEmail) =>
          oldEmail.email === initialValue ? { ...oldEmail, email } : oldEmail
        ),
      };
      onUpdate && onUpdate(newPerson, composeWebsiteContact(newPerson));
      if (queryKey) {
        queryClient.setQueryData<PersonType | PersonType[]>(queryKey, (prevData) =>
          Array.isArray(prevData)
            ? prevData.map((item) =>
                item.id === person.id
                  ? {
                      ...item,
                      emailsList: item.emailsList.map((oldEmail) =>
                        oldEmail.email === initialValue ? { ...oldEmail, email } : oldEmail
                      ),
                    }
                  : item
              )
            : {
                ...prevData,
                emailsList: prevData.emailsList.map((oldEmail) =>
                  oldEmail.email === initialValue ? { ...oldEmail, email } : oldEmail
                ),
              }
        );
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsAddingEmail(false);
    }
  };

  const onRemoveClick = async (curEmail: string) => {
    if (person?.emailsList?.length === 1 && person.emailsList[0]?.email === curEmail) {
      await removePersonApi(person.id);
      onUpdate && onUpdate(null, website);
      if (queryKey) {
        queryClient.setQueryData<PersonType | PersonType[]>(queryKey, (prevData) =>
          Array.isArray(prevData) ? prevData.filter((item) => item.id === person.id) : null
        );
      }
    } else {
      return handleRemoveEmail(curEmail);
    }
  };

  const handleRemoveEmail = async (email: string) => {
    try {
      await removePersonEmailApi(person.id, email);
      const newPerson = {
        ...person,
        emailsList: person.emailsList.slice().filter((oldEmail) => oldEmail.email !== email),
      };
      onUpdate && onUpdate(newPerson, composeWebsiteContact(newPerson));
      if (queryKey) {
        queryClient.setQueryData<PersonType | PersonType[]>(queryKey, (prevData) =>
          Array.isArray(prevData)
            ? prevData.map((item) =>
                item.id === person.id
                  ? {
                      ...item,
                      emailsList: item.emailsList
                        .slice()
                        .filter((oldEmail) => oldEmail.email !== email),
                    }
                  : item
              )
            : {
                ...prevData,
                emailsList: prevData.emailsList
                  .slice()
                  .filter((oldEmail) => oldEmail.email !== email),
              }
        );
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsAddingEmail(false);
    }
  };

  const handleUpdatePerson = async (key: string, value: string) => {
    try {
      const newPersonResponse = await updatePersonApi({
        ...person,
        [key]: value,
        workspaceId,
      });
      onUpdate && onUpdate({ ...person, ...newPersonResponse, [key]: value }, website);
      if (queryKey) {
        queryClient.setQueryData<PersonType | PersonType[]>(queryKey, (prevData) =>
          Array.isArray(prevData)
            ? prevData.map((item) =>
                item.id === person.id ? { ...item, ...newPersonResponse, [key]: value } : item
              )
            : { ...prevData, [key]: value }
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  const socialLinks = []
    .concat(person?.socialLinksList || [])
    .filter(Boolean)
    .filter((item, index, arr) => arr.findIndex((link) => link.url === item.url) === index);

  const emailsList = person.emailsList || [];

  return (
    <div className="contact-sidebar-crm__content-personal-info">
      {isAddingEmail && (
        <ContactsSidebarCRMSectionEditableField
          isOpen
          initialValue=""
          onSave={handleAddEmail}
          onClose={() => setIsAddingEmail(false)}
          placeholder="Enter email:"
        />
      )}
      <div className="inbox-content-wrapper">
        <div className="inbox-content-wrapper__content">
          <ContactsSidebarCRMSectionEditableField
            enableCopy={false}
            initialValue={person.name}
            onSave={(_, value) => handleUpdatePerson('name', value)}
            placeholder="Enter name:"
            extraClass="main-title"
          />
          {socialLinks?.length > 0 && (
            <>
              {socialLinks.map((item) => (
                <div
                  key={item.url}
                  className="contact-sidebar-crm__content-personal-info-data-row contact-sidebar-crm__content-personal-info-data-row-social"
                  onClick={() => window.open(item.url, '_blank')}
                >
                  <SVGIcon icon={socialLinkTypeToIconMapper[item.type] as SvgIconType} />
                </div>
              ))}
            </>
          )}
        </div>
        {(isShownMoreEmails ? emailsList : emailsList.length >= 1 ? [emailsList[0]] : []).map(
          (item) => {
            return (
              <ContactsSidebarCRMSectionEditableField
                key={item.email}
                initialValue={item.email}
                onSave={handleSaveEmail}
                onDelete={handleRemoveEmail}
                onClose={() => setIsAddingEmail(false)}
                placeholder="Enter email:"
                isRenderIcon
                renderIcon="messageIcon"
              />
            );
          }
        )}
        {isShownMoreEmails || emailsList.length <= 1 ? null : (
          <div
            onClick={() => setShownMoreEmails(true)}
            className="organizations-table__emails_more text-align-right"
          >
            +{emailsList.length - 1} other{emailsList.length - 1 > 1 ? 's' : ''}
          </div>
        )}
        <ContactsSidebarCRMSectionEditableField
          enableCopy={false}
          initialValue={person.jobPosition || ''}
          onSave={(_, value) => handleUpdatePerson('jobPosition', value)}
          placeholder="Enter job title:"
          renderContent={(value) => (value == null || value.length === 0 ? '-' : value)}
          isRenderIcon
          renderIcon="personRounded"
        />
        {IS_DEV ? (
          <ContactsSidebarCRMSectionSelectionField
            initialStatus={person.status}
            statusOptions={personStatusOptions}
            onChange={handleChangeStatus}
          />
        ) : (
          <div className="contact-sidebar-crm__content-personal-info-data-row">
            {person.status >= 0 ? (
              <RelationshipStatus status={person.status} isBlocked={false} withIcon={false} />
            ) : (
              ''
            )}
          </div>
        )}
      </div>
    </div>
  );
}

function ContactsSidebarCRMSectionWebsite({
  website,
  queryKey,
  onUpdate,
}: {
  website: WebsiteType;
  queryKey?: QueryKey;
  onUpdate?: (person?: PersonBaseType, website?: WebsiteType) => void;
}) {
  const queryClient = useQueryClient();

  const handleSaveCompany = async (_: unknown, title: string) => {
    try {
      await createOrUpdateCompanyApi({
        id: website.id,
        title,
        domain: website.domain,
        workspaceId: useCurrentWorkspaceId(),
      });
      onUpdate && onUpdate(null, { ...website, title });
      if (queryKey) {
        queryClient.setQueryData<WebsiteType | WebsiteType[]>(queryKey, (prevData) =>
          Array.isArray(prevData)
            ? prevData.map((item) => (item.id === website.id ? { ...item, title } : item))
            : { ...prevData, title }
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="contact-sidebar-crm__content-personal-info">
      <div className="contact-sidebar-crm__content-personal-info-label">Website name:</div>
      <ContactsSidebarCRMSectionEditableField
        initialValue={website.title}
        onSave={handleSaveCompany}
        renderContent={(value) => (value == null || value.length === 0 ? '-' : value)}
      />
      <div className="contact-sidebar-crm__content-personal-info-label">Created on: </div>
      <div className="contact-sidebar-crm__content-personal-info-data-row">
        {getDateShort(new Date(website.createdAt), true, true)}
      </div>
      {website?.socialLinksList?.length > 0 && (
        <>
          <div className="contact-sidebar-crm__content-personal-info-label">Social</div>
          {website.socialLinksList.map((item) => (
            <div
              key={item.url}
              className="contact-sidebar-crm__content-personal-info-data-row contact-sidebar-crm__content-personal-info-data-row-social-link"
              onClick={() => window.open(item.url, '_blank')}
            >
              <SVGIcon icon={socialLinkTypeToIconMapper[item.type] as SvgIconType} />
              <span>{item.url}</span>
            </div>
          ))}
        </>
      )}
    </div>
  );
}

function ContactsSidebarCRMSectionPersonal({
  index,
  person,
  website,
  queryKey,
  onUpdate,
  onRightComponentClick,
}: {
  index: number;
  person?: PersonBaseType;
  website?: WebsiteType;
  queryKey?: QueryKey;
  onUpdate?: (person?: PersonBaseType, website?: WebsiteType) => void;
  onRightComponentClick?: (sectionName: string, targetId: number) => void;
}) {
  const ref = useRef(null);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const location = useLocation();
  const isInboxPage = location.pathname.startsWith('/inbox');

  if (!person && !website) {
    return null;
  }

  const forceOpen = website ? website?.contactsList?.length > 0 : person?.emailsList?.length > 0;

  return (
    <ContactsSidebarCRMSection
      extraClass="inbox-contact-section"
      ref={ref}
      index={index}
      id="contacts"
      title="Contacts"
      onOpen={() => {}}
      forceOpen={forceOpen}
      rightComponent={
        isInboxPage ? null : (
          <div
            className="contact-sidebar-crm__content__add-btn"
            onClick={() =>
              onRightComponentClick && onRightComponentClick(SECTION_TYPE.CONTACTS, website?.id)
            }
          >
            <SVGIcon icon="plusIcon" size={10} color="#221CB6" />
            Add
          </div>
        )
      }
    >
      {(() => {
        if (website) {
          return (
            <div>
              {website?.contactsList.length > 0 &&
                website.contactsList
                  ?.slice(0, isExpanded ? undefined : 3)
                  ?.map((contact) => (
                    <ContactsSidebarCRMSectionPerson
                      key={contact.id}
                      person={contact as any}
                      website={website}
                      queryKey={queryKey}
                      onUpdate={onUpdate}
                    />
                  ))}
              {!isExpanded && website.contactsList?.length > 3 && (
                <div
                  className="contact-sidebar-crm__show-more-btn"
                  onClick={() => setIsExpanded(true)}
                >
                  Show More
                  <SVGIcon icon="chevron" size={8} />
                </div>
              )}
            </div>
          );
        }
        if (person) {
          return (
            <ContactsSidebarCRMSectionPerson
              person={person}
              website={website}
              queryKey={queryKey}
              onUpdate={onUpdate}
            />
          );
        }
      })()}
    </ContactsSidebarCRMSection>
  );
}

export default ContactsSidebarCRMSectionPersonal;
