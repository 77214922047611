import React from 'react';

export default ({
  color = '#3424EB',
  size = 19,
}: {
  color: string;
  size?: number;
}): JSX.Element => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.3566 9.89546V6.87065C16.3566 5.76608 15.4612 4.87066 14.3566 4.87066H3.43566C2.64246 4.87066 2 4.2282 2 3.435V15.6381C2 16.8275 2.96404 17.7916 4.15349 17.7916H14.3566C15.4612 17.7916 16.3566 16.8961 16.3566 15.7916V12.7668"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M13.4853 4.87132V4.87132C13.4853 3.28553 12.1997 2 10.6139 2H3.43566C2.64246 2 2 2.64246 2 3.43566C2 4.22886 2.64246 4.87132 3.43566 4.87132"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M17.0717 11.3317C17.0717 12.1245 16.4289 12.7673 15.636 12.7673H13.4825C12.6893 12.7673 12.0469 12.1249 12.0469 11.3317C12.0469 10.5385 12.6893 9.896 13.4825 9.896H15.636C16.4289 9.896 17.0717 10.5388 17.0717 11.3317V11.3317Z"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);
