export default {
  senderEmail: "Campaign emails will be evenly distributed among selected sender accounts.",
  priority:
    'When multiple campaigns are launched with the same email account, they’ll be sorted in the queue based on their priority.',
  autoBCC: 'Automatically BCC an email on all outgoing emails from this campaign.',
  autoCC: 'Automatically CC an email on all outgoing emails from this campaign.',
  sendingStrategy:
    'Determine what should Respona do when you assign multiple email addresses to each opportunity',
  bufferBetweenContacts:
    'The number of days Respona should wait after the last step of the sequence, before moving forward to the next person assigned to the opportunity.',
};
