import React from 'react';

const QueryErrorMessage = () => {
  return (
    <>
      Error! Try one of this
      <br />
      <br />
      1. Check internet
      <br />
      2. Try to reload page
      <br />
      3. Turn off filters.
    </>
  );
};

export default QueryErrorMessage;