import React from 'react';

import cn from 'class-names';

import { SVGIcon } from '@uikit/Icon/Icon';

import './CampaignItemInFilters.scss';

const CampaignItemInFilters = ({ campaign, isInFolder, isChoosen, onClick }) => {
  const cnItem = cn('campaigns-filter-item', {
    'campaigns-filter-item--in-folder': isInFolder,
  });

  return (
    <div className={cnItem} onClick={() => onClick(campaign.id)} role="button" tabIndex={0}>
      <p className="campaigns-filter-item__text" title={campaign.title}>
        {campaign.title}
      </p>
      {isChoosen ? (
        <SVGIcon className="campaigns-filter-item__choosen-icon" icon="star" color="#FC8E0E" />
      ) : null}
    </div>
  );
};

export default CampaignItemInFilters;
