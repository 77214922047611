import React, { useMemo } from 'react';
import { CustomVariableType } from 'respona_api/generated/common_pb';
import { fromNumberAhrefFormatHelper } from '@helpers/transformDataHelpers';
import { addNotification } from '@redux/actions/notifications.actions';
import Checkbox from '@uikit/Checkbox/Checkbox';
import HeaderDateFilter from '@uikit/HeaderDateFilter/HeaderDateFilter';
import Select from '@uikit/Select/Select';
import CustomAvatar from '@components/CustomAvatar/CustonAvatar';
import CustomOptionAvatar from '@components/CustomOptionAvatar/CustomOptionAvatar';
import Rating from 'react-rating';
import { SVGIcon } from '@uikit/Icon/Icon';
import { transformCustomVariableOptionsToOptions } from '@mappers/optionTransformers';
import MultiValueContainer from '@components/CustomFieldsSidebar/_components/MultiValueContainer';
import SidebarCRMSectionEditableField from '@components/SidebarCRM/sections/SidebarCRMSection/_components/SidebarCRMSectionEditableField/SidebarCRMSectionEditableField';
import { useDispatch } from 'react-redux';
import { SelectOptionType } from '@ts/common.types';
import { SelectCustomVariableSettings } from 'respona_api/generated/people_pb';

const composeOptionsFromSettings = (settings: SelectCustomVariableSettings.AsObject) => {
  return settings.itemsList
    .sort((a, b) => (a.order <= b.order ? -1 : 1))
    .map((it) => {
      return { label: it.name, value: it.uid, color: it.color };
    });
};

function enrichOptionsWithColor(
  options: SelectOptionType[],
  select: SelectCustomVariableSettings.AsObject
) {
  if (options?.length > 0) {
    return options.map((opt) => {
      const object = select.itemsList.find((it) => it.uid === opt.value);
      return { ...opt, color: object?.color };
    });
  }

  return [];
}

const renderEditableField = (
  key: string,
  initialValue: string,
  onSave: (newValue: string) => void,
  renderContent: (value: string) => string
) => (
  <React.Fragment key={key}>
    <div
      className="contacts-sidebar-section-variables-variable-info-label w-100"
      key={`label-${key}`}
    >
      {key}
    </div>
    <SidebarCRMSectionEditableField
      key={`value-${key}`}
      initialValue={initialValue}
      onSave={(_, newValue) => onSave(newValue)}
      renderContent={renderContent}
      extraRowClass="padding-top-none"
    />
  </React.Fragment>
);

function CustomVariable({
  item,
  variableValues,
  members,
  handleSaveStringVariable,
  handleSaveSelectVariable,
}) {
  const dispatch = useDispatch();

  const assignList = useMemo(() => {
    const sortedMembers = members
      .sort((a, b) => Number(b.isMe) - Number(a.isMe))
      .map((member) => ({
        value: member.userId,
        label: member.isMe ? 'Assign to me' : member.fullName,
        logoUrl: member.logoUrl,
      }));

    return [
      {
        value: -1,
        label: 'Unassigned',
        logoUrl: '',
      },
      ...sortedMembers,
    ];
  }, [members]);

  const variableValue = variableValues?.find((it) => it.variableId === item.id);

  switch (item.type) {
    case CustomVariableType.TEXT:
      return renderEditableField(
        item.title,
        variableValue?.stringValue || '',
        (newValue) => handleSaveStringVariable(newValue, item, variableValue),
        (value) => (value?.length ? value : '-')
      );

    case CustomVariableType.INT:
      return renderEditableField(
        item.title,
        variableValue?.stringValue || '',
        (newValue) => handleSaveStringVariable(newValue, item, variableValue),
        (value) => (Number.isNaN(value) ? '-' : fromNumberAhrefFormatHelper(Number(value)))
      );

    case CustomVariableType.URL:
      return renderEditableField(
        item.title,
        variableValue?.stringValue || '',
        (newValue) => {
          const urlPattern = /^(https?:\/\/)?([\w.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
          if (urlPattern.test(newValue)) {
            handleSaveStringVariable(newValue, item, variableValue);
          } else {
            dispatch(addNotification({ title: 'Invalid URL format', type: 'warning' }));
          }
        },
        (value) => (value?.length ? value : '-')
      );

    case CustomVariableType.CHECKBOX:
      return (
        <div key={item.id} className="contacts-sidebar-section-variables-variable-info-label w-100">
          <div>{item.title}</div>
          <Checkbox
            value={variableValue?.stringValue === 'true'}
            onChange={({ target: { checked } }) =>
              handleSaveStringVariable(checked.toString(), item, variableValue)
            }
          />
        </div>
      );

    case CustomVariableType.DATE_AND_TIME:
      return (
        <div key={item.id} className="contacts-sidebar-section-variables-variable-info-label w-100">
          <div className="contacts-sidebar-section-variables-variable-info-label w-100">
            {item.title}
          </div>
          <HeaderDateFilter
            value={{
              startDate: Number.isNaN(variableValue?.stringValue)
                ? null
                : Number(variableValue?.stringValue),
            }}
            onChangeDatarange={(value) =>
              handleSaveStringVariable(value.startDate.getTime().toString(), item, variableValue)
            }
            isRangePicker={false}
            id={item.id.toString()}
            skipDayCheck
          />
        </div>
      );

    case CustomVariableType.TEAM_MEMBER: {
      const composeAssignee = (userIdValue: string) => {
        if (userIdValue == null) {
          return {
            value: -1,
            label: 'Unassigned',
            logoUrl: '',
          };
        }
        const assignedUserId = Number(userIdValue);
        const user = assignList.find((it) => it.value === assignedUserId);
        if (user) {
          return {
            value: assignedUserId,
            label:
              user.label === 'Assign to me'
                ? members.find(({ userId }) => userId === user.value).fullName
                : user.label,
            logoUrl: user.logoUrl,
          };
        }
        if (assignedUserId) {
          return {
            value: assignedUserId,
            label: 'Removed User',
            logoUrl: '',
          };
        }
        return {
          value: -1,
          label: 'Unassigned',
          logoUrl: '',
        };
      };
      return (
        <div key={item.id} style={{ width: '100%' }}>
          <div className="contacts-sidebar-section-variables-variable-info-label w-100">
            {item.title}
          </div>
          <div className="contacts-sidebar-section-variables-variable-info-select">
            <Select
              openMenuOnFocus
              classNamePrefix="assign"
              placeholder="Assign user"
              onChange={(value) => {
                handleSaveStringVariable(value.value.toString(), item, variableValue);
              }}
              value={composeAssignee(variableValue?.stringValue)}
              options={assignList}
              additionalComponents={{ Control: CustomAvatar, Option: CustomOptionAvatar }}
              additionalStyles={{
                control: (provided) => ({
                  ...provided,
                  minHeight: '40px',
                  borderRadius: '8px',
                  paddingLeft: '8px',
                }),
                option: () => ({
                  display: 'flex',
                  gridColumnGap: '8px',
                  alignItems: 'center',
                  width: '100%',
                  color: 'white',
                  padding: '8px 12px',
                  '&:hover, &:focus': {
                    backgroundColor: '#181818',
                  },
                }),
              }}
            />
          </div>
        </div>
      );
    }

    case CustomVariableType.RATING: {
      return (
        <div key={item.id} className="contacts-sidebar-section-variables-variable-info-label w-100">
          <div className="contacts-sidebar-section-variables-variable-info-label w-100">
            {item.title}
          </div>
          <Rating
            emptySymbol={<SVGIcon icon="star" color="#BDBDBD" />}
            fullSymbol={<SVGIcon icon="fullStar" />}
            initialRating={
              Number.isNaN(variableValue?.stringValue) ? 0 : Number(variableValue?.stringValue)
            }
            onChange={(value) => handleSaveStringVariable(value.toString(), item, variableValue)}
          />
        </div>
      );
    }

    case CustomVariableType.SELECT: {
      const values = transformCustomVariableOptionsToOptions(
        variableValue?.selectValue?.valuesList
      );
      const coloredValue = enrichOptionsWithColor(values, item.select);
      const initialValue = coloredValue?.length ? values[0] : null;

      return (
        <div key={item.id} className="contacts-sidebar-section-variables-variable-info-label w-100">
          <div className="contacts-sidebar-section-variables-variable-info-label w-100">
            {item.title}
          </div>
          <div className="contacts-sidebar-section-variables-variable-info-select">
            <Select
              options={composeOptionsFromSettings(item.select)}
              value={initialValue}
              onChange={(opt) => handleSaveSelectVariable([opt], item, variableValue)}
            />
          </div>
        </div>
      );
    }

    case CustomVariableType.MULTI_SELECT: {
      const values = transformCustomVariableOptionsToOptions(
        variableValue?.selectValue?.valuesList
      );
      const coloredValues = enrichOptionsWithColor(values, item.select);

      return (
        <div key={item.id} className="contacts-sidebar-section-variables-variable-info-label w-100">
          <div className="contacts-sidebar-section-variables-variable-info-label w-100">
            {item.title}
          </div>
          <div className="contacts-sidebar-section-variables-variable-info-select">
            <Select
              isMulti
              isClearable={false}
              visibleMultiCount={3}
              options={composeOptionsFromSettings(item.select)}
              value={coloredValues}
              onChange={(opt) => handleSaveSelectVariable(opt, item, variableValue)}
              additionalComponents={{ MultiValueContainer }}
            />
          </div>
        </div>
      );
    }

    default:
      return <div />;
  }
}

export default CustomVariable;
