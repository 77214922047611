import React from 'react';
import { useQueryClient } from 'react-query';

import SidebarCRM, { ContentDataType, GeneralDataType } from '@components/SidebarCRM/SidebarCRM';
import { SECTION_TYPE } from '@components/SidebarCRM/SidebarCRMContent/SidebarCRMContent';

import useWorkspaceMembers from '@hooks/useWorkspaceMembers';
import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';

import {
  PipelineOpportunityType,
  RelationshipPipelineFilterType,
  RelationshipPipelineStageType,
} from '@ts/relationshipPipeline.types';
import { WebsiteType } from '@ts/company.types';
import { PersonBaseType, RelationshipStatusType } from '@ts/people.types';

import {
  getAllCompanyTagsApi,
  getTagsByCompanyIdApi,
  addCompanyTagApi,
  removeCompanyTagApi,
  fetchPeopleCompanyNotesApi,
  addCompanyNoteApi,
  removeCompanyNoteApi,
  getWebsiteByIdApi,
  getAllMetricsViewSettingsByCompanyIdApi,
  setMetricsValueByIdApi,
  deleteMetricsValueByIdApi,
  showMetricsByTypeApi,
  loadMetricsValueByIdApi,
  updateCompanyNoteApi,
} from '@api/website.api';
import { getPreviewEmailsApi } from '@api/mailbox.api';
import { CustomVariableValueRelatedTo } from 'respona_api/generated/common_pb';
import {
  createOrUpdateCustomVariableValueApi,
  getCustomVariableValuesApi,
} from '@api/customVariables.api';
import {
  removePersonApi,
  removePersonEmailApi,
  updatePersonApi,
  updatePersonEmailApi,
  updatePersonStatus,
} from '@api/people.api';
import { assignStageOpportunityApi, moveStageOpportunityApi } from '@api/relationshipPipeline';
import { DEALS_WITH_FILTERS_QUERY_KEY } from '@hooks/useRelationshipOpportunitiesWithFilters';
import { DEALS_WITHOUT_FILTERS_QUERY_KEY } from '@hooks/useRelationshipOpportunities';
import { PIPELINE_COLUMN_QUERY_KEY } from '@hooks/useRelationshipPipelineStages';

export type ItemIdType = string | number;
type Props = {
  isOpen: boolean;
  itemId: number;
  onClose: () => void;
  headerData: {
    logoUrl: string;
    title: string;
    url: string;
  };
  contentData: {
    opportunityId: number;
    assignedToUserId: number;
    pipelineTitle: string;
    stageTitle: string;
    stageId: number;
    createdAt: number;
  };
  pipelineId: number;
  pipelineStages: RelationshipPipelineStageType[];
  searchQuery: string;
  appliedFilters: RelationshipPipelineFilterType[];
};

const paramStageIndex = 3;

function PipelinesSidebar({
  isOpen,
  itemId,
  onClose,
  headerData,
  contentData,
  pipelineId,
  pipelineStages,
  searchQuery,
  appliedFilters,
}: Props): JSX.Element {
  const queryClient = useQueryClient();
  const workspaceId = useCurrentWorkspaceId();
  const { composeMemberOptions } = useWorkspaceMembers();

  if (!contentData) {
    return null;
  }

  const allOpportunitiesByPipelineId = (pId: number) => {
    return queryClient
      .getQueriesData({
        queryKey: [DEALS_WITHOUT_FILTERS_QUERY_KEY, pId],
      })
      .map(([opportunityQueryKey]) => opportunityQueryKey)
      .filter((opportunityQueryKey) => opportunityQueryKey[paramStageIndex]);
  };

  const findOpportunity = (id: number) => {
    return allOpportunitiesByPipelineId(pipelineId).filter(
      (opportunityQueryKey) => opportunityQueryKey[paramStageIndex] === id
    );
  };

  const onAssignNewMember = (userId: number, opportunityId: number, stageId: number) => {
    assignStageOpportunityApi(opportunityId, workspaceId, userId).then(() => {
      findOpportunity(stageId).forEach((key) => {
        queryClient.setQueryData(key, ({ pages, pageParams }) => {
          const updatePages = pages.map((page) =>
            page.map((item: PipelineOpportunityType) => {
              if (item.id === opportunityId) return { ...item, assignedToUserId: userId };
              return item;
            })
          );

          return {
            pageParams,
            pages: updatePages,
          };
        });
      });
    });
  };

  const onMoveOpportunity = (stageId: number, opportunityId: number) => {
    moveStageOpportunityApi(workspaceId, pipelineId, stageId, opportunityId).then((response) => {
      let moveFromStageId;
      let breakpoint;
      for (const key of allOpportunitiesByPipelineId(pipelineId)) {
        if (breakpoint) {
          break;
        }
        queryClient.setQueryData(key, ({ pages, pageParams }) => {
          const updatedPages = pages.map((page: PipelineOpportunityType[]) => {
            return page.filter((opportunity) => {
              if (opportunity.id !== opportunityId) {
                return true;
              }
              breakpoint = true;
              moveFromStageId = opportunity.stageId;
              return false;
            });
          });

          return { pageParams, pages: updatedPages };
        });
      }
      const resOppo = { ...response, targetTimeAt: Date.now() };
      let targetSearchKey;
      if (appliedFilters.length > 0) {
        targetSearchKey = [
          DEALS_WITH_FILTERS_QUERY_KEY,
          pipelineId,
          workspaceId,
          searchQuery,
          appliedFilters,
        ];
      } else {
        targetSearchKey = [
          DEALS_WITHOUT_FILTERS_QUERY_KEY,
          pipelineId,
          workspaceId,
          stageId,
          searchQuery,
        ];
      }

      queryClient.setQueryData(targetSearchKey, ({ pages, pageParams }) => {
        const updatedPages = [...pages];
        updatedPages[updatedPages.length - 1] = [...updatedPages[updatedPages.length - 1], resOppo];

        return {
          pageParams,
          pages: updatedPages,
        };
      });
      if (appliedFilters.length === 0) {
        queryClient.setQueryData(
          [PIPELINE_COLUMN_QUERY_KEY, workspaceId, pipelineId],
          (columns: RelationshipPipelineStageType[] = []) => {
            return columns.map((column) => {
              if (column.id === stageId) {
                return {
                  ...column,
                  opportunitiesNumber: column.opportunitiesNumber + 1,
                };
              }

              if (column.id === moveFromStageId) {
                return {
                  ...column,
                  opportunitiesNumber: column.opportunitiesNumber - 1,
                };
              }

              return column;
            });
          }
        );
      }
    });
  };

  const mapContentData: GeneralDataType[] = [
    {
      key: 'assignedToUserId',
      title: 'Assignee',
      value: contentData.assignedToUserId,
      type: ContentDataType.select,
      isEditable: true,
      options: composeMemberOptions(false),
      onChange: (value: number) =>
        onAssignNewMember(value, contentData.opportunityId, contentData.stageId),
    },
    {
      key: 'pipelineTitle',
      title: 'Pipeline',
      value: contentData.pipelineTitle,
      type: ContentDataType.text,
      isEditable: false,
    },
    {
      key: 'stageId',
      title: 'Stage',
      value: contentData.stageId,
      type: ContentDataType.select,
      isEditable: true,
      icon: 'flagStriped',
      options: pipelineStages.map((stage) => {
        return { label: stage.title, value: stage.id };
      }),
      onChange: (value: number) => onMoveOpportunity(value, contentData.opportunityId),
    },
    {
      key: 'createdAt',
      title: 'Created on',
      transformCreatedAt: true,
      value: contentData.createdAt,
      type: ContentDataType.text,
      isEditable: false,
    },
  ];

  const QUERY_KEY_BASE = 'pipelines-sidebar';
  const updateLocalWrapper = (cb: (prevData: WebsiteType) => WebsiteType) => {
    queryClient.setQueryData<WebsiteType>([`${QUERY_KEY_BASE}-website`, itemId, workspaceId], cb);
  };
  const onSuccessShowMetricByType = (res, variables) => {
    updateLocalWrapper((prevData: WebsiteType) => {
      return {
        ...prevData,
        metricsList: variables.enabled
          ? [...prevData.metricsList, res]
          : prevData.metricsList.filter((d) => d.type !== variables.type),
      };
    });
  };
  const onSuccessDeleteMetricValue = (_, variables) => {
    updateLocalWrapper((prevData: WebsiteType) => {
      return {
        ...prevData,
        metricsList: prevData.metricsList.filter((item) => item.id !== variables.id),
      };
    });
  };
  const onUpdatedContact = (
    key: keyof PersonBaseType,
    value: string,
    updatedContact: PersonBaseType
  ) => {
    updatePersonApi({
      ...updatedContact,
      [key]: value,
      workspaceId,
    });
    updateLocalWrapper((prevData: WebsiteType) => {
      return {
        ...prevData,
        contactsList: prevData.contactsList.map((contact) => {
          if (contact.id !== updatedContact.id) {
            return contact;
          }

          return { ...contact, [key]: value };
        }),
      };
    });
  };
  const onUpdatedStatus = (contactId: number, status: RelationshipStatusType) => {
    updatePersonStatus(contactId, status);
    updateLocalWrapper((prevData: WebsiteType) => {
      return {
        ...prevData,
        contactsList: prevData.contactsList.map((contact) => {
          if (contact.id !== contactId) {
            return contact;
          }

          return { ...contact, status };
        }),
      };
    });
  };
  const onSaveEmail = (contactId: number, initialValue: string, email: string) => {
    updatePersonEmailApi(contactId, initialValue, email);
    updateLocalWrapper((prevData: WebsiteType) => {
      return {
        ...prevData,
        contactsList: prevData.contactsList.map((contact) => {
          if (contact.id !== contactId) {
            return contact;
          }

          return {
            ...contact,
            emailsList: contact.emailsList.map((item) => {
              if (item.email !== initialValue) {
                return item;
              }

              return { ...item, email };
            }),
          };
        }),
      };
    });
  };
  const onRemovedContact = (contactId: number) => {
    removePersonApi(contactId);
    updateLocalWrapper((prevData: WebsiteType) => {
      return {
        ...prevData,
        contactsList: prevData.contactsList.filter((contact) => {
          return contact.id !== contactId;
        }),
      };
    });
  };
  const onRemovedEmail = (contactId: number, email: string) => {
    removePersonEmailApi(contactId, email);

    updateLocalWrapper((prevData: WebsiteType) => {
      return {
        ...prevData,
        contactsList: prevData.contactsList.map((contact) => {
          if (contact.id !== contactId) {
            return contact;
          }

          return {
            ...contact,
            emailsList: contact.emailsList.filter((item) => item.email !== email),
          };
        }),
      };
    });
  };
  const onGetEmail = (payloadId: number) => {
    return getPreviewEmailsApi(workspaceId, null, payloadId);
  };
  const onCreatedContact = (contact: PersonBaseType) => {
    updateLocalWrapper((prevData: WebsiteType) => {
      return {
        ...prevData,
        contactsList: [
          ...prevData.contactsList,
          {
            id: contact.id,
            name: contact.name,
            jobPosition: contact.jobPosition,
            avatarUrl: contact.avatarUrl,
            emailsList: contact.emailsList,
            socialLinksList: contact.socialLinksList,
            status: contact.status,
            ownerId: contact.ownerId,
            totalItems: prevData.totalItems + 1,
            workspaceId: contact.workspaceId,
          },
        ],
      };
    });
  };
  const sectionsMeta = {
    [SECTION_TYPE.METRICS]: {
      queryKey: `${QUERY_KEY_BASE}-metrics`,
      queryKeyWebsite: `${QUERY_KEY_BASE}-website`,
      title: 'Website',
      isShownWebsiteInfo: true,
      isOpenSection: true,
      getWebsite: getWebsiteByIdApi,
      getAllMetricsViewSettings: getAllMetricsViewSettingsByCompanyIdApi,
      setMetricValue: setMetricsValueByIdApi,
      deleteMetricValue: deleteMetricsValueByIdApi,
      onSuccessDeleteMetricValue,
      showMetricByType: showMetricsByTypeApi,
      onSuccessShowMetricByType,
      loadMetricValueById: loadMetricsValueByIdApi,
      openUrl: `/relationships/websites?id=${itemId}`
    },
    [SECTION_TYPE.CONTACTS]: {
      queryKeyWebsite: `${QUERY_KEY_BASE}-website`,
      getWebsite: getWebsiteByIdApi,
      updateContact: onUpdatedContact,
      onCreatedContact,
      updateStatus: onUpdatedStatus,
      removeEmail: onRemovedEmail,
      saveEmail: onSaveEmail,
      removeContact: onRemovedContact,
    },
    [SECTION_TYPE.EMAILS]: {
      queryKey: `${QUERY_KEY_BASE}-emails`,
      queryKeyWebsite: `${QUERY_KEY_BASE}-website`,
      title: 'Related conversations',
      getEmails: onGetEmail,
    },
    [SECTION_TYPE.NOTES]: {
      queryKey: `${QUERY_KEY_BASE}-notes`,
      getNote: fetchPeopleCompanyNotesApi,
      addNote: addCompanyNoteApi,
      updateNote: updateCompanyNoteApi,
      removeNote: removeCompanyNoteApi,
    },
    [SECTION_TYPE.TAGS]: {
      queryKey: `${QUERY_KEY_BASE}-tags`,
      getAllTags: getAllCompanyTagsApi,
      getAddedTags: getTagsByCompanyIdApi,
      addTag: addCompanyTagApi,
      removeTag: removeCompanyTagApi,
    },
    [SECTION_TYPE.VARIABLES]: {
      queryKey: `${QUERY_KEY_BASE}-variables`,
      itemId: contentData.opportunityId,
      relatedTo: CustomVariableValueRelatedTo.RELATIONSHIP_OPPORTUNITY,
      getVariables: getCustomVariableValuesApi,
      createOrUpdateVariable: createOrUpdateCustomVariableValueApi,
    },
  };

  return (
    <div>
      <SidebarCRM
        type="pipelines"
        itemId={itemId}
        isOpen={isOpen}
        onClose={onClose}
        headerData={headerData}
        contentData={mapContentData}
        sectionsMeta={sectionsMeta}
      />
    </div>
  );
}

export default PipelinesSidebar;
