import React from 'react';
import { useQuery } from 'react-query';
import parse from 'emailjs-mime-parser';
import { ThreadMessage } from 'respona_api/generated/mailbox_pb';
import { getLatestEmailByThreadId } from '@api/mailbox.api';
import { SVGIcon } from '@uikit/Icon/Icon';
import Loader from '@uikit/Loader/Loader';
import { NamedAvatar } from '@uikit/Avatar/Avatar';
import MimeMessage from '@uikit/MimeMessage/MimeMessage';
import { ThreadPreviewResponseType } from '@ts/mailboxInbox.types';
import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';

interface ContactsSidebarCRMSectionEmailsPreviewProps {
  email: ThreadPreviewResponseType;
}

const ContactsSidebarCRMSectionEmailsPreview: React.FC<
  ContactsSidebarCRMSectionEmailsPreviewProps
> = ({ email }) => {
  const threadUid = email?.uid;
  const currentWorkspaceId = useCurrentWorkspaceId();

  const { data: preview, isLoading } = useQuery<ThreadMessage.AsObject>(
    ['latest-email-by-thread', threadUid],
    () => getLatestEmailByThreadId(threadUid, currentWorkspaceId),
    {
      enabled: !!threadUid,
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 5 * (60 * 1000),
    }
  );

  return (
    <div className="contacts-sidebar-section-emails__email-preview">
      <div className="contacts-sidebar-section-emails__email-preview-header">
        <div
          className="contacts-sidebar-section-emails__email-preview-header-link"
          onClick={() => window.open(`/inbox/inbox?threadId=${threadUid}`, '_blank')}
        >
          <SVGIcon icon="externalLink" color="#BDBDBD" size={12} />
        </div>
        <NamedAvatar
          size="m"
          userName={email.fromName}
          className="contacts-sidebar-section-emails__email-preview-header-avatar"
        />
        <div className="contacts-sidebar-section-emails__email-preview-header-text">
          <span className="contacts-sidebar-section-emails__email-preview-header-text-email">
            {email.fromEmail}
          </span>
          <span>{email.fromEmail}</span>
          <span>Subject: {email.subject}</span>
        </div>
      </div>
      <Loader withTopMargin isLoading={isLoading || !preview}>
        <div className="contacts-sidebar-section-emails__email-preview-content">
          {preview?.content ? <MimeMessage mimeMessage={parse(preview.content)} /> : null}
        </div>
      </Loader>
    </div>
  );
};

export default ContactsSidebarCRMSectionEmailsPreview;
