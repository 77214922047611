import React from 'react';

export default ({ color = '#949494' }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 11.3334V14.6667"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.33325 11.3334H12.6666V10.16C12.6665 9.91198 12.5971 9.66888 12.4664 9.45807C12.3357 9.24726 12.1487 9.07709 11.9266 8.96671L10.7399 8.36671C10.5178 8.25632 10.3308 8.08616 10.2001 7.87534C10.0694 7.66453 10.0001 7.42143 9.99992 7.17337V4.00004H10.6666C11.0202 4.00004 11.3593 3.85957 11.6094 3.60952C11.8594 3.35947 11.9999 3.02033 11.9999 2.66671C11.9999 2.31309 11.8594 1.97395 11.6094 1.7239C11.3593 1.47385 11.0202 1.33337 10.6666 1.33337H5.33325C4.97963 1.33337 4.64049 1.47385 4.39044 1.7239C4.14039 1.97395 3.99992 2.31309 3.99992 2.66671C3.99992 3.02033 4.14039 3.35947 4.39044 3.60952C4.64049 3.85957 4.97963 4.00004 5.33325 4.00004H5.99992V7.17337C5.99979 7.42143 5.93046 7.66453 5.79973 7.87534C5.66901 8.08616 5.48206 8.25632 5.25992 8.36671L4.07325 8.96671C3.85111 9.07709 3.66417 9.24726 3.53344 9.45807C3.40271 9.66888 3.33338 9.91198 3.33325 10.16V11.3334Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
