import React from 'react';

import { useDispatch } from 'react-redux';

import Button from 'src/uikit/Button/Button';

import OnboardingPageHeader from 'src/components/Onboarding/OnboardingPageHeader/OnboardingPageHeader';
import { updateAuthStepRequest } from '@redux/thunks/userProfileRequests';
import { fetchBillingCredits } from '@redux/thunks/billings.thunks';

import VideoFrame from '@components/Campaigns/Dashboard/_components/VideoFrame/VideoFrame';

import './OnboardingTutorial.scss';

const OnboardingTutorial = (): JSX.Element => {
  const dispatch = useDispatch();

  const handleClickGoToDashboard = async () => {
    await dispatch(fetchBillingCredits());
    dispatch(updateAuthStepRequest(6));
  };

  return (
    <div className="onboarding-welcome">
      <OnboardingPageHeader
        icon="video"
        title="Welcome to Respona"
        subtitle="Watch a quick tutorial to help you better navigate the app."
      />

      <div className="onboarding-welcome__content">
        <div className="onboarding-welcome__video">
          <VideoFrame />
        </div>

        <Button onClick={handleClickGoToDashboard} className="onboarding-welcome__continue-btn">
          Continue to next step
        </Button>
      </div>
    </div>
  );
};

export default OnboardingTutorial;
