import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ContentAutomationSearchingMode } from 'respona_api/generated/automation-content_pb';
import { BacklinksSearchSource, BacklinksSearchSourceMap } from 'respona_api/generated/common_pb';
import { DispatchType } from 'src/store';

import { integrationsSelector } from '@redux/selectors/integrations.selectors';

import MetricsContainer from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/MetricsContainer/MetricsContainer';
import AutomationSourceSelect from '@components/CampaignSettings/ContactAutomationSetup/_components/AutomationSourceSelect/AutomationSourceSelect';
import { StepsContext } from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/CASearchTypeWrapper/CASearchTypeWrapper';
import BacklinksSearchAdvancedSettings from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/BacklinksSearchAdvancedSettings/BacklinksSearchAdvancedSettings';
import ContentSearchEditorV2, {
  onChangeQueriesType,
} from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/ContentSearchEditor/ContentSearchEditorV2';

import './BacklinksSearch.scss';
import SettingsStep from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/SettingsStep/SettingsStep';
import { BacklinksSearchType } from '@ts/automationContent.types';
import { automationContentData } from '@redux/selectors/automationContent.selectors';
import { CampaignPipelineType } from 'respona_api/generated/pipeline_pb';

const BacklinksSearchSteps = ({
  setSourceRef,
  data,
}: {
  setSourceRef: (value: number) => void;
  data?: BacklinksSearchType;
}) => {
  const dispatch = useDispatch<DispatchType>();

  const { activeStepIndex, onChangeActiveStepIndex } = useContext(StepsContext);
  const { integrations } = useSelector(integrationsSelector);

  const [source, setSource] = useState<BacklinksSearchSourceMap[keyof BacklinksSearchSourceMap]>(
    BacklinksSearchSource.RESPONA_BACKLINKS_SEARCH
  );

  const automationConfig = useSelector(
    automationContentData(ContentAutomationSearchingMode.BACKLINKS_SEARCH, source)
  );

  const handleChangeUrls: onChangeQueriesType = (urlsList) => {
    dispatch(automationConfig.set({ urlsList }));
  };

  const handleChangeAdvancedSettings = (advancedSettings: Partial<BacklinksSearchType>) => {
    dispatch(automationConfig.set(advancedSettings));
  };

  useEffect(() => {
    setSourceRef(source);
  }, [source]);

  useEffect(() => {
    onChangeActiveStepIndex(1);
  }, []);

  const config = automationConfig.data as BacklinksSearchType;

  if (!config) {
    return null;
  }

  switch (activeStepIndex) {
    case 1:
      return (
        <>
          <div className="backlinks-search__title">Select source:</div>
          <AutomationSourceSelect
            backlinksIntegrations
            integrations={integrations}
            selectedSource={source}
            setSelectedSource={setSource}
          />

          <ContentSearchEditorV2
            searchSourceKey="backlinksConfig"
            onChangeQueries={handleChangeUrls}
            queries={config?.urlsList || []}
            source={source}
          />
          <BacklinksSearchAdvancedSettings
            source={source}
            settings={config}
            onChangeSettings={handleChangeAdvancedSettings}
          />
        </>
      );
    case 2:
      return <MetricsContainer searchSource={source} isBacklinksSearch />;
    case 3:
      return <SettingsStep data={data} type={CampaignPipelineType.BACKLINK_SEARCH} />;
    default:
      onChangeActiveStepIndex(1);
      return null;
  }
};

export default BacklinksSearchSteps;
