import AuthService from '../../../respona_api/generated/auth_pb_service';

const service = (function () {
  let instance = null;

  return {
    getInstance: (): AuthService.AuthApiServiceClient => {
      if (!instance) {
        // @ts-ignore
        instance = new AuthService.AuthApiServiceClient(API_HOST);
      }

      return instance;
    },
  };
})();

export default service;
