import React from 'react';
import { nanoid } from 'nanoid';

export default ({
  color = '#C4C6CD',
  size = 14,
}: {
  color?: string;
  size?: number;
}): JSX.Element => {
  const id = nanoid();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#${id})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.42607 4.21461V10.6984L1.42661 10.6997C1.42816 10.7029 1.4334 10.712 1.44662 10.7238C1.47384 10.748 1.52612 10.7747 1.60037 10.7747H4.04054C4.43433 10.7747 4.75357 11.0939 4.75357 11.4877C4.75357 11.8815 4.43433 12.2008 4.04054 12.2008H1.60037C0.797476 12.2008 0 11.6048 0 10.6991V3.40302C0 2.49734 0.797477 1.90137 1.60037 1.90137H10.9174C11.7203 1.90137 12.5177 2.49734 12.5177 3.40302V4.83274C12.5177 5.22654 12.1985 5.54577 11.8047 5.54577C11.4109 5.54577 11.0917 5.22654 11.0917 4.83274V4.2148L6.672 7.35509C6.4247 7.5308 6.09331 7.5308 5.84601 7.35509L1.42607 4.21461ZM9.87843 3.32744H2.63958L6.259 5.89914L9.87843 3.32744ZM6.86384 7.6562C7.1423 7.37774 7.59377 7.37774 7.87223 7.6562L9.44772 9.23169L11.0232 7.6562C11.3017 7.37774 11.7531 7.37774 12.0316 7.6562C12.3101 7.93466 12.3101 8.38613 12.0316 8.66458L10.4561 10.2401L12.0316 11.8156C12.3101 12.094 12.3101 12.5455 12.0316 12.824C11.7531 13.1024 11.3017 13.1024 11.0232 12.824L9.44772 11.2485L7.87223 12.824C7.59377 13.1024 7.1423 13.1024 6.86384 12.824C6.58539 12.5455 6.58539 12.094 6.86384 11.8156L8.43934 10.2401L6.86384 8.66458C6.58539 8.38613 6.58539 7.93466 6.86384 7.6562Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="13.31" height="13.31" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
