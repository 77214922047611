import { Node } from 'slate';
import { ReactEditor } from 'slate-react';

import { toggleBlock } from '@uikit/RichEditor/SlateComponents';

const hasText = (item) => {
  if (!!item?.text || item.type === 'variable') {
    return true;
  }
  if (!!item?.children?.length) {
    return item.children.some(hasText);
  }
  return false;
};

const withBulletPoints = (editor: ReactEditor) => {
  const { insertBreak, deleteBackward } = editor;

  editor.insertBreak = () => {
    const parent: { type?: string; children?: any[]; text?: string } = Node.parent(
      editor,
      editor.selection.focus.path
    );

    if (parent?.type === 'list-item') {
      const parentParent: { type?: string; children?: any[]; text?: string } = Node.parent(
        editor,
        editor.selection.focus.path.slice(0, editor.selection.focus.path.length)
      );

      if (!parent.children.some(hasText) && !!parentParent?.type) {
        toggleBlock(editor, parentParent.type);
        return;
      }
    }

    insertBreak();
  };

  editor.deleteBackward = (e) => {
    const parent: { type?: string; children?: any[]; text?: string } = Node.parent(
      editor,
      editor.selection.focus.path
    );

    if (parent?.type === 'list-item') {
      const parentParent: { type?: string; children?: any[]; text?: string } = Node.parent(
        editor,
        editor.selection.focus.path.slice(0, editor.selection.focus.path.length - 1)
      );

      if (
        !parent.children.some(hasText) &&
        parent === parentParent?.children?.[0] &&
        !!parentParent?.type
      ) {
        toggleBlock(editor, parentParent.type);
        return;
      }
    }

    deleteBackward(e);
  };

  return editor;
};

export default withBulletPoints;
