import React from 'react';

import { SVGIcon } from '@uikit/Icon/Icon';
import Display from '@components/Display/Display';

import { RenderFunctionType } from '@components/CampaignSettings/Filters/filtersConfig';

import './SearchFilter.scss';

const SearchFilter = ({
  label,
  filterKey,
  render: Render = () => null,
  isOpen,
  onOpenFilter,
  onChangeFilterValue,
  sourceType,
  value,
  icon,
}: {
  label: string;
  filterKey: string;
  render: RenderFunctionType;
  isOpen: boolean;
  onOpenFilter: (string) => void;
  onChangeFilterValue: (filterKey: string, filterValue: string) => void;
  sourceType: number | string;
  value: any;
  icon: string;
  [key: string]: any;
}): JSX.Element => {
  const handleFilterClick = () => onOpenFilter(filterKey);
  const handleSetFilter = (filterValue) => onChangeFilterValue(filterKey, filterValue);

  return (
    <div className="campaign-builder-content-filter">
      <div className="campaign-builder-content-filter__filter-info" onClick={handleFilterClick}>
        <div className="campaign-builder-content-filter__label">{label}</div>
        <div className="campaign-builder-content-filter__add-icon">
          {isOpen ? <SVGIcon icon="minusCircle" /> : <SVGIcon icon="plusCircle" />}
        </div>
      </div>
      <Display isVisible={isOpen}>
        <Render onApply={handleSetFilter} sourceType={sourceType} value={value} />
      </Display>
    </div>
  );
};

export default SearchFilter;
