import loadingStatuses from '@constants/loadingStatuses';
import { SequenceType, TemplateContainerType, TemplateType } from "@ts/template.types";
import {
  SET_ANALYTICS_TEMPLATE,
  SET_ANALYTICS_TEMPLATE_LS,
  UPDATE_ANALYTICS_TEMPLATE_STEP,
} from '@redux/actions/campaignAnalytics.actions';
import { FileAttachmentType } from '@ts/common.types';
import { updateItemInListByIdHelper } from '@helpers/commonHelpers';

export type CampaignAnalyticsStateType = {
  template: {
    data: TemplateContainerType | null;
    loadingStatus: string;
  };
};

const initialState: CampaignAnalyticsStateType = {
  template: {
    loadingStatus: loadingStatuses.PENDING,
    data: null,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ANALYTICS_TEMPLATE:
      return {
        ...state,
        template: {
          ...state.template,
          data: payload,
        },
      };
    case SET_ANALYTICS_TEMPLATE_LS:
      return {
        ...state,
        template: {
          ...state.template,
          loadingStatus: payload,
        },
      };
    case UPDATE_ANALYTICS_TEMPLATE_STEP:
      return {
        ...state,
        template: {
          ...state.template,
          data: {
            ...state.template.data,
            stepsList: updateItemInListByIdHelper(state.template.data.stepsList, payload),
          },
        },
      };
    default:
      return state;
  }
};
