import React from 'react';

const LeaderBoardIllustaration = (): JSX.Element => {
    return (
      <svg width="216" height="145" viewBox="0 0 216 145" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="#511_data_analytics_flatline">
            <path id="Vector" d="M215.119 137.169H17.9847C17.8872 137.169 17.7937 137.13 17.7248 137.061C17.6559 136.992 17.6172 136.898 17.6172 136.8V0.623069C17.6172 0.52542 17.6559 0.43177 17.7248 0.362722C17.7937 0.293673 17.8872 0.254883 17.9847 0.254883C18.0822 0.254883 18.1756 0.293673 18.2445 0.362722C18.3135 0.43177 18.3522 0.52542 18.3522 0.623069V136.432H215.119C215.216 136.432 215.31 136.471 215.379 136.54C215.448 136.609 215.487 136.703 215.487 136.8C215.487 136.898 215.448 136.992 215.379 137.061C215.31 137.13 215.216 137.169 215.119 137.169Z" fill="#D1D3D4"/>
            <path id="Vector_2" d="M127.676 40.5488H86.7659V136.417H127.676V40.5488Z" fill="white"/>
            <path id="Vector_3" d="M127.669 40.5489V136.417H86.766V40.5489H127.669ZM127.669 39.8125H86.766C86.5711 39.8125 86.3841 39.8901 86.2463 40.0282C86.1084 40.1663 86.031 40.3536 86.031 40.5489V136.417C86.031 136.613 86.1084 136.8 86.2463 136.938C86.3841 137.076 86.5711 137.154 86.766 137.154H127.669C127.864 137.154 128.051 137.076 128.188 136.938C128.326 136.8 128.404 136.613 128.404 136.417V40.5489C128.404 40.3536 128.326 40.1663 128.188 40.0282C128.051 39.8901 127.864 39.8125 127.669 39.8125Z" fill="#231F20"/>
            <path id="Vector_4" d="M71.5588 72.9492H30.6487V136.417H71.5588V72.9492Z" fill="white"/>
            <path id="Vector_5" d="M71.5587 72.9493V136.417H30.6486V72.9493H71.5587ZM71.5587 72.2129H30.6486C30.4536 72.2129 30.2667 72.2905 30.1289 72.4286C29.991 72.5667 29.9136 72.754 29.9136 72.9493V136.417C29.9136 136.613 29.991 136.8 30.1289 136.938C30.2667 137.076 30.4536 137.154 30.6486 137.154H71.5587C71.7536 137.154 71.9406 137.076 72.0784 136.938C72.2162 136.8 72.2937 136.613 72.2937 136.417V72.9493C72.2937 72.754 72.2162 72.5667 72.0784 72.4286C71.9406 72.2905 71.7536 72.2129 71.5587 72.2129Z" fill="#231F20"/>
            <path id="Vector_6" d="M183.793 3.05273H142.883V136.417H183.793V3.05273Z" fill="white"/>
            <path id="Vector_7" d="M183.786 3.05278V136.417H142.883V3.05278H183.786ZM183.786 2.31641H142.883C142.688 2.31641 142.501 2.39399 142.363 2.53208C142.226 2.67018 142.148 2.85748 142.148 3.05278V136.417C142.148 136.613 142.226 136.8 142.363 136.938C142.501 137.076 142.688 137.154 142.883 137.154H183.786C183.981 137.154 184.168 137.076 184.306 136.938C184.444 136.8 184.521 136.613 184.521 136.417V3.05278C184.521 2.85748 184.444 2.67018 184.306 2.53208C184.168 2.39399 183.981 2.31641 183.786 2.31641Z" fill="#231F20"/>
            <path id="Vector_8" d="M113.88 124.068C115.355 122.746 117.023 121.659 118.827 120.843C118.974 126.137 116.827 131.012 114.843 134.348H108.397" fill="white"/>
            <path id="Vector_9" d="M114.843 134.717H108.397C108.3 134.717 108.206 134.678 108.137 134.609C108.068 134.54 108.03 134.446 108.03 134.349C108.03 134.251 108.068 134.157 108.137 134.088C108.206 134.019 108.3 133.981 108.397 133.981H114.615C116.394 130.939 118.43 126.381 118.444 121.411C116.873 122.196 115.413 123.187 114.101 124.356C114.032 124.424 113.94 124.461 113.843 124.461C113.747 124.461 113.655 124.424 113.586 124.356C113.552 124.322 113.524 124.281 113.506 124.236C113.487 124.192 113.477 124.143 113.477 124.095C113.477 124.046 113.487 123.998 113.506 123.953C113.524 123.908 113.552 123.868 113.586 123.833C115.089 122.479 116.793 121.366 118.636 120.534C118.688 120.507 118.746 120.493 118.805 120.493C118.863 120.493 118.921 120.507 118.974 120.534C119.023 120.567 119.064 120.61 119.093 120.661C119.123 120.713 119.14 120.77 119.143 120.829C119.297 126.293 117.033 131.3 115.108 134.533C115.081 134.582 115.044 134.625 114.997 134.657C114.951 134.689 114.899 134.71 114.843 134.717Z" fill="#231F20"/>
            <path id="Vector_10" d="M104.634 125.497C106.221 117.213 112.719 111.999 112.719 111.999C113.927 115.9 114.327 120.007 113.895 124.068C113.569 127.58 112.829 131.04 111.69 134.377H104.759" fill="white"/>
            <path id="Vector_11" d="M111.675 134.717H104.744C104.647 134.717 104.553 134.678 104.484 134.609C104.415 134.54 104.377 134.446 104.377 134.349C104.377 134.251 104.415 134.157 104.484 134.088C104.553 134.019 104.647 133.981 104.744 133.981H111.418C112.505 130.76 113.217 127.424 113.542 124.039C113.947 120.214 113.61 116.346 112.55 112.648C111.08 113.951 106.339 118.716 105.031 125.564C105.011 125.66 104.955 125.745 104.874 125.8C104.793 125.855 104.693 125.876 104.597 125.858C104.501 125.839 104.417 125.782 104.362 125.701C104.307 125.62 104.286 125.52 104.303 125.424C105.898 117.103 112.439 111.772 112.506 111.713C112.552 111.676 112.606 111.652 112.663 111.64C112.721 111.629 112.78 111.631 112.836 111.646C112.893 111.664 112.945 111.695 112.988 111.738C113.03 111.78 113.061 111.832 113.079 111.889C114.309 115.836 114.717 119.994 114.277 124.106C113.938 127.636 113.183 131.114 112.028 134.467C112.002 134.54 111.954 134.603 111.891 134.648C111.828 134.693 111.753 134.717 111.675 134.717Z" fill="#231F20"/>
            <path id="Vector_12" d="M101.209 134.349H107.353C107.412 132.53 107.001 129.768 105.031 126.19C104.891 125.947 104.766 125.718 104.634 125.497C101.076 119.459 97.8203 119.525 97.8203 119.525C96.4459 125.88 99.3491 131.543 101.209 134.349Z" fill="white"/>
            <path id="Vector_13" d="M107.353 134.717H101.209C101.149 134.717 101.09 134.702 101.038 134.674C100.985 134.646 100.94 134.605 100.907 134.555C99.0184 131.698 96.0563 125.939 97.4602 119.452C97.4781 119.37 97.5224 119.297 97.5863 119.244C97.6501 119.191 97.7299 119.16 97.813 119.157C97.9526 119.157 101.319 119.157 104.95 125.306L105.347 126.042C107.412 129.79 107.78 132.67 107.721 134.4C107.708 134.489 107.664 134.57 107.597 134.628C107.529 134.686 107.442 134.718 107.353 134.717ZM101.407 133.98H106.993C106.88 131.291 106.094 128.672 104.707 126.366L104.318 125.681C101.539 120.969 98.9596 120.092 98.1143 119.93C96.9751 125.902 99.6137 131.219 101.385 133.98H101.407Z" fill="#231F20"/>
            <path id="Vector_14" d="M102.326 144.106H114.38L116.313 134.349H100.385L102.326 144.106Z" fill="#231F20"/>
            <path id="Vector_15" d="M24.8863 137.117H0.881172C0.783705 137.117 0.690232 137.078 0.621313 137.009C0.552393 136.94 0.513672 136.846 0.513672 136.749C0.513672 136.651 0.552393 136.558 0.621313 136.488C0.690232 136.419 0.783705 136.381 0.881172 136.381H24.8863C24.9837 136.381 25.0772 136.419 25.1461 136.488C25.2151 136.558 25.2538 136.651 25.2538 136.749C25.2538 136.846 25.2151 136.94 25.1461 137.009C25.0772 137.078 24.9837 137.117 24.8863 137.117Z" fill="#D1D3D4"/>
            <path id="Vector_16" d="M20.4102 134.819C20.1962 134.821 19.9865 134.76 19.8075 134.643C19.5634 134.483 19.3923 134.233 19.3317 133.947C19.2711 133.662 19.3259 133.364 19.4841 133.118L51.927 82.7356C52.0182 82.5943 52.1404 82.4757 52.2844 82.3889C52.4283 82.3021 52.5901 82.2495 52.7575 82.2349C52.9246 82.2188 53.0932 82.2416 53.2501 82.3017C53.4069 82.3617 53.5477 82.4573 53.6616 82.581L79.2028 110.291L108.787 55.9022C108.873 55.7424 108.997 55.6064 109.148 55.5062C109.3 55.406 109.473 55.3447 109.654 55.3279C109.834 55.3123 110.015 55.341 110.182 55.4116C110.348 55.4822 110.495 55.5925 110.609 55.7329L136.702 87.8755L168.608 35.6888C168.762 35.4402 169.007 35.2627 169.291 35.195C169.575 35.1274 169.874 35.1752 170.122 35.328C170.247 35.4027 170.355 35.5015 170.441 35.6187C170.526 35.736 170.588 35.8691 170.621 36.0105C170.655 36.1518 170.66 36.2984 170.636 36.4417C170.612 36.585 170.56 36.7221 170.482 36.8449L137.768 90.4086C137.676 90.5593 137.549 90.6854 137.398 90.7765C137.247 90.8675 137.076 90.9207 136.9 90.9315C136.725 90.9441 136.549 90.9138 136.387 90.8432C136.226 90.7727 136.084 90.664 135.974 90.5265L109.933 58.4574L80.4008 112.75C80.3172 112.904 80.1982 113.035 80.0538 113.134C79.9094 113.233 79.7437 113.295 79.5703 113.317C79.3963 113.336 79.2203 113.315 79.0559 113.255C78.8915 113.195 78.743 113.098 78.6222 112.971L53.0074 85.1657L21.3362 134.355C21.2311 134.502 21.0919 134.621 20.9305 134.702C20.7691 134.783 20.5905 134.823 20.4102 134.819Z" fill="#221CB6"/>
            <path id="Vector_17" d="M176.642 29.504L175.267 61.3521C175.257 61.5967 175.184 61.8344 175.054 62.042C174.925 62.2496 174.744 62.42 174.529 62.5365C174.314 62.653 174.073 62.7117 173.829 62.7067C173.584 62.7018 173.346 62.6334 173.136 62.5083L163.801 56.9266L152.776 72.7217C149.429 71.3542 146.374 69.3599 143.772 66.8455L154.562 51.3817L144.596 45.4907C144.378 45.3606 144.199 45.1741 144.078 44.9507C143.957 44.7274 143.898 44.4755 143.908 44.2215C143.917 43.9675 143.995 43.7208 144.133 43.5075C144.271 43.2941 144.464 43.122 144.691 43.0091L174.613 28.208C174.83 28.1022 175.071 28.0539 175.312 28.0678C175.553 28.0816 175.786 28.1572 175.989 28.2872C176.193 28.4172 176.36 28.5973 176.474 28.8104C176.588 29.0234 176.646 29.2623 176.642 29.504Z" fill="#221CB6"/>
            <path id="Vector_18" d="M110.477 61.4551C113.387 61.4551 115.747 59.0912 115.747 56.1753C115.747 53.2593 113.387 50.8955 110.477 50.8955C107.566 50.8955 105.207 53.2593 105.207 56.1753C105.207 59.0912 107.566 61.4551 110.477 61.4551Z" fill="#221CB6"/>
            <path id="Vector_19" d="M53.5807 88.3399C56.4913 88.3399 58.8507 85.976 58.8507 83.0601C58.8507 80.1441 56.4913 77.7803 53.5807 77.7803C50.6702 77.7803 48.3108 80.1441 48.3108 83.0601C48.3108 85.976 50.6702 88.3399 53.5807 88.3399Z" fill="#221CB6"/>
            <path id="Vector_20" d="M57.344 48.6788H33.2874C33.1905 48.6769 33.0982 48.6375 33.0296 48.5688C32.9611 48.5002 32.9218 48.4076 32.9199 48.3106C32.9199 48.2129 32.9586 48.1193 33.0276 48.0502C33.0965 47.9812 33.19 47.9424 33.2874 47.9424H57.344C57.4414 47.9424 57.5349 47.9812 57.6038 48.0502C57.6728 48.1193 57.7115 48.2129 57.7115 48.3106C57.7096 48.4076 57.6703 48.5002 57.6018 48.5688C57.5332 48.6375 57.4409 48.6769 57.344 48.6788Z" fill="#D1D3D4"/>
            <path id="Vector_21" d="M71.5589 40.917H33.2874C33.1905 40.9152 33.0982 40.8758 33.0296 40.8071C32.9611 40.7385 32.9218 40.6459 32.9199 40.5489C32.9199 40.4512 32.9586 40.3576 33.0276 40.2885C33.0965 40.2195 33.19 40.1807 33.2874 40.1807H71.5589C71.6563 40.1807 71.7498 40.2195 71.8187 40.2885C71.8877 40.3576 71.9264 40.4512 71.9264 40.5489C71.9245 40.6459 71.8852 40.7385 71.8167 40.8071C71.7481 40.8758 71.6558 40.9152 71.5589 40.917Z" fill="#D1D3D4"/>
            <path id="Vector_22" d="M71.5589 33.1563H33.2874C33.19 33.1563 33.0965 33.1175 33.0276 33.0485C32.9586 32.9794 32.9199 32.8858 32.9199 32.7881C32.9199 32.6905 32.9586 32.5968 33.0276 32.5278C33.0965 32.4587 33.19 32.4199 33.2874 32.4199H71.5589C71.6563 32.4199 71.7498 32.4587 71.8187 32.5278C71.8877 32.5968 71.9264 32.6905 71.9264 32.7881C71.9264 32.8858 71.8877 32.9794 71.8187 33.0485C71.7498 33.1175 71.6563 33.1563 71.5589 33.1563Z" fill="#D1D3D4"/>
            <path id="Vector_23" d="M71.5589 25.3946H33.2874C33.19 25.3946 33.0965 25.3558 33.0276 25.2867C32.9586 25.2177 32.9199 25.124 32.9199 25.0264C32.9199 24.9287 32.9586 24.8351 33.0276 24.766C33.0965 24.697 33.19 24.6582 33.2874 24.6582H71.5589C71.6563 24.6582 71.7498 24.697 71.8187 24.766C71.8877 24.8351 71.9264 24.9287 71.9264 25.0264C71.9264 25.124 71.8877 25.2177 71.8187 25.2867C71.7498 25.3558 71.6563 25.3946 71.5589 25.3946Z" fill="#D1D3D4"/>
            <path id="Vector_24" d="M71.5589 17.6329H33.2874C33.19 17.6329 33.0965 17.5941 33.0276 17.525C32.9586 17.456 32.9199 17.3623 32.9199 17.2647C32.9218 17.1676 32.9611 17.0751 33.0296 17.0064C33.0982 16.9378 33.1905 16.8984 33.2874 16.8965H71.5589C71.6558 16.8984 71.7481 16.9378 71.8167 17.0064C71.8852 17.0751 71.9245 17.1676 71.9264 17.2647C71.9264 17.3623 71.8877 17.456 71.8187 17.525C71.7498 17.5941 71.6563 17.6329 71.5589 17.6329Z" fill="#D1D3D4"/>
        </g>
    </svg>
  );
};
 export default LeaderBoardIllustaration;
