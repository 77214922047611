import React from 'react';

export default ({color = '#8F9199', size = 15} : {color?: string; size?: number}) : JSX.Element => (
  <svg width={size} height={size} viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="pantone 1" clipPath="url(#clip0_21365_10724)">
      <g id="Group">
        <path id="Vector" d="M10.5164 4.41467L12.75 5.76392L8.6626 12.5306" stroke={color} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_2" d="M8.66699 1.88245L11.0003 2.4617L8.66991 11.8674" stroke={color} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_3" d="M8.66683 0.583374H2.8335V13.4167H8.66683V0.583374Z" stroke={color} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_4" d="M5.75033 11.6667C6.07249 11.6667 6.33366 11.4055 6.33366 11.0833C6.33366 10.7612 6.07249 10.5 5.75033 10.5C5.42816 10.5 5.16699 10.7612 5.16699 11.0833C5.16699 11.4055 5.42816 11.6667 5.75033 11.6667Z" stroke={color} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_5" d="M2.8335 8.75H8.66683" stroke={color} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_21365_10724">
        <rect width="14" height="14" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
