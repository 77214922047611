// package: storage.file
// file: file-storage.proto

var file_storage_pb = require("./file-storage_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var common_pb = require("./common_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var FileStorageService = (function () {
  function FileStorageService() {}
  FileStorageService.serviceName = "storage.file.FileStorageService";
  return FileStorageService;
}());

FileStorageService.upload = {
  methodName: "upload",
  service: FileStorageService,
  requestStream: false,
  responseStream: true,
  requestType: file_storage_pb.UploadRequest,
  responseType: file_storage_pb.UploadResponse
};

FileStorageService.uploadImage = {
  methodName: "uploadImage",
  service: FileStorageService,
  requestStream: false,
  responseStream: false,
  requestType: file_storage_pb.UploadImageRequest,
  responseType: file_storage_pb.UploadResponse
};

FileStorageService.delete = {
  methodName: "delete",
  service: FileStorageService,
  requestStream: false,
  responseStream: false,
  requestType: file_storage_pb.DeleteFileRequest,
  responseType: google_protobuf_empty_pb.Empty
};

FileStorageService.downloadImportReport = {
  methodName: "downloadImportReport",
  service: FileStorageService,
  requestStream: false,
  responseStream: false,
  requestType: file_storage_pb.DownloadImportReportRequest,
  responseType: common_pb.FileResponse
};

exports.FileStorageService = FileStorageService;

function FileStorageServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

FileStorageServiceClient.prototype.upload = function upload(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(FileStorageService.upload, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

FileStorageServiceClient.prototype.uploadImage = function uploadImage(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(FileStorageService.uploadImage, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

FileStorageServiceClient.prototype.delete = function pb_delete(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(FileStorageService.delete, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

FileStorageServiceClient.prototype.downloadImportReport = function downloadImportReport(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(FileStorageService.downloadImportReport, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.FileStorageServiceClient = FileStorageServiceClient;

