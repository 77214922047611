import React from 'react';

export default ({ color = '#000', size = 12 }) => (
  <svg width={size} height={size} viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.04517 0.267831L11.7535 4.9845C11.8316 5.06197 11.8936 5.15413 11.9359 5.25568C11.9782 5.35723 12 5.46615 12 5.57616C12 5.68617 11.9782 5.79509 11.9359 5.89664C11.8936 5.99819 11.8316 6.09036 11.7535 6.16783C11.5974 6.32304 11.3862 6.41016 11.166 6.41016C10.9458 6.41016 10.7346 6.32304 10.5785 6.16783L6.41183 2.04283L2.28683 6.16783C2.1307 6.32304 1.91949 6.41016 1.69933 6.41016C1.47918 6.41016 1.26797 6.32304 1.11183 6.16783C1.03309 6.09065 0.970452 5.99861 0.927539 5.89705C0.884624 5.79549 0.862293 5.68642 0.861835 5.57616C0.862293 5.46591 0.884624 5.35684 0.927539 5.25528C0.970452 5.15371 1.03309 5.06168 1.11183 4.9845L5.82017 0.267831C5.8982 0.183243 5.99291 0.115736 6.09833 0.0695632C6.20375 0.0233905 6.31758 -0.000446548 6.43267 -0.000446553C6.54775 -0.000446558 6.66159 0.0233905 6.76701 0.0695631C6.87242 0.115736 6.96713 0.183243 7.04517 0.267831Z"
      fill={color}
    />
  </svg>
);
