import React from 'react';

export default ({ color = 'black' }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.91389 10.8159C4.91389 10.4017 5.24968 10.0659 5.66389 10.0659H10.4772C10.8914 10.0659 11.2272 10.4017 11.2272 10.8159C11.2272 11.2301 10.8914 11.5659 10.4772 11.5659H5.66389C5.24968 11.5659 4.91389 11.2301 4.91389 10.8159Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.91389 8.02489C4.91389 7.61068 5.24968 7.27489 5.66389 7.27489H10.4772C10.8914 7.27489 11.2272 7.61068 11.2272 8.02489C11.2272 8.43911 10.8914 8.77489 10.4772 8.77489H5.66389C5.24968 8.77489 4.91389 8.43911 4.91389 8.02489Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.91367 5.24037C4.91367 4.82615 5.24946 4.49037 5.66367 4.49037H7.50034C7.91455 4.49037 8.25034 4.82615 8.25034 5.24037C8.25034 5.65458 7.91455 5.99037 7.50034 5.99037H5.66367C5.24946 5.99037 4.91367 5.65458 4.91367 5.24037Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6053 1.0835C11.7018 1.0835 12.6595 1.44653 13.3423 2.15128C14.0223 2.85311 14.36 3.82165 14.36 4.90483V11.0355C14.36 12.1137 14.0247 13.0778 13.3504 13.7785C12.6732 14.482 11.723 14.8481 10.6346 14.8548L10.63 14.8548L5.50523 14.8568L5.504 14.8568C4.40756 14.8568 3.44995 14.4938 2.76728 13.789C2.08745 13.0871 1.75 12.1186 1.75 11.0355V4.90483C1.75 3.82674 2.08504 2.86271 2.75929 2.16216C3.43632 1.45871 4.3864 1.09288 5.47471 1.08618L5.47933 1.08615L10.6053 1.0835ZM10.6055 2.5835L5.48196 2.58616C4.73132 2.59121 4.19237 2.83627 3.84004 3.20233C3.48463 3.57162 3.25 4.13625 3.25 4.90483V11.0355C3.25 11.8084 3.48655 12.3756 3.84472 12.7454C4.20001 13.1122 4.74433 13.3568 5.50377 13.3568M10.6055 2.5835C11.3649 2.58357 11.9096 2.82818 12.265 3.19505C12.6234 3.56488 12.86 4.132 12.86 4.90483V11.0355C12.86 11.804 12.6253 12.3688 12.2696 12.7383C11.9171 13.1046 11.3779 13.3498 10.6273 13.3548L10.6254 13.3548L5.50377 13.3568"
      fill={color}
    />
  </svg>
);
