import { createAction } from 'redux-actions';
import { TemplateStepTransformedType } from '@ts/template.types';

export const csSetSequence = createAction('CS_SET_SEQUENCE');
export const csSetSequenceAdditionalContent = createAction('CS_SET_SEQUENCE_ADDITIONAL_CONTENT');
export const csSetSequenceLs = createAction('CS_SET_SEQUENCE_LS');

export const csUpdateSingleSequenceStep = createAction<TemplateStepTransformedType>(
  'CS_UPDATE_SINGLE_SEQUENCE_STEP'
);
export const csAddSequenceStep = createAction<TemplateStepTransformedType>(
  'CS_ADD_SEQUENCE_STEP'
);

export const csRemoveSequenceStep = createAction<number>(
  'CS_REMOVE_SEQUENCE_STEP'
);
export const csUpdateSequenceStepAttachments = createAction('CS_UPDATE_SEQUENCE_STEP_ATTACHMENTS');
