import DOMPurify from 'dompurify';

import { ThreadPreviewResponseType } from '@ts/mailboxInbox.types';

export const contentTypes = {
  textHtml: 'text/html',
  textPlain: 'text/plain',
  multipartRelated: 'multipart/related',
  multipartMixed: 'multipart/mixed',
  multipartAlternative: 'multipart/alternative',
  multipartReport: 'multipart/report',
  pdf: 'application/pdf',
  image: 'image/',
};

export const sentAndNeedThreadMapper = (list: ThreadPreviewResponseType[]) =>
  list.map(({ threadId, uid, subject, fromName, snippet, lastActivityTime, totalItems }) => ({
    id: threadId,
    uid,
    name: fromName,
    time: lastActivityTime,
    subject,
    snippet,
    totalItems,
  }));

export const emailValidation = (email: string): boolean => {
  const reg = /\S+@\S+\.\S+/;

  return reg.test(email);
};

export const getStringFromUnitBytes = (buffer) => {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  for (let i = 0; i < bytes.byteLength; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return binary;
};

export const inlineImage = (content, imageType) => {
  const imageData = btoa(getStringFromUnitBytes(content));
  return `data:${imageType};base64,${imageData}`;
};

export const sanitizeMimeHtml = (
  html: string,
  prefix = 'mime-message-content',
  replacements: { name: string; value: string }[] = []
): string => {
  let htmlString;

  htmlString = DOMPurify.sanitize(html)
    .replace(/<style([^>]*)>/g, '<style$1>#mimeMessageContent{')
    .replace(/<\/style>/g, '}</style>')
    .replace(/\.(.+)( |)*{/g, `.${prefix}-$1{`)
    .replace(/class=(['"])(.+)(['"])/g, `class="${prefix}-$2"`);

  if (replacements?.length) {
    replacements.forEach((replacement) => {
      const pattern = new RegExp(` src=(['"])cid:${replacement.name.split('.')?.[0]}(['"])`, 'g');

      htmlString = htmlString.replace(pattern, ` src="${replacement.value}"`);
    });
  }

  return htmlString;
};

export const getMessageReplacements = (mimeMessage: {
  [key: string]: any;
}): { name?: string; value?: string }[] => {
  if (
    [
      contentTypes.multipartRelated,
      contentTypes.multipartMixed,
      contentTypes.multipartAlternative,
    ].includes(mimeMessage.contentType.value)
  ) {
    const images = mimeMessage.childNodes
      .filter((node) => node.contentType.value.startsWith(contentTypes.image))
      .filter((image) => image.headers?.['content-disposition']?.[0]?.value === 'inline');

    return images.map((image) => ({
      name: image.headers?.['content-id']?.[0]?.value?.replace(/[<>]/g, '') || '',
      value: inlineImage(image.content, image.contentType.value),
    }));
  }

  return [];
};

export const convertHtmlToPlainText = (html: string) => {
  let tempDivElement = document.createElement('div');

  tempDivElement.innerHTML = html;

  return tempDivElement.textContent || tempDivElement.innerText || '';
};
