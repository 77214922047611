// @ts-nocheck
import { setInsightsDateFilter } from '@redux/actions/insights.actions';
import { insightsDateFilterSelector } from '@redux/selectors/insights.selector';
import { ErrorBoundaryComponent } from '@uikit/ErrorBoundaries/ErrorBoundary';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';

import { getCurrentWorkspaceId } from '@redux/selectors/workspaces.selectors';
import { campaignAnalyticsTemplate } from '@redux/selectors/campaignAnalytics.selectors';

import CampaignAnalyticsSidebar from '@components/CampaignAnalytics/CampaignAnalyticsSidebar/CampaignAnalyticsSidebar';
import People from '@components/CampaignAnalytics/People/People';
import Settings from '@components/CampaignAnalytics/Settings/Settings';
import SentEmails, { InboxList } from '@components/CampaignAnalytics/SentEmails/SentEmails';
import Outbox from '@components/CampaignAnalytics/Outbox/Outbox';
import Sequence from '@components/CampaignAnalytics/Sequence/Sequence';
import PageHeader from '@components/PageHeader/PageHeader';
import Loader from '@uikit/Loader/Loader';

import {
  fetchAnalyticsTemplate,
  updateOpenCampaignData,
} from '@redux/thunks/campaignAnalytics.thunks';
import Button from '@uikit/Button/Button';
import { fetchOpenCampaign } from '@redux/thunks/campaignSettings.thunks';

import ColoredStatusToggler from '@uikit/ColoredStatusToggler/ColoredStatusToggler';
import { campaignStatusColor } from '@constants/campaigns.constants';
import InboxEmails from '@components/CampaignAnalytics/InboxEmails/InboxEmails';
import Display from '@components/Display/Display';

import HeaderDateFilter from '@uikit/HeaderDateFilter/HeaderDateFilter';

import CampaignInsights from '@components/CampaignAnalytics/CampaignInsights/CampaignInsights';

import { changeCampaignStatusApi, getOpenCampaignApi } from '@api/campaign.api';

import NoAccessMessage from '@uikit/NoAccessMessage/NoAccessMessage';

import './CampaignAnalyticsPage.scss';

import loadingStatuses from '@constants/loadingStatuses';
import Opportunities from "@components/CampaignAnalytics/Opportunities/_components/Opportunities";

function CampaignAnalyticsPage({
  match: {
    params: { campaignId },
  },
  location: { pathname },
}): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const queryClient = useQueryClient();
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const dateRange = useSelector(insightsDateFilterSelector);

  const { data: templateData, loadingStatus: templateLs } = useSelector(campaignAnalyticsTemplate);

  const {
    data: openCampaignData,
    isFetching: isOpenCampaignFetching,
    refetch,
    error: openCampaignError,
  } = useQuery(
    ['analytics-open-campaign', campaignId],
    () => getOpenCampaignApi(Number(campaignId), workspaceId),
    {
      enabled: !!campaignId && Number.isInteger(Number(campaignId)) && !!workspaceId,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (openCampaignData) {
      if (openCampaignData.status === 0) {
        history.push(`/campaign-builder/${openCampaignData.id}/opportunities`);
      } else {
        dispatch(fetchAnalyticsTemplate(openCampaignData.lastSequenceId));
      }
    }
  }, [openCampaignData]);

  const handleUpdateSettings = (fields) => {
    dispatch(
      updateOpenCampaignData({
        ...fields,
        id: Number(campaignId),
        workspaceId,
      })
    );
  };

  const handleChangeDate = (value) => {
    dispatch(
      setInsightsDateFilter({
        startDate: +value.startDate,
        endDate: +value.endDate,
      })
    );
  };

  const handleAddOpportunities = () => {
    dispatch(fetchOpenCampaign(openCampaignData?.id, workspaceId)).then(() => {
      history.push(`/campaign-builder/${openCampaignData?.id}/opportunities`);
    });
  };

  const campaignStatus = openCampaignData?.status || 0;

  const updateCampaignStatus = (status: number) => {
    changeCampaignStatusApi(openCampaignData?.id, openCampaignData?.workspaceId, status).then(
      () => {
        queryClient.setQueryData(['analytics-open-campaign', campaignId], (openCampaignCache) => ({
          ...openCampaignCache,
          status,
        }));
      }
    );
  };

  const handleChangeCampaignStatus = (isActive: boolean) => {
    if (isActive && (campaignStatus === 2 || campaignStatus === 5 || campaignStatus === 0)) {
      updateCampaignStatus(1);
    } else if (!isActive && campaignStatus === 1) {
      updateCampaignStatus(2);
    }
  };

  function RenderHeader({ children = null }: { children?: JSX.Element }): JSX.Element {
    return (
      <PageHeader
        howToLink="https://help.respona.com/en/articles/6406739-how-to-use-campaign-analytics"
        renderHeaderTitle={() => (
          <div className="campaign-analytics__header-title">
            <ColoredStatusToggler
              color={campaignStatusColor[campaignStatus]}
              isActive={campaignStatus === 1 || campaignStatus === 4}
              onChange={handleChangeCampaignStatus}
            />
            <span className="campaign-analytics__header-title-text">
              {openCampaignData?.title || ''}
            </span>
          </div>
        )}
      >
        <div className="campaign-analytics__header">
          {children}
          <Display isVisible={/campaign-analytics\/\d+\/insights/g.test(pathname)}>
            <HeaderDateFilter value={dateRange} onChangeDatarange={handleChangeDate} />
          </Display>
          <Button
            onClick={handleAddOpportunities}
            className="campaign-analytics__add-opportunities-btn"
          >
            Add opportunities
          </Button>
        </div>
      </PageHeader>
    );
  }

  if (openCampaignError) {
    try {
      const accessDenied = JSON.parse(openCampaignError?.message).code === 2000;

      return accessDenied ? (
        <NoAccessMessage />
      ) : (
        <ErrorBoundaryComponent onRetry={() => refetch()} />
      );
    } catch (e) {
      return <ErrorBoundaryComponent onRetry={() => refetch()} />;
    }
  }

  if (isOpenCampaignFetching || templateLs !== loadingStatuses.LOADED) {
    return (
      <div className="campaign-analytics">
        <Loader withTopMargin isLoading />
      </div>
    );
  }

  return (
    <div className="campaign-analytics">
      <CampaignAnalyticsSidebar campaignId={Number(campaignId)} pathname={pathname} />
      <div className="campaign-analytics__content">
        <Switch>
          <Route path="/campaign-analytics/:campaignId/people">
            <People
              RenderHeader={RenderHeader}
              campaignId={openCampaignData?.id}
              workspaceId={openCampaignData?.workspaceId}
            />
          </Route>
          <Route path="/campaign-analytics/:campaignId/opportunities">
            <Opportunities
              RenderHeader={RenderHeader}
              campaignId={openCampaignData?.id}
              workspaceId={openCampaignData?.workspaceId}
            />
          </Route>
          <Route path="/campaign-analytics/:campaignId/sequence">
            <Sequence
              stepsList={templateData?.stepsList || []}
              enabledUnsubscribeContent={templateData?.enabledUnsubscribeContent}
              enabledReferralContent={templateData?.enabledReferralContent}
              templateId={templateData?.id}
              RenderHeader={RenderHeader}
              openCampaign={openCampaignData}
            />
          </Route>
          <Route path="/campaign-analytics/:campaignId/insights">
            <CampaignInsights
              campaignId={Number(campaignId)}
              workspaceId={workspaceId}
              dateRange={dateRange}
              RenderHeader={RenderHeader}
            />
          </Route>
          <Route path="/campaign-analytics/:campaignId/inbox">
            <InboxEmails
              campaignId={openCampaignData?.id}
              workspaceId={openCampaignData?.workspaceId}
              RenderHeader={RenderHeader}
            />
          </Route>
          <Route path="/campaign-analytics/:campaignId/sent">
            <SentEmails
              listType={InboxList.Sent}
              campaignId={Number(campaignId)}
              workspaceId={openCampaignData?.workspaceId}
              RenderHeader={RenderHeader}
            />
          </Route>
          <Route path="/campaign-analytics/:campaignId/outbox">
            <Outbox
              campaignId={Number(campaignId)}
              workspaceId={openCampaignData?.workspaceId}
              RenderHeader={RenderHeader}
            />
          </Route>
          <Route path="/campaign-analytics/:campaignId/bounced">
            <SentEmails
              listType={InboxList.NeedAttention}
              campaignId={Number(campaignId)}
              workspaceId={openCampaignData?.workspaceId}
              RenderHeader={RenderHeader}
            />
          </Route>
          <Route path="/campaign-analytics/:campaignId/settings">
            <Settings
              openCampaign={openCampaignData}
              onUpdate={handleUpdateSettings}
              RenderHeader={RenderHeader}
            />
          </Route>
          <Route path="/campaign-analytics/:campaignId/*">
            <Redirect to={`/campaign-analytics/${campaignId}/people`} />
          </Route>
        </Switch>
      </div>
    </div>
  );
}

export default CampaignAnalyticsPage;
