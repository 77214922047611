import React, { memo, MutableRefObject, ReactNode } from 'react';
import cn from 'class-names';

import { SVGIcon } from 'src/uikit/Icon/Icon';
import Button from 'src/uikit/Button/Button';

import Display from '../Display/Display';

import './RightSidebar.scss';

type RightSidebarPropsType = {
  isOpen: boolean;
  onClose?: (any) => void;
  onSave?: (any) => void;
  children: ReactNode;
  title?: ReactNode;
  subtitle?: string | ReactNode;
  buttonSaveText?: string;
  isSaveButtonLoading?: boolean;
  isSaveButtonDisabled?: boolean;
  saveButtonLoadingText?: string;
  buttonCancelText?: string;
  barRef?: MutableRefObject<HTMLDivElement>;
  showFooter?: boolean;
  embedded?: boolean;
  width?: string;
};

function RightSidebar({
  title,
  subtitle,
  isOpen,
  onClose,
  children,
  buttonSaveText = 'Save',
  isSaveButtonLoading = false,
  isSaveButtonDisabled = false,
  saveButtonLoadingText,
  buttonCancelText = 'Cancel',
  onSave,
  barRef,
  width,
  showFooter = true,
  embedded = false,
}: RightSidebarPropsType): JSX.Element {
  if (!isOpen) {
    return null;
  }

  const sidebarStyle = width ? { width } : {};

  return (
    <div
      ref={barRef}
      className={cn('right-sidebar', { 'right-sidebar--embedded': embedded })}
      style={sidebarStyle}
    >
      {!embedded && title && (
        <div className="right-sidebar__header">
          <div className="right-sidebar__title">
            {onClose && (
              <span className="right-sidebar__close-btn" onClick={onClose}>
                <SVGIcon icon="arrowLeftCircle" />
              </span>
            )}
            {title}
          </div>
          {/* eslint-disable-next-line no-nested-ternary */}
          {subtitle ? (
            typeof subtitle === 'string' ? (
              <div className="right-sidebar__subtitle">{subtitle}</div>
            ) : (
              // @ts-ignore
              subtitle()
            )
          ) : null}
        </div>
      )}

      {children}

      <Display isVisible={showFooter}>
        <div className="right-sidebar__footer">
          <Display isVisible={onSave !== undefined}>
            <Button
              onClick={onSave}
              size="xl"
              disabled={isSaveButtonDisabled || isSaveButtonLoading}
              isLoading={isSaveButtonLoading}
              loadingText={saveButtonLoadingText}
            >
              {buttonSaveText}
            </Button>
          </Display>

          {onClose && (
            <Button onClick={onClose} type="additional" size="xl">
              {buttonCancelText}
            </Button>
          )}
        </div>
      </Display>
    </div>
  );
}

export default memo(RightSidebar);
