import React, { useRef, useState } from 'react';
import { useQueryClient } from 'react-query';

import { Button } from '@uikit/Button/Button';

import {
  createOrUpdateThreadConversationMessageApi,
  deleteThreadConversationMessageApi,
} from '@api/mailbox.api';
import EmojiButton from '@uikit/RichEditor/_components/EmojiButton/EmojiButton';
import { ThreadConversationMessageType } from '@ts/mailboxInbox.types';

import './InboxComments.scss';
import Avatar from '@uikit/Avatar/Avatar';
import { getDateShort } from '@utils/date';
import ReactTooltip from 'react-tooltip';
import { Mention, MentionsInput } from 'react-mentions';
import useWorkspaceMembers from '@hooks/useWorkspaceMembers';

function InboxComments({
  comments,
  threadUid,
}: {
  comments: Array<any>;
  threadUid: string;
}): JSX.Element {
  const queryClient = useQueryClient();
  const messageFormRef = useRef(null);
  const sendMessageRef = useRef(null);
  const inboxCommentsRef = useRef(null);
  const [message, setMessage] = useState('');
  const [isSendingMessage, setSendingMessage] = useState(false);
  const { members, activeUserId } = useWorkspaceMembers();
  // dirty solution to save "mentionId"
  const mentionIds = useRef(new Map());
  const [isEditable, setIsEditable] = useState<string>(null);

  const handleSendForm = (event, editableId) => {
    if (isSendingMessage || message.length === 0) {
      return;
    }

    event.preventDefault();
    const filteredMentionIds = [];

    // we but we don't filter it out when it's removed from the input
    // so we need to do it before sending
    for (const [id, display] of mentionIds.current) {
      if (message.includes(display)) {
        filteredMentionIds.push(Number(id));
      }
    }

    setSendingMessage(true);
    setMessage('');

    queryClient.setQueryData(
      ['mailbox-comments', threadUid],
      (data: ThreadConversationMessageType[]) => {
        if (editableId !== null) {
          setIsEditable(null);
          return data.map((item) =>
            item.id === editableId ? { ...item, content: message } : item
          );
        }
        return [
          ...data,
          {
            attachmentsList: [],
            content: message,
            createdAt: new Date().getTime(),
            createdBy: activeUserId,
            id: -1,
            mentionedUserIdsList: filteredMentionIds,
          },
        ];
      }
    );

    createOrUpdateThreadConversationMessageApi(threadUid, message, filteredMentionIds).then(
      (response) => {
        mentionIds.current.clear();
        setSendingMessage(false);

        if (editableId === null) {
          queryClient.setQueryData(
            ['mailbox-comments', threadUid],
            (data: ThreadConversationMessageType[]) => {
              return data.map((item) => (item.id === -1 ? response : item));
            }
          );
        }
      }
    );

    setTimeout(() => {
      inboxCommentsRef.current.scrollTop = inboxCommentsRef.current.scrollHeight;
    });
  };

  const removeComment = (commentId) => {
    deleteThreadConversationMessageApi(commentId).then((response) => {
      queryClient.setQueryData(
        ['mailbox-comments', threadUid],
        (data: ThreadConversationMessageType[]) => {
          return data.filter((item) => {
            return item.id !== commentId;
          });
        }
      );
    });
  };
  const editComment = (commentId) => {
    const filteredComment = comments.find((item) => item.id === commentId);

    if (filteredComment) {
      setMessage(filteredComment.content);
      setIsEditable(commentId);
    }
  };

  const submitForm = () => {
    messageFormRef.current.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
  };

  const handleEmoji = (emoji) => {
    const { selectionStart } = sendMessageRef.current;

    setMessage(message.slice(0, selectionStart) + emoji);
  };

  const renderUserSuggestion = (entri) => {
    return <div>{entri.display}</div>;
  };

  const displayTransform = (id, display) => {
    return `@${display}`;
  };

  const handleMentionChange = (event) => {
    setMessage(event.target.value);
  };

  const handleAddMention = (id, display) => {
    mentionIds.current.set(id, `@[${display}](${id})`);
  };

  const parseContent = (content) => {
    const OPEN_SIGNS = '@[';
    const MIDDLE_SIGNS = '](';
    const CLOSED_SIGNS = ')';
    let lastIndex = 0;
    const arr = [];

    for (let i = 0; i < content.length; i++) {
      if (content[i] + content[i + 1] === OPEN_SIGNS) {
        innerLoop: for (let j = i; j < content.length; j++) {
          if (content[j] + content[j + 1] === MIDDLE_SIGNS) {
            for (let k = j; k < content.length; k++) {
              if (content[k] === CLOSED_SIGNS) {
                arr.push(
                  <span key={lastIndex + i}>{content.substring(lastIndex, i)}</span>,
                  <span key={i + 2 + j} className="inbox-comments__mentioned">
                    @{content.slice(i + 2, j)}
                  </span> // username
                );

                lastIndex = i = k + 1;
                break innerLoop;
              }
            }
          }
        }
      }
    }

    if (lastIndex < content.length - 1) {
      arr.push(<span key="last-part">{content.substring(lastIndex)}</span>);
    }

    return arr;
  };

  const getUser = (id) => {
    return members.find(({ userId }) => userId === id);
  };

  return (
    <>
      <div className="inbox-comments" ref={inboxCommentsRef}>
        {comments.map((comment) => {
          const user = getUser(comment.createdBy);
          return (
            <div key={comment.id} className="inbox-comments__comment">
              <div className="inbox-comments__comment-avatar">
                <Avatar url={user?.logoUrl} />
              </div>
              <div className="inbox-comments__comment-container">
                <div className="inbox-comments__comment-header">
                  <div className="inbox-comments__comment-header-title">
                    {user?.fullName || '[Deleted]'}
                  </div>
                  <div className="inbox-comments__comment-header-content">
                    <div className="inbox-comments__comment-header-date">
                      {getDateShort(new Date(comment.createdAt), true, true)}
                    </div>
                    <div className="inbox-comments__comment-header-actions">
                      <Button
                        data-for={`inbox-comments-${comment.id}`}
                        data-tip=""
                        type="ghost"
                        leftIcon="actionsTwoDots"
                        colorIcon="#BDBDBD"
                      />
                      <ReactTooltip
                        id={`inbox-comments-${comment.id}`}
                        className="react-tooltip"
                        place="bottom"
                        effect="solid"
                        event="click"
                        globalEventOff="click"
                        arrowColor="transparent"
                        clickable
                      >
                        <div className="inbox-comments__comment-header-actions-container">
                          <div
                            className="action-item"
                            onClick={() => {
                              editComment(comment.id);
                            }}
                          >
                            Edit
                          </div>
                          <div
                            className="action-item"
                            onClick={() => {
                              removeComment(comment.id);
                            }}
                          >
                            Remove
                          </div>
                        </div>
                      </ReactTooltip>
                    </div>
                  </div>
                </div>
                {comment.content ? (
                  <div className="inbox-comments__comment-content">
                    <div className="inbox-comments__comment-text">
                      {parseContent(comment.content)}
                    </div>

                    <div className="inbox-comments__comment-header-attachments">
                      {comment.attachmentsList
                        ? comment.attachmentsList.map((attachment, id) => {
                            return (
                              <div
                                key={id}
                                className="inbox-comments__comment-header-attachments-item"
                              >
                                {attachment}
                              </div>
                            );
                          })
                        : null}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          );
        })}
      </div>

      <form
        onSubmit={(event) => {
          handleSendForm(event, isEditable);
        }}
        ref={messageFormRef}
        className="inbox-comments-form"
      >
        <MentionsInput
          ref={sendMessageRef}
          className="input inbox-input"
          value={message}
          onChange={handleMentionChange}
          singleLine
          allowSuggestionsAboveCursor
          forceSuggestionsAboveCursor
          placeholder="Add a private comment ..."
        >
          <Mention
            className="inbox-input-mentions"
            trigger="@"
            data={members.map(({ userId, fullName }) => ({ id: userId, display: fullName }))}
            renderSuggestion={renderUserSuggestion}
            appendSpaceOnAdd
            displayTransform={displayTransform}
            onAdd={handleAddMention}
          />
        </MentionsInput>
        <div className="inbox-comments-form__buttons">
          <EmojiButton onChange={handleEmoji} />
          <Button
            className="send-btn"
            disabled={isSendingMessage || message.length === 0}
            onClick={submitForm}
            type="ghost"
            leftIcon="inboxIcon"
            colorIcon="#BDBDBD"
            iconSize={20}
          />
        </div>
      </form>
    </>
  );
}

export default InboxComments;
