import { showReCaptchaApi } from '@api/auth.api';
import OnboardingPayment from '@components/Onboarding/OnboardingPayment/OnboardingPayment';
import { Button } from '@uikit/Button/Button';
import Input from '@uikit/Input/Input';
import React from 'react';
import { useQuery } from 'react-query';

export const LoginPagePaymentFlow = () => {
  // const { data: showCaptchaStatus, isSuccess } = useQuery({
  //   queryKey: 'recaptcha',
  //   queryFn: () => showReCaptchaApi(),
  //   refetchOnWindowFocus: false,
  // });

  return (
    <div className="login-payment-container">
      <OnboardingPayment />
    </div>
  );
};
