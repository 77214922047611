import React from 'react';

export default ({ color = '#200E32' }: { color?: string }): JSX.Element => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.3889 7.55127L10.8343 10.4132C10.1616 10.9406 9.21866 10.9406 8.54599 10.4132L4.96094 7.55127"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.6824 11V7.32876C17.7492 6.19118 17.3499 5.07572 16.5774 4.24216C15.8049 3.4086 14.7267 2.9297 13.5939 2.91699H5.73729C3.30403 2.91699 1.66406 4.89653 1.66406 7.32876V12.7687C1.66406 15.2009 3.30403 17.1804 5.73729 17.1804H9"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 19.0001L15.8407 16.8408"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.25 17.5C15.4927 17.5 16.5 16.4927 16.5 15.25C16.5 14.0074 15.4927 13 14.25 13C13.0074 13 12 14.0074 12 15.25C12 16.4927 13.0074 17.5 14.25 17.5Z"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
