import React from 'react';

export default ({ color = '#000' }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 1.3335C6.88516 1.33464 5.7884 1.61534 4.81008 2.14992C3.83177 2.6845 3.00313 3.45589 2.4 4.3935C1.55171 5.72387 1.20277 7.31198 1.41522 8.87542C1.62767 10.4389 2.38775 11.8763 3.56029 12.932C4.73282 13.9878 6.24181 14.5935 7.81889 14.6413C9.39597 14.6892 10.9389 14.1762 12.1733 13.1935C13.2477 12.3287 14.0274 11.1521 14.4051 9.82561C14.7827 8.49916 14.7397 7.08824 14.2819 5.78728C13.8241 4.48631 12.9741 3.35935 11.849 2.56168C10.7239 1.76401 9.37916 1.33491 8 1.3335ZM9.33334 2.84016C10.2546 3.07862 11.0952 3.55921 11.7681 4.23209C12.441 4.90496 12.9215 5.74561 13.16 6.66683C12.0453 6.69822 10.9573 7.01498 10 7.58683C9.51928 7.04149 8.98306 6.54771 8.4 6.1135C8.98743 5.1208 9.30889 3.99338 9.33334 2.84016ZM8 2.66683C7.99781 3.62351 7.73507 4.56153 7.24 5.38016C7.14 5.32683 7.04667 5.26683 6.94667 5.22016C6.08824 4.76722 5.17036 4.43732 4.22 4.24016C4.71537 3.74184 5.30432 3.34632 5.95301 3.07631C6.60171 2.80631 7.29736 2.66715 8 2.66683ZM3.33334 5.44016C4.37895 5.58542 5.39161 5.91019 6.32667 6.40016L6.41334 6.4535C5.92128 6.9451 5.33705 7.33476 4.69412 7.60017C4.0512 7.86557 3.36222 8.0015 2.66667 8.00016C2.67038 7.10438 2.89965 6.22397 3.33334 5.44016ZM6.66667 13.1602C5.74545 12.9217 4.9048 12.4411 4.23193 11.7682C3.55905 11.0954 3.07846 10.2547 2.84 9.3335C3.73044 9.31373 4.60766 9.11393 5.41884 8.74616C6.23001 8.37838 6.95836 7.85022 7.56 7.1935C8.04194 7.54976 8.48845 7.95162 8.89334 8.3935C8.21825 8.99542 7.67271 9.72843 7.28991 10.5479C6.90711 11.3674 6.69506 12.2561 6.66667 13.1602ZM8 13.3335C8.00144 12.6011 8.15372 11.8768 8.44735 11.2058C8.74097 10.5349 9.16965 9.93154 9.70667 9.4335C9.74667 9.48683 9.78667 9.5335 9.82 9.58683C10.3787 10.4423 10.7931 11.3837 11.0467 12.3735C10.1544 13 9.09025 13.3353 8 13.3335ZM12.14 11.3335C11.8505 10.4515 11.4471 9.61106 10.94 8.8335L10.8 8.66683C11.5751 8.2356 12.4463 8.00634 13.3333 8.00016C13.3267 9.21474 12.9057 10.3907 12.14 11.3335Z"
      fill={color}
    />
  </svg>
);
