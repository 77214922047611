const symbolValidator = (str: string, validator: (string) => boolean): boolean => {
  for (let i = 0; i < str.length; i++) {
    if (validator(str[i])) {
      return true;
    }
  }

  return false;
};

export default (
  password = ''
): {
  score: 0 | 1 | 2 | 3;
  text: string;
} => {
  if (password.length < 8) {
    return {
      score: 0,
      text: 'Has to be minimum of 8 characters long',
    };
  }

  if (!symbolValidator(password, (sym) => !Number.isNaN(Number(sym)))) {
    return {
      score: 1,
      text: 'Use numbers',
    };
  }

  if (!symbolValidator(password, (sym) => sym === sym.toUpperCase() && Number.isNaN(Number(sym)))) {
    return {
      score: 2,
      text: 'Use uppercase letter',
    };
  }

  return {
    score: 3,
    text: 'Password is strong',
  };
};
