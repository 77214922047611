import React, { useEffect, useState } from 'react';

import { isSmallWindowWidth } from '@utils/windowChecks';

export default <T extends { [key: string]: unknown }>(
  WrappedComponent: React.FC<T>,
  widthCheck?: number
): ((props: T) => JSX.Element) => {
  return function WithSmallScreensCheck(props: T) {
    const [isSmallScreen, changeIsSmallScreen] = useState(isSmallWindowWidth(widthCheck));

    useEffect(() => {
      const checkWindowWidth = () => changeIsSmallScreen(isSmallWindowWidth(widthCheck));

      window.addEventListener('resize', checkWindowWidth);

      return () => window.removeEventListener('resize', checkWindowWidth);
    });

    // @ts-ignore
    return <WrappedComponent {...props} isSmallScreen={isSmallScreen} />;
  };
};
