import React from 'react';

const CheckmarkInCircleIcon = ({ color = '#696969' }: { color: string }): JSX.Element => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.6584 6.01602C12.7768 6.48945 12.8359 7.02207 12.8359 7.55469C12.8359 10.8096 10.1729 13.4727 6.91797 13.4727C3.66309 13.4727 1 10.8096 1 7.55469C1 4.2998 3.66309 1.63672 6.91797 1.63672C8.04238 1.63672 9.10762 1.93262 10.0545 2.52441"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M4.55078 6.36914L6.91797 8.73633L13.4277 2.22656"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);

export default CheckmarkInCircleIcon;
