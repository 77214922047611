/**
 * Parse number to short value
 * 9000 -> 9k
 * 13000000 -> 13m
 * @param value
 */
export default (value: number): string => {
  if (value > 1_000_000) {
    return `${Number(value / 1_000_000).toFixed(1)}m`;
  }

  if (value > 1_000) {
    return `${Number(value / 1_000).toFixed(1)}k`;
  }

  return String(value);
};
