import { InsightsFilterField } from 'respona_api/generated/analytics_pb';

export const generateFilter = (filter, filterData) => {
  const { order } = filterData;
  const { field } = filterData;
  let value: string | undefined;
  const { operation } = filterData;
  if (
    filterData.field === InsightsFilterField.DROPDOWN_INSIGHT_WORKSPACE_ID ||
    filterData.field === InsightsFilterField.DROPDOWN_INSIGHT_CAMPAIGN_ID ||
    filterData.field === InsightsFilterField.DROPDOWN_INSIGHT_USER_ID ||
    filterData.field === InsightsFilterField.DROPDOWN_INSIGHT_CAMPAIGN_TEMPLATE_ID ||
    filterData.field === InsightsFilterField.DROPDOWN_INSIGHT_MAILBOX_ACCOUNT_ID
  ) {
    value = filterData.value.value?.toString();
  } else if (filterData.value?.value !== undefined) {
    value = filterData.value?.value.toString()
      ? filterData.value?.value.toString()
      : filterData.value?.value;
  } else if (filterData.value.toString) {
    value = filterData.value.toString();
  } else {
    value = filterData.value;
  }

  filter.setOrder(order);
  filter.setField(field);
  filter.setValue(value);
  filter.setOperation(operation);
  filter.setJoin(filterData.join);

  return filter;
};
