import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import {
  BlackListRecord,
  MailboxHealthResponse,
} from 'respona_api/generated/mailbox-account-health_pb';
import { MailboxAccountType } from '@ts/mailboxAccounts.types';

import { getAccountHealthByIdApi } from '@api/mailboxAccountHealth.api';

import { getDateShort } from '@utils/date';

import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';

import QuestionTooltip from '@pages/CampaignCreationPage/_components/QuestionTooltip/QuestionTooltip';

import Loader from '@uikit/Loader/Loader';
import PieChart from '@uikit/PieChart/PieChart';
import Select from '@uikit/Select/Select';

import PageHeader from '@components/PageHeader/PageHeader';
import HealthCheckStatus from '@components/Insights/EmailHealth/_components/HealthCheckDropdown/_components/HealthCheckStatus/HealthCheckStatus';
import HealthCheckDropdown from '@components/Insights/EmailHealth/_components/HealthCheckDropdown/HealthCheckDropdown';
import HealthScoreTile from '@components/Insights/EmailHealth/_components/HealthScoreTile/HealthScoreTile';
import { IssuesDropdownStatusName } from '@components/Insights/EmailHealth/_components/IssuesDropdown/_components/IssuesDropdownStatus/IssuesDropdownStatus';

// @ts-ignore
import emptyMailboxesIllustration from '@pages/InboxPage/assets/emptyMailboxesIllustration.svg';

import './EmailHealth.scss';
import useMailboxAccounts from '@hooks/useMailboxAccounts';
import { QueryCollectionHookType } from '@ts/common.types';

const selectStyles = {
  control: (provided) => ({
    ...provided,
    marginLeft: '20px',
    borderRadius: '8px',
    width: 320,
    border: '1px solid #DBDFE7',
    backgroundColor: '#F5F6FA',
    '&:focus,&:hover': {
      outline: 'none',
      border: '1px solid #221CB6',
      boxShadow: 'none',
    },
  }),
};

function EmailHealth() {
  const workspaceId = useCurrentWorkspaceId();
  const {
    items: accountMailboxes,
    isLoading: isMailboxAccountsLoading,
    isEmpty: isMailboxAccountsEmpty,
  }: QueryCollectionHookType<MailboxAccountType> = useMailboxAccounts(workspaceId);

  const [selectedEmail, setSelectedEmail] = useState<MailboxAccountType>();

  const { data, isFetching, isRefetching, refetch } = useQuery<
    unknown,
    unknown,
    MailboxHealthResponse.AsObject
  >({
    queryKey: ['email-health', selectedEmail],
    queryFn: () => getAccountHealthByIdApi(selectedEmail.id, workspaceId),
    enabled: !!selectedEmail?.id,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (isMailboxAccountsLoading) {
      return;
    }
    setSelectedEmail(accountMailboxes[0]);
  }, [accountMailboxes, accountMailboxes?.length]);

  const getIssues = (): { title: string; status: IssuesDropdownStatusName }[] => {
    const issues = [];

    if (!data) {
      return [];
    }

    if (!data.ptrValid) {
      issues.push({
        title: 'PTR not valid',
        status: IssuesDropdownStatusName.warning,
      });
    }
    if (!data.spfValid) {
      issues.push({
        title: 'SPF not valid',
        status: IssuesDropdownStatusName.warning,
      });
    }
    if (!data.dmarcValid) {
      issues.push({
        title: 'DMARC not valid',
        status: IssuesDropdownStatusName.warning,
      });
    }
    if (!data.dkimValid) {
      issues.push({
        title: 'DKIM not valid',
        status: IssuesDropdownStatusName.warning,
      });
    }

    if (data.blackListList) {
      data.blackListList.forEach(({ ip, source, isBlocked }) => {
        if (isBlocked) {
          issues.push({
            title: `${ip} found in ${source} Blacklist`,
            status: IssuesDropdownStatusName.danger,
          });
        }
      });
    }

    return issues;
  };

  const noHealthReportPlaceholder = (): JSX.Element => {
    return (
      <div className="email-health--empty">
        <img
          alt="No mailboxes"
          className="email-health--empty-image"
          src={emptyMailboxesIllustration}
        />
        <div className="email-health--empty-text">
          No health report has been generated for this email. Please check back in a few hours.
        </div>
      </div>
    );
  };

  if (!selectedEmail?.id) {
    if (isMailboxAccountsEmpty) {
      return noHealthReportPlaceholder();
    }

    return <Loader isLoading withTopMargin />;
  }

  const mailboxOptions =
    accountMailboxes?.map((item) => ({ label: item.email, value: item.id })) || [];

  const accountIssues = getIssues();

  const blackLists =
    data?.blackListList?.reduce<{ [key: string]: BlackListRecord.AsObject[] }>((acc, item) => {
      return {
        ...acc,
        [item.ip]: (acc[item.ip] || []).concat(item),
      };
    }, {}) || {};

  return (
    <div className="email-health">
      <PageHeader
        title="Email health"
        howToLink="https://help.respona.com/en/articles/6512716-what-is-email-health"
        renderHeaderActions={() => (
          <Select
            additionalStyles={selectStyles}
            value={mailboxOptions.find(({ value }) => value === selectedEmail?.id)}
            onChange={({ value }) =>
              setSelectedEmail(accountMailboxes?.find((item) => item.id === value))
            }
            options={mailboxOptions}
          />
        )}
      />
      <Loader withTopMargin isLoading={!selectedEmail || isFetching || isRefetching}>
        {(!data && !isFetching) || data?.lastCheckAt <= 0 ? (
          noHealthReportPlaceholder()
        ) : (
          <div className="email-health__content">
            <div className="email-health__card" style={{ padding: '27px' }}>
              <div className="email-health__card-row">
                <div className="email-health__card-row">
                  <div className="email-health__title">Health score:</div>
                  <div className="email-health__card-row" style={{ marginLeft: '11px' }}>
                    <PieChart
                      value={data?.healthScore}
                      color={
                        data?.healthScore >= 90
                          ? '#23e771'
                          : data?.healthScore >= 80
                            ? '#FFB800'
                            : '#fc0e0e'
                      }
                      name="emailHealth"
                      size={28}
                      pieWidth={4}
                    />
                    <div className="email-health__title-number" style={{ marginLeft: '9px' }}>
                      {data?.healthScore}
                    </div>
                  </div>
                </div>
                <div className="email-health__card-row">
                  <div className="email-health__last-check">
                    Last Check: {getDateShort(new Date(data?.lastCheckAt), true)}
                  </div>
                  {/* <Button leftIcon="refreshOneArrowAround" onClick={() => refetch()}> */}
                  {/*  Refresh check */}
                  {/* </Button> */}
                </div>
              </div>
            </div>

            {/* ACCOUNT ISSUES */}
            {/* <div className="email-health__card"> */}
            {/*  <div className="email-health__card-header"> */}
            {/*    <div className="email-health__title">Top issues</div> */}
            {/*  </div> */}

            {/*  {accountIssues?.length ? ( */}
            {/*    accountIssues?.map(({ status, title }) => ( */}
            {/*      <IssuesDropdown title={title} status={status} /> */}
            {/*    )) */}
            {/*  ) : ( */}
            {/*    <HealthCheckDropdown title="No issues found" /> */}
            {/*  )} */}

            {/*  <Button className="email-health__button" type="additional"> */}
            {/*    View all issues */}
            {/*  </Button> */}
            {/* </div> */}

            <div className="email-health__card">
              <div className="email-health__card-header">
                <div className="email-health__title">
                  Spam Check
                  <QuestionTooltip
                    style={{ marginLeft: '8px', marginTop: '3px' }}
                    text="We pull your IP records and periodically check to ensure they’re not listed on common blacklists."
                  />
                </div>
                <HealthScoreTile score={data?.blackListScore} />
              </div>
              {[
                {
                  title: (
                    <div className="health-check-dropdown-header__text">
                      <span>IP host list records</span>
                      <HealthCheckStatus success={!!data?.dnsRecordsList?.length} />
                    </div>
                  ),
                  details: data?.dnsRecordsList.map(({ value, type }) => ({
                    title: `${value} - ${type}`,
                  })),
                },
                {
                  title: (
                    <div className="health-check-dropdown-header__text">
                      <span>Common blacklists</span>
                      <HealthCheckStatus
                        success={
                          !Object.values(blackLists).some((item) =>
                            item.some(({ isBlocked }) => isBlocked)
                          )
                        }
                      />
                    </div>
                  ),
                  details: Object.entries(blackLists).map(([key, value]) => ({
                    title: (
                      <div className="health-check-dropdown-header__text">
                        {key}
                        <HealthCheckStatus
                          success={!value.some((item) => item.isBlocked)}
                          text={value.some((item) => item.isBlocked) ? 'Listed' : 'Not listed'}
                        />
                      </div>
                    ),
                    addresses: value.map(({ isBlocked, ip, source }) => ({
                      title: `${source}`,
                      status: isBlocked ? 'Listed' : 'Not listed',
                    })),
                  })),
                },
              ].map(({ title, details }, index) => (
                <HealthCheckDropdown title={title} details={details} key={index} />
              ))}
            </div>

            <div className="email-health__card">
              <div className="email-health__card-header">
                <div className="email-health__title">
                  DNS Checks
                  <QuestionTooltip
                    style={{ marginLeft: '8px', marginTop: '3px' }}
                    text="We check your domain’s DNS records to make sure they meet all necessary standards for email deliverability."
                  />
                </div>
                <HealthScoreTile score={data?.dnsScore} />
              </div>
              {[
                {
                  title: (
                    <div className="health-check-dropdown-header__text">
                      <span>DMARC</span>
                      <HealthCheckStatus success={data?.dmarcValid} />
                    </div>
                  ),
                  details: [{ title: data?.dmarc || 'DMARC record not found' }],
                },
                {
                  title: (
                    <div className="health-check-dropdown-header__text">
                      <span>DKIM</span>
                      <HealthCheckStatus success={data?.dkimValid} />
                    </div>
                  ),
                  details: [{ title: data?.dkimSignature || 'DKIM record not found' }],
                },
                {
                  title: (
                    <div className="health-check-dropdown-header__text">
                      <span>SPF</span>
                      <HealthCheckStatus success={data?.spfValid} />
                    </div>
                  ),
                  details: [{ title: data?.spf || 'SPF record not found' }],
                },
                // {
                //   title: (
                //     <div className="health-check-dropdown-header__text">
                //       <span>PTR</span>
                //       <HealthCheckStatus success={data?.ptrValid} />
                //     </div>
                //   ),
                //   details: [{ title: data?.ptr || 'PTR record not found' }],
                // },
              ].map(({ title, details }, index) => (
                <HealthCheckDropdown title={title} details={details} key={index} />
              ))}
            </div>

            {data?.spamAssassin && (
              <div className="email-health__card">
                <div className="email-health__card-header">
                  <div className="email-health__title">
                    Spam Assassin Check
                    <QuestionTooltip
                      style={{ marginLeft: '8px', marginTop: '3px' }}
                      text="We periodically run SpamAssassin checks on your emails, which is one of the most popular spam filters. Scores above 5 are considered spam."
                    />
                  </div>
                  <HealthScoreTile
                    text={data?.spamAssassin?.spamScore}
                    score={
                      data?.spamAssassin?.spamScore <= 2
                        ? 100
                        : data?.spamAssassin?.spamScore <= 5
                          ? 70
                          : 0
                    }
                  />
                </div>
                {data?.spamAssassin.recordsList
                  ?.map((item) => ({
                    title: (
                      <div className="health-check-dropdown-header__text">
                        <span>{item.ruleName}</span>
                        <span className="health-check-dropdown__description">
                          {item.description}
                        </span>
                        <span className="email-health__label">{item.pts}</span>
                      </div>
                    ),
                  }))
                  .map(({ title }, index) => <HealthCheckDropdown title={title} key={index} />)}
              </div>
            )}
          </div>
        )}
      </Loader>
    </div>
  );
}

export default EmailHealth;
