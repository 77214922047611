import { mapSequenceStepApiInfo } from '@mappers/campaign.mappers';

export const transformPersonalizationStepsUtil = (stepsList) =>
  stepsList
    .sort((itemA, itemB) => itemA.order - itemB.order)
    .reduce((newStepsList, curStep) => {
      const newStep = {
        ...mapSequenceStepApiInfo(curStep),
        emailsToBccList: curStep.threadA.emailsToBccList,
        emailsToCcList: curStep.threadA.emailsToCcList,
      };

      if (newStepsList.length === 0) {
        return [
          ...newStepsList,
          {
            ...newStep,
            delayFromFirstDay: newStep.sendDelay,
          },
        ];
      }

      return [
        ...newStepsList,
        {
          ...newStep,
          delayFromFirstDay:
            newStepsList[newStepsList.length - 1].delayFromFirstDay + newStep.sendDelay,
        },
      ];
    }, []);
