import React from 'react';

const MozLogo = ({ width = 136, height = 18 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 66 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_16769_11049)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 17.0389H3.98238C4.39365 17.0389 4.72997 16.7026 4.72997 16.2913V9.39132L12.1635 17.6702L19.5973 9.39132V16.2913C19.5973 16.7026 19.9337 17.0389 20.3449 17.0389H24.3269V2.25391H20.9456C19.9684 2.25391 19.24 2.72461 18.8464 3.16247L12.1635 10.6052L5.48052 3.16247C5.08734 2.72461 4.35857 2.25391 3.38172 2.25391H0V17.0389Z"
          fill="#24ABE2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44.7177 17.039H64.4187C64.83 17.039 65.1663 16.7026 65.1663 16.2914V12.3537H55.5871L65.1386 2.26758H45.4497C45.0388 2.26758 44.7021 2.60427 44.7021 3.01517V6.95288H54.2692L44.7177 17.039Z"
          fill="#24ABE2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.0961 0C41.1082 0 45.9822 4.20241 45.9822 9.50019C45.9822 14.7976 41.1082 19 35.0961 19C29.0835 19 24.21 14.7976 24.21 9.50019C24.21 4.20241 29.0835 0 35.0961 0ZM35.0961 4.74732C38.0277 4.74732 40.5419 6.87529 40.5419 9.50019C40.5419 12.1247 38.1038 14.2527 35.0961 14.2527C32.0883 14.2527 29.6499 12.1247 29.6499 9.50019C29.6499 6.87529 32.1644 4.74732 35.0961 4.74732Z"
          fill="#24ABE2"
        />
      </g>
      <defs>
        <clipPath id="clip0_16769_11049">
          <rect width="65.1663" height="19" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MozLogo;
