import React from 'react';

export default ({ color = '#FC8E0E' }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1599 5C10.3664 5 10.5602 5.09748 10.6803 5.26173L13.518 9.14428L15.8807 7.60497C16.1005 7.46174 16.3879 7.46537 16.6038 7.61412C16.8198 7.76286 16.92 8.02608 16.8559 8.27658L15.257 14.5266C15.1859 14.8048 14.9301 15 14.6366 15H5.68316C5.40074 15 5.15175 14.819 5.0706 14.5546L3.15201 8.30459C3.07612 8.05739 3.16352 7.79003 3.37199 7.63161C3.58047 7.47318 3.86715 7.45628 4.0936 7.58907L6.78499 9.1672L9.63947 5.26173C9.75952 5.09748 9.95334 5 10.1599 5ZM10.1599 6.70029L7.48263 10.3633C7.28883 10.6284 6.9191 10.7036 6.63319 10.5359L4.85168 9.49132L6.15899 13.75H14.1373L15.2138 9.54178L13.7123 10.52C13.4251 10.7071 13.0378 10.6378 12.8371 10.3633L10.1599 6.70029Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2761 13.125H5.0436V11.875H15.2761V13.125Z"
      fill={color}
    />
  </svg>
);
