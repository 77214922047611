export default (array) => {
  const itemsMap = new Map();

  array.forEach((item) => {
    if (!itemsMap.has(item.type)) {
      itemsMap.set(item.type, item);
    }
  });

  return Array.from(new Set(itemsMap.values()));
};

export const uniqueStringsArray = (array: string[]): string[] => Array.from(new Set(array));
