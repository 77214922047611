import { SummarySearchServiceClient } from 'respona_api/generated/search-summary_pb_service';

const service = (function () {
  let instance = null;

  return {
    getInstance: (): SummarySearchServiceClient => {
      if (!instance) {
        // @ts-ignore
        instance = new SummarySearchServiceClient(API_HOST);
      }

      return instance;
    },
  };
})();

export default service;
