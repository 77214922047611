import React from 'react';

export default (WrappedComponent: React.FC<{ [key: string]: unknown }>) =>
  function WithInlineMargins({
    marginLeft = 0,
    marginRight = 0,
    ...props
  }: {
    marginLeft?: number;
    marginRight?: number;
    [key: string]: unknown;
  }): JSX.Element {
    return (
      <span style={{ marginLeft: `${marginLeft}px`, marginRight: `${marginRight}px` }}>
        <WrappedComponent {...props} />
      </span>
    );
  };
