import React from 'react';

export default ({ color = '#200E32' }: { color?: string }): JSX.Element => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.3889 5.63428L10.8343 8.49617C10.1616 9.02356 9.21866 9.02356 8.54599 8.49617L4.96094 5.63428"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.6824 8.08301V5.41177C17.7492 4.27419 17.3499 3.15873 16.5774 2.32517C15.8049 1.49161 14.7267 1.01271 13.5939 1H5.73729C3.30403 1 1.66406 2.97954 1.66406 5.41177V10.8517C1.66406 13.2839 3.30403 15.2634 5.73729 15.2634H8"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.6666 15.04L14.4285 16L16.3333 14"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 19C17.2091 19 19 17.2091 19 15C19 12.7909 17.2091 11 15 11C12.7909 11 11 12.7909 11 15C11 17.2091 12.7909 19 15 19Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
