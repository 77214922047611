import React from 'react';

import { SearchTypesEnum } from '@ts/contentSearch.types';

import { FilterType } from '@components/CampaignSettings/Filters/filtersConfig/index';

import FilterWithSelect from '../_components/FilterWithSelect/FilterWithSelect';

const uniquePodcastsOption = [
  { value: 'unique', label: 'Unique podcasts' },
  { value: 'all', label: 'All episodes' },
];

export default {
  label: 'Unique podcasts',
  filterKey: 'uniquePodcasts',
  defaultValue: false,
  render: ({
    onApply,
    sourceType,
    value,
  }: {
    onApply: (filterValue: any) => void;
    sourceType: number | string;
    value: any;
  }): JSX.Element => {
    switch (sourceType) {
      case SearchTypesEnum.episodes:
        return (
          <FilterWithSelect
            options={uniquePodcastsOption}
            placeholder="Unique podcasts"
            value={value}
            onApply={onApply}
            defaultValue={uniquePodcastsOption[1]}
          />
        );
      default:
        return null;
    }
  },
} as FilterType;
