import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { audioGenresSelector } from '@redux/selectors/commonInfo.selectors';
import { fetchAudioGenres } from '@redux/thunks/contentSearch.thunks';
import { commonOptionsTransformer } from '@mappers/optionTransformers';
import { checkIsAnyPending } from '@utils/loadingChecks';
import Loader from '@uikit/Loader/Loader';
import Select from '@uikit/Select/Select';

import { FilterType } from '@components/CampaignSettings/Filters/filtersConfig/index';

export default {
  label: 'Category',
  filterKey: 'podcastCategory',
  defaultValue: null,
  render: ({ onApply, value }): JSX.Element => {
    const dispatch = useDispatch();
    const audioGenres = useSelector(audioGenresSelector);

    useEffect(() => {
      if (checkIsAnyPending(audioGenres.loadingStatus)) {
        dispatch(fetchAudioGenres());
      }
    }, []);

    useEffect(() => {
      onApply(audioGenresData[0] || null);
    }, [audioGenres]);

    const audioGenresData = commonOptionsTransformer(audioGenres.data, 'uid');

    return (
      <Loader isLoading={!value} withTopMargin>
        <Select
          placeholder="Select genre"
          options={audioGenresData}
          value={value || null}
          onChange={onApply}
        />
      </Loader>
    );
  },
} as FilterType;
