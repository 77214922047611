import React from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import Button from '@uikit/Button/Button';

// @ts-ignore
import image from './assets/haro.svg';

import './HaroReminder.scss';

const useQuery = () => new URLSearchParams(useLocation().search);

const HaroReminder = (): JSX.Element => {
  const history = useHistory();
  const query = useQuery();

  const handleClickSkip = () => {
    history.push(`/inquiries/all-inquiries?filterId=${query.get('filterId')}`);
  };

  const email = query.get('email');

  return (
    <div className="haro-reminder">
      <img className="haro-reminder__image" src={image} alt="Are you registered in Haro?" />
      <h1 className="haro-reminder__header">Your HARO filter is set up!</h1>
      <p className="haro-reminder__text">
        Please make sure to create a free HARO account{!!email ? ' with' : ''}
      </p>
      {!!email ? <p className="haro-reminder__user-email">{email}</p> : null}
      <Button className="haro-reminder__create-btn">
        <a target="_blank" href="https://www.helpareporter.com/subscriptions/basic/">
          Create HARO account
        </a>
      </Button>
      <p className="haro-reminder__skip" onClick={handleClickSkip}>
        Skip
      </p>
    </div>
  );
};

export default HaroReminder;
