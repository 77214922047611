import UserOrganizationService from '../../../respona_api/generated/user-organization_pb_service';

const service = (function () {
  let instance = null;

  return {
    getInstance: (): UserOrganizationService.UserOrganizationServiceClient => {
      if (!instance) {
        // @ts-ignore
        instance = new UserOrganizationService.UserOrganizationServiceClient(API_HOST);
      }

      return instance;
    },
  };
})();

export default service;
