import React from 'react';

import { WorkspaceType } from '@ts/userOrganization.types';
import ActionsMenu from '@components/ActionsMenu/ActionsMenu';
import TitleRenderer from '@components/Settings/WorkspacesTable/_components/TitleRenderer';
import ChangeTitleInput from '@components/Settings/WorkspacesTable/_components/ChangeTitleInput/ChangeTitleInput';
import Avatar from '@uikit/Avatar/Avatar';

type RenderPropsType = {
  row: WorkspaceType;
  renderProps: {
    [key: string]: any;
  };
};

export default [
  {
    title: '',
    dataKey: 'emptyForSkipFirstColumn',
  },
  {
    title: 'Name',
    dataKey: 'title',
    RenderCell: ({ row }: { row: WorkspaceType }): JSX.Element => (
      <div className="settings-workspaces-table__cell settings-workspaces-table__cell--title">
        <Avatar fullName={row.title} url={row.logoUrl} />
        <div className="settings-workspaces-table__cell--title-details">
          <div className="settings-workspaces-table____workspace-title">{row.title}</div>
        </div>
      </div>
    ),
  },
  {
    title: 'Members',
    dataKey: 'membersCount',
  },
  {
    title: 'Email accounts',
    dataKey: 'mailboxesCount',
  },
  {
    title: 'Campaigns',
    dataKey: 'campaignsCount',
  },
  {
    title: '',
    dataKey: 'actions',
    isSelectable: false,
    RenderCell: ({ row, renderProps }: RenderPropsType): JSX.Element => {
      const actionsConfig = [
        {
          icon: 'editAction',
          title: 'Edit',
          handler: () => renderProps.onEdit(row),
        },
      ];

      return (
        <ActionsMenu
          onRemove={() => renderProps.openModalToRemove(row.id)}
          id={`workspace-actions-${row.id}`}
          actionsConfig={actionsConfig}
          iconClassName="schedules-table__actions-icon"
          tooltipPlace="left"
          catchEvents={false}
        />
      );
    },
  },
];
