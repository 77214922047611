import React from 'react';

import { SVGIcon } from '@uikit/Icon/Icon';

import './HowToUseButton.scss';

const HowToUseButton = ({
  to,
  text,
  style,
}: {
  to: string;
  text?: string;
  style?: React.CSSProperties;
}) => {
  return (
    <a target="_blank" className="how-to-use-button" style={style} href={to}>
      <SVGIcon icon="questionInCircleFilled" size={12} />
      <span className="how-to-use-button__text">{text || 'How to use?'}</span>
    </a>
  );
};

export default HowToUseButton;
