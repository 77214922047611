import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const useBrowserBackButton = (onAction: () => any) => {
  const history = useHistory();

  useEffect(() => {
    const unblock = history.block((tx, action) => {
      if (action === 'POP') {
        onAction();
        return false;
      }

      return true;
    });

    return () => unblock();
  }, []);
};
