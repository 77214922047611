import { handleActions } from 'redux-actions';
import { setLastManualContactsSearch } from '@redux/actions/campaignConfirmContactsManual.actions';
import { csClearOpenCampaignInfo } from '@redux/actions/campaignSettings.actions';

export interface CampaignManualContactsSearchType {
  searchString: string;
  jobSearchString: string;
  page: number;
  limit: number;
  lastData: any[];
}

export interface CampaignManualContactsSearchStateType {
  [key: number]: CampaignManualContactsSearchType;
}

const defaultState: CampaignManualContactsSearchStateType = {};

export default handleActions(
  {
    [setLastManualContactsSearch]: (state: CampaignManualContactsSearchStateType, { payload }) => ({
      ...state,
      [payload.activeOpportunityId]: { ...payload },
    }),
    [csClearOpenCampaignInfo]: () => defaultState,
    /*
      Maybe in future we will save in store last data from query contacts
     */
    // [setLastManualContactsSearchData]: (
    //   state: CampaignManualContactsSearchStateType,
    //   { payload }
    // ) => ({
    //   ...state,
    //   [payload.opportunityId]: {
    //     ...state[payload.opportunityId],
    //     lastData: payload.data,
    //   },
    // }),
  },
  defaultState
);
