import { SVGIcon } from '@uikit/Icon/Icon';
import React, { useState, useEffect, useRef } from 'react';

const DragNDropZone = ({ onLoadFile }): JSX.Element => {
  const dndZoneRef = useRef();
  const [isDragged, changeIsDragged] = useState(false);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      changeIsDragged(true);
    }
  };

  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();

    changeIsDragged(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    changeIsDragged(false);

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      onLoadFile(e.dataTransfer.files[0]);
      /* this.props.handleDrop(e.dataTransfer.files)
      e.dataTransfer.clearData()
      this.dragCounter = 0 */
    }
  };

  const handleLoadFile = (e) => {
    onLoadFile(e.target.files[0]);
  };

  useEffect(() => {
    const dndZone = dndZoneRef.current;
    // @ts-ignore
    dndZone.addEventListener('dragenter', handleDragIn);
    // @ts-ignore
    dndZone.addEventListener('dragleave', handleDragOut);
    // @ts-ignore
    dndZone.addEventListener('dragover', handleDrag);
    // @ts-ignore
    dndZone.addEventListener('drop', handleDrop);

    return () => {
      // @ts-ignore
      dndZone.removeEventListener('dragenter', handleDragIn);
      // @ts-ignore
      dndZone.removeEventListener('dragleave', handleDragOut);
      // @ts-ignore
      dndZone.removeEventListener('dragover', handleDrag);
      // @ts-ignore
      dndZone.removeEventListener('drop', handleDrop);
    };
  }, []);

  return (
    <div ref={dndZoneRef} className="import-file-flow-dnd-zone">
      <div className="import-file-flow-dnd-zone__icon">
        <SVGIcon icon="folderOpen" size={28} />
      </div>
      <div className="import-file-flow-dnd-zone__description">
        Drag/drop or click the button to upload a .CSV (UTF-8) file
      </div>
      <label className="import-file-flow-dnd-zone__input-label" htmlFor="input-file-field">
        Upload .CSV (UTF-8) file
      </label>
      <input
        onChange={handleLoadFile}
        id="input-file-field"
        className="import-file-flow-dnd-zone__input-field"
        type="file"
        multiple
        accept=".csv"
      />
    </div>
  );
};

export default DragNDropZone;
