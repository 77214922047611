import { addNotification } from '@redux/actions/notifications.actions';

const errorTypes = {
  ALREADY_EXISTS: 'ALREADY_EXISTS',
  UNAUTHENTICATED: 'UNAUTHENTICATED',
  FAILED_PRECONDITION: 'FAILED_PRECONDITION',
  INVALID_ARGUMENT: 'INVALID_ARGUMENT',
  NOT_FOUND: 'NOT_FOUND',
  PERMISSION_DENIED: 'PERMISSION_DENIED',
  UNIMPLEMENTED: 'UNIMPLEMENTED',
};

const getErrorString = (errorMessage, parsePrefix) => {
  if (typeof errorMessage !== 'string') {
    return '';
  }

  if (parsePrefix) {
    const splittedMessage = errorMessage.split(':');

    if (errorTypes[splittedMessage[0]] !== undefined) {
      return splittedMessage.slice(1, splittedMessage.length).join(':').trim();
    }

    return '';
  }

  return errorMessage;
};

const requestErrorParser =
  (errorMessage: string, parsePrefix = true) =>
  (dispatch) => {
    const parsedErrorString = getErrorString(errorMessage, parsePrefix);
    if (parsedErrorString.trim() === '') {
      return;
    }
    let errorObj;

    try {
      errorObj = JSON.parse(parsedErrorString);
    } catch (e) {
      errorObj = {
        visible: true,
        msg: parsedErrorString,
      };
    }

    const hiddenErrors = new RegExp(/.*(exception|grpc|headers|timeout).*/g);

    if (hiddenErrors.test(errorObj.msg?.toLowerCase())) {
      return;
    }

    if (errorObj.visible) {
      dispatch(addNotification({ title: errorObj.msg, type: 'error' }));
    }
  };

export default requestErrorParser;
