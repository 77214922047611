import React from 'react';

import { markets, regions, countries } from '@constants/countries.contstants';
import { SearchTypesEnum } from '@ts/contentSearch.types';

import FilterWithSelect from '../_components/FilterWithSelect/FilterWithSelect';

export default {
  label: 'Location',
  filterKey: 'location',
  icon: 'slidersSettings',
  render: ({
    onApply,
    sourceType,
    value,
  }: {
    onApply: (filterValue: any) => void;
    sourceType: number | string;
    value: any;
  }): JSX.Element => {
    switch (sourceType) {
      case SearchTypesEnum.googleSearch:
      case SearchTypesEnum.bingSearch:
        return (
          <FilterWithSelect
            options={countries}
            placeholder="Select country"
            value={value}
            onApply={onApply}
            defaultValue={{ value: 'us', label: 'United States' }}
          />
        );
      case SearchTypesEnum.bingNews:
        return (
          <FilterWithSelect
            options={markets}
            placeholder="Select country"
            value={value}
            onApply={onApply}
            defaultValue={{ value: 'en-US', label: 'United States English' }}
          />
        );
      case 'trendingContent':
        return (
          <FilterWithSelect
            options={regions}
            placeholder="Select country"
            value={value}
            onApply={onApply}
          />
        );
      default:
        return null;
    }
  },
};
