import React, { KeyboardEventHandler, useEffect, useState } from 'react';

import CreatableSelect from 'react-select/creatable';
import { OnChangeValue } from 'react-select';

import { getCustomStyles } from '@uikit/Select/styles';
import { Option } from '@uikit/Select/selectTypes';

type CreatableSelectWrapperType = {
  gatherValues: (values: Option[]) => void;
  validateNewValue: (newValue: string) => boolean;
  validationErrorAction: () => void;
  isGhost: boolean;
  isClearable: boolean;
  isMulti: boolean;
  [key: string]: any;
};

const CreatableSelectWrapper = ({
  gatherValues,
  validateNewValue,
  validationErrorAction,
  isGhost = false,
  isClearable,
  isMulti,
  ...props
}: CreatableSelectWrapperType): JSX.Element => {
  const [value, changeValue] = useState([]);
  const [inputValue, changeInputValue] = useState('');

  useEffect(() => {
    gatherValues(value);
  }, [value]);

  const components = {
    DropdownIndicator: null,
  };
  const createOption = (label: string) => ({
    label,
    value: label,
  });

  /*
    We need this to handle deleting

    ** the second parameter can be, it will show what action come in
   */
  const handleChange = (newValue: OnChangeValue<Option, true>) => {
    changeValue(newValue);
  };

  const handleInputChange = (newInputValue: string) => {
    changeInputValue(newInputValue);
  };
  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (!inputValue) return;
    if (event.key === 'Enter' || event.key === 'Tab') {
      if (validateNewValue && validateNewValue(inputValue)) {
        changeInputValue('');
        changeValue([...value, createOption(inputValue)]);
      } else {
        validationErrorAction();
      }
      event.preventDefault();
    }
  };

  return (
    <CreatableSelect
      components={components}
      inputValue={inputValue}
      menuIsOpen={false}
      onChange={handleChange}
      onInputChange={handleInputChange}
      onKeyDown={handleKeyDown}
      isMulti={isMulti}
      isClearable={isClearable}
      placeholder="Type emails here and press Enter to add"
      styles={{ ...getCustomStyles(isGhost) }}
      value={value}
      {...props}
    />
  );
};

export default CreatableSelectWrapper;
