import { CustomVariablePlaceholderType } from '@ts/customVariales.types';
import { CustomVariableType, CustomVariableValueRelatedTo } from 'respona_api/generated/common_pb';

export const customVarsToOptions = (vars: CustomVariablePlaceholderType[]) => {
  return vars
    .filter((it) => {
      if (it?.isDefault === true) {
        return false;
      }
      if (it.id <= 0) {
        return false;
      }
      if (it.relatedTo === CustomVariableValueRelatedTo.MISC) {
        return false;
      }
      if (it.type === CustomVariableType.TEXT) {
        return true;
      }
      return false;
    })
    .map((variable) => ({
      ...variable,
      isCustomVariable: true,
      label: variable.title,
      value: `custom-${variable.id}`,
    }));
};
