import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';

import Button from '@uikit/Button/Button';
import EmailAccuracy from '@uikit/EmailAccuracy/EmailAccuracy';
import SocialLinks from '@uikit/SocialLinks/SocialLinks';
import Spinner from '@uikit/Spinner/Spinner';

const he = require('he');

export default [
  {
    title: 'Name',
    dataKey: 'name',
    RenderCell: ({ row: { name, socialLinksList, jobPosition, company, location } }) => (
      <>
        <div className="contacts-result-table__name">{name || '-'}</div>
        <div className="contacts-result-table__job-position">{he.decode(jobPosition)}</div>
        <div className="contacts-result-table__company">{he.decode(company)}</div>
        <div className="contacts-result-table__location">{he.decode(location)}</div>
        <SocialLinks socialLinks={socialLinksList} />
      </>
    ),
  },
  {
    title: 'Email',
    dataKey: 'email',
    RenderCell: ({
      row,
      renderProps: { selectedContactsMap, onAssignContact, onRemoveOpportunityContact },
    }) => {
      const { status, emailAddressList, id } = row;

      const handleAssignContact = (email, accuracy) => {
        const searchedEmail = selectedContactsMap[email];

        if (searchedEmail && searchedEmail.id) {
          onRemoveOpportunityContact(searchedEmail.id);
        } else if (onAssignContact) {
          onAssignContact(row, email, accuracy, row.optionalPersonId);
        }
      };

      const renderEmails = () => {
        if (status === 2 && emailAddressList.length > 0) {
          return (
            <>
              <div className="contacts-result-table__email-row">
                <EmailAccuracy
                  accuracy={emailAddressList[0].accuracy}
                  order={selectedContactsMap[emailAddressList[0].email]?.order}
                />
                <div
                  className="contacts-result-table__email-string"
                  title={emailAddressList[0].email}
                >
                  {emailAddressList[0].email}
                </div>
              </div>
              {emailAddressList.length > 1 ? (
                <div className="contacts-result-emails">
                  <div
                    className="contacts-result-emails__count"
                    data-for={`contact-emails-${id}`}
                    data-tip=""
                  >
                    + {emailAddressList.length - 1}&nbsp; more
                  </div>
                  <ReactTooltip
                    id={`contact-emails-${id}`}
                    className="react-tooltip"
                    place="bottom"
                    effect="solid"
                    event="click"
                    globalEventOff="click"
                    arrowColor="transparent"
                    clickable
                  >
                    <div className="contacts-result-emails-tooltip">
                      {emailAddressList.map(({ email, accuracy }) => (
                        <div
                          className="contacts-result-emails-tooltip__email"
                          key={email}
                          onClick={() => handleAssignContact(email, accuracy)}
                        >
                          <EmailAccuracy
                            accuracy={accuracy}
                            order={selectedContactsMap[email]?.order}
                          />
                          {email}
                        </div>
                      ))}
                    </div>
                  </ReactTooltip>
                </div>
              ) : null}
            </>
          );
        }

        return null;
      };

      return (
        <div
          className={`contacts-result-table__td contacts-result-table__td--email ${
            renderEmails() ? 'email-active' : ''
          }`}
        >
          {renderEmails()}
        </div>
      );
    },
  },
  {
    title: '',
    dataKey: 'status',
    RenderCell: ({
      row: {
        name,
        location,
        company,
        category,
        jobPosition,
        id,
        status,
        emailAddressList,
        optionalPersonId,
      },
      renderProps: { selectedContactsMap, onAssignContact, onGetContacts, onRemovePersonContact },
    }) => {
      const [isSearching, changeIsSearching] = useState(false);

      const handleGetEmail = () => {
        changeIsSearching(true);
        onGetContacts(id).finally(() => changeIsSearching(false));
      };

      const handleReassign = () => {
        const searchedEmail = emailAddressList.find(
          ({ email }) => selectedContactsMap[email] !== undefined
        );

        onRemovePersonContact(selectedContactsMap[searchedEmail.email].personId);
      };

      const renderStatus = () => {
        if (isSearching) {
          return (
            <Button
              type="bordered"
              className="contacts-result-table__status contacts-result-table__status--searching"
            >
              <Spinner marginRight={5} />
              Searching
            </Button>
          );
        }

        switch (status) {
          case 0:
            return (
              <span className="contacts-result-table__status contacts-result-table__status--not-found">
                Not found
              </span>
            );
          case 1:
            return (
              <Button
                className="contacts-result-table__status contacts-result-table__status--get-emails"
                type="bordered"
                size="s"
                onClick={handleGetEmail}
              >
                Get email
              </Button>
            );
          case 2:
            // If only one contact
            if (emailAddressList.some(({ email }) => selectedContactsMap[email] !== undefined)) {
              return (
                <Button
                  onClick={handleReassign}
                  className="contacts-result-table__status contacts-result-table__status--complete"
                >
                  Assigned
                </Button>
              );
            }

            if (onAssignContact) {
              return (
                <Button
                  className="contacts-result-table__status contacts-result-table__status--assign"
                  size="s"
                  onClick={() =>
                    onAssignContact(
                      {
                        name,
                        location,
                        company,
                        category,
                        jobPosition,
                      },
                      emailAddressList[0].email,
                      emailAddressList[0].accuracy,
                      optionalPersonId
                    )
                  }
                >
                  Assign
                </Button>
              );
            }

            return (
              <Button
                onClick={() => null}
                style={{ cursor: 'default' }}
                className="contacts-result-table__status contacts-result-table__status--complete"
              >
                Found
              </Button>
            );

          default:
            return null;
        }
      };

      return (
        <div className="contacts-result-table__td contacts-result-table__td--status">
          {renderStatus()}
        </div>
      );
    },
  },
];
