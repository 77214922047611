import React, { useRef, useState } from 'react';

import { DispatchType } from 'src/store';

import { useDispatch, useSelector } from 'react-redux';

import RightInfoSidebar from '@uikit/RightInfoSidebar/RightInfoSidebar';

import { changeSubscriptionApi } from '@api/billing.api';

import './SubscriptionActivationSidebar.scss';
import GradientBackgroundLoader from '@uikit/GradientBackgroundLoader/GradientBackgroundLoader';
import UseCardToPay from '@components/UseCardToPay/UseCardToPay';
import {
  BillingSubscriptionCycle,
  BillingSubscriptionType,
} from 'respona_api/generated/billing_pb';
import { addNotification } from '@redux/actions/notifications.actions';
import { billingDetailsSelector } from '@redux/selectors/billings.selectors';
import { Button } from '@uikit/Button/Button';
import { SVGIcon } from '@uikit/Icon/Icon';

const SubscriptionActivationSidebar = ({
  title,
  onClose,
  onActionAfterPay,
}: {
  title?: string;
  onClose: () => void;
  onActionAfterPay?: () => void;
}): JSX.Element => {
  const dispatch = useDispatch<DispatchType>();

  const submitBtnRef = useRef<HTMLButtonElement>();

  const [isChangingBilling, changeIsChangingBilling] = useState(false);
  const [isButtonDisabled, changeIsButtonDisabled] = useState(true);

  const { data: billingDetails} =
    useSelector(billingDetailsSelector);

  const handlePay = async (nonce: string, deviceData: string) => {
    changeIsChangingBilling(true);
    return changeSubscriptionApi(
      2,
      10_000,
      BillingSubscriptionType.STARTER,
      nonce,
      deviceData,
      BillingSubscriptionCycle.MONTHLY,
      Math.max(billingDetails.totalContactsNumber, 30_000)
    );
  };

  return (
    <RightInfoSidebar isOpen onClose={onClose} title={title || 'Activate your subscription'}>
      <div className="subscription-activation-sidebar">
        <div className="subscription-activation-sidebar__body">
          <div className="subscription-activation-sidebar__body__description">
            To continue, please activate your subscription.
          </div>
          <div className="subscription-activation-sidebar__body__price-block">
            <div className="subscription-activation-sidebar__body__price-description">
              Starter monthly plan <br /> (Cancel anytime)
            </div>
            <div className="subscription-activation-sidebar__body__price-mark">
              <span className="subscription-activation-sidebar__body__price">$198</span>
              <span className="subscription-activation-sidebar__body__period">/mo</span>
            </div>
          </div>

          <div className="subscription-form__due-today-price">
            Due Today
            <GradientBackgroundLoader isLoading={false} width={100} height={28} borderRadius={8.5}>
              <div className="subscription-form__due-today-price-value">$198</div>
            </GradientBackgroundLoader>
          </div>

          <div className="subscription-form__payment-details-title">Payment details</div>

          <div className="subscription-form__card">
            <UseCardToPay
              isCurrentCard={false}
              disableButtonHandler={(value) => {
                changeIsButtonDisabled(value);
              }}
              isButtonDisabled={isButtonDisabled}
              submitBtnRef={submitBtnRef}
              gradientLoaderHeight={78}
              gradientLoaderWidth={700}
              buttonClickCallback={(nonce: string, deviceData: string) => {
                handlePay(nonce, deviceData)
                  .then(() => {
                    dispatch(addNotification({ title: 'Subscription activated', type: 'success' }));
                    onActionAfterPay();
                  })
                  .finally(() => {
                    changeIsChangingBilling(false);
                  });
              }}
            />
          </div>
          <div className="subscription-form__note">
            <SVGIcon icon="lock" color="#8F9199" size={18} />
            Payments are securely processed by
            <a
              href="https://www.braintreegateway.com/merchants/4h5xpqspxxvttx79/verified"
              target="_blank"
              rel="noopener noreferrer"
            >
              Braintree
            </a>
          </div>
        </div>
        <div className="subscription-activation-sidebar__footer">
          <Button
            type="bordered"
            className="subscription-form__plans-btn"
            href="/settings/billing/change-subscription"
          >
            View all plans
          </Button>

          <Button
            disabled={isButtonDisabled}
            innerRef={submitBtnRef}
            className="subscription-form__apply-btn"
            isLoading={isChangingBilling}
          >
            Pay and activate subscription
          </Button>
        </div>
      </div>
    </RightInfoSidebar>
  );
};

export default SubscriptionActivationSidebar;
