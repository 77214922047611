import {
  addCompanyNoteApi,
  fetchPeopleCompanyNotesApi,
  removeCompanyNoteApi,
} from '@api/website.api';
import { addNotification } from '@redux/actions/notifications.actions';
import { WebsiteType } from '@ts/company.types';
import { PersonBaseType } from '@ts/people.types';
import Loader from '@uikit/Loader/Loader';
import React, { useCallback, useRef, useState, useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { NoteType } from '@ts/common.types';
import useWorkspaceMembers from '@hooks/useWorkspaceMembers';
import { SVGIcon } from '@uikit/Icon/Icon';
import ContactsSidebarCRMSection from '@components/ContactSidebarCRM/_components/ContactsSidebarCRMSection/ContactsSidebarCRMSection';
import { useDispatch } from 'react-redux';
import { DispatchType } from 'src/store';
import './ContactsSidebarCRMSectionNotes.scss';
import { addNoteApi, getAllNotesApi, removeNoteApi, updateNoteApi } from '@api/people.api';
import NoteEditor from '@components/ContactSidebarCRM/_components/ContactsSidebarCRMSectionNotes/_components/NoteEditor';
import NotePreview from '@components/ContactSidebarCRM/_components/ContactsSidebarCRMSectionNotes/_components/NotePreview';

function ContactsSidebarCRMSectionNotes({
  person,
  website,
  index,
}: {
  website?: WebsiteType;
  person?: PersonBaseType;
  index: number;
}) {
  const personId = person?.id;
  const websiteId = website?.id;

  const ref = useRef(null);
  const dispatch = useDispatch<DispatchType>();
  const queryClient = useQueryClient();
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [queryEnabled, setQueryEnabled] = useState<boolean>(true);

  const { data, isLoading } = useQuery<NoteType[]>(
    ['contacts-sidebar-notes', personId, websiteId],
    () =>
      (person ? getAllNotesApi : fetchPeopleCompanyNotesApi)(personId || websiteId).then(
        (notesArray: NoteType[]) => notesArray.sort((a, b) => a.createdAt - b.createdAt)
      ),
    {
      enabled: !!(personId || websiteId) && queryEnabled,
      refetchOnWindowFocus: false,
      onSettled: (data) => !!data?.length && ref.current.setOpen(true),
    }
  );

  const { members, isFetching } = useWorkspaceMembers(!(personId || websiteId) || !queryEnabled);

  useEffect(() => {
    setQueryEnabled(!!(personId || websiteId));
  }, [personId, websiteId]);

  const handleAddNote = useCallback(
    (value: string) => {
      const mutate = personId ? addNoteApi : addCompanyNoteApi;
      mutate(value, personId || websiteId).then((newNote) => {
        setIsCreating(false);
        queryClient.setQueryData(
          ['contacts-sidebar-notes', personId, websiteId],
          (prevNotes: NoteType[]) => [newNote, ...(prevNotes || [])]
        );
      });
    },
    [personId, websiteId, queryClient]
  );

  const handleUpdateNote = useCallback(
    (newValue: string, noteId: number): Promise<void> => {
      if (!personId) {
        dispatch(addNotification({ title: '<Method not implemented>', type: 'warning' }));
        return Promise.resolve();
      }

      return updateNoteApi(noteId, newValue, personId).then(() => {
        queryClient.setQueryData(
          ['contacts-sidebar-notes', personId, websiteId],
          (prevNotes: NoteType[]) =>
            prevNotes.map((note) => (note.id === noteId ? { ...note, content: newValue } : note))
        );
      });
    },
    [personId, websiteId, dispatch, queryClient]
  );

  const handleRemoveNote = useCallback(
    (noteId: number): Promise<void> => {
      const mutate = personId ? removeNoteApi : removeCompanyNoteApi;
      return mutate(noteId, personId ?? websiteId).then(() => {
        queryClient.setQueryData(
          ['contacts-sidebar-notes', personId, websiteId],
          (prevNotes: NoteType[]) => prevNotes.filter(({ id }) => id !== noteId)
        );
      });
    },
    [personId, websiteId, queryClient]
  );

  const onOpenCallback = useCallback(() => {
    setQueryEnabled(true);
  }, []);

  return (
    <ContactsSidebarCRMSection
      ref={ref}
      index={index}
      id="notes"
      title="Notes"
      onOpen={onOpenCallback}
      rightComponent={
        <div
          className="contact-sidebar-crm__content__add-btn"
          onClick={() => {
            ref.current.setOpen(true);
            setIsCreating(true);
          }}
        >
          <SVGIcon icon="plusIcon" size={10} color="#221CB6" />
          Add
        </div>
      }
    >
      <div className="contacts-sidebar-section-notes__container">
        {isCreating && <NoteEditor onSave={handleAddNote} onClose={() => setIsCreating(false)} />}
        <Loader isLoading={isLoading} withTopMargin>
          {data?.length
            ? data.map((note) => (
                <NotePreview
                  key={note.id}
                  note={note}
                  members={members}
                  onSave={handleUpdateNote}
                  onRemove={handleRemoveNote}
                />
              ))
            : null}
          {!data?.length && !isCreating ? (
            <div className="contacts-sidebar-section-notes__container--empty">Empty</div>
          ) : null}
        </Loader>
      </div>
    </ContactsSidebarCRMSection>
  );
}

export default ContactsSidebarCRMSectionNotes;
