import { StateType } from 'src/redux/reducers';

import { ScheduleType } from '@ts/schedules.types';
import { createControlledStateSelector } from '@redux/utils/createControlledStateSelector';

export const schedulesSelector = ({
  schedules,
}: StateType): { items: ScheduleType[]; loadingStatus: string } => schedules;

export const scheduleByIdSelector = (scheduleId) => ({ schedules }: StateType) =>
  schedules.items.find(({ id }) => id === scheduleId);

export const createGetSheduleBySheduleIdSelectorControlled = (sheduleId) => {
  return createControlledStateSelector(scheduleByIdSelector(sheduleId));
};