import React from 'react';

export default ({ color = '#200E32' }) => (
  <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.9751 7V10H12.9751"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.975 12.5001C15.8688 13.503 15.4288 14.4413 14.7255 15.1642C14.0223 15.8871 13.0965 16.3528 12.0969 16.4866C11.0972 16.6204 10.0816 16.4145 9.21298 15.9019C8.34437 15.3893 7.67314 14.5998 7.307 13.66C6.94086 12.7202 6.90103 11.6847 7.19391 10.7196C7.48679 9.7545 8.0954 8.91576 8.92207 8.33797C9.74874 7.76019 10.7455 7.47686 11.7525 7.53346C12.7595 7.59006 13.7183 7.9833 14.475 8.65007L15.975 10.0001"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
