import React from 'react';

export default ({
  color = '#C4C6CD',
  size = 20,
}: {
  color?: string;
  size?: number;
}): JSX.Element => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99996 1.66667C8.35179 1.66667 6.74062 2.15541 5.37021 3.07109C3.9998 3.98677 2.9317 5.28826 2.30097 6.81098C1.67024 8.33369 1.50521 10.0092 1.82675 11.6258C2.1483 13.2423 2.94197 14.7271 4.10741 15.8926C5.27284 17.058 6.7577 17.8517 8.37421 18.1732C9.99072 18.4948 11.6663 18.3297 13.189 17.699C14.7117 17.0683 16.0132 16.0002 16.9289 14.6298C17.8446 13.2593 18.3333 11.6482 18.3333 10C18.3333 8.90566 18.1177 7.82202 17.699 6.81098C17.2802 5.79993 16.6663 4.88127 15.8925 4.10745C15.1187 3.33363 14.2 2.7198 13.189 2.30101C12.1779 1.88222 11.0943 1.66667 9.99996 1.66667ZM9.99996 16.6667C8.68142 16.6667 7.39249 16.2757 6.29616 15.5431C5.19983 14.8106 4.34535 13.7694 3.84077 12.5512C3.33618 11.3331 3.20416 9.99261 3.46139 8.6994C3.71863 7.4062 4.35357 6.21831 5.28592 5.28596C6.21827 4.35361 7.40615 3.71867 8.69936 3.46144C9.99257 3.2042 11.333 3.33622 12.5512 3.84081C13.7694 4.34539 14.8105 5.19988 15.5431 6.2962C16.2756 7.39253 16.6666 8.68146 16.6666 10C16.6666 11.7681 15.9643 13.4638 14.714 14.7141C13.4638 15.9643 11.7681 16.6667 9.99996 16.6667ZM13.3333 9.16667H10.8333V6.66667C10.8333 6.44566 10.7455 6.2337 10.5892 6.07742C10.4329 5.92114 10.221 5.83334 9.99996 5.83334C9.77895 5.83334 9.56699 5.92114 9.41071 6.07742C9.25443 6.2337 9.16663 6.44566 9.16663 6.66667V9.16667H6.66663C6.44562 9.16667 6.23365 9.25447 6.07737 9.41075C5.92109 9.56703 5.8333 9.77899 5.8333 10C5.8333 10.221 5.92109 10.433 6.07737 10.5893C6.23365 10.7455 6.44562 10.8333 6.66663 10.8333H9.16663V13.3333C9.16663 13.5544 9.25443 13.7663 9.41071 13.9226C9.56699 14.0789 9.77895 14.1667 9.99996 14.1667C10.221 14.1667 10.4329 14.0789 10.5892 13.9226C10.7455 13.7663 10.8333 13.5544 10.8333 13.3333V10.8333H13.3333C13.5543 10.8333 13.7663 10.7455 13.9226 10.5893C14.0788 10.433 14.1666 10.221 14.1666 10C14.1666 9.77899 14.0788 9.56703 13.9226 9.41075C13.7663 9.25447 13.5543 9.16667 13.3333 9.16667Z"
      fill={color}
    />
  </svg>
);
