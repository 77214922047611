import { useQuery, useQueryClient } from 'react-query';
import { QueryCollectionHookType } from '@ts/common.types';
import { UserNotificationSettingsType } from '@ts/user.types';
import { getNotificationSettingsApi } from '@api/user.api';

export default (): QueryCollectionHookType<UserNotificationSettingsType> => {
  const queryClient = useQueryClient();

  const key = ['notification-settings'];

  const { data, isFetching, isLoading, isRefetching, refetch, isSuccess } = useQuery<
    UserNotificationSettingsType[]
  >(key, () => getNotificationSettingsApi(), {
    enabled: true,
    refetchOnWindowFocus: false,
    placeholderData: [],
    staleTime: 15 * 60 * 1000,
  });

  const updateCache = (
    updater: (cachedData: UserNotificationSettingsType[]) => UserNotificationSettingsType[]
  ) => {
    queryClient.setQueryData<UserNotificationSettingsType[]>(key, updater);
  };

  const removeFromCacheById = (id: number) => {
    updateCache((cachedData = []) => cachedData.filter((item) => item.id !== id));
  };

  const addToCache = (item: UserNotificationSettingsType) => {
    updateCache((cachedData = []) => [...cachedData, item]);
  };

  const updateInCache = (id: number, payload: Partial<UserNotificationSettingsType>) => {
    updateCache((cachedData = []) =>
      cachedData.map((item) => (item.id === id ? { ...item, ...payload } : item))
    );
  };

  const evictCache = () => queryClient.removeQueries(key);

  const isDataLoading = isFetching || isLoading || isRefetching;

  return {
    items: data,
    refetch,
    isLoading: isDataLoading,
    isEmpty: isSuccess && data?.length === 0,
    removeItem: removeFromCacheById,
    addItem: addToCache,
    updateItem: updateInCache,
    evictByKey: evictCache,
  };
};
