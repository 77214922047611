import React from 'react';
import { PieChart, Pie, Cell, PieProps } from 'recharts';

export type PieChartProps = {
  data?: any;
  value?: number;
  name: string;
  color?: string;
  backgroundColor?: string;
  maxValue?: number;
  size?: number;
  pieWidth?: number;
  [key: string]: unknown;
};

export default ({
  data,
  value,
  name,
  color,
  backgroundColor = '#eeeeee',
  maxValue = 100,
  size = 43,
  pieWidth = 5,
  ...pieProps
}: PieChartProps): JSX.Element => {
  const outerRadius = size / 2 - 1;

  return (
    <PieChart width={size} height={size}>
      <Pie // background pie
        dataKey="value"
        data={[{ value: 100, fill: backgroundColor }]}
        startAngle={90}
        endAngle={450}
        innerRadius={outerRadius - pieWidth}
        outerRadius={outerRadius}
        paddingAngle={0}
        cornerRadius={0}
        strokeWidth={0}
        isAnimationActive={false}
      />
      <Pie
        data={data || [{ value: maxValue === 0 ? 100 : maxValue - value }, { name, value }]}
        startAngle={90}
        endAngle={450}
        innerRadius={outerRadius - pieWidth}
        outerRadius={outerRadius}
        fill={backgroundColor}
        paddingAngle={0}
        dataKey="value"
        cornerRadius={8}
        strokeWidth={0}
        {...pieProps}
      >
        <Cell fill={backgroundColor} />
        <Cell fill={color} />
      </Pie>
    </PieChart>
  );
};
