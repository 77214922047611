import React, { useState, useRef, useEffect } from 'react';

import { SVGIcon } from '@uikit/Icon/Icon';
import Input from '@uikit/Input/Input';

import './ActionsTooltipContent.scss';

export type ConfigType = {
  title: string;
  icon: string;
  iconSize?: number;
  handler: () => void;
  isDisable?: boolean;
}[];

type ActionsTooltipContentPropsType = {
  onRemove?: () => void;
  onRename?: (string) => void;
  onHideTooltip?: () => void;
  itemTitle?: string;
  config?: ConfigType;
  isSingleStepRemove?: boolean;
  removeActionTitle?: string;
};

function ActionsTooltipContentItem({
  icon,
  iconSize,
  title,
  onClick,
}: {
  icon;
  iconSize?;
  title;
  onClick;
}) {
  return (
    <div className="actions-tooltip-content__item" onClick={onClick} tabIndex={0} role="button">
      <span className="actions-tooltip-content__item-icon">
        <SVGIcon color="#fff" icon={icon} size={iconSize} />
      </span>
      {title}
    </div>
  );
}

function ActionsTooltipContent({
  config = [],
  onRemove,
  onRename,
  itemTitle,
  onHideTooltip,
  isSingleStepRemove,
  removeActionTitle = 'Remove',
}: ActionsTooltipContentPropsType): JSX.Element {
  const [wasRemoveClicked, changeWasRemoveClicked] = useState(false);
  const [wasRenameClicked, changeWasRenameClicked] = useState(false);
  const [newName, changeNewName] = useState('');
  const inputRef = useRef();

  const handleRemoveClick = (e) => {
    e.stopPropagation();

    if (isSingleStepRemove) {
      onRemove();
      if (onHideTooltip) {
        onHideTooltip();
      }
      return;
    }

    if (wasRemoveClicked) {
      onRemove();
      if (onHideTooltip) {
        onHideTooltip();
      }
    } else {
      changeWasRemoveClicked(true);
    }
  };

  const handleRenameClick = (e) => {
    e.stopPropagation();

    if (!wasRenameClicked) {
      changeWasRenameClicked(true);
    }
  };

  const handleKeyDownOnInput = (e) => {
    if (e.keyCode === 13) {
      onRename(newName);
    }
  };

  const createActionHandler = (handler) => () => {
    handler();
    if (onHideTooltip) {
      onHideTooltip();
    }
  };

  useEffect(() => {
    if (inputRef.current && wasRenameClicked) {
      // @ts-ignore
      inputRef.current.focus();
    }
  }, [inputRef, inputRef.current, wasRenameClicked]);

  useEffect(() => {
    changeNewName(itemTitle);
  }, [itemTitle]);

  const renderRenameAction = () => {
    if (wasRenameClicked) {
      return (
        <div className="actions-tooltip-content__item">
          <Input
            isTransparent
            isInline
            inputRef={inputRef}
            value={newName}
            onChange={({ target: { value } }) => changeNewName(value)}
            onKeyDown={handleKeyDownOnInput}
          />
        </div>
      );
    }

    return (
      <ActionsTooltipContentItem icon="renameAction" title="Rename" onClick={handleRenameClick} />
    );
  };

  return (
    <div
      className="actions-tooltip-content"
      onClick={(e) => e.stopPropagation()}
      tabIndex={0}
      role="button"
    >
      {config.map(({ icon, iconSize, title, handler, isDisable }) =>
        isDisable ? null : (
          <ActionsTooltipContentItem
            key={title}
            icon={icon}
            title={title}
            onClick={createActionHandler(handler)}
            iconSize={iconSize}
          />
        )
      )}
      {onRemove ? (
        <ActionsTooltipContentItem
          icon="trashWhite"
          title={wasRemoveClicked ? 'Are you sure?' : removeActionTitle}
          onClick={handleRemoveClick}
        />
      ) : null}
      {onRename ? renderRenameAction() : null}
    </div>
  );
}

export default ActionsTooltipContent;
