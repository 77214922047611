import { useQuery, useQueryClient } from 'react-query';
import { QueryCollectionHookType } from '@ts/common.types';
import { useMemo } from 'react';
import { RelationshipPipelineType } from '@ts/relationshipPipeline.types';
import { getAllRelationshipPipelinesApi } from '@api/relationshipPipeline';

export default (workspaceId: number): QueryCollectionHookType<RelationshipPipelineType> => {
  const queryClient = useQueryClient();

  const key = useMemo(() => ['relationship-pipelines', workspaceId], [workspaceId]);

  const queryResult = useQuery<RelationshipPipelineType[]>(
    key,
    () => getAllRelationshipPipelinesApi(workspaceId),
    {
      enabled: workspaceId > 0,
      refetchOnWindowFocus: false,
      placeholderData: [],
      staleTime: 15 * 60 * 1000,
    }
  );

  const { data, isFetching, isLoading, isRefetching, refetch, isSuccess } = queryResult;

  const updateCache = (
    updateFn: (cachedData: RelationshipPipelineType[]) => RelationshipPipelineType[]
  ) => {
    queryClient.setQueryData<RelationshipPipelineType[]>(key, updateFn);
  };

  const removeItem = (id: number) => {
    updateCache((cachedData) => cachedData.filter((item) => item.id !== id));
  };

  const addItem = (item: RelationshipPipelineType) => {
    updateCache((cachedData) => (cachedData ? [...cachedData, item] : [item]));
  };

  const updateItem = (id: number, payload: RelationshipPipelineType) => {
    updateCache((cachedData) =>
      cachedData.map((item) => (item.id === id ? { ...item, ...payload } : item))
    );
  };

  const evictByKey = (id: number) => queryClient.removeQueries(key);

  return {
    items: data,
    refetch,
    isLoading: isFetching || isLoading || isRefetching,
    isEmpty: isSuccess && data?.length === 0,
    removeItem,
    addItem,
    updateItem,
    evictByKey,
  };
};
