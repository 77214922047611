import React from 'react';

import { ActivePagePropsType } from '@ts/internalSidebar.types';

import ConfigurableSidebar from '@uikit/ConfigurableSidebar/ConfigurableSidebar';

import sidebarConfig from './sidebarConfig';

import './SettingsSidebar.scss';

const SettingsSidebar = ({ pathname }: ActivePagePropsType): JSX.Element => {
  return (
    <div className="settings-sidebar">
      <ConfigurableSidebar config={sidebarConfig} renderProps={{ pathname }} SidebarHeader={() => <span>Settings</span>} />
    </div>
  );
};

export default SettingsSidebar;
