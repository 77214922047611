// source: people-website.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
var people_pb = require('./people_pb.js');
goog.object.extend(proto, people_pb);
goog.exportSymbol('proto.people.ExportPeopleWebsiteRequest', null, global);
goog.exportSymbol('proto.people.ExportPeopleWebsiteRequest.ExportType', null, global);
goog.exportSymbol('proto.people.PeopleComposedResponse', null, global);
goog.exportSymbol('proto.people.PeopleWebsiteCrudRequest', null, global);
goog.exportSymbol('proto.people.PeopleWebsiteExtendedResponse', null, global);
goog.exportSymbol('proto.people.PeopleWebsiteFilter', null, global);
goog.exportSymbol('proto.people.PeopleWebsiteFilterField', null, global);
goog.exportSymbol('proto.people.PeopleWebsiteFilterRequest', null, global);
goog.exportSymbol('proto.people.PeopleWebsiteMetricsRequest', null, global);
goog.exportSymbol('proto.people.PeopleWebsiteMetricsResponse', null, global);
goog.exportSymbol('proto.people.PeopleWebsiteMetricsViewSettingsResponse', null, global);
goog.exportSymbol('proto.people.PeopleWebsiteRenameRequest', null, global);
goog.exportSymbol('proto.people.PeopleWebsiteResponse', null, global);
goog.exportSymbol('proto.people.PeopleWebsiteResponseContainer', null, global);
goog.exportSymbol('proto.people.PeopleWebsiteShowMetricsRequest', null, global);
goog.exportSymbol('proto.people.PeopleWebsiteSocialRequest', null, global);
goog.exportSymbol('proto.people.PeopleWebsiteSocialUpdateRequest', null, global);
goog.exportSymbol('proto.people.RelationshipPipelineOpportunityResponse', null, global);
goog.exportSymbol('proto.people.WebsiteRelationshipStatusRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.people.PeopleWebsiteCrudRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.people.PeopleWebsiteCrudRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.people.PeopleWebsiteCrudRequest.displayName = 'proto.people.PeopleWebsiteCrudRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.people.PeopleWebsiteRenameRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.people.PeopleWebsiteRenameRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.people.PeopleWebsiteRenameRequest.displayName = 'proto.people.PeopleWebsiteRenameRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.people.PeopleWebsiteFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.people.PeopleWebsiteFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.people.PeopleWebsiteFilter.displayName = 'proto.people.PeopleWebsiteFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.people.PeopleWebsiteFilterRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.people.PeopleWebsiteFilterRequest.repeatedFields_, null);
};
goog.inherits(proto.people.PeopleWebsiteFilterRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.people.PeopleWebsiteFilterRequest.displayName = 'proto.people.PeopleWebsiteFilterRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.people.ExportPeopleWebsiteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.people.ExportPeopleWebsiteRequest.repeatedFields_, null);
};
goog.inherits(proto.people.ExportPeopleWebsiteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.people.ExportPeopleWebsiteRequest.displayName = 'proto.people.ExportPeopleWebsiteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.people.PeopleWebsiteResponseContainer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.people.PeopleWebsiteResponseContainer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.people.PeopleWebsiteResponseContainer.displayName = 'proto.people.PeopleWebsiteResponseContainer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.people.PeopleWebsiteMetricsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.people.PeopleWebsiteMetricsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.people.PeopleWebsiteMetricsResponse.displayName = 'proto.people.PeopleWebsiteMetricsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.people.PeopleWebsiteMetricsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.people.PeopleWebsiteMetricsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.people.PeopleWebsiteMetricsRequest.displayName = 'proto.people.PeopleWebsiteMetricsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.people.PeopleWebsiteShowMetricsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.people.PeopleWebsiteShowMetricsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.people.PeopleWebsiteShowMetricsRequest.displayName = 'proto.people.PeopleWebsiteShowMetricsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.people.PeopleWebsiteMetricsViewSettingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.people.PeopleWebsiteMetricsViewSettingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.people.PeopleWebsiteMetricsViewSettingsResponse.displayName = 'proto.people.PeopleWebsiteMetricsViewSettingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.people.PeopleWebsiteSocialRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.people.PeopleWebsiteSocialRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.people.PeopleWebsiteSocialRequest.displayName = 'proto.people.PeopleWebsiteSocialRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.people.PeopleWebsiteSocialUpdateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.people.PeopleWebsiteSocialUpdateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.people.PeopleWebsiteSocialUpdateRequest.displayName = 'proto.people.PeopleWebsiteSocialUpdateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.people.PeopleWebsiteExtendedResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.people.PeopleWebsiteExtendedResponse.repeatedFields_, null);
};
goog.inherits(proto.people.PeopleWebsiteExtendedResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.people.PeopleWebsiteExtendedResponse.displayName = 'proto.people.PeopleWebsiteExtendedResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.people.PeopleWebsiteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.people.PeopleWebsiteResponse.repeatedFields_, null);
};
goog.inherits(proto.people.PeopleWebsiteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.people.PeopleWebsiteResponse.displayName = 'proto.people.PeopleWebsiteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.people.PeopleComposedResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.people.PeopleComposedResponse.repeatedFields_, null);
};
goog.inherits(proto.people.PeopleComposedResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.people.PeopleComposedResponse.displayName = 'proto.people.PeopleComposedResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.people.WebsiteRelationshipStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.people.WebsiteRelationshipStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.people.WebsiteRelationshipStatusRequest.displayName = 'proto.people.WebsiteRelationshipStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.people.RelationshipPipelineOpportunityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.people.RelationshipPipelineOpportunityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.people.RelationshipPipelineOpportunityResponse.displayName = 'proto.people.RelationshipPipelineOpportunityResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.people.PeopleWebsiteCrudRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.people.PeopleWebsiteCrudRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.people.PeopleWebsiteCrudRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PeopleWebsiteCrudRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    domain: jspb.Message.getFieldWithDefault(msg, 3, ""),
    domainAuthority: jspb.Message.getFieldWithDefault(msg, 4, 0),
    logoUrl: jspb.Message.getFieldWithDefault(msg, 5, ""),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.people.PeopleWebsiteCrudRequest}
 */
proto.people.PeopleWebsiteCrudRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.people.PeopleWebsiteCrudRequest;
  return proto.people.PeopleWebsiteCrudRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.people.PeopleWebsiteCrudRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.people.PeopleWebsiteCrudRequest}
 */
proto.people.PeopleWebsiteCrudRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomain(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDomainAuthority(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogoUrl(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.people.PeopleWebsiteCrudRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.people.PeopleWebsiteCrudRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.people.PeopleWebsiteCrudRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PeopleWebsiteCrudRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDomain();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDomainAuthority();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getLogoUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.people.PeopleWebsiteCrudRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleWebsiteCrudRequest} returns this
 */
proto.people.PeopleWebsiteCrudRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.people.PeopleWebsiteCrudRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.PeopleWebsiteCrudRequest} returns this
 */
proto.people.PeopleWebsiteCrudRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string domain = 3;
 * @return {string}
 */
proto.people.PeopleWebsiteCrudRequest.prototype.getDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.PeopleWebsiteCrudRequest} returns this
 */
proto.people.PeopleWebsiteCrudRequest.prototype.setDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 domain_authority = 4;
 * @return {number}
 */
proto.people.PeopleWebsiteCrudRequest.prototype.getDomainAuthority = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleWebsiteCrudRequest} returns this
 */
proto.people.PeopleWebsiteCrudRequest.prototype.setDomainAuthority = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string logo_url = 5;
 * @return {string}
 */
proto.people.PeopleWebsiteCrudRequest.prototype.getLogoUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.PeopleWebsiteCrudRequest} returns this
 */
proto.people.PeopleWebsiteCrudRequest.prototype.setLogoUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 workspace_id = 6;
 * @return {number}
 */
proto.people.PeopleWebsiteCrudRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleWebsiteCrudRequest} returns this
 */
proto.people.PeopleWebsiteCrudRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.people.PeopleWebsiteRenameRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.people.PeopleWebsiteRenameRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.people.PeopleWebsiteRenameRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PeopleWebsiteRenameRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.people.PeopleWebsiteRenameRequest}
 */
proto.people.PeopleWebsiteRenameRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.people.PeopleWebsiteRenameRequest;
  return proto.people.PeopleWebsiteRenameRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.people.PeopleWebsiteRenameRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.people.PeopleWebsiteRenameRequest}
 */
proto.people.PeopleWebsiteRenameRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.people.PeopleWebsiteRenameRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.people.PeopleWebsiteRenameRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.people.PeopleWebsiteRenameRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PeopleWebsiteRenameRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.people.PeopleWebsiteRenameRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleWebsiteRenameRequest} returns this
 */
proto.people.PeopleWebsiteRenameRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.people.PeopleWebsiteRenameRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.PeopleWebsiteRenameRequest} returns this
 */
proto.people.PeopleWebsiteRenameRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 workspace_id = 3;
 * @return {number}
 */
proto.people.PeopleWebsiteRenameRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleWebsiteRenameRequest} returns this
 */
proto.people.PeopleWebsiteRenameRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.people.PeopleWebsiteFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.people.PeopleWebsiteFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.people.PeopleWebsiteFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PeopleWebsiteFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    order: jspb.Message.getFieldWithDefault(msg, 1, 0),
    field: jspb.Message.getFieldWithDefault(msg, 2, 0),
    value: jspb.Message.getFieldWithDefault(msg, 3, ""),
    operation: jspb.Message.getFieldWithDefault(msg, 4, 0),
    join: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.people.PeopleWebsiteFilter}
 */
proto.people.PeopleWebsiteFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.people.PeopleWebsiteFilter;
  return proto.people.PeopleWebsiteFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.people.PeopleWebsiteFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.people.PeopleWebsiteFilter}
 */
proto.people.PeopleWebsiteFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 2:
      var value = /** @type {!proto.people.PeopleWebsiteFilterField} */ (reader.readEnum());
      msg.setField(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 4:
      var value = /** @type {!proto.common.FilterOperation} */ (reader.readEnum());
      msg.setOperation(value);
      break;
    case 5:
      var value = /** @type {!proto.common.FilterJoinType} */ (reader.readEnum());
      msg.setJoin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.people.PeopleWebsiteFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.people.PeopleWebsiteFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.people.PeopleWebsiteFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PeopleWebsiteFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getField();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOperation();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getJoin();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional int32 order = 1;
 * @return {number}
 */
proto.people.PeopleWebsiteFilter.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleWebsiteFilter} returns this
 */
proto.people.PeopleWebsiteFilter.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional PeopleWebsiteFilterField field = 2;
 * @return {!proto.people.PeopleWebsiteFilterField}
 */
proto.people.PeopleWebsiteFilter.prototype.getField = function() {
  return /** @type {!proto.people.PeopleWebsiteFilterField} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.people.PeopleWebsiteFilterField} value
 * @return {!proto.people.PeopleWebsiteFilter} returns this
 */
proto.people.PeopleWebsiteFilter.prototype.setField = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.people.PeopleWebsiteFilter.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.PeopleWebsiteFilter} returns this
 */
proto.people.PeopleWebsiteFilter.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional common.FilterOperation operation = 4;
 * @return {!proto.common.FilterOperation}
 */
proto.people.PeopleWebsiteFilter.prototype.getOperation = function() {
  return /** @type {!proto.common.FilterOperation} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.common.FilterOperation} value
 * @return {!proto.people.PeopleWebsiteFilter} returns this
 */
proto.people.PeopleWebsiteFilter.prototype.setOperation = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional common.FilterJoinType join = 5;
 * @return {!proto.common.FilterJoinType}
 */
proto.people.PeopleWebsiteFilter.prototype.getJoin = function() {
  return /** @type {!proto.common.FilterJoinType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.common.FilterJoinType} value
 * @return {!proto.people.PeopleWebsiteFilter} returns this
 */
proto.people.PeopleWebsiteFilter.prototype.setJoin = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.people.PeopleWebsiteFilterRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.people.PeopleWebsiteFilterRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.people.PeopleWebsiteFilterRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.people.PeopleWebsiteFilterRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PeopleWebsiteFilterRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    optionalFolderId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    optionalSearchQuery: jspb.Message.getFieldWithDefault(msg, 4, ""),
    optionalFiltersList: jspb.Message.toObjectList(msg.getOptionalFiltersList(),
    proto.people.PeopleWebsiteFilter.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.people.PeopleWebsiteFilterRequest}
 */
proto.people.PeopleWebsiteFilterRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.people.PeopleWebsiteFilterRequest;
  return proto.people.PeopleWebsiteFilterRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.people.PeopleWebsiteFilterRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.people.PeopleWebsiteFilterRequest}
 */
proto.people.PeopleWebsiteFilterRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOptionalFolderId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionalSearchQuery(value);
      break;
    case 5:
      var value = new proto.people.PeopleWebsiteFilter;
      reader.readMessage(value,proto.people.PeopleWebsiteFilter.deserializeBinaryFromReader);
      msg.addOptionalFilters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.people.PeopleWebsiteFilterRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.people.PeopleWebsiteFilterRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.people.PeopleWebsiteFilterRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PeopleWebsiteFilterRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getOptionalFolderId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getOptionalSearchQuery();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOptionalFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.people.PeopleWebsiteFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 page = 1;
 * @return {number}
 */
proto.people.PeopleWebsiteFilterRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleWebsiteFilterRequest} returns this
 */
proto.people.PeopleWebsiteFilterRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 limit = 2;
 * @return {number}
 */
proto.people.PeopleWebsiteFilterRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleWebsiteFilterRequest} returns this
 */
proto.people.PeopleWebsiteFilterRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 optional_folder_id = 3;
 * @return {number}
 */
proto.people.PeopleWebsiteFilterRequest.prototype.getOptionalFolderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleWebsiteFilterRequest} returns this
 */
proto.people.PeopleWebsiteFilterRequest.prototype.setOptionalFolderId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string optional_search_query = 4;
 * @return {string}
 */
proto.people.PeopleWebsiteFilterRequest.prototype.getOptionalSearchQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.PeopleWebsiteFilterRequest} returns this
 */
proto.people.PeopleWebsiteFilterRequest.prototype.setOptionalSearchQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated PeopleWebsiteFilter optional_filters = 5;
 * @return {!Array<!proto.people.PeopleWebsiteFilter>}
 */
proto.people.PeopleWebsiteFilterRequest.prototype.getOptionalFiltersList = function() {
  return /** @type{!Array<!proto.people.PeopleWebsiteFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.people.PeopleWebsiteFilter, 5));
};


/**
 * @param {!Array<!proto.people.PeopleWebsiteFilter>} value
 * @return {!proto.people.PeopleWebsiteFilterRequest} returns this
*/
proto.people.PeopleWebsiteFilterRequest.prototype.setOptionalFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.people.PeopleWebsiteFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.people.PeopleWebsiteFilter}
 */
proto.people.PeopleWebsiteFilterRequest.prototype.addOptionalFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.people.PeopleWebsiteFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.people.PeopleWebsiteFilterRequest} returns this
 */
proto.people.PeopleWebsiteFilterRequest.prototype.clearOptionalFiltersList = function() {
  return this.setOptionalFiltersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.people.ExportPeopleWebsiteRequest.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.people.ExportPeopleWebsiteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.people.ExportPeopleWebsiteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.people.ExportPeopleWebsiteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.ExportPeopleWebsiteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    optionalId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    optionalSearchQuery: jspb.Message.getFieldWithDefault(msg, 3, ""),
    optionalFiltersList: jspb.Message.toObjectList(msg.getOptionalFiltersList(),
    proto.people.PeopleWebsiteFilter.toObject, includeInstance),
    optionalSelectedList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    invalidEmails: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    workEmails: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    personalEmails: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    genericEmails: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    catchAllEmails: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    optionalNumberTopResults: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.people.ExportPeopleWebsiteRequest}
 */
proto.people.ExportPeopleWebsiteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.people.ExportPeopleWebsiteRequest;
  return proto.people.ExportPeopleWebsiteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.people.ExportPeopleWebsiteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.people.ExportPeopleWebsiteRequest}
 */
proto.people.ExportPeopleWebsiteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.people.ExportPeopleWebsiteRequest.ExportType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOptionalId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionalSearchQuery(value);
      break;
    case 4:
      var value = new proto.people.PeopleWebsiteFilter;
      reader.readMessage(value,proto.people.PeopleWebsiteFilter.deserializeBinaryFromReader);
      msg.addOptionalFilters(value);
      break;
    case 5:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setOptionalSelectedList(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInvalidEmails(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWorkEmails(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPersonalEmails(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGenericEmails(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCatchAllEmails(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOptionalNumberTopResults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.people.ExportPeopleWebsiteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.people.ExportPeopleWebsiteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.people.ExportPeopleWebsiteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.ExportPeopleWebsiteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getOptionalId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getOptionalSearchQuery();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOptionalFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.people.PeopleWebsiteFilter.serializeBinaryToWriter
    );
  }
  f = message.getOptionalSelectedList();
  if (f.length > 0) {
    writer.writePackedInt64(
      5,
      f
    );
  }
  f = message.getInvalidEmails();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getWorkEmails();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getPersonalEmails();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getGenericEmails();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getCatchAllEmails();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getOptionalNumberTopResults();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.people.ExportPeopleWebsiteRequest.ExportType = {
  ALL: 0,
  FILTERED: 1,
  SELECTED: 2,
  FOLDER: 3,
  TOP_RESULTS: 4
};

/**
 * optional ExportType type = 1;
 * @return {!proto.people.ExportPeopleWebsiteRequest.ExportType}
 */
proto.people.ExportPeopleWebsiteRequest.prototype.getType = function() {
  return /** @type {!proto.people.ExportPeopleWebsiteRequest.ExportType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.people.ExportPeopleWebsiteRequest.ExportType} value
 * @return {!proto.people.ExportPeopleWebsiteRequest} returns this
 */
proto.people.ExportPeopleWebsiteRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int64 optional_id = 2;
 * @return {number}
 */
proto.people.ExportPeopleWebsiteRequest.prototype.getOptionalId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.ExportPeopleWebsiteRequest} returns this
 */
proto.people.ExportPeopleWebsiteRequest.prototype.setOptionalId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string optional_search_query = 3;
 * @return {string}
 */
proto.people.ExportPeopleWebsiteRequest.prototype.getOptionalSearchQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.ExportPeopleWebsiteRequest} returns this
 */
proto.people.ExportPeopleWebsiteRequest.prototype.setOptionalSearchQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated PeopleWebsiteFilter optional_filters = 4;
 * @return {!Array<!proto.people.PeopleWebsiteFilter>}
 */
proto.people.ExportPeopleWebsiteRequest.prototype.getOptionalFiltersList = function() {
  return /** @type{!Array<!proto.people.PeopleWebsiteFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.people.PeopleWebsiteFilter, 4));
};


/**
 * @param {!Array<!proto.people.PeopleWebsiteFilter>} value
 * @return {!proto.people.ExportPeopleWebsiteRequest} returns this
*/
proto.people.ExportPeopleWebsiteRequest.prototype.setOptionalFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.people.PeopleWebsiteFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.people.PeopleWebsiteFilter}
 */
proto.people.ExportPeopleWebsiteRequest.prototype.addOptionalFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.people.PeopleWebsiteFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.people.ExportPeopleWebsiteRequest} returns this
 */
proto.people.ExportPeopleWebsiteRequest.prototype.clearOptionalFiltersList = function() {
  return this.setOptionalFiltersList([]);
};


/**
 * repeated int64 optional_selected = 5;
 * @return {!Array<number>}
 */
proto.people.ExportPeopleWebsiteRequest.prototype.getOptionalSelectedList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.people.ExportPeopleWebsiteRequest} returns this
 */
proto.people.ExportPeopleWebsiteRequest.prototype.setOptionalSelectedList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.people.ExportPeopleWebsiteRequest} returns this
 */
proto.people.ExportPeopleWebsiteRequest.prototype.addOptionalSelected = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.people.ExportPeopleWebsiteRequest} returns this
 */
proto.people.ExportPeopleWebsiteRequest.prototype.clearOptionalSelectedList = function() {
  return this.setOptionalSelectedList([]);
};


/**
 * optional bool invalid_emails = 6;
 * @return {boolean}
 */
proto.people.ExportPeopleWebsiteRequest.prototype.getInvalidEmails = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.people.ExportPeopleWebsiteRequest} returns this
 */
proto.people.ExportPeopleWebsiteRequest.prototype.setInvalidEmails = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool work_emails = 7;
 * @return {boolean}
 */
proto.people.ExportPeopleWebsiteRequest.prototype.getWorkEmails = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.people.ExportPeopleWebsiteRequest} returns this
 */
proto.people.ExportPeopleWebsiteRequest.prototype.setWorkEmails = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool personal_emails = 8;
 * @return {boolean}
 */
proto.people.ExportPeopleWebsiteRequest.prototype.getPersonalEmails = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.people.ExportPeopleWebsiteRequest} returns this
 */
proto.people.ExportPeopleWebsiteRequest.prototype.setPersonalEmails = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool generic_emails = 9;
 * @return {boolean}
 */
proto.people.ExportPeopleWebsiteRequest.prototype.getGenericEmails = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.people.ExportPeopleWebsiteRequest} returns this
 */
proto.people.ExportPeopleWebsiteRequest.prototype.setGenericEmails = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool catch_all_emails = 10;
 * @return {boolean}
 */
proto.people.ExportPeopleWebsiteRequest.prototype.getCatchAllEmails = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.people.ExportPeopleWebsiteRequest} returns this
 */
proto.people.ExportPeopleWebsiteRequest.prototype.setCatchAllEmails = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional int32 optional_number_top_results = 11;
 * @return {number}
 */
proto.people.ExportPeopleWebsiteRequest.prototype.getOptionalNumberTopResults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.ExportPeopleWebsiteRequest} returns this
 */
proto.people.ExportPeopleWebsiteRequest.prototype.setOptionalNumberTopResults = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.people.PeopleWebsiteResponseContainer.prototype.toObject = function(opt_includeInstance) {
  return proto.people.PeopleWebsiteResponseContainer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.people.PeopleWebsiteResponseContainer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PeopleWebsiteResponseContainer.toObject = function(includeInstance, msg) {
  var f, obj = {
    exist: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    website: (f = msg.getWebsite()) && proto.people.PeopleWebsiteResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.people.PeopleWebsiteResponseContainer}
 */
proto.people.PeopleWebsiteResponseContainer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.people.PeopleWebsiteResponseContainer;
  return proto.people.PeopleWebsiteResponseContainer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.people.PeopleWebsiteResponseContainer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.people.PeopleWebsiteResponseContainer}
 */
proto.people.PeopleWebsiteResponseContainer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExist(value);
      break;
    case 2:
      var value = new proto.people.PeopleWebsiteResponse;
      reader.readMessage(value,proto.people.PeopleWebsiteResponse.deserializeBinaryFromReader);
      msg.setWebsite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.people.PeopleWebsiteResponseContainer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.people.PeopleWebsiteResponseContainer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.people.PeopleWebsiteResponseContainer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PeopleWebsiteResponseContainer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExist();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getWebsite();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.people.PeopleWebsiteResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool exist = 1;
 * @return {boolean}
 */
proto.people.PeopleWebsiteResponseContainer.prototype.getExist = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.people.PeopleWebsiteResponseContainer} returns this
 */
proto.people.PeopleWebsiteResponseContainer.prototype.setExist = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional PeopleWebsiteResponse website = 2;
 * @return {?proto.people.PeopleWebsiteResponse}
 */
proto.people.PeopleWebsiteResponseContainer.prototype.getWebsite = function() {
  return /** @type{?proto.people.PeopleWebsiteResponse} */ (
    jspb.Message.getWrapperField(this, proto.people.PeopleWebsiteResponse, 2));
};


/**
 * @param {?proto.people.PeopleWebsiteResponse|undefined} value
 * @return {!proto.people.PeopleWebsiteResponseContainer} returns this
*/
proto.people.PeopleWebsiteResponseContainer.prototype.setWebsite = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.people.PeopleWebsiteResponseContainer} returns this
 */
proto.people.PeopleWebsiteResponseContainer.prototype.clearWebsite = function() {
  return this.setWebsite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.people.PeopleWebsiteResponseContainer.prototype.hasWebsite = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.people.PeopleWebsiteMetricsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.people.PeopleWebsiteMetricsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.people.PeopleWebsiteMetricsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PeopleWebsiteMetricsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    rating: jspb.Message.getFieldWithDefault(msg, 3, 0),
    traffic: jspb.Message.getFieldWithDefault(msg, 4, 0),
    spamScore: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.people.PeopleWebsiteMetricsResponse}
 */
proto.people.PeopleWebsiteMetricsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.people.PeopleWebsiteMetricsResponse;
  return proto.people.PeopleWebsiteMetricsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.people.PeopleWebsiteMetricsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.people.PeopleWebsiteMetricsResponse}
 */
proto.people.PeopleWebsiteMetricsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.common.ContentMetricsType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRating(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTraffic(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSpamScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.people.PeopleWebsiteMetricsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.people.PeopleWebsiteMetricsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.people.PeopleWebsiteMetricsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PeopleWebsiteMetricsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getRating();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTraffic();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getSpamScore();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.people.PeopleWebsiteMetricsResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleWebsiteMetricsResponse} returns this
 */
proto.people.PeopleWebsiteMetricsResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional common.ContentMetricsType type = 2;
 * @return {!proto.common.ContentMetricsType}
 */
proto.people.PeopleWebsiteMetricsResponse.prototype.getType = function() {
  return /** @type {!proto.common.ContentMetricsType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.ContentMetricsType} value
 * @return {!proto.people.PeopleWebsiteMetricsResponse} returns this
 */
proto.people.PeopleWebsiteMetricsResponse.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int32 rating = 3;
 * @return {number}
 */
proto.people.PeopleWebsiteMetricsResponse.prototype.getRating = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleWebsiteMetricsResponse} returns this
 */
proto.people.PeopleWebsiteMetricsResponse.prototype.setRating = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 traffic = 4;
 * @return {number}
 */
proto.people.PeopleWebsiteMetricsResponse.prototype.getTraffic = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleWebsiteMetricsResponse} returns this
 */
proto.people.PeopleWebsiteMetricsResponse.prototype.setTraffic = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 spam_score = 5;
 * @return {number}
 */
proto.people.PeopleWebsiteMetricsResponse.prototype.getSpamScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleWebsiteMetricsResponse} returns this
 */
proto.people.PeopleWebsiteMetricsResponse.prototype.setSpamScore = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.people.PeopleWebsiteMetricsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.people.PeopleWebsiteMetricsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.people.PeopleWebsiteMetricsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PeopleWebsiteMetricsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    websiteId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    rating: jspb.Message.getFieldWithDefault(msg, 4, 0),
    traffic: jspb.Message.getFieldWithDefault(msg, 5, 0),
    spamScore: jspb.Message.getFieldWithDefault(msg, 6, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.people.PeopleWebsiteMetricsRequest}
 */
proto.people.PeopleWebsiteMetricsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.people.PeopleWebsiteMetricsRequest;
  return proto.people.PeopleWebsiteMetricsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.people.PeopleWebsiteMetricsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.people.PeopleWebsiteMetricsRequest}
 */
proto.people.PeopleWebsiteMetricsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWebsiteId(value);
      break;
    case 3:
      var value = /** @type {!proto.common.ContentMetricsType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRating(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTraffic(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSpamScore(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.people.PeopleWebsiteMetricsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.people.PeopleWebsiteMetricsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.people.PeopleWebsiteMetricsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PeopleWebsiteMetricsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWebsiteId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getRating();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getTraffic();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getSpamScore();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.people.PeopleWebsiteMetricsRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleWebsiteMetricsRequest} returns this
 */
proto.people.PeopleWebsiteMetricsRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 website_id = 2;
 * @return {number}
 */
proto.people.PeopleWebsiteMetricsRequest.prototype.getWebsiteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleWebsiteMetricsRequest} returns this
 */
proto.people.PeopleWebsiteMetricsRequest.prototype.setWebsiteId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional common.ContentMetricsType type = 3;
 * @return {!proto.common.ContentMetricsType}
 */
proto.people.PeopleWebsiteMetricsRequest.prototype.getType = function() {
  return /** @type {!proto.common.ContentMetricsType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.common.ContentMetricsType} value
 * @return {!proto.people.PeopleWebsiteMetricsRequest} returns this
 */
proto.people.PeopleWebsiteMetricsRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int32 rating = 4;
 * @return {number}
 */
proto.people.PeopleWebsiteMetricsRequest.prototype.getRating = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleWebsiteMetricsRequest} returns this
 */
proto.people.PeopleWebsiteMetricsRequest.prototype.setRating = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 traffic = 5;
 * @return {number}
 */
proto.people.PeopleWebsiteMetricsRequest.prototype.getTraffic = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleWebsiteMetricsRequest} returns this
 */
proto.people.PeopleWebsiteMetricsRequest.prototype.setTraffic = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 spam_score = 6;
 * @return {number}
 */
proto.people.PeopleWebsiteMetricsRequest.prototype.getSpamScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleWebsiteMetricsRequest} returns this
 */
proto.people.PeopleWebsiteMetricsRequest.prototype.setSpamScore = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 workspace_id = 7;
 * @return {number}
 */
proto.people.PeopleWebsiteMetricsRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleWebsiteMetricsRequest} returns this
 */
proto.people.PeopleWebsiteMetricsRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.people.PeopleWebsiteShowMetricsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.people.PeopleWebsiteShowMetricsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.people.PeopleWebsiteShowMetricsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PeopleWebsiteShowMetricsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    websiteId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    enabled: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.people.PeopleWebsiteShowMetricsRequest}
 */
proto.people.PeopleWebsiteShowMetricsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.people.PeopleWebsiteShowMetricsRequest;
  return proto.people.PeopleWebsiteShowMetricsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.people.PeopleWebsiteShowMetricsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.people.PeopleWebsiteShowMetricsRequest}
 */
proto.people.PeopleWebsiteShowMetricsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWebsiteId(value);
      break;
    case 2:
      var value = /** @type {!proto.common.ContentMetricsType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.people.PeopleWebsiteShowMetricsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.people.PeopleWebsiteShowMetricsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.people.PeopleWebsiteShowMetricsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PeopleWebsiteShowMetricsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWebsiteId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 website_id = 1;
 * @return {number}
 */
proto.people.PeopleWebsiteShowMetricsRequest.prototype.getWebsiteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleWebsiteShowMetricsRequest} returns this
 */
proto.people.PeopleWebsiteShowMetricsRequest.prototype.setWebsiteId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional common.ContentMetricsType type = 2;
 * @return {!proto.common.ContentMetricsType}
 */
proto.people.PeopleWebsiteShowMetricsRequest.prototype.getType = function() {
  return /** @type {!proto.common.ContentMetricsType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.ContentMetricsType} value
 * @return {!proto.people.PeopleWebsiteShowMetricsRequest} returns this
 */
proto.people.PeopleWebsiteShowMetricsRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional bool enabled = 3;
 * @return {boolean}
 */
proto.people.PeopleWebsiteShowMetricsRequest.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.people.PeopleWebsiteShowMetricsRequest} returns this
 */
proto.people.PeopleWebsiteShowMetricsRequest.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional int64 workspace_id = 4;
 * @return {number}
 */
proto.people.PeopleWebsiteShowMetricsRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleWebsiteShowMetricsRequest} returns this
 */
proto.people.PeopleWebsiteShowMetricsRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.people.PeopleWebsiteMetricsViewSettingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.people.PeopleWebsiteMetricsViewSettingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.people.PeopleWebsiteMetricsViewSettingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PeopleWebsiteMetricsViewSettingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    mozEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    ahrefEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    semrushEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.people.PeopleWebsiteMetricsViewSettingsResponse}
 */
proto.people.PeopleWebsiteMetricsViewSettingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.people.PeopleWebsiteMetricsViewSettingsResponse;
  return proto.people.PeopleWebsiteMetricsViewSettingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.people.PeopleWebsiteMetricsViewSettingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.people.PeopleWebsiteMetricsViewSettingsResponse}
 */
proto.people.PeopleWebsiteMetricsViewSettingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMozEnabled(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAhrefEnabled(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSemrushEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.people.PeopleWebsiteMetricsViewSettingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.people.PeopleWebsiteMetricsViewSettingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.people.PeopleWebsiteMetricsViewSettingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PeopleWebsiteMetricsViewSettingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMozEnabled();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getAhrefEnabled();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getSemrushEnabled();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional bool moz_enabled = 1;
 * @return {boolean}
 */
proto.people.PeopleWebsiteMetricsViewSettingsResponse.prototype.getMozEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.people.PeopleWebsiteMetricsViewSettingsResponse} returns this
 */
proto.people.PeopleWebsiteMetricsViewSettingsResponse.prototype.setMozEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool ahref_enabled = 2;
 * @return {boolean}
 */
proto.people.PeopleWebsiteMetricsViewSettingsResponse.prototype.getAhrefEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.people.PeopleWebsiteMetricsViewSettingsResponse} returns this
 */
proto.people.PeopleWebsiteMetricsViewSettingsResponse.prototype.setAhrefEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool semrush_enabled = 3;
 * @return {boolean}
 */
proto.people.PeopleWebsiteMetricsViewSettingsResponse.prototype.getSemrushEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.people.PeopleWebsiteMetricsViewSettingsResponse} returns this
 */
proto.people.PeopleWebsiteMetricsViewSettingsResponse.prototype.setSemrushEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.people.PeopleWebsiteSocialRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.people.PeopleWebsiteSocialRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.people.PeopleWebsiteSocialRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PeopleWebsiteSocialRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    websiteId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.people.PeopleWebsiteSocialRequest}
 */
proto.people.PeopleWebsiteSocialRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.people.PeopleWebsiteSocialRequest;
  return proto.people.PeopleWebsiteSocialRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.people.PeopleWebsiteSocialRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.people.PeopleWebsiteSocialRequest}
 */
proto.people.PeopleWebsiteSocialRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWebsiteId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.people.PeopleWebsiteSocialRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.people.PeopleWebsiteSocialRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.people.PeopleWebsiteSocialRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PeopleWebsiteSocialRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWebsiteId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 website_id = 1;
 * @return {number}
 */
proto.people.PeopleWebsiteSocialRequest.prototype.getWebsiteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleWebsiteSocialRequest} returns this
 */
proto.people.PeopleWebsiteSocialRequest.prototype.setWebsiteId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.people.PeopleWebsiteSocialRequest.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.PeopleWebsiteSocialRequest} returns this
 */
proto.people.PeopleWebsiteSocialRequest.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.people.PeopleWebsiteSocialUpdateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.people.PeopleWebsiteSocialUpdateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.people.PeopleWebsiteSocialUpdateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PeopleWebsiteSocialUpdateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    websiteId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    valueFrom: jspb.Message.getFieldWithDefault(msg, 2, ""),
    valueTo: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.people.PeopleWebsiteSocialUpdateRequest}
 */
proto.people.PeopleWebsiteSocialUpdateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.people.PeopleWebsiteSocialUpdateRequest;
  return proto.people.PeopleWebsiteSocialUpdateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.people.PeopleWebsiteSocialUpdateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.people.PeopleWebsiteSocialUpdateRequest}
 */
proto.people.PeopleWebsiteSocialUpdateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWebsiteId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValueFrom(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValueTo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.people.PeopleWebsiteSocialUpdateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.people.PeopleWebsiteSocialUpdateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.people.PeopleWebsiteSocialUpdateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PeopleWebsiteSocialUpdateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWebsiteId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getValueFrom();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValueTo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 website_id = 1;
 * @return {number}
 */
proto.people.PeopleWebsiteSocialUpdateRequest.prototype.getWebsiteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleWebsiteSocialUpdateRequest} returns this
 */
proto.people.PeopleWebsiteSocialUpdateRequest.prototype.setWebsiteId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string value_from = 2;
 * @return {string}
 */
proto.people.PeopleWebsiteSocialUpdateRequest.prototype.getValueFrom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.PeopleWebsiteSocialUpdateRequest} returns this
 */
proto.people.PeopleWebsiteSocialUpdateRequest.prototype.setValueFrom = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string value_to = 3;
 * @return {string}
 */
proto.people.PeopleWebsiteSocialUpdateRequest.prototype.getValueTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.PeopleWebsiteSocialUpdateRequest} returns this
 */
proto.people.PeopleWebsiteSocialUpdateRequest.prototype.setValueTo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.people.PeopleWebsiteExtendedResponse.repeatedFields_ = [6,7,8,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.people.PeopleWebsiteExtendedResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.people.PeopleWebsiteExtendedResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.people.PeopleWebsiteExtendedResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PeopleWebsiteExtendedResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    logoUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    website: jspb.Message.getFieldWithDefault(msg, 4, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 5, 0),
    socialLinksList: jspb.Message.toObjectList(msg.getSocialLinksList(),
    common_pb.SocialLink.toObject, includeInstance),
    notesList: jspb.Message.toObjectList(msg.getNotesList(),
    common_pb.Note.toObject, includeInstance),
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    common_pb.Tag.toObject, includeInstance),
    metricsList: jspb.Message.toObjectList(msg.getMetricsList(),
    proto.people.PeopleWebsiteMetricsResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.people.PeopleWebsiteExtendedResponse}
 */
proto.people.PeopleWebsiteExtendedResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.people.PeopleWebsiteExtendedResponse;
  return proto.people.PeopleWebsiteExtendedResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.people.PeopleWebsiteExtendedResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.people.PeopleWebsiteExtendedResponse}
 */
proto.people.PeopleWebsiteExtendedResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogoUrl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebsite(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 6:
      var value = new common_pb.SocialLink;
      reader.readMessage(value,common_pb.SocialLink.deserializeBinaryFromReader);
      msg.addSocialLinks(value);
      break;
    case 7:
      var value = new common_pb.Note;
      reader.readMessage(value,common_pb.Note.deserializeBinaryFromReader);
      msg.addNotes(value);
      break;
    case 8:
      var value = new common_pb.Tag;
      reader.readMessage(value,common_pb.Tag.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    case 9:
      var value = new proto.people.PeopleWebsiteMetricsResponse;
      reader.readMessage(value,proto.people.PeopleWebsiteMetricsResponse.deserializeBinaryFromReader);
      msg.addMetrics(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.people.PeopleWebsiteExtendedResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.people.PeopleWebsiteExtendedResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.people.PeopleWebsiteExtendedResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PeopleWebsiteExtendedResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLogoUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getWebsite();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getSocialLinksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      common_pb.SocialLink.serializeBinaryToWriter
    );
  }
  f = message.getNotesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      common_pb.Note.serializeBinaryToWriter
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      common_pb.Tag.serializeBinaryToWriter
    );
  }
  f = message.getMetricsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.people.PeopleWebsiteMetricsResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.people.PeopleWebsiteExtendedResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleWebsiteExtendedResponse} returns this
 */
proto.people.PeopleWebsiteExtendedResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.people.PeopleWebsiteExtendedResponse.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.PeopleWebsiteExtendedResponse} returns this
 */
proto.people.PeopleWebsiteExtendedResponse.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string logo_url = 3;
 * @return {string}
 */
proto.people.PeopleWebsiteExtendedResponse.prototype.getLogoUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.PeopleWebsiteExtendedResponse} returns this
 */
proto.people.PeopleWebsiteExtendedResponse.prototype.setLogoUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string website = 4;
 * @return {string}
 */
proto.people.PeopleWebsiteExtendedResponse.prototype.getWebsite = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.PeopleWebsiteExtendedResponse} returns this
 */
proto.people.PeopleWebsiteExtendedResponse.prototype.setWebsite = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 created_at = 5;
 * @return {number}
 */
proto.people.PeopleWebsiteExtendedResponse.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleWebsiteExtendedResponse} returns this
 */
proto.people.PeopleWebsiteExtendedResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated common.SocialLink social_links = 6;
 * @return {!Array<!proto.common.SocialLink>}
 */
proto.people.PeopleWebsiteExtendedResponse.prototype.getSocialLinksList = function() {
  return /** @type{!Array<!proto.common.SocialLink>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.SocialLink, 6));
};


/**
 * @param {!Array<!proto.common.SocialLink>} value
 * @return {!proto.people.PeopleWebsiteExtendedResponse} returns this
*/
proto.people.PeopleWebsiteExtendedResponse.prototype.setSocialLinksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.common.SocialLink=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.SocialLink}
 */
proto.people.PeopleWebsiteExtendedResponse.prototype.addSocialLinks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.common.SocialLink, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.people.PeopleWebsiteExtendedResponse} returns this
 */
proto.people.PeopleWebsiteExtendedResponse.prototype.clearSocialLinksList = function() {
  return this.setSocialLinksList([]);
};


/**
 * repeated common.Note notes = 7;
 * @return {!Array<!proto.common.Note>}
 */
proto.people.PeopleWebsiteExtendedResponse.prototype.getNotesList = function() {
  return /** @type{!Array<!proto.common.Note>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.Note, 7));
};


/**
 * @param {!Array<!proto.common.Note>} value
 * @return {!proto.people.PeopleWebsiteExtendedResponse} returns this
*/
proto.people.PeopleWebsiteExtendedResponse.prototype.setNotesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.common.Note=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Note}
 */
proto.people.PeopleWebsiteExtendedResponse.prototype.addNotes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.common.Note, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.people.PeopleWebsiteExtendedResponse} returns this
 */
proto.people.PeopleWebsiteExtendedResponse.prototype.clearNotesList = function() {
  return this.setNotesList([]);
};


/**
 * repeated common.Tag tags = 8;
 * @return {!Array<!proto.common.Tag>}
 */
proto.people.PeopleWebsiteExtendedResponse.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.common.Tag>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.Tag, 8));
};


/**
 * @param {!Array<!proto.common.Tag>} value
 * @return {!proto.people.PeopleWebsiteExtendedResponse} returns this
*/
proto.people.PeopleWebsiteExtendedResponse.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.common.Tag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Tag}
 */
proto.people.PeopleWebsiteExtendedResponse.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.common.Tag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.people.PeopleWebsiteExtendedResponse} returns this
 */
proto.people.PeopleWebsiteExtendedResponse.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * repeated PeopleWebsiteMetricsResponse metrics = 9;
 * @return {!Array<!proto.people.PeopleWebsiteMetricsResponse>}
 */
proto.people.PeopleWebsiteExtendedResponse.prototype.getMetricsList = function() {
  return /** @type{!Array<!proto.people.PeopleWebsiteMetricsResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.people.PeopleWebsiteMetricsResponse, 9));
};


/**
 * @param {!Array<!proto.people.PeopleWebsiteMetricsResponse>} value
 * @return {!proto.people.PeopleWebsiteExtendedResponse} returns this
*/
proto.people.PeopleWebsiteExtendedResponse.prototype.setMetricsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.people.PeopleWebsiteMetricsResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.people.PeopleWebsiteMetricsResponse}
 */
proto.people.PeopleWebsiteExtendedResponse.prototype.addMetrics = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.people.PeopleWebsiteMetricsResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.people.PeopleWebsiteExtendedResponse} returns this
 */
proto.people.PeopleWebsiteExtendedResponse.prototype.clearMetricsList = function() {
  return this.setMetricsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.people.PeopleWebsiteResponse.repeatedFields_ = [10,11,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.people.PeopleWebsiteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.people.PeopleWebsiteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.people.PeopleWebsiteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PeopleWebsiteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    logoUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    domain: jspb.Message.getFieldWithDefault(msg, 4, ""),
    peopleNumber: jspb.Message.getFieldWithDefault(msg, 5, 0),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0),
    ownerId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 8, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 9, 0),
    contactsList: jspb.Message.toObjectList(msg.getContactsList(),
    people_pb.PeopleShortResponse.toObject, includeInstance),
    socialLinksList: jspb.Message.toObjectList(msg.getSocialLinksList(),
    common_pb.SocialLink.toObject, includeInstance),
    metricsList: jspb.Message.toObjectList(msg.getMetricsList(),
    proto.people.PeopleWebsiteMetricsResponse.toObject, includeInstance),
    totalItems: jspb.Message.getFieldWithDefault(msg, 13, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.people.PeopleWebsiteResponse}
 */
proto.people.PeopleWebsiteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.people.PeopleWebsiteResponse;
  return proto.people.PeopleWebsiteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.people.PeopleWebsiteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.people.PeopleWebsiteResponse}
 */
proto.people.PeopleWebsiteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogoUrl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomain(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPeopleNumber(value);
      break;
    case 6:
      var value = /** @type {!proto.common.PeopleRelationshipStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOwnerId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 10:
      var value = new people_pb.PeopleShortResponse;
      reader.readMessage(value,people_pb.PeopleShortResponse.deserializeBinaryFromReader);
      msg.addContacts(value);
      break;
    case 11:
      var value = new common_pb.SocialLink;
      reader.readMessage(value,common_pb.SocialLink.deserializeBinaryFromReader);
      msg.addSocialLinks(value);
      break;
    case 12:
      var value = new proto.people.PeopleWebsiteMetricsResponse;
      reader.readMessage(value,proto.people.PeopleWebsiteMetricsResponse.deserializeBinaryFromReader);
      msg.addMetrics(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.people.PeopleWebsiteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.people.PeopleWebsiteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.people.PeopleWebsiteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PeopleWebsiteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLogoUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDomain();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPeopleNumber();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getOwnerId();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getContactsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      people_pb.PeopleShortResponse.serializeBinaryToWriter
    );
  }
  f = message.getSocialLinksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      common_pb.SocialLink.serializeBinaryToWriter
    );
  }
  f = message.getMetricsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.people.PeopleWebsiteMetricsResponse.serializeBinaryToWriter
    );
  }
  f = message.getTotalItems();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.people.PeopleWebsiteResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleWebsiteResponse} returns this
 */
proto.people.PeopleWebsiteResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.people.PeopleWebsiteResponse.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.PeopleWebsiteResponse} returns this
 */
proto.people.PeopleWebsiteResponse.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string logo_url = 3;
 * @return {string}
 */
proto.people.PeopleWebsiteResponse.prototype.getLogoUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.PeopleWebsiteResponse} returns this
 */
proto.people.PeopleWebsiteResponse.prototype.setLogoUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string domain = 4;
 * @return {string}
 */
proto.people.PeopleWebsiteResponse.prototype.getDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.PeopleWebsiteResponse} returns this
 */
proto.people.PeopleWebsiteResponse.prototype.setDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 people_number = 5;
 * @return {number}
 */
proto.people.PeopleWebsiteResponse.prototype.getPeopleNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleWebsiteResponse} returns this
 */
proto.people.PeopleWebsiteResponse.prototype.setPeopleNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional common.PeopleRelationshipStatus status = 6;
 * @return {!proto.common.PeopleRelationshipStatus}
 */
proto.people.PeopleWebsiteResponse.prototype.getStatus = function() {
  return /** @type {!proto.common.PeopleRelationshipStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.common.PeopleRelationshipStatus} value
 * @return {!proto.people.PeopleWebsiteResponse} returns this
 */
proto.people.PeopleWebsiteResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional int64 owner_id = 7;
 * @return {number}
 */
proto.people.PeopleWebsiteResponse.prototype.getOwnerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleWebsiteResponse} returns this
 */
proto.people.PeopleWebsiteResponse.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 created_at = 8;
 * @return {number}
 */
proto.people.PeopleWebsiteResponse.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleWebsiteResponse} returns this
 */
proto.people.PeopleWebsiteResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 workspace_id = 9;
 * @return {number}
 */
proto.people.PeopleWebsiteResponse.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleWebsiteResponse} returns this
 */
proto.people.PeopleWebsiteResponse.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * repeated PeopleShortResponse contacts = 10;
 * @return {!Array<!proto.people.PeopleShortResponse>}
 */
proto.people.PeopleWebsiteResponse.prototype.getContactsList = function() {
  return /** @type{!Array<!proto.people.PeopleShortResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, people_pb.PeopleShortResponse, 10));
};


/**
 * @param {!Array<!proto.people.PeopleShortResponse>} value
 * @return {!proto.people.PeopleWebsiteResponse} returns this
*/
proto.people.PeopleWebsiteResponse.prototype.setContactsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.people.PeopleShortResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.people.PeopleShortResponse}
 */
proto.people.PeopleWebsiteResponse.prototype.addContacts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.people.PeopleShortResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.people.PeopleWebsiteResponse} returns this
 */
proto.people.PeopleWebsiteResponse.prototype.clearContactsList = function() {
  return this.setContactsList([]);
};


/**
 * repeated common.SocialLink social_links = 11;
 * @return {!Array<!proto.common.SocialLink>}
 */
proto.people.PeopleWebsiteResponse.prototype.getSocialLinksList = function() {
  return /** @type{!Array<!proto.common.SocialLink>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.SocialLink, 11));
};


/**
 * @param {!Array<!proto.common.SocialLink>} value
 * @return {!proto.people.PeopleWebsiteResponse} returns this
*/
proto.people.PeopleWebsiteResponse.prototype.setSocialLinksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.common.SocialLink=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.SocialLink}
 */
proto.people.PeopleWebsiteResponse.prototype.addSocialLinks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.common.SocialLink, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.people.PeopleWebsiteResponse} returns this
 */
proto.people.PeopleWebsiteResponse.prototype.clearSocialLinksList = function() {
  return this.setSocialLinksList([]);
};


/**
 * repeated PeopleWebsiteMetricsResponse metrics = 12;
 * @return {!Array<!proto.people.PeopleWebsiteMetricsResponse>}
 */
proto.people.PeopleWebsiteResponse.prototype.getMetricsList = function() {
  return /** @type{!Array<!proto.people.PeopleWebsiteMetricsResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.people.PeopleWebsiteMetricsResponse, 12));
};


/**
 * @param {!Array<!proto.people.PeopleWebsiteMetricsResponse>} value
 * @return {!proto.people.PeopleWebsiteResponse} returns this
*/
proto.people.PeopleWebsiteResponse.prototype.setMetricsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.people.PeopleWebsiteMetricsResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.people.PeopleWebsiteMetricsResponse}
 */
proto.people.PeopleWebsiteResponse.prototype.addMetrics = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.people.PeopleWebsiteMetricsResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.people.PeopleWebsiteResponse} returns this
 */
proto.people.PeopleWebsiteResponse.prototype.clearMetricsList = function() {
  return this.setMetricsList([]);
};


/**
 * optional int32 total_items = 13;
 * @return {number}
 */
proto.people.PeopleWebsiteResponse.prototype.getTotalItems = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleWebsiteResponse} returns this
 */
proto.people.PeopleWebsiteResponse.prototype.setTotalItems = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.people.PeopleComposedResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.people.PeopleComposedResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.people.PeopleComposedResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.people.PeopleComposedResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PeopleComposedResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    person: (f = msg.getPerson()) && people_pb.PeopleCrudResponse.toObject(includeInstance, f),
    company: (f = msg.getCompany()) && proto.people.PeopleWebsiteResponse.toObject(includeInstance, f),
    coworkersList: jspb.Message.toObjectList(msg.getCoworkersList(),
    people_pb.PeopleShortResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.people.PeopleComposedResponse}
 */
proto.people.PeopleComposedResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.people.PeopleComposedResponse;
  return proto.people.PeopleComposedResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.people.PeopleComposedResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.people.PeopleComposedResponse}
 */
proto.people.PeopleComposedResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new people_pb.PeopleCrudResponse;
      reader.readMessage(value,people_pb.PeopleCrudResponse.deserializeBinaryFromReader);
      msg.setPerson(value);
      break;
    case 2:
      var value = new proto.people.PeopleWebsiteResponse;
      reader.readMessage(value,proto.people.PeopleWebsiteResponse.deserializeBinaryFromReader);
      msg.setCompany(value);
      break;
    case 3:
      var value = new people_pb.PeopleShortResponse;
      reader.readMessage(value,people_pb.PeopleShortResponse.deserializeBinaryFromReader);
      msg.addCoworkers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.people.PeopleComposedResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.people.PeopleComposedResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.people.PeopleComposedResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PeopleComposedResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPerson();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      people_pb.PeopleCrudResponse.serializeBinaryToWriter
    );
  }
  f = message.getCompany();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.people.PeopleWebsiteResponse.serializeBinaryToWriter
    );
  }
  f = message.getCoworkersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      people_pb.PeopleShortResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional PeopleCrudResponse person = 1;
 * @return {?proto.people.PeopleCrudResponse}
 */
proto.people.PeopleComposedResponse.prototype.getPerson = function() {
  return /** @type{?proto.people.PeopleCrudResponse} */ (
    jspb.Message.getWrapperField(this, people_pb.PeopleCrudResponse, 1));
};


/**
 * @param {?proto.people.PeopleCrudResponse|undefined} value
 * @return {!proto.people.PeopleComposedResponse} returns this
*/
proto.people.PeopleComposedResponse.prototype.setPerson = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.people.PeopleComposedResponse} returns this
 */
proto.people.PeopleComposedResponse.prototype.clearPerson = function() {
  return this.setPerson(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.people.PeopleComposedResponse.prototype.hasPerson = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PeopleWebsiteResponse company = 2;
 * @return {?proto.people.PeopleWebsiteResponse}
 */
proto.people.PeopleComposedResponse.prototype.getCompany = function() {
  return /** @type{?proto.people.PeopleWebsiteResponse} */ (
    jspb.Message.getWrapperField(this, proto.people.PeopleWebsiteResponse, 2));
};


/**
 * @param {?proto.people.PeopleWebsiteResponse|undefined} value
 * @return {!proto.people.PeopleComposedResponse} returns this
*/
proto.people.PeopleComposedResponse.prototype.setCompany = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.people.PeopleComposedResponse} returns this
 */
proto.people.PeopleComposedResponse.prototype.clearCompany = function() {
  return this.setCompany(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.people.PeopleComposedResponse.prototype.hasCompany = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated PeopleShortResponse coworkers = 3;
 * @return {!Array<!proto.people.PeopleShortResponse>}
 */
proto.people.PeopleComposedResponse.prototype.getCoworkersList = function() {
  return /** @type{!Array<!proto.people.PeopleShortResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, people_pb.PeopleShortResponse, 3));
};


/**
 * @param {!Array<!proto.people.PeopleShortResponse>} value
 * @return {!proto.people.PeopleComposedResponse} returns this
*/
proto.people.PeopleComposedResponse.prototype.setCoworkersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.people.PeopleShortResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.people.PeopleShortResponse}
 */
proto.people.PeopleComposedResponse.prototype.addCoworkers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.people.PeopleShortResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.people.PeopleComposedResponse} returns this
 */
proto.people.PeopleComposedResponse.prototype.clearCoworkersList = function() {
  return this.setCoworkersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.people.WebsiteRelationshipStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.people.WebsiteRelationshipStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.people.WebsiteRelationshipStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.WebsiteRelationshipStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    websiteId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    optOrgId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.people.WebsiteRelationshipStatusRequest}
 */
proto.people.WebsiteRelationshipStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.people.WebsiteRelationshipStatusRequest;
  return proto.people.WebsiteRelationshipStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.people.WebsiteRelationshipStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.people.WebsiteRelationshipStatusRequest}
 */
proto.people.WebsiteRelationshipStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWebsiteId(value);
      break;
    case 2:
      var value = /** @type {!proto.common.PeopleRelationshipStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOptOrgId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.people.WebsiteRelationshipStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.people.WebsiteRelationshipStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.people.WebsiteRelationshipStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.WebsiteRelationshipStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWebsiteId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getOptOrgId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 website_id = 1;
 * @return {number}
 */
proto.people.WebsiteRelationshipStatusRequest.prototype.getWebsiteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.WebsiteRelationshipStatusRequest} returns this
 */
proto.people.WebsiteRelationshipStatusRequest.prototype.setWebsiteId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional common.PeopleRelationshipStatus status = 2;
 * @return {!proto.common.PeopleRelationshipStatus}
 */
proto.people.WebsiteRelationshipStatusRequest.prototype.getStatus = function() {
  return /** @type {!proto.common.PeopleRelationshipStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.PeopleRelationshipStatus} value
 * @return {!proto.people.WebsiteRelationshipStatusRequest} returns this
 */
proto.people.WebsiteRelationshipStatusRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int64 opt_org_id = 3;
 * @return {number}
 */
proto.people.WebsiteRelationshipStatusRequest.prototype.getOptOrgId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.WebsiteRelationshipStatusRequest} returns this
 */
proto.people.WebsiteRelationshipStatusRequest.prototype.setOptOrgId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.people.RelationshipPipelineOpportunityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.people.RelationshipPipelineOpportunityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.people.RelationshipPipelineOpportunityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.RelationshipPipelineOpportunityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    pipelineId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pipelineTitle: jspb.Message.getFieldWithDefault(msg, 2, ""),
    stageId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    stageTitle: jspb.Message.getFieldWithDefault(msg, 4, ""),
    assignedToUserId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    createdByUserId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 7, 0),
    id: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.people.RelationshipPipelineOpportunityResponse}
 */
proto.people.RelationshipPipelineOpportunityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.people.RelationshipPipelineOpportunityResponse;
  return proto.people.RelationshipPipelineOpportunityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.people.RelationshipPipelineOpportunityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.people.RelationshipPipelineOpportunityResponse}
 */
proto.people.RelationshipPipelineOpportunityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPipelineId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPipelineTitle(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStageId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStageTitle(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAssignedToUserId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedByUserId(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.people.RelationshipPipelineOpportunityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.people.RelationshipPipelineOpportunityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.people.RelationshipPipelineOpportunityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.RelationshipPipelineOpportunityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPipelineId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPipelineTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStageId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getStageTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAssignedToUserId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getCreatedByUserId();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
};


/**
 * optional int64 pipeline_id = 1;
 * @return {number}
 */
proto.people.RelationshipPipelineOpportunityResponse.prototype.getPipelineId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.RelationshipPipelineOpportunityResponse} returns this
 */
proto.people.RelationshipPipelineOpportunityResponse.prototype.setPipelineId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string pipeline_title = 2;
 * @return {string}
 */
proto.people.RelationshipPipelineOpportunityResponse.prototype.getPipelineTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.RelationshipPipelineOpportunityResponse} returns this
 */
proto.people.RelationshipPipelineOpportunityResponse.prototype.setPipelineTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 stage_id = 3;
 * @return {number}
 */
proto.people.RelationshipPipelineOpportunityResponse.prototype.getStageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.RelationshipPipelineOpportunityResponse} returns this
 */
proto.people.RelationshipPipelineOpportunityResponse.prototype.setStageId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string stage_title = 4;
 * @return {string}
 */
proto.people.RelationshipPipelineOpportunityResponse.prototype.getStageTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.RelationshipPipelineOpportunityResponse} returns this
 */
proto.people.RelationshipPipelineOpportunityResponse.prototype.setStageTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 assigned_to_user_id = 5;
 * @return {number}
 */
proto.people.RelationshipPipelineOpportunityResponse.prototype.getAssignedToUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.RelationshipPipelineOpportunityResponse} returns this
 */
proto.people.RelationshipPipelineOpportunityResponse.prototype.setAssignedToUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 created_by_user_id = 6;
 * @return {number}
 */
proto.people.RelationshipPipelineOpportunityResponse.prototype.getCreatedByUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.RelationshipPipelineOpportunityResponse} returns this
 */
proto.people.RelationshipPipelineOpportunityResponse.prototype.setCreatedByUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 created_at = 7;
 * @return {number}
 */
proto.people.RelationshipPipelineOpportunityResponse.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.RelationshipPipelineOpportunityResponse} returns this
 */
proto.people.RelationshipPipelineOpportunityResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 id = 8;
 * @return {number}
 */
proto.people.RelationshipPipelineOpportunityResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.RelationshipPipelineOpportunityResponse} returns this
 */
proto.people.RelationshipPipelineOpportunityResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * @enum {number}
 */
proto.people.PeopleWebsiteFilterField = {
  STRING_TITLE: 0,
  STRING_WEBSITE: 1,
  NUMBER_WEBSITE_MEMBERS: 2,
  DROPDOWN_WEBSITE_OWNER_ID: 3,
  DATE_WEBSITE_CREATED_AT: 4,
  DROPDOWN_WEBSITE_TAG: 5,
  DROPDOWN_PERSON_CAMPAIGN_ID: 6,
  DROPDOWN_WEBSITE_WORKSPACE_ID: 7
};

goog.object.extend(exports, proto.people);
