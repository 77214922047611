import React from 'react';

export default ({ color = '#200E32' }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.14292 8.5015V14.2183"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0316 5.76605V14.2183"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.8568 11.5224V14.2182"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9046 1.66672H6.09508C3.37285 1.66672 1.6665 3.59345 1.6665 6.32102V13.6791C1.6665 16.4066 3.36492 18.3334 6.09508 18.3334H13.9046C16.6348 18.3334 18.3332 16.4066 18.3332 13.6791V6.32102C18.3332 3.59345 16.6348 1.66672 13.9046 1.66672Z"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
