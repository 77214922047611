import { SVGIcon } from '@uikit/Icon/Icon';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';

import './ContactsSidebarCRMSection.scss';
import { Draggable } from 'react-beautiful-dnd';

type Props = {
  index: number;
  id: string;
  title: React.ReactNode;
  rightComponent?: React.ReactNode;
  children?: React.ReactNode;
  onOpen?: () => void;
  extraClass?: string;
  forceOpen?: boolean;
};

const ContactsSidebarCRMSection = React.forwardRef(
  (
    { id, index, title, rightComponent, children, onOpen, extraClass, forceOpen = false }: Props,
    ref: { current: { setOpen: (open: boolean) => void } }
  ) => {
    const [isOpen, setOpen] = useState<boolean>(forceOpen);

    useEffect(() => {
      if (isOpen && !!onOpen) {
        onOpen();
      }
    }, [onOpen, isOpen]);

    useEffect(() => {
      if (!ref) {
        return;
      }

      ref.current = { setOpen };
    }, [ref]);

    useEffect(() => {
      if (forceOpen) {
        setOpen(true);
      }
    }, [forceOpen]);

    return (
      <Draggable draggableId={id} index={index}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            className={`contact-sidebar-crm-section ${extraClass}`}
          >
            <div className="contact-sidebar-crm-section__header">
              <div {...provided.dragHandleProps} className="contact-sidebar-crm-section__drag-icon">
                <SVGIcon icon="actionsTwoDots" size={10} color="#BDBDBDBA" />
              </div>

              <div
                className="contact-sidebar-crm-section__header-title"
                onClick={() => setOpen((open) => !open)}
              >
                {title}
              </div>
              <div
                className={cn('contact-sidebar-crm-section__header-collapse-btn', {
                  'contact-sidebar-crm-section__header-collapse-btn--open': isOpen,
                })}
                onClick={() => setOpen((open) => !open)}
              >
                <SVGIcon icon="chevron" size={8} color="#7B7B7BB0" />
              </div>
              {rightComponent && (
                <div className="contact-sidebar-crm-section__header-right-component">
                  {rightComponent}
                </div>
              )}
            </div>

            <div
              className={cn('contact-sidebar-crm-section__content-container', {
                'contact-sidebar-crm-section__content-container--open': isOpen,
              })}
            >
              <div className="contact-sidebar-crm-section__content">{children}</div>
            </div>
          </div>
        )}
      </Draggable>
    );
  }
);

export default ContactsSidebarCRMSection;
