import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getCurrentInvoiceApi } from '@api/billing.api';

import { updateAuthStepRequest } from '@redux/thunks/userProfileRequests';

import loadingStatuses from 'src/constants/loadingStatuses';
import Loader from 'src/uikit/Loader/Loader';
import { checkIsAnyNotLoaded } from 'src/utils/loadingChecks';
import InvoiceInfo from '@components/InvoiceInfo/InvoiceInfo';
import OnboardingTrial from '@components/Onboarding/OnboardingTrial/OnboardingTrial';
import ExitIntentPopup from '@components/Onboarding/ExitIntentPopup/ExitIntentPopup';

import './OnboardingPayment.scss';

const OnboardingPayment = (): JSX.Element => {
  const dispatch = useDispatch();
  const [needCheckLeave, changeNeedCheckLeave] = useState(-1);
  const [invoice, changeInvoice] = useState(null);
  const [invoiceLs, changeInvoiceLs] = useState(loadingStatuses.PENDING);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      changeNeedCheckLeave(0);
    }, 5000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    changeInvoiceLs(loadingStatuses.INITED);

    getCurrentInvoiceApi()
      .then(changeInvoice)
      .finally(() => changeInvoiceLs(loadingStatuses.LOADED));
  }, []);

  if (checkIsAnyNotLoaded(invoiceLs)) {
    return <Loader isLoading />;
  }

  const handleCustomInvoicePayment = () => {
    dispatch(updateAuthStepRequest(2));
  };

  if (invoice?.isCustom) {
    return <InvoiceInfo invoice={invoice.invoice} onPaymentSuccess={handleCustomInvoicePayment} />;
  }

  return <OnboardingTrial invoiceId={invoice.invoice.id} amount={invoice?.chargeAmount} />;
};

export default OnboardingPayment;
