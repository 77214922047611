/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { components } from 'react-select';

import Button from 'src/uikit/Button/Button';
import { SVGIcon } from 'src/uikit/Icon/Icon';
import Select from 'src/uikit/Select/Select';

import './MoreActionsButton.scss';

import { FolderType } from '../../../../types/common.types';
import Display from '@components/Display/Display';
import { transformDataWithIdAndTittleToOptions } from '@helpers/transformDataHelpers';
import SelectOption
  from '@components/Relationships/PeopleHeaderActions/MoreActionsButton/_components/SelectOption/SelectOption';
import { getCampaignShortcutsApi } from '@api/campaign.api';
import { useSelector } from 'react-redux';
import { getCurrentWorkspaceId } from '@redux/selectors/workspaces.selectors';
import {
  OnAddToCampaignFunctionType
} from '@components/Relationships/PeopleHeaderActions/MoreActionsButton/MoreActionsButton';

type MoreActionsButtonPropsType = {
  folders: FolderType[];
  onCreateOrMoveFolder: (folderId?: number) => void;
  isSmallScreen?: boolean;
  onAddToCampaign: OnAddToCampaignFunctionType;
};

const createSelectMenu = (title, buttonCallback) => (props) => {
  return (
    <>
      <components.Menu {...props}>
        <div className="organizations-actions-menu">
          <div className="organizations-actions-menu__header">
            <Button
              type="ghost"
              className="organizations-actions-menu__button"
              onClick={buttonCallback}
            >
              {title}
            </Button>
          </div>
          <div>{props.children}</div>
        </div>
      </components.Menu>
    </>
  );
};

// TODO: remove this
const Actions = {
  Tag: 'tag',
  Folder: 'folder',
  Campaign: 'campaign',
};

const transformFoldersToOptions = (items) =>
  items.map(({ id, title }) => ({
    value: id,
    label: title,
  }));

const MoreActionsButton = ({
  folders,
  onCreateOrMoveFolder,
  isSmallScreen,
  onAddToCampaign,
}: MoreActionsButtonPropsType): JSX.Element => {
  const moreActionsTooltipRef = useRef();
  const [activeAction, changeActiveAction] = useState('');
  const [campaignsList, changeCampaignsList] = useState([]);
  const currentWorkspaceId = useSelector(getCurrentWorkspaceId);

  useEffect(() => {
    if (activeAction === Actions.Campaign) {
      getCampaignShortcutsApi(currentWorkspaceId).then(changeCampaignsList);
    }
  }, [activeAction]);

  const handleMoreActionsClick = () => ReactTooltip.show(moreActionsTooltipRef.current);
  const createSetCurrentActionHandler = (action) => () => changeActiveAction(action);

  const handleCreateFolder = () => onCreateOrMoveFolder();

  const handleSelectFolder = ({ value }) => onCreateOrMoveFolder(value);

  return (
    <>
      <Button
        innerRef={moreActionsTooltipRef}
        onClick={handleMoreActionsClick}
        data-for="organizations-more-actions"
        type="additional"
        className="organizations-header-actions__more-actions-btn"
        data-tip=""
        data-event="click"
      >
        <SVGIcon icon="actions" />
        {isSmallScreen ? '' : 'More actions'}
      </Button>
      <ReactTooltip
        afterHide={() => changeActiveAction('')}
        id="organizations-more-actions"
        className="react-tooltip"
        place="bottom"
        effect="solid"
        globalEventOff="click"
        arrowColor="transparent"
        clickable
      >
        <div
          className="organizations-header-more-actions-tooltip"
          onClick={(e) => e.stopPropagation()}
        >
          <Display isVisible>
            <div
              className="people-header-more-actions-tooltip__action"
              onClick={createSetCurrentActionHandler(Actions.Campaign)}
              tabIndex={0}
              role="button"
            >
              {activeAction === Actions.Campaign ? (
                <div className="people-header-more-actions-tooltip__select">
                  <Select
                    isGhost
                    value={null}
                    options={transformDataWithIdAndTittleToOptions(campaignsList)}
                    additionalComponents={{
                      Menu: createSelectMenu(null, null),
                      Option: SelectOption,
                    }}
                    classNamePrefix="people-header-more-actions-react-select"
                    onChange={({ value, label: campaignName }) => {
                      onAddToCampaign(campaignName, value);
                    }}
                  />
                </div>
              ) : (
                <>
                  <SVGIcon icon="inbox" />
                  Add to campaign
                </>
              )}
            </div>
          </Display>

          <div
            className="organizations-header-more-actions-tooltip__action"
            onClick={createSetCurrentActionHandler(Actions.Folder)}
          >
            {activeAction === Actions.Folder ? (
              <div className="organizations-header-more-actions-tooltip__select">
                <Select
                  isGhost
                  value={null}
                  options={transformFoldersToOptions(folders)}
                  additionalComponents={{
                    Menu: createSelectMenu('Add folder', handleCreateFolder),
                  }}
                  onChange={handleSelectFolder}
                />
              </div>
            ) : (
              <>
                <SVGIcon icon="folderWhite" />
                Add to folder
              </>
            )}
          </div>
        </div>
      </ReactTooltip>
    </>
  );
};

export default MoreActionsButton;
