import React from 'react';

import { PeopleRelationshipStatusMap } from 'respona_api/generated/common_pb';

import { SVGIcon, SvgIconType } from '@uikit/Icon/Icon';

import './RelationshipStatus.scss';

export const relationshipStatusInfoMap = {
  0: {
    icon: 'personWithMinus',
    title: 'Not contacted',
  },
  1: {
    icon: 'plane',
    title: 'Scheduled',
  },
  2: {
    icon: 'userPlus',
    title: 'Contacted',
  },
  3: {
    icon: 'heart',
    title: 'Replied',
  },
  4: {
    icon: 'bounced',
    title: 'Bounced',
  },
  5: {
    icon: 'banWhite',
    title: 'Unsubscribed',
  },
  6: {
    icon: 'stopMessaging',
    title: 'Stopped',
  },
  7: {
    icon: 'interestedIcon',
    title: 'Interested',
  },
  8: {
    icon: 'notInterestedIcon',
    title: 'Not interested',
  },
  9: {
    icon: 'wonIcon',
    title: 'Won',
  },
  10: {
    icon: 'lostIcon',
    title: 'Lost',
  },
};

const RelationshipStatus = ({
  status,
  isBlocked,
  withIcon = true,
}: {
  status: PeopleRelationshipStatusMap[keyof PeopleRelationshipStatusMap];
  isBlocked: boolean;
  withIcon?: boolean;
}): JSX.Element =>
  status !== undefined ? (
    <div className="relationship-status">
      {withIcon && (
        <span className="relationship-status__icon">
          <SVGIcon
            icon={relationshipStatusInfoMap[isBlocked ? 5 : status]?.icon as SvgIconType}
            color="#8D8F92"
          />
        </span>
      )}

      {relationshipStatusInfoMap[isBlocked ? 5 : status]?.title}
    </div>
  ) : (
    <>Unknown</>
  );

export default RelationshipStatus;
