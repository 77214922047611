import { createSelector } from 'reselect';

import { StateType } from '@redux/reducers';

export const campaignsStateSelector = ({ campaigns }: StateType) => campaigns;

export const campaignsFoldersSelector = createSelector(
  [campaignsStateSelector],
  ({ folders }) => folders
);

/*
  Filters
 */

export const campaignsFiltersSelector = createSelector(
  [campaignsStateSelector],
  ({ filters }) => filters
);
export const campaignsDaterangeFilterSelector = createSelector(
  [campaignsStateSelector],
  ({ filters: { dateRange } }) => dateRange
);
export const campaignsPeopleIdsFilterSelector = createSelector(
  [campaignsStateSelector],
  ({ filters: { personIds } }) => personIds
);
export const campaignsTittleFilterSelector = createSelector(
  [campaignsStateSelector],
  ({ filters: { titleSearch } }) => titleSearch
);
