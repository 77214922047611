import { handleActions } from 'redux-actions';

import {
  setActivePaymentStatusInAppState,
  toggleNotificationsSidebar,
  toggleWorkspacesSidebar,
} from 'src/redux/actions/appState.actions';

export type AppStateType = {
  isWorkspacesSidebarOpen: boolean;
  isNotificationsSidebarOpen: boolean;
  isPaymentStatusActive: boolean;
};

const initialState: AppStateType = {
  isWorkspacesSidebarOpen: false,
  isNotificationsSidebarOpen: false,
  isPaymentStatusActive: false,
};

export default handleActions(
  {
    // [toggleSidebar]: (state: AppStateType): AppStateType => ({
    //   ...state,
    //   isSidebarOpen: !state.isSidebarOpen,
    // }),
    [toggleWorkspacesSidebar]: (state: AppStateType, { payload }): AppStateType => ({
      ...state,
      isWorkspacesSidebarOpen: payload,
    }),
    [toggleNotificationsSidebar]: (state: AppStateType, { payload }): AppStateType => ({
      ...state,
      isNotificationsSidebarOpen: payload,
    }),
    [setActivePaymentStatusInAppState]: (state: AppStateType, { payload }): AppStateType => ({
      ...state,
      isPaymentStatusActive: payload,
    }),
  },
  initialState
);
