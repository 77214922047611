export default [
  {
    label: 'Url',
    value: 1,
    relatedTo: 1,
  },
  {
    label: 'Url title',
    value: 2,
    relatedTo: 1,
  },
  {
    label: 'First name',
    value: 3,
    relatedTo: 0,
  },
  {
    label: 'Full name',
    value: 4,
    relatedTo: 0,
  },
  {
    label: 'Last name',
    value: 5,
    relatedTo: 0,
  },
  {
    label: 'Email',
    value: 6,
    relatedTo: 0,
  },
  {
    label: 'Email 2',
    value: 7,
    relatedTo: 0,
  },
  {
    label: 'Email 3',
    value: 8,
    relatedTo: 0,
  },
  {
    label: 'Email 4',
    value: 9,
    relatedTo: 0,
  },
  {
    label: 'Email 5',
    value: 10,
    relatedTo: 0,
  },
  {
    label: 'Location',
    value: 11,
    relatedTo: 0,
  },
  {
    label: 'Job title',
    value: 12,
    relatedTo: 0,
  },
  {
    label: 'LinkedIn url',
    value: 14,
    relatedTo: 0,
  },
  {
    label: 'Tag',
    value: 15,
    relatedTo: 0,
  },
];
