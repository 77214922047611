import React from 'react';

export default ({ color = '#9BA0A5' }) => (
  <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="4" width="9.22656" height="7" rx="2" fill={color} />
    <path
      d="M2.30493 4V3C2.30493 3 2.30493 3 2.30493 3C2.30493 3 2.30493 1 4.61157 1C6.91821 1 6.91821 3 6.91821 3C6.91821 3 6.91821 3 6.91821 3V4"
      stroke={color}
    />
  </svg>
);
