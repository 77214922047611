import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';

import { BillingFeedbackMap, BillingSubscriptionType } from 'respona_api/generated/billing_pb';

import { BillingType } from '@ts/billing.types';

import Select from '@uikit/Select/Select';
import { Button } from '@uikit/Button/Button';
import { SVGIcon } from '@uikit/Icon/Icon';
import Textarea from '@uikit/Textarea/Textarea';
import Input from '@uikit/Input/Input';

import {
  billingCancellationOptions,
  billingCancellationSelectStyles,
} from '@components/Settings/Billing/_components/BillingCancellationFlow/billingCancellationOptions';
import { BILLING_CANCELLATION_STEP } from '@components/Settings/Billing/Billing';
import PageHeader from '@components/PageHeader/PageHeader';

import './BillingCancellationFlow.scss';

const BillingCancellationFlow = ({
  cancellationStep,
  setCancellationStep,
  handleCancelSubscription,
  handlePlanChange,
  billingDetails,
  isCancelling,
}: {
  isCancelling?: boolean;
  billingDetails: BillingType;
  cancellationStep: number;
  setCancellationStep: (step: number) => void;
  handleCancelSubscription: (
    feedback: BillingFeedbackMap[keyof BillingFeedbackMap],
    improve: string
  ) => Promise<void>;
  handlePlanChange: () => Promise<void>;
}): JSX.Element => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onChangePlan = async () => {
    setIsLoading(true);
    try {
      await handlePlanChange();
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancellation = async (data) => {
    if (data.reason?.value === undefined || !(data.comment?.length >= 20)) {
      return;
    }

    handleCancelSubscription(data.reason.value, data.comment);
  };

  return (
    <div className="billing-cancellation-flow">
      <PageHeader
        title="Cancellation"
        renderHeaderActions={() => (
          <span
            className="billing-cancellation-flow__close-btn"
            onClick={() => setCancellationStep(BILLING_CANCELLATION_STEP.default)}
          >
            <SVGIcon icon="crossBlack" />
          </span>
        )}
      />

      {cancellationStep === BILLING_CANCELLATION_STEP.MAINTENANCE && (
        <div className="billing-cancellation-flow__content">
          <div className="billing-cancellation-flow__title">Need a break?</div>
          <div className="billing-cancellation-flow__subtitle">
            if you&#39;d like to keep all of your data in Respona, and resume anytime you are ready
            we recommend our maintenance plan
          </div>
          <div className="billing-cancellation-flow__plan-details">
            <span>Maintenance Plan</span>
            <div className="billing-cancellation-flow__plan-details-price-row">
              <span className="billing-cancellation-flow__plan-details-price">$15</span>
              <span className="billing-cancellation-flow__plan-details-period">/month</span>
            </div>

            <span className="billing-cancellation-flow__plan-details-point">
              <SVGIcon size={12} icon="check" />
              Save all of your remaining credits
            </span>
            <span className="billing-cancellation-flow__plan-details-point">
              <SVGIcon size={12} icon="check" />
              Keep all of your contacts and relationships
            </span>
            <span className="billing-cancellation-flow__plan-details-point">
              <SVGIcon size={12} icon="check" />
              Automatically put your campaigns on pause
            </span>
            <span className="billing-cancellation-flow__plan-details-point">
              <SVGIcon size={12} icon="check" />
              Save all automation settings
            </span>
          </div>

          <div className="billing-cancellation-flow__footer">
            {billingDetails.type !== BillingSubscriptionType.MAINTENANCE && (
              <Button size="xl" type="primary" isLoading={isLoading} onClick={onChangePlan}>
                Downgrade to maintenance plan
              </Button>
            )}

            <Button
              size="xl"
              type="bordered-grey"
              onClick={() => setCancellationStep(BILLING_CANCELLATION_STEP.CONFIRMATION)}
            >
              No thanks, please cancel
            </Button>
          </div>
        </div>
      )}

      {cancellationStep === BILLING_CANCELLATION_STEP.CONFIRMATION && (
        <div className="billing-cancellation-flow__content">
          <div className="billing-cancellation-flow__title">Last Step!</div>

          <div className="billing-cancellation-flow__form">
            <Formik initialValues={{ reason: null, comment: '' }} onSubmit={handleCancellation}>
              {({ values, handleChange, setFieldValue }) => (
                <Form>
                  <div className="billing-cancellation-flow__form-content">
                    <div className="billing-cancellation-flow__form-label">Feedback*</div>
                    <Select
                      placeholder="Please, select a reason?"
                      value={values.reason}
                      options={billingCancellationOptions}
                      onChange={(option) => {
                        setFieldValue('reason', option);
                      }}
                      additionalStyles={billingCancellationSelectStyles}
                    />

                    <div className="billing-cancellation-flow__form-label">
                      How can we improve?*
                    </div>
                    <Textarea
                      placeholder="As a small company, we'd be very grateful if you can share a few sentences to help us improve ❤️"
                      name="comment"
                      resizeDisabled
                      isFullWidth
                      className="billing-cancellation-flow__form-textarea"
                      value={values.comment}
                      onChange={handleChange}
                    />
                    {values.comment?.length < 20 ? (
                      <div className="billing-cancellation-flow__form-character-counter">
                        At least 20 characters
                      </div>
                    ) : null}
                  </div>

                  <div style={{ marginTop: '80px' }} className="billing-cancellation-flow__footer">
                    <Button
                      size="xl"
                      type="bordered-grey"
                      onClick={() => setCancellationStep(BILLING_CANCELLATION_STEP.default)}
                    >
                      Cancel
                    </Button>

                    <Button
                      size="xl"
                      type="alert"
                      isSubmit
                      disabled={!values.reason || !(values.comment?.length >= 20)}
                      isLoading={isCancelling}
                    >
                      Confirm cancellation
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </div>
  );
};

export default BillingCancellationFlow;
