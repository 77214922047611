import React from 'react';

import { SearchTypesEnum } from '@ts/contentSearch.types';

import { useBrowserBackButton } from '@hooks/useBrowserBackButton';

import { SVGIcon } from '@uikit/Icon/Icon';
import BaseModal from '@uikit/BaseModal/BaseModal';

import GoogleAdvancedSearchListTable from './_components/GoogleAdvancedSearchListTable';
import BingAdvancedSearchListTable from './_components/BingAdvancedSearchListTable';

import './AdvancedSearchListPage.scss';

const Wrapper: React.FC<{ onClose: () => void }> = ({ children, onClose }) => (
  <BaseModal isOpen onClose={onClose}>
    <div className="advanced-search-list">
      <div className="advanced-search-list__title">
        <span
          onClick={onClose}
          className="advanced-search-list__back-link"
          tabIndex={0}
          role="button"
        >
          <SVGIcon icon="arrowLeftCircle" />
        </span>
        Advanced Search
      </div>
      {children}
    </div>
  </BaseModal>
);

export default ({
  type,
  onClose,
}: {
  type?: string | number;
  onClose: () => void;
}): JSX.Element => {
  useBrowserBackButton(onClose);

  if (type === SearchTypesEnum.googleSearch) {
    return (
      <Wrapper onClose={onClose}>
        <GoogleAdvancedSearchListTable />
      </Wrapper>
    );
  }

  if (type === SearchTypesEnum.bingSearch) {
    return (
      <Wrapper onClose={onClose}>
        <BingAdvancedSearchListTable />
      </Wrapper>
    );
  }

  return null;
};
