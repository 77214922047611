import { createSelector } from 'reselect';

import { StateType } from '@redux/reducers/index';

export const campaignSettingsSelector = ({ campaignSettings }: StateType) => campaignSettings;

export const getCampaignSettingsViewState = ({ campaignSettings: { viewState } }: StateType) =>
  viewState;

export const getSourceType = ({ campaignSettings: { sourceType } }: StateType) => sourceType;

export const getActiveCampaignInfo = ({ campaignSettings: { activeCampaign } }: StateType) =>
  activeCampaign;

export const getActiveCampaignVerifyStatus = ({
  campaignSettings: { activeCampaign },
}: StateType) => activeCampaign.info.verificationStatus;

export const getActiveCampaignVerifyProgress = ({
  campaignSettings: { activeCampaign },
}: StateType) =>
  activeCampaign.info?.verificationProgress || {
    progress: 0,
    totalNumber: 0,
    verifiedNumber: 0,
    flaggedNumber: 0,
  };

export const selectedOpportunitiesSelector = createSelector(
  [campaignSettingsSelector],
  ({ selectedOpportunities }) => ({
    loadingStatus: selectedOpportunities.loadingStatus,
    items: Object.values(selectedOpportunities.items),
  })
);

export const searchFiltersSelector = ({ campaignSettings: { searchContentFilters } }: StateType) =>
  searchContentFilters;
