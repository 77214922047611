import React from 'react';
import { useHistory } from 'react-router-dom';

import Button from '@uikit/Button/Button';
import { SVGIcon } from '@uikit/Icon/Icon';

import './BackArrowButton.scss';

const BackArrowButton = ({ forceLink }: { forceLink?: string; }): JSX.Element => {
  const history = useHistory();

  const handleClick = () => {
    if (forceLink) {
      history.push(forceLink);
    } else {
      history.goBack();
    }
  };

  return (
    <Button type="ghost" onClick={handleClick} className="back-arrow-button">
      <SVGIcon icon="arrowLeft" color="#000" />
    </Button>
  );
};

export default BackArrowButton;
