import { CampaignPipelineType, CampaignPipelineTypeMap } from 'respona_api/generated/pipeline_pb';

const getUIForType = (value: CampaignPipelineTypeMap[keyof CampaignPipelineTypeMap]): string => {
  switch (value) {
    case CampaignPipelineType.GOOGLE_SEARCH:
      return 'Google Search';
    case CampaignPipelineType.BACKLINK_SEARCH:
      return 'Backlink Search';
    case CampaignPipelineType.PODCAST_SEARCH:
      return 'Podcast Search';
    case CampaignPipelineType.IMPORT:
      return 'CSV Import';
    case CampaignPipelineType.CONTACT_AUTOMATION:
      return 'Contact Search';
    case CampaignPipelineType.PERSONALIZATION_AUTOMATION:
      return 'AI Personalization';
    default:
      return 'CSV Import';
  }
};

export default getUIForType;
