import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import braintree from 'braintree-web';
import { useHistory } from 'react-router-dom';

import { getDateShort } from '@utils/date';

import { getBraintreeTokenApi, makePaymentApi } from '@api/billing.api';

import { GREY_COLOR } from '@constants/colors';
import { cardDetailsDefault } from '@constants/payments';

import { updateAuthStepRequest } from '@redux/thunks/userProfileRequests';
import { fetchBillingCredits, fetchBillingDetails } from '@redux/thunks/billings.thunks';
import { addNotification } from '@redux/actions/notifications.actions';

import { SVGIcon } from '@uikit/Icon/Icon';
import Button from '@uikit/Button/Button';

import OnboardingPageHeader from '@components/Onboarding/OnboardingPageHeader/OnboardingPageHeader';

import './OnboardingTrial.scss';
import ReactTooltip from 'react-tooltip';

let deviceData = '';

const OnboardingTrial = ({
  invoiceId,
  amount,
}: {
  invoiceId: number;
  amount?: number;
}): JSX.Element => {
  const tooltipRef = useRef<ReactTooltip>();

  const history = useHistory();

  const dispatch = useDispatch();
  const submitRef = useRef<HTMLButtonElement>();
  const brainTreeIsInstalled = useRef<boolean>(false);

  const [isGoingPaymentProcess, changeIsGoingPaymentProcess] = useState(false);

  const setupBraintree = (token) => {
    braintree.client.create(
      {
        authorization: token,
      },
      (err, clientInstance) => {
        if (err) {
          console.error(err);
          return;
        }

        braintree.hostedFields.create(
          {
            client: clientInstance,
            styles: {
              input: {
                color: '#282c37',
                'font-size': '16px',
                transition: 'color 0.1s',
                'line-height': '3',
                'padding-left': '10px',
                height: '72px',
              },
              // Style the text of an invalid input
              'input.invalid': {
                color: '#E53A40',
              },
              // placeholder styles need to be individually adjusted
              '::-webkit-input-placeholder': {
                color: 'rgba(0,0,0,0.6)',
              },
              ':-moz-placeholder': {
                color: 'rgba(0,0,0,0.6)',
              },
              '::-moz-placeholder': {
                color: 'rgba(0,0,0,0.6)',
              },
              ':-ms-input-placeholder': {
                color: 'rgba(0,0,0,0.6)',
              },
              // prevent IE 11 and Edge from
              // displaying the clear button
              // over the card brand icon
              'input::-ms-clear': {
                opacity: '0',
              },
            },
            // Add information for individual fields
            fields: {
              number: {
                selector: '#card-number',
                placeholder: cardDetailsDefault.CARD_NUMBER,
              },
              cvv: {
                selector: '#cvv',
                placeholder: cardDetailsDefault.CVV,
              },
              expirationDate: {
                selector: '#expiration-date',
                placeholder: cardDetailsDefault.EXPIRATION,
              },
            },
          },
          (instanceError, hostedFieldsInstance) => {
            if (instanceError) {
              console.error(instanceError);
              return;
            }

            braintree.dataCollector.create(
              {
                client: clientInstance,
                paypal: false,
              },
              (dataCollectorErr, dataCollectorInstance) => {
                if (dataCollectorErr) {
                  console.log(dataCollectorErr);
                  return;
                }
                deviceData = dataCollectorInstance.deviceData;
              }
            );

            hostedFieldsInstance.on('empty', function (event) {
              document.getElementById('card-image').className = '';
            });

            hostedFieldsInstance.on('cardTypeChange', function (event) {
              if (event.cards.length === 1) {
                document.getElementById('card-image').className = event.cards[0]?.type + '-md';
              }
            });

            if (submitRef.current) {
              // @ts-ignore
              submitRef.current.addEventListener(
                'click',
                (event) => {
                  event.preventDefault();
                  changeIsGoingPaymentProcess(true);

                  hostedFieldsInstance.tokenize((tokenizeErr, payload) => {
                    if (tokenizeErr) {
                      // we use setTimeout to make error message emergence and loading duration more smoothly
                      setTimeout(() => {
                        changeIsGoingPaymentProcess(false);
                        dispatch(
                          addNotification({ type: 'error', title: 'Invalid payment details' })
                        );
                      }, 500);
                      console.error(tokenizeErr.message);
                      return;
                    }
                    const details = JSON.parse(submitRef?.current.getAttribute('billingDetails'));
                    makePaymentApi(invoiceId, payload.nonce, deviceData, details)
                      .then(async ({ isSuccess, message }) => {
                        if (isSuccess) {
                          setTimeout(async () => {
                            await dispatch(updateAuthStepRequest(2));
                            await dispatch(fetchBillingDetails(true));
                            await dispatch(fetchBillingCredits(true));
                            history.push('/campaign-templates');
                          }, 1100);
                        }

                        if (!isSuccess) {
                          changeIsGoingPaymentProcess(false);
                          dispatch(addNotification({ type: 'error', title: message }));
                        }
                      })
                      .catch(() => changeIsGoingPaymentProcess(false));
                  });
                },
                false
              );
            }
          }
        );
      }
    );
  };

  useEffect(() => {
    if (submitRef.current && !brainTreeIsInstalled.current) {
      getBraintreeTokenApi().then((res) => {
        brainTreeIsInstalled.current = true;
        setupBraintree(res.value);
      });
    }
  }, [submitRef, submitRef.current]);

  const trialExpiration = new Date();
  trialExpiration.setDate(trialExpiration.getDate() + 7);

  return (
    <div className="onboarding-default-payment">
      <OnboardingPageHeader
        icon="creditCard"
        title="Start your 7-day free trial"
        subtitle="Canceling is easy and we’ll remind you a few days before your trial ends."
      />

      <div className="onboarding-default-payment__content">
        <div className="onboarding-default-payment__subscription-info">
          Monthly subscription
          <div className="onboarding-default-payment__subscription-info-details">
            <div className="onboarding-default-payment__subscription-info-details-price">
              197/mo
            </div>
            <div className="onboarding-default-payment__subscription-info-details-date">
              First charge: {getDateShort(trialExpiration)}
            </div>
          </div>
        </div>

        <div className="onboarding-default-payment__payment-info">
          Due Today
          <div className="onboarding-default-payment__payment-price">$0</div>
        </div>
        {/*<div className="onboarding-default-payment__payment-details-text">Credit card on file</div>*/}

        <form className="onboarding-default-payment-form" method="post">
          <div className="onboarding-default-payment__payment-label">Credit card on file</div>
          <div className="onboarding-default-payment-form__fields" id="checkout">
            <div className="onboarding-default-payment-form__input-field-wrapper">
              <div id="card-image" />
              <div
                className="onboarding-default-payment-form__input-field"
                id="card-number"
                data-braintree-name="number"
              />
            </div>

            <div
              className="onboarding-default-payment-form__input-field-date"
              id="expiration-date"
              data-braintree-name="cvv"
            />
            <div
              className="onboarding-default-payment-form__input-field-cvv"
              id="cvv"
              data-braintree-name="expiration_date"
            />
          </div>
        </form>

        <Button
          isLoading={isGoingPaymentProcess}
          loadingText="Loading..."
          className="onboarding-default-payment-form__submit-btn"
          innerRef={submitRef}
        >
          Get started
        </Button>

        <div className="onboarding-default-payment__bottom-promises-container">
          <div className="onboarding-default-payment__bottom-promise">
            <SVGIcon icon="shield" color={GREY_COLOR} />
            <span className="onboarding-default-payment__bottom-promise-text">
              You won’t be charged until your trial ends. This is a pre-auth check to verify your
              card.
            </span>
          </div>
          <div className="onboarding-default-payment__bottom-promise">
            <SVGIcon icon="shield" color={GREY_COLOR} />
            <span className="onboarding-default-payment__bottom-promise-text">
              We we&apos;ll send you a reminder email a few days before your trial ends.
            </span>
          </div>
          <div className="onboarding-default-payment__bottom-promise">
            <SVGIcon icon="shield" color={GREY_COLOR} />
            <span className="onboarding-default-payment__bottom-promise-text">
              If you cancel your account during the trial, you won’t be billed.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingTrial;
