import React from 'react';

import { SVGIcon } from '@uikit/Icon/Icon';
import Avatar from '@uikit/Avatar/Avatar';
import Display from '@components/Display/Display';

const PersonItem = ({ avatarSrc, name, isChoosen }) => (
  <>
    <Avatar className="team-filter__person-avatar" url={avatarSrc} size="xs" fullName={name} />
    <span className="team-filter__person-name">{name}</span>
    <Display isVisible={isChoosen}>
      <div className="team-filter__chosen-person-icon">
        <SVGIcon icon="star" color="#FC8E0E" />
      </div>
    </Display>
  </>
);

export default PersonItem;
