import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  KeywordSearchSource,
  KeywordSearchSourceMap,
} from 'respona_api/generated/automation-content_pb';

import {
  BacklinksSearchSourceMap,
  IntegrationStatus as INTEGRATION_STATUS,
  IntegrationType as INTEGRATION_TYPE,
} from 'respona_api/generated/common_pb';

import { mapFromToToMinMax, mapMinMaxToFromTo } from '@helpers/commonHelpers';

import { setMozMetrics } from '@redux/actions/automationContent.actions';
import {
  configSelector,
  mozMetricsSelector,
  searchModeSelector,
} from '@redux/selectors/automationContent.selectors';
import { integrationsSelector } from '@redux/selectors/integrations.selectors';

import { Button } from '@uikit/Button/Button';

import QuestionTooltip from '@pages/CampaignCreationPage/_components/QuestionTooltip/QuestionTooltip';

import FromToRow from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/FromToRow/FromToRow';
import MozLogo from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/assets/MozLogo';
import MetricsWrapper from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/MetricsWrapper/MetricsWrapper';

import './MozMetrics.scss';

const MozMetrics = ({
  source,
  searchSource,
  isBacklinksSearch,
}: {
  source?: BacklinksSearchSourceMap[keyof BacklinksSearchSourceMap];
  searchSource?: KeywordSearchSourceMap[keyof KeywordSearchSourceMap];
  isBacklinksSearch?: boolean;
}) => {
  const dispatch = useDispatch();
  const { includePaidMetrics, domainAuthority, domainSpamScore, pageAuthority } =
    useSelector(mozMetricsSelector);
  const searchMode = useSelector(searchModeSelector);
  const { integrations } = useSelector(integrationsSelector);

  const isMozConnected = integrations.some(
    (item) =>
      item.type === INTEGRATION_TYPE.MOZ_INTEGRATION &&
      item.status === INTEGRATION_STATUS.ACTIVE_INTEGRATION
  );

  const isNotResponaSearch = !isMozConnected && !(searchMode === 1 && source === 0);

  const toggleIncludePaidMetrics = () => {
    dispatch(
      setMozMetrics({
        includePaidMetrics: !includePaidMetrics,
      })
    );
  };

  useEffect(() => {
    if (isBacklinksSearch) {
      return;
    }

    if (
      (!includePaidMetrics && !isNotResponaSearch) ||
      (includePaidMetrics && searchSource === KeywordSearchSource.GOOGLE_NEWS_KEYWORD_SEARCH) ||
      (!includePaidMetrics && searchSource === KeywordSearchSource.GOOGLE_KEYWORD_SEARCH)
    ) {
      toggleIncludePaidMetrics();
    }
  }, []);

  return (
    <MetricsWrapper
      borderRight
      Logo={() => <MozLogo width={103} height={30} />}
      withAddOtherMetricsBtn={false}
    >
      <div className="moz-metrics-paid">
        <FromToRow
          inputStyle={{ width: '159px' }}
          label={
            <>
              Domain authority
              <QuestionTooltip
                color="#C6C6C6"
                style={{ marginLeft: '7px' }}
                text="Domain’s overall quality based on the number of backlinks, referring domains, organic search traffic, and other data. The max value is 100."
              />
            </>
          }
          onChange={(from, to) => {
            dispatch(
              setMozMetrics({
                domainAuthority: mapFromToToMinMax({ from, to }),
              })
            );
          }}
          {...mapMinMaxToFromTo(domainAuthority)}
        />

        <FromToRow
          maxNumber={60}
          inputStyle={{ width: '159px' }}
          label={
            <>
              Domain spam score
              <QuestionTooltip
                color="#C6C6C6"
                style={{ marginLeft: '7px' }}
                text={
                  <>
                    A measure of how likely a website is to be considered spam by search engines.
                    <br />
                    <br />
                    1%-30% is considered a Low Spam Score.
                    <br />
                    <br />
                    31%-60% is considered a Medium Spam Score.
                    <br />
                    <br />
                    61%-100% is considered a High Spam Score.
                  </>
                }
              />
            </>
          }
          onChange={(from, to) => {
            dispatch(
              setMozMetrics({
                domainSpamScore: mapFromToToMinMax({ from, to }),
              })
            );
          }}
          {...mapMinMaxToFromTo(domainSpamScore)}
        />

        <FromToRow
          maxNumber={1000000}
          inputStyle={{ width: '159px' }}
          label={
            <>
              Page authority
              <QuestionTooltip
                color="#C6C6C6"
                style={{ marginLeft: '7px' }}
                text="How well a specific page is likely to rank on Google. Page Authority scores range from one to 100, with higher scores corresponding to a greater ability to rank."
              />
            </>
          }
          onChange={(from, to) => {
            dispatch(
              setMozMetrics({
                pageAuthority: mapFromToToMinMax({ from, to }),
              })
            );
          }}
          {...mapMinMaxToFromTo(pageAuthority)}
        />

        {!includePaidMetrics ? (
          <div className="moz-metrics-include-paid">
            <span>Use Moz metrics to refine search results</span>
            {/*{isNotResponaSearch && (*/}
            {/*  <span className="moz-metrics-include-paid__price">1 credit per 1 search result</span>*/}
            {/*)}*/}
            <Button onClick={toggleIncludePaidMetrics}>Add filters</Button>
          </div>
        ) : (
          <Button
            className="moz-metrics-remove-filters"
            onClick={toggleIncludePaidMetrics}
            type="ghost"
            size="m"
          >
            Remove filters
          </Button>
        )}
      </div>
    </MetricsWrapper>
  );
};

export default MozMetrics;
