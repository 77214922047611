// @ts-nocheck
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { HaroFilterType, HaroOpportunityType } from '@ts/haro.types';

import { getFilterDetailsApi } from '@api/haro.api';
import Input from '@uikit/Input/Input';
import SlateEditor from '@uikit/RichEditor/SlateEditor';
import { SVGIcon } from '@uikit/Icon/Icon';
import Button from '@uikit/Button/Button';
import Loader from '@uikit/Loader/Loader';

import { createAndPitchInquiry } from '@redux/thunks/inquries.thunks';
import { addNotification } from '@redux/actions/notifications.actions';

import loadingStatuses from '@constants/loadingStatuses';
import urls from '@constants/urls';
import stripHtml from '@utils/stripHtml';

import {
  getHiWithFooterDescendant,
  getOnlyHiDescendant,
} from '@components/Inquiries/InquiryPitch/_helpers/defaultEmailText';

import './InquiryPitchEmailEditor.scss';
import {MailboxAccountSettingsType, MailboxAccountType} from '@ts/mailboxAccounts.types';
import MetaInfoValue from '@components/Inbox/InboxWidget/_components/MetaInfo/_components/MetaInfoValue';
import Display from '@components/Display/Display';
import ReactTooltip from 'react-tooltip';
import useMailboxAccounts from '@hooks/useMailboxAccounts';
import {QueryCollectionHookType} from "@ts/common.types";

function InquiryPitchEmailEditor({
  inquiry,
  filterId,
  workspaceId,
  mailboxAccountsData,
}: {
  inquiry: HaroOpportunityType;
  filterId?: number;
  workspaceId: number;
  mailboxAccountsData: MailboxAccountSettingsType[];
}): JSX.Element {
  const dispatch = useDispatch();
  const subjectInputRef = useRef<HTMLInputElement>();
  const [subject, changeSubject] = useState('');
  const [template, changeTemplate] = useState('');
  const [filterDetails, changeFilterDetails] = useState<HaroFilterType | null>(null);
  const [emailFrom, changeEmailFrom] = useState('');
  const [sendingStatus, changeSendingStatus] = useState(loadingStatuses.PENDING);
  const { items: mbAccounts }: QueryCollectionHookType<MailboxAccountType> = useMailboxAccounts();
  const [query, setQuery] = useState<string>('');

  useEffect(() => {
    if (workspaceId && filterId) {
      getFilterDetailsApi(workspaceId, filterId).then((filter) => {
        changeFilterDetails(filter);
      });
    }
  }, [filterId, workspaceId]);

  useEffect(() => {
    if (filterId) {
      const emailFromFilter = filterDetails
        ? mbAccounts?.find(({ id }) => id === filterDetails.mailboxAccountId)?.email
        : '';

      changeEmailFrom(emailFromFilter);
    } else {
      const emailFromFilter = mbAccounts?.length ? mbAccounts[0].email : '';

      changeEmailFrom(emailFromFilter);
    }
  }, [mbAccounts, filterDetails]);

  useEffect(() => {
    changeSubject(inquiry.summary);
  }, [inquiry]);

  const isFilterRef = useRef(!Number.isNaN(filterId));

  const defaultTextInEditor = useMemo(
    () =>
      isFilterRef.current
        ? getHiWithFooterDescendant(inquiry.authorName, filterDetails)
        : getOnlyHiDescendant(inquiry.authorName),
    [filterDetails]
  );

  const handleSubjectClick = () => {
    subjectInputRef.current.focus();
  };

  const handleSendEmail = () => {
    changeSendingStatus(loadingStatuses.INITED);
    const mbAccountFrom = mbAccounts?.find(({ email }) => email === emailFrom);
    if (!mbAccountFrom) {
      return dispatch(
        addNotification({ title: 'You have no email account to send mail from', type: 'warning' })
      );
    }

    return (
      dispatch(
        createAndPitchInquiry(
          inquiry.id,
          workspaceId,
          mbAccountFrom.id,
          inquiry.authorEmail,
          subject,
          template
        )
      )
        // @ts-ignore
        .then(() => {})
        .finally(() => {
          changeSendingStatus(loadingStatuses.LOADED);
        })
    );
  };

  const renderSendButton = (): JSX.Element => {
    const isDisabled = !subject || stripHtml(template).trim().length < 50;

    const buttonProps = {
      size: 's' as const,
      onClick: handleSendEmail,
      disabled: isDisabled,
      title: '50 characters at least',
    };

    if (sendingStatus === loadingStatuses.PENDING) {
      return (
        <Button {...buttonProps}>
          <SVGIcon icon="play" />
          &nbsp; Send
        </Button>
      );
    }

    if (sendingStatus === loadingStatuses.INITED) {
      return (
        <Button {...buttonProps} disabled>
          <SVGIcon icon="play" />
          &nbsp; Sending
        </Button>
      );
    }

    return (
      <Button {...buttonProps}>
        <SVGIcon icon="play" />
        &nbsp; Sent
      </Button>
    );
  };

  return (
    <div className="inquiry-pitch-email-editor">
      <div className="inquiry-pitch-email-editor__meta">
        <Loader isLoading={filterId && !filterDetails} withTopMargin>
          <div className="inquiry-pitch-email-editor__meta-row">
            From:
            {emailFrom ? (
              <>
                <span data-for="editor-sender-meta-chevron" data-tip="" data-event="click focus">
                  <MetaInfoValue value={emailFrom} />
                </span>
                <Display isVisible={mailboxAccountsData.length > 1}>
                  <ReactTooltip
                    id="editor-sender-meta-chevron"
                    className="react-tooltip"
                    place="bottom"
                    effect="float"
                    event="click"
                    globalEventOff="click"
                    arrowColor="transparent"
                    clickable
                  >
                    <div className="inquiry-pitch-email-editor__meta-row__details">
                      <Input
                        className="inquiry-pitch-email-editor__meta-row__details__contacts-search"
                        placeholder="Search"
                        icon="search"
                        value={query}
                        onChange={({ target: { value } }) => setQuery(value)}
                        isTransparent
                      />
                      {mailboxAccountsData
                        ?.slice()
                        ?.filter((item) => item.email.includes(query))
                        ?.map((email) => (
                          <div
                            key={email.id}
                            onClick={() => changeEmailFrom(email.email)}
                            className="inquiry-pitch-email-editor__meta-row__details__contact"
                            tabIndex={0}
                            role="button"
                          >
                            {email.email}
                          </div>
                        ))}
                    </div>
                  </ReactTooltip>
                </Display>
              </>
            ) : (
              <Link className="inquiry-pitch-email-editor__meta-value" to={urls.ALL_EMAILS}>
                Add email
              </Link>
            )}
          </div>
          <div className="inquiry-pitch-email-editor__meta-row">
            To:
            <div className="inquiry-pitch-email-editor__meta-value">{inquiry.authorName}</div>
          </div>
        </Loader>
      </div>
      <div
        className="inquiry-pitch-email-editor__subject-row"
        onClick={handleSubjectClick}
        tabIndex={0}
        role="button"
      >
        <Input
          className="inquiry-pitch-email-editor__subject-input"
          placeholder="Subject:"
          value={subject}
          onChange={({ target: { value } }) => changeSubject(value)}
          inputRef={subjectInputRef}
          isTransparent
        />
      </div>
      <div className="inquiry-pitch-email-editor__template">
        <SlateEditor
          onChange={(value) => changeTemplate(value)}
          message={template}
          renderAction={renderSendButton}
          disableVariable
          withAttachmentsAtAll={false}
          placeholder="Type your answer (at least 50 characters)"
          defaultValue={defaultTextInEditor}
        />
      </div>
    </div>
  );
}

export default InquiryPitchEmailEditor;
