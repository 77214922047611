import React, { useRef, useState } from 'react';
import cn from 'class-names';

import intToRgb from '@utils/intToRgb';
import getStringHashCode from '@utils/getStringHashCode';
import hexToRgba from '@utils/hexToRgba';

// @ts-ignore
import defaultAvatar from './assets/defaultAvatar.jpeg';

import './Avatar.scss';

import { SVGIcon, SvgIconType } from '@uikit/Icon/Icon';

type AvatarPropsType = {
  size?: 'xxs' | 'xs' | 's' | 'sm' | 'sl' | 'm' | 'xm' | 'l' | 'xl';
  url?: string;
  className?: string;
  wrapperClassName?: string;
  onClick?: () => void;
  fullName?: string;
  backgroundCustom?: string;
  defaultIcon?: SvgIconType;
};

type NamedAvatarPropsType = {
  userName: string;
  className?: string;
  size?: string;
  onClick?: () => void;
  backgroundCustom?: string;
  defaultIcon?: SvgIconType;
};

const avatarSizeMap = {
  xxs: '22px',
  xs: '30px',
  s: '36px',
  m: '50px',
  xm: '63px',
  l: '86px',
  xl: '100px',
};

/**
 * if the image is failed to load then put it in this array
 * so the next time it won't attempt to load it again to prevent blinking
 */
const failedSources = [];

export const NamedAvatar = React.memo<NamedAvatarPropsType>(
  ({
    userName,
    backgroundCustom,
    defaultIcon,
    className = '',
    size = 's',
    onClick,
  }: NamedAvatarPropsType): JSX.Element => {
    const cnNamedAvatar = cn('named-avatar', {
      [className]: className.length > 0,
      [`named-avatar--size-${size}`]: size,
    });

    const splittedName = userName?.split(' ') || [''];
    const nameContent =
      splittedName.length > 1 ? `${splittedName[0][0]}${splittedName[1][0]}` : splittedName[0][0];

    const color = `#${intToRgb(getStringHashCode(userName))}`;
    const backgroundColor = backgroundCustom || hexToRgba(color, '0.3');

    const normalizedContent = nameContent != null ? nameContent.replace('undefined', '') : '';

    return (
      <div
        style={{ backgroundColor }}
        className={cnNamedAvatar}
        onClick={onClick || null}
        role="button"
        tabIndex={0}
      >
        <div className="named-avatar__content" style={{ color }}>
          {nameContent === 'undefinedundefined' ? '-' : normalizedContent.toUpperCase()}
          <SVGIcon icon={defaultIcon} />
        </div>
      </div>
    );
  }
);

const Avatar = React.memo<AvatarPropsType>(
  ({
    size = 's',
    url,
    className = '',
    onClick,
    wrapperClassName = '',
    fullName = '',
    backgroundCustom = '',
    defaultIcon,
  }: AvatarPropsType): JSX.Element => {
    const [error, setError] = useState(false);

    const imgRef = useRef();

    const handleImgLoadingError = () => {
      failedSources.push(url);
      // @ts-ignore
      imgRef.current.src = defaultAvatar;
      setError(true);
    };

    const cnAvatar = cn('user-avatar', {
      [`user-avatar--size-${size}`]: size,
      [className]: className.length > 0,
    });

    const cnAvatarWrapper = cn('user-avatar-wrapper', {
      [`user-avatar-wrapper--size-${size}`]: size,
      [wrapperClassName]: wrapperClassName.length > 0,
    });

    if (!url || failedSources.includes(url) || error) {
      return (
        <NamedAvatar
          userName={fullName}
          className={className || wrapperClassName}
          onClick={onClick}
          size={size}
          backgroundCustom={backgroundCustom}
          defaultIcon={defaultIcon}
        />
      );
    }

    return (
      <div className={cnAvatarWrapper} onClick={onClick || null} tabIndex={0} role="button">
        <img
          ref={imgRef}
          width={avatarSizeMap[size]}
          height={avatarSizeMap[size]}
          src={url}
          alt=""
          className={cnAvatar}
          onError={handleImgLoadingError}
        />
      </div>
    );
  }
);

export default Avatar;
