import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb';
import { FolderType, NoteType, TagsType, TagType } from '@ts/common.types';
import { WebsiteType } from '@ts/company.types';

import streamRequest from '@api/base/streamRequest';
import request from '@api/base/request';
import peopleCompanyService from '@api/services/peopleCompanyService';
import * as common_pb from 'respona_api/generated/common_pb';
import PeopleCompanyModels, {
  PeopleWebsiteFilterField,
  PeopleWebsiteMetricsRequest,
  PeopleWebsiteMetricsResponse,
  PeopleWebsiteMetricsViewSettingsResponse,
  PeopleWebsiteShowMetricsRequest,
} from 'respona_api/generated/people-website_pb';
import CommonModels, {
  ContentMetricsTypeMap,
  EntityRelationRequest,
  FileResponse,
  FilterOperation,
  IdRequest,
} from 'respona_api/generated/common_pb';
import {
  PeopleOpportunityType,
  RelationshipPipelineOpportunityType,
  RelationshipStatusType,
} from '@ts/people.types';

export const getWebsiteByIdApi = (websiteId: number, workspaceId: number): Promise<WebsiteType> => {
  const client = peopleCompanyService.getInstance();
  const removeRequest = new CommonModels.IdRequest();

  removeRequest.setId(websiteId);
  removeRequest.setOptionalWorkspaceId(workspaceId);

  return request<WebsiteType>(client, client.getWebsiteById, removeRequest);
};

export const getAllWebsitesApi = (
  page = 0,
  limit = 10,
  optionalFolder = 0,
  optionalSearchQuery = '',
  optionalFilters = []
  // additionalColumns: { id: number; name: string }[] = []
): Promise<WebsiteType[]> => {
  const client = peopleCompanyService.getInstance();
  const fetchRequest = new PeopleCompanyModels.PeopleWebsiteFilterRequest();
  fetchRequest.setPage(page);
  fetchRequest.setLimit(limit);
  fetchRequest.setOptionalFolderId(optionalFolder);

  if (!!optionalSearchQuery && optionalSearchQuery?.length > 0) {
    fetchRequest.setOptionalSearchQuery(optionalSearchQuery);
  }

  const filtersRequest = optionalFilters
    .filter((item) => {
      if (typeof item.value === 'string') {
        return item.value.trim().length > 0;
      }
      if (
        item.value != null &&
        typeof item.value === 'object' &&
        Object.prototype.hasOwnProperty.call(item.value, 'value')
      ) {
        const { value } = item.value;
        if (typeof value === 'string') {
          return value.trim().length > 0;
        }
        if (typeof value === 'number') {
          return value > 0;
        }
        if (!Number.isNaN(Number(value))) {
          return Number(value) > 0;
        }
      }
      return false;
    })
    .map((item) => {
      if (
        item.field === PeopleWebsiteFilterField.DROPDOWN_WEBSITE_OWNER_ID ||
        item.field === PeopleWebsiteFilterField.DROPDOWN_WEBSITE_WORKSPACE_ID ||
        item.field === PeopleWebsiteFilterField.DROPDOWN_PERSON_CAMPAIGN_ID
      ) {
        return {
          ...item, // @ts-ignore
          value: item.value.value?.toString(),
          operation:
            item.operation === FilterOperation.NUMBER_EQUAL
              ? FilterOperation.STRING_EQUAL
              : FilterOperation.STRING_EQUAL_NOT,
        };
      }

      return item;
    })
    .map((item) => {
      const { order, field, value, operation, join } = item;
      const filter = new PeopleCompanyModels.PeopleWebsiteFilter();

      filter.setOrder(order);
      filter.setField(field);

      // @ts-ignore
      if (value?.value !== undefined) {
        filter.setValue(
          // @ts-ignore
          value?.value.toString // @ts-ignore
            ? value?.value.toString() // @ts-ignore
            : value?.value
        );
      } else if (value.toString) {
        filter.setValue(value.toString());
      } else {
        filter.setValue(value);
      }

      filter.setOperation(operation);
      filter.setJoin(join);

      return filter;
    });

  if (filtersRequest.length > 0) {
    fetchRequest.setOptionalFiltersList(filtersRequest);
  }

  return streamRequest<WebsiteType[]>(client, client.getAllWebsites, fetchRequest);
};

export const createOrUpdateCompanyApi = ({
  id,
  title,
  domain,
  workspaceId,
  domainAuthority,
}: {
  id: number;
  title: string;
  domain: string;
  workspaceId: number;
  domainAuthority?: number;
}): Promise<WebsiteType> => {
  const client = peopleCompanyService.getInstance();
  const createRequest = new PeopleCompanyModels.PeopleWebsiteCrudRequest();

  createRequest.setWorkspaceId(workspaceId);

  if (id) {
    createRequest.setId(id);
  }

  if (title) {
    createRequest.setTitle(title);
  }

  if (domain) {
    createRequest.setDomain(domain);
  }

  if (domainAuthority) {
    createRequest.setDomainAuthority(domainAuthority);
  }

  const method = id > 0 ? client.updateWebsite : client.createWebsite;

  return request<WebsiteType>(client, method, createRequest);
};

export const renameWebsiteApi = (websiteId: number, workspaceId: number, title: string) => {
  const client = peopleCompanyService.getInstance();
  const websiteRequest = new PeopleCompanyModels.PeopleWebsiteRenameRequest();

  websiteRequest.setId(websiteId);
  websiteRequest.setWorkspaceId(workspaceId);
  websiteRequest.setTitle(title);

  return request(client, client.renameWebsite, websiteRequest);
};

export const removeCompanyApi = (companyId: number, workspaceId: number) => {
  const client = peopleCompanyService.getInstance();
  const removeRequest = new CommonModels.IdRequest();

  removeRequest.setId(companyId);
  removeRequest.setOptionalWorkspaceId(workspaceId);

  return request(client, client.deleteWebsite, removeRequest);
};

export const removeCompanyBatchApi = (
  companyIds: number[],
  type: EntityRelationRequest.SelectionTypeMap[keyof EntityRelationRequest.SelectionTypeMap]
) => {
  const client = peopleCompanyService.getInstance();
  const removeRequest = new common_pb.EntityRelationRequest();

  if (companyIds != null && companyIds.length > 0) {
    removeRequest.setRelatedIdsList(companyIds);
  }
  removeRequest.setType(type);

  return request(client, client.batchDeleteWebsite, removeRequest);
};

export const getAllCompaniesFoldersApi = (): Promise<FolderType[]> => {
  const client = peopleCompanyService.getInstance();
  const emptyRequest = new google_protobuf_empty_pb.Empty();

  return streamRequest<FolderType[]>(client, client.getAllFolders, emptyRequest);
};

export const createCompanyFolderApi = (folderTitle: string): Promise<FolderType> => {
  const client = peopleCompanyService.getInstance();
  const folderRequest = new CommonModels.Folder();

  folderRequest.setTitle(folderTitle);

  return request<FolderType>(client, client.createFolder, folderRequest);
};

export const moveCompanyToFolderApi = (
  folderId: number,
  companiesIds: number[],
  type: EntityRelationRequest.SelectionTypeMap[keyof EntityRelationRequest.SelectionTypeMap]
) => {
  const client = peopleCompanyService.getInstance();
  const moveRequest = new CommonModels.EntityRelationRequest();

  moveRequest.setId(folderId);
  moveRequest.setRelatedIdsList(companiesIds);
  moveRequest.setType(type);

  return request(client, client.addWebsitesToFolder, moveRequest);
};

export const removeCompanyFromFolderApi = (
  folderId: number,
  companiesIds: number[],
  type: EntityRelationRequest.SelectionTypeMap[keyof EntityRelationRequest.SelectionTypeMap]
): Promise<void> => {
  const client = peopleCompanyService.getInstance();
  const removeRequest = new CommonModels.EntityRelationRequest();

  removeRequest.setId(folderId);
  removeRequest.setRelatedIdsList(companiesIds);
  removeRequest.setType(type);

  return request(client, client.removeWebsitesFromFolder, removeRequest);
};

export const removeCompanyFolderApi = (folderId: number) => {
  const client = peopleCompanyService.getInstance();
  const removeRequest = new CommonModels.IdRequest();

  removeRequest.setId(folderId);

  return request(client, client.deleteFolder, removeRequest);
};

export const updateCompanyFolderApi = (
  folderId: number,
  folderTitle: string
): Promise<FolderType> => {
  const client = peopleCompanyService.getInstance();
  const folderRequest = new CommonModels.Folder();

  folderRequest.setId(folderId);
  folderRequest.setTitle(folderTitle);

  return request<FolderType>(client, client.updateFolder, folderRequest);
};

export const getCompanyByPersonIdApi = (
  personId: number
): Promise<{ exist: boolean; company: WebsiteType }> => {
  const client = peopleCompanyService.getInstance();
  const idRequest = new CommonModels.IdRequest();

  idRequest.setId(personId);

  return request<{ exist: boolean; company: WebsiteType }>(
    client,
    client.getWebsiteByPersonId,
    idRequest
  );
};

export const addCompanyNoteApi = (content: string, websiteId: number): Promise<NoteType> => {
  const client = peopleCompanyService.getInstance();
  const noteRequest = new CommonModels.Note();

  noteRequest.setContent(content);
  noteRequest.setRelatedId(websiteId);
  noteRequest.setCreatedAt(Date.now());

  return request<NoteType>(client, client.addNote, noteRequest);
};

export const updateCompanyNoteApi = (
  noteId: number,
  newContent: string,
  websiteId: number
): Promise<NoteType> => {
  const client = peopleCompanyService.getInstance();
  const noteRequest = new CommonModels.Note();

  noteRequest.setId(noteId);
  noteRequest.setContent(newContent);
  noteRequest.setRelatedId(websiteId);

  return request<NoteType>(client, client.updateNote, noteRequest);
};

export const removeCompanyNoteApi = (noteId: number, websiteId: number): Promise<void> => {
  const client = peopleCompanyService.getInstance();
  const idRequest = new CommonModels.IdRequest();

  idRequest.setId(noteId);
  idRequest.setOptionalRelatedId(websiteId);

  return request<void>(client, client.removeNote, idRequest);
};

export const fetchPeopleCompanyNotesApi = (companyId: number): Promise<NoteType[]> => {
  const client = peopleCompanyService.getInstance();
  const idRequest = new CommonModels.IdRequest();

  idRequest.setId(companyId);

  return streamRequest<NoteType[]>(client, client.getAllNotes, idRequest);
};

export const exportCompanyToCSVApi = (
  companiesIds: number[],
  folderId?: number,
  searchString?: string
): Promise<{ complete: boolean; file: FileResponse.AsObject }[]> => {
  const client = peopleCompanyService.getInstance();
  const exportPeopleCompanyRequest = new PeopleCompanyModels.ExportPeopleWebsiteRequest();

  exportPeopleCompanyRequest.setType(companiesIds.length > 0 ? 2 : 1);
  if (folderId) {
    exportPeopleCompanyRequest.setOptionalId(folderId);
  }
  if (searchString) {
    exportPeopleCompanyRequest.setOptionalSearchQuery(searchString);
  }
  exportPeopleCompanyRequest.setOptionalSelectedList(companiesIds);

  return streamRequest(client, client.exportWebsitesToCsv, exportPeopleCompanyRequest);
};

export const getAllCompanyTagsApi = (): Promise<TagType[]> => {
  const client = peopleCompanyService.getInstance();
  const emptyRequest = new google_protobuf_empty_pb.Empty();

  return streamRequest<TagType[]>(client, client.getAllTags, emptyRequest);
};

export const getTagsByCompanyIdApi = (id: number, workspaceId?: number): Promise<TagsType> => {
  const client = peopleCompanyService.getInstance();
  const opportunitiesRequest = new IdRequest();

  opportunitiesRequest.setId(id);
  opportunitiesRequest.setOptionalWorkspaceId(workspaceId);
  opportunitiesRequest.setOptionalRelatedId(id);

  return request<TagsType>(client, client.getTagsByWebsiteId, opportunitiesRequest);
};

export const addCompanyTagApi = (
  tagId: number,
  tagTitle: string,
  personId: number
): Promise<TagType> => {
  const client = peopleCompanyService.getInstance();
  const tagRequest = new CommonModels.Tag();

  if (tagId) {
    tagRequest.setId(tagId);
  }
  tagRequest.setRelatedId(personId);
  tagRequest.setTitle(tagTitle);

  return request<TagType>(client, client.addTag, tagRequest);
};

export const removeCompanyTagApi = (tagId: number, personId: number) => {
  const client = peopleCompanyService.getInstance();
  const removeRequest = new CommonModels.IdRequest();

  removeRequest.setId(tagId);
  removeRequest.setOptionalRelatedId(personId);

  return request(client, client.removeTag, removeRequest);
};

// Metrics

export const getAllMetricsByCompanyIdApi = (companyId: number, workspaceId: number) => {
  const client = peopleCompanyService.getInstance();
  const metricsRequest = new IdRequest();

  metricsRequest.setId(companyId);
  metricsRequest.setOptionalWorkspaceId(workspaceId);

  return streamRequest<PeopleWebsiteMetricsResponse.AsObject[]>(
    client,
    client.getAllMetricsByWebsiteId,
    metricsRequest
  );
};

export const getAllMetricsViewSettingsByCompanyIdApi = (companyId: number, workspaceId: number) => {
  const client = peopleCompanyService.getInstance();
  const metricsRequest = new IdRequest();

  metricsRequest.setId(companyId);
  metricsRequest.setOptionalWorkspaceId(workspaceId);

  return request<PeopleWebsiteMetricsViewSettingsResponse.AsObject>(
    client,
    client.getAllMetricsViewSettingsByWebsiteId,
    metricsRequest
  );
};

export const showMetricsByTypeApi = (
  type: ContentMetricsTypeMap[keyof ContentMetricsTypeMap],
  companyId: number,
  workspaceId: number,
  enabled: boolean
) => {
  const client = peopleCompanyService.getInstance();
  const metricsRequest = new PeopleWebsiteShowMetricsRequest();

  metricsRequest.setType(type);
  metricsRequest.setWebsiteId(companyId);
  metricsRequest.setWorkspaceId(workspaceId);
  metricsRequest.setEnabled(enabled);

  return request<PeopleWebsiteMetricsResponse.AsObject>(
    client,
    client.showMetricsByType,
    metricsRequest
  );
};

export const loadMetricsValueByIdApi = (
  id: number,
  type: ContentMetricsTypeMap[keyof ContentMetricsTypeMap],
  websiteId: number,
  workspaceId: number
) => {
  const client = peopleCompanyService.getInstance();
  const metricsRequest = new PeopleWebsiteMetricsRequest();

  metricsRequest.setId(id);
  metricsRequest.setWebsiteId(websiteId);
  metricsRequest.setWorkspaceId(workspaceId);
  metricsRequest.setType(type);

  return request<PeopleWebsiteMetricsResponse.AsObject>(
    client,
    client.loadMetricsValueById,
    metricsRequest
  );
};

export const setMetricsValueByIdApi = (data: Partial<PeopleWebsiteMetricsRequest.AsObject>) => {
  const client = peopleCompanyService.getInstance();
  const metricsRequest = new PeopleWebsiteMetricsRequest();

  metricsRequest.setId(data.id);
  metricsRequest.setType(data.type);
  metricsRequest.setWebsiteId(data.websiteId);
  metricsRequest.setRating(data.rating);
  metricsRequest.setTraffic(data.traffic);
  metricsRequest.setSpamScore(data.spamScore);
  metricsRequest.setWorkspaceId(data.workspaceId);

  return request<void>(client, client.setMetricsValueById, metricsRequest);
};

export const deleteMetricsValueByIdApi = (id: number, workspaceId: number) => {
  const client = peopleCompanyService.getInstance();
  const metricsRequest = new IdRequest();

  metricsRequest.setId(id);
  metricsRequest.setOptionalWorkspaceId(workspaceId);

  return request<void>(client, client.deleteMetricsValueById, metricsRequest);
};

export const getCampaignOpportunitiesByWebsiteIdApi = (
  websiteId: number,
  workspaceId: number
): Promise<PeopleOpportunityType[]> => {
  const client = peopleCompanyService.getInstance();
  const metricsRequest = new IdRequest();

  metricsRequest.setId(websiteId);
  metricsRequest.setOptionalWorkspaceId(workspaceId);

  return streamRequest<PeopleOpportunityType[]>(
    client,
    client.getCampaignOpportunitiesByWebsiteId,
    metricsRequest
  );
};

export const updateWebsiteStatus = ({
  id,
  status,
}: {
  id: number;
  status: RelationshipStatusType;
}): Promise<void> => {
  const client = peopleCompanyService.getInstance();
  const createRequest = new PeopleCompanyModels.WebsiteRelationshipStatusRequest();

  createRequest.setWebsiteId(id);
  createRequest.setStatus(status);

  return request<void>(client, client.updateRelationshipStatus, createRequest);
};

export const getPipelineOpportunitiesByWebsiteIdApi = (
  id: number,
  workspaceId: number
): Promise<RelationshipPipelineOpportunityType[]> => {
  const client = peopleCompanyService.getInstance();
  const opportunitiesRequest = new IdRequest();

  opportunitiesRequest.setId(id);
  opportunitiesRequest.setOptionalWorkspaceId(workspaceId);

  return streamRequest<RelationshipPipelineOpportunityType[]>(
    client,
    client.getPipelineOpportunitiesByWebsiteId,
    opportunitiesRequest
  );
};
