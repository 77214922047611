import React from 'react';

export default ({ color = '#C4C6CD' }) => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.5981 10.4877L14.1339 14.0227L12.9547 15.2018L11.4314 13.6785V18.3327H9.76472V13.6768L8.24139 15.2018L7.06222 14.0227L10.5981 10.4877ZM10.5981 1.66602C12.0289 1.66608 13.4098 2.19194 14.4782 3.14361C15.5467 4.09528 16.2281 5.4064 16.3931 6.82768C17.4299 7.11045 18.3345 7.7486 18.9486 8.63062C19.5627 9.51264 19.8473 10.5824 19.7527 11.653C19.6581 12.7236 19.1903 13.7269 18.431 14.4875C17.6717 15.2482 16.6693 15.7178 15.5989 15.8144V14.136C15.9824 14.0813 16.3511 13.9506 16.6835 13.7518C17.016 13.553 17.3055 13.29 17.5352 12.978C17.7649 12.6661 17.9301 12.3115 18.0213 11.9351C18.1124 11.5586 18.1277 11.1677 18.0662 10.7852C18.0046 10.4028 17.8675 10.0364 17.6628 9.70756C17.4581 9.37868 17.19 9.09386 16.8741 8.86974C16.5581 8.64562 16.2007 8.48667 15.8226 8.40219C15.4446 8.31771 15.0535 8.30938 14.6722 8.37768C14.8027 7.77009 14.7957 7.14099 14.6516 6.53648C14.5075 5.93196 14.23 5.36734 13.8394 4.88396C13.4488 4.40058 12.9551 4.01069 12.3943 3.74284C11.8335 3.475 11.2199 3.33599 10.5985 3.33599C9.97702 3.33599 9.36343 3.475 8.80266 3.74284C8.24189 4.01069 7.74814 4.40058 7.35756 4.88396C6.96698 5.36734 6.68948 5.93196 6.54537 6.53648C6.40127 7.14099 6.39421 7.77009 6.52472 8.37768C5.76444 8.23491 4.97857 8.4 4.34001 8.83665C3.70145 9.2733 3.2625 9.94573 3.11972 10.706C2.97695 11.4663 3.14204 12.2522 3.57869 12.8907C4.01534 13.5293 4.68777 13.9682 5.44806 14.111L5.59806 14.136V15.8144C4.5276 15.718 3.52506 15.2484 2.76567 14.4878C2.00627 13.7273 1.53833 12.724 1.44362 11.6534C1.34891 10.5828 1.63346 9.51292 2.24753 8.63083C2.8616 7.74874 3.76615 7.1105 4.80306 6.82768C4.96781 5.40633 5.64923 4.09511 6.71771 3.1434C7.78618 2.1917 9.16719 1.6659 10.5981 1.66602Z"
      fill={color}
    />
  </svg>
);
