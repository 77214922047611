import React, { useRef } from 'react';
import { nanoid } from 'nanoid';

import { SVGIcon } from '@uikit/Icon/Icon';
import Button from '@uikit/Button/Button';
import ReactTooltip from 'react-tooltip';

import { GREY_COLOR } from '@constants/colors';
import useOutsideClick from '@hooks/useOutsideClick';

import './HeaderFiltersButtonWithTooltip.scss';

type HeaderFiltersButtonWithTooltipType = {
  areFiltersEnabled: boolean;
  onClearAllFilters: () => void;
  tooltipOffset?: number;
  children?: React.ReactNode;
  onHide?: () => void;
  isFilterBtnText?: boolean;
};

export const HeaderFiltersButtonWithTooltipContext = React.createContext({} as any);

const HeaderFiltersButtonWithTooltip = ({
  areFiltersEnabled,
  onClearAllFilters,
  tooltipOffset = 250,
  children,
  onHide,
  isFilterBtnText = true,
}: HeaderFiltersButtonWithTooltipType): JSX.Element => {
  const tooltipButtonRef = useRef();
  const tooltipWrapperRef = useRef();
  const id = nanoid();

  const handleOpenTooltip = (e) => {
    e.stopPropagation();
    ReactTooltip.show(tooltipButtonRef.current);
  };

  const closeTooltip = () => {
    ReactTooltip.hide(tooltipButtonRef.current);
  };

  useOutsideClick(tooltipWrapperRef, () => ReactTooltip.hide(tooltipButtonRef.current));

  return (
    <div className="header-filters" ref={tooltipWrapperRef}>
      <Button
        type="ghost"
        size="l"
        onClick={handleOpenTooltip}
        className="header-filters__tooltip-button"
        data-for={`header-filters-tooltip-id${id}`}
        data-tip=""
        innerRef={tooltipButtonRef}
      >
        <SVGIcon icon="filter" color={GREY_COLOR} />
        {isFilterBtnText ? 'Filters' : ''}
      </Button>

      {/* When filters are enabled we show red x to clear them */}
      {areFiltersEnabled ? (
        <div
          className="header-filters__throw-filters-button"
          onClick={onClearAllFilters}
          role="button"
          tabIndex={0}
        >
          <SVGIcon icon="crossDelete" />
        </div>
      ) : null}

      <ReactTooltip
        id={`header-filters-tooltip-id${id}`}
        className="react-tooltip header-filters__content-wrapper"
        place="bottom"
        effect="solid"
        event="none"
        eventOff="none"
        globalEventOff="none"
        clickable
        offset={{ left: tooltipOffset }}
        afterHide={onHide}
        arrowColor="transparent"
      >
        <HeaderFiltersButtonWithTooltipContext.Provider value={{ cb: closeTooltip }}>
          {children}
        </HeaderFiltersButtonWithTooltipContext.Provider>
      </ReactTooltip>
    </div>
  );
};

export default HeaderFiltersButtonWithTooltip;
