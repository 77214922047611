import React, { useEffect, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';

import { SVGIcon } from '@uikit/Icon/Icon';
import DateRangePickerWrapper from '@uikit/DateRangePicker/DateRangePicker';
import {
  transformDateToString,
  dateRangeNumberFormatToString,
} from '@helpers/dateTimeRangeHelpers';
import { DateRangeDateType, DateRangeNumberType } from '@ts/common.types';

import './HeaderDateFilter.scss';
import useOutsideClick from '@hooks/useOutsideClick';

const HeaderDateFilter = ({
  value,
  onChangeDatarange,
  additionalClassname,
  isRangePicker = true,
  id = '',
  skipDayCheck = false,
}: {
  value: DateRangeNumberType;
  onChangeDatarange: (selection: DateRangeDateType) => void;
  additionalClassname?: string;
  isRangePicker?: boolean;
  id?: string;
  skipDayCheck?: boolean;
}): JSX.Element => {
  const [dateRange, changeDateRange] = useState(dateRangeNumberFormatToString(value));
  const tooltipButtonRef = useRef();
  const tooltipWrapperRef = useRef();

  // TODO: it breaks "filter" tooltip on LeaderBoards and CampaignPerformance pages
  // useOutsideClick(tooltipWrapperRef, () => ReactTooltip.hide(tooltipButtonRef.current));

  const handleSelectDaterange = ({ selection }: { selection: DateRangeDateType }) => {
    const startDate = transformDateToString(selection.startDate);
    const endDate = transformDateToString(selection.endDate);

    if (isRangePicker) {
      changeDateRange(`${startDate} - ${endDate}`);
    } else {
      changeDateRange(startDate);
    }
    onChangeDatarange(selection);
  };

  useEffect(() => {
    if (dateRange === null && !!value.startDate) {
      changeDateRange(dateRangeNumberFormatToString(value));
    }
  }, [value, dateRange]);

  return (
    <div ref={tooltipWrapperRef}>
      <div
        className={`header-date-filter ${additionalClassname}`}
        data-for={`header-date-filter-tooltip--${id}`}
        data-tip=""
        data-event="click"
        role="button"
        tabIndex={0}
      >
        {dateRange}
        <SVGIcon icon="chevron" color="hsl(0, 0%, 80%)" />
      </div>
      <ReactTooltip
        className="react-tooltip react-tooltip--header-date-filter"
        id={`header-date-filter-tooltip--${id}`}
        place="bottom"
        effect="solid"
        event="off"
        globalEventOff="click"
        offset={{ left: 100 }}
        arrowColor="transparent"
        clickable
      >
        <DateRangePickerWrapper
          value={value}
          isRangePicker={isRangePicker}
          handleSelect={handleSelectDaterange}
          skipDayCheck={skipDayCheck}
        />
      </ReactTooltip>
    </div>
  );
};

export default HeaderDateFilter;
