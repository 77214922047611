import React from 'react';

const VerifyPlayIcon = ({ color = '#3424EB' }: { color: string }): JSX.Element => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.9052 4.75264L4.66016 0.597644C4.26812 0.371264 3.82316 0.252673 3.37045 0.253916C2.91775 0.255159 2.47344 0.376193 2.08266 0.604723C1.69187 0.833254 1.36853 1.16114 1.14547 1.55508C0.922413 1.94901 0.807589 2.39497 0.812661 2.84764V11.1876C0.812661 11.8679 1.0829 12.5203 1.56393 13.0014C2.04496 13.4824 2.69738 13.7526 3.37766 13.7526C3.82799 13.7519 4.27023 13.6329 4.66016 13.4076L11.9052 9.25264C12.2945 9.02734 12.6177 8.70362 12.8423 8.31397C13.067 7.92432 13.1853 7.48244 13.1853 7.03264C13.1853 6.58285 13.067 6.14097 12.8423 5.75132C12.6177 5.36167 12.2945 5.03795 11.9052 4.81264V4.75264ZM11.1552 7.89514L3.91016 12.1101C3.74778 12.2022 3.56431 12.2505 3.37766 12.2505C3.19101 12.2505 3.00755 12.2022 2.84516 12.1101C2.68323 12.0167 2.54877 11.8822 2.4553 11.7202C2.36182 11.5583 2.31263 11.3746 2.31266 11.1876V2.81764C2.31263 2.63067 2.36182 2.44698 2.4553 2.28504C2.54877 2.1231 2.68323 1.98863 2.84516 1.89514C3.00822 1.80451 3.19113 1.75556 3.37766 1.75264C3.56406 1.75647 3.74676 1.80536 3.91016 1.89514L11.1552 6.08014C11.3171 6.17359 11.4517 6.30805 11.5452 6.46999C11.6387 6.63192 11.688 6.81564 11.688 7.00264C11.688 7.18965 11.6387 7.37336 11.5452 7.5353C11.4517 7.69724 11.3171 7.83169 11.1552 7.92514V7.89514Z"
      fill={color}
    />
  </svg>
);

export default VerifyPlayIcon;
