import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { ActivePagePropsType } from '@ts/internalSidebar.types';

import ConfigurableSidebar from '@uikit/ConfigurableSidebar/ConfigurableSidebar';

import sidebarConfig from '@components/Insights/sidebarConfig';
import EmailHealth from '@components/Insights/EmailHealth/EmailHealth';
import EmailReport from '@components/Insights/EmailReport/EmailReport';
import CampaignReports from '@components/Insights/CampaignReports/CampaignReports';
import LeaderBoards from '@components/Insights/LeaderBoards/LeaderBoards';
import CreditsUsage from '@components/Insights/CreditsUsage/CreditsUsage';

import './InsightsPage.scss';

function InsightsPage({ location: { pathname } }: ActivePagePropsType): JSX.Element {
  return (
    <div className="insights-page">
      <ConfigurableSidebar
        config={sidebarConfig}
        renderProps={{ pathname }}
        SidebarHeader={() => <span>Insights</span>}
      />

      <div className="insights-page__content">
        <Switch>
          <Route path="/insights/campaign-reports">
            <CampaignReports />
          </Route>
          <Route path="/insights/leader-boards">
            <LeaderBoards />
          </Route>
          <Route path="/insights/credits-usage">
            <CreditsUsage />
          </Route>
          <Route path="/insights/campaign-reports">
            <EmailReport />
          </Route>
          <Route path="/insights/email-health">
            <EmailHealth />
          </Route>
          {/* <Route path="/insights/email-warmup"> */}
          {/*  <EmailWarmup /> */}
          {/* </Route> */}
          <Route path="/insights">
            <Redirect to="/insights/campaign-reports" />
          </Route>
          <Route path="/insights/*">
            <Redirect to="/insights/campaign-reports" />
          </Route>
        </Switch>
      </div>
    </div>
  );
}

export default InsightsPage;
