// source: mailbox.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
var mailbox$sender_pb = require('./mailbox-sender_pb.js');
goog.object.extend(proto, mailbox$sender_pb);
goog.exportSymbol('proto.inbox.AddCommentToThreadRequest', null, global);
goog.exportSymbol('proto.inbox.AssignMailsToPersonRequest', null, global);
goog.exportSymbol('proto.inbox.AssignThreadRequest', null, global);
goog.exportSymbol('proto.inbox.AssignThreadToUserRequest', null, global);
goog.exportSymbol('proto.inbox.AttachedFile', null, global);
goog.exportSymbol('proto.inbox.BlackListBatchRequest', null, global);
goog.exportSymbol('proto.inbox.BlackListReasonType', null, global);
goog.exportSymbol('proto.inbox.BlackListRequest', null, global);
goog.exportSymbol('proto.inbox.BlackListResponse', null, global);
goog.exportSymbol('proto.inbox.ChangeThreadStatusRequest', null, global);
goog.exportSymbol('proto.inbox.ChangeViewStateRequest', null, global);
goog.exportSymbol('proto.inbox.EmailInbox', null, global);
goog.exportSymbol('proto.inbox.ExportBlackListPatternRequest', null, global);
goog.exportSymbol('proto.inbox.ExportBlackListPatternRequest.ExportType', null, global);
goog.exportSymbol('proto.inbox.FutureMessage', null, global);
goog.exportSymbol('proto.inbox.GetEmailsRequest', null, global);
goog.exportSymbol('proto.inbox.GetUnsubscribedPatternPageable', null, global);
goog.exportSymbol('proto.inbox.GlobalInboxFilterRequest', null, global);
goog.exportSymbol('proto.inbox.InboxAccountsPreset', null, global);
goog.exportSymbol('proto.inbox.InboxFolders', null, global);
goog.exportSymbol('proto.inbox.InboxThread', null, global);
goog.exportSymbol('proto.inbox.InboxUnreadCounterRequest', null, global);
goog.exportSymbol('proto.inbox.InboxUnreadCounterResponse', null, global);
goog.exportSymbol('proto.inbox.MailAIAction', null, global);
goog.exportSymbol('proto.inbox.MailAIAssistantContainer', null, global);
goog.exportSymbol('proto.inbox.MailAIAssistantContainer.MailAIAssistantItem', null, global);
goog.exportSymbol('proto.inbox.MailAIAssistantRequest', null, global);
goog.exportSymbol('proto.inbox.MailAIAssistantResponse', null, global);
goog.exportSymbol('proto.inbox.MailThreadDraftRequest', null, global);
goog.exportSymbol('proto.inbox.MailboxFolderLabel', null, global);
goog.exportSymbol('proto.inbox.MailboxQueueCancelRequest', null, global);
goog.exportSymbol('proto.inbox.MailboxQueueCancelRequest.MailboxQueueCancelRelatedType', null, global);
goog.exportSymbol('proto.inbox.MessageRequest', null, global);
goog.exportSymbol('proto.inbox.OutboxPreviewResponse', null, global);
goog.exportSymbol('proto.inbox.OutboxThreadMessage', null, global);
goog.exportSymbol('proto.inbox.OutboxThreadMessage.DataCase', null, global);
goog.exportSymbol('proto.inbox.PersonInboxPreviewEmailsRequest', null, global);
goog.exportSymbol('proto.inbox.QueueMessageResponse', null, global);
goog.exportSymbol('proto.inbox.QueueMessageUpdateRequest', null, global);
goog.exportSymbol('proto.inbox.QueuePreviewResponse', null, global);
goog.exportSymbol('proto.inbox.SendEmailRequest', null, global);
goog.exportSymbol('proto.inbox.ThreadComments', null, global);
goog.exportSymbol('proto.inbox.ThreadConversationMessageRequest', null, global);
goog.exportSymbol('proto.inbox.ThreadConversationMessageResponse', null, global);
goog.exportSymbol('proto.inbox.ThreadMessage', null, global);
goog.exportSymbol('proto.inbox.ThreadMessageType', null, global);
goog.exportSymbol('proto.inbox.ThreadPreviewRequest', null, global);
goog.exportSymbol('proto.inbox.ThreadPreviewResponse', null, global);
goog.exportSymbol('proto.inbox.ThreadStatus', null, global);
goog.exportSymbol('proto.inbox.ThreadStatusRequest', null, global);
goog.exportSymbol('proto.inbox.ThreadTag', null, global);
goog.exportSymbol('proto.inbox.ThreadUidRequest', null, global);
goog.exportSymbol('proto.inbox.UnsubscribedFilter', null, global);
goog.exportSymbol('proto.inbox.UnsubscribedFilterField', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.inbox.ThreadTag = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.inbox.ThreadTag, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.inbox.ThreadTag.displayName = 'proto.inbox.ThreadTag';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.inbox.ThreadConversationMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.inbox.ThreadConversationMessageRequest.repeatedFields_, null);
};
goog.inherits(proto.inbox.ThreadConversationMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.inbox.ThreadConversationMessageRequest.displayName = 'proto.inbox.ThreadConversationMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.inbox.ThreadConversationMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.inbox.ThreadConversationMessageResponse.repeatedFields_, null);
};
goog.inherits(proto.inbox.ThreadConversationMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.inbox.ThreadConversationMessageResponse.displayName = 'proto.inbox.ThreadConversationMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.inbox.MailAIAssistantContainer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.inbox.MailAIAssistantContainer.repeatedFields_, null);
};
goog.inherits(proto.inbox.MailAIAssistantContainer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.inbox.MailAIAssistantContainer.displayName = 'proto.inbox.MailAIAssistantContainer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.inbox.MailAIAssistantContainer.MailAIAssistantItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.inbox.MailAIAssistantContainer.MailAIAssistantItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.inbox.MailAIAssistantContainer.MailAIAssistantItem.displayName = 'proto.inbox.MailAIAssistantContainer.MailAIAssistantItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.inbox.MailAIAssistantRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.inbox.MailAIAssistantRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.inbox.MailAIAssistantRequest.displayName = 'proto.inbox.MailAIAssistantRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.inbox.MailThreadDraftRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.inbox.MailThreadDraftRequest.repeatedFields_, null);
};
goog.inherits(proto.inbox.MailThreadDraftRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.inbox.MailThreadDraftRequest.displayName = 'proto.inbox.MailThreadDraftRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.inbox.MailAIAssistantResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.inbox.MailAIAssistantResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.inbox.MailAIAssistantResponse.displayName = 'proto.inbox.MailAIAssistantResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.inbox.InboxUnreadCounterRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.inbox.InboxUnreadCounterRequest.repeatedFields_, null);
};
goog.inherits(proto.inbox.InboxUnreadCounterRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.inbox.InboxUnreadCounterRequest.displayName = 'proto.inbox.InboxUnreadCounterRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.inbox.InboxUnreadCounterResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.inbox.InboxUnreadCounterResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.inbox.InboxUnreadCounterResponse.displayName = 'proto.inbox.InboxUnreadCounterResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.inbox.InboxAccountsPreset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.inbox.InboxAccountsPreset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.inbox.InboxAccountsPreset.displayName = 'proto.inbox.InboxAccountsPreset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.inbox.AssignThreadToUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.inbox.AssignThreadToUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.inbox.AssignThreadToUserRequest.displayName = 'proto.inbox.AssignThreadToUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.inbox.AssignMailsToPersonRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.inbox.AssignMailsToPersonRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.inbox.AssignMailsToPersonRequest.displayName = 'proto.inbox.AssignMailsToPersonRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.inbox.ChangeViewStateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.inbox.ChangeViewStateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.inbox.ChangeViewStateRequest.displayName = 'proto.inbox.ChangeViewStateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.inbox.ChangeThreadStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.inbox.ChangeThreadStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.inbox.ChangeThreadStatusRequest.displayName = 'proto.inbox.ChangeThreadStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.inbox.ThreadPreviewResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.inbox.ThreadPreviewResponse.repeatedFields_, null);
};
goog.inherits(proto.inbox.ThreadPreviewResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.inbox.ThreadPreviewResponse.displayName = 'proto.inbox.ThreadPreviewResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.inbox.OutboxPreviewResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.inbox.OutboxPreviewResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.inbox.OutboxPreviewResponse.displayName = 'proto.inbox.OutboxPreviewResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.inbox.QueuePreviewResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.inbox.QueuePreviewResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.inbox.QueuePreviewResponse.displayName = 'proto.inbox.QueuePreviewResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.inbox.QueueMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.inbox.QueueMessageResponse.repeatedFields_, null);
};
goog.inherits(proto.inbox.QueueMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.inbox.QueueMessageResponse.displayName = 'proto.inbox.QueueMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.inbox.QueueMessageUpdateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.inbox.QueueMessageUpdateRequest.repeatedFields_, null);
};
goog.inherits(proto.inbox.QueueMessageUpdateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.inbox.QueueMessageUpdateRequest.displayName = 'proto.inbox.QueueMessageUpdateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.inbox.GetEmailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.inbox.GetEmailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.inbox.GetEmailsRequest.displayName = 'proto.inbox.GetEmailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.inbox.ThreadMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.inbox.ThreadMessage.repeatedFields_, null);
};
goog.inherits(proto.inbox.ThreadMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.inbox.ThreadMessage.displayName = 'proto.inbox.ThreadMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.inbox.FutureMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.inbox.FutureMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.inbox.FutureMessage.displayName = 'proto.inbox.FutureMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.inbox.OutboxThreadMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.inbox.OutboxThreadMessage.oneofGroups_);
};
goog.inherits(proto.inbox.OutboxThreadMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.inbox.OutboxThreadMessage.displayName = 'proto.inbox.OutboxThreadMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.inbox.ThreadComments = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.inbox.ThreadComments, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.inbox.ThreadComments.displayName = 'proto.inbox.ThreadComments';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.inbox.InboxThread = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.inbox.InboxThread.repeatedFields_, null);
};
goog.inherits(proto.inbox.InboxThread, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.inbox.InboxThread.displayName = 'proto.inbox.InboxThread';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.inbox.EmailInbox = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.inbox.EmailInbox, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.inbox.EmailInbox.displayName = 'proto.inbox.EmailInbox';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.inbox.InboxFolders = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.inbox.InboxFolders, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.inbox.InboxFolders.displayName = 'proto.inbox.InboxFolders';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.inbox.AddCommentToThreadRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.inbox.AddCommentToThreadRequest.repeatedFields_, null);
};
goog.inherits(proto.inbox.AddCommentToThreadRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.inbox.AddCommentToThreadRequest.displayName = 'proto.inbox.AddCommentToThreadRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.inbox.AssignThreadRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.inbox.AssignThreadRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.inbox.AssignThreadRequest.displayName = 'proto.inbox.AssignThreadRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.inbox.AttachedFile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.inbox.AttachedFile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.inbox.AttachedFile.displayName = 'proto.inbox.AttachedFile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.inbox.MessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.inbox.MessageRequest.repeatedFields_, null);
};
goog.inherits(proto.inbox.MessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.inbox.MessageRequest.displayName = 'proto.inbox.MessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.inbox.SendEmailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.inbox.SendEmailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.inbox.SendEmailRequest.displayName = 'proto.inbox.SendEmailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.inbox.BlackListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.inbox.BlackListRequest.repeatedFields_, null);
};
goog.inherits(proto.inbox.BlackListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.inbox.BlackListRequest.displayName = 'proto.inbox.BlackListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.inbox.BlackListBatchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.inbox.BlackListBatchRequest.repeatedFields_, null);
};
goog.inherits(proto.inbox.BlackListBatchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.inbox.BlackListBatchRequest.displayName = 'proto.inbox.BlackListBatchRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.inbox.BlackListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.inbox.BlackListResponse.repeatedFields_, null);
};
goog.inherits(proto.inbox.BlackListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.inbox.BlackListResponse.displayName = 'proto.inbox.BlackListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.inbox.MailboxQueueCancelRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.inbox.MailboxQueueCancelRequest.repeatedFields_, null);
};
goog.inherits(proto.inbox.MailboxQueueCancelRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.inbox.MailboxQueueCancelRequest.displayName = 'proto.inbox.MailboxQueueCancelRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.inbox.ExportBlackListPatternRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.inbox.ExportBlackListPatternRequest.repeatedFields_, null);
};
goog.inherits(proto.inbox.ExportBlackListPatternRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.inbox.ExportBlackListPatternRequest.displayName = 'proto.inbox.ExportBlackListPatternRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.inbox.GetUnsubscribedPatternPageable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.inbox.GetUnsubscribedPatternPageable.repeatedFields_, null);
};
goog.inherits(proto.inbox.GetUnsubscribedPatternPageable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.inbox.GetUnsubscribedPatternPageable.displayName = 'proto.inbox.GetUnsubscribedPatternPageable';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.inbox.UnsubscribedFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.inbox.UnsubscribedFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.inbox.UnsubscribedFilter.displayName = 'proto.inbox.UnsubscribedFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.inbox.ThreadPreviewRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.inbox.ThreadPreviewRequest.repeatedFields_, null);
};
goog.inherits(proto.inbox.ThreadPreviewRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.inbox.ThreadPreviewRequest.displayName = 'proto.inbox.ThreadPreviewRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.inbox.ThreadUidRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.inbox.ThreadUidRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.inbox.ThreadUidRequest.displayName = 'proto.inbox.ThreadUidRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.inbox.PersonInboxPreviewEmailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.inbox.PersonInboxPreviewEmailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.inbox.PersonInboxPreviewEmailsRequest.displayName = 'proto.inbox.PersonInboxPreviewEmailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.inbox.ThreadStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.inbox.ThreadStatusRequest.repeatedFields_, null);
};
goog.inherits(proto.inbox.ThreadStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.inbox.ThreadStatusRequest.displayName = 'proto.inbox.ThreadStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.inbox.GlobalInboxFilterRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.inbox.GlobalInboxFilterRequest.repeatedFields_, null);
};
goog.inherits(proto.inbox.GlobalInboxFilterRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.inbox.GlobalInboxFilterRequest.displayName = 'proto.inbox.GlobalInboxFilterRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.inbox.ThreadTag.prototype.toObject = function(opt_includeInstance) {
  return proto.inbox.ThreadTag.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.inbox.ThreadTag} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.ThreadTag.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    threadUid: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.inbox.ThreadTag}
 */
proto.inbox.ThreadTag.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.inbox.ThreadTag;
  return proto.inbox.ThreadTag.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.inbox.ThreadTag} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.inbox.ThreadTag}
 */
proto.inbox.ThreadTag.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setThreadUid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.inbox.ThreadTag.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.inbox.ThreadTag.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.inbox.ThreadTag} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.ThreadTag.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getThreadUid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.inbox.ThreadTag.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.ThreadTag} returns this
 */
proto.inbox.ThreadTag.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.inbox.ThreadTag.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.ThreadTag} returns this
 */
proto.inbox.ThreadTag.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string thread_uid = 3;
 * @return {string}
 */
proto.inbox.ThreadTag.prototype.getThreadUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.ThreadTag} returns this
 */
proto.inbox.ThreadTag.prototype.setThreadUid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.inbox.ThreadConversationMessageRequest.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.inbox.ThreadConversationMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.inbox.ThreadConversationMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.inbox.ThreadConversationMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.ThreadConversationMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    threadUid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    content: jspb.Message.getFieldWithDefault(msg, 3, ""),
    mentionedUserIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    attachmentsList: jspb.Message.toObjectList(msg.getAttachmentsList(),
    common_pb.FileAttachment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.inbox.ThreadConversationMessageRequest}
 */
proto.inbox.ThreadConversationMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.inbox.ThreadConversationMessageRequest;
  return proto.inbox.ThreadConversationMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.inbox.ThreadConversationMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.inbox.ThreadConversationMessageRequest}
 */
proto.inbox.ThreadConversationMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setThreadUid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 4:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setMentionedUserIdsList(value);
      break;
    case 5:
      var value = new common_pb.FileAttachment;
      reader.readMessage(value,common_pb.FileAttachment.deserializeBinaryFromReader);
      msg.addAttachments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.inbox.ThreadConversationMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.inbox.ThreadConversationMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.inbox.ThreadConversationMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.ThreadConversationMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getThreadUid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMentionedUserIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      4,
      f
    );
  }
  f = message.getAttachmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      common_pb.FileAttachment.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.inbox.ThreadConversationMessageRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.ThreadConversationMessageRequest} returns this
 */
proto.inbox.ThreadConversationMessageRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string thread_uid = 2;
 * @return {string}
 */
proto.inbox.ThreadConversationMessageRequest.prototype.getThreadUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.ThreadConversationMessageRequest} returns this
 */
proto.inbox.ThreadConversationMessageRequest.prototype.setThreadUid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string content = 3;
 * @return {string}
 */
proto.inbox.ThreadConversationMessageRequest.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.ThreadConversationMessageRequest} returns this
 */
proto.inbox.ThreadConversationMessageRequest.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated int64 mentioned_user_ids = 4;
 * @return {!Array<number>}
 */
proto.inbox.ThreadConversationMessageRequest.prototype.getMentionedUserIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.inbox.ThreadConversationMessageRequest} returns this
 */
proto.inbox.ThreadConversationMessageRequest.prototype.setMentionedUserIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.inbox.ThreadConversationMessageRequest} returns this
 */
proto.inbox.ThreadConversationMessageRequest.prototype.addMentionedUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.inbox.ThreadConversationMessageRequest} returns this
 */
proto.inbox.ThreadConversationMessageRequest.prototype.clearMentionedUserIdsList = function() {
  return this.setMentionedUserIdsList([]);
};


/**
 * repeated common.FileAttachment attachments = 5;
 * @return {!Array<!proto.common.FileAttachment>}
 */
proto.inbox.ThreadConversationMessageRequest.prototype.getAttachmentsList = function() {
  return /** @type{!Array<!proto.common.FileAttachment>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.FileAttachment, 5));
};


/**
 * @param {!Array<!proto.common.FileAttachment>} value
 * @return {!proto.inbox.ThreadConversationMessageRequest} returns this
*/
proto.inbox.ThreadConversationMessageRequest.prototype.setAttachmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.common.FileAttachment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.FileAttachment}
 */
proto.inbox.ThreadConversationMessageRequest.prototype.addAttachments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.common.FileAttachment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.inbox.ThreadConversationMessageRequest} returns this
 */
proto.inbox.ThreadConversationMessageRequest.prototype.clearAttachmentsList = function() {
  return this.setAttachmentsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.inbox.ThreadConversationMessageResponse.repeatedFields_ = [5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.inbox.ThreadConversationMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.inbox.ThreadConversationMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.inbox.ThreadConversationMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.ThreadConversationMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    content: jspb.Message.getFieldWithDefault(msg, 2, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 3, 0),
    createdBy: jspb.Message.getFieldWithDefault(msg, 4, 0),
    mentionedUserIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    attachmentsList: jspb.Message.toObjectList(msg.getAttachmentsList(),
    common_pb.FileAttachment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.inbox.ThreadConversationMessageResponse}
 */
proto.inbox.ThreadConversationMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.inbox.ThreadConversationMessageResponse;
  return proto.inbox.ThreadConversationMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.inbox.ThreadConversationMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.inbox.ThreadConversationMessageResponse}
 */
proto.inbox.ThreadConversationMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedBy(value);
      break;
    case 5:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setMentionedUserIdsList(value);
      break;
    case 6:
      var value = new common_pb.FileAttachment;
      reader.readMessage(value,common_pb.FileAttachment.deserializeBinaryFromReader);
      msg.addAttachments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.inbox.ThreadConversationMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.inbox.ThreadConversationMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.inbox.ThreadConversationMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.ThreadConversationMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCreatedBy();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getMentionedUserIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      5,
      f
    );
  }
  f = message.getAttachmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      common_pb.FileAttachment.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.inbox.ThreadConversationMessageResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.ThreadConversationMessageResponse} returns this
 */
proto.inbox.ThreadConversationMessageResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string content = 2;
 * @return {string}
 */
proto.inbox.ThreadConversationMessageResponse.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.ThreadConversationMessageResponse} returns this
 */
proto.inbox.ThreadConversationMessageResponse.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 created_at = 3;
 * @return {number}
 */
proto.inbox.ThreadConversationMessageResponse.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.ThreadConversationMessageResponse} returns this
 */
proto.inbox.ThreadConversationMessageResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 created_by = 4;
 * @return {number}
 */
proto.inbox.ThreadConversationMessageResponse.prototype.getCreatedBy = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.ThreadConversationMessageResponse} returns this
 */
proto.inbox.ThreadConversationMessageResponse.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated int64 mentioned_user_ids = 5;
 * @return {!Array<number>}
 */
proto.inbox.ThreadConversationMessageResponse.prototype.getMentionedUserIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.inbox.ThreadConversationMessageResponse} returns this
 */
proto.inbox.ThreadConversationMessageResponse.prototype.setMentionedUserIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.inbox.ThreadConversationMessageResponse} returns this
 */
proto.inbox.ThreadConversationMessageResponse.prototype.addMentionedUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.inbox.ThreadConversationMessageResponse} returns this
 */
proto.inbox.ThreadConversationMessageResponse.prototype.clearMentionedUserIdsList = function() {
  return this.setMentionedUserIdsList([]);
};


/**
 * repeated common.FileAttachment attachments = 6;
 * @return {!Array<!proto.common.FileAttachment>}
 */
proto.inbox.ThreadConversationMessageResponse.prototype.getAttachmentsList = function() {
  return /** @type{!Array<!proto.common.FileAttachment>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.FileAttachment, 6));
};


/**
 * @param {!Array<!proto.common.FileAttachment>} value
 * @return {!proto.inbox.ThreadConversationMessageResponse} returns this
*/
proto.inbox.ThreadConversationMessageResponse.prototype.setAttachmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.common.FileAttachment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.FileAttachment}
 */
proto.inbox.ThreadConversationMessageResponse.prototype.addAttachments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.common.FileAttachment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.inbox.ThreadConversationMessageResponse} returns this
 */
proto.inbox.ThreadConversationMessageResponse.prototype.clearAttachmentsList = function() {
  return this.setAttachmentsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.inbox.MailAIAssistantContainer.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.inbox.MailAIAssistantContainer.prototype.toObject = function(opt_includeInstance) {
  return proto.inbox.MailAIAssistantContainer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.inbox.MailAIAssistantContainer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.MailAIAssistantContainer.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.inbox.MailAIAssistantContainer.MailAIAssistantItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.inbox.MailAIAssistantContainer}
 */
proto.inbox.MailAIAssistantContainer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.inbox.MailAIAssistantContainer;
  return proto.inbox.MailAIAssistantContainer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.inbox.MailAIAssistantContainer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.inbox.MailAIAssistantContainer}
 */
proto.inbox.MailAIAssistantContainer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.inbox.MailAIAssistantContainer.MailAIAssistantItem;
      reader.readMessage(value,proto.inbox.MailAIAssistantContainer.MailAIAssistantItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.inbox.MailAIAssistantContainer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.inbox.MailAIAssistantContainer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.inbox.MailAIAssistantContainer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.MailAIAssistantContainer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.inbox.MailAIAssistantContainer.MailAIAssistantItem.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.inbox.MailAIAssistantContainer.MailAIAssistantItem.prototype.toObject = function(opt_includeInstance) {
  return proto.inbox.MailAIAssistantContainer.MailAIAssistantItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.inbox.MailAIAssistantContainer.MailAIAssistantItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.MailAIAssistantContainer.MailAIAssistantItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    order: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    action: jspb.Message.getFieldWithDefault(msg, 4, 0),
    type: jspb.Message.getFieldWithDefault(msg, 5, ""),
    icon: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.inbox.MailAIAssistantContainer.MailAIAssistantItem}
 */
proto.inbox.MailAIAssistantContainer.MailAIAssistantItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.inbox.MailAIAssistantContainer.MailAIAssistantItem;
  return proto.inbox.MailAIAssistantContainer.MailAIAssistantItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.inbox.MailAIAssistantContainer.MailAIAssistantItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.inbox.MailAIAssistantContainer.MailAIAssistantItem}
 */
proto.inbox.MailAIAssistantContainer.MailAIAssistantItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {!proto.inbox.MailAIAction} */ (reader.readEnum());
      msg.setAction(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.inbox.MailAIAssistantContainer.MailAIAssistantItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.inbox.MailAIAssistantContainer.MailAIAssistantItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.inbox.MailAIAssistantContainer.MailAIAssistantItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.MailAIAssistantContainer.MailAIAssistantItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAction();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional int32 order = 1;
 * @return {number}
 */
proto.inbox.MailAIAssistantContainer.MailAIAssistantItem.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.MailAIAssistantContainer.MailAIAssistantItem} returns this
 */
proto.inbox.MailAIAssistantContainer.MailAIAssistantItem.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.inbox.MailAIAssistantContainer.MailAIAssistantItem.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.MailAIAssistantContainer.MailAIAssistantItem} returns this
 */
proto.inbox.MailAIAssistantContainer.MailAIAssistantItem.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.inbox.MailAIAssistantContainer.MailAIAssistantItem.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.MailAIAssistantContainer.MailAIAssistantItem} returns this
 */
proto.inbox.MailAIAssistantContainer.MailAIAssistantItem.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional MailAIAction action = 4;
 * @return {!proto.inbox.MailAIAction}
 */
proto.inbox.MailAIAssistantContainer.MailAIAssistantItem.prototype.getAction = function() {
  return /** @type {!proto.inbox.MailAIAction} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.inbox.MailAIAction} value
 * @return {!proto.inbox.MailAIAssistantContainer.MailAIAssistantItem} returns this
 */
proto.inbox.MailAIAssistantContainer.MailAIAssistantItem.prototype.setAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string type = 5;
 * @return {string}
 */
proto.inbox.MailAIAssistantContainer.MailAIAssistantItem.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.MailAIAssistantContainer.MailAIAssistantItem} returns this
 */
proto.inbox.MailAIAssistantContainer.MailAIAssistantItem.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string icon = 6;
 * @return {string}
 */
proto.inbox.MailAIAssistantContainer.MailAIAssistantItem.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.MailAIAssistantContainer.MailAIAssistantItem} returns this
 */
proto.inbox.MailAIAssistantContainer.MailAIAssistantItem.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated MailAIAssistantItem items = 1;
 * @return {!Array<!proto.inbox.MailAIAssistantContainer.MailAIAssistantItem>}
 */
proto.inbox.MailAIAssistantContainer.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.inbox.MailAIAssistantContainer.MailAIAssistantItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.inbox.MailAIAssistantContainer.MailAIAssistantItem, 1));
};


/**
 * @param {!Array<!proto.inbox.MailAIAssistantContainer.MailAIAssistantItem>} value
 * @return {!proto.inbox.MailAIAssistantContainer} returns this
*/
proto.inbox.MailAIAssistantContainer.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.inbox.MailAIAssistantContainer.MailAIAssistantItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.inbox.MailAIAssistantContainer.MailAIAssistantItem}
 */
proto.inbox.MailAIAssistantContainer.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.inbox.MailAIAssistantContainer.MailAIAssistantItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.inbox.MailAIAssistantContainer} returns this
 */
proto.inbox.MailAIAssistantContainer.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.inbox.MailAIAssistantRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.inbox.MailAIAssistantRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.inbox.MailAIAssistantRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.MailAIAssistantRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    action: jspb.Message.getFieldWithDefault(msg, 1, 0),
    context: jspb.Message.getFieldWithDefault(msg, 2, ""),
    optLanguage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    optReplyMessageId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.inbox.MailAIAssistantRequest}
 */
proto.inbox.MailAIAssistantRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.inbox.MailAIAssistantRequest;
  return proto.inbox.MailAIAssistantRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.inbox.MailAIAssistantRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.inbox.MailAIAssistantRequest}
 */
proto.inbox.MailAIAssistantRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.inbox.MailAIAction} */ (reader.readEnum());
      msg.setAction(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContext(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptLanguage(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptReplyMessageId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.inbox.MailAIAssistantRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.inbox.MailAIAssistantRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.inbox.MailAIAssistantRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.MailAIAssistantRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAction();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getContext();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOptLanguage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOptReplyMessageId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional MailAIAction action = 1;
 * @return {!proto.inbox.MailAIAction}
 */
proto.inbox.MailAIAssistantRequest.prototype.getAction = function() {
  return /** @type {!proto.inbox.MailAIAction} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.inbox.MailAIAction} value
 * @return {!proto.inbox.MailAIAssistantRequest} returns this
 */
proto.inbox.MailAIAssistantRequest.prototype.setAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string context = 2;
 * @return {string}
 */
proto.inbox.MailAIAssistantRequest.prototype.getContext = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.MailAIAssistantRequest} returns this
 */
proto.inbox.MailAIAssistantRequest.prototype.setContext = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string opt_language = 3;
 * @return {string}
 */
proto.inbox.MailAIAssistantRequest.prototype.getOptLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.MailAIAssistantRequest} returns this
 */
proto.inbox.MailAIAssistantRequest.prototype.setOptLanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string opt_reply_message_id = 4;
 * @return {string}
 */
proto.inbox.MailAIAssistantRequest.prototype.getOptReplyMessageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.MailAIAssistantRequest} returns this
 */
proto.inbox.MailAIAssistantRequest.prototype.setOptReplyMessageId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 workspace_id = 5;
 * @return {number}
 */
proto.inbox.MailAIAssistantRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.MailAIAssistantRequest} returns this
 */
proto.inbox.MailAIAssistantRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.inbox.MailThreadDraftRequest.repeatedFields_ = [6,7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.inbox.MailThreadDraftRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.inbox.MailThreadDraftRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.inbox.MailThreadDraftRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.MailThreadDraftRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    threadUid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    messageId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    subject: jspb.Message.getFieldWithDefault(msg, 3, ""),
    content: jspb.Message.getFieldWithDefault(msg, 4, ""),
    emailTo: jspb.Message.getFieldWithDefault(msg, 5, ""),
    emailsToCcList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    emailsToBccList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    attachmentsList: jspb.Message.toObjectList(msg.getAttachmentsList(),
    common_pb.FileAttachment.toObject, includeInstance),
    type: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.inbox.MailThreadDraftRequest}
 */
proto.inbox.MailThreadDraftRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.inbox.MailThreadDraftRequest;
  return proto.inbox.MailThreadDraftRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.inbox.MailThreadDraftRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.inbox.MailThreadDraftRequest}
 */
proto.inbox.MailThreadDraftRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setThreadUid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessageId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubject(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailTo(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addEmailsToCc(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addEmailsToBcc(value);
      break;
    case 8:
      var value = new common_pb.FileAttachment;
      reader.readMessage(value,common_pb.FileAttachment.deserializeBinaryFromReader);
      msg.addAttachments(value);
      break;
    case 9:
      var value = /** @type {!proto.mailbox_sender.MessageRequestType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.inbox.MailThreadDraftRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.inbox.MailThreadDraftRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.inbox.MailThreadDraftRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.MailThreadDraftRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getThreadUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessageId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSubject();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEmailTo();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEmailsToCcList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getEmailsToBccList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getAttachmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      common_pb.FileAttachment.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
};


/**
 * optional string thread_uid = 1;
 * @return {string}
 */
proto.inbox.MailThreadDraftRequest.prototype.getThreadUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.MailThreadDraftRequest} returns this
 */
proto.inbox.MailThreadDraftRequest.prototype.setThreadUid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string message_id = 2;
 * @return {string}
 */
proto.inbox.MailThreadDraftRequest.prototype.getMessageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.MailThreadDraftRequest} returns this
 */
proto.inbox.MailThreadDraftRequest.prototype.setMessageId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string subject = 3;
 * @return {string}
 */
proto.inbox.MailThreadDraftRequest.prototype.getSubject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.MailThreadDraftRequest} returns this
 */
proto.inbox.MailThreadDraftRequest.prototype.setSubject = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string content = 4;
 * @return {string}
 */
proto.inbox.MailThreadDraftRequest.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.MailThreadDraftRequest} returns this
 */
proto.inbox.MailThreadDraftRequest.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string email_to = 5;
 * @return {string}
 */
proto.inbox.MailThreadDraftRequest.prototype.getEmailTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.MailThreadDraftRequest} returns this
 */
proto.inbox.MailThreadDraftRequest.prototype.setEmailTo = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated string emails_to_cc = 6;
 * @return {!Array<string>}
 */
proto.inbox.MailThreadDraftRequest.prototype.getEmailsToCcList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.inbox.MailThreadDraftRequest} returns this
 */
proto.inbox.MailThreadDraftRequest.prototype.setEmailsToCcList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.inbox.MailThreadDraftRequest} returns this
 */
proto.inbox.MailThreadDraftRequest.prototype.addEmailsToCc = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.inbox.MailThreadDraftRequest} returns this
 */
proto.inbox.MailThreadDraftRequest.prototype.clearEmailsToCcList = function() {
  return this.setEmailsToCcList([]);
};


/**
 * repeated string emails_to_bcc = 7;
 * @return {!Array<string>}
 */
proto.inbox.MailThreadDraftRequest.prototype.getEmailsToBccList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.inbox.MailThreadDraftRequest} returns this
 */
proto.inbox.MailThreadDraftRequest.prototype.setEmailsToBccList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.inbox.MailThreadDraftRequest} returns this
 */
proto.inbox.MailThreadDraftRequest.prototype.addEmailsToBcc = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.inbox.MailThreadDraftRequest} returns this
 */
proto.inbox.MailThreadDraftRequest.prototype.clearEmailsToBccList = function() {
  return this.setEmailsToBccList([]);
};


/**
 * repeated common.FileAttachment attachments = 8;
 * @return {!Array<!proto.common.FileAttachment>}
 */
proto.inbox.MailThreadDraftRequest.prototype.getAttachmentsList = function() {
  return /** @type{!Array<!proto.common.FileAttachment>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.FileAttachment, 8));
};


/**
 * @param {!Array<!proto.common.FileAttachment>} value
 * @return {!proto.inbox.MailThreadDraftRequest} returns this
*/
proto.inbox.MailThreadDraftRequest.prototype.setAttachmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.common.FileAttachment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.FileAttachment}
 */
proto.inbox.MailThreadDraftRequest.prototype.addAttachments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.common.FileAttachment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.inbox.MailThreadDraftRequest} returns this
 */
proto.inbox.MailThreadDraftRequest.prototype.clearAttachmentsList = function() {
  return this.setAttachmentsList([]);
};


/**
 * optional mailbox_sender.MessageRequestType type = 9;
 * @return {!proto.mailbox_sender.MessageRequestType}
 */
proto.inbox.MailThreadDraftRequest.prototype.getType = function() {
  return /** @type {!proto.mailbox_sender.MessageRequestType} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.mailbox_sender.MessageRequestType} value
 * @return {!proto.inbox.MailThreadDraftRequest} returns this
 */
proto.inbox.MailThreadDraftRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.inbox.MailAIAssistantResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.inbox.MailAIAssistantResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.inbox.MailAIAssistantResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.MailAIAssistantResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    result: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.inbox.MailAIAssistantResponse}
 */
proto.inbox.MailAIAssistantResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.inbox.MailAIAssistantResponse;
  return proto.inbox.MailAIAssistantResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.inbox.MailAIAssistantResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.inbox.MailAIAssistantResponse}
 */
proto.inbox.MailAIAssistantResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.inbox.MailAIAssistantResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.inbox.MailAIAssistantResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.inbox.MailAIAssistantResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.MailAIAssistantResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getResult();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.inbox.MailAIAssistantResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.inbox.MailAIAssistantResponse} returns this
 */
proto.inbox.MailAIAssistantResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string result = 2;
 * @return {string}
 */
proto.inbox.MailAIAssistantResponse.prototype.getResult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.MailAIAssistantResponse} returns this
 */
proto.inbox.MailAIAssistantResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.inbox.InboxUnreadCounterRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.inbox.InboxUnreadCounterRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.inbox.InboxUnreadCounterRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.inbox.InboxUnreadCounterRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.InboxUnreadCounterRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    accountIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.inbox.InboxUnreadCounterRequest}
 */
proto.inbox.InboxUnreadCounterRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.inbox.InboxUnreadCounterRequest;
  return proto.inbox.InboxUnreadCounterRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.inbox.InboxUnreadCounterRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.inbox.InboxUnreadCounterRequest}
 */
proto.inbox.InboxUnreadCounterRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 2:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setAccountIdsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.inbox.InboxUnreadCounterRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.inbox.InboxUnreadCounterRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.inbox.InboxUnreadCounterRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.InboxUnreadCounterRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAccountIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 workspace_id = 1;
 * @return {number}
 */
proto.inbox.InboxUnreadCounterRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.InboxUnreadCounterRequest} returns this
 */
proto.inbox.InboxUnreadCounterRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated int64 account_ids = 2;
 * @return {!Array<number>}
 */
proto.inbox.InboxUnreadCounterRequest.prototype.getAccountIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.inbox.InboxUnreadCounterRequest} returns this
 */
proto.inbox.InboxUnreadCounterRequest.prototype.setAccountIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.inbox.InboxUnreadCounterRequest} returns this
 */
proto.inbox.InboxUnreadCounterRequest.prototype.addAccountIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.inbox.InboxUnreadCounterRequest} returns this
 */
proto.inbox.InboxUnreadCounterRequest.prototype.clearAccountIdsList = function() {
  return this.setAccountIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.inbox.InboxUnreadCounterResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.inbox.InboxUnreadCounterResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.inbox.InboxUnreadCounterResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.InboxUnreadCounterResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    openNumber: jspb.Message.getFieldWithDefault(msg, 1, 0),
    assignedNumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    mentionedNumber: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.inbox.InboxUnreadCounterResponse}
 */
proto.inbox.InboxUnreadCounterResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.inbox.InboxUnreadCounterResponse;
  return proto.inbox.InboxUnreadCounterResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.inbox.InboxUnreadCounterResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.inbox.InboxUnreadCounterResponse}
 */
proto.inbox.InboxUnreadCounterResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOpenNumber(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAssignedNumber(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMentionedNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.inbox.InboxUnreadCounterResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.inbox.InboxUnreadCounterResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.inbox.InboxUnreadCounterResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.InboxUnreadCounterResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOpenNumber();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAssignedNumber();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getMentionedNumber();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 open_number = 1;
 * @return {number}
 */
proto.inbox.InboxUnreadCounterResponse.prototype.getOpenNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.InboxUnreadCounterResponse} returns this
 */
proto.inbox.InboxUnreadCounterResponse.prototype.setOpenNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 assigned_number = 2;
 * @return {number}
 */
proto.inbox.InboxUnreadCounterResponse.prototype.getAssignedNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.InboxUnreadCounterResponse} returns this
 */
proto.inbox.InboxUnreadCounterResponse.prototype.setAssignedNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 mentioned_number = 3;
 * @return {number}
 */
proto.inbox.InboxUnreadCounterResponse.prototype.getMentionedNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.InboxUnreadCounterResponse} returns this
 */
proto.inbox.InboxUnreadCounterResponse.prototype.setMentionedNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.inbox.InboxAccountsPreset.prototype.toObject = function(opt_includeInstance) {
  return proto.inbox.InboxAccountsPreset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.inbox.InboxAccountsPreset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.InboxAccountsPreset.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    senderName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    selected: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.inbox.InboxAccountsPreset}
 */
proto.inbox.InboxAccountsPreset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.inbox.InboxAccountsPreset;
  return proto.inbox.InboxAccountsPreset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.inbox.InboxAccountsPreset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.inbox.InboxAccountsPreset}
 */
proto.inbox.InboxAccountsPreset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSenderName(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSelected(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.inbox.InboxAccountsPreset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.inbox.InboxAccountsPreset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.inbox.InboxAccountsPreset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.InboxAccountsPreset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSenderName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSelected();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.inbox.InboxAccountsPreset.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.InboxAccountsPreset} returns this
 */
proto.inbox.InboxAccountsPreset.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.inbox.InboxAccountsPreset.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.InboxAccountsPreset} returns this
 */
proto.inbox.InboxAccountsPreset.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string sender_name = 3;
 * @return {string}
 */
proto.inbox.InboxAccountsPreset.prototype.getSenderName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.InboxAccountsPreset} returns this
 */
proto.inbox.InboxAccountsPreset.prototype.setSenderName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool selected = 4;
 * @return {boolean}
 */
proto.inbox.InboxAccountsPreset.prototype.getSelected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.inbox.InboxAccountsPreset} returns this
 */
proto.inbox.InboxAccountsPreset.prototype.setSelected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.inbox.AssignThreadToUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.inbox.AssignThreadToUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.inbox.AssignThreadToUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.AssignThreadToUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    threadUid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.inbox.AssignThreadToUserRequest}
 */
proto.inbox.AssignThreadToUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.inbox.AssignThreadToUserRequest;
  return proto.inbox.AssignThreadToUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.inbox.AssignThreadToUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.inbox.AssignThreadToUserRequest}
 */
proto.inbox.AssignThreadToUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setThreadUid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.inbox.AssignThreadToUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.inbox.AssignThreadToUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.inbox.AssignThreadToUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.AssignThreadToUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getThreadUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string thread_uid = 1;
 * @return {string}
 */
proto.inbox.AssignThreadToUserRequest.prototype.getThreadUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.AssignThreadToUserRequest} returns this
 */
proto.inbox.AssignThreadToUserRequest.prototype.setThreadUid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 user_id = 2;
 * @return {number}
 */
proto.inbox.AssignThreadToUserRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.AssignThreadToUserRequest} returns this
 */
proto.inbox.AssignThreadToUserRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.inbox.AssignMailsToPersonRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.inbox.AssignMailsToPersonRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.inbox.AssignMailsToPersonRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.AssignMailsToPersonRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    personId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    websiteId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.inbox.AssignMailsToPersonRequest}
 */
proto.inbox.AssignMailsToPersonRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.inbox.AssignMailsToPersonRequest;
  return proto.inbox.AssignMailsToPersonRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.inbox.AssignMailsToPersonRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.inbox.AssignMailsToPersonRequest}
 */
proto.inbox.AssignMailsToPersonRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPersonId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWebsiteId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.inbox.AssignMailsToPersonRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.inbox.AssignMailsToPersonRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.inbox.AssignMailsToPersonRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.AssignMailsToPersonRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPersonId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getWebsiteId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.inbox.AssignMailsToPersonRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.AssignMailsToPersonRequest} returns this
 */
proto.inbox.AssignMailsToPersonRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 person_id = 2;
 * @return {number}
 */
proto.inbox.AssignMailsToPersonRequest.prototype.getPersonId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.AssignMailsToPersonRequest} returns this
 */
proto.inbox.AssignMailsToPersonRequest.prototype.setPersonId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 website_id = 3;
 * @return {number}
 */
proto.inbox.AssignMailsToPersonRequest.prototype.getWebsiteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.AssignMailsToPersonRequest} returns this
 */
proto.inbox.AssignMailsToPersonRequest.prototype.setWebsiteId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.inbox.ChangeViewStateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.inbox.ChangeViewStateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.inbox.ChangeViewStateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.ChangeViewStateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    threadUid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    viewed: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.inbox.ChangeViewStateRequest}
 */
proto.inbox.ChangeViewStateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.inbox.ChangeViewStateRequest;
  return proto.inbox.ChangeViewStateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.inbox.ChangeViewStateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.inbox.ChangeViewStateRequest}
 */
proto.inbox.ChangeViewStateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setThreadUid(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setViewed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.inbox.ChangeViewStateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.inbox.ChangeViewStateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.inbox.ChangeViewStateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.ChangeViewStateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getThreadUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getViewed();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string thread_uid = 1;
 * @return {string}
 */
proto.inbox.ChangeViewStateRequest.prototype.getThreadUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.ChangeViewStateRequest} returns this
 */
proto.inbox.ChangeViewStateRequest.prototype.setThreadUid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool viewed = 2;
 * @return {boolean}
 */
proto.inbox.ChangeViewStateRequest.prototype.getViewed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.inbox.ChangeViewStateRequest} returns this
 */
proto.inbox.ChangeViewStateRequest.prototype.setViewed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.inbox.ChangeThreadStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.inbox.ChangeThreadStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.inbox.ChangeThreadStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.ChangeThreadStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    threadId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    optionalSnoozedUntilAt: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.inbox.ChangeThreadStatusRequest}
 */
proto.inbox.ChangeThreadStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.inbox.ChangeThreadStatusRequest;
  return proto.inbox.ChangeThreadStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.inbox.ChangeThreadStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.inbox.ChangeThreadStatusRequest}
 */
proto.inbox.ChangeThreadStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setThreadId(value);
      break;
    case 2:
      var value = /** @type {!proto.inbox.ThreadStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOptionalSnoozedUntilAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.inbox.ChangeThreadStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.inbox.ChangeThreadStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.inbox.ChangeThreadStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.ChangeThreadStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getThreadId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getOptionalSnoozedUntilAt();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 thread_id = 1;
 * @return {number}
 */
proto.inbox.ChangeThreadStatusRequest.prototype.getThreadId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.ChangeThreadStatusRequest} returns this
 */
proto.inbox.ChangeThreadStatusRequest.prototype.setThreadId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional ThreadStatus status = 2;
 * @return {!proto.inbox.ThreadStatus}
 */
proto.inbox.ChangeThreadStatusRequest.prototype.getStatus = function() {
  return /** @type {!proto.inbox.ThreadStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.inbox.ThreadStatus} value
 * @return {!proto.inbox.ChangeThreadStatusRequest} returns this
 */
proto.inbox.ChangeThreadStatusRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int64 optional_snoozed_until_at = 3;
 * @return {number}
 */
proto.inbox.ChangeThreadStatusRequest.prototype.getOptionalSnoozedUntilAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.ChangeThreadStatusRequest} returns this
 */
proto.inbox.ChangeThreadStatusRequest.prototype.setOptionalSnoozedUntilAt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.inbox.ThreadPreviewResponse.repeatedFields_ = [16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.inbox.ThreadPreviewResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.inbox.ThreadPreviewResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.inbox.ThreadPreviewResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.ThreadPreviewResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    threadId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    lastActivityTime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    viewed: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    favorite: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    fromEmail: jspb.Message.getFieldWithDefault(msg, 6, ""),
    fromName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    toEmail: jspb.Message.getFieldWithDefault(msg, 8, ""),
    toName: jspb.Message.getFieldWithDefault(msg, 9, ""),
    subject: jspb.Message.getFieldWithDefault(msg, 10, ""),
    snippet: jspb.Message.getFieldWithDefault(msg, 11, ""),
    snoozedUntil: jspb.Message.getFieldWithDefault(msg, 12, 0),
    snoozedByUserId: jspb.Message.getFieldWithDefault(msg, 13, 0),
    assignedToUserId: jspb.Message.getFieldWithDefault(msg, 14, 0),
    draftId: jspb.Message.getFieldWithDefault(msg, 15, 0),
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    common_pb.Tag.toObject, includeInstance),
    totalItems: jspb.Message.getFieldWithDefault(msg, 17, 0),
    uid: jspb.Message.getFieldWithDefault(msg, 18, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.inbox.ThreadPreviewResponse}
 */
proto.inbox.ThreadPreviewResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.inbox.ThreadPreviewResponse;
  return proto.inbox.ThreadPreviewResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.inbox.ThreadPreviewResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.inbox.ThreadPreviewResponse}
 */
proto.inbox.ThreadPreviewResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setThreadId(value);
      break;
    case 2:
      var value = /** @type {!proto.inbox.ThreadStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastActivityTime(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setViewed(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFavorite(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromEmail(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setToEmail(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setToName(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubject(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setSnippet(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSnoozedUntil(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSnoozedByUserId(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAssignedToUserId(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDraftId(value);
      break;
    case 16:
      var value = new common_pb.Tag;
      reader.readMessage(value,common_pb.Tag.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalItems(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.inbox.ThreadPreviewResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.inbox.ThreadPreviewResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.inbox.ThreadPreviewResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.ThreadPreviewResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getThreadId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getLastActivityTime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getViewed();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getFavorite();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getFromEmail();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getFromName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getToEmail();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getToName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getSubject();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSnippet();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getSnoozedUntil();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getSnoozedByUserId();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getAssignedToUserId();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getDraftId();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      common_pb.Tag.serializeBinaryToWriter
    );
  }
  f = message.getTotalItems();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
};


/**
 * optional int64 thread_id = 1;
 * @return {number}
 */
proto.inbox.ThreadPreviewResponse.prototype.getThreadId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.ThreadPreviewResponse} returns this
 */
proto.inbox.ThreadPreviewResponse.prototype.setThreadId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional ThreadStatus status = 2;
 * @return {!proto.inbox.ThreadStatus}
 */
proto.inbox.ThreadPreviewResponse.prototype.getStatus = function() {
  return /** @type {!proto.inbox.ThreadStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.inbox.ThreadStatus} value
 * @return {!proto.inbox.ThreadPreviewResponse} returns this
 */
proto.inbox.ThreadPreviewResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int64 last_activity_time = 3;
 * @return {number}
 */
proto.inbox.ThreadPreviewResponse.prototype.getLastActivityTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.ThreadPreviewResponse} returns this
 */
proto.inbox.ThreadPreviewResponse.prototype.setLastActivityTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool viewed = 4;
 * @return {boolean}
 */
proto.inbox.ThreadPreviewResponse.prototype.getViewed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.inbox.ThreadPreviewResponse} returns this
 */
proto.inbox.ThreadPreviewResponse.prototype.setViewed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool favorite = 5;
 * @return {boolean}
 */
proto.inbox.ThreadPreviewResponse.prototype.getFavorite = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.inbox.ThreadPreviewResponse} returns this
 */
proto.inbox.ThreadPreviewResponse.prototype.setFavorite = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string from_email = 6;
 * @return {string}
 */
proto.inbox.ThreadPreviewResponse.prototype.getFromEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.ThreadPreviewResponse} returns this
 */
proto.inbox.ThreadPreviewResponse.prototype.setFromEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string from_name = 7;
 * @return {string}
 */
proto.inbox.ThreadPreviewResponse.prototype.getFromName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.ThreadPreviewResponse} returns this
 */
proto.inbox.ThreadPreviewResponse.prototype.setFromName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string to_email = 8;
 * @return {string}
 */
proto.inbox.ThreadPreviewResponse.prototype.getToEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.ThreadPreviewResponse} returns this
 */
proto.inbox.ThreadPreviewResponse.prototype.setToEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string to_name = 9;
 * @return {string}
 */
proto.inbox.ThreadPreviewResponse.prototype.getToName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.ThreadPreviewResponse} returns this
 */
proto.inbox.ThreadPreviewResponse.prototype.setToName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string subject = 10;
 * @return {string}
 */
proto.inbox.ThreadPreviewResponse.prototype.getSubject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.ThreadPreviewResponse} returns this
 */
proto.inbox.ThreadPreviewResponse.prototype.setSubject = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string snippet = 11;
 * @return {string}
 */
proto.inbox.ThreadPreviewResponse.prototype.getSnippet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.ThreadPreviewResponse} returns this
 */
proto.inbox.ThreadPreviewResponse.prototype.setSnippet = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional int64 snoozed_until = 12;
 * @return {number}
 */
proto.inbox.ThreadPreviewResponse.prototype.getSnoozedUntil = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.ThreadPreviewResponse} returns this
 */
proto.inbox.ThreadPreviewResponse.prototype.setSnoozedUntil = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int64 snoozed_by_user_id = 13;
 * @return {number}
 */
proto.inbox.ThreadPreviewResponse.prototype.getSnoozedByUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.ThreadPreviewResponse} returns this
 */
proto.inbox.ThreadPreviewResponse.prototype.setSnoozedByUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int64 assigned_to_user_id = 14;
 * @return {number}
 */
proto.inbox.ThreadPreviewResponse.prototype.getAssignedToUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.ThreadPreviewResponse} returns this
 */
proto.inbox.ThreadPreviewResponse.prototype.setAssignedToUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int64 draft_id = 15;
 * @return {number}
 */
proto.inbox.ThreadPreviewResponse.prototype.getDraftId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.ThreadPreviewResponse} returns this
 */
proto.inbox.ThreadPreviewResponse.prototype.setDraftId = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * repeated common.Tag tags = 16;
 * @return {!Array<!proto.common.Tag>}
 */
proto.inbox.ThreadPreviewResponse.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.common.Tag>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.Tag, 16));
};


/**
 * @param {!Array<!proto.common.Tag>} value
 * @return {!proto.inbox.ThreadPreviewResponse} returns this
*/
proto.inbox.ThreadPreviewResponse.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.common.Tag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Tag}
 */
proto.inbox.ThreadPreviewResponse.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.common.Tag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.inbox.ThreadPreviewResponse} returns this
 */
proto.inbox.ThreadPreviewResponse.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * optional int32 total_items = 17;
 * @return {number}
 */
proto.inbox.ThreadPreviewResponse.prototype.getTotalItems = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.ThreadPreviewResponse} returns this
 */
proto.inbox.ThreadPreviewResponse.prototype.setTotalItems = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional string uid = 18;
 * @return {string}
 */
proto.inbox.ThreadPreviewResponse.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.ThreadPreviewResponse} returns this
 */
proto.inbox.ThreadPreviewResponse.prototype.setUid = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.inbox.OutboxPreviewResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.inbox.OutboxPreviewResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.inbox.OutboxPreviewResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.OutboxPreviewResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    contactId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    opportunityId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    nextActivityTime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    toEmail: jspb.Message.getFieldWithDefault(msg, 4, ""),
    toName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    subject: jspb.Message.getFieldWithDefault(msg, 6, ""),
    snippet: jspb.Message.getFieldWithDefault(msg, 7, ""),
    totalItems: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.inbox.OutboxPreviewResponse}
 */
proto.inbox.OutboxPreviewResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.inbox.OutboxPreviewResponse;
  return proto.inbox.OutboxPreviewResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.inbox.OutboxPreviewResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.inbox.OutboxPreviewResponse}
 */
proto.inbox.OutboxPreviewResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setContactId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOpportunityId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNextActivityTime(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setToEmail(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setToName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubject(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSnippet(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.inbox.OutboxPreviewResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.inbox.OutboxPreviewResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.inbox.OutboxPreviewResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.OutboxPreviewResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContactId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOpportunityId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getNextActivityTime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getToEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getToName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSubject();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSnippet();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTotalItems();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
};


/**
 * optional int64 contact_id = 1;
 * @return {number}
 */
proto.inbox.OutboxPreviewResponse.prototype.getContactId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.OutboxPreviewResponse} returns this
 */
proto.inbox.OutboxPreviewResponse.prototype.setContactId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 opportunity_id = 2;
 * @return {number}
 */
proto.inbox.OutboxPreviewResponse.prototype.getOpportunityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.OutboxPreviewResponse} returns this
 */
proto.inbox.OutboxPreviewResponse.prototype.setOpportunityId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 next_activity_time = 3;
 * @return {number}
 */
proto.inbox.OutboxPreviewResponse.prototype.getNextActivityTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.OutboxPreviewResponse} returns this
 */
proto.inbox.OutboxPreviewResponse.prototype.setNextActivityTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string to_email = 4;
 * @return {string}
 */
proto.inbox.OutboxPreviewResponse.prototype.getToEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.OutboxPreviewResponse} returns this
 */
proto.inbox.OutboxPreviewResponse.prototype.setToEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string to_name = 5;
 * @return {string}
 */
proto.inbox.OutboxPreviewResponse.prototype.getToName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.OutboxPreviewResponse} returns this
 */
proto.inbox.OutboxPreviewResponse.prototype.setToName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string subject = 6;
 * @return {string}
 */
proto.inbox.OutboxPreviewResponse.prototype.getSubject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.OutboxPreviewResponse} returns this
 */
proto.inbox.OutboxPreviewResponse.prototype.setSubject = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string snippet = 7;
 * @return {string}
 */
proto.inbox.OutboxPreviewResponse.prototype.getSnippet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.OutboxPreviewResponse} returns this
 */
proto.inbox.OutboxPreviewResponse.prototype.setSnippet = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 total_items = 8;
 * @return {number}
 */
proto.inbox.OutboxPreviewResponse.prototype.getTotalItems = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.OutboxPreviewResponse} returns this
 */
proto.inbox.OutboxPreviewResponse.prototype.setTotalItems = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.inbox.QueuePreviewResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.inbox.QueuePreviewResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.inbox.QueuePreviewResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.QueuePreviewResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    personId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    contactId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    nextActivityTime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    toEmail: jspb.Message.getFieldWithDefault(msg, 4, ""),
    toName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    subject: jspb.Message.getFieldWithDefault(msg, 6, ""),
    snippet: jspb.Message.getFieldWithDefault(msg, 7, ""),
    totalItems: jspb.Message.getFieldWithDefault(msg, 8, 0),
    queueId: jspb.Message.getFieldWithDefault(msg, 9, 0),
    websiteId: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.inbox.QueuePreviewResponse}
 */
proto.inbox.QueuePreviewResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.inbox.QueuePreviewResponse;
  return proto.inbox.QueuePreviewResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.inbox.QueuePreviewResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.inbox.QueuePreviewResponse}
 */
proto.inbox.QueuePreviewResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPersonId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setContactId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNextActivityTime(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setToEmail(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setToName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubject(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSnippet(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalItems(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQueueId(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWebsiteId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.inbox.QueuePreviewResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.inbox.QueuePreviewResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.inbox.QueuePreviewResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.QueuePreviewResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPersonId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getContactId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getNextActivityTime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getToEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getToName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSubject();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSnippet();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTotalItems();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getQueueId();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getWebsiteId();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
};


/**
 * optional int64 person_id = 1;
 * @return {number}
 */
proto.inbox.QueuePreviewResponse.prototype.getPersonId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.QueuePreviewResponse} returns this
 */
proto.inbox.QueuePreviewResponse.prototype.setPersonId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 contact_id = 2;
 * @return {number}
 */
proto.inbox.QueuePreviewResponse.prototype.getContactId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.QueuePreviewResponse} returns this
 */
proto.inbox.QueuePreviewResponse.prototype.setContactId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 next_activity_time = 3;
 * @return {number}
 */
proto.inbox.QueuePreviewResponse.prototype.getNextActivityTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.QueuePreviewResponse} returns this
 */
proto.inbox.QueuePreviewResponse.prototype.setNextActivityTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string to_email = 4;
 * @return {string}
 */
proto.inbox.QueuePreviewResponse.prototype.getToEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.QueuePreviewResponse} returns this
 */
proto.inbox.QueuePreviewResponse.prototype.setToEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string to_name = 5;
 * @return {string}
 */
proto.inbox.QueuePreviewResponse.prototype.getToName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.QueuePreviewResponse} returns this
 */
proto.inbox.QueuePreviewResponse.prototype.setToName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string subject = 6;
 * @return {string}
 */
proto.inbox.QueuePreviewResponse.prototype.getSubject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.QueuePreviewResponse} returns this
 */
proto.inbox.QueuePreviewResponse.prototype.setSubject = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string snippet = 7;
 * @return {string}
 */
proto.inbox.QueuePreviewResponse.prototype.getSnippet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.QueuePreviewResponse} returns this
 */
proto.inbox.QueuePreviewResponse.prototype.setSnippet = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 total_items = 8;
 * @return {number}
 */
proto.inbox.QueuePreviewResponse.prototype.getTotalItems = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.QueuePreviewResponse} returns this
 */
proto.inbox.QueuePreviewResponse.prototype.setTotalItems = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 queue_id = 9;
 * @return {number}
 */
proto.inbox.QueuePreviewResponse.prototype.getQueueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.QueuePreviewResponse} returns this
 */
proto.inbox.QueuePreviewResponse.prototype.setQueueId = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 website_id = 10;
 * @return {number}
 */
proto.inbox.QueuePreviewResponse.prototype.getWebsiteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.QueuePreviewResponse} returns this
 */
proto.inbox.QueuePreviewResponse.prototype.setWebsiteId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.inbox.QueueMessageResponse.repeatedFields_ = [9,10,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.inbox.QueueMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.inbox.QueueMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.inbox.QueueMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.QueueMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    fromEmail: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fromName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    toName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    toEmail: jspb.Message.getFieldWithDefault(msg, 5, ""),
    subject: jspb.Message.getFieldWithDefault(msg, 6, ""),
    content: jspb.Message.getFieldWithDefault(msg, 7, ""),
    sameThread: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    attachmentsList: jspb.Message.toObjectList(msg.getAttachmentsList(),
    common_pb.FileAttachment.toObject, includeInstance),
    emailsToCcList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    emailsToBccList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
    nextActivityTime: jspb.Message.getFieldWithDefault(msg, 12, 0),
    opportunityId: jspb.Message.getFieldWithDefault(msg, 13, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.inbox.QueueMessageResponse}
 */
proto.inbox.QueueMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.inbox.QueueMessageResponse;
  return proto.inbox.QueueMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.inbox.QueueMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.inbox.QueueMessageResponse}
 */
proto.inbox.QueueMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setToName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setToEmail(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubject(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSameThread(value);
      break;
    case 9:
      var value = new common_pb.FileAttachment;
      reader.readMessage(value,common_pb.FileAttachment.deserializeBinaryFromReader);
      msg.addAttachments(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addEmailsToCc(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.addEmailsToBcc(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNextActivityTime(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOpportunityId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.inbox.QueueMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.inbox.QueueMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.inbox.QueueMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.QueueMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getFromEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFromName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getToName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getToEmail();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSubject();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSameThread();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getAttachmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      common_pb.FileAttachment.serializeBinaryToWriter
    );
  }
  f = message.getEmailsToCcList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
  f = message.getEmailsToBccList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      11,
      f
    );
  }
  f = message.getNextActivityTime();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getOpportunityId();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.inbox.QueueMessageResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.QueueMessageResponse} returns this
 */
proto.inbox.QueueMessageResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string from_email = 2;
 * @return {string}
 */
proto.inbox.QueueMessageResponse.prototype.getFromEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.QueueMessageResponse} returns this
 */
proto.inbox.QueueMessageResponse.prototype.setFromEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string from_name = 3;
 * @return {string}
 */
proto.inbox.QueueMessageResponse.prototype.getFromName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.QueueMessageResponse} returns this
 */
proto.inbox.QueueMessageResponse.prototype.setFromName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string to_name = 4;
 * @return {string}
 */
proto.inbox.QueueMessageResponse.prototype.getToName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.QueueMessageResponse} returns this
 */
proto.inbox.QueueMessageResponse.prototype.setToName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string to_email = 5;
 * @return {string}
 */
proto.inbox.QueueMessageResponse.prototype.getToEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.QueueMessageResponse} returns this
 */
proto.inbox.QueueMessageResponse.prototype.setToEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string subject = 6;
 * @return {string}
 */
proto.inbox.QueueMessageResponse.prototype.getSubject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.QueueMessageResponse} returns this
 */
proto.inbox.QueueMessageResponse.prototype.setSubject = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string content = 7;
 * @return {string}
 */
proto.inbox.QueueMessageResponse.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.QueueMessageResponse} returns this
 */
proto.inbox.QueueMessageResponse.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool same_thread = 8;
 * @return {boolean}
 */
proto.inbox.QueueMessageResponse.prototype.getSameThread = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.inbox.QueueMessageResponse} returns this
 */
proto.inbox.QueueMessageResponse.prototype.setSameThread = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * repeated common.FileAttachment attachments = 9;
 * @return {!Array<!proto.common.FileAttachment>}
 */
proto.inbox.QueueMessageResponse.prototype.getAttachmentsList = function() {
  return /** @type{!Array<!proto.common.FileAttachment>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.FileAttachment, 9));
};


/**
 * @param {!Array<!proto.common.FileAttachment>} value
 * @return {!proto.inbox.QueueMessageResponse} returns this
*/
proto.inbox.QueueMessageResponse.prototype.setAttachmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.common.FileAttachment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.FileAttachment}
 */
proto.inbox.QueueMessageResponse.prototype.addAttachments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.common.FileAttachment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.inbox.QueueMessageResponse} returns this
 */
proto.inbox.QueueMessageResponse.prototype.clearAttachmentsList = function() {
  return this.setAttachmentsList([]);
};


/**
 * repeated string emails_to_cc = 10;
 * @return {!Array<string>}
 */
proto.inbox.QueueMessageResponse.prototype.getEmailsToCcList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.inbox.QueueMessageResponse} returns this
 */
proto.inbox.QueueMessageResponse.prototype.setEmailsToCcList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.inbox.QueueMessageResponse} returns this
 */
proto.inbox.QueueMessageResponse.prototype.addEmailsToCc = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.inbox.QueueMessageResponse} returns this
 */
proto.inbox.QueueMessageResponse.prototype.clearEmailsToCcList = function() {
  return this.setEmailsToCcList([]);
};


/**
 * repeated string emails_to_bcc = 11;
 * @return {!Array<string>}
 */
proto.inbox.QueueMessageResponse.prototype.getEmailsToBccList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.inbox.QueueMessageResponse} returns this
 */
proto.inbox.QueueMessageResponse.prototype.setEmailsToBccList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.inbox.QueueMessageResponse} returns this
 */
proto.inbox.QueueMessageResponse.prototype.addEmailsToBcc = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.inbox.QueueMessageResponse} returns this
 */
proto.inbox.QueueMessageResponse.prototype.clearEmailsToBccList = function() {
  return this.setEmailsToBccList([]);
};


/**
 * optional int64 next_activity_time = 12;
 * @return {number}
 */
proto.inbox.QueueMessageResponse.prototype.getNextActivityTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.QueueMessageResponse} returns this
 */
proto.inbox.QueueMessageResponse.prototype.setNextActivityTime = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int64 opportunity_id = 13;
 * @return {number}
 */
proto.inbox.QueueMessageResponse.prototype.getOpportunityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.QueueMessageResponse} returns this
 */
proto.inbox.QueueMessageResponse.prototype.setOpportunityId = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.inbox.QueueMessageUpdateRequest.repeatedFields_ = [7,8,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.inbox.QueueMessageUpdateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.inbox.QueueMessageUpdateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.inbox.QueueMessageUpdateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.QueueMessageUpdateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    toName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    toEmail: jspb.Message.getFieldWithDefault(msg, 3, ""),
    subject: jspb.Message.getFieldWithDefault(msg, 4, ""),
    content: jspb.Message.getFieldWithDefault(msg, 5, ""),
    sameThread: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    attachmentsList: jspb.Message.toObjectList(msg.getAttachmentsList(),
    common_pb.FileAttachment.toObject, includeInstance),
    emailsToCcList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    emailsToBccList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.inbox.QueueMessageUpdateRequest}
 */
proto.inbox.QueueMessageUpdateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.inbox.QueueMessageUpdateRequest;
  return proto.inbox.QueueMessageUpdateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.inbox.QueueMessageUpdateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.inbox.QueueMessageUpdateRequest}
 */
proto.inbox.QueueMessageUpdateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setToName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setToEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubject(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSameThread(value);
      break;
    case 7:
      var value = new common_pb.FileAttachment;
      reader.readMessage(value,common_pb.FileAttachment.deserializeBinaryFromReader);
      msg.addAttachments(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addEmailsToCc(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addEmailsToBcc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.inbox.QueueMessageUpdateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.inbox.QueueMessageUpdateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.inbox.QueueMessageUpdateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.QueueMessageUpdateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getToName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getToEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSubject();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSameThread();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getAttachmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      common_pb.FileAttachment.serializeBinaryToWriter
    );
  }
  f = message.getEmailsToCcList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getEmailsToBccList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.inbox.QueueMessageUpdateRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.QueueMessageUpdateRequest} returns this
 */
proto.inbox.QueueMessageUpdateRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string to_name = 2;
 * @return {string}
 */
proto.inbox.QueueMessageUpdateRequest.prototype.getToName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.QueueMessageUpdateRequest} returns this
 */
proto.inbox.QueueMessageUpdateRequest.prototype.setToName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string to_email = 3;
 * @return {string}
 */
proto.inbox.QueueMessageUpdateRequest.prototype.getToEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.QueueMessageUpdateRequest} returns this
 */
proto.inbox.QueueMessageUpdateRequest.prototype.setToEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string subject = 4;
 * @return {string}
 */
proto.inbox.QueueMessageUpdateRequest.prototype.getSubject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.QueueMessageUpdateRequest} returns this
 */
proto.inbox.QueueMessageUpdateRequest.prototype.setSubject = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string content = 5;
 * @return {string}
 */
proto.inbox.QueueMessageUpdateRequest.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.QueueMessageUpdateRequest} returns this
 */
proto.inbox.QueueMessageUpdateRequest.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool same_thread = 6;
 * @return {boolean}
 */
proto.inbox.QueueMessageUpdateRequest.prototype.getSameThread = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.inbox.QueueMessageUpdateRequest} returns this
 */
proto.inbox.QueueMessageUpdateRequest.prototype.setSameThread = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * repeated common.FileAttachment attachments = 7;
 * @return {!Array<!proto.common.FileAttachment>}
 */
proto.inbox.QueueMessageUpdateRequest.prototype.getAttachmentsList = function() {
  return /** @type{!Array<!proto.common.FileAttachment>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.FileAttachment, 7));
};


/**
 * @param {!Array<!proto.common.FileAttachment>} value
 * @return {!proto.inbox.QueueMessageUpdateRequest} returns this
*/
proto.inbox.QueueMessageUpdateRequest.prototype.setAttachmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.common.FileAttachment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.FileAttachment}
 */
proto.inbox.QueueMessageUpdateRequest.prototype.addAttachments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.common.FileAttachment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.inbox.QueueMessageUpdateRequest} returns this
 */
proto.inbox.QueueMessageUpdateRequest.prototype.clearAttachmentsList = function() {
  return this.setAttachmentsList([]);
};


/**
 * repeated string emails_to_cc = 8;
 * @return {!Array<string>}
 */
proto.inbox.QueueMessageUpdateRequest.prototype.getEmailsToCcList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.inbox.QueueMessageUpdateRequest} returns this
 */
proto.inbox.QueueMessageUpdateRequest.prototype.setEmailsToCcList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.inbox.QueueMessageUpdateRequest} returns this
 */
proto.inbox.QueueMessageUpdateRequest.prototype.addEmailsToCc = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.inbox.QueueMessageUpdateRequest} returns this
 */
proto.inbox.QueueMessageUpdateRequest.prototype.clearEmailsToCcList = function() {
  return this.setEmailsToCcList([]);
};


/**
 * repeated string emails_to_bcc = 9;
 * @return {!Array<string>}
 */
proto.inbox.QueueMessageUpdateRequest.prototype.getEmailsToBccList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.inbox.QueueMessageUpdateRequest} returns this
 */
proto.inbox.QueueMessageUpdateRequest.prototype.setEmailsToBccList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.inbox.QueueMessageUpdateRequest} returns this
 */
proto.inbox.QueueMessageUpdateRequest.prototype.addEmailsToBcc = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.inbox.QueueMessageUpdateRequest} returns this
 */
proto.inbox.QueueMessageUpdateRequest.prototype.clearEmailsToBccList = function() {
  return this.setEmailsToBccList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.inbox.GetEmailsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.inbox.GetEmailsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.inbox.GetEmailsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.GetEmailsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    accountId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    optionalEmailAddressId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    optionalFolderId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    assigned: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    shared: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    status: jspb.Message.getFieldWithDefault(msg, 7, 0),
    page: jspb.Message.getFieldWithDefault(msg, 8, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.inbox.GetEmailsRequest}
 */
proto.inbox.GetEmailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.inbox.GetEmailsRequest;
  return proto.inbox.GetEmailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.inbox.GetEmailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.inbox.GetEmailsRequest}
 */
proto.inbox.GetEmailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAccountId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOptionalEmailAddressId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionalFolderId(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAssigned(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShared(value);
      break;
    case 7:
      var value = /** @type {!proto.inbox.ThreadStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.inbox.GetEmailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.inbox.GetEmailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.inbox.GetEmailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.GetEmailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getOptionalEmailAddressId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getOptionalFolderId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAssigned();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getShared();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
};


/**
 * optional int64 user_id = 1;
 * @return {number}
 */
proto.inbox.GetEmailsRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.GetEmailsRequest} returns this
 */
proto.inbox.GetEmailsRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 account_id = 2;
 * @return {number}
 */
proto.inbox.GetEmailsRequest.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.GetEmailsRequest} returns this
 */
proto.inbox.GetEmailsRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 optional_email_address_id = 3;
 * @return {number}
 */
proto.inbox.GetEmailsRequest.prototype.getOptionalEmailAddressId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.GetEmailsRequest} returns this
 */
proto.inbox.GetEmailsRequest.prototype.setOptionalEmailAddressId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string optional_folder_id = 4;
 * @return {string}
 */
proto.inbox.GetEmailsRequest.prototype.getOptionalFolderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.GetEmailsRequest} returns this
 */
proto.inbox.GetEmailsRequest.prototype.setOptionalFolderId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool assigned = 5;
 * @return {boolean}
 */
proto.inbox.GetEmailsRequest.prototype.getAssigned = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.inbox.GetEmailsRequest} returns this
 */
proto.inbox.GetEmailsRequest.prototype.setAssigned = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool shared = 6;
 * @return {boolean}
 */
proto.inbox.GetEmailsRequest.prototype.getShared = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.inbox.GetEmailsRequest} returns this
 */
proto.inbox.GetEmailsRequest.prototype.setShared = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional ThreadStatus status = 7;
 * @return {!proto.inbox.ThreadStatus}
 */
proto.inbox.GetEmailsRequest.prototype.getStatus = function() {
  return /** @type {!proto.inbox.ThreadStatus} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.inbox.ThreadStatus} value
 * @return {!proto.inbox.GetEmailsRequest} returns this
 */
proto.inbox.GetEmailsRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional int32 page = 8;
 * @return {number}
 */
proto.inbox.GetEmailsRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.GetEmailsRequest} returns this
 */
proto.inbox.GetEmailsRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 limit = 9;
 * @return {number}
 */
proto.inbox.GetEmailsRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.GetEmailsRequest} returns this
 */
proto.inbox.GetEmailsRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.inbox.ThreadMessage.repeatedFields_ = [11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.inbox.ThreadMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.inbox.ThreadMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.inbox.ThreadMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.ThreadMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    messageId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    date: jspb.Message.getFieldWithDefault(msg, 2, 0),
    fromName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    fromEmail: jspb.Message.getFieldWithDefault(msg, 4, ""),
    toName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    toEmail: jspb.Message.getFieldWithDefault(msg, 6, ""),
    subject: jspb.Message.getFieldWithDefault(msg, 7, ""),
    content: jspb.Message.getFieldWithDefault(msg, 8, ""),
    personId: jspb.Message.getFieldWithDefault(msg, 9, 0),
    snoozedUntil: jspb.Message.getFieldWithDefault(msg, 10, 0),
    tagIdsList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
    websiteId: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.inbox.ThreadMessage}
 */
proto.inbox.ThreadMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.inbox.ThreadMessage;
  return proto.inbox.ThreadMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.inbox.ThreadMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.inbox.ThreadMessage}
 */
proto.inbox.ThreadMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessageId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromEmail(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setToName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setToEmail(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubject(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPersonId(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSnoozedUntil(value);
      break;
    case 11:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setTagIdsList(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWebsiteId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.inbox.ThreadMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.inbox.ThreadMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.inbox.ThreadMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.ThreadMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessageId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDate();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getFromName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFromEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getToName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getToEmail();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSubject();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPersonId();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getSnoozedUntil();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getTagIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      11,
      f
    );
  }
  f = message.getWebsiteId();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
};


/**
 * optional string message_id = 1;
 * @return {string}
 */
proto.inbox.ThreadMessage.prototype.getMessageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.ThreadMessage} returns this
 */
proto.inbox.ThreadMessage.prototype.setMessageId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 date = 2;
 * @return {number}
 */
proto.inbox.ThreadMessage.prototype.getDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.ThreadMessage} returns this
 */
proto.inbox.ThreadMessage.prototype.setDate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string from_name = 3;
 * @return {string}
 */
proto.inbox.ThreadMessage.prototype.getFromName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.ThreadMessage} returns this
 */
proto.inbox.ThreadMessage.prototype.setFromName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string from_email = 4;
 * @return {string}
 */
proto.inbox.ThreadMessage.prototype.getFromEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.ThreadMessage} returns this
 */
proto.inbox.ThreadMessage.prototype.setFromEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string to_name = 5;
 * @return {string}
 */
proto.inbox.ThreadMessage.prototype.getToName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.ThreadMessage} returns this
 */
proto.inbox.ThreadMessage.prototype.setToName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string to_email = 6;
 * @return {string}
 */
proto.inbox.ThreadMessage.prototype.getToEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.ThreadMessage} returns this
 */
proto.inbox.ThreadMessage.prototype.setToEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string subject = 7;
 * @return {string}
 */
proto.inbox.ThreadMessage.prototype.getSubject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.ThreadMessage} returns this
 */
proto.inbox.ThreadMessage.prototype.setSubject = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string content = 8;
 * @return {string}
 */
proto.inbox.ThreadMessage.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.ThreadMessage} returns this
 */
proto.inbox.ThreadMessage.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int64 person_id = 9;
 * @return {number}
 */
proto.inbox.ThreadMessage.prototype.getPersonId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.ThreadMessage} returns this
 */
proto.inbox.ThreadMessage.prototype.setPersonId = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 snoozed_until = 10;
 * @return {number}
 */
proto.inbox.ThreadMessage.prototype.getSnoozedUntil = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.ThreadMessage} returns this
 */
proto.inbox.ThreadMessage.prototype.setSnoozedUntil = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * repeated int64 tag_ids = 11;
 * @return {!Array<number>}
 */
proto.inbox.ThreadMessage.prototype.getTagIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.inbox.ThreadMessage} returns this
 */
proto.inbox.ThreadMessage.prototype.setTagIdsList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.inbox.ThreadMessage} returns this
 */
proto.inbox.ThreadMessage.prototype.addTagIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.inbox.ThreadMessage} returns this
 */
proto.inbox.ThreadMessage.prototype.clearTagIdsList = function() {
  return this.setTagIdsList([]);
};


/**
 * optional int64 website_id = 12;
 * @return {number}
 */
proto.inbox.ThreadMessage.prototype.getWebsiteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.ThreadMessage} returns this
 */
proto.inbox.ThreadMessage.prototype.setWebsiteId = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.inbox.FutureMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.inbox.FutureMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.inbox.FutureMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.FutureMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    contactId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    queueId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    personId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    campaignId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    nextActivityTime: jspb.Message.getFieldWithDefault(msg, 5, 0),
    toEmail: jspb.Message.getFieldWithDefault(msg, 6, ""),
    toName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    fromEmail: jspb.Message.getFieldWithDefault(msg, 8, ""),
    fromName: jspb.Message.getFieldWithDefault(msg, 9, ""),
    subject: jspb.Message.getFieldWithDefault(msg, 10, ""),
    snippet: jspb.Message.getFieldWithDefault(msg, 11, ""),
    totalItems: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.inbox.FutureMessage}
 */
proto.inbox.FutureMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.inbox.FutureMessage;
  return proto.inbox.FutureMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.inbox.FutureMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.inbox.FutureMessage}
 */
proto.inbox.FutureMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setContactId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQueueId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPersonId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNextActivityTime(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setToEmail(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setToName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromEmail(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromName(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubject(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setSnippet(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.inbox.FutureMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.inbox.FutureMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.inbox.FutureMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.FutureMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContactId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getQueueId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPersonId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCampaignId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getNextActivityTime();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getToEmail();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getToName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getFromEmail();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getFromName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getSubject();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSnippet();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getTotalItems();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
};


/**
 * optional int64 contact_id = 1;
 * @return {number}
 */
proto.inbox.FutureMessage.prototype.getContactId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.FutureMessage} returns this
 */
proto.inbox.FutureMessage.prototype.setContactId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 queue_id = 2;
 * @return {number}
 */
proto.inbox.FutureMessage.prototype.getQueueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.FutureMessage} returns this
 */
proto.inbox.FutureMessage.prototype.setQueueId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 person_id = 3;
 * @return {number}
 */
proto.inbox.FutureMessage.prototype.getPersonId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.FutureMessage} returns this
 */
proto.inbox.FutureMessage.prototype.setPersonId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 campaign_id = 4;
 * @return {number}
 */
proto.inbox.FutureMessage.prototype.getCampaignId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.FutureMessage} returns this
 */
proto.inbox.FutureMessage.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 next_activity_time = 5;
 * @return {number}
 */
proto.inbox.FutureMessage.prototype.getNextActivityTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.FutureMessage} returns this
 */
proto.inbox.FutureMessage.prototype.setNextActivityTime = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string to_email = 6;
 * @return {string}
 */
proto.inbox.FutureMessage.prototype.getToEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.FutureMessage} returns this
 */
proto.inbox.FutureMessage.prototype.setToEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string to_name = 7;
 * @return {string}
 */
proto.inbox.FutureMessage.prototype.getToName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.FutureMessage} returns this
 */
proto.inbox.FutureMessage.prototype.setToName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string from_email = 8;
 * @return {string}
 */
proto.inbox.FutureMessage.prototype.getFromEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.FutureMessage} returns this
 */
proto.inbox.FutureMessage.prototype.setFromEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string from_name = 9;
 * @return {string}
 */
proto.inbox.FutureMessage.prototype.getFromName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.FutureMessage} returns this
 */
proto.inbox.FutureMessage.prototype.setFromName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string subject = 10;
 * @return {string}
 */
proto.inbox.FutureMessage.prototype.getSubject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.FutureMessage} returns this
 */
proto.inbox.FutureMessage.prototype.setSubject = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string snippet = 11;
 * @return {string}
 */
proto.inbox.FutureMessage.prototype.getSnippet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.FutureMessage} returns this
 */
proto.inbox.FutureMessage.prototype.setSnippet = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional int32 total_items = 12;
 * @return {number}
 */
proto.inbox.FutureMessage.prototype.getTotalItems = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.FutureMessage} returns this
 */
proto.inbox.FutureMessage.prototype.setTotalItems = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.inbox.OutboxThreadMessage.oneofGroups_ = [[3,4]];

/**
 * @enum {number}
 */
proto.inbox.OutboxThreadMessage.DataCase = {
  DATA_NOT_SET: 0,
  INBOX: 3,
  OUTBOX: 4
};

/**
 * @return {proto.inbox.OutboxThreadMessage.DataCase}
 */
proto.inbox.OutboxThreadMessage.prototype.getDataCase = function() {
  return /** @type {proto.inbox.OutboxThreadMessage.DataCase} */(jspb.Message.computeOneofCase(this, proto.inbox.OutboxThreadMessage.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.inbox.OutboxThreadMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.inbox.OutboxThreadMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.inbox.OutboxThreadMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.OutboxThreadMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    actionTime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    inbox: (f = msg.getInbox()) && proto.inbox.ThreadMessage.toObject(includeInstance, f),
    outbox: (f = msg.getOutbox()) && proto.inbox.FutureMessage.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.inbox.OutboxThreadMessage}
 */
proto.inbox.OutboxThreadMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.inbox.OutboxThreadMessage;
  return proto.inbox.OutboxThreadMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.inbox.OutboxThreadMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.inbox.OutboxThreadMessage}
 */
proto.inbox.OutboxThreadMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.inbox.ThreadMessageType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setActionTime(value);
      break;
    case 3:
      var value = new proto.inbox.ThreadMessage;
      reader.readMessage(value,proto.inbox.ThreadMessage.deserializeBinaryFromReader);
      msg.setInbox(value);
      break;
    case 4:
      var value = new proto.inbox.FutureMessage;
      reader.readMessage(value,proto.inbox.FutureMessage.deserializeBinaryFromReader);
      msg.setOutbox(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.inbox.OutboxThreadMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.inbox.OutboxThreadMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.inbox.OutboxThreadMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.OutboxThreadMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getActionTime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getInbox();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.inbox.ThreadMessage.serializeBinaryToWriter
    );
  }
  f = message.getOutbox();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.inbox.FutureMessage.serializeBinaryToWriter
    );
  }
};


/**
 * optional ThreadMessageType type = 1;
 * @return {!proto.inbox.ThreadMessageType}
 */
proto.inbox.OutboxThreadMessage.prototype.getType = function() {
  return /** @type {!proto.inbox.ThreadMessageType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.inbox.ThreadMessageType} value
 * @return {!proto.inbox.OutboxThreadMessage} returns this
 */
proto.inbox.OutboxThreadMessage.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int64 action_time = 2;
 * @return {number}
 */
proto.inbox.OutboxThreadMessage.prototype.getActionTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.OutboxThreadMessage} returns this
 */
proto.inbox.OutboxThreadMessage.prototype.setActionTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional ThreadMessage inbox = 3;
 * @return {?proto.inbox.ThreadMessage}
 */
proto.inbox.OutboxThreadMessage.prototype.getInbox = function() {
  return /** @type{?proto.inbox.ThreadMessage} */ (
    jspb.Message.getWrapperField(this, proto.inbox.ThreadMessage, 3));
};


/**
 * @param {?proto.inbox.ThreadMessage|undefined} value
 * @return {!proto.inbox.OutboxThreadMessage} returns this
*/
proto.inbox.OutboxThreadMessage.prototype.setInbox = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.inbox.OutboxThreadMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.inbox.OutboxThreadMessage} returns this
 */
proto.inbox.OutboxThreadMessage.prototype.clearInbox = function() {
  return this.setInbox(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.inbox.OutboxThreadMessage.prototype.hasInbox = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional FutureMessage outbox = 4;
 * @return {?proto.inbox.FutureMessage}
 */
proto.inbox.OutboxThreadMessage.prototype.getOutbox = function() {
  return /** @type{?proto.inbox.FutureMessage} */ (
    jspb.Message.getWrapperField(this, proto.inbox.FutureMessage, 4));
};


/**
 * @param {?proto.inbox.FutureMessage|undefined} value
 * @return {!proto.inbox.OutboxThreadMessage} returns this
*/
proto.inbox.OutboxThreadMessage.prototype.setOutbox = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.inbox.OutboxThreadMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.inbox.OutboxThreadMessage} returns this
 */
proto.inbox.OutboxThreadMessage.prototype.clearOutbox = function() {
  return this.setOutbox(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.inbox.OutboxThreadMessage.prototype.hasOutbox = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.inbox.ThreadComments.prototype.toObject = function(opt_includeInstance) {
  return proto.inbox.ThreadComments.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.inbox.ThreadComments} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.ThreadComments.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    authorName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    authorImageUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    date: jspb.Message.getFieldWithDefault(msg, 4, 0),
    content: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.inbox.ThreadComments}
 */
proto.inbox.ThreadComments.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.inbox.ThreadComments;
  return proto.inbox.ThreadComments.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.inbox.ThreadComments} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.inbox.ThreadComments}
 */
proto.inbox.ThreadComments.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthorName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthorImageUrl(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.inbox.ThreadComments.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.inbox.ThreadComments.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.inbox.ThreadComments} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.ThreadComments.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAuthorName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAuthorImageUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDate();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int64 user_id = 1;
 * @return {number}
 */
proto.inbox.ThreadComments.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.ThreadComments} returns this
 */
proto.inbox.ThreadComments.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string author_name = 2;
 * @return {string}
 */
proto.inbox.ThreadComments.prototype.getAuthorName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.ThreadComments} returns this
 */
proto.inbox.ThreadComments.prototype.setAuthorName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string author_image_url = 3;
 * @return {string}
 */
proto.inbox.ThreadComments.prototype.getAuthorImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.ThreadComments} returns this
 */
proto.inbox.ThreadComments.prototype.setAuthorImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 date = 4;
 * @return {number}
 */
proto.inbox.ThreadComments.prototype.getDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.ThreadComments} returns this
 */
proto.inbox.ThreadComments.prototype.setDate = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string content = 5;
 * @return {string}
 */
proto.inbox.ThreadComments.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.ThreadComments} returns this
 */
proto.inbox.ThreadComments.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.inbox.InboxThread.repeatedFields_ = [9,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.inbox.InboxThread.prototype.toObject = function(opt_includeInstance) {
  return proto.inbox.InboxThread.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.inbox.InboxThread} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.InboxThread.toObject = function(includeInstance, msg) {
  var f, obj = {
    threadId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    mailboxAccountId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    lastActivityTime: jspb.Message.getFieldWithDefault(msg, 4, 0),
    assigned: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    shared: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    viewed: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    folderId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    messagesList: jspb.Message.toObjectList(msg.getMessagesList(),
    proto.inbox.ThreadMessage.toObject, includeInstance),
    commentsList: jspb.Message.toObjectList(msg.getCommentsList(),
    proto.inbox.ThreadComments.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.inbox.InboxThread}
 */
proto.inbox.InboxThread.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.inbox.InboxThread;
  return proto.inbox.InboxThread.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.inbox.InboxThread} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.inbox.InboxThread}
 */
proto.inbox.InboxThread.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setThreadId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMailboxAccountId(value);
      break;
    case 3:
      var value = /** @type {!proto.inbox.ThreadStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastActivityTime(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAssigned(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShared(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setViewed(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setFolderId(value);
      break;
    case 9:
      var value = new proto.inbox.ThreadMessage;
      reader.readMessage(value,proto.inbox.ThreadMessage.deserializeBinaryFromReader);
      msg.addMessages(value);
      break;
    case 10:
      var value = new proto.inbox.ThreadComments;
      reader.readMessage(value,proto.inbox.ThreadComments.deserializeBinaryFromReader);
      msg.addComments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.inbox.InboxThread.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.inbox.InboxThread.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.inbox.InboxThread} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.InboxThread.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getThreadId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMailboxAccountId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getLastActivityTime();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getAssigned();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getShared();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getViewed();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getFolderId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getMessagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.inbox.ThreadMessage.serializeBinaryToWriter
    );
  }
  f = message.getCommentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.inbox.ThreadComments.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 thread_id = 1;
 * @return {number}
 */
proto.inbox.InboxThread.prototype.getThreadId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.InboxThread} returns this
 */
proto.inbox.InboxThread.prototype.setThreadId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 mailbox_account_id = 2;
 * @return {number}
 */
proto.inbox.InboxThread.prototype.getMailboxAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.InboxThread} returns this
 */
proto.inbox.InboxThread.prototype.setMailboxAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional ThreadStatus status = 3;
 * @return {!proto.inbox.ThreadStatus}
 */
proto.inbox.InboxThread.prototype.getStatus = function() {
  return /** @type {!proto.inbox.ThreadStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.inbox.ThreadStatus} value
 * @return {!proto.inbox.InboxThread} returns this
 */
proto.inbox.InboxThread.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int64 last_activity_time = 4;
 * @return {number}
 */
proto.inbox.InboxThread.prototype.getLastActivityTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.InboxThread} returns this
 */
proto.inbox.InboxThread.prototype.setLastActivityTime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool assigned = 5;
 * @return {boolean}
 */
proto.inbox.InboxThread.prototype.getAssigned = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.inbox.InboxThread} returns this
 */
proto.inbox.InboxThread.prototype.setAssigned = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool shared = 6;
 * @return {boolean}
 */
proto.inbox.InboxThread.prototype.getShared = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.inbox.InboxThread} returns this
 */
proto.inbox.InboxThread.prototype.setShared = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool viewed = 7;
 * @return {boolean}
 */
proto.inbox.InboxThread.prototype.getViewed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.inbox.InboxThread} returns this
 */
proto.inbox.InboxThread.prototype.setViewed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string folder_id = 8;
 * @return {string}
 */
proto.inbox.InboxThread.prototype.getFolderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.InboxThread} returns this
 */
proto.inbox.InboxThread.prototype.setFolderId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated ThreadMessage messages = 9;
 * @return {!Array<!proto.inbox.ThreadMessage>}
 */
proto.inbox.InboxThread.prototype.getMessagesList = function() {
  return /** @type{!Array<!proto.inbox.ThreadMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.inbox.ThreadMessage, 9));
};


/**
 * @param {!Array<!proto.inbox.ThreadMessage>} value
 * @return {!proto.inbox.InboxThread} returns this
*/
proto.inbox.InboxThread.prototype.setMessagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.inbox.ThreadMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.inbox.ThreadMessage}
 */
proto.inbox.InboxThread.prototype.addMessages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.inbox.ThreadMessage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.inbox.InboxThread} returns this
 */
proto.inbox.InboxThread.prototype.clearMessagesList = function() {
  return this.setMessagesList([]);
};


/**
 * repeated ThreadComments comments = 10;
 * @return {!Array<!proto.inbox.ThreadComments>}
 */
proto.inbox.InboxThread.prototype.getCommentsList = function() {
  return /** @type{!Array<!proto.inbox.ThreadComments>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.inbox.ThreadComments, 10));
};


/**
 * @param {!Array<!proto.inbox.ThreadComments>} value
 * @return {!proto.inbox.InboxThread} returns this
*/
proto.inbox.InboxThread.prototype.setCommentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.inbox.ThreadComments=} opt_value
 * @param {number=} opt_index
 * @return {!proto.inbox.ThreadComments}
 */
proto.inbox.InboxThread.prototype.addComments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.inbox.ThreadComments, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.inbox.InboxThread} returns this
 */
proto.inbox.InboxThread.prototype.clearCommentsList = function() {
  return this.setCommentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.inbox.EmailInbox.prototype.toObject = function(opt_includeInstance) {
  return proto.inbox.EmailInbox.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.inbox.EmailInbox} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.EmailInbox.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    numberOfItems: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.inbox.EmailInbox}
 */
proto.inbox.EmailInbox.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.inbox.EmailInbox;
  return proto.inbox.EmailInbox.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.inbox.EmailInbox} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.inbox.EmailInbox}
 */
proto.inbox.EmailInbox.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberOfItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.inbox.EmailInbox.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.inbox.EmailInbox.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.inbox.EmailInbox} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.EmailInbox.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNumberOfItems();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.inbox.EmailInbox.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.EmailInbox} returns this
 */
proto.inbox.EmailInbox.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.inbox.EmailInbox.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.EmailInbox} returns this
 */
proto.inbox.EmailInbox.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 number_of_items = 3;
 * @return {number}
 */
proto.inbox.EmailInbox.prototype.getNumberOfItems = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.EmailInbox} returns this
 */
proto.inbox.EmailInbox.prototype.setNumberOfItems = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.inbox.InboxFolders.prototype.toObject = function(opt_includeInstance) {
  return proto.inbox.InboxFolders.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.inbox.InboxFolders} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.InboxFolders.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.inbox.InboxFolders}
 */
proto.inbox.InboxFolders.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.inbox.InboxFolders;
  return proto.inbox.InboxFolders.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.inbox.InboxFolders} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.inbox.InboxFolders}
 */
proto.inbox.InboxFolders.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.inbox.InboxFolders.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.inbox.InboxFolders.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.inbox.InboxFolders} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.InboxFolders.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.inbox.InboxFolders.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.InboxFolders} returns this
 */
proto.inbox.InboxFolders.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.inbox.InboxFolders.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.InboxFolders} returns this
 */
proto.inbox.InboxFolders.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.inbox.AddCommentToThreadRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.inbox.AddCommentToThreadRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.inbox.AddCommentToThreadRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.inbox.AddCommentToThreadRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.AddCommentToThreadRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    accountId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    threadId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    text: jspb.Message.getFieldWithDefault(msg, 4, ""),
    mentionedAccountIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.inbox.AddCommentToThreadRequest}
 */
proto.inbox.AddCommentToThreadRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.inbox.AddCommentToThreadRequest;
  return proto.inbox.AddCommentToThreadRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.inbox.AddCommentToThreadRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.inbox.AddCommentToThreadRequest}
 */
proto.inbox.AddCommentToThreadRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAccountId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setThreadId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 5:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setMentionedAccountIdsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.inbox.AddCommentToThreadRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.inbox.AddCommentToThreadRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.inbox.AddCommentToThreadRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.AddCommentToThreadRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getThreadId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMentionedAccountIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      5,
      f
    );
  }
};


/**
 * optional int64 user_id = 1;
 * @return {number}
 */
proto.inbox.AddCommentToThreadRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.AddCommentToThreadRequest} returns this
 */
proto.inbox.AddCommentToThreadRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 account_id = 2;
 * @return {number}
 */
proto.inbox.AddCommentToThreadRequest.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.AddCommentToThreadRequest} returns this
 */
proto.inbox.AddCommentToThreadRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 thread_id = 3;
 * @return {number}
 */
proto.inbox.AddCommentToThreadRequest.prototype.getThreadId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.AddCommentToThreadRequest} returns this
 */
proto.inbox.AddCommentToThreadRequest.prototype.setThreadId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string text = 4;
 * @return {string}
 */
proto.inbox.AddCommentToThreadRequest.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.AddCommentToThreadRequest} returns this
 */
proto.inbox.AddCommentToThreadRequest.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated int64 mentioned_account_ids = 5;
 * @return {!Array<number>}
 */
proto.inbox.AddCommentToThreadRequest.prototype.getMentionedAccountIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.inbox.AddCommentToThreadRequest} returns this
 */
proto.inbox.AddCommentToThreadRequest.prototype.setMentionedAccountIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.inbox.AddCommentToThreadRequest} returns this
 */
proto.inbox.AddCommentToThreadRequest.prototype.addMentionedAccountIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.inbox.AddCommentToThreadRequest} returns this
 */
proto.inbox.AddCommentToThreadRequest.prototype.clearMentionedAccountIdsList = function() {
  return this.setMentionedAccountIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.inbox.AssignThreadRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.inbox.AssignThreadRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.inbox.AssignThreadRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.AssignThreadRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    accountId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    threadId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    targetAccountId: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.inbox.AssignThreadRequest}
 */
proto.inbox.AssignThreadRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.inbox.AssignThreadRequest;
  return proto.inbox.AssignThreadRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.inbox.AssignThreadRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.inbox.AssignThreadRequest}
 */
proto.inbox.AssignThreadRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAccountId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setThreadId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTargetAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.inbox.AssignThreadRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.inbox.AssignThreadRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.inbox.AssignThreadRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.AssignThreadRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getThreadId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getTargetAccountId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 user_id = 1;
 * @return {number}
 */
proto.inbox.AssignThreadRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.AssignThreadRequest} returns this
 */
proto.inbox.AssignThreadRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 account_id = 2;
 * @return {number}
 */
proto.inbox.AssignThreadRequest.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.AssignThreadRequest} returns this
 */
proto.inbox.AssignThreadRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 thread_id = 3;
 * @return {number}
 */
proto.inbox.AssignThreadRequest.prototype.getThreadId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.AssignThreadRequest} returns this
 */
proto.inbox.AssignThreadRequest.prototype.setThreadId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 target_account_id = 4;
 * @return {number}
 */
proto.inbox.AssignThreadRequest.prototype.getTargetAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.AssignThreadRequest} returns this
 */
proto.inbox.AssignThreadRequest.prototype.setTargetAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.inbox.AttachedFile.prototype.toObject = function(opt_includeInstance) {
  return proto.inbox.AttachedFile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.inbox.AttachedFile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.AttachedFile.toObject = function(includeInstance, msg) {
  var f, obj = {
    fileId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    file: msg.getFile_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.inbox.AttachedFile}
 */
proto.inbox.AttachedFile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.inbox.AttachedFile;
  return proto.inbox.AttachedFile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.inbox.AttachedFile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.inbox.AttachedFile}
 */
proto.inbox.AttachedFile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setFile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.inbox.AttachedFile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.inbox.AttachedFile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.inbox.AttachedFile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.AttachedFile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFileId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFile_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
};


/**
 * optional string file_id = 1;
 * @return {string}
 */
proto.inbox.AttachedFile.prototype.getFileId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.AttachedFile} returns this
 */
proto.inbox.AttachedFile.prototype.setFileId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.inbox.AttachedFile.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.AttachedFile} returns this
 */
proto.inbox.AttachedFile.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bytes file = 3;
 * @return {!(string|Uint8Array)}
 */
proto.inbox.AttachedFile.prototype.getFile = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes file = 3;
 * This is a type-conversion wrapper around `getFile()`
 * @return {string}
 */
proto.inbox.AttachedFile.prototype.getFile_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getFile()));
};


/**
 * optional bytes file = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getFile()`
 * @return {!Uint8Array}
 */
proto.inbox.AttachedFile.prototype.getFile_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getFile()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.inbox.AttachedFile} returns this
 */
proto.inbox.AttachedFile.prototype.setFile = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.inbox.MessageRequest.repeatedFields_ = [4,5,6,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.inbox.MessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.inbox.MessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.inbox.MessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.MessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    optionalThreadId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    optionalReplyMessageId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    emailAddressIdFrom: jspb.Message.getFieldWithDefault(msg, 3, 0),
    emailsToList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    emailsCcList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    emailsBccList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    subject: jspb.Message.getFieldWithDefault(msg, 7, ""),
    content: jspb.Message.getFieldWithDefault(msg, 8, ""),
    filesList: jspb.Message.toObjectList(msg.getFilesList(),
    proto.inbox.AttachedFile.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.inbox.MessageRequest}
 */
proto.inbox.MessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.inbox.MessageRequest;
  return proto.inbox.MessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.inbox.MessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.inbox.MessageRequest}
 */
proto.inbox.MessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOptionalThreadId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionalReplyMessageId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEmailAddressIdFrom(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addEmailsTo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addEmailsCc(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addEmailsBcc(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubject(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 9:
      var value = new proto.inbox.AttachedFile;
      reader.readMessage(value,proto.inbox.AttachedFile.deserializeBinaryFromReader);
      msg.addFiles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.inbox.MessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.inbox.MessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.inbox.MessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.MessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOptionalThreadId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOptionalReplyMessageId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmailAddressIdFrom();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getEmailsToList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getEmailsCcList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getEmailsBccList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getSubject();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getFilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.inbox.AttachedFile.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 optional_thread_id = 1;
 * @return {number}
 */
proto.inbox.MessageRequest.prototype.getOptionalThreadId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.MessageRequest} returns this
 */
proto.inbox.MessageRequest.prototype.setOptionalThreadId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string optional_reply_message_id = 2;
 * @return {string}
 */
proto.inbox.MessageRequest.prototype.getOptionalReplyMessageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.MessageRequest} returns this
 */
proto.inbox.MessageRequest.prototype.setOptionalReplyMessageId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 email_address_id_from = 3;
 * @return {number}
 */
proto.inbox.MessageRequest.prototype.getEmailAddressIdFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.MessageRequest} returns this
 */
proto.inbox.MessageRequest.prototype.setEmailAddressIdFrom = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated string emails_to = 4;
 * @return {!Array<string>}
 */
proto.inbox.MessageRequest.prototype.getEmailsToList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.inbox.MessageRequest} returns this
 */
proto.inbox.MessageRequest.prototype.setEmailsToList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.inbox.MessageRequest} returns this
 */
proto.inbox.MessageRequest.prototype.addEmailsTo = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.inbox.MessageRequest} returns this
 */
proto.inbox.MessageRequest.prototype.clearEmailsToList = function() {
  return this.setEmailsToList([]);
};


/**
 * repeated string emails_cc = 5;
 * @return {!Array<string>}
 */
proto.inbox.MessageRequest.prototype.getEmailsCcList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.inbox.MessageRequest} returns this
 */
proto.inbox.MessageRequest.prototype.setEmailsCcList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.inbox.MessageRequest} returns this
 */
proto.inbox.MessageRequest.prototype.addEmailsCc = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.inbox.MessageRequest} returns this
 */
proto.inbox.MessageRequest.prototype.clearEmailsCcList = function() {
  return this.setEmailsCcList([]);
};


/**
 * repeated string emails_bcc = 6;
 * @return {!Array<string>}
 */
proto.inbox.MessageRequest.prototype.getEmailsBccList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.inbox.MessageRequest} returns this
 */
proto.inbox.MessageRequest.prototype.setEmailsBccList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.inbox.MessageRequest} returns this
 */
proto.inbox.MessageRequest.prototype.addEmailsBcc = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.inbox.MessageRequest} returns this
 */
proto.inbox.MessageRequest.prototype.clearEmailsBccList = function() {
  return this.setEmailsBccList([]);
};


/**
 * optional string subject = 7;
 * @return {string}
 */
proto.inbox.MessageRequest.prototype.getSubject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.MessageRequest} returns this
 */
proto.inbox.MessageRequest.prototype.setSubject = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string content = 8;
 * @return {string}
 */
proto.inbox.MessageRequest.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.MessageRequest} returns this
 */
proto.inbox.MessageRequest.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated AttachedFile files = 9;
 * @return {!Array<!proto.inbox.AttachedFile>}
 */
proto.inbox.MessageRequest.prototype.getFilesList = function() {
  return /** @type{!Array<!proto.inbox.AttachedFile>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.inbox.AttachedFile, 9));
};


/**
 * @param {!Array<!proto.inbox.AttachedFile>} value
 * @return {!proto.inbox.MessageRequest} returns this
*/
proto.inbox.MessageRequest.prototype.setFilesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.inbox.AttachedFile=} opt_value
 * @param {number=} opt_index
 * @return {!proto.inbox.AttachedFile}
 */
proto.inbox.MessageRequest.prototype.addFiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.inbox.AttachedFile, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.inbox.MessageRequest} returns this
 */
proto.inbox.MessageRequest.prototype.clearFilesList = function() {
  return this.setFilesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.inbox.SendEmailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.inbox.SendEmailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.inbox.SendEmailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.SendEmailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    accountId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    message: (f = msg.getMessage()) && proto.inbox.MessageRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.inbox.SendEmailRequest}
 */
proto.inbox.SendEmailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.inbox.SendEmailRequest;
  return proto.inbox.SendEmailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.inbox.SendEmailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.inbox.SendEmailRequest}
 */
proto.inbox.SendEmailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAccountId(value);
      break;
    case 3:
      var value = new proto.inbox.MessageRequest;
      reader.readMessage(value,proto.inbox.MessageRequest.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.inbox.SendEmailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.inbox.SendEmailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.inbox.SendEmailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.SendEmailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.inbox.MessageRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 user_id = 1;
 * @return {number}
 */
proto.inbox.SendEmailRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.SendEmailRequest} returns this
 */
proto.inbox.SendEmailRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 account_id = 2;
 * @return {number}
 */
proto.inbox.SendEmailRequest.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.SendEmailRequest} returns this
 */
proto.inbox.SendEmailRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional MessageRequest message = 3;
 * @return {?proto.inbox.MessageRequest}
 */
proto.inbox.SendEmailRequest.prototype.getMessage = function() {
  return /** @type{?proto.inbox.MessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.inbox.MessageRequest, 3));
};


/**
 * @param {?proto.inbox.MessageRequest|undefined} value
 * @return {!proto.inbox.SendEmailRequest} returns this
*/
proto.inbox.SendEmailRequest.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.inbox.SendEmailRequest} returns this
 */
proto.inbox.SendEmailRequest.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.inbox.SendEmailRequest.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.inbox.BlackListRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.inbox.BlackListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.inbox.BlackListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.inbox.BlackListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.BlackListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pattern: jspb.Message.getFieldWithDefault(msg, 2, ""),
    workspaceIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.inbox.BlackListRequest}
 */
proto.inbox.BlackListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.inbox.BlackListRequest;
  return proto.inbox.BlackListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.inbox.BlackListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.inbox.BlackListRequest}
 */
proto.inbox.BlackListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPattern(value);
      break;
    case 3:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setWorkspaceIdsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.inbox.BlackListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.inbox.BlackListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.inbox.BlackListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.BlackListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPattern();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getWorkspaceIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.inbox.BlackListRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.BlackListRequest} returns this
 */
proto.inbox.BlackListRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string pattern = 2;
 * @return {string}
 */
proto.inbox.BlackListRequest.prototype.getPattern = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.BlackListRequest} returns this
 */
proto.inbox.BlackListRequest.prototype.setPattern = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated int64 workspace_ids = 3;
 * @return {!Array<number>}
 */
proto.inbox.BlackListRequest.prototype.getWorkspaceIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.inbox.BlackListRequest} returns this
 */
proto.inbox.BlackListRequest.prototype.setWorkspaceIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.inbox.BlackListRequest} returns this
 */
proto.inbox.BlackListRequest.prototype.addWorkspaceIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.inbox.BlackListRequest} returns this
 */
proto.inbox.BlackListRequest.prototype.clearWorkspaceIdsList = function() {
  return this.setWorkspaceIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.inbox.BlackListBatchRequest.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.inbox.BlackListBatchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.inbox.BlackListBatchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.inbox.BlackListBatchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.BlackListBatchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    patternsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    workspaceIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.inbox.BlackListBatchRequest}
 */
proto.inbox.BlackListBatchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.inbox.BlackListBatchRequest;
  return proto.inbox.BlackListBatchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.inbox.BlackListBatchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.inbox.BlackListBatchRequest}
 */
proto.inbox.BlackListBatchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addPatterns(value);
      break;
    case 2:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setWorkspaceIdsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.inbox.BlackListBatchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.inbox.BlackListBatchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.inbox.BlackListBatchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.BlackListBatchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPatternsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getWorkspaceIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
};


/**
 * repeated string patterns = 1;
 * @return {!Array<string>}
 */
proto.inbox.BlackListBatchRequest.prototype.getPatternsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.inbox.BlackListBatchRequest} returns this
 */
proto.inbox.BlackListBatchRequest.prototype.setPatternsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.inbox.BlackListBatchRequest} returns this
 */
proto.inbox.BlackListBatchRequest.prototype.addPatterns = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.inbox.BlackListBatchRequest} returns this
 */
proto.inbox.BlackListBatchRequest.prototype.clearPatternsList = function() {
  return this.setPatternsList([]);
};


/**
 * repeated int64 workspace_ids = 2;
 * @return {!Array<number>}
 */
proto.inbox.BlackListBatchRequest.prototype.getWorkspaceIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.inbox.BlackListBatchRequest} returns this
 */
proto.inbox.BlackListBatchRequest.prototype.setWorkspaceIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.inbox.BlackListBatchRequest} returns this
 */
proto.inbox.BlackListBatchRequest.prototype.addWorkspaceIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.inbox.BlackListBatchRequest} returns this
 */
proto.inbox.BlackListBatchRequest.prototype.clearWorkspaceIdsList = function() {
  return this.setWorkspaceIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.inbox.BlackListResponse.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.inbox.BlackListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.inbox.BlackListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.inbox.BlackListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.BlackListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pattern: jspb.Message.getFieldWithDefault(msg, 2, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 3, 0),
    reason: jspb.Message.getFieldWithDefault(msg, 4, ""),
    totalItems: jspb.Message.getFieldWithDefault(msg, 5, 0),
    workspaceIdsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.inbox.BlackListResponse}
 */
proto.inbox.BlackListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.inbox.BlackListResponse;
  return proto.inbox.BlackListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.inbox.BlackListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.inbox.BlackListResponse}
 */
proto.inbox.BlackListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPattern(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalItems(value);
      break;
    case 6:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setWorkspaceIdsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.inbox.BlackListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.inbox.BlackListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.inbox.BlackListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.BlackListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPattern();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTotalItems();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getWorkspaceIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      6,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.inbox.BlackListResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.BlackListResponse} returns this
 */
proto.inbox.BlackListResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string pattern = 2;
 * @return {string}
 */
proto.inbox.BlackListResponse.prototype.getPattern = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.BlackListResponse} returns this
 */
proto.inbox.BlackListResponse.prototype.setPattern = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 created_at = 3;
 * @return {number}
 */
proto.inbox.BlackListResponse.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.BlackListResponse} returns this
 */
proto.inbox.BlackListResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string reason = 4;
 * @return {string}
 */
proto.inbox.BlackListResponse.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.BlackListResponse} returns this
 */
proto.inbox.BlackListResponse.prototype.setReason = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 total_items = 5;
 * @return {number}
 */
proto.inbox.BlackListResponse.prototype.getTotalItems = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.BlackListResponse} returns this
 */
proto.inbox.BlackListResponse.prototype.setTotalItems = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated int64 workspace_ids = 6;
 * @return {!Array<number>}
 */
proto.inbox.BlackListResponse.prototype.getWorkspaceIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.inbox.BlackListResponse} returns this
 */
proto.inbox.BlackListResponse.prototype.setWorkspaceIdsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.inbox.BlackListResponse} returns this
 */
proto.inbox.BlackListResponse.prototype.addWorkspaceIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.inbox.BlackListResponse} returns this
 */
proto.inbox.BlackListResponse.prototype.clearWorkspaceIdsList = function() {
  return this.setWorkspaceIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.inbox.MailboxQueueCancelRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.inbox.MailboxQueueCancelRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.inbox.MailboxQueueCancelRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.inbox.MailboxQueueCancelRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.MailboxQueueCancelRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    relatedTo: jspb.Message.getFieldWithDefault(msg, 3, 0),
    idsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.inbox.MailboxQueueCancelRequest}
 */
proto.inbox.MailboxQueueCancelRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.inbox.MailboxQueueCancelRequest;
  return proto.inbox.MailboxQueueCancelRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.inbox.MailboxQueueCancelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.inbox.MailboxQueueCancelRequest}
 */
proto.inbox.MailboxQueueCancelRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {!proto.inbox.MailboxQueueCancelRequest.MailboxQueueCancelRelatedType} */ (reader.readEnum());
      msg.setRelatedTo(value);
      break;
    case 4:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setIdsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.inbox.MailboxQueueCancelRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.inbox.MailboxQueueCancelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.inbox.MailboxQueueCancelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.MailboxQueueCancelRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getRelatedTo();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.inbox.MailboxQueueCancelRequest.MailboxQueueCancelRelatedType = {
  PEOPLE: 0,
  OPPORTUNITY: 1
};

/**
 * optional int64 campaign_id = 1;
 * @return {number}
 */
proto.inbox.MailboxQueueCancelRequest.prototype.getCampaignId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.MailboxQueueCancelRequest} returns this
 */
proto.inbox.MailboxQueueCancelRequest.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 workspace_id = 2;
 * @return {number}
 */
proto.inbox.MailboxQueueCancelRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.MailboxQueueCancelRequest} returns this
 */
proto.inbox.MailboxQueueCancelRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional MailboxQueueCancelRelatedType related_to = 3;
 * @return {!proto.inbox.MailboxQueueCancelRequest.MailboxQueueCancelRelatedType}
 */
proto.inbox.MailboxQueueCancelRequest.prototype.getRelatedTo = function() {
  return /** @type {!proto.inbox.MailboxQueueCancelRequest.MailboxQueueCancelRelatedType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.inbox.MailboxQueueCancelRequest.MailboxQueueCancelRelatedType} value
 * @return {!proto.inbox.MailboxQueueCancelRequest} returns this
 */
proto.inbox.MailboxQueueCancelRequest.prototype.setRelatedTo = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * repeated int64 ids = 4;
 * @return {!Array<number>}
 */
proto.inbox.MailboxQueueCancelRequest.prototype.getIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.inbox.MailboxQueueCancelRequest} returns this
 */
proto.inbox.MailboxQueueCancelRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.inbox.MailboxQueueCancelRequest} returns this
 */
proto.inbox.MailboxQueueCancelRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.inbox.MailboxQueueCancelRequest} returns this
 */
proto.inbox.MailboxQueueCancelRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.inbox.ExportBlackListPatternRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.inbox.ExportBlackListPatternRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.inbox.ExportBlackListPatternRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.inbox.ExportBlackListPatternRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.ExportBlackListPatternRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    optionalSelectedList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.inbox.ExportBlackListPatternRequest}
 */
proto.inbox.ExportBlackListPatternRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.inbox.ExportBlackListPatternRequest;
  return proto.inbox.ExportBlackListPatternRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.inbox.ExportBlackListPatternRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.inbox.ExportBlackListPatternRequest}
 */
proto.inbox.ExportBlackListPatternRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.inbox.ExportBlackListPatternRequest.ExportType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setOptionalSelectedList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.inbox.ExportBlackListPatternRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.inbox.ExportBlackListPatternRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.inbox.ExportBlackListPatternRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.ExportBlackListPatternRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getOptionalSelectedList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.inbox.ExportBlackListPatternRequest.ExportType = {
  ALL: 0,
  SELECTED: 1
};

/**
 * optional ExportType type = 1;
 * @return {!proto.inbox.ExportBlackListPatternRequest.ExportType}
 */
proto.inbox.ExportBlackListPatternRequest.prototype.getType = function() {
  return /** @type {!proto.inbox.ExportBlackListPatternRequest.ExportType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.inbox.ExportBlackListPatternRequest.ExportType} value
 * @return {!proto.inbox.ExportBlackListPatternRequest} returns this
 */
proto.inbox.ExportBlackListPatternRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated int64 optional_selected = 2;
 * @return {!Array<number>}
 */
proto.inbox.ExportBlackListPatternRequest.prototype.getOptionalSelectedList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.inbox.ExportBlackListPatternRequest} returns this
 */
proto.inbox.ExportBlackListPatternRequest.prototype.setOptionalSelectedList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.inbox.ExportBlackListPatternRequest} returns this
 */
proto.inbox.ExportBlackListPatternRequest.prototype.addOptionalSelected = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.inbox.ExportBlackListPatternRequest} returns this
 */
proto.inbox.ExportBlackListPatternRequest.prototype.clearOptionalSelectedList = function() {
  return this.setOptionalSelectedList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.inbox.GetUnsubscribedPatternPageable.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.inbox.GetUnsubscribedPatternPageable.prototype.toObject = function(opt_includeInstance) {
  return proto.inbox.GetUnsubscribedPatternPageable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.inbox.GetUnsubscribedPatternPageable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.GetUnsubscribedPatternPageable.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    optionalQuery: jspb.Message.getFieldWithDefault(msg, 3, ""),
    optionalFiltersList: jspb.Message.toObjectList(msg.getOptionalFiltersList(),
    proto.inbox.UnsubscribedFilter.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.inbox.GetUnsubscribedPatternPageable}
 */
proto.inbox.GetUnsubscribedPatternPageable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.inbox.GetUnsubscribedPatternPageable;
  return proto.inbox.GetUnsubscribedPatternPageable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.inbox.GetUnsubscribedPatternPageable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.inbox.GetUnsubscribedPatternPageable}
 */
proto.inbox.GetUnsubscribedPatternPageable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionalQuery(value);
      break;
    case 4:
      var value = new proto.inbox.UnsubscribedFilter;
      reader.readMessage(value,proto.inbox.UnsubscribedFilter.deserializeBinaryFromReader);
      msg.addOptionalFilters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.inbox.GetUnsubscribedPatternPageable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.inbox.GetUnsubscribedPatternPageable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.inbox.GetUnsubscribedPatternPageable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.GetUnsubscribedPatternPageable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getOptionalQuery();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOptionalFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.inbox.UnsubscribedFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 page = 1;
 * @return {number}
 */
proto.inbox.GetUnsubscribedPatternPageable.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.GetUnsubscribedPatternPageable} returns this
 */
proto.inbox.GetUnsubscribedPatternPageable.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 limit = 2;
 * @return {number}
 */
proto.inbox.GetUnsubscribedPatternPageable.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.GetUnsubscribedPatternPageable} returns this
 */
proto.inbox.GetUnsubscribedPatternPageable.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string optional_query = 3;
 * @return {string}
 */
proto.inbox.GetUnsubscribedPatternPageable.prototype.getOptionalQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.GetUnsubscribedPatternPageable} returns this
 */
proto.inbox.GetUnsubscribedPatternPageable.prototype.setOptionalQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated UnsubscribedFilter optional_filters = 4;
 * @return {!Array<!proto.inbox.UnsubscribedFilter>}
 */
proto.inbox.GetUnsubscribedPatternPageable.prototype.getOptionalFiltersList = function() {
  return /** @type{!Array<!proto.inbox.UnsubscribedFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.inbox.UnsubscribedFilter, 4));
};


/**
 * @param {!Array<!proto.inbox.UnsubscribedFilter>} value
 * @return {!proto.inbox.GetUnsubscribedPatternPageable} returns this
*/
proto.inbox.GetUnsubscribedPatternPageable.prototype.setOptionalFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.inbox.UnsubscribedFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.inbox.UnsubscribedFilter}
 */
proto.inbox.GetUnsubscribedPatternPageable.prototype.addOptionalFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.inbox.UnsubscribedFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.inbox.GetUnsubscribedPatternPageable} returns this
 */
proto.inbox.GetUnsubscribedPatternPageable.prototype.clearOptionalFiltersList = function() {
  return this.setOptionalFiltersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.inbox.UnsubscribedFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.inbox.UnsubscribedFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.inbox.UnsubscribedFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.UnsubscribedFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    order: jspb.Message.getFieldWithDefault(msg, 1, 0),
    field: jspb.Message.getFieldWithDefault(msg, 2, 0),
    value: jspb.Message.getFieldWithDefault(msg, 3, ""),
    operation: jspb.Message.getFieldWithDefault(msg, 4, 0),
    join: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.inbox.UnsubscribedFilter}
 */
proto.inbox.UnsubscribedFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.inbox.UnsubscribedFilter;
  return proto.inbox.UnsubscribedFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.inbox.UnsubscribedFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.inbox.UnsubscribedFilter}
 */
proto.inbox.UnsubscribedFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 2:
      var value = /** @type {!proto.inbox.UnsubscribedFilterField} */ (reader.readEnum());
      msg.setField(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 4:
      var value = /** @type {!proto.common.FilterOperation} */ (reader.readEnum());
      msg.setOperation(value);
      break;
    case 5:
      var value = /** @type {!proto.common.FilterJoinType} */ (reader.readEnum());
      msg.setJoin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.inbox.UnsubscribedFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.inbox.UnsubscribedFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.inbox.UnsubscribedFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.UnsubscribedFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getField();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOperation();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getJoin();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional int32 order = 1;
 * @return {number}
 */
proto.inbox.UnsubscribedFilter.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.UnsubscribedFilter} returns this
 */
proto.inbox.UnsubscribedFilter.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional UnsubscribedFilterField field = 2;
 * @return {!proto.inbox.UnsubscribedFilterField}
 */
proto.inbox.UnsubscribedFilter.prototype.getField = function() {
  return /** @type {!proto.inbox.UnsubscribedFilterField} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.inbox.UnsubscribedFilterField} value
 * @return {!proto.inbox.UnsubscribedFilter} returns this
 */
proto.inbox.UnsubscribedFilter.prototype.setField = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.inbox.UnsubscribedFilter.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.UnsubscribedFilter} returns this
 */
proto.inbox.UnsubscribedFilter.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional common.FilterOperation operation = 4;
 * @return {!proto.common.FilterOperation}
 */
proto.inbox.UnsubscribedFilter.prototype.getOperation = function() {
  return /** @type {!proto.common.FilterOperation} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.common.FilterOperation} value
 * @return {!proto.inbox.UnsubscribedFilter} returns this
 */
proto.inbox.UnsubscribedFilter.prototype.setOperation = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional common.FilterJoinType join = 5;
 * @return {!proto.common.FilterJoinType}
 */
proto.inbox.UnsubscribedFilter.prototype.getJoin = function() {
  return /** @type {!proto.common.FilterJoinType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.common.FilterJoinType} value
 * @return {!proto.inbox.UnsubscribedFilter} returns this
 */
proto.inbox.UnsubscribedFilter.prototype.setJoin = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.inbox.ThreadPreviewRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.inbox.ThreadPreviewRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.inbox.ThreadPreviewRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.inbox.ThreadPreviewRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.ThreadPreviewRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    mailboxIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    filter: (f = msg.getFilter()) && proto.inbox.GlobalInboxFilterRequest.toObject(includeInstance, f),
    label: jspb.Message.getFieldWithDefault(msg, 5, 0),
    globalSearchQuery: jspb.Message.getFieldWithDefault(msg, 6, ""),
    sortDirection: jspb.Message.getFieldWithDefault(msg, 7, 0),
    page: jspb.Message.getFieldWithDefault(msg, 8, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.inbox.ThreadPreviewRequest}
 */
proto.inbox.ThreadPreviewRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.inbox.ThreadPreviewRequest;
  return proto.inbox.ThreadPreviewRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.inbox.ThreadPreviewRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.inbox.ThreadPreviewRequest}
 */
proto.inbox.ThreadPreviewRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 2:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setMailboxIdsList(value);
      break;
    case 3:
      var value = /** @type {!proto.inbox.ThreadStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = new proto.inbox.GlobalInboxFilterRequest;
      reader.readMessage(value,proto.inbox.GlobalInboxFilterRequest.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 5:
      var value = /** @type {!proto.inbox.MailboxFolderLabel} */ (reader.readEnum());
      msg.setLabel(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setGlobalSearchQuery(value);
      break;
    case 7:
      var value = /** @type {!proto.common.SortDirection} */ (reader.readEnum());
      msg.setSortDirection(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.inbox.ThreadPreviewRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.inbox.ThreadPreviewRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.inbox.ThreadPreviewRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.ThreadPreviewRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMailboxIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.inbox.GlobalInboxFilterRequest.serializeBinaryToWriter
    );
  }
  f = message.getLabel();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getGlobalSearchQuery();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSortDirection();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
};


/**
 * optional int64 workspace_id = 1;
 * @return {number}
 */
proto.inbox.ThreadPreviewRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.ThreadPreviewRequest} returns this
 */
proto.inbox.ThreadPreviewRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated int64 mailbox_ids = 2;
 * @return {!Array<number>}
 */
proto.inbox.ThreadPreviewRequest.prototype.getMailboxIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.inbox.ThreadPreviewRequest} returns this
 */
proto.inbox.ThreadPreviewRequest.prototype.setMailboxIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.inbox.ThreadPreviewRequest} returns this
 */
proto.inbox.ThreadPreviewRequest.prototype.addMailboxIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.inbox.ThreadPreviewRequest} returns this
 */
proto.inbox.ThreadPreviewRequest.prototype.clearMailboxIdsList = function() {
  return this.setMailboxIdsList([]);
};


/**
 * optional ThreadStatus status = 3;
 * @return {!proto.inbox.ThreadStatus}
 */
proto.inbox.ThreadPreviewRequest.prototype.getStatus = function() {
  return /** @type {!proto.inbox.ThreadStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.inbox.ThreadStatus} value
 * @return {!proto.inbox.ThreadPreviewRequest} returns this
 */
proto.inbox.ThreadPreviewRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional GlobalInboxFilterRequest filter = 4;
 * @return {?proto.inbox.GlobalInboxFilterRequest}
 */
proto.inbox.ThreadPreviewRequest.prototype.getFilter = function() {
  return /** @type{?proto.inbox.GlobalInboxFilterRequest} */ (
    jspb.Message.getWrapperField(this, proto.inbox.GlobalInboxFilterRequest, 4));
};


/**
 * @param {?proto.inbox.GlobalInboxFilterRequest|undefined} value
 * @return {!proto.inbox.ThreadPreviewRequest} returns this
*/
proto.inbox.ThreadPreviewRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.inbox.ThreadPreviewRequest} returns this
 */
proto.inbox.ThreadPreviewRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.inbox.ThreadPreviewRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional MailboxFolderLabel label = 5;
 * @return {!proto.inbox.MailboxFolderLabel}
 */
proto.inbox.ThreadPreviewRequest.prototype.getLabel = function() {
  return /** @type {!proto.inbox.MailboxFolderLabel} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.inbox.MailboxFolderLabel} value
 * @return {!proto.inbox.ThreadPreviewRequest} returns this
 */
proto.inbox.ThreadPreviewRequest.prototype.setLabel = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string global_search_query = 6;
 * @return {string}
 */
proto.inbox.ThreadPreviewRequest.prototype.getGlobalSearchQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.ThreadPreviewRequest} returns this
 */
proto.inbox.ThreadPreviewRequest.prototype.setGlobalSearchQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional common.SortDirection sort_direction = 7;
 * @return {!proto.common.SortDirection}
 */
proto.inbox.ThreadPreviewRequest.prototype.getSortDirection = function() {
  return /** @type {!proto.common.SortDirection} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.common.SortDirection} value
 * @return {!proto.inbox.ThreadPreviewRequest} returns this
 */
proto.inbox.ThreadPreviewRequest.prototype.setSortDirection = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional int32 page = 8;
 * @return {number}
 */
proto.inbox.ThreadPreviewRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.ThreadPreviewRequest} returns this
 */
proto.inbox.ThreadPreviewRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 limit = 9;
 * @return {number}
 */
proto.inbox.ThreadPreviewRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.ThreadPreviewRequest} returns this
 */
proto.inbox.ThreadPreviewRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.inbox.ThreadUidRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.inbox.ThreadUidRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.inbox.ThreadUidRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.ThreadUidRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    uid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.inbox.ThreadUidRequest}
 */
proto.inbox.ThreadUidRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.inbox.ThreadUidRequest;
  return proto.inbox.ThreadUidRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.inbox.ThreadUidRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.inbox.ThreadUidRequest}
 */
proto.inbox.ThreadUidRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.inbox.ThreadUidRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.inbox.ThreadUidRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.inbox.ThreadUidRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.ThreadUidRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 workspace_id = 1;
 * @return {number}
 */
proto.inbox.ThreadUidRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.ThreadUidRequest} returns this
 */
proto.inbox.ThreadUidRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string uid = 2;
 * @return {string}
 */
proto.inbox.ThreadUidRequest.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.ThreadUidRequest} returns this
 */
proto.inbox.ThreadUidRequest.prototype.setUid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.inbox.PersonInboxPreviewEmailsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.inbox.PersonInboxPreviewEmailsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.inbox.PersonInboxPreviewEmailsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.PersonInboxPreviewEmailsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    personId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    personCompanyId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.inbox.PersonInboxPreviewEmailsRequest}
 */
proto.inbox.PersonInboxPreviewEmailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.inbox.PersonInboxPreviewEmailsRequest;
  return proto.inbox.PersonInboxPreviewEmailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.inbox.PersonInboxPreviewEmailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.inbox.PersonInboxPreviewEmailsRequest}
 */
proto.inbox.PersonInboxPreviewEmailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPersonId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPersonCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.inbox.PersonInboxPreviewEmailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.inbox.PersonInboxPreviewEmailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.inbox.PersonInboxPreviewEmailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.PersonInboxPreviewEmailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPersonId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPersonCompanyId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 workspace_id = 1;
 * @return {number}
 */
proto.inbox.PersonInboxPreviewEmailsRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.PersonInboxPreviewEmailsRequest} returns this
 */
proto.inbox.PersonInboxPreviewEmailsRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 person_id = 2;
 * @return {number}
 */
proto.inbox.PersonInboxPreviewEmailsRequest.prototype.getPersonId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.PersonInboxPreviewEmailsRequest} returns this
 */
proto.inbox.PersonInboxPreviewEmailsRequest.prototype.setPersonId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 person_company_id = 3;
 * @return {number}
 */
proto.inbox.PersonInboxPreviewEmailsRequest.prototype.getPersonCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.PersonInboxPreviewEmailsRequest} returns this
 */
proto.inbox.PersonInboxPreviewEmailsRequest.prototype.setPersonCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.inbox.ThreadStatusRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.inbox.ThreadStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.inbox.ThreadStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.inbox.ThreadStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.ThreadStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    threadUidsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    optionalSnoozedUntilAt: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.inbox.ThreadStatusRequest}
 */
proto.inbox.ThreadStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.inbox.ThreadStatusRequest;
  return proto.inbox.ThreadStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.inbox.ThreadStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.inbox.ThreadStatusRequest}
 */
proto.inbox.ThreadStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addThreadUids(value);
      break;
    case 3:
      var value = /** @type {!proto.inbox.ThreadStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOptionalSnoozedUntilAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.inbox.ThreadStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.inbox.ThreadStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.inbox.ThreadStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.ThreadStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getThreadUidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getOptionalSnoozedUntilAt();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 workspace_id = 1;
 * @return {number}
 */
proto.inbox.ThreadStatusRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.ThreadStatusRequest} returns this
 */
proto.inbox.ThreadStatusRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated string thread_uids = 2;
 * @return {!Array<string>}
 */
proto.inbox.ThreadStatusRequest.prototype.getThreadUidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.inbox.ThreadStatusRequest} returns this
 */
proto.inbox.ThreadStatusRequest.prototype.setThreadUidsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.inbox.ThreadStatusRequest} returns this
 */
proto.inbox.ThreadStatusRequest.prototype.addThreadUids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.inbox.ThreadStatusRequest} returns this
 */
proto.inbox.ThreadStatusRequest.prototype.clearThreadUidsList = function() {
  return this.setThreadUidsList([]);
};


/**
 * optional ThreadStatus status = 3;
 * @return {!proto.inbox.ThreadStatus}
 */
proto.inbox.ThreadStatusRequest.prototype.getStatus = function() {
  return /** @type {!proto.inbox.ThreadStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.inbox.ThreadStatus} value
 * @return {!proto.inbox.ThreadStatusRequest} returns this
 */
proto.inbox.ThreadStatusRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int64 optional_snoozed_until_at = 4;
 * @return {number}
 */
proto.inbox.ThreadStatusRequest.prototype.getOptionalSnoozedUntilAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.ThreadStatusRequest} returns this
 */
proto.inbox.ThreadStatusRequest.prototype.setOptionalSnoozedUntilAt = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.inbox.GlobalInboxFilterRequest.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.inbox.GlobalInboxFilterRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.inbox.GlobalInboxFilterRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.inbox.GlobalInboxFilterRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.GlobalInboxFilterRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    fromEmail: jspb.Message.getFieldWithDefault(msg, 1, ""),
    toEmail: jspb.Message.getFieldWithDefault(msg, 2, ""),
    subjectQuery: jspb.Message.getFieldWithDefault(msg, 3, ""),
    contentQuery: jspb.Message.getFieldWithDefault(msg, 4, ""),
    campaignId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    onlyRelatedToCampaign: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    tagIdsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.inbox.GlobalInboxFilterRequest}
 */
proto.inbox.GlobalInboxFilterRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.inbox.GlobalInboxFilterRequest;
  return proto.inbox.GlobalInboxFilterRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.inbox.GlobalInboxFilterRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.inbox.GlobalInboxFilterRequest}
 */
proto.inbox.GlobalInboxFilterRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setToEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubjectQuery(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setContentQuery(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignId(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnlyRelatedToCampaign(value);
      break;
    case 7:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setTagIdsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.inbox.GlobalInboxFilterRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.inbox.GlobalInboxFilterRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.inbox.GlobalInboxFilterRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.inbox.GlobalInboxFilterRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFromEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getToEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSubjectQuery();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getContentQuery();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCampaignId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getOnlyRelatedToCampaign();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getTagIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      7,
      f
    );
  }
};


/**
 * optional string from_email = 1;
 * @return {string}
 */
proto.inbox.GlobalInboxFilterRequest.prototype.getFromEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.GlobalInboxFilterRequest} returns this
 */
proto.inbox.GlobalInboxFilterRequest.prototype.setFromEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string to_email = 2;
 * @return {string}
 */
proto.inbox.GlobalInboxFilterRequest.prototype.getToEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.GlobalInboxFilterRequest} returns this
 */
proto.inbox.GlobalInboxFilterRequest.prototype.setToEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string subject_query = 3;
 * @return {string}
 */
proto.inbox.GlobalInboxFilterRequest.prototype.getSubjectQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.GlobalInboxFilterRequest} returns this
 */
proto.inbox.GlobalInboxFilterRequest.prototype.setSubjectQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string content_query = 4;
 * @return {string}
 */
proto.inbox.GlobalInboxFilterRequest.prototype.getContentQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.inbox.GlobalInboxFilterRequest} returns this
 */
proto.inbox.GlobalInboxFilterRequest.prototype.setContentQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 campaign_id = 5;
 * @return {number}
 */
proto.inbox.GlobalInboxFilterRequest.prototype.getCampaignId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.inbox.GlobalInboxFilterRequest} returns this
 */
proto.inbox.GlobalInboxFilterRequest.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bool only_related_to_campaign = 6;
 * @return {boolean}
 */
proto.inbox.GlobalInboxFilterRequest.prototype.getOnlyRelatedToCampaign = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.inbox.GlobalInboxFilterRequest} returns this
 */
proto.inbox.GlobalInboxFilterRequest.prototype.setOnlyRelatedToCampaign = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * repeated int64 tag_ids = 7;
 * @return {!Array<number>}
 */
proto.inbox.GlobalInboxFilterRequest.prototype.getTagIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.inbox.GlobalInboxFilterRequest} returns this
 */
proto.inbox.GlobalInboxFilterRequest.prototype.setTagIdsList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.inbox.GlobalInboxFilterRequest} returns this
 */
proto.inbox.GlobalInboxFilterRequest.prototype.addTagIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.inbox.GlobalInboxFilterRequest} returns this
 */
proto.inbox.GlobalInboxFilterRequest.prototype.clearTagIdsList = function() {
  return this.setTagIdsList([]);
};


/**
 * @enum {number}
 */
proto.inbox.MailAIAction = {
  FIX_GRAMMAR: 0,
  MAKE_SHORTER: 1,
  TRANSLATE: 2,
  MAKE_CASUAL: 3,
  MAKE_FORMAL: 4,
  MAKE_FRIENDLY: 5,
  MAKE_FUNNY: 6,
  WRITE_DRAFT: 7
};

/**
 * @enum {number}
 */
proto.inbox.ThreadMessageType = {
  INBOX_ITEM: 0,
  OUTBOX_ITEM: 1
};

/**
 * @enum {number}
 */
proto.inbox.BlackListReasonType = {
  MANUAL: 0,
  AUTOMATIC: 1
};

/**
 * @enum {number}
 */
proto.inbox.UnsubscribedFilterField = {
  STRING_PATTERN: 0,
  DROPDOWN_WORKSPACE_ID: 1,
  STRING_SCOPE: 2
};

/**
 * @enum {number}
 */
proto.inbox.ThreadStatus = {
  OPEN: 0,
  ARCHIVE: 1,
  SNOOZED: 2,
  REMOVED: 3,
  ALL: 4
};

/**
 * @enum {number}
 */
proto.inbox.MailboxFolderLabel = {
  INBOX: 0,
  SENT: 1,
  SPAM: 2,
  NEED_ATTENTION: 3,
  ASSIGNED_TO_ME: 4,
  MENTIONED_ME: 5,
  DRAFT: 6,
  FAVORITE: 7
};

goog.object.extend(exports, proto.inbox);
