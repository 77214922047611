import { handleActions } from 'redux-actions';

import loadingStatuses from 'src/constants/loadingStatuses';
import { updateItemInListByIdHelper } from 'src/helpers/commonHelpers';
import {
  changeCurrentWorkspace,
  changeWorkspace,
  createWorkspace,
  deleteWorkspace,
  fetchWorkspaces,
  setWorkspaces,
} from '@redux/actions/workspaces.actions';
import { WorkspaceShortType } from '@ts/userOrganization.types';

export type WorkspacesStateType = {
  currentWorkspaceUid?: number;
  workspaces: WorkspaceShortType[];
  loadingStatus: string;
};

const initialState: WorkspacesStateType = {
  currentWorkspaceUid: null,
  workspaces: [],
  loadingStatus: loadingStatuses.PENDING,
};

export default handleActions(
  {
    [changeCurrentWorkspace]: (state: WorkspacesStateType, { payload }) => ({
      ...state,
      currentWorkspaceUid: payload,
    }),
    [fetchWorkspaces]: (state: WorkspacesStateType) => ({
      ...state,
      loadingStatus: loadingStatuses.INITED,
    }),
    [setWorkspaces]: (state: WorkspacesStateType, { payload }) => {
      const activeWorkspace = payload?.find((item) => item.favorite) || payload?.[0];

      return {
        ...state,
        workspaces: payload,
        currentWorkspaceUid: activeWorkspace?.id || null,
        loadingStatus: loadingStatuses.LOADED,
      };
    },
    [changeWorkspace]: (state: WorkspacesStateType, { payload }) => ({
      ...state,
      workspaces: updateItemInListByIdHelper(state.workspaces, payload),
    }),
    [createWorkspace]: (state: WorkspacesStateType, { payload }) => ({
      ...state,
      workspaces: [...state.workspaces, payload],
    }),
    [deleteWorkspace]: (state: WorkspacesStateType, { payload }) => ({
      ...state,
      workspaces: state.workspaces.filter(({ id }) => id !== payload),
    }),
  },
  initialState
);
