import React, { ReactNode } from 'react';

import DropdownPanel from '@uikit/DropdownPanel/DropdownPanel';

import './HealthCheckDropdown.scss';

const mockData = [
  {
    title: '123.33.32.233 - Record ',
    addresses: [
      { title: 'Some name of Loren ipsum', status: 'Not listed' },
      { title: 'Some name of Loren ipsum', status: 'Listed' },
    ],
  },
  {
    title: '123.33.32.233 - Record ',
    addresses: [{ title: 'Some name of Loren ipsum', status: 'Not listed' }],
  },
];

const HealthCheckDropdown = ({
  title,
  details,
}: {
  title?: ReactNode;
  details?: { title: ReactNode; addresses?: { title: ReactNode; status: string }[] }[];
}): JSX.Element => {
  return (
    <DropdownPanel
      disabled={!details?.length}
      classNames={{
        header: 'health-check-dropdown-header',
        container: 'health-check-dropdown',
        containerOpen: 'health-check-dropdown--open',
        content: 'health-check-dropdown-content',
      }}
      headerText={title}
      initiallyOpen={false}
    >
      {details?.map((item, index) => {
        const isDisabled = !details?.some((item) => item.addresses?.length > 0);
        return (
          <DropdownPanel
            key={index}
            disabled={isDisabled}
            classNames={{
              header: 'health-check-dropdown-nested-header',
              container: 'health-check-dropdown-nested',
              containerOpen: 'health-check-dropdown-nested--open',
              content: 'health-check-dropdown-nested-content',
            }}
            headerText={
              <div
                className="health-check-dropdown-nested-header__text"
                style={{ whiteSpace: isDisabled ? 'pre' : 'nowrap' }}
              >
                {item.title}
              </div>
            }
            initiallyOpen={false}
          >
            {item.addresses?.map((address, index) => (
              <div className="health-check-dropdown-nested__text-list" key={index}>
                <div className="health-check-dropdown-nested__text">{address.title}</div>
                <div className="health-check-dropdown-nested__text">{address.status}</div>
              </div>
            ))}
          </DropdownPanel>
        );
      })}
    </DropdownPanel>
  );
};

export default HealthCheckDropdown;
