import { createSelector } from 'reselect';

import { StateType } from '@redux/reducers';

export const getPipelinesState = ({ pipelines }: StateType) => pipelines;

export const getRefetchOpportunities = createSelector(
  [getPipelinesState],
  ({ refetchOpportunities }): (() => void) => refetchOpportunities
);

export const getRefetchShortOpportunities = createSelector(
  [getPipelinesState],
  ({ refetchShortOpportunities }): (() => void) => refetchShortOpportunities
);
