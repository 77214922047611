import { OpenCampaignType } from '@ts/campaigns.types';
import { TemplateStepTransformedType } from '@ts/template.types';
import React, { useState, useEffect } from 'react';
import cn from 'class-names';

import SlateEditor from '@uikit/RichEditor/SlateEditor';
import InlineSlateEditor from '@uikit/RichEditor/InlineSlateEditor';
import ModerateChance from '@components/ModerateChance/ModerateChance';

import './AnalyticSequenceEditor.scss';
import { ReferralLinkData } from 'respona_api/generated/user-organization_pb';

const AnalyticSequenceEditor = ({
  step,
  stepIndex,
  openCampaign,
  enabledUnsubscribeContent,
  referralContent,
  onSave,
}: {
  step: TemplateStepTransformedType;
  stepIndex: number;
  openCampaign: OpenCampaignType;
  enabledUnsubscribeContent: boolean;
  referralContent: ReferralLinkData.AsObject;
  onSave: any;
}) => {
  const [subject, changeSubject] = useState('');
  const [template, changeTemplate] = useState('');
  // const senderMailbox = useSelector(getBaseAccountMailboxById(openCampaign?.senderMailboxId));

  useEffect(() => {
    if (!step?.subject) {
      return;
    }
    changeSubject(step.subject);
  }, [step, stepIndex]);

  useEffect(() => {
    if (!step?.content) {
      return;
    }
    changeTemplate(step.content);
  }, [step, stepIndex]);

  const handleSave = () => {
    onSave({
      ...step,
      subject,
      content: template,
    });
  };

  const cnSequenceEditorHeader = cn('analytic-sequence-editor__header', {
    'analytic-sequence-editor__header--same-thread': step?.isSameThread,
  });
  //
  // if (!step) {
  //   return null;
  // }

  return (
    <div className="analytic-sequence-editor">
      <div className={cnSequenceEditorHeader}>
        <InlineSlateEditor
          key={`inline-${stepIndex}-${step?.id}`}
          message={subject}
          onChange={changeSubject}
          placeholder={
            stepIndex === 0 ? 'Subject' : 'Keep empty to send as a reply to your previous email'
          }
          useMessageUpdate
          disabled
        />
        <ModerateChance subject={subject} template={template} />
      </div>
      <SlateEditor
        removingText={`Step ${stepIndex + 1}`}
        key={`${stepIndex}-${step?.id}`}
        message={template}
        onChange={changeTemplate}
        onFileUpload={() => {}}
        onRemoveFile={() => {}}
        isAttachmentsLoading={false}
        attachments={step?.attachmentsList}
        // signature={senderMailbox?.signature}
        onAction={handleSave}
        classNames={{
          controls: 'analytic-sequence-editor__footer',
        }}
        actionText="Save"
        useMessageUpdate
        showUnsubscribeContent={enabledUnsubscribeContent}
        referralContent={referralContent}
        disabled
      />
    </div>
  );
};

export default AnalyticSequenceEditor;
