import { createAction } from 'redux-actions';

import { FolderType } from '@ts/common.types';
import { PeopleFilterObjectType, PeopleTagsType } from '@ts/people.types';

export const setFiltersForPeople = createAction<PeopleFilterObjectType>('SET_FILTERS_FOR_PEOPLE');
export const throwFiltersForPeople = createAction<void>('THROW_FILTERS_FOR_PEOPLE');

export const setFoldersData = createAction<FolderType[]>('SET_PEOPLES_FOLDERS_DATA');
export const addFoldersData = createAction<FolderType>('ADD_PEOPLES_FOLDERS_DATA');
export const updateFolderData = createAction<FolderType>('UPDATE_PEOPLES_FOLDER_DATA');
export const removeFolderData = createAction<number>('REMOVE_PEOPLES_FOLDER_DATA');
export const setFoldersLs = createAction<string>('SET_PEOPLES_FOLDERS_LS');

export const setPeopleTagsData = createAction<PeopleTagsType>('SET_PEOPLE_TAGS_DATA');
export const addPeopleTag = createAction<PeopleTagsType>('ADD_PEOPLE_TAG');
export const setPeopleTagsLs = createAction<string>('SET_PEOPLE_TAGS_LS');
