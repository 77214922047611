import ResponaNewLogo from '@uikit/ResponaNewLogo/ResponaNewLogo';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cn from 'class-names';

import { fetchAllWorkspaces } from '@redux/thunks/workspacesRequests';
import { workspacesSelector, getCurrentWorkspaceId } from '@redux/selectors/workspaces.selectors';
import { toggleWorkspacesSidebar } from '@redux/actions/appState.actions';

import Loader from '@uikit/Loader/Loader';

import loadingStatuses from '@constants/loadingStatuses';

import './WorkspaceSelect.scss';
import { WorkspaceShortType } from '@ts/userOrganization.types';

const getWorkspaceById = (workspaces: WorkspaceShortType[], workspaceUid: number) =>
  workspaces.find(({ id }) => id === workspaceUid);

export default function ({ preview }: { preview?: boolean }): JSX.Element {
  const dispatch = useDispatch();
  const { workspaces, loadingStatus } = useSelector(workspacesSelector);
  const curWorkspaceId = useSelector(getCurrentWorkspaceId);
  const curWorkspace = getWorkspaceById(workspaces, curWorkspaceId);

  useEffect(() => {
    if (loadingStatus === loadingStatuses.PENDING && workspaces.length === 0 && !preview) {
      fetchAllWorkspaces(dispatch);
    }
  }, [preview]);

  const handleSelectClick = (evt) => {
    evt.stopPropagation();
    dispatch(toggleWorkspacesSidebar(true));
  };

  const cnWorkspaceSelect = cn('workspace-select');

  if (loadingStatus !== loadingStatuses.LOADED && !preview) {
    return <Loader isLoading withTopMargin />;
  }

  if (preview) {
    return (
      <div className={cnWorkspaceSelect} onClick={handleSelectClick}>
        <div className="workspace-select__short-content">
          <ResponaNewLogo size="small" />
        </div>
      </div>
    );
  }

  if (workspaces?.length === 0) {
    return null;
  }

  return (
    <div className={cnWorkspaceSelect} onClick={handleSelectClick}>
      {curWorkspace.logoUrl ? (
        <div className="workspace-select__short-content">
          <img src={curWorkspace.logoUrl} className="workspace-select__logo" alt="" />
        </div>
      ) : (
        <div className="workspace-select__short-content">{curWorkspace.title.substr(0, 2)}</div>
      )}
    </div>
  );
}
