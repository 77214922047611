import {
  CampaignAutomationStatus,
  CampaignAutomationStatusMap,
} from 'respona_api/generated/common_pb';
import { Status } from '@components/CampaignSettings/ContentSearchActivityLogs/types/types';

const getUIForStatus = (
  value: CampaignAutomationStatusMap[keyof CampaignAutomationStatusMap]
): string => {
  switch (value) {
    case CampaignAutomationStatus.IN_PROCESS:
      return Status.IN_PROCESS;
    case CampaignAutomationStatus.FUTURE:
      return Status.FUTURE;
    case CampaignAutomationStatus.CANCELED:
      return Status.CANCELED;
    case CampaignAutomationStatus.FINISHED:
      return Status.FINISHED;
    case CampaignAutomationStatus.DRAFT:
      return Status.DRAFT;
    default:
      return '-';
  }
};

export default getUIForStatus;
