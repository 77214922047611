import React from 'react';

export default ({ color = '#221CB6', size = 20 }): JSX.Element => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0002 0.833496C10.3174 0.833496 10.6071 1.01356 10.7474 1.29798L13.1287 6.12222L18.454 6.90059C18.7678 6.94646 19.0284 7.16646 19.1262 7.46814C19.224 7.76981 19.1421 8.10085 18.915 8.32213L15.0623 12.0746L15.9715 17.376C16.0252 17.6886 15.8966 18.0046 15.64 18.191C15.3833 18.3775 15.0431 18.402 14.7623 18.2544L10.0002 15.75L5.23806 18.2544C4.9573 18.402 4.61706 18.3775 4.36041 18.191C4.10376 18.0046 3.97522 17.6886 4.02885 17.376L4.93809 12.0746L1.08541 8.32213C0.858228 8.10085 0.776334 7.76981 0.874149 7.46814C0.971963 7.16646 1.23253 6.94646 1.54633 6.90059L6.87164 6.12222L9.25293 1.29798C9.39332 1.01356 9.68301 0.833496 10.0002 0.833496ZM10.0002 3.54954L8.17244 7.25235C8.05116 7.49806 7.81684 7.66844 7.54571 7.70807L3.4572 8.30567L6.41496 11.1865C6.61153 11.378 6.70124 11.6539 6.65486 11.9244L5.957 15.9932L9.61231 14.0709C9.85513 13.9432 10.1452 13.9432 10.3881 14.0709L14.0434 15.9932L13.3455 11.9244C13.2991 11.6539 13.3888 11.378 13.5854 11.1865L16.5432 8.30567L12.4547 7.70807C12.1835 7.66844 11.9492 7.49806 11.8279 7.25235L10.0002 3.54954Z"
        fill={color}
      />
      <path
        d="M8.17244 7.25235L10.0002 3.54954L11.8279 7.25235C11.9492 7.49806 12.1835 7.66844 12.4547 7.70807L16.5432 8.30567L13.5854 11.1865C13.3888 11.378 13.2991 11.6539 13.3455 11.9244L14.0434 15.9932L10.3881 14.0709C10.1452 13.9432 9.85513 13.9432 9.61231 14.0709L5.957 15.9932L6.65486 11.9244C6.70124 11.6539 6.61153 11.378 6.41496 11.1865L3.4572 8.30567L7.54571 7.70807C7.81684 7.66844 8.05116 7.49806 8.17244 7.25235Z"
        fill={color}
      />
    </svg>
  );
};
