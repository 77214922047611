import React from 'react';

const EmailHealthIcon = ({ color = '#BDBDBD' }: { color: string }): JSX.Element => (
  <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.6346 1C10.0577 1 9.53846 1.28846 9.25 1.75C8.96154 1.28846 8.44231 1 7.86538 1C6.94231 1 6.25 1.75 6.25 2.67308C6.25 4.28846 9.25 6.76923 9.25 6.76923C9.25 6.76923 12.25 4.28846 12.25 2.67308C12.25 1.75 11.5577 1 10.6346 1Z"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M1 7V16H17.5V7"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path d="M17.5 7L9.25 11.5" stroke="#212121" strokeWidth="1.6" strokeMiterlimit="10" />
    <path
      d="M1 7L17.5 16"
      stroke={color}
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);

export default EmailHealthIcon;
