import { contactSidebarSize } from '@components/ContactSidebarCRM/ContactSidebarCRM';
import Display from '@components/Display/Display';
import { WebsiteType } from '@ts/company.types';
import { PersonBaseType } from '@ts/people.types';
import Avatar, { NamedAvatar } from '@uikit/Avatar/Avatar';
import { SVGIcon } from '@uikit/Icon/Icon';
import React from 'react';

import './ContactSidebarCRMHeader.scss';

type Props = {
  isWebsite?: boolean;
  person?: PersonBaseType;
  website?: WebsiteType;
  size?: contactSidebarSize;
};

function ContactSidebarCRMHeader({
  isWebsite,
  website,
  person,
  size = contactSidebarSize.NORMAL,
}: Props) {
  const websiteUrl = website?.logoUrl || person?.website?.logoUrl || '';
  const showWebsite = isWebsite && websiteUrl.length > 0;
  const displayTitle = (isWebsite ? person?.website?.title : person?.name) || website?.title;
  const displayDomain = person?.website?.domain || website?.domain;

  return (
    <div className="contact-sidebar-crm-header">
      <Display isVisible={size === contactSidebarSize.NORMAL}>
        {showWebsite ? (
          <Avatar
            url={websiteUrl}
            fullName={website?.title || person?.website?.title || ''}
            size="m"
          />
        ) : (
          <NamedAvatar userName={displayTitle} className="contact-sidebar-crm-header-avatar" />
        )}
      </Display>

      <div className="contact-sidebar-crm-header-column">
        <div className="contact-sidebar-crm-header-name">{displayTitle}</div>

        {displayDomain && (
          <div
            className="contact-sidebar-crm-header-company"
            onClick={() => window.open(`https://${displayDomain}`, '_blank')}
          >
            {displayDomain}
            <SVGIcon icon="externalLink" size={12} color="#221cb6" />
          </div>
        )}
      </div>
    </div>
  );
}

export default ContactSidebarCRMHeader;
