import React from 'react';

export default ({ color = '#8F9199' }) => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.97168 10C0.97168 9.53976 1.34478 9.16667 1.80501 9.16667H6.80501C7.08364 9.16667 7.34383 9.30592 7.49839 9.53775L8.91767 11.6667H11.359L12.7783 9.53775C12.9329 9.30592 13.1931 9.16667 13.4717 9.16667H18.4717C18.9319 9.16667 19.305 9.53976 19.305 10C19.305 10.4602 18.9319 10.8333 18.4717 10.8333H13.9177L12.4984 12.9622C12.3438 13.1941 12.0836 13.3333 11.805 13.3333H8.47168C8.19305 13.3333 7.93286 13.1941 7.7783 12.9622L6.35903 10.8333H1.80501C1.34478 10.8333 0.97168 10.4602 0.97168 10Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.17168 2.5L14.105 2.5C14.5701 2.50025 15.0264 2.63024 15.4216 2.87535C15.8166 3.12025 16.1354 3.47037 16.3424 3.8864C16.3426 3.88677 16.3428 3.88713 16.343 3.8875L19.2168 9.62689C19.2748 9.74271 19.305 9.87047 19.305 10V15C19.305 15.663 19.0416 16.2989 18.5728 16.7678C18.1039 17.2366 17.4681 17.5 16.805 17.5H3.47168C2.80864 17.5 2.17275 17.2366 1.70391 16.7678C1.23507 16.2989 0.97168 15.663 0.97168 15V10C0.97168 9.87047 1.00188 9.74272 1.05987 9.62689L3.93374 3.8875C3.93394 3.88709 3.93414 3.88669 3.93434 3.88628C4.14133 3.4703 4.46015 3.12022 4.85504 2.87535C5.25032 2.63024 5.70657 2.50025 6.17168 2.5ZM4.68001 4.25833L5.42515 4.63144L2.63835 10.197V15C2.63835 15.221 2.72614 15.433 2.88242 15.5893C3.0387 15.7455 3.25067 15.8333 3.47168 15.8333H16.805C17.026 15.8333 17.238 15.7455 17.3943 15.5893C17.5505 15.433 17.6383 15.221 17.6383 15V10.197L14.8515 4.63144L14.8504 4.62917C14.7814 4.49033 14.6751 4.37349 14.5433 4.29178C14.4116 4.21011 14.2597 4.16678 14.1047 4.16667H6.17196C6.01698 4.16678 5.8651 4.21011 5.73339 4.29178C5.60163 4.37349 5.49528 4.49033 5.42629 4.62917L4.68001 4.25833Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="20" height="20" fill="white" transform="translate(0.138184)" />
      </clipPath>
    </defs>
  </svg>
);
