import React, { useEffect } from 'react';
import Display from '../../../../Display/Display';
import ClearFilterLabel from '../ClearFilterLabel/ClearFilterLabel';
import Select from '../../../../../uikit/Select/Select';
import { countries } from '../../../../../constants/countries.contstants';

type OptionType = {
  label: string | number;
  value: string | number;
  [key: string]: any;
};

const FilterWithSelect = ({
  value,
  onApply,
  options,
  placeholder,
  defaultValue,
}: {
  value: OptionType | undefined | null;
  options: OptionType[];
  onApply: (value: OptionType | null | undefined) => void;
  placeholder: string;
  defaultValue?: OptionType;
}): JSX.Element => {
  useEffect(() => {
    if (!value) {
      onApply(defaultValue || options[0]);
    }
  }, []);

  return (
    <>
      <Select placeholder={placeholder} onChange={onApply} options={options} value={value} />
    </>
  );
};

export default FilterWithSelect;
