import React, { useRef } from 'react';

import Urls from '@constants/urls';

import Loader from 'src/uikit/Loader/Loader';
import { checkIsAnyLoading } from 'src/utils/loadingChecks';

import useIntersectionObserver from '@hooks/useIntersectionObserver';

import FolderSidebarLink, {
  NewFolderSidebarLink,
} from '@components/FolderSidebarLink/FolderSidebarLink';
import Display from '@components/Display/Display';
import {
  RenderConfigurableCampaignSidebarLink
} from '@components/Campaigns/CampaignsSidebar/_components/CampaignsSidebarHeader/components/ConfigurableCampaignSidebarLinks';

export default [
  {
    isGroup: true,
    title: 'Create folder',
    pageKey: 'FoldersGroup',
    RenderGroupLinks: ({
      renderProps: {
        campaignFolders,
        campaignFoldersLs,
        onRemoveCampaignFolder,
        onUpdateCampaignFolder,
        onLoadMoreCampaignFolders,
        onShareFolder,
        pathname,
        search,
        isNewFolderCreation,
        onCreateCampaignFolder,
        onCancelFolderCreation,
      },
    }) => {
      const pathNameWithSearch = `${pathname}${search}`;
      const loadingRef = useRef<HTMLDivElement>();

      useIntersectionObserver(loadingRef, () => onLoadMoreCampaignFolders());

      return (
        <>
          <Loader isLoading={checkIsAnyLoading(campaignFoldersLs)} withTopMargin>
            {campaignFolders.map(({ id, title }) => (
              <FolderSidebarLink
                key={id}
                id={id}
                title={title}
                onRemoveFolder={onRemoveCampaignFolder}
                onUpdateFolder={onUpdateCampaignFolder}
                pathNameWithSearch={pathNameWithSearch}
                onShareFolder={onShareFolder}
                basePath="/campaigns"
              />
            ))}

            <RenderConfigurableCampaignSidebarLink
              title="Removed"
              url={Urls.DELETED}
              isActive={pathNameWithSearch === Urls.DELETED}
              isCropped
              icon="trash"
            />
          </Loader>
          <Display isVisible={isNewFolderCreation}>
            <NewFolderSidebarLink
              onCreateFolder={onCreateCampaignFolder}
              onCancel={onCancelFolderCreation}
            />
          </Display>
        </>
      );
    },
    groupActionClick: ({ onOpenFolderCreation }) => {
      onOpenFolderCreation();
    },
  },
];
