import React from 'react';

export default ({ color = '#8D8F92' }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.7824 9.53247C12.5177 8.95396 13.0544 8.16066 13.3178 7.26293C13.5812 6.36519 13.5583 5.40767 13.2522 4.52357C12.9461 3.63947 12.3721 2.87277 11.6099 2.33011C10.8478 1.78746 9.93546 1.49585 8.99988 1.49585C8.06429 1.49585 7.15196 1.78746 6.38982 2.33011C5.62768 2.87277 5.05363 3.63947 4.74753 4.52357C4.44144 5.40767 4.41851 6.36519 4.68195 7.26293C4.94538 8.16066 5.48208 8.95396 6.21738 9.53247C4.95743 10.0373 3.85809 10.8745 3.03654 11.9549C2.215 13.0353 1.70205 14.3184 1.55238 15.6675C1.54154 15.766 1.55021 15.8656 1.57789 15.9608C1.60558 16.0559 1.65173 16.1447 1.71371 16.222C1.83889 16.3781 2.02096 16.4781 2.21988 16.5C2.41879 16.5218 2.61825 16.4638 2.77437 16.3386C2.93049 16.2135 3.0305 16.0314 3.05238 15.8325C3.21706 14.3664 3.91614 13.0123 5.01604 12.0291C6.11594 11.0458 7.53955 10.5022 9.01488 10.5022C10.4902 10.5022 11.9138 11.0458 13.0137 12.0291C14.1136 13.0123 14.8127 14.3664 14.9774 15.8325C14.9978 16.0168 15.0857 16.187 15.2242 16.3102C15.3627 16.4335 15.542 16.5011 15.7274 16.5H15.8099C16.0065 16.4773 16.1862 16.3779 16.3098 16.2234C16.4334 16.0689 16.491 15.8717 16.4699 15.675C16.3195 14.3221 15.8038 13.0357 14.978 11.9536C14.1522 10.8715 13.0476 10.0346 11.7824 9.53247ZM8.99988 8.99997C8.40653 8.99997 7.82651 8.82402 7.33317 8.49438C6.83982 8.16473 6.4553 7.6962 6.22824 7.14802C6.00117 6.59984 5.94176 5.99664 6.05752 5.4147C6.17327 4.83276 6.459 4.29821 6.87855 3.87865C7.29811 3.45909 7.83266 3.17337 8.41461 3.05762C8.99655 2.94186 9.59975 3.00127 10.1479 3.22833C10.6961 3.4554 11.1646 3.83991 11.4943 4.33326C11.8239 4.82661 11.9999 5.40663 11.9999 5.99997C11.9999 6.79562 11.6838 7.55868 11.1212 8.12129C10.5586 8.6839 9.79553 8.99997 8.99988 8.99997Z"
      fill={color}
    />
  </svg>
);
