// package: mailbox_sender
// file: mailbox-sender.proto

var mailbox_sender_pb = require("./mailbox-sender_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var MailboxSenderApiService = (function () {
  function MailboxSenderApiService() {}
  MailboxSenderApiService.serviceName = "mailbox_sender.MailboxSenderApiService";
  return MailboxSenderApiService;
}());

MailboxSenderApiService.sendMessage = {
  methodName: "sendMessage",
  service: MailboxSenderApiService,
  requestStream: false,
  responseStream: false,
  requestType: mailbox_sender_pb.EmailMessageRequest,
  responseType: google_protobuf_empty_pb.Empty
};

MailboxSenderApiService.sendTestMessage = {
  methodName: "sendTestMessage",
  service: MailboxSenderApiService,
  requestStream: false,
  responseStream: false,
  requestType: mailbox_sender_pb.TestMessage,
  responseType: google_protobuf_empty_pb.Empty
};

exports.MailboxSenderApiService = MailboxSenderApiService;

function MailboxSenderApiServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

MailboxSenderApiServiceClient.prototype.sendMessage = function sendMessage(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MailboxSenderApiService.sendMessage, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MailboxSenderApiServiceClient.prototype.sendTestMessage = function sendTestMessage(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MailboxSenderApiService.sendTestMessage, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.MailboxSenderApiServiceClient = MailboxSenderApiServiceClient;

