import React, { useCallback, useRef, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import { DispatchType } from 'src/store';
import { TagsType, TagType } from '@ts/common.types';

import { addNotification } from '@redux/actions/notifications.actions';

import { SVGIcon } from '@uikit/Icon/Icon';
import Loader from '@uikit/Loader/Loader';
import TooltipSearchableContent from '@uikit/TooltipSearchableContent/TooltipSearchableContent';
import Tag from '@components/SidebarCRM/sections/SidebarCRMSectionTags/_components/Tag';
import SidebarCRMSection from '@components/SidebarCRM/sections/SidebarCRMSection/SidebarCRMSection';
import { ItemIdType } from '@components/Relationships/PipelinesSidebar/PipelinesSidebar';

import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';

import './SidebarCRMSectionTags.scss';

type PropMeta = {
  queryKey: string;
  index: number;
  itemId: ItemIdType;
  getAllTags: () => Promise<TagType[]>;
  getAddedTags: (itemId: ItemIdType, workspaceId: number) => Promise<TagsType>;
  addTag: (tagId: number, tagTitle: string, itemId: ItemIdType) => Promise<TagType>;
  removeTag: (tagId: number, itemId: ItemIdType) => Promise<TagType>;
};

function SidebarCRMSectionTags({
  queryKey,
  index,
  itemId,
  getAllTags,
  getAddedTags,
  addTag,
  removeTag,
}: PropMeta): JSX.Element {
  const ref = useRef(null);
  const dispatch = useDispatch<DispatchType>();
  const queryClient = useQueryClient();
  const workspaceId = useCurrentWorkspaceId();
  const [queryEnabled, setQueryEnabled] = useState<boolean>(true);

  const preparedQuery = [queryKey, itemId];

  const { data: tags } = useQuery<TagType[]>(preparedQuery, getAllTags, {
    enabled: queryEnabled && !!itemId,
    refetchOnWindowFocus: false,
  });

  const { data: addedTags, isLoading } = useQuery<TagsType>(
    [...preparedQuery, 'current-tags'],
    () => getAddedTags(itemId, workspaceId),
    {
      enabled: queryEnabled && !!itemId,
      refetchOnWindowFocus: false,
      onSettled: (data) => !!data?.tagsList?.length && ref.current.setOpen(true),
    }
  );

  const onOpenCallback = useCallback(() => {
    setQueryEnabled(true);
  }, []);

  const handleToggleTag = (tagId, tagTitle, selected = true) => {
    const apiCall = selected ? addTag(tagId, tagTitle, itemId) : removeTag(tagId, itemId);

    apiCall.then((res: TagType) => {
      queryClient.setQueryData([...preparedQuery, 'current-tags'], (cachedTags: TagsType) => ({
        ...cachedTags,
        tagsList: selected
          ? [...(cachedTags?.tagsList || []), res]
          : cachedTags.tagsList.filter((item: TagType) => item.id !== tagId),
      }));
      dispatch(
        addNotification({ title: `Tag ${selected ? 'added' : 'removed'}`, type: 'success' })
      );
    });
  };

  const handleCreateTag = (title: string) => {
    addTag(null, title, itemId).then((tag) => {
      queryClient.setQueryData<TagType[]>(preparedQuery, (prevData = []) => [...prevData, tag]);
      queryClient.setQueryData<TagType[]>([queryKey, 'current-tags'], (prevTags = []) => [
        ...prevTags,
        tag,
      ]);
      dispatch(addNotification({ title: 'Tag created', type: 'success' }));
    });
  };

  return (
    <SidebarCRMSection
      ref={ref}
      index={index}
      id="tags"
      title="Tags"
      onOpen={onOpenCallback}
      rightComponent={
        <div
          className="contact-sidebar-crm__content__add-btn"
          data-for="sidebar-crm-tags"
          data-tip=""
        >
          <SVGIcon icon="plusIcon" size={10} color="#221CB6" />
          Add
        </div>
      }
    >
      <div className="contacts-sidebar-section-tags">
        <Loader isLoading={isLoading}>
          {addedTags?.tagsList?.length ? (
            addedTags?.tagsList.map((item: TagType) => (
              <Tag
                key={item.id}
                title={item.title}
                onRemove={() => handleToggleTag(item.id, item.title, false)}
              />
            ))
          ) : (
            <div className="contacts-sidebar-section-tags--empty">Empty</div>
          )}
        </Loader>
      </div>

      <ReactTooltip
        id="sidebar-crm-tags"
        className="react-tooltip"
        place="left"
        effect="solid"
        event="click"
        globalEventOff="click"
        arrowColor="transparent"
        clickable
      >
        <TooltipSearchableContent
          title="Add Tags"
          addButtonText="+ Create new tag"
          isLoading={isLoading}
          onCreate={handleCreateTag}
          onSelect={(tag, selected) => handleToggleTag(tag.id, tag.title, selected)}
          items={tags?.map((item: TagType) => ({
            title: item.title,
            id: item.id,
            selected: addedTags?.tagsList?.some((tag: TagType) => tag.id === item.id),
          }))}
        />
      </ReactTooltip>
    </SidebarCRMSection>
  );
}

export default SidebarCRMSectionTags;
