import React, { memo } from 'react';
import cn from 'class-names';

import HowToUseButton from '@uikit/HowToUseButton/HowToUseButton';

import BackArrowButton from '@components/BackArrowButton/BackArrowButton';

import './PageHeader.scss';

type PageHeaderPropsType = {
  title?: string;
  withBackArrow?: boolean;
  backArrowForceLink?: string;
  renderHeaderActions?: (renderProps: any) => JSX.Element;
  renderHeaderTitle?: () => JSX.Element;
  headerActionsProps?: {
    [key: string]: any;
    [key: number]: any;
  };
  children?: any;
  howToLink?: string;
};

const PageHeader = ({
  title,
  withBackArrow,
  backArrowForceLink,
  renderHeaderActions: RenderHeader = (renderProps) => null,
  renderHeaderTitle: RenderTitle,
  headerActionsProps,
  children,
  howToLink,
}: PageHeaderPropsType): JSX.Element => {
  if (withBackArrow) {
    return (
      <div className="page-header">
        <div
          className={cn('page-header__title-with-backarrow', {
            'page-header__title-with-how-to': !!howToLink,
          })}
        >
          <BackArrowButton forceLink={backArrowForceLink} />
          <div className="page-header__title">
            {title}
            {howToLink && <HowToUseButton style={{ margin: '0 0 4px 19px' }} to={howToLink} />}
          </div>
        </div>
        <div className="page-header__actions">
          {children || <RenderHeader {...headerActionsProps} />}
        </div>
      </div>
    );
  }

  return (
    <div className="page-header">
      <div
        className={cn('page-header__title', {
          'page-header__title-with-how-to': !!howToLink,
        })}
      >
        {RenderTitle ? <RenderTitle /> : title}
        {howToLink && <HowToUseButton style={{ margin: '0 0 4px 19px' }} to={howToLink} />}
      </div>
      <div className="page-header__actions">
        {children || <RenderHeader {...headerActionsProps} />}
      </div>
    </div>
  );
};

export default memo(PageHeader);
